import React, { FC } from 'react'
import { inject, observer } from 'mobx-react'
import DetailsIcon from '@material-ui/icons/InfoRounded'
import BuilderIcon from '@material-ui/icons/Chat'
import OptionsIcon from '@material-ui/icons/Tune'
import ParticipantsIcon from '@material-ui/icons/PersonPin'
import PreviewIcon from '@material-ui/icons/RemoveRedEye'
import { SurveyEditTabsEnum } from '../../../types/SurveyEditTabsEnum'
import Step from './Step'
import { SurveysStore } from 'src/app/surveys/store/SurveysStore'
import './Steppers.scss'
import TopToolbarEndButtons from '../TopToolbarEndButtons'
import CheckIcon from '@material-ui/icons/Check'
import WarningIcon from '@material-ui/icons/Warning'

interface Props {
  surveysStore?: SurveysStore
}

const Steppers: FC<Props> = ({ surveysStore }) => {
  const vm = surveysStore.viewModels.editVM.steppersVM
  if (!vm) return null
  if (!vm.showSteppers) return null

  const renderDetailsIcon = () => {
    if (vm.detailsCompleted) return <CheckIcon className='check-icon' />
    if (vm.detailsError) return <WarningIcon className='warning-icon' />
    return <DetailsIcon />
  }

  const renderBuilderIcon = () => {
    if (vm.builderError) return <WarningIcon className='warning-icon' />
    if (vm.builderCompleted) return <CheckIcon className='check-icon' />
    return <BuilderIcon />
  }

  const renderOptionsIcon = () => {
    if (vm.optionsCompleted) {
      if (vm.optionsError) return <WarningIcon className='warning-icon' />
      return <CheckIcon className='check-icon' />
    }
    return <OptionsIcon />
  }

  const renderParticipantsIcon = () => {
    if (vm.participantsCompleted) return <CheckIcon className='check-icon' />
    if (vm.participantsError) return <WarningIcon className='warning-icon' />
    return <ParticipantsIcon />
  }

  const renderFeedbackIcon = () => {
    if (vm.feedbackCompleted) return <CheckIcon className='check-icon' />
    if (vm.feedbackError) return <WarningIcon className='warning-icon' />
    return <ParticipantsIcon />
  }

  const renderPreviewIcon = () => {
    if (vm.previewCompleted) return <CheckIcon className='check-icon' />
    return <PreviewIcon />
  }

  const renderPublishedIcon = () => {
    if (vm.publishCompleted) return <CheckIcon className='check-icon' />
    return (
      <i
        style={{ fontSize: 14, marginLeft: -2 }}
        className='fa fa-paper-plane'
        aria-hidden='true'
      />
    )
  }

  return (
    <div id='SurveySteppers'>
      <div className='left'>
        <div className='left-line' />
        <Step
          icon={renderDetailsIcon()}
          label='Details'
          index={SurveyEditTabsEnum.DETAILS}
          error={vm.detailsError}
          visited={vm.detailsVisited}
        />
        <Step
          icon={renderBuilderIcon()}
          label='Builder'
          index={SurveyEditTabsEnum.BUILDER}
          error={vm.builderError}
          visited={vm.builderVisited}
        />
        <Step
          icon={renderOptionsIcon()}
          label='Options'
          index={SurveyEditTabsEnum.OPTIONS}
          error={vm.optionsError}
          visited={vm.optionsVisited}
        />
        {!vm.isTemplate && (
          <Step
            icon={renderParticipantsIcon()}
            label='Participants'
            index={SurveyEditTabsEnum.PARTICIPANTS}
            error={vm.participantsError}
            visited={vm.participantsVisited}
          />
        )}
        {vm.surveyType && vm.surveyType.isFeedbackType && (
          <Step
            icon={renderFeedbackIcon()}
            label={vm.surveyTypeForPrompt}
            index={SurveyEditTabsEnum.FEEDBACK}
            error={vm.feedbackError}
            visited={vm.feedbackVisited}
          />
        )}
        <Step
          icon={renderPreviewIcon()}
          label='Preview'
          index={SurveyEditTabsEnum.PREVIEW}
          visited={vm.previewVisited}
        />
        {!vm.isPublished && !vm.isTemplate && (
          <Step
            icon={renderPublishedIcon()}
            label='Published'
            index={SurveyEditTabsEnum.PUBLISH}
            visited={vm.publishCompleted}
          />
        )}
      </div>
      <div className='right'>{vm.isNewSurvey && <TopToolbarEndButtons />}</div>
    </div>
  )
}

export default inject('surveysStore')(observer(Steppers))
