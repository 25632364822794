import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { CardHeader } from '@material-ui/core'
import Searchbar from 'src/app/shared/Searchbar'
import { UserTasksStore } from '../../store/UserTasksStore'

interface HeaderProps {
  localizationStore?: LocalizationStore
  userTasksStore?: UserTasksStore
}

const WidgetHeader: React.FC<HeaderProps> = ({ localizationStore, userTasksStore }) => {
  const { lzStrings } = localizationStore
  const vm = userTasksStore.completedUserTasksWidgetVM
  if (!vm) return null
  if (!vm.shouldRender) return null

  return (
    <CardHeader
      className='drag-handle'
      title={lzStrings.completedUserTasksWidget.completed_tasks}
      action={
        <Searchbar
          focused={vm.typedFilterText.length > 0}
          className='search'
          searchValue={vm.typedFilterText}
          onSearchChange={(e) => vm.setQuickFilter(e.target.value)}
        />
      }
    />
  )
}

export default inject('localizationStore', 'userTasksStore')(observer(WidgetHeader))
