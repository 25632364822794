import React, { FC } from 'react'
import { observer, inject } from 'mobx-react'
import './SurveyOptions.scss'
import { SurveysStore } from 'src/app/surveys/store/SurveysStore'
import { Switch } from '@material-ui/core'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import InfoTooltip from '../../../../shared/InfoTooltip'
import { LabelsStore } from '../../../../labels/store/LabelsStore'

interface Props {
  surveysStore?: SurveysStore
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
}

const AlwaysAvailableSurvey: FC<Props> = ({ surveysStore, localizationStore, labelsStore }) => {
  const surveyEditVM = surveysStore.viewModels.editVM
  const vm = surveyEditVM.surveyOptionsVM
  if (!surveyEditVM) return null
  if (!vm) return null
  const s = localizationStore.lzStrings.surveys

  return (
    <div className='section always-available-content'>
      <div className='primary-text includes-info'>
        {s.feedback}
        <InfoTooltip
          message={`${s.feedback} ${labelsStore.getLabelByLanguageAndFor(
            'pulses'
          )} are ${labelsStore.getLabelByLanguageAndFor('pulses').toLowerCase()} ${
            s.that_are_always
          }`}
        />
      </div>
      <div className='section-content'>
        <Switch
          disabled={surveyEditVM.surveyType && surveyEditVM.surveyType.isFeedbackType}
          checked={surveyEditVM.surveysAlwaysAvailable}
          onChange={() => surveyEditVM.toggleSurveysAreAlwaysAvailable()}
        />
      </div>
    </div>
  )
}

export default inject(
  'surveysStore',
  'localizationStore',
  'labelsStore'
)(observer(AlwaysAvailableSurvey))
