import { observer } from 'mobx-react'
import React from 'react'
import { MediaUploadPickerVM } from '../../../../media-items/view-models/media-upload-picker/MediaUploadPickerVM'
import MediaUploadPicker from '../../../../media-items/views/media-upload-picker/MediaUploadPicker'
import CheckIcon from '@material-ui/icons/Check'
import PublishIcon from '@material-ui/icons/Publish'
import { Tooltip, Button, IconButton, AppBar, Toolbar, Typography, Slide, Paper } from '@material-ui/core'

interface Props {
  vm: MediaUploadPickerVM
}

const TinyMCEMediaPicker: React.FC<Props> = ({ vm }) => {
  if (!vm) return null

  const renderMediaUploadButton = () => {
    if (!vm.showMediaUploadPicker) return null
    return (
      <Tooltip title={`Upload new media`} placement='bottom-start' enterDelay={500}>
        <IconButton onClick={() => vm.openDrawer()} className='media-upload-button'>
          <PublishIcon />
        </IconButton>
      </Tooltip>
    )
  }

  const renderMediaSelectButton = () => {
    if (!vm.showMediaUploadPicker) return null
    return (
      <Tooltip title={`Add selected media`} placement='bottom-start' enterDelay={500}>
        <span>
          <Button
            variant='contained'
            disabled={!vm.selectedMediaItemId}
            onClick={() => vm.addMedia()}
            startIcon={<CheckIcon />}
          >
            Add Media
          </Button>
        </span>
      </Tooltip>
    )
  }

  const renderMediaUploadPicker = () => {
    if (!vm.showMediaUploadPicker) return null
    return (
      <Slide direction={'left'} in={vm.showMediaUploadPicker}>
          <Paper id='BuilderDrawer' square elevation={6} >
            <AppBar color='default' position='sticky' elevation={0}>
              <Toolbar className={'media-toolbar'}>
                <Typography variant='h5' style={{ flexGrow: 1 }}>
                  Media Upload
                </Typography>
                {renderMediaUploadButton()}
                {renderMediaSelectButton()}
              </Toolbar>
            </AppBar>
            <MediaUploadPicker mediaUploadPickerVM={vm} />
          </Paper>
      </Slide>
    )
  }

  return (
    <>
     {renderMediaUploadPicker()}
    </>
  )
}
export default observer(TinyMCEMediaPicker)
