import { RootStore } from '../../../../stores/RootStore'
import { computed, action, observable } from 'mobx'
import { TrainingPlanItem } from '../../../aggregates/TrainingPlanItem'
import { Quiz } from '../../../../quiz/aggregate/Quiz'

export class CatalogPlanItemRowVM {
  private trainingPlanItem: TrainingPlanItem
  private rootStore: RootStore

  constructor(rootStore: RootStore, trainingPlanItem: TrainingPlanItem) {
    this.rootStore = rootStore
    this.trainingPlanItem = trainingPlanItem
  }

  @observable public isExpanded: boolean = false

  @computed
  public get title(): string {
    return this.trainingPlanItem.name
  }

  @computed
  public get quiz(): Quiz {
    return this.trainingPlanItem.quiz
  }

  @computed
  public get certificate(): boolean {
    return this.trainingPlanItem.requireCertificate
  }

  @computed
  public get contentItemNames(): string[] {
    const names = []
    this.trainingPlanItem.contentItems.forEach((item) => names.push(item.title))
    return names
  }

  @action
  public toggleIsExpanded() {
    this.isExpanded = !this.isExpanded
  }
}
