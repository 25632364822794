import { RootStore } from '../../stores/RootStore'
import Parse from 'parse'
import { observable, action, computed, runInAction } from 'mobx'
import { find, filter, findIndex } from 'lodash'
import PrivilegeSet from '../aggregate/PrivilegeSet'
import { PrivilegeSetsWidgetVM } from '../view-models/PrivilegeSetsWidgetVM'
import { PrivilegeSetDrawerVM } from '../view-models/PrivilegeSetDrawerVM'
import { IPrivilegeSetDTO } from '../dtos/IPrivilegeSetDTO'
import { DataStore } from '../../shared/data/DataStore'

export class PrivilegeSetsStore extends DataStore<PrivilegeSet, IPrivilegeSetDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, PrivilegeSet, 'privilegeSets', [
      'organizationId',
      'name',
      'description',
      'privileges',
    ])
  }

  @observable public selectedPrivilegeSetId: string
  @observable public privilegeSetsWidgetVM: PrivilegeSetsWidgetVM = null
  @observable public privilegeSetDrawerVM: PrivilegeSetDrawerVM = null
  @observable public showDrawer: boolean = false
  @observable public showDeleteDialog: boolean = false
  @observable public loaded: boolean = false

  @computed
  public get privilegeSets(): Array<PrivilegeSet> {
    return this.records
  }

  @action
  public onListRecordsLoaded() {
    this.privilegeSetsWidgetVM = new PrivilegeSetsWidgetVM(this.rootStore)
    this.loaded = true
  }

  @computed
  public get currentOrgPrivilegeSets(): Array<PrivilegeSet> {
    return this.privilegeSets
  }

  public getPrivilegeSet(objectId): PrivilegeSet {
    return this.privilegeSets.find(e => e.objectId === objectId)
  }

  public setSelectedPrivilegeSet(id) {
    this.selectedPrivilegeSetId = id
    this.privilegeSetDrawerVM = new PrivilegeSetDrawerVM(this.rootStore, this.getPrivilegeSet(id))
  }

  @action
  public setAddNewPrivilegeSet() {
    this.selectedPrivilegeSetId = null
    this.privilegeSetDrawerVM = new PrivilegeSetDrawerVM(this.rootStore)
  }

  @action
  public addResource(resource: PrivilegeSet) {
    this.privilegeSets.push(resource)
  }

  public deletePrivilegeSet(objectId) {
    this.privilegeSets.splice(this.getPrivilegeSetIndex(objectId), 1)
  }

  private getPrivilegeSetIndex(objectId): number {
    return this.privilegeSets.findIndex(e => e.objectId === objectId)
  }

  public getPrivilegeSetByName(name): PrivilegeSet {
    return this.privilegeSets.find(e => e.name === name)
  }

  public toggleDeleteDialog() {
    this.showDeleteDialog = !this.showDeleteDialog
  }

  public clearForms() {
    this.selectedPrivilegeSetId = ''
    this.privilegeSetDrawerVM = null
  }
}
