import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { OrganizationsStore } from '../../store/OrganizationsStore'
import { OutlinedInput, InputLabel, TextField, InputAdornment, IconButton } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

interface DrawerBasicInfoEditProps {
  localizationStore?: LocalizationStore
  organizationsStore?: OrganizationsStore
}

const DrawerBasicInfoEdit: React.FC<DrawerBasicInfoEditProps> = ({
  localizationStore,
  organizationsStore,
}) => {
  const { lzStrings } = localizationStore
  const { editVM: editForm } = organizationsStore
  if (editForm.currentTabIndex !== 0) return null

  return (
    <section>
      <h6>{lzStrings.organizationWidgetDrawer.basic_info}</h6>
      <form>
        <InputLabel htmlFor='name'>{lzStrings.organizationWidgetDrawer.org_name}</InputLabel>
        <OutlinedInput
          id='name'
          fullWidth
          labelWidth={0}
          autoComplete='off'
          value={editForm.name}
          onChange={(e) => editForm.setName(e.target.value)}
          error={!editForm.isNameValid}
        />
        <InputLabel htmlFor='adminEmail'>{lzStrings.organizationWidgetDrawer.org_email}</InputLabel>
        <OutlinedInput
          id='adminEmail'
          fullWidth
          labelWidth={0}
          autoComplete='off'
          value={editForm.adminEmail}
          onChange={(e) => editForm.setAdminEmail(e.target.value)}
          error={!editForm.isAdminEmailValid}
        />
      </form>
    </section>
  )
}

export default inject('localizationStore', 'organizationsStore')(observer(DrawerBasicInfoEdit))
