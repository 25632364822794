import React from 'react'
import { inject, observer } from 'mobx-react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { SurveysStore } from '../../../store/SurveysStore'
import QuestionCard from './QuestionCard'
import './QuestionsList.scss'

interface QuestionsListProps {
  surveysStore?: SurveysStore
}

const SortableItem: any = SortableElement(({ value }) => (
  <div style={{ zIndex: 99999999 }} className='question-draggable-item'>
    {value}
  </div>
))

const SortableList: any = SortableContainer(({ items }) => {
  return (
    <div>
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} value={value} />
      ))}
    </div>
  )
})

const QuestionsList = ({ surveysStore }: QuestionsListProps) => {
  const { editVM } = surveysStore.viewModels
  if (!editVM) return null

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (editVM.isReadOnly) return
    editVM.setOldIndex(oldIndex)
    editVM.setNewIndex(newIndex)
    editVM.moveQuestion()
  }

  const questions = editVM.questions.map((value, index) => (
    <QuestionCard key={`item-${index}`} index={index} question={value} />
  ))

  return (
    <div id='QuestionsList'>
      <SortableList
        disabled={editVM.isReadOnly}
        distance={3}
        items={questions}
        onSortEnd={onSortEnd}
        shouldCancelStart={() => editVM.isReadOnly}
      />
    </div>
  )
}

export default inject('surveysStore')(observer(QuestionsList))
