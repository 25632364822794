import { computed, action, reaction, when, observable } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { WidgetRowVM } from './WidgetRowVM'
import { ColDef, GridOptions, RowClickedEvent } from 'ag-grid-community'
import { AGGridVM } from '../../../shared/ag-grid/AGGridVM'
import { nameOf } from '../../../shared/nameOf'
import { OrganizationUsersWidgetDataStore } from './OrganizationUsersWidgetDataStore'
import { UserImportVM } from '../import/UserImportVM'
import { OrganizationUser } from '../../aggregate/OrganizationUser'
import { ActiveTabVM } from '../../view-models/widget/ActiveTabVM'
import { ArchivedTabVM } from '../../view-models/widget/ArchivedTabVM'

export class OrganizationUsersWidgetVM extends AGGridVM {
  private rootStore: RootStore
  private dataStore: OrganizationUsersWidgetDataStore
  public importVM: UserImportVM

  constructor(rootStore: RootStore) {
    super()
    this.rootStore = rootStore
    this.importVM = new UserImportVM(this.rootStore)
    this.serverSideLoaded = true
    this.loadTabs()
  }

  @observable public tabIndex: number = 0
  @observable public activeTab: ActiveTabVM
  @observable public archivedTab: ArchivedTabVM
  @observable public shouldShowHiddenUsers: boolean = false

  private loadTabs() {
    this.activeTab = new ActiveTabVM(this.rootStore, this, 0)
    this.archivedTab = new ArchivedTabVM(this.rootStore, this, 1)
  }

  @action
  public setTabIndex(val: number) {
    this.tabIndex = val
  }

  public getRowNodeId(row: WidgetRowVM): string {
    return row.objectId
  }

  public onRecordUpdated(obj: OrganizationUser) {
    const rowNode = this.gridApi.getRowNode(obj.objectId)
    if (!rowNode) return
    rowNode.setData(new WidgetRowVM(this.rootStore, obj, this))
  }

  @computed
  public get shouldRender(): boolean {
    if (!this.dataStore) return false
    return true
  }

  @action
  public openNewUser() {
    this.rootStore.organizationUsersStore.loadEditVMForNewUser()
  }

  @action
  public openImportModal() {
    this.rootStore.appStore.router.push('dashboard/tenantAdmin/userImport')
  }

  @computed
  public get columnDefs(): ColDef[] {
    const s = this.rootStore.localizationStore.lzStrings
    return [
      {
        headerName: s.orgUsersTableWidget.name,
        field: nameOf<WidgetRowVM, string>((e) => e.sortName),
        sort: 'asc',
        cellRenderer: 'nameCell',
      },
      {
        headerName: s.orgUsersTableWidget.email,
        field: nameOf<WidgetRowVM, string>((e) => e.email),
      },
      {
        headerName: s.orgUsersTableWidget.title,
        field: nameOf<WidgetRowVM, string>((e) => e.title),
      },
      {
        headerName: s.orgUsersTableWidget.roles,
        field: nameOf<WidgetRowVM, string>((e) => e.roleNames),
        sortable: false,
      },
      {
        headerName: s.orgUsersTableWidget.groups,
        field: nameOf<WidgetRowVM, string>((e) => e.groupNames),
        sortable: false,
      },
    ]
  }

  public getGridOptions(): GridOptions {
    return {
      ...this.baseGridOptions,
      onRowClicked: (e) => this.rowClicked(e),
      getRowNodeId: (e) => this.getRowNodeId(e),
      columnDefs: this.ensureTooltipFields(this.columnDefs),
    }
  }

  @action
  public setQuickFilter(val: string) {
    this.typedFilterText = val
    if (this.quickFilterTO) clearTimeout(this.quickFilterTO)
    this.quickFilterTO = setTimeout(() => this.applyFilter(), 1000)
  }

  @action
  public toggleViewHiddenUsers() {
    this.shouldShowHiddenUsers = !this.shouldShowHiddenUsers
    this.setHiddenUserBools()
  }

  private setHiddenUserBools() {
    this.activeTab.setShouldShowHiddenUsers(this.shouldShowHiddenUsers)
    this.archivedTab.setShouldShowHiddenUsers(this.shouldShowHiddenUsers)
  }

  private applyFilter() {
    this.dataStore.setFilter(this.typedFilterText)
    this.reload()
    this.columnApi.applyColumnState({
      state: [{ colId: 'sortName', sort: 'asc' }],
    })
  }

  protected onGridReadied() {
    this.gridApi.setDatasource({
      rowCount: this.totalRecords,
      getRows: async (params) => {
        params.sortModel.forEach((col: { colId: string; sort: 'asc' | 'desc' }, idx: number) => {
          let dbCol = col.colId
          if (dbCol === 'sortName') dbCol = 'fk_User.lastName'
          if (dbCol === 'email') dbCol = 'fk_User.email'
          if (idx === 0) this.dataStore.setSort(dbCol, col.sort)
          if (idx > 0) this.dataStore.addSort(dbCol, col.sort)
        })
        if (params.sortModel.length === 0) this.dataStore.setSort('fk_User.lastName')
        if (params.startRow === 0) this.dataStore.setPage(0)
        await this.dataStore.getNextPage()
        const rows = this.dataStore.organizationUsers.map(
          (e) => new WidgetRowVM(this.rootStore, e, this)
        )
        params.successCallback(rows, this.dataStore.totalRecords)
      },
    })
  }

  public get rows(): WidgetRowVM[] {
    return []
  }

  public rowClicked(e: RowClickedEvent) {
    const row: WidgetRowVM = e.data
    if (!row) return
    row.click()
  }

  @computed
  protected get totalRecords() {
    if (!this.dataStore) return 0
    return this.dataStore.totalRecords
  }
}
