import { RootStore } from '../../stores/RootStore'
import { observable, computed, action } from 'mobx'
import { TrainingPlan } from '../../training-plans/aggregates/TrainingPlan'
import { TrainingPlanItem } from '../../training-plans/aggregates/TrainingPlanItem'
import { TrainingPlanEditVM } from './TrainingPlanEditVM'
import { Goal } from '../../goals/aggregate/Goal'
import moment from 'moment'

export class GoalPreviewVM {
  private editVM: TrainingPlanEditVM
  public rootStore: RootStore
  public goal: Goal

  constructor(rootStore: RootStore, editVM: TrainingPlanEditVM, goal: Goal) {
    this.editVM = editVM
    this.rootStore = rootStore
    this.goal = goal
  }

  @observable public expandedPreviewItemId: string = ''

  @computed
  public get name(): string {
    return this.goal.name
  }

  @computed
  public get dueDate(): string {
    const s = this.rootStore.localizationStore.lzStrings.goals
    return moment(this.goal.endDate).format(s.due_date_format)
  }

  @computed
  public get thumbnail(): string {
    return this.goal.thumbnail
  }

  @computed
  public get description(): string {
    return this.goal.description
  }

  @computed
  public get category(): string {
    const category = this.rootStore.categoriesStore.getCategory(this.goal.categoryId)
    return category.name
  }
}
export default GoalPreviewVM
