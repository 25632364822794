import React from 'react'
import { Route } from 'react-router'
import Layout from '../../../layout/Layout'
import { authenticate } from '../../../auth/routes/AuthenticatedRoute'
import UserTrainingPlanTakeScreen from '../views/take/UserTrainingPlanTakeScreen'
import PlanNotFound from '../../views/plan-not-found/PlanNotFound'

export default function getUserTrainingPlanRoutes() {
  return (
    <Route key={'us0'} path='userTrainingPlans' component={authenticate(Layout)}>
      <Route key={'us1'} path='take/:id' component={UserTrainingPlanTakeScreen} />
      <Route key={'us1'} path='take/:id/:orgId' component={UserTrainingPlanTakeScreen} />
      <Route key={'us3'} path='view/:id' component={UserTrainingPlanTakeScreen} />
      <Route key={'us2'} path='completed/:responseId' component={UserTrainingPlanTakeScreen} />
      <Route
        key={'us4'}
        path='take/:id/foritem/:trainingItemId'
        component={UserTrainingPlanTakeScreen}
      />
      <Route key={'us5'} path='take/:id/forquiz/:quizId' component={UserTrainingPlanTakeScreen} />
      <Route key={'us6'} path='take/:id/forcert/:certId' component={UserTrainingPlanTakeScreen} />
      <Route key={'us7'} path='404' component={PlanNotFound} />
    </Route>
  )
}
