import { computed, action, observable } from 'mobx'
import { PulseCampaignEditVM } from '../../pulse-campaigns/view-models/PulseCampaignEditVM'
import { RootStore } from '../../stores/RootStore'

export class PulseCampaignEditSteppersVM {
  rootStore: RootStore
  pulseCampaignEditVM: PulseCampaignEditVM
  constructor(rootStore: RootStore, pulseCampaignEditVM: PulseCampaignEditVM) {
    this.rootStore = rootStore
    this.pulseCampaignEditVM = pulseCampaignEditVM
  }

  @observable public detailsVisited: boolean = true
  @observable public focusVisited: boolean = false
  @observable public buildVisited: boolean = false
  @observable public audienceVisited: boolean = false
  @observable public scheduleVisited: boolean = false
  @observable public initiateVisited: boolean = false
  @observable public detailsCompleted: boolean = false
  @observable public focusCompleted: boolean = false
  @observable public buildCompleted: boolean = false
  @observable public audienceCompleted: boolean = false
  @observable public scheduleCompleted: boolean = false
  @observable public initiateCompleted: boolean = false
  @observable public initiatedCompleted: boolean = false

  @computed
  public get showSteppers() {
    return (
      this.isNewPulseCampaign ||
      (!this.isNewPulseCampaign && this.pulseCampaignEditVM.pulseCampaignWasJustPublished)
    )
  }

  @computed
  public get allCompleted() {
    return (
      this.detailsCompleted &&
      this.focusCompleted &&
      this.buildCompleted &&
      this.audienceCompleted &&
      this.scheduleCompleted &&
      this.initiatedCompleted
    )
  }

  @computed
  public get index() {
    return this.pulseCampaignEditVM.currentTabIndex
  }

  @computed
  public get isTemplate() {
    return this.pulseCampaignEditVM.isTemplate
  }

  @computed
  public get isPreview() {
    return this.pulseCampaignEditVM.isPreview
  }

  @computed
  public get isPublished() {
    return this.pulseCampaignEditVM.isPublished
  }

  @computed
  public get isNewPulseCampaign() {
    return this.pulseCampaignEditVM.isNewPulseCampaign
  }

  @computed
  public get pulseCampaignType() {
    return this.pulseCampaignEditVM.pulseCampaignType
  }

  @computed
  public get publishTried() {
    return this.pulseCampaignEditVM.publishTried
  }

  @computed
  public get detailsValid() {
    return Boolean(this.pulseCampaignEditVM.name.trim())
  }

  @computed
  public get templatesValid() {
    return Boolean(this.pulseCampaignEditVM.pulseCampaign)
  }

  @computed
  public get buildValid() {
    const invalidSurveys = []
    this.pulseCampaignEditVM.surveys.map((e) => {
      if (!e.allValid) {
        invalidSurveys.push(e.allValid)
      }
    })
    if (invalidSurveys.length) return false
    return true
  }

  @computed
  public get audienceValid() {
    const participants = this.pulseCampaignEditVM.participantsSelectVM.participants
    const anonymousParticipants =
      this.pulseCampaignEditVM.participantsSelectVM.anonymousParticipants
    return participants.length > 0 || anonymousParticipants.length > 0
  }

  @computed
  public get scheduleValid() {
    return this.pulseCampaignEditVM.scheduleValid
  }

  @computed
  public get initiateValid() {
    return true
  }

  @computed
  public get detailsError() {
    return this.publishTried && !this.detailsValid
  }

  @computed
  public get buildError() {
    return this.publishTried && !this.buildValid
  }

  @computed
  public get audienceError() {
    return this.publishTried && !this.audienceValid
  }

  @computed
  public get scheduleError() {
    return this.publishTried && !this.scheduleValid
  }

  @computed
  public get participantsError() {
    return this.publishTried && !this.audienceValid
  }

  @computed
  public get initateError() {
    return this.publishTried && !this.initiateVisited
  }

  @computed
  public get templateError() {
    return this.publishTried && !this.templatesValid
  }

  @action
  public updateSteppers(newIndex: number, previousIndex: number = null) {
    if (newIndex === 1) this.detailsVisited = true
    if (newIndex === 2) this.focusVisited = true
    if (newIndex === 3) this.buildVisited = true
    if (newIndex === 4) this.audienceVisited = true
    if (newIndex === 5) this.scheduleVisited = true
    if (newIndex === 6) this.initiateVisited = true

    if (newIndex > 0 && this.detailsVisited) {
      if (this.detailsValid) {
        this.detailsCompleted = true
      } else {
        this.detailsCompleted = false
      }
    }

    if (newIndex > 1 && this.focusVisited) {
      this.focusCompleted = true
    }

    if (newIndex > 2 && this.buildVisited) {
      if (this.buildValid) {
        this.buildCompleted = true
      } else {
        this.buildCompleted = false
      }
    }

    if (newIndex > 3 && this.audienceVisited) {
      if (this.audienceValid) {
        this.audienceCompleted = true
      } else {
        this.audienceCompleted = false
      }
    }

    if (newIndex > 4 && this.scheduleVisited) {
      this.scheduleCompleted = true
    }

    if (newIndex > 5 && this.initiateVisited) {
      if (this.initiateValid) {
        this.initiateCompleted = true
      } else {
        this.initiateCompleted = false
      }
    }
  }
}
