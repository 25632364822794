import { action, computed, observable } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { ChildCategoryRowVM } from './ChildCategoryRowVM'
import { IPulseCategoryDTO } from '../../../pulse-categories/dtos/IPulseCategoryDTO'
import { IPulseCategoriesFindRequest } from '../../../pulse-categories/interfaces/IPulseCategoriesFindRequest'
import { PulseCategoriesDataStore } from '../../../pulse-categories/view-models/PulseCategoriesDataStore'
import { PulseCategoriesManageVM } from '../PulseCategoriesManageVM'

export class ParentCategoryRowVM {
  private rootStore: RootStore

  constructor(
    rootStore: RootStore,
    category: IPulseCategoryDTO,
    grandParentName: string,
    manageVM: PulseCategoriesManageVM
  ) {
    this.rootStore = rootStore
    this.category = category
    this.grandParentName = grandParentName
    this.manageVM = manageVM
    this.getChildPulseCategories()
  }

  @observable public category: IPulseCategoryDTO = null
  @observable public isExpanded: boolean = false
  @observable public childPulseCategories: IPulseCategoryDTO[] = []
  @observable public manageVM: PulseCategoriesManageVM = null
  @observable public index: number = 0
  @observable public categoryVMs: ChildCategoryRowVM[] = []
  @observable public childPulseCategoryRows: ChildCategoryRowVM[] = []
  @observable public grandParentName: string = ''

  @computed
  public get isBuild() {
    return this.manageVM.isBuild
  }

  @computed
  public get isLoaded(): boolean {
    return this.manageVM.dataStore.isLoaded
  }

  @computed
  public get name(): string {
    return this.category.name
  }

  @computed
  public get objectId(): string {
    return this.category.objectId
  }

  @computed
  public get grandParentPulseCategoryId(): string {
    return this.category.grandCategoryId
  }

  @action
  public setIndex(index: number) {
    this.index = index
  }

  @action
  public toggleIsExpanded(isExpanded: boolean) {
    this.isExpanded = isExpanded
  }

  @action
  public async getChildPulseCategories() {
    // await this.dataStore.loadListRecords()
    this.loadChildPulseCategoryRows()
  }

  @computed
  public get hierarchyString(): string {
    const string = `${this.grandParentName} - ${this.name}`
    return string
  }

  @action
  private loadChildPulseCategoryRows() {
    const categories = this.manageVM.dataStore.rows.filter(
      (c: IPulseCategoryDTO) => c.parentCategoryId === this.objectId
    )
    this.childPulseCategoryRows = categories.map((c) => {
      let foundVM = this.categoryVMs.find((cVM) => c.objectId === cVM.objectId)
      if (!foundVM) {
        foundVM = new ChildCategoryRowVM(this.rootStore, c, this.hierarchyString, this.manageVM)
        foundVM.setIndex(this.index)
        this.addCategoryVM(foundVM)
      }
      return foundVM
    })
  }

  @action
  private addCategoryVM(categoryVM: ChildCategoryRowVM) {
    this.categoryVMs.push(categoryVM)
  }

  @computed
  public get childPulseQuestionCount(): number {
    return this.childPulseCategoryRows
      .map((cc) => cc.pulseQuestionsCount)
      .reduce((acc, val) => acc + val, 0)
  }

  @computed
  public get childPulseSelectedQuestionCount(): number {
    return this.childPulseCategoryRows
      .map((cc) => cc.pulseSelectedQuestionsCount)
      .reduce((acc, val) => acc + val, 0)
  }

  @computed
  public get currentSurveyChildPulseSelectedQuestionCount(): number {
    return this.childPulseCategoryRows
      .map((cc) => cc.currentSurveyPulseSelectedQuestionsCount)
      .reduce((acc, val) => acc + val, 0)
  }

  @computed
  public get areAllQuestionsSelected() {
    return (
      this.childPulseCategoryRows.filter(
        (c) => c.pulseQuestionsCount > 0 && !c.areAllQuestionsSelected
      ).length === 0
    )
  }

  @computed
  public get areAllExpanded() {
    return (
      this.childPulseCategoryRows.filter((c) => c.pulseQuestionsCount > 0 && !c.isExpanded)
        .length === 0
    )
  }

  @action
  public selectRecursiveCategoryQuestions() {
    this.childPulseCategoryRows.forEach((r) => {
      r.selectRecursiveCategoryQuestions()
    })
  }

  @action
  public unselectRecursiveCategoryQuestions() {
    this.childPulseCategoryRows.forEach((r) => {
      r.unselectRecursiveCategoryQuestions()
    })
  }

  @action
  public toggleVisibilityForRecursiveCategories(isExpanded: boolean) {
    this.toggleIsExpanded(isExpanded)
    this.childPulseCategoryRows.forEach((r) => {
      r.toggleVisibilityForRecursiveCategories(isExpanded)
    })
  }
}
