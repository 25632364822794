import React from 'react'
import { inject, observer } from 'mobx-react'
import { Card } from '@material-ui/core'
import rootStore from '../../stores/RootStore'
import LocalizationStore from '../../localization/LocalizationStore'
import MuiConfirmPrompt from '../../shared/MuiConfirmPrompt'
import ContentLayoutGrid from './CMSItemsLayoutGrid'
import ContentLayoutTopToolbar from './CMSItemsLayoutTopToolbar'
import CMSItemsPreviewDialog from './dialogs/CMSItemsPreviewDialog'
import CMSItemsUploadDialog from './dialogs/CMSItemsUploadDialog'
import ContentConfirmDialog from './dialogs/CMSItemsConfirmDialog'
import ContentLayoutTable from './CMSItemsLayoutTable'
import { CMSItemsPickerVM } from '../view-models/CMSItemsPickerVM'
import './CMSItemsLayout.scss'

interface Props {
  vm: CMSItemsPickerVM
  localizationStore?: LocalizationStore
}

const CMSItemsLayout: React.FC<Props> = ({ vm }) => {
  if (!vm) return

  if (!vm.manageVM) return

  const renderLayout = () => {
    if (!vm.manageVM.isLayoutGrid) return renderTable()
    return renderGrid()
  }

  const renderTable = () => {
    return (
      <Card elevation={0}>
        <ContentLayoutTable
          manageVM={vm.manageVM}
          localizationStore={rootStore.localizationStore}
        />
      </Card>
    )
  }

  const renderGrid = () => {
    return <ContentLayoutGrid vm={vm} />
  }

  const renderPreviewDialog = () => {
    return (
      <CMSItemsPreviewDialog
        fileTypes={vm.manageVM.fileTypes}
        toggleShowModal={() => vm.previewVM.toggleShowPreviewDialog()}
        isOpen={vm.previewVM.isOpen}
        vm={vm.previewVM}
        localizationStore={rootStore.localizationStore}
        rootStore={rootStore}
        addAfterSave={() => console.debug('preview add after save')}
      />
    )
  }

  const renderUploadDialog = () => {
    return (
      <CMSItemsUploadDialog
        fileTypes={vm.manageVM.fileTypes}
        toggleShowModal={() => vm.uploadVM.toggleShowUploadDialog()}
        isOpen={vm.uploadVM.isOpen}
        vm={vm.uploadVM}
        localizationStore={rootStore.localizationStore}
        rootStore={rootStore}
        addAfterSave={() => console.debug('upload add after save')}
      />
    )
  }

  const renderContentConfirm = () => {
    return (
      <ContentConfirmDialog vm={vm.previewVM} localizationStore={rootStore.localizationStore} />
    )
  }

  const renderConfirmDeleteDialog = () => {
    const item = vm.previewVM.item
    if (!item) return
    return (
      <MuiConfirmPrompt
        confirmMessage={`Are you sure you want to delete this content item (${item.title})? This action cannot be undone!`}
        onClose={() => vm.previewVM.hideDeleteConfirmDialog()}
        open={vm.previewVM.isConfimDeleteDialogOpen}
        onConfirm={() => vm.previewVM.handleDelete(item.itemId)}
        title={`Warning`}
      />
    )
  }

  return (
    <div id='ContentLayout'>
      <div className='content-layout-container'>
        <ContentLayoutTopToolbar vm={vm.manageVM} />
        {renderLayout()}
        {renderPreviewDialog()}
        {renderUploadDialog()}
        {renderContentConfirm()}
        {renderConfirmDeleteDialog()}
      </div>
    </div>
  )
}

export default inject('localizationStore')(observer(CMSItemsLayout))
