import { observable, computed, action } from 'mobx'
import { RootStore } from '../../../stores/RootStore'

export class PulseCampaignsTopToolbarVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }


  @action
  public setSelectedCampaignsRoute(index: number) {
    if (index === 0) this.rootStore.appStore.router.push('/pulse/v1/campaigns')
    if (index === 1) this.rootStore.appStore.router.push('/pulse/v1/campaignTemplates')
  }

  @computed
  public get isCampaignsSelected(): boolean {
    return this.rootStore.appStore.currentRoute.includes('campaigns')
  }

  @computed
  public get isCampaignTemplatesSelected(): boolean {
    return this.rootStore.appStore.currentRoute.includes('campaigntemplates')

  }
  
}
