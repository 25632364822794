import { ChartType } from '../types/ChartType'
import { observable, action, computed } from 'mobx'
import { serializable, serialize, deserialize, list, object, date } from 'serializr'
import { IWorksheetDTO } from '../dtos/IWorksheetDTO'
import { Participant } from './Participant'
import { IAggregate } from '../../shared/data/IAggregate'
import env from '../../../env'

export class Worksheet implements IWorksheetDTO, IAggregate {
  public static create(orgId: string, tableauSiteName: string) {
    const sht = new Worksheet()
    sht.organizationId = orgId
    sht.name = ''
    sht.tableauSiteName = tableauSiteName
    sht.isNew = true
    sht.surveyTypeId = ''
    return sht
  }

  @serializable @observable public objectId: string = ''
  @serializable @observable public organizationId: string = ''
  @serializable @observable public chartTypeId: ChartType = 'tableau'
  @serializable @observable public name: string = ''
  @serializable @observable public forSurveys: boolean = false
  @serializable @observable public forPulseCampaigns: boolean = false
  @serializable @observable public defaultPulseCampaignTemplate: boolean = false
  @serializable @observable public forLoggedInUser: boolean = false
  @serializable @observable public showTabs: boolean = false
  @serializable @observable public surveyId: string = ''
  @serializable @observable public pulseCampaignId: string = ''
  @serializable @observable public surveyTypeId: string = ''
  @serializable @observable public isDeleted: boolean = false
  @serializable @observable public tableauWorkbook: string = ''
  @serializable @observable public tableauWorksheet: string = ''
  @serializable @observable public tableauSiteName: string = ''
  @serializable(date()) @observable public createdAt: Date
  @serializable(date()) @observable public updatedAt: Date
  @serializable @observable public tableauServerUrl: string =
    env.var.REACT_APP_TABLEAU_SERVER_URL + '/'
  @serializable @observable public owner: string = ''
  @observable private loggedInUserId: string = ''
  @serializable(list(object(Participant))) @observable visibleTo: Array<Participant> = []
  public isOnServer: boolean = false
  public isNew: boolean = false

  @action
  public setName(val) {
    this.name = val
  }

  @action
  public setSurvey(val) {
    this.surveyId = val
  }

  @action
  public setPulseCampaign(val) {
    this.pulseCampaignId = val
  }

  @action
  public setLoggedInUser(val) {
    this.loggedInUserId = val
  }

  @action
  public setSurveyTypeId(id) {
    this.surveyTypeId = id
  }

  @action
  public setOwner(id) {
    this.owner = id
  }

  @computed
  public get tableauWorksheetDisplayName() {
    return this.tableauWorksheet.replace(this.tableauWorkbook, '').replace('/sheets/', '')
  }

  @action
  public toggleForSurveys() {
    this.forSurveys = !this.forSurveys
  }

  @action
  public toggleForPulseCampaigns() {
    this.forPulseCampaigns = !this.forPulseCampaigns
    if (!this.forPulseCampaigns && this.defaultPulseCampaignTemplate) {
      this.defaultPulseCampaignTemplate = false
    }
  }

  @action
  public toggleDefaultPulseCampaignTemplate() {
    this.defaultPulseCampaignTemplate = !this.defaultPulseCampaignTemplate
  }

  @action
  public toggleForLoggedInUser() {
    this.forLoggedInUser = !this.forLoggedInUser
  }

  @action
  public toggleShowTabs() {
    this.showTabs = !this.showTabs
  }

  @action
  public setChartType(val: ChartType) {
    this.chartTypeId = val
  }

  @action
  public setTableauWorkbook(val) {
    this.tableauWorkbook = val
  }

  @action
  public setTableauWorksheet(val) {
    val = val.replace(' ', '')
    val = val.replace(' ', '')
    val = val.replace(' ', '')
    val = val.replace(' ', '')
    val = val.replace(' ', '')
    val = val.replace(' ', '')
    val = val.replace(' ', '')
    val = val.replace(' ', '')
    this.tableauWorksheet = val
  }

  public markIsNotOnServer() {
    this.isOnServer = false
  }

  public markIsOnServer() {
    this.isOnServer = true
  }

  @computed
  public get hasTableauWorkbook(): boolean {
    return this.tableauWorkbook && this.tableauWorkbook !== ''
  }

  @computed
  public get tableauViewUrl(): string {
    return (
      this.tableauServerUrl +
      (this.tableauSiteName !== '' ? 't/' + this.tableauSiteName : '') +
      '/views/' +
      this.tableauWorkbook +
      '/' +
      this.tableauWorksheet.replace(this.tableauWorkbook, '').replace('/sheets/', '')
    )
  }

  private getSurveyFilter() {
    if (!this.surveyId) return ''
    return '&RippleSurveyId=' + this.surveyId + '&Ripple Survey Id=' + this.surveyId
  }

  private getPulseCampaignFilter() {
    if (!this.pulseCampaignId) return ''
    return (
      '&RipplePulseCampaignId=' +
      this.pulseCampaignId +
      '&Ripple Pulse Campaign Id=' +
      this.pulseCampaignId
    )
  }

  private getLoggedInUserFilter() {
    if (!this.forLoggedInUser) return ''
    if (!this.loggedInUserId) return ''
    return (
      '&ViewingUserId=' +
      this.loggedInUserId +
      '&[Parameters].[ViewingUserId]=' +
      this.loggedInUserId
    )
  }

  @computed
  public get tableauQuery(): string {
    const q =
      '?:embed=yes&:comments=no&:toolbar=no&:refresh=yes' +
      this.getSurveyFilter() +
      this.getPulseCampaignFilter() +
      this.getLoggedInUserFilter()
    return q
  }

  public updateFromServer(fromObject: any) {
    if (!fromObject.User) fromObject.User = {}
    this.name = fromObject.name
    this.isDeleted = fromObject.isDeleted
    this.visibleTo = fromObject.visibleTo
    this.createdAt = new Date(fromObject.createdAt)
    this.updatedAt = new Date(fromObject.updatedAt)
    this.chartTypeId = fromObject.chartTypeId
    this.forLoggedInUser = fromObject.forLoggedInUser
    this.forSurveys = fromObject.forSurveys
    this.organizationId = fromObject.organizationId
    this.owner = fromObject.owner ? fromObject.owner : ''
    this.showTabs = fromObject.showTabs
    this.surveyId = fromObject.surveyId
    this.surveyTypeId = fromObject.surveyTypeId
    this.tableauSiteName = fromObject.tableauSiteName
    this.tableauWorkbook = fromObject.tableauWorkbook
    this.tableauWorksheet = fromObject.tableauWorksheet
    this.tableauServerUrl = fromObject.tableauServerUrl
    this.isOnServer = true
  }

  public serialize(): IWorksheetDTO {
    return serialize(this)
  }

  public clone(): Worksheet {
    return deserialize(Worksheet, this.serialize())
  }
}
