import React from 'react'
import {
  Button,
  Grid,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  OutlinedInput,
  Typography,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete'
import { PulseCampaignEditVM } from '../../view-models/PulseCampaignEditVM'
import { inject, observer } from 'mobx-react'
import PulseCampaignType from '../../../pulse-campaign-types/aggregate/PulseCampaignType'
import './PulseCampaignDetailsColLeft.scss'

interface Props {
  editVM: PulseCampaignEditVM
}

const PulseCampaignDetailsColLeft: React.FC<Props> = ({ editVM }) => {
  const renderOwnerDialog = () => {
    return (
      <Dialog className='owner-dialog' open={editVM.ownerDialogOpen} maxWidth={'md'}>
        <DialogTitle>Change Pulse Campaign Owner</DialogTitle>
        <DialogContent className='owner-dialog-content-sm'>
          <Autocomplete
            disabled={false}
            options={editVM.availableOwnerOptions}
            getOptionSelected={(option, val) => option.userId === val.userId}
            getOptionLabel={(e) => e.name}
            value={editVM.ownerUser}
            onChange={(e, val) => {
              editVM.setTempOwnerUser(val ? val.userId : null)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder='Select...'
                variant='outlined'
                className={'publisher-select'}
                style={{ width: 200 }}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => editVM.toggleShowOwnerDialog()}>
            {/* {slz.cancel} */}
            Cancel
          </Button>
          <Button
            disabled={!editVM.tempOwnerUserId}
            variant='contained'
            onClick={() => editVM.changeCampaignOwner()}
          >
            {/* {slz.update} */}
            Update
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const renderChangeButton = () => {
    return (
      <Button variant='outlined' onClick={() => editVM.openChangeOwnerDialog()}>
        {/* {slz.change} */}
        Change
      </Button>
    )
  }

  const renderChangeOwnerField = () => {
    return (
      <Grid item>
        <div className='input-div owner-input'>
          <OutlinedInput
            id='pulse-owner-text'
            className='owner-name'
            value={editVM.ownerUser.name}
            readOnly={true}
          />
          {renderChangeButton()}
          {renderOwnerDialog()}
        </div>
      </Grid>
    )
  }

  return (
    <div id='PulseCampaignDetailsColLeft'>
      <Grid
        container
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-start'
        className={'details-grid'}
      >
        <Grid item className={'details-grid-header'}>
          <Grid
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='flex-start'
            className={'details-grid-field-wrapper'}
          >
            <Typography component='span' className='details-grid-header-title'>
              {/* {lz.pulse_campaign + ' ' + lz.type} */}
              Pulse Campaign Title
            </Typography>
          </Grid>
        </Grid>
        <Grid item className={'details-grid-header'}>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='flex-start'
            className={'details-grid-field-wrapper'}
          >
            <TextField
              fullWidth
              autoComplete='off'
              placeholder='(Untitled Pulse Campaign)'
              value={editVM.name}
              error={editVM.publishTried && !editVM.nameValid}
              className={'input-div'}
              onChange={(e) => editVM.setName(e.target.value)}
              variant='outlined'
            />
          </Grid>
        </Grid>
        <Grid item className={'details-grid-header'}>
          <Grid
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='flex-start'
            className={'details-grid-field-wrapper'}
          >
            <Typography component='span' className='details-grid-header-title'>
              {/* {lz.pulse_campaign + ' ' + lz.type} */}
              Pulse Campaign Type
            </Typography>
          </Grid>
        </Grid>
        <Grid item className={'details-grid-header'}>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='flex-start'
            className={'details-grid-field-wrapper'}
          >
            <Grid container>
              <div className='input-div'>
                <Autocomplete
                  className='survey-type toolbar-input'
                  options={editVM.pulseCampaignTypes}
                  getOptionLabel={(e) => e.name}
                  getOptionSelected={(option, val) => option.name === val.name}
                  value={editVM.pulseCampaignType}
                  onChange={(e, val: PulseCampaignType) => {
                    editVM.setPulseCampaignType(val ? val.objectId : null)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // placeholder={lz.select + ' ' + lz.pulse_campaign + ' ' + lz.type}
                      placeholder='Pulse Campaign Type'
                      variant='outlined'
                      className='survey-type-input'
                    />
                  )}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={'details-grid-header'}>
          <Grid
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='flex-start'
            className={'details-grid-field-wrapper'}
          >
            <Typography component='span' className='details-grid-header-title'>
              {/* {lz.owner} */}
              Owner
            </Typography>
          </Grid>
        </Grid>
        <Grid item className={'details-grid-header'}>
          <Grid
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='flex-start'
            className={'details-grid-field-wrapper'}
          >
            {renderChangeOwnerField()}
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default inject('pulseCampaignsStore')(observer(PulseCampaignDetailsColLeft))
