import React from 'react'
import { inject, observer } from 'mobx-react'
import { PulseCampaignsStore } from '../../store/PulseCampaignsStore'
import { PulseCampaignEditTabsEnum } from '../../type/PulseCampaignEditTabsEnum'
import FocusStep from '../steppers/FocusStep'
import BuildStep from '../steppers/BuildStep'
import AudienceStep from '../steppers/AudienceStep'
import ScheduleStep from '../steppers/ScheduleStep'
import InitiateStep from '../steppers/InitiatedStep'
import InitiatedStep from '../steppers/InitiatedStep'
import TemplateStep from '../steppers/TemplateStep'
import './PulseCampaignEditStepView.scss'
import DetailsStep from '../steppers/DetailsStep'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div hidden={value !== index} {...other} className='survey-edit-tab-panel-container'>
      {children}
    </div>
  )
}

interface Props {
  pulseCampaignsStore?: PulseCampaignsStore
}

const TabsBar: React.FC<Props> = ({ pulseCampaignsStore }) => {
  const { editVM } = pulseCampaignsStore.viewModels
  const vm = editVM
  if (!vm) return null

  const manageVM = editVM.manageVM
  const buildVM = editVM.buildVM
  const templatesVM = editVM.templatesVM

  const DETAILS = vm.isPreview ? 1 : 0

  return (
    <>
      <div id='PulseCampaignEditStepView'>
        {vm.isPreview && (
          <TabPanel
            key={PulseCampaignEditTabsEnum.TEMPLATES}
            value={vm.currentTabIndex}
            index={PulseCampaignEditTabsEnum.TEMPLATES}
          >
            <TemplateStep buildVM={buildVM} templatesVM={templatesVM} />
          </TabPanel>
        )}
        <TabPanel key={DETAILS} value={vm.currentTabIndex} index={DETAILS}>
          <DetailsStep buildVM={buildVM} />
        </TabPanel>
        {!vm.isPreview && (
          <TabPanel
            key={PulseCampaignEditTabsEnum.FOCUS}
            value={vm.currentTabIndex}
            index={PulseCampaignEditTabsEnum.FOCUS}
          >
            <FocusStep manageVM={manageVM} />
          </TabPanel>
        )}
        <TabPanel
          key={PulseCampaignEditTabsEnum.BUILD}
          value={vm.currentTabIndex}
          index={PulseCampaignEditTabsEnum.BUILD}
        >
          <BuildStep buildVM={buildVM} />
        </TabPanel>
        <TabPanel
          key={PulseCampaignEditTabsEnum.AUDIENCE}
          value={vm.currentTabIndex}
          index={PulseCampaignEditTabsEnum.AUDIENCE}
        >
          <AudienceStep />
        </TabPanel>
        <TabPanel
          key={PulseCampaignEditTabsEnum.SCHEDULE}
          value={vm.currentTabIndex}
          index={PulseCampaignEditTabsEnum.SCHEDULE}
        >
          <ScheduleStep />
        </TabPanel>
        <TabPanel
          key={PulseCampaignEditTabsEnum.INITIATE}
          value={vm.currentTabIndex}
          index={PulseCampaignEditTabsEnum.INITIATE}
        >
          <InitiateStep />
        </TabPanel>
        <TabPanel
          key={PulseCampaignEditTabsEnum.LAUNCHED}
          value={vm.currentTabIndex}
          index={PulseCampaignEditTabsEnum.LAUNCHED}
        >
          <InitiatedStep />
        </TabPanel>
      </div>
    </>
  )
}

export default inject('pulseCampaignsStore')(observer(TabsBar))
