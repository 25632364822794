import { ColDef, GridOptions, RowClickedEvent } from 'ag-grid-community'
import { action, computed, observable } from 'mobx'
import { AGGridVM } from '../../shared/ag-grid/AGGridVM'
import { nameOf } from '../../shared/nameOf'
import { RootStore } from '../../stores/RootStore'
import { GroupedRowVM } from './GroupedRowVM'
import { ParticipantsSelectVM } from './ParticipantsSelectVM'
import { UserRowVM } from './UserRowVM'
import { UsersListDataStore } from './UsersListDataStore'

export class UsersAGGridVM extends AGGridVM {
  private rootStore: RootStore
  private parentVM: ParticipantsSelectVM
  private dataStore: UsersListDataStore
  private forGroupedRow: GroupedRowVM

  constructor(rootStore: RootStore, parentVM: ParticipantsSelectVM, forGroupedRow?: GroupedRowVM) {
    super()
    this.rootStore = rootStore
    this.parentVM = parentVM
    this.forGroupedRow = forGroupedRow
    this.dataStore = new UsersListDataStore(
      this.rootStore,
      this.parentVM.userId,
      this.forGroupedRow
    )
    this.sizeColumnsToFit = false
    this.serverSideLoaded = true
  }

  @observable public shown: boolean = false
  @observable public height: number = undefined
  @observable public showHidden: boolean = false

  @action
  public setHeight(val) {
    this.height = val
  }

  @computed
  public get hasFilter(): boolean {
    return this.dataStore.hasFilter
  }

  public getRowNodeId(row: UserRowVM): string {
    return row.rowNodeId
  }

  @action
  public toggleShowHidden(): void {
    this.showHidden = !this.showHidden
    this.dataStore.setShowHidden(this.showHidden)
    this.dataStore.reloadUsers()
    this.reload()
  }

  public get shouldRender(): boolean {
    if (!this.rootStore.rolesStore.loaded) return false
    if (!this.rootStore.groupsStore.loaded) return false
    return true
  }

  @computed
  public get columnDefs(): ColDef[] {
    const s = this.rootStore.localizationStore.lzStrings
    return [
      {
        headerName: s.orgUsersTableWidget.name,
        field: nameOf<UserRowVM, string>((e) => e.name),
        sort: 'asc',
        cellRenderer: 'nameCell',
        width: 440,
      },
    ]
  }

  public getGridOptions(): GridOptions {
    return {
      ...this.baseGridOptions,
      onRowClicked: (e) => this.rowClicked(e),
      getRowNodeId: (e) => this.getRowNodeId(e),
      columnDefs: this.ensureTooltipFields(this.columnDefs),
      pagination: false,
      headerHeight: 0,
      rowHeight: 52,
    }
  }

  @action
  public setQuickFilter(val: string) {
    this.typedFilterText = val
    if (this.quickFilterTO) clearTimeout(this.quickFilterTO)
    this.quickFilterTO = setTimeout(() => this.applyFilter(), 1000)
  }

  private applyFilter() {
    this.dataStore.setFilter(this.typedFilterText)
    this.reload()
    this.columnApi.applyColumnState({
      state: [{ colId: 'name', sort: 'asc' }],
    })
  }

  @computed
  public get filter(): string {
    return this.dataStore.filter
  }

  protected onGridReadied() {
    this.gridApi.setDatasource({
      rowCount: this.totalRecords,
      getRows: async (params) => {
        if (params.startRow === 0) this.dataStore.setPage(0)
        await this.dataStore.getNextPage()
        const rows = this.dataStore.organizationUsers.map(
          (e) => new UserRowVM(e, this.rootStore, this.parentVM, this)
        )
        params.successCallback(rows, this.dataStore.totalRecords)
      },
    })
  }

  public get rows(): UserRowVM[] {
    return []
  }

  public rowClicked(e: RowClickedEvent) {
    const row: UserRowVM = e.data
    row.click()
  }

  @computed
  protected get totalRecords() {
    return this.dataStore.totalRecords
  }

  @action
  public show() {
    this.shown = true
  }

  @action
  public hide() {
    this.shown = false
  }
}
