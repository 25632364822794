import { action, observable } from 'mobx'
import { IAggregate } from '../../shared/data/IAggregate'
import { ISurveyTagDTO } from '../dtos/ISurveyTagDTO'
import { serializable, serialize } from 'serializr'

export default class SurveyTag implements ISurveyTagDTO, IAggregate {
  static create(orgId) {
    const surveyTag = new SurveyTag()
    surveyTag.organizationId = orgId
    return surveyTag
  }

  @serializable @observable objectId: string = ''
  @serializable @observable organizationId: string = ''
  @serializable @observable name: string = ''
  @serializable @observable description: string = ''
  @serializable @observable isProcessing: boolean = false
  @serializable @observable isDeleted: boolean = false
  isOnServer: boolean = false

  @action
  markIsNotOnServer() {
    this.isOnServer = false
  }

  @action
  markIsOnServer() {
    this.isOnServer = true
  }

  @action
  markIsNotProcessing() {
    this.isProcessing = false
  }

  @action
  markIsProcessing() {
    this.isProcessing = true
  }

  public serialize(): ISurveyTagDTO {
    return serialize(this)
  }
}
