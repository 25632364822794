import { RootStore } from '../../stores/RootStore'
import { computed, action, observable } from 'mobx'
import User from '../../users/aggregate/User'
import { UserGoal } from '../aggregate/UserGoal'
import { MyGoalsWidgetVM } from './MyGoalsWidgetVM'
import moment, { Moment } from 'moment'
import { GoalsService } from '../../goals/service/GoalsService'
import { AudienceMember } from 'src/app/audience-members/aggregate/AudienceMember'

export class MyGoalsWidgetRowVM {
  private userGoal: UserGoal
  private rootStore: RootStore
  private myGoalsWidgetVM: MyGoalsWidgetVM
  private svc: GoalsService

  constructor(rootStore: RootStore, myGoalsWidgetVM: MyGoalsWidgetVM, userGoal: UserGoal) {
    this.rootStore = rootStore
    this.userGoal = userGoal
    this.myGoalsWidgetVM = myGoalsWidgetVM
    this.svc = new GoalsService()
    this.getCurrentValue()
  }

  @observable public current: number = 0

  @action
  public async getCurrentValue() {
    if (!this.userGoal) return
    const goal = this.rootStore.goalsStore.getGoal(this.userGoal.goalId)
    if (!goal) return
    const dto = goal.serialize()
    const valueFacts = await this.svc.getValueFacts(dto)
    const userValueFact = valueFacts.filter((fact) => fact.RippleUserId === this.userGoal.userId)[0]
    if (userValueFact) this.current = userValueFact.Current1
  }

  @computed
  public get objectId(): string {
    return this.userGoal.objectId
  }

  @computed
  public get active(): boolean {
    return this.userGoal.active
  }

  @computed
  public get goalId(): string {
    return this.userGoal.goalId
  }

  @computed
  public get assignedByUser(): AudienceMember {
    return this.rootStore.audienceMembersStore.getUser(this.userGoal.createdByUserId)
  }

  @computed
  public get progress(): number {
    if (!this.current) return 0
    return Math.ceil((this.current / this.userGoal.target) * 100)
  }

  @computed
  public get name(): string {
    if (!this.userGoal) return
    return this.userGoal.name
  }

  @computed
  public get user(): AudienceMember {
    return this.rootStore.audienceMembersStore.getUser(this.userGoal.userId)
  }

  @computed
  public get category(): string {
    const category = this.rootStore.categoriesStore.getCategory(this.userGoal.categoryId)
    if (!category) return ''
    return category.name
  }

  @computed
  public get userOwned(): boolean {
    if (this.userGoal.userId === this.userGoal.createdByUserId) return true
    return false
  }

  @computed
  public get dueDate(): Date {
    return this.userGoal.endDate
  }

  @computed
  public get isRead(): boolean {
    return this.userGoal.isRead
  }

  @action
  public editGoal() {
    this.rootStore.appStore.router.push(`/goals/edit/${this.objectId}`)
  }
}
