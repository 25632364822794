import React from 'react'
import { inject, observer } from 'mobx-react'
import { makeStyles } from '@material-ui/core/styles'
import ReactPlayer from 'react-player'
import { MediaUploadPickerVM } from '../../view-models/media-upload-picker/MediaUploadPickerVM'
import { MediaItemsStore } from '../../../media-items/store/MediaItemsStore'
interface MediaPanelPreviewProps {
  mediaItemsStore?: MediaItemsStore
  mediaUploadPickerVM: MediaUploadPickerVM
}
const MediaPanelPreview: React.FC<MediaPanelPreviewProps> = ({
  mediaItemsStore,
  mediaUploadPickerVM,
}) => {
  const classes = useStyles({})
  const vm = mediaUploadPickerVM
  if (!vm) return null
  const mediaItem = vm.selectedMediaItemId
    ? mediaItemsStore.getMediaItem(vm.selectedMediaItemId)
    : null
  const mediaPreview =
    mediaItem?.type === 'video' ? (
      <ReactPlayer
        url={mediaItem?.fileUrl}
        controls
        width='auto'
        height='35vh'
        style={{ maxHeight: '35vh' }}
      />
    ) : mediaItem?.type === 'image' ? (
      <img src={mediaItem?.fileUrl} className={classes.img} />
    ) : null
  return mediaItem && !mediaItem.isDeleted && <div className={classes.root}>{mediaPreview}</div>
}
const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '35vh',
    padding: '1em 0',
    backgroundColor: 'black',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  img: {
    maxWidth: '100%',
    maxHeight: '35vh',
  },
})
export default inject('mediaItemsStore')(observer(MediaPanelPreview))
