import React from 'react'
import { inject, observer } from 'mobx-react'
import { AgGridReact } from 'ag-grid-react'
import { CircularProgress, Typography } from '@material-ui/core'
import './AnnouncementListTable.scss'
import { AnnouncementListTableVM } from '../../view-models/list/AnnouncementListTableVM'

interface Props {
  tableVM: AnnouncementListTableVM
}
const AnnouncementListTable: React.FC<Props> = ({ tableVM }) => {
  const vm = tableVM
  if (!vm) return

  const renderSpinner = () => {
    if (vm.shouldRender) return
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  const renderNoRowsContent = () => {
    if (vm.hasRows) return
    if (vm.loadingFirstPage) return
    return (
      <Typography component='div' className='no-rows'>
        No Announcements Found
      </Typography>
    )
  }

  const renderGrid = () => {
    if (!vm.shouldRender) return
    return (
      <div className={vm.hasRows ? 'ag-theme-alpine' : 'ag-theme-alpine ag-grid-no-rows'}>
        <AgGridReact
          gridOptions={{
            ...vm.getGridOptions(),
          }}
        />
      </div>
    )
  }

  return (
    <>
      {renderSpinner()}
      {renderGrid()}
      {renderNoRowsContent()}
    </>
  )
}

export default inject()(observer(AnnouncementListTable))
