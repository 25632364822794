import React from 'react'
import { inject, observer } from 'mobx-react'
import ReactPlayer from 'react-player'
import { OldMediaItemVM } from '../../../user-surveys/view-models/OldMediaItemVM'
import { QuestionVM } from '../../view-models/QuestionVM'
import './QuestionRow.scss'

interface Props {
  question: QuestionVM
  vm: OldMediaItemVM
}

const OldQuestionMedia: React.FC<Props> = ({ question, vm }) => {
  if (!vm) return

  const renderImage = (item: OldMediaItemVM) => {
    if (item.isCMSItem) return
    if (!item.isImage) return
    if (item.hasVimeoURL) return
    if (item.hasYouTubeURL) return
    return (
      <img
        className='media-image'
        src={question.media.path}
        onClick={() => question.toggleLightBox()}
        alt=''
      />
    )
  }

  const renderYouTubeVideo = (item: OldMediaItemVM) => {
    if (item.isCMSItem) return
    if (!item.hasYouTubeURL) return

    return (
      <div>
        <iframe
          width={'100%'}
          className='media-youTube'
          src={question.embedUrl}
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        />
      </div>
    )
  }

  const renderVimeoVideo = (item: OldMediaItemVM) => {
    if (item.isCMSItem) return
    if (!item.hasVimeoURL) return null

    return (
      <div>
        <iframe
          width={'100%'}
          className='media-vimeo'
          src={question.embedUrl}
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        />
      </div>
    )
  }

  const renderVideo = (item: OldMediaItemVM) => {
    if (item.isImage) return
    if (item.isCMSItem) return
    if (question.hasYouTubeURL) return null
    if (question.hasVimeoURL) return null
    return (
      <ReactPlayer
        id={`survey-video-${question.id}`}
        height={''}
        width={'100%'}
        progressInterval={1000}
        onProgress={(e) => question.watchProgress(e)}
        onDuration={(e) => question.setWatchPercentageNeeded(e)}
        url={question.media.path}
        onSeek={() => overrideSeek()}
        controls
        className='media-video'
      />
    )
  }

  const overrideSeek = () => {
    let video = document.querySelector('video')
    if (video.currentTime === question.playedSeconds) return

    if (question.playedSeconds <= question.watchSecondsNeeded)
      video.currentTime = question.playedSeconds
  }

  return (
    <>
      {renderImage(vm)}
      {renderYouTubeVideo(vm)}
      {renderVimeoVideo(vm)}
      {renderVideo(vm)}
    </>
  )
}

export default inject()(observer(OldQuestionMedia))
