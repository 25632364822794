import { RootStore } from '../../../stores/RootStore'
import { observable, computed, action } from 'mobx'
import { CustomCollectionRowVM } from './CustomCollectionRowVM'
import { AGGridVM } from '../../../shared/ag-grid/AGGridVM'
import { ColDef, GridOptions, RowClickedEvent } from 'ag-grid-community'
import { nameOf } from '../../../shared/nameOf'

export class CustomCollectionsWidgetVM extends AGGridVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    super()
    this.rootStore = rootStore
  }

  @observable public deleteConfirmShown: boolean = false
  @observable public deletingOrgId: string = ''

  @computed public get rows(): CustomCollectionRowVM[] {
    return this.rootStore.customCollectionsStore.currentOrgCustomCollections.map(
      (e) => new CustomCollectionRowVM(this.rootStore, this, e)
    )
  }

  @action
  public getRowNodeId(row): string {
    return row.objectId
  }

  public get shouldRender(): boolean {
    if (!this.rootStore.customCollectionsStore.loaded) return false
    return true
  }

  public getGridOptions(): GridOptions {
    return {
      ...this.baseGridOptions,
      rowData: this.rows,
      onRowClicked: (e) => this.rowClicked(e),
      getRowNodeId: (e) => this.getRowNodeId(e),
      columnDefs: this.ensureTooltipFields(this.columnDefs),
    }
  }

  public rowClicked(e: RowClickedEvent) {
    const row: CustomCollectionRowVM = e.data
    if (!row) return
    row.editCustomCollection()
  }

  @computed
  public get columnDefs(): ColDef[] {
    const s = this.rootStore.localizationStore.lzStrings
    return [
      {
        headerName: s.clientManagementWidget.name,
        field: nameOf<CustomCollectionRowVM, string>((e) => e.name),
        sort: 'asc',
      },
      {
        headerName: 'Fields',
        field: nameOf<CustomCollectionRowVM, number>((e) => e.fieldsCount),
      },
      {
        headerName: 'Records',
        field: nameOf<CustomCollectionRowVM, number>((e) => e.recordsCount),
      },
    ]
  }

  @action
  public addNew() {
    this.rootStore.appStore.router.push('/customcollections/edit/new')
  }

  @action
  public toggleDeleteDialog() {
    this.deleteConfirmShown = !this.deleteConfirmShown
  }

  @action
  public showDeleteDialog(orgId: string) {
    this.deletingOrgId = orgId
    this.deleteConfirmShown = true
  }
}
