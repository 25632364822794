import React, { useState, useImperativeHandle, forwardRef, FC, ForwardRefRenderFunction } from 'react'
import MuiDownshift from 'mui-downshift'

/**
 * Required:
 * @param options
 * @param onSelect      Function to handle selection: selectedOption => { do stuff with selectedOption }
 * Optional:
 * @param labelKey      The object key to show in the dropdown menu. Default is 'label'.
 * @param error         Applies Mui error styling (red border).
 * @param disabled      Applies Mui disabled styling & disables input.
 * @param label         The label that gets shifted up/down when input is focused/blurred.
 * @param placeholder
 * @param maxResults    Number of results to show in dropdown menu. Default is 5.
 * @param focusOnClear  If true, will focus the input when clear button is clicked. Default is false.
 * @param id            HTML ID, if you want to apply an ID to the div wrapping the MuiDownshift component
 * @param value         If you use the value prop, know that you will have to handle changing the value on select
 *                      manually. If you want to see what I mean, provide a value, type a partial name into the
 *                      input, then select an option from the dropdown. The selection fires off but the input still
 *                      shows the partial name.
 * @param classes       Passes the styles object generated via MaterialUI useStyle and makeStyle hooks to the corresponding MaterialUI components
 */
interface Props {
  options: Array<any>
  onSelect: Function
  labelKey?: string
  error?: boolean
  disabled?: boolean
  label?: string
  placeholder?: string
  maxResults?: number
  focusOnClear?: boolean
  id?: string
  value?: string
  classes?: any
}

/**
 * @param ref
 * ref can be used to access component methods in the parent component.
 * Right now, there's just one component method: clear
 * You might want to use clear to clear the downshift component when a button is clicked.
 * To do so, pass a ref to MuiSelect...
 *
 * const muiSelectRef = useRef()
 * <MuiSelect ref={muiSelectRef} {...otherProps} />
 *
 * Now you have access to the clear method via muiSelectRef.current.clear()
 */
const MuiSelect: ForwardRefRenderFunction<unknown, Props> = (props, ref) => {
  const {
    maxResults,
    focusOnClear,
    options,
    onSelect,
    label,
    placeholder,
    error,
    disabled,
    id,
    value,
    classes,
  } = props

  let labelKey = 'label'
  if (props.labelKey) {
    labelKey = props.labelKey
  }

  const [search, setSearch] = useState('')

  // Only show options that have the label key
  let items = []
  if (Array.isArray(options) && options.length > 0) {
    items = options
      .filter(
        option =>
          option[labelKey] !== undefined && option[labelKey] !== null && option[labelKey] !== ''
      )
      .map(option => {
        option.label = option[labelKey]
        return option
      })
  }

  // Filter items if searching
  if (search) {
    items = items.filter(item => item[labelKey].toLowerCase().indexOf(search.toLowerCase()) !== -1)
  }

  // Set the search value to filter items
  const handleStateChange = change => {
    const { inputValue } = change
    if (typeof inputValue === 'string') {
      setSearch(inputValue)
    }
  }

  // Access to the Downshift component state...
  let setDownshiftState

  const getInputProps = props => {
    setDownshiftState = props.setState

    const inputProps: any = {
      id: id ? id : null,
      classes: classes ? classes : null,
      label: label ? label : '',
      placeholder: placeholder ? placeholder : '',
      error: error ? error : null,
      disabled: disabled ? disabled : null,
    }

    if (value) {
      // Only add value prop if it's provided... lets Downshift handle updating input state for you.
      inputProps.value = value
    }

    return inputProps
  }

  const clear = () => {
    setDownshiftState({ inputValue: '' })
    onSelect(null)
  }

  useImperativeHandle(ref, () => ({
    clear,
  }))

  return (
    //<div id={id || null}>
    <MuiDownshift
      variant='outlined'
      menuItemCount={maxResults || 5}
      focusOnClear={focusOnClear || false}
      items={items}
      itemToString={item => (item ? item[labelKey] : '')}
      onChange={item => onSelect(item)}
      onStateChange={change => handleStateChange(change)}
      getInputProps={getInputProps}
    />
    //</div>
  )
}

export default forwardRef(MuiSelect)
