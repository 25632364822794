import React from 'react'
import { observer, inject } from 'mobx-react'
import { CardHeader, IconButton, Tooltip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { ClientsStore } from '../store/ClientsStore'
import { LabelsStore } from '../../labels/store/LabelsStore'
import Searchbar from '../../shared/Searchbar'
import LocalizationStore from '../../localization/LocalizationStore'

interface WidgetHeaderProps {
  clientsStore?: ClientsStore
  labelsStore?: LabelsStore
  localizationStore?: LocalizationStore
}

const WidgetHeader: React.FC<WidgetHeaderProps> = ({
  labelsStore,
  clientsStore,
  localizationStore,
}) => {
  const vm = clientsStore.clientManagementWidgetVM
  const s = localizationStore.lzStrings
  if (!vm) return

  return (
    <CardHeader
      id='WidgetHeader'
      title={labelsStore.getLabelByLanguageAndFor('clients')}
      action={
        <>
          <div id='PrivilegeSetSearch' className='action-btn'>
            <Searchbar
              focused={vm.typedFilterText.length > 0}
              className='search'
              searchValue={vm.typedFilterText}
              onSearchChange={(e) => vm.setQuickFilter(e.target.value)}
            />
          </div>
          <React.Fragment>
            <Tooltip
              title={
                s.clientManagementWidget.add_new + labelsStore.getLabelByLanguageAndFor('client')
              }
              placement='bottom'
            >
              <IconButton
                className='widget-cta'
                size='small'
                onClick={() => clientsStore.addNewClient()}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </React.Fragment>
        </>
      }
      className='widgetHeader'
    ></CardHeader>
  )
}

export default inject('labelsStore', 'clientsStore', 'localizationStore')(observer(WidgetHeader))
