import React from 'react'
import { inject, observer } from 'mobx-react'
import { TaskTemplatesStore } from '../store/TaskTemplatesStore'
import { Card } from '@material-ui/core'
import './TaskTypesWidget.scss'
import TaskTypeDrawer from '../new/TaskTypeDrawer'
import LocalizationStore from '../../localization/LocalizationStore'
import WidgetHeader from './WidgetHeader'
import WidgetContent from './WidgetContent'

interface Props {
  taskTemplatesStore?: TaskTemplatesStore
  localizationStore?: LocalizationStore
}

const TaskTypesWidget: React.FC<Props> = ({ localizationStore, taskTemplatesStore }) => {
  const { taskTypesWidgetVM: vm, selectedTaskTemplateId } = taskTemplatesStore
  if (!vm) return null


  const handleEditClick = (id) => {
    taskTemplatesStore.setSelectedTaskTemplate(id)
    taskTemplatesStore.showDrawer = true
  }
  const onAddClick = () => {
    taskTemplatesStore.setAddNewTaskTemplate()
    taskTemplatesStore.showDrawer = true
  }

  return (
    <>
      <TaskTypeDrawer />
      <Card id='TaskTypesWidget' elevation={0}>
        <WidgetHeader />
        <WidgetContent />
      </Card>
    </>
  )
}

export default inject('localizationStore', 'taskTemplatesStore')(observer(TaskTypesWidget))
