import React from 'react'
import { IndexRoute, Route } from 'react-router'
import Layout from '../../layout/Layout'
import { authenticate } from '../../auth/routes/AuthenticatedRoute'
import GoalEditScreen from '../../goals/views/edit/goal/GoalEditScreen'
import TrainingItemEditScreen from 'src/app/training-items/views/edit/TrainingItemEditScreen'

export default function getGoalsRoutes() {
  return (
    <Route key={'impact0'} component={authenticate(Layout)} path='goals'>
      <IndexRoute onEnter={(nextState, replace) => replace('/dashboard/impact')} />
      <Route key={'impact1'} path='edit/:id' component={GoalEditScreen} />
      <Route key={'impact2'} path='training-item/:id' component={TrainingItemEditScreen} />
      <Route key={'impact3'} path='from-catalog/edit/:catalogId' component={GoalEditScreen} />
      <Route key={'impact3'} path='from-catalog/edit/:copyCatalogId' component={GoalEditScreen} />
      <Route
        key={'impact3'}
        path='from-catalog/assign/:assignCatalogId'
        component={GoalEditScreen}
      />
    </Route>
  )
}
