import React from 'react'
import './WidgetHeader.scss'
import { observer, inject } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { CardHeader, IconButton, Tooltip } from '@material-ui/core'
import Searchbar from '../../../shared/Searchbar'
import AddIcon from '@material-ui/icons/Add'
import { SurveyTagsStore } from '../../store/SurveyTagsStore'
import { LabelsStore } from '../../../labels/store/LabelsStore'

interface WidgetHeaderProps {
  localizationStore?: LocalizationStore
  surveyTagsStore?: SurveyTagsStore
  labelsStore?: LabelsStore
}

const WidgetHeader: React.FC<WidgetHeaderProps> = ({
  localizationStore,
  surveyTagsStore,
  labelsStore,
}) => {
  const { surveyTags: strings } = localizationStore.lzStrings
  const { surveyTagsWidgetVM: vm } = surveyTagsStore
  if (!vm) return

  const handleAdd = () => {
    surveyTagsStore.addNewSurveyType()
  }

  return (
    <CardHeader
      id='WidgetHeader'
      title={
        labelsStore.getLabelByLanguageAndFor('pulse') +
        ' ' +
        labelsStore.getLabelByLanguageAndFor('tags')
      }
      action={
        <>
          <div id='SurveyTagsSearch' className='action-btn'>
            <Searchbar
              focused={vm.typedFilterText.length > 0}
              className='search'
              searchValue={vm.typedFilterText}
              onSearchChange={(e) => vm.setQuickFilter(e.target.value)}
            />
          </div>
          <div id='SurveyTagsAdd' className='action-btn'>
            <React.Fragment>
              <Tooltip
                title={
                  strings.add_a_new +
                  labelsStore.getLabelByLanguageAndFor('pulse') +
                  ' ' +
                  labelsStore.getLabelByLanguageAndFor('tag')
                }
                placement='bottom'
              >
                <IconButton className='widget-cta' size='small' onClick={handleAdd}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </React.Fragment>
          </div>
        </>
      }
      className='widgetHeader'
    ></CardHeader>
  )
}

export default inject('localizationStore', 'surveyTagsStore', 'labelsStore')(observer(WidgetHeader))
