import { computed, observable } from 'mobx'
import { PulseCampaign } from '../../../pulse-campaigns/aggregate/PulseCampaign'
import { IPulseCampaignDTO } from '../../../pulse-campaigns/dto/IPulseCampaignDTO'
import { IPulseCampaignFindResult } from '../../../pulse-campaigns/interfaces/IPulseCampaignFindResult'
import { IPulseCampaignsSAFindRequest } from '../../../pulse-campaigns/interfaces/IPulseCampaignsSAFindRequest'
import { PulseCampaignsService } from '../../../pulse-campaigns/service/PulseCampaignsService'
import { DataStore } from '../../../shared/data/DataStore'
import { RootStore } from '../../../stores/RootStore'

export class PulseSetCampaignTemplatesDataStore extends DataStore<PulseCampaign, IPulseCampaignDTO> {
  private request: IPulseCampaignsSAFindRequest

  constructor(rootStore: RootStore, request: IPulseCampaignsSAFindRequest) {
    super(rootStore, PulseCampaign, 'pulseCampaigns', ['name', 'surveys'])
    this.request = request
    this.paged = true
    this.listenToListRecordsViaApiUpdates = true
  }

  @observable public isLoaded: boolean = false

  public setRequest(request: IPulseCampaignsSAFindRequest) {
    this.request = request
  }

  public onListRecordsLoaded() {
    this.isLoaded = true
  }

  protected getListRecordsApiCall() {
    return async () => {
      const req: IPulseCampaignsSAFindRequest = {
        ...this.request,
        listColumns: this.listColumns,
      }
      const svc = new PulseCampaignsService()
      const result: IPulseCampaignFindResult = await svc.findPulseCampaignsSA(req)
      this.totalRecords = result.count
      return result.pulseCampaigns
    }
  }

  public get rows() {
    return this.records
  }
}
