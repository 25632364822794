import React from 'react'
import {
  CardContent,
  Typography,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  LinearProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../../localization/LocalizationStore'
import EditIcon from '@material-ui/icons/Edit'
import Avatar from '../../../../shared/Avatar'
import './TrainerWidget.scss'
import { TrainerWidgetRowVM } from '../../../view-models/widgets/trainer-widget/TrainerWidgetRowVM'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import { AudienceMember } from '../../../aggregates/AudienceMember'
import { TrainingPlansStore } from '../../../../training-plans/store/TrainingPlansStore'
import { UserTrainingPlanRowVM } from '../../../../training-plans/view-models/widgets/trainer-widget/UserTrainingPlanRowVM'
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded'
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded'

interface Props {
  trainingPlansStore?: TrainingPlansStore
  localizationStore?: LocalizationStore
}

const Content: React.FC<Props> = ({ trainingPlansStore, localizationStore }) => {
  const { trainerWidgetVM: vm } = trainingPlansStore
  if (!vm) return null

  const renderNoRowsContent = () => {
    if (vm.rows.length > 0) return
    return (
      <Typography component='div' className='no-rows'>
        No Training Plans Found
      </Typography>
    )
  }

  const renderUserTrainingPlan = (row: UserTrainingPlanRowVM, index: number) => {
    if (!row.user) return null
    return (
      <Grid
        item
        xs={12}
        key={index}
        className='user-plan-container'
        onClick={() => row.openUserTrainingPlanToView()}
      >
        <Tooltip title={row.user.name ? row.user.name : ''} placement='bottom' enterDelay={100}>
          <span>
            <Avatar user={row.user} size={30} />
          </span>
        </Tooltip>

        <LinearProgress
          variant='determinate'
          value={row.progress}
          className={row.userOwned ? 'owned' : 'not-owned'}
        />
        <Typography className='progress-label' variant='h6'>
          {row.progress}%
        </Typography>
      </Grid>
    )
  }

  const renderBadge = (user: AudienceMember, row: TrainerWidgetRowVM) => {
    if (!user) return null
    if (row.completedPlanUserIds.includes(user.id)) return <CheckIcon className='complete-badge' />
    if (row.incompletePlanUserIds.includes(user.id))
      return <CloseIcon className='incomplete-badge' />
  }

  const renderExpandPlanIcon = (row) => {
    if (row.isExpanded) return <ExpandLessRoundedIcon />
    if (!row.isExpanded) return <ExpandMoreRoundedIcon />
  }

  const renderPlanRows = () => {
    if (vm.rows.length === 0) return null
    const renderEditIcon = (row) => {
      if (!row.userOwned) return null
      return (
        <Tooltip title='Edit Training Plan' placement='bottom' enterDelay={100}>
          <EditIcon
            className='edit-icon'
            onClick={(e) => {
              e.stopPropagation()
              row.editTrainingPlan()
            }}
          />
        </Tooltip>
      )
    }
    return vm.rows.map((row, index) => {
      return (
        <Accordion key={index} id='TrainerPlanRow'>
          <AccordionSummary className='trainer-summary'>
            <Grid item xs={12}>
              <Grid container justifyContent='space-between' className='title-row'>
                <Typography variant='h6' className='title-text'>
                  {row.title}
                </Typography>
                <span className='icons'>{renderEditIcon(row)}</span>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction='row' justifyContent='space-between'>
                <span className='subtitle'>
                  <Grid item>
                    <Typography>Due: {row.dueDate}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant='h6' className='break'>
                      |
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>{row.categories}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant='h6' className='break'>
                      |
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>{row.userTrainingPlanRows.length} Assignees</Typography>
                  </Grid>
                </span>

                <div className='expand-icon' onClick={() => row.toggleIsExpanded()}>
                  {renderExpandPlanIcon(row)}
                </div>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              {row.userTrainingPlanRows.map((row, index) => renderUserTrainingPlan(row, index))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      )
    })
  }

  const renderFilterMenu = () => {
    return (
      <Grid container direction='row' justifyContent='flex-end' className='filter-row'>
        <Grid item className='filter'>
          <InputLabel>Sort By:</InputLabel>
          <Select value={vm.sortType} onChange={(e) => vm.setSortType(e.target.value)}>
            <MenuItem value={'Date'}>Date</MenuItem>
            <MenuItem value={'Name'}>Name</MenuItem>
          </Select>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      {renderFilterMenu()}
      <div id='Content'>
        <CardContent className='no-padding'>
          {renderPlanRows()}
          {renderNoRowsContent()}
        </CardContent>
      </div>
    </>
  )
}

export default inject('trainingPlansStore', 'localizationStore')(observer(Content))
