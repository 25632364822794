import React, { FC } from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { observer, inject } from 'mobx-react'
import './SurveyOptions.scss'
import { SurveysStore } from 'src/app/surveys/store/SurveysStore'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import { Switch } from '@material-ui/core'
import NotificationTemplatesEditor from './NotificationTemplatesEditor'
import { LabelsStore } from '../../../../labels/store/LabelsStore'

interface Props {
  surveysStore?: SurveysStore
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
}

const PublishedNotificationTemplates: FC<Props> = ({
  surveysStore,
  localizationStore,
  labelsStore,
}) => {
  const surveyEditVM = surveysStore.viewModels.editVM
  const vm = surveyEditVM.surveyOptionsVM
  if (!surveyEditVM) return null
  if (!vm) return null
  const s = localizationStore.lzStrings.surveys

  const renderForceEmails = () => {
    return (
      <div>
        <div className='primary-text'>{s.override_users_email}</div>
        <div className='secondary-text'>{s.sends_this_notification}</div>
        <Switch
          color='primary'
          disabled={surveyEditVM.isTemplateDisabled || surveyEditVM.isReadOnly}
          checked={surveyEditVM.forceEmails}
          onChange={() => surveyEditVM.toggleForceEmails()}
        />
      </div>
    )
  }

  return (
    <div className='survey-published-notification-templates'>
      <div className='primary-text'>{`${labelsStore.getLabelByLanguageAndFor('pulse')} ${
        s.published_notification
      }`}</div>
      <div className='section-content'>
        <div>
          <Tabs
            orientation='horizontal'
            variant='standard'
            value={vm.publishedNotificationTabIndex}
            onChange={(e, index) => {
              vm.setPublishedNotificationTabIndex(index)
            }}
            className='survey-published-notification-type-tabs'
          >
            <Tab
              className={surveyEditVM.isPublishedTemplatesLinkValid ? 'survey-published-notification-type-tab' : 'survey-published-notification-type-tab error'}

              label='emails'
              {...a11yProps(0)}
            />
            <Tab
              className='survey-published-notification-type-tab'
              label='text messages'
              {...a11yProps(1)}
            />
            <Tab
              className='survey-published-notification-type-tab'
              label='push notifications'
              {...a11yProps(2)}
            />
          </Tabs>
        </div>
        <div>
          <NotificationTemplatesEditor id='published-notification-templates-editor' />
          <TabPanel value={vm.publishedNotificationTabIndex} index={0}>
            {renderForceEmails()}
          </TabPanel>
        </div>
      </div>
    </div>
  )
}

const TabPanel = (props) => {
  const { children, value, index, ...rest } = props
  return (
    <div
      id={`survey-published-notification-tabpanel-${index}`}
      aria-labelledby={`survey-published-notification-tabpanel-${index}`}
      hidden={value !== index}
      {...rest}
      className='options-v2-tab-panel'
    >
      {children}
    </div>
  )
}

const a11yProps = (index) => {
  return {
    id: `survey-published-notification-tab-${index}`,
    'aria-controls': `survey-published-notification-tab-${index}`,
  }
}

export default inject(
  'surveysStore',
  'localizationStore',
  'labelsStore'
)(observer(PublishedNotificationTemplates))
