import { RootStore } from '../../stores/RootStore'
import Parse from 'parse'

export class GroupsUpdateService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public async saveGroup(orgId: string, group: any) {
    const data = await Parse.Cloud.run('saveGroup', { orgId, group })
    return data
  }

  public async deleteGroup(orgId: string, groupId: string) {
    const data = await Parse.Cloud.run('deleteGroup', { orgId, groupId })
    return data
  }

  public async updateGroupMembers(
    orgId: string,
    groupId: string,
    userIdsToAdd: Array<string>,
    userIdsToRemove: Array<string>
  ) {
    const result = await Parse.Cloud.run('updateGroupMembers', {
      orgId,
      groupId,
      userIdsToAdd,
      userIdsToRemove,
    })
    return result
  }

  public async refreshEvents(groupId: string) {
    const orgId = this.rootStore.appStore.currentOrgId
    await Parse.Cloud.run('refreshEventsForGroup', { orgId, groupId })
  }

}
