import { computed, observable, action, when, reaction } from 'mobx'
import { RootStore } from '../../../../stores/RootStore'
import { QuestionRowVM } from './QuestionRowVM'
import { ColDef, GridOptions, RowClickedEvent } from 'ag-grid-community'
import { CategoryPickerVM } from '../../../../shared/category-picker/CategoryPickerVM'
import { Question } from '../../../../questions/aggregates/Question'
import { QuestionsCatalogDataStore } from './QuestionsCatalogDataStore'
import { AGGridVM } from '../../../../shared/ag-grid/AGGridVM'
import { nameOf } from '../../../../shared/nameOf'
import { QuestionTypesFilterVM } from '../../../../questions/view-models/QuestionTypesFilterVM'
import { SurveyEditVM } from '../../SurveyEditVM'

export class QuestionsCatalogVM extends AGGridVM {
  private rootStore: RootStore
  private dataStore: QuestionsCatalogDataStore
  private parentVM: SurveyEditVM

  constructor(rootStore: RootStore, parentVM: SurveyEditVM) {
    super()
    this.rootStore = rootStore
    this.parentVM = parentVM
    this.serverSideLoaded = true
    this.rowHeight = 120
    this.categoryPickerVM = new CategoryPickerVM(rootStore, true, true, true, undefined, undefined)
    this.typesFilterVM = new QuestionTypesFilterVM(this)
    this.loadDataStore()
    this.loadReactions()
  }

  @observable public categoryPickerVM: CategoryPickerVM = null
  @observable public typesFilterVM: QuestionTypesFilterVM = null
  @observable public shown: boolean = false
  @observable public selectedQuestions: QuestionRowVM[] = []

  @action
  public addQuestion(row: QuestionRowVM) {
    this.selectedQuestions.push(row)
  }

  private loadDataStore() {
    this.dataStore = new QuestionsCatalogDataStore(this.rootStore, this)
    this.dataStore.onRecordUpdated = (e) => this.onRecordUpdated(e)
  }

  private loadReactions() {
    reaction(
      () =>
        this.rootStore.questionsStore.editVM &&
        this.rootStore.questionsStore.editVM.newQuestionJustSaved,
      (val) => val && this.reload()
    )
    reaction(
      () =>
        this.rootStore.questionsStore.editVM &&
        this.rootStore.questionsStore.editVM.questionJustDeleted,
      (val) => val && this.reload()
    )
  }

  @action
  public getRecord(id: string): Question {
    return this.getRecord(id)
  }

  public onRecordUpdated(obj: Question) {
    const rowNode = this.gridApi.getRowNode(obj.objectId)
    if (!rowNode) return
    rowNode.setData(new QuestionRowVM(this.rootStore, this, obj))
  }

  public rowClicked(e: RowClickedEvent) {}

  public get rows(): QuestionRowVM[] {
    return []
  }

  protected async onGridReadied() {
    this.gridApi.setDatasource({
      rowCount: this.totalRecords,
      getRows: async (params) => {
        params.sortModel.forEach((col: { colId: string; sort: 'asc' | 'desc' }, idx: number) => {
          let dbCol = col.colId
          if (idx === 0) this.dataStore.setSort(dbCol, col.sort)
          if (idx > 0) this.dataStore.addSort(dbCol, col.sort)
        })
        if (params.sortModel.length === 0) this.dataStore.setSort('label')
        if (params.startRow === 0) this.dataStore.setPage(0)
        await this.dataStore.getNextPage()
        const rows = this.dataStore.questions.map((e) => new QuestionRowVM(this.rootStore, this, e))
        params.successCallback(rows, this.dataStore.totalRecords)
        this.gridApi.resetRowHeights()
      },
    })
  }

  @computed
  public get hasSurveyPrivilege(): boolean {
    return this.rootStore.appStore.canSurvey
  }

  @computed
  public get shouldRender(): boolean {
    if (!this.dataStore) return false
    return true
  }

  @action
  public getRowNodeId(row): string {
    return row.objectId
  }

  @action
  public setQuickFilter(val: string) {
    this.typedFilterText = val
    if (this.quickFilterTO) clearTimeout(this.quickFilterTO)
    this.quickFilterTO = setTimeout(() => this.applyFilter(), 1000)
  }

  private applyFilter() {
    this.dataStore.setFilter(this.typedFilterText)
    this.reload()
  }

  public get columnDefs(): ColDef[] {
    const s = this.rootStore.localizationStore.lzStrings
    return [
      {
        headerName: s.questionsCatalogWidget.label,
        field: nameOf<QuestionRowVM, string>((e) => e.title),
        sort: 'asc',
        cellRenderer: 'questionCard',
      },
    ]
  }

  public getGridOptions(): GridOptions {
    return {
      ...this.baseGridOptions,
      onRowClicked: (e) => this.rowClicked(e),
      getRowNodeId: (e) => this.getRowNodeId(e),
      columnDefs: this.columnDefs,
      // fullWidthCellRenderer: 'questionCard',
      headerHeight: 0,
      pagination: false,
    }
  }

  public openImportModal() {
    // this.rootStore.questionsStore.toggleImportModal()
  }

  @action
  public close(): void {
    this.shown = false
  }

  @action
  public toggle(): void {
    if (this.shown) {
      this.close()
      return
    }
    this.shown = true
  }

  @action
  public toggleQuestion(row: QuestionRowVM) {
    if (row.isSelected) {
      this.selectedQuestions = this.selectedQuestions.filter((e) => e.objectId !== row.objectId)
      return
    }
    this.selectedQuestions.push(row)
  }

  public addSelectedQuestions(): void {
    for (let q of this.selectedQuestions) {
      this.parentVM.addQuestionFromCatalog(q.question)
      q.toggle()
    }
    this.toggle()
  }

  public isQuestionSelected(id: string) {
    return Boolean(this.selectedQuestions.find((e) => e.objectId === id))
  }
}
