import { observable } from 'mobx'
import { AudienceMemberType } from '../types/AudienceMemberType'
import { Role } from './Role'
import { Group } from './Group'
import { IAudienceRoleResult } from '../interfaces/IAudienceRoleResult'
import { IAudienceGroupResult } from '../interfaces/IAudienceGroupResult'
import { IAudienceUserResult } from '../interfaces/IAudienceUserResult'
import { OrganizationUser } from '../../organization-users/aggregate/OrganizationUser'

export class AudienceMember {
  constructor(orgUser?: OrganizationUser, groupOrRole?: Group | Role) {
    if (orgUser) {
      this.objectId = orgUser.userId
      this.iconURL = ''
      this.name = orgUser.fk_User.name
      this.title = orgUser.title ? orgUser.title : ''
      // this.roles = orgUser.roles ? orgUser.roles : []
      // this.groups = orgUser.groups ? orgUser.groups : []
      this.roles = [] // TODO: Finish refactor
      this.groups = [] // TODO: Finish refactor
      this.organizationId = orgUser.organizationId
      this.email = orgUser.fk_User.email
      this.primaryGroupId = orgUser.primaryGroupId
      this.primaryRoleId = orgUser.primaryRoleId
      this.subordinates = orgUser.subordinates
      this.allSubordinates = orgUser.allSubordinates
      this.isArchived = orgUser.isArchived
    }

    if (groupOrRole) {
      this.objectId = groupOrRole.id
      this.name = groupOrRole.name
      this.type = groupOrRole.type
    }
  }

  static create(orgId?: string) {
    const mbr = new AudienceMember()
    if (orgId) mbr.organizationId = orgId
    return mbr
  }

  public static fromAudienceUserResult(orgId: string, user: IAudienceUserResult): AudienceMember {
    const mbr = new AudienceMember()
    mbr.organizationId = orgId
    mbr.name = user.name
    mbr.objectId = user.objectId
    mbr.type = 'user'
    mbr.iconURL = user.iconURL
    mbr.title = user.title
    mbr.email = user.email
    mbr.primaryGroupId = user.primaryGroupId
    mbr.primaryRoleId = user.primaryRoleId
    mbr.subordinates = user.subordinates
    mbr.allSubordinates = user.allSubordinates
    mbr.isArchived = user.isArchived
    if (user.groups) mbr.groups = user.groups.map((e) => e.name)
    if (user.roles) mbr.roles = user.roles.map((e) => e.name)
    return mbr
  }

  public static fromAudienceRoleResult(orgId: string, role: IAudienceRoleResult): AudienceMember {
    const mbr = new AudienceMember()
    mbr.organizationId = orgId
    mbr.name = role.name
    mbr.objectId = role.objectId
    mbr.type = 'role'
    return mbr
  }

  public static fromAudienceGroupResult(
    orgId: string,
    group: IAudienceGroupResult
  ): AudienceMember {
    const mbr = new AudienceMember()
    mbr.organizationId = orgId
    mbr.name = group.name
    mbr.objectId = group.objectId
    mbr.type = 'group'
    return mbr
  }

  @observable public objectId: string = ''
  @observable public name: string = ''
  @observable public title: string = ''
  @observable public email: string = ''
  @observable public organizationId: string = ''
  @observable public type: AudienceMemberType = 'user'
  @observable public iconURL: string = ''
  @observable public primaryRoleId: string = ''
  @observable public primaryGroupId: string = ''
  @observable public roles: Array<string> = []
  @observable public groups: Array<string> = []
  @observable public subordinates: Array<string> = []
  @observable public allSubordinates: Array<string> = []
  @observable public isArchived: boolean = false
  public isOnServer: boolean = false

  public markIsNotOnServer() {
    this.isOnServer = false
  }

  public isInRole(roleId) {
    return this.roles.includes(roleId)
  }

  public isInGroup(groupId) {
    return this.groups.includes(groupId)
  }

  public get isUser(): boolean {
    return this.type === 'user'
  }
}
