import React from 'react'
import { inject, observer } from 'mobx-react'
import { Card } from '@material-ui/core'
import ConsentWidgetHeader from './ConsentWidgetHeader'
import ConsentWidgetBody from './ConsentWidgetBody'
import ConsentDrawer from './ConsentDrawer'
import './ConsentWidget.scss'
import LocalizationStore from '../../localization/LocalizationStore'
import { ConsentsStore } from '../store/ConsentsStore'

interface Props {
  localizationStore?: LocalizationStore
  consentsStore?: ConsentsStore
}

const ConsentWidget: React.FC<Props> = ({ localizationStore, consentsStore }) => {
  return (
    <>
      <Card elevation={0} id='ConsentWidget'>
        <ConsentWidgetHeader />
        <ConsentWidgetBody />
      </Card>
      <ConsentDrawer />
    </>
  )
}

export default inject('consentsStore', 'localizationStore')(observer(ConsentWidget))
