import { RootStore } from '../../stores/RootStore'
import { action, observable, computed, reaction } from 'mobx'
import { NotificationTemplate } from '../../notifications/store/aggregates/NotificationTemplate'
import { Survey } from '../aggregate/Survey'
import { SurveyOptionsVM } from './SurveyOptionsVM'
import { NotificationTemplateVM } from './NotificationTemplateVM'
import { ISurveyNotificationTemplatesDTO } from '../dtos/ISurveyNotificationTemplatesDTO'
import { PLUGINS, TOOLBAR } from '../../config/TinyMCEconfig'
import uuid from 'uuid/v4'
import { FileUploadService } from '../../upload/services/FileUploadService'
import { CloudinaryResult } from '../../upload/aggregate/CloudinaryResult'
import { MediaUploadPickerVM } from '../../media-items/view-models/media-upload-picker/MediaUploadPickerVM'
import { MediaItem } from '../../media-items/aggregate/MediaItem'
import { Attachment } from '../../upload/aggregate/Attachment'
import { ParseService } from '../../services/ParseService'
import { SurveyTemplatesService } from '../../survey-templates/services/SurveyTemplatesService'

export class NotificationTemplatesEditorVM {
  private rootStore: RootStore
  private surveyOptionsVM: SurveyOptionsVM
  private surveyId: string

  constructor(rootStore: RootStore, survey: Survey, surveyOptionsVM: SurveyOptionsVM) {
    this.rootStore = rootStore
    this.surveyOptionsVM = surveyOptionsVM
    this.surveyId = survey.objectId
    this.lz = this.rootStore.localizationStore.lzStrings.surveys
    this.mediaUploadPickerVM = new MediaUploadPickerVM(
      this.rootStore,
      (media) => this.addMediaToInfoTextFromLibrary(media),
      true
    )
    this.loadData(survey)
    reaction(
      () => this.currentlyEditingNotificationTypeId,
      () =>
        this.setSelectedOptionValue(
          `${this.currentlyEditingNotificationTypeId}-${this.currentlyEditingChannel}-en`
        )
    )
    reaction(
      () => this.currentlyEditingChannel,
      () =>
        this.setSelectedOptionValue(
          `${this.currentlyEditingNotificationTypeId}-${this.currentlyEditingChannel}-en`
        )
    )
  }

  @observable public templates: Array<NotificationTemplateVM> = []
  @observable public editorRef: any = null
  @observable public selectedOptionValue: string = 'survey-published-EMAIL-en'
  @observable public showConfirmDialog: boolean = false
  @observable public lz = null
  @observable public showMediaModal: boolean = false
  @observable public mediaUploadPickerVM: MediaUploadPickerVM
  @observable public templateDataLoaded = false
  @observable public isFromPulseCampaign = false
  @observable showLinkDialog: boolean = false

  @action
  public setEditorRef(editorRef: any) {
    this.editorRef = editorRef
  }

  @action
  public setSelectedOptionValue(value) {
    this.selectedOptionValue = value
  }

  @action
  public async loadData(survey: Survey) {
    let fromSurveyId = this.surveyId
    if (survey.pulseCampaignId) {
      this.isFromPulseCampaign = true
      this.setSelectedOptionValue(
        `${this.currentlyEditingNotificationTypeId}-${this.currentlyEditingChannel}-en`
      )
    }
    if (!this.surveyId && survey.templateId) fromSurveyId = survey.templateId
    if (!fromSurveyId) {
      this.loadDefaultTemplates()
      this.templateDataLoaded = true
      return
    }

    let templates: ISurveyNotificationTemplatesDTO = null

    if (survey.isFromSystemTemplate) {
      templates = await new SurveyTemplatesService(
        this.rootStore
      ).getSurveyTemplateNotificationTemplates(fromSurveyId)
    } else {
      templates = await new ParseService().getSurveyNotificationTemplates(
        fromSurveyId,
        this.rootStore.appStore.currentOrgId
      )
    }

    if (!templates || !templates.publishedTemplates || !templates.reminderTemplates) {
      this.loadDefaultTemplates()
      this.templateDataLoaded = true
      return
    }
    if (Array.isArray(templates.publishedTemplates)) {
      templates.publishedTemplates.forEach((template) => {
        this.templates.push(new NotificationTemplateVM(this.rootStore, template))
      })
    }
    if (Array.isArray(templates.reminderTemplates)) {
      templates.reminderTemplates.forEach((template) => {
        this.templates.push(new NotificationTemplateVM(this.rootStore, template))
      })
    }
    this.templateDataLoaded = true
  }

  @action
  public findTemplateIndex(template) {
    const templateIdx = this.templates.findIndex(
      (t) =>
        t.notificationTypeId === template.notificationTypeId &&
        t.channel === template.channel &&
        t.language === template.language
    )
    return templateIdx
  }

  @action
  private loadDefaultTemplates() {
    if (this.defaultTemplates.length === 0) return
    for (let template of this.defaultTemplates) {
      this.templates.push(new NotificationTemplateVM(this.rootStore, template))
    }
  }

  @computed
  public get publishedTemplates() {
    return this.templates.filter((t) => t.notificationTypeId === 'survey-published')
  }

  @computed
  public get reminderTemplates() {
    const type = !this.isFromPulseCampaign ? 'survey-reminder' : 'campaign-survey-reminder'
    return this.templates.filter((t) => t.notificationTypeId === type)
  }

  @computed
  public get currentlyEditingNotificationTypeId():
    | 'survey-published'
    | 'survey-reminder'
    | 'campaign-survey-reminder' {
    if (this.isFromPulseCampaign) {
      if (this.surveyOptionsVM.tabIndex === 2) return 'survey-published'
      if (this.surveyOptionsVM.tabIndex === 3) return 'campaign-survey-reminder'
    }
    if (this.surveyOptionsVM.tabIndex === 2) return 'survey-published'
    if (this.surveyOptionsVM.tabIndex === 3) return 'survey-reminder'
    return 'survey-published'
  }

  @computed
  public get currentlyEditingChannel(): 'EMAIL' | 'TEXT' | 'PUSH' {
    if (this.currentlyEditingNotificationTypeId === 'survey-published') {
      if (this.surveyOptionsVM.publishedNotificationTabIndex === 0) return 'EMAIL'
      if (this.surveyOptionsVM.publishedNotificationTabIndex === 1) return 'TEXT'
      if (this.surveyOptionsVM.publishedNotificationTabIndex === 2) return 'PUSH'
    }
    if (
      this.currentlyEditingNotificationTypeId === 'survey-reminder' ||
      this.currentlyEditingNotificationTypeId === 'campaign-survey-reminder'
    ) {
      if (this.surveyOptionsVM.reminderTemplatesTabIndex === 0) return 'EMAIL'
      if (this.surveyOptionsVM.reminderTemplatesTabIndex === 1) return 'TEXT'
      if (this.surveyOptionsVM.reminderTemplatesTabIndex === 2) return 'PUSH'
    }
    return 'EMAIL'
  }

  @action
  private templateLanguageText(lang) {
    if (lang === 'en') return this.lz.english
    if (lang === 'de') return this.lz.german
  }

  @computed
  public get selectOptions() {
    return this.templates
      .filter(
        (template) =>
          template.notificationTypeId === this.currentlyEditingNotificationTypeId &&
          template.channel === this.currentlyEditingChannel
      )
      .map((template) => {
        const label = this.templateLanguageText(template.language)
        const value = `${template.notificationTypeId}-${template.channel}-${template.language}`
        return {
          label,
          value,
          notificationTypeId: template.notificationTypeId,
          channel: template.channel,
          language: template.language,
        }
      })
  }

  @computed
  public get isTemplateLinkValid(): boolean {
    const pattern = /<a[\s\S]*?href=[\s\S]*?Link[\s\S]*?>[\s\S]*?<\/a>/
    if (this.currentlyEditingChannel !== 'EMAIL') return true
    if (!this.currentTemplate) return true
    return pattern.test(this.currentTemplate.body)
  }

  @computed
  public get tinyMCEKey() {
    return `tinyMCE-${this.currentlyEditingChannel}`
  }

  @computed
  public get tinyMCEInit() {
    const vm = this
    const init = {
      height: 500,
      resize: false,
      menubar: false,
      plugins: PLUGINS as any,
      toolbar: TOOLBAR,
      browser_spellcheck: true,
    } as any
    if (this.currentlyEditingChannel === 'EMAIL') {
      init.toolbar = `customInsertButton ${TOOLBAR}`
      init.plugins = `${PLUGINS} image media fullpage`
      init.valid_children =
        '+a[h1|h2|h3|h4|h5|h6|div|span|div|img|name|href|target|title|clicktracking]'
      init.forced_root_block = false
      init.force_br_newlines = true
      init.force_p_newlines = true
      ;(init.extended_valid_elements =
        '+div[*],+a[*],+span[*],video[poster|src|controls|preload|width|height|data-setup],source[src|type]'),
        (init.images_reuse_filename = true)
      init.images_upload_handler = (blobInfo, success, failure, progress) =>
        this.uploadToCloudinary(blobInfo, success, failure, progress)
      init.setup = (editor) => {
        editor.on('CloseWindow', (e) => {
          setTimeout(() => {
            editor.execCommand('selectAll', false, 'texteditor')
            editor.selection.collapse()
            vm.showLinkDialog = false
          }, 100)
        })
        editor.on('ObjectSelected', (e) => {
          if (editor.selection.getNode().nodeName === 'A') {
            e.preventDefault()
            e.stopPropagation()
            e.stopImmediatePropagation()
            if (!vm.showLinkDialog) {
              vm.toggleShowLinkDialog()
              editor.execCommand('mceLink', null, 'openLink', null)
            }
          }
          if (editor.selection.getNode().nodeName === 'SPAN') {
            if (
              editor.selection.getNode().parentNode &&
              editor.selection.getNode().parentNode.nodeName === 'A'
            ) {
              e.preventDefault()
              e.stopPropagation()
              e.stopImmediatePropagation()
              if (!vm.showLinkDialog) {
                vm.toggleShowLinkDialog()
                editor.execCommand('mceLink', null, 'openLink', null)
              }
            }
          }
        })
        editor.ui.registry.addMenuButton('customInsertButton', {
          text: 'Upload Media',
          tooltip: 'Upload an image or video from the rippleworx media library or your computer.',
          icon: 'upload',
          fetch: function (callback) {
            let items: any = []
            if (this.rootStore.organizationsStore.currentOrganization?.hasCMSPicker) {
              items = [
                {
                  type: 'menuitem',
                  icon: 'gallery',
                  text: 'Content Management System',
                  onAction: () => {
                    vm.toggleMediaUploadPicker()
                  },
                },
              ]
            } else {
              items = [
                {
                  type: 'menuitem',
                  icon: 'gallery',
                  text: 'RippleWorx Media Library',
                  onAction: () => {
                    vm.toggleMediaUploadPicker()
                  },
                },
                {
                  type: 'menuitem',
                  icon: 'browse',
                  text: 'My Computer',
                  onAction: () => {
                    vm.toggleMediaUploadModal()
                  },
                },
              ]
            }
            callback(items)
          },
        })
      }
    }
    return init
  }

  public async uploadToCloudinary(blobInfo, success, failure, progress) {
    try {
      const blob = blobInfo.blob()
      var file = new File([blob], `tiny-${uuid()}`)
      const uploadSvc = new FileUploadService()
      const res: CloudinaryResult[] = await uploadSvc.uploadMediaItemsToCloudinary([file])
      if (res) return success(res[0].secure_url)
    } catch (e) {
      if (e) {
        return failure(e.toString())
      }
    }
  }

  @action
  private addMediaToInfoTextFromLibrary(mediaObject: MediaItem) {
    let content = ''
    if (mediaObject.type === 'video') {
      const url = mediaObject.fileUrl.toLowerCase()
      if (url.includes('vimeo')) {
        let regExp =
          /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/
        if (mediaObject.fileUrl.includes('player.vimeo.com')) {
          regExp =
            /(http|https)?:\/\/(www\.)?player.vimeo.com\/(?:video\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/
        }
        const match = mediaObject.fileUrl.match(regExp)
        const uuid = match && match.length ? match[4] : ''
        content = `<iframe height=220 width=320 src=${`https://player.vimeo.com/video/${uuid}`} allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullscreen />`
      } else if (url.includes('youtube') || url.includes('youtu.be')) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
        const match = mediaObject.fileUrl.match(regExp)
        const uuid = match && match[2].length === 11 ? match[2] : ''
        content = `<iframe height=220 width=320 src=${`https://www.youtube-nocookie.com/embed/${uuid}?modestbranding=1&showinfo=0&rel=0`} allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullscreen />`
      } else {
        content = `<video height=220 width=320 alt=${mediaObject.name} poster=${mediaObject.videoThumbnail} src=${mediaObject.fileUrl} controls='controls' />`
      }
    }
    if (mediaObject.type === 'image') {
      content = `<img alt=${mediaObject.name} src=${mediaObject.fileUrl} height=220 width=220 />`
    }
    return setTimeout(() => {
      this.editorRef?.current?.editor?.execCommand('mceInsertContent', false, content)
    }, 1000)
  }

  @action
  public addMediaToInfoTextFromComputer(attachment: Attachment) {
    let content = ''
    if (attachment.type === 'video') {
      const url = attachment.url.toLowerCase()
      if (url.includes('vimeo')) {
        let regExp =
          /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/
        if (attachment.url.includes('player.vimeo.com')) {
          regExp =
            /(http|https)?:\/\/(www\.)?player.vimeo.com\/(?:video\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/
        }
        const match = attachment.url.match(regExp)
        const uuid = match && match.length ? match[4] : ''
        content = `<iframe height=220 width=320 src=${`https://player.vimeo.com/video/${uuid}`} allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullscreen />`
      } else if (url.includes('youtube') || url.includes('youtu.be')) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
        const match = attachment.url.match(regExp)
        const uuid = match && match[2].length === 11 ? match[2] : ''
        content = `<iframe height=220 width=320 src=${`https://www.youtube.com/embed/${uuid}?modestbranding=1&showinfo=0&rel=0`} allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullscreen />`
      } else {
        content = `<video height=220 width=320 alt=${attachment.fileName} poster=${attachment.thumbnail} src=${attachment.url} controls='controls' />`
      }
    }
    if (attachment.type === 'image') {
      content = `<img alt=${attachment.fileName} src=${attachment.url} height=220 width=220 />`
    }
    return setTimeout(() => {
      this.editorRef?.current?.editor?.execCommand('mceInsertContent', false, content)
    }, 1000)
  }

  @action
  public toggleMediaUploadModal() {
    this.showMediaModal = !this.showMediaModal
  }

  @action
  public toggleMediaUploadPicker() {
    this.mediaUploadPickerVM.toggleShowMediaUploadPicker()
  }

  @computed
  public get currentTemplate(): NotificationTemplateVM {
    const selectedTemplate = this.selectOptions.find(
      (option) => option.value === this.selectedOptionValue
    )
    if (!selectedTemplate) return undefined
    return this.templates.find(
      (e) =>
        e.language === selectedTemplate.language &&
        e.notificationTypeId === this.currentlyEditingNotificationTypeId &&
        e.channel === this.currentlyEditingChannel
    )
  }

  @action
  public toggleShowLinkDialog() {
    this.showLinkDialog = !this.showLinkDialog
  }

  @computed
  public get defaultTemplates(): Array<NotificationTemplate> {
    if (!this.rootStore) return []
    if (!this.rootStore.notificationsStore) return []
    const pubType = 'survey-published'
    const remType = !this.isFromPulseCampaign ? 'survey-reminder' : 'campaign-survey-reminder'
    if (this.surveyOptionsVM.isSurveyTemplateEdit) {
      return this.rootStore.notificationsStore.defaultNotificationTemplates.filter(
        (t) => t.notificationTypeId === pubType || t.notificationTypeId === remType
      )
    }
    return this.rootStore.notificationsStore.currentOrgNotificationTemplates.filter(
      (t) => t.notificationTypeId === pubType || t.notificationTypeId === remType
    )
  }

  @action
  public toggleShowConfirmDialog() {
    this.showConfirmDialog = !this.showConfirmDialog
  }

  @action
  public refreshEditor(editorRef: any, body) {
    if (
      editorRef &&
      editorRef.current &&
      editorRef.current.editor &&
      editorRef.current.editor.setContent
    ) {
      editorRef.current.editor.setContent(body)
    }
  }

  @action
  public resetToDefaultTemplate(editorRef: any) {
    if (this.currentTemplate) {
      // specific template being edited, restore only that one
      const foundTemplate = this.defaultTemplates.find(
        (e) =>
          e.notificationTypeId === this.currentTemplate.notificationTypeId &&
          e.language === this.currentTemplate.language &&
          e.channel === this.currentTemplate.channel
      )
      const foundIndex = this.templates.findIndex(
        (e) =>
          e.notificationTypeId === this.currentTemplate.notificationTypeId &&
          e.language === this.currentTemplate.language &&
          e.channel === this.currentTemplate.channel
      )
      if (foundTemplate) {
        if (foundIndex > -1) this.templates.splice(foundIndex, 1)
        this.templates.push(new NotificationTemplateVM(this.rootStore, foundTemplate))
        this.refreshEditor(editorRef, foundTemplate.body)
      }
    } else {
      // no template being edited, restore all languages
      const foundTemplates = this.defaultTemplates.filter((e) => {
        const correctType =
          e.notificationTypeId === this.currentlyEditingNotificationTypeId &&
          e.channel === this.currentlyEditingChannel
        if (
          correctType &&
          e.language === 'de' &&
          this.rootStore.organizationsStore.currentOrganization.hasGerman
        ) {
          return true
        } else if (
          correctType &&
          e.language === 'en' &&
          this.rootStore.organizationsStore.currentOrganization.hasEnglish
        ) {
          return true
        }
        return false
      })
      foundTemplates.forEach((template) =>
        this.templates.push(new NotificationTemplateVM(this.rootStore, template))
      )
    }
  }
}
