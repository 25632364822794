import { action, observable, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { NotificationTemplatesEditorVM } from './NotificationTemplatesEditorVM'
import { PublishModeType } from '../types/PublishModeType'
import { SurveyEditVM } from './SurveyEditVM'
import { CustomPublishGroupsVM } from './CustomPublishGroupsVM'
import { CustomPublishGroupVM } from './CustomPublishGroupVM'
import { AudienceMember } from '../../audience-members/aggregate/AudienceMember'
import { OrganizationUsersService } from '../../organization-users/service/OrganizationUsersService'
import { Owner } from '../aggregate/Owner'
import { object } from 'serializr'

export class SurveyOptionsVM {
  private rootStore: RootStore
  private surveyEditVM: SurveyEditVM

  constructor(rootStore: RootStore, surveyEditVM: SurveyEditVM) {
    this.rootStore = rootStore
    this.surveyEditVM = surveyEditVM
    this.notificationTemplatesEditorVM = new NotificationTemplatesEditorVM(
      rootStore,
      surveyEditVM.survey,
      this
    )
    this.customPublishVM = new CustomPublishGroupsVM(
      rootStore,
      surveyEditVM.survey,
      this.surveyEditVM
    )
  }

  @observable public tabIndex: number = 0
  @observable public publishedNotificationTabIndex: number = 0 // 0 = email, 1 = text, 2 = push
  @observable public reminderTemplatesTabIndex: number = 0 // 0 = email, 1 = text, 2 = push
  @observable public notificationTemplatesEditorVM: NotificationTemplatesEditorVM = null
  @observable public customPublishVM: CustomPublishGroupsVM = null
  @observable public isCustomPublishGroup: boolean = false
  @observable public publishMode: PublishModeType = 'immediately'
  @observable public ownerDialogOpen: boolean = false
  @observable public FKTempPublishedByUser: Owner = null
  @observable public isSurveyTemplateEdit: boolean = false

  @action
  public getAllOwners(val?: string) {
    this.surveyEditVM.getAllOwners(val)
  }

  @computed
  public get publishedByUser() {
    let foundUser: Owner
    if (
      this.surveyEditVM.isSurveyTemplateEdit &&
      this.surveyEditVM.survey.organizationId === 'DEFAULT'
    ) {
      if (this.FKTempPublishedByUser) {
        foundUser = this.rootStore.systemAdminsStore.getSystemAdmin(
          this.FKTempPublishedByUser.objectId
        ) as Owner
      } else {
        foundUser = this.surveyEditVM.fkPublishedByUser as Owner
      }
    }
    if (!foundUser) return { name: '' } as Owner
    return foundUser
  }

  @action
  public setIsSurveyTemplateEdit(isSurveyTemplateEdit: boolean) {
    this.isSurveyTemplateEdit = isSurveyTemplateEdit
    if (this.isSurveyTemplateEdit && this.surveyEditVM.survey.organizationId === 'DEFAULT') {
      this.notificationTemplatesEditorVM = new NotificationTemplatesEditorVM(
        this.rootStore,
        this.surveyEditVM.survey,
        this
      )
    }
  }

  @action
  public setTabIndex(index: number) {
    this.tabIndex = index
    if (index === 2) {
      this.surveyEditVM.emailTemplates.editPublishedNotificationTemplates()
    }
    if (index === 3) {
      this.surveyEditVM.emailTemplates.editReminderTemplates()
    }
  }

  @action
  public setTempPublishedByUser(FKpublishedByUserId: Owner) {
    this.FKTempPublishedByUser = FKpublishedByUserId
  }

  @action
  public changeSurveyOwner() {
    this.surveyEditVM.setPublishedByUser(this.FKTempPublishedByUser.objectId)
    this.surveyEditVM.setFkPublishedByUser(this.FKTempPublishedByUser)
    this.ownerDialogOpen = false
  }

  @action
  public toggleShowOwnerDialog() {
    this.ownerDialogOpen = !this.ownerDialogOpen
  }

  @action
  public openChangeOwnerDialog() {
    this.getAllOwners()
    this.FKTempPublishedByUser = this.surveyEditVM.fkPublishedByUser
    this.ownerDialogOpen = true
  }

  @action
  public setPublishedNotificationTabIndex(num: number) {
    this.publishedNotificationTabIndex = num
  }

  @action
  public setReminderTemplatesTabIndex(num: number) {
    this.reminderTemplatesTabIndex = num
  }

  @action
  public toggleCustomPublishGroupDialogOpen() {
    this.customPublishVM.toggleCustomPublishGroupDialogOpen()
  }

  @action
  public addCustomPublishGroup(val: CustomPublishGroupVM) {
    this.customPublishVM.addCustomPublishGroup(val)
    this.surveyEditVM.openSnackbar('Custom Publish Group created successfully.')
  }

  @action
  public updateCustomPublishGroup(val: CustomPublishGroupVM) {
    this.customPublishVM.updateCustomPublishGroup(val)
    this.surveyEditVM.openSnackbar('Custom Publish Group updated successfully.')
  }

  @computed
  public get customPublishGroups() {
    return this.customPublishVM.customPublishGroups
  }

  @computed
  public get isCustomPublishGroupDialogOpen(): boolean {
    return this.customPublishVM.isCustomPublishGroupDialogOpen
  }

  @computed
  public get customPublishEnabled(): boolean {
    return this.isCustomPublishGroup
  }

  @action
  public setSurveyPublishModeType(val: PublishModeType) {
    this.publishMode = val
  }

  @computed
  public get surveyPublishModeType(): PublishModeType {
    return this.publishMode
  }

  @action
  public toggleCustomPublishGroup() {
    this.isCustomPublishGroup = !this.isCustomPublishGroup
  }

  @computed
  public get advancedTabText(): string {
    if (
      this.surveyEditVM.isSurveyTemplateEdit &&
      this.surveyEditVM.survey.organizationId === 'DEFAULT'
    )
      return 'Link Expiration'
    return 'Analysis Worksheet, Link Expiration'
  }
}
