import React from 'react'
import { inject, observer } from 'mobx-react'
import { WeightProfilesStore } from '../../../store/WeightProfilesStore'
import CategoriesScreenHeader from './CategoriesScreenHeader'
import CategoriesScreenBody from './CategoriesScreenBody'

import './CategoriesScreen.scss'
import { CircularProgress } from '@material-ui/core'

interface Props {
  weightProfilesStore?: WeightProfilesStore
}

const CategoriesScreen: React.FC<Props> = ({ weightProfilesStore }) => {
  const { editVM } = weightProfilesStore
  if (!editVM) return null
  if (!editVM.selectedRole) return null
  
  if (!editVM.selectedRole.weightProfileVM) return (
    <CircularProgress
      color='secondary'
      style={{ position: 'fixed', top: '50%', left: '60%' }}
    />
  )

  return (
    <div id='CategoriesScreen'>
      <CategoriesScreenHeader />
      <CategoriesScreenBody />
    </div>
  )
}

export default inject('weightProfilesStore')(observer(CategoriesScreen))
