import { observable } from 'mobx'
import { OrganizationUser } from '../../organization-users/aggregate/OrganizationUser'

export class Owner {
  constructor(orgUser?: OrganizationUser) {
    if (orgUser) {
      this.objectId = orgUser.fk_User.objectId
      this.name = orgUser.fk_User.name
    }
  }

  static create(o) {
    const owner = new Owner()
    owner.name = o.name
    owner.objectId = o.objectId
    return owner
  }

  @observable public objectId: string = ''
  @observable public name: string = ''
}
