import React from 'react'
import {
  Dialog,
  DialogTitle,
  IconButton,
  Grid,
  DialogContent,
  Button,
  Typography,
  DialogActions,
} from '@material-ui/core'
import { observer, inject } from 'mobx-react'
import { EventSendNotificationsVM } from '../view-models/EventSendNotificationsVM'
import LocalizationStore from '../../localization/LocalizationStore'
import SendRoundedIcon from '@material-ui/icons/SendRounded'
import './EventSendNotificationsPrompt.scss'
import SendNotificationSVG from '../../../assets/img/send-notification.svg'

interface Props {
  vm: EventSendNotificationsVM
  localizationStore?: LocalizationStore
}

const EventSendNotificationsPrompt: React.FC<Props> = ({ vm, localizationStore }) => {
  if (!vm) return null

  return (
    <Dialog id={'EventSendNotificationsPrompt'} onClose={() => vm.toggleShowNotificationsModal()} open={vm.showNotificationsModal} fullWidth>
      <DialogTitle className='dialog-title'>Send Notifications</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item md={1} sm={1}>
            <img className={'send-image'} src={SendNotificationSVG} />
          </Grid>
          <Grid item md={11} sm={11} className='confirm-message-view'>
            <p className='confirm-message'>Do you wish to send notifications to participants?</p>
            <p className='cannot-undo-text'><span className={'note-span'}>Note:</span> Any established reminders will still be sent as scheduled.</p>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => vm.setSendNotifications(false)}>
          No, Don't Send
        </Button>
        <Button variant='contained' onClick={() => vm.setSendNotifications(true)}>
          <SendRoundedIcon className='send-icon-btn' /> Yes, Send
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default inject('eventsStore')(observer(EventSendNotificationsPrompt))
