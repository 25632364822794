import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { CardHeader, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

interface DrawerHeaderProps {
  handleClose: () => void
  localizationStore?: LocalizationStore
}

const DrawerHeader: React.FC<DrawerHeaderProps> = ({ handleClose, localizationStore }) => {
  const strings = localizationStore.lzStrings
  return (
    <CardHeader
      action={
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      }
      title={strings.pendingUserTasksWidgetDrawer.view_task}
    />
  )
}

export default inject('localizationStore')(observer(DrawerHeader))
