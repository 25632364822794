import React from 'react'
import { useLocalStore } from 'mobx-react'
import rootStore from '../../stores/RootStore'
import NotificationTemplatesCard from '../../notifications/views/NotificationTemplatesCard'
import NotificationsWidgetDrawer from '../../notifications/widgets/Drawer'
import NotificationTemplatesDialog from '../../notifications/views/NotificationTemplatesDialog'
import { NotificationsWidgetVM } from '../../notifications/store/view-models/NotificationsWidgetVM'
import './table/EmailTemplatesTable.scss'

interface Props {}

const SystemDefaultEmailTemplates: React.FC<Props> = ({}) => {

  const vm = new NotificationsWidgetVM(rootStore)
  vm.isSystemOnly = true
  vm.channels = ['EMAIL']
  const localStore = useLocalStore(() => ({
    vm: vm,
  }))

  return (
    <>
      <div className='templates-card'>
        <NotificationTemplatesCard vm={localStore.vm} />
        <NotificationsWidgetDrawer vm={localStore.vm} />
        <NotificationTemplatesDialog vm={localStore.vm} />
      </div>
    </>
  )
}

export default SystemDefaultEmailTemplates
