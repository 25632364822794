import React from 'react'
import { inject, observer } from 'mobx-react'
import { Drawer } from '@material-ui/core'
import { DashboardsStore } from '../../DashboardsStore'
import MuiDrawerHeader from '../../../shared/Mui-Drawer/MuiDrawerHeader'
import MuiDrawerToolbar from '../../../shared/Mui-Drawer/MuiDrawerToolbar'
import ParticipantsSelect from '../../../participants-select/views/ParticipantsSelect'
import SpinnerOverlay from '../../../shared/SpinnerOverlay'
import './ShareDashboardDrawer.scss'

interface Props {
  dashboardsStore?: DashboardsStore
}

const ShareDashboardDrawer: React.FC<Props> = ({ dashboardsStore }) => {
  const vm = dashboardsStore.sharedWithVM
  if (!vm) return null

  const toggleDrawer = () => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    vm.closeDrawer()
  }

  const renderSpinner = () => {
    if (!vm.isProcessing) return
    return <SpinnerOverlay />
  }

  return (
    <Drawer
      variant='temporary'
      anchor='right'
      open={vm.isOpen}
      onClose={toggleDrawer()}
      className='share-dashboard-drawer'
    >
      <div id='ShareDashboardDrawer'>
        {renderSpinner()}
        <MuiDrawerHeader title='Share Dashboard' toggleDrawer={toggleDrawer()} />
        <MuiDrawerToolbar
          disabled={Boolean(vm.isProcessing)}
          handleCancelClick={() => vm.closeDrawer()}
          handleSubmitClick={() => vm.saveSharedWith()}
        />
        <div id='ShareDashboardParticipantSelect'>
          <ParticipantsSelect participantsSelectVM={vm.sharedWithVM} />
        </div>
      </div>
    </Drawer>
  )
}

export default inject('dashboardsStore', 'audienceMembersStore')(observer(ShareDashboardDrawer))
