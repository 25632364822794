import { computed } from 'mobx'
import { AGGridRowVM } from '../../shared/ag-grid/AGGridRowVM'
import { RootStore } from '../../stores/RootStore'
import { Contact } from '../aggregate/Contact'
import { ContactListWidgetVM } from './ContactListWidgetVM'

export class ContactRowVM extends AGGridRowVM<Contact> {
  constructor(rootStore: RootStore, contact: Contact, listVM: ContactListWidgetVM) {
    super(rootStore, contact, listVM)
  }

  @computed
  public get objectId() {
    return this.aggregate.objectId
  }

  @computed
  public get organizationId() {
    return this.aggregate.organizationId
  }

  @computed
  public get name() {
    return this.aggregate.name
  }

  @computed
  public get email() {
    return this.aggregate.email
  }

  @computed
  public get clientId() {
    return this.aggregate.clientId
  }

  @computed
  public get title() {
    return this.aggregate.title
  }

  @computed
  public get clientName() {
    if (!this.rootStore.clientsStore) return ''
    const foundClient = this.rootStore.clientsStore.getClient(this.clientId)
    if (!foundClient) return ''
    return foundClient.name
  }

  openContact() {
    this.rootStore.contactsStore.setSelectedContactToEdit(this.aggregate)
  }

  public toDTO() {
    return {
      objectId: this.objectId,
      name: this.name,
      organizationId: this.organizationId,
      clientName: this.clientName,
      clientId: this.clientId,
      title: this.title,
      email: this.email,
    }
  }
}
