import { reaction, action, computed, observable } from 'mobx'
import SurveyType from '../store/aggregate/SurveyType'
import { AGGridRowVM } from '../../shared/ag-grid/AGGridRowVM'
import { SurveyTypesTableVM } from './SurveyTypesTableVM'
import { RootStore } from '../../stores/RootStore'
import { LabelsStore } from '../../labels/store/LabelsStore'
import { SurveyTypesService } from '../service/SurveyTypesService'

export class SurveyTypesTableRowVM extends AGGridRowVM<SurveyType> {
  private listVM: SurveyTypesTableVM

  constructor(
    rootStore: RootStore,
    surveyType: SurveyType,
    listVM: SurveyTypesTableVM
  ) {
    super(rootStore, surveyType, listVM)
    this.labelsStore = this.rootStore.labelsStore
    this.listVM = listVM
  }

  @observable public labelsStore: LabelsStore = null
  @observable public deleteConfirm: boolean = false
  @observable deletePending: boolean = false

  @computed
  public get objectId(): string {
    return this.aggregate.objectId
  }

  @computed
  public get name(): string {
    return this.aggregate.name
  }

  @computed
  public get description(): string {
    return this.aggregate.description
  }

  @action
  public navigateToSurveyTypeEdit() {
    const screenType = this.listVM.manageVM.screenType
    this.rootStore.appStore.router.push(`/surveyTypes/edit/${screenType}/${this.objectId}`)
  }

  @action
  public openDeleteDialog() {
    this.deleteConfirm = true
    this.listVM.selectedRow = this
  }

  @action
  public closeDeleteDialog() {
    this.deleteConfirm = false
    this.listVM.selectedRow = null
  }

  @action
  public async deleteSurveyTemplate() {
    this.deletePending = true
    this.closeDeleteDialog()
    const svc = new SurveyTypesService(this.rootStore)
    const result = await svc.deleteSurveyType(this.objectId)
    if (result) {
      this.deletePending = false
      this.deleteConfirm = false
      this.listVM.openSnackbar('Survey Type deleted successfully')
      this.listVM.hardRefreshTemplatesList()
    }
   
  }
}
