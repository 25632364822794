import { action, computed, observable } from 'mobx'
import env from '../../../env'
import { RootStore } from '../../stores/RootStore'
import { ExportRoleVM } from './ExportRoleVM'
import WeightProfilesImportVM from './WeightProfilesImportVM'

export default class WeightProfileExportVM {
  private rootStore: RootStore
  private importVM: WeightProfilesImportVM

  constructor(rootStore: RootStore, importVM: WeightProfilesImportVM) {
    this.rootStore = rootStore
    this.importVM = importVM
  }

  @observable allSelected: boolean = false

  @action
  public toggleAllSelected() {
    this.allSelected = !this.allSelected
    if (!this.allSelected) {
      return this.roles.forEach((e) => (e.isSelected = false))
    }
    this.roles.forEach((e) => (e.isSelected = true))
  }

  @action
  public rowGetter(index: number) {
    return this.roles[index].height + 20
  }

  @action
  public downloadTemplate() {
    window.location.href = `${env.var.REACT_APP_API_URL}/exportWeightProfilesToCsv?orgId=${this.rootStore.appStore.currentOrgId}&roleIds=${this.selectedRoleIds}`
    this.importVM.toggleExportScreen()
  }

  @computed
  public get roles(): Array<ExportRoleVM> {
    if (!this.rootStore) return []
    if (!this.rootStore.rolesStore.roles) return []
    const availableRoles = this.rootStore.rolesStore.roles
    return availableRoles
      .map((role) => new ExportRoleVM(this.rootStore, role, this))
      .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0))
  }

  @computed
  public get exportValid(): boolean {
    return Boolean(this.selectedRoles.length !== 0)
  }

  @computed
  public get selectedRoleIds(): Array<string> {
    return this.selectedRoles.map((e) => e.objectId)
  }

  @computed
  public get selectedRoles(): Array<ExportRoleVM> {
    return this.roles.filter((e) => e.isSelected)
  }
}
