import { observable, action } from 'mobx'
import { serializable } from 'serializr'
import { AlarmPeriodType } from '../types/AlarmPeriodType'
import uuid from 'uuid/v4'

export class Alarm {
  public static create(): Alarm {
    const alarm = new Alarm()
    alarm.id = uuid()
    alarm.units = 1
    alarm.period = 'hours'
    return alarm
  }
  public static createOnTimeAlarm(): Alarm {
    const alarm = new Alarm()
    alarm.id = uuid()
    alarm.units = 0
    alarm.period = 'minutes'
    return alarm
  }

  @serializable @observable public id: string
  @serializable @observable public units: number
  @serializable @observable public period: AlarmPeriodType

  @action
  public setUnits(val: number) {
    this.units = Number(val)
  }

  @action
  public setPeriod(val: AlarmPeriodType) {
    this.period = val
  }
}
