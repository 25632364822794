import { RowClickedEvent } from 'ag-grid-community'
import { action, computed, observable } from 'mobx'
import { RootStore } from '../../../../stores/RootStore'
import { UpcomingPulseRowVM } from './UpcomingPulseRowVM'
import { UpcomingPulseDataStore } from './UpcomingPulseDataStore'

export class UpcomingPulseVM {
  private rootStore: RootStore
  public dataStore: UpcomingPulseDataStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.lz = this.rootStore.localizationStore.lzStrings.pulseCampaignDashboard
    this.labelsStore = this.rootStore.labelsStore
    this.dataStore = new UpcomingPulseDataStore(this.rootStore)
    this.loadStore()
  }

  @observable public lz = undefined
  @observable public labelsStore = undefined

  public getRowNodeId(row: UpcomingPulseRowVM): string {
    return row.objectId
  }

  public get shouldRender(): boolean {
    if (!this.dataStore.listRecordsLoaded) return false
    return true
  }

  @computed
  public get rows(): UpcomingPulseRowVM[] {
    return this.dataStore.upcomingSurveys
      .map((draft) => new UpcomingPulseRowVM(this.rootStore, draft))
      .sort((a, b) =>
        new Date(a.deliveryDate).getTime() < new Date(b.deliveryDate).getTime() ? -1 : 1
      )
  }

  public rowClicked(e: RowClickedEvent) {}

  @computed
  public get hasRows(): boolean {
    return this.dataStore.hasListRecords
  }

  @computed
  public get isSystemAdmin(): boolean {
    return this.rootStore.appStore.isSystemAdmin
  }

  @computed
  protected get totalRecords() {
    return this.dataStore.totalRecords
  }

  @action
  public loadStore() {
    this.dataStore.loadListRecords()
  }

  @action
  public createPulseCampaign() {
    this.rootStore.pulseCampaignsStore.viewModels.createCampaignDialogVM.openCreateCampaignDialog()
  }
}
