import React from 'react'
import { CardContent } from '@material-ui/core'
import NotificationTemplatesTable from './NotificationTemplatesTable'
import { NotificationsWidgetVM } from '../store/view-models/NotificationsWidgetVM'

interface Props {
  vm?: NotificationsWidgetVM
}

const Content: React.FC<Props> = ({ vm }) => {
  return (
    <CardContent className='no-padding'>
      <NotificationTemplatesTable vm={vm} />
    </CardContent>
  )
}

export default Content
