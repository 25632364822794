import React from 'react'
import { observer, inject } from 'mobx-react'
import {
  Button,
  ButtonGroup,
  Checkbox,
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import Searchbar from '../../../shared/Searchbar'
import LocalizationStore from '../../../localization/LocalizationStore'
import RefreshIcon from '@material-ui/icons/Refresh'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { SurveyTemplatesManageVM } from '../../view-models/SurveyTemplatesManageVM'
import './SurveyTemplatesHeader.scss'
import MuiConfirmDeleteDialog from '../../../organization-users/views/list/MuiConfirmDeleteDialog'
import MuiConfirmPrompt from '../../../shared/MuiConfirmPrompt'
import MDSpinner from 'react-md-spinner'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Organization } from '../../../organizations-sa/aggregate/Organization'

interface Props {
  vm: SurveyTemplatesManageVM
  localizationStore?: LocalizationStore
}

const useStyles = makeStyles({
  popOverRoot: {
    pointerEvents: 'none',
  },
})

const OrganizationUsersListTableHeader: React.FC<Props> = ({ vm, localizationStore }) => {
  const { tableVM: surveyTemplatesTable } = vm
  const styles = useStyles()
  if (!surveyTemplatesTable) return null
  const lz = localizationStore.lzStrings.orgUsersList

  const handleClose = (value: string) => {
    surveyTemplatesTable.setModifyMenuAnchorEl(null)
    if (value === 'delete') surveyTemplatesTable.toggleDeleteTemplatesConfirmDialog()
    if (value === 'copy') surveyTemplatesTable.toggleCopyTemplatesConfirmDialog()
    if (value === 'move') surveyTemplatesTable.toggleIsMovingTemplates()
  }

  const renderAddOption = () => {
    return (
      <div>
        <MenuItem onClick={() => handleClose('move')}>
          <ListItemText>Move to... </ListItemText>
        </MenuItem>
      </div>
    )
  }

  const renderDeleteTemplatesOption = () => {
    return <MenuItem onClick={() => handleClose('delete')}>Delete Templates</MenuItem>
  }

  const renderCopyTemplatesOption = () => {
    return <MenuItem onClick={() => handleClose('copy')}>Copy Templates</MenuItem>
  }

  const renderModifyButton = () => {
    if (vm.screenType === 'tenantManage' && vm.foldersVM.isSystemToggleMode) return null
    // if (surveyTemplatesTable.tableHasBeenEdited) return
    return (
      <div onMouseLeave={(e) => surveyTemplatesTable.nullAllAnchors()}>
        <Button
          disabled={surveyTemplatesTable.actionButtonDisabled}
          onClick={(e) => surveyTemplatesTable.setModifyMenuAnchorEl(e.currentTarget)}
          onMouseOver={(e) => surveyTemplatesTable.setModifyMenuAnchorEl(e.currentTarget)}
          variant='contained'
          className={
            surveyTemplatesTable.actionButtonDisabled
              ? 'manage-users-button-disabled'
              : 'manage-users-button'
          }
        >
          {lz.action} <ArrowDropDownIcon />
        </Button>
        <Menu
          id='simple-menu'
          anchorEl={surveyTemplatesTable.modifyMenuAnchorEl}
          keepMounted
          open={Boolean(surveyTemplatesTable.modifyMenuAnchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          onMouseLeave={() => handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          style={{ marginTop: 40 }}
          MenuListProps={{ onMouseLeave: (e) => surveyTemplatesTable.setModifyMenuAnchorEl(null) }}
        >
          {renderActionItems()}
        </Menu>
      </div>
    )
  }

  const renderActionItems = () => {
    return (
      <div>
        {renderAddOption()}
        {renderDeleteTemplatesOption()}
        {renderCopyTemplatesOption()}
      </div>
    )
  }

  const renderSearch = () => {
    return (
      <div className='search'>
        <Searchbar
          focused={surveyTemplatesTable.hasFilter}
          searchValue={surveyTemplatesTable.typedFilterText}
          onSearchChange={(e) => {
            surveyTemplatesTable.setQuickFilter(e.target.value)
          }}
          className='action-btn'
        />
      </div>
    )
  }

  const renderSelectedTemplatesCount = () => {
    if (surveyTemplatesTable.hideCounts) return null
    if (surveyTemplatesTable.newSelectedRows.length === 0) return null
    if (surveyTemplatesTable.selectAll && surveyTemplatesTable.unSelectedRows.length === 0)
      return (
        <div className='selected-user-count'>{` All ${surveyTemplatesTable.totalRecords} Templates selected`}</div>
      )
    if (surveyTemplatesTable.selectAll) {
      const count = surveyTemplatesTable.totalRecords - surveyTemplatesTable.unSelectedRows.length
      return <div className='selected-user-count'>{`${count} Templates selected`}</div>
    }
    return (
      <div className='selected-user-count'>
        {` ${surveyTemplatesTable.newSelectedRows.length} Templates selected`}
      </div>
    )
  }

  const renderImport = () => {
    if (vm.screenType === 'tenantManage') return null
    return (
      <div className='import-action-btn'>
        <Tooltip
          title={'Import Templates'}
          placement='bottom'
        >
          <IconButton onClick={() => surveyTemplatesTable.navigateToImportSurveyTemplates()}>
            <img
              className={'import-icon'}
              alt={'import'}
              src='../../../../assets/img/icons/Import-Export.svg'
            />
          </IconButton>
        </Tooltip>
      </div>
    )
  }

  const renderRefreshIcon = () => {
    if (!vm.tableVM.isLoaded) return <MDSpinner size={20} singleColor='#05d1cf' />
    return <RefreshIcon />
  }

  const renderRefreshButton = () => {
    return (
      <Tooltip title={'Refresh'} placement='bottom'>
        <Button
          onClick={(e) => {
            surveyTemplatesTable.hardRefreshTemplatesList()
          }}
          variant='outlined'
          className='action-btn-end refresh-btn'
        >
          {renderRefreshIcon()}
        </Button>
      </Tooltip>
    )
  }

  const renderCreateButton = () => {
    if (vm.screenType === 'tenantManage' && vm.foldersVM.isSystemToggleMode) return null
    return (
      <div className='action-btn-add'>
        <Tooltip title={'Add New Template'} placement='bottom'>
          <span>
            <Button
              onClick={() => vm.navigateToCreateSurveyTemplate()}
              variant='contained'
              className={'manage-users-button'}
              // disabled={!tableVM.isLoaded}
            >
              New Template
            </Button>
          </span>
        </Tooltip>
      </div>
    )
  }

  const renderConfirmDeleteDialog = () => {
    return (
      <MuiConfirmDeleteDialog
        confirmationMessage={`Are you sure you would like to Delete ${surveyTemplatesTable.dialogCount} Templates?`}
        open={surveyTemplatesTable.showDeleteTemplatesDialog}
        onConfirm={() => {
          surveyTemplatesTable.deleteTemplates()
        }}
        onClose={() => surveyTemplatesTable.toggleDeleteTemplatesConfirmDialog()}
      />
    )
  }

  const renderConfirmCopyDialog = () => {
    return (
      <MuiConfirmPrompt
        confirmMessage={`Are you sure you would like to Copy ${surveyTemplatesTable.dialogCount} Templates?`}
        onClose={() => surveyTemplatesTable.toggleCopyTemplatesConfirmDialog()}
        open={surveyTemplatesTable.showCopyTemplatesDialog}
        onConfirm={() => {
          surveyTemplatesTable.copyTemplates()
        }}
        title='Copy Templates'
      />
    )
  }

  const renderConfirmMoveDialog = () => {
    return (
      <MuiConfirmPrompt
        confirmMessage={`Are you sure you would like to Move ${surveyTemplatesTable.dialogCount} Templates to ${surveyTemplatesTable.selectedMoveToFolder}?`}
        onClose={() => surveyTemplatesTable.toggleMoveTemplatesConfirmDialog()}
        open={surveyTemplatesTable.showMoveTemplatesDialog}
        onConfirm={() => {
          surveyTemplatesTable.moveTemplatesToFolder()
        }}
        title='Move Templates'
      />
    )
  }

  const renderRightLeft = () => {
    if (vm.screenType === 'permissions') return null
    if (vm.screenType === 'tenantManage' && vm.foldersVM.isSystemToggleMode) return null
    return (
      <>
        {renderImport()}
        {renderCreateButton()}
        {renderModifyButton()}
        {renderSelectedTemplatesCount()}
      </>
    )
  }

  const renderOrganizationSelect = () => {
    if (vm.screenType !== 'permissions') return null
    return (
      <div className='temp-orgs'>
        <Autocomplete
          className='orgs-input toolbar-input'
          value={vm.selectedOrg}
          multiple={false}
          filterSelectedOptions
          options={vm.availableOrgs.slice()}
          getOptionLabel={(e) => e.name}
          onChange={(e, val: Organization) => vm.setSelectedOrg(val)}
          renderInput={(params) => (
            <TextField
              placeholder={vm.selectedOrg ? 'Select Organization' : ''}
              {...params}
              variant='outlined'
              className={'org-input-textfield'}
            />
          )}
        />
      </div>
    )
  }

  const renderMoveFolderText = () => {
    if (!vm.foldersVM.isMovingTemplatesToFolder) return
    return (
      <div className='move-text'>
      {'Please Select a folder.'}
      </div>
    )
  }

  return (
    <div id='STTableHeader'>
      {renderConfirmMoveDialog()}
      {renderConfirmDeleteDialog()}
      {renderConfirmCopyDialog()}
      <div className='manage-templates-container'>
        <div className='left'>
          {renderMoveFolderText()}
          {renderOrganizationSelect()}
          </div>
        <div className='right'>
          <div className='right-left'>{renderRightLeft()}</div>
          <div className='right-right'>
            {renderSearch()}
            {renderRefreshButton()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default inject(
  'organizationUsersStore',
  'localizationStore'
)(observer(OrganizationUsersListTableHeader))
