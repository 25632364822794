import { observable } from 'mobx'
import { serializable } from 'serializr'

export class EmailTemplate {
  static create(temp: any) {
    const emailTemplate = new EmailTemplate()
    emailTemplate.channel = temp.channel
    emailTemplate.subject = temp.subject
    emailTemplate.body = temp.body
    emailTemplate.notificationTypeId = temp.notificationTypeId
    emailTemplate.language = temp.language
    emailTemplate.organizationId = temp.organizationId

    return emailTemplate
  }

  @serializable @observable channel: string = ''
  @serializable @observable subject: string = ''
  @serializable @observable body: string = ''
  @serializable @observable notificationTypeId: string = ''
  @serializable @observable language: string = ''
  @serializable @observable organizationId: string = ''
}
