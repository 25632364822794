import { RootStore } from '../../stores/RootStore'
import { observable, computed, action } from 'mobx'
import { CalendarVM } from './CalendarVM'
import { ILocationDTO } from '../../locations/dtos/ILocationDTO'
import { EventsService } from '../services/EventsService'
import { PopupParticipantVM } from './PopupParticipantVM'
import { IEventPillVM } from '../intefaces/IEventPillVM'
import { IEventStatusReadResult } from '../intefaces/IEventStatusReadResult'
import { Event } from '../aggregate/Event'
import { UserEvent } from '../user-events/aggregate/UserEvent'
import moment from 'moment'
import { deserialize } from 'serializr'
import { EventEditVM } from './EventEditVM'
import { EventsCopyService } from '../services/EventsCopyService'
import { MasterEventPromptType } from '../types/MasterEventPromptType'
import { EventSendNotificationsVM } from './EventSendNotificationsVM'
import { isNumeric } from '../../shared/isNumeric'
import { AttachmentVM } from '../../attachments/view-models/AttachmentVM'
import { OldAttachmentVM } from '../../attachments/view-models/OldAttachmentVM'

export class UserEventPopupVM {
  private rootStore: RootStore
  private calendarVM: CalendarVM
  @observable private settingGoingStatus: string
  @observable private goingStatusResult: IEventStatusReadResult

  constructor(rootStore: RootStore, calendarVM: CalendarVM, pillVM: IEventPillVM) {
    this.rootStore = rootStore
    this.calendarVM = calendarVM
    this.eventPill = pillVM
    this.checkReadStatus()
    this.getAnchorElement()
    this.getGoingStatuses()
  }

  @observable private eventPill: IEventPillVM
  @observable public isProcessing: boolean = false
  @observable public masterEventPromptShown: boolean = false
  @observable public masterEventPromptMode: MasterEventPromptType = 'edit'
  @observable public seriesGoingPromptShown: boolean = false
  @observable public isLoadingGoingStatuses: boolean = false
  @observable public eventSendNotificationsVM: EventSendNotificationsVM = null

  @action
  public async getGoingStatuses() {
    this.isLoadingGoingStatuses = true
    const svc = new EventsService()
    this.goingStatusResult = await svc.getGoingStatuses(
      this.rootStore.appStore.currentOrgId,
      this.userEvent.eventId,
      this.userEvent.startDate,
      this.userEvent.isFakeEvent
    )
    this.isLoadingGoingStatuses = false
  }

  @computed
  public get event(): Event {
    if (this.eventPill.userEvent) return this.userEvent
    return this.rootStore.eventsStore.getEvent(this.eventPill.objectId)
  }

  @computed
  public get userEvent(): UserEvent {
    if (!this.eventPill.userEvent) return null
    if (this.eventPill.userEvent.isFakeEvent) return this.eventPill.userEvent
    const ue = this.rootStore.userEventsStore.getUserEvent(this.eventPill.userEvent.objectId)
    return ue
  }

  @action
  private getAnchorElement() {
    setTimeout(() => (this.anchorEl = document.getElementsByClassName('selectedEvent')[0]), 20)
  }

  @action
  private async checkReadStatus() {
    if (!this.userEvent) return
    if (this.userId !== this.rootStore.appStore.currentUserId) return
    let doMarkAsRead = false
    if (!this.userEvent.readStatus) doMarkAsRead = true
    if (this.userEvent.readStatus === 'new') doMarkAsRead = true
    if (this.userEvent.readStatus === 'unread') doMarkAsRead = true
    if (!doMarkAsRead) return
    const svc = new EventsService()
    await svc.markEventAsRead(
      this.rootStore.appStore.currentUserId,
      this.userEvent.eventId,
      this.rootStore.appStore.currentOrgId
    )
  }

  @observable public anchorEl: any = null

  @computed
  public get goingStatus(): string {
    return this.userEvent.goingStatus
  }

  @computed
  public get goingYesUserIds(): string[] {
    if (this.goingStatusResult) return this.goingStatusResult.goingYesUserIds
    if (this.userEvent.isFakeEvent) return []
    return this.event.goingYesUserIds
  }

  @computed
  public get allDay(): boolean {
    return this.event.allDay
  }

  @computed
  public get goingNoUserIds(): string[] {
    if (this.goingStatusResult) return this.goingStatusResult.goingNoUserIds
    if (this.userEvent.isFakeEvent) return []
    return this.event.goingNoUserIds
  }

  @computed
  public get goingMaybeUserIds(): string[] {
    if (this.goingStatusResult) return this.goingStatusResult.goingMaybeUserIds
    if (this.userEvent.isFakeEvent) return []
    return this.event.goingMaybeUserIds
  }

  @computed
  public get isRead(): boolean {
    return this.userEvent.isRead
  }

  @computed
  public get isFakeEvent(): boolean {
    return this.userEvent.isFakeEvent
  }

  public get notes(): string {
    // if(!this.event.notes) return ''
    return this.event.notes
  }

  @computed
  public get goingStatusSubTitle(): string {
    if (!this.goingStatusResult) return ''
    const yesStr = `${this.goingStatusResult.goingYesUserIds.length} Yes, `
    const noStr = `${this.goingStatusResult.goingNoUserIds.length} No, `
    const maybeStr = `${this.goingStatusResult.goingMaybeUserIds.length} Maybe, `
    const awaitingStr = `${this.goingStatusResult.goingNewUserIds.length} Awaiting`
    return yesStr + noStr + maybeStr + awaitingStr
  }

  @computed
  public get attachments(): AttachmentVM[] {
    return this.event.attachments.map((e, idx) => {
      if (isNumeric(e.objectId) || e.cmsItemId) {
        return this.rootStore.cmsItemAttachmentStore.loadAttachment(e)
      }
      return new OldAttachmentVM(this.rootStore, e, idx)
    })
  }

  @action
  public async setGoingStatus(value: string) {
    this.isProcessing = false
    this.settingGoingStatus = value
    if (this.userEvent.isInSeries || (this.event.schedule && this.event.schedule.enabled)) {
      this.showSeriesGoingPrompt()
      return
    }
    this.setGoingStatusOneTime()
  }

  @action
  private showSeriesGoingPrompt() {
    this.seriesGoingPromptShown = true
  }

  @action
  public hideSeriesGoingPrompt() {
    this.seriesGoingPromptShown = false
  }

  @action
  public async setGoingStatusFuture(): Promise<void> {
    this.hideSeriesGoingPrompt()
    this.userEvent.setGoingStatus(this.settingGoingStatus, false)
    await this.setGoingStatusOnServer()
    this.close()
    this.calendarVM.refresh()
  }

  @action
  public async setGoingStatusOneTime(): Promise<void> {
    this.hideSeriesGoingPrompt()
    this.userEvent.setGoingStatus(this.settingGoingStatus, true)
    await this.setGoingStatusOnServer()
    this.close()
    this.calendarVM.refresh()
  }

  @action
  public async setGoingStatusOnServer(): Promise<void> {
    const svc = new EventsService()
    this.isProcessing = true
    const { currentUserId, currentOrgId } = this.rootStore.appStore
    await svc.setGoingStatus(
      currentOrgId,
      currentUserId,
      this.userEvent.eventId,
      this.userEvent.goingStatus,
      moment(this.startDate).toISOString(),
      this.userEvent.goingStatusOnce
    )
    this.isProcessing = false
  }

  @action
  public createSurvey() {
    let url = '/surveys/foruserevent'
    url += '/' + this.userEvent.eventId
    url += '/' + this.userEvent.userId
    this.rootStore.appStore.router.push(url)
    this.close()
  }

  @action
  public async handleMarkAllGoing() {
    const svc = new EventsService()
    await svc.setGoingStatusYes(this.event.organizationId, this.userEvent.eventId, this.userId)
    this.close()
  }

  @computed
  public get title(): string {
    return this.eventPill.title
  }

  @computed
  public get startDate(): Date {
    return this.eventPill.localStartDate
  }

  @computed
  public get endDate(): Date {
    return this.eventPill.localEndDate
  }

  @computed
  public get objectId(): string {
    return this.event.objectId
  }

  @computed
  public get userId(): string {
    return this.userEvent.userId
  }

  @computed
  public get organizer() {
    const foundOrganizer = this.rootStore.audienceMembersStore.getUser(this.event.organizer)
    if (foundOrganizer) return foundOrganizer
    return null
  }

  @computed
  public get editable(): boolean {
    if (this.rootStore.appStore.isOrgAdmin) return true
    return this.event.organizer === this.rootStore.appStore.currentUserId
  }

  @computed
  public get goingPanelShown(): boolean {
    if (!this.userEvent) return false
    if (this.userEvent.userId === this.rootStore.appStore.currentUserId) return true
    return false
  }

  @computed
  public get resourcesCSV(): string {
    if (this.event.resources.length === 0) return ''
    const resourceArray = this.event.resources.map((resource) => {
      const foundRsrce = this.rootStore.resourcesStore.getResource(resource.resourceId)
      if (foundRsrce) return foundRsrce.name
      return null
    })
    return resourceArray.join(', ')
  }

  @computed
  public get users(): Array<PopupParticipantVM> {
    return this.event.userIds.map((userId) => new PopupParticipantVM(this.rootStore, this, userId))
  }

  @computed
  public get location(): ILocationDTO {
    return (
      this.rootStore.locationsStore.locations.find(
        (location) => location.objectId === this.event.locationId
      ) ?? null
    )
  }

  @computed
  public get placeholderIconShown() {
    if (this.rootStore.appStore.environmentLabel !== 'LOCAL') return false
    return this.userEvent.isFakeEvent
  }

  @computed
  public get alarmsCSV(): string {
    if (this.event.alarms.length === 0) return ''
    const alarmArray = this.event.alarms
      .filter((e) => typeof e.period === 'string')
      .sort((a, b) => (a.units < b.units ? -1 : 0))
      .map((alarm) => {
        if (alarm.units === 0) {
          return 'At event start'
        } else if (Number(alarm.units) === 1) {
          const alarmSingularPeriod = alarm.period.substr(0, alarm.period.length - 1)
          const str = `${alarm.units} ${alarmSingularPeriod}`
          return str
        } else {
          const str = `${alarm.units} ${alarm.period}`
          return str
        }
      })
      .filter((x, i, a) => a.indexOf(x) == i)
    return alarmArray
      .join(', ')
      .concat(alarmArray[0] === 'At event start' && alarmArray.length === 1 ? '' : ' before')
  }

  @computed
  public get calendarIndex(): number {
    const idx = this.calendarVM.selectedOtherCalendars.findIndex((e) => e.id === this.userId)
    if (idx === -1) return 0
    return idx
  }

  public getCalendarIndex(): number {
    const idx = this.calendarVM.selectedOtherCalendars.findIndex((e) => e.id === this.userId)
    if (idx === -1) return 0
    return idx
  }

  @action
  public edit() {
    if (this.event.isInSeries) {
      this.toggleMasterEventPrompt('edit')
      return
    }
    this.editThisEvent()
  }

  @action
  public copy() {
    if (this.event.isInSeries) {
      return this.toggleMasterEventPrompt('copy')
    }
    this.copyThisEvent()
  }

  @action
  public async copyMasterEvent() {
    const svc = new EventsCopyService(this.rootStore)
    const copiedEvent = await svc.copyEvent(this.userEvent.eventId)
    this.rootStore.eventsStore.loadEventEditVMFromEvent(copiedEvent)
    this.rootStore.eventsStore.calendarVM.openEventDrawer()
    this.close()
  }

  @action
  public async copyThisEvent() {
    const svc = new EventsCopyService(this.rootStore)
    if (this.userEvent.isFakeEvent) {
      const newEvent = await svc.copyEvent(this.userEvent.eventId)
      newEvent.startDate = this.userEvent.startDate
      newEvent.endDate = this.userEvent.endDate
      newEvent.occurrenceStartDate = this.userEvent.startDate
      this.rootStore.eventsStore.loadEventEditVMFromEvent(newEvent)
      this.rootStore.eventsStore.calendarVM.openEventDrawer()
      return this.close()
    }
    const copiedEvent = await svc.copyEvent(this.userEvent.eventId)
    this.rootStore.eventsStore.loadEventEditVMFromEvent(copiedEvent)
    this.rootStore.eventsStore.calendarVM.openEventDrawer()
    this.close()
  }

  @action
  public async editThisEvent() {
    if (this.userEvent.isFakeEvent) {
      const newEvent = await this.getEventObject()
      this.rootStore.eventsStore.editVM = new EventEditVM(this.rootStore, newEvent)
      this.rootStore.eventsStore.calendarVM.openEventDrawer()
      this.close()
      return
    }
    this.rootStore.eventsStore.lazyLoadEventEditVM(this.userEvent.eventId)
    this.rootStore.eventsStore.calendarVM.openEventDrawer()
    this.close()
  }

  private async getEventObject() {
    let event = this.rootStore.eventsStore.getEvent(this.userEvent.eventId)
    if (!event) event = await this.rootStore.eventsStore.getFullRecord(this.userEvent.eventId)
    if (!event) throw 'unable to find userEvent.event'
    if (!this.userEvent.isFakeEvent) return event
    const newEventDTO = event.serialize()
    newEventDTO.separateFromMasterEventId = this.userEvent.eventId
    newEventDTO.objectId = null
    newEventDTO.startDate = this.userEvent.startDate
    newEventDTO.endDate = this.userEvent.endDate
    newEventDTO.occurrenceStartDate = this.userEvent.startDate
    newEventDTO.isFakeEvent = true
    newEventDTO.schedule = null
    const newEvent = deserialize(Event, newEventDTO)
    return newEvent
  }

  @action
  public editMasterEvent() {
    this.rootStore.eventsStore.lazyLoadEventEditVM(this.userEvent.masterEventId)
    this.rootStore.eventsStore.calendarVM.openEventDrawer()
    this.close()
  }

  @action
  public toggleMasterEventPrompt(promptType?: MasterEventPromptType) {
    if (promptType === 'delete') this.masterEventPromptMode = 'delete'
    if (promptType === 'copy') this.masterEventPromptMode = 'copy'
    if (promptType === 'edit') this.masterEventPromptMode = 'edit'
    this.masterEventPromptShown = !this.masterEventPromptShown
  }

  @action
  public handleMasterEventSeries() {
    if (this.masterEventPromptMode === 'delete')
      return this.loadNotificationsVM(() => this.deleteMasterEvent())
    if (this.masterEventPromptMode === 'copy') return this.copyMasterEvent()
    this.editMasterEvent()
  }

  @action
  public handleMasterEventThisOccurrence() {
    if (this.masterEventPromptMode === 'delete')
      return this.loadNotificationsVM(() => this.deleteThisEvent())
    if (this.masterEventPromptMode === 'copy') return this.copyThisEvent()
    this.editThisEvent()
  }

  @action
  public loadNotificationsVM(afterConfirm: Function) {
    if (this.masterEventPromptShown) this.toggleMasterEventPrompt()
    this.eventSendNotificationsVM = new EventSendNotificationsVM(this.rootStore, afterConfirm)
    setTimeout(() => this.eventSendNotificationsVM.toggleShowNotificationsModal(), 500)
  }

  @action
  public delete() {
    this.toggleMasterEventPrompt('delete')
  }

  @action
  public async deleteThisEvent() {
    this.close()
    const event = await this.getEventObject()
    event.markAsDeleted()
    const dto = event.serialize()
    const svc = new EventsService()
    await svc.updateEvent(dto, dto.organizationId, this.eventSendNotificationsVM.skipNotifications)
    this.calendarVM.refresh()
  }

  @action
  public async deleteMasterEvent() {
    this.close()
    const masterEvent = await this.rootStore.eventsStore.getFullRecord(this.userEvent.masterEventId)
    masterEvent.markAsDeleted()
    const dto = masterEvent.serialize()
    const svc = new EventsService()
    await svc.updateEvent(dto, dto.organizationId, this.eventSendNotificationsVM.skipNotifications)
    this.calendarVM.refresh()
  }

  @action
  public close() {
    this.anchorEl = null
  }

  public async openAttachment(attachment: AttachmentVM) {
    attachment.openAttachment()
  }
}
