import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from 'ag-grid-react'
import Searchbar from '../../shared/Searchbar'
import { ContactsAGGridVM } from '../view-models/ContactsAGGridVM'
import ContactRow from './ContactRow'
import { ContactRowVM } from '../view-models/ContactRowVM'

interface Props {
  contactsAGGridVM: ContactsAGGridVM
}

const ContactsList: FC<Props> = ({ contactsAGGridVM }) => {
  if (!contactsAGGridVM) return null

  const renderNameCell = (props) => {
    const row: ContactRowVM = props.data
    if (!row) return null
    return <ContactRow contact={row} style={{}} />
  }

  const renderGrid = () => {
    return (
      <div
        className='ag-theme-alpine'
        style={{
          width: '100%',
          display: contactsAGGridVM.shown ? undefined : 'none',
        }}
      >
        <AgGridReact
          gridOptions={{
            ...contactsAGGridVM.getGridOptions(),
            frameworkComponents: {
              nameCell: renderNameCell,
            },
          }}
        />
      </div>
    )
  }

  const renderSearchbox = () => {
    if (!contactsAGGridVM.shown) return
    return (
      <Searchbar
        focused={contactsAGGridVM.hasFilter}
        className='search'
        searchValue={contactsAGGridVM.typedFilterText}
        onSearchChange={(e) => contactsAGGridVM.setQuickFilter(e.target.value)}
      />
    )
  }

  return (
    <div
      id='UsersList'
      style={{
        display: contactsAGGridVM.shown ? undefined : 'none',
      }}
    >
      {renderSearchbox()}
      {renderGrid()}
    </div>
  )
}

export default observer(ContactsList)
