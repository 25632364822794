import { computed, action, observable, reaction } from 'mobx'
import { RootStore } from 'src/app/stores/RootStore'
import { SurveyEditVM } from './SurveyEditVM'

export class SurveyEditSteppersVM {
  rootStore: RootStore
  surveyEditVM: SurveyEditVM
  constructor(rootStore: RootStore, surveyEditVM: SurveyEditVM) {
    this.rootStore = rootStore
    this.surveyEditVM = surveyEditVM
  }

  @observable public detailsVisited: boolean = true
  @observable public builderVisited: boolean = false
  @observable public optionsVisited: boolean = false
  @observable public participantsVisited: boolean = false
  @observable public feedbackVisited: boolean = false
  @observable public previewVisited: boolean = false
  @observable public detailsCompleted: boolean = false
  @observable public builderCompleted: boolean = false
  @observable public optionsCompleted: boolean = false
  @observable public participantsCompleted: boolean = false
  @observable public feedbackCompleted: boolean = false
  @observable public previewCompleted: boolean = false
  @observable public publishCompleted: boolean = false

  @computed
  public get showSteppers() {
    return this.isNewSurvey || (!this.isNewSurvey && this.surveyEditVM.surveyWasJustPublished)
  }

  @computed
  public get allCompleted() {
    return (
      this.detailsCompleted &&
      this.builderCompleted &&
      this.optionsCompleted &&
      (this.feedbackCompleted || !this.isFeedbackType) &&
      this.previewCompleted &&
      this.publishCompleted
    )
  }

  @computed
  public get index() {
    return this.surveyEditVM.currentTabIndex
  }

  @computed
  public get isTemplate() {
    return this.surveyEditVM.isTemplate
  }

  @computed
  public get surveyType() {
    return this.surveyEditVM.surveyType
  }

  @computed
  public get surveyTypeForPrompt() {
    return this.surveyEditVM.surveyTypeForPrompt
  }

  @computed
  public get isPublished() {
    return this.surveyEditVM.isPublished
  }

  @computed
  public get isNewSurvey() {
    return this.surveyEditVM.isNewSurvey
  }

  @computed
  public get publishTried() {
    return this.surveyEditVM.publishTried
  }

  @computed
  public get isFeedbackType() {
    return this.surveyEditVM.isFeedbackType
  }

  @computed
  public get optionsValid() {
    return Boolean(this.surveyEditVM.isTemplatesValid)
  }

  @computed
  public get detailsValid() {
    return Boolean(this.surveyEditVM.name.trim())
  }

  @computed
  public get builderValid() {
    const invalidQuestions = this.surveyEditVM.questions.filter((q) => !q.allValid)
    return invalidQuestions.length === 0
  }

  @computed
  public get participantsValid() {
    const participants = this.surveyEditVM.participantsSelectVM.participants
    const anonymousParticipants = this.surveyEditVM.participantsSelectVM.anonymousParticipants
    return participants.length > 0 || anonymousParticipants.length > 0
  }

  @computed
  public get feedbackValid() {
    return this.surveyEditVM.feedbackParticipantsSelectVM.participants.length > 0
  }

  @computed
  public get notifError() {
    return (this.publishTried || this.isTemplate) && !this.surveyEditVM.isNotificationTemplatesValid
  }

  @computed
  public get reminderError() {
    return (this.publishTried || this.isTemplate) && !this.surveyEditVM.isReminderTemplatesValid
  }

  @computed
  public get optionsError() {
    return this.publishTried && !this.optionsValid
  }

  @computed
  public get detailsError() {
    return this.publishTried && !this.detailsValid
  }

  @computed
  public get builderError() {
    return this.publishTried && !this.builderValid
  }

  @computed
  public get participantsError() {
    return this.publishTried && !this.participantsValid
  }

  @computed
  public get feedbackError() {
    return this.publishTried && this.isFeedbackType && !this.feedbackValid
  }

  @action
  public updateSteppers(newIndex: number, previousIndex: number = null) {
    if (newIndex === 1) this.builderVisited = true
    if (newIndex === 2) this.optionsVisited = true
    if (newIndex === 3) this.participantsVisited = true
    if (newIndex === 4) this.feedbackVisited = true
    if (newIndex === 5) this.previewVisited = true

    if (newIndex > 0 && this.detailsVisited) {
      if (this.detailsValid) {
        this.detailsCompleted = true
      } else {
        this.detailsCompleted = false
      }
    }

    if (newIndex > 1 && this.builderVisited) {
      if (this.builderValid) {
        this.builderCompleted = true
      } else {
        this.builderCompleted = false
      }
    }

    if (newIndex > 2 && this.optionsVisited) {
      this.optionsCompleted = true
    }

    if (newIndex > 3 && this.participantsVisited) {
      if (this.participantsValid) {
        this.participantsCompleted = true
      } else {
        this.participantsCompleted = false
      }
    }

    if (newIndex > 4 && this.isFeedbackType && this.feedbackVisited) {
      if (this.feedbackValid) {
        this.feedbackCompleted = true
      } else {
        this.feedbackCompleted = false
      }
    }
  }
}
