import React, { Component } from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'
import rootStore from '../../stores/RootStore'
import SystemAdminsListWidget from '../../system-admins/views/list-widget/SystemAdminsListWidget'
import OrganizationsSAListWidget from '../../organizations-sa/views/list/OrganizationsSAListWidget'
import SystemNotificationsWidget from '../../notifications/widgets/SystemNotificationsWidget'
import { DashboardService } from '../../services/DashboardService'
import './SystemAdminDashboard.scss'
import SystemAdminToolbar from './components/SystemAdminToolbar'

const RGL = WidthProvider(Responsive)

class SystemAdminDashboard extends Component<any, any> {
  layout: Array<any>
  rowsOnScreen: number
  margin: Array<number>
  RGLprops: any
  minRowHeight: number
  dashboard: any

  constructor(props) {
    super(props)

    const rowsOnScreen = 3
    this.rowsOnScreen = rowsOnScreen

    const margin = [15, 15]
    this.margin = margin

    this.minRowHeight = 200
    const rowHeight = this.calcRowHeight()

    const breakpoints = { lg: 1200, md: 900, sm: 500, xs: 300, xxs: 0 }
    const cols = { lg: 4, md: 3, sm: 3, xs: 1, xxs: 1 }
    const systemAdminDashboard = new DashboardService().getSystemAdminDashboard()

    this.dashboard = systemAdminDashboard

    this.RGLprops = {
      className: 'layout',
      layouts: systemAdminDashboard.layouts,
      breakpoints,
      cols,
      margin,
      compactType: null,
      onDragStart: this.onDragStart,
      onDrag: this.onDrag,
      onDragStop: this.onDragStop,
      onResize: this.setRect,
      onResizeStop: this.setRectFinish,
      onLayoutChange: this.onLayoutChange,
      preventCollision: true,
      isDraggable: true,
      isResizable: true,
      draggableHandle: '.drag-handle',
    }

    this.state = {
      rowHeight,
      isDragEnabled: true,
      isResizeEnabled: true,
    }
  }

  componentDidMount() {
    this.resizeRows()
    window.addEventListener('resize', this.debounce(this.resizeRows, 200))
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debounce(this.resizeRows, 200))
  }

  renderWidgets() {
    const widgets = []

    for (let widget of this.dashboard.layout) {
      let component
      switch (widget.type) {
        case 'SystemAdminsListWidget':
          component = <SystemAdminsListWidget />
          break
        case 'OrganizationsSAListWidget':
          component = <OrganizationsSAListWidget />
          break
        case 'NotificationsWidget':
          component = <SystemNotificationsWidget />
          break
        default:
          component = null
      }

      const el = (
        <div className='widget-container' key={widget.i}>
          {component}
        </div>
      )

      widgets.push(el)
    }

    return widgets
  }

  toggleDrag = () => {
    this.RGLprops.isDraggable = !this.RGLprops.isDraggable
    this.setState({
      isDragEnabled: !this.state.isDragEnabled,
    })
  }

  toggleResize = () => {
    this.RGLprops.isResizable = !this.RGLprops.isResizable
    this.setState({
      isResizeEnabled: !this.state.isResizeEnabled,
    })
  }

  render() {
    return (
      <div id='SystemAdminDashboardContainer'>
        <div>
          <SystemAdminToolbar />
        </div>
        <RGL {...this.RGLprops} rowHeight={Math.max(this.state.rowHeight, this.minRowHeight)}>
          {this.renderWidgets()}
        </RGL>
      </div>
    )
  }

  onLayoutChange = (layout) => {}

  onDragStart = (layout, oldItem, newItem, placeholder, e, element) => {}
  onDrag = (layout, oldItem, newItem, placeholder, e, element) => {}
  onDragStop = (layout, oldItem, newItem, placeholder, e, element) => {}
  setRect() {
    rootStore.appStore.incrementWidgetResizeCounter()
  }
  setRectFinish() {
    setTimeout(() => rootStore.appStore.incrementWidgetResizeCounter(), 500)
  }

  // Add delay to row resize to reduce re-render frequency
  debounce = (fn, delay) => {
    let timer
    return () => {
      clearTimeout(timer)
      timer = setTimeout(function () {
        timer = null
        fn.apply(this, arguments)
      }, delay)
    }
  }

  resizeRows = () => {
    let rowHeight = this.calcRowHeight()
    this.setState({ rowHeight })
  }

  calcRowHeight = () => {
    const container = document.getElementById('SystemAdminDashboardContainer')
    const dashHeader = document.getElementsByClassName('dashboard-header')[0]
    if (container && dashHeader) {
      const availableHeight =
        container.clientHeight - dashHeader.clientHeight - (this.rowsOnScreen + 1) * this.margin[1]
      return availableHeight / this.rowsOnScreen
    } else {
      return this.minRowHeight
    }
  }
}

export default SystemAdminDashboard
