import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import {
  CardHeader,
  Checkbox,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import Searchbar from '../../../shared/Searchbar'
import './WidgetHeader.scss'
import UsersImportModal from '../import/UsersImportModal'
import { OrganizationUsersWidgetVM } from '../../view-models/widget/OrganizationUsersWidgetVM'
import WidgetTitle from './WidgetTitle'
import { ActiveTabVM } from '../../view-models/widget/ActiveTabVM'
import { ArchivedTabVM } from '../../view-models/widget/ArchivedTabVM'
import MoreIcon from '@material-ui/icons/MoreVert'
import rootStore from '../../../stores/RootStore'

interface Props {
  localizationStore?: LocalizationStore
  orgUsersVM: OrganizationUsersWidgetVM
  vm: ActiveTabVM | ArchivedTabVM
}

const WidgetHeader: React.FC<Props> = ({ localizationStore, orgUsersVM, vm }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleClose = () => {
    setAnchorEl(null)
  }

  const isSystemAdmin = () => {
    if (!rootStore) return false
    if (!rootStore.userStore) return false
    if (!rootStore.userStore.user) return false
    return rootStore.appStore.isSystemAdmin
  }

  const renderShowInvisibleUserMenu = () => {
    if (!isSystemAdmin()) return

    return (
      <div>
        <IconButton
          size='small'
          onClick={(e) => {
            setAnchorEl(e.currentTarget)
          }}
          className={Boolean(anchorEl) ? 'focused' : ''}
        >
          <MoreIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          elevation={1}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MenuItem
            onClick={() => {
              orgUsersVM.toggleViewHiddenUsers()
              handleClose()
            }}
          >
            <Checkbox checked={orgUsersVM.shouldShowHiddenUsers} />
            <ListItemText primary='View Hidden' />
          </MenuItem>
        </Menu>
      </div>
    )
  }

  return (
    <CardHeader
      title={<WidgetTitle vm={orgUsersVM} />}
      id='WidgetHeader'
      action={
        <>
          <div>{renderShowInvisibleUserMenu()}</div>
          <div className='action-btn'>
            <Searchbar
              focused={vm.typedFilterText.length > 0}
              className='search'
              searchValue={vm.typedFilterText}
              onSearchChange={(e) => {
                vm.setQuickFilter(e.target.value)
              }}
            />
          </div>
          {orgUsersVM.tabIndex == 0 ? (
            <div className='action-btn'>
              <Tooltip
                title={localizationStore.lzStrings.orgUsersTableWidget.importUsers}
                placement='bottom'
              >
                <IconButton size='small' onClick={() => vm.openImportModal()}>
                  <ImportExportIcon />
                </IconButton>
              </Tooltip>
            </div>
          ) : undefined}
          {orgUsersVM.tabIndex == 0 ? (
            <div className='action-btn'>
              <Tooltip
                title={localizationStore.lzStrings.orgUsersTableWidget.addNewUser}
                placement='bottom'
              >
                <IconButton className='widget-cta' size='small' onClick={() => vm.openNewUser()}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </div>
          ) : undefined}

          <UsersImportModal vm={vm.importVM} />
        </>
      }
    />
  )
}

export default inject('localizationStore')(observer(WidgetHeader))
