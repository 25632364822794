import React from 'react'
import { inject, observer } from 'mobx-react'
import { ClientsStore } from '../store/ClientsStore'
import { AgGridReact } from 'ag-grid-react/lib/agGridReact'
import AGGridWidgetPagingRow from '../../shared/ag-grid/AGGridPagingRow'

interface Props {
  clientsStore?: ClientsStore
}

const ClientListTable: React.FC<Props> = ({ clientsStore }) => {
  const vm = clientsStore.clientManagementWidgetVM
  if (!vm) return null
  if (!vm.shouldRender) return null
  
  const renderGrid = () => {
    return (
      <div className='ag-theme-alpine' style={{ height: '100%', width: '100%' }}>
         <AgGridReact gridOptions={{
          ...vm.getGridOptions()
        }} />
      </div>
    )
  }

  return (
    <>
      {renderGrid()}
      <AGGridWidgetPagingRow vm={vm} />
    </>
  )
}

export default inject('clientsStore')(observer(ClientListTable))
