import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { TasksStore } from '../../store/TasksStore'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { Grid, InputLabel } from '@material-ui/core'
import moment from 'moment'
import TimePicker from '../../../shared/time-picker/TimePicker'
import './Drawer.scss'

interface DrawerTaskDetailsDateProps {
  localizationStore?: LocalizationStore
  tasksStore?: TasksStore
}

const DrawerTaskDetailsDate: React.FC<DrawerTaskDetailsDateProps> = ({
  localizationStore,
  tasksStore,
}) => {
  const { lzStrings } = localizationStore
  const { taskEditVM: VM } = tasksStore

  const handleDateChange = (date: Date) => {
    const unixValue = moment(date).unix()
    VM.setDueDate(unixValue)
  }

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <InputLabel required htmlFor='taskDate'>
            {lzStrings.tasksManagementWidgetDrawer.due_date}
          </InputLabel>
          <KeyboardDatePicker
            disablePast={VM.objectId ? false : true}
            inputVariant='outlined'
            format='MM/dd/yyyy'
            margin='none'
            fullWidth
            autoOk
            error={false}
            helperText={null}
            id='taskDate'
            value={moment.unix(VM.dueDate).toDate()}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
              edge: 'end',
            }}
          />
        </Grid>
        <Grid item xs={6} className='date-time-input'>
          <InputLabel htmlFor='taskDateTime'>Due Time </InputLabel>
          <TimePicker
            error={false}
            value={VM.dueDateAsDate}
            onChange={(hr, min) => VM.setDueDateTime(hr, min)}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default inject('localizationStore', 'tasksStore')(observer(DrawerTaskDetailsDate))
