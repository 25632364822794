import axios from 'axios'
import { RootStore } from '../../stores/RootStore'
import * as Sentry from '@sentry/browser'
import env from '../../../env' 
import { CMSAuthService } from '../../cms-auth/services/CMSAuthService'
import { ICMSAvatarDeleteRequest } from '../interfaces/ICMSAvatarDeleteRequest'

export class CMSAvatarDeleteService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  private getCMSApiUrl(): string {
    return env.var.REACT_APP_CMS_API_URL
  }

  public async deleteAvatar(req: ICMSAvatarDeleteRequest): Promise<any> {
    try {
      const jwt = await CMSAuthService.getJWT()
      if (!jwt) return false

      const response = await axios.delete(
        `${this.getCMSApiUrl()}/api/avatars/delete/${req.userId}`,
        {
          headers: { Authorization: 'Bearer ' + jwt },
        }
      )

      return response
    } catch (e) {
      console.error(e)
      Sentry.captureException({
        message: 'Unable to delete cms avatar',
        exception: e,
        userId: this.rootStore.appStore.currentUserId,
        orgId: this.rootStore.appStore.currentOrgId,
      })
    }
  }
}
