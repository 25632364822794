import React from 'react'
import {
  CardContent,
  Typography,
  Grid,
  LinearProgress,
  InputLabel,
  Select,
  MenuItem,
  Popover,
  Tooltip,
  IconButton,
} from '@material-ui/core'
import LockRoundedIcon from '@material-ui/icons/LockRounded'
import LockOpenRoundedIcon from '@material-ui/icons/LockOpenRounded'
import WavesRoundedIcon from '@material-ui/icons/WavesRounded'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../../localization/LocalizationStore'
import EditIcon from '@material-ui/icons/Edit'
import Avatar from '../../../../shared/Avatar'
import './MyTrainingPlansWidget.scss'
import moment from 'moment'
import { UserTrainingPlansStore } from '../../store/UserTrainingPlansStore'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import TrackChangesIcon from '@material-ui/icons/TrackChanges'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import VirtualList from 'react-tiny-virtual-list'
import Subtitle from './Subtitle'
import Timeline from './Timeline'
import { MyTrainingPlansWidgetRowVM } from '../../view-models/widgets/MyTrainingPlansWidgetRowVM'

interface Props {
  userTrainingPlansStore?: UserTrainingPlansStore
  localizationStore?: LocalizationStore
}

const Content: React.FC<Props> = ({ userTrainingPlansStore, localizationStore }) => {
  const { myTrainingPlansWidgetVM: vm } = userTrainingPlansStore
  const { training_plans: strings } = localizationStore.lzStrings
  if (!vm) return null

  const renderNoRowsContent = () => {
    if (vm.displayRows.length > 0) return
    return (
      <Typography component='div' className='no-rows'>
        {strings.no_plans}
      </Typography>
    )
  }

  const renderTrainerMenu = (row) => {
    if (!row.trainer) return null
    return (
      <Popover
        open={Boolean(row.myTrainerAnchorEl)}
        anchorEl={row.myTrainerAnchorEl}
        onClose={(e) => {
          row.setMyTrainerAnchorEl(null)
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className='trainer-menu'
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography variant='subtitle2' className='trainer-label'>
              {strings.assigned_trainer}
            </Typography>
            <Typography variant='subtitle2' className='trainer-label'>
              {strings.contact}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <span className='trainer-container'>
              <Avatar user={row.trainer} />
              <Typography variant='h6'>{row.trainer.name}</Typography>
            </span>
            <a className='mail-link' href={row.mailToLink} target='_blank'>
              <MailOutlineIcon className='mail' />
            </a>
          </Grid>
        </Grid>
      </Popover>
    )
  }

  const renderPlanRow = (row: MyTrainingPlansWidgetRowVM, index: number) => {
    const renderEditIcon = (userOwned) => {
      if (!userOwned) return null
      return (
        <Tooltip title={strings.edit_training_plan} placement='bottom' enterDelay={100}>
          <IconButton
            size='small'
            onClick={(e) => {
              e.stopPropagation()
              row.editTrainingPlan()
            }}
          >
            <EditIcon className='edit-icon' />
          </IconButton>
        </Tooltip>
      )
    }

    const renderTrainerIcon = (trainer) => {
      if (!trainer) return null
      return (
        <Tooltip title={strings.view_trainer_information} placement='bottom' enterDelay={100}>
          <PeopleAltIcon
            className='trainer'
            onClick={(e) => {
              e.stopPropagation()
              row.setMyTrainerAnchorEl(e.currentTarget)
            }}
          />
        </Tooltip>
      )
    }

    const renderImpactIcon = (row) => {
      if (!row.associateToGoal) return null
      return (
        <Tooltip
          title={`${strings.associated_to} ${row.associatedGoalName}`}
          placement='bottom'
          enterDelay={100}
        >
          <TrackChangesIcon className='skills' />
        </Tooltip>
      )
    }

    const renderUnreadIndicator = (isRead) => {
      if (isRead) return null
      return <div className='unread-indicator' />
    }

    const tooltipStatusInfo = (row: MyTrainingPlansWidgetRowVM) => {
      if (row.isComplete) return strings.training_plan_complete_info
      if (row.isReadonly) return strings.training_plan_locked_info
      else return strings.training_plan_unlocked_info
    }

    const renderReadonlyIcon = (row: MyTrainingPlansWidgetRowVM) => {
      if (row.isComplete) return

      if (row.isReadonly) return <LockRoundedIcon className={row.lockClasses} />
      else return <LockOpenRoundedIcon className={row.lockClasses} />
    }

    const renderReadonlyIndicator = () => {
      return (
        <Tooltip title={tooltipStatusInfo(row)} placement='right' enterDelay={500}>
          <span>{renderReadonlyIcon(row)}</span>
        </Tooltip>
      )
    }

    const renderFlowIndicator = () => {
      if (!row.isForTrainingFlow) return null
      return (
        <Tooltip title={strings.training_plan_automated_info} placement='right' enterDelay={500}>
          <span>
            <WavesRoundedIcon className={row.lockClasses} />
          </span>
        </Tooltip>
      )
    }

    return (
      <Grid container key={index} direction='row' id='PlanRow'>
        {renderUnreadIndicator(row.isRead)}

        <Grid
          item
          xs={10}
          onClick={() => {
            row.openUserTrainingPlan()
          }}
          className='cursor'
        >
          <Grid container justifyContent='space-between' className='title-row'>
            <div
              onMouseOver={(e) => {
                e.stopPropagation()
              }}
              className='readonly-status'
            >
              <Typography className='plan-title'>{row.title}</Typography>
              {renderReadonlyIndicator()}
              {renderFlowIndicator()}
            </div>
          </Grid>
        </Grid>

        <Grid
          item
          xs={2}
          className='title-row cursor'
          onClick={() => {
            row.openUserTrainingPlan()
          }}
        >
          <div className='icons'>
            {renderTrainerIcon(row.trainer)}
            {renderImpactIcon(row)}
            {renderEditIcon(row.userOwned)}
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          onClick={() => {
            row.openUserTrainingPlan()
          }}
          className='cursor plan-row-assignee'
        >
          <span>
            <Avatar user={row.assignedByUser} size={20} />
            <Typography className='user-name'>
              {row.assignedByUser ? row.assignedByUser.name : 'N/A'}
            </Typography>
          </span>

          <Typography className={row.isPastDue && !row.isComplete ? 'past-due' : ''}>
            {row.isPastDue && !row.isComplete ? '' : strings.due}
            {row.isPastDue && !row.isComplete
              ? row.daysPastDue
              : moment(row.dueDate).format(strings.due_date_format)}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          onClick={() => {
            row.openUserTrainingPlan()
          }}
          className='progress-container'
        >
          <LinearProgress
            variant='determinate'
            value={row.progress}
            className={row.userOwned ? 'owned' : 'not-owned'}
          />
          <Typography className='progress-label'>{row.progress}%</Typography>
        </Grid>
        {renderTrainerMenu(row)}
      </Grid>
    )
  }

  const renderSubHeader = () => {
    return (
      <Grid container direction='row' className='filter-row'>
        <Grid item>
          <Subtitle />
        </Grid>
        {renderFilterMenu()}
      </Grid>
    )
  }

  const renderFilterMenu = () => {
    if (vm.showTimeline) return
    return (
      <Grid item className='filter'>
        <InputLabel>Sort By:</InputLabel>
        <Select value={vm.sortType} onChange={(e) => vm.setSortType(e.target.value)}>
          <MenuItem value={'Date'}>Date</MenuItem>
          <MenuItem value={'Name'}>Name</MenuItem>
        </Select>
      </Grid>
    )
  }

  // const renderVirtualList = () => {
  //   if (vm.displayRows.length === 0) return null
  //   return (
  //     <VirtualList
  //       height={vm.displayRows.length * 140}
  //       itemCount={vm.displayRows.length}
  //       itemSize={140}
  //       renderItem={({ index, style }) => renderPlanRow(vm.displayRows[index], index, style)}
  //     />
  //   )
  // }

  const renderListRows = () => {
    if (vm.showTimeline) return
    return <>{vm.displayRows.map((row, index) => renderPlanRow(row, index))}</>
  }

  return (
    <>
      {renderSubHeader()}
      <div id='Content'>
        <CardContent className='no-padding'>
          {renderListRows()}
          <Timeline />
          {/* {renderVirtualList()} */}
          {renderNoRowsContent()}
        </CardContent>
      </div>
    </>
  )
}

export default inject('userTrainingPlansStore', 'localizationStore')(observer(Content))
