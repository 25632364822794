import { observable, action, computed } from 'mobx'
import { Event } from '../aggregate/Event'

export class WeekdayVM {
  constructor(name, dbName, event) {
    this.event = event
    this.name = name
    this.dbName = dbName
  }

  @observable event: Event = null
  @observable name: string = ''
  dbName: string = ''

  @computed
  public get isChecked(): boolean {
    return this.event.schedule.days[this.dbName]
  }
}
