import { RootStore } from '../../../stores/RootStore'
import { computed, action, observable } from 'mobx'
import { Label } from '../../aggregate/Label'

export class OrganizationEditLabelRowVM {
  private label: Label
  private rootStore: RootStore

  constructor(rootStore: RootStore, label: Label, orgId: string) {
    this.rootStore = rootStore
    this.label = label
    this.organizationId = orgId
  }

  @observable organizationId: string = ''

  @computed
  public get objectId(): string {
    return this.label.objectId
  }

  @computed
  public get selector(): string {
    return this.label.selector
  }

  @computed
  public get isDefault(): boolean {
    return this.label.isDefault
  }

  @computed
  public get language(): string {
    return this.label.language
  }

  @computed
  public get value(): string {
    return this.label.value
  }

  @computed
  public get isDirty(): boolean {
    return this.label.isDirty
  }

  @action
  public setLabel(val) {
    this.label.setValue(val)
  }

  @action
  public setOrganization(val) {
    this.label.setOrganization(val)
  }
}
