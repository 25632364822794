import React from 'react'
import { observer, inject } from 'mobx-react'
import LocalizationStore from '../../localization/LocalizationStore'
import { QuestionsStore } from '../store/QuestionsStore'
import { Button } from '@material-ui/core'
import FilterListIcon from '@material-ui/icons/FilterList'
import CategoryPicker from '../../shared/category-picker/CategoryPicker'
import { QuestionCatalogWidgetVM } from '../view-models/QuestionCatalogWidgetVM'

interface Props {
  localizationStore?: LocalizationStore
  vm: QuestionCatalogWidgetVM
}

const QuestionCategoryFilter: React.FC<Props> = ({ vm }) => {
  if (!vm) return
  if (!vm.categoryPickerVM) return
  return (
    <CategoryPicker
      onApply={(categoryIds: string[]) => vm.reload()}
      vm={vm.categoryPickerVM}
      mode='multiSelect'
      renderCustomButtonComponent={() => (
        <Button
          variant='outlined'
          startIcon={<FilterListIcon />}
          onClick={(e) => {
            vm.categoryPickerVM.toggleShowPicker()
          }}
        >
          {vm.categoryPickerVM.categoryLabel} (
          {vm.categoryPickerVM.selectAllChecked
            ? 'All'
            : vm.categoryPickerVM.selectedCategoryIds.length}
          )
        </Button>
      )}
    />
  )
}

export default inject('localizationStore')(observer(QuestionCategoryFilter))
