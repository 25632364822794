import Parse from 'parse'

export class TasksService {
  public async saveTask(orgId, task): Promise<string> {
    const taskId = await Parse.Cloud.run('saveTask', {
      orgId,
      task,
    })
    return taskId
  }

  public async deleteTask(taskId: string, orgId: string): Promise<boolean> {
    const result = await Parse.Cloud.run('deleteTask', { taskId, orgId })
    return result
  }
}
