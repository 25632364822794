import { action, computed, observable } from 'mobx'
import { ParseService } from '../../../services/ParseService'
import { RootStore } from '../../../stores/RootStore'
import { ISurveyTypeDTO } from '../dtos/ISurveyTypeDTO'

export default class WidgetForm {
  rootStore: RootStore

  constructor(rootStore: RootStore, surveyType?: ISurveyTypeDTO) {
    this.rootStore = rootStore
    if (surveyType) this.loadData(surveyType)
  }

  @observable objectId: string = ''
  @observable name: string = ''
  @observable description: string = ''
  @observable worksheetId: string = ''
  @observable isFeedbackType: boolean = false
  @observable isDefaultSurveyType: boolean = false
  @observable forPrompt: string = ''
  @observable isDirty: boolean = false
  @observable saveTried: boolean = false
  @observable saveSuccessful: boolean = false
  @observable deletePending: boolean = false
  @observable deleteSuccessful: boolean = false
  @observable allowMultipleForUsers: boolean = false
  @observable responseForParticipant: boolean = false

  private loadData(incomingSurveyType: ISurveyTypeDTO) {
    this.objectId = incomingSurveyType.objectId
    this.name = incomingSurveyType.name
    this.description = incomingSurveyType.description
    this.worksheetId = incomingSurveyType.worksheetId ? incomingSurveyType.worksheetId : ''
    this.isFeedbackType = incomingSurveyType.isFeedbackType
    this.isDefaultSurveyType = incomingSurveyType.isDefaultSurveyType
    this.forPrompt = incomingSurveyType.forPrompt
    this.allowMultipleForUsers = incomingSurveyType.allowMultipleForUsers
    this.responseForParticipant = incomingSurveyType.responseForParticipant
  }

  private toDTO() {
    const newSurveyType: ISurveyTypeDTO = {
      objectId: this.objectId,
      organizationId: this.rootStore.appStore.currentOrgId,
      name: this.name.trim(),
      description: this.description.trim(),
      worksheetId: this.worksheetId,
      isFeedbackType: this.isFeedbackType,
      isDefaultSurveyType: this.isDefaultSurveyType,
      isDeleted: false,
      forPrompt: this.forPrompt.trim(),
      allowMultipleForUsers: this.allowMultipleForUsers,
      responseForParticipant: this.responseForParticipant
    }

    return newSurveyType
  }

  @action public setWorksheet(worksheetId) {
    this.worksheetId = worksheetId
    this.isDirty = true
  }

  @computed get availableWorksheets() {
    return this.rootStore.worksheetsStore.worksheets.filter(
      worksheet => worksheet.isDeleted === false && worksheet.forSurveys === true
    )
  }

  @action
  public toggleFeedBack() {
    this.isFeedbackType = !this.isFeedbackType
    this.isDirty = true
  }

  @action
  public toggleIsDefaultSurveyType() {
    this.isDefaultSurveyType = !this.isDefaultSurveyType
    this.isDirty = true
  }

  @action
  public toggleMultipleFor() {
    this.allowMultipleForUsers = !this.allowMultipleForUsers
    this.isDirty = true
  }

  @action
  public toggleResponseForParticipant() {
    this.responseForParticipant = !this.responseForParticipant
    this.isDirty = true
  }

  @action
  public setField(key: string, value: string | boolean) {
    this[key] = value
    this.isDirty = true
  }

  @computed get nameError() {
    return this.saveTried && !this.name
  }

  @computed get saveEnabled() {
    return Boolean(this.name) && this.isDirty
  }

  @computed get deleteEnabled() {
    return Boolean(this.objectId)
  }

  @action
  public async saveSurveyType() {
    this.saveTried = true
    if (this.nameError) return
    const pSvc = new ParseService()
    const result = await pSvc.saveSurveyType(this.rootStore.appStore.currentOrgId, this.toDTO())
    if (result) {
      this.saveSuccessful = true
      setTimeout(() => this.rootStore.surveyTypesStore.closeWidgetDrawer(), 500)
    }
  }

  public async deleteSurveyType() {
    this.deletePending = true
    const pSvc = new ParseService()
    const result = await pSvc.deleteSurveyType(
      this.rootStore.appStore.currentOrgId,
      this.objectId
    )
    if (result) {
      this.deleteSuccessful = true
      setTimeout(() => this.rootStore.surveyTypesStore.closeWidgetDrawer(), 500)
    }
  }
}
