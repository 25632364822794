import React, { FC } from 'react'
import { inject, observer } from 'mobx-react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import CheckIcon from '@material-ui/icons/Check'
import PublishIcon from '@material-ui/icons/Publish'
import './MediaUploadPicker.scss'
import { IconButton, Tooltip, Icon, Typography, Button } from '@material-ui/core'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'
import { MediaUploadPickerVM } from '../../view-models/media-upload-picker/MediaUploadPickerVM'
import Searchbar from '../../../shared/Searchbar'
export interface MediaSearchBarProps {
  mediaUploadPickerVM: MediaUploadPickerVM
}
const MediaToolbar: FC<MediaSearchBarProps> = ({ mediaUploadPickerVM }) => {
  const vm = mediaUploadPickerVM
  const renderBackButton = () => {
    return (
      <div>
        <Tooltip title={'Back'} placement='bottom-start' enterDelay={500}>
          <IconButton
            onClick={() => {
              vm.toggleShowMediaUploadPicker()
            }}
          >
            <ChevronLeftIcon />
          </IconButton>
        </Tooltip>
      </div>
    )
  }
  const renderToggleButtons = () => {
    return (
      <ToggleButtonGroup className='media-toggle-button-group'>
        {vm.allowImages && (
          <ToggleButton
            className={vm.showImages ? 'toggle-button active' : 'toggle-button'}
            key={0}
            disabled={!vm.allowImages}
            value={vm.showImages}
            onClick={() => vm.toggleShowImages()}
          >
            Images
          </ToggleButton>
        )}
        {vm.allowVideos && (
          <ToggleButton
            className={vm.showVideos ? 'toggle-button active' : 'toggle-button'}
            key={1}
            disabled={!vm.allowVideos}
            value={vm.showVideos}
            onClick={() => vm.toggleShowVideos()}
          >
            Videos
          </ToggleButton>
        )}
      </ToggleButtonGroup>
    )
  }

  const renderSearchBar = () => {
    return (
      <Searchbar
        className='search-bar'
        searchValue={vm.searchValue}
        onSearchChange={(e) => vm.setSearchValue(e.target.value)}
      />
    )
  }

  return (
    <AppBar color='inherit' position='static' id='MediaSearchBar'>
      <Toolbar className='media-searchbar-toolbar'>
        {renderBackButton()}
        <div className='media-right-toolbar'>
          {renderSearchBar()}
          <div className='media-searchbar-toolbar-right'>{renderToggleButtons()}</div>
        </div>
      </Toolbar>
    </AppBar>
  )
}
export default inject()(observer(MediaToolbar))
