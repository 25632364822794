import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Button,
  CircularProgress,
} from '@material-ui/core'

const MuiConfirmDialog = (props) => {
  return (
    <div id='MuiConfirmDialog'>
      <Dialog onClose={props.onClose} open={props.open}>
        {props.isProcessing ? (
          <>
            <CircularProgress className='spinner' />
          </>
        ) : (
          <>
            <DialogTitle className='MuiConfirmDialogTitle'>{props.confirmMessage}</DialogTitle>
            {props.confirmMessageSecondary ? (
              <DialogTitle className='MuiConfirmDialogTitleSecondary'>
                {props.confirmMessageSecondary}
              </DialogTitle>
            ) : null}
            <DialogContent className='MuiConfirmDialogContent'>
              <Grid container justifyContent='flex-end'>
                <Button className='cancelButton' onClick={props.onClose}>
                  No, Nevermind
                </Button>
                <Button className='confirmButton' onClick={props.onConfirm}>
                  Yes, Confirm
                </Button>
              </Grid>
            </DialogContent>
          </>
        )}
      </Dialog>
    </div>
  )
}

export default MuiConfirmDialog
