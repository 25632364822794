import React from 'react'
import { observer } from 'mobx-react'
import {
  Button,
  Drawer,
  Card,
  CardHeader,
  CardContent,
  IconButton,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import StyledButtons from './Buttons/StyledButtons'
import StyledInputs from './Inputs/StyledInputs'
import StyledDrawerBody from './Drawer/StyledDrawerBody'
import MuiDrawerHeader from '../shared/Mui-Drawer/MuiDrawerHeader'
import MuiDrawerToolbar from '../shared/Mui-Drawer/MuiDrawerToolbar'
import './StyleGuideScreen.scss'


@observer
export default class StyleGuideScreen extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      isDrawerOpen: false,
    }
  }

  toggleDrawer() {
    this.setState({
      isDrawerOpen: !this.state.isDrawerOpen,
    })
  }

  renderButtons() {
    return (
      <div>
        <section>
          <h3>Buttons</h3>
          <StyledButtons />
        </section>
      </div>
    )
  }

  renderInputs() {
    return (
      <div>
        <section>
          <h3>Input</h3>
          <StyledInputs />
        </section>
      </div>
    )
  }

  renderDrawer() {
    return (
      <div>
        <section>
          <h3>Drawer</h3>
          <Button variant='contained' onClick={this.toggleDrawer.bind(this)}>
            Open Drawer
          </Button>

          <Drawer
            anchor='right'
            open={this.state.isDrawerOpen}
            onClose={this.toggleDrawer.bind(this)}
          >
            <Card elevation={0} className={'drawerCard'}>
              <CardHeader
                title='Drawer Header'
                action={
                  <IconButton className={'closeIcon'} onClick={this.toggleDrawer.bind(this)}>
                    <CloseIcon />
                  </IconButton>
                }
              />
              <MuiDrawerToolbar />
              <CardContent>
                <StyledDrawerBody />
              </CardContent>
            </Card>
          </Drawer>
        </section>
      </div>
    )
  }

  render() {
    return (
      <div id='StyleGuideScreen' className={'styleguide'}>
        {this.renderButtons()}
        {this.renderInputs()}
        {this.renderDrawer()}
      </div>
    )
  }
}
