import {
  Button,
  Chip,
  Grid,
  LinearProgress,
  Tooltip,
  Typography,
} from '@material-ui/core'
import LockRoundedIcon from '@material-ui/icons/LockRounded'
import LockOpenRoundedIcon from '@material-ui/icons/LockOpenRounded'
import WavesRoundedIcon from '@material-ui/icons/WavesRounded'
import { inject, observer } from 'mobx-react'
import React from 'react'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { UserTrainingPlanTakeVM } from '../../view-models/UserTrainingPlanTakeVM'
import './UserTrainingPlanTakeWidget.scss'
import ContentItem from './ContentItem'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import UserTrainingPlanMenu from './UserTrainingPlanMenu'
import QuizView from '../../../../quiz/views/QuizView'

interface Props {
  takeVM: UserTrainingPlanTakeVM
  localizationStore?: LocalizationStore
}

const UserTrainingPlanTakeWidget: React.FC<Props> = ({ takeVM, localizationStore }) => {
  const { training_plans: strings } = localizationStore.lzStrings
  if (!takeVM) return null
  if (takeVM.completionScreenShown) return null

  const renderScore = () => {
    if (!takeVM.selectedQuiz) return null
    if (!takeVM.selectedQuiz.isComplete) return null
    if (takeVM.quizTakeVM.showQuizResults) return null
    return (
      <Typography variant='h4' className='quiz-score'>
        Complete: {takeVM.selectedQuiz.score}%
      </Typography>
    )
  }

  const renderQuizName = () => {
    if (!takeVM.isCompletionQuiz) return `${takeVM.activeTrainingItem.name} Completion Quiz`
    return 'Completion Quiz'
  }

  const renderQuizAnswered = () => {
    const vm = takeVM.quizTakeVM
    if (vm.showQuizResults) return null
    const chipText = <Typography className={'quiz-answered-chip-text'}>{vm.numberOfQuestionsAnswered} / {vm.questions.length} answered</Typography>
    return (
      <>
        <div className={'quiz-answered'}>
          <Chip label={chipText} className={'quiz-answered-chip'} />
        </div>
      </>
    )
  }

  const renderSelectedQuiz = () => {
    if (!takeVM.selectedQuiz) return null
    return (
      <>
        <div className='quiz-toolbar'>
          <Typography variant='h6' className='quiz-title'>
            {renderQuizName()}
            {renderQuizAnswered()}
          </Typography>
          {renderScore()}
        </div>
        <QuizView vm={takeVM.quizTakeVM} />
      </>
    )
  }

  const renderSelectedContentItem = () => {
    if (
      !takeVM.selectedContentItem &&
      !takeVM.selectedQuiz &&
      !takeVM.selectedCertificateUploadId
    ) {
      return <div className='no-content-selected'>No Content Item Selected</div>
    } else if (!takeVM.selectedQuiz) {
      return <ContentItem takeVM={takeVM} />
    }
  }

  const renderBackButton = () => {
    if (!takeVM.objectId) return null
    return (
      <span>
        <Button variant='text' onClick={() => takeVM.backToImpactDashboard()}>
          <KeyboardBackspaceIcon /> Back to Impact
        </Button>
      </span>
    )
  }

  const renderCategoryNames = () => {
    return (
      <span>
        {takeVM.categories.map((cat, index) => (
          <Chip key={index} label={cat} />
        ))}
      </span>
    )
  }

  const tooltipStatusInfo = () => {
    if (takeVM.planIsComplete) return strings.training_plan_complete_info
    if (takeVM.isReadOnly) return strings.training_plan_locked_info
    else return strings.training_plan_unlocked_info
  }

  const renderReadonlyIcon = () => {
    if (takeVM.isReadOnly) return <LockRoundedIcon className={takeVM.lockClasses} />
    else return <LockOpenRoundedIcon className={takeVM.lockClasses} />
  }

  const renderReadonlyIndicator = () => {
    return (
      <div className='readonly-status'>
        <Tooltip
          title={tooltipStatusInfo()}
          placement='right'
          enterDelay={500}
        >
          {renderReadonlyIcon()}
        </Tooltip>
      </div>
    )
  }

  const renderFlowIndicator = () => {
    if (!takeVM.isForTrainingFlow) return null
    return (
      <div className='readonly-status'>
        <Tooltip
          title={strings.training_plan_automated_info}
          placement='right'
          enterDelay={500}
        >
          <WavesRoundedIcon className={takeVM.lockClasses} />
        </Tooltip>
      </div>
    )
  }

  return (
    <div id='UserTrainingPlanTakeWidget'>
      <Grid container>
        <Grid item xs={12} className='preview-header'>
          {renderBackButton()}
          <div className='title-container'>
            <Typography variant='h5'>{takeVM.title}</Typography>
            {renderReadonlyIndicator()}
            {renderFlowIndicator()}
          </div>
          {renderCategoryNames()}
          <Typography variant='subtitle1'>{takeVM.description}</Typography>
          <span className='progress-bar-container'>
            <LinearProgress variant='determinate' value={takeVM.progress} />
            <Typography
              className='progress-label'
              style={{ right: takeVM.progressLabel }}
              variant='h6'
            >
              {takeVM.progress}%
            </Typography>
          </span>
        </Grid>
        <Grid item xs={12} className='preview-body'>
          <Grid container>
            <Grid item xs={3} className='training-items-menu'>
              <div className='line' style={{ height: `${takeVM.lineHeight}px` }} />
              <Grid container alignContent='flex-start'>
                <UserTrainingPlanMenu takeVM={takeVM} />
                <div style={{ height: '40px', width: '100%' }} />
              </Grid>
            </Grid>
            <Grid
              item
              xs={9}
              className={
                takeVM.quizOpenAndDisabled ? 'preview-column  content-disabled' : 'preview-column'
              }
            >
              {renderSelectedContentItem()}
              {renderSelectedQuiz()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
export default inject('localizationStore')(observer(UserTrainingPlanTakeWidget))
