import { observable, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { PulseCampaignTemplatesVM } from './PulseCampaignTemplatesVM'
import { PulseCategoriesManageVM } from './PulseCategoriesManageVM'
import { PulseQuestionPreviewVM } from './PulseQuestionPreviewVM'

export class PulseCampaignTemplatePreviewerVM {
  private rootStore: RootStore

  constructor(
    rootStore: RootStore,
    vm: PulseCampaignTemplatesVM,
    categoriesVM: PulseCategoriesManageVM
  ) {
    this.rootStore = rootStore
    this.vm = vm
    this.categoriesVM = categoriesVM
  }

  @observable public vm: PulseCampaignTemplatesVM = undefined
  @observable categoriesVM: PulseCategoriesManageVM = undefined

  @computed
  public get currentQuestions() {
    return this.currentSurvey.questions
      .map((q) => {
        const pulseQuestion = this.vm.template.questions.find(
          (tq) => tq.objectId === q.originalPulseQuestionId
        )
        if (!pulseQuestion) return new PulseQuestionPreviewVM(this.rootStore, q)
        return new PulseQuestionPreviewVM(this.rootStore, pulseQuestion)
      })
      .filter(
        (q) => {
          if (!q.fk_grandCategoryId) return false
          return this.categoriesVM.selectedGrandCategoryTabs.indexOf(q.fk_grandCategoryId.objectId) > -1
        }
      )
  }

  @computed
  public get currentSurvey() {
    return this.vm.currentSurvey
  }

  @computed
  public get selectedCategories() {
    return this.categoriesVM.selectedGrandCategoryTabs
  }
}
