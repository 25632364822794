import { RootStore } from '../../stores/RootStore'
import { action, computed } from 'mobx'
import { ColDef, GridOptions, RowClickedEvent } from 'ag-grid-community'
import { ClientRowVM } from './ClientRowVM'
import { AGGridVM } from '../../shared/ag-grid/AGGridVM'
import { nameOf } from '../../shared/nameOf'

export class ClientManagementWidgetVM extends AGGridVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    super()
    this.rootStore = rootStore
  }

  @computed
  public get rows(): ClientRowVM[] {
    return this.rootStore.clientsStore.clients
      .map((row) => new ClientRowVM(this.rootStore, row, this))
  }

  @action
  public getRowNodeId(row): string {
    return row.objectId
  }

  public get shouldRender(): boolean {
    if (!this.rootStore.clientsStore.loaded) return false
    return true
  }

  public getGridOptions(): GridOptions {
    return {
      ...this.baseGridOptions,
      rowData: this.rows,
      onRowClicked: (e) => this.rowClicked(e),
      getRowNodeId: (e) => this.getRowNodeId(e),
      columnDefs: this.ensureTooltipFields(this.columnDefs),
    }
  }

  public rowClicked(e: RowClickedEvent) {
    const row: ClientRowVM = e.data
    if (!row) return
    row.openClient()
  }

  @computed
  public get columnDefs(): ColDef[] {
    const s = this.rootStore.localizationStore.lzStrings
    return [
      { headerName: s.clientManagementWidget.name, field: nameOf<ClientRowVM, string>((e) => e.name), sort: 'asc' },
      { headerName: s.clientManagementWidget.description, field: nameOf<ClientRowVM, string>((e) => e.description) },
    ]
  }
}
