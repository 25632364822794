import { persist } from 'mobx-persist'
import { action, observable } from 'mobx'
import { IPulseQuestionOptionDTO } from '../dtos/IPulseQuestionOptionDTO'
import { ResponseType } from '../types/ResponseType'
import HashId from '../../shared/HashId'
import { serialize, deserialize, object, serializable } from 'serializr'
import FollowUpQuestion from './FollowUpQuestion'

export class PulseQuestionOption implements IPulseQuestionOptionDTO {
 
  @persist @observable public id: string = HashId.generate()
  @persist @observable public text: string = ''
  @persist @observable public responseType: ResponseType = 'neutral'
  @persist @observable public emoji: string = ''
  @persist @observable public rank: number = 0
  @persist @observable public subText: string = ''
  @persist @observable public value: number = 0
  @persist @observable public hasFollowUp: boolean = false
  @serializable(object(FollowUpQuestion)) @observable public followUpQuestion: FollowUpQuestion = null
  @persist @observable public isDeleted: boolean = false

  @action
  public setResponseType(val: ResponseType) {
    this.responseType = val
  }

  @action
  public setText(val: string) {
    this.text = val
  }

  @action
  public setEmoji(val: string) {
    this.emoji = val
  }
  
  @action
  public setRank(val: number) {
    this.rank = val
  }

  @action
  public toggleHasFollowUp() {
    this.hasFollowUp = !this.hasFollowUp
    if (this.hasFollowUp) {
      const pulseQuestion = FollowUpQuestion.create()
      pulseQuestion.setQuestionType('text')
      pulseQuestion.setDefaultOptions('text')
      return this.followUpQuestion = pulseQuestion
    }
    this.followUpQuestion = null
  }

  @action
  public setValue(val: number) {
    this.value = val
  }

  @action
  public setSubText(val: string) {
    this.subText = val
  }

  public clone(): PulseQuestionOption {
    return deserialize(PulseQuestionOption, this.toDTO())
  }

  public toDTO(): IPulseQuestionOptionDTO {
    let dto = serialize(this)
    return dto
  }
}