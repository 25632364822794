import React, { FC } from 'react'
import { observer, inject } from 'mobx-react'
import './SurveyOptions.scss'
import { SurveysStore } from '../../../store/SurveysStore'
import { Switch } from '@material-ui/core'
import LocalizationStore from '../../../../localization/LocalizationStore'

interface Props {
  surveysStore?: SurveysStore
  localizationStore?: LocalizationStore
}

const ShowResults: FC<Props> = ({ surveysStore, localizationStore }) => {
  const surveyEditVM = surveysStore.viewModels.editVM
  const vm = surveyEditVM.surveyOptionsVM
  if (!surveyEditVM) return null
  if (!vm) return null
  const s = localizationStore.lzStrings.surveys

  return (
    <div className='section'>
      <div className='primary-text'>{s.show_results}</div>
      <div className='section-content'>
        <Switch
          disabled={surveyEditVM.isReadOnly}
          color='primary'
          checked={surveyEditVM.showResults}
          onChange={() => surveyEditVM.toggleShowResults()}
        />
      </div>
    </div>
  )
}

export default inject('surveysStore', 'localizationStore')(observer(ShowResults))
