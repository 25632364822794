import React from 'react'
import { observer } from 'mobx-react'
import { inject } from 'mobx-react'
import { SurveysStore } from '../../../store/SurveysStore'
import LocalizationStore from '../../../../localization/LocalizationStore'
import {
  Grid,
  Select,
  MenuItem,
  Card,
  IconButton,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import VisibilityParticipantSelect from './VisibilityParticipantSelect'
import './VisibilityEditor.scss'
import Avatar from '../../../../shared/Avatar'
import styles from '../../../../shared/Avatar.scss'
import CloseIcon from '@material-ui/icons/Close'
import AddIcon from '@material-ui/icons/Add'
import { LabelsStore } from '../../../../labels/store/LabelsStore'
import { VisibleParticipantVM } from 'src/app/surveys/view-models/VisibleParticipantVM'

interface Props {
  localizationStore?: LocalizationStore
  surveysStore?: SurveysStore
  labelsStore?: LabelsStore
}

const VisibilityEditor: React.FC<Props> = ({ surveysStore, localizationStore, labelsStore }) => {
  const { editVM } = surveysStore.viewModels
  const visibilityVM = editVM.visibilityEditorVM
  if (!visibilityVM) return null
  const s = localizationStore.lzStrings.surveys

  const handleSelectPeopleClick = () => {
    visibilityVM.toggleShowParticipantSelect()
  }

  const renderOptionSelect = () => {
    return (
      <Grid item xs={8} md={6} lg={6}>
        <Select
          disabled={!editVM.canModifyVisibilitySelect || editVM.isReadOnly}
          id='visibility-select'
          className={'template-select'}
          value={visibilityVM.selectedVisibility.value}
          onChange={(e) => visibilityVM.setSelectedVisOption(e.target.value)}
          variant='outlined'
          style={{ width: 325 }}
        >
          <MenuItem value='default'>{`${s.default} (${labelsStore.getLabelByLanguageAndFor(
            'pulse'
          )} ${s.owner_and_manager})`}</MenuItem>
          <MenuItem value='private'>{`${s.private} (${labelsStore.getLabelByLanguageAndFor(
            'pulse'
          )} ${s.owner_only})`}</MenuItem>
          <MenuItem value='public'>{`${s.public} (${s.everyone})`}</MenuItem>
          <MenuItem value='specific'>{s.specific_users}</MenuItem>
        </Select>
      </Grid>
    )
  }

  const renderSpecificUsers = () => {
    if (visibilityVM.selectedOptionValue !== 'specific') return null
    return (
      <>
        <div className='visibility-participants-container'>
          <Card
            className='visibility-participant-card select-people'
            onClick={() => handleSelectPeopleClick()}
          >
            <div className='visibility-participant-card-left'>
              <div className='fake-avatar-gray'>
                <AddIcon className='visibility-add-participant-button-icon' />
              </div>
            </div>
            <div className='visibility-participant-card-right'>
              <div>
                <div className='name'>Select User(s)...</div>
              </div>
            </div>
          </Card>
          {visibilityVM.selectedParticipants.map((participant: VisibleParticipantVM, index) => {
            return (
              <div className='visibility-participant-row'>
                <Card className='visibility-participant-card' key={participant.name}>
                  <div className='visibility-participant-card-left'>
                    <Avatar
                      user={{
                        name: participant.name,
                        iconURL: participant.iconURL,
                        objectId: participant.objectId,
                      }}
                      size={35}
                      style={{ marginLeft: styles.marginLeft, fontSize: styles.fontSize }}
                      isArchived={participant.isArchived}
                    />
                  </div>
                  <div className='visibility-participant-card-right'>
                    <div>
                      <span
                        style={
                          participant.isArchived ? { fontStyle: styles.archivedFontStyle } : {}
                        }
                      >
                        {participant.name}
                      </span>
                      <div className='type'>{participant.type}</div>
                    </div>
                    <div>
                      <IconButton
                        onClick={() => visibilityVM.removeParticipantById(participant.id)}
                        size='small'
                      >
                        <CloseIcon className='visibility-participant-card-close-icon' />
                      </IconButton>
                    </div>
                  </div>
                </Card>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={participant.limited}
                      onChange={() => participant.toggleLimited()}
                    />
                  }
                  label='Hide Responses'
                />
              </div>
            )
          })}
        </div>
      </>
    )
  }

  return (
    <div id='visibilityEditor'>
      <VisibilityParticipantSelect />
      {renderOptionSelect()}
      {renderSpecificUsers()}
    </div>
  )
}

export default inject(
  'surveysStore',
  'localizationStore',
  'labelsStore'
)(observer(VisibilityEditor))
