import { observable, action } from 'mobx'
import { IAggregate } from 'src/app/shared/data/IAggregate'
import { IGroupDTO } from '../dtos/IGroupDTO'
import { serializable, serialize, list, primitive } from 'serializr'

export class Group implements IGroupDTO, IAggregate {
  static create(orgId) {
    const group = new Group()
    group.organizationId = orgId
    return group
  }

  @serializable @observable objectId: string = ''
  @serializable @observable defaultDashboardId: string = ''
  @serializable @observable organizationId: string = ''
  @serializable @observable description: string = ''
  @serializable(list(primitive())) @observable privilegeSets: Array<string>
  @serializable @observable name: string = ''
  @serializable @observable isDeleted: boolean = null
  @serializable @observable isProcessing: boolean = false
  @serializable @observable forceDashboardRoute: boolean = false
  @serializable @observable memberCount: number = 0
  isOnServer: boolean = false
  public type: string = 'group'

  public markIsNotOnServer() {
    this.isOnServer = false
  }

  public markIsOnServer() {
    this.isOnServer = true
  }

  public serialize() {
    return serialize(this)
  }
}
