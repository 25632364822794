import React from 'react'
import { IndexRoute, Route } from 'react-router'
import Layout from '../layout/Layout'
import WeightProfileEditScreen from './views/edit/WeightProfileEditScreen'
import { authenticate } from '../auth/routes/AuthenticatedRoute'

export default function getWeightProfilesRoutes() {
  return (
    <Route key={'wp0'} path='weightProfiles' component={authenticate(Layout)}>
      <Route key={'wp1'} path='edit' component={WeightProfileEditScreen} />
    </Route>
  )
}
