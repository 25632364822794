import React from 'react'
import { inject, observer } from 'mobx-react'
import { AgGridReact } from 'ag-grid-react'
import AGGridWidgetPagingRow from '../../../shared/ag-grid/AGGridPagingRow'
import AGGridUserCell from '../../../shared/ag-grid/AGGridUserCell'
import { AlwaysAvailableSurveysWidgetVM } from '../../view-models/always-available-surveys-widget/AlwaysAvailableSurveysWidgetVM'
import { AlwaysAvailableUserSurveyRowVM } from '../../view-models/always-available-surveys-widget/AlwaysAvailableUserSurveyRowVM'
import { CardContent, IconButton, Tooltip, Typography } from '@material-ui/core'
import ArrowForward from '@material-ui/icons/ArrowForward'
import { LabelsStore } from '../../../labels/store/LabelsStore'

interface Props {
  vm: AlwaysAvailableSurveysWidgetVM
  labelsStore?: LabelsStore
}

const WidgetTable: React.FC<Props> = ({ vm, labelsStore }) => {
  if (!vm) return null
  // if (!vm.shouldRender) return null

  const renderOwnerCell = (props) => {
    const row: AlwaysAvailableUserSurveyRowVM = props.data
    if (!row) return null
    return <AGGridUserCell userId={row.fromUserId} name={row.fromUserName} iconURL={row.fromUserIconURL} />
  }

  const renderActionsCell = (props) => {
    const row: AlwaysAvailableUserSurveyRowVM = props.data
    if (!row) return <div></div>
    return (
      <div className='my-surveys-widget-actions-cell'>
        <Tooltip
          title={`Take ${labelsStore.getLabelByLanguageAndFor('pulse')}`}
          placement='bottom-start'
          enterDelay={500}
        >
          <IconButton style={{ padding: '3px' }} onClick={() => row.openUserSurvey()}>
            <ArrowForward id='take-survey' />
          </IconButton>
        </Tooltip>
      </div>
    )
  }

  const renderGrid = () => {
    return (
      <div className={vm.hasRows ? 'ag-theme-alpine' : 'ag-theme-alpine ag-grid-no-rows'}>
        <AgGridReact
          gridOptions={{
            ...vm.getGridOptions(),
            frameworkComponents: {
              fromCell: renderOwnerCell,
              actionsCell: renderActionsCell,
            },
          }}
        />
      </div>
    )
  }

  const renderNoRowsContent = () => {
    if (vm.hasRows) return
    return (
      <Typography component='div' className='no-rows'>
        {vm.noRowsToShowLabel}
      </Typography>
    )
  }

  return (
    <>
      <CardContent id='Content'>
        {renderGrid()}
        {renderNoRowsContent()}
        <AGGridWidgetPagingRow vm={vm} />
      </CardContent>
    </>
  )
}

export default inject('completedSurveyResponsesStore', 'labelsStore')(observer(WidgetTable))
