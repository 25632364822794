import { DataStore } from '../../shared/data/DataStore'
import { RootStore } from '../../stores/RootStore'
import { observable } from 'mobx'
import SurveyType from './aggregate/SurveyType'
import { ISurveyTypeDTO } from './dtos/ISurveyTypeDTO'
import { ISurveyTypesFindRequest } from '../interfaces/ISurveyTypesFindRequest'
import { SurveyTypesService } from '../service/SurveyTypesService'

export class SurveyTypesFindDataStore extends DataStore<SurveyType, ISurveyTypeDTO> {
  private request: ISurveyTypesFindRequest

  constructor(rootStore: RootStore, request: ISurveyTypesFindRequest) {
    super(rootStore, SurveyType, 'surveyTypes', [
      'organizationId',
      'name',
      'description'
    ])
    this.request = request
    this.paged = true
    this.recordsPerPage = 100
    this.sortColumnName = 'name'
    this.pageNumber = 1
    this.listenToListRecordsViaApiUpdates = true
  }

  @observable public isLoaded: boolean = false

  public onListRecordsLoaded() {
    this.isLoaded = true
  }

  public setRequest(request: ISurveyTypesFindRequest) {
    this.request = request
  }

  public getRequest(): ISurveyTypesFindRequest {
    return this.request
  }

  protected getListRecordsApiCall() {
    this.isLoaded = false
    return async () => {
      const req: ISurveyTypesFindRequest = {
        ...this.request,
        // userId: this.rootStore.appStore.currentUserId,
        // organizationId: 'DEFAULT',
        // folderId: null,
        terms: this.filter,
        skip: this.recordsPerPage * (this.pageNumber - 1),
        limit: this.recordsPerPage,
        sortColumnName: this.sortColumnName,
        sortDirection: this.sortDirection,
        listColumns: this.listColumns,
      }
      const svc = new SurveyTypesService(this.rootStore)
      const result = await svc.getSurveyTypes(req)
      this.totalRecords = result.count
      return result.surveyTypes
    }
  }

  public get surveyTypes() {
    return this.records
  }
}
