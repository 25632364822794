import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { EventsStore } from '../../store/EventsStore'
import { Grid, TextField, InputLabel } from '@material-ui/core'

interface ICalendarEventDrawerNotes {
  localizationStore?: LocalizationStore
  eventsStore?: EventsStore
}

const CalendarEventDrawerNotes: React.FC<ICalendarEventDrawerNotes> = ({
  localizationStore,
  eventsStore,
}) => {
  const { lzStrings } = localizationStore
  const { editVM: vm } = eventsStore

  return (
    <Grid item xs={12} className='calendar-event-drawer-notes-grid'>
      <InputLabel>{lzStrings.calendarEventDrawer.notes}</InputLabel>
      <TextField
        id='eventNotes'
        multiline
        fullWidth
        minRows='4'
        maxRows='4'
        value={vm.event.notes}
        onChange={(e) => vm.setNotes(e.target.value)}
        variant='outlined'
      />
    </Grid>
  )
}

export default inject('localizationStore', 'eventsStore')(observer(CalendarEventDrawerNotes))
