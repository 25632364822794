import { observer } from 'mobx-react'
import React from 'react'
import { CMSItemsLayoutTableRowVM } from '../../view-models/CMSItemsLayoutTableRowVM'
import './NameCell.scss'

const NameCell = (props) => {
  const data: CMSItemsLayoutTableRowVM = props.data
  if (!data) return <div></div>

  return (
    <div id='ContentNameCell'>
      <span>{data.Title}</span>
    </div>
  )
}

export default observer(NameCell)
