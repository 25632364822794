import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { EventsStore } from '../../store/EventsStore'
import { Grid, TextField, Button, InputLabel, Input } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { optionsType } from 'mobx-persist'
import { IResourceDTO } from 'src/app/resources/store/dtos/IResourceDTO'
import { isNull } from 'util'
import { AgAbstractInputField } from 'ag-grid-community/dist/lib/widgets/agAbstractInputField'

interface ICalendarEventDrawerResources {
  localizationStore?: LocalizationStore
  eventsStore?: EventsStore
}

const CalendarEventDrawerResources: React.FC<ICalendarEventDrawerResources> = ({
  localizationStore,
  eventsStore,
}) => {
  const { lzStrings } = localizationStore
  const { editVM: VM } = eventsStore

  const renderNewResourceFields = () => {
    if (!VM.addNewResource || !VM.canEditResource) return null
    return (
      <>
        <Grid item xs={12} className='calendar-event-drawer-resources-grid'>
          <InputLabel required>{lzStrings.calendarEventDrawer.resources}</InputLabel>
          <TextField
            error={!VM.resourceValid}
            variant='outlined'
            placeholder={lzStrings.calendarEventDrawer.add_resource}
            value={VM.newResource.name}
            fullWidth
            margin='none'
            onChange={(e) => VM.setNewResourceTitle(e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={12} className='calendar-event-drawer-resources-grid'>
          <InputLabel>{lzStrings.calendarEventDrawer.description}</InputLabel>
          <TextField
            multiline
            minRows={5}
            margin='none'
            placeholder={lzStrings.calendarEventDrawer.resource_description}
            value={VM.newResource.description}
            onChange={(e) => VM.setNewResourceDescription(e.target.value)}
            fullWidth
            variant='outlined'
            id='description'
          />
        </Grid>
        <Grid
          item
          xs={12}
          id='save-resource-button'
          className='calendar-event-drawer-resources-grid button'
        >
          <Button id='cancel-button' variant='text' onClick={(e) => VM.toggleAddNewResource()}>
            {lzStrings.calendarEventDrawer.cancel}
          </Button>
          <Button
            id='save-resource-button'
            variant='contained'
            onClick={(e) => VM.saveNewResource()}
          >
            {lzStrings.calendarEventDrawer.save_resource}
          </Button>
        </Grid>
      </>
    )
  }

  const renderResourceSelect = () => {
    if (VM.addNewResource) return null
    return (
      <Grid item xs={12} className='calendar-event-drawer-resources-grid'>
        <InputLabel>{lzStrings.calendarEventDrawer.resources}</InputLabel>
        <Autocomplete
          id='eventDrawerResourcesMultiSelect'
          className='resources-input'
          multiple
          filterSelectedOptions
          options={VM.resources.sort((a, b) => -b.name.localeCompare(a.name))}
          getOptionLabel={(option) => option.name}
          filterOptions={(options, params) => {
            const filteredOpts = []
            if (VM.canEditResource) {
              options.push({
                objectId: '',
                name: 'Add New...',
                organizationId: '',
                description: '',
                isDeleted: false,
              } as IResourceDTO)
            }

            options.forEach((element) => {
              if (
                element.name === 'Add New...' ||
                element.name.toLowerCase().includes(params.inputValue.toLowerCase())
              )
                filteredOpts.push(element)
            })

            return filteredOpts
          }}
          onChange={(e, value: IResourceDTO[]) => VM.handleResourceChange(value)}
          value={VM.selectedResources}
          renderInput={(params) => (
            <TextField {...params} variant='outlined' fullWidth required margin='none' />
          )}
        />
      </Grid>
    )
  }
  return (
    <>
      {renderNewResourceFields()}
      {renderResourceSelect()}
    </>
  )
}

export default inject('localizationStore', 'eventsStore')(observer(CalendarEventDrawerResources))
