import { observable, action } from 'mobx'
import { serializable, serialize, deserialize, list, object, primitive } from 'serializr'
import { ITrainingItemDTO } from '../dto/ITrainingItemDTO'
import { ContentItem } from './ContentItem'
import { ContentItemType } from '../types/ContentItemType'
import { ApplicableParticipant } from './ApplicableParticipant'
import { ParticipantVM } from 'src/app/participants-select/view-models/ParticipantVM'
import uuid from 'uuid/v4'
import { Quiz } from '../../quiz/aggregate/Quiz'
import { IApplicableParticipantDTO } from '../dto/IApplicableParticipantDTO'
import { IAggregate } from 'src/app/shared/data/IAggregate'

export class TrainingItem implements ITrainingItemDTO, IAggregate {
  public static create(orgId: string, userId: string): TrainingItem {
    const trainingItem = new TrainingItem()
    trainingItem.organizationId = orgId
    trainingItem.createdByUserId = userId
    trainingItem.addContentItem('link')
    trainingItem.quiz = Quiz.create()
    return trainingItem
  }

  @serializable @observable public objectId: string = ''
  @serializable @observable public name: string = ''
  @serializable @observable public description: string = ''
  @serializable(list(primitive())) @observable public categories: string[] = []
  @serializable(list(object(ContentItem))) @observable public contentItems: ContentItem[] = []
  @serializable(list(object(ApplicableParticipant)))
  @observable
  public applicableParticipants: ApplicableParticipant[] = []
  @serializable @observable public organizationId: string = ''
  @serializable @observable public createdByUserId: string = ''
  @serializable @observable public mustCompleteInOrder: boolean = false
  @serializable(object(Quiz)) @observable public quiz: Quiz = null
  @serializable @observable public requireProofOfCompletion: boolean = false
  @serializable @observable public requireQuiz: boolean = false
  @serializable @observable public requireCertificate: boolean = false
  @serializable @observable public block: string = ''

  isOnServer: boolean = false
  @serializable @observable isDeleted: boolean = false

  @action
  public setName(val: string) {
    this.name = val
  }

  @action
  public setBlock(val: string) {
    this.block = val
  }

  @action
  public setDescription(val: string) {
    this.description = val
  }

  @action
  public toggleRequireCompletion() {
    this.requireProofOfCompletion = !this.requireProofOfCompletion
  }

  @action
  public toggleRequireQuiz() {
    this.requireQuiz = !this.requireQuiz
  }

  @action
  public toggleRequireCertificate() {
    this.requireCertificate = !this.requireCertificate
  }

  @action
  public setCategories(tags: string[]) {
    this.categories = tags
  }

  @action
  public setRolesAndGroups(array: IApplicableParticipantDTO[]) {
    this.applicableParticipants = []
    array.forEach((arr) => {
      const part = ApplicableParticipant.create(arr.id, arr.name, arr.type)
      this.applicableParticipants.push(part)
    })
  }

  @action
  public toggleMustCompleteInOrder() {
    this.mustCompleteInOrder = !this.mustCompleteInOrder
  }

  @action
  public addContentItem(type: ContentItemType) {
    const content = ContentItem.create(type)
    this.contentItems.push(content)
    return content.id
  }

  @action
  public duplicateContentItem(id: string) {
    const contentCopy = this.contentItems.find((content) => content.id === id).clone()
    contentCopy.id = uuid()
    this.contentItems.push(contentCopy)
  }

  @action
  public deleteContentItem(id: string) {
    this.contentItems = this.contentItems.filter((contentItem) => contentItem.id !== id)
  }

  @action
  public markIsOnServer() {
    this.isOnServer = true
  }

  @action
  public markIsNotOnServer() {
    this.isOnServer = true
  }

  public serialize() {
    const obj = serialize(this)
    return obj
  }

  public clone(): TrainingItem {
    return deserialize(TrainingItem, this.serialize())
  }
}
