import { Dialog, DialogContent, DialogTitle, DialogActions, Button, Grid, Typography, CircularProgress } from '@material-ui/core'
import { AgGridReact } from 'ag-grid-react'
import { inject, observer } from 'mobx-react'
import React from 'react'
import LocalizationStore from '../../localization/LocalizationStore'
import { SurveysStore } from '../../surveys/store/SurveysStore'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import './SurveyEventSummaryDialog.scss'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'

interface Props {
  surveysStore?: SurveysStore
  localizationStore?: LocalizationStore
}

const SurveyEventSummaryDialog: React.FC<Props> = ({ surveysStore, localizationStore }) => {
  const { editVM } = surveysStore.viewModels
  if (!editVM) return null
  const vm = editVM.surveyParticipantsList
  if (!vm.showEmailSummary) return null
  const emailSummaryVM = vm.emailSummaryVM
  if (!emailSummaryVM) return null
  const { lzStrings } = localizationStore


  const renderLoading = () => {
    if (!emailSummaryVM.loading) return
    return (
      <Typography component='div' className='no-rows'>
        Loading...
        <CircularProgress className='progressCircle' />
      </Typography>
    )
  }

  return (
    <Dialog
      className='surveyEventSummary'
      maxWidth={'lg'}
      onClose={() => vm.toggleViewEmailSummary()}
      open={vm.showEmailSummary}
    >
      <DialogTitle>
        <Grid container>
          <Grid item lg={12} md={12} className='buttons-container'>
            <Grid item lg={8} className='buttons-item'>
              Email Summary Events
            </Grid>
            <Grid item>
              <ToggleButtonGroup
                size="small"
                value={emailSummaryVM.getEventType}
                exclusive
                onChange={(e, val) => emailSummaryVM.handleEventChange(val)}
                className='tab-buttons'
              >
                <ToggleButton size='small' value={'published-email'}>Published Email</ToggleButton>
                <ToggleButton size='small' value={'reminder-email'}>Reminder Email</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <div className='ag-theme-alpine'>
          <AgGridReact
            gridOptions={{ suppressCellSelection: true, rowModelType: 'infinite'}}
            rowSelection={'multiple'}
            suppressRowClickSelection={true}
            suppressClickEdit={false}
            columnDefs={emailSummaryVM.columnDefs}
            onGridReady={(e) => emailSummaryVM.onGridReady(e)}
            getRowNodeId={(e) => emailSummaryVM.getRowNodeId(e)}
            editType=''
          />
          {renderLoading()}
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' size='large' onClick={() => vm.toggleViewEmailSummary()}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default inject('surveysStore', 'localizationStore')(observer(SurveyEventSummaryDialog))
