import { RootStore } from '../../stores/RootStore'
import { action, observable, computed } from 'mobx'
import moment from 'moment-timezone'
import { CustomPublishGroup } from '../aggregate/CustomPublishGroup'
import { TimeZoneType } from '../types/TimeZoneType'
import { SurveyEditVM } from './SurveyEditVM'
import { ParticipantsSelectVM } from '../../participants-select/view-models/ParticipantsSelectVM'
import { ParticipantVM } from '../../participants-select/view-models/ParticipantVM'
import { AnonymousParticipantVM } from '../../participants-select/view-models/AnonymousParticipantVM'
import { IPublishGroup } from '../dtos/IPublishGroup'
import { DateUtils } from '../../shared/data/DateUtils'

export class CustomPublishGroupVM {
  private rootStore: RootStore
  private surveyEditVM: SurveyEditVM

  constructor(
    rootStore: RootStore,
    surveyEditVM: SurveyEditVM,
    publishGroup?: IPublishGroup
  ) {
    this.rootStore = rootStore
    this.surveyEditVM = surveyEditVM
    this.customPublishGroup = publishGroup ? CustomPublishGroup.create(publishGroup) : new CustomPublishGroup()
    this.customPublishGroupSelectVM = new ParticipantsSelectVM(
      this.rootStore,
      false,
      false,
      false,
      true,
      false
    )
    this.customPublishGroupSelectVM.setParticipants(publishGroup.participants)
    this.candidatePublishGroupSelectVM = new ParticipantsSelectVM(
      this.rootStore,
      false,
      false,
      false,
      true,
      false
    )
    // initialize based on the saved timezone for correct time
    this.deliveryTime = DateUtils.getBrowserDate(this.customPublishGroup.deliveryTime, this.customPublishGroup.deliveryTimeZone)
    this.startDate = DateUtils.getBrowserDate(this.customPublishGroup.deliveryTime, this.customPublishGroup.deliveryTimeZone)
    this.deliveryTimeZoneVal = this.customPublishGroup.deliveryTimeZone
  }

  @observable public deliveryTime: Date
  @observable public startDate: Date
  @observable public deliveryTimeZoneVal: string
  @observable public customPublishGroupDialogOpen: boolean = false
  @observable public customPublishGroup: CustomPublishGroup = new CustomPublishGroup()
  @observable public customPublishGroupSelectVM: ParticipantsSelectVM = null
  @observable public candidatePublishGroupSelectVM: ParticipantsSelectVM = null

  @action
  public resetTimes() {
    this.deliveryTime = DateUtils.getBrowserDate(this.customPublishGroup.deliveryTime, this.customPublishGroup.deliveryTimeZone)
    this.startDate = DateUtils.getBrowserDate(this.customPublishGroup.deliveryTime, this.customPublishGroup.deliveryTimeZone)
    this.deliveryTimeZoneVal = this.customPublishGroup.deliveryTimeZone
  }

  @computed
  public get isCustomPublishGroupDialogOpen(): boolean {
    return this.customPublishGroupDialogOpen
  }

  @action
  public toggleCustomPublishGroupDialogOpen() {
    this.customPublishGroupDialogOpen = !this.customPublishGroupDialogOpen
  }

  @action
  public setCustomPublishGroup(val: CustomPublishGroup) {
    this.customPublishGroup = CustomPublishGroup.create(val)
  }

  @action
  public deleteCustomPublishGroup() {
    this.customPublishGroup = null
  }

  @action
  public setID(val: string) {
    this.customPublishGroup.setID(val)
  }

  @action
  public setEnabled(val: boolean) {
    this.customPublishGroup.setEnabled(val)
  }

  @action
  public setStartDate(val: Date) {
    this.startDate = val
  }

  @action
  public setDeliveryTime(hour: number, minute: number) {
    const newTime = moment(this.startDate).hour(hour).minute(minute).second(0).millisecond(0)

    this.deliveryTime = newTime.toDate()
  }

  @action
  public setDeliveryTimeZone(val: any) {
    if (!val) return
    this.deliveryTimeZoneVal = val.value
  }

  @action
  public setAllParticipants(val: Array<AnonymousParticipantVM | ParticipantVM>) {
    this.customPublishGroup.setAllParticipants(val)
  }

  @action
  public addParticipant(val: AnonymousParticipantVM | ParticipantVM) {
    this.customPublishGroup.addParticipant(val)
  }

  @action
  public deleteParticipantById(id: string) {
    this.customPublishGroup.deleteParticipantById(id)
  }

  @action
  public deleteParticipantByIndex(index) {
    this.customPublishGroup.deleteParticipantByIndex(index)
  }

  @action
  public clearAllParticipants() {
    this.customPublishGroup.clearAllParticipants()
  }

  @computed
  public get id(): string {
    return this.customPublishGroup.id
  }

  @computed
  public get enabled(): boolean {
    return this.customPublishGroup.enabled
  }

  @computed
  public get deliveryHour(): string {
    return this.customPublishGroup.deliveryHour
  }

  @computed
  public get deliveryMinute(): string {
    return this.customPublishGroup.deliveryMinute
  }

  @computed
  public get deliveryTimeZoneType(): TimeZoneType {
    return this.surveyEditVM.timeZones.find(
      (e) => e.value === this.deliveryTimeZoneVal
    )
  }

  @computed
  public get deliveryTimeZone(): TimeZoneType {
    return this.surveyEditVM.timeZones.find(
      (e) => e.value === this.customPublishGroup.deliveryTimeZone
    )
  }

  @computed
  public get participants(): Array<AnonymousParticipantVM | ParticipantVM> {
    return this.customPublishGroup.participants.map((e) => new ParticipantVM(this.rootStore, e))
  }

  @computed
  public get isStartDateValid(): boolean {
    if (!this.customPublishGroup.startDate) return false
    return true
  }
}
