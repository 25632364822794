import Parse from 'parse'
import { observable, action, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { DataStore } from '../../shared/data/DataStore'
import { SurveyResult } from '../aggregate/SurveyResult'
import { ISurveyResultDTO } from '../dtos/ISurveyResultDTO'
import { SurveyResultsVM } from '../view-models/SurveyResultsVM'
import { ISurveyResultFindRequest } from '../interfaces/ISurveyResultFindRequest'
import { SurveyResultsService } from '../service/SurveyResultsService'
import { SurveyResponse } from '../../survey-responses/aggregate/SurveyResponse'
import { deserialize } from 'serializr'

export class SurveyResultsStore extends DataStore<SurveyResult, ISurveyResultDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, SurveyResult, 'surveyResults', [
      'objectId',
      'organizationId',
      'surveyId',
      'questionAnswers',
    ])
  }

  @observable public currentSurveyId: string = ''
  @observable public resultsVM: SurveyResultsVM = null
  @observable public result = null
  @observable public anonymousToken: string = null

  @action
  public loadSurveyResultsVM(surveyId: string, userSurveyId: string, token: string) {
    if (token) {
      if (this.anonymousToken && token === this.anonymousToken) return
      this.setCurrentAnonymousToken(token)
      this.loadVMFromAnonymousToken(token)
    } else {
      if (this.currentSurveyId && surveyId === this.currentSurveyId) return
      this.setCurrentSurveyId(surveyId)
      this.loadVM(userSurveyId)
    }
  }

  @action
  public async loadVMFromAnonymousToken(token: string) {
    const svc = new SurveyResultsService(this.rootStore)
    const req: ISurveyResultFindRequest = {
      anonymousToken: token,
    }
    const result = await svc.findByToken(token)
    const surveyResponse: SurveyResponse = deserialize(SurveyResponse, result.surveyResponse)
    const surveyResult: SurveyResult = deserialize(SurveyResult, result.surveyResult)
    this.result = surveyResult
    this.resultsVM = new SurveyResultsVM(
      this.rootStore,
      surveyResponse.userSurveyId,
      surveyResponse
    )
  }

  @action
  private async loadVM(userSurveyId: string, attempts: number = 0) {
    if (attempts === 20) return
    this.resultsVM = undefined
    const response =
      await this.rootStore.completedSurveyResponsesStore.currentOrgCompletedSurveyResponses.find(
        (e) => e.userSurveyId === userSurveyId
      )
    if (!response) return setTimeout(() => this.loadVM(userSurveyId, ++attempts), 1000)
    const responseId = response.objectId
    const surveyResponse = await this.rootStore.completedSurveyResponsesStore.getFullRecord(
      responseId
    )

    this.resultsVM = new SurveyResultsVM(this.rootStore, userSurveyId, surveyResponse)
  }

  @action
  public setCurrentSurveyId(surveyId: string) {
    this.currentSurveyId = surveyId
    this.loadListRecords()
  }

  @action
  public setCurrentAnonymousToken(token: string) {
    this.anonymousToken = token
    // this.loadListRecords()
  }

  protected getBaseQuery() {
    const query = new Parse.Query(this.className)
    query.equalTo('organizationId', this.rootStore.appStore.currentOrgId)
    query.equalTo('surveyId', this.currentSurveyId)
    return query
  }

  // protected getListRecordsApiCall() {
  //   return async () => {
  //     const req = {
  //       token: this.currentSurveyId,
  //     }
  //     const svc = new SurveyResultsService(this.rootStore)
  //     const result = await svc.findByToken(req)
  //     return result.surveyResults
  //   }
  // }

  @computed
  public get currentOrgSurveyResults(): SurveyResult[] {
    if (this.rootStore.appStore.currentOrgId) {
      return this.records.filter((e) => e.organizationId === this.rootStore.appStore.currentOrgId)
    } else {
      return this.records
    }
  }
}
