import { action, computed, observable } from 'mobx'
import { serializable } from 'serializr'
import moment from 'moment'
import { IAutoCloseDTO } from '../dto/IAutoCloseDTO'
import { PeriodType } from '../type/PeriodType'

export class AutoClose implements IAutoCloseDTO {
  static create(due: any) {
    const autoClose = new AutoClose()
    autoClose.enabled = due.enabled
    if (due.value) autoClose.value = due.value
    if (due.period) autoClose.period = due.period
    return autoClose
  }

  @serializable @observable enabled: boolean = false
  @serializable @observable value: number = 5
  @serializable @observable period: PeriodType = 'day'

  @action
  public toggleIsEnabled() {
    this.enabled = !this.enabled
  }

  @action
  public setPeriod(val: PeriodType) {
    this.period = val
  }

  @action
  public setValue(val: number) {
    this.value = val
  }
}
