import React from 'react'
import { inject, observer } from 'mobx-react'
import { Card, CircularProgress } from '@material-ui/core'
import WidgetHeader from './WidgetHeader'
import WidgetContent from './WidgetContent'
import Drawer from '../drawer/Drawer'
import { UserTasksStore } from '../../store/UserTasksStore'
import { AudienceMembersStore } from '../../../audience-members/store/AudienceMembersStore'
import './PendingUserTasksWidget.scss'

interface Props {
  tasksStore?: UserTasksStore
  audienceMembersStore?: AudienceMembersStore
}

const PendingUserTasksWidget: React.FC<Props> = ({ tasksStore, audienceMembersStore }) => {

  const renderSpinner = () => {
    if (tasksStore.loaded && audienceMembersStore.loaded) return
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  const renderContent = () => {
    if (!tasksStore.loaded) return
    if (!audienceMembersStore.loaded) return
    return (
      <WidgetContent />
    )
  }

  return (
    <>
      <Card elevation={0} id='PendingUserTasksWidget'>
        <WidgetHeader />
        {renderSpinner()}
        {renderContent()}
      </Card>
      <Drawer />
    </>
  )
}

export default inject('tasksStore', 'audienceMembersStore')(observer(PendingUserTasksWidget))
