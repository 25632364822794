import React from 'react'
import { OrganizationsSAStore } from '../../store/OrganizationsSAStore'
import { inject, observer } from 'mobx-react'
import { Button, CircularProgress, Grid, IconButton, InputLabel, Snackbar, TextField } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import './OrganizationEditDrawer.scss'

interface Props {
  organizationsSAStore?: OrganizationsSAStore
}

const OrganizationTemplates: React.FC<Props> = ({ organizationsSAStore }) => {
  const { editVM: vm } = organizationsSAStore
  if (!vm || vm.isNew) return null

  const renderSaveButton = () => {
    if (vm.isCreatingTemplate || vm.isCheckingOrgTemplateName) {
      return (
        <CircularProgress className='progressCircle'/>
      )
    } else {
      return ('Save')
    }
  }

  const renderExists = () => {
    if (!vm.orgTemplateNameIsInvalid) return

    return (
      <div className="templateNameError">Name already exists</div>
    )
  }

  const renderTemplates = () => {
    return (
      <div id='orgTemplates' className='details-paper'>
        <h6>Create Template</h6>
        <InputLabel className='details-info-label'>{'Template Name'}</InputLabel>
        <Grid container alignItems='center' justifyContent='flex-start' direction='row'>
          <Grid item xs={10}>
            <TextField
              disabled={!vm.templateIsValid}
              className='details-info-input'
              value={vm.templateName}
              onChange={(e) => vm.setTemplateName(e.target.value)}
              fullWidth
              error={vm.orgTemplateNameIsInvalid}
              variant='outlined'
              id='templateName'
            />
            
          </Grid>
          <Grid item xs={2}>
            <Button
              className='createTemplateButton'
              variant='contained'
              disabled={!vm.templateName || vm.isCreatingTemplate || vm.isCheckingOrgTemplateName || vm.orgTemplateNameIsInvalid}
              onClick={() => vm.createNewTemplate()}
            >
              {renderSaveButton()}
            </Button>
          </Grid>
          {renderExists()}
        </Grid>
      </div>
    )
  }

  return (
    <div id='OrganizationTemplates'>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          {renderTemplates()}
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        className='temmplateSnackBar'
        open={vm.showTemplateSnackBar}
        autoHideDuration={8000}
        onClose={() => vm.toggleTemplateSnackbar()}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id='message-id'>{vm.templateSnackBarMessage}</span>}
        action={[
          <IconButton
            key='close'
            aria-label='close'
            color='inherit'
            onClick={() => vm.toggleTemplateSnackbar()}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </div>
  )
}

export default inject('organizationsSAStore')(observer(OrganizationTemplates))
