import React from 'react'
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Grid,
  IconButton,
  Button,
} from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import LeftIcon from '@material-ui/icons/ChevronLeft'
import RightIcon from '@material-ui/icons/ChevronRight'
import './OrganizationsSAListWidget.scss'
import MuiConfirmDeleteDialog from '../../../shared/MuiConfirmDeleteDialog'
import { OrganizationsSAStore } from '../../store/OrganizationsSAStore'
import { OrganizationsSARowVM } from '../../view-models/list/OrganizationSARowVM'
import LocalizationStore from '../../../localization/LocalizationStore'

interface Props {
  organizationsSAStore?: OrganizationsSAStore
  localizationStore?: LocalizationStore
}

const OrgsTable: React.FC<Props> = ({ organizationsSAStore, localizationStore }) => {
  const { listWidgetVM: vm } = organizationsSAStore
  const { organizationsSADrawer: strings } = localizationStore.lzStrings
  const pagedDataVM = vm.pagedData

  if (!vm) return null
  if (!pagedDataVM.displayRows.length) return null

  const renderSortIndicator = (key: string) => {
    if (pagedDataVM.sort === key) return <i className='fa fa-caret-down' />
    if (pagedDataVM.sort === '-' + key) return <i className='fa fa-caret-up' />
  }

  const renderPagingRow = () => {
    if (!pagedDataVM.displayRows.length) return
    return (
      <Grid container direction='row' justifyContent='flex-end' alignItems='center' className='paging-row'>
        <Grid item>{pagedDataVM.recordsDisplayedInfo}</Grid>
        <Grid item>
          <IconButton onClick={() => pagedDataVM.goPrevPage()} disabled={pagedDataVM.isFirstPage}>
            <LeftIcon />
          </IconButton>
          <IconButton onClick={() => pagedDataVM.goNextPage()} disabled={pagedDataVM.isLastPage}>
            <RightIcon />
          </IconButton>
        </Grid>
      </Grid>
    )
  }

  return (
    <div id='OrgsTable'>
      <Table stickyHeader={false} style={{ tableLayout: 'auto' }} size='small' id='WidgetTable'>
        <TableHead>
          <TableRow style={{ cursor: 'pointer' }}>
            <TableCell onClick={() => pagedDataVM.setSort('name')}>
              {strings.name} {renderSortIndicator('name')}
            </TableCell>
            <TableCell onClick={() => pagedDataVM.setSort('email')}>
              {strings.email} {renderSortIndicator('email')}
            </TableCell>
            <TableCell onClick={() => pagedDataVM.setSort('phone')}>
              {strings.phone} {renderSortIndicator('phone')}
            </TableCell>
            <TableCell onClick={() => pagedDataVM.setSort('zone')}>
              {strings.zone} {renderSortIndicator('zone')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pagedDataVM.displayRows.map((row: OrganizationsSARowVM) => {
            return (
              <TableRow
                hover
                className='table-row'
                key={row.objectId}
                onClick={() => organizationsSAStore.editOrganization(row.objectId)}
              >
                <TableCell align='left' style={{ width: '30%' }} className='name-cell'>
                  {row.name}
                </TableCell>
                <TableCell align='left' style={{ width: '30%' }} className='email-cell'>
                  {row.email}
                </TableCell>
                <TableCell align='left' className='phone-cell'>
                  {row.phone}
                </TableCell>
                <TableCell align='left' className='zone-cell'>
                  {row.zone}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      {/* {renderDeleteDialog()} */}
      {renderPagingRow()}
    </div>
  )
}

export default inject('organizationsSAStore', 'localizationStore')(observer(OrgsTable))
