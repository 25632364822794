import React from 'react'
import { inject, observer } from 'mobx-react'
import { AppBar, Toolbar, Tabs, Tab } from '@material-ui/core'
import ChatIcon from '@material-ui/icons/Chat'
import InfoIcon from '@material-ui/icons/Info'
import TuneIcon from '@material-ui/icons/Tune'
import PersonPinIcon from '@material-ui/icons/PersonPin'
import Builder from './builder/Builder'
import { SurveysStore } from '../../store/SurveysStore'
import ParticipantsTabPanel from './participants/ParticipantsTabPanel'
import PublishTabPanel from './publish/PublishTabPanel'
import PreviewTabPanel from './preview/PreviewTabPanel'
import PreviewIcon from '@material-ui/icons/RemoveRedEye'
import ChartIcon from '@material-ui/icons/InsertChart'
import MailIcon from '@material-ui/icons/Mail'
import AnalysisTabPanel from './analysis/AnalysisTabPanel'
import ResponsesTabPanel from './responses/ResponsesTabPanel'
import ScheduleTabPanel from './schedule/ScheduleTabPanel'
import InvitationsTabPanel from './invitations/InvitationsTabPanel'
import FeedBackTabPanel from './feedback/FeedbackTabPanel'
import { SurveyEditTabsEnum } from '../../types/SurveyEditTabsEnum'
import DetailsTabPanel from './details/DetailsTabPanel'
import SurveyOptions from './optionsV2/SurveyOptions'
import './TabsBar.scss'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div hidden={value !== index} {...other} className='survey-edit-tab-panel-container'>
      {children}
    </div>
  )
}

interface Props {
  surveysStore?: SurveysStore
}

const TabsBar: React.FC<Props> = ({ surveysStore }) => {
  const { editVM } = surveysStore.viewModels
  const vm = editVM
  if (!vm) return null
  if (vm.currentTabIndex === 4 && !vm.surveyType.isFeedbackType) vm.setCurrentTab(0)
  if (
    (vm.currentTabIndex === SurveyEditTabsEnum.ANALYSIS ||
      vm.currentTabIndex === SurveyEditTabsEnum.RESPONSES) &&
    editVM.limited
  ) {
    vm.setCurrentTab(SurveyEditTabsEnum.DETAILS)
  }
  const handleChange = (e: any, newValue: number) => {
    vm.setCurrentTab(newValue)
  }

  const renderTabs = () => {
    if (vm.steppersVM && vm.steppersVM.showSteppers) return null
    return (
      <AppBar position='static' elevation={0}>
        <Toolbar>
          <div>
            <Tabs
              value={vm.currentTabIndex}
              onChange={handleChange}
              indicatorColor={'primary'}
              textColor='primary'
              variant='fullWidth'
              className='manage-survey-tabs'
            >
              {vm.isPublished && !vm.limited && (
                <Tab label='Analysis' value={SurveyEditTabsEnum.ANALYSIS} icon={<ChartIcon />} />
              )}
              {vm.isPublished && !vm.limited && (
                <Tab
                  label={<div className='responses-tab-label'>{vm.responseTabString}</div>}
                  value={SurveyEditTabsEnum.RESPONSES}
                  icon={<PersonPinIcon />}
                />
              )}
              <Tab
                label='Details'
                className={vm.isNameValid ? '' : 'error'}
                value={SurveyEditTabsEnum.DETAILS}
                icon={<InfoIcon />}
              />
              <Tab
                className={vm.areQuestionsValid ? '' : 'error'}
                label='Builder'
                value={SurveyEditTabsEnum.BUILDER}
                icon={<ChatIcon />}
              />
              <Tab
                className={vm.isPublishedByUserIdValid && vm.isTemplatesValid ? '' : 'error'}
                label='Options'
                value={SurveyEditTabsEnum.OPTIONS}
                icon={<TuneIcon />}
              />
              {!vm.isTemplate && (
                <Tab
                  className={vm.isParticipantsValid || !vm.participantsLoaded ? '' : 'error'}
                  label='Participants'
                  icon={<PersonPinIcon />}
                  value={SurveyEditTabsEnum.PARTICIPANTS}
                />
              )}
              {vm.surveyType && vm.surveyType.isFeedbackType && (
                <Tab
                  className={vm.feedbackParticipantsValid ? '' : 'error'}
                  label={vm.surveyTypeForPrompt}
                  icon={<PersonPinIcon />}
                  value={SurveyEditTabsEnum.FEEDBACK}
                />
              )}
              <Tab label='Preview' value={SurveyEditTabsEnum.PREVIEW} icon={<PreviewIcon />} />
              {vm.isPublished && (
                <Tab
                  label='Invitations'
                  value={SurveyEditTabsEnum.INVITATIONS}
                  icon={<MailIcon />}
                />
              )}
              {renderPublishTab()}
            </Tabs>
          </div>
        </Toolbar>
      </AppBar>
    )
  }

  const renderPublishTab = () => {
    if (vm.isPublished) return null
    if (vm.isTemplate) return null
    if (!vm.survey.isNew) return null
    return (
      <Tab
        label={'Publish'}
        className={vm.isStartDateValid ? '' : 'error'}
        value={SurveyEditTabsEnum.PUBLISH}
        icon={<i style={{ fontSize: 15 }} className='fa fa-paper-plane' aria-hidden='true'></i>}
      />
    )
  }

  return (
    <>
      <div id='ManageSurveyTabsBar'>
        {renderTabs()}
        <TabPanel
          key={SurveyEditTabsEnum.ANALYSIS}
          value={vm.currentTabIndex}
          index={SurveyEditTabsEnum.ANALYSIS}
        >
          <AnalysisTabPanel />
        </TabPanel>
        <TabPanel
          key={SurveyEditTabsEnum.RESPONSES}
          value={vm.currentTabIndex}
          index={SurveyEditTabsEnum.RESPONSES}
        >
          <ResponsesTabPanel />
        </TabPanel>
        <TabPanel
          key={SurveyEditTabsEnum.DETAILS}
          value={vm.currentTabIndex}
          index={SurveyEditTabsEnum.DETAILS}
        >
          <DetailsTabPanel />
        </TabPanel>
        <TabPanel
          key={SurveyEditTabsEnum.BUILDER}
          value={vm.currentTabIndex}
          index={SurveyEditTabsEnum.BUILDER}
        >
          <Builder />
        </TabPanel>
        <TabPanel
          key={SurveyEditTabsEnum.OPTIONS}
          value={vm.currentTabIndex}
          index={SurveyEditTabsEnum.OPTIONS}
        >
          {/* <SurveyOptionsTabPanel /> */}
          <SurveyOptions />
        </TabPanel>
        <TabPanel
          key={SurveyEditTabsEnum.PARTICIPANTS}
          value={vm.currentTabIndex}
          index={SurveyEditTabsEnum.PARTICIPANTS}
        >
          <ParticipantsTabPanel />
        </TabPanel>
        <TabPanel
          key={SurveyEditTabsEnum.FEEDBACK}
          value={vm.currentTabIndex}
          index={SurveyEditTabsEnum.FEEDBACK}
        >
          <FeedBackTabPanel />
        </TabPanel>
        <TabPanel
          key={SurveyEditTabsEnum.PREVIEW}
          value={vm.currentTabIndex}
          index={SurveyEditTabsEnum.PREVIEW}
        >
          <PreviewTabPanel />
        </TabPanel>
        <TabPanel
          key={SurveyEditTabsEnum.SCHEDULE}
          value={vm.currentTabIndex}
          index={SurveyEditTabsEnum.SCHEDULE}
        >
          <ScheduleTabPanel />
        </TabPanel>
        <TabPanel
          key={SurveyEditTabsEnum.INVITATIONS}
          value={vm.currentTabIndex}
          index={SurveyEditTabsEnum.INVITATIONS}
        >
          <InvitationsTabPanel />
        </TabPanel>
        <TabPanel
          key={SurveyEditTabsEnum.PUBLISH}
          value={vm.currentTabIndex}
          index={SurveyEditTabsEnum.PUBLISH}
        >
          <PublishTabPanel />
        </TabPanel>
      </div>
    </>
  )
}

export default inject('surveysStore')(observer(TabsBar))
