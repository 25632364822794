import React from 'react'
import { observer, inject } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { SurveyTypesStore } from '../../../survey-types/store/SurveyTypesStore'
import {
  CardHeader,
  IconButton,
  InputBase,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  Tooltip,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import './Header.scss'
import { ChartWidgetVM } from '../../view-models/chart-widget/ChartWidgetVM'
import CategoryTreeSelect from '../../../surveys/views/edit/question-panel/CategoryTreeSelect'
import CloseIcon from '@material-ui/icons/Close'
import ReplayIcon from '@material-ui/icons/Replay'
import FilterListIcon from '@material-ui/icons/FilterList'
import TrackChangesIcon from '@material-ui/icons/TrackChanges'

interface Props {
  widgetVM: ChartWidgetVM
  localizationStore?: LocalizationStore
}

const Header: React.FC<Props> = ({ widgetVM, localizationStore }) => {
  const { visualizationWidget: strings } = localizationStore.lzStrings
  const renderGoalModal = () => {
    return (
      <Dialog onClose={() => widgetVM.closeGoalModal()} open={widgetVM.goalModalShown}>
        <DialogTitle disableTypography className='dialog-title'>
          <Typography variant='h6'>{strings.create_goal}</Typography>
        </DialogTitle>
        <DialogContent style={{ width: '600px', height: '300px' }}>
          {widgetVM.highlightedData}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => widgetVM.closeGoalModal()} color='primary'>
            {strings.cancel}
          </Button>
          <Button onClick={() => widgetVM.closeGoalModal()} color='primary'>
            {strings.ok}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const renderRevertAllButton = () => {
    if (!widgetVM.canRevertAll) return
    return (
      <Tooltip title={strings.revert} placement='bottom-start'>
        <IconButton onClick={() => widgetVM.revertAll()}>
          <ReplayIcon />
        </IconButton>
      </Tooltip>
    )
  }

  return (
    <>
      <CardHeader
        id='Header'
        title={widgetVM.name}
        action={
          <>
            {renderRevertAllButton()}
            <Tooltip title={strings.viz_menu} placement='bottom-start'>
              <IconButton onClick={() => widgetVM.openDrawer()}>
                <MoreVertIcon />
              </IconButton>
            </Tooltip>
          </>
        }
      />
      {renderGoalModal()}
    </>
  )
}

export default inject('localizationStore')(observer(Header))
