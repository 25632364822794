import { action, computed, observable } from 'mobx'
import { MediaItem } from '../../media-items/aggregate/MediaItem'
import { MediaUploadPickerVM } from '../../media-items/view-models/media-upload-picker/MediaUploadPickerVM'
import { IPulseCategoryDTO } from '../../pulse-categories/dtos/IPulseCategoryDTO'
import { ChildCategoryRowVM } from '../../pulse-categories/view-models/ChildCategoryRowVM'
import { PulseCategorySelectVM } from '../../pulse-categories/view-models/PulseCategorySelectVM'
import { PulseSetEditVM } from '../../pulse-sets/view-models/PulseSetEditVM'
import { PulseSetVM } from '../../pulse-sets/view-models/PulseSetVM'
import { Media } from '../../questions/aggregates/Media'
import { RootStore } from '../../stores/RootStore'
import PulseQuestion from '../aggregate/PulseQuestion'
import { PulseQuestionsService } from '../services/PulseQuestionsService'
import { PulseQuestionType } from '../types/PulseQuestionType'
import { PulseQuestionOptionVM } from './PulseQuestionOptionVM'
import { YouTubePreviewVM } from './YouTubePreviewVM'
import { VimeoPreviewVM } from './VimeoPreviewVM'
import { MediaItemVM } from '../../surveys/view-models/MediaItemVM'
import { CMSMediaItemVM } from '../../surveys/view-models/CMSMediaItemVM'
import { OldMediaItemVM } from '../../surveys/view-models/OldMediaItemVM'
import { isNumeric } from '../../shared/isNumeric'

export class PulseQuestionEditVM {
  private rootStore: RootStore
  private origPulseQuestion: PulseQuestion = null

  constructor(
    rootStore: RootStore,
    childCategoryId: string,
    pulseQuestion: PulseQuestion,
    isReadOnly?: boolean,
    isFollowUp?: boolean,
    isCampaignEdit?: boolean
  ) {
    if (!isCampaignEdit) this.isLoading = true
    this.rootStore = rootStore
    this.childCategoryId = childCategoryId
    this.pulseQuestion = pulseQuestion
    this.origPulseQuestion = pulseQuestion.clone()
    this.isReadOnly = isReadOnly ? isReadOnly : false
    this.isFollowUp = isFollowUp ? isFollowUp : false
    this.isCampaignEdit = isCampaignEdit ? isCampaignEdit : false
    if (!this.isCampaignEdit) {
      this.setPulseCategoryId()
      this.loadSelectVMs()
    }
  }

  @observable public pulseQuestion: PulseQuestion = null
  @observable public childCategoryId: string = ''
  @observable public grandCategoryId: string = ''
  @observable public parentCategoryId: string = ''
  @observable public tabIndex: number = 0
  @observable public mediaTabIndex: number = 0
  @observable public grandCategorySelectVM: PulseCategorySelectVM = null
  @observable public parentCategorySelectVM: PulseCategorySelectVM = null
  @observable public childCategorySelectVM: PulseCategorySelectVM = null
  @observable public showConfirmDelete: boolean = false
  @observable public isFollowUp: boolean = false
  @observable public isReadOnly: boolean = false
  @observable public isCampaignEdit: boolean = false
  @observable public saveTried: boolean = false
  @observable public previewYouTube: boolean = false
  @observable public cantDeleteLastOption: boolean = false
  @observable public isExpanded: boolean = false
  @observable public isLoading: boolean = false
  @observable public youTubePreviewVM: YouTubePreviewVM = null
  @observable public vimeoPreviewVM: VimeoPreviewVM = null
  @observable public questionTypes: Array<string> = [
    'radio',
    'rating',
    'starRating',
    'emoji',
    'text',
    'yesNo',
    // 'infoText',
    'dropdown',
  ]

  private setPulseCategoryId() {
    this.pulseQuestion.setPulseCategoryId(this.childCategoryId)
  }

  public async loadSelectVMs() {
    const pulseCategory: IPulseCategoryDTO =
      await this.rootStore.pulseCategoriesStore.getFullRecord(this.childCategoryId, true)
    if (pulseCategory) {
      this.parentCategoryId = pulseCategory.parentCategoryId
      this.grandCategoryId = pulseCategory.grandCategoryId
    }
    this.grandCategorySelectVM = new PulseCategorySelectVM(
      this.rootStore,
      undefined,
      undefined,
      this.grandCategoryId
    )
    this.parentCategorySelectVM = new PulseCategorySelectVM(
      this.rootStore,
      this.grandCategoryId,
      undefined,
      this.parentCategoryId
    )
    this.childCategorySelectVM = new PulseCategorySelectVM(
      this.rootStore,
      this.grandCategoryId,
      this.parentCategoryId,
      this.childCategoryId
    )
    this.isLoading = false
  }

  @computed
  public get isSystemAdmin(): boolean {
    return this.rootStore.appStore.isSystemAdmin
  }

  @computed
  public get mediaUploadPickerVM(): MediaUploadPickerVM {
    return this.rootStore.pulseQuestionsStore.mediaUploadPickerVM
  }

  @computed
  public get questionTypeTitle() {
    if (this.questionType === 'rating') return 'Rating (Scale)'
    if (this.questionType === 'emoji') return 'Emoji'
    if (this.questionType === 'text') return 'Short Answer (Text)'
    if (this.questionType === 'radio') return 'Multiple Choice (Radio)'
    if (this.questionType === 'starRating') return 'Star Rating'
    if (this.questionType === 'dropdown') return 'Dropdown'
    return this.questionType
  }

  @computed
  public get isNew(): boolean {
    return Boolean(!this.objectId)
  }

  @computed
  public get isDropdownMultiSelect(): boolean {
    return this.pulseQuestion.isDropdownMultiSelect
  }

  @computed
  public get objectId(): string {
    return this.pulseQuestion.objectId
  }

  @computed
  public get questionType(): PulseQuestionType {
    return this.pulseQuestion.type
  }

  @computed
  public get required(): boolean {
    return this.pulseQuestion.required
  }

  @computed
  public get showMediaFirst(): boolean {
    return this.pulseQuestion.showMediaFirst
  }

  @computed
  public get hasMedia(): boolean {
    if (Boolean(this.youTubeURL)) return true
    if (Boolean(this.vimeoURL)) return true
    return Boolean(this.media.url)
  }

  @computed
  public get isLinked() {
    return this.pulseQuestion.isLinked
  }

  @computed
  public get title() {
    return this.pulseQuestion.title
  }

  @computed
  public get placeholder() {
    return this.pulseQuestion.placeholder
  }

  @computed
  public get youTubeURL() {
    return this.pulseQuestion.youTubeURL
  }

  @computed
  public get vimeoURL() {
    return this.pulseQuestion.vimeoURL
  }

  @computed
  public get ratingDisplay() {
    return this.pulseQuestion.ratingDisplay
  }

  @computed
  public get titleValid(): boolean {
    if (this.isFollowUp && !this.title) return false
    if (!this.saveTried) return true
    if (!this.title) return false
    return true
  }

  @computed
  public get placeholderValid(): boolean {
    if (this.questionType !== 'dropdown') return true
    if (this.isFollowUp && !this.placeholder) return false
    if (this.placeholder === undefined) return false
    if (!this.saveTried) return true
    if (!this.placeholder || this.placeholder === '') return false
    return true
  }

  @computed
  public get isValid(): boolean {
    if (!this.titleValid) return false
    if (this.optionsError) return false
    if (!this.isCampaignEdit) {
      if (!this.parentCategoryValid) return false
      if (!this.childCategoryValid) return false
    }
    if (!this.placeholderValid) return false
    return true
  }

  @computed
  public get optionsError(): boolean {
    if (this.questionType === 'text') return false
    return this.options.some((o) => !o.isValid)
  }

  @computed
  public get options(): PulseQuestionOptionVM[] {
    return this.pulseQuestion.options.map(
      (q, i) => new PulseQuestionOptionVM(this.rootStore, q, i, this)
    )
  }

  @computed
  public get followUpQuestions(): PulseQuestionEditVM[] {
    return this.options.filter((o) => o.hasFollowUp).map((o) => o.followUpQuestionVM)
  }

  @computed
  public get pulseSets(): PulseSetVM[] {
    return this.rootStore.pulseSetsStore.pulseSets.map(
      (s) => new PulseSetVM(this.rootStore, this, s.clone())
    )
  }

  @computed
  public get pulseSetEditVM(): PulseSetEditVM {
    return this.rootStore.pulseSetsStore.viewModels.editVM
  }

  @action
  public moveOption(index, newIndex) {
    this.pulseQuestion.moveOption(index, newIndex)
    this.setOptionRanks()
  }

  @action
  public addOption() {
    this.pulseQuestion.addOption()
    this.setOptionRanks()
  }

  @action
  public setOptionRanks() {
    this.options.forEach((opt, idx) => {
      opt.setRank(idx + 1)
    })
  }

  @action
  public deleteOption(idx: number) {
    if (this.pulseQuestion.options.length === 1) return this.toggleDeleteLastOption()
    this.pulseQuestion.deleteOption(idx)
    this.setOptionRanks()
  }

  @action
  public toggleDeleteLastOption() {
    this.cantDeleteLastOption = !this.cantDeleteLastOption
  }

  @action
  public toggleDropdownMultiSelect() {
    this.pulseQuestion.toggleDropdownMultiSelect()
  }

  @action
  public setTabIndex(index: number) {
    this.tabIndex = index
  }

  @action
  public setMediaTabIndex(index: number) {
    this.mediaTabIndex = index
  }

  @action
  public setRatingDisplay(val) {
    this.pulseQuestion.setRatingDisplay(val)
  }

  @action
  public toggleRequred() {
    this.pulseQuestion.toggleIsRequired()
  }

  @action
  public toggleShowMediaFirst() {
    this.pulseQuestion.toggleShowMediaFirst()
  }

  @action
  public toggleLinked() {
    this.pulseQuestion.toggleIsLinked()
  }

  @action
  public setTitle(title: string) {
    this.pulseQuestion.setTitle(title)
  }

  @action
  public setPlaceholder(placeholder: string) {
    this.pulseQuestion.setPlaceholder(placeholder)
  }

  @action
  public setYouTubeURL(url: string) {
    this.pulseQuestion.setYouTubeURL(url)
  }

  @action
  public setVimeoURL(url: string) {
    this.pulseQuestion.setVimeoURL(url)
  }

  @action
  public closeEditModal() {
    if (!this.saveTried && this.isCampaignEdit) this.reset()
    this.rootStore.pulseQuestionsStore.loadPulseQuestionEditVM('empty')
    if (!this.isCampaignEdit) this.rootStore.appStore.router.push('systemAdmin/manage/pulseCategories')
  }

  @action
  public openYouTubePreview() {
    this.rootStore.pulseQuestionsStore.loadYouTubePreviewVM(this.pulseQuestion)
  }

  @action
  public openVimeoPreview() {
    this.rootStore.pulseQuestionsStore.loadVimeoPreviewVM(this.pulseQuestion)
  }

  @action
  public loadYouTubePreviewVM() {
    this.youTubePreviewVM = new YouTubePreviewVM(this.rootStore, this.pulseQuestion)
  }

  @action
  public loadVimeoPreviewVM() {
    this.vimeoPreviewVM = new VimeoPreviewVM(this.rootStore, this.pulseQuestion)
  }

  @action
  public clearYouTubePreviewVM() {
    this.youTubePreviewVM = null
  }

  @action
  public clearVimeoPreviewVM() {
    this.vimeoPreviewVM = null
  }

  @action
  public async save() {
    this.saveTried = true
    if (!this.isValid) return
    const svc = new PulseQuestionsService(this.rootStore)
    const pulseQuestion = this.pulseQuestion.serialize()
    delete pulseQuestion.id
    await svc.savePulseQuestion(pulseQuestion)
    this.closeEditModal()
  }

  @action
  public async delete() {
    const svc = new PulseQuestionsService(this.rootStore)
    await svc.deletePulseQuestion(this.objectId)
    this.toggleConfirmDelete()
    this.closeEditModal()
  }

  @action
  public async apply() {
    this.saveTried = true
    if (!this.isValid) return
    this.rootStore.pulseCampaignsStore.viewModels.editVM.updateQuestionForCurrentSurvey(
      this.pulseQuestion as PulseQuestion
    )
    this.origPulseQuestion = this.pulseQuestion.clone()
    this.closeEditModal()
  }

  @action
  public async reset() {
    this.pulseQuestion = this.origPulseQuestion.clone()
  }

  @action
  public toggleConfirmDelete() {
    this.showConfirmDelete = !this.showConfirmDelete
  }

  @action
  public addSet() {
    this.rootStore.pulseSetsStore.viewModels.loadPulseSetEditVM('new')
  }

  @action
  public closeAddSet() {
    this.rootStore.pulseSetsStore.viewModels.loadPulseSetEditVM('empty')
  }

  @action
  public toggleIsExpanded() {
    this.isExpanded = !this.isExpanded
  }

  public get parentCategoryValid(): boolean {
    if (!this.saveTried) return true
    if (
      !this.parentCategorySelectVM.selectedCategoryId ||
      this.parentCategorySelectVM.selectedCategoryId === ''
    )
      return false
    return true
  }

  public get childCategoryValid(): boolean {
    if (!this.saveTried) return true
    if (
      !this.childCategorySelectVM.selectedCategoryId ||
      this.childCategorySelectVM.selectedCategoryId === ''
    )
      return false
    return true
  }

  public setGrandCategoryId(categoryId) {
    if (this.grandCategoryId === categoryId) return
    this.grandCategoryId = categoryId
    this.grandCategorySelectVM.setSelectedCategoryId(categoryId)
    this.parentCategorySelectVM.setSelectedCategoryId('')
    this.parentCategorySelectVM.setGrandCategoryId(this.grandCategoryId)
    this.parentCategorySelectVM.refreshStore()
    this.childCategorySelectVM.setSelectedCategoryId('')
    this.childCategorySelectVM.setGrandCategoryId(this.grandCategoryId)
    this.childCategorySelectVM.refreshStore()
  }

  public setParentCategoryId(categoryId) {
    if (this.parentCategoryId === categoryId) return
    this.parentCategoryId = categoryId
    this.parentCategorySelectVM.setSelectedCategoryId(categoryId)
    this.childCategorySelectVM.setSelectedCategoryId('')
    this.childCategorySelectVM.setParentCategoryId(categoryId)
    this.childCategorySelectVM.refreshStore()
  }

  public setChildCategoryId(categoryId) {
    if (this.pulseQuestion.pulseCategoryId === categoryId) return
    this.pulseQuestion.setPulseCategoryId(categoryId)
    this.childCategorySelectVM.setSelectedCategoryId(categoryId)
  }

  public changeQuestionType(val) {
    this.pulseQuestion.setQuestionType(val)
    this.pulseQuestion.setDefaultOptions(val)
  }

  public updateQuestionByType(type: PulseQuestionType) {
    this.changeQuestionType(type)
  }

  @action
  public showMediaPicker() {
    this.rootStore.pulseQuestionsStore.showMediaPicker(this.pulseQuestion, (media) =>
      this.addMedia(media)
    )
  }

  @action
  public addMedia(mediaObject: MediaItem) {
    let frm: MediaItemVM
    if (isNumeric(mediaObject.objectId) || mediaObject.cmsItemId) {
      frm = new CMSMediaItemVM(this.rootStore, Media.create(mediaObject))
    } else { 
      const media = new Media()
      media.cmsItemId = mediaObject.cmsItemId
      media.type = mediaObject.type
      media.name = mediaObject.name
      media.path = mediaObject.fileUrl
      media.objectId = mediaObject.objectId
      frm = new OldMediaItemVM(this.rootStore, media)
    }

    if (frm.isCMSItem) (frm as CMSMediaItemVM).loadCMSItem()

    const url = mediaObject.fileUrl.toLowerCase()
    if (url.includes('vimeo')) {
      this.pulseQuestion.vimeoURL = mediaObject.fileUrl
    } else if (url.includes('youtube') || url.includes('youtu.be')) {
      this.pulseQuestion.youTubeURL = mediaObject.fileUrl
    } else {
      this.pulseQuestion.youTubeURL = ''
      this.pulseQuestion.vimeoURL = ''
    }

    this.pulseQuestion.addMedia(frm)
  }

  @computed
  public get media(): MediaItemVM {
    if (isNumeric(this.pulseQuestion.media.objectId) || this.pulseQuestion.media.cmsItemId) {
      const vm = new CMSMediaItemVM(this.rootStore, this.pulseQuestion.media)
      vm.loadCMSItem()
      return vm
    } else return new OldMediaItemVM(this.rootStore, this.pulseQuestion.media)

  }
}
