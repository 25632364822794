import React, { Component } from 'react'
import { Tooltip, Typography } from '@material-ui/core'
import ArchivedLabel from '../../shared/ArchivedLabel'
import { SurveyParticipantRowVM } from '../view-models/SurveyParticipantRowVM'

class NameCell extends Component {
  public props: any
  public data: SurveyParticipantRowVM

  constructor(props) {
    super(props)
    this.data = props.data
  }

  renderName() {
    if (!this.data) return null
    return (
      <>
        <Tooltip
          title={
            <div className='user-card'>
              <Typography className='type-email-title-text'>{'Type: '}</Typography>
              <Typography className='type-text'>
                <i>{this.data.userId ? 'User' : 'Non-user'}</i>
              </Typography>
              <Typography className='type-email-title-text'>{'Email: '}</Typography>
              <Typography className='email-text'>
                <i>{this.data.emailAddressDisplay}</i>
              </Typography>
            </div>
          }
          placement='bottom-start'
        >
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Typography className='text' variant='inherit' noWrap>
              <a
                href={
                  this.data.isArchived
                    ? 'javascript:void(0)'
                    : 'mailto:' + this.data.emailAddressDisplay
                }
                target={this.data.isArchived ? '_self' : '_blank'}
              >
                {this.data.userName}
              </a>
            </Typography>
            {this.data.isArchived ? <ArchivedLabel /> : undefined}
          </div>
        </Tooltip>
      </>
    )
  }

  render() {
    return this.renderName()
  }
}

export default NameCell
