import React from 'react'
import { Card } from '@material-ui/core'
import WidgetHeader from './WidgetHeader'
import WidgetContent from './WidgetContent'
import './ManageGroupsAndRolesWidget.scss'
import GroupEditDrawer from '../../groups/views/GroupEditDrawer'
import RoleEditDrawer from '../../roles/views/RoleEditDrawer'
import WeightProfileModal from './weight-profile-modal/WeightProfileModal'

const ManageGroupsAndRolesWidget: React.FC = () => {
  return (
    <>
      <Card id='ManageGroupsAndRolesWidget' elevation={0}>
        <WidgetHeader />
        <WidgetContent />
      </Card>
      <GroupEditDrawer />
      <RoleEditDrawer />
      <WeightProfileModal />
    </>
  )
}
export default ManageGroupsAndRolesWidget
