import { action, computed, reaction } from 'mobx'
import { AGGridRowVM } from '../../shared/ag-grid/AGGridRowVM'
import { RootStore } from '../../stores/RootStore'
import { Question } from '../aggregates/Question'
import { QuestionCatalogWidgetVM } from './QuestionCatalogWidgetVM'

export class QuestionRowVM extends AGGridRowVM<Question> {
  constructor(rootStore: RootStore, widgetVM: QuestionCatalogWidgetVM, question: Question) {
    super(rootStore, question, widgetVM)
    reaction(
      () => question.title,
      () => this.updateRow()
    )
  }

  @computed
  public get objectId() {
    return this.aggregate.objectId
  }

  @computed
  public get title(): string {
    if (!this.aggregate.title) return '-'

    if (this.questionType === 'dropdown') {
      if (this.aggregate.isDropdownMultiSelect)
        return `${this.aggregate.title} (multiple)`
      else 
      return `${this.aggregate.title} (single)`
    }
    return this.aggregate.title
  }

  @computed
  public get questionType(): string {
    return this.aggregate.type
  }

  @computed
  public get categoryLabel() {
    if (!this.rootStore.categoriesStore) return '-'
    if (!this.aggregate.categoryId) return '-'
    const foundCat = this.rootStore.categoriesStore.getCategory(this.aggregate.categoryId)
    if (!foundCat) return '-'
    return foundCat.label
  }

  @computed
  public get surveyTypeLabel() {
    if (!this.rootStore.surveyTypesStore) return '-'
    if (!this.aggregate.surveyTypeId) return '-'
    const foundSurveyType = this.rootStore.surveyTypesStore.getSurveyType(
      this.aggregate.surveyTypeId
    )
    if (!foundSurveyType) return '-'
    return foundSurveyType.name
  }

  public click() {
    this.rootStore.questionsStore.loadEditVM(this.objectId)
  }
}
