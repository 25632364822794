import { action, computed, observable, when } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { PulseSetRowVM } from './PulseSetRowVM'

export class PulseSetsManageVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @computed
  public get pulseSets(): PulseSetRowVM[] {
    return this.rootStore.pulseSetsStore.pulseSets.map((p) => new PulseSetRowVM(this.rootStore, p))
  }

  @action
  public createNewPulseSet() {
    this.rootStore.pulseSetsStore.viewModels.loadPulseSetEditVM('new')
  }

  @action
  public createPulseTemplate() {
    this.rootStore.appStore.router.push('/pulse/v1/fromtemplate/edit/new')
  }

  @computed
  public get isLoaded(): boolean {
   return this.rootStore.pulseSetsStore.isLoaded
  }
}
