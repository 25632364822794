import React from 'react'
import { inject, observer } from 'mobx-react'
import { InputLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import { QuestionsStore } from '../store/QuestionsStore'
import './RatingDisplay.scss'

interface Props {
  localizationStore?: LocalizationStore
  questionsStore?: QuestionsStore
}

const RatingDisplay: React.FC<Props> = ({
  localizationStore,
  questionsStore,
}) => {
  const { surveyEditQuestionForm: strings } = localizationStore.lzStrings
  const { editVM: vm } = questionsStore
  if (!vm) return null


  const renderRatingDisplay = () => {
    if (vm.questionType !== 'rating') return null

    return (
      <div>
        <InputLabel>{strings.rating}</InputLabel>
        <RadioGroup
          value={vm.ratingDisplay}
          onChange={(e) => vm.setRatingDisplay(e.target.value)}
        >
          <FormControlLabel
            value='horizontal'
            control={<Radio />}
            label='Horizontal'
          />
          <FormControlLabel
            value='vertical'
            control={<Radio />}
            label='Vertical'
          />
        </RadioGroup>
      </div>
    )
  }

  return (
    <div id={'RatingDisplay'}>
      {renderRatingDisplay()}
    </div>
  )
}

export default inject('questionsStore', 'localizationStore')(observer(RatingDisplay))
