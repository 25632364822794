import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { ContactsStore } from '../../store/ContactsStore'
import MuiDrawerToolbar from '../../../shared/Mui-Drawer/MuiDrawerToolbar'

interface DrawerButtonControlsProps {
  localizationStore?: LocalizationStore
  contactsStore?: ContactsStore
}

const DrawerButtonControls: React.FC<DrawerButtonControlsProps> = ({
  localizationStore,
  contactsStore,
}) => {
  const { lzStrings } = localizationStore
  const { contactEditVM: vm } = contactsStore

  if (!vm) return null
  return (
    <div>
      <MuiDrawerToolbar
        disabled={vm.error}
        handleSubmitClick={(e) => vm.saveContact()}
        handleCancelClick={() => contactsStore.toggleWidgetDrawer()}
        showReset={Boolean(vm.objectId)}
        onConfirmReset={() => contactsStore.reloadEditVM(vm.contact)}
        moreMenuProps={{
          isEditMode: vm.isEditMode,
          itemName: vm.name,
          itemType: lzStrings.moreMenu.obj_type.contact,
          onConfirmDelete: () => vm.deleteContact(),
        }}
      />
    </div>
  )
}

export default inject('localizationStore', 'contactsStore')(observer(DrawerButtonControls))
