import { Checkbox, FormControlLabel, Grid, InputLabel, TextField } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import React from 'react'
import StepIndicators from '../../shared/StepIndicators'
import { QuestionsStore } from '../store/QuestionsStore'
import './DrawerNumberInputs.scss'

interface Props {
  questionsStore?: QuestionsStore
}

const DrawerNumberInputs: React.FC<Props> = ({ questionsStore }) => {
  const { editVM: editVM } = questionsStore
  if (editVM.questionType !== 'number') return null

  const renderMinInput = () => {
    if (!editVM.minimumRequired) return null
    return (
      <Grid item xs={6}>
        <InputLabel>Minimum Value</InputLabel>
        <TextField
          error={!editVM.minMaxValid}
          value={editVM.minimumValueAllowed}
          onFocus={(event) => {
            event.target.select()
          }}
          onChange={(e) => {
            editVM.setMinimumValueAllowed(e.target.value)
          }}
          InputProps={{
            endAdornment: (
              <StepIndicators
                onIncreaseStep={() => editVM.increaseMinimumValueAllowed()}
                onDecreaseStep={() => editVM.decreaseMinimumValueAllowed()}
              />
            ),
          }}
          margin='none'
          fullWidth
          variant='outlined'
          placeholder={'Minimum value allowed...'}
        />
      </Grid>
    )
  }

  const renderDefaultValueInput = () => {
    if (!editVM.defaultValueRequired) return null
    return (
      <Grid item xs={6}>
        <InputLabel>Default Value</InputLabel>
        <TextField
          onFocus={(event) => {
            event.target.select()
          }}
          value={editVM.defaultValueNumeric}
          onChange={(e) => {
            editVM.setDefaultValueNumeric(e.target.value)
          }}
          InputProps={{
            endAdornment: (
              <StepIndicators
                onIncreaseStep={() => editVM.increaseDefaultValueNumeric()}
                onDecreaseStep={() => editVM.decreaseDefaultValueNumeric()}
              />
            ),
          }}
          margin='none'
          fullWidth
          variant='outlined'
          placeholder={'Default Value...'}
        />
      </Grid>
    )
  }

  const renderMaxInput = () => {
    if (!editVM.maximumRequired) return null

    return (
      <Grid item xs={6}>
        <InputLabel>Maximum Value</InputLabel>
        <TextField
          error={!editVM.minMaxValid}
          onFocus={(event) => {
            event.target.select()
          }}
          value={editVM.maximumValueAllowed}
          onChange={(e) => {
            editVM.setMaximumValueAllowed(e.target.value)
          }}
          InputProps={{
            endAdornment: (
              <StepIndicators
                onIncreaseStep={() => editVM.increaseMaximumValueAllowed()}
                onDecreaseStep={() => editVM.decreaseMaximumValueAllowed()}
              />
            ),
          }}
          margin='none'
          fullWidth
          variant='outlined'
          placeholder={'Maximum value allowed...'}
        />
      </Grid>
    )
  }

  const renderStepInput = () => {
    if (!editVM.numberStepRequired) return null
    return (
      <Grid item xs={6}>
        <InputLabel>Step</InputLabel>
        <TextField
          onFocus={(event) => {
            event.target.select()
          }}
          value={editVM.numberStep}
          onChange={(e) => {
            editVM.setNumberStep(e.target.value)
          }}
          InputProps={{
            endAdornment: (
              <StepIndicators
                onIncreaseStep={() => editVM.increaseNumberStep()}
                onDecreaseStep={() => editVM.decreaseNumberStep()}
              />
            ),
          }}
          margin='none'
          fullWidth
          variant='outlined'
          placeholder={'Number Step...'}
        />
      </Grid>
    )
  }

  return (
    <Grid container spacing={2} id='numberInputs'>
      <Grid item xs={4}>
        <InputLabel>Decimals</InputLabel>
        <TextField
          InputProps={{
            endAdornment: (
              <StepIndicators
                onIncreaseStep={() => editVM.increaseDecimalsAllowed()}
                onDecreaseStep={() => editVM.decreaseDecimalsAllowed()}
              />
            ),
          }}
          error={editVM.decimalError}
          value={editVM.decimalsAllowed}
          onChange={(e) => {
            editVM.setDecimalsAllowed(parseFloat(e.target.value))
          }}
          margin='none'
          fullWidth
          variant='outlined'
          placeholder={'Number of decimal allowed...'}
        />
      </Grid>
      <Grid item xs={8}>
        <InputLabel>Options</InputLabel>

        <FormControlLabel
          control={
            <Checkbox
              checked={editVM.minimumRequired}
              onChange={() => editVM.toggleMinimumRequired()}
            />
          }
          label={'Min'}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={editVM.maximumRequired}
              onChange={() => editVM.toggleMaximumRequired()}
            />
          }
          label={'Max'}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={editVM.numberStepRequired}
              onChange={() => editVM.toggleNumberStepRequired()}
            />
          }
          label={'Step'}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={editVM.defaultValueRequired}
              onChange={() => editVM.toggleDefaultValueRequired()}
            />
          }
          label={'Default Value'}
        />
      </Grid>
      <Grid container className='numeric-inputs' spacing={1}>
        {renderMinInput()}
        {renderMaxInput()}
        {renderDefaultValueInput()}
        {renderStepInput()}
      </Grid>
    </Grid>
  )
}

export default inject('questionsStore')(observer(DrawerNumberInputs))
