import React from 'react'
import { Route, Redirect } from 'react-router'
import { authenticate } from '../auth/routes/AuthenticatedRoute'
import Layout from '../layout/Layout'
import UserDashboardWrapper from '../dashboardsV2/user-dashboards/views/UserDashboard/UserDashboardWrapper'
import TasksDashboard from '../dashboards/TasksDashboard/TasksDashboard'
import SystemAdminDashboard from '../dashboards/SystemAdminDashboard/SystemAdminDashboard'
import DashboardEditScreen from './views/DashboardEditScreen'
import OrgEmailTemplatesManageWrapper from '../email-templates/views/OrgEmailTemplatesManageWrapper'
import OrganizationUsersListWrapper from '../organization-users/views/list/OrganizationUsersListWrapper'
import AnnouncementAnalyticsWrapper from '../announcements/views/analytics/AnnouncementAnalyticsWrapper'
import OrganizationUsersImportWrapper from '../organization-users/views/importV2/OrganizationUsersImportWrapper'
import AnnouncementListAllWrapper from '../announcements/views/list/AnnouncementListAllWrapper'
import AnnouncementListForUserWrapper from '../announcements/views/list/AnnouncementListForUserWrapper'
import OrgSurveyTemplatesManageWrapper from '../survey-templates/views/manageV2/tenant/OrgSurveyTemplatesManageWrapper'
import SurveyTypesTAManageWrapper from '../survey-types/views/tenant/SurveyTypesTAManageWrapper'

export default function getDashboardRoutes() {
  return (
    <Route key='dashboard' component={authenticate(Layout)} path='/'>
      <Route key='userDashboard' path='dashboard/userDashboard' component={UserDashboardWrapper} />

      <Route
        key='announcementAnalytics'
        path='announcement/analytics/:id'
        component={AnnouncementAnalyticsWrapper}
      />
      <Route
        key='announcementListLoggedInUser'
        path='announcement/list/forloggedinuser/:id'
        component={AnnouncementListForUserWrapper}
      />
      <Route
        key='announcementListAll'
        path='announcement/list/all'
        component={AnnouncementListAllWrapper}
      />
      <Route
        key='userDashboardId'
        path='dashboard/userDashboard/:id'
        component={UserDashboardWrapper}
      />
      <Route
        key='userDashboardEdit'
        path='dashboard/userDashboard/edit/:id'
        component={DashboardEditScreen}
      />
      <Route
        key='dashboardNew'
        path='dashboard/userDashboard/edit/new'
        component={DashboardEditScreen}
      />
      <Route
        key='dashboardClone'
        path='dashboard/userDashboard/clone/:dashboardId'
        component={DashboardEditScreen}
      />

      <Route
        key='worksheetsDashboard'
        path='dashboard/worksheets'
        component={UserDashboardWrapper}
      />
      <Route
        key='worksheetsDashboardId'
        path='dashboard/worksheets/:id'
        component={UserDashboardWrapper}
      />
      <Route
        key='worksheetsDashboardEdit'
        path='dashboard/worksheets/edit/:id'
        component={DashboardEditScreen}
      />

      <Route key='tasksDashboard' path='dashboard/tasks' component={UserDashboardWrapper} />
      <Route key='tasksDashboardId' path='dashboard/tasks/:id' component={UserDashboardWrapper} />
      <Route
        key='tasksDashboardEdit'
        path='dashboard/tasks/edit/:id'
        component={DashboardEditScreen}
      />

      <Route key='surveysDash' path='dashboard/surveys' component={UserDashboardWrapper} />
      <Route key='surveysDashId' path='dashboard/surveys/:id' component={UserDashboardWrapper} />
      <Route
        key='surveysDashEdit'
        path='dashboard/surveys/edit/:id'
        component={DashboardEditScreen}
      />

      <Route key='goalsDash' path='dashboard/impact/goals' component={UserDashboardWrapper} />
      <Route key='goalsDashId' path='dashboard/impact/goals/:id' component={UserDashboardWrapper} />
      <Route
        key='goalsDashEdit'
        path='dashboard/impact/goals/edit/:id'
        component={DashboardEditScreen}
      />

      <Route
        key='trainingsDash'
        path='dashboard/impact/trainings'
        component={UserDashboardWrapper}
      />
      <Route
        key='trainingsDashId'
        path='dashboard/impact/trainings/:id'
        component={UserDashboardWrapper}
      />
      <Route
        key='trainingsDashEdit'
        path='dashboard/impact/trainings/edit/:id'
        component={DashboardEditScreen}
      />

      <Route
        key='impactCatalogDash'
        path='dashboard/impact/catalog'
        component={UserDashboardWrapper}
      />
      <Route
        key='impactCatalogDashId'
        path='dashboard/impact/catalog/:id'
        component={UserDashboardWrapper}
      />
      <Route
        key='impactCatalogDashEdit'
        path='dashboard/impact/catalog/edit/:id'
        component={DashboardEditScreen}
      />

      <Route
        key='tenantAdminOrg'
        path='dashboard/tenantAdmin/org'
        component={UserDashboardWrapper}
      />
      <Route
        key='tenantAdminOrgId'
        path='dashboard/tenantAdmin/org/:id'
        component={UserDashboardWrapper}
      />
      <Route
        key='tenantAdminOrgEdit'
        path='dashboard/tenantAdmin/org/edit/:id'
        component={DashboardEditScreen}
      />

      <Route
        key='tenantAdminConfig'
        path='dashboard/tenantAdmin/config'
        component={UserDashboardWrapper}
      />
      <Route
        key='tenantAdminConfigId'
        path='dashboard/tenantAdmin/config/:id'
        component={UserDashboardWrapper}
      />
      <Route
        key='tenantAdminConfigEdit'
        path='dashboard/tenantAdmin/config/edit/:id'
        component={DashboardEditScreen}
      />

      <Route
        key='tenantAdminSurvey'
        path='dashboard/tenantAdmin/survey'
        component={UserDashboardWrapper}
      />
      <Route
        key='tenantAdminSurveyId'
        path='dashboard/tenantAdmin/survey/:id'
        component={UserDashboardWrapper}
      />
      <Route
        key='tenantAdminSurveyEdit'
        path='dashboard/tenantAdmin/survey/edit/:id'
        component={DashboardEditScreen}
      />

      <Route
        key='tenantAdminOrgUsers'
        path='dashboard/tenantAdmin/users'
        component={OrganizationUsersListWrapper}
      />

      <Route
        key={'tenantAdminEmailsTemplatesManage'}
        path='tenantAdmin/manage/emailTemplates'
        component={OrgEmailTemplatesManageWrapper}
      />

      <Route
        key={'tenantAdminSurveyTemplatesManage'}
        path='tenantAdmin/manage/surveyTemplates'
        component={OrgSurveyTemplatesManageWrapper}
      />

      <Route
        key={'tenantAdminSurveyTypesManage'}
        path='tenantAdmin/manage/surveyTypes'
        component={SurveyTypesTAManageWrapper}
      />

      <Route
        key='tenantAdminUserImport'
        path='dashboard/tenantAdmin/userImport'
        component={OrganizationUsersImportWrapper}
      />

      <Route key='sysAdminDash' path='dashboard/systemAdmin' component={SystemAdminDashboard} />
      {/* ////////// */}
      <Route
        key='tasksDashboardWithTaskId'
        path='dashboard/tasks/:userTaskId'
        component={TasksDashboard}
      />
      <Route
        key='tasksDashboardWithTaskAndOrgId'
        path='dashboard/tasks/:userTaskId/orgId/:orgId'
        component={TasksDashboard}
      />
      <Redirect from='dashboard/*' to='dashboard/userDashboard' />
    </Route>
  )
}
