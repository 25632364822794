import Parse from 'parse'
import { action, observable, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Organization } from '../aggregate/Organization'
import { OrganizationsService } from '../service/OrganizationsService'
import { MediaItemsService } from '../../media-items/service/MediaItemsService'
import defaultLogo from '../../../assets/img/logos/RippleWorx-BlueWhiteGrey.png'
import { Attachment } from '../../upload/aggregate/Attachment'
import { OrganizationLinksVM } from './OrganizationLinksVM'
import { TenantLink } from '../aggregate/TenantLink'
import { TenantLinkRowVM } from './TenantLinkRowVM'
import { OrganizationWelcomeBannerVM } from './OrganizationWelcomeBannerVM'

export class OrganizationEditVM {
  private rootStore: RootStore
  private cleanOrganization: Organization
  @observable public organization: Organization
  @observable public showLogoUploadModal: boolean = false
  @observable public currentTabIndex: number = 0
  @observable public linksVM: OrganizationLinksVM = null
  @observable public welcomeBannerVM: OrganizationWelcomeBannerVM = null

  constructor(rootStore: RootStore, organization: Organization, isOpen: boolean = false) {
    this.rootStore = rootStore
    this.cleanOrganization = organization
    this.organization = organization
    this.isFormDrawerOpen = isOpen
    this.linksVM = new OrganizationLinksVM(this.rootStore, this)
    this.welcomeBannerVM = new OrganizationWelcomeBannerVM(this.rootStore, this)
  }

  @computed
  public get objectId(): string {
    return this.organization.objectId
  }

  @computed
  public get name(): string {
    return this.organization.name
  }

  @action
  public setName(val) {
    this.organization.setName(val)
  }

  @computed
  public get adminEmail(): string {
    return this.organization.adminEmail
  }

  @action
  public setAdminEmail(val) {
    return this.organization.setAdminEmail(val)
  }

  @action
  public setLogo(attachment: Attachment) {
    this.organization.setLogoUrl(attachment.unsignedUrl)
  }

  @action
  public setCurrentTab(idx: number) {
    this.currentTabIndex = idx
  }

  @action
  public addLinkToOrg(link: TenantLink) {
    this.organization.addTenantLink(link)
  }

  @action
  public removeLinkFromOrg(link: TenantLink) {
    this.organization.removeTenantLink(link)
  }

  @computed
  public get logoUrl(): string {
    if (!this.organization.logoUrl) return defaultLogo
    return this.organization.logoUrl
  }

  @observable public isFormDrawerOpen: boolean = false
  @observable public isProcessing: boolean = false

  @computed
  public get isNameValid(): boolean {
    if (Boolean(this.name)) return true
    return false
  }

  @computed
  public get isAdminEmailValid(): boolean {
    const exp =
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    const regex = RegExp(exp)
    return regex.test(this.adminEmail)
  }

  @computed
  public get saveDisabled(): boolean {
    if (!this.isAdminEmailValid) return true
    if (!this.isNameValid) return true
    return false
  }

  @action
  public reset() {
    this.rootStore.organizationsStore.loadEditVM(true)
  }

  @action
  public openDrawer() {
    this.isFormDrawerOpen = true
  }

  @action
  public closeDrawer() {
    this.isFormDrawerOpen = false
  }

  @action
  public async generateSignature(callback: Function, paramsToSign: object): Promise<void> {
    const svc = new MediaItemsService()
    const signature = await svc.getCloudinarySignature(paramsToSign)
    callback(signature)
  }

  @action
  public toggleLogoUploadModal() {
    this.showLogoUploadModal = !this.showLogoUploadModal
  }

  @action
  public revertLogoToDefault() {
    this.organization.setLogoUrl('')
  }

  public async save() {
    if (!this.isAdminEmailValid) return
    this.isProcessing = true
    const svc = new OrganizationsService(this.rootStore)
    await svc.saveOrganization(this.organization.serialize())
    this.isProcessing = false
    this.closeDrawer()
  }
}
