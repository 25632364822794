import { action, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { MediaItem } from '../../media-items/aggregate/MediaItem'
import { ContentItemType } from '../types/ContentItemType'
import { MediaItemAttachmentVM } from './MediaItemAttachmentVM'
import { FileOpenService } from '../../upload/services/FileOpenService'
import { Attachment } from '../../attachments/aggregate/Attachment'

export class OldMediaItemAttachmentVM extends MediaItemAttachmentVM {
  constructor(rootStore: RootStore, contentItemType: ContentItemType, media: MediaItem) {
    super(rootStore, contentItemType, media)
  }

  @computed
  public get isVideo() {
    return this.fileType === 'video'
  }

  @computed
  public get isImage() {
    return this.fileType === 'image'
  }

  @computed
  public get isDocument() {
    return !this.isImage && !this.isVideo
  }

  @computed
  public get type(): ContentItemType {
    return this.contentItemType
  }

  @computed
  public get isCMSItem(): boolean {
    return false
  }

  @computed
  public get thumbnail() {
    if (!this.media) return ''
    if (this.media.type === 'video') return this.media.videoThumbnail
    return this.media.fileUrl
  }

  @computed
  public get url() {
    if (!this.media) return ''
    return this.media.fileUrl
  }

  @computed
  public get fileType(): string {
    if (!this.media) return ''
    return this.media.type
  }

  @computed
  public get fileFormat(): string {
    if (!this.media) return ''
    const url = this.media.fileUrl
    const idx = url.lastIndexOf('.')
    const type = url.substring(idx, url.length)
    return type
  }

  @computed
  public get fileExtension(): string {
    if (!this.media) return ''
    if (!this.media.fileUrl) return ''
    const index = this.media.fileUrl.lastIndexOf('.')
    if (index < 0) return ''
    return this.media.fileUrl.substring(index, this.media.fileUrl.length)
  }

  @computed
  public get fileName(): string {
    if (!this.media) return null
    if (this.media.name.length > 50)
      return this.media.name.substring(0, 50) + '..' + this.fileExtension
    return this.media.name
  }

  @computed
  public get shorterFileName(): string {
    if (!this.media) return null
    if (this.media.name.length > 30)
      return this.media.name.substring(0, 30) + '..' + this.fileExtension
    return this.media.name
  }

  @action
  public async openAttachment() {
    if (!this.media) return null
    const svc = new FileOpenService(this.rootStore)
    await svc.openFile(this.toAttachment())
  }

  @action
  public toAttachment() {
    const attachment = new Attachment()
    if (this.media) {
      attachment.url = this.url
    }
    return attachment
  }

  @computed
  public get hasYouTubeURL(): boolean {
    const url = this.url.toLowerCase()
    return url.includes('youtube') || url.includes('youtu.be')
  }

  @computed
  public get hasVimeoURL(): boolean {
    const url = this.url.toLowerCase()
    return url.includes('vimeo')
  }

  @computed
  public get youTubeId(): string {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
    const match = this.url.match(regExp)
    return match && match[2].length === 11 ? match[2] : null
  }

  @computed
  public get vimeoId(): string {
    let regExp =
      /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/
    if (this.url.includes('player.vimeo.com')) {
      regExp =
        /(http|https)?:\/\/(www\.)?player.vimeo.com\/(?:video\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/
    }
    const match = this.url.match(regExp)
    return match && match.length ? match[4] : ''
  }

  @computed
  public get embedUrl(): string {
    if (!this.hasYouTubeURL && !this.hasVimeoURL) return ''
    if (this.hasYouTubeURL) return `https://www.youtube.com/embed/${this.youTubeId}`
    else if (this.hasVimeoURL) return `https://player.vimeo.com/video/${this.vimeoId}`
    return ''
  }

  @computed
  public get hasEmbedUrl(): boolean {
    return Boolean(this.embedUrl)
  }
}
