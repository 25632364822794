import {
  FormControlLabel,
  Grid,
  OutlinedInput,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import React from 'react'
import ParticipantsSelect from '../../../../participants-select/views/ParticipantsSelect'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { TrainingPlansStore } from '../../../store/TrainingPlansStore'
import './ParticipantsTabPanel.scss'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { TabEnums } from '../../../types/TabsEnums'
import { ParticipantDueDateVM } from '../../../view-models/ParticipantDueDateVM'
import Avatar from '../../../../shared/Avatar'
import { TrainingPlanItemVM } from '../../../view-models/TrainingPlanItemVM'
import InfoIcon from '@material-ui/icons/Info'
import TimePicker from 'src/app/shared/time-picker/TimePicker'
import InfoTooltip from 'src/app/shared/InfoTooltip'

interface Props {
  trainingPlansStore?: TrainingPlansStore
  localizationStore?: LocalizationStore
}

const ParticipantsTabPanel: React.FC<Props> = ({ trainingPlansStore, localizationStore }) => {
  const { training_plans: strings } = localizationStore.lzStrings
  const { trainingPlanEditVM: vm } = trainingPlansStore
  // if (vm.currentTab !== TabEnums.PARTICIPANTS) return null

  const renderSameDueDateForAll = () => {
    if (vm.dueDateType !== 'sameForAll') return null
    return (
      <div className='active-date-and-time-picker-div'>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            className='date-input'
            disablePast={true}
            inputVariant='outlined'
            format='MM/dd/yyyy'
            minDate={vm.activeDate}
            minDateMessage='Due date cannot be before active date.'
            margin='none'
            autoOk
            helperText={null}
            value={vm.dueDate}
            disabled={vm.editIsDisabled}
            onChange={(e) => vm.setDueDate(e)}
          />
        </MuiPickersUtilsProvider>
        <TimePicker
          value={vm.dueDate}
          onChange={(hr, min) => vm.setDueTime(hr, min)}
          // error={!vm.dueDate}
          disabled={vm.editIsDisabled}
          textFieldClassName='active-date-time-picker'
          error={!vm.dueDateValid}
          // onChange={(e) => vm.setDueDate(e)}
        />
      </div>
    )
  }

  const participantRow = (participantVM: ParticipantDueDateVM, index) => {
    if (!participantVM.participant) return null
    return (
      <Grid item xs={12} key={index} className='participant-due-date'>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disabled={vm.editIsDisabled}
            className='date-input'
            disablePast={true}
            inputVariant='outlined'
            format='MM/dd/yyyy'
            margin='none'
            minDate={vm.activeDate}
            minDateMessage='Due date cannot be before active date.'
            autoOk
            helperText={null}
            value={participantVM.dueDate}
            error={!participantVM.dueDateValid}
            onChange={(e) => participantVM.setDueDate(e)}
          />
        </MuiPickersUtilsProvider>
        <TimePicker
          value={participantVM.dueDate}
          onChange={(hr, min) => participantVM.setDueTime(hr, min)}
          error={!participantVM.dueDate}
          disabled={vm.editIsDisabled}
          textFieldClassName='active-date-time-picker'
        />
        <span className='participant-avatar'>
          <Avatar size={30} user={participantVM.participant} style={{ fontSize: '1rem' }} />
        </span>
        <Typography variant='subtitle2'>{participantVM.participant.name}</Typography>
      </Grid>
    )
  }

  const trainingPlanItemRow = (trainingPlanItem: TrainingPlanItemVM, index) => {
    return (
      <Grid item xs={12} key={index} className='participant-due-date'>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disabled={vm.editIsDisabled}
            className='date-input'
            disablePast={true}
            inputVariant='outlined'
            format='MM/dd/yyyy'
            margin='none'
            autoOk
            helperText={null}
            value={trainingPlanItem.dueDate}
            onChange={(e) => trainingPlanItem.setDueDate(e)}
          />
        </MuiPickersUtilsProvider>
        <span className='training-item-number'>{index + 1}</span>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant='h6'>{trainingPlanItem.name}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body2'>{trainingPlanItem.subtitle}</Typography>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderVariesByParticipant = () => {
    if (vm.dueDateType !== 'variesByParticipant' || !vm.participantDueDateVMs) return null
    return (
      <Grid container>
        {vm.participantDueDateVMs.map((vm, index) => {
          return participantRow(vm, index)
        })}
      </Grid>
    )
  }

  const renderVariesByTrainingItem = () => {
    if (vm.dueDateType !== 'variesByTrainingItem') return null
    return (
      <Grid container>
        {vm.trainingPlanItemVMs.map((vm, index) => {
          return trainingPlanItemRow(vm, index)
        })}
      </Grid>
    )
  }

  const renderDueDateSection = () => {
    return (
      <Grid container>
        {/* <Grid item xs={12}>
          <Typography variant='h5' className='input-label'>
            {strings.recurring_training_plan} <InfoIcon className='info-icon' />
          </Typography>
          <Switch
            disabled={vm.planDisabled}
            onChange={() => vm.toggleIsRecurring()}
            checked={vm.isRecurring}
          />
        </Grid> */}
        <Grid item xs={12}>
          <Typography variant='h5' className='input-label'>
            {strings.active_date} <InfoTooltip message={strings.active_date_tooltip} />
          </Typography>
          <div className='active-date-and-time-picker-div'>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disabled={vm.editIsDisabled}
                className='date-input'
                // disablePast={true}
                inputVariant='outlined'
                format='MM/dd/yyyy'
                margin='none'
                autoOk
                helperText={null}
                value={vm.activeDate}
                onChange={(e) => vm.setActiveDate(e)}
              />
            </MuiPickersUtilsProvider>
            <TimePicker
              value={vm.activeDate}
              onChange={(hr, min) => vm.setActiveTime(hr, min)}
              error={!vm.activeDate}
              disabled={vm.editIsDisabled}
              textFieldClassName='active-date-time-picker'
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h5' className='input-label due-date'>
            {strings.due_date} <InfoTooltip message={strings.due_date_tooltip} />
          </Typography>
          <RadioGroup
            className='due-date-radios'
            value={vm.dueDateType || ''}
            onChange={(e) => vm.setDueDateType(e.target.value)}
          >
            <FormControlLabel
              value='sameForAll'
              control={<Radio disabled={vm.editIsDisabled} />}
              label='Same due date for all'
            />
            <FormControlLabel
              value='variesByParticipant'
              control={<Radio disabled={vm.editIsDisabled} />}
              label='Varies for each participant'
            />
            {/* <FormControlLabel
              value='variesByTrainingItem'
              control={<Radio disabled={vm.editIsDisabled}
              label='Varies for each training item'
            /> */}
          </RadioGroup>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container id='ParticipantsTabPanel'>
      <Grid item xs={5} className={vm.editIsDisabled ? 'left-panel disabled-panel' : 'left-panel'}>
        <ParticipantsSelect participantsSelectVM={vm.participantsSelectVM} />
      </Grid>
      <Grid item xs={7} className='right-panel'>
        {renderDueDateSection()}
        {renderSameDueDateForAll()}
        {renderVariesByParticipant()}
        {renderVariesByTrainingItem()}
      </Grid>
    </Grid>
  )
}

export default inject('localizationStore', 'trainingPlansStore')(observer(ParticipantsTabPanel))
