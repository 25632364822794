import React from 'react'
import { Card } from '@material-ui/core'
// import { WidgetContainerVM } from '../../../dashboards/store/view-models/WidgetContainerVM'
import { WorksheetsStore } from '../../store/WorksheetsStore'
import { observer, inject } from 'mobx-react'
import { ChartWidgetVM } from '../../view-models/chart-widget/ChartWidgetVM'
import './VizWidget.scss'
import Header from './Header'
import Content from './Content'
import { WidgetContainerVM } from '../../../dashboardsV2/view-models/WidgetContainerVM'

interface Props {
  widgetInfo: WidgetContainerVM
  worksheetsStore?: WorksheetsStore
}

const ChartWidget: React.FC<Props> = ({ widgetInfo, worksheetsStore }) => {
  const widgetVM: ChartWidgetVM = worksheetsStore.getWorksheetWidgetVM(widgetInfo)
  
  return (
    <Card id='ChartWidget' elevation={0}>
      <Header widgetVM={widgetVM} />
      <Content widgetVM={widgetVM} />
    </Card>
  )
}

export default inject('worksheetsStore')(observer(ChartWidget))
