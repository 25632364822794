import { computed, action, observable } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { SurveysStore } from '../../store/SurveysStore'
import { FilterPopupVM } from './FilterPopupVM'
import { SurveysService } from '../../service/SurveysService'
import { ISurveyDTO } from '../../dtos/ISurveyDTO'
import { serialize } from 'serializr'
import { ISurveysFindRequest } from '../../interfaces/ISurveysFindRequest'
import { SurveysTabVM } from './SurveysTabVM'
import { LabelsStore } from '../../../labels/store/LabelsStore'

export class ManageSurveysWidgetVM {
  private rootStore: RootStore
  public svc: SurveysService

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.tabIndex = 0
    this.filterPopupVM = new FilterPopupVM(rootStore, this)
    this.svc = new SurveysService(this.rootStore)
    this.loadSurveyTabs()
    this.onFilterChanged()
    this.lz = this.rootStore.localizationStore.lzStrings.surveys
    this.labelsStore = this.rootStore.labelsStore
  }

  @observable public tabIndex: number = 0
  @observable public filterPopupVM: FilterPopupVM = null
  @observable public filterPopupAnchorEl = null
  @observable public closeSurveyDialogOpen: boolean = false
  @observable public sendRemindersDialogOpen: boolean = false
  @observable public selectedSurveyId: string = null
  @observable public isSnackbarOpen: boolean
  @observable public snackbarMessage: string = ''
  @observable public openSurveysTab: SurveysTabVM
  @observable public closedSurveysTab: SurveysTabVM
  @observable public draftSurveysTab: SurveysTabVM
  @observable public lz = null
  @observable public labelsStore: LabelsStore = null

  @action
  public onFilterChanged() {
    const filters = {
      dateStart: undefined,
      dateEnd: undefined,
      groups: undefined,
      roles: undefined,
      owners: undefined,
      types: undefined,
      participants: undefined,
      categories: undefined,
    } as ISurveysFindRequest
    if (this.filterPopupVM.startDate) {
      filters.dateStart = this.filterPopupVM.startDate.getTime()
    }
    if (this.filterPopupVM.endDate) {
      filters.dateEnd = this.filterPopupVM.endDate.getTime()
    }
    if (this.filterPopupVM.filterSurveyOwnerIds.length > 0) {
      filters.owners = this.filterPopupVM.filterSurveyOwnerIds.slice()
    }
    if (this.filterPopupVM.filterSurveyTypeIds.length > 0) {
      filters.types = this.filterPopupVM.filterSurveyTypeIds.slice()
    }
    if (this.filterPopupVM.filterGroupIds.length > 0) {
      filters.groups = this.filterPopupVM.filterGroupIds.slice()
    }
    if (this.filterPopupVM.filterRoleIds.length > 0) {
      filters.roles = this.filterPopupVM.filterRoleIds.slice()
    }
    if (this.filterPopupVM.filterUserIds.length > 0) {
      filters.participants = this.filterPopupVM.filterUserIds.slice()
    }
    if (this.filterPopupVM.filterCategoryIds.length > 0) {
      filters.categories = this.filterPopupVM.filterCategoryIds.slice()
    }
    this.openSurveysTab.dataStore.setRequest({
      ...this.openSurveysTab.dataStore.getRequest(),
      ...filters,
    })
    this.closedSurveysTab.dataStore.setRequest({
      ...this.closedSurveysTab.dataStore.getRequest(),
      ...filters,
    })
    this.draftSurveysTab.dataStore.setRequest({
      ...this.draftSurveysTab.dataStore.getRequest(),
      ...filters,
    })

    this.applySelectedTabFilter()
  }

  private applySelectedTabFilter() {
    if (this.tabIndex === 0 && this.openSurveysTab.shownOnce) this.openSurveysTab.applyFilter()
    if (this.tabIndex === 1 && this.closedSurveysTab.shownOnce) this.closedSurveysTab.applyFilter()
    if (this.tabIndex === 2 && this.draftSurveysTab.shownOnce) this.draftSurveysTab.applyFilter()
  }

  @action
  private loadSurveyTabs() {
    this.openSurveysTab = new SurveysTabVM(
      this.rootStore,
      this,
      {
        isClosed: false,
        published: true,
      } as ISurveysFindRequest,
      0
    )
    this.closedSurveysTab = new SurveysTabVM(
      this.rootStore,
      this,
      {
        isClosed: true,
        published: true,
      } as ISurveysFindRequest,
      1
    )
    this.draftSurveysTab = new SurveysTabVM(
      this.rootStore,
      this,
      {
        published: false,
      } as ISurveysFindRequest,
      2
    )
  }

  @observable
  public setFilterPopupAnchorEl(el) {
    this.rootStore.audienceMembersStore.loadAudienceMembers()
    this.filterPopupAnchorEl = el
  }

  @action
  public setTabIndex(index: number) {
    this.tabIndex = index
    this.applySelectedTabFilter()
  }

  @computed
  private get surveysStore(): SurveysStore {
    return this.rootStore.surveysStore
  }

  @computed
  public get hasSurveyPrivilege(): boolean {
    return this.rootStore.appStore.canSurvey
  }

  @computed
  public get hasFilter() {
    return this.filterPopupVM.hasFilter
  }

  @action
  public openSnackbar(msg) {
    this.snackbarMessage = msg
    this.isSnackbarOpen = true
  }

  @action
  public closeSnackbar() {
    this.isSnackbarOpen = false
  }

  @action
  public toggleSendRemindersDialog() {
    this.sendRemindersDialogOpen = !this.sendRemindersDialogOpen
  }

  @action
  public setSelectedSurveyRow(objectId: string) {
    this.selectedSurveyId = objectId
  }

  @action
  public async sendReminders() {
    this.toggleSendRemindersDialog()
    await this.svc.sendSurveyRemindersToAllOpenUserSurveys(
      this.rootStore.appStore.currentOrgId,
      this.selectedSurveyId
    )
    this.openSnackbar('Reminders Sent')
  }

  @action
  public toggleCloseSurveyDialog() {
    this.closeSurveyDialogOpen = !this.closeSurveyDialogOpen
  }

  @action
  public async closeSurvey() {
    this.toggleCloseSurveyDialog()
    await this.svc.closeSurvey(this.selectedSurveyId, this.rootStore.appStore.currentOrgId)
    this.openSurveysTab = undefined
    this.closedSurveysTab = undefined
    this.draftSurveysTab = undefined
    setTimeout(() => this.loadSurveyTabs(), 250)
    this.openSnackbar(`${this.labelsStore.getLabelByLanguageAndFor('pulse')} ${this.lz.closed}`)
  }

  public get shouldRender() {
    // if (!this.rootStore.audienceMembersStore.loaded) return false
    // if (!this.rootStore.surveysStore.loaded) return false
    return true
  }
}
