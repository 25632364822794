import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { GoalsStore } from 'src/app/goals/store/GoalsStore'
import CloseIcon from '@material-ui/icons/Close'
import ImageIcon from '@material-ui/icons/Image'
import './PreviewPlan.scss'
import { TrainingPlanItem } from '../../../../training-plans/aggregates/TrainingPlanItem'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import LinkIcon from '@material-ui/icons/Link'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import PermMediaIcon from '@material-ui/icons/PermMedia'
import ExerciseIcon from '@material-ui/icons/FitnessCenterRounded'

interface Props {
  goalsStore?: GoalsStore
}

const PreviewPlan: React.FC<Props> = ({ goalsStore }) => {
  const { planToPreview: vm } = goalsStore.goalEditVM
  if (!vm) return null

  const renderThumbnail = () => {
    if (!vm.thumbnail) {
      return (
        <div className='preview-thumbnail'>
          <ImageIcon />
        </div>
      )
    } else {
      return <img className='preview-thumbnail' src={vm.thumbnail} />
    }
  }

  const renderPlanItem = (item: TrainingPlanItem, index) => {
    const renderDropDown = () => {
      if (item.id === vm.expandedPreviewItemId) return <KeyboardArrowDownIcon />
      if (item.id !== vm.expandedPreviewItemId) return <KeyboardArrowRightIcon />
    }

    const renderItemSubtitle = () => {
      const lessons = item.contentItems.length > 0 ? ' lessons' : 'lesson'
      const plusQuiz = item.requireQuiz ? '+ Quiz' : ''
      const plusCert = item.requireCertificate ? '+ Certificate' : ''
      return (
        <Typography variant='body1' className='item-subtitle'>
          {item.contentItems.length} {lessons} {plusQuiz} {plusCert}
        </Typography>
      )
    }

    return (
      <Grid item xs={12} key={index}>
        <Accordion
          elevation={0}
          onClick={() => vm.setExpandedPreviewItemId(item.id)}
          expanded={item.id === vm.expandedPreviewItemId}
        >
          <AccordionSummary className='preview-item-container'>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant='h6' className='subtitle'>
                  {renderDropDown()} {item.name} {renderItemSubtitle()}
                </Typography>
              </Grid>
              <Grid item xs={12} className='subtitle'></Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className='content-items-container'>
            <Grid container>
              {item.contentItems.map((contentItem, index) => {
                const renderIcon = () => {
                  if (contentItem.type === 'link') return <LinkIcon />
                  if (contentItem.type.toLowerCase() === 'media') return <PermMediaIcon />
                  if (contentItem.type === 'file') return <AttachFileIcon />
                  if (contentItem.type.toLowerCase() === 'exercise') return <ExerciseIcon />
                }
                return (
                  <Grid item xs={12} key={index}>
                    <Typography>
                      {index + 1}. {contentItem.title}
                    </Typography>
                    <Typography className='type-and-icon'>
                      {renderIcon()}
                      {contentItem.type}
                    </Typography>
                  </Grid>
                )
              })}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    )
  }

  return (
    <div id='PlanPreview'>
      <Grid container id='PreviewHeader'>
        <Grid item xs={12} className='title'>
          <DialogTitle>Preview</DialogTitle>
          <CloseIcon
            className='close-modal-button'
            onClick={() => goalsStore.goalEditVM.closeTrainingPlansModal()}
          />
        </Grid>
        <Grid item xs={12} className='preview-overview'>
          <Grid container>
            <Grid item xs={12}>
              <Button
                variant='contained'
                className='back-button'
                onClick={() => goalsStore.goalEditVM.clearPreviewPlan()}
              >
                Back
              </Button>
            </Grid>
            <Grid item xs={4} className='preview-thumbnail-container'>
              {renderThumbnail()}
            </Grid>
            <Grid item xs={8} className='preview-details'>
              <div>
                <Typography>TRAINING PLAN</Typography>
                <Typography variant='h6'>{vm.title}</Typography>
                <Typography>{vm.description}</Typography>
              </div>
              <div>
                {vm.categories.map((cat, index) => (
                  <div key={index} className='preview-category'>
                    {cat}
                  </div>
                ))}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DialogContent>
        <Grid container id='PreviewBody'>
          <div className='preview-plan-content'>
            {vm.trainingPlanItems.map((item, index) => {
              return renderPlanItem(item, index)
            })}
          </div>
        </Grid>
      </DialogContent>
    </div>
  )
}

export default inject('goalsStore')(observer(PreviewPlan))
