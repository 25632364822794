import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { AppBar, Button, Toolbar } from '@material-ui/core'
import rootStore from '../../../stores/RootStore'
import { PulseCampaignsStore } from '../../store/PulseCampaignsStore'
import LocalizationStore from '../../../localization/LocalizationStore'
import LeftIcon from '@material-ui/icons/ChevronLeft'
import './TopToolbar.scss'
import TableauReport from '../../../worksheets/views/TableauReport'
import { TableauScriptService } from '../../../tableau/service/TableauScriptService'

interface Props {
  pulseCampaignsStore?: PulseCampaignsStore
  localizationStore?: LocalizationStore
}

const CampaignWorksheet: React.FC<Props> = ({
  pulseCampaignsStore,
  localizationStore,
}) => {
  const [scriptLoaded, setScriptLoaded] = useState(false)

  useEffect(() => {
    TableauScriptService.loadTableauScript(() => setScriptLoaded(true))
  })

  const vm = pulseCampaignsStore.viewModels.campaignAnalysisEditVM
  if (!vm) return null

  const renderReport = () => {
    if (!vm.worksheetId) return null
    if (!vm.tableauViewUrl) return null
    if (!scriptLoaded) return null
    return (
      <TableauReport
        key={vm.tableauViewUrl + vm.tableauAuthToken + vm.tableauQuery}
        token={vm.tableauAuthToken}
        onVizLoaded={(div, viz) => vm.setTableauVizContainer(div, viz)}
        query={vm.tableauQuery}
        options={{
          width: '100%',
          height: '80vh',
          device: 'desktop',
          hideTabs: !vm.showTabs,
        }}
        url={vm.tableauViewUrl}
      />
    )
  }

  return (
    <div className={'campaign-worksheet'}>
     {renderReport()}
    </div>
  )
}

export default inject(
  'pulseCampaignsStore',
  'localizationStore'
)(observer(CampaignWorksheet))
