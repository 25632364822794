import React from 'react'
import { inject, observer } from 'mobx-react'
import { TasksStore } from '../../store/TasksStore'
import DrawerParticipantsList from './DrawerParticipantsList'

interface Props {
  tasksStore?: TasksStore
}

const DrawerParticipantsTab: React.FC<Props> = ({ tasksStore }) => {
  const { taskEditVM: vm } = tasksStore
  if (vm.participantsSelectOpen) return null

  return <DrawerParticipantsList />
}

export default inject('tasksStore')(observer(DrawerParticipantsTab))
