import Parse from 'parse'
import { observable, action, computed, runInAction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { deserialize } from 'serializr'
import { CustomCollection } from '../aggregate/CustomCollection'
import { CustomCollectionEditVM } from '../view-models/edit/CustomCollectionEditVM'
import { CustomCollectionsWidgetVM } from '../view-models/list/CustomCollectionsWidgetVM'
import { ICustomCollectionDTO } from '../dto/ICustomCollectionDTO'
import { DataStore } from '../../shared/data/DataStore'

export class CustomCollectionsStore extends DataStore<CustomCollection, ICustomCollectionDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, CustomCollection, 'customCollections', [
      'organizationId',
      'name',
      'pushToDWAsTable',
      'pushToValueFacts',
      'isGoalSource',
      'customRecordsCount',
      'fields',
      'collectionName',
      'watsonIBMModelId',
    ])
  }

  @observable public editVM: CustomCollectionEditVM = null
  @observable public widgetVM: CustomCollectionsWidgetVM = null
  @observable public loaded: boolean = false

  @computed
  public get customCollections(): CustomCollection[] {
    return this.records
  }

  @action
  public onListRecordsLoaded() {
    this.widgetVM = new CustomCollectionsWidgetVM(this.rootStore)
    this.loaded = true
  }

  @action
  public loadEditVM(objectId: string) {
    if (objectId === 'new') {
      this.editVM = new CustomCollectionEditVM(
        this.rootStore,
        CustomCollection.create(this.rootStore.appStore.currentOrgId)
      )
      return
    }
    this.editVM = new CustomCollectionEditVM(this.rootStore, this.getCustomCollection(objectId))
  }

  @action
  public lazyLoadEditVM(id: string, attempts: number = 0) {
    if (attempts === 14) return
    if (!this.rootStore.organizationsStore.currentOrganization) {
      setTimeout(() => this.lazyLoadEditVM(id, attempts++), 500)
      return
    }
    if (id === 'empty') this.editVM = null
    else if (id === 'new')
      this.editVM = new CustomCollectionEditVM(
        this.rootStore,
        CustomCollection.create(this.rootStore.appStore.currentOrgId)
      )
    else {
      const foundItem = this.getCustomCollection(id)
      if (!foundItem) setTimeout(() => this.lazyLoadEditVM(id, attempts++), 500)
      else this.editVM = new CustomCollectionEditVM(this.rootStore, foundItem.clone())
    }
  }

  @computed
  public get currentOrgCustomCollections() {
    let customCollections = this.customCollections
      .filter((e) => e.organizationId === this.rootStore.appStore.currentOrgId)
      .filter((e) => !e.isDeleted)
    return customCollections
  }

  public clearEditVM() {
    this.editVM = null
  }

  public getCustomCollection(objectId): CustomCollection {
    return this.customCollections.find((e) => e.objectId === objectId)
  }
}
