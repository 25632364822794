import React from 'react'
import { inject, observer } from 'mobx-react'
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Button,
  Typography,
  Grid,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import './UserEventPopup.scss'
import { EventsStore } from '../../../store/EventsStore'

interface IUserEventGoingPanel {
  eventsStore?: EventsStore
}

const UserEventGoingPanel: React.FC<IUserEventGoingPanel> = ({ eventsStore }) => {
  const { userEventPopupVM: vm } = eventsStore
  if (!vm) return null
  if (!vm.anchorEl) return null
  if (!vm.goingPanelShown) return null

  return (
    <Grid container className='going-container'>
      <Grid item>
        <Typography className='going'>Going?</Typography>
      </Grid>
      <Grid item>
        <Button
          variant={vm.goingStatus === 'yes' ? 'contained' : 'outlined'}
          className='going-button'
          disabled={vm.isProcessing}
          onClick={() => vm.setGoingStatus('yes')}
        >
          Yes
        </Button>
        <Button
          variant={vm.goingStatus === 'no' ? 'contained' : 'outlined'}
          className='going-button'
          disabled={vm.isProcessing}
          onClick={() => vm.setGoingStatus('no')}
        >
          No
        </Button>
        <Button
          variant={vm.goingStatus === 'maybe' ? 'contained' : 'outlined'}
          className='going-button'
          disabled={vm.isProcessing}
          onClick={() => vm.setGoingStatus('maybe')}
        >
          Maybe
        </Button>
      </Grid>
    </Grid>
  )
}

export default inject('eventsStore')(observer(UserEventGoingPanel))
