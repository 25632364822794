import React, { useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import {
  Button,
  CircularProgress,
  Typography,
  Tooltip,
} from '@material-ui/core'
import UploadIcon from '@material-ui/icons/CloudUploadRounded'
import LocalizationStore from '../../localization/LocalizationStore'
import { CMSItemsPickerVM } from '../view-models/CMSItemsPickerVM'
import emptyResults from '../../../assets/img/empty-state/empty-state-results.png'
import emptySearch from '../../../assets/img/empty-state/empty-state-search.png'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList as List } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import memoize from 'memoize-one'
import ContentCard from './CMSItemsCard'
import '../../css/dashboard-toolbar.scss'
import './CMSItemsLayoutGridBody.scss'

interface Props {
  vm: CMSItemsPickerVM
  localizationStore?: LocalizationStore
}

const CMSItemsLayoutGridBody: React.FC<Props> = ({ vm, localizationStore }) => {
  const { contentManagement: lz } = localizationStore.lzStrings

  if (!vm) return

  const manageVM = vm.manageVM
  if (!manageVM) return

  const options = manageVM.mediaItems

  const renderSpinner = () => {
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  const renderEmptyStateInfo = () => {
    if (manageVM.typedFilterText.trim() === '' && !manageVM.filterTags && !manageVM.filterTypes)
      return renderEmptyStateWithGlobalAccessInfo()

    if (!manageVM.isPrivateAccess)
      return renderEmptyStateWithPrivateAccessInfo()

    return (
      <div id='ContentPickerEmptyText'>
        <Typography className='content-empty-text'>{lz.no_content_search}</Typography>
      </div>
    )
  }

  const renderEmptyStateWithGlobalAccessInfo = () => {
    if (manageVM.canManage)
      return (
        <div id='ContentPickerEmptyText'>
          <Typography className='content-empty-text'>
            Oops, looks like you do not have any private uploads.
          </Typography>
          <Typography className='content-empty-text'>Upload one now or switch back to global tenant access.</Typography>
          <Tooltip title={'Create, modify, and delete content items.'} placement='bottom'>
            <span>
              <Button
                variant='outlined'
                className='content-empty-action-btn'
                onClick={() => manageVM.openUploadDialog()}
                startIcon={<UploadIcon />}
              >
                {lz.upload_content}
              </Button>
            </span>
          </Tooltip>
        </div>
      )

    return (
      <div id='ContentPickerEmptyText'>
        <Typography className='content-empty-text'>{lz.no_content_regular_details}</Typography>
        <Typography className='content-empty-text'>{lz.contact_admin}</Typography>
      </div>
    )
  }

  const renderEmptyStateWithPrivateAccessInfo = () => {
    if (manageVM.canManage)
      return (
        <div id='ContentPickerEmptyText'>
          <Typography className='content-empty-text'>
            Oops, looks like no content items have been uploaded yet.
          </Typography>
          <Typography className='content-empty-text'>Upload one now.</Typography>
          <Tooltip title={'Create, modify, and delete content items.'} placement='bottom'>
            <span>
              <Button
                variant='outlined'
                className='content-empty-action-btn'
                onClick={() => manageVM.openUploadDialog()}
                startIcon={<UploadIcon />}
              >
                {lz.upload_content}
              </Button>
            </span>
          </Tooltip>
        </div>
      )

    return (
      <div id='ContentPickerEmptyText'>
        <Typography className='content-empty-text'>{lz.no_content_regular_details}</Typography>
        <Typography className='content-empty-text'>{lz.contact_admin}</Typography>
      </div>
    )
  }

  const renderEmptyState = () => {
    return (
      <div className={'content-empty-container'}>
        <div className={'content-empty-avatar'}>
          <img
            className={'content-empty-img'}
            alt='Empty'
            src={manageVM.typedFilterText.trim() === '' ? emptyResults : emptySearch}
          />
        </div>
        <Typography className='content-empty-header'>{lz.no_content_found}</Typography>
        {renderEmptyStateInfo()}
      </div>
    )
  }

  const getItemData = memoize((itemsPerRow, options) => ({
    itemsPerRow,
    options,
  }))

  const renderGrid = () => {
    if (!manageVM.shouldRender) return renderSpinner()
    if (!options || options.length === 0) return renderEmptyState()

    return (
      <div style={{ marginTop: '0', height: '100%' }}>
        <AutoSizer>
          {({ height, width }) => {
            const columnCount = Math.floor(width / manageVM.CARD_SIZE)
            const rowCount = Math.ceil(options.length / columnCount)

            // If there are more items to be loaded then add an extra row to hold a loading indicator.
            const itemCount = manageVM.hasNextPage ? options.length + 1 : options.length

            // Only load 1 page of items at a time.
            // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
            // const loadMoreItems = manageVM.isNextPageLoading ? Promise.resolve() : manageVM.loadNextPage()

            // Every row is loaded except for our loading indicator row.
            const isItemLoaded = (index) => !manageVM.hasNextPage || !!options[index]

            const itemsPerRow = Math.floor(width / manageVM.CARD_SIZE) || 1
            const itemData = getItemData(itemsPerRow, options)

            const Row = ({ index, style }) => {
              const cards = []
              const fromIndex = index * itemsPerRow
              const toIndex = Math.min(fromIndex + itemsPerRow, options.length)

              for (let i = fromIndex; i < toIndex; i++) {
                cards.push(<ContentCard vm={vm} key={i} item={options[i]} />)
              }

              return (
                <div className={'row'} style={style}>
                  {cards}
                </div>
              )
            }

            return (
              <InfiniteLoader
                isItemLoaded={(index) => {
                  if (index >= rowCount - 1) return false
                  return !manageVM.hasNextPage || !!options[index]
                }}
                itemCount={rowCount}
                loadMoreItems={(startIndex: number, stopIndex: number) => {
                  if (rowCount < 5) return Promise.resolve()
                  if (manageVM.isNextPageLoading) return Promise.resolve()
                  if (startIndex && stopIndex === 0) return Promise.resolve()
                  if (startIndex === stopIndex) return manageVM.loadNextPage()
                  return Promise.resolve()
                }}
                threshold={2}
              >
                {({ onItemsRendered, ref }) => (
                  <div>
                    <List
                      height={height}
                      itemCount={rowCount}
                      itemData={itemData}
                      itemSize={manageVM.CARD_SIZE}
                      width={width}
                      onItemsRendered={onItemsRendered}
                      ref={ref}
                    >
                      {Row}
                    </List>
                  </div>
                )}
              </InfiniteLoader>
            )
          }}
        </AutoSizer>
      </div>
    )
  }

  return (
    <>
      <div id='ContentPickerGrid'>{renderGrid()}</div>
    </>
  )
}

export default inject('localizationStore')(observer(CMSItemsLayoutGridBody))
