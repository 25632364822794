import { RootStore } from '../../stores/RootStore'
import { TrainingItem } from '../aggregate/TrainingItem'
import { CategoryPickerVM } from '../../shared/category-picker/CategoryPickerVM'
import { ParticipantsSelectVM } from '../../participants-select/view-models/ParticipantsSelectVM'
import { Attachment } from '../../tasks/aggregates/Attachment'
import { ContentItemType } from '../types/ContentItemType'

export abstract class FileAttachmentVM {
  protected rootStore: RootStore
  public trainingItem: TrainingItem
  public categoryPickerVM: CategoryPickerVM
  public participantsSelectVM: ParticipantsSelectVM
  public attachment: Attachment
  public contentItemType: ContentItemType

  constructor(rootStore: RootStore, contentItemType: ContentItemType, attachment: Attachment) {
    this.rootStore = rootStore
    this.contentItemType = contentItemType
    this.attachment = attachment
  }

  public abstract get isImage(): boolean
  public abstract get isVideo(): boolean
  public abstract get fileName(): string
  public abstract get shorterFileName(): string
  public abstract get type(): ContentItemType
  public abstract get fileType(): string
  public abstract get url(): string
  public abstract get thumbnailUrl(): string
  public abstract get fileFormat(): string

  public abstract get isCMSItem(): boolean
  public abstract openAttachment(): void
}
