import React from 'react'
import { inject, observer } from 'mobx-react'
import { Grid, Button } from '@material-ui/core'
import { UserImportVM } from '../../view-models/importV2/UserImportVM'
import './OrganizationUsersImportFooter.scss'
import LocalizationStore from '../../../localization/LocalizationStore'

interface Props {
  vm: UserImportVM
  localizationStore?: LocalizationStore
}

const OrganizationUsersImportFooter: React.FC<Props> = ({ vm, localizationStore }) => {
  const lz = localizationStore.lzStrings.usersImport
  if (!vm) return

  const renderActions = () => {
    return (
      <Grid container className='dialog-actions' alignItems='center' justifyContent='space-between'>
        <Grid item>
          <Button
            variant='outlined'
            size='large'
            onClick={() => vm.downloadXSLX()}
            className='downloadButton'
          >
            Export to XLSX
          </Button>
          <Button
            variant='outlined'
            size='large'
            onClick={() => vm.downloadCSV()}
            className='downloadButton'
          >
            Export to CSV
          </Button>
        </Grid>
        <Grid item className='dialog-actions-right'>
          <Button
            variant='text'
            size='large'
            onClick={() => vm.toggleConfirmResetModal()}
            className='cancelButton'
          >
            {vm.cancelButtonText}
          </Button>
          <Button
            variant='contained'
            size='large'
            onClick={() => vm.validate()}
            disabled={!vm.validateEnabled}
            className='saveButton'
          >
            {lz.validate}
          </Button>
          <Button
            variant='contained'
            size='large'
            onClick={() => vm.acceptChanges()}
            disabled={!vm.allowAcceptChanges}
            className='saveButton'
          >
            {!vm.validateEnabled ? lz.accept_changes : lz.skip_validation}
          </Button>
        </Grid>
      </Grid>
    )
  }

  return <div id='UserImportFooter'>{renderActions()}</div>
}

export default inject('localizationStore')(observer(OrganizationUsersImportFooter))
