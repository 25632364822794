import React from 'react'
import { TrainingItemsStore } from '../../store/TrainingItemsStore'
import LocalizationStore from '../../../localization/LocalizationStore'
import { inject, observer } from 'mobx-react'
import './BuilderTabPanel.scss'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import {
  Grid,
  FormControlLabel,
  Switch,
  Typography,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import BuilderDrawer from './BuilderDrawer'
import ContentCard from './ContentCard'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import LinkIcon from '@material-ui/icons/Link'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import PermMediaIcon from '@material-ui/icons/PermMedia'
import PanToolIcon from '@material-ui/icons/PanTool'
import ExerciseIcon from '@material-ui/icons/FitnessCenterRounded'
import DetailsArea from './DetailsArea'
import InfoTooltip from '../../../shared/InfoTooltip'

interface Props {
  trainingItemsStore?: TrainingItemsStore
  localizationStore?: LocalizationStore
}

const SortableItem: any = SortableElement(({ value }) => (
  <div style={{ zIndex: 99999999 }}>{value}</div>
))

const SortableList: any = SortableContainer(({ items }) => {
  return (
    <div>
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} value={value} />
      ))}
    </div>
  )
})

const BuilderTabPanel: React.FC<Props> = ({ trainingItemsStore, localizationStore }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const { trainingItemEditVM: vm } = trainingItemsStore
  const { training_items: strings, training_plans: strings2 } = localizationStore.lzStrings
  if (!vm) return null

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const renderTopBar = () => {
    return (
      <Grid item xs={12} className='topToolbar'>
        <DetailsArea />

        <span className='complete-in-order'>
          <FormControlLabel
            control={
              <Switch
                checked={vm.contentCompletedInOrder}
                onChange={() => vm.toggleMustCompletedInOrder()}
              />
            }
            label={strings.completed_in_order}
          />
          <InfoTooltip message={strings2.complete_in_order_message} />
        </span>
      </Grid>
    )
  }

  const content = vm.contentItemVMs.map((content, index) => (
    <ContentCard key={`item-${content.key}`} index={index} content={content} />
  ))

  const onSortEnd = ({ oldIndex, newIndex }) => {
    vm.setOldIndex(oldIndex)
    vm.setNewIndex(newIndex)
    vm.moveContent()
  }

  const contentCards = () => {
    return <SortableList distance={3} items={content} onSortEnd={onSortEnd} />
  }

  const addContentMenuButton = () => {
    return (
      <div id='AddContentMenu'>
        <Button variant='contained' className='add-content-button' onClick={handleClick}>
          Add Another
          <ArrowDropDownIcon />
        </Button>
      </div>
    )
  }

  const addContentMenu = () => {
    return (
      <Menu
        id='add-content-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            vm.addContentItem('link')
            handleClose()
          }}
        >
          <ListItemIcon className='icon'>
            <LinkIcon className='content-blue' />
          </ListItemIcon>
          <ListItemText primary='Link' />
        </MenuItem>
        <MenuItem
          onClick={() => {
            vm.addContentItem('media')
            handleClose()
          }}
        >
          <ListItemIcon className='icon'>
            <PermMediaIcon className='content-orange' />
          </ListItemIcon>
          <ListItemText primary='Media Item' />
        </MenuItem>
        <MenuItem
          onClick={() => {
            vm.addContentItem('file')
            handleClose()
          }}
        >
          <ListItemIcon className='icon'>
            <AttachFileIcon className='content-teal' />
          </ListItemIcon>
          <ListItemText primary='File Attachment' />
        </MenuItem>
        <MenuItem
          onClick={() => {
            vm.addContentItem('hands-on')
            handleClose()
          }}
        >
          <ListItemIcon className='icon'>
            <PanToolIcon className='content-purple' />
          </ListItemIcon>
          <ListItemText primary='Hands On' />
        </MenuItem>

        <MenuItem
          onClick={() => {
            vm.addContentItem('exercise')
            handleClose()
          }}
        >
          <ListItemIcon className='icon'>
            <ExerciseIcon className='content-navy' />
          </ListItemIcon>
          <ListItemText primary='Exercise' />
        </MenuItem>
      </Menu>
    )
  }

  const renderContentBuilder = () => {
    return (
      <Grid item xs={7} className='content-builder'>
        <div className='elements-container'>
          <div className='start-end-badge' id='start-badge'>
            <Typography>Start</Typography>
          </div>
          {renderContentCards()}
          {addContentMenuButton()}

          <div className='start-end-badge' id='end-badge'>
            <Typography>End</Typography>
          </div>
        </div>
      </Grid>
    )
  }

  const renderContentCards = () => {
    return <div>{contentCards()}</div>
  }

  return (
    <Grid container id='BuilderTabPanel'>
      {renderTopBar()}
      {renderContentBuilder()}
      <BuilderDrawer />
      {addContentMenu()}
    </Grid>
  )
}

export default inject('trainingItemsStore', 'localizationStore')(observer(BuilderTabPanel))
