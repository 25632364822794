import { observable, action } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { OrganizationsSAService } from '../service/OrganizationsSAService'
import { OrganizationsSAListWidgetVM } from '../view-models/list/OrganizationsSAListWidgetVM'
import { deserialize } from 'serializr'
import { Organization } from '../aggregate/Organization'
import { OrganizationEditVM } from '../view-models/drawer/OrganizationEditVM'
import { Zone } from '../aggregate/Zone'

export class OrganizationsSAStore {
  private rootStore: RootStore = null

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.listWidgetVM = new OrganizationsSAListWidgetVM(rootStore)
  }

  @observable public organizations: Array<Organization> = []
  @observable public listWidgetVM: OrganizationsSAListWidgetVM = null
  @observable public editVM: OrganizationEditVM = null
  @observable public zones: Array<Zone> = []
  @observable public isDrawerOpen: boolean = false
  @observable public isLoading: boolean = true

  @action
  public async loadOrganizations() {
    this.isLoading = true
    const svc = new OrganizationsSAService()
    const organizations = await svc.getOrganizations()
    this.organizations = deserialize(Organization, organizations)
    this.listWidgetVM.setKeywordFilter('')
    this.loadZones()
    this.isLoading = false
  }

  @action
  public async loadZones() {
    const svc = new OrganizationsSAService()
    this.zones = await svc.getZones()
  }

  @action
  public editOrganization(orgId) {
    const organization = this.organizations.find((org) => org.objectId === orgId)
    this.editVM = new OrganizationEditVM(this.rootStore, organization.clone())
    this.toggleDrawer()
  }

  @action
  public reloadEditVM(orgId) {
    const organization = this.organizations.find((org) => org.objectId === orgId)
    this.editVM = new OrganizationEditVM(this.rootStore, organization.clone())
  }

  @action
  public addOrganization() {
    this.editVM = new OrganizationEditVM(this.rootStore, Organization.create())
    this.toggleDrawer()
  }

  @action
  public toggleDrawer() {
    this.isDrawerOpen = !this.isDrawerOpen
  }

  public clearData() {
    this.organizations = []
  }
}
