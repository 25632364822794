import { computed, observable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { LoginVM } from '../view-models/LoginVM'
import { ForgotPasswordVM } from '../view-models/ForgotPasswordVM'
import { PasswordResetVM } from '../view-models/PasswordResetVM'
import { WelcomeVM } from '../view-models/WelcomeVM'
export class AuthStore {
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.loadLoginVM()
    this.ForgotPasswordVM = new ForgotPasswordVM(rootStore)
  }

  private rootStore: RootStore

  @observable LoginVM: LoginVM = null
  @observable ForgotPasswordVM: ForgotPasswordVM
  @observable PasswordResetVM: PasswordResetVM
  @observable WelcomeVM: WelcomeVM

  public loadLoginVM() {
    // return earl here? do not even load this VM if we are public
    this.LoginVM = new LoginVM(this.rootStore)
  }

  public loadResetPasswordVM(token) {
    this.PasswordResetVM = new PasswordResetVM(this.rootStore, token)
  }

  public loadWelcomeVM(orgId) {
    this.WelcomeVM = new WelcomeVM(this.rootStore, orgId)
  }

  public async tryRouteToMobile(token: string, mobileAppId: string) {
    if (!token) return
    if (!mobileAppId) return
    window.location.replace(`${mobileAppId}://auth/passwordreset/${token}`)
  }
}
