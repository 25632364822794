import React from 'react'
import { inject, observer, useLocalStore } from 'mobx-react'
import clsx from 'clsx'
import { AppBar, Button, ButtonBase, CssBaseline, Grid, IconButton, Toolbar, Tooltip, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import MuiConfirmDeleteDialog from '../../../shared/MuiConfirmDeleteDialog'
import { PulseQuestionsStore } from '../../store/PulseQuestionsStore'
import isIE11 from '../../../../utils/isIE11'
import './PulseQuestionEditHeader.scss'

interface Props {
  pulseQuestionsStore?: PulseQuestionsStore
}

const PulseQuestionEditHeader: React.FC<Props> = ({ pulseQuestionsStore }) => {
  const vm = pulseQuestionsStore.editVM
  if (!vm) return null

  const renderHeader = () => {
    return (
      <div id='DashboardToolbar'>
        <CssBaseline />
        <AppBar
          className={clsx('dashboard-appbar', {
            'no-transition': isIE11,
          })}
          elevation={0}
        >
          <Toolbar className='dashboard-toolbar'>
            <Typography className='dashboard-title'>
              Question Details
            </Typography>
            <div className='mgmt-tab-btns'>
              {renderDialogTabs()}
            </div>
            <div className='dashboard-toolbar-right'>
              {renderDeleteButton()}
              <Button variant='contained' onClick={() => vm.save()}>
                Save
              </Button>
              <IconButton onClick={() => vm.closeEditModal()}>
                <CloseIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <Toolbar />
      </div>
    )
  }

  const renderConfirmDelete = () => {
    if (!vm.showConfirmDelete) return
    return (
      <MuiConfirmDeleteDialog
        onClose={() => vm.toggleConfirmDelete()}
        open={vm.showConfirmDelete}
        itemType={'Pulse Question'}
        itemName={vm.title}
        onConfirm={() => vm.delete()}
      />
    )
  }

  const renderDeleteButton = () => {
    if (vm.isNew) return null
    return (
      <Button variant='text' className={'delete-btn'} onClick={() => vm.toggleConfirmDelete()}>
        Delete
      </Button>
    )
  }

  const renderDialogTabs = () => {
    return (
      <div className={'tab-btns'}>
        <Tooltip title={'Main'} placement='bottom'>
          <ButtonBase
            className={clsx('tab-button', {
              active: vm.tabIndex === 0,
              error: !vm.isValid,
            })}
            onClick={() => vm.setTabIndex(0)}
          >
            Main
          </ButtonBase>
        </Tooltip>
        <Tooltip title={'More Details'} placement='bottom'>
          <ButtonBase
            className={vm.tabIndex === 1 ? 'tab-button active' : 'tab-button'}
            onClick={() => vm.setTabIndex(1)}
          >
            More Details
          </ButtonBase>
        </Tooltip>
      </div>
    )
  }

  return (
    <div id='PulseQuestionEditHeader'>
      {renderHeader()}
      {renderConfirmDelete()}
    </div>
  )
}

export default inject('pulseQuestionsStore')(observer(PulseQuestionEditHeader))
