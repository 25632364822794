import moment from 'moment'
import { RootStore } from '../../../stores/RootStore'
import { DashboardType } from '../../types/DashboardType'

export class UserDashboardsFindService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public findDefaultDashboard() {
    const currentOrgUser = this.rootStore.audienceMembersStore.getUser(
      this.rootStore.appStore.currentUserId
    )
    if (!currentOrgUser) return null
    const customDashboards = this.rootStore.userDashboardsStore.currentOrgUserDashboards.filter(
      (e) => e.type === 'custom'
    )
    if (currentOrgUser.primaryRoleId) {
      const role = this.rootStore.rolesStore.getRole(currentOrgUser.primaryRoleId)
      if (role && role.defaultDashboardId && role.forceDashboardRoute) {
        const defaultDashboard = customDashboards.find(
          (e) => e.dashboardId === role.defaultDashboardId
        )
        if (defaultDashboard) return defaultDashboard
      }
    }
    if (currentOrgUser.primaryGroupId) {
      const group = this.rootStore.groupsStore.getGroup(currentOrgUser.primaryGroupId)
      if (group && group.defaultDashboardId && group.forceDashboardRoute) {
        const defaultDashboard = customDashboards.find(
          (e) => e.dashboardId === group.defaultDashboardId
        )
        if (defaultDashboard) return defaultDashboard
      }
    }
    return null
  }

  public findLastVisitedDashboard() {
    const customDashboards = this.rootStore.userDashboardsStore.viewModels.customUserDashboards
    const visitedDashboards = customDashboards.filter((e) => e.lastVisitedDateTime)
    const sortedDashboards = visitedDashboards.sort((a, b) => {
      const valueA = a.lastVisitedDateTime.toString()
      const valueB = b.lastVisitedDateTime.toString()
      if (valueA < valueB) {
        return 1
      } else if (valueA > valueB) {
        return -1
      } else {
        return 0
      }
    })
    return sortedDashboards[0]
  }

  public findDashboardByTypeAndId(objectId: string, type: DashboardType) {
    if (type === 'custom') {
      const customDashboards = this.rootStore.userDashboardsStore.currentOrgUserDashboards.filter((e) => {
        if (!e.type) return true
        if (e.type === 'custom') return true
        return false
      })
      return customDashboards.find((e) => e.objectId === objectId)
    }
    const typeDashboards = this.rootStore.userDashboardsStore.currentOrgUserDashboards.filter((e) => e.type === type)
    return typeDashboards.find((e) => e.objectId === objectId)
  }

  public findDashboardByType(type: DashboardType) {
    return this.rootStore.userDashboardsStore.currentOrgUserDashboards.find((e) => e.type === type)
  }

  public findDashboardByDashboardId(dashboardId: string) {
    return this.rootStore.userDashboardsStore.currentOrgUserDashboards.find((e) => e.dashboardId === dashboardId)
  }
}
