import { action, computed, observable } from 'mobx'
import { RootStore } from '../../../stores/RootStore'

export class PulseCampaignsScreenVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.lz = this.rootStore.localizationStore.lzStrings.pulseCampaign
    this.labelsStore = this.rootStore.labelsStore
  }

  @observable public tabIndex: number = 0
  @observable public templateLayout: number = 0
  @observable public showDropdownMenu: boolean = false
  @observable public lz = undefined
  @observable public labelsStore = undefined

  @computed
  public get isSystemAdmin(): boolean {
    return this.rootStore.appStore.isSystemAdmin
  }

  @computed
  public get canManage() {
    if (this.rootStore.appStore.isSystemAdmin) return true
    if (this.rootStore.appStore.isOrgAdmin) return true
    if (!this.rootStore.userStore.currentOrganization) return false
    return Boolean(this.rootStore.appStore.canManageCategories)
  }

  @action
  public createPulseCampaign() {
    this.rootStore.pulseCampaignsStore.viewModels.createCampaignDialogVM.openCreateCampaignDialog()
  }

  @action
  public manageTemplates() {
    this.rootStore.appStore.router.push('/pulse/v1/campaignTemplates')
  }

  @action
  public toggleDropdownMenu() {
    this.showDropdownMenu = !this.showDropdownMenu
  }
}
