import { RootStore } from '../../stores/RootStore'
import { observable, action, computed, runInAction } from 'mobx'
import Parse from 'parse'
import { deserialize } from 'serializr'
import { IDTO } from './IDTO'
import { IAggregate } from './IAggregate'
import { IDataStore } from './IDataStore'
import { ParseQueryService } from './ParseQueryService'
import * as ParseQueryServiceWorker from './ParseQueryService.worker'
import { ParsePagedQueryResponse } from './ParsePagedQueryResponse'
import { IColumnSort } from './IColumnSort'
import { has } from 'lodash'
import * as Sentry from '@sentry/browser'
import env from '../../../env'

export class DataStore<T extends IAggregate, DTO extends IDTO> implements IDataStore<T, DTO> {
  protected rootStore: RootStore
  protected listRecordsQuery: Parse.Query
  private listRecordsSubscription: Parse.LiveQuerySubscription
  private fullRecordsSubscription: Parse.LiveQuerySubscription
  protected className: string
  protected listColumns: string[]
  protected fullColumns: string[]
  protected type: any
  @observable public listRecordsLoaded: boolean = false
  private fullObjectIds: Set<string> = new Set()
  protected listenToListRecordsViaApiUpdates: boolean = false

  constructor(rootStore: RootStore, c: { new (): T }, className: string, listColumns: string[]) {
    this.rootStore = rootStore
    this.className = className
    this.listColumns = this.getDistinctItems(listColumns)
    this.fullColumns = this.getDistinctItems([...this.listColumns, ...this.getFullColumns()])
    this.type = c
  }

  @observable.shallow protected records: Array<T> = []
  @observable public totalRecords: number = 0
  protected paged: boolean = false
  protected filterColumns: string[] = []
  public pageNumber: number = 0
  protected recordsPerPage: number = 1000
  protected keepIsDeletedRecords: boolean = false
  @observable public filter: string = ''

  @computed
  public get hasFilter(): boolean {
    return Boolean(this.filter)
  }

  public onListRecordsLoaded() {}
  public onRecordUpdated(obj: T) {}
  public onRecordDeleted() {}
  @observable public sortColumnName: string = ''
  @observable public sortDirection: 'asc' | 'desc' = 'asc'
  public additionalSortColumns: IColumnSort[] = []

  public async getNextPage(): Promise<void> {
    this.pageNumber = this.pageNumber + 1
    await this.loadListRecords()
  }

  public setFilter(val: string) {
    if (val) val = val.trim()
    this.filter = val
    this.loadListRecords()
    this.pageNumber = 0
  }

  protected getFullColumns(): string[] {
    return []
  }

  protected shouldLoadListRecords() {
    return true
  }

  public setSort(columnName: string, direction: 'asc' | 'desc' = 'asc') {
    let doContinue = false
    if (this.sortColumnName !== columnName) doContinue = true
    if (this.sortDirection !== direction) doContinue = true
    if (!doContinue) return
    this.sortColumnName = columnName
    this.sortDirection = direction
    this.additionalSortColumns = []
    this.pageNumber = 0
  }

  public addSort(columnName: string, direction: 'asc' | 'desc' = 'asc') {
    this.additionalSortColumns.push({ columnName, direction })
  }

  @action
  public async loadListRecords(): Promise<void> {
    if (!this.shouldLoadListRecords()) return
    const items = await this.getListRecords()
    runInAction(() => {
      const arr = this.records as any
      let errorDetails // we only want one
      arr.replace(
        items.reduce((accumulator, currentValue) => {
          let item
          try {
            item = deserialize(this.type, currentValue)
            accumulator.push(item)
          } catch (error) {
            errorDetails = {
              type: String(this.className),
              json: JSON.stringify(currentValue),
              error,
            }
          }
          return accumulator
        }, [])
      )
      if (errorDetails) {
        console.log(errorDetails)
        console.log(`Error Serializing ${errorDetails.type} sent error to Sentry`)
        Sentry.captureException(errorDetails.error, {
          extra: {
            type: errorDetails.type,
            json: errorDetails.json,
          },
        })
      }
      this.listenToListRecordsQueryOnServer()
      this.listRecordsLoaded = true
      this.onListRecordsLoaded()
    })
  }

  private async getListRecords() {
    const items = await this.getListRecordsViaApi()
    if (items) return items
    return await this.getListRecordsViaQuery()
  }

  private async getListRecordsViaApi() {
    if (process.env.NODE_ENV === 'test') return undefined
    const apiCall = this.getListRecordsApiCall()
    if (!apiCall) return undefined
    const items = await apiCall()
    if (!Array.isArray(items)) {
      throw 'An array was not returned: ' + this.className
    }
    if (!items) return []
    if (this.listenToListRecordsViaApiUpdates) {
      // this listens to all of the objectId's returned in the resulting array
      this.listRecordsQuery = this.getBaseQuery()
      this.listRecordsQuery.select(...this.listColumns)
    }
    return items
  }

  private async getListRecordsViaQuery() {
    this.listRecordsQuery = this.getBaseQuery()
    this.addFilterToListRecordsQuery()
    this.listRecordsQuery.select(...this.listColumns)
    this.addSortingToListRecordsQuery()
    if (this.paged) {
      if (this.pageNumber === 0) this.pageNumber = 1
      return await this.getPageFromQuery(
        this.listRecordsQuery,
        this.pageNumber,
        this.recordsPerPage
      )
    }
    return await this.getAllFromQuery(this.listRecordsQuery, this.recordsPerPage)
  }

  private addFilterToListRecordsQuery() {
    if (!this.hasFilter) return
    if (this.filterColumns.length === 0) return
    let cleanTerms = this.filter.replace(',', ' ')
    cleanTerms = cleanTerms.replace('.', ' ')
    const terms = cleanTerms.split(' ').filter((e) => e && e.length > 0)
    const andQueries = []
    for (let term of terms) {
      const orQueries = []
      const termRegex = new RegExp(term, 'i')
      for (let col of this.filterColumns) {
        const query = this.getBaseQuery()
        query.matches(col, termRegex)
        orQueries.push(query)
      }
      andQueries.push(Parse.Query.or(...orQueries))
    }
    this.listRecordsQuery = Parse.Query.and(...andQueries)
  }

  private addSortingToListRecordsQuery() {
    if (this.sortColumnName) {
      if (this.sortDirection === 'asc') this.listRecordsQuery.ascending(this.sortColumnName)
      if (this.sortDirection === 'desc') this.listRecordsQuery.descending(this.sortColumnName)
    }
    this.additionalSortColumns.forEach((sort) => {
      if (sort.direction === 'asc') this.listRecordsQuery.addAscending(sort.columnName)
      if (sort.direction === 'desc') this.listRecordsQuery.addDescending(sort.columnName)
    })
  }

  private getDistinctItems(items: string[]) {
    return Array.from(new Set(items))
  }

  protected getBaseQuery() {
    const query = new Parse.Query(this.className)
    query.equalTo('organizationId', this.rootStore.appStore.currentOrgId)
    query.notEqualTo('isDeleted', true)
    return query
  }

  protected getListRecordsApiCall() {
    return undefined
  }

  public setPage(val: number) {
    this.pageNumber = val
  }

  @action
  public async getFullRecord(objectId: string, skipOrgId: boolean = false): Promise<T> {
    const query = new Parse.Query(this.className)
    query.equalTo('objectId', objectId)
    if (!skipOrgId) query.equalTo('organizationId', this.rootStore.appStore.currentOrgId)
    query.includeAll()
    query.select(...this.fullColumns)
    const records = await this.getAllFromQuery(query, 1)
    if (records.length !== 1) return undefined
    this.addFullObjectId(objectId)
    runInAction(() => this.updateRecordFromServer(records[0] as DTO, 'full'))
    return this.getRecord(objectId)
  }

  private addFullObjectId(objectId: string) {
    this.fullObjectIds.add(objectId)
    this.listenToFullRecordsQueryOnServer()
  }

  public getRecord(objectId: string): T {
    return this.records.find((e) => e.objectId === objectId)
  }

  @action
  public deleteRecord(objectId) {
    const idx = this.getRecordIndex(objectId)
    if (!idx) return
    if (this.records[idx] && this.records[idx].objectId === objectId) this.records.splice(idx, 1)
    this.totalRecords = this.totalRecords - 1
    if (this.totalRecords < 0) this.totalRecords = 0
    this.onRecordDeleted()
  }

  private getRecordIndex(objectId: string): number {
    return this.records.findIndex((e) => e.objectId === objectId)
  }

  public updateRecordFromServer(fromItem: DTO, fromQuery: 'full' | 'list') {
    if (!fromItem) return
    if (fromItem.isDeleted && !this.keepIsDeletedRecords) {
      this.deleteRecord(fromItem.objectId)
      return
    }
    if (fromQuery === 'list') {
      if (this.fullObjectIds.has(fromItem.objectId)) return
    }
    const existingItemIndex = this.getRecordIndex(fromItem.objectId)
    if (existingItemIndex === -1) {
      const item: T = deserialize(this.type, fromItem)
      item.markIsOnServer()
      this.records.push(item)
      this.onRecordUpdated(item)
      return item
    }
    const updatedDTO = this.mergeObjects(this.records[existingItemIndex], fromItem)
    const item: T = deserialize(this.type, updatedDTO)
    item.markIsOnServer()
    this.records[existingItemIndex] = item
    this.onRecordUpdated(item)
    return item
  }

  private mergeObjects(existing: T, newItem): DTO {
    const current: IDTO = existing.serialize()
    return {
      ...current,
      ...newItem,
    }
  }

  public async listenToListRecordsQueryOnServer() {
    if (process.env.NODE_ENV === 'test') return
    if (!this.listRecordsQuery) return
    if (this.listRecordsSubscription) this.listRecordsSubscription.unsubscribe()
    const sub = await this.listRecordsQuery.subscribe()
    sub.on('create', (e: Parse.Object) => this.updateRecordFromServer(e.toJSON() as DTO, 'list'))
    sub.on('enter', (e: Parse.Object) => this.updateRecordFromServer(e.toJSON() as DTO, 'list'))
    sub.on('update', (e: Parse.Object) => this.updateRecordFromServer(e.toJSON() as DTO, 'list'))
    sub.on('delete', (e: Parse.Object) => this.deleteRecord(e.id))
    sub.on('leave', (e: Parse.Object) => this.deleteRecord(e.id))
    this.listRecordsSubscription = sub
  }

  public async listenToFullRecordsQueryOnServer() {
    if (process.env.NODE_ENV === 'test') return
    if (this.fullRecordsSubscription) this.fullRecordsSubscription.unsubscribe()
    const query = new Parse.Query(this.className)
    query.equalTo('organizationId', this.rootStore.appStore.currentOrgId)
    query.containedIn('objectId', Array.from(this.fullObjectIds))
    const sub = await query.subscribe()
    sub.on('create', (e: Parse.Object) => this.updateRecordFromServer(e.toJSON() as DTO, 'full'))
    sub.on('enter', (e: Parse.Object) => this.updateRecordFromServer(e.toJSON() as DTO, 'full'))
    sub.on('update', (e: Parse.Object) => this.updateRecordFromServer(e.toJSON() as DTO, 'full'))
    sub.on('delete', (e: Parse.Object) => this.deleteRecord(e.id))
    sub.on('leave', (e: Parse.Object) => this.deleteRecord(e.id))
    this.fullRecordsSubscription = sub
  }

  public clearData() {
    const arr = this.records as any
    arr.replace([])
  }

  public isIE(): boolean {
    const ua = window.navigator.userAgent //Check the userAgent property of the window.navigator object
    const msie = ua.indexOf('MSIE ') // IE 10 or older
    const trident = ua.indexOf('Trident/') //IE 11

    return msie > 0 || trident > 0
  }

  protected async getAllFromQuery(query: Parse.Query, recordsPerPage: number) {
    let useWorker: boolean = true
    if (process.env.NODE_ENV === 'test') useWorker = false
    if (process.env.NODE_ENV !== 'test' && this.isIE()) useWorker = false
    if (!useWorker) {
      const svc = new ParseQueryService()
      return await svc.getAllFromQuery(query, recordsPerPage)
    }
    const worker = (ParseQueryServiceWorker as any)() as typeof ParseQueryServiceWorker
    const items = await worker.getAllFromQuery(
      env.var.REACT_APP_API_URL,
      this.rootStore.userStore.parseUserInfo,
      this.className,
      query.toJSON(),
      recordsPerPage
    )
    const wrker: any = worker
    if (process.env.NODE_ENV === 'production') wrker.terminate()
    return items
  }

  protected async getPageFromQuery(query: Parse.Query, pageNumber: number, recordsPerPage: number) {
    let useWorker: boolean = true
    if (process.env.NODE_ENV === 'test') useWorker = false
    if (process.env.NODE_ENV !== 'test' && this.isIE()) useWorker = false
    if (!useWorker) {
      const svc = new ParseQueryService()
      const result = await svc.getPageFromQuery(query, pageNumber, recordsPerPage)
      if (result.count) runInAction(() => (this.totalRecords = result.count))
      return result.rows
    }
    const worker = (ParseQueryServiceWorker as any)() as typeof ParseQueryServiceWorker
    const result: ParsePagedQueryResponse = await worker.getPageFromQuery(
      env.var.REACT_APP_API_URL,
      this.rootStore.userStore.parseUserInfo,
      this.className,
      query.toJSON(),
      pageNumber,
      recordsPerPage
    )

    const wrker: any = worker
    if (process.env.NODE_ENV === 'production') wrker.terminate()
    if (result.count) runInAction(() => (this.totalRecords = result.count))
    return result.rows
  }

  @computed
  public get hasListRecords() {
    if (!this.listRecordsLoaded) return true
    return this.totalRecords > 0
  }
}
