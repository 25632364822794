import React, { FC } from 'react'
import { observer, inject } from 'mobx-react'
import { Button, Grid } from '@material-ui/core'
import { Player } from '@lottiefiles/react-lottie-player'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import { PulseCampaignsStore } from '../../store/PulseCampaignsStore'
import { PulseCampaignEditTabsEnum } from '../../type/PulseCampaignEditTabsEnum'
import './InitiatedStep.scss'

interface PublishTabPanelProps {
  pulseCampaignsStore?: PulseCampaignsStore
  localizationStore?: LocalizationStore
}

const InitiatedStep: FC<PublishTabPanelProps> = ({ pulseCampaignsStore, localizationStore }) => {
  const { lzStrings } = localizationStore
  const vm = pulseCampaignsStore.viewModels.editVM
  if (!vm) return null
  if (vm.currentTabIndex !== PulseCampaignEditTabsEnum.LAUNCHED) return null
  return (
    <Grid container id='InitiatedStep'>
      <div className='success-text'>Your survey is on its way!</div>
      <div className='player-container'>
        <Player
          autoplay={true}
          loop={true}
          controls={false}
          src='https://assets10.lottiefiles.com/packages/lf20_rcwlnqyn.json'
          style={{ height: '300px', width: 'auto' }}
        ></Player>
      </div>
      <div className='btn-row'>
        <Button variant='contained' onClick={() => vm.navigateToPulseCampaigns()}>
          Back to Pulse Campaigns
        </Button>
      </div>
    </Grid>
  )
}

export default inject('localizationStore', 'pulseCampaignsStore')(observer(InitiatedStep))
