import React from 'react'
import { inject, observer } from 'mobx-react'
import { Button, Grid } from '@material-ui/core'
import { CustomCollectionsStore } from '../../store/CustomCollectionStore'
import { AgGridReact } from 'ag-grid-react'
import './RecordsTab.scss'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import CustomRecordsImportModal from '../import-records/CustomRecordsImportModal'
import CustomRecordModal from '../import-records/CustomRecordModal'
import CustomLoadingCellRenderer from './CustomLoadingCellRenderer'

interface Props {
  customCollectionsStore?: CustomCollectionsStore
}

const RecordsTab: React.FC<Props> = ({ customCollectionsStore }) => {
  const { editVM: vm } = customCollectionsStore
  if (!vm) return null
  if (vm.tabIndex !== 1) return null

  const renderTable = () => {
    return (
      <div className='cc-records-ag-grid-container'>
        <div className='ag-theme-alpine'>
          <AgGridReact
            gridOptions= {{suppressCellSelection: true, rowModelType: 'infinite'}}
            rowSelection={'multiple'}
            suppressRowClickSelection={true}
            suppressClickEdit={false}
            onRowSelected={(e) => vm.onRowSelected(e)}
            columnDefs={vm.columnDefs}
            onGridReady={(e) => vm.onGridReady(e)}
            getRowNodeId={(e) => vm.getRowNodeId(e)}
            onRowClicked={(e) => vm.onRowClicked(e)}
            editType=''
          />
        </div>
      </div>
    )
  }

  const renderTopButtons = () => {
    return (
      <Grid container className='deleteButton' alignItems='center' justifyContent='flex-start'>
        <Grid item>
          <Button 
            disabled={vm.selectedIds.size === 0 || vm.isProcessing}
            variant='contained'
            className='btn-remove'
            onClick={() => vm.deleteSelected()}
          >
            Delete Selected
          </Button>
        </Grid>
      </Grid> 
    )
  }

  const renderBottomButtons = () => {
    return (
      <Grid container className='buttons' alignItems='center' justifyContent='space-between'>
        <Grid item>
          <Button
            variant='outlined'
            color='primary'
            size='large'
            className='downloadButton'
            onClick={() => vm.downloadTemplate()}
          >
            Download Template
          </Button>
        </Grid>
        <Grid item>
        <Button variant='outlined' onClick={() => vm.addNew()}>
            Add New
          </Button>
          <Button variant='outlined' onClick={() => vm.openImportRecordsModal()}>
            Import
          </Button>
          <Button variant='outlined' onClick={() => vm.exportRecords()}>
            Export
          </Button>
        </Grid>
      </Grid> 
    )
  }

  return (
    <div id='RecordsTab'>
      {renderTopButtons()}
      {renderTable()}
      {renderBottomButtons()}
      <CustomRecordsImportModal vm={vm.importRecordsModalVM} />
      <CustomRecordModal vm={vm.customRecordModalVM} />
    </div>
  )
}

export default inject('customCollectionsStore')(observer(RecordsTab))
