import React from 'react'
import { inject, observer, useLocalStore } from 'mobx-react'
import LocalizationStore from '../../localization/LocalizationStore'
import './PulseCampaignTypesEdit.scss'
import PulseCampaignTypeEditVM from '../view-models/PulseCampaignTypeEditVM'
import rootStore from '../../stores/RootStore'
import { PulseCampaignTypesStore } from '../store/PulseCampaignTypesStore'
import TopToolbarEdit from './TopToolbarEdit'
import { Button, Checkbox, Grid, TextField, Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import InfoTooltip from '../../shared/InfoTooltip'
import MuiConfirmDeleteDialog from '../../shared/MuiConfirmDeleteDialog'

interface Props {
  objId: string
  localizationStore?: LocalizationStore
  pulseCampaignTypesStore?: PulseCampaignTypesStore
}

const PulseCampaignTypesEdit: React.FC<Props> = ({
  pulseCampaignTypesStore,
  localizationStore,
  objId,
}) => {
  const lz = localizationStore.lzStrings.pulseCampaign
  let localStore = useLocalStore(() => ({
    vm: new PulseCampaignTypeEditVM(rootStore, pulseCampaignTypesStore.getPulseCampaignType(objId)),
  }))
  let vm = localStore.vm

  const renderDeleteDialog = () => {
    return (
      <MuiConfirmDeleteDialog
        onClose={() => vm.toggleDeleteDialog()}
        open={vm.deleteConfirmShown}
        itemType='Pulse Campaign Type'
        itemName={vm.name}
        onConfirm={() => vm.deletePulseCampaignType()}
      />
    )
  }

  return (
    <div id='PulseCampaignTypesEdit'>
      <TopToolbarEdit vm={vm} />
      <div id={'PulseCampaignTypesEditForm'}>
        <Grid className='set-details' item xs={12}>
          <div className='name-input'>
            <Typography className='primary-text'>{lz.name_lower}</Typography>
            <div className='name-container'>
              <TextField
                error={!vm.nameValid}
                id='outlined-name'
                className={'set-name'}
                value={vm.name}
                onChange={(e) => vm.setName(e.target.value)}
                fullWidth
                variant='outlined'
              />
            </div>
          </div>
          <div className='active-checkbox'>
            <div>
              <Checkbox checked={vm.isActive} onChange={() => vm.toggleIsActive()} />
            </div>
            <Typography className='primary-text primary-text--horizontal'>
              {lz.is_active}
            </Typography>
            <InfoTooltip message={lz.is_active_tooltip} />
          </div>
        </Grid>
        <Grid item xs={12}>
          {!vm.isValid && <Typography className='error-text'>{lz.name_error}</Typography>}
        </Grid>
        <Grid className='set-actions' item xs={12}>
          <Button
            className='delete-btn'
            variant='contained'
            onClick={() => vm.toggleDeleteDialog()}
          >
            <DeleteIcon />
            {lz.delete}
          </Button>
        </Grid>
      </div>
      {renderDeleteDialog()}
    </div>
  )
}

export default inject(
  'pulseCampaignTypesStore',
  'localizationStore'
)(observer(PulseCampaignTypesEdit))
