import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import './QuizResponses.scss'
import { inject, observer } from 'mobx-react'
import { QuizResultsVM } from '../view-models/QuizResultsVM'
import QuizResultQuestion from './QuizResultQuestion'
import LocalizationStore from '../../localization/LocalizationStore'

interface Props {
  vm: QuizResultsVM
  localizationStore?: LocalizationStore
}

const QuizResponses: React.FC<Props> = ({ vm, localizationStore }) => {
  if (!vm) return null
  const lz = localizationStore.lzStrings.quiz

  const renderResponsesTitle = () => {
    return (
      <Grid item xs={12} className={'quiz-responses-title'}>
        <Typography className={'quiz-responses-title-text'}>{lz.responses}</Typography>
      </Grid>
    )
  }

  const renderQuestionsHeader = () => {
    return (
      <Grid container item xs={12} className={'quiz-responses-header'}>
        <Grid item xs={1}>
          <Typography className={'header-text'}>#</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={'header-text'}>{lz.question}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography className={'header-text your-answer-text'}>{lz.your_answer_vs}</Typography>
        </Grid>
      </Grid>
    )
  }

  const renderQuestionsResults = () => {
    return vm.questions.map((q, i) => <QuizResultQuestion vm={q} key={i} />)
  }

  const renderContent = () => {
    return (
      <Grid container className={'quiz-responses-container'}>
        {renderQuestionsHeader()}
        {renderQuestionsResults()}
      </Grid>
    )
  }

  return (
    <Grid container id='QuizResponses'>
      {renderResponsesTitle()}
      {renderContent()}
    </Grid>
  )
}

export default inject('localizationStore')(observer(QuizResponses))
