import { action, observable, computed, reaction } from 'mobx'
import { Question } from 'src/app/surveys/aggregate/Question'
import { RootStore } from '../../stores/RootStore'
import { SurveyResponse } from '../../survey-responses/aggregate/SurveyResponse'
import { SurveyResult } from '../aggregate/SurveyResult'
import { QuestionVM } from './QuestionVM'

export class SurveyResultsVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, userSurveyId: string, surveyResponse: SurveyResponse) {
    this.rootStore = rootStore
    this.userSurveyId = userSurveyId
    this.surveyResponse = surveyResponse
  }

  @observable public questionIndex: number = 0
  @observable public userSurveyId: string
  @observable public fullSurveyResponse: SurveyResponse = null
  @observable public surveyResponse: SurveyResponse = null
  @observable public showSummaryPage: boolean = true

  @computed
  public get surveyResponseId(): string {
    if (!this.surveyResponse) return ''
    return this.surveyResponse.objectId
  }

  @computed
  public get surveyId(): string {
    if (!this.surveyResponse) return ''
    const surveyId = this.surveyResponse.surveyId
    if (surveyId) this.rootStore.surveyResultsStore.setCurrentSurveyId(surveyId)
    return surveyId
  }

  @computed
  public get name(): string {
    if (!this.surveyResponse) return ''
    return this.surveyResponse.name
  }

  @computed
  public get isLoaded(): boolean {
    if (!this.surveyResponse) return false
    if (!this.surveyResult) return false
    return true
  }

  @computed
  public get currentQuestion() {
    return this.questions[this.questionIndex]
  }

  @computed
  public get nextQuestion(): boolean {
    const foundQuestion = this.questions[this.questionIndex + 1]
    if (!foundQuestion) return false
    return true
  }

  @computed
  public get questions(): Array<QuestionVM> {
    if (!this.isLoaded) return []
    return this.surveyResponse.questions.map((q, i) => new QuestionVM(this.rootStore, q, i, this))
  }

  @computed
  public get surveyResult(): SurveyResult {
    if (
      !this.rootStore.surveyResultsStore.currentOrgSurveyResults &&
      !this.rootStore.surveyResultsStore.result
    )
      return null
    const result = this.rootStore.surveyResultsStore.currentOrgSurveyResults.find(
      (e) => e.surveyId === this.surveyId
    )
    if (!result) return this.rootStore.surveyResultsStore.result
    return result
  }

  @action
  public toggleSummaryPage() {
    this.showSummaryPage = !this.showSummaryPage
  }

  @computed
  public get surveyTitle(): string {
    if (!this.surveyResponse) return ''
    return this.surveyResponse.name
  }

  @action
  public goNext() {
    this.questionIndex = this.questionIndex + 1
  }

  @action
  public goPrevious() {
    if (this.questionIndex === 0) return
    this.questionIndex = this.questionIndex - 1
  }

  @action
  public handleBackToSurveys() {
    this.rootStore.appStore.router.push('/dashboard/surveys')
  }
}
