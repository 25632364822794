import React from 'react'
import { Card } from '@material-ui/core'
import { inject, useLocalStore, observer } from 'mobx-react'
import { OrganizationUsersWidgetVM } from '../../view-models/widget/OrganizationUsersWidgetVM'
import { RootStore } from '../../../stores/RootStore'
import './OrganizationUsersWidget.scss'
import './Table.scss'
import ActiveTab from '../widget/ActiveTab'
import ArchivedTab from '../widget/ArchivedTab'

interface Props {
  rootStore?: RootStore
}

const OrganizationUsersWidget: React.FC<Props> = ({ rootStore }) => {
  const localStore = useLocalStore(() => ({
    vm: new OrganizationUsersWidgetVM(rootStore),
  }))
  const vm = localStore.vm

  return (
    <Card elevation={0} id='OrganizationUsersWidget'>
      <ActiveTab vm={vm.activeTab} />
      <ArchivedTab vm={vm.archivedTab} />
    </Card>
  )
}

export default inject('rootStore')(observer(OrganizationUsersWidget))
