import { observable, action, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { PulseCampaign } from '../aggregate/PulseCampaign'
import { PulseCampaignsStore } from '../store/PulseCampaignsStore'
import PulseQuestion from '../../pulse-questions/aggregate/PulseQuestion'
import { CreateCampaignDialogVM } from '../view-models/create-dialog/CreateCampaignDialogVM'
import { PulseCampaignEditVM } from '../view-models/PulseCampaignEditVM'
import { PulseQuestionEditVM } from '../view-models/PulseQuestionEditVM'
import { CampaignAnalysisEditVM } from '../view-models/analysis/CampaignAnalysisEditVM'
import { IPulseCampaignFindResult } from '../interfaces/IPulseCampaignFindResult'
import { deserialize } from 'serializr'

export class PulseCampaignsViewModelsService {
  private rootStore: RootStore
  private pulseCampaignsStore: PulseCampaignsStore

  constructor(rootStore: RootStore, pulseCampaignsStore: PulseCampaignsStore) {
    this.rootStore = rootStore
    this.pulseCampaignsStore = pulseCampaignsStore
    this.createCampaignDialogVM = new CreateCampaignDialogVM(this.rootStore)
  }

  @observable public editVM: PulseCampaignEditVM = null
  @observable public campaignAnalysisEditVM: CampaignAnalysisEditVM = null
  @observable public editQuestionVM: PulseQuestionEditVM = null
  @observable public createCampaignDialogVM: CreateCampaignDialogVM = null

  @action
  public async loadPulseCampaignEditVM(
    id: string,
    surveyId: string = null,
    attempts: number = 0
  ): Promise<PulseCampaignEditVM> {
    const maxAttempts = 20
    attempts = attempts + 1
    if (attempts === maxAttempts) {
      console.log('giving up')
      this.rootStore.appStore.router.push('/pulse/v1/campaigns')
      return
    }

    if (process.env.NODE_ENV !== 'test') {
      if (!this.rootStore.organizationsStore.currentOrganization) {
        setTimeout(async () => await this.loadPulseCampaignEditVM(id, surveyId, attempts), 500)
        return
      }
      if (!this.rootStore.surveyTypesStore.listRecordsLoaded) {
        setTimeout(async () => await this.loadPulseCampaignEditVM(id, surveyId, attempts), 500)
        return
      }
    }
    if (id === 'empty') return (this.editVM = null)
    if (id === 'new') {
      return (this.editVM = new PulseCampaignEditVM(
        this.rootStore,
        PulseCampaign.create(this.rootStore.appStore.currentOrgId),
        false
      ))
    }
    const foundPulseCampaign = await this.pulseCampaignsStore.getFullRecord(id)
    if (!foundPulseCampaign) {
      console.log('not found ' + id + '. trying again. attempts: ' + attempts)
      setTimeout(async () => await this.loadPulseCampaignEditVM(id, surveyId, attempts), 500)
      return
    }
    this.editVM = new PulseCampaignEditVM(
      this.rootStore,
      foundPulseCampaign.clone(),
      false,
      surveyId
    )
  }

  @action
  public async createPulseCampaignFromTemplate(templateId: string, attempts: number = 0) {
    if (templateId === 'preview') {
      const template = PulseCampaign.create(this.rootStore.appStore.currentOrgId)
      this.editVM = new PulseCampaignEditVM(this.rootStore, template, false)
      this.editVM.isPreview = true
    } else {
      const maxAttempts = 20
      attempts = attempts + 1
      if (attempts === maxAttempts) {
        console.log('giving up')
        this.rootStore.appStore.router.push('/pulse/v1/campaigns')
        return
      }
      console.log('trying ' + attempts)
      const result: IPulseCampaignFindResult = await this.pulseCampaignsStore.getCampaignTemplates([
        templateId,
      ])
      const foundCampaign = result.pulseCampaigns[0]
      if (!foundCampaign) {
        setTimeout(
          async () => await this.createPulseCampaignFromTemplate(templateId, attempts++),
          500
        )
        return
      }
      const newPulseCampaign = deserialize(PulseCampaign, foundCampaign)
      newPulseCampaign.setObjectId(undefined)
      this.editVM = new PulseCampaignEditVM(this.rootStore, newPulseCampaign, false)
    }
  }

  @action
  public async createPulseCampaignFromCopy(copyId: string, attempts: number = 0) {
    const maxAttempts = 20
    attempts = attempts + 1
    if (attempts === maxAttempts) {
      console.log('giving up')
      this.rootStore.appStore.router.push('/pulse/v1/campaigns')
      return
    }
    console.log('trying ' + attempts)
    const foundCampaign = await this.rootStore.pulseCampaignsStore.getFullRecord(copyId)
    if (!foundCampaign) {
      setTimeout(async () => await this.createPulseCampaignFromCopy(copyId, attempts++), 500)
      if (attempts === 13) console.log('pulse campaign not found: ' + copyId)
      return
    }
    const newPulseCampaign = foundCampaign.clone()
    newPulseCampaign.setObjectId(undefined)
    newPulseCampaign.setName(foundCampaign.name + ' (Copy)')
    newPulseCampaign.setStatus('draft')
    newPulseCampaign.surveys.forEach((survey) => (survey.status = 'scheduled'))
    this.editVM = new PulseCampaignEditVM(this.rootStore, newPulseCampaign, false)
  }

  @action
  public async createTemplateFromCopy(copyId: string, attempts: number = 0) {
    const maxAttempts = 20
    attempts = attempts + 1
    if (attempts === maxAttempts) {
      console.log('giving up')
      this.rootStore.appStore.router.push('/pulse/v1/campaigns')
      return
    }
    console.log('trying ' + attempts)
    const result: IPulseCampaignFindResult = await this.pulseCampaignsStore.getCampaignTemplates([
      copyId,
    ])
    const foundCampaign = result.pulseCampaigns[0]
    if (!foundCampaign) setTimeout(() => this.createTemplateFromCopy(copyId, attempts++), 500)
    const copiedCampaign = deserialize(PulseCampaign, foundCampaign)
    const newPulseCampaign = copiedCampaign.clone()
    newPulseCampaign.setObjectId(undefined)
    newPulseCampaign.setName(copiedCampaign.name + ' (Copy)')
    newPulseCampaign.setStatus('draft')
    this.editVM = new PulseCampaignEditVM(this.rootStore, newPulseCampaign, true)
  }

  @action
  public async loadTemplateEditVM(templateId: string, attempts: number = 0) {
    const maxAttempts = 20
    attempts = attempts + 1
    if (attempts === maxAttempts) {
      console.log('giving up')
      this.rootStore.appStore.router.push('/pulse/v1/campaigns')
      return
    }
    if (!this.rootStore.userStore.currentOrganization && process.env.NODE_ENV !== 'test') {
      setTimeout(() => this.loadTemplateEditVM(templateId, ++attempts), 500)
      return
    } else if (templateId === 'new') {
      return (this.editVM = new PulseCampaignEditVM(
        this.rootStore,
        PulseCampaign.create(this.rootStore.appStore.currentOrgId),
        true
      ))
    } else {
      const result: IPulseCampaignFindResult = await this.pulseCampaignsStore.findGlobalTemplates([
        templateId,
      ])
      const foundCampaign = result.pulseCampaigns[0]
      if (!foundCampaign) setTimeout(() => this.loadTemplateEditVM(templateId, attempts++), 500)
      else {
        const copiedCampaign = deserialize(PulseCampaign, foundCampaign)
        this.editVM = new PulseCampaignEditVM(this.rootStore, copiedCampaign, true)
      }
    }
  }

  @action
  public async loadPulseCampaignTemplatePreview(templateId: string, attempts: number = 0) {
    if (templateId === 'empty') this.editVM = null
    else if (templateId === 'new')
      return (this.editVM = new PulseCampaignEditVM(
        this.rootStore,
        PulseCampaign.create(this.rootStore.appStore.currentOrgId),
        true
      ))
    else {
      const maxAttempts = 20
      attempts = attempts + 1
      if (attempts === maxAttempts) {
        console.log('giving up')
        this.rootStore.appStore.router.push('/pulse/v1/campaigns')
        return
      }
      console.debug('trying ' + attempts)
      const result: IPulseCampaignFindResult = await this.pulseCampaignsStore.getCampaignTemplates([
        templateId,
      ])
      const foundCampaign = result.pulseCampaigns[0]
      if (!foundCampaign) {
        setTimeout(
          async () => await this.loadPulseCampaignTemplatePreview(templateId, attempts++),
          500
        )
        return
      }
      const newPulseCampaign = deserialize(PulseCampaign, foundCampaign)
      newPulseCampaign.setStartDate(new Date())
      newPulseCampaign.setEndDate(new Date())
      this.editVM.loadData(newPulseCampaign, false)
      this.editVM.resetDates()
    }
  }

  public async loadPulseQuestionEditVM(objectId: string, question?: PulseQuestion) {
    if (objectId === 'empty') return (this.editQuestionVM = null)
    if (objectId === 'new') {
      return (this.editQuestionVM = new PulseQuestionEditVM(this.rootStore, question.clone(), true))
    }
    return (this.editQuestionVM = new PulseQuestionEditVM(this.rootStore, question.clone(), true))
  }

  @action
  public async loadCampaignAnalysisEditVM(id: string, attempts: number = 0) {
    const maxAttempts = 20
    attempts = attempts + 1
    if (attempts === maxAttempts) {
      console.log('giving up')
      this.rootStore.appStore.router.push('/pulse/v1/campaigns')
      return
    }
    if (!this.rootStore.userStore.currentOrganization && process.env.NODE_ENV !== 'test') {
      setTimeout(() => this.loadCampaignAnalysisEditVM(id, ++attempts), 500)
      return
    }
    if (id === 'empty') this.campaignAnalysisEditVM = null
    else {
      const foundItem = await this.rootStore.pulseCampaignsStore.getFullRecord(id)
      if (!foundItem) setTimeout(() => this.loadCampaignAnalysisEditVM(id, attempts++), 500)
      else {
        const copiedCampaign = foundItem.clone()
        this.campaignAnalysisEditVM = new CampaignAnalysisEditVM(this.rootStore, copiedCampaign)
      }
    }
  }

  @computed
  public get isSurveyQuestionEditDialogOpen() {
    return Boolean(this.editQuestionVM)
  }
}
