import { RootStore } from '../../stores/RootStore'
import { observable, computed, action } from 'mobx'
import { UserGoal } from '../../user-goals/aggregate/UserGoal'
import { RowType } from '../types/RowType'
import moment from 'moment'
import { INotificationRow } from '../interfaces/INotificationRow'

export class GoalRowVM implements INotificationRow {
  private rootStore: RootStore

  constructor(rootStore: RootStore, goal: UserGoal) {
    this.rootStore = rootStore
    this.goal = goal
  }

  @observable public goal: UserGoal = null
  @observable public type: RowType = 'userGoal'
  @observable public buttonText: string = 'VIEW GOAL'

  @computed
  public get id(): string {
    return this.goal.objectId
  }

  @computed
  public get title(): string {
    return this.goal.name
  }

  @computed
  public get createdSeconds(): number {
    const now = new Date()
    const createdAt = new Date(this.goal.startDate)
    return (now.getTime() - createdAt.getTime()) / 1000
  }

  @computed
  public get createdAt(): string {
    return moment(this.goal.startDate).fromNow()
  }

  @computed
  public get dueDate(): string {
    const s = this.rootStore.localizationStore.lzStrings.goals
    return moment(this.goal.endDate).format(s.notification_end_date_format)
  }

  @computed
  public get hasDuedate(): boolean {
    if (!this.dueDate) return false
    return true
  }

  @action
  public onAction() {
    console.log('wont work until goal changes are merged in (different route name)')
    this.rootStore.appStore.router.push('/dashboard/impact/goals')
  }
}
