import React from 'react'
import { IndexRoute, Route } from 'react-router'
import Layout from '../../layout/Layout'
import { authenticate } from '../../auth/routes/AuthenticatedRoute'
import GoalEditScreen from '../../goals/views/edit/goal/GoalEditScreen'
import TrainingItemEditScreen from 'src/app/training-items/views/edit/TrainingItemEditScreen'

export default function getImpactRoutes() {
  return (
    <Route key={'impact0'} component={authenticate(Layout)} path='impact'>
      <IndexRoute onEnter={(nextState, replace) => replace('/dashboard/impact')} />
      <Route key={'impact1'} path='goal/:id' component={GoalEditScreen} />
      <Route key={'impact2'} path='training-item/:id' component={TrainingItemEditScreen} />
    </Route>
  )
}
