import React from 'react'
import { inject, observer } from 'mobx-react'
import {
  AppBar,
  Button,
  CssBaseline,
  Grid,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core'
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder'
import BookmarkIcon from '@material-ui/icons/Bookmark'
import CloseIcon from '@material-ui/icons/Close'
import ReplayIcon from '@material-ui/icons/Replay'
import EditRoundedIcon from '@material-ui/icons/EditRounded'
import rootStore from '../../stores/RootStore'
import { WorksheetsStore } from '../../worksheets/store/WorksheetsStore'
import { ContentVM } from '../view-models/ContentVM'
import '../../css/dashboard-toolbar.scss'
import './Visualizations.scss'

interface Props {
  worksheetsStore?: WorksheetsStore
  vm: ContentVM
}

const VisualizationReportViewerToolbar: React.FC<Props> = ({ worksheetsStore, vm }) => {
  if (!vm) return null

  const handleClose = () => {
    rootStore.appStore.router.push(`/userWorksheets`)
    vm.setOpen(false)
    vm.setSelectedItemVM(undefined)
  }

  return (
    <div id='DashboardToolbar' className={'dashboardToolbar-header'}>
      <CssBaseline />
      <AppBar style={{ position: 'relative' }}>
        <Toolbar className={'dashboard-toolbar'}>
          <Grid container direction='row' justifyContent='space-between' alignItems='center'>
            <Typography variant='h6'>
              {vm.selectedItemVM ? vm.selectedItemVM.worksheet.name : ''}
            </Typography>
            <div id='VizDialogRight'>
              {vm.canManage ? (
                <Tooltip
                  title={
                    rootStore.localizationStore.lzStrings.visualizations.edit_visualization_tooltip
                  }
                  placement='bottom-start'
                >
                  <span>
                    <Button
                      variant='contained'
                      onClick={() =>
                        rootStore.appStore.router.push(`/worksheets/edit/${vm.selectedItemVM.id}`)
                      }
                      size='small'
                      startIcon={<EditRoundedIcon />}
                    >
                      {rootStore.localizationStore.lzStrings.visualizations.edit_visualization}
                    </Button>
                  </span>
                </Tooltip>
              ) : undefined}
              {vm.isBookmarkEnabled && vm.canManage ? (
                <Tooltip
                  title={rootStore.localizationStore.lzStrings.visualizations.bookmark}
                  placement='bottom-start'
                >
                  <span>
                    <IconButton
                      className='viz-dialog-action'
                      edge='start'
                      color='inherit'
                      onClick={() => vm.selectedItemVM.setBookmark(!vm.selectedItemVM.isBookmarked)}
                      aria-label='close'
                    >
                      {vm.selectedItemVM && vm.selectedItemVM.isBookmarked ? (
                        <BookmarkIcon className='checked-icon-filled' />
                      ) : (
                        <BookmarkBorderIcon />
                      )}
                    </IconButton>
                  </span>
                </Tooltip>
              ) : undefined}
              {vm.selectedItemVM.canRevertAll && (
                <Tooltip
                  title={rootStore.localizationStore.lzStrings.visualizations.reset}
                  placement='bottom-start'
                >
                  <span>
                    <IconButton
                      className='viz-dialog-action'
                      edge='start'
                      color='inherit'
                      aria-label='close'
                      onClick={() => vm.selectedItemVM.revertAll()}
                    >
                      <ReplayIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              <Tooltip
                title={rootStore.localizationStore.lzStrings.visualizations.close}
                placement='bottom-start'
              >
                <span>
                  <IconButton
                    className='viz-dialog-action'
                    edge='start'
                    color='inherit'
                    onClick={handleClose}
                    aria-label='close'
                  >
                    <CloseIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default inject('worksheetsStore')(observer(VisualizationReportViewerToolbar))
