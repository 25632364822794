import env from '../../../env'

export class TableauScriptService {
  public static loadTableauScript(callback) {
    const existingScript = document.getElementById('tableau-js')
    if (!existingScript) {
      const script = document.createElement('script')
      script.src = env.var.REACT_APP_TABLEAU_SERVER_URL + '/javascripts/api/tableau-2.8.1.min.js'
      script.id = 'tableau-js'
      document.head.appendChild(script)
      script.onload = () => {
        if (callback) callback()
      }
    }
    if (existingScript && callback) callback()
  }
}
