import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  Select,
  MenuItem,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TasksStore } from '../../store/TasksStore'
import { AlarmVM } from '../../view-models/AlarmVM'

interface Props {
  localizationStore?: LocalizationStore
  tasksStore?: TasksStore
}

const DrawerAlarmsTab: React.FC<Props> = ({ localizationStore, tasksStore }) => {
  const { tasksManagementWidgetDrawer: strings } = localizationStore.lzStrings
  const { taskEditVM: vm } = tasksStore

  const renderRow = (row: AlarmVM, index: number) => {
    if (row.units === 0) return null
    return (
      <Grid item key={index}>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Grid item xs={2}>
            <Autocomplete
              className='units-input'
              disableClearable
              options={row.availableUnits.slice()}
              getOptionLabel={(option) => option}
              onChange={(e, value) => row.setUnits(value)}
              value={row.units.toString()}
              renderInput={(params) => (
                <TextField {...params} variant='outlined' fullWidth margin='none' />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl variant='outlined' fullWidth>
              <Select value={row.period} onChange={(e) => row.setPeriod(e.target.value.toString())}>
                {row.alarmOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {row.units === 1 ? option.substring(0, option.length - 1) : option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Typography variant='h6' className='alarm-text'>
              {strings.before_task_due}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={(e) => row.delete()} aria-label='Delete Alarm'>
              <CancelIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderRows = () => {
    return vm.alarms.map((e, idx) => renderRow(e, idx))
  }

  return (
    <>
      <Grid item xs={12}>
        <h6>{strings.reminders}</h6>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox checked={vm.onTimeAlarm} onChange={() => vm.handleOnTimeAlarmToggle()} />
          }
          label={
            <Typography variant='h6' className='alarm-text'>
              {strings.when_task_due}
            </Typography>
          }
        />
      </Grid>
      {renderRows()}
      <Grid item xs={12}>
        <Button className='add-reminder-button' onClick={() => vm.addAlarm()}>
          {strings.add_reminder}
        </Button>
      </Grid>
    </>
  )
}

export default inject('localizationStore', 'tasksStore')(observer(DrawerAlarmsTab))
