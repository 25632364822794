import React, { FC } from 'react'
import { observer, inject } from 'mobx-react'
import './SurveyOptions.scss'
import { SurveysStore } from 'src/app/surveys/store/SurveysStore'
import { Switch } from '@material-ui/core'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { LabelsStore } from '../../../../labels/store/LabelsStore'
import AdditionalResponseNotifications from './AdditionalResponseNotifications'

interface Props {
  surveysStore?: SurveysStore
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
}

const EmailOnResponse: FC<Props> = ({ surveysStore, localizationStore, labelsStore }) => {
  const surveyEditVM = surveysStore.viewModels.editVM
  const vm = surveyEditVM.surveyOptionsVM
  if (!surveyEditVM) return null
  if (!vm) return null
  const s = localizationStore.lzStrings.surveys

  const sendEmailToOwnerToggle = () => {
    return (
      <>
        <div className='primary-text'>
          {`${s.send_email_to} ${labelsStore.getLabelByLanguageAndFor('pulse')} ${
            s.owner_each_time
          }`}
        </div>
        <div className='section-content'>
          <FormControlLabel
            control={
              <Switch
                checked={surveyEditVM.sendEmailOnResponse}
                onChange={() => surveyEditVM.toggleSendEmailOnResponse()}
                color='primary'
              />
            }
            label={surveyEditVM.sendEmailOnResponse ? 'On' : 'Off'}
          />
        </div>
      </>
    )
  }

  const sendEmailToAdditionalToggle = () => {
    return (
      <>
        <div className='primary-text'>
          {`Send email to Additional Users each time a response is submitted`}
        </div>
        <div className='section-content'>
          <FormControlLabel
            control={
              <Switch
                checked={surveyEditVM.sendEmailToAdditionalUsersOnResponse}
                onChange={() => surveyEditVM.toggleSendEmailToAdditionalUsersOnResponse()}
                color='primary'
              />
            }
            label={surveyEditVM.sendEmailToAdditionalUsersOnResponse ? 'On' : 'Off'}
          />
        </div>
        {sendEmailToAdditional()}
      </>
    )
  }

  const sendEmailToAdditional = () => {
    if (!surveyEditVM.sendEmailToAdditionalUsersOnResponse) return
    return <AdditionalResponseNotifications />
  }

  return (
    <div className='section'>
      {sendEmailToOwnerToggle()}
      {sendEmailToAdditionalToggle()}
    </div>
  )
}

export default inject('surveysStore', 'localizationStore', 'labelsStore')(observer(EmailOnResponse))
