import {  computed, action } from 'mobx'
import { FileOpenService } from '../../upload/services/FileOpenService'
import { RootStore } from '../../stores/RootStore'
import { Attachment } from '../aggregate/Attachment'
import { AttachmentVM } from './AttachmentVM'

export class OldAttachmentVM extends AttachmentVM {
  constructor(rootStore: RootStore, attachment: Attachment, idx: number = 0) {
    super(rootStore, attachment, idx)
  }

  @computed
  public get isLoaded() {
    return true
  }

  @computed
  public get fileExtension(): string {
    if (!this.attachment) return ''
    if (!this.attachment.url) return ''
    const index = this.attachment.url.lastIndexOf('.')
    if (index < 0) return ''
    return this.attachment.url.substring(index, this.attachment.url.length)
  }

  @computed
  public get fileName(): string {
    if (!this.attachment) return null
    if (this.attachment.fileName.length > 50)
      return this.attachment.fileName.substring(0, 50) + '..' + this.fileExtension
    return this.attachment.fileName
  }

  @computed
  public get shorterFileName(): string {
    if (!this.attachment) return null
    if (this.attachment.fileName.length > 30)
      return this.attachment.fileName.substring(0, 30) + '..' + this.fileExtension
    return this.attachment.fileName
  }

  @computed
  public get thumbnailUrl(): string {
    let url = this.attachment.thumbnail
    if (!url) return ''
    return url
  }

  @computed
  public get url(): string {
    let url = this.attachment.url
    if (!url) return ''
    return url
  }

  @computed
  public get isVideo() {
    if (!this.attachment) return null
    if (this.type === 'mp4') return true
    if (this.type === 'mov') return true
    if (this.type === 'video') return true
    return false
  }

  @computed
  public get isImage() {
    if (!this.attachment) return null
    if (this.type === 'png') return true
    if (this.type === 'jpg') return true
    if (this.type === 'image') return true
    if (this.type === 'gif') return true
    return false
  }

  @computed
  public get type() {
    if (!this.attachment) return null
    if (!this.attachment.type) return this.fileExtension.substring(1, this.attachment.url.length)
    return this.attachment.type
  }

  @computed
  public get isCMSItem(): boolean {
    return false
  }

  @action
  public async openAttachment() {
    if (!this.attachment) return null
    const svc = new FileOpenService(this.rootStore)
    await svc.openFile(this.attachment)
  }

  @computed
  public get hasYouTubeURL(): boolean {
    const url = this.url.toLowerCase()
    return url.includes('youtube') || url.includes('youtu.be')
  }

  @computed
  public get hasVimeoURL(): boolean {
    const url = this.url.toLowerCase()
    return url.includes('vimeo')
  }

  @computed
  public get youTubeId(): string {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
    const match = this.url.match(regExp)
    return match && match[2].length === 11 ? match[2] : null
  }

  @computed
  public get vimeoId(): string {
    let regExp = /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/
    if (this.url.includes('player.vimeo.com')) {
      regExp = /(http|https)?:\/\/(www\.)?player.vimeo.com\/(?:video\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/
    }
    const match = this.url.match(regExp)
    return match && match.length ? match[4] : ''
  }

  @computed
  public get embedUrl(): string {
    if (!this.hasYouTubeURL && !this.hasVimeoURL) return ''
    if (this.hasYouTubeURL) return `https://www.youtube.com/embed/${this.youTubeId}`
    else if (this.hasVimeoURL) return `https://player.vimeo.com/video/${this.vimeoId}`
    return ''
  }

  @computed
  public get hasEmbedUrl(): boolean {
    return Boolean(this.embedUrl)
  }
}
