import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputLabel,
    Modal,
    Paper,
    Typography,
  } from '@material-ui/core'
  import AddIcon from '@material-ui/icons/Add'
  import { inject, observer } from 'mobx-react'
  import React from 'react'
  import ParticipantsSelect from '../../../participants-select/views/ParticipantsSelect'
  import CloseIcon from '@material-ui/icons/Close'
  import './UserSelectModal.scss'
import { UserSelectModalVM } from '../../view-models/import-records/UserSelectModalVM'
import Avatar from '../../../shared/Avatar'
  
  interface Props {
    disabled: boolean
    displayName: string
    required: boolean
    id: string
    onChange
    vm: UserSelectModalVM,
    value: any
  }
  
  const UserSelectModal: React.FC<Props> = ({ disabled, displayName, required, id, onChange, vm, value }) => {
    vm.user = value
    const renderIcon = () => {
      if (vm.user) {
        return (
          <div className='add-icon-container'>
            <Avatar user={vm.user} />
          </div>
        )
      } else {
        return (
          <AddIcon />
        )
      }
    }

    const renderDisplay = () => {
      if (vm.user) {
        return (
          <h6 className={vm.user ? 'add-participant-text filled' : 'add-participant-text'}>
            {vm.user.name}
          </h6>
        )
      } else {
        return (
          <h6 className='add-participant-text'>{'Select ' + displayName }</h6>
        )
      }
    }

    return (
      <div id='UserSelectModal'>
        <InputLabel required={required}>
            {displayName}
        </InputLabel>
        <Paper
          id={id} 
          aria-disabled={disabled}
          elevation={1}
          onClick={() => {if (!disabled) vm.toggleUserSelectOpen()}}
        >
          <Grid container direction='row' className={disabled ? 'audience-container-disabled' : 'audience-container'}>
            <Grid item className='avatar-container'>
              <div className='add-icon-container'>
                {renderIcon()}
              </div>
            </Grid>
            <Grid item className='name-container'>
              {renderDisplay()}
            </Grid>
          </Grid>
        </Paper>
        <Dialog
          open={vm.userSelectModalOpen}
          id='UserSelectModalDialog'
        >
          <DialogTitle>
            <Grid className='modal-title' container justifyContent='space-between' alignItems='center'>
              <Grid item>{'Select ' + displayName}</Grid>
              <Grid item>
                <IconButton onClick={() => vm.toggleUserSelectOpen()}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent className='trainer-content'>
            <ParticipantsSelect participantsSelectVM={vm.userSelectVM} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => vm.toggleUserSelectOpen()}>CANCEL</Button>
            <Button variant='contained' onClick={() => {
              vm.setUser()
              onChange(vm.user)
            }}>
              Select
            </Button>
          </DialogActions>
        </Dialog>
        <p>{vm.userSelectModalOpen}</p>
      </div>
    )
  }

  export default inject('rootStore')(observer(UserSelectModal))