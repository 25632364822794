import React from 'react'
import { inject, observer } from 'mobx-react'
import { Grid, IconButton, Typography } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { PulseSetRowVM } from '../../view-models/manage/PulseSetRowVM'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ChevronDownIcon from '@material-ui/icons/ExpandMore'
import './PulseSetRow.scss'
import PulseSetRowDetails from './PulseSetRowDetails'

interface Props {
  vm: PulseSetRowVM
}

const PulseSetRow: React.FC<Props> = ({ vm }) => {
  if (!vm) return null

  const renderExpandIcon = () => {
    let icon = <ChevronRightIcon className={'expand-icon'} />
    if (vm.isExpanded) icon = <ChevronDownIcon className={'expand-icon'} />
    return (
      <IconButton
        disableRipple
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          vm.toggleIsExpanded()
        }
        }
        className={'icon-container'}
      >
        {icon}
      </IconButton >
    )
  }

  const renderRow = () => {
    return (
      <Grid onClick={() => vm.toggleIsExpanded()} className={`pulse-set-row`} item xs={12}>
        <div className={'pulse-set-title'}>
          {renderExpandIcon()}
          <Typography className='pulse-set-name'>{vm.name}</Typography>
        </div>
        <div>
          <IconButton
            size='small'
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              vm.editPulseSet()
            }
            }
          >
            <EditIcon />
          </IconButton>
        </div>
      </Grid>
    )
  }

  const renderDetails = () => {
    return <PulseSetRowDetails vm={vm} />
  }

  return (
    <div id={'PulseSetRow'}>
      {renderRow()}
      {renderDetails()}
    </div>
  )
}

export default inject()(observer(PulseSetRow))
