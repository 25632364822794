import React from 'react'
import { observer, inject } from 'mobx-react'
import { Dialog, DialogTitle, DialogContent, Grid, Button } from '@material-ui/core'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import { ActiveUserSurveysTabVM } from '../../../view-models/my-surveys-widget/ActiveUserSurveysTabVM'
import MuiConfirmPrompt from '../../../../shared/MuiConfirmPrompt'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'

interface Props {
  vm: ActiveUserSurveysTabVM
  localizationStore?: LocalizationStore
}

const InactiveDialog: React.FC<Props> = ({ vm, localizationStore }) => {
  if (!vm.showInactiveDialog) return null
  const lz = localizationStore.lzStrings.surveyInvitationsList

  const renderMessage = () => {
    if (vm.inactiveProcessing) return lz.processing
    return (
      <>
        <span>{lz.survey_as_inactive}</span>
      </>
    )
  }

  const renderContent = () => {
    return (
      <MuiConfirmPrompt
        confirmMessage={renderMessage()}
        confirmMessageSecondary={lz.this_action_cannot}
        onClose={() => vm.toggleInactiveDialog()}
        open={vm.showInactiveDialog}
        onConfirm={() => vm.setUserSurveyInactive()}
        title='Set Survey Inactive'
        disabled={vm.inactiveProcessing}
        icon={<WarningRoundedIcon className='warning-icon' />}
      />
    )
    // return (
    //   <>
    //     <DialogTitle className='MuiConfirmDialogTitle'>{renderMessage()}</DialogTitle>
    //     <DialogContent className='MuiConfirmDialogContent'>
    //       <Grid container justifyContent='center'>
    //         <Button
    //           className='cancelButton'
    //           disabled={vm.inactiveProcessing}
    //           onClick={() => vm.toggleInactiveDialog()}
    //         >
    //           {lz.no_nevermind}
    //         </Button>
    //         <Button
    //           className='confirmButton'
    //           disabled={vm.inactiveProcessing}
    //           onClick={() => vm.setUserSurveyInactive()}
    //         >
    //           {lz.yes_confirm}
    //         </Button>
    //       </Grid>
    //     </DialogContent>
    //   </>
    // )
  }

  return (
    <Dialog onClose={() => vm.toggleInactiveDialog()} open={vm.showInactiveDialog}>
      {renderContent()}
    </Dialog>
  )
}

export default inject('localizationStore')(observer(InactiveDialog))
