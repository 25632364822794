import Parse from 'parse'
import { IAnnouncementParticipantRequest } from '../interfaces/IAnnouncementParticipantsRequest'
import { IAnnouncementParticipantFindResult } from '../interfaces/IAnnouncementParticipantResult'
import { IAnnouncementListFindRequest } from '../interfaces/IAnnouncementListFindRequest'
import { IAnnouncementListFindResult } from '../interfaces/IAnnouncementListFindResult'
import { IAnnouncementFindOwnersResult } from '../interfaces/IAnnouncementFindOwnersResult'

export class AnnouncementsService {
  public async saveAnnouncement(announcement, orgId, sendNotifications) {
    return await Parse.Cloud.run('saveAnnouncement', { announcement, orgId, sendNotifications })
  }

  public async deleteAnnouncement(id, orgId) {
    await Parse.Cloud.run('deleteAnnouncement', { id, orgId })
  }

  public async markUserAnnouncementAsRead(id, orgId) {
    await Parse.Cloud.run('markUserAnnouncementAsRead', { id, orgId })
  }

  public async toggleLikeAnnouncement(announcementId, userId) {
    await Parse.Cloud.run('toggleLikeAnnouncement', { announcementId, userId })
  }

  public async findAnnouncementParticipants(
    request: IAnnouncementParticipantRequest
  ): Promise<IAnnouncementParticipantFindResult> {
    return await Parse.Cloud.run('findAnnouncementParticipants', { request })
  }

  public async findAnnouncementParticipantsAdmin(
    request: IAnnouncementParticipantRequest
  ): Promise<IAnnouncementParticipantFindResult> {
    return await Parse.Cloud.run('findAnnouncementParticipantsAdmin', { request })
  }

  public async findAnnouncements(
    request: IAnnouncementListFindRequest
  ): Promise<IAnnouncementListFindResult> {
    return await Parse.Cloud.run('findAnnouncements', { request })
  }

  public async findAnnouncementOwners(organizationId): Promise<IAnnouncementFindOwnersResult> {
    return await Parse.Cloud.run('findAnnouncementOwners', { organizationId })
  }
}
