import React, { FC, useRef } from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../../localization/LocalizationStore'
import clsx from 'clsx'
import Button from '@material-ui/core/Button'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import EditIcon from '@material-ui/icons/Edit'
import ShareIcon from '@material-ui/icons/Share'
import ExpandIcon from '@material-ui/icons/ExpandMoreRounded'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import CollapseIcon from '@material-ui/icons/ExpandLessRounded'
import DashboardDropdown from './DashboardDropdown'
import './UserDashboardHeader.scss'
import { CssBaseline, AppBar, Toolbar } from '@material-ui/core'
import isIE11 from '../../../../../utils/isIE11'
import { UserDashboardsStore } from '../../UserDashboardsStore'

interface UserDashboardHeaderProps {
  localizationStore?: LocalizationStore
  userDashboardsStore?: UserDashboardsStore
}

const UserDashboardHeader: FC<UserDashboardHeaderProps> = ({
  localizationStore,
  userDashboardsStore
}) => {
  const strings = localizationStore.lzStrings.userDashboards
  const vmSvc = userDashboardsStore.viewModels
  const vm = vmSvc.userDashboardVM
  const anchorRef = useRef()

  const renderUserDashboardHeader = () => {
    return (
      <div className='dashboard-header'>
        <div className='dashboard-dropdown'>
          <ListItem
            button
            className={clsx('list-button', { 'is-Menu-Open': vm.showMenu })}
            ref={anchorRef}
            onClick={() => vm.toggleShowMenu()}
          >
            <Typography variant='inherit' noWrap>
              {vm.name}
            </Typography>
            {vm.showMenu ? <CollapseIcon /> : <ExpandIcon />}
          </ListItem>
          <DashboardDropdown anchorRef={anchorRef} />
        </div>
        <div className='dashboard-action'>
          {vm.isEditable && (
            <Tooltip title='Share dashboard' placement='bottom'>
              <span>
                <Button
                  disabled={Boolean(!vm.userDashboard)}
                  variant='outlined'
                  className='btn-icon btn-icon-white'
                  onClick={() => vm.showSharedWithDrawer()}
                  size='small'
                >
                  <ShareIcon />
                </Button>
              </span>
            </Tooltip>
          )}
          <Tooltip title='Clone dashboard' placement='bottom'>
            <span>
              <Button
                disabled={Boolean(!vm.userDashboard)}
                variant='outlined'
                className='btn-icon btn-icon-white'
                onClick={() => vm.cloneDashboard()}
                size='small'
              >
                <FileCopyOutlinedIcon />
              </Button>
            </span>
          </Tooltip>
          <Tooltip title='Add dashboard to favorites' placement='bottom'>
            <span>
              <Button
                disabled={Boolean(!vm.userDashboard)}
                variant='outlined'
                className={vm.isFavorite ? ' btn-icon btn-isFaved' : 'btn-icon btn-fave'}
                size='small'
                onClick={() => vm.toggleIsFavorite()}
              >
                {vm.isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
              </Button>
            </span>
          </Tooltip>

          {vm.isEditable && (
            <Tooltip title='Edit dashboard' placement='bottom'>
              <span>
                <Button
                  disabled={Boolean(!vm.userDashboard)}
                  variant='outlined'
                  className='btn-icon btn-icon-white'
                  onClick={() => vm.editDashboard()}
                  size='small'
                >
                  <EditIcon />
                </Button>
              </span>
            </Tooltip>
          )}

          {vm.isEditable && (
            <Tooltip title='Create new dashboard' placement='bottom'>
              <span>
                <Button
                  disabled={Boolean(!vm.userDashboard)}
                  variant='outlined'
                  aria-label='add dashboard'
                  className={'btn-icon'}
                  onClick={() => vm.createDashboard()}
                >
                  New Dashboard
              </Button>
              </span>
            </Tooltip>
          )}
          {vm.isEditable && (
            <Tooltip title='Add widget to dashboard' placement='bottom'>
              <span>
                <Button
                  className={'btn-icon'}
                  variant='contained'
                  disabled={Boolean(!vm.userDashboard)}
                  onClick={() => vm.openWidgetDrawer()}
                >
                  Add Widget
              </Button>
              </span>
            </Tooltip>
          )}
        </div>
      </div>
    )
  }

  return (
    <div id='UserDashboardHeaderContainer'>
      <div id='DashboardToolbar'>
        <CssBaseline />
        <AppBar
          id='AppBar'
          className={clsx('dashboard-appbar', {
            'no-transition': isIE11,
          })}
          elevation={0}
        >
          <Toolbar
            className={clsx('dashboard-toolbar')}
          >
            {renderUserDashboardHeader()}
          </Toolbar>
        </AppBar>
        <Toolbar />
      </div>
    </div>
  )
}

export default inject('userDashboardsStore', 'localizationStore')(observer(UserDashboardHeader))
