import { RootStore } from '../../stores/RootStore'
import Parse from 'parse'

export class RolesUpdateService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public async saveRole(orgId: string, role: any) {
    const data = await Parse.Cloud.run('saveRole', { orgId, role })
    return data
  }

  public async updateRoleMembers(
    orgId: string,
    roleId: string,
    userIdsToAdd: Array<string>,
    userIdsToRemove: Array<string>
  ) {
    const result = await Parse.Cloud.run('updateRoleMembers', {
      orgId,
      roleId,
      userIdsToAdd,
      userIdsToRemove,
    })
    return result
  }

  public async deleteRole(roleId: any) {
    const orgId = this.rootStore.appStore.currentOrgId
    const data = await Parse.Cloud.run('deleteRole', { orgId, roleId })
    return data
  }

  public async refreshEvents(roleId: string) {
    const orgId = this.rootStore.appStore.currentOrgId
    await Parse.Cloud.run('refreshEventsForRole', { orgId, roleId })
  }
}
