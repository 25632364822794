import React from 'react'
import rootStore from '../../../../stores/RootStore'
import './GoalEditScreen.scss'
import TopToolbar from './TopToolbar'
import Tabs from './TabsBar'
import TrainingPlansModal from '../training-plans-modal/TrainingPlansModal'

export default class GoalEditScreen extends React.Component<any, any> {
  constructor(props) {
    super(props)

    if (props.params.id && props.params.id !== 'new') {
      rootStore.goalsStore.editGoal(props.params.id)
    }
    if (props.params.id && props.params.id === 'new') {
      rootStore.goalsStore.createNewGoal()
    }
    if (props.params.assignCatalogId) {
      rootStore.goalsStore.createGoalFromCatalog(props.params.assignCatalogId)
    }
    if (props.params.catalogId && props.params.catalogId === 'new') {
      rootStore.goalsCatalogStore.createNewCatalogGoal()
    }
    if (props.params.catalogId && props.params.catalogId !== 'new') {
      rootStore.goalsCatalogStore.editCatalogGoal(props.params.catalogId)
    }
  }

  routerWillLeave(nextLocation) {
    if (!rootStore.goalsStore.goalEditVM) return
    if (rootStore.goalsStore.goalEditVM.isDirty) {
      return 'You have unsaved information, are you sure you want to leave this page?'
    }
  }

  componentDidMount() {
    this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave.bind(this))
  }

  render() {
    if (!rootStore.goalsStore) return null
    return (
      <div id='GoalEditScreen'>
        <TopToolbar />
        <Tabs />
        <TrainingPlansModal />
      </div>
    )
  }
}
