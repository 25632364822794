import React, { FC } from 'react'
import { inject, observer, Observer } from 'mobx-react'
import VirtualList from 'react-tiny-virtual-list'
import { RolesStore } from '../../../roles/store/RolesStore'
import { GroupsStore } from '../../../groups/store/GroupsStore'
import Searchbar from '../../../shared/Searchbar'
import MyAvatar from '../../../shared/Avatar'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import AddIcon from '@material-ui/icons/Add'
import IconButton from '@material-ui/core/IconButton'
import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'
import LocalizationStore from '../../../localization/LocalizationStore'
import './UserOrganizationTab.scss'
import { OrganizationUsersStore } from '../../store/OrganizationUsersStore'
import ArchivedLabel from '../../../shared/ArchivedLabel'
import styles from '../../../shared/Avatar.scss'
export interface Props {
  organizationUsersStore?: OrganizationUsersStore
  rolesStore?: RolesStore
  groupsStore?: GroupsStore
  localizationStore?: LocalizationStore
}

const UserOrganizationTab: FC<Props> = ({
  organizationUsersStore,
  rolesStore,
  groupsStore,
  localizationStore,
}) => {
  const { editVM } = organizationUsersStore
  const { lzStrings } = localizationStore
  const rolesList = editVM.availableRoles
  const groupsList = editVM.availableGroups
  const superiorsList = editVM.availableSuperiors
  const subordinatesList = editVM.availableSubordinates

  const renderSearchbarRoles = () => {
    if (!editVM.isEditable || !editVM.showRoles) return null

    return (
      <Searchbar
        className='searchbar'
        focused={editVM.roleFilter.length > 0}
        searchValue={editVM.roleFilter}
        onSearchChange={(e) => (editVM.roleFilter = e.target.value)}
        containerStyle={{
          marginRight: 8,
        }}
      />
    )
  }

  const renderSearchbarGroups = () => {
    if (!editVM.isEditable || !editVM.showGroups) return null

    return (
      <Searchbar
        className='searchBar'
        focused={editVM.groupFilter.length > 0}
        searchValue={editVM.groupFilter}
        onSearchChange={(e) => (editVM.groupFilter = e.target.value)}
        containerStyle={{
          marginRight: 8,
        }}
      />
    )
  }

  const roles = (
    <Paper className='paper'>
      <List dense className='list'>
        <ListItem key='roles-collapse' className='collapse-item'>
          <ListItemText primary={lzStrings.userEdit.roles} className='list-header' />
          <Typography color='inherit' className='typography'>
            {editVM.roles.length} / {rolesStore.roles.length} selected
          </Typography>
          {renderSearchbarRoles()}
          {!editVM.isEditable ? null : editVM.showRoles ? (
            <IconButton
              className='btn-icon-cta'
              size='small'
              style={{ margin: 'auto 0.5rem' }}
              onClick={() => editVM.toggleRolesCollapse()}
            >
              <ExpandLess />
            </IconButton>
          ) : (
            <IconButton
              disabled={!editVM.isOrgAdmin && !editVM.isEditable}
              className='btn-icon-cta'
              size='small'
              style={{ margin: 'auto 0.5rem' }}
              onClick={() => editVM.toggleRolesCollapse()}
            >
              <AddIcon />
            </IconButton>
          )}
        </ListItem>
        {editVM.selectedRoles.length > 0 && (
          <Grid container className='chips-grid'>
            {editVM.selectedRoles.map((role, index) => (
              <Grid item key={'selected-role-' + index}>
                <Chip
                  onDelete={
                    !editVM.isEditable ? null : () => editVM.toggleRoleChecked(role.objectId)
                  }
                  className='chip'
                  label={role.name}
                  size='small'
                />
              </Grid>
            ))}
          </Grid>
        )}
        {!editVM.isEditable ? null : (
          <Collapse in={editVM.showRoles} timeout='auto' unmountOnExit>
            <List component='div' dense>
              <VirtualList
                width='100%'
                height={200}
                itemCount={rolesList.length}
                itemSize={36}
                renderItem={({ index, style }) => (
                  <Observer key={rolesList[index].objectId}>
                    {() => (
                      <ListItem style={style} key={'rld' + index}>
                        <ListItemIcon>
                          <Checkbox
                            onChange={() => editVM.toggleRoleChecked(rolesList[index].objectId)}
                            checked={editVM.roles.includes(rolesList[index].objectId)}
                          />
                        </ListItemIcon>
                        <ListItemText primary={rolesList[index].name} />
                      </ListItem>
                    )}
                  </Observer>
                )}
              />
            </List>
          </Collapse>
        )}
      </List>
    </Paper>
  )

  const groups = (
    <Paper className='paper'>
      <List dense className='list'>
        <ListItem key='groups-collapse' className='collapse-item'>
          <ListItemText primary={lzStrings.userEdit.groups} className='list-header' />
          <Typography color='inherit' className='typography'>
            {editVM.groups.length} / {groupsStore.groups.length} selected
          </Typography>
          {renderSearchbarGroups()}
          {!editVM.isEditable ? null : editVM.showGroups ? (
            <IconButton
              className='btn-icon-cta'
              size='small'
              style={{ margin: 'auto 0.5rem' }}
              onClick={() => editVM.toggleGroupsCollapse()}
            >
              <ExpandLess />
            </IconButton>
          ) : (
            <IconButton
              disabled={!editVM.isOrgAdmin && !editVM.isEditable}
              className='btn-icon-cta'
              size='small'
              style={{ margin: 'auto 0.5rem' }}
              onClick={() => editVM.toggleGroupsCollapse()}
            >
              <AddIcon />
            </IconButton>
          )}
        </ListItem>
        {editVM.selectedGroups.length > 0 && (
          <Grid container className='chips-grid'>
            {editVM.selectedGroups.map((group, index) => (
              <Grid item key={'selected-group-' + index}>
                <Chip
                  onDelete={
                    !editVM.isEditable ? null : () => editVM.toggleGroupChecked(group.objectId)
                  }
                  className='chip'
                  label={group.name}
                  size='small'
                />
              </Grid>
            ))}
          </Grid>
        )}
        {!editVM.isEditable ? null : (
          <Collapse in={editVM.showGroups} timeout='auto' unmountOnExit>
            <List component='div' dense>
              <VirtualList
                width='100%'
                height={200}
                itemCount={groupsList.length}
                itemSize={36}
                renderItem={({ index, style }) => (
                  <Observer key={groupsList[index].objectId}>
                    {() => (
                      <ListItem style={style} key={'rld' + index}>
                        <ListItemIcon>
                          <Checkbox
                            onChange={() => editVM.toggleGroupChecked(groupsList[index].objectId)}
                            checked={editVM.groups.includes(groupsList[index].objectId)}
                          />
                        </ListItemIcon>
                        <ListItemText primary={groupsList[index].name} />
                      </ListItem>
                    )}
                  </Observer>
                )}
              />
            </List>
          </Collapse>
        )}
      </List>
    </Paper>
  )

  const superiors = (
    <Paper className='paper'>
      <List dense className='list'>
        <ListItem key='superiors-collapse' className='collapse-item'>
          <ListItemText primary={lzStrings.userEdit.managers} className='list-header' />
          {!editVM.isEditable
            ? null
            : editVM.showSuperiors && (
                <Searchbar
                  focused={editVM.superiorFilter.length > 0}
                  searchValue={editVM.superiorFilter}
                  onSearchChange={(e) => (editVM.superiorFilter = e.target.value)}
                  containerStyle={{
                    marginRight: 8,
                  }}
                />
              )}
          {editVM.superiors.length > 0 && !editVM.showSuperiors && (
            <Typography color='inherit' className='typography'>
              {editVM.superiors.length} selected
            </Typography>
          )}
          {!editVM.isEditable ? null : editVM.showSuperiors ? (
            <IconButton
              className='btn-icon-cta'
              size='small'
              style={{ margin: 'auto 0.5rem' }}
              onClick={() => editVM.toggleSuperiorsCollapse()}
            >
              <ExpandLess />
            </IconButton>
          ) : (
            <IconButton
              className='btn-icon-cta'
              size='small'
              style={{ margin: 'auto 0.5rem' }}
              onClick={() => editVM.toggleSuperiorsCollapse()}
            >
              <AddIcon />
            </IconButton>
          )}
        </ListItem>
        {editVM.selectedSuperiorUsers.length > 0 && (
          <Grid container className='chips-grid'>
            {editVM.selectedSuperiorUsers.map((user, index) => (
              <Grid item key={'selected-superior-' + index}>
                <Chip
                  onDelete={
                    !editVM.isEditable ? null : () => editVM.toggleSuperiorChecked(user.objectId)
                  }
                  className='chip'
                  label={
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        alignItems: 'center',
                      }}
                    >
                      <span style={user.isArchived ? { fontStyle: styles.archivedFontStyle } : {}}>
                        {user.name}
                      </span>
                      {user.isArchived ? <ArchivedLabel /> : undefined}
                    </div>
                  }
                  size='small'
                  avatar={
                    <MyAvatar
                      user={user}
                      size={20}
                      style={{ fontSize: '1rem', marginLeft: '0.5rem', marginRight: '-0.5rem' }}
                      showOnlyFirstInitial
                      isArchived={user.isArchived}
                    />
                  }
                />
              </Grid>
            ))}
          </Grid>
        )}
        {!editVM.isEditable ? null : (
          <Collapse in={editVM.showSuperiors} timeout='auto' unmountOnExit>
            <List component='div' dense>
              <VirtualList
                width='100%'
                height={200}
                itemCount={superiorsList.length}
                itemSize={36}
                renderItem={({ index, style }) => (
                  <Observer key={superiorsList[index].objectId}>
                    {() => (
                      <ListItem style={style} key={'rld' + index}>
                        <ListItemIcon>
                          <Checkbox
                            onChange={() =>
                              editVM.toggleSuperiorChecked(superiorsList[index].objectId)
                            }
                            checked={editVM.superiors.includes(superiorsList[index].objectId)}
                          />
                        </ListItemIcon>
                        <MyAvatar
                          user={superiorsList[index]}
                          size={20}
                          style={{ marginRight: '1rem', fontSize: '1rem' }}
                          showOnlyFirstInitial
                        />
                        <ListItemText primary={superiorsList[index].name} />
                      </ListItem>
                    )}
                  </Observer>
                )}
              />
            </List>
          </Collapse>
        )}
      </List>
    </Paper>
  )

  const subordinates = (
    <Paper className='paper'>
      <List dense className='list'>
        <ListItem key='subordinates-collapse' className='collapse-item'>
          <ListItemText primary={lzStrings.userEdit.direct_reports} className='list-header' />
          {!editVM.isEditable
            ? null
            : editVM.showSubordinates && (
                <Searchbar
                  focused={editVM.subordinateFilter.length > 0}
                  searchValue={editVM.subordinateFilter}
                  onSearchChange={(e) => (editVM.subordinateFilter = e.target.value)}
                  containerStyle={{
                    marginRight: 8,
                  }}
                />
              )}
          {editVM.subordinates.length > 0 && !editVM.showSubordinates && (
            <Typography color='inherit' className='typography'>
              {editVM.subordinates.length} selected
            </Typography>
          )}
          {!editVM.isEditable ? null : editVM.showSubordinates ? (
            <IconButton
              className='btn-icon-cta'
              size='small'
              style={{ margin: 'auto 0.5rem' }}
              onClick={() => editVM.toggleSubordinatesCollapse()}
            >
              <ExpandLess />
            </IconButton>
          ) : (
            <IconButton
              className='btn-icon-cta'
              size='small'
              style={{ margin: 'auto 0.5rem' }}
              onClick={() => editVM.toggleSubordinatesCollapse()}
            >
              <AddIcon />
            </IconButton>
          )}
        </ListItem>
        {editVM.selectedSubordinateUsers.length > 0 && (
          <Grid container className='chips-grid'>
            {editVM.selectedSubordinateUsers.map((user, index) => (
              <Grid item key={'selected-subordinate-' + index}>
                <Chip
                  onDelete={
                    !editVM.isEditable ? null : () => editVM.toggleSubordinateChecked(user.objectId)
                  }
                  className='chip'
                  label={
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        alignItems: 'center',
                      }}
                    >
                      <span style={user.isArchived ? { fontStyle: styles.archivedFontStyle } : {}}>
                        {user.name}
                      </span>
                      {user.isArchived ? <ArchivedLabel /> : undefined}
                    </div>
                  }
                  size='small'
                  avatar={
                    <MyAvatar
                      user={user}
                      size={20}
                      style={{ fontSize: '1rem', marginLeft: '0.5rem', marginRight: '-0.5rem' }}
                      showOnlyFirstInitial
                      isArchived={user.isArchived}
                    />
                  }
                />
              </Grid>
            ))}
          </Grid>
        )}
        {!editVM.isEditable ? null : (
          <Collapse in={editVM.showSubordinates} timeout='auto' unmountOnExit>
            <List component='div' dense>
              <VirtualList
                width='100%'
                height={200}
                itemCount={subordinatesList.length}
                itemSize={36}
                renderItem={({ index, style }) => (
                  <Observer key={subordinatesList[index].objectId}>
                    {() => (
                      <ListItem style={style} key={'rld' + index}>
                        <ListItemIcon>
                          <Checkbox
                            onChange={() =>
                              editVM.toggleSubordinateChecked(subordinatesList[index].objectId)
                            }
                            checked={editVM.subordinates.includes(subordinatesList[index].objectId)}
                          />
                        </ListItemIcon>
                        <MyAvatar
                          user={subordinatesList[index]}
                          size={20}
                          style={{ marginRight: '1rem', fontSize: '1rem' }}
                          showOnlyFirstInitial
                        />
                        <ListItemText primary={subordinatesList[index].name} />
                      </ListItem>
                    )}
                  </Observer>
                )}
              />
            </List>
          </Collapse>
        )}
      </List>
    </Paper>
  )

  return (
    <div id='UserOrganizationTab'>
      {roles}
      {groups}
      {superiors}
      {subordinates}
    </div>
  )
}

export default inject(
  'organizationUsersStore',
  'rolesStore',
  'groupsStore',
  'localizationStore'
)(observer(UserOrganizationTab))
