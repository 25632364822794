import { observable, action } from 'mobx'
import { serializable, serialize } from 'serializr'
import { IAggregate } from '../../../shared/data/IAggregate'
import { IResourceDTO } from '../dtos/IResourceDTO'

export default class Resource implements IResourceDTO, IAggregate {
  static create(orgId) {
    const resource = new Resource()
    resource.organizationId = orgId
    return resource
  }

  @serializable @observable objectId: string = ''
  @serializable @observable name: string = ''
  @serializable @observable organizationId: string = ''
  @serializable @observable description: string = ''
  @serializable @observable isProcessing: boolean = false
  @serializable @observable isDeleted: boolean = false
  isOnServer: boolean = false

  markIsNotOnServer() {
    this.isOnServer = false
  }

  markIsOnServer() {
    this.isOnServer = true
  }

  @action
  markIsNotProcessing() {
    this.isProcessing = false
  }

  markIsProcessing() {
    this.isProcessing = true
  }

  public serialize(): IResourceDTO {
    return serialize(this)
  }
}
