import React from 'react'
import clsx from 'clsx'
import { inject, observer } from 'mobx-react'
import { ButtonBase } from '@material-ui/core'
import LocalizationStore from '../../../../localization/LocalizationStore'
import CatalogItemsWidgetVM from '../../../view-models/widgets/item-catalog-widget/CatalogItemsWidgetVM'

interface WidgetTitleProps {
  localizationStore?: LocalizationStore
  vm: CatalogItemsWidgetVM
}

const WidgetTitle: React.FC<WidgetTitleProps> = ({ localizationStore, vm }) => {
  const { training_items: lz } = localizationStore.lzStrings

  return (
    <div className='widget-title-tabs'>
      <span>{lz.catalog_items}</span>
    </div>
  )
}

export default inject('localizationStore')(observer(WidgetTitle))
