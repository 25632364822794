import React from 'react'
import { observer } from 'mobx-react'
import rootStore from '../../../stores/RootStore'
import TopToolbar from './TopToolbar'
import './CustomCollectionEditScreen.scss'
import TabsBar from './TabsBar'

@observer
export default class CustomCollectionEditScreen extends React.Component<any, any> {
  constructor(props) {
    super(props)
    rootStore.customCollectionsStore.lazyLoadEditVM(props.params.id)
  }

  componentWillUnmount() {
    rootStore.customCollectionsStore.lazyLoadEditVM('empty')
  }

  render() {
    return (
      <div id='CustomCollectionEditScreen'>
        <TopToolbar />
        <TabsBar />
      </div>
    )
  }
}
