import { observable, action, computed } from 'mobx'
import { FileUploadService } from '../services/FileUploadService'
import { Attachment } from '../aggregate/Attachment'
import { CloudinaryResult } from '../aggregate/CloudinaryResult'
import { FileVM } from './FileVM'
import { FileTypeEnum } from '../enum/FileTypeEnum'
import { IParseFileSaveResult } from '../interfaces/IParseFileSaveResult'
import { IFileUploadStatusFn } from '../interfaces/IFileUploadStatusFn'
import { RootStore } from '../../stores/RootStore'
import env from '../../../env'

export class FileUploadModalVM {
  private rootStore: RootStore

  constructor(
    rootStore: RootStore,
    allowMultiple: boolean = true,
    addAfterSave: Function = null,
    toggleShowModal: Function = null,
    fileTypes: FileTypeEnum
  ) {
    this.rootStore = rootStore
    this.allowMultiple = allowMultiple
    this.addAfterSave = addAfterSave
    this.toggleShowModal = toggleShowModal
    this.fileTypes = fileTypes
    this.rootStore = rootStore
    this.loadAcceptedFileTypes()
  }

  @observable public allowMultiple: boolean = true
  @observable public addAfterSave: Function = null
  @observable public toggleShowModal: Function = null
  @observable public files: Array<FileVM> = []
  @observable public uploadProcessing: boolean = false
  @observable public uploadProcessingProgress: number = 0
  @observable public uploadProcessingText: string = ''
  @observable public uploadProcessingSubText: string = ''
  @observable public fileTypes: FileTypeEnum = FileTypeEnum.DEFAULT
  @observable public showErrors: boolean = false
  @observable public errors: IParseFileSaveResult[] = []
  @observable public filesTooBig: Array<File> = []

  private loadAcceptedFileTypes() {
    if (this.fileTypes !== FileTypeEnum.DEFAULT) return
    if (!this.rootStore.organizationsStore.currentOrganization) return
    if (!this.rootStore.organizationsStore.currentOrganization.defaultFileTypes) return
    const currentOrg = this.rootStore.organizationsStore.currentOrganization
    let all = currentOrg.defaultFileTypes
    if (currentOrg.additionalFileTypes) all = all.concat(currentOrg.additionalFileTypes)
    const unique = Array.from(new Set(all))
    this.fileTypes = unique.map((type) => '.' + type).join(', ') as FileTypeEnum
  }

  public toggleModal() {
    if (this.uploadProcessing) return
    this.toggleShowModal()
    this.files = []
    this.errors = []
    this.showErrors = false
    this.filesTooBig = []
  }

  public handleFilesDrop(files: File[]) {
    this.filesTooBig = []
    files.forEach((file: File) => {
      const okFileSize = this.checkFileSize(file)
      if (!okFileSize) return this.filesTooBig.push(file)
      const fileVM = new FileVM(file)
      if (!this.allowMultiple) return (this.files = [fileVM])
      return this.files.push(fileVM)
    })
  }

  private checkFileSize(file: File) {
    if (file.type.includes('video')) return Boolean(file.size < 2147483648)
    return Boolean(file.size < 20971520)
  }

  public removeFile(index: number) {
    this.files.splice(index, 1)
  }

  public async uploadFiles() {
    this.uploadProcessingProgress = 0
    this.uploadProcessingText = 'Uploading...'
    this.uploadProcessingSubText = ''
    this.uploadProcessing = true
    let mediaFiles = []
    let otherFiles = []
    const useCloudinary = env.var.REACT_APP_MEDIA_UPLOAD_SERVICE === 'cloudinary'
    this.files.forEach((f: FileVM) => {
      if (f.type === 'video' && useCloudinary) return mediaFiles.push(f.file)
      if (f.type === 'image' && useCloudinary) return mediaFiles.push(f.file)
      return otherFiles.push(f.file)
    })
    const cloudinaryAttachments = await this.uploadToCloudinary(mediaFiles)
    const azureBlobAttachments = await this.uploadToAzureStorage(otherFiles)
    this.addAfterSave(cloudinaryAttachments.concat(azureBlobAttachments))
    this.finishUpload()
  }

  private tickUploadProgress = (progress: number, heading: string, details: string) => {
    this.uploadProcessingProgress = progress <= 100 ? progress : 100
    this.uploadProcessingText = heading
    this.uploadProcessingSubText = details
  }

  private async uploadToCloudinary(mediaFiles): Promise<Attachment[]> {
    const svc = new FileUploadService()
    const cb: IFileUploadStatusFn = (progress: number, heading: string, details: string) => {
      this.tickUploadProgress(progress, heading, details)
    }
    const res = await svc.uploadMediaItemsToCloudinary(mediaFiles, cb)
    return res.map((e: CloudinaryResult) => Attachment.createFromCloudinaryResult(e))
  }

  private async uploadToAzureStorage(otherFiles): Promise<Attachment[]> {
    const svc = new FileUploadService()
    const orgId = this.rootStore.appStore.currentOrgId
    const res: IParseFileSaveResult[] = await svc.uploadFilesToAzureStorage(orgId, otherFiles)
    const res2 = this.validateParseFileResult(res)
    return res2
  }

  private validateParseFileResult(res: IParseFileSaveResult[]): Attachment[] {
    const attachments = []
    res.forEach((r) => {
      if (!r.success) {
        this.errors.push(r)
        console.log(r)
      } else attachments.push(r)
    })
    return attachments.map((e) => Attachment.createFromParseFile(e))
  }

  public finishUpload() {
    this.uploadProcessing = false
    this.uploadProcessingText = ''
    this.uploadProcessingSubText = ''
    this.uploadProcessingProgress = 0
    if (this.errors.length > 0) return (this.showErrors = true)
    this.toggleModal()
  }

  @computed
  public get fileSizePrompt() {
    return 'Videos: 2 GB, Files: 20 MB'
  }

  @computed
  public get acceptedFileTypes(): string {
    if (this.fileTypes === FileTypeEnum.PDF) return '.pdf'
    return this.fileTypes.toString()
  }

  @computed
  public get saveEnabled() {
    if (this.uploadProcessing) return false
    if (!Boolean(this.files.length)) return false
    return true
  }

  @action
  public reset() {
    this.files = []
  }

  @computed
  public get mimeTypes() {
    const typesImage = ['image/bmp', 'image/jpeg', 'image/png', 'image/gif', 'image/svg+xml', 'image/heic']
    const typesVideo = ['video/mp4', 'video/quicktime', 'video/x-ms-wmv']
    const typesFiles = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/zip',
      'application/vnd.oasis.opendocument.text',
      'application/rtf',
      'text/csv',
      'text/plain',
    ]
    const typesAudio = ['audio/*']
    const typesPDF = ['application/pdf']

    if (this.fileTypes === FileTypeEnum.DEFAULT) {
      return [
        ...typesImage,
        ...typesVideo,
        ...typesFiles,
      ]
    } else if (this.fileTypes === FileTypeEnum.IMAGES_VIDEOS_AUDIO) {
      return [
        ...typesImage,
        ...typesVideo,
        ...typesAudio,
      ]
    } else if (this.fileTypes === FileTypeEnum.IMAGES_VIDEOS) {
      return [
        ...typesImage,
        ...typesVideo,
      ]
    } else if (this.fileTypes === FileTypeEnum.IMAGES) {
      return [
        ...typesImage,
      ]
    } else if (this.fileTypes === FileTypeEnum.VIDEOS) {
      return [
        ...typesVideo,
      ]
    } else if (this.fileTypes === FileTypeEnum.FILES) {
      return [
        ...typesFiles,
      ]
    } else if (this.fileTypes === FileTypeEnum.AUDIO) {
      return [
        ...typesAudio,
      ]
    } else if (this.fileTypes === FileTypeEnum.PDF) {
      return [
        ...typesPDF
      ]
    }
  }
}
