import React from 'react'
import { Button, Grid, Modal } from '@material-ui/core'
import { observer, inject } from 'mobx-react'
import { SurveysStore } from '../../../store/SurveysStore'
import { UserSurveysStore } from '../../../../user-surveys/store/UserSurveysStore'
import ResponsesList from './ResponsesList'
import UserSurveyTakeWidget from '../../../../user-surveys/views/take/UserSurveyTakeWidget'
import './ResponsesTabPanel.scss'
import { SurveyEditTabsEnum } from '../../../types/SurveyEditTabsEnum'
import ParticipantsSelect from '../../../../participants-select/views/ParticipantsSelect'

interface Props {
  surveysStore?: SurveysStore
  userSurveysStore?: UserSurveysStore
}

const ResponsesTabPanel: React.FC<Props> = ({ surveysStore, userSurveysStore }) => {
  const { editVM } = surveysStore.viewModels
  if (!editVM) return null
  if (!editVM.analysis) return null
  if (editVM.currentTabIndex !== SurveyEditTabsEnum.RESPONSES) return null

  const renderUserSurvey = () => {
    if (!editVM.responseVM) return null
    if (!editVM.responseVM.isForResponseDisplay) return null
    if (editVM.analysis.responsesListVM.hideResponses) return null
    return (
      <UserSurveyTakeWidget
        takeVM={editVM.responseVM}
        disabled={true}
        userName={editVM.analysis.selectedResponseUserName}
      />
    )
  }

  const renderResponsesList = () => {
    return <ResponsesList />
  }

  const renderInputResponseButton = () => {
    return (
      <>
        <Button
          variant='outlined'
          className='add-response-button'
          onClick={() => {
            editVM.togglePaperSurveyParticipantsPickerOpen()
          }}
        >
          Add Response
        </Button>
        <Modal
          open={editVM.isPaperSurveyParticipantsPickerOpen}
          onClose={() => editVM.togglePaperSurveyParticipantsPickerOpen()}
        >
          <div id='PaperSurveyParticipantsPicker'>
            <div className='participants-select-container'>
              <ParticipantsSelect participantsSelectVM={editVM.paperSurveyParticipantsSelectVM} />
            </div>
            <div className='paper-survey-participants-picker-footer'>
              <Button
                variant='outlined'
                className=''
                onClick={() => editVM.togglePaperSurveyParticipantsPickerOpen()}
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                className=''
                onClick={() => editVM.createUserSurveyForPaperSurvey()}
                disabled={editVM.isPaperSurveyParticipantsPickerSubmitDisabled}
              >
                Apply
              </Button>
            </div>
          </div>
        </Modal>
      </>
    )
  }

  return (
    <Grid container id='ResponsesTabPanel' spacing={0}>
      <Grid item xs={3} id='ResponsesListContainer'>
        {renderInputResponseButton()}
        {renderResponsesList()}
      </Grid>
      <Grid item xs={9}>
        {renderUserSurvey()}
      </Grid>
    </Grid>
  )
}

export default inject('surveysStore', 'userSurveysStore')(observer(ResponsesTabPanel))
