import React from 'react'
import { observer } from 'mobx-react'
import rootStore from '../../../stores/RootStore'
import CreateCampaignDialog from '../dialogs/CreateCampaignDialog'
import { PulseCampaignEditVM } from '../../view-models/PulseCampaignEditVM'
import IntroductionScreenDesignerDialog from '../dialogs/IntroductionScreenDesignerDialog'
import PulseCampaignSnackbar from './PulseCampaignEditSnackbar'
import PulseCampaignEditStepper from '../steppers/PulseCampaignEditStepper'
import PulseCampaignEditStepView from './PulseCampaignEditStepView'
import './PulseCampaignEditScreen.scss'
import PulseCampaignBottomToolbar from './PulseCampaignBottomToolbar'
import ConfirmSaveDialog from '../dialogs/ConfirmSaveDialog'
import CompletedContentScreenDesignerDialog from '../dialogs/CompletedContentScreenDesignerDialog'
import PulseQuestionMediaPicker from '../../../pulse-questions/views/media-picker/PulseQuestionMediaPicker'

@observer
export default class PulseCampaignEditScreen extends React.Component<any, any> {
  private vm: PulseCampaignEditVM

  constructor(props) {
    super(props)

    this.vm = rootStore.pulseCampaignsStore.viewModels.editVM
    if (props.params.id && props.params.surveyId) {
      rootStore.pulseCampaignsStore.viewModels.loadPulseCampaignEditVM(
        props.params.id,
        props.params.surveyId
      )
    }
    if (props.params.id && !props.params.surveyId) {
      rootStore.pulseCampaignsStore.viewModels.loadPulseCampaignEditVM(props.params.id)
    }

    if (props.params.templateEditId) {
      rootStore.pulseCampaignsStore.viewModels.loadTemplateEditVM(props.params.templateEditId)
    }

    if (props.params.templateCopyId) {
      rootStore.pulseCampaignsStore.viewModels.createTemplateFromCopy(props.params.templateCopyId)
    }

    if (props.params.templateId) {
      rootStore.pulseCampaignsStore.viewModels.createPulseCampaignFromTemplate(
        props.params.templateId
      )
    }

    if (props.params.copyId) {
      rootStore.pulseCampaignsStore.viewModels.createPulseCampaignFromCopy(props.params.copyId)
    }
  }

  routerWillLeave(nextLocation) {
    if (!rootStore.pulseCampaignsStore.viewModels.editVM) return
    if (rootStore.pulseCampaignsStore.viewModels.editVM.isDirty) {
      return 'You have unsaved information, are you sure you want to leave this page?'
    }
  }

  componentDidMount() {
    this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave.bind(this))
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      if (this.props.params.copyId) {
        rootStore.pulseCampaignsStore.viewModels.createPulseCampaignFromCopy(
          this.props.params.copyId
        )
        this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave.bind(this))
      }
      if (this.props.params.templateEditId) {
        rootStore.pulseCampaignsStore.viewModels.loadTemplateEditVM(
          this.props.params.templateEditId
        )
        this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave.bind(this))
      }
      if (this.props.params.templateCopyId) {
        rootStore.pulseCampaignsStore.viewModels.createTemplateFromCopy(
          this.props.params.templateCopyId
        )
        this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave.bind(this))
      }
    }
  }

  componentWillUnmount() {
    rootStore.pulseCampaignsStore.viewModels.loadPulseCampaignEditVM('empty')
    rootStore.pulseQuestionsStore.loadCampaignPulseQuestionEditVM('empty')
  }

  render() {
    return (
      <div id='PulseCampaignEditScreen'>
        {rootStore.pulseQuestionsStore.editVM && (
          <PulseQuestionMediaPicker vm={rootStore.pulseQuestionsStore.editVM.mediaUploadPickerVM} />
        )}
        <PulseCampaignEditStepper />
        <PulseCampaignEditStepView />
        <PulseCampaignBottomToolbar />
        <ConfirmSaveDialog pulseCampaignsStore={rootStore.pulseCampaignsStore} />
        <CreateCampaignDialog pulseCampaignsStore={rootStore.pulseCampaignsStore} />
        <PulseCampaignSnackbar pulseCampaignsStore={rootStore.pulseCampaignsStore} />
        <IntroductionScreenDesignerDialog pulseCampaignsStore={rootStore.pulseCampaignsStore} />
        <CompletedContentScreenDesignerDialog pulseCampaignsStore={rootStore.pulseCampaignsStore} />
      </div>
    )
  }
}
