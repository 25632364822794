import { action, computed, observable } from 'mobx'
import { PeriodType } from '../types/PeriodType'
import { serializable } from 'serializr'
import moment from 'moment'
import { IDueAfterDTO } from '../dtos/IDueAfterDTO'
import { DueAfterModelType } from '../types/DueAfterModelType'
import { DateUtils } from '../../shared/data/DateUtils'

export class DueAfter implements IDueAfterDTO {
  static create(due: any) {
    const dueAfter = new DueAfter()
    dueAfter.enabled = due.enabled
    dueAfter.mode = due.mode
    if (due.value) dueAfter.value = due.value
    if (due.period) dueAfter.period = due.period
    if (due.dueDate) dueAfter.dueDate = due.dueDate
    if (due.dueTimeZone) dueAfter.dueTimeZone = due.dueTimeZone
    return dueAfter
  }

  @serializable @observable mode: DueAfterModelType = 'specific'
  @serializable @observable enabled: boolean = false
  @serializable @observable value: number = 1
  @serializable @observable period: PeriodType = 'day'
  @serializable @observable dueDate: string = moment(
    moment()
      .minute(Math.ceil(moment().minute() / 15) * 15)
      .second(0)
      .add(1, 'days')
  ).toISOString()
  @serializable @observable dueTime: Date = null
  @serializable @observable dueTimeZone: string = moment.tz.guess()

  @action
  public toggleIsEnabled() {
    this.enabled = !this.enabled
  }

  @action
  public toggleMode(type) {
    this.mode = type
  }

  @action
  public setPeriod(val: PeriodType) {
    this.period = val
  }

  @action
  public setMode(val: DueAfterModelType) {
    this.mode = val
  }

  @action
  public setValue(val: number) {
    this.value = val
  }

  @action
  public setDate(val: Date) {
    this.dueDate = moment(val).toISOString()
  }

  @action
  public setDueTimeZone(val) {
    if (!val) return
    this.dueTimeZone = val
  }
}
