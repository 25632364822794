import { RootStore } from '../../stores/RootStore'
import { observable, computed, action } from 'mobx'
import { GoalEditVM } from './GoalEditVM'
import { IValueFactResponse } from '../dto/IValueFactResponse'
import User from '../../users/aggregate/User'
import { TrainingPlan } from '../../training-plans/aggregates/TrainingPlan'
import { TrainingPlanItem } from '../../training-plans/aggregates/TrainingPlanItem'

export class PlanPreviewVM {
  private editVM: GoalEditVM
  public rootStore: RootStore
  public trainingPlan: TrainingPlan

  constructor(rootStore: RootStore, editVM: GoalEditVM, trainingPlan: TrainingPlan) {
    this.editVM = editVM
    this.rootStore = rootStore
    this.trainingPlan = trainingPlan
  }

  @observable public expandedPreviewItemId: string = ''

  @computed
  public get title(): string {
    return this.trainingPlan.title
  }

  @computed
  public get subtitle(): string {
    const lessons = this.trainingPlanItems.length > 0 ? ' lessons' : 'lesson'
    const plusQuiz = this.trainingPlan.requireQuiz ? '+ Quiz' : ''
    const plusCert = this.trainingPlan.requireCertificate ? '+ Certificate' : ''
    return `${this.trainingPlanItems.length} ${lessons} ${plusQuiz} ${plusCert}`
  }

  @computed
  public get thumbnail(): string {
    return this.trainingPlan.thumbnail
  }

  @computed
  public get description(): string {
    return this.trainingPlan.description
  }

  @computed
  public get trainingPlanItems(): TrainingPlanItem[] {
    return this.trainingPlan.trainingPlanItems
  }

  @computed
  public get categories(): string[] {
    const names = []
    this.trainingPlan.categories.forEach((cat) => {
      const category = this.rootStore.categoriesStore.getCategory(cat)
      if (category) names.push(category.name)
    })
    return names
  }

  @action
  public setExpandedPreviewItemId(id: string) {
    if (this.expandedPreviewItemId === id) {
      this.expandedPreviewItemId = ''
    } else {
      this.expandedPreviewItemId = id
    }
  }
}
export default PlanPreviewVM
