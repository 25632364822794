import { CellClickedEvent, ColDef, GridOptions, RowClickedEvent } from 'ag-grid-community'
import { action, computed, observable } from 'mobx'
import moment from 'moment'
import { AGGridVM } from '../../../shared/ag-grid/AGGridVM'
import { nameOf } from '../../../shared/nameOf'
import { RootStore } from '../../../stores/RootStore'
import { IUserSurveysListRequest } from '../../interfaces/IUserSurveysListRequest'
import { ActiveUserSurveyRowVM } from './ActiveUserSurveyRowVM'
import { UserSurveysListStore } from './UserSurveysListStore'
import { MySurveysWidgetVM } from './MySurveysWidgetVM'
import { UserSurveysService } from '../../service/UserSurveysService'
import { UserSurvey } from '../../aggregate/UserSurvey'
import { ParseService } from '../../../services/ParseService'

export class ActiveUserSurveysTabVM extends AGGridVM {
  private rootStore: RootStore
  public dataStore: UserSurveysListStore
  @observable private shownOnce: boolean = false

  constructor(rootStore: RootStore, widgetVM: MySurveysWidgetVM) {
    super()
    this.rootStore = rootStore
    this.widgetVM = widgetVM
    this.dataStore = new UserSurveysListStore(this.rootStore, {
      getInactive: this.showInactiveUserSurveys,
    } as IUserSurveysListRequest)
    this.serverSideLoaded = true
  }

  public widgetVM: MySurveysWidgetVM
  @observable public showInactiveDialog: boolean = false
  @observable public inactiveProcessing: boolean = false
  @observable public selectedUserSurvey: UserSurvey = null
  public tabIndex: number = 0
  @observable public showInactiveUserSurveys: boolean = false

  @action
  public toggleInactiveDialog() {
    if (this.inactiveProcessing) return
    this.showInactiveDialog = !this.showInactiveDialog
  }

  @action
  public setSelectedUserSurveyId(userSurvey: UserSurvey) {
    this.selectedUserSurvey = userSurvey
  }

  public getRowNodeId(row: ActiveUserSurveyRowVM): string {
    return row.objectId
  }

  @action
  private setShownOnce() {
    this.shownOnce = true
  }

  @action
  public toggleShowInactiveUserSurveys() {
    this.showInactiveUserSurveys = !this.showInactiveUserSurveys
    this.onFilterChanged()
  }

  @action
  public onFilterChanged() {
    const filters = {
      getInactive: undefined,
    } as IUserSurveysListRequest
    filters.getInactive = this.showInactiveUserSurveys
    this.dataStore.setRequest({
      ...this.dataStore.getRequest,
      ...filters,
    })

    this.applyFilter()
  }

  @computed
  public get shown(): boolean {
    return this.widgetVM.tabIndex === this.tabIndex
  }

  @computed
  public get shouldRenderGrid(): boolean {
    if (!this.shouldRender) return false
    if (this.shownOnce) return true
    if (this.shown) return true
    return false
  }

  public get shouldRender(): boolean {
    return true
  }

  @computed
  public get columnDefs(): ColDef[] {
    const s = this.rootStore.localizationStore.lzStrings
    return [
      {
        headerName: s.mySurveysWidget.due,
        sortable: false,
        field: nameOf<ActiveUserSurveyRowVM, string>((e) => e.dueDate),
        cellClass: (params) => {
          if (!params.data) return null
          if (params.data.isActive) return 'is-active'
          return 'is-inactive'
        },
        onCellClicked: (e: CellClickedEvent) => {
          const rowVM: ActiveUserSurveyRowVM = e.data
          if (rowVM && rowVM.openUserSurvey) rowVM.openUserSurvey()
        },
      },
      {
        headerName: s.mySurveysWidget.name,
        sortable: false,
        field: nameOf<ActiveUserSurveyRowVM, string>((e) => e.name),
        onCellClicked: (e: CellClickedEvent) => {
          const rowVM: ActiveUserSurveyRowVM = e.data
          if (rowVM && rowVM.openUserSurvey) rowVM.openUserSurvey()
        },
      },
      {
        headerName: s.mySurveysWidget.from,
        sortable: false,
        field: nameOf<ActiveUserSurveyRowVM, string>((e) => e.fromUserName),
        cellRenderer: 'fromCell',
        onCellClicked: (e: CellClickedEvent) => {
          const rowVM: ActiveUserSurveyRowVM = e.data
          if (rowVM && rowVM.openUserSurvey) rowVM.openUserSurvey()
        },
      },
      {
        headerName: s.mySurveysWidget.questions,
        sortable: false,
        field: nameOf<ActiveUserSurveyRowVM, number>((e) => e.questionsCount),
        onCellClicked: (e: CellClickedEvent) => {
          const rowVM: ActiveUserSurveyRowVM = e.data
          if (rowVM && rowVM.openUserSurvey) rowVM.openUserSurvey()
        },
      },
      {
        headerName: s.mySurveysWidget.published,
        sortable: false,
        field: nameOf<ActiveUserSurveyRowVM, string>((e) => e.publishedDateFormated),
        comparator: (a, b) => this.comparator(a, b),
        onCellClicked: (e: CellClickedEvent) => {
          const rowVM: ActiveUserSurveyRowVM = e.data
          if (rowVM && rowVM.openUserSurvey) rowVM.openUserSurvey()
        },
      },
      // {
      //   headerName: s.mySurveysWidget.categories,
      //   sortable: false,
      //   field: nameOf<ActiveUserSurveyRowVM, string>((e) => e.categories),
      // },
      {
        headerName: s.mySurveysWidget.action,
        cellRenderer: 'actionsCell',
        sortable: false,
        onCellClicked: null,
      },
    ]
  }

  @action
  public comparator(a, b) {
    if (a === '-' && b !== '-') return 1
    if (a !== '-' && b === '-') return -1
    if (a === '-' && b === '-') return 0
    let momentA = moment(a)
    let momentB = moment(b)
    if (momentA.isBefore(momentB)) return 1
    else if (momentA.isSame(momentB)) return 0
    else if (momentA.isAfter(momentB)) return -1
  }

  public getGridOptions(): GridOptions {
    return {
      ...this.baseGridOptions,
      onRowClicked: (e) => this.rowClicked(e),
      getRowNodeId: (e) => this.getRowNodeId(e),
      columnDefs: this.ensureTooltipFields(this.columnDefs),
    }
  }

  @action
  public setQuickFilter(val: string) {
    this.typedFilterText = val
    if (this.quickFilterTO) clearTimeout(this.quickFilterTO)
    this.quickFilterTO = setTimeout(() => this.applyFilter(), 1000)
  }

  private applyFilter() {
    this.dataStore.setFilter(this.typedFilterText)
    this.reload()
    this.columnApi.applyColumnState({
      state: [{ colId: 'due', sort: 'asc' }],
    })
  }

  protected onGridReadied() {
    this.gridApi.setDatasource({
      rowCount: this.totalRecords,
      getRows: async (params) => {
        this.setShownOnce()
        if (params.startRow === 0) this.dataStore.setPage(0)
        await this.dataStore.getNextPage()
        const rows = this.dataStore.rows.map(
          (e) => new ActiveUserSurveyRowVM(this.rootStore, e, this)
        )
        params.successCallback(rows, this.dataStore.totalRecords)
      },
    })
  }

  public get rows(): ActiveUserSurveyRowVM[] {
    return []
  }

  public rowClicked(e: RowClickedEvent) {
    console.debug(e)
  }

  @computed
  protected get totalRecords() {
    return this.dataStore.totalRecords
  }

  @computed
  public get hasRows(): boolean {
    return this.dataStore.hasListRecords
  }

  @computed
  public get noRowsToShowLabel() {
    return this.rootStore.localizationStore.lzStrings.dashboard.no_rows_to_show
  }

  @computed
  public get canArchiveSurveys() {
    return this.rootStore.appStore.canArchiveSurveys
  }

  @action
  reloadActiveSurveys() {
    this.rootStore.userSurveysCountStore.loadListRecords()
  }

  @action
  public async setUserSurveyInactive() {
    this.inactiveProcessing = true
    const svc = new UserSurveysService(this.rootStore)
    await svc.markUserSurveyAsInactive(this.selectedUserSurvey.objectId)
    if (!this.selectedUserSurvey.isRead) {
      this.reloadActiveSurveys()
    }
    if (this.selectedUserSurvey.alwaysAvailable) {
      const pSvc = new ParseService()
      await pSvc.cancelSurveyInvitations(
        this.rootStore.appStore.currentOrgId,
        this.selectedUserSurvey.surveyId,
        [this.selectedUserSurvey.objectId],
        false
      )
    }
    this.inactiveProcessing = false
    this.toggleInactiveDialog()
    this.onFilterChanged()
  }
}
