import React from 'react'
import { observer, inject } from 'mobx-react'
import DeleteIcon from '@material-ui/icons/Delete'
import { IconButton, Tooltip } from '@material-ui/core'
import './WidgetContainer.scss'
import { AppStore } from '../../../../stores/AppStore'
import { WidgetContainerVM } from '../../../view-models/WidgetContainerVM'
import PendingUserSurveysWidget from '../../../../user-surveys/views/pending-widget/PendingUserSurveysWidget'
import ManageSurveysWidget from '../../../../surveys/views/manage-surveys-widget/ManageSurveysWidget'
import NotificationsWidget from '../../../../notifications/widgets/NotificationsWidgetV2'
import OrganizationGeneralWidget from '../../../../organizations/views/OrganizationGeneralWidget/OrganizationGeneralWidget'
import CalendarManagementWidget from '../../../../events/views/widgets/CalendarManagementWidget'
import ConsentWidget from '../../../../consents/views/ConsentWidget'
import VizWidget from '../../../../worksheets/views/viz-widget/VizWidget'
import PendingUserTasksWidget from '../../../../user-tasks/views/pending-tasks-widget/PendingUserTasksWidget'
import CompletedUserTasksWidget from '../../../../user-tasks/views/completed-tasks-widget/CompletedUserTasksWidget'
import TasksManagementWidget from '../../../../tasks/views/widget/TasksManagementWidget'
import MediaItemsListWidget from '../../../../media-items/views/list-widget/MediaItemsListWidget'
import SurveyResponsesWidget from '../../../../survey-responses/views/survey-responses-widget/SurveyResponsesWidget'
import ContactListWidget from '../../../../contacts/widgets/ContactListWidget'
import MyTrainingPlansWidget from '../../../../training-plans/user-training-plans/views/widgets/MyTrainingPlansWidget'
import TrainerWidget from '../../../../training-plans/views/widgets/trainer-widget/TrainerWidget'
import ManageTrainingPlansWidget from '../../../../training-plans/views/widgets/manage-plans-widget/ManageTrainingPlansWidget'
import CatalogItemsWidget from '../../../../training-plans/views/widgets/catalog-items-widget/CatalogItemsWidget'
import CatalogPlansWidget from '../../../../training-plans/views/widgets/catalog-plans-widget/CatalogPlansWidget'
import PrivilegeSetsWidget from '../../../../privilege-sets/views/PrivilegeSetsWidget'
import TaskTypesWidget from '../../../../task-types/views/TaskTypesWidget'
import CustomCollectionsListWidget from '../../../../custom-collections/views/widget/CustomCollectionsListWidget'
import QuestionCatalogWidget from '../../../../questions/widgets/QuestionCatalogWidget'
import WorksheetsListWidget from '../../../../worksheets/views/list-widget/WorksheetsListWidget'
import OrganizationUsersWidget from '../../../../organization-users/views/widget/OrganizationUsersWidget'
import GoalsCatalogWidget from '../../../../goals/views/goals-catalog-widget/GoalsCatalogWidget'
import ManageGoalsWidget from '../../../../goals/views/manage-goals-widget/ManageGoalsWidget'
import MyGoalsWidget from '../../../../user-goals/views/MyGoalsWidget'
import CategoriesListWidget from '../../../../categories/views/widgets/CategoriesListWidget/CategoriesListWidget'
import { DashboardsStore } from '../../../DashboardsStore'
import ClientManagementWidget from '../../../../clients/views/ClientManagementWidget'
import MyAudienceWidget from '../../../../organization-users/views/my-audience-widget/MyAudienceWidget'
import ManageGroupsAndRolesWidget from '../../../../groups-roles-widget/views/ManageGroupsAndRolesWidget'
import MySurveysWidget from '../../../../user-surveys/views/my-surveys-widget/MySurveysWidget'
import AlwaysAvailableSurveysWidget from '../../../../user-surveys/views/always-available-surveys-widget/AlwaysAvailableSurveysWidget'
import SurveyTagsWidget from '../../../../survey-tags/views/survey-tags-widget/SurveyTagsWidget'

interface Props {
  widget: WidgetContainerVM
  appStore?: AppStore
  dashboardsStore?: DashboardsStore
}

const WidgetContainer: React.FC<Props> = ({ widget, appStore, dashboardsStore }) => {
  const type = widget.type
  const editVM = dashboardsStore.editVM

  const renderDeleteButton = () => {
    if (!editVM.dashboard) return null
    if (editVM.type !== 'custom') return null
    return (
      <Tooltip title={`Remove from dashboard`} placement='bottom-start' enterDelay={500}>
        <IconButton className='deleteIcon' size='small' onClick={() => widget.removeWidget()}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    )
  }

  const renderImpactTrainingWidgets = () => {
    if (type === 'MyTrainingPlansWidget') return <MyTrainingPlansWidget />
    if (type === 'MyTrainingsWidget') return <MyTrainingPlansWidget />
    if (type === 'ManageTrainingsWidget') return <ManageTrainingPlansWidget />
    if (type === 'ManageTrainingPlansWidget') return <ManageTrainingPlansWidget />
    if (type === 'PeerTrainingsWidget') return <TrainerWidget />
    if (type === 'TrainerWidget') return <TrainerWidget />
  }

  const renderImpactCatalogWidgets = () => {
    if (type === 'GoalsCatalogWidget') return <GoalsCatalogWidget />
    if (type === 'CatalogPlansWidget') return <CatalogPlansWidget />
    if (type === 'CatalogItemsWidget') return <CatalogItemsWidget />
    if (type === 'TrainingPlansCatalogWidget') return <CatalogPlansWidget />
    if (type === 'TrainingItemsCatalogWidget') return <CatalogItemsWidget />
  }

  const renderImpactGoalsWidgets = () => {
    if (type === 'ManageGoalsWidget') return <ManageGoalsWidget />
    if (type === 'MyGoalsWidget') return <MyGoalsWidget />
  }

  const renderTasksWidgets = () => {
    if (type === 'TasksManagementWidget') return <TasksManagementWidget />
    if (type === 'TasksManageMentWidget') return <TasksManagementWidget />
    if (type === 'CompletedUserTasksWidget') return <CompletedUserTasksWidget />
    if (type === 'PendingUserTasksWidget') return <PendingUserTasksWidget />
  }

  const renderSurveysWidgets = () => {
    if (type === 'PendingUserSurveys') return <MySurveysWidget /> //replace old widget
    if (type === 'PendingUserSurveysWidget') return <MySurveysWidget /> //replace old widget
    if (type === 'MySurveysWidget') return <MySurveysWidget />
    if (type === 'SurveyResponsesWidget') return <AlwaysAvailableSurveysWidget /> //replace old widget
    if (type === 'AlwaysAvailableSurveysWidget') return <AlwaysAvailableSurveysWidget />
    if (type === 'ManageSurveys') return <ManageSurveysWidget />
    if (type === 'ManageSurveysWidget') return <ManageSurveysWidget />
    // if (type === 'SurveyTemplatesWidget') return <SurveyTemplatesWidget />
  }

  const renderTenantAdminOrgWidgets = () => {
    if (type === 'OrganizationGeneralWidget') return <OrganizationGeneralWidget />
    if (type === 'OrgUsersTableWidget') return <OrganizationUsersWidget />
    if (type === 'ManageGroupsAndRolesWidget') return <ManageGroupsAndRolesWidget />
    if (type === 'PrivilegeSetsWidget') return <PrivilegeSetsWidget />
    if (type === 'ManagePrivilegeSetsWidget') return <PrivilegeSetsWidget />
    if (type === 'ContactsWidget') return <ContactListWidget />
    if (type === 'ContactListWidget') return <ContactListWidget />
    if (type === 'ClientsWidget') return <ClientManagementWidget />
    if (type === 'ClientManagementWidget') return <ClientManagementWidget />
  }

  const renderTenantAdminConfigWidgets = () => {
    if (type === 'NotificationsWidget') return <NotificationsWidget />
    if (type === 'CategoriesManagementWidget') return <CategoriesListWidget />
    if (type === 'CategoriesListWidget') return <CategoriesListWidget />
    if (type === 'CalendarManagementWidget') return <CalendarManagementWidget />
    if (type === 'TaskTypesWidget') return <TaskTypesWidget />
    if (type === 'ManageTaskTypesWidget') return <TaskTypesWidget />
    if (type === 'CustomCollectionsListWidget') return <CustomCollectionsListWidget />
    if (type === 'ConsentWidget') return <ConsentWidget />
  }

  const renderTenantAdminSurveyWidgets = () => {
    if (type === 'QuestionsCatalogWidget') return <QuestionCatalogWidget />
    if (type === 'QuestionCatalogWidget') return <QuestionCatalogWidget />
    if (type === 'ManageMediaItemsWidget') return <MediaItemsListWidget />
    if (type === 'MediaItemsListWidget') return <MediaItemsListWidget />
    if (type === 'SurveyTagsWidget') return <SurveyTagsWidget />
  }

  const renderComponent = () => {
    // else if (widget.type === 'CategoriesManagementWidget') {
    //   return <CategoriesManagementWidget />
    // }
    if (widget.type === 'AudienceMembersWidget') {
      return <MyAudienceWidget />
    } else if (widget.type === 'WorksheetWidget') {
      return <VizWidget widgetInfo={widget} />
    } else if (widget.type === 'WorksheetsListWidget') {
      return <WorksheetsListWidget />
    }
  }

  appStore.incrementWidgetResizeCounter()

  return (
    <div id='WidgetContainer'>
      {renderDeleteButton()}
      {renderComponent()}
      {renderImpactTrainingWidgets()}
      {renderImpactCatalogWidgets()}
      {renderImpactGoalsWidgets()}
      {renderTasksWidgets()}
      {renderSurveysWidgets()}
      {renderTenantAdminOrgWidgets()}
      {renderTenantAdminConfigWidgets()}
      {renderTenantAdminSurveyWidgets()}
    </div>
  )
}

export default inject('appStore', 'dashboardsStore')(observer(WidgetContainer))
