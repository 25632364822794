import React from 'react'
import { observer } from 'mobx-react'
import { PulseQuestionOptionVM } from '../../view-models/questions/PulseQuestionOptionVM'
import { Button, Checkbox, Radio } from '@material-ui/core'
import './PulseQuestionOptionButton.scss'

interface Props {
  vm: PulseQuestionOptionVM
}

const Option: React.FC<Props> = ({ vm: option }) => {
  const renderEmojiImage = () => {
    if (option.questionType !== 'emoji') return
    return <img src={'../../../assets/emoticons/png/' + option.emoji + '.png'} alt='' />
  }

  const renderCheckbox = () => {
    if (option.questionType === 'emoji') return
    if (option.questionType === 'radio') return
    return <Checkbox checked={option.isChecked} />
  }

  const renderRadioButton = () => {
    let doRender = false
    if (option.questionType === 'radio') doRender = true
    if (!doRender) return
    return <Radio checked={option.isChecked} />
  }

  const renderButton = () => {
    if (option.questionType === 'text') return
    return (
      <Button
        disabled={true}
        className={option.isChecked ? 'active' : ''}
        onClick={() => {
          option.toggleChecked()
        }}
        component='div'
        startIcon={
          <>
            {renderEmojiImage()}
            {renderCheckbox()}
            {renderRadioButton()}
          </>
        }
      >
        {option.text}
      </Button>
    )
  }

  return (
    <div id='PulseOptionButton' className={'sln-question' + '-option' + option.rank}>
      {renderButton()}
    </div>
  )
}

export default observer(Option)
