import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { Card, CardContent, Grid, CardHeader, Button } from '@material-ui/core'
import './TaskHeader.scss'
import moment from 'moment'
import { UserSurveyTakeVM } from '../../view-models/UserSurveyTakeVM'

interface Props {
  localizationStore?: LocalizationStore
  takeVM: UserSurveyTakeVM
}

const TaskHeader: React.FC<Props> = ({ takeVM, localizationStore }) => {
  const vm = takeVM
  const { surveys: lz } = localizationStore.lzStrings
  if (!vm) return null
  if (!vm.userTask) return null

  const renderDescription = () => {
    if (!vm.userTask.description) return null

    return (
      <Grid item>
        <span className='taskHeaderSelector'>{lz.task_description}</span> {vm.userTask.description}
      </Grid>
    )
  }

  const renderTask = () => {
    return (
      <CardContent>
        <Grid container direction='row' justifyContent='space-between' alignItems='flex-end'>
          <Grid item>
            <Grid container direction='column'>
              <Grid item>
                <span className='taskHeaderSelector'>{lz.task_name}</span> {vm.userTask.name}
              </Grid>
              {renderDescription()}
              <Grid item>
                <span className='taskHeaderSelector'>{lz.task_from}</span> {vm.taskAssignedBy}
              </Grid>
              <Grid item>
                <span className='taskHeaderSelector'>{lz.task_for}</span> {vm.taskCompletedBy}
              </Grid>
              <Grid item>
                <span className='taskHeaderSelector'>{lz.task_created}</span>{' '}
                {moment(vm.userTask.createdAt).format(lz.task_created_at_format)}
              </Grid>
              <Grid item>
                <span className='taskHeaderSelector'>{lz.task_due}</span>{' '}
                {moment.unix(vm.userTask.dueDate).format(lz.task_due_date_format)}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>{renderTaskLink()}</Grid>
        </Grid>
      </CardContent>
    )
  }

  const renderTaskLink = () => {
    if (!vm.userIsTaskOwner) return null
    return (
      <Button className='task-link' variant='outlined' onClick={() => vm.openTask()}>
        {lz.task_open_task}
      </Button>
    )
  }

  return (
    <Card id='TaskHeader'>
      <CardHeader title={lz.task_card_header} id='TaskCardHeader' />
      {renderTask()}
    </Card>
  )
}

export default inject('localizationStore')(observer(TaskHeader))
