import { computed, action, observable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { ConsentsStore } from '../store/ConsentsStore'
import { Consent } from '../aggregate/Consent'
import { Attachment } from '../../upload/aggregate/Attachment'
import { ParseService } from '../../services/ParseService'
import { AttachmentVM } from '../../attachments/view-models/AttachmentVM'
import { isNumeric } from '../../shared/isNumeric'
import { OldAttachmentVM } from '../../attachments/view-models/OldAttachmentVM'

type DrawerModeType = 'create' | 'edit'
type LanguageType = 'en' | 'de'

export class ConsentDrawerVM {
  private rootStore: RootStore
  public consent: Consent

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.resetText()
    this.loadConsent()
  }

  @observable public isDrawerOpen: boolean = false
  @observable public drawerMode: DrawerModeType = 'create'
  @observable public english: string = ''
  @observable public german: string = ''
  @observable public currentLanguage: LanguageType = 'en'
  @observable public consentRequiredChangeProcessing: boolean = false
  @observable public isSaving: boolean = false
  @observable public showUploadModal: boolean = false

  @computed
  public get latestConsent(): Consent {
    return this.rootStore.consentsStore.latestConsent
  }

  @action
  public toggleShowUploadModal() {
    this.showUploadModal = !this.showUploadModal
  }

  @action
  public loadConsent() {
    if (!this.latestConsent) {
      this.consent = Consent.create(this.consentsStore.currentOrgId)
    } else {
      this.consent = this.latestConsent
    }
  }

  @computed
  public get attachments(): AttachmentVM[] {
    if (!this.consent) return []
    if (!this.consent.attachments) return []

    return this.consent.attachments.map((e, idx) => {
      if (isNumeric(e.objectId) || e.cmsItemId) {
        return this.rootStore.cmsItemAttachmentStore.loadAttachment(e)
      }
      return new OldAttachmentVM(this.rootStore, e, idx)
    })
  }

  @computed
  public get consentsStore(): ConsentsStore {
    return this.rootStore.consentsStore
  }

  @action
  public addAttachments(attachments: Attachment[]) {
    attachments.forEach((a: Attachment) => this.consent.attachments.push(a))
  }

  @computed
  public get currentOrganization() {
    return this.rootStore.organizationsStore.currentOrganization
  }

  @computed
  public get isConsentRequiredForLogin() {
    if (!this.currentOrganization) return false
    return this.currentOrganization.isConsentRequiredForLogin
  }

  @action
  public async makeConsentRequired() {
    this.consentRequiredChangeProcessing = true
    const organization = this.rootStore.organizationsStore.currentOrganization
    organization.setConsentRequiredForLogin()
    const dto = organization.serialize()
    const svc = new ParseService()
    await svc.updateOrgGeneralDetails(dto.objectId, dto)
    this.consentRequiredChangeProcessing = false
  }

  @action
  public async makeConsentNotRequired() {
    this.consentRequiredChangeProcessing = true
    const organization = this.rootStore.organizationsStore.currentOrganization
    organization.setConsentNotRequiredForLogin()
    const dto = organization.serialize()
    const svc = new ParseService()
    await svc.updateOrgGeneralDetails(dto.objectId, dto)
    this.consentRequiredChangeProcessing = false
  }

  @action
  public toggleDrawer() {
    this.isDrawerOpen = !this.isDrawerOpen
  }

  @action
  public setDrawerMode(mode: DrawerModeType) {
    this.drawerMode = mode
    if (mode === 'edit') {
      this.english = this.latestConsent.text.en
      this.german = this.latestConsent.text.de
    }
  }

  @action
  public setLanguage(language: LanguageType) {
    this.currentLanguage = language
  }

  @action
  public setText(text: string) {
    switch (this.currentLanguage) {
      case 'en':
        this.english = text
        return
      case 'de':
        this.german = text
        return
      default:
        return
    }
  }

  @action
  public resetText() {
    if (!this.latestConsent) return ''
    switch (this.currentLanguage) {
      case 'en':
        this.english = this.latestConsent.text.en
        return
      case 'de':
        this.german = this.latestConsent.text.de
        return
      default:
        return
    }
  }

  @computed
  public get saveEnabled() {
    if (this.english.trim() === '') return false
    if (!this.latestConsent) return true
    return this.english !== this.latestConsent.text.en || this.german !== this.latestConsent.text.de
  }

  @computed
  public get changeConsentRequiredEnabled() {
    if (!this.latestConsent) return false
    else return true
  }

  @action
  public async save() {
    this.isSaving = true
    const text = {
      en: this.english,
      de: this.german,
    }
    const orgId = this.consentsStore.currentOrgId
    const userId = this.rootStore.appStore.currentUserId
    const attachments = JSON.parse(
      JSON.stringify(this.consent.attachments ? this.consent.attachments : [])
    )
    const svc = new ParseService()
    await svc.updateConsent(orgId, userId, text, attachments)
    this.isSaving = false
    this.toggleDrawer()
  }

  @action
  public deleteAttachmentFromConsent(index: number) {
    if (index < 0) return
    this.consent.attachments.splice(index, 1)
  }
}
