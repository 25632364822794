import { observable, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'

export class CategoryForm {
  private rootStore: RootStore

  constructor(rootStore, part) {
    this.rootStore = rootStore
    if (!part) return
    this.categoryId = part.categoryId
    this.weight = part.weight
  }

  @observable categoryId: string = null
  @observable weight: number = 1
  @observable saveTried: boolean = false

  @computed
  public get isValid() {
    if (!this.saveTried) return true
    if (!this.categoryValid) return false
    if (!this.weightValid) return false
    return true
  }

  @computed
  public get categoryValid() {
    if (!this.saveTried) return true
    if (!this.categoryId) return false
    return true
  }

  @computed
  public get weightValid() {
    if (!this.saveTried) return true
    if (!this.weight || this.weight === 0) return false
    return true
  }

  @computed
  public get categoryOptions(): Array<any> {
    const options = []
    this.rootStore.categoriesStore.currentOrgCategories.forEach(e => {
      options.push({
        label: e.name,
        value: e.objectId,
      })
    })
    return options
  }
}
