import { observable, computed, action } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Survey } from '../aggregate/Survey'
import { ParticipantsSelectVM } from '../../participants-select/view-models/ParticipantsSelectVM'
import { PagedDataVM } from '../../shared/view-models/PagedDataVM'
import { IParticipant } from '../../participants-select/interfaces/IParticipant'
import { AdditionalResponseNotificationParticipantVM } from './AdditionalResponseNotificationParticipantVM'

export class AdditionalResponseNotificationEditorVM {
  rootStore: RootStore
  survey: Survey

  constructor(rootStore: RootStore, survey: Survey) {
    this.rootStore = rootStore
    this.addResponseNotificationParticipantVM = new ParticipantsSelectVM(
      this.rootStore,
      false,
      false,
      true
    )
    this.addResponseNotificationParticipantVM.setVisible()
    this.survey = survey
    this.loadVMData(survey)
    this.addResponseNotificationParticipantVM.setAddParticipantCallback((e) =>
      this.addParticipant(e)
    )
  }

  private addParticipant(e: IParticipant) {
    this.survey.addAdditionalResponseNotificationParticipant(e)
  }

  @observable public showParticipantSelectModal: boolean = false
  @observable public selectedUsers: Array<any> = []
  @observable public addResponseNotificationParticipantVM: ParticipantsSelectVM = null
  @observable public participantsKeywordFilter: string = ''
  @observable public participantsLoaded: boolean = false

  private async loadVMData(survey: Survey) {
    if (this.rootStore.audienceMembersStore)
      await this.rootStore.audienceMembersStore.loadAudienceMembers()
    setTimeout(() => {
      if (survey.additionalResponseNotificationParticipants.length)
        this.addResponseNotificationParticipantVM.setParticipants(
          survey.additionalResponseNotificationParticipants
        )
      this.participantsLoaded = true
    }, 500)
  }

  @action
  public removeParticipantById(id: string) {
    console.log(id)
    const newParticipants = this.addResponseNotificationParticipantVM.participants.filter(
      (p) => p.id !== id
    )
    console.log(newParticipants)
    this.addResponseNotificationParticipantVM.setParticipants(newParticipants)
    this.survey.updateAdditionalResponseNotificationParticipants(
      this.addResponseNotificationParticipantVM.allParticipants
    )
    console.log(this.addResponseNotificationParticipantVM.allParticipants)
  }

  @computed
  public get selectedParticipantsPagedData() {
    return new PagedDataVM(
      this.rootStore,
      this.selectedParticipants,
      ['name'],
      '',
      'VisibilityEditor',
      this.participantsKeywordFilter
    )
  }

  @computed
  public get selectedParticipants() {
    return this.survey.additionalResponseNotificationParticipants.map(
      (e) => new AdditionalResponseNotificationParticipantVM(this.rootStore, e)
    )
  }

  @action
  public toggleShowParticipantSelect() {
    this.showParticipantSelectModal = !this.showParticipantSelectModal
    if (!this.showParticipantSelectModal) {
      this.addResponseNotificationParticipantVM.setHidden()
    } else {
      this.addResponseNotificationParticipantVM.setVisible()
    }
  }
}
