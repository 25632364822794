import React from 'react'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { EventsStore } from '../../../store/EventsStore'
import { observer, inject } from 'mobx-react'

import {
  Grid,
  Button,
  InputLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import './CalendarExportModal.scss'
import { DatePicker } from '@material-ui/pickers'

interface Props {
  eventsStore?: EventsStore
  localizationStore?: LocalizationStore
}

const DownloadTab: React.FC<Props> = ({ eventsStore, localizationStore }) => {
  const { exportVM: vm } = eventsStore.calendarVM

  const renderSpinner = () => {
    if (!vm.isProcessing) return
    return (
      <Grid container item xs={12}>
        <CircularProgress
          color='secondary'
          style={{ position: 'relative', top: '50%', left: '90%' }}
        />
      </Grid>
    )
  }

  const renderDownloadButton = () => {
    if (vm.isProcessing) return
    return (
      <Grid item xs={12} className='download-button'>
        <Button variant='contained' onClick={() => vm.exportEvents()}>
          Download
        </Button>
      </Grid>
    )
  }

  return (
    <Grid container id='downloadTab'>
      <Grid item xs={12}>
        <Grid container className='download-form'>
          <Grid container justifyContent='space-between'>
            <Grid item xs={12} className='download-description'>
              <Typography variant='h6'>Select the dates you would like to export.</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent='space-between'>
                <Grid item>
                  <InputLabel>From</InputLabel>
                  <DatePicker
                    required
                    autoOk
                    id='fromDate'
                    variant='inline'
                    inputVariant='outlined'
                    margin='none'
                    fullWidth
                    value={vm.startDate}
                    onChange={(date) => vm.setStartDate(date)}
                    format='MMM dd, yyyy'
                  />
                </Grid>
                <Grid item>
                  <InputLabel>To</InputLabel>
                  <DatePicker
                    required
                    autoOk
                    id='toDate'
                    variant='inline'
                    inputVariant='outlined'
                    margin='none'
                    fullWidth
                    value={vm.endDate}
                    onChange={(date) => vm.setToDate(date)}
                    format='MMM dd, yyyy'
                  />
                </Grid>
                <Grid item>
                  <FormControl component='fieldset'>
                    <FormLabel
                      component='legend'
                      error={vm.fileTypeError}
                      className='file-type-label'
                    >
                      File Type
                    </FormLabel>
                    <RadioGroup
                      value={vm.fileType}
                      onChange={(e) => vm.setFileType(e.target.value)}
                    >
                      {/* <FormControlLabel
                        className='radio-label'
                        value='PDF'
                        control={<Radio className='radio' />}
                        label='PDF'
                      /> */}
                      <FormControlLabel
                        className='radio-label'
                        value='CSV'
                        control={<Radio className='radio' />}
                        label='CSV'
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className='download-button-container'>
              {renderDownloadButton()}
              {renderSpinner()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default inject('eventsStore', 'localizationStore')(observer(DownloadTab))
