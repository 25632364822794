import { observable } from 'mobx'
import { serializable } from 'serializr'
import { IFKUserDTO } from '../dtos/IFKUserDTO'

export class FKUser implements IFKUserDTO {
  @serializable @observable public name: string = ''
  @serializable @observable public email: string = ''
  @serializable @observable public username: string = ''
  @serializable @observable public firstName: string
  @serializable @observable public lastName: string
  @serializable @observable public iconURL: string
  @serializable @observable public objectId: string = ''
  @serializable @observable public phone: number
  @serializable @observable public birthDate: any
  @serializable @observable public languagePreference: string
  @serializable @observable public receiveEmails: boolean
  @serializable @observable public receivePushNotifications: boolean
  @serializable @observable public receivetextNotifications: boolean
}
