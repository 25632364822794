import React from 'react'
import { inject, observer } from 'mobx-react'
import { UserTasksStore } from '../../store/UserTasksStore'
import { Card, Drawer, CardContent } from '@material-ui/core'
import DrawerHeader from './DrawerHeader'
import DrawerButtonControls from './DrawerButtonControls'
import DrawerTaskDetails from './DrawerTaskDetails'
import DrawerCommentsContainer from './DrawerCommentsContainer'
import { AppStore } from '../../../stores/AppStore'
import './Drawer.scss'

interface Props {
  userTasksStore?: UserTasksStore
  appStore?: AppStore
}

const UserTasksDrawer: React.FC<Props> = ({ userTasksStore, appStore }) => {
  const handleClose = () => {
    userTasksStore.closeDrawer()

    const router = appStore.router
    if (
      router &&
      router.location.pathname.includes('dashboard/tasks') &&
      router.params.userTaskId
    ) {
      router.replace('/dashboard/tasks')
    }
  }

  return (
    <Drawer
      anchor='right'
      open={userTasksStore.isPendingUserTasksDrawerOpen}
      onClose={handleClose}
      id='PendingUserTasksWidgetDrawer'
    >
      <Card elevation={0}>
        <DrawerHeader handleClose={handleClose} />
        <DrawerButtonControls />
        <CardContent>
          <DrawerTaskDetails />
          <DrawerCommentsContainer />
        </CardContent>
      </Card>
    </Drawer>
  )
}

export default inject('appStore', 'userTasksStore')(observer(UserTasksDrawer))
