import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import CheckIcon from '@material-ui/icons/CheckRounded'
import { observer, inject } from 'mobx-react'
import { AvatarMenuRowVM } from 'src/app/user/view-models/AvatarMenuRowVM'

interface Props {
  vm?: AvatarMenuRowVM
}

const SelectOrgButton: React.FC<Props> = ({ vm }) => {
  const renderSelectedCheck = () => {
    if (!vm.isCurrentOrg) return null
    return <CheckIcon className='current-org-check' />
  }
  return (
    <MenuItem onClick={() => vm.click()} className='avatar-menu-item select-org-btn'>
      <Typography variant='inherit' noWrap>
        {vm.name}
      </Typography>
      {renderSelectedCheck()}
    </MenuItem>
  )
}

export default observer(SelectOrgButton)
