import React from 'react'
import { observer, inject } from 'mobx-react'
import {
  CardHeader,
  IconButton,
  Card,
  CardContent,
  TextField,
  Typography,
  Button,
} from '@material-ui/core'
import './WidgetHeader.scss'
import CalendarIcon from '@material-ui/icons/CalendarTodayRounded'
import Popover from '@material-ui/core/Popover'
import DateRangeModal from './DateRangeModal/DateRangeModal'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import { Dropdown } from 'semantic-ui-react'
import CloseIcon from '@material-ui/icons/Close'
import { ManageSurveysWidgetVM } from '../../view-models/manage-surveys-widget/ManageSurveysWidgetVM'

interface FilterPopupProps {
  widgetVM: ManageSurveysWidgetVM
  localizationStore?: LocalizationStore
}

const FilterPopup: React.FC<FilterPopupProps> = ({ widgetVM, localizationStore }) => {
  const vm = widgetVM.filterPopupVM
  if (!widgetVM || !vm) return null

  const close = () => widgetVM.setFilterPopupAnchorEl(null)

  const renderCardHeader = () => {
    return (
      <CardHeader
        title={
          <div className='card-header-title-container'>
            <div>Filter Surveys</div>
            <div>
              <IconButton onClick={() => close()}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        }
        className='filter-popover-card-header'
      />
    )
  }

  const renderCardContent = () => {
    return (
      <CardContent className='filter-popover-card-content'>
        <div className='date-range-picker-section'>
          <div>Date Range</div>
          <div className='date-range-picker-div'>
            <TextField
              variant='outlined'
              disabled
              value={vm.startDateFormatted}
              autoComplete='off'
            />
            <Typography style={{ margin: '0 5px' }}>-</Typography>
            <TextField variant='outlined' disabled value={vm.endDateFormatted} autoComplete='off' />
            <IconButton onClick={() => vm.toggleDateRangeModal()}>
              <CalendarIcon />
            </IconButton>
            {vm.startDate || vm.endDate ? (
              <Button
                variant='outlined'
                onClick={() => {
                  vm.clearDateRangeFilter()
                  // vm.onFilterChanged()
                }}
              >
                Clear
              </Button>
            ) : null}
            <DateRangeModal
              onChange={(range) => {
                vm.setDateRange(range)
                // vm.onFilterChanged()
              }}
              range={vm.dateRange}
              open={vm.dateRangeModalOpen}
              onClose={() => vm.toggleDateRangeModal()}
            />
          </div>
        </div>
        <div>
          <div className='groups-section-label'>Survey Owner</div>
          <Dropdown
            placeholder='Survey Owner'
            fluid
            multiple
            search
            selection
            options={vm.surveyOwnerOptions}
            value={vm.filterSurveyOwnerIds.slice()}
            onChange={(e, { value }) => {
              vm.setFilterSurveyOwnerIds(value)
              // vm.onFilterChanged()
            }}
            clearable
          />
        </div>

        <div>
          <div className='groups-section-label'>Survey Type</div>
          <Dropdown
            placeholder='Survey Type'
            fluid
            multiple
            search
            selection
            options={vm.surveyTypeOptions}
            value={vm.filterSurveyTypeIds.slice()}
            onChange={(e, { value }) => {
              vm.setFilterSurveyTypeIds(value)
              // vm.onFilterChanged()
            }}
            clearable
          />
        </div>

        <div>
          <div className='groups-section-label'>Groups</div>
          <Dropdown
            placeholder='Groups'
            fluid
            multiple
            search
            selection
            options={vm.groupOptions}
            value={vm.filterGroupIds.slice()}
            onChange={(e, { value }) => {
              vm.setFilterGroupIds(value)
              // vm.onFilterChanged()
            }}
            clearable
          />
        </div>
        <div>
          <div className='roles-section-label'>Roles</div>
          <Dropdown
            placeholder='Roles'
            fluid
            multiple
            search
            selection
            options={vm.roleOptions}
            value={vm.filterRoleIds.slice()}
            onChange={(e, { value }) => {
              vm.setFilterRoleIds(value)
              // vm.onFilterChanged()
            }}
            clearable
          />
        </div>
        <div>
          <div className='participants-section-label'>Participants</div>
          <Dropdown
            placeholder='Participants'
            fluid
            multiple
            search
            selection
            options={vm.userOptions}
            value={vm.filterUserIds.slice()}
            onChange={(e, { value }) => {
              vm.setFilterUserIds(value)
              // vm.onFilterChanged()
            }}
            clearable
          />
        </div>
        <div>
          <div className='categories-section-label'>Categories</div>
          <Dropdown
            placeholder='Categories'
            fluid
            multiple
            search
            selection
            options={vm.categoryOptions}
            value={vm.filterCategoryIds.slice()}
            onChange={(e, { value }) => {
              vm.setFilterCategoryIds(value)
              // vm.onFilterChanged()
            }}
            clearable
          />
        </div>
        <div className='apply'>
          <Button
            disabled={!vm.hasFilter}
            onClick={() => {
              vm.clearFilters()
              // close()
            }}
          >
            Clear All
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              vm.handleFiltering()
              close()
            }}
          >
            Apply
          </Button>
        </div>
      </CardContent>
    )
  }

  return (
    <Popover
      id='ManageSurveysWidgetFilterPopover'
      open={Boolean(widgetVM.filterPopupAnchorEl)}
      anchorEl={widgetVM.filterPopupAnchorEl}
      onClose={() => close()}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Card id='ManageSurveysWidgetFilterPopoverCard'>
        {renderCardHeader()}
        {renderCardContent()}
      </Card>
    </Popover>
  )
}

export default inject('surveysStore', 'localizationStore')(observer(FilterPopup))
