import React, { useState, useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import { WorksheetsStore } from '../../store/WorksheetsStore'
import TableauReport from '../TableauReport'
import { CircularProgress } from '@material-ui/core'
import { TableauScriptService } from '../../../tableau/service/TableauScriptService'
import './TableauReportContainer.scss'

interface Props {
  worksheetsStore?: WorksheetsStore
}

const TableauReportContainer: React.FC<Props> = ({ worksheetsStore }) => {
  const [scriptLoaded, setScriptLoaded] = useState(false)
  useEffect(() => {
    TableauScriptService.loadTableauScript(() => setScriptLoaded(true))
  })

  const { editVM } = worksheetsStore
  if (!editVM) return null
  if (!editVM.tableauWorksheet && !editVM.newtableauWorkbookMode) return null
  if (editVM.newtableauWorkbookMode && !editVM.tableauDataSource) return null

  if (editVM.chartType === 'tableau' && !editVM.tableauAuthToken)
    return (
      <CircularProgress color='secondary' style={{ position: 'fixed', top: '50%', left: '50%' }} />
    )

  const renderReport = () => {
    if (editVM.width === 0) return null
    if (!scriptLoaded) return null
    return (
      <TableauReport
        key={editVM.tableauViewUrl + editVM.tableauAuthToken + editVM.tableauQuery}
        token={editVM.tableauAuthToken}
        onVizLoaded={(div, viz) => editVM.setTableauVizContainer(div, viz)}
        query={editVM.tableauQuery}
        options={{
          width: editVM.width.toString() + 'px',
          height: editVM.height.toString() + 'px',
          device: 'desktop',
          hideTabs: !editVM.showTabs,
        }}
        url={editVM.tableauViewUrl}
      />
    )
  }

  return <div id='TableauReportContainer'>{renderReport()}</div>
}

export default inject('worksheetsStore')(observer(TableauReportContainer))
