import { action, observable, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Question } from '../aggregate/Question'
import { QuestionType } from '../types/QuestionType'
import { SurveyResultsRowVM } from './SurveyResultsRowVM'
import { SurveyResultsVM } from './SurveyResultsVM'

export class QuestionVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, question: Question, index: number, resultsVM: SurveyResultsVM) {
    this.rootStore = rootStore
    this.question = question
    this.index = index
    this.resultsVM = resultsVM
  }

  public question: Question = null
  public resultsVM: SurveyResultsVM = null
  public index: number = 0

  @computed
  public get title(): string {
    return this.question.title
  }

  @computed
  public get type(): QuestionType {
    return this.question.type
  }

  @computed
  public get options() {
    return this.question.options
  }

  @computed
  public get ratingAverage(): number {
    if (!this.currentQuestionResult) return 0
    let totalRank = 0
    const totalResponses = this.currentQuestionResult.totalResponses
    this.currentOptionsResults.forEach((o) => {
      const option = this.question.options.find((i) => i.id === o.option.id)
      const responses = this.currentQuestionResult[o.option.id]
      totalRank = totalRank + responses * option.rank
    })
    let average = totalRank / totalResponses
    const ratio = average / this.question.options.length
    const starValue = ratio * this.question.options.length
    return Math.round(starValue / 0.5) * 0.5
  }

  @computed
  public get ratingAverageText(): string {
    const index = Math.round(this.ratingAverage) - 1
    if (index < 0) return ''
    if (this.type === 'rating' || this.type === 'starRating') {
      const currentSelectedOption = this.options[index].text
      return currentSelectedOption ? currentSelectedOption : ''
    }
    return null
  }

  @computed
  public get myRatingAnswer(): number {
    const checkedAnswer = this.question.options.find((e) => e.isChecked)
    const ratio = checkedAnswer.rank / this.question.options.length
    const starValue = ratio * this.question.options.length
    return Math.round(starValue / 0.5) * 0.5
  }

  @computed
  public get myRatingText(): string {
    const index = this.myRatingAnswer - 1
    if (index < 0) return ''
    if (this.type === 'rating' || this.type === 'starRating') {
      const currentSelectedOption = this.options[index].text
      return currentSelectedOption ? currentSelectedOption : ''
    }
    return null
  }

  @computed
  public get currentQuestionResult() {
    if (!this.resultsVM.surveyResult) return
    const foundQuestionResult = this.resultsVM.surveyResult.questionAnswers[this.question.id]
    if (foundQuestionResult) return foundQuestionResult
  }

  @computed
  public get orderOptionsAverageResults(): SurveyResultsRowVM[] {
    if (this.question.type !== 'order') return []
    if (!this.resultsVM.surveyResult) return []
    const foundQuestionResult = this.resultsVM.surveyResult.questionAnswers[this.question.id]
    const newOptionsArray = []
    const orderedOptionsArray = []
    this.question.options.map((o) => {
      const rank = foundQuestionResult[o.id]
      const newObject = { id: o.id, rank: rank }
      newOptionsArray.push(newObject)
    })
    const newOrder = newOptionsArray.slice().sort(function (a, b) {
      return a.rank - b.rank
    })
    newOrder.map((o) => {
      const foundOption = this.question.options.find((e) => o.id === e.id)
      orderedOptionsArray.push(foundOption)
    })
    return orderedOptionsArray.map(
      (o) => new SurveyResultsRowVM(this.rootStore, o, this.currentQuestionResult)
    )
  }

  @computed
  public get currentOptionsResults(): SurveyResultsRowVM[] {
    if (!this.currentQuestionResult) return []
    if (this.question.type === 'order') {
      const newOrder = this.question.options.slice().sort(function (a, b) {
        return a.rank - b.rank
      })
      return newOrder.map(
        (o) => new SurveyResultsRowVM(this.rootStore, o, this.currentQuestionResult)
      )
    }
    return this.question.options.map(
      (o) => new SurveyResultsRowVM(this.rootStore, o, this.currentQuestionResult)
    )
  }
}
