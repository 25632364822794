import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { TasksStore } from '../../store/TasksStore'
import { Grid, InputLabel, OutlinedInput } from '@material-ui/core'

interface DrawerTaskDetailsDescriptionProps {
  localizationStore?: LocalizationStore
  tasksStore?: TasksStore
}

const DrawerTaskDetailsDescription: React.FC<DrawerTaskDetailsDescriptionProps> = ({
  localizationStore,
  tasksStore,
}) => {
  const { lzStrings } = localizationStore
  const { taskEditVM: VM } = tasksStore

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    VM.setTaskDescription(e.target.value)
  }

  return (
    <Grid item xs={12}>
      <InputLabel htmlFor='taskDescription'>
        {lzStrings.tasksManagementWidgetDrawer.task_description}
      </InputLabel>
      <OutlinedInput
        multiline
        rows={3}
        id='taskDescription'
        fullWidth
        labelWidth={0}
        value={VM.description}
        onChange={handleDescriptionChange}
        required
      />
    </Grid>
  )
}

export default inject('localizationStore', 'tasksStore')(observer(DrawerTaskDetailsDescription))
