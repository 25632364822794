import React from 'react'
import { ParticipantsSelectVM } from '../view-models/ParticipantsSelectVM'
import { Grid, ButtonBase } from '@material-ui/core'
import { observer } from 'mobx-react'

interface Props {
  participantsSelectVM: ParticipantsSelectVM
}
const TabButtons: React.FC<Props> = ({ participantsSelectVM }) => {
  const vm = participantsSelectVM

  return (
    <>
      {vm.showEventUsers && (
        <ButtonBase
          className={vm.currentTab === 5 ? 'tab-button active' : 'tab-button'}
          onClick={() => vm.setCurrentTab(5)}
        >
          From Event
        </ButtonBase>
      )}
      <ButtonBase
        className={vm.currentTab === 0 ? 'tab-button active' : 'tab-button'}
        onClick={() => vm.setCurrentTab(0)}
      >
        Users
      </ButtonBase>
      {vm.showGroups && (
        <ButtonBase
          className={vm.currentTab === 1 ? 'tab-button active' : 'tab-button'}
          onClick={() => vm.setCurrentTab(1)}
        >
          By Group
        </ButtonBase>
      )}
      {vm.showRoles && (
        <ButtonBase
          className={vm.currentTab === 2 ? 'tab-button active' : 'tab-button'}
          onClick={() => vm.setCurrentTab(2)}
        >
          By Role
        </ButtonBase>
      )}
      {vm.showClients && (
        <ButtonBase
          className={vm.currentTab === 3 ? 'tab-button active client' : 'tab-button client'}
          onClick={() => vm.setCurrentTab(3)}
        >
          By {vm.clientLabel}
        </ButtonBase>
      )}
      {vm.showEmail && (
        <ButtonBase
          className={vm.currentTab === 4 ? 'tab-button active' : 'tab-button'}
          onClick={() => vm.setCurrentTab(4)}
        >
          By Email
        </ButtonBase>
      )}
    </>
  )
}

export default observer(TabButtons)
