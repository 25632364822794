import React from 'react'
import { observer, inject } from 'mobx-react'
import { CardHeader, Tooltip, IconButton } from '@material-ui/core'
import Searchbar from '../../../shared/Searchbar'
import AddIcon from '@material-ui/icons/Add'
import LocalizationStore from '../../../localization/LocalizationStore'
import { OrganizationsSAStore } from '../../store/OrganizationsSAStore'
import './OrganizationsSAListWidget.scss'
import './Header.scss'

interface Props {
  organizationsSAStore?: OrganizationsSAStore
  localizationStore?: LocalizationStore
}

const Header: React.FC<Props> = ({ organizationsSAStore, localizationStore }) => {
  const { listWidgetVM } = organizationsSAStore
  if (!listWidgetVM) return null
  const { lzStrings } = localizationStore

  return (
    <CardHeader
      id='Header'
      title={lzStrings.organizationsSAList.tenants}
      action={
        <>
          <div className='search'>
            <Searchbar
              focused={listWidgetVM.keywordFilter.length > 0}
              searchValue={listWidgetVM.keywordFilter}
              onSearchChange={(e) => listWidgetVM.setKeywordFilter(e.target.value)}
            />
          </div>
          <React.Fragment>
            <Tooltip title={lzStrings.organizationsSAList.add_new_tooltip} placement='bottom'>
              <span>
                <IconButton
                  className='widget-cta'
                  size='small'
                  disabled={listWidgetVM.isLoading}
                  onClick={() => listWidgetVM.openAddDrawer()}
                >
                  <AddIcon />
                </IconButton>
              </span>
            </Tooltip>
          </React.Fragment>
        </>
      }
    ></CardHeader>
  )
}

export default inject('organizationsSAStore', 'localizationStore')(observer(Header))
