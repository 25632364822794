import React from 'react'
import { observer } from 'mobx-react'
import { Responsive, WidthProvider } from 'react-grid-layout'
import WidgetContainer from '../user-dashboards/views/UserDashboard/WidgetContainer'
import DashboardEditVM from '../view-models/DashboardEditVM'

interface Props {
  RGLprops: any
  rowHeight: any
  minRowHeight: any
  vm: DashboardEditVM
}

const RGL = WidthProvider(Responsive)

const DashboardEditWidgets: React.FC<Props> = ({
  rowHeight,
  minRowHeight,
  RGLprops,
  vm,
}) => {
  const layout = vm.widgets.slice()
  const layouts = {
    xxs: layout,
    xs: layout,
    sm: layout,
    md: layout,
    lg: layout,
  }
  RGLprops.layouts = layouts

  const renderWidgets = () => {
    let widgets = []
    layout.forEach(e => {
      widgets.push(
        <div className='widget-container' key={e.index}>
          <WidgetContainer widget={e}/>
        </div>
      )
    })
    return widgets
  }

  return (
    <div>
      {layout && (
        <RGL {...RGLprops} rowHeight={Math.max(rowHeight, minRowHeight)}>
          {renderWidgets()}
        </RGL>
      )}
    </div>
  )
}

export default (observer(DashboardEditWidgets))
