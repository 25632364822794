import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { CircularProgress, Dialog, Grid } from '@material-ui/core'
import rootStore from '../../../stores/RootStore'
import { EmailTemplatePreviewVM } from '../../view-models/EmailTemplatePreviewVM'
import EmailTemplateEditTopToolbar from './EmailTemplateEditTopToolbar'
import EmailTemplateSettingsForm from './../settings/EmailTemplateSettingsForm'
import EmailTemplatesEditorHTMLTab from './../editor/EmailTemplatesEditorHTMLTab'
import EmailTemplatesEditorTestDataTab from './../editor/EmailTemplatesEditorTestDataTab'
import EmailTemplateSettingsDrawer from './../settings/EmailTemplateSettingsDrawer'
import LocalizationStore from '../../../localization/LocalizationStore'
import './EmailTemplateEditDialog.scss'

interface Props {
  vm: EmailTemplatePreviewVM
  localizationStore?: LocalizationStore
}

const EmailTemplatEditDialog: React.FC<Props> = ({ vm, localizationStore }) => {
  if (!vm) return

  const cleanup = () => {
    vm.disposeReactions()
  }

  useEffect(() => {
    return () => cleanup()
  }, [])

  const renderSpinner = () => {
    if (!vm.isLoading && !vm.isSaving && !vm.isSending) return
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  const renderEmailTemplateSettings = () => {
    if (vm.isLoading) return
    if (!vm.emailTemplate) return
    return <EmailTemplateSettingsForm vm={vm} localizationStore={rootStore.localizationStore} />
  }

  return (
    <Dialog
      fullScreen
      open={vm.isOpen}
      aria-labelledby='email-template-edit-dialog-title'
      aria-describedby='email-template-edit-dialog-description'
      disableEnforceFocus={true}
      disableAutoFocus={true}
    >
      <div id='EmailTemplateEditDialogAppBar'>
        <EmailTemplateEditTopToolbar vm={vm} />
      </div>
      <Grid
        className='email-template-edit-dialog-grid'
        container
        spacing={0}
        justifyContent='center'
        alignItems='center'
      >
        {renderSpinner()}
        <EmailTemplateSettingsDrawer>{renderEmailTemplateSettings()}</EmailTemplateSettingsDrawer>
        <EmailTemplatesEditorHTMLTab vm={vm} />
        <EmailTemplatesEditorTestDataTab vm={vm} />
      </Grid>
    </Dialog>
  )
}

export default inject('localizationStore')(observer(EmailTemplatEditDialog))
