import { observable, action } from 'mobx'

export class Location {
  @observable public name: string = ''
  @observable public lat: number = 0
  @observable public lng: number = 0

  @action
  public setName(val: string) {
    this.name = val
  }

  @action
  public setLat(val: number) {
    this.lat = val
  }

  @action
  public setLng(val: number) {
    this.lng = val
  }
}
