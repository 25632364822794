import { action, observable, computed } from 'mobx'
import { QuizResult } from '../aggregate/QuizResult'
import { RootStore } from '../../stores/RootStore'
import { Quiz } from '../../quiz/aggregate/Quiz'
import { Question } from '../../quiz/aggregate/Question'
import { QuizResultOptionVM } from './QuizResultOptionVM'
import { QuestionType } from '../../quiz/types/QuestionType'

export class QuizResultQuestionVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, question: Question, quizResult: QuizResult, index: number) {
    this.rootStore = rootStore
    this.question = question
    this.quizResult = quizResult
    this.index = index + 1
  }

  @observable public question: Question = null
  @observable public quizResult: QuizResult = null
  @observable public index: number = 0

  @computed
  public get options(): QuizResultOptionVM[] {
    return this.question.options.map(
      (e) => new QuizResultOptionVM(this.rootStore, e, this.currentQuestionResult, this)
    )
  }

  @computed public get isEvenQuestion(): boolean {
    return !Boolean(this.index % 2)
  }

  @computed
  public get title(): string {
    return this.question.title
  }

  @computed
  public get type(): QuestionType {
    return this.question.type
  }

  @computed
  public get isCorrect(): boolean {
    if (this.type === 'text') return true
    return this.options.some((e) => e.isCorrect)
  }

  @computed
  public get currentQuestionResult() {
    if (!this.quizResult) return
    const foundQuestionResult = this.quizResult.questionAnswers[this.question.id]
    if (foundQuestionResult) return foundQuestionResult
  }
}
