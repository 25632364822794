import React from 'react'
import { observer, inject } from 'mobx-react'
import { PulseCampaignsStore } from '../../store/PulseCampaignsStore'
import { Grid, IconButton, DialogTitle } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import './DialogHeader.scss'

interface Props {
  pulseCampaignsStore?: PulseCampaignsStore
  title: string
  onClick: Function
}

const DialogHeader: React.FC<Props> = ({ pulseCampaignsStore, title, onClick }) => {
  return (
    <div id='PulseCampaignDialogHeader'>
      <Grid id='pulseCampaignsDialogHeader' container alignItems='center'>
        <Grid item xs={11}>
          <DialogTitle className={'pulse-campaign-header-title'} disableTypography>
            {title}
          </DialogTitle>
        </Grid>
        <Grid className={'pulse-campaign-header-right'} item xs={1}>
          <IconButton
            className={'pulse-campaign-header-close'}
            onClick={() => onClick()}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  )
}

export default inject('pulseCampaignsStore', 'localizationStore')(observer(DialogHeader))
