import React from 'react'
import { ListItem, Grid, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'
import { ErrorRowVM } from '../view-models/ErrorRowVM'
import './ContactImportRow.scss'

interface Props {
  row: ErrorRowVM
}

const ErrorRow: React.FC<Props> = ({ row }) => {
  const renderErrorMessage = () => {
    return (
      <Typography className='result-error' variant='h6'>
        {row.message}
      </Typography>
    )
  }

  return (
    <ListItem id='ContactErrorRow'>
      <Grid container direction='column'>
        <Grid item>{renderErrorMessage()}</Grid>
      </Grid>
    </ListItem>
  )
}

export default observer(ErrorRow)
