import React from 'react'
import { inject, observer } from 'mobx-react'
import { InputLabel, Grid, Toolbar, IconButton, Typography, CardContent } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'
import DoneIcon from '@material-ui/icons/Done'
import { CalendarSettingsStore } from '../../../calendar-settings/store/CalendarSettingsStore'
import { KeyboardTimePicker } from '@material-ui/pickers'
import './TimesTab.scss'
import { LabelsStore } from 'src/app/labels/store/LabelsStore'

interface Props {
  calendarSettingsStore?: CalendarSettingsStore
  labelsStore?: LabelsStore
}

const TimesTab: React.FC<Props> = ({ calendarSettingsStore, labelsStore }) => {
  const { editForm } = calendarSettingsStore

  const renderPickers = (editForm) => {
    return (
      <div>
        <Grid container className='pickerContainer'>
          <InputLabel htmlFor='start-time'>
            Start {labelsStore.getLabelByLanguageAndFor('working hours')}
          </InputLabel>
          <KeyboardTimePicker
            inputVariant={editForm.editScreen ? 'outlined' : 'standard'}
            disabled={!editForm.editScreen}
            margin='none'
            id='start-time'
            value={editForm.eventStart}
            fullWidth
            minutesStep={15}
            onChange={(e) => (editForm.eventStart = e)}
            className={!editForm.editScreen ? 'hide-adornment' : ''}
            KeyboardButtonProps={{
              'aria-label': 'change time',
              edge: 'end',
            }}
          />
        </Grid>

        <Grid container className='pickerContainer'>
          <InputLabel htmlFor='end-time'>
            End {labelsStore.getLabelByLanguageAndFor('working hours')}
          </InputLabel>
          <KeyboardTimePicker
            inputVariant={editForm.editScreen ? 'outlined' : 'standard'}
            disabled={!editForm.editScreen}
            margin='none'
            id='end-time'
            value={editForm.eventEnd}
            fullWidth
            minutesStep={15}
            onChange={(e) => (editForm.eventEnd = e)}
            className={!editForm.editScreen ? 'hide-adornment' : ''}
            KeyboardButtonProps={{
              'aria-label': 'change time',
              edge: 'end',
            }}
          />
        </Grid>
      </div>
    )
  }

  const renderError = () => {
    return (
      <Grid container justifyContent='center'>
        <Grid item>
          <Typography className='timesError'>
            Please ensure your latest start time is after your earliest start time.
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <CardContent id='TimesTab'>
      {renderPickers(editForm)}
      {!editForm.timesValid && renderError()}
    </CardContent>
  )
}

export default inject('calendarSettingsStore', 'labelsStore')(observer(TimesTab))
