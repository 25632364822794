import { inject, observer } from 'mobx-react'
import { Button, CardHeader, Grid, Tooltip } from '@material-ui/core'
import React from 'react'
import './AnnouncementListHeader.scss'
import Searchbar from '../../../shared/Searchbar'
import { AnnouncementListVM } from '../../view-models/list/AnnouncementListVM'
import RefreshIcon from '@material-ui/icons/Refresh'
import { LabelsStore } from '../../../labels/store/LabelsStore'

interface Props {
  announcementListVM: AnnouncementListVM
  labelsStore?: LabelsStore
}

const AnnouncementListHeader: React.FC<Props> = ({ announcementListVM, labelsStore }) => {
  const vm = announcementListVM
  const tableVM = vm.tableVM
  if (!vm) return

  const renderSearch = () => {
    return (
      <div className='search'>
        <Searchbar
          focused={tableVM.hasFilter}
          searchValue={tableVM.typedFilterText}
          onSearchChange={(e) => {
            tableVM.setQuickFilter(e.target.value)
          }}
        />
      </div>
    )
  }

  const renderRefreshButton = () => {
    return (
      <Tooltip title={'Refresh'} placement='bottom'>
        <Button
          onClick={(e) => {
            tableVM.hardRefresh()
          }}
          variant='outlined'
          className='action-btn-end refresh-btn'
        >
          <RefreshIcon />
        </Button>
      </Tooltip>
    )
  }

  return (
    <>
      <CardHeader
        id='TableHeader'
        title={labelsStore.getLabelByLanguageAndFor('announcements')}
        action={
          <>
            <Grid container>
              {renderSearch()}
              {renderRefreshButton()}
            </Grid>
          </>
        }
      ></CardHeader>
    </>
  )
}

export default inject('labelsStore')(observer(AnnouncementListHeader))
