import { Dialog, DialogContent, DialogTitle, DialogActions, Button } from '@material-ui/core'
import { AgGridReact } from 'ag-grid-react'
import { inject, observer } from 'mobx-react'
import React from 'react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import './SentimentDialog.scss'
import { QuestionVM } from '../../view-models/QuestionVM'
import LocalizationStore from '../../../localization/LocalizationStore'
import { SentimentAnalysisRowVM } from '../../view-models/SentimentAnalysisRowVM'

interface Props {
  questionVM: QuestionVM
  localizationStore?: LocalizationStore
}

const SentimentDialog: React.FC<Props> = ({ questionVM, localizationStore }) => {
  if (!questionVM) return null
  if (!questionVM.showSentimentAnalysis) return null
  const sentimentVM = questionVM.sentimentAnalysisVM
  const { nlp: lz } = localizationStore.lzStrings
  if (!sentimentVM) return null

  const renderSentimentCell = (props) => {
    const row: SentimentAnalysisRowVM = props.node.data
    if (!row) return null
    const className = row.isPositiveSentiment ? 'positive-sentiment' : 'negative-sentiment'
    return (
      <span className={className}>{row.sentiment}</span>
    )
  }

  return (
    <Dialog
      className='sentiment-dialog'
      maxWidth={'lg'}
      onClose={() => questionVM.toggleSentimentAnalysis()}
      open={questionVM.showSentimentAnalysis}
    >
      <DialogTitle className='dialog-title'>{lz.nlp}</DialogTitle>
      <DialogContent>
        <p className='dialog-text'>{questionVM.textAnswer}</p>
        <div className='ag-theme-alpine'>
          <AgGridReact
            gridOptions={{ suppressCellSelection: true, rowModelType: 'infinite', frameworkComponents: {
              sentimentCell: renderSentimentCell
            } }}
            rowSelection={'multiple'}
            suppressRowClickSelection={true}
            suppressClickEdit={false}
            columnDefs={sentimentVM.columnDefs}
            onGridReady={(e) => sentimentVM.onGridReady(e)}
            getRowNodeId={(e) => sentimentVM.getRowNodeId(e)}
            editType=''
          />
        </div>
      </DialogContent>
      <DialogActions className='sentiment-actions'>
        <Button variant='outlined' size='large' onClick={() => questionVM.showTrainAI()}>
          {lz.train}
        </Button>
        <Button
          variant='contained'
          size='large'
          onClick={() => questionVM.toggleSentimentAnalysis()}
        >
          {lz.close}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default inject('localizationStore')(observer(SentimentDialog))
