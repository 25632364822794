import { observable, action, computed } from 'mobx'
import { RootStore } from 'src/app/stores/RootStore'
import PulseCampaignType from '../aggregate/PulseCampaignType'
import { IPulseCampaignTypeDTO } from '../dtos/IPulseCampaignTypeDTO'
import { DataStore } from '../../shared/data/DataStore'
import PulseCampaignTypeEditVM from '../view-models/PulseCampaignTypeEditVM'
import Parse from 'parse'

export class PulseCampaignTypesStore extends DataStore<PulseCampaignType, IPulseCampaignTypeDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, PulseCampaignType, 'pulseCampaignTypes', ['name', 'isActive'])
  }

  @observable public editVM: PulseCampaignTypeEditVM = null
  @observable public isLoaded: boolean = false

  protected getBaseQuery() {
    const query = new Parse.Query(this.className)
    query.notEqualTo('isDeleted', true)
    return query
  }

  @computed
  public get pulseCampaignTypes(): PulseCampaignType[] {
    return this.records
  }

  @action
  public onListRecordsLoaded() {
    this.isLoaded = true
  }

  @action
  public getPulseCampaignType(objectId): PulseCampaignType {
    return this.pulseCampaignTypes.find((e) => e.objectId === objectId)
  }

  @action
  public getPulseCampaignTypeByName(name): PulseCampaignType {
    return this.pulseCampaignTypes.find((e) => e.name === name)
  }

  @action
  public getPulseCampaignTypeIndex(objectId): number {
    return this.pulseCampaignTypes.findIndex((e) => e.objectId === objectId)
  }

  @action
  public editPulseCampaignType(objectId: string) {
    return this.rootStore.appStore.router.push(`/systemAdmin/manage/pulseCampaignTypes/${objectId}`)
  }
}
