import React from 'react'
import { observer, inject } from 'mobx-react'
import { CardHeader, Tooltip, IconButton } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import LocalizationStore from '../../../../localization/LocalizationStore'
import Searchbar from '../../../../shared/Searchbar'
import { TrainingPlansCatalogStore } from '../../../store/TrainingPlansCatalogStore'

interface Props {
  trainingPlansCatalogStore?: TrainingPlansCatalogStore
  localizationStore?: LocalizationStore
}

const Header: React.FC<Props> = ({ trainingPlansCatalogStore, localizationStore }) => {
  const { catalogPlansWidgetVM: vm } = trainingPlansCatalogStore
  if (!vm) return null
  const { training_plans: strings } = localizationStore.lzStrings

  return (
    <CardHeader
      id='Header'
      title={strings.plans}
      action={
        <>
          <div className='search'>
            <Searchbar
              focused={vm.keywordFilter.length > 0}
              searchValue={vm.keywordFilter}
              onSearchChange={(e) => vm.setKeywordFilter(e.target.value)}
            />
          </div>
          <React.Fragment>
            <Tooltip title={strings.create_catalog_plan} placement='bottom'>
              <IconButton
                className='widget-cta'
                size='small'
                onClick={() => vm.createNewCatalogPlan()}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </React.Fragment>
        </>
      }
    />
  )
}

export default inject('trainingPlansCatalogStore', 'localizationStore')(observer(Header))
