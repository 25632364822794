import { action, computed, observable } from 'mobx'
import DashboardEditVM from './DashboardEditVM'
import WidgetTileVM from './WidgetTileVM'
import { RootStore } from '../../stores/RootStore'

export default class WidgetDrawerVM {
  rootStore: RootStore
  editVM: DashboardEditVM

  constructor(rootStore: RootStore, editVM: DashboardEditVM) {
    this.rootStore = rootStore
    this.editVM = editVM
  }

  @observable public filter: string = ''

  @computed
  public get availableWidgets() {
    const widgets = this.userAvailableWidgets.sort((a, b) => a.name.localeCompare(b.name))
    if (this.filter && this.filter.trim() !== '') {
      const filter = this.filter.replace(/\s/g, '')
      return widgets.filter((widget) =>
        widget.name.toLowerCase().replace(/\s/g, '').includes(filter.toLowerCase())
      )
    }
    return widgets
  }

  @action
  public setFilter(val) {
    this.filter = val
  }

  @computed
  public get availableWidgetTypes() {
    let widgetTypes = []
    if (this.rootStore.appStore.isOrgAdmin) widgetTypes.push('admin')
    if (this.rootStore.appStore.canAssignWork) widgetTypes.push('can_assign_work')
    if (this.rootStore.appStore.canChat) widgetTypes.push('can_chat')
    if (this.rootStore.appStore.canManageCategories) widgetTypes.push('can_manage_categories')
    if (this.rootStore.appStore.canSchedule) widgetTypes.push('can_schedule')
    if (this.rootStore.appStore.canSurvey) widgetTypes.push('can_survey')
    if (
      this.rootStore.appStore.canCreateTrainings &&
      this.rootStore.organizationsStore.currentOrganization.isTrainingsEnabled
    )
      widgetTypes.push('can_create_trainings')
    if (this.rootStore.organizationsStore.currentOrganization.isTrainingsEnabled)
      widgetTypes.push('trainings_enabled')
    if (this.rootStore.appStore.canManageCustomCollections)
      widgetTypes.push('can_manage_custom_collections')
    if (this.rootStore.appStore.canAccessWorksheets) widgetTypes.push('can_access_worksheets')
    widgetTypes.push('any')
    return widgetTypes
  }

  @computed
  public get userAvailableWidgets() {
    let userWidgets = []
    this.availableWidgetTypes.forEach((value) => {
      let foundWidgets = this.widgetsByPriv(value)
      Array.prototype.push.apply(userWidgets, foundWidgets)
    })
    return userWidgets.filter((e, i) => userWidgets.findIndex((a) => a['i'] === e['i']) === i)
  }

  @action
  public widgetsByPriv(value) {
    let widgets = []
    this.allWidgets.forEach((widget) => {
      if (widget.privileges.includes(value)) {
        widgets.push(new WidgetTileVM(this.editVM, widget))
      }
    })

    return widgets
  }

  @action
  public isWidgetUsed(type) {
    if (type === 'WorksheetWidget') return false
    let foundWidget = this.editVM.layout.find((e) => e.type === type)
    if (foundWidget) return true
    return false
  }

  @computed
  public get allWidgets() {
    const filePath = '../../../assets/img/widgets/'
    return [
      {
        i: '0',
        x: 0,
        y: 0,
        h: 2,
        w: 2,
        type: 'MySurveysWidget',
        name: 'My Surveys',
        permission: 'user',
        privileges: ['any'],
        img: `${filePath}pending-surveys.png`,
      },
      {
        i: '1',
        x: 0,
        y: 0,
        h: 2,
        w: 2,
        type: 'ManageSurveysWidget',
        name: 'Manage Surveys',
        permission: 'user',
        privileges: ['can_survey'],
        img: `${filePath}manage-surveys.png`,
      },
      {
        i: '2',
        x: 0,
        y: 0,
        h: 2,
        w: 2,
        type: 'NotificationsWidget',
        name: 'Notifications',
        permission: 'admin',
        privileges: ['admin'],
        img: `${filePath}manage-notifications-2.png`,
      },
      {
        i: '3',
        x: 0,
        y: 0,
        h: 2,
        w: 1,
        type: 'OrganizationGeneralWidget',
        name: 'Organization Details',
        permission: 'admin',
        privileges: ['admin'],
        img: `${filePath}org-details.png`,
      },
      {
        i: '4',
        x: 0,
        y: 0,
        h: 2,
        w: 3,
        type: 'OrgUsersTableWidget',
        name: 'Organization Users',
        permission: 'admin',
        privileges: ['admin'],
        img: `${filePath}manage-users.png`,
      },
      {
        i: '5',
        x: 0,
        y: 0,
        h: 2,
        w: 3,
        type: 'CategoriesListWidget',
        name: `Manage ${this.rootStore.labelsStore.getLabelByLanguageAndFor('categories')}`,
        permission: 'admin',
        privileges: ['can_manage_categories'],
        img: `${filePath}manage-categories.png`,
      },
      {
        i: '6',
        x: 0,
        y: 0,
        h: 2,
        w: 2,
        type: 'CalendarManagementWidget',
        name: 'Manage Calendar',
        permission: 'admin',
        privileges: ['admin'],
        img: `${filePath}manage-calendar.png`,
      },
      {
        i: '7',
        x: 0,
        y: 0,
        h: 1,
        w: 1,
        type: 'ConsentWidget',
        name: 'Manage Consent',
        permission: 'admin',
        privileges: ['admin'],
        img: `${filePath}manage-consents.png`,
      },
      {
        i: '8',
        x: 0,
        y: 0,
        h: 2,
        w: 2,
        type: 'AudienceMembersWidget',
        name: 'My Audience',
        permission: 'any',
        privileges: ['any'],
        img: `${filePath}manage-users.png`,
      },
      {
        i: '9',
        x: 0,
        y: 0,
        h: 2,
        w: 2,
        type: 'WorksheetWidget',
        name: 'Visualization',
        permission: 'any',
        privileges: ['any'],
        img: `${filePath}manage-users.png`,
      },
      {
        i: '10',
        x: 0,
        y: 0,
        h: 2,
        w: 2,
        type: 'ManageGroupsAndRolesWidget',
        name: 'Manage Groups & Roles',
        permission: 'admin',
        privileges: ['admin'],
        img: `${filePath}manage-categories.png`,
      },
      {
        i: '11',
        x: 0,
        y: 0,
        h: 2,
        w: 2,
        type: 'PendingUserTasksWidget',
        name: 'Pending Tasks',
        permission: 'admin',
        privileges: ['any'],
        img: `${filePath}manage-categories.png`,
      },
      {
        i: '12',
        x: 0,
        y: 0,
        h: 2,
        w: 2,
        type: 'CompletedUserTasksWidget',
        name: 'Completed Tasks',
        permission: 'admin',
        privileges: ['any'],
        img: `${filePath}manage-categories.png`,
      },
      {
        i: '13',
        x: 0,
        y: 0,
        h: 2,
        w: 2,
        type: 'TasksManagementWidget',
        name: 'Tasks Management',
        permission: 'any',
        privileges: ['can_assign_work'],
        img: `${filePath}manage-categories.png`,
      },
      {
        i: '14',
        x: 0,
        y: 0,
        h: 2,
        w: 2,
        type: 'MediaItemsListWidget',
        name: 'Manage Media Items',
        permission: 'admin',
        privileges: ['admin'],
        img: `${filePath}manage-categories.png`,
      },
      // {
      //   i: '15',
      //   x: 0,
      //   y: 0,
      //   h: 2,
      //   w: 2,
      //   type: 'SurveyTemplatesWidget',
      //   name: 'Survey Templates',
      //   permission: 'user',
      //   privileges: ['can_survey'],
      //   img: `${filePath}manage-surveys.png`,
      // },
      {
        i: '16',
        x: 0,
        y: 0,
        h: 2,
        w: 2,
        type: 'AlwaysAvailableSurveysWidget',
        name: 'Feedback Surveys',
        permission: 'user',
        privileges: ['any'],
        img: `${filePath}manage-surveys.png`,
      },
      {
        i: '17',
        x: 0,
        y: 0,
        h: 2,
        w: 2,
        type: 'ClientManagementWidget',
        name: `${this.rootStore.labelsStore.getLabelByLanguageAndFor('clients')}`,
        permission: 'user',
        privileges: ['admin'],
        img: `${filePath}manage-users.png`,
      },
      {
        i: '18',
        x: 0,
        y: 0,
        h: 2,
        w: 2,
        type: 'ContactListWidget',
        name: 'Contacts',
        permission: 'user',
        privileges: ['admin'],
        img: `${filePath}manage-users.png`,
      },
      {
        i: '19',
        x: 0,
        y: 0,
        h: 2,
        w: 2,
        type: 'MyTrainingPlansWidget',
        name: 'My Trainings',
        permission: 'user',
        privileges: ['trainings_enabled'],
        img: `${filePath}manage-users.png`,
      },
      {
        i: '20',
        x: 0,
        y: 0,
        h: 2,
        w: 2,
        type: 'TrainerWidget',
        name: 'Peer Trainings',
        permission: 'user',
        privileges: ['trainings_enabled'],
        img: `${filePath}manage-users.png`,
      },
      {
        i: '21',
        x: 0,
        y: 0,
        h: 2,
        w: 2,
        type: 'ManageTrainingsWidget',
        name: 'Manage Trainings',
        permission: 'user',
        privileges: ['can_create_trainings'],
        img: `${filePath}manage-users.png`,
      },
      {
        i: '22',
        x: 0,
        y: 0,
        h: 2,
        w: 2,
        type: 'TrainingPlansCatalogWidget',
        name: 'Training Plans Catalog',
        permission: 'user',
        privileges: ['can_create_trainings'],
        img: `${filePath}manage-users.png`,
      },
      {
        i: '23',
        x: 0,
        y: 0,
        h: 2,
        w: 2,
        type: 'TrainingItemsCatalogWidget',
        name: 'Training Items Catalog',
        permission: 'user',
        privileges: ['can_create_trainings'],
        img: `${filePath}manage-users.png`,
      },
      {
        i: '24',
        x: 0,
        y: 0,
        h: 2,
        w: 2,
        type: 'PrivilegeSetsWidget',
        name: 'Manage Privilege Sets',
        permission: 'admin',
        privileges: ['admin'],
        img: `${filePath}manage-users.png`,
      },
      {
        i: '25',
        x: 0,
        y: 0,
        h: 2,
        w: 1,
        type: 'TaskTypesWidget',
        name: 'Manage Task Types',
        permission: 'admin',
        privileges: ['admin'],
        img: `${filePath}manage-categories.png`,
      },
      {
        i: '26',
        x: 0,
        y: 0,
        h: 2,
        w: 1,
        type: 'CustomCollectionsListWidget',
        name: 'Custom Collections',
        permission: 'admin',
        privileges: ['can_manage_custom_collections'],
        img: `${filePath}manage-categories.png`,
      },
      {
        i: '28',
        x: 0,
        y: 0,
        h: 2,
        w: 2,
        type: 'QuestionsCatalogWidget',
        name: 'Questions Catalog',
        permission: 'any',
        privileges: ['can_survey'],
        img: `${filePath}manage-surveys.png`,
      },
      // ,
      // {
      //   i: '29',
      //   x: 0,
      //   y: 0,
      //   h: 3,
      //   w: 4,
      //   type: 'WorksheetsListWidget',
      //   name: 'Visualizations',
      //   permission: 'any',
      //   privileges: ['admin', 'can_access_worksheets', 'can_access_scoped_analyis'],
      //   img: `${filePath}manage-users.png`,
      // },
    ]
  }
}
