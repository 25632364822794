import { observable, action, computed } from 'mobx'
import moment from 'moment'
import { Survey } from '../../surveys/aggregate/Survey'
import { IUserSurveyDTO } from '../dtos/IUserSurveyDTO'
import { serializable, serialize, deserialize, list, object, primitive, raw } from 'serializr'
import { AnonymousParticipant } from '../../surveys/aggregate/AnonymousParticipant'
import { IAggregate } from '../../shared/data/IAggregate'
import { ParseDate } from '../../shared/ParseDate'
import { FKUser } from '../../organization-users/aggregate/FKUser'

export class UserSurvey extends Survey implements IAggregate, IUserSurveyDTO {
  static create(orgId) {
    const userSurvey = new UserSurvey()
    userSurvey.organizationId = orgId
    return userSurvey
  }

  @serializable @observable public anonymousToken: string = ''
  @serializable @observable public email: string = ''
  @serializable(raw()) @observable public reminderLastSentAt: ParseDate = null
  @serializable @observable public isRead: boolean = false
  @serializable(object(ParseDate)) @observable public readDateTime: ParseDate = null
  @serializable @observable public isPaperSurvey: boolean = false
  @serializable @observable public isActive: boolean = true
  @serializable @observable public inputByUserId: string = ''
  @serializable @observable public isAnonymous: boolean = false
  @serializable @observable public dueDate: string = undefined
  @serializable @observable public userId: string = undefined
  @serializable @observable public surveyId: string = undefined
  @serializable(list(primitive())) @observable public categoryIds: string[] = []
  @serializable(object(AnonymousParticipant)) @observable public anonymousParticipant: AnonymousParticipant = undefined
  @serializable(object(FKUser)) @observable public publishedByUser: FKUser = null
  isOnServer: boolean = false

  // @action
  // public addQuestion(
  //   id,
  //   type,
  //   title,
  //   required,
  //   rank,
  //   media,
  //   ratingDisplay,
  //   html,
  //   decimalsAllowed,
  //   minimumValueAllowed,
  //   maximumValueAllowed,
  //   numberStep,
  //   defaultValueNumeric
  // ): Question {
  //   const attrs = {
  //     id: id,
  //     type: type,
  //     rank: rank,
  //     title: title,
  //     required: required,
  //     isDeleted: false,
  //     media: media,
  //     ratingDisplay: ratingDisplay,
  //     html,
  //     decimalsAllowed: decimalsAllowed,
  //     minimumValueAllowed: minimumValueAllowed,
  //     maximumValueAllowed: maximumValueAllowed,
  //     numberStep: numberStep,
  //     defaultValueNumeric: defaultValueNumeric,
  //   }
  //   const question = Question.create(attrs)
  //   this.questions.push(question)
  //   return question
  // }

  // @computed
  // public get dueDateFormatted(): string {
  //   if (!this.dueDate) return ''
  //   if (!this.hasDueDate) return ''
  //   return moment(this.dueDate).format('MMM. D')
  // }

  // @computed
  // public get publishedDateFormatted(): string {
  //   if (!this.publishedDateTime) return ''
  //   return moment(this.publishedDateTime).format('M/D/YY')
  // }

  // @computed
  // public get publishedDateTimeAsDate(): Date {
  //   if (!this.publishedDateTime) return null
  //   return moment(this.publishedDateTime).toDate()
  // }

  @computed
  public get hasDueDate() {
    return this.dueDate && this.dueDate !== ''
  }

  @computed
  public get reminderLastSentAtAsDate(): Date {
    if (!this.reminderLastSentAt) return null
    return this.reminderLastSentAt.date
  }

  @computed
  public get readDateTimeAsDate(): Date {
    if (!this.readDateTime) return null
    if (typeof this.readDateTime === 'string') {
      const parseDt = ParseDate.fromDate(this.readDateTime)
      return parseDt.date
    }
    return this.readDateTime.date
  }

  public markIsNotOnServer() {
    this.isOnServer = false
  }

  public serialize(): IUserSurveyDTO {
    return serialize(this)
  }
}
