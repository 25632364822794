import React from 'react'
import { observer } from 'mobx-react'
import Dropzone from 'react-dropzone'
import CloseIcon from '@material-ui/icons/Close'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  DialogActions,
  Button,
  LinearProgress,
} from '@material-ui/core'
import './UsersImportModal.scss'
import UserImportRow from './UserImportRow'
import VirtualList from 'react-tiny-virtual-list'
import ErrorRow from './ErrorRow'
import SpinnerOverlay from '../../../shared/SpinnerOverlay'
import { UserImportVM } from '../../view-models/import/UserImportVM'
import MuiConfirmPrompt from '../../../shared/MuiConfirmPrompt'

interface Props {
  vm: UserImportVM
}

const UsersImportModal: React.FC<Props> = ({ vm }) => {
  if (!vm) return

  const renderUserImportVirtualList = () => {
    if (vm.userRows.length === 0) return null
    return (
      <VirtualList
        className='user-import-list'
        height={400}
        width='100%'
        itemCount={vm.userRows.length}
        itemSize={(index) => vm.userRows[index].height}
        renderItem={({ style, index }) => {
          const row = vm.userRows[index]
          return <UserImportRow key={'usr' + row.key} row={row} style={style} vm={vm} />
        }}
      />
    )
  }

  const renderErrors = () => {
    if (!vm.hasErrors) return null
    return vm.errorRows.map((e, idx) => <ErrorRow key={'er' + idx} row={e} />)
  }

  const renderSpinner = () => {
    if (vm.showImportingSpinner && vm.userRows.length === 0) {
    return (
        <SpinnerOverlay />
    )
    }else {
      return
     }
  }

  const renderConfirmClose = () => {
    if (!vm.confirmCloseDialogOpen) return
    return (
      <MuiConfirmPrompt
        confirmMessage={vm.renderCloseConfirm}
        confirmMessageSecondary={vm.renderCloseConfirmSecondary}
        open={vm.confirmCloseDialogOpen}
        onConfirm={() => vm.cancelChanges()}
        title={'User Import Tracking'}
        onClose={() => {vm.confirmCloseDialogOpen = false}}
        icon={<WarningRoundedIcon className='warning-icon' />}
      />
    )
  }

  const renderDropZone = () => {
    if (!vm.showDropZone) return null
    return (
      <Grid container className='dialog-content'>
        <DialogContent>
          {!vm.file ? (
            <Dropzone
              onDrop={(file) => vm.handleFileDrop(file)}
              accept='.csv,text/csv'
              multiple={false}
              minSize={0}
              maxSize={5242880}
            >
              {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                <section>
                  <div className='dropzone' {...getRootProps()}>
                    <input {...getInputProps()} />

                    {!isDragActive && (
                      <div className='iconContainer'>
                        <NoteAddIcon className='noteAddIcon' />
                        <p className='dropzoneText'>
                          Drag and drop or <span className='dropLink'>browse</span> your file
                        </p>
                      </div>
                    )}

                    {isDragActive && !isDragReject && (
                      <div className='iconContainer'>
                        <ThumbUpIcon className='thumbUpIcon' />
                        <p className='dropzoneText'>This file looks good to go!</p>
                      </div>
                    )}

                    {isDragActive && isDragReject && (
                      <div className='iconContainer'>
                        <ThumbDownIcon className='thumbDownIcon' />
                        <p className='dropzoneText'>Sorry, this file type isn't accepted</p>
                      </div>
                    )}
                  </div>
                </section>
              )}
            </Dropzone>
          ) : (
            <Dropzone
              onDrop={(file) => vm.handleFileDrop(file)}
              accept='.csv,text/csv'
              multiple={false}
              minSize={0}
              maxSize={5242880}
            >
              {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                <section>
                  <div className='dropzone' {...getRootProps()}>
                    <input {...getInputProps()} />

                    {!isDragActive && (
                      <div className='iconContainer'>
                        <ThumbUpIcon className='thumbUpIcon' />
                        <p className='dropzoneText'>{vm.file.name}</p>
                        <p className='dropzoneText'>This file looks good to go!</p>
                      </div>
                    )}

                    {isDragActive && !isDragReject && (
                      <div className='iconContainer'>
                        <ThumbUpIcon className='thumbUpIcon' />
                        <p className='dropzoneText'>This file looks good to go!</p>
                      </div>
                    )}

                    {isDragActive && isDragReject && (
                      <div className='iconContainer'>
                        <ThumbDownIcon className='thumbDownIcon' />
                        <p className='dropzoneText'>Sorry, this file type isn't accepted</p>
                      </div>
                    )}
                  </div>
                </section>
              )}
            </Dropzone>
          )}
          <DialogContentText className='dialogText'> Acceptable formats: csv </DialogContentText>
        </DialogContent>
      </Grid>
    )
  }

  const renderDropZoneNotProcessing = () => {
    if (!vm.showValidatingSpinner) return null
    return (
      <Grid container className='dialog-content'>
        <DialogContent>
          <div className='dropzone'>
            <div className='iconContainer'>
              <p className='dropzoneText'>Processing Users Upload...</p>
            </div>
          </div>
        </DialogContent>
      </Grid>
    )
  }

  const renderLinearProgress = () => {
    if (!vm.importProcessing) return null
    if (!vm.importProgress) return null
    return <LinearProgress variant='determinate' value={vm.importProgress} />
  }

  return (
    <div id='UserImportModal'>
      <Dialog onClose={() => false} open={vm.showUsersImport} fullWidth>
        {renderConfirmClose()}
        <Grid
          className='dialog-title'
          container
          justifyContent='space-between'
          alignItems='center'
          style={{ paddingRight: '10px', zIndex: 1600 }}
        >
          <Grid item>
            <DialogTitle>{vm.dialogTitle}</DialogTitle>
          </Grid>
          <Grid item>
            <IconButton onClick={() => vm.handleCancel()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        {renderLinearProgress()}
        <Grid className={vm.userRows.length > 0 ? 'dialog-body' : 'dialog-empty'}>
          {renderSpinner()}
          {renderErrors()}
          {renderUserImportVirtualList()}
          {renderDropZone()}
          {renderDropZoneNotProcessing()}
        </Grid>
        <DialogActions>
          <Grid container className='dialog-actions' alignItems='center' justifyContent='space-between'>
            <Grid item>
              <Button
                variant='outlined'
                size='large'
                onClick={() => vm.downloadTemplate()}
                className='downloadButton'
              >
                Download Template
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant='text'
                size='large'
                onClick={() => vm.handleCancel()}
                disabled={vm.importProcessing}
                className='cancelButton'
              >
                {vm.cancelButtonText}
              </Button>
              <Button
                variant='contained'
                size='large'
                onClick={() => vm.validate()}
                disabled={!vm.validateEnabled}
                className='saveButton'
              >
                Validate
              </Button>
              <Button
                variant='contained'
                size='large'
                onClick={() => vm.acceptChanges()}
                disabled={!vm.allowAcceptChanges}
                className='saveButton'
              >
                {!vm.validateEnabled ? 'Accept Changes' : 'Skip Validation'}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default observer(UsersImportModal)
