import { serializable } from 'serializr'
import { action, computed, observable } from 'mobx'
import { CellClickedEvent, ColDef, GridOptions, RowClickedEvent } from 'ag-grid-community'
import { AGGridVM } from '../../shared/ag-grid/AGGridVM'
import { nameOf } from '../../shared/nameOf'
import { RootStore } from '../../stores/RootStore'
import { SurveyTypesTableRowVM } from './SurveyTypesTableRowVM'
import { SurveyTypesFindDataStore } from '../store/SurveyTypesFindDataStore'
import { ISurveyTypesFindRequest } from '../interfaces/ISurveyTypesFindRequest'
import { SurveyTypesManageVM } from './SurveyTypesManageVM'

export class SurveyTypesTableVM extends AGGridVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, manageVM: SurveyTypesManageVM, req: ISurveyTypesFindRequest) {
    super()
    this.rootStore = rootStore
    this.manageVM = manageVM
    this.loadDataStore(req)
    this.sizeColumnsToFit = true
    this.serverSideLoaded = true
  }

  @observable public selectedRow: SurveyTypesTableRowVM = undefined
  @observable public shouldShowSnackbar: boolean = false
  @observable public snackbarMessage: string = ''
  @observable public manageVM: SurveyTypesManageVM = null
  @observable public dataStore: SurveyTypesFindDataStore = null

  @action
  public loadDataStore(req: ISurveyTypesFindRequest) {
    if (this.manageVM.screenType === 'tenantManage') {
      req.organizationId = this.rootStore.appStore.currentOrgId
    }
    this.dataStore = new SurveyTypesFindDataStore(this.rootStore, req)
  }

  @action
  public openSnackbar(msg: string) {
    this.snackbarMessage = msg
    this.shouldShowSnackbar = true
  }

  @action
  public closeSnackbar() {
    this.shouldShowSnackbar = false
  }

  @computed
  public get rows() {
    return []
  }

  @action
  public getRowNodeId(row): string {
    return row.objectId
  }

  public get shouldRender(): boolean {
    return true
  }

  @action
  public hardRefreshTemplatesList() {
    if (!this.gridApi) return
    this.gridApi.refreshInfiniteCache()
    this.gridApi.purgeInfiniteCache()
  }

  public getGridOptions(): GridOptions {
    return {
      ...this.baseGridOptions,
      rowData: this.rows,
      onRowClicked: (e) => this.rowClicked(e),
      getRowNodeId: (e) => this.getRowNodeId(e),
      columnDefs: this.ensureTooltipFields(this.columnDefs),
    }
  }

  public rowClicked(e: RowClickedEvent) {
    const row: SurveyTypesTableRowVM = e.data
    if (!row) return
    // row.navigateToSystemSurveyTypeEdit()
  }

  @computed
  public get isLoading(): boolean {
    return this.rootStore.notificationsStore.loaded
  }

  @action
  public setQuickFilter(val: string) {
    this.typedFilterText = val
    if (this.quickFilterTO) clearTimeout(this.quickFilterTO)
    this.quickFilterTO = setTimeout(() => this.applyFilter(), 1000)
  }

  public applyFilter() {
    this.dataStore.setFilter(this.typedFilterText)
    this.reload()
    this.columnApi.applyColumnState({
      state: [{ colId: 'name', sort: 'asc' }],
    })
  }

  @computed
  public get columnDefs(): ColDef[] {
    const s = this.rootStore.localizationStore.lzStrings
    return [
      {
        headerName: 'Name',
        field: nameOf<SurveyTypesTableRowVM, string>((e) => e.name),
        sort: 'asc',
        onCellClicked: (e: CellClickedEvent) => {
          const rowVM: SurveyTypesTableRowVM = e.data
          if (rowVM) rowVM.navigateToSurveyTypeEdit()
        },
      },
      {
        headerName: 'Description',
        field: nameOf<SurveyTypesTableRowVM, string>((e) => e.description),
        onCellClicked: (e: CellClickedEvent) => {
          const rowVM: SurveyTypesTableRowVM = e.data
          if (rowVM) rowVM.navigateToSurveyTypeEdit()
        },
      },
      {
        cellRenderer: 'actionsCell',
        cellClass: 'actions-button-cell',
        sortable: false,
        onCellClicked: null,
        width: 50,
      },
    ]
  }

  protected onGridReadied() {
    this.gridApi.setDatasource({
      rowCount: this.totalRecords,
      getRows: async (params) => {
        params.sortModel.forEach((col: { colId: string; sort: 'asc' | 'desc' }, idx: number) => {
          let dbCol = col.colId
          if (dbCol === 'name') dbCol = 'name'
          if (idx === 0) this.dataStore.setSort(dbCol, col.sort)
          if (idx > 0) this.dataStore.addSort(dbCol, col.sort)
        })
        if (params.sortModel.length === 0) this.dataStore.setSort('name')
        if (params.startRow === 0) this.dataStore.setPage(0)
        await this.dataStore.getNextPage()
        const rows = this.dataStore.surveyTypes.map(
          (e) => new SurveyTypesTableRowVM(this.rootStore, e, this)
        )
        params.successCallback(rows, this.dataStore.totalRecords)
      },
    })
  }
}
