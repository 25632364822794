import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { CircularProgress, Grid, IconButton, Tooltip } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import DescriptionIcon from '@material-ui/icons/Description'
import VideoIcon from '@material-ui/icons/VideocamRounded'
import { useIsMounted } from '../../shared/useIsMounted'
import { CMSItemAttachmentVM } from '../../attachments/view-models/CMSItemAttachmentVM'
import { CMSFileAttachmentVM } from 'src/app/training-items/view-models/CMSFileAttachmentVM'

interface Props {
  vm: CMSItemAttachmentVM | CMSFileAttachmentVM
  index?: number
  onClose?: Function
  showDelete?: boolean
}

const CMSThumbnail: React.FC<Props> = ({ vm, index, onClose, showDelete = true }) => {
  const isMounted = useIsMounted()

  const handleLoad = () => {
    if (!isMounted) return
    if (vm.isLoading) return

    vm.loadCMSItem()
  }

  useEffect(() => {
    handleLoad()
  }, [])

  const renderSpinner = () => {
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  if (!vm) return

  if (!vm.isLoaded) {
    handleLoad()
    return renderSpinner()
  }

  const renderThumbnail = () => {
    if (vm.isImage) return <img src={vm.url} />
    if (vm.isVideo) {
      if (vm.url) {
        if (vm.hasVimeoURL) return <VideoIcon />
        if (vm.hasYouTubeURL) return <VideoIcon />
        return (
          <video>
            <source src={vm.url} type='video/mp4' />
          </video>
        )
      } else return <VideoIcon />
    }
    return <DescriptionIcon />
  }

  const docStyle = () => {
    if (vm.isImage) return 'thumbnail-container'
    if (vm.isVideo) {
      if (vm.url) {
        if (vm.hasVimeoURL) return 'file-container'
        if (vm.hasYouTubeURL) return 'file-container'
        return 'thumbnail-container'
      } else return 'file-container'
    }
    return 'file-container'
  }

  const renderDeleteBtn = () => {
    if (!showDelete) return
    return (
      <div>
        <IconButton
          onClick={() => {
            if (onClose) onClose()
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
    )
  }

  return (
    <>
      <Grid item xs={6} key={index} className='attachment-container'>
        <div className='attachment-content'>
          <div className={docStyle()}>{renderThumbnail()}</div>
          <Tooltip title={vm.fileName ? vm.fileName : ''} placement='bottom-start' enterDelay={500}>
            <span style={{ cursor: 'pointer' }} onClick={() => vm.openAttachment()}>
              {vm.shorterFileName}
            </span>
          </Tooltip>
        </div>
        {renderDeleteBtn()}
      </Grid>
    </>
  )
}

export default inject()(observer(CMSThumbnail))
