import { RootStore } from '../../stores/RootStore'
import { DataStore } from '../../shared/data/DataStore'
import { Role } from '../../roles/aggregate/Role'
import { IRoleDTO } from '../../roles/dtos/IRoleDTO'
import { IOrganizationUsersAudienceRoleSearchRequest } from '../../organization-users/interfaces/IOrganizationUsersAudienceRoleSearchRequest'
import { OrganizationUsersService } from '../../organization-users/service/OrganizationUsersService'

export class RolesListDataStore extends DataStore<Role, IRoleDTO> {
  private userId: string

  constructor(rootStore: RootStore, userId: string) {
    super(rootStore, Role, 'roles', ['objectId', 'name'])
    this.userId = userId
    this.paged = true
    this.recordsPerPage = 100
    this.sortColumnName = 'name'
  }

  protected getListRecordsApiCall() {
    return async () => {
      const req: IOrganizationUsersAudienceRoleSearchRequest = {
        userId: this.userId,
        orgId: this.rootStore.appStore.currentOrgId,
        terms: this.filter,
        skip: this.recordsPerPage * (this.pageNumber - 1),
        limit: this.recordsPerPage,
        sortColumnName: this.sortColumnName,
        sortDirection: 'asc',
      }
      const svc = new OrganizationUsersService(this.rootStore)
      const result = await svc.searchAudienceRoles(req)
      this.totalRecords = result.count === 0 ? result.roles.length : result.count
      return result.roles
    }
  }

  public get roles() {
    return this.records
  }
}
