import Parse from 'parse'
import { action, observable, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { TenantLink } from '../aggregate/TenantLink'
import { OrganizationLinksVM } from './OrganizationLinksVM'
import uuid from 'uuid/v4'
import { Attachment } from '../../upload/aggregate/Attachment'

export class TenantLinkEditVM {
  private rootStore: RootStore


  constructor(rootStore: RootStore, parentVM: OrganizationLinksVM, tenantLink: TenantLink) {
    this.rootStore = rootStore
    this.parentVM = parentVM
    this.tenantLink = tenantLink
  }

  @observable public tenantLink: TenantLink = null
  @observable public parentVM: OrganizationLinksVM = null
  @observable public saveTried: boolean = false
  @observable public showUploadModal: boolean = false

  public setName(name: string) {
    this.tenantLink.setName(name)
  }

  public setUrl(url: string) {
    this.tenantLink.setUrl(url)
  }

  public toggleIsEmbedded() {
    this.tenantLink.toggleIsEmbeded()
  }

  @computed
  public get isNew(): boolean {
    return Boolean(!this.tenantLink.id)
  }

  @computed 
  public get isValid(): boolean {
    if (!this.isNameValid) return false
    if (!this.isUrlValid) return false
    return true
  }

  @computed get isNameValid(): boolean {
    if (!this.saveTried) return true
    if (!this.name) return false
    if (this.name === '') return false
    return true
  }

  @computed get isUrlValid(): boolean {
    if (!this.saveTried) return true
    if (!this.url) return false
    if (this.url === '') return false
    if (!this.isValidURL) return false
    return true
  }

  @computed
  public get isValidURL(): boolean {
      let givenURL
      try {
          givenURL = new URL(this.url)
      } catch (error) {
        return false
      }
      return givenURL.protocol === "http:" || givenURL.protocol === "https:";
  }

  @computed
  public get name(): string {
    return this.tenantLink.name
  }

  @computed
  public get url(): string {
    return this.tenantLink.url
  }

  @computed
  public get isEmbedded(): boolean {
    return this.tenantLink.isEmbedded
  }

  @computed
  public get iconName(): string {
    if (!this.tenantLink.iconName) return ''
    return this.tenantLink.iconName.fileName
  }

  public removeTenantLink() {
    this.parentVM.removeLinkFromOrg(this.tenantLink)
    this.cancel()
  }

  public cancel() {
    this.parentVM.clearLinkEditVM()
  }

  public async apply() {
    this.saveTried = true
    if (!this.isValid) return null
    if (this.isNew) this.tenantLink.id = uuid()
    this.parentVM.addLinkToOrg(this.tenantLink)
    this.cancel()
  }

  @action
  public toggleShowUploadModal() {
    this.showUploadModal = !this.showUploadModal
  }

  @action
  public addAttachment(att: Attachment) {
    this.tenantLink.setIconName(att)
  }

  @action
  public clearAttachment() {
    this.tenantLink.clearIconName()
  }
}
