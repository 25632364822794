import Parse from 'parse'
import { IEmailTemplatesCreateRequest } from '../interfaces/IEmailTemplatesCreateRequest'
import { IEmailTemplatesCreateResponse } from '../interfaces/IEmailTemplatesCreateResponse'
import { IEmailTemplatesDeleteRequest } from '../interfaces/IEmailTemplatesDeleteRequest'
import { IEmailTemplatesDeleteResponse } from '../interfaces/IEmailTemplatesDeleteResponse'
import { IEmailTemplatesGetAllRequest } from '../interfaces/IEmailTemplatesGetAllRequest'
import { IEmailTemplatesGetAllResponse } from '../interfaces/IEmailTemplatesGetAllResponse'
import { IEmailTemplatesGetVersionRequest } from '../interfaces/IEmailTemplatesGetVersionRequest'
import { IEmailTemplatesGetVersionResponse } from '../interfaces/IEmailTemplatesGetVersionResponse'
import { IEmailTemplatesInitializeRequest } from '../interfaces/IEmailTemplatesInitializeRequest'
import { IEmailTemplatesInitializeResponse } from '../interfaces/IEmailTemplatesInitializeResponse'
import { IEmailTemplatesListRequest } from '../interfaces/IEmailTemplatesListRequest'
import { IEmailTemplatesListResponse } from '../interfaces/IEmailTemplatesListResponse'
import { IEmailTemplatesTestEmailRequest } from '../interfaces/IEmailTemplatesTestEmailRequest'
import { IEmailTemplatesTestEmailResponse } from '../interfaces/IEmailTemplatesTestEmailResponse'
import { IEmailTemplatesUpdateRequest } from '../interfaces/IEmailTemplatesUpdateRequest'
import { IEmailTemplatesUpdateResponse } from '../interfaces/IEmailTemplatesUpdateResponse'

export class EmailTemplatesService {
  constructor() {}

  public async getEmailTemplatesList(
    request: IEmailTemplatesListRequest,
    orgId: string
  ): Promise<IEmailTemplatesListResponse> {
    return await Parse.Cloud.run('getEmailTemplatesList', { orgId, request })
  }

  public async getAllEmailTemplates(
    request: IEmailTemplatesGetAllRequest,
    orgId: string
  ): Promise<IEmailTemplatesGetAllResponse> {
    return await Parse.Cloud.run('getAllEmailTemplates', { orgId, request })
  }

  public async getEmailTemplateVersion(
    request: IEmailTemplatesGetVersionRequest,
    orgId: string
  ): Promise<IEmailTemplatesGetVersionResponse> {
    return await Parse.Cloud.run('getEmailTemplateVersion', { orgId, request })
  }

  public async getSystemDefaultEmailTemplateVersion(
    request: IEmailTemplatesGetVersionRequest,
    orgId: string
  ): Promise<IEmailTemplatesGetVersionResponse> {
    return await Parse.Cloud.run('getSystemDefaultEmailTemplateVersion', { orgId, request })
  }

  public async getEmailTemplateVersions(
    request: IEmailTemplatesGetVersionRequest,
    orgId: string
  ): Promise<IEmailTemplatesGetVersionResponse> {
    return await Parse.Cloud.run('getEmailTemplateVersions', { orgId, request })
  }

  public async initializeEmailTemplate(
    request: IEmailTemplatesInitializeRequest,
    orgId: string
  ): Promise<IEmailTemplatesInitializeResponse> {
    return await Parse.Cloud.run('initializeEmailTemplate', { orgId, request })
  }

  public async initializeDefaultEmailTemplate(
    request: IEmailTemplatesInitializeRequest,
    orgId: string
  ): Promise<IEmailTemplatesInitializeResponse> {
    return await Parse.Cloud.run('initializeDefaultEmailTemplate', { orgId, request })
  }

  public async createEmailTemplateVersion(
    request: IEmailTemplatesCreateRequest,
    orgId: string
  ): Promise<IEmailTemplatesCreateResponse> {
    return await Parse.Cloud.run('createEmailTemplateVersion', { orgId, request })
  }

  public async updateEmailTemplate(
    request: IEmailTemplatesUpdateRequest,
    orgId: string
  ): Promise<IEmailTemplatesUpdateResponse> {
    return await Parse.Cloud.run('updateEmailTemplateVersion', { orgId, request })
  }

  public async updateEmailTemplateTestVersion(
    request: IEmailTemplatesUpdateRequest,
    orgId: string
  ): Promise<IEmailTemplatesUpdateResponse> {
    return await Parse.Cloud.run('updateEmailTemplateTestVersion', { orgId, request })
  }

  public async revertEmailTemplate(
    request: IEmailTemplatesUpdateRequest,
    orgId: string
  ): Promise<IEmailTemplatesUpdateResponse> {
    return await Parse.Cloud.run('revertEmailTemplate', { orgId, request })
  }

  public async deleteEmailTemplate(
    request: IEmailTemplatesDeleteRequest,
    orgId: string
  ): Promise<IEmailTemplatesDeleteResponse> {
    return await Parse.Cloud.run('deleteEmailTemplateVersion', { orgId, request })
  }

  public async sendTestEmail(
    request: IEmailTemplatesTestEmailRequest,
    orgId: string
  ): Promise<IEmailTemplatesTestEmailResponse> {
    return await Parse.Cloud.run('sendEmailTemplateTest', { orgId, request })
  }
}
