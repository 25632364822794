import { observable, action, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Consent } from '../aggregate/Consent'
import { ConsentWidgetVM } from '../view-models/ConsentWidgetVM'
import { UserConsentScreenVM } from '../view-models/UserConsentScreenVM'
import { IConsentDTO } from '../dtos/IConsentDTO'
import { DataStore } from '../../shared/data/DataStore'

export class ConsentsStore extends DataStore<Consent, IConsentDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, Consent, 'consents', [
      'organizationId',
      'versionNumber',
      'createdByUserId',
      'text',
      'location',
      'attachments',
    ])
  }

  @observable public consentWidgetVM: ConsentWidgetVM = null
  @observable public userConsentScreenVM: UserConsentScreenVM = null
  @observable public loaded: boolean = false

  @computed
  public get consents() {
    return this.records
  }

  @action
  public onListRecordsLoaded() {
    this.consentWidgetVM = new ConsentWidgetVM(this.rootStore)
    this.userConsentScreenVM = new UserConsentScreenVM(this.rootStore)
    this.loaded = true
  }

  @computed
  public get currentOrganization() {
    if (this.rootStore.userStore && this.rootStore.userStore.currentOrganization)
      return this.rootStore.userStore.currentOrganization
    else return null
  }

  @computed
  public get shouldRedirectToConsent() {
    if (!this.currentOrganization) return false
    if (!this.currentOrganization.isConsentRequiredForLogin) return false
    if (this.currentOrganization.hasConsented) return false
    return true
  }

  @computed
  public get numberOfConsents() {
    return this.currentOrgConsents.length
  }

  @computed
  public get currentOrgId() {
    return this.rootStore.appStore.currentOrgId
  }

  @computed
  public get currentOrgConsents() {
    return this.consents
      .filter((consent) => consent.organizationId === this.currentOrgId)
      .sort((a, b) => {
        if (a.versionNumber > b.versionNumber) return -1
        else if (a.versionNumber < b.versionNumber) return 1
        return 0
      })
  }

  @computed
  public get latestConsent() {
    if (this.currentOrgConsents.length === 0) return null
    return this.currentOrgConsents[0]
  }

  public getConsent(objectId: string): Consent | null {
    return this.currentOrgConsents.find((e) => e.objectId === objectId)
  }

  private getConsentIndex(objectId: string): number {
    return this.currentOrgConsents.findIndex((e) => e.objectId === objectId)
  }

  private deleteConsent(objectId) {
    const idx = this.getConsentIndex(objectId)
    if (idx !== -1) this.consents.splice(idx, 1)
  }
}
