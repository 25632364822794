import { observable } from 'mobx'
import { serializable } from 'serializr'
import { IFKOwnerUserDTO } from '../dtos/IFKOwnerUserDTO'

export class FKOwnerUser implements IFKOwnerUserDTO {
  public static create(firstName, lastName, name, iconUrl): FKOwnerUser {
    const pt = new FKOwnerUser()
    pt.firstName = firstName
    pt.name = name
    pt.lastName = lastName
    pt.iconURL = iconUrl
    return pt
  }

  @serializable @observable public firstName: string = ''
  @serializable @observable public lastName: string = ''
  @serializable @observable public iconURL: string = null
  @serializable @observable public name: string = ''
}
