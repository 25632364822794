import { action } from 'mobx'
import Parse from 'parse'
import { RootStore } from 'src/app/stores/RootStore'

export class WeightProfilesService {
  private rootStore: RootStore
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }
  public async importWeightProfilesFromCsv(orgId, parseFile, doSave, batchId) {
    const result = await Parse.Cloud.run('importWeightProfilesFromCsv', {
      orgId,
      parseFile,
      doSave,
      batchId,
    })
    return result
  }

  async saveWeightProfile(orgId, weightProfile) {
    const result = await Parse.Cloud.run('saveWeightProfile', { orgId, weightProfile })
    return result
  }

  async copyWeightProfileToRoles(orgId, weightProfileId, roleIds) {
    const result = await Parse.Cloud.run('copyWeightProfileToRoles', {
      orgId,
      weightProfileId,
      roleIds,
    })
    return result
  }

  async deleteWeightProfile(weightProfileId: string, orgId: string) {
    const result = await Parse.Cloud.run('deleteWeightProfile', { weightProfileId, orgId })
    return result
  }
}
