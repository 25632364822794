import { action, computed, observable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { ColDef, RowClickedEvent, GridOptions } from 'ag-grid-community'
import moment from 'moment'
import { UserTaskSnapshot } from '../aggregates/UserTaskSnapshot'
import { AGGridVM } from '../../shared/ag-grid/AGGridVM'
import { TasksManagementWidgetRowVM } from './TasksManagementWidgetRowVM'
import { nameOf } from '../../shared/nameOf'

export class TasksManagementWidgetVM extends AGGridVM {
  private rootStore: RootStore

  constructor(rootStore) {
    super()
    this.rootStore = rootStore
  }

  @observable public tabIndex: number = 0

  @action
  setTabIndex(value: number) {
    this.tabIndex = value
    this.gridApi.paginationGoToPage(0)
    this.gridApi.onFilterChanged()
  }

  @computed
  public get rows() {
    let rows = this.rootStore.tasksStore.tasks.map(
      (task) => new TasksManagementWidgetRowVM(this.rootStore, task, this)
    )
    if (!this.rootStore.appStore.isOrgAdmin) {
      rows = rows.filter((task) => task.assignedByUserId === this.rootStore.appStore.currentUserId)
    }
    // if (this.gridApi) this.gridApi.setRowData(rows)
    return rows
  }

  @action
  public getRowNodeId(row): string {
    return row.objectId
  }

  public get columnDefs(): ColDef[] {
    const s = this.rootStore.localizationStore.lzStrings.tasksManagementWidget
    return [
      {
        headerName: s.name,
        field: nameOf<TasksManagementWidgetRowVM, string>((e) => e.name),
        sort: 'asc',
      },
      {
        headerName: s.due,
        field: nameOf<TasksManagementWidgetRowVM, number>((e) => e.dueDate),
        valueFormatter: ({ value }) => moment.unix(value).format(s.due_date_format),
      },
      {
        headerName: s.participants,
        field: nameOf<TasksManagementWidgetRowVM, UserTaskSnapshot[]>(
          (e) => e.assignedToAudienceMembers
        ),
        cellRenderer: 'participantsCell',
      },
    ]
  }

  @computed
  public get hasWorkAccess(): boolean {
    return this.rootStore.appStore.canAssignWork
  }

  @computed
  public get shouldRender(): boolean {
    if (!this.rootStore.audienceMembersStore.loaded) return false
    if (!this.rootStore.tasksStore.loaded) return false
    return true
  }

  @action
  protected isExternalFilterPresent(): boolean {
    return Boolean(this.tabIndex !== 2)
  }

  @action
  protected doesExternalFilterPass(node) {
    switch (this.tabIndex) {
      case 0:
        return !node.data.isComplete
      case 1:
        return node.data.isComplete
      default:
        return true
    }
  }

  public getGridOptions(): GridOptions {
    return {
      ...this.baseGridOptions,
      rowData: this.rows,
      onRowClicked: (e) => this.rowClicked(e),
      getRowNodeId: (e) => this.getRowNodeId(e),
      columnDefs: this.columnDefs,
    }
  }

  public rowClicked(e: RowClickedEvent) {
    const row: TasksManagementWidgetRowVM = e.data
    if (!row) return
    row.openTask()
  }
}
