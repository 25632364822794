import React from 'react'
import { inject, observer } from 'mobx-react'
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import CopyIcon from '@material-ui/icons/FileCopyOutlined'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import DragIndicatorRoundedIcon from '@material-ui/icons/DragIndicatorRounded'
import EditIcon from '@material-ui/icons/EditOutlined'
import { SortableHandle } from 'react-sortable-hoc'
import rootStore from '../../../stores/RootStore'
import PulseQuestion from '../../../pulse-questions/aggregate/PulseQuestion'
import { PulseQuestionVM as PulseCategoryQuestionVM } from '../../view-models/tree/PulseQuestionVM'
import PulseQuestionRow from '../questions/PulseQuestionRow'
import { PulseQuestionVM } from '../../view-models/questions/PulseQuestionVM'
import { PulseQuestionEditVM } from '../../view-models/PulseQuestionEditVM'
import './PulseCampaignSurveyQuestionCard.scss'

interface Props {
  vm: PulseCategoryQuestionVM
}

const PulseCampaignSurveyQuestionCard: React.FC<Props> = ({ vm }) => {
  if (!vm) return null
  const { pulseCampaign: lz } = rootStore.localizationStore.lzStrings
  const labelsStore = rootStore.labelsStore

  const SortableDragIndicator: any = SortableHandle(({ }) => (
    <CardHeader
      avatar={renderDragIndicator()}
      action={
        <div className={'question-card-header-actions'}>
          <Tooltip
            title={
              vm.currentSurvey && vm.isCurrentSurveySent
                ? `${labelsStore.getLabelByLanguageAndFor('pulse')} ${lz.already_sent}`
                : lz.copy
            }
            placement='bottom-start'
          >
            <span>
              <IconButton
                disabled={vm.currentSurvey && vm.isCurrentSurveySent}
                aria-label='question-card-header-action-copy'
                className={'question-card-btn'}
                onClick={() => {
                  vm.copySelectedQuestionToCurrentSurvey()
                }}
              >
                <Avatar
                  className={`question-card-btn-avatar ${vm.currentSurvey && vm.isCurrentSurveySent ? 'disabled' : ''
                    }`}
                >
                  <CopyIcon fontSize='small' />
                </Avatar>
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip
            title={
              vm.currentSurvey && vm.isCurrentSurveySent
                ? `${labelsStore.getLabelByLanguageAndFor('pulse')} ${lz.already_sent}`
                : lz.delete
            }
            placement='bottom-start'
          >
            <span>
              <IconButton
                disabled={vm.currentSurvey && vm.isCurrentSurveySent}
                aria-label='question-card-header-action-delete'
                className={'question-card-btn'}
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  vm.setSelected(false)
                  vm.removeSelectedQuestionFromCurrentSurvey()
                }}
              >
                <Avatar
                  className={`question-card-btn-avatar ${vm.currentSurvey && vm.isCurrentSurveySent ? 'disabled' : ''
                    }`}
                >
                  <DeleteIcon fontSize='small' />
                </Avatar>
              </IconButton>
            </span>
          </Tooltip>
        </div>
      }
      title={
        <div className='question-card-header-title'>
          {vm.title}
          {vm.required && <span className='required-indicator'>*</span>}
        </div>
      }
    />
  ))

  const renderContainsPrompts = () => {
    if (!vm.hasFollowUp) return null
    return (
      <div className={'prompts'}>{'This question contains follow ups.'}</div>
    )
  }

  const renderDragIndicator = () => {
    if (vm.currentSurvey && vm.isCurrentSurveySent) return <div className='question-card-header-drag' />
    return (
      <Tooltip title={''} placement='bottom-start'>
        <span>
          <div className='question-card-header-drag'>
            <IconButton aria-label='question-card-header-drag' disabled={true}>
              <DragIndicatorRoundedIcon />
            </IconButton>
          </div>
        </span>
      </Tooltip>
    )
  }

  const renderHeader = () => {
    if (!vm.currentSurvey) return
    return <SortableDragIndicator />
  }

  const renderBody = () => {
    if (!vm.currentSurvey) return
    return (
      <CardContent className='question-card-content-body'>
        <PulseQuestionRow question={new PulseQuestionVM(rootStore, new PulseQuestionEditVM(rootStore, vm.pulseQuestion as PulseQuestion))} />
      </CardContent>
    )
  }

  const renderEditButton = () => {
    if (!vm.currentSurvey) return
    return (
      <div className={'question-card-edit'}>
        <Tooltip
          title={
            vm.currentSurvey && vm.isCurrentSurveySent
              ? `${labelsStore.getLabelByLanguageAndFor('pulse')} ${lz.already_sent}`
              : lz.edit
          }
          placement='bottom-start'
        >
          <span>
            <IconButton
              disabled={vm.currentSurvey && vm.isCurrentSurveySent}
              className={'question-card-btn'}
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                rootStore.pulseQuestionsStore.loadCampaignPulseQuestionEditVM(
                  vm.pulseQuestion.objectId,
                  vm.pulseQuestion as PulseQuestion
                )
              }}
            >
              <Avatar
                className={`question-card-btn-avatar ${vm.currentSurvey && vm.isCurrentSurveySent ? 'disabled' : ''
                  }`}
              >
                <EditIcon fontSize='small' />
              </Avatar>
            </IconButton>
          </span>
        </Tooltip>
      </div>
    )
  }

  const renderCard = () => {
    return (
      <div id='PulseCampaignSurveyQuestionCard'>
        <Card>
          {renderHeader()}
          {renderBody()}
          {renderContainsPrompts()}
        </Card>
        {renderEditButton()}
      </div>
    )
  }

  return <>{renderCard()}</>
}

export default inject()(observer(PulseCampaignSurveyQuestionCard))
