import Parse from 'parse'
import { IUserWorksheetListRequest } from '../interfaces/IUserWorksheetListRequest'
import { IUserWorksheetListResponse } from '../interfaces/IUserWorksheetListResponse'
import { IUserWorksheetSaveRequest } from '../interfaces/IUserWorksheetSaveRequest'
import { IUserWorksheetSaveResponse } from '../interfaces/IUserWorksheetSaveResponse'
import { IUserWorksheetBookmarkRequest } from '../interfaces/IUserWorksheetBookmarkRequest'
import { IUserWorksheetBookmarkResponse } from '../interfaces/IUserWorksheetBookmarkResponse'
import { IUserWorksheetUpdateSortOrderRequest } from '../interfaces/IUserWorksheetUpdateSortOrderRequest'
import { IUserWorksheetUpdateSortOrderResponse } from '../interfaces/IUserWorksheetUpdateSortOrderResponse'
import { IUserWorksheetDeleteRequest } from '../interfaces/IUserWorksheetDeleteRequest'
import { IUserWorksheetDeleteResponse } from '../interfaces/IUserWorksheetDeleteResponse'

export class UserWorksheetsService {
  public async getUserWorksheetList(
    request: IUserWorksheetListRequest
  ): Promise<IUserWorksheetListResponse> {
    return await Parse.Cloud.run('getUserWorksheetList', { request })
  }

  public async saveUserWorksheet(
    request: IUserWorksheetSaveRequest
  ): Promise<IUserWorksheetSaveResponse> {
    return await Parse.Cloud.run('saveUserWorksheet', { request })
  }

  public async bookmarkUserWorksheet(
    request: IUserWorksheetBookmarkRequest
  ): Promise<IUserWorksheetBookmarkResponse> {
    return await Parse.Cloud.run('bookmarkUserWorksheet', { request })
  }

  public async updateUserWorksheetSortOrder(
    request: IUserWorksheetUpdateSortOrderRequest
  ): Promise<IUserWorksheetUpdateSortOrderResponse> {
    return await Parse.Cloud.run('updateUserWorksheetSortOrder', { request })
  }

  public async deleteUserWorksheet(
    request: IUserWorksheetDeleteRequest
  ): Promise<IUserWorksheetDeleteResponse> {
    return await Parse.Cloud.run('deleteUserWorksheet', { request })
  }
}
