import React from 'react'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import clsx from 'clsx'
import './AvatarBadge.scss'

const AvatarBadge = ({ isCompleted }) => {
  return (
    <div className={clsx('avatar-badge', isCompleted ? 'completedBadge' : 'incompleteBadge')}>
      {isCompleted ? <CheckIcon fontSize='inherit' /> : <CloseIcon fontSize='inherit' />}
    </div>
  )
}

export default AvatarBadge
