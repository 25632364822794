import React from 'react'
import { Grid, Paper } from '@material-ui/core'
import Avatar, { AvatarUserMinimum } from '../../../shared/Avatar'
import User from 'src/app/user/aggregate/User'
import ArchivedLabel from './../../../shared/ArchivedLabel'
import styles from './../../../shared/Avatar.scss'

interface ICalendarEventDrawerSelectedParticipantCard {
  participant: any
}

const CalendarEventDrawerSelectedParticipantCard: React.FC<
  ICalendarEventDrawerSelectedParticipantCard
> = ({ participant }) => {
  return (
    <Grid item>
      <Paper elevation={2} className='participant-card'>
        <Grid container spacing={1} alignItems='center'>
          <Grid item>{renderAvatar(participant)}</Grid>
          <Grid item className='participant-card-name'>
            <div
              style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center' }}
            >
              <span style={participant.isArchived ? { fontStyle: styles.archivedFontStyle } : {}}>
                {participant.name}
              </span>
              {participant.isArchived ? <ArchivedLabel /> : undefined}
            </div>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

const renderAvatar = (participant) => {
  if (participant.type !== 'user' && participant.type !== 'contact') return null
  return (
    <Avatar
      user={participant as User | AvatarUserMinimum}
      size={40}
      style={{ marginLeft: styles.marginLeft, fontSize: styles.fontSize }}
      showOnlyFirstInitial
      isArchived={participant.isArchived}
    />
  )
}

export default CalendarEventDrawerSelectedParticipantCard
