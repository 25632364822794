import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { EventsStore } from '../../store/EventsStore'
import { Grid, InputLabel } from '@material-ui/core'
import CalendarEventDrawerAddParticipantCard from './CalendarEventDrawerAddParticipantCard'
import CalendarEventDrawerSelectedParticipantCard from './CalendarEventDrawerSelectedParticipantCard'

interface ICalendarEventDrawerInvitees {
  localizationStore?: LocalizationStore
  eventsStore?: EventsStore
}

const CalendarEventDrawerInvitees: React.FC<ICalendarEventDrawerInvitees> = ({
  localizationStore,
  eventsStore,
}) => {
  const { lzStrings } = localizationStore
  const { editVM: vm } = eventsStore

  return (
    <Grid item xs={12} container spacing={1} className='invitees-section'>
      <Grid item xs={12}>
        <InputLabel>{lzStrings.calendarEventDrawer.invitees}</InputLabel>
      </Grid>
      <Grid item xs={6}>
        <CalendarEventDrawerAddParticipantCard />
      </Grid>
      {vm.participantsSelectVM.participants.map((participant) => (
        <Grid item xs={6} key={participant.id}>
          <CalendarEventDrawerSelectedParticipantCard participant={participant} />
        </Grid>
      ))}
    </Grid>
  )
}

export default inject('localizationStore', 'eventsStore')(observer(CalendarEventDrawerInvitees))
