import { RootStore } from '../stores/RootStore'
import Parse from 'parse'
import { action, observable } from 'mobx'
import { GroupsAndRolesWidgetVM } from './view-models/GroupsAndRolesWidgetVM'
import WeightProfilesImportVM from '../weight-profiles/view-models/WeightProfilesImportVM'

export class GroupsAndRolesStore {
  constructor(rootStore: RootStore | null = null) {
    if (rootStore) this.rootStore = rootStore
    this.groupsAndRolesWidgetVM = new GroupsAndRolesWidgetVM(this.rootStore)
  }

  private rootStore: RootStore
  @observable public groupsAndRolesWidgetVM: GroupsAndRolesWidgetVM = null

  public setRootStore(root) {
    this.rootStore = root
  }
}
