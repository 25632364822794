import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import MuxPlayer from '@mux/mux-player-react'
import rootStore from '../stores/RootStore'
import { CMSItemsFindService } from '../cms-items/service/CMSItemsFindService'
import { isNumeric } from './isNumeric'
import { platform } from '../../utils/getPlatform'
import { browser } from '../../utils/getBrowser'

interface Props {
  cmsItemId: string
  playbackUrl: string
  playbackId?: string
  videoTitle?: string
  style?: CSSProperties
}

interface IItemFindResponse {
  playback_id: string
  video_id: string
  video_title: string
}

const MuxVideo: React.FC<Props> = ({
  cmsItemId,
  playbackUrl,
  playbackId = '',
  videoTitle = '',
  style,
}) => {
  const [muxPlaybackId, setMuxPlaybackId] = useState(playbackId)
  const [muxVideoId, setMuxVideoId] = useState(cmsItemId)
  const [muxVideoTitle, setMuxVideoTitle] = useState(videoTitle)

  const mounted = useRef(false)

  async function loadCMSItemData(cmsItemId: string): Promise<IItemFindResponse> {
    const svc = new CMSItemsFindService(rootStore)

    const response = await svc.getItem(Number(cmsItemId))
    if (!response) return

    if (Boolean(response.Optimized_Video) && Boolean(response.Optimized_Video.playback_id)) {
      return {
        playback_id: response.Optimized_Video.playback_id,
        video_id: response.Optimized_Video.asset_id,
        video_title: response.Optimized_Video.title,
      }
    }
  }

  const isMux = (playbackId: string) => {
    if (!playbackId) return false
    return !playbackId.startsWith('http')
  }

  const canPlayMux = () => {
    if (browser === 'Apple Safari') return false
    if (platform === 'ios') return false
    return true
  }

  useEffect(() => {
    mounted.current = true

    async function prepare() {
      try {
        const fetchData = async () => {
          loadCMSItemData(cmsItemId).then((item) => {
            if (!item) return
            if (mounted.current) {
              setMuxPlaybackId(item.playback_id)
              setMuxVideoId(item.video_id)
              setMuxVideoTitle(item.video_title)
            }
          })
        }
        if (!isMux(muxPlaybackId)) fetchData()
      } catch (e) {
        console.error(e)
      }
    }
    prepare()

    return () => {
      mounted.current = false
    }
  }, [])

  if (canPlayMux() && isNumeric(cmsItemId) && isMux(muxPlaybackId)) {
    return (
      <MuxPlayer
        style={{ ...style }}
        streamType='on-demand'
        playbackId={muxPlaybackId}
        metadata={{
          video_id: muxVideoId,
          video_title: muxVideoTitle,
          viewer_user_id: rootStore.appStore.currentUserId,
        }}
        className={'mux-player swiper-lazy'}
      />
    )
  }

  return (
    <video className='swiper-lazy' controls width='100%' src={`${playbackUrl}`}>
      <source type='video/mp4' src={`${playbackUrl}`} />
    </video>
  )
}

export default observer(MuxVideo)
