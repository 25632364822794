import { action, computed, observable } from 'mobx'
import { RootStore } from '../../../../stores/RootStore'
import { PulseCampaignStatus } from '../../../type/PulseCampaignStatus'
import { PulseCampaignTemplatesListVM } from './PulseCampaignTemplatesListVM'

export class PulseCampaignTemplatesDashboardVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.lz = this.rootStore.localizationStore.lzStrings.pulseCampaign
    this.labelsStore = this.rootStore.labelsStore
    this.listVM = new PulseCampaignTemplatesListVM(this.rootStore, this)
  }

  @observable public templateLayout: number = 0
  @observable public lz = undefined
  @observable public labelsStore = undefined
  @observable public listVM: PulseCampaignTemplatesListVM = null

  @computed
  public get isSystemAdmin(): boolean {
    return this.rootStore.appStore.isSystemAdmin
  }

  @computed
  public get canManage() {
    return this.isSystemAdmin 
  }

  public setTemplateLayout(index: number) {
    this.templateLayout = index
  }

  @action
  public createPulseTemplate() {
    this.rootStore.appStore.router.push('/pulse/v1/fromtemplate/edit/new')
  }

}
