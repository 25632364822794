import { computed, action, observable, autorun, reaction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import RGLItem from '../aggregate/RGLItem'
import { DashboardsUpdateService } from '../service/DashboardsUpdateService'
import DashboardEditVM from './DashboardEditVM'

export class WidgetContainerVM {
  private rootStore: RootStore
  private editVM: DashboardEditVM

  constructor(rootStore: RootStore, RGLItem, dashboardId: string, editVM: DashboardEditVM = null) {
    this.rootStore = rootStore
    this.RGLItem = RGLItem
    this.dashboardId = dashboardId
    this.editVM = editVM
  }

  @observable RGLItem: RGLItem = null
  @observable dashboardId: string = ''

  @computed
  public get type(): string {
    return this.RGLItem.type
  }

  @computed
  public get index(): string {
    return this.RGLItem.i
  }

  @computed
  public get i(): string {
    return this.RGLItem.i
  }

  @computed
  public get x(): Number {
    return this.RGLItem.x
  }

  @computed
  public get y(): Number {
    return this.RGLItem.y
  }

  @computed
  public get h(): Number {
    return this.RGLItem.h
  }

  @computed
  public get w(): Number {
    return this.RGLItem.w
  }

  @computed
  public get worksheetId(): string {
    return this.RGLItem.worksheetId
  }

  @action
  public removeWidget() {
    this.editVM.removeWidget(this.index)
  }

  @action
  public setWorksheet(objectId: string) {
    this.RGLItem.worksheetId = objectId
    this.saveWorksheet(objectId)
  }

  public async saveWorksheet(objectId: string) {
    const foundDash = this.rootStore.dashboardsStore.getDashboard(this.dashboardId)
    if (foundDash) {
      const foundWidget = foundDash.lgLayout.find((e) => e.i === this.index)
      if (foundWidget) {
        foundWidget.worksheetId = objectId
        const svc = new DashboardsUpdateService()
        await svc.updateDashboard(foundDash.serialize())
      }
    }
  }
}
