import { action, computed, observable } from 'mobx'
import { RootStore } from 'src/app/stores/RootStore'
import { IAnnouncementDTO } from '../../dtos/IAnnouncementDTO'
import { Announcement } from '../../aggregate/Announcement'
import { AnnouncementParticipantsListVM } from './AnnouncementParticipantsListVM'
import { UserAnnouncementVM } from '../UserAnnouncementVM'
import moment from 'moment'
import User from 'src/app/users/aggregate/User'
import { AnnouncementsStore } from '../../store/AnnouncementsStore'

export class AnnouncementAnalyticsVM {
  private rootStore: RootStore
  private blankValue: string = '-'
  private unavailable: string = 'Unavailable'

  constructor(rootStore: RootStore, announcementId: string) {
    this.rootStore = rootStore
    this.announcementId = announcementId
    this.dataStore = new AnnouncementsStore(this.rootStore)
    this.dataStore.onRecordUpdated = (e) => this.loadAnnouncement()
    this.refreshData(announcementId)
  }

  @action
  public refreshData(announcementId: string) {
    this.loadAnnouncement()
    this.tableVM = new AnnouncementParticipantsListVM(this.rootStore, this, announcementId)
  }

  @observable public announcementId: string = null
  @observable public tableVM: AnnouncementParticipantsListVM = null
  @observable public userAnnouncementVM: UserAnnouncementVM = null
  @observable public announcement: Announcement
  @observable public isLoadingAnnouncement: boolean = false
  @observable public createdByUser: User
  @observable public announcementIndex: number
  private dataStore: AnnouncementsStore

  @computed
  public get isAdmin() {
    const isAdmin = this.rootStore.appStore.isSystemAdmin || this.rootStore.appStore.isOrgAdmin
    return isAdmin
  }

  @action
  public async loadAnnouncement() {
    this.isLoadingAnnouncement = true
    if (!this.rootStore.userStore.loaded) {
      setTimeout(() => this.loadAnnouncement(), 500)
      return
    }
    let foundAnnouncement = this.dataStore.getRecord(this.announcementId)
    if (!foundAnnouncement) {
      foundAnnouncement = await this.dataStore.getFullRecord(this.announcementId)
    }

    if (
      this.isAdmin ||
      foundAnnouncement.createdByUserId === this.rootStore.appStore.currentUserId
    ) {
      this.announcement = foundAnnouncement
    }
    this.isLoadingAnnouncement = false
  }

  private formatDate(isoString: string) {
    if (!isoString) return '-'
    return moment(isoString).format('lll')
  }

  @computed
  public get likes() {
    if (!this.announcement) return this.blankValue
    return this.announcement.likes
  }

  @computed
  public get likePercentage() {
    const total = this.announcement.numberOfParticipants
    if (total && total !== 0 && typeof this.likes === 'number') {
      return `${(this.likes / total) * 100}% Liked`
    } else {
      return this.unavailable
    }
  }

  @computed
  public get views() {
    if (!this.announcement) return this.blankValue
    return this.announcement.views
  }

  @computed
  public get viewPercentage() {
    const total = this.announcement.numberOfParticipants
    if (total && total !== 0 && typeof this.views === 'number') {
      return `${(this.views / total) * 100}% Viewed`
    }
    return this.unavailable
  }

  @computed
  public get numberOfParticipants() {
    if (!this.announcement) return this.blankValue
    return this.announcement.numberOfParticipants
  }

  @computed
  public get createdAt() {
    return this.formatDate(this.announcement.createdAt.toISOString())
  }

  @computed
  public get createdByUserName() {
    if (this.announcement && this.announcement.fk_createdByUser)
      return this.announcement.fk_createdByUser.name
    return this.blankValue
  }

  @computed
  public get canCreateAnnouncements(): boolean {
    return this.rootStore.appStore.canCreateAnnouncements
  }

  @action
  public navigateBackToMainScreen() {
    if (this.isAdmin) {
      this.rootStore.appStore.router.push('/announcement/list/all')
    } else if (this.canCreateAnnouncements) {
      const userId = this.rootStore.appStore.currentUserId
      this.rootStore.appStore.router.push(`/announcement/list/forloggedinuser/${userId}`)
    }
  }
}
