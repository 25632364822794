import React from 'react'
import { observer, inject } from 'mobx-react'
import { Card, Grid, FormControlLabel, Checkbox, CardActions, IconButton } from '@material-ui/core'
import Popover from '@material-ui/core/Popover'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import { OrganizationUsersListTableVM } from '../../view-models/list/OrganizationUsersListTableVM'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'
import './ColumnPopup.scss'

interface ColumnPopupProps {
  vm: OrganizationUsersListTableVM
  localizationStore?: LocalizationStore
}

const ColumnPopup: React.FC<ColumnPopupProps> = ({ vm, localizationStore }) => {
  if (!vm) return null
  const lz = localizationStore.lzStrings.orgUsersList

  const close = () => vm.setColumnPopupAnchorEl(null)

  const renderName = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={vm.showNameColumn}
            onChange={() => vm.modifyViewColumn('name')}
            color='default'
          />
        }
        label={lz.name}
      />
    )
  }

  const renderFirstName = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={vm.showFirstColumn}
            onChange={() => vm.modifyViewColumn('first')}
            color='default'
          />
        }
        label={lz.first_name}
      />
    )
  }

  const renderLastName = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={vm.showLastColumn}
            onChange={() => vm.modifyViewColumn('last')}
            color='default'
          />
        }
        label={lz.last_name}
      />
    )
  }

  const renderEmail = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={vm.showEmailColumn}
            onChange={() => vm.modifyViewColumn('email')}
            color='default'
          />
        }
        label={lz.email}
      />
    )
  }

  const renderUsername = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={vm.showUsernameColumn}
            onChange={() => vm.modifyViewColumn('username')}
            color='default'
          />
        }
        label={lz.username}
      />
    )
  }

  const renderRoles = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={vm.showRolesColumn}
            onChange={() => vm.modifyViewColumn('roles')}
            color='default'
          />
        }
        label={lz.roles}
      />
    )
  }

  const renderGroups = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={vm.showGroupsColumn}
            onChange={() => vm.modifyViewColumn('groups')}
            color='default'
          />
        }
        label={lz.groups}
      />
    )
  }

  const renderPrimaryRole = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={vm.showPrimaryRoleColumn}
            onChange={() => vm.modifyViewColumn('primaryRole')}
            color='default'
          />
        }
        label={'Primary Role'}
      />
    )
  }

  const renderPrimaryGroup = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={vm.showPrimaryGroupColumn}
            onChange={() => vm.modifyViewColumn('primaryGroup')}
            color='default'
          />
        }
        label={'Primary Group'}
      />
    )
  }

  const renderPhone = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={vm.showPhoneColumn}
            onChange={() => vm.modifyViewColumn('phoneNumber')}
            color='default'
          />
        }
        label={lz.phone}
      />
    )
  }

  const renderPrivilegeSets = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={vm.showPrivilegeSetsColumn}
            onChange={() => vm.modifyViewColumn('privilegeSets')}
            color='default'
          />
        }
        label={lz.privilege_sets}
      />
    )
  }

  const renderPrivileges = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={vm.showPrivilegesColumn}
            onChange={() => vm.modifyViewColumn('privileges')}
            color='default'
          />
        }
        label={'Privileges'}
      />
    )
  }

  const renderTitle = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={vm.showTitleColumn}
            onChange={() => vm.modifyViewColumn('title')}
            color='default'
          />
        }
        label={lz.title}
      />
    )
  }

  const renderBirthday = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={vm.showBirthdayColumn}
            onChange={() => vm.modifyViewColumn('birthday')}
            color='default'
          />
        }
        label={lz.birthdate}
      />
    )
  }

  const renderEmployeeId = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={vm.showEmployeeIdColumn}
            onChange={() => vm.modifyViewColumn('employeeId')}
            color='default'
          />
        }
        label={lz.employee_id}
      />
    )
  }

  const renderJob = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={vm.showJobNumberColumn}
            onChange={() => vm.modifyViewColumn('jobNumber')}
            color='default'
          />
        }
        label={lz.job_number}
      />
    )
  }

  const renderRoleStartDate = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={vm.showRoleStartDateColumn}
            onChange={() => vm.modifyViewColumn('roleStartDate')}
            color='default'
          />
        }
        label={lz.role_start_date}
      />
    )
  }

  const renderLanguage = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={vm.showLanguageColumn}
            onChange={() => vm.modifyViewColumn('language')}
            color='default'
          />
        }
        label={lz.language}
      />
    )
  }

  const renderEmailNotifications = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={vm.showEmailNotificationsColumn}
            onChange={() => vm.modifyViewColumn('emailNotifications')}
            color='default'
          />
        }
        label={lz.email_notifications}
      />
    )
  }

  const renderPushNotifications = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={vm.showPushNotificationsColumn}
            onChange={() => vm.modifyViewColumn('pushNotifications')}
            color='default'
          />
        }
        label={lz.push_notifications}
      />
    )
  }

  const renderTextNotifications = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={vm.showTextNotificationsColumn}
            onChange={() => vm.modifyViewColumn('textNotifications')}
            color='default'
          />
        }
        label={lz.text_notifications}
      />
    )
  }

  const renderWelcome = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={vm.showWelcomeColumn}
            onChange={() => vm.modifyViewColumn('welcomeEmailStatus')}
            color='default'
          />
        }
        label={lz.welcome_email_status}
      />
    )
  }

  const renderConsent = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={vm.showConsentColumn}
            onChange={() => vm.modifyViewColumn('hasConsented')}
            color='default'
          />
        }
        label={lz.has_consented}
      />
    )
  }

  const renderCreatedAt = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={vm.showCreatedColumn}
            onChange={() => vm.modifyViewColumn('createdAt')}
            color='default'
          />
        }
        label={'Created At'}
      />
    )
  }
  const renderUpdatedAt = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={vm.showUpdatedColumn}
            onChange={() => vm.modifyViewColumn('updatedAt')}
            color='default'
          />
        }
        label={'Updated At'}
      />
    )
  }

  const renderCardContent = () => {
    return (
      <Grid container className='checkbox-container'>
        <Grid item xs={6} className='column-checkbox'>
          {renderFirstName()}
          {renderLastName()}
          {renderName()}
          {renderEmail()}
          {renderUsername()}
          {renderPhone()}
          {renderRoles()}
          {renderGroups()}
          {renderPrivilegeSets()}
          {renderPrivileges()}
          {renderPrimaryRole()}
          {renderPrimaryGroup()}
        </Grid>
        <Grid item xs={6} className='column-checkbox'>
          {renderTitle()}
          {renderBirthday()}
          {renderEmployeeId()}
          {renderJob()}
          {renderRoleStartDate()}
          {renderLanguage()}
          {renderEmailNotifications()}
          {renderPushNotifications()}
          {renderTextNotifications()}
          {renderWelcome()}
          {renderConsent()}
          {renderCreatedAt()}
          {renderUpdatedAt()}
        </Grid>
      </Grid>
    )
  }

  const renderCardActions = () => {
    return (
      <CardActions className='column-card-actions'>
        <IconButton size='small' onClick={() => close()}>
          <CloseOutlinedIcon />
        </IconButton>
      </CardActions>
    )
  }

  return (
    <Popover
      id='OrgUsersListFilterPopover'
      open={Boolean(vm.columnPopupAnchorEl)}
      anchorEl={vm.columnPopupAnchorEl}
      onClose={() => close()}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Card id='ColumnPopoverCard' onMouseLeave={() => close()}>
        {renderCardActions()}
        {renderCardContent()}
      </Card>
    </Popover>
  )
}

export default inject('localizationStore')(observer(ColumnPopup))
