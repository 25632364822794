import { observable, action, computed, runInAction, autorun, reaction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { PagedDataVM } from '../../shared/view-models/PagedDataVM'
import Parse from 'parse'
import { Group } from '../aggregate/Group'
import { GroupEditVM } from '../view-models/GroupEditVM'
import { DataStore } from '../../shared/data/DataStore'
import { IGroupDTO } from '../dtos/IGroupDTO'

export class GroupsStore extends DataStore<Group, IGroupDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, Group, 'groups', [
      'organizationId',
      'defaultDashboardId',
      'description',
      'privilegeSets',
      'name',
      'forceDashboardRoute',
      'memberCount'
    ])
  }

  @observable public editVM: GroupEditVM = null
  @observable public usersInGroup: Array<any> = []
  @observable public groupMenuId: string = ''
  @observable public groupToEdit: Group = null
  @observable public active: boolean = true
  @observable public showDrawer: boolean = false
  @observable public loaded: boolean = false

  @action
  public onListRecordsLoaded() {
    this.loaded = true
  }

  @action
  public toggleGroupsDrawer() {
    this.showDrawer = !this.showDrawer
  }

  @action
  public closeGroupsDrawer() {
    this.showDrawer = false
    setTimeout(() => (this.editVM = null), 500)
  }

  @action
  public setActive() {
    this.active = true
  }

  @action
  public setInactive() {
    this.active = false
  }

  @action clearForms() {
    this.editVM = null
    this.groupToEdit = null
  }

  @computed
  public get groups() {
    return this.records
  }

  public getGroup(objectId): Group {
    return this.groups.find((e) => e.objectId === objectId)
  }

  public getGroupByName(name): Group {
    return this.groups.find((e) => e.name === name)
  }

  @action
  public setGroupToEdit(objectId) {
    this.groupToEdit = this.getGroup(objectId)
    this.editVM = new GroupEditVM(this.rootStore, this.groupToEdit)
    this.showDrawer = true
  }

  @action
  public setNewGroupToEdit() {
    this.editVM = new GroupEditVM(this.rootStore)
    this.showDrawer = true
  }
}
