import React from 'react'
import { inject, observer } from 'mobx-react'
import {
  AppBar,
  Button,
  CssBaseline,
  Grid,
  IconButton,
  TextField,
  Toolbar,
  Tooltip,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ReplayIcon from '@material-ui/icons/Replay'
import SaveRoundedIcon from '@material-ui/icons/SaveRounded'
import rootStore from '../../../stores/RootStore'
import { EmailTemplatePreviewVM } from '../../view-models/EmailTemplatePreviewVM'
import EmailTemplatesEditorViewToggle from './../editor/EmailTemplatesEditorViewToggle'
import '../../../css/dashboard-toolbar.scss'
import './EmailTemplateEditTopToolbar.scss'

interface Props {
  vm: EmailTemplatePreviewVM
}

const EmailTemplateEditTopToolbar: React.FC<Props> = ({ vm }) => {
  const { emailTemplateManagement: lz } = rootStore.localizationStore.lzStrings

  if (!vm) return null

  const handleClose = () => {
    if (!vm.isDirty) return vm.close()
    vm.doClose = true
    vm.toggleConfirmDialog()
  }

  const handleReset = () => {
    if (!vm.isDirty) return
    vm.doReset = true
    vm.toggleConfirmDialog()
  }

  const handleSave = () => {
    vm.toggleConfirmDialog()
  }

  const onNameChange = (e) => {
    vm.setPreviewName(e.target.value)
  }

  const renderEmailTemplateName = () => {
    return (
      <TextField
        error={!vm.emailTemplateNameValid}
        value={vm.emailTemplate || !vm.isLoading ? vm.name : lz.loading}
        onChange={onNameChange}
        variant='outlined'
        color='primary'
        className='email-template-name-toolbar'
        disabled={vm.isLoading || vm.isSaving}
      />
    )
  }

  const renderSaveButton = () => {
    if (!vm.canManage) return
    return (
      <Tooltip title={lz.save_email_templates_info} placement='bottom-start' arrow>
        <span>
          <Button
            className='email-template-dialog-action-save'
            variant='contained'
            onClick={handleSave}
            disabled={!vm.isDirty || vm.isLoading || vm.isSaving}
            size='small'
            startIcon={<SaveRoundedIcon />}
          >
            {`${lz.save} ${lz.email_template}`}
          </Button>
        </span>
      </Tooltip>
    )
  }

  const renderResetButton = () => {
    return (
      <Tooltip title={`${lz.reset} ${lz.email_template}`} placement='bottom-start' arrow>
        <span>
          <IconButton
            className='email-template-dialog-action'
            edge='start'
            color='inherit'
            aria-label='close'
            disabled={!vm.isDirty}
            onClick={handleReset}
          >
            <ReplayIcon />
          </IconButton>
        </span>
      </Tooltip>
    )
  }

  const renderCloseButton = () => {
    return (
      <Tooltip
        title={rootStore.localizationStore.lzStrings.visualizations.close}
        placement='bottom-start'
        arrow
      >
        <span>
          <IconButton
            className='email-template-dialog-action'
            edge='start'
            color='inherit'
            onClick={handleClose}
            aria-label='close'
          >
            <CloseIcon />
          </IconButton>
        </span>
      </Tooltip>
    )
  }

  return (
    <div id='DashboardToolbar' className={'dashboardToolbar-header'}>
      <CssBaseline />
      <AppBar style={{ position: 'relative' }}>
        <Toolbar className={'dashboard-toolbar'}>
          <Grid container direction='row' justifyContent='space-between' alignItems='center'>
            {renderEmailTemplateName()}
            <EmailTemplatesEditorViewToggle vm={vm} />
            <div id='EmailTemplateEditDialogRight'>
              {renderSaveButton()}
              {renderResetButton()}
              {renderCloseButton()}
            </div>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default inject('worksheetsStore')(observer(EmailTemplateEditTopToolbar))
