import { action, observable, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Option } from '../aggregate/Option'

export class SurveyResultsRowVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, option: Option, questionResult: object) {
    this.rootStore = rootStore
    this.option = option
    this.questionResult = questionResult
  }

  public option: Option = null
  public questionResult: any = null

  @computed
  public get emoji(): string {
    if (!this.option.emoji) return ''
    return this.option.emoji
  }

  @computed
  public get shorterOptionText(): string {
    if (!this.optionText) return ''
    if (this.optionText.length > 30) return this.optionText.substring(0, 30) + '..'
    return this.optionText
  }

  @computed
  public get isChecked(): boolean {
    return this.option.isChecked
  }

  @computed
  public get optionText(): string {
    return this.option.text
  }

  @computed
  public get myNumberAnswer(): number {
    return this.option.responseNumber
  }

  @computed
  public get numberAverage(): number {
    return this.questionResult[this.option.id] / this.questionResult.totalResponses
  }

  @computed
  public get progress(): number {
    return this.questionResult[this.option.id] / this.questionResult.totalResponses
  }

  @computed
  public get progressPercent(): number {
    return Math.round(this.progress * 100)
  }

  @computed
  public get percent(): string {
    return `${String(Math.round(this.progress * 100))}%`
  }
}
