import { action, computed, observable } from 'mobx'
import { ParseService } from '../../services/ParseService'
import { RootStore } from '../../stores/RootStore'
import { ISurveyTagDTO } from '../dtos/ISurveyTagDTO'

export default class WidgetForm {
  rootStore: RootStore

  constructor(rootStore: RootStore, surveyTag?: ISurveyTagDTO) {
    this.rootStore = rootStore
    if (surveyTag) this.loadData(surveyTag)
  }

  @observable objectId: string = ''
  @observable name: string = ''
  @observable description: string = ''

  @observable isDirty: boolean = false
  @observable saveTried: boolean = false
  @observable saveSuccessful: boolean = false
  @observable deletePending: boolean = false
  @observable deleteSuccessful: boolean = false

  private loadData(incomingSurveyTags: ISurveyTagDTO) {
    this.objectId = incomingSurveyTags.objectId
    this.name = incomingSurveyTags.name
    this.description = incomingSurveyTags.description
  }

  private toDTO() {
    const newSurveyTag: ISurveyTagDTO = {
      objectId: this.objectId,
      organizationId: this.rootStore.appStore.currentOrgId,
      name: this.name.trim(),
      description: this.description.trim(),
      isDeleted: false,
    }

    return newSurveyTag
  }

  @action
  public setField(key: string, value: string | boolean) {
    this[key] = value
    this.isDirty = true
  }

  @computed get nameError() {
    return this.saveTried && !this.name
  }

  @computed get saveEnabled() {
    return Boolean(this.name) && this.isDirty
  }

  @computed get deleteEnabled() {
    return Boolean(this.objectId)
  }

  @action
  public async saveSurveyTag() {
    this.saveTried = true
    if (this.nameError) return
    const pSvc = new ParseService()
    const result = await pSvc.saveSurveyTag(this.rootStore.appStore.currentOrgId, this.toDTO())
    if (result) {
      this.saveSuccessful = true
      this.rootStore.surveyTagsStore.loadListRecords()
      setTimeout(() => this.rootStore.surveyTagsStore.closeWidgetDrawer(), 500)
    }
  }

  public async deleteSurveyTag() {
    this.deletePending = true
    const pSvc = new ParseService()
    const result = await pSvc.deleteSurveyTag(
      this.rootStore.appStore.currentOrgId,
      this.objectId
    )
    if (result) {
      this.deleteSuccessful = true
      this.rootStore.surveyTagsStore.loadListRecords()
      setTimeout(() => this.rootStore.surveyTagsStore.closeWidgetDrawer(), 500)
    }
  }
}
