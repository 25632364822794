import { action, computed, observable } from 'mobx'
import { PulseQuestionEditVM } from './PulseQuestionEditVM'
import { RootStore } from '../../stores/RootStore'
import { PulseQuestionOption } from '../aggregate/PulseQuestionOption'
import { ResponseType } from '../types/ResponseType'
import { PulseQuestionType } from '../types/PulseQuestionType'
import PulseQuestion from '../aggregate/PulseQuestion'
import FollowUpQuestion from '../aggregate/FollowUpQuestion'

export class PulseQuestionOptionVM {
  private rootStore: RootStore

  constructor(
    rootStore: RootStore,
    pulseQuestionOption: PulseQuestionOption,
    index: number,
    parentVM: PulseQuestionEditVM
  ) {
    this.rootStore = rootStore
    this.pulseQuestionOption = pulseQuestionOption
    this.index = index
    this.parentVM = parentVM
    this.loadFollowUpVM()
  }

  @observable public pulseQuestionOption: PulseQuestionOption = null
  @observable public parentVM: PulseQuestionEditVM = null
  @observable public index: number = 0
  @observable public followUpQuestionVM: PulseQuestionEditVM = null

  @action
  private loadFollowUpVM() {
    if (!this.hasFollowUp) return
    this.followUpQuestionVM = new PulseQuestionEditVM(this.rootStore, this.parentVM.childCategoryId, this.followUpQuestion as PulseQuestion, this.parentVM.isReadOnly, true) 
  }

  @computed
  public get isReadOnly(): boolean {
    return this.parentVM.isReadOnly
  }

  @computed
  public get isFollowUp(): boolean {
    return this.parentVM.isFollowUp
  }

  @computed
  public get isSystemAdmin(): boolean {
    return this.parentVM.isSystemAdmin
  }

  @computed
  public get questionType(): PulseQuestionType {
    return this.parentVM.questionType
  }

  @computed
  public get responseTypeOptions() {
    let options = [
      { value: 'very positive' },
      { value: 'positive' },
      { value: 'neutral' },
      { value: 'negative' },
      { value: 'very negative' },
    ]
    return options
  }

  @computed
  public get responseType(): ResponseType {
    return this.pulseQuestionOption.responseType
  }

  @computed
  public get text(): string {
    return this.pulseQuestionOption.text
  }

  @computed
  public get hasFollowUp(): boolean {
    return this.pulseQuestionOption.hasFollowUp
  }

  @computed
  public get followUpQuestion(): FollowUpQuestion {
    return this.pulseQuestionOption.followUpQuestion
  }

  @computed
  public get emoji(): string {
    return this.pulseQuestionOption.emoji
  }

  @computed
  public get isValid(): boolean {
    if (!this.parentVM.saveTried) return true
    if (!this.textValid) return false
    if (this.followUpQuestion && !this.followUpQuestion.title) return false
    return true
  }

  @computed
  public get textValid(): boolean {
    if (!this.parentVM.saveTried) return true
    if (!this.text || this.text === '') return false
    return true
  }

  @computed
  public get otherFollowUpQuestions(): PulseQuestionEditVM[] {
    const otherOptions = this.parentVM.options.filter((o) => o.index !== this.index)
    return otherOptions.filter((o) => o.hasFollowUp).map((o) => o.followUpQuestionVM)
  }

  @action
  public setEmoji(emoji: string) {
    this.pulseQuestionOption.setEmoji(emoji)
  }

  @action
  public setResponseType(responseType) {
    this.pulseQuestionOption.setResponseType(responseType)
  }

  @action
  public setText(text: string) {
    this.pulseQuestionOption.setText(text)
  }

  @action
  public setRank(val: number) {
    this.pulseQuestionOption.setRank(val)
  }

  @action
  public deleteOption() {
    this.parentVM.deleteOption(this.index)
  }

  @action
  public toggleHasFollowUpQuestion() {
    this.pulseQuestionOption.toggleHasFollowUp()
    if (this.hasFollowUp) return this.loadFollowUpVM()
    this.followUpQuestionVM = null
  }
}
