import React from 'react'
import { Card } from '@material-ui/core'
import { observer, inject } from 'mobx-react'
import './MyTrainingPlansWidget.scss'
import Content from './Content'
import Header from './Header'
import { UserTrainingPlansStore } from '../../store/UserTrainingPlansStore'

interface Props {
  userTrainingPlansStore?: UserTrainingPlansStore
}

const MyTrainingPlansWidget: React.FC<Props> = ({ userTrainingPlansStore }) => {
  const { myTrainingPlansWidgetVM: vm } = userTrainingPlansStore
  if (!vm) return null

  return (
    <>
      <Card id='MyTrainingPlansWidget' elevation={0}>
        <Header />
        <Content />
      </Card>
    </>
  )
}

export default inject('userTrainingPlansStore')(observer(MyTrainingPlansWidget))
