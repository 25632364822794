import { AGGridRowVM } from 'src/app/shared/ag-grid/AGGridRowVM'
import { RootStore } from '../../../stores/RootStore'
import { action, computed } from 'mobx'
import moment from 'moment'
import { Announcement } from '../../aggregate/Announcement'
import { AnnouncementListTableVM } from './AnnouncementListTableVM'

export class AnnouncementListRowVM extends AGGridRowVM<Announcement> {
  private listVM: AnnouncementListTableVM
  constructor(rootStore: RootStore, announcement: Announcement, listVM: AnnouncementListTableVM) {
    super(rootStore, announcement, listVM)
    this.listVM = listVM
  }

  private blankValue = '--'

  private formatDate(isoString: string) {
    if (!isoString) return this.blankValue
    return moment(isoString).format('l, LT')
  }

  @computed
  public get title(): string {
    return this.aggregate.title
  }

  @computed
  public get objectId(): string {
    return this.aggregate.objectId
  }

  @computed
  public get owner(): string {
    if (this.aggregate.fk_ownerUser) return this.aggregate.fk_ownerUser.name
    return this.blankValue
  }

  @computed
  public get createdAt(): string {
    return this.formatDate(this.aggregate.createdAt.toISOString())
  }

  @computed
  public get createdBy(): string {
    return this.aggregate.fk_createdByUser.name
  }

  @computed
  public get updatedAt(): string {
    if (this.aggregate.updatedAt && this.aggregate.fk_updatedByUser)
      return this.formatDate(this.aggregate.updatedAt.toISOString())
    return this.blankValue
  }

  @computed
  public get updatedBy(): string {
    if (this.aggregate.fk_updatedByUser) return this.aggregate.fk_updatedByUser.name
    return this.blankValue
  }

  @computed
  public get participantCount(): number {
    return this.aggregate.numberOfParticipants
  }

  @computed
  public get likeCount(): number {
    return this.aggregate.likes
  }

  @computed
  public get viewCount(): number {
    return this.aggregate.views
  }

  @computed
  public get attachmentCount(): number {
    return this.aggregate.attachments.length
  }

  @computed
  public get status(): string {
    if (this.aggregate.status) return this.aggregate.status
    return this.blankValue
  }

  public click() {
    //   const eventsVM = new UserAnnouncementEventsVM(
    //     this.aggregate.organizationId,
    //     this.aggregate.announcementId,
    //     this.aggregate.userId,
    //     this.aggregate.firstName,
    //     this.aggregate.lastName
    //   )
    //   this.listVM.eventsVM = eventsVM
    //   this.listVM.toggleShowEventDialog()
  }

  @action
  public editAnnouncement(e) {
    if (!this.rootStore.announcementsStore) return
    if (!this.rootStore.announcementsStore.announcementsWidgetVM) return
    this.rootStore.announcementsStore.announcementsWidgetVM.setAnchorEl(e)
    this.rootStore.announcementsStore.announcementsWidgetVM.toggleAnnouncementsWidgetOpen()
    this.rootStore.announcementsStore.lazyLoadEditVM(this.aggregate.objectId)
  }

  @action
  public viewAnalytics() {
    this.rootStore.appStore.router.push(`/announcement/analytics/${this.aggregate.objectId}`)
  }
}
