import { ColDef, GridOptions, RowClickedEvent } from 'ag-grid-community'
import { action, computed, observable } from 'mobx'
import { AGGridVM } from '../../../../shared/ag-grid/AGGridVM'
import { nameOf } from '../../../../shared/nameOf'
import { RootStore } from '../../../../stores/RootStore'
import { CatalogItemRowVM } from './CatalogItemRowVM'
import { CatalogItemsWidgetVM } from './CatalogItemsWidgetVM'
import { ICatalogItemsFindRequest } from '../../../../training-items/interfaces/ICatalogItemsFindRequest'
import { CatalogItemFindDataStore } from './CatalogItemFindDataStore'
import { LabelsStore } from '../../../../labels/store/LabelsStore'

export class CatalogItemsTabVM extends AGGridVM {
  private rootStore: RootStore
  public dataStore: CatalogItemFindDataStore
  public labelsStore?: LabelsStore

  constructor(
    rootStore: RootStore,
    widgetVM: CatalogItemsWidgetVM,
    req: ICatalogItemsFindRequest,
    tabIndex: number
  ) {
    super()
    this.rootStore = rootStore
    this.widgetVM = widgetVM
    this.dataStore = new CatalogItemFindDataStore(this.rootStore, req)
    this.sizeColumnsToFit = true
    this.tabIndex = tabIndex
    this.serverSideLoaded = true
    this.labelsStore = new LabelsStore(this.rootStore)
  }

  public widgetVM: CatalogItemsWidgetVM
  public tabIndex: number
  @observable public shownOnce: boolean = false

  @action
  public setShownOnce() {
    this.shownOnce = true
  }

  @computed
  public get shown(): boolean {
    return this.widgetVM.tabIndex === this.tabIndex
  }

  @computed
  public get shouldRenderGrid(): boolean {
    if (this.shownOnce) return true
    if (this.shown) return true
    return false
  }

  public getRowNodeId(row: CatalogItemRowVM): string {
    return row.objectId
  }

  public get shouldRender(): boolean {
    if (!this.rootStore.rolesStore.loaded) return false
    if (!this.rootStore.groupsStore.loaded) return false
    return true
  }

  @computed
  public get columnDefs(): ColDef[] {
    const s = this.rootStore.localizationStore.lzStrings
    const cols: ColDef[] = [
      {
        headerName: s.dashboard.name,
        field: nameOf<CatalogItemRowVM, string>((e) => e.name),
      },
      {
        headerName: this.labelsStore.getLabelByLanguageAndFor('category'),
        field: nameOf<CatalogItemRowVM, string[]>((e) => e.categories),
        sortable: false,
      },
      {
        headerName: s.dashboard.number_of_items,
        field: nameOf<CatalogItemRowVM, number>((e) => e.numberOfContentItems),
        sortable: false,
      },
      {
        headerName: s.dashboard.proof_of_completion,
        field: nameOf<CatalogItemRowVM, string>((e) => e.proofOfCompletion),
        sortable: false,
      },
    ]
    if (this.tabIndex === 0)
      cols.push({
        headerName: s.dashboard.actions,
        cellRenderer: 'actionsCell',
        sortable: false,
        onCellClicked: null,
      })
    return cols
  }

  public getGridOptions(): GridOptions {
    return {
      ...this.baseGridOptions,
      onRowClicked: (e) => this.rowClicked(e),
      getRowNodeId: (e) => this.getRowNodeId(e),
      columnDefs: this.ensureTooltipFields(this.columnDefs),
    }
  }

  @action
  public setQuickFilter(val: string) {
    this.typedFilterText = val
    if (this.quickFilterTO) clearTimeout(this.quickFilterTO)
    this.quickFilterTO = setTimeout(() => this.applyFilter(), 1000)
  }

  public applyFilter() {
    this.dataStore.setFilter(this.typedFilterText)
    this.reload()
    this.columnApi.applyColumnState({
      state: [{ colId: 'name', sort: 'desc' }],
    })
  }

  protected onGridReadied() {
    this.gridApi.setDatasource({
      rowCount: this.totalRecords,
      getRows: async (params) => {
        this.setShownOnce()
        params.sortModel.forEach((col: { colId: string; sort: 'asc' | 'desc' }, idx: number) => {
          let dbCol = col.colId
          if (dbCol === 'name') dbCol = 'name'
          if (idx === 0) this.dataStore.setSort(dbCol, col.sort)
          if (idx > 0) this.dataStore.addSort(dbCol, col.sort)
        })
        if (params.sortModel.length === 0) this.dataStore.setSort('name')
        if (params.startRow === 0) this.dataStore.setPage(0)
        await this.dataStore.getNextPage()
        const rows = this.dataStore.trainingItems.map(
          (e) => new CatalogItemRowVM(this.rootStore, this, e)
        )
        params.successCallback(rows, this.dataStore.totalRecords)
      },
    })
  }

  public get rows(): CatalogItemRowVM[] {
    return []
  }

  public rowClicked(e: RowClickedEvent) {}

  @computed
  protected get totalRecords() {
    return this.dataStore.totalRecords
  }

  @computed
  public get hasRows() {
    return this.dataStore.hasListRecords
  }

  @computed
  public get hasTrainingItemsFilter() {
    return this.widgetVM.hasPopupFilter
  }

  @computed
  public get noRowsToShowLabel() {
    return this.rootStore.localizationStore.lzStrings.dashboard.no_rows_to_show
  }
}
