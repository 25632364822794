import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import './SurveyTagsTable.scss'
import { AgGridReact } from 'ag-grid-react/lib/agGridReact'
import AGGridWidgetPagingRow from '../../../shared/ag-grid/AGGridPagingRow'
import { SurveyTagsStore } from '../../store/SurveyTagsStore'

interface Props {
  surveyTagsStore?: SurveyTagsStore
  localizationStore?: LocalizationStore
}

const SurveyTagsTable: React.FC<Props> = ({ localizationStore, surveyTagsStore }) => {
  const { surveyTagsWidgetVM: vm } = surveyTagsStore
  const s = localizationStore.lzStrings
  if (!vm) return null

  const renderGrid = () => {
    return (
      <div className='ag-theme-alpine' style={{ height: '100%', width: '100%' }}>
        <AgGridReact
          gridOptions={{
            ...vm.getGridOptions(),
          }}
        />
      </div>
    )
  }

  return (
    <>
      {renderGrid()}
      <AGGridWidgetPagingRow vm={vm} />
    </>
  )
}

export default inject('localizationStore', 'surveyTagsStore')(observer(SurveyTagsTable))
