import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Tooltip, Typography } from '@material-ui/core'
import React from 'react'
import { UserTrainingPlanTakeVM } from '../../view-models/UserTrainingPlanTakeVM'
import { observer } from 'mobx-react'
import { ContentVM } from '../../view-models/ContentVM'
import LinkIcon from '@material-ui/icons/Link'
import VideoIcon from '@material-ui/icons/VideocamRounded'
import PanToolIcon from '@material-ui/icons/PanTool'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import CheckIcon from '@material-ui/icons/Check'

interface Props {
  takeVM: UserTrainingPlanTakeVM
  item: ContentVM
  index: number
}

const UserTrainingPlanMenuContentItem: React.FC<Props> = ({ takeVM, item, index }) => {

  const renderContentItemIndicator = (item: ContentVM) => {
    if (item.isComplete) {
      return (
        <div className='completed-item'>
          <CheckIcon />
        </div>
      )
    } else {
      return (
        <div className={item.isSelected ? 'content-item-mark-selected' : 'content-item-mark'} />
      )
    }
  }

  const hasYouTubeURL = (url: string): boolean => {
    if (!url) return false
    const urlLower = url.toLowerCase()
    return urlLower.includes('youtube') || url.includes('youtu.be')
  }

  const hasVimeoURL = (url: string): boolean => {
    if (!url) return false
    const urlLower = url.toLowerCase()
    return urlLower.includes('vimeo')
  }

  const renderContentItem = () => {
    if (!item) return null
    const renderThumbnail = () => {
      if (!item) return null
      if (!item || !item.type) return null
      if (item.type === 'link') return <LinkIcon style={{ color: 'white' }} />
      if (item.type === 'hands-on') return <PanToolIcon style={{ color: 'white' }} />
      if (item.type === 'media' && item.media)
        if (item.media.type === 'image') {
          return <img style={{ width: '100%', height: '100%' }} src={item.media.fileUrl} />
        } else {
          if (item.media.fileUrl) {
            if (hasVimeoURL(item.media.fileUrl)) return <VideoIcon style={{ color: 'white' }} />
            if (hasYouTubeURL(item.media.fileUrl)) return <VideoIcon style={{ color: 'white' }} />
            return (
              <video style={{ width: '100%', height: '100%' }}>
                <source src={item.media.fileUrl} type='video/mp4' />
              </video>
            )
          } else return <VideoIcon style={{ color: 'white' }} />
        }
      if (item.type === 'exercise' && item.media)
        if (item.media.type === 'image') {
          return <img style={{ width: '100%', height: '100%' }} src={item.media.fileUrl} />
        } else {
          if (item.media.fileUrl) {
            if (hasVimeoURL(item.media.fileUrl)) return <VideoIcon style={{ color: 'white' }} />
            if (hasYouTubeURL(item.media.fileUrl)) return <VideoIcon style={{ color: 'white' }} />
            return (
              <video style={{ width: '100%', height: '100%' }}>
                <source src={item.media.fileUrl} type='video/mp4' />
              </video>
            )
          } else return <VideoIcon style={{ color: 'white' }} />
        }
      if (item.type === 'file') return <AttachFileIcon style={{ color: 'white' }} />
    }
    return (
      <AccordionDetails
        key={index}
        className={
          item.contentMenuItemEnabled ? 'content-item' : 'content-item content-item-disabled'
        }
      >
        <Grid container>
          <Grid item xs={1} className='content-mark-container'>
            {renderContentItemIndicator(item)}
          </Grid>
          <Grid
            item
            xs={11}
            className={
              item.isSelected
                ? 'selected-content-item content-item-container'
                : 'content-item-container'
            }
          >
            <Grid
              className={'content-item-body'}
              container
              onClick={() => {
                if (!item.contentMenuItemEnabled) return
                takeVM.setSelectedContentItem(item.id, item.trainingItemId)
              }}
            >
              <Grid item xs={12}>
                <div className='thumbnail-container'>{renderThumbnail()}</div>
                <span className='content-item-info'>
                  <Typography variant='caption' className='content-item-subtitle'>
                    {item.typeUpperCase}
                  </Typography>
                  <Typography variant='body1' className='content-item-title'>
                    {item.title}
                  </Typography>
                </span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    )
  }

  return (
    <>
      {renderContentItem()}
    </>
  )
}

export default observer(UserTrainingPlanMenuContentItem)
