import React from 'react'
import { inject, observer } from 'mobx-react'
import { SurveysStore } from '../../store/SurveysStore'
import LocalizationStore from '../../../localization/LocalizationStore'
import { Button, Menu, MenuItem, IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import RefreshIcon from '@material-ui/icons/Refresh'
import StorageIcon from '@material-ui/icons/Storage'
import BarChartIcon from '@material-ui/icons/BarChart'
import { SurveyEditTabsEnum } from '../../types/SurveyEditTabsEnum'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import PlusIcon from '@material-ui/icons/Add'
import MailIcon from '@material-ui/icons/Mail'
import './TopToolbar.scss'
import { LabelsStore } from '../../../labels/store/LabelsStore'

interface Props {
  surveysStore?: SurveysStore
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
  surveyTemplateId?: string
}

const TopToolbarEndButtons: React.FC<Props> = ({
  surveysStore,
  localizationStore,
  labelsStore,
  surveyTemplateId,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const vm = surveysStore.viewModels.editVM
  const s = localizationStore.lzStrings
  if (!vm) return null

  const renderCloseSurveyButton = () => {
    if (!vm.showCloseSurveyButton) return null
    if (vm.isTemplate) return null
    return (
      <Button
        variant='outlined'
        onClick={() => surveysStore.viewModels.editVM.openCloseSurveyDialog()}
      >
        {`${s.moreMenu.close} ${labelsStore.getLabelByLanguageAndFor('pulse')}`}
      </Button>
    )
  }

  const renderOpenSurveyButton = () => {
    if (vm.showCloseSurveyButton) return null
    if (!vm.isPublished) return null
    if (vm.isTemplate) return null
    return (
      <Button variant='outlined' onClick={() => surveysStore.viewModels.editVM.openSurvey()}>
        {`${s.moreMenu.open_survey} ${labelsStore.getLabelByLanguageAndFor('pulse')}`}
      </Button>
    )
  }

  const renderClosedSurveyMessage = () => {
    if (!vm.showCloseSurveyMessage) return null
    if (!vm.isPublished) return null

    return <span>{vm.closedMessage}</span>
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (value: string) => {
    setAnchorEl(null)
    if (value === 'delete') vm.toggleDeleteDialog()
    if (value === 'refresh') vm.refreshSurveyResponses()
    if (value === 'test-data') vm.testDataVM.populateTestData('self-assessment')
    if (value === 'copy-survey') vm.copySurvey()
    if (value === 'invitations') vm.refreshInvitations()
    if (value === 'run_nlp') vm.runNLPProcessing()
    if (value === 'save-survey-template') vm.saveAsSurveyTemplate()
  }

  const renderDeleteOption = () => {
    if (vm.isNewSurvey) return null
    let label = `${s.moreMenu.delete} ${labelsStore.getLabelByLanguageAndFor('pulse')}`
    if (vm.isSurveyTemplateEdit) label = `${s.moreMenu.delete} Template`
    return (
      <MenuItem disabled={vm.isTemplateAndDisabled} onClick={() => handleClose('delete')}>
        <DeleteIcon className='icon' />
        {label}
      </MenuItem>
    )
  }

  const renderRunNLPOption = () => {
    if (vm.isNewSurvey || !vm.isSystemAdmin) return null
    return (
      <MenuItem
        disabled={vm.isTemplateAndDisabled || vm.isSaving}
        onClick={() => handleClose('run_nlp')}
      >
        <BarChartIcon className='icon' /> {s.moreMenu.run_nlp}
      </MenuItem>
    )
  }

  const renderRefreshInvitationsOption = () => {
    if (vm.isNewSurvey || !vm.isSystemAdmin) return null
    return (
      <MenuItem
        disabled={vm.isTemplateAndDisabled || vm.isSaving}
        onClick={() => handleClose('invitations')}
      >
        <MailIcon className='icon' /> {s.moreMenu.refresh_invitations}
      </MenuItem>
    )
  }

  const renderRefreshOption = () => {
    if (vm.isNewSurvey) return null
    return (
      <MenuItem disabled={vm.isTemplateAndDisabled} onClick={() => handleClose('refresh')}>
        <RefreshIcon className='icon' /> {s.moreMenu.refresh}
      </MenuItem>
    )
  }

  const renderAddTestQuestions = () => {
    if (!vm.addTestDataButtonShown) return
    return (
      <MenuItem disabled={vm.isTemplateAndDisabled} onClick={() => handleClose('test-data')}>
        <StorageIcon className='icon' /> {s.moreMenu.add_test_questions}
      </MenuItem>
    )
  }

  const renderCopyText = () => {
    if (vm.isTemplate) {
      return s.moreMenu.copy_template
    }
    return `${s.moreMenu.copy} ${labelsStore.getLabelByLanguageAndFor('pulse')}`
  }

  const renderCopyButton = () => {
    if (!vm.showCopySurveyButton) return null
    return (
      <MenuItem onClick={() => handleClose('copy-survey')}>
        <FileCopyIcon className='icon' /> {renderCopyText()}
      </MenuItem>
    )
  }

  const renderSaveAsTemplateButton = () => {
    if (!vm.canSaveAsTemplate) return null
    if (vm.isNewSurvey) return null
    if (vm.isSurveyTemplateEdit) return null
    return (
      <MenuItem onClick={() => handleClose('save-survey-template')}>
        <PlusIcon className='icon' /> {'Create Template'}
      </MenuItem>
    )
  }

  const renderMenu = () => {
    if (!vm.shouldShowTopMenu) return
    return (
      <>
        <IconButton aria-controls='simple-menu' aria-haspopup='true' onClick={handleClick}>
          <MoreHorizIcon />
        </IconButton>
        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          style={{ marginTop: 40 }}
        >
          {renderDeleteOption()}
          {renderRefreshOption()}
          {renderRunNLPOption()}
          {renderRefreshInvitationsOption()}
          {renderAddTestQuestions()}
          {renderCopyButton()}
          {renderSaveAsTemplateButton()}
        </Menu>
      </>
    )
  }

  const renderCreateSurveyButton = () => {
    if (!vm.isTemplate) return null
    if (vm.isPublished) return null
    if (vm.isNewSurvey) return null
    if (surveyTemplateId) {
      return (
        <Button
          variant='outlined'
          onClick={() => vm.createSurveyFromSystemTemplate()}
          disabled={vm.isSaving}
        >
          {s.moreMenu.create_survey_from_template}
        </Button>
      )
    } else {
      return (
        <Button
          variant='outlined'
          onClick={() => vm.createSurveyFromTemplate()}
          disabled={vm.isSaving}
        >
          {s.moreMenu.create_survey_from_template}
        </Button>
      )
    }
  }

  const renderSaveTemplateButton = () => {
    if (vm.isPublished) return null
    if (vm.isClosed) return null
    if (!vm.isTemplate) return null
    return (
      <Button
        variant='contained'
        onClick={() => vm.save()}
        disabled={vm.isSaving || vm.isTemplateAndDisabled}
      >
        {vm.saveButtonText}
      </Button>
    )
  }

  const renderPublishButton = () => {
    if (vm.isClosed) return null
    if (vm.isTemplate) return null
    return (
      <>
        {!vm.isPublished && vm.currentTabIndex !== SurveyEditTabsEnum.PUBLISH ? (
          <Button variant='outlined' onClick={() => vm.saveValidation()} disabled={vm.isSaving}>
            {vm.saveButtonText}
          </Button>
        ) : undefined}
        {!vm.isPublished && vm.currentTabIndex !== SurveyEditTabsEnum.PUBLISH ? (
          <Button
            variant='contained'
            id='continue-button'
            onClick={() => vm.goToNextTab()}
            disabled={vm.isSaving}
          >
            {vm.currentTabIndex !== SurveyEditTabsEnum.PREVIEW
              ? s.surveys.continue
              : vm.publishButtonText}
          </Button>
        ) : !vm.isPublished ? (
          <Button
            variant='contained'
            id='finish-button'
            onClick={() => vm.goToNextTab()}
            disabled={vm.isSaving}
          >
            {s.surveys.finish}
          </Button>
        ) : undefined}
        {vm.isPublished && (
          <Button
            variant='contained'
            id='update-button'
            onClick={() => vm.saveValidation()}
            disabled={vm.isSaving || !vm.isDirty}
          >
            {s.moreMenu.update}
          </Button>
        )}
      </>
    )
  }

  return (
    <div slot='end' className='end-buttons'>
      {renderMenu()}
      {renderClosedSurveyMessage()}
      {renderCloseSurveyButton()}
      {renderOpenSurveyButton()}
      {renderCreateSurveyButton()}
      {renderPublishButton()}
      {renderSaveTemplateButton()}
    </div>
  )
}

export default inject(
  'surveysStore',
  'localizationStore',
  'labelsStore'
)(observer(TopToolbarEndButtons))
