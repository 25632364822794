import { observable, action } from 'mobx'
import { ILabelDTO } from 'src/app/organizations-sa/dto/ILabelDTO'
import { IAggregate } from 'src/app/shared/data/IAggregate'
import { serializable, serialize } from 'serializr'

export class Label implements ILabelDTO, IAggregate {
  static create(orgId) {
    const label = new Label()
    label.organizationId = orgId
    return label
  }

  @serializable @observable objectId: string = ''
  @serializable @observable organizationId: string = ''
  @serializable @observable selector: string = ''
  @serializable @observable isDefault: boolean = false
  @serializable @observable language: string = ''
  @serializable @observable value: string = ''
  @serializable @observable isDeleted: boolean = false
  isOnServer: boolean = false

  markIsNotOnServer() {
    this.isOnServer = false
  }

  markIsOnServer() {
    this.isOnServer = true
  }

  public serialize() {
    return serialize(this)
  }
  
}
