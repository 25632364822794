import React, { FC } from 'react'
import { inject, observer } from 'mobx-react'
import Searchbar from '../../../shared/Searchbar'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import './UserAudienceTab.scss'
import VirtualList from 'react-tiny-virtual-list'
import { IconButton, Typography } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ParticipantsSelect from '../../../participants-select/views/ParticipantsSelect'
import { OrganizationUsersStore } from '../../store/OrganizationUsersStore'
import { AudienceRowVM } from '../../view-models/drawer/AudienceRowVM'
import AGGridUserCell from '../../../shared/ag-grid/AGGridUserCell'
import { AgGridReact } from 'ag-grid-react'
import AGGridWidgetPagingRow from '../../../shared/ag-grid/AGGridPagingRow'

interface Props {
  organizationUsersStore?: OrganizationUsersStore
}

const UserAudienceTab: FC<Props> = ({ organizationUsersStore }) => {
  const { editVM: vm } = organizationUsersStore
  if (!vm.audienceListVM) return

  const renderAddAudienceMember = () => {
    if (!vm.isEditable) return null
    return (
      <Button variant='outlined' onClick={() => vm.toggleAddAudience()}>
        Manage Additional Audience Members
      </Button>
    )
  }

  const renderToolBar = () => {
    return (
      <Grid item lg={12} sm={12} xs={12} className='audience-paper'>
        {renderAddAudienceMember()}
        <Searchbar
          focused={vm.audienceListVM.typedFilterText.length > 0}
          searchValue={vm.audienceListVM.typedFilterText}
          onSearchChange={(e) => vm.audienceListVM.setQuickFilter(e.target.value)}
        />
      </Grid>
    )
  }

  const renderParticipantSelect = () => {
    if (!vm.addAudienceTabShown) return null
    return (
      <>
        <Grid container className='actions-container' alignItems='center' justifyContent='space-between'>
          <Grid item>
            <IconButton onClick={() => vm.cancelAudienceSelect()}>
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <Button variant='contained' onClick={() => vm.addSelectedItemsToAudience()}>
              Done
            </Button>
          </Grid>
        </Grid>
        <div className='user-audience-tab-participants-select-container' style={{ height: 'calc(100% - 49.25px)' }}>
          <ParticipantsSelect participantsSelectVM={vm.audienceParticipantsSelectVM} />
        </div>
      </>
    )
  }

  const renderSaveForChangesMessage = () => {
    if (!vm.audienceMembersAdded) return null
    return <Typography className='save-message'>Press 'Save' to reflect changes</Typography>
  }

  const renderNameCell = (props) => {
    const row: AudienceRowVM = props.data
    if (!row) return null
    return <AGGridUserCell userId={row.userId} name={row.name} iconURL={row.iconURL} />
  }

  const renderGrid = () => {
    return (
      <div className='ag-theme-alpine' style={{ height: '100%', width: '100%' }}>
        <AgGridReact
          gridOptions={{
            ...vm.audienceListVM.getGridOptions(),
            frameworkComponents: {
              nameCell: renderNameCell,
            },
          }}
        />
      </div>
    )
  }

  const renderMyAudienceList = () => {
    if (vm.addAudienceTabShown) return null
    return (
      <Grid container>
        {renderToolBar()}
        <Grid item xs={12} lg={12} sm={12}>
          {renderSaveForChangesMessage()}
          <div id='AudienceTable'>{renderGrid()}</div>
        </Grid>
      </Grid>
    )
  }

  return (
    <div id='UserAudienceTab' style={{ height: '100%', width: '100%' }}>
      {renderMyAudienceList()}
      {renderParticipantSelect()}
    </div>
  )
}

export default inject('organizationUsersStore')(observer(UserAudienceTab))
