import { action, observable } from 'mobx'
import { serializable, serialize, deserialize } from 'serializr'
import { INotificationTemplateDTO } from 'src/app/notifications/store/model/INotificationTemplateDTO'
import { IAggregate } from '../../shared/data/IAggregate'

export class EmailTemplate implements INotificationTemplateDTO, IAggregate {
  static create(temp: any) {
    const template = new EmailTemplate()
    template.objectId = temp.objectId
    template.body = temp.body
    template.channel = temp.channel
    template.isDefault = temp.isDefault
    template.language = temp.language
    template.notificationTypeId = temp.notificationTypeId
    template.organizationId = temp.organizationId
    template.subject = temp.subject
    template.isDeleted = temp.isDeleted
    template.id = temp.id
    template.name = temp.name
    template.generation = temp.generation
    template.sgTemplateId = temp.sgTemplateId
    template.sgTemplateVersionId = temp.sgTemplateVersionId
    template.sgTemplateTestVersionId = temp.sgTemplateTestVersionId
    template.thumbnailUrl = temp.thumbnailUrl
    template.html = temp.html
    template.parentId = temp.parentId
    template.test_data = temp.test_data
    return template
  }

  @serializable @observable objectId: string = ''
  @serializable @observable body: string = ''
  @serializable @observable channel: string = ''
  @serializable @observable isDefault: boolean = false
  @serializable @observable language: string = ''
  @serializable @observable notificationTypeId: string = ''
  @serializable @observable organizationId: string = ''
  @serializable @observable subject: string = ''
  @serializable @observable isDeleted: boolean = false
  public isOnServer: boolean = false

  @serializable @observable id: string = ''
  @serializable @observable name: string = ''
  @serializable @observable generation: string = ''

  @serializable @observable sgTemplateId: string = ''
  @serializable @observable sgTemplateVersionId: string = ''
  @serializable @observable sgTemplateTestVersionId: string = ''

  @serializable @observable thumbnailUrl: string = ''
  @serializable @observable html: string = ''
  @serializable @observable parentId: string = ''

  @serializable @observable test_data: string = undefined

  markIsNotOnServer() {
    this.isOnServer = false
  }

  markIsOnServer() {
    this.isOnServer = true
  }

  @action
  public handleSubjectChange(val: string) {
    this.subject = val
  }

  public updateFromServer(fromObject: any) {
    if (!fromObject.User) fromObject.User = {}
    this.objectId = fromObject.objectId
    this.isDefault = fromObject.isDefault
    this.notificationTypeId = fromObject.notificationTypeId
    this.organizationId = fromObject.organizationId
    this.subject = fromObject.subject
    this.isDeleted = fromObject.isDeleted
    this.id = fromObject.id
    this.name = fromObject.name
    this.generation = fromObject.generation
    this.sgTemplateId = fromObject.sgTemplate
    this.sgTemplateVersionId = fromObject.sgTemplateVersionId
    this.sgTemplateTestVersionId = fromObject.sgTemplateTestVersionId
    this.thumbnailUrl = fromObject.thumbnailUrl
    this.html = fromObject.html
    this.parentId = fromObject.parentId
    this.test_data = fromObject.test_data
    this.isOnServer = true
  }

  public clone(): EmailTemplate {
    return deserialize(EmailTemplate, this.serialize())
  }

  public serialize(): INotificationTemplateDTO {
    return serialize(this)
  }

  @action
  public toDTO() {
    return {
      channel: this.channel,
      subject: this.subject,
      body: this.body,
      notificationTypeId: this.notificationTypeId,
      language: this.language,
      organizationId: this.organizationId,

      isDefault: this.isDefault,
      isDeleted: this.isDeleted,
      id: this.id,
      name: this.name,
      generation: this.generation,
      sgTemplateId: this.sgTemplateId,
      sgTemplateVersionId: this.sgTemplateVersionId,
      sgTemplateTestVersionId: this.sgTemplateTestVersionId,
      thumbnailUrl: this.thumbnailUrl,
      html: this.html,
      parentId: this.parentId,
      test_data: this.test_data,
    }
  }
}
