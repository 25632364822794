import { inject, observer } from "mobx-react"
import { AnnouncementAnalyticsVM } from "../../view-models/analytics/AnnouncementAnalyticsVM"
import { Button, Card, CardHeader, Grid, Menu, MenuItem, Tooltip, Typography } from "@material-ui/core"
import React from "react"
import './AnnouncementParticipantsHeader.scss'
import Searchbar from "../../../shared/Searchbar"
import RefreshIcon from '@material-ui/icons/Refresh'

interface Props {
    announcementAnalyticsVM: AnnouncementAnalyticsVM
    announcementId: string
}

const AnnouncementParticipantsHeader: React.FC<Props> = ({
    announcementAnalyticsVM,
    announcementId
}) => {
    const vm = announcementAnalyticsVM
    const tableVM = vm.tableVM
    if (!vm) return

    const renderSearch = () => {
        return (
          <div className='search'>
            <Searchbar
              focused={tableVM.hasFilter}
              searchValue={tableVM.typedFilterText}
              onSearchChange={(e) => {
                tableVM.setQuickFilter(e.target.value)
              }}
            />
          </div>
        )
      }

    const renderRefreshButton = () => {
    return (
        <Tooltip title={'Reload the list of Participants'} placement='bottom'>
        <Button
            onClick={(e) => {
            tableVM.hardRefresh()
            }}
            variant='outlined'
        >
            <RefreshIcon />
        </Button>
        </Tooltip>
    )
    }

    const renderDetails = () => {
      if (vm.isLoadingAnnouncement) return
      return (
        <div id="AnnouncementDetailsHeader">
        <Grid className='summary-info-bar'>
          <Grid item className='summary-responses' xs={3}>
            <Typography className='responses-header'>Title</Typography>
            <Typography className='responses-text'>{vm.announcement.title}</Typography>
          </Grid>
          <Grid item className='summary-responses' xs={3}>
            <Typography className='responses-header'>Created</Typography>
            <Typography className='responses-text'>{vm.createdAt}</Typography>
          </Grid>
          <Grid item className='summary-responses' xs={3}>
            <Typography className='responses-header'>Created By</Typography>
            <Typography className='responses-text'>{vm.createdByUserName}</Typography>
          </Grid>
          <Grid item className='summary-responses' xs={3}>
            <Typography className='responses-header'>Likes</Typography>
            <Tooltip placement='left-end' title={vm.likePercentage}>
              <Typography className={'responses-text'}>{vm.likes}</Typography>
            </Tooltip>
          </Grid>
          <Grid item className='summary-responses' xs={3}>
            <Typography className='responses-header'>Views</Typography>
            <Tooltip placement='left-end' title={vm.viewPercentage}>
              <Typography className={'responses-text'}>{vm.views}</Typography>
            </Tooltip>
          </Grid>
          <Grid item className='summary-responses' xs={3}>
            <Typography className='responses-header'>Participants</Typography>
            <Typography className={'responses-text'}>{vm.numberOfParticipants}</Typography>
          </Grid>
        </Grid>
        </div>
      )
    }

    const handleClose = (value: string) => {
      tableVM.setFilterAnchorEl(null)
      tableVM.setListFilter(value)
    }

    const renderFilterButton = () => {
      return (
        <Tooltip
        title={
          'Send welcome email, expire passwords, add or remove multiple users from groups, roles, or permission sets'
        }
        placement='bottom'
      >
        <>
          <Button
            disabled={tableVM.loadingFirstPage || tableVM.filterDisabled}
            onClick={(e) => tableVM.setFilterAnchorEl(e.currentTarget)}
            variant='contained'
          >
            {tableVM.filterText}
          </Button>
          <Menu
            id='simple-menu'
            anchorEl={tableVM.filterAnchorEl}
            keepMounted
            open={Boolean(tableVM.filterAnchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            style={{ marginTop: 40 }}
          >
            <MenuItem onClick={() => handleClose('all')}>{'All'}</MenuItem>
            <MenuItem onClick={() => handleClose('liked')}>{'Liked'}</MenuItem>
            <MenuItem onClick={() => handleClose('viewed')}>{'Viewed'}</MenuItem>
          </Menu>
        </>
      </Tooltip>
      )
    }

    return (
      <>
        {renderDetails()}
        <CardHeader
            id='TableHeader'
            title={'Announcement Participants'}
            action={
            <>
                <Grid container>
                  
                  {renderSearch()}
                  {renderFilterButton()}
                  {renderRefreshButton()}
                </Grid>
            </>
            }
        ></CardHeader> 
      </>

    )
}

export default inject()(observer(AnnouncementParticipantsHeader))