import Parse from 'parse'
import { observable, action, computed } from 'mobx'
import { DataStore } from '../../shared/data/DataStore'
import { RootStore } from '../../stores/RootStore'
import PulseCategory from '../aggregate/PulseCategory'
import { IPulseCategoryDTO } from '../dtos/IPulseCategoryDTO'
import { PulseCategoriesViewModelsService } from '../services/PulseCategoriesViewModelsService'
import { IPulseCategoriesFindRequest } from '../../pulse-categories/interfaces/IPulseCategoriesFindRequest'
import { PulseCategoriesService } from '../../pulse-categories/services/PulseCategoriesService'

export class PulseCategoriesStore extends DataStore<PulseCategory, IPulseCategoryDTO> {
  private request: IPulseCategoriesFindRequest
  public svc: PulseCategoriesService

  constructor(rootStore: RootStore) {
    super(rootStore, PulseCategory, 'pulseCategories', [
      'objectId',
      'name',
      'grandCategoryId',
      'parentCategoryId',
      'isDefault',
    ])
    this.request = {
      includeAll: true,
    } as IPulseCategoriesFindRequest
    this.paged = true
    this.recordsPerPage = 100
    this.pageNumber = 1
    this.svc = new PulseCategoriesService(rootStore)
    this.viewModels = new PulseCategoriesViewModelsService(rootStore, this)
  }

  @observable public viewModels: PulseCategoriesViewModelsService = undefined
  @observable public isLoaded: boolean = false

  public onListRecordsLoaded() {
    this.isLoaded = true
  }

  protected getBaseQuery() {
    const query = new Parse.Query(this.className)
    query.equalTo('grandCategoryId', this.request.grandCategoryId)
    query.equalTo('parentCategoryId', this.request.parentCategoryId)
    return query
  }

  protected getListRecordsApiCall() {
    return async () => {
      const req: IPulseCategoriesFindRequest = {
        ...this.request,
        listColumns: this.listColumns,
      }
      const svc = new PulseCategoriesService(this.rootStore)
      const result = await svc.getPulseCategories(req)
      this.totalRecords = result.count
      return result.pulseCategories
    }
  }

  @computed
  public get pulseCategories(): PulseCategory[] {
    return this.records
  }

  @action
  public getPulseCategory(objectId: string): PulseCategory {
    return this.records.find((e) => e.objectId === objectId)
  }
}
