import { observable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { IMediaDTO } from '../dtos/IMediaDTO'
import { MediaItemType } from '../../upload/types/MediaItemType'

export abstract class MediaItemVM {
  protected rootStore: RootStore
  @observable protected media: IMediaDTO

  constructor(rootStore, incomingMediaItem?: IMediaDTO) {
    this.rootStore = rootStore

    if (
      incomingMediaItem &&
      incomingMediaItem.objectId &&
      incomingMediaItem.name &&
      incomingMediaItem.path
    ) {
      this.objectId = incomingMediaItem.objectId
      this.name = incomingMediaItem.name
      this.path = incomingMediaItem.path
      this.mediaWatchPercentage = incomingMediaItem.mediaWatchPercentage
        ? incomingMediaItem.mediaWatchPercentage
        : 0
      this.media = incomingMediaItem
    }

    if (incomingMediaItem && incomingMediaItem.cmsItemId) {
      this.cmsItemId = incomingMediaItem.cmsItemId
      this.name = incomingMediaItem.name
      this.path = incomingMediaItem.path
      this.mediaWatchPercentage = incomingMediaItem.mediaWatchPercentage
        ? incomingMediaItem.mediaWatchPercentage
        : 0
      this.media = incomingMediaItem
    }
  }

  @observable public cmsItemId: number = undefined
  @observable public objectId: string = ''
  @observable public name: string = ''
  @observable public path: string = ''
  @observable public uploadName: string = ''
  @observable public uploadBase64: string = ''
  @observable public file: File = null
  @observable public uploadPending: Boolean = false
  @observable public mediaWatchPercentage: number = 0

  public abstract setWatchPercentage(value: number): void

  public abstract get type(): MediaItemType
  public abstract get url(): string
  public abstract get displayName(): string
  public abstract get uploadNameValid(): boolean
  public abstract get saveEnabled(): boolean

  public abstract get isLoaded(): boolean
  public abstract get isCMSItem(): boolean

  public abstract toDTO(): IMediaDTO
}
