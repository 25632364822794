import { RootStore } from '../../stores/RootStore'
import { observable, computed, action } from 'mobx'
import { Option } from '../aggregate/Option'
import { QuestionType } from '../types/QuestionType'
import { QuestionTakeVM } from './QuestionTakeVM'

export class OptionTakeVM {
  public option: Option
  public question: QuestionTakeVM

  constructor(option: Option, question: QuestionTakeVM) {
    this.option = option
    this.question = question
  }

  @computed
  public get id(): string {
    return this.option.id
  }

  @computed
  public get type(): QuestionType {
    return this.question.type
  }

  @computed
  public get isChecked(): boolean {
    return this.option.isChecked
  }

  @computed
  public get text(): string {
    return this.option.text
  }

  @computed
  public get weight(): number {
    return this.option.weight
  }

  @computed
  public get markedAndCorrect(): boolean {
    if (this.isCorrectAnswer && this.isChecked) return true
  }

  @computed
  public get markedAndIncorrect(): boolean {
    if (!this.isCorrectAnswer && this.isChecked) return true
  }

  @computed
  public get rank(): number {
    return this.option.rank
  }

  @computed
  public get emoji(): string {
    return this.option.emoji
  }

  @computed
  public get isCorrectAnswer(): boolean {
    return this.option.isCorrectAnswer
  }

  @action
  public toggleChecked() {
    this.option.toggleIsChecked()
    if (this.type === 'emoji' || this.type === 'yesNo' || this.type === 'radio' || this.type === 'dropdown') {
      const otherOptions = this.question.options.filter((e) => e.id !== this.id)
      otherOptions.forEach((e) => e.setChecked(false))
    }
  }

  @action
  public setText(val: string) {
    this.option.setText(val)
  }

  @action
  public setChecked(val: boolean) {
    this.option.setIsCheckedValue(val)
  }
}
