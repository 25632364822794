import { action, observable } from 'mobx'

export class PasswordSnackbarVM {

  constructor() {}

  @observable public isSnackbarOpen: boolean = false
  @observable public snackbarMessage: string = ''

  @action
  public openSnackbar(msg) {
    this.snackbarMessage = msg
    this.isSnackbarOpen = true
  }

  @action
  public closeSnackbar() {
    this.isSnackbarOpen = false
  }
}