import { action, computed, observable } from 'mobx'
import { LabelsStore } from '../../../labels/store/LabelsStore'
import { AGGridRowVM } from '../../../shared/ag-grid/AGGridRowVM'
import { RootStore } from '../../../stores/RootStore'
import { OrganizationUser } from '../../aggregate/OrganizationUser'
import { UsersTabVM } from './UsersTabVM'

export class UserRowVM extends AGGridRowVM<OrganizationUser> {
  private parentVM: UsersTabVM

  constructor(rootStore: RootStore, user: OrganizationUser, gridVM: UsersTabVM) {
    super(rootStore, user, gridVM)
    this.parentVM = gridVM
    this.labelsStore = this.rootStore.labelsStore
  }

  @observable public rowType: string = 'user'
  @observable public emailTo: string = ''
  @observable public labelsStore: LabelsStore

  @computed
  public get objectId(): string {
    return this.aggregate.objectId
  }

  @computed
  public get userId(): string {
    return this.aggregate.userId
  }

  @computed
  public get name(): string {
    if (!this.aggregate.fk_User) return ''
    return this.aggregate.fk_User.name
  }

  @computed
  public get type(): string {
    if (!this.rootStore.userStore.currentOrganization) return '(loading)'
    if (!this.rootStore.userStore.currentOrganization.subordinates) return '(loading)'
    if (this.parentVM.tabIndex === 2) return 'Additional'
    if (this.rootStore.userStore.currentOrganization.subordinates.includes(this.aggregate.userId)) {
      return 'Direct Report'
    }
    return 'Other Report'
  }

  @computed
  public get iconURL(): string {
    if (!this.aggregate.fk_User) return ''
    return this.aggregate.fk_User.iconURL
  }

  @computed
  public get groupsDelimited() {
    if (!this.aggregate.fk_groups) return ''
    return this.aggregate.fk_groups.map((e) => e.name).join(', ')
  }

  @computed
  public get rolesDelimited() {
    if (!this.aggregate.fk_roles) return ''
    return this.aggregate.fk_roles.map((e) => e.name).join(', ')
  }

  @computed
  public get surveyUrl(): string {
    if (this.rowType === 'user') return '/surveys/foraudienceuser'
    if (this.rowType === 'group') return '/surveys/foraudiencegroup'
    if (this.rowType === 'role') return '/surveys/foraudiencerole'
  }

  @computed
  public get eventUrl(): string {
    if (this.rowType === 'user') return '/calendar/v2/foraudienceuser'
    if (this.rowType === 'group') return '/calendar/v2/foraudiencegroup'
    if (this.rowType === 'role') return '/calendar/v2/foraudiencerole'
  }

  @action
  public setEmailTo(val: string) {
    this.emailTo = val
  }

  @action
  public goToSurvey() {
    let url = this.surveyUrl
    url += '/' + this.userId
    this.rootStore.appStore.router.push(url)
  }

  @action
  public goToEvent() {
    let url = this.eventUrl
    url += '/' + this.userId
    this.rootStore.appStore.router.push(url)
  }

  @computed
  public get mailToLink() {
    let foundUser = this.rootStore.audienceMembersStore.getUser(this.userId)
    if (foundUser) {
      let href = ''
      if (this.emailTo === 'to') {
        href = 'mailto:' + foundUser.email
      } else if (this.emailTo === 'bcc') {
        href = 'mailto:?bcc=' + foundUser.email
      }
      return href
    }
  }

  @computed
  public get canSurvey(): boolean {
    if (this.rootStore.appStore.canSurvey) return true
    return false
  }

  public setEmailAnchorEl(currentTarget: EventTarget & HTMLButtonElement): void {
    this.parentVM.setEmailAnchorEl(this.type, currentTarget, this)
  }
}
