import { RootStore } from '../../stores/RootStore'
import { observable, computed, action } from 'mobx'
import { GoalEditVM } from './GoalEditVM'
import { IValueFactResponse } from '../dto/IValueFactResponse'
import { AudienceMember } from 'src/app/audience-members/aggregate/AudienceMember'

export class ParticipantVM {
  private editVM: GoalEditVM
  public rootStore: RootStore
  public valueFact: any

  constructor(rootStore: RootStore, editVM: GoalEditVM, valueFact: IValueFactResponse) {
    this.editVM = editVM
    this.rootStore = rootStore
    this.valueFact = valueFact
  }

  @computed
  public get current(): number {
    if (!this.valueFact) return 0
    return this.valueFact.Current1
  }

  @computed
  public get participant(): AudienceMember {
    return this.rootStore.audienceMembersStore.getUser(this.valueFact.RippleUserId)
  }

  @computed
  public get name(): string {
    if (!this.participant) return ''
    return this.participant.name
  }
}
export default ParticipantVM
