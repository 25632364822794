import React from 'react'
import { InputBase, AppBar, Toolbar, Tooltip } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import Button from '@material-ui/core/Button'
import RefreshIcon from '@material-ui/icons/Refresh'
import DeleteIcon from '@material-ui/icons/Delete'
import DoneIcon from '@material-ui/icons/Check'
import rootStore from '../../../stores/RootStore'
import { WorksheetsStore } from '../../store/WorksheetsStore'
import './TopToolbar.scss'

interface Props {
  worksheetsStore?: WorksheetsStore
}

const TopToolbar: React.FC<Props> = ({ worksheetsStore }) => {
  const { editVM } = worksheetsStore
  if (!editVM) return null

  const renderDeleteButton = () => {
    if (editVM.isNew) return
    return (
      <Button variant='outlined' onClick={() => editVM.delete()} disabled={editVM.isSaving}>
        <DeleteIcon />
      </Button>
    )
  }

  const renderRefreshButton = () => {
    if (editVM.isNew) return
    if (!editVM.canRevertAll) return
    return (
      <Button variant='outlined' onClick={() => editVM.refresh()} disabled={editVM.isSaving}>
        <RefreshIcon />
      </Button>
    )
  }

  const renderSaveButton = () => {
    return (
      <Button disabled={editVM.isSaving} variant='outlined' onClick={() => editVM.save()}>
        Save
      </Button>
    )
  }

  const renderDoneManagingButton = () => {
    return (
      <Tooltip
        title={rootStore.localizationStore.lzStrings.analysisDashboard.done_managing_tooltip}
        placement='bottom'
      >
        <span>
          <Button
            disabled={editVM.isSaving}
            variant='contained'
            onClick={() => rootStore.appStore.router.push('/userWorksheets')}
          >
            <DoneIcon style={{ marginRight: '10px' }} /> {rootStore.localizationStore.lzStrings.analysisDashboard.done_managing}
          </Button>
        </span>
      </Tooltip>
    )
  }

  return (
    <AppBar position='static' color='default' id='MainForm' elevation={2}>
      <Toolbar className='tabs-toolbar'>
        <form noValidate autoComplete='off'>
          <InputBase
            value={editVM.name}
            onChange={(e) => editVM.setName(e.target.value)}
            placeholder='Enter Name'
            className={editVM.isNameValid ? 'viz-title-input' : 'viz-title-input error'}
            disabled={editVM.isSaving}
          />
        </form>
        <div slot='end' className='end-buttons'>
          {renderDeleteButton()}
          {renderRefreshButton()}
          {renderSaveButton()}
          {/* {renderDoneManagingButton()} */}
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default inject('worksheetsStore')(observer(TopToolbar))
