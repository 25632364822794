import React from 'react'
import { inject, observer } from 'mobx-react'
import { SurveyResultsStore } from '../stores/SurveyResultsStore'
import UserTasksDrawer from '../../user-tasks/views/drawer/Drawer'
import TaskDrawer from '../../tasks/views/drawer/Drawer'
import SurveyResultsHeader from './SurveyResultsHeader'
import SurveyResultsContent from './SurveyResultsContent'
import { CircularProgress } from '@material-ui/core'
import './SurveyResultsArea.scss'

interface Props {
  surveyResultsStore?: SurveyResultsStore
  userSurveyId?: string
  surveyId?: string
  anonymousToken?: string
}

const SurveyResultsArea: React.FC<Props> = ({
  surveyResultsStore,
  userSurveyId,
  surveyId,
  anonymousToken,
}) => {
  if (!surveyResultsStore) return null
  surveyResultsStore.loadSurveyResultsVM(surveyId, userSurveyId, anonymousToken)
  const resultsVM = surveyResultsStore.resultsVM

  const renderSpinner = () => {
    if (resultsVM) return
    return (
      <span className='loading'>
        <CircularProgress
          color='secondary'
          style={{ position: 'fixed', top: '50%', left: '50%' }}
        />
      </span>
    )
  }

  const renderScreen = () => {
    if (!resultsVM) return null

    return (
      <>
        <SurveyResultsHeader resultsVM={resultsVM} />
        <SurveyResultsContent />
        <UserTasksDrawer />
        <TaskDrawer />
      </>
    )
  }

  return (
    <div id='SurveyResultsArea'>
      {renderScreen()}
      {renderSpinner()}
    </div>
  )
}
// inject just surveyresultsStore
export default inject('surveyResultsStore')(observer(SurveyResultsArea))
