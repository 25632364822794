import { computed, action, reaction, observable } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { WidgetRowVM } from './WidgetRowVM'
import { ColDef, GridOptions, RowClickedEvent } from 'ag-grid-community'
import { AGGridVM } from '../../../shared/ag-grid/AGGridVM'
import { nameOf } from '../../../shared/nameOf'
import { OrganizationUsersWidgetDataStore } from './OrganizationUsersWidgetDataStore'
import { UserImportVM } from '../import/UserImportVM'
import { OrganizationUser } from '../../aggregate/OrganizationUser'
import { OrganizationUsersWidgetVM } from './OrganizationUsersWidgetVM'
import LocalizationStore from 'src/app/localization/LocalizationStore'

export class ArchivedTabVM extends AGGridVM {
  private rootStore: RootStore
  private dataStore: OrganizationUsersWidgetDataStore
  @observable private shownOnce: boolean = false
  @observable private showHiddenUsers: boolean = false

  constructor(rootStore: RootStore, orgUsersVM: OrganizationUsersWidgetVM, tabIndex: number) {
    super()
    this.rootStore = rootStore
    this.importVM = new UserImportVM(this.rootStore)
    this.tabIndex = tabIndex
    this.serverSideLoaded = true
    this.orgUsersVM = orgUsersVM
    this.lzStore = rootStore.localizationStore
    this.loadWidget()
    reaction(
      () =>
        this.rootStore.organizationUsersStore.editVM &&
        this.rootStore.organizationUsersStore.editVM.userJustDeleted !== '',
      () => {
        if (this.gridApi && this.rootStore.organizationUsersStore.editVM.userJustDeleted !== '') {
          this.reloadWidget()
        }
      }
    )
    reaction(
      () =>
        this.rootStore.organizationUsersStore.editVM &&
        this.rootStore.organizationUsersStore.editVM.userJustDisabled !== '',
      () => {
        if (this.gridApi && this.rootStore.organizationUsersStore.editVM.userJustDisabled !== '') {
          this.reloadWidget()
        }
      }
    )
    reaction(
      () =>
        this.rootStore.organizationUsersStore.editVM &&
        this.rootStore.organizationUsersStore.editVM.userJustArchived !== '',
      () => {
        if (this.gridApi && this.rootStore.organizationUsersStore.editVM.userJustArchived !== '') {
          this.reloadWidget()
        }
      }
    )
    reaction(
      () => this.showHiddenUsers,
      () => {
        this.reloadWidget()
      }
    )
    reaction(
      () =>
        this.rootStore.organizationUsersStore.editVM &&
        this.rootStore.organizationUsersStore.editVM.userJustWasHidden !== '',
      () => {
        if (this.gridApi && this.rootStore.organizationUsersStore.editVM.userJustWasHidden !== '') {
          this.reloadWidget()
        }
      }
    )
  }

  public importVM: UserImportVM
  public orgUsersVM: OrganizationUsersWidgetVM
  public tabIndex: number
  public lzStore: LocalizationStore

  private reloadWidget() {
    if (this.rootStore.audienceMembersStore)
      this.rootStore.audienceMembersStore.loadAudienceMembers()
    setTimeout(() => {
      this.loadWidget()
      if (this.gridApi) this.onGridReadied()
    }, 2000)
  }

  @action
  public setShouldShowHiddenUsers(shouldShowHiddenUsers: boolean) {
    this.showHiddenUsers = shouldShowHiddenUsers
  }

  private loadWidget() {
    this.dataStore = new OrganizationUsersWidgetDataStore(
      this.rootStore,
      true,
      this.showHiddenUsers
    )
    this.dataStore.onRecordUpdated = (e) => this.onRecordUpdated(e)
  }

  public getRowNodeId(row: WidgetRowVM): string {
    return row.objectId
  }

  @action
  private setShownOnce() {
    this.shownOnce = true
  }

  @computed
  public get shown(): boolean {
    return this.orgUsersVM.tabIndex === this.tabIndex
  }

  @computed
  public get shouldRenderGrid(): boolean {
    if (this.shownOnce) return true
    if (this.shown) return true
    return false
  }

  @computed
  public get hasRows(): boolean {
    return this.dataStore.hasListRecords
  }

  @computed
  public get noRowsToShowLabel() {
    return this.rootStore.localizationStore.lzStrings.dashboard.no_rows_to_show
  }

  public onRecordUpdated(obj: OrganizationUser) {
    const rowNode = this.gridApi.getRowNode(obj.objectId)
    if (!rowNode) return
    rowNode.setData(new WidgetRowVM(this.rootStore, obj, this.orgUsersVM))
  }

  @computed
  public get shouldRender(): boolean {
    if (!this.dataStore) return false
    return true
  }

  @action
  public openNewUser() {
    this.rootStore.organizationUsersStore.loadEditVMForNewUser()
  }

  @action
  public openImportModal() {
    this.rootStore.appStore.router.push('dashboard/tenantAdmin/userImport')
  }

  @computed
  public get columnDefs(): ColDef[] {
    const s = this.rootStore.localizationStore.lzStrings
    return [
      {
        headerName: s.orgUsersTableWidget.name,
        field: nameOf<WidgetRowVM, string>((e) => e.sortName),
        sort: 'asc',
        cellRenderer: 'nameCell',
      },
      {
        headerName: s.orgUsersTableWidget.email,
        field: nameOf<WidgetRowVM, string>((e) => e.email),
      },
      {
        headerName: s.orgUsersTableWidget.title,
        field: nameOf<WidgetRowVM, string>((e) => e.title),
      },
      {
        headerName: s.orgUsersTableWidget.roles,
        field: nameOf<WidgetRowVM, string>((e) => e.roleNames),
        sortable: false,
      },
      {
        headerName: s.orgUsersTableWidget.groups,
        field: nameOf<WidgetRowVM, string>((e) => e.groupNames),
        sortable: false,
      },
    ]
  }

  public getGridOptions(): GridOptions {
    return {
      ...this.baseGridOptions,
      onRowClicked: (e) => this.rowClicked(e),
      getRowNodeId: (e) => this.getRowNodeId(e),
      columnDefs: this.ensureTooltipFields(this.columnDefs),
    }
  }

  @action
  public setQuickFilter(val: string) {
    this.typedFilterText = val
    if (this.quickFilterTO) clearTimeout(this.quickFilterTO)
    this.quickFilterTO = setTimeout(() => this.applyFilter(), 1000)
  }

  private applyFilter() {
    this.dataStore.setFilter(this.typedFilterText)
    this.reload()
    this.columnApi.applyColumnState({
      state: [{ colId: 'sortName', sort: 'asc' }],
    })
  }

  protected onGridReadied() {
    this.gridApi.setDatasource({
      rowCount: this.totalRecords,
      getRows: async (params) => {
        this.setShownOnce()
        params.sortModel.forEach((col: { colId: string; sort: 'asc' | 'desc' }, idx: number) => {
          let dbCol = col.colId
          if (dbCol === 'sortName') dbCol = 'fk_User.lastName'
          if (dbCol === 'email') dbCol = 'fk_User.email'
          if (idx === 0) this.dataStore.setSort(dbCol, col.sort)
          if (idx > 0) this.dataStore.addSort(dbCol, col.sort)
        })
        if (params.sortModel.length === 0) this.dataStore.setSort('fk_User.lastName')
        if (params.startRow === 0) this.dataStore.setPage(0)
        await this.dataStore.getNextPage()
        const rows = this.dataStore.organizationUsers.map(
          (e) => new WidgetRowVM(this.rootStore, e, this.orgUsersVM)
        )
        params.successCallback(rows, this.dataStore.totalRecords)
      },
    })
  }

  public get rows(): WidgetRowVM[] {
    return []
  }

  public rowClicked(e: RowClickedEvent) {
    const row: WidgetRowVM = e.data
    if (!row) return
    row.click()
  }

  @computed
  protected get totalRecords() {
    if (!this.dataStore) return 0
    return this.dataStore.totalRecords
  }
}
