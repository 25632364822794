import { observable } from 'mobx'
import { IAggregate } from 'src/app/shared/data/IAggregate'
import { INotificationTemplateDTO } from '../model/INotificationTemplateDTO'
import { serializable, serialize, deserialize } from 'serializr'

export class NotificationTemplate implements INotificationTemplateDTO, IAggregate {
  @serializable @observable objectId: string = ''
  @serializable @observable organizationId: string = ''
  @serializable @observable channel: string = ''
  @serializable @observable subject: string = ''
  @serializable @observable body: string = ''
  @serializable @observable notificationTypeId: string = ''
  @serializable @observable language: string = ''
  @serializable @observable isDefault: boolean = false
  @serializable isDeleted: boolean = false
  isOnServer: boolean = false

  markIsNotOnServer() {
    this.isOnServer = false
  }

  markIsOnServer() {
    this.isOnServer = true
  }

  public serialize() {
    return serialize(this)
  }

  public clone(): NotificationTemplate {
    return deserialize(NotificationTemplate, this.serialize())
  }
}
