import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputLabel from '@material-ui/core/InputLabel'
import { SurveyTagsStore } from '../../store/SurveyTagsStore'

interface SurveyTagsEditProps {
  localizationStore?: LocalizationStore
  surveyTagsStore?: SurveyTagsStore
}

const SurveyTagsEdit: React.FC<SurveyTagsEditProps> = ({ localizationStore, surveyTagsStore }) => {
  const strings = localizationStore.lzStrings.surveyTags
  const widgetForm = surveyTagsStore.widgetForm

  const handleChange = (event) => {
    widgetForm.setField(event.target.id, event.target.value)
  }

  return (
    <section>
      <h6>{localizationStore.lzStrings.organizationWidgetDrawer.basic_info}</h6>
      <InputLabel htmlFor='name'>{strings.name}</InputLabel>
      <OutlinedInput
        id='name'
        fullWidth
        labelWidth={0}
        autoComplete='off'
        value={widgetForm.name}
        onChange={handleChange}
        error={widgetForm.nameError}
      />
      <InputLabel htmlFor='description'>{strings.description}</InputLabel>
      <OutlinedInput
        id='description'
        fullWidth
        labelWidth={0}
        autoComplete='off'
        value={widgetForm.description}
        onChange={handleChange}
        multiline
        minRows={4}
        maxRows={4}
      />
    </section>
  )
}

export default inject('localizationStore', 'surveyTagsStore')(observer(SurveyTagsEdit))
