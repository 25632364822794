import { RootStore } from '../../stores/RootStore'
import { observable, action, computed } from 'mobx'
import { CMSItemsStore } from '../store/CMSItemsStore'
import { CMSItemVM } from './CMSItemVM'
import { ICMSItemsListRequest } from '../interfaces/ICMSItemsListRequest'
import { ISortParams } from '../interfaces/ISortParams'
import { IPaginationParams } from '../interfaces/IPaginationParams'
import { ISearchParams } from '../interfaces/ISearchParams'
import { Attachment } from '../../attachments/aggregate/Attachment'
import { CMSItemsLayoutTableVM } from './CMSItemsLayoutTableVM'
import { IFilterParams } from '../interfaces/IFilterParams'
import { IItemType } from '../interfaces/IItemType'
import { ITagParams } from '../interfaces/ITagParams'
import { IAccessParams } from '../interfaces/IAccessParams'
import { FileUploadModalVM } from '../../upload/view-models/FileUploadModalVM'
import { CMSItemsPickerVM } from './CMSItemsPickerVM'
import { CMSItemsTypeEnum } from '../enum/CMSItemsTypeEnum'
import { FileTypeEnum } from '../../upload/enum/FileTypeEnum'
import { ICMSItemSelected } from '../interfaces/ICMSItemSelected'

export class CMSItemsManageVM {
  private rootStore: RootStore
  private dataStore: CMSItemsStore

  private defaultMediaHeight: number = 160
  private defaultCardWidth: number = 340
  private minItems: number = Math.floor(window.innerWidth / this.defaultCardWidth)

  constructor(
    rootStore: RootStore,
    dataStore: CMSItemsStore,
    parentVM: CMSItemsPickerVM,
    allowMultiple: boolean = true,
    addAfterSave: Function = null,
    toggleShowModal: Function = null,
    contentType: CMSItemsTypeEnum
  ) {
    this.rootStore = rootStore
    this.dataStore = dataStore
    this.parentVM = parentVM
    this.allowMultiple = allowMultiple
    this.addAfterSave = addAfterSave
    this.toggleShowModal = toggleShowModal
    this.contentType = contentType

    window.addEventListener('resize', () => {
      const cols = Math.floor(window.innerWidth / this.defaultCardWidth)
      this.minItems = cols
    })

    const fileTypeKey =
      Object.keys(CMSItemsTypeEnum)[Object.values(CMSItemsTypeEnum).indexOf(contentType)]

    this.tableVM = new CMSItemsLayoutTableVM(this.rootStore, this.dataStore, this)
    this.fileUploadVM = new FileUploadModalVM(
      rootStore,
      allowMultiple,
      addAfterSave,
      toggleShowModal,
      FileTypeEnum[fileTypeKey]
    )

    this.setFilterTypes(this.formats)
  }

  @observable public allowMultiple: boolean = true
  @observable public addAfterSave: Function = null
  @observable public toggleShowModal: Function = null
  @observable public contentType: CMSItemsTypeEnum = CMSItemsTypeEnum.DEFAULT

  @observable public parentVM: CMSItemsPickerVM = undefined
  @observable public tableVM: CMSItemsLayoutTableVM = undefined
  @observable public fileUploadVM: FileUploadModalVM = undefined
  @observable public isOpen: boolean = false

  @observable public isSaving: boolean = false
  @observable public isLoading: boolean = false
  @observable public isRefVisible: boolean = false

  @observable public selectedItemVM: CMSItemVM = undefined
  @observable public typedFilterText: string = ''
  @observable public sortType: string = 'createdAt-desc'
  @observable public filterTypes: IItemType[] = undefined
  @observable public filterTags: string[] = undefined

  @observable public page: number = 1
  @observable public pageSize: number = 50
  @observable public start: number = 0
  @observable public initial: Attachment[] = []
  @observable public layoutIndex: number = 0
  @observable public accessIndex: number = 0

  @observable public showTable: boolean = true

  @observable public visibleRowStartIndex = 0
  @observable public visibleColumnStartIndex = 0
  @observable public rowIndex: number = 0
  @observable public columnIndex: number = 0

  @observable public CARD_SIZE = 300

  @observable public dontShowSelectedIndirectly: boolean = false
  @observable public items: Array<CMSItemVM> = []
  @observable public selectedItemIndex: { [key: string]: ICMSItemSelected } = {}

  @computed
  public get canManage() {
    if (this.rootStore.appStore.isOrgAdmin) return true
    if (!this.rootStore.userStore.currentOrganization) return false
    return true
  }

  @computed
  public get canManageTags() {
    if (this.rootStore.appStore.isSystemAdmin) return true
    if (!this.rootStore.userStore.currentOrganization) return false
    return false
  }

  @computed
  public get hasNextPage(): boolean {
    return this.dataStore.hasNextPage
  }

  @computed
  public get isNextPageLoading(): boolean {
    return this.dataStore.isNextPageLoading
  }

  @computed
  public get pagination() {
    return {
      start: this.start,
      limit: this.pageSize,
    } as IPaginationParams
  }

  @computed
  public get sort() {
    const attrs = this.sortType.split('-')
    return {
      field: attrs[0],
      dir: attrs[1],
    } as ISortParams
  }

  @computed
  public get search() {
    if (!this.typedFilterText) return
    return {
      contains: this.typedFilterText,
    } as ISearchParams
  }

  @computed
  public get filter() {
    if (!this.filterTypes) return
    return {
      types: this.filterTypes.slice(),
    } as IFilterParams
  }

  @computed
  public get tags() {
    if (!this.filterTags) return
    return {
      values: this.filterTags.slice(),
    } as ITagParams
  }

  @computed
  public get access() {
    if (!this.isPrivateAccess) return
    return {
      showPrivate: this.isPrivateAccess,
      userId: this.rootStore.appStore.currentUserId,
    } as IAccessParams
  }

  @computed
  public get formats() {
    return this.contentType.split(',')
  }

  @computed
  public get tagOptions(): string[] {
    if (!this.dataStore.isLoaded) return []
    if (!this.dataStore.tags) return []
    return this.dataStore.tags.map((e) => e.Tag_Name)
  }

  @computed
  public get shouldResetPaging() {
    if (!this.isLayoutGrid) return true
    if (!this.typedFilterText) return true
    if (!this.filterTypes) return true
    if (!this.filterTags) return true
    return false
  }

  @computed
  public get fileTypes(): FileTypeEnum {
    const fileTypeKey =
      Object.keys(CMSItemsTypeEnum)[Object.values(CMSItemsTypeEnum).indexOf(this.contentType)]
    return FileTypeEnum[fileTypeKey]
  }

  @action
  public async refreshData() {
    this.start = 0
    this.doLoad()
  }

  @computed
  public get request() {
    const req = {
      userId: this.rootStore.appStore.currentUserId,
      organizationId: this.rootStore.appStore.currentOrgId,
      sort: this.sort,
      pagination: this.pagination,
      search: this.search,
      filter: this.filter,
      tags: this.tags,
      access: this.access,
    } as ICMSItemsListRequest
    return req
  }

  @action
  public async reloadPage() {
    try {
      await this.dataStore.loadListRecords(this.request)
    } catch (error) {
      console.log(error)
    }
  }

  @action
  public async loadNextPage() {
    this.start = this.start + this.pageSize
    this.pageSize = this.pageSize

    try {
      await this.dataStore.loadNextPage(this.request)
    } catch (error) {
      console.log(error)
    }
  }

  @action
  private async doLoad() {
    await this.dataStore.loadListRecords(this.request)
    this.tableVM.refresh()
  }

  @action
  public async doPagination() {
    if (this.shouldResetPaging) {
      this.start = 0
    }
    await this.doLoad()
  }

  @action
  public async doSort() {
    if (this.shouldResetPaging) {
      this.start = 0
    }
    await this.doLoad()
  }

  @action
  public async doSearch() {
    if (this.shouldResetPaging) {
      this.start = 0
    }
    await this.doLoad()
  }

  @action
  public async doFilter() {
    if (this.shouldResetPaging) {
      this.start = 0
    }
    await this.doLoad()
  }

  @action
  public async doAccess() {
    if (this.shouldResetPaging) {
      this.start = 0
    }
    await this.doLoad()
  }

  @action
  public setSortType(type) {
    this.sortType = type
    this.doSort()
  }

  @action
  public setSearchFilter(val: string) {
    this.typedFilterText = val
    this.doSearch()
  }

  @action
  public setFilterTypes(vals) {
    const isArray = Array.isArray(vals)
    if (isArray) {
      if (!vals.length) this.filterTypes = undefined
      else this.filterTypes = vals
    } else {
      if (!vals) this.filterTypes = undefined
      else this.filterTypes = [vals]
    }
    this.doFilter()
  }

  @action
  public setTagValues(vals) {
    const isArray = Array.isArray(vals)
    if (isArray) {
      this.filterTags = vals
      if (!vals.length) this.filterTags = undefined
      else this.filterTags = vals
    } else {
      if (!vals) this.filterTags = undefined
      else this.filterTags = [vals]
    }
    this.doFilter()
  }

  @action
  public toggleUploadMediaDialog() {
    this.parentVM.uploadVM.toggleShowUploadDialog()
  }

  @action
  public openUploadDialog() {
    this.parentVM.uploadVM.setPrivate(this.isPrivateAccess)
    this.parentVM.uploadVM.openUploadDialog()
  }

  @action
  public closeUploadDialog() {
    this.parentVM.uploadVM.closeUploadDialog()
  }

  @action
  public setOpen(isOpen: boolean) {
    this.isOpen = isOpen
  }

  @action
  public toggleShowContentSelectModal() {
    this.isOpen = !this.isOpen
  }

  @action
  public show() {
    this.isOpen = true
  }

  @action
  public hide() {
    this.isOpen = false
  }

  @action
  public setSelectedItemVM(item: CMSItemVM) {
    this.selectedItemVM = item
  }

  @action
  public setInitialSelect(initial: Attachment[]) {
    if (!initial) return (this.initial = [])
    this.initial = initial
  }

  @computed
  public get isLayoutGrid() {
    if (this.layoutIndex === 0) return true
    else return false
  }

  @computed
  public get isPrivateAccess() {
    if (this.accessIndex === 1) return true
    else return false
  }

  @action
  public setLayoutIndex(val: number) {
    this.layoutIndex = val
  }

  @action
  public setAccessIndex(val: number) {
    this.accessIndex = val
    this.doAccess()
  }

  @action
  public verifyNewSelection() {
    if (!this.allowMultiple && this.hasSelections) this.clearSelections()
  }

  @action
  public clearSelections() {
    const ids = this.items.map((item) => item.itemId)
    ids.forEach((id) => this.deleteItem(id))

    this.mediaItems.forEach((item) => {
      item.setChecked(false)
    })

    this.tableVM.resetNodesSelected()
  }

  @computed
  public get hasSelections() {
    return this.items.length > 0
  }

  @computed
  public get selected() {
    return this.items
  }

  @computed
  public get selectedCount() {
    return this.selected.length
  }

  @computed
  public get shouldRender(): boolean {
    if (!this.dataStore.isLoaded) return false
    return true
  }

  @computed
  public get isLoaded(): boolean {
    return this.dataStore.isLoaded
  }

  @action
  public isItemSelected(id: string) {
    if (this.dontShowSelectedIndirectly) {
      if (this.selectedItemIndex[id] && this.selectedItemIndex[id].isSelectedDirectly) return true
    } else {
      if (this.selectedItemIndex[id]) return true
    }

    return false
  }

  @computed
  public get mediaItems(): CMSItemVM[] {
    if (!this.dataStore.rows) return []
    return this.dataStore.rows.map(
      (item) => new CMSItemVM(this.parentVM, item, this.isItemSelected(item.id.toString()), true)
    )
  }

  @computed
  public get mediaHeight(): number {
    return this.defaultMediaHeight
  }

  @action
  public toggleItem(item: CMSItemVM) {
    const idx = this.items.findIndex((e) => e.itemId === item.itemId)
    if (idx > -1) {
      this.deleteItem(item.itemId)
    } else this.addItem(item)
  }

  @action
  public addItem(frm: CMSItemVM) {
    this.verifyNewSelection()
    this.items.push(frm)
    this.updateSelectedIndex(frm)
  }

  @action
  public deleteItem(id: number) {
    const idx = this.items.findIndex((e) => e.itemId === id)
    const deletedParticipantVM = this.items[idx]
    this.deleteFromSelectedIndex(deletedParticipantVM)
    this.items.splice(idx, 1)
  }

  @action
  private deleteFromSelectedIndex(p: CMSItemVM) {
    if (this.selectedItemIndex[p.itemIdStr]) delete this.selectedItemIndex[p.itemIdStr]
  }

  @action
  private updateSelectedIndex(p: CMSItemVM) {
    if (this.selectedItemIndex[p.itemIdStr])
      this.selectedItemIndex[p.itemIdStr].isSelectedDirectly = true
    else
      this.selectedItemIndex[p.itemIdStr] = {
        id: p.itemIdStr,
        isSelectedDirectly: true,
        isSelectedIndirectly: false,
      }
  }
}
