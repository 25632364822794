import React from 'react'
import { inject, observer } from 'mobx-react'
import { MenuItem, Select, Typography } from '@material-ui/core'
import { PulseQuestionsStore } from '../../store/PulseQuestionsStore'
import './PulseQuestionEditCategories.scss'
import PulseCategory from '../../../pulse-categories/aggregate/PulseCategory'

interface Props {
  pulseQuestionsStore?: PulseQuestionsStore
}

const PulseQuestionEditCategories: React.FC<Props> = ({ pulseQuestionsStore }) => {
  const vm = pulseQuestionsStore.editVM
  if (!vm) return null
  if (vm.isLoading) return null

  const renderGrandParentCategory = () => {
    const selectVM = vm.grandCategorySelectVM
    return (
      <div className='category'>
        <Typography className='category-header'>KMO</Typography>
        <Select
          className={'category-select'}
          value={selectVM.selectedCategoryId}
          onChange={(e) => {
            vm.setGrandCategoryId(e.target.value)
          }}
          variant='outlined'
        >
          {selectVM.categories.map((category: PulseCategory) => (
            <MenuItem key={category.objectId} value={category.objectId}>
              {category.name}
            </MenuItem>
          ))}
        </Select>
      </div>
    )
  }

  const renderParentCategory = () => {
    const selectVM = vm.parentCategorySelectVM
    return (
      <div className='category'>
        <Typography className='category-header'>Category</Typography>
        <Select
          error={!vm.parentCategoryValid}
          className={'category-select'}
          value={selectVM.selectedCategoryId}
          onChange={(e) => {
            vm.setParentCategoryId(e.target.value)
          }}
          variant='outlined'
        >
          {selectVM.categories.map((category: PulseCategory) => (
            <MenuItem key={category.objectId} value={category.objectId}>
              {category.name}
            </MenuItem>
          ))}
        </Select>
      </div>
    )
  }

  const renderChildCategory = () => {
    const selectVM = vm.childCategorySelectVM
    return (
      <div className='category'>
        <Typography className='category-header'>Subcategory</Typography>
        <Select
          error={!vm.childCategoryValid}
          className={'category-select'}
          value={selectVM.selectedCategoryId}
          onChange={(e) => {
            vm.setChildCategoryId(e.target.value)
          }}
          variant='outlined'
        >
          {selectVM.categories.map((category: PulseCategory) => (
            <MenuItem key={category.objectId} value={category.objectId}>
              {category.name}
            </MenuItem>
          ))}
        </Select>
      </div>
    )
  }


  return (
    <div id='PulseQuestionEditCategories'>
      <div className='categories-row'>
        {renderGrandParentCategory()}
        {renderParentCategory()}
        {renderChildCategory()}
      </div>
    </div>
  )
}

export default inject('pulseQuestionsStore')(observer(PulseQuestionEditCategories))
