import Parse from 'parse'
import { DataStore } from '../../shared/data/DataStore'
import { RootStore } from '../../stores/RootStore'
import { IUserDashboardDTO } from './dtos/IUserDashboardDTO'
import UserDashboard from './aggregate/UserDashboard'
import { computed, observable } from 'mobx'
import { UserDashboardsViewModelService } from './view-models/UserDashboardsViewModelService'

export class UserDashboardsStore extends DataStore<UserDashboard, IUserDashboardDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, UserDashboard, 'userDashboards', [
      'objectId',
      'organizationId',
      'type',
      'name',
      'dashboardId',
      'ownerUserId',
      'userId',
      'lgLayout',
      'isFavorite',
      'lastVisitedDateTime'
    ])
    this.viewModels = new UserDashboardsViewModelService(rootStore, this)
  }

  @observable public loaded: boolean = false
  @observable public viewModels: UserDashboardsViewModelService = undefined

  protected getBaseQuery() {
    const query = new Parse.Query(this.className)
    query.equalTo('organizationId', this.rootStore.appStore.currentOrgId)
    query.equalTo('userId', this.rootStore.appStore.currentUserId)
    query.notEqualTo('isDeleted', true)
    return query
  }

  public onListRecordsLoaded() {
    this.loaded = true
  }

  @computed
  public get currentOrgUserDashboards(): UserDashboard[] {
    return this.records
  }

  public getUserDashboard(objectId): UserDashboard {
    return this.getRecord(objectId)
  }

}
