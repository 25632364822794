import React from 'react'
import { inject, observer } from 'mobx-react'
import {
  Button,
  Grid,
} from '@material-ui/core'
import './PulseCampaignQuestionEdit.scss'
import LocalizationStore from '../../../../localization/LocalizationStore'
import PulseQuestionMain from '../../../../pulse-questions/views/edit/PulseQuestionMain'
import { PulseQuestionsStore } from '../../../../pulse-questions/store/PulseQuestionsStore'
import LeftIcon from '@material-ui/icons/ChevronLeft'
import PulseQuestionMediaPicker from '../../../../pulse-questions/views/media-picker/PulseQuestionMediaPicker'

interface Props {
  pulseQuestionsStore?: PulseQuestionsStore
  localizationStore?: LocalizationStore
}

const PulseCampaignQuestionEdit: React.FC<Props> = ({ pulseQuestionsStore, localizationStore }) => {
  const vm = pulseQuestionsStore.editVM
  if (!vm) return null

  const { lzStrings: lz } = localizationStore

  const renderEditHeader = () => {
    return (
      <div className={'question-edit-header'}>
        <div>
          <Button className={'back-to-survey-btn'} variant='text' onClick={() => vm.closeEditModal()}>
            <LeftIcon />
            Back to Survey
          </Button>
        </div>
        <div className={'header-btns'}>
          <Button variant='text' onClick={() => vm.reset()}>
            Reset
          </Button>
          <Button variant='contained' onClick={() => vm.apply()}>
            Apply
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div id={'PulseCampaignQuestionEdit'}>
      <Grid item xs={12}>
        {renderEditHeader()}
        <PulseQuestionMain />
      </Grid>
    </div>
  )
}

export default inject('pulseQuestionsStore', 'localizationStore')(observer(PulseCampaignQuestionEdit))
