import { observable, action, computed, reaction, autorun } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { ParseService } from '../../services/ParseService'
import { Client } from '../aggregate/Client'

export class ClientEditVM {
  constructor(rootStore: RootStore, client?: Client) {
    if (rootStore) this.rootStore = rootStore
    if (client) this.loadData(client)

    reaction(
      () => this.rootStore.contactsStore.contacts.length,
      () => setTimeout(() => this.setContactsForClient(), 1000)
    )
  }

  loadData(client) {
    this.client = client
    ;(this.objectId = client.objectId),
      (this.name = client.name),
      (this.description = client.description)
    this.organizationId = client.organizationId
    this.client = client
    this.contacts = this.rootStore.contactsStore.getContactsByClient(this.objectId)
  }

  private rootStore: RootStore
  public client: Client = null
  @observable public contacts: Array<any> = []
  @observable public contactsToRemove: Array<any> = []
  @observable public objectId: string = ''
  @observable public name: string = ''
  @observable public description: string = ''
  @observable public organizationId: string = ''
  @observable public deleteSuccessful: boolean = false
  @observable public saveSuccessful: boolean = false
  @observable public saveTried: boolean = false

  @computed
  public get nameError() {
    if (!this.name && this.saveTried) return true
    return false
  }

  public setContactsForClient() {
    this.contacts = this.rootStore.contactsStore.getContactsByClient(this.objectId)
  }

  @computed
  public get saveEnabled(): boolean {
    if (!this.nameError) return true
  }

  @computed
  public get deleteEnabled(): boolean {
    return this.objectId.length > 0
  }

  @computed get isEditMode() {
    return Boolean(this.objectId)
  }

  @action
  public removeContact(index) {
    this.contactsToRemove.push(this.contacts[index])
    this.contacts.splice(index, 1)
  }

  @action
  public setName(val) {
    this.name = val
  }

  @action
  public setDescription(val) {
    this.description = val
  }

  @action
  public async saveClient() {
    this.saveTried = true
    if (this.nameError) return

    const client = {
      organizationId: this.rootStore.appStore.currentOrgId,
      objectId: this.objectId.trim(),
      name: this.name.trim(),
      description: this.description.trim(),
    }
    const svc = new ParseService()
    const result = await svc.saveClient(this.rootStore.appStore.currentOrgId, client)
    if (result) {
      this.contactsToRemove.forEach((contact) => {
        this.rootStore.contactsStore.removeClientFromContact(contact.objectId)
      })

      this.saveSuccessful = true
      setTimeout(() => {
        this.rootStore.clientsStore.toggleWidgetDrawer()
      }, 1000)
    }
  }

  @action
  public async deleteClient() {
    const svc = new ParseService()
    const result = await svc.deleteClient(this.rootStore.appStore.currentOrgId, this.objectId)
    if (result) {
      let contacts = this.rootStore.contactsStore.getContactsByClient(this.objectId)
      contacts.forEach((contact) => {
        contact.clientId = null
      })
      this.deleteSuccessful = true
      setTimeout(() => {
        this.rootStore.clientsStore.toggleWidgetDrawer()
      }, 1000)
    }
  }
}
