import { RootStore } from '../../stores/RootStore'
import { observable, computed, action } from 'mobx'
import { Quiz } from '../aggregate/Quiz'
import { Question } from '../aggregate/Question'
import { OptionVM } from './OptionVM'
import { IconVM } from './IconVM'
import { RatingDisplayType } from '../types/RatingDisplayType'

export class QuestionVM {
  private rootStore: RootStore
  public question: Question
  public index: number

  constructor(rootStore: RootStore, question: Question, index: number) {
    this.rootStore = rootStore
    this.question = question
    this.index = index
  }

  @observable public titleError: boolean = false
  @observable public optionsError: boolean = false

  @computed
  public get id(): string {
    return this.question.id
  }

  @computed
  public get options(): OptionVM[] {
    return this.question.options.map((option) => new OptionVM(this.rootStore, this, option))
  }

  @computed
  public get title(): string {
    return this.question.title
  }

  @computed
  public get type(): string {
    return this.question.type
  }

  @action
  public setTitleError(boolean) {
    this.titleError = boolean
  }

  @action
  public setOptionsError(boolean) {
    this.optionsError = boolean
  }

  @action
  public setOptionIsCorrect(idx: number) {
    this.options[idx].setIsCorrect()
    if (this.options.length > 0) this.setOptionsError(false)
  }

  @action
  public setOptionIsIncorrect(idx: number) {
    this.options[idx].setIsIncorrect()
  }

  @computed
  public get ratingDisplay(): RatingDisplayType {
    return this.question.ratingDisplay
  }

  @computed
  public get canHaveOptions() {
    if (this.type === 'text' || this.type === 'infoText') return false
    return true
  }

  @action
  public setRatingDisplay(val) {
    this.question.setRatingDisplay(val)
  }

  @action
  public setTitle(val: string) {
    this.question.setTitle(val)
    this.setTitleError(false)
  }

  @action
  public changeQuestionType(val) {
    this.question.changeQuestionType(val)
  }

  @action
  public moveOption(oldIndex, newIndex) {
    this.question.moveOption(oldIndex, newIndex)
  }

  @action
  public moveOptionUp(idx: number) {
    this.question.moveOptionUp(idx)
    this.setOptionRanks()
  }

  @action
  public moveOptionDown(idx: number) {
    this.question.moveOptionDown(idx)
    this.setOptionRanks()
  }

  @action
  public deleteOption(idx: number) {
    this.question.deleteOption(idx)
    this.setOptionRanks()
  }

  @action
  public addOption() {
    this.question.addOption()
    this.setOptionRanks()
  }

  @action
  public setOptionRanks() {
    this.options.forEach((opt, idx) => {
      opt.setRank(idx + 1)
    })
  }

  @computed
  public get canAddMoreOptions() {
    switch (this.type) {
      case 'emoji':
        return true
      case 'radio':
        return true
      case 'check':
        return true
      case 'yesNo':
        return false
      case 'rating':
        return true
      case 'starRating':
        return true
      case 'text':
        return false
      case 'dropdown':
        return true
      default:
        return false
    }
  }

  @computed
  public get icon(): IconVM {
    switch (this.type) {
      case 'emoji':
        return new IconVM('far fa-smile', 'brown')
      case 'rating':
        return new IconVM('fas fa-list-ol', 'orange')
      case 'starRating':
        return new IconVM('fas fa-star', 'yellow')
      case 'radio':
        return new IconVM('far fa-dot-circle', 'green')
      case 'check':
        return new IconVM('far fa-check-square', 'blue')
      case 'yesNo':
        return new IconVM('far fa-thumbs-up', 'purple')
      case 'text':
        return new IconVM('fas fa-font', 'red')
      case 'infoText':
        return new IconVM('fas fa-info-circle', 'blue')
      case 'dropdown':
        return new IconVM('fas fa-caret-square-down', 'green')
      default:
    }
  }
}
