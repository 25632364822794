import { action, observable } from 'mobx'
import { serializable, serialize, list, object, date } from 'serializr'
import { IQuizDTO } from '../dtos/IQuizDTO'
import { QuestionType } from '../types/QuestionType'
import { Question } from './Question'
import HashId from '../../shared/HashId'
import moment from 'moment'
export class Quiz implements IQuizDTO {
  static create() {
    const quiz = new Quiz()
    quiz.id = HashId.generate()
    quiz.addQuestion('emoji')
    return quiz
  }

  @serializable(list(object(Question))) @observable public questions: Question[] = []
  @serializable @observable public isComplete: boolean = false
  @serializable @observable public id: string = ''
  @serializable @observable public score: number = 0
  @serializable(date()) @observable public completedAt: Date = null

  @action
  public createNewId() {
    this.id = HashId.generate()
  }

  @action
  public addQuestion(type: QuestionType) {
    if (type === 'emoji') this.questions.push(Question.createFromEmojiType())
    if (type === 'rating') this.questions.push(Question.createFromRatingType())
    if (type === 'starRating') this.questions.push(Question.createFromStarRatingType())
    if (type === 'radio') this.questions.push(Question.createFromRadioType())
    if (type === 'check') this.questions.push(Question.createFromCheckType())
    if (type === 'yesNo') this.questions.push(Question.createFromYesNoType())
    if (type === 'text') this.questions.push(Question.createFromTextType())
    if (type === 'dropdown') this.questions.push(Question.createFromDropdownType())
  }

  @action
  public spliceQuestion(index: number, newIdx: number) {
    const questionObj = this.questions.splice(index, 1)
    this.questions.splice(newIdx, 0, questionObj[0])
  }

  @action
  public deleteQuestion(index: number) {
    this.questions.splice(index, 1)
  }

  @action
  public completeQuiz(score: number) {
    this.score = score
    this.completedAt = moment().toDate()
    this.isComplete = true
  }

  public clone(): Quiz {
    return serialize(Quiz, this.toDTO())
  }

  public toDTO() {
    return serialize(this)
  }
}
