import React from 'react'
import { observer } from 'mobx-react'
import SurveyTypesSAEdit from './SurveyTypesSAEdit'

@observer
export default class SurveyTypesSAEditWrapper extends React.Component<any, any> {
	constructor(props) {
		super(props)
	}
	
	render() {
		return (
			<SurveyTypesSAEdit screenType={this.props.params.screenType} objId={this.props.params.objectId} />
		)
	}
}