import { observable } from 'mobx'
import { serializable } from 'serializr'

export class Resource {
  public static create(resourceId): Resource {
    const rsc = new Resource()
    rsc.resourceId = resourceId
    return rsc
  }

  @serializable @observable public resourceId: string = ''
}
