import { action, observable } from 'mobx'
import { IClientDTO } from '../dtos/IClientDTO'
import { serializable, serialize } from 'serializr'
import { IAggregate } from '../../shared/data/IAggregate'

export class Client implements IClientDTO, IAggregate {
  @serializable @observable objectId: string = ''
  @serializable @observable organizationId: string = ''
  @serializable @observable name: string = ''
  @serializable @observable description: string = ''
  @serializable @observable isDefault: boolean = false
  @serializable @observable isProcessing: boolean = false
  @serializable @observable isDeleted: boolean = false
  isOnServer: boolean = false
  public type: string = 'client'

  static create(orgId) {
    const surveyType = new Client()
    surveyType.organizationId = orgId
    return surveyType
  }

  @action
  markIsNotOnServer() {
    this.isOnServer = false
  }

  @action
  markIsOnServer() {
    this.isOnServer = true
  }

  @action
  markIsNotProcessing() {
    this.isProcessing = false
  }

  @action
  markIsProcessing() {
    this.isProcessing = true
  }

  public serialize(): IClientDTO {
    return serialize(this)
  }
}
