import { RootStore } from '../../stores/RootStore'
import Parse from 'parse'
import { IRolesDuplicateRequest } from '../interfaces/IRolesDuplicateRequest'
import { IRolesDuplicateResult } from '../interfaces/IRolesDuplicateResult'

export class RolesDuplicateService {

  async checkForDuplicateRole(req: IRolesDuplicateRequest): Promise<IRolesDuplicateResult> {
    const result = await Parse.Cloud.run('checkForDuplicateRole', { req })
    return result
  }
}