import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { ListItem, ListItemText, Checkbox, IconButton } from '@material-ui/core'
import GroupIcon from '@material-ui/icons/Group'
import { GroupRowVM } from '../view-models/GroupRowVM'
import UsersListModal from './UsersListModal'
import { RoleRowVM } from '../view-models/RoleRowVM'

interface Props {
  row: GroupRowVM | RoleRowVM
}

const GroupedRow: FC<Props> = ({ row }) => {
  return (
    <ListItem
      disabled={row.isReadOnly}
      key={`GroupedRow-${row.objectId}`}
      button
      selected={row.isParticipantSelected}
      onClick={(e) => {
        e.stopPropagation()
        row.toggleParticipant()
      }}
    >
      <Checkbox
        checked={row.isParticipantSelected}
        onClick={(e) => {
          e.stopPropagation()
          row.toggleParticipant()
        }}
      />
      <ListItemText primary={row.name} />
      <IconButton
        onClick={(e) => {
          e.stopPropagation()
          row.openUsersListModal()
        }}
      >
        <GroupIcon />
      </IconButton>
    </ListItem>
  )
}

export default observer(GroupedRow)
