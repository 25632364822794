import { RootStore } from '../../../stores/RootStore'
import SurveyTemplateFolder from '../../../survey-template-folders/aggregate/SurveyTemplateFolder'
import { action, computed, observable } from 'mobx'
import { SurveyTemplatesSelectTableVM } from './SurveyTemplatesSelectTableVM'
import { SurveyTemplatesSelectFoldersVM } from './SurveyTemplatesSelectFoldersVM'
import { ISurveyTemplateFoldersFindRequest } from '../../../survey-template-folders/interfaces/ISurveyTemplateFoldersFindRequest'
import { PulseCampaignEditVM } from '../../../pulse-campaigns/view-models/PulseCampaignEditVM'

export class SurveyTemplatesSelectVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, editVM: PulseCampaignEditVM = null) {
    this.rootStore = rootStore
    this.editVM = editVM
    this.loadSurveyTemplateSelectFolderVM()
    this.loadSurveyTemplateSelectTableVM()
  }

  @observable public foldersVM: SurveyTemplatesSelectFoldersVM = null
  @observable public tableVM: SurveyTemplatesSelectTableVM = null
  @observable public editVM: PulseCampaignEditVM

  @action
  public getTemplatesByFolder(id: string) {
    let folderId = null
    if (id !== 'no-folder') folderId = id
    let orgId = 'DEFAULT'
    const request = this.tableVM.dataStore.getRequest()
    if (this.foldersVM.isSystemToggleMode) {
      request.subscribedOrganizationId = this.rootStore.appStore.currentOrgId
    } else {
      orgId = this.rootStore.appStore.currentOrgId
      request.subscribedOrganizationId = null
    }
    request.organizationId = orgId
    request.folderId = folderId
    this.tableVM.dataStore.setRequest(request)
    this.tableVM.applyFilter()
  }

  @action
  public loadSurveyTemplateSelectFolderVM() {
    this.foldersVM = new SurveyTemplatesSelectFoldersVM(this.rootStore, this, {
      organizationId: this.rootStore.appStore.currentOrgId,
      isSystem: false
    } as ISurveyTemplateFoldersFindRequest)
  }

  @action
  public loadSurveyTemplateSelectTableVM() {
    this.tableVM = new SurveyTemplatesSelectTableVM(this.rootStore, this, {
      organizationId: this.rootStore.appStore.currentOrgId,
    } as ISurveyTemplateFoldersFindRequest)
  }

  @action
  public resetTable() {
    if (!this.tableVM) return
    this.tableVM.hardRefreshTemplatesList()
  }

  @computed
  public get availableOrgs() {
    return this.rootStore.organizationsSAStore.organizations
  }

  @computed
  public get selectedFolder(): SurveyTemplateFolder {
    return this.foldersVM.validSurveyTemplateFolders.find(
      (e) => e.objectId === this.foldersVM.selectedFolder.id
    )
  }

  @action
  public async resetSubscriptionsTable() {
    this.tableVM.hardRefreshTemplatesList()
  }

  @action
  public navigateBackToSelect() {
    this.rootStore.appStore.router.push('/surveys/create')

  }
}
