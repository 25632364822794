export default function formatPhoneNumber(phoneNumber: string): string {
  if (!phoneNumber) return ''

  const numbersArray = '0123456789'.split('')
  const phoneNumberArray = phoneNumber.split('')
  const result = []

  phoneNumberArray.forEach(character => {
    if (numbersArray.includes(character)) result.push(character)
  })

  if (result.length > 10) {
    result.splice(result.length - 10, 0, '-')
    result.splice(result.length - 7, 0, '-')
    result.splice(result.length - 4, 0, '-')
  } else if (result.length > 7) {
    result.splice(result.length - 7, 0, '-')
    result.splice(result.length - 4, 0, '-')
  } else if (result.length === 7) result.splice(result.length - 4, 0, '-')

  return result.join('')
}
