import { ReactElement } from 'react'
import { computed, action, observable } from 'mobx'
import { AvatarUserMinimum } from '../../shared/Avatar'
import { RootStore } from '../../stores/RootStore'
import { IUserInfoOrganizationResult } from '../interfaces/IUserInfoResult'
import { AvatarMenuRowVM } from './AvatarMenuRowVM'

export class AvatarMenuVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @observable public avatarMenuEl: any = null
  @observable public helpDialogOpen: boolean = false
  @observable public isRefreshing: boolean = false

  @computed
  public get organizationResults(): IUserInfoOrganizationResult[] {
    return this.rootStore.userStore.organizationResults
  }

  @computed
  public get user(): AvatarUserMinimum {
    if (!this.rootStore.userStore.user) return undefined
    return {
      name: this.rootStore.userStore.user.name,
      iconURL: this.rootStore.userStore.user.iconURL,
      objectId: this.rootStore.userStore.user.objectId,
    }
  }

  @action
  public setRefreshing(isRefreshing: boolean) {
    this.isRefreshing = isRefreshing
  }

  @action
  public open(el: ReactElement) {
    this.avatarMenuEl = el
  }

  @computed
  public get isOpen() {
    return Boolean(this.avatarMenuEl)
  }

  @computed
  public get loaded(): boolean {
    if (!this.rootStore.appStore.currentOrgId) return false
    if (!this.organizationResults) return false
    return true
  }

  @computed
  public get rows(): AvatarMenuRowVM[] {
    return this.organizationResults
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((e) => new AvatarMenuRowVM(this.rootStore, e))
  }

  @computed
  public get dropDownIconShown(): boolean {
    if (this.rows.length === 1) return false
    return true
  }

  @computed
  public get currentOrgName(): string {
    if (!this.rootStore.userStore.currentOrganization) return ''
    return this.rootStore.userStore.currentOrganization.name
  }

  @computed
  public get canOpenUserSettings(): boolean {
    if (!this.rootStore.userStore.currentOrganization) return false
    if (!this.rootStore.organizationsStore.currentOrganization) return false
    return true
  }

  @action
  public editUserSettings() {
    if (!this.canOpenUserSettings) return
    this.rootStore.organizationUsersStore.loadEditVM(this.rootStore.userStore.currentOrgUserId)
  }

  @action
  public editPassword() {
    if (!this.canOpenUserSettings) return
    this.rootStore.organizationUsersStore.loadPasswordEditVM(
      this.rootStore.userStore.currentOrgUserId
    )
  }

  @action
  public toggleHelpDialog() {
    if (this.isOpen) {
      this.avatarMenuEl = null
    }
    this.helpDialogOpen = !this.helpDialogOpen
  }

  @action
  public handleLogout() {
    this.rootStore.appStore.logout('User clicked logout')
  }
}
