import { observable, computed, action } from 'mobx'
import { ResponseType } from '../types/ResponseType'
import { QuestionEditVM } from '../view-models/QuestionEditVM'
import HashId from '../../shared/HashId'
import { RootStore } from '../../stores/RootStore'
import { IOptionDTO } from 'src/app/surveys/dtos/IOptionDTO'
import { Icon } from '../../surveys/models/Icon'

export class OptionForm {
  private rootStore: RootStore
  private forQuestion: QuestionEditVM

  constructor(rootStore, forQuestion: QuestionEditVM = null, option = null) {
    this.rootStore = rootStore
    this.forQuestion = forQuestion
    if (option) {
      this.loadData(option)
      return
    }
  }

  loadData(option: IOptionDTO) {
    this.id = option.id ? option.id : HashId.generate()
    this.text = option.text
    this.rank = option.rank
    this.value = option.value
    this.weight = option.weight
    this.emoji = option.emoji
    this.responseType = option.responseType
    this.responseNumber = option.responseNumber
  }

  @observable id: string = HashId.generate()
  @observable responseType: ResponseType = 'neutral'
  @observable emoji: string = ''
  @observable text: string = ''
  @observable rank: number = 1
  @observable value: number = null
  @observable rating: number = null
  @observable check: boolean = false
  @observable weight: number = 0
  @observable responseNumber: number = 0

  @action
  public handleTextChange(value) {
    this.text = value
    this.forQuestion.isDirty = true
  }
  @action
  public setWeight(val) {
    this.weight = val
    this.forQuestion.isDirty = true
  }

  @computed
  public get weightValid() {
    const val = this.weight
    if (val != Math.floor(val)) return false
    if (val < 0) return false
    return true
  }

  @action
  public setRank(val: number) {
    this.rank = val
    if (this.forQuestion) this.forQuestion.isDirty = true
  }

  @action
  public handleEmojiChange(name) {
    this.emoji = name
    this.forQuestion.isDirty = true
  }

  @action
  public setResponseType(name: ResponseType) {
    this.forQuestion.isDirty = true
    this.responseType = name
  }

  // @computed
  // public get isValid() {
  //   if (!this.saveTried) return true
  //   if (!this.textValid) return false
  //   return true
  // }

  // @computed
  // public get textValid() {
  //   if (!this.saveTried) return true
  //   if (!this.text || this.text === '') return false
  //   return true
  // }

  @computed
  public get icon(): Icon {
    switch (this.forQuestion.questionType) {
      case 'emoji':
        return new Icon('far fa-smile', 'brown')
      case 'rating':
        return new Icon('fas fa-list-ol', 'orange')
      case 'starRating':
        return new Icon('fas fa-star', 'yellow')
      case 'radio':
        return new Icon('far fa-dot-circle', 'green')
      case 'check':
        return new Icon('far fa-check-square', 'blue')
      case 'yesNo':
        return new Icon('far fa-thumbs-up', 'purple')
      case 'text':
        return new Icon('fas fa-font', 'red')
      case 'infoText':
        return new Icon('fas fa-info-circle', 'blue')
      case 'dropdown':
        return new Icon('fas fa-caret-square-down', 'green')
      default:
    }
  }

  public toDTO() {
    return {
      id: this.id,
      rank: this.rank,
      text: this.text,
      responseType: this.responseType,
      emoji: this.emoji,
      value: this.value ? this.value : this.rank,
      weight: this.weight,
      responseNumber: this.responseNumber,
    }
  }
}
