import { action, computed, observable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import PulseQuestion from '../../pulse-questions/aggregate/PulseQuestion'
import { PulseQuestionType } from '../../pulse-questions/types/PulseQuestionType'
import { PulseQuestionOptionVM } from './questions/PulseQuestionOptionVM'
import { Media } from '../../questions/aggregates/Media'

export class PulseQuestionEditVM {
  private rootStore: RootStore
  private origPulseQuestion: PulseQuestion = null

  constructor(
    rootStore: RootStore,
    pulseQuestion: PulseQuestion,
    isReadOnly?: boolean,
    isFollowUp?: boolean
  ) {
    this.rootStore = rootStore
    this.pulseQuestion = pulseQuestion
    this.origPulseQuestion = pulseQuestion.clone()
    this.isReadOnly = isReadOnly ? isReadOnly : false
    this.isFollowUp = isFollowUp ? isFollowUp : false
  }

  @observable public pulseQuestion: PulseQuestion = null
  @observable public applyTried: boolean = false
  @observable public cantDeleteLastOption: boolean = false
  @observable public isReadOnly: boolean = false
  @observable public isFollowUp: boolean = false
  @observable public isExpanded: boolean = false
  @observable public questionTypes: Array<string> = [
    'radio',
    'rating',
    'starRating',
    'emoji',
    'text',
    'yesNo',
    // 'infoText',
    'dropdown',
  ]

  @computed
  public get questionTypeTitle() {
    if (this.questionType === 'rating') return 'Rating (Scale)'
    if (this.questionType === 'emoji') return 'Emoji'
    if (this.questionType === 'text') return 'Short Answer (Text)'
    if (this.questionType === 'radio') return 'Multiple Choice (Radio)'
    if (this.questionType === 'starRating') return 'Star Rating'
    if (this.questionType === 'dropdown') return 'Dropdown'
    return this.questionType
  }

  @computed
  public get isNew(): boolean {
    return Boolean(!this.objectId)
  }

  @computed
  public get objectId(): string {
    return this.pulseQuestion.objectId
  }

  @computed
  public get questionType(): PulseQuestionType {
    return this.pulseQuestion.type
  }

  @computed
  public get media(): Media {
    return this.pulseQuestion.media
  }

  @computed
  public get html(): string {
    return this.pulseQuestion.html
  }

  @computed
  public get mediaDisplayName(): string {
    if (Boolean(this.pulseQuestion.youTubeURL)) return this.pulseQuestion.youTubeURL
    if (Boolean(this.pulseQuestion.vimeoURL)) return this.pulseQuestion.vimeoURL
    return this.pulseQuestion.media.name
  }

  @computed
  public get maximumValueAllowed(): string {
    return Number(this.pulseQuestion.maximumValueAllowed) === 0
      ? String(10 ** 22 - 1)
      : String(this.pulseQuestion.maximumValueAllowed)
  }

  @computed
  public get minimumValueAllowed(): string {
    return String(this.pulseQuestion.minimumValueAllowed)
  }

  @computed
  public get numberStep(): string {
    if (Number(this.pulseQuestion.numberStep) === 0) return '1'
    return String(this.pulseQuestion.numberStep)
  }

  @computed
  public get required() {
    return this.pulseQuestion.required
  }

  @computed
  public get isDropdownMultiSelect() {
    return this.pulseQuestion.isDropdownMultiSelect
  }

  @computed
  public get showMediaFirst(): boolean {
    return this.pulseQuestion.showMediaFirst
  }

  @computed
  public get hasMedia(): boolean {
    if (Boolean(this.pulseQuestion.youTubeURL)) return true
    if (Boolean(this.pulseQuestion.vimeoURL)) return true
    return Boolean(this.pulseQuestion.media.path)
  }

  @action
  public toggleShowMediaFirst() {
    this.pulseQuestion.toggleShowMediaFirst()
  }

  @action
  public toggleDropdownMultiSelect() {
    this.pulseQuestion.toggleDropdownMultiSelect()
  }

  @action
  public setPlaceholder(placeholder: string) {
    this.pulseQuestion.setPlaceholder(placeholder)
  }

  @computed
  public get title() {
    return this.pulseQuestion.title
  }

  @computed
  public get placeholder() {
    return this.pulseQuestion.placeholder
  }

  @computed
  public get originalPulseQuestionId() {
    return this.pulseQuestion.originalPulseQuestionId
  }

  @computed
  public get ratingDisplay() {
    return this.pulseQuestion.ratingDisplay
  }

  @computed
  public get titleValid(): boolean {
    if (!this.applyTried) return true
    if (!this.title) return false
    return true
  }

  @computed
  public get placeholderValid(): boolean {
    if (this.questionType !== 'dropdown') return true
    if (this.placeholder === undefined) return false
    if (!this.applyTried) return true
    if (!this.placeholder || this.placeholder === '') return false
    return true
  }

  @computed
  public get isValid(): boolean {
    if (!this.titleValid) return false
    if (this.optionsError) return false
    return true
  }

  @computed
  public get optionsError(): boolean {
    if (this.questionType === 'text') return false
    return this.options.some((o) => !o.isValid)
  }

  @computed
  public get options(): PulseQuestionOptionVM[] {
    return this.pulseQuestion.options.map(
      (q, i) => new PulseQuestionOptionVM(this.rootStore, q, i, this)
    )
  }

  @action
  public toggleIsExpanded() {
    this.isExpanded = !this.isExpanded
  }

  @action
  public moveOption(index, newIndex) {
    this.pulseQuestion.moveOption(index, newIndex)
    this.setOptionRanks()
  }

  @action
  public addOption() {
    this.pulseQuestion.addOption()
    this.setOptionRanks()
  }

  @action
  public setOptionRanks() {
    this.options.forEach((opt, idx) => {
      opt.setRank(idx + 1)
    })
  }

  @action
  public deleteOption(idx: number) {
    if (this.pulseQuestion.options.length === 1) return this.toggleDeleteLastOption()
    this.pulseQuestion.deleteOption(idx)
    this.setOptionRanks()
  }

  @action
  public toggleDeleteLastOption() {
    this.cantDeleteLastOption = !this.cantDeleteLastOption
  }

  @action
  public setRatingDisplay(val) {
    this.pulseQuestion.setRatingDisplay(val)
  }

  @action
  public toggleRequred() {
    this.pulseQuestion.toggleIsRequired()
  }

  @action
  public setTitle(title: string) {
    this.pulseQuestion.setTitle(title)
  }

  @action
  public async closeEditModal(): Promise<PulseQuestionEditVM> {
    if (!this.applyTried) this.reset()
    return this.rootStore.pulseCampaignsStore.viewModels.loadPulseQuestionEditVM('empty')
  }

  public changeQuestionType(val) {
    this.pulseQuestion.setQuestionType(val)
    this.pulseQuestion.setDefaultOptions(val)
  }

  public updateQuestionByType(type: PulseQuestionType) {
    this.changeQuestionType(type)
  }

  @action
  public async apply() {
    this.applyTried = true
    if (!this.isValid) return
    this.rootStore.pulseCampaignsStore.viewModels.editVM.updateQuestionForCurrentSurvey(
      this.pulseQuestion as PulseQuestion
    )
    this.origPulseQuestion = this.pulseQuestion.clone()
    this.closeEditModal()
  }

  @action
  public async reset() {
    this.pulseQuestion = this.origPulseQuestion.clone()
  }

  @action
  public setIsReadOnly(isReadOnly: boolean) {
    this.isReadOnly = isReadOnly
  }
}
