import React from 'react'

//MobX Stores
import { observer, inject } from 'mobx-react'
import { QuestionsStore } from '../store/QuestionsStore'

//Types
import { ResponseType } from '../types/ResponseType'

//MaterialUI Components
import { Button, Icon, Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { MenuProps } from '@material-ui/core/Menu'

//Material Icons
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'

//Styles
import { withStyles } from '@material-ui/core/styles'
import './DrawerResponseTypeMenu.scss'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles(theme => ({
  // root: {
  //   '&:focus': {
  //     backgroundColor: theme.palette.primary.main,
  //     '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
  //       color: theme.palette.common.white,
  //     },
  //   },
  // },
}))(MenuItem)

interface DrawerResponseTypeMenuProps {
  questionsStore?: QuestionsStore
  idx: number
}

const DrawerResponseTypeMenu: React.FC<DrawerResponseTypeMenuProps> = ({ questionsStore, idx }) => {
  const { editVM: QuestionCatalogWidgetForm } = questionsStore

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const yesNoClicked = (name: ResponseType) => {
    QuestionCatalogWidgetForm.options[idx].setResponseType(name)
    handleClose()
  }

  const getResponseType = () => {
    let options = [{ text: 'very positive' }, { text: 'positive' }, { text: 'neutral' }, { text: 'negative' }, { text: 'very negative' }]

    const responseTypeOptions = options.map((option, idx) => {
      switch (option.text) {
        case 'very positive':
          return (
            <StyledMenuItem id='positive' key={idx} onClick={() => yesNoClicked('very positive')}>
              <ListItemIcon>
              <><AddIcon className='positive' /><AddIcon className='positive' /></>
              </ListItemIcon>
              <ListItemText primary='Excellent' />
            </StyledMenuItem>
          )
        case 'positive':
          return (
            <StyledMenuItem id='positive' key={idx} onClick={() => yesNoClicked('positive')}>
              <ListItemIcon>
                <AddIcon className='positive' />
              </ListItemIcon>
              <ListItemText primary='Good' />
            </StyledMenuItem>
          )
        case 'neutral':
          return (
            <StyledMenuItem id='neutral' key={idx} onClick={() => yesNoClicked('neutral')}>
              <ListItemIcon>
                <Icon className='neutral'>--</Icon>
              </ListItemIcon>
              <ListItemText primary='Average' />
            </StyledMenuItem>
          )
        case 'negative':
          return (
            <StyledMenuItem id='negative' key={idx} onClick={() => yesNoClicked('negative')}>
              <ListItemIcon>
                <RemoveIcon className='negative' />
              </ListItemIcon>
              <ListItemText primary='Below Average' />
            </StyledMenuItem>
          )
        case 'very negative':
          return (
            <StyledMenuItem id='negative' key={idx} onClick={() => yesNoClicked('very negative')}>
              <ListItemIcon>
                <><RemoveIcon className='negative' /><RemoveIcon className='negative' /></>
              </ListItemIcon>
              <ListItemText primary='Poor' />
            </StyledMenuItem>
          )
      }
    })

    return responseTypeOptions
  }

  const check = QuestionCatalogWidgetForm.options[idx].responseType

  return (
    <div>
      <Button className='response-type-button' onClick={handleClick}>
        {check === 'very positive' ? <><AddIcon className='positive' /><AddIcon className='positive' /></> : null}
        {check === 'positive' ? <AddIcon className='positive' /> : null}
        {check === 'neutral' ? <Icon className='neutral'>--</Icon> : null}
        {check === 'negative' ? <RemoveIcon className='negative' /> : null}
        {check === 'very negative' ? <><RemoveIcon className='negative' /><RemoveIcon className='negative' /></> : null}
      </Button>

      {anchorEl ? (
        <StyledMenu
          id='customized-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {getResponseType()}
        </StyledMenu>
      ) : null}
    </div>
  )
}

export default inject('questionsStore')(observer(DrawerResponseTypeMenu))
