import React from 'react'
import { inject, observer } from 'mobx-react'
import { CalendarSettingsStore } from '../../../calendar-settings/store/CalendarSettingsStore'
import { IconButton, Card, CardHeader, CardContent } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'
import DoneIcon from '@material-ui/icons/Done'
import TimesTab from './TimesTab'
import EventTypesDrawer from './drawer/EventTypesDrawer'
import LocationsDrawer from './drawer/LocationsDrawer'
import ResourcesDrawer from './drawer/ResourcesDrawer'
import WidgetTitle from './WidgetTitle'
import './CalendarManagementWidget.scss'
import { AgGridReact } from 'ag-grid-react'
import AGGridWidgetPagingRow from '../../../shared/ag-grid/AGGridPagingRow'

interface Props {
  calendarSettingsStore?: CalendarSettingsStore
}

const CalendarManagementWidget: React.FC<Props> = ({
  calendarSettingsStore,
}) => {
  const { widgetVM: vm, editForm } = calendarSettingsStore
  if (!vm.shouldRender) return null
  
  const renderAddButtons = () => {
    return (
      <div>
        <IconButton size='small' className='widget-cta' onClick={() => vm.onAddClick(vm.tabIndex)}>
          <AddIcon />
        </IconButton>
      </div>
    )
  }

  const renderEditButton = (editForm) => {
    return (
      <div>
        <IconButton size='small' className='widget-cta' onClick={() => editForm.openEditScreen()}>
          <EditIcon />
        </IconButton>
      </div>
    )
  }

  const renderSaveButton = (editForm) => {
    return (
      <div>
        <IconButton
          size='small'
          className='margin-right'
          onClick={() => editForm.closeEditScreen()}
        >
          <CloseIcon />
        </IconButton>
        <IconButton size='small' className='widget-cta' onClick={() => editForm.save()}>
          <DoneIcon />
        </IconButton>
      </div>
    )
  }

  const renderSwitch = (tabContent) => {
    switch (tabContent) {
      case 3:
        return <TimesTab />
      default:
        return renderAGGrid()
    }
  }

  const renderAGGrid = () => {
    return (
      <>
        <div className='ag-theme-alpine' style={{ height: '100%', width: '100%' }}>
          <AgGridReact gridOptions={{
            ...vm.getGridOptions(),
          }} />
        </div>
        <AGGridWidgetPagingRow vm={vm} />
      </>
    )
  }

  return (
    <div id='CalendarManagementWidget'>
      <EventTypesDrawer />
      <LocationsDrawer />
      <ResourcesDrawer />
      <Card elevation={0}>
        <CardHeader
          className={'cardHeader'}
          action={
            <>
              {vm.tabIndex !== 3
                ? renderAddButtons()
                : !editForm.editScreen
                  ? renderEditButton(editForm)
                  : renderSaveButton(editForm)}
            </>
          }
          title={<WidgetTitle />}
        />
      </Card>
      <CardContent className='cardContent'>{renderSwitch(vm.tabIndex)}</CardContent>
    </div>
  )
}

export default inject(
  'calendarSettingsStore',
)(observer(CalendarManagementWidget))
