import React from 'react'
import { inject, observer, useLocalStore } from 'mobx-react'
import { SurveyTemplatesSelectVM } from '../../../view-models/template-select/SurveyTemplatesSelectVM'
import SurveyTemplatesSelectHeader from './SurveyTemplatesSelectHeader'
import TemplateSelectTopToolbar from './TemplateSelectTopToolbar'
import { RootStore } from '../../../../stores/RootStore'
import SurveyTemplatesFolderSelect from './SurveyTemplatesFolderSelect'
import SurveyTemplatesTableSelect from './SurveyTemplatesTableSelect'
import './SurveyTemplatesSelect.scss'
import { Card, CardContent } from '@material-ui/core'
import { PulseCampaignEditVM } from '../../../../pulse-campaigns/view-models/PulseCampaignEditVM'

interface Props {
  rootStore?: RootStore
  editVM?: PulseCampaignEditVM
}

const SurveyTemplatesSelect: React.FC<Props> = ({ rootStore, editVM = null }) => {
  const localStore = useLocalStore(() => ({
    vm: new SurveyTemplatesSelectVM(rootStore, editVM),
  }))
  const vm = localStore.vm

  const renderSelectToolbar = () => {
    if (editVM) return null
    return <TemplateSelectTopToolbar vm={vm} />
  }

  const renderTemplatesFoldersTable = () => {
    return (
      <div id={'SurveyTemplatesSelectFoldersTable'}>
        <div className={'survey-templates-manage'}>
          <div className='dashboard-left'>
            <Card elevation={0} className={'folders-card'}>
              <CardContent>
                <SurveyTemplatesFolderSelect vm={vm.foldersVM} />
              </CardContent>
            </Card>
          </div>
          <div className='dashboard-right'>
            <div id='AllSurveyTemplatesCard'>
              <SurveyTemplatesTableSelect vm={vm.tableVM} />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div id='SurveyTemplatesSelect'>
      {renderSelectToolbar()}
      <SurveyTemplatesSelectHeader vm={vm} />
      {renderTemplatesFoldersTable()}
    </div>
  )
}

export default inject('rootStore')(observer(SurveyTemplatesSelect))
