import { action, observable } from 'mobx'
import { serializable } from 'serializr'
import { IVisibleParticipantDTO } from '../dtos/IVisibleParticipantDTO'
import { ParticipantType } from '../types/ParticipantType'

export class VisibleParticipant implements IVisibleParticipantDTO {
  public static create(fromPart: any) {
    const part = new VisibleParticipant()
    part.id = fromPart.id
    part.type = fromPart.type
    part.name = fromPart.name
    return part
  }

  @serializable @observable public id: string = ''
  @serializable @observable public type: ParticipantType
  @serializable @observable public name: string = ''
  @serializable @observable public limited: boolean = false

  @action
  public toggleLimited() {
    this.limited = !this.limited
  }
}
