import React from 'react'
import { inject, observer } from 'mobx-react'
import { OrganizationsStore } from '../../store/OrganizationsStore'
import { Card, CardContent, Drawer } from '@material-ui/core'
import DrawerHeader from './DrawerHeader'
import DrawerButtonControls from './DrawerButtonControls'
import DrawerBasicInfoEdit from './DrawerBasicInfoEdit'
import DrawerLogoEdit from './DrawerLogoEdit'
import './Drawer.scss'
import DrawerTabs from './DrawerTabs'
import DrawerLinks from './links/DrawerLinks'
import WelcomeBanner from './welcome-banner/WelcomeBanner'

interface OrganizationGeneralWidgetDrawerProps {
  organizationsStore?: OrganizationsStore
}

const OrganizationGeneralWidgetDrawer: React.FC<OrganizationGeneralWidgetDrawerProps> = ({
  organizationsStore,
}) => {
  const { editVM: editForm } = organizationsStore
  if (!editForm) return null

  return (
    <Drawer
      id='OrganizationDetails'
      anchor='right'
      open={editForm.isFormDrawerOpen}
      onClose={() => organizationsStore.closeDrawer()}
      disableEnforceFocus
    >
      <Card elevation={0}>
        <DrawerHeader />
        <DrawerButtonControls />
        <DrawerTabs />
        <CardContent>
          <DrawerBasicInfoEdit />
          <DrawerLogoEdit />
          <DrawerLinks vm={editForm.linksVM} />
          <WelcomeBanner vm={editForm.welcomeBannerVM} />
        </CardContent>
      </Card>
    </Drawer>
  )
}

export default inject('organizationsStore')(observer(OrganizationGeneralWidgetDrawer))
