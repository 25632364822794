import { observable, computed, action, reaction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Category } from '../../categories/aggregate/Category'
import { EditScreenVM } from './EditScreenVM'
import { RoleVM } from './RoleVM'

export class CategoryVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, editVM: EditScreenVM, category: Category) {
    this.rootStore = rootStore
    this.editVM = editVM
    this.category = category
  }

  @observable public editVM: EditScreenVM = null
  @observable public category: Category = null
  @observable public allChildrenChecked: boolean = false
  @observable children: Array<CategoryVM> = []

  @computed
  public get showNameTooltip(): boolean {
    let length = this.name.length
    if (length > 50) return true
    return false
  }

  @computed
  public get selectedRole(): RoleVM {
    return this.editVM.selectedRole
  }

  @computed
  public get parentCategory(): CategoryVM {
    if (!this.parentCategoryId) return null

    let parent

    const findParentCat = (categories) => {
      categories.forEach((cat) => {
        const foundParent = categories.find((e) => e.objectId === this.parentCategoryId)
        if (foundParent) parent = foundParent
        else if (!foundParent && cat.hasChildren) findParentCat(cat.children)
      })
    }

    findParentCat(this.editVM.categories)

    if (parent) return parent
  }

  @computed
  public get parentCategoryId() {
    return this.category.parentCategoryId
  }

  @computed
  public get isChecked() {
    if (!this.weightProfileCategory) return false
    return this.weightProfileCategory.isChecked
  }

  @computed
  public get isSelected() {
    if (!this.weightProfileCategory) return false
    return this.weightProfileCategory.isSelected
  }

  @computed
  public get weightProfileCategory() {
    if (!this.selectedRole) return null
    if (!this.selectedRole.weightProfileVM) return null
    let foundCat = this.selectedRole.weightProfileVM.categories.find(
      (e) => e.categoryId === this.objectId
    )
    if (!foundCat) return null
    return foundCat
  }

  @computed
  public get checkedChildren(): Array<CategoryVM> {
    return this.children.filter((child) => child.isChecked)
  }

  @computed
  public get computedParentWeight() {
    if (this.parentCategory) return Math.floor(100 / this.parentCategory.checkedChildren.length)
  }

  @computed
  public get computedChildrenWeight() {
    return Math.floor(100 / this.checkedChildren.length)
  }

  @computed
  public get topLevelDefaultWeight() {
    let checkedCategories = this.editVM.categories.filter((category) => category.isChecked)
    return Math.floor(100 / checkedCategories.length)
  }

  @computed
  public get childrenSelectedValid() {
    if (!this.selectedRole) return true
    if (!this.hasChildren) return true
    if (!this.isChecked) return true
    if (!this.selectedRole.weightProfileVM.saveTried) return true

    if (this.isChecked) {
      if (!this.checkedChildren.length) return false
      if (this.childrenHaveSelectedValidError) return false
    }

    return true
  }

  @computed
  public get childrenHaveSelectedValidError() {
    const errors = this.children.filter((child) => !child.childrenSelectedValid)

    return errors.length > 0
  }

  @computed
  public get childrenWeightValid() {
    if (!this.selectedRole) return true
    if (!this.hasChildren) return true
    if (!this.isChecked) return true
    if (!this.selectedRole.weightProfileVM.saveTried) return true
    if (!this.checkedChildren.length) return true

    let totalWeight = 0
    this.checkedChildren.forEach((child) => {
      totalWeight += Number(child.weight)
    })
    return Boolean(totalWeight === 100)
  }

  @computed
  public get hasChildren() {
    return Boolean(this.children.length)
  }

  @computed
  public get objectId() {
    return this.category.objectId
  }

  @computed
  public get name() {
    return this.category.name
  }

  @computed
  public get weight() {
    if (!this.weightProfileCategory) return 0
    if (!this.weightProfileCategory.isChecked) return 0
    return this.weightProfileCategory.value
  }

  @computed
  public get description() {
    return this.category.description
  }

  @action
  public checkAll() {
    this.rootStore.weightProfilesStore.editVM.selectedRole.weightProfileVM.isDirty = true
    if (this.allChildrenChecked) {
      this.children.forEach((child) => {
        child.weightProfileCategory.isChecked = false
        child.weightProfileCategory.setValue(0)
      })
      this.toggleAllChildrenChecked()
    } else {
      this.children.forEach((child) => {
        child.weightProfileCategory.isChecked = true
      })
      this.toggleAllChildrenChecked()
    }
    if (!this.allChildrenChecked) return
    this.children.forEach((child) => {
      child.weightProfileCategory.setValue(this.computedChildrenWeight)
    })
  }

  @action
  public toggleAllChildrenChecked() {
    this.allChildrenChecked = !this.allChildrenChecked
  }

  @action
  public unSelectOtherBranch() {
    if (!this.parentCategoryId) {
      let foundBranch = this.editVM.categories.find(
        (e) => e.isSelected && e.objectId !== this.objectId
      )
      if (foundBranch) foundBranch.handleCatClick()
    } else if (this.parentCategory && this.parentCategory.children.length > 1) {
      let foundBranch = this.parentCategory.children.find(
        (e) => e.isSelected && e.objectId !== this.objectId
      )
      if (foundBranch) foundBranch.handleCatClick()
    }
  }

  @action
  public handleCatClick() {
    if (!this.weightProfileCategory) return null

    this.unSelectOtherBranch()

    this.weightProfileCategory.toggleIsSelected()

    if (!this.weightProfileCategory.isSelected) {
      const deselectChildren = (children) => {
        children.forEach((child) => {
          child.weightProfileCategory.isSelected = false
          if (child.children) deselectChildren(child.children)
        })
      }

      if (this.children) deselectChildren(this.children)
    }
  }

  @action
  public handleCatCheck() {
    if (!this.weightProfileCategory) return null

    this.selectedRole.weightProfileVM.isDirty = true
    this.weightProfileCategory.toggleIsChecked()
    if (!this.weightProfileCategory.isChecked) {
      const decheckChildren = (children) => {
        children.forEach((child) => {
          child.weightProfileCategory.isChecked = false
          if (child.children) decheckChildren(child.children)
        })
      }

      if (this.children) decheckChildren(this.children)
    } else {
      if (this.children.length === 1) {
        const selectSingleChildren = (children) => {
          children.forEach((child) => {
            child.weightProfileCategory.isChecked = true
            if (child.children.length === 1) selectSingleChildren(child.children)
          })
        }
        selectSingleChildren(this.children)
      }
    }
    if (!this.parentCategory) {
      this.editVM.categories.forEach((category) => {
        if (category.isChecked) category.weightProfileCategory.setValue(this.topLevelDefaultWeight)
      })
    } else {
      this.parentCategory.children.forEach((child) => {
        if (child.isChecked) child.weightProfileCategory.setValue(this.computedParentWeight)
      })
    }
  }

  @action
  public handleWeightChange(e) {
    if (!this.weightProfileCategory) return null

    this.selectedRole.weightProfileVM.isDirty = true
    this.weightProfileCategory.setValue(e)
  }
}
