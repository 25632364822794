import { observable, action } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { UsersTabVM } from './UsersTabVM'
import { IAggregate } from '../../../shared/data/IAggregate'
import { IOrganizationUserAudienceSearchRequest } from '../../interfaces/IOrganizationUserAudienceSearchRequest'
import { GroupingsTabVM } from './GroupingsTabVM'

export class MyAudienceWidgetVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.loadTabs()
    this.rootStore.audienceMembersStore.loadAudienceMembers()
  }

  @observable public tabIndex: number = 0
  @observable public emailAnchorEl: any = null
  @observable public selectedRow: any = null
  @observable public directReportsTab: UsersTabVM
  @observable public allReportsTab: UsersTabVM
  @observable public additionalUsersTab: UsersTabVM
  @observable public rolesTab: GroupingsTabVM
  @observable public groupsTab: GroupingsTabVM

  private loadTabs() {
    this.directReportsTab = new UsersTabVM(
      this.rootStore,
      this,
      {
        subordinates: true,
      } as IOrganizationUserAudienceSearchRequest,
      0
    )
    this.allReportsTab = new UsersTabVM(
      this.rootStore,
      this,
      {
        allSubordinates: true,
      } as IOrganizationUserAudienceSearchRequest,
      1
    )
    this.additionalUsersTab = new UsersTabVM(
      this.rootStore,
      this,
      {
        additionalAudienceUsers: true,
      } as IOrganizationUserAudienceSearchRequest,
      2
    )
    this.rolesTab = new GroupingsTabVM(this.rootStore, this, 3)
    this.groupsTab = new GroupingsTabVM(this.rootStore, this, 4)
  }

  @action
  public getRowNodeId(row: IAggregate): string {
    return row.objectId
  }

  @action
  protected isExternalFilterPresent(): boolean {
    return true
  }

  @action
  public setEmailAnchorEl(type: string, val: any, row: any) {
    // if (type === 'user') return
    this.selectedRow = row
    this.emailAnchorEl = val
  }

  @action
  public sendEmailOnToLine() {
    this.selectedRow.setEmailTo('to')
    window.open(this.selectedRow.mailToLink)
    this.setEmailAnchorEl('', null, null)
  }

  @action
  public sendEmailOnBCCLine() {
    this.selectedRow.setEmailTo('bcc')
    window.open(this.selectedRow.mailToLink)
    this.setEmailAnchorEl('', null, null)
  }

  @action
  public setTabIndex(val: number) {
    this.tabIndex = val
  }
}
