import { observable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { EmailTemplatesStore } from '../store/EmailTemplatesStore'
import { EmailTemplateCreateDialogVM } from '../view-models/EmailTemplateCreateDialogVM'
import { EmailTemplatesManageVM } from '../view-models/EmailTemplatesManageVM'
import { EmailTemplatesTableVM } from '../view-models/EmailTemplatesTableVM'
import { EmailTemplatePreviewVM } from '../view-models/EmailTemplatePreviewVM'

export class EmailTemplatesViewModelsService {
  private rootStore: RootStore
  private emailTemplatesStore: EmailTemplatesStore

  constructor(rootStore: RootStore, emailTemplatesStore: EmailTemplatesStore) {
    this.rootStore = rootStore
    this.emailTemplatesStore = emailTemplatesStore
    this.manageVM = new EmailTemplatesManageVM(rootStore)
    this.createDialogVM = new EmailTemplateCreateDialogVM(this.rootStore)
    this.orgTableVM = new EmailTemplatesTableVM(rootStore)
    this.orgTableVM.isSystemTable = false
    this.sysTableVM = new EmailTemplatesTableVM(rootStore)
    this.previewVM = new EmailTemplatePreviewVM(rootStore)
  }

  @observable public manageVM: EmailTemplatesManageVM = null
  @observable public createDialogVM: EmailTemplateCreateDialogVM = null
  @observable public orgTableVM: EmailTemplatesTableVM = null
  @observable public sysTableVM: EmailTemplatesTableVM = null
  @observable public previewVM: EmailTemplatePreviewVM = null
}
