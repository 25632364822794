import { RootStore } from '../../../../stores/RootStore'
import { action, computed } from 'mobx'
import { UserTrainingPlan } from '../../../../training-plans/user-training-plans/aggregate/UserTrainingPlan'
import User from '../../../../users/aggregate/User'
import TrainerWidgetRowVM from './TrainerWidgetRowVM'
import { AudienceMember } from 'src/app/audience-members/aggregate/AudienceMember'

export class UserTrainingPlanRowVM {
  private rootStore: RootStore
  private trainingPlanVM: TrainerWidgetRowVM
  public userTrainingPlan: UserTrainingPlan

  constructor(
    rootStore: RootStore,
    trainingPlanVM: TrainerWidgetRowVM,
    userTrainingPlan: UserTrainingPlan
  ) {
    this.rootStore = rootStore
    this.userTrainingPlan = userTrainingPlan
    this.trainingPlanVM = trainingPlanVM
  }

  @computed
  public get title(): string {
    return this.userTrainingPlan.title
  }

  @computed
  public get user(): AudienceMember {
    return this.rootStore.audienceMembersStore.getUser(this.userTrainingPlan.userId)
  }

  @computed
  public get userOwned(): boolean {
    return this.userTrainingPlan.userId === this.userTrainingPlan.assignedByUserId
  }

  @computed
  public get completedItems(): number {
    let completedItems = 0
    this.userTrainingPlan.trainingPlanItems.forEach((item) => {
      item.contentItems.forEach((contentItem) => {
        if (contentItem.isComplete) completedItems++
      })
    })
    return completedItems
  }

  @computed
  public get totalItems(): number {
    let totalItems = 0
    this.userTrainingPlan.trainingPlanItems.forEach((item) => {
      totalItems = totalItems + item.contentItems.length
    })
    return totalItems
  }

  @computed
  public get progress(): number {
    if (this.completedItems === 0) return 0
    return Math.round((this.completedItems / this.totalItems) * 100)
  }

  @action
  public openUserTrainingPlanToView() {
    this.rootStore.appStore.router.push(`/userTrainingPlans/view/${this.userTrainingPlan.objectId}`)
  }
}
