import React from 'react'
import {
  Grid,
  InputLabel,
  TextField,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  Hidden,
  Switch,
  InputAdornment,
  IconButton,
  Typography,
  Divider,
  RadioGroup,
  Radio,
} from '@material-ui/core'
import AlarmIcon from '@material-ui/icons/Alarm'
import { observer } from 'mobx-react'
import { inject } from 'mobx-react'
import { SurveysStore } from '../../../store/SurveysStore'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'
import DeleteIcon from '@material-ui/icons/Close'
import AddIcon from '@material-ui/icons/Add'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { ReminderVM } from '../../../view-models/ReminderVM'
import './ScheduleTabPanel.scss'
import { SurveyEditTabsEnum } from '../../../types/SurveyEditTabsEnum'
import moment from 'moment'
import TimePicker from '../../../../shared/time-picker/TimePicker'
import LocalizationStore from 'src/app/localization/LocalizationStore'
interface Props {
  surveysStore?: SurveysStore
  localizationStore?: LocalizationStore
}

const ScheduleTabPanel: React.FC<Props> = ({ surveysStore, localizationStore }) => {
  const { surveys: lz } = localizationStore.lzStrings
  const { editVM } = surveysStore.viewModels
  if (!editVM) return null
  if (editVM.currentTabIndex !== SurveyEditTabsEnum.SCHEDULE) return null

  const renderSendInvitationsRow = () => {
    return (
      <>
        <Grid item xs={12} md={8}>
          <InputLabel>{lz.enable_schedule}</InputLabel>
          <FormControlLabel
            control={
              <Switch
                disabled={editVM.isReadOnly}
                checked={editVM.scheduleEnabled}
                onChange={() => editVM.toggleScheduleEnabled()}
              />
            }
            label={''}
          />
        </Grid>
        <Hidden smDown>
          <Grid item xs={4} />
        </Hidden>
      </>
    )
  }

  const renderFirstDeliverInvitationsRow = () => {
    if (!editVM.scheduleEnabled) return null
    return renderDeliverInvitationsRow()
  }

  const renderDeliverInvitationsRow = (isSecondary: boolean = false) => {
    return (
      <>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item xs={12} md={8}>
            <InputLabel className={isSecondary ? 'more-top-margin' : ''}>{lz.starting}</InputLabel>

            {!isSecondary && (
              <KeyboardDatePicker
                disabled={editVM.isReadOnly}
                className='date-input'
                disableToolbar
                autoOk
                variant='inline'
                inputVariant='outlined'
                format='MM/dd/yyyy'
                margin='none'
                id='date-picker-inline4'
                value={editVM.startDateDate}
                onChange={(e) => editVM.setStartDate(e)}
              />
            )}
            <div className='date-input'>
              <TimePicker
                error={false}
                value={editVM.startDateTime}
                onChange={(hr, min) => editVM.setStartDateTime(hr, min)}
              />
            </div>
            <Autocomplete
              disabled={editVM.isReadOnly}
              options={editVM.timeZones}
              getOptionLabel={(e) => e.label}
              value={editVM.deliveryTimeZone}
              onChange={(e, val) => editVM.setDeliveryTimeZone(val)}
              renderInput={(params) => (
                <TextField {...params} placeholder='Select Time Zone' variant='outlined' />
              )}
            />
          </Grid>
          <Hidden smDown>
            <Grid item md={4} />
          </Hidden>
        </MuiPickersUtilsProvider>
      </>
    )
  }

  const renderRepeatRow = () => {
    if (!editVM.scheduleEnabled) return null
    return (
      <>
        <Grid item xs={12} md={12}>
          <InputLabel className='more-top-margin'>{lz.repeat}</InputLabel>
          <FormControl variant='outlined'>
            <Select
              disabled={editVM.isReadOnly}
              value={editVM.repeatMode}
              onChange={(e) => {
                editVM.setRepeatMode(e.target.value as string)
              }}
            >
              <MenuItem value={'no repeat'}>{lz.no_repeat}</MenuItem>
              <MenuItem value={'daily'}>{lz.daily}</MenuItem>
              <MenuItem value={'weekly'}>{lz.weekly}</MenuItem>
              <MenuItem value={'monthly'}>{lz.monthly}</MenuItem>
              <MenuItem value={'yearly'}>{lz.yearly}</MenuItem>
            </Select>
          </FormControl>
          {editVM.repeatMode === 'weekly' && (
            <ToggleButtonGroup className='publish-reminders' value={editVM.selectedWeekdays}>
              {editVM.weekdays.map((e, index) => (
                <ToggleButton
                  disabled={editVM.isReadOnly}
                  key={'wd' + index}
                  value={index}
                  onClick={() => e.toggleChecked()}
                >
                  {e.subString}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          )}
        </Grid>
      </>
    )
  }

  const renderEndingRow = () => {
    if (!editVM.scheduleEnabled) return null
    if (editVM.repeatMode === 'no repeat') return null
    return (
      <>
        <Grid item xs={12} md={10}>
          <InputLabel className='more-top-margin'>{lz.ending}</InputLabel>
          <FormControl variant='outlined'>
            <Select
              disabled={editVM.isReadOnly}
              value={editVM.endingMode}
              onChange={(e) => {
                editVM.setEndingMode(e.target.value as string)
              }}
            >
              <MenuItem value={'Never'}>{lz.never}</MenuItem>
              <MenuItem value={'Specific Date'}>{lz.specific_date}</MenuItem>
            </Select>
          </FormControl>
          {editVM.endingMode === 'Specific Date' && (
            <KeyboardDatePicker
              disabled={editVM.isReadOnly}
              className='date-input'
              disableToolbar
              autoOk
              variant='inline'
              inputVariant='outlined'
              format='MM/dd/yyyy'
              margin='none'
              id='date-picker-inline3'
              value={editVM.endDate}
              minDate={editVM.minEndDate}
              onChange={(e) => editVM.setEndDate(e)}
            />
          )}
        </Grid>
        <Hidden smDown>
          <Grid item md={2} />
        </Hidden>
      </>
    )
  }

  const surveyDueInToggle = () => {
    return (
      <div className='toggle-text'>
        <Typography variant='h6'>{lz.set_a_due_date}</Typography>
        <FormControlLabel
          control={
            <Switch
              disabled={editVM.isReadOnly}
              checked={editVM.dueAfterEnabled}
              onChange={() => editVM.toggleDueAfterEnabled()}
            />
          }
          label=''
        />
      </div>
    )
  }

  const dueDateRadios = () => {
    if (!editVM.survey.dueAfterEnabled) return null
    return (
      <div>
        <RadioGroup
          className='due-date-radios'
          value={editVM.dueDateType || ''}
          // onChange={(e) => editVM.setDueDateType(value)}
        >
          <FormControlLabel value='dueAfter' control={<Radio />} label={lz.set_due_after_period} />
          <FormControlLabel
            value='specificDate'
            control={<Radio />}
            label={lz.set_specific_due_date}
          />
        </RadioGroup>
      </div>
    )
  }

  const surveyDue = () => {
    if (!editVM.survey.dueAfterEnabled) return null
    if (editVM.dueDateType !== 'specificDate') return null
    return (
      <div>
        <div className='due-date-picker-row'>
          <InputLabel htmlFor='surveyDueDate'>{lz.due_on_specific_date_and_time}</InputLabel>
          <KeyboardDatePicker
            disablePast={true}
            inputVariant='outlined'
            format='MM/dd/yyyy'
            margin='none'
            disableToolbar
            className='due-date-picker'
            fullWidth
            autoOk
            error={false}
            helperText={null}
            id='date-picker-inline6'
            value={editVM.dueDateDate}
            onChange={(e) => editVM.setDueDate(e)}
          />
        </div>
        <div className='due-time-picker-row'>
          <TimePicker
            error={false}
            value={editVM.dueDateTime}
            onChange={(hr, min) => editVM.setDueTime(hr, min)}
          />
          {/* <Autocomplete
            options={vm.timeZones}
            getOptionLabel={(e) => e.label}
            value={vm.deliveryTimeZone}
            onChange={(e, val) => vm.setDueTimeZone(val)}
            renderInput={(params) => (
              <TextField {...params} placeholder='Select Time Zone' variant='outlined' />
            )}
            className='time-zone-autocomplete'
          /> */}
        </div>
      </div>
    )
  }

  const renderDueInputRow = () => {
    if (!editVM.dueAfterEnabled) return null
    if (editVM.dueDateType !== 'dueAfter') return null
    return (
      <>
        <Grid item xs={12} md={8}>
          <InputLabel className='hidden'></InputLabel>
          <TextField
            disabled={editVM.isReadOnly}
            value={editVM.dueAfterValue}
            onChange={(e) => editVM.setDueAfterValue(e.target.value as string)}
            className='small-number-input'
            variant='outlined'
          />
          <InputLabel className='hidden'></InputLabel>
          <FormControl variant='outlined'>
            <Select
              disabled={editVM.isReadOnly}
              value={editVM.dueAfterPeriod}
              onChange={(e) => {
                editVM.setDueAfterPeriod(e.target.value as string)
              }}
            >
              <MenuItem value={'hour'}>{lz.hours}</MenuItem>
              <MenuItem value={'minute'}>{lz.minutes}</MenuItem>
              <MenuItem value={'day'}>{lz.days}</MenuItem>
              <MenuItem value={'week'}>{lz.weeks}</MenuItem>
              <MenuItem value={'month'}>{lz.months}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Hidden smDown>
          <Grid item xs={4} />
        </Hidden>
      </>
    )
  }

  const renderSendRemindersToggleRow = () => {
    if (!editVM.dueDateType) return null
    if (!editVM.dueAfterEnabled) return null
    return (
      <>
        <Grid item xs={12} md={8}>
          <InputLabel className='more-top-margin'>Send reminders to complete the survey</InputLabel>
          <FormControlLabel
            control={
              <Switch
                disabled={editVM.isReadOnly}
                checked={editVM.sendReminders}
                onChange={() => editVM.toggleSendReminders()}
              />
            }
            label={''}
          />
        </Grid>
        <Hidden smDown>
          <Grid item xs={4} />
        </Hidden>
      </>
    )
  }

  const renderSendRemindersWhenDueRow = () => {
    if (!editVM.dueDateType) return null
    if (!editVM.dueAfterEnabled) return null
    if (!editVM.sendReminders) return null
    return (
      <>
        <Grid item xs={12} md={8}>
          <Typography className='when-due'>{lz.when_the_survey_is_due}</Typography>
          <Divider />
        </Grid>
        <Hidden smDown>
          <Grid item xs={4} />
        </Hidden>
      </>
    )
  }

  const renderReminderRow = (reminder: ReminderVM, idx: number) => {
    if (!editVM.dueDateType) return null
    if (!editVM.dueAfterEnabled) return null
    if (!editVM.sendReminders) return null
    return (
      <React.Fragment key={'rmd' + idx}>
        <Grid item xs={12} md={8}>
          {reminder.period === 'minute' ? (
            <FormControl variant='outlined'>
              <Select
                disabled={editVM.isReadOnly}
                value={reminder.value}
                onChange={(e) => {
                  reminder.setValue(e.target.value)
                }}
              >
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={45}>45</MenuItem>
              </Select>
            </FormControl>
          ) : (
            <TextField
              disabled={editVM.isReadOnly}
              value={reminder.value}
              onChange={(e) => reminder.setValue(e.target.value as string)}
              variant='outlined'
              className='small-number-input'
              autoFocus
              onFocus={(e) => e.target.select()}
            />
          )}
          <FormControl variant='outlined'>
            <Select
              disabled={editVM.isReadOnly}
              value={reminder.period}
              onChange={(e) => {
                reminder.setPeriod(e.target.value)
              }}
            >
              <MenuItem value={'hour'}>{lz.hours}</MenuItem>
              <MenuItem value={'minute'}>{lz.minutes}</MenuItem>
              <MenuItem value={'day'}>{lz.days}</MenuItem>
              <MenuItem value={'week'}>{lz.weeks}</MenuItem>
              <MenuItem value={'month'}>{lz.months}</MenuItem>
            </Select>
          </FormControl>
          <Typography component={'span'} className='before-due-text'>
            {lz.before_the_survey_is_due}
            <IconButton onClick={() => editVM.deleteReminder(idx)}>
              <DeleteIcon />
            </IconButton>
          </Typography>
          <Divider />
        </Grid>
        <Hidden smDown>
          <Grid item xs={4} />
        </Hidden>
      </React.Fragment>
    )
  }

  const renderAddReminderRow = () => {
    if (!editVM.dueDateType) return null
    if (!editVM.dueAfterEnabled) return null
    if (!editVM.sendReminders) return null
    return (
      <>
        <Grid item xs={12} md={8}>
          <IconButton
            disabled={editVM.isReadOnly}
            // onClick={() => editVM.addReminder()}
          >
            <AddIcon />
          </IconButton>
        </Grid>
        <Hidden smDown>
          <Grid item xs={4} />
        </Hidden>
      </>
    )
  }

  return (
    <Grid container id='ScheduleTabPanel'>
      <Grid item sm={12} md={6} xl={4}>
        {renderSendInvitationsRow()}
        {renderRepeatRow()}
        {renderFirstDeliverInvitationsRow()}
        {renderEndingRow()}
        {surveyDueInToggle()}
        {dueDateRadios()}
        {surveyDue()}
        {renderDueInputRow()}
        {renderSendRemindersToggleRow()}
        {renderSendRemindersWhenDueRow()}
        {editVM.reminders.map((e, idx) => renderReminderRow(e, idx))}
        {renderAddReminderRow()}
      </Grid>
    </Grid>
  )
}

export default inject('localizationStore', 'surveysStore')(observer(ScheduleTabPanel))
