import Parse from 'parse'
import { RootStore } from '../../stores/RootStore'
import { ISurveyTemplateFoldersFindRequest } from '../interfaces/ISurveyTemplateFoldersFindRequest'
import { ISurveyTemplateFoldersFindResult } from '../interfaces/ISurveyTemplateFoldersFindResult'
import { ISurveyTemplateDTO } from '../../survey-templates/dtos/ISurveyTemplateDTO'
import { ISurveyTemplateFolderDTO } from '../dtos/ISurveyTemplateFolderDTO'

export class SurveyTemplateFoldersService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public async findSurveyTemplateFolders(
    request: ISurveyTemplateFoldersFindRequest
  ): Promise<ISurveyTemplateFoldersFindResult> {
    return await Parse.Cloud.run('findSurveyTemplateFolders', { request })
  }

  public async getSurveyTemplateFolderForOrg(
    request: ISurveyTemplateFoldersFindRequest
  ): Promise<ISurveyTemplateFoldersFindResult> {
    return await Parse.Cloud.run('getSurveyTemplateFoldersForOrg', { request })
  }

  public async saveSurveyTemplateFolder(surveyTemplateFolder: ISurveyTemplateFolderDTO, orgId: string) {
    const data = await Parse.Cloud.run('saveSurveyTemplateFolder', { surveyTemplateFolder, orgId })
    return data
  }

  public async deleteSurveyTemplateFolder(objectId: string, orgId: string) {
    const data = await Parse.Cloud.run('deleteSurveyTemplateFolder', { objectId, orgId })
    return data
  }

  public async addOrganizationToFolder(orgId: string, folderId: string) {
    await Parse.Cloud.run('addOrganizationToFolder', { orgId, folderId })
  }

  public async removeOrganizationFromFolder(orgId: string, folderId: string) {
    await Parse.Cloud.run('removeOrganizationFromFolder', { orgId, folderId })
  }
}
