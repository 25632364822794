import React from 'react'
import { TextField, InputAdornment, Button, InputLabel } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import { SurveysStore } from '../../../store/SurveysStore'
import './MediaSelect.scss'

interface MediaSelectProps {
  surveysStore?: SurveysStore
}

const MediaSelect: React.FC<MediaSelectProps> = ({ surveysStore }) => {
  const vm = surveysStore.viewModels.editVM
  if (!vm) return null

  const button =
    vm.currentQuestion && vm.currentQuestion.media && (vm.currentQuestion.media.objectId || vm.currentQuestion.media.cmsItemId) ? (
      <Button
        disabled={vm.isReadOnly}
        className='browse-button'
        variant='outlined'
        onClick={() => vm.currentQuestion.removeMedia()}
      >
        Remove
      </Button>
    ) : (
        <Button
          disabled={vm.isReadOnly}
          className='browse-button'
          variant='outlined'
          onClick={() => vm.showMediaPanel()}
        >
          Browse
        </Button>
      )

  return (
    <div id='MediaSelect'>
      <InputLabel className='question-form-input'>Media</InputLabel>

      <TextField
        onFocus={() => vm.showMediaPanel()}
        disabled={vm.isReadOnly}
        variant='outlined'
        type='text'
        placeholder='Add Media'
        margin='none'
        fullWidth
        value={vm.currentQuestion.media.displayName}
        InputProps={{
          endAdornment: <InputAdornment position='end'>{button}</InputAdornment>,
        }}
      />
    </div>
  )
}

export default inject('surveysStore')(observer(MediaSelect))
