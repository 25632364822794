import { observable, computed, action, runInAction } from 'mobx'
import moment from 'moment'
import { RootStore } from '../../stores/RootStore'
import { AudienceMember } from '../aggregate/AudienceMember'
import { IAudienceUserResult } from '../interfaces/IAudienceUserResult'
import { IAudienceRoleResult } from '../interfaces/IAudienceRoleResult'
import { IAudienceGroupResult } from '../interfaces/IAudienceGroupResult'
import { AudienceMemberService } from '../service/AudienceMembersService'

export class AudienceMembersStore {
  private rootStore: RootStore
  private called: boolean = false

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @observable public users: Array<IAudienceUserResult> = []
  @observable public roles: Array<IAudienceRoleResult> = []
  @observable public groups: Array<IAudienceGroupResult> = []
  @observable public allUsers: Array<IAudienceUserResult> = []
  @observable public allRoles: Array<IAudienceRoleResult> = []
  @observable public allGroups: Array<IAudienceGroupResult> = []
  @observable public loaded: boolean = false
  @observable public updatedAt: number = moment().unix()

  @action
  public async loadAudienceMembers() {
    if (this.loaded) return
    if (this.called) return
    if (this.rootStore.userStore.currentOrganization.disableOldAudience === true) {
      return
    }
    this.called = true
    const svc = new AudienceMemberService(this.rootStore)
    const result = await svc.getUserAudience()
    runInAction(() => {
      this.updatedAt = moment().unix()
      this.users = result.users
      this.roles = result.roles
      this.groups = result.groups
      this.allUsers = result.allUsers
      this.allRoles = result.allRoles
      this.allGroups = result.allGroups
      this.loaded = true
    })
  }

  private doArraysHaveElementsInCommon(array1: Array<string>, array2: Array<string>): boolean {
    for (let i = 0; i < array1.length; i++) {
      if (array2.includes(array1[i])) return true
    }
    return false
  }

  @computed
  public get audienceRoles() {
    return this.currentOrgRoles
  }

  @computed
  public get audienceGroups() {
    return this.currentOrgGroups
  }

  @computed
  public get audienceUsers(): AudienceMember[] {
    return this.currentOrgUsers
  }

  @computed
  public get audienceMembers(): AudienceMember[] {
    return this.currentOrgRoles.concat(this.currentOrgGroups).concat(this.currentOrgUsers)
  }

  @computed
  public get allCurrentOrgGroups(): Array<AudienceMember> {
    if (!this.allGroups) return []
    return this.allGroups.map((e) =>
      AudienceMember.fromAudienceGroupResult(this.rootStore.appStore.currentOrgId, e)
    )
  }

  @computed
  public get allCurrentOrgRoles(): Array<AudienceMember> {
    if (!this.allRoles) return []
    return this.allRoles.map((e) =>
      AudienceMember.fromAudienceRoleResult(this.rootStore.appStore.currentOrgId, e)
    )
  }

  @computed
  public get allCurrentOrgUsers(): Array<AudienceMember> {
    if (!this.allUsers) return []
    return this.allUsers.map((e) =>
      AudienceMember.fromAudienceUserResult(this.rootStore.appStore.currentOrgId, e)
    )
  }

  @computed
  public get currentOrgUsers(): Array<AudienceMember> {
    return this.users.map((e) =>
      AudienceMember.fromAudienceUserResult(this.rootStore.appStore.currentOrgId, e)
    )
  }

  @computed
  public get currentOrgRoles() {
    return this.roles.map((e) =>
      AudienceMember.fromAudienceRoleResult(this.rootStore.appStore.currentOrgId, e)
    )
  }

  @computed
  public get currentOrgGroups() {
    return this.groups.map((e) =>
      AudienceMember.fromAudienceGroupResult(this.rootStore.appStore.currentOrgId, e)
    )
  }

  public currentOrgUsersInRole(roleId: string, keywordFilter: string = ''): Array<AudienceMember> {
    const foundRole = this.roles.find((e) => e.objectId === roleId)
    if (!foundRole) return []
    if (!foundRole.users) return []
    return foundRole.users.map((e) =>
      AudienceMember.fromAudienceUserResult(this.rootStore.appStore.currentOrgId, e)
    )
  }

  public currentOrgUsersInGroup(
    groupId: string,
    keywordFilter: string = ''
  ): Array<AudienceMember> {
    const foundGroup = this.groups.find((e) => e.objectId === groupId)
    if (!foundGroup) return []
    if (!foundGroup.users) return []
    return foundGroup.users.map((e) =>
      AudienceMember.fromAudienceUserResult(this.rootStore.appStore.currentOrgId, e)
    )
  }

  public getUser(objectId): AudienceMember {
    return this.allCurrentOrgUsers.find((e) => e.objectId === objectId)
  }

  public getAudienceMember(objectId): AudienceMember {
    return this.audienceMembers.find((e) => e.objectId === objectId)
  }

  public getAudienceMemberByName(name): AudienceMember {
    return this.audienceMembers.find((e) => e.name === name)
  }

  @action
  public clearData() {
    this.users = []
    this.roles = []
    this.groups = []
    this.allUsers = []
    this.allRoles = []
    this.allGroups = []
  }
}
