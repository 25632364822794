import Parse from 'parse'
import { observable, action, computed, runInAction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import SurveyType from './aggregate/SurveyType'
import WidgetForm from './forms/WidgetForm'
import { ISurveyTypeDTO } from './dtos/ISurveyTypeDTO'
import { DataStore } from '../../shared/data/DataStore'

export class SurveyTypesStore extends DataStore<SurveyType, ISurveyTypeDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, SurveyType, 'surveyTypes', [
      'organizationId',
      'name',
      'description',
      'worksheetId',
      'isFeedbackType',
      'isDefaultSurveyType',
      'forPrompt',
      'allowMultipleForUsers',
      'responseForParticipant',
    ])
  }

  @observable public selectedSurveyTypeId: string = ''
  @observable public widgetForm: WidgetForm = null
  @observable public isWidgetDrawerOpen: boolean = false
  @observable public loaded: boolean = false

  protected getBaseQuery() {
    const query = new Parse.Query(this.className)
    query.containedIn('organizationId', [this.rootStore.appStore.currentOrgId, 'DEFAULT'])
    query.notEqualTo('isDeleted', true)
    return query
  }

  @computed
  public get surveyTypes(): SurveyType[] {
    return this.records
  }

  @computed
  public get defaultSurveyTypes(): SurveyType[] {
    return this.records.filter((e) => e.organizationId === 'DEFAULT')
  }

  @action
  public onListRecordsLoaded() {
    this.loaded = true
  }

  @action
  public setSelectedSurveyTypeId(id) {
    this.selectedSurveyTypeId = id
    this.widgetForm = new WidgetForm(this.rootStore, this.getSurveyType(id))
    this.openWidgetDrawer()
  }

  @action
  public addNewSurveyType() {
    this.selectedSurveyTypeId = null
    this.widgetForm = new WidgetForm(this.rootStore)
    console.log(this.widgetForm)
    this.openWidgetDrawer()
  }

  public getSurveyType(objectId): SurveyType {
    return this.surveyTypes.find((e) => e.objectId === objectId)
  }
  public getSurveyTypeByName(name): SurveyType {
    return this.surveyTypes.find((e) => e.name === name)
  }

  private getSurveyTypeIndex(objectId): number {
    return this.surveyTypes.findIndex((e) => e.objectId === objectId)
  }

  public deleteSurveyType(objectId) {
    this.surveyTypes.splice(this.getSurveyTypeIndex(objectId), 1)
  }

  @action public openWidgetDrawer() {
    this.isWidgetDrawerOpen = true
  }

  @action public closeWidgetDrawer() {
    this.isWidgetDrawerOpen = false
  }

  public clearForms() {
    this.selectedSurveyTypeId = ''
  }
}
