import Parse from 'parse'
import { ICustomCollectionDTO } from '../dto/ICustomCollectionDTO'
import axios from 'axios'
import { ICustomRecordsQueryResult } from '../interfaces/ICustomRecordsQueryResult'
import { ICustomRecordDTO } from '../dto/ICustomRecordDTO'
import { RootStore } from 'src/app/stores/RootStore'
import { CustomCollection } from '../aggregate/CustomCollection'
import { values } from 'mobx'
import { ServiceUtils } from '../../shared/ag-grid/ServiceUtils'

export class CustomCollectionsService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public async checkUniqueName(name: string, currentId: string, orgId: string): Promise<boolean> {
    const query = new Parse.Query('customCollections')
    query.equalTo('name', name)
    query.equalTo('organizationId', orgId)
    query.equalTo('isDeleted', false)
    const result = await query.first()
    if (result && result.id !== currentId) {
      console.log('not unique')
      return false
    }

    return true
  }

  public async importCustomCollectionFromCsv(orgId, parseFile) {
    const result = await Parse.Cloud.run('importCustomCollectionFromCsv', {
      orgId,
      parseFile,
    })
    return result
  }

  // trigger dw to begin import
  public async dwImport(customCollectionId, orgId) {
    const result = await Parse.Cloud.run('dwImport', {
      orgId,
      customCollectionId,
    })

    return result
  }

  public async importCustomRecordsFromCsv(orgId, customCollectionId, parseFile, doSave, batchId) {
    const result = await Parse.Cloud.run('importCustomRecordsFromCsv', {
      orgId,
      customCollectionId,
      parseFile,
      doSave,
      batchId,
    })
    return result
  }

  public async getAIModelStatus() {
    const result = await Parse.Cloud.run('getAIModelStatus')
    return result
  }

  public async saveCustomCollection(customCollection: ICustomCollectionDTO, orgId: string) {
    const result = await Parse.Cloud.run('saveCustomCollection', { customCollection, orgId })
    return result
  }

  public async deleteCustomRecords(ids: string[], customCollectionId, orgId) {
    const result = await Parse.Cloud.run('deleteCustomRecords', {
      ids,
      customCollectionId,
      orgId,
    })

    return result
  }

  public async saveCustomRecords(records: any[], customCollectionId, orgId) {
    const result = await Parse.Cloud.run('saveCustomRecords', {
      records,
      customCollectionId,
      orgId,
    })

    return result
  }

  public async getCustomRecords(
    customCollection: CustomCollection,
    filterModel: any[] = [],
    sortModel: any[] = [],
    skip: number = 0,
    limit: number = 100
  ): Promise<ICustomRecordsQueryResult> {
    const query = new Parse.Query(customCollection.collectionName)
    customCollection.fields.forEach((field) => {
      if (field.type === 'User') {
        query.include(field.fieldName)
      }
    })

    query.equalTo('isDeleted', false)
    ServiceUtils.addFilter(query, filterModel)

    ServiceUtils.addSort(query, sortModel)

    query.skip(skip)
    query.limit(limit)

    let response = await query.find()

    const customRecords = response.map((e) => e.toJSON())
    return {
      success: true,
      customRecords: customRecords as unknown as ICustomRecordDTO[],
    }
  }
}
