import React from 'react'
import { IndexRoute, Route } from 'react-router'
import Login from '../../auth/views/Login'
import ForgotPassword from '../../auth/views/ForgotPassword'
import NoOrganizationsFound from '../../auth/views/NoOrganizationsFound'
import AuthSpinner from '../../auth/views/Spinner'
import PasswordResetScreen from '../../auth/views/PasswordResetScreen'
import WelcomeScreen from '../../auth/views/WelcomeScreen'
import OIDCCallback from '../../auth/views/OIDCCallback'

export default function getAuthRoutes() {
  return (
    <Route key={'auth0'} path='auth'>
      <IndexRoute onEnter={(nextState, replace) => replace('/auth/login')} />
      <Route key={'auth1'} component={Login} path='login' />
      <Route key={'auth1.1'} component={Login} path='login/task/:userTaskId/org/:orgId' />
      <Route key={'auth2'} component={ForgotPassword} path='forgot' />
      <Route key={'auth3'} component={NoOrganizationsFound} path='/auth/login/organization/404/' />
      <Route key={'auth4'} component={PasswordResetScreen} path='passwordReset/:token' />
      <Route key={'auth5'} component={PasswordResetScreen} path='passwordReset/expired/:token' />
      <Route key={'auth7'} component={PasswordResetScreen} path='passwordReset/:token/:mobileAppId' />
      <Route key={'auth6'} component={WelcomeScreen} path='welcome/:orgId' />
      <Route key={'authorization-code'} component={OIDCCallback} path='oidclogin' />
      <Route key={'spinner'} component={AuthSpinner} path='spinner' />
      <Route key={'authpassword'} component={Login} path='login/password' />
    </Route>
  )
}
