import React, { FC, useState, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { SurveysStore } from '../../../store/SurveysStore'
import CloseIcon from '@material-ui/icons/Close'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import FilterListIcon from '@material-ui/icons/FilterList'
import CatalogQuestion from './QuestionRow'
import Searchbar from '../../../../shared/Searchbar'
import Drawer from '@material-ui/core/Drawer'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { AgGridReact } from 'ag-grid-react'
import { QuestionRowVM } from '../../../view-models/edit/questions-catalog/QuestionRowVM'
import QuestionTypesFilter from '../../../../questions/widgets/QuestionTypesFilter'
import 'react-dropdown-tree-select/dist/styles.css'
import './QuestionsCatalogDrawer.scss'
import CategoryPicker from '../../../../shared/category-picker/CategoryPicker'

interface Props {
  surveysStore?: SurveysStore
  localizationStore?: LocalizationStore
}

const QuestionCatalogDrawer: FC<Props> = ({ surveysStore, localizationStore }) => {
  const [categoryAnchorEl, setCategoryAnchorEl] = useState(null)
  const { questionsCatalogWidget: strings } = localizationStore.lzStrings
  const { editVM } = surveysStore.viewModels
  if (!editVM) return null
  const vm = editVM.questionsCatalogVM
  if (!vm) return null

  const renderHeader = () => {
    return (
      <Grid item xs={12} className='question-catalog-header'>
        <Typography variant='h5'>{strings.add_from}</Typography>
        <IconButton disableRipple onClick={() => vm.close()} className='modal-close'>
          <CloseIcon />
        </IconButton>
      </Grid>
    )
  }

  const renderToolbar = () => {
    return (
      <div className='question-catalog-toolbar'>
        <div className='question-catalog-toolbar-left'>
          <QuestionTypesFilter vm={vm.typesFilterVM} />
          &nbsp;
          <CategoryPicker
            onApply={(categoryIds: string[]) => vm.reload()}
            vm={vm.categoryPickerVM}
            mode='multiSelect'
            renderCustomButtonComponent={() => (
              <Button
                variant='outlined'
                startIcon={<FilterListIcon />}
                onClick={(e) => {
                  vm.categoryPickerVM.toggleShowPicker()
                }}
              >
                {vm.categoryPickerVM.categoryLabel} (
                {vm.categoryPickerVM.selectAllChecked
                  ? 'All'
                  : vm.categoryPickerVM.selectedCategoryIds.length}
                )
              </Button>
            )}
          />
          <Searchbar
            searchValue={vm.typedFilterText}
            onSearchChange={(e) => vm.setQuickFilter(e.target.value)}
            className='question-catalog-searchbar'
            focused={true}
          />
        </div>
        <div className='question-catalog-toolbar-right'>
          <Button variant='text' size='large' disableRipple onClick={() => vm.close()}>
            Cancel
          </Button>
          <Button
            variant='contained'
            size='large'
            disabled={vm.selectedQuestions.length === 0}
            onClick={() => vm.addSelectedQuestions()}
          >
            Add ({vm.selectedQuestions.length})
          </Button>
        </div>
      </div>
    )
  }

  const renderQuestionCard = (props) => {
    const row: QuestionRowVM = props.data
    if (!row) return null
    return <CatalogQuestion question={row} index={props.rowIndex} style={undefined} />
  }

  const renderGrid = () => {
    return (
      <div
        className='ag-theme-alpine'
        style={{
          width: '100%',
          // display: qcVM.shown ? undefined : 'none',
        }}
      >
        <AgGridReact
          gridOptions={{
            ...vm.getGridOptions(),
            frameworkComponents: {
              questionCard: renderQuestionCard,
            },
          }}
        />
      </div>
    )
  }

  return (
    <Drawer anchor='right' open={vm.shown} onClose={() => vm.close()} id='QuestionsCatalogDrawer'>
      {renderHeader()}
      {renderToolbar()}
      {renderGrid()}
    </Drawer>
  )
}

export default inject('surveysStore', 'localizationStore')(observer(QuestionCatalogDrawer))
