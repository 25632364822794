import { observable } from 'mobx'
import { deserialize, serializable, serialize } from 'serializr'
import uuid from 'uuid/v4'
import { IExerciseInfoDTO } from '../dto/IExerciseInfoDTO'

export class ExerciseInfo implements IExerciseInfoDTO {
  public static create(): ExerciseInfo {
    const content = new ExerciseInfo()
    content.id = uuid()
    return content
  }

  @serializable @observable id: string = ''
  @serializable @observable sets: number = 0
  @serializable @observable reps: number = 0
  @serializable @observable weight: number = 0
  @serializable @observable time: number = 0
  @serializable @observable percent: number = 0

  public serialize() {
    const obj = serialize(this)
    return obj
  }

  public clone(): ExerciseInfo {
    return deserialize(ExerciseInfo, this.serialize())
  }
}
