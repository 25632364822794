import React, { useState, useEffect } from 'react'
import { Grid, CircularProgress } from '@material-ui/core'
import { observer, inject } from 'mobx-react'
import { SurveysStore } from '../../../store/SurveysStore'
import TableauReport from '../../../../worksheets/views/TableauReport'
import { TableauScriptService } from '../../../../tableau/service/TableauScriptService'
import './AnalysisTabPanel.scss'

interface Props {
  surveysStore?: SurveysStore
}

const AnalysisTabPanel: React.FC<Props> = ({ surveysStore }) => {
  const [scriptLoaded, setScriptLoaded] = useState(false)
  useEffect(() => {
    TableauScriptService.loadTableauScript(() => setScriptLoaded(true))
  })
  const { editVM } = surveysStore.viewModels
  if (!editVM) return null
  if (!editVM.analysis) return null
  // if (editVM.currentTabIndex !== SurveyEditTabsEnum.ANALYSIS) return null

  const analysisVM = editVM.analysis

  const renderReport = () => {
    if (analysisVM.width === 0) return null
    if (!analysisVM.worksheetId) return null
    if (!analysisVM.hasResponses) return null
    if (!analysisVM.tableauViewUrl) return null
    if (!scriptLoaded) return null
    return (
      <TableauReport
        key={analysisVM.tableauViewUrl + analysisVM.tableauAuthToken + analysisVM.tableauQuery}
        token={analysisVM.tableauAuthToken}
        onVizLoaded={(div, viz) => analysisVM.setTableauVizContainer(div, viz)}
        query={analysisVM.tableauQuery}
        options={{
          width: analysisVM.width.toString() + 'px',
          height: analysisVM.height.toString() + 'px',
          device: 'desktop',
          hideTabs: !analysisVM.showTabs,
        }}
        url={analysisVM.tableauViewUrl}
      />
    )
  }

  const renderNoResponses = () => {
    if (!analysisVM.showNoResponsesMsg) return null
    return <div className='no-responses'>No Responses Found</div>
  }

  const renderNoWorksheetMessage = () => {
    if (!analysisVM.showNoWorksheetMessage) return null
    return <div className='no-worksheet'>Please select a worksheet</div>
  }

  const renderSpinner = () => {
    if (!analysisVM.showSpinner) return null
    return (
      <CircularProgress color='secondary' style={{ position: 'fixed', top: '50%', left: '50%' }} />
    )
  }

  return (
    <Grid container id='AnalysisTabPanel' spacing={0}>
      {renderSpinner()}
      {renderNoResponses()}
      {renderNoWorksheetMessage()}
      <div id='TableauReportContainer'>{renderReport()}</div>
    </Grid>
  )
}

export default inject('surveysStore')(observer(AnalysisTabPanel))
