import { observable, action, computed } from 'mobx'
import moment from 'moment'
import { serializable, serialize, deserialize, date } from 'serializr'
import { IParticipantDueDateDTO } from '../dtos/IParticipantDueDateDTO'

export class ParticipantDueDate implements IParticipantDueDateDTO {
  public static create(participantId: string): ParticipantDueDate {
    const participantDueDate = new ParticipantDueDate()
    participantDueDate.participantId = participantId
    const now = moment()
    const remainder = 15 - (now.minute() % 15)
    const currentDateRoundedToNext15Mins = now.add(remainder, 'minutes')
    participantDueDate.dueDate = currentDateRoundedToNext15Mins.toISOString()
    return participantDueDate
  }

  @serializable @observable public participantId: string = ''
  @serializable @observable public dueDate: string = ''

  @computed
  public get fullDate(): Date {
    return moment(this.dueDate).toDate()
  }

  @action
  public setDueDate(val: Date) {
    this.dueDate = moment(val).toISOString()
  }
}
