import { action, computed, observable } from 'mobx'
import { PulseQuestionEditVM } from '../../pulse-questions/view-models/PulseQuestionEditVM'
import { RootStore } from '../../stores/RootStore'
import { PulseSet } from '../aggregate/PulseSet'

export class PulseSetVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, parentVM: PulseQuestionEditVM, pulseSet: PulseSet) {
    this.rootStore = rootStore
    this.pulseSet = pulseSet
    this.parentVM = parentVM
  }

  @observable public parentVM: PulseQuestionEditVM = null
  @observable public pulseSet: PulseSet = null

  @computed
  public get objectId(): string {
    return this.pulseSet.objectId
  }

  @computed
  public get name(): string {
    return this.pulseSet.name
  }

  @computed
  public get isChecked(): boolean {
    return Boolean(this.parentVM.pulseQuestion.pulseSets[this.objectId])
  }

  @action
  public toggleChecked() {
    this.parentVM.pulseQuestion.togglePulseSet(this.objectId, this.name)
  }
}
