import { action, computed, observable, when } from 'mobx'
import ILZstrings from 'src/app/localization/ILZstrings'
import { RootStore } from '../../stores/RootStore'
import { PulseCampaign } from '../aggregate/PulseCampaign'
import { ICampaignTemplateFindRequest } from '../interfaces/ICampaignTemplateFindRequest'
import { PulseCampaignTemplatesListDataStore } from './dashboard/PulseCampaignTemplatesListDataStore'
import { PulseCampaignSurveyEditVM } from './PulseCampaignSurveyEditVM'

export class PulseCampaignTemplatesVM {
  private rootStore: RootStore
  public dataStore: PulseCampaignTemplatesListDataStore
  private lzStrings: ILZstrings

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.lzStrings = rootStore.localizationStore.lzStrings
    this.dataStore = new PulseCampaignTemplatesListDataStore(this.rootStore, {
      organizationId: this.rootStore.appStore.currentOrgId,
    } as ICampaignTemplateFindRequest)
    this.dataStore.loadListRecords()

    when(
      () => this.dataStore.isLoaded,
      () => {
        if (!rootStore.pulseCampaignsStore.viewModels.editVM) return
        if (!rootStore.pulseCampaignsStore.viewModels.editVM.isPreview) return
        if (!this.selectedTemplate && this.dataStore.templates.length > 0)
          this.handleSelectTemplate(this.dataStore.templates[0])
      }
    )
  }

  @observable public typedFilterText: string = ''
  @observable public template: PulseCampaign = null
  private candidateTemplate: PulseCampaign = null
  @observable public currentSurveyIndex: number = -1
  @observable public isLoading: boolean = false
  @observable public confirmSwitchTemplate: boolean = false

  @computed
  public get isSystemAdmin(): boolean {
    return this.rootStore.appStore.isSystemAdmin
  }

  @computed
  public get canManage() {
    return this.isSystemAdmin
  }

  @action
  public async proceedSelectTemplate() {
    const editVM = this.rootStore.pulseCampaignsStore.viewModels.editVM
    editVM.confirmSaveDialogOpen = false
    this.confirmSwitchTemplate = false

    if (!this.candidateTemplate) return

    this.template = this.candidateTemplate
    this.isLoading = true
    editVM.isLoading = true
    this.rootStore.pulseCampaignsStore.viewModels.loadPulseCampaignTemplatePreview(
      this.template.objectId
    )
  }

  @action
  public async handleSelectTemplate(pulseCampaign: PulseCampaign, fromSwitcher?: boolean) {
    if (pulseCampaign?.name === this.lzStrings.pulseCampaign.add_new) return this.addNewTemplate()
    if (this.template && pulseCampaign.objectId === this.template.objectId) return
    this.candidateTemplate = pulseCampaign.clone()
    if (fromSwitcher) {
      this.confirmSwitchTemplate = true
    }

    const editVM = this.rootStore.pulseCampaignsStore.viewModels.editVM
    if (editVM.isDirty) return editVM.toggleConfirmSave()

    this.template = this.candidateTemplate
    this.proceedSelectTemplate()
  }

  @action
  public async templateLoaded(pulseCampaign: PulseCampaign) {
    this.isLoading = false
    this.template = pulseCampaign
    this.candidateTemplate = pulseCampaign.clone()
    this.currentSurveyIndex = 0
  }

  @action
  public async reset() {
    this.isLoading = false
    this.candidateTemplate = this.template
    this.confirmSwitchTemplate = false
  }

  @action
  public createPulseTemplate() {
    this.rootStore.appStore.router.push('/pulse/v1/fromtemplate/edit/new')
  }

  @computed
  public get isLoaded(): boolean {
    return this.dataStore.isLoaded
  }

  @computed
  public get currentSurvey(): PulseCampaignSurveyEditVM {
    if (this.surveys.length === 0) return undefined
    const currentSurvey = this.surveys[this.currentSurveyIndex]
    currentSurvey.loadSurveyTemplate()
    return currentSurvey
  }

  @computed
  public get numberOfSurveys(): number {
    if (!this.template) return 0
    return this.template.surveys.length
  }

  @computed
  public get surveys(): Array<PulseCampaignSurveyEditVM> {
    if (!this.template) return []
    return this.template.surveys
      .filter((e) => !e.isDeleted)
      .map(
        (s) =>
          new PulseCampaignSurveyEditVM(
            this.rootStore,
            this.rootStore.pulseCampaignsStore.viewModels.editVM,
            s,
            true
          )
      )
  }

  @action
  public setCurrentSurveyIndex(idx: number) {
    this.currentSurveyIndex = idx
  }

  @computed
  public get selectedTemplate(): PulseCampaign {
    if (!this.template) return
    return this.getTemplate(this.template.objectId)
  }

  @action
  public addNewTemplate() {
    this.rootStore.pulseCampaignsStore.viewModels.createCampaignDialogVM.closeCreateCampaignDialog()
    this.rootStore.appStore.router.push(`/pulse/v1/fromtemplate/edit/new`)
  }

  @action
  public setQuickFilter(val: string) {
    this.typedFilterText = val
  }

  @computed
  public get hasTemplates(): boolean {
    return this.dataStore.hasListRecords
  }

  @computed
  public get templates() {
    return this.dataStore.templates
  }

  public getTemplate(objectId: string): PulseCampaign {
    return this.dataStore.templates.find((template) => template.objectId === objectId)
  }

  @action
  public previewSurvey() {
    this.rootStore.pulseCampaignsStore.viewModels.editVM.previewTemplateSurvey(
      this.currentSurveyIndex
    )
  }
}
