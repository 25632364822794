import { RootStore } from '../../stores/RootStore'
import { observable, computed, action } from 'mobx'
import { PagedDataVM } from '../../shared/view-models/PagedDataVM'
import { ManageGoalsWidgetRowVM } from './ManageGoalsWidgetRowVM'

export class ManageGoalsWidgetVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @observable public keywordFilter: string = ''
  @observable public deletingOrgId: string = ''

  @computed public get rows(): ManageGoalsWidgetRowVM[] {
    return this.rootStore.goalsStore.currentOrgGoals
      .filter((goal) => goal.createdByUserId === this.rootStore.appStore.currentUserId)
      .map((e) => new ManageGoalsWidgetRowVM(this.rootStore, this, e))
  }

  @computed
  public get pagedData(): PagedDataVM {
    return new PagedDataVM(
      this.rootStore,
      this.rows,
      ['name', 'category'],
      '',
      'ManageGoalsWidget',
      this.keywordFilter
    )
  }

  @action setKeywordFilter(val) {
    this.keywordFilter = val
    this.pagedData.setFilter(this.keywordFilter)
  }

  @action createNewGoal() {
    this.rootStore.appStore.router.push('/goals/edit/new')
  }
}
export default ManageGoalsWidgetVM
