import { Dialog } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { GoalsStore } from '../../../store/GoalsStore'
import AddPlans from './AddPlans'
import PreviewPlan from './PreviewPlan'

import './TrainingPlansModal.scss'

interface Props {
  goalsStore?: GoalsStore
}

const TrainingPlansModal: React.FC<Props> = ({ goalsStore }) => {
  const { goalEditVM: vm } = goalsStore
  if (!vm) return null

  const renderAddPlans = () => {
    if (vm.planToPreview) return null
    return <AddPlans />
  }

  const renderPlanPreview = () => {
    if (!vm.planToPreview) return null
    return <PreviewPlan />
  }
  return (
    <Dialog
      onClose={() => vm.closeTrainingPlansModal()}
      open={vm.trainingPlansModalOpen}
      id='TrainingPlansModal'
      fullWidth
    >
      {renderAddPlans()}
      {renderPlanPreview()}
    </Dialog>
  )
}

export default inject('goalsStore')(observer(TrainingPlansModal))
