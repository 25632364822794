import { observable, action } from 'mobx'
import { serializable, date, list, primitive, object, serialize } from 'serializr'
import { RepeatType } from '../types/RepeatTypes'
import { Days } from './Days'
import { IEventScheduleDTO } from '../dtos/IEventScheduleDTO'
import { EndingModeType } from '../types/EndingModeType'
import { ScheduledEvent } from './ScheduledEvent'
import { IAggregate } from '../../shared/data/IAggregate'
import { IDTO } from '../../shared/data/IDTO'
export class Schedule implements IEventScheduleDTO, IAggregate {
  @serializable @observable public objectId: string = ''
  @serializable @observable public eventId: string = ''
  @serializable @observable public enabled: boolean = false
  @serializable(object(Days)) @observable public days: Days = new Days()
  @serializable @observable public repeat: RepeatType = 'weekly'
  @serializable @observable public every: number = 1
  @serializable @observable public endingMode: EndingModeType = 'never'
  @serializable @observable public endDate: string = ''
  @serializable(list(object(ScheduledEvent))) @observable public events: ScheduledEvent[] = []
  @serializable @observable public loaded: boolean = false
  @serializable @observable public isDeleted: boolean
  @serializable @observable public isOnServer: boolean

  @action
  public markAsNotLoaded() {
    this.loaded = false
  }

  @action
  public markAsLoaded() {
    this.loaded = true
  }

  @action
  public setEndingMode(val: EndingModeType) {
    this.endingMode = val
  }

  @action
  public setEvery(val: number) {
    this.every = val
  }

  @action
  public setRepeatType(val: RepeatType) {
    this.repeat = val
  }

  @action
  public toggleWeekdayRepeat(val: string) {
    this.days[val] = !this.days[val]
  }

  @action
  public setScheduleEndDate(val) {
    this.endDate = val
  }

  @action
  public toggleScheduleEnabled() {
    this.enabled = !this.enabled
  }

  public markIsOnServer(): void {
    this.isOnServer = true
  }

  public markIsNotOnServer(): void {
    this.isOnServer = false
  }

  public serialize(): IDTO {
    return serialize(this)
  }
}
