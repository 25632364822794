import React from 'react'
import { inject, observer, useLocalStore } from 'mobx-react'
import { Button, ButtonBase, Card, Checkbox, Dialog, IconButton, MenuItem, Select, Switch, TextField, Tooltip, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import './PulseSetEditModal.scss'
import { PulseSetsStore } from '../../store/PulseSetsStore'
import MuiConfirmDeleteDialog from '../../../shared/MuiConfirmDeleteDialog'
import Autocomplete from '@material-ui/lab/Autocomplete'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import CheckRounded from '@material-ui/icons/CheckCircleRounded'
import { Organization } from '../../../organizations-sa/aggregate/Organization'
import { PulseCampaign } from '../../../pulse-campaigns/aggregate/PulseCampaign'
import LocalizationStore from '../../../localization/LocalizationStore'

interface Props {
  pulseSetsStore?: PulseSetsStore
  localizationStore?: LocalizationStore
}

const PulseSetEditModal: React.FC<Props> = ({ pulseSetsStore, localizationStore }) => {
  const vm = pulseSetsStore.viewModels.editVM
  if (!vm) return null
  const lz = localizationStore.lzStrings.pulseSetMGMT

  const renderDialogTitle = () => {
    return (
      <div className={'dialog-header'}>
        <div className='dialog-left'>
          <Typography className={'dialog-title'}>{lz.pulse_set_details}</Typography>
        </div>
        <div className='dialog-right'>
          {renderDeleteButton()}
          <Button variant='contained' onClick={() => vm.save()}>
            {lz.save}
          </Button>
          <IconButton onClick={() => vm.closeEditModal()}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    )
  }

  const renderDeleteButton = () => {
    if (vm.isNew) return null
    return (
      <Button variant='text' className={'delete-btn'} onClick={() => vm.toggleConfirmDelete()}>
        {lz.delete}
      </Button>
    )
  }

  const renderName = () => {
    return (
      <div className='set-name'>
        <Typography className='set-header'>{lz.name}</Typography>
        <TextField
          error={!vm.nameValid}
          autoFocus
          id='outlined-name'
          className={'name-input'}
          value={vm.name}
          onChange={(e) => vm.setName(e.target.value)}
          margin='none'
          fullWidth
          variant='outlined'
        />
      </div >
    )
  }

  const renderOrgs = () => {
    return (
      <div className='set-orgs'>
        <Typography className='set-header'>{lz.organizations}</Typography>
        <Autocomplete
          className='orgs-input toolbar-input'
          value={vm.pulseSetOrgs.slice()}
          multiple
          disableCloseOnSelect
          filterSelectedOptions
          options={vm.availableOrgs.slice()}
          getOptionLabel={(e) => e.name}
          onChange={(e, arr: Organization[]) => vm.setSelectedOrgs(arr)}
          renderInput={(params) => (
            <TextField
              placeholder={vm.pulseSetOrgs.length < 1 ? lz.select_organizations : ''}
              {...params}
              variant='outlined'
              className={'org-input-textfield'}
            />
          )
          }
        />
      </div >
    )
  }

  const renderTemplates = () => {
    return (
      <div className='set-templates'>
        <Typography className='set-header'>{lz.templates}</Typography>
        {renderCampaignTemplates()}
        {renderAddTemplateSelect()}
      </div >
    )
  }

  const renderAddTemplateSelect = () => {
    return (
      <div className={'pulse-set-template-select'}>
        <Autocomplete
          className='templates-input toolbar-input'
          value={vm.selectedAddTemplates.slice()}
          multiple
          disableCloseOnSelect
          filterSelectedOptions
          options={vm.availableTemplates.slice()}
          getOptionLabel={(e) => e.name}
          onChange={(e, templates: PulseCampaign[]) => vm.setSelectedAddTemplates(templates)}
          renderInput={(params) => (
            <TextField
            placeholder={vm.selectedAddTemplates.length < 1 ? lz.add_templates : ''}
            {...params}
              variant='outlined'
              className={'template-input-textfield'}
            />
          )
          }
        />
        <Tooltip title={`Add`} placement='bottom-start' enterDelay={500}>
          <IconButton
            size='small'
            className={'pulse-set-template-save-button'}
            color='secondary'
            onClick={() => vm.addCampaignTemplate()}
          >
            <CheckRounded />
          </IconButton>
        </Tooltip>
      </div>
    )
  }

  const renderCampaignTemplates = () => {
    const templates = []
    vm.pulseSetTemplates.forEach((template: PulseCampaign, i: number) => {
      templates.push(
        <Card key={'template' + i} className={'template-container'}>
          <div>
            <Typography className={'template-name'}>{template.name}</Typography>
            <div className={'surveys-container'}>
              {renderSurveys(template)}
            </div>
          </div>
          <IconButton
            disableRipple
            onClick={(e) => vm.removeCampaignTemplate(template.objectId)}
            className={'icon-container'}
          >
            <CloseIcon />
          </IconButton>
        </Card>
      )
    })
    return templates
  }

  const renderSurveys = (template) => {
    const surveyNames = template.surveys.map((s) => s.name)
    return <Typography className={'survey-name'}>{surveyNames.join(', ')}</Typography>
  }


  const renderConfirmDelete = () => {
    return (
      <MuiConfirmDeleteDialog
        itemType='Campaign Template Set'
        itemName={vm.name}
        open={vm.showConfirmDelete}
        onConfirm={() => vm.delete()}
        onClose={() => vm.toggleConfirmDelete()}
        additionalDetails={lz.confirm_delete}
      />
    )
  }

  const renderMainDetails = () => {
    return (
      <>
        {renderConfirmDelete()}
        {renderName()}
        {renderOrgs()}
        {renderTemplates()}
      </>
    )
  }

  return (
    <Dialog
      id='PulseSetEditModal'
      open={true}
      onClose={() => vm.closeEditModal()}
      fullWidth
    >
      <div className={'dialog-container'}>
        {renderDialogTitle()}
      </div>
      <div className={'dialog-content'}>
        {renderMainDetails()}
      </div>
    </Dialog>
  )
}

export default inject('pulseSetsStore', 'localizationStore')(observer(PulseSetEditModal))
