import { RootStore } from '../../../stores/RootStore'
import { action, computed, observable } from 'mobx'
import { SurveyResponse } from '../../aggregate/SurveyResponse'
import { RowVM } from './RowVM'
import { GridApi, GridReadyEvent, ColDef, GridOptions, RowClickedEvent } from 'ag-grid-community'
import { nameOf } from '../../../shared/nameOf'
import { AGGridVM } from '../../../shared/ag-grid/AGGridVM'

export class WidgetVM extends AGGridVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    super()
    this.rootStore = rootStore
  }

  @computed
  public get rows(): RowVM[] {
    return this.rootStore.completedSurveyResponsesStore.currentOrgCompletedSurveyResponses.map(
      (e) => new RowVM(this.rootStore, this, e)
    )
  }

  public getRowNodeId(row: RowVM): string {
    return row.objectId
  }

  protected get columnDefs(): ColDef[] {
    const s = this.rootStore.localizationStore.lzStrings
    return [
      {
        headerName: s.completedSurveysWidget.name,
        field: nameOf<RowVM, string>((e) => e.name),
        cellClass: 'pending-user-survey-name-cell',
      },
      {
        headerName: s.completedSurveysWidget.from,
        field: nameOf<RowVM, string>((e) => e.fromUserName),
        cellRenderer: 'fromCell',
      },
      {
        headerName: s.completedSurveysWidget.questions,
        field: nameOf<RowVM, number>((e) => e.questionsCount),
      },
      {
        headerName: s.completedSurveysWidget.responded_on,
        field: nameOf<RowVM, string>((e) => e.respondedAtFormatted),
        comparator: this.dateComparator,
        sort: 'desc',
      },
    ]
  }

  @computed
  public get shouldRender(): boolean {
    // if (!this.rootStore.usersStore.loaded) return false
    // if (!this.rootStore.categoriesStore.loaded) return false
    if (!this.rootStore.labelsStore.loaded) return false
    return true
  }

  public getGridOptions(): GridOptions {
    return {
      ...this.baseGridOptions,
      rowData: this.rows,
      onRowClicked: (e) => this.rowClicked(e),
      getRowNodeId: (e) => this.getRowNodeId(e),
      columnDefs: this.ensureTooltipFields(this.columnDefs),
    }
  }

  public rowClicked(e: RowClickedEvent) {
    const row: RowVM = e.data
    if (!row) return
    row.openUserSurvey()
  }
}
