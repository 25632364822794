import { RootStore } from '../../stores/RootStore'
import { IParticipant } from '../interfaces/IParticipant'
import { AvatarUserMinimum } from '../../shared/Avatar'
import { ParticipantType } from '../types/ParticipantType'

export class ParticipantVM {
  private rootStore: RootStore

  constructor(rootStore, participant: IParticipant = null) {
    this.rootStore = rootStore
    if (participant) this.loadData(participant)
  }

  private loadData(participant: IParticipant) {
    this.id = this.getId(participant)
    this.type = participant.type
    this.name = this.findParticipantName(participant)
    this.isArchived = this.findParticipantArchivedStatus(participant)
  }

  public id: string = ''
  public name: string = ''
  public type: ParticipantType = null
  public isArchived: boolean = false

  public get iconURL(): string {
    if (!this.user) return ''
    if (!this.user.iconURL) return ''
    return this.user.iconURL
  }

  public get objectId(): string {
    if (!this.user) return ''
    if (!this.user.objectId) return ''
    return this.user.objectId
  }

  private getId(participant: IParticipant): string {
    if (participant.objectId) return participant.objectId
    return participant.id
  }

  private findParticipantName(participant: IParticipant): string {
    if (this.type === 'group') {
      const foundGroup = this.rootStore.groupsStore.getGroup(this.id)
      if (foundGroup) return foundGroup.name
    }
    if (this.type === 'role') {
      const foundRole = this.rootStore.rolesStore.getRole(this.id)
      if (foundRole) return foundRole.name
    }
    if (this.type === 'client') {
      const foundClient = this.rootStore.clientsStore.getClient(this.id)
      if (foundClient) return foundClient.name
    }
    if (this.type === 'user') {
      const foundUser = this.rootStore.audienceMembersStore.getUser(this.id)
      if (foundUser) return foundUser.name
    }
    if (!participant.name) return ''
    if (typeof participant.name !== 'string') return ''
    return participant.name
  }

  private findParticipantArchivedStatus(participant: IParticipant): boolean {
    if (this.type === 'user') {
      const foundUser = this.rootStore.audienceMembersStore.getUser(this.id)
      if (foundUser) return foundUser.isArchived
    }
    if (!participant.isArchived) return false
    if (typeof participant.isArchived !== 'boolean') return false
    return participant.isArchived
  }

  public get user() {
    if (this.type !== 'user') return undefined
    const foundUser = this.rootStore.audienceMembersStore.getUser(this.id)
    if (!foundUser) {
      const avatarUser: AvatarUserMinimum = { name: this.name, iconURL: '', objectId: this.id }
      return avatarUser
    }
    return foundUser
  }

  public get key(): string {
    return this.id + this.type
  }

  public get chipLabel() {
    if (this.type !== 'user') return this.name
    return this.user && this.user.name ? this.user.name : this.name
  }

  public get explodable(): boolean {
    if (!this.type) return false
    if (this.type === 'contact') return false
    if (this.type === 'user') return false
    return true
  }

  public get displayName(): string {
    if (this.type === 'user') return this.name
    return this.displayNameWithType
  }

  public get typeName(): string {
    return this.type.charAt(0).toUpperCase() + this.type.slice(1)
  }

  public get displayNameWithType(): string {
    return this.typeName + ': ' + this.name
  }

  public get sortName(): string {
    return this.displayName.toLowerCase()
  }

  public get sortNameWithType(): string {
    return this.displayNameWithType.toLowerCase()
  }

  public toDTO(): IParticipant {
    return {
      id: this.id,
      type: this.type,
      name: this.name,
      iconURL: this.iconURL,
      isArchived: this.isArchived,
      objectId: this.id,
    }
  }
}
