import React from 'react'
import { inject, Observer, observer } from 'mobx-react'
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
} from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import DeleteIcon from '@material-ui/icons/Delete'
import ArchiveIcon from '@material-ui/icons/Archive'
import FileCopyIcon from '@material-ui/icons/FileCopyRounded'
import { SurveysStore } from '../../../store/SurveysStore'
import { QuestionsStore } from '../../../../questions/store/QuestionsStore'
import CloseIcon from '@material-ui/icons/Close'
import Snackbar from '@material-ui/core/Snackbar'

interface Props {
  surveysStore?: SurveysStore
  questionsStore?: QuestionsStore
}

const QuestionMenu: React.FC<Props> = ({ surveysStore, questionsStore }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const { editVM } = surveysStore.viewModels
  if (!editVM) return null

  let isMenuOpen = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => setAnchorEl(null)

  const renderAddToCatalogOption = () => {
    if (!editVM.currentQuestion) return
    if (!editVM.currentQuestion.canAddToCatalog) return
    return (
      <MenuItem
        onClick={() => {
          editVM.currentQuestion.addToCatalog()
          handleClose()
        }}
      >
        <ListItemIcon className='blue'>
          <ArchiveIcon />
        </ListItemIcon>
        <ListItemText primary='Add to Catalog' />
      </MenuItem>
    )
  }

  const renderSnackbar = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={editVM.isSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => editVM.closeSnackbar()}
        message={<Typography>{editVM.snackbarMessage}</Typography>}
        action={[
          <Observer key={'1'}>
            {() => (
              <IconButton
                key='close'
                aria-label='close'
                color='inherit'
                onClick={() => editVM.closeSnackbar()}
              >
                <CloseIcon />
              </IconButton>
            )}
          </Observer>,
        ]}
      />
    )
  }

  return (
    <div>
      <IconButton
        id='MoreButton'
        disabled={editVM.isReadOnly}
        onClick={handleClick}
        className={isMenuOpen ? 'focused' : ''}
      >
        <MoreHorizIcon />
      </IconButton>

      <Menu
        id='MoreMenu'
        elevation={2}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {editVM.currentQuestionIndex === 0 ? null : (
          <MenuItem onClick={() => editVM.moveQuestionUp()}>
            <ListItemIcon>
              <ArrowUpwardIcon />
            </ListItemIcon>
            <ListItemText primary='Move Up' />
          </MenuItem>
        )}
        {editVM.currentQuestionIndex >= editVM.questions.length - 1 ? null : (
          <MenuItem onClick={() => editVM.moveQuestionDown()}>
            <ListItemIcon>
              <ArrowDownwardIcon />
            </ListItemIcon>
            <ListItemText primary='Move Down' />
          </MenuItem>
        )}
        {renderAddToCatalogOption()}
        <MenuItem
          onClick={() => {
            editVM.copyQuestion()
            handleClose()
          }}
        >
          <ListItemIcon className='blue'>
            <FileCopyIcon />
          </ListItemIcon>
          <ListItemText primary='Copy' />
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            editVM.deleteQuestion()
            handleClose()
          }}
        >
          <ListItemIcon className='red'>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText primary='Delete' />
        </MenuItem>
      </Menu>
      {renderSnackbar()}
    </div>
  )
}

export default inject('surveysStore', 'questionsStore')(observer(QuestionMenu))
