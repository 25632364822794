import React from 'react'
import { inject, observer, useLocalStore } from 'mobx-react'
import { GrandParentCategoryRowVM } from '../view-models/GrandParentCategoryRowVM'
import { Grid, IconButton, Typography } from '@material-ui/core'
import { ParentCategoryRowVM } from '../view-models/ParentCategoryRowVM'
import ParentCategoryRow from './ParentCategoryRow'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'

interface Props {
  vm: GrandParentCategoryRowVM
}

const GrandCategoryRow: React.FC<Props> = ({ vm }) => {
  if (!vm) return null

  const renderHeader = () => {
    return (
      <Grid item xs={12} className={'grand-row'}>
        <Typography className='grand-header'>{vm.name}</Typography>
        <div>
          {renderEditButton()}
          {renderAddButton()}
        </div>
      </Grid>
    )
  }

  const renderParentCategories = () => {
    const categories = []
    vm.parentPulseCategories.forEach((row: ParentCategoryRowVM, i) => {
      categories.push(<ParentCategoryRow key={i} vm={row} />)
    })
    return categories
  }

  const renderAddButton = () => {
    return (
      <IconButton
        disableRipple
        onClick={() => vm.createNewPulseCategory()}
        className={'add-icon-container'}
        size='small'
      >
        <AddIcon />
      </IconButton>)
  }

  const renderEditButton = () => {
    return (
      <IconButton
        disableRipple
        onClick={() => vm.editPulseCategory()}
        size='small'
      >
        <EditIcon />
      </IconButton>
    )
  }

  return (
    <div className='grand-container'>
      {renderHeader()}
      {renderParentCategories()}
    </div>
  )
}

export default inject()(observer(GrandCategoryRow))
