import { observable, action } from 'mobx'
import { IAggregate } from 'src/app/shared/data/IAggregate'
import { IRoleDTO } from '../dtos/IRoleDTO'
import { Category } from './Category'
import { serializable, serialize, list, object, primitive } from 'serializr'

export class Role implements IRoleDTO, IAggregate {
  static create(orgId) {
    const role = new Role()
    role.organizationId = orgId
    return role
  }

  @serializable @observable public objectId: string = ''
  @serializable @observable public defaultDashboardId: string = ''
  @serializable @observable public organizationId: string = ''
  @serializable @observable public description: string = ''
  // @serializable(list(object(Category))) @observable public categories: Array<Category>
  @serializable(list(primitive())) @observable public privilegeSets: Array<string>
  @serializable @observable public name: string = ''
  @serializable @observable public isDeleted: boolean = null
  @serializable @observable public forceDashboardRoute: boolean = false
  @serializable @observable public memberCount: number = 0
  public isOnServer: boolean = false
  public type: string = 'role'

  @action
  public markIsNotOnServer() {
    this.isOnServer = false
  }

  @action
  public markIsOnServer() {
    this.isOnServer = true
  }

  public serialize() {
    return serialize(this)
  }
}
