import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { TasksStore } from '../../store/TasksStore'
import { Grid, InputLabel, OutlinedInput } from '@material-ui/core'

interface DrawerTaskDetailsNameProps {
  localizationStore?: LocalizationStore
  tasksStore?: TasksStore
}

const DrawerTaskDetailsName: React.FC<DrawerTaskDetailsNameProps> = ({
  localizationStore,
  tasksStore,
}) => {
  const { lzStrings } = localizationStore
  const { taskEditVM: VM } = tasksStore

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    VM.setTaskName(e.target.value)
  }

  return (
    <Grid item xs={6}>
      <InputLabel htmlFor='taskName' required>
        {lzStrings.tasksManagementWidgetDrawer.task_name}
      </InputLabel>
      <OutlinedInput
        id='taskName'
        fullWidth
        labelWidth={0}
        value={VM.name}
        onChange={handleNameChange}
        error={VM.nameError}
        required
      />
    </Grid>
  )
}

export default inject('localizationStore', 'tasksStore')(observer(DrawerTaskDetailsName))
