import React, { Component } from 'react'
import { Checkbox, FormControlLabel, Tooltip } from '@material-ui/core'
import { observer } from 'mobx-react'
import { SurveyTemplatesTableVM } from '../view-models/SurveyTemplatesTableVM'

class SelectAll extends Component {
  public props: any
  public vm: SurveyTemplatesTableVM

  constructor(props) {
    super(props)
    this.vm = props.vm
  }

  renderCheckbox() {
    if (!this.vm) return null
    const disabled = Boolean(this.vm.manageVM.foldersVM?.selectedFolder?.id === 'no-folder' && this.vm.manageVM.screenType === 'permissions')
    let tooltip = 'Select all. New templates will automatically be subscribed.'
    if (disabled) tooltip = 'Select all is disabled for templates not in a folder.'
    if (this.vm.manageVM.screenType !== 'permissions') tooltip = 'Select all.'
    return (
      <>
        <Tooltip
          title={tooltip}
          placement='bottom-start'
        >
          <FormControlLabel
            control={
              <Checkbox
                disabled={disabled}
                color='primary'
                indeterminate={this.vm.allSelectedIndeterminate}
                onClick={() => this.vm.toggleSelectAll()}
                checked={this.vm.allSelectedChecked}
              />
            }
            label={''}
          />
        </Tooltip>
      </>
    )
  }

  render() {
    return this.renderCheckbox()
  }
}

export default observer(SelectAll)
