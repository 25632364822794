import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../localization/LocalizationStore'
import { InputLabel } from '@material-ui/core'
import CategoryPicker from '../../shared/category-picker/CategoryPicker'
import { QuestionsStore } from '../store/QuestionsStore'

interface Props {
  localizationStore?: LocalizationStore
  questionsStore?: QuestionsStore
}

const DrawerCategorySection: React.FC<Props> = ({ localizationStore, questionsStore }) => {
  const { lzStrings } = localizationStore
  const { editVM } = questionsStore

  return (
    <section>
      <InputLabel>{lzStrings.questionsCatalogWidgetDrawer.category}</InputLabel>
      <div className=''>
        <CategoryPicker
          fullWidth={true}
          // error={!editVM.categoryValid}
          onApply={(id) => editVM.setCategory(id)}
          vm={editVM.categoryPickerVM}
        />
      </div>
    </section>
  )
}

export default inject('localizationStore', 'questionsStore')(observer(DrawerCategorySection))
