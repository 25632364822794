import { observable, computed, action, reaction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { RoleVM } from './RoleVM'
import { PagedDataVM } from '../../shared/view-models/PagedDataVM'
import { ParseService } from '../../services/ParseService'
import { ImportWeightProfileVM } from '../view-models/ImportWeightProfileVM'
import { CategoryVM } from './CategoryVM'
import { any } from 'prop-types'

export class EditScreenVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.importWeightProfileVM = new ImportWeightProfileVM(this.rootStore, this)
  }

  @observable public keywordFilter: string = ''
  @observable public optionsKeywordFilter: string = ''
  @observable public showCopyOptions: boolean = false
  @observable public selectedCopyOptions: Array<any> = []
  @observable public showDeleteDialog: boolean = false
  @observable public showRevertDialog: boolean = false
  @observable public showImportDialog: boolean = false
  @observable public showCopyDialog: boolean = false
  @observable public importWeightProfileVM: ImportWeightProfileVM = null

  @computed
  public get copyOptionsPagedData() {
    return new PagedDataVM(
      this.rootStore,
      this.copyOptions,
      ['name'],
      '',
      'CategoriesScreen',
      this.optionsKeywordFilter
    )
  }

  @action
  public rowGetter(index: number) {
    if (this.categories[index].isSelected) {
      if (index === 0) return this.heightForRowsToDisplay
      return this.heightForRowsToDisplay
    }
    if (index === 0) return 150
    return 100
  }

  @computed
  public get selectedRoleName() {
    return this.selectedRole.name
  }

  @computed
  public get selected(): Array<CategoryVM> {
    let selected = []
    const topCat = this.categories.find((e) => e.isSelected)
    if (!topCat) return selected
    selected.push(topCat)
    const getSelected = (children: Array<CategoryVM>) => {
      let selectedChild = children.find((e) => e.isSelected)
      if (!selectedChild) return
      selected.push(selectedChild)
      if (selectedChild.hasChildren) getSelected(selectedChild.children)
    }
    if (topCat.hasChildren) getSelected(topCat.children)
    return selected
  }

  @computed
  public get heightForRowsToDisplay() {
    let rowsToDisplay = 0
    this.selected.forEach((e) => {
      if (e.hasChildren) {
        if (e.children.length > rowsToDisplay) rowsToDisplay = e.children.length
      }
    })
    if (rowsToDisplay === 0) return 100
    return rowsToDisplay * 100 + 50
  }

  @computed
  public get categories(): Array<CategoryVM> {
    let roots = []
    let nodes = {}

    const array = this.rootStore.categoriesStore.currentOrgCategories

    for (let i = 0, len = array.length; i < len; ++i) {
      let item = array[i],
        p = item.parentCategoryId,
        target = !p ? roots : nodes[p] || (nodes[p] = [])

      target.push(new CategoryVM(this.rootStore, this, item))
    }

    let findChildren = function (parent) {
      if (nodes[parent.objectId]) {
        parent.children = nodes[parent.objectId]
        parent.children.forEach((child) => {
          findChildren(child)
        })
      }
    }

    for (let i = 0, len = roots.length; i < len; ++i) {
      findChildren(roots[i])
    }

    return roots
  }

  @computed
  public get copyOptions(): Array<RoleVM> {
    if (!this.selectedRole) return []
    return this.roles.filter((e) => e.objectId !== this.selectedRole.objectId)
  }

  @computed
  public get roles(): Array<RoleVM> {
    if (!this.rootStore) return []
    if (!this.rootStore.rolesStore.roles) return []
    const availableRoles = this.rootStore.rolesStore.roles
    return availableRoles.map((role) => new RoleVM(this.rootStore, this, role)).sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0))
  }

  @computed
  public get rolesToRender() {
    if (!this.keywordFilter) return this.roles
    return this.roles.filter((e) => e.name.toLowerCase().includes(this.keywordFilter.toLowerCase()))
  }

  @computed
  public get selectedRole(): RoleVM {
    return this.roles.find((role) => role.isSelected)
  }

  @action
  public selectRole(roleId) {
    const selectedRole = this.roles.find((e) => e.isSelected && e.objectId !== roleId)
    if (selectedRole) selectedRole.toggleIsSelected()
    const foundRole = this.roles.find((e) => e.objectId === roleId)
    foundRole.toggleIsSelected()
  }

  @action
  public toggleShowCopyOptions() {
    this.showCopyOptions = !this.showCopyOptions
    if (!this.showCopyOptions) this.selectedCopyOptions = []
  }

  @action
  public setKeywordFilter(e) {
    this.keywordFilter = e
  }

  @action
  public setOptionsKeywordFilter(e) {
    this.optionsKeywordFilter = e
  }

  @action
  public toggleRoleToCopySelect(roleId) {
    if (this.selectedCopyOptions.includes(roleId)) {
      this.selectedCopyOptions = this.selectedCopyOptions.filter((e) => e !== roleId)
    } else {
      this.selectedCopyOptions.push(roleId)
    }
  }

  @action
  public toggleImportDialog() {
    this.showImportDialog = !this.showImportDialog
  }

  @action
  public toggleDeleteDialog() {
    this.showDeleteDialog = !this.showDeleteDialog
  }

  @action
  public toggleRevertDialog() {
    this.showRevertDialog = !this.showRevertDialog
  }

  @action
  public toggleCopyDialog() {
    this.showCopyDialog = !this.showCopyDialog
  }

  @action
  public revertWeightProfile() {
    this.selectedRole.revertWeightProfile()
    this.toggleRevertDialog()
  }

  @action
  public deleteWeightProfile() {
    this.selectedRole.deleteWeightProfile()
  }

  @action
  public saveWeightProfile() {
    this.selectedRole.saveWeightProfile()
  }

  @action
  public async copyWeightProfileToRoles() {
    this.toggleCopyDialog()

    const svc = new ParseService()
    await svc.copyWeightProfileToRoles(
      this.rootStore.appStore.currentOrgId,
      this.selectedRole.weightProfileVM.weightProfile.serialize().objectId,
      this.selectedCopyOptions.slice()
    )

    this.toggleShowCopyOptions()
    this.selectedCopyOptions = []

    // this.roles.forEach(role => {
    //   role.loadWeightProfileVM()
    // })

    this.selectedRole.weightProfileVM.toggleCopySnackbar()
  }
}
