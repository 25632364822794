import { action, computed, observable } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { UserImportVM } from './UserImportVM'

export class OrganizationUsersImportManageVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.importVM = new UserImportVM(this.rootStore)
  }

  @observable public importVM: UserImportVM
  @observable public isLoading: boolean = false
  @observable public isSnackbarOpen: boolean = false
  @observable public snackbarMessage: string = ''

  @computed
  public get shouldRender(): boolean {
    if (!this.importVM) return false
    return true
  }

  @computed
  public get canManage() {
    if (this.rootStore.appStore.isSystemAdmin) return true
    if (!this.rootStore.userStore.currentOrganization) return false
    return false
  }

  @action
  public openSnackbar(msg) {
    this.snackbarMessage = msg
    this.isSnackbarOpen = true
  }

  @action
  public closeSnackbar() {
    this.isSnackbarOpen = false
  }
}
