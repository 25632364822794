import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { ContactsStore } from '../../store/ContactsStore'
import { CardHeader, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

interface DrawerHeaderProps {
  localizationStore?: LocalizationStore
  contactsStore?: ContactsStore
}

const DrawerHeader: React.FC<DrawerHeaderProps> = ({ localizationStore, contactsStore }) => {
  const { lzStrings } = localizationStore

  return (
    <CardHeader
      action={
        <IconButton onClick={() => contactsStore.toggleWidgetDrawer()}>
          <CloseIcon />
        </IconButton>
      }
      title={
        contactsStore.contactEditVM.objectId
          ? lzStrings.contactListWidget.edit_contact
          : lzStrings.contactListWidget.add_contact
      }
    />
  )
}

export default inject('localizationStore', 'contactsStore')(observer(DrawerHeader))
