import React from 'react'
import { CardContent } from '@material-ui/core'
import { observer, inject } from 'mobx-react'
import { AgGridReact } from 'ag-grid-react/lib/agGridReact'
import AGGridWidgetPagingRow from '../../shared/ag-grid/AGGridPagingRow'
import WeightProfileCell from './WeightProfileCell'
import { GroupsAndRolesStore } from '../GroupsAndRolesStore'

interface WidgetContentProps {
  groupsAndRolesStore?: GroupsAndRolesStore
}

const WidgetContent: React.FC<WidgetContentProps> = ({ groupsAndRolesStore }) => {
  const { groupsAndRolesWidgetVM: vm } = groupsAndRolesStore
  if (!vm) return null
  if (!vm.shouldRender) return null

  const renderGroupsGrid = () => {
    if (vm.tabIndex === 1) return null
    return (
      <div
        className='ag-theme-alpine'
        style={{ height: '100%', width: '100%', display: vm.tabIndex === 0 ? undefined : 'none' }}
      >
        <AgGridReact
          gridOptions={{
            ...vm.getGridOptions(),
          }}
        />
      </div>
    )
  }

  const renderRolesGrid = () => {
    if (vm.tabIndex === 0) return null
    return (
      <div
        className='ag-theme-alpine'
        style={{ height: '100%', width: '100%', display: vm.tabIndex === 1 ? undefined : 'none' }}
      >
        <AgGridReact
          gridOptions={{
            ...vm.getGridOptions(),
            frameworkComponents: {
              weightProfileCell: WeightProfileCell,
            },
          }}
        />
      </div>
    )
  }

  return (
    <CardContent>
      {renderGroupsGrid()}
      {renderRolesGrid()}
      <AGGridWidgetPagingRow vm={vm} />
    </CardContent>
  )
}

export default inject('groupsAndRolesStore')(observer(WidgetContent))
