import { RootStore } from '../../stores/RootStore'
import { observable, computed, action } from 'mobx'
import { ResponseType } from '../types/ResponseType'
import { QuestionVM } from './QuestionVM'
import { IOptionDTO } from '../dtos/IOptionDTO'
import { Option } from '../aggregate/Option'

export class OptionVM {
  private rootStore: RootStore
  private forQuestion: QuestionVM
  private option: Option

  constructor(rootStore, forQuestion: QuestionVM = null, option = null) {
    this.rootStore = rootStore
    this.forQuestion = forQuestion
    this.option = option
  }

  @observable saveTried: boolean = false
  rect: ClientRect | DOMRect = null

  @computed
  public get text(): string {
    return this.option.text
  }

  @computed
  public get id(): string {
    return this.option.id
  }

  @computed
  public get rank(): number {
    return this.option.rank
  }

  @computed
  public get emoji(): string {
    if (!this.option.emoji) return this.option.iconName
    return this.option.emoji
  }

  @computed
  public get weight(): number {
    return this.option.weight
  }

  @computed
  public get isCorrectAnswer(): boolean {
    return this.option.isCorrectAnswer
  }

  @action
  public setIsCorrect() {
    this.option.setIsCorrect()
  }

  @action
  public setIsIncorrect() {
    this.option.setIsIncorrect()
  }

  @action
  public setWeight(val) {
    this.option.setWeight(val)
  }

  @computed
  public get isValid() {
    // if (!this.saveTried) return true
    if (!this.textValid) return false
    return true
  }

  @computed
  public get textValid() {
    // if (!this.saveTried) return true
    if (!this.text || this.text === '') return false
    return true
  }

  @action
  public setRank(val: number) {
    this.option.setRank(val)
  }

  @action
  public setText(val) {
    this.option.setText(val)
  }

  @action
  public setEmoji(val) {
    this.option.setEmoji(val)
  }

  @computed
  public get icon(): string {
    switch (this.forQuestion.type) {
      case 'emoji':
        return null
      case 'rating':
        return null
      case 'starRating':
        return null
      case 'radio':
        return 'far fa-dot-circle'
      case 'check':
        return 'far fa-check-square'
      case 'yesNo':
        return 'far fa-dot-circle'
      case 'dropdown':
        return 'fas fa-caret-square-down'
      case 'text':
        return null
      default:
        return null
    }
  }

  public toDTO(): IOptionDTO {
    return this.option.toDTO()
  }
}
