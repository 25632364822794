import { observable, action, computed, reaction } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import UserDashboard from '../aggregate/UserDashboard'
import { UserDashboardsFindService } from '../service/UserDashboardsFindService'
import { UserDashboardsStore } from '../UserDashboardsStore'
import UserDashboardVM from './UserDashboardVM'
import { browserHistory } from 'react-router'
import { DashboardType } from '../../types/DashboardType'

export class UserDashboardsViewModelService {
  private rootStore: RootStore
  private userDashboardsStore: UserDashboardsStore
  private findSvc: UserDashboardsFindService

  constructor(rootStore: RootStore, userDashboardsStore: UserDashboardsStore) {
    this.rootStore = rootStore
    this.userDashboardsStore = userDashboardsStore
    this.findSvc = new UserDashboardsFindService(this.rootStore)
    this.userDashboardVM = new UserDashboardVM(this.rootStore)
    this.listenToRoute()
  }

  @observable public userDashboardVM: UserDashboardVM = null
  @observable public loadedRouteId: string = null
  @observable public loadedRoute: string = null
  @observable public locationProp: string = null
  @observable public isProcessing: boolean = true

  public listenToRoute() {
    browserHistory.listen((location) => {
      this.setIsProcessing()
      if (this.locationProp === location.hash) return
      setTimeout(() => {
        if (!this.shouldProcessRoute(location.hash)) return
        this.getDashboard()
      }, 1000)
    })
  }

  public setIsProcessing() {
    this.userDashboardVM.clearUserDashboard()
  }

  public get currentRoute(): string {
    return this.rootStore.appStore.currentRoute
  }

  public get currentRouteId(): string {
    return this.rootStore.appStore.router.params.id
  }

  public get shouldRender(): boolean {
    if (!this.rootStore.dashboardsStore.loaded) return false
    if (!this.rootStore.userDashboardsStore.loaded) return false
    return true
  }

  private shouldProcessRoute(route) {
    if (!route.includes('dashboard')) return false
    if (route.includes('edit')) return false
    if (route.includes('clone')) return false
    return true
  }

  public setSelectedDashboardRoute(dashboard: UserDashboard) {
    if (!dashboard) return
    const id = dashboard.objectId
    const type = dashboard.type
    if (type === 'custom' || !type)
      this.rootStore.appStore.router.replace(`/dashboard/userDashboard/${id}`)
    if (type === 'analysis') this.rootStore.appStore.router.replace(`/dashboard/worksheets/${id}`)
    if (type === 'tasks') this.rootStore.appStore.router.replace(`/dashboard/tasks/${id}`)
    if (type === 'surveys') this.rootStore.appStore.router.replace(`/dashboard/surveys/${id}`)
    if (type === 'impactGoals')
      this.rootStore.appStore.router.replace(`/dashboard/impact/goals/${id}`)
    if (type === 'impactTrainingPlans')
      this.rootStore.appStore.router.replace(`/dashboard/impact/trainings/${id}`)
    if (type === 'impactCatalog')
      this.rootStore.appStore.router.replace(`/dashboard/impact/catalog/${id}`)
    if (type === 'tenantAdminOrg')
      this.rootStore.appStore.router.replace(`/dashboard/tenantAdmin/org/${id}`)
    if (type === 'tenantAdminConfig')
      this.rootStore.appStore.router.replace(`/dashboard/tenantAdmin/config/${id}`)
    if (type === 'tenantAdminSurvey')
      this.rootStore.appStore.router.replace(`/dashboard/tenantAdmin/survey/${id}`)
  }

  @action
  public getDashboard(attempts: number = 0) {
    if (!this.shouldRender) return setTimeout(() => this.getDashboard(++attempts), 1000)
    if (!this.typeFromRoute) return
    const foundDash = this.findSvc.findDashboardByTypeAndId(this.currentRouteId, this.typeFromRoute)
    if (!foundDash) return this.getDefaultDashboardByType(this.typeFromRoute)
    return this.loadUserDashboardVM(foundDash)
  }
 
  @action
  public loadUserDashboardVM(dashboard: UserDashboard) {
    this.userDashboardVM.loadUserDashboard(dashboard)
  }

  public get typeFromRoute(): DashboardType {
    if (this.currentRoute.includes('userdashboard')) return 'custom'
    if (this.currentRoute.includes('worksheets') && !this.currentRoute.includes('user')) return 'analysis'
    if (this.currentRoute.includes('tasks')) return 'tasks'
    if (this.currentRoute.includes('surveys')) return 'surveys'
    if (this.currentRoute.includes('goals')) return 'impactGoals'
    if (this.currentRoute.includes('trainings')) return 'impactTrainingPlans'
    if (this.currentRoute.includes('catalog')) return 'impactCatalog'
    if (this.currentRoute.includes('tenantadmin/org')) return 'tenantAdminOrg'
    if (this.currentRoute.includes('tenantadmin/config')) return 'tenantAdminConfig'
    if (this.currentRoute.includes('tenantadmin/survey')) return 'tenantAdminSurvey'
    if (this.currentRoute.includes('surveyresults')) return 'surveys'
    if (this.currentRoute.includes('surveyresponses')) return 'surveys'
    return null
  }

  public getDefaultDashboardByType(type: DashboardType) {
    let foundUserDashboard = null
    if (type === 'custom') {
      foundUserDashboard = this.findSvc.findDefaultDashboard()
      if (!foundUserDashboard) foundUserDashboard = this.findSvc.findLastVisitedDashboard()
    }
    if (!foundUserDashboard) foundUserDashboard = this.findSvc.findDashboardByType(type)
    return this.setSelectedDashboardRoute(foundUserDashboard)
  }

  public getUserDashboardByDashboardId(dashboardId: string, attempts: number = 0) {
    if (attempts === 20) return
    const foundUserDashboard = this.findSvc.findDashboardByDashboardId(dashboardId)
    if (!foundUserDashboard)
      setTimeout(() => this.getUserDashboardByDashboardId(dashboardId, ++attempts), 500)
    return this.setSelectedDashboardRoute(foundUserDashboard)
  }

  public get dashboardsInDropdown(): UserDashboard[] {
    return this.customUserDashboards
      .filter((e) => {
        if (e.isFavorite) return true
        if (e.ownerUserId !== this.rootStore.appStore.currentUserId) return false
        return true
      })
      .sort((a, b) => (a.isFavorite > b.isFavorite ? -1 : 0))
  }

  public get customUserDashboards(): UserDashboard[] {
    return this.rootStore.userDashboardsStore.currentOrgUserDashboards.filter((e) => {
      if (e.type === 'custom') return true
      if (!e.type) return true
      return false
    })
  }

  public get sharedDashboards(): UserDashboard[] {
    return this.rootStore.userDashboardsStore.currentOrgUserDashboards.filter(
      (e) => e.ownerUserId !== this.rootStore.appStore.currentUserId
    )
  }
}
