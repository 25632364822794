import React, { FC } from 'react'
import { inject, observer } from 'mobx-react'
import {
  CardContent,
  CardHeader,
  FormControlLabel,
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { AgGridReact } from 'ag-grid-react'
import AGGridUserCell from '../../../shared/ag-grid/AGGridUserCell'
import WidgetTitle from './WidgetTitle'
import Searchbar from '../../../shared/Searchbar'
import AGGridWidgetPagingRow from '../../../shared/ag-grid/AGGridPagingRow'
import { ActiveUserSurveysTabVM } from '../../view-models/my-surveys-widget/ActiveUserSurveysTabVM'
import { ActiveUserSurveyRowVM } from '../../view-models/my-surveys-widget/ActiveUserSurveyRowVM'
import BookmarkIcon from '@material-ui/icons/Bookmark'
import ArrowForward from '@material-ui/icons/ArrowForward'
import LoopIcon from '@material-ui/icons/Loop'
import './ActiveUserSurveysTab.scss'
import { LabelsStore } from '../../../labels/store/LabelsStore'

interface Props {
  vm: ActiveUserSurveysTabVM
  labelsStore?: LabelsStore
}

const ActiveUserSurveysTab: FC<Props> = ({ vm, labelsStore }) => {
  const renderOwnerCell = (props) => {
    const row: ActiveUserSurveyRowVM = props.data
    if (!row) return null
    return <AGGridUserCell userId={row.fromUserId} name={row.fromUserName} iconURL={row.fromUserIconURL} />
  }

  const renderActionsCell = (props) => {
    const row: ActiveUserSurveyRowVM = props.data
    if (!row) return <div></div>
    return (
      <div className='my-surveys-widget-actions-cell'>
        <Tooltip
          title={`Take ${labelsStore.getLabelByLanguageAndFor('pulse')}`}
          placement='bottom-start'
          enterDelay={500}
        >
          <IconButton style={{ padding: '3px' }} onClick={() => row.openUserSurvey()}>
            <ArrowForward id='take-survey' />
          </IconButton>
        </Tooltip>
        {renderInactiveButton(row)}
      </div>
    )
  }

  const renderInactiveButton = (row: ActiveUserSurveyRowVM) => {
    if (!vm.canArchiveSurveys) return
    if (!row.isActive) return
    return (
      <Tooltip title={`Set inactive`} placement='bottom-start' enterDelay={500}>
        <IconButton style={{ padding: '3px' }} onClick={() => row.setSurveyInactive()}>
          <BookmarkIcon id={'inactive-survey'} />
        </IconButton>
      </Tooltip>
    )
  }

  const renderGrid = () => {
    if (!vm.shouldRenderGrid) return
    return (
      <div
        className={vm.hasRows ? 'ag-theme-alpine' : 'ag-theme-alpine ag-grid-no-rows'}
        style={{ height: '100%', width: '100%', display: vm.shown ? undefined : 'none' }}
      >
        <AgGridReact
          gridOptions={{
            ...vm.getGridOptions(),
            frameworkComponents: {
              fromCell: renderOwnerCell,
              actionsCell: renderActionsCell,
            },
          }}
        />
      </div>
    )
  }

  const renderPagingRow = () => {
    if (!vm.shown) return
    return <AGGridWidgetPagingRow vm={vm} />
  }

  const renderInactiveToggle = () => {
    if (!vm.canArchiveSurveys) return
    return (
      <FormControlLabel
        control={
          <Switch
            color='primary'
            size='small'
            checked={vm.showInactiveUserSurveys}
            onChange={() => vm.toggleShowInactiveUserSurveys()}
          />
        }
        label='Show Inactive'
      />
    )
  }

  const renderHeader = () => {
    if (!vm.shown) return
    return <CardHeader title={<WidgetTitle vm={vm.widgetVM} />} action={renderInactiveToggle()} />
  }

  const renderNoRowsContent = () => {
    if (vm.hasRows) return
    return (
      <Typography component='div' className='no-rows'>
        {vm.noRowsToShowLabel}
      </Typography>
    )
  }

  return (
    <>
      {renderHeader()}
      <CardContent id={'ActiveUserSurveysTab'} style={{ display: vm.shown ? undefined : 'none' }}>
        {renderGrid()}
        {renderNoRowsContent()}
        {renderPagingRow()}
      </CardContent>
    </>
  )
}

export default inject('labelsStore')(observer(ActiveUserSurveysTab))
