import { RootStore } from '../../stores/RootStore'
import { observable, computed, action } from 'mobx'
import { Question } from '../aggregate/Question'
import { OptionTakeVM } from './OptionTakeVM'
import { RatingDisplayType } from '../types/RatingDisplayType'
import { QuestionType } from '../types/QuestionType'
import isMobile from '../../../utils/isMobile'

export class QuestionTakeVM {
  public question: Question

  constructor(question: Question) {
    this.question = question
  }

  @observable hover: number = 0

  @computed
  public get id(): string {
    return this.question.id
  }

  @computed
  public get title(): string {
    return this.question.title
  }

  @computed
  public get ratingDisplay(): RatingDisplayType {
    return this.question.ratingDisplay
  }

  @computed
  public get options(): OptionTakeVM[] {
    return this.question.options.map((option) => new OptionTakeVM(option, this))
  }

  @computed
  public get answers(): string[] {
    const answers = []
    this.options.forEach((option) => {
      if (option.isChecked) answers.push(option.id)
      if (this.type === 'text' && option.text) answers.push(option.id)
    })
    return answers
  }

  @computed
  public get hasAnswer(): boolean {
    if (this.type === 'infoText') return true
    if (this.type !== 'text') {
      return this.options.filter((e) => e.isChecked).length !== 0
    } else {
      return this.options[0].text !== ''
    }
  }

  @action
  public setValue(val) {
    this.options.forEach((e) => e.setChecked(false))
    this.options.forEach((e) => {
      if (e.rank === val) e.setChecked(true)
    })
  }

  @computed
  public get isCorrect(): boolean {
    if (this.options.some((option) => option.isChecked && !option.isCorrectAnswer)) return false
    if (this.options.some((option) => option.isChecked && option.isCorrectAnswer)) return true
    return false
  }

  @computed
  public get type(): QuestionType {
    return this.question.type
  }

  @computed
  public get value(): number {
    if (this.type === 'starRating') {
      const currentSelectedOption = this.options.find((e) => e.isChecked === true)
      if (currentSelectedOption) return currentSelectedOption.rank
      return 0
    }
    return null
  }

  @computed
  public get ratingText(): string {
    const index = this.hover - 1 > -1 ? this.hover - 1 : this.value - 1
    if (index < 0) return `${isMobile ? 'Tap' : 'Click'} to Select`
    if (this.type === 'rating' || this.type === 'starRating') {
      const currentSelectedOption = this.options[index].text
      return currentSelectedOption ? currentSelectedOption : ''
    }
    return null
  }

  @action
  public setHover(newHover: number) {
    this.hover = newHover
  }
}
