import { observable, action, computed } from 'mobx'
import { serializable, serialize, deserialize, object } from 'serializr'
import { IContentItemDTO } from '../dto/IContentItemDTO'
import { ContentItemType } from '../types/ContentItemType'
import uuid from 'uuid/v4'
import moment from 'moment'
import { Exercise } from './Exercise'
import { ExerciseSet } from './ExerciseSet'
import { Attachment } from '../../attachments/aggregate/Attachment'
import { MediaItem } from '../../media-items/aggregate/MediaItem'

export class ContentItem implements IContentItemDTO {
  public static create(type: ContentItemType): ContentItem {
    const content = new ContentItem()
    content.id = uuid()
    content.type = type
    if (type === 'exercise') content.exercise = Exercise.create()
    return content
  }

  @serializable @observable public id: string = ''
  @serializable @observable public title: string = ''
  @serializable @observable public type: ContentItemType = null
  @serializable @observable public link: string = ''
  @serializable @observable public mediaItemId: string = ''
  @serializable(object(Attachment)) @observable public fileAttachment: Attachment = null
  @serializable @observable public categoryId: string = ''
  @serializable @observable public descriptionTitle: string = ''
  @serializable @observable public descriptionBody: string = ''
  @serializable @observable public isComplete: boolean = false
  @serializable @observable public completedAt: string = ''
  @serializable @observable public mediaWatchSeconds: number = 0
  @serializable(object(Exercise)) @observable public exercise: Exercise = null
  @serializable(object(MediaItem)) @observable public mediaItem: MediaItem = null

  isOnServer: boolean = false
  isDeleted: boolean = false

  @action
  public setTitle(val: string) {
    this.title = val
  }

  @action
  public addExerciseItem(val?: Exercise) {
    if (!val) return (this.exercise = Exercise.create())
    this.exercise = val
  }

  @action
  public loadSets() {
    if (this.type !== 'exercise') return
    if (!this.exercise) this.addExerciseItem()
    const sets = []
    for (let i = 0; i < this.exercise.goal.sets; i++) {
      const set = ExerciseSet.create()
      set.index = i
      set.reps = this.exercise.goal.reps
      set.weight = this.exercise.goal.weight
      set.time = this.exercise.goal.time
      set.percent = this.exercise.goal.percent
      sets.push(set)
    }
    this.exercise.sets = []
    this.exercise.sets = sets
  }

  @action
  public setReps(val: number) {
    if (!this.exercise) this.addExerciseItem()
    this.exercise.goal.reps = val
  }

  @action
  public setSets(val: number) {
    if (!this.exercise) this.addExerciseItem()
    this.exercise.goal.sets = val
  }

  @action
  public setWeight(val: number) {
    if (!this.exercise) this.addExerciseItem()
    this.exercise.goal.weight = val
  }

  @action
  public setTime(val: number) {
    if (!this.exercise) this.addExerciseItem()
    this.exercise.goal.time = val
  }

  @action
  public setPercent(val: number) {
    if (!this.exercise) this.addExerciseItem()
    this.exercise.goal.percent = val
  }

  @action
  public setMediaWatchSeconds(val: number) {
    this.mediaWatchSeconds = Math.floor(val)
  }

  @action
  public setType(val) {
    this.type = val
  }

  @action
  public setLink(val: string) {
    this.link = val
  }

  @action
  public toggleMarkIsComplete() {
    this.isComplete = !this.isComplete
    if (this.isComplete) this.completedAt = moment().toISOString()
    else this.completedAt = ''
  }

  @action
  public markIsComplete() {
    this.isComplete = true
    this.completedAt = moment().toISOString()
  }

  @action
  public markIsIncomplete() {
    this.isComplete = false
    this.completedAt = ''
  }

  @action
  public setMediaItem(val: any) {
    this.mediaItem = val
  }

  @action
  public setMediaItemId(val: any) {
    this.mediaItemId = val
  }

  @action
  public setFileAttachment(val: any) {
    this.fileAttachment = val
  }

  @action
  public setCategory(id: string) {
    this.categoryId = id
  }

  @action
  public setDescriptionTitle(val: string) {
    this.descriptionTitle = val
  }

  @action
  public setDescriptionBody(val: string) {
    this.descriptionBody = val
  }

  @action
  public addFile(attachment: Attachment) {
    this.fileAttachment = attachment
  }

  @action
  public removeMedia() {
    this.mediaItemId = null
  }

  @action
  public removeFile() {
    this.fileAttachment = null
  }

  @action
  public markIsOnServer() {
    this.isOnServer = true
  }

  @action
  public markIsNotOnServer() {
    this.isOnServer = true
  }

  public serialize() {
    const obj = serialize(this)
    return obj
  }

  public clone(): ContentItem {
    return deserialize(ContentItem, this.serialize())
  }
}
