import React from 'react'
import { Route } from 'react-router'
import Layout from '../../layout/Layout'
import UserSurveyTakeScreen from '../../user-surveys/views/take/UserSurveyTakeScreen'
import { authenticate } from '../../auth/routes/AuthenticatedRoute'

export default function getUserSurveysRoutes() {
  return (
    <Route key={'us0'} path='usersurveys' component={authenticate(Layout)}>
      <Route key={'us1'} path='take/:id' component={UserSurveyTakeScreen} />
      <Route key={'us3'} path='paperSurvey/:id' component={UserSurveyTakeScreen} />
      <Route key={'us3'} path='alwaysAvailable/:id' component={UserSurveyTakeScreen} />
    </Route>
  )
}
