import { observable, action, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Role } from '../aggregate/Role'
import RoleEditVM from '../view-models/RoleEditVM'
import RoleNewForm from '../view-models/RoleNewForm'
import { IRoleDTO } from '../dtos/IRoleDTO'
import { DataStore } from '../../shared/data/DataStore'

export class RolesStore extends DataStore<Role, IRoleDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, Role, 'roles', [
      'organizationId',
      'defaultDashboardId',
      'description',
      'categories',
      'privilegeSets',
      'name',
      'forceDashboardRoute',
      'memberCount'
    ])
  }

  @observable public selectedRoleId: string
  @observable public newForm: RoleNewForm = null
  @observable public usersInRole: Array<any> = []
  @observable public roleMenuId: string = ''
  @observable public editVM: RoleEditVM = null
  @observable public active: boolean = false
  @observable public roleToEdit: Role = null
  @observable public loaded: boolean = false

  @action
  public onListRecordsLoaded() {
    this.loaded = true
  }

  @action
  public setRoleToEdit(objectId) {
    this.roleToEdit = this.getRole(objectId)
    this.editVM = new RoleEditVM(this.rootStore, this.roleToEdit)
    this.editVM.showDrawer = true
  }

  @action
  public setNewRoleToEdit() {
    this.editVM = new RoleEditVM(this.rootStore)
    this.editVM.showDrawer = true
  }

  @action
  public setSelectedRole(id) {
    this.selectedRoleId = id
    this.editVM = new RoleEditVM(this.rootStore, this.getRole(id))
    this.newForm = null
  }

  @action
  public startNewRole() {
    this.selectedRoleId = null
    this.editVM = null
    this.newForm = new RoleNewForm(this.rootStore)
  }

  @action clearForms() {
    this.editVM = null
    this.newForm = null
    this.selectedRoleId = null
  }

  @computed
  public get roles() {
    return this.records
  }

  public getRole(objectId): Role {
    return this.roles.find((e) => e.objectId === objectId)
  }

  public getRoleByName(name): Role {
    return this.roles.find((e) => e.name === name)
  }

  private getRoleIndex(objectId): number {
    return this.roles.findIndex((e) => e.objectId === objectId)
  }

  @action
  public getUsersInRole(role) {
    // this.roleMenuId = role
    this.usersInRole = []
    this.rootStore.usersStore.currentOrganizationUsers.forEach((user) => {
      if (user.roles.includes(role)) {
        this.usersInRole.push(user)
      }
    })

    return this.usersInRole
  }
}
