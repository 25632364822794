import React, { FC, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { Dialog, DialogActions, Button, DialogContent, DialogTitle } from '@material-ui/core'
import SpinnerOverlay from '../../../shared/SpinnerOverlay'
import { OrganizationUsersStore } from '../../store/OrganizationUsersStore'

interface Props {
  organizationUsersStore?: OrganizationUsersStore
}

const ProfilePictureDialog: FC<Props> = ({ organizationUsersStore }) => {
  const { profilePicVM: vm } = organizationUsersStore.editVM

  const onLoad = useCallback((img) => {
    vm.setImgRef(img)
  }, [])

  return (
    <>
      {vm.file && (
        <Dialog
          onClose={() => vm.toggleShowProfilePictureDialog()}
          open={vm.showProfilePictureDialog}
          id='ProfilePicDialog'
        >
          {vm.isProcessing ? <SpinnerOverlay style={{ height: '100%' }} /> : null}
          <DialogTitle>Upload Profile Picture</DialogTitle>
          <DialogContent>
            <ReactCrop
              onImageLoaded={onLoad}
              keepSelection={true}
              src={vm.upImg}
              ruleOfThirds
              crop={vm.crop}
              onChange={(c) => vm.setCrop(c)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => vm.cancel()}>CANCEL</Button>
            <Button variant='contained' onClick={() => vm.save()}>
              SAVE
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default inject('organizationUsersStore')(observer(ProfilePictureDialog))
