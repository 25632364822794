import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../localization/LocalizationStore'
import { NotificationsStore } from '../store/NotificationsStore'
import { CardHeader } from '@material-ui/core'
import Searchbar from '../../shared/Searchbar'
import { NotificationsWidgetVM } from '../store/view-models/NotificationsWidgetVM'
import './WidgetHeader.scss'

interface WidgetHeaderProps {
  notificationsStore?: NotificationsStore
  localizationStore?: LocalizationStore
  vm: NotificationsWidgetVM
}

const WidgetHeader: React.FC<WidgetHeaderProps> = ({ localizationStore, notificationsStore, vm }) => {

  return (
    <CardHeader
      id='WidgetHeader'
      action={
        <>
          <div id='ManageNotificationsSearch'>
            <Searchbar
              focused={vm.typedFilterText.length > 0}
              className='search'
              searchValue={vm.typedFilterText}
              onSearchChange={(e) => vm.setQuickFilter(e.target.value)}
            />
          </div>
        </>
      }
      title={localizationStore.lzStrings.notificationsWidget.notifications}
    />
  )
}

export default inject('localizationStore', 'notificationsStore')(observer(WidgetHeader))
