import React from 'react'
import { observer } from 'mobx-react'
import { inject } from 'mobx-react'
import { SurveysStore } from '../../../store/SurveysStore'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core'
import ParticipantsSelect from '../../../../participants-select/views/ParticipantsSelect'

interface Props {
  localizationStore?: LocalizationStore
  surveysStore?: SurveysStore
}

const AdditionalResponseNotificationParticipantSelect: React.FC<Props> = ({ surveysStore }) => {
  const { editVM } = surveysStore.viewModels
  const vm = editVM.additionalResponseNotificationEditorVM
  if (!vm) return null
  if (!vm.showParticipantSelectModal) return null

  const toolbar = () => {
    return (
      <div className='participant-toolbar'>
        <Button variant='contained' onClick={() => vm.toggleShowParticipantSelect()}>
          Apply
        </Button>
      </div>
    )
  }

  return (
    <Dialog
      id='ResponseNotificationModal'
      fullWidth
      maxWidth='lg'
      open={vm.showParticipantSelectModal}
      onClose={() => vm.toggleShowParticipantSelect()}
    >
      <DialogContent className='reno-participant-dialog-content'>
        <div className='reno-participant-select'>
          <ParticipantsSelect
            participantsSelectVM={vm.addResponseNotificationParticipantVM}
            responseNotificationVM={vm}
          />
        </div>
      </DialogContent>
      <DialogActions>{toolbar()}</DialogActions>
    </Dialog>
  )
}

export default inject(
  'surveysStore',
  'localizationStore'
)(observer(AdditionalResponseNotificationParticipantSelect))
