import React from 'react'
import { TrainingItemsStore } from '../../store/TrainingItemsStore'
import { inject, observer } from 'mobx-react'
import {
  Grid,
  Typography,
  OutlinedInput,
  IconButton,
  Slide,
  Paper,
  Select,
  MenuItem,
  Menu,
  TextField,
  Button,
  AppBar,
  Toolbar,
  Tooltip,
} from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import CloseIcon from '@material-ui/icons/Close'
import VideoIcon from '@material-ui/icons/VideocamRounded'
import './BuilderDrawer.scss'
import { LabelsStore } from 'src/app/labels/store/LabelsStore'
import CategoryPicker from '../../../shared/category-picker/CategoryPicker'
import LinkIcon from '@material-ui/icons/Link'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import PermMediaIcon from '@material-ui/icons/PermMedia'
import MediaUploadPicker from 'src/app/media-items/views/media-upload-picker/MediaUploadPicker'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import DeleteIcon from '@material-ui/icons/Delete'
import DescriptionIcon from '@material-ui/icons/Description'
import PanToolIcon from '@material-ui/icons/PanTool'
import LaunchIcon from '@material-ui/icons/Launch'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import FileUploadModal from '../../../upload/views/FileUploadModal'
import { FileTypeEnum } from '../../../upload/enum/FileTypeEnum'
import { Attachment } from '../../../attachments/aggregate/Attachment'
import PublishIcon from '@material-ui/icons/Publish'
import CheckIcon from '@material-ui/icons/Check'
import ExerciseIcon from '@material-ui/icons/FitnessCenterRounded'
import CMSItemsPickerDialog from '../../../cms-items/views/dialogs/CMSItemsPickerDialog'
import { CMSItemsTypeEnum } from '../../../cms-items/enum/CMSItemsTypeEnum'
import { OrganizationsStore } from '../../../organizations/store/OrganizationsStore'
import CMSThumbnail from '../../../thumbnail/views/CMSThumbnail'
import OldThumbnail from '../../../thumbnail/views/OldThumbnail'
import { CMSFileAttachmentVM } from '../../view-models/CMSFileAttachmentVM'
import { OldFileAttachmentVM } from '../../view-models/OldFileAttachmentVM'

interface Props {
  trainingItemsStore?: TrainingItemsStore
  labelsStore?: LabelsStore
  localizationStore?: LocalizationStore
  organizationsStore?: OrganizationsStore
}

const BuilderDrawer: React.FC<Props> = ({
  trainingItemsStore,
  labelsStore,
  localizationStore,
  organizationsStore,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const { currentContentItemVM: vm } = trainingItemsStore.trainingItemEditVM
  const { training_items: strings } = localizationStore.lzStrings
  if (!vm) return null

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const renderFileMsg = () => {
    if (vm.type !== 'File') return null
    if (vm.fileValid) return null
    return (
      <Typography variant='h6' className='upload-error'>
        <WarningRoundedIcon />
        {strings.upload_file}
      </Typography>
    )
  }

  const renderMediaMsg = () => {
    if (vm.type !== 'Media') return null
    if (vm.mediaItemValid) return null
    return (
      <Typography variant='h6' className='upload-error'>
        <WarningRoundedIcon />
        {strings.upload_media}
      </Typography>
    )
  }

  const renderLinkMsg = () => {
    if (vm.type !== 'Link') return null
    if (vm.linkUrlValid) return null
    return (
      <Typography variant='h6' className='upload-error'>
        <WarningRoundedIcon />
        {'Please provide a link for this content.'}
      </Typography>
    )
  }

  const renderHeader = () => {
    return (
      <>
        <Grid item xs={12} className='drawer-title'>
          <Typography variant='h5'>Training 1 Editor</Typography>
          <IconButton onClick={() => trainingItemsStore.trainingItemEditVM.closeBuilderDrawer()}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} className='drawer-header'>
          <IconButton onClick={handleClick}>
            <MoreHorizIcon className='menu-icon' />
          </IconButton>
        </Grid>
      </>
    )
  }

  const renderMenu = () => {
    return (
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          onClick={() => trainingItemsStore.trainingItemEditVM.duplicateContentItem(vm.content)}
        >
          <FileCopyIcon className='duplicate-drawer-icon' />
          {` `}Duplicate
        </MenuItem>
        <MenuItem onClick={() => trainingItemsStore.trainingItemEditVM.deleteContentItem(vm.id)}>
          <DeleteIcon className='delete-drawer-icon' />
          {` `}Delete
        </MenuItem>
      </Menu>
    )
  }

  const renderLink = () => {
    if (vm.type !== 'Link') return null
    return (
      <Grid item xs={12} className='details-item'>
        <Typography variant='h6' className='input-label'>
          {strings.link_url}
          <span className='required'>*</span>
        </Typography>
        <OutlinedInput
          className='link-input'
          required
          fullWidth
          labelWidth={0}
          autoComplete='off'
          value={vm.linkUrl}
          onChange={(e) => vm.setLink(e.target.value)}
          error={!vm.linkUrlValid}
          endAdornment={
            <IconButton disabled={!vm.linkUrl} onClick={() => vm.openLink()}>
              <LaunchIcon />
            </IconButton>
          }
        />
      </Grid>
    )
  }

  const renderMediaUploadButton = () => {
    if (vm.type !== 'Media') return null

    const renderButton = () => {
      if (vm.attachment) return null
      return (
        <Button
          variant='text'
          onClick={() => vm.toggleMediaUpload()}
          className={vm.mediaItemValid ? '' : 'media-item-error'}
        >
          {strings.add_media}
        </Button>
      )
    }

    return (
      <Grid item xs={12} className='details-item'>
        <Grid item className='upload'>
          <Typography variant='h6' className='input-label'>
            {strings.media}
            <span className='required'>*</span>
          </Typography>
          {renderButton()}
        </Grid>
        {renderUploadedFilesOrMedia()}
      </Grid>
    )
  }

  const renderExerciseMediaUploadButton = () => {
    if (vm.type !== 'Exercise') return null

    const renderButton = () => {
      if (vm.attachment) return null
      return (
        <Button
          variant='text'
          onClick={() => vm.toggleMediaUpload()}
          className={vm.mediaItemValid ? '' : 'media-item-error'}
        >
          {strings.add_media}
        </Button>
      )
    }

    return (
      <Grid item xs={12} className='details-item'>
        <Grid item className='upload'>
          <Typography variant='h6' className='input-label'>
            {strings.media}
          </Typography>
          {renderButton()}
        </Grid>
        {renderUploadedFilesOrMedia()}
      </Grid>
    )
  }

  const renderFileUploadButton = () => {
    if (vm.type !== 'File') return null

    const renderButton = () => {
      if (vm.attachment) return null
      return (
        <Button
          variant='text'
          onClick={() => vm.toggleFileUploadModal()}
          className={vm.mediaItemValid ? '' : 'media-item-error'}
        >
          {strings.upload_file_short}
        </Button>
      )
    }

    return (
      <Grid item xs={12} className='details-item'>
        <Grid item className='upload'>
          <Typography variant='h6' className='input-label'>
            {strings.file}
            <span className='required'>*</span>
          </Typography>
          {renderButton()}
        </Grid>
        {renderUploadedFilesOrMedia()}
      </Grid>
    )
  }

  const renderUploadedFilesOrMedia = () => {
    if (!vm.hasAttachments) return null

    const item = vm.attachment

    if (item.isCMSItem)
      return (
        <CMSThumbnail
          key={`train-cms-attach-thumb`}
          vm={item as CMSFileAttachmentVM}
          onClose={() => {
            vm.removeCurrentAttachment()
          }}
        />
      )
    return (
      <OldThumbnail
        key={`train-old-attach-thumb`}
        vm={item as OldFileAttachmentVM}
        onClose={() => vm.removeFile()}
      />
    )
  }

  const renderDescriptionRequired = () => {
    if (!vm.descriptionRequired) return null
    return <span className='required'>*</span>
  }

  const renderExerciseInfo = () => {
    if (vm.type !== 'Exercise') return
    return (
      <>
        <Typography variant='h6' className='input-label'>
          {strings.default}
        </Typography>
        <Grid
          container
          spacing={3}
          alignContent='flex-start'
          justifyContent='flex-start'
          className='exercise-items'
        >
          <Grid item xs={2} className='exercise-item'>
            <Typography variant='body1' className='input-label'>
              {strings.sets}
              <span className='required'>*</span>
            </Typography>
            <TextField
              required
              InputProps={{ inputProps: { min: 0 } }}
              margin='none'
              fullWidth
              variant='outlined'
              autoComplete='off'
              value={vm.sets ? vm.sets.toString() : '0'}
              placeholder={'#'}
              onChange={(e) => vm.setSets(e.target.value)}
              error={!vm.setsValid}
              type={'number'}
            />
          </Grid>
          <Grid item xs={2} className='exercise-item'>
            <Typography variant='body1' className='input-label'>
              {strings.reps}
            </Typography>
            <TextField
              required
              InputProps={{ inputProps: { min: 0 } }}
              margin='none'
              fullWidth
              variant='outlined'
              autoComplete='off'
              value={vm.reps ? vm.reps.toString() : '0'}
              placeholder={'#'}
              onChange={(e) => vm.setReps(e.target.value)}
              error={!vm.repsValid}
              type={'number'}
            />
          </Grid>
          <Grid item xs={2} className='exercise-item'>
            <Typography variant='body1' className='input-label'>
              {strings.weight}
            </Typography>
            <TextField
              required
              InputProps={{ inputProps: { min: 0 } }}
              margin='none'
              fullWidth
              variant='outlined'
              autoComplete='off'
              value={vm.weight ? vm.weight.toString() : '0'}
              placeholder={'#'}
              onChange={(e) => vm.setWeight(e.target.value)}
              error={!vm.weightValid}
              type={'number'}
            />
          </Grid>
          <Grid item xs={3} className='exercise-item'>
            <Typography variant='body1' className='input-label'>
              {strings.time}
            </Typography>
            <TextField
              required
              InputProps={{ inputProps: { min: 0 } }}
              margin='none'
              fullWidth
              variant='outlined'
              autoComplete='off'
              value={vm.time ? vm.time.toString() : '0'}
              placeholder={'#'}
              onChange={(e) => vm.setTime(e.target.value)}
              error={!vm.timeValid}
              type={'number'}
            />
          </Grid>
          <Grid item xs={3} className='exercise-item'>
            <Typography variant='body1' className='input-label'>
              {'%1RM'}
            </Typography>
            <TextField
              required
              InputProps={{ inputProps: { min: 0 } }}
              margin='none'
              fullWidth
              variant='outlined'
              autoComplete='off'
              value={vm.percent ? vm.percent.toString() : '0'}
              placeholder={'#'}
              onChange={(e) => vm.setPercent(e.target.value)}
              error={!vm.percentValid}
              type={'number'}
            />
          </Grid>
        </Grid>
      </>
    )
  }

  const renderBody = () => {
    return (
      <>
        <Grid item xs={12} className='details-item'>
          <Typography variant='h6' className='input-label'>
            Item Title
            <span className='required'>*</span>
          </Typography>
          <OutlinedInput
            required
            fullWidth
            labelWidth={0}
            autoComplete='off'
            value={vm.title}
            placeholder='(Untitled)'
            onChange={(e) => vm.setTitle(e.target.value)}
            error={!vm.titleValid}
          />
        </Grid>
        <Grid item xs={12} className='details-item'>
          <Typography variant='h6' className='input-label'>
            Description Title (if different from item title)
          </Typography>
          <OutlinedInput
            required
            fullWidth
            labelWidth={0}
            autoComplete='off'
            value={vm.descriptionTitle}
            onChange={(e) => vm.setDescriptionTitle(e.target.value)}
          />
          <Typography variant='h6' className='input-label description-body'>
            Description Body
            {renderDescriptionRequired()}
          </Typography>
          <OutlinedInput
            required
            fullWidth
            error={!vm.descriptionValid}
            multiline
            rows={4}
            labelWidth={0}
            autoComplete='off'
            value={vm.descriptionBody}
            onChange={(e) => vm.setDescriptionBody(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} className='details-item'>
          <Typography variant='h6' className='input-label'>
            Content Type
            <span className='required'>*</span>
          </Typography>
          <Select
            required
            fullWidth
            variant='outlined'
            labelWidth={0}
            autoComplete='off'
            value={vm.type || ''}
            onChange={(e) => vm.setType(e.target.value)}
            error={!vm.contentTypeValid}
          >
            <MenuItem value='Link'>
              <LinkIcon className='icon link' />
              {` `}Link
            </MenuItem>
            <MenuItem value='Media'>
              <PermMediaIcon className='icon media' />
              {` `}Media Item
            </MenuItem>
            <MenuItem value='File'>
              <AttachFileIcon className='icon file' />
              {` `}File Attachment
            </MenuItem>
            <MenuItem value='Hands-on'>
              <PanToolIcon className='icon hands-on' />
              {` `}Hands On Training
            </MenuItem>
            <MenuItem value='Exercise'>
              <ExerciseIcon className='icon exercise' />
              {` `}Exercise
            </MenuItem>
          </Select>
        </Grid>
        {renderFileMsg()}
        {renderMediaMsg()}
        {renderLinkMsg()}
        {renderLink()}
        {/* {renderFileAttachment()} */}
        {renderFileUploadButton()}
        {renderMediaUploadButton()}
        {renderExerciseMediaUploadButton()}
        {renderExerciseInfo()}
        <Grid item xs={12} className='details-item'>
          <Typography variant='h6' className='input-label'>
            {labelsStore.getLabelByLanguageAndFor('category')}
            {/* <span className='required'>*</span> */}
          </Typography>
          <CategoryPicker
            error={!vm.categoryValid}
            onApply={(val) => vm.setCategory(val)}
            vm={vm.categoryPickerVM}
            fullWidth={true}
          />
        </Grid>
      </>
    )
  }

  const renderMediaUploadIcon = () => {
    if (!vm) return null
    if (!vm.mediaUploadPickerVM.showMediaUploadPicker) return null
    return (
      <Tooltip title={`Upload new media`} placement='bottom-start' enterDelay={500}>
        <IconButton
          onClick={() => vm.mediaUploadPickerVM.openDrawer()}
          className='media-upload-button'
        >
          <PublishIcon />
        </IconButton>
      </Tooltip>
    )
  }

  const renderMediaSelectButton = () => {
    if (!vm) return null
    if (!vm.mediaUploadPickerVM.showMediaUploadPicker) return null
    const mediaPickerVM = vm.mediaUploadPickerVM
    return (
      <Tooltip title={`Add selected media`} placement='bottom-start' enterDelay={500}>
        <span>
          <Button
            variant='contained'
            disabled={!mediaPickerVM.selectedMediaItemId}
            onClick={() => mediaPickerVM.addMedia()}
            startIcon={<CheckIcon />}
          >
            {strings.add_media}
          </Button>
        </span>
      </Tooltip>
    )
  }

  const renderMediaToolbar = () => {
    if (!vm.mediaUploadPickerVM.showMediaUploadPicker) return null
    return (
      <AppBar className={'media-toolbar'} color='default' position='sticky' elevation={0}>
        <Toolbar>
          <Typography variant='h5' style={{ flexGrow: 1 }}>
            {strings.upload_media_short}
          </Typography>
          {renderMediaUploadIcon()}
          {renderMediaSelectButton()}
        </Toolbar>
      </AppBar>
    )
  }

  const renderMediaUpload = () => {
    if (!vm.mediaUploadPickerVM.showMediaUploadPicker) return null
    return <MediaUploadPicker mediaUploadPickerVM={vm.mediaUploadPickerVM} />
  }

  const renderFileUploadModal = () => {
    if (organizationsStore.currentOrganization?.hasCMSPicker) return renderCMSUploadModal()
    return renderStandardUploadModal()
  }

  const renderCMSUploadModal = () => {
    return (
      <CMSItemsPickerDialog
        key={`file-${vm.key}`}
        allowMultiple={false}
        fileTypes={CMSItemsTypeEnum.FILES}
        addAfterSave={(a: Attachment[]) => {
          const attachment = new Attachment()
          attachment.cmsItemId = a[0].cmsItemId
          vm.addAttachment(attachment)
        }}
        toggleShowModal={() => vm.toggleFileUploadModal()}
        isOpen={vm.showFileUploadModal}
      />
    )
  }

  const renderStandardUploadModal = () => {
    return (
      <FileUploadModal
        key={`file-${vm.key}`}
        allowMultiple={false}
        fileTypes={FileTypeEnum.FILES}
        addAfterSave={(a: Attachment[]) => vm.addAttachment(a[0])}
        toggleShowModal={() => vm.toggleFileUploadModal()}
        isOpen={vm.showFileUploadModal}
      />
    )
  }

  const renderDrawer = () => {
    if (vm.mediaUploadPickerVM.showMediaUploadPicker) return null
    return (
      <>
        <Grid container>{renderHeader()}</Grid>
        <Grid container alignContent='flex-start' className='drawer-body'>
          {renderBody()}
        </Grid>
        {renderMenu()}
      </>
    )
  }

  return (
    <Slide direction={'left'} in={trainingItemsStore.trainingItemEditVM.builderDrawerOpen}>
      <Paper id='BuilderDrawer' square elevation={6}>
        {renderMediaToolbar()}
        {renderMediaUpload()}
        {renderFileUploadModal()}
        {renderDrawer()}
      </Paper>
    </Slide>
  )
}

export default inject(
  'trainingItemsStore',
  'labelsStore',
  'localizationStore',
  'organizationsStore'
)(observer(BuilderDrawer))
