import { CardContent } from '@material-ui/core'
import React, { Fragment } from 'react'
import { QuizTakeVM } from '../../view-models/QuizTakeVM'
import QuestionCard from './QuestionCard'
import SubmitRow from './SubmitRow'
import './QuizTake.scss'
import QuestionDivider from './QuestionDivider'
import { UserTrainingPlansStore } from '../../../training-plans/user-training-plans/store/UserTrainingPlansStore'
import { inject, observer } from 'mobx-react'
import QuizConfirmDialog from './QuizConfirmDialog'

interface Props {
  vm: QuizTakeVM
  userTrainingPlansStore?: UserTrainingPlansStore
}

const QuizTake: React.FC<Props> = ({ vm, userTrainingPlansStore }) => {
  if (!vm) return null

  const renderContent = () => {
    return vm.questions.map((q, idx) => (
      <Fragment key={'questionrow' + idx}>
        <QuestionCard vm={vm} question={q} index={idx} />
        <QuestionDivider />
      </Fragment>
    ))
  }

  const renderSubmitRow = () => {
    return <SubmitRow vm={vm} />
  }

  return (
    <div id='QuizTake'>
      <QuizConfirmDialog vm={vm} />
      <CardContent>
        {renderContent()}
        {renderSubmitRow()}
      </CardContent>
    </div>
  )
}

export default inject('userTrainingPlansStore')(observer(QuizTake))
