import React, { FC } from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { CategoriesStore } from 'src/app/categories/CategoriesStore'
import { OutlinedInput, InputLabel } from '@material-ui/core'
import { LabelsStore } from '../../../../labels/store/LabelsStore'
import CategoryPicker from 'src/app/shared/category-picker/CategoryPicker'

interface DrawerCategoryDetails {
  localizationStore?: LocalizationStore
  categoriesStore?: CategoriesStore
  labelsStore?: LabelsStore
}

const DrawerCategoryDetails: FC<DrawerCategoryDetails> = ({
  localizationStore,
  categoriesStore,
  labelsStore,
}) => {
  const { lzStrings } = localizationStore
  const { widgetForm } = categoriesStore

  const handleChange = (event) => {
    widgetForm.setField(event.target.id, event.target.value)
  }

  const handleSelect = (e) => {
    if (Boolean(e)) {
      widgetForm.setField('parentCategoryId', e)
    } else {
      widgetForm.setField('parentCategoryId', '')
    }
  }

  return (
    <>
      <section>
        <form>
          <InputLabel htmlFor='name' required>
            {lzStrings.categoriesManagementWidgetDrawer.name}
          </InputLabel>
          <OutlinedInput
            id='name'
            fullWidth
            labelWidth={0}
            autoComplete='off'
            value={widgetForm.name}
            onChange={handleChange}
            error={!widgetForm.isNameValid}
          />
          <InputLabel htmlFor='description'>
            {lzStrings.categoriesManagementWidgetDrawer.description}
          </InputLabel>
          <OutlinedInput
            id='description'
            fullWidth
            labelWidth={0}
            autoComplete='off'
            multiline
            rows={6}
            maxRows={8}
            margin='none'
            value={widgetForm.description}
            onChange={handleChange}
          />
          <InputLabel htmlFor='parentCategoryId'>
            {localizationStore.language === 'English'
              ? `${
                  lzStrings.categoriesManagementWidgetDrawer.parent
                } ${labelsStore.getLabelByLanguageAndFor('category')}`
              : `${
                  lzStrings.categoriesManagementWidgetDrawer.parent
                }${labelsStore.getLabelByLanguageAndFor('category')}`}
          </InputLabel>
          <div className='drawerCategoriesContainer'>
            <CategoryPicker
              onApply={(e) => handleSelect(e)}
              vm={widgetForm.categoryPickerVM}
              fullWidth={true}
            />
          </div>
        </form>
      </section>
    </>
  )
}

export default inject(
  'localizationStore',
  'categoriesStore',
  'labelsStore'
)(observer(DrawerCategoryDetails))
