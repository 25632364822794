import Parse from 'parse'
import { UserFederationSettings } from '../../organizations/aggregate/UserFederationSettings'
import { IOrganizationDTO } from 'src/app/organizations/dtos/IOrganizationDTO'
import { IUserFederationSettings } from '../../federatedImports/interfaces/IUserFederationSettings'
import { ParseService } from 'src/app/services/ParseService'
import { IAPIResponse } from 'src/app/shared/IAPIResponse'

export class OrganizationsSAService {
  public async getOrganizations() {
    const organizations = await Parse.Cloud.run('getOrganizations')
    return Array.isArray(organizations) ? organizations : []
  }

  public async deleteOrganization(id) {
    const result = await Parse.Cloud.run('deleteOrganization', { organizationId: id })
    if (!result) return 'ERROR DELETING ORGANIZATION'
    console.log({ result })
    return result
  }

  public async saveOrganization(organization) {
    const orgId = organization.objectId
    const result = await Parse.Cloud.run('saveOrganizationAsSystemAdmin', { orgId, organization })
    if (!result) return 'ERROR SAVING ORGANIZATION'
    return result
  }

  public async getZones() {
    const zones = await Parse.Cloud.run('getZones')
    return zones
  }

  public async addOrganization(name, adminId, zone, templateId) {
    const result = await Parse.Cloud.run('createNewOrganization', {
      name,
      adminId,
      zone,
      templateId,
    })
    if (!result.success) return 'ERROR SAVING NEW ORGANIZATION'
    return result.organizationId
  }

  public async checkOrgTemplateName(name) {
    const result = await Parse.Cloud.run('checkOrganizationTemplateName', { name })
    return result.exists
  }

  public async checkOrgName(name: string, organizationId: string) {
    const result = await Parse.Cloud.run('checkOrganizationName', { name, organizationId })
    return result.exists
  }

  public async createOrganizationTemplate(name: string, organizationId: string) {
    const result = await Parse.Cloud.run('createOrganizationTemplate', { name, organizationId })
    console.log({ result })
    if (!result.success) return 'Failed to create new Template' + result.errorMessage
    return organizationId
  }

  public async searchOrganizationTemplates(terms: string) {
    const result = await Parse.Cloud.run('searchOrganizationTemplates', { terms })

    return result
  }

  public async getOrganizationLabels(organizationId: string) {
    const orgLabels = await Parse.Cloud.run('getOrgLabels', { organizationId })
    return Array.isArray(orgLabels) ? orgLabels : []
  }

  public async saveOrgLabel(label) {
    const { organizationId, value, language, selector } = label
    const result = await Parse.Cloud.run('saveOrgLabel', {
      organizationId,
      value,
      language,
      selector,
    })
    if (!result) return 'ERROR SAVING LABEL'
    return 'SUCCESS'
  }

  public async testFederatedUserSync(
    federationAdapterSettings: IUserFederationSettings
  ): Promise<IAPIResponse> {
    const result = await Parse.Cloud.run('testFederatedUserSync', { federationAdapterSettings })
    return result
  }

  // requires the organization to have been saved with the appropriate sttings
  public async startFederatedUserSync(
    organizationId: string,
    isFull: boolean
  ): Promise<IAPIResponse> {
    const result = await Parse.Cloud.run('executeFederatedUserSync', { organizationId, isFull })
    return result
  }

  public async getGroupsFromAdapter(organizationId: string, terms?: string): Promise<IAPIResponse> {
    const result = await Parse.Cloud.run('getGroupsFromAdapter', { organizationId, terms })

    return result
  }
}
