import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Icon from './Icon'
import { inject, observer } from 'mobx-react'
import './AddQuestionMenu.scss'
import { SurveysStore } from 'src/app/surveys/store/SurveysStore'
import LocalizationStore from 'src/app/localization/LocalizationStore'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: any) => (
  <Menu
    id={props.id}
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles((theme) => ({
  // root: {
  //   '&:focus': {
  //     backgroundColor: theme.palette.primary.main,
  //     '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
  //       color: theme.palette.common.white,
  //     },
  //   },
  // },
}))(MenuItem)

interface Props {
  surveysStore?: SurveysStore
  localizationStore?: LocalizationStore
}

const AddQuestionMenu: React.FC<Props> = ({ surveysStore, localizationStore }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const { editVM } = surveysStore.viewModels
  if (!editVM) return null

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    if (editVM.questions.length !== 0) {
      const mostRecentQuestion = editVM.questions.length - 1
      editVM.setCurrentQuestion(mostRecentQuestion)
    }
  }

  const addQuestionButton = () => {
    if (editVM.addButtonToFooter) return null
    return (
      <Button
        disabled={editVM.isReadOnly}
        aria-controls='customized-menu'
        aria-haspopup='true'
        variant='contained'
        className='add-question-button'
        onClick={handleClick}
        ref={(e: any) => {
          if (e) editVM.setAddQuestionButtonRect(e.getBoundingClientRect())
        }}
      >
        Add Question
        <Icon icon={'fa fa-caret-down'} />
      </Button>
    )
  }

  const addQuestionFooter = () => {
    if (!editVM.addButtonToFooter) return null
    return (
      <div id='footContainer'>
        <div className='addFooter'>
          <Button
            disabled={editVM.isReadOnly}
            aria-controls='customized-menu'
            aria-haspopup='true'
            variant='contained'
            className='add-question-button'
            onClick={handleClick}
            ref={(e: any) => {
              if (e) editVM.setAddQuestionButtonRect(e.getBoundingClientRect())
            }}
          >
            Add Question
            <Icon icon={'fa fa-caret-down'} />
          </Button>
        </div>
      </div>
    )
  }

  const renderAddFromCatalog = () => {
    if (editVM.isSurveyTemplateEdit && editVM.survey.organizationId === 'DEFAULT') return null
    return (
      <StyledMenuItem
        className='catalog'
        onClick={() => {
          editVM.toggleQuestionCatalog()
          handleClose()
        }}
      >
        <ListItemIcon className='icon blue'>
          <Icon icon={'fas fa-plus-circle'} />
        </ListItemIcon>
        <ListItemText primary='Add from Catalog' />
      </StyledMenuItem>
    )
  }

  return (
    <div id='AddQuestionMenu'>
      {addQuestionButton()}
      {addQuestionFooter()}
      <StyledMenu
        id='customized-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem
          className='menu-item'
          onClick={() => {
            editVM.addQuestionByType('emoji')
            handleClose()
          }}
        >
          <ListItemIcon className='icon brown'>
            <Icon icon={'far fa-smile'} />
          </ListItemIcon>
          <ListItemText primary='Emoji' />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            editVM.addQuestionByType('check')
            handleClose()
          }}
        >
          <ListItemIcon className='icon blue'>
            <Icon icon={'far fa-check-square'} />
          </ListItemIcon>
          <ListItemText primary='Multi-select' />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            editVM.addQuestionByType('radio')
            handleClose()
          }}
        >
          <ListItemIcon className='icon green'>
            <Icon icon={'far fa-dot-circle'} />
          </ListItemIcon>
          <ListItemText primary='Multiple Choice' />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            editVM.addQuestionByType('rating')
            handleClose()
          }}
        >
          <ListItemIcon className='icon orange'>
            <Icon icon={'fas fa-list-ol'} />
          </ListItemIcon>
          <ListItemText primary='Rating' />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            editVM.addQuestionByType('starRating')
            handleClose()
          }}
        >
          <ListItemIcon className='icon yellow'>
            <Icon icon={'fas fa-star'} />
          </ListItemIcon>
          <ListItemText primary='Star Rating' />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            editVM.addQuestionByType('text')
            handleClose()
          }}
        >
          <ListItemIcon className='icon red'>
            <Icon icon={'fas fa-font'} />
          </ListItemIcon>
          <ListItemText primary='Short Answer' />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            editVM.addQuestionByType('yesNo')
            handleClose()
          }}
        >
          <ListItemIcon className='icon purple'>
            <Icon icon={'far fa-thumbs-up'} />
          </ListItemIcon>
          <ListItemText primary='Yes or No' />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            editVM.addQuestionByType('number')
            handleClose()
          }}
        >
          <ListItemIcon className='icon orange'>
            <Icon icon={'fas fa-calculator'} />
          </ListItemIcon>
          <ListItemText primary='Number' />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            editVM.addQuestionByType('order')
            handleClose()
          }}
        >
          <ListItemIcon className='icon pink'>
            <Icon icon={'fas fa-sort-amount-up'} />
          </ListItemIcon>
          <ListItemText primary='Order' />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            editVM.addQuestionByType('dropdown')
            handleClose()
          }}
        >
          <ListItemIcon className='icon green'>
            <Icon icon={'fas fa-caret-square-down'} />
          </ListItemIcon>
          <ListItemText primary='Dropdown' />
        </StyledMenuItem>
        <StyledMenuItem
          className='catalog'
          onClick={() => {
            editVM.addQuestionByType('infoText')
            handleClose()
          }}
        >
          <ListItemIcon className='icon blue'>
            <Icon icon={'fas fa-info-circle'} />
          </ListItemIcon>
          <ListItemText primary='Info Text' />
        </StyledMenuItem>
        {renderAddFromCatalog()}
        <StyledMenuItem
          className='catalog'
          onClick={() => {
            editVM.toggleImportModal()
            handleClose()
          }}
        >
          <ListItemIcon className='icon green'>
            <Icon icon={'fas fa-plus-circle'} />
          </ListItemIcon>
          <ListItemText primary='Import from csv' />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  )
}

export default inject('surveysStore', 'localizationStore')(observer(AddQuestionMenu))
