import React, { FC } from 'react'
import { ParticipantsSelectVM } from '../view-models/ParticipantsSelectVM'
import { Grid } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import TabButtons from './TabButtons'
import RolesList from './RolesList'
import SelectedPane from './SelectedPane'
import GroupsList from './GroupsList'
import ByEmailTab from './ByEmailTab'
import ClientsList from './ClientsList'
import UsersList from './UsersList'
import UsersListModal from './UsersListModal'
import ContactsListModal from './ContactsListModal'
import '../styles/ParticipantsSelect.scss'
import FromEventTab from './FromEventTab'
import { SurveysStore } from 'src/app/surveys/store/SurveysStore'
import { AdditionalResponseNotificationEditorVM } from '../../surveys/view-models/AdditionalResponseNotificationEditorVM'

interface Props {
  participantsSelectVM: ParticipantsSelectVM
  surveysStore?: SurveysStore
  responseNotificationVM?: AdditionalResponseNotificationEditorVM
}
const ParticipantsSelect: FC<Props> = ({
  participantsSelectVM,
  surveysStore,
  responseNotificationVM,
}) => {
  const vm = participantsSelectVM
  const { editVM } = surveysStore.viewModels
  let visibilityVM
  if (editVM && editVM.visibilityEditorVM) {
    visibilityVM = editVM.visibilityEditorVM
  }

  const renderUsersListModal = () => {
    if (!vm.isUsersListModalOpen) return
    return <UsersListModal groupedRowVM={vm.openGroupedRow} />
  }

  const renderContactsListModal = () => {
    if (!vm.isContactsListModalOpen) return
    return <ContactsListModal clientRowVM={vm.openClientRow} />
  }

  const renderSelectedPane = () => {
    if (responseNotificationVM)
      return (
        <SelectedPane
          participantsSelectVM={vm}
          additionalResponseNotificationEditorVM={responseNotificationVM}
        />
      )
    if (visibilityVM)
      return <SelectedPane participantsSelectVM={vm} visibilityEditorVM={visibilityVM} />
    else if (!visibilityVM && !responseNotificationVM)
      return <SelectedPane participantsSelectVM={vm} />
  }

  return (
    <>
      <div id='ParticipantsSelect' style={{ display: vm.isVisible ? undefined : 'none' }}>
        <Grid container justifyContent='center'>
          <Grid item xs className='select-column'>
            <Grid container id='virtualListHeader'>
              <Grid item xs md lg className='tab-buttons'>
                <TabButtons participantsSelectVM={vm} />
              </Grid>
            </Grid>
            <Grid item xs className='participants-list'>
              <UsersList usersAGGridVM={vm.usersAGGridVM} />
              <RolesList participantsSelectVM={vm} />
              <GroupsList participantsSelectVM={vm} />
              <ClientsList participantsSelectVM={vm} />
              <ByEmailTab participantsSelectVM={vm} />
              <FromEventTab participantsSelectVM={vm} />
            </Grid>
          </Grid>
          <Grid item xs md lg className='selected-column'>
            {renderSelectedPane()}
          </Grid>
        </Grid>
      </div>
      {renderUsersListModal()}
      {renderContactsListModal()}
    </>
  )
}

export default inject('surveysStore')(observer(ParticipantsSelect))
