import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../localization/LocalizationStore'
import { ClientsStore } from '../store/ClientsStore'
import { OutlinedInput, InputLabel } from '@material-ui/core'
import { LabelsStore } from '../../labels/store/LabelsStore'

interface ClientInfoProps {
  localizationStore?: LocalizationStore
  clientsStore?: ClientsStore
  labelsStore?: LabelsStore
}

const ClientInfo: React.FC<ClientInfoProps> = ({
  labelsStore,
  localizationStore,
  clientsStore,
}) => {
  const { lzStrings } = localizationStore
  const { clientEditVM: vm } = clientsStore

  return (
    <section id='ClientInfo'>
      <h6>{lzStrings.organizationWidgetDrawer.basic_info}</h6>
      <form>
        <InputLabel required htmlFor='name'>
          {labelsStore.getLabelByLanguageAndFor('client')}
        </InputLabel>
        <OutlinedInput
          id='name'
          fullWidth
          labelWidth={0}
          autoComplete='off'
          value={vm.name}
          onChange={e => vm.setName(e.target.value)}
          error={vm.nameError}
        />
        <InputLabel htmlFor='description'>
          {lzStrings.clientManagementWidget.description}
        </InputLabel>
        <OutlinedInput
          id='description'
          fullWidth
          labelWidth={0}
          autoComplete='off'
          value={vm.description}
          onChange={e => vm.setDescription(e.target.value)}
          multiline
          rows={4}
          maxRows={4}
        />
      </form>
    </section>
  )
}

export default inject('labelsStore', 'localizationStore', 'clientsStore')(observer(ClientInfo))
