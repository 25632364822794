import { persist } from 'mobx-persist'
import { observable, action } from 'mobx'
import { serialize } from 'serializr'
import { IAggregate } from '../../shared/data/IAggregate'
import { ISurveyResultDTO } from '../dtos/ISurveyResultDTO'

export class SurveyResult implements ISurveyResultDTO, IAggregate {
  @persist @observable public objectId: string = ''
  @persist @observable public surveyId: string = ''
  @persist @observable public organizationId: string = ''
  @persist('object') @observable public questionAnswers: any = null
  @persist @observable public isDeleted: boolean = false

  isOnServer: boolean = false

  @action
  public markIsOnServer() {
    this.isOnServer = true
  }

  @action
  public markIsNotOnServer() {
    this.isOnServer = false
  }

  public toDTO(): ISurveyResultDTO {
    return serialize(this)
  }

  public serialize(): ISurveyResultDTO {
    return serialize(this)
  }
}
