import { RootStore } from '../../stores/RootStore'
import { computed, observable, action } from 'mobx'
import { UserSurveyRowVM } from './UserSurveyRowVM'
import { EventRowVM } from './EventRowVM'
import { TaskRowVM } from './TaskRowVM'
import { GoalRowVM } from './GoalRowVM'
import { NotificationsWidgetService } from '../service/NotificationsWidgetService'
import { SurveyResponseRowVM } from './SurveyResponseRowVM'

export class NotificationsWidgetVM {
  private rootStore: RootStore
  private svc: NotificationsWidgetService

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.svc = new NotificationsWidgetService(this.rootStore)
  }

  @observable public notificationsWidgetOpen: boolean = false
  @observable public anchorEl: any = null
  @observable public markingAllRead: boolean = false

  @action
  public async markAllAsRead() {
    if (this.rows.length === 0) return
    this.markingAllRead = true
    await this.svc.markAllAsRead(
      this.surveyIds,
      [],
      this.goalIds,
      this.eventIds,
      this.taskIds,
      this.rootStore.appStore.currentOrgId
    )
    this.markingAllRead = false
  }

  @computed
  private get surveyIds(): string[] {
    return this.unreadUserSurveys.map((userSurvey) => userSurvey.id)
  }

  @computed
  private get goalIds(): string[] {
    return this.unreadUserGoals.map((userGoal) => userGoal.id)
  }

  @computed
  private get taskIds(): string[] {
    return this.unreadUserTasks.map((userTask) => userTask.id)
  }

  @computed
  private get eventIds(): string[] {
    return this.unreadUserEvents.map((userEvent) => userEvent.userEvent.eventId)
  }

  @action
  public toggleNotificationsWidgetOpen() {
    this.notificationsWidgetOpen = !this.notificationsWidgetOpen
  }

  @action
  public setAnchorEl(e) {
    this.anchorEl = e
  }

  @action
  public clearAnchorEl() {
    this.anchorEl = null
    this.toggleNotificationsWidgetOpen()
  }

  @computed
  public get rows() {
    return [
      ...this.unreadUserEvents,
      ...this.unreadUserGoals,
      ...this.unreadUserSurveys,
      ...this.unreadUserTasks,
    ].sort((a, b) => (a.createdSeconds > b.createdSeconds ? 1 : -1))
  }

  @computed
  public get notificationCount(): number {
    if (this.rows.length > 99) return 99
    return this.rows.length
  }

  @computed
  public get unreadUserSurveys(): UserSurveyRowVM[] {
    return this.rootStore.userSurveysStore.currentOrgUserSurveys
      .filter((survey) => !survey.isRead)
      .filter((survey) => !survey.isWelcomeEmail)
      .map((e) => new UserSurveyRowVM(this.rootStore, e))
  }

  @computed
  public get unreadUserEvents(): EventRowVM[] {
    return this.rootStore.userEventsStore.currentOrgUserEvents
      .filter((event) => {
        const foundEvent = this.rootStore.eventsStore.getEvent(event.eventId)
        if (!foundEvent) return false
        return !foundEvent.readUserIds.includes(this.rootStore.appStore.currentUserId)
      })
      .filter((event) => !event.isDeleted && event.readStatus !== 'read')
      .map((e) => new EventRowVM(this.rootStore, e))
  }

  @computed
  public get unreadUserTasks(): TaskRowVM[] {
    return this.rootStore.userTasksStore.currentOrgTasks
      .filter(
        (userTask) =>
          !userTask.isRead &&
          !userTask.isCompleted &&
          userTask.name &&
          userTask.userId === this.rootStore.appStore.currentUserId
      )
      .map((e) => new TaskRowVM(this.rootStore, e))
  }

  @computed
  public get unreadSurveyResponses(): SurveyResponseRowVM[] {
    return []
  }

  @computed
  public get unreadUserGoals(): GoalRowVM[] {
    return this.rootStore.userGoalsStore.orgUserGoals
      .filter((userGoal) => !userGoal.isRead)
      .map((e) => new GoalRowVM(this.rootStore, e))
  }
}
