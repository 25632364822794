import Parse from 'parse'
import { RootStore } from '../../stores/RootStore'
import { IUserDetailsDTO } from '../dtos/IUserDetailsDTO'
import { IUserSaveResult } from '../interfaces/IUserSaveResult'
import { IOrganizationUsersAudienceRoleSearchRequest } from '../interfaces/IOrganizationUsersAudienceRoleSearchRequest'
import { IOrganizationUsersAudienceRoleSearchResult } from '../interfaces/IOrganizationUsersAudienceRoleSearchResult'
import { IUserSavePasswordResult } from '../interfaces/IUserSavePasswordResult'
import { IOneTimeUsePasscodeResult } from '../interfaces/IOneTimeUsePasscodeResult'
import { IOrganizationUserDTO } from '../dtos/IOrganizationUserDTO'
import { IOrganizationUsersFindRequest } from '../interfaces/IOrganizationUsersFindRequest'
import { IOrganizationUsersFindResult } from '../interfaces/IOrganizationUsersFindResult'
import { IOrganizationUsersListPreferencesSaveRequest } from '../interfaces/IOrganizationUsersListPreferencesSaveRequest'
import { IOrganizationUsersListPreferencesSaveResult } from '../interfaces/IOrganizationUsersListPreferencesSaveResult'
import { IOrganizationUsersListPreferencesFindRequest } from '../interfaces/IOrganizationUsersListPreferencesFindRequest'
import { IOrganizationUsersListPreferencesFindResult } from '../interfaces/IOrganizationUsersListPreferencesFindResult'

export class OrganizationUsersService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public async importUsersFromCsvV2(orgId, parseFile, doSave, batchId) {
    const result = await Parse.Cloud.run('importUsersFromCsvV2', {
      orgId,
      parseFile,
      doSave,
      batchId,
    })
    return result
  }

  public async getOrganizationUser(orgUserId): Promise<IOrganizationUserDTO> {
    return await Parse.Cloud.run('getOrganizationUser', {
      organizationId: this.rootStore.appStore.currentOrgId,
      organizationUserId: orgUserId,
    })
  }

  public async impersonateUser(userId) {
    const sessionToken = await Parse.Cloud.run('getUserSessionToken', { userId })
    if (!sessionToken) {
      alert('User does not currently have a logged in session. Unable to impersonate.')
      return
    }
    await Parse.User.become(sessionToken)
    this.rootStore.appStore.setCurrentUser(Parse.User.current().id, true)
    window.location.href = '/'
  }

  public async addUser(orgId: string, userDetails: IUserDetailsDTO): Promise<IUserSaveResult> {
    return await Parse.Cloud.run('addUser', {
      orgId,
      user: userDetails,
    })
  }

  public async updateUser(
    orgId: string,
    userId: string,
    userDetails: IUserDetailsDTO
  ): Promise<IUserSaveResult> {
    return await Parse.Cloud.run('updateUser', {
      orgId,
      userId,
      user: userDetails,
    })
  }

  public async updateUserPassword(
    userId: string,
    currentPassword: string,
    password: string
  ): Promise<IUserSavePasswordResult> {
    return await Parse.Cloud.run('updateUserPassword', {
      userId,
      currentPassword,
      password,
    })
  }

  public async searchAudienceUsers(req) {
    const result = await Parse.Cloud.run('searchAudienceUsers', req)
    return result
  }

  public async searchAudienceGroups(req) {
    const result = await Parse.Cloud.run('searchAudienceGroups', req)
    return result
  }

  public async searchAudienceRoles(
    req: IOrganizationUsersAudienceRoleSearchRequest
  ): Promise<IOrganizationUsersAudienceRoleSearchResult> {
    const result = await Parse.Cloud.run('searchAudienceRoles', req)
    return result
  }

  public async createOneTimeUsePasscode(
    userId: string,
    loggedInUserId: string
  ): Promise<IOneTimeUsePasscodeResult> {
    const result = await Parse.Cloud.run('createOneTimeUsePasscode', { userId, loggedInUserId })
    return result
  }

  public async findOrganizationUsers(
    request: IOrganizationUsersFindRequest
  ): Promise<IOrganizationUsersFindResult> {
    return await Parse.Cloud.run('findOrganizationUsers', { request })
  }

  public async saveOrganizationUsersListPreferences(
    req: IOrganizationUsersListPreferencesSaveRequest
  ): Promise<IOrganizationUsersListPreferencesSaveResult> {
    return await Parse.Cloud.run('saveOrganizationUsersListPreferences', req)
  }

  public async getOrganizationUsersListPreferences(
    req: IOrganizationUsersListPreferencesFindRequest
  ): Promise<IOrganizationUsersListPreferencesFindResult> {
    return await Parse.Cloud.run('findOrganizationUsersListPreferences', req)
  }
}
