import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../localization/LocalizationStore'
import { SurveyTemplateFoldersPickerVM } from '../view-models/SurveyTemplateFoldersPickerVM'
import TreeItem from '@material-ui/lab/TreeItem'
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import NewFolderIcon from '@material-ui/icons/CreateNewFolderOutlined'
import DeleteIcon from '@material-ui/icons/DeleteOutline'
import EditIcon from '@material-ui/icons/EditOutlined'

import './SurveyTemplateFolder.scss'
import SurveyTemplateFolderEdit from './SurveyTemplateFolderEdit'

interface Props {
  nodes: any
  vm: SurveyTemplateFoldersPickerVM
}

const SurveyTemplateFolder: React.FC<Props> = ({ nodes, vm }) => {
  const renderActions = (node) => {
    if (!vm.showFolderActions) return null
    // if (!vm.showActions) return renderCheckbox(node)
    if (vm.manageVM.screenType === 'tenantManage') {
      if (vm.isSystemToggleMode) return null
    }
    if (node.id !== vm.selectedFolder?.id) return null
    if (node.id === 'no-folder') return null
    return (
      <div className={'folder-actions'}>
        <Tooltip title={'Edit'}>
          <EditIcon
            className='action-icon'
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              vm.editFolder(node.id)
            }}
          />
        </Tooltip>
        <Tooltip title={'Delete'}>
          <DeleteIcon
            className='action-icon'
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              vm.toggleDeleteDialog()
            }}
          />
        </Tooltip>
        <Tooltip title={'New Folder'}>
          <NewFolderIcon
            className='action-icon'
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              vm.addNewChildFolder(node.id)
            }}
          />
        </Tooltip>
      </div>
    )
  }

  // const renderCheckbox = (node) => {
  //   if (node.id === 'no-folder') return null
  //   if (node.id !== vm.selectedFolder?.id) return null
  //   return (
  //     <Checkbox
  //       checked={vm.folderChecked}
  //       onClick={(e) => {
  //         e.preventDefault()
  //         e.stopPropagation()
  //         vm.toggleSelectedFolderChecked()
  //       }}
  //     />
  //   )
  // }

  const renderLabel = (node) => {
    if (vm.folderEditVM?.objectId === node.id)
      return <SurveyTemplateFolderEdit vm={vm.folderEditVM} />
    return (
      <div className={'tree-item-label'} key={node.id} onClick={() => vm.setSelectedFolder(node)}>
        <div className={'item-left'}>
          <Typography>{node.name}</Typography>
        </div>
        <div className={'item-right'}>{renderActions(node)}</div>
      </div>
    )
  }

  const renderTree = (nodes) => (
    <TreeItem key={nodes.id} nodeId={nodes.id} label={renderLabel(nodes)}>
      {Array.isArray(nodes.children) ? nodes.children.map((node, i) => renderTree(node)) : null}
    </TreeItem>
  )

  return <div id={'SurveyTemplateFolder'}>{renderTree(nodes)}</div>
}

export default inject('localizationStore')(observer(SurveyTemplateFolder))
