import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import MuiConfirmPrompt from '../../../shared/MuiConfirmPrompt'
import { CMSItemsPreviewVM } from '../../view-models/CMSItemsPreviewVM'

interface Props {
  vm: CMSItemsPreviewVM
  localizationStore?: LocalizationStore
}

const CMSItemsConfirmDialgo: React.FC<Props> = ({ vm, localizationStore }) => {
  const { contentManagement: lz } = localizationStore.lzStrings

  if (!vm) return
  if (!vm.confirmDialogOpen) return

  if (vm.doReset) {
    return (
      <MuiConfirmPrompt
        confirmMessage={lz.are_you_sure_reset}
        onClose={() => vm.hideConfirmDialog()}
        open={vm.confirmDialogOpen}
        onConfirm={() => vm.reset()}
        title={lz.reset_item}
      />
    )
  }
  if (vm.doClose) {
    return (
      <MuiConfirmPrompt
        confirmMessage={lz.are_you_sure_close}
        onClose={() => vm.hideConfirmDialog()}
        open={vm.confirmDialogOpen}
        onConfirm={() => vm.closePreviewDialog()}
        title={lz.close_item}
      />
    )
  }
  return (
    <MuiConfirmPrompt
      confirmMessage={lz.are_you_sure_save}
      onClose={() => vm.hideConfirmDialog()}
      open={vm.confirmDialogOpen}
      onConfirm={() => vm.handleEdit()}
      title={lz.save_item}
    />
  )
}

export default inject('localizationStore')(observer(CMSItemsConfirmDialgo))
