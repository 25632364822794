import React from 'react'
import clsx from 'clsx'
import { inject, observer } from 'mobx-react'
import { ButtonBase } from '@material-ui/core'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { TrainingPlansStore } from 'src/app/training-plans/store/TrainingPlansStore'

interface WidgetTitleProps {
  localizationStore?: LocalizationStore
  trainingPlansStore?: TrainingPlansStore
}

const Title: React.FC<WidgetTitleProps> = ({ localizationStore, trainingPlansStore }) => {
  const { training_plans: strings } = localizationStore.lzStrings
  const { manageTrainingsWidgetVM: vm } = trainingPlansStore

  if (!vm) return

  return (
    <div className='widget-title-tabs'>
      <span>{strings.manage_trainings}</span>
      <div className='tab-btns'>
        <ButtonBase
          onClick={() => vm.setTabIndex(0)}
          className={clsx('tab-button', {
            active: vm.tabIndex === 0,
          })}
        >
          ACTIVE
        </ButtonBase>
        <ButtonBase
          onClick={() => vm.setTabIndex(1)}
          className={clsx('tab-button', {
            active: vm.tabIndex === 1,
          })}
        >
          ARCHIVED
        </ButtonBase>
      </div>
    </div>
  )
}

export default inject('localizationStore', 'trainingPlansStore')(observer(Title))
