import { observable, computed, action, reaction } from 'mobx'

export class PolarityScaleVM {
  constructor(polarity, showScale, arrowColor) {
    this.polarity = polarity ? polarity : 0
    this.showScale = showScale
    this.arrowColor = arrowColor
  }

  @observable public polarity: number = 0
  @observable public showScale: boolean = true
  @observable public arrowColor: boolean = false

  @computed public get calculatedPolarity() {
    const margin = this.polarity / 3.33
    return margin
  }

  @computed public get polarityColor() {
    if (!this.arrowColor) return '#738599'
    if (this.polarity < -40) return 'red'
    if (this.polarity < 40) return 'orange'
    return 'green'
  }

  @computed public get hideNegativeHundo() {
    if (!this.showScale) return true
    if (this.polarity < -85) return true
    return false
  }

  @computed public get hideHundo() {
    if (!this.showScale) return true
    if (this.polarity > 85) return true
    return false
  }

  @computed public get hideZero() {
    if (!this.showScale) return true
    if (this.polarity < 15 && this.polarity > -15) return true
    return false
  }

  @computed public get arrowMargin() {
    const length = this.polarity.toString().length
    if (length === 2) return '5px'
    if (length === 3) return '12px'
    if (length === 4) return '12px'
    return '0px'
  }

  @computed public get arrowColored() {
    if (!this.arrowColor) return '#738599'
    return this.polarityColor
  }
}
