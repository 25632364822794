import { action, observable, computed } from 'mobx'
import { QuizResult } from '../aggregate/QuizResult'
import { RootStore } from '../../stores/RootStore'
import { Quiz } from '../../quiz/aggregate/Quiz'
import { QuizResultQuestionVM } from './QuizResultQuestionVM'
import moment from 'moment'

export class QuizResultsVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, quiz: Quiz, quizId: string, trainingPlanId: string) {
    this.rootStore = rootStore
    this.quiz = quiz
    this.quizId = quizId
    this.trainingPlanId = trainingPlanId
    this.rootStore.quizResultsStore.setCurrentIds(this.quizId, this.trainingPlanId)
  }

  @observable public quiz: Quiz = null
  @observable public quizId: string = ''
  @observable public trainingPlanId: string = ''

  @computed
  public get myScore(): number {
    return this.quiz.score
  }

  @computed
  public get averageScore(): number {
    return Math.ceil((this.quizResult.totalScores / this.quizResult.totalResponses))
  }

  @computed
  public get quizResult(): QuizResult {
    return this.rootStore.quizResultsStore.currentOrgQuizResults.find(
      (e) => e.quizId === this.quizId && e.trainingPlanId === this.trainingPlanId
    )
  }

  @computed
  public get questions(): QuizResultQuestionVM[] {
    return this.quiz.questions.map((e, i) => new QuizResultQuestionVM(this.rootStore, e, this.quizResult, i))
  }

  @computed
  public get questionsCorrect(): number {
    return this.questions.filter(e => e.isCorrect).length
  }

  @computed
  public get questionsToScore(): number {
    return this.questions.length
  }

  @computed
  public get formattedCompletionDate(): string {
    if (!this.quiz.completedAt) return ''
    const lz = this.rootStore.localizationStore.lzStrings.quiz
    return moment(this.quiz.completedAt).format(lz.completed_at_format)
  }
}
