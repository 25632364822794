import Parse from 'parse'
import { observable, action, computed, runInAction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { ISurveyTagDTO } from '../dtos/ISurveyTagDTO'
import WidgetForm from '../forms/WidgetForm'
import SurveyTag from '../aggregate/SurveyTag'
import { SurveyTagsWidgetVM } from '../view-model/SurveyTagsWidgetVM'
import { DataStore } from '../../shared/data/DataStore'

export class SurveyTagsStore extends DataStore<SurveyTag, ISurveyTagDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, SurveyTag, 'surveyTags', ['organizationId', 'name', 'description'])
  }

  @observable public surveyTagsWidgetVM: SurveyTagsWidgetVM = null
  @observable public selectedSurveyTagId: string = ''
  @observable public widgetForm: WidgetForm = null
  @observable public isWidgetDrawerOpen: boolean = false
  @observable public loaded: boolean = false

  @computed
  public get surveyTags(): SurveyTag[] {
    return this.records
  }

  @action
  public onListRecordsLoaded() {
    this.surveyTagsWidgetVM = new SurveyTagsWidgetVM(this.rootStore)
    this.loaded = true
  }

  @action
  public setSelectedSurveyTagId(id) {
    this.selectedSurveyTagId = id
    this.widgetForm = new WidgetForm(this.rootStore, this.getSurveyTag(id))
    this.openWidgetDrawer()
  }

  @action
  public addNewSurveyType() {
    this.selectedSurveyTagId = null
    this.widgetForm = new WidgetForm(this.rootStore)
    this.openWidgetDrawer()
  }

  public getSurveyTag(objectId): SurveyTag {
    return this.surveyTags.find((e) => e.objectId === objectId)
  }

  @action public openWidgetDrawer() {
    this.isWidgetDrawerOpen = true
  }

  @action public closeWidgetDrawer() {
    this.isWidgetDrawerOpen = false
  }

  public clearForms() {
    this.selectedSurveyTagId = ''
  }
}
