import React from 'react'
import { Card } from '@material-ui/core'
import { observer, inject } from 'mobx-react'
import './CustomCollectionsListWidget.scss'
import Header from './Header'
import Content from './Content'
import { CustomCollectionsStore } from '../../store/CustomCollectionStore'

interface Props {
  customCollectionsStore?: CustomCollectionsStore
}

const CustomCollectionsListWidget: React.FC<Props> = ({ customCollectionsStore }) => {
  const { widgetVM: vm } = customCollectionsStore
  if (!vm) return null

  return (
    <Card id='CustomCollectionsListWidget' elevation={0}>
      <Header />
      <Content />
    </Card>
  )
}

export default inject('customCollectionsStore')(observer(CustomCollectionsListWidget))
