import React from 'react'
import clsx from 'clsx'
import { inject, observer } from 'mobx-react'
import { ButtonBase } from '@material-ui/core'
import { MySurveysWidgetVM } from '../../view-models/my-surveys-widget/MySurveysWidgetVM'
import LocalizationStore from '../../../localization/LocalizationStore'
import { LabelsStore } from '../../../labels/store/LabelsStore'

interface Props {
  vm: MySurveysWidgetVM
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
}

const WidgetTitle: React.FC<Props> = ({ vm, localizationStore, labelsStore }) => {
  const { lzStrings } = localizationStore

  const renderActive = () => {
    return (
      <ButtonBase
        onClick={() => vm.setTabIndex(0)}
        className={clsx('tab-button', {
          active: vm.tabIndex === 0,
        })}
      >
        {lzStrings.mySurveysWidget.active}
      </ButtonBase>
    )
  }

  const renderResponses = () => {
    return (
      <ButtonBase
        onClick={() => vm.setTabIndex(1)}
        className={clsx('tab-button', {
          active: vm.tabIndex === 1,
        })}
      >
        {lzStrings.mySurveysWidget.completed}
      </ButtonBase>
    )
  }

  return (
    <div className='widget-title-tabs'>
      <span>
        {`${lzStrings.mySurveysWidget.my} ${labelsStore.getLabelByLanguageAndFor('pulses')}`}
      </span>
      <div className='tab-btns'>
        {renderActive()}
        {renderResponses()}
      </div>
    </div>
  )
}

export default inject('localizationStore', 'labelsStore')(observer(WidgetTitle))
