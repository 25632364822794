import { action, observable, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { ParseService } from '../../services/ParseService'
import Parse from 'parse'
import env from '../../../env'

export default class CategoryImportForm {
  rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @observable public file: File = null
  @observable public importProcessing: Boolean = false

  public handleFileDrop(file) {
    this.file = file[0]
    return
  }

  @computed
  public get saveEnabled() {
    if (this.file) return true
    return false
  }

  @action
  public handleTemplateDownload() {
    window.location.href = `${env.var.REACT_APP_API_URL}/exportCategoriesToCsv?orgId=${this.rootStore.appStore.currentOrgId}`
  }

  @action
  public async save() {
    try {
      this.importProcessing = true

      const fileName = this.file.name
      const newFile = await new Parse.File(fileName, this.file, 'text/csv')
      newFile.setTags({ organizationId: this.rootStore.appStore.currentOrgId })
      const parseFile = await newFile.save()

      await new ParseService().processCategoriesFile(
        this.rootStore.appStore.currentOrgId,
        parseFile
      )

      this.rootStore.categoriesStore.clearForms()
      this.rootStore.categoriesStore.toggleImportDialog()
      this.importProcessing = false
    } catch (e) {
      console.error(e)
      return false
    }
  }
}
