import React from 'react'
import { inject, observer } from 'mobx-react'
import { Button, IconButton, Snackbar } from '@material-ui/core'
import { UserDashboardsStore } from '../../user-dashboards/UserDashboardsStore'
import CloseIcon from '@material-ui/icons/Close'

interface Props {
  userDashboardsStore?: UserDashboardsStore
}

const SharedWithSnackbar: React.FC<Props> = ({ userDashboardsStore }) => {
  const dashVM = userDashboardsStore.viewModels.userDashboardVM
  const vm = dashVM.sharedSnackbarVM
  if (!vm) return null

  const renderMessage = () => {
    if (vm.shared) return 'Dashboard has been shared.'
    return 'Dashboard has been unshared.'
  }

  return (
    <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={true}
        autoHideDuration={6000}
        onClose={() => dashVM.closeSharedSnackbar()}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={
          <span id='message-id'>
            {renderMessage()}
          </span>
        }
        action={[
          <IconButton
            key='close'
            aria-label='close'
            color='inherit'
            onClick={() => dashVM.closeSharedSnackbar()}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
  )
}

export default inject('userDashboardsStore')(observer(SharedWithSnackbar))
