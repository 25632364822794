import axios from 'axios'
import { RootStore } from '../../stores/RootStore'
import * as Sentry from '@sentry/browser'
import env from '../../../env'
import { ICMSTenantFindResponse } from '../interfaces/ICMSTenantFindResponse'
import { ICMSTenant } from '../interfaces/ICMSTenant'
import { CMSAuthService } from '../../cms-auth/services/CMSAuthService'

export class CMSTenantsFindService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  private getCMSApiUrl(): string {
    return env.var.REACT_APP_CMS_API_URL
  }

  public async getTenant(organizationId: string): Promise<ICMSTenantFindResponse> {
    let response = { success: false } as ICMSTenantFindResponse
    try {
      const url = this.getCMSApiUrl() + '/api/tenants' + `?filters[Tenant_Id][$eq]=${organizationId}`
      const jwt = await CMSAuthService.getJWT()
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt}`,
          Accept: '*/*',
        },
      }
      const res = await axios.get(url, config)

      if (!res.data) return
      if (!res.data.data) return
      if (res.data.data) {
        const tenant = res.data.data.length > 0 ? (res.data.data[0] as ICMSTenant) : undefined
        response = {
          success: true,
          tenant: tenant,
        }
      }
    } catch (e) {
      console.error(e)
      Sentry.captureException({
        message: 'Unable to get cms tenant',
        exception: e,
        userId: this.rootStore.appStore.currentUserId,
        orgId: this.rootStore.appStore.currentOrgId,
      })
    }
    return response
  }

}
