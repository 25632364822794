import axios from 'axios'
import * as Sentry from '@sentry/browser'
import env from '../../../env'
import { RootStore } from '../../stores/RootStore'
import { ICMSItemEditRequest } from '../interfaces/ICMSItemEditRequest'
import { ICMSItemUpload } from '../interfaces/ICMSItemUpload'
import { ICMSItemUploadRequest } from '../interfaces/ICMSItemUploadRequest'
import { CMSAuthService } from '../../cms-auth/services/CMSAuthService'
import { CMSTenantsFindService } from '../../cms-tenants/services/CMSTenantsFindService'
import { CMSItemTagsFindService } from '../../cms-item-tags/services/CMSItemTagsFindService'

export class CMSItemsUploadService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  private getCMSApiUrl(): string {
    return env.var.REACT_APP_CMS_API_URL
  }

  private getExternalVideo(extVideoUrl: string) {
    if (!extVideoUrl) return

    let uuid = ''

    let provider = ''
    let isVimeo = false
    let isYouTube = false
    const url = extVideoUrl.toLowerCase()
    if (url.includes('vimeo')) {
      let regExp =
        /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/
      if (extVideoUrl.includes('player.vimeo.com')) {
        regExp =
          /(http|https)?:\/\/(www\.)?player.vimeo.com\/(?:video\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/
      }
      const match = extVideoUrl.match(regExp)
      uuid = match && match.length ? match[4] : ''
      provider = 'vimeo'
      isVimeo = true
    } else if (url.includes('youtube') || url.includes('youtu.be')) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
      const match = extVideoUrl.match(regExp)
      uuid = match && match[2].length === 11 ? match[2] : ''
      provider = 'youtube'
      isYouTube = true
    }

    return {
      provider: provider,
      url: extVideoUrl,
      providerUid: uuid,
      isVimeo: isVimeo,
      isYouTube: isYouTube,
    } as any
  }

  private getType(mimeType: string) {
    if (!mimeType) return ''

    if (mimeType.includes('audio')) {
      return 'audio'
    } else if (mimeType.includes('image')) {
      return 'image'
    } else if (mimeType.includes('video')) {
      return 'video'
    } else {
      return 'file'
    }
  }

  public async uploadItem(req: ICMSItemUploadRequest, cbAfterUpload?: Function): Promise<boolean> {
    try {
      const jwt = await CMSAuthService.getJWT()
      if (!jwt) return false

      let response = undefined
      if (req.file) {
        const formData = new FormData()
        formData.append('files', req.file)
        response = await axios.post(`${this.getCMSApiUrl()}/api/upload`, formData, {
          headers: { Authorization: 'Bearer ' + jwt },
        })
      }

      const tenantsSvc = new CMSTenantsFindService(this.rootStore)
      const foundTenant = await tenantsSvc.getTenant(req.organizationId)
      const tagsSvc = new CMSItemTagsFindService(this.rootStore)
      const foundTags = await tagsSvc.getTagIds(req.tags)
      const extVideo = req.extVideoUrl ? this.getExternalVideo(req.extVideoUrl) : undefined
      const isPrivate = req.isPrivate ? req.isPrivate : false
      const privateTo = req.privateTo ? req.privateTo : ''

      let mimeType = 'file'
      if (response && response.data[0]) {
        mimeType = this.getType(response.data[0].mime)
      } else if (!response && extVideo) {
        mimeType = 'ext-video'
      }

      let itemPayload = {
        Title: req.title,
        Description: req.description,
        Tenants: foundTenant ? [foundTenant.tenant.id] : undefined,
        Is_Private: isPrivate,
        Private_To: privateTo,
        External_Video: extVideo,
        // Tags: foundTags ? foundTags : undefined,
        Item_Tags: foundTags ? foundTags : undefined,
        Type: mimeType === 'ext-video' ? 'video' : mimeType,
      } as ICMSItemUpload

      if (mimeType === 'image') {
        Object.assign(itemPayload, {
          File: [response.data[0].id],
        })
      } else if (mimeType === 'audio') {
        Object.assign(itemPayload, {
          File: [response.data[0].id],
        })
      } else if (mimeType === 'file') {
        Object.assign(itemPayload, {
          File: [response.data[0].id],
        })
      } else if (mimeType === 'video') {
        const data = {
          Title: req.title,
          Description: req.description,
          Unoptimized_Video: [response.data[0].id],
        }

        Object.assign(itemPayload, {
          File: response.data[0].id,
        })
      }

      await axios.post(
        `${this.getCMSApiUrl()}/api/items`,
        { data: itemPayload },
        { headers: { Authorization: 'Bearer ' + jwt } }
      )

      if (cbAfterUpload) cbAfterUpload()

      return true
    } catch (e) {
      console.error(e)
      Sentry.captureException({
        message: 'Unable to upload cms item',
        exception: e,
        userId: this.rootStore.appStore.currentUserId,
        orgId: this.rootStore.appStore.currentOrgId,
      })
    }
  }

  public async editItem(req: ICMSItemEditRequest): Promise<boolean> {
    try {
      const jwt = await CMSAuthService.getJWT()
      if (!jwt) return false

      let mimeType = 'file'
      const item = req.item
      const file = item.File
      const extVideo = item.External_Video

      if (file) {
        mimeType = this.getType(file.mime)
      } else if (!file && extVideo.url) {
        mimeType = 'ext-video'
      }

      const tenantsSvc = new CMSTenantsFindService(this.rootStore)
      const foundTenant = await tenantsSvc.getTenant(req.organizationId)
      const tagsSvc = new CMSItemTagsFindService(this.rootStore)
      const foundTags = await tagsSvc.getTagIds(req.tags)
      const isPrivate = req.isPrivate ? req.isPrivate : false
      const privateTo = req.privateTo ? req.privateTo : ''

      let itemPayload = {
        Title: item.Title,
        Description: item.Description,
        Tenants: foundTenant ? [foundTenant.tenant.id] : undefined,
        Is_Private: isPrivate,
        Private_To: privateTo,
        External_Video: extVideo,
        // Tags: foundTags ? foundTags : undefined,
        Item_Tags: foundTags ? foundTags : undefined,
        Type: mimeType === 'ext-video' ? 'video' : mimeType,
      } as ICMSItemUpload

      if (mimeType === 'image') {
        Object.assign(itemPayload, {
          File: [item.File.id],
        })
      } else if (mimeType === 'audio') {
        Object.assign(itemPayload, {
          File: [item.File.id],
        })
      } else if (mimeType === 'file') {
        Object.assign(itemPayload, {
          File: [item.File.id],
        })
      } else if (mimeType === 'video') {
        Object.assign(itemPayload, {
          File: item.File.id,
        })
      }

      await axios.put(
        `${this.getCMSApiUrl()}/api/items/${req.id}`,
        { data: itemPayload },
        { headers: { Authorization: 'Bearer ' + jwt } }
      )

      return true
    } catch (e) {
      console.error(e)
      Sentry.captureException({
        message: 'Unable to edit cms item',
        exception: e,
        userId: this.rootStore.appStore.currentUserId,
        orgId: this.rootStore.appStore.currentOrgId,
      })
    }
  }
}
