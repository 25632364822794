import React, { FC } from 'react'
import './SurveyOptions.scss'
import QuestionFlow from './QuestionFlow'
import SaveAsSurveyTemplate from './SaveAsSurveyTemplate'
import SurveyOwner from './SurveyOwner'
import AnonymizeResults from './AnonymizeResults'
import Visibility from './Visibility'
import ShowResults from './ShowResults'

interface Props {}

const GeneralTab: FC<Props> = () => {
  return (
    <>
      <QuestionFlow />
      <AnonymizeResults />
      <ShowResults />
      {/* <SaveAsSurveyTemplate /> */}
      <SurveyOwner />
      <Visibility />
    </>
  )
}

export default GeneralTab
