import { observable } from 'mobx'
import { serializable } from 'serializr'
import { ICampaignStatsDTO } from '../dto/ICampaignStatsDTO'

export class CampaignStats implements ICampaignStatsDTO {
  @serializable @observable public responseRate: number
  @serializable @observable public averagePolarityScore: number
  @serializable @observable public responses: number
  @serializable @observable public invitations: number
}
