import React from 'react'
import { inject, observer } from 'mobx-react'
import { UserTasksStore } from '../../store/UserTasksStore'
import WidgetPendingTasksTable from './WidgetPendingTasksTable'
import WidgetTimeline from './WidgetTimeline'
import { CardContent } from '@material-ui/core'

interface IWidgetContent {
  userTasksStore?: UserTasksStore
}
const WidgetContent: React.FC<IWidgetContent> = ({ userTasksStore }) => {
  const { pendingUserTasksWidgetVM: VM } = userTasksStore

  if (!VM) return null
  if (!VM.shouldRender) return null

  return (
    <CardContent className='pending-tasks-widget-card-content'>
      {VM.tabIndex === 0 ? <WidgetTimeline /> : <WidgetPendingTasksTable />}
    </CardContent>
  )
}

export default inject('userTasksStore')(observer(WidgetContent))
