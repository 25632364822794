import React from 'react'
import { observer } from 'mobx-react'
import { DatePicker } from '@material-ui/pickers'
import { OrganizationUsersListRowVM } from '../../view-models/list/OrganizationUsersListRowVM'
import './DatePickerCell.scss'

const DatePickerCell = (props) => {
  const data: OrganizationUsersListRowVM = props.data
  if (!data) return <div></div>

  return (
    <div id='DatePicker' className={data.roleStartDateChanged ? 'cell-edited' : ''}>
      <DatePicker
        value={data.roleStartDate ? data.roleStartDate : null}
        onChange={(e) => data.setRoleStartDate(String(e))}
        format='MM/dd/yyyy'
        onAbort={() => data.setRoleStartDate('')}
      />
    </div>
  )
}

export default observer(DatePickerCell)
