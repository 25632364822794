import { action, computed, observable } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { ChildCategoryRowVM } from './ChildCategoryRowVM'
import { IPulseQuestionDTO } from '../../../pulse-questions/dtos/IPulseQuestionDTO'
import { PulseQuestionType } from '../../../pulse-questions/types/PulseQuestionType'
import PulseQuestion from '../../../pulse-questions/aggregate/PulseQuestion'
import { PulseCampaignSurveyEditVM } from '../PulseCampaignSurveyEditVM'

export class PulseQuestionVM {
  private rootStore: RootStore

  constructor(
    rootStore: RootStore,
    parentVM: ChildCategoryRowVM,
    pulseQuestion: IPulseQuestionDTO,
    isSelected?: boolean,
    isBuild?: boolean
  ) {
    this.rootStore = rootStore
    this.parentVM = parentVM
    this.pulseQuestion = pulseQuestion
    this.isSelected = isSelected ? isSelected : false
    this.isBuild = isBuild ? isBuild : false
  }

  @observable public pulseQuestion: IPulseQuestionDTO = null
  @observable public parentVM: ChildCategoryRowVM = null
  @observable public isSelected: boolean = false
  @observable public isBuild: boolean = false

  @computed
  public get questionTypeTitle(): string {
    if (this.questionType === 'yesNo') return 'Yes or No'
    if (this.questionType === 'rating') return 'Rating (Scale)'
    if (this.questionType === 'emoji') return 'Emoji'
    if (this.questionType === 'text') return 'Short Answer (Text)'
    if (this.questionType === 'radio') return 'Multiple Choice (Radio)'
    if (this.questionType === 'starRating') return 'Star Rating'
    if (this.questionType === 'dropdown') return 'Dropdown'
    return this.questionType
  }

  @computed
  public get objectId(): string {
    return this.pulseQuestion.objectId
  }

  @computed
  public get required(): boolean {
    return this.pulseQuestion.required
  }

  @computed
  public get title(): string {
    return this.pulseQuestion.title
  }

  @computed
  public get questionType(): PulseQuestionType {
    return this.pulseQuestion.type
  }

  @computed
  public get previouslyUsedInSurveys(): number[] {
    let id = this.pulseQuestion.originalPulseQuestionId
    if (!id) id = this.pulseQuestion.objectId
    return this.rootStore.pulseCampaignsStore.viewModels.editVM.getPreviouslyUsedInPulses(id)
  }

  @computed
  public get currentSurvey(): PulseCampaignSurveyEditVM {
    return this.rootStore.pulseCampaignsStore.viewModels.editVM.currentSurvey
  }

  @computed
  public get isCurrentSurveySent(): boolean {
    return this.rootStore.pulseCampaignsStore.viewModels.editVM.isCurrentSurveySent
  }

  @computed
  public get currentSurveyIndex(): number {
    return this.rootStore.pulseCampaignsStore.viewModels.editVM.currentSurveyIndex
  }

  @computed
  public get currentSurveyIndexValid(): boolean {
    return this.rootStore.pulseCampaignsStore.viewModels.editVM.currentSurveyIndex !== -1
  }

  @computed
  public get originalPulseQuestionId(): string {
    return this.pulseQuestion.originalPulseQuestionId
  }

  @computed
  public get index(): number {
    return this.parentVM.index
  }

  @computed
  public get hasFollowUp(): boolean {
    return this.pulseQuestion.options.some((o) => o.hasFollowUp)
  }

  @action
  public copySelectedQuestionToCurrentSurvey() {
    this.rootStore.pulseCampaignsStore.viewModels.editVM.copyQuestionToCurrentSurvey(
      this.pulseQuestion as PulseQuestion
    )
  }

  @action
  public addSelectedQuestionToCurrentSurvey() {
    this.rootStore.pulseCampaignsStore.viewModels.editVM.addQuestionToCurrentSurvey(
      this.pulseQuestion as PulseQuestion
    )
  }

  @action
  public removeSelectedQuestionFromCurrentSurvey() {
    this.rootStore.pulseCampaignsStore.viewModels.editVM.deleteQuestionByIdFromCurrentSurvey(
      this.pulseQuestion.id
    )
  }

  @action
  public removeSelectedQuestionInstancesFromCurrentSurvey() {
    const objectId =
      this.pulseQuestion.originalPulseQuestionId === ''
        ? this.pulseQuestion.objectId
        : this.pulseQuestion.originalPulseQuestionId
    this.rootStore.pulseCampaignsStore.viewModels.editVM.deleteAllQuestionsByIdFromCurrentSurvey(
      objectId
    )
  }

  @action
  public addSelectedQuestionToCampaign() {
    this.rootStore.pulseCampaignsStore.viewModels.editVM.addQuestion(
      this.pulseQuestion as PulseQuestion
    )
  }

  @action
  public removeSelectedQuestionFromCampaign() {
    this.rootStore.pulseCampaignsStore.viewModels.editVM.deleteQuestionById(
      this.pulseQuestion.objectId
    )
  }

  @action
  public setSelected(isSelected: boolean) {
    this.isSelected = isSelected
  }

  @action
  public handleQuestionSelection(isSelected: boolean) {
    if (this.currentSurveyIndex === -1) return
    if (this.isSelected !== isSelected) this.setSelected(isSelected)

    if (this.isBuild) {
      isSelected
        ? this.addSelectedQuestionToCurrentSurvey()
        : this.removeSelectedQuestionInstancesFromCurrentSurvey()
    } else {
      isSelected ? this.addSelectedQuestionToCampaign() : this.removeSelectedQuestionFromCampaign()
    }
  }
}
