import React from 'react'
import { inject, observer } from 'mobx-react'
import { Card, CardHeader, Checkbox, Chip, Tooltip, Typography } from '@material-ui/core'
import rootStore from '../../../stores/RootStore'
import { PulseQuestionVM } from '../../view-models/tree/PulseQuestionVM'
import './PulseQuestion.scss'

interface Props {
  vm: PulseQuestionVM
}

const PulseQuestion: React.FC<Props> = ({ vm }) => {
  if (!vm) return null
  const { pulseCampaign: lz } = rootStore.localizationStore.lzStrings
  const labelsStore = rootStore.labelsStore

  const handleChecked = (event) => {
    if (vm.isBuild && vm.currentSurvey && vm.currentSurvey.isSent) return
    vm.handleQuestionSelection(event.target.checked)
  }

  const toggleChecked = () => {
    if (vm.isBuild && vm.currentSurvey && vm.currentSurvey.isSent) return
    vm.handleQuestionSelection(!vm.isSelected)
  }

  const renderFirst3PulseChipsTooltips = () => {
    if (vm.previouslyUsedInSurveys.length > 3) return
    return vm.previouslyUsedInSurveys.map((value) => (
      <Chip label={value} style={{ margin: '3px' }} size='small' key={'chp' + value}></Chip>
    ))
  }

  const render4PlusPulseChipsTooltips = () => {
    if (vm.previouslyUsedInSurveys.length <= 3) return
    return (
      <Tooltip
        id='PulseChips'
        title={vm.previouslyUsedInSurveys.map((value) => (
          <Chip label={value} style={{ margin: '3px' }} size='small' key={'chp' + value}></Chip>
        ))}
      >
        <Chip
          label={'...'}
          size='small'
          style={{ maxWidth: '200px', overflowWrap: 'break-word' }}
        ></Chip>
      </Tooltip>
    )
  }

  const renderChips = () => {
    return (
      <>
        {renderFirst3PulseChipsTooltips()}
        {render4PlusPulseChipsTooltips()}
      </>
    )
  }

  const renderCard = () => {
    return (
      <div id='PulseCampaignQuestion'>
        <Tooltip
          title={
            vm.isBuild && vm.currentSurvey && vm.currentSurvey.isSent
              ? `${labelsStore.getLabelByLanguageAndFor('pulse')} ${lz.already_sent}`
              : ''
          }
          placement='bottom'
          enterDelay={500}
        >
          <span>
            <Card
              className={`question-card ${vm.isBuild ? `border-cat-${vm.parentVM.index}` : ''}`}
              onClick={toggleChecked}
            >
              <CardHeader
                action={
                  <>
                    {renderChips()}
                    <Checkbox
                      disabled={vm.isBuild && vm.currentSurvey && vm.currentSurvey.isSent}
                      checked={vm.isSelected}
                      onChange={handleChecked}
                      inputProps={{ 'aria-label': 'pulse question checkbox' }}
                    />
                  </>
                }
                title={<Typography className='question-type'>{vm.questionTypeTitle}</Typography>}
              />
              <Typography className='question-title'>{vm.title}</Typography>
            </Card>
          </span>
        </Tooltip>
      </div>
    )
  }

  return <>{renderCard()}</>
}

export default inject()(observer(PulseQuestion))
