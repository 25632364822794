import React from 'react'
import { inject, observer } from 'mobx-react'
import { GroupsStore } from '../store/GroupsStore'
import LocalizationStore from '../../localization/LocalizationStore'
import { CircularProgress, Drawer, Tabs, Tab, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core'
import './GroupEditDrawer.scss'
import MuiDrawerToolbar from '../../shared/Mui-Drawer/MuiDrawerToolbar'
import MuiDrawerHeader from '../../shared/Mui-Drawer/MuiDrawerHeader'
import TabPanel from '../../groups-roles-widget/views/TabPanel'
import GroupsBasicInfo from './GroupsBasicInfo'
import GroupMembersList from './GroupMembersList'
import RefreshIcon from '@material-ui/icons/Refresh'
import GroupsSnackBar from './GroupsSnackBar'

interface Props {
  groupsStore?: GroupsStore
  localizationStore?: LocalizationStore
}

const GroupEditDrawer: React.FC<Props> = ({ groupsStore, localizationStore }) => {
  const { lzStrings } = localizationStore
  const { editVM: vm } = groupsStore
  const [value, setValue] = React.useState(0)
  if (!groupsStore.editVM) return

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    vm.setTabValue(newValue)
  }

  const renderDrawer = () => {
    if (vm.isParticipantsTabOpen) return null
    return (
      <div>
        <MuiDrawerToolbar
          handleCancelClick={() => {
            groupsStore.closeGroupsDrawer()
            setValue(0)
          }}
          handleSubmitClick={() => vm.save()}
          showReset={Boolean(vm.objectId)}
          onConfirmReset={() => groupsStore.setGroupToEdit(vm.objectId)}
          disabled={vm.saveDisabled}
          moreMenuProps={{
            isEditMode: vm.isEditMode,
            itemType: localizationStore.lzStrings.moreMenu.obj_type.group,
            itemName: vm.name,
            onConfirmDelete: () => vm.delete(),
          }}
          renderAdditionalMenuItems={(close) => [
            <MenuItem
              onClick={() => {
                close()
                vm.refreshEvents()
              }}
            >
              <ListItemIcon className='green'><RefreshIcon /></ListItemIcon>
              <ListItemText primary={'Refresh Events'} />
            </MenuItem>,
          ]}
        />
        <Tabs value={vm.tabValue} onChange={handleChange} variant='fullWidth'>
          <Tab label='Basic Info' className={!vm.isValid ? 'tab-error' : ''} />
          <Tab label='Members' disabled={vm.isNew} />
        </Tabs>
        <TabPanel value={vm.tabValue} index={0}>
          <GroupsBasicInfo />
        </TabPanel>
        <TabPanel value={vm.tabValue} index={1}>
          <GroupMembersList />
        </TabPanel>
      </div>
    )
  }

  const renderSpinner = () => {
    if (vm.isLoading)
      return (
        <div className={'spinner-container'}>
          <CircularProgress className='spinner' />
        </div>
      )
  }

  return (
    <Drawer
      variant='temporary'
      anchor='right'
      open={groupsStore.showDrawer}
      onClose={() => (groupsStore.showDrawer = false)}
      id='GroupEditDrawer'
    >
      <MuiDrawerHeader
        title={vm.objectId ? lzStrings.groupEdit.manage_group : lzStrings.groupEdit.add_group}
        toggleDrawer={() => {
          groupsStore.toggleGroupsDrawer()
        }}
      />
      {renderSpinner()}
      {renderDrawer()}
      <GroupsSnackBar vm={vm} />
    </Drawer>
  )
}

export default inject('groupsStore', 'localizationStore')(observer(GroupEditDrawer))
