import { observable, action } from 'mobx'
import moment from 'moment'

export default class CalendarSetting {
  static create(fromObject: any) {
    const calendarSetting = new CalendarSetting()
    calendarSetting.objectId = fromObject.objectId
    calendarSetting.eventStart = moment(fromObject.eventStart, 'hh:mma').toDate()
    calendarSetting.eventEnd = moment(fromObject.eventEnd, 'hh:mma').toDate()
    return calendarSetting
  }

  @observable objectId: string = ''
  @observable eventStart: Date = null
  @observable eventEnd: Date = null
}
