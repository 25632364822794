import React from 'react'
import { inject, observer } from 'mobx-react'
import { MediaItemsStore } from '../../store/MediaItemsStore'
import { MediaItemRowVM } from '../../view-models/list-widget/MediaItemRowVM'
import './Content.scss'
import { AgGridReact } from 'ag-grid-react/lib/agGridReact'
import AGGridWidgetPagingRow from '../../../shared/ag-grid/AGGridPagingRow'

interface Props {
  mediaItemsStore?: MediaItemsStore
}

const Content: React.FC<Props> = ({ mediaItemsStore }) => {
  const { listWidgetVM: vm } = mediaItemsStore
  if (!vm) return null

  const renderGrid = () => {
    const PreviewCell = observer((props) => {
      const row: MediaItemRowVM = props.data
      return (
        <div>
          {row.type === 'image' ? (
            <img className='preview-img' src={row.preview} alt='preview' title='preview' />
          ) : (
            <video className='preview-img' src={row.preview} title='preview' />
          )}
        </div>
      )
    })
    return (
      <div className='ag-theme-alpine' style={{ height: '100%', width: '100%' }}>
        <AgGridReact
          gridOptions={{
            ...vm.getGridOptions(),
            frameworkComponents: {
              previewCell: PreviewCell,
            },
          }}
        />
      </div>
    )
  }

  return (
    <div id='Content'>
      {renderGrid()}
      <AGGridWidgetPagingRow vm={vm} />
    </div>
  )
}

export default inject('mediaItemsStore')(observer(Content))
