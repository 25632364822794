import { ColDef, ColumnApi, GridApi, GridReadyEvent } from 'ag-grid-community'
import { action, computed } from 'mobx'
import { NLPResponsesService } from '../../nlpResponses/services/NLPResponsesService'
import { SentimentAnalysisRowVM } from './SentimentAnalysisRowVM'

export class SentimentAnalysisVM {
  private questionId: string
  private organizationId: string
  private surveyResponseId: string
  public gridApi: GridApi
  private columnApi: ColumnApi

  constructor(surveyResponseId: string, questionId: string, organizationId: string) {
    this.surveyResponseId = surveyResponseId
    this.questionId = questionId
    this.organizationId = organizationId
  }

  @computed
  public get fields() {
    return [
      { name: 'Date', field: 'date', tooltip: 'Date the text was processed' },
      { name: 'Type', field: 'type' },
      { name: 'Sentiment', field: 'sentiment' },
      { name: 'Classifications', field: 'classifications' },
    ]
  }

  @action
  public onGridReady(e: GridReadyEvent) {
    this.gridApi = e.api
    this.columnApi = e.columnApi

    this.gridApi.setDatasource({
      rowCount: null,
      getRows: (params) => {
        const svc = new NLPResponsesService()
        svc
          .getNLPResponses({
            questionId: this.questionId,
            surveyResponseId: this.surveyResponseId,
            organizationId: this.organizationId,
            skip: params.startRow,
            limit: params.endRow - params.startRow,
          })
          .then((result) => {
            const records = result.map((e) => new SentimentAnalysisRowVM(e))
            const lastRow =
              records.length < params.endRow - params.startRow
                ? params.startRow + records.length
                : undefined
            params.successCallback(records, lastRow)

            this.updateGridData()
          })
      },
    })
    this.updateGridData()
  }

  private updateGridData() {
    if (!this.gridApi) return
    this.gridApi.sizeColumnsToFit()
    //this.resizeColumns()
  }

  @computed
  public get columnDefs(): ColDef[] {
    return this.fields.map((e) => {
      let def: ColDef = {
        headerName: e.name,
        sortable: true,
        field: e.field,
        editable: false,
        resizable: true,
        headerTooltip: e.tooltip || e.name,
      }
      if (e.field === 'type') {
        def.width = 80
      } else if (e.field === 'date') {
        def.width = 180
      } else if (e.field === 'sentiment') {
        def.cellRenderer = 'sentimentCell'
        def.width = 180
      } else if (e.field === 'classifications') {
        def.width = 400
      }
      return def
    })
  }

  @action
  public getRowNodeId(row: SentimentAnalysisRowVM) {
    return row.objectId
  }
}
