import { observable, action } from 'mobx'
import { serializable, serialize, deserialize, list, primitive, date, object } from 'serializr'
import moment, { Moment } from 'moment'
import { IGoalDTO } from '../dto/IGoalDTO'
import { AggregationMethodType } from '../types/AggregationMethodType'
import { PeriodType } from '../types/PeriodType'
import { GoalDirectionType } from '../types/GoalDirectionType'
import { TargetModeType } from '../types/TargetModeType'
import { Participant } from './Participant'
import { IntervalType } from '../types/IntervalType'
import { ICloudinaryResult } from '../../media-items/dtos/ICloudinaryResult'
import { ActiveCatalogPlan } from './ActiveCatalogPlan'
import { IAggregate } from 'src/app/shared/data/IAggregate'

export class Goal implements IGoalDTO, IAggregate {
  public static create(userId: string, orgId: string): Goal {
    const goal = new Goal()
    goal.createdByUserId = userId
    goal.organizationId = orgId
    return goal
  }

  public static createCatalogGoal(userId: string, orgId: string): Goal {
    const goal = new Goal()
    goal.createdByUserId = userId
    goal.organizationId = orgId
    goal.isCatalogGoal = true
    return goal
  }

  @serializable @observable public objectId: string = ''
  @serializable @observable public name: string = ''
  @serializable @observable public categoryId: string = ''
  @serializable @observable public organizationId: string = ''
  @serializable(date()) @observable public startDate: Date = new Date()
  @serializable(date()) @observable public endDate: Date = new Date()
  @serializable @observable public description: string = ''
  @serializable @observable public potentialObstacles: string = ''
  @serializable @observable public dataSourceId: number = 0
  @serializable @observable public facetId: number = 0
  @serializable @observable public targetInputValue: number = 0
  @serializable @observable public goalDirection: GoalDirectionType = 'increase'
  @serializable @observable public aggregationMethod: AggregationMethodType = 'average'
  @serializable @observable public consistencyThreshold: number = 0
  @serializable @observable public aggregationPeriod: PeriodType = 'days'
  @serializable @observable public createdByUserId: string = ''
  @serializable @observable public worksheetId: string = ''
  @serializable @observable public targetMode: TargetModeType = 'percentage'
  @serializable @observable public target: number = 0
  @serializable @observable public current: number = 100
  @serializable @observable public measureGoal: boolean = false
  @serializable(list(primitive())) @observable public participatingUserIds: string[] = []
  @serializable(list(object(Participant))) @observable public participants: Participant[] = []
  @serializable @observable public isDeleted: boolean = false
  @serializable @observable public targetPercentage: number = 0
  @serializable @observable public askAboutProgress: boolean = false
  @serializable @observable public sendReminders: boolean = false
  @serializable @observable public progressInterval: IntervalType = 'daily'
  @serializable @observable public reminderInterval: IntervalType = 'daily'
  @serializable @observable public thumbnail: string = ''
  @serializable @observable public thumbnailFileName: string = ''
  @serializable @observable public associateToTrainingPlans: boolean = false
  @serializable(list(primitive())) @observable public associatedTrainingPlanIds: string[] = []
  @serializable @observable public isCatalogGoal: boolean = false
  @serializable(list(object(ActiveCatalogPlan)))
  @observable
  public activeCatalogPlans: ActiveCatalogPlan[] = []
  isOnServer: boolean = false

  @action
  public setThumbnail(result: ICloudinaryResult) {
    this.thumbnail = result.secure_url
    this.thumbnailFileName = result.original_filename
  }

  @action
  public addTrainingPlanId(id: string) {
    this.activeCatalogPlans.push({ catalogPlanId: id, trainingPlanId: '' } as ActiveCatalogPlan)
    this.associatedTrainingPlanIds.push(id)
  }

  @action
  public removeTrainingPlanId(id: string) {
    this.associatedTrainingPlanIds = this.associatedTrainingPlanIds.filter((e) => e !== id)
    this.activeCatalogPlans = this.activeCatalogPlans.filter((e) => e.catalogPlanId !== id)
  }

  @action
  public setName(val: string) {
    this.name = val
  }

  @action
  public setAssociateToTrainingPlans(val: boolean) {
    this.associateToTrainingPlans = val
  }

  @action
  public setCategory(id: string) {
    this.categoryId = id
  }

  @action
  public setStartDate(date: Date) {
    this.startDate = date
  }

  @action
  public setEndDate(date: Date) {
    this.endDate = date
  }

  @action
  public setDescription(val: string) {
    this.description = val
  }

  @action
  public setMeasureGoal(val: boolean) {
    this.measureGoal = val
  }

  @action
  public setObstacles(val: string) {
    this.potentialObstacles = val
  }

  @action
  public toggleAskAboutProgress() {
    this.askAboutProgress = !this.askAboutProgress
  }

  @action
  public toggleSendReminders() {
    this.sendReminders = !this.sendReminders
  }

  @action
  public setProgressInterval(val: IntervalType) {
    this.progressInterval = val
  }

  @action
  public setReminderInterval(val: IntervalType) {
    this.reminderInterval = val
  }

  @action
  public setDataSource(val: string) {
    this.dataSourceId = Number(val)
  }

  @action
  public setFacetId(val: string) {
    this.facetId = Number(val)
  }

  @action
  public setGoalDirection(val: GoalDirectionType) {
    this.goalDirection = val
    this.setTarget()
  }

  @action
  public setTargetPercentage(val: number) {
    this.targetPercentage = Number(val)
    this.setTarget()
  }

  @action
  public setWorkSheetId(id: string) {
    this.worksheetId = id
  }

  @action
  public setTargetInputValue(val: number) {
    this.targetInputValue = Number(val)
    this.setTarget()
  }

  @action
  public setTarget() {
    let target = this.target
    if (this.targetMode === 'percentage') {
      if (this.goalDirection === 'increase') {
        target = this.current * (this.targetPercentage / 100) + this.current
        console.log(target)
      } else {
        target = this.current - this.current * (this.targetPercentage / 100)
      }
    } else {
      target = this.targetInputValue
    }

    this.target = target
  }

  @action
  public setTargetMode(val: TargetModeType) {
    this.targetMode = val
    if (val === 'percentage') {
      this.target = this.current / this.target + this.current
    } else {
      this.target = this.target
    }
  }

  @action
  public setAggregationMethod(val: AggregationMethodType) {
    this.aggregationMethod = val
  }

  @action
  public setConsistencyThreshold(val: number) {
    this.consistencyThreshold = Number(val)
  }

  @action
  public setAggregationPeriod(val: PeriodType) {
    this.aggregationPeriod = val
  }

  @action
  public markIsOnServer() {
    this.isOnServer = true
  }

  @action
  public markIsNotOnServer() {
    this.isOnServer = true
  }

  public serialize(): IGoalDTO {
    const obj = serialize(this)
    obj.startDate = moment(this.startDate).toISOString()
    obj.endDate = moment(this.endDate).toISOString()
    return obj
  }

  public clone(): Goal {
    return deserialize(Goal, this.serialize())
  }
}
