import React from 'react'
import { InputBase, AppBar, Toolbar, TextField, Grid, Snackbar, IconButton } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import Button from '@material-ui/core/Button'
import { WeightProfilesStore } from '../../store/WeightProfilesStore'
import CloseIcon from '@material-ui/icons/Close'

interface Props {
  weightProfilesStore?: WeightProfilesStore
}

const SaveSnackbar: React.FC<Props> = ({ weightProfilesStore }) => {
  const { editVM } = weightProfilesStore
  if (!editVM) return null
  if (!editVM.selectedRole) return null
  const weightProfileVM = editVM.selectedRole.weightProfileVM
  if (!weightProfileVM) return null

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={editVM.selectedRole.weightProfileVM.showSaveSnackbar}
      autoHideDuration={6000}
      onClose={() => weightProfileVM.toggleSaveSnackbar()}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={<span id='message-id'>{weightProfileVM.isNew ? 'Weight Profile has been saved' : 'Weight Profile has been updated'}.</span>}
      action={[
        <IconButton
          key='close'
          aria-label='close'
          color='inherit'
          onClick={() => weightProfileVM.toggleSaveSnackbar()}
        >
          <CloseIcon />
        </IconButton>,
      ]}
    />
  )
}

export default inject('weightProfilesStore')(observer(SaveSnackbar))
