import { computed, observable } from 'mobx'
import { Question } from './Question'
import { IAnonymousParticipantDTO } from '../../participants-select/interfaces/IAnonymousParticipantDTO'
import { ISurveyResponseDTO } from '../dtos/ISurveyResponseDTO'
import { IAggregate } from '../../shared/data/IAggregate'
import { UserSurvey } from '../../user-surveys/aggregate/UserSurvey'
import { serializable, serialize, deserialize, list, object } from 'serializr'
import { IDTO } from '../../shared/data/IDTO'
import { ParseDate } from '../../shared/ParseDate'
import { FKUser } from '../../organization-users/aggregate/FKUser'

export class SurveyResponse extends UserSurvey implements ISurveyResponseDTO, IAggregate {
  @serializable @observable public userSurveyId: string = undefined
  @serializable(object(FKUser)) @observable public fk_User: FKUser
  @serializable(object(ParseDate)) @observable public respondedAt: ParseDate = undefined
  @serializable @observable public forUserId: string = undefined
  @serializable @observable public forPrimaryRoleId: string = undefined
  @serializable @observable public forPrimaryGroupId: string = undefined
  @serializable @observable public inputByUserId: string = undefined
  @serializable @observable public userSurveyViewedDateTime: Date = undefined
  @serializable @observable public lastImportedToDW: Date = undefined
  @serializable(object(ParseDate)) @observable public paperSurveyResponseDate: ParseDate = undefined
  @serializable(object(ParseDate)) @observable public paperSurveyInputAt: ParseDate = undefined
  @serializable(list(object(Question))) @observable public questions: Question[] = []

  @computed
  public get paperSurveyResponseDateAsDate(): Date {
    if (!this.paperSurveyResponseDate) return null
    return this.paperSurveyResponseDate.date
  }

  @computed
  public get paperSurveyInputAtAsDate(): Date {
    if (!this.paperSurveyInputAt) return null
    return this.paperSurveyInputAt.date
  }

  @computed
  public get respondedAtAsDate(): Date {
    if (!this.respondedAt) return null
    return this.respondedAt.date
  }

  public clone(): SurveyResponse {
    return deserialize(SurveyResponse, this.serialize())
  }

  public serialize(): ISurveyResponseDTO {
    return serialize(this)
  }
}
