import React from 'react'
import { inject, observer } from 'mobx-react'
import { Switch, TextField, Typography } from '@material-ui/core'
import LocalizationStore from '../../localization/LocalizationStore'
import { QuestionsStore } from '../store/QuestionsStore'
import './DropdownInfo.scss'

interface Props {
  localizationStore?: LocalizationStore
  questionsStore?: QuestionsStore
}

const DropdownInfo: React.FC<Props> = ({ localizationStore, questionsStore }) => {
  const { questionsCatalogWidgetDrawer: lz } = localizationStore.lzStrings
  const { editVM: vm } = questionsStore
  if (!vm) return null

  const renderDropdownPlaceholder = () => {
    if (vm.questionType !== 'dropdown') return null
      return (
        <div className='dropdown-label'>
          <Typography className='dropdown-label-header'>{lz.dropdown_placeholder}</Typography>
          <TextField
            error={!vm.placeholderValid}
            id='outlined-placeholder'
            value={vm.placeholder}
            onChange={(e) => {
              vm.setPlaceholder(e.target.value)
            }}
            fullWidth
            margin='none'
            variant='outlined'
          />
        </div>
      )
    }

  const renderDropdownMultiSelectToggle = () => {
    if (vm.questionType !== 'dropdown') return null
    return (
      <div className={'dropdown-toggle'}>
        <Typography className={'dropdown-label-header'}>{lz.dropdown_multiselect}</Typography>
        <Switch checked={vm.isDropdownMultiSelect} onChange={() => vm.toggleDropdownMultiSelect()} />
      </div>
    )
  }

  const renderDropdownInfo = () => {
    if (vm.questionType !== 'dropdown') return null
    return (
      <div className={'dropdown-info'}>
        {renderDropdownPlaceholder()}
        {renderDropdownMultiSelectToggle()}
      </div>
    )
  }

  return (
    <div id='DropdownInfo'>
      {renderDropdownInfo()}
    </div>
  )
}

export default inject('localizationStore', 'questionsStore')(observer(DropdownInfo))
