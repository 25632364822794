import { observable, computed, action } from 'mobx'
import { MediaItemType } from '../../../upload/types/MediaItemType';
import { MediaItem } from '../../aggregate/MediaItem';

export class MediaLibraryItemVM {
    @observable private mediaItem: MediaItem
    @observable public showEditIcon: boolean

    constructor(mediaItem: MediaItem) {
        this.mediaItem = mediaItem
    }

    @computed
    public get objectId(): string {
        return this.mediaItem.objectId
    }

    @computed
    public get name(): string {
        return this.mediaItem.name
    }

    @computed
    public get type(): MediaItemType {
        return this.mediaItem.type
    }

    @computed
    public get fileUrl(): string {
        return this.mediaItem.fileUrl
    }

    @computed
    public get videoThumbnail(): string {
        return this.mediaItem.videoThumbnail
    }

    @action
    public hideEdit(): void {
      this.showEditIcon = false
    }
  
    @action
    public showEdit(): void {
      this.showEditIcon = true
    }
}