import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import LogoutButton from './LogoutButton'
import SelectOrgButton from './SelectOrgButton'
import Popper from '@material-ui/core/Popper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Paper from '@material-ui/core/Paper'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import Collapse from '@material-ui/core/Collapse'
import Typography from '@material-ui/core/Typography'
import ExpandIcon from '@material-ui/icons/ExpandMoreRounded'
import CollapseIcon from '@material-ui/icons/ExpandLessRounded'
import { UserStore } from '../../store/UserStore'
import LocalizationStore from '../../../localization/LocalizationStore'

interface Props {
  localizationStore?: LocalizationStore
  userStore?: UserStore
}

const AvatarMenu: React.FC<Props> = ({ userStore, localizationStore }) => {
  const [expand, setExpand] = useState(false)
  const { avatarMenuVM: vm } = userStore
  if (!vm) return null
  if (!vm.loaded) return
  const lz = localizationStore.lzStrings.profile

  const renderDropdownIcon = () => {
    if (!vm.dropDownIconShown) return null
    if (expand) {
      return <CollapseIcon />
    } else {
      return <ExpandIcon />
    }
  }

  const renderOrgsMenu = () => {
    return (
      <Collapse in={expand}>
        <div className='avatar-menu-org-list'>
          {vm.rows.map((e, index) => {
            return (
              <span key={index}>
                <SelectOrgButton vm={e} />
              </span>
            )
          })}
        </div>
      </Collapse>
    )
  }

  const renderCurrentOrg = () => {
    return (
      <MenuItem onClick={() => setExpand(!expand)} className='avatar-menu-item'>
        <Typography variant='inherit' noWrap>
          {vm.currentOrgName}
        </Typography>
        {renderDropdownIcon()}
      </MenuItem>
    )
  }

  return (
    <Popper
      open={vm.isOpen}
      anchorEl={vm.avatarMenuEl}
      placement='bottom-end'
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Collapse {...TransitionProps}>
          <Paper id='avatar-menu' elevation={1}>
            <ClickAwayListener mouseEvent='onMouseDown' onClickAway={() => vm.open(null)}>
              <MenuList autoFocusItem={vm.isOpen} className='menu-list'>
                {renderCurrentOrg()}
                {renderOrgsMenu()}
                <MenuItem onClick={() => vm.editUserSettings()} className='avatar-menu-item'>
                  {lz.profile_settings}
                </MenuItem>
                <MenuItem onClick={() => vm.editPassword()} className='avatar-menu-item'>
                  {lz.change_password}
                </MenuItem>
                <MenuItem onClick={() => vm.toggleHelpDialog()} className='avatar-menu-item'>
                  {lz.help}
                </MenuItem>
                <LogoutButton />
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Collapse>
      )}
    </Popper>
  )
}

export default inject('userStore', 'localizationStore')(observer(AvatarMenu))
