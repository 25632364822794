import React from 'react'
import { observer, inject } from 'mobx-react'
import { CardHeader, Tooltip, IconButton } from '@material-ui/core'
import Searchbar from '../../../shared/Searchbar'
import AddIcon from '@material-ui/icons/Add'
import LocalizationStore from '../../../localization/LocalizationStore'
import { GoalsCatalogStore } from '../../store/GoalsCatalogStore'

interface Props {
  goalsCatalogStore?: GoalsCatalogStore
  localizationStore?: LocalizationStore
}

const Header: React.FC<Props> = ({ goalsCatalogStore, localizationStore }) => {
  const { goalsCatalogWidgetVM: vm } = goalsCatalogStore
  if (!vm) return null
  const { goals_catalog: strings } = localizationStore.lzStrings

  return (
    <CardHeader
      id='Header'
      title={strings.goals_catalog}
      action={
        <>
          <div className='search'>
            <Searchbar
              focused={vm.keywordFilter.length > 0}
              searchValue={vm.keywordFilter}
              onSearchChange={(e) => vm.setKeywordFilter(e.target.value)}
            />
          </div>
          <React.Fragment>
            <Tooltip title={strings.add_new_tooltip} placement='bottom'>
              <IconButton
                className='widget-cta'
                size='small'
                onClick={() => vm.createNewCatalogGoal()}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </React.Fragment>
        </>
      }
    ></CardHeader>
  )
}

export default inject('goalsCatalogStore', 'localizationStore')(observer(Header))
