import { action, computed, observable, when } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import SurveyTemplateFolder from '../aggregate/SurveyTemplateFolder'
import { SurveyTemplateFoldersFindDataStore } from '../stores/SurveyTemplateFoldersFindDataStore'
import { ISurveyTemplateFoldersFindRequest } from '../interfaces/ISurveyTemplateFoldersFindRequest'
import { SurveyTemplateFolderEditVM } from './SurveyTemplateFolderEditVM'
import { SurveyTemplateFoldersService } from '../services/SurveyTemplateFoldersService'
import { SurveyTemplatesManageVM } from '../../survey-templates/view-models/SurveyTemplatesManageVM'

interface SurveyTemplateFolderForPicker {
  id: string
  name: string
  parentId: string
  children: SurveyTemplateFolderForPicker[]
}

const noFolder = {
  id: 'no-folder',
  name: `[ No Folder ]`,
  parentId: null,
  children: [],
}

export class SurveyTemplateFoldersPickerVM {
  private rootStore: RootStore
  @observable public dataStore: SurveyTemplateFoldersFindDataStore

  constructor(
    rootStore: RootStore,
    manageVM: SurveyTemplatesManageVM,
    req: ISurveyTemplateFoldersFindRequest
  ) {
    this.rootStore = rootStore
    this.manageVM = manageVM
    this.dataStore = new SurveyTemplateFoldersFindDataStore(this.rootStore, req)
    this.dataStore.loadListRecords()
    if(this.manageVM.screenType === 'permissions') {
      this.setShowActions(false)
    }
  }

  @observable public manageVM: SurveyTemplatesManageVM = null
  @observable public folderEditVM: SurveyTemplateFolderEditVM = null
  @observable public selectedFolder: SurveyTemplateFolderForPicker = noFolder
  @observable public showDeleteDialog: boolean = false
  @observable public showFolderActions: boolean = true
  @observable public isSystemToggleMode: boolean = true
  @observable public isSelectedFolderChecked: boolean = false
  @observable public expanded: string[] = []

  @computed
  public get isLoaded(): boolean {
    return this.dataStore.isLoaded
  }

  @computed
  public get isMovingTemplatesToFolder(): boolean {
    return this.manageVM.isMovingTemplatesToFolder
  }

  @action
  public getFoldersForMode() {
    let orgId = 'DEFAULT'
    const request = this.dataStore.getRequest()
    if (!this.isSystemToggleMode) orgId = this.rootStore.appStore.currentOrgId  
    request.organizationId = orgId
    this.dataStore.setRequest(request)
    this.dataStore.loadListRecords()
  }

  @action
  public setShowActions(show: boolean) {
    this.showFolderActions = show
  }

  @computed
  public get validSurveyTemplateFolders(): SurveyTemplateFolder[] {
    return this.dataStore.surveyTemplates.slice().filter((folder) => !folder.isDeleted)
  }

  @computed
  public get formattedFolders(): any {
    let folders: Array<SurveyTemplateFolderForPicker> = this.validSurveyTemplateFolders
      .sort((a: SurveyTemplateFolder, b: SurveyTemplateFolder) => {
        const nameA = a.name.toLowerCase()
        const nameB = b.name.toLowerCase()
        if (nameA < nameB) return -1
        if (nameB < nameA) return 1
        return 0
      })
      .map((e: SurveyTemplateFolder) => ({
        id: e.objectId,
        name: e.name,
        parentId: e.parentFolderId,
        children: [],
      }))

    folders.unshift(noFolder)

    return folders
  }

  @computed
  public get defaultExpanded(): string[] {
    return this.formattedFolders.map((e: SurveyTemplateFolderForPicker) => e.id)
  }

  private listToTree(list) {
    const map = {}
    const roots = []
    for (let i = 0; i < list.length; i++) {
      map[list[i].id] = i
      list[i].children = []
    }

    for (let i = 0; i < list.length; i++) {
      const node = list[i]
      if (node.parentId) {
        list[map[node.parentId]].children.push(node)
      } else {
        roots.push(node)
      }
    }
    return roots
  }

  @computed
  public get data(): any {
    const tree = this.listToTree(this.formattedFolders)
    return tree
  }

  @action
  public setSelectedFolder(node) {
    if (this.isMovingTemplatesToFolder) {
      this.moveTemplatesToFolder(node)
      return
    }
    this.selectedFolder = node
    this.folderEditVM = null
    this.manageVM.getTemplatesByFolder(node.id)
  }

  @action
  public moveTemplatesToFolder(node) {
    this.manageVM.moveTemplatesToFolder(node)
  }

  @action
  public toggleDeleteDialog() {
    this.showDeleteDialog = !this.showDeleteDialog
  }

  @action
  public async deleteFolder() {
    this.toggleDeleteDialog()
    let orgId = this.rootStore.appStore.currentOrgId
    if (this.manageVM.screenType  === 'systemManage') orgId = 'DEFAULT'
    const svc = new SurveyTemplateFoldersService(this.rootStore)
    const result = await svc.deleteSurveyTemplateFolder(this.selectedFolder.id, orgId)
    if (result.success) {
      this.manageVM.tableVM.openSnackbar('Success: Folder deleted. Re-building folder tree.')
      this.goToNoFolderAfterDelete()
      this.dataStore.loadListRecords()
    } else {
      console.log(result.errorMessage)
      this.manageVM.tableVM.openSnackbar(`Error: Something went wrong.`)
    }
  }

  @action
  public goToNoFolderAfterDelete() {
    this.manageVM.tableVM.goToFolderAfterMove(noFolder)
  }

  @action
  public editFolder(id: string, parentFolderId?: string) {
    if (id === 'new') {
      let orgId = 'DEFAULT'
      if (!this.isSystemToggleMode) orgId = this.rootStore.appStore.currentOrgId
      const surveyTemplateFolder = SurveyTemplateFolder.create(orgId)
      if (parentFolderId) surveyTemplateFolder.parentFolderId = parentFolderId
      return (this.folderEditVM = new SurveyTemplateFolderEditVM(
        this.rootStore,
        this,
        surveyTemplateFolder
      ))
    }
    const folder = this.dataStore.getRecord(id)
    return (this.folderEditVM = new SurveyTemplateFolderEditVM(
      this.rootStore,
      this,
      folder
    ))
  }

  @action
  public async addNewChildFolder(parentFolderId: string) {
    let orgId = 'DEFAULT'
    if (!this.isSystemToggleMode) orgId = this.rootStore.appStore.currentOrgId
    const surveyTemplateFolder = SurveyTemplateFolder.create(orgId)
    surveyTemplateFolder.parentFolderId = parentFolderId
    surveyTemplateFolder.name = '[ new folder ]'
    const svc = new SurveyTemplateFoldersService(this.rootStore)
    const result = await svc.saveSurveyTemplateFolder(surveyTemplateFolder.serialize(), orgId)
    if (result.success) {
      this.manageVM.tableVM.openSnackbar('Success: New Folder created. Re-building folder tree.')
      this.dataStore.loadListRecords()
    } else {
      console.log(result.errorMessage)
      this.manageVM.tableVM.openSnackbar(`Error: Something went wrong.`)
    }
  }

  // @computed
  // public get folderChecked(): boolean {
  //   const folder = this.validSurveyTemplateFolders.find((e) => e.objectId === this.selectedFolder.id)
  //   if (!folder) return null
  //   return folder.subscribedOrganizationIds.includes(this.manageVM.selectedOrg.objectId)
  // }

  // @action
  // public async toggleSelectedFolderChecked() {
  //   const folder = this.validSurveyTemplateFolders.find((e) => e.objectId === this.selectedFolder.id)
  //   if (!folder) return null
  //   if (folder.subscribedOrganizationIds.includes(this.manageVM.selectedOrg.objectId)) {
  //     folder.removeOrg(this.manageVM.selectedOrg.objectId)
  //     this.manageVM.tableVM.setSelectAll(false)
  //   }
  //   else {
  //     folder.addOrg(this.manageVM.selectedOrg.objectId)
  //     this.manageVM.tableVM.setSelectAll(true)
  //   }
  // }

  @action
  public closeEditFolder() {
    this.folderEditVM = null
  }
}
