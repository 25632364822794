import React from 'react'
import { inject, observer, useLocalStore } from 'mobx-react'
import { Badge, Button, Grid, Typography } from '@material-ui/core'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import AddIcon from '@material-ui/icons/Add'
import BackIcon from '@material-ui/icons/KeyboardBackspace'
import rootStore from '../../../stores/RootStore'
import LocalizationStore from '../../../localization/LocalizationStore'
import { PulseCampaignsStore } from '../../store/PulseCampaignsStore'
import { PulseCategoriesManageVM } from '../../view-models/PulseCategoriesManageVM'
import './BuildStep.scss'
import MixedAndTemplatePanel from '../mode-builder/MixedAndTemplatePanel'
import { PulseCampaignSurveyStatusType } from '../../type/PulseCampaignSurveyStatusType'

interface Props {
  pulseCampaignsStore?: PulseCampaignsStore
  buildVM: PulseCategoriesManageVM
  localizationStore?: LocalizationStore
}

const BuildStep: React.FC<Props> = ({ pulseCampaignsStore, buildVM, localizationStore }) => {
  // const lz = localizationStore.lzStrings.pulseCampaign
  const { editVM } = pulseCampaignsStore.viewModels
  if (!editVM) return null

  const localStore = useLocalStore(() => ({
    categoryVM: buildVM ? buildVM : new PulseCategoriesManageVM(rootStore),
  }))
  const categoryVM = localStore.categoryVM

  const renderBackButton = () => {
    if (rootStore.pulseCampaignsStore.viewModels.editVM.isTemplate) return
    return (
      <Button
        className='back-to-questions-btn'
        variant='text'
        aria-label='back'
        onClick={() => {
          rootStore.pulseCampaignsStore.viewModels.editVM.goBackToPreviousTab()
        }}
      >
        <BackIcon />
        BACK
      </Button>
    )
  }

  const handleAddSurvey = (event: React.MouseEvent<HTMLElement>, val: number | null) => {
    if (val) {
      editVM.createNewSurveyForCurrentCampaign()
      editVM.setCurrentSurveyIndex(editVM.numberOfSurveys - 1)
    }
  }

  const handleCurrentSurvey = (event: React.MouseEvent<HTMLElement>, val: number | null) => {
    if (val === -1) return handleAddSurvey(event, val)
    if (val !== null) {
      editVM.setCurrentSurveyIndex(val)
      editVM.isTemplateBuildMode = true
    }
  }

  const getBadgetColor = (status: PulseCampaignSurveyStatusType) => {
    if (status === 'sent') {
      return 'primary'
    } else if (status === 'scheduled') {
      return 'secondary'
    } else if (status === 'closed') {
      return 'error'
    } else {
      return
    }
  }

  const renderSurveyButtons = () => {
    if (editVM.surveys.length === 0) return
    return editVM.surveys.map((survey, index) => {
      return (
        <ToggleButton
          value={index}
          key={`survey-${survey.id}-${index}`}
          aria-label={`survey-${survey.id}-btn-${index}`}
          className={
            survey.allValid
              ? 'survey-selector-root-grid-btn'
              : 'survey-selector-root-grid-btn selector-error'
          }
        >
          <Grid item xs className='survey-selector-root-grid-item'>
            <Badge
              key={`survey-${survey.id}-${index}-badge`}
              overlap='circular'
              badgeContent={''}
              color={getBadgetColor(survey.status)}
              className={`survey-selector-survey-badge ${
                editVM.campaignStatus === 'draft' ? 'off' : ''
              }`}
            >
              <span key={`survey-${survey.id}`} className='survey-selector-survey-btn'>
                {index + 1}
              </span>
            </Badge>
          </Grid>
        </ToggleButton>
      )
    })
  }

  const renderAddSurveyButton = () => (
    <ToggleButton
      disabled={editVM.surveys.length >= editVM.maxPulseSurveys}
      value={-1}
      aria-label='add-survey-btn'
      className='survey-selector-root-grid-btn'
    >
      <Grid item xs className='survey-selector-root-grid-item'>
        <AddIcon />
      </Grid>
    </ToggleButton>
  )

  const renderSurveyButtonGroup = () => {
    return (
      <div className='survey-selector-root'>
        <Grid container spacing={1} className='survey-selector-root-grid'>
          <ToggleButtonGroup
            size='small'
            value={editVM.currentSurveyIndex}
            exclusive
            onChange={handleCurrentSurvey}
            aria-label='survey-selector'
            className='survey-selector-root-grid-btn-grp'
          >
            {renderSurveyButtons()}
            {renderAddSurveyButton()}
          </ToggleButtonGroup>
        </Grid>
      </div>
    )
  }

  const renderCurrentSurveyStatusKey = () => {
    if (!editVM.currentSurvey) return
    if (editVM.campaignStatus === 'draft') return

    return (
      <div className='survey-selector-status-key'>
        <div className={`survey-selector-status-key-item`}>
          <Badge
            overlap='circular'
            badgeContent={''}
            color={'secondary'}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            className={`status-key-badge`}
          />
          <span className='status-key-text'>Scheduled</span>
        </div>
        <div className={`survey-selector-status-key-item`}>
          <Badge
            overlap='circular'
            badgeContent={''}
            color={'primary'}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            className={`status-key-badge`}
          />
          <span className='status-key-text'>Sent</span>
        </div>
        <div className={`survey-selector-status-key-item`}>
          <Badge
            overlap='circular'
            badgeContent={''}
            color={'error'}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            className={`status-key-badge`}
          />
          <span className='status-key-text'>Closed</span>
        </div>
      </div>
    )
  }

  const renderMixedPanel = () => {
    return (
      <Grid item className={'pulse-campaign-build-full-panel'}>
        <MixedAndTemplatePanel editVM={editVM} categoryVM={categoryVM} />
      </Grid>
    )
  }

  return (
    <div id='BuildStep'>
      <Grid
        container
        direction='row'
        wrap='nowrap'
        alignItems='baseline'
        justifyContent='space-between'
        className='surveys-wrapper'
      >
        <Grid item>{renderBackButton()}</Grid>
        <Grid item id='PulseCampaignSurveySelector'>
          <Typography component='span' className='survey-selector-title'>
            {/* {`${lz.no_of} ${labelsStore.getLabelByLanguageAndFor('pulses')}`} */}
            No. of Pulses
          </Typography>
          {renderSurveyButtonGroup()}
          {renderCurrentSurveyStatusKey()}
        </Grid>
      </Grid>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='stretch'
        className={'pulse-campaign-build'}
      >
        {renderMixedPanel()}
      </Grid>
    </div>
  )
}

export default inject('rootStore', 'pulseCampaignsStore')(observer(BuildStep))
