import React from 'react'
import { inject, observer } from 'mobx-react'
import CloseIcon from '@material-ui/icons/Close'
import {
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  DialogActions,
  Button,
  CircularProgress,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from '@material-ui/core'
import './TrainAIDialog.scss'
import { QuestionVM } from '../../view-models/QuestionVM'
import Autocomplete from '@material-ui/lab/Autocomplete'
import LocalizationStore from '../../../localization/LocalizationStore'

interface Props {
  questionVM: QuestionVM
  localizationStore?: LocalizationStore
}

const TrainAIDialog: React.FC<Props> = ({ questionVM, localizationStore }) => {
  if (!questionVM) return null
  const { trainAIVM: vm } = questionVM
  const { nlp: lz } = localizationStore.lzStrings
  if (!vm) return null
  if (!vm.showTrainDialog) return null

  const renderDialogButtons = () => {
    if (vm.addNewLabel) return null
    if (vm.processing) {
      return (
        <Grid container className='dialog-actions' alignItems='center' justifyContent='center'>
          <Grid item>
            <CircularProgress color='primary' />
          </Grid>
        </Grid>
      )
    }
    return (
      <Grid container className='dialog-actions' alignItems='center' justifyContent='flex-end'>
        <Grid item>
          <Button
            variant='text'
            size='large'
            disabled={vm.processing}
            onClick={() => vm.toggleTrainDialog()}
            className='cancelButton'
          >
            {lz.cancel}
          </Button>
          <Button
            variant='contained'
            size='large'
            onClick={() => vm.save()}
            disabled={vm.processing || !vm.allValid}
            className='saveButton'
          >
            {lz.save}
          </Button>
        </Grid>
      </Grid>
    )
  }

  const renderErrorMessage = () => {
    if (!vm.errorMessage) return null
    return <Typography className='error nlp-error'>{vm.errorMessage}</Typography>
  }

  const renderNewLabelFields = () => {
    if (!vm.addNewLabel) return null
    return (
      <section id='NLPBody'>
        <Grid container className='dialog-body dialog-content nlp-content' alignItems='flex-start'>
          <InputLabel required>{lz.new_classification}</InputLabel>
          <TextField
            error={!vm.labelValid}
            variant='outlined'
            placeholder='Add Classification'
            value={vm.newLabel}
            fullWidth
            margin='none'
            onChange={(e) => vm.setNewLabel(e.target.value)}
            required
          />
        </Grid>
      </section>
    )
  }

  const renderAddNewLabelButtons = () => {
    if (!vm.addNewLabel) return null
    return (
      <Grid container className='dialog-actions' alignItems='center' justifyContent='flex-end'>
        <Button id='cancel-button' variant='text' onClick={(e) => vm.toggleAddNewLabel()}>
          {lz.cancel}
        </Button>
        <Button id='save-resource-button' variant='contained' onClick={(e) => vm.saveNewLabel()}>
          {lz.save}
        </Button>
      </Grid>
    )
  }

  const renderInputFields = () => {
    if (vm.addNewLabel) return null
    return (
      <section id='NLPBody'>
        <Grid container className='dialog-body dialog-content nlp-content' alignItems='flex-start'>
          <InputLabel required={true}>{lz.classifications}</InputLabel>
          <Autocomplete
            id='nlpLabelsMultiSelect'
            filterSelectedOptions
            multiple
            options={vm.labels.slice()}
            value={vm.selectedLabels.slice()}
            onChange={(e, value) => vm.setLabels(value)}
            filterOptions={(options, params) => {
              const filteredOpts = []
              options.push('Add New...')
              options.forEach((element) => {
                if (
                  element === 'Add New...' ||
                  element.toLowerCase().includes(params.inputValue.toLowerCase())
                )
                  filteredOpts.push(element)
              })
              return filteredOpts
            }}
            renderInput={(params) => (
              <TextField {...params} variant='outlined' fullWidth required margin='none' />
            )}
          />
          <InputLabel required={true}>{lz.text}</InputLabel>
          <OutlinedInput
            className='nlp-response'
            multiline
            error={!vm.textValid}
            fullWidth
            labelWidth={0}
            autoComplete='off'
            value={vm.responseText}
            onChange={(e) => vm.setText(e.target.value)}
          />
          {renderErrorMessage()}
        </Grid>
      </section>
    )
  }

  return (
    <div id='CustomRecordModal'>
      <Dialog onClose={() => vm.toggleTrainDialog()} open={vm.showTrainDialog} fullWidth>
        <Grid className='dialog-header' container justifyContent='space-between' alignItems='center'>
          <Grid item>
            <DialogTitle>{lz.train_ai_model}</DialogTitle>
          </Grid>
          <Grid item>
            <IconButton className='nlp-close-button' onClick={() => vm.toggleTrainDialog()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        {renderInputFields()}
        {renderNewLabelFields()}
        <DialogActions>
          {renderDialogButtons()}
          {renderAddNewLabelButtons()}
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default inject('localizationStore', 'rootStore')(observer(TrainAIDialog))
