import { computed, observable, action, IReactionDisposer, reaction } from 'mobx'
import { AGGridVM } from '../../shared/ag-grid/AGGridVM'
import { RootStore } from '../../stores/RootStore'
import { SurveyTemplateRowVM } from './SurveyTemplatesRowVM'
import { CellClickedEvent, ColDef, GridOptions, RowClickedEvent, RowNode } from 'ag-grid-community'
import { nameOf } from '../../shared/nameOf'
import { SurveyTemplatesFindDataStore } from '../stores/SurveyTemplatesFindDataStore'
import { ISurveyTemplatesFindRequest } from '../interfaces/ISurveyTemplatesFindRequest'
import { SurveyTemplatesManageVM } from './SurveyTemplatesManageVM'
import SelectAll from '../views/SelectAll'
import { ISurveyTemplateDTO } from '../dtos/ISurveyTemplateDTO'
import { ISurveyTemplatesBulkRequest } from '../interfaces/ISurveyTemplatesBulkRequest'
import { SurveyTemplatesBulkOperationsService } from '../services/SurveyTemplatesBulkOperationsService'
import { SurveyTemplatesTableDataStore } from '../stores/SurveyTemplatesTableDataStore'
import { PulseCampaignEditVM } from '../../pulse-campaigns/view-models/PulseCampaignEditVM'

export class SurveyTemplatesTableVM extends AGGridVM {
  private rootStore: RootStore
  public dataStore: SurveyTemplatesTableDataStore
  private reactions: IReactionDisposer[] = []

  constructor(
    rootStore: RootStore,
    manageVM: SurveyTemplatesManageVM,
    req: ISurveyTemplatesFindRequest
  ) {
    super()
    this.rootStore = rootStore
    this.manageVM = manageVM
    this.dataStore = new SurveyTemplatesTableDataStore(this.rootStore, req)
    this.sizeColumnsToFit = true
    this.serverSideLoaded = true
    if (this.manageVM.isPermissions) {
      this.reactions.push(
        reaction(
          () => this.dataStore.isLoaded,
          () => {
            if (!this.dataStore.isLoaded) return
            setTimeout(() => {
              this.setInitialCheckedRows()
            }, 2000)
          }
        )
      )
    }
  }

  @observable public manageVM: SurveyTemplatesManageVM = null
  @observable public selectedRow: SurveyTemplateRowVM = undefined
  @observable public shouldShowSnackbar: boolean = false
  @observable public snackbarMessage: string = ''
  @observable public selectAll: boolean = false
  @observable public newSelectedRows: Array<any> = []
  @observable public unSelectedRows: Array<any> = []
  @observable public selectedTotalShown: number = 0
  @observable public calculatedSelected: number = 0
  @observable public isProcessing: boolean = false
  @observable public modifyMenuAnchorEl = null
  @observable public hideCounts: boolean = false
  @observable public showDeleteTemplatesDialog: boolean = false
  @observable public showCopyTemplatesDialog: boolean = false
  @observable public showMoveTemplatesDialog: boolean = false

  @computed
  public get isLoaded(): boolean {
    return this.dataStore.isLoaded
  }

  @action
  public getRowNodeId(row): string {
    return row.objectId
  }

  @action
  public setInitialCheckedRows() {
    if (
      this.manageVM.selectedFolder?.subscribedOrganizationIds.includes(
        this.manageVM.selectedOrg.objectId
      )
    ) {
      return this.setSelectAll(true)
    }
    this.gridApi.forEachNode((node) => {
      const data: SurveyTemplateRowVM = node.data
      if (!data) return
      if (data.subscribedOrganizationIds.includes(this.manageVM.selectedOrg.objectId)) {
        node.setSelected(true)
      }
    })
  }

  @computed
  public get actionButtonDisabled() {
    if (this.isProcessing) return true
    if (this.newSelectedRows.length === 0) return true
    return false
  }

  @computed public get allSelectedIndeterminate() {
    if (this.selectAll && !this.allSelected) return true
    return false
  }

  @computed public get allSelectedChecked() {
    if (this.selectAll && this.allSelected) return true
    return false
  }

  @computed public get allSelected() {
    if (!this.selectAll) return false
    if (this.hasFilter) return false
    if (this.totalRecords === this.dialogCount) return true
    return false
  }

  @computed
  public get selectedMoveToFolder() {
    if (!this.manageVM.moveToFolder) return null
    return this.manageVM.moveToFolder.name
  }

  @computed
  public get dialogCount() {
    if (this.selectAll && this.unSelectedRows.length === 0) return this.totalRecords
    if (this.selectAll) return this.totalRecords - this.unSelectedRows.length
    return this.newSelectedRows.length
  }

  @computed
  public get shouldRender(): boolean {
    // if (!this.rootStore.surveyTemplatesStoreV2.loaded) return false
    // if (!this.rootStore.audienceMembersStore.loaded) return false
    return true
  }

  @computed
  public get editVM(): PulseCampaignEditVM {
    return this.rootStore.pulseCampaignsStore.viewModels.editVM
  }

  @action
  public onCellClicked(objectId: string) {
    let orgId = this.rootStore.appStore.currentOrgId
    if (this.manageVM.screenType === 'systemManage') {
      return this.rootStore.appStore.router.push(`/surveyTemplates/edit/${objectId}`)
    }
    this.rootStore.appStore.router.push(`/surveyTemplates/edit/${objectId}/${orgId}`)
  }

  @computed
  public get columnDefs(): ColDef[] {
    const def = [
      {
        width: 50,
        suppressSizeToFit: true,
        checkboxSelection: true,
        sortable: false,
        headerComponent: 'selectAll',
        headerComponentParams: { vm: this },
      },
      {
        headerName: 'Name',
        field: nameOf<SurveyTemplateRowVM, string>((e) => e.name),
        onCellClicked: (e: CellClickedEvent) => {
          this.onCellClicked(e.data.objectId)
        },
      },
      {
        headerName: 'Questions',
        field: nameOf<SurveyTemplateRowVM, number>((e) => e.questionsCount),
        onCellClicked: (e: CellClickedEvent) => {
          this.onCellClicked(e.data.objectId)
        },
        width: 100,
      },
      {
        headerName: 'Owner',
        field: nameOf<SurveyTemplateRowVM, string>((e) => e.owner),
        onCellClicked: (e: CellClickedEvent) => {
          this.onCellClicked(e.data.objectId)
        },
        width: 150,
      },
      {
        headerName: 'Created',
        field: nameOf<SurveyTemplateRowVM, string>((e) => e.created),
        onCellClicked: (e: CellClickedEvent) => {
          this.onCellClicked(e.data.objectId)
        },
      },
      {
        headerName: 'Updated',
        field: nameOf<SurveyTemplateRowVM, string>((e) => e.updated),
        onCellClicked: (e: CellClickedEvent) => {
          this.onCellClicked(e.data.objectId)
        },
      },
    ]
    return def
  }

  @action
  public getGridOptions(): GridOptions {
    return {
      ...this.baseGridOptions,
      onRowClicked: (e) => this.rowClicked(e),
      getRowNodeId: (e) => this.getRowNodeId(e),
      onRowSelected: (e) => this.onRowSelected(e.node),
      isRowSelectable: (rowNode) => {
        if (!rowNode.data) return false
        return true
      },
      suppressRowClickSelection: true,
      suppressRowHoverHighlight: true,
      suppressCellSelection: false,
      rowSelection: 'multiple',
      columnDefs: this.ensureTooltipFields(this.columnDefs),
      frameworkComponents: {
        selectAll: SelectAll,
      },
      pagination: false,
    }
  }

  protected onGridReadied() {
    this.gridApi.setDatasource({
      rowCount: this.totalRecords,
      getRows: async (params) => {
        params.sortModel.forEach((col: { colId: string; sort: 'asc' | 'desc' }, idx: number) => {
          let dbCol = col.colId
          if (dbCol === 'name') dbCol = 'name'
          if (dbCol === 'created') dbCol = 'createdAt'
          if (dbCol === 'updated') dbCol = 'updatedAt'
          if (dbCol === 'owner') dbCol = 'fk_owner.name'
          if (idx === 0) this.dataStore.setSort(dbCol, col.sort)
          if (idx > 0) this.dataStore.addSort(dbCol, col.sort)
        })
        if (params.sortModel.length === 0) this.dataStore.setSort('name')
        if (params.startRow === 0) this.dataStore.setPage(0)
        await this.dataStore.getNextPage()
        const rows = this.dataStore.surveyTemplates.map(
          (e) => new SurveyTemplateRowVM(this.rootStore, e, this)
        )
        params.successCallback(rows, this.dataStore.totalRecords)
      },
    })
  }

  @computed
  public get rows() {
    return []
  }

  @action
  public manageUnselectedRows(node) {
    if (!this.selectAll) return
    if (node.selected) return

    const selection = node.data
    let index
    index = this.unSelectedRows.findIndex((e) => e.objectId === selection.objectId)
    if (index === -1) this.unSelectedRows.push(selection)
    else this.unSelectedRows.splice(index, 1)
  }

  @action
  public manageSelectedRows(node) {
    const selection = node.data
    let index
    index = this.newSelectedRows.findIndex((e) => e.objectId === selection.objectId)
    if (index === -1) this.newSelectedRows.push(selection)
    else this.newSelectedRows.splice(index, 1)
  }

  @action
  public onRowSelected(node: RowNode) {
    this.manageSelectedRows(node)
    this.manageUnselectedRows(node)
    this.calculateAllSelected()
  }

  @action
  public toggleSelectAll() {
    this.selectAll = !this.selectAll
    if (this.selectAll) {
      this.selectAllNodes()
    }
    if (!this.selectAll) {
      this.deSelectAllNodes()
    }
    setTimeout(() => this.setSelectedTotalShown(), 100)
  }

  @action
  public setSelectAll(selectAll: boolean) {
    this.selectAll = selectAll
    if (this.selectAll) {
      this.selectAllNodes()
    }
    if (!this.selectAll) {
      this.deSelectAllNodes()
    }
    setTimeout(() => this.setSelectedTotalShown(), 100)
  }

  @action public calculateAllSelected() {
    if (!this.selectAll) return
    const diff = this.selectedTotalShown - this.newSelectedRows.length
    const result = this.totalRecords - diff
    this.calculatedSelected = result
  }

  @action public setSelectedTotalShown() {
    if (!this.selectAll) return
    if (this.newSelectedRows.length > this.selectedTotalShown) {
      this.selectedTotalShown = this.newSelectedRows.length
    }
    this.calculateAllSelected()
  }

  @action
  selectAllNodes() {
    this.gridApi.forEachNode((node) => {
      node.setSelected(true)
    })
  }

  @action
  deSelectAllNodes() {
    this.gridApi.forEachNode((node) => {
      node.setSelected(false)
    })
  }

  @action
  public rowClicked(e: RowClickedEvent) {
    console.debug(e)
  }

  @action
  public setQuickFilter(val: string) {
    this.typedFilterText = val
    if (this.quickFilterTO) clearTimeout(this.quickFilterTO)
    this.quickFilterTO = setTimeout(() => this.applyFilter(), 1000)
  }

  @action
  resetSelected() {
    this.newSelectedRows = []
    this.unSelectedRows = []
  }

  @action
  public resetNodesSelected() {
    this.selectAll = false
    this.gridApi.deselectAll()
    setTimeout(() => this.resetSelected(), 100)
  }

  public applyFilter() {
    this.resetNodesSelected()
    this.dataStore.setFilter(this.typedFilterText)
    this.reload()
    this.columnApi.applyColumnState({
      state: [{ colId: 'name', sort: 'asc' }],
    })
  }

  @computed
  public get totalRecords() {
    return this.dataStore.totalRecords
  }

  @computed
  public get hasRows(): boolean {
    return this.dataStore.hasListRecords
  }

  @action
  public openSnackbar(msg: string) {
    this.snackbarMessage = msg
    this.shouldShowSnackbar = true
  }

  @action
  public closeSnackbar() {
    this.shouldShowSnackbar = false
  }

  @action
  public setModifyMenuAnchorEl(el) {
    this.modifyMenuAnchorEl = el
  }

  @action
  public navigateToImportSurveyTemplates() {
    this.rootStore.appStore.router.push('/systemAdmin/manage/surveyTemplates/import')
  }

  @action
  public scrollToTop() {
    this.gridApi.ensureIndexVisible(0)
  }

  @action
  public hardRefreshTemplatesList() {
    if (!this.gridApi) return
    this.selectAll = false
    this.scrollToTop()
    this.resetNodesSelected()
    this.gridApi.refreshInfiniteCache()
    this.gridApi.purgeInfiniteCache()
  }

  @action nullAllAnchors() {
    // this.removeMenuAnchorEl = null
    // this.addMenuAnchorEl = null
  }

  @action
  public toggleDeleteTemplatesConfirmDialog() {
    this.showDeleteTemplatesDialog = !this.showDeleteTemplatesDialog
  }

  public getTemplateIds(): string[] {
    const idArray = []
    this.newSelectedRows.map((e) => idArray.push(e.objectId))
    return idArray
  }

  @action
  async getUnselectedTemplates() {
    let unselectedUsers = []
    this.unSelectedRows.map((e) => {
      unselectedUsers.push(e.objectId)
    })
    return unselectedUsers
  }

  @action
  public async moveTemplatesToFolder() {
    let moveToFolderId = this.manageVM.moveToFolder.id
    if (moveToFolderId === 'no-folder') moveToFolderId = null
    let orgId = this.rootStore.appStore.currentOrgId
    if (this.manageVM.screenType === 'systemManage') orgId = 'DEFAULT'
    let selectedTemplateIds = []
    let unselectedTemplates = []
    if (!this.selectAll) selectedTemplateIds = this.getTemplateIds()
    if (this.selectAll && this.dialogCount !== this.totalRecords) {
      unselectedTemplates = await this.getUnselectedTemplates()
    }
    const moveToFolder = this.manageVM.moveToFolder
    this.toggleMoveTemplatesConfirmDialog()
    this.isProcessing = true
    let selectedFolderId = this.manageVM.foldersVM.selectedFolder.id
    if (selectedFolderId === 'no-folder') selectedFolderId = null
    try {
      const req: ISurveyTemplatesBulkRequest = {
        organizationId: orgId,
        moveToFolderId: moveToFolderId,
        folderId: selectedFolderId,
        selectedTemplateIds: selectedTemplateIds,
        allSelected: this.selectAll,
        unselectedTemplateIds: unselectedTemplates,
        type: 'move',
      }
      const svc = new SurveyTemplatesBulkOperationsService()
      const result = await svc.moveTemplatesOperation(req)
      this.isProcessing = false
      if (result.success === true) {
        this.openSnackbar(`Success: Templates have been moved.`)
        setTimeout(() => this.goToFolderAfterMove(moveToFolder), 1000)
      } else if (result.success === false) {
        console.log(result.errorMessage)
        this.openSnackbar(`Error: Something went wrong.`)
      }
      this.isProcessing = false
    } catch (error) {
      console.log(error)
    }
  }

  @action
  public goToFolderAfterMove(node) {
    this.manageVM.foldersVM.setSelectedFolder(node)
  }

  @action
  public async deleteTemplates() {
    let orgId = this.rootStore.appStore.currentOrgId
    if (this.manageVM.screenType === 'systemManage') orgId = 'DEFAULT'
    let selectedTemplateIds = []
    let unselectedTemplates = []
    if (!this.selectAll) selectedTemplateIds = this.getTemplateIds()
    if (this.selectAll && this.dialogCount !== this.totalRecords) {
      unselectedTemplates = await this.getUnselectedTemplates()
    }
    this.toggleDeleteTemplatesConfirmDialog()
    this.isProcessing = true
    let selectedFolderId = this.manageVM.foldersVM.selectedFolder.id
    if (selectedFolderId === 'no-folder') selectedFolderId = null
    try {
      const req: ISurveyTemplatesBulkRequest = {
        organizationId: orgId,
        folderId: selectedFolderId,
        selectedTemplateIds: selectedTemplateIds,
        allSelected: this.selectAll,
        unselectedTemplateIds: unselectedTemplates,
        type: 'delete',
      }
      const svc = new SurveyTemplatesBulkOperationsService()
      const result = await svc.deleteTemplatesOperation(req)
      this.isProcessing = false
      if (result.success === true) {
        this.openSnackbar(`Success: Templates have been deleted.`)
        this.hardRefreshTemplatesList()
      } else if (result.success === false) {
        console.log(result.errorMessage)
        this.openSnackbar(`Error: Something went wrong.`)
      }
      this.isProcessing = false
    } catch (error) {
      console.log(error)
    }
  }

  @action
  public toggleCopyTemplatesConfirmDialog() {
    this.showCopyTemplatesDialog = !this.showCopyTemplatesDialog
  }

  @action
  public toggleMoveTemplatesConfirmDialog() {
    this.showMoveTemplatesDialog = !this.showMoveTemplatesDialog
    if (!this.showMoveTemplatesDialog) this.toggleIsMovingTemplates()
  }

  @action
  public toggleIsMovingTemplates() {
    this.manageVM.isMovingTemplatesToFolder = !this.manageVM.isMovingTemplatesToFolder
    if (!this.manageVM.isMovingTemplatesToFolder) {
      this.manageVM.moveToFolder = null
    }
  }

  @action
  public async copyTemplates() {
    let orgId = this.rootStore.appStore.currentOrgId
    if (this.manageVM.screenType === 'systemManage') orgId = 'DEFAULT'
    let selectedTemplateIds = []
    let unselectedTemplates = []
    if (!this.selectAll) selectedTemplateIds = this.getTemplateIds()
    if (this.selectAll && this.dialogCount !== this.totalRecords) {
      unselectedTemplates = await this.getUnselectedTemplates()
    }
    this.toggleCopyTemplatesConfirmDialog()
    this.isProcessing = true
    let selectedFolderId = this.manageVM.foldersVM.selectedFolder.id
    if (selectedFolderId === 'no-folder') selectedFolderId = null
    try {
      const req: ISurveyTemplatesBulkRequest = {
        organizationId: orgId,
        folderId: selectedFolderId,
        selectedTemplateIds: selectedTemplateIds,
        allSelected: this.selectAll,
        unselectedTemplateIds: unselectedTemplates,
        type: 'copy',
      }
      const svc = new SurveyTemplatesBulkOperationsService()
      const result = await svc.copyTemplatesOperation(req)
      this.isProcessing = false
      if (result.success === true) {
        this.openSnackbar(`Success: Templates have been queued for Copy`)
        this.hardRefreshTemplatesList()
      } else if (result.success === false) {
        console.log(result.errorMessage)
        this.openSnackbar(`Error: Something went wrong.`)
      }
      this.isProcessing = false
    } catch (error) {
      console.log(error)
    }
  }
}
