import { action, computed, observable } from 'mobx'
import { TrainingItem } from '../../training-items/aggregate/TrainingItem'
import { RootStore } from '../../stores/RootStore'
import { TrainingPlanEditVM } from './TrainingPlanEditVM'
import { ContentItem } from '../../training-items/aggregate/ContentItem'

export class TrainingItemCardVM {
  private rootStore: RootStore
  public trainingItem: TrainingItem
  public trainingPlanVM: TrainingPlanEditVM

  constructor(
    rootStore: RootStore,
    trainingItem: TrainingItem,
    trainingPlanVM: TrainingPlanEditVM
  ) {
    this.rootStore = rootStore
    this.trainingItem = trainingItem
    this.trainingPlanVM = trainingPlanVM
  }

  @observable public isActive: boolean = false
  @observable public anchorEl: any = null

  @computed
  public get categories(): string[] {
    return this.trainingItem.categories
  }

  @computed
  public get categoryNames(): string[] {
    const categoryNames = []
    this.trainingItem.categories.forEach((id) => {
      const category = this.rootStore.categoriesStore.getCategory(id)
      if (!category) return
      categoryNames.push(category.name)
    })

    return categoryNames
  }

  @computed
  public get isSelected(): boolean {
    return Boolean(
      this.trainingPlanVM.trainingPlanItems.filter(
        (plan) => plan.trainingItemId === this.trainingItem.objectId
      )[0]
    )
  }

  @computed
  public get trainingItemCompletionString(): string {
    if (this.trainingItem.requireQuiz && this.trainingItem.requireCertificate)
      return '+ Quiz + Certificate'
    if (this.trainingItem.requireQuiz) return '+ Quiz'
    if (this.trainingItem.requireCertificate) return '+ Certificate'
    return ''
  }

  @computed
  public get id(): string {
    return this.trainingItem.objectId
  }

  @computed
  public get title(): string {
    return this.trainingItem.name
  }

  @computed
  public get block(): string {
    return this.trainingItem.block
  }

  @computed
  public get subtitleItemsString(): string {
    if (this.contentItems.length > 1) return 'items'
    return 'item'
  }

  @computed
  public get subtitle(): string {
    return `${this.trainingItem.contentItems.length} ${this.subtitleItemsString} ${this.trainingItemCompletionString}`
  }

  @computed
  public get contentItems(): ContentItem[] {
    return this.trainingItem.contentItems
  }

  @action
  public setAnchorEl(e) {
    this.anchorEl = e
  }

  @action
  public onSelect() {
    if (this.isSelected || this.trainingPlanVM.editIsDisabled) return
    this.trainingPlanVM.addTrainingItem(this.trainingItem)
  }
}
