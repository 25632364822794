import { observable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Media } from '../../surveys/aggregate/Media'

export abstract class MediaItemVM {
  protected rootStore: RootStore
  @observable public media: Media

  constructor(rootStore, media: Media) {
    this.rootStore = rootStore
    this.media = media
  }

  public abstract get isImage(): boolean
  public abstract get isVideo(): boolean
  public abstract get isLoaded(): boolean

  public abstract get path(): string
  public abstract get hasMedia(): boolean
  public abstract get mediaClassName(): string

  public abstract get type(): string

  public abstract toDTO()

  public abstract get isCMSItem(): boolean
}
