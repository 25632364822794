import { action, observable, computed, reaction, IReactionDisposer, when, runInAction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Survey } from '../aggregate/Survey'
import { QuestionVM } from './QuestionVM'
import { QuestionType } from '../types/QuestionType'
import { Path } from '../models/Path'
import { ReminderVM } from './ReminderVM'
import { isEqual } from 'lodash'
import { WeekdayVM } from './WeekdayVM'
import { RepeatModeType } from '../types/RepeatModeType'
import { EndingModeType } from '../types/EndingModeType'
import { PublishModeType } from '../types/PublishModeType'
import moment from 'moment-timezone'
import { PeriodType } from '../types/PeriodType'
import { TimeZoneType } from '../types/TimeZoneType'
import _ from 'lodash'
import { UserSurveyTakeVM } from '../../user-surveys/view-models/UserSurveyTakeVM'
import { UserSurvey } from '../../user-surveys/aggregate/UserSurvey'
import { SurveyEditTabsEnum } from '../types/SurveyEditTabsEnum'
import { FlowFormatType } from '../types/FlowFormatType'
import HashId from '../../shared/HashId'
import { AnalysisVM } from './AnalysisVM'
import SurveyType from '../../survey-types/store/aggregate/SurveyType'
import { EmailTemplatesVM } from './EmailTemplatesVM'
import { ParticipantsSelectVM } from '../../participants-select/view-models/ParticipantsSelectVM'
import { IParticipant } from '../../participants-select/interfaces/IParticipant'
import { IAnonymousParticipantDTO } from '../../participants-select/interfaces/IAnonymousParticipantDTO'
import { Worksheet } from '../../worksheets/aggregate/Worksheet'
import { TestDataVM } from './TestDataVM'
import { VisibilityEditorVM } from './VisibilityEditorVM'
import { Question } from '../aggregate/Question'
import { Question as CatalogQuestion } from '../../questions/aggregates/Question'
import { CategoryPickerVM } from '../../shared/category-picker/CategoryPickerVM'
import { QuestionsCatalogVM } from './edit/questions-catalog/QuestionsCatalogVM'
import { Event } from '../../events/aggregate/Event'
import { SurveysService } from '../service/SurveysService'
import { ISurveyDTO } from '../dtos/ISurveyDTO'
import { SurveyResponsesService } from 'src/app/survey-responses/services/SurveyResponsesService'
import { ISurveyUpdateResult } from '../interfaces/ISurveyUpdateResult'
import { IParticipantDTO } from '../dtos/IParticipantDTO'
import * as Sentry from '@sentry/browser'
import { SurveyOptionsVM } from './SurveyOptionsVM'
import { SurveyEditSteppersVM } from './SurveyEditSteppersVM'
import { QuestionsImportVM } from '../../questions/view-models/QuestionsImportVM'
import { IQuestionDTO } from '../dtos/IQuestionDTO'
import env from '../../../env'
import { DueAfterModelType } from '../types/DueAfterModelType'
import { DateUtils } from '../../shared/data/DateUtils'
import { SurveyParticipantsAGGridVM } from '../../surveyParticipants/view-models/SurveyParticipantsAGGridVM'
import SurveyTag from 'src/app/survey-tags/aggregate/SurveyTag'
import { SurveyIntroductionVM } from './SurveyIntroductionVM'
import LocalizationStore from '../../localization/LocalizationStore'
import { LabelsStore } from '../../labels/store/LabelsStore'
import { ParseService } from '../../services/ParseService'
import { VisibleParticipant } from '../aggregate/VisibleParticipant'
import { SurveyCompletedContentVM } from './SurveyCompletedContentVM'
import { SurveyTemplatesService } from '../../survey-templates/services/SurveyTemplatesService'
import { AdditionalResponseNotificationEditorVM } from './AdditionalResponseNotificationEditorVM'
import { OrganizationUsersService } from '../../organization-users/service/OrganizationUsersService'
import { IOrganizationUserAudienceSearchRequest } from '../../organization-users/interfaces/IOrganizationUserAudienceSearchRequest'
import { Owner } from '../aggregate/Owner'
import User from '../../user/aggregate/User'
import { FKUser } from '../../organization-users/aggregate/FKUser'

export class SurveyEditVM {
  private rootStore: RootStore
  private DEBUG_DIAGRAM: boolean = false
  private drawAttempts: number = 0
  private svc: SurveysService
  private loadDiagramTimeout: any = null
  public survey: Survey = null
  private surveyResponseSvc: SurveyResponsesService
  private fromTemplate: boolean = false
  private forEvent: boolean
  private reactions: IReactionDisposer[] = []
  protected quickFilterTO: NodeJS.Timer

  constructor(
    rootStore: RootStore,
    survey: Survey,
    feedbackParticipants?: IParticipant[],
    forEvent: boolean = false,
    fromTemplate: boolean = false
  ) {
    this.rootStore = rootStore
    this.survey = survey
    if (survey.organizationId === 'DEFAULT') {
      this.checkSurveyTypeId()
    }
    if (!survey.published && survey.isAutoSaveDraft) {
      this.autoSaveId = survey.objectId
      this.isAutoSaveDraft = survey.isAutoSaveDraft
    }
    this.cleanSurvey = survey.clone()
    this.fromTemplate = fromTemplate
    this.rootStore.audienceMembersStore.loadAudienceMembers()

    this.forEvent = forEvent
    this.svc = new SurveysService(this.rootStore)
    this.setCurrentQuestion(0)
    this.feedbackParticipantsSelectVM = new ParticipantsSelectVM(this.rootStore, false, false) // initialized
    this.setPublishedByUser(this.survey.publishedByUserId)
    this.setFkPublishedByUser(
      this.survey.fk_publishedByUserId ? this.survey.fk_publishedByUserId : null
    )
    this.participantsSelectVM.setPillsPerRow(2)
    this.participantsSelectVM.setAnonymousParticipants(
      survey.anonymousParticipants as IAnonymousParticipantDTO[]
    )
    this.participantsSelectVM.setReadOnly(this.isClosed)
    if (feedbackParticipants) {
      this.feedbackParticipantsSelectVM.setParticipants(feedbackParticipants as IParticipant[])
    }
    this.loadParticipantVMData(survey, feedbackParticipants)
    this.testDataVM = new TestDataVM(rootStore, this)
    this.questionsImportVM = new QuestionsImportVM(this.rootStore, true, this)
    this.categoryPickerVM = new CategoryPickerVM(this.rootStore)
    this.categoryPickerVM.setAppliedCategory(this.mainCategoryId)
    this.questionCategoryPickerVM = new CategoryPickerVM(this.rootStore)
    this.analysis = null
    this.analysis = new AnalysisVM(this.rootStore, this.survey.objectId, this)
    this.loadInvitationsList()
    this.emailTemplates = new EmailTemplatesVM(this.rootStore, survey)
    this.currentTabIndex = SurveyEditTabsEnum.DETAILS
    if (this.isPublished) {
      this.publishTried = true
      this.currentTabIndex = SurveyEditTabsEnum.ANALYSIS
    }
    this.visibilityEditorVM = new VisibilityEditorVM(this.rootStore, survey)
    this.additionalResponseNotificationEditorVM = new AdditionalResponseNotificationEditorVM(
      this.rootStore,
      survey
    )
    this.surveyIntroductionVM = new SurveyIntroductionVM(this.rootStore, this)
    this.surveyCompletedContentVM = new SurveyCompletedContentVM(this.rootStore, this)
    if (survey.eventId) this.participantsSelectVM.setEvent(survey.eventId)
    if (survey.surveyIntroduction) {
      this.surveyIntroductionEnabled = true
    }
    this.surveyOptionsVM = new SurveyOptionsVM(rootStore, this)
    this.steppersVM = new SurveyEditSteppersVM(rootStore, this)
    this.lz = this.rootStore.localizationStore.lzStrings.surveys
    this.labelsStore = this.rootStore.labelsStore

    if (
      this.survey.scheduleEnabled &&
      this.survey.schedule.publishGroups &&
      this.survey.schedule.publishGroups.length > 0
    ) {
      this.toggleCustomPublishGroup()
      this.surveyOptionsVM.setSurveyPublishModeType('multiple')
    } else if (this.survey.scheduleEnabled) {
      this.surveyOptionsVM.setSurveyPublishModeType('later')
    }

    this.dueDateTime = DateUtils.getBrowserDate(
      this.survey.dueAfter.dueDate,
      this.survey.dueAfter.dueTimeZone
    )
    this.dueDateDate = DateUtils.getBrowserDate(
      this.survey.dueAfter.dueDate,
      this.survey.dueAfter.dueTimeZone
    )
    this.startDateDate = DateUtils.getBrowserDate(
      this.survey.schedule.startDate,
      this.survey.schedule.deliveryTimeZone
    )
    this.startDateTime = DateUtils.getBrowserDate(
      this.survey.schedule.startDate,
      this.survey.schedule.deliveryTimeZone
    )

    window.onresize = () => {
      setTimeout(() => this.clearDiagram(), 10)
      setTimeout(() => this.loadDiagram(), 500)
    }
    setTimeout(() => this.loadDiagram(), 500)

    if (this.survey.isNew) {
      if (fromTemplate) return
      this.loadDefaultAnonymizeResults()
      this.loadDefaultForceEmails()
      this.setDefaultSurveyTypeAndWorksheet()
      this.loadDefaultEmailFrom()
      this.loadDefaultQuestion()
      this.setPublishedByUser(this.rootStore.appStore.currentUserId)
      this.setFkPublishedByUser({
        name: this.rootStore.userStore.user.name,
        objectId: this.rootStore.userStore.user.objectId,
      } as FKUser)
      this.prepopulateDeliveryTime()
    }

    this.reactions.push(
      reaction(
        () => this.survey.questions.length,
        () => {
          setTimeout(() => {
            this.loadDiagramHeight()
            this.resetDiagram()
          }, 300)
        }
      )
    )

    setTimeout(() => {
      this.loadDiagramHeight()
      this.resetDiagram()
    }, 1500)

    if (
      this.rootStore.organizationsStore.currentOrganization &&
      this.rootStore.organizationsStore.currentOrganization.autoSaveSurveys
    ) {
      this.reactions.push(
        reaction(
          () => this.isDirty,
          () => {
            if (this.isDirty === true) {
              this.autoSaveValidation()
            }
          }
        )
      )
    }

    this.reactions.push(
      reaction(
        () => JSON.stringify(this.survey),
        () => {
          if (this.cancelIsDirty || this.isPaperSurveyParticipantsPickerOpen) {
            this.isDirty = false
          } else {
            this.deepEqual(this.cleanSurvey, this.survey)
          }
        }
      )
    )

    this.reactions.push(
      reaction(
        () => this.isPublished && this.limited,
        () => {
          const tab = !this.limited ? SurveyEditTabsEnum.ANALYSIS : SurveyEditTabsEnum.DETAILS
          this.setCurrentTab(tab)
        }
      )
    )

    this.reactions.push(
      when(
        // once participants are loaded
        () =>
          this.participantsLoaded &&
          this.visibilityEditorVM.participantsLoaded &&
          this.additionalResponseNotificationEditorVM.participantsLoaded,
        // monitor the infoString for changes
        () => {
          this.reactions.push(
            reaction(
              () => this.infoString,
              () => {
                if (this.cancelIsDirty) {
                  this.isDirty = false
                } else {
                  this.isDirty = true
                }
              }
            )
          )
        }
      )
    )

    this.reactions.push(
      reaction(
        () => this.surveyTypeId,
        () => {
          this.setFeedbackParticipantsSelectVM()
        },
        { delay: 300 }
      )
    )

    // reaction needed because if user refreshes page, survey types load
    // after the VM and the setter can't find the default surveyType
    this.reactions.push(
      reaction(
        () => this.surveyTypes.length,
        () => {
          if (this.survey) return
          if (this.surveyTypeId && this.worksheetId) return
          this.findAndSetDefaultSurveyTypeAndWorksheet()
        }
      )
    )

    this.reactions.push(
      reaction(
        () => this.rootStore.audienceMembersStore && this.rootStore.audienceMembersStore.updatedAt,
        () => {
          if (this.participantsSelectVM) {
            this.participantsSelectVM.setAnonymousParticipants(
              survey.anonymousParticipants as IAnonymousParticipantDTO[]
            )
            this.participantsSelectVM.updateParticipants(this.survey.participants as IParticipant[])
            if (feedbackParticipants) {
              this.feedbackParticipantsSelectVM = new ParticipantsSelectVM(
                this.rootStore,
                false,
                false
              )
              this.feedbackParticipantsSelectVM.setParticipants(
                feedbackParticipants as IParticipant[]
              )
            }
            this.participantsLoaded = true
          }
        }
      )
    )
  }

  private async checkSurveyTypeId() {
    const type = this.rootStore.surveyTypesStore.getSurveyType(this.surveyTypeId)
    if (!type || type.organizationId !== 'DEFAULT') {
      this.survey.surveyTypeId = null
      this.setDefaultSurveyTypeAndWorksheet()
    }
  }

  private async loadParticipantVMData(survey, feedbackParticipants) {
    await this.rootStore.audienceMembersStore.loadAudienceMembers()
    runInAction(() => {
      this.participantsSelectVM.setAnonymousParticipants(
        survey.anonymousParticipants as IAnonymousParticipantDTO[]
      )
      this.participantsSelectVM.updateParticipants(this.survey.participants as IParticipant[])
      if (feedbackParticipants) {
        this.feedbackParticipantsSelectVM = new ParticipantsSelectVM(this.rootStore, false, false)
        this.feedbackParticipantsSelectVM.setParticipants(feedbackParticipants as IParticipant[])
      }
      this.participantsLoaded = true
    })
  }

  private setFeedbackParticipantsSelectVM() {
    if (!this.surveyType) return
    if (!this.surveyType.isFeedbackType) return
    let allowMultipleForUsers = this.surveyType.allowMultipleForUsers
    if (!allowMultipleForUsers) allowMultipleForUsers = false
    this.feedbackParticipantsSelectVM = new ParticipantsSelectVM(
      this.rootStore,
      false,
      false,
      allowMultipleForUsers,
      allowMultipleForUsers,
      false,
      allowMultipleForUsers,
      false,
      this.survey ? this.survey.publishedByUserId : undefined
    )
    if (this.survey) {
      this.feedbackParticipantsSelectVM.setParticipants(
        this.survey.feedbackParticipants as IParticipant[]
      )
      if (this.survey.feedbackForLocked) {
        this.feedbackParticipantsSelectVM.setReadOnly(true)
        return
      }
    }
    this.feedbackParticipantsSelectVM.setReadOnly(this.isReadOnly)
  }

  private loadDefaultQuestion() {
    this.survey.addQuestion(Question.createDefaultFromEmojiType())
  }

  private loadInvitationsList() {
    this.surveyParticipantsList = undefined
    setTimeout(() => {
      this.surveyParticipantsList = new SurveyParticipantsAGGridVM(this.rootStore, this.survey)
    }, 100)
  }

  @observable public feedbackParticipantsSelectVM: ParticipantsSelectVM = null
  @observable public participantsSelectVM: ParticipantsSelectVM = null
  @observable public saveDraftTried: boolean = false
  @observable public publishTried: boolean = false
  @observable public sidePanelShown: boolean = false
  @observable public currentQuestionIndex: number = 0
  public startBadgeRect: ClientRect | DOMRect
  public endBadgeRect: ClientRect | DOMRect
  public addQuestionButtonRect: ClientRect | DOMRect
  public containerRect: ClientRect | DOMRect
  public container: HTMLElement
  @observable public masterCategoryName: string = ''
  @observable public currentTabIndex: number = 0
  @observable public anonymousEmail: string = ''
  public paths: Array<Path> = []
  @observable public pathsLastUpdated: number = 0
  @observable public isSnackbarOpen: boolean
  @observable public snackbarMessage: string = ''
  @observable public isSaving: boolean = false
  @observable public cancelIsDirty: boolean = false // Prevents the isDirty flag from being set to true, which in turn is controlling the disabled status of the Publish/update button when there is no diff between published and current survey
  @observable public saveProgress: number = 0
  @observable public oldIndex: number = 0
  @observable public newIndex: number = 0
  @observable public analysis: AnalysisVM = null
  @observable public surveyParticipantsList: SurveyParticipantsAGGridVM = null
  @observable public emailTemplates: EmailTemplatesVM = null
  @observable public questionsImportVM: QuestionsImportVM = null
  @observable public showDeleteDialog: boolean = false
  @observable public cantDeleteLastQuestion: boolean = false
  @observable public templateId: string = ''
  @observable public showImportModal: boolean = false
  @observable public categoryPickerVM: CategoryPickerVM = null
  @observable public questionCategoryPickerVM: CategoryPickerVM = null
  @observable public questionsCatalogVM: QuestionsCatalogVM = null
  @observable public previewVM: UserSurveyTakeVM = null
  @observable public responseVM: UserSurveyTakeVM = null
  @observable public closeSurveyDialogOpen: boolean = false
  @observable public virtualListParent: any = null
  @observable public questionTypes: Array<string> = [
    'emoji',
    'check',
    'radio',
    'rating',
    'starRating',
    'text',
    'yesNo',
    'infoText',
    'number',
    'order',
    'dropdown',
  ]
  @observable public cleanSurvey: Survey = null
  @observable public isDirty: boolean = false
  @observable public surveyIntroductionEnabled: boolean = true
  @observable public surveyFlowCanvasHeight: number = 0
  @observable public builderHeight: number = 0
  @observable public surveyOptionsVM: SurveyOptionsVM = null
  @observable public isPaperSurveyParticipantsPickerOpen: boolean = false
  @observable public paperSurveyParticipantsSelectVM: ParticipantsSelectVM = null
  @observable public steppersVM: SurveyEditSteppersVM = null
  @observable public surveyWasJustPublished: boolean = false
  @observable public dueDateTime: Date
  @observable public dueDateDate: Date
  @observable public dueDateValid: boolean = true
  @observable public startDateDate: Date
  @observable public startDateDateValid: boolean = true
  @observable public startDateTime: Date
  public testDataVM: TestDataVM = null
  @observable public visibilityEditorVM: VisibilityEditorVM = null
  @observable
  public additionalResponseNotificationEditorVM: AdditionalResponseNotificationEditorVM = null
  public vmCreatedAt: Date = new Date()
  @observable public responseJustDeleted: boolean = false
  @observable public confirmSaveDialogOpen: boolean = false
  @observable public doPublish: boolean = false
  @observable public surveyIntroductionVM: SurveyIntroductionVM = null
  @observable public autoSaveId: string = ''
  @observable public isAutoSaveDraft: boolean = false
  @observable public autoSaveInt: any = null
  @observable public lz = null
  @observable public labelsStore: LabelsStore = null
  @observable public participantsLoaded: boolean = false
  @observable public surveyDetailsTabIndex: number = 0
  @observable public surveyCompletedContentVM: SurveyCompletedContentVM = null
  @observable public isSurveyTemplateEdit: boolean = false
  @observable public saveToFolderId: string = null
  @observable public usersOrdered: Owner[] = []
  @observable public loadingOwners = false

  @action
  public setIsSurveyTemplateEdit(isSurveyTemplateEdit: boolean) {
    this.isSurveyTemplateEdit = isSurveyTemplateEdit
    this.surveyOptionsVM.setIsSurveyTemplateEdit(isSurveyTemplateEdit)
  }

  @action
  public togglePaperSurveyParticipantsPickerOpen() {
    this.isPaperSurveyParticipantsPickerOpen = !this.isPaperSurveyParticipantsPickerOpen
    if (this.isPaperSurveyParticipantsPickerOpen) {
      this.paperSurveyParticipantsSelectVM = new ParticipantsSelectVM(
        this.rootStore,
        false,
        false,
        false,
        false,
        false,
        this.forEvent ? true : false
      )
      this.paperSurveyParticipantsSelectVM.setVisible()
    }
  }

  @computed
  public get isPaperSurveyParticipantsPickerSubmitDisabled() {
    if (!this.paperSurveyParticipantsSelectVM) return true
    return this.paperSurveyParticipantsSelectVM.participants.length !== 1
  }

  @action
  public async createUserSurveyForPaperSurvey() {
    const svc = new ParseService()
    const userSurvey: UserSurvey = await svc.createUserSurveyForPaperSurvey(
      this.survey.organizationId,
      this.survey.objectId,
      this.paperSurveyParticipantsSelectVM.participants[0].id,
      this.toDTO(),
      null,
      this.rootStore.appStore.currentUserId
    )
    if (!userSurvey) {
      console.error('User survey for paper survey is null or undefined.')
    } else {
      this.rootStore.appStore.router.push(`/usersurveys/paperSurvey/${userSurvey.objectId}`)
    }
  }

  @computed
  public get objectId(): string {
    if (this.isAutoSaveDraft && !this.isPublished && !this.isTemplate) return ''
    return this.survey.objectId
  }

  @computed
  public get surveyIntroduction(): string {
    return this.survey.surveyIntroduction
  }

  @computed
  public get surveyCompletedContent(): string {
    return this.survey.surveyCompletedContent
  }

  @computed
  public get mainCategoryId(): string {
    return this.survey.mainCategoryId
  }

  @computed
  public get canSetOptionlessBranching(): boolean {
    if (this.currentQuestion.type !== 'text' && this.currentQuestion.type !== 'number') return false
    if (this.questions.length === 1) return false
    return true
  }

  @computed
  public get publishedDateTime(): Date {
    return moment(this.survey.publishedDateTime).toDate()
  }

  @computed
  public get publishedByUserId(): string {
    return this.survey.publishedByUserId
  }

  @computed
  public get name(): string {
    return this.survey.name
  }

  @computed
  public get categoryId(): string {
    return this.survey.categoryId
  }

  @computed
  public get isTemplate(): boolean {
    return this.survey.isTemplate
  }

  @computed
  public get eventId(): string {
    return this.survey.eventId
  }

  @computed
  public get isClosed(): boolean {
    return this.survey.isClosed
  }

  @computed
  public get linksNeverExpire(): boolean {
    return this.survey.linksNeverExpire
  }

  @computed
  public get closedByUserId(): string {
    return this.survey.closedByUserId
  }

  @computed
  public get closedDate(): Date {
    return this.survey.closedDateAsDate
  }

  @computed
  public get surveyTypeId(): string {
    return this.survey.surveyTypeId
  }

  @computed
  public get surveyTagId(): string {
    return this.survey.surveyTagIds[0]
  }

  @computed
  public get worksheetId(): string {
    return this.survey.worksheetId
  }

  @computed
  public get userTaskId(): string {
    return this.survey.userTaskId
  }

  @computed
  public get emailFromName(): string {
    return this.survey.emailFromName
  }

  @computed
  public get emailFromAddress(): string {
    return this.survey.emailFromAddress
  }

  @computed
  public get questionsFlowFormat(): FlowFormatType {
    return this.survey.questionsFlowFormat
  }

  @computed
  public get anonymizeResults(): boolean {
    return this.survey.anonymizeResults
  }

  @computed
  public get showResults(): boolean {
    return this.survey.showResults
  }

  @computed
  public get forceEmails(): boolean {
    return this.survey.forceEmails
  }

  @computed
  public get sendEmailOnResponse(): boolean {
    return this.survey.sendEmailOnResponse
  }

  @computed
  public get sendEmailToAdditionalUsersOnResponse(): boolean {
    return this.survey.sendEmailToAdditionalUsersOnResponse
  }

  @computed
  public get forceTextMessages(): boolean {
    return this.survey.forceTextMessages
  }

  @computed
  public get dueAfterEnabled(): boolean {
    return this.survey.dueAfterEnabled
  }

  @computed
  public get surveysAlwaysAvailable(): boolean {
    return this.survey.alwaysAvailable
  }

  @computed
  public get dueAfterPeriod(): PeriodType {
    return this.survey.dueAfterPeriod
  }

  @computed
  public get dueAfterValue(): number {
    return this.survey.dueAfterValue
  }

  @computed
  public get scheduleEnabled(): boolean {
    return this.survey.scheduleEnabled
  }

  @computed
  public get customPublishEnabled(): boolean {
    return this.surveyOptionsVM.customPublishEnabled
  }

  @computed
  public get repeatMode(): RepeatModeType {
    return this.survey.repeatMode
  }

  @computed
  public get startDate(): Date {
    return this.survey.startDate
  }

  @computed
  public get endDate(): Date {
    return this.survey.endDate
  }

  @computed
  public get endingMode(): EndingModeType {
    return this.survey.endingMode
  }

  @computed
  public get deliveryTimeZone(): TimeZoneType {
    return this.timeZones.find((e) => e.value === this.survey.deliveryTimeZone)
  }

  @computed
  public get weekdays() {
    const days = Object.keys(this.survey.weekdays)
    return days.map((e) => new WeekdayVM(e, this))
  }

  @action
  public setDueDateMode(mode: DueAfterModelType) {
    this.survey.setMode(mode)
  }

  @computed
  public get dueDateMode() {
    return this.survey.dueAfter.mode
  }

  @action
  public toggleDueAfterEnabled() {
    this.survey.toggleDueAfterEnabled()
  }

  @computed
  public get reminderDueDateText() {
    if (this.dueAfterEnabled && this.dueDateMode === 'specific') {
      const date = moment(this.dueDateDate).format(' MMMM Do, YYYY')
      const time = moment(this.dueDateTime).format('h:mm a')
      return date + ', at ' + time
    }
    if (this.dueAfterEnabled && this.dueDateMode === 'period') {
      let { value, period } = this.survey.dueAfter
      const due = moment(new Date())
      due.add(value, period)
      while (!this.isZeroSeconds(due.seconds())) due.subtract(1, 'second')
      while (!this.is15MinuteIncrement(due.minutes())) due.add(1, 'minute')
      return due.format('MMMM Do, YYYY, h:mm a')
    } else return 'None Selected'
  }

  private isZeroSeconds(sec) {
    return sec === 0
  }

  private is15MinuteIncrement(min) {
    return min === 45 || min === 0 || min === 30 || min === 15
  }

  @action
  public toggleSurveysAreAlwaysAvailable() {
    this.survey.toggleAlwaysAvailable()
    if (this.survey.alwaysAvailable) this.survey.linksNeverExpire = true
    if (!this.survey.alwaysAvailable) this.survey.linksNeverExpire = false
  }

  @action
  public toggleAutoCancel() {
    this.survey.autoCancel.enabled = !this.survey.autoCancel.enabled
  }

  @computed
  public get autoCancelWhenDue() {
    return this.survey.autoCancel.enabled
  }

  @action
  public setDueMode(type) {
    this.survey.setMode(type)
  }

  @action
  public setResponseJustDeleted() {
    this.responseJustDeleted = true
  }

  @computed
  public get dueDateType(): string {
    if (this.survey.dueAfter && this.survey.dueAfter.enabled) return this.survey.dueAfter.mode
    return 'specific'
  }

  @computed
  public get dueTimeZone(): TimeZoneType {
    return this.timeZones.find((e) => e.value === this.survey.dueAfter.dueTimeZone)
  }

  @computed
  public get alarms() {
    return this.survey.alarms
  }

  @computed
  public get reminders(): Array<ReminderVM> {
    return this.alarms.selectedAlarms.map((e) => new ReminderVM(e))
  }

  @computed
  public get periodReminders(): Array<ReminderVM> {
    return this.alarms.selectedAlarms
      .filter((e) => e.mode === 'period')
      .map((e) => new ReminderVM(e))
  }

  @computed
  public get specificReminders(): Array<ReminderVM> {
    return this.alarms.selectedAlarms
      .filter((e) => e.mode === 'specific')
      .map((e) => new ReminderVM(e))
  }

  @computed
  public get sendReminders(): boolean {
    return this.survey.sendReminders
  }

  @computed
  public get sendRemindersOnDueDate(): boolean {
    return this.survey.sendRemindersOnDueDate
  }

  @computed
  public get sendReminders15BeforeDueDate(): boolean {
    return this.survey.sendReminders15BeforeDueDate
  }

  @computed
  public get sendRemindersCustomPeriod(): boolean {
    return this.survey.sendRemindersCustomPeriod
  }

  @computed
  public get sendRemindersSpecificDate(): boolean {
    return this.survey.sendRemindersSpecificDate
  }

  @computed
  public get questions(): Array<QuestionVM> {
    return this.survey.questions
      .filter((e) => !e.isDeleted)
      .map((q) => new QuestionVM(this.rootStore, this, q))
  }

  @action
  public clearQuestions() {
    this.survey.clearQuestions()
  }

  @action
  public toggleWeekday(day) {
    this.survey.toggleWeekday(day)
  }

  @computed
  public get templateOnlyEditableByOwner(): boolean {
    return this.survey.templateOnlyEditableByOwner
  }

  @action
  public toggleTemplateEditability() {
    this.survey.toggleTemplateEditability()
  }

  private findAndSetDefaultSurveyTypeAndWorksheet() {
    if (this.survey.organizationId === 'DEFAULT') return
    const defaultType = this.surveyTypes.find((type) => type.isDefaultSurveyType && !type.isDeleted)
    if (defaultType) {
      if (defaultType.objectId && !this.surveyTypeId) this.setSurveyType(defaultType.objectId)
      if (defaultType.worksheetId && !this.worksheetId) this.setWorksheet(defaultType.worksheetId)
    }
  }

  private setDefaultSurveyTypeAndWorksheet() {
    if (this.surveyTypes.length === 0) return
    this.findAndSetDefaultSurveyTypeAndWorksheet()
  }

  @action
  public setVirtualListParent(parentEl: string) {
    this.virtualListParent = document.getElementById(parentEl)
  }

  @action
  public setUserTask(id: string) {
    this.survey.setUserTaskId(id)
  }

  @action
  public setTemplate(id: string) {
    this.templateId = id
  }

  @action
  public openCloseSurveyDialog() {
    this.toggleCloseSurveyDialog()
  }

  @computed
  public get responseTabString(): string {
    if (this.analysis && this.analysis.surveyResponsesTotalCount)
      return `Responses (${this.analysis.surveyResponsesTotalCount})`
    return 'Responses'
  }

  @computed
  public get isSystemAdmin() {
    if (!this.rootStore.userStore) return false
    if (!this.rootStore.userStore.user) return false
    return this.rootStore.userStore.user.isSystemAdmin
  }

  @action async runNLPProcessing() {
    this.isSaving = true
    this.setSaveProgress(0, true)
    await this.svc.processShortAnswers(this.objectId, this.rootStore.appStore.currentOrgId)
    this.setSaveProgress(100, false)
    this.openSnackbar('Responses Data is being processed by AI')
    this.isSaving = false
    setTimeout(() => this.rootStore.surveysStore.viewModels.loadSurveyEditVM(this.objectId), 1000)
  }

  @action
  public async saveAsSurveyTemplate() {
    this.isSaving = true
    this.setSaveProgress(0, true)
    const svc = new SurveyTemplatesService(this.rootStore)
    await svc.saveSurveyAsTemplate(
      this.objectId,
      this.rootStore.appStore.currentUserId,
      this.rootStore.appStore.currentOrgId
    )
    this.setSaveProgress(100, false)
    this.openSnackbar('Saved as Survey Template')
    this.isSaving = false
    setTimeout(
      () => this.rootStore.appStore.router.push('/tenantAdmin/manage/surveyTemplates'),
      2000
    )
  }

  @action
  public async refreshInvitations() {
    this.isSaving = true
    this.setSaveProgress(0, true)
    await this.svc.refreshSurveyParticipants(this.objectId, this.rootStore.appStore.currentOrgId)
    this.setSaveProgress(100, false)
    this.openSnackbar('Invitations Data is being refreshed')
    this.isSaving = false
    setTimeout(() => this.rootStore.surveysStore.viewModels.loadSurveyEditVM(this.objectId), 1000)
  }

  @action
  public async closeSurvey() {
    this.setSaveProgress(0, true)
    await this.svc.closeSurvey(this.objectId, this.rootStore.appStore.currentOrgId)
    this.toggleCloseSurveyDialog()
    this.setSaveProgress(100, false)
    this.openSnackbar(`${this.labelsStore.getLabelByLanguageAndFor('pulse')} ${this.lz.closed}`)
    setTimeout(() => this.rootStore.surveysStore.viewModels.loadSurveyEditVM(this.objectId), 1000)
  }

  @computed
  public get virtualListHeight(): number {
    if (!this.virtualListParent) return 750
    return this.virtualListParent.clientHeight
  }

  @computed
  public get closedMessage(): string {
    const user = this.rootStore.audienceMembersStore.getUser(this.closedByUserId)
    if (this.closedDate) {
      const s = this.rootStore.localizationStore.lzStrings.surveys
      const date = moment(this.closedDate).format(s.closed_date_format)
      return `Closed ${date} by ${user ? user.name : 'Admin'}`
    }
    return `Closed by ${user ? user.name : 'Admin'}`
  }

  @computed
  public get saveButtonText(): string {
    if (this.isTemplate) return 'Save Template'
    return 'Save Draft'
  }

  @computed
  public get systemAdminUsersOrdered() {
    if (!this.rootStore.systemAdminsStore.systemAdmins.length) return []
    return this.rootStore.systemAdminsStore.systemAdmins.sort((a, b) => {
      var nameA = a.name ? a.name.toLowerCase() : ''
      var nameB = b.name ? b.name.toLowerCase() : ''
      if (nameA < nameB) return -1
      if (nameA > nameB) return 1
    })
  }

  @action public async searchAudienceUsers(val?: string) {
    const req: IOrganizationUserAudienceSearchRequest = {
      userId: this.rootStore.appStore.currentUserId,
      orgId: this.rootStore.appStore.currentOrgId,
      listColumns: ['fk_User'],
      terms: val ? val : '',
      skip: 0,
      limit: 50,
      isArchived: false,
    }
    const svc = new OrganizationUsersService(this.rootStore)
    const result = await svc.searchAudienceUsers(req)
    if (result.success) {
      const users = []
      result.users.map((user) => {
        users.push(new Owner(user))
      })
      this.usersOrdered = users
      this.loadingOwners = false
    }
  }

  @action public async getAllOwners(val?: string) {
    this.loadingOwners = true
    if (val) {
      this.usersOrdered = []
      if (this.quickFilterTO) clearTimeout(this.quickFilterTO)
      this.quickFilterTO = setTimeout(() => this.searchAudienceUsers(val), 1000)
    } else {
      this.searchAudienceUsers()
    }
  }

  @computed
  public get showCloseSurveyButton(): boolean {
    if (this.isNewSurvey) return false
    if (!this.isPublished) return false
    return !this.isClosed
  }

  @computed
  public get showCloseSurveyMessage(): boolean {
    if (this.isNewSurvey) return false
    return this.isClosed
  }

  @computed
  public get templateIsEditable(): boolean {
    if (!this.objectId) return false
    if (this.rootStore.appStore.isOrgAdmin) return true
    if (!this.survey.templateOnlyEditableByOwner) return true
    return this.rootStore.appStore.currentUserId === this.survey.publishedByUserId
  }

  @computed
  public get isTemplateAndDisabled(): boolean {
    if (this.isTemplate && this.objectId && !this.templateIsEditable) return true
    return false
  }

  @computed
  public get isReadOnly(): boolean {
    if (this.isTemplateAndDisabled) return true
    if (this.isClosed) return true
    return false
  }

  @action
  public toggleCloseSurveyDialog() {
    this.closeSurveyDialogOpen = !this.closeSurveyDialogOpen
  }

  @computed
  public get closeSurveyMessage(): string {
    if (!this.surveyParticipantsList) return ''
    if (this.surveyParticipantsList.pendingInvitations === 0) return 'Are you sure?'
    if (this.surveyParticipantsList.pendingInvitations === 1)
      return `Are you sure? ${this.surveyParticipantsList.pendingInvitations} pending invitation will be deleted.`
    return `Are you sure? ${this.surveyParticipantsList.pendingInvitations} pending invitations will be deleted.`
  }

  @computed
  public get event(): Event {
    if (!this.eventId) return null
    return this.rootStore.eventsStore.getEvent(this.eventId)
  }

  @action
  public setPublishedByUser(val) {
    if (typeof val !== 'string') throw 'invalid published by user ID'

    const canSurveyClients =
      this.rootStore && this.rootStore.appStore && this.rootStore.appStore.canSurveyClients

    this.participantsSelectVM = new ParticipantsSelectVM(
      this.rootStore,
      canSurveyClients ? canSurveyClients : false,
      true,
      true,
      true,
      true,
      true,
      this.forEvent ? true : false,
      val
    )

    this.survey.setPublishedByUserId(val)
    this.setFeedbackParticipantsSelectVM()
  }

  @action
  public setFkPublishedByUser(val) {
    this.survey.setFKPublishedByUserId(val)
  }

  @computed
  public get fkPublishedByUser() {
    if (!this.survey.fk_publishedByUserId) return { name: '', objectId: '' }
    return this.survey.fk_publishedByUserId
  }

  @action
  public toggleIsTemplate() {
    this.survey.toggleIsTemplate()
  }

  @computed
  public get canModifyVisibilitySelect() {
    return this.rootStore.appStore.canModifySurveyVisibility
  }

  @computed
  public get canModifySurveyOwner() {
    return this.rootStore.appStore.canModifySurveyOwner
  }

  @computed
  public get publishedByUser() {
    let foundUser = this.rootStore.audienceMembersStore.getUser(this.publishedByUserId)
    if (!foundUser) return { name: '' }
    return foundUser
  }

  @computed
  public get anonymizedResultsDisabled() {
    if (!this.cleanSurvey) return false
    if (this.isPublished && this.cleanSurvey.anonymizeResults) return true
    return false
  }

  @computed
  public get isTemplateDisabled() {
    if (this.isPublished && this.isTemplate) return true
    return false
  }

  @computed
  public get surveyType(): SurveyType {
    return this.rootStore.surveyTypesStore.getSurveyType(this.surveyTypeId)
  }

  @computed
  public get surveyTag(): SurveyTag {
    return this.rootStore.surveyTagsStore.getSurveyTag(this.surveyTagId)
  }

  @computed
  public get isFeedbackType(): boolean {
    if (!this.surveyType) return false
    return this.surveyType.isFeedbackType
  }

  @computed
  public get surveyTypeForPrompt() {
    if (!this.surveyType.forPrompt) return 'Feedback For'
    return this.surveyType.forPrompt
  }

  @computed
  public get feedbackRequired() {
    if (!this.surveyType) return false
    return this.surveyType.isFeedbackType
  }

  public addQuestion(question) {
    this.survey.addQuestion(question)
    this.setCurrentQuestion(this.questions.length - 1)
    this.setQuestionRanks()
    setTimeout(() => this.scrollToBottom(), 300)
  }

  public addQuestionByType(type: QuestionType) {
    this.survey.addQuestionByType(type)
    this.setCurrentQuestion(this.questions.length - 1)
    this.setQuestionRanks()
    setTimeout(() => this.scrollToBottom(), 300)
  }

  public updateCurrentQuestionByType(type: QuestionType) {
    this.currentQuestion.changeQuestionType(type)
    this.setQuestionRanks()
  }

  @action
  public createSurveyFromTemplate() {
    if (!this.isTemplate) return
    this.rootStore.appStore.router.push(`/surveys/fromtemplate/${this.objectId}`)
    this.rootStore.surveysStore.viewModels.createSurveyFromTemplate(this.objectId)
  }

  @action
  public createSurveyFromSystemTemplate() {
    this.rootStore.appStore.router.push(`/surveys/fromsystemtemplate/${this.objectId}`)
    this.rootStore.surveysStore.viewModels.createSurveyFromSystemTemplate(this.objectId)
  }

  @action
  public setEvent(eventId: string) {
    this.survey.setEventId(eventId)
    this.participantsSelectVM.setEvent(eventId)
  }

  @action
  public toggleLinksNeverExpire() {
    this.survey.toggleLinksNeverExpire()
    if (!this.survey.linksNeverExpire) this.survey.alwaysAvailable = false
  }

  @action
  public async copySurvey() {
    if (this.isSurveyTemplateEdit) return this.copySystemSurveyTemplate()
    const svc = new ParseService()
    const result: ISurveyDTO = await svc.copySurvey(
      this.rootStore.appStore.currentOrgId,
      this.objectId,
      this.rootStore.appStore.currentUserId
    )
    if (result) {
      this.rootStore.appStore.router.push('/surveys/editv2/' + result.objectId)
      this.rootStore.surveysStore.viewModels.loadSurveyEditVM(result.objectId)
    }
  }

  @action
  public copyQuestion(index?: number) {
    if (this.isReadOnly) return
    const questionToCopy = index ? this.questions[index].toDTO() : this.currentQuestion.toDTO()
    const question = Question.create(questionToCopy)
    const questionVM = new QuestionVM(this.rootStore, this, question)
    questionVM.clearAllPaths()
    this.addQuestion(question)
    this.setCurrentQuestion(this.questions.length - 1)
    this.setQuestionRanks()
  }

  @computed
  public get worksheet(): Worksheet {
    return this.rootStore.worksheetsStore.getWorksheet(this.worksheetId)
  }

  @computed
  public get worksheets(): Worksheet[] {
    return this.rootStore.worksheetsStore.currentOrgWorksheets
      .filter(
        (worksheet) =>
          worksheet.forSurveys &&
          (!worksheet.surveyTypeId ||
            worksheet.surveyTypeId === this.surveyTypeId ||
            (this.survey && this.worksheetId === worksheet.objectId))
      )
      .sort((a, b) => (a.name < b.name ? -1 : 0))
  }

  @action
  public setWorksheet(id) {
    this.survey.setWorksheetId(id)
    if (!this.analysis) return
    this.analysis.loadTableauAuthToken()
  }

  @action
  public setSurveyType(id) {
    const previousSurveyTypeId = this.surveyTypeId
    const previousSurveyType = this.surveyTypes.filter(
      (type) => type.objectId === previousSurveyTypeId
    )[0]

    this.survey.setSurveyTypeId(id)

    // if survey type is cleared: on new surveys, clear the worksheet if it is associated w/ the cleared survey type
    // do not auto-clear worksheet on old surveys
    if (
      !id &&
      !this.survey &&
      previousSurveyType &&
      previousSurveyType.worksheetId === this.worksheetId
    )
      this.setWorksheet('')

    // if survey type is set: on new surveys, change the worksheet to match the survey type
    // on old surveys, only change if worksheet is not already set
    if (id) {
      if (!this.survey)
        this.setWorksheet(
          this.surveyType && this.surveyType.worksheetId ? this.surveyType.worksheetId : ''
        )

      if (this.survey && !this.worksheetId)
        this.setWorksheet(
          this.surveyType && this.surveyType.worksheetId ? this.surveyType.worksheetId : ''
        )
    }
  }

  @action
  public setSurveyTag(id) {
    this.survey.setSurveyTagId(id)
  }

  @action
  private loadDefaultAnonymizeResults() {
    this.survey.setAnonymizeResults(
      this.rootStore &&
        this.rootStore.organizationsStore &&
        this.rootStore.organizationsStore.currentOrganization &&
        this.rootStore.organizationsStore.currentOrganization.anonymizeSurveys
    )
  }

  @action
  public setEmailTemplatesFromSurveyTemplate(survey) {
    this.emailTemplates.loadData(survey)
  }

  @action
  private loadDefaultForceEmails() {
    this.setForceEmails(
      this.rootStore &&
        this.rootStore.organizationsStore &&
        this.rootStore.organizationsStore.currentOrganization &&
        this.rootStore.organizationsStore.currentOrganization.forceSurveyEmails
    )
  }

  @action
  public loadDefaultEmailFrom() {
    const currentOrg =
      this.rootStore &&
      this.rootStore.organizationsStore &&
      this.rootStore.organizationsStore.currentOrganization
    if (!currentOrg) return
    if (this.emailFromName === '') this.setEmailFromName(currentOrg.name)
    if (this.emailFromAddress === '') this.setEmailFromAddress(currentOrg.adminEmail)
  }

  @action
  public toggleAnonymizeResults() {
    this.survey.toggleAnonymizeResults()
  }

  @action
  public setAnonymizeResults(val: boolean) {
    this.survey.setAnonymizeResults(val)
  }

  @action
  public toggleShowResults() {
    this.survey.toggleShowResults()
  }

  @action
  public setForceEmails(val: boolean) {
    this.survey.setForceEmails(val)
  }

  @action
  public toggleForceEmails() {
    this.survey.toggleForceEmails()
  }

  @action
  public toggleSendEmailOnResponse() {
    this.survey.toggleSendEmailOnResponse()
  }

  @action
  public toggleSendEmailToAdditionalUsersOnResponse() {
    this.survey.toggleSendEmailToAdditionalUsersOnResponse()
  }

  @action
  public setForceTextMessages(val: boolean) {
    this.survey.setForceTextMessages(val)
  }

  @action
  public toggleForceTextMessages() {
    this.survey.toggleForceTextMessages()
  }

  @action
  public toggleScheduleEnabled() {
    this.survey.toggleScheduleEnabled()
  }

  @action
  public toggleCustomPublishGroup() {
    this.surveyOptionsVM.toggleCustomPublishGroup()
  }

  @action
  public setMainCategory(categoryId) {
    this.survey.setMainCategoryId(categoryId)
  }

  @action
  public toggleImportModal() {
    this.questionsImportVM.clear()
    this.questionsImportVM.toggle()
  }

  @action
  public toggleQuestionCatalog() {
    if (!this.questionsCatalogVM)
      this.questionsCatalogVM = new QuestionsCatalogVM(this.rootStore, this)
    this.questionsCatalogVM.toggle()
  }

  @action
  public addQuestionFromCatalog(question: CatalogQuestion) {
    this.addQuestion(question)
  }

  @action
  public setQuestionsFlowFormat(value: FlowFormatType) {
    if (this.isReadOnly) return
    this.survey.setQuestionsFlowFormat(value)
  }

  @action
  public setNewIndex(value) {
    this.newIndex = value
  }

  @action
  public setOldIndex(value) {
    this.oldIndex = value
  }

  @computed
  public get currentQuestion(): QuestionVM {
    return this.questions[this.currentQuestionIndex]
  }

  @action
  public setSurveyPublishModeType(type) {
    this.surveyOptionsVM.setSurveyPublishModeType(type)
  }

  @computed
  public get surveyPublishModeType(): PublishModeType {
    return this.surveyOptionsVM.surveyPublishModeType
  }

  @action
  private parseISOString(s) {
    var b = s.split(/\D+/)
    return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]))
  }

  @action
  public loadPreviewVM() {
    if (this.questions.length === 0) return
    const userSurvey = this.survey.clone() as UserSurvey
    userSurvey.objectId = HashId.generate()
    userSurvey.name = this.name
    userSurvey.questionsFlowFormat = this.questionsFlowFormat
    userSurvey.surveyIntroduction = this.surveyIntroduction
    userSurvey.surveyCompletedContent = this.surveyCompletedContent
    this.previewVM = new UserSurveyTakeVM(this.rootStore, userSurvey, 'previewer', true)
  }

  @computed
  public get timeZones(): Array<TimeZoneType> {
    const timeZones = moment.tz.names()
    const offsetTmz = []

    for (const i in timeZones) {
      const tz = moment.tz(timeZones[i]).format('Z').replace(':00', '').replace(':30', '.5')
      let x = tz === '0' ? 0 : parseInt(tz).toFixed(2)

      const timeZone: TimeZoneType = {
        label: `(GMT${moment.tz(timeZones[i]).format('Z')}) ${timeZones[i]}`,
        value: `${timeZones[i]}`,
        time: `${x}`,
      }
      offsetTmz.push(timeZone)
    }

    return _.sortBy(offsetTmz, [
      function (el) {
        return -el.time
      },
    ])
  }

  @action
  public setName(val) {
    this.survey.name = val
  }

  @computed
  public get surveyTypes(): SurveyType[] {
    if (this.isSurveyTemplateEdit && this.survey.organizationId === 'DEFAULT') {
      return this.rootStore.surveyTypesStore.defaultSurveyTypes
    }
    return this.rootStore.surveyTypesStore.surveyTypes.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    )
  }

  @computed
  public get surveyTags(): SurveyTag[] {
    return this.rootStore.surveyTagsStore.surveyTags.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    )
  }

  @computed
  public get allowSurveyTags(): Boolean {
    if (this.isSurveyTemplateEdit && this.survey.organizationId === 'DEFAULT') return false
    if (!this.rootStore.organizationsStore.currentOrganization) return false
    return this.rootStore.organizationsStore.currentOrganization.allowSurveyTags
  }

  @action
  public toggleSendReminders() {
    this.survey.toggleSendReminders()
  }

  @action
  public toggleSendRemindersOnDueDate() {
    this.survey.toggleSendRemindersOnDueDate()
  }

  @action
  public toggleSendReminders15BeforeDueDate() {
    this.survey.toggleSendReminders15BeforeDueDate()
  }

  @action
  public toggleSendRemindersCustomPeriod() {
    this.survey.toggleSendRemindersCustomPeriod()
  }

  @action
  public toggleSendRemindersSpecificDate() {
    this.survey.toggleSendRemindersSpecificDate()
  }

  @action
  public setStartDate(val: any) {
    this.startDateDate = val
    try {
      const timeZonedDate = DateUtils.getISOStringFromBrowserInput(
        this.startDateTime.toISOString(),
        val.toISOString(),
        this.survey.schedule.deliveryTimeZone
      )
      this.survey.setStartDate(new Date(timeZonedDate))
      this.startDateDateValid = true
    } catch (e) {
      this.startDateDateValid = false
    }
  }

  @action
  public setEndDate(val: any) {
    this.survey.setEndDate(val)
  }

  @computed
  public get minEndDate(): Date {
    return this.startDate
  }

  @computed
  public get isStartDateValid(): boolean {
    if (!this.publishTried) return true
    if (!this.startDate) return false
    return true
  }

  @action
  public setStartDateTime(hour: number, minute: number) {
    const newTime = moment(this.startDateTime).hour(hour).minute(minute).second(0)
    this.startDateTime = newTime.toDate()
    if (!this.startDateDateValid) return
    const timeZonedDate = DateUtils.getISOStringFromBrowserInput(
      newTime.toISOString(),
      this.startDateDate.toISOString(),
      this.survey.schedule.deliveryTimeZone
    )
    this.survey.setStartDate(new Date(timeZonedDate))
  }

  @action
  public setDeliveryTimeZone(timeZone: any) {
    if (!this.survey || !timeZone) return
    this.survey.setDeliveryTimeZone(timeZone.value)
    const timeZonedDate = DateUtils.getISOStringFromBrowserInput(
      this.startDateTime.toISOString(),
      this.startDateDate.toISOString(),
      this.survey.schedule.deliveryTimeZone
    )
    this.survey.setStartDate(new Date(timeZonedDate))
  }

  @action
  public setDueDate(val: any) {
    this.dueDateDate = val
    try {
      const timeZonedDate = DateUtils.getISOStringFromBrowserInput(
        this.dueDateTime.toISOString(),
        val.toISOString(),
        this.survey.dueAfter.dueTimeZone
      )
      this.survey.setDueDate(new Date(timeZonedDate))
      this.dueDateValid = true
    } catch (e) {
      this.dueDateValid = false
    }
  }

  @action
  public setDueTime(hour: number, minute: number) {
    const newDate = moment(this.survey.dueAfter.dueDate).hour(hour).minute(minute)
    this.dueDateTime = newDate.toDate()
    if (!this.dueDateValid) return
    const timeZonedDate = DateUtils.getISOStringFromBrowserInput(
      newDate.toISOString(),
      this.dueDateDate.toISOString(),
      this.survey.dueAfter.dueTimeZone
    )
    this.survey.setDueDate(new Date(timeZonedDate))
  }

  @action
  public setDueTimeZone(timeZone: any) {
    if (!timeZone) return
    const timeZonedDate = DateUtils.getISOStringFromBrowserInput(
      this.dueDateTime.toISOString(),
      this.dueDateDate.toISOString(),
      timeZone.value
    )
    this.survey.setDueDate(new Date(timeZonedDate))
    this.survey.setDueTimeZone(timeZone.value)
  }

  @action
  public setDueAfterValue(val: string) {
    this.survey.setDueAfterValue(Number(val))
  }

  @action
  public setDueAfterPeriod(val: string) {
    this.survey.setDueAfterPeriod(val as PeriodType)
  }

  @action
  public addReminder(obj) {
    this.survey.addAlarm(obj)
  }

  @action
  deleteReminder(mode) {
    this.survey.deleteAlarm(mode)
  }

  @action
  private prepopulateDeliveryTime() {
    let nextSlot = moment().startOf('minute')
    while (
      nextSlot.minute() !== 0 &&
      nextSlot.minute() !== 15 &&
      nextSlot.minute() !== 30 &&
      nextSlot.minute() !== 45
    ) {
      nextSlot.add(1, 'minute')
    }
    nextSlot.add(1, 'hour')
    this.survey.setDeliveryTime(nextSlot.toDate())
  }

  @computed
  public get mediaPanelShown(): boolean {
    if (this.currentQuestion.mediaUploadPickerVM.showMediaUploadPicker) return true
    return false
  }

  @action
  public showMediaPanel() {
    this.currentQuestion.mediaUploadPickerVM.toggleShowMediaUploadPicker()
    if (this.currentQuestion.media.objectId)
      this.currentQuestion.mediaUploadPickerVM.selectMediaItem(this.currentQuestion.media.objectId)
  }

  @action
  public markSaveDraftTried() {
    this.saveDraftTried = true
    this.questions.forEach((frm) => (frm.publishTried = true))
  }

  @action
  public markPublishTried() {
    this.publishTried = true
  }

  @action
  public setRepeatMode(val: string) {
    this.survey.setRepeatMode(val as RepeatModeType)
  }

  @action
  public setEndingMode(val: string) {
    this.survey.setEndingMode(val as EndingModeType)
  }

  @computed
  public get selectedWeekdays(): Array<boolean> {
    const indexes = []
    this.weekdays.forEach((e, index) => {
      if (e.isChecked) indexes.push(index)
    })
    return indexes
  }

  @action
  public setCurrentQuestion(idx: number) {
    if (idx !== null) this.sidePanelShown = true
    this.currentQuestionIndex = idx
    this.loadDiagram()
  }

  @computed
  public get isAllValid() {
    if (!this.saveDraftTried) return true
    if (!this.isNameValid) return false
    if (!this.isParticipantsValid) return false
    if (!this.areQuestionsValid) return false
    if (!this.feedbackParticipantsValid) return false
    if (!this.isTemplatesValid) return false
    return true
  }

  @computed
  public get isPublishedByUserIdValid() {
    if (!this.saveDraftTried) return true
    if (!this.publishedByUserId || this.publishedByUserId.trim() == '') {
      return false
    }
    return true
  }

  @computed
  public get isNameValid() {
    if (!this.saveDraftTried) return true
    if (!this.name || this.name.trim() === '') return false
    return true
  }

  @computed
  public get isParticipantsValid() {
    if (!this.publishTried && !this.doPublish) return true
    if (
      this.participantsSelectVM.participants.length === 0 &&
      this.participantsSelectVM.anonymousParticipants.length === 0
    ) {
      return false
    }
    return true
  }

  @computed
  public get isReminderTemplatesLinkValid(): boolean {
    const pattern = /<a[\s\S]*?href=[\s\S]*?Link[\s\S]*?>[\s\S]*?<\/a>/
    const templates = this.surveyOptionsVM.notificationTemplatesEditorVM.reminderTemplates
    return templates
      .filter((template) => template.channel === 'EMAIL')
      .every((temp) => pattern.test(temp.body))
  }

  @computed
  public get isPublishedTemplatesLinkValid(): boolean {
    const pattern = /<a[\s\S]*?href=[\s\S]*?Link[\s\S]*?>[\s\S]*?<\/a>/
    const templates = this.surveyOptionsVM.notificationTemplatesEditorVM.publishedTemplates
    return templates
      .filter((template) => template.channel === 'EMAIL')
      .every((temp) => pattern.test(temp.body))
  }

  @computed
  public get isReminderTemplatesValid() {
    if (
      this.surveyOptionsVM.notificationTemplatesEditorVM.templateDataLoaded &&
      this.surveyOptionsVM.notificationTemplatesEditorVM.reminderTemplates.length === 0
    ) {
      return false
    }
    if (
      this.surveyOptionsVM.notificationTemplatesEditorVM.templateDataLoaded &&
      !this.isReminderTemplatesLinkValid
    )
      return false
    return true
  }

  @computed
  public get isNotificationTemplatesValid() {
    if (
      this.surveyOptionsVM.notificationTemplatesEditorVM.templateDataLoaded &&
      this.surveyOptionsVM.notificationTemplatesEditorVM.publishedTemplates.length === 0
    ) {
      return false
    }
    if (
      this.surveyOptionsVM.notificationTemplatesEditorVM.templateDataLoaded &&
      !this.isPublishedTemplatesLinkValid
    )
      return false
    return true
  }

  @computed
  public get isTemplatesValid() {
    return this.isNotificationTemplatesValid && this.isReminderTemplatesValid
  }

  @computed
  public get feedbackParticipantsValid(): boolean {
    if (!this.isFeedbackType) return true
    if (!this.publishTried) return true
    if (this.feedbackParticipantsSelectVM.participants.length === 0) return false
    return true
  }

  @computed
  public get areQuestionsValid() {
    if (!this.saveDraftTried) return true
    let notValidQuestions = this.questions.filter((e) => !e.allValid)
    if (notValidQuestions.length) return false
    return true
  }

  @computed
  public get shouldShowSidePanel() {
    if (this.currentTabIndex !== SurveyEditTabsEnum.BUILDER) return false
    return this.sidePanelShown
  }

  @action
  public setEmailFromName(val) {
    this.survey.setEmailFromName(val)
  }

  @action
  public setEmailFromAddress(val) {
    this.survey.setEmailFromAddress(val)
  }

  @computed
  public get emailValid() {
    let pattern = new RegExp(/[^\s@]+@[^\s@]+\.[^\s@]+/i)
    return pattern.test(this.emailFromAddress)
  }

  @computed
  public get nameValid() {
    if (this.emailFromName) return true
    return false
  }

  @action
  public async openSurvey() {
    this.setSaveProgress(0, true)
    const openedSurvey = await this.svc.openSurvey(
      this.objectId,
      this.rootStore.appStore.currentOrgId
    )
    this.setSaveProgress(100, false)
    this.openSnackbar(`${this.labelsStore.getLabelByLanguageAndFor('pulse')} ${this.lz.opened}`)
    setTimeout(
      () => this.rootStore.surveysStore.viewModels.loadSurveyEditVM(openedSurvey.objectId),
      1000
    )
  }

  @action
  public setCurrentTab(idx) {
    if (this.isNewSurvey) this.steppersVM.updateSteppers(idx, this.currentTabIndex)
    this.currentTabIndex = idx
    if (this.currentTabIndex === SurveyEditTabsEnum.BUILDER) {
      setTimeout(() => {
        this.loadDiagramHeight()
        this.resetDiagram()
      }, 1000)
    }
    if (this.currentTabIndex === SurveyEditTabsEnum.PARTICIPANTS) {
      this.participantsSelectVM.setVisible()
    } else {
      this.participantsSelectVM.setHidden()
    }
    if (this.currentTabIndex === SurveyEditTabsEnum.FEEDBACK) {
      this.feedbackParticipantsSelectVM.setVisible()
    } else {
      this.feedbackParticipantsSelectVM.setHidden()
    }
    if (this.currentTabIndex === SurveyEditTabsEnum.FEEDBACK && this.analysis)
      this.analysis.clearSelectedResponse()
    if (this.currentTabIndex === SurveyEditTabsEnum.PREVIEW) this.loadPreviewVM()
    if (this.currentTabIndex === SurveyEditTabsEnum.RESPONSES) this.loadResponsesList()
    if (this.currentTabIndex === SurveyEditTabsEnum.INVITATIONS) {
      if (this.surveyParticipantsList.loadingFirstPage) {
        this.loadInvitationsList()
        return
      }
      this.surveyParticipantsList.bringInView()
    }
  }

  @action
  public loadResponsesList() {
    this.analysis.loadResponsesList()
  }

  @action
  public openResponseFromNotificationsWidget(id: string) {
    setTimeout(() => this.analysis.setSelectedResponse(id), 1000)
    this.surveyResponseSvc.markSurveyResponseAsRead(id, this.rootStore.appStore.currentOrgId)
    this.setCurrentTab(7)
  }

  @action
  public goToNextTab() {
    if (this.currentTabIndex === SurveyEditTabsEnum.PARTICIPANTS && !this.feedbackRequired) {
      this.setCurrentTab(SurveyEditTabsEnum.FEEDBACK)
    }
    if (this.currentTabIndex === SurveyEditTabsEnum.PARTICIPANTS && this.feedbackRequired) {
      this.setCurrentTab(SurveyEditTabsEnum.FEEDBACK - 1)
    }
    if (this.currentTabIndex === SurveyEditTabsEnum.PREVIEW) {
      this.steppersVM.previewCompleted = true
      this.doPublish = true
      this.saveValidation()
    }
    if (this.currentTabIndex < SurveyEditTabsEnum.PREVIEW)
      this.setCurrentTab(this.currentTabIndex + 1)
    if (this.currentTabIndex === SurveyEditTabsEnum.PUBLISH) {
      this.rootStore.surveysStore.viewModels.loadSurveyEditVM(this.objectId)
    }
  }

  @computed
  public get showCopySurveyButton() {
    return !this.isNewSurvey
  }

  @computed
  public get shouldShowTopMenu() {
    if (this.addTestDataButtonShown) return true
    if (this.showCopySurveyButton) return true
    return !this.isNewSurvey
  }

  @computed
  public get canSaveAsTemplate(): boolean {
    if (this.rootStore.appStore.isOrgAdmin) return true
    if (this.rootStore.appStore.canManageSurveyTemplates) return true
    return false
  }

  @computed
  public get isNewSurvey(): boolean {
    // if (!this.survey) return true
    // if (this.survey.objectId) return false
    if (
      this.survey.objectId &&
      !this.survey.isAutoSaveDraft &&
      !this.isPublished &&
      !this.isTemplate
    )
      return false
    if (this.survey.objectId && this.survey.isTemplate) return false
    if (this.survey.published) return false
    return true
  }

  @action
  public toggleConfirmSave() {
    this.confirmSaveDialogOpen = !this.confirmSaveDialogOpen
  }

  @action
  public setAutoSaveId(objectId) {
    this.autoSaveId = objectId
  }

  @action
  public autoSaveValidation() {
    if (this.isPublished) return
    if (this.isTemplate) return
    if (this.autoSaveInt) clearInterval(this.autoSaveInt)
    this.autoSaveInt = setInterval(this.autoSave.bind(this), 60000)
  }

  @action
  public async autoSave() {
    this.markSaveDraftTried()
    if (this.isTemplate) return
    if (this.isSaving) return
    if (!this.isNameValid) return
    const url = window.location.href
    if (!url.includes('/surveys/editv2/')) {
      clearInterval(this.autoSaveInt)
      return
    }
    if (!this.isDirty) return
    this.isSaving = true
    this.isDirty = false
    this.cancelIsDirty = false
    this.setSaveProgress(33)
    const postData = this.toDTO()
    // postData.isAutoSaveDraft = true
    this.isDirty = false
    let result: ISurveyUpdateResult
    try {
      this.isDirty = false
      const svc = new ParseService()
      result = await svc.saveSurvey(this.rootStore.appStore.currentOrgId, postData, false)
      if (result && result.success && result.surveyId) {
        clearInterval(this.autoSaveInt)
        this.setAutoSaveId(result.surveyId)
      } else {
        const msg = result.errorMessage
        this.openSnackbar(msg)
        this.cancelIsDirty = false
        this.isSaving = false
        this.isDirty = true
        this.cancelIsDirty = true
        this.setSaveProgress(0)
        return
      }
    } catch (e) {
      this.isSaving = false
      console.error(e)
      this.openSnackbar(this.lz.unable_to_save)
      return
    }
    if (this.isTemplate) {
      this.setSaveProgress(66)
      this.openSnackbar(
        `${this.labelsStore.getLabelByLanguageAndFor('pulse')} ${this.lz.template_autosaved}`
      )
    } else {
      this.setSaveProgress(66)
      this.openSnackbar(
        `${this.labelsStore.getLabelByLanguageAndFor('pulse')} ${this.lz.has_been_autosaved}`
      )
    }
    this.setSaveProgress(100)
    setTimeout(() => {
      this.setSaveProgress(0)
      this.isSaving = false
      this.isDirty = false
    })
  }

  @action
  public saveValidation() {
    this.markSaveDraftTried()
    if (this.doPublish) {
      this.markPublishTried()
      if (!this.isAllValid) return
    } else {
      if (this.isPublished) {
        if (!this.areQuestionsValid) return
      }
      if (!this.isNameValid) return
      if (!this.isParticipantsValid) return
      if (!this.isPublishedByUserIdValid) return
      if (!this.isTemplatesValid) return
    }
    if (this.isPublished || this.isTemplate || !this.doPublish) this.save()
    else this.toggleConfirmSave()
  }

  @action
  public async saveSystemSurveyTemplate() {
    const surveyTemplateDTO = this.toDTO()
    if (this.saveToFolderId) {
      surveyTemplateDTO.folderId = this.saveToFolderId
    }
    try {
      const svc = new SurveyTemplatesService(this.rootStore)
      const result = await svc.saveSurveyTemplate(surveyTemplateDTO)
      this.setSaveProgress(66)
      this.setSaveProgress(100)
      this.openSnackbar(
        `${this.labelsStore.getLabelByLanguageAndFor('pulse')} ${this.lz.template_saved}`
      )
      setTimeout(() => {
        this.setSaveProgress(0)
        this.isSaving = false
        this.isDirty = false
        this.goBack()
      }, 2000)
    } catch (e) {
      this.isSaving = false
      console.error(e)
      this.openSnackbar(this.lz.unable_to_save)
      this.setSaveProgress(0)
      Sentry.captureException({
        message: 'Unable to save survey template',
        exception: e,
        userId: this.rootStore.appStore.currentUserId,
        orgId: this.rootStore.appStore.currentOrgId,
      })
      return
    }
  }

  @action
  public async save() {
    this.confirmSaveDialogOpen = false
    this.cancelIsDirty = true
    if (this.isTemplate) {
      this.clearParticipants()
    }
    this.isSaving = true
    this.reactions.forEach((dispose: IReactionDisposer) => dispose())
    this.isDirty = false
    this.cancelIsDirty = false
    this.cleanSurvey = null
    this.setSaveProgress(33)
    if (this.isSurveyTemplateEdit) return this.saveSystemSurveyTemplate()
    const postData = this.toDTO()
    this.isDirty = false
    let result: ISurveyUpdateResult
    try {
      this.isDirty = false
      const svc = new ParseService()
      result = await svc.saveSurvey(this.rootStore.appStore.currentOrgId, postData, this.doPublish)
      if (result && result.success && result.surveyId) {
        this.survey.setObjectId(result.surveyId)
      } else {
        const msg = result.errorMessage
        this.openSnackbar(msg)
        this.cancelIsDirty = false
        this.isSaving = false
        this.isDirty = true
        this.cancelIsDirty = true
        this.setSaveProgress(0)
        return
      }
    } catch (e) {
      this.isSaving = false
      console.error(e)
      this.openSnackbar(this.lz.unable_to_save)
      Sentry.captureException({
        message: 'Unable to save survey',
        exception: e,
        userId: this.rootStore.appStore.currentUserId,
        orgId: this.rootStore.appStore.currentOrgId,
      })
      return
    }
    if (result.createdMultipleSurveys) {
      this.rootStore.appStore.router.push('/dashboard/surveys')
      return
    }
    if (this.isTemplate) {
      this.setSaveProgress(66)
      this.openSnackbar(
        `${this.labelsStore.getLabelByLanguageAndFor('pulse')} ${this.lz.template_saved}`
      )
    }
    if (this.doPublish) {
      this.setSaveProgress(66)
      this.openSnackbar(
        `${this.labelsStore.getLabelByLanguageAndFor('pulse')} ${this.lz.has_been_saved}`
      )
      this.surveyWasJustPublished = true
      this.steppersVM.publishCompleted = true
      this.steppersVM.previewCompleted = true
    }
    if (!this.doPublish && !this.isPublished && !this.isTemplate) {
      this.setSaveProgress(66)
      this.openSnackbar(
        `${this.labelsStore.getLabelByLanguageAndFor('pulse')} ${this.lz.draft_has_been_saved}`
      )
    }
    this.setSaveProgress(100)
    setTimeout(() => {
      this.setSaveProgress(0)
      this.isSaving = false
      this.isDirty = false
      if (this.doPublish) {
        this.setCurrentTab(SurveyEditTabsEnum.PUBLISH)
        this.doPublish = false
        return
      }
      this.rootStore.surveysStore.viewModels.loadSurveyEditVM(result.surveyId)
      this.doPublish = false
    }, 2000)
  }

  @action
  public navigateToAnalysis() {
    this.rootStore.appStore.router.push('/surveys/editv2/' + this.objectId)
    this.rootStore.surveysStore.viewModels.loadSurveyEditVM(this.objectId)
  }

  @action
  public clearParticipants() {
    this.participantsSelectVM.participants = []
  }

  public toDTO(): ISurveyDTO {
    const dto = this.survey.serialize()
    if (this.autoSaveId) dto.objectId = this.autoSaveId
    dto.participants = this.participantsSelectVM.participantsToDTO() as IParticipantDTO[]
    dto.anonymousParticipants = this.participantsSelectVM.anonymousParticipantsToDTO()
    dto.feedbackParticipants =
      this.feedbackParticipantsSelectVM.participantsToDTO() as IParticipantDTO[]
    dto.visibleParticipants =
      this.visibilityEditorVM.selectedOptionValue === 'specific'
        ? this.visibilityEditorVM.selectedParticipants.map((e) => e.toDTO())
        : []
    dto.visibleTo =
      this.visibilityEditorVM.selectedOptionValue === 'specific'
        ? this.visibilityEditorVM.selectedParticipants.map((e) => e.toDTO())
        : []
    dto.emailTemplates = this.surveyOptionsVM.notificationTemplatesEditorVM.templates
      .filter((e) => e.channel === 'EMAIL' && e.notificationTypeId === 'survey-published')
      .map((e) => e.toDTO())
    dto.publishedTemplates =
      this.surveyOptionsVM.notificationTemplatesEditorVM.publishedTemplates.map((e) => e.toDTO())
    dto.reminderTemplates =
      this.surveyOptionsVM.notificationTemplatesEditorVM.reminderTemplates.map((e) => e.toDTO())
    return dto
  }

  public questionsToDTO(): IQuestionDTO[] {
    return this.survey.serialize().questions
  }

  @computed
  public get isPublished(): boolean {
    return this.survey.published
  }

  @computed
  public get limited(): boolean {
    const foundParticipant = this.visibilityEditorVM.selectedParticipants.find(
      (participant) => participant.id === this.rootStore.appStore.currentUserId
    ) as VisibleParticipant
    if (foundParticipant) {
      return foundParticipant.limited
    }
    return false
  }

  @action
  public async refreshSurveyResponses() {
    this.analysis.refreshTableauData()
    const svc = new SurveysService(this.rootStore)
    await svc.refreshSurveyResponses(this.rootStore.appStore.currentOrgId, this.objectId)
    this.surveyParticipantsList.refresh()
  }

  @action
  public moveOption(index, newIndex) {
    this.currentQuestion.moveOption(index, newIndex)
    this.currentQuestion.setOptionRanks()
    this.resetDiagram()
  }

  @action
  public moveQuestion(moveToIndex?: number) {
    const newIndex = typeof moveToIndex === 'number' ? moveToIndex : this.newIndex
    this.spliceQuestion(newIndex)
    this.setCurrentQuestion(newIndex)
    this.removeUpwardBranches() // prevent loops
  }

  @action
  public moveQuestionUp() {
    this.moveQuestion(this.currentQuestionIndex - 1)
  }

  @action
  public moveQuestionDown() {
    this.moveQuestion(this.currentQuestionIndex + 1)
  }

  private removeUpwardBranches() {
    this.questions.forEach((question, index) => {
      if (!question.options || !question.options.forEach) return
      question.options.forEach((option) => {
        if (!option.nextQuestionId) return
        const nextQuestionIndex = this.questions.findIndex((e) => e.id === option.nextQuestionId)
        if (nextQuestionIndex !== -1 && nextQuestionIndex < index) option.setNextQuestion(null)
      })
    })
  }

  @action
  public spliceQuestion(newIdx) {
    this.survey.spliceQuestion(this.currentQuestionIndex, newIdx)
    this.setQuestionRanks()
    this.resetDiagram()
  }

  @action
  public toggleDeleteLastQuestion() {
    this.cantDeleteLastQuestion = !this.cantDeleteLastQuestion
  }

  @action
  public deleteQuestionByCard(index: number) {
    if (this.isReadOnly) return
    this.setCurrentQuestion(index)
    if (process.env.NODE_ENV === 'test') {
      this.deleteQuestion()
      return
    }
    setTimeout(() => this.deleteQuestion(), 100)
  }

  @action
  public deleteQuestion() {
    if (this.questions.length === 1) return this.toggleDeleteLastQuestion()
    this.survey.deleteQuestion(this.currentQuestionIndex)
    if (this.currentQuestionIndex === 0) {
      this.setCurrentQuestion(0)
    } else if (this.currentQuestionIndex > 0) {
      this.setCurrentQuestion(this.currentQuestionIndex - 1)
    } else {
      this.setCurrentQuestion(null)
    }
    this.setQuestionRanks()
    this.resetDiagram()
  }

  @action
  public toggleDeleteDialog = () => {
    this.showDeleteDialog = !this.showDeleteDialog
  }

  @action
  public async deleteSystemSurveyTemplate() {
    this.toggleDeleteDialog()
    const svc = new SurveyTemplatesService(this.rootStore)
    await svc.deleteSurveyTemplate(this.objectId)
    this.rootStore.surveysStore.viewModels.clearEditVM()
    this.goBack()
  }

  @action
  public async copySystemSurveyTemplate() {
    this.rootStore.appStore.router.push('/dashboard/surveys/')
    const svc = new SurveyTemplatesService(this.rootStore)
    await svc.copySurveyTemplate(this.objectId)
    this.rootStore.surveysStore.viewModels.clearEditVM()
    this.goBack()
  }

  @action
  public async delete() {
    if (this.isSurveyTemplateEdit) return this.deleteSystemSurveyTemplate()
    this.toggleDeleteDialog()
    this.rootStore.surveysStore.deleteRecord(this.objectId)
    this.rootStore.appStore.router.push('/dashboard/surveys/')
    this.rootStore.surveysStore.viewModels.clearEditVM()
    const svc = new ParseService()
    await svc.deleteSurvey(this.rootStore.appStore.currentOrgId, this.objectId)
  }

  private setQuestionRanks() {
    this.questions.forEach((q, idx) => {
      q.setRank(idx + 1)
      q.setOptionRanks()
    })
  }

  public clearDiagram() {
    this.paths = []
    this.pathsLastUpdated += 1
  }

  public resetDiagram() {
    this.clearDiagram()
    this.loadDiagram()
  }

  @computed
  public get diagramWidth(): number {
    return 800
  }

  @computed
  public get diagramHeight(): number {
    // start badge + end badge
    let height = 120

    // extra height to make sure "go to end" line isn't hidden
    height += 480

    // 15px lines between elements, one for each question, one from start to q1, and one from add menu to end
    height += this.questions.length * 15 + 15 + 15

    // for every question, try to get the question box height...
    this.questions.forEach((q, index) => {
      const element = document.getElementById(`question-${index}`)
      if (element) {
        height += element.offsetHeight
      } else {
        // if you can't get the box height, add 30 for the header, 35 for the title container, and 30 for each option
        height += 30 + 35 + 30 * q.options.length
      }
    })
    return height
  }

  @computed
  public get diagramEnabled(): boolean {
    if (this.rootStore.appStore.currentOrgId === 'ddNIgjihAS') return false
    return true
  }

  public loadDiagram() {
    if (this.drawAttempts === 10) {
      console.log('Too many attempts: ' + this.drawAttempts)
      return
    }

    const onSurveyEditScreen =
      window.location.href.toString().toLowerCase().indexOf('surveys/editv2') === -1

    const onForUserTaskSurveyScreen =
      window.location.href.toString().toLowerCase().indexOf('surveys/forusertask') === -1

    if (!onSurveyEditScreen && !onForUserTaskSurveyScreen) return

    if (this.loadDiagramTimeout) clearTimeout(this.loadDiagramTimeout)
    this.loadDiagramTimeout = setTimeout(() => {
      if (!this.getAllRects()) {
        this.drawAttempts += 1
        setTimeout(() => this.loadDiagram(), 200)
        return
      }

      this.drawAttempts = 0

      const offsetX = this.containerRect.left
      let offsetY = 180 - this.container.scrollTop - window.scrollY
      if (this.addButtonToFooter) {
        offsetY = offsetY - 3
      }
      if (this.DEBUG_DIAGRAM) {
        console.log('scrollTop: ' + this.container.scrollTop)
        console.log('offsetTop: ' + this.container.offsetTop)
        console.log('top: ' + this.containerRect.top)
        console.log('Q0 top: ' + this.questions[0].rect.top)
        console.log('FINAL offsetY: ' + offsetY)
      }

      const paths = []
      let fromRect = null
      let toRect = null
      this.questions.forEach((q, idx) => {
        if (idx === 0) {
          fromRect = this.startBadgeRect
          toRect = this.questions[0].rect
        } else {
          fromRect = toRect
          toRect = q.rect
        }
        paths.push(new Path(fromRect, toRect, paths, offsetX, offsetY))

        q.options.forEach((opt) => {
          if (opt.nextQuestionId) {
            if (opt.nextQuestion === null) return opt.setNextQuestion(null)
            paths.push(new Path(opt.rect, opt.nextQuestion.rect, paths, offsetX, offsetY, 'option'))
          }
          if (opt.goToEnd) {
            paths.push(new Path(opt.rect, this.endBadgeRect, paths, offsetX, offsetY, 'end'))
          }
        })
      })

      // fromRect = toRect
      // toRect = this.addQuestionButtonRect
      // paths.push(new Path(fromRect, toRect, paths, offsetX, offsetY))

      fromRect = toRect
      toRect = this.endBadgeRect
      paths.push(new Path(fromRect, toRect, paths, offsetX, offsetY))

      if (this.DEBUG_DIAGRAM) {
        console.log(this.startBadgeRect)
        this.questions.forEach((q) => {
          console.log(q.rect)
        })
        console.log(this.addQuestionButtonRect)
        paths.forEach((p) => {
          console.log(p.toPointsArray())
        })
      }
      if (!isEqual(paths, this.paths)) {
        this.paths = paths
        this.pathsLastUpdated += 1
      }
    }, 200)
  }

  public getAllRects(): boolean {
    let e: HTMLElement
    e = document.getElementById('Builder')
    if (!e) {
      if (this.DEBUG_DIAGRAM) console.log('missing container rect')
      return false
    }
    this.container = e
    this.containerRect = e.getBoundingClientRect()

    e = document.getElementById('start-badge')
    if (!e) {
      if (this.DEBUG_DIAGRAM) console.log('missing start badge rect')
      return false
    }
    this.startBadgeRect = e.getBoundingClientRect()

    e = document.getElementById('end-badge')
    if (!e) {
      if (this.DEBUG_DIAGRAM) console.log('missing end badge rect')
      return false
    }
    this.endBadgeRect = e.getBoundingClientRect()

    let hasAllQuestionRects = true
    this.questions.forEach((q, questionIndex) => {
      e = document.getElementById('question-' + questionIndex)
      if (!e && this.DEBUG_DIAGRAM) console.log('missing question rect')
      if (!e) hasAllQuestionRects = false
      if (e) q.setRect(e.getBoundingClientRect())

      q.options.forEach((opt, idx) => {
        e = document.getElementById('question-' + questionIndex + '-option-' + idx)
        if (!e && this.DEBUG_DIAGRAM) console.log('missing opt rect')
        if (!e) hasAllQuestionRects = false
        if (e) opt.setRect(e.getBoundingClientRect())
      })
    })

    return hasAllQuestionRects
  }

  public setAddQuestionButtonRect(rect: DOMRect) {
    if (this.addQuestionButtonRect) return
    this.addQuestionButtonRect = rect
  }

  @action
  public openSnackbar(msg) {
    this.snackbarMessage = msg
    this.isSnackbarOpen = true
  }

  @action
  public closeSnackbar() {
    this.isSnackbarOpen = false
  }

  @action
  public setSaveProgress(val: number, showIndicator: boolean = false) {
    this.saveProgress = val
    if (showIndicator) this.isSaving = true
  }

  @computed
  public get publishButtonText(): string {
    return this.isPublished ? 'Update' : 'Publish'
  }

  @computed
  public get showSaveDraftButton(): boolean {
    return this.survey ? false : true
  }

  @computed
  public get addTestDataButtonShown(): boolean {
    if (env.var.REACT_APP_ENVIRONMENT === 'PRODUCTION') return false
    if (!this.isNewSurvey) return false
    return true
  }

  @computed
  public get analyzeResponsesAvailable(): boolean {
    if (env.var.REACT_APP_ENVIRONMENT === 'LOCAL') return true
    if (env.var.REACT_APP_ENVIRONMENT === 'DEV') return true
    if (env.var.REACT_APP_ENVIRONMENT === 'QA') return true
    if (env.var.REACT_APP_ENVIRONMENT === 'DEMO') return true
    if (env.var.REACT_APP_ENVIRONMENT === 'STAGING') return true
    return false
  }

  @action
  public setSurveyIntroduction(text) {
    this.survey.setSurveyintroduction(text)
  }

  @action
  public setSurveyCompletedContent(text) {
    this.survey.setSurveyCompletedContent(text)
  }

  @action
  public deepEqual(object1, object2) {
    if (this.isDirty) return
    if (this.isSaving) return
    const keys1 = Object.keys(object1)
    const keys2 = Object.keys(object2)
    if (keys1.length !== keys2.length) this.isDirty = true
    for (const key of keys1) {
      if (this.isDirty) return
      const val1 = object1[key]
      const val2 = object2[key]
      if (val1 !== val2) this.isDirty = true
      const areObjects = this.isObject(val1) && this.isObject(val2)
      if (areObjects && !this.isDirty) this.deepEqual(val1, val2)
    }
  }

  @action
  public isObject(object) {
    return object != null && typeof object === 'object'
  }

  @computed
  public get infoString() {
    if (!this.isParticipantsValid) return ''
    let participants = this.participantsSelectVM.participants.length
    const string =
      this.participantsSelectVM.anonymousParticipants.length +
      participants +
      this.visibilityEditorVM.selectedParticipants.length +
      this.additionalResponseNotificationEditorVM.selectedParticipants.length
    return string
  }

  @computed
  public get addButtonToFooter(): boolean {
    if (this.surveyFlowCanvasHeight > this.builderHeight) return true
    return false
  }

  @action
  public loadDiagramHeight() {
    const surveyFlowCanvasEl: HTMLElement = document.getElementById('FlowDiagram')
    const builderEl: HTMLElement = document.getElementById('Builder')
    if (!surveyFlowCanvasEl) return
    if (!builderEl) return
    this.surveyFlowCanvasHeight = surveyFlowCanvasEl.clientHeight + 500
    this.builderHeight = builderEl.clientHeight + 500
  }

  @action
  public scrollToBottom() {
    const title = document.getElementById('FlowDiagram')
    if (title) {
      title.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }

  @action
  public setSurveyDetailsTabIndex(num: number) {
    this.surveyDetailsTabIndex = num
  }

  @action
  public goBack() {
    if (this.isSurveyTemplateEdit) {
      if (this.survey.organizationId === 'DEFAULT') {
        return this.rootStore.appStore.router.push('/systemAdmin/manage/surveyTemplates')
      }
      return this.rootStore.appStore.router.push('/tenantAdmin/manage/surveyTemplates')
    }
    this.rootStore.appStore.router.push('/dashboard/surveys')
  }

  @computed
  public get anonymousSurveyResultCount() {
    if (!this.rootStore.organizationsStore.currentOrganization) return null
    if (!this.rootStore.organizationsStore.currentOrganization.anonymousSurveyResultCount) return 10
    return this.rootStore.organizationsStore.currentOrganization.anonymousSurveyResultCount
  }
}
