import Parse from 'parse'
import { RootStore } from '../../stores/RootStore'
import { IOrganizationDTO } from '../dtos/IOrganizationDTO'
import { IOrganizationsFindRequest } from '../interfaces/IOrganizationsFindRequest'
import { IOrganizationMobileAppIdFindRequest } from '../interfaces/IOrganizationMobileAppIdFindRequest'

export class OrganizationsService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public async saveOrganization(organization: IOrganizationDTO) {
    const orgId = organization.objectId
    const result = await Parse.Cloud.run('saveOrganization', { orgId, organization })
    return result
  }

  public async generateTokenForOrganization(organization: IOrganizationDTO) {
    const result = await Parse.Cloud.run('generateToken', { organization })
    return result.organization.customCollectionAPIToken
  }

  public async findOrganizations(request: IOrganizationsFindRequest) {
    const result = await Parse.Cloud.run('findOrganizations', { request })
    return result
  }

  public async getOrganizationMobileAppId(request: IOrganizationMobileAppIdFindRequest) {
    const result = await Parse.Cloud.run('getOrganizationMobileAppId', { request })
    return result
  }


}
