import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import {
  AppBar,
  Box,
  Button,
  CssBaseline,
  Link,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core'
import clsx from 'clsx'
import isIE11 from '../../../../utils/isIE11'
import { SurveyTypesManageVM } from '../../view-models/SurveyTypesManageVM'
import '../../../css/dashboard-toolbar.scss'
import './TopToolbar.scss'

interface Props {
  localizationStore?: LocalizationStore
  vm: SurveyTypesManageVM
}

const TopToolbar: React.FC<Props> = ({ localizationStore, vm }) => {
  const renderPageTitle = () => {
    return (
      <Typography className='dashboard-title'>{`Manage Survey Types`}</Typography>
    )
  }

  return (
    <div id='DashboardToolbar' className={'dashboardToolbar-header'}>
      <CssBaseline />
      <AppBar
        className={clsx('dashboard-appbar', {
          'no-transition': isIE11,
        }, vm.screenType === 'systemManage' ? 'strong' : '')}
        elevation={0}
      >
        <Toolbar className='dashboard-toolbar'>{renderPageTitle()}</Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  )
}

export default inject('localizationStore')(observer(TopToolbar))
