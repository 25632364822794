import { action, observable } from 'mobx'
import { RootStore } from '../../../stores/RootStore'

export class CreateSurveyScreenVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }


  @action
  public navigateBack = () => {
    this.rootStore.appStore.router.push('/dashboard/surveys')
  }

  @action
  public handleStartFromScratch = () => {
    this.rootStore.appStore.router.push('/surveys/editv2/new')
  }

  @action
  public handleStartFromTemplate = () => {
    this.rootStore.appStore.router.push('/surveys/systemadminnew')
  }
  
}
