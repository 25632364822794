import { observable, computed, action } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { ITaskDTO } from '../../tasks/dtos/ITaskDTO'
import { Category } from '../../categories/aggregate/Category'
import { TaskComment } from '../../task-comments/aggregates/TaskComment'
import { Survey } from '../../surveys/aggregate/Survey'
import { UserTasksService } from '../service/UserTasksService'
import { TaskCommentsService } from '../../task-comments/service/TaskCommentsService'
import { TaskCommentEditVM } from '../../task-comments/view-models/TaskCommentEditVM'
import { UserTask } from '../aggregates/UserTask'
import { TaskAttachmentVM } from '../../tasks/view-models/TaskAttachmentVM'
import { MediaItemsService } from '../../media-items/service/MediaItemsService'
import { AudienceMember } from '../../audience-members/aggregate/AudienceMember'
import { Attachment } from '../../upload/aggregate/Attachment'
import { ParseService } from '../../services/ParseService'
import { AttachmentVM } from '../../attachments/view-models/AttachmentVM'
import { OldAttachmentVM } from '../../attachments/view-models/OldAttachmentVM'
import { isNumeric } from '../../shared/isNumeric'
import { OldTaskAttachmentVM } from '../../tasks/view-models/OldTaskAttachmentVM'
import { CMSTaskAttachmentVM } from '../../tasks/view-models/CMSTaskAttachmentVM'

export class UserTaskEditVM {
  private rootStore: RootStore
  private userTask: UserTask
  private commentSvc: TaskCommentsService
  private userTaskSvc: UserTasksService

  @observable public commentText: string
  @observable public isDirty: boolean = false
  @observable public markCompletedRequested: boolean = false
  @observable public markCompletedSuccessful: boolean = false
  @observable public markCompletedFailed: boolean = false
  @observable public markIncompleteRequested: boolean = false
  @observable public markIncompleteSuccessful: boolean = false
  @observable public markIncompleteFailed: boolean = false
  @observable public selectedSurveyTemplateId: any
  @observable public generatingSurvey: boolean = false
  @observable public commentAttachment: Attachment = null
  @observable public showCommentUploadModal: boolean = false

  public static loadUserTaskView(rootStore: RootStore, userTask: UserTask): UserTaskEditVM {
    const userTaskSvc = new UserTasksService()
    if (!userTask.isRead) userTaskSvc.markAsRead(userTask.objectId, userTask.organizationId)
    const pendingUserTasksDrawerVM = new UserTaskEditVM()
    pendingUserTasksDrawerVM.rootStore = rootStore
    pendingUserTasksDrawerVM.userTask = userTask
    return pendingUserTasksDrawerVM
  }

  @computed
  public get taskComments(): TaskCommentEditVM[] {
    return this.rootStore.taskCommentsStore.taskComments
      .filter((comment) => comment.taskId === this.userTask.taskId)
      .sort((a, b) => a.datePosted - b.datePosted)
      .map((comment) => new TaskCommentEditVM(this.rootStore, comment))
  }

  @computed
  public get allowFeedback(): boolean {
    if (this.availableSurveyTemplates.length < 1) return false
    return this.rootStore.organizationsStore.currentOrganization.tasksAllowFeedback
  }

  @computed
  public get allowDiscipline(): boolean {
    if (!this.rootStore.organizationsStore.currentOrganization) return false
    return this.rootStore.organizationsStore.currentOrganization.tasksAllowDiscipline
  }

  @computed
  public get allowPartnerChannel(): boolean {
    return this.rootStore.organizationsStore.currentOrganization.tasksAllowPartnerChannel
  }

  @computed
  public get attachments(): AttachmentVM[] {
    return this.task.attachments.map((e, idx) => {
      if (isNumeric(e.objectId) || e.cmsItemId) {
        return this.rootStore.cmsItemAttachmentStore.loadAttachment(e)
      }
      return new OldAttachmentVM(this.rootStore, e, idx)
    })
  }

  @computed
  public get hasFeedbackSurveyTemplate(): boolean {
    return Boolean(this.userTask.feedbackSurveyTemplateId)
  }

  @computed
  public get hasSurvey(): boolean {
    return Boolean(this.userTask.surveyId)
  }

  @computed
  public get organizationId(): string {
    return this.userTask.organizationId
  }

  @computed
  public get task(): ITaskDTO {
    return this.rootStore.tasksStore.tasks.filter(
      (task) => task.objectId === this.userTask.taskId
    )[0]
  }

  @computed
  public get assignedByUser(): AudienceMember {
    return this.rootStore.audienceMembersStore.getUser(this.task.assignedByUserId)
  }

  @computed
  public get objectId(): string {
    return this.userTask.objectId
  }

  @computed
  public get userId(): string {
    return this.userTask.userId
  }

  @computed
  public get taskId(): string {
    return this.userTask.taskId
  }

  @computed
  public get isCompleted(): boolean {
    return this.userTask.isCompleted
  }

  @computed
  public get surveyId(): string {
    return this.userTask.surveyId
  }

  @computed
  public get categoryName(): string {
    if (!this.category) return ''
    return this.category.name
  }

  @computed
  public get taskName(): string {
    if (!this.task) return ''
    return this.task.name
  }

  @computed
  public get creatorName(): string {
    if (!this.assignedByUser) return ''
    return this.assignedByUser.name
  }

  @computed
  public get category(): Category {
    return this.rootStore.categoriesStore.currentOrgCategories.find(
      (category) => category.objectId === this.task.categoryId
    )
  }

  @computed
  public get clientName(): string {
    const foundClient = this.rootStore.clientsStore.clients.find(
      (e) => e.objectId === this.task.channelPartnerId
    )
    if (!foundClient) {
      return this.rootStore.localizationStore.lzStrings.pendingUserTasksWidgetDrawer.none
    }
    return foundClient.name
  }

  @computed
  public get feedbackSectionVisible(): boolean {
    if (!this.allowFeedback) return false
    if (!this.isCompleted) return false
    return true
  }

  @computed
  public get getFeedbackButtonShown(): boolean {
    if (this.task.feedbackSurveyTemplateId && !this.attachedSurvey) return true
    if (this.openSurveyButtonVisible) return false
    if (!this.task.feedbackSurveyTemplateId && !this.attachedSurvey) return false
    return true
  }

  @computed
  public get markAsIncompleteDisabled(): boolean {
    if (!this.saveEnabled) return false
    if (this.attachedSurvey && this.attachedSurvey.published !== true) return false
    return true
  }

  @computed
  public get availableSurveyTemplates(): Array<Survey> {
    if (!this.rootStore.surveysStore) return []
    if (!this.rootStore.surveyTypesStore) return []
    const results = []
    // const results = this.rootStore.surveyTemplatesStore.currentOrgSurveyTemplates.filter((e) => {
    //   const foundType = this.rootStore.surveyTypesStore.getSurveyType(e.surveyTypeId)
    //   if (!foundType) return false
    //   if (!foundType.isFeedbackType) return false
    //   return true
    // })
    return results.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0))
  }

  @computed
  public get selectedSurveyTemplate(): Survey {
    if (!this.selectedSurveyTemplateId) return null
    return this.availableSurveyTemplates.find((e) => e.objectId === this.selectedSurveyTemplateId)
  }

  @computed
  public get attachedSurvey() {
    return null
    // return this.rootStore.surveyTemplatesStore.currentOrgSurveyTemplates.find(
    //   (e) => e.objectId === this.surveyId
    // )
  }

  @computed
  public get openSurveyButtonVisible(): boolean {
    return Boolean(this.attachedSurvey)
  }

  @computed
  public get templateSelectShown(): boolean {
    if (this.task.feedbackSurveyTemplateId) return false
    if (this.attachedSurvey) return false
    if (this.availableSurveyTemplates.length < 1) return false
    return true
  }

  @computed
  public get commentAttachmentVM(): TaskAttachmentVM {
    if (!this.commentAttachment) return null
    if (isNumeric(this.commentAttachment.objectId) || this.commentAttachment.cmsItemId) {
      const vm = new CMSTaskAttachmentVM(this.rootStore, this.commentAttachment)
      vm.loadCMSItem()
      return vm
    }
    return new OldTaskAttachmentVM(this.rootStore, this.commentAttachment)
  }

  @action
  public async generateSignature(callback: Function, paramsToSign: object): Promise<void> {
    const svc = new MediaItemsService()
    const signature = await svc.getCloudinarySignature(paramsToSign)
    callback(signature)
  }

  public async getThumbnailUrl(info) {
    const svc = new MediaItemsService()
    const thumbnailUrl = await svc.buildThumbnailURL(info.public_id, '.jpg', info.secure_url)
    return thumbnailUrl
  }

  @action
  public setSelectedSurveyTemplate(surveyId: string) {
    this.selectedSurveyTemplateId = surveyId
  }

  @action
  public deleteAttachmentFromComment() {
    this.commentAttachment = null
  }

  @action
  public getFeedback() {
    this.generatingSurvey = true
    if (this.task.feedbackSurveyTemplateId && this.task.autoPublishFeedback) {
      setTimeout(async () => {
        const svc = new UserTasksService()
        await svc.autoPublishSurvey(this.objectId, this.organizationId)
        this.rootStore.userTasksStore.loadEditVM(this.objectId)
      }, 1)
    }
    if (this.task.feedbackSurveyTemplateId && !this.task.autoPublishFeedback) {
      this.selectedSurveyTemplateId = this.task.feedbackSurveyTemplateId
      this.goToNewSurveyFromTemplate()
    }
    this.generatingSurvey = false
  }

  @computed
  public get hasSelectedSurvey(): boolean {
    return this.selectedSurveyTemplateId
  }

  @computed
  public get commentSaveDisabled(): boolean {
    if (!this.commentAttachment && !this.commentText) return true
    return false
  }

  @action
  public goToNewSurveyFromTemplate() {
    this.rootStore.userTasksStore.closeDrawer()
    let url = '/surveys/forusertask'
    url += '/' + this.selectedSurveyTemplateId
    url += '/' + this.objectId
    url += '/' + this.userId
    this.rootStore.appStore.router.push(url)
  }

  @action
  public openSurvey(e: any) {
    let url = '/surveys/editv2/' + this.surveyId
    this.rootStore.appStore.router.push(url)
  }

  @action
  public setCommentText(text: string) {
    this.commentText = text
  }

  @action
  public async markUserTaskAsCompleted() {
    this.markCompletedRequested = true
    try {
      const pSvc = new ParseService()
      const result = await pSvc.markUserTaskAsCompleted(this.organizationId, this.objectId)
      if (result.isCompleted) {
        this.markCompletedSuccessful = true
        this.markCompletedRequested = false
        setTimeout(() => this.rootStore.userTasksStore.closeDrawer(), 500)
        setTimeout(() => this.rootStore.userTasksStore.loadEditVM(result.objectId), 1000)
      } else {
        this.markCompletedRequested = false
        this.markCompletedFailed = true
      }
    } catch (error) {
      this.markCompletedRequested = false
      this.markCompletedFailed = true
      throw error
    }
  }

  @action
  public async markUserTaskAsIncomplete() {
    this.markIncompleteRequested = true
    try {
      const pSvc = new ParseService()
      const result = await pSvc.markUserTaskAsIncomplete(this.organizationId, this.objectId)
      if (!result.isCompleted) {
        this.markIncompleteSuccessful = true
        this.markIncompleteRequested = false
        setTimeout(() => this.rootStore.userTasksStore.closeDrawer(), 1000)
      } else {
        this.markIncompleteRequested = false
        this.markIncompleteFailed = true
      }
    } catch (error) {
      this.markIncompleteRequested = false
      this.markIncompleteFailed = true
      throw error
    }
  }

  @action
  public toggleCommentUploadModal() {
    this.showCommentUploadModal = !this.showCommentUploadModal
  }

  @action
  public addAttachmentToTaskComment(attachment: Attachment) {
    // attachments.forEach((a: Attachment) => this.task.addAttachment(a))
    this.commentAttachment = attachment
  }

  @action
  public async addComment() {
    if (!this.commentText && !this.commentAttachment) return
    this.commentSvc = new TaskCommentsService()

    const taskComment = TaskComment.create(
      this.rootStore.appStore.currentOrgId,
      this.rootStore.appStore.currentUserId,
      this.commentText ? this.commentText.trim() : '',
      this.userTask.taskId,
      this.commentAttachment
    )

    await this.commentSvc.saveTaskComment(this.organizationId, taskComment)
    this.commentText = ''
    this.commentAttachment = null
  }

  @computed
  public get saveEnabled() {
    return !this.markCompletedRequested
  }
}
