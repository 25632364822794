import React from 'react'
import { Grid } from '@material-ui/core'
import { observer } from 'mobx-react'
import CheckIcon from '@material-ui/icons/Check'
import WarningIcon from '@material-ui/icons/Warning'
import { ContactImportRowVM } from '../view-models/ContactImportRowVM'
import './ContactImportRow.scss'

interface Props {
  row: ContactImportRowVM
  style: any
}

const UserImportRow: React.FC<Props> = ({ row, style }) => {
  const renderContactFound = () => {
    if (row.contactErrorMessage) return
    return (
      <Grid container item>
        <strong>Contact Found</strong>:{' '}
        {row.isNewContact ? <WarningIcon className='bad' /> : <CheckIcon className='good' />}
      </Grid>
    )
  }
  const renderName = () => {
    return (
      <Grid container item>
        <strong>Name</strong>: {row.name}
      </Grid>
    )
  }

  const renderTitle = () => {
    return (
      <Grid container item>
        <strong>Title</strong>: {row.title}
      </Grid>
    )
  }

  const renderEmail = () => {
    return (
      <Grid container item>
        <strong>Email</strong>: {row.email}
      </Grid>
    )
  }

  const renderClient = () => {
    return (
      <Grid container item>
        <strong>Client</strong>: {row.clientName}
      </Grid>
    )
  }

  const renderUpdatedName = () => {
    if (!row.updatedName) return
    return (
      <Grid container item>
        <strong>Updated Name</strong>: {row.updatedName}
      </Grid>
    )
  }

  const renderUpdatedEmail = () => {
    if (!row.updatedEmail) return
    return (
      <Grid container item>
        <strong>Updated Email</strong>: {row.updatedEmail}
      </Grid>
    )
  }

  const renderUpdatedTitle = () => {
    if (!row.updatedTitle) return
    return (
      <Grid container item>
        <strong>Updated Title</strong>: {row.updatedTitle}
      </Grid>
    )
  }

  const renderAddedTitle = () => {
    if (!row.addedTitle) return
    return (
      <Grid container item>
        <strong>Added Title</strong>: {row.addedTitle}
      </Grid>
    )
  }

  const renderAddedClient = () => {
    if (!row.addedClient) return
    return (
      <Grid container item>
        <strong>Added Client</strong>: {row.addedClient}
      </Grid>
    )
  }

  const renderUpdatedClient = () => {
    if (!row.updatedClient) return
    return (
      <Grid container item>
        <strong>Updated Client</strong>: {row.updatedClient}
      </Grid>
    )
  }

  const renderContactErrorMessage = () => {
    if (!row.contactErrorMessage) return
    return (
      <Grid container item className='row-error-text'>
        <strong>Error</strong>: {row.contactErrorMessage}
      </Grid>
    )
  }

  const renderSuccessfulRow = () => {
    if (row.hasError) return
    return (
      <>
        {renderContactFound()}
        {renderName()}
        {renderTitle()}
        {renderEmail()}
        {renderClient()}
        {renderUpdatedName()}
        {renderAddedTitle()}
        {renderUpdatedTitle()}
        {renderUpdatedEmail()}
        {renderAddedClient()}
        {renderUpdatedClient()}
      </>
    )
  }

  return (
    <Grid container style={style} id='ContactImportRow'>
      <Grid container item>
        <strong>Row: {row.rowIndex}</strong>
      </Grid>
      {renderContactErrorMessage()}
      {renderSuccessfulRow()}
    </Grid>
  )
}

export default observer(UserImportRow)
