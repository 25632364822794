import React from 'react'
import { observer, inject } from 'mobx-react'
import { CardHeader, Tooltip, IconButton } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import LocalizationStore from '../../../../localization/LocalizationStore'
import Searchbar from '../../../../shared/Searchbar'
import Title from './Title'
import { TrainingPlansStore } from 'src/app/training-plans/store/TrainingPlansStore'

interface Props {
  trainingPlansStore?: TrainingPlansStore
  localizationStore?: LocalizationStore
}

const Header: React.FC<Props> = ({ trainingPlansStore, localizationStore }) => {
  const { trainerWidgetVM: vm } = trainingPlansStore
  if (!vm) return null
  const { training_plans: strings } = localizationStore.lzStrings

  return (
    <CardHeader
      id='Header'
      title={<Title />}
      action={
        <>
          <div className='search'>
            <Searchbar
              focused={vm.keywordFilter.length > 0}
              searchValue={vm.keywordFilter}
              onSearchChange={(e) => vm.setKeywordFilter(e.target.value)}
            />
          </div>
        </>
      }
    />
  )
}

export default inject('trainingPlansStore', 'localizationStore')(observer(Header))
