import React from 'react'
import { Card, Grid, InputLabel, MenuItem, Select, IconButton, Typography } from '@material-ui/core'
import { observer, inject, Observer } from 'mobx-react'
import './ManageTrainingPlansWidget.scss'
import Content from './Content'
import { TrainingPlansStore } from '../../../store/TrainingPlansStore'
import Header from './Header'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import Snackbar from '@material-ui/core/Snackbar'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

interface Props {
  trainingPlansStore?: TrainingPlansStore
  localizationStore?: LocalizationStore
}

const ManageTrainingPlansWidget: React.FC<Props> = ({ trainingPlansStore, localizationStore }) => {
  const { manageTrainingsWidgetVM: vm } = trainingPlansStore
  const { training_plans: strings } = localizationStore.lzStrings
  if (!vm) return null
  if (!trainingPlansStore.canCreateTrainingPlans) return null

  const renderSnackbar = () => {
    if (!vm.isSnackbarOpen) return null
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={vm.isSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => vm.closeSnackbar()}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={
          <Typography style={{ color: 'black', display: 'flex' }}>
            <CheckCircleIcon className='check-icon' />
            {vm.snackbarMessage}
          </Typography>
        }
        action={[
          <Observer key={'1'}>
            {() => (
              <IconButton key='close' aria-label='close' onClick={() => vm.closeSnackbar()}>
                <Typography style={{ fontSize: '10pt', color: '#12c09b', fontWeight: 'bold' }}>
                  {strings.dismiss}
                </Typography>
              </IconButton>
            )}
          </Observer>,
        ]}
      />
    )
  }

  const renderFilterMenu = () => {
    return (
      <Grid container direction='row' justifyContent='flex-end' className='filter-row'>
        <Grid item className='filter'>
          <InputLabel>{strings.sort_by}</InputLabel>
          <Select value={vm.sortType} onChange={(e) => vm.setSortType(e.target.value)}>
            <MenuItem value={'Date'}>{strings.date}</MenuItem>
            <MenuItem value={'Name'}>{strings.name}</MenuItem>
          </Select>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <Card id='ManageTrainingPlansWidget' elevation={0}>
        <Header />
        {renderFilterMenu()}
        <Content />
      </Card>
      {renderSnackbar()}
    </>
  )
}

export default inject(
  'trainingPlansStore',
  'localizationStore'
)(observer(ManageTrainingPlansWidget))
