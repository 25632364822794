import { RootStore } from '../../../stores/RootStore'
import { action, observable } from 'mobx'
import { OrganizationUserEditVM } from './OrganizationUserEditVM'
import * as Sentry from '@sentry/browser'
import { OrganizationUsersService } from '../../service/OrganizationUsersService'
import { IOneTimeUsePasscodeResult } from '../../interfaces/IOneTimeUsePasscodeResult'

export class UserOneTimeUsePasscodeDialogVM {
  private rootStore: RootStore
  private editVM: OrganizationUserEditVM

  constructor(rootStore: RootStore, editVM: OrganizationUserEditVM) {
    this.rootStore = rootStore
    this.editVM = editVM
  }

  @observable public shown: boolean = false
  @observable public isProcessing: boolean = false
  @observable public sessionToken: string = ''
  @observable public userOneTimeUsePasscode: string = ''
  @observable public errorMessage: string = ''

  @action
  public show() {
    this.shown = true
    this.getPasscode()
  }

  @action
  public hide() {
    this.shown = false
  }

  @action
  public async getPasscode() {
    this.isProcessing = true
    const svc = new OrganizationUsersService(this.rootStore)
    try {
      const result: any = await svc.createOneTimeUsePasscode(
        this.editVM.userId,
        this.rootStore.appStore.currentUserId
      )
      this.userOneTimeUsePasscode = result.passcode
      this.isProcessing = false
      if (result.success === false) {
        this.errorMessage = result.errorMessage
        setTimeout(() => this.hide(), 1000)
      }
    } catch (e) {
      this.hide()
      this.isProcessing = false
      Sentry.captureException(e)
    }
  }

  @action
  public cancel() {
    this.hide()
  }

  @action
  public setPasscode(val: string) {
    this.userOneTimeUsePasscode = val
  }
}
