import React from 'react'
import { observer } from 'mobx-react'
import rootStore from '../../stores/RootStore'
import PasswordReset from './PasswordReset'
import isMobile from '../../../utils/isMobile'

@observer
export default class PasswordResetScreen extends React.Component<any, any> {
  constructor(props) {
    super(props)

    if (props.params.token) {
      rootStore.authStore.loadResetPasswordVM(props.params.token)
      if (isMobile) {
        if (props.params.mobileAppId) rootStore.authStore.tryRouteToMobile(props.params.token, props.params.mobileAppId)
      }
    }
  }

  // componentWillUnmount() {
  //   rootStore.pulseCampaignsStore.viewModels.loadCampaignEditVM('empty')
  // }

  render() {
    return (
      <div id='ResetPasswordScreen'>
        <PasswordReset />
      </div>
    )
  }
}
