import { ColDef, GridOptions, RowClickedEvent, RowNode } from 'ag-grid-community'
import { action, computed, observable } from 'mobx'
import NameCell from '../../surveyParticipants/views/NameCell'
import RowActions from '../../surveyParticipants/views/RowActions'
import SelectAll from '../../surveyParticipants/views/SelectAll'
import { AGGridVM } from '../../shared/ag-grid/AGGridVM'
import { nameOf } from '../../shared/nameOf'
import { RootStore } from '../../stores/RootStore'
import { SurveyParticipant } from '../aggregate/SurveyParticipant'
import { SurveyParticipantsDataStore } from '../store/SurveyParticipantsDataStore'
import { SurveyParticipantRowVM } from './SurveyParticipantRowVM'
import { HistoryVM } from '../../surveys/view-models/HistoryVM'
import { ParseService } from '../../services/ParseService'
import { Survey } from '../../surveys/aggregate/Survey'
import { SurveyEventSummaryVM } from '../../userSurveyEvents/view-models/SurveyEventSummaryVM'

export class SurveyParticipantsAGGridVM extends AGGridVM {
  private rootStore: RootStore
  public dataStore: SurveyParticipantsDataStore
  private survey: Survey

  constructor(rootStore: RootStore, survey: Survey) {
    super()
    this.rootStore = rootStore
    this.survey = survey
    this.sizeColumnsToFit = true
    this.serverSideLoaded = true
    this.dataStore = new SurveyParticipantsDataStore(this.rootStore, this.survey.objectId)
    this.dataStore.onRecordUpdated = (e) => this.onRecordUpdated(e)
  }

  @observable public rows = []
  @observable public selectedId: string = ''
  @observable public showCancelDialog: boolean = false
  @observable public showRemindDialog: boolean = false
  @observable public showSendDialog: boolean = false
  @observable public showHistoryDialog: boolean = false
  @observable public historyVM: HistoryVM
  @observable public showLinkDialog: boolean = false
  @observable public surveyLink: string = ''
  @observable public showCopySnackbar: boolean = false
  @observable public filter: string = ''
  @observable public displayAll: boolean = false
  @observable public selectAll: boolean = false
  @observable public newSelectedRows: Array<any> = []
  @observable public newSelectedRow = null
  @observable public batchActionButtonsActive: boolean = true
  @observable public processingMsg: string = 'processing'
  @observable public loadingFirstPage: boolean = true
  @observable public selectedTotalShown: number = 0
  @observable public calculatedSelected: number = 0
  @observable public showEmailSummary: boolean = false
  @observable public emailSummaryVM: SurveyEventSummaryVM

  @computed
  public get isEmailSummaryAvailable() {
    // Must be a tenant admin and not be a repeating survey
    return this.rootStore.appStore.isOrgAdmin && this.survey.schedule.repeat === 'no repeat'
  }

  public getRowNodeId(row: SurveyParticipantRowVM): string {
    return row.surveyParticipant.objectId
  }

  public get shouldRender(): boolean {
    if (!this.dataStore.listRecordsLoaded) return false
    return true
  }

  public bringInView() {
    if (!this.gridApi) return
    this.gridApi.sizeColumnsToFit()
  }

  public onRecordUpdated(obj: SurveyParticipant) {
    const rowNode = this.gridApi.getRowNode(obj.objectId)
    if (!rowNode) return
    rowNode.setData(new SurveyParticipantRowVM(this.rootStore, obj, this.survey.anonymizeResults))
  }

  @computed
  public get columnDefs(): ColDef[] {
    const s = this.rootStore.localizationStore.lzStrings
    return [
      {
        width: 50,
        suppressSizeToFit: true,
        checkboxSelection: true,
        sortable: false,
        headerComponent: 'selectAll',
        headerComponentParams: { vm: this },
      },
      {
        headerName: s.surveyInvitationsList.name,
        headerTooltip: s.surveyInvitationsList.name,
        field: nameOf<SurveyParticipantRowVM, string>((e) => e.name),
        cellRenderer: 'nameCell',
        width: 175,
        suppressSizeToFit: true,
        checkboxSelection: false,
        tooltipField: 'null',
      },
      {
        headerName: s.surveyInvitationsList.invite_sent,
        headerTooltip: s.surveyInvitationsList.invite_sent,
        field: nameOf<SurveyParticipantRowVM, string>((e) => e.invitationSent),
      },
      {
        headerName: s.surveyInvitationsList.email_status,
        headerTooltip: s.surveyInvitationsList.email_status,
        field: nameOf<SurveyParticipantRowVM, string>((e) => e.emailStatus),
      },
      {
        headerName: s.surveyInvitationsList.viewed,
        headerTooltip: s.surveyInvitationsList.viewed,
        field: nameOf<SurveyParticipantRowVM, string>((e) => e.viewedDate),
      },
      {
        headerName: s.surveyInvitationsList.remind_last_sent,
        headerTooltip: s.surveyInvitationsList.remind_last_sent,
        field: nameOf<SurveyParticipantRowVM, string>((e) => e.reminderLastSentAt),
      },
      {
        headerName: s.surveyInvitationsList.next_reminder,
        headerTooltip: s.surveyInvitationsList.next_reminder,
        field: nameOf<SurveyParticipantRowVM, string>((e) => e.nextReminderAt),
      },
      {
        headerName: s.surveyInvitationsList.due_date,
        headerTooltip: s.surveyInvitationsList.due_date,
        field: nameOf<SurveyParticipantRowVM, string>((e) => e.dueDate),
      },
      {
        headerName: s.surveyInvitationsList.response_received,
        headerTooltip: s.surveyInvitationsList.response_received,
        field: nameOf<SurveyParticipantRowVM, string>((e) => e.responseDate),
      },
      {
        headerName: s.surveyInvitationsList.next_invite,
        headerTooltip: s.surveyInvitationsList.next_invite,
        field: nameOf<SurveyParticipantRowVM, string>((e) => e.nextInvitationAt),
      },
      {
        headerName: s.surveyInvitationsList.actions,
        headerTooltip: s.surveyInvitationsList.actions,
        field: nameOf<SurveyParticipantRowVM, string>((e) => e.userSurveyId),
        width: 160,
        suppressSizeToFit: true,
        tooltipField: 'null',
        sortable: false,
        cellRenderer: 'rowActions',
        cellRendererParams: {
          send: (dto) => {
            this.sendSurveyInvite(dto)
          },
          link: (userSurveyId) => {
            this.handleLinkClick(userSurveyId)
          },
          remind: (dto) => {
            this.sendSurveyReminder(dto)
          },
          cancel: (dto) => {
            this.cancelUserSurvey(dto)
          },
          history: (surveyId, userId, userName, email) => {
            this.showHistory(surveyId, userId, userName, email)
          },
        },
      },
    ]
  }

  public getGridOptions(): GridOptions {
    return {
      ...this.baseGridOptions,
      onRowClicked: (e) => this.rowClicked(e),
      getRowNodeId: (e) => this.getRowNodeId(e),
      suppressRowClickSelection: true,
      suppressCellSelection: true,
      suppressRowHoverHighlight: true,
      rowSelection: 'multiple',
      onRowSelected: (e) => this.onRowSelected(e.node),
      columnDefs: this.ensureTooltipFields(this.columnDefs),
      pagination: false,
      frameworkComponents: {
        rowActions: RowActions,
        nameCell: NameCell,
        selectAll: SelectAll,
      },

      isRowSelectable: (rowNode) => {
        if (!rowNode.data) return false
        return !rowNode.data.isArchived
      },
    }
  }

  @action
  public setQuickFilter(val: string) {
    this.typedFilterText = val
    if (this.quickFilterTO) clearTimeout(this.quickFilterTO)
    this.quickFilterTO = setTimeout(() => this.applyFilter(), 1000)
  }

  @action
  public refresh() {
    if (!this.gridApi) return
    this.gridApi.refreshInfiniteCache()
    this.gridApi.purgeInfiniteCache()
  }

  @action
  public scrollToTop() {
    this.gridApi.ensureIndexVisible(0)
  }

  @action
  public hardRefreshParticipantsList() {
    if (!this.gridApi) return
    this.selectAll = false
    this.scrollToTop()
    this.resetNodesSelected()
    this.gridApi.refreshInfiniteCache()
    this.gridApi.purgeInfiniteCache()
  }

  private applyFilter() {
    this.dataStore.setFilter(this.typedFilterText)
    this.reload()
    this.columnApi.applyColumnState({
      state: [{ colId: 'userName', sort: 'asc' }],
    })
  }

  protected onGridReadied() {
    this.gridApi.sizeColumnsToFit()
    this.gridApi.setDatasource({
      rowCount: this.totalRecords,
      getRows: async (params) => {
        params.sortModel.forEach((col: { colId: string; sort: 'asc' | 'desc' }, idx: number) => {
          let dbCol = col.colId
          if (dbCol === 'name') {
            dbCol = 'firstName'
          }
          if (idx === 0) {
            this.dataStore.setSort(dbCol, col.sort)
            if (dbCol === 'firstName') this.dataStore.addSort('lastName', col.sort)
          }
          if (idx > 0) this.dataStore.addSort(dbCol, col.sort)
        })

        if (params.sortModel.length === 0) {
          this.dataStore.setSort('firstName')
          this.dataStore.addSort('lastName')
        }
        if (params.startRow === 0) {
          this.dataStore.setPage(0)
          this.loadingFirstPage = true
        }
        await this.dataStore.getNextPage()
        const rows = this.dataStore.surveyParticipants.map(
          (e) => new SurveyParticipantRowVM(this.rootStore, e, this.survey.anonymizeResults)
        )
        this.rows = rows
        this.loadingFirstPage = false

        params.successCallback(rows, this.dataStore.totalRecords)
        if (this.selectAll) {
          this.gridApi.forEachNode((node) => {
            node.setSelected(true)
          })
        }
        setTimeout(() => this.setSelectedTotalShown(), 100)
      },
    })
  }

  public rowClicked(e: RowClickedEvent) {
    console.log(e)
  }

  @action
  private selectRow(dto) {
    this.newSelectedRow = dto
  }

  @action
  private cancelUserSurvey(dto) {
    this.selectRow(dto)
    this.toggleCancelDialog()
  }

  @action
  private async showHistory(surveyId, userId, userName, email) {
    const orgId = this.rootStore.appStore.currentOrgId
    this.historyVM = new HistoryVM(orgId, userId, surveyId, userName, email)
    this.toggleShowHistoryDialog()
  }

  @action
  public async showEmailSummaryDialog() {
    const orgId = this.rootStore.appStore.currentOrgId
    this.emailSummaryVM = new SurveyEventSummaryVM(orgId, this.survey.objectId)
    this.toggleViewEmailSummary()
  }

  @action
  private sendSurveyReminder(dto) {
    this.selectRow(dto)
    this.toggleRemindDialog()
  }

  @action
  private sendSurveyInvite(dto) {
    this.selectRow(dto)
    this.toggleSendDialog()
  }

  @computed
  public get hasResponses(): boolean {
    return this.totalRecords !== 0
  }

  @action
  public toggleCancelDialog() {
    this.showCancelDialog = !this.showCancelDialog
    if (!this.showCancelDialog) this.clearSelectedRow()
  }

  @action
  public toggleRemindDialog() {
    this.showRemindDialog = !this.showRemindDialog
    if (!this.showRemindDialog) this.clearSelectedRow()
  }

  @action
  public toggleSendDialog() {
    this.showSendDialog = !this.showSendDialog
    if (!this.showSendDialog) this.clearSelectedRow()
  }

  @action
  public toggleViewEmailSummary() {
    this.showEmailSummary = !this.showEmailSummary
  }

  @action
  public clearSelectedRow() {
    this.newSelectedRow = null
  }

  @action
  public toggleShowHistoryDialog() {
    this.showHistoryDialog = !this.showHistoryDialog
  }

  @action
  public handleLinkClick(objectId) {
    this.generateSurveyLink(objectId)
    this.toggleLinkDialog()
  }

  @action
  public async generateSurveyLink(objectId) {
    const svc = new ParseService()
    const link = await svc.generateSurveyLink(this.rootStore.appStore.currentOrgId, objectId)
    this.surveyLink = link
  }

  @action
  public toggleLinkDialog() {
    this.showLinkDialog = !this.showLinkDialog
    if (!this.showLinkDialog) {
      this.surveyLink = ''
      this.showCopySnackbar = false
    }
  }

  @action
  public remindInvite() {
    this.remindSurveyInvitation()
    this.toggleRemindDialog()
  }

  @action
  public async remindSurveyInvitation() {
    let remindersArray = []
    remindersArray.push(this.newSelectedRow)
    const svc = new ParseService()
    await svc.sendUserSurveyReminders(
      this.rootStore.appStore.currentOrgId,
      this.survey.objectId,
      remindersArray,
      false
    )
  }

  @action
  public async remindSurveyInvitations() {
    this.processingMsg = 'Sending Reminders..'
    this.batchActionButtonsActive = false
    this.toggleRemindDialog()
    if (this.selectAll) {
      const skipInvitations = this.getUnselected()
      const svc = new ParseService()
      await svc.sendUserSurveyReminders(
        this.rootStore.appStore.currentOrgId,
        this.survey.objectId,
        null,
        true,
        skipInvitations
      )
      this.selectAll = false
    } else {
      const invitations = this.newSelectedRows.slice()
      const svc = new ParseService()
      await svc.sendUserSurveyReminders(
        this.rootStore.appStore.currentOrgId,
        this.survey.objectId,
        invitations,
        false
      )
    }
    this.resetNodesSelected()
    this.batchActionButtonsActive = true
  }

  @action
  public sendInvite() {
    this.sendSurveyInvitation()
    this.toggleSendDialog()
  }

  @action
  public async sendSelectedInvitations() {
    this.processingMsg = 'Sending Invitations..'
    this.batchActionButtonsActive = false
    this.toggleSendDialog()
    if (this.selectAll) {
      const skipInvitations = this.getUnselectedInvites()
      const svc = new ParseService()
      await svc.sendSurveyInvitations(
        this.rootStore.appStore.currentOrgId,
        this.survey.objectId,
        null,
        true,
        skipInvitations
      )
      this.selectAll = false
    } else {
      const invitations = this.newSelectedRows.slice()
      const svc = new ParseService()
      await svc.sendSurveyInvitations(
        this.rootStore.appStore.currentOrgId,
        this.survey.objectId,
        invitations,
        false
      )
    }
    this.resetNodesSelected()
    this.batchActionButtonsActive = true
  }

  @action
  public async sendSurveyInvitation() {
    let invitationsArray = []
    invitationsArray.push(this.newSelectedRow)
    const svc = new ParseService()
    await svc.sendSurveyInvitations(
      this.rootStore.appStore.currentOrgId,
      this.survey.objectId,
      invitationsArray,
      false
    )
  }

  @action
  public cancelInvite() {
    this.cancelSurveyInvitation()
    this.toggleCancelDialog()
  }

  @action
  public async cancelSurveyInvitation() {
    let invitationsArray = []
    invitationsArray.push(this.newSelectedRow.userSurveyId)
    const svc = new ParseService()
    await svc.cancelSurveyInvitations(
      this.rootStore.appStore.currentOrgId,
      this.survey.objectId,
      invitationsArray,
      false
    )
  }

  @action
  public async cancelSelectedInvitations() {
    this.processingMsg = 'Cancelling Invitations..'
    this.batchActionButtonsActive = false
    this.toggleCancelDialog()
    if (this.selectAll) {
      const skipInvitations = this.getUnselected()
      const svc = new ParseService()
      await svc.cancelSurveyInvitations(
        this.rootStore.appStore.currentOrgId,
        this.survey.objectId,
        null,
        true,
        skipInvitations
      )
      this.selectAll = false
    } else {
      const invitations = this.convertToUserSurveyIds()
      const svc = new ParseService()
      await svc.cancelSurveyInvitations(
        this.rootStore.appStore.currentOrgId,
        this.survey.objectId,
        invitations,
        false
      )
    }
    this.resetNodesSelected()
    this.batchActionButtonsActive = true
  }

  @action
  public convertToUserSurveyIds() {
    let userSurveyIds = []
    for (let row of this.newSelectedRows) {
      let usi = row.userSurveyId
      if (usi) userSurveyIds.push(usi)
    }
    return userSurveyIds
  }

  @action
  public toggleCopySnackbar() {
    this.showCopySnackbar = !this.showCopySnackbar
  }

  @action
  public manageSelectedRows(node) {
    const selection = node.data.toDTO()
    let index

    if (selection.type === 'user') {
      index = this.newSelectedRows.findIndex((e) => e.userId === selection.userId)
    }
    if (selection.type === 'non-user') {
      index = this.newSelectedRows.findIndex((e) => e.email === selection.email)
    }
    if (index === -1) this.newSelectedRows.push(selection)
    else this.newSelectedRows.splice(index, 1)
  }

  @action
  public onRowSelected(node: RowNode) {
    this.manageSelectedRows(node)
    this.calculateAllSelected()
  }

  @action
  public resetNodesSelected() {
    this.newSelectedRows = this.newSelectedRows.splice(0, this.newSelectedRows.length)
    this.gridApi.deselectAll()
  }

  @computed
  public get hasRows() {
    return this.rows.length !== 0
  }

  @computed
  public get pendingInvitations() {
    if (!this.dataStore) return []
    return this.dataStore.totalRecords
  }

  @computed
  public get surveyParticipants() {
    return this.dataStore.surveyParticipants
  }

  @computed
  public get totalRecords() {
    return this.dataStore.totalRecords
  }

  @computed public get allSelectedIndeterminate() {
    if (this.selectAll && !this.allSelected) return true
    return false
  }

  @computed public get allSelectedChecked() {
    if (this.selectAll && this.allSelected) return true
    return false
  }

  @computed public get allSelected() {
    if (this.totalRecords === this.newSelectedRows.length) return true
    if (this.surveyParticipants.length === this.newSelectedRows.length) return true
    return false
  }

  @action public calculateAllSelected() {
    if (!this.selectAll) return
    const diff = this.selectedTotalShown - this.newSelectedRows.length
    const result = this.totalRecords - diff
    this.calculatedSelected = result
  }

  @action public getUnselected() {
    let unselected = []
    this.gridApi.forEachNode((node) => {
      if (node.isSelected() === false) {
        unselected.push(node.data.userId)
      }
    })
    return unselected
  }

  @action public getUnselectedInvites() {
    let unselected = []
    this.gridApi.forEachNode((node) => {
      if (node.isSelected() === false) {
        const selection = node.data.toDTO()
        unselected.push(selection)
      }
    })
    return unselected
  }

  @action public setSelectedTotalShown() {
    if (!this.selectAll) return
    if (this.newSelectedRows.length > this.selectedTotalShown) {
      this.selectedTotalShown = this.newSelectedRows.length
    }
    this.calculateAllSelected()
  }

  @action
  public toggleSelectAll() {
    this.selectAll = !this.selectAll
    if (this.selectAll) {
      this.gridApi.forEachNode((node) => {
        node.setSelected(true)
      })
    }
    if (!this.selectAll) {
      this.gridApi.forEachNode((node) => {
        node.setSelected(false)
      })
    }
    setTimeout(() => this.setSelectedTotalShown(), 100)
  }
}
