import React, { FC } from 'react'
import { observer, inject } from 'mobx-react'
import './SurveyOptions.scss'
import { SurveysStore } from '../../../store/SurveysStore'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  OutlinedInput,
  TextField,
} from '@material-ui/core'
import LocalizationStore from '../../../../localization/LocalizationStore'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { LabelsStore } from '../../../../labels/store/LabelsStore'
import { AudienceMember } from '../../../../audience-members/aggregate/AudienceMember'
import { Owner } from '../../../aggregate/Owner'

interface Props {
  surveysStore?: SurveysStore
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
}

const SurveyOwner: FC<Props> = ({ surveysStore, localizationStore, labelsStore }) => {
  const surveyEditVM = surveysStore.viewModels.editVM
  const vm = surveyEditVM.surveyOptionsVM
  if (!surveyEditVM) return null
  if (surveyEditVM.limited) return null
  if (!vm) return null
  const s = localizationStore.lzStrings.surveys

  const renderDialog = () => {
    let ownersList = surveyEditVM.usersOrdered
    if (surveyEditVM.isSurveyTemplateEdit && surveyEditVM.survey.organizationId === 'DEFAULT')
      ownersList = surveyEditVM.systemAdminUsersOrdered as Owner[]
    return (
      <Dialog className='owner-dialog' open={vm.ownerDialogOpen} maxWidth={'sm'}>
        <DialogTitle>{`${labelsStore.getLabelByLanguageAndFor('pulse')}  ${s.owner}`}</DialogTitle>
        <DialogContent className='owner-dialog-content'>
          <div className='owner-warning'>{s.owner_warning}</div>
          <Autocomplete
            disabled={false}
            options={ownersList}
            getOptionLabel={(e) => e.name}
            onInputChange={(e, val) => vm.getAllOwners(val)}
            loading={surveyEditVM.loadingOwners}
            value={vm.publishedByUser}
            onChange={(e, val) => vm.setTempPublishedByUser(val ? (val as Owner) : null)}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!surveyEditVM.isPublishedByUserIdValid}
                placeholder='Select...'
                variant='outlined'
                className={'publisher-select'}
                style={{ width: 200 }}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => vm.toggleShowOwnerDialog()}>
            {s.cancel}
          </Button>
          <Button
            disabled={!vm.FKTempPublishedByUser}
            variant='contained'
            onClick={() => vm.changeSurveyOwner()}
          >
            {s.update}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const renderChangeButton = () => {
    if (!surveyEditVM.canModifySurveyOwner || surveyEditVM.isReadOnly) return
    return (
      <Button variant='outlined' onClick={() => vm.openChangeOwnerDialog()}>
        {s.change}
      </Button>
    )
  }

  return (
    <div className='section survey-owner'>
      <div className='primary-text'>{`${labelsStore.getLabelByLanguageAndFor('pulse')}  ${
        s.owner
      }`}</div>

      <div className='section-content'>
        <div className='owner-container'>
          <OutlinedInput
            id='survey-owner-text'
            className='owner-name'
            value={surveyEditVM.fkPublishedByUser.name}
            readOnly={true}
          />
          {renderChangeButton()}
          {renderDialog()}
        </div>
      </div>
    </div>
  )
}

export default inject('surveysStore', 'localizationStore', 'labelsStore')(observer(SurveyOwner))
