import React from 'react'
import { inject, observer } from 'mobx-react'
import { RolesStore } from '../store/RolesStore'
import LocalizationStore from '../../localization/LocalizationStore'
import { CircularProgress, Drawer, Tabs, Tab, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core'
import './RoleEditDrawer.scss'
import MuiDrawerToolbar from '../../shared/Mui-Drawer/MuiDrawerToolbar'
import MuiDrawerHeader from '../../shared/Mui-Drawer/MuiDrawerHeader'
import TabPanel from '../../groups-roles-widget/views/TabPanel'
import RolesBasicInfo from './RolesBasicInfo'
import RolesMembersList from './RoleMemberList'
import { LabelsStore } from '../../labels/store/LabelsStore'
import RefreshIcon from '@material-ui/icons/Refresh'

interface Props {
  rolesStore?: RolesStore
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
}

const RoleEditDrawer: React.FC<Props> = ({ rolesStore, localizationStore, labelsStore }) => {
  const strings = localizationStore.lzStrings
  const { editVM: vm } = rolesStore
  if (!vm) return null

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    vm.setTabValue(newValue)
  }

  const renderDrawer = () => {
    if (vm.isParticipantsTabOpen) return null
    return (
      <div>
        <MuiDrawerToolbar
          moreMenuProps={vm.moreMenuProps}
          showReset={Boolean(vm.objectId)}
          onConfirmReset={() => rolesStore.setRoleToEdit(vm.objectId)}
          handleCancelClick={() => vm.toggleRolesDrawer()}
          disabled={vm.isProcessing}
          handleSubmitClick={() => vm.save()}
          renderAdditionalMenuItems={(close) => [
            <MenuItem
              onClick={() => {
                close()
                vm.refreshEvents()
              }}
            >
              <ListItemIcon className='green'>
                <RefreshIcon />
              </ListItemIcon>
              <ListItemText primary={'Refresh Events'} />
            </MenuItem>,
          ]}
        />
        <Tabs value={vm.tabValue} onChange={handleChange} variant='fullWidth'>
          <Tab label='Basic Info' className={!vm.isValid ? 'tab-error' : ''} />
          <Tab label='Members' disabled={vm.isNew} />
        </Tabs>
        <TabPanel value={vm.tabValue} index={0}>
          <RolesBasicInfo />
        </TabPanel>
        <TabPanel value={vm.tabValue} index={1}>
          <RolesMembersList />
        </TabPanel>
      </div>
    )
  }

  const renderSpinner = () => {
    if (vm.isLoading)
      return (
        <div className={'spinner-container'}>
          <CircularProgress className='spinner' />
        </div>
      )
  }

  return (
    <Drawer
      variant='temporary'
      anchor='right'
      open={vm.showDrawer}
      onClose={() => (vm.showDrawer = false)}
      id='RoleEditDrawer'
    >
      <MuiDrawerHeader
        title={vm.objectId ? strings.rolesEdit.manage_role : strings.rolesEdit.add_role}
        toggleDrawer={() => {
          vm.toggleRolesDrawer()
        }}
      />
      {renderSpinner()}
      {renderDrawer()}
    </Drawer>
  )
}

export default inject('rolesStore', 'localizationStore', 'labelsStore')(observer(RoleEditDrawer))
