import Parse from 'parse'
import { RootStore } from '../../stores/RootStore'
import { IUserInfoResult } from '../interfaces/IUserInfoResult'

export class UserService {
  rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public async getUserInfo(orgId?: string): Promise<IUserInfoResult> {
    try {
      const result = await Parse.Cloud.run('getUserInfo', { orgId })
      return result
    } catch (e) {
      console.log('Error in UserService')
      console.error(e)
      return {
        success: false,
        errorMessage: e.message,
      }
    }
  }
}
