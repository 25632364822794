import { action, computed, observable } from 'mobx'
import { RootStore } from '../../stores/RootStore'


export class AnnouncementSendNotificationsVM {
  private rootStore: RootStore
  private afterConfirm: Function

  constructor(rootStore: RootStore, afterConfirm: Function) {
    this.rootStore = rootStore
    this.afterConfirm = afterConfirm
  }

  @observable showNotificationsModal: boolean = false
  @observable didClick: boolean = false
  @observable sendNotifications: boolean = false

  @action
  public toggleShowNotificationsModal() {
    if (this.showNotificationsModal && !this.didClick) return
    this.showNotificationsModal = !this.showNotificationsModal
  }

  @action
  public setSendNotifications(send: boolean) {
    this.didClick = true
    this.sendNotifications = send
    this.toggleShowNotificationsModal()
    this.afterConfirm()
    this.rootStore.announcementsStore.announcementsWidgetVM.forceUpdate()
  }

}
