import React, { FC, useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import { SurveysStore } from '../../../store/SurveysStore'
import { SurveyEditTabsEnum } from '../../../types/SurveyEditTabsEnum'
import Grid from '@material-ui/core/Grid'
import './PublishTabPanel.scss'
import TimePicker from '../../../../shared/time-picker/TimePicker'
import { InputLabel, Radio, RadioGroup } from '@material-ui/core'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import { Button } from '@material-ui/core'
import { Player } from '@lottiefiles/react-lottie-player'

interface PublishTabPanelProps {
  surveysStore?: SurveysStore
  localizationStore?: LocalizationStore
}

const PublishTabPanel: FC<PublishTabPanelProps> = ({ surveysStore, localizationStore }) => {
  const { surveys: lz } = localizationStore.lzStrings
  const vm = surveysStore.viewModels.editVM
  if (!vm) return null
  if (vm.currentTabIndex !== SurveyEditTabsEnum.PUBLISH) return null
  return (
    <Grid container id='PublishTabPanel'>
      <div className='success-text'>Your survey is on its way!</div>
      <div className='player-container'>
        <Player
          autoplay={true}
          loop={true}
          controls={false}
          src='https://assets10.lottiefiles.com/packages/lf20_rcwlnqyn.json'
          style={{ height: '300px', width: 'auto' }}
        ></Player>
      </div>
      <div className='btn-row'>
        <Button variant='contained' onClick={() => vm.navigateToAnalysis()}>
          Go to Analysis
        </Button>
      </div>
    </Grid>
  )
}

export default inject('localizationStore', 'surveysStore')(observer(PublishTabPanel))
