import { computed, observable } from 'mobx'
import { IPulseCategoryDTO } from '../../../pulse-categories/dtos/IPulseCategoryDTO'

export class GrandCategoryTabVM {
  constructor(
    selectedTabs: number[],
    category: IPulseCategoryDTO,
    index: number
  ) {
    this.category = category
    this.index = index
    this.selectedTabs = selectedTabs.slice()
  }

  @observable public category: IPulseCategoryDTO = null
  @observable public index: number = 0
  @observable public selectedTabs: number[] = []

  @computed
  public get name(): string {
    return this.category.name
  }

  @computed
  public get objectId(): string {
    return this.category.objectId
  }

  @computed
  public get isSelected(): boolean {
    return this.selectedTabs.includes(this.index)
  }
}
