import { Contact } from '../../contacts/aggregate/Contact'
import { RootStore } from '../../stores/RootStore'
import { ContactsExpansionDataStore } from './ContactsExpansionDataStore'

export class ContactsExpansionService {
  private rootStore: RootStore
  private dataStore: ContactsExpansionDataStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.dataStore = new ContactsExpansionDataStore(rootStore)
  }

  public async getAllContacts(clientd: string): Promise<Contact[]> {
    await this.dataStore.loadListRecords()
    return this.dataStore.contacts
  }
}
