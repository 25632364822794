import React from 'react'
import { inject, observer } from 'mobx-react'
import { Box, AppBar, Toolbar, Tabs, Tab } from '@material-ui/core'
import './TabsBar.scss'
import { TabEnums } from '../../../types/TabEnums'
import { GoalsStore } from '../../../store/GoalsStore'
import DetailsTabPanel from './DetailsTabPanel'
import ParticipantsTabPanel from './ParticipantsTabPanel'
import PreviewTabPanel from './SummaryTabPanel'
import ChartIcon from '@material-ui/icons/InsertChart'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div hidden={value !== index} {...other}>
      <Box component='div'>{children}</Box>
    </div>
  )
}

interface Props {
  goalsStore?: GoalsStore
}

const TabsBar: React.FC<Props> = ({ goalsStore }) => {
  const { goalEditVM: vm } = goalsStore
  if (!vm) return null

  const handleChange = (e: any, newValue: number) => {
    vm.setCurrentTab(newValue)
  }

  return (
    <>
      <div id='TabsBar'>
        <AppBar position='static' elevation={0}>
          <Toolbar>
            <div>
              <Tabs
                value={vm.currentTab}
                onChange={handleChange}
                indicatorColor={'primary'}
                textColor='primary'
                variant='fullWidth'
              >
                <Tab
                  className={!vm.saveValid ? 'tab-error' : ''}
                  label='Details'
                  value={TabEnums.DETAILS}
                  icon={<ChartIcon />}
                />
                <Tab
                  label='Participants'
                  className={!vm.participantsValid ? 'tab-error' : ''}
                  value={TabEnums.PARTICIPANTS}
                  icon={<ChartIcon />}
                />
                <Tab
                  label='Summary'
                  // disabled={!vm.saveValid}
                  value={TabEnums.SUMMARY}
                  icon={<ChartIcon />}
                />
              </Tabs>
            </div>
          </Toolbar>
        </AppBar>

        <TabPanel key={TabEnums.DETAILS} value={vm.currentTab} index={TabEnums.DETAILS}>
          <DetailsTabPanel />
        </TabPanel>
        <TabPanel key={TabEnums.PARTICIPANTS} value={vm.currentTab} index={TabEnums.PARTICIPANTS}>
          <ParticipantsTabPanel />
        </TabPanel>
        <TabPanel key={TabEnums.SUMMARY} value={vm.currentTab} index={TabEnums.SUMMARY}>
          <PreviewTabPanel />
        </TabPanel>
      </div>
    </>
  )
}

export default inject('goalsStore')(observer(TabsBar))
