import React from 'react'
import { inject, observer } from 'mobx-react'
import { AgGridReact } from 'ag-grid-react/lib/agGridReact'
import { CircularProgress, Typography } from '@material-ui/core'
import MuiConfirmPrompt from '../../../shared/MuiConfirmPrompt'
import LocalizationStore from '../../../localization/LocalizationStore'
import './OrganizationUsersListTable.scss'
import { OrganizationUsersListManageVM } from '../../view-models/list/OrganizationUsersListManageVM'
import MuiConfirmDeleteDialog from './MuiConfirmDeleteDialog'

interface Props {
  organizationUsersListManageVM: OrganizationUsersListManageVM
  localizationStore: LocalizationStore
}

const OrganizationUsersListTable: React.FC<Props> = ({
  organizationUsersListManageVM,
  localizationStore,
}) => {
  const vm = organizationUsersListManageVM.tableVM
  const lz = localizationStore.lzStrings.orgUsersList
  if (!vm) return

  const renderSpinner = () => {
    if (vm && vm.shouldRender) return
    if (!vm.columnsLoaded) return
    if (vm.showTable) return
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  const renderGrid = () => {
    if (!vm.shouldRender) return
    if (!vm.showTable) return
    const computeHiddenClass = (params) => {
      if (params && params.data && params.data.aggregate && params.data.aggregate.isHiddenInOrg)
        return 'hidden'
      return ''
    }
    return (
      <div className={vm.hasRows ? 'ag-theme-alpine' : 'ag-theme-alpine ag-grid-no-rows'}>
        <AgGridReact
          gridOptions={{
            ...vm.getGridOptions(),
          }}
          getRowClass={computeHiddenClass}
        />
      </div>
    )
  }

  const renderNoRowsContent = () => {
    if (vm.hasRows && vm.columnsLoaded) return
    return (
      <Typography component='div' className='no-rows'>
        {lz.no_rows_to_show}
      </Typography>
    )
  }

  const renderConfirmSendWelcomeDialog = () => {
    return (
      <MuiConfirmPrompt
        confirmMessage={`${lz.are_you_sure} ${vm.dialogCount} ${lz.users}?`}
        onClose={() => vm.cancelSendWelcomeConfirmDialog()}
        open={vm.confirmSendDialogOpen}
        onConfirm={() => {
          vm.sendWelcome()
        }}
        title={lz.send_welcome}
        disabled={vm.isProcessing}
      />
    )
  }

  const renderConfirmExpirePasswordsDialog = () => {
    return (
      <MuiConfirmPrompt
        confirmMessage={`${lz.are_you_sure_expire} ${vm.dialogCount} ${lz.users}?`}
        onClose={() => vm.cancelExpirePasswordsConfirmDialog()}
        open={vm.confirmExpirePasswordsDialogOpen}
        onConfirm={() => {
          vm.expirePasswords()
        }}
        title={lz.expire_password}
        disabled={vm.isProcessing}
      />
    )
  }

  const renderConfirmArchiveDialog = () => {
    return (
      <MuiConfirmPrompt
        confirmMessage={`Are you sure you would like to Archive ${vm.dialogCount} ${lz.users}?`}
        onClose={() => vm.cancelArchiveUsersConfirmDialog()}
        open={vm.archiveUsersDialogOpen}
        onConfirm={() => {
          vm.archiveUsers()
        }}
        title='Archive Users'
        disabled={vm.isProcessing}
      />
    )
  }

  const renderConfirmEnableEmailNotificationsDialog = () => {
    return (
      <MuiConfirmPrompt
        confirmMessage={`Are you sure you would like to Enable Email Notifications for ${vm.dialogCount} ${lz.users}?`}
        onClose={() => vm.cancelEnableEmailNotificationsDialog()}
        open={vm.enableEmailNotificationsDialogOpen}
        onConfirm={() => {
          vm.saveOptionToUsers()
        }}
        title='Enable Email Notifications'
        disabled={vm.isProcessing}
      />
    )
  }

  const renderConfirmDisableEmailNotificationsDialog = () => {
    return (
      <MuiConfirmPrompt
        confirmMessage={`Are you sure you would like to Disable Email Notifications for ${vm.dialogCount} ${lz.users}?`}
        onClose={() => vm.cancelDisableEmailNotificationsDialog()}
        open={vm.disableEmailNotificationsDialogOpen}
        onConfirm={() => {
          vm.saveOptionToUsers()
        }}
        title='Disable Email Notifications'
        disabled={vm.isProcessing}
      />
    )
  }

  const renderConfirmEnableTextNotificationsDialog = () => {
    return (
      <MuiConfirmPrompt
        confirmMessage={`Are you sure you would like to Enable Text Notifications for ${vm.dialogCount} ${lz.users}?`}
        onClose={() => vm.cancelEnableTextNotificationsDialog()}
        open={vm.enableTextNotificationsDialogOpen}
        onConfirm={() => {
          vm.saveOptionToUsers()
        }}
        title='Enable Text Notifications'
        disabled={vm.isProcessing}
      />
    )
  }

  const renderConfirmDisableTextNotificationsDialog = () => {
    return (
      <MuiConfirmPrompt
        confirmMessage={`Are you sure you would like to Disable Text Notifications for ${vm.dialogCount} ${lz.users}?`}
        onClose={() => vm.cancelDisableTextNotificationsDialog()}
        open={vm.disableTextNotificationsDialogOpen}
        onConfirm={() => {
          vm.saveOptionToUsers()
        }}
        title='Disable Text Notifications'
        disabled={vm.isProcessing}
      />
    )
  }

  const renderConfirmEnablePushNotificationsDialog = () => {
    return (
      <MuiConfirmPrompt
        confirmMessage={`Are you sure you would like to Enable Push Notifications for ${vm.dialogCount} ${lz.users}?`}
        onClose={() => vm.cancelEnablePushNotificationsDialog()}
        open={vm.enablePushNotificationsDialogOpen}
        onConfirm={() => {
          vm.saveOptionToUsers()
        }}
        title='Enable Push Notifications'
        disabled={vm.isProcessing}
      />
    )
  }

  const renderConfirmDisablePushNotificationsDialog = () => {
    return (
      <MuiConfirmPrompt
        confirmMessage={`Are you sure you would like to Disable Push Notifications for ${vm.dialogCount} ${lz.users}?`}
        onClose={() => vm.cancelDisablePushNotificationsDialog()}
        open={vm.disablePushNotificationsDialogOpen}
        onConfirm={() => {
          vm.saveOptionToUsers()
        }}
        title='Disable Push Notifications'
        disabled={vm.isProcessing}
      />
    )
  }

  const renderConfirmDeleteDialog = () => {
    return (
      <MuiConfirmDeleteDialog
        confirmationMessage={`Are you sure you would like to Delete ${vm.dialogCount} ${lz.users}`}
        open={vm.deleteUsersDialogOpen}
        onConfirm={() => {
          vm.deleteUsers()
        }}
        onClose={() => vm.cancelDeleteUsersConfirmDialog()}
      />
    )
  }

  const renderConfirmStartTrainingPlanFlowBeginnerDialog = () => {
    return (
      <MuiConfirmPrompt
        confirmMessage={`Are you sure you would like to Start Beginner (L1) Training Plan Flow for ${vm.dialogCount} ${lz.users}?`}
        onClose={() => vm.cancelStartTrainingPlanFlowBeginnerConfirmDialog()}
        open={vm.startTrainingPlanFlowBeginnerDialogOpen}
        onConfirm={() => {
          vm.startTrainingPlanFlowBeginner()
        }}
        title='Start Beginner (L1) Training Plan Flow'
        disabled={vm.isProcessing}
      />
    )
  }

  const renderConfirmStartTrainingPlanFlowIntermediateDialog = () => {
    return (
      <MuiConfirmPrompt
        confirmMessage={`Are you sure you would like to Start Intermediate (L2) Training Plan Flow for ${vm.dialogCount} ${lz.users}?`}
        onClose={() => vm.cancelStartTrainingPlanFlowIntermediateConfirmDialog()}
        open={vm.startTrainingPlanFlowIntermediateDialogOpen}
        onConfirm={() => {
          vm.startTrainingPlanFlowIntermediate()
        }}
        title='Start Intermediate (L2) Training Plan Flow'
        disabled={vm.isProcessing}
      />
    )
  }

  const renderConfirmStartTrainingPlanFlowAdvancedDialog = () => {
    return (
      <MuiConfirmPrompt
        confirmMessage={`Are you sure you would like to Start Optimization (OPT) Training Plan Flow for ${vm.dialogCount} ${lz.users}?`}
        onClose={() => vm.cancelStartTrainingPlanFlowIntermediateConfirmDialog()}
        open={vm.startTrainingPlanFlowAdvancedDialogOpen}
        onConfirm={() => {
          vm.startTrainingPlanFlowAdvanced()
        }}
        title='Start Optimization (OPT) Training Plan Flow'
        disabled={vm.isProcessing}
      />
    )
  }

  const renderConfirmCancelTrainingPlanFlowDialog = () => {
    return (
      <MuiConfirmPrompt
        confirmMessage={`Are you sure you would like to Cancel Training Plan Flow for ${vm.dialogCount} ${lz.users}?`}
        onClose={() => vm.cancelCancelTrainingPlanFlowConfirmDialog()}
        open={vm.cancelTrainingPlanFlowDialogOpen}
        onConfirm={() => {
          vm.cancelTrainingPlanFlow()
        }}
        title='Stop Training Plan Flow'
        disabled={vm.isProcessing}
      />
    )
  }

  return (
    <>
      {renderSpinner()}
      {renderGrid()}
      {renderNoRowsContent()}
      {renderConfirmSendWelcomeDialog()}
      {renderConfirmExpirePasswordsDialog()}
      {renderConfirmArchiveDialog()}
      {renderConfirmDeleteDialog()}
      {renderConfirmEnableEmailNotificationsDialog()}
      {renderConfirmDisableEmailNotificationsDialog()}
      {renderConfirmEnableTextNotificationsDialog()}
      {renderConfirmDisableTextNotificationsDialog()}
      {renderConfirmEnablePushNotificationsDialog()}
      {renderConfirmDisablePushNotificationsDialog()}
      {renderConfirmStartTrainingPlanFlowBeginnerDialog()}
      {renderConfirmStartTrainingPlanFlowIntermediateDialog()}
      {renderConfirmStartTrainingPlanFlowAdvancedDialog()}
      {renderConfirmCancelTrainingPlanFlowDialog()}
    </>
  )
}

export default inject('localizationStore')(observer(OrganizationUsersListTable))
