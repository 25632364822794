import { computed, action } from 'mobx'
import { OrganizationUser } from '../../organization-users/aggregate/OrganizationUser'
import { RootStore } from '../../stores/RootStore'
import { ParticipantsSelectVM } from './ParticipantsSelectVM'
import { UsersAGGridVM } from './UsersAGGridVM'

export class UserRowVM {
  private orgUser: OrganizationUser
  private rootStore: RootStore
  private selectVM: ParticipantsSelectVM
  private parentGrid: UsersAGGridVM

  constructor(
    orgUser: OrganizationUser,
    rootStore: RootStore,
    selectVM: ParticipantsSelectVM,
    parentGrid: UsersAGGridVM
  ) {
    this.orgUser = orgUser
    this.rootStore = rootStore
    this.selectVM = selectVM
    this.parentGrid = parentGrid
  }

  @computed
  public get objectId(): string {
    return this.orgUser.userId
  }

  @computed
  public get name(): string {
    if (!this.orgUser.fk_User) return ''
    if (this.orgUser.fk_User.firstName && this.orgUser.fk_User.lastName)
      return `${this.orgUser.fk_User.firstName} ${this.orgUser.fk_User.lastName}`
    return this.orgUser.fk_User.name
  }

  @computed
  public get iconURL(): string {
    return ''
  }

  @computed
  public get rolesAndGroups(): string {
    if (!this.parentGrid.hasFilter) return ''
    if (!this.orgUser.fk_roles) return ''
    return this.orgUser.fk_roles
      .sort((a, b) => {
        if (a.objectId === this.orgUser.primaryRoleId) return -1
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0
      })
      .sort((a, b) => {
        if (a.objectId === this.orgUser.primaryRoleId) return -1
        return 0
      })
      .map((e) => e.name)
      .filter((e) => {
        if (!this.parentGrid.filter) return true
        return e.toLowerCase().indexOf(this.parentGrid.filter.toLowerCase()) > -1
      })
      .join(', ')
  }

  @computed
  public get filteredGroupsAndRoles(): boolean {
    if (this.selectVM.filteredGroups.length > 0) return true
    if (this.selectVM.filteredRoles.length > 0) return true
    return false
  }

  @computed
  public get isReadOnly(): boolean {
    return this.selectVM.isReadOnly
  }

  @computed
  public get isArchived(): boolean {
    return this.orgUser.isArchived
  }

  @computed
  public get isParticipantSelected(): boolean {
    const isSelectedDirectly =
      this.selectVM.participants.filter((e) => e.id === this.objectId).length !== 0
    let isSelectedIndirectly = false
    // this.selectVM.selectedRoles.forEach((e) => {
    //   if (
    //     this.rootStore.audienceMembersStore
    //       .currentOrgUsersInRole(e.id)
    //       .find((e) => e.objectId === this.objectId)
    //   )
    //     isSelectedIndirectly = true
    // })
    // this.selectVM.selectedGroups.forEach((e) => {
    //   if (
    //     this.rootStore.audienceMembersStore
    //       .currentOrgUsersInGroup(e.id)
    //       .filter((e) => e.objectId === this.objectId).length !== 0
    //   )
    //     isSelectedIndirectly = true
    // })
    // this.selectVM.selectedEvents.forEach((e) => {
    //   if (this.selectVM.getUsersByEventGroup(e.id).find((e) => e.objectId === this.objectId))
    //     isSelectedIndirectly = true
    // })
    return isSelectedDirectly || isSelectedIndirectly
  }

  public get rowNodeId() {
    return this.orgUser.userId
  }

  @action
  public click() {
    const idx = this.selectVM.participants.findIndex((e) => e.id === this.objectId)
    if (idx === -1) {
      this.selectVM.addParticipant(this.orgUser.userId, this.orgUser.fk_User.name, 'user', this.orgUser.isArchived)
    } else {
      this.selectVM.deleteParticipant(this.objectId)
    }
  }
}
