import React from 'react'
import { inject, observer } from 'mobx-react'
import moment from 'moment-timezone'
import './SurveyOptions.scss'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { SurveysStore } from '../../../store/SurveysStore'
import {
  Grid,
  IconButton,
  InputLabel,
  Paper,
  Typography,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'
import CustomPublishGroup from './CustomPublishGroup'
import CustomPublishGroupParticipants from './CustomPublishGroupParticipants'

interface ICreateCustomPublishGroup {
  localizationStore?: LocalizationStore
  surveysStore?: SurveysStore
}

const CreateCustomPublishGroup: React.FC<ICreateCustomPublishGroup> = ({
  localizationStore,
  surveysStore,
}) => {
  const surveyEditVM = surveysStore.viewModels.editVM
  const vm = surveyEditVM.surveyOptionsVM
  return (
    <div className={'custom-publish-section'}>
      <div className='section-content'>
        <div className='primary-text'>Send to specified Participant(s) on set date/time:</div>
        <div className={'custom-publish-group'}>
          <div className={'custom-publish-group-paper'}>
            <CustomPublishGroup localizationStore={localizationStore} surveysStore={surveysStore} />
          </div>
          <div className={'custom-publish-group-items'}>
            {vm.customPublishVM
              ? vm.customPublishVM.customPublishGroups.slice().map((grp, index) => {
                  return (
                    <div key={`publish-grp-${index}`} className={'custom-publish-group-item'}>
                      <Grid container spacing={2}>
                        <Grid
                          className={'custom-publish-group-item-grid'}
                          item
                          key={`publish-grp-grid-${index}`}
                        >
                          <Paper elevation={0} className={'custom-publish-group-item-grid-paper'}>
                            <Grid
                              container
                              direction='row'
                              alignItems='center'
                              justifyContent='flex-start'
                              className={'custom-publish-group-item-grid-sub'}
                            >
                              <Grid item xs={10}>
                                <Grid
                                  container
                                  item
                                  direction='row'
                                  alignItems='flex-start'
                                  justifyContent='flex-start'
                                >
                                  <Grid item className={'custom-publish-group-item-grid-buffer'}>
                                    <Grid container direction='column' spacing={1}>
                                      <Grid item>
                                        <InputLabel shrink margin='dense'>
                                          DATE
                                        </InputLabel>
                                      </Grid>
                                      <Grid item>
                                        <Typography>
                                          {moment(grp.startDate).format('MM/DD/YYYY')}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item className={'custom-publish-group-item-grid-buffer'}>
                                    <Grid container direction='column' spacing={1}>
                                      <Grid item>
                                        <InputLabel shrink margin='dense'>
                                          TIME
                                        </InputLabel>
                                      </Grid>
                                      <Grid item>
                                        <Typography>{`${moment(grp.deliveryTime).format(
                                          'hh:mmA'
                                        )} (${moment.tz(grp.deliveryTimeZoneVal).zoneAbbr()})`}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={8}
                                    className={'custom-publish-group-item-grid-buffer'}
                                  >
                                    <Grid container direction='column' spacing={1}>
                                      <Grid item>
                                        <InputLabel shrink margin='dense'>
                                          PARTICIPANTS
                                        </InputLabel>
                                      </Grid>
                                      <Grid item>
                                      <CustomPublishGroupParticipants
                                        participantsSelectVM={grp.customPublishGroupSelectVM}
                                      />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={2}>
                                <Grid
                                  container
                                  direction='row'
                                  spacing={1}
                                  alignItems='flex-end'
                                  justifyContent='flex-end'
                                >
                                  <Grid item xs={5}>
                                    <IconButton
                                      className='custom-publish-btn'
                                      onClick={(evt) => {
                                        vm.customPublishVM.setEditing(grp)
                                        vm.customPublishVM.setCustomPublishGroupNew(false)
                                        vm.customPublishVM.editing.candidatePublishGroupSelectVM.setParticipants(vm.customPublishVM.editing.customPublishGroupSelectVM.participants)
                                        vm.customPublishVM.editing.candidatePublishGroupSelectVM.setVisible()
                                        vm.toggleCustomPublishGroupDialogOpen()
                                      }}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <IconButton
                                      className='custom-publish-btn'
                                      onClick={(evt) => {
                                        vm.customPublishVM.deleteCustomPublishGroup(grp.customPublishGroup)
                                      }}
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                      </Grid>
                    </div>
                  )
                })
              : undefined}
          </div>
          <div className={'custom-publish-rest'}>
            <div className='primary-text'>Send to everyone else on:</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default inject('localizationStore', 'surveysStore')(observer(CreateCustomPublishGroup))
