import { action, computed, observable } from 'mobx'
import { debounce } from 'lodash'
import { RootStore } from '../../stores/RootStore'
import { CMSPostTemplatesFindService } from '../service/CMSPostTemplatesFindService'
import { ICMSPostTemplateRequest } from '../interfaces/ICMSPostTemplateRequest'
import { PostTemplate } from '../aggregate/PostTemplate'
import { CMSAuthService } from '../../cms-auth/services/CMSAuthService'

export class CMSPostTemplatesStore {
  private rootStore: RootStore
  private svc: CMSPostTemplatesFindService
  private jwt: string

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.svc = new CMSPostTemplatesFindService(this.rootStore)
    this.loadData().then(() => {
      const req = {
        sort: undefined,
        pagination: undefined,
        search: undefined,
      } as ICMSPostTemplateRequest

      this.loadListRecords(req)
    })
  }

  @observable public isLoaded: boolean = false
  @observable public hasNextPage: boolean = false
  @observable public isNextPageLoading: boolean = false
  @observable.shallow private records: PostTemplate[] = []
  @observable public isRefreshing: boolean = true
  @observable public filter: string = ''

  public async loadData() {
    this.jwt = await CMSAuthService.getJWT()
  }

  @computed
  public get token(): string {
    return this.jwt
  }

  @action
  public setRecords(records: PostTemplate[]) {
    this.records = records
  }

  @action
  private debounceFn = debounce((fn: Function) => {
    this.handleDebounceFn(fn)
  }, 350)

  @action
  private handleDebounceFn(fn: Function) {
    fn()
  }

  public setFilter(val: string) {
    if (val) val = val.trim()
    this.filter = val
    const req = {
      sort: undefined,
      pagination: undefined,
      search: this.filter,
    } as ICMSPostTemplateRequest
    setTimeout(() => this.loadListRecords(req), 1000)
  }

  public async loadNextPage(req: ICMSPostTemplateRequest): Promise<boolean> {
    const loadFn = async () => {
      if (this.isNextPageLoading) return
      this.isNextPageLoading = true

      const freshRecords = await this.svc.getItems(req)
      const newRecords = freshRecords.map((record) => PostTemplate.create(record))

      this.setRecords([...this.records].concat(newRecords))
      if (newRecords.length < this.svc.DEFAULT_PAGINATION.limit) this.hasNextPage = false
      else this.hasNextPage = true

      this.onListRecordsLoaded()
    }
    this.debounceFn(loadFn)

    return true
  }

  public async loadListRecords(req: ICMSPostTemplateRequest): Promise<boolean> {
    this.isRefreshing = true
    const freshRecords = await this.svc.getItems(req)
    let newRecords = []
    if (freshRecords) newRecords = freshRecords.map((record) => PostTemplate.create(record))

    this.setRecords(newRecords ? newRecords : [])
    if (newRecords && newRecords.length < this.svc.DEFAULT_PAGINATION.limit)
      this.hasNextPage = false
    else this.hasNextPage = true

    this.onListRecordsLoaded()

    return true
  }

  @action
  public onListRecordsLoaded() {
    this.isRefreshing = false
    this.isLoaded = true
    this.isNextPageLoading = false
  }

  @computed
  public get rows() {
    return this.records
  }

  @computed
  public get totalRecords(): number {
    return this.records.length
  }
}
