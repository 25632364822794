import React from 'react'
import { observer } from 'mobx-react'
import { Avatar, Button } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import CircularProgress from '@material-ui/core/CircularProgress'
import './SubmitRow.scss'
import { UserSurveyTakeVM } from '../../view-models/UserSurveyTakeVM'
import isMobile from '../../../../utils/isMobile'

interface Props {
  takeVM: UserSurveyTakeVM
}

const SubmitRow: React.FC<Props> = ({ takeVM }) => {
  if (takeVM.isForResponse) return null
  const renderSpinner = () => {
    if (!takeVM.isSubmitting) return
    return <CircularProgress className='spinner' />
  }

  const renderButtons = () => {
    if (takeVM.isSubmitting) return
    return (
      <div>
        <Button
          variant='contained'
          className='sln-survey-submit-button'
          disabled={!takeVM.isReadyToSubmit}
          onClick={() => takeVM.submit()}
        >
          Submit
        </Button>
        &nbsp;
        <Button
          variant='text'
          className='sln-survey-reset-button'
          disabled={!takeVM.resetEnabled}
          onClick={() => takeVM.resetSurvey()}
        >
          Reset
        </Button>
      </div>
    )
  }
  const renderCheckIcon = () => {
    if (isMobile) return
    return (
      <Avatar className={takeVM.isComplete ? 'indicator active' : 'indicator'}>
        <CheckIcon />
      </Avatar>
    )
  }
  
  const blurredClass = takeVM.lastQuestion && takeVM.lastQuestion.blurred ? 'blurred' : ''

  const getMobileClassNameForRow = () => {
    if (!isMobile) return ''
    else if (isMobile && blurredClass === '') return 'is-mobile'
    return ' is-mobile'
  }

  const getMobileClassNameForDivider = () => {
    if (!isMobile) return ''
    return ' is-mobile'
  }

  return (
    <div id='SubmitRow' className={`${blurredClass}${getMobileClassNameForRow()}`}>
      {renderCheckIcon()}
      <div className={`hr${getMobileClassNameForDivider()}`} />
      {renderButtons()}
      {renderSpinner()}
    </div>
  )
}

export default observer(SubmitRow)
