import { action, computed, observable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { ParseService } from '../../services/ParseService'
import Parse from 'parse'
import { EditScreenVM } from './EditScreenVM'
import env from '../../../env'

export class ImportWeightProfileVM {
  rootStore: RootStore
  editVM: EditScreenVM

  constructor(rootStore: RootStore, editVM: EditScreenVM) {
    this.rootStore = rootStore
    this.editVM = editVM
  }

  @observable public file: File = null
  @observable public importProcessing: boolean = false


  public handleFileDrop(file) {
    this.file = file[0]
    return
  }

  @computed
  public get saveEnabled() {
    if (this.file) return true
    return false
  }

  @action
  public handleTemplateDownload() {
    window.location.href = `${env.var.REACT_APP_API_URL}/exportWeightProfilesToCsv?orgId=${this.rootStore.appStore.currentOrgId}`
  }

  @action
  public async save() {
    try {
      this.importProcessing = true

      const fileName = this.file.name
      const orgId = this.rootStore.appStore.currentOrgId
      const newFile = await new Parse.File(fileName, this.file, 'text/csv')
      newFile.setTags({ organizationId: orgId })
      const parseFile = await newFile.save()

      const svc = new ParseService()
      await svc.processWeightProfilesFile(this.rootStore.appStore.currentOrgId, parseFile)

      // this.editVM.roles.forEach(role => {
      //   role.loadWeightProfileVM()
      // })

      this.editVM.toggleImportDialog()
      this.importProcessing = false
      this.file = null
    } catch (e) {
      console.error(e)
      return false
    }
  }
}
