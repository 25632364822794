import { action, observable, computed, reaction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { CategoryPickerVM } from '../../shared/category-picker/CategoryPickerVM'
import { ParticipantsSelectVM } from '../../participants-select/view-models/ParticipantsSelectVM'
import { TrainingPlansUpdateService } from '../service/TrainingPlansUpdateService'
import { TrainingPlan } from '../aggregates/TrainingPlan'
import { MediaItemsService } from '../../media-items/service/MediaItemsService'
import { ParticipantDueDateVM } from './ParticipantDueDateVM'
import { ParticipantDueDate } from '../aggregates/ParticipantDueDate'
import { TrainingPlanItem } from '../aggregates/TrainingPlanItem'
import { TrainingPlanItemVM } from './TrainingPlanItemVM'
import { TrainingItem } from '../../training-items/aggregate/TrainingItem'
import { TrainingItemCardVM } from './TrainingItemCardVM'
import { UserTrainingPlanTakeVM } from '../user-training-plans/view-models/UserTrainingPlanTakeVM'
import { UserTrainingPlan } from '../user-training-plans/aggregate/UserTrainingPlan'
import { TabEnums } from '../types/TabsEnums'
import moment from 'moment'
import { AudienceMember } from '../aggregates/AudienceMember'
import { QuizVM } from '../../quiz/view-models/QuizVM'
import { Goal } from '../../goals/aggregate/Goal'
import GoalPreviewVM from './GoalPreviewVM'
import { IApplicableParticipantDTO } from '../../training-items/dto/IApplicableParticipantDTO'
import { IParticipant } from '../../participants-select/interfaces/IParticipant'
import { Category } from '../../categories/aggregate/Category'
import { IGoalDTO } from 'src/app/goals/dto/IGoalDTO'
import { Attachment } from '../../upload/aggregate/Attachment'

export class TrainingPlanEditVM {
  private rootStore: RootStore
  public trainingPlan: TrainingPlan
  public planCategoryPickerVM: CategoryPickerVM
  public svc: TrainingPlansUpdateService

  constructor(rootStore: RootStore, trainingPlan: TrainingPlan) {
    console.log({ initTrainingPlan: trainingPlan })
    this.rootStore = rootStore
    this.trainingPlan = trainingPlan
    this.drawerCategoryFilterVM = new CategoryPickerVM(this.rootStore, true, true, false)
    this.planCategoryPickerVM = new CategoryPickerVM(this.rootStore)
    this.participantsSelectVM = new ParticipantsSelectVM(this.rootStore, false, false)
    this.trainerSelectVM = new ParticipantsSelectVM(
      this.rootStore,
      false,
      false,
      false,
      false,
      false,
      false,
      false
    )

    this.traineeSelectVM = new ParticipantsSelectVM(
      this.rootStore,
      false,
      false,
      true,
      true,
      false,
      false,
      false
    )
    this.svc = new TrainingPlansUpdateService()
    this.loadVMData(trainingPlan)
    this.savedActiveDate = trainingPlan.activeDate

    reaction(
      () => JSON.stringify(this.trainingPlan),
      () => this.setIsDirty()
    )
    setTimeout(() => {
      reaction(
        () => this.participantsSelectVM.participants.length,
        () => {
          this.setIsDirty()
        }
      )
    }, 4000)

    reaction(
      () => this.participantsSelectVM.participants.length,
      (val, previousVal) => this.updateParticipantDueDatesArray()
    )

    if (trainingPlan.objectId) {
      this.participantDueDates = trainingPlan.participantDueDates
    }
    if (trainingPlan.categories) {
      this.planCategoryPickerVM.selectedCategoryIds = trainingPlan.categories
      this.planCategoryPickerVM.setAppliedCategories()
    }
    if (this.trainingPlan.applicableParticipants) {
      this.selectedRolesAndGroups = this.trainingPlan.applicableParticipants
    }

    if (trainingPlan.isCatalogPlan && trainingPlan.objectId) this.addToCatalog = false
  }

  private async loadVMData(trainingPlan: TrainingPlan) {
    if (this.rootStore.audienceMembersStore)
      await this.rootStore.audienceMembersStore.loadAudienceMembers()
    setTimeout(() => {
      this.participantsSelectVM.setParticipants(trainingPlan.assignedToAudienceMembers)
      this.participantsSelectVM.setVisible()
    }, 500)
  }

  @observable public currentTab: number = 0
  @observable public selectedRolesAndGroups: IApplicableParticipantDTO[] = []
  @observable public participantsSelectVM: ParticipantsSelectVM = null
  @observable public trainerSelectVM: ParticipantsSelectVM = null
  @observable public traineeSelectVM: ParticipantsSelectVM = null
  @observable public builderDrawerOpen: boolean = true
  @observable public trainingItemsFilter: string = ''
  @observable public newIndex: number = 0
  @observable public oldIndex: number = 0
  @observable public saveRequested: boolean = false
  @observable public traineeSelectVMOpen: boolean = false
  @observable public trainerSelectVMOpen: boolean = false
  @observable public traineeMembers: AudienceMember[] = []
  @observable public saveProcessing: boolean = false
  @observable public previewVM: UserTrainingPlanTakeVM = null
  @observable public goalsModalOpen: boolean = false
  @observable public stagedGoalId: string = ''
  @observable public goalToPreview: GoalPreviewVM = null
  @observable public isDirty: boolean = false
  @observable public addToCatalog: boolean = false
  @observable public drawerCategoryFilterVM: CategoryPickerVM = null
  @observable public isSnackbarOpen: boolean = false
  @observable public itemModalOpen: boolean = false
  @observable public menuAnchorEl: any = null
  @observable public saveProgress: number = 0
  @observable public confirmArchiveDialogOpen: boolean = false
  @observable public showDeleteDialog: boolean = false
  @observable public participantDueDates: ParticipantDueDate[] = []
  @observable public savedActiveDate: string = ''
  @observable public tabOneHasOpened: boolean = false
  @observable public tabTwoHasOpened: boolean = false
  @observable public tabThreeHasOpened: boolean = false
  @observable public tabFourHasOpened: boolean = false
  @observable public itemConfirmDialogOpen: boolean = false
  @observable public showThumbnailUploadModal: boolean = false
  @observable public snackbarMessage: string = ''

  @action
  public toggleItemConfirmDialogOpen() {
    if (!this.rootStore.trainingItemsStore.trainingItemEditVM.isDirty) {
      this.toggleItemModalOpen()
    } else {
      this.itemConfirmDialogOpen = !this.itemConfirmDialogOpen
    }
  }

  @computed
  public get canCreateGoals(): boolean {
    if (!this.rootStore.appStore.canCreateGoals) return false
    if (
      this.rootStore.organizationsStore.currentOrganization &&
      !this.rootStore.organizationsStore.currentOrganization.isGoalsEnabled
    )
      return false
    return true
  }

  @computed
  public get canManageTrainingPlanFlows(): boolean {
    if (!this.rootStore.appStore.canManageTrainingPlanFlows) return false
    if (
      this.rootStore.organizationsStore.currentOrganization &&
      !this.rootStore.organizationsStore.currentOrganization.hasTrainingPlanFlows
    )
      return false
    return true
  }

  @action
  public toggleConfirmDialogOpen() {
    this.confirmArchiveDialogOpen = !this.confirmArchiveDialogOpen
  }

  @computed
  public get archiveMessage(): string {
    if (this.isArchived) {
      return 'Do you want to restore this archived plan and all associated user plans?'
    } else {
      return 'Do you want to archive this plan? All associated user plans will be archived as well'
    }
  }

  @computed
  public get editIsDisabled(): boolean {
    if (this.isCatalogPlan) return false
    const isOnParticipantsTab = this.currentTab === TabEnums.PARTICIPANTS
    if (this.objectId && !isOnParticipantsTab) return true
    return false
  }

  @computed
  public get isNew(): boolean {
    return Boolean(!this.trainingPlan.objectId)
  }

  @computed
  public get isArchived(): boolean {
    return this.trainingPlan.isArchived
  }

  @computed
  public get isForAutomatedTraining(): boolean {
    return this.trainingPlan.isForAutomatedTraining
  }

  @computed
  public get selectedAssociatedGoalId(): string {
    return this.trainingPlan.associatedCatalogGoalId
  }

  @computed
  public get saveDisabled(): boolean {
    if (this.currentTab === TabEnums.BUILDER && this.trainingPlanItems.length === 0) return true
    return false
  }

  @computed
  public get selectedGoal(): Goal {
    const id =
      !this.stagedGoalId && this.selectedAssociatedGoalId
        ? this.selectedAssociatedGoalId
        : this.stagedGoalId
    return this.rootStore.goalsCatalogStore.getCatalogGoal(id)
  }

  @computed
  public get isCatalogPlan(): boolean {
    return this.trainingPlan.isCatalogPlan
  }

  @computed
  public get goalAssociationDisabled(): boolean {
    if (this.objectId && this.associatedGoalId) return true
    return false
  }

  // @computed
  // public get planDisabled(): boolean {
  //   if (!this.isCatalogPlan && this.objectId) return true
  //   return false
  // }

  @computed
  public get availableGoals(): Goal[] {
    return this.rootStore.goalsCatalogStore.currentOrgCatalogGoals.filter(
      (g) => this.selectedAssociatedGoalId !== g.objectId
    )
  }

  @action
  public createNewGoal() {
    const win = window.open('/#/goals/edit/new', '_blank')
    if (win !== null) {
      win.focus()
    }
  }

  @action
  public closeSnackbar() {
    this.isSnackbarOpen = false
  }

  @action
  public openSnackbar(msg: string) {
    this.snackbarMessage = msg
    this.isSnackbarOpen = true
  }

  @action
  public openGoalsModal() {
    this.goalsModalOpen = true
  }

  @action
  public closeGoalsModal() {
    this.goalsModalOpen = false
  }

  @action
  public cancelAssociateGoals() {
    this.closeGoalsModal()
    if (!this.associatedGoal) this.trainingPlan.setAssociateToGoals(false)
    this.stagedGoalId = ''
    // this.associateGoalToTrainingPlan(this.stagedGoalId)
  }

  @action
  public saveAssociatedGoals() {
    // if (this.trainingPlan.associatedGoalId) {

    if (this.stagedGoalId) this.associateGoalToTrainingPlan(this.stagedGoalId)
    // } else {
    // this.trainingPlan.setAssociateToGoals(false)
    // }
    this.closeGoalsModal()
    // this.stagedGoalId = ''
  }

  @action
  public associateGoalToTrainingPlan(id: string) {
    this.trainingPlan.setAssociateToGoals(true)
    this.trainingPlan.setAssociatedGoalId(id)
  }

  @action
  public setStagedGoalId(id: string) {
    this.stagedGoalId = id
  }

  @action
  public toggleItemModalOpen() {
    this.itemModalOpen = !this.itemModalOpen
    if (this.itemConfirmDialogOpen) this.itemConfirmDialogOpen = false
  }

  @action
  public setGoalToPreview(goal: Goal) {
    this.goalToPreview = new GoalPreviewVM(this.rootStore, this, goal)
  }

  @action
  public clearPreviewPlan() {
    this.goalToPreview = null
  }

  @action
  public setAnchorEl(e: any) {
    this.menuAnchorEl = e
  }

  @action
  public refresh() {
    if (!this.isCatalogPlan) {
      this.rootStore.trainingPlansStore.editTrainingPlan(this.objectId)
    } else {
      this.rootStore.trainingPlansCatalogStore.editCatalogTrainingPlan(this.objectId)
    }
  }

  @action
  public async archivePlan() {
    if (this.isArchived) {
      await this.svc.restoreTrainingPlan(this.rootStore.appStore.currentOrgId, this.objectId)
    } else {
      await this.svc.archiveTrainingPlan(this.rootStore.appStore.currentOrgId, this.objectId)
    }
    this.confirmArchiveDialogOpen = false
  }

  @action
  public loadPreviewVM() {
    console.log('load preview')
    console.log({ clone: this.trainingPlan.clone(), quiz: this.trainingPlan.quiz })
    this.previewVM = new UserTrainingPlanTakeVM(
      this.rootStore,
      UserTrainingPlan.createForPreview(
        this.trainingPlan.clone(),
        this.rootStore.appStore.currentOrgId,
        this.rootStore.appStore.currentUserId
      ),
      false
    )
  }

  @computed
  public get quiz(): QuizVM {
    return new QuizVM(this.rootStore, this.trainingPlan.quiz, this.editIsDisabled)
  }

  @computed
  public get objectId(): string {
    return this.trainingPlan.objectId
  }

  @computed
  public get title(): string {
    return this.trainingPlan.title
  }

  @computed
  public get description(): string {
    return this.trainingPlan.description
  }

  @computed
  public get program(): string {
    return this.trainingPlan.program
  }

  @computed
  public get thumbnail(): string {
    return this.trainingPlan.thumbnail
  }

  @computed
  public get thumbnailFileName(): string {
    return this.trainingPlan.thumbnailFileName
  }

  @computed
  public get isRecurring(): boolean {
    return this.trainingPlan.isRecurring
  }

  @computed
  public get activeDate(): Date {
    return moment(this.trainingPlan.activeDate).toDate()
  }

  @computed
  public get dueDate(): Date {
    return moment(this.trainingPlan.dueDate).toDate()
  }

  @computed
  public get dueDateValid(): boolean {
    if (moment(this.activeDate).isSameOrBefore(this.dueDate)) return true
    if (
      this.dueDateType === 'variesByParticipant' &&
      !this.participantDueDateVMs.some((e) => moment(this.activeDate).isAfter(e.dueDate))
    )
      return true
    return false
  }

  @computed
  public get dueDateType(): string {
    if (this.trainingPlan.dueDateSameForAll) return 'sameForAll'
    if (this.trainingPlan.dueDateVariesByUser) return 'variesByParticipant'
    if (this.trainingPlan.dueDateVariesByTrainingItem) return 'variesByTrainingItem'
  }

  @computed
  public get showQuizResults(): boolean {
    return this.trainingPlan.showQuizResults
  }

  @computed
  public get trainer(): AudienceMember {
    return this.trainingPlan.trainer
  }

  @computed
  public get associatedGoalId(): string {
    return this.trainingPlan.associatedGoalId
  }

  @computed
  public get associatedCatalogGoalId(): string {
    return this.trainingPlan.associatedCatalogGoalId
  }

  @computed
  public get associatedGoal(): IGoalDTO {
    if (!this.associatedCatalogGoalId) return null
    return this.rootStore.goalsCatalogStore.getCatalogGoal(this.associatedCatalogGoalId)
  }

  @computed
  public get trainee(): AudienceMember {
    return this.trainingPlan.trainee
  }

  @computed
  public get variesByParticipantDisabled(): boolean {
    return this.participantDueDates.length === 0
  }

  @computed
  public get availableRolesAndGroups() {
    return this.participantsSelectVM.availableParticipants
      .filter(
        (participant) =>
          participant.id !== this.rootStore.appStore.currentUserId &&
          participant.type !== 'user' &&
          participant.type !== 'client' &&
          participant.type !== 'from-event'
      )
      .sort((a, b) => (a.sortNameWithType < b.sortNameWithType ? -1 : 0))
  }

  @action
  public updateParticipantDueDatesArray() {
    const participantIds = this.participantsSelectVM.participants.map(
      (participant) => participant.id
    )
    const dueDatesIds = this.participantDueDates.map((dueDate) => dueDate.participantId)

    participantIds.forEach((id) => {
      if (!dueDatesIds.includes(id)) {
        this.participantDueDates.push(ParticipantDueDate.create(id))
      }
    })

    dueDatesIds.forEach((id) => {
      if (!participantIds.includes(id)) {
        const index = this.participantDueDates.findIndex((dueDate) => dueDate.participantId === id)
        this.participantDueDates.splice(index, 1)
      }
    })
  }

  @computed
  public get participantDueDateVMs(): ParticipantDueDateVM[] {
    return this.participantDueDates.map(
      (participant) => new ParticipantDueDateVM(this.rootStore, participant, this)
    )
  }

  @computed
  public get requireProofOfCompletion(): boolean {
    return this.trainingPlan.requireProofOfCompletion
  }

  @computed
  public get requireQuiz(): boolean {
    return this.trainingPlan.requireQuiz
  }

  @computed
  public get requireCertificate(): boolean {
    return this.trainingPlan.requireCertificate
  }

  @computed
  public get mustCompleteInOrder(): boolean {
    return this.trainingPlan.mustCompleteInOrder
  }

  @computed
  public get orgTrainingItems(): TrainingItemCardVM[] {
    return this.rootStore.trainingItemsStore.orgTrainingItems.map(
      (item) => new TrainingItemCardVM(this.rootStore, item, this)
    )
  }

  @computed
  public get trainingItems(): TrainingItemCardVM[] {
    let items = this.orgTrainingItems
    items = items.filter((item) => {
      let foundCat = false
      item.categories.forEach((cat) => {
        if (this.drawerCategoryFilterVM.selectedCategoryIds.includes(cat)) foundCat = true
      })
      return foundCat
    })
    if (this.drawerCategoryFilterVM.selectAllChecked) items = this.orgTrainingItems
    if (
      !this.drawerCategoryFilterVM.selectAllChecked &&
      this.drawerCategoryFilterVM.selectedCategoryIds.length === 0
    )
      return []

    if (this.trainingItemsFilter) {
      items = items.filter((item) =>
        item.title.toLowerCase().includes(this.trainingItemsFilter.toLowerCase())
      )
    }
    const unSelected = (item) => item.isSelected === false
    items = items.filter(unSelected)
    return items
  }

  @computed
  public get availableCategories(): Category[] {
    return this.rootStore.categoriesStore.currentOrgCategories
  }

  @action
  public toggleRequireCompletion() {
    this.trainingPlan.toggleRequireCompletion()
    if (!this.trainingPlan.requireProofOfCompletion) {
      this.trainingPlan.requireQuiz = false
      this.trainingPlan.requireCertificate = false
    }
  }

  @action
  public toggleMustCompleteInOrder() {
    this.trainingPlan.toggleMustCompleteInOrder()
  }

  @action
  public toggleRequireQuiz() {
    this.trainingPlan.toggleRequireQuiz()
    if (this.requireQuiz && !this.trainingPlan.quiz) {
      this.trainingPlan.quiz = this.quiz.quiz
    }
  }

  @action
  public toggleRequireCertificate() {
    this.trainingPlan.toggleRequireCertificate()
  }

  @computed
  public get trainingPlanItems(): TrainingPlanItem[] {
    return this.trainingPlan.trainingPlanItems
  }

  @computed
  public get isForPeerTraining(): boolean {
    return this.trainingPlan.isForPeerTraining
  }

  @computed
  public get trainingPlanItemVMs(): TrainingPlanItemVM[] {
    return this.trainingPlanItems.map(
      (trainingPlanItem) => new TrainingPlanItemVM(this.rootStore, trainingPlanItem, this)
    )
  }

  @action
  public setIsDirty() {
    this.isDirty = true
  }

  @action
  public toggleTrainerSelectOpen() {
    if (this.editIsDisabled) return
    this.trainerSelectVMOpen = !this.trainerSelectVMOpen

    if (this.trainerSelectVMOpen) {
      this.trainerSelectVM = new ParticipantsSelectVM(
        this.rootStore,
        false,
        false,
        false,
        false,
        false,
        false,
        false
      )
      setTimeout(() => (this.trainerSelectVM.setVisible(), 2000))
      if (this.trainer) this.trainerSelectVM.setParticipants([this.trainer])
    }
    // } else {
    //   setTimeout(() => (this.trainerSelectVM = null), 1000)
    // }
    // if (this.trainerSelectVMOpen) {
    //   this.trainerSelectVM.setVisible()
    //   console.log(this.trainerSelectVM)
    // } else {
    //   this.trainerSelectVM.setHidden()
    //   console.log('hide')
    //   console.log(this.trainerSelectVM)
    // }

    // if (this.trainer && this.trainerSelectVMOpen) {
    //   this.trainerSelectVM.setParticipants([this.trainer])
    // }
    // if (!this.trainer && !this.trainerSelectVMOpen) {
    //   this.trainerSelectVM.participants = []
    // }
  }

  @action
  public toggleTraineeSelectOpen() {
    this.traineeSelectVMOpen = !this.traineeSelectVMOpen
    if (this.traineeSelectVMOpen) this.traineeSelectVM.setVisible()
    if (!this.traineeSelectVMOpen) this.traineeSelectVM.setHidden()
  }

  @action
  public setTrainer() {
    if (this.trainerSelectVM.participants.length === 0) {
      this.trainingPlan.setTrainer(null)
    } else {
      const trainer = AudienceMember.createFromParticipantSelect(
        this.trainerSelectVM.participants[0].toDTO()
      )
      this.trainingPlan.setTrainer(trainer)
    }
    this.toggleTrainerSelectOpen()
  }

  @action
  public setTrainee() {
    if (this.traineeSelectVM.participants.length === 0) {
      this.trainingPlan.setTrainee(null)
      this.participantsSelectVM.setParticipants(null)
      this.toggleTraineeSelectOpen()
    } else {
      const trainee = AudienceMember.createFromParticipantSelect(
        this.traineeSelectVM.participants[0].toDTO()
      )
      this.trainingPlan.setTrainee(trainee)
      this.participantsSelectVM.setParticipants(this.traineeSelectVM.participants as IParticipant[])
      if (this.participantsSelectVM.participants[0].explodable)
        this.participantsSelectVM.explodeParticipant(this.participantsSelectVM.participants[0].id)
      this.toggleTraineeSelectOpen()
    }
  }

  @action
  public moveTrainingItem() {
    this.trainingPlan.moveTrainingPlanItem(this.newIndex, this.oldIndex)
  }

  @action
  public setNewIndex(value) {
    this.newIndex = value
  }

  @action
  public setOldIndex(value) {
    this.oldIndex = value
  }

  @action
  public toggleBuilderDrawerOpen() {
    this.builderDrawerOpen = !this.builderDrawerOpen
  }

  @action
  public createNewTrainingItem() {
    this.rootStore.trainingItemsStore.createNewTrainingItem()
    this.toggleItemModalOpen()
  }

  @action
  public setSelectedRolesAndGroups(arr: IApplicableParticipantDTO[]) {
    this.selectedRolesAndGroups = arr
    this.trainingPlan.setRolesAndGroups(arr)
  }

  @action
  public setTitle(val: string) {
    this.trainingPlan.setTitle(val)
  }

  @action
  public toggleAddToCatalog() {
    this.addToCatalog = !this.addToCatalog
  }

  @action
  public toggleIsForAutomatedTraining() {
    this.trainingPlan.toggleIsForAutomatedTraining()
  }

  @action
  public toggleShowQuizResults() {
    this.trainingPlan.toggleShowQuizResults()
  }

  @action
  public setFilter(val: string) {
    this.trainingItemsFilter = val
  }

  @action
  public setDescription(val: string) {
    this.trainingPlan.setDescription(val)
  }

  @action
  public setProgram(val: string) {
    this.trainingPlan.setProgram(val)
  }

  @action
  public setCategories(val: string[]) {
    this.trainingPlan.setCategories(val)
  }

  @action
  public removeCategory(id: string) {
    this.trainingPlan.removeCategory(id)
  }

  @action
  public setActiveDate(date: Date) {
    this.trainingPlan.setActiveDate(date)
  }

  @action
  public setActiveTime(hour: number, minute: number) {
    const newDate = moment(this.trainingPlan.activeDate).hour(hour).minute(minute)
    this.setActiveDate(newDate.toDate())
  }

  @action
  public setDueTime(hour: number, minute: number) {
    const newDate = moment(this.trainingPlan.dueDate).hour(hour).minute(minute)
    this.setDueDate(newDate.toDate())
  }

  @action
  public addTrainingItem(trainingItem: TrainingItem) {
    const trainingPlanItem = TrainingPlanItem.create(trainingItem.clone(), new Date())
    if (trainingPlanItem.requireQuiz) {
      if (trainingPlanItem.quiz) trainingPlanItem.quiz.createNewId()
    }
    this.trainingPlan.addTrainingPlanItem(trainingPlanItem)
  }

  @action
  public removeTrainingItem(id: string) {
    this.trainingPlan.removeTrainingItem(id)
  }

  @action
  public setDueDateType(val) {
    if (val === 'sameForAll') this.trainingPlan.setDueDateSameForAll()
    if (val === 'variesByParticipant') this.trainingPlan.setDueDateVariesByParticipant()
    if (val === 'variesByTrainingItem') this.trainingPlan.setDueDateVariesByTrainingItem()
  }

  @action
  public setDueDate(val: Date) {
    this.trainingPlan.setDueDate(val)
  }

  @action
  public continue() {
    if (this.tabsDisabled && this.currentTab === 1) return
    if (this.currentTab === TabEnums.PARTICIPANTS) this.loadPreviewVM()
    // if (this.currentTab === TabEnums.DETAILS && this.objectId) this.currentTab = this.currentTab + 2
    if (this.currentTab === 2 && this.isCatalogPlan) {
      this.currentTab = this.currentTab + 2
      this.tabValidation()
    } else if (this.currentTab === TabEnums.PREVIEW) {
      this.save()
    } else {
      this.currentTab++
      this.tabValidation()
    }
  }

  @action
  public tabValidation() {
    if (this.currentTab === 1) {
      this.tabOneHasOpened = true
    }
    if (this.currentTab === 2) {
      this.tabTwoHasOpened = true
    }
    if (this.currentTab === 3) {
      this.tabThreeHasOpened = true
      this.quiz.checkQuizErrors()
    }
    if (this.currentTab === 4) {
      if (this.isCatalogPlan) {
        this.tabThreeHasOpened = true
        this.quiz.checkQuizErrors()
      }
      this.tabFourHasOpened = true
    }
  }

  @action
  public setCurrentTab(val: number) {
    if (this.tabsDisabled) {
      if (val === 2 || val === 3 || val === 4)
        return this.openSnackbar('Please select at least one training item to continue.')
    }
    if (val > TabEnums.BUILDER) this.loadPreviewVM()
    this.currentTab = val
    this.tabValidation()
  }

  @action
  public toggleMarkAsPeerTraining() {
    this.trainingPlan.toggleMarkAsPeerTraining()
    if (!this.isForPeerTraining) {
      this.trainingPlan.setTrainee(null)
      this.trainingPlan.setTrainer(null)
      this.trainerSelectVM.participants = []
    }
    if (this.isForPeerTraining) this.toggleTrainerSelectOpen()
  }

  @action
  public toggleIsRecurring() {
    this.trainingPlan.toggleIsRecurring()
  }

  @computed
  public get titleValid(): boolean {
    if (!this.title && this.tabOneHasOpened) return false
    return true
  }

  @computed
  public get categoriesValid(): boolean {
    // making categories not required... just commenting this out for now
    // if (this.trainingPlan.categories.length === 0 && this.tabOneHasOpened) return false
    return true
  }

  @computed
  public get groupsAndRolesValid(): boolean {
    // making groups/roles not required... just commenting this out for now
    // if (this.trainingPlan.applicableParticipants.length === 0 && this.saveRequested) return false
    return true
  }

  @computed
  public get proofOfCompletionValid(): boolean {
    if (this.trainingPlan.requireProofOfCompletion && this.tabThreeHasOpened) {
      if (this.trainingPlan.requireCertificate) return true
      if (this.trainingPlan.requireQuiz) return true
      return false
    }
    return true
  }

  @computed
  public get tabsDisabled(): boolean {
    if (!this.trainingPlanItems) return true
    if (this.trainingPlanItems.length === 0) return true
    return false
  }

  @computed
  public get trainerValid(): boolean {
    if (!this.tabOneHasOpened) return true
    if (!this.trainer && this.isForPeerTraining) return false
    return true
  }

  @computed
  public get detailsTabValid(): boolean {
    if (!this.trainerValid) return false
    if (!this.titleValid) return false
    if (!this.groupsAndRolesValid) return false
    return true
  }

  @computed
  public get trainingPlanItemsValid(): boolean {
    if (this.trainingPlanItems.length === 0 && this.tabTwoHasOpened) return false
    return true
  }

  @computed
  public get builderTabValid(): boolean {
    if (!this.trainingPlanItemsValid) return false
    return true
  }

  @computed
  public get quizValid(): boolean {
    // if (this.isCatalogPlan) {
    //   if (!this.tabFourHasOpened) return true
    // }
    if (!this.proofOfCompletionValid) return false
    if (!this.requireQuiz) return true
    if (!this.tabThreeHasOpened) return true
    let isValid = true
    this.quiz.questions.forEach((question) => {
      if (question.titleError || question.optionsError) isValid = false
    })
    return isValid
  }

  @computed
  public get assignTabValid(): boolean {
    if (!this.tabFourHasOpened) return true
    if (!this.dueDateValid) return false
    if (this.isCatalogPlan) return true
    if (this.addToCatalog) return true
    if (this.participantsSelectVM.participants.length === 0) return false
    return true
  }

  @computed
  public get isForCatalog(): boolean {
    if (this.isCatalogPlan) return true
    if (this.addToCatalog) return true
    return false
  }

  @computed
  public get saveValid(): boolean {
    if (!this.builderTabValid) return false
    if (!this.trainingPlanItemsValid) return false
    if (!this.detailsTabValid) return false
    if (!this.quizValid) return false
    if (!this.assignTabValid) return false
    return true
  }

  @computed
  public get completionTabCompleted(): boolean {
    if (this.quizValid && this.isCatalogPlan && this.tabFourHasOpened) return true
    if (this.quizValid && this.tabThreeHasOpened) return true
    return false
  }

  @action
  public async generateSignature(callback: Function, paramsToSign: object): Promise<void> {
    const svc = new MediaItemsService()
    const signature = await svc.getCloudinarySignature(paramsToSign)
    callback(signature)
  }

  @action
  public toggleShowThumbnailModal() {
    this.showThumbnailUploadModal = !this.showThumbnailUploadModal
  }

  @action
  public setThumbnail(attachments: Attachment[]) {
    this.trainingPlan.setThumbnail(attachments[0])
  }

  @action
  public async save() {
    this.saveRequested = true
    if (this.requireQuiz) this.quiz.checkQuizErrors()
    if (!this.saveValid) return
    this.saveProcessing = true
    this.saveProgress = 33
    if (this.isForCatalog) await this.saveCatalogPlan()
    this.saveProgress = 66
    if (this.participantsSelectVM.participants.length > 0) await this.createTrainingPlan()
    this.saveProgress = 100
    if (this.isCatalogPlan) {
      if (this.isNew) this.openSnackbar('Success! Catalog Plan has been created.')
      else this.openSnackbar('Success! Catalog Plan has been updated.')
    } else {
      if (this.objectId) this.openSnackbar('Success! Training Plan has been update.')
      else this.openSnackbar('Success! Training Plan has been created.')
    }
    this.isDirty = false
    this.routeToDash()
    this.saveProcessing = false
  }

  @action
  public routeToDash() {
    if (this.isCatalogPlan) {
      setTimeout(() => this.rootStore.appStore.router.push('/dashboard/impact/catalog'), 1000)
    } else {
      setTimeout(() => this.rootStore.appStore.router.push('/dashboard/impact/trainings'), 1000)
    }
  }

  @action
  public async createTrainingPlan() {
    this.trainingPlan.setParticipantDueDates(this.participantDueDates)
    const dto = this.trainingPlan.serialize()
    if (!dto.requireQuiz) dto.quiz = null
    dto.assignedToAudienceMembers = this.participantsSelectVM.participantsToDTO()
    await this.svc.saveTrainingPlan(dto)
  }

  @action
  public async saveCatalogPlan() {
    if (this.objectId && this.isDirty) this.trainingPlan.updateVersion()
    const dto = this.trainingPlan.serialize()
    if (!dto.requireQuiz) dto.quiz = null
    dto.isCatalogPlan = true
    await this.svc.saveCatalogPlan(dto)
  }

  @action
  public delete() {
    if (!this.objectId) return
    if (this.isCatalogPlan) {
      this.deleteCatalogPlan()
    } else {
      this.deleteTrainingPlan()
    }
  }

  @action
  public deleteTrainingPlan() {
    this.rootStore.appStore.router.push('/dashboard/impact/trainings')
    this.svc.deleteTrainingPlan(this.rootStore.appStore.currentOrgId, this.objectId)
  }

  @action
  public deleteCatalogPlan() {
    this.rootStore.trainingPlansCatalogStore.deleteCatalogTrainingPlan(this.objectId)
    this.rootStore.appStore.router.push('/dashboard/impact/catalog')
    this.svc.deleteCatalogPlan(this.objectId, this.rootStore.appStore.currentOrgId)
  }

  @action
  public toggleDeleteDialog() {
    this.setAnchorEl(null)
    this.showDeleteDialog = !this.showDeleteDialog
  }
}
