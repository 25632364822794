import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { EventsStore } from '../../store/EventsStore'
import { Grid, InputLabel, OutlinedInput } from '@material-ui/core'

interface ICalendarEventDrawerTitle {
  localizationStore?: LocalizationStore
  eventsStore?: EventsStore
}

const CalendarEventDrawerTitle: React.FC<ICalendarEventDrawerTitle> = ({
  localizationStore,
  eventsStore,
}) => {
  const { lzStrings } = localizationStore
  const { editVM: vm } = eventsStore

  return (
    <Grid item xs={12} className='calendar-event-drawer-title-grid'>
      <InputLabel required htmlFor='eventTitle'>
        {lzStrings.calendarEventDrawer.title}
      </InputLabel>
      <OutlinedInput
        id='eventTitle'
        disabled={vm.addNewType}
        fullWidth
        labelWidth={0}
        value={vm.title}
        error={!vm.titleValid}
        onChange={(e) => vm.setTitle(e.target.value)}
        required
      />
    </Grid>
  )
}

export default inject('localizationStore', 'eventsStore')(observer(CalendarEventDrawerTitle))
