import { Checkbox, FormControlLabel, Grid, InputLabel, TextField } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { LabelsStore } from 'src/app/labels/store/LabelsStore'
import InfoTooltip from '../../../shared/InfoTooltip'
import { OrganizationsSAStore } from '../../store/OrganizationsSAStore'
import OrganizationAdditionalFileTypes from './OrganizationAdditionalFileTypes'
import OrganizationAnalysis from './OrganizationAnalysis'
import './OrganizationEditDrawer.scss'
import OrganizationFeatures from './OrganizationFeatures'
import OrganizationModules from './OrganizationModules'

interface Props {
  organizationsSAStore?: OrganizationsSAStore
  labelsStore?: LabelsStore
}

const OrganizationSettingsTab: React.FC<Props> = ({ organizationsSAStore, labelsStore }) => {
  const { editVM: vm } = organizationsSAStore
  if (!vm) return null

  const renderTaskSettings = () => {
    return (
      <>
        <InputLabel className='details-info-label'>Task Settings:</InputLabel>
        <FormControlLabel
          key='Discipline'
          htmlFor='discipline'
          onClick={() => vm.toggleAllowDiscipline()}
          control={<Checkbox color='primary' checked={vm.allowDiscipline} />}
          label={'Show Discipline'}
        />
        <FormControlLabel
          key='Feedback'
          htmlFor='feedback'
          onClick={() => vm.toggleAllowFeedback()}
          control={<Checkbox color='primary' checked={vm.allowFeedback} />}
          label={'Show Feedback'}
        />
        <FormControlLabel
          key='PartnerChannel'
          htmlFor='partnerChannel'
          onClick={() => vm.toggleAllowPartnerChannel()}
          control={<Checkbox color='primary' checked={vm.allowPartnerChannel} />}
          label={`Show ${labelsStore.getLabelByLanguageAndFor('client')}`}
        />
      </>
    )
  }

  const renderAnonymizeSettings = () => {
    return (
      <div className='anon-cont'>
        <div className='anon-survey-settings'>
          <InputLabel className='anon-survey-settings'>
            {`Anonymous ${labelsStore.getLabelByLanguageAndFor('pulse')} Results Settings`}
          </InputLabel>
          <InfoTooltip
            message={`Number of survey results needed to display results when ${labelsStore.getLabelByLanguageAndFor(
              'pulse'
            )} is anonymous`}
          />
        </div>
        <TextField
          id='outlined-number'
          type='number'
          variant='outlined'
          value={vm.anonymousSurveyResultCount}
          onChange={(e) => vm.setAnonymousSurveyResultCount(+e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
    )
  }

  const renderSurveyArchiveSettings = () => {
    if (!vm.canArchiveSurveys) return null
    return (
      <div className='anon-cont'>
        <div className='anon-survey-settings'>
          <InputLabel className='anon-survey-settings'>
            {`Archive ${labelsStore.getLabelByLanguageAndFor('pulse')} After Days Setting`}
          </InputLabel>
          <InfoTooltip
            message={`Number of days until ${labelsStore.getLabelByLanguageAndFor(
              'pulse'
            )} is auto archived`}
          />
        </div>
        <TextField
          id='outlined-number'
          type='number'
          variant='outlined'
          value={vm.archivedAfterDays}
          onChange={(e) => vm.setArchivedAfterDays(+e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
    )
  }

  const renderCampaignEmailFromSettings = () => {
    return (
      <div className='add-settings-cont'>
        <div className='add-setting'>
          <InputLabel className='add-setting'>Campaign Email From Settings:</InputLabel>
          <InfoTooltip
            message={`The name and email address campaign related emails will be from for this tenant`}
          />
        </div>
        <div className='add-setting'>
          <InputLabel className='add-setting'>Name</InputLabel>
        </div>
        <TextField
          id='from-email-name'
          variant='outlined'
          fullWidth
          value={vm.fromEmailName}
          onChange={(e) => vm.setFromEmailName(e.target.value)}
        />
        <div className='add-setting margin-top'>
          <InputLabel className='add-setting'>Email Address</InputLabel>
        </div>
        <TextField
          id='from-email-address'
          variant='outlined'
          fullWidth
          value={vm.fromEmailAddress}
          onChange={(e) => vm.setFromEmailAddress(e.target.value)}
          error={!vm.fromEmailValid}
        />
      </div>
    )
  }

  return (
    <div id='OrgSettingsTab'>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <OrganizationModules />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <OrganizationFeatures />
        </Grid>
        <Grid item xs={12}>
          <div className='details-paper'>
            <h6>Additional Settings</h6>
            {renderTaskSettings()}
            {renderAnonymizeSettings()}
            {renderSurveyArchiveSettings()}
            <OrganizationAdditionalFileTypes />
            {renderCampaignEmailFromSettings()}
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <OrganizationAnalysis />
        </Grid>
      </Grid>
    </div>
  )
}
export default inject('organizationsSAStore', 'labelsStore')(observer(OrganizationSettingsTab))
