import { observable, action, computed } from 'mobx'

export class FileVM {

  constructor(
    file: File,
  ) {
    this.file = file
  }

  @observable public file: File = null

  @computed 
  public get name() {
    return this.file.name
  }

  @computed 
  public get type() {
    if (this.file.type.includes('image')) return 'image'
    if (this.file.type.includes('video')) return 'video'
    return ''
  }

  @computed 
  public get preview() {
    return URL.createObjectURL(this.file)
  }

  @computed
  public get shorterFileName(): string {
    if (this.name.length > 50)
    return this.name.substring(0, 30) + '..'
  return this.name
  }

}
