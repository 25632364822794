import React from 'react'
import { inject, observer, useLocalStore } from 'mobx-react'
import './SurveyTypesSAEdit.scss'
import rootStore from '../../../stores/RootStore'
import SAEditTopToolbar from './SAEditTopToolbar'
import { Button, Checkbox, Grid, LinearProgress, TextField, Typography } from '@material-ui/core'
import MuiConfirmDeleteDialog from 'src/app/shared/MuiConfirmDeleteDialog'
import SurveyTypeEditVM from '../../store/view-models/SurveyTypeEditVM'
import { LabelsStore } from '../../../labels/store/LabelsStore'
import LocalizationStore from '../../../localization/LocalizationStore'
import { ScreenType } from '../../types/ScreenType'

interface Props {
  objId: string
  screenType: ScreenType
  labelsStore?: LabelsStore
  localizationStore?: LocalizationStore
}

const SurveyTypesSAEdit: React.FC<Props> = ({
  objId,
  screenType,
  labelsStore,
  localizationStore,
}) => {
  let localStore = useLocalStore(() => ({
    vm: new SurveyTypeEditVM(rootStore, screenType, objId),
  }))
  let vm = localStore.vm
  const strings = localizationStore.lzStrings.surveyTypesWidgetDrawer

  const renderDeleteDialog = () => {
    return (
      <MuiConfirmDeleteDialog
        onClose={() => vm.toggleDeleteDialog()}
        open={vm.deleteConfirmShown}
        itemType='Survey Type'
        itemName={vm.name}
        onConfirm={() => vm.deleteSurveyType()}
      />
    )
  }

  const renderNameInput = () => {
    return (
      <>
        <Grid item xs={12}>
          {!vm.isValid && <Typography className='error-text'>{'Please Enter a Name'}</Typography>}
        </Grid>
        <Grid className='set-details' item xs={12}>
          <div className='name-input'>
            <Typography className='primary-text'>{'Name'}</Typography>
            <div className='name-container'>
              <TextField
                error={!vm.nameValid}
                id='outlined-name'
                className={'set-name'}
                value={vm.name}
                onChange={(e) => vm.setName(e.target.value)}
                fullWidth
                variant='outlined'
              />
            </div>
          </div>
        </Grid>
      </>
    )
  }

  const renderDescriptionInput = () => {
    return (
      <Grid className='set-details' item xs={12}>
        <div className='description-input'>
          <Typography className='primary-text'>{'Description'}</Typography>
          <div className='description-container'>
            <TextField
              multiline
              minRows={4}
              error={!vm.nameValid}
              // id='outlined-name'
              className={'set-description'}
              value={vm.description}
              onChange={(e) => vm.setDescription(e.target.value)}
              fullWidth
              variant='outlined'
            />
          </div>
        </div>
        {/* <div className='active-checkbox'>
					  <div>
						  <Checkbox
							  checked={vm.isActive}
							  onChange={() => vm.toggleIsActive()}
						  />
					  </div>
					  <Typography className='primary-text primary-text--horizontal'>{lz.is_active}</Typography>
					  <InfoTooltip message={lz.is_active_tooltip} />
				  </div> */}
      </Grid>
    )
  }

  const renderResponseIsForParticipant = () => {
    if (vm.isFeedbackType) return null
    return (
      <Grid className='set-details' item xs={12}>
        <div className='active-checkbox'>
          <div>
            <Checkbox
              checked={vm.responseForParticipant}
              onChange={() => vm.toggleResponseForParticipant()}
            />
          </div>
          <Typography className='primary-text primary-text--horizontal'>
            {`Responses are for the user answering the ${labelsStore
              .getLabelByLanguageAndFor('pulse')
              .toLowerCase()}`}
          </Typography>
          {/* <InfoTooltip message={lz.is_active_tooltip} /> */}
        </div>
      </Grid>
    )
  }

  const renderIsFeedback = () => {
    return (
      <Grid className='set-details' item xs={12}>
        <div className='active-checkbox'>
          <div>
            <Checkbox checked={vm.isFeedbackType} onChange={() => vm.toggleFeedBack()} />
          </div>
          <Typography className='primary-text primary-text--horizontal'>
            {`${strings.who_the} ${labelsStore.getLabelByLanguageAndFor('pulse').toLowerCase()} ${
              strings.is_for_must
            }`}
          </Typography>
          {/* <InfoTooltip message={lz.is_active_tooltip} /> */}
        </div>
      </Grid>
    )
  }

  const renderMultipleFor = () => {
    if (!vm.isFeedbackType) return null
    return (
      <Grid className='set-details' item xs={12}>
        <div className='active-checkbox'>
          <div>
            <Checkbox checked={vm.allowMultipleForUsers} onChange={() => vm.toggleMultipleFor()} />
          </div>
          <Typography className='primary-text primary-text--horizontal'>
            {'Allow multiple for users to be selected'}
          </Typography>
          {/* <InfoTooltip message={lz.is_active_tooltip} /> */}
        </div>
      </Grid>
    )
  }

  const renderForPrompt = () => {
    if (!vm.isFeedbackType) return null
    return (
      <>
        <Grid className='set-details' item xs={12}>
          <div className='prompt-input'>
            <Typography className='primary-text'>{'For Prompt'}</Typography>
            <div className='name-container'>
              <TextField
                id='outlined-name'
                className={'set-name'}
                value={vm.forPrompt}
                onChange={(e) => vm.setPrompt(e.target.value)}
                fullWidth
                variant='outlined'
              />
            </div>
          </div>
        </Grid>
      </>
    )
  }

  return (
    <div id='SurveyTypesSAEdit'>
      <SAEditTopToolbar vm={vm} />
      <div id={'SurveyTypesSAEditForm'}>
        {renderNameInput()}
        {renderDescriptionInput()}
        {renderIsFeedback()}
        {renderResponseIsForParticipant()}
        {renderMultipleFor()}
        {renderForPrompt()}
      </div>
      {renderDeleteDialog()}
    </div>
  )
}

export default inject('labelsStore', 'localizationStore')(observer(SurveyTypesSAEdit))
