import { action, computed, observable, reaction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Category } from '../../weight-profiles/aggregate/Category'
import { WeightProfile } from '../../weight-profiles/aggregate/WeightProfile'
import { WeightProfilesService } from '../../weight-profiles/services/WeightProfilesService'
import { ImportWeightProfileVM } from '../../weight-profiles/view-models/ImportWeightProfileVM'
import { CategoryVM } from './CategoryVM'
import { GroupsAndRolesWidgetVM } from './GroupsAndRolesWidgetVM'

export class WeightProfileModalVM {
  private rootStore: RootStore
  public weightProfile: WeightProfile
  private svc: WeightProfilesService
  private listVM: GroupsAndRolesWidgetVM

  constructor(listVM: GroupsAndRolesWidgetVM, rootStore: RootStore, weightProfile: WeightProfile) {
    this.rootStore = rootStore
    this.listVM = listVM
    this.weightProfile = weightProfile
    this.svc = new WeightProfilesService(this.rootStore)
    this.ensureAllCategories()
    setTimeout(() => this.buildInitialTreeData(), 500)

    reaction(
      () => JSON.stringify(this.weightProfile),
      () => this.setIsDirty()
    )
  }

  @computed
  public get orgCategoryTreeData(): CategoryVM[] {
    let roots = []
    let nodes = {}
    const arry = this.rootStore.categoriesStore.currentOrgCategories
    for (let i = 0; i < arry.length; ++i) {
      let item = arry[i],
        p = item.parentCategoryId,
        target = !p ? roots : nodes[p] || (nodes[p] = [])
      target.push(new CategoryVM(this.rootStore, this, item))
    }
    let findChildren = function (parent, depth) {
      if (depth === 4) depth = 1
      if (depth === 5) depth = 2
      if (depth === 6) depth = 3
      parent.depth = depth
      if (nodes[parent.objectId]) {
        parent.children = nodes[parent.objectId]
        for (let i = 0, len = parent.children.length; i < len; ++i) {
          findChildren(parent.children[i], depth + 1)
        }
      }
    }
    for (let i = 0; i < roots.length; ++i) {
      findChildren(roots[i], 1)
    }
    return roots
  }

  @action
  public buildInitialTreeData() {
    const data = []
    for (let i = 0; i < this.orgCategoryTreeData.length; i++) {
      data.push(this.orgCategoryTreeData[i])
    }
    this.treeData = data
  }

  @observable public treeData: CategoryVM[] = []
  @observable public saveTried: boolean = false
  @observable public isProcessing: boolean = false
  @observable public showSaveSnackbar: boolean = false
  @observable public showCopySnackbar: boolean = false
  @observable public isDirty: boolean = false
  @observable public showChildrenMissingMessage: boolean = false
  @observable public showWeightInvalidMessage: boolean = false
  @observable public importWeightProfileVM: ImportWeightProfileVM = null
  @observable public showImportDialog: boolean = false

  @computed
  public get objectId(): string {
    return this.weightProfile.objectId
  }

  @action
  public setIsDirty() {
    this.isDirty = true
  }

  @action
  public toggleImportDialog() {
    this.showImportDialog = !this.showImportDialog
  }

  @action
  public ensureAllCategories() {
    // if (this.isNew) return
    this.rootStore.categoriesStore.currentOrgCategories.forEach((cat) => {
      const foundCat = this.weightProfile.categories.find((e) => e.categoryId === cat.objectId)
      if (!foundCat) this.weightProfile.categories.push(Category.create(cat))
    })
  }

  @action
  public onChange(val: any) {
    this.treeData = val
  }

  @action
  public setCategoryWeightValue(id: string, val: string) {
    if (!/^[0-9]*$/.test(val)) return
    if (Number(val) > 100) return
    if (Number(val) < 0) return
    const foundCat = this.weightProfile.categories.find((e) => e.categoryId === id)
    if (!foundCat) return
    foundCat.setValue(Number(val))
    this.updateTreeData(foundCat)
  }

  @action
  public toggleCatSelected(id: string, e: any) {
    const clickedItemClassName = e.target.className
    if (
      clickedItemClassName === 'rst__expandButton' ||
      clickedItemClassName === 'rst__collapseButton'
    )
      return
    const foundCat = this.weightProfile.categories.find((e) => e.categoryId === id)
    if (!foundCat) return
    foundCat.isChecked = !foundCat.isChecked
    this.setSiblingWeights(foundCat)
    this.ensureChildrenCheckedCorrectly(foundCat)
  }

  @action
  public ensureChildrenCheckedCorrectly(foundCat: Category) {
    const ary = this.treeData

    const selectSingleChildren = (children) => {
      if (children.length > 1) return
      children[0].isChecked = true
      const weightCat = this.weightProfile.categories.find(
        (e) => e.categoryId === children[0].objectId
      )
      if (weightCat) weightCat.isChecked = true
      if (children[0].children.length > 0) selectSingleChildren(children[0].children)
    }

    let findChildren = function (siblings) {
      siblings.forEach((child) => {
        if (child.objectId === foundCat.categoryId && child.children.length !== 0) {
          selectSingleChildren(child.children)
        }
        if (child.children.length > 0) findChildren(child.children)
      })
    }

    for (let i = 0; i < ary.length; i++) {
      if (ary[i].objectId === foundCat.categoryId) break

      if (ary[i].children.length > 0) findChildren(ary[i].children)
    }
    this.treeData = [...ary]
  }

  @action
  public updateTreeData(foundCat: Category) {
    const ary = this.treeData
    let checkChildren = function (siblings) {
      siblings.forEach((child) => {
        if (child.objectId === foundCat.categoryId) {
          child.weightValue = foundCat.value
          child.isChecked = foundCat.isChecked
          if (!child.isChecked) child.inValid = false
        }
        if (child.children) checkChildren(child.children)
      })
    }
    for (let i = 0; i < ary.length; i++) {
      if (ary[i].objectId === foundCat.categoryId) {
        ary[i].weightValue = foundCat.value
        ary[i].isChecked = foundCat.isChecked
        if (!ary[i].isChecked) ary[i].inValid = false
        break
      }
      if (ary[i].children) checkChildren(ary[i].children)
    }
    this.treeData = [...ary]
  }

  @action
  public setTreeSiblingValues(foundCat: Category) {
    const ary = this.treeData
    const weightProfile = this.weightProfile
    let checkChildren = function (siblings) {
      siblings.forEach((child) => {
        if (child.objectId === foundCat.categoryId) {
          child.isChecked = foundCat.isChecked
          const numberOfSelectedSiblings = siblings.filter((e) => e.isChecked).length
          const sharedValue = 100 / numberOfSelectedSiblings
          siblings.forEach((e) => (e.weightValue = sharedValue))

          for (let cat of siblings) {
            if (!cat.isChecked) return
            const weightProfileCat = weightProfile.categories.find((e) => e.categoryId === cat.id)
            if (!weightProfileCat) return
            weightProfileCat.setValue(sharedValue)
          }
        }
        if (child.children) checkChildren(child.children)
      })
    }

    for (let i = 0; i < ary.length; i++) {
      if (ary[i].objectId === foundCat.categoryId) {
        ary[i].isChecked = foundCat.isChecked
        const numberOfSelectedSiblings = ary.filter((e) => e.isChecked).length
        const sharedValue = 100 / numberOfSelectedSiblings
        ary.forEach((e) => (e.weightValue = sharedValue))

        ary.forEach((cat) => {
          if (!cat.isChecked) return
          const weightProfileCat = weightProfile.categories.find(
            (e) => e.categoryId === cat.objectId
          )
          if (!weightProfileCat) return
          weightProfileCat.setValue(sharedValue)
        })

        break
      }

      if (ary[i].children) checkChildren(ary[i].children)
    }

    this.weightProfile = weightProfile
    this.treeData = [...ary]
  }

  @action
  private setSiblingWeights(cat: Category) {
    this.updateTreeData(cat)
    this.setTreeSiblingValues(cat)
  }

  @action
  public toggleSaveSnackbar() {
    this.showSaveSnackbar = !this.showSaveSnackbar
  }

  @action
  public toggleCopySnackbar() {
    this.showCopySnackbar = !this.showCopySnackbar
  }

  @action
  public markSaveTried() {
    this.saveTried = true
  }

  @action
  public checkChildWeightValidation() {
    const ary = this.treeData
    let isValid = true
    let checked = 0
    let totalWeight = 0
    let showChildrenMissingMessage = false
    let showWeightInvalidMessage = false

    //check parent weight validation
    for (let i = 0; i < this.treeData.length; i++) {
      if (this.treeData[i].isChecked) {
        checked++
        totalWeight += Number(this.treeData[i].weightValue)
      }
    }
    if (totalWeight !== 100 && checked) {
      isValid = false
      showWeightInvalidMessage = true
      if (isValid) return
      this.treeData.forEach((e) => {
        e.isChecked ? (e.inValid = true) : (e.inValid = false)
      })
    }

    //check siblings and any children they might have
    let checkChildren = function (siblings) {
      let siblingsTotalWeight = 0
      let checked = 0
      siblings.forEach((child) => {
        if (child.isChecked) {
          checked++
          siblingsTotalWeight += Number(child.weightValue)
          if (child.children.length > 0 && !child.children.some((e) => e.isChecked)) {
            showChildrenMissingMessage = true
            child.inValid = true
          } else {
            child.inValid = false
          }
        }
        if (child.children) checkChildren(child.children)
      })
      if (siblingsTotalWeight !== 100 && checked) {
        isValid = false
        if (isValid) return
        siblings.forEach((child) => {
          if (!child.isChecked) {
            child.inValid = false
            return
          }
          child.inValid = true
        })
      }
    }

    //run through parents to burrow into children to check weight validation
    for (let i = 0; i < ary.length; i++) {
      if (ary[i].isChecked) {
        if (ary[i].children.length > 0 && !ary[i].children.some((e) => e.isChecked)) {
          showChildrenMissingMessage = true
          ary[i].inValid = true
        }
      }
      if (ary[i].children) checkChildren(ary[i].children)
    }
    this.showChildrenMissingMessage = showChildrenMissingMessage
    this.showWeightInvalidMessage = showWeightInvalidMessage
    this.treeData = [...ary]
    return isValid
  }

  @action
  public async save() {
    this.markSaveTried()
    const isValid = this.checkChildWeightValidation()
    if (!isValid) return
    if (this.showChildrenMissingMessage) return
    this.isProcessing = true
    const savedWeightProfileId = await this.svc.saveWeightProfile(
      this.rootStore.appStore.currentOrgId,
      this.weightProfile.serialize()
    )

    this.toggleSaveSnackbar()

    setTimeout(() => {
      this.isProcessing = false
      const weightProfile =
        this.rootStore.weightProfilesStore.getWeightProfile(savedWeightProfileId)
      this.listVM.setRole(weightProfile.roleId)
    }, 1000)
  }

  @action
  public async delete() {
    this.isProcessing = true
    this.listVM.toggleDeleteDialog()
    await this.svc.deleteWeightProfile(
      this.weightProfile.objectId,
      this.rootStore.appStore.currentOrgId
    )

    setTimeout(() => {
      this.listVM.setRole(this.weightProfile.roleId)
      this.isProcessing = false
    }, 1000)
  }
}
