import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { TasksStore } from '../../store/TasksStore'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import BackIcon from '@material-ui/icons/ArrowBack'

interface MediaToolbarProps {
  tasksStore?: TasksStore
  localizationStore?: LocalizationStore
}

const MediaToolbar: React.FC<MediaToolbarProps> = ({ tasksStore, localizationStore }) => {
  const { lzStrings } = localizationStore
  const { taskEditVM: VM } = tasksStore

  const handleBackClick = () => {
    VM.participantsSelect.setParticipants(VM.assignedToAudienceMembers)
    VM.participantsSelectOpen = false
  }

  const handleAddSelected = () => {
    VM.assignedToAudienceMembers = [
      ...VM.assignedToAudienceMembers,
      ...VM.participantsSelect.participants,
    ]
    VM.participantsSelectOpen = false
  }

  return (
    <AppBar color='default' position='static' elevation={0}>
      <Toolbar className='participantsToolbar'>
        <IconButton disableRipple onClick={handleBackClick}>
          <BackIcon />
        </IconButton>
        <Typography variant='h5'>
          {lzStrings.tasksManagementWidgetDrawer.add_participants}
        </Typography>
        <Button
          disabled={!VM.addSelectedParticipantsButtonEnabled}
          variant='contained'
          size='large'
          onClick={handleAddSelected}
        >
          {lzStrings.tasksManagementWidgetDrawer.apply}
        </Button>
      </Toolbar>
    </AppBar>
  )
}

export default inject('tasksStore', 'localizationStore')(observer(MediaToolbar))
