import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { CardContent } from '@material-ui/core'
import { WorksheetsStore } from '../../../store/WorksheetsStore'
import WorksheetsList from './WorksheetsList'

interface Props {
  localizationStore?: LocalizationStore
  worksheetsStore?: WorksheetsStore
}

const Content: React.FC<Props> = ({ worksheetsStore }) => {
  const { chartWidgetDrawerVM } = worksheetsStore

  return (
    <CardContent>
      <WorksheetsList />
    </CardContent>
  )
}

export default inject('worksheetsStore')(observer(Content))
