import { observable, computed, action } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { QuestionVM } from './QuestionVM'
import { QuestionType } from '../types/QuestionType'
import { UserSurveyTakeVM } from './UserSurveyTakeVM'
import { Option } from '../../surveys/aggregate/Option'
import { Option as SurveyResponseOption } from '../../survey-responses/aggregate/Option'

export class OptionVM {
  private rootStore: RootStore
  private forQuestion: QuestionVM
  private option: Option
  private takeForm: UserSurveyTakeVM

  constructor(
    rootStore,
    takeForm: UserSurveyTakeVM,
    forQuestion: QuestionVM,
    option: Option | SurveyResponseOption
  ) {
    this.rootStore = rootStore
    this.forQuestion = forQuestion
    this.takeForm = takeForm
    this.option = option
    this.loadData(option)
    setTimeout(() => {
      if (!this.takeForm.isForResponseDisplay)
        this.responseNumber = Number(this.forQuestion.defaultValueNumeric)
    }, 100)
  }

  loadData(option: Option | SurveyResponseOption) {
    this.text = option.text.replace('(Textbox)', '')
    this.subText = option.subText
    this.responseNumber = option.responseNumber
    if ((option as SurveyResponseOption).isChecked) {
      this.isChecked = (option as SurveyResponseOption).isChecked
    }
  }

  @observable public text: string = ''
  @observable public responseNumber: number = 0
  @observable public subText: string = ''
  @observable public isChecked: boolean = false

  @computed
  public get blurred(): boolean {
    return this.forQuestion.blurred
  }

  @computed
  public get defaultValueNumeric(): string {
    return this.forQuestion.defaultValueNumeric
  }

  @computed
  public get id(): string {
    return this.option.id
  }

  @computed
  public get nextQuestionId(): string {
    if (this.isBranched) return this.option.nextQuestionId
    if (!this.forQuestion.hasAnswer && this.forQuestionType === 'text') return ''
    if (!this.forQuestion.hasAnswer && this.forQuestionType === 'number') return ''
    return this.option.nextQuestionId
  }

  @computed
  public get weight(): number {
    return this.option.weight
  }

  @computed
  public get isBranched(): boolean {
    return this.option.nextQuestionId !== null || this.option.goToEnd
  }

  @computed
  public get value(): number {
    return Number(this.option.value)
  }

  @computed
  public get rank(): number {
    return Number(this.option.rank)
  }

  @computed
  public get emoji(): string {
    return this.option.emoji
  }

  @computed
  public get optionsEnabled(): boolean {
    return this.forQuestion.watchPercentageReached
  }

  @computed
  public get goToEnd() {
    if (this.option.goToEnd && this.forQuestion.type === 'text') return true
    if (this.option.goToEnd && this.forQuestion.type === 'number') return true
    const toEnd = this.option.goToEnd && this.isChecked
    return toEnd
  }

  @computed
  public get maximumValueAllowed(): number {
    return Number(this.forQuestion.maximumValueAllowed)
  }

  @computed
  public get minimumValueAllowed(): number {
    return Number(this.forQuestion.minimumValueAllowed)
  }

  @computed
  public get numberStep(): number {
    return Number(this.forQuestion.numberStep)
  }

  @action
  public toggleChecked() {
    this.isChecked = !this.isChecked
    if (
      this.forQuestionType === 'emoji' ||
      this.forQuestionType === 'yesNo' ||
      this.forQuestionType === 'radio'
    ) {
      const otherOptions = this.forQuestion.options.filter((e) => e.id !== this.id)
      otherOptions.forEach((e) => e.setChecked(false))
    }
    this.takeForm.setCurrentQuestionIndex()
  }

  @action
  public setChecked(val: boolean) {
    this.isChecked = val
  }

  @action
  public setText(val: string) {
    this.text = val
  }

  @computed
  public get decimalRegex(): any {
    if (this.forQuestion.decimalsAllowed === 0) return /^[\d ()+-]+$/
    return /^[^A-Za-z]+$/
  }

  @action
  public setNumber(val: any) {
    if (!val) this.responseNumber = 0
    if (!this.decimalRegex.test(val)) return
    let decimals = 0
    const decimalIndex = String(val).lastIndexOf('.')
    if (decimalIndex > 0) {
      decimals = String(val).length - decimalIndex - 1
    }
    if (decimals > this.forQuestion.decimalsAllowed) return
    this.responseNumber = Number(val)
    this.forQuestion.setIsDirty()
  }

  @action
  public increaseResponse() {
    const num = this.responseNumber + this.numberStep
    this.setNumber(num)
  }

  @action
  public decreaseResponse() {
    const num = this.responseNumber - this.numberStep
    this.setNumber(num)
  }

  @computed
  public get forQuestionType(): QuestionType {
    return this.forQuestion.type
  }

  @computed
  public get forQuestionRank(): number {
    return this.forQuestion.rank
  }

  @computed
  public get nextQuestion(): QuestionVM {
    if (this.goToEnd) return null
    const allQuestions = this.takeForm.allQuestions
    if (!this.nextQuestionId) {
      const nextQuestionIndex = this.forQuestion.index + 1
      if (nextQuestionIndex < allQuestions.length) return allQuestions[nextQuestionIndex]
      else return null
    }
    return allQuestions.find((e) => e.id === this.nextQuestionId)
  }

  public toDTO() {
    return {
      id: this.id,
      emoji: this.emoji,
      rank: this.rank,
      text: this.text,
      subText: this.subText,
      weight: this.weight,
      value: this.value,
      isChecked: this.isChecked,
      responseNumber: this.responseNumber,
      // this.numberStep > 0
      //   ? Math.round(this.responseNumber / this.numberStep) * this.numberStep
      //   : this.responseNumber,
    }
  }
}
