import React from 'react'
import { inject, observer } from 'mobx-react'
import TreeView from '@material-ui/lab/TreeView'
import FolderOpenIcon from '@material-ui/icons/FolderOpen'
import PlusSquare from '@material-ui/icons/AddBoxOutlined'
import MinusSquare from '@material-ui/icons/IndeterminateCheckBoxOutlined'
import './SurveyTemplatesFolderSelect.scss'
import MDSpinner from 'react-md-spinner'
import LocalizationStore from '../../../../localization/LocalizationStore'
import TreeItem from '@material-ui/lab/TreeItem'
import { Typography } from '@material-ui/core'
import { SurveyTemplatesSelectFoldersVM } from '../../../view-models/template-select/SurveyTemplatesSelectFoldersVM'

interface Props {
  localizationStore?: LocalizationStore
  vm: SurveyTemplatesSelectFoldersVM
}

const SurveyTemplatesFolderSelect: React.FC<Props> = ({ vm }) => {
  
  const renderRoots = () => {
    const treeItems = []
    vm.data.forEach((e, i) => {
      treeItems.push(renderFolder(e))
    })
    return treeItems
  }

  const renderLabel = (node) => {
    return (
      <div className={'tree-item-label'} key={node.id} onClick={() => vm.setSelectedFolder(node)}>
        <div className={'item-left'}>
          <Typography>{node.name}</Typography>
        </div>
      </div>
    )
  }

  const renderFolder = (nodes) => (
    <TreeItem key={nodes.id} nodeId={nodes.id} label={renderLabel(nodes)}>
      {Array.isArray(nodes.children) ? nodes.children.map((node, i) => renderFolder(node)) : null}
    </TreeItem>
  )

  const renderTree = () => {
    if (!vm.isLoaded) return null
    return (
      <TreeView
        // className={classes.root}
        defaultExpandIcon={<PlusSquare />}
        defaultCollapseIcon={<MinusSquare />}
        defaultEndIcon={<FolderOpenIcon />}
        defaultSelected={['no-folder']}
        expanded={vm.defaultExpanded}
      >
        {renderRoots()}
      </TreeView>
    )
  }

  const renderSpinner = () => {
    if (vm.isLoaded) return null
    return (
      <div className='folder-spinner'>
        <MDSpinner size={40} singleColor='#05d1cf' />
      </div>
    )
  }

  return (
    <div id='SurveyTemplateFoldersPicker'>
      <div className='picker-modal-content'>
        {renderSpinner()}
        {renderTree()}
        </div>
    </div>
  )
}

export default inject('localizationStore')(observer(SurveyTemplatesFolderSelect))
