import { action, computed, observable } from 'mobx'
import moment from 'moment'
import { AudienceMember } from '../../../audience-members/aggregate/AudienceMember'
import { AGGridRowVM } from '../../../shared/ag-grid/AGGridRowVM'
import { RootStore } from '../../../stores/RootStore'
import { CompletedSurveyResponsesTabVM } from './CompletedSurveyResponsesTabVM'
import { SurveyResponse } from '../../../survey-responses/aggregate/SurveyResponse'

export class CompletedSurveyResponseRowVM extends AGGridRowVM<SurveyResponse> {
  private parentVM: CompletedSurveyResponsesTabVM

  constructor(
    rootStore: RootStore,
    surveyResponse: SurveyResponse,
    gridVM: CompletedSurveyResponsesTabVM
  ) {
    super(rootStore, surveyResponse, gridVM)
    this.parentVM = gridVM
  }

  @computed
  public get objectId(): string {
    return this.aggregate.objectId
  }

  @computed
  public get name(): string {
    return this.aggregate.name
  }

  @computed
  public get respondedAtFormatted(): string {
    const s = this.rootStore.localizationStore.lzStrings.surveys
    return moment(this.aggregate.respondedAtAsDate).format(s.responded_at_date_format)
  }

  @computed
  public get questionsCount(): number {
    if (!this.aggregate.questionsCount) return 0
    return this.aggregate.questionsCount
  }

  @computed
  public get fromUserId(): string {
    if (!this.aggregate.publishedByUserId) return ''
    return this.aggregate.publishedByUserId
  }

  @computed
  public get fromUserName(): string {
    return this.aggregate.publishedByUserName
  }

  @computed
  public get fromUserIconURL(): string {
    return undefined
  }

  @computed
  public get categories(): string {
    if (!this.aggregate.categoryIds.length) return '--'
    let categories = []
    this.aggregate.categoryIds.forEach((id) => {
      const foundCategory = this.rootStore.categoriesStore.getCategory(id)
      if (foundCategory) categories.push(foundCategory.name)
    })
    return categories.join(', ')
  }

  @computed
  public get showResults(): boolean {
    return this.aggregate.showResults
  }

  @action
  public openResultsOrResponse() {
    let url = '/surveyresponses/view/' + this.objectId
    if (this.showResults) url = '/surveyresults/view/' + this.aggregate.anonymousToken
    this.rootStore.appStore.router.push(url)
  }
}
