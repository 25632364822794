import React from 'react'
import {
  Button,
  AppBar,
  Toolbar,
  Typography,
  TextField,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core'
import BackIcon from '@material-ui/icons/KeyboardBackspace'
import { AppStore } from '../../../stores/AppStore'
import { inject, observer } from 'mobx-react'
import ReplayIcon from '@material-ui/icons/Replay'
import LocalizationStore from '../../../localization/LocalizationStore'
import './TopToolbar.scss'
import { TrainingItemsStore } from '../../store/TrainingItemsStore'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import DeleteIcon from '@material-ui/icons/Delete'
import MuiConfirmDeleteDialog from '../../../shared/MuiConfirmDeleteDialog'

interface Props {
  localizationStore?: LocalizationStore
  trainingItemsStore?: TrainingItemsStore
  appStore?: AppStore
}

const TopToolbar: React.FC<Props> = ({ appStore, trainingItemsStore, localizationStore }) => {
  const { trainingItemEditVM: vm } = trainingItemsStore
  const { training_items: strings } = localizationStore.lzStrings
  if (!vm) return null

  const renderMenu = () => {
    if (!vm.objectId) return null
    return (
      <>
        <IconButton
          className='toolbar-menu'
          onClick={(e) => vm.setToolbarAnchorEl(e.currentTarget)}
        >
          <MoreHorizIcon />
        </IconButton>
        <Menu
          anchorEl={vm.toolbarAnchorEl}
          keepMounted
          open={Boolean(vm.toolbarAnchorEl)}
          onClose={() => vm.setToolbarAnchorEl(null)}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          style={{ marginTop: 40 }}
        >
          {renderDeleteOption()}
        </Menu>
      </>
    )
  }

  const renderConfirmDelete = () => {
    if (!vm.showDeleteDialog) return null
    return (
      <MuiConfirmDeleteDialog
        onClose={() => vm.toggleDeleteDialog()}
        open={vm.showDeleteDialog}
        itemName={vm.name}
        itemType={'Training Item'}
        onConfirm={() => vm.delete()}
      />
    )
  }

  const renderDeleteOption = () => {
    if (!vm.objectId) return null
    return (
      <MenuItem className='delete-item' onClick={() => vm.toggleDeleteDialog()}>
        <DeleteIcon />
        <Typography variant='h6'>{localizationStore.lzStrings.buttonLabels.delete}</Typography>
      </MenuItem>
    )
  }
  const renderBackButton = () => {
    if (vm.isCreatingFromModal) return null
    return (
      <Button
        variant='text'
        aria-label='back'
        className='training-item-back-to-impace-btn'
        onClick={() => {
          if (appStore.router.location.pathname.startsWith('/trainingItems/from-catalog/')) {
            appStore.router.push('/dashboard/impact/catalog')
          } else {
            appStore.router.push('/dashboard/impact/trainings')
          }
        }}
      >
        <BackIcon className='back-icon' />
        {strings.back_to_impact}
      </Button>
    )
  }

  const renderTrainingItemName = () => {
    return (
      <TextField
        error={!vm.nameValid}
        value={vm.name}
        onChange={(e) => vm.setName(e.target.value)}
        placeholder={vm.objectId ? strings.edit_training_item : strings.create_training_item}
        variant='standard'
      />
    )
  }

  const renderRefreshButton = () => {
    if (!vm.objectId) return null
    return (
      <IconButton
        className='icon-button'
        onClick={() => trainingItemsStore.editTrainingItem(vm.objectId)}
      >
        <ReplayIcon />
      </IconButton>
    )
  }

  const renderRightToolBar = () => {
    return (
      <>
        {renderMenu()}
        {renderRefreshButton()}
        <Button onClick={() => vm.continue()} variant='contained'>
          {vm.currentTab === 1
            ? vm.objectId
              ? strings.update_training_item
              : strings.create_training_item
            : strings.continue}
        </Button>
      </>
    )
  }

  return (
    <>
      <AppBar position='static' color='default' id='TopToolbar' elevation={0}>
        <Toolbar className='toolbar'>
          <div slot='start' className='start'>
            {renderBackButton()}
            <Typography className='title' variant='h5'>
              {vm.objectId ? strings.edit_training_item : strings.create_training_item}
            </Typography>
            {renderTrainingItemName()}
          </div>
          <div slot='end' className='end-buttons'>
            {renderRightToolBar()}
          </div>
        </Toolbar>
      </AppBar>
      {renderConfirmDelete()}
    </>
  )
}
export default inject('trainingItemsStore', 'appStore', 'localizationStore')(observer(TopToolbar))
