import React from 'react'
import { OrganizationsSAStore } from '../../store/OrganizationsSAStore'
import LocalizationStore from '../../../localization/LocalizationStore'
import { inject, observer } from 'mobx-react'
import { Grid, InputLabel, TextField, Select, MenuItem, Typography, InputAdornment, CircularProgress } from '@material-ui/core'
import './OrganizationEditDrawer.scss'
import Autocomplete from '@material-ui/lab/Autocomplete'

interface Props {
  organizationsSAStore?: OrganizationsSAStore
  localizationStore?: LocalizationStore
}

const OrganizationInfo: React.FC<Props> = ({ organizationsSAStore, localizationStore }) => {
  const { editVM: vm } = organizationsSAStore
  const { organizationsSADrawer: strings } = localizationStore.lzStrings
  if (!vm) return null

  const renderMenuItems = () => {
    return organizationsSAStore.zones.map((zone) => {
      return (
        <MenuItem key={zone.objectId} value={zone.objectId}>
          {zone.objectId}
        </MenuItem>
      )
    })
  }

  const renderOrganizationTemplate = () => {
    if (!vm.isNew) return null
    return (
      <>
        <InputLabel className='details-info-label'>{'Organization Template '}</InputLabel>
        <Autocomplete
          disabled={false}
          className={'org-temp-input'}
          filterSelectedOptions
          onFocus={() => {vm.loadOrganizationTemplates()}}
          options={Array.from(vm.organizationTemplates)}
          onChange={(e, val) => vm.setOrganizationTemplate(val)}
          onInputChange={(e, val) => vm.loadOrganizationTemplates(val)}
          loading={vm.loadingOrganizationTemplates}
          getOptionLabel={(e) =>
            `${e.name}`
          }
          disableClearable={true}
          renderInput={(params) => (
            <TextField
              className={'org-temp-input'}
              placeholder={'Select Organization Template'}
              {...params}
              variant='outlined'
              fullWidth
            />
          )}
        />
      </>
    )
  }

  const renderExists = () => {
    if (!vm.nameError) return

    return (
      <div className="templateNameError">Name already exists</div>
    )
  }

  const renderSpinner = () => {
    if (!vm.isCheckingOrgName) return <div />
    return (
      <div id={'OrgNameSpinner'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  const renderNameAndZone = () => {
    return (
      <>
        <InputLabel className='details-info-label'>{strings.name}</InputLabel>
        <TextField
          error={vm.nameError}
          required
          className='details-info-input'
          value={vm.name}
          onChange={(e) => vm.setName(e.target.value)}
          fullWidth
          variant='outlined'
          id='organizationName'
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                {renderSpinner()}
              </InputAdornment>
            ),
          }}
        />
        {renderExists()}

        <InputLabel className='details-info-label'>{strings.zone}</InputLabel>
        <Select
          fullWidth
          error={vm.zoneError}
          required
          variant='outlined'
          className='details-info-combo'
          value={vm.zoneObjectId}
          onChange={(e) => vm.setZone(e.target.value)}
        >
          {renderMenuItems()}
        </Select>
      </>
    )
  }

  const renderBasicInfoSection = () => {
    if (vm.isNew) return null
    return (
      <>
        <InputLabel className='details-info-label'>{strings.email}</InputLabel>
        <TextField
          error={vm.emailError}
          className='details-info-input'
          value={vm.adminEmail}
          onChange={(e) => vm.setAdminEmail(e.target.value)}
          fullWidth
          variant='outlined'
          id='organizationEmail'
        />
        <InputLabel className='details-info-label'>{strings.phone}</InputLabel>
        <TextField
          className='details-info-input'
          value={vm.adminPhone}
          onChange={(e) => vm.setAdminPhone(e.target.value)}
          fullWidth
          variant='outlined'
          id='organizationPhone'
        />
        <InputLabel className='details-info-label'>{strings.tableau_site_name}</InputLabel>
        <TextField
          className='details-info-input'
          value={vm.tableauSiteName || ''}
          onChange={(e) => vm.setTableauSiteName(e.target.value)}
          fullWidth
          variant='outlined'
          id='organizationTableauSiteName'
        />
        <InputLabel className='details-info-label'>{strings.theme}</InputLabel>
        <TextField
          className='details-info-input'
          value={vm.theme || ''}
          onChange={(e) => vm.setTheme(e.target.value)}
          fullWidth
          variant='outlined'
          id='organizationTheme'
        />
        <InputLabel className='details-info-label'>{strings.mobile_app_id}</InputLabel>
        <Autocomplete
          disabled={false}
          className='details-info-input'
          value={vm.mobileAppId}
          options={vm.mobileAppSchemes}
          onFocus={() => {vm.loadMobileAppSchemes()}}
          onChange={(e, val) => vm.setMobileAppId(val)}
          loading={vm.loadingMobileSchemes}
          renderInput={(params) => (
            <TextField
              className={'org-temp-input'}
              placeholder={'Select Organization Template'}
              {...params}
              variant='outlined'
              fullWidth
            />
          )}
        />
      </>
    )
  }

  return (
    <div id='OrgDetailsTab'>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <div className='details-paper'>
            <h6>{strings.tenant_details}</h6>
            {renderNameAndZone()}
            {renderOrganizationTemplate()}
            {renderBasicInfoSection()}
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default inject('organizationsSAStore', 'localizationStore')(observer(OrganizationInfo))
