import React from 'react'
import { CardContent, Grid, Typography, Link } from '@material-ui/core'
import CreateIcon from '@material-ui/icons/Create'
import PersonIcon from '@material-ui/icons/Person'
import LinkIcon from '@material-ui/icons/Link'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import moment from 'moment'
import { observer, inject } from 'mobx-react'
import { EventsStore } from '../../../store/EventsStore'
import AlarmIcon from '@material-ui/icons/Alarm'

interface Props {
  eventsStore?: EventsStore
}

const TrainingItemPopupBody: React.FC<Props> = ({ eventsStore }) => {
  const { trainingItemPopupVM: vm } = eventsStore

  const renderDueDate = () => {
    if (vm.dueDate === null) return null
    return (
      <Grid
        item
        xs={12}
        container
        spacing={0}
        alignItems='flex-start'
        className='user-training-popup-questions-grid'
      >
        <Grid item xs={1} container justifyContent='center'>
          <AlarmIcon className='icon' />
        </Grid>
        <Typography>Due: {vm.dueDate}</Typography>
      </Grid>
    )
  }

  if (!vm) return null
  return (
    <CardContent>
      <Grid item xs={12} className='user-training-popup-title-grid'>
        <Typography variant='h6'>{vm.title}</Typography>
      </Grid>
      {renderDueDate()}
      {/* <Grid
        item
        xs={12}
        container
        spacing={0}
        alignItems='flex-start'
        className='user-training-popup-created-at-grid'
      >
        <Grid item xs={1} container justifyContent='center'>
          <CreateIcon className='icon' />
        </Grid>
        <Grid item>Created: {vm.activeDate}</Grid>
      </Grid> */}
      {/* <Grid
        item
        xs={12}
        container
        spacing={0}
        alignItems='flex-start'
        className='user-training-popup-from-grid'
      > */}
      {/* <Grid item xs={1} container justifyContent='center'>
          <PersonIcon className='icon' />
        </Grid>
        <Grid item>From: {vm.from}</Grid>
      </Grid> */}
    </CardContent>
  )
}

export default inject('eventsStore')(observer(TrainingItemPopupBody))
