import React from 'react'
import { observer } from 'mobx-react'
import rootStore from '../../../stores/RootStore'
import Header from './ScreenHeader'
import UserSurveyTakeWidget from './UserSurveyTakeWidget'
import UserTasksDrawer from '../../../user-tasks/views/drawer/Drawer'
import TaskDrawer from '../../../tasks/views/drawer/Drawer'
import './UserSurveyTakeScreen.scss'
import TaskHeader from './TaskHeader'
import ReactGA from 'react-ga'
class UserSurveyTakeScreen extends React.Component<any, any> {
  isAnonymousMode: boolean = false
  isPaperSurveyMode: boolean = false
  isAlwaysAvailableMode: boolean = false

  constructor(props) {
    super(props)

    const { location, params } = props

    if (
      location &&
      typeof location.pathname === 'string' &&
      location.pathname.toLowerCase().includes('public') &&
      location.pathname.toLowerCase().includes('survey')
    ) {
      this.isAnonymousMode = true
      ReactGA.event({
        category: 'Take_Survey',
        action: 'User opened a Survey',
        label: location.pathname,
      })
    }

    if (
      location &&
      typeof location.pathname === 'string' &&
      location.pathname.toLowerCase().includes('papersurvey')
    ) {
      this.isPaperSurveyMode = true
    }

    if (
      location &&
      typeof location.pathname === 'string' &&
      location.pathname.toLowerCase().includes('alwaysavailable')
    ) {
      this.isAlwaysAvailableMode = true
    }

    rootStore.surveysStore.viewModels.clearEditVM()

    if (this.isAnonymousMode) {
      rootStore.userSurveysStore.loadAnonymousTakeVM(
        params.anonymousToken,
        params.orgId ? params.orgId : null
      )
    } else if (this.isPaperSurveyMode) {
      rootStore.userSurveysStore.loadPaperSurveyTakeVM(params.id)
    } else if (this.isAlwaysAvailableMode) {
      rootStore.userSurveysStore.loadAlwaysAvailableUserSurveyTakeVM(params.id)
    } else {
      rootStore.userSurveysStore.loadTakeVM(params.id)
    }
  }

  componentDidUpdate(props) {
    if (props.params.id) {
      if (
        rootStore.userSurveysStore.takeVM &&
        !this.isPaperSurveyMode &&
        props.params.id !== rootStore.userSurveysStore.takeVM.userSurvey.objectId
      ) {
        setTimeout(() => rootStore.userSurveysStore.loadTakeVM(props.params.id), 1000)
      }
    }
  }

  render() {
    const takeVM = rootStore.userSurveysStore.takeVM
    return (
      <div id='UserSurveyTakeScreen' style={this.isAnonymousMode ? { height: '100vh' } : null}>
        <Header takeVM={takeVM} />
        <TaskHeader takeVM={takeVM} />
        <UserSurveyTakeWidget takeVM={takeVM} />
        <UserTasksDrawer />
        <TaskDrawer />
      </div>
    )
  }
}

export default observer(UserSurveyTakeScreen)
