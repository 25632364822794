import { observable, computed, action, reaction, IReactionDisposer } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { ICMSItemUploadRequest } from '../interfaces/ICMSItemUploadRequest'
import { CMSItemsManageVM } from './CMSItemsManageVM'
import { CMSItemExternalVideoPreviewVM } from './CMSItemExternalVideoPreviewVM'
import { CMSItemsUploadService } from '../service/CMSItemsUploadService'

export class CMSItemsUploadVM {
  private rootStore: RootStore
  private reactions: IReactionDisposer[] = []
  private manageVM: CMSItemsManageVM

  constructor(rootStore: RootStore, manageVM: CMSItemsManageVM) {
    this.rootStore = rootStore
    this.lz = this.rootStore.localizationStore.lzStrings.contentManagement
    this.manageVM = manageVM
  }

  @observable public lz = undefined
  @observable public uploadFile: ICMSItemUploadRequest = null
  @observable public uploadFileClean: ICMSItemUploadRequest = null
  @observable public doneTried: boolean = false
  @observable public isLoading: boolean = false
  @observable public isSaving: boolean = false
  @observable public isOpen: boolean = false
  @observable public isEditing: boolean = false
  @observable public isDirty: boolean = false

  @observable public tags: string[] = []
  @observable public tenants: string[] = []
  @observable public title: string = ''
  @observable public description: string = ''
  @observable public extVideoUrl: string = ''
  @observable public isExternalVideo: boolean = false
  @observable public isPrivate: boolean = false
  @observable public extVideoPreviewVM: CMSItemExternalVideoPreviewVM = null
  @observable public showLightBox: boolean = false

  @computed
  public get file(): File {
    if (!this.uploadFile) return undefined
    return this.uploadFile.file
  }

  @computed
  public get canManage() {
    return this.manageVM.canManage
  }

  @computed
  public get canManageTags() {
    return this.manageVM.canManageTags
  }

  @computed
  public get isLoaded(): boolean {
    return this.manageVM.isLoaded
  }

  @computed
  public get isValid() {
    if (!this.isNameValid) return false
    if (!this.isDescriptionValid) return false
    if (!this.isExternalVideo && !this.isFileValid) return false
    if (this.isExternalVideo && !this.isExtVideoUrlValid) return false
    return true
  }

  @computed
  public get isNameValid() {
    if (!this.title && this.doneTried) return false
    if (this.title.trim() === '' && this.doneTried) return false
    return true
  }

  @computed
  public get isDescriptionValid() {
    if (!this.description && this.description !== '' && this.doneTried) return false
    return true
  }

  @computed
  public get isExtVideoUrlValid() {
    if (!this.isExternalVideo) return true
    if (!this.doneTried) return true
    const trimmed = this.extVideoUrl.toLowerCase().trim()
    if (trimmed.includes('vimeo.com/') && this.doneTried) return true
    if (trimmed.includes('youtube.com/') && this.doneTried) return true
    if (trimmed.includes('youtu.be/') && this.doneTried) return true
    return false
  }

  @computed
  public get isTagsValid() {
    if (!this.tags && this.doneTried) return false
    return true
  }

  @computed
  public get isTenantsValid() {
    if (!this.tenants && this.doneTried) return false
    return true
  }

  @computed
  public get isFileValid() {
    if (!this.file && this.doneTried) return false
    return true
  }

  @computed
  public get tagOptions() {
    return this.manageVM.tagOptions
  }

  @action
  public loadReactions() {
    this.reactions.forEach((dispose: IReactionDisposer) => dispose())

    this.reactions.push(
      reaction(
        () => JSON.stringify(this.title),
        () => {
          if (this.uploadFile) this.deepEqual(this.uploadFile, this.title)
        }
      )
    )

    this.reactions.push(
      reaction(
        () => JSON.stringify(this.description),
        () => {
          if (this.uploadFile) this.deepEqual(this.uploadFile, this.description)
        }
      )
    )
  }

  @action
  public setUpload(upload: ICMSItemUploadRequest, keepFields: boolean = false) {
    this.uploadFile = upload
    if (!upload) {
      this.uploadFileClean = upload
      if (!keepFields) {
        this.setTitle('')
        this.setDescription('')
      }
    } else {
      let opts = undefined
      if (upload.file && upload.file.type) {
        opts = { type: upload.file.type }
      }
      this.uploadFileClean = {
        title: upload.title,
        description: upload.description,
        file: new File([upload.file], upload.file.name, opts),
        organizationId: this.rootStore.appStore.currentOrgId,
        isPrivate: upload.isPrivate,
        privateTo: upload.privateTo ? upload.privateTo : undefined,
      }
      this.setTitle(upload.title)
      this.setDescription(upload.description)
    }

    if (!keepFields) this.loadReactions()
  }

  @action
  public setTitle = (title: string) => {
    this.title = title
  }

  @action
  public setExtVideoUrl = (extVideoUrl: string) => {
    this.extVideoUrl = extVideoUrl
    if (this.extVideoPreviewVM) this.extVideoPreviewVM.setUrl(this.extVideoUrl)
  }

  @action
  public setDescription = (description: string) => {
    this.description = description
  }

  @action
  public toggleShowUploadDialog = () => {
    this.isOpen = !this.isOpen
  }

  @action
  public toggleExtVideoEnabled = () => {
    this.isExternalVideo = !this.isExternalVideo
    if (this.isExternalVideo) {
      this.extVideoPreviewVM = new CMSItemExternalVideoPreviewVM('')
      this.setUpload(undefined, true)
    } else {
      this.extVideoPreviewVM = undefined
      this.setExtVideoUrl('')
    }
  }

  @action
  public toggleLightBox() {
    this.showLightBox = !this.showLightBox
  }

  @action
  public enableExtVideo = () => {
    this.isExternalVideo = true
  }

  @action
  public disableExtVideo = () => {
    this.isExternalVideo = false
  }

  @action
  public togglePrivateEnabled = () => {
    this.isPrivate = !this.isPrivate
  }

  @action
  public setPrivate = (isPrivate: boolean) => {
    this.isPrivate = isPrivate
  }

  @action
  public enablePrivate = () => {
    this.isPrivate = true
  }

  @action
  public disablePrivate = () => {
    this.isPrivate = false
  }

  @action
  public openUploadDialog = () => {
    this.isOpen = true
  }

  @action
  public closeUploadDialog = () => {
    this.isOpen = false
  }

  @action
  public reset() {
    this.setTitle(this.uploadFileClean ? this.uploadFileClean.title : '')
    this.setDescription(this.uploadFileClean ? this.uploadFileClean.description : '')
    this.setUpload(this.uploadFileClean ? this.uploadFileClean : undefined)
    this.tags = this.uploadFileClean && this.uploadFileClean.tags ? this.uploadFileClean.tags : []
    this.tenants = []
    this.doneTried = false
    this.isExternalVideo =
      this.uploadFileClean && this.uploadFileClean.extVideoUrl
        ? Boolean(this.uploadFileClean.extVideoUrl)
        : false
    this.extVideoUrl = this.isExternalVideo ? this.uploadFileClean.extVideoUrl : ''
    this.extVideoPreviewVM = this.isExternalVideo
      ? new CMSItemExternalVideoPreviewVM('')
      : undefined
    this.isDirty = false
  }

  @action
  public clear() {
    this.setUpload(undefined)
    this.tags = []
    this.tenants = []
    this.doneTried = false
    this.isExternalVideo = false
    this.extVideoUrl = ''
    this.extVideoPreviewVM = undefined
    this.isDirty = false
  }

  @action
  public handleUpload(cb?: Function) {
    const req = {
      title: this.title,
      description: this.description,
      file: this.file,
      organizationId: this.rootStore.appStore.currentOrgId,
      extVideoUrl: this.extVideoUrl !== '' ? this.extVideoUrl.trim() : undefined,
      isPrivate: this.isPrivate,
      privateTo: this.isPrivate ? this.rootStore.appStore.currentUserId : undefined,
      tags: this.tags,
    } as ICMSItemUploadRequest

    const cmsItemsSvc = new CMSItemsUploadService(this.rootStore)
    cmsItemsSvc.uploadItem(req, () => {
      this.manageVM.refreshData()
      this.isSaving = false
      this.closeUploadDialog()
      this.clear()
      if (cb) cb()
    })
  }

  @action
  public setTagValues(vals: string[]) {
    this.tags = vals
  }

  @action
  public setTenantValues(vals: string[]) {
    this.tenants = vals
  }

  @action
  public deepEqual(object1, object2) {
    if (this.isSaving) return
    const keys1 = Object.keys(object1)
    const keys2 = Object.keys(object2)
    if (keys1.length !== keys2.length) {
      this.isDirty = true
    }
    for (const key of keys1) {
      const val1 = object1[key]
      const val2 = object2[key]
      // added to avoid false flag on empty mobx arrays
      if (JSON.stringify(val1) === '[]' && JSON.stringify(val2) === '[]') return
      if (val1 !== val2) {
        this.isDirty = true
      }
      const areObjects = this.isObject(val1) && this.isObject(val2)
      if (areObjects && !this.isDirty) this.deepEqual(val1, val2)
    }
    if (!this.isObject(object1) && !this.isObject(object2)) {
      if (object1 !== object2) {
        this.isDirty = true
      }
    }
  }

  @action
  public disposeReactions() {
    if (!this.reactions) return
    this.reactions.forEach((dispose: IReactionDisposer) => dispose())
  }

  @action
  public isObject(object) {
    return object != null && typeof object === 'object'
  }
}
