import { observable, action } from 'mobx'
import { serializable, deserialize, serialize, date } from 'serializr'
import { Task } from '../../tasks/aggregates/Task'
import { IUserTaskDTO } from '../dtos/IUserTaskDTO'
import moment from 'moment'
import { IAggregate } from 'src/app/shared/data/IAggregate'

export class UserTask extends Task implements IUserTaskDTO, IAggregate {
  @serializable @observable public userId: string
  @serializable @observable public taskId: string
  @serializable @observable public surveyId: string
  @serializable @observable public isCompleted: boolean
  @serializable @observable public isRead: boolean = false
  @serializable(date()) @observable public readDateTime: Date = null

  public isOnServer: boolean = false
  public isProcessing: boolean = false

  @action
  public markIsNotOnServer() {
    this.isOnServer = false
  }

  @action
  public markIsNotProcessing() {
    this.isProcessing = false
  }

  @action
  public markIsProcessing() {
    this.isProcessing = true
  }

  @action
  public markIsOnServer() {
    this.isOnServer = true
  }

  public clone(): UserTask {
    return deserialize(UserTask, this.serialize())
  }

  public serialize(): IUserTaskDTO {
    let obj = serialize(this)
    obj.dueDate = moment(this.dueDate).toISOString()
    return obj
  }
}
