import React from 'react'
import { inject, observer, useLocalStore } from 'mobx-react'
import SurveyTemplatesTable from '../SurveyTemplatesTable'
import { Card, CardContent } from '@material-ui/core'
import SurveyTemplateFoldersPicker from '../../../survey-template-folders/views/SurveyTemplateFoldersPicker'
import { SurveyTemplateFoldersPickerVM } from '../../../survey-template-folders/view-models/SurveyTemplateFoldersPickerVM'
import { SurveyTemplatesTableVM } from '../../view-models/SurveyTemplatesTableVM'
import './SurveyTemplatesFoldersTable.scss'

interface Props {
  folderVM: SurveyTemplateFoldersPickerVM
  tableVM: SurveyTemplatesTableVM
}

const SurveyTemplatesFoldersTable: React.FC<Props> = ({ folderVM, tableVM }) => {
  return (
    <div id={'SurveyTemplatesFoldersTable'}>
      <div className={'survey-templates-manage'}>
        <div className='dashboard-left'>
          <Card elevation={0} className={'folders-card'}>
            <CardContent>
              <SurveyTemplateFoldersPicker vm={folderVM} />
            </CardContent>
          </Card>
        </div>
        <div className='dashboard-right'>
          <div id='AllSurveyTemplatesCard'>
            <SurveyTemplatesTable vm={tableVM} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default inject('rootStore')(observer(SurveyTemplatesFoldersTable))
