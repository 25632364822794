import React from 'react'
import { inject, observer } from 'mobx-react'
import {
  Drawer,
  Paper,
  Card,
  GridList,
  GridListTile,
  CardHeader,
  IconButton,
  Grid,
} from '@material-ui/core'
import './WidgetsDrawer.scss'
import CloseIcon from '@material-ui/icons/Close'
import { DashboardsStore } from '../../DashboardsStore'
import Searchbar from '../../../shared/Searchbar'

interface Props {
  dashboardsStore?: DashboardsStore
}

const WidgetsDrawer: React.FC<Props> = ({ dashboardsStore }) => {
  const vm = dashboardsStore.editVM
  if (!vm) return null
  const widgetDrawerVM = vm.widgetDrawerVM
  if (!widgetDrawerVM) return null

  const toggleDrawer = () => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }
    vm.toggleShowWidgetDrawer()
  }

  const renderAvailableWidgets = () => {
    return widgetDrawerVM.availableWidgets.map((widget, i) => (
      <GridListTile
        key={i}
        className={widget.isUsed ? 'disabled grid-list-item' : 'grid-list-item'}
      >
        <div onClick={widget.isUsed ? null : () => vm.addWidget(widget.type)}>
          <p style={{ fontSize: '14px' }}>{widget.name}</p>
          <Paper elevation={0}>
            <img src={widget.img} width='100%' height='auto' alt=''></img>
          </Paper>
        </div>
      </GridListTile>
    ))
  }

  const renderDrawerHeader = () => {
    return (
      <div id='MuiDrawerHeader'>
        <CardHeader
          action={
            <>
              <Grid
                container
                direction='row'
                justifyContent='flex-end'
                alignItems='center'
                className='paging-row'
              >
                <Grid item>
                  <Searchbar
                    iconColor='white'
                    focused={widgetDrawerVM.filter.length > 0}
                    className='search'
                    searchValue={widgetDrawerVM.filter}
                    onSearchChange={(e) => widgetDrawerVM.setFilter(e.target.value)}
                  />
                </Grid>
                <Grid item>
                  <IconButton onClick={() => vm.toggleShowWidgetDrawer()}>
                    <CloseIcon color='secondary' />
                  </IconButton>
                </Grid>
              </Grid>
            </>
          }
          title={'Widgets'}
        />
      </div>
    )
  }

  return (
    <div id='WidgetsDrawer'>
      <Drawer
        variant='temporary'
        anchor='right'
        open={vm.showWidgetDrawer}
        onClose={toggleDrawer()}
        className='widget-drawer'
      >
        <Card className='widget-card' elevation={0}>
          {renderDrawerHeader()}
          <GridList cols={3} cellHeight='auto' spacing={20} className='grid-list'>
            {renderAvailableWidgets()}
          </GridList>
        </Card>
      </Drawer>
    </div>
  )
}

export default inject('dashboardsStore')(observer(WidgetsDrawer))
