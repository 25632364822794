import axios from 'axios'
import env from '../../../env'
import { RootStore } from '../../stores/RootStore'

export class TableauAuthTokensService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public async getAuthToken() {
    const token = this.rootStore.userStore.parseUserInfo.sessionToken
    const orgId = this.rootStore.appStore.currentOrgId
    const url = env.var.REACT_APP_API_URL + '/tableauAuthTokens/authToken/?sessionToken=' + token
    const response = await axios.post(url, { orgId })
    return response.data ? response.data.data : null
  }
}
