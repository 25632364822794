import { observable, action } from 'mobx'
import { IPrivilegeDTO } from '../dtos/IPrivilegeDTO'
import { serializable, serialize } from 'serializr'
import { IAggregate } from 'src/app/shared/data/IAggregate'

export default class Privilege implements IPrivilegeDTO, IAggregate {
  static create() {
    const privilege = new Privilege()
    return privilege
  }

  @serializable @observable objectId: string = ''
  @serializable @observable name: string = ''
  @serializable @observable shortDescription: string = ''
  @serializable @observable longDescription: string = ''
  @serializable @observable isProcessing: boolean = false
  @serializable @observable isDeleted: boolean = false
  isOnServer: boolean = false

  markIsNotOnServer() {
    this.isOnServer = false
  }

  markIsOnServer() {
    this.isOnServer = true
  }

  @action
  markIsNotProcessing() {
    this.isProcessing = false
  }

  markIsProcessing() {
    this.isProcessing = true
  }

  public serialize() {
    return serialize(this)
  }
}
