import Parse from 'parse'
import { RootStore } from '../../stores/RootStore'
import { PagedDataVM } from '../../shared/view-models/PagedDataVM'
import { DataStore } from '../../shared/data/DataStore'
import { Schedule } from '../aggregate/Schedule'
import { IEventScheduleDTO } from '../dtos/IEventScheduleDTO'
import { action, computed, reaction } from 'mobx'

export class EventSchedulesStore extends DataStore<Schedule, IEventScheduleDTO> {
  private loadedEvents: string[] = []

  constructor(rootStore: RootStore) {
    super(rootStore, Schedule, 'eventSchedules', [
      'objectId',
      'organizationId',
      'eventId',
      'enabled',
      'days',
      'repeat',
      'every',
      'endingMode',
      'endDate',
      'events',
      'loaded',
    ])
    this.reactToWatchingEvents()
  }

  private reactToWatchingEvents() {
    if (process.env.NODE_ENV === 'test') return
    reaction(
      () => this.watchingEvents,
      () => {
        if (!this.listRecordsLoaded) return
        this.loadListRecords()
      },
      { delay: 500 }
    )
  }

  protected getBaseQuery() {
    const query = new Parse.Query(this.className)
    query.equalTo('organizationId', this.rootStore.appStore.currentOrgId)
    query.equalTo('enabled', true)
    query.containedIn('eventId', this.watchingEvents)
    return query
  }

  @computed
  private get watchingEvents() {
    const list = this.rootStore.userEventsStore.currentOrgUserEvents
      .map((e) => e.eventId)
      .sort((a, b) => (a < b ? -1 : 0))
    const set = new Set(list)
    return Array.from(set)
  }

  protected shouldLoadListRecords() {
    if (this.loadedEvents.length === 0) return true
    return JSON.stringify(this.loadedEvents) !== JSON.stringify(this.watchingEvents)
  }

  public onListRecordsLoaded() {
    this.setLoadedEvents(this.watchingEvents)
  }

  @action
  private setLoadedEvents(eventIds: string[]) {
    this.loadedEvents = eventIds
  }

  public getEventSchedule(eventId: string) {
    return this.records.find((e) => e.eventId === eventId)
  }
}
