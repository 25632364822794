import Parse from 'parse'
import { action, observable, runInAction, computed, reaction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Event } from '../aggregate/Event'
import { CalendarVM } from '../view-models/CalendarVM'
import { EventEditVM } from '../view-models/EventEditVM'
import { UserEventPopupVM } from '../view-models/UserEventPopupVM'
import { SurveyPopupVM } from '../view-models/SurveyPopupVM'
import { TaskPopupVM } from '../view-models/TaskPopupVM'
import { TrainingPlanPopupVM } from '../view-models/TrainingPlanPopupVM'
import { TrainingItemPopupVM } from '../view-models/TrainingItemPopupVM'
import { IEventPillVM } from '../intefaces/IEventPillVM'
import { EventsService } from '../services/EventsService'
import { IEventDTO } from '../dtos/IEventDTO'
import { DataStore } from '../../shared/data/DataStore'
import { EventPopupVM } from '../view-models/EventPopupVM'
import moment from 'moment'

export class EventsStore extends DataStore<Event, IEventDTO> {
  private loadedUsers: string[] = []

  constructor(rootStore: RootStore) {
    super(rootStore, Event, 'events', [
      'objectId',
      'organizationId',
      'organizer',
      'participants',
      'title',
      'startDate',
      'endDate',
      'schedule',
      'separateFromMasterEventId',
      'masterEventId',
      'readStatus',
    ]) ///TODO: Do we need to load events
    this.calendarVM = new CalendarVM(this.rootStore)
    this.reactToWatchingUsers()
  }

  private reactToWatchingUsers() {
    if (process.env.NODE_ENV === 'test') return
    reaction(
      () => this.watchingUsers,
      () => {
        if (!this.listRecordsLoaded) return
        this.loadListRecords()
      },
      { delay: 500 }
    )
  }

  protected getFullColumns() {
    return [
      'startDate',
      'endDate',
      'dayIndex',
      'occurrenceStartDate',
      'allDay',
      'title',
      'notes',
      'isDeleted',
      'locationId',
      'eventTypeId',
      'repeat',
      'organizer',
      'masterEventId',
      'onTimeAlarm',
      'separateFromMasterEventId',
      'schedule',
      'participants',
      'resources',
      'alarms',
      'userIds',
      'readUserIds',
      'goingNewUserIds',
      'goingYesUserIds',
      'goingMaybeUserIds',
      'goingNoUserIds',
      'occurrenceId',
      'attachments',
    ]
  }

  // TODO: Move "Popup" methods to calendarVM. They seem to belong there more than here
  @observable public calendarVM: CalendarVM = null
  @observable public editVM: EventEditVM = null
  @observable public userEventPopupVM: UserEventPopupVM = null
  @observable public eventPopupVM: EventPopupVM = null
  @observable public surveyPopupVM: SurveyPopupVM = null
  @observable public taskPopupVM: TaskPopupVM = null
  @observable public trainingPlanPopupVM: TrainingPlanPopupVM = null
  @observable public trainingItemPopupVM: TrainingItemPopupVM = null

  public async lazyLoadListRecords() {
    let retry = false
    if (!this.rootStore.isHydrated) retry = true
    if (!this.rootStore.appStore.currentOrgId) retry = true
    if (retry) {
      setTimeout(() => this.lazyLoadListRecords(), 500)
      return
    }
    await this.loadListRecords()
    await this.rootStore.userEventsStore.loadListRecords()
  }
  
  protected getBaseQuery() {
    return Parse.Query.or(this.getParticipantsQuery(), this.getOrganizerQuery())
  }

  private getParticipantsQuery() {
    const query = new Parse.Query(this.className)
    query.equalTo('organizationId', this.rootStore.appStore.currentOrgId)
    query.containedIn('participants.id', this.watchingUsers.slice())
    query.notEqualTo('isDeleted', true)
    return query
  }

  private getOrganizerQuery() {
    const query = new Parse.Query(this.className)
    query.equalTo('organizationId', this.rootStore.appStore.currentOrgId)
    query.equalTo('organizer', this.rootStore.appStore.currentUserId)
    query.notEqualTo('isDeleted', true)
    return query
  }

  public onListRecordsLoaded() {
    this.calendarVM.refresh()
    this.setLoadedUsers(this.watchingUsers.slice())
    this.records.forEach((ev) => ev.cleanAlarmValues())
  }

  @computed
  public get watchingUsers() {
    if (!this.rootStore.userEventsStore) {
      return [this.rootStore.appStore.currentUserId]
    }
    return this.rootStore.userEventsStore.watchingUsers
  }

  @action
  public openEventPopupFromLink(eventId: string, orgId: string, attempts: number = 0) {
    if (attempts === 14) return
    const eventPill = this.calendarVM.formattedEvents.filter(
      (eventPill) => eventPill.userEvent && eventPill.userEvent.eventId === eventId
    )[0]
    if (!eventPill) {
      setTimeout(() => this.openEventPopupFromLink(eventId, orgId, attempts++), 500)
      return
    }
    if (eventPill) {
      const svc = new EventsService()
      svc.markUserEventAsRead(eventPill.userEvent.objectId, orgId)
      this.calendarVM.onNavigate(eventPill.localStartDate)
      this.loadPopup(eventPill)
      setTimeout(() => {
        const pill = document.getElementById(eventId)
        if (pill) pill.click()
      }, 200)
    }
    this.loadPopup(eventPill)
    setTimeout(() => {
      const pill = document.getElementById(eventId)
      pill.click()
    }, 100)
  }

  @action
  public createEventForUser(userId, attempts = 0) {
    const maxAttempts = 14
    attempts += 1
    if (attempts === maxAttempts) {
      console.log('giving up')
      return
    }
    const foundUser = this.rootStore.audienceMembersStore.audienceMembers.find(
      (member) => member.objectId === userId
    )
    if (!foundUser) {
      setTimeout(() => this.createEventForUser(userId, attempts++), 500)
      if (attempts === 13) console.log('user not found: ' + userId)
      return
    }
    this.editVM = new EventEditVM(
      this.rootStore,
      Event.create(this.rootStore.appStore.currentOrgId)
    )
    this.editVM.setDatesToDefault()
    this.editVM.participantsSelectVM.addParticipantFromAudienceMember(foundUser)
    if (this.editVM.participantsSelectVM.participants)
      this.editVM.eventParticipants = this.editVM.participantsSelectVM.participants
    this.calendarVM.openEventDrawer()
  }

  @action
  public createEventForGroup(groupId, attempts = 0) {
    const maxAttempts = 14
    attempts += 1
    if (attempts === maxAttempts) {
      console.log('giving up')
      return
    }
    const foundGroup = this.rootStore.audienceMembersStore.allCurrentOrgGroups.find(
      (group) => group.objectId === groupId
    )
    if (!foundGroup) {
      setTimeout(() => this.createEventForGroup(groupId, attempts++), 500)
      if (attempts === 13) console.log('group not found: ' + groupId)
      return
    }
    this.editVM = new EventEditVM(
      this.rootStore,
      Event.create(this.rootStore.appStore.currentOrgId)
    )
    this.editVM.setDatesToDefault()
    this.editVM.participantsSelectVM.addParticipantFromAudienceMember(foundGroup)
    if (this.editVM.participantsSelectVM.participants)
      this.editVM.eventParticipants = this.editVM.participantsSelectVM.participants
    this.calendarVM.openEventDrawer()
  }

  @action
  public createEventForRole(roleId, attempts = 0) {
    const maxAttempts = 14
    attempts += 1
    if (attempts === maxAttempts) {
      console.log('giving up')
      return
    }
    const foundRole = this.rootStore.audienceMembersStore.allCurrentOrgRoles.find(
      (role) => role.objectId === roleId
    )
    if (!foundRole) {
      setTimeout(() => this.createEventForRole(roleId, attempts++), 500)
      if (attempts === 13) console.log('role not found: ' + roleId)
      return
    }
    this.editVM = new EventEditVM(
      this.rootStore,
      Event.create(this.rootStore.appStore.currentOrgId)
    )
    this.editVM.setDatesToDefault()
    this.editVM.participantsSelectVM.addParticipantFromAudienceMember(foundRole)
    if (this.editVM.participantsSelectVM.participants)
      this.editVM.eventParticipants = this.editVM.participantsSelectVM.participants
    this.calendarVM.openEventDrawer()
  }

  @action
  public async lazyLoadEventEditVM(
    id: string,
    startDate?: Date,
    endDate?: Date,
    attempts: number = 0
  ) {
    if (attempts === 14) return
    if (!this.rootStore.organizationsStore.currentOrganization) {
      setTimeout(() => this.lazyLoadEventEditVM(id, startDate, endDate, attempts++), 500)
      return
    }
    if (id === 'empty') {
      this.editVM = null
      return
    }
    if (id === 'new') {
      this.editVM = new EventEditVM(
        this.rootStore,
        Event.create(this.rootStore.appStore.currentOrgId)
      )
      this.editVM.setDatesToDefault()
      if (startDate) {
        if (this.calendarVM.viewModeName !== 'month') {
          const startMoment = moment(startDate)
          const endMoment = moment(endDate)
          this.editVM.setStartTime(startMoment.hour(), startMoment.minute())
          this.editVM.setEndTime(endMoment.hour(), endMoment.minute())
        }
        this.editVM.setStartDate(startDate)
        this.editVM.setEndDate(endDate)
      }
      return
    }
    const foundEvent = await this.getFullRecord(id)
    if (!foundEvent) {
      setTimeout(() => this.lazyLoadEventEditVM(id, startDate, endDate, attempts++), 500)
      return
    }
    if (foundEvent.masterEventId && foundEvent.masterEventId !== foundEvent.objectId) {
      const clonedEvent = foundEvent.clone()
      clonedEvent.separateFromMasterEvent()
      this.editVM = new EventEditVM(this.rootStore, clonedEvent)
      return
    }
    this.editVM = new EventEditVM(this.rootStore, foundEvent.clone())
  }

  @action
  public loadEventEditVMFromEvent(event: Event) {
    this.editVM = new EventEditVM(this.rootStore, event)
  }

  @action
  public destroyEventEditVM() {
    this.editVM = null
  }

  public shouldLoadListRecords() {
    if (this.loadedUsers.length === 0) return true
    return JSON.stringify(this.loadedUsers) !== JSON.stringify(this.watchingUsers)
  }

  @action
  private setLoadedUsers(userIds: string[]) {
    this.loadedUsers = userIds
  }

  @action
  public loadPopup(pill: IEventPillVM) {
    if (pill.userSurvey) {
      this.surveyPopupVM = new SurveyPopupVM(this.rootStore, this.calendarVM, pill.userSurvey)
      return
    }
    if (pill.userTask) {
      this.taskPopupVM = new TaskPopupVM(this.rootStore, this.calendarVM, pill.task, pill.userTask)
      return
    }
    if (pill.userTrainingPlan) {
      this.trainingPlanPopupVM = new TrainingPlanPopupVM(
        this.rootStore,
        this.calendarVM,
        pill.userTrainingPlan
      )
      return
    }
    if (pill.trainingItem) {
      this.trainingItemPopupVM = new TrainingItemPopupVM(
        this.rootStore,
        this.calendarVM,
        pill.trainingItem,
        pill.parentId
      )
      return
    }
    if (pill.groupId) {
      this.eventPopupVM = new EventPopupVM(this.rootStore, this.calendarVM, pill)
      return
    }
    if (pill.roleId) {
      this.eventPopupVM = new EventPopupVM(this.rootStore, this.calendarVM, pill)
      return
    }
    if (pill.userEvent) {
      const svc = new EventsService()
      svc.markUserEventAsRead(pill.userEvent.objectId, pill.userEvent.organizationId)
      this.userEventPopupVM = new UserEventPopupVM(this.rootStore, this.calendarVM, pill)
      return
    }
    this.userEventPopupVM = new UserEventPopupVM(this.rootStore, this.calendarVM, pill)
  }

  @computed
  public get currentOrgEvents(): Array<Event> {
    return this.records.filter((e) => e.organizationId === this.rootStore.appStore.currentOrgId)
  }

  public getEvent(objectId: string): Event {
    return this.records.find((e) => e.objectId === objectId)
  }

  @computed
  public get eventsByOrganizer() {
    return this.currentOrgEvents.filter(
      (e) => e.organizer === this.rootStore.appStore.currentUserId
    )
  }
}
