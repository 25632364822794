import React from 'react'
import { observer, Observer } from 'mobx-react'
import { CircularProgress } from '@material-ui/core'
import rootStore from '../../stores/RootStore'
import './AuthenticatedRoute.scss'

export function authenticate(Component) {
  class AuthenticatedRoute extends React.Component<any> {
    render() {
      rootStore.appStore.setRouter(this.props.router)

      return (
        <Observer>
          {() => {
            const proceeed = rootStore.appStore.checkAuth()
            if (!proceeed) {
              console.log('waiting for auth')
              return (
                <div className='authentication' key={'au' + rootStore.appStore.authCheckedTimes}>
                  <span className='loading'>
                    <CircularProgress
                      color='secondary'
                      style={{ position: 'fixed', top: '50%', left: '50%' }}
                    />
                  </span>
                </div>
              )
            } else {
              return <Component {...this.props} />
            }
          }}
        </Observer>
      )
    }
  }
  return observer(AuthenticatedRoute)
}
