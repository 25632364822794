import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Menu, { MenuProps } from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MoreIcon from '@material-ui/icons/MoreVert'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import { inject, observer } from 'mobx-react'
import { Divider } from '@material-ui/core'
import { QuestionVM } from '../../view-models/QuestionVM'
import { QuizVM } from '../../view-models/QuizVM'

interface OptionMenuProps {
  quiz: QuizVM
  idx: number
}

const OptionMenu: React.FC<OptionMenuProps> = ({ quiz, idx }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  if (!quiz.currentQuestion) return null

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button className='more-button' onClick={handleClick}>
        <MoreIcon />
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} elevation={1}>
        {idx === 0 ? null : (
          <MenuItem onClick={() => quiz.currentQuestion.moveOptionUp(idx)}>
            <ListItemIcon>
              <ArrowUpwardIcon />
            </ListItemIcon>
            <ListItemText primary='Move Up' />
          </MenuItem>
        )}
        {idx >= quiz.currentQuestion.options.length - 1 ? null : (
          <MenuItem onClick={() => quiz.currentQuestion.moveOptionDown(idx)}>
            <ListItemIcon>
              <ArrowDownwardIcon />
            </ListItemIcon>
            <ListItemText primary='Move Down' />
          </MenuItem>
        )}
        <Divider />
        <MenuItem
          onClick={() => {
            quiz.currentQuestion.deleteOption(idx)
            handleClose()
          }}
        >
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText primary='Delete' />
        </MenuItem>
      </Menu>
    </div>
  )
}

export default inject('surveysStore')(observer(OptionMenu))
