import { RootStore } from '../../../../stores/RootStore'
import { computed, action, observable } from 'mobx'
import MyTrainingPlansWidgetVM from './MyTrainingPlansWidgetVM'
import moment from 'moment'
import { UserTrainingPlan } from '../../aggregate/UserTrainingPlan'
import { TrainingPlan } from '../../../../training-plans/aggregates/TrainingPlan'
import { MyTrainingsTimelineItemRowVM } from './MyTrainingsTimelineItemRowVM'

export class MyTrainingsTimelinePlanRowVM {
  private userTrainingPlan: UserTrainingPlan
  private rootStore: RootStore
  private manageWidgetVM: MyTrainingPlansWidgetVM

  constructor(
    rootStore: RootStore,
    manageWidgetVM: MyTrainingPlansWidgetVM,
    userTrainingPlan: UserTrainingPlan
  ) {
    this.rootStore = rootStore
    this.userTrainingPlan = userTrainingPlan
    this.manageWidgetVM = manageWidgetVM
  }

  @observable public isExpanded: boolean = false

  @computed
  public get objectId(): string {
    return this.userTrainingPlan.objectId
  }

  @computed
  public get trainingPlan(): TrainingPlan {
    return this.rootStore.trainingPlansStore.getTrainingPlan(this.userTrainingPlan.trainingPlanId)
  }

  @computed
  public get title(): string {
    if (!this.userTrainingPlan) return ''
    return this.userTrainingPlan.title
  }

  @computed
  public get isArchived(): boolean {
    return this.userTrainingPlan.isArchived
  }

  @computed
  public get isRead(): boolean {
    return this.userTrainingPlan.isRead
  }

  @computed
  public get isReadonly(): boolean {
    return Boolean(this.userTrainingPlan.isReadonly)
  }

  @computed
  public get isForTrainingFlow(): boolean {
    return Boolean(this.userTrainingPlan.trainingPlanFlowId)
  }

  @computed
  public get userOwned(): boolean {
    return this.userTrainingPlan.assignedByUserId === this.rootStore.appStore.currentUserId
  }

  @computed
  public get activeDate(): string {
    if (!this.userTrainingPlan.activeDate) return moment().toISOString()
    return this.userTrainingPlan.activeDate
  }

  @computed
  public get isActive(): boolean {
    if (
      moment(moment(this.activeDate).toDate()).isBefore(moment()) &&
      moment(moment(this.dueDate).endOf('day').toDate()).isSameOrAfter(moment())
    )
      return true

    return false
  }

  @computed
  public get isPastDue(): boolean {
    if (moment().isAfter(moment(this.dueDate).endOf('day'))) return true
    return false
  }

  @computed
  public get daysPastDue(): string {
    if (!this.isPastDue) return ''
    const numberOfDays = moment().diff(this.dueDate, 'days')
    const day = numberOfDays > 1 ? 'days' : 'day'
    const daysPastDue = `${numberOfDays} ${day} past due`
    return daysPastDue
  }

  @computed
  public get isComplete(): boolean {
    if (this.userTrainingPlan.isComplete) return true

    return false
  }

  @computed
  public get dueDate(): string {
    return this.userTrainingPlan.dueDate
  }

  @computed
  public get dueClass(): string {
    let classStr = ''
    if (!this.isRead) classStr = classStr.concat(' unread-plan')
    if (this.isPastDue && !this.isComplete) classStr = classStr.concat(' past-due')
    return classStr
  }

  @computed
  public get arrowClasses(): string {
    if (this.isPastDue && this.isExpanded) return 'circle circle-due arrow-up'
    if (!this.isPastDue && this.isExpanded) return 'circle circle-active arrow-up'
    if (this.isPastDue && !this.isExpanded) return 'circle circle-due arrow-down'
    if (!this.isPastDue && !this.isExpanded) return 'circle circle-active arrow-down'
  }
  
  @computed
  public get lockClasses(): string {
    if (!this.isComplete && this.isPastDue) return 'lock lock-due'
    return 'lock lock-active'
  }

  @computed
  public get dueText(): string {
    if (!this.isRead) return 'NEW'
    if (this.isPastDue && !this.isComplete) return this.daysPastDue
    const dueDate = moment(this.dueDate).format('M/D/YY')
    return `Due: ${dueDate}`
  }

  @computed
  public get dueDay(): string {
    return moment(this.dueDate).format('DD')
  }

  @computed
  public get dueMonth(): string {
    return moment(this.dueDate).format('MMM')
  }

  @computed
  public get totalItems(): number {
    let totalItems = 0
    this.userTrainingPlan.trainingPlanItems.forEach((item) => {
      totalItems = totalItems + item.contentItems.length
    })
    return totalItems
  }

  @computed
  public get associateToGoal(): boolean {
    if (!this.trainingPlan) return null
    return this.trainingPlan.associateToGoals
  }

  @computed
  public get associatedGoalId(): string {
    if (!this.trainingPlan) return null
    return this.trainingPlan.associatedGoalId
  }

  @computed
  public get associatedGoalName(): string {
    if (!this.associateToGoal) return null
    const id = this.trainingPlan.associatedGoalId
    const goal = this.rootStore.goalsStore.getGoal(id)
    if (goal) return goal.name
    return 'goal'
  }

  @computed
  public get progress(): number {
    return this.userTrainingPlan.completion
  }

  @action
  public openUserTrainingPlan() {
    this.rootStore.appStore.router.push(`/userTrainingPlans/take/${this.objectId}`)
  }

  @computed
  public get numberOfPlanItems(): string {
    if (this.userTrainingPlan.trainingPlanItems.length === 1) return '1 Item'
    return `${this.userTrainingPlan.trainingPlanItems.length} Items`
  }

  @computed
  public get numberOfQuizzes(): string {
    let quizzes = 0
    this.userTrainingPlan.trainingPlanItems.forEach((item) => {
      if (item.quiz && item.requireQuiz) quizzes++
    })
    if (quizzes === 0) return ''
    if (quizzes === 1) return ` + 1 Quiz`
    return ` + ${quizzes} Quizzes`
  }

  @computed
  public get numberOfCertificates(): string {
    let certificates = 0
    this.userTrainingPlan.trainingPlanItems.forEach((item) => {
      if (item.requireCertificate) certificates++
    })
    if (certificates === 0) return ''
    if (certificates === 1) return ' + 1 Certificate'
    return ` + ${certificates} Certificates`
  }

  @computed public get requireQuiz(): boolean {
    return this.userTrainingPlan.requireQuiz
  }

  @computed public get requireCertificate(): boolean {
    return this.userTrainingPlan.requireCertificate
  }

  @computed public get certificateUploaded(): boolean {
    if (!this.userTrainingPlan.certificate) return false
    if (this.userTrainingPlan.certificate.url) return true
    return false
  }

  @computed
  public get variesByItem(): boolean {
    return this.userTrainingPlan.dueDateVariesByTrainingItem
  }

  @action
  public openUserTrainingPlanQuiz() {
    this.rootStore.appStore.router.push(
      `/userTrainingPlans/take/${this.objectId}/forquiz/${this.userTrainingPlan.quiz.id}`
    )
  }

  @action
  public openUserTrainingPlanCertificate() {
    this.rootStore.appStore.router.push(`/userTrainingPlans/take/${this.objectId}/forcert/for-plan`)
  }

  @computed
  public get subTitle(): string {
    return `${this.numberOfPlanItems} ${this.numberOfQuizzes} ${this.numberOfCertificates}`
  }

  @computed
  public get mustCompleteItemsInOrder(): boolean {
    return this.userTrainingPlan.mustCompleteInOrder
  }

  @computed
  public get quizQuestionCount(): string {
    let questions = 0
    this.userTrainingPlan.quiz.questions.forEach((item) => {
      questions++
    })
    if (questions > 1) return `${questions} questions`
    if (questions === 1) return `${questions} question`
  }

  @computed public get allItemsComplete(): boolean {
    if (this.userTrainingPlan.trainingPlanItems.some((item) => !item.isComplete)) return false
    return true
  }

  @computed
  public get quizCompletionAllowed(): boolean {
    if (!this.allItemsComplete && this.userTrainingPlan.mustCompleteInOrder) {
      return false
    }
    return true
  }

  @computed public get quizComplete(): boolean {
    if (!this.userTrainingPlan.quiz.isComplete) return false
    return true
  }

  @computed
  public get certUploadAllowed(): boolean {
    if (this.requireQuiz) {
      if (this.allItemsComplete && this.quizComplete) return true
      return false
    }
    if (this.allItemsComplete) return true
    return false
  }

  @computed
  public get itemRows(): MyTrainingsTimelineItemRowVM[] {
    return this.userTrainingPlan.trainingPlanItems.map(
      (e) => new MyTrainingsTimelineItemRowVM(this.rootStore, this, e)
    )
    // .filter(
    //   (row) =>
    //     (row.isActive && !row.isArchived && !row.isComplete) ||
    //     (row.isPastDue && !row.isArchived && !row.isComplete)
    // )
  }

  @action
  public toggleIsExpanded() {
    this.isExpanded = !this.isExpanded
  }
}
