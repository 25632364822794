import { action, computed, observable } from 'mobx'
import { LabelsStore } from '../../labels/store/LabelsStore'
import { RootStore } from '../../stores/RootStore'
import { EmailTemplate } from '../aggregate/EmailTemplate'
import { AGGridRowVM } from '../../shared/ag-grid/AGGridRowVM'
import { EmailTemplatesTableVM } from './EmailTemplatesTableVM'

export class EmailTemplateRowVM extends AGGridRowVM<EmailTemplate> {
  constructor(rootStore: RootStore, emailTemplate: EmailTemplate, tableVM: EmailTemplatesTableVM) {
    super(rootStore, emailTemplate, tableVM)
    this.labelsStore = this.rootStore.labelsStore
    this.tableVM = tableVM
  }

  @observable public labelsStore: LabelsStore = null
  @observable public popoverOpen: boolean = false
  @observable public anchorEl: HTMLElement | null = null
  @observable public tableVM: EmailTemplatesTableVM = null

  @computed
  public get infoString(): string {
    return this.notificationTypeId + this.name + this.isDefault
  }

  @computed
  public get objectId(): string {
    return this.aggregate.objectId
  }

  @computed
  public get name(): string {
    return this.aggregate.name
  }

  @computed
  public get notificationTypeId(): string {
    return this.aggregate.notificationTypeId
  }

  @computed
  public get isDefault(): boolean {
    return this.aggregate.isDefault
  }

  @computed
  public get thumbnailUrl(): string {
    return `https:${this.aggregate.thumbnailUrl}`
  }

  @action
  public openEmailTemplate() {
    if (this.tableVM.isSystemTable) {
      this.rootStore.emailTemplatesStore.initSysEmailTemplate(this.objectId)
    } else {
      this.rootStore.emailTemplatesStore.initOrgEmailTemplate(this.objectId)
    }
  }

  @action
  public revertEmailTemplate() {
    //reverting a template deletes and restores to the system template
    this.rootStore.emailTemplatesStore.deleteEmailTemplate(this.objectId)
  }

  @action
  public deleteEmailTemplate() {
    this.rootStore.emailTemplatesStore.deleteEmailTemplate(this.objectId)
  }

  @action
  public setAnchorEl(anchorEl: HTMLElement | null) {
    this.anchorEl = anchorEl
  }
}
