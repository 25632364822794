import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Menu, { MenuProps } from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import { observer, inject } from 'mobx-react'
import { PulseQuestionOptionVM } from '../../view-models/PulseQuestionOptionVM'
import './EmojiMenu.scss'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles((theme) => ({
  // root: {
  //   '&:focus': {
  //     backgroundColor: theme.palette.primary.main,
  //     '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
  //       color: theme.palette.common.white,
  //     },
  //   },
  // },
}))(MenuItem)

interface EmojiMenuProps {
  vm: PulseQuestionOptionVM
}

const WeightMenu: React.FC<EmojiMenuProps> = ({ vm }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  if (!vm) return null

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const emojiClicked = (name) => {
    handleClose()
    vm.setEmoji(name)
  }

  const getEmojis = () => {
    let imageItems = require.context('../../../../assets/emoticons/png').keys()
    let images = imageItems.map((imageName) => {
      imageName = imageName.substring(imageName.indexOf('/') + 1)
      imageName = imageName.substring(0, imageName.indexOf('.'))
      const isSelected = vm.emoji === imageName
      return (
        <div key={'img' + vm.index + imageName} onClick={() => emojiClicked(imageName)}>
          <ListItemIcon>
            <img
              className={isSelected ? 'selected' : ''}
              src={'../../../../assets/emoticons/png/' + imageName + '.png'}
              style={{
                width: 20,
                height: 20,
                margin: 5,
              }}
            />
          </ListItemIcon>
        </div>
      )
    })
    return images
  }

  return (
    <div id={'PulseOptionEmojiMenu'}>
      <Button
        className='emoji-button'
        variant={'outlined'}
        onClick={handleClick}
      >
        {vm.emoji ? (
          <img
            src={'../../../../assets/emoticons/png/' + vm.emoji + '.png'}
            style={{ width: 20, height: 20 }}
          />
        ) : null}
      </Button>

      {anchorEl ? (
        <StyledMenu
          id='customized-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: 240,
              flexWrap: 'wrap',
              justifyContent: 'space-evenly',
            }}
          >
            {getEmojis()}
          </div>
        </StyledMenu>
      ) : null}
    </div>
  )
}

export default inject('surveysStore')(observer(WeightMenu))
