import { Grid } from '@material-ui/core'
import { observer } from 'mobx-react'
import React from 'react'
import DescriptionIcon from '@material-ui/icons/Description'
import ImageIcon from '@material-ui/icons/ImageRounded'
import VideoIcon from '@material-ui/icons/VideocamRounded'
import { IExternalVideo } from '../../interfaces/IExternalVideo'
import { CMSItemVM } from '../../view-models/CMSItemVM'
import { CMSItemsLayoutTableRowVM } from '../../view-models/CMSItemsLayoutTableRowVM'
import VimeoImage from '../../../shared/VimeoImage'
import YouTubeImage from '../../../shared/YouTubeImage'
import './PreviewCell.scss'

const PreviewCell = (props) => {
  const data: CMSItemsLayoutTableRowVM = props.data
  if (!data) return <div></div>

  const item = data.item
  if (!item) return <div></div>

  const handleImageLoadError = (item: CMSItemVM) => {
    item.handleImageLoadError()
  }

  const handleImageLoad = (item: CMSItemVM) => {
    item.handleImageLoad()
  }

  const renderIcon = (item: CMSItemVM) => {
    if (!item) return <DescriptionIcon className={'media-preview file'} />
    if (item.type === 'image') return <ImageIcon className={'media-preview file'} />
    if (item.type === 'video') return <VideoIcon className={'media-preview file'} />
    return <DescriptionIcon className={'media-preview file'} />
  }

  const renderType = (item: CMSItemVM) => {
    return <div className={'cell-content-card-container-type'}>{renderIcon(item)}</div>
  }

  const renderThumbnail = (item: CMSItemVM) => {
    if (!item) return
    if (!item.thumbnail) return

    const renderThumbnailImg = () => {
      if (item.type === 'image') {
        return (
          <div className={'cell-preview-img'}>
            <img
              className={item.loading ? 'media-off' : 'media-preview image'}
              key={`cell-img-${item.title}-${item.itemId}`}
              src={item.thumbnail}
              alt={item.altText}
              onLoad={() => handleImageLoad(item)}
            />
          </div>
        )
      } else if (item.type === 'video') {
        if (item.hasOptimizedVideo) {
          return (
            <img
              className={item.loading ? 'media-off' : 'media-preview image'}
              key={`cell-video-${item.title}-${item.itemId}`}
              src={`https://image.mux.com/${item.thumbnail}/thumbnail.png`}
              alt={item.altText}
              onLoad={() => {
                handleImageLoad(item)
              }}
              onError={() => {
                handleImageLoadError(item)
              }}
            />
          )
        } else {
          if (item.hasExternalVideo) {
            const extVideo: IExternalVideo = item.item.External_Video
            if (extVideo.provider === 'youtube') return renderYouTubeVideo(extVideo.providerUid)
            if (extVideo.provider === 'vimeo') return renderVimeoVideo(extVideo.providerUid)
          }
          return (
            <video
              className={item.loading ? 'media-off' : 'media-preview image'}
              key={`cell-ext-video-${item.title}-${item.itemId}-alt`}
              src={`${item.thumbnail}`}
              preload='metadata'
              onLoadedData={() => handleImageLoad(item)}
              onError={() => {
                handleImageLoadError(item)
              }}
            />
          )
        }
      }

      handleImageLoad(item)
      return <DescriptionIcon className={'media-preview file'} />
    }
    return renderThumbnailImg()
  }

  const renderYouTubeVideo = (providerUid: string) => {
    return (
      <YouTubeImage
        providerUuid={providerUid}
        height={50}
        width={75}
        onLoad={() => handleImageLoad(item)}
        onError={() => handleImageLoadError(item)}
        style={{ zIndex: 9 }}
      />
    )
  }

  const renderVimeoVideo = (providerUid: string) => {
    return (
      <VimeoImage
        providerUuid={providerUid}
        height={50}
        width={75}
        onLoad={() => handleImageLoad(item)}
        onError={() => handleImageLoadError(item)}
        style={{ zIndex: 9 }}
      />
    )
  }

  const renderPreview = (item: CMSItemVM) => {
    return (
      <Grid item xs={12} key={`attachment-container-preview`} className='attachment-container'>
        {renderType(item)}
        {renderThumbnail(item)}
      </Grid>
    )
  }

  return <div className='preview-cell'>{renderPreview(item)}</div>
}

export default observer(PreviewCell)
