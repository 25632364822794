import React from 'react'
import './QuestionRowDivider.scss'
import { QuestionVM } from '../../view-models/QuestionVM'
import isMobile from '../../../../utils/isMobile'

interface Props {
  question: QuestionVM
}

const QuestionRowDivider: React.FC<Props> = ({ question }) => {
  return (
    <div
      id='QuestionRowDivider'
      style={{ borderLeft: isMobile ? 'none' : '1px solid #cacace' }}
      className={question.blurred ? 'blurred' : ''}
    >
      <div className='indicator'></div>
    </div>
  )
}

export default QuestionRowDivider
