import { RootStore } from '../../stores/RootStore'
import { observable, computed, action } from 'mobx'
import { ContactRowVM } from './ContactRowVM'
import { ColDef, GridOptions, RowClickedEvent } from 'ag-grid-community'
import ContactImportVM from './ContactImportVM'
import { nameOf } from '../../shared/nameOf'
import { AGGridVM } from '../../shared/ag-grid/AGGridVM'

export class ContactListWidgetVM extends AGGridVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    super()
    this.rootStore = rootStore
  }

  @observable public showImportModal: boolean = false
  @observable public importVM: ContactImportVM

  @computed
  public get rows() {
    return this.rootStore.contactsStore.contacts.map(
      (contact) => new ContactRowVM(this.rootStore, contact, this)
    )
  }

  @action
  public getRowNodeId(row): string {
    return row.objectId
  }

  public get shouldRender(): boolean {
    if (!this.rootStore.contactsStore.loaded) return false
    if (!this.rootStore.clientsStore.loaded) return false
    return true
  }

  public getGridOptions(): GridOptions {
    return {
      ...this.baseGridOptions,
      rowData: this.rows,
      onRowClicked: (e) => this.rowClicked(e),
      getRowNodeId: (e) => this.getRowNodeId(e),
      columnDefs: this.ensureTooltipFields(this.columnDefs),
    }
  }

  public rowClicked(e: RowClickedEvent) {
    const row: ContactRowVM = e.data
    if (!row) return
    row.openContact()
  }

  @computed
  public get columnDefs(): ColDef[] {
    const s = this.rootStore.localizationStore.lzStrings
    return [
      {
        headerName: s.contactListWidget.name,
        field: nameOf<ContactRowVM, string>((e) => e.name),
        sort: 'asc',
      },
      {
        headerName: s.contactListWidget.title,
        field: nameOf<ContactRowVM, string>((e) => e.title),
      },
      {
        headerName: s.contactListWidget.email,
        field: nameOf<ContactRowVM, string>((e) => e.email),
      },
      {
        headerName: this.rootStore.labelsStore.getLabelByLanguageAndFor('client'),
        field: nameOf<ContactRowVM, string>((e) => e.clientName),
      },
    ]
  }

  @action
  public toggleImportModal() {
    this.showImportModal = !this.showImportModal
    this.importVM = null
    if (!this.showImportModal) return
    this.importVM = new ContactImportVM(this.rootStore, this)
  }
}
