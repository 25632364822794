import React from 'react'
import { InputLabel, TextField } from '@material-ui/core'

export default class DrawerBody extends React.Component<any, any> {
  render() {
    return (
      <div>
        <h6>Details</h6>
        <form>
          <InputLabel required>First Name</InputLabel>
          <TextField
            fullWidth
            id='outlined-name'
            margin='dense'
            variant='outlined'
            placeholder={'Placeholder'}
          />
        </form>

        <form>
          <InputLabel required>Last Name</InputLabel>
          <TextField
            fullWidth
            id='outlined-name'
            margin='dense'
            variant='outlined'
            placeholder={'Placeholder'}
          />
        </form>

        <form>
          <InputLabel>Nickname</InputLabel>
          <TextField
            fullWidth
            id='outlined-name'
            margin='dense'
            variant='outlined'
            placeholder={'Placeholder'}
          />
        </form>

        <h6>Contact</h6>
        <form>
          <InputLabel required>Phone Number</InputLabel>
          <TextField
            type='tel'
            fullWidth
            id='outlined-name'
            margin='dense'
            variant='outlined'
            placeholder={'Placeholder'}
          />
        </form>

        <form>
          <InputLabel required>Last Name</InputLabel>
          <TextField
            fullWidth
            id='outlined-name'
            margin='dense'
            variant='outlined'
            placeholder={'Placeholder'}
          />
        </form>

        <form>
          <InputLabel>Nickname</InputLabel>
          <TextField
            fullWidth
            id='outlined-name'
            margin='dense'
            variant='outlined'
            placeholder={'Placeholder'}
          />
        </form>
      </div>
    )
  }
}
