import React from 'react'
import { GoalsStore } from '../../../store/GoalsStore'
import { inject, observer } from 'mobx-react'
import {
  Grid,
  Typography,
  OutlinedInput,
  Select,
  MenuItem,
  Radio,
  InputAdornment,
  TextField,
  IconButton,
  FormControlLabel,
  Switch,
  Button,
  Popover,
} from '@material-ui/core'
import './DetailsTabPanel.scss'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { LabelsStore } from '../../../../labels/store/LabelsStore'
import CategoryPicker from '../../../../shared/category-picker/CategoryPicker'
import DateRangeModal from '../../DateRangeModal/DateRangeModal'
import CalendarIcon from '@material-ui/icons/CalendarTodayRounded'
import MeasureModal from './MeasureModal'

interface Props {
  goalsStore?: GoalsStore
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
}

const DetailsTabPanel: React.FC<Props> = ({ goalsStore, localizationStore, labelsStore }) => {
  const { goalEditVM: vm } = goalsStore
  const { goal_create: strings } = localizationStore.lzStrings

  if (!vm) return null

  const renderThumbnailInput = () => {
    return (
      <Grid item xs={12} className='details-item'>
        <Typography variant='h5' className='input-label'>
          {strings.thumbnail_image}
        </Typography>
        <OutlinedInput
          onFocus={() => vm.showWidget()}
          className='link-input'
          required
          fullWidth
          labelWidth={0}
          autoComplete='off'
          placeholder='Add Thumbnail'
          value={vm.thumbnailFileName}
          startAdornment={
            <InputAdornment position='start' className='start-adornment'>
              {vm.thumbnail && <img src={vm.thumbnail} />}
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position='end'>
              <Button className='browse-button' variant='outlined' onClick={() => vm.showWidget()}>
                Browse
              </Button>
            </InputAdornment>
          }
        />
      </Grid>
    )
  }

  const renderDatePicker = () => {
    return (
      <Grid item xs={12} sm={12} lg={12}>
        <Typography variant='h5' className='input-label'>
          {strings.goal_duration}
          <span className='required'>*</span>
        </Typography>
        <Grid item xs={12} className='duration-field'>
          <OutlinedInput disabled labelWidth={0} autoComplete='off' value={vm.startDate} />
          <Typography style={{ margin: '0 5px' }}>-</Typography>
          <OutlinedInput disabled labelWidth={0} autoComplete='off' value={vm.endDate} />
          <IconButton onClick={() => vm.toggleDateRangeModal()}>
            <CalendarIcon />
          </IconButton>
        </Grid>
        <DateRangeModal
          onChange={(range) => {
            vm.setRange(range)
          }}
          range={vm.range}
          open={vm.dateRangeModalOpen}
          onClose={() => vm.toggleDateRangeModal()}
        />
      </Grid>
    )
  }

  const renderGoalOverview = () => {
    return (
      <>
        <Grid item xs={12}>
          <Grid container direction='row' justifyContent='space-between'>
            <Grid item xs={5}>
              <Typography variant='h5' className='input-label'>
                {strings.goal_name}
                <span className='required'>*</span>
              </Typography>
              <OutlinedInput
                required
                fullWidth
                labelWidth={0}
                autoComplete='off'
                className='name-input'
                value={vm.name}
                onChange={(e) => vm.setName(e.target.value)}
                error={!vm.nameValid}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6} className='date-range'>
              {renderDatePicker()}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className='skill-input'>
          <Typography variant='h5' className='input-label'>
            {labelsStore.getLabelByLanguageAndFor('category')}
            <span className='required'>*</span>
          </Typography>
          <CategoryPicker
            fullWidth={true}
            error={!vm.categoryValid}
            onApply={(id) => vm.setCategory(id)}
            vm={vm.categoryPickerVM}
          />
        </Grid>

        <Grid item xs={12} className='optional-input'>
          <Typography variant='h5' className='input-label'>
            {strings.goal_description}
          </Typography>
          <OutlinedInput
            multiline
            fullWidth
            rows={2}
            labelWidth={0}
            autoComplete='off'
            value={vm.description}
            onChange={(e) => vm.setDescription(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h5' className='input-label'>
            {strings.goal_obstacles}
          </Typography>
          <OutlinedInput
            multiline
            fullWidth
            rows={2}
            labelWidth={0}
            autoComplete='off'
            value={vm.obstacles}
            onChange={(e) => vm.setObstacles(e.target.value)}
          />
        </Grid>
      </>
    )
  }

  const renderTargetValueOptions = () => {
    if (!vm.goalDirection) return null
    return (
      <>
        <Grid item xs={12} className='flex-and-center'>
          <Radio
            checked={vm.targetMode === 'percentage'}
            onClick={() => vm.setTargetMode('percentage')}
          />
          <Select
            disabled={vm.targetMode !== 'percentage'}
            className='percent-increase'
            variant='outlined'
            value={vm.goalDirection || ''}
            onChange={(e) => vm.setGoalDirection(e.target.value)}
          >
            <MenuItem value='increase'>Increase</MenuItem>
            <MenuItem value='decrease'>Decrease</MenuItem>
          </Select>

          <Typography variant='h6' className='by'>
            by
          </Typography>
          <TextField
            disabled={vm.targetMode !== 'percentage'}
            className='percent-increase'
            variant='outlined'
            type='number'
            onChange={(e) => vm.setTargetPercentage(e.target.value)}
            value={vm.targetPercentage}
            InputProps={{
              endAdornment: <InputAdornment position='end'>%</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12} className='flex-and-center specific-threshold'>
          <Radio checked={vm.targetMode === 'amount'} onClick={() => vm.setTargetMode('amount')} />
          <Typography variant='h6' className='specific-threshold-message'>
            Reach specific amount
          </Typography>
          <TextField
            className='specific-amount-input'
            disabled={vm.targetMode !== 'amount'}
            type='number'
            variant='outlined'
            onChange={(e) => vm.setTargetInputValue(e.target.value)}
            value={vm.targetInputValue}
          />
        </Grid>
      </>
    )
  }

  const renderDataSourceSelect = () => {
    return (
      <Grid item xs={6} lg={4} md={4} className='data-source'>
        <Typography variant='h5' className='input-label'>
          {strings.data_source}
          <span className='required'>*</span>
        </Typography>
        <Select
          variant='outlined'
          fullWidth
          value={vm.dataSourceId || ''}
          error={!vm.dataSourceValid}
          onChange={(e) => vm.setDataSource(e.target.value)}
        >
          {vm.dataSourceOptions &&
            vm.dataSourceOptions.map((source, index) => (
              <MenuItem key={index} value={source.id}>
                {source.name}
              </MenuItem>
            ))}
        </Select>
      </Grid>
    )
  }

  const renderGoalDetails = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          {/* <Grid container> */}
          {/* {renderDataSourceSelect()} */}
          {/* <Grid item xs={6} md={5} lg={4}>
              <Typography variant='h5' className='input-label'>
                {strings.improve}
                <span className='required'>*</span>
              </Typography>
              <Select
                variant='outlined'
                fullWidth
                error={!vm.facetValid}
                value={vm.facetId}
                onChange={(e) => vm.setFacetId(e.target.value)}
              >
                {vm.facetOptions &&
                  vm.facetOptions.map((option, index) => (
                    <MenuItem key={index} value={option.id}>
                      {option.field}
                    </MenuItem>
                  ))}
              </Select>
            </Grid> */}
          {/* </Grid> */}
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant='h5' className='input-label target-value'>
                {strings.target_value}
                <span className='required'>*</span>
              </Typography>
            </Grid>
            {renderTargetValueOptions()}
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderMeasurementOptions = () => {
    return (
      <Grid container item xs={12} direction='column' className='measurement'>
        <Typography variant='h5' className='input-label'>
          {strings.how_should_goal_be_measured}
        </Typography>
        <FormControlLabel
          control={
            <Radio
              checked={!vm.measureGoal}
              onClick={() => vm.setMeasureGoal(false)}
              value={false}
            />
          }
          label={strings.skip_this_step}
        />
        <Typography variant='body2' className='subtext'>
          {strings.actual_values}
        </Typography>
        <FormControlLabel
          control={
            <Radio checked={vm.measureGoal} onClick={() => vm.setMeasureGoal(true)} value={true} />
          }
          label={strings.achieve_this_goal}
        />
        <Typography variant='body2' className='subtext'>
          {strings.some_fields}
        </Typography>
      </Grid>
    )
  }

  const renderProgressMenu = () => {
    return (
      <Popover
        anchorEl={vm.progressAnchorEl}
        open={Boolean(vm.progressAnchorEl)}
        onClose={() => vm.setProgressAnchorEl(null)}
      >
        <Grid container className='interval-menu'>
          <Grid item xs={12} className='interval-menu-row'>
            <Button
              onClick={() => vm.setProgressInterval('daily')}
              variant={vm.progressInterval === 'daily' ? 'contained' : 'outlined'}
            >
              Daily
            </Button>
            <Button
              onClick={() => vm.setProgressInterval('weekly')}
              variant={vm.progressInterval === 'weekly' ? 'contained' : 'outlined'}
            >
              Weekly
            </Button>
          </Grid>
          <Grid item xs={12} className='interval-menu-row'>
            <Button
              onClick={() => vm.setProgressInterval('monthly')}
              variant={vm.progressInterval === 'monthly' ? 'contained' : 'outlined'}
            >
              Monthly
            </Button>
            <Button
              onClick={() => vm.setProgressInterval('quarterly')}
              variant={vm.progressInterval === 'quarterly' ? 'contained' : 'outlined'}
            >
              Quarterly
            </Button>
          </Grid>
        </Grid>
      </Popover>
    )
  }

  const renderRemindersMenu = () => {
    return (
      <Popover
        anchorEl={vm.reminderAnchorEl}
        open={Boolean(vm.reminderAnchorEl)}
        onClose={() => vm.setReminderAnchorEl(null)}
      >
        <Grid container className='interval-menu'>
          <Grid item xs={12} className='interval-menu-row'>
            <Button
              onClick={() => vm.setReminderInterval('daily')}
              variant={vm.reminderInterval === 'daily' ? 'contained' : 'outlined'}
            >
              Daily
            </Button>
            <Button
              onClick={() => vm.setReminderInterval('weekly')}
              variant={vm.reminderInterval === 'weekly' ? 'contained' : 'outlined'}
            >
              Weekly
            </Button>
          </Grid>
          <Grid item xs={12} className='interval-menu-row'>
            <Button
              onClick={() => vm.setReminderInterval('monthly')}
              variant={vm.reminderInterval === 'monthly' ? 'contained' : 'outlined'}
            >
              Monthly
            </Button>
            <Button
              onClick={() => vm.setReminderInterval('quarterly')}
              variant={vm.reminderInterval === 'quarterly' ? 'contained' : 'outlined'}
            >
              Quarterly
            </Button>
          </Grid>
        </Grid>
      </Popover>
    )
  }

  const renderProgressInterval = () => {
    if (!vm.askAboutProgress) return null
    return (
      <Typography
        variant='h6'
        className='interval-text'
        onClick={(e) => vm.setProgressAnchorEl(e.currentTarget)}
      >
        {vm.progressInterval.toUpperCase()}
      </Typography>
    )
  }

  const renderReminderInterval = () => {
    if (!vm.sendReminders) return null
    return (
      <Typography
        variant='h6'
        className='interval-text'
        onClick={(e) => vm.setReminderAnchorEl(e.currentTarget)}
      >
        {vm.reminderInterval.toUpperCase()}
      </Typography>
    )
  }

  const renderAssociateToTrainingPlan = () => {
    if (!vm.canCreateTrainings) return null
    return (
      <Grid item className='option-item'>
        <Typography variant='h5' className='input-label'>
          {strings.associate_training_plan}
        </Typography>
        <span className='option-inputs'>
          <Switch
            checked={vm.selectedTrainingPlans.length > 0 || vm.trainingPlansModalOpen}
            onChange={() => vm.openTrainingPlansModal()}
          />
        </span>
      </Grid>
    )
  }

  const renderProgressOptions = () => {
    return (
      <Grid container direction='row' className='option-container'>
        <Grid item className='option-item'>
          <Typography variant='h5' className='input-label'>
            {strings.ask_about_progress}
          </Typography>
          <span className='option-inputs'>
            <Switch
              checked={vm.askAboutProgress}
              onChange={(e) => vm.toggleAskAboutProgress(e.currentTarget)}
            />
            {renderProgressInterval()}
          </span>
          {renderProgressMenu()}
        </Grid>
        <Grid item className='option-item'>
          <Typography variant='h5' className='input-label'>
            {strings.send_reminders}
          </Typography>
          <span className='option-inputs'>
            <Switch
              checked={vm.sendReminders}
              onChange={(e) => vm.toggleSendReminders(e.currentTarget)}
            />
            {renderReminderInterval()}
          </span>
          {renderRemindersMenu()}
        </Grid>
        {renderAssociateToTrainingPlan()}
      </Grid>
    )
  }

  const renderAddToCatalog = () => {
    return (
      <Grid item className='details-item'>
        <Typography variant='h5' className='input-label'>
          {strings.add_to_catalog}
        </Typography>
        <span className='option-inputs'>
          <Switch checked={vm.addToCatalog} onChange={() => vm.toggleAddToCatalog()} />
        </span>
      </Grid>
    )
  }

  return (
    <Grid container direction='row' id='detailsTab'>
      <Grid item xs={5} className='goal-overview'>
        {renderGoalOverview()}
        {renderThumbnailInput()}
        {renderAddToCatalog()}
      </Grid>
      <Grid item xs={7} className='goal-details'>
        {renderGoalDetails()}
        {renderProgressOptions()}
        {/* {renderMeasurementOptions()} */}
      </Grid>
      <MeasureModal />
    </Grid>
  )
}

export default inject('goalsStore', 'localizationStore', 'labelsStore')(observer(DetailsTabPanel))
