import React from 'react'

//MobX Stores
import { inject, observer } from 'mobx-react'
import { QuestionsStore } from '../store/QuestionsStore'

//MaterialUI Components
import { Button } from '@material-ui/core'

//Material Icons
import AddIcon from '@material-ui/icons/Add'

//Components
import { SortableElement, SortableContainer } from 'react-sortable-hoc'
import OptionRow from './DrawerOptionRow'

//Styles
import './OptionsList.scss'

const SortableItem: any = SortableElement(({ value }) => (
  <div style={{ zIndex: 99999999 }} className='option-draggable-item'>
    {value}
  </div>
))

const SortableList: any = SortableContainer(({ items }) => {
  return (
    <div>
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} value={value} />
      ))}
    </div>
  )
})

interface OptionsListProps {
  questionsStore?: QuestionsStore
}

const OptionsList: React.FC<OptionsListProps> = ({ questionsStore }) => {
  const { editVM: QuestionCatalogWidgetForm } = questionsStore

  const options = QuestionCatalogWidgetForm.options.map((e, idx) => (
    <OptionRow option={e} idx={idx} key={'opt' + idx} />
  ))

  const onSortEnd = ({ oldIndex, newIndex }, e) => {
    if (oldIndex === newIndex) return
    QuestionCatalogWidgetForm.moveOption(oldIndex, newIndex)
  }

  return (
    <div id='OptionsList'>
      <SortableList distance={3} items={options} onSortEnd={onSortEnd} useDragHandle={true} />
      {QuestionCatalogWidgetForm.canAddMoreOptions && (
        <Button
          className='form-button'
          fullWidth
          onClick={() => QuestionCatalogWidgetForm.addOption()}
        >
          <AddIcon />
        </Button>
      )}
    </div>
  )
}

export default inject('questionsStore')(observer(OptionsList))
