import * as React from 'react'
import './OptionsList.scss'
import { inject, observer } from 'mobx-react'
import { TextField, InputAdornment, Button } from '@material-ui/core'
import EmojiMenu from './EmojiMenu'
import OptionMenu from './OptionMenu'
import OptionNextQuestionMenu from './OptionNextQuestionMenu'
import ResponseTypeMenu from './ResponseTypeMenu'
import WeightMenu from './WeightMenu'
import { SortableHandle } from 'react-sortable-hoc'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import { OptionVM } from '../../../view-models/OptionVM'

interface Props {
  option: OptionVM
  disabled: boolean
  idx: number
}

const DragHandle = SortableHandle(() => (
  <Button className='drag-handle'>
    <DragIndicatorIcon />
  </Button>
))

const renderEmojiMenu = (type, idx) => {
  if (type === 'order') return null
  return <EmojiMenu idx={idx} />
}

const OptionRow = ({ disabled, option, idx }: Props) => (
  <TextField
    disabled={disabled}
    className='survey-edit-side-panel-option-row-textfield'
    key={'opttxt' + idx}
    id={'opttxt' + idx}
    value={option.text || ''}
    onChange={(e) => option.setText(e.target.value)}
    margin='none'
    fullWidth
    variant='outlined'
    InputProps={{
      startAdornment: (
        <InputAdornment position='start'>
          <ResponseTypeMenu idx={idx} />
          {renderEmojiMenu(option.type, idx)}
          <WeightMenu idx={idx} option={option} />
        </InputAdornment>
      ),
      endAdornment: (
        <InputAdornment position='end'>
          <OptionNextQuestionMenu option={option} idx={idx} />
          <OptionMenu idx={idx} />
          <DragHandle />
        </InputAdornment>
      ),
    }}
  />
)

export default inject('surveysStore')(observer(OptionRow))
