import axios from 'axios'
import { RootStore } from '../../stores/RootStore'
import * as Sentry from '@sentry/browser'
import env from '../../../env' 
import { FileVM } from '../../upload/view-models/FileVM'
import { CMSAuthService } from '../../cms-auth/services/CMSAuthService'
import { ICMSFileEditRequest } from '../interfaces/ICMSEditFileRequest'

export class CMSFilesUploadService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  private getCMSApiUrl(): string {
    return env.var.REACT_APP_CMS_API_URL
  }

  public async uploadFile(vm: FileVM, cbAfterUpload?: Function): Promise<any> {
    try {
      const jwt = await CMSAuthService.getJWT()
      if (!jwt) return false

      let response = undefined
      if (vm.file) {
        const formData = new FormData()
        formData.append('files', vm.file)
        response = await axios.post(`${this.getCMSApiUrl()}/api/upload`, formData, {
          headers: { Authorization: 'Bearer ' + jwt },
        })
      }
      console.log('upload cms file response', response)

      if (cbAfterUpload) cbAfterUpload()

      return response
    } catch (e) {
      console.error(e)
      Sentry.captureException({
        message: 'Unable to upload cms file',
        exception: e,
        userId: this.rootStore.appStore.currentUserId,
        orgId: this.rootStore.appStore.currentOrgId,
      })
    }
  }

  public async editFile(req: ICMSFileEditRequest): Promise<boolean> {
    try {
      if (!req.id) return false

      const jwt = await CMSAuthService.getJWT()
      if (!jwt) return false

      const formData = new FormData()
      formData.append('fileInfo', JSON.stringify(req))
      //if (req.file) formData.append('files', req.file)

      await axios.post(`${this.getCMSApiUrl()}/api/upload?id=${req.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${jwt}`,
          Accept: '*/*',
        },
        transformRequest: (data) => {
          return data
        },
      })

      return true
    } catch (e) {
      console.error(e)
      Sentry.captureException({
        message: 'Unable to edit cms file',
        exception: e,
        userId: this.rootStore.appStore.currentUserId,
        orgId: this.rootStore.appStore.currentOrgId,
      })
    }
  }

}
