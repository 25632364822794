import React, { useState } from 'react'
import { Card, IconButton, MenuItem, Tooltip, Typography } from '@material-ui/core'
import './ActionsCell.scss'
import MoreVert from '@material-ui/icons/MoreVert'
import {
  useFloating,
  offset,
  flip,
  shift,
  autoUpdate,
  useClick,
  useDismiss,
  useRole,
  useId,
  useInteractions,
  FloatingFocusManager,
} from '@floating-ui/react'
import { SurveyTypesTableRowVM } from '../../view-models/SurveyTypesTableRowVM'

const ActionsCell = (props) => {
  const row: SurveyTypesTableRowVM = props.data
  const vm = props.data
  if (!row) return <div></div>

  const CustomPopover = () => {
    const [isOpen, setIsOpen] = useState(false)
    const { refs, floatingStyles, context } = useFloating({
      open: isOpen,
      onOpenChange: setIsOpen,
      middleware: [offset(5), flip({ fallbackAxisSideDirection: 'end' }), shift()],
      whileElementsMounted: autoUpdate,
    })

    const click = useClick(context)
    const dismiss = useDismiss(context)
    const role = useRole(context)

    const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role])

    const headingId = useId()

    return (
      <>
        <Tooltip title={'More Options'} enterDelay={500}>
          <IconButton ref={refs.setReference} {...getReferenceProps()}>
            <MoreVert />
          </IconButton>
        </Tooltip>
        {isOpen && (
          <FloatingFocusManager context={context} modal={true}>
            <Card
              className='custom-popover'
              elevation={8}
              ref={refs.setFloating}
              style={floatingStyles}
              aria-labelledby={headingId}
              {...getFloatingProps()}
            >
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  row.navigateToSurveyTypeEdit()
                }}
              >
                <Typography>Edit</Typography>
              </MenuItem>
              {/* <MenuItem
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  // row.copySurveyTemplate()
                  setIsOpen(false)
                }}
              >
                <Typography>Copy</Typography>
              </MenuItem> */}
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  row.openDeleteDialog()
                  setIsOpen(false)
                }}
              >
                <Typography>Delete</Typography>
              </MenuItem>
            </Card>
          </FloatingFocusManager>
        )}
      </>
    )
  }

  return (
    <div id='SurveyRowActionsCell'>
      {/* <Tooltip
        title={'Edit Survey Template'}
        enterDelay={500}
      >
        <IconButton onClick={(e) => {
						e.stopPropagation()
						e.preventDefault()
						vm.selectedRow = row
						row.navigateToEditPage()
					}}
        >
          <Edit />
        </IconButton>
      </Tooltip>
      
      <Tooltip
        title={'Delete Survey Template'}
        enterDelay={500}
      >
        <IconButton onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          vm.selectedRow = row
          row.toggleDeleteDialog()
          // vm and row are the same...why is this
        }}>
          <Delete />
        </IconButton>
      </Tooltip> */}
      <CustomPopover />
    </div>
  )
}

export default ActionsCell
