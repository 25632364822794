import React from 'react'
import { CardHeader } from '@material-ui/core'
import WidgetTitle from './WidgetTitle'
import { inject, observer } from 'mobx-react'
import Searchbar from 'src/app/shared/Searchbar'
import LocalizationStore from '../../../localization/LocalizationStore'
import { TasksStore } from '../../store/TasksStore'

interface WidgetTitleProps {
  localizationStore?: LocalizationStore
  tasksStore?: TasksStore
}

const WidgetHeader: React.FC<WidgetTitleProps> = ({ localizationStore, tasksStore }) => {
  const { lzStrings } = localizationStore
  const { taskManagementWidgetVM: vm } = tasksStore
  if (!vm) return null

  return (
    <CardHeader
      className='drag-handle'
      title={<WidgetTitle />}
      action={
        <Searchbar
          focused={vm.typedFilterText.length > 0}
          className='search'
          searchValue={vm.typedFilterText}
          onSearchChange={(e) => {
            vm.setQuickFilter(e.target.value)
          }}
        />
      }
    />
  )
}

export default inject('localizationStore', 'tasksStore')(observer(WidgetHeader))
