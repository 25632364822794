import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import DrawerLogoDropzone from './DrawerLogoDropzone'
import { OrganizationsStore } from '../../store/OrganizationsStore'

interface DrawerHeaderProps {
  organizationsStore?: OrganizationsStore
  localizationStore?: LocalizationStore
}

const DrawerHeader: React.FC<DrawerHeaderProps> = ({ localizationStore, organizationsStore }) => {
  const { lzStrings } = localizationStore
  const { editVM } = organizationsStore
  if (editVM.currentTabIndex !== 0) return null

  const renderLogoPreview = () => {
    return (
      <>
        <p className='field-label'>{lzStrings.organizationWidgetDrawer.logo_preview}</p>
        <img src={editVM.logoUrl} style={{ maxWidth: '95%', maxHeight: '35vh' }} alt='organization logo'/>
      </>
    )
  }
  return (
    <section>
      <h6>{lzStrings.organizationWidgetDrawer.logo}</h6>
      <form>
        <p>{lzStrings.organizationWidgetDrawer.logo_instructions}</p>
        {renderLogoPreview()}
        <DrawerLogoDropzone />
      </form>
    </section>
  )
}

export default inject('localizationStore', 'organizationsStore')(observer(DrawerHeader))
