import { computed, observable } from 'mobx'
import Parse from 'parse'
import { PulseCampaign } from '../aggregate/PulseCampaign'
import { IPulseCampaignDTO } from '../dto/IPulseCampaignDTO'
import { IPulseCampaignFindResult } from '../interfaces/IPulseCampaignFindResult'
import { IPulseCampaignTemplateGlobalRequest } from '../interfaces/IPulseCampaignTemplateGlobalRequest'
import { PulseCampaignsService } from '../service/PulseCampaignsService'
import { DataStore } from '../../shared/data/DataStore'
import { RootStore } from '../../stores/RootStore'

export class CampaignTemplatesGlobalDataStore extends DataStore<PulseCampaign, IPulseCampaignDTO> {
  private request: IPulseCampaignTemplateGlobalRequest

  constructor(rootStore: RootStore, request: IPulseCampaignTemplateGlobalRequest) {
    super(rootStore, PulseCampaign, 'pulseCampaigns', ['name', 'surveys'])
    this.request = request
    this.paged = true
    this.listenToListRecordsViaApiUpdates = true
  }

  @observable public isLoaded: boolean = false

  public onRecordUpdated(obj: PulseCampaign) {
    return this.loadListRecords()
  }

  public onListRecordsLoaded() {
    this.isLoaded = true
  }

  public setRequest(request: IPulseCampaignTemplateGlobalRequest) {
    this.request = request
  }

  public getRequest(): IPulseCampaignTemplateGlobalRequest {
    return this.request
  }

  protected getBaseQuery() {
    const query = new Parse.Query(this.className)
    return query
  }

  protected getListRecordsApiCall() {
    return async () => {
      const req: IPulseCampaignTemplateGlobalRequest = {
        ...this.request,
        listColumns: this.listColumns,
      }
      const svc = new PulseCampaignsService()
      const result: IPulseCampaignFindResult = await svc.findGlobalTemplates(req)
      this.totalRecords = result.count
      return result.pulseCampaigns
    }
  }

  public get rows() {
    return this.records
  }
}
