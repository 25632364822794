import { action, computed, observable } from 'mobx'
import { RootStore } from 'src/app/stores/RootStore'
import { NLPResponsesService } from '../../../nlpResponses/services/NLPResponsesService'
import { IUniqueTagsResponse } from '../../../nlpResponses/interfaces/IUniqueTagsResponse'
import { INLPModelStatusResponse } from '../../../nlpResponses/interfaces/INLPModelStatusResponse'
import { INLPModelRecord } from '../../../nlpResponses/interfaces/INLPModelRecord'

export class TrainAIVM {
  public rootStore: RootStore
  public textAnswer: string

  constructor(rootStore: RootStore, textAnswer: string) {
    this.rootStore = rootStore
    this.responseText = textAnswer
    this.svc = new NLPResponsesService()
    this.getLabels()
  }

  @observable public labels: Array<string> = []
  @observable public selectedLabels: Array<string> = []
  @observable public responseText: string = ''
  @observable public processing = false
  @observable public svc: NLPResponsesService = null
  @observable public didSave: boolean = false
  @observable public labelSaveTried: boolean = false
  @observable public errorMessage: string = ''
  @observable public showTrainDialog: boolean = false
  @observable public addNewLabel: boolean = false
  @observable public newLabel: string = ''

  @action
  public toggleAddNewLabel() {
    this.addNewLabel = !this.addNewLabel
    if (!this.addNewLabel) this.addNewLabel = null
  }

  @action
  public setLabels(val) {
    if (val[val.length - 1] && val[val.length - 1] === 'Add New...') {
      this.toggleAddNewLabel()
    } else {
      this.clearLabels()
      val.forEach((label) => this.addLabel(label))
    }
  }

  @action
  public setText(text) {
    this.responseText = text
  }

  @action
  public addLabel(label) {
    this.selectedLabels.push(label)
  }

  @action
  public setErrorMessage(message) {
    this.errorMessage = message
  }

  @action
  public clearLabels() {
    this.selectedLabels = []
  }

  @action
  public async getLabels() {
    const result: IUniqueTagsResponse = await this.svc.getUniqueLabels()
    this.labels = result.labels
  }

  @computed
  public get labelsValid() {
    if (!this.didSave) return true
    if (this.selectedLabels.length >= 1) return true
    return false
  }

  @computed
  public get textValid() {
    if (!this.didSave) return true
    if (this.responseText) return true
    return false
  }

  @computed
  public get allValid() {
    if (!this.didSave) return true
    if (!this.labelsValid) return false
    if (!this.textValid) return false
    return true
  }

  @action
  public async save() {
    this.didSave = true
    this.processing = true
    const labels = this.selectedLabels.map((e) => e)
    const record: INLPModelRecord = {
      classifications: labels,
      text: this.responseText,
    }
    if (!this.allValid) return
    const result: INLPModelStatusResponse = await this.svc.trainAIModel(record)
    this.processing = false

    if (result.success) {
      this.toggleTrainDialog()
      this.rootStore.surveysStore.viewModels.editVM.openSnackbar('AI Trained Successfully')
    } else {
      this.setErrorMessage(result.errorMessage)
    }
  }

  @action
  public toggleTrainDialog() {
    this.showTrainDialog = !this.showTrainDialog
  }

  @action
  public setNewLabel(val: string) {
    this.newLabel = val.replace(/\s/g, '')
  }

  @computed
  public get labelValid() {
    if (!this.newLabel && this.labelSaveTried) return false
    return true
  }

  @action
  public async saveNewLabel() {
    this.labelSaveTried = true
    if (!this.labelValid) return
    this.selectedLabels.push(this.newLabel)
    this.newLabel = ''
    this.labelSaveTried = false
    this.toggleAddNewLabel()
  }
}
