import { Button, Grid, IconButton, Tooltip, InputLabel } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { ConsentsStore } from '../store/ConsentsStore'
import FileUploadModal from '../../upload/views/FileUploadModal'
import { FileTypeEnum } from '../../upload/enum/FileTypeEnum'
import { Attachment } from '../../upload/aggregate/Attachment'
import CMSItemsPickerDialog from '../../cms-items/views/dialogs/CMSItemsPickerDialog'
import { CMSItemsTypeEnum } from '../../cms-items/enum/CMSItemsTypeEnum'
import { OrganizationsStore } from '../../organizations/store/OrganizationsStore'
import { AttachmentVM } from '../../attachments/view-models/AttachmentVM'
import { CMSItemAttachmentVM } from '../../attachments/view-models/CMSItemAttachmentVM'
import { OldAttachmentVM } from '../../attachments/view-models/OldAttachmentVM'
import CMSThumbnail from '../../thumbnail/views/CMSThumbnail'
import OldThumbnail from '../../thumbnail/views/OldThumbnail'
import './ConsentDrawerAttachments.scss'

interface Props {
  consentsStore?: ConsentsStore
  organizationsStore?: OrganizationsStore
}

const ConsentDrawerAttachments: React.FC<Props> = ({ consentsStore, organizationsStore }) => {
  const { drawerVM: vm } = consentsStore.consentWidgetVM

  const renderAttachmentThumbnail = (item: AttachmentVM, index: number) => {
    if (item.isCMSItem)
      return (
        <CMSThumbnail
          key={`consent-cms-attach-thumb-${index}`}
          vm={item as CMSItemAttachmentVM}
          index={index}
          onClose={() => vm.deleteAttachmentFromConsent(index)}
        />
      )
    return (
      <OldThumbnail
        key={`consent-old-attach-thumb-${index}`}
        vm={item as OldAttachmentVM}
        index={index}
        onClose={() => vm.deleteAttachmentFromConsent(index)}
      />
    )
  }

  const renderAttachments = () => {
    if (!vm.attachments) return null
    return (
      <div id='ConsentDrawerAttachments'>
        <form>
          <Grid container className='attachments-list'>
            {vm.attachments.map((doc, index) => {
              return renderAttachmentThumbnail(doc, index)
            })}
          </Grid>
        </form>
      </div>
    )
  }

  const renderUploadModal = () => {
    if (organizationsStore.currentOrganization?.hasCMSPicker) return renderCMSUploadModal()
    return renderStandardUploadModal()
  }

  const renderCMSUploadModal = () => {
    return (
      <CMSItemsPickerDialog
        allowMultiple={true}
        fileTypes={CMSItemsTypeEnum.FILES}
        addAfterSave={(arr: Attachment[]) => {
          const attachments = arr.map((a) => {
            const attachment = new Attachment()
            attachment.cmsItemId = a.cmsItemId
            return attachment
          })
          vm.addAttachments(attachments)
        }}
        toggleShowModal={() => vm.toggleShowUploadModal()}
        isOpen={vm.showUploadModal}
      />
    )
  }

  const renderStandardUploadModal = () => {
    return (
      <FileUploadModal
        allowMultiple={true}
        fileTypes={FileTypeEnum.PDF}
        addAfterSave={(a: Attachment[]) => vm.addAttachments(a)}
        toggleShowModal={() => vm.toggleShowUploadModal()}
        isOpen={vm.showUploadModal}
      />
    )
  }

  return (
    <Grid item xs={12} className='attachments-section'>
      {renderUploadModal()}
      <div className='attachments-header'>
        <InputLabel>Attachments</InputLabel>
        <Button variant='text' onClick={() => vm.toggleShowUploadModal()}>
          Add Attachments
        </Button>
      </div>
      {renderAttachments()}
    </Grid>
  )
}

export default inject('consentsStore', 'organizationsStore')(observer(ConsentDrawerAttachments))
