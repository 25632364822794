import React, { FC } from 'react'
import { inject, observer } from 'mobx-react'
import rootStore from '../../../stores/RootStore'
import WarningIcon from '@material-ui/icons/Warning'
import Step from './PulseCampaignEditStep'
import { PulseCampaignsStore } from '../../store/PulseCampaignsStore'
import PulseCampaignTopToolbarEndButtons from '../edit/PulseCampaignTopToolbarEndButtons'
import { PulseCampaignEditTabsEnum } from '../../type/PulseCampaignEditTabsEnum'
import './PulseCampaignEditStepper.scss'

import schedule from '../../../../assets/img/steps/schedule.svg'
import audience from '../../../../assets/img/steps/audience.svg'
import focus from '../../../../assets/img/steps/focus.svg'
import build from '../../../../assets/img/steps/build.svg'
import initiate from '../../../../assets/img/steps/initiate.svg'
import PulseCampaignEditOptions from '../edit/PulseCampaignEditOptions'

interface Props {
  pulseCampaignsStore?: PulseCampaignsStore
}

const Steppers: FC<Props> = ({ pulseCampaignsStore }) => {
  const showIcons = true
  const editVM = rootStore.pulseCampaignsStore.viewModels.editVM
  if (!editVM) return null
  const vm = editVM.steppersVM
  const { pulseCampaign: lz } = rootStore.localizationStore.lzStrings
  const DETAILS = vm.isPreview ? 1 : 0

  const renderDetailStepLabel = () => {
    if (vm.detailsError) return <WarningIcon className='warning-icon' />
    return showIcons ? <img className='custom-icon' alt='focus icon' src={focus} /> : undefined
  }

  const renderFocusStepLabel = () => {
    return showIcons ? <img className='custom-icon' alt='focus icon' src={focus} /> : undefined
  }

  const renderBuildStepLabel = () => {
    if (vm.buildError) return <WarningIcon className='warning-icon' />
    return showIcons ? <img className='custom-icon' alt='build icon' src={build} /> : undefined
  }

  const renderScheduleStepLabel = () => {
    if (vm.scheduleError) return <WarningIcon className='warning-icon' />
    return showIcons ? (
      <img className='custom-icon' alt='schedule icon' src={schedule} />
    ) : undefined
  }

  const renderAudienceStepLabel = () => {
    if (vm.audienceError) return <WarningIcon className='warning-icon' />
    return showIcons ? (
      <img className='custom-icon' alt='audience icon' src={audience} />
    ) : undefined
  }

  const renderInitiateStepLabel = () => {
    return showIcons ? (
      <img className='custom-icon' alt='initiate icon' src={initiate} />
    ) : undefined
  }

  const renderPublishTemplateStepLabel = () => {
    return showIcons ? (
      <img className='custom-icon' alt='initiate icon' src={initiate} />
    ) : undefined
  }

  const renderTemplateStepLabel = () => {
    if (vm.templateError) return <WarningIcon className='warning-icon' />
    return showIcons ? <img className='custom-icon' alt='focus icon' src={focus} /> : undefined
  }

  return (
    <div id='PulseCampaignSteppers'>
      <div className='left'>
        <div className='left-line' />
        {vm.isPreview && (
          <Step
            icon={renderTemplateStepLabel()}
            label='Template'
            index={PulseCampaignEditTabsEnum.TEMPLATES}
            error={vm.templateError}
            visited={vm.focusVisited}
          />
        )}
        <Step
          icon={renderDetailStepLabel()}
          label='Details'
          index={DETAILS}
          error={vm.detailsError}
          visited={vm.detailsVisited}
        />
        {!vm.isPreview && (
          <Step
            icon={renderFocusStepLabel()}
            label='Focus'
            index={PulseCampaignEditTabsEnum.FOCUS}
            visited={vm.focusVisited}
          />
        )}
        <Step
          icon={renderBuildStepLabel()}
          label='Build'
          index={PulseCampaignEditTabsEnum.BUILD}
          error={vm.buildError}
          visited={vm.buildVisited}
        />
        {(!vm.isTemplate || vm.isPreview) && (
          <Step
            icon={renderAudienceStepLabel()}
            label='Audience'
            index={PulseCampaignEditTabsEnum.AUDIENCE}
            error={vm.audienceError}
            visited={vm.audienceVisited}
          />
        )}
        {(!vm.isTemplate || vm.isPreview) && (
          <Step
            icon={renderScheduleStepLabel()}
            label='Schedule'
            index={PulseCampaignEditTabsEnum.SCHEDULE}
            error={vm.scheduleError}
            visited={vm.scheduleVisited}
          />
        )}
        {!vm.isPublished && (
          <Step
            icon={vm.isTemplate ? renderPublishTemplateStepLabel() : renderInitiateStepLabel()}
            label={`${vm.isTemplate} ? ${lz.save} : ${lz.initiate}`}
            index={PulseCampaignEditTabsEnum.INITIATE}
            visited={vm.initiateVisited}
          />
        )}
      </div>
      <PulseCampaignEditOptions />
    </div>
  )
}

export default inject('pulseCampaignsStore')(observer(Steppers))
