import React from 'react'
import { inject, observer } from 'mobx-react'
import { AgGridReact } from 'ag-grid-react/lib/agGridReact'
import AGGridWidgetPagingRow from '../../shared/ag-grid/AGGridPagingRow'
import { TaskTemplatesStore } from '../store/TaskTemplatesStore'

interface Props {
  taskTemplatesStore?: TaskTemplatesStore
}

const WidgetContent: React.FC<Props> = ({ taskTemplatesStore }) => {
  const vm = taskTemplatesStore.taskTypesWidgetVM
  if (!vm) return null
  if (!vm.shouldRender) return null

  const renderGrid = () => {
    return (
      <div className='ag-theme-alpine' style={{ height: '100%', width: '100%' }}>
        <AgGridReact
          gridOptions={{
            ...vm.getGridOptions(),
          }}
        />
      </div>
    )
  }

  return (
    <>
      {renderGrid()}
      <AGGridWidgetPagingRow vm={vm} />
    </>
  )
}

export default inject('taskTemplatesStore')(observer(WidgetContent))
