import { action, observable } from 'mobx'
import { Alarm } from './Alarm'
import { serializable, list, object } from 'serializr'
import { RootStore } from '../../stores/RootStore'

export class Alarms {
  static create(al: any) {
    const alarms = new Alarms()
    alarms.enabled = al.enabled
    alarms.selectedAlarms = al.selectedAlarms.map((e) => Alarm.create(e))
    alarms.sendRemindersCustomPeriod = al.sendRemindersCustomPeriod
    return alarms
  }

  @serializable @observable enabled: boolean = false
  @serializable(list(object(Alarm))) @observable selectedAlarms: Array<Alarm> = []
  @serializable @observable sendRemindersCustomPeriod: boolean = false

  @action
  public toggleSendReminders() {
    this.enabled = !this.enabled
    if (this.enabled) this.toggleSendRemindersCustomPeriod()
    if (!this.enabled) this.clearAllAlarms()
  }

  @action
  public toggleSendRemindersCustomPeriod() {
    this.sendRemindersCustomPeriod = !this.sendRemindersCustomPeriod
    if (this.enabled && this.sendRemindersCustomPeriod) {
      this.addAlarm({ mode: 'period', units: 1, period: 'day' })
    }
    if (this.enabled && !this.sendRemindersCustomPeriod) {
      this.deleteAlarm('period')
    }
  }

  @action
  public addAlarm(obj) {
    this.selectedAlarms.push(Alarm.create(obj))
  }

  @action
  public deleteAlarm(mode) {
    const index = this.selectedAlarms.findIndex((e) => e.mode === mode)
    this.selectedAlarms.splice(index, 1)
  }

  @action
  public clearAllAlarms() {
    this.selectedAlarms = []
  }

  @action
  public resetAlarms() {
    this.selectedAlarms = []
    this.sendRemindersCustomPeriod = false
  }
}
