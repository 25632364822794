import React, { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { EventsStore } from '../../../store/EventsStore'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { TextField } from '@material-ui/core'
import './CalendarEventDrawerLocationSearchBar.scss'
import LocationSearchBarSuggestion from './LocationSearchBarSuggestion'

interface Props {
  eventsStore?: EventsStore
}

const LocationSearchBar: React.FC<Props> = ({ eventsStore }) => {
  const { editVM: vm } = eventsStore
  // const [address, setAddress] = useState('')
  if (!vm) return null

  // useEffect(() => {
  //   var input = document.getElementById('calendarEventDrawerLocationSearchBar')
  //   var nativeInputValueSetter = Object.getOwnPropertyDescriptor(
  //     window.HTMLInputElement.prototype,
  //     'value'
  //   ).set
  //   nativeInputValueSetter.call(input, vm.locationText)
  //   var event = new Event('input', { bubbles: true })
  //   input.dispatchEvent(event)
  // }, [])

  const handleChange = (address) => {
    // console.log(address)
    // setAddress(address)
    vm.setNewLocationName(address)
  }

  const handleSelect = (address) => {
    // setAddress(address)
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => vm.setNewLocation(address, latLng))
      .catch((error) => console.error('Error', error))
  }

  return (
    <PlacesAutocomplete value={vm.newLocationName} onChange={handleChange} onSelect={handleSelect} ref={e => vm.setAutoCompleteRef(e)}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <TextField
            id='calendarEventDrawerLocationSearchBar'
            autoFocus
            variant='outlined'
            fullWidth
            {...getInputProps({
              placeholder: 'Search Places ...',
              className: 'location-search-input',
              autoFocus: true,
            })}
          />
          <div className='autocomplete-dropdown-container'>
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion) => {
              const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item'
              const style = suggestion.active
                ? { backgroundColor: '#fafafa' }
                : { backgroundColor: '#ffffff' }
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                  key={suggestion.placeId}
                >
                  <LocationSearchBarSuggestion suggestion={suggestion} />
                </div>
              )
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  )
}

export default inject('eventsStore')(observer(LocationSearchBar))
