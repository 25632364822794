import { observable, action } from 'mobx'
import { IAggregate } from 'src/app/shared/data/IAggregate'
import { serializable, serialize, list, object, deserialize, raw } from 'serializr'
import { PulseQuestionOption } from './PulseQuestionOption'
import { PulseQuestionType } from '../types/PulseQuestionType'
import { IPulseQuestionDTO } from '../dtos/IPulseQuestionDTO'
import { IPulseSetsDTO } from '../../pulse-sets/interfaces/IPulseSetsDTO'
import { RatingDisplayType } from '../../surveys/types/RatingDisplayType'
import { Media } from '../../questions/aggregates/Media'
import { FKPulseCategory } from './FKPulseCategory'
import uuid from 'uuid/v4'

export default class PulseQuestion implements IPulseQuestionDTO, IAggregate {
  static create() {
    const pulseQuestion = new PulseQuestion()
    pulseQuestion.setQuestionType('emoji')
    pulseQuestion.setDefaultOptions('emoji')
    return pulseQuestion
  }

  @serializable @observable public id: string = uuid()
  @serializable @observable objectId: string = ''
  @serializable @observable organizationId: string = undefined
  @serializable @observable originalPulseQuestionId: string = ''
  @serializable @observable pulseCategoryId: string = ''
  @serializable(object(FKPulseCategory)) @observable fk_pulseCategoryId: FKPulseCategory
  @serializable(object(FKPulseCategory)) @observable fk_parentCategoryId: FKPulseCategory
  @serializable(object(FKPulseCategory)) @observable fk_grandCategoryId: FKPulseCategory
  @serializable(list(object(PulseQuestionOption))) @observable options: Array<PulseQuestionOption> =
    []
  @serializable @observable title: string = ''
  @serializable @observable youTubeURL: string = ''
  @serializable @observable vimeoURL: string = ''
  @serializable @observable type: PulseQuestionType = 'emoji'
  @serializable @observable html: string = undefined
  @serializable @observable ratingDisplay: RatingDisplayType = 'horizontal'
  @serializable(object(Media)) @observable media: Media = new Media()
  @serializable @observable showMediaFirst: boolean = false
  @serializable @observable required: boolean = true
  @serializable @observable isDefault: boolean = false
  @serializable(raw()) @observable pulseSets: IPulseSetsDTO = {}
  @serializable @observable isDeleted: boolean = false
  public isOnServer: boolean = false

  @serializable @observable public minimumValueAllowed: number = 0
  @serializable @observable public maximumValueAllowed: number = 0
  @serializable @observable public numberStep: number = 0
  @serializable @observable public minimumRequired: boolean = false
  @serializable @observable public maximumRequired: boolean = false
  @serializable @observable public numberStepRequired: boolean = false
  @observable public isLinked: boolean = false
  @serializable @observable public isDropdownMultiSelect: boolean = false
  @serializable @observable public placeholder: string = 'Please Select'

  markIsNotOnServer() {
    this.isOnServer = false
  }

  markIsOnServer() {
    this.isOnServer = true
  }

  toggleIsRequired() {
    this.required = !this.required
  }

  toggleIsLinked() {
    this.isLinked = !this.isLinked
  }

  toggleShowMediaFirst() {
    this.showMediaFirst = !this.showMediaFirst
  }

  @action
  public toggleDropdownMultiSelect() {
    this.isDropdownMultiSelect = !this.isDropdownMultiSelect
  }

  @action
  public setId(id: string) {
    this.id = id
  }

  @action
  public setObjectId(objectId: string) {
    this.objectId = objectId
  }

  @action
  public setOrganizationId(organizationId: string) {
    this.organizationId = organizationId
  }

  @action
  public setTitle(title: string) {
    this.title = title
  }

  @action
  public setPlaceholder(placeholder: string) {
    this.placeholder = placeholder
  }

  @action
  public setYouTubeURL(url: string) {
    this.youTubeURL = url
  }

  @action
  public setVimeoURL(url: string) {
    this.vimeoURL = url
  }

  setDefaultOptions(type: PulseQuestionType) {
    this.options = []
    if (type === 'text') return this.createFromTextType()
    if (type === 'yesNo') return this.createFromYesNoType()
    this.addOption()
    this.options[0].responseType = 'very negative'
    this.options[0].text = 'Strongly Disagree'
    this.options[0].rank = 1
    this.options[0].value = 1
    this.addOption()
    this.options[1].responseType = 'negative'
    this.options[1].text = 'Disagree'
    this.options[1].rank = 2
    this.options[1].value = 2
    this.addOption()
    this.options[2].responseType = 'neutral'
    this.options[2].text = 'Neutral'
    this.options[2].rank = 3
    this.options[2].value = 3
    this.addOption()
    this.options[3].responseType = 'positive'
    this.options[3].text = 'Agree'
    this.options[3].rank = 4
    this.options[3].value = 4
    this.addOption()
    this.options[4].responseType = 'very positive'
    this.options[4].text = 'Strongly Agree'
    this.options[4].rank = 5
    this.options[4].value = 5
    if (type === 'emoji') this.setDefaultEmojis()
  }

  createFromTextType() {
    this.addOption()
    this.options[0].text = ''
    this.options[0].rank = 1
  }

  createFromYesNoType() {
    this.addOption()
    this.options[0].setText('Yes')
    this.options[0].responseType = 'positive'
    this.options[0].rank = 1
    this.addOption()
    this.options[1].setText('No')
    this.options[1].responseType = 'negative'
    this.options[1].rank = 2
  }

  setDefaultEmojis() {
    this.options[0].emoji = 'unhappy'
    this.options[1].emoji = 'confused'
    this.options[2].emoji = 'bored'
    this.options[3].emoji = 'smile'
    this.options[4].emoji = 'happy-1'
  }

  ensureRatingValues() {
    this.options.forEach((e, i) => {
      e.setValue(i + 1)
      e.setRank(i + 1)
    })
  }

  @action
  public moveOption(index, newIndex) {
    const optionObj = this.options.splice(index, 1)
    this.options.splice(newIndex, 0, optionObj[0])
  }

  @action
  public addOption() {
    this.options.push(new PulseQuestionOption())
    if (this.type === 'emoji') {
      this.options[this.options.length - 1].setEmoji('happy-4')
    }
  }

  @action
  public deleteOption(idx: number) {
    this.options.splice(idx, 1)
  }

  @action
  public togglePulseSet(objectId: string, name: string) {
    const pulseSets = JSON.parse(JSON.stringify(this.pulseSets))
    if (this.pulseSets[objectId]) {
      delete pulseSets[objectId]
      return (this.pulseSets = pulseSets)
    }
    pulseSets[objectId] = name
    return (this.pulseSets = pulseSets)
  }

  @action
  public setQuestionType(type: PulseQuestionType) {
    this.type = type
  }

  @action
  public setPulseCategoryId(categoryId: string) {
    this.pulseCategoryId = categoryId
  }

  @action
  public setRatingDisplay(ratingDisplay: RatingDisplayType) {
    this.ratingDisplay = ratingDisplay
  }

  @action
  public setOriginalPulseQuestionId(originalPulseQuestionId: string) {
    this.originalPulseQuestionId = originalPulseQuestionId
  }

  @action
  public removeMedia() {
    this.media = new Media()
    this.showMediaFirst = false
  }

  @action
  public addMedia(frm) {
    this.media = Media.create(frm)
  }

  public clone(): PulseQuestion {
    return deserialize(PulseQuestion, this.serialize())
  }

  public serialize(): IPulseQuestionDTO {
    try {
      const dto = serialize(this)
      return dto
    } catch (e) {
      console.error(e)
      console.log(this)
    }
  }
}
