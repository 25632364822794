import { action, computed } from 'mobx'
import { IParticipant } from '../../participants-select/interfaces/IParticipant'
import { ParticipantVM } from '../../participants-select/view-models/ParticipantVM'
import { AdditionalResponseNotificationParticipant } from '../aggregate/AdditionalResponseNotificationParticipant'

export class AdditionalResponseNotificationParticipantVM extends ParticipantVM {
  private participant: AdditionalResponseNotificationParticipant
  constructor(rootStore, participant: AdditionalResponseNotificationParticipant) {
    super(rootStore, participant as IParticipant)
    this.participant = participant
  }

  public toDTO(): AdditionalResponseNotificationParticipant {
    const dto = super.toDTO() as AdditionalResponseNotificationParticipant
    return dto
  }
}
