import { computed, observable, action, reaction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import _ from 'lodash'

export class PagedDataVM {
  rootStore: RootStore
  resizedTO: any

  constructor(
    rootStore: RootStore,
    data: Array<any>,
    filterColumns: Array<string>,
    defaultSort: string = null,
    componentId: string = '',
    keywordFilter: string = '',
    fixedRecordsPerPage: number = 0
  ) {
    this.fixedRecordsPerPage = fixedRecordsPerPage
    this.rootStore = rootStore
    this.data = data
    this.parentContainerId = componentId
    this.filterColumns = filterColumns
    this.keywordFilter = keywordFilter
    if (defaultSort) this.sort = defaultSort
    if (!this.rootStore) return
    if (!this.rootStore.appStore) return
    reaction(
      () => this.rootStore.appStore.widgetResizeCounter,
      () => this.refreshContainerHeight(),
      { delay: 500 }
    )
    setTimeout(() => this.refreshContainerHeight(), 1)
    window.onresize = () => {
      if (this.resizedTO) clearTimeout(this.resizedTO)
      this.resizedTO = setTimeout(() => this.resized++, 200)
    }
    reaction(
      () => this.resized,
      () => this.refreshContainerHeight(),
      { delay: 100 }
    )
  }

  @observable public keywordFilter: string = ''
  @observable public sort: string = 'name'
  @observable public pageNumber: number = 1
  @observable public data: Array<any> = []
  @observable public filterColumns: Array<string> = []
  @observable public parentContainerId: string = ''
  @observable public container: any = null
  @observable public tableHeight: number = 250
  @observable public renderedPages: number = 0
  @observable public resized: number = 0
  @observable public fixedRecordsPerPage: number = 0

  @action public getPageNumber() {
    return this.pageNumber
  }

  @action public setPageNumber(pageNumber: number) {
    this.pageNumber = pageNumber
  }

  @computed
  public get e() {
    return document.getElementById(this.parentContainerId)
  }

  @action
  public refreshContainerHeight(): any {
    if (this.parentContainerId === '') return

    let headerHeight = 52
    let tabBarHeight = 0
    const tableHeaderHeight = 32
    let footerHeight = 54

    if (
      this.parentContainerId === 'CalendarManagementWidget' ||
      this.parentContainerId === 'ManageGroupsAndRolesWidget'
    ) {
      tabBarHeight = 32
    }

    if (this.parentContainerId === 'WorksheetsListWidget') {
      footerHeight = 39
    }

    if (this.parentContainerId === 'AudienceMembersWidget') {
      headerHeight = 48
      footerHeight = 38.75
    }

    if (this.e && this.e.clientHeight) {
      this.tableHeight =
        this.e.clientHeight - headerHeight - footerHeight - tableHeaderHeight - tabBarHeight
      this.pageNumber = 1
    }
  }

  @computed
  public get recordsPerPage(): number {
    if (this.fixedRecordsPerPage > 0) return this.fixedRecordsPerPage
    try {
      if (this.parentContainerId === 'AudienceTable') return 10
      if (this.parentContainerId === 'SystemAdminsListWidget') return 8
      if (this.parentContainerId === 'OrganizationsSAListWidget') return 8
      let records = 1
      if (this.parentContainerId === 'AudienceMembersWidget') {
        records = Math.round(this.tableHeight / 34)
      } else if (this.parentContainerId === 'MediaItemsListWidget') {
        records = Math.round(this.tableHeight / 43)
      } else {
        records = Math.round(this.tableHeight / 32)
      }
      if (records <= 0) records = 1
      return records
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  @action
  public setData(data: Array<any>) {
    this.data = data
    this.refreshContainerHeight()
  }

  @action
  public setFilter(val: string) {
    this.pageNumber = 1
    this.keywordFilter = val
  }

  @computed
  private get filteredRows(): Array<any> {
    try {
      let keyword = this.keywordFilter.toLowerCase()
      if (keyword) {
        return this.data.filter((row) => {
          let isMatch = false
          this.filterColumns.forEach((col: string) => {
            if (row[col]) {
              if (row[col].toLowerCase().includes(keyword)) isMatch = true
            }
          })
          return isMatch
        })
      }
      return this.data
    } catch (e) {
      console.error(e)
      return []
    }
  }

  @computed
  public get sortedFilteredRows(): Array<any> {
    let col = this.sort
    const desc = col[0] === '-'
    if (desc) col = col.slice(1)
    return [...this.filteredRows].sort((a, b) => {
      const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890-'
      let valueA = a && a[col] ? String(a[col]).trim().toLowerCase() : ''
      while (!alphabet.includes(valueA.charAt(0))) {
        valueA = valueA.slice(1)
      }
      let valueB = b && b[col] ? String(b[col]).trim().toLowerCase() : ''
      while (!alphabet.includes(valueB.charAt(0))) {
        valueB = valueB.slice(1)
      }
      if (!valueA || valueA === '-' || valueA === '--') return 1
      if (!valueB || valueB === '-' || valueB === '--') return -1
      else {
        const result = valueA > valueB ? -1 : 1
        return desc ? result : -result
      }
    })
  }

  @computed
  public get recordsCount(): number {
    return this.filteredRows.length
  }

  @computed
  public get displayRows(): Array<any> {
    const offset = (this.pageNumber - 1) * this.recordsPerPage
    let pagedRecords = []
    if (this.sortedFilteredRows.length > 0) {
      pagedRecords = this.sortedFilteredRows.slice(offset, offset + this.recordsPerPage)
    }
    return pagedRecords
  }

  @action
  public setSort(key) {
    let sort = key
    if (this.sort && this.sort === key) sort = '-' + key
    this.pageNumber = 1
    this.sort = sort
  }

  @action
  public goNextPage() {
    this.pageNumber = this.pageNumber + 1
  }

  @action
  public goPrevPage() {
    this.pageNumber = this.pageNumber - 1
  }

  @computed
  public get pagesCount(): number {
    let pages = Math.ceil(this.recordsCount / this.recordsPerPage)
    if (pages === 0) pages = 1
    return pages
  }

  @computed
  public get isFirstPage(): boolean {
    return this.pageNumber === 1
  }

  @computed
  public get isLastPage(): boolean {
    if (this.pageNumber === 1 && this.pagesCount === 1) return true
    return this.pageNumber === this.pagesCount
  }

  @computed
  public get recordsDisplayedInfo(): string {
    if (this.recordsCount === 0) return ''
    let desc = ''
    desc = desc + ((this.pageNumber - 1) * this.recordsPerPage + 1).toString()
    if (this.isLastPage || this.recordsCount < this.recordsPerPage) {
      desc = desc + '-' + this.recordsCount.toString()
    } else {
      desc = desc + '-' + (this.pageNumber * this.recordsPerPage).toString()
    }
    desc = desc + ' of ' + this.recordsCount.toString()
    return desc
  }
}
