import React from 'react'
import { Button } from '@material-ui/core'
import errorCatSvg from '../../../assets/img/error-cat.svg'
import './AppErrorMessage.scss'
import env from '../../../env'

function refreshPage() {
  window.location.reload()
}

const AppErrorMessage = ({ error }) => {
  const renderProductionErrorMessage = () => {
    return (
      <>
        <h2 className='text body'>
          An unexpected error has occurred.
          <br></br>
          Please contact your administrator if this problem persists.
        </h2>
        {renderErrorMessage()}
        <Button variant='contained' onClick={refreshPage}>
          Reload Page
        </Button>
      </>
    )
  }

  const renderErrorMessage = () => {
    if (env.var.REACT_APP_ENVIRONMENT === 'PRODUCTION') return
    if (!error) return
    return (
      <>
        <pre style={{ color: 'red' }}>{error.message}</pre>
        <pre style={{ color: 'red' }}>{error.stack}</pre>
      </>
    )
  }

  return (
    <div className='container'>
      <img src={errorCatSvg} />
      <h1 className='text header'>Uh oh!</h1>
      {renderProductionErrorMessage()}
    </div>
  )
}

export default AppErrorMessage
