import Parse from 'parse'
import { RootStore } from '../../stores/RootStore'
import { IPulseCategoryDTO } from '../dtos/IPulseCategoryDTO'
import { IPulseCategoriesFindRequest } from '../interfaces/IPulseCategoriesFindRequest'
import { IPulseCategoriesFindResult } from '../interfaces/IPulseCategoriesFindResult'

export class PulseCategoriesService {
  rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public async getPulseCategories(request: IPulseCategoriesFindRequest): Promise<IPulseCategoriesFindResult> {
    return await Parse.Cloud.run('getPulseCategories', { request })
  }

  public async savePulseCategory(pulseCategory: IPulseCategoryDTO) {
    return await Parse.Cloud.run('savePulseCategory', { pulseCategory })
  }

  public async deletePulseCategory(objectId: string) {
    return await Parse.Cloud.run('deletePulseCategory', { objectId })
  }

}
