import React from 'react'
import { observer, inject } from 'mobx-react'
import ContentLayoutGridBody from './CMSItemsLayoutGridBody'
import { CMSItemsPickerVM } from '../view-models/CMSItemsPickerVM'
import './CMSItemsLayoutGrid.scss'

interface Props {
  vm: CMSItemsPickerVM
}

const CMSItemsLayoutGrid: React.FC<Props> = ({ vm }) => {
  if (!vm) return

  return (
    <div id='ContentLayoutGrid'>
      <ContentLayoutGridBody vm={vm} />
    </div>
  )
}

export default inject()(observer(CMSItemsLayoutGrid))
