import React from 'react'
import { CardHeader } from '@material-ui/core'
import { observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { inject } from 'mobx-react'
import InfoTooltip from '../../../shared/InfoTooltip'
import { AlwaysAvailableSurveysWidgetVM } from '../../view-models/always-available-surveys-widget/AlwaysAvailableSurveysWidgetVM'
import { LabelsStore } from '../../../labels/store/LabelsStore'

interface Props {
  localizationStore?: LocalizationStore
  vm: AlwaysAvailableSurveysWidgetVM
  labelsStore?: LabelsStore
}

const WidgetHeader: React.FC<Props> = ({ vm, localizationStore, labelsStore }) => {
  const s = localizationStore.lzStrings
  if (!vm) return null

  return (
    <CardHeader
      id='Header'
      title={'Feedback'}
      subheader={
        <InfoTooltip
          message={`Feedback ${labelsStore.getLabelByLanguageAndFor('pulses')} are ${labelsStore
            .getLabelByLanguageAndFor('pulses')
            .toLowerCase()} that are always active and can be taken multiple times.`}
        />
      }
    />
  )
}

export default inject('localizationStore', 'labelsStore')(observer(WidgetHeader))
