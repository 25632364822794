import Parse from 'parse'
import { RootStore } from '../../stores/RootStore'
import { observable, action, computed } from 'mobx'
import { ContentItemVM } from './ContentItemVM'
import { Worksheet } from '../../worksheets/aggregate/Worksheet'
import { WorksheetsStore } from '../../worksheets/store/WorksheetsStore'
import { UserWorksheetsStore } from '../store/UserWorksheetsStore'
import { UserWorksheet } from '../aggregate/UserWorksheet'
import { IUserWorksheetDTO } from '../dto/IUserWorksheetDTO'
import { IUserWorksheetUpdateSortOrderRequest } from '../interfaces/IUserWorksheetUpdateSortOrderRequest'

export class ContentVM {
  private rootStore: RootStore
  private chartTypeId: string = 'tableau'
  private enableBookmark: boolean = false
  private enableSorting: boolean = true
  private enableShowAll: boolean = false
  private defaultMediaHeight: number = 160
  private defaultCardWidth: number = 340
  private minItems: number = Math.floor(window.innerWidth / this.defaultCardWidth)

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore

    window.addEventListener('resize', () => {
      const cols = Math.floor(window.innerWidth / this.defaultCardWidth)
      this.minItems = cols
      if (!this.isShowingAll) this.itemsVisible = this.minItems
    })
  }

  @observable public contentItemVMs: Array<ContentItemVM> = []
  @observable public itemsVisible: number = this.minItems
  @observable public isOpen: boolean = false
  @observable public isShowingAll: boolean = false
  @observable public selectedItemVM: ContentItemVM = undefined
  @observable public typedFilterText: string = ''
  @observable public bookmarkFilterOn: boolean = false

  @action
  private async refreshUserWorksheets() {
    this.userWorksheetsStore.loadListRecords()
  }

  @action
  public setQuickFilter(val: string) {
    this.typedFilterText = val
  }

  @action
  public setBookmarkFilter(val: boolean) {
    this.bookmarkFilterOn = val
  }

  @action
  public sort() {
    this.contentItemVMs.sort((a, b) => a.sortNumber - b.sortNumber)
  }

  @action
  public async setSortOrder(userWorksheets: IUserWorksheetDTO[]) {
    const req: IUserWorksheetUpdateSortOrderRequest = {
      userId: this.rootStore.appStore.currentUserId,
      organizationId: this.rootStore.userStore.currentOrganization.objectId,
      userWorksheets: userWorksheets,
    }
    const result = await this.userWorksheetsStore.svc.updateUserWorksheetSortOrder(req)
    if (result.success) {
      this.sort()
      this.refreshUserWorksheets()
    }
  }

  @computed
  public get shouldRender(): boolean {
    if (!this.rootStore.worksheetsStore.loaded) return false
    return true
  }

  @computed
  public get currentUserWorksheets(): UserWorksheet[] {
    const userWorksheets = this.userWorksheetsStore.userWorksheets
    return userWorksheets.filter((e) => e.userId === this.rootStore.appStore.currentUserId)
  }

  @computed
  public get currentWorksheets(): Worksheet[] {
    const worksheets = this.worksheetsStore.currentOrgWorksheets
    if (this.canAccessScopedAnalysis) {
      return worksheets.filter((e) => e.owner === this.rootStore.appStore.currentUserId)
    }
    return worksheets
  }

  @computed
  private get worksheetsStore(): WorksheetsStore {
    return this.rootStore.worksheetsStore
  }

  @computed
  private get userWorksheetsStore(): UserWorksheetsStore {
    return this.rootStore.userWorksheetsStore
  }

  @computed
  public get canAccessScopedAnalysis(): boolean {
    return this.rootStore.appStore.canAccessScopedAnalysis
  }

  @action
  public setAllContentItems(val: Array<ContentItemVM>) {
    this.contentItemVMs = val.map((e) => e)
  }

  @action
  public addContentItem(val: ContentItemVM) {
    this.contentItemVMs.unshift(val)
  }

  @action
  public getContentItemById(id: string): ContentItemVM {
    return this.contentItemVMs.length > 0 ? this.contentItemVMs.find((x) => x.id === id) : undefined
  }

  @action
  public updateContentItem(val: ContentItemVM) {
    const item = this.getContentItemById(val.id)

    if (item) {
      item.setWorksheet(val.worksheet)
    } else {
      this.addContentItem(val)
    }
  }

  @action
  public deleteContentItem(item: ContentItemVM) {
    this.contentItemVMs = this.contentItemVMs.filter((e) => e.id !== item.id)
  }

  @action
  public deleteContentItemById(id: string) {
    this.contentItemVMs = this.contentItemVMs.filter((e) => e.id !== id)
  }

  @action
  public clearAllContentItems() {
    this.contentItemVMs = []
  }

  @computed
  public get userEmail(): string {
    if (!this.rootStore.userStore.user) return null
    if (!this.rootStore.userStore.user.email) return null
    return this.rootStore.userStore.user.email
  }

  @computed
  public get canManageVis(): boolean {
    if (this.rootStore.appStore.canAccessScopedAnalysis) return true
    if (this.rootStore.appStore.canAccessAllAnalysis) return true
    return false
  }

  @action
  public setOpen(isOpen: boolean) {
    this.isOpen = isOpen
  }

  @action
  public setShowAll(isShowingAll: boolean) {
    this.isShowingAll = isShowingAll
  }

  @action
  public setItemsVisible(itemsVisible: number) {
    this.itemsVisible = itemsVisible
  }

  @action
  public setSelectedItemVM(selectedItemVM: ContentItemVM) {
    this.selectedItemVM = selectedItemVM
  }

  @computed
  public get chartTypeIdFilter(): string {
    return this.chartTypeId
  }

  @computed
  public get isBookmarkEnabled(): boolean {
    return this.enableBookmark
  }

  @computed
  public get isSortingEnabled(): boolean {
    return this.enableSorting
  }

  @computed
  public get isShowAllEnabled(): boolean {
    return this.enableShowAll
  }

  @computed
  public get minItemsVisible(): number {
    return this.minItems
  }

  @computed
  public get mediaHeight(): number {
    return this.defaultMediaHeight
  }

  @computed
  public get canManage() {
    if (this.rootStore.appStore.isOrgAdmin) return true
    if (!this.rootStore.userStore.currentOrganization) return false
    return Boolean(this.rootStore.appStore.canAccessWorksheets)
  }

  public hasPermission(worksheet: Worksheet) {
    if (this.rootStore.appStore.isOrgAdmin) return true
    if (!this.rootStore.userStore.currentOrganization) return false
    if (!worksheet) return false
    if (!worksheet.objectId) return false
    if (worksheet.owner === this.rootStore.appStore.currentUserId) return true
    if (!worksheet.visibleTo) return false
    let isVisible = false
    const foundUser = worksheet.visibleTo.find(
      (e) => e.id === this.rootStore.appStore.currentUserId
    )
    if (foundUser) isVisible = true
    const roles = worksheet.visibleTo.filter((e) => e.type === 'role')
    if (roles.length) {
      roles.forEach((role) => {
        const foundRole = this.rootStore.userStore.currentOrganization.roles.find(
          (e) => e.id === role.id
        )
        if (foundRole) isVisible = true
      })
    }
    const groups = worksheet.visibleTo.filter((e) => e.type === 'group')
    if (groups.length) {
      groups.forEach((group) => {
        const foundRole = this.rootStore.userStore.currentOrganization.groups.find(
          (e) => e.id === group.id
        )
        if (foundRole) isVisible = true
      })
    }
    return isVisible
  }
}
