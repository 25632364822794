import React from 'react'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { EventsStore } from '../../../store/EventsStore'
import { observer, inject } from 'mobx-react'
import { Grid, IconButton, DialogTitle } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import './CalendarExportModal.scss'

interface Props {
  eventsStore?: EventsStore
  localizationStore?: LocalizationStore
}

const ModalHeader: React.FC<Props> = ({ eventsStore, localizationStore }) => {
  const { exportVM: vm } = eventsStore.calendarVM

  return (
    <Grid
      id='modalHeader'
      className='dialog-title'
      container
      spacing={3}
      justifyContent='space-between'
      alignItems='center'
    >
      <Grid item>
        <DialogTitle disableTypography>Export Calendar</DialogTitle>
      </Grid>
      <Grid item>
        <IconButton onClick={() => vm.toggleExportModal()}>
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default inject('eventsStore', 'localizationStore')(observer(ModalHeader))
