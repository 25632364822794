import Parse from 'parse'
import { computed, observable } from 'mobx'
import { DataStore } from '../../../../shared/data/DataStore'
import { nameOf } from '../../../../shared/nameOf'
import { RootStore } from '../../../../stores/RootStore'
import { IPulseSurveyScheduleDTO } from '../../../dto/IPulseSurveyScheduleDTO'
import { PulseSurveySchedule } from '../../../aggregate/PulseSurveySchedule'
import moment from 'moment'

export class UpcomingPulseDataStore extends DataStore<
  PulseSurveySchedule,
  IPulseSurveyScheduleDTO
> {
  constructor(rootStore: RootStore) {
    super(rootStore, PulseSurveySchedule, 'pulseSurveySchedules', [
      nameOf<IPulseSurveyScheduleDTO, string>((e) => e.pulseCampaignSurveyName),
      nameOf<IPulseSurveyScheduleDTO, string>((e) => e.pulseCampaignName),
      nameOf<IPulseSurveyScheduleDTO, string>((e) => e.effectiveDateTime),
      nameOf<IPulseSurveyScheduleDTO, string>((e) => e.pulseCampaignId),
      nameOf<IPulseSurveyScheduleDTO, string>((e) => e.pulseCampaignSurveyId),
    ])
    this.paged = true
    this.recordsPerPage = 100
    this.sortColumnName = 'effectiveDateTime'
    this.sortDirection = 'desc'
  }

  @observable public isLoaded: boolean = false

  public onListRecordsLoaded() {
    this.isLoaded = true
  }

  @computed
  private get now(): string {
    return new Date().toISOString()
  }

  @computed
  private get upcomingDateRange(): string {
    return moment(new Date()).add(14, 'days').toISOString()
  }

  protected getBaseQuery() {
    let query = new Parse.Query(this.className)
    query.equalTo('organizationId', this.rootStore.appStore.currentOrgId)
    query.equalTo('status', 'not processed')
    query.greaterThanOrEqualTo('effectiveDateTime', this.now)
    query.lessThanOrEqualTo('effectiveDateTime', this.upcomingDateRange)
    return query
  }

  public get upcomingSurveys() {
    return this.records
  }
}
