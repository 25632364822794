import React from 'react'
import { inject, observer } from 'mobx-react'
import { GrandParentCategoryRowVM } from '../../view-models/tree/GrandParentCategoryRowVM'
import { PulseCategoriesManageVM } from '../../view-models/PulseCategoriesManageVM'
import GrandParentCategoryRow from './GrandParentCategoryRow'
import './PulseCampaignCategoriesTree.scss'
import { PulseCampaignEditVM } from '../../view-models/PulseCampaignEditVM'

interface Props {
  vm: PulseCategoriesManageVM
  editVM?: PulseCampaignEditVM
}

const PulseCampaignCategoriesTree: React.FC<Props> = ({ vm, editVM }) => {
  if (!vm) return null

  const renderGrandCategoryRows = () => {
    const rows = []
    vm.grandParentCategoryRows().forEach((row: GrandParentCategoryRowVM, i) => {
      rows.push(<GrandParentCategoryRow key={i} vm={row} />)
    })
    return rows
  }

  return <>{renderGrandCategoryRows()}</>
}

export default inject()(observer(PulseCampaignCategoriesTree))
