import React from 'react'
import { observer } from 'mobx-react'
import Avatar from '../Avatar'

interface Props {
  name: string
  iconURL: string
  showName?: boolean
  userId?: string
  sortName?: string
}

const AGGridUserCell: React.FC<Props> = ({ name, iconURL, showName = true, userId, sortName }) => {
  const renderUserName = () => {
    if (!showName) return null
    return <div className='name'>{sortName ? sortName : name}</div>
  }

  const renderAvatar = () => {
    return <Avatar user={{ name: name, iconURL: iconURL, objectId: userId }} size={20} />
  }

  return (
    <div className='user-cell'>
      {renderAvatar()}
      {renderUserName()}
    </div>
  )
}

export default (observer(AGGridUserCell))
