import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { SurveysStore } from '../../../store/SurveysStore'
import { QuestionVM } from '../../../view-models/QuestionVM'
import Icon from './Icon'
import Typography from '@material-ui/core/Typography'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import PermMediaIcon from '@material-ui/icons/PermMedia'
import FileCopyIcon from '@material-ui/icons/FileCopyRounded'
import DeleteIcon from '@material-ui/icons/Delete'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import Tooltip from '@material-ui/core/Tooltip'
import './QuestionsList.scss'

interface Props {
  index: number
  localizationStore?: LocalizationStore
  surveysStore?: SurveysStore
  question: QuestionVM
}

const QuestionCard: React.FC<Props> = ({ index, localizationStore, surveysStore, question }) => {
  const { editVM } = surveysStore.viewModels
  if (!editVM) return null

  const strings = localizationStore.lzStrings.questionCard

  const renderEmojiOptions = (question: QuestionVM, questionIndex) => {
    if (question.type !== 'emoji') return
    const emojis = question.options.map((opt, idx) => {
      return (
        <div
          className='emoji-options'
          key={'qcopt' + idx}
          id={'question-' + questionIndex + '-option-' + idx}
        >
          <ListItemIcon>
            <img
              src={'../../../assets/emoticons/png/' + opt.emoji + '.png'}
              style={{ width: 10, height: 10 }}
              alt=''
            />
          </ListItemIcon>
          <Typography component='span'>{opt.text}</Typography>
        </div>
      )
    })
    return emojis
  }

  const renderNormalOptions = (question: QuestionVM, questionIndex) => {
    if (question.type === 'emoji' || question.type === 'infoText') return
    // if (question.type === 'text') return

    const options = question.options.map((opt, idx) => {
      return (
        <div
          className='options'
          key={'qcopt' + idx}
          id={'question-' + questionIndex + '-option-' + idx}
        >
          <span className={opt.icon}></span>
          <Typography>
            {opt.text} {opt.subText}
          </Typography>
        </div>
      )
    })
    return options
  }

  const renderQuestionIndex = () => {
    if (question.type === 'infoText') return `INFO TEXT ${question.infoTextIndex + 1}`
    return (
      <>
        Q{index + 1}
        {question.required ? <span className='question-required'>*</span> : ''}
      </>
    )
  }

  return (
    <div
      key={'qcd1' + index}
      id={'question-' + index}
      className={index === editVM.currentQuestionIndex ? 'question-card active' : 'question-card'}
      onMouseDown={() => editVM.setCurrentQuestion(index)}
    >
      <div className={index === editVM.currentQuestionIndex ? 'header active-header' : 'header'}>
        <div className='question-card-header-left'>
          <ListItemIcon className={question.icon.className}>
            <Icon icon={question.icon.iconType} />
          </ListItemIcon>
          <Typography className='card-index'>{renderQuestionIndex()}</Typography>
        </div>
        <div className='question-card-header-icons'>
          {question.media.objectId ? <PermMediaIcon className='media-icon' /> : null}
          <ListItemIcon
            className={editVM.isReadOnly ? '' : 'copy-icon'}
            onClick={() => editVM.copyQuestion(index)}
          >
            <Tooltip title={strings.copy}>
              <FileCopyIcon />
            </Tooltip>
          </ListItemIcon>
          <ListItemIcon
            className={editVM.isReadOnly ? '' : 'delete-icon'}
            onClick={() => editVM.deleteQuestionByCard(index)}
          >
            <Tooltip title={strings.delete}>
              <DeleteIcon />
            </Tooltip>
          </ListItemIcon>
          <ListItemIcon className='drag-icon'>
            <Tooltip title={strings.drag}>
              <DragIndicatorIcon />
            </Tooltip>
          </ListItemIcon>
        </div>
      </div>
      <div className='title-container'>
        <Typography className='title'>{question.title}</Typography>
      </div>
      {renderEmojiOptions(question, index)}
      {renderNormalOptions(question, index)}
    </div>
  )
}

export default inject('surveysStore', 'localizationStore')(observer(QuestionCard))
