import { action, observable, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { ParticipantsSelectVM } from '../../participants-select/view-models/ParticipantsSelectVM'
import { Announcement } from '../aggregate/Announcement'
import moment from 'moment'
import { IParticipant } from 'src/app/participants-select/interfaces/IParticipant'
import { TimeZoneType } from '../../surveys/types/TimeZoneType'
import { DateUtils } from '../../shared/data/DateUtils'
import _ from 'lodash'
import { AudienceMember } from '../../audience-members/aggregate/AudienceMember'
import { AnnouncementStatusType } from '../types/AnnouncementStatusType'
import { AnnouncementSendNotificationsVM } from './AnnouncementSendNotificationsVM'
import { OldAttachmentVM } from '../../attachments/view-models/OldAttachmentVM'
import { AttachmentVM } from '../../attachments/view-models/AttachmentVM'
import { Attachment } from '../../attachments/aggregate/Attachment'
import { isNumeric } from '../../shared/isNumeric'
import { AnnouncementsService } from '../service/AnnouncementsService'
import { PostTemplatesTableVM } from '../../cms-post-templates/view-models/PostTemplatesTableVM'

export class AnnouncementEditVM {
  private rootStore: RootStore
  public announcement: Announcement
  private svc: AnnouncementsService

  constructor(rootStore: RootStore, announcement: Announcement) {
    this.rootStore = rootStore
    this.announcement = announcement

    this.participantsSelectVM = new ParticipantsSelectVM(
      this.rootStore,
      false,
      false,
      true,
      true,
      false,
      true,
      false
    )
    this.participantsSelectVM.setExcludeCurrentUser()
    this.participantsSelectVM.setParticipants(announcement.participants)
    this.participantsSelectVM.setHeight(350)
    this.svc = new AnnouncementsService()
    if (this.announcement.fk_ownerUser) {
      this.loadSelectedUser()
    }
    this.sendNotificationsVM = new AnnouncementSendNotificationsVM(this.rootStore, () =>
      this.saveAnnouncement()
    )
    this.startDateDate = DateUtils.getBrowserDate(
      this.announcement.schedule.deliveryDateTime,
      this.announcement.schedule.deliveryTimeZone
    )
    this.startDateTime = DateUtils.getBrowserDate(
      this.announcement.schedule.deliveryDateTime,
      this.announcement.schedule.deliveryTimeZone
    )
    this.getAllOwners()
  }

  @observable public participantsSelectVM: ParticipantsSelectVM = null
  @observable public showParticipantsSelect: boolean = false
  @observable public showDeleteDialog: boolean = false
  @observable public menuAnchorEl: any = null
  @observable public saveRequested: boolean = false
  @observable public showUploadModal: boolean = false
  @observable public sendNotificationsVM: AnnouncementSendNotificationsVM = null
  @observable public isSaving: boolean = false
  @observable public showOwnerSelect: boolean = false
  @observable public showSchedule: boolean = false
  @observable public showTemplateTable: boolean = false
  @observable public startDateDate: Date
  @observable public startDateDateValid: boolean = true
  @observable public startDateTime: Date
  @observable public timeZone: string = moment.tz.guess()
  public vmCreatedAt: Date = new Date()
  @observable public announcementOwnerOptions = []
  @observable public selectedOwner = undefined
  @observable public tableVM: PostTemplatesTableVM = null

  @action private loadSelectedUser() {
    const owner = {
      name: this.announcement.fk_ownerUser.name,
      userId: this.announcement.ownerUserId,
    }

    this.setSelectedOwner(owner)
  }

  @computed
  public get objectId(): string {
    return this.announcement.objectId
  }

  @computed
  public get title(): string {
    return this.announcement.title
  }

  @computed
  public get likes(): number {
    return this.announcement.likes
  }

  @computed
  public get views(): number {
    return this.announcement.views
  }

  @action
  public setTitle(val: string) {
    this.announcement.setTitle(val)
  }

  @computed
  public get body(): string {
    return this.announcement.body
  }

  @action
  public setBody(val: string) {
    this.announcement.setBody(val)
  }

  @computed
  public get isNew(): boolean {
    return Boolean(!this.objectId)
  }

  @computed
  public get status(): string {
    return this.announcement.status
  }

  @computed
  public get publishButtonText(): string {
    if (!this.isNew && this.status === 'published') return 'UPDATE'
    return 'PUBLISH'
  }

  @computed
  public get date() {
    if (this.isNew) return moment().format('L')
    return moment(this.announcement.createdAt).format('L')
  }

  @computed
  public get createdAt(): string {
    const s = this.rootStore.localizationStore.lzStrings.announcements_widget
    return moment(this.announcement.createdAt).format(s.created_format)
  }

  @computed
  public get participants(): IParticipant[] {
    return this.announcement.participants
  }

  @action
  public addParticipants() {
    this.announcement.addParticipants(this.participantsSelectVM.participants)
    this.toggleParticipantsSelect()
  }

  @action
  public toggleParticipantsSelect() {
    this.showParticipantsSelect = !this.showParticipantsSelect
    if (!this.showParticipantsSelect) {
      this.participantsSelectVM.setHidden()
    }
    if (this.showParticipantsSelect) {
      this.participantsSelectVM.setVisible()
    }
  }

  @action
  public toggleOwnerSelect() {
    this.showOwnerSelect = !this.showOwnerSelect
  }

  @action
  public toggleShowTemplateTable() {
    this.showTemplateTable = !this.showTemplateTable
  }

  @action
  public toggleEnableSchedule() {
    this.announcement.toggleScheduleEnabled()
  }

  @action
  public toggleShowAnnouncementOwner() {
    this.announcement.toggleShowAnnouncementOwner()
  }

  @computed
  public get scheduleEnabled() {
    return this.announcement.schedule.enabled
  }

  @computed
  public get showAnnouncementOwner() {
    return this.announcement.showAnnouncementOwner
  }

  @action
  public cancel() {
    this.rootStore.announcementsStore.lazyLoadEditVM('empty')
  }

  @action
  public setAnchorEl(val) {
    this.menuAnchorEl = val
  }

  @action
  public deleteAnnouncement() {
    this.isSaving = true
    this.toggleDeleteDialog()
    this.svc.deleteAnnouncement(this.objectId, this.rootStore.appStore.currentOrgId)
    setTimeout(() => {
      this.isSaving = false
      this.rootStore.announcementsStore.lazyLoadEditVM('empty')
    }, 1000)
  }

  @action
  public toggleDeleteDialog() {
    this.showDeleteDialog = !this.showDeleteDialog
  }

  @computed
  public get titleValid(): boolean {
    if (!this.title && this.saveRequested) return false
    return true
  }

  @computed
  public get bodyValid(): boolean {
    if (!this.body && this.saveRequested) return false
    return true
  }

  @computed
  public get participantsValid(): boolean {
    if (this.participants.length === 0 && this.saveRequested) return false
    return true
  }

  @computed
  public get isValid(): boolean {
    if (!this.bodyValid || !this.titleValid || !this.participantsValid) return false
    return true
  }

  @computed
  public get attachments(): AttachmentVM[] {
    return this.announcement.attachments.map((e, idx) => {
      if (isNumeric(e.objectId) || e.cmsItemId) {
        return this.rootStore.cmsItemAttachmentStore.loadAttachment(e)
      }
      return new OldAttachmentVM(this.rootStore, e, idx)
    })
  }

  @action
  public removeAttachmentByIndex(index: number) {
    this.announcement.removeAttachmentByIndex(index)
  }

  @action
  public removeAttachmentByObjectId(objectId: string) {
    this.announcement.removeAttachmentByObjectId(objectId)
  }

  @action
  public toggleShowUploadModal() {
    this.showUploadModal = !this.showUploadModal
  }

  @action
  public addAttachments(attachments: Attachment[]) {
    attachments.forEach((a: Attachment) => this.announcement.addAttachment(a))
  }

  @action
  public clearAttachments() {
    this.announcement.clearAttachments()
  }

  @action
  public setAnnouncementStatus(status: AnnouncementStatusType) {
    this.announcement.setStatus(status)
  }

  @computed
  public get showDraftButton(): boolean {
    if (this.announcement.status === 'published') return false
    if (this.announcement.status === 'initialized') return false
    if (this.announcement.status === 'queued') return false
    return true
  }

  @action
  public viewAnalytics() {
    this.cancel()
    this.rootStore.appStore.router.push(`/announcement/analytics/${this.announcement.objectId}`)
  }

  @action
  public handleSaveAnnouncement() {
    if (this.status === 'published') {
      return setTimeout(() => this.sendNotificationsVM.toggleShowNotificationsModal(), 500)
    }
    this.setAnnouncementStatus('initialized')
    this.saveAnnouncement()
  }

  @action
  public handleSaveDraft() {
    this.setAnnouncementStatus('draft')
    this.saveAnnouncement()
  }

  @action
  public async saveAnnouncement() {
    this.saveRequested = true
    if (!this.isValid) return
    const obj = this.announcement.serialize()
    obj.participants = this.participantsSelectVM.participantsToDTO()
    let sendNotifications = this.sendNotificationsVM.sendNotifications
    if (this.isNew) sendNotifications = true
    this.isSaving = true
    const result = await this.svc.saveAnnouncement(
      obj,
      this.announcement.organizationId,
      sendNotifications
    )
    console.log({ result })
    this.isSaving = false
    this.rootStore.announcementsStore.lazyLoadEditVM('empty')
  }

  @computed
  public get timeZones(): Array<TimeZoneType> {
    const timeZones = moment.tz.names()
    const offsetTmz = []

    for (const i in timeZones) {
      const tz = moment.tz(timeZones[i]).format('Z').replace(':00', '').replace(':30', '.5')
      let x = tz === '0' ? 0 : parseInt(tz).toFixed(2)

      const timeZone: TimeZoneType = {
        label: `(GMT${moment.tz(timeZones[i]).format('Z')}) ${timeZones[i]}`,
        value: `${timeZones[i]}`,
        time: `${x}`,
      }
      offsetTmz.push(timeZone)
    }

    return _.sortBy(offsetTmz, [
      function (el) {
        return -el.time
      },
    ])
  }

  @computed
  public get deliveryTimeZone(): TimeZoneType {
    return this.timeZones.find((e) => e.value === this.timeZone)
  }

  @action
  public setStartDate(val: any) {
    this.startDateDate = val
    try {
      const timeZonedDate = DateUtils.getISOStringFromBrowserInput(
        this.startDateTime.toISOString(),
        val.toISOString(),
        this.announcement.schedule.deliveryTimeZone
      )
      this.announcement.setDeliveryDateTime(new Date(timeZonedDate))
      this.startDateDateValid = true
    } catch (e) {
      this.startDateDateValid = false
    }
  }

  @action
  public setStartDateTime(hour: number, minute: number) {
    const newTime = moment(this.startDateTime).hour(hour).minute(minute).second(0)
    this.startDateTime = newTime.toDate()
    if (!this.startDateDateValid) return
    const timeZonedDate = DateUtils.getISOStringFromBrowserInput(
      newTime.toISOString(),
      this.startDateDate.toISOString(),
      this.announcement.schedule.deliveryTimeZone
    )
    this.announcement.setDeliveryDateTime(new Date(timeZonedDate))
  }

  @action
  public setDeliveryTimeZone(timeZone: any) {
    if (!this.announcement || !timeZone) return
    this.announcement.setDeliveryTimeZone(timeZone.value)
    const timeZonedDate = DateUtils.getISOStringFromBrowserInput(
      this.startDateTime.toISOString(),
      this.startDateDate.toISOString(),
      this.announcement.schedule.deliveryTimeZone
    )
    this.announcement.setDeliveryDateTime(new Date(timeZonedDate))
  }

  @action public setAnnouncementOwner() {
    this.announcement.setOwnerId(this.selectedOwner.userId)
    this.showOwnerSelect = false
  }

  @action public setSelectedOwner(owner) {
    this.selectedOwner = owner
  }

  @computed public get selectedOwnerError() {
    if (!this.selectedOwner) return true
    return false
  }

  @computed public get scheduleIsReadOnly() {
    if (this.announcement.objectId && this.announcement.status === 'published') return true
    return false
  }

  @action private async getAllOwners() {
    const result = await this.svc.findAnnouncementOwners(this.rootStore.appStore.currentOrgId)
    if (result.success) {
      this.announcementOwnerOptions = result.owners
    }
  }

  @computed public get announcementOwner() {
    return this.announcement.fk_ownerUser
  }

  @computed public get announcementOwnerId() {
    return this.announcement.ownerUserId
  }

  @computed public get announcementCreatedBy() {
    return this.announcement.fk_createdByUser
  }

  @computed
  public get currentUser(): AudienceMember {
    return this.rootStore.audienceMembersStore.getUser(this.rootStore.appStore.currentUserId)
  }

  @computed public get announcementOwnerName() {
    if (!this.selectedOwner && !this.announcement.fk_ownerUser && this.currentUser)
      return this.currentUser.name
    if (this.selectedOwner && !this.announcement.fk_ownerUser) return this.selectedOwner.name
    if (
      this.selectedOwner &&
      this.announcement.objectId &&
      this.selectedOwner.name !== this.announcement.fk_ownerUser.name
    )
      return this.selectedOwner.name
    if (this.selectedOwner && this.selectedOwner.name === this.announcement.fk_ownerUser.name)
      return this.announcement.fk_ownerUser.name
    if (this.announcementCreatedBy) return this.announcement.fk_createdByUser.name
    return this.rootStore.userStore.user.name
  }

  @action
  public loadTable() {
    this.tableVM = new PostTemplatesTableVM(this.rootStore, this)
  }
}
