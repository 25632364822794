import { RootStore } from '../../../../stores/RootStore'
import { computed, action } from 'mobx'
import { TrainingItem } from '../../../../training-items/aggregate/TrainingItem'
import { ContentItem } from '../../../../training-items/aggregate/ContentItem'
import { CatalogItemsTabVM } from './CatalogItemsTabVM'

export class CatalogItemRowVM {
  private rootStore: RootStore
  private trainingItem: TrainingItem

  constructor(rootStore: RootStore, tabVM: CatalogItemsTabVM, trainingItem: TrainingItem) {
    this.rootStore = rootStore
    this.trainingItem = trainingItem
    this.tabVM = tabVM
  }

  private tabVM: CatalogItemsTabVM

  @computed
  public get objectId(): string {
    return this.trainingItem.objectId
  }

  @computed
  public get name(): string {
    return this.trainingItem.name
  }

  @computed
  public get userOwned(): boolean {
    return this.trainingItem.createdByUserId === this.rootStore.appStore.currentUserId
  }

  @computed
  public get contentItems(): ContentItem[] {
    return this.trainingItem.contentItems
  }

  @computed
  public get categories(): string[] {
    const names = []
    this.trainingItem.categories.forEach((cat) => {
      const category = this.rootStore.categoriesStore.getCategory(cat)
      if (category) names.push(category.name)
    })
    return names
  }

  @computed
  public get numberOfContentItems(): number {
    return this.contentItems.length
  }

  @computed
  public get proofOfCompletion(): string {
    if (this.trainingItem.requireCertificate || this.trainingItem.requireQuiz) return 'Yes'
    if (this.trainingItem.requireProofOfCompletion) return 'Yes'
    return 'No'
  }

  @action
  public editTrainingItem() {
    this.rootStore.appStore.router.push(`/trainingItems/from-catalog/edit/${this.objectId}`)
  }

  @action
  public createFromCatalog() {
    this.rootStore.appStore.router.push(`/trainingItems/from-catalog/clone/${this.objectId}`)
  }

  @computed
  public get canEditAllTrainings(): boolean {
    if (
      this.rootStore.organizationsStore.currentOrganization &&
      !this.rootStore.organizationsStore.currentOrganization.isTrainingsEnabled
    )
      return false
    return this.rootStore.appStore.canEditAllTrainings
  }
}
