import { action, observable, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { ParseService } from '../../services/ParseService'
import { CategoryForm } from './CategoryForm';

export default class CategoryNewForm implements CategoryForm {
  rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @observable public name: string = ''
  @observable public description: string = ''
  @observable public parentCategoryId: string = ''
  @observable public availableForSurveys: boolean = false
  @observable public availableForTasks: boolean = false
  @observable public saveTried: boolean = false

  @action
  public markSaveTried() {
    this.saveTried = true
  }

  @computed
  public get nameValid() {
    if (!this.saveTried) return true
    if (!this.name || this.name.trim() === '') return false
    return true
  }

  @computed
  public get nameUnique() {
    if (!this.saveTried) return true
    const foundCategory = this.rootStore.categoriesStore.getCategoryByName(this.name)
    if (foundCategory) return false
    return true
  }

  @computed
  public get isValid() {
    if (!this.saveTried) return true
    if (!this.nameValid) return false
    if (!this.nameUnique) return false
    return true
  }

  

  @action
  public async save() {
    this.markSaveTried()
    if (!this.isValid) return false
    try {
      const newCategory = {
        name: this.name,
        description: this.description,
        parentCategoryId: this.parentCategoryId,
        availableForSurveys: this.availableForSurveys,
        availableForTasks: this.availableForTasks,
        isDeleted: false
      }
      await new ParseService().saveCategory(this.rootStore.appStore.currentOrgId, newCategory)
      this.rootStore.categoriesStore.clearForms()
    }
    catch (e) {
      console.error(e)
      return false
    }

  }



}