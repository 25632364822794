import React, { useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import BronzeBadge from '../../../assets/img/streak/Bronze.png'
import SilverBadge from '../../../assets/img/streak/Silver.png'
import GoldBadge from '../../../assets/img/streak/Gold.png'
import PlatinumBadge from '../../../assets/img/streak/Platinum.png'
import Drop1 from '../../../assets/img/streak/Fill-1.png'
import Drop2 from '../../../assets/img/streak/Fill-2.png'
import Drop3 from '../../../assets/img/streak/Fill-3.png'
import PartyPopper from '../../../assets/img/streak/party-popper-emoji.png'
import LocalizationStore from '../../localization/LocalizationStore'
import { OrganizationUsersStore } from '../../organization-users/store/OrganizationUsersStore'
import { inject, observer } from 'mobx-react'
import { CheckCircle } from 'react-feather'
import './SurveyStreak.scss'
import { LabelsStore } from '../../labels/store/LabelsStore'

interface NavigationProps {
  localizationStore?: LocalizationStore
  organizationUsersStore?: OrganizationUsersStore
  labelsStore?: LabelsStore
}

const surveyStreak: React.FC<NavigationProps> = ({
  localizationStore,
  organizationUsersStore,
  labelsStore,
}) => {
  useEffect(() => {
    const loadStreakVM = async () => await organizationUsersStore.loadSurveyStreakVM()
    loadStreakVM().catch(console.error)
  }, [])

  if (!organizationUsersStore) return null
  if (!organizationUsersStore.surveyStreakVM) return null
  const { surveyStreakVM: vm } = organizationUsersStore
  const { surveyStreak: lz } = localizationStore.lzStrings

  const renderTitleText = () => {
    if (vm.noStreak) return <Typography className='streak-title'>{lz.start_streak}</Typography>
    return <Typography className='streak-title'>{lz.great_job}</Typography>
  }

  const renderHighlightedText = () => {
    if (vm.noStreak) {
      return (
        <>
          <Typography className='streak-text'>
            {`${lz.complete_a} ${labelsStore.getLabelByLanguageAndFor('pulse')} ${lz.to}`}{' '}
          </Typography>
          <Typography className='streak-text'>{lz.start_a_streak}</Typography>{' '}
        </>
      )
    } else {
      return (
        <>
          <Typography className='streak-text'>{`${lz.your} ${labelsStore.getLabelByLanguageAndFor(
            'pulse'
          )} ${lz.streak}`}</Typography>
          <Typography className='streak-text'>{lz.is_at + `${vm.surveyStreak} 🎉!`}</Typography>{' '}
        </>
      )
    }
  }

  const renderBadge = () => {
    if (vm.noStreak) return null
    if (vm.surveyStreak >= 30) return <img className='streak-badge' src={PlatinumBadge} alt='' />
    if (vm.surveyStreak >= 20) return <img className='streak-badge' src={GoldBadge} alt='' />
    if (vm.surveyStreak >= 10) return <img className='streak-badge' src={SilverBadge} alt='' />
    return <img className='streak-badge' src={BronzeBadge} alt='' />
  }

  const renderDrops = () => {
    return (
      <>
        <img className='drop-logo2' src={Drop1} alt='' />
        <img className='drop-logo' src={Drop2} alt='' />
        <img className='drop-logo' src={Drop3} alt='' />
      </>
    )
  }

  const renderSurveyNumbers = () => {
    return (
      <>
        <Typography className='progress-text'>10</Typography>
        <Typography className='progress-text'>20</Typography>
        <Typography className='progress-text'>30</Typography>
      </>
    )
  }

  const renderChecksArray = () => {
    let items = [5, 10, 15, 20, 25, 30]
    let itemList = []
    const streak = vm.surveyStreak
    const active = vm.activeItem
    items.forEach((item, index) => {
      if (active === item) {
        itemList.push(<div className='progress-circle-active' key={index} />)
      }
      if (streak > item && item !== active) {
        itemList.push(<CheckCircle key={index} className='progress-icon' />)
      }
      if (streak < item && item !== active) {
        itemList.push(<div className='progress-circle' key={index} />)
      }
    })
    return <>{itemList}</>
  }

  const renderProgressBar = () => {
    if (vm.noStreak) return null
    return (
      <>
        <Grid item className='streak-drops-container'>
          {renderDrops()}
        </Grid>
        <Grid item className='streak-checks-container'>
          {renderChecksArray()}
        </Grid>
        <Grid item className='streak-number-container'>
          {renderSurveyNumbers()}
        </Grid>
      </>
    )
  }

  return (
    <div id='SurveyStreak'>
      {renderTitleText()}
      <Grid item>{renderBadge()}</Grid>
      <Grid item className='streak-text-container'>
        {renderHighlightedText()}
      </Grid>
      <Grid item className='streak-text-container-2'>
        <Typography className='streak-text-2'>{`${
          lz.continue_completing
        } ${labelsStore.getLabelByLanguageAndFor('pulses')} ${lz.on_time_to}`}</Typography>
        <Typography className='streak-text-2'>{lz.maintain_progress}</Typography>
      </Grid>
      {renderProgressBar()}
    </div>
  )
}

export default inject(
  'organizationUsersStore',
  'localizationStore',
  'labelsStore'
)(observer(surveyStreak))
