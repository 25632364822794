import React from 'react'
import { inject, observer } from 'mobx-react'
import { Button, Grid, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import './CustomPublishGroupDialog.scss'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { SurveysStore } from '../../../../surveys/store/SurveysStore'
import ParticipantsSelect from '../../../../participants-select/views/ParticipantsSelect'
import CustomPublishTimePicker from './CustomPublishTimePicker'

interface ICustomPublishGroupDialog {
  localizationStore?: LocalizationStore
  surveysStore?: SurveysStore
}

const CustomPublishGroupDialog: React.FC<ICustomPublishGroupDialog> = ({
  localizationStore,
  surveysStore,
}) => {
  const surveyEditVM = surveysStore.viewModels.editVM
  const vm = surveyEditVM.surveyOptionsVM
  return (
    <div id='CustomPublishGroupModal'>
      <Dialog
        id='CustomPublishGroupDialog'
        fullWidth
        maxWidth={'lg'}
        open={vm.isCustomPublishGroupDialogOpen}
        onClose={() => {
          vm.toggleCustomPublishGroupDialogOpen()
          vm.customPublishVM.setCustomPublishGroupNew(false)
        }}
      >
        <DialogTitle>
          <Grid
            className={'custom-publish-dialog-title'}
            container
            justifyContent='space-between'
            alignItems='center'
          >
            <Grid item>{`${
              vm.customPublishVM.isCustomPublishGroupNew ? 'Create' : 'Update'
            } Custom Publish Group`}</Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className={'custom-publish-dialog-content'}>
          <div className={'custom-publish-time-picker'}>
            <CustomPublishTimePicker />
          </div>
          {vm.customPublishVM.editing ? (
            <div className={'custom-publish-participant-select'}>
              <ParticipantsSelect
                participantsSelectVM={vm.customPublishVM.editing.candidatePublishGroupSelectVM}
              />
            </div>
          ) : undefined}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              vm.toggleCustomPublishGroupDialogOpen()
              vm.customPublishVM.setCustomPublishGroupNew(false)
              vm.customPublishVM.editing.setAllParticipants(
                vm.customPublishVM.editing.customPublishGroupSelectVM.filteredParticipants
              )
              vm.customPublishVM.editing.customPublishGroupSelectVM.setParticipants(vm.customPublishVM.editing.customPublishGroupSelectVM.participants)
              vm.customPublishVM.editing.candidatePublishGroupSelectVM.setParticipants([])
              vm.customPublishVM.resetEditing(vm.customPublishVM.editing.customPublishGroup)
              vm.customPublishVM.editing.resetTimes()
            }}
          >
            CANCEL
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              vm.customPublishVM.editing.setAllParticipants(
                vm.customPublishVM.editing.candidatePublishGroupSelectVM.filteredParticipants
              )
              vm.customPublishVM.editing.customPublishGroupSelectVM.setParticipants(vm.customPublishVM.editing.candidatePublishGroupSelectVM.participants)
              vm.customPublishVM.editing.candidatePublishGroupSelectVM.setParticipants([])
              if (vm.customPublishVM.isCustomPublishGroupNew) {
                vm.addCustomPublishGroup(vm.customPublishVM.editing)
              } else {
                vm.updateCustomPublishGroup(vm.customPublishVM.editing)
              }
              vm.customPublishVM.setCustomPublishGroupNew(false)
              vm.toggleCustomPublishGroupDialogOpen()
            }}
          >
            {vm.customPublishVM.isCustomPublishGroupNew ? 'CREATE' : 'UPDATE'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default inject('localizationStore', 'surveysStore')(observer(CustomPublishGroupDialog))
