import Parse from 'parse'
import { observable } from 'mobx'
import { FKUser } from '../../../../organization-users/aggregate/FKUser'
import { DataStore } from '../../../../shared/data/DataStore'
import { nameOf } from '../../../../shared/nameOf'
import { RootStore } from '../../../../stores/RootStore'
import { PulseCampaign } from '../../../aggregate/PulseCampaign'
import { IPulseCampaignDTO } from '../../../dto/IPulseCampaignDTO'
import { IPulseCampaignFindRequest } from '../../../interfaces/IPulseCampaignFindRequest'
import { PulseCampaignsService } from '../../../service/PulseCampaignsService'

export class DraftPulseCampaignsDataStore extends DataStore<PulseCampaign, IPulseCampaignDTO> {
  private request: IPulseCampaignFindRequest

  constructor(rootStore: RootStore, req: IPulseCampaignFindRequest) {
    super(rootStore, PulseCampaign, 'pulseCampaigns', [
      nameOf<IPulseCampaignDTO, string>((e) => e.name),
      nameOf<IPulseCampaignDTO, string>((e) => e.startDate),
      nameOf<IPulseCampaignDTO, string>((e) => e.endDate),
      nameOf<IPulseCampaignDTO, boolean>((e) => e.isTemplate),
    ])
    this.request = req
    this.paged = true
    this.recordsPerPage = 100
  }

  @observable public isLoaded: boolean = false

  public onListRecordsLoaded() {
    this.isLoaded = true
  }

  public setRequest(request: IPulseCampaignFindRequest) {
    this.request = request
  }

  public getRequest(): IPulseCampaignFindRequest {
    return this.request
  }

  protected getListRecordsApiCall() {
    return async () => {
      const req: IPulseCampaignFindRequest = {
        ...this.request,
        listColumns: this.listColumns,
      }
      const svc = new PulseCampaignsService()
      const result = await svc.findPulseCampaigns(req)
      this.totalRecords = result.count
      return result.pulseCampaigns
    }
  }

  public get rows() {
    return this.records
  }
}
