import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Button
} from '@material-ui/core'
import './MuiDialogMessage.scss'

const MuiDialogMessage = (props => {
  return (
    <div id='MuiDialogMessage'>
      <Dialog
        onClose={props.onClose}
        open={props.open}
      >
        <DialogTitle className='MuiDialogMessageTitle' >
          {props.message}
        </DialogTitle>
        <DialogContent className='MuiDialogMessageContent'>
          <Grid container justifyContent='flex-end'>
            <Button className='okButton' onClick={props.onClose}>Ok</Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  )
})

export default MuiDialogMessage