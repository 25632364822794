import React from 'react'
import { inject, observer, useLocalStore } from 'mobx-react'
import Dropzone from 'react-dropzone'
import {
  Button,
  Grid,
  makeStyles,
  Checkbox,
  Dialog,
  DialogContent,
  DialogActions,
  InputLabel,
  TextField,
  CircularProgress,
  Box,
  DialogTitle,
  DialogContentText,
  IconButton,
  Theme,
  Typography,
  Switch,
  withStyles,
} from '@material-ui/core'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import CloseIcon from '@material-ui/icons/Close'
import DescriptionIcon from '@material-ui/icons/Description'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlankRounded'
import CheckBoxIcon from '@material-ui/icons/CheckBoxRounded'
import PreviewIcon from '@material-ui/icons/VideoCallRounded'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import env from '../../../../env'
import { RootStore } from '../../../stores/RootStore'
import LocalizationStore from '../../../localization/LocalizationStore'
import ContentPickerDialogHeader from './CMSItemsPickerDialogHeader'
import { CMSItemsUploadVM } from '../../view-models/CMSItemsUploadVM'
import { CMSItemExternalVideoPreviewVM } from '../../view-models/CMSItemExternalVideoPreviewVM'
import { FileUploadModalVM } from '../../../upload/view-models/FileUploadModalVM'
import { FileVM } from '../../../upload/view-models/FileVM'
import { FileTypeEnum } from '../../../upload/enum/FileTypeEnum'
import { IParseFileSaveResult } from '../../../upload/interfaces/IParseFileSaveResult'
import { ICMSItemUploadRequest } from '../../interfaces/ICMSItemUploadRequest'
import { blue, teal } from '@material-ui/core/colors'
import './CMSItemsDialog.scss'

interface Props {
  vm: CMSItemsUploadVM
  isOpen: boolean
  fileTypes?: FileTypeEnum
  addAfterSave?: Function
  toggleShowModal?: Function
  rootStore?: RootStore
  localizationStore?: LocalizationStore
}

const useStyles = makeStyles((theme: Theme) => ({
  backDrop: {
    backdropFilter: 'blur(1px)',
    backgroundColor: 'rgba(0,0,30,0.9)',
  },
  input: {
    textAlign: 'center',
    '&::placeholder': {
      textAlign: 'center',
    },
  },
}))

const CMSItemsUploadDialog: React.FC<Props> = ({
  vm,
  localizationStore,
  isOpen,
  fileTypes = FileTypeEnum.DEFAULT,
  addAfterSave,
  toggleShowModal,
  rootStore,
}) => {
  const classes = useStyles()
  const { contentManagement: lz } = localizationStore.lzStrings

  const localStore = useLocalStore(() => ({
    vm: new FileUploadModalVM(rootStore, false, addAfterSave, toggleShowModal, fileTypes),
  }))
  const fileUploadVM = localStore.vm

  if (!vm) return

  const handleClose = () => {
    vm.doneTried = false
    vm.closeUploadDialog()
    handleReset()
  }

  const handleReset = () => {
    vm.reset()
    fileUploadVM.reset()
  }

  const handleDone = () => {
    vm.doneTried = true
    if (!vm.isValid) return
    vm.isSaving = true

    vm.handleUpload(() => fileUploadVM.reset())
  }

  const renderDialogTitle = () => {
    return (
      <div className='intro-text intro-header'>
        <ContentPickerDialogHeader
          title={'Upload Media File'}
          onClose={() => vm.closeUploadDialog()}
        />
      </div>
    )
  }

  const handleNameChange = (event: React.ChangeEvent<{ value: string }>) => {
    vm.setTitle(event.target.value || '')
  }

  const handleDescriptionChange = (event: React.ChangeEvent<{ value: string }>) => {
    vm.setDescription(event.target.value || '')
  }

  const handleExtVideoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    vm.toggleExtVideoEnabled()
    fileUploadVM.reset()
  }

  const handleExtVideoUrlChange = (event: React.ChangeEvent<{ value: string }>) => {
    vm.setExtVideoUrl(event.target.value || '')
  }

  const handlePrivateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    vm.togglePrivateEnabled()
  }

  const renderYouTubeVideo = (previewVM: CMSItemExternalVideoPreviewVM) => {
    return (
      <iframe
        width={'auto'}
        height={150}
        src={previewVM.embedUrlPlay}
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
      />
    )
  }

  const renderVimeoVideo = (previewVM: CMSItemExternalVideoPreviewVM) => {
    return (
      <iframe
        src={previewVM.embedUrlPlay}
        width={'auto'}
        height={'auto'}
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
      />
    )
  }

  const renderContentInfo = () => {
    return (
      <div className='content-upload-dialog-form'>
        <form className={'dialog-form'}>
          <Grid container spacing={2} direction='column' className='settings-group'>
            {renderExtVideo()}
            {renderTitle()}
            {renderDescription()}
            {renderPrivateSwitch()}
            {renderTags()}
          </Grid>
        </form>
      </div>
    )
  }

  const renderTitle = () => {
    return (
      <Grid item sm={12}>
        <InputLabel required htmlFor='content-name' className={'form-group-label'}>
          Title
        </InputLabel>
        <TextField
          error={!vm.isNameValid}
          value={vm.title}
          onChange={handleNameChange}
          fullWidth
          variant='outlined'
          className='content-name'
          inputProps={{ readOnly: false }}
          disabled={vm.isLoading || vm.isSaving}
          autoComplete='off'
        />
      </Grid>
    )
  }

  const renderDescription = () => {
    return (
      <Grid item sm={12}>
        <InputLabel htmlFor='content-description' className={'form-group-label'}>
          {lz.description}
        </InputLabel>
        <TextField
          multiline
          error={!vm.isDescriptionValid}
          value={vm.description}
          minRows={1}
          onChange={handleDescriptionChange}
          fullWidth
          variant='outlined'
          className='content-description'
          disabled={vm.isLoading || vm.isSaving}
          autoComplete='off'
          inputProps={{ maxLength: 255 }}
          helperText={lz.max_chars_helper}
        />
      </Grid>
    )
  }

  const renderPrivateSwitch = () => {
    return (
      <Grid item sm={12}>
        <InputLabel htmlFor='preview-private-switch' className={'form-group-label'}>
          Private Upload
        </InputLabel>
        <Switch
          checked={vm.isPrivate}
          onChange={handlePrivateChange}
          name='upload-private-switch'
          inputProps={{ readOnly: false }}
          disabled={vm.isLoading || vm.isSaving}
        />
      </Grid>
    )
  }

  const renderExtVideo = () => {
    return (
      <Grid item>
        <InputLabel htmlFor='content-ext-video' className={'form-group-label'}>
          Type
        </InputLabel>
        {renderExtVideoSwitch()}
      </Grid>
    )
  }

  const ExtVideoSwitch = withStyles({
    switchBase: {
      color: teal[300],
      '&$checked': {
        color: teal[500],
      },
      '&$checked + $track': {
        backgroundColor: blue[500],
      },
    },
    checked: {},
    track: {},
  })(Switch)

  const renderExtVideoSwitch = () => {
    return (
      <Grid item sm={12}>
        <Grid container spacing={1} direction='row'>
          <Grid item className={'switch-label-group'}>
            <Typography className={'switch-label'}>File</Typography>
          </Grid>
          <Grid item>
            <ExtVideoSwitch
              checked={vm.isExternalVideo}
              onChange={handleExtVideoChange}
              name='upload-ext-video-switch'
              inputProps={{ readOnly: false }}
              disabled={vm.isLoading || vm.isSaving}
            />
          </Grid>
          <Grid item className={'switch-label-group'}>
            <Typography className={'switch-label'}>Video URL (Vimeo/YouTube)</Typography>
          </Grid>
          {renderExtVideoUrl()}
        </Grid>
      </Grid>
    )
  }

  const renderExtVideoUrl = () => {
    if (!vm.isExternalVideo) return
    return (
      <Grid item sm={12}>
        <TextField
          error={!vm.isExtVideoUrlValid}
          value={vm.extVideoUrl}
          onChange={handleExtVideoUrlChange}
          fullWidth
          variant='outlined'
          className='content-ext-video-url'
          inputProps={{ readOnly: false }}
          disabled={vm.isLoading || vm.isSaving}
          autoComplete='off'
          placeholder='eg. https://vimeo.com/1234'
        />
        <DialogContentText className={'file-details'}>
          <span className={'file-details-title-sub'}>
            Supported URLs include YouTube and Vimeo.
          </span>{' '}
          Note: Please ensure the video is publicly accessible.
        </DialogContentText>
      </Grid>
    )
  }

  const renderTags = () => {
    if (!vm.canManageTags) return

    return (
      <Grid item sm={12}>
        <InputLabel htmlFor='content-ext-video' className={'form-group-label'}>
          Tags
        </InputLabel>
        <Autocomplete
          className={'toolbar-filter'}
          key={'toolbar-filter-format'}
          size='small'
          limitTags={4}
          multiple
          disableCloseOnSelect
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                checkedIcon={<CheckBoxIcon fontSize='small' />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </React.Fragment>
          )}
          onChange={(e, val) => {
            if (val === null) {
              vm.setTagValues([])
            } else {
              vm.setTagValues(val)
            }
          }}
          options={vm.tagOptions}
          getOptionLabel={(option) => option}
          getOptionSelected={(option, value) => option === value}
          renderInput={(params) => (
            <TextField
              label=''
              name='toolbar-tag-text'
              type='search'
              variant='outlined'
              placeholder={''}
              {...params}
            />
          )}
        />
      </Grid>
    )
  }

  const renderPreview = (item: FileVM, index: number) => {
    if (item.type === 'image')
      return (
        <img
          className='media-preview image'
          id={`upload-img-${item.shorterFileName}-${item.type}`}
          key={`upload-img-${item.shorterFileName}-${item.type}`}
          src={item.preview}
          alt={'preview'}
          onClick={() => vm.toggleLightBox()}
        />
      )
    if (item.type === 'video')
      return (
        <video
          className='media-preview image'
          id={`upload-video-${item.shorterFileName}-${item.type}`}
          key={`upload-video-${item.shorterFileName}-${item.type}`}
          src={item.preview}
          controls
        />
      )
    return <DescriptionIcon className={'media-preview file'} />
  }

  const renderFile = (item: FileVM, index: number) => {
    if (!item) return

    return (
      <Grid item xs={12} key={index} className='attachment-container'>
        {renderPreview(item, index)}
        {renderLightBox(item)}
        <div className='attachment-clear-btn'>
          <IconButton
            disabled={fileUploadVM.uploadProcessing}
            onClick={() => fileUploadVM.removeFile(index)}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </Grid>
    )
  }

  const renderExternalVideoProvider = (previewVM: CMSItemExternalVideoPreviewVM) => {
    if (!previewVM) return
    if (previewVM.provider === 'youtube') return renderYouTubeVideo(previewVM)
    if (previewVM.provider === 'vimeo') return renderVimeoVideo(previewVM)
  }

  const renderExternalVideoHelpText = () => {
    const previewVM = vm.extVideoPreviewVM
    if (!previewVM) return
    if (previewVM.provider) return

    return (
      <div className='iconContainer'>
        <PreviewIcon className='noteAddIcon' />
        <p className='dropzoneText'>Add a URL below to generate a preview.</p>
      </div>
    )
  }

  const renderExternalVideo = () => {
    const previewVM = vm.extVideoPreviewVM
    if (!previewVM || !previewVM.provider) return

    return (
      <div className={'attachment-clear-root'}>
        {renderExternalVideoProvider(previewVM)}
        <div className='attachment-clear-btn'>
          <IconButton onClick={() => vm.setExtVideoUrl('')}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    )
  }

  const renderFiles = () => {
    if (vm.extVideoPreviewVM)
      return (
        <Grid container className='filesContainer'>
          <Grid item xs={12} className='attachment-container'>
            {renderExternalVideoHelpText()}
            {renderExternalVideo()}
          </Grid>
        </Grid>
      )

    return (
      <Grid container className='filesContainer'>
        {fileUploadVM.files.map((file, index) => {
          return renderFile(file, index)
        })}
      </Grid>
    )
  }

  const renderFileTooBig = (file: File, index: number) => {
    return (
      <Grid item xs={12} key={index} className={'errorContainer'}>
        <div className={'errorText'}>{`${file.name} ${lz.exceeds_max_size}.`}</div>
      </Grid>
    )
  }

  const renderFilesTooBig = () => {
    if (!fileUploadVM.filesTooBig) return
    return (
      <Grid container className='errorContainer'>
        {fileUploadVM.filesTooBig.map((file, index) => {
          return renderFileTooBig(file, index)
        })}
      </Grid>
    )
  }

  const renderDefault = () => {
    if (fileUploadVM.uploadProcessing) return
    if (fileUploadVM.files.length > 0) return
    if (vm.extVideoPreviewVM) return
    return (
      <Grid container className='dialog-content-grid'>
        <DialogContent className='dialog-content'>
          <Dropzone
            disabled={vm.isExternalVideo}
            accept={fileUploadVM.mimeTypes}
            noDragEventsBubbling={true}
            onDrop={(files) => {
              fileUploadVM.handleFilesDrop(files)
              if (fileUploadVM.files && fileUploadVM.files.length > 0) {
                const fileVM = fileUploadVM.files[0]
                const upload = {
                  title: fileVM.name,
                  description: vm.description,
                  file: fileVM.file,
                  organizationId: rootStore.appStore.currentOrgId,
                } as ICMSItemUploadRequest
                vm.setUpload(upload)
              }
            }}
            multiple={fileUploadVM.allowMultiple}
            minSize={0}
          >
            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
              <>
                <div className='dropzone' {...getRootProps()}>
                  <input {...getInputProps()} />

                  {!isDragActive && (
                    <div className='iconContainer'>
                      <NoteAddIcon className='noteAddIcon' />
                      {renderDropzoneDropText()}
                    </div>
                  )}

                  {isDragActive && !isDragReject && (
                    <div className='iconContainer'>
                      <ThumbUpIcon className='thumbUpIcon' />
                      <p className='dropzoneText'>{lz.file_good_to_go}</p>
                    </div>
                  )}

                  {isDragActive && isDragReject && (
                    <div className='iconContainer'>
                      <ThumbDownIcon className='thumbDownIcon' />
                      <p className='dropzoneText'>{lz.sorry_file_not_accepted}</p>
                    </div>
                  )}
                </div>
              </>
            )}
          </Dropzone>
          {renderDropzoneFileText()}
        </DialogContent>
      </Grid>
    )
  }

  const renderDropzoneDropText = () => {
    if (vm.isExternalVideo) return <p className='dropzoneText'>--</p>
    return <p className='dropzoneText'>{lz.drag_and_drop_or_browse}</p>
  }

  const renderExternalVideoText = () => {
    return (
      <div className='dialogText'>
        <DialogContentText className={'file-details'}>
          <span className={'file-details-title'}>File Dropzone (Disabled):</span> To upload a file
          from your computer please toggle the External Video URL option in the form below!
        </DialogContentText>
        <DialogContentText className={'file-details'}>
          <span className={'file-details-title'}>{lz.max_file_size}</span> --
        </DialogContentText>
      </div>
    )
  }

  const renderDropzoneFileText = () => {
    if (vm.isExternalVideo) return renderExternalVideoText()
    return (
      <div className='dialogText'>
        <DialogContentText className={'file-details'}>
          <span className={'file-details-title'}>{lz.accepted_file_types}</span>{' '}
          {fileUploadVM.acceptedFileTypes}
        </DialogContentText>
        <DialogContentText className={'file-details'}>
          <span className={'file-details-title'}>{lz.max_file_size}</span>{' '}
          {fileUploadVM.fileSizePrompt}
        </DialogContentText>
      </div>
    )
  }

  const renderProgress = () => {
    const useCloudinary = env.var.REACT_APP_MEDIA_UPLOAD_SERVICE === 'cloudinary'
    return (
      <div className='dropzone'>
        <div className='iconContainer'>
          <Typography variant='body1' className='dropzoneText'>
            {fileUploadVM.uploadProcessingText}
          </Typography>
          <Typography variant='caption' className='dropzoneText'>
            {fileUploadVM.uploadProcessingSubText}
          </Typography>
          {useCloudinary && (
            <Box position='relative' display='inline-flex'>
              <CircularProgress
                className={'uploadProgress'}
                variant='determinate'
                value={fileUploadVM.uploadProcessingProgress}
              />
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position='absolute'
                display='flex'
                alignItems='center'
                justifyContent='center'
              >
                <Typography variant='caption' component='div' color='textSecondary'>{`${Math.round(
                  fileUploadVM.uploadProcessingProgress
                )}%`}</Typography>
              </Box>
            </Box>
          )}
        </div>
      </div>
    )
  }

  const renderProcessing = () => {
    if (!fileUploadVM.uploadProcessing) return
    return (
      <Grid container className='dialog-content'>
        <DialogContent>
          {renderProgress()}
          <div className='dialogText'>
            <DialogContentText>
              {`${lz.accepted_file_types} ${fileUploadVM.acceptedFileTypes}`}
            </DialogContentText>
            <DialogContentText>{`${lz.max_file_size} ${fileUploadVM.fileSizePrompt}`}</DialogContentText>
          </div>
        </DialogContent>
      </Grid>
    )
  }

  const renderDropzone = () => {
    if (fileUploadVM.showErrors) return
    return (
      <>
        {renderDefault()}
        {renderProcessing()}
        {renderFiles()}
        {renderFilesTooBig()}
      </>
    )
  }

  const renderError = (error: IParseFileSaveResult, index) => {
    return (
      <Grid item xs={12} key={index} className={'errorContainer'}>
        <div>{error.fileName}</div>
        <div className={'errorText'}>{error.error}</div>
      </Grid>
    )
  }

  const renderErrors = () => {
    if (!fileUploadVM.showErrors) return
    return (
      <>
        <Grid
          className='dialog-title'
          container
          justifyContent='space-between'
          alignItems='center'
          style={{ paddingRight: '10px' }}
        >
          <Grid item>
            <DialogTitle>{lz.file_upload_error}</DialogTitle>
          </Grid>
          <Grid item>
            <IconButton onClick={() => fileUploadVM.toggleModal()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContentText className='errorDialog'>
          {`${lz.error_uploading_message}:`}
        </DialogContentText>
        <Grid container className='errorContainer'>
          {fileUploadVM.errors.map((error, index) => {
            return renderError(error, index)
          })}
        </Grid>
        <DialogActions>
          <Grid
            container
            className='dialog-actions'
            alignItems='center'
            justifyContent='space-between'
          >
            <Grid item>
              <Button
                variant='contained'
                size='large'
                onClick={() => fileUploadVM.toggleModal()}
                className='saveButton'
              >
                {lz.ok}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </>
    )
  }

  const renderBody = () => {
    return (
      <Grid item xs={12} className='body'>
        <Grid container spacing={2} direction='row'>
          <Grid item xs={12} className='content-info'>
            {renderDropzone()}
            {renderErrors()}
            {renderContentInfo()}
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderActionButtons = () => {
    return (
      <Grid item xs={12} className='dialog-action-buttons'>
        <Grid item>
          <Button
            disabled={vm.isLoading || vm.isSaving}
            variant='outlined'
            onClick={(e) => handleReset()}
          >
            {lz.reset}
          </Button>
        </Grid>
        <Grid item>
          <Button
            disabled={vm.isLoading || vm.isSaving}
            variant='outlined'
            onClick={(e) => handleClose()}
          >
            {lz.cancel}
          </Button>
          <Button
            disabled={!vm.isValid || vm.isLoading || vm.isSaving}
            variant='contained'
            onClick={() => handleDone()}
          >
            {lz.upload}
          </Button>
        </Grid>
      </Grid>
    )
  }

  const renderDialogContent = () => {
    return (
      <DialogContent>
        <Grid
          className='content-upload-dialog-content'
          container
          direction='column'
          justifyContent='center'
          alignItems='stretch'
        >
          {renderBody()}
        </Grid>
      </DialogContent>
    )
  }

  const renderDialogActions = () => {
    return (
      <DialogActions>
        <Grid
          className='content-upload-dialog-actions'
          container
          direction='column'
          justifyContent='center'
          alignItems='stretch'
        >
          {renderActionButtons()}
        </Grid>
      </DialogActions>
    )
  }

  const renderSpinner = () => {
    if (!vm.isLoading && !vm.isSaving) return
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  const renderLightBox = (item: FileVM) => {
    if (!item) return
    if (!item.preview) return
    if (item.type !== 'image') return
    if (!vm.showLightBox) return
    const lightBoxStyles = { overlay: { zIndex: 10000 } }
    return (
      <Lightbox
        mainSrc={item.preview}
        reactModalStyle={lightBoxStyles}
        onCloseRequest={() => vm.toggleLightBox()}
      ></Lightbox>
    )
  }

  return (
    <Dialog
      id='ContentUploadDialog'
      open={isOpen}
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
      onClose={handleClose}
      fullWidth
    >
      <div className={'dialog-container'}>{renderDialogTitle()}</div>
      {renderDialogContent()}
      {renderDialogActions()}
      {renderSpinner()}
    </Dialog>
  )
}

export default inject('localizationStore')(observer(CMSItemsUploadDialog))
