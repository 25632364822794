import { observable, computed, action } from 'mobx'
import { AGGridRowVM } from '../../shared/ag-grid/AGGridRowVM'
import { RootStore } from '../../stores/RootStore'
import { Category } from '../aggregate/Category'
import { ListWidgetVM } from '../view-models/ListWidgetVM'

export class ListWidgetRowVM extends AGGridRowVM<Category> {
  private listVM: ListWidgetVM

  constructor(rootStore: RootStore, category: Category, listVM: ListWidgetVM) {
    super(rootStore, category, listVM)
    this.listVM = listVM
  }

  @observable public isSelected: boolean = false

  @action public toggleSelected() {
    this.isSelected = !this.isSelected
  }

  @computed
  public get nodeRowId(): string {
    return this.objectId
  }

  @computed
  public get objectId(): string {
    return this.aggregate.objectId
  }

  @computed
  public get name(): string {
    return this.aggregate.name
  }

  @computed
  public get categories(): Array<Category> {
    return this.rootStore.categoriesStore.currentOrgCategories
  }

  @computed
  public get parentCategoryName(): string {
    if (!this.parentCategoryId) return ''
    const foundParent = this.categories.find((e) => e.objectId === this.parentCategoryId)
    if (foundParent) return foundParent.name
    return ''
  }

  @computed
  public get parentCategoryId(): string {
    return this.aggregate.parentCategoryId
  }

  @computed
  public get parentPathName(): string {
    return this.aggregate.parentPathName
  }

  @computed
  public get availableForSkills(): string {
    if (this.aggregate.availableForSkills) return 'Yes'
    return 'No'
  }

  @computed
  public get availableForSurveys(): string {
    if (this.aggregate.availableForSurveys) return 'Yes'
    return 'No'
  }

  @computed
  public get availableForTasks(): string {
    if (this.aggregate.availableForTasks) return 'Yes'
    return 'No'
  }

  @computed
  public get availableForWork(): string {
    if (this.aggregate.availableForWork) return 'Yes'
    return 'No'
  }

  @action public editCategory() {
    if (this.objectId) {
      if (this.listVM.tabIndex === 1) {
        this.rootStore.categoriesStore.showEditButton()
      }
      this.rootStore.categoriesStore.widgetForm.setField('objectId', this.objectId)
      this.rootStore.categoriesStore.widgetForm.initEditForm(
        this.rootStore.categoriesStore.categories,
        this.rootStore.categoriesStore.widgetForm.objectId
      )
      if (this.listVM.tabIndex === 0) this.rootStore.categoriesStore.widgetForm.openDrawer()
    }
  }
}
