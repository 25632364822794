import React from 'react'
import { observer } from 'mobx-react'
import rootStore from '../../../stores/RootStore'
import { Grid, Hidden } from '@material-ui/core'
import CalendarEventDrawer from '../edit/CalendarEventDrawer'
import CalendarTopToolbar from './CalendarTopToolbar'
import MiniCalendar from './MiniCalendar'
import UserEventPopup from '../popup/userEvent/UserEventPopup'
import OtherCalendarsSelect from './OtherCalendarsSelect'
import MyCalendarsSelect from './MyCalendarsSelect'
import EventPill from './EventPill'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import './Calendar.scss'
import UserSurveyPopup from '../popup/survey/UserSurveyPopup'
import UserTaskPopup from '../popup/task/UserTaskPopup'
import UserTrainingPlanPopup from '../popup/trainingPlan/UserTrainingPlanPopup'
import TrainingItemPopup from '../popup/trainingItem/TrainingItemPopup'
import TasksManagementDrawer from '../../../tasks/views/drawer/Drawer'
import UserTasksDrawer from '../../../user-tasks/views/drawer/Drawer'
import CalendarExportModal from './export-modal/CalendarExportModal'
import { EventsStore } from '../../store/EventsStore'
import ViewItems from './ViewItems'
import { momentLocalizer, Calendar } from 'react-big-calendar'
import moment from 'moment'
import EventPopup from '../popup/event/EventPopup'

interface Props {
  props: any
  eventsStore?: EventsStore
}

@observer
export default class RippleCalendar extends React.Component<Props, any> {
  constructor(props) {
    super(props)
    if (props.params.eventId && props.params.organizationId) {
      setTimeout(() => {
        rootStore.eventsStore.openEventPopupFromLink(
          props.params.eventId,
          props.params.organizationId
        )
      }, 500)
    }
    if (props.params.userId) rootStore.eventsStore.createEventForUser(props.params.userId)
    if (props.params.groupId) rootStore.eventsStore.createEventForGroup(props.params.groupId)
    if (props.params.roleId) rootStore.eventsStore.createEventForRole(props.params.roleId)
    rootStore.eventsStore.lazyLoadListRecords()
    rootStore.audienceMembersStore.loadAudienceMembers()
  }

  componentDidUpdate(props) {
    // if (props.params.eventId && props.params.organizationId) {
    //   if (
    //     rootStore.eventsStore.eventPopupVM &&
    //     props.params.eventId !== rootStore.eventsStore.eventPopupVM.userEvent.eventId
    //   ) {
    //     setTimeout(
    //       () =>
    //         rootStore.eventsStore.openEventPopupFromLink(
    //           props.params.eventId,
    //           props.params.organizationId
    //         ),
    //       500
    //     )
    //   }
    // }
  }

  render() {
    const vm = rootStore.eventsStore.calendarVM
    return (
      <Grid container spacing={0} id='CalendarLayout'>
        <Hidden smDown>
          <Grid container item className='side-container' md={3} spacing={0}>
            <Grid item xs={12} className='mini-calendar'>
              <MiniCalendar />
            </Grid>
            <Grid item xs={12} className='side-padding my-calendars'>
              <h6>Calendars</h6>
              <MyCalendarsSelect />
            </Grid>
            <Grid item xs={12} className='side-padding other-calendars'>
              <OtherCalendarsSelect />
              <ViewItems />
            </Grid>
          </Grid>
        </Hidden>
        <Grid item className='calendar-container' xs={12} md={9}>
          <CalendarTopToolbar />
          <EventPopup />
          <UserEventPopup />
          <UserSurveyPopup />
          <UserTaskPopup />
          <UserTrainingPlanPopup />
          <TrainingItemPopup />
          <Calendar
            toolbar={false}
            localizer={momentLocalizer(moment)}
            culture={vm.culture}
            views={vm.allViews.slice()}
            view={vm.viewMode}
            onView={(v) => vm.setViewMode(v)}
            date={vm.selectedDate}
            onNavigate={(date: Date) => vm.onNavigate(date)}
            eventPropGetter={vm.eventPropGetter}
            // slotPropGetter={vm.slotPropGetter}
            // dayPropGetter={vm.dayPropGetter}
            startAccessor={'localStartDate'}
            endAccessor={'localEndDate'}
            events={vm.formattedEvents.slice()}
            selectable
            allDayAccessor={'allDay'}
            onSelectSlot={(selectedObj) => vm.handleSlotSelected(selectedObj)}
            onSelectEvent={(e) => vm.openEvent(e)}
            components={{
              event: (e) => (
                <div className={e.event.userNotGoing ? 'declined' : 'going'}>
                  <EventPill event={e} />
                </div>
              ),
            }}
          />
          <CalendarEventDrawer />
          <TasksManagementDrawer />
          <UserTasksDrawer />
          <CalendarExportModal />
        </Grid>
      </Grid>
    )
  }
}
