import { observable } from 'mobx'
import { serializable } from 'serializr'

export default class RGLItem {
  static create() {
    const rglItem = new RGLItem()
    return rglItem
  }

  @serializable @observable i: string
  @serializable @observable x: number
  @serializable @observable y: number
  @serializable @observable h: number
  @serializable @observable w: number
  @serializable @observable type: string
  @serializable @observable worksheetId: string
}
