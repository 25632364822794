import * as React from 'react'
import './OptionsHeader.scss'
import { inject, observer } from 'mobx-react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { SurveysStore } from '../../../store/SurveysStore'
import InfoTooltip from '../../../../shared/InfoTooltip'
import { Tooltip } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

interface Props {
  surveysStore: SurveysStore
}

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    color: 'white',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    backgroundColor: theme.palette.common.white,
  },
}))(Tooltip)

const OptionsHeader = ({ surveysStore }: Props) => {
  const { editVM } = surveysStore.viewModels
  if (!editVM) return null

  const noPointer = { cursor: 'default' }

  const renderCategory = () => {
    if (editVM.currentQuestion.type === 'order') return null
    return (
      <LightTooltip title='Specifies type of question.' placement='bottom' style={noPointer}>
        <div id='Category' className='optionsLabel'>
          <p>Category</p>
          <InfoOutlinedIcon />
        </div>
      </LightTooltip>
    )
  }

  return (
    <>
      <div id='OptionsHeader'>
        <LightTooltip title='Select appropriate choice.' placement='bottom' style={noPointer}>
          <div id='Sentiment' className='optionsLabel'>
            <p>Sentiment</p>
            <InfoOutlinedIcon />
          </div>
        </LightTooltip>

        {renderCategory()}

        <LightTooltip title='Assign an integer 0 or greater.' placement='bottom' style={noPointer}>
          <div id='Weight' className='optionsLabel'>
            <p>Weight</p>
            <InfoOutlinedIcon />
          </div>
        </LightTooltip>

        <LightTooltip title='Enter answer here.' placement='bottom' style={noPointer}>
          <div id='AnswerText'>
            <p>Answer Text</p>
            <InfoOutlinedIcon />
          </div>
        </LightTooltip>

        <LightTooltip
          title={
            editVM.currentQuestion.type === 'order'
              ? 'Branching not available for order questions.'
              : 'Assign logic to the answer. '
          }
          placement='bottom'
          style={noPointer}
        >
          <div id='Branching' className='optionsLabel'>
            <p>Branching</p>
            <InfoOutlinedIcon />
          </div>
        </LightTooltip>

        <LightTooltip title='Additional options.' placement='bottom' style={noPointer}>
          <div id='More' className='optionsLabel'>
            <p>More</p>
            <InfoOutlinedIcon />
          </div>
        </LightTooltip>
        <div id='Handle'></div>
      </div>
    </>
  )
}

export default inject('surveysStore')(observer(OptionsHeader))
