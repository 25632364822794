import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { Button, Typography } from '@material-ui/core'
import './DoneContent.scss'
import { AppStore } from '../../../stores/AppStore'
import { UserSurveyTakeVM } from '../../view-models/UserSurveyTakeVM'
import { Player } from '@lottiefiles/react-lottie-player'
import { LabelsStore } from '../../../labels/store/LabelsStore'

interface Props {
  localizationStore?: LocalizationStore
  takeVM: UserSurveyTakeVM
  appStore?: AppStore
  labelsStore?: LabelsStore
}

const DoneContent: React.FC<Props> = ({ takeVM, appStore, labelsStore, localizationStore }) => {
  if (!takeVM) return null
  if (!takeVM.isSubmitted) return null
  const { doneContent: lz } = localizationStore.lzStrings

  const renderPlayer = () => {
    return (
      <Player
        autoplay={true}
        loop={true}
        controls={false}
        src='https://assets10.lottiefiles.com/packages/lf20_rcwlnqyn.json'
        style={{ height: '300px', width: 'auto' }}
      ></Player>
    )
  }

  const handlePendingSurveysButtonClick = () => {
    appStore.router.push('/dashboard/surveys')
  }

  const handleSurveyResultsButtonClick = () => {
    takeVM.showSurveyResults()
  }

  const renderDoneMessage = () => {
    if (!takeVM.showPendingSurveysButton) return
    return `${lz.check_the} ${labelsStore.getLabelByLanguageAndFor('pulse')} ${lz.dashboard_to}`
  }

  const renderPendingSurveysButton = () => {
    if (!takeVM.showPendingSurveysButton) return
    return (
      <Button
        className='back-to-surveys'
        variant='contained'
        disabled={!takeVM.isComplete}
        onClick={handlePendingSurveysButtonClick}
      >
        {`${lz.back_to} ${labelsStore.getLabelByLanguageAndFor('pulses')}`}
      </Button>
    )
  }

  const renderResultsButton = () => {
    if (!takeVM.showSurveyResultsButton) return
    return (
      <Button
        className='view-results'
        variant='text'
        // disabled={!takeVM.isComplete}
        onClick={handleSurveyResultsButtonClick}
      >
        {lz.view_results}
      </Button>
    )
  }

  const defaultDoneContent = () => {
    if (!takeVM) return
    if (takeVM.surveyCompletedContent) return null
    return (
      <>
        <Typography variant='body1' className='success-msg sln-survey-your-all-done'>
          {lz.all_done}
        </Typography>
        {renderPlayer()}
        <Typography className='success-caption'>{`${lz.thank_you} ${labelsStore
          .getLabelByLanguageAndFor('pulse')
          .toLowerCase()}.`}</Typography>
        <Typography className='success-caption mtb'>{renderDoneMessage()}</Typography>
        {renderResultsButton()}
        {renderPendingSurveysButton()}
      </>
    )
  }

  const customDoneContent = () => {
    if (!takeVM) return
    if (!takeVM.surveyCompletedContent) return null
    return (
      <>
        <div
          className='survey-completed-content-text'
          dangerouslySetInnerHTML={{ __html: takeVM.surveyCompletedContent }}
        ></div>
        {renderResultsButton()}
      </>
    )
  }

  return (
    <div id='DoneContent'>
      {defaultDoneContent()}
      {customDoneContent()}
    </div>
  )
}

export default inject(
  'localizationStore',
  'userSurveysStore',
  'appStore',
  'labelsStore'
)(observer(DoneContent))
