import Parse from 'parse'
import { observable, action, computed, runInAction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { UserTask } from '../aggregates/UserTask'
import { PendingUserTasksWidgetVM } from '../view-models/PendingUserTasksWidgetVM'
import { UserTaskEditVM } from '../view-models/UserTaskEditVM'
import { CompletedUserTasksWidgetVM } from '../view-models/CompletedUserTasksWidgetVM'
import { UserTasksTimelineVM } from '../view-models/UserTasksTimelineVM'
import { deserialize } from 'serializr'
import { IUserTaskDTO } from '../dtos/IUserTaskDTO'
import { DataStore } from '../../shared/data/DataStore'

export class UserTasksStore extends DataStore<UserTask, IUserTaskDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, UserTask, 'userTasks', [
      'userId',
      'taskId',
      'surveyId',
      'isCompleted',
      'isRead',
      'readDateTime',
      'organizationId',
      'assignedByUserId',
      'taskTypeId',
      'categoryId',
      'dueDate',
      'channelPartnerId',
      'name',
      'description',
      'feedbackSurveyTemplateId',
      'createdAt',
      'autoPublishFeedback',
      'attachments',
      'assignedToAudienceMembers',
      'alarms',
    ])
  }

  @observable public pendingUserTasksWidgetVM: PendingUserTasksWidgetVM = null
  @observable public editVM: UserTaskEditVM = null
  @observable public completedUserTasksWidgetVM: CompletedUserTasksWidgetVM = null
  @observable public userTasksTimelineVM: UserTasksTimelineVM = null
  @observable public isPendingUserTasksDrawerOpen: boolean = false
  @observable public loaded: boolean = false

  @computed
  public get userTasks(): UserTask[] {
    return this.records
  }

  @action
  public onListRecordsLoaded() {
    this.pendingUserTasksWidgetVM = new PendingUserTasksWidgetVM(this.rootStore)
    this.completedUserTasksWidgetVM = new CompletedUserTasksWidgetVM(this.rootStore)
    this.userTasksTimelineVM = new UserTasksTimelineVM(this.rootStore)
    this.loaded = true
  }

  public getUserTask(objectId: string): UserTask {
    return this.userTasks.find((e) => e.objectId === objectId)
  }

  @computed
  public get currentOrgTasks() {
    return this.userTasks
  }

  @action
  public loadEditVM(userTaskId) {
    const userTask = this.userTasks.filter((userTask) => userTask.objectId === userTaskId)[0]
    this.editVM = UserTaskEditVM.loadUserTaskView(this.rootStore, userTask)
    this.openDrawer()
  }

  @action
  public openDrawer() {
    this.isPendingUserTasksDrawerOpen = true
  }

  @action
  public closeDrawer() {
    this.isPendingUserTasksDrawerOpen = false
  }
}
