import { action, computed, observable } from 'mobx'
import { RootStore } from 'src/app/stores/RootStore'
import { Announcement } from '../../aggregate/Announcement'
import { UserAnnouncementVM } from '../UserAnnouncementVM'
import User from 'src/app/users/aggregate/User'
import { AnnouncementListTableVM } from './AnnouncementListTableVM'
import { AnnouncementListDataStore } from '../../store/AnnouncementListDataStore'

export class AnnouncementListVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, userId: string) {
    this.rootStore = rootStore
    this.userId = userId
    this.dataStore = new AnnouncementListDataStore(this.rootStore)
    this.tableVM = new AnnouncementListTableVM(this.rootStore, this, this.dataStore)
    this.refreshData()
  }

  @action
  public refreshData() {
    this.dataStore.loadListRecords()
  }

  @observable public userId: string = null
  @observable public tableVM: AnnouncementListTableVM = null
  @observable public userAnnouncementVM: UserAnnouncementVM = null
  @observable public announcement: Announcement
  @observable public createdByUser: User
  @observable public announcementIndex: number
  private dataStore: AnnouncementListDataStore

  @computed
  public get isAdmin() {
    const isAdmin = this.rootStore.appStore.isSystemAdmin || this.rootStore.appStore.isOrgAdmin
    return isAdmin
  }

  @computed
  public get isLoadingAnnouncements() {
    if (this.dataStore.loaded) return false
    return true
  }

  @computed
  public get hasAnnouncements() {
    if (this.dataStore.announcements.length > 0) return true
    return false
  }

  @action
  public toggleForLoggedInUser() {
    this.dataStore.setForLoggedInUser()
  }
}
