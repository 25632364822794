
import React from 'react'
import { inject, observer } from 'mobx-react'
import Popper from '@material-ui/core/Popper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Paper from '@material-ui/core/Paper'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import Collapse from '@material-ui/core/Collapse'
import Typography from '@material-ui/core/Typography'
import CheckIcon from '@material-ui/icons/Check'
import FavoriteIcon from '@material-ui/icons/Favorite'
import ShareIcon from '@material-ui/icons/Share'
import { UserDashboardsStore } from '../../UserDashboardsStore'
import LocalizationStore from '../../../../localization/LocalizationStore'

interface Props {
  anchorRef: any
  userDashboardsStore?: UserDashboardsStore
  localizationStore?: LocalizationStore
}

const DashboardDropdown: React.FC<Props> = ({ anchorRef, userDashboardsStore, localizationStore }) => {
  const vmSvc = userDashboardsStore.viewModels
  const vm = userDashboardsStore.viewModels.userDashboardVM
  if (!vm) return null
  const lz = localizationStore.lzStrings.userDashboards

  const otherDashboards = () => {
    let dashboards = []
    vmSvc.dashboardsInDropdown.forEach((dashboard) => {
      const selected = dashboard.objectId === vm.objectId
      dashboards.push(
        <MenuItem className={selected ? 'popper-listItem selected' : 'popper-listItem'} key={dashboard.objectId} onClick={() => vmSvc.setSelectedDashboardRoute(dashboard)} >
          {dashboard.isFavorite && <FavoriteIcon className='favoriteIcon' />}
          {dashboard.sharedWith.length > 0 && <ShareIcon className='favoriteIcon' />}
          <Typography className='text' variant='inherit' noWrap>
            {dashboard.name}
          </Typography>
          {selected && <CheckIcon className='checkIcon' />}
        </MenuItem>
      )
    })

    return (
      <div className={'dash-list'}>
        {dashboards}
      </div>
    )
  }

  const renderSharedMenu = () => {
    return (
      <MenuItem className={'popper-listItem allSharedDash'} key={'sharedDashboards'} onClick={() => vm.toggleShowSharedDashboardsDrawer()} >
        <Typography className='text' variant='inherit'>
          {lz.shared_dashboards}
        </Typography>
      </MenuItem>
    )
  }

  return (
    <Popper
      className='moreDashboardsList'
      open={vm.showMenu}
      anchorEl={anchorRef.current}
      placement='bottom-start'
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Collapse
          in={vm.showMenu}
          {...TransitionProps}
        >
          <Paper
            className='popper-paper'
            elevation={1}
          >
            <ClickAwayListener onClickAway={() => vm.toggleShowMenu()}>
              <MenuList className='popper-root'
                autoFocusItem={vm.showMenu}
                onKeyDown={() => console.log('key down')}
              >

                {otherDashboards()}
                {renderSharedMenu()}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Collapse>
      )}
    </Popper>
  )
}

export default inject('userDashboardsStore', 'localizationStore')(observer(DashboardDropdown))

