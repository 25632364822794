import React from 'react'
import { Card } from '@material-ui/core'
import { WidgetContainerVM } from '../../../dashboards/store/view-models/WidgetContainerVM'
import { WorksheetsStore } from '../../store/WorksheetsStore'
import { observer, inject } from 'mobx-react'
import { ChartWidgetVM } from '../../view-models/chart-widget/ChartWidgetVM'
import './WorksheetsListWidget.scss'
import Header from './Header'
import Content from './Content'

interface Props {
  worksheetsStore?: WorksheetsStore
}

const WorksheetsListWidget: React.FC<Props> = ({ worksheetsStore }) => {
  const { listWidgetVM } = worksheetsStore
  if (!listWidgetVM) return null

  return (
    <Card id='WorksheetsListWidget' elevation={0}>
      <Header />
      <Content />
    </Card>
  )
}

export default inject('worksheetsStore')(observer(WorksheetsListWidget))
