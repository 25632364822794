import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import './CircularProgressBar.css'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import rippleDrop from '../../../assets/img/logos/ripple-drop.png'
import { makeStyles } from '@material-ui/core'

interface Props {
  progress: number
}

const useStyles = makeStyles(() => ({
  circle: {
    stroke: "url(#linearColors)",
  },
}));

const CircularProgressBar: React.FC<Props> = ({ progress }) => {
  const classes = useStyles({});

  return (
    <>
      <svg style={{ height: 0 }}>
        <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
          <stop offset="0%" stopColor="#7A67ED" />
          <stop offset="100%" stopColor="#71E3FC" />
        </linearGradient>
      </svg>
      <div id='CircularProgressBar' style={{ height: 50, width: 50 }}>
        <CircularProgressbarWithChildren value={progress} strokeWidth={12}>
          <img style={{ width: 18, marginTop: 2 }} alt='RippleWorx Logo' src={rippleDrop} />
        </CircularProgressbarWithChildren>
      </div>
    </>

  )
}

export default inject()(observer(CircularProgressBar))
