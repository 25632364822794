import { action, computed, observable } from 'mobx'
import Parse from 'parse'
import { UserImportRowVM } from './UserImportRowVM'
import moment from 'moment'
import { ErrorRowVM } from './ErrorRowVM'
import { RootStore } from '../../../stores/RootStore'
import { UsersService } from '../../../users/service/UsersService'
import { IUsersImportResultDTO } from '../../dtos/IUsersImportResultDTO'
import env from '../../../../env'
import { IUserImportRowDTO } from '../../dtos/IUserImportRowDTO'
import * as XLSX from 'xlsx'

export class UserImportVM {
  private rootStore: RootStore
  private svc: UsersService
  private subscription: Parse.LiveQuerySubscription
  private query: Parse.Query
  private capturedRows: any = {}
  @observable public rows: any[] = []

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.svc = new UsersService(this.rootStore)
  }

  @observable public file: File = null
  @observable public importProcessing: boolean = false
  @observable public result: IUsersImportResultDTO = null
  @observable public showUsersImport: boolean = false
  @observable public importCompleted: boolean = false
  @observable public acceptingChanges: boolean = false
  @observable public acceptedChangesComplete: boolean = false
  @observable public selectedSheet = ''
  @observable public sheetSelectedDialogOpen: boolean = false
  @observable public xlsxFile: File = null
  @observable public xlsxSheets: Array<string> = null
  @observable public confirmResetDialogOpen = false

  public handleFileDrop(file) {
    let selectedFile = file[0]
    if (selectedFile.path.includes('.xlsx')) {
      this.xlsxFile = selectedFile
      this.getSheets()
      setTimeout(() => this.toggleSheetSelectionDialog(), 1000)
    } else {
      this.file = selectedFile
    }
    return
  }

  @action toggleSheetSelectionDialog() {
    this.sheetSelectedDialogOpen = !this.sheetSelectedDialogOpen
  }

  @action setSelectedSheet(sheet) {
    this.selectedSheet = sheet
  }

  @action
  public getSheets(): Array<any> {
    if (!this.xlsxFile) return
    this.xlsxSheets = []
    const reader = new FileReader()
    reader.onload = (e) => {
      const workbook = XLSX.read(e.target.result, { type: 'binary' })
      workbook.SheetNames.forEach((e, index) => this.xlsxSheets.push(e))
    }
    reader.readAsBinaryString(this.xlsxFile)
    return
  }

  @action
  public async convertSheetToCsv() {
    if (!this.xlsxFile || !this.selectedSheet) return
    this.toggleSheetSelectionDialog()
    const reader = new FileReader()
    reader.onload = (e) => {
      // Read the XLSX file
      const workbook = XLSX.read(e.target.result, { type: 'binary' })
      // Get the first worksheet
      const worksheet = workbook.Sheets[this.selectedSheet]
      // Convert the worksheet to a CSV string
      const csvString = XLSX.utils.sheet_to_csv(worksheet)
      // Write the CSV string to a file
      const blob = new Blob([csvString], { type: 'text/csv' })
      const file = new File([blob], `${this.selectedSheet}.csv`, { type: 'text/csv' })
      this.file = file
    }
    reader.readAsBinaryString(this.xlsxFile)
  }

  @computed
  public get isImportValidAndComplete(): boolean {
    return (
      this.result &&
      this.result.success &&
      !this.showUsersImport &&
      !this.importProcessing &&
      this.importCompleted
    )
  }

  private hasError(row): boolean {
    if (!row.result) return false
    if (row.result.errorMessage) return true
    if (row.result.orgUserSaveResult.errorMessage) return true
  }

  @computed
  public get hasErrors(): boolean {
    if (!this.result) return false
    if (this.errorRows.length !== 0) return true
    return this.rows.some(this.hasError)
  }

  @computed
  public get errorCount(): number {
    return this.rows.reduce((acc, row) => {
      if (this.hasError(row)) acc += 1
      return acc
    }, 0)
  }

  @computed
  public get validateEnabled() {
    if (this.importProcessing) return false
    if (this.result && this.result.success) return false
    if (this.file) return true
    return false
  }

  @computed
  public get errorRows(): ErrorRowVM[] {
    if (!this.result) return []
    return this.result.errorMessages.map((err, index) => new ErrorRowVM(this.rootStore, err, index))
  }

  @computed
  public get importProgress(): number {
    if (!this.result) return 0
    const val = (this.userRows.length / this.result.numberOfRows) * 100
    return val
  }

  @computed
  public get userRows(): UserImportRowVM[] {
    return this.rows.filter((e) => e.result).map((row) => new UserImportRowVM(this.rootStore, row))
  }

  @action
  public downloadXSLX() {
    window.location.href = `${env.var.REACT_APP_API_URL}/exportUsersToXlsx?orgId=${this.rootStore.appStore.currentOrgId}`
  }

  @action
  public downloadCSV() {
    window.location.href = `${env.var.REACT_APP_API_URL}/exportUsersToCsv?orgId=${this.rootStore.appStore.currentOrgId}`
  }

  @action
  public async acceptChanges() {
    this.rows = []
    this.capturedRows = {}
    this.importProcessing = true
    this.importCompleted = false
    this.acceptingChanges = true
    const s = this.rootStore.localizationStore.lzStrings.orgUsersTableWidget
    const batchId = moment().format('MMMMDYYYYhmmssa')
    const fileName = batchId + '.csv'
    const parseFile = await new Parse.File(fileName, this.file, 'text/csv')
    parseFile.setTags({ organizationId: this.rootStore.appStore.currentOrgId })
    await parseFile.save()
    await this.listenToChanges(batchId)
    const result = await this.svc.importUsersFromCsvV2(
      this.rootStore.appStore.currentOrgId,
      parseFile,
      true,
      batchId
    )
    result.rows = []
    this.setResult(result)
  }

  @computed
  public get renderCloseConfirm(): string {
    return 'Are you sure you want to close?'
  }
  @computed
  public get renderCloseConfirmSecondary(): string {
    if (this.importProcessing && !this.acceptingChanges) {
      return 'Closing this dialog will lose the ability to accept validation changes'
    }
    if (this.importProcessing && this.acceptingChanges) {
      return 'You will no longer be able to see the progress of this User Import process. It will continue in the background.'
    }
  }

  @action
  public toggleConfirmResetModal() {
    this.confirmResetDialogOpen = !this.confirmResetDialogOpen
  }

  @action
  public resetXlsx() {
    this.selectedSheet = ''
    this.sheetSelectedDialogOpen = false
    this.xlsxFile = null
    this.xlsxSheets = null
  }

  public resetImport() {
    this.file = null
    this.importProcessing = false
    this.result = null
    this.showUsersImport = false
    this.importCompleted = false
    this.acceptingChanges = false
    this.acceptedChangesComplete = false
    if (this.rows) this.rows = []
    this.confirmResetDialogOpen = false
    this.resetXlsx()
    if (this.subscription) this.subscription.unsubscribe()
  }

  @computed
  public get cancelButtonText(): string {
    return 'Reset'
  }

  @computed
  public get dialogTitle(): string {
    if (this.userRows.length > 0) {
      return `Users Processed: ${this.userRows.length} Errors: ${this.errorCount}`
    } else {
      return 'File Upload'
    }
  }

  @computed
  public get processedRowsCount(): number {
    if (!this.result || !this.rows) return 0
    return this.rows.filter((e) => e.result).length
  }

  @computed
  public get allowAcceptChanges(): boolean {
    if (this.acceptedChangesComplete) return false
    if (this.hasErrors) return false
    if (this.importProcessing) return false
    if (!this.file) return false
    return true
  }

  @action
  private setResult(result: IUsersImportResultDTO) {
    this.result = result
    if (!this.result) return
    if (!this.result.success) this.importProcessing = false
    if (this.result.success && this.processedRowsCount === this.result.numberOfRows)
      this.importProcessing = false
    if (!this.result.success) this.file = null
  }

  @action
  public async validate() {
    this.importProcessing = true
    this.setResult(null)
    const s = this.rootStore.localizationStore.lzStrings.orgUsersTableWidget
    const batchId = moment().format('MMMMDYYYYhmmssa')
    const fileName = batchId + '.csv'
    const parseFile = await new Parse.File(fileName, this.file, 'text/csv')
    parseFile.setTags({ organizationId: this.rootStore.appStore.currentOrgId })
    await parseFile.save()
    this.rows = []
    this.capturedRows = {}
    await this.listenToChanges(batchId)
    const result = await this.svc.importUsersFromCsvV2(
      this.rootStore.appStore.currentOrgId,
      parseFile,
      false,
      batchId
    )
    result.rows = []
    this.setResult(result)
  }

  @computed
  public get showDropZone(): boolean {
    if (this.userRows.length === 0 && !this.importProcessing && this.errorRows.length > 0) {
      return true
    }
    if (this.userRows.length) return false
    if (this.importProcessing) return false
    return true
  }

  @computed
  public get showValidatingSpinner(): boolean {
    if (this.allowAcceptChanges) return false
    if (this.userRows.length > 0) return false
    if (this.importProcessing) return true
    return false
  }

  @computed
  public get showImportingSpinner(): boolean {
    if (this.allowAcceptChanges) return false
    if (this.importCompleted) return false
    if (this.importProcessing) return true
    return false
  }

  private async listenToChanges(batchId: string) {
    this.capturedRows = {}
    if (this.subscription) this.subscription.unsubscribe()
    this.query = new Parse.Query('userImportUpdates')
    this.query.equalTo('batchId', batchId)
    await this.query.find()
    this.subscription = await this.query.subscribe()
    this.subscription.on('create', (e: Parse.Object) => {
      const obj = e.toJSON() as any
      if (obj.updateType === 'row') this.processRowResult(obj.rowData)
    })
  }

  @action
  private processRowResult(row: IUserImportRowDTO) {
    // keep track of which records you have already because there
    // may be more than one of the same live query event
    if (!this.capturedRows[row.rowIndex]) {
      this.capturedRows[row.rowIndex] = true
      this.rows.push(row)
    }

    if (
      this.result &&
      this.result.success &&
      this.processedRowsCount === this.result.numberOfRows
    ) {
      this.importProcessing = false
      if (this.acceptingChanges) this.acceptedChangesComplete = true
    }
  }

  @action
  public openUserImport() {
    this.rootStore.appStore.router.push('dashboard/tenantAdmin/userImport')
  }
}
