import { computed } from 'mobx'
import { AGGridRowVM } from '../../shared/ag-grid/AGGridRowVM'
import { RootStore } from 'src/app/stores/RootStore'
import SurveyTag from '../aggregate/SurveyTag'
import { SurveyTagsWidgetVM } from './SurveyTagsWidgetVM'

export class SurveyTagsRowVM extends AGGridRowVM<SurveyTag> {
  constructor(rootStore: RootStore, surveyTag: SurveyTag, listVM: SurveyTagsWidgetVM) {
    super(rootStore, surveyTag, listVM)
    this.rootStore = rootStore
  }

  @computed
  public get objectId(): string {
    return this.aggregate.objectId
  }

  @computed
  public get name(): string {
    return this.aggregate.name
  }

  @computed
  public get description(): string {
    return this.aggregate.description
  }

  editSurveyTag() {
    this.rootStore.surveyTagsStore.setSelectedSurveyTagId(this.objectId)
  }
}
