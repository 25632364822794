import { computed } from 'mobx'
import { RootStore } from 'src/app/stores/RootStore'

export class ErrorRowVM {
  private rootStore: RootStore
  private error: string
  private index: number
  public isError: boolean

  constructor(rootStore: RootStore, error: string, index: number, isError = true) {
    this.error = error
    this.index = index
    this.rootStore = rootStore
    this.isError = isError
  }

  public type: string = 'error'

  @computed
  public get isWarning(): boolean {
    return !this.isError
  }

  @computed
  public get message(): string {
    return this.error
  }

  @computed
  public get height() {
    return 90
  }  
}
