import { inject, observer } from 'mobx-react'
import React from 'react'
import { GoalsStore } from 'src/app/goals/store/GoalsStore'
import {
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import PreviewIcon from '@material-ui/icons/Visibility'

interface Props {
  goalsStore?: GoalsStore
}

const AddPlans: React.FC<Props> = ({ goalsStore }) => {
  const { goalEditVM: vm } = goalsStore

  return (
    <>
      <Grid container id='Header'>
        <Grid item xs={12} className='title'>
          <DialogTitle>Associate a Goal to a Training Plan</DialogTitle>
          <CloseIcon
            className='close-modal-button'
            onClick={() => vm.cancelAssociateTrainingPlans()}
          />
        </Grid>
        <Grid item xs={12} className='body-tool-bar'>
          <Typography variant='h5'>Choose a Training Plan</Typography>
          <div>
            <Button variant='text' onClick={() => vm.cancelAssociateTrainingPlans()}>
              Cancel
            </Button>
            <Button variant='outlined' onClick={() => vm.saveAssociatedTrainingPlans()}>
              Save
            </Button>
          </div>
        </Grid>
      </Grid>
      <DialogContent>
        <Grid container className='body'>
          <Grid item xs={6} className='left-body'>
            <Typography variant='h6'>Every goal needs a plan, choose yours:</Typography>
            <Select
              multiple={true}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'center',
                  horizontal: 'center',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
                getContentAnchorEl: null,
              }}
              variant='outlined'
              value={[]}
              fullWidth
              placeholder='(Training Plan Name)'
            >
              {vm.availableTrainingPlans.map((plan, index) => (
                <MenuItem
                  value={plan.objectId}
                  key={index}
                  onClick={() => vm.addToStagedPlanIds(plan.objectId)}
                >
                  {plan.title}
                </MenuItem>
              ))}
              <MenuItem value={''} key={'create-new'} onClick={() => vm.createNewTrainingPlan()}>
                Create New Training Plan...
              </MenuItem>
            </Select>
          </Grid>
          <Grid item xs={6} className='right-body'>
            <Typography variant='h6'>Selected Plans:</Typography>
            {vm.selectedTrainingPlans.map((plan, index) => {
              return (
                <Paper key={index} elevation={2} className='plan-pill'>
                  <Typography>{plan.title}</Typography>
                  <div className='icons'>
                    <PreviewIcon onClick={() => vm.setPlanToPreview(plan)} />
                    <CloseIcon onClick={() => vm.addToIdsToRemove(plan.objectId)} />
                  </div>
                </Paper>
              )
            })}
          </Grid>
        </Grid>
      </DialogContent>
    </>
  )
}

export default inject('goalsStore')(observer(AddPlans))
