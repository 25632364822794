import React from 'react'
import { CardContent } from '@material-ui/core'
import NotificationsTable from './NotificationsTable'
import { NotificationsWidgetVM } from '../store/view-models/NotificationsWidgetVM'

interface Props {
  vm: NotificationsWidgetVM
}

const WidgetContent: React.FC<Props> = ({ vm }) => {
  return (
    <CardContent className='no-padding'>
      <NotificationsTable vm={vm} />
    </CardContent>
  )
}

export default WidgetContent
