import { observable } from 'mobx'
import { serializable, serialize, deserialize, list, object } from 'serializr'
import uuid from 'uuid/v4'
import { IExerciseDTO } from '../dto/IExerciseDTO'
import { ExerciseInfo } from './ExerciseInfo'
import { ExerciseSet } from './ExerciseSet'

export class Exercise implements IExerciseDTO {
  public static create(): Exercise {
    const content = new Exercise()
    content.id = uuid()
    content.goal = ExerciseInfo.create()
    return content
  }

  @serializable @observable public id: string = ''
  @serializable(object(ExerciseInfo)) @observable public goal: ExerciseInfo = null
  @serializable(list(object(ExerciseSet))) @observable public sets: ExerciseSet[] = []

  public serialize(): IExerciseDTO {
    const obj = serialize(this)
    return obj
  }

  public clone(): Exercise {
    return deserialize(Exercise, this.serialize())
  }
}
