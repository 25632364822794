import React from 'react'
import { observer, inject } from 'mobx-react'
import './SettingsPane.scss'
import { WorksheetsStore } from '../../store/WorksheetsStore'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Autocomplete from '@material-ui/lab/Autocomplete'
import EditIcon from '@material-ui/icons/Edit'
import { InputLabel } from '@material-ui/core'

interface Props {
  worksheetsStore?: WorksheetsStore
}

const TableauSourcePane: React.FC<Props> = ({ worksheetsStore }) => {
  const { editVM } = worksheetsStore
  if (!editVM) return null

  const renderNewButton = () => {
    if (editVM.newtableauWorkbookMode) return
    if (!editVM.canEditTableau) return
    return (
      <Button
        variant='text'
        disabled={editVM.isSaving || editVM.editMode}
        onClick={() => editVM.enterNewTableauWorkbookMode()}
      >
        New
      </Button>
    )
  }

  const renderGoButton = () => {
    if (!editVM.newtableauWorkbookMode) return
    return (
      <Button
        variant='text'
        disabled={editVM.isSaving}
        onClick={() => editVM.exitNewTableauWorkbook()}
      >
        Use Existing Workbook
      </Button>
    )
  }

  const renderCsvButton = () => {
    if (editVM.newtableauWorkbookMode) return
    return (
      <Button
        variant='text'
        disabled={editVM.isSaving || !editVM.tableauVizLoaded}
        onClick={() => editVM.exportToCsv()}
      >
        CSV
      </Button>
    )
  }

  const renderPdfButton = () => {
    if (editVM.newtableauWorkbookMode) return
    return (
      <Button
        variant='text'
        disabled={editVM.isSaving || !editVM.tableauVizLoaded}
        onClick={() => editVM.exportToPdf()}
      >
        PDF
      </Button>
    )
  }

  const renderImageButton = () => {
    if (editVM.newtableauWorkbookMode) return
    return (
      <Button
        variant='text'
        disabled={editVM.isSaving || !editVM.tableauVizLoaded}
        onClick={() => editVM.exportToImage()}
      >
        Image
      </Button>
    )
  }

  const renderEditWorkbookButton = () => {
    if (editVM.editMode) return null
    if (!editVM.canEditTableau) return
    if (editVM.newtableauWorkbookMode) return
    return (
      <Button
        variant='text'
        color='primary'
        disabled={editVM.isSaving || !editVM.tableauVizLoaded}
        startIcon={<EditIcon />}
        onClick={() => editVM.enterTableauEditMode()}
      >
        Edit Workbook
      </Button>
    )
  }

  const renderSelectWorkbook = () => {
    if (editVM.newtableauWorkbookMode) return
    return (
      <>
        <InputLabel>Workbook</InputLabel>
        &nbsp;&nbsp;
        <Select
          value={editVM.tableauWorkbookName}
          onChange={(e) => editVM.setTableauWorkbook(e.target.value)}
          variant='outlined'
          disabled={editVM.editMode}
        >
          {editVM.availableTableauWorkbooks.map((e, idx) => {
            return (
              <MenuItem value={e.contentUrl} key={'wb' + idx}>
                {e.name}
              </MenuItem>
            )
          })}
        </Select>
      </>
    )
  }

  const renderWorksheetSelect = () => {
    if (!editVM.selectedTableauWorkbook) return null
    if (editVM.newtableauWorkbookMode) return
    return (
      <Select
        value={editVM.tableauWorksheet}
        onChange={(e) => editVM.setTableauWorksheet(e.target.value)}
        variant='outlined'
        disabled={editVM.editMode}
      >
        {editVM.selectedTableauWorkbookViews.map((e, idx) => (
          <MenuItem value={e.contentUrl} key={'ws' + idx}>
            {e.name}
          </MenuItem>
        ))}
      </Select>
    )
  }

  const renderSelectDataSource = () => {
    if (!editVM.newtableauWorkbookMode) return
    return (
      <>
        <InputLabel>From Datasource</InputLabel>
        &nbsp;&nbsp;
        <Select
          value={editVM.tableauDataSource}
          onChange={(e) => editVM.setTableauDataSource(e.target.value.toString())}
          variant='outlined'
          disabled={editVM.editMode}
          placeholder='Select Datasource'
          className={editVM.isVizValid ? '' : 'error'}
        >
          {editVM.availableDataSources.map((e, idx) => (
            <MenuItem value={e.contentUrl} key={'wb' + idx}>
              {e.name}
            </MenuItem>
          ))}
        </Select>
      </>
    )
  }

  return (
    <div id='SettingsPane'>
      <div id='Content'>
        <form autoComplete='off'>
          {renderSelectWorkbook()}
          {renderWorksheetSelect()}
          {renderSelectDataSource()}
          {renderNewButton()}
          {renderGoButton()}
          <div className='right-buttons'>
            {renderCsvButton()}
            {renderImageButton()}
            {renderPdfButton()}
            {renderEditWorkbookButton()}
          </div>
        </form>
      </div>
    </div>
  )
}

export default inject('worksheetsStore')(observer(TableauSourcePane))
