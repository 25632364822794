import { RootStore } from '../../stores/RootStore'
import { observable, action, computed } from 'mobx'
import { Goal } from '../aggregate/Goal'
import { GoalEditVM } from '../view-models/GoalEditVM'
import { ManageGoalsWidgetVM } from '../view-models/ManageGoalsWidgetVM'
import moment from 'moment'
import { IGoalDTO } from '../dto/IGoalDTO'
import { DataStore } from '../../shared/data/DataStore'

export class GoalsStore extends DataStore<Goal, IGoalDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, Goal, 'goals', [
      'name',
      'categoryId',
      'organizationId',
      'startDate',
      'endDate',
      'description',
      'potentialObstacles',
      'dataSourceId',
      'facetId',
      'targetInputValue',
      'goalDirection',
      'aggregationMethod',
      'consistencyThreshold',
      'aggregationPeriod',
      'createdByUserId',
      'worksheetId',
      'targetMode',
      'target',
      'current',
      'measureGoal',
      'participatingUserIds',
      'participants',
      'targetPercentage',
      'askAboutProgress',
      'sendReminders',
      'progressInterval',
      'reminderInterval',
      'thumbnail',
      'thumbnailFileName',
      'associateToTrainingPlans',
      'associatedTrainingPlanIds',
      'isCatalogGoal',
      'activeCatalogPlans',
    ])
  }

  @observable public goalEditVM: GoalEditVM = null
  @observable public manageGoalsWidgetVM: ManageGoalsWidgetVM = null
  @observable public loaded: boolean = false

  @computed
  public get currentOrgGoals(): Goal[] {
    return this.records
  }

  @action
  public onListRecordsLoaded() {
    this.manageGoalsWidgetVM = new ManageGoalsWidgetVM(this.rootStore)
    this.loaded = true
  }

  @computed
  public get canCreateGoals(): boolean {
    return this.rootStore.appStore.canCreateGoals
  }

  public getGoal(objectId): Goal {
    return this.currentOrgGoals.find((goal) => goal.objectId === objectId)
  }

  public deleteGoal(objectId) {
    this.currentOrgGoals.splice(this.getGoalIndex(objectId), 1)
  }

  private getGoalIndex(objectId): number {
    return this.currentOrgGoals.findIndex((e) => e.objectId === objectId)
  }

  @action
  public createNewGoal(attempts: number = 0) {
    this.goalEditVM = new GoalEditVM(
      this.rootStore,
      Goal.create(this.rootStore.appStore.currentUserId, this.rootStore.appStore.currentOrgId)
    )
  }

  @action
  public editGoal(id: string, attempts: number = 0) {
    if (attempts > 10) console.log('Error: No goal found')
    const goal = this.getGoal(id)
    if (!goal || goal === undefined) {
      setTimeout(() => this.editGoal(id, attempts++), 500)
    } else {
      this.goalEditVM = new GoalEditVM(this.rootStore, goal.clone())
    }
  }

  @action
  public getCatalogGoal(id: string) {
    return this.rootStore.goalsCatalogStore.getCatalogGoal(id)
  }

  @action
  public createGoalFromCatalog(id: string, attempts: number = 0) {
    if (attempts === 10) return console.log('No Training Plan Found')
    const foundGoal = this.getCatalogGoal(id)
    if (!foundGoal) {
      setTimeout(() => this.createGoalFromCatalog(id, attempts++), 100)
      return
    }
    const clonedGoal = foundGoal.clone()
    clonedGoal.objectId = ''
    clonedGoal.isCatalogGoal = false
    clonedGoal.createdByUserId = this.rootStore.appStore.currentUserId
    clonedGoal.startDate = moment().toDate()
    clonedGoal.endDate = moment().add(1, 'week').toDate()
    this.goalEditVM = new GoalEditVM(this.rootStore, clonedGoal)
  }
}
