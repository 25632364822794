import { observable, action } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { IAggregate } from '../../../shared/data/IAggregate'
import { ActiveUserSurveysTabVM } from './ActiveUserSurveysTabVM'
import { CompletedSurveyResponsesTabVM } from './CompletedSurveyResponsesTabVM'


export class MySurveysWidgetVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.activeUserSurveysTab = new ActiveUserSurveysTabVM(this.rootStore, this)
    this.completedSurveyResponsesTab = new CompletedSurveyResponsesTabVM(this.rootStore, this)
  }

  @observable public tabIndex: number = 0
  @observable public activeUserSurveysTab: ActiveUserSurveysTabVM
  @observable public completedSurveyResponsesTab: CompletedSurveyResponsesTabVM

  @action
  public getRowNodeId(row: IAggregate): string {
    return row.objectId
  }

  @action
  protected isExternalFilterPresent(): boolean {
    return true
  }

  @action
  public setTabIndex(val: number) {
    this.tabIndex = val
  }
}
