import React, { FC } from 'react'
import { observer, inject } from 'mobx-react'
import { MediaItemsStore } from '../../../../media-items/store/MediaItemsStore'
import { QuestionsStore } from '../../../../questions/store/QuestionsStore'
import { SurveysStore } from '../../../store/SurveysStore'
import Paper from '@material-ui/core/Paper'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Slide from '@material-ui/core/Slide'
import CheckIcon from '@material-ui/icons/Check'
import QuestionMenu from './QuestionMenu'
import QuestionForm from './QuestionForm'
import './SidePanel.scss'
import MediaUploadPicker from '../../../../media-items/views/media-upload-picker/MediaUploadPicker'
import { IconButton, Tooltip, Button } from '@material-ui/core'
import PublishIcon from '@material-ui/icons/Publish'

interface Props {
  surveysStore?: SurveysStore
  questionsStore?: QuestionsStore
  mediaItemsStore?: MediaItemsStore
}

const SidePanel: FC<Props> = ({ surveysStore, questionsStore }) => {
  const vm = surveysStore.viewModels.editVM
  if (!vm || !vm.currentQuestion) return null

  const renderMediaPanel = () => {
    if (!vm.mediaPanelShown) return null
    return <MediaUploadPicker mediaUploadPickerVM={vm.currentQuestion.mediaUploadPickerVM} />
  }

  const renderQuestionForm = () => {
    if (vm.mediaPanelShown) return null
    return <QuestionForm />
  }

  const renderQuestionMenu = () => {
    if (vm.mediaPanelShown) return null
    return <QuestionMenu />
  }

  const renderDrawerTitle = () => {
    if (vm.currentQuestion.type === 'infoText')
      return `Info Text ${vm.currentQuestion.infoTextIndex + 1} Editor`
    return `Q${vm.currentQuestionIndex + 1} Editor`
  }

  const renderMediaSelectButton = () => {
    if (!vm) return null
    if (!vm.mediaPanelShown) return null
    if (!vm.currentQuestion) return null
    const mediaPickerVM = vm.currentQuestion.mediaUploadPickerVM
    return (
      <Tooltip title={`Add selected media`} placement='bottom-start' enterDelay={500}>
        <span>
          <Button
            variant='contained'
            disabled={!mediaPickerVM.selectedMediaItemId}
            onClick={() => mediaPickerVM.addMedia()}
            startIcon={<CheckIcon />}
          >
            Add Media
          </Button>
        </span>
      </Tooltip>
    )
  }

  const renderMediaUploadButton = () => {
    if (!vm) return null
    if (!vm.mediaPanelShown) return null
    if (!vm.currentQuestion) return null
    const mediaPickerVM = vm.currentQuestion.mediaUploadPickerVM
    return (
      <Tooltip title={`Upload new media`} placement='bottom-start' enterDelay={500}>
        <IconButton onClick={() => mediaPickerVM.openDrawer()} className='media-upload-button'>
          <PublishIcon />
        </IconButton>
      </Tooltip>
    )
  }

  return (
    <Slide in={vm.shouldShowSidePanel} direction={'left'}>
      <Paper id='SidePanel' square elevation={6}>
        <AppBar color='default' position='sticky' elevation={0}>
          <Toolbar>
            <Typography variant='h5' style={{ flexGrow: 1 }}>
              {renderDrawerTitle()}
            </Typography>
            {renderQuestionMenu()}
            {renderMediaUploadButton()}
            {renderMediaSelectButton()}
          </Toolbar>
        </AppBar>
        <div className='content-container'>
          {renderQuestionForm()}
          {renderMediaPanel()}
        </div>
      </Paper>
    </Slide>
  )
}

export default inject('surveysStore', 'questionsStore', 'mediaItemsStore')(observer(SidePanel))
