import { RootStore } from '../../stores/RootStore'
import { observable, computed, action } from 'mobx'
import { UserSurvey } from '../../user-surveys/aggregate/UserSurvey'
import { RowType } from '../types/RowType'
import moment from 'moment'
import { INotificationRow } from '../interfaces/INotificationRow'

export class UserSurveyRowVM implements INotificationRow {
  private rootStore: RootStore

  constructor(rootStore: RootStore, survey: UserSurvey, labelsStore?) {
    this.rootStore = rootStore
    this.userSurvey = survey
    this.buttonText = `VIEW ${this.rootStore.labelsStore.getLabelByLanguageAndFor('pulse')}`
  }

  @observable public userSurvey: UserSurvey = null
  @observable public type: RowType = 'userSurvey'
  @observable public buttonText: string = null

  @computed
  public get id(): string {
    return this.userSurvey.objectId
  }

  @computed
  public get title(): string {
    return this.userSurvey.name
  }

  @computed
  public get createdSeconds(): number {
    const now = new Date()
    const createdAt = new Date(this.userSurvey.createdAtAsDate)
    return (now.getTime() - createdAt.getTime()) / 1000
  }

  @computed
  public get createdAt(): string {
    return moment(this.userSurvey.createdAtAsDate).fromNow()
  }

  @computed
  public get dueDate() {
    const s = this.rootStore.localizationStore.lzStrings.notifications_widget
    if (!this.userSurvey.dueDate) return null
    return moment(this.userSurvey.dueDate).format(s.survey_due_date_format)
  }

  @computed
  public get hasDuedate(): boolean {
    if (!this.dueDate) return false
    return true
  }

  @action
  public onAction() {
    this.rootStore.appStore.router.push('/usersurveys/take/' + this.userSurvey.objectId)
  }
}
