import { Dialog } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { TrainingPlansStore } from '../../../../training-plans/store/TrainingPlansStore'
import AddGoals from './AddGoals'
import './GoalsModal.scss'

interface Props {
  trainingPlansStore?: TrainingPlansStore
}

const GoalsModal: React.FC<Props> = ({ trainingPlansStore }) => {
  const { trainingPlanEditVM: vm } = trainingPlansStore
  if (!vm) return null

  const renderAddGoals = () => {
    return <AddGoals />
  }

  return (
    <Dialog onClose={() => vm.closeGoalsModal()} open={vm.goalsModalOpen} id='GoalsModal' fullWidth>
      {renderAddGoals()}
    </Dialog>
  )
}

export default inject('trainingPlansStore')(observer(GoalsModal))
