import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { TasksStore } from '../../store/TasksStore'
import { Grid } from '@material-ui/core'
import DrawerTaskDetailsName from './DrawerTaskDetailsName'
import DrawerTaskDetailsDiscipline from './DrawerTaskDetailsDiscipline'
import DrawerTaskDetailsDate from './DrawerTaskDetailsDate'
import DrawerTaskDetailsPartnerChannel from './DrawerTaskDetailsPartnerChannel'
import DrawerTaskDetailsDescription from './DrawerTaskDetailsDescription'
import DrawerTaskDetailsTaskType from './DrawerTaskDetailsTaskType'

interface DrawerTaskDetailsProps {
  localizationStore?: LocalizationStore
}

const DrawerTaskDetails: React.FC<DrawerTaskDetailsProps> = ({ localizationStore }) => {
  const { lzStrings } = localizationStore

  return (
    <section>
      <h6>{lzStrings.tasksManagementWidgetDrawer.task_details}</h6>
      <form autoComplete='off' noValidate>
        <Grid container spacing={2}>
          <DrawerTaskDetailsName />
          <DrawerTaskDetailsTaskType />
          <DrawerTaskDetailsDate />
          <DrawerTaskDetailsDiscipline />
          <DrawerTaskDetailsPartnerChannel />
          <DrawerTaskDetailsDescription />
        </Grid>
      </form>
    </section>
  )
}

export default inject('localizationStore')(observer(DrawerTaskDetails))
