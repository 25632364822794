import React from 'react'
import { inject, observer } from 'mobx-react'
import { EmailTemplatesStore } from '../../store/EmailTemplatesStore'
import { AgGridReact } from 'ag-grid-react/lib/agGridReact'
import AGGridWidgetPagingRow from '../../../shared/ag-grid/AGGridPagingRow'
import { CircularProgress, IconButton, Popover, Tooltip } from '@material-ui/core'
import DeleteRounded from '@material-ui/icons/DeleteRounded'
import EditRoundedIcon from '@material-ui/icons/EditRounded'
import ReplayRoundedIcon from '@material-ui/icons/ReplayRounded'
import MuiConfirmPrompt from '../../../shared/MuiConfirmPrompt'
import LocalizationStore from '../../../localization/LocalizationStore'
import { EmailTemplateRowVM } from '../../view-models/EmailTemplateRowVM'
import { EmailTemplatesTableVM } from '../../view-models/EmailTemplatesTableVM'
import './EmailTemplatesTable.scss'

interface Props {
  emailTemplatesStore?: EmailTemplatesStore
  localizationStore: LocalizationStore
  vm: EmailTemplatesTableVM
}

const EmailTemplatesTable: React.FC<Props> = ({ emailTemplatesStore, localizationStore, vm }) => {
  const lz = localizationStore.lzStrings.emailTemplateManagement
  if (!vm) return

  const renderSpinner = () => {
    if (vm && vm.shouldRender) return
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    row: EmailTemplateRowVM
  ) => {
    event.stopPropagation()
    event.preventDefault()
    row.setAnchorEl(event.currentTarget)
    row.popoverOpen = true
  }

  const handlePopoverClose = (row: EmailTemplateRowVM) => {
    row.setAnchorEl(null)
    row.popoverOpen = false
  }

  const renderPreviewCell = observer((props) => {
    const row: EmailTemplateRowVM = props.data
    return (
      <div
        className='email-template-preview-cell'
        onMouseEnter={(evt) => handlePopoverOpen(evt, row)}
        onMouseLeave={(evt) => handlePopoverClose(row)}
        aria-owns='email-template-preview-popover'
        aria-haspopup='true'
      >
        <img
          className='email-template-preview-img'
          onClick={(evt) => row.openEmailTemplate()}
          src={row.thumbnailUrl}
          alt='preview'
          title='preview'
        />
        <Popover
          style={{ pointerEvents: 'none' }}
          id={'email-template-preview-popover'}
          className={'email-template-preview-popover'}
          open={row.popoverOpen}
          anchorEl={row.anchorEl}
          onMouseLeave={(evt) => handlePopoverClose(row)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={(evt) => handlePopoverClose(row)}
          disableAutoFocus
          disableEnforceFocus
          disableRestoreFocus
        >
          <img
            className='email-template-preview-popover-img'
            onClick={(e) => row.openEmailTemplate()}
            src={row.thumbnailUrl}
            alt='preview'
            title='preview'
          />
        </Popover>
      </div>
    )
  })

  const renderEditButton = (row: EmailTemplateRowVM) => {
    return (
      <div className='email-template-action-btn'>
        <Tooltip arrow title={`Edit Email Template`} placement='bottom' enterDelay={500}>
          <span>
            <IconButton
              className='email-template-action-btn-edit'
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                vm.selectedRow = row
                row.openEmailTemplate()
              }}
            >
              <EditRoundedIcon className='email-template-action-btn-edit-icon' />
            </IconButton>
          </span>
        </Tooltip>
      </div>
    )
  }
  const renderRevertButton = (row: EmailTemplateRowVM) => {
    if (vm.isSystemTable) return
    return (
      <div className='email-template-action-btn'>
        <Tooltip arrow title={`Revert to System Default`} placement='bottom' enterDelay={500}>
          <span>
            <IconButton
              className='email-template-action-btn-revert'
              disabled={row.isDefault}
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                vm.selectedRow = row
                vm.confirmRevertDialogOpen = true
              }}
            >
              <ReplayRoundedIcon className='email-template-action-btn-revert-icon' />
            </IconButton>
          </span>
        </Tooltip>
      </div>
    )
  }

  const renderDeleteButton = (row: EmailTemplateRowVM) => {
    if (!vm.canManage) return
    return (
      <div className='email-template-action-btn'>
        <Tooltip arrow title={`Delete Email Template`} placement='bottom' enterDelay={500}>
          <span>
            <IconButton
              disabled={!vm.canManage || !vm.canDeleteType(row.notificationTypeId)}
              className='email-template-action-btn-delete'
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                vm.selectedRow = row
                vm.confirmDeleteDialogOpen = true
              }}
            >
              <DeleteRounded className='email-template-action-btn-delete-icon' />
            </IconButton>
          </span>
        </Tooltip>
      </div>
    )
  }

  const renderActionsCell = (props) => {
    const row: EmailTemplateRowVM = props.data
    if (!row) return <div></div>
    return (
      <div className='email-template-actions-cell'>
        {renderEditButton(row)}
        {renderRevertButton(row)}
      </div>
    )
  }

  const renderGrid = () => {
    if (!vm.shouldRender) return
    return (
      <div className='ag-theme-alpine' style={{ height: '100%', width: '100%' }}>
        <AgGridReact
          gridOptions={{
            ...vm.getGridOptions(),
            frameworkComponents: {
              actionsCell: renderActionsCell,
              previewCell: renderPreviewCell,
            },
          }}
        />
      </div>
    )
  }

  const renderGridPaging = () => {
    if (!vm.shouldRender) return
    return <AGGridWidgetPagingRow vm={vm} />
  }

  const renderConfirmRevertDialog = () => {
    return (
      <MuiConfirmPrompt
        confirmMessage={lz.are_you_sure_revert}
        onClose={() => vm.cancelRevertConfirmDialog()}
        open={vm.confirmRevertDialogOpen}
        onConfirm={() => {
          vm.revertEmailTemplate()
        }}
        title={`${lz.revert} ${lz.email_template}`}
      />
    )
  }

  const renderConfirmDeleteDialog = () => {
    return (
      <MuiConfirmPrompt
        confirmMessage={lz.are_you_sure_delete}
        onClose={() => vm.cancelDeleteConfirmDialog()}
        open={vm.confirmDeleteDialogOpen}
        onConfirm={() => {
          vm.deleteEmailTemplate()
        }}
        title={`${lz.revert} ${lz.email_template}`}
      />
    )
  }

  return (
    <>
      {renderSpinner()}
      {renderGrid()}
      {renderGridPaging()}
      {renderConfirmRevertDialog()}
      {renderConfirmDeleteDialog()}
    </>
  )
}

export default inject('emailTemplatesStore')(observer(EmailTemplatesTable))
