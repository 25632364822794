import React from 'react'
import { inject, observer } from 'mobx-react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import QuestionCard from './QuestionCard'
import './QuestionsList.scss'
import { QuizVM } from '../view-models/QuizVM'

interface QuestionsListProps {
  questions?: any
  quiz: QuizVM
}

const SortableItem: any = SortableElement(({ value }) => (
  <div style={{ zIndex: 99999999 }} className='question-draggable-item'>
    {value}
  </div>
))

const SortableList: any = SortableContainer(({ items }) => {
  return (
    <div>
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} value={value} />
      ))}
    </div>
  )
})

const QuestionsList = ({ questions, quiz }: QuestionsListProps) => {
  if (!questions) return null

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (quiz.isDisabled) return
    quiz.setOldIndex(oldIndex)
    quiz.setNewIndex(newIndex)
    quiz.moveQuestion()
  }

  const questionsList = questions.map((value, index) => (
    <QuestionCard key={`item-${index}`} index={index} question={value} quiz={quiz} />
  ))

  return (
    <div id='QuestionsList'>
      <SortableList
        disabled={quiz.isDisabled}
        distance={3}
        items={questionsList}
        onSortEnd={onSortEnd}
      />
    </div>
  )
}

export default QuestionsList
