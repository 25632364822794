import { observable, action } from 'mobx'
import { serializable, date, list, primitive, object } from 'serializr'
import { IScheduledEventDTO } from '../dtos/IScheduledEventDTO'
import { ScheduledEventStatusType } from '../types/ScheduledEventStatusType'

export class ScheduledEvent implements IScheduledEventDTO {
  @serializable @observable public effectiveDateTime: string
  @serializable @observable public status: ScheduledEventStatusType
  @serializable @observable public eventId: string
  @serializable @observable public occurrenceId: string
}
