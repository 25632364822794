import React from 'react'
import { inject, observer } from 'mobx-react'
import { NotificationsStore } from '../../notifications/store/NotificationsStore'
import { Card, CardContent, CircularProgress, Drawer } from '@material-ui/core'
import DrawerHeader from './DrawerHeader'
import DrawerButtonControls from './DrawerButtonControls'
import DrawerNotificationInfo from './DrawerNotificationInfo'
import DrawerTemplateEditor from './DrawerTemplateEditor'
import DrawerDefaultButton from './DrawerDefaultButton'
import { NotificationsWidgetVM } from '../store/view-models/NotificationsWidgetVM'
import './Drawer.scss'

interface NotificationsWidgetDrawerProps {
  notificationsStore?: NotificationsStore
  vm: NotificationsWidgetVM
}

const NotificationsWidgetDrawer: React.FC<NotificationsWidgetDrawerProps> = ({
  notificationsStore,
  vm,
}) => {
  const { editVM: widgetForm } = notificationsStore
  if (!widgetForm) return

  const renderSpinner = () => {
    if (!widgetForm.isSaving) return
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  const renderCardContent = () => {
    return (
      <CardContent>
        <DrawerNotificationInfo />
        <DrawerTemplateEditor />
        <DrawerDefaultButton />
      </CardContent>
    )
  }
  
  return (
    <Drawer
      anchor='right'
      open={widgetForm.isFormDrawerOpen}
      onClose={() => widgetForm.closeEditor()}
      id='NotificationsWidgetDrawer'
    >
      <Card elevation={0} id='NotificationsWidgetDrawerCard'>
        <DrawerHeader />
        <DrawerButtonControls vm={vm} />
        {renderSpinner()}
        {renderCardContent()}
      </Card>
    </Drawer>
  )
}

export default inject('notificationsStore')(observer(NotificationsWidgetDrawer))
