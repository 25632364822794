import { computed, observable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { UserEventPopupVM } from './UserEventPopupVM'
import { AudienceMember } from 'src/app/audience-members/aggregate/AudienceMember'
import { EventPopupVM } from './EventPopupVM'

export class PopupParticipantVM {
  private rootStore: RootStore
  private userId: string = ''

  constructor(rootStore: RootStore, userEventPopupVM: UserEventPopupVM | EventPopupVM, userId: string) {
    this.rootStore = rootStore
    this.userId = userId
    this.userEventPopupVM = userEventPopupVM
  }

  @observable private userEventPopupVM: UserEventPopupVM | EventPopupVM

  @computed
  private get user(): AudienceMember {
    return this.rootStore.audienceMembersStore.getUser(this.userId)
  }

  @computed
  public get name(): string {
    if (!this.user) return ''
    return this.user.name
  }

  @computed
  public get id(): string {
    if (!this.user) return ''
    return this.user.objectId
  }

  @computed
  public get iconURL(): string {
    if (!this.user) return ''
    return this.user.iconURL
  }

  @computed
  public get userEventReadStatus(): boolean {
    return this.userEventPopupVM.event.readUserIds.some((id) => id === this.userId)
  }

  @computed
  public get goingStatus(): string {
    if (this.isGoingYes) return 'yes'
    if (this.isGoingNo) return 'no'
    if (this.isGoingMaybe) return 'maybe'
    return 'new'
  }

  @computed
  private get isGoingYes(): boolean {
    return this.userEventPopupVM.goingYesUserIds.some((e) => e === this.userId)
  }

  @computed
  private get isGoingNo(): boolean {
    return this.userEventPopupVM.goingNoUserIds.some((e) => e === this.userId)
  }

  @computed
  private get isGoingMaybe(): boolean {
    return this.userEventPopupVM.goingMaybeUserIds.some((e) => e === this.userId)
  }

  @computed
  public get type(): string {
    if (!this.user) return ''
    return this.user.type
  }

  @computed
  public get isArchived(): boolean {
    if (!this.user) return false
    return this.user.isArchived
  }
}
