import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { Button, Checkbox, FormControlLabel, IconButton, InputAdornment, InputLabel, Menu, MenuItem, OutlinedInput, TextField } from '@material-ui/core'
import { TenantLinkEditVM } from '../../../view-models/TenantLinkEditVM'
import DeleteIcon from '@material-ui/icons/Delete'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import './TenantLinkEdit.scss'
import FileUploadModal from '../../../../upload/views/FileUploadModal'
import { FileTypeEnum } from '../../../../upload/enum/FileTypeEnum'
import { Attachment } from '../../../../upload/aggregate/Attachment'
import CMSItemsPickerDialog from '../../../../cms-items/views/dialogs/CMSItemsPickerDialog'
import { CMSItemsTypeEnum } from '../../../../cms-items/enum/CMSItemsTypeEnum'
import { OrganizationsStore } from '../../../../organizations/store/OrganizationsStore'

interface Props {
  vm: TenantLinkEditVM
  localizationStore?: LocalizationStore
  organizationsStore?: OrganizationsStore
}

const TenantLinkEdit: React.FC<Props> = ({ localizationStore, vm, organizationsStore }) => {
  const { lzStrings } = localizationStore
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  if (!vm) return null

  const handleClose = (value: string) => {
    setAnchorEl(null)
    if (value === 'delete') vm.removeTenantLink()
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const renderDeleteOption = () => {
    if (vm.isNew) return null
    return (
      <MenuItem
        onClick={() => handleClose('delete')}
      >
        <DeleteIcon className='icon' />
        {`Delete Tenant Link`}
      </MenuItem>
    )
  }

  const renderMenu = () => {
    if (vm.isNew) return
    return (
      <>
        <IconButton aria-controls='simple-menu' aria-haspopup='true' onClick={handleClick}>
          <MoreHorizIcon />
        </IconButton>
        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          style={{ marginTop: 40 }}
        >
          {renderDeleteOption()}
        </Menu>
      </>
    )
  }

  const renderPreview = () => {
    const media = vm.tenantLink.iconName
    if (!media) return null

    const renderThumbnail = () => {
      return <img src={vm.tenantLink.iconName.url} />
    }


    return (
      <div
        className={'thumbnail-container'}
      >
        {renderThumbnail()}
      </div>
    )
  }

  const renderImagePicker = () => {
    const button =
      vm.tenantLink && vm.tenantLink.iconName ? (
        <Button
          className='browse-button'
          variant='outlined'
          onClick={() => vm.tenantLink.clearIconName()}
        >
          Remove
        </Button>
      ) : (
        <Button
          className='browse-button'
          variant='outlined'
          onClick={() => vm.toggleShowUploadModal()}
        >
          Upload
        </Button>
      )

    return (
      <div className='media-select'>
        <TextField
          variant='outlined'
          type='text'
          placeholder='Add Media'
          margin='none'
          fullWidth
          value={vm.iconName}
          InputProps={{
            startAdornment: <InputAdornment position='start'>{renderPreview()}</InputAdornment>,
            endAdornment: <InputAdornment position='end'>{button}</InputAdornment>,
          }}
        />
      </div>
    )
  }

  const renderUploadModal = () => {
    if (organizationsStore.currentOrganization?.hasCMSPicker) return renderCMSUploadModal()
    return renderStandardUploadModal()
  }

  const renderCMSUploadModal = () => {
    return (
      <CMSItemsPickerDialog
        allowMultiple={false}
        fileTypes={CMSItemsTypeEnum.IMAGES}
        addAfterSave={(a: Attachment[]) => vm.addAttachment(a[0])}
        toggleShowModal={() => vm.toggleShowUploadModal()}
        isOpen={vm.showUploadModal}
      />
    )
  }

  const renderStandardUploadModal = () => {
    return (
      <FileUploadModal
        allowMultiple={false}
        fileTypes={FileTypeEnum.IMAGES}
        addAfterSave={(a: Attachment[]) => vm.addAttachment(a[0])}
        toggleShowModal={() => vm.toggleShowUploadModal()}
        isOpen={vm.showUploadModal}
      />
    )
  }

  const renderLinkErrorText = () => {
    if (vm.isUrlValid) return null
    return (
        <div className={'error-text'}>Please insert a valid url. *Remember to include http:// or https://</div>
    )
  }

  return (
    <div id={'TenantLinkEdit'}>
      {/* {renderUploadModal()} */}
      <section>
        <div className={'link-edit-header'}>
          <h6 className={'link-edit-title'}>{'Link Edit'}</h6>
          {renderMenu()}
        </div>
        <form>
          <InputLabel htmlFor='name'>{'Name'}</InputLabel>
          <OutlinedInput
            id='name'
            fullWidth
            labelWidth={0}
            autoComplete='off'
            value={vm.name}
            onChange={(e) => vm.setName(e.target.value)}
            error={!vm.isNameValid}
          />
          <InputLabel htmlFor='url'>{'Url'}</InputLabel>
          <OutlinedInput
            id='url'
            fullWidth
            labelWidth={0}
            autoComplete='off'
            value={vm.url}
            onChange={(e) => vm.setUrl(e.target.value)}
            error={!vm.isUrlValid}
          />
          {renderLinkErrorText()}
          {/* <InputLabel htmlFor='iconName'>{'Icon Image'}</InputLabel>
          {renderImagePicker()} */}
          <div>
            <FormControlLabel control={<Checkbox
              onChange={() =>
                vm.toggleIsEmbedded()
              }
              checked={vm.isEmbedded}
            />} label="Is Embedded" />
          </div>
        </form>
      </section>
      <div className={'btn-row'}>
        <Button onClick={() => vm.cancel()}>Cancel</Button>
        <Button onClick={() => vm.apply()} variant='contained'>Apply</Button>
      </div>

    </div>
  )
}

export default inject('localizationStore', 'organizationsStore')(observer(TenantLinkEdit))
