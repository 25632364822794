import React, { Component } from 'react'
import _ from 'lodash'
import Navigation from './Navigation'
import '../css/layout.scss'
import '../css/themes.scss'
import '../css/mui-theme.scss'
import '../css/mui-drawers.scss'
import '../css/mui-widgets.scss'
import '../css/mui-tables.scss'
import '../css/mui-widget-temp.scss'

export default class Layout extends Component<any, any> {
  render() {
    return <Navigation children={this.props.children} router={this.props.router} />
  }
}
