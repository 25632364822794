import { RootStore } from 'src/app/stores/RootStore'
import { Event } from '../aggregate/Event'
import { Resource } from '../aggregate/Resource'

export class EventsCopyService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public async copyEvent(eventId: string) {
    const eventToCopy: Event = await this.rootStore.eventsStore.getFullRecord(eventId)
    if (!eventToCopy) throw 'unable to find master event to copy'
    const copiedEvent = eventToCopy.clone()
    copiedEvent.objectId = null
    copiedEvent.organizer = this.rootStore.appStore.currentUserId
    copiedEvent.title = eventToCopy.title + ' (Copy)'
    copiedEvent.goingMaybeUserIds = []
    copiedEvent.goingNoUserIds = []
    copiedEvent.goingYesUserIds = []
    copiedEvent.goingNewUserIds = []
    copiedEvent.readUserIds = []
    this.validateEventType(copiedEvent)
    this.validateLocation(copiedEvent)
    this.validateResources(copiedEvent)
    return copiedEvent
  }

  private validateEventType(event: Event) {
    if (!event.eventTypeId) return
    const foundEventType = this.rootStore.eventTypesStore.getRecord(event.eventTypeId)
    if (foundEventType) return
    event.eventTypeId = ''
  }

  private validateLocation(event: Event) {
    if (!event.locationId) return
    const foundLocation = this.rootStore.locationsStore.getRecord(event.locationId)
    if (foundLocation) return
    event.locationId = ''
  }

  private validateResources(event: Event) {
    const foundResources = []
    if (!event.resources) return
    event.resources.forEach((r: Resource) => {
      const foundResource = this.rootStore.resourcesStore.getRecord(r.resourceId)
      if (foundResource) foundResources.push(r)
    })
    event.resources = foundResources
  }
}
