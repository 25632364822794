import React from 'react'
import { observer, inject } from 'mobx-react'
import { CardHeader, Tooltip, IconButton } from '@material-ui/core'
import './Header.scss'
import { SystemAdminsStore } from '../../store/SystemAdminsStore'
import Searchbar from '../../../shared/Searchbar'
import AddIcon from '@material-ui/icons/Add'
import LocalizationStore from '../../../localization/LocalizationStore'

interface Props {
  systemAdminsStore?: SystemAdminsStore
  localizationStore?: LocalizationStore
}

const Header: React.FC<Props> = ({ systemAdminsStore, localizationStore }) => {
  const { listWidgetVM } = systemAdminsStore
  if (!listWidgetVM) return null
  const { lzStrings } = localizationStore

  return (
    <CardHeader id='Header' title={'System Administrators'}
      action=
      {
        <>
          <div className='search'>
            <Searchbar
              focused={listWidgetVM.keywordFilter.length > 0}
              searchValue={listWidgetVM.keywordFilter}
              onSearchChange={e => listWidgetVM.setKeywordFilter(e.target.value)}
            />
          </div>
          <React.Fragment>
            <Tooltip title='Add New System Administrator' placement='bottom'>
              <span>
                <IconButton
                  className='widget-cta'
                  size='small'
                  disabled={listWidgetVM.isLoading}
                  onClick={() => listWidgetVM.openAddDrawer()}
                >
                  <AddIcon />
                </IconButton>
              </span>
            </Tooltip>
          </React.Fragment>
        </>
      }
    ></CardHeader>
  )
}

export default inject('systemAdminsStore', 'localizationStore')(observer(Header))
