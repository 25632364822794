import Parse from 'parse'
import { observable, action, computed } from 'mobx'
import { DataStore } from '../../shared/data/DataStore'
import { deserialize } from 'serializr'
import { RootStore } from '../../stores/RootStore'
import PulseQuestion from '../aggregate/PulseQuestion'
import { IPulseQuestionDTO } from '../dtos/IPulseQuestionDTO'
import { PulseQuestionEditVM } from '../view-models/PulseQuestionEditVM'
import { PulseQuestionTypeSelectModalVM } from '../view-models/PulseQuestionTypeSelectModalVM'
import { ChildCategoryRowVM } from '../../pulse-categories/view-models/ChildCategoryRowVM'
import { MediaUploadPickerVM } from '../../media-items/view-models/media-upload-picker/MediaUploadPickerVM'
import { YouTubePreviewVM } from '../view-models/YouTubePreviewVM'
import { VimeoPreviewVM } from '../view-models/VimeoPreviewVM'

export class PulseQuestionsStore extends DataStore<PulseQuestion, IPulseQuestionDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, PulseQuestion, 'pulseQuestions', [])
  }

  @observable public loaded: boolean = false
  @observable public editVM: PulseQuestionEditVM = null
  @observable public typeSelectVM: PulseQuestionTypeSelectModalVM = null
  @observable public mediaUploadPickerVM: MediaUploadPickerVM = null
  @observable public youTubePreviewVM: YouTubePreviewVM = null
  @observable public vimeoPreviewVM: VimeoPreviewVM = null

  @computed
  public get pulseQuestions(): PulseQuestion[] {
    return this.records
  }

  @action
  public onListRecordsLoaded() {
    this.loaded = true
  }

  public closeTypeSelectModal() {
    this.typeSelectVM = null
  }

  public getPulseQuestion(objectId): PulseQuestion {
    return this.pulseQuestions.find((e) => e.objectId === objectId)
  }

  public loadPulseQuestionTypeSelectVM(parentVM: ChildCategoryRowVM) {
    this.typeSelectVM = new PulseQuestionTypeSelectModalVM(this.rootStore, parentVM)
  }

  public closePulseQuestionTypeSelectVM() {
    this.typeSelectVM = null
  }

  public closeYouTubePreviewVM() {
    this.youTubePreviewVM = null
  }

  public closeVimeoPreviewVM() {
    this.vimeoPreviewVM = null
  }

  public showMediaPicker(pulseQuestion: PulseQuestion, addAfter: Function) {
    this.mediaUploadPickerVM = new MediaUploadPickerVM(
      this.rootStore,
      (media) => addAfter(media),
      true
    )
    this.mediaUploadPickerVM.toggleShowMediaUploadPicker()
    if (pulseQuestion.media.objectId)
      this.mediaUploadPickerVM.selectMediaItem(pulseQuestion.media.objectId)
  }

  public async loadPulseQuestionEditVM(objectId: string, childCategoryId?: string) {
    if (objectId === 'empty') return (this.editVM = null)
    if (objectId === 'new') {
      return (this.editVM = new PulseQuestionEditVM(
        this.rootStore,
        childCategoryId,
        PulseQuestion.create()
      ))
    }
    const pulseQuestion = await this.getFullRecord(objectId, true)
    this.editVM = new PulseQuestionEditVM(this.rootStore, childCategoryId, pulseQuestion.clone())
  }

  public async loadCampaignPulseQuestionEditVM(objectId: string, question?: PulseQuestion) {
    if (objectId === 'empty') return (this.editVM = null)
    if (objectId === 'new') {
      return (this.editVM = new PulseQuestionEditVM(this.rootStore, null, question.clone(), true, false, true))
    }
    return (this.editVM = new PulseQuestionEditVM(this.rootStore, null, question.clone(), true, false, true))
  }

  @action
  public loadYouTubePreviewVM(pulseQuestion: PulseQuestion) {
    this.youTubePreviewVM = new YouTubePreviewVM(this.rootStore, pulseQuestion)
  }

  @action
  public loadVimeoPreviewVM(pulseQuestion: PulseQuestion) {
    this.vimeoPreviewVM = new VimeoPreviewVM(this.rootStore, pulseQuestion)
  }

}
