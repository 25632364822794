import React from 'react'
import { inject, observer } from 'mobx-react'
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/DeleteRounded'
import MoreIcon from '@material-ui/icons/MoreVertRounded'
import PreviewIcon from '@material-ui/icons/VisibilityRounded'
import Fade from '@material-ui/core/Fade'
import rootStore from '../../stores/RootStore'
import { CMSItemVM } from '../view-models/CMSItemVM'
import { CMSItemsPreviewVM } from '../view-models/CMSItemsPreviewVM'
import { ICMSItemTag } from '../../cms-item-tags/interfaces/ICMSItemTag'
import './CMSItemsOptionMenu.scss'

interface Props {
  vm: CMSItemVM
  previewVM: CMSItemsPreviewVM
}

const CMSItemsOptionMenu: React.FC<Props> = ({ vm, previewVM }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  if (!vm) return null

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }

  const onPreview = async (ev) => {
    ev.stopPropagation()
    ev.preventDefault()

    if (!previewVM) return

    previewVM.openPreviewDialog()

    previewVM.setItem(vm)
    previewVM.setUpload({
      title: vm.title,
      description: vm.description,
      file: new File([], vm.title),
      id: vm.fileId,
      organizationId: rootStore.appStore.currentOrgId,
      isPrivate: vm.isPrivate,
      privateTo: vm.isPrivate ? rootStore.appStore.currentUserId : undefined,
      tags: vm.hasTags ? vm.tags.map((tag: ICMSItemTag) => tag.Tag_Name).slice() : [],
      extVideoUrl: vm.hasExternalVideo ? vm.extVideoUrl : '',
    })
    handleClose()
  }

  const onDelete = async (ev) => {
    ev.stopPropagation()
    ev.preventDefault()

    if (!previewVM) return

    previewVM.setItem(vm)
    previewVM.showDeleteConfirmDialog()
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const renderPreviewOption = () => {
    return (
      <MenuItem key={`preview-option-${new Date().toISOString()}`} onClick={(ev) => onPreview(ev)}>
        <ListItemIcon>
          <PreviewIcon className='icon' />
        </ListItemIcon>
        <ListItemText primary='Preview' />
      </MenuItem>
    )
  }

  const renderDeleteOption = () => {
    return (
      <MenuItem key={`delete-option-${new Date().toISOString()}`} onClick={(ev) => onDelete(ev)}>
        <ListItemIcon>
          <DeleteIcon className='icon' />
        </ListItemIcon>
        <ListItemText primary='Delete' />
      </MenuItem>
    )
  }

  const renderMenu = () => {
    return (
        <React.Fragment>
          <IconButton
            size='small'
            onClick={handleClick}
            onMouseDown={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
          >
            <MoreIcon fontSize='small' />
          </IconButton>
          <Menu
            className={`content-option-menu`}
            anchorEl={anchorEl}
            key={`content-option-menu-${new Date().toISOString()}`}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            elevation={1}
            TransitionComponent={Fade}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
            }}
            onMouseDown={(e) => {
              e.stopPropagation()
              e.preventDefault()
            }}
          >
            {renderPreviewOption()}
            {renderDeleteOption()}
          </Menu>
        </React.Fragment>
    )
  }

  return <div className='content-option-menu-container'>{renderMenu()}</div>
}

export default inject()(observer(CMSItemsOptionMenu))
