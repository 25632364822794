import React from 'react'
import { observer, useLocalStore } from 'mobx-react'
import { PulseCampaignsScreenVM } from '../../view-models/dashboard/PulseCampaignsScreenVM'
import rootStore from '../../../stores/RootStore'
import PulseScreenTopToolbar from '../dashboard/PulseCampaignsTopToolbar'
import EmptyState from '../../../shared/empty-state/EmptyState'
import emptyResults from '../../../../assets/img/empty-state/empty-state-results.png'
import { Button, Grid, Tooltip, Typography } from '@material-ui/core'
import './PulseCampaignsDashboard.scss'
import CreateCampaignDialog from '../dialogs/CreateCampaignDialog'
import AllCampaignsCard from './all-campaigns/AllCampaignsCard'
import UpcomingPulsesCard from './upcoming-pulses/UpcomingPulsesCard'
import DraftCampaignsCard from './draft-campaigns/DraftCampaignsCard'

const PulseCampaignsDashboard: React.FC = () => {
  const localStore = useLocalStore(() => ({
    vm: new PulseCampaignsScreenVM(rootStore),
  }))

  const vm = localStore.vm
  const lz = vm.lz
  const labelsStore = vm.labelsStore

  const renderCampaignsDashboard = () => {
    if (vm.tabIndex !== 0) return null
    return (
      <div className={'pulse-campaigns-dashboard'}>
        <div className={'campaigns-dashboard-header'}>
          <Typography className={'dashboard-title'}>{lz.campaigns}</Typography>
          <div className='header-buttons'>
            {renderManageTemplatesButton()}
            {renderCreateCampaignButton()}
          </div>
        </div>
        <div className={'pulse-campaigns-dashboard-content'}>{renderCards()}</div>
      </div>
    )
  }

  const renderManageTemplatesButton = () => {
    if (!vm.canManage) return null
    return (
      <Button
        onClick={() => vm.manageTemplates()}
        className={'empty-state-action-btn'}
        variant={'outlined'}
      >
        {'Manage Templates'}
      </Button>
    )
  }

  const renderCreateCampaignButton = () => {
    if (!vm.canManage) return null
    return (
      <Tooltip
        title={`${lz.create} ${labelsStore.getLabelByLanguageAndFor('pulse')} ${lz.campaign}`}
        placement='bottom'
      >
        <span>
          <Button
            onClick={() => vm.createPulseCampaign()}
            className={'empty-state-action-btn'}
            variant={'contained'}
          >
            {lz.create_campaign}
          </Button>
        </span>
      </Tooltip>
    )
  }

  const renderCards = () => {
    return (
      <>
        <div className={'dashboard-left'}>
          <AllCampaignsCard />
        </div>
        <div className={'dashboard-right'}>
          <UpcomingPulsesCard />
          <DraftCampaignsCard />
        </div>
      </>
    )
  }

  return (
    <div id='PulseCampaignsDashboard'>
      <CreateCampaignDialog />
      {/* <PulseScreenTopToolbar/> */}
      {renderCampaignsDashboard()}
    </div>
  )
}

export default observer(PulseCampaignsDashboard)
