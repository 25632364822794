import React from 'react'
import { inject, observer } from 'mobx-react'
import { Card, CircularProgress, Grid } from '@material-ui/core'
import rootStore from '../../stores/RootStore'
import EmailTemplatesTopToolbar from './EmailTemplatesTopToolbar'
import EmailTemplateCreateDialog from './dialogs/EmailTemplateCreateDialog'
import EmailTemplatesEmptyState from './empty-state/EmailTemplatesEmptyState'
import EmailTemplatesTable from './table/EmailTemplatesTable'
import EmailTemplateConfirmDialog from './dialogs/EmailTemplateConfirmDialog'
import EmailTemplateEditDialog from './dialogs/EmailTemplateEditDialog'
import EmailTemplatesSnackbar from './EmailTemplatesSnackbar'
import EmailTemplatesTableHeader from './table/EmailTemplatesTableHeader'
import OrgDefaultEmailTemplates from './OrgDefaultEmailTemplates'
import { EmailTemplatesManageVM } from '../view-models/EmailTemplatesManageVM'
import './EmailTemplatesManage.scss'

interface Props {
  vm: EmailTemplatesManageVM
}

const OrgEmailTemplatesManage: React.FC<Props> = ({ vm }) => {
  if (!vm) return

  const renderSpinner = () => {
    if (vm.shouldRender && !vm.isLoading) return
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  const renderEmptyState = () => {
    if (vm.shouldRender && vm.isLoading) return
    return (
      <EmailTemplatesEmptyState
        emailTemplatesStore={rootStore.emailTemplatesStore}
        localizationStore={rootStore.localizationStore}
        vm={vm}
      />
    )
  }

  const renderNotificationTemplates = () => {
    if (vm.shouldRender && vm.templates.length === 0) return renderEmptyState()
    return (
      <div className='email-templates-card'>
        <OrgDefaultEmailTemplates />
      </div>
    )
  }

  const renderTable = () => {
    if (!vm.isLoaded) return renderEmptyState()
    if (vm.templates.length === 0) return renderEmptyState()
    return (
      <Card elevation={0} id='EmailTemplatesTable'>
        <EmailTemplatesTableHeader
          vm={rootStore.emailTemplatesStore.viewModels.orgTableVM}
          emailTemplatesStore={rootStore.emailTemplatesStore}
        />
        <EmailTemplatesTable
          vm={rootStore.emailTemplatesStore.viewModels.orgTableVM}
          emailTemplatesStore={rootStore.emailTemplatesStore}
          localizationStore={rootStore.localizationStore}
        />
      </Card>
    )
  }

  const renderTopToolbar = () => {
    return (
      <EmailTemplatesTopToolbar
      emailTemplatesStore={rootStore.emailTemplatesStore}
      localizationStore={rootStore.localizationStore}
      vm={vm}
      tableVM={rootStore.emailTemplatesStore.viewModels.orgTableVM}
    />
    )
  }

  return (
    <div id='EmailTemplatesManage'>
      {renderSpinner()}
      <div className='email-templates-manage-container'>
          {renderTopToolbar()}
        <Grid className='email-templates-grid' container spacing={1} direction='column'>
          <Grid item className='email-templates-grid-item'>
            {renderTable()}
          </Grid>
          <Grid item className='email-templates-grid-item'>
            {renderNotificationTemplates()}
          </Grid>
        </Grid>
      </div>
      <EmailTemplateCreateDialog
        emailTemplatesStore={rootStore.emailTemplatesStore}
        localizationStore={rootStore.localizationStore}
      />
      <EmailTemplateConfirmDialog
        vm={rootStore.emailTemplatesStore.viewModels.previewVM}
        localizationStore={rootStore.localizationStore}
      />
      <EmailTemplateEditDialog vm={rootStore.emailTemplatesStore.viewModels.previewVM} />
      <EmailTemplatesSnackbar vm={rootStore.emailTemplatesStore.viewModels.previewVM} />
    </div>
  )
}

export default inject()(observer(OrgEmailTemplatesManage))
