import { computed, action } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { IUserInfoOrganizationResult } from '../interfaces/IUserInfoResult'

export class AvatarMenuRowVM {
  private rootStore: RootStore
  private org: IUserInfoOrganizationResult

  constructor(rootStore: RootStore, org: IUserInfoOrganizationResult) {
    this.rootStore = rootStore
    this.org = org
  }

  @computed
  public get name(): string {
    return this.org.name
  }

  @computed
  public get isCurrentOrg(): boolean {
    return this.org.organizationId === this.rootStore.appStore.currentOrgId
  }

  @action
  public async click() {
    const path = this.rootStore.appStore.router.location.pathname
    const router = this.rootStore.appStore.router
    router.push('/dashboard/userDashboard')
    const changed = await this.rootStore.appStore.setCurrentOrg(this.org.organizationId)
    if (changed) {
      console.log('refreshing')
      window.location.reload()
    }
  }
}
