import React from 'react'
import { OrganizationsSAStore } from '../../store/OrganizationsSAStore'
import { inject, observer } from 'mobx-react'
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core'
import './OrganizationEditDrawer.scss'

interface Props {
  organizationsSAStore?: OrganizationsSAStore
}

const OrganizationLanguages: React.FC<Props> = ({ organizationsSAStore }) => {
  const { editVM: vm } = organizationsSAStore
  if (!vm || vm.isNew) return null

  const renderLanguages = () => {
    return (
      <div id='userLanguages' className='details-paper'>
        <h6>Languages</h6>
        <Grid container>
          <FormControlLabel
            key='English'
            htmlFor='languages'
            onClick={() => vm.toggleEnglish()}
            control={<Checkbox color='primary' checked={vm.hasEnglish} />}
            label={'English (EN)'}
          />
          <FormControlLabel
            key='German'
            htmlFor='languages'
            onClick={() => vm.toggleGerman()}
            control={<Checkbox color='primary' checked={vm.hasGerman} />}
            label={'German (DE)'}
          />
          <FormControlLabel
            key='Pseudo'
            htmlFor='languages'
            onClick={() => vm.togglePseudo()}
            control={<Checkbox color='primary' checked={vm.hasPseudo} />}
            label={'Pseudo'}
          />
        </Grid>
      </div>
    )
  }

  return (
    <div id='OrganizationLanguages'>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          {renderLanguages()}
        </Grid>
      </Grid>
    </div>
  )
}

export default inject('organizationsSAStore')(observer(OrganizationLanguages))
