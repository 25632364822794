import React from 'react'
import {
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'

const BooleanInput = props => {
  return (
    <div className={props.className}>
        <FormControlLabel
          control={
            <Checkbox
              disabled={props.disabled}
              onChange={(e) => props.onChange(e.target.value)}
              value={props.value}
              color='primary'
            />
          }
          label={props.displayName}
        />
    </div>  
  )
}

export default BooleanInput
