import React from 'react'
import { MediaUploadPickerVM } from '../../view-models/media-upload-picker/MediaUploadPickerVM'
import { inject, observer } from 'mobx-react'
import rootStore from '../../../stores/RootStore'
import MediaToolbar from './MediaToolbar'
import MediaPanelPreview from './MediaPanelPreview'
import MediaGrid from './MediaGrid'
import MediaItemDrawer from './MediaItemDrawer'
import FileUploadModal from '../../../upload/views/FileUploadModal'
import { FileTypeEnum } from '../../../upload/enum/FileTypeEnum'
import { Attachment } from '../../../upload/aggregate/Attachment'
import CMSItemsPickerDialog from '../../../cms-items/views/dialogs/CMSItemsPickerDialog'
import { CMSItemsTypeEnum } from '../../../cms-items/enum/CMSItemsTypeEnum'
import { OrganizationsStore } from '../../../organizations/store/OrganizationsStore'
import { MediaItem } from '../../aggregate/MediaItem'
import './MediaUploadPicker.scss'

interface Props {
  mediaUploadPickerVM: MediaUploadPickerVM
  organizationsStore?: OrganizationsStore
}

const MediaUploadPicker: React.FC<Props> = ({ mediaUploadPickerVM, organizationsStore }) => {
  const vm = mediaUploadPickerVM
  if (!vm.showMediaUploadPicker) return null

  const renderNewMediaDrawer = () => {
    return <MediaItemDrawer mediaUploadPickerVM={vm} />
  }

  const renderMediaUploadPicker = () => {
    if (vm.mediaDrawerVM) return
    if (organizationsStore.currentOrganization?.hasCMSPicker) return false
    return (
      <div id='MediaUploadPicker'>
        <MediaToolbar mediaUploadPickerVM={vm} />
        <div className='media-view'>
          <MediaPanelPreview mediaUploadPickerVM={vm} />
          <MediaGrid mediaUploadPickerVM={vm} />
        </div>
      </div>
    )
  }

  const renderUploadModal = () => {
    if (organizationsStore.currentOrganization?.hasCMSPicker) return renderCMSUploadModal()
    return renderStandardUploadModal()
  }

  const renderCMSUploadModal = () => {
    return (
      <CMSItemsPickerDialog
        allowMultiple={false}
        fileTypes={CMSItemsTypeEnum.IMAGES_VIDEOS}
        addAfterSave={(a: Attachment[]) => {
          const media = MediaItem.createFromAttachment(a[0], rootStore.appStore.currentOrgId)
          media.cmsItemId = a[0].cmsItemId
          vm.handleMediaSelection(media)
        }}
        toggleShowModal={() => vm.toggleShowMediaUploadPicker()}
        isOpen={vm.showMediaUploadPicker}
    />
    )
  }

  const renderStandardUploadModal = () => {
    return (
      <FileUploadModal
        allowMultiple={false}
        fileTypes={FileTypeEnum.DEFAULT}
        addAfterSave={(a: Attachment[]) => vm.uploadMediaItem(a[0])}
        toggleShowModal={() => vm.toggleFileUploadModal()}
        isOpen={vm.showUploadModal}
      />
    )
  }

  return (
    <>
      {renderUploadModal()}
      {renderNewMediaDrawer()}
      {renderMediaUploadPicker()}
    </>
  )
}
export default inject('organizationsStore')(observer(MediaUploadPicker))
