import React, { useMemo, useState } from 'react'
import { debounce } from 'lodash'
import { inject, observer } from 'mobx-react'
import {
  Toolbar,
  Tooltip,
  Select,
  MenuItem,
  TextField,
  Checkbox,
  IconButton,
  Button,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import UploadIcon from '@material-ui/icons/CloudUploadRounded'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlankRounded'
import CheckBoxIcon from '@material-ui/icons/CheckBoxRounded'
import LocalizationStore from '../../localization/LocalizationStore'
import Searchbar from '../../shared/Searchbar'
import ContentToggleAccess from './CMSItemsToggleAccess'
import ContentToggleLayout from './CMSItemsToggleLayout'
import { CMSItemsManageVM } from '../view-models/CMSItemsManageVM'
import '../../css/dashboard-toolbar.scss'
import './CMSItemsLayoutTopToolbar.scss'

interface Props {
  vm: CMSItemsManageVM
  localizationStore?: LocalizationStore
}

const CMSItemsLayoutTopToolbar: React.FC<Props> = ({ vm, localizationStore }) => {
  const { contentManagement: lz } = localizationStore.lzStrings

  const [search, setSearch] = useState('')

  const debounceFn = useMemo(() => debounce((val) => handleDebounceFn(val), 350), [])

  const handleDebounceFn = (inputValue: string) => {
    vm.setSearchFilter(inputValue)
  }

  const handleSearchChange = (event) => {
    setSearch(event.target.value)
    debounceFn(event.target.value)
  }

  if (!vm) return null

  const renderSearchbar = () => {
    return (
      <Searchbar
        className={'search'}
        focused={vm.typedFilterText.length > 0}
        searchValue={search}
        onSearchChange={handleSearchChange}
      />
    )
  }

  const renderFilterByTag = () => {
    if (!vm.canManageTags) return

    return (
      <Autocomplete
        className={'toolbar-filter'}
        key={'toolbar-filter-format'}
        size='small'
        limitTags={1}
        multiple
        disableCloseOnSelect
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
              checkedIcon={<CheckBoxIcon fontSize='small' />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option}
          </React.Fragment>
        )}
        onChange={(e, val) => {
          if (val === null) {
            vm.setTagValues([])
          } else {
            vm.setTagValues(val)
          }
        }}
        options={vm.tagOptions}
        getOptionLabel={(option) => option}
        getOptionSelected={(option, value) => option === value}
        renderInput={(params) => (
          <TextField
            label='Tag'
            name='toolbar-tag-text'
            type='search'
            variant='outlined'
            placeholder={''}
            {...params}
          />
        )}
      />
    )
  }

  const renderFilterByFormat = () => {
    return (
      <Autocomplete
        className={'toolbar-filter'}
        key={'toolbar-filter-tags'}
        size='small'
        limitTags={1}
        multiple
        disableCloseOnSelect
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
              checkedIcon={<CheckBoxIcon fontSize='small' />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option}
          </React.Fragment>
        )}
        onChange={(e, val) => {
          if (val === null) {
            vm.setFilterTypes(undefined)
          } else {
            vm.setFilterTypes(val)
          }
        }}
        options={vm.formats}
        getOptionLabel={(option) => option}
        getOptionSelected={(option, value) => option === value}
        renderInput={(params) => (
          <TextField
            label='Type'
            name='toolbar-type-text'
            type='search'
            variant='outlined'
            placeholder={''}
            {...params}
          />
        )}
      />
    )
  }

  const renderSortByAttribute = () => {
    if (!vm.isLayoutGrid) return
    return (
      <Select
        className='sort-by-select'
        variant='outlined'
        value={vm.sortType}
        onChange={(e) => vm.setSortType(e.target.value)}
      >
        <MenuItem className='sort-by-select-item' value={'createdAt-desc'}>
          {lz.created_date} (newest first)
        </MenuItem>
        <MenuItem className='sort-by-select-item' value={'createdAt-asc'}>
          {lz.created_date} (oldest first)
        </MenuItem>
        <MenuItem className='sort-by-select-item' value={'updatedAt-desc'}>
          {lz.updated_date} (newest first)
        </MenuItem>
        <MenuItem className='sort-by-select-item' value={'updatedAt-asc'}>
          {lz.updated_date} (oldest first)
        </MenuItem>
        <MenuItem className='sort-by-select-item' value={'Title-asc'}>
          {lz.name} (A to Z)
        </MenuItem>
        <MenuItem className='sort-by-select-item' value={'Title-desc'}>
          {lz.name} (Z to A)
        </MenuItem>
      </Select>
    )
  }

  const renderFilters = () => {
    return (
      <div id='ContentPickerLayoutFilters'>
        {renderFilterByFormat()}
        {renderFilterByTag()}
      </div>
    )
  }

  const renderViewToggle = () => {
    return <ContentToggleLayout vm={vm} />
  }

  const renderAccessToggle = () => {
    return <ContentToggleAccess vm={vm} />
  }

  const renderUploadButton = () => {
    return (
      <Tooltip title={'Upload New Content'} placement='bottom-start'>
        <span>
          <IconButton onClick={() => vm.openUploadDialog()} className={'toolbar-action-btn'}>
            <UploadIcon />
          </IconButton>
        </span>
      </Tooltip>
    )
  }

  const renderClearButton = () => {
    const selectedCount = vm.selectedCount
    if (selectedCount < 1) return
    return (
      <Tooltip title={`Clear ${selectedCount} item${selectedCount > 1 ? 's' : ''} selected`} placement='bottom-start'>
        <span>
          <Button
            className={'toolbar-action-btn'}
            variant='outlined'
            onClick={(e) => {
              vm.clearSelections()
            }}
          >
            {lz.clear_selections}
          </Button>
        </span>
      </Tooltip>
    )
  }

  return (
    <div id='ContentLayoutTopToolbar' className={'dashboardToolbar-header'}>
      <Toolbar className={'dashboard-toolbar'}>
        <div className='toolbar-left'>
          {renderUploadButton()}
          {renderClearButton()}
        </div>
        <div className='toolbar-right'>
          {renderSearchbar()}
          {renderFilters()}
          {renderSortByAttribute()}
          {renderViewToggle()}
        </div>
      </Toolbar>
    </div>
  )
}

export default inject('localizationStore')(observer(CMSItemsLayoutTopToolbar))
