import Parse from 'parse'
import { observable, action, computed, runInAction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { DataStore } from '../../shared/data/DataStore'
import { QuizResult } from '../aggregate/QuizResult'
import { IQuizResultDTO } from '../dtos/IQuizResultDTO'
import { QuizResultsVM } from '../view-models/QuizResultsVM'

export class QuizResultsStore extends DataStore<QuizResult, IQuizResultDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, QuizResult, 'quizResults', [
      'objectId',
      'organizationId',
      'quizId',
      'trainingPlanId',
      'questionAnswers',
      'totalScores',
      'totalResponses'
    ])
  }

  @observable public resultsVM: QuizResultsVM = null
  private currentQuizId: string = ''
  private currentTrainingPlanId: string = ''
  private loadedQuizId: string = ''
  private loadedTrainingPlanId: string = ''

  @action
  public onListRecordsLoaded() {
    this.setLoadedIds(this.currentQuizId, this.currentTrainingPlanId)
  }

  public shouldLoadListRecords() {
    if (this.loadedQuizId !== this.currentQuizId) return true
    if (this.loadedTrainingPlanId !== this.currentTrainingPlanId) return true
    return false
  }

  protected getBaseQuery() {
    const query = new Parse.Query(this.className)
    query.equalTo('organizationId', this.rootStore.appStore.currentOrgId)
    query.equalTo('quizId', this.currentQuizId)
    query.equalTo('trainingPlanId', this.currentTrainingPlanId)
    return query
  }

  @action
  public loadResultsVM(quizId: string, trainingPlanId: string) {
    // this.resultsVM = new SurveyResultsVM(this.rootStore, surveyId, userSurveyId)
  }

  @action
  private setLoadedIds(quizId: string, trainingPlanId: string) {
    this.loadedQuizId = quizId
    this.loadedTrainingPlanId = trainingPlanId
  }

  @action
  public setCurrentIds(quizId: string, trainingPlanId: string) {
    this.currentQuizId = quizId
    this.currentTrainingPlanId = trainingPlanId
    this.loadListRecords()
  }

  @action
  public clearResultsVM() {
    this.resultsVM = null
  }

  @computed
  public get currentOrgQuizResults(): QuizResult[] {
    return this.records
  }
}
