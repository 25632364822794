import React from 'react'
import './Drawer.scss'
import { inject, observer } from 'mobx-react'
import { Card, CardContent, Drawer, Dialog, Grid, Button, DialogActions } from '@material-ui/core'
import DrawerHeader from './DrawerHeader'
import DrawerButtonControls from './DrawerButtonControls'
import DrawerCategoryDetails from './DrawerCategoryDetails'
import DrawerCategoryModules from './DrawerCategoryModules'
import MuiDrawerToolbar from '../../../../shared/Mui-Drawer/MuiDrawerToolbar'
import { LabelsStore } from '../../../../labels/store/LabelsStore'
import { CategoriesStore } from 'src/app/categories/CategoriesStore'

interface CategoriesManagmentWidgetDrawer {
  categoriesStore?: CategoriesStore
  labelsStore?: LabelsStore
}

const CategoriesManagementWidgetDrawer: React.FC<CategoriesManagmentWidgetDrawer> = ({
  categoriesStore,
  labelsStore,
}) => {
  const { widgetForm } = categoriesStore
  if (!widgetForm) return

  const renderRoles = () => {
    return widgetForm.connectedRoles.map((e, i) => (
      <Grid item xs={12} className='tiedRole' key={i}>
        - {e.name}
      </Grid>
    ))
  }

  const renderDialog = () => {
    return (
      <Dialog
        onClose={() => widgetForm.toggleCantDeleteDialog()}
        open={widgetForm.showCantDeleteDialog}
        id='CantDeleteDialog'
      >
        <Grid container className='cantDeleteDialog'>
          <Grid item xs={12}>
            <span className={'bold'}>{widgetForm.name}</span> can not be deleted due to it currently
            being tied to the following role(s):
          </Grid>
          {renderRoles()}
          <Grid item xs={12}>
            <span className={'bold'}>
              {' '}
              In order to delete a {labelsStore.getLabelByLanguageAndFor('category')}, it must not
              be tied to a role.
            </span>
          </Grid>
        </Grid>
        <DialogActions>
          <Button variant='contained' onClick={() => widgetForm.toggleCantDeleteDialog()}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <Drawer
      anchor='right'
      open={widgetForm.isFormDrawerOpen}
      onClose={() => widgetForm.closeDrawer()}
    >
      {renderDialog()}
      <Card elevation={0}>
        <DrawerHeader />
        <DrawerButtonControls />
        <CardContent>
          <DrawerCategoryDetails />
          <DrawerCategoryModules />
        </CardContent>
      </Card>
    </Drawer>
  )
}

export default inject('categoriesStore', 'labelsStore')(observer(CategoriesManagementWidgetDrawer))
