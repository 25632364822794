import React from 'react'
import { inject, observer } from 'mobx-react'
import {
  AppBar,
  Button,
  CssBaseline,
  Grid,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ReplayIcon from '@material-ui/icons/Replay'
import SaveRoundedIcon from '@material-ui/icons/SaveRounded'
import LocalizationStore from '../../localization/LocalizationStore'
import { NotificationsStore } from '../store/NotificationsStore'
import { NotificationsWidgetVM } from '../store/view-models/NotificationsWidgetVM'
import '../../css/dashboard-toolbar.scss'
import './NotificationTemplatesTopToolbar.scss'

interface Props {
  localizationStore?: LocalizationStore
  notificationsStore?: NotificationsStore
  vm: NotificationsWidgetVM
}

const NotificationTemplatesTopToolbar: React.FC<Props> = ({
  localizationStore,
  notificationsStore,
  vm,
}) => {
  const { notificationsWidgetDrawer: lz } = localizationStore.lzStrings

  const { editVM: widgetForm } = notificationsStore

  const handleClose = () => {
    if (!widgetForm.isDirty) return widgetForm.closeEditor()
    widgetForm.doClose = true
    widgetForm.toggleConfirmDialog()
  }

  const handleReset = () => {
    widgetForm.doReset = true
    widgetForm.toggleConfirmDialog()
  }

  const handleSave = () => {
    widgetForm.toggleConfirmDialog()
  }

  const renderDialogTitle = () => {
    return <Typography className='notification-template-name-toolbar'>{lz.edit}</Typography>
  }

  const renderSaveButton = () => {
    return (
      <Tooltip
        title={lz.save_tooltip}
        placement='bottom-start'
        arrow
      >
        <span>
          <Button
            className='notification-template-dialog-action-save'
            variant='contained'
            onClick={handleSave}
            disabled={!widgetForm.saveEnabled}
            size='small'
            startIcon={<SaveRoundedIcon />}
          >
            {lz.save_notification}
          </Button>
        </span>
      </Tooltip>
    )
  }

  const renderResetButton = () => {
    return (
      <Tooltip
        title={lz.reset_tooltip}
        placement='bottom-start'
        arrow
      >
        <span>
          <IconButton
            className='notification-template-dialog-action'
            edge='start'
            color='inherit'
            aria-label='close'
            disabled={!widgetForm.isDirty}
            onClick={handleReset}
          >
            <ReplayIcon />
          </IconButton>
        </span>
      </Tooltip>
    )
  }

  const renderCloseButton = () => {
    return (
      <Tooltip title={lz.close_tooltip} placement='bottom-start' arrow>
        <span>
          <IconButton
            className='notification-template-dialog-action'
            edge='start'
            color='inherit'
            onClick={handleClose}
            aria-label='close'
          >
            <CloseIcon />
          </IconButton>
        </span>
      </Tooltip>
    )
  }

  return (
    <div id='DashboardToolbar' className={'dashboardToolbar-header'}>
      <CssBaseline />
      <AppBar style={{ position: 'relative' }}>
        <Toolbar className={'dashboard-toolbar'}>
          <Grid container direction='row' justifyContent='space-between' alignItems='center'>
            {renderDialogTitle()}
            <div id='NotificationTemplatesDialogRight'>
              {renderSaveButton()}
              {renderResetButton()}
              {renderCloseButton()}
            </div>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default inject(
  'localizationStore',
  'notificationsStore'
)(observer(NotificationTemplatesTopToolbar))
