import { action, observable, computed, reaction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { CategoryPickerVM } from '../../shared/category-picker/CategoryPickerVM'
import { ParticipantsSelectVM } from '../../participants-select/view-models/ParticipantsSelectVM'
import { ContentItem } from '../aggregate/ContentItem'
import { MediaUploadPickerVM } from '../../media-items/view-models/media-upload-picker/MediaUploadPickerVM'
import { MediaItem } from '../../media-items/aggregate/MediaItem'
import { TrainingItemEditVM } from './TrainingItemEditVM'
import { ContentAttachmentType } from '../types/ContentAttachmentType'
import { FileOpenService } from '../../upload/services/FileOpenService'
import { Attachment } from '../../attachments/aggregate/Attachment'
import { isNumeric } from '../../shared/isNumeric'
import { OldFileAttachmentVM } from './OldFileAttachmentVM'
import { CMSFileAttachmentVM } from './CMSFileAttachmentVM'
import { ContentItemType } from '../types/ContentItemType'
import { CMSMediaItemAttachmentVM } from './CMSMediaItemAttachmentVM'
import { OldMediaItemAttachmentVM } from './OldMediaItemAttachmentVM'

export class ContentItemEditVM {
  private rootStore: RootStore
  public content: ContentItem
  public categoryPickerVM: CategoryPickerVM
  public mediaUploadPickerVM: MediaUploadPickerVM
  public participantsSelectVM: ParticipantsSelectVM
  public trainingItemVM: TrainingItemEditVM
  private index: number

  constructor(
    rootStore: RootStore,
    content: ContentItem,
    trainingItemVM: TrainingItemEditVM,
    index: number
  ) {
    this.rootStore = rootStore
    this.content = content
    this.index = index
    this.trainingItemVM = trainingItemVM
    this.categoryPickerVM = new CategoryPickerVM(this.rootStore)
    this.mediaUploadPickerVM = new MediaUploadPickerVM(
      this.rootStore,
      (e) => this.setMediaItem(e),
      true
    )
    if (this.content) this.categoryPickerVM.setAppliedCategory(this.content.categoryId)
    this.participantsSelectVM = new ParticipantsSelectVM(rootStore, false, false)
  }

  @observable public showFileUploadModal: boolean = false

  @computed
  public get key(): string {
    return this.content.id + '_' + this.index
  }

  @computed
  public get hasAttachments(): boolean {
    if (this.content.type === 'media' && !this.mediaItem) return false
    if (this.content.type === 'exercise' && !this.mediaItem) return false
    if (this.content.type === 'file' && !this.file) return false
    if (this.content.type === 'link') return false
    if (!this.attachment) return false
    return true
  }

  @computed
  public get isActive(): boolean {
    if (!this.rootStore.trainingItemsStore.trainingItemEditVM.currentContentItemVM) return false
    return this.rootStore.trainingItemsStore.trainingItemEditVM.currentContentItemVM.id === this.id
  }

  @computed
  public get id(): string {
    return this.content.id
  }

  @computed
  public get title(): string {
    if (!this.content) return ''
    return this.content.title
  }

  @action
  public setTitle(val: string) {
    this.content.setTitle(val)
  }

  @computed
  public get sets(): number {
    if (!this.content) return 0
    if (!this.content.exercise) return 0
    return this.content.exercise.goal.sets
  }

  @action
  public setSets(val: any) {
    if (!val) return this.content.setSets(0)
    if (val === '0') return this.content.setSets(0)
    this.content.setSets(parseInt(val.replace(/^0+/, ''), 10))
  }

  @computed
  public get reps(): number {
    if (!this.content) return 0
    if (!this.content.exercise) return 0
    return this.content.exercise.goal.reps
  }

  @action
  public setReps(val: string) {
    if (!val) return this.content.setReps(0)
    if (val === '0') return this.content.setReps(0)
    this.content.setReps(parseInt(val.replace(/^0+/, ''), 10))
  }

  @computed
  public get weight(): number {
    if (!this.content) return 0
    if (!this.content.exercise) return 0
    return this.content.exercise.goal.weight
  }

  @action
  public setWeight(val: string) {
    if (!val) return this.content.setWeight(0)
    if (val === '0') return this.content.setWeight(0)
    this.content.setWeight(parseInt(val.replace(/^0+/, ''), 10))
  }

  @computed
  public get time(): number {
    if (!this.content) return 0
    if (!this.content.exercise) return 0
    return this.content.exercise.goal.time
  }

  @computed
  public get percent(): number {
    if (!this.content) return 0
    if (!this.content.exercise) return 0
    return this.content.exercise.goal.percent
  }

  @action
  public setPercent(val: string) {
    if (!val) return this.content.setPercent(0)
    if (val === '0') return this.content.setPercent(0)
    this.content.setPercent(parseInt(val.replace(/^0+/, ''), 10))
  }

  @action
  public setTime(val: string) {
    if (!val) return this.content.setTime(0)
    if (val === '0') return this.content.setTime(0)
    this.content.setTime(parseInt(val.replace(/^0+/, ''), 10))
  }

  @computed
  public get type(): string {
    if (!this.content || !this.content.type) return ''
    const string = this.content.type
    return string.substr(0, 1).toUpperCase() + string.substr(1).toLowerCase()
  }

  @action
  public setType(val: any) {
    this.content.setType(val.toLowerCase())
    this.removeCurrentAttachment()
  }

  @computed
  public get linkUrl(): string {
    if (!this.content) return ''
    return this.content.link
  }

  @action
  public setLink(val: string) {
    this.content.setLink(val)
  }

  @computed
  public get attachment(): ContentAttachmentType {
    const type = this.type.toLowerCase() as ContentItemType
    if (this.type === 'Media' && this.mediaItem) {
      if (isNumeric(this.mediaItem.cmsItemId) || this.mediaItem.cmsItemId) {
        const vm = new CMSMediaItemAttachmentVM(this.rootStore, type, this.mediaItem)
        vm.loadCMSItem()
        return vm
      }
      return new OldMediaItemAttachmentVM(this.rootStore, type, this.mediaItem)
    }
    if (this.type === 'Exercise' && this.mediaItem) {
      if (isNumeric(this.mediaItem.cmsItemId) || this.mediaItem.cmsItemId) {
        const vm = new CMSMediaItemAttachmentVM(this.rootStore, type, this.mediaItem)
        vm.loadCMSItem()
        return vm
      }
      return new OldMediaItemAttachmentVM(this.rootStore, type, this.mediaItem)
    }
    if (this.type === 'File' && this.content.fileAttachment) {
      if (isNumeric(this.file.cmsItemId) || this.file.cmsItemId) {
        const vm = new CMSFileAttachmentVM(this.rootStore, type, this.file)
        vm.loadCMSItem()
        return vm
      }
      return new OldFileAttachmentVM(this.rootStore, type, this.file)
    }
  }

  @computed
  public get file() {
    return this.content.fileAttachment
  }

  @computed
  public get descriptionRequired(): boolean {
    if (this.type === 'Hands-on') return true
    return false
  }

  @computed
  public get descriptionValid(): boolean {
    if (!this.descriptionBody && this.type === 'Hands-on' && this.trainingItemVM.saveTried)
      return false
    return true
  }

  @computed
  public get isValid(): boolean {
    if (
      !this.titleValid ||
      !this.contentTypeValid ||
      !this.categoryValid ||
      !this.linkUrlValid ||
      !this.fileValid ||
      !this.mediaItemValid ||
      !this.descriptionValid ||
      !this.exerciseValid
    )
      return false
    return true
  }

  @computed
  public get mediaItem(): MediaItem {
    if (this.content.mediaItem) return this.content.mediaItem

    if (!this.content.mediaItemId) return null
    return this.rootStore.mediaItemsStore.getMediaItem(this.content.mediaItemId)
  }

  @computed
  public get exerciseMediaItem(): MediaItem {
    if (this.content.mediaItem) return this.content.mediaItem

    if (!this.content.mediaItemId) return null
    return this.rootStore.mediaItemsStore.getMediaItem(this.content.mediaItemId)
  }

  @action
  public toggleFileUploadModal() {
    this.showFileUploadModal = !this.showFileUploadModal
  }

  @action
  public addAttachment(attachment: Attachment) {
    this.content.addFile(attachment)
  }

  @action
  public async openAttachment() {
    const svc = new FileOpenService(this.rootStore)
    await svc.openFile(this.file)
  }

  @action
  public removeCurrentAttachment() {
    if (this.type === 'Media') this.removeMedia()
    else if (this.type === 'Exercise') this.removeMedia()
    else if (this.type === 'File') this.removeFile()
    else if (this.type === 'Link') this.setLink('')
  }

  @action
  public removeMedia() {
    this.content.removeMedia()
  }

  @action
  public removeFile() {
    this.content.removeFile()
  }

  @action
  public setMediaItemId(mediaItemId) {
    this.content.setMediaItemId(mediaItemId)
  }

  @action
  public setMediaItem(media) {
    this.content.setMediaItem(media)
  }

  @action
  public toggleMediaUpload() {
    this.mediaUploadPickerVM.toggleShowMediaUploadPicker()
  }

  @computed
  public get categoryId(): string {
    if (!this.content) return ''
    return this.content.categoryId
  }

  @action
  public setCategory(id: string) {
    this.categoryPickerVM.setAppliedCategory(id)
    this.content.setCategory(id)
  }

  @computed
  public get descriptionTitle(): string {
    if (!this.content) return ''
    return this.content.descriptionTitle
  }

  @action
  public setDescriptionTitle(val: string) {
    this.content.setDescriptionTitle(val)
  }

  @computed
  public get descriptionBody(): string {
    if (!this.content) return ''
    return this.content.descriptionBody
  }

  @action
  public setDescriptionBody(val: string) {
    this.content.setDescriptionBody(val)
  }

  @computed
  public get titleValid(): boolean {
    if (!this.title && this.trainingItemVM.saveTried) return false
    return true
  }

  @computed
  public get setsValid(): boolean {
    if (!this.sets && this.trainingItemVM.saveTried) return false
    if (this.sets <= 0 && this.trainingItemVM.saveTried) return false
    return true
  }

  @computed
  public get repsValid(): boolean {
    if (!this.reps && this.reps !== 0 && this.trainingItemVM.saveTried) return false
    if (this.reps < 0 && this.trainingItemVM.saveTried) return false
    return true
  }

  @computed
  public get weightValid(): boolean {
    if (!this.weight && this.weight !== 0 && this.trainingItemVM.saveTried) return false
    if (this.weight < 0 && this.trainingItemVM.saveTried) return false
    return true
  }

  @computed
  public get timeValid(): boolean {
    if (!this.time && this.time !== 0 && this.trainingItemVM.saveTried) return false
    if (this.time < 0 && this.trainingItemVM.saveTried) return false
    return true
  }

  @computed
  public get percentValid(): boolean {
    if (!this.percent && this.percent !== 0 && this.trainingItemVM.saveTried) return false
    if (this.percent < 0 && this.trainingItemVM.saveTried) return false
    return true
  }

  @computed
  public get exerciseValid(): boolean {
    if (this.type !== 'Exercise') return true
    if (!this.repsValid && this.trainingItemVM.saveTried) return false
    if (!this.setsValid && this.trainingItemVM.saveTried) return false
    if (!this.weightValid && this.trainingItemVM.saveTried) return false
    if (!this.timeValid && this.trainingItemVM.saveTried) return false
    if (!this.percentValid && this.trainingItemVM.saveTried) return false
    return true
  }

  @computed
  public get contentTypeValid(): boolean {
    if (!this.type && this.trainingItemVM.saveTried) return false
    return true
  }

  @computed
  public get categoryValid(): boolean {
    return true
    if (!this.categoryId && this.trainingItemVM.saveTried) return false
    return true
  }

  @computed
  public get linkUrlValid(): boolean {
    const regex = RegExp(
      /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi
    )
    const linkValid = regex.test(this.linkUrl)
    if (this.type === 'Link' && !linkValid && this.trainingItemVM.saveTried) return false
    if (this.type === 'Link' && !this.linkUrl && this.trainingItemVM.saveTried) return false
    return true
  }

  @action
  public openLink() {
    if (!this.linkUrlValid) return
    let url = this.linkUrl
    if (!this.linkUrl.startsWith('http')) url = 'https://' + url
    window.open(url, '_blank')
  }

  @computed
  public get mediaItemValid(): boolean {
    if (this.type === 'Media' && !this.mediaItem && this.trainingItemVM.saveTried) return false
    return true
  }

  @computed
  public get fileValid(): boolean {
    if (this.type === 'File' && !this.file && this.trainingItemVM.saveTried) return false
    return true
  }

  @computed
  public get exerciseMediaItemValid(): boolean {
    if (this.type === 'Exercise' && !this.mediaItem && this.trainingItemVM.saveTried) return false
    return true
  }
}
