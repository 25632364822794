import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { CircularProgress } from '@material-ui/core'
import ReactPlayer from 'react-player'
import { CMSMediaItemVM } from '../../../user-surveys/view-models/CMSMediaItemVM'
import MuxVideo from '../../../shared/MuxVideo'
import { useIsMounted } from '../../../shared/useIsMounted'
import { isNumeric } from '../../../shared/isNumeric'
import { QuestionVM } from '../../view-models/QuestionVM'
import './QuestionRow.scss'

interface Props {
  question: QuestionVM
  vm: CMSMediaItemVM
}

const CMSQuestionMedia: React.FC<Props> = ({ question, vm }) => {
  const isMounted = useIsMounted()

  const handleLoad = () => {
    if (!isMounted) return
    if (vm.isLoading) return

    vm.loadCMSItem()
  }

  useEffect(() => {
    handleLoad()
  }, [])

  const renderSpinner = () => {
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  if (!vm) return

  if (!vm.isLoaded) {
    handleLoad()
    return renderSpinner()
  }

  const renderCMSImage = (item: CMSMediaItemVM) => {
    if (!item.isCMSItem) return
    if (!item.isImage) return
    if (item.hasVimeoURL) return
    if (item.hasYouTubeURL) return
    return (
      <img
        className='media-image'
        src={question.media.path}
        onClick={() => question.toggleLightBox()}
        alt=''
      />
    )
  }

  const renderYouTubeVideo = (item: CMSMediaItemVM) => {
    if (!item.isCMSItem) return
    if (!item.hasYouTubeURL) return
    return (
      <div>
        <iframe
          width={'100%'}
          className='media-youTube'
          src={item.embedUrl}
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        />
      </div>
    )
  }

  const renderVimeoVideo = (item: CMSMediaItemVM) => {
    if (!item.isCMSItem) return
    if (!item.hasVimeoURL) return
    return (
      <div>
        <iframe
          width={'100%'}
          className='media-vimeo'
          src={item.embedUrl}
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        />
      </div>
    )
  }

  const renderCMSVideo = (item: CMSMediaItemVM) => {
    if (!item.isVideo) return
    if (!item.isCMSItem) return
    if (item.isMuxVideo) return
    if (item.hasVimeoURL) return
    if (item.hasYouTubeURL) return

    return (
      <ReactPlayer
        id={`survey-video-${question.id}`}
        height={''}
        width={'100%'}
        progressInterval={1000}
        onProgress={(e) => question.watchProgress(e)}
        onDuration={(e) => question.setWatchPercentageNeeded(e)}
        url={item.path}
        onSeek={() => overrideSeek()}
        controls
        className='media-video'
      />
    )
  }

  const renderMuxVideo = (item: CMSMediaItemVM) => {
    if (!item.isVideo) return
    if (!item.isMuxVideo) return

    const cmsItemId = isNumeric(item.objectId) ? Number(item.objectId) : item.cmsItemId

    return (
      <div className={'mux-player-container'}>
        <MuxVideo
          cmsItemId={`${cmsItemId}`}
          playbackUrl={item.path}
          playbackId={item.thumbnailUrl}
          videoTitle={item.path}
        />
      </div>
    )
  }

  const overrideSeek = () => {
    let video = document.querySelector('video')
    if (video.currentTime === question.playedSeconds) return

    if (question.playedSeconds <= question.watchSecondsNeeded)
      video.currentTime = question.playedSeconds
  }

  return (
    <>
      {renderCMSImage(vm)}
      {renderYouTubeVideo(vm)}
      {renderVimeoVideo(vm)}
      {renderCMSVideo(vm)}
      {renderMuxVideo(vm)}
    </>
  )
}

export default inject()(observer(CMSQuestionMedia))
