import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { OrganizationsStore } from '../../store/OrganizationsStore'
import { CardHeader, IconButton, Tooltip } from '@material-ui/core'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'

interface HeaderProps {
  localizationStore?: LocalizationStore
  organizationsStore?: OrganizationsStore
}

const WidgetHeader: React.FC<HeaderProps> = ({ localizationStore, organizationsStore }) => {
  const [mouseDown, setMouseDown] = useState(false)

  return (
    <CardHeader
      className='drag-handle'
      onMouseDown={() => setMouseDown(true)}
      onMouseUp={() => setMouseDown(false)}
      action={
        <>
          <React.Fragment>
            <Tooltip
              title={localizationStore.lzStrings.organizationWidget.edit_org_details}
              placement='bottom'
            >
              <IconButton
                size='small'
                className='widget-cta'
                onClick={() => organizationsStore.openDrawer()}
              >
                <EditOutlinedIcon />
              </IconButton>
            </Tooltip>
          </React.Fragment>
        </>
      }
      title={localizationStore.lzStrings.organizationWidget.org_details}
    ></CardHeader>
  )
}

export default inject('localizationStore', 'organizationsStore')(observer(WidgetHeader))
