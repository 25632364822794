import React from 'react'
import url from 'url'
import shallowequal from 'shallowequal'
import tokenizeUrl from '../../shared/TokenizeUrl'

interface Props {
  filters?: object
  url: string
  parameters?: object
  options?: tableau.VizCreateOptions
  token?: string
  onLoad?: Function
  query?: string
  onVizLoaded?: any
}

interface State {
  loading: boolean
  parameters: object
  filters: object
  options: tableau.VizCreateOptions
  query: string
  didInvalidateToken: boolean
}

class TableauReport extends React.Component<Props, State> {
  workbook: any
  sheet: any
  viz: any
  container: HTMLElement

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      query: '?:embed=yes&:comments=no&:toolbar=yes&:refresh=yes',
      filters: props.filters ? props.filters : {},
      parameters: props.parameters ? props.parameters : {},
      options: props.options ? props.options : {},
      didInvalidateToken: false,
    }
  }

  componentDidMount() {
    this.initTableau()
  }

  componentWillUnmount() {
    if (this.viz) this.viz.dispose()
    this.viz = null
  }

  componentWillReceiveProps(nextProps) {
    const isReportChanged = nextProps.url !== this.props.url
    const isFiltersChanged = !shallowequal(
      this.props.filters,
      nextProps.filters,
      this.compareArrays
    )
    const isParametersChanged = !shallowequal(this.props.parameters, nextProps.parameters)
    const isLoading = this.state.loading

    // Only report is changed - re-initialize
    if (isReportChanged) {
      // this.initTableau(nextProps.url)
    }

    // Only filters are changed, apply via the API
    if (!isReportChanged && isFiltersChanged && !isLoading) {
      this.applyFilters(nextProps.filters)
    }

    // Only parameters are changed, apply via the API
    if (!isReportChanged && isParametersChanged && !isLoading) {
      this.applyParameters(nextProps.parameters)
    }

    // token change, validate it.
    if (nextProps.token !== this.props.token) {
      this.setState({ didInvalidateToken: false })
    }
  }

  /**
   * Compares the values of filters to see if they are the same.
   * @param  {Array<Number>} a
   * @param  {Array<Number>} b
   * @return {Boolean}
   */
  compareArrays(a, b) {
    if (Array.isArray(a) && Array.isArray(b)) {
      return a.sort().toString() === b.sort().toString()
    }

    return undefined
  }

  /**
   * Execute a callback when an array of promises complete, regardless of
   * whether any throw an error.
   */
  onComplete(promises, cb) {
    Promise.all(promises).then(
      () => cb(),
      () => cb()
    )
  }

  /**
   * Returns a vizUrl, tokenizing it if a token is passed and immediately
   * invalidating it to prevent it from being used more than once.
   */
  getUrl(nextUrl) {
    const newUrl = nextUrl || this.props.url
    const { token, query } = this.props
    const parsed = url.parse(newUrl, true)

    if (!this.state.didInvalidateToken && token) {
      this.invalidateToken()
      return tokenizeUrl(newUrl, token) + query
    }

    let returnUrl = parsed.protocol + '//' + parsed.host + parsed.pathname + query
    returnUrl = returnUrl.replace('%20', '')
    returnUrl = returnUrl.replace('%20', '')
    returnUrl = returnUrl.replace('%20', '')
    returnUrl = returnUrl.replace('%20', '')
    returnUrl = returnUrl.replace('%20', '')
    returnUrl = returnUrl.replace('%20', '')
    returnUrl = returnUrl.replace('%20', '')
    returnUrl = returnUrl.replace('%20', '')
    returnUrl = returnUrl.replace('%20', '')
    console.log(returnUrl)

    return returnUrl
  }

  invalidateToken() {
    this.setState({ didInvalidateToken: true })
  }

  /**
   * Asynchronously applies filters to the worksheet, excluding those that have
   * already been applied, which is determined by checking against state.
   * @param  {Object} filters
   * @return {void}
   */
  applyFilters(filters) {
    const Tableau = window.tableau
    const REPLACE = Tableau.FilterUpdateType.REPLACE
    const promises = []

    this.setState({ loading: true })

    for (const key in filters) {
      if (
        !this.state.filters.hasOwnProperty(key) ||
        !this.compareArrays(this.state.filters[key], filters[key])
      ) {
        promises.push(this.sheet.applyFilterAsync(key, filters[key], REPLACE))
      }
    }

    this.onComplete(promises, () => this.setState({ loading: false, filters }))
  }

  applyParameters(parameters) {
    const promises = []

    for (const key in parameters) {
      if (
        !this.state.parameters.hasOwnProperty(key) ||
        this.state.parameters[key] !== parameters[key]
      ) {
        const val = parameters[key]
        // Ensure that parameters are applied only when we have a workbook
        if (this.workbook && this.workbook.changeParameterValueAsync) {
          promises.push(this.workbook.changeParameterValueAsync(key, val))
        }
      }
    }

    this.onComplete(promises, () => this.setState({ loading: false, parameters }))
  }

  /**
   * Initialize the viz via the Tableau JS API.
   * @return {void}
   */
  initTableau(nextUrl = null) {
    const { filters, parameters } = this.props
    const vizUrl = this.getUrl(nextUrl)
    console.log('init tableau viz')

    const options: tableau.VizCreateOptions = {
      ...filters,
      ...parameters,
      ...this.props.options,
      onFirstInteractive: () => {
        this.workbook = this.viz.getWorkbook()
        this.sheet = this.workbook.getActiveSheet()

        // If child sheets exist, choose them.
        const hasChildSheets = typeof this.sheet.getWorksheets !== 'undefined'
        if (hasChildSheets) {
          const childSheets = this.sheet.getWorksheets()

          if (childSheets && childSheets.length) {
            this.sheet = childSheets[0]
          }
        }

        this.props.onLoad && this.props.onLoad(new Date())
      },
    }

    // cleanup
    if (this.viz) {
      this.viz.dispose()
      this.viz = null
    }
    
    const Tableau = window.tableau
    if (!Tableau) {
      console.log('no tableau')
      return
    }
    this.viz = new Tableau.Viz(this.container, vizUrl, options)
    if (this.props.onVizLoaded) this.props.onVizLoaded(this.container, this.viz)
  }

  render() {
    return <div ref={(c) => (this.container = c)} />
  }
}

export default TableauReport
