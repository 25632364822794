import React, { useEffect } from 'react'
import { observer } from 'mobx-react'

interface IProps {
  url: string
  toggleLightBox: Function
}

const PulseQuestionRowImage: React.FC<IProps> = ({ url, toggleLightBox }) => {
  return <img className='media-image' src={url} onClick={() => toggleLightBox()} alt='' />
}
export default observer(PulseQuestionRowImage)
