import { RootStore } from '../../stores/RootStore'
import { observable, action, computed } from 'mobx'
import { TrainingItem } from '../aggregate/TrainingItem'
import { TrainingItemEditVM } from '../view-models/TrainingItemEditVM'
import { ITrainingItemDTO } from '../dto/ITrainingItemDTO'
import { DataStore } from '../../shared/data/DataStore'
import { EditExerciseDetailsDialogVM } from '../view-models/EditExerciseDetailsDialogVM'

export class TrainingItemsStore extends DataStore<TrainingItem, ITrainingItemDTO> {

  constructor(rootStore: RootStore) {
    super(rootStore, TrainingItem, 'trainingItems', [
      'organizationId',
      'name',
      'description',
      'categories',
      'contentItems',
      'applicableParticipants',
      'createdByUserId',
      'mustCompleteInOrder',
      'quiz',
      'requireProofOfCompletion',
      'requireQuiz',
      'requireCertificate'
    ])
    this.exerciseDetailsDialogVM = new EditExerciseDetailsDialogVM(this.rootStore)
  }
  
  @observable public trainingItemEditVM: TrainingItemEditVM = null
  @observable public exerciseDetailsDialogVM: EditExerciseDetailsDialogVM = null
  @observable public loaded: boolean = false

  @computed
  public get orgTrainingItems(): TrainingItem[] {
    return this.records
  }

  @action
  public onListRecordsLoaded() {
    this.loaded = true
  }

  @action
  public createNewTrainingItem(attempts: number = 0) {
    this.trainingItemEditVM = new TrainingItemEditVM(
      this.rootStore,
      TrainingItem.create(
        this.rootStore.appStore.currentOrgId,
        this.rootStore.appStore.currentUserId
      )
    )
  }

  public getTrainingItem(objectId): TrainingItem {
    return this.orgTrainingItems.find((trainingItem) => trainingItem.objectId === objectId)
  }

  public deleteTrainingItem(objectId) {
    this.orgTrainingItems.splice(this.getTrainingItemIndex(objectId), 1)
  }

  private getTrainingItemIndex(objectId): number {
    return this.orgTrainingItems.findIndex((e) => e.objectId === objectId)
  }

  @action
  public editTrainingItem(id: string, attempts: number = 0) {
    if (attempts > 10) console.log('Error finding training item to edit')
    const trainingItem = this.getTrainingItem(id)
    if (!trainingItem || trainingItem === undefined) {
      setTimeout(() => this.editTrainingItem(id, attempts++), 500)
    } else {
      this.trainingItemEditVM = new TrainingItemEditVM(this.rootStore, trainingItem.clone())
    }
  }

  @action
  public createItemFromCatalog(id: string, attempts: number = 0) {
    if (attempts > 10) return console.log('No Training Item Found')
    const foundTrainingItem = this.getTrainingItem(id)
    if (!foundTrainingItem) {
      setTimeout(() => this.createItemFromCatalog(id, attempts++), 100)
      return
    } else {
      const clonedItem = foundTrainingItem.clone()
      clonedItem.objectId = null
      clonedItem.name = clonedItem.name + ' (Copy)'
      clonedItem.createdByUserId = this.rootStore.appStore.currentUserId
      this.trainingItemEditVM = new TrainingItemEditVM(this.rootStore, clonedItem)
    }
  }

}
