import { RootStore } from '../../../../stores/RootStore'
import { observable, computed, action } from 'mobx'
import { TrainingPlanRowVM } from './TrainingPlanRowVM'

export class ManageTrainingsWidgetVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.setPlanRows('')
  }

  @observable public keywordFilter: string = ''
  @observable public deletingOrgId: string = ''
  @observable public sortType: string = 'Date'
  @observable public tabIndex: number = 0
  @observable public isSnackbarOpen: boolean
  @observable public snackbarMessage: string = ''
  @observable public trainingPlanRows: TrainingPlanRowVM[] = []

  @computed
  public get rows(): TrainingPlanRowVM[] {
    if (this.tabIndex === 0) return this.trainingPlanRows
    if (this.tabIndex === 1) return this.archivedRows
  }

  @action
  public setPlanRows(filter: string = '') {
    this.trainingPlanRows = this.rootStore.trainingPlansStore.orgTrainingPlans
      .filter(
        (trainingPlan) =>
          (trainingPlan.assignedByUserId === this.rootStore.appStore.currentUserId ||
            this.rootStore.appStore.isOrgAdmin ||
            this.rootStore.appStore.canCreateTrainings ||
            this.rootStore.appStore.canEditAllTrainings) &&
          !trainingPlan.isCatalogPlan &&
          !trainingPlan.isArchived &&
          trainingPlan.title.toLowerCase().includes(filter)
      )
      .map((e) => new TrainingPlanRowVM(this.rootStore, this, e))
      .sort((a, b) => {
        if (this.sortType === 'Name') return a.title < b.title ? -1 : 0
        if (this.sortType === 'Date') return a.dueDate > b.dueDate ? -1 : 0
      })
  }

  @computed
  public get archivedRows(): TrainingPlanRowVM[] {
    return this.rootStore.trainingPlansStore.orgTrainingPlans
      .filter(
        (trainingPlan) =>
          trainingPlan.assignedByUserId === this.rootStore.appStore.currentUserId ||
          this.rootStore.appStore.isOrgAdmin ||
          this.rootStore.appStore.canCreateTrainings ||
          this.rootStore.appStore.canEditAllTrainings
      )
      .filter((plan) => !plan.isCatalogPlan && plan.isArchived)
      .filter((plan) => plan.title.toLowerCase().includes(this.keywordFilter.toLowerCase()))
      .map((e) => new TrainingPlanRowVM(this.rootStore, this, e))
      .sort((a, b) => {
        if (this.sortType === 'Name') return a.title < b.title ? -1 : 0
        if (this.sortType === 'Date') return a.dueDate > b.dueDate ? -1 : 0
      })
  }

  @action
  public setTabIndex(num: number) {
    this.tabIndex = num
  }

  @action setSortType(type) {
    this.sortType = type
  }

  private filterTimeout = undefined
  @action setKeywordFilter(val) {
    // set to lowercase because the filter lowercases the searchable content
    this.keywordFilter = val
    if (this.filterTimeout) clearTimeout(this.filterTimeout)
    this.filterTimeout = setTimeout(() => this.setPlanRows(this.keywordFilter.toLowerCase()), 1000)
  }

  @action createNewTrainingPlan() {
    this.rootStore.appStore.router.push('/trainingPlans/edit/new')
  }

  @action
  public openSnackbar(msg) {
    this.snackbarMessage = msg
    this.isSnackbarOpen = true
  }

  @action
  public closeSnackbar() {
    this.isSnackbarOpen = false
  }
}
export default ManageTrainingsWidgetVM
