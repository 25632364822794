import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import MuiDrawerToolbar from '../../../shared/Mui-Drawer/MuiDrawerToolbar'
import { SurveyTagsStore } from '../../store/SurveyTagsStore'
import { LabelsStore } from 'src/app/labels/store/LabelsStore'

interface DrawerButtonControlsProps {
  localizationStore?: LocalizationStore
  surveyTagsStore?: SurveyTagsStore
  labelsStore?: LabelsStore
}

const DrawerButtonControls: React.FC<DrawerButtonControlsProps> = ({
  localizationStore,
  surveyTagsStore,
  labelsStore,
}) => {
  const { surveyTags: strings } = localizationStore.lzStrings
  const { widgetForm: vm } = surveyTagsStore

  return (
    <div>
      <MuiDrawerToolbar
        handleCancelClick={() => {
          surveyTagsStore.closeWidgetDrawer()
        }}
        handleSubmitClick={() => vm.saveSurveyTag()}
        showReset={Boolean(vm.objectId)}
        onConfirmReset={() => surveyTagsStore.setSelectedSurveyTagId(vm.objectId)}
        moreMenuProps={{
          hidden: !vm.objectId,
          isEditMode: true,
          itemType: labelsStore.getLabelByLanguageAndFor('pulse') + ' ' + labelsStore.getLabelByLanguageAndFor('tag'),
          itemName: vm.name,
          onConfirmDelete: () => vm.deleteSurveyTag(),
        }}
      />
    </div>
  )
}

export default inject(
  'localizationStore',
  'surveyTagsStore',
  'labelsStore'
)(observer(DrawerButtonControls))
