import React from 'react'
import { TextField, InputAdornment, Button, InputLabel } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import './MediaSelect.scss'
import { QuestionsStore } from '../store/QuestionsStore'

interface MediaSelectProps {
  questionsStore?: QuestionsStore
}
const MediaSelect: React.FC<MediaSelectProps> = ({ questionsStore }) => {
  const { editVM: vm } = questionsStore

  const button =
    vm.media && vm.media.objectId ? (
      <Button className='browse-button' variant='outlined' onClick={() => vm.removeMedia()}>
        Remove
      </Button>
    ) : (
      <Button className='browse-button' variant='outlined' onClick={() => vm.showMediaPanel()}>
        Browse
      </Button>
    )

  return (
    <section>
      <div id='MediaSelect'>
        <InputLabel className='question-form-input'>Media</InputLabel>
        <TextField
          onFocus={() => vm.showMediaPanel()}
          variant='outlined'
          type='text'
          placeholder='Add Media'
          margin='none'
          fullWidth
          value={vm.media.displayName}
          InputProps={{
            endAdornment: <InputAdornment position='end'>{button}</InputAdornment>,
          }}
        />
      </div>
    </section>
  )
}

export default inject('questionsStore')(observer(MediaSelect))
