import React from 'react'
import { inject, observer } from 'mobx-react'
import { Avatar, Button, Paper } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { PulseCampaignEditVM } from '../../view-models/PulseCampaignEditVM'
import './AddSurveyIntroductionButton.scss'
import LocalizationStore from '../../../localization/LocalizationStore'

interface Props {
  vm: PulseCampaignEditVM
  localizationStore?: LocalizationStore
}

const AddSurveyIntroductionButton: React.FC<Props> = ({ vm, localizationStore }) => {
  if (!vm) return null
  const { pulseCampaign: lz } = localizationStore.lzStrings

  const introClass =
    !vm.currentSurvey.surveyIntroductionValid && vm.publishTried
      ? 'add-intro-screen-create-btn intro-error'
      : 'add-intro-screen-create-btn'

  return (
    <div id='AddSurveyIntroductionButton' className={'add-intro-screen-group-paper'}>
      <Paper elevation={0} onClick={() => vm.openIntroScreenDialog()}>
        <Button className={introClass} color='primary' fullWidth>
          <Avatar className={'add-intro-screen-avatar'}>
            <AddIcon fontSize='small' />
          </Avatar>
          <p className={'add-intro-screen-label'}>{lz.add_intro_screen}</p>
        </Button>
      </Paper>
    </div>
  )
}

export default inject(
  'pulseCampaignsStore',
  'localizationStore'
)(observer(AddSurveyIntroductionButton))
