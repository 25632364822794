import { computed, observable, action, IReactionDisposer, reaction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import PulseCategory from '../../pulse-categories/aggregate/PulseCategory'
import { QuestionVM } from '../../surveys/view-models/QuestionVM'
import { PulseCategoriesStore } from '../../pulse-categories/store/PulseCategoriesStore'

interface PulseCategoryForPicker {
  id: string
  name: string
  parentId: string
  label: string
  value: string
  checked: boolean
  expanded?: boolean
  grandCategoryId?: string
}

export class PulseCategoryPickerVM {
  private rootStore: RootStore
  private withSelectAll: boolean = false
  private withNoCategoryOption: boolean = false
  private dataStore: PulseCategoriesStore
  private onChange: Function
  private reactions: IReactionDisposer[] = []

  constructor(
    rootStore: RootStore,
    onChange?: Function,
    value?: string,
    withSelectAll?: boolean,
    selectAllByDefault?: boolean,
    withNoCategoryOption?: boolean,
    categoryIdsToHide?: Array<string>,
    applyAllByDefault?: boolean
  ) {
    if (onChange) this.onChange = onChange

    this.rootStore = rootStore
    this.withSelectAll = Boolean(withSelectAll)
    if (selectAllByDefault) {
      this.selectAllChecked = Boolean(selectAllByDefault)
      setTimeout(() => {
        this.selectedCategoryIds = this.validCategories.map((cat) => cat.objectId)
        if (withNoCategoryOption && selectAllByDefault) this.selectedCategoryIds.push('no-category')
        if (applyAllByDefault) this.setAppliedCategories()
      }, 2000)
    }
    this.withNoCategoryOption = Boolean(withNoCategoryOption)

    if (withNoCategoryOption && selectAllByDefault)
      if (categoryIdsToHide) setTimeout(() => this.selectedCategoryIds.push('no-category'), 2000)
    this.categoryIdsToHide = categoryIdsToHide

    this.loadReactions(value)

    this.dataStore = rootStore.pulseCategoriesStore
    if (!this.dataStore.isLoaded) {
      this.loadDataStore()
    } else {
      if (value) this.loadSelectedCategoryId(value)
    }
  }

  @observable public showPicker: boolean = false
  @observable public appliedCategoryId: string = ''
  @observable public selectedCategoryId: string = ''
  @observable public selectedCategoryIds: Array<string> = []
  @observable public appliedCategoryIds: string[] = []
  @observable public expandedCategoryIds: Array<string> = []
  @observable public currentQuestionVM: QuestionVM = null
  @observable public selectAllChecked: boolean = false
  @observable public categoryIdsToHide: Array<string> = []

  private loadReactions(value?: string) {
    this.reactions.forEach((dispose: IReactionDisposer) => dispose())

    const disposer = reaction(
      () => this.dataStore.isLoaded,
      () => {
        if (value) {
          this.loadSelectedCategoryId(value)
        }
        disposer()
      }
    )
    this.reactions.push(disposer)
  }

  @action
  private loadDataStore() {
    this.dataStore.loadListRecords()
  }

  @action
  public setCategoryIdsToHide(ids: Array<string>) {
    this.categoryIdsToHide = ids
  }

  @action
  public setCurrentQuestionVM(questionVM: QuestionVM) {
    this.currentQuestionVM = questionVM
  }

  @computed
  public get appliedCategory() {
    if (this.currentQuestionVM) {
      const foundCat = this.validCategories.find(
        (cat) => cat.objectId === this.currentQuestionVM.pulseCategoryId
      )
      if (foundCat) return foundCat
      else return null
    }
    if (this.appliedCategoryId) {
      const foundCat = this.validCategories.find((cat) => cat.objectId === this.appliedCategoryId)
      if (foundCat) return foundCat
    }
    return null
  }

  @computed
  public get appliedCategories() {
    let categoryNames = []
    this.appliedCategoryIds.forEach((id) => {
      const foundCat = this.validCategories.find((cat) => cat.objectId === id)
      if (foundCat) categoryNames.push({ id: id, name: foundCat.name })
    })

    return categoryNames
  }

  @computed
  public get appliedCategoryName() {
    if (this.appliedCategory) return this.appliedCategory.name
    else return `No ${this.categoryLabel} Selected`
  }

  @computed
  public get categoryLabel() {
    const label = `${this.rootStore.labelsStore.getLabelByLanguageAndFor(
      'pulse'
    )} ${this.rootStore.labelsStore.getLabelByLanguageAndFor('category')}`
    if (label) return label
    return 'Pulse Category'
  }

  @action
  public loadSelectedCategoryId(id) {
    if (this.selectedCategoryId !== id) {
      const deselectNode = this.formattedCategories.filter(
        (cat) => cat.id === this.selectedCategoryId
      )[0]
      if (deselectNode) {
        deselectNode.checked = false
        this.selectedCategoryIds = this.selectedCategoryIds.filter((id) => id !== deselectNode.id)
      }
    }

    this.selectedCategoryId = id
    const node = this.formattedCategories.filter((cat) => cat.id === id)[0]
    if (node) {
      if (!node.grandCategoryId || !node.parentId) {
        this.expandedCategoryIds.push(id)
        node.expanded = true
      }
      node.checked = true
      this.handleSelectOne(node, false)

      if (node.parentId) {
        const foundParent = this.formattedCategories.filter((cat) => cat.id === node.parentId)[0]
        if (foundParent) {
          foundParent.expanded = true
          this.expandedCategoryIds.push(foundParent.id)

          if (foundParent.grandCategoryId) {
            const foundGrandParent = this.formattedCategories.filter(
              (cat) => cat.id === foundParent.grandCategoryId
            )[0]
            if (foundGrandParent) {
              foundGrandParent.expanded = true
              this.expandedCategoryIds.push(foundGrandParent.id)
            }
          }
        }
      }
    }

    if (this.onChange) this.onChange(id)
  }

  @action
  public setSelectedCategoryId(id) {
    if (this.selectedCategoryId !== id) {
      const deselectNode = this.formattedCategories.filter(
        (cat) => cat.id === this.selectedCategoryId
      )[0]
      if (deselectNode) {
        deselectNode.checked = false
        this.selectedCategoryIds = this.selectedCategoryIds.filter((id) => id !== deselectNode.id)
      }
    }

    this.selectedCategoryId = id
    const node = this.formattedCategories.filter((cat) => cat.id === id)[0]
    if (node) {

      if (!node.grandCategoryId || !node.parentId) {
        this.expandedCategoryIds.push(id)
        node.expanded = true
      }
      node.checked = true
      this.handleSelectOne(node, false)
    }

    if (this.onChange) this.onChange(id)
  }

  @action
  public setAppliedCategory(id) {
    this.appliedCategoryId = id
  }

  @action
  public toggleShowPicker() {
    this.showPicker = !this.showPicker
  }

  @action
  public setAppliedCategories() {
    this.appliedCategoryIds = this.selectedCategoryIds.slice()
    if (this.onChange) this.onChange(this.selectedCategoryIds.slice())
  }

  @action
  public handleSelectAll(shouldSelectAll: boolean) {
    this.selectAllChecked = shouldSelectAll
    if (shouldSelectAll) {
      this.selectedCategoryIds = []
      return
    }
  }

  @action
  public removeCategory(id: string) {
    let foundCat = this.formattedCategories.find((cat) => cat.id === id)
    foundCat.checked = false
    this.handleSelectOne(foundCat, false)
    this.setAppliedCategories()
  }

  @action
  public selectAllChildren(selected) {
    const selectChildren = (children) => {
      children.forEach((child) => {
        const foundCategory = this.validCategories.find((cat) => cat.objectId === child)
        if (foundCategory) this.selectedCategoryIds.push(foundCategory.objectId)
        // if (foundCategory.children.length)
        //   selectChildren(foundCategory.children.map((child) => child.objectId))
      })
    }
    if (selected._children.length) selectChildren(selected._children)
  }

  @action
  public deselectAllChildren(selected) {
    const deselectChildren = (children) => {
      children.forEach((child) => {
        const foundCategory = this.validCategories.find((cat) => cat.objectId === child)
        if (foundCategory)
          this.selectedCategoryIds = this.selectedCategoryIds.filter(
            (id) => id !== foundCategory.objectId
          )
        // if (foundCategory.children.length)
        //   deselectChildren(foundCategory.children.map((child) => child.objectId))
      })
    }
    if (selected._children.length) deselectChildren(selected._children)
  }

  @action
  public handleSelectOne(selected, selectAll) {
    if (selected.id === 'select-all') return this.handleSelectAll(selected.checked)
    if (selected.checked) {
      this.selectedCategoryIds.push(selected.id)
      if (Array.isArray(selected._children) && selectAll) return this.selectAllChildren(selected)
    }
    if (!selected.checked) {
      this.selectedCategoryIds = this.selectedCategoryIds.filter((id) => id !== selected.id)
    }
    this.selectAllChecked = false
    if (Array.isArray(selected._children)) return this.deselectAllChildren(selected)
  }

  @action
  public handleChange(selected) {
    if (selected.id === 'select-all') return this.handleSelectAll(selected.checked)
    this.handleSelectOne(selected, true)
  }

  @computed
  public get validCategories(): PulseCategory[] {
    return this.dataStore.pulseCategories.slice().filter((cat) => !cat.isDeleted)
  }

  @action
  public handleNodeToggle(node) {
    if (node.expanded) {
      this.expandedCategoryIds.push(node.id)
    } else {
      // collapse node and its children (looks wonky if children allowed to stay expanded)
      this.expandedCategoryIds = this.expandedCategoryIds.filter((id) => id !== node.id)
      if (node && node._children && node._children.length) {
        node._children.forEach((id) => {
          this.handleNodeToggle({ id, expanded: false })
        })
      }
    }
  }

  @computed
  public get formattedCategories() {
    let cats: Array<PulseCategoryForPicker> = this.validCategories
      .sort((a: PulseCategory, b: PulseCategory) => {
        const nameA = a.name.toLowerCase()
        const nameB = b.name.toLowerCase()
        if (nameA < nameB) return -1
        if (nameB < nameA) return 1
        return 0
      })
      .map((e: PulseCategory) => ({
        id: e.objectId,
        name: e.name,
        parentId: e.parentCategoryId,
        grandCategoryId: e.grandCategoryId,
        label: e.name,
        value: e.objectId,
        checked: this.selectAllChecked ? false : this.selectedCategoryIds.includes(e.objectId),
        expanded: this.expandedCategoryIds.includes(e.objectId),
      }))

    if (this.categoryIdsToHide && this.categoryIdsToHide.length > 0) {
      cats = cats.filter((cat) => !this.categoryIdsToHide.includes(cat.id))
    }

    if (this.withNoCategoryOption) {
      const label = this.categoryLabel[0].toUpperCase() + this.categoryLabel.slice(1)

      const noCategory = {
        id: 'no-category',
        name: `No ${label}`,
        parentId: null,
        grandCategoryId: null,
        label: `No ${label}`,
        value: 'no-category',
        checked: this.selectAllChecked ? false : this.selectedCategoryIds.includes('no-category'),
      }

      cats.unshift(noCategory)
    }

    if (this.withSelectAll) {
      const selectAll = {
        id: 'select-all',
        name: 'Select All',
        parentId: null,
        grandCategoryId: null,
        label: 'Select All',
        value: 'select-all',
        checked: this.selectAllChecked,
      }

      cats.unshift(selectAll)
    }

    if (this.selectAllChecked)
      cats.forEach((cat) => {
        cat.checked = true
      })

    return cats
  }

  private listToTree(list) {
    const map = {}
    const roots = []
    for (let i = 0; i < list.length; i++) {
      map[list[i].id] = i
      list[i].children = []
    }

    //populate grand (root) and parent levels
    for (let i = 0; i < list.length; i++) {
      const node = list[i]
      if (node.grandCategoryId) {
        if (!node.parentId) {
          list[map[node.grandCategoryId]].children.push(node)
        }
      } else {
        roots.push(node)
      }
    }

    //populate children level
    for (let i = 0; i < list.length; i++) {
      const node = list[i]

      if (node.grandCategoryId) {
        if (node.parentId) {
          const foundParent = list[map[node.grandCategoryId]].children.find(
            (c) => c.id === node.parentId
          )
          if (foundParent) {
            foundParent.children.push(node)
          }
        }
      }
    }
    return roots
  }

  @computed
  public get data() {
    const tree = this.listToTree(this.formattedCategories)
    return tree
  }
}
