import React from 'react'
import { Grid } from '@material-ui/core'
import { observer, inject } from 'mobx-react'
import { WorksheetsStore } from '../../store/WorksheetsStore'
import TableauReportContainer from './TableauReportContainer'
import './WorkbookTabPanel.scss'
import SettingsPane from './SettingsPane'

interface Props {
  worksheetsStore?: WorksheetsStore
}

const WorkbookTabPanel: React.FC<Props> = ({ worksheetsStore }) => {
  const { editVM } = worksheetsStore
  if (!editVM) return null

  return (
    <Grid container id='WorkbookTabPanel' spacing={0} direction='column'>
      <SettingsPane />
      <TableauReportContainer />
    </Grid>
  )
}

export default inject('worksheetsStore')(observer(WorkbookTabPanel))
