import React from 'react'
import { observer, inject } from 'mobx-react'
import { Grid, IconButton, DialogTitle } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/DeleteRounded'
import LocalizationStore from '../../../localization/LocalizationStore'
import './CMSItemsPickerDialogHeader.scss'

interface Props {
  localizationStore?: LocalizationStore
  title: string
  onClose: Function
  onDelete?: Function
}

const CMSItemsPickerDialogHeader: React.FC<Props> = ({
  title,
  onClose,
  onDelete,
  localizationStore,
}) => {

  const renderDeleteButton = () => {
    if (!onDelete) return
    return (
      <Grid item xs={1}>
        <IconButton className={'dialog-header-delete'} onClick={() => onDelete()}>
          <DeleteIcon />
        </IconButton>
      </Grid>
    )
  }

  const renderCloseButton = () => {
    return (
      <Grid item xs={1}>
        <IconButton className={'dialog-header-close'} onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      </Grid>
    )
  }

  return (
    <div id='ContentPickerDialogHeader'>
      <Grid className='dialog-header-grid' container alignItems='center'>
        <Grid item xs={10}>
          <DialogTitle className={'dialog-header-title'} disableTypography>
            {title}
          </DialogTitle>
        </Grid>
        {renderDeleteButton()}
        {renderCloseButton()}
      </Grid>
    </div>
  )
}

export default inject('localizationStore')(observer(CMSItemsPickerDialogHeader))
