import React from 'react'
import { inject, observer } from 'mobx-react'
import { NotificationsStore } from '../store/NotificationsStore'
import LocalizationStore from '../../localization/LocalizationStore'
import MuiConfirmDialog from '../../shared/MuiConfirmDialog'
import { Button } from '@material-ui/core'
import NotificationTemplateConfirmDialog from '../views/NotificationTemplateConfirmDialog'
import './DrawerDefaultButton.scss'

interface DrawerDefaultButtonProps {
  localizationStore?: LocalizationStore
  notificationsStore?: NotificationsStore
}

const DrawerDefaultButton: React.FC<DrawerDefaultButtonProps> = ({
  localizationStore,
  notificationsStore,
}) => {
  const { lzStrings } = localizationStore
  const { editVM } = notificationsStore

  const handleReset = () => {
    editVM.isSaving = true
    editVM.getDefaultNotification().then(() => {
      editVM.closeEditor()
      editVM.isSaving = false
    })
    editVM.toggleResetDialog()
  }

  const resetDialog = () => {
    return (
      <MuiConfirmDialog
        confirmMessage={
          'Are you sure you want to reset the notification template? Changes cannot be undone.'
        }
        onConfirm={handleReset}
        open={editVM.resetDialog}
        onClose={() => editVM.toggleResetDialog()}
      />
    )
  }

  const renderConfirmDialog = () => {
    return <NotificationTemplateConfirmDialog vm={editVM} />
  }

  const renderResetButton = () => {
    if (editVM.isDefault) return
    return (
      <Button
        className='default-button'
        variant='outlined'
        disabled={editVM.isDefault || editVM.isSaving}
        onClick={() => editVM.toggleResetDialog()}
      >
        {lzStrings.notificationsWidgetDrawer.default}
      </Button>
    )
  }

  return (
    <div id='DrawerDefaultButton'>
      {renderResetButton()}
      {resetDialog()}
      {renderConfirmDialog()}
    </div>
  )
}

export default inject('localizationStore', 'notificationsStore')(observer(DrawerDefaultButton))
