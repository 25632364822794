import React from 'react'
import WidgetHeader from './WidgetHeader'
import WidgetContent from './WidgetContent'
import { Card, Button } from '@material-ui/core'
import DoneContent from './DoneContent'
import './UserSurveyTakeWidget.scss'
import { inject, observer } from 'mobx-react'
import { UserSurveysStore } from '../../store/UserSurveysStore'
import { UserSurveyTakeVM } from '../../view-models/UserSurveyTakeVM'
import { UsersStore } from 'src/app/users/store/UsersStore'
import Avatar from '../../../shared/Avatar'
import { AppStore } from 'src/app/stores/AppStore'
import Switch from '@material-ui/core/Switch'
import { DatePicker } from '@material-ui/pickers'
import TimePicker from '../../../shared/time-picker/TimePicker'
import { AudienceMember } from '../../../audience-members/aggregate/AudienceMember'

interface Props {
  takeVM: UserSurveyTakeVM
  userSurveysStore?: UserSurveysStore
  disabled?: boolean
  userName?: string
  appStore?: AppStore
  usersStore?: UsersStore
}

const UserSurveyTakeWidget: React.FC<Props> = ({
  userSurveysStore,
  disabled,
  takeVM,
  userName,
}) => {
  if (!takeVM && !userSurveysStore.anonymousSurveyNotFound) return null

  const renderPaperSurveyInfo = () => {
    if (!takeVM) return null
    const userSurvey = takeVM.userSurvey
    const currentUser = takeVM.currentUser
    const paperSurveyForUser = takeVM.paperSurveyForUser
    if (!userSurvey) return null
    if (!userSurvey.isPaperSurvey) return null
    if (!currentUser) return null
    if (!paperSurveyForUser) return null

    const renderUserCard = (user: AudienceMember) => {
      return (
        <div className='paper-survey-info-user-card'>
          <Avatar user={user} />
          <div>{user.name}</div>
        </div>
      )
    }

    const renderDateTimePickers = () => {
      if (!takeVM.showResponseDateTimePickers) return null
      return (
        <div className='paper-survey-response-date-time-pickers-container'>
          <DatePicker
            id='responseDate'
            variant='inline'
            inputVariant='outlined'
            margin='none'
            fullWidth
            autoOk
            helperText={null}
            value={takeVM.paperSurveyResponseDate}
            error={false}
            onChange={(e) => takeVM.setPaperSurveyResponseDate(e)}
            format='MMM dd, yyyy'
          />
          <TimePicker
            value={takeVM.paperSurveyResponseDate}
            onChange={(hr, min) => takeVM.setPaperSurveyResponseTime(hr, min)}
            error={false}
          />
        </div>
      )
    }

    return (
      <Card id='PaperSurveyInfoCard' elevation={1}>
        <div className='paper-survey-info-top'>
          <div>{renderUserCard(currentUser)}</div>
          <div className='inputting-response-for-div'>Inputting Response For</div>
          <div>{renderUserCard(paperSurveyForUser)}</div>
        </div>
        <div className='paper-survey-info-bottom'>
          <div>
            <Switch
              checked={takeVM.showResponseDateTimePickers}
              onChange={() => takeVM.toggleShowResponseDateTimePickers()}
            />
            Specify Response Date and Time
          </div>
          {renderDateTimePickers()}
        </div>
      </Card>
    )
  }

  return (
    <>
      {renderPaperSurveyInfo()}
      <Card id='UserSurveyTakeWidget' elevation={1} key={takeVM ? takeVM.key : 'tempkey'}>
        <WidgetHeader takeVM={takeVM} disabled={disabled} userName={userName} />
        <WidgetContent takeVM={takeVM} disabled={disabled} />
        <DoneContent takeVM={takeVM} />
      </Card>
    </>
  )
}

export default inject('userSurveysStore')(observer(UserSurveyTakeWidget))
