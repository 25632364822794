import { Button, DialogTitle, Grid, Typography } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import ImageIcon from '@material-ui/icons/Image'
import './PreviewGoal.scss'
import { TrainingPlansStore } from '../../../../training-plans/store/TrainingPlansStore'

interface Props {
  trainingPlansStore?: TrainingPlansStore
}

const PreviewGoal: React.FC<Props> = ({ trainingPlansStore }) => {
  const { goalToPreview: vm } = trainingPlansStore.trainingPlanEditVM
  if (!vm) return null

  const renderThumbnail = () => {
    if (!vm.thumbnail) {
      return (
        <div className='preview-thumbnail'>
          <ImageIcon />
        </div>
      )
    } else {
      return <img className='preview-thumbnail' src={vm.thumbnail} />
    }
  }

  return (
    <div id='GoalPreview'>
      <Grid container id='PreviewHeader'>
        <Typography className="title">Goal Preview:</Typography>
        <Grid item xs={12} className='preview-overview'>
          <Grid container>
            <Grid item xs={12}>
            </Grid>
            <Grid item xs={4} className='preview-thumbnail-container'>
              {renderThumbnail()}
            </Grid>
            <Grid item xs={8} className='preview-details'>
              <div>
                <div className='preview-title'>
                  <Typography>GOAL</Typography>
                </div>
                <Typography variant='h6'>{vm.name}</Typography>
                <Typography>{vm.description}</Typography>
              </div>
              <div>
                <div className='preview-category'>{vm.category}</div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default inject('trainingPlansStore')(observer(PreviewGoal))
