import React from 'react'
import { Route } from 'react-router'
import SurveyResultsScreen from 'src/app/survey-results/views/SurveyResultsScreen'
import UserSurveyTakeScreen from '../../user-surveys/views/take/UserSurveyTakeScreen'

const getPublicRoutes = () => (
  <Route key='public-route' path='public'>
    <Route key='p1' path='take-survey/:anonymousToken' component={UserSurveyTakeScreen} />
    <Route key='p2' path='take-survey/:anonymousToken/:orgId' component={UserSurveyTakeScreen} />
    <Route key='p3' path='survey-results/:anonymousToken' component={SurveyResultsScreen} />
  </Route>
)

export default getPublicRoutes
