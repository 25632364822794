import React from 'react'
import {
  ListItem,
  Grid,
  Typography,
  DialogActions,
  Button,
  DialogTitle,
  IconButton,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import './WeightProfileImportRow.scss'
import VirtualList from 'react-tiny-virtual-list'
import ExportRoleRow from './ExportRoleRow'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import CloseIcon from '@material-ui/icons/Close'
import { GroupsAndRolesStore } from 'src/app/groups-roles-widget/GroupsAndRolesStore'

interface Props {
  groupsAndRolesStore?: GroupsAndRolesStore
  localizationStore?: LocalizationStore
}

const ExportScreen: React.FC<Props> = ({ groupsAndRolesStore, localizationStore }) => {
  const { importVM } = groupsAndRolesStore.groupsAndRolesWidgetVM
  if (!importVM) return null
  const vm = importVM.exportVM
  if (!vm) return null
  const { lzStrings } = localizationStore

  const renderHeader = () => {
    return (
      <Grid
        className='dialog-title'
        container
        justifyContent='space-between'
        alignItems='center'
        style={{ paddingRight: '10px' }}
      >
        <Grid item>
          <DialogTitle>Export</DialogTitle>
        </Grid>
        <Grid item>
          <IconButton
            onClick={() => groupsAndRolesStore.groupsAndRolesWidgetVM.toggleImportModal()}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    )
  }

  const renderRolesList = () => {
    return (
      <Grid className={'dialog-body'}>
        <VirtualList
          className='roles-export-list'
          height={200}
          width='100%'
          itemCount={vm.roles.length}
          overscanCount={20}
          itemSize={(index) => vm.rowGetter(index)}
          renderItem={({ style, index }) => {
            const row = vm.roles[index]
            return <ExportRoleRow key={index} row={row} style={style} />
          }}
        />
      </Grid>
    )
  }

  const renderActions = () => {
    return (
      <DialogActions>
        <Grid container className='dialog-actions' alignItems='center' justifyContent='space-between'>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={vm.allSelected}
                  onChange={() => vm.toggleAllSelected()}
                  name='all-selected'
                  color='primary'
                />
              }
              label='Select All'
            />
          </Grid>
          <Grid item>
            <Button
              variant='text'
              size='large'
              onClick={() => importVM.toggleExportScreen()}
              className='cancelButton'
            >
              {lzStrings.contact_import.cancel}
            </Button>
            <Button
              variant='contained'
              size='large'
              onClick={() => vm.downloadTemplate()}
              disabled={!vm.exportValid}
              className='saveButton'
            >
              {lzStrings.importModal.downloadTemplate}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    )
  }

  return (
    <>
      {renderHeader()}
      {renderRolesList()}
      {renderActions()}
    </>
  )
}

export default inject('groupsAndRolesStore', 'localizationStore')(observer(ExportScreen))
