import React from 'react'
import {
  Dialog,
  DialogTitle,
  IconButton,
  Grid,
  DialogContent,
  Button,
  Typography,
  DialogActions,
} from '@material-ui/core'
import { observer, inject } from 'mobx-react'
import LocalizationStore from '../localization/LocalizationStore'
import SendRoundedIcon from '@material-ui/icons/SendRounded'
import './MuiConfirmPrompt.scss'
import SendNotificationSVG from '../../assets/img/send-notification.svg'

interface Props {
  localizationStore?: LocalizationStore
  confirmMessage: any
  confirmMessageSecondary?: any
  onClose: any
  open: any
  onConfirm: any
  title: string
  icon?: any
  disabled?: any
  cancelFullWidth?: boolean
  isSystemWarn?: boolean
}

const MuiConfirmPrompt: React.FC<Props> = ({
  localizationStore,
  confirmMessage,
  confirmMessageSecondary,
  onClose,
  onConfirm,
  open,
  title,
  icon,
  disabled,
  cancelFullWidth,
  isSystemWarn = false
}) => {
  const renderIcon = () => {
    if (icon) {
      return icon
    } else {
      return <img className={'send-image'} src={SendNotificationSVG} alt='' />
    }
  }

  const renderButtonIcon = () => {
    if (icon) {
      return null
    } else {
      return <SendRoundedIcon className='send-icon-btn' />
    }
  }

  const renderSecondaryMessage = () => {
    if (!confirmMessageSecondary) return null
    return (
      <p className='cannot-undo-text'>
        <span className={'note-span'}>Note:</span> {confirmMessageSecondary}
      </p>
    )
  }

  const renderSystemWarningMessage = () => {
    if (!isSystemWarn) return
    return (
      <p className='confirm-message-strong'>Changes will be system wide!</p>
    )
  }

  return (
    <Dialog
      id={'MuiConfirmPrompt'}
      onClose={onClose}
      open={open}
      fullWidth={cancelFullWidth ? false : true}
    >
      <DialogTitle className={`dialog-title ${isSystemWarn ? 'strong' : ''}`}>{title}</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item md={1} sm={1}>
            {renderIcon()}
          </Grid>
          <Grid item md={11} sm={11} className='confirm-message-view'>
            {renderSystemWarningMessage()}
            <p className='confirm-message'>{confirmMessage}</p>
            {renderSecondaryMessage()}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onClose} disabled={disabled}>
          No, Nevermind
        </Button>
        <Button variant='contained' onClick={onConfirm} disabled={disabled}>
          {renderButtonIcon()} Yes, Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default MuiConfirmPrompt
