import React from 'react'
import { CardContent, Grid, Typography } from '@material-ui/core'
import CategoryIcon from '@material-ui/icons/Category'
import PersonIcon from '@material-ui/icons/Person'
import AlarmIcon from '@material-ui/icons/Alarm'
import { observer, inject } from 'mobx-react'
import { EventsStore } from '../../../store/EventsStore'

interface IUserTaskPopupBody {
  eventsStore?: EventsStore
}

const UserTaskPopupBody: React.FC<IUserTaskPopupBody> = ({ eventsStore }) => {
  const { taskPopupVM: vm } = eventsStore
  if (!vm) return null

  const renderCategory = () => {
    if (!vm.allowDiscipline) return null
    return (
      <Grid
        item
        xs={12}
        container
        spacing={0}
        alignItems='flex-start'
        className='user-task-popup-category-grid'
      >
        <Grid item xs={1} container justifyContent='center'>
          <CategoryIcon className='icon' />
        </Grid>
        {vm.category}
      </Grid>
    )
  }

  return (
    <CardContent>
      <Grid item xs={12} className='user-task-popup-title-grid'>
        <Typography variant='h5'>{vm.name}</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        container
        spacing={0}
        alignItems='flex-start'
        className='user-task-popup-due-grid'
      >
        <Grid item xs={1} container justifyContent='center'>
          <AlarmIcon className='icon' />
        </Grid>
        Due: {vm.dueDate}
      </Grid>
      {renderCategory()}
      <Grid
        item
        xs={12}
        container
        spacing={0}
        alignItems='flex-start'
        className='user-task-popup-assigned-by-grid'
      >
        <Grid item xs={1} container justifyContent='center'>
          <PersonIcon className='icon' />
        </Grid>
        Assigned By: {vm.assignedBy}
      </Grid>
    </CardContent>
  )
}

export default inject('eventsStore')(observer(UserTaskPopupBody))
