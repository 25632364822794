import { computed } from 'mobx'
import { IUserAnnouncementEventDTO } from '../dtos/UserAnnouncementEventDTO'
import moment from 'moment'

export class UserAnnouncementEventRowVM {
  private userAnnouncementEvent: IUserAnnouncementEventDTO
  constructor(userSurveyEvent: IUserAnnouncementEventDTO) {
    this.userAnnouncementEvent = userSurveyEvent
  }

  private blankValue = '-'

  @computed
  public get eventType() {
    return this.userAnnouncementEvent.eventType
  }

  @computed
  public get eventSource() {
    return this.userAnnouncementEvent.eventSource
  }

  private formatDate(isoString: string) {
    if (!isoString) return this.blankValue
    return moment(isoString).format('lll')
  }

  @computed
  public get eventDateTime() {
    return this.formatDate(this.userAnnouncementEvent.eventDateTime)
  }

  @computed
  public get additionalInfo() {
    if (this.userAnnouncementEvent.additionalInfo) {
      return this.userAnnouncementEvent.additionalInfo.reason
    }
    return '-'
  }

  @computed
  public get objectId() {
    return this.userAnnouncementEvent.objectId
  }
}
