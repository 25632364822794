import { action, computed, observable } from 'mobx'
import { RootStore } from '../../../../stores/RootStore'
import { IPulseCampaignFindRequest } from '../../../interfaces/IPulseCampaignFindRequest'
import usePagination from '../../../views/summary/Pagination'
import { CampaignDetailsVM } from '../campaign-details/CampaignDetailsVM'
import { AllCampaignsCardVM } from './AllCampaignsCardVM'
import { AllCampaignsDataStore } from './AllCampaignsDataStore'
import { CampaignRowVM } from './CampaignRowVM'

export class CampaignsListVM {
  private rootStore: RootStore
  public dataStore: AllCampaignsDataStore

  constructor(rootStore: RootStore, parentVM: AllCampaignsCardVM) {
    this.rootStore = rootStore
    this.parentVM = parentVM
    this.dataStore = new AllCampaignsDataStore(this.rootStore, {
      organizationId: this.rootStore.appStore.currentOrgId,
      status: 'initiated',
    } as IPulseCampaignFindRequest)
    this.loadStore()
  }

  @observable public page: number = 1
  @observable public PER_PAGE: number = 11
  @observable public parentVM: AllCampaignsCardVM = null
  @observable public detailsVM: CampaignDetailsVM = null
  @observable public detailsOpen: boolean = false

  @computed
  public get hasRows(): boolean {
    return this.dataStore.hasListRecords
  }

  @computed
  public get lzStrings() {
    return this.rootStore.localizationStore.lzStrings.pulseCampaign
  }

  @computed
  public get campaigns(): CampaignRowVM[] {
    return this.dataStore.rows.map((c) => new CampaignRowVM(this.rootStore, c, this))
  }

  @computed
  public get _DATA() {
    return usePagination(this.campaigns, this.PER_PAGE)
  }

  @action
  public loadStore() {
    this.dataStore.loadListRecords()
  }

  @computed
  public get count(): number {
    return this.campaigns.length > 0 ? Math.ceil(this.campaigns.length / this.PER_PAGE) : 1
  }

  @computed
  public get sortName() {
    return this.dataStore.sortColumnName
  }

  @computed
  public get sortDirection() {
    return this.dataStore.sortDirection
  }

  @action
  public setSort(name: string) {
    let direction: 'asc' | 'desc' = 'asc'
    if (this.sortName === name) {
      if (this.sortDirection === 'asc') direction = 'desc'
    }
    this.dataStore.sortColumnName = name
    this.dataStore.sortDirection = direction
    this.loadStore()
  }

  @action
  public hanglePageChange(e, page: number) {
    this.setPage(page)
    this._DATA.jump(page)
    if (this.page > this._DATA.getMaxPage()) this.hanglePageChange({}, this._DATA.getMaxPage())
  }

  @action
  public setPage(page: number) {
    this.page = page
  }

  @action
  public setPaginationPerPage(PER_PAGE: number) {
    this.PER_PAGE = PER_PAGE
  }

  @action
  public openDetailsModal(objectId, index?) {
    this.detailsVM = new CampaignDetailsVM(this.rootStore, objectId, this, index)
    this.detailsOpen = true
  }

  @action
  public closeDetailsModal() {
    this.detailsVM = null
    this.detailsOpen = false
  }

  @computed
  public get activeSurveyIndex(): number {
    if (!this.detailsVM) return null
    return this.detailsVM.activeSurveyIndex
  }
}
