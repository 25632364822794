import React, { useEffect, useLayoutEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { Box, Card, CardContent, Tab, Tabs, Typography } from '@material-ui/core'
import LocalizationStore from '../../../localization/LocalizationStore'
import MuiDrawerHeader from '../../../shared/Mui-Drawer/MuiDrawerHeader'
import MuiDrawerToolbar from '../../../shared/Mui-Drawer/MuiDrawerToolbar'
import SpinnerOverlay from '../../../shared/SpinnerOverlay'
import './OrganizationUserEditDrawer.scss'
import clsx from 'clsx'
import Drawer from '@material-ui/core/Drawer'
import { OrganizationUsersStore } from '../../store/OrganizationUsersStore'
import DrawerTabPanel from '../../../shared/DrawerTabPanel'
import UserInfoTab from './UserInfoTab'
import UserOrganizationTab from './UserOrganizationTab'
import UserAudienceTab from './UserAudienceTab'
import UserPrivilegeSetsTab from './UserPrivilegeSetsTab'
import PasswordSnackbar from '../password-snackbar/PasswordSnackbar'
import StatusTab from './StatusTab'

interface Props {
  organizationUsersStore?: OrganizationUsersStore
  localizationStore?: LocalizationStore
}

const OrganizationUserEditDrawer: React.FC<Props> = ({
  organizationUsersStore,
  localizationStore,
}) => {
  const s = localizationStore.lzStrings
  const vm = organizationUsersStore.editVM

  useLayoutEffect(() => {
    if (!vm) return
    vm.setVirtualListParent('tabBody')
  }, [])

  if (!vm) return null

  const handleChange = (e: any, newValue: number) => {
    vm.setCurrentTab(newValue)
  }

  const handleCancelClick = (e) => {
    e.preventDefault()
    vm.hideDrawer()
  }

  const renderOrganizationTab = () => {
    if (!vm.isEditable) return null
    return <Tab value={1} label={'Org'} />
  }

  const renderHeaderAndTabs = () => {
    if (vm.addAudienceTabShown) return null
    return (
      <>
        <MuiDrawerToolbar
          disabled={vm.isProcessing}
          handleCancelClick={(e) => handleCancelClick(e)}
          handleSubmitClick={(e) => {
            e.preventDefault()
            vm.save()
          }}
          showReset={Boolean(vm.objectId)}
          onConfirmReset={() => organizationUsersStore.reloadEditVM()}
          moreMenuProps={{
            hidden: !vm.objectId || !vm.isEditable || vm.isUserOwned,
            isEditMode: vm.isEditMode,
            itemType: localizationStore.lzStrings.moreMenu.obj_type.user,
            itemName: `${vm.firstName} ${vm.lastName}`,
            onConfirmDelete: () => vm.deleteOrgUser(),
            onConfirmArchive: () => (vm.isArchived ? vm.unArchiveOrgUser() : vm.archiveOrgUser()),
            onConfirmSendPasswordReset: () => vm.sendPasswordReset(),
            onConfirmSetNewPassword: () => vm.editPassword(),
            onConfirmSendWelcomeEmail: () => vm.sendWelcomeEmail(),
            onConfirmExpirePassword: () => vm.expirePassword(),

            onConfirmArchiveAll:
              vm.isSystemAdmin && !vm.isArchived ? () => vm.archiveUserForAllOrgs() : null,
            onUnlockAccount: () => vm.unlockAccount(),
            archiveButtonLabel: vm.isArchived ? 'Restore' : 'Archive',
          }}
        />
        <Tabs variant='fullWidth' centered value={vm.currentTabIndex} onChange={handleChange}>
          <Tab
            value={0}
            className={vm.isUserInfoValid ? '' : 'error'}
            label={s.userEdit.user_info}
          />
          {renderOrganizationTab()}
          <Tab value={2} label={s.userEdit.audience} disabled={vm.isNewUser} />
          <Tab value={3} wrapped label={s.userEdit.privilege_sets} />
        </Tabs>
      </>
    )
  }

  const renderSpinner = () => {
    if (!vm.isProcessing) return
    return <SpinnerOverlay />
  }

  const renderOrganizationTabPanel = () => {
    if (!vm.isEditable) return null
    return (
      <DrawerTabPanel value={vm.currentTabIndex} index={1}>
        <UserOrganizationTab />
      </DrawerTabPanel>
    )
  }

  return (
    <Drawer variant='temporary' anchor='right' open={vm.isOpen} onClose={() => vm.toggleDrawer()}>
      <div id='OrganizationUserEditDrawer'>
        {renderSpinner()}
        <Card elevation={0}>
          <MuiDrawerHeader title={s.userEdit.edit_user} toggleDrawer={() => vm.toggleDrawer()} />
          {renderHeaderAndTabs()}
          <CardContent
            className={clsx('panel-body', {
              'edit-user-drawer-card-content-on-participants-select-screen': vm.addAudienceTabShown,
            })}
            id='tabBody'
          >
            <DrawerTabPanel value={vm.currentTabIndex} index={0}>
              <UserInfoTab />
            </DrawerTabPanel>
            {renderOrganizationTabPanel()}
            <DrawerTabPanel value={vm.currentTabIndex} index={2}>
              <UserAudienceTab />
            </DrawerTabPanel>
            <DrawerTabPanel value={vm.currentTabIndex} index={3}>
              <UserPrivilegeSetsTab />
            </DrawerTabPanel>
          </CardContent>
        </Card>
      </div>
      <PasswordSnackbar vm={organizationUsersStore.pwSnackbarVM} />
    </Drawer>
  )
}

export default inject(
  'organizationUsersStore',
  'localizationStore'
)(observer(OrganizationUserEditDrawer))
