import Parse from 'parse'
import { IAPIResponse } from '../../shared/IAPIResponse'
import { IOrganizationUsersBulkOperationsRequest } from '../interfaces/IOrganizationUsersBulkOperationsRequest'
import { IOrganizationUsersBulkOperationsResult } from '../interfaces/IOrganizationUsersBulkOperationsResult'
import { IOrganizationUsersBulkRequest } from '../interfaces/IOrganizationUsersBulkRequest'
import { IOrganizationUsersEditOperationsRequest } from '../interfaces/IOrganizationUsersEditOperationsRequest'
import { IOrganizationUsersEditOperationsResult } from '../interfaces/IOrganizationUsersEditOperationsResult'

export class OrganizationUsersBulkOperationsService {
  public async saveToOrganizationUsersBulkOperation(
    req: IOrganizationUsersBulkOperationsRequest
  ): Promise<IOrganizationUsersBulkOperationsResult> {
    return await Parse.Cloud.run('saveToOrganizationUsersBulkOperation', { req })
  }

  public async saveOrganizationUsersTableEditsOperation(
    req: IOrganizationUsersEditOperationsRequest
  ): Promise<IOrganizationUsersEditOperationsResult> {
    return await Parse.Cloud.run('saveOrganizationUsersTableEditsOperation', { req })
  }

  public async archiveUsersOperation(req: IOrganizationUsersBulkRequest): Promise<IAPIResponse> {
    return await Parse.Cloud.run('archiveUsersBulkOperation', { req })
  }

  public async deleteUsersOperation(req: IOrganizationUsersBulkRequest): Promise<IAPIResponse> {
    return await Parse.Cloud.run('deleteUsersBulkOperation', { req })
  }

  public async refreshUsersInDW(organizationId: string): Promise<IAPIResponse> {
    return await Parse.Cloud.run('refreshUsersInDW', { organizationId })
  }
}
