import { action, observable, computed } from 'mobx'
import { serializable, serialize, deserialize, list, object, primitive } from 'serializr'

export class Category {
  static create(category) {
    const cat = new Category()
    cat.categoryId = category.objectId
    cat.isChecked = false
    cat.value = 0
    return cat
  }

  @serializable @observable categoryId: string = ''
  @serializable @observable isChecked: boolean = false
  @serializable @observable value: number = 0
  @observable isSelected: boolean = false

  @action
  public toggleIsSelected() {
    this.isSelected = !this.isSelected
  }

  @action
  public toggleIsChecked() {
    this.isChecked = !this.isChecked
  }

  @action
  public setValue(e) {
    this.value = e
  }
}
