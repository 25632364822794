import React from 'react'
import { observer } from 'mobx-react'
import rootStore from '../../stores/RootStore'
import { EmailTemplatesManageVM } from '../view-models/EmailTemplatesManageVM'
import { EmailTemplatesTableVM } from '../view-models/EmailTemplatesTableVM'
import OrgEmailTemplatesManage from './OrgEmailTemplatesManage'

interface Props {
  props: any
}

@observer
export default class OrgEmailTemplatesManageWrapper extends React.Component<Props, any> {
  private vm: EmailTemplatesManageVM
  private tableVM: EmailTemplatesTableVM

  constructor(props) {
    super(props)

    this.vm = rootStore.emailTemplatesStore.viewModels.manageVM
    this.tableVM = rootStore.emailTemplatesStore.viewModels.orgTableVM
    if (props.params && props.params.id && this.vm) {
      // rootStore.emailTemplatesStore.lazyLoadVM(props.params.id)
    }
  }

  componentWillUnmount() {}

  render() {
    return <OrgEmailTemplatesManage vm={this.vm} />
  }
}
