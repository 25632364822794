import React from 'react'
import { inject, observer } from 'mobx-react'
import { PulseQuestionsStore } from '../../store/PulseQuestionsStore'
import { CircularProgress } from '@material-ui/core'
import PulseQuestionSets from './PulseQuestionSets'
import './PulseQuestionDetails.scss'

interface Props {
  pulseQuestionsStore?: PulseQuestionsStore
}

const PulseQuestionDetails: React.FC<Props> = ({ pulseQuestionsStore }) => {
  const vm = pulseQuestionsStore.editVM
  if (!vm) return null
  if (vm.tabIndex !== 1) return null

  const renderSpinner = () => {
    if (!vm.isLoading) return null
    return <CircularProgress color='secondary' style={{ position: 'fixed', top: '50%', left: '50%' }} />
  }

  const renderMoreDetails = () => {
    if (vm.isLoading) return null
    return (
      <div className='more-details'>
        <PulseQuestionSets />
      </div>
    )
  }

  return (
    <div id='PulseQuestionDetails'>
      {renderSpinner()}
      {renderMoreDetails()}
    </div>
  )
}

export default inject('pulseQuestionsStore')(observer(PulseQuestionDetails))
