import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { CardHeader, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { WorksheetsStore } from '../../../store/WorksheetsStore'

interface Props {
  worksheetsStore?: WorksheetsStore
}

const Header: React.FC<Props> = ({ worksheetsStore }) => {
  const { chartWidgetDrawerVM } = worksheetsStore

  const handleClose = () => {
    chartWidgetDrawerVM.close()
  }

  return (
    <CardHeader
      action={
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      }
      title={'Viz'}
    />
  )
}

export default inject('worksheetsStore')(observer(Header))
