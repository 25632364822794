import React from 'react'
import { inject, observer } from 'mobx-react'
import { AgGridReact } from 'ag-grid-react/lib/agGridReact'
import AGGridWidgetPagingRow from '../../../shared/ag-grid/AGGridPagingRow'
import { CircularProgress } from '@material-ui/core'
import './SurveyTypesTable.scss'
import { SurveyTypesTableVM } from '../../view-models/SurveyTypesTableVM'
import ActionsCell from './ActionsCell'
import MuiConfirmDeleteDialog from '../../../shared/MuiConfirmDeleteDialog'
import ActionSnackbar from '../snackbar/ActionSnackbar'

interface Props {
  vm: SurveyTypesTableVM
}

const SurveyTypesTable: React.FC<Props> = ({ vm }) => {
  if (!vm.shouldRender) return

  const renderDeleteDialog = () => {
    if (!vm.selectedRow) return null
    return (
      <MuiConfirmDeleteDialog
        onClose={() => vm.selectedRow.closeDeleteDialog()}
        open={vm.selectedRow.deleteConfirm}
        itemType='Survey Type'
        itemName={vm.selectedRow.name}
        onConfirm={() => vm.selectedRow.deleteSurveyTemplate()}
      />
    )
  }

  const renderSpinner = () => {
    if (vm && vm.shouldRender) return
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  const renderGrid = () => {
    if (!vm.shouldRender) return
    return (
      <div className='ag-theme-alpine'>
        <AgGridReact
          gridOptions={{
            ...vm.getGridOptions(),
            frameworkComponents: {
              actionsCell: ActionsCell,
            },
          }}
        />
      </div>
    )
  }

  const renderGridPaging = () => {
    if (!vm.shouldRender) return
    return <AGGridWidgetPagingRow vm={vm} />
  }

  return (
    <>
      <ActionSnackbar vm={vm}/>
      {renderDeleteDialog()}
      {renderSpinner()}
      {renderGrid()}
      {renderGridPaging()}
    </>
  )
}

export default inject()(observer(SurveyTypesTable))
