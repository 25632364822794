import { observable } from 'mobx'
import { serializable } from 'serializr'
import { IParticipant } from '../../participants-select/interfaces/IParticipant'
import { ParticipantType } from '../../participants-select/types/ParticipantType'

export class Participant implements IParticipant {
  public static create(id, type, name, isArchived): Participant {
    const pt = new Participant()
    pt.id = id
    pt.name = name
    pt.type = type
    pt.isArchived = isArchived
    return pt
  }

  @serializable @observable public id: string = ''
  @serializable @observable public type: ParticipantType = null
  @serializable @observable public iconURL?: string = null
  @serializable @observable public name: string = ''
  @serializable @observable public isArchived?: boolean = false
}
