import React from 'react'
import { inject, observer } from 'mobx-react'
import rootStore from '../../../../stores/RootStore'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core'
import { OrganizationWelcomeBannerVM } from '../../../view-models/OrganizationWelcomeBannerVM'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import './WelcomeBanner.scss'
import clsx from 'clsx'
import WelcomeBannerEdit from './WelcomeBannerEdit'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import { EffectFade, Lazy, Navigation, Pagination } from 'swiper'
import parse from 'html-react-parser'
import 'swiper/swiper.scss'
import 'swiper/modules/effect-fade/effect-fade.scss'
import 'swiper/modules/lazy/lazy.scss'
import 'swiper/modules/navigation/navigation.scss'
import 'swiper/modules/pagination/pagination.scss'
import { WelcomeBannerAttachmentVM } from '../../../../organizations/view-models/WelcomeBannerAttachmentVM'
import MuxVideo from '../../../../shared/MuxVideo'
import { AttachmentVM } from 'src/app/attachments/view-models/AttachmentVM'
import CMSItemAttachment from 'src/app/announcements/views/CMSItemAttachment'
import OldItemAttachment from 'src/app/announcements/views/OldItemAttachment'
import { OldAttachmentVM } from 'src/app/attachments/view-models/OldAttachmentVM'
import { CMSItemAttachmentVM } from 'src/app/attachments/view-models/CMSItemAttachmentVM'

interface Props {
  vm: OrganizationWelcomeBannerVM
  localizationStore?: LocalizationStore
}

const WelcomeBanner: React.FC<Props> = ({ localizationStore, vm }) => {
  const { lzStrings } = localizationStore
  if (!vm) return null

  if (vm.currentTabIndex !== 2) return null

  const renderEditButton = () => {
    return (
      <Tooltip title={'Edit welcome banner'} placement='bottom'>
        <IconButton
          className='edit-btn'
          size='small'
          onClick={() => {
            vm.loadWelcomeBannerEditVM()
          }}
        >
          <EditOutlinedIcon />
        </IconButton>
      </Tooltip>
    )
  }

  const renderHeader = () => {
    return (
      <div className={'banner-header'}>
        <Typography className={'header-text'}>{'Welcome Banner'}</Typography>
        {renderEditButton()}
      </div>
    )
  }

  const renderBannerEdit = () => {
    if (!vm.welcomeBannerEditVM) return null
    return <WelcomeBannerEdit vm={vm.welcomeBannerEditVM} />
  }

  const renderItemAttachment = (item: AttachmentVM) => {
    if (item.isCMSItem) return <CMSItemAttachment vm={item as CMSItemAttachmentVM} />
    return <OldItemAttachment vm={item as OldAttachmentVM} />
  }

  const renderMedia = () => {
    const media = vm.swiperAttachments
    if (media && media.length < 1) return
    return (
      <Swiper
        autoHeight
        simulateTouch
        spaceBetween={50}
        lazy={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[EffectFade, Lazy, Pagination, Navigation]}
        className='swiper-swipe'
      >
        {vm.swiperAttachments.map((e, index) => {
          return (
            <SwiperSlide key={`swiper-slide-${vm.editVM.objectId}-${index}`}>
              {({ isActive, isVisible }) => {
                return (
                  <div className={'swiper-slide-container'}>
                    {renderItemAttachment(e)}
                    <div className='swiper-lazy-preloader swiper-lazy-preloader-white' />
                  </div>
                )
              }}
            </SwiperSlide>
          )
        })}
      </Swiper>
    )
  }

  const renderBody = () => {
    return (
      <div className='announcement-body-trunc'>
        <div className='announcement-body-html'>{parse(vm.welcomeBanner.body)}</div>
        {/* {renderExpandButton()} */}
      </div>
    )
  }

  const renderWelcomeBanner = () => {
    return (
      <>
        <Grid item xs={12} className={clsx('card-item', 'swiper-body')} id={`banner-images`}>
          {renderMedia()}
        </Grid>
        {renderBody()}
      </>
    )
  }

  const renderBannerContainer = () => {
    if (vm.welcomeBannerEditVM) return null
    return (
      <div>
        {renderHeader()}
        {renderWelcomeBanner()}
      </div>
    )
  }

  return (
    <div id={'WelcomeBanner'}>
      {renderBannerEdit()}
      {renderBannerContainer()}
    </div>
  )
}

export default inject('localizationStore', 'organizationsStore')(observer(WelcomeBanner))
