import React from 'react'
import { observer, Observer } from 'mobx-react'
import clsx from 'clsx'
import { QuestionVM } from '../../view-models/QuestionVM'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Checkbox,
  Grid,
  IconButton,
  Slider,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core'
import Rating from '@material-ui/lab/Rating'
import StarIcon from '@material-ui/icons/Star'
import StarOutlineIcon from '@material-ui/icons/StarOutline'
import BarChartIcon from '@material-ui/icons/BarChart'
import CloseIcon from '@material-ui/icons/Close'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlankRounded'
import CheckBoxIcon from '@material-ui/icons/CheckBoxRounded'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import OptionRow from './OptionButton'
import './QuestionRow.scss'
import { UserSurveyTakeVM } from '../../view-models/UserSurveyTakeVM'
import { makeStyles } from '@material-ui/core/styles'
import isMobile from '../../../../utils/isMobile'
import StepIndicators from '../../../shared/StepIndicators'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import OrderTile from './OrderTile'
import SentimentDialog from './SentimentDialog'
import TrainAIDialog from './TrainAIDialog'
import OldQuestionMedia from './OldQuestionMedia'
import CMSQuestionMedia from './CMSQuestionMedia'
import { MediaItemVM } from '../../view-models/MediaItemVM'
import { OldMediaItemVM } from '../../view-models/OldMediaItemVM'
import { CMSMediaItemVM } from '../../view-models/CMSMediaItemVM'

interface Props {
  takeVM: UserSurveyTakeVM
  question: QuestionVM
  index: number
}

const QuestionRow: React.FC<Props> = ({ question, takeVM }) => {
  const styles = makeStyles((theme) => ({
    vertical: {
      height: isMobile
        ? `${question.options.length * 3 * 12 + 6}px !important`
        : `${question.options.length * 2 * 19 + 10}px !important`,
    },
    markLabelMobile: {
      fontSize: '0.8em',
      minWidth: '100px !important',
      maxHeight: `${15 * 3}px !important`,
    },
    rowContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    media: {
      display: 'flex',
      alignItems: 'center',
      flexShrink: 0,
      flexBasis: 'auto',
    },
    question: {
      flexShrink: 0,
      flexBasis: 'auto',
    },
  }))()

  const renderButtons = () => {
    if (question.type === 'rating') return null
    if (question.type === 'starRating') return null
    if (question.type === 'text') return null
    if (question.type === 'number') return null
    if (question.type === 'order') return null
    if (question.type === 'dropdown') return null
    return question.options.map((opt, idx) => (
      <OptionRow takeVM={takeVM} key={'opt' + idx} option={opt} />
    ))
  }

  const createMarksArray = () => {
    const marks: Array<any> = []
    question.options.forEach((e) => {
      marks.push({
        value: e.value,
        label: e.text + (e.subText ? '\r\n' + e.subText : ''),
      })
    })
    return marks
  }

  const starRatingValueLabel = () => {
    if (question.value !== undefined && question.ratingText !== '')
      return <Box className={'star-text'}>{question.ratingText}</Box>
    if (question.value !== undefined) return <Box className={'star-text-hidden'}>.</Box>
    return undefined
  }

  const renderSlider = () => {
    if (question.type !== 'rating' && question.type !== 'starRating') return null
    const isVertical =
      (isMobile || question.ratingDisplayVal === 'vertical') && question.type !== 'starRating'
    const marks = createMarksArray()
    return (
      <div
        className={clsx('slider-container', question.media.mediaClassName, {
          'mui-slider-container-vertical': isVertical,
          'mui-slider-container-mobile': isMobile,
        })}
      >
        {question.type === 'rating' ? (
          <Slider
            orientation={isVertical ? 'vertical' : 'horizontal'}
            disabled={question.blurred || takeVM.isSubmitting || takeVM.isForResponseDisplay}
            key={'slider' + question.id + '_' + question.value}
            className={clsx({
              'has-answer': question.hasAnswer,
              'is-mobile': isMobile,
            })}
            classes={{
              vertical: isVertical ? styles.vertical : null,
              markLabel: isMobile ? styles.markLabelMobile : null,
            }}
            component='div'
            min={1}
            defaultValue={question.value}
            valueLabelDisplay='off'
            max={question.options.length}
            marks={marks}
            onChangeCommitted={(e, val) => {
              question.setValue(val)
            }}
          />
        ) : (
          <Grid container direction='column' justifyContent='center' alignItems='flex-start'>
            <div className={'flex-row'}>
              {starRatingValueLabel()}
              <Rating
                name={'rating' + question.id + '_' + question.value}
                key={'rating' + question.id + '_' + question.value}
                className={clsx({
                  'has-answer': question.hasAnswer,
                  'is-mobile': isMobile,
                })}
                disabled={question.blurred || takeVM.isSubmitting || takeVM.isForResponseDisplay}
                onChange={(e, val) => {
                  question.setValue(val)
                }}
                value={question.value}
                onChangeActive={(event, newHover) => {
                  if (isMobile) return
                  question.setHover(newHover)
                }}
                defaultValue={question.value}
                precision={1}
                max={question.options ? question.options.length : 0}
                icon={<StarIcon className='star-icon'></StarIcon>}
                emptyIcon={<StarOutlineIcon className='star-icon'></StarOutlineIcon>}
              />
            </div>
          </Grid>
        )}
      </div>
    )
  }

  const renderTextBox = () => {
    if (question.type !== 'text') return
    const option = question.options[0]
    return (
      <TextField
        id='outlined-name'
        className={'sln-question' + option.forQuestionRank + '-option' + option.rank}
        disabled={option.blurred || takeVM.isSubmitting || takeVM.isForResponseDisplay}
        value={option.text}
        onChange={(e) => option.setText(e.target.value)}
        margin='none'
        multiline
        fullWidth
        minRows={2}
        variant='outlined'
        onFocus={(e) => e.target.select()}
        placeholder={'Answer here'}
      />
    )
  }

  const renderDropdownSingleSelect = (question: QuestionVM) => {
    if (!question) return
    if (!question.options[0]) return
    if (question.type !== 'dropdown') return

    const option = question.options[0]
    const checked = question.options.filter((o) => o.isChecked)
    const defaultVals =
      checked && checked.length > 0 ? checked.map((o) => ({ text: o.text })) : [{ text: '' }]

    return (
      <Autocomplete
        className={'dropdown-input'}
        id='dropdown-type'
        disabled={option.blurred || takeVM.isSubmitting || takeVM.isForResponseDisplay}
        key={'dropdown' + question.id + '_' + question.value}
        value={defaultVals[0]}
        onChange={(e, val) => {
          if (val === null) {
            question.setValues([])
          } else {
            question.setValues(val ? val['text'] : '')
          }
        }}
        options={
          question.options ? question.options.map((o) => ({ text: o.text })) : [{ text: '' }]
        }
        getOptionLabel={(option) => option.text}
        getOptionSelected={(option, value) => option.text === value.text}
        renderInput={(params) => (
          <TextField
            required
            id='dropdown-type-text'
            error={!takeVM.isValid && question.required && !question.hasAnswer ? true : false}
            label=''
            name='dropdown-type-text'
            type='search'
            variant='outlined'
            placeholder={question.placeholder}
            {...params}
          />
        )}
      />
    )
  }

  const renderDropdownMultiSelect = (question: QuestionVM) => {
    if (!question) return
    if (!question.options[0]) return
    if (question.type !== 'dropdown') return

    const option = question.options[0]
    const checked = question.options.filter((o) => o.isChecked)
    const defaultVals = checked ? checked.map((o) => ({ text: o.text })) : [{ text: '' }]

    return (
      <Autocomplete
        className={'dropdown-input'}
        id='dropdown-type'
        disabled={option.blurred || takeVM.isSubmitting || takeVM.isForResponseDisplay}
        key={'dropdown' + question.id + '_' + question.value}
        multiple
        disableCloseOnSelect
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
              checkedIcon={<CheckBoxIcon fontSize='small' />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.text}
          </React.Fragment>
        )}
        value={defaultVals}
        onChange={(e, val) => {
          if (val === null) {
            question.setValues([])
          } else {
            question.setValues(val.map((v) => (v ? v['text'] : '')))
          }
        }}
        options={
          question.options ? question.options.map((o) => ({ text: o.text })) : [{ text: '' }]
        }
        getOptionLabel={(option) => option.text}
        getOptionSelected={(option, value) => option.text === value.text}
        renderInput={(params) => (
          <TextField
            required
            id='dropdown-type-text'
            error={!takeVM.isValid && question.required && !question.hasAnswer ? true : false}
            label=''
            name='dropdown-type-text'
            type='search'
            variant='outlined'
            placeholder={question.placeholder}
            {...params}
          />
        )}
      />
    )
  }

  const renderDropdown = () => {
    if (question.type !== 'dropdown') return

    if (question.isDropdownMultiSelect) {
      return renderDropdownMultiSelect(question)
    } else {
      return renderDropdownSingleSelect(question)
    }
  }

  const options = question.options.map((value, index) => (
    <div id={`ordered-option-idx-${index}`} className='ordered-option-row'>
      <OrderTile value={value} index={index} disabled={question.isForResponseDisplay} />
    </div>
  ))

  const onSortEnd = ({ oldIndex, newIndex }) => {
    question.setOldRank(oldIndex)
    question.setNewRank(newIndex)
    question.moveOption()
  }

  const SortableItem: any = SortableElement(({ value }) => (
    <div style={{ zIndex: 99999999 }} className='question-draggable-item'>
      <div className='order-option-title'>{value}</div>
    </div>
  ))

  const SortableList: any = SortableContainer(({ items }) => {
    return (
      <div className='ordered-list-container'>
        {items.map((value, index) => (
          <SortableItem
            disabled={question.isForResponseDisplay}
            key={`item-${index}`}
            index={index}
            value={value}
          />
        ))}
      </div>
    )
  })

  const renderOrderOptions = () => {
    if (question.type !== 'order') return null
    return <SortableList distance={3} items={options} onSortEnd={onSortEnd} />
  }

  const renderNumberText = () => {
    if (!question.isDirty) return null
    if (question.validatedNumberResponse) return null
    return (
      <p className='number-text'>
        Min: {question.minimumValueAllowed || 0}, Max: {question.maximumValueAllowed || 0}, Step:{' '}
        {question.numberStep || 0}
      </p>
    )
  }

  const renderNumberInput = () => {
    if (question.type !== 'number') return null
    const option = question.options[0]
    const renderSteps = () => {
      if (takeVM.isForResponseDisplay) return null
      return (
        <StepIndicators
          onIncreaseStep={() => option.increaseResponse()}
          onDecreaseStep={() => option.decreaseResponse()}
        />
      )
    }
    return (
      <>
        <TextField
          id='outlined-name'
          type='number'
          className={'number-field'}
          disabled={option.blurred || takeVM.isSubmitting || takeVM.isForResponseDisplay}
          value={option.responseNumber}
          onChange={(e) => option.setNumber(e.target.value)}
          margin='none'
          inputProps={{ step: String(option.numberStep) }}
          variant='outlined'
          onFocus={(e) => e.target.select()}
          placeholder={'Answer here'}
          InputProps={{
            endAdornment: renderSteps(),
          }}
        />
        {renderNumberText()}
      </>
    )
  }

  const renderLightBox = () => {
    if (!question.showLightBox) return null
    const lightBoxStyles = { overlay: { zIndex: 10000 } }
    return (
      <Lightbox
        mainSrc={question.media.path}
        reactModalStyle={lightBoxStyles}
        onCloseRequest={() => question.toggleLightBox()}
      ></Lightbox>
    )
  }

  const renderMediaItem = (item: MediaItemVM) => {
    if (item.isCMSItem) return <CMSQuestionMedia question={question}  vm={item as CMSMediaItemVM} />
    return <OldQuestionMedia question={question} vm={item as OldMediaItemVM} />
  }

  const renderMedia = () => {
    if (!question.hasMedia) return null
    if (question.isMediaFirstQuestionAndMediaNotShown) return null
    return (
      <Grid container direction='row' justifyContent='center' alignItems='center' spacing={1}>
        <Grid item>
          {renderMediaItem(question.media)}
        </Grid>
      </Grid>
    )
  }

  const renderSentimentButton = () => {
    if (question.type !== 'text' || !takeVM.isForResponseDisplay) return
    return (
      <IconButton
        className='sentiment-button'
        aria-label='Sentiment Analysis'
        size='small'
        onClick={() => question.showSentiments()}
      >
        <BarChartIcon />
      </IconButton>
    )
  }

  const renderSnackbar = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        classes={{ root: `${takeVM.snackbarError ? 'errorMsg' : ''}` }}
        open={takeVM.isSnackbarOpen}
        autoHideDuration={100000}
        onClose={() => takeVM.closeSnackbar()}
        message={<Typography>{takeVM.snackbarMessage}</Typography>}
        action={[
          <Observer key={'survey-snack-unanswered'}>
            {() => (
              <IconButton
                key='survey-snack-unanswered-close'
                aria-label='close'
                color='inherit'
                onClick={() => takeVM.closeSnackbar()}
              >
                <CloseIcon />
              </IconButton>
            )}
          </Observer>,
        ]}
      />
    )
  }

  const renderQuestion = () => {
    if (question.isMediaFirstQuestionAndMediaShown) return null
    return (
      <>
        {renderButtons()}
        {renderSlider()}
        {renderTextBox()}
        {renderNumberInput()}
        {renderLightBox()}
        {renderOrderOptions()}
        {renderDropdown()}
      </>
    )
  }

  const renderQuestionTitle = () => {
    if (question.isMediaFirstQuestionAndMediaShown) return null
    return (
      <>
        {question.title}
        {question.required && <span className='required-indicator'>*</span>}
        {renderSentimentButton()}
      </>
    )
  }

  const renderQuestionAvatar = () => {
    if (isMobile) return
    return (
      <>
        <Avatar
          className={
            !takeVM.isValid && question.required && !question.hasAnswer
              ? 'indicator error'
              : question.isCurrentQuestion
              ? 'indicator active'
              : 'indicator'
          }
        >
          Q{question.displayedIndex + 1}
        </Avatar>
        <div className='hr' />
      </>
    )
  }

  const renderQuestionHeaderForMobile = () => {
    if (!isMobile) return
    return <span className='mobile-question-index'>Q{question.displayedIndex + 1}</span>
  }

  const blurredClass = question.blurred ? 'blurred' : ''

  const getMobileClassNameForRow = () => {
    if (!isMobile) return ''
    else if (isMobile && blurredClass === '') return 'is-mobile'
    return ' is-mobile'
  }

  return (
    <div id='QuestionRow' className={`${blurredClass}${getMobileClassNameForRow()}`}>
      {renderQuestionAvatar()}
      <Card elevation={0}>
        <CardContent>
          {renderQuestionHeaderForMobile()}
          <div className='question-row-title'>{renderQuestionTitle()}</div>
          <Grid
            className={styles.rowContainer}
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='stretch'
            spacing={1}
          >
            <Grid item className={styles.media}>
              {renderMedia()}
            </Grid>
            <Grid item lg md sm xs className={styles.question}>
              {renderQuestion()}
            </Grid>
          </Grid>
          <div
            className={question.type === 'rating' || question.type === 'starRating' ? 'spacer' : ''}
          />
        </CardContent>
      </Card>
      {renderSnackbar()}
      <SentimentDialog questionVM={question} />
      <TrainAIDialog questionVM={question} />
    </div>
  )
}

export default observer(QuestionRow)
