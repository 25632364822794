import React, { useState } from 'react'
import { RolesStore } from '../../roles/store/RolesStore'
import { GroupsStore } from '../../groups/store/GroupsStore'
import LocalizationStore from '../../localization/LocalizationStore'
import { GroupsAndRolesStore } from '../GroupsAndRolesStore'
import {
  Button,
  IconButton,
  CardHeader,
  Menu,
  MenuItem,
  ListItemText,
  ListSubheader,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { observer, inject } from 'mobx-react'
import Tooltip from '@material-ui/core/Tooltip'
import WidgetTitle from './WidgetTitle'
import Searchbar from '../../shared/Searchbar'
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter'
import './ManageGroupsAndRolesWidget.scss'

interface HeaderProps {
  rolesStore?: RolesStore
  groupsStore?: GroupsStore
  groupsAndRolesStore?: GroupsAndRolesStore
  localizationStore?: LocalizationStore
}

const WidgetHeader: React.FC<HeaderProps> = ({
  rolesStore,
  groupsStore,
  groupsAndRolesStore,
  localizationStore,
}) => {
  const { lzStrings } = localizationStore
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { groupsAndRolesWidgetVM: vm } = groupsAndRolesStore

  const onAddGroup = () => {
    groupsStore.setNewGroupToEdit()
    setAnchorEl(null)
  }

  const onAddRole = () => {
    rolesStore.setNewRoleToEdit()
    setAnchorEl(null)
  }

  return (
    <>
      <CardHeader
        action={
          <>
            <div className='search'>
              <Searchbar
                focused={vm.typedFilterText.length > 0}
                searchValue={vm.typedFilterText}
                onSearchChange={(e) => vm.setQuickFilter(e.target.value)}
              />
            </div>
            &nbsp;
            <div>
              <Tooltip title={lzStrings.rolesEdit.add_new_group_role} placement='bottom'>
                <IconButton
                  className='widget-cta'
                  size='small'
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </div>
          </>
        }
        title={<WidgetTitle />}
      >
        <Button>test</Button>
      </CardHeader>
      <Menu
        id='MoreMenu'
        className='add-new-menu'
        elevation={2}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <ListSubheader>{lzStrings.addNewSelect.addNew}</ListSubheader>
        <MenuItem key='add-group' onClick={() => onAddGroup()}>
          <ListItemText primary={lzStrings.groupEdit.group} />
        </MenuItem>
        <MenuItem key='add-role' onClick={() => onAddRole()}>
          <ListItemText primary={lzStrings.rolesEdit.role} />
        </MenuItem>
      </Menu>
    </>
  )
}

export default inject(
  'rolesStore',
  'groupsStore',
  'groupsAndRolesStore',
  'localizationStore'
)(observer(WidgetHeader))
