import React from 'react'
import { inject, observer, useLocalStore } from 'mobx-react'
import rootStore from '../../../stores/RootStore'
import './PulseSetsManage.scss'
import { PulseSetsManageVM } from '../../view-models/manage/PulseSetsManageVM'
import TopToolbar from './TopToolbar'
import PulseSetEditModal from './PulseSetEditModal'
import PulseSetsList from './PulseSetsList'

const PulseSetsManage: React.FC = () => {
  const localStore = useLocalStore(() => ({
    vm: new PulseSetsManageVM(rootStore),
  }))

  const vm = localStore.vm
  return (
    <div id='PulseSetsManage'>
      <div className='pulse-sets-container'>
        <PulseSetEditModal />
        <TopToolbar vm={vm} />
        <PulseSetsList vm={vm} />
      </div>
    </div>
  )
}

export default inject()(observer(PulseSetsManage))
