import { observable, action } from 'mobx'
import { Participant } from './Participant'
import { serializable, serialize, list, object } from 'serializr'
import { ITaskTemplateDTO } from '../dtos/ITaskTemplateDTO'
import { IAggregate } from '../../../shared/data/IAggregate'

export default class TaskTemplate implements ITaskTemplateDTO, IAggregate {
  static create(orgId) {
    const temp = new TaskTemplate()
    temp.organizationId = orgId
    return temp
  }

  @serializable @observable objectId: string = ''
  @serializable @observable organizationId: string = ''
  @serializable @observable name: string = ''
  @serializable(list(object(Participant))) @observable participants: Array<Participant> = []
  @serializable @observable isProcessing: boolean = false
  @serializable @observable isDeleted: boolean = false
  public isOnServer: boolean = false

  markIsNotOnServer() {
    this.isOnServer = false
  }

  markIsOnServer() {
    this.isOnServer = true
  }

  @action
  markIsNotProcessing() {
    this.isProcessing = false
  }

  markIsProcessing() {
    this.isProcessing = true
  }

  public serialize(): ITaskTemplateDTO {
    return serialize(this)
  }
}
