import React from 'react'
import { inject, observer } from 'mobx-react'
import { Box, AppBar, Toolbar, Tabs, Tab } from '@material-ui/core'
import './TabsBar.scss'
import { TabEnums } from '../../types/TabsEnums'
import ChartIcon from '@material-ui/icons/InsertChart'
import { TrainingItemsStore } from '../../store/TrainingItemsStore'
import BuilderTabPanel from './BuilderTabPanel'
import CompletionTabPanel from './CompletionTabPanel'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div hidden={value !== index} {...other}>
      <Box component='div'>{children}</Box>
    </div>
  )
}

interface Props {
  trainingItemsStore?: TrainingItemsStore
}

const TabsBar: React.FC<Props> = ({ trainingItemsStore }) => {
  const { trainingItemEditVM: vm } = trainingItemsStore
  if (!vm) return null

  const handleChange = (e: any, newValue: number) => {
    vm.setCurrentTab(newValue)
  }

  return (
    <>
      <div id='TabsBar'>
        <AppBar position='relative' elevation={0}>
          <Toolbar>
            <div>
              <Tabs
                value={vm.currentTab}
                onChange={handleChange}
                indicatorColor={'primary'}
                textColor='primary'
                variant='fullWidth'
              >
                <Tab
                  label='Builder'
                  value={TabEnums.BUILDER}
                  icon={<ChartIcon />}
                  className={vm.builderTabValid ? '' : 'error'}
                />
                <Tab
                  label='Completion'
                  value={TabEnums.COMPLETION}
                  className={vm.quizValid ? '' : 'error'}
                  icon={<ChartIcon />}
                />
              </Tabs>
            </div>
          </Toolbar>
        </AppBar>
        <TabPanel key={TabEnums.BUILDER} value={vm.currentTab} index={TabEnums.BUILDER}>
          <BuilderTabPanel />
        </TabPanel>
        <TabPanel key={TabEnums.COMPLETION} value={vm.currentTab} index={TabEnums.COMPLETION}>
          <CompletionTabPanel />
        </TabPanel>
      </div>
    </>
  )
}

export default inject('trainingItemsStore')(observer(TabsBar))
