import { action, computed, observable } from 'mobx'
import { RootStore } from '../../../../stores/RootStore'
import { PulseCampaign } from '../../../aggregate/PulseCampaign'
import { PulseCampaignTemplatesDashboardDataStore } from './PulseCampaignTemplatesDashboardDataStore'

export class CampaignTemplateRowVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, pulseCampaign: PulseCampaign) {
    this.rootStore = rootStore
    this.pulseCampaign = pulseCampaign
    }

  @observable public pulseCampaign: PulseCampaign = null

  @computed
  public get objectId(): string {
    return this.pulseCampaign.objectId
  }

  @computed
  public get isSystemAdmin(): boolean {
    return this.rootStore.appStore.isSystemAdmin
  }

  @computed
  public get canManage() {
    return this.isSystemAdmin 
  }

  @computed
  public get hasVizPermission(): boolean {
    if (this.rootStore.appStore.isSystemAdmin) return true
    return this.pulseCampaign.ownerUserId === this.rootStore.appStore.currentUserId
  }

  @action
  public editTemplate() {
    this.rootStore.appStore.router.push(`/pulse/v1/fromtemplate/edit/${this.objectId}`)
  }

  @action
  public copyTemplate() {
    this.rootStore.appStore.router.push(`/pulse/v1/fromtemplate/fromCopy/${this.objectId}`)
  }

  @action
  public editPulseAnalysis() {
    this.rootStore.appStore.router.push(`/pulse/v1/analysis/${this.objectId}`)
  }

  @computed
  public get name(): string {
    return this.pulseCampaign.name
  }
}
