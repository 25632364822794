import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../localization/LocalizationStore'
import { ConsentsStore } from '../store/ConsentsStore'
import { CardHeader, IconButton, Grid } from '@material-ui/core'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'

interface HeaderProps {
  localizationStore?: LocalizationStore
  consentsStore?: ConsentsStore
}

const ConsentWidgetHeader: React.FC<HeaderProps> = ({ localizationStore, consentsStore }) => {
  const vm = consentsStore.consentWidgetVM
  if (!vm) return null
  const handleClick = () => {
    vm.toggleDrawer()
    vm.setDrawerMode('edit')
  }
  return (
    <CardHeader
      action={
        <Grid container alignItems='center' justifyContent='flex-end'>
          <Grid item>
            {vm.latestConsent ? (
              <IconButton className='widget-cta' size='small' onClick={handleClick}>
                <EditOutlinedIcon />
              </IconButton>
            ) : null}
          </Grid>
        </Grid>
      }
      title={localizationStore.lzStrings.consentWidget.consent_title}
    ></CardHeader>
  )
}

export default inject('consentsStore', 'localizationStore')(observer(ConsentWidgetHeader))
