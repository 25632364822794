import React from 'react'
import { observer, inject } from 'mobx-react'
import { CardHeader, Tooltip, IconButton } from '@material-ui/core'
import Searchbar from '../../../shared/Searchbar'
import AddIcon from '@material-ui/icons/Add'
import { CustomCollectionsStore } from '../../store/CustomCollectionStore'

interface Props {
  customCollectionsStore?: CustomCollectionsStore
}

const Header: React.FC<Props> = ({ customCollectionsStore }) => {
  const { widgetVM } = customCollectionsStore
  if (!widgetVM) return null

  return (
    <CardHeader
      id='Header'
      title='Custom Collections'
      action={
        <>
          <div className='search'>
            <Searchbar
              focused={widgetVM.typedFilterText.length > 0}
              searchValue={widgetVM.typedFilterText}
              onSearchChange={(e) => widgetVM.setQuickFilter(e.target.value)}
            />
          </div>
          <Tooltip title={'Add New'} placement='bottom'>
            <IconButton
              className='widget-cta'
              size='small'
              onClick={() => widgetVM.addNew()}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </>
      }
    ></CardHeader>
  )
}

export default inject('customCollectionsStore')(observer(Header))
