import React from 'react'
import { ListItem, Grid, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'
import CheckIcon from '@material-ui/icons/Check'
import WarningIcon from '@material-ui/icons/Warning'
import './QuestionImportRow.scss'
import { ImportOptionVM } from '../../view-models/ImportOptionVM'
import { QuestionImportRowVM } from '../../view-models/QuestionImportRowVM'

interface Props {
  option: ImportOptionVM
  row: QuestionImportRowVM
}

const ImportOption: React.FC<Props> = ({ option, row }) => {

  const renderText = () => {
    return <Typography className={row.textStyle}>Text: <strong>{option.text}</strong></Typography>
  }

  const renderValue = () => {
    if (row.type !== 'rating' && row.type !== 'starRating') return null
    return <Typography className={row.textStyle}>Value: {option.value}</Typography>
  }

  const renderResponseType = () => {
    return <Typography className={row.textStyle}>Response Type: {option.responseType}</Typography>
  }

  const renderEmoji = () => {
    if (row.type !== 'emoji') return null
    return <Typography className={row.textStyle}>Emoji: <img
      src={'../../../assets/emoticons/png/' + option.emoji + '.png'}
      style={{ width: 20, height: 20 }}
      alt=''
    /></Typography>
  }

  return (
    <Grid container id='ImportOption'>
      {renderText()}
      {renderValue()}
      {renderResponseType()}
      {renderEmoji()}
    </Grid>
  )
}

export default observer(ImportOption)
