import { observable, action, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Task } from '../aggregates/Task'
import { TaskEditVM } from '../view-models/TaskEditVM'
import { TasksManagementWidgetVM } from '../view-models/TasksManagementWidgetVM'
import { ITaskDTO } from '../dtos/ITaskDTO'
import { DataStore } from '../../shared/data/DataStore'

export class TasksStore extends DataStore<Task, ITaskDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, Task, 'tasks', [
      'organizationId',
      'assignedByUserId',
      'taskTypeId',
      'categoryId',
      'dueDate',
      'channelPartnerId',
      'name',
      'description',
      'feedbackSurveyTemplateId',
      'createdAt',
      'autoPublishFeedback',
      'attachments',
      'assignedToAudienceMembers',
      'alarms',
      'snapshots',
      'isDeleted'
    ])
  }

  @observable public taskEditVM: TaskEditVM = null
  @observable public taskManagementWidgetVM: TasksManagementWidgetVM = null
  @observable public isTasksManagementDrawerOpen: boolean = false
  @observable public loaded: boolean = false

  @computed
  public get tasks(): Task[] {
    return this.records
  }

  @action
  public onListRecordsLoaded() {
    this.taskManagementWidgetVM = new TasksManagementWidgetVM(this.rootStore)
    this.loaded = true
  }

  public createNewTask() {
    const orgId = this.rootStore.appStore.currentOrgId
    const assignedById = this.rootStore.appStore.currentUserId
    const endOfDay = this.rootStore.calendarSettingsStore.calendarSetting.eventEnd
    this.taskEditVM = new TaskEditVM(this.rootStore, Task.create(orgId, assignedById, endOfDay))
  }

  public openTask(id: string) {
    const foundTask = this.tasks.filter((task) => task.objectId === id)[0]
    this.taskEditVM = new TaskEditVM(this.rootStore, foundTask.clone())
  }

  public getTask(objectId: string): Task {
    return this.tasks.find((e) => e.objectId === objectId)
  }

  public openDrawer() {
    this.isTasksManagementDrawerOpen = true
  }

  public closeDrawer() {
    this.isTasksManagementDrawerOpen = false
  }
}
