import { observable, action } from 'mobx'
import { serializable, serialize, deserialize, date } from 'serializr'
import { IAggregate } from 'src/app/shared/data/IAggregate'
import { IUserAnnouncementDTO } from '../dtos/IUserAnnouncementDTO'
import { Announcement } from './Announcement'

export class UserAnnouncement extends Announcement implements IUserAnnouncementDTO, IAggregate {
  @serializable @observable public userId: string = ''
  @serializable @observable public objectId: string = ''
  @serializable @observable public isRead: boolean = false
  @serializable @observable public announcementId: string = ''
  @serializable(date()) @observable public readDateTime: Date = new Date()
  @serializable(date()) @observable public updatedAt: Date = new Date()
  @serializable @observable public isDeleted: boolean = false
  @serializable @observable public liked: boolean = false

  isOnServer: boolean = false

  @action
  public markIsOnServer() {
    this.isOnServer = true
  }

  @action
  public markIsNotOnServer() {
    this.isOnServer = true
  }

  public serialize() {
    const obj = serialize(this)
    return obj
  }

  public clone(): Announcement {
    return deserialize(Announcement, this.serialize())
  }
}
