import { observable, action, computed } from 'mobx'
import { PulseCampaignEditVM } from './PulseCampaignEditVM'

export class WeekdayVM {
  private editVM: PulseCampaignEditVM

  constructor(day, editVM: PulseCampaignEditVM) {
    this.day = day
    this.editVM = editVM
  }

  @observable day: string = ''

  @computed
  public get isChecked(): boolean {
    return this.editVM.pulseCampaign.weekdays[this.day]
  }

  @computed
  public get subString() {
    // return this.day.substring(0, 3).toUpperCase()
    return `${this.day}s`
  }

  @action
  public toggleChecked() {
    this.editVM.toggleWeekday(this.day)
  }
}
