import { computed, observable, action } from 'mobx'
import { IPulseQuestionDTO } from '../dtos/IPulseQuestionDTO'
import { RootStore } from '../../stores/RootStore'

export class YouTubePreviewVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, pulseQuestion: IPulseQuestionDTO) {
    this.rootStore = rootStore
    this.pulseQuestion = pulseQuestion
  }

  @observable public pulseQuestion: IPulseQuestionDTO = null

  @action
  public closeYouTubePreviewVM() {
    return this.rootStore.pulseQuestionsStore.closeYouTubePreviewVM()
  }

  @computed
  public get youTubeId() {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
    const match = this.pulseQuestion.youTubeURL.match(regExp)

    return match && match[2].length === 11 ? match[2] : null
  }

  @computed
  public get embedUrl() {
    return `https://www.youtube.com/embed/${this.youTubeId}`
  }
}
