import { observable, action } from 'mobx'
import { IAggregate } from 'src/app/shared/data/IAggregate'
import { serializable, serialize, list, primitive, object, deserialize } from 'serializr'
import { IPulseCategoryDTO } from '../dtos/IPulseCategoryDTO'

export default class PulseCategory implements IPulseCategoryDTO, IAggregate{

  static create(grandCategoryId: string, parentCategoryId: string) {
    const pulseCategory = new PulseCategory()
    pulseCategory.grandCategoryId = grandCategoryId
    pulseCategory.parentCategoryId = parentCategoryId
    return pulseCategory
  }
 
  @serializable @observable objectId: string = ''
  @serializable @observable organizationId: string = undefined
  @serializable @observable name: string = ''
  @serializable @observable grandCategoryId: string = undefined
  @serializable @observable parentCategoryId: string = undefined
  @serializable @observable isDefault: boolean = true
  @serializable @observable isDeleted: boolean = false
  public isOnServer: boolean = false

  markIsNotOnServer() {
    this.isOnServer = false
  }

  markIsOnServer() {
    this.isOnServer = true
  }

  setName(name: string) {
    this.name = name
  }

  public clone(): PulseCategory {
    return deserialize(PulseCategory, this.serialize())
  }

  public serialize(): IPulseCategoryDTO {
    return serialize(this)
  }
}