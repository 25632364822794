import { persist } from 'mobx-persist'
import { action, computed, observable } from 'mobx'
import { Days } from './Days'
import { RepeatModeType } from '../types/RepeatModeType'
import { serializable, date, serialize, deserialize, list, object } from 'serializr'
import { EndingModeType } from '../types/EndingModeType'
import moment from 'moment-timezone'
import { CustomPublishGroup } from '../aggregate/CustomPublishGroup'
import { IPublishGroup } from '../dtos/IPublishGroup'
import { DateUtils } from '../..//shared/data/DateUtils'

export class Schedule {
  static create(sch: any) {
    const schedule = new Schedule()
    schedule.enabled = sch.enabled
    schedule.startDate = sch.startDate
    schedule.endDate = sch.endDate
    schedule.endingMode = sch.endingMode
    schedule.days = Days.create(sch.days)
    schedule.repeat = sch.repeat
    schedule.deliveryTime = sch.deliveryTime
    schedule.deliveryHour = sch.deliveryHour
    schedule.deliveryMinute = sch.deliveryMinute
    schedule.deliveryTimeZone = sch.deliveryTimeZone
    schedule.publishGroups = sch.publishGroups
    return schedule
  }

  @serializable @observable enabled: boolean = false
  @serializable @observable startDate: string = moment(
    moment()
      .minute(Math.ceil(moment().minute() / 15) * 15)
      .second(0)
      .add(2, 'hours')
  ).toISOString()
  @serializable @observable endDate: string = moment(new Date()).toISOString()
  @serializable @observable endingMode: EndingModeType = 'Never'
  @persist('object', Days) @observable days: Days = new Days()
  @serializable @observable repeat: RepeatModeType = 'no repeat'
  @serializable @observable deliveryHour: string = ''
  @serializable @observable deliveryTime: string = moment(new Date()).toISOString()
  @serializable @observable deliveryMinute: string = ''
  @serializable @observable deliveryTimeZone: string = moment.tz.guess()
  @serializable(list(object(CustomPublishGroup))) @observable publishGroups: Array<IPublishGroup> = []

  @action
  public toggleIsEnabled() {
    this.enabled = !this.enabled
  }

  @action
  public setRepeat(val: RepeatModeType) {
    this.repeat = val
  }

  @action
  public setStartDate(val: Date) {
    this.startDate = moment(val).toISOString()
  }

  @action
  public setEndDate(val: Date) {
    this.endDate = moment(val).toISOString()
  }

  @action
  public setDeliveryTime(val: Date) {
    this.deliveryTime = moment(val).toISOString()
  }

  @action
  public setEndingMode(val) {
    this.endingMode = val
  }

  @action
  public setDeliveryTimeZone(val) {
    this.deliveryTimeZone = val
  }

  @action
  public toggleDay(day) {
    this.days[day] = !this.days[day]
  }

  @action
  public setAllPublishGroups(val: Array<IPublishGroup>) {
    this.publishGroups = val.map((e) => CustomPublishGroup.create(e))
  }

  @action
  public addPublishGroup(val: IPublishGroup) {
    this.publishGroups.push(CustomPublishGroup.create(val))
  }

  @action
  public updatePublishGroup(val: IPublishGroup) {
    this.publishGroups = this.publishGroups.map((grp) => (grp.id === val.id ? CustomPublishGroup.create(val) : grp))
  }

  @action
  public deletePublishGroupById(id: string) {
    this.publishGroups = this.publishGroups.filter((e) => e.id !== id)
  }

  @action
  public deletePublishGroupByIndex(index) {
    this.publishGroups.splice(index, 1)
  }

  @action
  public splicePublishGroupByIndex(index: number, newIdx: number) {
    const groupObj = this.publishGroups.splice(index, 1)
    this.publishGroups.splice(newIdx, 0, groupObj[0])
  }
}

