import { RootStore } from '../../../../stores/RootStore'
import { computed, action, observable } from 'mobx'
import ManageTrainingsWidgetVM from './ManageTrainingsWidgetVM'
import { TrainingPlan } from '../../../aggregates/TrainingPlan'
import moment from 'moment'
import { AudienceMember } from '../../../aggregates/AudienceMember'
import { UserTrainingPlanRowVM } from './UserTrainingPlanRowVM'
import { UserTrainingPlan } from '../../../../training-plans/user-training-plans/aggregate/UserTrainingPlan'
import { TrainingPlansUpdateService } from '../../../../training-plans/service/TrainingPlansUpdateService'
import { Goal } from '../../../../goals/aggregate/Goal'
import { ApplicableParticipant } from '../../../../training-items/aggregate/ApplicableParticipant'

export class TrainingPlanRowVM {
  private trainingPlan: TrainingPlan
  private rootStore: RootStore
  private manageWidgetVM: ManageTrainingsWidgetVM
  private svc: TrainingPlansUpdateService

  constructor(
    rootStore: RootStore,
    manageWidgetVM: ManageTrainingsWidgetVM,
    trainingPlan: TrainingPlan
  ) {
    this.svc = new TrainingPlansUpdateService()
    this.rootStore = rootStore
    this.trainingPlan = trainingPlan
    this.manageWidgetVM = manageWidgetVM
  }

  @observable public isExpanded: boolean = false
  @observable public trainerAnchorEl: any = null
  @observable public menuAnchorEl: any = null
  @observable public showDeleteDialog: boolean = false
  @observable public confirmArchiveDialogOpen: boolean = false

  @computed
  public get editIsDisabled(): boolean {
    if (moment().isSameOrAfter(this.activeDate)) return true
    return false
  }

  @action
  public toggleConfirmDialogOpen() {
    this.confirmArchiveDialogOpen = !this.confirmArchiveDialogOpen
  }

  @computed
  public get objectId(): string {
    return this.trainingPlan.objectId
  }

  @computed
  public get activeDate(): string {
    return this.trainingPlan.activeDate
  }

  @computed
  public get title(): string {
    return this.trainingPlan.title
  }

  @computed
  public get isForTrainingFlow(): boolean {
    return Boolean(this.trainingPlan.trainingPlanFlowId)
  }

  @computed
  public get isArchived(): boolean {
    return this.trainingPlan.isArchived
  }

  @computed
  public get assignedToAudienceMembers(): AudienceMember[] {
    return this.trainingPlan.assignedToAudienceMembers
  }

  @computed
  public get assignedToAudienceMembersWithMissingData(): any {
    if (!this.trainingPlan.assignedToAudienceMembers) return []
    return this.trainingPlan.assignedToAudienceMembers.map((user) => {
      const userId = user.objectId ? user.objectId : user.id
      if (!userId) return user
      return this.rootStore.audienceMembersStore.getUser(userId)
    })
  }

  @computed
  public get userOwned(): boolean {
    return this.trainingPlan.assignedByUserId === this.rootStore.appStore.currentUserId
  }

  @computed
  public get userTrainingPlans(): UserTrainingPlan[] {
    return this.rootStore.userTrainingPlansStore.getAllByPlanId(this.objectId)
  }

  @computed
  public get userTrainingPlanRows(): UserTrainingPlanRowVM[] {
    return this.userTrainingPlans
      .filter((plan) => !plan.isArchived)
      .map((plan) => new UserTrainingPlanRowVM(this.rootStore, this, plan))
      .sort((planA, planB) => {
        return planA.user?.name < planB.user?.name ? -1 : 0
      })
  }

  @computed
  public get associatedGoalId(): string {
    return this.trainingPlan.associatedGoalId
  }

  @computed
  public get associatedGoalName(): string {
    if (!this.associateToGoal) return null
    const id = this.trainingPlan.associatedGoalId
    const goal = this.rootStore.goalsStore.getGoal(id)
    if (goal) return goal.name
    return 'goal'
  }

  @computed
  public get associateToGoal(): boolean {
    return this.trainingPlan.associateToGoals
  }

  // @computed
  // public get numberOfPlanItems(): string {
  //   return `${this.trainingPlan.trainingPlanItems.length} Items`
  // }

  // @computed
  // public get numberOfQuizzes(): string {
  //   let quizzes = 0
  //   this.trainingPlan.trainingPlanItems.forEach((item) => {
  //     if (item.quiz) quizzes++
  //   })
  //   if (quizzes === 0) return ''
  //   return ` + ${quizzes} Quizzes`
  // }

  @computed
  public get subTitle(): string {
    const items = this.userTrainingPlans.length > 1 ? 'Plans' : 'Plan'
    return `${this.userTrainingPlans.length} Assigned ${items}`
  }

  @computed
  public get buttonText(): string {
    if (this.isExpanded) {
      return 'VIEW LESS'
    } else {
      return 'VIEW MORE'
    }
  }

  @computed
  public get archivedText(): string {
    if (this.isArchived) {
      return 'Restore'
    } else {
      return 'Archive'
    }
  }

  @computed
  public get archiveMessage(): string {
    if (this.isArchived) {
      return 'Do you want to restore this archived plan and all associated user plans?'
    } else {
      return 'Do you want to archive this plan? All associated user plans will be archived as well.'
    }
  }

  @computed
  public get mailToLink() {
    if (!this.trainer) return ''
    let foundUser = this.rootStore.audienceMembersStore.getUser(this.trainer.id)
    if (foundUser) {
      let href = 'mailto:' + foundUser.email
      return href
    }
  }

  @computed
  public get trainer(): AudienceMember {
    return this.trainingPlan.trainer
  }

  @computed
  public get dueDate(): Date {
    return moment(this.trainingPlan.dueDate).toDate()
  }

  @action
  public setAnchorEl(e: any) {
    this.menuAnchorEl = e
  }

  @action
  public setTrainerAnchorEl(e) {
    this.trainerAnchorEl = e
  }

  @action
  public toggleDeleteDialog() {
    this.setAnchorEl(null)
    this.showDeleteDialog = !this.showDeleteDialog
  }

  @action
  public toggleIsExpanded() {
    this.isExpanded = !this.isExpanded
  }

  @action
  public async refreshInDW() {
    await this.svc.refreshInDW(this.rootStore.appStore.currentOrgId, this.objectId)
    this.manageWidgetVM.openSnackbar('Sent To DW')
  }

  @action
  public async deleteTrainingPlan() {
    await this.svc.deleteTrainingPlan(this.rootStore.appStore.currentOrgId, this.objectId)
    this.toggleDeleteDialog()
    this.manageWidgetVM.openSnackbar('Training Plan deleted successfully')
  }

  @action
  public editTrainingPlan() {
    this.rootStore.appStore.router.push(`/trainingPlans/edit/${this.objectId}`)
  }

  @action
  public async archivePlan() {
    if (this.isArchived) {
      await this.svc.restoreTrainingPlan(this.rootStore.appStore.currentOrgId, this.objectId)
      this.manageWidgetVM.openSnackbar('Training Plan restored successfully')
    } else {
      await this.svc.archiveTrainingPlan(this.rootStore.appStore.currentOrgId, this.objectId)
      this.manageWidgetVM.openSnackbar('Training Plan archived successfully')
    }
  }

  @computed
  public get canEditAllTrainings(): boolean {
    if (
      this.rootStore.organizationsStore.currentOrganization &&
      !this.rootStore.organizationsStore.currentOrganization.isTrainingsEnabled
    )
      return false
    return this.rootStore.appStore.canEditAllTrainings
  }

  @computed
  public get hasActiveTrainingPlanFlows(): boolean {
    if (this.isForTrainingFlow) return true
    return false
  }

  @computed
  public get canManageTrainingPlanFlows(): boolean {
    if (!this.rootStore.organizationsStore.currentOrganization?.hasTrainingPlanFlows) return false
    if (this.rootStore.appStore.canManageTrainingPlanFlows) return true
    return false
  }
}
