import { computed, observable } from 'mobx'
import Parse from 'parse'
import PulseCategory from '../../../../pulse-categories/aggregate/PulseCategory'
import { IPulseCategoryDTO } from '../../../../pulse-categories/dtos/IPulseCategoryDTO'
import { DataStore } from '../../../../shared/data/DataStore'
import { RootStore } from '../../../../stores/RootStore'


export class SurveyPulseCategoriesDataStore extends DataStore<PulseCategory, IPulseCategoryDTO> {

  constructor(rootStore: RootStore, pulseCategoryIds: string[]) {
    super(rootStore, PulseCategory, 'pulseCategories', ['name'])
    this.pulseCategoryIds = pulseCategoryIds
  }

  @observable public isLoaded: boolean = false
  @observable public pulseCategoryIds: string[] = null

  public onListRecordsLoaded() {
    this.isLoaded = true
    console.log(this.rows)
  }

  protected getBaseQuery() {
    const query = new Parse.Query(this.className)
    query.containedIn('objectId', Array.from(this.pulseCategoryIds))
    return query
  }

  public get rows() {
    return this.records
  }
}
