import { observable, computed, action } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { CloudinaryResult } from '../../upload/aggregate/CloudinaryResult'
import { FileUploadService } from '../../upload/services/FileUploadService'
import { SurveyEditVM } from './SurveyEditVM'
import uuid from 'uuid/v4'
import { MediaUploadPickerVM } from '../../media-items/view-models/media-upload-picker/MediaUploadPickerVM'
import { MediaItem } from '../../media-items/aggregate/MediaItem'
import { Attachment } from '../../upload/aggregate/Attachment'

export class SurveyIntroductionVM {
  private rootStore: RootStore
  private editVM: SurveyEditVM

  constructor(
    rootStore: RootStore,
    editVM: SurveyEditVM
  ) {
    this.rootStore = rootStore
    this.editVM = editVM
    this.mediaUploadPickerVM = new MediaUploadPickerVM(
      this.rootStore,
      (media) => this.addMediaToInfoTextFromLibrary(media),
      true
    )
  }

  @observable public editorRef: any = null
  @observable public showMediaModal: boolean = false
  @observable public mediaUploadPickerVM: MediaUploadPickerVM

  @computed
  public get objectId() {
    return this.editVM.objectId
  }

  @computed
  public get isReadOnly() {
    return this.editVM.isReadOnly
  }

  @computed
  public get surveyIntroduction() {
    return this.editVM.surveyIntroduction
  }

  @action
  public setSurveyIntroduction(text) {
    this.editVM.setSurveyIntroduction(text)
  }

  @action
  public setEditorRef(editorRef: any) {
    this.editorRef = editorRef
  }

  @action
  public toggleMediaUploadModal() {
    this.showMediaModal = !this.showMediaModal
  }

  @action
  public toggleMediaUploadPicker() {
    this.mediaUploadPickerVM.toggleShowMediaUploadPicker()
  }

  @action
  private addMediaToInfoTextFromLibrary(mediaObject: MediaItem) {
    let content = ''
    if (mediaObject.type === 'video') {
      const url = mediaObject.fileUrl.toLowerCase()
      if (url.includes('vimeo')) {
        let regExp = /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/
        if (mediaObject.fileUrl.includes('player.vimeo.com')) {
          regExp = /(http|https)?:\/\/(www\.)?player.vimeo.com\/(?:video\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/
        }
        const match = mediaObject.fileUrl.match(regExp)
        const uuid = match && match.length ? match[4] : ''
        content = `<iframe height=220 width=320 src=${`https://player.vimeo.com/video/${uuid}`} allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullscreen />`
      } else if (url.includes('youtube') || url.includes('youtu.be')) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
        const match = mediaObject.fileUrl.match(regExp)
        const uuid = match && match[2].length === 11 ? match[2] : ''
        content = `<iframe height=220 width=320 src=${`https://www.youtube-nocookie.com/embed/${uuid}?modestbranding=1&showinfo=0&rel=0`} allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullscreen />`
      } else {
        content = `<video height=220 width=320 alt=${mediaObject.name} poster=${mediaObject.videoThumbnail} src=${mediaObject.fileUrl} controls='controls' />`
      }
    }
    if (mediaObject.type === 'image') {
      content = `<img alt=${mediaObject.name} src=${mediaObject.fileUrl} height=220 width=220 />`
    }
    return setTimeout(() => {
      this.editorRef?.current?.editor?.execCommand('mceInsertContent', false, content)
    }, 1000)
  }

  @action
  public addMediaToInfoTextFromComputer(attachment: Attachment) {
    let content = ''
    if (attachment.type === 'video') {

      const url = attachment.url.toLowerCase()
      if (url.includes('vimeo')) {
        let regExp = /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/
        if (attachment.url.includes('player.vimeo.com')) {
          regExp = /(http|https)?:\/\/(www\.)?player.vimeo.com\/(?:video\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/
        }
        const match = attachment.url.match(regExp)
        const uuid = match && match.length ? match[4] : ''
        content = `<iframe height=220 width=320 src=${`https://player.vimeo.com/video/${uuid}`} allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullscreen />`
      } else if (url.includes('youtube') || url.includes('youtu.be')) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
        const match = attachment.url.match(regExp)
        const uuid = match && match[2].length === 11 ? match[2] : ''
        content = `<iframe height=220 width=320 src=${`https://www.youtube.com/embed/${uuid}`} allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullscreen />`
      } else {
        content = `<video height=220 width=320 alt=${attachment.fileName} poster=${attachment.thumbnail} src=${attachment.url} controls='controls' />`
      }
    }
    if (attachment.type === 'image') {
      content = `<img alt=${attachment.fileName} src=${attachment.url} height=220 width=220 />`
    }
    return setTimeout(() => {
      this.editorRef?.current?.editor?.execCommand('mceInsertContent', false, content)
    }, 1000)
  }

  public async uploadToCloudinary(blobInfo, success, failure, progress) {
    try {
      const blob = blobInfo.blob()
      var file = new File([blob], `tiny-${uuid()}`)
      const uploadSvc = new FileUploadService()
      const res: CloudinaryResult[] = await uploadSvc.uploadMediaItemsToCloudinary([file])
      if (res) return success(res[0].secure_url)
    } catch (e) {
      if (e) {
        return failure(e.toString())
      }
    }
  }
}

