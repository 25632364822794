import { inject, observer } from 'mobx-react'
import React from 'react'
import {
  Avatar as MuiAvatar,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import DeleteIcon from '@material-ui/icons/Delete'
import SkillsIcon from '@material-ui/icons/BarChartRounded'
import GroupIcon from '@material-ui/icons/Group'
import { Editor } from '@tinymce/tinymce-react'
import { PLUGINS, TOOLBAR } from '../../config/TinyMCEconfig'
import { AnnouncementsStore } from '../store/AnnouncementsStore'
import LocalizationStore from '../../localization/LocalizationStore'
import ParticipantsSelect from '../../participants-select/views/ParticipantsSelect'
import { IParticipant } from 'src/app/participants-select/interfaces/IParticipant'
import MuiConfirmDeleteDialog from 'src/app/shared/MuiConfirmDeleteDialog'
import AnnouncementAttachments from './AnnouncementAttachments'
import Avatar, { AvatarUserMinimum } from '../../shared/Avatar'
import ArchivedLabel from './../../shared/ArchivedLabel'
import User from 'src/app/user/aggregate/User'
import styles from './../../shared/Avatar.scss'
import './AnnouncementEditTabPanel.scss'
import AnnouncementSendNotificationsPrompt from './AnnouncementSendNotificationsPrompt'
import { LabelsStore } from '../../labels/store/LabelsStore'
import { KeyboardDatePicker } from '@material-ui/pickers'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TimePicker from '../../shared/time-picker/TimePicker'
import PostTemplatesTable from '../../cms-post-templates/views/PostTemplatesTable'

interface Props {
  announcementsStore?: AnnouncementsStore
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
}

const AnnouncementsEditTabPanel: React.FC<Props> = ({
  announcementsStore,
  localizationStore,
  labelsStore,
}) => {
  const { announcementsWidgetVM } = announcementsStore
  const vm = announcementsStore.editVM
  const { announcements_widget: strings } = localizationStore.lzStrings
  if (!vm) return null

  const renderTitle = () => {
    return (
      <Grid item xs={12} className='add-new-title'>
        <IconButton onClick={() => announcementsWidgetVM.goBack()}>
          <ChevronLeftIcon />
        </IconButton>
        <Typography component='h4'>
          {vm.isNew
            ? String(strings.create + ' ' + labelsStore.getLabelByLanguageAndFor('announcement'))
            : String(strings.edit + ' ' + labelsStore.getLabelByLanguageAndFor('announcement'))}
        </Typography>
      </Grid>
    )
  }

  const renderTitleInput = () => {
    return (
      <Grid item xs={12} className='section'>
        <Typography variant='h5' className='input-label'>
          {strings.title}
          <span className='required'>*</span>
        </Typography>
        <OutlinedInput
          error={!vm.titleValid}
          fullWidth
          labelWidth={0}
          autoComplete='off'
          value={vm.title}
          placeholder={'Title'}
          onChange={(e) => vm.setTitle(e.target.value)}
        />
      </Grid>
    )
  }

  const renderDateInput = () => {
    return (
      <Grid item xs={12} className='section'>
        <Typography variant='h5' className='input-label'>
          {strings.created}
        </Typography>
        <OutlinedInput fullWidth disabled labelWidth={0} autoComplete='off' value={vm.date} />
      </Grid>
    )
  }

  const renderAnnouncementText = () => {
    return (
      <Grid item xs={12} className='section'>
        <Typography variant='h5' className='input-label'>
          {strings.announcement_text}
          <span className='required'>*</span>
        </Typography>
        <div className={!vm.bodyValid ? 'body-invalid' : null}>
          <Editor
            value={vm.body}
            init={{
              branding: false,
              height: 250,
              resize: true,
              menubar: false,
              plugins: PLUGINS,
              toolbar: TOOLBAR,
              powerpaste_block_drop: true,
              placeholder:
                'Add a description to let participants know what this announcement is all about...',
              browser_spellcheck: true,
              contextmenu: false,
              smart_paste: false,
              paste_data_images: false,
              powerpaste_allow_local_images: false,
              image_file_types: '',
              invalid_elements: 'img,svg',
            }}
            onEditorChange={(e) => vm.setBody(e)}
          />
        </div>
      </Grid>
    )
  }

  const renderAvatar = (participant) => {
    if (participant.type === 'user') {
      return (
        <Avatar
          user={participant as User | AvatarUserMinimum}
          size={40}
          style={{ marginLeft: styles.marginLeft, fontSize: styles.fontSize }}
          showOnlyFirstInitial
          isArchived={participant.isArchived}
        />
      )
    } else {
      return (
        <MuiAvatar>
          <GroupIcon />
        </MuiAvatar>
      )
    }
  }

  const participantCard = (participant: IParticipant, index) => {
    return (
      <Grid item xs={6} className='participant-card' key={index}>
        <Paper elevation={1} className='participant-card-paper'>
          <Grid container alignItems='center'>
            <Grid item>{renderAvatar(participant)}</Grid>
            <Grid item>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  alignItems: 'center',
                }}
              >
                <span style={participant.isArchived ? { fontStyle: styles.archivedFontStyle } : {}}>
                  <h6 className='add-participant'>{renderUserName(participant)}</h6>
                </span>
                {participant.isArchived ? <ArchivedLabel /> : undefined}
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    )
  }

  const renderAttachments = () => {
    return (
      <Grid item xs={12} className='attachments-section'>
        <AnnouncementAttachments />
      </Grid>
    )
  }

  const participants = () => {
    return (
      <Grid item xs={12} className='participants-section'>
        <Grid item xs={12} className='participants-title'>
          <Typography variant='h5' className='input-label'>
            {strings.participants}
            <span className='required'>*</span>
          </Typography>
          <Button
            variant='text'
            className={vm.participantsValid ? '' : 'participant-error'}
            onClick={() => vm.toggleParticipantsSelect()}
          >
            {strings.add_manage}
          </Button>
        </Grid>
        <Grid container spacing={2}>
          {vm.participants.map((part, index) => participantCard(part, index))}
        </Grid>
      </Grid>
    )
  }

  const renderOwner = () => {
    if (vm.showOwnerSelect) {
      return (
        <Grid item xs={12} className='owner-title'>
          <Grid item xs={6} className='dropdown-container'>
            <Select
              variant='outlined'
              fullWidth
              placeholder='Select owner...'
              value={vm.selectedOwner}
              onChange={(e) => {
                vm.setSelectedOwner(e.target.value)
              }}
            >
              {vm.announcementOwnerOptions.map((option, index) => {
                return (
                  <MenuItem key={index} value={option}>
                    {option.name}
                  </MenuItem>
                )
              })}
            </Select>
          </Grid>
          <Grid item xs={6} className='button-container'>
            <Button
              disabled={vm.selectedOwnerError}
              variant='contained'
              onClick={() => vm.setAnnouncementOwner()}
            >
              {`UPDATE`}
            </Button>
            <Button variant='contained' onClick={() => vm.toggleOwnerSelect()}>
              {`CANCEL`}
            </Button>
          </Grid>
        </Grid>
      )
    }
    return (
      <Grid item xs={12} className='owner-section'>
        <Grid item xs={12} className='participants-title'>
          <Typography variant='h5' className='input-label'>
            {`Owner `}
          </Typography>
          <Typography className='owner-label'>{vm.announcementOwnerName}</Typography>
          <Button variant='text' onClick={() => vm.toggleOwnerSelect()}>
            {`CHANGE`}
          </Button>
        </Grid>
      </Grid>
    )
  }

  const renderScheduleDetails = () => {
    if (!vm.scheduleEnabled) return
    return (
      <div className='section-content publish-at-specific-date-time-content'>
        <div>
          <div className='secondary-text textfield-label'>Delivery Date</div>
          <KeyboardDatePicker
            disablePast
            disableToolbar
            autoOk
            disabled={vm.scheduleIsReadOnly}
            // error={!surveyEditVM.startDateDateValid}
            variant='inline'
            inputVariant='outlined'
            format='MM/dd/yyyy'
            invalidDateMessage={null}
            margin='none'
            id='date-picker-inline4'
            value={vm.startDateDate}
            onChange={(e) => vm.setStartDate(e)}
            className='deliver-on-specific-date-picker'
          />
        </div>
        <div>
          <div className='secondary-text textfield-label'>Time</div>
          <TimePicker
            error={false}
            disabled={vm.scheduleIsReadOnly}
            value={vm.startDateTime}
            key={String(vm.vmCreatedAt)}
            onChange={(hr, min) => vm.setStartDateTime(hr, min)}
          />
        </div>
        <div>
          <div className='secondary-text textfield-label'>Time Zone</div>
          <Autocomplete
            options={vm.timeZones}
            disabled={vm.scheduleIsReadOnly}
            getOptionLabel={(e) => e.label}
            value={vm.deliveryTimeZone}
            onChange={(e, val) => vm.setDeliveryTimeZone(val)}
            renderInput={(params) => (
              <TextField {...params} placeholder='Select Time Zone' variant='outlined' />
            )}
            className='time-zone-autocomplete'
          />
        </div>
      </div>
    )
  }

  const renderSchedule = () => {
    return (
      <Grid item xs={12} className='schedule-section'>
        <Grid item xs={12} className='schedule-title'>
          <FormControlLabel
            control={
              <Checkbox
                disabled={vm.scheduleIsReadOnly}
                checked={vm.scheduleEnabled}
                onChange={() => vm.toggleEnableSchedule()}
                value='showSchedule'
                color='primary'
              />
            }
            label={
              <Typography variant='h5' style={{ color: '#4e4e5e' }}>
                Deliver Later
              </Typography>
            }
          />
          {renderScheduleDetails()}
        </Grid>
      </Grid>
    )
  }

  const renderShowAnnouncementOwner = () => {
    return (
      <Grid item xs={12} className='schedule-section'>
        <Grid item xs={12} className='schedule-title'>
          <FormControlLabel
            control={
              <Checkbox
                checked={vm.showAnnouncementOwner}
                onChange={() => vm.toggleShowAnnouncementOwner()}
                value='showOwner'
                color='primary'
              />
            }
            label={
              <Typography variant='h5' style={{ color: '#4e4e5e' }}>
                {`Display who posted this ${labelsStore.getLabelByLanguageAndFor('announcement')}`}
              </Typography>
            }
          />
        </Grid>
      </Grid>
    )
  }

  const renderSelectTemplateButton = () => {
    if (vm.showTemplateTable) return
    return (
      <Button
        className='select-template-button'
        variant='outlined'
        onClick={() => vm.toggleShowTemplateTable()}
      >
        {`SELECT A TEMPLATE`}
      </Button>
    )
  }

  const renderSelectTemplateTable = () => {
    if (!vm.showTemplateTable) return
    return <PostTemplatesTable editVM={vm} />
  }

  const renderBody = () => {
    if (vm.showParticipantsSelect) return null
    return (
      <Grid item xs={12} className='body'>
        {renderTitleInput()}
        {renderDateInput()}
        {renderAnnouncementText()}
        {participants()}
        {renderOwner()}
        {renderAttachments()}
        {renderShowAnnouncementOwner()}
        {renderSchedule()}
        {renderSelectTemplateButton()}
        {renderSelectTemplateTable()}
      </Grid>
    )
  }

  const participantSelect = () => {
    if (!vm.showParticipantsSelect) return
    return (
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} className='participants-select'>
            <ParticipantsSelect participantsSelectVM={vm.participantsSelectVM} />
          </Grid>
          <Grid item xs={12} className='add-selected-button'>
            <Button variant='outlined' onClick={() => vm.toggleParticipantsSelect()}>
              {strings.cancel}
            </Button>
            <Button variant='contained' onClick={() => vm.addParticipants()}>
              {strings.apply}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderDraftButton = () => {
    if (!vm.showDraftButton) return
    return (
      <Button disabled={vm.isSaving} variant='contained' onClick={() => vm.handleSaveDraft()}>
        {vm.isNew ? 'SAVE DRAFT' : 'UPDATE DRAFT'}
      </Button>
    )
  }

  const announceButton = () => {
    if (vm.showParticipantsSelect) return null
    return (
      <Grid item xs={12} className='announce-button'>
        {menuButton()}
        <Grid item>
          <Button variant='outlined' onClick={() => vm.cancel()}>
            {strings.cancel}
          </Button>
          {analyticsButton()}
          {renderDraftButton()}
          <Button
            disabled={vm.isSaving}
            variant='contained'
            onClick={() => vm.handleSaveAnnouncement()}
          >
            {vm.publishButtonText}
          </Button>
        </Grid>
      </Grid>
    )
  }

  const analyticsButton = () => {
    if (!vm.objectId) return null

    return (
      <>
        <Button
          disabled={vm.isSaving}
          variant='outlined'
          onClick={() => {
            announcementsWidgetVM.toggleAnnouncementsWidgetOpen()
            vm.viewAnalytics()
          }}
        >
          <SkillsIcon />
          View Analysis
        </Button>
      </>
    )
  }

  const menuButton = () => {
    if (!vm.objectId) return <div />
    return (
      <>
        <Button disabled={vm.isSaving} variant='outlined' onClick={() => vm.toggleDeleteDialog()}>
          <DeleteIcon className='announcement-menu-delete-icon' />
          {strings.delete}
        </Button>

        {renderConfirmDelete()}
      </>
    )
  }

  const renderConfirmDelete = () => {
    if (!vm.showDeleteDialog) return null
    return (
      <MuiConfirmDeleteDialog
        onClose={() => vm.toggleDeleteDialog()}
        open={vm.showDeleteDialog}
        itemName={vm.title}
        itemType={'Announcement'}
        onConfirm={() => vm.deleteAnnouncement()}
      />
    )
  }

  const renderSpinner = () => {
    if (!vm.isSaving) return
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  return (
    <Grid id='AnnouncementEditTabPanel' container justifyContent='flex-start'>
      <AnnouncementSendNotificationsPrompt vm={vm.sendNotificationsVM} />
      {renderSpinner()}
      {renderTitle()}
      {renderBody()}
      {participantSelect()}
      {announceButton()}
    </Grid>
  )
}

const renderUserName = (participant: IParticipant) => {
  if (participant.name.length > 16) {
    const shortenedName: string = participant.name.slice(0, 14) + '...'
    return shortenedName
  } else {
    return participant.name
  }
}

export default inject(
  'announcementsStore',
  'localizationStore',
  'labelsStore'
)(observer(AnnouncementsEditTabPanel))
