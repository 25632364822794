import React, { FC } from 'react'
import { observer, inject } from 'mobx-react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@material-ui/core'
import LocalizationStore from '../../localization/LocalizationStore'
import { UserStore } from '../../user/store/UserStore'
import { makeStyles } from '@material-ui/core/styles'

interface Props {
  localizationStore?: LocalizationStore
  userStore?: UserStore
}

const useStyles = makeStyles({
  title: {
    backgroundColor: '#f0eff4',
  },
  content: {
    paddingTop: '15px',
    height: '240px',
    width: '500px',
  },
  phone: {
    paddingTop: '15px',
  },
  error: {
    color: 'red',
  },
})

const UserInfoPrompt: FC<Props> = ({ localizationStore, userStore }) => {
  const classes = useStyles({})
  const vm = userStore.userInfoPromptVM
  if (!vm) return null
  const lz = localizationStore.lzStrings.userInfoPrompt

  const renderMessage = () => {
    if (vm.errorMessage) {
      return <Typography className={classes.error}>{vm.errorMessage}</Typography>
    } else {
      return <Typography>{lz.please_provide}</Typography>
    }
  }

  return (
    <Dialog open={vm.userInfoPromptOpen && vm.showPrompt} maxWidth={'md'}>
      <DialogTitle className={classes.title}>{lz.user_info}</DialogTitle>
      <DialogContent className={classes.content}>
        <Grid item md={12} sm={12}>
          {renderMessage()}
          <InputLabel htmlFor='email'>{lz.email}</InputLabel>
          <TextField
            className='email-input'
            error={!vm.emailValid}
            value={vm.email}
            onChange={(e) => vm.setEmailAddress(e.target.value)}
            fullWidth
            variant='outlined'
            id='email'
            helperText={vm.isEmailTaken ? 'That email address is already taken.' : ''}
          />
        </Grid>

        <Grid className={classes.phone} item md={12} sm={12}>
          <InputLabel htmlFor='phone'>{lz.phone}</InputLabel>
          <TextField
            value={vm.phone}
            error={!vm.phoneValid}
            onChange={(e) => vm.setPhoneNumber(e.target.value)}
            fullWidth
            variant='outlined'
            id='phone'
            helperText={vm.phoneValid ? '' : 'Phone number not valid'}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => vm.close()}>
          {lz.close}
        </Button>
        <Button variant='outlined' onClick={() => vm.saveEmailAndPhone()}>
          {lz.submit}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default inject('localizationStore', 'userStore')(observer(UserInfoPrompt))
