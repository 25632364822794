import { computed, action } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { AGGridRowVM } from '../../../shared/ag-grid/AGGridRowVM'
import EventType from 'src/app/event-types/store/aggregate/EventType'
import { CalendarManagementWidgetVM } from './CalendarManagementWidgetVM'

export class EventTypeRowVM extends AGGridRowVM<EventType> {
  constructor(rootStore: RootStore, eventType: EventType, widgetVM: CalendarManagementWidgetVM) {
    super(rootStore, eventType, widgetVM)
  }

  public type: string = 'eventType'

  @computed
  public get objectId() {
    return this.aggregate.objectId
  }

  @computed
  public get name() {
    return this.aggregate.name
  }

  @computed
  public get description() {
    return this.aggregate.description
  }

  editAggregate() {
    this.rootStore.eventTypesStore.setSelectedEventTypeId(this.objectId)
    this.rootStore.eventTypesStore.showDrawer = true
  }
}
