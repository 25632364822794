import React, { useRef, useEffect } from 'react'
import { observer } from 'mobx-react'
import { ParticipantsSelectVM } from '../view-models/ParticipantsSelectVM'
import { Button, Chip, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import Avatar, { AvatarUserMinimum } from '../../shared/Avatar'
import VirtualList from 'react-tiny-virtual-list'
import Searchbar from '../../shared/Searchbar'
import { ParticipantVM } from '../view-models/ParticipantVM'
import { AnonymousParticipantVM } from '../view-models/AnonymousParticipantVM'
import User from 'src/app/user/aggregate/User'
import ArchivedLabel from './../../shared/ArchivedLabel'
import styles from './../../shared/Avatar.scss'
import { VisibilityEditorVM } from 'src/app/surveys/view-models/VisibilityEditorVM'
import { AdditionalResponseNotificationEditorVM } from '../../surveys/view-models/AdditionalResponseNotificationEditorVM'

interface Props {
  participantsSelectVM: ParticipantsSelectVM
  visibilityEditorVM?: VisibilityEditorVM
  additionalResponseNotificationEditorVM?: AdditionalResponseNotificationEditorVM
}
const SelectedPane: React.FC<Props> = ({
  participantsSelectVM,
  visibilityEditorVM,
  additionalResponseNotificationEditorVM,
}) => {
  const virtualListRef = useRef<any>(null)
  useEffect(
    () => participantsSelectVM.setVirtualListParent('SelectedPane_VirtualList'),
    [participantsSelectVM.isVisible]
  )
  useEffect(() => virtualListRef.current.forceUpdate(), [participantsSelectVM?.participants])

  const vm = participantsSelectVM
  let vVm = null
  if (additionalResponseNotificationEditorVM) {
    vVm = additionalResponseNotificationEditorVM
  } else if (visibilityEditorVM) {
    vVm = visibilityEditorVM
  }

  const renderAvatar = (participant) => {
    if (participant.type !== 'user' && participant.type !== 'contact') return null
    return (
      <Avatar
        user={participant as User | AvatarUserMinimum}
        size={20}
        style={{ marginLeft: styles.marginLeft, fontSize: styles.fontSize }}
        showOnlyFirstInitial
        isArchived={participant.isArchived}
      />
    )
  }

  const renderRow = (idx, style) => {
    const chips = []
    const part1Idx = idx * vm.pillsPerRow
    const part1 = vm.filteredParticipants[part1Idx]
    chips.push(renderChip(part1))
    let nextIdx = part1Idx
    for (let row = 1; row < vm.pillsPerRow; row++) {
      nextIdx = nextIdx + 1
      if (nextIdx > vm.filteredParticipants.length - 1) break
      const nextPart = vm.filteredParticipants[nextIdx]
      chips.push(renderChip(nextPart))
    }
    return (
      <div style={style} key={'row' + idx.toString() + vm.filteredParticipantsCount.toString()}>
        {chips}
      </div>
    )
  }

  const renderChip = (participant: AnonymousParticipantVM | ParticipantVM) => {
    if (!participant) return
    return (
      <Chip
        disabled={vm.isReadOnly}
        avatar={renderAvatar(participant)}
        label={
          <div
            style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center' }}
          >
            <span style={participant.isArchived ? { fontStyle: styles.archivedFontStyle } : {}}>
              {participant.chipLabel}
            </span>
            {participant.isArchived ? <ArchivedLabel /> : undefined}
          </div>
        }
        key={'chp' + participant.key}
        onDelete={() => {
          vm.deleteParticipant(participant.id)
          if (vVm) {
            vVm.removeParticipantById(participant.id)
          }
        }}
        onClick={() => vm.explodeParticipant((participant as ParticipantVM).id)}
        icon={participant.explodable ? <AddIcon /> : null}
      />
    )
  }

  const renderSelectedItems = () => {
    return (
      <div id='SelectedPane_VirtualList' style={{ height: '100%' }}>
        <VirtualList
          ref={virtualListRef}
          height={vm.virtualListHeight}
          itemCount={vm.virtualListRowsCount}
          key={vm.filteredParticipantsCount + vm.renderKey}
          itemSize={45}
          renderItem={({ index, style }) => renderRow(index, style)}
        />
      </div>
    )
  }

  const renderSearchbox = () => {
    return (
      <Searchbar
        focused={vm.hasFilter}
        className='search'
        searchValue={vm.typedFilterText}
        onSearchChange={(e) => vm.setFilter(e.target.value)}
      />
    )
  }

  return (
    <>
      <div className='participants-select-selected-pane-top-row'>
        <Typography>Selected</Typography>
        <Button variant='text' onClick={() => vm.clearParticipants()}>
          Clear
        </Button>
      </div>
      {renderSearchbox()}
      {renderSelectedItems()}
    </>
  )
}

export default observer(SelectedPane)
