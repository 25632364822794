import { observable, computed } from 'mobx'
import Parse from 'parse'
import { DataStore } from '../shared/data/DataStore'
import { IDashboardDTO } from './dtos/IDashboardDTO'
import Dashboard from './aggregate/Dashboard'
import { RootStore } from '../stores/RootStore'
import { IDashboardsFindRequest } from './interfaces/IDashboardsFindRequest'
import { DashboardsFindService } from './service/DashboardsFindService'
import DashboardEditVM from './view-models/DashboardEditVM'
import { DashboardService } from '../services/DashboardService'
import SharedWithVM from './view-models/SharedWithVM'

export class DashboardsStore extends DataStore<Dashboard, IDashboardDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, Dashboard, 'dashboards', [
      'objectId',
      'organizationId',
      'name',
      'type',
      'sharedWith',
      'userId',
      'lgLayout',
    ])
    this.editVM = new DashboardEditVM(this.rootStore)
  }

  @observable public loaded: boolean = false
  @observable public editVM: DashboardEditVM = null
  @observable public sharedWithVM: SharedWithVM = null

  protected getBaseQuery() {
    const query = new Parse.Query(this.className)
    query.equalTo('organizationId', this.rootStore.appStore.currentOrgId)
    query.notEqualTo('isDeleted', true)
    return query
  }

  protected getListRecordsApiCall() {
    return async () => {
      const svc = new DashboardsFindService(this.rootStore)
      return await svc.getDashboards(this.request)
    }
  }

  public onListRecordsLoaded() {
    this.loaded = true
    this.sharedWithVM = new SharedWithVM(this.rootStore)
  }

  @computed
  public get currentOrgDashboards(): Dashboard[] {
    return this.records
  }

  @computed
  public get request(): IDashboardsFindRequest {
    return {
      userId: this.rootStore.appStore.currentUserId,
      organizationId: this.rootStore.appStore.currentOrgId,
    }
  }

  public getDashboard(objectId): Dashboard {
    return this.getRecord(objectId)
  }

  public get shouldRender(): boolean {
    if (!this.rootStore.userDashboardsStore.loaded) return false
    return true
  }

  public async loadEditVM(objectId: string, attempts: number = 0) {
    this.editVM.setIsProcessing()
    if (attempts === 20) return
    if (!this.shouldRender) return setTimeout(() => this.loadEditVM(objectId, ++attempts), 500)
    if (objectId === 'empty') return this.editVM.clearDashboard()
    if (objectId === 'new')
      return this.editVM.loadDashboard(
        Dashboard.create(
          this.rootStore.appStore.currentOrgId,
          this.rootStore.appStore.currentUserId
        )
      )
    const dashboard = await this.getFullRecord(objectId)
    if (!dashboard) return this.rootStore.appStore.router.push(`/dashboard/userDashboard/`)
    if (dashboard.isDeleted) return this.rootStore.appStore.router.push(`/dashboard/userDashboard/`)
    if (dashboard.userId !== this.rootStore.appStore.currentUserId)
      return this.rootStore.appStore.router.push(`/dashboard/userDashboard/`)
      this.editVM.loadDashboard(dashboard.clone())
  }

  public async cloneDashboard(dashboardId: string) {
    this.editVM.setIsProcessing()
    const dashboard = await this.getFullRecord(dashboardId)
    if (!dashboard) return this.rootStore.appStore.router.push(`/dashboard/userDashboard/`)
    if (dashboard.isDeleted) return this.rootStore.appStore.router.push(`/dashboard/userDashboard/`)
    const clonedDash = dashboard.clone()
    clonedDash.objectId = null
    clonedDash.name = clonedDash.name + ' (Copy)'
    clonedDash.userId = this.rootStore.appStore.currentUserId
    clonedDash.sharedWith = []
    this.editVM.loadDashboard(clonedDash.clone())
  }

  ////stuff below to remove

  @computed
  public get fillerDash() {
    return new DashboardService().getGoalsDashboard()
  }
}
