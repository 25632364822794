import React from 'react'
import clsx from 'clsx'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../localization/LocalizationStore'
import { GroupsAndRolesStore } from '../GroupsAndRolesStore'
import { Button, ButtonBase } from '@material-ui/core'

interface WidgetTitleProps {
  localizationStore?: LocalizationStore
  groupsAndRolesStore?: GroupsAndRolesStore
}

const WidgetTitle: React.FC<WidgetTitleProps> = ({ localizationStore, groupsAndRolesStore }) => {
  const { lzStrings } = localizationStore
  const { groupsAndRolesWidgetVM: vm } = groupsAndRolesStore

  if (!vm) return

  const handleGroupsClick = () => {
    vm.setTabIndex(0)
  }

  const handleRolesClick = () => {
    vm.setTabIndex(1)
  }

  return (
    <div className='widget-title-tabs'>
      <span>{lzStrings.rolesEdit.manage_groups_role}</span>
      <div className='tab-btns'>
        <ButtonBase
          onClick={handleGroupsClick}
          className={clsx('tab-button', {
            active: vm.tabIndex === 0,
          })}
        >
          {lzStrings.dashboard.groups}
        </ButtonBase>
        <ButtonBase
          onClick={handleRolesClick}
          className={clsx('tab-button', {
            active: vm.tabIndex === 1,
          })}
        >
          {lzStrings.dashboard.roles}
        </ButtonBase>
      </div>
    </div>
  )
}

export default inject('localizationStore', 'groupsAndRolesStore')(observer(WidgetTitle))
