import React from 'react'
import {
  Button,
  AppBar,
  Toolbar,
  Typography,
  TextField,
  IconButton,
  Menu,
  MenuItem,
  LinearProgress,
} from '@material-ui/core'
import BackIcon from '@material-ui/icons/KeyboardBackspace'
import { AppStore } from '../../../stores/AppStore'
import { inject, observer } from 'mobx-react'
import ReplayIcon from '@material-ui/icons/Replay'
import LocalizationStore from '../../../localization/LocalizationStore'
import './TopToolbar.scss'
import { TrainingPlansStore } from '../../store/TrainingPlansStore'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import DeleteIcon from '@material-ui/icons/Delete'
import ArchiveIcon from '@material-ui/icons/Archive'
import { TabEnums } from '../../types/TabsEnums'
import MuiConfirmDeleteDialog from 'src/app/shared/MuiConfirmDeleteDialog'

interface Props {
  localizationStore?: LocalizationStore
  trainingPlansStore?: TrainingPlansStore
  appStore?: AppStore
}

const TopToolbar: React.FC<Props> = ({ appStore, trainingPlansStore, localizationStore }) => {
  const { trainingPlanEditVM: vm } = trainingPlansStore
  const { training_plans: strings } = localizationStore.lzStrings
  if (!vm) return null

  const renderMenu = () => {
    if (!vm.objectId) return null
    return (
      <>
        <IconButton onClick={(e) => vm.setAnchorEl(e.currentTarget)}>
          <MoreHorizIcon className='menu-icon' />
        </IconButton>
        <Menu
          id='topMenu'
          anchorEl={vm.menuAnchorEl}
          keepMounted
          open={Boolean(vm.menuAnchorEl)}
          onClose={() => vm.setAnchorEl(null)}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          style={{ marginTop: 40 }}
        >
          {renderDelete()}
          {renderRefresh()}
          {renderArchive()}
        </Menu>
      </>
    )
  }

  const renderBackButton = () => {
    return (
      <Button
        variant='text'
        aria-label='back'
        onClick={() => {
          if (appStore.router.location.pathname.startsWith('/trainingPlans/from-catalog/')) {
            appStore.router.push('/dashboard/impact/catalog')
          } else {
            appStore.router.push('/dashboard/impact/trainings')
          }
        }}
      >
        <BackIcon className='back-icon' />
        {strings.back_to_impact}
      </Button>
    )
  }

  const renderTrainingPlanTitle = () => {
    return (
      <TextField
        error={!vm.titleValid}
        value={vm.title}
        onChange={(e) => vm.setTitle(e.target.value)}
        placeholder={vm.objectId ? strings.edit_training_plan : strings.create_training_plan}
        variant='standard'
      />
    )
  }

  const renderPreviewButton = () => {
    if (vm.tabsDisabled) return null
    if (vm.currentTab === TabEnums.PREVIEW) return null
    return (
      <Button variant='outlined' onClick={() => vm.setCurrentTab(TabEnums.PREVIEW)}>
        {strings.preview}
      </Button>
    )
  }

  const renderRefresh = () => {
    if (!vm.objectId) return null
    return (
      <MenuItem className='refresh-icon' onClick={() => vm.refresh()}>
        <ReplayIcon /> Refresh
      </MenuItem>
    )
  }

  const renderConfirmDelete = () => {
    if (!vm.showDeleteDialog) return null
    return (
      <MuiConfirmDeleteDialog
        onClose={() => vm.toggleDeleteDialog()}
        open={vm.showDeleteDialog}
        itemName={vm.title}
        itemType={'Training Plan'}
        onConfirm={() => vm.delete()}
      />
    )
  }

  const renderDelete = () => {
    if (!vm.objectId) return null
    if (vm.editIsDisabled) return null
    return (
      <MenuItem onClick={() => vm.toggleDeleteDialog()}>
        <DeleteIcon className='delete-icon' />
        Delete
      </MenuItem>
    )
  }

  const renderArchive = () => {
    if (!vm.objectId) return null
    return (
      <MenuItem onClick={() => vm.toggleConfirmDialogOpen()}>
        <ArchiveIcon className='archive-icon' />
        {vm.isArchived ? 'Restore' : 'Archive'}
      </MenuItem>
    )
  }

  const renderRightToolBar = () => {
    return (
      <>
        {renderMenu()}
        {/* {renderPreviewButton()} */}
        <Button
          onClick={() => vm.continue()}
          disabled={vm.saveProcessing || vm.saveDisabled}
          variant='contained'
        >
          {vm.currentTab === TabEnums.PREVIEW
            ? vm.objectId
              ? strings.update
              : strings.create
            : strings.continue}
        </Button>
      </>
    )
  }

  const renderSaveProgress = () => {
    if (!vm.saveProcessing) return null
    return <LinearProgress variant='determinate' value={vm.saveProgress} />
  }
  return (
    <>
      <AppBar position='static' color='default' id='TopToolbar'>
        <Toolbar className='toolbar'>
          <div slot='start' className='start'>
            {renderBackButton()}
            <Typography className='title' variant='h5'>
              {vm.objectId ? strings.edit_training_plan : strings.create_training_plan}
            </Typography>
            {renderTrainingPlanTitle()}
          </div>
          <div slot='end' className='end-buttons'>
            {/* {renderRightToolBar()} */}
          </div>
        </Toolbar>
      </AppBar>
      {renderSaveProgress()}
      {/* {renderConfirmDelete()}
      <MuiConfirmDialog
        open={vm.confirmArchiveDialogOpen}
        onClose={() => vm.toggleConfirmDialogOpen()}
        confirmMessage={vm.archiveMessage}
        onConfirm={() => vm.archivePlan()}
      /> */}
    </>
  )
}
export default inject('trainingPlansStore', 'appStore', 'localizationStore')(observer(TopToolbar))
