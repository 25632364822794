import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { UserSurveysStore } from '../../store/UserSurveysStore'
import { CardHeader, Typography, IconButton, Menu, MenuItem } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import ErrorIcon from '@material-ui/icons/ErrorOutlineRounded'
import Fade from '@material-ui/core/Fade'
import './WidgetHeader.scss'
import { UserSurveyTakeVM } from '../../view-models/UserSurveyTakeVM'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import DeleteIcon from '@material-ui/icons/Delete'
import MuiConfirmDeleteDialog from '../../../shared/MuiConfirmDeleteDialog'

interface Props {
  userSurveysStore?: UserSurveysStore
  takeVM: UserSurveyTakeVM
  localizationStore?: LocalizationStore
  disabled: boolean
  userName: string
}

const WidgetHeader: React.FC<Props> = ({
  userSurveysStore,
  takeVM,
  localizationStore,
  disabled,
  userName,
}) => {
  const strings = localizationStore.lzStrings.takeSurvey
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const renderMenu = () => {
    return (
      <>
        <IconButton
          aria-controls='simple-menu-3'
          aria-haspopup='true'
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <MoreHorizIcon />
        </IconButton>
        <Menu
          id='simple-menu-3'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          style={{ marginTop: 40 }}
        >
          <MenuItem
            onClick={() => {
              setAnchorEl(null)
              takeVM.toggleConfirmDelete()
            }}
          >
            <DeleteIcon className='icon' /> Delete Response
          </MenuItem>
        </Menu>
      </>
    )
  }

  const renderTitle = () => {
    if (takeVM && takeVM.name) return takeVM.name
    if (userSurveysStore.anonymousSurveyNotFound) {
      return (
        <Fade in timeout={1500}>
          <ErrorIcon className='survey-not-found-icon' />
        </Fade>
      )
    }
    // return <Skeleton variant='text' width='40%' height='24px' />
  }

  const renderAction = () => {
    if (disabled) return renderMenu()
    if (takeVM && takeVM.name) {
      return <Typography component='span'>* = {strings.required}</Typography>
    }
    if (userSurveysStore.anonymousSurveyNotFound) return null
    // return <Skeleton variant='text' width='250px' height='24px' />
  }

  const renderConfirmDelete = () => {
    if (!takeVM) return null
    return (
      <MuiConfirmDeleteDialog
        onClose={() => takeVM.toggleConfirmDelete()}
        open={takeVM.confirmDeleteShown}
        itemName={'From: ' + userName}
        itemType={'Survey Response'}
        onConfirm={() => takeVM.deleteSurveyResponse()}
      />
    )
  }

  return (
    <>
      <CardHeader id='WidgetHeader' title={renderTitle()} action={renderAction()} />
      {renderConfirmDelete()}
    </>
  )
}

export default inject('userSurveysStore', 'localizationStore')(observer(WidgetHeader))
