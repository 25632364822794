import React from 'react'
import { inject, observer } from 'mobx-react'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import BackIcon from '@material-ui/icons/ArrowBack'
import { EventsStore } from '../../store/EventsStore'
import LocalizationStore from '../../../localization/LocalizationStore'
import './CalendarEventDrawer.scss'

interface MediaToolbarProps {
  eventsStore?: EventsStore
  localizationStore?: LocalizationStore
}

const ParticipantsToolbar: React.FC<MediaToolbarProps> = ({ eventsStore, localizationStore }) => {
  const { lzStrings } = localizationStore
  const { editVM: vm } = eventsStore

  const handleBackClick = () => {
    vm.participantsSelectVM.setParticipants(vm.eventParticipants)

    vm.closeParticipantsTab()
  }

  const handleAddSelected = () => {
    vm.closeParticipantsTab()
  }

  return (
    <AppBar color='default' position='static' elevation={0} className='participantsToolbar'>
      <Toolbar className='root event-participants-toolbar'>
        <div className='box'>
          <span>
            <IconButton disableRipple onClick={handleBackClick}>
              <BackIcon />
            </IconButton>
          </span>
        </div>
        <div className='box'>
          <Typography variant='h5'>
            {lzStrings.tasksManagementWidgetDrawer.add_participants}
          </Typography>
        </div>
        <div className='box'>
          <span>
            <Button
              //   disabled={!vm.addSelectedParticipantsButtonEnabled}
              variant='contained'
              size='large'
              onClick={handleAddSelected}
            >
              {lzStrings.tasksManagementWidgetDrawer.apply}
            </Button>
          </span>
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default inject('eventsStore', 'localizationStore')(observer(ParticipantsToolbar))
