import { computed, observable } from 'mobx'
import moment from 'moment'
import { EventType } from '../../notifications/types/EventType'
import { RootStore } from '../../stores/RootStore'
import { SurveyParticipant } from '../aggregate/SurveyParticipant'

export class SurveyParticipantRowVM {
  private rootStore: RootStore
  private isAnonymous: boolean

  constructor(rootStore: RootStore, surveyParticipant: SurveyParticipant, isAnonymous: boolean) {
    this.rootStore = rootStore
    this.surveyParticipant = surveyParticipant
    this.isAnonymous = isAnonymous
  }

  private blankValue = '-'

  @observable public surveyParticipant: SurveyParticipant = null

  private formatDate(isoString: string) {
    if (!isoString) return this.blankValue
    return moment(isoString).format('lll')
  }

  @computed
  public get name() {
    return `${this.surveyParticipant.firstName} ${this.surveyParticipant.lastName}`
  }

  @computed
  public get userId() {
    return this.surveyParticipant.userId
  }

  @computed
  public get email() {
    return this.surveyParticipant.email
  }

  @computed
  public get emailAddressDisplay(): string {
    if (this.userId) {
      const foundUser = this.rootStore.audienceMembersStore.getUser(this.userId)
      if (!foundUser) return ''
      return foundUser.email
    } else {
      return this.email
    }
  }

  @computed
  public get userName(): string {
    if (this.surveyParticipant.firstName || this.surveyParticipant.lastName) {
      return this.name
    } else return this.surveyParticipant.email
  }

  @computed
  public get isArchived(): boolean {
    if (this.userId) {
      const foundUser = this.rootStore.audienceMembersStore.getUser(this.userId)
      if (foundUser) return foundUser.isArchived
    }
    return false
  }

  @computed
  public get invitationSent() {
    return this.formatDate(this.surveyParticipant.invitationSent)
  }

  @computed
  public get emailStatus() {
    let eventType: EventType
    let dateString: string
    if (this.surveyParticipant.publishEmailClickDate) {
      eventType = 'click'
      dateString = this.surveyParticipant.publishEmailClickDate
    } else if (this.surveyParticipant.publishEmailOpenDate) {
      eventType = 'open'
      dateString = this.surveyParticipant.publishEmailOpenDate
    } else if (this.surveyParticipant.publishEmailBounceDate) {
      eventType = 'bounce'
      dateString = this.surveyParticipant.publishEmailBounceDate
    } else if (this.surveyParticipant.publishEmailDeliverDate) {
      eventType = 'delivered'
      dateString = this.surveyParticipant.publishEmailDeliverDate
    } else if (this.surveyParticipant.publishEmailProcessedDate) {
      eventType = 'processed'
      dateString = this.surveyParticipant.publishEmailProcessedDate
    } else if (this.surveyParticipant.publishEmailCreatedDate) {
      eventType = 'created'
      dateString = this.surveyParticipant.publishEmailCreatedDate
    } else if (this.surveyParticipant.publishEmailErrorDate) {
      if (this.surveyParticipant.emailStatus.toLowerCase().indexOf('disabled') > -1)
        return 'disabled'
      eventType = 'error'
      dateString = this.surveyParticipant.publishEmailErrorDate
    } else if (this.surveyParticipant.emailStatusDate) {
      if (this.surveyParticipant.emailStatus.toLowerCase().indexOf('disabled') > -1)
        return 'disabled'
      eventType = this.surveyParticipant.emailStatus as EventType
      dateString = this.surveyParticipant.emailStatusDate
    }

    if (eventType) return `${eventType}: ${this.formatDate(dateString)}`
    else return this.blankValue
  }

  @computed
  public get viewedDate() {
    return this.formatDate(this.surveyParticipant.viewedDate)
  }

  @computed
  public get reminderLastSentAt() {
    return this.formatDate(this.surveyParticipant.reminderLastSentAt)
  }

  @computed
  public get nextReminderAt() {
    return this.formatDate(this.surveyParticipant.nextReminderAt)
  }

  @computed
  public get dueDate() {
    return this.formatDate(this.surveyParticipant.dueDate)
  }

  @computed
  public get responseDate() {
    if (this.isAnonymous && this.surveyParticipant.responseDate) {
      return '✓'
    }
    return this.formatDate(this.surveyParticipant.responseDate)
  }

  @computed
  public get isSystemAdmin() {
    if (!this.rootStore.userStore) return false
    if (!this.rootStore.userStore.user) return false
    return this.rootStore.userStore.user.isSystemAdmin
  }

  @computed
  public get surveyId(): string {
    return this.surveyParticipant.surveyId
  }

  @computed
  public get userSurveyId(): string {
    if (!this.surveyParticipant) return ''
    return this.surveyParticipant.userSurveyId
  }

  @computed
  public get idToSend(): string {
    if (this.userId) return this.userId
    else return this.email
  }

  public toDTO() {
    return {
      userId: this.userId,
      email: this.userId ? this.emailAddressDisplay : this.email,
      firstName: this.surveyParticipant.firstName,
      lastName: this.surveyParticipant.lastName,
      type: this.userId ? 'user' : 'non-user',
      userSurveyId: this.userSurveyId,
    }
  }

  @computed
  public get nextInvitationAt() {
    return this.formatDate(this.surveyParticipant.nextInvitationAt)
  }

  @computed
  public get canViewInvitationHistory(): boolean {
    if (this.rootStore.appStore.canViewPulseInvitationHistory) return true
    return false
  }
}
