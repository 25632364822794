import React from 'react'

//MobX Stores
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { SurveyTagsStore } from '../../store/SurveyTagsStore'
import { LabelsStore } from '../../../labels/store/LabelsStore'

//MaterialUI Components
import { CardHeader, IconButton } from '@material-ui/core'

//Material Icons
import CloseIcon from '@material-ui/icons/Close'

//Components Props Definition
interface DrawerHeaderProps {
  localizationStore?: LocalizationStore
  surveyTagsStore?: SurveyTagsStore
  labelsStore?: LabelsStore
}

//Component
const DrawerHeader: React.FC<DrawerHeaderProps> = ({
  localizationStore,
  surveyTagsStore,
  labelsStore,
}) => {
  const { surveyTags: strings } = localizationStore.lzStrings

  const handleClose = () => {
    surveyTagsStore.closeWidgetDrawer()
  }

  return (
    <CardHeader
      action={
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      }
      title={`${labelsStore.getLabelByLanguageAndFor(
        'pulse'
      )} ${labelsStore.getLabelByLanguageAndFor('tag')}`}
    />
  )
}

export default inject('localizationStore', 'surveyTagsStore', 'labelsStore')(observer(DrawerHeader))
