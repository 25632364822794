import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { ContactsStore } from '../../store/ContactsStore'
import { Card, CardContent, Drawer } from '@material-ui/core'
import DrawerHeader from './DrawerHeader'
import DrawerButtonControls from './DrawerButtonControls'
import ContactInfo from './ContactInfo'
import '../ContactListWidget.scss'

interface ContactDrawerProps {
  localizationStore?: LocalizationStore
  contactsStore?: ContactsStore
}

const ContactDrawer: React.FC<ContactDrawerProps> = ({ localizationStore, contactsStore }) => {
  return (
    <Drawer
      id='ContactDrawer'
      anchor='right'
      open={contactsStore.isWidgetDrawerOpen}
      onClose={() => contactsStore.toggleWidgetDrawer()}
    >
      <Card elevation={0}>
        <DrawerHeader />
        <DrawerButtonControls />
        <CardContent>
          <ContactInfo />
        </CardContent>
      </Card>
    </Drawer>
  )
}

export default inject('localizationStore', 'contactsStore')(observer(ContactDrawer))
