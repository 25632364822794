import { Button, Tooltip } from '@material-ui/core'
import { observer } from 'mobx-react'
import React from 'react'
import ArchivedLabel from '../../../shared/ArchivedLabel'
import { OrganizationUsersListRowVM } from '../../view-models/list/OrganizationUsersListRowVM'
import './NameCell.scss'

const NameCell = (props) => {
  const data: OrganizationUsersListRowVM = props.data
  if (!data) return <div></div>

  const renderArchivedLabel = () => {
    if (!data.archived) return
    return <ArchivedLabel />
  }

  return (
    <div id='NameCell'>
      <Tooltip title={'Edit User'}>
        <span onClick={(e) => data.click()} className={data.archived ? 'archived-style' : ''}>
          {data.fullName}
          {renderArchivedLabel()}
        </span>
      </Tooltip>
    </div>
  )
}

export default observer(NameCell)
