import React from 'react'
import { inject, observer } from 'mobx-react'
import { Button, IconButton, Snackbar } from '@material-ui/core'
import { UserDashboardsStore } from '../../user-dashboards/UserDashboardsStore'
import CloseIcon from '@material-ui/icons/Close'

interface Props {
  userDashboardsStore?: UserDashboardsStore
}

const FavoriteSnackbar: React.FC<Props> = ({ userDashboardsStore }) => {
  const vm = userDashboardsStore.viewModels.userDashboardVM
  if (!vm) return null

  const renderMessage = () => {
    if (vm.isFavorite) return 'Dashboard added to favorites.'
    return 'Dashboard removed from favorites.'
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={vm.showFavoriteSnackbar}
      autoHideDuration={6000}
      onClose={() => vm.toggleShowFavoriteSnackbar()}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={<span id='message-id'>{renderMessage()}</span>}
      action={[
        <Button
          key='undo'
          id='UndoButton'
          color='secondary'
          size='small'
          onClick={() => vm.toggleIsFavorite()}
        >
          UNDO
        </Button>,
        <IconButton
          key='close'
          aria-label='close'
          color='inherit'
          onClick={() => vm.toggleShowFavoriteSnackbar()}
        >
          <CloseIcon />
        </IconButton>,
      ]}
    />
  )
}

export default inject('userDashboardsStore')(observer(FavoriteSnackbar))
