export enum SurveyEditTabsEnum {
  DETAILS = 0,
  BUILDER = 1,
  OPTIONS = 2,
  PARTICIPANTS = 3,
  FEEDBACK = 4,
  PREVIEW = 5,
  PUBLISH = 6,
  ANALYSIS = 7,
  RESPONSES = 8,
  SCHEDULE = 9,
  INVITATIONS = 10,
}
