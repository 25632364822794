import React from 'react'
import { Card } from '@material-ui/core'
import { observer, inject } from 'mobx-react'
import './MyGoalsWidget.scss'
import Header from './Header'
import Content from './Content'
import { UserGoalsStore } from '../store/UserGoalsStore'

interface Props {
  userGoalsStore?: UserGoalsStore
}

const MyGoalsWidget: React.FC<Props> = ({ userGoalsStore }) => {
  const { myGoalsWidgetVM: vm } = userGoalsStore
  if (!vm) return null

  return (
    <>
      <Card id='MyGoalsWidget' elevation={0}>
        <Header />
        <Content />
      </Card>
    </>
  )
}

export default inject('userGoalsStore')(observer(MyGoalsWidget))
