import React from 'react'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { DateRangePicker } from 'react-date-range'
import { Grid, DialogTitle, IconButton, Button } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import CloseIcon from '@material-ui/icons/Close'
import staticRanges from './staticRanges'
import './DateRangeModal.scss'

interface Props {
  onChange?: (range: any) => void
  showSelectionPreview?: boolean
  moveRangeOnFirstSelection?: boolean
  months?: number
  ranges?: Object
  direction?: string
  range?: any
  open: boolean
  onClose: () => void
}

const DateRange: React.FC<Props> = ({ range, onChange, open, onClose }) => {
  if (!range) return null

  const renderHeader = () => {
    return (
      <Grid className='header' container justifyContent='space-between' alignItems='center'>
        <Grid item>
          <DialogTitle>Goal Duration</DialogTitle>
        </Grid>
        <Grid item>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    )
  }

  const renderFooter = () => {
    return (
      <Grid container className='footer' justifyContent='flex-end' alignItems='center'>
        <Button variant='outlined' onClick={onClose} className='modal-button'>
          Ok
        </Button>
      </Grid>
    )
  }

  return (
    <Dialog id='DateRangeModal' open={open} onClose={onClose}>
      {renderHeader()}
      <DateRangePicker
        onChange={(range) => onChange(range.range1)}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={1}
        staticRanges={staticRanges}
        ranges={[range]}
        direction='horizontal'
      />
      {renderFooter()}
    </Dialog>
  )
}
export default DateRange
