import React from 'react'
import { inject, observer } from 'mobx-react'
import { Button } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { UserSurveyTakeVM } from '../../view-models/UserSurveyTakeVM'

interface Props {
  takeVM: UserSurveyTakeVM
}

const LinearNavRow: React.FC<Props> = ({ takeVM }) => {

  const renderMediaNextButton = () => {
    if (takeVM.showSurveyIntroductionPage) return null
    if (!takeVM.currentQuestion) return null
    if (!takeVM.currentQuestion.isMediaFirstQuestion) return null
    if (takeVM.currentQuestion.isMediaFirstQuestionAndMediaNotShown) return null
    return (
      <Button
        variant='outlined'
        className='sln-survey-next-button'
        onClick={() => takeVM.toggleShowCurrentQuestionMedia()}
      >
        Next
      </Button>
    )
  }

  const renderDefaultNextButton = () => {
    if (!takeVM.currentQuestion) return null
    if (takeVM.currentQuestion.isMediaFirstQuestionAndMediaShown && takeVM.currentQuestionIndex !== 0) return null
    if (takeVM.currentQuestion.isMediaFirstQuestionAndMediaShown && takeVM.currentQuestionIndex === 0 && !takeVM.showSurveyIntroductionPage) return null
    return (
      <>
        {(takeVM.currentQuestionIndex !== takeVM.currentBranchQuestions.length - 1 ||
          takeVM.showSurveyIntroductionPage) && (
            <Button
              disabled={
                takeVM.currentQuestion.required &&
                !takeVM.currentQuestion.hasAnswer &&
                !takeVM.showSurveyIntroductionPage
              }
              variant='outlined'
              className='sln-survey-next-button'
              onClick={() => takeVM.handleNextQuestion()}
            >
              Next
            </Button>
          )}
      </>
    )
  }

  const renderSubmitButton = () => {
    if (!takeVM.currentQuestion) return null
    if (takeVM.currentQuestion.isMediaFirstQuestionAndMediaShown) return null
    return (
      <>
        {takeVM.isComplete &&
          takeVM.currentQuestionIndex === takeVM.currentBranchQuestions.length - 1 &&
          !takeVM.showSurveyIntroductionPage && (
            <Button
              variant='contained'
              color='primary'
              disabled={!takeVM.isComplete}
              className='sln-survey-submit-button'
              onClick={() => takeVM.submit()}
            >
              Submit
            </Button>
          )}
      </>
    )
  }

  return (
    <div id='LinearNavRow'>
      <div className='hr' />
      {!takeVM.isSubmitting ? (
        <div>
          {(takeVM.currentQuestionIndex !== 0 ||
            (takeVM.currentQuestionIndex === 0 &&
              takeVM.questionsFlowFormat === 'paged' &&
              takeVM.hasSurveyIntroduction &&
              !takeVM.showSurveyIntroductionPage)) && (
              <Button
                variant='outlined'
                onClick={() => takeVM.handlePreviousQuestion()}
                className='sln-survey-next-button'
              >
                Back
              </Button>
            )}
          &nbsp;
          {renderMediaNextButton()}
          {renderDefaultNextButton()}
          {renderSubmitButton()}
        </div>
      ) : (
        <CircularProgress className='spinner' />
      )}
    </div>
  )
}

export default observer(LinearNavRow)
