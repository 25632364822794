import { observable, action, computed } from 'mobx'
import TaskTemplate from '../aggregate/TaskTemplate'
import { RootStore } from '../../../stores/RootStore'
import { ParseService } from '../../../services/ParseService'
import { ParticipantForm } from '../forms/ParticipantForm'

export default class TaskTypeDrawerVM {
  rootStore: RootStore

  constructor(rootStore: RootStore, taskTemplate: TaskTemplate = null) {
    this.rootStore = rootStore
    if (taskTemplate) this.loadData(taskTemplate)
  }

  private loadData(taskTemplate: TaskTemplate) {
    this.taskTemplate = taskTemplate
    this.objectId = taskTemplate.objectId
    this.name = taskTemplate.name
    this.participantForms = []
    this.participantForms = taskTemplate.participants.map(
      (e) => new ParticipantForm(this.rootStore, e)
    )
  }

  public taskTemplate: TaskTemplate
  @observable public objectId: string = ''
  @observable public name: string = ''
  @observable public participantForms: Array<ParticipantForm> = []
  @observable public saveTried: boolean = false

  @action
  public addParticipant() {
    this.participantForms.push(new ParticipantForm(this.rootStore, null))
  }

  @action
  public removeParticipant(idx) {
    this.participantForms.splice(idx, 1)
  }

  @computed
  public get isValid(): boolean {
    if (!this.saveTried) return true
    if (!this.nameValid) return false
    if (!this.nameUnique) return false
    if (!this.participantsValid) return false
    return true
  }

  @computed get isEditMode() {
    return Boolean(this.objectId)
  }

  @computed
  public get errorHelperText() {
    if (!this.nameValid) return 'Please enter a name for this work type.'
    if (!this.nameUnique) return 'This name is being used by another work type.'
  }

  @computed
  public get nameValid() {
    if (!this.saveTried) return true
    if (!this.name || this.name === '') return false
    return true
  }

  @computed
  public get nameUnique() {
    if (!this.saveTried) return true
    const foundTaskType = this.rootStore.taskTemplatesStore.getTaskTemplateByName(this.name)
    if (foundTaskType && foundTaskType.objectId !== this.objectId) return false
    return true
  }

  @computed
  public get participantsValid(): boolean {
    if (!this.saveTried) return true
    return this.participantForms.filter((frm) => !frm.isValid).length === 0
  }

  @computed
  public get roleOptions() {
    let availableRoles = []
    let roles = this.rootStore.rolesStore.roles
    for (let role of roles) {
      availableRoles.push(role.name)
    }
    return availableRoles
  }

  @action
  public markSaveTried() {
    this.saveTried = true
    this.participantForms.forEach((frm) => (frm.saveTried = true))
  }

  @action
  public async save() {
    this.markSaveTried()
    if (!this.isValid) return false
    this.rootStore.taskTemplatesStore.showDrawer = false

    try {
      const newTaskTemplate = {
        objectId: this.objectId ? this.objectId : null,
        name: this.name,
        participants: this.participantForms.map((e) => e.toDTO()),
      }

      await new ParseService().saveTaskTemplate(
        this.rootStore.appStore.currentOrgId,
        newTaskTemplate
      )
      this.rootStore.taskTemplatesStore.clearForms()
    } catch (e) {
      console.error(e)
    }
  }

  @action
  public async delete() {
    try {
      this.rootStore.taskTemplatesStore.showDeleteDialog = false
      this.rootStore.taskTemplatesStore.showDrawer = false
      await new ParseService().deleteTaskTemplate(
        this.rootStore.appStore.currentOrgId,
        this.objectId
      )
      this.rootStore.taskTemplatesStore.clearForms()
    } catch (e) {
      console.error(e)
    }
  }
}
