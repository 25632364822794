import React from 'react'
import { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../localization/LocalizationStore'
import {
  Avatar,
  Grid,
  TextField,
  ClickAwayListener,
  Tooltip,
  Button,
  Typography,
  Box,
} from '@material-ui/core'
import moment from 'moment'
import './TaskCommentCard.scss'
import { TaskCommentEditVM } from '../view-models/TaskCommentEditVM'
import EditIcon from '@material-ui/icons/Edit'
import MuiConfirmDeleteDialog from '../../shared/MuiConfirmDeleteDialog'
import DescriptionIcon from '@material-ui/icons/Description'
import VideoIcon from '@material-ui/icons/VideocamRounded'
import Linkify from 'react-linkify'
import CloseIcon from '@material-ui/icons/Close'
import clsx from 'clsx'

interface Props {
  localizationStore?: LocalizationStore
  comment: TaskCommentEditVM
}

const TaskCommentCard: React.FC<Props> = ({ comment, localizationStore }) => {
  const { lzStrings } = localizationStore
  if (!comment.commentedBy) return null

  const calculatePostAgeInSeconds = (datePosted: number): number => {
    const posted = moment.unix(datePosted)
    const now = moment()
    const differenceInSeconds = now.diff(posted, 'seconds')
    return differenceInSeconds
  }

  const [isOldPost, setIsOldPost] = useState(calculatePostAgeInSeconds(comment.datePosted) > 30)

  useEffect(() => {
    let interval = null
    if (!isOldPost) {
      interval = setInterval(() => {
        const postAge = calculatePostAgeInSeconds(comment.datePosted)
        if (postAge > 30) {
          setIsOldPost(true)
          clearInterval(interval)
        }
      }, 1000)
    }
    return () => clearInterval(interval)
  })

  const renderAddAttachmentButton = () => {
    if (comment.attachment) return null
    return (
      <Button variant='outlined' className='comment-button' onClick={() => comment.showWidget()}>
        Add Attachment
      </Button>
    )
  }

  const renderTextField = () => {
    if (!comment.isInEditMode) return null
    return (
      <ClickAwayListener onClickAway={() => comment.toggleEditComment()}>
        <Grid container className='comment-edit-inputs'>
          <Grid item xs={12} onClick={(e) => e.stopPropagation()}>
            <TextField
              autoFocus
              multiline
              fullWidth
              className='comment-text-field'
              variant='outlined'
              minRows={1}
              maxRows={4}
              value={comment.editedText}
              onChange={(e) => comment.setText(e.target.value)}
            />
          </Grid>
          <Grid container direction='row-reverse'>
            <Tooltip title='Save changes' placement='bottom'>
              <Button
                variant='outlined'
                className='comment-button'
                onClick={(e) => {
                  e.stopPropagation()
                  comment.saveTaskComment()
                }}
              >
                Save Changes
              </Button>
            </Tooltip>
            <Tooltip title='Delete comment' placement='bottom'>
              <Button
                variant='outlined'
                className='comment-button'
                onClick={() => comment.toggleDeleteDialog()}
              >
                Delete
              </Button>
            </Tooltip>
            {renderAddAttachmentButton()}
          </Grid>
        </Grid>
      </ClickAwayListener>
    )
  }

  const componentDecorator = (href, text, key) => (
    <a href={href} key={key} target='_blank'>
      {text}
    </a>
  )

  const renderCommentText = () => {
    if (comment.isInEditMode || !comment.text) return null
    return (
      <div
        className={comment.isUserOwned ? 'comment-action comment-text' : 'comment-text'}
        onClick={() => comment.toggleEditComment()}
      >
        <span
          className='comment-text-wrapper'
          onClick={(e) => {
            e.stopPropagation()
            comment.toggleEditComment()
          }}
        >
          <Linkify componentDecorator={componentDecorator}>{comment.text}</Linkify>
        </span>
      </div>
    )
  }

  const renderEditIcon = () => {
    if (!comment.editIconShown) return null
    return (
      <span onMouseEnter={() => comment.showEditIcon()} onClick={() => comment.toggleEditComment()}>
        <EditIcon className='edit-icon' onClick={() => comment.toggleEditComment()} />
      </span>
    )
  }

  const renderAttachment = () => {
    if (!comment.showAttachment) return null
    return (
      <Grid item xs={5} className='task-comment-attachment-row'>
        <span className='thumbnail-file-field'>
          {renderAttachmentThumbnail()}
          <Typography
            variant='body1'
            className='attachment-file'
            onClick={(e) => {
              e.stopPropagation()
              comment.openAttachment()
            }}
          >
            {comment.attachment.fileName}
          </Typography>
        </span>
        <span className='delete'>{renderDeleteIcon()}</span>
      </Grid>
    )
  }

  const renderAttachmentThumbnail = () => {
    if (comment.attachment.isImage) {
      return (
        <div
          className='img-container'
          style={{ backgroundImage: `url("${comment.attachment.url}")` }}
        />
      )
    }
    if (comment.attachment.isVideo) {
      if (comment.attachment.url) {
        if (comment.attachment.url.includes('vimeo')) return <VideoIcon />
        if (comment.attachment.url.includes('youtube') || comment.attachment.url.includes('youtu.be')) return <VideoIcon />
        if (comment.attachment.url.startsWith('http')) {
          return (
            <div className='img-container'>
              <video>
                <source src={comment.attachment.url} type='video/mp4' />
              </video>
            </div>
          )
        }
        else return <VideoIcon />
      }
      else return <VideoIcon />
    }
    return <DescriptionIcon />
  }

  const renderDeleteIcon = () => {
    if (!comment.showDeleteIcon) return null
    return (
      <CloseIcon
        onClick={(e) => {
          e.stopPropagation()
          comment.deleteAttachmentFromComment()
        }}
      />
    )
  }

  return (
    <>
      <div
        id='TaskCommentCard'
        className={comment.isUserOwned ? 'editable' : ''}
        onMouseOver={() => comment.showEditIcon()}
        onMouseOut={() => comment.hideEditIcon()}
        onClick={() => comment.toggleEditComment()}
      >
        <div className='task-comment-card-left'>
          <Avatar
            className='comments-avatar'
            alt={`${comment.commentedBy.name}'s Avatar`}
            src={comment.commentedBy.iconURL}
          />
        </div>
        <div
          className={
            comment.isUserOwned
              ? 'task-comment-card-right comment-body-editable'
              : 'task-comment-card-right comment-body'
          }
        >
          <div className='task-comment-card-name-date-status'>
            <span className={comment.isUserOwned ? 'editable' : ''}>
              {comment.commentedBy.name}
            </span>
            <span className={comment.isUserOwned ? 'editable' : ''}>
              {isOldPost
                ? moment
                    .unix(comment.datePosted)
                    .format(lzStrings.tasksManagementWidgetDrawer.date_posted_format)
                : lzStrings.tasksManagementWidgetDrawer.moment_ago}
              {renderEditIcon()}
            </span>
          </div>
          <Grid item xs={12}>
            {renderCommentText()}
            {renderTextField()}
            {renderAttachment()}
          </Grid>
        </div>

        <MuiConfirmDeleteDialog
          onClose={() => comment.toggleDeleteDialog()}
          open={comment.deleteDialogOpen}
          itemName={comment.text}
          itemType={'task comment'}
          onConfirm={() => comment.deleteTaskComment()}
        />
      </div>
    </>
  )
}

export default inject('localizationStore')(observer(TaskCommentCard))
