import { computed, action } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { UserSurvey } from '../../aggregate/UserSurvey'
import moment from 'moment'
import { PendingUserSurveysWidgetVM } from './PendingUserSurveysWidgetVM'
import { AGGridRowVM } from '../../../shared/ag-grid/AGGridRowVM'

export class RowVM extends AGGridRowVM<UserSurvey> {
  constructor(rootStore: RootStore, userSurvey: UserSurvey, widgetVM: PendingUserSurveysWidgetVM) {
    super(rootStore, userSurvey, widgetVM)
  }

  @computed
  public get objectId() {
    return this.aggregate.objectId
  }

  @computed
  public get name() {
    return this.aggregate.name
  }

  @computed
  public get dueDate() {
    const s = this.rootStore.localizationStore.lzStrings.surveys
    if (!this.aggregate.dueDate) return '--'
    return moment(this.aggregate.dueDate).format(s.due_date_format2)
  }

  @computed
  public get fromUser() {
    return this.rootStore.audienceMembersStore.getUser(this.aggregate.publishedByUserId)
  }

  @computed
  public get fromUserIconURL(): string {
    if (!this.fromUser) return undefined
    return this.fromUser.iconURL
  }

  @computed
  public get fromUserId(): string {
    if (!this.aggregate.publishedByUserId) return ''
    return this.aggregate.publishedByUserId
  }

  @computed
  public get fromUserName(): string {
    if (!this.fromUser) return '--'
    return this.fromUser.name
  }

  @computed
  public get publishedDateTime() {
    return this.aggregate.publishedDateTime
  }

  @computed
  public get publishedDateFormatted() {
    const s = this.rootStore.localizationStore.lzStrings.surveys
    return moment(this.aggregate.publishedDateTime).format(s.publish_date_format)
  }

  @computed
  public get questions() {
    return this.aggregate.questionsCount
  }

  @action
  public openUserSurvey() {
    this.rootStore.appStore.router.push('/usersurveys/take/' + this.objectId)
  }

  @computed
  public get categoryNames(): string {
    const cats = []
    this.aggregate.categoryIds.forEach((e) => {
      const foundCat = this.rootStore.categoriesStore.getCategory(e)
      if (foundCat) cats.push(foundCat.name)
    })
    return cats.sort((a, b) => (a < b ? -1 : 0)).join(', ')
  }
}
