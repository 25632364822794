import { UserSurvey } from '../aggregate/UserSurvey'
import { Option } from '../../surveys/aggregate/Option'
import { serialize } from 'serializr'
import uuid from 'uuid/v4'

export class UserSurveysCleanService {
  public cleanData(userSurvey: UserSurvey) {
    // ensure unique question ids
    if (
      userSurvey.questions.length > 1 &&
      userSurvey.questions[0].rank === 0 &&
      userSurvey.questions[1].rank === 0
    ) {
      userSurvey.questions.forEach((q, idx) => {
        q.rank = idx
      })
    }

    userSurvey.questions.forEach((q, idx) => {
      // apply id to questions if no id
      if (!q.id) {
        q.id = q.rank ? q.rank.toString() : idx.toString()
      }
      // fix text question
      if (q.options.length === 0 && q.type === 'text') {
        const opt = {
          rank: 0,
          id: uuid(),
          text: '',
          value: null,
          nextQuestionId: null,
        }
        const option = serialize(Option, opt)
        q.options.push(option)
      }

      q.options.forEach((opt) => {
        if (opt.iconName) opt.emoji = opt.iconName
      })

      // ensure rating questions have values
      if (q.type === 'rating' || q.type === 'starRating') {
        let shouldRewriteValues = false
        const checked = []
        q.options.forEach((opt) => {
          if (shouldRewriteValues) return // stop checking
          const same = checked.find((e) => e.value === opt.value)
          if (same || !opt.value) {
            shouldRewriteValues = true
            return
          }
          checked.push(opt)
        })
        if (shouldRewriteValues) {
          q.options.forEach((opt, i) => {
            if (!opt.rank) {
              opt.value = i + 1
              opt.rank = i + 1
            }
            else opt.value = opt.rank
          })
        }
      }

      // ensure unique option ids
      let shouldRewrite = false
      if (q.options.length > 1 && q.options[0].id === '' && q.options[1].id === '') {
        shouldRewrite = true
      }
      if (q.options.length > 1 && (!q.options[0].id || q.options[0].id === '')) {
        shouldRewrite = true
      }
      if (shouldRewrite) {
        q.options.forEach((opt, idx) => {
          opt.id = idx.toString()
          opt.rank = idx
        })
      }
    })
  }
}
