import React from 'react'
import '../ContactListWidget.scss'
import { inject, observer } from 'mobx-react'
import { ContactsStore } from '../../store/ContactsStore'
import { AgGridReact } from 'ag-grid-react/lib/agGridReact'
import AGGridWidgetPagingRow from '../../../shared/ag-grid/AGGridPagingRow'

interface Props {
  contactsStore?: ContactsStore
}

const ContactsListTable: React.FC<Props> = ({ contactsStore }) => {
  const vm = contactsStore.contactListWidgetVM
  if (!vm) return null
  if (!vm.shouldRender) return null

  const renderGrid = () => {
    return (
      <div className='ag-theme-alpine' style={{ height: '100%', width: '100%' }}>
         <AgGridReact gridOptions={{
          ...vm.getGridOptions()
        }} />
      </div>
    )
  }

  return (
    <>
      {renderGrid()}
      <AGGridWidgetPagingRow vm={vm} />
    </>
  )
}

export default inject('contactsStore')(observer(ContactsListTable))
