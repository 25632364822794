export class AGGridTooltip {
  private eGui: HTMLDivElement

  public init(params) {
    // console.log(params)
    // const data = params.api.getDisplayedRowAtIndex(params.rowIndex).data
    const eGui = (this.eGui = document.createElement('div'))
    eGui.classList.add('ag-grid-tooltip')
    this.eGui.innerHTML = `${params.value}`
  }

  public getGui() {
    return this.eGui
  }
}
