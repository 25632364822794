import React from 'react'
import { observer } from 'mobx-react'
import clsx from 'clsx'
import { Typography, Grid, Slider } from '@material-ui/core'
import StarIcon from '@material-ui/icons/Star'
import StarOutlineIcon from '@material-ui/icons/StarOutline'
import { makeStyles } from '@material-ui/core/styles'
import './SurveyResultsScreen.scss'
import CurrentQuestionOptionResultRow from './CurrentQuestionOptionResultRow'
import { QuestionVM } from '../view-models/QuestionVM'
import { SurveyResultsVM } from '../view-models/SurveyResultsVM'
import rootStore from '../../stores/RootStore'
import Rating from '@material-ui/lab/Rating'
import isMobile from '../../../utils/isMobile'

interface Props {
  question: QuestionVM
  resultsVM: SurveyResultsVM
}

const QuestionView: React.FC<Props> = ({ resultsVM, question }) => {
  if (!resultsVM) return null
  const lz = rootStore.localizationStore.lzStrings.surveyResults
  if (!question) return

  const styles = makeStyles((theme) => ({
    vertical: {
      height: isMobile
        ? `${question.options.length * 3 * 12 + 6}px !important`
        : `${question.options.length * 2 * 19 + 10}px !important`,
    },
    markLabelMobile: {
      fontSize: '0.8em',
      minWidth: '100px !important',
      maxHeight: `${15 * 3}px !important`,
    },
  }))()

  const renderCurrentQuestionResultRows = () => {
    return question.currentOptionsResults.map((o, i) => {
      return (
        <CurrentQuestionOptionResultRow
          index={i}
          key={i}
          result={o}
          question={question}
          resultsVM={resultsVM}
        />
      )
    })
  }

  const renderOrderQuestionAverageResultRows = () => {
    return question.orderOptionsAverageResults.map((o, i) => {
      return (
        <CurrentQuestionOptionResultRow
          index={i}
          key={i}
          result={o}
          question={question}
          resultsVM={resultsVM}
        />
      )
    })
  }

  const createMarksArray = () => {
    const marks: Array<any> = []
    question.options.forEach((e) => {
      marks.push({
        value: e.value,
        label: e.text + (e.subText ? '\r\n' + e.subText : ''),
      })
    })
    return marks
  }

  const renderSliderAnswer = () => {
    const isVertical = (isMobile || question.question.ratingDisplay === 'vertical') && question.type !== 'starRating'
    const marks = createMarksArray()

    return (
      <Grid className={isMobile ? 'question-results-mobile' : 'question-results'} container spacing={3} direction={'column'}>
        <Grid className='label' item xs>
          <Typography className='rating-answer'>{lz.your_answer}:</Typography>
        </Grid>
        <Grid className='rating-slider' item xs>
            <Slider
              orientation={isVertical ? 'vertical' : 'horizontal'}
              disabled={true}
              key={'slider-read-only' + question.question.id + '_' + question.myRatingAnswer}
              className={clsx({
                'has-answer': question.myRatingAnswer !== undefined,
                'is-mobile': isMobile,
              })}
              classes={{
                vertical: isVertical ? styles.vertical : null,
                markLabel: isMobile ? styles.markLabelMobile : null,
              }}
              component='div'
              min={1}
              max={question.options.length}
              marks={marks}
              defaultValue={question.myRatingAnswer}
              valueLabelDisplay='off'
              step={0.1}
            />
        </Grid>
        <Grid className='label' item xs>
          <Typography className='rating-result'>{lz.average_answer}:</Typography>
        </Grid>
        <Grid className='rating-slider' item xs>
            <Slider
              orientation={isVertical ? 'vertical' : 'horizontal'}
              disabled={true}
              key={'slider-read-only' + question.question.id + '_' + question.ratingAverage}
              className={clsx({
                'has-answer': question.ratingAverage !== undefined,
                'is-mobile': isMobile,
              })}
              classes={{
                vertical: isVertical ? styles.vertical : null,
                markLabel: isMobile ? styles.markLabelMobile : null,
              }}
              component='div'
              min={1}
              max={question.options.length}
              marks={marks}
              defaultValue={question.ratingAverage}
              valueLabelDisplay='off'
              step={0.1}
            />
        </Grid>
      </Grid>
    )
  }

  const renderRatingAnswer = () => {
    return (
      <Grid className={isMobile ? 'question-results-mobile' : 'question-results'} container spacing={3} direction={'column'}>
        <Grid className={'label'} item xs>
          <Typography className='rating-answer'>{`${lz.your_answer}: ${question.myRatingText}`}</Typography>
        </Grid>
        <Grid className='rating-stars' item xs>
        <Rating
          name={'my-rating' + question.question.id + '_' + question.myRatingAnswer}
          key={'my-rating' + question.question.id + '_' + question.myRatingAnswer}
          className={clsx({
            'is-mobile': isMobile,
          })}
          disabled
          value={question.myRatingAnswer}
          precision={0.1}
          max={question.options ? question.options.length : 0}
          icon = {<StarIcon className={'star-icon'}></StarIcon>}
          emptyIcon = {<StarOutlineIcon className={'star-icon'}></StarOutlineIcon>}
        />
        </Grid>
        <Grid className={'label'} item xs>
          <Typography className='rating-result'>{`${lz.average_answer}: ${question.ratingAverageText}`}</Typography>
        </Grid>
        <Grid className='rating-stars' item xs>
        <Rating
          name={'ave-rating' + question.question.id + '_' + question.ratingAverage}
          key={'ave-rating' + question.question.id + '_' + question.ratingAverage}
          className={clsx({
            'is-mobile': isMobile,
          })}
          disabled
          value={question.ratingAverage}
          precision={0.1}
          max={question.options ? question.options.length : 0}
          icon = {<StarIcon className={'star-icon'}></StarIcon>}
          emptyIcon = {<StarOutlineIcon className={'star-icon'}></StarOutlineIcon>}
        />
        </Grid>
      </Grid>
    )
  }

  const renderBorder = () => {
    if (!resultsVM.showSummaryPage) return null
    return <Grid />
  }

  const renderOrderAnswer = () => {
    return (
      <Grid className={isMobile ? 'question-results-mobile' : 'question-results'} container spacing={3} direction={'column'}>
        <Grid className={'label'} item xs>
          <Typography className='rating-answer'>{lz.your_answer}:</Typography>
        </Grid>
        <Grid className={'order-container'} item xs>
          {renderCurrentQuestionResultRows()}
        </Grid>
        <Grid className={'label'} item xs>
          <Typography className='rating-result'>{lz.average_answer}:</Typography>
        </Grid>
        <Grid className={'order-container'} item xs>
          {renderOrderQuestionAverageResultRows()}
        </Grid>
      </Grid>
    )
  }

  const renderQuestionResults = () => {
    if (question.type === 'rating') return renderSliderAnswer()
    if (question.type === 'starRating') return renderRatingAnswer()
    if (question.type === 'order') return renderOrderAnswer()
    return <Grid className={isMobile ? 'question-results-mobile' : 'question-results'} container spacing={3}>{renderCurrentQuestionResultRows()}</Grid>
  }

  return (
    <>
      <Grid className={'label'} container alignItems='center' spacing={3}>
        <Typography className='survey-question'>
          {lz.question} {question.index + 1}: {question.title}
        </Typography>
      </Grid>
      {renderQuestionResults()}
      {renderBorder()}
    </>
  )
}

export default observer(QuestionView)
