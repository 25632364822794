import React from 'react'
import { inject, observer } from 'mobx-react'
import { PulseQuestionsStore } from '../../store/PulseQuestionsStore'
import PulseQuestionEditCategories from './PulseQuestionEditCategories'
import { CircularProgress } from '@material-ui/core'
import PulseQuestionTypeRequired from './PulseQuestionTypeRequired'
import PulseQuestionTitle from './PulseQuestionTitle'
import PulseQuestionOptions from './PulseQuestionOptions'
import PulseQuestionMediaPicker from '../media-picker/PulseQuestionMediaPicker'
import './PulseQuestionMain.scss'
import PulseQuestionMedia from './PulseQuestionMedia'
import YouTubePreviewModal from './YouTubePreviewModal'
import VimeoPreviewModal from './VimeoPreviewModal'
import PulseQuestionDropdownInfo from './PulseQuestionDropdownInfo'

interface Props {
  pulseQuestionsStore?: PulseQuestionsStore
}

const PulseQuestionMain: React.FC<Props> = ({ pulseQuestionsStore }) => {
  const vm = pulseQuestionsStore.editVM
  if (!vm) return null
  if (vm.tabIndex !== 0) return null

  const renderSpinner = () => {
    if (!vm.isLoading) return null
    return <CircularProgress color='secondary' style={{ position: 'fixed', top: '50%', left: '50%' }} />
  }

  const renderMainDetails = () => {
    if (vm.isLoading) return null
    return (
      <div className='main-content'>
        {!vm.isCampaignEdit && <PulseQuestionEditCategories />}
        <PulseQuestionTitle />
        <PulseQuestionTypeRequired />
        <PulseQuestionMedia vm={vm} />
        <PulseQuestionOptions vm={vm} />
        <PulseQuestionDropdownInfo vm={vm} />
      </div>
    )
  }

  return (
    <div id='PulseQuestionMain'>
      <YouTubePreviewModal />
      <VimeoPreviewModal />
      {!vm.isCampaignEdit && <PulseQuestionMediaPicker vm={vm.mediaUploadPickerVM} />}
      {renderSpinner()}
      {renderMainDetails()}
    </div>
  )
}

export default inject('pulseQuestionsStore')(observer(PulseQuestionMain))
