import { computed, observable } from 'mobx'
import Parse from 'parse'
import { Organization } from '../../../organizations/aggregate/Organization'
import { IOrganizationDTO } from '../../../organizations/dtos/IOrganizationDTO'
import { IOrganizationsFindRequest } from '../../../organizations/interfaces/IOrganizationsFindRequest'
import { IOrganizationsFindResult } from '../../../organizations/interfaces/IOrganizationsFindResult'
import { OrganizationsService } from '../../../organizations/service/OrganizationsService'
import { DataStore } from '../../../shared/data/DataStore'
import { RootStore } from '../../../stores/RootStore'

export class PulseSetOrganizationsDataStore extends DataStore<Organization, IOrganizationDTO> {
  private request: IOrganizationsFindRequest

  constructor(rootStore: RootStore, request: IOrganizationsFindRequest) {
    super(rootStore, Organization, 'organizations', ['name'])
    this.request = request
    this.paged = true
    this.listenToListRecordsViaApiUpdates = true
  }

  @observable public isLoaded: boolean = false

  public onRecordUpdated(obj: Organization) {
    return this.loadListRecords()
  }

  public onListRecordsLoaded() {
    this.isLoaded = true
  }

  public setRequest(request: IOrganizationsFindRequest) {
    this.request = request
  }

  public getRequest(): IOrganizationsFindRequest {
    return this.request
  }

  protected getBaseQuery() {
    const query = new Parse.Query(this.className)
    return query
  }

  protected getListRecordsApiCall() {
    return async () => {
      const req: IOrganizationsFindRequest = {
        ...this.request,
        listColumns: this.listColumns,
      }
      const svc = new OrganizationsService(this.rootStore)
      const result: IOrganizationsFindResult = await svc.findOrganizations(req)
      this.totalRecords = result.count
      return result.organizations
    }
  }

  public get rows() {
    return this.records
  }
}
