import { observable, computed, action } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { QuestionType } from '../types/QuestionType'
import { OptionVM } from './OptionVM'
import { Icon } from '../models/Icon'
import { MediaItem } from '../../media-items/aggregate/MediaItem'
import { MediaItemVM } from './MediaItemVM'
import { Category } from '../../categories/aggregate/Category'
import { MediaUploadPickerVM } from '../../media-items/view-models/media-upload-picker/MediaUploadPickerVM'
import { SurveyEditVM } from './SurveyEditVM'
import { IQuestionDTO } from '../dtos/IQuestionDTO'
import { Question } from '../aggregate/Question'
import { RatingDisplayType } from '../types/RatingDisplayType'
import { QuestionsService } from '../../questions/services/QuestionsService'
import { IQuestionDTO as ICatalogQuestionDTO } from '../../questions/dtos/IQuestionDTO'
import { FileUploadService } from '../../upload/services/FileUploadService'
import { CloudinaryResult } from '../../upload/aggregate/CloudinaryResult'
import uuid from 'uuid/v4'
import { Attachment } from '../../attachments/aggregate/Attachment'
import { isNumeric } from '../../shared/isNumeric'
import { CMSMediaItemVM } from './CMSMediaItemVM'
import { OldMediaItemVM } from './OldMediaItemVM'
import { Media } from '../aggregate/Media'

export class QuestionVM {
  private rootStore: RootStore
  private question: Question
  public mediaUploadPickerVM: MediaUploadPickerVM
  private editVM: SurveyEditVM

  constructor(rootStore: RootStore, editVM: SurveyEditVM, question: Question) {
    this.rootStore = rootStore
    this.editVM = editVM
    this.question = question
    this.mediaUploadPickerVM = new MediaUploadPickerVM(
      this.rootStore,
      (media) => this.addMedia(media),
      true
    )
    this.scrollToQuestionInput()
  }

  @observable public publishTried: boolean = false
  @observable public toolTipOpen: boolean = false
  @observable public fileUploadError: boolean = false
  @observable public timeOut: any = null
  @observable public cantDeleteLastOption: boolean = false
  @observable public showBranchMsg: boolean = false
  @observable public editorRef: any = null
  @observable public showMediaModal: boolean = false

  rect: ClientRect | DOMRect = null

  @action
  public setEditorRef(editorRef: any) {
    this.editorRef = editorRef
  }

  @computed
  public get key(): string {
    return this.id + '_' + this.editVM.currentQuestionIndex
  }

  @computed
  public get id(): string {
    return this.question.id
  }

  @computed
  public get title(): string {
    return this.question.title
  }

  @computed
  public get placeholder(): string {
    return this.question.placeholder
  }

  @computed
  public get rank(): number {
    return this.question.rank
  }

  @computed
  public get categoryId(): string {
    return this.question.categoryId
  }

  @computed
  public get pulseCategoryId(): string {
    return this.question.pulseCategoryId
  }

  @computed
  public get decimalsAllowed(): number {
    return this.question.decimalsAllowed
  }

  @computed
  public get minimumValueAllowed(): string {
    return this.question.minimumValueAllowedAsString
  }

  @computed
  public get maximumValueAllowed(): string {
    return this.question.maximumValueAllowedAsString
  }

  @computed
  public get defaultValueRequired(): boolean {
    return this.question.defaultValueRequired
  }

  @computed
  public get defaultValueNumeric(): string {
    return this.question.defaultValueNumericAsString
  }

  @computed
  public get numberStep(): string {
    return this.question.numberStepAsString
  }

  @computed
  public get category(): Category {
    if (!this.rootStore) return null
    return this.rootStore.categoriesStore.getCategory(this.categoryId)
  }

  @computed
  public get nextQuestionMenuString(): string {
    if (!this.options[0].nextQuestion) return ''
    if (this.options[0].goToEnd) return ' Go To End'
    return `Q${this.options[0].nextQuestion.rank}: ${this.options[0].nextQuestion.title}`
  }

  @computed
  public get type(): QuestionType {
    return this.question.type
  }

  @computed
  public get options(): Array<OptionVM> {
    return this.question.options.map((e) => new OptionVM(this.rootStore, this.editVM, this, e))
  }

  @computed
  public get media(): MediaItemVM {
    if (isNumeric(this.question.media.objectId) || this.question.media.cmsItemId) {
      const vm = new CMSMediaItemVM(this.rootStore, this.question.media)
      vm.loadCMSItem()
      return vm
    } else return new OldMediaItemVM(this.rootStore, this.question.media)
  }

  @computed
  public get required(): boolean {
    return this.question.required
  }

  @computed
  public get isDropdownMultiSelect(): boolean {
    return this.question.isDropdownMultiSelect
  }

  @computed
  public get ratingDisplay(): RatingDisplayType {
    return this.question.ratingDisplay
  }

  @computed
  public get html(): string {
    return this.question.html
  }

  @computed
  public get allValid() {
    if (!this.publishTried) return true
    // if (!this.categoryValid) return false
    if (!this.optionsValid) return false
    if (!this.titleValid) return false
    if (this.decimalError) return false
    if (!this.placeholderValid) return false
    return true
  }

  @computed
  public get categoryValid() {
    if (!this.publishTried) return true
    if (!this.categoryId || this.categoryId === '') return false
    return true
  }

  @computed
  public get optionsValid(): boolean {
    if (this.options.some((option) => !option.weightValid)) return false
    return true
  }

  @computed
  public get titleValid(): boolean {
    if (this.type === 'infoText') return true
    if (this.title === undefined) return false
    if (!this.publishTried) return true
    if (!this.title || this.title === '') return false
    return true
  }

  @computed
  public get placeholderValid(): boolean {
    if (this.type !== 'dropdown') return true
    if (this.placeholder === undefined) return false
    if (!this.publishTried) return true
    if (!this.placeholder || this.placeholder === '') return false
    return true
  }

  @computed
  public get minimumRequired(): boolean {
    return this.question.minimumRequired
  }

  @computed
  public get maximumRequired(): boolean {
    return this.question.maximumRequired
  }

  @computed
  public get numberStepRequired(): boolean {
    return this.question.numberStepRequired
  }

  @computed
  public get canAddMoreOptions() {
    switch (this.type) {
      case 'emoji':
        return true
      case 'radio':
        return true
      case 'check':
        return true
      case 'yesNo':
        return false
      case 'rating':
        return true
      case 'starRating':
        if (this.options && this.options.length < 10) return true
        return false
      case 'text':
        return false
      case 'order':
        return true
      case 'dropdown':
        return true
      default:
        return false
    }
  }

  @computed
  public get canAddToCatalog() {
    if (this.type === 'infoText') return false
    return true
  }

  @computed
  public get icon(): Icon {
    switch (this.type) {
      case 'emoji':
        return new Icon('far fa-smile', 'brown')
      case 'rating':
        return new Icon('fas fa-list-ol', 'orange')
      case 'starRating':
        return new Icon('fas fa-star', 'yellow')
      case 'radio':
        return new Icon('far fa-dot-circle', 'green')
      case 'check':
        return new Icon('far fa-check-square', 'blue')
      case 'yesNo':
        return new Icon('far fa-thumbs-up', 'purple')
      case 'text':
        return new Icon('fas fa-font', 'red')
      case 'infoText':
        return new Icon('fas fa-info-circle', 'blue')
      case 'number':
        return new Icon('fas fa-calculator', 'orange')
      case 'order':
        return new Icon('fas fa-sort-amount-up', 'pink')
      case 'dropdown':
        return new Icon('fas fa-caret-square-down', 'green')
      default:
        return new Icon('far fa-thumbs-up', 'purple')
    }
  }

  @computed
  public get canHaveOptions() {
    if (this.type === 'text' || this.type === 'infoText' || this.type === 'number') return false
    return true
  }

  @computed
  public get index() {
    return this.editVM.survey.questions.findIndex((e) => e.id === this.question.id)
  }

  @action
  public setRatingDisplay(value) {
    this.question.setRatingDisplay(value)
  }

  @action
  public setPlaceholder(placeholder: string) {
    this.question.setPlaceholder(placeholder)
  }

  @action
  public toggleRequired() {
    this.question.toggleRequired()
  }

  @action
  public setHTML(html) {
    this.question.setHTML(html)
  }

  @action
  public setTitle(title) {
    this.question.setTitle(title)

    if (this.timeOut) clearTimeout(this.timeOut)
    this.timeOut = setTimeout(() => {
      this.editVM.loadDiagram()
    }, 1000)
  }

  @action
  public setDecimalsAllowed(val: number) {
    if (val < 0) return
    this.question.setDecimalsAllowed(val)
  }

  @action
  public increaseDecimalsAllowed() {
    this.question.setDecimalsAllowed(this.decimalsAllowed + 1)
  }

  @action
  public decreaseDecimalsAllowed() {
    if (this.decimalsAllowed === 0) return
    this.question.setDecimalsAllowed(this.decimalsAllowed - 1)
  }

  @action
  public increaseDefaultValueNumeric() {
    this.question.increaseDefaultValueNumeric()
  }

  @action
  public decreaseDefaultValueNumeric() {
    this.question.decreaseDefaultValueNumeric()
  }

  @action
  public toggleDefaultValueRequired() {
    this.question.toggleDefaultValueRequired()
  }

  @action
  public toggleDropdownMultiSelect() {
    this.question.toggleDropdownMultiSelect()
  }

  @action
  public setDefaultValueNumeric(val: string) {
    if (!this.decimalRegex.test(val)) return
    this.question.setDefaultValueNumeric(val)
  }

  @action
  public increaseMinimumValueAllowed() {
    this.question.increaseMinimumValueAllowed()
  }

  @action
  public decreaseMinimumValueAllowed() {
    this.question.decreaseMinimumValueAllowed()
  }

  @action
  public increaseMaximumValueAllowed() {
    this.question.increaseMaximumValueAllowed()
  }

  @action
  public decreaseMaximumValueAllowed() {
    this.question.decreaseMaximumValueAllowed()
  }

  @action
  public increaseNumberStep() {
    this.question.increaseNumberStep()
  }

  @action
  public decreaseNumberStep() {
    this.question.decreaseNumberStep()
  }

  @computed
  public get decimalError(): boolean {
    if (this.type === 'number') {
      if (this.decimalsAllowed > 4) return true
      if (this.decimalsAllowed < 0) return true
    }
    return false
  }

  @computed
  public get decimalRegex(): any {
    if (this.decimalsAllowed === 0) return /^[\d ()+-]+$/
    return /^[^A-Za-z]+$/
  }

  @action
  public setMinimumValueAllowed(val: string) {
    if (!this.decimalRegex.test(val)) return
    this.question.setMinimumValueAllowed(val)
  }

  @action
  public setMaximumValueAllowed(val: any) {
    if (!this.decimalRegex.test(val)) return
    this.question.setMaximumValueAllowed(val)
  }

  @computed
  public get minMaxValid(): boolean {
    if (Number(this.maximumValueAllowed) < Number(this.minimumValueAllowed)) return false
    return true
  }

  @action
  public setNumberStep(val: string) {
    if (!this.decimalRegex.test(val)) return
    this.question.setNumberStep(val)
  }

  @action
  public toggleMinimumRequired() {
    this.question.toggleMinimumRequired()
  }

  @action
  public toggleMaximumRequired() {
    this.question.toggleMaximumRequired()
  }

  @action
  public toggleNumberStepRequired() {
    this.question.toggleNumberStepRequired()
  }

  @action
  public removeMedia() {
    this.question.removeMedia()
  }

  // @action
  // public toggleAnalyzeResponse() {
  //   this.question.toggleAnalyzeResponse()
  // }

  // @action
  // public toggleResponseKeywords() {
  //   this.question.toggleResponseKeywords()
  // }

  @action
  public setWatchPercentage(value) {
    this.toolTipOpen = true
    this.media.setWatchPercentage(value)
  }

  // @action
  // public toggleResponseConcepts() {
  //   this.question.toggleResponseConcepts()
  // }

  // @action
  // public toggleResponseEntities() {
  //   this.question.toggleResponseEntities()
  // }

  // @action
  // public toggleResponseEmotions() {
  //   this.question.toggleResponseEmotions()
  // }

  // @action
  // public setResponseRangeMin(val: number) {
  //   this.question.setResponseRangeMin(val)
  // }

  // @action
  // public setResponseRangeMax(val: number) {
  //   this.question.setResponseRangeMax(val)
  // }

  @action
  public addOption() {
    this.question.addOption()
    this.setOptionRanks()
    if (!this.editVM) return
    this.editVM.resetDiagram()
  }

  public setOptionRanks() {
    this.options.forEach((opt, idx) => {
      opt.setRank(idx + 1)
    })
  }

  @action
  public clearRects() {
    this.rect = null
  }

  @action
  public addMedia(mediaObject: MediaItem) {
    if (this.type === 'infoText' && !mediaObject.cmsItemId)
      return this.addMediaToInfoTextFromLibrary(mediaObject)
    if (this.type === 'infoText' && mediaObject.cmsItemId)
      this.addMediaToInfoTextFromLibrary(mediaObject)

    let frm: MediaItemVM
    if (isNumeric(mediaObject.objectId) || mediaObject.cmsItemId) {
      frm = new CMSMediaItemVM(this.rootStore, Media.create(mediaObject))
    } else {
      frm = new OldMediaItemVM(this.rootStore, Media.create(mediaObject))
    }

    if (frm.isCMSItem) (frm as CMSMediaItemVM).loadCMSItem()

    const url = this.media && this.media.path ? this.media.path.toLowerCase() : ''
    if (url.includes('vimeo')) {
      this.question.vimeoURL = mediaObject.fileUrl
    } else if (url.includes('youtube') || url.includes('youtu.be')) {
      this.question.youTubeURL = mediaObject.fileUrl
    } else {
      this.question.youTubeURL = ''
      this.question.vimeoURL = ''
    }

    this.question.addMedia(frm)
  }

  @action
  private addMediaToInfoTextFromLibrary(mediaObject: MediaItem) {
    let content = ''
    if (mediaObject.type === 'video') {
      const url = mediaObject.fileUrl.toLowerCase()
      if (url.includes('vimeo')) {
        let regExp =
          /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/
        if (mediaObject.fileUrl.includes('player.vimeo.com')) {
          regExp =
            /(http|https)?:\/\/(www\.)?player.vimeo.com\/(?:video\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/
        }
        const match = mediaObject.fileUrl.match(regExp)
        const uuid = match && match.length ? match[4] : ''
        content = `<iframe height=220 width=320 src=${`https://player.vimeo.com/video/${uuid}`} allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullscreen />`
      } else if (url.includes('youtube') || url.includes('youtu.be')) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
        const match = mediaObject.fileUrl.match(regExp)
        const uuid = match && match[2].length === 11 ? match[2] : ''
        content = `<iframe height=220 width=320 src=${`https://www.youtube-nocookie.com/embed/${uuid}?modestbranding=1&showinfo=0&rel=0`} allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullscreen />`
      } else {
        if (mediaObject.cmsItemId)
          content =
            content = `<video height=220 width=320 alt=${mediaObject.name} poster=${mediaObject.videoThumbnail} src=${mediaObject.fileUrl} data-cmsitemid=${mediaObject.cmsItemId} controls='controls' />`
        else
          content =
            content = `<video height=220 width=320 alt=${mediaObject.name} poster=${mediaObject.videoThumbnail} src=${mediaObject.fileUrl} controls='controls' />`
      }
    }
    if (mediaObject.type === 'image') {
      if (mediaObject.cmsItemId)
        content = `<img alt=${mediaObject.name} src=${mediaObject.fileUrl} data-cmsitemid=${mediaObject.cmsItemId} height=220 width=220 />`
      else
        content = `<img alt=${mediaObject.name} src=${mediaObject.fileUrl} height=220 width=220 />`
    }
    return setTimeout(() => {
      this.editorRef?.current?.editor?.execCommand('mceInsertContent', false, content)
    }, 1000)
  }

  @action
  public addMediaToInfoTextFromComputer(attachment: Attachment) {
    let content = ''
    if (attachment.type === 'video') {
      const url = attachment.url.toLowerCase()
      if (url.includes('vimeo')) {
        let regExp =
          /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/
        if (attachment.url.includes('player.vimeo.com')) {
          regExp =
            /(http|https)?:\/\/(www\.)?player.vimeo.com\/(?:video\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/
        }
        const match = attachment.url.match(regExp)
        const uuid = match && match.length ? match[4] : ''
        content = `<iframe height=220 width=320 src=${`https://player.vimeo.com/video/${uuid}`} allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullscreen />`
      } else if (url.includes('youtube') || url.includes('youtu.be')) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
        const match = attachment.url.match(regExp)
        const uuid = match && match[2].length === 11 ? match[2] : ''
        content = `<iframe height=220 width=320 src=${`https://www.youtube.com/embed/${uuid}?modestbranding=1&showinfo=0&rel=0`} allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullscreen />`
      } else {
        if (attachment.cmsItemId)
          content =
            content = `<video height=220 width=320 alt=${attachment.fileName} poster=${attachment.thumbnail} src=${attachment.url} data-cmsitemid=${attachment.cmsItemId} controls='controls' />`
        else
          content =
            content = `<video height=220 width=320 alt=${attachment.fileName} poster=${attachment.thumbnail} src=${attachment.url} controls='controls' />`
      }
    }
    if (attachment.type === 'image') {
      if (attachment.cmsItemId)
        content = `<img alt=${attachment.fileName} src=${attachment.url} data-cmsitemid=${attachment.cmsItemId} height=220 width=220 />`
      else content = `<img alt=${attachment.fileName} src=${attachment.url} height=220 width=220 />`
    }
    return setTimeout(() => {
      this.editorRef?.current?.editor?.execCommand('mceInsertContent', false, content)
    }, 1000)
  }

  @action
  public toggleMediaUploadModal() {
    this.showMediaModal = !this.showMediaModal
  }

  @action
  public toggleMediaUploadPicker() {
    this.mediaUploadPickerVM.toggleShowMediaUploadPicker()
  }

  @action
  public clearAllPaths() {
    this.options.forEach((e) => e.setNextQuestion(null))
  }

  @action
  public setCategory(id: string) {
    this.question.setCategoryId(id)
  }

  @action
  public setPulseCategory(id: string) {
    this.question.setPulseCategoryId(id)
  }

  @action
  public changeQuestionType(val) {
    this.question.changeQuestionType(val)
    this.clearAllPaths()
    this.editVM.loadDiagram()
  }

  @action
  public setRect(rect: ClientRect | DOMRect) {
    this.rect = rect
  }

  @action
  public setRank(val: number) {
    this.question.setRank(val)
  }

  @computed
  public get infoTextIndex(): number {
    if (this.type !== 'infoText') return -1
    let num = 0
    for (let i = 0; i < this.editVM.questions.length - 1; i++) {
      let currentQuestion = this.editVM.questions[i]
      if (currentQuestion.type !== 'infoText') continue
      if (currentQuestion.rank === this.rank) break
      else num = num + 1
    }
    return num
  }

  @action
  public moveOption(index, newIndex) {
    this.question.moveOption(index, newIndex)
  }

  @action
  public moveOptionUp(idx: number) {
    this.question.moveOptionUp(idx)
    this.editVM.resetDiagram()
    this.setOptionRanks()
  }

  @action
  public moveOptionDown(idx: number) {
    this.question.moveOptionDown(idx)
    this.editVM.resetDiagram()
    this.setOptionRanks()
  }

  @action
  public deleteOption(idx: number) {
    if (this.question.options.length === 1) return this.toggleDeleteLastOption()
    this.question.deleteOption(idx)
    this.editVM.resetDiagram()
    this.editVM.resetDiagram()
    this.setOptionRanks()
  }

  @computed
  public get nextQuestion(): QuestionVM {
    const idx = this.index + 1
    return this.editVM.questions[idx]
  }

  @computed
  public get nextNextQuestion() {
    const idx = this.index + 2
    return this.editVM.questions[idx]
  }

  @action
  public showMultiSelectBranchMessage() {
    this.showBranchMsg = true
  }
  @action
  public hideMultiSelectBranchMessage() {
    this.showBranchMsg = false
  }

  @action
  public handleMultiSelectBranching(questionId, option: OptionVM) {
    const branchedOptions = this.options.filter((e) => e.nextQuestionId && e.id !== option.id)
    if (branchedOptions.length > 0) {
      this.showMultiSelectBranchMessage()
    } else if (
      branchedOptions.length > 1 &&
      !this.options.some((e) => e.nextQuestionId === this.nextQuestion.id)
    ) {
      this.hideMultiSelectBranchMessage()
    }
    const otherOptions = this.options.filter((e) => e.id !== option.id)
    this.options.forEach((e) => {
      e.option.setNextQuestionId(null)
      e.option.setGoToEnd(false)
      this.editVM.resetDiagram()
    })
    if (questionId === null) {
      option.option.setNextQuestionId(questionId)
      this.editVM.resetDiagram()
      return
    }
    if (questionId && questionId !== this.nextQuestion.id) {
      option.option.setNextQuestionId(questionId)
      this.editVM.resetDiagram()
      return
    }
    if (this.nextQuestion && this.nextQuestion.id === questionId) {
      option.option.setNextQuestionId(questionId)
      if (this.nextNextQuestion) {
        otherOptions.forEach((e) => {
          e.option.setNextQuestionId(this.nextNextQuestion.id)
        })
      }
      if (!this.nextNextQuestion) {
        otherOptions.forEach((e) => {
          e.option.setGoToEnd(true)
        })
      }
    }
    this.editVM.resetDiagram()
  }

  @action
  public toggleDeleteLastOption() {
    this.cantDeleteLastOption = !this.cantDeleteLastOption
  }

  public toDTO(): IQuestionDTO {
    return this.question.toDTO()
  }

  @action
  public scrollToQuestionInput() {
    const title = document.getElementById('question-label')
    const titleInput = document.getElementById('outlined-name')
    if (title) {
      title.scrollIntoView()
      setTimeout(function () {
        titleInput.focus()
      }, 200)
    }
  }

  public async addToCatalog() {
    const svc = new QuestionsService(this.rootStore)
    const q: ICatalogQuestionDTO = this.toDTO() as any
    q.surveyTypeId = this.editVM.surveyTypeId
    const result = await svc.addToCatalog(this.rootStore.appStore.currentOrgId, q)
    if (!result.success) {
      alert(result.errorMessage)
      return
    }
    this.editVM.openSnackbar('Question added to catalog')
  }

  public async uploadToCloudinary(blobInfo, success, failure, progress) {
    try {
      const blob = blobInfo.blob()
      var file = new File([blob], `tiny-${uuid()}`)
      const uploadSvc = new FileUploadService()
      const res: CloudinaryResult[] = await uploadSvc.uploadMediaItemsToCloudinary([file])
      if (res) return success(res[0].secure_url)
    } catch (e) {
      if (e) {
        return failure(e.toString())
      }
    }
  }
}
