import { RootStore } from '../../stores/RootStore'
import { observable, action, computed } from 'mobx'
import { CellClickedEvent, ColDef, GridOptions, RowClickedEvent } from 'ag-grid-community'
import { AGGridVM } from '../../shared/ag-grid/AGGridVM'
import { nameOf } from '../../shared/nameOf'
import { GroupsAndRolesWidgetRowVM } from './GroupsAndRolesWidgetRowVM'
import { WeightProfileModalVM } from './WeightProfileModalVM'
import { WeightProfile } from '../../weight-profiles/aggregate/WeightProfile'
import { WeightProfilesService } from '../../weight-profiles/services/WeightProfilesService'
import { PagedDataVM } from '../../shared/view-models/PagedDataVM'
import WeightProfilesImportVM from '../../weight-profiles/view-models/WeightProfilesImportVM'

export class GroupsAndRolesWidgetVM extends AGGridVM {
  private rootStore: RootStore
  private svc: WeightProfilesService

  constructor(rootStore: RootStore) {
    super()
    this.rootStore = rootStore
    this.svc = new WeightProfilesService(this.rootStore)
    this.tabIndex = 0
  }

  @observable public tabIndex: number = 0 // 0 = groups, 1 = roles
  @observable public weightProfileModalOpen: boolean = false
  @observable public confirmationMessageShown: boolean = false
  @observable public roleConfirmationMessageShown: boolean = false
  @observable public weightProfileVM: WeightProfileModalVM = null
  @observable public selectedRoleId: string = ''
  @observable public showCopyDialog: boolean = false
  @observable public selectedCopyOptions: Array<any> = []
  @observable public showCopyOptions: boolean = false
  @observable public stagedRoleId: string = ''
  @observable public optionsKeywordFilter: string = ''
  @observable public showDeleteDialog: boolean = false
  @observable public showImportModal: boolean = false
  @observable public importVM: WeightProfilesImportVM = null

  @computed
  public get selectedRoleName(): string {
    if (!this.selectedRoleId) return ''
    const foundRole = this.rootStore.rolesStore.getRole(this.selectedRoleId)
    if (!foundRole) return ''
    return foundRole.name
  }

  @action
  public setOptionsKeywordFilter(e) {
    this.optionsKeywordFilter = e
  }

  @action
  public toggleRoleToCopySelect(roleId) {
    if (this.selectedCopyOptions.includes(roleId)) {
      this.selectedCopyOptions = this.selectedCopyOptions.filter((e) => e !== roleId)
    } else {
      this.selectedCopyOptions.push(roleId)
    }
  }

  @action
  public setRole(id?: string) {
    this.selectedRoleId = id ? id : this.stagedRoleId
    const foundWeightProfile = this.rootStore.weightProfilesStore.getWeightProfileByRoleId(
      this.selectedRoleId
    )
    this.weightProfileModalOpen = true
    this.roleConfirmationMessageShown = false
    this.weightProfileVM = new WeightProfileModalVM(
      this,
      this.rootStore,
      foundWeightProfile
        ? foundWeightProfile.clone()
        : WeightProfile.create(this.rootStore.appStore.currentOrgId, this.selectedRoleId)
    )
  }

  @computed
  public get availableRoles() {
    return this.rootStore.rolesStore.roles.map((e) => {
      return { name: e.name, id: e.objectId }
    })
  }

  @computed
  public get copyOptionsPagedData() {
    return new PagedDataVM(
      this.rootStore,
      this.copyOptions,
      ['name'],
      '',
      'CategoriesScreen',
      this.optionsKeywordFilter
    )
  }

  @computed
  public get copyOptions(): any[] {
    if (!this.weightProfileVM) return []
    return this.availableRoles.filter((e) => e.id !== this.selectedRoleId)
  }

  @computed
  public get rows(): GroupsAndRolesWidgetRowVM[] {
    const rows = []
    this.rootStore.groupsStore.groups.forEach((group) =>
      rows.push(new GroupsAndRolesWidgetRowVM(this.rootStore, group, this, 'group'))
    )
    this.rootStore.rolesStore.roles.forEach((role) =>
      rows.push(new GroupsAndRolesWidgetRowVM(this.rootStore, role, this, 'role'))
    )
    return rows.filter((e) => (this.tabIndex === 0 ? e.type === 'group' : e.type === 'role'))
  }

  public get shouldRender(): boolean {
    if (!this.rootStore.rolesStore.loaded) return false
    if (!this.rootStore.groupsStore.loaded) return false
    return true
  }

  @action
  public getRowNodeId(row): string {
    return row.objectId
  }

  @computed
  public get columnDefs(): ColDef[] {
    const s = this.rootStore.localizationStore.lzStrings
    const cols: ColDef[] = [
      {
        headerName: s.groupsTable.name,
        field: nameOf<GroupsAndRolesWidgetRowVM, string>((e) => e.name),
        sort: 'asc',
        onCellClicked: (e: CellClickedEvent) => {
          const rowVM: GroupsAndRolesWidgetRowVM = e.data
          if (rowVM && rowVM.openEditDrawer) rowVM.openEditDrawer()
        },
      },
      {
        headerName: s.groupsTable.description,
        field: nameOf<GroupsAndRolesWidgetRowVM, string>((e) => e.description),
        onCellClicked: (e: CellClickedEvent) => {
          const rowVM: GroupsAndRolesWidgetRowVM = e.data
          if (rowVM && rowVM.openEditDrawer) rowVM.openEditDrawer()
        },
      },
      {
        headerName: s.groupsTable.members,
        field: nameOf<GroupsAndRolesWidgetRowVM, number>((e) => e.memberCount),
        valueFormatter: ({ value }) => {
          if (!value) return 0
          return value
        },
        onCellClicked: (e: CellClickedEvent) => {
          const rowVM: GroupsAndRolesWidgetRowVM = e.data
          if (rowVM && rowVM.openEditDrawer) rowVM.openEditDrawer()
        },
      },
    ]
    if (this.tabIndex === 1) {
      cols.push({
        headerName: this.rootStore.labelsStore.getLabelByLanguageAndFor('categories'),
        cellRenderer: 'weightProfileCell',
        sortable: false,
        onCellClicked: null,
      })
    }

    return cols
  }

  @action
  protected isExternalFilterPresent(): boolean {
    return true
  }

  @action
  public async openWeightProfileModal(id: string) {
    await this.rootStore.weightProfilesStore.loadListRecords()
    const foundWeightProfile = this.rootStore.weightProfilesStore.getWeightProfileByRoleId(id)
    this.weightProfileVM = new WeightProfileModalVM(
      this,
      this.rootStore,
      foundWeightProfile
        ? foundWeightProfile.clone()
        : WeightProfile.create(this.rootStore.appStore.currentOrgId, id)
    )
    this.selectedRoleId = id
    this.weightProfileModalOpen = !this.weightProfileModalOpen
  }

  @action
  public tryWeightProfileModalClose() {
    if (this.weightProfileVM.isDirty) {
      this.toggleConfirmDialog()
      return
    }
    this.toggleWeightProfileModalOpen()
  }

  @action
  public tryRoleChange(id: string) {
    if (this.weightProfileVM.isDirty) {
      this.stagedRoleId = id
      this.toggleRoleConfirmDialog()
      return
    }
    this.setRole(id)
  }

  @action
  public toggleConfirmDialog() {
    this.confirmationMessageShown = !this.confirmationMessageShown
  }

  @action
  public toggleRoleConfirmDialog() {
    this.roleConfirmationMessageShown = !this.roleConfirmationMessageShown
  }

  @action
  public toggleWeightProfileModalOpen() {
    this.confirmationMessageShown = false
    this.weightProfileModalOpen = !this.weightProfileModalOpen
  }

  @action
  public toggleDeleteDialog() {
    this.showDeleteDialog = !this.showDeleteDialog
  }

  @action
  protected doesExternalFilterPass(node) {
    switch (this.tabIndex) {
      case 0:
        return Boolean(node.data.type === 'group')
      case 1:
        return Boolean(node.data.type === 'role')
      default:
        return true
    }
  }

  public getGridOptions(): GridOptions {
    //console.log({rows: this.rows})
    return {
      ...this.baseGridOptions,
      rowData: this.rows,
      getRowNodeId: (e) => this.getRowNodeId(e),
      columnDefs: this.ensureTooltipFields(this.columnDefs),
    }
  }

  public rowClicked(e: RowClickedEvent) {
    const row: GroupsAndRolesWidgetRowVM = e.data
    if (!row) return
    row.openEditDrawer()
  }

  @action
  public setTabIndex(value: number) {
    this.tabIndex = value
    this.gridApi.paginationGoToFirstPage()
    this.gridApi.onFilterChanged()
    this.gridApi.setColumnDefs(this.columnDefs)
    this.gridApi.sizeColumnsToFit()
  }

  @action
  public goToWeightProfilesMgr() {
    this.rootStore.appStore.router.push('/weightProfiles/edit')
  }

  @action
  public toggleCopyDialog() {
    this.showCopyDialog = !this.showCopyDialog
  }

  @action
  public toggleShowCopyOptions() {
    this.showCopyOptions = !this.showCopyOptions
    if (!this.showCopyOptions) this.selectedCopyOptions = []
  }

  @action
  public async copyWeightProfileToRoles() {
    this.toggleCopyDialog()

    await this.svc.copyWeightProfileToRoles(
      this.rootStore.appStore.currentOrgId,
      this.weightProfileVM.weightProfile.serialize().objectId,
      this.selectedCopyOptions.slice()
    )

    this.toggleShowCopyOptions()
    this.selectedCopyOptions = []
  }

  @action
  public toggleImportModal() {
    this.showImportModal = !this.showImportModal
    if (this.showImportModal) this.importVM = new WeightProfilesImportVM(this.rootStore)
    else this.importVM = null
  }
}
