import React, { useRef } from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../localization/LocalizationStore'
import { NotificationsStore } from '../store/NotificationsStore'
import { OutlinedInput, InputLabel } from '@material-ui/core'
import { Editor } from '@tinymce/tinymce-react'
import { TOOLBAR, PLUGINS } from '../../config/TinyMCEconfig'
import FileUploadModal from '../../upload/views/FileUploadModal'
import { FileTypeEnum } from '../../upload/enum/FileTypeEnum'
import { Attachment } from '../../upload/aggregate/Attachment'
import NotificationTemplatesEditorToggle from '../views/NotificationTemplatesEditorToggle'
import NotificationTemplateEditor from '../views/NotificationTemplateEditor'
import CMSItemsPickerDialog from '../../cms-items/views/dialogs/CMSItemsPickerDialog'
import { CMSItemsTypeEnum } from '../../cms-items/enum/CMSItemsTypeEnum'
import { OrganizationsStore } from '../../organizations/store/OrganizationsStore'

interface DrawerTemplateEditorProps {
  localizationStore?: LocalizationStore
  notificationsStore?: NotificationsStore
  organizationsStore?: OrganizationsStore
}

const DrawerTemplateEditor: React.FC<DrawerTemplateEditorProps> = ({
  localizationStore,
  notificationsStore,
  organizationsStore,
}) => {
  const { lzStrings } = localizationStore
  const { editVM: widgetForm } = notificationsStore
  let editorRef: any = useRef()

  if (!widgetForm) return

  const handleChange = (event) => {
    widgetForm.setField(event.target.id, event.target.value)
  }

  const renderMediaUploadModal = () => {
    if (organizationsStore.currentOrganization?.hasCMSPicker) return renderCMSUploadModal()
    return renderStandardUploadModal()
  }

  const renderCMSUploadModal = () => {
    return (
      <CMSItemsPickerDialog
        allowMultiple={false}
        fileTypes={CMSItemsTypeEnum.IMAGES}
        addAfterSave={(a: Attachment[]) => widgetForm.addMediaToInfoTextFromComputer(a[0])}
        toggleShowModal={() => widgetForm.toggleMediaUploadModal()}
        isOpen={widgetForm.showMediaModal}
      />
    )
  }

  const renderStandardUploadModal = () => {
    return (
      <FileUploadModal
        key={`notification-template-upload-file`}
        allowMultiple={false}
        fileTypes={FileTypeEnum.IMAGES}
        addAfterSave={(a: Attachment[]) => widgetForm.addMediaToInfoTextFromComputer(a[0])}
        toggleShowModal={() => widgetForm.toggleMediaUploadModal()}
        isOpen={widgetForm.showMediaModal}
      />
    )
  }

  const renderWYSIWYGEditor = () => {
    return (
      <Editor
        key={'notification-template-editor-wysiwyg'}
        value={widgetForm.body}
        disabled={widgetForm.isSaving || widgetForm.resetDialog}
        init={
          widgetForm.isEmail
            ? {
                height: 350,
                width: '100%',
                resize: false,
                menubar: false,
                plugins: `${PLUGINS} fullpage image media`,
                toolbar: `customInsertButton ${TOOLBAR}`,
                images_reuse_filename: true,
                images_upload_handler: (blobInfo, success, failure, progress) =>
                widgetForm.uploadToCloudinary(blobInfo, success, failure, progress),
                powerpaste_block_drop: true,
                contextmenu: false,
                extended_valid_elements: '+div[*],+a[*],+span[*]',
                valid_children:
                  '+a[h1|h2|h3|h4|h5|h6|div|span|div|img|name|href|target|title|clicktracking]',
                forced_root_block: false,
                force_br_newlines: true,
                force_p_newlines: true,
                setup: (editor) => {
                  editor.on('init', (e) => {
                    widgetForm.setEditorRef(editorRef)
                  })
                  editor.on('CloseWindow', (e) => {
                    setTimeout(() => {
                      widgetForm.showLinkDialog = false
                    }, 500)
                  })
                  editor.on('ObjectSelected', (e) => {
                    if (editor.selection.getNode().nodeName === 'A') {
                      e.preventDefault()
                      e.stopPropagation()
                      e.stopImmediatePropagation()
                      if (!widgetForm.showLinkDialog) {
                        widgetForm.toggleShowLinkDialog()
                        editor.execCommand('mceLink', null, 'openLink', null)
                      }
                    }
                    if (editor.selection.getNode().nodeName === 'SPAN') {
                      if (editor.selection.getNode().parentNode && editor.selection.getNode().parentNode.nodeName === 'A') {
                        e.preventDefault()
                        e.stopPropagation()
                        e.stopImmediatePropagation()
                        if (!widgetForm.showLinkDialog) {
                          widgetForm.toggleShowLinkDialog()
                          editor.execCommand('mceLink', null, 'openLink', null)
                        }
                      }
                    }
                  })
                  editor.ui.registry.addMenuButton('customInsertButton', {
                    text: 'Upload Media',
                    tooltip:
                      'Upload an image from your computer.',
                    icon: 'upload',
                    fetch: function (callback) {
                      const items: any = [
                        {
                          type: 'menuitem',
                          icon: 'browse',
                          text: organizationsStore.currentOrganization?.hasCMSPicker
                            ? 'Content Management System'
                            : 'My Computer',
                          onAction: () => {
                            widgetForm.toggleMediaUploadModal()
                          },
                        },
                      ]
                      callback(items)
                    },
                  })
                },
              }
            : {
                height: 350,
                width: '100%',
                resize: false,
                menubar: false,
                plugins: PLUGINS,
                toolbar: 'undo redo',
                contextmenu: false,
              }
        }
        onEditorChange={(e) => {
          widgetForm.setBody(e)
        }}
        outputFormat={widgetForm.isEmail ? 'html' : 'text'}
        ref={editorRef}
      />
    )
  }

  const renderHTMLEditor = () => {
    return (
      <NotificationTemplateEditor />
    )
  }

  const renderEditor = () => {
    return widgetForm.editorTabIndex === 0 ? renderWYSIWYGEditor() : renderHTMLEditor()
  }

  const renderEditorToggle = () => {
    if (!widgetForm.isEmail) return
    return (
      <NotificationTemplatesEditorToggle />
    )
  }

  return (
    <div>
      <h6>{lzStrings.notificationsWidgetDrawer.template_editor}</h6>
      <InputLabel htmlFor='subject'>{lzStrings.notificationsWidgetDrawer.subject}</InputLabel>
      <OutlinedInput
        id='subject'
        fullWidth
        labelWidth={0}
        autoComplete='off'
        value={widgetForm.subject}
        onChange={handleChange}
        disabled={widgetForm.isSaving || widgetForm.resetDialog}
        error={!widgetForm.isSubjectValid}
      />
      <InputLabel htmlFor='adminEmail'>{lzStrings.notificationsWidgetDrawer.body}</InputLabel>
      {renderEditorToggle()}
      {renderEditor()}
      {renderMediaUploadModal()}
    </div>
  )
}

export default inject('localizationStore', 'notificationsStore', 'organizationsStore')(observer(DrawerTemplateEditor))
