import { RootStore } from 'src/app/stores/RootStore'
import { action, computed, observable } from 'mobx'
import SurveyType from '../aggregate/SurveyType'
import { SurveyTypesService } from '../../service/SurveyTypesService'
import { ScreenType } from '../../types/ScreenType'

export default class SurveyTypeEditVM {
  rootStore: RootStore

  constructor(rootStore: RootStore, screenType: ScreenType, objectId: string) {
    this.rootStore = rootStore
    this.screenType = screenType
    this.loadData(objectId)
  }

  @observable surveyType: SurveyType = null
  @observable isActive: boolean = true
  @observable saveTried: boolean = false
  @observable saveSuccessful: boolean = false
  @observable deletePending: boolean = false
  @observable deleteSuccessful: boolean = false
  @observable deleteConfirmShown: boolean = false
  @observable screenType: ScreenType = null
  @observable isSaving: boolean = false

  private async loadData(objectId: string) {
    let orgId = 'DEFAULT'
    if (this.screenType === 'tenantManage') {
      orgId = this.rootStore.appStore.currentOrgId
    }
    if (objectId === 'new') {
      this.surveyType = SurveyType.create(orgId)
      return
    }
    const skipOrgId = Boolean(this.screenType === 'systemManage')
    this.surveyType = await this.rootStore.surveyTypesStore.getFullRecord(objectId, skipOrgId)
    return
  }

  @computed
  public get objectId(): string {
    if (!this.surveyType) return ''
    return this.surveyType.objectId
  }

  @computed
  public get isNew(): boolean {
    if (!this.objectId || this.objectId === '') return true
    return false
  }

  @computed
  public get name(): string {
    if (!this.surveyType) return ''
    return this.surveyType.name
  }

  @computed
  public get description(): string {
    if (!this.surveyType) return ''
    return this.surveyType.description
  }

  @computed
  public get forPrompt(): string {
    if (!this.surveyType) return ''
    return this.surveyType.forPrompt
  }

  @computed
  public get responseForParticipant(): boolean {
    if (!this.surveyType) return false
    return this.surveyType.responseForParticipant
  }

  @computed
  public get isFeedbackType(): boolean {
    if (!this.surveyType) return false
    return this.surveyType.isFeedbackType
  }

  @computed
  public get allowMultipleForUsers(): boolean {
    if (!this.surveyType) return false
    return this.surveyType.allowMultipleForUsers
  }

  @computed
  public get nameValid(): boolean {
    if (!this.saveTried) return true
    if (!this.name || this.name === '') return false
    return true
  }

  @computed
  public get isValid(): boolean {
    if (!this.nameValid) return false
    return true
  }

  @action
  public setName(name: string) {
    this.surveyType.setName(name)
  }

  @action
  public setDescription(val: string) {
    this.surveyType.setDescription(val)
  }

  @action
  public toggleResponseForParticipant() {
    this.surveyType.toggleResponseForParticipant()
  }

  @action
  public toggleFeedBack() {
    this.surveyType.toggleIsFeedback()
  }

  @action
  public toggleMultipleFor() {
    this.surveyType.toggleMultipleFor()
  }

  @action
  public setPrompt(prompt: string) {
    this.surveyType.setPrompt(prompt)
  }

  @action
  public toggleDeleteDialog() {
    this.deleteConfirmShown = !this.deleteConfirmShown
  }

  @action
  public async deleteSurveyType() {
    this.isSaving = true
    this.toggleDeleteDialog()
    const svc = new SurveyTypesService(this.rootStore)
    const result = await svc.deleteSurveyType(this.objectId)
    if (result) {
      this.isSaving = false
      this.deleteSuccessful = true
      this.navigateBackToMainScreen()
    }
  }

  @action
  public async saveSurveyType() {
    this.saveTried = true
    this.isSaving = true
    if (!this.isValid) return
    const svc = new SurveyTypesService(this.rootStore)
    const dto = this.surveyType.serialize()
    const result = await svc.saveSurveyType(dto)
    if (result) {
      this.saveSuccessful = true
      this.isSaving = false
      this.navigateBackToMainScreen()
    }
  }

  @action
  public navigateBackToMainScreen() {
    if (this.screenType === 'systemManage') {
      return this.rootStore.appStore.router.push('/systemAdmin/manage/surveyTypes')
    }
    return this.rootStore.appStore.router.push('/tenantAdmin/manage/surveyTypes')
  }
}
