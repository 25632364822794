import { computed, action, observable } from 'mobx'
import { AudienceMember } from '../../audience-members/aggregate/AudienceMember'
import { Group } from '../../groups/aggregate/Group'
import { Role } from '../../roles/aggregate/Role'
import { RootStore } from '../../stores/RootStore'
import { IEventParticipantGroup } from '../interfaces/IEventParticipantGroup'
import { ParticipantType } from '../types/ParticipantType'
import { ParticipantsSelectVM } from './ParticipantsSelectVM'
import { UsersAGGridVM } from './UsersAGGridVM'

export class GroupedRowVM {
  private rootStore: RootStore
  private selectVM: ParticipantsSelectVM
  private groupItem: Group | Role | IEventParticipantGroup

  constructor(
    rootStore: RootStore,
    selectVM: ParticipantsSelectVM,
    groupItem: Group | Role | IEventParticipantGroup
  ) {
    this.rootStore = rootStore
    this.selectVM = selectVM
    this.groupItem = groupItem
  }

  @observable public usersAGGridVM: UsersAGGridVM

  public get objectId(): string {
    return this.groupItem.objectId
  }

  public get type(): ParticipantType {
    return this.groupItem.type as ParticipantType
  }

  public get rowNodeId() {
    return this.groupItem.objectId
  }

  @computed
  public get isUsersListModalOpen(): boolean {
    return this.selectVM.isUsersListModalOpen
  }

  @computed
  public get eventId(): string {
    return this.selectVM.event.objectId
  }

  public openUsersListModal() {
    this.selectVM.openUsersListModal(this)
    if (!this.usersAGGridVM)
      this.usersAGGridVM = new UsersAGGridVM(this.rootStore, this.selectVM, this)
    this.usersAGGridVM.show()
  }

  public closeUsersListModal() {
    this.usersAGGridVM = null
    this.selectVM.closeUsersListModal()
  }

  @computed
  public get typedFilterText(): string {
    if (!this.usersAGGridVM) return ''
    return this.usersAGGridVM.typedFilterText
  }

  @computed
  public get hasFilter(): boolean {
    if (!this.usersAGGridVM) return false
    return this.usersAGGridVM.hasFilter
  }

  @action
  public setFilter(val: string) {
    this.usersAGGridVM.setQuickFilter(val)
  }

  @computed
  public get name(): string {
    return this.groupItem.name
  }

  @computed
  public get isReadOnly(): boolean {
    return this.selectVM.isReadOnly
  }

  @computed
  public get isParticipantSelected(): boolean {
    const isSelectedDirectly =
      this.selectVM.participants.filter((e) => e.id === this.objectId).length !== 0
    let isSelectedIndirectly = false
    this.selectVM.selectedRoles.forEach((e) => {
      if (
        this.rootStore.audienceMembersStore
          .currentOrgUsersInRole(e.id)
          .find((e) => e.objectId === this.objectId)
      )
        isSelectedIndirectly = true
    })
    this.selectVM.selectedGroups.forEach((e) => {
      if (
        this.rootStore.audienceMembersStore
          .currentOrgUsersInGroup(e.id)
          .find((e) => e.objectId === this.objectId)
      )
        isSelectedIndirectly = true
    })
    return isSelectedDirectly || isSelectedIndirectly
  }

  @action
  public toggleParticipant() {
    const exists = this.selectVM.participants.findIndex((e) => e.id === this.objectId) > -1
    if (!exists) {
      this.selectVM.addParticipant(this.objectId, this.name, this.type)
      return
    }
    this.selectVM.deleteParticipant(this.objectId)
  }
}
