import Parse from 'parse'
import { RootStore } from '../../stores/RootStore'
import { ISurveyResponseDTO } from '../dtos/ISurveyResponseDTO'

export class SurveyResponsesService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public async saveSurveyResponseFromAlwaysAvailableSurvey(
    response: ISurveyResponseDTO,
    isLoggedIn: boolean
  ) {
    const result = await Parse.Cloud.run('saveResponseFromAlwaysAvailableSurvey', {
      orgId: this.rootStore.appStore.currentOrgId,
      userId: this.rootStore.appStore.currentUserId,
      response: response,
      application: 'web',
      isLoggedIn: isLoggedIn,
    })
    return result
  }

  public async deleteSurveyResponse(orgId, surveyId, responseId) {
    const result = await Parse.Cloud.run('deleteSurveyResponse', { orgId, surveyId, responseId })
    return result
  }

  public async markSurveyResponseAsRead(responseId, orgId) {
    await Parse.Cloud.run('markSurveyResponseAsRead', { responseId, orgId })
  }
}
