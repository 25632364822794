import { observable } from 'mobx'
import { serializable } from 'serializr'

export class Attachment {
  @serializable @observable public objectId: string = ''
  @serializable @observable public fileName: string = ''
  @serializable @observable public thumbnail: string = ''
  @serializable @observable public cmsItemId: number = undefined
  @serializable @observable public url: string = ''
  @serializable @observable public format: string = ''
  @serializable @observable public bytes: number = 0
  @serializable @observable public type: string = ''
}
