import { observable } from 'mobx'
import { serializable } from 'serializr'
import { IUserDTO } from '../dtos/IUserDTO'

export class User implements IUserDTO {
  @serializable @observable public objectId: string = ''
  @serializable @observable public organizationId: string = ''
  @serializable @observable public firstName: string
  @serializable @observable public lastName: string
  @serializable @observable public name?: string
  @serializable @observable public sortName?: string
  @serializable @observable public username: string
  @serializable @observable public publicEmail: string
  @serializable @observable public iconURL?: string
  @serializable @observable public birthDate?: string
  @serializable @observable public phone?: string
  @serializable @observable public password: string
  @serializable @observable public receiveEmails?: boolean
  @serializable @observable public receivePushNotifications?: boolean
  @serializable @observable public receiveTextMessages?: boolean
  @serializable @observable public languagePreference?: string
  @serializable @observable public tableauLicenseType?: string
  @serializable @observable public tableauLicensePermanent?: boolean
}
