import { observable, action, computed } from 'mobx'
import { serializable, serialize, deserialize, date } from 'serializr'
import moment, { Moment } from 'moment'
import { IUserGoalDTO } from '../dto/IUserGoalDTO'
import { Goal } from '../../goals/aggregate/Goal'
import { IAggregate } from 'src/app/shared/data/IAggregate'

export class UserGoal extends Goal implements IUserGoalDTO, IAggregate {
  @serializable @observable public objectId: string = ''
  @serializable @observable public goalId: string = ''
  @serializable @observable public userId: string = ''
  @serializable @observable public active: boolean = false
  @serializable @observable public isDeleted: boolean = false
  @serializable @observable public isRead: boolean = false
  @serializable(date()) @observable public readDateTime: Date = null

  @observable public targetPercentage: number = 0
  @observable public targetInputValue: number = 0
  isOnServer: boolean = false

  @action
  public setName(val: string) {
    this.name = val
  }

  @action
  public setCategory(id: string) {
    this.categoryId = id
  }

  @action
  public setStartDate(date: Date) {
    this.startDate = date
  }

  @action
  public setEndDate(date: Date) {
    this.endDate = date
  }

  @action
  public setDescription(val: string) {
    this.description = val
  }

  @action
  public setObstacles(val: string) {
    this.potentialObstacles = val
  }

  @action
  public setDataSource(val: string) {
    this.dataSourceId = Number(val)
  }

  @action
  public setFacetId(val: string) {
    this.facetId = Number(val)
  }

  @action
  public setTargetPercentage(val: number) {
    this.targetPercentage = Number(val)
    this.setTarget()
  }

  @action
  public setWorkSheetId(id: string) {
    this.worksheetId = id
  }

  @action
  public setTargetInputValue(val: number) {
    this.targetInputValue = Number(val)
    this.setTarget()
  }

  @action
  public setTarget() {
    let target = this.target
    if (this.targetMode === 'percentage') {
      if (this.goalDirection === 'increase') {
        target = this.current * (this.targetPercentage / 100) + this.current
        console.log(target)
      } else {
        target = this.current - this.current * (this.targetPercentage / 100)
      }
    } else {
      target = this.targetInputValue
    }

    console.log(target)
    this.target = target
  }

  @action
  public setConsistencyThreshold(val: number) {
    this.consistencyThreshold = Number(val)
  }

  @action
  public markIsOnServer() {
    this.isOnServer = true
  }

  @action
  public markIsNotOnServer() {
    this.isOnServer = true
  }

  public serialize() {
    const obj = serialize(this)
    obj.startDate = moment(this.startDate).toISOString()
    obj.endDate = moment(this.endDate).toISOString()
    return obj
  }

  public clone(): UserGoal {
    return deserialize(UserGoal, this.serialize())
  }
}
