import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'

const AnyReactComponent: any = ({ text }) => <div className='marker'>{text}</div>

export default class Map extends Component<any, any> {

  render() {
    const center = {
      lat: this.props.lat,
      lng: this.props.lng
    }
    return (
      <div className={this.props.className}>
        <GoogleMapReact
          defaultZoom={13}
          center={center}
        >
          <AnyReactComponent
            lat={this.props.lat}
            lng={this.props.lng}
            text={''}
          />
        </GoogleMapReact>
      </div>
    )
  }
}