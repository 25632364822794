import { RootStore } from '../../stores/RootStore'
import { observable, computed, action } from 'mobx'
import { MyGoalsWidgetRowVM } from './MyGoalsWidgetRowVM'

export class MyGoalsWidgetVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @observable public keywordFilter: string = ''
  @observable public deletingOrgId: string = ''
  @observable public sortType: string = 'Date'

  @computed
  public get canCreateGoals(): boolean {
    return this.rootStore.appStore.canCreateGoals
  }

  @computed
  public get currentUserGoals(): MyGoalsWidgetRowVM[] {
    return this.rootStore.userGoalsStore.orgUserGoals
      .filter((goal) => goal.userId === this.rootStore.appStore.currentUserId)
      .map((goal) => new MyGoalsWidgetRowVM(this.rootStore, this, goal))
      .filter((e) => e.name.toLowerCase().includes(this.keywordFilter))
      .sort((a, b) => {
        if (this.sortType === 'Name') return a.name < b.name ? -1 : 0
        if (this.sortType === 'Date') return a.dueDate > b.dueDate ? -1 : 0
        if (this.sortType === 'Category') return a.category > b.category ? -1 : 0
      })
  }

  @action setKeywordFilter(val) {
    this.keywordFilter = val
  }

  @action setSortType(type) {
    this.sortType = type
  }

  @action createNewGoal() {
    this.rootStore.appStore.router.push('/goals/edit/new')
  }

  @action editGoal(id: string) {
    this.rootStore.appStore.router.push(`/goals/edit/${id}`)
  }
}
export default MyGoalsWidgetVM
