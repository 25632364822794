import { action, computed, observable, when } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { IEmailTemplatesGetVersionRequest } from '../interfaces/IEmailTemplatesGetVersionRequest'
import { EmailTemplatesService } from '../service/EmailTemplatesService'
import { ParseService } from '../../services/ParseService'
import { EmailTemplateVM } from './EmailTemplateVM'
import { EmailTemplatesStore } from '../store/EmailTemplatesStore'

export class EmailTemplatesManageVM {
  private rootStore: RootStore
  public emailTemplatesStore: EmailTemplatesStore
  private svc: EmailTemplatesService

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.svc = new EmailTemplatesService()
    this.emailTemplatesStore = this.rootStore.emailTemplatesStore
  }

  @observable public typedFilterText: string = ''
  @observable public isLoading: boolean = false
  @observable public isSnackbarOpen: boolean = false
  @observable public snackbarMessage: string = ''

  @computed
  public get templates() {
    return this.rootStore.emailTemplatesStore.emailTemplateVMs
  }

  @computed
  public get isLoaded(): boolean {
    if (!this.rootStore.emailTemplatesStore.loaded) return false
    return true
  }

  @computed
  public get shouldRender(): boolean {
    if (!this.rootStore.emailTemplatesStore.loaded) return false
    return true
  }

  @action
  private loadOrgTemplates() {
    let orgTemplates = this.emailTemplatesStore.allEmailTemplates

    for (let orgTemplate of orgTemplates) {
      this.templates.push(new EmailTemplateVM(this.rootStore, orgTemplate))
    }
  }

  @action
  public async loadData() {
    if (this.templates.length === 0) {
      this.loadOrgTemplates()
      return
    }
    const parseSvc = new ParseService()
  }

  @computed
  public get canManage() {
    if (this.rootStore.appStore.isSystemAdmin) return true
    if (this.rootStore.appStore.isOrgAdmin) return true
    if (!this.rootStore.userStore.currentOrganization) return false
    return false
  }

  @computed
  public get orgTableVM() {
    return this.rootStore.emailTemplatesStore.viewModels.orgTableVM
  }

  @computed
  public get sysTableVM() {
    return this.rootStore.emailTemplatesStore.viewModels.sysTableVM
  }

  @action
  public async createNewEmailTemplate() {
    this.rootStore.emailTemplatesStore.viewModels.createDialogVM.openDialog()
  }

  @action
  public async getEmailTemplateVersion(sgTemplateId: string, sgTemplateVersionId: string) {
    const req: IEmailTemplatesGetVersionRequest = {
      template_id: sgTemplateId,
      version_id: sgTemplateVersionId,
    }
    const result = await this.svc.getEmailTemplateVersion(req, this.rootStore.appStore.currentOrgId)
    return result
  }

  @action
  public setQuickFilter(val: string) {
    this.typedFilterText = val
  }

  @action
  public openSnackbar(msg) {
    this.snackbarMessage = msg
    this.isSnackbarOpen = true
  }

  @action
  public closeSnackbar() {
    this.isSnackbarOpen = false
  }
}
