import { computed, observable, action } from 'mobx'
import { PulseCategoriesManageVM } from '../../pulse-categories/view-models/PulseCategoriesManageVM'
import { AGGridVM } from '../../shared/ag-grid/AGGridVM'
import { RootStore } from '../../stores/RootStore'
import { PulseSetFilterRowVM } from './PulseSetFilterRowVM'

export class PulseSetsFilterVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, parentVM?: PulseCategoriesManageVM) {
    this.rootStore = rootStore
  }

  @computed
  public get isLoaded() {
    return this.rootStore.pulseSetsStore.isLoaded
  }

  @computed
  public get rows(): PulseSetFilterRowVM[] {
    return this.rootStore.pulseSetsStore.pulseSets.map((p) => new PulseSetFilterRowVM(this.rootStore, p.clone()))
  }

  @computed
  public get selectedCountLabel(): string {
    if (!this.isLoaded) return '0'
    if (this.allChecked) return 'All'
    return this.rows.filter((e => e.isChecked)).length.toString()
  }

  @computed
  public get allChecked(): boolean {
    return !this.rows.some((e) => !e.isChecked)
  }

  @computed
  public get checkedPulseSetIds(): string[] {
    return this.rows.filter((e) => e.isChecked).map((r) => r.objectId)
  }

  toggleAllChecked() {
    if (this.allChecked) {
      return this.rows.forEach((e) => e.toggleIsChecked())
    }
    this.rows.forEach((row) => {
      if (row.isChecked) return
      row.toggleIsChecked()
    })
  }

}
