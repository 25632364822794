import Parse from 'parse'
import { RootStore } from '../../stores/RootStore'
import { SurveyTemplate } from '../aggregates/SurveyTemplate'
import { IPulseCampaignDTO } from '../../pulse-campaigns/dto/IPulseCampaignDTO'
import { ISurveyTemplateDTO } from '../dtos/ISurveyTemplateDTO'
import { IPulseCampaignSurveyDTO } from '../../pulse-campaigns/dto/IPulseCampaignSurveyDTO'
import { ISurveyNotificationTemplatesDTO } from 'src/app/surveys/dtos/ISurveyNotificationTemplatesDTO'
import { ISurveyTemplatesFindRequest } from '../interfaces/ISurveyTemplatesFindRequest'
import { ISurveyTemplatesFindResult } from '../interfaces/ISurveyTemplatesFindResult'
import { ISurveyTemplatePermissionsUpdateRequest } from '../interfaces/ISurveyTemplatePermissionsUpdateRequest'
import { ISurveyTemplatesImportRequest } from '../interfaces/ISurveyTemplatesImportRequest'

export class SurveyTemplatesService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public async importSurveyTemplate(request: ISurveyTemplatesImportRequest) {
    const result = await Parse.Cloud.run('importSurveyTemplate', { request })
    return result
  }

  public async saveSurveyAsTemplate(objectId: string, userId: string, organizationId: string) {
    const result = await Parse.Cloud.run('saveSurveyAsTemplate', {
      objectId,
      userId,
      organizationId,
    })
    return result
  }

  public async getSurveyTemplate(objectId: string, orgId: string): Promise<SurveyTemplate> {
    const result = await Parse.Cloud.run('getSurveyTemplate', { objectId, orgId })
    return result
  }

  public async copySurveyTemplate(objectId: string): Promise<SurveyTemplate> {
    const result = await Parse.Cloud.run('copySurveyTemplate', {
      objectId,
      orgId: this.rootStore.appStore.currentOrgId,
    })
    return result
  }

  public async saveSurveyTemplate(surveyTemplate: any): Promise<SurveyTemplate> {
    const result = await Parse.Cloud.run('saveSurveyTemplate', {
      surveyTemplate,
      orgId: surveyTemplate.organizationId,
    })
    return result
  }

  public async deleteSurveyTemplate(objectId: string): Promise<any> {
    const result = await Parse.Cloud.run('deleteSurveyTemplate', {
      objectId,
      orgId: this.rootStore.appStore.currentOrgId,
    })
    return result
  }

  public async getSurveyFromSystemTemplate(objectId: string, orgId: string): Promise<any> {
    const result = await Parse.Cloud.run('getSurveyFromSystemTemplate', { objectId, orgId })
    return result
  }

  // public async getPulseCampaignSurveyFromSurveyTemplate(
  //   pulseCampaign: IPulseCampaignDTO,
  //   surveyTemplate: ISurveyTemplateDTO
  // ): Promise<IPulseCampaignSurveyDTO> {
  //   const result = await Parse.Cloud.run('getPulseCampaignSurveyFromSurveyTemplate', {
  //     pulseCampaign,
  //     surveyTemplate,
  //   })
  //   return result
  // }

  public async getSurveyTemplateNotificationTemplates(
    surveyTemplateId: string
  ): Promise<ISurveyNotificationTemplatesDTO> {
    const result = await Parse.Cloud.run('getSurveyTemplateNotificationTemplates', {
      surveyTemplateId,
      orgId: this.rootStore.appStore.currentOrgId,
    })
    return result
  }

  public async getSubscribedSurveyTemplates(
    request: ISurveyTemplatesFindRequest
  ): Promise<ISurveyTemplatesFindResult> {
    return await Parse.Cloud.run('getSubscribedSurveyTemplates', { request })
  }

  public async getSurveyTemplates(
    request: ISurveyTemplatesFindRequest
  ): Promise<ISurveyTemplatesFindResult> {
    return await Parse.Cloud.run('getSurveyTemplates', { request })
  }

  public async saveSurveyTemplatePermissions(
    req: ISurveyTemplatePermissionsUpdateRequest
  ): Promise<ISurveyTemplatesFindResult> {
    return await Parse.Cloud.run('saveSurveyTemplatePermissions', { req })
  }
}
