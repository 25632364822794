import React from 'react'
import LocalizationStore from '../../localization/LocalizationStore'
import { AuthStore } from '../store/AuthStore'
import MDSpinner from 'react-md-spinner'
import fullLogo from '../../../assets/img/logos/RippleWorx-White.png'
import './Spinner.scss'

interface Props {
  localizationStore?: LocalizationStore
  authStore?: AuthStore
  router: any
}

const AuthSpinner: React.FC<Props> = ({ router }) => {
  return (
    <div id='AuthSpinner'>
      <div className='center-body'>
        <div>
          <img className='logo' alt='RippleWorx Logo' src={fullLogo} />
        </div>
        <MDSpinner size={50} singleColor='#ffffff' />
      </div>
    </div>
  )
}

export default AuthSpinner
