import { observable, action, computed } from 'mobx'
import { serializable, serialize } from 'serializr'
import { IAggregate } from '../../shared/data/IAggregate'
import { ICategoryDTO } from '../dtos/ICategoryDTO'

export class Category implements ICategoryDTO, IAggregate {
  public static create(orgId) {
    const cat = new Category()
    cat.organizationId = orgId
    return cat
  }

  @serializable @observable public objectId: string = ''
  @serializable @observable public organizationId: string = ''
  @serializable @observable public parentCategoryId: string = ''
  @serializable @observable public name: string = ''
  @serializable @observable public description: string = ''
  @serializable @observable public availableForSkills: boolean = true
  @serializable @observable public availableForSurveys: boolean = true
  @serializable @observable public availableForTasks: boolean = true
  @serializable @observable public availableForWork: boolean = true
  @serializable @observable public isDeleted: boolean = false
  @observable public pathName: string = ''
  @observable public parentPathName: string = ''
  @observable public children: Array<Category> = []
  public isOnServer: boolean = false

  @computed
  public get hasChildren(): boolean {
    return this.children.length !== 0
  }

  @computed
  public get hasParent(): boolean {
    return this.parentCategoryId && this.parentCategoryId !== ''
  }

  @computed
  public get label(): string {
    return this.pathName
  }

  public computeRelations(allCategories: Array<Category>) {
    this.parentPathName = this.computePathName(allCategories, false)
    this.pathName = this.parentPathName ? this.parentPathName + ' \\ ' + this.name : this.name
    // this.loadChildren(allCategories)
    // setTimeout(() => this.computeAllSubordinateCategories(), 500)
  }

  @action
  public getAllSubordinateCategoryIds(): Array<string> {
    return this.getAllSubordinateCategories().map((category) => category.objectId)
  }

  @action
  public getAllSubordinateCategories() {
    if (this.children && this.children.length && this.children.length === 0) return
    const result = []
    const childrenToCheck = [...this.children]
    while (childrenToCheck.length > 0) {
      const nextChild = childrenToCheck.shift()
      if (result.map((category) => category.objectId).includes(nextChild.objectId)) continue
      result.push(nextChild)
      const childrenOfNext = [...nextChild.children]
      if (childrenOfNext.length > 0)
        childrenToCheck.splice(childrenToCheck.length, 0, ...childrenOfNext)
    }
    return result
  }

  @action
  public setChildren(categories: Category[]) {
    this.children = categories
  }

  private computePathName(allCategories: Array<Category>, includeSelf: boolean) {
    let cat: Category = this
    let path = includeSelf ? this.name : ''
    if (!cat.parentCategoryId) return path
    const categoryIdsAlreadyInPath = [this.objectId]
    while (cat && cat.hasParent) {
      const parentCat = allCategories.find((e) => e.objectId === cat.parentCategoryId)
      if (!parentCat) break
      if (categoryIdsAlreadyInPath.includes(parentCat.objectId)) break
      categoryIdsAlreadyInPath.push(parentCat.objectId)
      path = parentCat.name.trim() + (path ? ' \\ ' + path : '')
      cat = parentCat
    }
    return path
  }

  public markIsOnServer() {
    this.isOnServer = true
  }

  public markIsNotOnServer() {
    this.isOnServer = false
  }

  public serialize(): ICategoryDTO {
    return serialize(this)
  }

  public updateFromServer(fromObject: any) {
    this.objectId = fromObject.objectId
    this.name = fromObject.name
    this.parentCategoryId = fromObject.parentCategoryId
    this.description = fromObject.description
    this.availableForSkills = Boolean(fromObject.availableForSkills)
    this.availableForSurveys = Boolean(fromObject.availableForSurveys)
    this.availableForTasks = Boolean(fromObject.availableForTasks)
    this.availableForWork = Boolean(fromObject.availableForWork)
    this.isOnServer = true
  }
}
