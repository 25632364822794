import React, { FC } from 'react'
import { Toolbar, Typography, Button, Tooltip, CssBaseline, AppBar, Box } from '@material-ui/core'
import LinkIcon from '@material-ui/icons/OpenInNewRounded'
import env from '../../../../env'
import '../../../css/dashboard-toolbar.scss'
import { inject, observer } from 'mobx-react'
import clsx from 'clsx'
import isIE11 from '../../../../utils/isIE11'
import { SystemAdminsStore } from '../../../system-admins/store/SystemAdminsStore'
import './SystemAdminToolbar.scss'

export interface Props {
  systemAdminsStore?: SystemAdminsStore
}

const SystemAdminToolbar: FC<Props> = ({ systemAdminsStore }) => {
  const vm = systemAdminsStore.screenVM
  const labelsStore = vm.labelsStore
  const lz = vm.lz
  const sLz = vm.sLz

  const renderManagePulseCampaignTypes = () => {
    return (
      <Tooltip title={lz.manage_pulse_campaign_types} placement='bottom'>
        <span>
          <Button
            variant='outlined'
            className='btn-icon btn-icon-white'
            onClick={() => vm.navigateToManagePulseCampaignTypes()}
          >
            {lz.manage_pulse_campaign_types}
          </Button>
        </span>
      </Tooltip>
    )
  }

  const renderManagePulseCategories = () => {
    return (
      <Tooltip title={lz.manage_questions_and_categories} placement='bottom'>
        <span>
          <Button
            variant='outlined'
            className='btn-icon btn-icon-white'
            onClick={() => vm.navigateToManageCategories()}
          >
            {`${labelsStore.getLabelByLanguageAndFor('pulse')} ${lz.categories}`}
          </Button>
        </span>
      </Tooltip>
    )
  }

  const renderManageCampaignTemplateSets = () => {
    return (
      <Tooltip title={lz.manage_pulse_sets} placement='bottom'>
        <span>
          <Button
            variant='outlined'
            className='btn-icon btn-icon-white'
            onClick={() => vm.navigateToManagePulseSets()}
          >
            {`${lz.campaign_template} ${lz.sets}`}
          </Button>
        </span>
      </Tooltip>
    )
  }

  const renderSurveyTemplates = () => {
    return (
      <Tooltip title={sLz.manage_survey_templates} placement='bottom'>
        <span>
          <Button
            variant='outlined'
            className='btn-icon btn-icon-white'
            onClick={() => vm.navigateToManageSurveyTemplates()}
          >
            {vm.sLz.manage_survey_templates}
            </Button>
        </span>
      </Tooltip>
    )
  }
  
  const renderManageSurveyTypes = () => {
    return (
      <Tooltip title={lz.manage_pulse_types} placement='bottom'>
        <span>
          <Button
            variant='outlined'
            className='dashboard-action btn-icon btn-icon-white end-action'
            onClick={() => vm.navigateToManageSurveyTypes()}
          >
            {`Survey ${lz.types}`}
          </Button>
        </span>
      </Tooltip>
    )
  }

  const renderManageSystemEmailTemplatesButton = () => {
    return (
      <Tooltip title={vm.lzn.manage_email_templates_info} placement='bottom'>
        <span>
          <Button
            variant='outlined'
            className='btn-icon btn-icon-white'
            onClick={() => vm.navigateToManageSystemEmailTemplates()}
          >
            {vm.lzn.manage_email_templates}
          </Button>
        </span>
      </Tooltip>
    )
  }

  const renderCMSLinkButton = () => {
    return (
      <Button
        variant='outlined'
        className='btn-icon btn-icon-white end-action'
        href={`${env.var.REACT_APP_CMS_API_URL}/admin/auth/login`}
        target='_blank'
        startIcon={<LinkIcon />}
      >
        CMS
      </Button>
    )
  }

  return (
    <>
      <div id='SystemDashboardToolbar'>
        <div id='DashboardToolbar'>
          <CssBaseline />
          <AppBar
            className={clsx('dashboard-appbar', {
              'no-transition': isIE11,
            })}
            elevation={0}
          >
            <Toolbar className='dashboard-toolbar'>
              <Typography className='dashboard-title sys-admin'>System Admin</Typography>
              <div className='dashboard-toolbar-right'>
                <Box className='end-content'>
                  <div className='dashboard-action'>{renderManagePulseCampaignTypes()}</div>
                  <div className='dashboard-action'>{renderManagePulseCategories()}</div>
                  <div className='dashboard-action'>{renderManageCampaignTemplateSets()}</div>
                  <div className='dashboard-action'>{renderSurveyTemplates()}</div>
                  <div className='dashboard-action'>{renderManageSurveyTypes()}</div>
                  <div className='dashboard-action'>{renderManageSystemEmailTemplatesButton()}</div>
                  <div className='dashboard-action'>{renderCMSLinkButton()}</div>
                </Box>
              </div>
            </Toolbar>
          </AppBar>
          <Toolbar />
        </div>
      </div>
    </>
  )
}

export default inject('systemAdminsStore')(observer(SystemAdminToolbar))
