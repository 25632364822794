import React from 'react'
import { Observer } from 'mobx-react'
import rootStore from '../../stores/RootStore'
import fullLogo from '../../../assets/img/logos/RippleWorx-White.png'
import './PasswordReset.scss'
import PasswordStrengthMeter from '../../shared/password-strength/PasswordStrengthMeter'
import { Grid, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core'
import PasswordStrengthChecklist from '../../shared/password-strength/PasswordStrengthChecklist'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Visibility from '@material-ui/icons/Visibility'
import MDSpinner from 'react-md-spinner'

interface IPasswordReset {}

const PasswordReset: React.FC<IPasswordReset> = () => {
  const { lzStrings } = rootStore.localizationStore
  const { PasswordResetVM: vm } = rootStore.authStore

  const renderPasswordChecklist = () => {
    return (
      <Grid item md={12} sm={12} className='policy-grid'>
        <PasswordStrengthChecklist vm={vm.meterVM} isVisible />
      </Grid>
    )
  }

  const renderNewPassword = () => {
    return (
      <Grid item md={12} sm={12}>
        <TextField
          fullWidth
          error={!vm.newPasswordValid || !vm.newPasswordsMatch}
          placeholder='New Password'
          id='NewPassword'
          variant='outlined'
          disabled={vm.isProcessing}
          type={vm.showNewPassword ? 'text' : 'password'}
          autoComplete={vm.showNewPassword ? 'off' : 'new-password'}
          value={vm.newPassword}
          onChange={(e) => (vm.newPassword = e.target.value)}
          onFocus={() => vm.setPasswordMeterShown(true)}
          onBlur={() => vm.setPasswordMeterShown(false)}
          InputProps={{
            type: vm.showNewPassword ? 'text' : 'password',
            autoComplete: vm.showNewPassword ? 'off' : 'new-password',
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  edge='end'
                  aria-label='toggle password visibility'
                  onClick={() => vm.toggleShowNewPassword()}
                  onMouseDown={(e) => e.preventDefault()}
                >
                  {!vm.showNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <PasswordStrengthMeter vm={vm.meterVM} isVisible />
        {vm.newPasswordsMatch ? null : (
          <Typography className='error-text'>{lzStrings.profile.no_match_message}</Typography>
        )}
        {!vm.saveTried || (vm.saveTried && vm.meterVM.isNewPasswordStrong) ? null : (
          <Typography className='error-text'>{lzStrings.profile.strength_message}</Typography>
        )}
      </Grid>
    )
  }

  const renderConfirmPassword = () => {
    return (
      <Grid item md={12} sm={12}>
        <TextField
          fullWidth
          error={!vm.confirmNewPasswordValid || !vm.newPasswordsMatch}
          placeholder='Confirm New Password'
          id='ConfirmedPassword'
          variant='outlined'
          disabled={vm.isProcessing}
          type={vm.showConfirmNewPassword ? 'text' : 'password'}
          autoComplete={vm.showNewPassword ? 'off' : 'confirm-new-password'}
          value={vm.confirmNewPassword}
          onChange={(e) => (vm.confirmNewPassword = e.target.value)}
          InputProps={{
            autoComplete: vm.showNewPassword ? 'off' : 'confirm-new-password',
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  edge='end'
                  aria-label='toggle password visibility'
                  onClick={() => vm.toggleShowConfirmNewPassword()}
                  onMouseDown={(e) => e.preventDefault()}
                >
                  {!vm.showConfirmNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {vm.newPasswordsMatch ? null : (
          <Typography className='error-text margin-top'>
            {lzStrings.profile.no_match_message}
          </Typography>
        )}
      </Grid>
    )
  }

  const renderButtonText = () => {
    if (vm.isProcessing) {
      return (
        <>
          <MDSpinner size={20} singleColor='#ffffff' />
        </>
      )
    } else {
      return <>{lzStrings.passwordReset.change_password}</>
    }
  }

  const renderErrorText = () => {
    if (!vm.errorMessage) return
    return (
      <Typography className='error-text margin-top'>
        {lzStrings.passwordReset.token_expired}
      </Typography>
    )
  }

  const renderHeaderText = () => {
    if (vm.isExpiredPasswordReset) {
      return (
        <>
          <Typography className='header-text'>
            {lzStrings.passwordReset.your_password_expired}
          </Typography>
          <Typography className='error-text margin-bottom'>
            {lzStrings.passwordReset.please_enter_new}
          </Typography>
        </>
      )
    }
    return <h4>{lzStrings.passwordReset.reset_your_password}</h4>
  }

  return (
    <Observer>
      {() => (
        <div className='reset'>
          <div className='center-body'>
            <div>
              <img className='logo' alt='RippleWorx Logo' src={fullLogo} />
            </div>
            <form>
              <div className='card'>
                {renderHeaderText()}
                <Grid container spacing={1} direction='row'>
                  <Grid item md={8} sm={8}>
                    <Grid container spacing={2}>
                      {renderNewPassword()}
                      {renderConfirmPassword()}
                    </Grid>
                  </Grid>
                  <Grid item md={4} sm={4}>
                    {renderPasswordChecklist()}
                  </Grid>
                </Grid>
                {renderErrorText()}
                <div className='button-container'>
                  <button
                    className='request-pw-button'
                    disabled={vm.isProcessing}
                    type='button'
                    onClick={vm.handleSubmit}
                  >
                    {renderButtonText()}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </Observer>
  )
}

export default PasswordReset
