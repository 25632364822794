import React from 'react'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import { ButtonBase } from '@material-ui/core'
import { OrganizationUsersWidgetVM } from '../../view-models/widget/OrganizationUsersWidgetVM'

interface Props {
  vm: OrganizationUsersWidgetVM
}

const WidgetTitle: React.FC<Props> = ({ vm }) => {
  const renderActiveUsers = () => {
    return (
      <ButtonBase
        onClick={() => vm.setTabIndex(0)}
        className={clsx('tab-button', {
          active: vm.tabIndex === 0,
        })}
      >
        Active
      </ButtonBase>
    )
  }

  const renderArchivedUsers = () => {
    return (
      <ButtonBase
        onClick={() => vm.setTabIndex(1)}
        className={clsx('tab-button', {
          active: vm.tabIndex === 1,
        })}
      >
        Archived
      </ButtonBase>
    )
  }

  return (
    <div className='widget-title-tabs'>
      <span>Organizational Users</span>
      <div className='tab-btns'>
        {renderActiveUsers()}
        {renderArchivedUsers()}
      </div>
    </div>
  )
}

export default observer(WidgetTitle)