import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { UserTrainingPlansStore } from '../../training-plans/user-training-plans/store/UserTrainingPlansStore'
import { QuizTakeVM } from '../view-models/QuizTakeVM'
import QuizConfirmDialog from './take/QuizConfirmDialog'
import QuizTake from './take/QuizTake'
import QuizResults from '../../quiz-results/views/QuizResults'

interface Props {
  vm: QuizTakeVM
  userTrainingPlansStore?: UserTrainingPlansStore
}

const QuizView: React.FC<Props> = ({ vm, userTrainingPlansStore }) => {
  if (!vm) return null

  const renderQuizTake = () => {
    if (vm.showQuizResults) return null
    return <QuizTake vm={vm}/>
  }

  const renderQuizResults = () => {
    if (!vm.showQuizResults) return null
    return <QuizResults vm={vm.resultsVM} />
  }

  return (
    <div id='QuizTake'>
      <QuizConfirmDialog vm={vm} />
      {renderQuizTake()}
      {renderQuizResults()}
    </div>
  )
}

export default inject('userTrainingPlansStore')(observer(QuizView))
