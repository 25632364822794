export class ServiceUtils {
    public static addFilter (query: Parse.Query, filterModel) {
        Object.entries(filterModel).forEach(([key, value]) => {
            const {filterType, operator, condition1} = value as any
            if (operator === 'OR') {
                // not supported
                console.log('OR filter not supported in this version of Parse')
                const cond1 = {
                    [key] : condition1,
                }
                return this.addFilter(query, cond1)
            }
            if (filterType === 'number') {
                const {filter, type, filterTo} = value as any
                if (type === 'greaterThan') query.greaterThan(key, filter)
                if (type === 'equals') query.equalTo(key, filter)
                if (type === 'lessThan') query.lessThan(key, filter)
                if (type === 'notEqual') query.notEqualTo(key, filter)
                if (type === 'lessThanOrEqual') query.lessThanOrEqualTo(key, filter)
                if (type === 'greaterThanOrEqual') query.greaterThanOrEqualTo(key, filter)
                if (type === 'inRange') {
                    query.greaterThanOrEqualTo(key, filter)
                    query.lessThanOrEqualTo(key, filterTo)
                }
            }
        })

    }
    
    public static addSort (query: Parse.Query, sortModel) {
        sortModel.forEach(({colId, sort}) => {
            if (sort === 'asc') query.addAscending(colId)
            if (sort === 'desc') query.descending(colId)
        })
    }
}