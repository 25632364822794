import React, { useRef, FC, useEffect } from 'react'
import {
  MenuItem,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  IconButton,
  TextField,
} from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { TimePickerVM } from './TimePickerVM'
import { observer, useLocalStore, useObserver } from 'mobx-react'
import './PulseTimePicker.scss'
import moment, { Moment } from 'moment'
import ClockIcon from '@material-ui/icons/Timer'

interface TimePickerProps {
  onChange: Function
  value: Date
  error: boolean
  textFieldClassName?: string
  textFieldStyle?: any
  disabled?: boolean
  beginningOfDay?: Date
  endOfDay?: Date
}

const TimePicker: FC<TimePickerProps> = ({
  onChange,
  value,
  error,
  textFieldClassName,
  textFieldStyle,
  disabled,
  beginningOfDay,
  endOfDay,
}) => {
  const anchorRef = useRef(null)
  const paperRef = useRef(null)

  const localStore = useLocalStore(() => ({
    vm: new TimePickerVM(onChange, value, beginningOfDay, endOfDay),
  }))

  useEffect(() => {
    localStore.vm.setSelectedTime(moment(value).clone())
  }, [value])

  const handleOutsideClick = (e) => {
    if (anchorRef.current && !anchorRef.current.contains(e.target)) {
      localStore.vm.closeMenu()
      localStore.vm.verifyInputText()
    }
  }

  const vm = localStore.vm
  if (!vm) return undefined
  return useObserver(() => (
    <>
      <TextField
        disabled={disabled}
        ref={anchorRef}
        value={vm.inputText}
        onFocus={(e) => {
          vm.handleInputFocus()
          setTimeout(() => {
            let pixelsToScroll = 31 * Math.round((vm.hour * 60 + vm.minute) / 30)
            if (paperRef && paperRef.current) {
              paperRef.current.scrollTop = pixelsToScroll
            }
          }, 0)
        }}
        onChange={(e) => {
          vm.setInputText(e.target.value)
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') vm.handleEnterKeyDown(e)
          else if (e.key === 'Tab') vm.handleTabKeyDown(e)
        }}
        error={!vm.isInputTextValid || error}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <>
              <ClockIcon className='clock-icon' />
            </>
          ),
          endAdornment: (
            <>
              <IconButton
                size='small'
                disabled={disabled}
                onClick={(e) => {
                  vm.handleClickArrowLeft()
                }}
              >
                <ChevronLeftIcon fontSize='medium' />
              </IconButton>
              <IconButton
                disabled={disabled}
                size='small'
                onClick={(e) => {
                  vm.handleClickArrowRight()
                }}
              >
                <ChevronRightIcon fontSize='medium' />
              </IconButton>
            </>
          ),
          style: {
            paddingRight: 5,
          },
        }}
        size='small'
        variant='standard'
        style={
          textFieldStyle
            ? textFieldStyle
            : {
                backgroundColor: '#f8f8fb',
                width: '230px',
                marginTop: 10,
              }
        }
        className={textFieldClassName ? textFieldClassName : 'custom-time-picker'}
      />
      <Popper
        open={vm.menuOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 5000 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper
              style={{
                maxHeight: '200px',
                overflowY: 'scroll',
                overflowX: 'hidden',
                width: anchorRef.current ? anchorRef.current.clientWidth : '200px',
              }}
              innerRef={paperRef}
            >
              <ClickAwayListener onClickAway={handleOutsideClick}>
                <MenuList>
                  {vm.timeOptionsToDisplay.map((m: Moment, index) => {
                    return (
                      <MenuItem key={index} onClick={(e) => vm.setSelectedTime(m)}>
                        {m.format(vm.displayFormat)}
                      </MenuItem>
                    )
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  ))
}

export default TimePicker
