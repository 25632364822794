import React from 'react'
import { observer } from 'mobx-react'
import rootStore from '../../stores/RootStore'
import { EmailTemplatesManageVM } from '../view-models/EmailTemplatesManageVM'
import { EmailTemplatesTableVM } from '../view-models/EmailTemplatesTableVM'
import EmailTemplatesManage from './EmailTemplatesManage'

interface Props {
  props: any
}

@observer
export default class EmailTemplatesManageWrapper extends React.Component<Props, any> {
  private vm: EmailTemplatesManageVM
  private tableVM: EmailTemplatesTableVM

  constructor(props) {
    super(props)

    this.vm = rootStore.emailTemplatesStore.viewModels.manageVM
    this.tableVM = rootStore.emailTemplatesStore.viewModels.sysTableVM
    if (props.params && props.params.id && this.vm) {
      // rootStore.emailTemplatesStore.lazyLoadVM(props.params.id)
    }
  }

  componentWillUnmount() {}

  render() {
    return (
      <EmailTemplatesManage vm={this.vm} />
    )
  }
}