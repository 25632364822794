import React from 'react'
import { observer, inject } from 'mobx-react'
import {
  Button,
  ButtonGroup,
  makeStyles,
  Tooltip,
} from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import MDSpinner from 'react-md-spinner'
import './SurveyTemplatesSelectHeader.scss'
import { SurveyTemplatesSelectVM } from '../../../view-models/template-select/SurveyTemplatesSelectVM'
import LocalizationStore from '../../../../localization/LocalizationStore'
import Searchbar from '../../../../shared/Searchbar'


interface Props {
  vm: SurveyTemplatesSelectVM
  localizationStore?: LocalizationStore
}

const SurveyTemplatesSelectHeader: React.FC<Props> = ({ vm, localizationStore }) => {
  const { tableVM: surveyTemplatesTable } = vm
  if (!surveyTemplatesTable) return null
  const lz = localizationStore.lzStrings.orgUsersList


  const renderSearch = () => {
    return (
      <div className='search'>
        <Searchbar
          focused={surveyTemplatesTable.hasFilter}
          searchValue={surveyTemplatesTable.typedFilterText}
          onSearchChange={(e) => {
            surveyTemplatesTable.setQuickFilter(e.target.value)
          }}
          className='action-btn'
        />
      </div>
    )
  }


  const renderRefreshIcon = () => {
    if (!vm.tableVM.isLoaded) return <MDSpinner size={20} singleColor='#05d1cf' />
    return <RefreshIcon />
  }

  const renderRefreshButton = () => {
    return (
      <Tooltip title={'Refresh'} placement='bottom'>
        <Button
          onClick={(e) => {
            surveyTemplatesTable.hardRefreshTemplatesList()
          }}
          variant='outlined'
          className='action-btn-end refresh-btn'
        >
          {renderRefreshIcon()}
        </Button>
      </Tooltip>
    )
  }

  const renderTenantSystemToggle = () => {
    return (
      <div className='toggle-action-btn'>
        <ButtonGroup>
          <Button
            key='anon-on'
            variant='outlined'
            className={vm.foldersVM.isSystemToggleMode ? 'Mui-selected toggle-btn' : 'toggle-btn'}
            onClick={() => vm.foldersVM.toggleSystemMode()}
          >
            {'System'}
          </Button>
          <Button
            key='anon-off'
            variant='outlined'
            className={!vm.foldersVM.isSystemToggleMode ? 'Mui-selected toggle-btn' : 'toggle-btn'}
            onClick={() => vm.foldersVM.toggleSystemMode()}
          >
            {'Tenant'}
          </Button>
        </ButtonGroup>
      </div>
    )
  }

  return (
    <div id='SurveyTemplatesSelectHeader'>
      <div className='manage-templates-container'>
        <div className='left'>
          {renderTenantSystemToggle()}
          </div>
        <div className='right'>
          <div className='right-left'></div>
          <div className='right-right'>
            {renderSearch()}
            {renderRefreshButton()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default inject(
  'organizationUsersStore',
  'localizationStore'
)(observer(SurveyTemplatesSelectHeader))
