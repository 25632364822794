import React from 'react'
import { inject, observer } from 'mobx-react'
import { Grid, IconButton, Tooltip } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import DescriptionIcon from '@material-ui/icons/Description'
import VideoIcon from '@material-ui/icons/VideocamRounded'
import { OldAttachmentVM } from '../../attachments/view-models/OldAttachmentVM'
import { OldFileAttachmentVM } from '../../training-items/view-models/OldFileAttachmentVM'

interface Props {
  vm: OldAttachmentVM | OldFileAttachmentVM
  index?: number
  onClose?: Function
  showDelete?: boolean
}

const CMSThumbnail: React.FC<Props> = ({ vm, index, onClose, showDelete = true }) => {
  if (!vm) return

  const renderThumbnail = () => {
    if (vm.isImage) return <img src={vm.url} />
    if (vm.isVideo) {
      if (vm.url) {
        if (vm.hasYouTubeURL) return <VideoIcon />
        return (
          <video>
            <source src={vm.url} type='video/mp4' />
          </video>
        )
      } else return <VideoIcon />
    }
    return <DescriptionIcon />
  }

  const docStyle = () => {
    if (vm.isImage) return 'thumbnail-container'
    if (vm.isVideo) {
      if (!vm.url) return 'file-container'
      if (vm.hasYouTubeURL) return 'file-container'
      return 'thumbnail-container'
    }
    return 'file-container'
  }

  const renderDeleteBtn = () => {
    if (!showDelete) return
    return (
      <div>
        <IconButton
          onClick={() => {
            if (onClose) onClose()
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
    )
  }

  return (
    <>
      <Grid item xs={6} key={index} className='attachment-container'>
        <div className='attachment-content'>
          <div className={docStyle()}>{renderThumbnail()}</div>
          <Tooltip title={vm.fileName ? vm.fileName : ''} placement='bottom-start' enterDelay={500}>
            <span style={{ cursor: 'pointer' }} onClick={() => vm.openAttachment()}>
              {vm.shorterFileName}
            </span>
          </Tooltip>
        </div>
        {renderDeleteBtn()}
      </Grid>
    </>
  )
}

export default inject()(observer(CMSThumbnail))
