import React, { FC } from 'react'
import { observer, inject } from 'mobx-react'
import './SurveyOptions.scss'
import { SurveysStore } from '../../../store/SurveysStore'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { LabelsStore } from '../../../../labels/store/LabelsStore'
import AdditionalResponseNotificationEditor from './AdditionalResponseNotificationEditor'

interface Props {
  surveysStore?: SurveysStore
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
}

const AdditionalResponseNotifications: FC<Props> = ({
  surveysStore,
  localizationStore,
  labelsStore,
}) => {
  const surveyEditVM = surveysStore.viewModels.editVM
  const vm = surveyEditVM.surveyOptionsVM
  if (!surveyEditVM) return null
  if (surveyEditVM.limited) return null
  if (!vm) return null
  const s = localizationStore.lzStrings.surveys
  return (
    <div>
      <div className='secondary-text'>{`Select users to recieve notifications on response recieved`}</div>
      <div className='section-content'>
        <AdditionalResponseNotificationEditor />
      </div>
    </div>
  )
}

export default inject(
  'surveysStore',
  'localizationStore',
  'labelsStore'
)(observer(AdditionalResponseNotifications))
