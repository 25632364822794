import React from 'react'
import { inject, observer } from 'mobx-react'
import { Avatar, Chip, Grid, IconButton, Tooltip, Typography } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import rootStore from '../../../stores/RootStore'
import { PulseCampaignSummaryVM } from '../../view-models/PulseCampaignSummaryVM'
import { PulseCategoriesManageVM } from '../../view-models/PulseCategoriesManageVM'
import usePagination from './Pagination'
import PulseQuestion from 'src/app/pulse-questions/aggregate/PulseQuestion'
import PersistentDrawer from './PersistentDrawer'
import TopToolbar from './TopToolbar'
import './PulseCampaignSummary.scss'
import LocalizationStore from '../../../localization/LocalizationStore'

interface Props {
  vm: PulseCampaignSummaryVM
  pulseCategoriesVM: PulseCategoriesManageVM
  localizationStore?: LocalizationStore
}

const PulseCampaignSummary: React.FC<Props> = ({ pulseCategoriesVM, vm, localizationStore }) => {
  const { pulseCampaign: lz } = localizationStore.lzStrings
  if (!vm) return null

  const data = vm.questions
  if (!data) return null
  const count = data.length > 0 ? Math.ceil(data.length / vm.PER_PAGE) : 1
  const _DATA = usePagination(data, vm.PER_PAGE)

  const handleChange = (e, p) => {
    vm.setPage(p)
    _DATA.jump(p)
  }

  if (vm && vm.page > _DATA.getMaxPage()) handleChange({}, _DATA.getMaxPage())

  return (
    <>
      <PersistentDrawer>
        <div id='PulseCampaignSummary'>
          <Grid
            container
            direction='column'
            justifyContent='flex-start'
            alignItems='flex-start'
            className={'pulse-campaign-summary-grid'}
          >
            <Grid item className={'pulse-campaign-summary-grid-header'}>
              <Grid
                container
                direction='row'
                justifyContent='flex-start'
                alignItems='flex-start'
                className={'pulse-campaign-summary-grid-header-questions-selected-grp'}
              >
                <Typography
                  component='span'
                  className='pulse-campaign-summary-grid-header-questions-selected-number'
                >
                  {vm.questionsSelectedCount}
                </Typography>
                <Typography
                  component='span'
                  variant='caption'
                  className={'pulse-campaign-summary-grid-header-questions-selected-text'}
                >
                  {lz.questions_selected}
                </Typography>
              </Grid>
            </Grid>
            <Grid item className={'pulse-campaign-summary-grid-categories'}>
              <Grid
                container
                direction='row'
                justifyContent='space-around'
                alignItems='flex-start'
                className={'pulse-campaign-summary-grid-categories-grps'}
              >
                <TopToolbar vm={vm} />
              </Grid>
            </Grid>
            <Grid item className={'pulse-campaign-summary-grid-questions'}>
              <Grid
                container
                direction='row'
                justifyContent='flex-start'
                alignItems='flex-start'
                className={'pulse-campaign-summary-grid-questions-list'}
              >
                {_DATA.currentData().map((v: PulseQuestion, index: number) => {
                  return (
                    <Grid
                      item
                      id={`pc-summary-q-${v.objectId}`}
                      key={`pc-summary-question-grp-${index}`}
                      className={'pulse-campaign-summary-grid-questions-list-root'}
                    >
                      <div
                        className={`pulse-campaign-summary-grid-questions-list-grp border-cat-${pulseCategoriesVM.getGrandParentCategoryIndex(
                          v.fk_grandCategoryId.objectId
                        )}`}
                      >
                        <Grid
                          container
                          direction='column'
                          justifyContent='flex-start'
                          alignItems='flex-start'
                          className={`pulse-campaign-summary-grid-questions-list-grp-item border-cat-${pulseCategoriesVM.getGrandParentCategoryIndex(
                            v.fk_grandCategoryId.objectId
                          )}`}
                          key={`pc-summary-question-item-${index}-${v.objectId}`}
                        >
                          <Typography
                            component='span'
                            variant='caption'
                            className='pulse-campaign-summary-grid-questions-list-grp-item-text'
                          >
                            {v.title}
                          </Typography>
                          <Chip
                            variant='outlined'
                            color='primary'
                            label={v.fk_parentCategoryId.name}
                            size='small'
                            className='pulse-campaign-summary-grid-questions-list-grp-item-chip'
                          />
                        </Grid>

                        <div className={'pc-card-header-actions'}>
                          <Tooltip title={lz.delete} placement='bottom-start'>
                            <span>
                              <IconButton
                                aria-label='pc-card-header-action-delete'
                                className={'pc-card-btn'}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  e.preventDefault()
                                  rootStore.pulseCampaignsStore.viewModels.editVM.deleteQuestionById(
                                    v.objectId
                                  )
                                }}
                              >
                                <Avatar className={`pc-card-btn-avatar`}>
                                  <DeleteIcon fontSize='small' />
                                </Avatar>
                              </IconButton>
                            </span>
                          </Tooltip>
                        </div>
                      </div>
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
          </Grid>
          <div className='pulse-campaign-summary-pagination'>
            <Pagination
              count={count}
              size='small'
              page={vm.page}
              variant='outlined'
              color='primary'
              onChange={handleChange}
            />
          </div>
        </div>
      </PersistentDrawer>
    </>
  )
}

export default inject('pulseCampaignsStore', 'localizationStore')(observer(PulseCampaignSummary))
