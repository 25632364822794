import React from 'react'
import { observer, Observer } from 'mobx-react'
import rootStore from '../../../stores/RootStore'
import TabsBar from './TabsBar'
import TopToolbar from './TopToolbar'
import CloseIcon from '@material-ui/icons/Close'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import QuestionCatalogDrawer from './questions-catalog/QuestionsCatalogDrawer'
import './SurveyEditScreen.scss'
import Steppers from './steppers/Steppers'
import MDSpinner from 'react-md-spinner'

@observer
export default class SurveyEditScreen extends React.Component<any, any> {
  // constructor(props) {
  //   super(props)
  //   this.checkLoadedObjectId(props.params.id)
  //   if (rootStore.surveysStore.viewModels.editVM) {
  //     rootStore.surveysStore.viewModels.editVM.loadDiagram()
  //   }
  // }

  // UNSAFE_componentWillUpdate(props) {
  //   this.checkLoadedObjectId(props.params.id)
  // }

  // checkLoadedObjectId(id) {
  //   if (
  //     id &&
  //     rootStore.surveysStore.viewModels.editVM &&
  //     rootStore.surveysStore.viewModels.editVM.loadedWithObjectId !== id
  //   ) {
  //     rootStore.surveysStore.loadSurveyEditVM(id)
  //   } else if (id && !rootStore.surveysStore.viewModels.editVM) {
  //     rootStore.surveysStore.loadSurveyEditVM(id)
  //   } else if (!id) {
  //     rootStore.surveysStore.loadSurveyEditVM()
  //   }
  // }

  constructor(props) {
    super(props)
    this.refreshData(props)
  }

  refreshData(props) {
    if (!rootStore.notificationsStore.loaded) return setTimeout(() => this.refreshData(props), 1000)
    if (props.params.surveyTemplateId) {
      rootStore.surveysStore.viewModels.loadSystemSurveyTemplateEditVM(
        props.params.surveyTemplateId,
        props.params.orgId,
        props.params.folderId
      )
    }
    if (props.params.id && !props.params.templateId) {
      rootStore.surveysStore.viewModels.loadSurveyEditVM(props.params.id)
    }
    if (props.params.templateId && props.params.userTaskId && props.params.userId) {
      rootStore.surveysStore.viewModels.createSurveyFromTemplateForUserTask(
        props.params.templateId,
        props.params.userTaskId,
        props.params.userId
      )
    }
    if (props.params.eventId && props.params.userId) {
      rootStore.surveysStore.viewModels.createNewSurveyFromEvent(
        props.params.eventId,
        props.params.userId
      )
    }
    if (props.params.userId && !props.params.eventId && !props.params.templateId) {
      rootStore.surveysStore.viewModels.createNewSurveyForUser(props.params.userId)
    }
    if (props.params.groupId && !props.params.eventId && !props.params.templateId) {
      rootStore.surveysStore.viewModels.createNewSurveyForGroup(props.params.groupId)
    }
    if (props.params.roleId && !props.params.eventId && !props.params.templateId) {
      rootStore.surveysStore.viewModels.createNewSurveyForRole(props.params.roleId)
    }
    if (props.params.systemTemplateId && !props.params.userId) {
      rootStore.surveysStore.viewModels.createSurveyFromSystemTemplate(
        props.params.systemTemplateId
      )
    }
    if (props.params.templateId && !props.params.userId) {
      rootStore.surveysStore.viewModels.createSurveyFromTemplate(props.params.templateId)
    }
  }

  routerWillLeave(nextLocation) {
    if (!rootStore.surveysStore.viewModels.editVM) return
    if (rootStore.surveysStore.viewModels.editVM.isDirty) {
      return 'You have unsaved information, are you sure you want to leave this page?'
    }
  }

  componentDidMount() {
    this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave.bind(this))
  }

  componentWillUnmount() {
    rootStore.surveysStore.viewModels.loadSurveyEditVM('empty')
  }

  componentDidUpdate(props) {
    if (props.params.templateId && !props.params.userId) {
      this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave.bind(this))
    }
  }

  renderSnackbar() {
    if (!rootStore.surveysStore.viewModels.editVM) return null
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={rootStore.surveysStore.viewModels.editVM.isSnackbarOpen}
        autoHideDuration={10000}
        onClose={() => rootStore.surveysStore.viewModels.editVM.closeSnackbar()}
        message={
          <Typography>{rootStore.surveysStore.viewModels.editVM.snackbarMessage}</Typography>
        }
        action={[
          <Observer key={'1'}>
            {() => (
              <IconButton
                key='close'
                aria-label='close'
                color='inherit'
                onClick={() => rootStore.surveysStore.viewModels.editVM.closeSnackbar()}
              >
                <CloseIcon />
              </IconButton>
            )}
          </Observer>,
        ]}
      />
    )
  }

  render() {
    if (!rootStore.surveysStore.viewModels.editVM) {
      return (
        <div id='SurveyEditScreen'>
          <div className='spinner-container'>
            <MDSpinner size={50} singleColor='#ffffff' />
          </div>
        </div>
      )
    }
    return (
      <div id='SurveyEditScreen'>
        <TopToolbar routeParams={this.props.params} />
        <Steppers />
        <TabsBar />
        <QuestionCatalogDrawer />
        {this.renderSnackbar()}
      </div>
    )
  }
}
