import Parse from 'parse'
import { IUserAnnouncementEventsRequest } from '../interfaces/IUserAnnouncementEventsRequest'
import { IUserAnnouncementEventsResult } from '../interfaces/IUserAnnouncementEventsResult'

export class UserAnnouncementEventService {
  public async getUserAnnouncementEvents(
    request: IUserAnnouncementEventsRequest
  ): Promise<IUserAnnouncementEventsResult> {
    return await Parse.Cloud.run('getUserAnnouncementEvents', { request })
  }
}
