import { persist } from 'mobx-persist'
import { observable } from 'mobx'
import { IMediaDTO } from '../dtos/IMediaDTO'
import { MediaItemType } from '../../upload/types/MediaItemType'

export class Media implements IMediaDTO {
  static create(m) {
    const media = new Media()
    media.type = m.type
    media.name = m.name
    media.path = m.path
    media.objectId = m.objectId
    media.watchPercentageRequirement = m.watchPercentageRequirement
    media.cmsItemId = m.cmsItemId
    return media
  }

  @persist @observable objectId: string = ''
  @persist @observable type: MediaItemType = null
  @persist @observable name: string = ''
  @persist @observable path: string = ''
  @persist @observable mediaWatchPercentage: number = 0
  @persist @observable watchPercentageRequirement: number = 0
  @persist @observable cmsItemId: number = undefined
}
