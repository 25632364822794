import { observable, computed, action } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { MediaItemVM } from '../../../user-surveys/view-models/MediaItemVM'
import { QuestionType } from '../../../user-surveys/types/QuestionType'
import PulseQuestion from '../../../pulse-questions/aggregate/PulseQuestion'
import isMobile from '../../../../utils/isMobile'
import { PulseQuestionOption } from '../../../pulse-questions/aggregate/PulseQuestionOption'
import { PulseQuestionOptionVM } from '../questions/PulseQuestionOptionVM'
import { PulseQuestionEditVM } from '../PulseQuestionEditVM'
import { isNumeric } from '../../../shared/isNumeric'
import { CMSMediaItemVM } from '../../../user-surveys/view-models/CMSMediaItemVM'
import { OldMediaItemVM } from '../../../user-surveys/view-models/OldMediaItemVM'

export class PulseQuestionVM {
  private rootStore: RootStore
  private question: PulseQuestionEditVM

  constructor(rootStore, question: PulseQuestionEditVM) {
    this.rootStore = rootStore
    this.question = question
    this.loadData(question.pulseQuestion)
  }

  private loadData(question: PulseQuestion) {
    this.type = question.type as QuestionType
    this.required = question.required
    this.ratingDisplay = question.ratingDisplay
    if (question.media) {
      if (isNumeric(question.media.objectId) || question.media.cmsItemId) {
        this.media = new CMSMediaItemVM(this.rootStore, question.media)
      } else {
        this.media = new OldMediaItemVM(
          this.rootStore,
          question.media,
          question.youTubeURL,
          question.vimeoURL
        )
      }
    }

    if (
      !this.media?.path ||
      this.media?.type === 'image' ||
      this.question.media.watchPercentageRequirement === 0
    )
      this.watchPercentageReached = true
    question.options.forEach((opt: PulseQuestionOption, index: number) => {
      this.options.push(
        new PulseQuestionOptionVM(
          this.rootStore,
          opt,
          index,
          new PulseQuestionEditVM(this.rootStore, question, true)
        )
      )
    })

    this.isDropdownMultiSelect = question.isDropdownMultiSelect
    this.placeholder = question.placeholder
  }

  @observable type: QuestionType = null
  @observable index: number = 0
  @observable options: Array<PulseQuestionOptionVM> = []
  @observable media: MediaItemVM = null
  @observable required: boolean = false
  @observable saveTried: boolean = false
  @observable playedSeconds: number = 0
  @observable mediaTimeLeft: number = 0
  @observable watchSecondsNeeded: number = 0
  @observable watchPercentageReached: boolean = false
  @observable ratingDisplay: string = ''
  @observable isDirty: boolean = false
  displayedIndex: number = 0
  @observable showLightBox: boolean = false
  @observable oldIndex: number = 0
  @observable newIndex: number = 0
  @observable hover: number = 0
  @observable public isExpanded: boolean = false
  @observable isDropdownMultiSelect: boolean = false
  @observable placeholder: string = 'Please Select'

  @computed
  public get html(): string {
    return this.question.html
  }

  @action
  public setIsDirty() {
    this.isDirty = true
  }

  @action
  public setHover(newHover: number) {
    this.hover = newHover
  }

  @action
  public setNewRank(value) {
    this.newIndex = value
  }

  @computed
  public get isForResponseDisplay(): boolean {
    return false
  }

  @action
  public toggleLightBox() {
    this.showLightBox = !this.showLightBox
  }

  @action
  public setValue(val) {
    if (!val) return
    this.options.forEach((e) => (e.isChecked = false))
    this.options.filter((e) => e.value === val)[0].isChecked = true
  }

  @action
  public watchProgress(event) {
    this.playedSeconds++
    if (this.mediaTimeLeft > 0) this.mediaTimeLeft--
    if (Math.floor(event.playedSeconds) > this.watchSecondsNeeded) {
      this.watchPercentageReached = true
    }
  }

  @action
  public setWatchPercentageNeeded(event) {
    const duration = Math.floor(event)
    if (this.question.media.watchPercentageRequirement > 0) {
      this.watchSecondsNeeded = duration * (this.question.media.watchPercentageRequirement / 100)
      this.mediaTimeLeft = this.watchSecondsNeeded
    }
  }

  @computed
  public get isShown(): boolean {
    return true
  }

  @computed
  public get value(): number {
    if (this.type === 'rating' || this.type === 'starRating') {
      const currentSelectedOption = this.options.find((e) => e.isChecked === true)
      if (currentSelectedOption) return currentSelectedOption.value
      return 0
    }
    return null
  }

  @computed
  public get ratingDisplayVal(): string {
    return this.question.ratingDisplay
  }

  public setDisplayedIndex(val: number) {
    this.displayedIndex = val
  }

  @computed
  public get validatedNumberResponse(): boolean {
    if (!this.isDirty) return false
    if (
      Number(this.options[0].responseNumber) >= Number(this.minimumValueAllowed) &&
      Number(this.options[0].responseNumber) <= Number(this.maximumValueAllowed)
    )
      return true
    return false
  }

  @computed
  public get hasAnswer(): boolean {
    if (this.type === 'infoText') return true
    if (this.type === 'order') return true
    if (this.type === 'number') return this.validatedNumberResponse
    if (this.type !== 'text') {
      return this.options.filter((e) => e.isChecked).length !== 0
    } else {
      return this.options[0].text !== ''
    }
  }

  @computed
  public get textAnswer(): string {
    if (this.hasAnswer) {
      return this.options[0].text
    } else {
      return ''
    }
  }

  @computed
  public get blurred(): boolean {
    return false
  }

  @computed
  public get ratingText(): string {
    const index = this.hover - 1 > -1 ? this.hover - 1 : this.value - 1
    if (index < 0) return `${isMobile ? 'Tap' : 'Click'} to Select`
    if (this.type === 'rating' || this.type === 'starRating') {
      const currentSelectedOption = this.options[index].text
      return currentSelectedOption ? currentSelectedOption : ''
    }
    return null
  }

  @computed
  public get objectId(): string {
    return this.question.objectId
  }

  @computed
  public get title(): string {
    return this.question.title
  }

  @computed
  public get originalPulseQuestionId(): string {
    return this.question.originalPulseQuestionId
  }

  @computed
  public get maximumValueAllowed(): string {
    return Number(this.question.maximumValueAllowed) === 0
      ? String(10 ** 22 - 1)
      : String(this.question.maximumValueAllowed)
  }

  @computed
  public get minimumValueAllowed(): string {
    return String(this.question.minimumValueAllowed)
  }

  @computed
  public get numberStep(): string {
    if (Number(this.question.numberStep) === 0) return '1'
    return String(this.question.numberStep)
  }

  @computed
  public get hasMedia(): boolean {
    if (!this.media) return false
    if (this.media.media && this.media.media.path) return true
    if (Boolean(this.media.hasMedia)) return true
    return this.media.hasMedia
  }

  @computed
  public get hasYouTubeURL(): boolean {
    return Boolean(this.question.pulseQuestion.youTubeURL)
  }

  @computed
  public get hasVimeoURL(): boolean {
    return Boolean(this.question.pulseQuestion.vimeoURL)
  }

  @computed
  public get youTubeId(): string {
    if (!this.hasYouTubeURL) return ''
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
    const match = this.question.pulseQuestion.youTubeURL.match(regExp)

    return match && match[2].length === 11 ? match[2] : null
  }

  @computed
  public get vimeoId(): string {
    if (!this.hasVimeoURL) return ''
    let regExp =
      /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/
    if (this.question.pulseQuestion.vimeoURL.includes('player.vimeo.com')) {
      regExp =
        /(http|https)?:\/\/(www\.)?player.vimeo.com\/(?:video\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/
    }
    const match = this.question.pulseQuestion.vimeoURL.match(regExp)

    return match && match.length ? match[4] : ''
  }

  @computed
  public get embedUrl(): string {
    if (this.hasYouTubeURL) return `https://www.youtube.com/embed/${this.youTubeId}`
    else if (this.hasVimeoURL) return `https://player.vimeo.com/video/${this.vimeoId}`
    else return ''
  }

  @computed
  public get isValid() {
    if (!this.saveTried) return true
    return true
  }

  public toDTO() {
    return {
      objectId: this.question.objectId,
      title: this.title,
      type: this.type,
      options: this.options.map((e) => e.toDTO()),
      media: this.media.toDTO(),
      required: this.required,
      html: this.html,
      ratingDisplay: this.ratingDisplay,
    }
  }
}
