import React from 'react'
import './QuestionDivider.scss'

const QuestionRowDivider: React.FC = () => {
  return (
    <div id='QuestionDivider'>
      <div className='indicator'></div>
    </div>
  )
}

export default QuestionRowDivider
