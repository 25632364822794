import { observable, action } from 'mobx'
import { ILocationDTO } from '../dtos/ILocationDTO'
import { serializable, serialize } from 'serializr'
import { IAggregate } from '../../shared/data/IAggregate'

export default class Location implements ILocationDTO, IAggregate {
  static create(orgId) {
    const location = new Location()
    location.organizationId = orgId
    return location
  }

  @serializable @observable objectId: string = ''
  @serializable @observable name: string = ''
  @serializable @observable organizationId: string = ''
  @serializable @observable lng: number = null
  @serializable @observable lat: number = null
  @serializable @observable isProcessing: boolean = false
  @serializable @observable isDeleted: boolean = false
  public isOnServer: boolean = false

  markIsNotOnServer() {
    this.isOnServer = false
  }

  markIsOnServer() {
    this.isOnServer = true
  }

  @action
  markIsNotProcessing() {
    this.isProcessing = false
  }

  markIsProcessing() {
    this.isProcessing = true
  }

  public serialize(): ILocationDTO {
    return serialize(this)
  }
}
