import * as React from 'react'
import './OptionsList.scss'
import { inject, observer } from 'mobx-react'
import { Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { SortableElement, SortableContainer } from 'react-sortable-hoc'
import { QuizVM } from '../../view-models/QuizVM'
import OptionRow from './OptionRow'

const SortableItem: any = SortableElement(({ value }) => (
  <div style={{ zIndex: 99999999 }} className='option-draggable-item'>
    {value}
  </div>
))

const SortableList: any = SortableContainer(({ items }) => {
  return (
    <div>
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} value={value} />
      ))}
    </div>
  )
})

interface Props {
  quiz: QuizVM
}

const OptionsList = ({ quiz }: Props) => {
  const options = quiz.currentQuestion.options.map((e, idx) => (
    <OptionRow option={e} idx={idx} key={'opt' + idx} quiz={quiz} />
  ))

  const onSortEnd = ({ oldIndex, newIndex }, e) => {
    if (quiz.isDisabled) return
    if (oldIndex === newIndex) return
    quiz.currentQuestion.moveOption(oldIndex, newIndex)
  }

  return (
    <div id='OptionsList'>
      <SortableList distance={3} items={options} onSortEnd={onSortEnd} useDragHandle={true} />
      {quiz.currentQuestion.canAddMoreOptions ? (
        <Button
          disabled={quiz.isDisabled}
          className={
            quiz.currentQuestion.options.length === 0 && quiz.currentQuestion.optionsError
              ? 'form-button error'
              : 'form-button'
          }
          fullWidth
          onClick={() => quiz.currentQuestion.addOption()}
        >
          <AddIcon />
        </Button>
      ) : null}
    </div>
  )
}

export default inject('surveysStore')(observer(OptionsList))
