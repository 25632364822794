import React from 'react'
import { inject, observer, useLocalStore } from 'mobx-react'
import { PulseCategoriesManageVM } from '../view-models/PulseCategoriesManageVM'
import rootStore from '../../stores/RootStore'
import { GrandParentCategoryRowVM } from '../view-models/GrandParentCategoryRowVM'
import { Grid, Typography } from '@material-ui/core'
import './PulseCategoriesTree.scss'
import GrandParentCategoryRow from './GrandParentCategoryRow'
import PulseCategoryEditModal from './PulseCategoryEditModal'

interface Props {
  vm: PulseCategoriesManageVM
}

const PulseCategoriesTree: React.FC<Props> = ({ vm }) => {
  if (!vm) return null

  const renderGrandCategoryRows = () => {
    const rows = []
    vm.grandParentCategoryRowsToShow.forEach((row: GrandParentCategoryRowVM, i) => {
      rows.push(
        <GrandParentCategoryRow key={i} vm={row} />
      )
    })
    return rows
  }


  return (
    <div id={'PulseCategoryTree'}>
      <PulseCategoryEditModal />
      {renderGrandCategoryRows()}
    </div>
  )
}

export default inject()(observer(PulseCategoriesTree))
