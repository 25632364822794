import React from 'react'
import clsx from 'clsx'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { UserTasksStore } from '../../store/UserTasksStore'
import { ButtonBase } from '@material-ui/core'

interface WidgetTitleProps {
  localizationStore?: LocalizationStore
  userTasksStore?: UserTasksStore
}

const WidgetTitle: React.FC<WidgetTitleProps> = ({ localizationStore, userTasksStore }) => {
  const { lzStrings } = localizationStore
  const { pendingUserTasksWidgetVM: VM } = userTasksStore

  if (!VM) return

  const handleTimelineClick = () => {
    VM.setTabIndex(0)
  }

  const handleListClick = () => {
    VM.setTabIndex(1)
  }

  return (
    <div className='widget-title-tabs'>
      <span>{lzStrings.pendingUserTasksWidget.pending_tasks}</span>
      <div className='tab-btns'>
        <ButtonBase
          onClick={handleTimelineClick}
          className={clsx('tab-button', {
            active: VM.tabIndex === 0,
          })}
        >
          {lzStrings.pendingUserTasksWidget.timeline}
        </ButtonBase>
        <ButtonBase
          onClick={handleListClick}
          className={clsx('tab-button', {
            active: VM.tabIndex === 1,
          })}
        >
          {lzStrings.pendingUserTasksWidget.list}
        </ButtonBase>
      </div>
    </div>
  )
}

export default inject('localizationStore', 'userTasksStore')(observer(WidgetTitle))
