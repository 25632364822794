import { DataStore } from '../../../shared/data/DataStore'
import { RootStore } from '../../../stores/RootStore'
import { Survey } from '../../aggregate/Survey'
import { ISurveyDTO } from '../../dtos/ISurveyDTO'
import { ISurveysFindRequest } from '../../interfaces/ISurveysFindRequest'
import { SurveysService } from '../../service/SurveysService'

export class SurveysFindDataStore extends DataStore<Survey, ISurveyDTO> {
  private request: ISurveysFindRequest

  constructor(rootStore: RootStore, request: ISurveysFindRequest) {
    super(rootStore, Survey, 'surveys', [
      'objectId',
      'name',
      'visibility',
      'visibleTo',
      'isClosed',
      'published',
      'publishedByUserId',
      'fk_publishedByUserId',
      'organizationId',
      'surveyTypeId',
      'fk_surveyTypeId',
      'userTaskId',
      'publishedDateTime',
      'closedDate',
      'participants',
      'isTemplate',
      'mainCategoryId',
      'isDeleted',
      'numberOfParticipants',
      'uniqueUserResponses',
      'surveyTagIds',
      'isWelcomeEmail',
    ])
    this.request = request
    this.paged = true
    this.recordsPerPage = 100
    this.sortColumnName = 'fk_publishedByUserId.name'
  }

  public setRequest(request: ISurveysFindRequest) {
    this.request = request
  }

  public getRequest(): ISurveysFindRequest {
    return this.request
  }

  protected getListRecordsApiCall() {
    return async () => {
      const req: ISurveysFindRequest = {
        ...this.request,
        userId: this.rootStore.appStore.currentUserId,
        organizationId: this.rootStore.appStore.currentOrgId,
        terms: this.filter,
        skip: this.recordsPerPage * (this.pageNumber - 1),
        limit: this.recordsPerPage,
        sortColumnName: this.sortColumnName,
        sortDirection: this.sortDirection,
        listColumns: this.listColumns,
      }
      const svc = new SurveysService(this.rootStore)
      const result = await svc.getSurveysForUser(req)
      this.totalRecords = result.count
      return result.surveys
    }
  }

  public get surveys() {
    return this.records
  }
}
