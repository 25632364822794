import { RootStore } from '../../stores/RootStore'
import { observable, computed, action } from 'mobx'
import { CalendarVM } from './CalendarVM'
import { TrainingItem } from '../aggregate/TrainingItem'
import moment from 'moment'

export class TrainingItemPopupVM {
  private rootStore: RootStore
  private calendarVM: CalendarVM

  constructor(
    rootStore: RootStore,
    calendarVM: CalendarVM,
    trainingItem: TrainingItem,
    parentPlanObjectId
  ) {
    this.rootStore = rootStore
    this.trainingItem = trainingItem
    this.parentPlanObjectId = parentPlanObjectId
    this.calendarVM = calendarVM
    setTimeout(
      () => (this.anchorEl = document.getElementsByClassName('selectedTrainingItem')[0]),
      20
    )
  }

  @observable private trainingItem: TrainingItem = null
  @observable public anchorEl: any = null
  @observable public parentPlanObjectId: string = null

  @computed
  public get title(): string {
    return this.trainingItem.name
  }

  @computed
  public get id(): string {
    return this.trainingItem.id
  }

  @computed
  public get dueDate(): string {
    const s = this.rootStore.localizationStore.lzStrings.surveys
    if (this.trainingItem.dueDate === null) return null
    return moment(this.trainingItem.dueDate).format(s.due_date_format)
  }

  @computed
  public get trainingItemLink(): string {
    const url = `/userTrainingPlans/take/${this.parentPlanObjectId}/foritem/${this.id}`
    return url
  }

  @action
  public navigateToTrainingItem() {
    this.rootStore.appStore.router.push(this.trainingItemLink)
    this.close()
  }

  @action
  public close() {
    this.anchorEl = null
  }
}
