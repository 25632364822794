import React from 'react'
import { observer, inject } from 'mobx-react'
import { CardHeader, Tooltip, IconButton } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { UserGoalsStore } from '../store/UserGoalsStore'
import LocalizationStore from '../../localization/LocalizationStore'
import Searchbar from '../../shared/Searchbar'

interface Props {
  userGoalsStore?: UserGoalsStore
  localizationStore?: LocalizationStore
}

const Header: React.FC<Props> = ({ userGoalsStore, localizationStore }) => {
  const { myGoalsWidgetVM: vm } = userGoalsStore
  if (!vm) return null
  const { my_goals: strings } = localizationStore.lzStrings

  const renderAddButton = () => {
    if (!vm.canCreateGoals) return null
    return (
      <React.Fragment>
        <Tooltip title={strings.add_new_tooltip} placement='bottom'>
          <IconButton className='widget-cta' size='small' onClick={() => vm.createNewGoal()}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    )
  }

  return (
    <CardHeader
      id='Header'
      title={strings.my_goals}
      action={
        <>
          <div className='search'>
            <Searchbar
              focused={vm.keywordFilter.length > 0}
              searchValue={vm.keywordFilter}
              onSearchChange={(e) => vm.setKeywordFilter(e.target.value)}
            />
          </div>
          {/* {renderAddButton()} */}
        </>
      }
    />
  )
}

export default inject('userGoalsStore', 'localizationStore')(observer(Header))
