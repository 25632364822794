import React from 'react'
import RoomIcon from '@material-ui/icons/Room'

interface IMarker {
  text: string
  lat: number
  lng: number
  $hover?: boolean
}

const hoveredStyles = {
  color: 'red',
}

const regularStyles = {
  color: 'black',
}

const Marker: React.FC<IMarker> = ({ $hover }) => {
  const styles = $hover ? hoveredStyles : regularStyles

  return (
    <div style={styles}>
      <RoomIcon />
    </div>
  )
}

export default Marker
