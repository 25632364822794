import React, { FC } from 'react'
import { inject, observer } from 'mobx-react'
import { CardContent, CardHeader, IconButton, Typography } from '@material-ui/core'
import { AgGridReact } from 'ag-grid-react'
import FilterListIcon from '@material-ui/icons/FilterList'
import WidgetTitle from './WidgetTitle'
import Searchbar from '../../../../shared/Searchbar'
import AGGridWidgetPagingRow from '../../../../shared/ag-grid/AGGridPagingRow'
import './WidgetHeader.scss'
import { CatalogItemsTabVM } from '../../../view-models/widgets/item-catalog-widget/CatalogItemsTabVM'
import ActionsCell from './ActionsCell'

interface Props {
  vm: CatalogItemsTabVM
}

const CatalogItemsTab: FC<Props> = ({ vm }) => {
  if (!vm) return

  const renderGrid = () => {
    if (!vm.shouldRenderGrid) return
    return (
      <div
        className={vm.hasRows ? 'ag-theme-alpine' : 'ag-theme-alpine ag-grid-no-rows'}
        style={{
          height: '100%',
          width: '100%',
          display: vm.shown ? undefined : 'none',
        }}
      >
        <AgGridReact
          gridOptions={{
            ...vm.getGridOptions(),
            frameworkComponents: {
              actionsCell: ActionsCell,
            },
          }}
        />
      </div>
    )
  }

  const renderHeader = () => {
    if (!vm.shown) return
    return (
      <CardHeader
        title={<WidgetTitle vm={vm.widgetVM} />}
        action={
          <>
            <IconButton
              size='small'
              onClick={(e) => {
                vm.widgetVM.setFilterPopupAnchorEl(e.currentTarget)
              }}
              className={vm.widgetVM.hasPopupFilter ? 'filter-is-active' : ''}
            >
              <FilterListIcon />
            </IconButton>
            <div id='ManageSurveysSearch'>
              <Searchbar
                focused={vm.typedFilterText.length > 0}
                className='search'
                searchValue={vm.typedFilterText}
                onSearchChange={(e) => vm.setQuickFilter(e.target.value)}
              />
            </div>
          </>
        }
      />
    )
  }

  const renderPagingRow = () => {
    if (!vm.shown) return
    return <AGGridWidgetPagingRow vm={vm} />
  }

  const renderNoRowsContent = () => {
    if (vm.hasRows) return
    return (
      <Typography component='div' className='no-rows'>
        {vm.noRowsToShowLabel}
      </Typography>
    )
  }

  return (
    <>
      {renderHeader()}
      <CardContent
        style={{
          display: vm.shown ? undefined : 'none',
        }}
      >
        {renderGrid()}
        {renderNoRowsContent()}
        {renderPagingRow()}
      </CardContent>
    </>
  )
}

export default inject('localizationStore')(observer(CatalogItemsTab))
