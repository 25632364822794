import React from 'react'
import { inject, observer } from 'mobx-react'
import { Card, Fade, Popover } from '@material-ui/core'
import UserEventPopupToolbar from './UserEventPopupToolbar'
import UserEventPopupBody from './UserEventPopupBody'
import './UserEventPopup.scss'
import { EventsStore } from '../../../store/EventsStore'
import UserEventExpansionPanel from './UserEventGoingPanel'
import MasterEventPrompt from './MasterEventPrompt'
import SeriesGoingPrompt from './SeriesGoingPrompt'
import EventSendNotificationsPrompt from '../../EventSendNotificationsPrompt'

interface IUserEventPopup {
  eventsStore?: EventsStore
}

const UserEventPopup: React.FC<IUserEventPopup> = ({ eventsStore }) => {
  const { userEventPopupVM: vm } = eventsStore
  if (!vm) return null
  if (!vm.anchorEl) return null

  return (
    <>
      <Popover
        open={true}
        anchorEl={vm.anchorEl}
        onClose={() => vm.close()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className='calendarEventModal'
      >
        <Card elevation={5} className='userEventPopupCard'>
          <UserEventPopupToolbar />
          <UserEventPopupBody />
          <UserEventExpansionPanel />
        </Card>
      </Popover>
      <MasterEventPrompt />
      <SeriesGoingPrompt />
      <EventSendNotificationsPrompt vm={vm.eventSendNotificationsVM} />
    </>
  )
}

export default inject('eventsStore')(observer(UserEventPopup))
