import { action, computed, observable } from 'mobx'
import moment from 'moment'
import { LabelsStore } from '../../../labels/store/LabelsStore'
import { AGGridRowVM } from '../../../shared/ag-grid/AGGridRowVM'
import { RootStore } from '../../../stores/RootStore'
import { OrganizationUser } from '../../aggregate/OrganizationUser'
import { OrganizationUsersListTableVM } from './OrganizationUsersListTableVM'

export class OrganizationUsersListRowVM extends AGGridRowVM<OrganizationUser> {
  constructor(
    rootStore: RootStore,
    orgUser: OrganizationUser,
    listVM: OrganizationUsersListTableVM
  ) {
    super(rootStore, orgUser, listVM)
    this.labelsStore = this.rootStore.labelsStore
    this.listVM = listVM
    this.loadData()
  }

  @observable public labelsStore: LabelsStore = null
  @observable public listVM: OrganizationUsersListTableVM = null
  @observable public popoverOpen: boolean = false
  @observable public anchorEl: HTMLElement | null = null
  @observable public email: string = '--'
  @observable public fullName: string = '--'
  @observable public username: string = '--'
  @observable public firstName: string = '--'
  @observable public lastName: string = '--'
  @observable public title: string = '--'
  @observable public phone: string = '--'
  @observable public jobNumber: string = '--'
  @observable public employeeId: string = '--'
  @observable public languagePreference: string = '--'
  @observable public receiveEmails: string = '--'
  @observable public receivePushNotifications: string = '--'
  @observable public receiveTextMessages: string = '--'
  @observable public birthDate: string = ''
  @observable public roleStartDate: string = ''
  @observable public birthDateChanged: boolean = false
  @observable public roleStartDateChanged: boolean = false
  @observable public isEdited: boolean = false

  @action
  public loadData() {
    if (this.aggregate.fk_User && this.aggregate.fk_User.email)
      this.email = this.aggregate.fk_User.email
    if (
      this.aggregate.fk_User &&
      this.aggregate.fk_User.firstName &&
      this.aggregate.fk_User.lastName
    )
      this.fullName = `${this.aggregate.fk_User.lastName}, ${this.aggregate.fk_User.firstName}`
    if (this.aggregate.fk_User && this.aggregate.fk_User.username)
      this.username = this.aggregate.fk_User.username
    if (this.aggregate.fk_User && this.aggregate.fk_User.firstName)
      this.firstName = this.aggregate.fk_User.firstName
    if (this.aggregate.fk_User && this.aggregate.fk_User.lastName)
      this.lastName = this.aggregate.fk_User.lastName
    if (this.aggregate.title) this.title = this.aggregate.title
    if (this.aggregate.fk_User && this.aggregate.fk_User.phone)
      this.phone = this.aggregate.fk_User.phone.toString()
    if (this.aggregate.jobNumber) this.jobNumber = this.aggregate.jobNumber
    if (this.aggregate.employeeId) this.employeeId = this.aggregate.employeeId
    if (this.aggregate.fk_User && this.aggregate.fk_User.languagePreference)
      this.languagePreference = this.aggregate.fk_User.languagePreference
    if (this.aggregate.fk_User && this.aggregate.fk_User.receiveEmails)
      this.receiveEmails = this.aggregate.fk_User.receiveEmails ? 'Yes' : 'No'
    if (this.aggregate.fk_User && this.aggregate.fk_User.receivePushNotifications)
      this.receivePushNotifications = this.aggregate.fk_User.receivePushNotifications ? 'Yes' : 'No'
    if (this.aggregate.fk_User && this.aggregate.fk_User.receivetextNotifications)
      this.receiveTextMessages = this.aggregate.fk_User.receivetextNotifications ? 'Yes' : 'No'
    if (this.aggregate.fk_User && this.aggregate.fk_User.birthDate)
      this.birthDate = this.aggregate.fk_User.birthDate
    if (this.aggregate.roleStartDate) this.roleStartDate = this.aggregate.roleStartDate
  }

  @computed
  public get objectId(): string {
    return this.aggregate.userId
  }

  @computed
  public get orgUserId(): string {
    return this.aggregate.objectId
  }

  @computed
  public get roles(): string {
    if (!this.aggregate.fk_roles || this.aggregate.fk_roles.length === 0) return '--'
    let roles = ''
    this.aggregate.fk_roles.forEach((e, idx) => {
      if (idx === 0) {
        roles = roles + `${e.name}`
      } else {
        roles = roles + `, ${e.name}`
      }
    })
    return roles
  }

  @computed
  public get groups(): string {
    if (!this.aggregate.fk_groups || this.aggregate.fk_groups.length === 0) return '--'
    let groups = ''
    this.aggregate.fk_groups.forEach((e, idx) => {
      if (idx === 0) {
        groups = groups + `${e.name}`
      } else {
        groups = groups + `, ${e.name}`
      }
    })
    return groups
  }

  @computed
  public get privilegeSets(): string {
    if (!this.aggregate.fk_privilegeSets || this.aggregate.fk_privilegeSets.length === 0)
      return '--'
    let privilegeSets = ''
    this.aggregate.fk_privilegeSets.forEach((e, idx) => {
      if (idx === 0) {
        privilegeSets = privilegeSets + `${e.name}`
      } else {
        privilegeSets = privilegeSets + `, ${e.name}`
      }
    })
    return privilegeSets
  }

  @action
  getPrivilegeString(e) {
    if (e === 'can_survey') return this.labelsStore.getLabelByLanguageAndFor('Pulse')
    if (e === 'can_schedule') return 'Schedule'
    if (e === 'can_assign_work') return 'Assign Work'
    if (e === 'can_manage_categories') return 'Manage Categories'
    if (e === 'can_access_worksheets') return 'Access Worksheets'
    if (e === 'can_modify_survey_visibility')
      return `Modify ${this.labelsStore.getLabelByLanguageAndFor('Pulse')} Visability`
    if (e === 'can_survey_clients') return 'Survey Clients'
    if (e === 'can_modify_survey_owner')
      return `Modify ${this.labelsStore.getLabelByLanguageAndFor('Pulse')} Owner`
    if (e === 'can_impersonate_users') return 'Impersonate Users'
    if (e === 'can_create_impact_items') return 'Create Impact Items'
    if (e === 'can_edit_event_type') return 'Edit Event Type'
    if (e === 'can_edit_event_resource') return 'Edit Event Resource'
    if (e === 'can_create_trainings') return 'Create Trainings'
    if (e === 'can_create_goals') return 'Create Goals'
    if (e === 'can_manage_custom_collections') return 'Manage Custom Collections'
    if (e === 'can_chat') return 'Can Chat'
    if (e === 'can_create_announcements') return 'Create Announcements'
    if (e === 'can_edit_all_trainings') return 'Edit All Trainings'
    if (e === 'can_manage_campaigns') return 'Manage Pulse Campaigns'
    if (e === 'can_access_scoped_analysis') return 'Access Scoped Analysis'
    if (e === 'can_create_chat') return 'Create Chat'
    if (e === 'can_manage_users') return 'Manage Users'
    if (e === 'can_manage_training_plan_flows') return 'Manage Training Plan Flows'
    if (e === 'can_start_training_plan_flows') return 'Start Training Plan Flows'
    if (e === 'can_view_pulse_invite_history')
      return `View ${this.labelsStore.getLabelByLanguageAndFor('Pulse')}  Invite History`
  }

  @computed
  public get privileges(): string {
    if (!this.aggregate.resolvedPrivileges || this.aggregate.resolvedPrivileges.length === 0)
      return '--'
    let privileges = ''
    this.aggregate.resolvedPrivileges.forEach((e, idx) => {
      if (idx === 0) {
        const text = this.getPrivilegeString(e)
        privileges = privileges + `${text}`
      } else {
        const text = this.getPrivilegeString(e)
        privileges = privileges + `, ${text}`
      }
    })
    return privileges
  }

  @computed
  public get primaryRole(): string {
    if (this.aggregate.primaryRoleId) {
      const roleId = this.aggregate.primaryRoleId
      const foundRole = this.rootStore.rolesStore.roles.find((role) => role.objectId === roleId)
      if (foundRole) return foundRole.name
      else return '--'
    } else return '--'
  }

  @computed
  public get primaryGroup(): string {
    if (this.aggregate.primaryGroupId) {
      const groupId = this.aggregate.primaryGroupId
      const foundGroup = this.rootStore.groupsStore.groups.find(
        (group) => group.objectId === groupId
      )
      if (foundGroup) return foundGroup.name
      else return '--'
    } else return '--'
  }

  @action
  public setRoleStartDate(date) {
    this.roleStartDate = date
    this.listVM.addToEditedRowEventsArray(this.aggregate.userId, 'roleStartDate', date)
    this.roleStartDateChanged = true
  }

  @action
  public setBirthDate(date) {
    this.birthDate = date
    this.listVM.addToEditedRowEventsArray(this.aggregate.userId, 'birthDate', date)
    this.birthDateChanged = true
  }

  @computed
  public get hasConsented(): any {
    if (this.aggregate.hasConsented) return 'True'
    else return '--'
  }

  @computed
  public get welcomeEmailStatus(): string {
    if (this.aggregate.welcomeEmailStatus) return this.aggregate.welcomeEmailStatus
    return '--'
  }

  @computed
  public get passwordResetStatus(): string {
    if (this.aggregate.resetEmailStatus) return this.aggregate.resetEmailStatus
    return '--'
  }

  @computed
  public get lastLogin(): string {
    return '--'
  }

  @computed
  public get createdAt(): string {
    if (this.aggregate.createdAt)
      return moment(this.aggregate.createdAt).format('M/DD/YYYY hh:mm A')
    return '--'
  }

  @computed
  public get updatedAt(): string {
    if (this.aggregate.updatedAt)
      return moment(this.aggregate.updatedAt).format('M/DD/YYYY hh:mm A')
    return '--'
  }

  @action
  public setAnchorEl(anchorEl: HTMLElement | null) {
    this.anchorEl = anchorEl
  }

  public toDTO() {
    return {
      userId: this.objectId,
      email: this.email,
      firstName: this.firstName,
      lastName: this.lastName,
      type: this.objectId ? 'user' : 'non-user',
    }
  }

  public click() {
    this.rootStore.organizationUsersStore.loadEditVM(this.orgUserId)
    this.listVM.setHighlightedRow(this.aggregate.userId)
  }

  @computed
  public get archived(): boolean {
    return this.aggregate.isArchived
  }
}
