import React from 'react'
import { inject, observer } from 'mobx-react'
import { ResourcesStore } from '../../../../resources/store/ResourcesStore'
import LocalizationStore from '../../../../localization/LocalizationStore'
import {
  CardContent,
  Drawer,
  Grid,
  Button,
  InputLabel,
  TextField,
  Divider,
} from '@material-ui/core'
import MuiDrawerToolbar from '../../../../shared/Mui-Drawer/MuiDrawerToolbar'
import './ResourcesDrawer.scss'
import MuiDrawerHeader from '../../../../shared/Mui-Drawer/MuiDrawerHeader'

interface Props {
  resourcesStore?: ResourcesStore
  localizationStore?: LocalizationStore
}

const ResourcesDrawer: React.FC<Props> = ({ resourcesStore, localizationStore }) => {
  const { editForm } = resourcesStore
  const { lzStrings } = localizationStore

  const handleSubmitClick = () => {
    editForm.save()
    resourcesStore.selectedResourceId = ''
  }

  const toggleDrawer = () => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    resourcesStore.showDrawer = !resourcesStore.showDrawer
    if (resourcesStore.showDrawer === false) resourcesStore.selectedResourceId = ''
  }

  return (
    <div id='ResourcesDrawer'>
      {editForm && (
        <Drawer
          variant='temporary'
          anchor='right'
          open={resourcesStore.showDrawer}
          onClose={toggleDrawer()}
        >
          <MuiDrawerHeader
            title={
              editForm.objectId
                ? lzStrings.resourcesEdit.manage_resource
                : lzStrings.resourcesEdit.add_resource
            }
            toggleDrawer={toggleDrawer()}
          />
          <MuiDrawerToolbar
            handleCancelClick={toggleDrawer()}
            handleSubmitClick={() => handleSubmitClick()}
            showReset={Boolean(editForm.objectId)}
            onConfirmReset={() => resourcesStore.setSelectedResourceId(editForm.objectId)}
            moreMenuProps={{
              isEditMode: editForm.isEditMode,
              itemType: localizationStore.lzStrings.moreMenu.obj_type.resource,
              itemName: editForm.name,
              onConfirmDelete: () => editForm.delete(),
            }}
          />
          <CardContent id='resourcesBasicInfo'>
            <h6>Basic Info</h6>
            <form autoComplete='off' noValidate>
              <Grid container>
                <Grid item md={12} sm={12}>
                  <InputLabel required htmlFor='name'>
                    Name
                  </InputLabel>
                  <TextField
                    error={!editForm.nameValid || !editForm.nameUnique}
                    value={editForm.name}
                    onChange={(e) => (editForm.name = e.target.value)}
                    fullWidth
                    helperText={editForm.errorHelperText}
                    variant='outlined'
                    id='name'
                  />
                </Grid>
              </Grid>
              <Grid container className='descriptionContainer'>
                <Grid item md={12} sm={12}>
                  <InputLabel className='input-label' htmlFor='description'>
                    Description
                  </InputLabel>
                  <TextField
                    multiline
                    minRows={5}
                    margin='none'
                    value={editForm.description}
                    onChange={(e) => (editForm.description = e.target.value)}
                    fullWidth
                    variant='outlined'
                    id='description'
                  />
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Drawer>
      )}
    </div>
  )
}

export default inject('resourcesStore', 'localizationStore')(observer(ResourcesDrawer))
