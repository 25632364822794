import React from 'react'
import { inject, observer } from 'mobx-react'
import rootStore from '../../stores/RootStore'
import { SurveyResultsStore } from '../stores/SurveyResultsStore'
import { Grid, Button, Card, CardHeader, Icon } from '@material-ui/core'
import QuestionView from './QuestionView'
import NavButtons from './NavButtons'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ViewListRoundedIcon from '@material-ui/icons/ViewListRounded'
import ViewArrayRoundedIcon from '@material-ui/icons/ViewArrayRounded'
interface Props {
  surveyResultsStore?: SurveyResultsStore
}

const SurveyResultsContent: React.FC<Props> = ({ surveyResultsStore }) => {
  const lz = rootStore.localizationStore.lzStrings
  const { resultsVM } = surveyResultsStore

  const renderCurrentQuestion = () => {
    if (!resultsVM) return null
    if (resultsVM.showSummaryPage) return null
    return <Grid><QuestionView resultsVM={resultsVM} question={resultsVM.currentQuestion} /></Grid>
  }

  const renderNavButtons = () => {
    if (!resultsVM) return null
    if (resultsVM.showSummaryPage) return null
    return <NavButtons resultsVM={resultsVM} />
  }

  const renderSummaryView = () => {
    if (!resultsVM) return null
    if (!resultsVM.showSummaryPage) return null
    const questions = resultsVM.questions.map((q, i) => (
      <QuestionView resultsVM={resultsVM} question={q} key={i} />
    ))
    return <Grid>{questions}</Grid>
  }

  const renderTitle = () => {
    if (resultsVM && resultsVM.name) return resultsVM.name
  }

  const renderButtons = () => {
    return (
      <div className='back-button-container'>
        {renderBackToSurveysButton()}
        <div className='results-toggle'>
          <ToggleButtonGroup
            value={resultsVM.showSummaryPage}
            exclusive
            size='small'
            onChange={(e) => resultsVM.toggleSummaryPage()}
          >
            <ToggleButton value={true}>
              <ViewListRoundedIcon className='icon' />
              Scroll
            </ToggleButton>
            <ToggleButton value={false}>
              <ViewArrayRoundedIcon className='icon' />
              Paged
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
    )
  }

  const renderBackToSurveysButton = () => {
    if (window.location.href.toLowerCase().includes('public')) return null
    return (
      <Button
        className='back-to-surveys'
        variant='text'
        onClick={() => resultsVM.handleBackToSurveys()}
      >
        Back To Surveys
      </Button>
    )
  }

  return (
    <>
      <Card id='UserSurveyTakeWidget' elevation={1} key='temkey'>
        <CardHeader id='WidgetHeader' title={renderTitle()} />
        <Grid id='SurveyResults'>
          <Grid className='results-container'>
            {renderButtons()}
            <Grid className='content-container'>
              {renderCurrentQuestion()}
              {renderSummaryView()}
              {renderNavButtons()}
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  )
}

export default inject('surveyResultsStore')(observer(SurveyResultsContent))
