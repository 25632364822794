import React from 'react'
import '../ContactListWidget.scss'
import { observer, inject } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { CardHeader, IconButton, Tooltip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { ContactsStore } from '../../store/ContactsStore'
import Searchbar from '../../../shared/Searchbar'
import { LabelsStore } from 'src/app/labels/store/LabelsStore'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import ContactsImportModal from '../../views/ContactsImportModal'

interface WidgetHeaderProps {
  localizationStore?: LocalizationStore
  contactsStore?: ContactsStore
  labelsStore?: LabelsStore
}

const WidgetHeader: React.FC<WidgetHeaderProps> = ({
  localizationStore,
  contactsStore,
  labelsStore,
}) => {
  const vm = contactsStore.contactListWidgetVM
  const s = localizationStore.lzStrings
  if (!vm) return
  const title = labelsStore.getLabelByLanguageAndFor('manage contacts')
  return (
    <CardHeader
      id='WidgetHeader'
      className='widgetHeader'
      title={title}
      action={
        <>
          <div id='ContactListSearch' className='action-btn'>
            <Searchbar
              focused={vm.typedFilterText.length > 0}
              className='search'
              searchValue={vm.typedFilterText}
              onSearchChange={(e) => vm.setQuickFilter(e.target.value)}
            />
          </div>
          <div id='OrgUsersImport' className='action-btn'>
            <React.Fragment>
              <Tooltip
                title={s.contact_import.import_contacts}
                placement='bottom'
              >
                <IconButton size='small' onClick={() => vm.toggleImportModal()}>
                  <ImportExportIcon />
                </IconButton>
              </Tooltip>
            </React.Fragment>
          </div>
          <div id='ContactListAdd' className='action-btn'>
            <React.Fragment>
              <Tooltip title={s.contactListWidget.add_new_contact} placement='bottom'>
                <IconButton
                  className='widget-cta'
                  size='small'
                  onClick={() => contactsStore.addNewContact()}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </React.Fragment>
          </div>
          <ContactsImportModal />
        </>
      }
    ></CardHeader>
  )
}

export default inject('localizationStore', 'contactsStore', 'labelsStore')(observer(WidgetHeader))
