import React, { FC } from 'react'
import { inject, observer } from 'mobx-react'
import { CardContent, CardHeader, IconButton, Tooltip, Typography } from '@material-ui/core'
import { AgGridReact } from 'ag-grid-react'
import AGGridUserCell from '../../../shared/ag-grid/AGGridUserCell'
import WidgetTitle from './WidgetTitle'
import Searchbar from '../../../shared/Searchbar'
import AGGridWidgetPagingRow from '../../../shared/ag-grid/AGGridPagingRow'
import BarChartIcon from '@material-ui/icons/BarChart'
import ArrowRightIcon from '@material-ui/icons/ArrowRightRounded'
import { CompletedSurveyResponseRowVM } from '../../view-models/my-surveys-widget/CompletedSurveyResponseRowVM'
import { CompletedSurveyResponsesTabVM } from '../../view-models/my-surveys-widget/CompletedSurveyResponsesTabVM'
import './CompletedSurveyResponsesTab.scss'

interface Props {
  vm: CompletedSurveyResponsesTabVM
}

const CompletedSurveyResponsesTab: FC<Props> = ({ vm }) => {
  if (!vm) return null
  const renderOwnerCell = (props) => {
    const row: CompletedSurveyResponseRowVM = props.data
    if (!row) return null
    return <AGGridUserCell userId={row.fromUserId} name={row.fromUserName} iconURL={row.fromUserIconURL} />
  }

  const renderActionsCell = (props) => {
    const row: CompletedSurveyResponseRowVM = props.data
    if (!row) return <div></div>
    return renderResponseOrResults(row)
  }

  const renderResponseOrResults = (row: CompletedSurveyResponseRowVM) => {
    return (
      <div className='my-surveys-widget-actions-cell'>
        <Tooltip
          title={renderTooltipTitle(row)}
          placement='bottom-start'
          enterDelay={500}
        >
          <IconButton style={{ padding: '3px' }} onClick={() => row.openResultsOrResponse()}>
            <BarChartIcon id='view-response' />
          </IconButton>
        </Tooltip>
      </div>
    )
  }

  const renderTooltipTitle = (row: CompletedSurveyResponseRowVM) => {
    if (!row.showResults) return 'View Response'
    return 'View Results'
  }

  const renderGrid = () => {
    if (!vm.shouldRenderGrid) return
    return (
      <div
        className={vm.hasRows ? 'ag-theme-alpine' : 'ag-theme-alpine ag-grid-no-rows'}
        style={{ height: '100%', width: '100%', display: vm.shown ? undefined : 'none' }}
      >
        <AgGridReact
          gridOptions={{
            ...vm.getGridOptions(),
            frameworkComponents: {
              fromCell: renderOwnerCell,
              actionsCell: renderActionsCell,
            },
          }}
        />
      </div>
    )
  }

  const renderPagingRow = () => {
    if (!vm.shown) return
    return <AGGridWidgetPagingRow vm={vm} />
  }

  const renderHeader = () => {
    if (!vm.shown) return
    return (
      <CardHeader
        title={<WidgetTitle vm={vm.widgetVM} />}
      // action={
      //   <Searchbar
      //     focused={vm.typedFilterText.length > 0}
      //     className='search'
      //     searchValue={vm.typedFilterText}
      //     onSearchChange={(e) => vm.setQuickFilter(e.target.value)}
      //   />
      // }
      />
    )
  }

  const renderNoRowsContent = () => {
    if (vm.hasRows) return
    return (
      <Typography component='div' className='no-rows'>
        {vm.noRowsToShowLabel}
      </Typography>
    )
  }

  return (
    <>
      {renderHeader()}
      <CardContent id={'CompletedSurveyResponsesTab'} style={{ display: vm.shown ? undefined : 'none' }}>
        {renderGrid()}
        {renderNoRowsContent()}
        {renderPagingRow()}
      </CardContent>
    </>
  )
}

export default inject()(observer(CompletedSurveyResponsesTab))
