import React from 'react'
import { inject, observer } from 'mobx-react'
import TasksIcon from '@material-ui/icons/AssignmentOutlined'
import SurveysIcon from '@material-ui/icons/LiveHelpOutlined'
import EventIcon from '@material-ui/icons/Event'
import { EventsStore } from '../../store/EventsStore'
import { TextField, Checkbox, Grid } from '@material-ui/core'
import { ParticipantVM } from '../../../participants-select/view-models/ParticipantVM'
import Autocomplete from '@material-ui/lab/Autocomplete'

interface Props {
  eventsStore?: EventsStore
}

const CalendarEventDrawerResources: React.FC<Props> = ({ eventsStore }) => {
  const { calendarVM: vm } = eventsStore

  return (
    <>
      <Autocomplete
        className='other-calendar-input'
        value={vm.selectedOtherCalendars.slice()}
        multiple
        filterSelectedOptions
        options={vm.availableOtherCalendars.slice()}
        getOptionLabel={(e) => e.displayNameWithType}
        onChange={(e, arr: ParticipantVM[]) => vm.setSelectedOtherCalendars(arr)}
        renderInput={(params) => (
          <TextField
            placeholder={vm.selectedOtherCalendars.length < 1 ? 'Other Calendars...' : ''}
            {...params}
            variant='outlined'
            fullWidth
            margin='none'
          />
        )}
      />
    </>
  )
}

export default inject('eventsStore')(observer(CalendarEventDrawerResources))
