import React from 'react'
import { Card } from '@material-ui/core'
import { observer, inject } from 'mobx-react'
import '../catalog-plans-widget/CatalogWidget.scss'
import { TrainingPlansCatalogStore } from '../../../store/TrainingPlansCatalogStore'
import FilterPopup from './FilterPopup'
import CatalogItemsTab from './CatalogItemsTab'

interface Props {
  trainingPlansCatalogStore?: TrainingPlansCatalogStore
}

const CatalogItemsWidget: React.FC<Props> = ({ trainingPlansCatalogStore }) => {
  const { catalogItemsWidgetVM: vm } = trainingPlansCatalogStore
  if (!vm) return null
  if (!trainingPlansCatalogStore.canCreateTrainingPlans) return null

  return (
    <>
      <Card id='CatalogItemsWidget' elevation={0}>
        <FilterPopup widgetVM={vm} />
        <CatalogItemsTab vm={vm.allCatalogItemsTab} />
      </Card>
    </>
  )
}

export default inject('trainingPlansCatalogStore')(observer(CatalogItemsWidget))
