import React from 'react'
import { inject, observer, useLocalStore } from 'mobx-react'
import LocalizationStore from '../../localization/LocalizationStore'
import './PulseCampaignTypesNew.scss'
import rootStore from '../../stores/RootStore'
import TopToolbarNew from './TopToolbarNew'
import { Checkbox, Grid, TextField, Typography } from '@material-ui/core'
import PulseCampaignTypeEditVM from '../view-models/PulseCampaignTypeEditVM'
import InfoTooltip from '../../shared/InfoTooltip'
import { PulseCampaignTypesStore } from '../store/PulseCampaignTypesStore'

interface PulseCampaignTypesNewProps {
  localizationStore?: LocalizationStore
  pulseCampaignTypesStore?: PulseCampaignTypesStore
}

const PulseCampaignTypesNew: React.FC<PulseCampaignTypesNewProps> = ({
  localizationStore,
  pulseCampaignTypesStore,
}) => {
  const lz = localizationStore.lzStrings.pulseCampaign
  const vm = new PulseCampaignTypeEditVM(rootStore)
  const localStore = useLocalStore(() => ({
    vm: vm,
  }))

  return (
    <div id='PulseCampaignTypesNew'>
      <TopToolbarNew vm={localStore.vm} />
      <div id={'PulseCampaignTypesNewForm'}>
        <Grid className='set-details' item xs={12}>
          <div className='name-input'>
            <Typography className='primary-text'>{lz.name_lower}</Typography>
            <div className='name-container'>
              <TextField
                error={!localStore.vm.nameValid}
                id='outlined-name'
                className={'set-name'}
                value={localStore.vm.name}
                onChange={(e) => localStore.vm.setName(e.target.value)}
                fullWidth
                variant='outlined'
              />
            </div>
          </div>
          <div className='active-checkbox'>
            <div>
              <Checkbox
                checked={localStore.vm.isActive}
                onChange={() => localStore.vm.toggleIsActive()}
              />
            </div>
            <Typography className='primary-text primary-text--horizontal'>
              {lz.is_active}
            </Typography>
            <InfoTooltip message={lz.is_active_tooltip} />
          </div>
        </Grid>
        <Grid item xs={12}>
          {!localStore.vm.isValid && (
            <Typography className='error-text'>{lz.name_error}</Typography>
          )}
        </Grid>
      </div>
    </div>
  )
}

export default inject(
  'pulseCampaignTypesStore',
  'localizationStore'
)(observer(PulseCampaignTypesNew))
