import { observable, action, computed } from 'mobx'
import { SurveyEditVM } from './SurveyEditVM'

export class WeekdayVM {
  private editVM: SurveyEditVM

  constructor(day, editVM: SurveyEditVM) {
    this.day = day
    this.editVM = editVM
  }

  @observable day: string = ''

  @computed
  public get isChecked(): boolean {
    return this.editVM.survey.weekdays[this.day]
  }

  @computed
  public get subString() {
    return this.day.substring(0,3).toUpperCase()
  }

  @action
  public toggleChecked() {
    this.editVM.toggleWeekday(this.day)
  }

}
