import React, { FC } from 'react'
import rootStore from '../../../../stores/RootStore'
import {
  Toolbar,
  Typography,
  ButtonBase,
  Tooltip,
  Button,
  CssBaseline,
  AppBar,
} from '@material-ui/core'
import '../../../../css/dashboard-toolbar.scss'
import './ImpactDashboard.scss'
import { inject, observer } from 'mobx-react'
import { UserDashboardsStore } from '../../UserDashboardsStore'
import EditIcon from '@material-ui/icons/Edit'
import isIE11 from '../../../../../utils/isIE11'
import clsx from 'clsx'
import { LabelsStore } from '../../../../labels/store/LabelsStore'

export interface Props {
  children?: React.ReactElement
  userDashboardsStore?: UserDashboardsStore
  labelsStore?: LabelsStore
}

const TenantAdminToolbar: FC<Props> = ({ userDashboardsStore, labelsStore }) => {
  const vm = userDashboardsStore.viewModels.userDashboardVM

  const renderManageEmailTemplatesButton = () => {
    return (
      <Tooltip
        title={
          rootStore.localizationStore.lzStrings.emailTemplateManagement.manage_email_templates_info
        }
        placement='bottom'
      >
        <span>
          <Button
            variant='outlined'
            className='dashboard-action btn-icon btn-icon-white end-action'
            onClick={() => rootStore.appStore.router.push('/tenantAdmin/manage/emailTemplates')}
          >
            {rootStore.localizationStore.lzStrings.emailTemplateManagement.manage_email_templates}
          </Button>
        </span>
      </Tooltip>
    )
  }

  const renderManageSurveyTemplatesButton = () => {
    return (
      <Tooltip title={'Survey Templates'} placement='bottom'>
        <span>
          <Button
            variant='outlined'
            className='dashboard-action btn-icon btn-icon-white end-action'
            onClick={() => rootStore.appStore.router.push('/tenantAdmin/manage/surveyTemplates')}
          >
            {'Survey Templates'}
          </Button>
        </span>
      </Tooltip>
    )
  }

  const renderManageSurveyTypesButton = () => {
    return (
      <Tooltip title={'Survey Types'} placement='bottom'>
        <span>
          <Button
            variant='outlined'
            className='dashboard-action btn-icon btn-icon-white end-action'
            onClick={() => rootStore.appStore.router.push('/tenantAdmin/manage/surveyTypes')}
          >
            {'Survey Types'}
          </Button>
        </span>
      </Tooltip>
    )
  }

  const renderManageUserOnboardingButton = () => {
    return (
      <Tooltip title='Manage organization users' placement='bottom'>
        <span>
          <Button
            variant='outlined'
            className='dashboard-action btn-icon btn-icon-white btn-action'
            onClick={() => vm.navigateToManageUserOnboarding()}
          >
            Manage Users
          </Button>
        </span>
      </Tooltip>
    )
  }

  const renderManageDashboardButton = () => {
    return (
      <Tooltip
        title={rootStore.localizationStore.lzStrings.dashboard.manage_dashboard_button_tooltip}
        placement='bottom'
      >
        <span>
          <Button
            disabled={Boolean(!vm.userDashboard)}
            className='dashboard-action btn-icon btn-icon-white'
            variant='outlined'
            onClick={() => vm.editDashboard()}
          >
            <EditIcon />{' '}
            {rootStore.localizationStore.lzStrings.dashboard.manage_dashboard_button_text}
          </Button>
        </span>
      </Tooltip>
    )
  }

  return (
    <>
      <div id='DashboardToolbar'>
        <CssBaseline />
        <AppBar
          className={clsx('dashboard-appbar', {
            'no-transition': isIE11,
          })}
          elevation={0}
        >
          <Toolbar className='dashboard-toolbar'>
            <Typography className='dashboard-title'>
              {rootStore.localizationStore.lzStrings.dashboard.tenant_admin}
            </Typography>
            <div className='tab-btns'>
              <Tooltip
                title={rootStore.localizationStore.lzStrings.tenantAdminDashboard.org_tooltip}
                placement='bottom'
              >
                <ButtonBase
                  className={vm.tenantAdminTab === 0 ? 'tab-button active' : 'tab-button'}
                  onClick={() => vm.setSelectedTenantAdminRoute(0)}
                >
                  {rootStore.localizationStore.lzStrings.tenantAdminDashboard.organization}
                </ButtonBase>
              </Tooltip>
              <Tooltip
                title={rootStore.localizationStore.lzStrings.tenantAdminDashboard.config_tooltip}
                placement='bottom'
              >
                <ButtonBase
                  className={vm.tenantAdminTab === 1 ? 'tab-button active' : 'tab-button'}
                  onClick={() => vm.setSelectedTenantAdminRoute(1)}
                >
                  {rootStore.localizationStore.lzStrings.tenantAdminDashboard.configuration}
                </ButtonBase>
              </Tooltip>
              <Tooltip
                title={`${labelsStore.getLabelByLanguageAndFor('pulse')} ${
                  rootStore.localizationStore.lzStrings.tenantAdminDashboard.survey_tooltip
                }`}
                placement='bottom'
              >
                <ButtonBase
                  className={vm.tenantAdminTab === 2 ? 'tab-button active' : 'tab-button'}
                  onClick={() => vm.setSelectedTenantAdminRoute(2)}
                >
                  {`${labelsStore.getLabelByLanguageAndFor('pulse')} ${
                    rootStore.localizationStore.lzStrings.tenantAdminDashboard._settings
                  }`}
                </ButtonBase>
              </Tooltip>
            </div>
            <div className='dashboard-toolbar-right'>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {renderManageUserOnboardingButton()}
                {renderManageDashboardButton()}
                {renderManageEmailTemplatesButton()}
                {renderManageSurveyTemplatesButton()}
                {renderManageSurveyTypesButton()}
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <Toolbar />
      </div>
    </>
  )
}

export default inject('userDashboardsStore', 'labelsStore')(observer(TenantAdminToolbar))
