import { RootStore } from '../../stores/RootStore'
import { observable, action, computed } from 'mobx'
import { UserGoal } from '../aggregate/UserGoal'
import MyGoalsWidgetVM from '../view-models/MyGoalsWidgetVM'
import { UserGoalsService } from '../service/UserGoalsService'
import { IUserGoalDTO } from '../dto/IUserGoalDTO'
import { DataStore } from '../../shared/data/DataStore'

export class UserGoalsStore extends DataStore<UserGoal, IUserGoalDTO> {
  private svc: UserGoalsService

  constructor(rootStore: RootStore) {
    super(rootStore, UserGoal, 'userGoals', [
      'name',
      'goalId',
      'userId',
      'active',
      'isRead',
      'readDateTime',
      'categoryId',
      'organizationId',
      'startDate',
      'endDate',
      'description',
      'potentialObstacles',
      'dataSourceId',
      'facetId',
      'targetInputValue',
      'goalDirection',
      'aggregationMethod',
      'consistencyThreshold',
      'aggregationPeriod',
      'createdByUserId',
      'worksheetId',
      'targetMode',
      'target',
      'current',
      'measureGoal',
      'participatingUserIds',
      'participants',
      'targetPercentage',
      'askAboutProgress',
      'sendReminders',
      'progressInterval',
      'reminderInterval',
      'thumbnail',
      'thumbnailFileName',
      'associateToTrainingPlans',
      'associatedTrainingPlanIds',
      'isCatalogGoal',
      'activeCatalogPlans',
    ])
  }

  @observable public myGoalsWidgetVM: MyGoalsWidgetVM = null
  @observable public loaded: boolean = false

  @computed
  public get orgUserGoals(): UserGoal[] {
    return this.records
  }

  @action
  public onListRecordsLoaded() {
    this.myGoalsWidgetVM = new MyGoalsWidgetVM(this.rootStore)
    this.svc = new UserGoalsService()
    this.loaded = true
  }

  @action
  public markUserGoalAsRead(id: string) {
    this.svc.markUserGoalAsRead(id)
  }

  public getUserGoal(objectId): UserGoal {
    return this.orgUserGoals.find((goal) => goal.objectId === objectId)
  }

  public deleteUserGoal(objectId) {
    this.orgUserGoals.splice(this.getUserGoalIndex(objectId), 1)
  }

  private getUserGoalIndex(objectId): number {
    return this.orgUserGoals.findIndex((e) => e.objectId === objectId)
  }

  @action
  public editUserGoal(id: string, attempts: number = 0) {
    if (attempts > 10) console.log('Error finding goal to edit')
    const goal = this.getUserGoal(id)
    if (!goal || goal === undefined) setTimeout(() => this.editUserGoal(id, attempts++), 500)
    // this.goalEditVM = new GoalEditVM(this.rootStore, goal)
  }
}
