import React from 'react'
import {
  Dialog,
  DialogTitle,
  Grid,
  DialogContent,
  Button,
  DialogActions,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core'
import LocalizationStore from '../../localization/LocalizationStore'
import SendRoundedIcon from '@material-ui/icons/SendRounded'
import './MuiSelectPrompt.scss'

interface Props {
  localizationStore?: LocalizationStore
  confirmMessage: any
  confirmMessageSecondary?: any
  onClose: any
  open: any
  onConfirm: any
  onSelect: any
  onChange?: any
  title: string
  selectedOption: any
  options: any
  icon?: any
  disabled?: any
  cancelFullWidth?: boolean
  addNew?: boolean
}

const MuiSelectPrompt: React.FC<Props> = ({
  localizationStore,
  confirmMessage,
  confirmMessageSecondary,
  onClose,
  onConfirm,
  onSelect,
  onChange,
  open,
  title,
  selectedOption,
  options,
  icon,
  disabled,
  cancelFullWidth,
  addNew,
}) => {
  const renderButtonIcon = () => {
    if (icon) {
      return null
    } else {
      return <SendRoundedIcon className='send-icon-btn' />
    }
  }

  const renderSecondaryMessage = () => {
    if (!confirmMessageSecondary) return null
    return (
      <p className='cannot-undo-text'>
        <span className={'note-span'}>Note:</span> {confirmMessageSecondary}
      </p>
    )
  }

  const renderDropDownMenu = () => {
    if (addNew) return null
    return (
      <Grid item xs={6}>
        <Select
          variant='outlined'
          fullWidth
          placeholder='Select option...'
          value={selectedOption}
          onChange={onSelect}
        >
          {options.map((option, index) => {
            return (
              <MenuItem key={index} value={option}>
                {option.name}
              </MenuItem>
            )
          })}
        </Select>
      </Grid>
    )
  }

  const renderAddNewInput = () => {
    if (!addNew) return null
    return (
      <Grid item xs={8}>
        <TextField
          value={selectedOption}
          onChange={onChange}
          placeholder='Enter name of new option....'
          fullWidth
          variant='outlined'
        />
      </Grid>
    )
  }

  return (
    <Dialog
      id={'MuiConfirmPrompt'}
      onClose={onClose}
      open={open}
      fullWidth={cancelFullWidth ? false : true}
    >
      <DialogTitle className='dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item md={11} sm={11} className='confirm-message-view'>
            <p className='confirm-message'>{confirmMessage}</p>
            {renderSecondaryMessage()}
          </Grid>
          <Grid item md={11} sm={11} className='drop-down-view'>
            {renderDropDownMenu()}
            {renderAddNewInput()}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onClose}>
          No, Nevermind
        </Button>
        <Button variant='contained' onClick={onConfirm} disabled={disabled}>
          {renderButtonIcon()} Yes, Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default MuiSelectPrompt
