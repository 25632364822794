import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { EventsStore } from '../../store/EventsStore'
import './CalendarEventDrawer.scss'
import { DatePicker } from '@material-ui/pickers'
import { Checkbox, Grid, FormControlLabel, InputLabel } from '@material-ui/core'
import TimePicker from 'src/app/shared/time-picker/TimePicker'

interface ICalendarEventDrawerTimes {
  localizationStore?: LocalizationStore
  eventsStore?: EventsStore
}

const CalendarEventDrawerTimes: React.FC<ICalendarEventDrawerTimes> = ({
  localizationStore,
  eventsStore,
}) => {
  const s = localizationStore.lzStrings
  const vm = eventsStore.editVM
  if (!vm) return

  const renderStartTimePicker = () => {
    if (vm.allDay) return
    return (
      <TimePicker
        value={vm.localStartDate}
        onChange={(hr, min) => {
          vm.setStartTime(hr, min)
        }}
        error={!vm.startDateValid}
      />
    )
  }

  const renderEndTimePicker = () => {
    if (vm.allDay) return
    return (
      <TimePicker
        value={vm.localEndDate}
        onChange={(hr, min) => {
          vm.setEndTime(hr, min)
        }}
        error={!vm.endDateValid}
      />
    )
  }

  return (
    <Grid container spacing={1} item>
      <Grid item xs={6} id='startDatePickers'>
        <InputLabel required htmlFor='startDate'>
          {s.calendarEventDrawer.start}
        </InputLabel>
        <DatePicker
          disabled={vm.addNewType}
          required
          id='startDate'
          variant='inline'
          inputVariant='outlined'
          margin='none'
          fullWidth
          autoOk
          helperText={null}
          value={vm.localStartDate}
          error={!vm.startDateValid}
          onChange={(dt) => vm.setStartDate(dt)}
          format='MMM dd, yyyy'
        />
        {renderStartTimePicker()}
      </Grid>
      <Grid item xs={6} id='endDatePickers'>
        <InputLabel required htmlFor='endDate'>
          {s.calendarEventDrawer.end}
        </InputLabel>
        <DatePicker
          required
          id='endDate'
          variant='inline'
          inputVariant='outlined'
          margin='none'
          fullWidth
          helperText={null}
          autoOk
          value={vm.localEndDate}
          error={!vm.endDateValid}
          onChange={(dt) => vm.setEndDate(dt)}
          format='MMM dd, yyyy'
        />
        {renderEndTimePicker()}
      </Grid>
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              disabled={vm.addNewType}
              checked={vm.event.allDay}
              onChange={() => vm.handleAllDayToggle()}
              value='allDay'
              color='primary'
            />
          }
          label={s.calendarEventDrawer.all_day}
        />
      </Grid>
    </Grid>
  )
}

export default inject('localizationStore', 'eventsStore')(observer(CalendarEventDrawerTimes))
