import React, { useEffect } from 'react'
import { Grid, Tooltip } from '@material-ui/core'
import { inject, observer, Observer } from 'mobx-react'
import VirtualList from 'react-tiny-virtual-list'
import { SurveysStore } from '../../../store/SurveysStore'
import './ResponsesList.scss'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import moment from 'moment'
import { AgGridReact } from 'ag-grid-react'

interface Props {
  surveysStore?: SurveysStore
  localizationStore?: LocalizationStore
}

const ResponsesList: React.FC<Props> = ({ surveysStore, localizationStore }) => {
  const { editVM } = surveysStore.viewModels
  if (!editVM) return null
  const vm = editVM.analysis
  if (!vm) return null
  const responses = vm.responses
  const s = localizationStore.lzStrings

  const renderNoResponses = () => {
    if (vm.responsesListVM.hasRows) return
    return <div className='no-responses'>{s.responseList.no_responses_found}</div>
  }

  const renderHideResponses = () => {
    if (!vm.responsesListVM.hideResponses) return
    return (
      <div className='no-responses'>
        {`To preserve anonymity, responses are hidden unless there are ${editVM.anonymousSurveyResultCount} or more total responses`}
      </div>
    )
  }

  const renderGrid = () => {
    return (
      <div className={vm.responsesListVM.gridClassName} style={{ height: '100%', width: '100%' }}>
        <AgGridReact
          gridOptions={{
            ...vm.responsesListVM.getGridOptions(),
          }}
        />
      </div>
    )
  }

  const renderResponsesList = () => {
    return (
      <div id='ResponsesList'>
        {renderGrid()}
        {renderNoResponses()}
        {renderHideResponses()}
      </div>
    )
  }

  return <>{renderResponsesList()}</>
}

export default inject('surveysStore', 'localizationStore')(observer(ResponsesList))
