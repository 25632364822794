import { RootStore } from '../../../stores/RootStore'
import { computed } from 'mobx'
import OrganizationsSAListWidgetVM from './OrganizationsSAListWidgetVM'
import { Organization } from '../../aggregate/Organization'

export class OrganizationsSARowVM {
  private organization: Organization
  private rootStore: RootStore
  private listVM: OrganizationsSAListWidgetVM

  constructor(
    rootStore: RootStore,
    listVM: OrganizationsSAListWidgetVM,
    organization: Organization
  ) {
    this.rootStore = rootStore
    this.organization = organization
    this.listVM = listVM
  }

  @computed
  public get objectId(): string {
    return this.organization.objectId
  }

  @computed
  public get name(): string {
    return this.organization.name
  }

  @computed
  public get email(): string {
    if (!this.organization.adminEmail) return '--'
    return this.organization.adminEmail
  }

  @computed
  public get phone(): string {
    if (!this.organization.adminPhone) return '--'
    return this.organization.adminPhone
  }

  @computed
  public get zone(): string {
    if (!this.organization.zone) return '--'
    return this.organization.zone.objectId
  }
}
