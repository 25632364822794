import { observable, computed, action, reaction } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { Worksheet } from '../../aggregate/Worksheet'
import { ChartType } from '../../types/ChartType'
import { ParseService } from '../../../services/ParseService'
import HashId from '../../../shared/HashId'
import { WidgetContainerVM } from '../../../dashboardsV2/view-models/WidgetContainerVM'
import { TableauAuthTokensService } from '../../../tableauAuthTokens/service/TableauAuthTokensService'

export class ChartWidgetVM {
  private rootStore: RootStore
  private tableauViz: tableau.Viz

  constructor(rootStore: RootStore, dashboardWidgetInfo: WidgetContainerVM) {
    this.rootStore = rootStore
    this.dashboardWidgetInfo = dashboardWidgetInfo
    if (this.worksheet) this.worksheet.setLoggedInUser(rootStore.appStore.currentUserId)
    this.loadTableauAuthToken()
    this.key = HashId.generate()
    this.refreshDimensions()
  }

  @observable public isSaving: boolean = false
  @observable public dashboardWidgetInfo: WidgetContainerVM
  @observable public width: number = 0
  @observable public height: number = 0
  @observable public key: string
  @observable public highlightedData: string = ''
  @observable public goalModalShown: boolean = false
  @observable public tableauAuthToken: string = null

  @computed
  public get hasPermission() {
    if (this.rootStore.appStore.isOrgAdmin) return true
    if (!this.worksheetId) return true
    if (!this.rootStore.userStore.currentOrganization) return false
    if (!this.worksheet) return false
    if (this.worksheet.owner === this.rootStore.appStore.currentUserId) return true
    if (!this.worksheet.visibleTo) return false
    let isVisible = false
    const foundUser = this.worksheet.visibleTo.find(
      (e) => e.id === this.rootStore.appStore.currentUserId
    )
    if (foundUser) isVisible = true
    const roles = this.worksheet.visibleTo.filter((e) => e.type === 'role')
    if (roles.length) {
      roles.forEach((role) => {
        const foundRole = this.rootStore.userStore.currentOrganization.roles.find(
          (e) => e.id === role.id
        )
        if (foundRole) isVisible = true
      })
    }
    const groups = this.worksheet.visibleTo.filter((e) => e.type === 'group')
    if (groups.length) {
      groups.forEach((group) => {
        const foundRole = this.rootStore.userStore.currentOrganization.groups.find(
          (e) => e.id === group.id
        )
        if (foundRole) isVisible = true
      })
    }
    return isVisible
  }

  @computed
  public get isLoading(): boolean {
    return !this.worksheet && this.hasWorksheet
  }

  @computed
  public get worksheetId(): string {
    return this.dashboardWidgetInfo.worksheetId
  }

  @computed
  public get worksheet(): Worksheet {
    return this.rootStore.worksheetsStore.getWorksheet(this.worksheetId)
  }

  @computed
  public get dashboardWidgetIndex(): string {
    return this.dashboardWidgetInfo.index
  }

  @computed
  public get hasWorksheet(): boolean {
    return Boolean(this.dashboardWidgetInfo.worksheetId)
  }

  @action
  public openDrawer() {
    this.rootStore.worksheetsStore.openChartWidgetDrawer(this)
  }

  @computed
  public get chartType(): ChartType {
    if (!this.hasWorksheet) return null
    return this.worksheet.chartTypeId
  }

  @computed
  public get name(): string {
    if (!this.hasWorksheet) return 'Select Chart'
    if (this.isLoading) return 'Loading'
    return this.worksheet.name
  }

  @computed
  public get selectedChartTypeText(): string {
    if (!this.hasWorksheet) return ''
    return this.worksheet.chartTypeId
  }

  publish() {
    throw new Error('Method not implemented.')
  }

  refresh() {
    throw new Error('Method not implemented.')
  }

  @computed
  public get isNameValid(): boolean {
    return this.name !== ''
  }

  loadTestData() {
    throw new Error('Method not implemented.')
  }

  @action
  public setChartType(val: ChartType): void {
    this.worksheet.setChartType(val)
  }

  @action
  public async save() {
    const svc = new ParseService()
    const newWorksheetId = await svc.saveWorksheet(
      this.rootStore.appStore.currentOrgId,
      this.worksheet.serialize()
    )
    this.rootStore.appStore.router.push('/worksheets/edit/' + newWorksheetId)
  }

  @computed
  public get tableauWorkbook(): string {
    return this.worksheet.tableauWorkbook
  }

  @computed
  public get tableauWorksheet(): string {
    return this.worksheet.tableauWorksheet
  }

  @action
  public async loadTableauAuthToken() {
    if (!this.worksheet) return
    if (!this.hasPermission) return
    const svc = new TableauAuthTokensService(this.rootStore)
    const token = await svc.getAuthToken()
    this.tableauAuthToken = token
  }

  @computed
  public get tableauViewUrl(): string {
    console.log(this.worksheet.tableauViewUrl)
    return this.worksheet.tableauViewUrl
  }

  @computed
  public get tableauQuery(): string {
    return this.worksheet.tableauQuery
  }

  @computed
  public get showTabs(): boolean {
    return this.worksheet.showTabs
  }

  @action
  public refreshDimensions() {
    const div = document.getElementById('div' + this.key)
    if (!div) {
      setTimeout(() => this.refreshDimensions(), 200)
      return
    }
    const rect = div.getBoundingClientRect()
    this.width = rect.width
    this.height = rect.height
  }

  @action
  public setTableauVizContainer(div, viz) {
    this.tableauViz = viz
    this.captureMarks()
  }

  private captureMarks() {
    this.tableauViz.addEventListener(
      tableau.TableauEventName.MARKS_SELECTION,
      (marks: tableau.MarksEvent) => {
        marks.getMarksAsync().then((marks: tableau.Mark[]) => {
          const pairs = marks[0].getPairs()
          this.highlightedData = JSON.stringify(pairs)
        })
      }
    )
  }

  @computed
  public get canRevertAll(): boolean {
    if (!this.worksheet) return false
    return !Boolean(this.worksheet.forLoggedInUser)
  }

  @action
  public async revertAll(): Promise<void> {
    if (!this.tableauViz) return
    await this.tableauViz.revertAllAsync()
  }

  @action
  public openGoalModal() {
    this.goalModalShown = true
  }

  @action
  public closeGoalModal() {
    this.goalModalShown = false
  }

  @action
  public filterChart() {
    // // const workbook = this.tableauViz.getWorkbook()
    // // const worksheet = workbook.getActiveSheet()
    // // const sheet = worksheet.getWork
    // // worksheet.applyFilterAsync('Name', 'Jason', tableau.FilterUpdateType.REPLACE)
    // const sheets = this.tableauViz.getWorkbook().getActiveSheet().getWorksheets()
    // console.log(sheets)
    // const db = sheets[0].getParentDashboard()
    // console.log(db)
    // const sheet = this.tableauViz.getWorkbook().getActiveSheet().getWorksheets().get('Bar 1')
    // console.log(sheet)
    // sheet.applyFilterAsync('Name', 'Jason', tableau.FilterUpdateType.REPLACE)
    const viz: any = this.tableauViz
    viz
      .getWorkbook()
      .getActiveSheet()
      .getWorksheets()
      .get('Bars 1')
      .getFiltersAsync()
      .then((filters: tableau.Filter[]) => {
        console.log(filters)
        // filters[2].applyFilterAsync('Jason', tableau.FilterUpdateType.REPLACE)
        // FilterForApples = filters[0].getAppliedValues();
        // FilterForOranges = filters[1].getAppliedValues();
      })

    viz
      .getWorkbook()
      .getActiveSheet()
      .getWorksheets()
      .get('Bars 1')
      .applyFilterAsync('Name', 'Jason', tableau.FilterUpdateType.REPLACE)
  }
}
