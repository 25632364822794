import React from 'react'
import { inject, observer } from 'mobx-react'
import { Card, Fade, Popover, Link } from '@material-ui/core'
import UserTrainingPlanPopupToolbar from './UserTrainingPlanPopupToolbar'
import UserTrainingPlanPopupBody from './UserTrainingPlanPopupBody'
import './UserTrainingPlanPopup.scss'
import { EventsStore } from '../../../store/EventsStore'

interface IUserTrainingPlanPopup {
  eventsStore?: EventsStore
}

const UserTrainingPlanPopup: React.FC<IUserTrainingPlanPopup> = ({ eventsStore }) => {
  const { trainingPlanPopupVM: vm } = eventsStore
  if (!vm) return null
  if (!vm.anchorEl) return null

  return (
    <Popover
      open={true}
      anchorEl={vm.anchorEl}
      onClose={() => vm.close()}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      className='calendarEventModal'
    >
      <Card elevation={5} className='userTrainingPopupCard'>
        <UserTrainingPlanPopupToolbar />
        <UserTrainingPlanPopupBody />
        <Link onClick={() => vm.navigateToTrainingPlan()} className='training-link'>
          Open Training Plan
        </Link>
      </Card>
    </Popover>
  )
}

export default inject('eventsStore')(observer(UserTrainingPlanPopup))
