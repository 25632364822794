import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { CategoriesStore } from 'src/app/categories/CategoriesStore'
import MuiDrawerToolbar from '../../../../shared/Mui-Drawer/MuiDrawerToolbar'
import { LabelsStore } from '../../../../labels/store/LabelsStore'

interface DrawerButtonControlsProps {
  localizationStore?: LocalizationStore
  categoriesStore?: CategoriesStore
  labelsStore?: LabelsStore
}

const DrawerButtonControls: React.FC<DrawerButtonControlsProps> = ({
  localizationStore,
  categoriesStore,
  labelsStore,
}) => {
  const { lzStrings } = localizationStore
  const { widgetForm: vm } = categoriesStore
  if (!vm) return null

  return (
    <div>
      <MuiDrawerToolbar
        handleCancelClick={() => {
          vm.closeDrawer()
        }}
        handleSubmitClick={() => categoriesStore.saveWidgetForm()}
        showReset={Boolean(vm.objectId)}
        onConfirmReset={() => categoriesStore.reloadEditVM(vm.objectId)}
        moreMenuProps={{
          hidden: !vm.objectId,
          isEditMode: true,
          itemType: labelsStore.getLabelByLanguageAndFor('category'),
          itemName: vm.name,
          onConfirmDelete: () => vm.delete(),
        }}
      />
    </div>
  )
}

export default inject(
  'localizationStore',
  'categoriesStore',
  'labelsStore'
)(observer(DrawerButtonControls))
