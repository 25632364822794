import React, { FC } from 'react'
import rootStore from '../../../../stores/RootStore'
import { Toolbar, Typography, Button, Tooltip, CssBaseline, AppBar } from '@material-ui/core'
import '../../../../css/dashboard-toolbar.scss'
import { inject, observer } from 'mobx-react'
import { UserDashboardsStore } from '../../UserDashboardsStore'
import EditIcon from '@material-ui/icons/Edit'
import isIE11 from '../../../../../utils/isIE11'
import clsx from 'clsx'
import { LabelsStore } from '../../../../labels/store/LabelsStore'

export interface Props {
  children?: React.ReactElement
  userDashboardsStore?: UserDashboardsStore
  labelsStore?: LabelsStore
}

const SurveysToolbar: FC<Props> = ({ userDashboardsStore, labelsStore }) => {
  const vm = userDashboardsStore.viewModels.userDashboardVM

  const renderSurveysButtons = () => {
    return (
      <>
        <Tooltip
          title={rootStore.localizationStore.lzStrings.dashboard.manage_dashboard_button_tooltip}
          placement='bottom'
        >
          <span>
            <Button
              disabled={Boolean(!vm.userDashboard)}
              className='dashboard-action btn-icon btn-icon-white'
              variant='outlined'
              onClick={() => vm.editDashboard()}
            >
              <EditIcon />{' '}
              {rootStore.localizationStore.lzStrings.dashboard.manage_dashboard_button_text}
            </Button>
          </span>
        </Tooltip>
        {renderManageSurveyTemplatesButton()}
        {renderAddButton()}
        {renderSeleniumCanSurveyHook()}
      </>
    )
  }

  const renderSeleniumCanSurveyHook = () => {
    if (!vm.userDashboard) return null
    if (!rootStore.appStore.canSurvey) return null
    return <input type='hidden' className='sln-can-create-survey' />
  }

  const renderAddButton = () => {
    if (!rootStore.appStore.canSurvey) return null
    return (
      <>
        <Button
          disabled={Boolean(!vm.userDashboard)}
          className='dashboard-action sln-create-survey-button'
          variant='contained'
          onClick={() => vm.addSurvey()}
        >
          {`${rootStore.localizationStore.lzStrings.surveys.create_new}
          ${labelsStore.getLabelByLanguageAndFor('pulse')}`}
        </Button>
      </>
    )
  }

  const renderManageSurveyTemplatesButton = () => {
    if (!vm.canManageSurveyTemplates) return null
    return (
      <Tooltip title={'Survey Templates'} placement='bottom'>
        <span>
          <Button
            variant='outlined'
            className='dashboard-action btn-icon btn-icon-white end-action'
            onClick={() => rootStore.appStore.router.push('/tenantAdmin/manage/surveyTemplates')}
          >
            {'Survey Templates'}
          </Button>
        </span>
      </Tooltip>
    )
  }

  return (
    <>
      <div id='DashboardToolbar'>
        <CssBaseline />
        <AppBar
          className={clsx('dashboard-appbar', {
            'no-transition': isIE11,
          })}
          elevation={0}
        >
          <Toolbar className='dashboard-toolbar'>
            <Typography className='dashboard-title'>
              {labelsStore.getLabelByLanguageAndFor('pulses')}
            </Typography>
            <div className='dashboard-toolbar-right'>{renderSurveysButtons()}</div>
          </Toolbar>
        </AppBar>
        <Toolbar />
      </div>
    </>
  )
}

export default inject('userDashboardsStore', 'labelsStore')(observer(SurveysToolbar))
