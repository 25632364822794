import React from 'react'
import { observer } from 'mobx-react'
import { Dialog } from '@material-ui/core'
import { ContentVM } from '../view-models/ContentVM'
import rootStore from '../../stores/RootStore'
import { WorksheetsStore } from '../../worksheets/store/WorksheetsStore'
import VisualizationReport from './VisualizationReport'
import VisualizationReportViewerToolbar from './VisualizationReportViewerToolbar'
import './Content.scss'
import './Visualizations.scss'

interface Props {
  props: any
  worksheetsStore?: WorksheetsStore
}

@observer
export default class VisualizationReportViewer extends React.Component<Props, any> {
  private vm: ContentVM

  constructor(props) {
    super(props)

    this.vm = rootStore.userWorksheetsStore.contentVM
    if (props.params.id && this.vm && !this.vm.selectedItemVM) {
      rootStore.userWorksheetsStore.lazyLoadVM(props.params.id)
    }
  }

  componentWillUnmount() {
    if (this.vm && this.vm.selectedItemVM) {
      this.vm.selectedItemVM.loadTableauAuthToken()
    }
  }

  private handleClose = () => {
    this.vm.setOpen(false)
    this.vm.setSelectedItemVM(undefined)
  }

  render() {
    return (
      <div id='VisualizationsLayout'>
        <div className='visualizations-container'>
          <div id='SortableGrid'>
            <Dialog
              fullScreen
              open={this.vm.isOpen}
              onClose={this.handleClose}
              aria-labelledby='grid-viz-dialog-title'
              aria-describedby='grid-viz-dialog-description'
            >
              <div id='VizDialogAppBar'>
                <VisualizationReportViewerToolbar vm={this.vm} />
              </div>
              <div id='visualization-report'>
                {this.vm.selectedItemVM ? (
                  <VisualizationReport vm={this.vm.selectedItemVM} />
                ) : undefined}
              </div>
            </Dialog>
          </div>
        </div>
      </div>
    )
  }
}
