import React from 'react'
import { inject, observer } from 'mobx-react'
import QuizStats from './QuizStats'
import QuizResponses from './QuizResponses'
import { QuizResultsVM } from '../view-models/QuizResultsVM'
import { Grid, CircularProgress } from '@material-ui/core'
import './QuizResults.scss'

interface Props {
  vm: QuizResultsVM
}

const QuizResults: React.FC<Props> = ({ vm }) => {
  if (!vm) return null

  const renderQuizStats = () => {
    return <QuizStats vm={vm} />
  }

  const renderQuizResponses = () => {
    return <QuizResponses vm={vm} />
  }

  const renderSpinner = () => {
    if (vm.quizResult) return null
    return (
      <Grid container item xs={12}>
        <CircularProgress color='secondary' style={{ position: 'relative', top: '50%', left: '50%' }} />
      </Grid>
    )
  }

  const renderQuizResults = () => {
    if (!vm.quizResult) return null
    return (
      <>
        {renderQuizStats()}
        {renderQuizResponses()}
      </>
    )
  }

  return (
    <div id='QuizResults'>
      {renderSpinner()}
      {renderQuizResults()}
    </div>
  )
}

export default inject()(observer(QuizResults))
