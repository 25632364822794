import Parse from 'parse'
import { action, computed, reaction } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { UserEvent } from '../aggregate/UserEvent'
import { IUserEventDTO } from '../dtos/IUserEventDTO'
import { DataStore } from '../../../shared/data/DataStore'

export class UserEventsStore extends DataStore<UserEvent, IUserEventDTO> {
  private loadedUsers: string[] = []

  constructor(rootStore: RootStore) {
    super(rootStore, UserEvent, 'userEvents', [
      'objectId',
      'organizationId',
      'eventId',
      'startDate',
      'endDate',
      'readStatus',
      'goingStatus',
      'userId',
      'goingStatusOnce',
      'dayIndex',
      'occurrenceStartDate',
      'allDay',
      'title',
      'notes',
      'locationId',
      'eventTypeId',
      'repeats',
      'organizer',
      'masterEventId',
      'onTimeAlarm',
      'separateFromMasterEventId',
      'participants',
      'resources',
      'alarms',
      'userIds',
      'readUserIds',
      'goingNewUserIds',
      'goingYesUserIds',
      'goingMaybeUserIds',
      'goingNoUserIds',
      'occurrenceId',
      'attachments',
      'schedule',
      'isDeleted',
    ])
    this.reactToWatchingUsers()
    this.keepIsDeletedRecords = true
  }

  private reactToWatchingUsers() {
    if (process.env.NODE_ENV === 'test') return
    reaction(
      () => this.watchingUsers,
      () => {
        if (!this.listRecordsLoaded) return
        this.loadListRecords()
      },
      { delay: 500 }
    )
  }

  protected getBaseQuery() {
    const query = new Parse.Query(this.className)
    query.equalTo('organizationId', this.rootStore.appStore.currentOrgId)
    query.containedIn('userId', this.watchingUsers)
    return query
  }

  @computed
  public get watchingUsers() {
    if (!this.rootStore.eventsStore) {
      return [this.rootStore.appStore.currentUserId]
    }
    const list = this.rootStore.eventsStore.calendarVM.selectedOtherCalendars
      .map((e) => e.id)
      .concat(this.rootStore.appStore.currentUserId)
      .sort((a, b) => (a < b ? -1 : 0))
    const set = new Set(list)
    return Array.from(set)
  }

  public shouldLoadListRecords() {
    if (this.loadedUsers.length === 0) return true
    return JSON.stringify(this.loadedUsers) !== JSON.stringify(this.watchingUsers)
  }

  @action
  private setLoadedUsers(userIds: string[]) {
    this.loadedUsers = userIds
  }

  public onListRecordsLoaded() {
    this.setLoadedUsers(this.watchingUsers.slice())
  }

  public getUserEventsForUser(userId: string): UserEvent[] {
    return this.currentOrgUserEvents.filter((e) => e.userId === userId)
  }

  public getUserEventsForLoggedInUser(): UserEvent[] {
    const loggedInUserId = this.rootStore.appStore.currentUserId
    return this.currentOrgUserEvents.filter((e) => {
      if (e.userId === loggedInUserId) return true
      return false
    })
  }

  @computed
  public get currentOrgUserEvents(): Array<UserEvent> {
    return this.records.filter((e) => e.organizationId === this.rootStore.appStore.currentOrgId)
  }

  public getUserEvent(objectId: string): UserEvent {
    return this.records.find((e) => e.objectId === objectId)
  }
}
