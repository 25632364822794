import { action, observable, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { UserTask } from '../aggregates/UserTask'
import moment from 'moment'
import { GridReadyEvent, ColDef, GridApi, RowClickedEvent, GridOptions } from 'ag-grid-community'
import PendingUserTaskWidgetRowVM from '../view-models/PendingUserTaskWidgetRowVM'
import { AGGridVM } from '../../shared/ag-grid/AGGridVM'
import { nameOf } from '../../shared/nameOf'

export class PendingUserTasksWidgetVM extends AGGridVM {
  constructor(rootStore: RootStore) {
    super()
    this.rootStore = rootStore
    this.rootStore.audienceMembersStore.loadAudienceMembers()
  }

  @observable private rootStore: RootStore
  @observable public tabIndex: number = 0

  @action
  public getRowNodeId(row): string {
    return row.objectId
  }

  @computed
  public get fields() {
    const s = this.rootStore.localizationStore.lzStrings
    return [
      { name: s.completedUserTasksWidget.name, field: 'name' },
      { name: s.completedUserTasksWidget.discipline, field: 'disciplineName' },
      { name: s.completedUserTasksWidget.due, field: 'dueDate' },
      { name: s.completedUserTasksWidget.creator, field: 'assignedByUserName' },
    ]
  }

  @computed
  public get columnDefs(): ColDef[] {
    const s = this.rootStore.localizationStore.lzStrings
    return [
      {
        headerName: s.completedUserTasksWidget.name,
        field: nameOf<PendingUserTaskWidgetRowVM, string>((e) => e.name),
        sort: 'asc',
      },
      {
        headerName: s.completedUserTasksWidget.discipline,
        field: nameOf<PendingUserTaskWidgetRowVM, string>((e) => e.disciplineName),
        hide: !this.allowDiscipline,
      },
      {
        headerName: s.completedUserTasksWidget.due,
        field: nameOf<PendingUserTaskWidgetRowVM, number>((e) => e.dueDate),
        valueFormatter: ({ value }) =>
          moment.unix(value).format(s.completedUserTasksWidget.user_task_due_date_format),
        cellClass: (params) => {
          const isOverdue = params.data.isOverdue
          if (isOverdue) return 'over-due-days'
          return ''
        },
      },
      {
        headerName: s.completedUserTasksWidget.creator,
        field: 'assignedByUserName',
        cellRenderer: 'creatorCell',
      },
    ]
  }

  @computed get pendingUserTasks(): Array<UserTask> {
    return this.rootStore.userTasksStore.userTasks.filter(
      (e) => !e.isCompleted && e.userId === this.rootStore.appStore.currentUserId
    )
  }

  @computed
  public get allowFeedback(): boolean {
    if (!this.rootStore.organizationsStore.currentOrganization) return false
    return this.rootStore.organizationsStore.currentOrganization.tasksAllowFeedback
  }

  @computed
  public get allowDiscipline(): boolean {
    if (!this.rootStore.organizationsStore.currentOrganization) return false
    return this.rootStore.organizationsStore.currentOrganization.tasksAllowDiscipline
  }

  @computed
  public get rows(): PendingUserTaskWidgetRowVM[] {
    const rows = this.pendingUserTasks.map((userTask) => {
      return new PendingUserTaskWidgetRowVM(this.rootStore, userTask, this)
    })
    return rows.filter((e) => e.name)
  }

  public getGridOptions(): GridOptions {
    return {
      ...this.baseGridOptions,
      rowData: this.rows,
      onRowClicked: (e) => this.rowClicked(e),
      getRowNodeId: (e) => this.getRowNodeId(e),
      columnDefs: this.ensureTooltipFields(this.columnDefs),
    }
  }

  public rowClicked(e: RowClickedEvent) {
    const row: PendingUserTaskWidgetRowVM = e.data
    if (!row) return
    row.openUserTask()
  }

  @computed
  public get shouldRender(): boolean {
    if (!this.rootStore.tasksStore.loaded) return false
    if (!this.rootStore.audienceMembersStore.loaded) return false
    return true
  }

  @action setTabIndex(value: number) {
    this.tabIndex = value
  }
}
