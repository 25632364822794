import React from 'react'
import { inject, observer } from 'mobx-react'
import rootStore from '../../../stores/RootStore'
import { Card, Dialog, DialogContent, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import { PulseCampaignsStore } from '../../store/PulseCampaignsStore'
import startFromScratch from '../../../../assets/img/startFromScratch.png'
import startFromTemplate from '../../../../assets/img/startFromTemplate.png'
import DialogHeader from './DialogHeader'
import './CreateCampaignDialog.scss'

interface Props {
  pulseCampaignsStore?: PulseCampaignsStore
}

const useStyles = makeStyles((theme: Theme) => ({
  backDrop: {
    backdropFilter: 'blur(1px)',
    backgroundColor: 'rgba(0,0,30,0.9)',
  },
  input: {
    textAlign: 'center',
    '&::placeholder': {
      textAlign: 'center',
    },
  },
}))

const CreateCampaignDialog: React.FC<Props> = ({ pulseCampaignsStore }) => {
  const classes = useStyles()
  const vm = pulseCampaignsStore.viewModels.createCampaignDialogVM
  if (!vm) return null
  const { pulseCampaign: lz } = rootStore.localizationStore.lzStrings
  const labelsStore = rootStore.labelsStore

  const renderDialogTitle = () => {
    return (
      <DialogHeader
        pulseCampaignsStore={pulseCampaignsStore}
        title={lz.create_campaign}
        onClick={() => vm.closeCreateCampaignDialog()}
      />
    )
  }

  const renderCampaignDialogActionButtons = () => {
    return (
      <Grid container direction='row' justifyContent='center' alignItems='center'>
        <Grid item className={'create-campaign-grid-item'} xs={6}>
          <Card
            className={'create-campaign-btn-card'}
            onClick={(e) => {
              vm.handleStartFromTemplate()
            }}
          >
            <div className='create-campaign-btn-card-container'>
              <div className={'create-campaign-btn-card-frame'}>
                <img
                  className={'create-campaign-btn-img'}
                  src={startFromTemplate}
                  alt={'Start from Template'}
                />
              </div>
              <div className={'create-campaign-btn-card-text'}>
                <Typography variant='body1' className={'create-campaign-card-label-main'}>
                  {lz.start_from_template}
                </Typography>
                <Typography variant='body2' className={'create-campaign-card-label-sub'}>
                  {`${lz.choose_from_our_library} ${labelsStore.getLabelByLanguageAndFor(
                    'pulses'
                  )}`}
                </Typography>
              </div>
            </div>
          </Card>
        </Grid>
        <Grid item className={'create-campaign-grid-item'} xs={6}>
          <Card
            className={'create-campaign-btn-card'}
            onClick={(e) => {
              vm.handleStartFromScratch()
            }}
          >
            <div className='create-campaign-btn-card-container'>
              <div className={'create-campaign-btn-card-frame'}>
                <img
                  className={'create-campaign-btn-img'}
                  src={startFromScratch}
                  alt={'Start from Scratch'}
                />
              </div>
              <div className={'create-campaign-btn-card-text'}>
                <Typography variant='body1' className={'create-campaign-card-label-main'}>
                  {lz.start_from_scratch}
                </Typography>
                <Typography variant='body2' className={'create-campaign-card-label-sub'}>
                  {lz.create_your_own}
                </Typography>
              </div>
            </div>
          </Card>
        </Grid>
      </Grid>
    )
  }

  const renderDialogContent = () => {
    return (
      <DialogContent>
        <Grid
          container
          className={'create-campaign-grid'}
          direction='row'
          justifyContent='center'
          alignItems='stretch'
        >
          {renderCampaignDialogActionButtons()}
        </Grid>
      </DialogContent>
    )
  }

  return (
    <Dialog
      id='CreateCampaignDialog'
      open={vm.isCreateCampaignDialogOpen}
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
      onClose={() => vm.closeCreateCampaignDialog()}
      fullWidth
    >
      <div className={'dialog-root'}>
        <div className={'dialog-header'}>{renderDialogTitle()}</div>
        <div className={'dialog-content'}>{renderDialogContent()}</div>
      </div>
    </Dialog>
  )
}

export default inject('pulseCampaignsStore')(observer(CreateCampaignDialog))
