import { observable } from 'mobx'
import { serializable, serialize, deserialize } from 'serializr'
import { IFKPulseCategoryDTO } from '../dtos/IFKPulseCategoryDTO'

export class FKPulseCategory implements IFKPulseCategoryDTO {
  @serializable @observable public name: string = ''
  @serializable @observable public objectId: string = ''

  public clone(): FKPulseCategory {
    return deserialize(FKPulseCategory, this.serialize())
  }

  public serialize(): IFKPulseCategoryDTO {
    return serialize(this)
  }
}