import React from 'react'
import { inject, observer } from 'mobx-react'
import { ListWidgetVM } from '../../../view-models/ListWidgetVM'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import { ClickAwayListener } from '@material-ui/core'
import { CategoriesStore } from 'src/app/categories/CategoriesStore'
import AGGridWidgetPagingRow from '../../../../shared/ag-grid/AGGridPagingRow'

interface Props {
  categoriesStore?: CategoriesStore
}

const CategoriesTable: React.FC<Props> = ({ categoriesStore }) => {
  const vm: ListWidgetVM = categoriesStore.listWidgetVM
  if (!vm) return null
  if (!vm.shouldRender) return null

  const renderGrid = () => {
    return (
      <div className='ag-theme-alpine' style={{ height: '100%', width: '100%' }}>
        <AgGridReact
          gridOptions={{
            ...vm.getGridOptions(),
          }}
        />
      </div>
    )
  }

  const renderCustomPagination = () => {
    return (
      <ClickAwayListener onClickAway={() => categoriesStore.hideEditButton()}>
        <AGGridWidgetPagingRow vm={vm} />
      </ClickAwayListener>
    )
  }

  return (
    <>
      {renderGrid()}
      {renderCustomPagination()}
    </>
  )
}

export default inject('categoriesStore')(observer(CategoriesTable))
