import React from 'react'
import { observer, inject } from 'mobx-react'
import { CardHeader, IconButton, Card, CardContent, Button } from '@material-ui/core'
import './WidgetHeader.scss'
import Popover from '@material-ui/core/Popover'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import { Dropdown } from 'semantic-ui-react'
import CloseIcon from '@material-ui/icons/Close'
import CatalogItemsWidgetVM from '../../../view-models/widgets/item-catalog-widget/CatalogItemsWidgetVM'

interface FilterPopupProps {
  widgetVM: CatalogItemsWidgetVM
  localizationStore?: LocalizationStore
}

const FilterPopup: React.FC<FilterPopupProps> = ({ widgetVM, localizationStore }) => {
  const vm = widgetVM.filterPopupVM
  const { filter_popup: lz } = localizationStore.lzStrings
  if (!widgetVM || !vm) return null

  const close = () => widgetVM.setFilterPopupAnchorEl(null)

  const renderCardHeader = () => {
    return (
      <CardHeader
        title={
          <div className='card-header-title-container'>
            <div>Filter Catalog Items</div>
            <div>
              <IconButton onClick={() => close()}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        }
        className='filter-popover-card-header'
      />
    )
  }

  const renderCardContent = () => {
    return (
      <CardContent className='filter-popover-card-content'>
        <div>
          <div className='categories-section-label'>Categories</div>
          <Dropdown
            placeholder='Categories'
            fluid
            multiple
            search
            selection
            options={vm.categoryOptions}
            value={vm.filterCategoryIds.slice()}
            onChange={(e, { value }) => {
              vm.setFilterCategoryIds(value)
            }}
            clearable
          />
        </div>
        <div className='apply'>
          <Button
            disabled={!vm.hasFilter}
            onClick={() => {
              vm.clearFilters()
            }}
          >
            {lz.clear_all}
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              vm.handleFiltering()
              close()
            }}
          >
            {lz.apply}
          </Button>
        </div>
      </CardContent>
    )
  }

  return (
    <Popover
      id='CatalogItemsWidgetFilterPopover'
      open={Boolean(widgetVM.filterPopupAnchorEl)}
      anchorEl={widgetVM.filterPopupAnchorEl}
      onClose={() => close()}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Card id='CatalogItemsWidgetFilterPopoverCard'>
        {renderCardHeader()}
        {renderCardContent()}
      </Card>
    </Popover>
  )
}

export default inject('surveysStore', 'localizationStore')(observer(FilterPopup))
