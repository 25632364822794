import { computed, observable } from 'mobx'
import { LabelsStore } from '../../labels/store/LabelsStore'
import DashboardEditVM from './DashboardEditVM'

export default class WidgetTileVM {
  private widget: any
  private editVM: DashboardEditVM

  constructor(editVM: DashboardEditVM, widget) {
    this.widget = widget
    this.editVM = editVM
    this.labels = editVM.rootStore.labelsStore
  }

  @observable private labels: LabelsStore = null

  @computed
  public get i() {
    return this.widget.i
  }

  @computed
  public get name() {
    let name = this.widget.name
    name = name.replace('Surveys', this.labels.getLabelByLanguageAndFor('pulses'))
    name = name.replace('Survey', this.labels.getLabelByLanguageAndFor('pulse'))
    return name
  }

  @computed
  public get img() {
    return this.widget.img
  }

  @computed
  public get type() {
    return this.widget.type
  }

  @computed
  public get isUsed() {
    if (this.type === 'WorksheetWidget') return false
    let foundWidget = this.editVM.layout.find((e) => e.type === this.type)
    if (foundWidget) return true
  }
}
