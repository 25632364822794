import React from 'react'
import { CardContent, Typography } from '@material-ui/core'
import './OrganizationsSAListWidget.scss'
import OrgsTable from './OrgsTable'
import { OrganizationsSAStore } from '../../store/OrganizationsSAStore'
import LocalizationStore from '../../../localization/LocalizationStore'
import { inject, observer } from 'mobx-react'

interface Props {
  organizationsSAStore?: OrganizationsSAStore
  localizationStore?: LocalizationStore
}

const Content: React.FC<Props> = ({ organizationsSAStore, localizationStore }) => {
  const { listWidgetVM: vm } = organizationsSAStore
  const pagedDataVM = vm.pagedData

  if (!vm) return null

  const renderNoRowsContent = () => {
    if (pagedDataVM.displayRows.length > 0) return
    return (
      <Typography component='div' className='no-rows'>
        No Records Found
      </Typography>
    )
  }

  return (
    <div id='Content'>
      <CardContent className='no-padding'>
        <OrgsTable />
        {renderNoRowsContent()}
      </CardContent>
    </div>
  )
}

export default inject('organizationsSAStore', 'localizationStore')(observer(Content))
