import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { UserSurveysStore } from '../../store/UserSurveysStore'
import LocalizationStore from '../../../localization/LocalizationStore'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Fade from '@material-ui/core/Fade'
import Skeleton from '@material-ui/lab/Skeleton'
import Question from './QuestionRow'
import QuestionRowDivider from './QuestionRowDivider'
import SubmitRow from './SubmitRow'
import LinearNavRow from './LinearNavRow'
import notFoundImage from '../../../../assets/img/survey-not-found.png'
import './WidgetContent.scss'
import { UserSurveyTakeVM } from '../../view-models/UserSurveyTakeVM'
import Card from '@material-ui/core/Card'
import InfoTextRow from './InfoTextRow'

interface Props {
  localizationStore?: LocalizationStore
  userSurveysStore?: UserSurveysStore
  takeVM: UserSurveyTakeVM
  disabled?: boolean
}

const WidgetContent: React.FC<Props> = ({
  localizationStore,
  userSurveysStore,
  takeVM,
  disabled,
}) => {
  const store = userSurveysStore
  const strings = localizationStore.lzStrings.takeSurvey

  if (takeVM && takeVM.isSubmitted) return null

  const renderLoading = () => {
    if (takeVM || store.anonymousSurveyNotFound) return null

    return (
      <div className='user-survey-question-skeleton'>
        <Skeleton variant='circle' height='40px' width='40px' />
        <Skeleton
          variant='rect'
          height='250px'
          width='100%'
          className='user-survey-question-skeleton-rect'
        />
      </div>
    )
  }

  const renderNotFound = () => {
    if (store.anonymousSurveyNotFound)
      return (
        <Fade in timeout={1500}>
          <div className='survey-not-found-container'>
            <Typography variant='h4'>{strings.not_found_oops}</Typography>
            <img src={notFoundImage} style={{ margin: '1rem auto' }} alt='' />
            <div style={{ textAlign: 'left' }}>
              <Typography variant='body1' className='survey-not-found-typography'>
                {strings.not_found_could_be_due_to}
              </Typography>
              <div className='survey-not-found-text-list' style={{ margin: '0.9rem auto' }}>
                <Typography variant='body1' className='survey-not-found-typography'>
                  1. {strings.not_found_you_already_took}
                </Typography>
                <Typography variant='body1' className='survey-not-found-typography'>
                  2. {strings.not_found_survey_was_cancelled}
                </Typography>
              </div>
              <Typography variant='body1' className='survey-not-found-typography'>
                {strings.not_found_contact_creator}
              </Typography>
            </div>
          </div>
        </Fade>
      )
  }

  const renderLinearRow = () => {
    if (!takeVM) return
    if (takeVM.questionsFlowFormat !== 'paged') return
    return <LinearNavRow takeVM={takeVM} />
  }

  const renderSubmitRow = () => {
    if (!takeVM) return
    if (disabled) return null
    if (takeVM.questionsFlowFormat === 'paged') return
    return <SubmitRow takeVM={takeVM} />
  }

  const renderSurveyIntroduction = () => {
    if (!takeVM) return
    if (!takeVM.hasSurveyIntroduction) return null
    if (takeVM.questionsFlowFormat === 'paged' && !takeVM.showSurveyIntroductionPage) return null
    return (
      <Card className='survey-introduction-card' elevation={0}>
        <div
          className='survey-introduction-text'
          dangerouslySetInnerHTML={{ __html: takeVM.surveyIntroduction }}
        ></div>
      </Card>
    )
  }

  const renderAllQuestions = () => {
    return takeVM.allQuestions.map((q, idx) => (
      <Fragment key={'questionrow' + idx}>
        {q.isShown && (
          <>
            {q.type === 'infoText' ? (
              <InfoTextRow takeVM={takeVM} question={q} index={idx} />
            ) : (
              <Question takeVM={takeVM} question={q} index={idx} />
            )}
            <QuestionRowDivider question={q} />
          </>
        )}
      </Fragment>
    ))
  }

  const renderBranchQuestions = () => {
    return takeVM.currentBranchQuestions.map((q, idx) => (
      <Fragment key={'questionrow' + idx}>
        {q.isShown && (
          <>
            {q.type === 'infoText' ? (
              <InfoTextRow takeVM={takeVM} question={q} index={idx} />
            ) : (
              <Question takeVM={takeVM} question={q} index={idx} />
            )}
            <QuestionRowDivider question={q} />
          </>
        )}
      </Fragment>
    ))
  }

  const renderContent = () => {
    if (!takeVM) return
    if (takeVM.questionsFlowFormat === 'paged' && takeVM.showSurveyIntroductionPage) return null
    if (takeVM.currentBranchQuestions.length) return renderBranchQuestions()
    if (takeVM.allQuestions.length) return renderAllQuestions()
  }

  return (
    <div id='UserSurveyWidgetContent'>
      <CardContent>
        {renderLoading()}
        {renderNotFound()}
        {renderSurveyIntroduction()}
        {renderContent()}
        {renderLinearRow()}
        {renderSubmitRow()}
      </CardContent>
    </div>
  )
}

export default inject('userSurveysStore', 'localizationStore')(observer(WidgetContent))
