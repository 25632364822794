import { computed, observable, action } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { CategoriesStore } from '../../../app/categories/CategoriesStore'
import { Category } from '../../categories/aggregate/Category'
import { QuestionVM } from '../../surveys/view-models/QuestionVM'

interface CategoryForPicker {
  id: string
  name: string
  parentId: string
  label: string
  value: string
  checked: boolean
  expanded?: boolean
}

export class CategoryPickerVM {
  private rootStore: RootStore
  private withSelectAll: boolean = false
  private withNoCategoryOption: boolean = false

  constructor(
    rootStore: RootStore,
    withSelectAll?: boolean,
    selectAllByDefault?: boolean,
    withNoCategoryOption?: boolean,
    categoryIdsToHide?: Array<string>,
    applyAllByDefault?: boolean
  ) {
    this.rootStore = rootStore
    this.withSelectAll = Boolean(withSelectAll)
    if (selectAllByDefault) {
      this.selectAllChecked = Boolean(selectAllByDefault)
      // setTimeout(() => {
      //   this.selectedCategoryIds = this.validCategories.map((cat) => cat.objectId)
      //   if (withNoCategoryOption && selectAllByDefault) this.selectedCategoryIds.push('no-category')
      //   if (applyAllByDefault) this.setAppliedCategories()
      // }, 2000)
    }
    this.withNoCategoryOption = Boolean(withNoCategoryOption)

    if (withNoCategoryOption && selectAllByDefault)
      if (categoryIdsToHide)
        // setTimeout(() => this.selectedCategoryIds.push('no-category'), 2000)
        this.categoryIdsToHide = categoryIdsToHide
  }

  @observable public showPicker: boolean = false
  @observable public appliedCategoryId: string = ''
  @observable public selectedCategoryId: string = ''
  @observable public selectedCategoryIds: Array<string> = []
  @observable public appliedCategoryIds: string[] = []
  @observable public expandedCategoryIds: Array<string> = []
  @observable public currentQuestionVM: QuestionVM = null
  @observable public selectAllChecked: boolean = false
  @observable public categoryIdsToHide: Array<string> = []

  @action
  public setCategoryIdsToHide(ids: Array<string>) {
    this.categoryIdsToHide = ids
  }

  @action
  public setCurrentQuestionVM(questionVM: QuestionVM) {
    this.currentQuestionVM = questionVM
  }

  @computed
  public get appliedCategory() {
    if (this.currentQuestionVM) {
      const foundCat = this.validCategories.find(
        (cat) => cat.objectId === this.currentQuestionVM.categoryId
      )
      if (foundCat) return foundCat
      else return null
    }
    if (this.appliedCategoryId) {
      const foundCat = this.validCategories.find((cat) => cat.objectId === this.appliedCategoryId)
      if (foundCat) return foundCat
    }
    return null
  }

  @computed
  public get appliedCategories() {
    let categoryNames = []
    this.appliedCategoryIds.forEach((id) => {
      const foundCat = this.validCategories.find((cat) => cat.objectId === id)
      if (foundCat) categoryNames.push({ id: id, name: foundCat.name })
    })

    return categoryNames
  }

  @computed
  public get appliedCategoryName() {
    if (this.appliedCategory) return this.appliedCategory.name
    else return `No ${this.categoryLabel} Selected`
  }

  @computed
  public get categoryLabel() {
    const label = this.rootStore.labelsStore.getLabelByLanguageAndFor('category')
    if (label) return label
    return 'Category'
  }

  @action
  public setSelectedCategoryId(id) {
    this.selectedCategoryId = id
    this.expandedCategoryIds.push(id)
    const node = this.formattedCategories.filter((cat) => cat.id === id)[0]
    node.expanded = true
    node.checked = true
  }

  @action
  public setAppliedCategory(id) {
    this.appliedCategoryId = id
  }

  @action
  public toggleShowPicker() {
    this.showPicker = !this.showPicker
  }

  @action
  public setAppliedCategories() {
    this.appliedCategoryIds = this.selectedCategoryIds.slice()
  }

  @action
  public handleSelectAll(shouldSelectAll: boolean) {
    this.selectAllChecked = shouldSelectAll
    if (shouldSelectAll) {
      this.selectedCategoryIds = []
      return
    }
  }

  @action
  public removeCategory(id: string) {
    let foundCat = this.formattedCategories.find((cat) => cat.id === id)
    foundCat.checked = false
    this.handleSelectOne(foundCat, false)
    this.setAppliedCategories()
  }

  @action
  public selectAllChildren(selected) {
    const selectChildren = (children) => {
      children.forEach((child) => {
        const foundCategory = this.validCategories.find((cat) => cat.objectId === child)
        if (foundCategory) this.selectedCategoryIds.push(foundCategory.objectId)
        if (foundCategory.children.length)
          selectChildren(foundCategory.children.map((child) => child.objectId))
      })
    }
    if (selected._children.length) selectChildren(selected._children)
  }

  @action
  public deselectAllChildren(selected) {
    const deselectChildren = (children) => {
      children.forEach((child) => {
        const foundCategory = this.validCategories.find((cat) => cat.objectId === child)
        if (foundCategory)
          this.selectedCategoryIds = this.selectedCategoryIds.filter(
            (id) => id !== foundCategory.objectId
          )
        if (foundCategory.children.length)
          deselectChildren(foundCategory.children.map((child) => child.objectId))
      })
    }
    if (selected._children.length) deselectChildren(selected._children)
  }

  @action
  public handleSelectOne(selected, selectAll) {
    if (selected.id === 'select-all') return this.handleSelectAll(selected.checked)
    if (selected.checked) {
      this.selectedCategoryIds.push(selected.id)
      if (Array.isArray(selected._children) && selectAll) return this.selectAllChildren(selected)
    }
    if (!selected.checked) {
      this.selectedCategoryIds = this.selectedCategoryIds.filter((id) => id !== selected.id)
    }
    this.selectAllChecked = false
    if (Array.isArray(selected._children)) return this.deselectAllChildren(selected)
  }

  @action
  public handleChange(selected) {
    if (selected.id === 'select-all') return this.handleSelectAll(selected.checked)
    this.handleSelectOne(selected, true)
  }

  @computed
  public get validCategories(): Array<Category> {
    return this.rootStore.categoriesStore.categories.slice().filter((cat) => !cat.isDeleted)
  }

  @action
  public handleNodeToggle(node) {
    if (node.expanded) {
      this.expandedCategoryIds.push(node.id)
    } else {
      // collapse node and its children (looks wonky if children allowed to stay expanded)
      this.expandedCategoryIds = this.expandedCategoryIds.filter((id) => id !== node.id)
      const foundCategory = this.validCategories.find((cat) => cat.objectId === node.id)
      if (foundCategory && foundCategory.children && foundCategory.children.length) {
        const childrenIds = foundCategory.children.map((child) => child.objectId)
        childrenIds.forEach((id) => {
          this.handleNodeToggle({ id, expanded: false })
        })
      }
    }
  }

  @computed
  public get formattedCategories() {
    let cats: Array<CategoryForPicker> = this.validCategories
      .sort((a: Category, b: Category) => {
        const nameA = a.name.toLowerCase()
        const nameB = b.name.toLowerCase()
        if (nameA < nameB) return -1
        if (nameB < nameA) return 1
        return 0
      })
      .map((e: Category) => ({
        id: e.objectId,
        name: e.name,
        parentId: e.parentCategoryId,
        label: e.name,
        value: e.objectId,
        checked: this.selectAllChecked ? false : this.selectedCategoryIds.includes(e.objectId),
        expanded: this.expandedCategoryIds.includes(e.objectId),
      }))

    if (this.categoryIdsToHide.length > 0) {
      cats = cats.filter((cat) => !this.categoryIdsToHide.includes(cat.id))
    }

    if (this.withNoCategoryOption) {
      const label = this.categoryLabel[0].toUpperCase() + this.categoryLabel.slice(1)

      const noCategory = {
        id: 'no-category',
        name: `No ${label}`,
        parentId: null,
        label: `No ${label}`,
        value: 'no-category',
        checked: this.selectAllChecked ? false : this.selectedCategoryIds.includes('no-category'),
      }

      cats.unshift(noCategory)
    }

    if (this.withSelectAll) {
      const selectAll = {
        id: 'select-all',
        name: 'Select All',
        parentId: null,
        label: 'Select All',
        value: 'select-all',
        checked: this.selectAllChecked,
      }

      cats.unshift(selectAll)
    }

    // if (this.selectAllChecked)
    //   cats.forEach((cat) => {
    //     cat.checked = true
    //   })

    return cats
  }

  private listToTree(list) {
    const map = {}
    const roots = []
    for (let i = 0; i < list.length; i++) {
      map[list[i].id] = i
      list[i].children = []
    }
    for (let i = 0; i < list.length; i++) {
      const node = list[i]
      if (node.parentId) {
        list[map[node.parentId]].children.push(node)
      } else {
        roots.push(node)
      }
    }
    return roots
  }

  @computed
  public get data() {
    return this.listToTree(this.formattedCategories)
  }
}
