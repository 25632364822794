import { RootStore } from '../../stores/RootStore'
import Parse from 'parse'
import { IOrganizationUserAudienceSearchRequest } from '../../organization-users/interfaces/IOrganizationUserAudienceSearchRequest'
import { IOrganizationUsersAudienceRoleSearchResult } from '../../organization-users/interfaces/IOrganizationUsersAudienceRoleSearchResult'
import { IOrganizationUsersAudienceRoleSearchRequest } from '../../organization-users/interfaces/IOrganizationUsersAudienceRoleSearchRequest'

export class AudienceMemberService {
  rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public async getUserAudience() {
    if (process.env.NODE_ENV === 'test') return []
    const result = await Parse.Cloud.run('getUserAudience', {
      orgId: this.rootStore.appStore.currentOrgId,
      includeUserRolesAndGroups: false
    })
    return result
  }
}
