import { observable, action } from 'mobx'
import { serializable } from 'serializr'

export class Days {
  @serializable @observable public monday: boolean = false
  @serializable @observable public tuesday: boolean = false
  @serializable @observable public wednesday: boolean = false
  @serializable @observable public thursday: boolean = false
  @serializable @observable public friday: boolean = false
  @serializable @observable public saturday: boolean = false
  @serializable @observable public sunday: boolean = false

  // @action
  // public setMon() {
  //   this.monday = !this.monday
  // }

  // @action
  // public setTues() {
  //   this.tuesday = !this.tuesday
  // }

  // @action
  // public setWed() {
  //   this.wednesday = !this.wednesday
  // }

  // @action
  // public setThurs() {
  //   this.thursday = !this.thursday
  // }

  // @action
  // public setFri() {
  //   this.friday = !this.friday
  // }

  // @action
  // public setSat() {
  //   this.saturday = !this.saturday
  // }

  // @action
  // public setSun() {
  //   this.sunday = !this.sunday
  // }
}
