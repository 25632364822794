import React from 'react'
import clsx from 'clsx'
import { inject, observer } from 'mobx-react'
import { IconButton, Tooltip } from '@material-ui/core'
import ListIcon from '@material-ui/icons/ListRounded'
import GridIcon from '@material-ui/icons/AppsRounded'
import LocalizationStore from '../../localization/LocalizationStore'
import { LabelsStore } from '../../labels/store/LabelsStore'
import { CMSItemsManageVM } from '../view-models/CMSItemsManageVM'
import './CMSItemsToggle.scss'

interface Props {
  vm: CMSItemsManageVM
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
}

const CMSItemsToggleLayout: React.FC<Props> = ({ vm, localizationStore, labelsStore }) => {
  const { lzStrings } = localizationStore

  const renderGridButton = () => {
    if (vm.isLayoutGrid) return
    return (
      <Tooltip title={'Change to Grid View'} placement='bottom-start'>
        <span>
          <IconButton
            disabled={vm.isLoading || vm.isSaving}
            onClick={() => vm.setLayoutIndex(0)}
            className={clsx('tab-button', {
              active: vm.layoutIndex === 0,
            })}
          >
            <GridIcon />
          </IconButton>
        </span>
      </Tooltip>
    )
  }

  const renderListButton = () => {
    if (!vm.isLayoutGrid) return
    return (
      <Tooltip title={'Change to List View'} placement='bottom-start'>
        <span>
          <IconButton
            disabled={vm.isLoading || vm.isSaving}
            onClick={() => vm.setLayoutIndex(1)}
            className={clsx('tab-button', {
              active: vm.layoutIndex === 1,
            })}
          >
            <ListIcon />
          </IconButton>
        </span>
      </Tooltip>
    )
  }

  return (
    <div className='content-toggle'>
      <div className='tab-btns'>
        {renderGridButton()}
        {renderListButton()}
      </div>
    </div>
  )
}

export default inject('localizationStore', 'labelsStore')(observer(CMSItemsToggleLayout))
