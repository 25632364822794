import { IEventsParticipantsSearchRequest } from '../../events/intefaces/IEventsParticipantsSearchRequest'
import { EventsService } from '../../events/services/EventsService'
import { IOrganizationUserDTO } from '../../organization-users/dtos/IOrganizationUserDTO'
import { IOrganizationUserAudienceSearchRequest } from '../../organization-users/interfaces/IOrganizationUserAudienceSearchRequest'
import { OrganizationUsersService } from '../../organization-users/service/OrganizationUsersService'
import { RootStore } from '../../stores/RootStore'
import { EventParticipantGroupType } from '../types/EventParticipantGroupType'
import { ParticipantType } from '../types/ParticipantType'

export class UsersExpansionService {
  private rootStore: RootStore
  private recordsPerPage: number = 100
  private eventId: string
  private id: string
  private type: string
  private audienceUserId: string

  constructor(rootStore: RootStore, audienceUserId: string) {
    this.rootStore = rootStore
    this.audienceUserId = audienceUserId
  }

  public async getAllUsers(
    id: string,
    type: ParticipantType,
    eventId?: string
  ): Promise<IOrganizationUserDTO[]> {
    let allRecords = []
    let pageNumber = 1
    let rows = []
    this.id = id
    this.type = type
    this.eventId = eventId
    while (rows.length === this.recordsPerPage || pageNumber === 1) {
      const result = await this.getPage(pageNumber)
      rows = result.users
      allRecords = allRecords.concat(rows)
      pageNumber = pageNumber + 1
    }
    return allRecords
  }

  private async getPage(pageNumber: number) {
    if (this.type === 'from-event')
      return await this.getEventParticipantsListRecordsApiCall(pageNumber)
    return await this.getAudienceUsersListRecordsApiCall(pageNumber)
  }

  private async getEventParticipantsListRecordsApiCall(pageNumber: number) {
    const req: IEventsParticipantsSearchRequest = {
      organizationId: this.rootStore.appStore.currentOrgId,
      eventId: this.eventId,
      skip: this.recordsPerPage * (pageNumber - 1),
      limit: this.recordsPerPage,
      sortColumnName: 'fk_User.firstName',
      listColumns: ['fk_User', 'objectId', 'userId', 'isArchived'],
    }
    if ((this.id as EventParticipantGroupType) === 'goingYesAndMaybeUserIds') {
      req.goingYesUserIds = true
      req.goingMaybeUserIds = true
    }
    if ((this.id as EventParticipantGroupType) === 'goingYesUserIds') {
      req.goingYesUserIds = true
    }
    if ((this.id as EventParticipantGroupType) === 'goingNoUserIds') {
      req.goingNoUserIds = true
    }
    const svc = new EventsService()
    const result = await svc.searchEventsParticipants(req)
    return result
  }

  private async getAudienceUsersListRecordsApiCall(pageNumber: number) {
    const req: IOrganizationUserAudienceSearchRequest = {
      userId: this.audienceUserId || this.rootStore.appStore.currentUserId,
      orgId: this.rootStore.appStore.currentOrgId,
      skip: this.recordsPerPage * (pageNumber - 1),
      limit: this.recordsPerPage,
      sortColumnName: 'fk_User.firstName',
      sortDirection: 'asc',
      listColumns: ['fk_User', 'userId'],
      isArchived: false,
    }
    req[this.type + 'Id'] = this.id
    const svc = new OrganizationUsersService(this.rootStore)
    const result = await svc.searchAudienceUsers(req)
    return result
  }
}
