import { DataStore } from 'src/app/shared/data/DataStore'
import { AnnouncementParticipant } from '../aggregate/AnnouncementParticipant'
import { IAnnouncementParticipantDTO } from '../dtos/IAnnouncementParticipantDTO'
import { RootStore } from '../../stores/RootStore'
import { Announcement } from '../aggregate/Announcement'
import { IAnnouncementParticipantRequest } from '../interfaces/IAnnouncementParticipantsRequest'
import { AnnouncementsService } from '../service/AnnouncementsService'
import { computed } from 'mobx'
import { nameOf } from '../../shared/nameOf'

export default class AnnouncementParticipantsDataStore extends DataStore<
  AnnouncementParticipant,
  IAnnouncementParticipantDTO
> {
  private request: IAnnouncementParticipantRequest
  constructor(rootStore: RootStore, req: IAnnouncementParticipantRequest) {
    super(rootStore, AnnouncementParticipant, 'announcementParticipants', [
      nameOf<IAnnouncementParticipantDTO, string>((e) => e.objectId),
      nameOf<IAnnouncementParticipantDTO, string>((e) => e.announcementId),
      nameOf<IAnnouncementParticipantDTO, string>((e) => e.organizationId),
      nameOf<IAnnouncementParticipantDTO, string>((e) => e.userId),
      nameOf<IAnnouncementParticipantDTO, string>((e) => e.firstName),
      nameOf<IAnnouncementParticipantDTO, string>((e) => e.lastName),
      nameOf<IAnnouncementParticipantDTO, string>((e) => e.invitationCreated),
      nameOf<IAnnouncementParticipantDTO, string>((e) => e.createdEmailDeliverDate),
      nameOf<IAnnouncementParticipantDTO, string>((e) => e.createdEmailProcessedDate),
      nameOf<IAnnouncementParticipantDTO, string>((e) => e.createdEmailCreatedDate),
      nameOf<IAnnouncementParticipantDTO, string>((e) => e.createdEmailOpenDate),
      nameOf<IAnnouncementParticipantDTO, string>((e) => e.createdEmailDropDate),
      nameOf<IAnnouncementParticipantDTO, string>((e) => e.createdEmailClickDate),
      nameOf<IAnnouncementParticipantDTO, string>((e) => e.createdEmailBounceDate),
      nameOf<IAnnouncementParticipantDTO, string>((e) => e.createdEmailErrorDate),
      nameOf<IAnnouncementParticipantDTO, string>((e) => e.createdTextDate),
      nameOf<IAnnouncementParticipantDTO, string>((e) => e.createdPushDate),

      nameOf<IAnnouncementParticipantDTO, string>((e) => e.invitationUpdated),
      nameOf<IAnnouncementParticipantDTO, string>((e) => e.updatedEmailDeliverDate),
      nameOf<IAnnouncementParticipantDTO, string>((e) => e.updatedEmailProcessedDate),
      nameOf<IAnnouncementParticipantDTO, string>((e) => e.updatedEmailCreatedDate),
      nameOf<IAnnouncementParticipantDTO, string>((e) => e.updatedEmailOpenDate),
      nameOf<IAnnouncementParticipantDTO, string>((e) => e.updatedEmailDropDate),
      nameOf<IAnnouncementParticipantDTO, string>((e) => e.updatedEmailClickDate),
      nameOf<IAnnouncementParticipantDTO, string>((e) => e.updatedEmailBounceDate),
      nameOf<IAnnouncementParticipantDTO, string>((e) => e.updatedEmailErrorDate),
      nameOf<IAnnouncementParticipantDTO, string>((e) => e.updatedTextDate),
      nameOf<IAnnouncementParticipantDTO, string>((e) => e.updatedPushDate),
      nameOf<IAnnouncementParticipantDTO, string>((e) => e.viewedDate),
      nameOf<IAnnouncementParticipantDTO, string>((e) => e.likedDate),
      nameOf<IAnnouncementParticipantDTO, string>((e) => e.unlikedDate),
      nameOf<IAnnouncementParticipantDTO, string>((e) => e.removedDate),
    ])

    this.request = req
    this.paged = true
    this.recordsPerPage = 100
    this.listenToListRecordsViaApiUpdates = true
    this.sortColumnName = 'lastName'
  }

  public setRequest(request: IAnnouncementParticipantRequest) {
    this.request = request
  }

  public getRequest(): IAnnouncementParticipantRequest {
    return this.request
  }

  private getskipMultiplier() {
    if (this.pageNumber === 0) return 1
    return this.pageNumber
  }

  protected getListRecordsApiCall() {
    return async () => {
      const req: IAnnouncementParticipantRequest = {
        ...this.request,
        listColumns: this.listColumns,
        sortColumnName: this.sortColumnName,
        sortDirection: this.sortDirection,
        skip: this.recordsPerPage * (this.getskipMultiplier() - 1),
        limit: this.recordsPerPage,
        terms: this.filter,
      }
      const svc = new AnnouncementsService()
      let result = await svc.findAnnouncementParticipants(req)
      this.totalRecords = result.count
      return result.announcementParticipants
    }
  }

  @computed
  public get announcementParticipants() {
    return this.records
  }
}
