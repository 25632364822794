import React from 'react'
import { inject, observer } from 'mobx-react'
import { PrivilegeSetsStore } from '../store/PrivilegeSetsStore'
import { PrivilegesStore } from '../../stores/privileges/PrivilegesStore'
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Paper,
  Table,
} from '@material-ui/core'
import { LabelsStore } from '../../labels/store/LabelsStore'

interface Props {
  privilegeSetsStore?: PrivilegeSetsStore
  privilegesStore?: PrivilegesStore
  labelsStore?: LabelsStore
}

const PrivilegesTable: React.FC<Props> = ({ privilegeSetsStore, privilegesStore, labelsStore }) => {
  const { privilegeSetDrawerVM: vm } = privilegeSetsStore
  const { privileges } = privilegesStore

  const replaceDisplayText = (text) => {
    let newText = text
    newText = newText.replace('survey', labelsStore.getLabelByLanguageAndFor('pulse').toLowerCase())
    newText = newText.replace('Survey', labelsStore.getLabelByLanguageAndFor('pulse'))
    return newText
  }

  const renderTableHead = (
    <TableHead>
      <TableRow key='priv-table-header' className='temp-table'>
        <TableCell key='blank-cell'></TableCell>
        <TableCell key='name-cell'>Name</TableCell>
        <TableCell key='description-cell'>Description</TableCell>
      </TableRow>
    </TableHead>
  )

  const renderTableBody = (
    <TableBody>
      {vm.availablePrivilegs.map((priv) => {
        const row = (
          <TableRow key={priv.name + '-row'}>
            <TableCell key={priv.name + '-check'} className='checkbox-container'>
              <Checkbox
                color='primary'
                onChange={() => vm.toggleCheckedPriv(priv.objectId)}
                checked={vm.privileges.includes(priv.objectId)}
              />
            </TableCell>
            <TableCell key={priv.name + '-name'}>{replaceDisplayText(priv.name)}</TableCell>
            <TableCell key={priv.name + '-description'}>
              {replaceDisplayText(priv.longDescription)}
            </TableCell>
          </TableRow>
        )

        return row
      })}
    </TableBody>
  )

  return (
    <div id='PrivilegesTable'>
      <Paper elevation={0}>
        <Table size='small'>
          {renderTableHead}
          {renderTableBody}
        </Table>
      </Paper>
    </div>
  )
}

export default inject(
  'privilegeSetsStore',
  'privilegesStore',
  'labelsStore'
)(observer(PrivilegesTable))
