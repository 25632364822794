import { RootStore } from '../../stores/RootStore'
import { observable, action, computed } from 'mobx'
import { Announcement } from '../aggregate/Announcement'
import { AnnouncementEditVM } from '../view-models/AnnouncementEditVM'
import { AnnouncementsWidgetVM } from '../view-models/AnnouncementsWidgetVM'
import { IAnnouncementDTO } from '../dtos/IAnnouncementDTO'
import { DataStore } from '../../shared/data/DataStore'
import { AnnouncementsService } from '../service/AnnouncementsService'
import { IAnnouncementListFindRequest } from '../interfaces/IAnnouncementListFindRequest'

export class AnnouncementListDataStore extends DataStore<Announcement, IAnnouncementDTO> {
  private request: IAnnouncementListFindRequest
  constructor(rootStore: RootStore, forLoggedInUser?: boolean, sortField?, sortOrder?) {
    super(rootStore, Announcement, 'announcements', [
      'organizationId',
      'body',
      'title',
      'createdByUserId',
      'createdAt',
      'updatedAt',
      'participants',
      'attachments',
      'likes',
      'views',
      'fk_createdByUser',
      'numberOfParticipants',
      'updatedByUserId',
      'fk_updatedByUser',
      'status',
      'fk_ownerUser',
      'ownerUserId',
      'schedule',
    ])
    this.forLoggedInUser = forLoggedInUser
    this.paged = true
    this.recordsPerPage = 100
    this.listenToListRecordsViaApiUpdates = true
    this.sortColumnName = sortField
    this.sortDirection = sortOrder
    this.filterColumns = [
      'title',
      'fk_createdByUser.name',
      'fk_updatedByUser.name',
      'fk_ownerUser.name',
    ]
  }

  @observable public announcementsWidgetVM: AnnouncementsWidgetVM
  @observable public loaded: boolean = false
  @observable public forLoggedInUser: boolean = false

  @computed
  public get announcements(): Announcement[] {
    return this.records
  }

  private getskipMultiplier() {
    if (this.pageNumber === 0) return 1
    return this.pageNumber
  }

  protected getListRecordsApiCall() {
    return async () => {
      let forLoggedInUserId = undefined
      if (this.forLoggedInUser) forLoggedInUserId = this.rootStore.appStore.currentUserId
      const req: IAnnouncementListFindRequest = {
        ...this.request,
        organizationId: this.rootStore.appStore.currentOrgId,
        forLoggedInUserId: forLoggedInUserId,
        listColumns: this.listColumns,
        sortColumnName: this.sortColumnName,
        sortDirection: this.sortDirection,
        terms: this.filter,
        skip: this.recordsPerPage * (this.getskipMultiplier() - 1),
      }
      const svc = new AnnouncementsService()
      let result = await svc.findAnnouncements(req)
      this.totalRecords = result.count
      return result.announcements
    }
  }

  @action
  public onListRecordsLoaded() {
    if (!this.announcementsWidgetVM)
      this.announcementsWidgetVM = new AnnouncementsWidgetVM(this.rootStore)
    this.loaded = true
  }

  public onRecordUpdated(obj: Announcement) {
    if (!this.announcementsWidgetVM) return
    this.announcementsWidgetVM.forceRefresh()
    // return this.loadListRecords()
  }

  public setRootStore(root) {
    this.rootStore = root
  }

  public setForLoggedInUser() {
    this.forLoggedInUser = true
  }

  public getAnnouncement(objectId): Announcement {
    return this.announcements.find((announcement) => announcement.objectId === objectId)
  }

  public deleteAnnouncement(objectId) {
    this.announcements.splice(this.getAnnouncementIndex(objectId), 1)
  }

  private getAnnouncementIndex(objectId): number {
    return this.announcements.findIndex((e) => e.objectId === objectId)
  }
}
