import React from 'react'
import { inject, observer, useLocalStore } from 'mobx-react'
import rootStore from '../../stores/RootStore'
import './PulseCampaignTypesManage.scss'
import { PulseCampaignTypesManageVM } from '../view-models/PulseCampaignTypesManageVM'
import TopToolbar from './TopToolbar'
import PulseCampaignTypesList from './PulseCampaignTypesList'

const PulseCampaignTypesManage: React.FC = () => {
  const localStore = useLocalStore(() => ({
    vm: new PulseCampaignTypesManageVM(rootStore),
  }))

  const vm = localStore.vm
  return (
    <div id='PulseCampaignTypesManage'>
      <div className='pulse-campaign-types-container'>
        <TopToolbar vm={vm} />
        <PulseCampaignTypesList vm={vm} />
      </div>
    </div>
  )
}

export default inject()(observer(PulseCampaignTypesManage))
