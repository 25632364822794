import { RootStore } from '../../stores/RootStore'
import { DataStore } from '../../shared/data/DataStore'
import { OrganizationUser } from '../aggregate/OrganizationUser'
import { IOrganizationUserDTO } from '../dtos/IOrganizationUserDTO'
import { action, observable } from 'mobx'
import { OrganizationUserEditVM } from '../view-models/drawer/OrganizationUserEditVM'
import { MyAudienceWidgetVM } from '../view-models/my-audience-widget/MyAudienceWidgetVM'
import { OrganizationUserPasswordEditVM } from '../view-models/password-drawer/OrganizationUserPasswordEditVM'
import { SurveyStreakVM } from '../../survey-streak/view-models/SurveyStreakVM'
import { PasswordSnackbarVM } from '../views/password-snackbar/PasswordSnackbarVM'

export class OrganizationUsersStore extends DataStore<OrganizationUser, IOrganizationUserDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, OrganizationUser, 'organizationUsers', [])
    this.surveyStreakVM = new SurveyStreakVM(this.rootStore)
    this.pwSnackbarVM = new PasswordSnackbarVM()
  }

  protected getFullColumns() {
    return [
      'objectId',
      'organizationId',
      'roles',
      'groups',
      'title',
      'primaryRoleId',
      'User',
      'privilegeSets',
      'superiors',
      'subordinates',
      'userId',
      'roleStartDate',
      'employeeId',
      'primaryGroupId',
      'jobNumber',
      'additionalAudienceMembers',
      'bypassOIDCAuth',
      'isArchived',
      'archivedDate',
      'isDisabled',
      'disabled',
      'surveyStreak',
      'tableauLicenseType',
      'tableauLicensePermanent'
    ]
  }

  @observable public editVM: OrganizationUserEditVM = null
  @observable public pwSnackbarVM: PasswordSnackbarVM = null
  @observable public editPasswordVM: OrganizationUserPasswordEditVM = null
  @observable public surveyStreakVM: SurveyStreakVM = null

  public get currentOrganizationUsers() {
    return this.records
  }

  public async loadPasswordEditVM(objectId: string) {
    this.editPasswordVM = new OrganizationUserPasswordEditVM(this.rootStore)
    await this.editPasswordVM.loadUser(objectId)
  }

  public async loadEditVM(objectId: string) {
    this.editVM = new OrganizationUserEditVM(this.rootStore)
    await this.editVM.loadUser(objectId)
  }

  public async loadSurveyStreakVM() {
    this.surveyStreakVM = new SurveyStreakVM(this.rootStore)
    await this.surveyStreakVM.loadUser()
  }

  public loadEditVMForNewUser() {
    this.editVM = new OrganizationUserEditVM(this.rootStore)
    this.editVM.loadNewUser()
  }

  public async reloadEditVM() {
    const userId = this.editVM.objectId
    this.editVM = new OrganizationUserEditVM(this.rootStore)
    await this.editVM.loadUser(userId)
  }

  public async reloadPasswordEditVM() {
    const userId = this.editPasswordVM.objectId
    this.editPasswordVM = new OrganizationUserPasswordEditVM(this.rootStore)
    await this.editPasswordVM.loadUser(userId)
  }

  // public getCurrentUser() {
  //   const currentUser = this.currentOrganizationUsers.filter(
  //     (e) => e.userId === this.rootStore.appStore.currentUserId
  //   )
  //   return currentUser
  // }
}
