import { action, computed, observable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import Parse from 'parse'
import { NotificationTemplate } from './aggregates/NotificationTemplate'
import { EditVM } from './view-models/EditVM'
import { NotificationsWidgetVM } from './view-models/NotificationsWidgetVM'
import { DataStore } from '../../shared/data/DataStore'
import { INotificationTemplateDTO } from './model/INotificationTemplateDTO'
import { deserialize } from 'serializr'

export class NotificationsStore extends DataStore<NotificationTemplate, INotificationTemplateDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, NotificationTemplate, 'notificationTemplates', [
      'organizationId',
      'channel',
      'subject',
      'body',
      'notificationTypeId',
      'language',
      'isDefault',
    ])
    this.editVM = new EditVM(this.rootStore)
  }
  @observable public editVM: EditVM
  @observable public loaded: boolean = false

  @computed
  public get notificationTemplates(): NotificationTemplate[] {
    return this.records
  }

  @action
  public onListRecordsLoaded() {
    this.loaded = true
  }

  protected getBaseQuery() {
    const query = new Parse.Query(this.className)
    query.containedIn('organizationId', [this.rootStore.appStore.currentOrgId, 'DEFAULT'])
    query.notEqualTo('isDeleted', true)
    return query
  }

  @computed
  public get defaultNotificationTemplates(): NotificationTemplate[] {
    return this.notificationTemplates
      .filter((notification) => notification.isDefault === true)
      .filter((notification) => this.userLanguages.includes(notification.language))
  }

  @computed
  public get userLanguages(): string[] {
    if (!this.rootStore.organizationsStore.currentOrganization) return []
    const languages = []
    if (this.rootStore.organizationsStore.currentOrganization.hasEnglish) languages.push('en')
    if (this.rootStore.organizationsStore.currentOrganization.hasGerman) languages.push('de')
    return languages
  }

  @computed
  public get customNotificationTemplates(): NotificationTemplate[] {
    return this.notificationTemplates.filter(
      (notification) =>
        notification.isDefault === false &&
        notification.organizationId === this.rootStore.appStore.currentOrgId &&
        this.userLanguages.includes(notification.language)
    )
  }

  @computed
  public get currentOrgNotificationTemplates(): NotificationTemplate[] {
    const list = this.customNotificationTemplates.filter((notification) =>
      this.userLanguages.includes(notification.language)
    )
    this.defaultNotificationTemplates.forEach((n) => {
      const index = list.findIndex(
        (e) =>
          e.notificationTypeId === n.notificationTypeId &&
          e.language === n.language &&
          e.channel === n.channel
      )
      if (index === -1) list.push(n)
    })
    return list
  }

  @computed
  public get currentOrgDefaultNotificationTemplates(): NotificationTemplate[] {
    return this.defaultNotificationTemplates
  }

  public initWidgetForm(id, isSystemEdit: boolean = false) {
    //Find the corresonding notification object
    const notification = this.notificationTemplates.filter(
      (notification) => notification.objectId === id
    )[0]
    this.editVM.populate(notification, isSystemEdit)
    this.editVM.openEditor()
  }
}
