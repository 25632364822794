import { computed, action, observable, when } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { UserInfoPromptService } from '../service/UserInfoPromptService'
import { IAPIResponse } from '../../shared/IAPIResponse'
import User from '../../user/aggregate/User'

export class UserInfoPromptVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore

    when(
      () => this.loaded,
      () => {
        if (!this.rootStore.userStore.user) return
        const user = this.rootStore.userStore.user.clone()
        this.cleanUser = user
        this.email = user.email
        this.phone = user.phone
        this.username = user.username
        this.userInfoPromptRead = user.userInfoPromptRead
      }
    )
  }

  @observable public userInfoPromptOpen: boolean = true
  @observable public email: string = ''
  @observable public phone: string = ''
  @observable public username: string = ''
  @observable public cleanUser: User = null
  @observable public saveTried: boolean = false
  @observable public userInfoPromptRead: boolean = false
  @observable public errorMessage: string = ''

  @computed
  public get loaded(): boolean {
    if (!this.rootStore.appStore.currentOrgId) return false
    if (!this.rootStore.userStore) return false
    if (!this.rootStore.userStore.loaded) return false
    return true
  }

  @computed
  public get showPrompt(): boolean {
    if (!this.loaded) return false
    if (this.username && this.username.includes('@')) return false
    const url = window.location.href
    if (url.includes('consent')) return false
    if (this.userInfoPromptRead) return false
    if ( this.cleanUser.email && this.cleanUser.phone) return false
    return true
  }

  @action
  public toggleUserInfoPrompt() {
    this.userInfoPromptOpen = !this.userInfoPromptOpen
  }

  @action
  public setEmailAddress(value) {
    this.email = value
  }

  @action
  public setPhoneNumber(value) {
    this.phone = value
  }

  @computed
  public get phoneValid() {
    if (!this.saveTried) return true
    if (this.phone && this.phone.trim() === '') return false
    if (this.phone && this.phone.length < 10) return false
    return true
  }

  @computed
  public get emailValid() {
    if (!this.saveTried) return true
    let pattern = new RegExp(/[^\s@]+@[^\s@]+\.[^\s@]+/i)
    return pattern.test(this.email)
  }

  @computed
  public get userId() {
    return this.rootStore.appStore.currentUserId
  }

  @computed
  public get orgId() {
    return this.rootStore.appStore.currentOrgId
  }

  @computed
  public get isEmailTaken(): boolean {
    return Boolean(
      this.rootStore.usersStore.orgUsers.filter(
        (user) =>
          user.email !== '' &&
          !user.isDisabled &&
          user.email === this.email &&
          user.objectId !== this.userId
      )[0]
    )
  }

  @action
  public async saveEmailAndPhone() {
    this.saveTried = true
    this.errorMessage = ''
    if (!this.phoneValid || !this.emailValid) return
    const svc = new UserInfoPromptService()
    let result: IAPIResponse
    try {
      result = await svc.saveEmailAndPhone(this.userId, this.email, this.phone)
      if (result.success) {
        this.markAsRead()
        this.toggleUserInfoPrompt()
      } else {
        // show errors and retry
        this.errorMessage = result.errorMessage
        console.log(result.errorMessage)
      }
    } catch (e) {
      console.error(e)
    }
  }

  @action
  public async markAsRead() {
    const svc = new UserInfoPromptService()
    let result
    try {
      result = await svc.markAsRead(this.userId, this.orgId)
    } catch (e) {
      console.error(e)
    }
  }

  @action
  public close() {
    this.markAsRead()
    this.toggleUserInfoPrompt()
  }
}
