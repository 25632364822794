import React from 'react'
import { observer, useLocalStore } from 'mobx-react'
import { Grid, LinearProgress, Typography } from '@material-ui/core'
import './PolarityScale.scss'
import { PolarityScaleVM } from './PolarityScaleVM'

interface Props {
  polarity?: number // if no polarity value, will render shaded back empty scale
  showScale: boolean //show -100>0>100
  fontSize: number // size of polarity text font
  arrowColor: boolean // polarity text and arrow colored or nah?
}

const PolarityScale: React.FC<Props> = ({ polarity, showScale, fontSize, arrowColor }) => {
  const localStore = useLocalStore(() => ({
    vm: new PolarityScaleVM(polarity, showScale, arrowColor),
  }))

  const vm = localStore.vm
  if (!vm) return null

  if (!polarity && polarity !== 0) {
    return (
      <Grid container direction='row' justifyContent='center' id='PolarityScale'>
        <Grid container className='polarity-scale-cont'>
          <Typography className='polarity-scale'>-100</Typography>
          <Typography className='polarity-scale'>0</Typography>
          <Typography className='polarity-scale'>100</Typography>
        </Grid>
        <LinearProgress variant='determinate' value={0} className='no-polarity' />
      </Grid>
    )
  }

  return (
    <Grid container direction='row' justifyContent='center' id='PolarityScale'>
      <span
        className='polarity-text'
        key={'arrow' + vm.calculatedPolarity}
        style={{
          fontSize: `${fontSize}pt`,
          marginLeft: `${vm.calculatedPolarity}%`,
          color: vm.polarityColor,
        }}
      >
        {polarity}
        <div
          className='arrow-down'
          style={{
            borderTop: `8px solid ${vm.arrowColored}`,
            marginLeft: vm.arrowMargin,
          }}
        ></div>
      </span>

      <Grid container className='polarity-scale-cont'>
        <Typography
          className={vm.hideNegativeHundo ? 'polarity-scale hide-scale' : 'polarity-scale'}
        >
          -100
        </Typography>
        <Typography className={vm.hideZero ? 'polarity-scale hide-scale' : 'polarity-scale'}>
          0
        </Typography>
        <Typography className={vm.hideHundo ? 'polarity-scale hide-scale' : 'polarity-scale'}>
          100
        </Typography>
      </Grid>
      <LinearProgress variant='determinate' value={0} className='polarity-bar' />
    </Grid>
  )
}

export default observer(PolarityScale)
