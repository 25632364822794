import React from 'react'
import { inject, observer } from 'mobx-react'
import { Button, Card, CircularProgress, Grid, Typography } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import rootStore from '../../../stores/RootStore'
import LocalizationStore from '../../../localization/LocalizationStore'
import PulseSetsFilter from '../../../pulse-sets/views/PulseSetsFilter'
import { PulseCampaign } from '../../aggregate/PulseCampaign'
import { PulseCategoriesManageVM } from '../../view-models/PulseCategoriesManageVM'
import { PulseCampaignTemplatesVM } from '../../view-models/PulseCampaignTemplatesVM'
import { PulseSetFilterRowVM } from '../../../pulse-sets/view-models/PulseSetFilterRowVM'
import './PulseCampaignTemplateSelector.scss'

interface Props {
  manageVM: PulseCategoriesManageVM
  templatesVM: PulseCampaignTemplatesVM
  localizationStore?: LocalizationStore
}

const PulseCampaignTemplateSelector: React.FC<Props> = ({
  manageVM,
  templatesVM,
  localizationStore,
}) => {
  const { pulseCampaign: lz } = localizationStore.lzStrings
  if (!manageVM) return null
  const labelsStore = rootStore.labelsStore

  const renderSetsLabel = (sets: PulseSetFilterRowVM[]) => {
    if (!sets) return
    if (sets.length === 0) return
    if (sets.length === manageVM.pulseSetsFilterVM.rows.length)
      return <Typography className='template-sets-filter-label-text'>{lz.all_sets}</Typography>

    return sets.map((row, index) => {
      const seperator = sets.length > 1 && index < sets.length - 1 ? ',' : ''
      return (
        <Typography
          key={`${row.name}-${row.objectId}`}
          className='template-sets-filter-label-text'
        >{`${row.name}${seperator}`}</Typography>
      )
    })
  }

  const renderSetsFilter = () => {
    const filtered = manageVM.pulseSetsFilterVM.rows.filter((row) => row.isChecked)
    return (
      <Grid item className='template-sets-filter'>
        <PulseSetsFilter vm={manageVM.pulseSetsFilterVM} />
        <div className='template-sets-filter-label-container'>
          <div className='template-sets-filter-label-item'>{renderSetsLabel(filtered)}</div>
        </div>
      </Grid>
    )
  }

  const renderSpinner = () => {
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  const renderTemplate = (template: PulseCampaign) => {
    const indicator =
      templatesVM.selectedTemplate && templatesVM.selectedTemplate.objectId === template.objectId
        ? 'selected'
        : 'unselected'
    return (
      <Grid item key={`template-${template.objectId}-${indicator}`} className='template-grid-item'>
        <Button
          className={`tab-btn-${indicator}`}
          key={`template-btn-${template.objectId}`}
          fullWidth
          value={template.objectId}
          onClick={() => templatesVM.handleSelectTemplate(template, true)}
        >
          <Card className='template-card'>
            <div className='template-card-left'>
              <Typography className='template-card-text'>{template.name}</Typography>
              <Typography className='template-card-subtext'>{`${
                template.surveys.length
              } ${labelsStore.getLabelByLanguageAndFor('pulses')}`}</Typography>
            </div>
            <div className='template-card-right'>
              {indicator === 'selected' ? <CheckIcon className='check-icon' /> : undefined}
            </div>
          </Card>
        </Button>
      </Grid>
    )
  }

  const renderTemplates = () => {
    if (!templatesVM.isLoaded) return renderSpinner()

    return templatesVM.templates.slice().map((template, index) => {
      return renderTemplate(template)
    })
  }

  return (
    <>
      <div id='PulseCampaignTemplateSelector'>
        <Grid
          container
          justifyContent='flex-start'
          alignItems='flex-start'
          className={'templates-grid'}
        >
          <Grid item className={'templates-grid-header'}>
            <Grid
              container
              direction='column'
              justifyContent='flex-start'
              alignItems='flex-start'
              className={'templates-grid-header-grp'}
            >
              <Grid item>
                <Typography component='span' className='templates-grid-header-title'>
                  {lz.templates}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={'templates-grid'}>
            <Grid
              container
              direction='row'
              justifyContent='flex-start'
              alignItems='flex-start'
              className={'templates-grid-list'}
            >
              {renderTemplates()}
              <Button
                className={'create-btn'}
                variant='contained'
                onClick={() => {
                  templatesVM.createPulseTemplate()
                }}
              >
                {lz.create_template}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default inject(
  'pulseCampaignsStore',
  'localizationStore'
)(observer(PulseCampaignTemplateSelector))
