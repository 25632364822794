import React from 'react'
import { inject, observer, useLocalStore } from 'mobx-react'
import { CircularProgress, Grid } from '@material-ui/core'
import rootStore from '../../../stores/RootStore'
import OrganizationUsersSnackbar from '../snackbar/OrganizationUsersSnackbar'
import './OrganizationUsersImport.scss'
import OrganizationUsersImportHeader from './OrganizationUsersImportHeader'
import OrganizationUsersImportContent from './OrganizationUsersImportContent'
import { OrganizationUsersImportManageVM } from '../../view-models/importV2/OrganizationUsersImportManageVM'
import OrganizationUsersImportFooter from './OrganizationUsersImportFooter'

const OrganizationUsersImport: React.FC = () => {
  const localStore = useLocalStore(() => ({
    vm: new OrganizationUsersImportManageVM(rootStore),
  }))
  const vm = localStore.vm

  if (!vm) return

  const renderSpinner = () => {
    if (vm.shouldRender && !vm.isLoading) return
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  const renderContent = () => {
    if (vm.isLoading) return
    return (
      <Grid id='OrgUsersContent'>
        <OrganizationUsersImportHeader />
        <OrganizationUsersImportContent vm={vm.importVM} />
        <OrganizationUsersImportFooter vm={vm.importVM} />
      </Grid>
    )
  }

  return (
    <div id='OrgUsersImport'>
      {renderSpinner()}
      <div className='org-users-import-container'></div>
      {renderContent()}
      {/* <OrganizationUsersSnackbar vm={vm} /> */}
    </div>
  )
}

export default inject()(observer(OrganizationUsersImport))
