import { observable, computed, reaction } from 'mobx'
import Parse from 'parse'
import { RootStore } from '../../stores/RootStore'
import { Survey } from '../aggregate/Survey'
import { ISurveyDTO } from '../dtos/ISurveyDTO'
import { DataStore } from '../../shared/data/DataStore'
import { SurveysViewModelsService } from '../service/SurveysViewModelsService'
export class SurveysStore extends DataStore<Survey, ISurveyDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, Survey, 'surveys', []) // List columns not used
    this.viewModels = new SurveysViewModelsService(rootStore, this)
  }

  protected getFullColumns() {
    return [
      'categoryId',
      'publishedByUserId',
      'publishedByUserName',
      'linksNeverExpire',
      'participants',
      'participatingUsers',
      'feedbackParticipants',
      'iconURL',
      'questions',
      'anonymousParticipants',
      'emailTemplates',
      'anonymizeResults',
      'forceEmails',
      'sendEmailOnResponse',
      'forceTextMessages',
      'worksheetId',
      'eventId',
      'schedule',
      'dueAfter',
      'autoCancel',
      'alarms',
      'name',
      'published',
      'publishedDateTime',
      'surveyTypeId',
      'emailFromName',
      'emailFromAddress',
      'questionsFlowFormat',
      'lastResponseReceived',
      'closedByUserId',
      'feedbackForLocked',
      'surveyIntroduction',
      'templateOnlyEditableByOwner',
      'templateId',
      'isTemplate',
      'organizationId',
      'visibility',
      'visibleTo',
      'visibleParticipants',
      'isClosed',
      'showResults',
      'alwaysAvailable',
      'mainCategoryId',
      'surveyTagIds',
      'isAutoSaveDraft',
      'surveyCompletedContent',
      'pulseCampaignId',
      'sendEmailToAdditionalUsersOnResponse',
      'additionalResponseNotificationParticipants',
      'fk_publishedByUserId',
    ]
  }

  @observable public viewModels: SurveysViewModelsService = undefined

  protected getBaseQuery() {
    const query = new Parse.Query(this.className)
    query.equalTo('organizationId', this.rootStore.appStore.currentOrgId)
    query.notEqualTo('isDeleted', true)
    query.notEqualTo('isTemplate', true)
    return query
  }

  @computed
  public get currentOrgSurveys(): Survey[] {
    return this.records.filter((e) => e.organizationId === this.rootStore.appStore.currentOrgId)
  }

  public getSurvey(objectId: string): Survey {
    return this.getRecord(objectId)
  }
}
