import React from 'react'
import { inject, observer } from 'mobx-react'
import './SurveyParticipantsList.scss'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import { AgGridReact } from 'ag-grid-react'
import { Typography, CircularProgress } from '@material-ui/core'
import { CampaignDetailsVM } from '../../../view-models/dashboard/campaign-details/CampaignDetailsVM'

interface Props {
  campaignDetailsVM?: CampaignDetailsVM
  localizationStore?: LocalizationStore
}

const SurveyParticipantsList: React.FC<Props> = ({ campaignDetailsVM, localizationStore }) => {
  const detailsVM = campaignDetailsVM
  if (!detailsVM) return null
  const vm = detailsVM.surveyParticipantsList
  if (!vm) return
  const lz = localizationStore.lzStrings.pulseCampaignDashboard

  const renderGrid = () => {
    return (
      <div
        className={vm.hasRows ? 'ag-theme-alpine' : 'ag-theme-alpine ag-grid-no-invite-rows'}
        // style={{ height: '100%', width: '100%' }}
      >
        <AgGridReact
          key={'svylist_' + detailsVM.objectId}
          gridOptions={{
            ...vm.getGridOptions(),
          }}
        />
      </div>
    )
  }

  const renderNoRowsContent = () => {
    if (vm.hasRows) return
    if (vm.loadingFirstPage) return
    return (
      <Typography component='div' className='no-rows'>
        {lz.no_rows_to_show}
      </Typography>
    )
  }

  const renderLoading = () => {
    if (!vm.loadingFirstPage) return
    return (
      <Typography component='div' className='no-rows'>
        {lz.loading}
        <CircularProgress className='progressCircle' />
      </Typography>
    )
  }

  return (
    <div id='PulseCampaignParticipantsList' className='surveyParticipantsList'>
      {renderGrid()}
      {renderNoRowsContent()}
      {renderLoading()}
    </div>
  )
}

export default inject('localizationStore')(observer(SurveyParticipantsList))
