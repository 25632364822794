import React from 'react'
import { observer, inject } from 'mobx-react'
import { Grid, Typography } from '@material-ui/core'
import QuestionsList from './QuestionsList'
import Drawer from './drawer/Drawer'
import MuiDialogMessage from '../../shared/MuiDialogMessage'
import { QuizVM } from '../view-models/QuizVM'
import AddQuestionMenu from './AddQuestionMenu'

interface BuilderProps {
  quiz: QuizVM
}

const Builder = ({ quiz }: BuilderProps) => {
  if (!quiz) return null

  return (
    <Grid container spacing={0} id='Builder'>
      <MuiDialogMessage
        open={quiz.cantDeleteLastQuestion}
        onClose={() => quiz.toggleDeleteLastQuestion()}
        message='Cannot delete the last question on quiz.'
      />

      <Drawer quiz={quiz} />

      <Grid item xs={12} sm={9} md={9}>
        <div id='FlowDiagram'>
          <div id='container'>
            <div className='elements-container'>
              <div className='start-end-badge' id='start-badge'>
                <Typography>Start</Typography>
              </div>
              <QuestionsList questions={quiz.questions} quiz={quiz} />
              <AddQuestionMenu quiz={quiz} />
              <div className='start-end-badge' id='end-badge'>
                <Typography>End</Typography>
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  )
}

export default inject('surveysStore', 'questionsStore', 'localizationStore')(observer(Builder))
