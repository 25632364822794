import React from 'react'
import { observer, inject } from 'mobx-react'
import './InvitationsLinkDialog.scss'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import MuiConfirmPrompt from '../../../../../shared/MuiConfirmPrompt'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import { CampaignDetailsVM } from '../../../../view-models/dashboard/campaign-details/CampaignDetailsVM'
import { LabelsStore } from '../../../../../labels/store/LabelsStore'

interface Props {
  campaignDetailsVM?: CampaignDetailsVM
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
}

const CloseSurveyDialog: React.FC<Props> = ({
  campaignDetailsVM,
  localizationStore,
  labelsStore,
}) => {
  const detailsVM = campaignDetailsVM
  if (!detailsVM) return null
  const lz = localizationStore.lzStrings.surveyInvitationsList

  const renderMessage = () => {
    return `${lz.close} ${labelsStore.getLabelByLanguageAndFor('pulse')}: ${
      detailsVM.activeSurveyName
    } ${lz.and_delete_all_pending}`
  }

  return (
    <MuiConfirmPrompt
      confirmMessage={renderMessage()}
      onClose={() => detailsVM.toggleCloseSurveyDialog()}
      open={detailsVM.showCloseSurveyDialog}
      onConfirm={() => detailsVM.closeSurvey()}
      title={lz.close_campaign_pulse}
      icon={<WarningRoundedIcon className='warning-icon' />}
      cancelFullWidth={true}
    />
  )
}

export default inject('localizationStore', 'labelsStore')(observer(CloseSurveyDialog))
