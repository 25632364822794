import { Event } from '../aggregate/Event'
import { action, observable, computed, reaction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { IEventTypeDTO } from '../../event-types/store/dtos/IEventTypeDTO'
import { ICoordinates } from '../../components/MobX-Calendar-Map/Map'
import { ParticipantsSelectVM } from '../../participants-select/view-models/ParticipantsSelectVM'
import { AlarmVM } from './AlarmVM'
import { RepeatType } from '../types/RepeatTypes'
import { ILocationDTO } from '../../locations/dtos/ILocationDTO'
import { IResourceDTO } from '../../resources/store/dtos/IResourceDTO'
import { EventsService } from '../services/EventsService'
import { Participant } from '../aggregate/Participant'
import { WeekdayVM } from './WeekdayVM'
import { Location } from '../aggregate/Location'
import { LocationsService } from '../../locations/service/LocationsService'
import moment from 'moment'
import Resource from '../../resources/store/aggregate/Resource'
import { MediaItemsService } from '../../media-items/service/MediaItemsService'
import EventType from '../../event-types/store/aggregate/EventType'
import EventTypeEditForm from '../../event-types/store/forms/EventTypeEditForm'
import { Attachment } from '../../upload/aggregate/Attachment'
import { FileOpenService } from '../../upload/services/FileOpenService'
import { EventsCopyService } from '../services/EventsCopyService'
import { EventSendNotificationsVM } from './EventSendNotificationsVM'
import { isNumeric } from '../../shared/isNumeric'
import { AttachmentVM } from '../../attachments/view-models/AttachmentVM'
import { OldAttachmentVM } from '../../attachments/view-models/OldAttachmentVM'

export class EventEditVM {
  private rootStore: RootStore
  @observable private autoCompleteRef: any

  constructor(rootStore: RootStore, event: Event) {
    this.rootStore = rootStore
    this.event = event
    this.eventParticipants = event.participants
    if (this.event.schedule) this.hasEndDate = this.event.schedule.endingMode
    this.participantsSelectVM = new ParticipantsSelectVM(rootStore, false, false)
    this.participantsSelectVM.setParticipants(this.eventParticipants)
    this.loadWeekdayRepeats()
    if (event.alarms && event.onTimeAlarm) this.filterForOnTimeAlarm()
    this.previousEndDate = this.event.localEndDate
  }

  @observable public editForm: EventTypeEditForm = null
  @observable public repeatOptions: Array<string> = ['daily', 'weekly', 'monthly', 'yearly']
  @observable public endingOptions: Array<string> = ['Never', 'Specific Date']
  @observable public tabIndex: number = 0
  @observable public isParticipantsTabOpen: boolean = false
  @observable public event: Event = null
  @observable public participantsSelectVM: ParticipantsSelectVM = null
  @observable public saveRequested: boolean = false
  @observable public saveFailed: boolean = false
  @observable public saveSuccessful: boolean = false
  @observable public hasEndDate: string = ''
  @observable public saveProcessing: boolean = false
  @observable public eventParticipants: Array<Participant> = []
  @observable public saveTried: boolean = false
  @observable public resourceSaveTried: boolean = false
  @observable public eventTypeSaveTried: boolean = false
  @observable public addNewLocation: boolean = false
  @observable public addNewResource: boolean = false
  @observable public addNewType: boolean = false
  @observable public newLocation: Location = new Location()
  @observable public newResource: Resource = null
  @observable public newEventType: EventType = null
  @observable public weekdayRepeats: Array<WeekdayVM> = []
  @observable public locationText: string = ''
  @observable public previousEndDate: Date = null
  @observable public showUploadModal: boolean = false
  @observable public eventSendNotificationsVM: EventSendNotificationsVM = null

  @action
  public setDatesToDefault() {
    let nextSlot = moment().startOf('minute')
    while (
      nextSlot.minute() !== 0 &&
      nextSlot.minute() !== 15 &&
      nextSlot.minute() !== 30 &&
      nextSlot.minute() !== 45
    ) {
      nextSlot.add(1, 'minute')
    }
    nextSlot.add(1, 'hour')
    this.event.setStartDate(nextSlot)
    this.event.setEndDate(moment(this.event.localStartDate).add(1, 'hour'))
  }

  @computed
  public get alarms(): Array<AlarmVM> {
    return this.event.alarms.map((alarm) => new AlarmVM(this.rootStore, this, alarm))
  }

  @computed
  public get onTimeAlarm(): boolean {
    return this.event.onTimeAlarm
  }

  @computed
  public get isNewEvent(): boolean {
    return Boolean(!this.event.objectId)
  }

  @computed
  public get scheduleEndDate(): Date {
    return this.event.scheduleEndDate
  }

  @computed
  public get objectId(): string {
    return this.event.objectId
  }

  @computed
  public get title(): string {
    return this.event.title
  }

  @computed
  public get allDay(): boolean {
    return this.event.allDay
  }

  @computed
  public get resourceValid() {
    if (!this.newResource.name && this.resourceSaveTried) return false
    return true
  }

  @computed
  public get titleValid(): boolean {
    if (!this.title && this.saveTried) return false
    return true
  }

  @action
  public toggleShowUploadModal() {
    this.showUploadModal = !this.showUploadModal
  }

  @computed
  public get startDateValid(): boolean {
    if (!this.saveTried) return true
    if (!this.localStartDate) return false
    if (moment(this.localStartDate).isSameOrAfter(moment(this.localEndDate).subtract(1, 'second')))
      return false
    return true
  }

  @computed
  public get endDateValid(): boolean {
    if (!this.saveTried) return true
    if (!this.localEndDate) return false
    if (moment(this.localStartDate).isSameOrAfter(moment(this.localEndDate).subtract(1, 'second')))
      return false
    return true
  }

  @computed
  public get participantsValid(): boolean {
    if (this.participantsSelectVM.participants.length === 0 && this.saveTried) return false
    return true
  }

  @computed
  public get saveEnabled(): boolean {
    if (!Boolean(this.title)) return false
    if (!this.saveProcessing) return false
    return true
  }

  @computed
  public get eventTypes(): Array<IEventTypeDTO> {
    return this.rootStore.eventTypesStore.currentOrgEventTypes.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    )
  }

  @computed
  public get selectedEventTypeOption(): IEventTypeDTO {
    return (
      this.rootStore.eventTypesStore.currentOrgEventTypes.filter(
        (eventType) => eventType.objectId === this.event.eventTypeId
      )[0] ?? null
    )
  }

  @computed
  public get locations(): Array<ILocationDTO> {
    return this.rootStore.locationsStore.locations.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    )
  }

  @computed
  public get selectedLocationOption(): ILocationDTO {
    return (
      this.rootStore.locationsStore.locations.find(
        (location) => location.objectId === this.event.locationId
      ) ?? null
    )
  }

  @computed
  public get selectedLocationOptionCoordinates(): ICoordinates {
    if (this.newLocation?.lat && this.newLocation?.lng) {
      return { lat: this.newLocation.lat, lng: this.newLocation.lng }
    } else if (this.selectedLocationOption?.lat && this.selectedLocationOption?.lng) {
      return { lat: this.selectedLocationOption.lat, lng: this.selectedLocationOption.lng }
    } else {
      return undefined //Uses default props on Map component
    }
  }

  @computed
  public get mapZoom(): number {
    if (this.newLocation?.lat && this.newLocation?.lng) {
      return 12
    } else if (this.selectedLocationOption?.lat && this.selectedLocationOption?.lng) {
      return 12
    } else {
      return undefined //Uses default props on Map component
    }
  }

  @computed
  public get resources(): Array<IResourceDTO> {
    return this.rootStore.resourcesStore.resources.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    )
  }

  @computed
  public get selectedResources(): Array<IResourceDTO> {
    const resources = []
    this.event.resources.forEach((e) => {
      const foundResource = this.rootStore.resourcesStore.resources.find(
        (r) => r.objectId === e.resourceId
      )
      if (foundResource) resources.push(foundResource)
    })
    return resources
  }

  @computed
  public get attachments(): AttachmentVM[] {
    return this.event.attachments.map((e, idx) => {
      if (isNumeric(e.objectId) || e.cmsItemId) {
        return this.rootStore.cmsItemAttachmentStore.loadAttachment(e)
      }
      return new OldAttachmentVM(this.rootStore, e, idx)
    })
  }

  @action
  public handleReset() {
    this.rootStore.eventsStore.lazyLoadEventEditVM(this.event.objectId)
  }

  @action
  public async generateSignature(callback: Function, paramsToSign: object): Promise<void> {
    const svc = new MediaItemsService()
    const signature = await svc.getCloudinarySignature(paramsToSign)
    callback(signature)
  }

  public async getThumbnailUrl(info) {
    const svc = new MediaItemsService()
    const thumbnailUrl = await svc.buildThumbnailURL(info.public_id, '.jpg', info.secure_url)
    return thumbnailUrl
  }

  @action
  public addAttachments(attachments: Attachment[]) {
    attachments.forEach((a) => this.event.addAttachmentsToEvent(a))
  }

  @action
  public deleteAttachmentFromTask(index: number) {
    this.event.deleteAttachment(index)
  }

  public async openAttachment(attachment: AttachmentVM) {
    attachment.openAttachment()
  }

  @action
  public toggleAddNewLocation() {
    this.addNewLocation = !this.addNewLocation
    if (!this.addNewLocation) {
      this.newLocation = new Location()
      return
    }
    this.newLocation.name = this.locationText
    this.fetchPredictions()
  }

  private fetchPredictions() {
    if (!this.autoCompleteRef) {
      setTimeout(() => this.fetchPredictions(), 100)
      return
    }
    this.autoCompleteRef.fetchPredictions()
  }

  @action
  public setNewResourceTitle(val: string) {
    this.newResource.name = val
  }

  @action
  public setNewResourceDescription(val: string) {
    this.newResource.description = val
  }

  @action
  public async saveNewResource() {
    this.resourceSaveTried = true
    if (!this.resourceValid) return
    const svc = new EventsService()
    const savedResourceId = await svc.saveResource(
      this.rootStore.appStore.currentOrgId,
      this.newResource
    )
    this.event.addResource(savedResourceId)
    this.toggleAddNewResource()
  }

  @action
  public setLocationText(val) {
    this.locationText = val
  }

  @action
  public handleOnTimeAlarmToggle() {
    this.event.toggleOnTimeAlarm()
  }

  @action
  public filterForOnTimeAlarm() {
    this.alarms.forEach((alarm, index) => alarm.setIndex(index))
    const onTimeAlarms = this.alarms.filter((e) => e.period === 'minutes' && e.units === 0)
    onTimeAlarms.forEach((alarm) => this.deleteAlarm(alarm.index))
  }

  @action
  public setNewLocation(address, latLng) {
    this.newLocation.setName(address)
    this.newLocation.setLat(latLng.lat)
    this.newLocation.setLng(latLng.lng)
  }

  @action
  private loadWeekdayRepeats() {
    this.weekdayRepeats.push(new WeekdayVM('Sun', 'sunday', this.event))
    this.weekdayRepeats.push(new WeekdayVM('Mon', 'monday', this.event))
    this.weekdayRepeats.push(new WeekdayVM('Tue', 'tuesday', this.event))
    this.weekdayRepeats.push(new WeekdayVM('Wed', 'wednesday', this.event))
    this.weekdayRepeats.push(new WeekdayVM('Thu', 'thursday', this.event))
    this.weekdayRepeats.push(new WeekdayVM('Fri', 'friday', this.event))
    this.weekdayRepeats.push(new WeekdayVM('Sat', 'saturday', this.event))
  }

  @computed
  public get selectedWeekdays(): Array<boolean> {
    const indexes = []
    this.weekdayRepeats.forEach((e, index) => {
      if (e.isChecked) indexes.push(index)
    })
    return indexes
  }

  @computed
  public get detailsTabValid(): boolean {
    if (!this.titleValid) return false
    if (!this.startDateValid) return false
    if (!this.endDateValid) return false
    if (!this.participantsValid) return false
    return true
  }

  @action
  public loadNotificationsVM(afterConfirm: Function) {
    this.eventSendNotificationsVM = new EventSendNotificationsVM(this.rootStore, afterConfirm)
    setTimeout(() => this.eventSendNotificationsVM.toggleShowNotificationsModal(), 500)
  }

  @action
  public trySave() {
    this.saveTried = true
    if (!this.detailsTabValid) return
    if (this.saveProcessing) return
    this.loadNotificationsVM(() => this.save())
  }

  @action
  public async save() {
    this.saveProcessing = true
    this.setOrganizer()
    const dto = this.event.serialize()
    dto.participants = this.participantsSelectVM.participantsToDTO()
    const svc = new EventsService()
    await svc.updateEvent(
      dto,
      this.rootStore.appStore.currentOrgId,
      this.eventSendNotificationsVM.skipNotifications
    )
    this.saveProcessing = false
    this.saveTried = false
    this.rootStore.eventsStore.calendarVM.closeCalendarEventDrawer()
    this.rootStore.eventsStore.calendarVM.refresh()
  }

  @action
  private setOrganizer() {
    if (!this.isNewEvent) return
    this.event.setOrganizer(this.rootStore.appStore.currentUserId)
  }

  @action
  public async handleDelete() {
    this.event.markAsDeleted()
    const dto = this.event.serialize()
    const svc = new EventsService()
    this.rootStore.eventsStore.calendarVM.closeCalendarEventDrawer()
    await svc.updateEvent(dto, dto.organizationId, this.eventSendNotificationsVM.skipNotifications)
  }

  @action
  public includesOption(option) {
    if (!this.weekdayRepeats.includes(option)) return true
    return true
  }

  @action
  public toggleWeekdayRepeat(value) {
    this.event.toggleWeekdayRepeat(value)
  }

  @action
  public setTabIndex(index: number) {
    if (this.addNewResource) return
    this.tabIndex = index
  }

  @action
  public openParticipantsTab() {
    this.participantsSelectVM.setVisible()
    this.isParticipantsTabOpen = true
  }

  @action
  public closeParticipantsTab() {
    this.participantsSelectVM.setHidden()
    this.eventParticipants = this.participantsSelectVM.participants
    this.isParticipantsTabOpen = false
  }

  @action
  public setTitle(val: string) {
    this.event.setTitle(val)
  }

  @computed
  public get localStartDate(): Date {
    return this.event.localStartDate
  }

  @computed
  public get localEndDate(): Date {
    return this.event.localEndDate
  }

  @action
  public setStartDate(date: Date) {
    if (this.allDay) {
      const startMoment = moment(this.localStartDate)
      const endMoment = moment(this.localEndDate)
      let differenceInDays = endMoment.diff(startMoment, 'days')
      if (differenceInDays < 0) differenceInDays = 0
      this.event.setStartDate(moment(date))
      this.event.setEndDate(moment(date).add(differenceInDays, 'days').endOf('day'))
      return
    }
    const startMoment = moment(this.localStartDate)
    const endMoment = moment(this.localEndDate)
    let differenceInMinutes = endMoment.diff(startMoment, 'minutes')
    if (differenceInMinutes <= 0) differenceInMinutes = 60
    const newStartMoment = moment(date)
      .hour(moment(this.localStartDate).hour())
      .minute(moment(this.localStartDate).minute())
      .second(0)
    this.event.setStartDate(newStartMoment)
    const newEndMoment = newStartMoment.clone().add(differenceInMinutes, 'minutes')
    this.event.setEndDate(newEndMoment)
  }

  @action
  public setStartTime(hr: number, min: number) {
    const startMoment = moment(this.localStartDate)
    const endMoment = moment(this.localEndDate)
    let differenceInMinutes = endMoment.diff(startMoment, 'minutes')
    if (differenceInMinutes <= 0) differenceInMinutes = 60
    const newStartMoment = moment(this.localStartDate).hour(hr).minute(min).second(0)
    const newEndMoment = newStartMoment.clone().add(differenceInMinutes, 'minutes')
    this.event.setStartDate(newStartMoment)
    this.event.setEndDate(newEndMoment)
  }

  @action
  public setEndDate(date: Date) {
    if (this.allDay) {
      this.event.setEndDate(moment(date))
      return
    }
    const endMoment = moment(date)
    endMoment
      .hour(moment(this.localEndDate).hour())
      .minute(moment(this.localEndDate).minute())
      .second(0)
    this.event.setEndDate(endMoment)
  }

  @action
  public setEndTime(hr: number, min: number) {
    const endMoment = moment(this.event.localEndDate)
    endMoment.hour(hr).minute(min).second(0)
    this.event.setEndDate(endMoment)
    this.previousEndDate = moment(this.previousEndDate).hour(hr).minute(min).second(0).toDate()
  }

  @action
  public setEndingMode(val) {
    this.hasEndDate = val
    this.event.setEndingMode(val)
  }

  @action
  public setScheduleEndDate(date: Date) {
    this.event.setScheduleEndDate(date)
  }

  @action
  public handleAllDayToggle() {
    this.event.toggleAllday()
    if (this.event.allDay) {
      this.event.setStartDate(moment(this.localStartDate).startOf('day'))
      this.event.setEndDate(moment(this.localEndDate).endOf('day'))
      return
    }
    const now = moment()
    const nextHour = now.startOf('hour').add(1, 'hour').hour()
    this.event.setStartDate(moment(this.localStartDate).hour(nextHour))
    this.event.setEndDate(moment(this.localEndDate).hour(nextHour).add(1, 'hour').startOf('hour'))
  }

  @computed
  public get skipNotifications(): boolean {
    return this.event.skipNotifications
  }

  @action
  public handleSkipNotifications() {
    this.event.toggleSkipNotifications()
  }

  @action
  public handleRepeatsToggle() {
    this.event.toggleSchedule()
  }

  @computed
  public get canHaveSchedule(): boolean {
    if (!moment(this.event.startDate).isSame(moment(this.event.endDate), 'day')) return false
    if (this.event.separateFromMasterEventId) return false
    return true
  }

  @computed
  public get scheduleEnabled(): boolean {
    if (!this.event.schedule) return false
    return this.event.schedule.enabled
  }

  @action
  public handleScheduleFrequencyChange(value: number) {
    this.event.setScheduleFrequency(value)
  }

  @computed
  public get scheduleEvery(): number {
    return this.event.schedule.every
  }

  @action
  public setRepeatType(value) {
    this.event.setRepeatType(value)
  }

  @computed
  public get repeatType(): RepeatType {
    return this.event.schedule.repeat
  }

  @action
  public handleEventTypeSelect(value: string) {
    this.event.setEventType(value)
  }

  @action
  public handleLocationSelect(value) {
    if (value?.name === 'Add New') return this.toggleAddNewLocation()
    this.event.setLocation(null)
    setTimeout(() => this.event.setLocation(value ? value.objectId : null), 50)
  }

  @action
  public setNewLocationName(address) {
    this.newLocation.name = address
  }

  @action
  public async saveNewLocation() {
    const svc = new LocationsService()
    const savedLocation = await svc.saveLocation(
      this.rootStore.appStore.currentOrgId,
      this.newLocation
    )
    this.newLocation = new Location()
    this.event.setLocation(savedLocation.objectId)
    this.toggleAddNewLocation()
  }

  @computed
  public get newLocationName() {
    return this.newLocation.name
  }

  @action
  public toggleAddNewResource() {
    this.addNewResource = !this.addNewResource
    if (!this.addNewResource) this.newResource = null
  }

  @action
  public handleResourceChange(value: Array<IResourceDTO>) {
    if (value[value.length - 1] && value[value.length - 1].name === 'Add New...') {
      this.newResource = Resource.create(this.rootStore.appStore.currentOrgId)
      this.toggleAddNewResource()
    }
    this.event.clearResources()
    value.forEach((resource) => this.event.addResource(resource.objectId))
  }

  @action
  public addAlarm() {
    this.event.addAlarm()
  }

  @action
  public deleteAlarm(index: number) {
    this.event.deleteAlarm(index)
  }

  @action
  public setNotes(notes: string) {
    this.event.setNotes(notes)
  }

  public setAutoCompleteRef(e: any) {
    this.autoCompleteRef = e
  }

  @computed
  public get canEditResource(): boolean {
    return this.rootStore.appStore.canEditEventResource
  }

  @computed
  public get canEditType(): boolean {
    return this.rootStore.appStore.canEditEventType
  }

  @action
  public toggleAddNewEventType() {
    this.addNewType = !this.addNewType
    if (!this.addNewType) this.newEventType = null
  }

  @action
  public async saveNewEventType() {
    this.eventTypeSaveTried = true
    if (!this.eventTypeValid) return
    const svc = new EventsService()
    const savedEventTypeId = await svc.saveEventType(
      this.rootStore.appStore.currentOrgId,
      this.newEventType
    )
    this.event.setEventType(savedEventTypeId)
    this.toggleAddNewEventType()
  }

  @computed
  public get eventTypeValid() {
    if (!this.newEventType.name && this.eventTypeSaveTried) return false
    return true
  }

  @action
  public setNewEventTypeName(val: string) {
    this.newEventType.name = val
  }

  @action
  public setNewEventTypeDescription(val: string) {
    this.newEventType.description = val
  }

  @action
  public async copyEvent() {
    const svc = new EventsCopyService(this.rootStore)
    const eventCopy = await svc.copyEvent(this.objectId)
    this.rootStore.eventsStore.loadEventEditVMFromEvent(eventCopy)
  }

  @action
  public handleEventTypeChange(value: EventType) {
    if (!value) {
      this.event.clearEventTypeId()
    } else if (value.name === 'Add New...') {
      this.newEventType = EventType.create(this.rootStore.appStore.currentOrgId)
      this.toggleAddNewEventType()
    } else {
      this.event.setEventType(value.objectId)
    }
  }
}
