import React from 'react'
import { inject, observer } from 'mobx-react'
import { ClientsStore } from '../store/ClientsStore'
import { CardHeader, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { LabelsStore } from '../../labels/store/LabelsStore'
import './ClientManagementWidget.scss'

interface DrawerHeaderProps {
  labelsStore?: LabelsStore
  clientsStore?: ClientsStore
}

const DrawerHeader: React.FC<DrawerHeaderProps> = ({ labelsStore, clientsStore }) => {
  return (
    <CardHeader
      action={
        <IconButton onClick={() => clientsStore.toggleWidgetDrawer()}>
          <CloseIcon />
        </IconButton>
      }
      title={
        clientsStore.clientEditVM.objectId
          ? `Edit ${labelsStore.getLabelByLanguageAndFor('client')}`
          : `Add ${labelsStore.getLabelByLanguageAndFor('client')}`
      }
    />
  )
}

export default inject('labelsStore', 'clientsStore')(observer(DrawerHeader))
