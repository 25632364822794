import React from 'react'
import { CardContent, Typography } from '@material-ui/core'
import { LabelsStore } from '../../../../labels/store/LabelsStore'
import { CategoriesStore } from 'src/app/categories/CategoriesStore'
import { observer, inject } from 'mobx-react'
import CategoriesTable from './CategoriesTable'
import CategoriesTree from './CategoriesTree'
import './WidgetContent.scss'

interface Props {
  labelsStore?: LabelsStore
  categoriesStore?: CategoriesStore
}

const WidgetContent: React.FC<Props> = ({ labelsStore, categoriesStore }) => {
  const { listWidgetVM: vm } = categoriesStore
  if (!vm) return
  return (
    <>
      {vm.tabIndex === 0 ? (
        <CardContent className='no-padding'>
          <CategoriesTable />
        </CardContent>
      ) : (
        <CardContent className='card-content'>
          <CategoriesTree />
        </CardContent>
      )}
    </>
  )
}

export default inject('labelsStore', 'categoriesStore')(observer(WidgetContent))
