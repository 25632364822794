import React from 'react'
import { RootStore } from '../../stores/RootStore'
import { inject, observer } from 'mobx-react'
import { CompletedSurveyResponsesStore } from '../store/CompletedSurveyResponsesStore'
import TaskHeader from '../../user-surveys/views/take/TaskHeader'
import UserSurveyTakeWidget from '../../user-surveys/views/take/UserSurveyTakeWidget'
import UserTasksDrawer from '../../user-tasks/views/drawer/Drawer'
import TaskDrawer from '../../tasks/views/drawer/Drawer'
import CompletedSurveyResponseHeader from './CompletedSurveyResponseHeader'

interface Props {
  rootStore?: RootStore
  completedSurveyResponsesStore?: CompletedSurveyResponsesStore
  surveyResponseId: string
}

const CompletedSurveyResponseArea: React.FC<Props> = ({
  surveyResponseId,
  rootStore,
  completedSurveyResponsesStore,
}) => {
  rootStore.completedSurveyResponsesStore.loadTakeVM(surveyResponseId)
  const vm = completedSurveyResponsesStore.takeVM
  return (
    <div id='UserSurveyTakeScreen'>
      <CompletedSurveyResponseHeader takeVM={vm} />
      <TaskHeader takeVM={vm} />
      <UserSurveyTakeWidget takeVM={vm} />
      <UserTasksDrawer />
      <TaskDrawer />
    </div>
  )
}

export default inject(
  'rootStore',
  'completedSurveyResponsesStore'
)(observer(CompletedSurveyResponseArea))
