import React from 'react'
import { OrganizationsSAStore } from '../../store/OrganizationsSAStore'
import LocalizationStore from '../../../localization/LocalizationStore'
import { inject, observer } from 'mobx-react'
import { Grid, InputLabel, ButtonGroup, Button } from '@material-ui/core'
import './OrganizationEditDrawer.scss'
import { LabelsStore } from 'src/app/labels/store/LabelsStore'

interface Props {
  organizationsSAStore?: OrganizationsSAStore
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
}

const OrganizationFeatures: React.FC<Props> = ({
  organizationsSAStore,
  localizationStore,
  labelsStore,
}) => {
  const { editVM: vm } = organizationsSAStore
  const { organizationsSADrawer: strings } = localizationStore.lzStrings
  if (!vm) return null
  if (vm.isNew) return null

  const renderAnonSurveys = () => {
    return (
      <>
        <Grid container direction='row' alignItems='center' spacing={1}>
          <Grid item>
            <ButtonGroup>
              <Button
                key='anon-on'
                variant='outlined'
                className={vm.anonymizeSurveys ? 'Mui-selected' : ''}
                onClick={() => vm.enableAnonymizeSurveys()}
              >
                {strings.on}
              </Button>
              <Button
                key='anon-off'
                variant='outlined'
                className={!vm.anonymizeSurveys ? 'Mui-selected' : ''}
                onClick={() => vm.disableAnonymizeSurveys()}
              >
                {strings.off}
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item>
            <InputLabel className='features-label'>{`Anonymize ${labelsStore.getLabelByLanguageAndFor(
              'pulses'
            )}`}</InputLabel>
          </Grid>
        </Grid>
      </>
    )
  }

  const renderAutoSaveSurveys = () => {
    return (
      <Grid container direction='row' alignItems='center' spacing={1}>
        <Grid item>
          <ButtonGroup>
            <Button
              key='anon-on'
              variant='outlined'
              className={vm.autoSaveSurveys ? 'Mui-selected' : ''}
              onClick={() => vm.toggleAutoSaveSurveys()}
            >
              {strings.on}
            </Button>
            <Button
              key='anon-off'
              variant='outlined'
              className={!vm.autoSaveSurveys ? 'Mui-selected' : ''}
              onClick={() => vm.toggleAutoSaveSurveys()}
            >
              {strings.off}
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item>
          <InputLabel className='features-label'>{`${labelsStore.getLabelByLanguageAndFor(
            'pulse'
          )} AutoSave`}</InputLabel>
        </Grid>
      </Grid>
    )
  }

  const renderCanImpersonateUsers = () => {
    return (
      <Grid container direction='row' alignItems='center' spacing={1}>
        <Grid item>
          <ButtonGroup>
            <Button
              key='anon-on'
              variant='outlined'
              className={vm.canImpersonateUsers ? 'Mui-selected' : ''}
              onClick={() => vm.toggleCanImpersonateUsers()}
            >
              {strings.on}
            </Button>
            <Button
              key='anon-off'
              variant='outlined'
              className={!vm.canImpersonateUsers ? 'Mui-selected' : ''}
              onClick={() => vm.toggleCanImpersonateUsers()}
            >
              {strings.off}
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item>
          <InputLabel className='features-label'>{strings.can_impersonate_users}</InputLabel>
        </Grid>
      </Grid>
    )
  }

  const renderAllowSurveyTags = () => {
    return (
      <Grid container direction='row' alignItems='center' spacing={1}>
        <Grid item>
          <ButtonGroup>
            <Button
              key='anon-on'
              variant='outlined'
              className={vm.allowSurveyTags ? 'Mui-selected' : ''}
              onClick={() => vm.toggleAllowSurveyTags()}
            >
              {strings.on}
            </Button>
            <Button
              key='anon-off'
              variant='outlined'
              className={!vm.allowSurveyTags ? 'Mui-selected' : ''}
              onClick={() => vm.toggleAllowSurveyTags()}
            >
              {strings.off}
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item>
          <InputLabel className='features-label'>
            {labelsStore.getLabelByLanguageAndFor('pulse') +
              ' ' +
              labelsStore.getLabelByLanguageAndFor('tags')}
          </InputLabel>
        </Grid>
      </Grid>
    )
  }

  const renderDiscludePeersFromAudience = () => {
    return (
      <Grid container direction='row' alignItems='center' spacing={1}>
        <Grid item>
          <ButtonGroup>
            <Button
              key='anon-on'
              variant='outlined'
              className={vm.discludePeersFromAudience ? 'Mui-selected' : ''}
              onClick={() => vm.toggleDiscludePeersFromAudience()}
            >
              {strings.on}
            </Button>
            <Button
              key='anon-off'
              variant='outlined'
              className={!vm.discludePeersFromAudience ? 'Mui-selected' : ''}
              onClick={() => vm.toggleDiscludePeersFromAudience()}
            >
              {strings.off}
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item>
          <InputLabel className='features-label'>{strings.disclude_peers_from_audience}</InputLabel>
        </Grid>
      </Grid>
    )
  }

  const renderDisableOldAudience = () => {
    return (
      <Grid container direction='row' alignItems='center' spacing={1}>
        <Grid item>
          <ButtonGroup>
            <Button
              key='old-audience-on'
              variant='outlined'
              className={vm.disableOldAudience ? 'Mui-selected' : ''}
              onClick={() => vm.toggleDisableOldAudience()}
            >
              {strings.on}
            </Button>
            <Button
              key='anon-off'
              variant='outlined'
              className={!vm.disableOldAudience ? 'Mui-selected' : ''}
              onClick={() => vm.toggleDisableOldAudience()}
            >
              {strings.off}
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item>
          <InputLabel className='features-label'>{strings.disable_old_audience}</InputLabel>
        </Grid>
      </Grid>
    )
  }

  const renderForceSurveyEmails = () => {
    return (
      <Grid container direction='row' alignItems='center' spacing={1}>
        <Grid item>
          <ButtonGroup>
            <Button
              key='anon-on'
              variant='outlined'
              className={vm.forceSurveyEmails ? 'Mui-selected' : ''}
              onClick={() => vm.enableForceSurveyEmails()}
            >
              {strings.on}
            </Button>
            <Button
              key='anon-off'
              variant='outlined'
              className={!vm.forceSurveyEmails ? 'Mui-selected' : ''}
              onClick={() => vm.disableForceSurveyEmails()}
            >
              {strings.off}
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item>
          <InputLabel className='features-label'>{`Force ${labelsStore.getLabelByLanguageAndFor(
            'pulse'
          )} Emails`}</InputLabel>
        </Grid>
      </Grid>
    )
  }

  const renderForceSurveyTextMessages = () => {
    return (
      <>
        <Grid container direction='row' alignItems='center' spacing={1}>
          <Grid item>
            <ButtonGroup>
              <Button
                key='force-tm-on'
                variant='outlined'
                className={vm.forceSurveyTextMessages ? 'Mui-selected' : ''}
                onClick={() => vm.enableForceSurveyTextMessages()}
              >
                {strings.on}
              </Button>
              <Button
                key='force-tm-off'
                variant='outlined'
                className={!vm.forceSurveyTextMessages ? 'Mui-selected' : ''}
                onClick={() => vm.disableForceSurveyTextMessages()}
              >
                {strings.off}
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item>
            <InputLabel className='features-label'>{`Force ${labelsStore.getLabelByLanguageAndFor(
              'pulse'
            )} Text Messages`}</InputLabel>
          </Grid>
        </Grid>
      </>
    )
  }

  const renderAllowSeparateUserNames = () => {
    return (
      <>
        <Grid container direction='row' alignItems='center' spacing={1}>
          <Grid item>
            <ButtonGroup>
              <Button
                key='force-tm-on'
                variant='outlined'
                className={vm.allowSeparateUsernames ? 'Mui-selected' : ''}
                onClick={() => vm.enableAllowSeparateUsernames()}
              >
                {strings.on}
              </Button>
              <Button
                key='force-tm-off'
                variant='outlined'
                className={!vm.allowSeparateUsernames ? 'Mui-selected' : ''}
                onClick={() => vm.disableAllowSeparateUsernames()}
              >
                {strings.off}
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item>
            <InputLabel className='features-label'>Separate Usernames</InputLabel>
          </Grid>
        </Grid>
      </>
    )
  }

  const renderJobNumber = () => {
    return (
      <>
        <Grid container direction='row' alignItems='center' spacing={1}>
          <Grid item>
            <ButtonGroup>
              <Button
                key='anon-on'
                variant='outlined'
                className={vm.hasJobNumber ? 'Mui-selected' : ''}
                onClick={() => vm.enableHasJobNumber()}
              >
                {strings.on}
              </Button>
              <Button
                key='anon-off'
                variant='outlined'
                className={!vm.hasJobNumber ? 'Mui-selected' : ''}
                onClick={() => vm.disableHasJobNumber()}
              >
                {strings.off}
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item>
            <InputLabel className='features-label'>{strings.job_number}</InputLabel>
          </Grid>
        </Grid>
      </>
    )
  }

  const renderEmployeeId = () => {
    return (
      <>
        <Grid container direction='row' alignItems='center' spacing={1}>
          <Grid item>
            <ButtonGroup>
              <Button
                key='anon-on'
                variant='outlined'
                className={vm.hasEmployeeId ? 'Mui-selected' : ''}
                onClick={() => vm.enableHasEmployeeId()}
              >
                {strings.on}
              </Button>
              <Button
                key='anon-off'
                variant='outlined'
                className={!vm.hasEmployeeId ? 'Mui-selected' : ''}
                onClick={() => vm.disableHasEmployeeId()}
              >
                {strings.off}
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item>
            <InputLabel className='features-label'>{strings.employee_id}</InputLabel>
          </Grid>
        </Grid>
      </>
    )
  }

  const renderCanImportPasswords = () => {
    return (
      <>
        <Grid container direction='row' alignItems='center' spacing={1}>
          <Grid item>
            <ButtonGroup>
              <Button
                key='streak-on'
                variant='outlined'
                className={vm.canImportPasswords ? 'Mui-selected' : ''}
                onClick={() => vm.enableCanImportPasswords()}
              >
                {strings.on}
              </Button>
              <Button
                key='streak-off'
                variant='outlined'
                className={!vm.canImportPasswords ? 'Mui-selected' : ''}
                onClick={() => vm.disableCanImportPasswords()}
              >
                {strings.off}
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item>
            <InputLabel className='features-label'>{strings.can_import_passwords}</InputLabel>
          </Grid>
        </Grid>
      </>
    )
  }

  const renderCMSPicker = () => {
    return (
      <>
        <Grid container direction='row' alignItems='center' spacing={1}>
          <Grid item>
            <ButtonGroup>
              <Button
                key='cms-picker-on'
                variant='outlined'
                className={vm.hasCMSPicker ? 'Mui-selected' : ''}
                onClick={() => vm.enableHasCMSPicker()}
              >
                {strings.on}
              </Button>
              <Button
                key='cms-picker-off'
                variant='outlined'
                className={!vm.hasCMSPicker ? 'Mui-selected' : ''}
                onClick={() => vm.disableHasCMSPicker()}
              >
                {strings.off}
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item>
            <InputLabel className='features-label'>{strings.cms_picker}</InputLabel>
          </Grid>
        </Grid>
      </>
    )
  }

  const renderTrainingPlanFlows = () => {
    return (
      <>
        <Grid container direction='row' alignItems='center' spacing={1}>
          <Grid item>
            <ButtonGroup>
              <Button
                key='training-plan-flows-on'
                variant='outlined'
                className={vm.hasTrainingPlanFlows ? 'Mui-selected' : ''}
                onClick={() => vm.enableHasTrainingPlanFlows()}
              >
                {strings.on}
              </Button>
              <Button
                key='training-plan-flows-off'
                variant='outlined'
                className={!vm.hasTrainingPlanFlows ? 'Mui-selected' : ''}
                onClick={() => vm.disableHasTrainingPlanFlows()}
              >
                {strings.off}
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item>
            <InputLabel className='features-label'>{strings.training_plan_flows}</InputLabel>
          </Grid>
        </Grid>
      </>
    )
  }

  const renderSurveyStreak = () => {
    return (
      <>
        <Grid container direction='row' alignItems='center' spacing={1}>
          <Grid item>
            <ButtonGroup>
              <Button
                key='streak-on'
                variant='outlined'
                className={vm.hasSurveyStreak ? 'Mui-selected' : ''}
                onClick={() => vm.enableHasSurveySteak()}
              >
                {strings.on}
              </Button>
              <Button
                key='streak-off'
                variant='outlined'
                className={!vm.hasSurveyStreak ? 'Mui-selected' : ''}
                onClick={() => vm.disableHasSurveyStreak()}
              >
                {strings.off}
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item>
            <InputLabel className='features-label'>{`${labelsStore.getLabelByLanguageAndFor(
              'pulse'
            )} Streak`}</InputLabel>
          </Grid>
        </Grid>
      </>
    )
  }

  const renderArchiveSurveys = () => {
    return (
      <>
        <Grid container direction='row' alignItems='center' spacing={1}>
          <Grid item>
            <ButtonGroup>
              <Button
                key='archive-on'
                variant='outlined'
                className={vm.canArchiveSurveys ? 'Mui-selected' : ''}
                onClick={() => vm.enableCanArchiveSurveys()}
              >
                {strings.on}
              </Button>
              <Button
                key='archive-off'
                variant='outlined'
                className={vm.canArchiveSurveys ? '' : 'Mui-selected'}
                onClick={() => vm.disableCanArchiveSurveys()}
              >
                {strings.off}
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item>
            <InputLabel className='features-label'>{`Can Archive ${labelsStore.getLabelByLanguageAndFor(
              'pulses'
            )}`}</InputLabel>
          </Grid>
        </Grid>
      </>
    )
  }

  const renderFeaturesSection = () => {
    return (
      <div className='details-paper'>
        <h6>{strings.features}</h6>
        <Grid container direction='row' alignItems='center' spacing={2}>
          <Grid item xs={6} lg={6}>
            {renderJobNumber()}
          </Grid>
          <Grid item xs={6} lg={6}>
            {renderEmployeeId()}
          </Grid>
        </Grid>
        <Grid
          container
          direction='row'
          alignItems='center'
          justifyContent='space-around'
          spacing={2}
        >
          <Grid item xs={6} lg={6}>
            {renderAnonSurveys()}
          </Grid>
          <Grid item xs={6} lg={6}>
            {renderForceSurveyEmails()}
          </Grid>
        </Grid>
        <Grid container direction='row' alignItems='center' justifyContent='flex-start' spacing={2}>
          <Grid item xs={6} lg={6}>
            {renderAllowSurveyTags()}
          </Grid>
          <Grid item xs={6} lg={6}>
            {renderAutoSaveSurveys()}
          </Grid>
        </Grid>
        <Grid container direction='row' alignItems='center' justifyContent='flex-start' spacing={2}>
          <Grid item xs={6} lg={6}>
            {renderAllowSeparateUserNames()}
          </Grid>
          <Grid item xs={6} lg={6}>
            {renderForceSurveyTextMessages()}
          </Grid>
        </Grid>
        <Grid container direction='row' alignItems='center' justifyContent='flex-start' spacing={2}>
          <Grid item xs={6} lg={6}>
            {renderCanImpersonateUsers()}
          </Grid>
          <Grid item xs={6} lg={6}>
            {renderDiscludePeersFromAudience()}
          </Grid>
        </Grid>
        <Grid container direction='row' alignItems='center' justifyContent='flex-start' spacing={2}>
          <Grid item xs={6} lg={6}>
            {renderSurveyStreak()}
          </Grid>
          <Grid item xs={6} lg={6}>
            {renderCanImportPasswords()}
          </Grid>
        </Grid>
        <Grid container direction='row' alignItems='center' justifyContent='flex-start' spacing={2}>
          <Grid item xs={6} lg={6}>
            {renderArchiveSurveys()}
          </Grid>
          <Grid item xs={6} lg={6}>
            {renderCMSPicker()}
          </Grid>
        </Grid>
        <Grid container direction='row' alignItems='center' justifyContent='flex-start' spacing={2}>
          <Grid item xs={6} lg={6}>
            {renderDisableOldAudience()}
          </Grid>
          <Grid item xs={6} lg={6}>
            {renderTrainingPlanFlows()}
          </Grid>
        </Grid>
      </div>
    )
  }
  return (
    <div id='OrganizationFeatures'>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          {renderFeaturesSection()}
        </Grid>
      </Grid>
    </div>
  )
}

export default inject(
  'organizationsSAStore',
  'localizationStore',
  'labelsStore'
)(observer(OrganizationFeatures))
