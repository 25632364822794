import { action } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import Parse from 'parse'

export class UsersService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public async importUsersFromCsvV2(orgId, parseFile, doSave, batchId) {
    const result = await Parse.Cloud.run('importUsersFromCsvV2', {
      orgId,
      parseFile,
      doSave,
      batchId,
    })
    return result
  }

  public async getOrgUser() {
    const qry = new Parse.Query('organizationUsers')
    qry.equalTo('organizationId', this.rootStore.appStore.currentOrgId)
    qry.equalTo('objectId', this.rootStore.userStore.currentOrgUserId)
    const result = await qry.first()
    return result
  }

  @action
  public async impersonateUser(userId) {
    const sessionToken = await Parse.Cloud.run('getUserSessionToken', { userId })
    if (!sessionToken) {
      alert('User does not currently have a logged in session. Unable to impersonate.')
      return
    }
    await Parse.User.become(sessionToken)
    this.rootStore.appStore.setCurrentUser(Parse.User.current().id, true)
    window.location.href = '/'
  }
}
