import React from 'react'
import { inject, observer } from 'mobx-react'
import {
  CardContent,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core'
import FavoriteIcon from '@material-ui/icons/Favorite'
import { UserDashboardsStore } from '../../UserDashboardsStore'
import UserDashboard from '../../aggregate/UserDashboard'
import MuiDrawerHeader from '../../../../shared/Mui-Drawer/MuiDrawerHeader'

interface Props {
  userDashboardsStore?: UserDashboardsStore
}

const SharedDashboardsDrawer: React.FC<Props> = ({ userDashboardsStore }) => {
  const vmSvc = userDashboardsStore.viewModels
  const vm = vmSvc.userDashboardVM
  if (!vm) return null


  const toggleDrawer = () => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    vm.toggleShowSharedDashboardsDrawer()
  }

  const renderFavoriteIcon = (dashboard: UserDashboard) => {
    if (!dashboard.isFavorite) return null
    return <FavoriteIcon />

  }

  const renderListItem = (dashboard: UserDashboard) => {
    return (
      <ListItem button key={dashboard.objectId} onClick={() => vm.goToSharedDashboard(dashboard)}>
        <ListItemIcon>
          {renderFavoriteIcon(dashboard)}
        </ListItemIcon>
        <ListItemText primary={dashboard.name} secondary={`Shared by: ${vm.getUserDashboardOwnerName(dashboard.ownerUserId)}`} />
      </ListItem>
    )
  }

  const renderSharedDashboards = () => {
    if (!vmSvc.sharedDashboards.length) return 'No shared dashboards.'
    return vmSvc.sharedDashboards.map(e => (
      renderListItem(e)
    ))
  }

  return (
    <>
      <div id='SharedDashbordsDrawer'>
        <Drawer
          variant='temporary'
          anchor='right'
          open={vm.showSharedDashboardsDrawer}
          onClose={toggleDrawer()}
        >
          <MuiDrawerHeader
            title={'Shared Dashboards'}
            toggleDrawer={toggleDrawer()}
          />
          <CardContent className='SharedDashbordsDrawer'>
            <List>
              {renderSharedDashboards()}
            </List>
          </CardContent>
        </Drawer>
      </div>
    </>
  )
}

export default inject('userDashboardsStore')(observer(SharedDashboardsDrawer))
