import { RootStore } from '../../stores/RootStore'
import { IAggregate } from '../data/IAggregate'
import { AGGridVM } from './AGGridVM'

export class AGGridRowVM<T extends IAggregate> {
  protected rootStore: RootStore
  protected aggregate: T
  protected widgetVM: AGGridVM

  constructor(rootStore: RootStore, aggregate: T, widgetVM: AGGridVM) {
    this.rootStore = rootStore
    this.aggregate = aggregate
    this.widgetVM = widgetVM
    // this.updateRow()
  }

  public get rowNodeId() {
    return this.aggregate.objectId
  }

  protected updateRow() {
    if (!this.widgetVM.gridApi) {
      // console.log('nope')
      return
    }
    // console.log(this.widgetVM.ilo)
    // console.log((this.widgetVM.gridApi as any).rowModel)
    // console.log((this.widgetVM.gridApi as any).rowModel.infiniteCache && (this.widgetVM.gridApi as any).rowModel.infiniteCache.active)
    if (this.widgetVM.serverSideLoaded) return
    // if (this.widgetVM.gridApi.mode)
    const rowNode = this.widgetVM.gridApi.getRowNode(this.rowNodeId)
    if (!rowNode) {
      console.log('found node: applying')
      console.log({ aggregate: this.aggregate })
      this.widgetVM.gridApi.applyTransaction({ add: [this] })
      return
    }
    rowNode.setData(this)
  }
}
