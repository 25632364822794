import { observable, computed, action } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Attachment } from '../../upload/aggregate/Attachment'
import { FileOpenService } from '../../upload/services/FileOpenService'

export class PulseCampaignSurveyAttachmentVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, attachment: Attachment) {
    this.rootStore = rootStore
    this.attachment = attachment
  }

  @observable public showDelete: boolean = false
  @observable public attachment: Attachment = undefined

  @computed
  public get objectId(): string {
    return this.attachment.objectId
  }

  @computed
  public get bytes(): number {
    return Math.round((this.attachment.bytes / 1000) * 10) / 10
  }

  @computed
  public get fileType(): string {
    if (!this.attachment) return ''
    const regExp = /([^?\/]+?(?=\?|\&|\#|$))/
    const match = this.url.match(regExp)
  
    if (match && match.length) {
      const index = match[0].lastIndexOf('.')
      return match[0].substring(index, match[0].length)
    }

    const index = this.attachment.url.lastIndexOf('.')
    return this.attachment.url.substring(index, this.attachment.url.length)
  }

  @computed
  public get toolTipName(): string {
    return this.attachment.fileName
  }

  @computed
  public get shorterFileName(): string {
    if (this.attachment.fileName.length > 30)
      return this.attachment.fileName.substring(0, 30) + '..'
    return this.attachment.fileName
  }

  @computed
  public get fileName(): string {
    if (this.attachment.fileName.length > 40)
      return this.attachment.fileName.substring(0, 40) + '..'
    return this.attachment.fileName
  }

  @computed
  public get thumbnail(): string {
    if (!this.attachment.thumbnail) return ''
    return this.attachment.thumbnail
  }

  @computed
  public get url(): string {
    return this.attachment.url
  }

  @computed
  public get type(): string {
    return this.attachment.type
  }

  @computed
  public get format(): string {
    if (!this.attachment.format) return ''
    return this.attachment.format
  }

  @action
  public async openAttachment() {
    const svc = new FileOpenService(this.rootStore)
    await svc.openFile(this.attachment)
  }

  @action
  public showDeleteIcon() {
    this.showDelete = true
  }
  @action
  public hideDeleteIcon() {
    this.showDelete = false
  }
}
