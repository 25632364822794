import React from 'react'
import { inject, observer } from 'mobx-react'
import { GoalsStore } from '../../../store/GoalsStore'
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputAdornment,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
} from '@material-ui/core'
import LocalizationStore from '../../../../localization/LocalizationStore'
import './MeasureModal.scss'
import CloseIcon from '@material-ui/icons/Close'

interface Props {
  goalsStore?: GoalsStore
  localizationStore?: LocalizationStore
}

const MeasureModal: React.FC<Props> = ({ goalsStore, localizationStore }) => {
  const { goal_create: strings } = localizationStore.lzStrings
  const { goalEditVM: vm } = goalsStore
  if (!vm) return null

  const renderMeasurementOptions = () => {
    return (
      <>
        <Grid container direction='row' className='aggregation-row' alignItems='center'>
          <Typography variant='h6'>the</Typography>
          <Select
            className='aggregation-select'
            variant='outlined'
            value={vm.aggregationMethod}
            onChange={(e) => vm.setAggregationMethod(e.target.value)}
          >
            <MenuItem value='average'>Average</MenuItem>
            <MenuItem value='minimum'>Minimum</MenuItem>
            <MenuItem value='maximum'>Maximum</MenuItem>
          </Select>
          <Typography variant='h6'>of the</Typography>
          <TextField
            disabled
            className='aggregation-select'
            variant='outlined'
            value={vm.facet ? vm.facet.field : ''}
          />
        </Grid>
        <Grid container direction='row' className='aggregation-row-2' alignItems='center'>
          <Typography variant='h6'>must exceed or meet</Typography>
          <TextField
            disabled
            className='aggregation-select'
            variant='outlined'
            value={vm.targetMode === 'percentage' ? vm.targetPercentage : vm.targetInputValue}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  {vm.targetMode === 'percentage' ? '%' : ''}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid container direction='row' className='aggregation-row' alignItems='center'>
          <Typography variant='h6'>at least</Typography>
          <TextField
            type='number'
            className='aggregation-select'
            variant='outlined'
            onChange={(e) => vm.setConsistencyThreshold(e.target.value)}
            value={vm.consistencyThreshold}
            InputProps={{
              endAdornment: <InputAdornment position='end'>%</InputAdornment>,
            }}
          />
          <Typography variant='h6'>of the</Typography>

          <Select
            className='aggregation-select'
            variant='outlined'
            value={vm.aggregationPeriod}
            onChange={(e) => vm.setAggregationPeriod(e.target.value)}
          >
            <MenuItem value='days'>Days</MenuItem>
            <MenuItem value='weeks'>Weeks</MenuItem>
            <MenuItem value='months'>Months</MenuItem>
            <MenuItem value='years'>Years</MenuItem>
          </Select>
        </Grid>
        <Grid container direction='row' className='aggregation-row-2' alignItems='center'>
          <Typography variant='h6'>between</Typography>
          <TextField disabled className='date-range' variant='outlined' value={vm.startDate} />
          <Typography variant='h6'>and</Typography>
          <TextField disabled className='date-range' variant='outlined' value={vm.endDate} />
        </Grid>
      </>
    )
  }

  const renderToolbar = () => {
    return (
      <div className='measure-modal-toolbar'>
        <Typography variant='h5'>{strings.aggregation_method}</Typography>
        <span>
          <Button variant='text' onClick={() => vm.setMeasureGoal('false')}>
            Cancel
          </Button>
          <Button variant='outlined' onClick={() => vm.toggleMeasureModal()}>
            Save
          </Button>
        </span>
      </div>
    )
  }

  return (
    <Dialog open={vm.measureModalOpen} onClose={() => vm.toggleMeasureModal()} id='MeasureModal'>
      <DialogTitle>
        {strings.achieve_this_goal}
        <CloseIcon onClick={() => vm.toggleMeasureModal()} />
      </DialogTitle>
      {renderToolbar()}
      <DialogContent>{renderMeasurementOptions()}</DialogContent>
    </Dialog>
  )
}

export default inject('goalsStore', 'localizationStore')(observer(MeasureModal))
