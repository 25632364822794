import Parse from 'parse'
import { RootStore } from 'src/app/stores/RootStore'

export class PulseCampaignTypesService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public async savePulseCampaignType(orgId, data) {
    const result = await Parse.Cloud.run('savePulseCampaignType', {
      orgId,
      data,
    })
    return result
  }

  public async deletePulseCampaignType(orgId, objectId) {
    const result = await Parse.Cloud.run('deletePulseCampaignType', {
      orgId,
      objectId,
    })
    return result
  }
}
