import React from 'react'
import { inject, observer } from 'mobx-react'
import { RolesStore } from '../store/RolesStore'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import './RoleEditDrawer.scss'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Switch, Checkbox } from '@material-ui/core'
import InfoTooltip from '../../shared/InfoTooltip'

interface RolesBasicInfoProps {
  rolesStore?: RolesStore
}

const GroupsBasicInfo: React.FC<RolesBasicInfoProps> = ({ rolesStore }) => {
  const vm = rolesStore.editVM
  const basicInfo = vm.basicInfo

  if (!basicInfo) return null

  const renderAlwaysLandToggle = () => {
    if (!basicInfo.defaultDashboardId) return null
    return (
      <Grid container className='alwaysLandToggle-container'>
        <Grid item xs={12} md={12} lg={12}>
          <h6 className='title'>
            Force Dashboard Route
            <InfoTooltip
              message={'Users will always land on this dashboard when logging in if selected.'}
            />
          </h6>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Switch
            className={'alwaysLandToggle'}
            color='primary'
            checked={basicInfo.forceDashboardRoute}
            onChange={() => basicInfo.toggleForceDashboardRoute()}
          />
        </Grid>
      </Grid>
    )
  }

  const renderDefaultDashboard = () => {
    return (
      <Grid container className='defaultDash-container'>
        <Grid item xs={12} md={12} lg={12}>
          <h6 className='title'>Default Dashboard</h6>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Autocomplete
            options={basicInfo.availableDashboards}
            getOptionLabel={(e) => e.name}
            value={basicInfo.defaultDashboard}
            onChange={(e, val) => basicInfo.setDefaultDashboard(val)}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder='Select Default Dashboard'
                variant='outlined'
                className={'defaultDash-select'}
              />
            )}
          />
        </Grid>
      </Grid>
    )
  }

  const renderTableHead = () => (
    <TableHead>
      <TableRow key='eventTypes-header' className='temp-table'>
        <TableCell>Name</TableCell>
        <TableCell>Description</TableCell>
      </TableRow>
    </TableHead>
  )

  const renderTableBody = () => {
    if (!basicInfo.privilegeSetRows) return
    return (
      <TableBody>
        {basicInfo.privilegeSetRows.map((row) => (
          <TableRow
            hover
            key={row.objectId + 'row'}
            onClick={() => row.toggleIsSelected()}
            className='privilege-row'
          >
            <TableCell key={row.objectId + 'name'}>
              <div className='privilege-checkbox-cell-content'>
                <Checkbox checked={row.isSelected} />
                <div className='privilege-name'>{row.name}</div>
              </div>
            </TableCell>
            <TableCell key={row.objectId + 'desc'} className='role-description'>
              {row.description ? row.description : '—'}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    )
  }

  return (
    <div id='RoleEditDrawer'>
      <h6 className='title'>Basic Info</h6>
      <form autoComplete='off' noValidate>
        <Grid container>
          <Grid item md={12} sm={12}>
            <InputLabel required htmlFor='name'>
              Name
            </InputLabel>
            <TextField
              value={basicInfo.name}
              onChange={(e) => {
                vm.saveTried = false
                basicInfo.setName(e.target.value)
              }}
              disabled={basicInfo.disabledCauseAdmin}
              fullWidth
              variant='outlined'
              id='name'
              error={!vm.isNameValid}
              helperText={vm.roleNameErrorMessage}
              FormHelperTextProps={{
                className: 'role-name-helper-text',
              }}
            />
          </Grid>
        </Grid>
        <Grid container className='description-container'>
          <Grid item md={12} sm={12}>
            <InputLabel className='input-label' htmlFor='description'>
              Description
            </InputLabel>
            <TextField
              multiline
              minRows={5}
              margin='none'
              value={basicInfo.description}
              onChange={(e) => basicInfo.setDescription(e.target.value)}
              fullWidth
              variant='outlined'
              id='description'
            />
          </Grid>
        </Grid>
      </form>

      <Grid container>
        <Grid item xs={7}>
          {renderDefaultDashboard()}
        </Grid>
        <Grid item xs={5}>
          {renderAlwaysLandToggle()}
        </Grid>
      </Grid>

      {basicInfo.privilegeSetRows && (
        <div>
          <Grid container className='privileges-container'>
            <Grid item md={12} sm={12}>
              <h6 className='title'>Privilege Sets</h6>
              <Table stickyHeader size='small' className='privileges-table'>
                {renderTableHead()}
                {renderTableBody()}
              </Table>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  )
}

export default inject('rolesStore')(observer(GroupsBasicInfo))
