import React, { useState } from 'react'
import { observer, inject } from 'mobx-react'
import LocalizationStore from '../../localization/LocalizationStore'
import { Button, Popover } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import FilterListIcon from '@material-ui/icons/FilterList'
import { QuestionTypesFilterVM } from '../view-models/QuestionTypesFilterVM'

interface Props {
  localizationStore?: LocalizationStore
  vm: QuestionTypesFilterVM
}

const QuestionTypesFilter: React.FC<Props> = ({ vm }) => {
  const [typeAnchorEl, setTypeAnchorEl] = useState(null)
  if (!vm) return

  const renderLabel = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={vm.allChecked}
            onChange={() => vm.handleClickSelectAllTypes()}
            name='select-all'
            color='primary'
          />
        }
        label='Select All'
        className='question-catalog-type-filter-row'
      />
    )
  }

  const renderTypes = () => {
    return vm.types.map((type, index) => (
      <FormControlLabel
        key={index}
        control={
          <Checkbox
            checked={vm.typesToShow.includes(type)}
            onChange={() => vm.handleChangeTypeFilter(type)}
            name={type}
            color='primary'
          />
        }
        label={vm.prettierTypeName(type)}
        className='question-catalog-type-filter-row'
      />
    ))
  }

  const renderPopover = () => {
    if (!typeAnchorEl) return
    return (
      <Popover
        open={Boolean(typeAnchorEl)}
        onClose={() => setTypeAnchorEl(null)}
        anchorEl={typeAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className='question-type-popper'
      >
        <div className='question-catalog-type-filter-menu'>
          {renderLabel()}
          {renderTypes()}
        </div>
      </Popover>
    )
  }

  return (
    <>
      <Button
        variant='outlined'
        startIcon={<FilterListIcon />}
        onClick={(e) => setTypeAnchorEl(typeAnchorEl ? null : e.currentTarget)}
      >
        Type ({vm.selectedCountLabel})
      </Button>
      {renderPopover()}
    </>
  )
}

export default inject('localizationStore')(observer(QuestionTypesFilter))
