import React from 'react'
import CalendarEventDrawerAlarms from './CalendarEventDrawerAlarms'
import CalendarEventDrawerNotes from './CalendarEventDrawerNotes'

const CalendarEventDrawerAlarmsTab = () => (
  <>
    <CalendarEventDrawerAlarms />
    <CalendarEventDrawerNotes />
  </>
)

export default CalendarEventDrawerAlarmsTab
