import { action, observable } from 'mobx'
import { Participant } from './Participant'
import { serializable, serialize, deserialize, list, object, primitive } from 'serializr'
import moment from 'moment'
import uuid from 'uuid/v4'
import { IParticipantDTO } from '../dtos/IParticipantDTO'
import { IPublishGroup } from '../dtos/IPublishGroup'
import { ParticipantVM } from '../../participants-select/view-models/ParticipantVM'
import { AnonymousParticipantVM } from '../../participants-select/view-models/AnonymousParticipantVM'

export class CustomPublishGroup implements IPublishGroup {
  static create(grp: IPublishGroup) {
    const customPublishGroup = new CustomPublishGroup()
    customPublishGroup.id = grp.id
    customPublishGroup.enabled = grp.enabled
    customPublishGroup.startDate = grp.startDate
    customPublishGroup.deliveryTime = grp.deliveryTime
    customPublishGroup.deliveryHour = grp.deliveryHour
    customPublishGroup.deliveryMinute = grp.deliveryMinute
    customPublishGroup.deliveryTimeZone = grp.deliveryTimeZone
    customPublishGroup.participants = grp.participants.map((p) => Participant.create(p))
    customPublishGroup.participatingUsers = grp.participatingUsers.slice()
    return customPublishGroup
  }

  @serializable @observable id: string = uuid()
  @serializable @observable enabled: boolean = false
  @serializable @observable startDate: string = moment(new Date()).toISOString()
  @serializable @observable deliveryTime: string = this.loadDeliveryTime().toISOString()
  @serializable @observable deliveryHour: string = ''
  @serializable @observable deliveryMinute: string = ''
  @serializable @observable deliveryTimeZone: string = moment.tz.guess()
  @serializable(list(object(Participant))) @observable participants: Array<IParticipantDTO> = []
  @serializable(list(primitive())) @observable participatingUsers: Array<string> = []

  @action
  public setID(val: string) {
    this.id = val
  }

  @action
  public toggleIsEnabled() {
    this.enabled = !this.enabled
  }

  @action
  public setEnabled(val: boolean) {
    this.enabled = val
  }

  @action
  public setStartDate(val: Date) {
    this.startDate = moment(val).toISOString()
  }

  @action
  public setDeliveryTime(val: Date) {
    this.deliveryTime = moment(val).toISOString()
    this.deliveryHour = moment(val).format('H')
    this.deliveryMinute = moment(val).format('mm')
  }

  private loadDeliveryTime(): Date {
    let nextSlot = moment().startOf('minute')
    while (
      nextSlot.minute() !== 0 &&
      nextSlot.minute() !== 15 &&
      nextSlot.minute() !== 30 &&
      nextSlot.minute() !== 45
    ) {
      nextSlot.add(1, 'minute')
    }
    nextSlot.add(1, 'hour')
    return nextSlot.toDate()
  }

  @action
  public setDeliveryTimeZone(val: any) {
    if (!val) return
    this.deliveryTimeZone = val.value
  }

  @action
  public setAllParticipants(val: Array<AnonymousParticipantVM | ParticipantVM>) {
    this.participants = val.map((e) => {
      return Participant.create(e)
    })
  }

  @action
  public addParticipant(val: AnonymousParticipantVM | ParticipantVM) {
    this.participants.push(Participant.create(val))
  }

  @action
  public deleteParticipantById(id: string) {
    this.participants = this.participants.filter((e) => e.id !== id)
  }

  @action
  public deleteParticipantByIndex(index) {
    this.participants.splice(index, 1)
  }

  @action
  public clearAllParticipants() {
    this.participants = []
  }

  public clone(): CustomPublishGroup {
    return deserialize(CustomPublishGroup, this.serialize())
  }

  public serialize(): IPublishGroup {
    try {
      const dto = serialize(this)
      return dto
    } catch (e) {
      console.error(e)
      console.log(this)
    }
  }
}
