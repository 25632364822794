import Parse from 'parse'
import { action, computed, observable, runInAction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { AuthenticationService } from '../services/AuthenticationService'
import { IUserAuthInfoResult, IUserAuthOrgInfoResult } from '../interfaces/IUserAuthInfoResult'
import { SignInMethodRowVM } from './SignInMethodRowVM'

import { AuthorizationRequest } from '@openid/appauth/built/authorization_request'
import uuid from 'uuid/v4'
import { AuthorizationServiceConfiguration } from '@openid/appauth/built/authorization_service_configuration'
import { NodeRequestor } from '@openid/appauth/built/node_support/node_requestor'
import { RedirectRequestHandler } from '@openid/appauth'
import env from '../../../env'
import { IDomainSSOResult } from '../interfaces/IDomainSSOResult'

export class LoginVM {
  private rootStore: RootStore
  private authSvc = new AuthenticationService()

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.checkForLoggedInUser()
    this.initRememberMe()
    this.checkForRequiredSSO()
  }

  @observable public username: string = ''
  @observable public password: string = ''
  @observable public forgotPasswordUsername: string = ''
  @observable public isProcessing: boolean = false
  @observable public loginSuccessful: boolean = false
  @observable public passwordLoginFailed: boolean = false
  @observable public passwordLoginErrorMessage: string = ''
  @observable public userAuthInfoResult: IUserAuthInfoResult = null
  @observable public selectedOrgOption: number = -1
  @observable public handlingOIDCLogin: boolean = false
  @observable public checkingSSOSettings: boolean = false

  private async checkForRequiredSSO() {
    try {
      if (
        window.location.href.includes('/public') ||
        window.location.href.includes('oidcRedirect') ||
        window.location.href.includes('oidclogin')
      ) {
        return
      }

      this.checkingSSOSettings = true
      let host = window.location.host.split(':')[0]
      const result: IDomainSSOResult = await this.authSvc.getDomainSSO(host)
      // console.log('get SSO host')
      // console.log({ sso: result })
      if (result.orgOption) {
        // console.log('redirect to azure' + window.location.href)
        await this.loginWithOIDCProvider(result.orgOption)
      }
      this.checkingSSOSettings = false
    } catch (e) {
      console.error(e)
      console.log('Failed')
    }
  }

  private initRememberMe() {
    if (!this.rootStore.isHydrated) {
      setTimeout(() => this.initRememberMe(), 250)
      return
    }
    let rememberMe = this.rootStore.appStore.rememberMe
    let rememberedUserName = this.rootStore.appStore.rememberedUserName
    if (env.var.REACT_APP_USE_SESSION_STORAGE === '1') {
      rememberMe = Boolean(localStorage.getItem('rememberMe'))
      rememberedUserName = localStorage.getItem('rememberedUserName')
    }
    if (!rememberMe) return
    this.rootStore.appStore.setRememberMe(true)
    this.setUsername(rememberedUserName)
  }

  private checkForLoggedInUser() {
    if (!this.rootStore.appStore.currentUserId) return
    console.log('route to not login page')
    window.location.href = '/'
  }

  @action
  public setUsername(val) {
    this.username = val
    this.userAuthInfoResult = null
    this.rootStore.appStore.setRememberedUserName(val)
  }

  @action
  public toggleRememberMe() {
    this.rootStore.appStore.toggleRememberMe()
  }

  @action
  public setPassword(val) {
    this.passwordLoginErrorMessage = ''
    this.passwordLoginFailed = false
    this.password = val
  }

  @action
  public setForgotPasswordUsername(value) {
    this.forgotPasswordUsername = value
  }

  @computed
  public get rememberMe(): boolean {
    return this.rootStore.appStore.rememberMe
  }

  @computed
  public get usernameError(): boolean {
    return false
  }

  @computed
  public get passwordError(): boolean {
    return false
  }

  @computed
  public get isOffline(): boolean {
    return !this.rootStore.appStore.isOnline
  }

  @computed
  public get isIE(): boolean {
    const ua = window.navigator.userAgent //Check the userAgent property of the window.navigator object
    const msie = ua.indexOf('MSIE ') // IE 10 or older
    const trident = ua.indexOf('Trident/') //IE 11

    return msie > 0 || trident > 0
  }

  @action
  public goToForgotPassword() {
    this.rootStore.appStore.router.push('/auth/forgot')
  }

  @computed
  public get loginButtonDisabled(): boolean {
    if (this.isOffline) return true
    return false
  }

  @action
  public async handleSubmit(e: any) {
    e.preventDefault()
    this.rootStore.appStore.setRememberedUserName(this.username)
    this.rootStore.appStore.checkOnline()
    if (this.isOffline) return
    if (this.userAuthInfoResult && !this.userAuthInfoResult.success) this.userAuthInfoResult = null
    if (!this.userAuthInfoResult && !this.passwordModeForced) {
      await this.getUserAuthInfo()
      return
    }
    if (!this.signInButtonShown) return
    await this.loginWithPassword()
  }

  @action
  public async loginWithPassword() {
    if (!this.signInButtonShown) return
    this.isProcessing = true
    this.passwordLoginFailed = false
    const authSvc = new AuthenticationService()
    const result = await authSvc.tryLogin(this.username, this.password)
    if (!result.success) {
      this.isProcessing = false
      this.passwordLoginErrorMessage = result.errorMessage
      this.passwordLoginFailed = true
      return
    }
    this.loginSuccessful = true
    this.navigateToHome()
    this.isProcessing = false
  }

  @computed
  public get errorMessage(): string {
    if (this.userAuthInfoResult) {
      if (!this.userAuthInfoResult.success) return this.userAuthInfoResult.errorMessage
    }
    if (this.passwordLoginFailed)
      return this.passwordLoginErrorMessage || 'Invalid email or password'
    return undefined
  }

  @action
  private async navigateToHome() {
    if (this.rootStore.appStore.rememberLink) {
      console.log(`${this.rootStore.appStore.rememberLink}`)
      const link = this.rootStore.appStore.rememberLink
      this.rootStore.appStore.setRememberLink(undefined)
      this.confirmPathAuth(link)
      return
    }
    const { userTaskId, orgId } = this.rootStore.appStore.router.params
    let path = '/'
    let to = 0
    if (userTaskId && orgId) {
      path = `/dashboard/tasks/${userTaskId}/orgId/${orgId}`
      to = 1500
    }
    setTimeout(() => this.rootStore.appStore.router.push(path), to)
  }

  @action
  private confirmPathAuth(link) {
    //Make sure rememberlink doesnt include a restricted path
    let path = link
    if (link.includes('tenantAdmin')) path = '/'
    if (link.includes('systemAdmin')) path = '/'
    setTimeout(() => this.rootStore.appStore.router.push(path), 1500)
  }

  @action
  public async getUserAuthInfo() {
    this.isProcessing = true
    const req = { email: this.username }
    const authSvc = new AuthenticationService()
    const result = await authSvc.getUserAuthInfo(req)
    this.userAuthInfoResult = result
    this.selectedOrgOption = -1
    this.isProcessing = false
    await this.checkSingleOIDCLogin()
  }

  private async checkSingleOIDCLogin() {
    if (this.userAuthInfoResult.orgOptions.length === 1) {
      const orgOption = this.userAuthInfoResult.orgOptions[0]
      if (orgOption.requireOIDCAuth) {
        await this.loginWithOIDCProvider(orgOption)
      }
    }

    return false
  }

  private redirectUri: string = `${window.location.protocol}//${window.location.host}/auth/oidcRedirect?`

  @action
  public async loginWithOIDCProvider(orgOption: IUserAuthOrgInfoResult) {
    this.handlingOIDCLogin = true
    const configuration = await this.fetchServiceConfiguration(orgOption.oidcConnectUrl)
    if (!configuration) {
      console.log('Unknown service configuration')
      return
    }
    let request = new AuthorizationRequest({
      client_id: orgOption.oidcClientId,
      redirect_uri: this.redirectUri,
      scope: 'openid',
      response_type: 'token',
      state: orgOption.id, // state is the organization ID
      extras: { access_type: 'offline', nonce: uuid() },
    })
    console.log({ request })
    const authorizationHandler = new RedirectRequestHandler()
    authorizationHandler.performAuthorizationRequest(configuration, request)
  }

  @action
  public async fetchServiceConfiguration(
    oidcConnectUrl: string
  ): Promise<AuthorizationServiceConfiguration> {
    try {
      const requestor = new NodeRequestor()
      const configuration = await AuthorizationServiceConfiguration.fetchFromIssuer(
        oidcConnectUrl,
        requestor
      )
      return configuration
    } catch (e) {
      console.log(e)
    }
  }

  @computed
  public get signInButtonShown(): boolean {
    if (this.isIE) return false
    if (this.passwordModeForced) return true
    if (!this.userAuthInfoResult) return false
    if (!this.userAuthInfoResult.success) return false
    if (this.userAuthInfoResult.orgOptions.length > 0 && this.selectedOrgOption === -1) return false
    return true
  }

  @computed
  public get passwordModeForced(): boolean {
    if (window.navigator.webdriver === true) return true
    if (window.location.href.indexOf('/password') > -1) return true
    if (this.hasOnlyPasswordOption) return true
    return false
  }

  @computed
  public get hasOnlyPasswordOption(): boolean {
    if (
      this.userAuthInfoResult &&
      this.userAuthInfoResult.success &&
      this.userAuthInfoResult.orgOptions.length === 1
    ) {
      const orgOption = this.userAuthInfoResult.orgOptions[0]
      if (!orgOption.oidcClientId || !orgOption.oidcConnectUrl) return true
    }
    return false
  }

  @computed
  public get nextButtonShown(): boolean {
    if (this.isIE) return false
    if (this.passwordModeForced) return false
    if (!this.userAuthInfoResult) return true
    if (!this.userAuthInfoResult.success) return true
    return false
  }

  @computed
  public get passwordShown(): boolean {
    if (this.passwordModeForced) return true
    if (!this.userAuthInfoResult) return false
    if (!this.userAuthInfoResult.success) return false
    if (this.userAuthInfoResult.orgOptions.length > 0 && this.selectedOrgOption === -1) return false
    if (this.handlingOIDCLogin) return false
    return true
  }

  @computed
  public get signInMethods(): SignInMethodRowVM[] {
    if (!this.userAuthInfoResult) return []
    if (!this.userAuthInfoResult.success) return []
    return this.userAuthInfoResult.orgOptions.map((e) => new SignInMethodRowVM(e))
  }

  @action
  private handleOIDCAuth() {
    if (!this.userAuthInfoResult || !this.userAuthInfoResult.orgOptions) return false
    if (this.userAuthInfoResult.orgOptions.length === 1) {
      const orgOption = this.userAuthInfoResult.orgOptions[0]
      if (orgOption.requireOIDCAuth) {
        this.loginWithOIDCProvider(orgOption)
      }
    } else if (this.selectedOrgOption) {
      const orgOption = this.signInMethods[this.selectedOrgOption].option
      if (orgOption.oidcConnectUrl && orgOption.oidcClientId) {
        this.loginWithOIDCProvider(orgOption)
      }
    }
    return false
  }

  @action
  public async setSelectedOrgOption(idx: number) {
    this.selectedOrgOption = idx
    this.handleOIDCAuth()
  }
}
