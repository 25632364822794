import { action, computed, observable, reaction } from 'mobx'
import { PasswordStrengthMeterVM } from '../../shared/password-strength/PasswordStrengthMeterVM'
import { RootStore } from '../../stores/RootStore'
import { IUserWelcomePasswordRequest } from '../interfaces/IUserWelcomePasswordRequest'
import { AuthenticationService } from '../services/AuthenticationService'

export class WelcomeVM {
  constructor(rootStore: RootStore, orgId: string) {
    this.rootStore = rootStore
    this.orgId = orgId
    this.meterVM = new PasswordStrengthMeterVM(rootStore.localizationStore)

    reaction(
      () => this.newPassword,
      () => {
        this.meterVM.setPassword(this.newPassword)
      }
    )
  }

  private rootStore: RootStore
  @observable public email: string = ''
  @observable public newPassword: string = ''
  @observable public confirmNewPassword: string = ''
  @observable public meterVM: PasswordStrengthMeterVM = null
  @observable public saveTried: boolean = false
  @observable public isProcessing: boolean = false
  @observable public showNewPassword: boolean = false
  @observable public showConfirmNewPassword: boolean = false
  @observable public orgId: string
  @observable public errorMessage: string = null

  public get emailValid() {
    if (!this.saveTried) return true
    let pattern = new RegExp(/[^\s@]+@[^\s@]+\.[^\s@]+/i)
    return pattern.test(this.email)
  }

  public get newPasswordValid() {
    if (!this.saveTried) return true
    if (!this.newPassword || this.newPassword.trim() === '') return false
    if (!this.isNewPasswordStrong) return false
    return true
  }

  public get confirmNewPasswordValid() {
    if (!this.saveTried) return true
    if (!this.confirmNewPassword || this.confirmNewPassword.trim() === '') return false
    return true
  }

  public get newPasswordsMatch() {
    if (!this.saveTried) return true
    if (this.newPassword !== this.confirmNewPassword) return false
    return true
  }

  @computed
  public get isNewPasswordStrong(): boolean {
    if (!this.saveTried) return true
    if (!this.newPassword || this.newPassword.trim() === '') return true
    if (this.meterVM.newPasswordStrengthScore < 5) return false
    return true
  }

  @action
  public goToLogin() {
    if (!this.rootStore.appStore.router) {
      window.location.href = '/'
      return
    }
    this.rootStore.appStore.router.push('/auth/login')
  }

  @action
  public markSaveTried() {
    this.saveTried = true
  }

  @computed
  public get isValid() {
    if (!this.saveTried) return true
    if (!this.newPasswordValid) return false
    if (!this.confirmNewPasswordValid) return false
    if (!this.emailValid) return false
    return true
  }

  @action handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault()
    this.markSaveTried()
    if (!this.isValid) return
    if (!this.newPasswordsMatch) return
    if (!this.isNewPasswordStrong) return
    this.isProcessing = true
    try {
      const req: IUserWelcomePasswordRequest = {
        orgId: this.orgId,
        email: this.email,
        password: this.newPassword,
      }
      const authSvc = new AuthenticationService()
      const result = await authSvc.welcomePasswordSet(req)
      if (result.success === true) {
        this.goToLogin()
      } else if (result.success === false) {
        this.errorMessage = result.errorMessage
        this.isProcessing = false
      }
    } catch (error) {
      console.log(error)
    }
  }

  @action
  public setPasswordMeterShown(isPasswordMeterShown: boolean) {
    if (this.newPassword) return
    this.meterVM.setPasswordMeterShown(isPasswordMeterShown)
  }

  @action
  public toggleShowNewPassword() {
    this.showNewPassword = !this.showNewPassword
  }

  @action
  public toggleShowConfirmNewPassword() {
    this.showConfirmNewPassword = !this.showConfirmNewPassword
  }
}
