import React from 'react'
import { Button } from '@material-ui/core'

export default class StyledButtons extends React.Component<any, any> {
  render() {
    return (
      <div>
        <h5>Contained</h5>
        <div>
          <Button variant='contained'>Enabled</Button>
          <Button variant='contained' disabled>
            Disabled
          </Button>
          <Button variant='contained' className={'btn-remove'}>
            Remove
          </Button>
        </div>

        <h5>Outlined</h5>
        <div>
          <Button variant='outlined'>Enabled</Button>
          <Button variant='outlined' disabled>
            Disabled
          </Button>
          <Button variant='outlined' className={'btn-remove'}>
            Remove
          </Button>
        </div>
        <h5>Text</h5>
        <div>
          <Button variant='text'>Enabled</Button>
          <Button variant='text' disabled>
            Disabled
          </Button>
          <Button variant='text' className={'btn-remove'}>
            Remove
          </Button>
        </div>
      </div>
    )
  }
}
