import { persist } from 'mobx-persist'
import { action, observable } from 'mobx'
import { ResponseType } from '../types/ResponseType'
import HashId from '../../shared/HashId'
import { serialize, deserialize, serializable } from 'serializr'
import { IOptionDTO } from '../dtos/IOptionDTO'

export class Option implements IOptionDTO {
  static create(opt: any) {
    console.log(opt)
    const option = new Option()
    option.rank = opt.rank ? opt.rank : 0
    option.id = opt.id ? opt.id : opt.rank
    option.text = opt.text
    option.isPositive = opt.isPositive
    option.iconName = opt.iconName
    option.isDeleted = opt.isDeleted
    option.weight = opt.weight
    option.emoji = opt.emoji
    return option
  }

  @serializable @observable public id: string = HashId.generate()
  @serializable @observable public text: string = ''
  @serializable @observable public iconName: string = ''
  @serializable @observable public rank: number = 0
  @serializable @observable public isPositive: boolean = false
  @serializable @observable public isDeleted: boolean = false
  @serializable @observable public weight: number = 0
  @serializable @observable public emoji: string = ''
  @serializable @observable public isChecked: boolean = false
  @serializable @observable public isCorrectAnswer: boolean = false

  @action
  public setWeight(val: number) {
    this.weight = val
  }

  @action
  public toggleIsChecked() {
    this.isChecked = !this.isChecked
  }

  @action
  public setIsCheckedValue(val: boolean) {
    this.isChecked = val
  }

  @action
  public setIsCorrect() {
    this.isCorrectAnswer = true
  }

  @action
  public setIsIncorrect() {
    this.isCorrectAnswer = false
  }

  @action
  public setRank(val: number) {
    this.rank = val
  }

  @action
  public setText(val: string) {
    this.text = val
  }

  @action
  public setEmoji(val: string) {
    this.emoji = val
  }

  public clone(): Option {
    return deserialize(Option, this.toDTO())
  }

  public toDTO(): IOptionDTO {
    return serialize(this)
  }
}
