import { Event } from '../../aggregate/Event'
import { observable, action } from 'mobx'
import { serialize, serializable, deserialize, list, primitive, date } from 'serializr'
import { IUserEventDTO } from '../dtos/IUserEventDTO'
import moment from 'moment'

export class UserEvent extends Event {
  @serializable @observable public eventId: string = ''
  @serializable @observable public readStatus: string = ''
  @serializable @observable public goingStatus: string = ''
  @serializable @observable public userId: string = ''
  @serializable @observable public isRead: boolean = false
  @serializable(date()) @observable public readDateTime: Date = null
  @serializable @observable public goingStatusOnce: boolean = false

  @action
  public setGoingStatus(val: string, once: boolean) {
    this.goingStatus = val
    this.goingStatusOnce = once
  }

  public clone(): UserEvent {
    return deserialize(UserEvent, this.serialize())
  }

  public serialize(): IUserEventDTO {
    const obj = serialize(this)
    obj.startDate = moment(this.startDate).toISOString()
    obj.endDate = moment(this.endDate).toISOString()
    if (obj.schedule) obj.schedule.endDate = moment(this.schedule.endDate).toISOString()
    return obj
  }
}
