import React from 'react'
import { PulseCampaignEditVM } from '../../view-models/PulseCampaignEditVM'
import { inject, observer } from 'mobx-react'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'
import './MixedAndTemplatePanel.scss'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { AgGridReact } from 'ag-grid-react'
import AGGridPagingRow from '../../../shared/ag-grid/AGGridPagingRow'
import { PulseCategoriesManageVM } from '../../view-models/PulseCategoriesManageVM'
import PulseCampaignSurveySelector from '../surveys/PulseCampaignSurveySelector'
import Searchbar from '../../../shared/Searchbar'
import PulseCampaignSurveyPreviewModal from '../surveys/PulseCampaignSurveyPreviewModal'
import PulseCampaignQuestionsPanel from '../questions/PulseCampaignQuestionsPanel'
import CampaignSurveyTemplateSelect from './survey-templates/CampaignSurveyTemplateSelect'

interface Props {
  editVM: PulseCampaignEditVM
  categoryVM: PulseCategoriesManageVM
}

const MixedAndTemplatePanel: React.FC<Props> = ({ editVM, categoryVM }) => {
  if (!editVM) return

  const renderToggle = () => {
    return (
      <div className={'mixed-toggle-container'}>
        <ToggleButtonGroup
          exclusive
          className={'mixed-toggle'}
          size='small'
          aria-label='questions-or-template-toggle'
          value={editVM.tabBuildMode}
          onChange={(e, value) => editVM.toggleCampaignBuildMode()}
        >
          <ToggleButton
            className={'mixed-toggle-btn'}
            value={'templates'}
            disabled={editVM.hasSelectedSurveyTemplate || editVM.pulseHasQuestions}
          >
            Template
          </ToggleButton>
          <ToggleButton
            className={'mixed-toggle-btn'}
            value={'questions'}
            disabled={editVM.hasSelectedSurveyTemplate || editVM.pulseHasQuestions}
          >
            Questions
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    )
  }

  const renderSelectedTemplate = () => {
    if (editVM.isSimplePreviewMode) return
    const currentSurvey = editVM.currentSurvey
    if (!currentSurvey.selectedSurveyTemplate) {
      return (
        <div className='spinner-container'>
          <CircularProgress className='spinner' />
          <Typography variant='h6' className='template-title'>
            Loading Template...
          </Typography>
        </div>
      )
    }

    return (
      <Grid item className='selected-template-screen'>
        <Typography variant='h6' className='template-title'>
          {currentSurvey.selectedSurveyTemplateName}
        </Typography>
        <Typography className='template-body-item'>
          {currentSurvey.selectedSurveyTemplateQuestionsCount}
        </Typography>
        <Typography className='template-body-item'>{`Created ${currentSurvey.selectedSurveyTemplateCreatedAt} by ${currentSurvey.selectedSurveyTemplateCreatedByName}`}</Typography>
        {!currentSurvey.areCreatedAtAndUpdatedAtTheSame && (
          <Typography className='template-body-item'>{`Updated ${currentSurvey.selectedSurveyTemplateUpdatedAt} by ${currentSurvey.selectedSurveyTemplateUpdatedByName}`}</Typography>
        )}
        <Typography className='template-body-item'>{`Owned by ${currentSurvey.selectedSurveyTemplateOwnerName}`}</Typography>
        <Grid item className='template-selected-actions'>
          {editVM.isSystemAdmin && (
            <Button
              variant='outlined'
              onClick={() => editVM.currentSurvey.navigateToSelectedSurveyTemplateEditPage()}
            >
              Edit Template
            </Button>
          )}
          <Button variant='outlined' onClick={() => editVM.previewSurvey()}>
            Preview Template
          </Button>
        </Grid>
        <Button
          variant='contained'
          className='template-reset'
          disabled={!currentSurvey.isEditable}
          onClick={() => {
            editVM.isTemplateBuildMode = false
            editVM.currentSurvey.resetSelectedSurveyTemplate()
          }}
        >
          Choose Different Template
        </Button>
      </Grid>
    )
  }

  const renderTable = () => {
    if (editVM.hasSelectedSurveyTemplate && !editVM.isSimplePreviewMode) return
    return <CampaignSurveyTemplateSelect editVM={editVM} />
  }

  const renderTemplatesPanel = () => {
    if (editVM.tabBuildMode === 'questions') return
    return (
      <Grid container className={'template-container'}>
        {renderTable()}
        {renderSelectedTemplate()}
      </Grid>
    )
  }

  const renderQuestionsPanels = () => {
    if (editVM.tabBuildMode === 'templates') return
    return (
      <>
        <Grid container className='questions-panels'>
          <Grid item xs={5} className={'pulse-campaign-build-questions-panel'}>
            <PulseCampaignQuestionsPanel vm={categoryVM} editVM={editVM} />
          </Grid>
          <Grid item xs={7} className={'pulse-campaign-build-survey-selector'}>
            <PulseCampaignSurveySelector vm={categoryVM} />
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <div id='MixedAndTemplatePanel'>
      <PulseCampaignSurveyPreviewModal vm={editVM.currentSurvey} evm={editVM} />
      {renderToggle()}
      <div className={'panel-container'}>
        {renderTemplatesPanel()}
        {renderQuestionsPanels()}
      </div>
    </div>
  )
}

export default inject('pulseCampaignsStore')(observer(MixedAndTemplatePanel))
