import env from '../../env'

export class MobxPersistStorage {
  private storage: Storage

  constructor() {
    this.storage = window.localStorage
    if (env.var.REACT_APP_USE_SESSION_STORAGE === '1') this.storage = window.sessionStorage
  }

  public clear() {
    return new Promise((resolve, reject) => {
      try {
        this.storage.clear()
        resolve(null)
      } catch (err) {
        reject(err)
      }
    })
  }

  public getItem(key: string): Promise<string> {
    return new Promise((resolve, reject) => {
      try {
        const value = this.storage.getItem(key)
        resolve(value)
      } catch (err) {
        reject(err)
      }
    })
  }

  public removeItem(key: string) {
    return new Promise((resolve, reject) => {
      try {
        this.storage.removeItem(key)
        resolve(null)
      } catch (err) {
        reject(err)
      }
    })
  }

  public setItem(key: string, value: string) {
    return new Promise((resolve, reject) => {
      try {
        this.storage.setItem(key, value)
        resolve(null)
      } catch (err) {
        reject(err)
      }
    })
  }
}
