import { computed, action, observable } from 'mobx'
import { AudienceMember } from '../../audience-members/aggregate/AudienceMember'
import { RootStore } from '../../stores/RootStore'
import { ParticipantsSelectVM } from './ParticipantsSelectVM'
import { Client } from '../../clients/aggregate/Client'
import { ContactsAGGridVM } from './ContactsAGGridVM'

export class ClientRowVM {
  private client: Client
  private rootStore: RootStore
  private selectVM: ParticipantsSelectVM
  contactsAGGridVM: ContactsAGGridVM

  constructor(rootStore: RootStore, selectVM: ParticipantsSelectVM, client: Client) {
    this.client = client
    this.rootStore = rootStore
    this.selectVM = selectVM
  }

  @observable public keywordFilter: string = ''
  @observable public iconURL: string = ''

  @computed
  public get isContactsListModalOpen(): boolean {
    return this.selectVM.isContactsListModalOpen
  }

  public openContactsListModal() {
    this.selectVM.openContactsListModal(this)
    if (!this.contactsAGGridVM)
      this.contactsAGGridVM = new ContactsAGGridVM(this.rootStore, this.selectVM, this)
    this.contactsAGGridVM.show()
  }

  public closeContactsListModal() {
    this.contactsAGGridVM = null
    this.selectVM.closeContactsListModal()
  }

  @computed
  public get typedFilterText(): string {
    if (!this.contactsAGGridVM) return ''
    return this.contactsAGGridVM.typedFilterText
  }

  @computed
  public get objectId(): string {
    return this.client.objectId
  }

  @computed
  public get name(): string {
    return this.client.name
  }

  @computed
  public get isReadOnly(): boolean {
    return this.selectVM.isReadOnly
  }

  @computed
  public get isParticipantSelected(): boolean {
    const isSelectedDirectly =
      this.selectVM.participants.filter((e) => e.id === this.objectId).length !== 0
    let isSelectedIndirectly = false
    return isSelectedDirectly || isSelectedIndirectly
  }

  @action
  public setFilter(val: string) {
    this.keywordFilter = val
  }

  @action
  public toggleParticipant() {
    const idx = this.selectVM.participants.findIndex((e) => e.id === this.objectId)
    if (idx === -1) {
      this.selectVM.addParticipantFromAudienceMember(this.client as unknown as AudienceMember)
    } else {
      this.selectVM.deleteParticipant(this.objectId)
    }
  }
}
