import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../localization/LocalizationStore'
import MuiConfirmPrompt from '../../shared/MuiConfirmPrompt'
import { EditVM } from '../store/view-models/EditVM'

interface Props {
  vm: EditVM
  localizationStore?: LocalizationStore
}

const NotificationTemplateConfirmDialog: React.FC<Props> = ({ vm, localizationStore }) => {
  const { notificationsWidgetDrawer: lz } = localizationStore.lzStrings

  if (!vm) return null

  const handleReset = () => {
    vm.cancelConfirmDialog()
    vm.handleReset()
  }

  const handleClose = () => {
    vm.cancelConfirmDialog()
    vm.closeEditor()
  }

  const handleSave = () => {
    vm.cancelConfirmDialog()
    vm.saveNotification()
  }

  if (!vm.confirmDialogOpen) return
  if (!vm.isDirty) return
  if (vm.doReset) {
    return (
      <MuiConfirmPrompt
        confirmMessage={lz.are_you_sure_reset}
        onClose={() => vm.cancelConfirmDialog()}
        open={vm.confirmDialogOpen}
        onConfirm={handleReset}
        title={`${lz.reset_editor}`}
      />
    )
  }
  if (vm.doClose) {
    return (
      <MuiConfirmPrompt
        confirmMessage={lz.are_you_sure_close}
        onClose={() => vm.cancelConfirmDialog()}
        open={vm.confirmDialogOpen}
        onConfirm={handleClose}
        title={`${lz.close_editor}`}
      />
    )
  }
  return (
    <MuiConfirmPrompt
      confirmMessage={lz.are_you_sure_save}
      onClose={() => vm.cancelConfirmDialog()}
      open={vm.confirmDialogOpen}
      onConfirm={handleSave}
      title={`${lz.save_notification}`}
      isSystemWarn={vm.isSystemEdit}
    />
  )
}

export default inject('localizationStore')(observer(NotificationTemplateConfirmDialog))
