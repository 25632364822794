import { RootStore } from '../../stores/RootStore'
import { observable, computed, action } from 'mobx'
import { CalendarVM } from './CalendarVM'
import { Survey } from '../aggregate/Survey'
import moment from 'moment'

export class SurveyPopupVM {
  private rootStore: RootStore
  private calendarVM: CalendarVM

  constructor(rootStore: RootStore, calendarVM: CalendarVM, userSurvey: Survey) {
    this.rootStore = rootStore
    this.userSurvey = userSurvey
    this.calendarVM = calendarVM
    setTimeout(() => (this.anchorEl = document.getElementsByClassName('selectedSurvey')[0]), 20)
  }

  @observable private userSurvey: Survey = null
  @observable public anchorEl: any = null

  @computed
  public get name(): string {
    return this.userSurvey.name
  }

  @computed
  public get from(): string {
    if (this.userSurvey.publishedByUserId === this.rootStore.appStore.currentUserId) return 'Me'
    return this.userSurvey.publishedByUserName
  }

  @computed
  public get questions(): string {
    if (this.userSurvey.questionsCount > 1) return `${this.userSurvey.questionsCount} Questions`
    return `${this.userSurvey.questionsCount} Question`
  }

  @computed
  public get dueDate(): string {
    const s = this.rootStore.localizationStore.lzStrings.surveys
    if (this.userSurvey.dueDate === null) return null
    return moment(this.userSurvey.dueDate).format(s.due_date_format)
  }

  @computed
  public get createdAt(): string {
    if (!this.userSurvey) return null
    const date = moment(this.userSurvey.createdAt).format('M/D/YY h:mm a')
    return date
  }

  @computed
  public get category(): string {
    return this.userSurvey.category
  }

  @computed
  public get surveyLink(): string {
    const url = '/usersurveys/take/' + this.userSurvey.objectId
    return url
  }

  @action
  public navigateToSurvey() {
    this.rootStore.appStore.router.push(this.surveyLink)
    this.close()
  }

  @action
  public close() {
    this.anchorEl = null
  }
}
