import React, { useRef } from 'react'
import { inject, observer } from 'mobx-react'
import rootStore from '../../../stores/RootStore'
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
  Theme,
  DialogActions,
  CircularProgress,
  OutlinedInput,
  InputLabel,
  Typography,
  DialogTitle,
} from '@material-ui/core'
import { Editor } from '@tinymce/tinymce-react'
import { PulseCampaignsStore } from '../../store/PulseCampaignsStore'
import './CompletedContentScreenDesignerDialog.scss'
import DialogHeader from './DialogHeader'

interface Props {
  pulseCampaignsStore?: PulseCampaignsStore
}

const useStyles = makeStyles((theme: Theme) => ({
  backDrop: {
    backdropFilter: 'blur(1px)',
    backgroundColor: 'rgba(0,0,30,0.9)',
  },
  input: {
    textAlign: 'center',
    '&::placeholder': {
      textAlign: 'center',
    },
  },
}))

const CompletedContentScreenDesigner: React.FC<Props> = ({ pulseCampaignsStore }) => {
  const classes = useStyles()
  const editVM = pulseCampaignsStore.viewModels.editVM
  if (!editVM) return null
  if (!editVM.currentSurvey) return null
  const vm = editVM.surveyCompletedContentVM

  const { pulseCampaign: strings } = rootStore.localizationStore.lzStrings

  const handleClose = () => {
    vm.applyTried = false
    vm.closeCompletedContentScreenDialog()
    if (vm.currentSurvey.originalSurveyCompletedContent !== vm.currentSurvey.surveyCompletedContent)
      setSurveyCompletedContent(vm.currentSurvey.originalSurveyCompletedContent)
  }

  const handleReset = () => {
    vm.applyTried = false
    setSurveyCompletedContent(vm.currentSurvey.originalSurveyCompletedContent)
  }

  const handleApply = () => {
    vm.applyTried = true
    if (isInvalid()) return
    if (vm.currentSurvey.originalSurveyCompletedContent !== vm.currentSurvey.surveyCompletedContent)
      setOriginalSurveyCompletedContent(vm.currentSurvey.surveyCompletedContent)
    handleClose()
  }

  const setOriginalSurveyCompletedContent = (val: string) => {
    vm.currentSurvey.setOriginalSurveyCompletedContent(val)
  }

  const setSurveyCompletedContent = (val: string) => {
    vm.currentSurvey.setSurveyCompletedContent(val)
  }

  const renderAddButtonModal = () => {
    return (
      <Dialog
        onClose={() => vm.toggleButtonModal()}
        open={vm.showButtonModal}
        id='CantDeleteDialog'
      >
        <DialogTitle className='add-button-modal-header'>
          Add a button to Completed Content
        </DialogTitle>
        <DialogContent className='add-button-modal-content'>
          <InputLabel htmlFor='button text' required>
            Button Text
          </InputLabel>
          <OutlinedInput
            id='ButtonText'
            fullWidth
            labelWidth={0}
            autoComplete='off'
            value={vm.buttonText}
            onChange={(e) => vm.setButtonText(e.target.value)}
            error={!vm.isButtonTextValid}
          />
          <InputLabel htmlFor='button URL' required>
            Button URL
          </InputLabel>
          <OutlinedInput
            id='ButtonURL'
            fullWidth
            labelWidth={0}
            autoComplete='off'
            value={vm.buttonURL}
            onChange={(e) => vm.setButtonURL(e.target.value)}
            error={!vm.isButtonURLValid}
          />
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={() => vm.addButtonToContent()}>
            OK
          </Button>
          <Button variant='contained' onClick={() => vm.closeButtonModal()}>
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const renderDialogTitle = () => {
    return (
      <div className='intro-text intro-header'>
        <DialogHeader
          pulseCampaignsStore={pulseCampaignsStore}
          title='Completed Content Designer'
          onClick={handleClose}
        />
      </div>
    )
  }

  const isInvalid = () => {
    if (!vm.currentSurvey.surveyCompletedContentValid && vm.applyTried) return true
    return false
  }

  const renderBodyText = () => {
    return (
      <Grid item xs={12} className='editor-section'>
        <div className={isInvalid() ? 'body-invalid' : null}>
          <Editor
            value={vm.currentSurvey.surveyCompletedContent}
            init={vm.tinyMCEInit}
            onEditorChange={setSurveyCompletedContent}
          />
        </div>
      </Grid>
    )
  }

  const renderBodyInput = () => {
    return <div className='intro-section'>{renderBodyText()}</div>
  }

  const renderBody = () => {
    return (
      <Grid item xs={12} className='body'>
        <Grid container spacing={2} direction='row'>
          <Grid item xs={5} className='intro-left'>
            <Typography component='h6' className='intro-editor-label'>
              Completed Content Information
            </Typography>
            <Button
              className='add-button'
              variant='contained'
              onClick={() => vm.toggleButtonModal()}
            >
              Add A Button
            </Button>
            {renderBodyInput()}
          </Grid>
          <Grid item xs={7} className='intro-right'>
            <Typography component='h6' className='intro-editor-label'>
              Completed Content Preview
            </Typography>
            <Grid item className='survey-completed-content-card'>
              <div
                className='survey-completed-content-text'
                dangerouslySetInnerHTML={{ __html: vm.surveyCompletedContent }}
              ></div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderActionButtons = () => {
    return (
      <Grid item xs={12} className='intro-buttons'>
        <Grid item>
          <Button variant='outlined' onClick={handleReset}>
            {strings.reset}
          </Button>
          <Button disabled={isInvalid()} variant='contained' onClick={handleApply}>
            {strings.apply}
          </Button>
        </Grid>
      </Grid>
    )
  }

  const renderDialogContent = () => {
    if (!vm) return null
    return (
      <DialogContent>
        <Grid
          id='CompletedContentScreenDesigner'
          container
          direction='column'
          justifyContent='center'
          alignItems='stretch'
        >
          {renderBody()}
        </Grid>
      </DialogContent>
    )
  }

  const renderDialogActions = () => {
    return (
      <DialogActions>
        <Grid
          id='CompletedContentScreenDesignerActions'
          container
          direction='column'
          justifyContent='center'
          alignItems='stretch'
        >
          {renderActionButtons()}
        </Grid>
      </DialogActions>
    )
  }

  const renderSpinner = () => {
    if (vm) return null
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  return (
    <Dialog
      id='IntroductionScreenDesignerDialog'
      open={vm.isCompletedContentScreenDesignerOpen}
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
      onClose={handleClose}
      fullWidth
    >
      <div className={'dialog-container'}>{renderDialogTitle()}</div>
      {renderSpinner()}
      {renderDialogContent()}
      {renderDialogActions()}
      {renderAddButtonModal()}
    </Dialog>
  )
}

export default inject('pulseCampaignsStore')(observer(CompletedContentScreenDesigner))
