import React from 'react'
import { inject, observer } from 'mobx-react'
import { WorksheetsStore } from '../../store/WorksheetsStore'
import './Content.scss'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import AGGridWidgetPagingRow from '../../../shared/ag-grid/AGGridPagingRow'
import AGGridUserCell from '../../../shared/ag-grid/AGGridUserCell'
import { WorksheetsListRowVM } from '../../view-models/list-widget/WorksheetsListRowVM'
import Searchbar from 'src/app/shared/Searchbar'
import zIndex from '@material-ui/core/styles/zIndex'

interface Props {
  worksheetsStore?: WorksheetsStore
}

const Content: React.FC<Props> = ({ worksheetsStore }) => {
  const vm = worksheetsStore.listWidgetVM
  if (!vm) return null
  if (!vm.shouldRender) return null

  const renderOwnerCell = (props) => {
    const row: WorksheetsListRowVM = props.data
    if (!row.worksheetOwner) return null
    return <AGGridUserCell userId={row.ownerId} name={row.ownerName} iconURL={row.ownerIconURL} />
  }

  const renderGrid = () => {
    const searchStyle: React.CSSProperties = {
      position: 'absolute',
      top: '5px',
      zIndex: 3,
      right: '10px',
    }
    return (
      <div className='ag-theme-alpine' style={{ height: '100%', width: '100%' }}>
        <Searchbar
          containerStyle={searchStyle}
          searchValue={vm.typedFilterText}
          onSearchChange={(e) => vm.setSearchFilter(e.target.value)}
        />
        <AgGridReact
          gridOptions={{
            ...vm.getGridOptions(),
            frameworkComponents: {
              ownerCell: renderOwnerCell,
            },
          }}
        />
      </div>
    )
  }

  return (
    <>
      {renderGrid()}
      <AGGridWidgetPagingRow vm={vm} />
    </>
  )
}

export default inject('worksheetsStore')(observer(Content))
