import React from 'react'
import { Tooltip } from '@material-ui/core'
import { IconButton } from '@material-ui/core'
import './ActionsCell.scss'
import { CatalogItemRowVM } from '../../../view-models/widgets/item-catalog-widget/CatalogItemRowVM'
import QueueIcon from '@material-ui/icons/Queue'
import EditIcon from '@material-ui/icons/Edit'

const ActionsCell = (props) => {
  const data: CatalogItemRowVM = props.data
  if (!data) return <div></div>

  const renderEditIcon = () => {
    if (!data.userOwned && !data.canEditAllTrainings) return null
    return (
      <Tooltip title='Edit Training Item' placement='bottom' enterDelay={100}>
        <IconButton size='small' onClick={() => data.editTrainingItem()}>
          <EditIcon id='edit-item' />
        </IconButton>
      </Tooltip>
    )
  }

  return (
    <div className='catalog-items-widget-actions-cell'>
      <Tooltip title={`Duplicate the training item`} placement='bottom-start' enterDelay={500}>
        <IconButton
          style={{ padding: '3px' }}
          color='secondary'
          onClick={() => data.createFromCatalog()}
        >
          <QueueIcon id='duplicate' />
        </IconButton>
      </Tooltip>
      {renderEditIcon()}
    </div>
  )
}

export default ActionsCell
