import { ColDef, GridOptions, RowClickedEvent } from 'ag-grid-community'
import { action, computed, observable } from 'mobx'
import { AGGridVM } from '../../../shared/ag-grid/AGGridVM'
import { nameOf } from '../../../shared/nameOf'
import { RootStore } from '../../../stores/RootStore'
import { DirectReportsDataStore } from './UsersDataStore'
import { MyAudienceWidgetVM } from './MyAudienceWidgetVM'
import { UserRowVM } from './UserRowVM'
import { IOrganizationUserAudienceSearchRequest } from '../../interfaces/IOrganizationUserAudienceSearchRequest'

export class UsersTabVM extends AGGridVM {
  private rootStore: RootStore
  private dataStore: DirectReportsDataStore
  @observable private shownOnce: boolean = false

  constructor(
    rootStore: RootStore,
    widgetVM: MyAudienceWidgetVM,
    request: IOrganizationUserAudienceSearchRequest,
    tabIndex: number
  ) {
    super()
    this.rootStore = rootStore
    this.widgetVM = widgetVM
    this.dataStore = new DirectReportsDataStore(this.rootStore, request)
    this.tabIndex = tabIndex
    this.serverSideLoaded = true
  }

  public widgetVM: MyAudienceWidgetVM
  public tabIndex: number

  public getRowNodeId(row: UserRowVM): string {
    return row.objectId
  }

  @action
  private setShownOnce() {
    this.shownOnce = true
  }

  @computed
  public get shown(): boolean {
    return this.widgetVM.tabIndex === this.tabIndex
  }

  @computed
  public get shouldRenderGrid(): boolean {
    if (this.shownOnce) return true
    if (this.shown) return true
    return false
  }

  public get shouldRender(): boolean {
    if (!this.rootStore.rolesStore.loaded) return false
    if (!this.rootStore.groupsStore.loaded) return false
    return true
  }

  @computed
  public get columnDefs(): ColDef[] {
    const s = this.rootStore.localizationStore.lzStrings
    return [
      {
        headerName: s.dashboard.name,
        field: nameOf<UserRowVM, string>((e) => e.name),
        sort: 'asc',
        cellRenderer: 'nameCell',
      },
      {
        headerName: s.dashboard.groups,
        field: nameOf<UserRowVM, string>((e) => e.groupsDelimited),
        sortable: false,
      },
      {
        headerName: s.dashboard.roles,
        field: nameOf<UserRowVM, string>((e) => e.rolesDelimited),
        sortable: false,
      },
      {
        headerName: s.userEdit.actions,
        field: 'actions',
        cellRenderer: 'actionsCell',
        sortable: false,
      },
    ]
  }

  public getGridOptions(): GridOptions {
    return {
      ...this.baseGridOptions,
      onRowClicked: (e) => this.rowClicked(e),
      getRowNodeId: (e) => this.getRowNodeId(e),
      columnDefs: this.ensureTooltipFields(this.columnDefs),
    }
  }

  @action
  public setQuickFilter(val: string) {
    this.typedFilterText = val
    if (this.quickFilterTO) clearTimeout(this.quickFilterTO)
    this.quickFilterTO = setTimeout(() => this.applyFilter(), 1000)
  }

  private applyFilter() {
    this.dataStore.setFilter(this.typedFilterText)
    this.reload()
    this.columnApi.applyColumnState({
      state: [{ colId: 'sortName', sort: 'asc' }],
    })
  }

  protected onGridReadied() {
    this.gridApi.setDatasource({
      rowCount: this.totalRecords,
      getRows: async (params) => {
        this.setShownOnce()
        params.sortModel.forEach((col: { colId: string; sort: 'asc' | 'desc' }, idx: number) => {
          let dbCol = col.colId
          if (dbCol === 'name') dbCol = 'fk_User.firstName'
          if (dbCol === 'email') dbCol = 'fk_User.email'
          if (idx === 0) this.dataStore.setSort(dbCol, col.sort)
          if (idx > 0) this.dataStore.addSort(dbCol, col.sort)
        })
        if (params.sortModel.length === 0) this.dataStore.setSort('fk_User.lastName')
        if (params.startRow === 0) this.dataStore.setPage(0)
        await this.dataStore.getNextPage()
        const rows = this.dataStore.organizationUsers.map(
          (e) => new UserRowVM(this.rootStore, e, this)
        )
        params.successCallback(rows, this.dataStore.totalRecords)
      },
    })
  }

  public get rows(): UserRowVM[] {
    return []
  }

  public rowClicked(e: RowClickedEvent) {
    console.debug(e)
  }

  @computed
  protected get totalRecords() {
    return this.dataStore.totalRecords
  }

  @action
  public setEmailAnchorEl(type: string, val: any, row: any) {
    this.widgetVM.setEmailAnchorEl(type, val, row)
  }

  @computed
  public get hasRows(): boolean {
    return this.dataStore.hasListRecords
  }

  @computed
  public get noRowsToShowLabel() {
    return this.rootStore.localizationStore.lzStrings.dashboard.no_rows_to_show
  }
}
