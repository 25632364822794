import React from 'react'
import { Card } from '@material-ui/core'
import { observer, inject } from 'mobx-react'
import { MediaItemsStore } from '../../store/MediaItemsStore'
import Header from './Header'
import Content from './Content'
import MediaItemDrawer from '../Drawer/MediaItemDrawer'
import './MediaItemsListWidget.scss'

interface Props {
  mediaItemsStore?: MediaItemsStore
}

const MediaItemsListWidget: React.FC<Props> = ({ mediaItemsStore }) => {

  return (
    <>
      <Card id='MediaItemsListWidget' elevation={0}>
        <Header />
        <Content />
      </Card>
      <MediaItemDrawer />
    </>
  )
}

export default inject('mediaItemsStore')(observer(MediaItemsListWidget))
