import React from 'react'
import { inject, observer } from 'mobx-react'
import { Card, CircularProgress, Grid, IconButton, Typography } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { PulseSetRowVM } from '../../view-models/manage/PulseSetRowVM'
import AddIcon from '@material-ui/icons/Add'
import './PulseSetRowDetails.scss'
import { Organization } from '../../../organizations/aggregate/Organization'
import { PulseCampaign } from '../../../pulse-campaigns/aggregate/PulseCampaign'
import LocalizationStore from '../../../localization/LocalizationStore'

interface Props {
  vm: PulseSetRowVM
  localizationStore?: LocalizationStore
}

const PulseSetRowDetails: React.FC<Props> = ({ vm, localizationStore }) => {
  if (!vm) return null
  if (!vm.isExpanded) return null
  const lz = localizationStore.lzStrings.pulseSetMGMT

  const renderDetails = () => {
    if (!vm.isLoaded) return null
    return (
      <Card className={'pulse-set-details-card'}>
        <div className={'pulse-set-orgs'}>
          <Typography className={'details-header'}>{lz.organizations}</Typography>
          <div className={'orgs-container'}>
            {renderNoOrganizations()}
            {renderOrganizations()}
          </div>
        </div>
        <div className={'pulse-set-templates'}>
          <div className={'templates-header'}>
            <Typography className={'templates-title'}>{lz.templates}</Typography>
            {/* {renderAddTemplateButton()} */}
          </div>
          {renderNoCampaignTemplates()}
          {renderCampaignTemplates()}
        </div>

      </Card>
    )
  }

  const renderAddTemplateButton = () => {
    return (
      <IconButton
        disableRipple
        onClick={() => vm.createNewCampaignTemplate()}
        className={'add-icon-container'}
        size='small'
      >
        <AddIcon />
      </IconButton>)
  }

  const renderOrganizations = () => {
    const orgs = []
    vm.pulseSetOrganizations.forEach((org: Organization, i: number) => {
      orgs.push(
        <div key={'org' + i} className={'org-container'}>
          <Typography className={'org-name'}>{org.name}</Typography>
        </div>
      )
    })
    return orgs
  }

  const renderCampaignTemplates = () => {
    const templates = []
    vm.pulseSetTemplates.forEach((template: PulseCampaign, i: number) => {
      templates.push(
        <Card key={'template' + i} className={'template-container'} onClick={() => vm.editCampaignTemplate(template.objectId)}>
          <div className={'template-details'}>
            <Typography className={'template-name'}>{template.name}</Typography>
            <div className={'surveys-container'}>
              {renderSurveys(template)}
            </div>
          </div>
          <EditIcon />
        </Card>
      )
    })
    return templates
  }

  const renderSurveys = (template: PulseCampaign) => {
    const surveyNames = template.surveys.map((s) => s.name)
    return <Typography className={'survey-name'}>{surveyNames.join(', ')}</Typography>
  }

  const renderSpinner = () => {
    if (vm.isLoaded) return null
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  const renderNoOrganizations = () => {
    if (vm.pulseSetOrganizations.length) return null
    return <span>{lz.no_orgs}</span>
  }

  const renderNoCampaignTemplates = () => {
    if (vm.pulseSetTemplates.length) return null
    return <span>{lz.no_templates}</span>
  }

  return (
    <div id={'PulseSetRowDetails'}>
      {renderSpinner()}
      {renderDetails()}
    </div>
  )
}

export default inject('localizationStore')(observer(PulseSetRowDetails))
