import { computed, action } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { ColDef, GridOptions, RowClickedEvent } from 'ag-grid-community'
import { AGGridVM } from '../../shared/ag-grid/AGGridVM'
import { nameOf } from '../../shared/nameOf'
import { SurveyTagsRowVM } from './SurveyTagsRowVM'
import SurveyTag from '../aggregate/SurveyTag'

export class SurveyTagsWidgetVM extends AGGridVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    super()
    this.rootStore = rootStore
  }

  @computed
  public get rows(): SurveyTagsRowVM[] {
    return this.rootStore.surveyTagsStore.surveyTags.map(
      (e) => new SurveyTagsRowVM(this.rootStore, e, this)
    )
  }

  @action
  public getRowNodeId(row: SurveyTag): string {
    return row.objectId
  }

  public get shouldRender(): boolean {
    if (!this.rootStore.surveyTagsStore.loaded) return false
    return true
  }

  public getGridOptions(): GridOptions {
    return {
      ...this.baseGridOptions,
      rowData: this.rows,
      onRowClicked: (e) => this.rowClicked(e),
      getRowNodeId: (e) => this.getRowNodeId(e),
      columnDefs: this.ensureTooltipFields(this.columnDefs),
    }
  }

  public rowClicked(e: RowClickedEvent) {
    const row: SurveyTagsRowVM = e.data
    if (!row) return
    row.editSurveyTag()
  }

  @computed
  public get columnDefs(): ColDef[] {
    const s = this.rootStore.localizationStore.lzStrings
    return [
      {
        headerName: 'Name',
        field: nameOf<SurveyTagsRowVM, string>((e) => e.name),
        sort: 'asc',
      },
      {
        headerName: s.surveyTypesWidget.description,
        field: nameOf<SurveyTagsRowVM, string>((e) => e.description),
      },
    ]
  }
}
