import React, { FC } from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../localization/LocalizationStore'
import { ConsentsStore } from '../store/ConsentsStore'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

interface ConsentProps {
  localizationStore?: LocalizationStore
  consentsStore?: ConsentsStore
}

const ConsentWidgetBody: FC<ConsentProps> = ({ localizationStore, consentsStore }) => {
  const strings = localizationStore.lzStrings
  const vm = consentsStore.consentWidgetVM
  if (!vm) return null

  const handleClickCreate = () => {
    vm.toggleDrawer()
    vm.setDrawerMode('create')
  }

  const noConsentContent = vm.latestConsent ? null : (
    <div>
      <Typography variant='body1' className='consent-row'>
        Consent is <span style={{ color: 'red' }}>disabled</span> for your organization.
      </Typography>
      <Typography variant='body1' className='consent-row'>
        To enable consent, you must create a consent.
      </Typography>
      <div>
        <Button variant='contained' onClick={handleClickCreate}>
          Create a Consent
        </Button>
      </div>
    </div>
  )

  const content = vm.latestConsent ? (
    <div className='consent-content-container'>
      <div className='consent-required-status'>
        <div>Consent is currently</div>
        <div>
          {vm.isConsentRequiredForLogin ? (
            <span className='required'>required</span>
          ) : (
            <span className='not-required'>not required</span>
          )}
        </div>
        <div>for login.</div>
      </div>
      <div>
        <div>Current Version</div>
        <div className='consent-version-text'>{vm.latestConsent.versionNumber}</div>
      </div>
    </div>
  ) : null

  return (
    <CardContent className='card-content'>
      {noConsentContent}
      {content}
    </CardContent>
  )
}

export default inject('localizationStore', 'consentsStore')(observer(ConsentWidgetBody))
