import React from 'react'
import { Checkbox, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { observer } from 'mobx-react'
import './WeightProfileImportRow.scss'
import { ExportRoleVM } from 'src/app/weight-profiles/view-models/ExportRoleVM'

interface Props {
  row: ExportRoleVM
  style: any
}

const ExportRoleRow: React.FC<Props> = ({ row, style }) => {
  return (
    <ListItem style={style} key={`exportRole-${row.objectId}`} selected={row.isSelected}>
      <ListItemIcon>
        <Checkbox
          onChange={() => row.toggleIsSelected()}
          edge='start'
          checked={row.isSelected}
          disableRipple
        />
      </ListItemIcon>
      <ListItemText primary={row.name} />
    </ListItem>
  )
}

export default observer(ExportRoleRow)
