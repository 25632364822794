import React from 'react'
import rootStore from '../../../../stores/RootStore'
import { observer, useLocalStore } from 'mobx-react'
import { Card, CardHeader, Grid, Typography } from '@material-ui/core'
import './DraftCampaignsCard.scss'
import { DraftPulseCampaignsVM } from '../../../view-models/dashboard/draft-campaigns/DraftPulseCampaignsVM'
import { DraftPulseCampaignRowVM } from '../../../view-models/dashboard/draft-campaigns/DraftPulseCampaignRowVM'
import editIcon from '../../../../../assets/img/campaign/editIcon.png'

const DraftCampaignsCard: React.FC = () => {
  const localStore = useLocalStore(() => ({
    vm: new DraftPulseCampaignsVM(rootStore),
  }))

  const vm = localStore.vm
  const lz = vm.lz

  const renderRow = (row: DraftPulseCampaignRowVM, index: number) => {
    return (
      <Grid
        item
        key={index}
        onClick={(e) => {
          row.editPulseCampaign()
        }}
      >
        <Grid container className='draft-row'>
          <Grid item xs={10} className='draft-text'>
            <Typography className='name-text'>{row.name}</Typography>
            <Typography className='date-text'>{`${lz.draft_created_at} ${row.createdAt}`}</Typography>
          </Grid>
          <Grid item xs={2} className='arrow-cont'>
            <div className='arrow'>
              <div className='line'></div>
              <div className='point'></div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderNoDrafts = () => {
    if (vm.hasRows) return null
    return <Typography className='no-drafts'>{lz.no_drafts_to_show}</Typography>
  }

  const renderRows = () => {
    if (!vm.hasRows) return null
    return (
      <Grid container className='draft-container'>
        {vm.rows.map((e, idx) => renderRow(e, idx))}
      </Grid>
    )
  }

  const renderCard = () => {
    return (
      <Card className={'draft-campaigns-card'}>
        <Grid container>
          <Grid item xs={10}>
            <CardHeader
              className='header-text'
              title={lz.drafts}
              subheader={lz.campaigns_not_yet_initiated}
            />
          </Grid>
          <Grid item xs={2} className={'icon-container'}>
            <div className={'draft-campaigns-icon'}>
              <img className='edit-icon' alt='File Icon' src={editIcon} />
            </div>
          </Grid>
        </Grid>
        {renderNoDrafts()}
        {renderRows()}
      </Card>
    )
  }

  return <div id='DraftCampaignsCard'>{renderCard()}</div>
}

export default observer(DraftCampaignsCard)
