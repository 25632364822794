import React, { FC } from 'react'
import { observer, inject } from 'mobx-react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import LocalizationStore from '../localization/LocalizationStore'
import { UserStore } from '../user/store/UserStore'
import { makeStyles } from '@material-ui/core/styles'

interface Props {
  localizationStore?: LocalizationStore
  userStore?: UserStore
}

const useStyles = makeStyles({
  title: {
    backgroundColor: '#f0eff4',
  },
  content: {
    paddingTop: '15px',
    height: '90px',
  },
})

const HelpDialog: FC<Props> = ({ localizationStore, userStore }) => {
  const classes = useStyles({})
  const vm = userStore.avatarMenuVM
  if (!vm) return null
  const lz = localizationStore.lzStrings.helpDialog

  return (
    <Dialog className='' open={vm.helpDialogOpen} maxWidth={'sm'}>
      <DialogTitle className={classes.title}>
        <h4>{lz.help}</h4>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <p>
          <a
            href='https://www.rippleworx.com/privacy-policy'
            target='_blank'
            rel='noopener noreferrer'
            onClick={() => vm.toggleHelpDialog()}
          >
            {lz.privacy_policy}
          </a>
        </p>

        <div className=''>
          {lz.contact_us}:{' '}
          <a onClick={() => vm.toggleHelpDialog()} href='mailto: Support@rippleworx.com'>
            support@rippleworx.com
          </a>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => vm.toggleHelpDialog()}>
          {lz.close}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default inject('localizationStore', 'userStore')(observer(HelpDialog))
