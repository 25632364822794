import { action, observable } from 'mobx'
import { serializable, serialize, deserialize, list, object } from 'serializr'
import moment from 'moment'
import uuid from 'uuid/v4'
import { IPulseCampaignSurveyDTO } from '../dto/IPulseCampaignSurveyDTO'
import { PulseCampaignSurveyStatusType } from '../type/PulseCampaignSurveyStatusType'
import PulseQuestion from '../../pulse-questions/aggregate/PulseQuestion'
import { Attachment } from '../../upload/aggregate/Attachment'
import { DateUtils } from '../../shared/data/DateUtils'
import { EmailTemplate } from '../../email-templates/aggregate/EmailTemplate'

export class PulseCampaignSurvey implements IPulseCampaignSurveyDTO {
  public static create(orgId: string, isTemplate?: boolean) {
    const pulse = new PulseCampaignSurvey()
    pulse.name = ''
    pulse.organizationId = orgId
    pulse.isDeleted = false
    pulse.isTemplate = isTemplate ? isTemplate : false
    pulse.setDeliveryTime(
      moment(
        moment()
          .minute(Math.ceil(moment().minute() / 15) * 15)
          .second(0)
      ).toISOString()
    )
    pulse.setDeliveryTimeZone(moment.tz.guess())
    return pulse
  }

  @serializable @observable public id: string = uuid()
  @serializable @observable public name: string = undefined
  @serializable @observable public organizationId: string = ''
  @serializable @observable public ownerUserId: string = ''
  @serializable @observable public surveyId?: string = ''
  @serializable @observable public deliveryTime: string = moment(new Date()).toISOString()
  @serializable @observable public deliveryTimeZone: string = moment.tz.guess()
  @serializable @observable public surveyIntroduction?: string = ''
  @serializable @observable public originalSurveyIntroduction: string = ''
  @serializable(list(object(PulseQuestion))) @observable public questions: PulseQuestion[] = []
  @serializable @observable public isDeleted: boolean = false
  @serializable @observable public isTemplate: boolean = false
  @serializable @observable public status: PulseCampaignSurveyStatusType = 'scheduled'
  @serializable(object(Attachment)) @observable public attachment: Attachment = undefined
  @serializable @observable public deliveryDateManuallySet: boolean = false
  @serializable(list(object(EmailTemplate)))
  @observable
  public publishedTemplates: EmailTemplate[] = []
  @serializable(list(object(EmailTemplate))) @observable public reminderTemplates: EmailTemplate[] =
    []
  @serializable @observable public surveyCompletedContent?: string = ''
  @serializable @observable public originalSurveyCompletedContent?: string = ''
  @serializable @observable public surveyTemplateId: string = undefined

  public serialize(): IPulseCampaignSurveyDTO {
    return serialize(this)
  }

  public clone(): PulseCampaignSurvey {
    return deserialize(PulseCampaignSurvey, this.serialize())
  }

  @action
  public setSurveyTemplateId(id: string) {
    this.surveyTemplateId = id
  }

  @action
  public setId(id: string) {
    this.id = id
  }

  @action
  public setStatus(status: PulseCampaignSurveyStatusType) {
    this.status = status
  }

  @action
  public setOrganizationId(organizationId: string) {
    this.organizationId = organizationId
  }

  @action
  public setName(name: string) {
    this.name = name
  }

  @action
  public setDeliveryTimeZone(timeZone) {
    this.deliveryTimeZone = timeZone
  }

  @action
  public setDeliveryTime(val) {
    this.deliveryTime = moment(val).toISOString()
  }

  @action
  public setDeliveryDateManuallySet(bool) {
    this.deliveryDateManuallySet = bool
  }

  @action
  public resetManualFlag() {
    this.deliveryDateManuallySet = false
  }
}
