import { action, observable, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { RoleForm } from './RoleForm'
import { without } from 'lodash'
import { CategoryForm } from './CategoryForm'
import { RolesUpdateService } from '../service/RolesUpdateService'

export default class RoleNewForm implements RoleForm {
  rootStore: RootStore
  svc: RolesUpdateService

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.svc = new RolesUpdateService(this.rootStore)
  }

  @observable public name: string = ''
  @observable public description: string = ''
  @observable public privilegeSets: Array<any> = []
  @observable public saveTried: boolean = false
  @observable public categoryForms: Array<CategoryForm> = []

  @action
  public markSaveTried() {
    this.saveTried = true
  }

  @action
  public removeCategory(idx) {
    this.categoryForms.splice(idx, 1)
  }

  @action
  public addCategory() {
    this.categoryForms.push(new CategoryForm(this.rootStore, null))
  }

  @action
  public removePrivilegeSet(objectId) {
    const privilegeSets = without(this.privilegeSets, objectId)
    this.privilegeSets = privilegeSets
  }

  @action
  public addPrivilegeSet(objectId) {
    this.privilegeSets.push(objectId)
  }

  @computed
  public get nameValid() {
    if (!this.saveTried) return true
    if (!this.name || this.name.trim() === '') return false
    return true
  }

  @computed
  public get sumOfCategoryWeight(): number {
    let weight = 0
    this.categoryForms.forEach(e => {
      weight += e.weight
    })
    weight = Math.ceil(weight)
    return weight
  }

  @computed
  public get nameUnique() {
    if (!this.saveTried) return true
    const foundRole = this.rootStore.rolesStore.getRoleByName(this.name)
    if (foundRole) return false
    return true
  }

  @computed
  public get isValid() {
    if (!this.saveTried) return true
    if (!this.nameValid) return false
    if (!this.weightValid) return false
    if (!this.categoriesValid) return false
    if (!this.categoriesUnique) return false
    if (!this.nameUnique) return false
    return true
  }

  @computed
  public get weightValid() {
    if (!this.saveTried) return true
    if (this.categoryForms.length === 0) return true
    if (this.sumOfCategoryWeight !== 100) return false
    return true
  }

  private hasDuplicates(array) {
    return new Set(array).size !== array.length
  }

  public get categoriesUnique(): boolean {
    if (!this.saveTried) return true
    const categoryIds = []
    this.categoryForms.forEach(e => {
      categoryIds.push(e.categoryId)
    })
    if (this.hasDuplicates(categoryIds) === false) return true
  }

  @computed
  public get categoriesValid(): boolean {
    if (!this.saveTried) return true
    let categoriesValid = true
    this.categoryForms.forEach(e => {
      if (!e.categoryId) categoriesValid = false
    })
    return categoriesValid
  }

  @action
  public async save() {
    this.markSaveTried()
    if (!this.isValid) return false
    try {
      const newRole = {
        name: this.name,
        description: this.description,
        privilegeSets: this.privilegeSets,
        // categories: this.categoryForms.map(e => e.toDTO()),
        isDeleted: false,
      }
      await this.svc.saveRole(this.rootStore.appStore.currentOrgId, newRole)
      this.rootStore.rolesStore.clearForms()
    } catch (e) {
      console.error(e)
      return false
    }
  }
}
