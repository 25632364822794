import { RootStore } from '../../stores/RootStore'
import { action, observable, computed, reaction } from 'mobx'
import { EmailTemplateVM } from './EmailTemplateVM'
import { NotificationTemplate } from '../../notifications/store/aggregates/NotificationTemplate'
import { EmailTemplateType } from '../types/EmailTemplateType'
import { Survey } from '../aggregate/Survey'

export class EmailTemplatesVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, survey: Survey) {
    this.rootStore = rootStore
    this.loadSelectOptions()
    this.loadData(survey)
    this.setSelectedTemplateEditor('Survey Published (en)')
  }

  @observable public emailTemplates: Array<EmailTemplateVM> = []
  @observable public selectedTemplateEditor: EmailTemplateType = null
  @observable public showConfirmDialog: boolean = false
  @observable public editorRef: any = null
  public selectOptions: Array<EmailTemplateType> = []
  @observable public editingPublishedNotifications: boolean = false
  @observable public editingReminders: boolean = false

  @action
  public editPublishedNotificationTemplates() {
    this.editingPublishedNotifications = true
    this.editingReminders = false
    this.selectOptions = [
      {
        label: 'Survey Published (en)',
        typeId: 'survey-published',
        value: 'survey-published(en)',
        language: 'en',
      },
      {
        label: 'Survey Published (de)',
        typeId: 'survey-published',
        value: 'survey-published(de)',
        language: 'de',
      },
    ]
    this.setSelectedTemplateEditor('Survey Published (en)')
  }

  @action
  public editReminderTemplates() {
    this.editingReminders = true
    this.editingPublishedNotifications = false
    this.selectOptions = [
      {
        label: 'Survey Reminder (en)',
        typeId: 'survey-reminder',
        value: 'survey-reminder(en)',
        language: 'en',
      },
      {
        label: 'Survey Reminder (de)',
        typeId: 'survey-reminder',
        value: 'survey-reminder(de)',
        language: 'de',
      },
    ]
    this.setSelectedTemplateEditor('Survey Reminder (en)')
  }

  @action
  public toggleShowConfirmDialog() {
    this.showConfirmDialog = !this.showConfirmDialog
  }

  @action
  private loadTemplates() {
    this.loadCurrentTemplates()
    reaction(
      () => this.currentOrgTemplates.length,
      () => {
        this.loadCurrentTemplates()
      }
    )
  }

  @action
  public loadData(survey) {
    if (!survey.emailTemplates) return this.loadTemplates()
    if (survey.emailTemplates.length === 0) {
      return this.loadTemplates()
    }
    this.emailTemplates = survey.emailTemplates.map((e) => new EmailTemplateVM(this.rootStore, e))
  }

  @action
  private loadCurrentTemplates() {
    if (this.currentOrgTemplates.length === 0) return
    for (let template of this.currentOrgTemplates) {
      this.emailTemplates.push(new EmailTemplateVM(this.rootStore, template))
    }
  }

  @action
  public resetToDefaultTemplate(editorRef: any) {
    const foundTemplate = this.currentOrgTemplates.find(
      (e) =>
        e.notificationTypeId === this.currentEmailTemplate.notificationTypeId &&
        e.language === this.currentEmailTemplate.language
    )
    const foundIndex = this.emailTemplates.findIndex(
      (e) =>
        e.notificationTypeId === this.currentEmailTemplate.notificationTypeId &&
        e.language === this.currentEmailTemplate.language
    )
    if (foundTemplate) {
      this.emailTemplates.splice(foundIndex, 1)
      this.emailTemplates.push(new EmailTemplateVM(this.rootStore, foundTemplate))
      if (
        editorRef &&
        editorRef.current &&
        editorRef.current.editor &&
        editorRef.current.editor.setContent
      ) {
        editorRef.current.editor.setContent(foundTemplate.body)
      }
    }
  }

  @action
  private loadSelectOptions() {
    this.selectOptions = [
      {
        label: 'Survey Published (en)',
        typeId: 'survey-published',
        value: 'survey-published(en)',
        language: 'en',
      },
      {
        label: 'Survey Published (de)',
        typeId: 'survey-published',
        value: 'survey-published(de)',
        language: 'de',
      },
      {
        label: 'Survey Reminder (en)',
        typeId: 'survey-reminder',
        value: 'survey-reminder(en)',
        language: 'en',
      },
      {
        label: 'Survey Reminder (de)',
        typeId: 'survey-reminder',
        value: 'survey-reminder(de)',
        language: 'de',
      },
    ]
  }

  @action
  public setSelectedTemplateEditor(val) {
    let foundOption = this.selectOptions.find((e) => e.label === val)
    this.selectedTemplateEditor = foundOption
    if (
      this.editorRef &&
      this.editorRef.current &&
      this.editorRef.current.editor &&
      this.editorRef.current.editor.setContent
    ) {
      this.editorRef.current.editor.setContent(this.currentEmailTemplate.body)
    }
  }

  @computed
  public get hasEnglish() {
    return this.rootStore.organizationsStore.currentOrganization.hasEnglish
  }

  @computed
  public get hasGerman() {
    return this.rootStore.organizationsStore.currentOrganization.hasGerman
  }

  @action
  public setEditorRef(editorRef: any) {
    this.editorRef = editorRef
  }

  @computed
  public get currentEmailTemplate(): EmailTemplateVM {
    if (!this.selectedTemplateEditor) return null
    const emailTemplate = this.emailTemplates.filter(
      (e) =>
        e.language === this.selectedTemplateEditor.language &&
        e.notificationTypeId === this.selectedTemplateEditor.typeId
    )
    if (!emailTemplate) return null
    return emailTemplate[0]
  }

  @computed
  public get currentOrgTemplates(): Array<NotificationTemplate> {
    if (!this.rootStore && !this.rootStore.notificationsStore) return []
    let currentOrgTemplates = this.rootStore.notificationsStore.currentOrgNotificationTemplates
    currentOrgTemplates = currentOrgTemplates.filter((e) => e.channel === 'EMAIL')
    currentOrgTemplates = currentOrgTemplates.filter(
      (e) =>
        e.notificationTypeId === 'survey-published' || e.notificationTypeId === 'survey-reminder'
    )
    return currentOrgTemplates
  }

  @computed
  public get emailTemplateNames(): Array<string> {
    const list = []
    if (this.editingPublishedNotifications) {
      if (this.hasEnglish || (!this.hasEnglish && !this.hasGerman)) {
        list.push('Survey Published (en)')
      }
      if (this.hasGerman) {
        list.push('Survey Published (de)')
      }
    }
    if (this.editingReminders) {
      if (this.hasEnglish || (!this.hasEnglish && !this.hasGerman)) {
        list.push('Survey Reminder (en)')
      }
      if (this.hasGerman) {
        list.push('Survey Reminder (de)')
      }
    }
    return list
  }
}
