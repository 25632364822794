import { observable } from 'mobx'
import { IOptionDTO } from '../dtos/IOptionDTO'
import { serializable, list, object } from 'serializr'
import { Option as SurveyOption } from '../../surveys/aggregate/Option'

export class Option extends SurveyOption implements IOptionDTO {
  // static create(opt: any) {
  //   const option = new Option()
  //   option.isChecked = opt.isChecked
  //   option.rank = opt.rank ? opt.rank : 0
  //   option.id = opt.id ? opt.id : opt.rank
  //   option.text = opt.text
  //   option.subText = opt.subText
  //   option.isPositive = opt.isPositive
  //   option.emoji = opt.emoji
  //   if (!option.emoji) option.emoji = opt.iconName
  //   option.value = opt.value
  //   option.isDeleted = opt.isDeleted
  //   option.weight = opt.weight
  //   option.responseNumber = opt.responseNumber
  //   option.nextQuestionId = opt.nextQuestionId ? opt.nextQuestionId : null
  //   option.goToEnd = opt.goToEnd ? true : false
  //   return option
  // }

  @serializable @observable text: string = ''
  @serializable @observable isChecked: boolean = false
  @serializable @observable responseNumber: number = 0
}
