import { RootStore } from '../../../../stores/RootStore'
import { observable, computed, action } from 'mobx'
import { FilterPopupVM } from './FilterPopupVM'
import { ICatalogItemsFindRequest } from '../../../../training-items/interfaces/ICatalogItemsFindRequest'
import { CatalogItemsTabVM } from './CatalogItemsTabVM'
import { TrainingItemsService } from '../../../../training-items/service/TrainingItemsService'

export class CatalogItemsWidgetVM {
  private rootStore: RootStore
  public svc: TrainingItemsService

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.filterPopupVM = new FilterPopupVM(rootStore, this)
    this.tabIndex = 0
    this.svc = new TrainingItemsService()
    this.loadSurveyTabs()
  }

  @observable public keywordFilter: string = ''
  @observable public deletingOrgId: string = ''
  @observable public sortType: string = 'Date'
  @observable public filterPopupAnchorEl = null
  @observable public filterPopupVM: FilterPopupVM = null
  @observable public allCatalogItemsTab: CatalogItemsTabVM
  @observable public tabIndex: number = 0

  @action setKeywordFilter(val) {
    this.keywordFilter = val
  }

  @action createNewCatalogItem() {
    this.rootStore.appStore.router.push('/trainingItems/from-catalog/edit/new')
  }

  @action
  public onFilterChanged() {
    const filters = {
      categories: undefined,
    } as ICatalogItemsFindRequest
    if (this.filterPopupVM.filterCategoryIds.length > 0) {
      filters.categories = this.filterPopupVM.filterCategoryIds.slice()
    }
    this.allCatalogItemsTab.dataStore.setRequest({
      ...this.allCatalogItemsTab.dataStore.getRequest,
      ...filters,
    })

    this.applySelectedTabFilter()
  }

  private applySelectedTabFilter() {
    if (this.tabIndex === 0 && this.allCatalogItemsTab.shownOnce)
      this.allCatalogItemsTab.applyFilter()
  }

  @computed
  public get hasPopupFilter() {
    return this.filterPopupVM.hasFilter
  }

  @observable
  public setFilterPopupAnchorEl(el) {
    this.filterPopupAnchorEl = el
  }

  @action
  private loadSurveyTabs() {
    this.allCatalogItemsTab = new CatalogItemsTabVM(
      this.rootStore,
      this,
      {} as ICatalogItemsFindRequest,
      0
    )
  }
}
export default CatalogItemsWidgetVM
