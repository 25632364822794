import React from 'react'
import { inject, observer } from 'mobx-react'
import { Card, Fade, Popover, Link } from '@material-ui/core'
import UserSurveyPopupToolbar from './UserSurveyPopupToolbar'
import UserSurveyPopupBody from './UserSurveyPopupBody'
import './UserSurveyPopup.scss'
import { EventsStore } from '../../../store/EventsStore'

interface IUserSurveyPopup {
  eventsStore?: EventsStore
}

const UserSurveyPopup: React.FC<IUserSurveyPopup> = ({ eventsStore }) => {
  const { surveyPopupVM: vm } = eventsStore
  if (!vm) return null
  if (!vm.anchorEl) return null

  return (
    <Popover
      open={true}
      anchorEl={vm.anchorEl}
      onClose={() => vm.close()}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      className='calendarEventModal'
    >
      <Card elevation={5} className='userSurveyPopupCard'>
        <UserSurveyPopupToolbar />
        <UserSurveyPopupBody />
        <Link onClick={() => vm.navigateToSurvey()} className='survey-link'>
          Open Survey
        </Link>
      </Card>
    </Popover>
  )
}

export default inject('eventsStore')(observer(UserSurveyPopup))
