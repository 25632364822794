import React from 'react'
import { inject, observer } from 'mobx-react'
import { Card, CardContent } from '@material-ui/core'
import rootStore from '../../../stores/RootStore'
import PulseQuestionRow from '../questions/PulseQuestionRow'
import { PulseQuestionVM } from '../../view-models/questions/PulseQuestionVM'
import { PulseQuestionEditVM } from '../../view-models/PulseQuestionEditVM'
import { PulseQuestionPreviewVM } from '../../view-models/PulseQuestionPreviewVM'
import './PulseCampaignSurveyQuestionPreviewCard.scss'

interface Props {
  vm: PulseQuestionPreviewVM
}

const PulseCampaignSurveyQuestionPreviewCard: React.FC<Props> = ({ vm }) => {
  if (!vm) return null

  const renderBody = () => {
    return (
      <CardContent className='question-card-content-body'>
        <PulseQuestionRow
          question={new PulseQuestionVM(rootStore, new PulseQuestionEditVM(rootStore, vm.question))}
        />
      </CardContent>
    )
  }

  const renderCard = () => {
    return (
      <div id='PulseCampaignSurveyQuestionPreviewCard'>
        <Card>{renderBody()}</Card>
      </div>
    )
  }

  return <>{renderCard()}</>
}

export default inject()(observer(PulseCampaignSurveyQuestionPreviewCard))
