import { observable, computed, action } from 'mobx'
import moment from 'moment'
import { RootStore } from '../../../../stores/RootStore'
import TrainerWidgetRowVM from './TrainerWidgetRowVM'

export class TrainerWidgetVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @observable public keywordFilter: string = ''
  @observable public deletingOrgId: string = ''
  @observable public tabIndex: number = 0
  @observable public sortType: string = 'Date'

  @computed
  public get activeRows(): TrainerWidgetRowVM[] {
    return this.rootStore.trainingPlansStore.orgTrainingPlans
      .filter(
        (plan) => moment().isSameOrAfter(plan.activeDate) && moment().isSameOrBefore(plan.dueDate)
      )
      .filter((plan) => plan.trainer && plan.trainer.id === this.rootStore.appStore.currentUserId)
      .filter((plan) => plan.title.toLowerCase().includes(this.keywordFilter))
      .map((plan) => {
        return new TrainerWidgetRowVM(plan, this.rootStore)
      })
  }

  @computed
  public get inactiveRows(): TrainerWidgetRowVM[] {
    return this.rootStore.trainingPlansStore.orgTrainingPlans
      .filter((plan) => moment().isBefore(plan.activeDate) || moment().isAfter(plan.dueDate))
      .filter((plan) => plan.trainer && plan.trainer.id === this.rootStore.appStore.currentUserId)
      .map((plan) => {
        return new TrainerWidgetRowVM(plan, this.rootStore)
      })
  }

  @computed
  public get rows(): TrainerWidgetRowVM[] {
    if (this.tabIndex === 0) return this.activeRows
    if (this.tabIndex === 1) return this.inactiveRows
  }

  @action setSortType(type) {
    this.sortType = type
  }

  @action
  public setTabIndex(num: number) {
    this.tabIndex = num
  }

  @action setKeywordFilter(val) {
    this.keywordFilter = val
  }

  @action createNewTrainingPlan() {
    this.rootStore.appStore.router.push('/trainingPlans/edit/new')
  }
}
export default TrainerWidgetVM
