import { serializable } from 'serializr'
import moment, { ISO_8601, unitOfTime } from 'moment'
import { IParseDateDTO } from './IParseDateDTO'

export class ParseDate implements IParseDateDTO {
  public static now(): ParseDate {
    const dt = new ParseDate()
    dt.iso = moment().toISOString()
    return dt
  }

  public static fromDate(date: Date): ParseDate {
    const dt = new ParseDate()
    dt.iso = moment(date).toISOString()
    return dt
  }

  @serializable public __type: string = 'Date'
  @serializable public iso: string = ''

  public toString() {
    return this.date.toISOString()
  }

  public add(amount: number, unit: unitOfTime.DurationConstructor): ParseDate {
    this.iso = moment(this.iso, ISO_8601).add(amount, unit).toISOString()
    return this
  }

  public get date(): Date {
    return moment(this.iso, ISO_8601).toDate()
  }
}
