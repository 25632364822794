import React from 'react'
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Grid,
  IconButton,
  Tooltip,
  Badge,
} from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import LeftIcon from '@material-ui/icons/ChevronLeft'
import RightIcon from '@material-ui/icons/ChevronRight'
import LocalizationStore from '../../../localization/LocalizationStore'
import { ManageGoalsWidgetRowVM } from '../../view-models/ManageGoalsWidgetRowVM'
import { GoalsStore } from '../../store/GoalsStore'
import AvatarBadge from '../../../shared/AvatarBadge'
import Avatar from '../../../shared/Avatar'
import moment from 'moment'
import './GoalsTable.scss'

interface Props {
  goalsStore?: GoalsStore
  localizationStore?: LocalizationStore
}

const GoalsTable: React.FC<Props> = ({ goalsStore, localizationStore }) => {
  const { manageGoalsWidgetVM: vm } = goalsStore
  const { lzStrings } = localizationStore
  const pagedDataVM = vm.pagedData

  if (!vm) return null
  if (!pagedDataVM.displayRows.length) return null

  const renderSortIndicator = (key: string) => {
    if (pagedDataVM.sort === key) return <i className='fa fa-caret-down' />
    if (pagedDataVM.sort === '-' + key) return <i className='fa fa-caret-up' />
  }

  const renderPagingRow = () => {
    if (!pagedDataVM.displayRows.length) return
    return (
      <Grid container direction='row' justifyContent='flex-end' alignItems='center' className='paging-row'>
        <Grid item>{pagedDataVM.recordsDisplayedInfo}</Grid>
        <Grid item>
          <IconButton onClick={() => pagedDataVM.goPrevPage()} disabled={pagedDataVM.isFirstPage}>
            <LeftIcon />
          </IconButton>
          <IconButton onClick={() => pagedDataVM.goNextPage()} disabled={pagedDataVM.isLastPage}>
            <RightIcon />
          </IconButton>
        </Grid>
      </Grid>
    )
  }

  return (
    <div id='GoalsTable'>
      <Table stickyHeader={false} style={{ tableLayout: 'auto' }} size='small' id='WidgetTable'>
        <TableHead>
          <TableRow style={{ cursor: 'pointer' }}>
            <TableCell onClick={() => pagedDataVM.setSort('name')}>
              {lzStrings.manage_goals.name} {renderSortIndicator('name')}
            </TableCell>
            <TableCell onClick={() => pagedDataVM.setSort('category')}>
              {lzStrings.manage_goals.category} {renderSortIndicator('category')}
            </TableCell>
            <TableCell onClick={() => pagedDataVM.setSort('dueDate')}>
              {lzStrings.manage_goals.due}
              {renderSortIndicator('dueDate')}
            </TableCell>
            <TableCell onClick={() => pagedDataVM.setSort('participants')}>
              {lzStrings.manage_goals.participants} {renderSortIndicator('participants')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pagedDataVM.displayRows.map((row: ManageGoalsWidgetRowVM) => {
            return (
              <TableRow
                hover
                className='table-row'
                key={row.objectId}
                onClick={() => row.editGoal()}
              >
                <TableCell align='left' style={{ width: '30%' }} className='name-cell'>
                  {row.name}
                </TableCell>
                <TableCell align='left' style={{ width: '30%' }} className='category-cell'>
                  <span className='category-text'>{row.category}</span>
                </TableCell>
                <TableCell align='left' className='phone-cell'>
                  {moment(row.dueDate).format(lzStrings.manage_goals.due_date_format)}
                </TableCell>
                <TableCell align='left' className='participant-cell'>
                  {row.participatingUsers.map((participant, index) => {
                    if (!participant) return null
                    return (
                      <Tooltip
                        key={participant.objectId}
                        title={participant.name || ''}
                        placement='bottom'
                      >
                        <Badge
                          badgeContent={<AvatarBadge isCompleted={false} />}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                        >
                          <Avatar
                            user={participant}
                            size={20}
                            style={{ fontSize: 12, float: 'left', marginLeft: -2 }}
                            showOnlyFirstInitial
                          />
                        </Badge>
                      </Tooltip>
                    )
                  })}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      {/* {renderDeleteDialog()} */}
      {renderPagingRow()}
    </div>
  )
}

export default inject('goalsStore', 'localizationStore')(observer(GoalsTable))
