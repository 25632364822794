import React, { FC } from 'react'
import { inject, observer } from 'mobx-react'
import { CardContent, CardHeader, Typography } from '@material-ui/core'
import { AudienceRowVM } from '../../view-models/drawer/AudienceRowVM'
import { AgGridReact } from 'ag-grid-react'
import AGGridUserCell from '../../../shared/ag-grid/AGGridUserCell'
import WidgetTitle from './WidgetTitle'
import Searchbar from '../../../shared/Searchbar'
import AGGridWidgetPagingRow from '../../../shared/ag-grid/AGGridPagingRow'
import ActionsCell from './ActionsCell'
import { GroupingsTabVM } from '../../view-models/my-audience-widget/GroupingsTabVM'

interface Props {
  vm: GroupingsTabVM
}

const GroupsTab: FC<Props> = ({ vm }) => {
  const renderNameCell = (props) => {
    const row: AudienceRowVM = props.data
    if (!row) return null
    return <AGGridUserCell userId={row.userId} name={row.name} iconURL={row.iconURL} />
  }

  const renderGrid = () => {
    if (!vm.shouldRenderGrid) return
    return (
      <div
        className={vm.hasRows ? 'ag-theme-alpine' : 'ag-theme-alpine ag-grid-no-rows'}
        style={{ height: '100%', width: '100%', display: vm.shown ? undefined : 'none' }}
      >
        <AgGridReact
          gridOptions={{
            ...vm.getGridOptions(),
            frameworkComponents: {
              nameCell: renderNameCell,
              actionsCell: ActionsCell,
            },
          }}
        />
      </div>
    )
  }

  const renderPagingRow = () => {
    if (!vm.shown) return
    return <AGGridWidgetPagingRow vm={vm} />
  }

  const renderHeader = () => {
    if (!vm.shown) return
    return (
      <CardHeader
        title={<WidgetTitle vm={vm.widgetVM} />}
        action={
          <Searchbar
            focused={vm.typedFilterText.length > 0}
            className='search'
            searchValue={vm.typedFilterText}
            onSearchChange={(e) => vm.setQuickFilter(e.target.value)}
          />
        }
      />
    )
  }

  const renderNoRowsContent = () => {
    if (vm.hasRows) return
    return (
      <Typography component='div' className='no-rows'>
        {vm.noRowsToShowLabel}
      </Typography>
    )
  }

  return (
    <>
      {renderHeader()}
      <CardContent style={{ display: vm.shown ? undefined : 'none' }}>
        {renderGrid()}
        {renderNoRowsContent()}
        {renderPagingRow()}
      </CardContent>
    </>
  )
}

export default inject('organizationUsersStore')(observer(GroupsTab))
