import React from 'react'
import { inject, observer } from 'mobx-react'
import { AppBar, Button, CssBaseline, Link, Toolbar, Typography } from '@material-ui/core'
import clsx from 'clsx'
import isIE11 from '../../../../../utils/isIE11'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { SurveyTemplatesSelectVM } from '../../../view-models/template-select/SurveyTemplatesSelectVM'

interface Props {
  localizationStore?: LocalizationStore
  vm: SurveyTemplatesSelectVM
}

const TemplateSelectTopToolbar: React.FC<Props> = ({vm}) => {

  return (
    <div id='DashboardToolbar'>
      <CssBaseline />
      <AppBar
        className={clsx('dashboard-appbar', {
          'no-transition': isIE11,
        })}
        elevation={0}
      >
        <Toolbar className='dashboard-toolbar'>
          <Link onClick={() => vm.navigateBackToSelect()} underline='always'>
            <Typography className='dashboard-title spaced'>{'Create New Survey'}</Typography>
          </Link>
          <Typography className='dashboard-title spaced'>{' > '}</Typography>
          <Typography className='dashboard-title spaced'>{'From Template'}</Typography>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default inject('localizationStore')(observer(TemplateSelectTopToolbar))
