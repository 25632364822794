import React from 'react'
import { Card, CircularProgress } from '@material-ui/core'
import { observer, inject } from 'mobx-react'
import Header from './Header'
import Content from './Content'
import { SystemAdminsStore } from '../../store/SystemAdminsStore'
import AddSystemAdminDrawer from '../drawer/AddSystemAdminDrawer'
import './SystemAdminsListWidget.scss'

interface Props {
  systemAdminsStore?: SystemAdminsStore
}

const SystemAdminsListWidget: React.FC<Props> = ({ systemAdminsStore }) => {
  const { listWidgetVM } = systemAdminsStore
  if (!listWidgetVM) return null

  const renderSpinner = () => {
    if (!listWidgetVM.isLoading) return
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  const renderContent = () => {
    if (listWidgetVM.isLoading) return
    return <Content />
  }

  return (
    <>
    <Card id='SystemAdminsListWidget' elevation={0}>
      <Header />
      {renderSpinner()}
      {renderContent()}
    </Card>
    <AddSystemAdminDrawer/>
    </>
  )
}

export default inject('systemAdminsStore')(observer(SystemAdminsListWidget))
