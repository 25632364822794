import { computed, action, observable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { IQuestionImportRowDTO } from '../dtos/IQuestionImportRowDTO'
import { ImportOptionVM } from './ImportOptionVM'
import { QuestionsImportVM } from './QuestionsImportVM'

export class QuestionImportRowVM {
  private rootStore: RootStore
  private row: IQuestionImportRowDTO
  private importVM: QuestionsImportVM
  private index: number

  constructor(
    rootStore: RootStore,
    row: IQuestionImportRowDTO,
    questionsImportVM: QuestionsImportVM,
    index
  ) {
    this.row = row
    this.rootStore = rootStore
    this.importVM = questionsImportVM
    this.index = index
  }

  @computed
  public get textStyle(): string {
    let textStyle = 'import-row-text'
    if (this.isDeleted) textStyle = 'import-row-text is-deleted'
    if (this.isNew) textStyle = 'import-row-text is-new'
    return textStyle
  }

  @computed
  public get isNew(): boolean {
    if (!this.row.result) return true
    if (!this.row.result.question) return true
    return Boolean(!this.row.result.question.objectId)
  }

  @computed
  public get isDeleted(): boolean {
    if (!this.row.result) return false
    if (!this.row.result.question) return false
    return this.row.result.question.isDeleted
  }

  @computed
  public get height(): number {
    const foundHeight = this.importVM.cardHeights.find((e) => e.index === this.index)
    if (foundHeight) return foundHeight.height
    return 0
  }

  @action
  public setHeight(height, index) {
    const indexObject = {
      index: index,
      height: height,
    }
    this.importVM.cardHeights.push(indexObject)
  }

  @computed
  public get hasError() {
    return !this.row.result.success
  }

  @computed
  public get rowIndex(): string {
    return this.row.rowIndex.toString()
  }

  @computed
  public get isNewContact(): boolean {
    return this.row.result.isNew
  }

  @computed
  public get title(): string {
    if (!this.row.result) return ''
    if (!this.row.result.question) return this.row['Question']
    return this.row.result.question.title
  }

  @computed
  public get type(): string {
    if (!this.row.result) return ''
    if (!this.row.result.question) return this.row['Question Type']
    return this.row.result.question.type
  }

  @computed
  public get category(): string {
    if (!this.row.result) return ''
    if (!this.row.result.question) return ''
    if (Boolean(this.row['Category'])) return this.row['Category']
    return '-'
  }

  @computed
  public get path(): string {
    if (!this.row.result) return ''
    if (Boolean(this.row['Path'])) return this.row['Path']
    return '-'
  }

  @computed
  public get surveyType(): string {
    if (!this.row.result) return ''
    if (!this.row.result.question) return ''
    if (Boolean(this.row['Survey Type'])) return this.row['Survey Type']
    return '-'
  }

  @computed
  public get options(): Array<ImportOptionVM> {
    if (!this.row.result) return []
    if (!this.row.result.question) return []
    if (!this.row.result.question.options) return []
    return this.row.result.question.options.map((o) => new ImportOptionVM(this.rootStore, o))
  }

  @computed
  public get decimalsAllowed(): number {
    if (!this.row.result) return 0
    if (!this.row.result.question) return 0
    if (!this.row.result.question.decimalsAllowed) return 0
    return this.row.result.question.decimalsAllowed
  }

  @computed
  public get minimumValue(): number {
    if (!this.row.result) return 0
    if (!this.row.result.question) return 0
    if (!this.row.result.question.decimalsAllowed) return 0
    return this.row.result.question.minimumValueAllowed
  }

  @computed
  public get maximumValue(): number {
    if (!this.row.result) return 0
    if (!this.row.result.question) return 0
    if (!this.row.result.question.decimalsAllowed) return 0
    return this.row.result.question.maximumValueAllowed
  }

  @computed
  public get numberStep(): number {
    if (!this.row.result) return 0
    if (!this.row.result.question) return 0
    if (!this.row.result.question.decimalsAllowed) return 0
    return this.row.result.question.numberStep
  }

  @computed
  public get defaultValue(): number {
    if (!this.row.result) return 0
    if (!this.row.result.question) return 0
    if (!this.row.result.question.decimalsAllowed) return 0
    return this.row.result.question.defaultValueNumeric
  }

  @computed
  public get key() {
    return 'ques' + this.row.rowIndex
  }

  @computed
  public get questionErrorMessage(): string {
    if (!this.row.result) return ''
    if (!this.row.result.errorMessage) return ''
    return this.row.result.errorMessage
  }
}
