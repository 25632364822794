import React, { useRef } from 'react'
import { observer, inject } from 'mobx-react'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { Grid } from '@material-ui/core'
import './DetailsTabPanel.scss'
import { Editor } from '@tinymce/tinymce-react'
import surveyDetailsTabImage from '../../../../../assets/img/surveys-detail.svg'
import { PLUGINS, TOOLBAR } from '../../../../config/TinyMCEconfig'
import FileUploadModal from '../../../../upload/views/FileUploadModal'
import { FileTypeEnum } from '../../../../upload/enum/FileTypeEnum'
import { Attachment } from '../../../../upload/aggregate/Attachment'
import { SurveyIntroductionVM } from '../../../view-models/SurveyIntroductionVM'
import TinyMCEMediaPicker from '../TinyMCEMediaPicker/TinyMCEMediaPicker'
import { LabelsStore } from '../../../../labels/store/LabelsStore'
import CMSItemsPickerDialog from '../../../../cms-items/views/dialogs/CMSItemsPickerDialog'
import { CMSItemsTypeEnum } from '../../../../cms-items/enum/CMSItemsTypeEnum'
import { OrganizationsStore } from '../../../../organizations/store/OrganizationsStore'

interface Props {
  vm: SurveyIntroductionVM
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
  organizationsStore?: OrganizationsStore
}

const SurveyIntroduction: React.FC<Props> = ({ vm, localizationStore, labelsStore, organizationsStore }) => {
  const editorRef: any = useRef()

  if (!vm) return null
  const mediaPickerVM = vm.mediaUploadPickerVM

  const { surveys: strings } = localizationStore.lzStrings

  const renderSurveyIntroductionRow = () => {
    return (
      <Grid
        item
        className={vm.isReadOnly ? 'details-tab-panel-right disabled' : 'details-tab-panel-right'}
      >
        <h6>{labelsStore.getLabelByLanguageAndFor('pulse') + ' ' + 'Introduction/Instructions'}</h6>
        <Grid item xs={12}>
          <Editor
            ref={editorRef}
            key={vm.objectId}
            value={vm.surveyIntroduction}
            init={{
              height: 400,
              resize: false,
              menubar: false,
              plugins: `${PLUGINS} image media`,
              toolbar: `customInsertButton ${TOOLBAR}`,
              contextmenu: false,
              extended_valid_elements:
                'video[poster|src|controls|preload|width|height|data-setup],source[src|type]',
              images_reuse_filename: true,
              images_upload_handler: (blobInfo, success, failure, progress) =>
                vm.uploadToCloudinary(blobInfo, success, failure, progress),
              placeholder:
                'Add an introduction to let participants know what this ' +
                labelsStore.getLabelByLanguageAndFor('pulse').toLowerCase() +
                ' is all about...',
              setup: (editor) => {
                editor.on('init', (e) => {
                  vm.setEditorRef(editorRef)
                })
                editor.ui.registry.addMenuButton('customInsertButton', {
                  text: 'Upload Media',
                  tooltip:
                    'Upload an image or video from the rippleworx media library or your computer.',
                  icon: 'upload',
                  fetch: function (callback) {
                    let items: any = []
                    if (organizationsStore.currentOrganization?.hasCMSPicker) {
                      items = [
                        {
                          type: 'menuitem',
                          icon: 'gallery',
                          text: 'Content Management System',
                          onAction: () => {
                            vm.toggleMediaUploadPicker()
                          },
                        }
                      ]
                    } else {
                      items = [
                        {
                          type: 'menuitem',
                          icon: 'gallery',
                          text: 'RippleWorx Media Library',
                          onAction: () => {
                            vm.toggleMediaUploadPicker()
                          },
                        },
                        {
                          type: 'menuitem',
                          icon: 'browse',
                          text: 'My Computer',
                          onAction: () => {
                            vm.toggleMediaUploadModal()
                          },
                        },
                      ]
                    }
                    callback(items)
                  },
                })
              },
              browser_spellcheck: true,
            }}
            onEditorChange={(e) => vm.setSurveyIntroduction(e)}
          />
        </Grid>
        <div className='survey-details-tab-image'>
          <img src={surveyDetailsTabImage} alt='' height={170} />
        </div>
      </Grid>
    )
  }

  const renderMediaUploadModal = () => {
    if (organizationsStore.currentOrganization?.hasCMSPicker) return renderCMSUploadModal()
    return renderStandardUploadModal()
  }

  const renderCMSUploadModal = () => {
    return (
      <CMSItemsPickerDialog
        key={`surveyIntroFile-${vm.objectId}`}
        allowMultiple={false}
        fileTypes={CMSItemsTypeEnum.IMAGES_VIDEOS}
        addAfterSave={(a: Attachment[]) => vm.addMediaToInfoTextFromComputer(a[0])}
        toggleShowModal={() => vm.toggleMediaUploadModal()}
        isOpen={vm.showMediaModal}
      />
    )
  }

  const renderStandardUploadModal = () => {
    return (
      <FileUploadModal
        key={`surveyIntroFile-${vm.objectId}`}
        allowMultiple={false}
        fileTypes={FileTypeEnum.IMAGES_VIDEOS}
        addAfterSave={(a: Attachment[]) => vm.addMediaToInfoTextFromComputer(a[0])}
        toggleShowModal={() => vm.toggleMediaUploadModal()}
        isOpen={vm.showMediaModal}
      />
    )
  }

  const renderMediaUploadPicker = () => {
    return <TinyMCEMediaPicker vm={mediaPickerVM} />
  }

  return (
    <>
      {renderMediaUploadModal()}
      {renderMediaUploadPicker()}
      {renderSurveyIntroductionRow()}
    </>
  )
}

export default inject(
  'surveysStore',
  'localizationStore',
  'labelsStore',
  'organizationsStore'
)(observer(SurveyIntroduction))
