import React from 'react'
import { observer } from 'mobx-react'
import { Avatar, Button } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import './SubmitRow.scss'
import { QuizTakeVM } from '../../view-models/QuizTakeVM'

interface Props {
  vm: QuizTakeVM
}

const SubmitRow: React.FC<Props> = ({ vm }) => {
  const renderResetButton = () => {
    if (vm.isComplete) return null
    return (
      <Button variant='text' disabled={vm.isComplete} onClick={() => vm.reset()}>
        Reset
      </Button>
    )
  }
  const renderButtons = () => {
    return (
      <div>
        <Button
          variant='contained'
          disabled={!vm.canSubmit || vm.isComplete}
          onClick={() => vm.toggleShowConfirmDialog()}
        >
          Submit
        </Button>
        &nbsp;
        {renderResetButton()}
      </div>
    )
  }

  return (
    <div id='SubmitRow'>
      <Avatar className={vm.canSubmit ? 'indicator active' : 'indicator'}>
        <CheckIcon />
      </Avatar>
      <div className='hr' />
      {renderButtons()}
    </div>
  )
}

export default observer(SubmitRow)
