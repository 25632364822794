import { Checkbox, FormControlLabel, Grid, Switch, Typography } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import React from 'react'
import LocalizationStore from '../../../localization/LocalizationStore'
import InfoIcon from '@material-ui/icons/Info'
import './CompletionToolbar.scss'
import { TrainingItemsStore } from '../../store/TrainingItemsStore'
import InfoTooltip from '../../../shared/InfoTooltip'

interface Props {
  trainingItemsStore?: TrainingItemsStore
  localizationStore?: LocalizationStore
}

const CompletionToolbar: React.FC<Props> = ({ trainingItemsStore, localizationStore }) => {
  const { trainingItemEditVM: vm } = trainingItemsStore
  const { training_plans: strings } = localizationStore.lzStrings
  if (!vm) return null

  return (
    <div id='CompletionToolbar'>
      <Grid container>
        <Grid item xs={12} className='flex-align'>
          <Typography variant='h5' className='input-label'>
            {strings.require_completion} <InfoTooltip message={strings.completion_message} />
          </Typography>
          <Switch
            checked={vm.requireProofOfCompletion}
            onChange={() => vm.toggleRequireCompletion()}
          />
        </Grid>
        <Grid item xs={12} style={{ marginTop: '-10px' }}>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!vm.requireProofOfCompletion}
                checked={vm.requireQuiz}
                onChange={() => vm.toggleRequireQuiz()}
                color='default'
                className={vm.proofOfCompletionValid ? '' : 'check-error'}
              />
            }
            label={strings.quiz}
          />
          <FormControlLabel
            control={
              <Checkbox
                disabled={!vm.requireProofOfCompletion}
                checked={vm.requireCertificate}
                onChange={() => vm.toggleRequireCertificate()}
                color='default'
                className={vm.proofOfCompletionValid ? '' : 'check-error'}
              />
            }
            label={strings.certificate}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default inject('trainingItemsStore', 'localizationStore')(observer(CompletionToolbar))
