import { Dialog, DialogContent, DialogTitle, DialogActions, Button } from '@material-ui/core'
import { AgGridReact } from 'ag-grid-react'
import { inject, observer } from 'mobx-react'
import React from 'react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { SurveysStore } from '../../../surveys/store/SurveysStore'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import './InvitationsTable.scss'

interface Props {
  surveysStore?: SurveysStore
  localizationStore?: LocalizationStore
}

const HistoryDialog: React.FC<Props> = ({ surveysStore, localizationStore }) => {
  const { editVM } = surveysStore.viewModels
  if (!editVM) return null
  const vm = editVM.surveyParticipantsList
  if (!vm.showHistoryDialog) return null
  const historyVM = vm.historyVM
  if (!historyVM) return null
  const { lzStrings } = localizationStore

  return (
    <Dialog
      className='invitationHistory'
      maxWidth={'lg'}
      onClose={() => vm.toggleShowHistoryDialog()}
      open={vm.showHistoryDialog}
    >
      <DialogTitle>Invitation Events: {historyVM.getUserName}</DialogTitle>
      <DialogContent>
        <div className='ag-theme-alpine'>
          <AgGridReact
            gridOptions={{ suppressCellSelection: true, rowModelType: 'infinite' }}
            rowSelection={'multiple'}
            suppressRowClickSelection={true}
            suppressClickEdit={false}
            columnDefs={historyVM.columnDefs}
            onGridReady={(e) => historyVM.onGridReady(e)}
            getRowNodeId={(e) => historyVM.getRowNodeId(e)}
            editType=''
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' size='large' onClick={() => vm.toggleShowHistoryDialog()}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default inject('surveysStore', 'localizationStore')(observer(HistoryDialog))
