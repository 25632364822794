import moment from 'moment'
import { computed } from 'mobx'
import fileSizer from '../../../shared/FileSizer'
import { MediaItem } from '../../aggregate/MediaItem'
import { RootStore } from '../../../stores/RootStore'
import { AGGridRowVM } from '../../../shared/ag-grid/AGGridRowVM'
import { MediaItemListWidgetVM } from './MediaItemListWidgetVM'
import { MediaItemType } from '../../../upload/types/MediaItemType'

export class MediaItemRowVM extends AGGridRowVM<MediaItem> {
  private previewWidth: number = 30

  constructor(rootStore: RootStore, mediaItem: MediaItem, widgetVM: MediaItemListWidgetVM) {
    super(rootStore, mediaItem, widgetVM)
  }

  @computed
  public get objectId(): string {
    return this.aggregate.objectId
  }

  @computed
  public get name(): string {
    return this.aggregate.name
  }

  @computed
  public get preview(): string {
    return this.aggregate.type === 'image'
      ? this.makeThumbnail(this.aggregate.fileUrl)
      : this.makeThumbnail(this.aggregate.fileUrl)
  }

  @computed
  public get type(): MediaItemType {
    return this.aggregate.type
  }

  @computed
  public get createdDate(): string {
    const s = this.rootStore.localizationStore.lzStrings.mediaItemsListWidget
    if (Boolean(this.aggregate.createdAt))
      return moment(this.aggregate.createdAt).format(s.created_date_format)
    else return ''
  }

  @computed
  public get size(): string {
    return fileSizer(this.aggregate.size)
  }

  ///Cloudinary allows for automatic aspect ratio resizing of an image by
  ///adding a width (w_) parameter to an image's URL
  private makeThumbnail(imgUrl: string): string {
    if (imgUrl && imgUrl.includes('q_auto'))
      return imgUrl.replace('q_auto', `h_${this.previewWidth}`)
    else return imgUrl
  }

  openMedia() {
    this.rootStore.mediaItemsStore.openDrawer(this.objectId)
  }
}
