import React from 'react'
import { observer, inject } from 'mobx-react'
import { Stage, Layer, Line } from 'react-konva'
import { QuestionsStore } from '../../../../questions/store/QuestionsStore'
import { SurveysStore } from '../../../store/SurveysStore'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { Grid, Typography } from '@material-ui/core'
import AddQuestionMenu from './AddQuestionMenu'
import QuestionsList from './QuestionsList'
import SidePanel from '../question-panel/SidePanel'
import MuiDialogMessage from '../../../../shared/MuiDialogMessage'
import './Builder.scss'
import QuestionsImportModal from '../../../../questions/views/import/QuestionsImportModal'

interface BuilderProps {
  surveysStore?: SurveysStore
  questionsStore?: QuestionsStore
  localizationStore?: LocalizationStore
}

const Builder = ({ surveysStore, questionsStore, localizationStore }: BuilderProps) => {
  const editVM = surveysStore.viewModels.editVM
  if (!editVM) return null

  const lzStrings = localizationStore.lzStrings.surveys

  const renderDiagram = () => {
    if (!editVM.diagramEnabled) return

    return (
      <>
        <Stage
          key={'canvas' + editVM.pathsLastUpdated}
          width={editVM.diagramWidth}
          height={editVM.diagramHeight}
          className='canvas-container'
          listening={false}
        >
          <Layer listening={false}>
            {editVM.paths.map((path, idx) => (
              <Line
                key={'ln' + idx}
                points={path.toPointsArray()}
                stroke={path.color}
                strokeWidth={2}
                _strictMode
                listening={false}
                perfectDrawEnabled={false}
                visible={true}
              />
            ))}
          </Layer>
        </Stage>

        <svg
          key={'svg' + editVM.pathsLastUpdated}
          className='canvas-container'
          style={{
            width: editVM.diagramWidth,
            height: editVM.diagramHeight,
          }}
          xmlns='http://www.w3.org/2000/svg'
        >
          {editVM.paths
            .filter((e) => e.type === 'option' || e.type === 'end')
            .map((path, idx) => {
              let { x, y } = path.getArrowPoint()
              return (
                <path
                  key={'path' + idx}
                  d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z'
                  fill={path.color}
                  transform={'translate(' + x + ' ' + y + ')'}
                />
              )
            })}
        </svg>
      </>
    )
  }

  return (
    <Grid container spacing={0} id='Builder'>
      <QuestionsImportModal vm={editVM.questionsImportVM} />
      <MuiDialogMessage
        open={editVM.cantDeleteLastQuestion}
        onClose={() => editVM.toggleDeleteLastQuestion()}
        message='Cannot delete the last question in survey.'
      />
      <MuiDialogMessage
        open={editVM.currentQuestion && editVM.currentQuestion.cantDeleteLastOption}
        onClose={() => editVM.currentQuestion.toggleDeleteLastOption()}
        message='Cannot delete the last option of a question.'
      />
      <SidePanel />

      <Grid item xs={12} sm={6} md={6}>
        <div id='FlowDiagram'>
          <div id='container'>
            {renderDiagram()}

            <div className='elements-container'>
              <div className='start-end-badge' id='start-badge'>
                <Typography>Start</Typography>
              </div>

              <QuestionsList />
              {!editVM.addButtonToFooter ? <AddQuestionMenu /> : null}
              <div className='start-end-badge' id='end-badge'>
                <Typography>End</Typography>
              </div>
            </div>
          </div>
        </div>
        {editVM.addButtonToFooter ? <AddQuestionMenu /> : null}
      </Grid>
    </Grid>
  )
}

export default inject('surveysStore', 'questionsStore', 'localizationStore')(observer(Builder))
