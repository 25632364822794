import React from 'react'
import UpIcon from '@material-ui/icons/KeyboardArrowUp'
import DownIcon from '@material-ui/icons/KeyboardArrowDown'
import './StepIndicators.scss'

interface Props {
  onIncreaseStep: () => void
  onDecreaseStep: () => void
}

const StepIndicators: React.FC<Props> = ({ onIncreaseStep, onDecreaseStep }) => {
  return (
    <div className='step-container'>
      <UpIcon className='increase-step' onClick={onIncreaseStep} />
      <DownIcon className='decrease-step' onClick={onDecreaseStep} />
    </div>
  )
}

export default StepIndicators
