import { action, computed, observable } from 'mobx'
import { serializable, object, list } from 'serializr'
import { ErrorRowVM } from '../import/ErrorRowVM'
import { RootStore } from 'src/app/stores/RootStore'
import { CustomCollectionsService } from '../../service/CustomCollectionsService'
import { CustomCollection } from '../../aggregate/CustomCollection'
import { FieldType } from '../../types/FieldType'
import { AudienceMember } from 'src/app/tasks/aggregates/AudienceMember'
import { Field } from '../../aggregate/Field'
import moment from 'moment'
import {get, has} from 'lodash'
import { ICustomRecordsImportResult } from '../../interfaces/ICustomRecordsImportResult'
import { IFieldDTO } from '../../dto/IFieldDTO'
import { OrganizationUsersWidgetDataStore } from 'src/app/organization-users/view-models/widget/OrganizationUsersWidgetDataStore'
import { OrganizationUser } from 'src/app/organization-users/aggregate/OrganizationUser'
import { AudienceMemberService } from 'src/app/audience-members/service/AudienceMembersService'
import { IOrganizationUserAudienceSearchRequest } from 'src/app/organization-users/interfaces/IOrganizationUserAudienceSearchRequest'
import { IOrganizationUserDTO } from 'src/app/organization-users/dtos/IOrganizationUserDTO'

export class CustomRecordModalVM {
  public rootStore: RootStore
  private svc: CustomCollectionsService
  public customCollection: CustomCollection
  private MAX_PRECISION: number = 6
  private MAX_NUM_LEN: number = 28
  private MAX_STRING_LEN: number = 1000
  @observable public result: ICustomRecordsImportResult = null

  @serializable(object(Object)) @observable public values = {}
  @observable public hasFieldErrors: boolean = false

  @observable public importProcessing = false
  private successHandler = async () => {}

  static getDefault(fields: Field[]): Object {
    const values = {}
    fields.forEach((field) => {
        let value: any = ''
        if (field.type === 'Date') {
            value = moment(new Date()).startOf('day').toDate()
        } else if (field.type === 'Boolean') {
            value = false
        }

        values[field.fieldName] = value
    })

    return values
  }

  constructor(
    rootStore: RootStore,
    customCollection: CustomCollection,
    successHandler: () => Promise<void>,
  ) {
    this.rootStore = rootStore
    this.svc = new CustomCollectionsService(this.rootStore)
    this.customCollection = customCollection
    this.values = observable(CustomRecordModalVM.getDefault(customCollection.fields))
    this.successHandler = successHandler
  }

  @observable public shown: boolean = false

  @computed
  public get errorRows(): ErrorRowVM[] {
    return this.result.errorMessages.map((e, idx) => new ErrorRowVM(this.rootStore, e, idx))
  }

  @action
  public setResult(result) {
    this.result = result
    if (!this.result) return
    if (!this.result.success) this.importProcessing = false
  }

  @computed
  public get hasError(): boolean {
    if (!this.result) {
      return false
    }
    return !this.result.success
  }

  @action
  public setValues(values) {
    this.values = {
      ...CustomRecordModalVM.getDefault(this.customCollection.fields),
      ...values,
    }
    this.result = null
  }

  @computed
  public get getValues() {
      return this.values
  }

  @action
  public clearValues() {
    this.values = CustomRecordModalVM.getDefault(this.customCollection.fields)
    this.result = null
  }

  public static isFieldRequired(field: IFieldDTO) {
    return field.isCustomRecordKey || field.isFacet
  }

  private isEmpty(value) {
    return value === undefined || value === null || value === ''
  }

  @computed
  public get hasErrors() {
    return Object.entries(this.fieldErrors).some(([key, value]) => value)
  }

  private validateNumber(value: number): any {
    const stringNumber = String(value)
    const decimalIndex = stringNumber.indexOf('.')
    if (decimalIndex !== -1) {
      if (stringNumber.length - decimalIndex - 1 > this.MAX_PRECISION) {
        return 'Number exceeds maximum allowed precision'
      }
    } else if (stringNumber.length > this.MAX_NUM_LEN) {
      return 'Number exceeds Maximum allowed value'
    }

    return false
  }

  private validateString(value: string) {
    const string = String(value)
    if (string.length > this.MAX_STRING_LEN) return 'Input exceeds maximum number of characters'

    return false
  }

  @computed
  public get fieldErrors() {
    const fieldErrors = {}
    this.customCollection.fields.forEach((field) => {
      const value = get(this.values, field.fieldName)
      if (this.isEmpty(value) && CustomRecordModalVM.isFieldRequired(field)) {
        fieldErrors[field.fieldName] = `Value of type ${field.type} is required`
      } else if (field.type === 'Number') {
        fieldErrors[field.fieldName] = this.validateNumber(value)
      } else if (field.type === 'String') {
        fieldErrors[field.fieldName] = this.validateString(value)
      } else {
        fieldErrors[field.fieldName] = false
      }
    })

    return fieldErrors
  }

  @action
  public setValue(type: FieldType, key, value) {
      if (type === 'User') {
        const user = value
        this.values[key] = user
        this.values['userId'] = user ? user.id : undefined
      } else if (type === 'Date') {
        const newDate: Date = value
        this.values[key] = newDate
      } else {
        this.values[key] = value
      }
  }

  @computed
  public get isNew() {
    return !has(this.values, 'objectId')
  }

  @computed
  public get getTitle() {
    const prefix = has(this.values, 'objectId') ? 'Edit' : 'Add New'
      return `${prefix} '${this.customCollection.name}' Record`
  }

  @action
  public async save() {
      this.importProcessing = true
      const result: ICustomRecordsImportResult = await this.svc.saveCustomRecords(
        [this.values],
        this.customCollection.objectId,
        this.customCollection.organizationId)
      this.importProcessing = false

      if (result.success) {
        this.shown = false
        await this.successHandler()
      } else {
        this.setResult(result)
      }
  }

  @action
  public toggleShown() {
    this.shown = !this.shown
  }
}
