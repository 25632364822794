import { TrainingItem } from '../../../../training-items/aggregate/TrainingItem'
import { ITrainingItemDTO } from '../../../../training-items/dto/ITrainingItemDTO'
import { ICatalogItemsFindRequest } from '../../../../training-items/interfaces/ICatalogItemsFindRequest'
import { TrainingItemsService } from '../../../../training-items/service/TrainingItemsService'
import { DataStore } from '../../../../shared/data/DataStore'
import { RootStore } from '../../../../stores/RootStore'

export class CatalogItemFindDataStore extends DataStore<TrainingItem, ITrainingItemDTO> {
  private request: ICatalogItemsFindRequest

  constructor(rootStore: RootStore, request: ICatalogItemsFindRequest) {
    super(rootStore, TrainingItem, 'trainingItems', [
      'organizationId',
      'name',
      'description',
      'categories',
      'contentItems',
      'applicableParticipants',
      'createdByUserId',
      'mustCompleteInOrder',
      'quiz',
      'requireProofOfCompletion',
      'requireQuiz',
      'requireCertificate',
    ])
    this.request = request
    this.paged = true
    this.recordsPerPage = 100
    this.sortColumnName = 'name'
  }

  public setRequest(request: ICatalogItemsFindRequest) {
    this.request = request
  }

  public getRequest(): ICatalogItemsFindRequest {
    return this.request
  }

  protected getListRecordsApiCall() {
    return async () => {
      const req: ICatalogItemsFindRequest = {
        ...this.request,
        userId: this.rootStore.appStore.currentUserId,
        organizationId: this.rootStore.appStore.currentOrgId,
        terms: this.filter,
        skip: this.recordsPerPage * (this.pageNumber - 1),
        limit: this.recordsPerPage,
        sortColumnName: this.sortColumnName,
        sortDirection: this.sortDirection,
        listColumns: this.listColumns,
      }
      const svc = new TrainingItemsService()
      const result = await svc.getCatalogTrainingItemsForUser(req)
      this.totalRecords = result.count
      return result.trainingItems
    }
  }

  public get trainingItems() {
    return this.records
  }
}
