import React from 'react'
import { inject, observer } from 'mobx-react'
import { TaskTemplatesStore } from '../store/TaskTemplatesStore'
import LocalizationStore from '../../localization/LocalizationStore'
import { Drawer, Grid, InputLabel, TextField, CardContent } from '@material-ui/core'
import './TaskTypeDrawer.scss'
import MuiDrawerToolbar from '../../shared/Mui-Drawer/MuiDrawerToolbar'
import MuiDrawerHeader from '../../shared/Mui-Drawer/MuiDrawerHeader'
import TaskTypeParticipants from './TaskTypeParticipants'

interface Props {
  taskTemplatesStore?: TaskTemplatesStore
  localizationStore?: LocalizationStore
}

const TaskTypeDrawer: React.FC<Props> = ({ taskTemplatesStore, localizationStore }) => {
  const { taskTypeDrawerVM } = taskTemplatesStore
  const { lzStrings } = localizationStore

  const handleSubmitClick = () => {
    taskTypeDrawerVM.save()
    taskTemplatesStore.selectedTaskTemplateId = ''
  }

  const toggleDrawer = () => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    taskTemplatesStore.showDrawer = !taskTemplatesStore.showDrawer
    if (taskTemplatesStore.showDrawer === false) taskTemplatesStore.selectedTaskTemplateId = ''
  }

  return (
    <div id='TaskTypeDrawer'>
      {taskTypeDrawerVM && (
        <>
          <Drawer
            variant='temporary'
            anchor='right'
            open={taskTemplatesStore.showDrawer}
            onClose={toggleDrawer()}
          >
            <MuiDrawerHeader
              title={
                taskTypeDrawerVM.objectId
                  ? lzStrings.taskTemplates.manage_task_types
                  : lzStrings.taskTemplates.add
              }
              toggleDrawer={toggleDrawer()}
            />
            <MuiDrawerToolbar
              handleCancelClick={toggleDrawer()}
              handleSubmitClick={() => handleSubmitClick()}
              showReset={Boolean(taskTypeDrawerVM.objectId)}
              onConfirmReset={() =>
                taskTemplatesStore.setSelectedTaskTemplate(taskTypeDrawerVM.objectId)
              }
              moreMenuProps={{
                isEditMode: taskTypeDrawerVM.isEditMode,
                itemType: localizationStore.lzStrings.moreMenu.obj_type.task_type,
                itemName: taskTypeDrawerVM.name,
                onConfirmDelete: () => taskTypeDrawerVM.delete(),
              }}
            />
            <CardContent id='taskTypeBasicInfo'>
              <h6>Basic Info</h6>
              <form autoComplete='off' noValidate>
                <Grid container>
                  <Grid item md={12} sm={12}>
                    <InputLabel required htmlFor='name'>
                      {lzStrings.tasksManagementWidget.name}
                    </InputLabel>
                    <TextField
                      error={!taskTypeDrawerVM.nameValid || !taskTypeDrawerVM.nameUnique}
                      value={taskTypeDrawerVM.name}
                      onChange={(e) => (taskTypeDrawerVM.name = e.target.value)}
                      fullWidth
                      helperText={taskTypeDrawerVM.errorHelperText}
                      variant='outlined'
                      id='name'
                    />
                  </Grid>
                </Grid>
                <Grid container className='participantsContainer'>
                  <InputLabel htmlFor='participants'>
                    {lzStrings.tasksManagementWidget.participants}
                  </InputLabel>
                </Grid>
                <TaskTypeParticipants />
              </form>
            </CardContent>
          </Drawer>
        </>
      )}
    </div>
  )
}

export default inject('taskTemplatesStore', 'localizationStore')(observer(TaskTypeDrawer))
