import { observable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { CMSItemsStore } from '../store/CMSItemsStore'
import { CMSItemsManageVM } from './CMSItemsManageVM'
import { CMSItemsPreviewVM } from './CMSItemsPreviewVM'
import { CMSItemsUploadVM } from './CMSItemsUploadVM'
import { CMSItemsTypeEnum } from '../enum/CMSItemsTypeEnum'

export class CMSItemsPickerVM {
  private rootStore: RootStore

  constructor(
    rootStore: RootStore,
    allowMultiple: boolean = true,
    addAfterSave: Function = null,
    toggleShowModal: Function = null,
    fileTypes: CMSItemsTypeEnum = CMSItemsTypeEnum.DEFAULT
  ) {
    this.rootStore = rootStore
    this.cmsItemsStore = new CMSItemsStore(rootStore, fileTypes)
    this.manageVM = new CMSItemsManageVM(
      rootStore,
      this.cmsItemsStore,
      this,
      allowMultiple,
      addAfterSave,
      toggleShowModal,
      fileTypes
    )
    this.uploadVM = new CMSItemsUploadVM(this.rootStore, this.manageVM)
    this.previewVM = new CMSItemsPreviewVM(this.rootStore, this.manageVM)
  }

  @observable public cmsItemsStore: CMSItemsStore = null
  @observable public manageVM: CMSItemsManageVM = null
  @observable public uploadVM: CMSItemsUploadVM = null
  @observable public previewVM: CMSItemsPreviewVM = null
}
