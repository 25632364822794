import { computed, action } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { AGGridRowVM } from '../../../shared/ag-grid/AGGridRowVM'
import { CalendarManagementWidgetVM } from './CalendarManagementWidgetVM'
import Resource from 'src/app/resources/store/aggregate/Resource'

export class ResourceRowVM extends AGGridRowVM<Resource> {
  constructor(rootStore: RootStore, resource: Resource, widgetVM: CalendarManagementWidgetVM) {
    super(rootStore, resource, widgetVM)
  }

  public type: string = 'resource'

  @computed
  public get objectId() {
    return this.aggregate.objectId
  }

  @computed
  public get name() {
    return this.aggregate.name
  }

  @computed
  public get description() {
    return this.aggregate.description
  }

  editAggregate() {
    this.rootStore.resourcesStore.setSelectedResourceId(this.objectId)
    this.rootStore.resourcesStore.showDrawer = true
  }

}
