import { inject, observer } from 'mobx-react'
import React from 'react'
import { TrainingPlansCatalogStore } from '../../../store/TrainingPlansCatalogStore'
import LocalizationStore from '../../../../localization/LocalizationStore'
import TrainingPlanRow from './TrainingPlanRow'
import { Typography } from '@material-ui/core'

interface Props {
  trainingPlansCatalogStore?: TrainingPlansCatalogStore
  localizationStore?: LocalizationStore
}

const Content: React.FC<Props> = ({ trainingPlansCatalogStore, localizationStore }) => {
  const { catalogPlansWidgetVM: vm } = trainingPlansCatalogStore
  if (!vm) return null

  const renderPlanRows = () => {
    if (!vm.catalogPlanRows) return null
    return vm.catalogPlanRows.map((plan, index) => <TrainingPlanRow plan={plan} key={index} />)
  }

  const renderNoPlansContent = () => {
    if (vm.catalogPlanRows.length > 0) return null
    return (
      <Typography component='div' className='no-rows'>
        No Training Plans Found
      </Typography>
    )
  }

  return (
    <div id='Content'>
      {renderNoPlansContent()}
      {renderPlanRows()}
    </div>
  )
}

export default inject('trainingPlansCatalogStore', 'localizationStore')(observer(Content))
