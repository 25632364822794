import React from 'react'
import {
  CardContent,
  Typography,
  Grid,
  LinearProgress,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import { UserGoalsStore } from '../store/UserGoalsStore'
import LocalizationStore from '../../localization/LocalizationStore'
import EditIcon from '@material-ui/icons/Edit'
import Avatar from '../../shared/Avatar'
import './MyGoalsWidget.scss'
import moment from 'moment'
import VirtualList from 'react-tiny-virtual-list'

interface Props {
  userGoalsStore?: UserGoalsStore
  localizationStore?: LocalizationStore
}

const Content: React.FC<Props> = ({ userGoalsStore, localizationStore }) => {
  const { myGoalsWidgetVM: vm } = userGoalsStore
  const { my_goals: strings } = localizationStore.lzStrings
  if (!vm) return null

  const renderNoRowsContent = () => {
    if (vm.currentUserGoals.length > 0) return
    return (
      <Typography component='div' className='no-rows'>
        {strings.no_goals_found}
      </Typography>
    )
  }

  const renderGoalRow = (userGoal, index, style) => {
    if (vm.currentUserGoals.length === 0) return null

    const renderUnreadIndicator = () => {
      if (userGoal.isRead) return null
      return <div className='unread-indicator' />
    }
    return (
      <Grid
        container
        direction='row'
        key={index}
        style={style}
        className={userGoal.isRead ? 'goal-row' : 'goal-row unread'}
        onClick={() => userGoalsStore.markUserGoalAsRead(userGoal.objectId)}
      >
        {renderUnreadIndicator()}
        <Grid item xs={12}>
          <Grid container justifyContent='space-between'>
            <Typography className='goal-title'>{userGoal.name}</Typography>
            {userGoal.userOwned && (
              <EditIcon className='edit-icon' onClick={() => vm.editGoal(userGoal.goalId)} />
            )}
          </Grid>
        </Grid>
        <Grid item xs={9}>
          <Grid container direction='row' justifyContent='space-around'>
            <Grid item>
              <Avatar user={userGoal.assignedByUser} size={20} />
              <Typography className='user-name'>
                {userGoal.assignedByUser ? userGoal.assignedByUser.name : 'N/A'}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='h6' className='break'>
                |
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                {strings.due}
                {moment(userGoal.dueDate).format(strings.due_date_format)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='h6' className='break'>
                |
              </Typography>
            </Grid>
            <Grid item>
              <Typography>{userGoal.category}</Typography>
            </Grid>
            <Grid item>
              <Typography variant='h6' className='break'>
                |
              </Typography>
            </Grid>
            <Grid item>
              <span className={userGoal.active ? 'active' : 'inactive'}></span>
              {strings.active}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <LinearProgress
            variant='determinate'
            value={userGoal.progress}
            className={userGoal.userOwned ? 'owned' : 'not-owned'}
          />
          <Typography className='progress-label' variant='h6'>
            {userGoal.progress}%
          </Typography>
        </Grid>
      </Grid>
    )
  }

  const renderFilterMenu = () => {
    return (
      <Grid container direction='row' justifyContent='flex-end' className='filter-row'>
        <Grid item className='filter'>
          <InputLabel>{strings.sort_by}</InputLabel>
          <Select value={vm.sortType} onChange={(e) => vm.setSortType(e.target.value)}>
            <MenuItem value={'Date'}>{strings.date}</MenuItem>
            <MenuItem value={'Name'}>{strings.name}</MenuItem>
            <MenuItem value={'Category'}>{strings.category}</MenuItem>
          </Select>
        </Grid>
      </Grid>
    )
  }

  const renderVirtualList = () => {
    return (
      <VirtualList
        height={vm.currentUserGoals.length * 140}
        itemCount={vm.currentUserGoals.length}
        itemSize={140}
        renderItem={({ index, style }) => renderGoalRow(vm.currentUserGoals[index], index, style)}
      />
    )
  }

  return (
    <>
      {renderFilterMenu()}
      <div id='Content'>
        <CardContent className='no-padding'>
          {renderVirtualList()}
          {renderNoRowsContent()}
        </CardContent>
      </div>
    </>
  )
}

export default inject('userGoalsStore', 'localizationStore')(observer(Content))
