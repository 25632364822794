import React from 'react'
import { inject, observer } from 'mobx-react'
import TasksIcon from '@material-ui/icons/AssignmentOutlined'
import SurveysIcon from '@material-ui/icons/LiveHelpOutlined'
import EventIcon from '@material-ui/icons/Event'
import { EventsStore } from '../../store/EventsStore'
import { Checkbox, Grid } from '@material-ui/core'
import TrainingsIcon from '../../../shared/icons/TrainingsIcon'
import { LabelsStore } from '../../../labels/store/LabelsStore'

interface Props {
  eventsStore?: EventsStore
  labelsStore?: LabelsStore
}

const ViewItems: React.FC<Props> = ({ eventsStore, labelsStore }) => {
  const { calendarVM: vm } = eventsStore

  return (
    <>
      <h6>View Items</h6>
      <Grid container className='my-calendars-select'>
        <Grid
          item
          xs={8}
          className='my-calendars-items event'
          onClick={() => vm.toggleCalendarEvents()}
        >
          <Checkbox checked={vm.isCalendarEventsChecked} value='my-calendar' />
          <Grid container className='my-calendar-event'>
            <EventIcon className='myCalendarSelect-icon icon-event' />
            Events
          </Grid>
        </Grid>
        <Grid
          item
          xs={8}
          className='my-calendars-items task'
          onClick={() => vm.toggleCalendarTasks()}
        >
          <Checkbox checked={vm.isCalendarTasksChecked} value='tasks' />
          <Grid container className='my-calendar-task'>
            <TasksIcon className='myCalendarSelect-icon icon-task' />
            Tasks
          </Grid>
        </Grid>
        <Grid
          item
          xs={8}
          className='my-calendars-items survey'
          onClick={() => vm.toggleCalendarSurveys()}
        >
          <Checkbox checked={vm.isCalendarSurveysChecked} value='surveys' />
          <Grid container className='my-calendar-survey'>
            <SurveysIcon className='myCalendarSelect-icon icon-survey' />
            {labelsStore.getLabelByLanguageAndFor('pulses')}
          </Grid>
        </Grid>
        <Grid
          item
          xs={8}
          className='my-calendars-items trainings'
          onClick={() => vm.toggleCalendarTrainings()}
        >
          <Checkbox checked={vm.isCalendarTrainingsChecked} value='trainings' />
          <Grid container className='my-calendar-trainings'>
            <TrainingsIcon />
            Trainings
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default inject('eventsStore', 'labelsStore')(observer(ViewItems))
