import { action, observable, computed } from 'mobx'
import { ICustomCollectionDTO } from '../dto/ICustomCollectionDTO'
import { serializable, serialize, deserialize, list, object } from 'serializr'
import { Field } from './Field'
import { CustomRecord } from './CustomRecord'
import { ICustomRecordDTO } from '../dto/ICustomRecordDTO'
import { IFieldDTO } from '../dto/IFieldDTO'
import { IAggregate } from 'src/app/shared/data/IAggregate'

export class CustomCollection implements ICustomCollectionDTO, IAggregate {
  static create(orgId) {
    const coll = new CustomCollection()
    coll.organizationId = orgId
    coll.isNew = true
    return coll
  }

  @serializable @observable objectId: string = ''
  @serializable @observable organizationId: string = ''
  @serializable @observable name: string = ''
  @serializable @observable isDeleted: boolean = false
  @serializable @observable customRecordsCount: number = 0
  @serializable @observable importedFile: string = ''
  @serializable @observable collectionName: string = ''
  @serializable @observable watsonIBMModelId: string = ''
  @serializable(list(object(Field))) @observable fields: Array<Field> = []
  @observable customRecords: Array<any> = []
  @observable public isNew: boolean = false
  public isOnServer: boolean = false

  @action
  public setCustomRecords(records) {
    this.customRecords = records
  }

  @action
  public setFields(dtos: IFieldDTO[]) {
    const newFields = dtos.map((e) => {
      const rcd = deserialize(Field, e)
      return rcd
    })
    const arr = this.fields as any
    arr.replace(newFields)
  }

  @action
  public setImportedFile(filePath: string) {
    this.importedFile = filePath
  }

  @action
  public setName(val) {
    this.name = val
  }

  @action
  public markIsNotOnServer() {
    this.isOnServer = false
  }

  @action
  public markIsOnServer() {
    this.isOnServer = true
  }

  @action
  public delete() {
    this.isDeleted = true
  }

  public serialize(): ICustomCollectionDTO {
    return serialize(this)
  }

  public clone(): CustomCollection {
    return deserialize(CustomCollection, this.serialize())
  }
}
