import { observable, computed, action } from 'mobx'
import { PeriodType } from '../../surveys/types/PeriodType'
import _ from 'lodash'
import { IAutoCloseDTO } from '../dto/IAutoCloseDTO'

export class AutoCloseVM {
  private autoClose
  private editVM

  constructor(autoClose, editVM) {
    this.autoClose = autoClose
    this.editVM = editVM
  }

  @computed
  public get value(): number {
    return this.autoClose.value
  }

  @computed
  public get period(): PeriodType {
    return this.autoClose.period
  }

  @action
  setValue(val: any) {
    if (val) {
      this.autoClose.value = parseInt(val)
      this.editVM.resetDates()
    } else {
      this.autoClose.value = null
    }
  }

  @action
  setPeriod(val: unknown) {
    this.autoClose.period = val
    this.editVM.resetDates()
  }

  public toDTO(): IAutoCloseDTO {
    return this.autoClose.toDTO()
  }
}
