import React from 'react'
import { CardHeader } from '@material-ui/core'
import WidgetTitle from './WidgetTitle'
import Searchbar from 'src/app/shared/Searchbar'
import { UserTasksStore } from '../../store/UserTasksStore'
import { inject, observer } from 'mobx-react'

interface Props {
  userTasksStore?: UserTasksStore
}

const WidgetHeader: React.FC<Props> = ({ userTasksStore }) => {
  const vm = userTasksStore.pendingUserTasksWidgetVM
  if (!vm) return null
  if (!vm.shouldRender) return null

  return (
    <CardHeader
      className='drag-handle'
      title={<WidgetTitle />}
      action={
        <div id='ManageSurveysSearch'>
          <Searchbar
            focused={vm.typedFilterText.length > 0}
            className='search'
            searchValue={vm.typedFilterText}
            onSearchChange={(e) => {
              vm.setTabIndex(1)
              vm.setQuickFilter(e.target.value)
            }}
          />
        </div>
      }
    />
  )
}

export default inject('userTasksStore')(observer(WidgetHeader))
