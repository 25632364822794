import React from 'react'
import { observer, inject } from 'mobx-react'
import LocalizationStore from '../../localization/LocalizationStore'
import { CardHeader, IconButton, Tooltip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import Searchbar from '../../shared/Searchbar'
import QuestionsImportModal from '../views/import/QuestionsImportModal'
import QuestionTypeFilter from './QuestionTypesFilter'
import QuestionCategoryFilter from './QuestionCategoryFilter'
import { QuestionCatalogWidgetVM } from '../view-models/QuestionCatalogWidgetVM'

interface WidgetHeaderProps {
  localizationStore?: LocalizationStore
  vm: QuestionCatalogWidgetVM
}

const WidgetHeader: React.FC<WidgetHeaderProps> = ({ localizationStore, vm }) => {
  const { lzStrings } = localizationStore

  return (
    <>
      <QuestionsImportModal vm={vm.importVM} />
      <CardHeader
        title={lzStrings.questionsCatalogWidget.questions_catalog}
        action={
          <>
            <div id='QuesCatalogSearch' className='action-btn'>
              <Searchbar
                focused={vm.hasFilter}
                className='search'
                searchValue={vm.typedFilterText}
                onSearchChange={(e) => vm.setQuickFilter(e.target.value)}
              />
            </div>
            <div id='QuesTypeFilter' className='action-btn'>
              <QuestionTypeFilter vm={vm.typesFilterVM} />
            </div>
            <div id='QuesCategoryFilter' className='action-btn'>
              <QuestionCategoryFilter vm={vm} />
            </div>
            <div id='QuestionImport' className='action-btn'>
              <Tooltip title={'Import Questions'} placement='bottom'>
                <IconButton size='small' onClick={() => vm.toggleImportModal()}>
                  <ImportExportIcon />
                </IconButton>
              </Tooltip>
            </div>
            <div id='QuesCatalogAdd' className='action-btn'>
              <Tooltip title={lzStrings.questionsCatalogWidget.add_new_question} placement='bottom'>
                <IconButton className='widget-cta' size='small' onClick={() => vm.addQuestion()}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </div>
          </>
        }
        className='widgetHeader'
      />
    </>
  )
}

export default inject('localizationStore')(observer(WidgetHeader))
