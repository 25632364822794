import { Checkbox, IconButton, ListItemText, Menu, MenuItem } from '@material-ui/core'
import React, { FC, useState } from 'react'
import MoreIcon from '@material-ui/icons/MoreVert'
import rootStore from '../../stores/RootStore'
import { observer } from 'mobx-react'
import { UsersAGGridVM } from '../view-models/UsersAGGridVM'

interface Props {
  className: string
  vm: UsersAGGridVM
}

const HiddenUserMenu: FC<Props> = ({ vm, className }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const isSystemAdmin = () => {
    if (!rootStore) return false
    if (!rootStore.userStore) return false
    if (!rootStore.userStore.user) return false
    return rootStore.appStore.isSystemAdmin
  }
  if (!isSystemAdmin()) return

  return (
    <div className={className}>
      <IconButton
        size='small'
        onClick={(e) => {
          setAnchorEl(e.currentTarget)
        }}
        className={Boolean(anchorEl) ? 'focused' : ''}
      >
        <MoreIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={1}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={() => {
            vm.toggleShowHidden()
            handleClose()
          }}
        >
          <Checkbox checked={vm.showHidden} />
          <ListItemText primary='View Hidden' />
        </MenuItem>
      </Menu>
    </div>
  )
}
export default observer(HiddenUserMenu)
