import React from 'react'
import { inject, observer } from 'mobx-react'
import { TextField, Typography } from '@material-ui/core'
import { PulseQuestionEditVM } from '../../view-models/PulseQuestionEditVM'
import LocalizationStore from '../../../localization/LocalizationStore'
import './PulseQuestionDropdownInfo.scss'

interface Props {
  localizationStore?: LocalizationStore
}

interface Props {
  vm: PulseQuestionEditVM
}

const PulseQuestionTitle: React.FC<Props> = ({ localizationStore, vm }) => {
  const { questionsCatalogWidgetDrawer: lz } = localizationStore.lzStrings
  if (!vm) return null

  const renderDropdownPlaceholder = () => {
    if (vm.questionType !== 'dropdown') return null
    return (
      <div className='dropdown-label'>
        <Typography className='dropdown-label-header'>{lz.dropdown_placeholder}</Typography>
        <TextField
          error={!vm.placeholderValid}
          id='outlined-placeholder'
          value={vm.placeholder}
          onChange={(e) => {
            vm.setPlaceholder(e.target.value)
          }}
          fullWidth
          margin='none'
          variant='outlined'
        />
      </div>
    )
  }

  const renderDropdownInfo = () => {
    if (vm.questionType !== 'dropdown') return null
    return (
      <div className={'dropdown-info'}>
        {renderDropdownPlaceholder()}
      </div>
    )
  }

  return <div id='PulseQuestionDropdownInfo'>{renderDropdownInfo()}</div>
}

export default inject('localizationStore')(observer(PulseQuestionTitle))
