import { CellClickedEvent, ColDef, GridOptions, RowClickedEvent } from 'ag-grid-community'
import { action, computed, observable } from 'mobx'
import { AGGridVM } from '../../../shared/ag-grid/AGGridVM'
import { nameOf } from '../../../shared/nameOf'
import { RootStore } from '../../../stores/RootStore'
import { ISurveyTemplatesFindRequest } from '../../../survey-templates/interfaces/ISurveyTemplatesFindRequest'
import { SurveyTemplatesSelectVM } from './SurveyTemplatesSelectVM'
import { SurveyTemplateSelectRowVM } from './SurveyTemplateSelectRowVM'
import { SurveyTemplatesSelectDataStore } from '../../stores/SurveyTemplatesSelectDataStore'
import { SurveyTemplate } from '../../aggregates/SurveyTemplate'

export class SurveyTemplatesSelectTableVM extends AGGridVM {
  private rootStore: RootStore
  public dataStore: SurveyTemplatesSelectDataStore

  constructor(
    rootStore: RootStore,
    selectVM: SurveyTemplatesSelectVM,
    req: ISurveyTemplatesFindRequest
  ) {
    super()
    this.rootStore = rootStore
    this.selectVM = selectVM
    this.dataStore = new SurveyTemplatesSelectDataStore(this.rootStore, req)
    this.serverSideLoaded = true
  }
  @observable public selectedRow: SurveyTemplateSelectRowVM = null
  public selectVM: SurveyTemplatesSelectVM
  private PAGE_SIZE = 15

  @computed
  public get isLoaded(): boolean {
    return this.dataStore.isLoaded
  }

  @computed
  public get isSystemAdmin(): boolean {
    return this.rootStore.appStore.isSystemAdmin
  }

  @computed
  public get canManageTemplates(): boolean {
    if (this.isSystemAdmin) return true
    if (this.rootStore.appStore.isOrgAdmin && !this.selectVM.foldersVM.isSystemToggleMode)
      return true
    if (
      this.rootStore.appStore.canManageSurveyTemplates &&
      !this.selectVM.foldersVM.isSystemToggleMode
    )
      return true
    return false
  }

  @action
  public getRowNodeId(row): string {
    return row.objectId
  }

  @action
  public hardRefreshTemplatesList() {
    if (!this.gridApi) return
    this.scrollToTop()
    this.gridApi.refreshInfiniteCache()
    this.gridApi.purgeInfiniteCache()
  }

  @action
  public scrollToTop() {
    this.gridApi.ensureIndexVisible(0)
  }

  @computed
  public get shouldRender(): boolean {
    return true
  }

  @computed
  public get columnDefs(): ColDef[] {
    const colDef = [
      {
        headerName: 'Name',
        field: nameOf<SurveyTemplateSelectRowVM, string>((e) => e.name),
        sort: 'asc' as 'asc' | 'desc',
        onCellClicked: (e: CellClickedEvent) => {
          this.cellClicked(e)
        },
      },
      {
        headerName: 'Questions',
        field: nameOf<SurveyTemplateSelectRowVM, number>((e) => e.questionsCount),
        sort: 'asc' as 'asc' | 'desc',
        onCellClicked: (e: CellClickedEvent) => {
          this.cellClicked(e)
        },
      },
      {
        headerName: 'Created',
        field: nameOf<SurveyTemplateSelectRowVM, string>((e) => e.createdAt),
        sort: 'asc' as 'asc' | 'desc',
        onCellClicked: (e: CellClickedEvent) => {
          this.cellClicked(e)
        },
      },
    ]
    if (this.selectVM.editVM) {
      colDef.push({
        cellRenderer: 'actionsCell',
        sortable: false,
        onCellClicked: null,
      } as any)
    }
    return colDef
  }

  @action
  public getGridOptions(): GridOptions {
    return {
      ...this.baseGridOptions,
      onRowClicked: (e) => this.rowClicked(e),
      getRowNodeId: (e) => this.getRowNodeId(e),
      columnDefs: this.ensureTooltipFields(this.columnDefs),
      pagination: false,
      paginationAutoPageSize: false,
      paginationPageSize: this.PAGE_SIZE,
    }
  }

  protected onGridReadied() {
    this.gridApi.setDatasource({
      rowCount: this.totalRecords,
      getRows: async (params) => {
        params.sortModel.forEach((col: { colId: string; sort: 'asc' | 'desc' }, idx: number) => {
          let dbCol = col.colId
          if (dbCol === 'name') dbCol = 'name'
          if (dbCol === 'created') dbCol = 'createdAt'
          if (idx === 0) this.dataStore.setSort(dbCol, col.sort)
          if (idx > 0) this.dataStore.addSort(dbCol, col.sort)
        })
        if (params.sortModel.length === 0) this.dataStore.setSort('name')
        if (params.startRow === 0) this.dataStore.setPage(0)
        await this.dataStore.getNextPage()
        const rows = this.dataStore.surveyTemplates.map(
          (e) => new SurveyTemplateSelectRowVM(this.rootStore, e)
        )
        params.successCallback(rows, this.dataStore.totalRecords)
      },
    })
  }

  @computed
  public get rows() {
    return []
  }

  @action
  public cellClicked(e: RowClickedEvent) {
    const template: SurveyTemplate = e.data.template.clone()
    if (this.selectVM.editVM) {
      this.selectVM.editVM.isTemplateBuildMode = true
      this.selectVM.editVM.currentSurvey.setSurveyTemplateId(e.data.template.objectId)
      this.selectVM.editVM.currentSurvey.setName(template.name)
      this.selectVM.editVM.currentSurvey.setSelectedTemplate(template)
      return
    }
    const data: SurveyTemplateSelectRowVM = e.data
    return data.selectSystemTemplate()
  }

  @action
  public rowClicked(e: RowClickedEvent) {}

  @action
  public setQuickFilter(val: string) {
    this.typedFilterText = val
    if (this.quickFilterTO) clearTimeout(this.quickFilterTO)
    this.quickFilterTO = setTimeout(() => this.applyFilter(), 1000)
  }

  public applyFilter() {
    this.dataStore.setFilter(this.typedFilterText)
    this.reload()
    this.columnApi.applyColumnState({
      state: [{ colId: 'name', sort: 'asc' }],
    })
  }

  @computed
  public get owner() {
    return nameOf<SurveyTemplateSelectRowVM, any>((e) => e.owner)
  }

  @computed
  protected get totalRecords() {
    return this.dataStore.totalRecords
  }

  @computed
  public get hasRows(): boolean {
    return this.dataStore.hasListRecords
  }

  @action
  public selectPreview(row: SurveyTemplateSelectRowVM) {
    this.selectedRow = row
    this.selectVM.editVM.toggleSimplePreviewMode()
    this.selectVM.editVM.currentSurvey.setSurveyTemplateId(row.objectId)
    this.selectVM.editVM.previewSurvey()
  }
}
