import { DataStore } from '../../shared/data/DataStore'
import { nameOf } from '../../shared/nameOf'
import { RootStore } from '../../stores/RootStore'
import { SurveyParticipant } from '../aggregate/SurveyParticipant'
import { ISurveyParticipantDTO } from '../dtos/ISurveyParticipantDTO'
import Parse from 'parse'

export class SurveyParticipantsDataStore extends DataStore<
  SurveyParticipant,
  ISurveyParticipantDTO
> {
  private surveyId: string = ''
  constructor(rootStore: RootStore, surveyId: string) {
    super(rootStore, SurveyParticipant, 'surveyParticipants', [
      nameOf<ISurveyParticipantDTO, string>((e) => e.objectId),
      nameOf<ISurveyParticipantDTO, string>((e) => e.surveyId),
      nameOf<ISurveyParticipantDTO, string>((e) => e.organizationId),
      nameOf<ISurveyParticipantDTO, string>((e) => e.userId),
      nameOf<ISurveyParticipantDTO, string>((e) => e.email),
      nameOf<ISurveyParticipantDTO, string>((e) => e.firstName),
      nameOf<ISurveyParticipantDTO, string>((e) => e.lastName),
      nameOf<ISurveyParticipantDTO, string>((e) => e.invitationSent),
      nameOf<ISurveyParticipantDTO, string>((e) => e.emailStatus),
      nameOf<ISurveyParticipantDTO, string>((e) => e.emailStatusDate),
      nameOf<ISurveyParticipantDTO, string>((e) => e.viewedDate),
      nameOf<ISurveyParticipantDTO, string>((e) => e.reminderLastSentAt),
      nameOf<ISurveyParticipantDTO, string>((e) => e.nextReminderAt),
      nameOf<ISurveyParticipantDTO, string>((e) => e.dueDate),
      nameOf<ISurveyParticipantDTO, string>((e) => e.responseDate),
      nameOf<ISurveyParticipantDTO, string>((e) => e.nextInvitationAt),
      nameOf<ISurveyParticipantDTO, string>((e) => e.userSurveyId),
      nameOf<ISurveyParticipantDTO, string>((e) => e.publishEmailClickDate),
      nameOf<ISurveyParticipantDTO, string>((e) => e.publishEmailCreatedDate),
      nameOf<ISurveyParticipantDTO, string>((e) => e.publishEmailDeliverDate),
      nameOf<ISurveyParticipantDTO, string>((e) => e.publishEmailDropDate),
      nameOf<ISurveyParticipantDTO, string>((e) => e.publishEmailProcessedDate),
      nameOf<ISurveyParticipantDTO, string>((e) => e.publishEmailOpenDate),
      nameOf<ISurveyParticipantDTO, string>((e) => e.publishEmailBounceDate),
      nameOf<ISurveyParticipantDTO, string>((e) => e.publishEmailErrorDate),
    ])
    this.paged = true
    this.recordsPerPage = 100
    this.surveyId = surveyId
    this.filterColumns = [
      nameOf<ISurveyParticipantDTO, string>((e) => e.email),
      nameOf<ISurveyParticipantDTO, string>((e) => e.firstName),
      nameOf<ISurveyParticipantDTO, string>((e) => e.lastName),
    ]
  }

  public get surveyParticipants() {
    return this.records
  }

  protected getBaseQuery() {
    let query = new Parse.Query(this.className)
    query.equalTo('organizationId', this.rootStore.appStore.currentOrgId)
    query.equalTo('surveyId', this.surveyId)
    return query
  }
}
