import { action, observable } from 'mobx'
import { IAggregate } from '../../shared/data/IAggregate'
import { IDTO } from '../../shared/data/IDTO'
import { ISurveyParticipantDTO } from '../dtos/ISurveyParticipantDTO'
import { serializable, serialize } from 'serializr'

export class SurveyParticipant implements ISurveyParticipantDTO, IAggregate {
  isDeleted: boolean
  static create(orgId) {
    const surveyParticipant = new SurveyParticipant()
    surveyParticipant.organizationId = orgId
    return surveyParticipant
  }

  @serializable @observable public objectId: string
  @serializable @observable public surveyId: string
  @serializable @observable public organizationId: string
  @serializable @observable public userId?: string
  @serializable @observable public email?: string
  @serializable @observable public firstName: string
  @serializable @observable public lastName: string
  @serializable @observable public invitationSent?: string
  @serializable @observable public emailStatus?: string
  @serializable @observable public emailStatusDate?: string
  @serializable @observable public publishEmailDeliverDate?: string
  @serializable @observable public publishEmailProcessedDate?: string
  @serializable @observable public publishEmailCreatedDate?: string
  @serializable @observable public publishEmailOpenDate?: string
  @serializable @observable public publishEmailDropDate?: string
  @serializable @observable public publishEmailClickDate?: string
  @serializable @observable public publishEmailBounceDate?: string
  @serializable @observable public publishEmailErrorDate?: string
  @serializable @observable public reminderEmailDeliverDate?: string
  @serializable @observable public reminderEmailProcessedDate?: string
  @serializable @observable public reminderEmailCreatedDate?: string
  @serializable @observable public reminderEmailOpenDate?: string
  @serializable @observable public reminderEmailDropDate?: string
  @serializable @observable public reminderEmailClickDate?: string
  @serializable @observable public reminderEmailBounceDate?: string
  @serializable @observable public reminderEmailErrorDate?: string
  @serializable @observable public viewedDate?: string
  @serializable @observable public reminderLastSentAt?: string
  @serializable @observable public nextReminderAt?: string
  @serializable @observable public dueDate?: string
  @serializable @observable public responseDate?: string
  @serializable @observable public nextInvitationAt?: string
  @serializable @observable public userSurveyId?: string

  public isOnServer: boolean = false
  public type: string = 'role'

  @action
  public markIsNotOnServer() {
    this.isOnServer = false
  }

  @action
  public markIsOnServer() {
    this.isOnServer = true
  }

  public serialize() {
    return serialize(this)
  }
}
