import { action, computed, observable } from 'mobx'
import { Group } from '../../../groups/aggregate/Group'
import { LabelsStore } from '../../../labels/store/LabelsStore'
import { Role } from '../../../roles/aggregate/Role'
import { AGGridRowVM } from '../../../shared/ag-grid/AGGridRowVM'
import { RootStore } from '../../../stores/RootStore'
import { GroupingsTabVM } from './GroupingsTabVM'

export class GroupingRowVM extends AGGridRowVM<Group | Role> {
  private tabVM: GroupingsTabVM

  constructor(rootStore: RootStore, item: Group | Role, tabVM: GroupingsTabVM) {
    super(rootStore, item, tabVM)
    this.tabVM = tabVM
    this.labelsStore = this.rootStore.labelsStore
  }

  @observable public emailTo: string = ''
  @observable public labelsStore: LabelsStore

  public get rowType(): string {
    return this.capitalize(this.tabVM.type)
  }

  @computed
  public get objectId(): string {
    return this.aggregate.objectId
  }

  @computed
  public get name(): string {
    return this.aggregate.name
  }

  private capitalize(str) {
    const lower = str.toLowerCase()
    return str.charAt(0).toUpperCase() + lower.slice(1)
  }

  @computed
  public get iconURL(): string {
    return null
  }

  @computed
  public get groupsDelimited() {
    return ''
  }

  @computed
  public get rolesDelimited() {
    return ''
  }

  @computed
  public get surveyUrl(): string {
    if (this.rowType === 'Role') return '/surveys/foraudiencerole'
    return '/surveys/foraudiencegroup'
  }

  @computed
  public get eventUrl(): string {
    if (this.rowType === 'Role') return '/calendar/v2/foraudiencerole'
    return '/calendar/v2/foraudiencegroup'
  }

  @action
  public goToSurvey() {
    let url = this.surveyUrl
    url += '/' + this.objectId
    this.rootStore.appStore.router.push(url)
  }

  @action
  public goToEvent() {
    console.log(this.aggregate)
    let url = this.eventUrl
    url += '/' + this.objectId
    this.rootStore.appStore.router.push(url)
  }

  @action
  public setEmailTo(val: string) {
    this.emailTo = val
  }

  @computed
  public get mailToLink() {
    let users = []
    if (this.rowType === 'Group') {
      users = this.rootStore.audienceMembersStore.currentOrgUsersInGroup(this.objectId)
    } else {
      users = this.rootStore.audienceMembersStore.currentOrgUsersInRole(this.objectId)
    }

    if (users) {
      const userEmails = []
      for (let orgUser of users) {
        const foundUser = this.rootStore.audienceMembersStore.getUser(orgUser.objectId)
        if (foundUser) {
          userEmails.push(foundUser.email)
        }
      }
      let href = ''
      if (this.emailTo === 'to') {
        href = 'mailto:' + userEmails.join(', ')
      } else if (this.emailTo === 'bcc') {
        href = 'mailto:?bcc=' + userEmails.join(', ')
      }
      return href
    }
  }

  @computed
  public get canSurvey(): boolean {
    if (this.rootStore.appStore.canSurvey) return true
    return false
  }

  public setEmailAnchorEl(currentTarget: EventTarget & HTMLButtonElement): void {
    this.tabVM.setEmailAnchorEl(this.rowType, currentTarget, this)
  }
}
