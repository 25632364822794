import { Button, Checkbox, Collapse, ListItem } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import React from 'react'
import MuiConfirmDialog from 'src/app/shared/MuiConfirmDialog'
import Searchbar from 'src/app/shared/Searchbar'
import { GroupsAndRolesStore } from '../../GroupsAndRolesStore'
import './WeightProfileModal.scss'

interface Props {
  groupsAndRolesStore?: GroupsAndRolesStore
}

const CopyWeightProfile: React.FC<Props> = ({ groupsAndRolesStore }) => {
  const { groupsAndRolesWidgetVM: parentVM } = groupsAndRolesStore

  const renderCopyDialog = () => {
    return (
      <MuiConfirmDialog
        open={parentVM.showCopyDialog}
        onClose={() => parentVM.toggleCopyDialog()}
        onConfirm={() => parentVM.copyWeightProfileToRoles()}
        confirmMessage={
          'Are you sure you would like to copy this weight profile to the selected roles?'
        }
      />
    )
  }

  return (
    <>
      <Collapse
        className='options-collapse'
        in={parentVM.showCopyOptions}
        timeout='auto'
        unmountOnExit
      >
        <ListItem>
          <Searchbar
            focused={parentVM.optionsKeywordFilter.length > 0}
            searchValue={parentVM.optionsKeywordFilter}
            onSearchChange={(e) => parentVM.setOptionsKeywordFilter(e.target.value)}
          />
        </ListItem>
        {parentVM.copyOptionsPagedData.displayRows.map((e, index) => (
          <ListItem key={index} value={e.id}>
            <Checkbox
              onChange={() => parentVM.toggleRoleToCopySelect(e.id)}
              edge='start'
              checked={parentVM.selectedCopyOptions.includes(e.id)}
              disableRipple
            />
            {e.name}
          </ListItem>
        ))}
        <Button onClick={() => parentVM.toggleShowCopyOptions()}>Cancel</Button>
        <Button
          disabled={!parentVM.selectedCopyOptions.length}
          variant='contained'
          className='btn-outlined-white'
          onClick={() => parentVM.toggleCopyDialog()}
        >
          Copy
        </Button>
      </Collapse>
      {renderCopyDialog()}
    </>
  )
}

export default inject('groupsAndRolesStore')(observer(CopyWeightProfile))
