import React, { FC } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Toolbar from '@material-ui/core/Toolbar'
import ReplayIcon from '@material-ui/icons/Replay'
import MoreMenu from '../MoreMenu'
import './MuiDrawerToolbar.scss'
import { IconButton, Tooltip } from '@material-ui/core'
import { observer } from 'mobx-react'

export interface DrawerToolbarProps {
  disabled?: boolean
  showReset?: boolean
  handleCancelClick?: (e) => void
  handleSubmitClick?: (e) => void
  onConfirmReset?: () => void
  moreMenuProps?: {
    hidden?: boolean
    isEditMode?: boolean
    onConfirmDelete: (e) => void
    onConfirmCopy?: () => void
    onConfirmArchive?: () => void
    onConfirmSendPasswordReset?: () => void
    onConfirmSetNewPassword?: () => void
    onConfirmArchiveAll?: () => void
    onUnlockAccount?: () => void
    onConfirmSendWelcomeEmail?: () => void
    onConfirmExpirePassword?: () => void
    itemType?: string
    itemName?: string
    archiveButtonLabel?: string
  }
  renderAdditionalMenuItems?: (close: () => void) => any
}

const MuiDrawerToolbar: FC<DrawerToolbarProps> = ({
  disabled,
  showReset,
  handleCancelClick,
  handleSubmitClick,
  onConfirmReset,
  moreMenuProps,
  renderAdditionalMenuItems: renderAdditionalMenuItems,
}) => {
  const renderMoreMenu = () => {
    if (!moreMenuProps) return null
    if (!moreMenuProps.onConfirmDelete && !renderAdditionalMenuItems) return null
    if (!moreMenuProps.isEditMode) return null
    if (moreMenuProps.hidden) return null
    return <MoreMenu {...moreMenuProps} renderAdditionalMenuItems={renderAdditionalMenuItems} />
  }

  return (
    <div id='MuiDrawerToolbar'>
      <AppBar position='relative' elevation={0}>
        <Toolbar disableGutters={true} variant='dense' className='tool-bar'>
          <div>
            {onConfirmReset && showReset && (
              <Tooltip title={'Undo Changes'} placement='bottom-start' enterDelay={500}>
                <IconButton onClick={onConfirmReset}>
                  <ReplayIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
          <div className='more-menu'>
            {renderMoreMenu()}
            <Button variant='text' onClick={handleCancelClick}>
              Cancel
            </Button>
            <Button variant='contained' disabled={disabled} onClick={handleSubmitClick}>
              Save
            </Button>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default observer(MuiDrawerToolbar)
