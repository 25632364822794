import Parse from 'parse'
import { observable, action, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Worksheet } from '../aggregate/Worksheet'
import { WidgetContainerVM } from '../../dashboardsV2/view-models/WidgetContainerVM'
import { WorksheetEditVM } from '../view-models/edit/WorksheetEditVM'
import { ChartWidgetVM } from '../view-models/chart-widget/ChartWidgetVM'
import { ChartWidgetDrawerVM } from '../view-models/chart-widget/ChartWidgetDrawerVM'
import { WorksheetsListWidgetVM } from '../view-models/list-widget/WorksheetsListWidgetVM'
import { IWorksheetDTO } from '../dtos/IWorksheetDTO'
import { DataStore } from '../../shared/data/DataStore'

export class WorksheetsStore extends DataStore<Worksheet, IWorksheetDTO> {
  private subscription: Parse.LiveQuerySubscription
  private query: Parse.Query

  constructor(rootStore: RootStore) {
    super(rootStore, Worksheet, 'worksheets', [
      'organizationId',
      'chartTypeId',
      'name',
      'forSurveys',
      'forPulseCampaigns',
      'defaultPulseCampaignTemplate',
      'forLoggedInUser',
      'channelPartnerId',
      'showTabs',
      'surveyId',
      'surveyTypeId',
      'tableauWorkbook',
      'tableauWorksheet',
      'tableauSiteName',
      'tableauServerUrl',
      'loggedInUserId',
      'visibleTo',
      'owner',
    ])
  }

  @observable public editVM: WorksheetEditVM = null
  @observable public listWidgetVM: WorksheetsListWidgetVM = null
  @observable public chartWidgetDrawerVM: ChartWidgetDrawerVM = null
  @observable public loaded: boolean = false

  @computed
  public get worksheets(): Worksheet[] {
    return this.records
  }

  @action
  public onListRecordsLoaded() {
    this.listWidgetVM = new WorksheetsListWidgetVM(this.rootStore)
    this.loaded = true
    this.listenToChanges()
  }

  @action
  public loadWorksheetEditVM(id: string, attempts: number = 0) {
    if (attempts === 14) return
    if (!this.rootStore.organizationsStore.currentOrganization) {
      setTimeout(() => this.loadWorksheetEditVM(id, attempts++), 500)
      return
    }
    if (!this.rootStore.userStore.loaded) {
      setTimeout(() => this.loadWorksheetEditVM(id, attempts++), 500)
      return
    }
    if (id === 'empty') this.editVM = null
    else if (id === 'new')
      this.editVM = new WorksheetEditVM(
        this.rootStore,
        Worksheet.create(
          this.rootStore.appStore.currentOrgId,
          this.rootStore.organizationsStore.currentOrganization.tableauSiteName
        )
      )
    else {
      const foundWorksheet = this.getWorksheet(id)
      if (!foundWorksheet) setTimeout(() => this.loadWorksheetEditVM(id, attempts++), 500)
      else this.editVM = new WorksheetEditVM(this.rootStore, foundWorksheet.clone())
    }
  }

  public getWorksheetWidgetVM(dashboardWidgetInfo: WidgetContainerVM): ChartWidgetVM {
    return new ChartWidgetVM(this.rootStore, dashboardWidgetInfo)
  }

  @action
  public openChartWidgetDrawer(worksheetWidgetVM: ChartWidgetVM) {
    this.chartWidgetDrawerVM = new ChartWidgetDrawerVM(this.rootStore, worksheetWidgetVM)
  }

  @computed
  public get currentOrgWorksheets(): Array<Worksheet> {
    return this.worksheets
  }

  public getWorksheet(id): Worksheet {
    return this.worksheets.find((e) => e.objectId === id)
  }

  public deleteWorksheet(id) {
    const idx = this.worksheets.findIndex((e) => e.objectId === id)
    if (idx > -1) this.worksheets.splice(idx, 1)
  }

  public async refreshWorksheets() {
    this.loadListRecords()
  }

  public async updateWorksheet(obj: Parse.Object) {
    const serverObj = obj.toJSON()
    const worksheet = this.getWorksheet(obj.id)
    if (worksheet) worksheet.updateFromServer(serverObj)
  }

  public async listenToChanges() {
    if (process.env.NODE_ENV === 'test') return
    if (this.subscription) this.subscription.unsubscribe()
    this.query = new Parse.Query('worksheets')
    this.query.equalTo('organizationId', this.rootStore.appStore.currentOrgId)
    this.subscription = await this.query.subscribe()
    this.subscription.on('create', (e: Parse.Object) => {
      this.refreshWorksheets()
    })
    this.subscription.on('update', (e: Parse.Object) => {
      this.updateWorksheet(e)
    })
    this.subscription.on('delete', (e: Parse.Object) => {
      this.refreshWorksheets()
    })
  }

  public async stopListeningToChanges() {
    if (this.subscription) this.subscription.unsubscribe()
  }
}
