import React from 'react'
import { inject, observer } from 'mobx-react'
import { UserTasksStore } from '../../store/UserTasksStore'
import LocalizationStore from '../../../localization/LocalizationStore'
import { Button, Card, CardContent, CardActions, Typography, Grid } from '@material-ui/core'
import moment from 'moment'
import Avatar from '../../../shared/Avatar'

interface IWidgetTimelineUpcomingTasksSection {
  userTasksStore?: UserTasksStore
  localizationStore?: LocalizationStore
}

const WidgetTimelineUpcomingTasksSection: React.FC<IWidgetTimelineUpcomingTasksSection> = ({
  localizationStore,
  userTasksStore,
}) => {
  const { userTasksTimelineVM: VM } = userTasksStore
  const { lzStrings } = localizationStore

  if (!VM) return null

  const handleComplete = (userTaskId) => {
    VM.loadUserTask(userTaskId)
  }

  return (
    <>
      <Grid item xs={12} container justifyContent='center' className='upcoming-header-container'>
        <Grid
          item
          xs={12}
          container
          justifyContent='space-between'
          className={
            VM.upcomingPendingTasksTotal === 0 ? 'upcoming-header no-tasks-due' : 'upcoming-header'
          }
        >
          <Grid item>
            {`${lzStrings.pendingUserTasksWidget.upcoming} (${VM.upcomingPendingTasksTotal})`}
          </Grid>
          {VM.upcomingPendingTasksTotal > 0 ? (
            <Grid item>{VM.daysTilNextPendingTaskDue}</Grid>
          ) : (
            <Grid item>{`${lzStrings.pendingUserTasksWidget.no_tasks_due}`}</Grid>
          )}
        </Grid>
      </Grid>
      {Boolean(Object.keys(VM.upcomingPendingTasksGroupedByDueDate).length) ? (
        Object.keys(VM.upcomingPendingTasksGroupedByDueDate).map((dueDate) => {
          return (
            <Grid key={dueDate} item xs={12} container>
              <Grid
                item
                xs={1}
                container
                spacing={0}
                direction='column'
                justifyContent='center'
                alignItems='center'
                className='dateContainer'
              >
                <span className='upcomingMonth'>
                  {moment.unix(parseInt(dueDate)).format('MMM')}
                </span>
                <span className='upcomingDay'>{moment.unix(parseInt(dueDate)).format('DD')}</span>
                <span className='upcomingCircle'></span>
              </Grid>
              <Grid
                item
                xs={11}
                container
                alignItems='center'
                justifyContent='center'
                className='paddedContainer'
                wrap='nowrap'
              >
                {/* <Grid item>
                  <div className='arrow-left'></div>
                </Grid> */}
                <Grid item container>
                  <Grid item container direction='column'>
                    {VM.upcomingPendingTasksGroupedByDueDate[dueDate].map((userTask) => {
                      return (
                        <Card
                          elevation={1}
                          className='task-item'
                          key={userTask.objectId}
                          onClick={() => handleComplete(userTask.objectId)}
                        >
                          <CardContent>
                            <Typography gutterBottom className='task-name'>
                              {userTask.name}
                            </Typography>
                            <Typography color='textSecondary' className='task-desc'>
                              {(userTask.description && <span>{userTask.description}</span>) || (
                                <span>No description provided</span>
                              )}
                            </Typography>
                          </CardContent>
                          <CardActions className='card-actions'>
                            <Typography
                              variant='body2'
                              component='span'
                              className='discipline-chip'
                            >
                              {userTask.discipline}
                            </Typography>
                            <Avatar user={userTask.creator} size={26} fontSize={13} />
                          </CardActions>
                        </Card>

                        // <Grid
                        //   key={userTask.objectId}
                        //   item
                        //   container
                        //   alignItems='center'
                        //   className='paddedContainer'
                        // >
                        //   <Grid item xs={6}>
                        //     {userTask.name}
                        //   </Grid>
                        //   <Grid item xs={2}>
                        //     {userTask.discipline}
                        //   </Grid>
                        //   <Grid item xs={2}>
                        //     <Avatar user={userTask.creator} size={40} />
                        //   </Grid>
                        //   <Grid item xs={2}>
                        //     <Button
                        //       variant='contained'
                        //       onClick={() => handleComplete(userTask.objectId)}
                        //     >
                        //       {lzStrings.pendingUserTasksWidget.complete}
                        //     </Button>
                        //   </Grid>
                        // </Grid>
                      )
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )
        })
      ) : (
        <Grid item xs={12} className='no-tasks-due' />
      )}
    </>
  )
}
export default inject(
  'localizationStore',
  'userTasksStore'
)(observer(WidgetTimelineUpcomingTasksSection))
