import React from 'react'
import { OrganizationsSAStore } from '../../store/OrganizationsSAStore'
import LocalizationStore from '../../../localization/LocalizationStore'
import { inject, observer } from 'mobx-react'
import EmailIcon from '@material-ui/icons/Email'
import TextsmsIcon from '@material-ui/icons/Textsms'
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid'
import TasksIcon from '@material-ui/icons/Assignment'
import ChatIcon from '@material-ui/icons/ChatBubbleOutlineRounded'
import SurveysIcon from '@material-ui/icons/LiveHelpOutlined'
import CalendarIcon from '@material-ui/icons/CalendarTodayRounded'
import DataUsageIcon from '@material-ui/icons/DataUsage'
import GoalsIcon from '@material-ui/icons/TrackChanges'
import { Paper, Grid, InputLabel, TextField, ButtonGroup, Button } from '@material-ui/core'
import ReorderIcon from '@material-ui/icons/Reorder'
import TimelineIcon from '@material-ui/icons/Timeline'
import './OrganizationEditDrawer.scss'

interface Props {
  organizationsSAStore?: OrganizationsSAStore
  localizationStore?: LocalizationStore
}

const OrganizationModules: React.FC<Props> = ({ organizationsSAStore, localizationStore }) => {
  const { editVM: vm } = organizationsSAStore
  const { organizationsSADrawer: strings } = localizationStore.lzStrings
  const { lzStrings } = localizationStore
  if (!vm) return null
  if (vm.isNew) return null

  const renderNotifications = () => {
    return (
      <>
        <InputLabel className='details-info-label'>{strings.notification_settings}</InputLabel>
        <ButtonGroup>
          <Button
            key='text'
            variant='outlined'
            className={vm.isTextChecked ? 'Mui-selected' : ''}
            startIcon={<TextsmsIcon />}
            onClick={() => vm.toggleIsTextChecked()}
          >
            {strings.text}
          </Button>
          <Button
            key='push'
            variant='outlined'
            className={vm.isPushChecked ? 'Mui-selected' : ''}
            startIcon={<PhoneAndroidIcon />}
            onClick={() => vm.toggleIsPushChecked()}
          >
            {strings.push}
          </Button>
          <Button
            key='email'
            variant='outlined'
            className={vm.isEmailChecked ? 'Mui-selected' : ''}
            startIcon={<EmailIcon />}
            onClick={() => vm.toggleIsEmailChecked()}
          >
            {strings.email}
          </Button>
        </ButtonGroup>
      </>
    )
  }

  const renderOrgModules = () => {
    return (
      <>
        <InputLabel className='details-info-label'>{strings.module_settings}</InputLabel>
        <Grid container>
          <Grid item xs={12}>
            <ButtonGroup>
              <Button
                key='surveys'
                variant='outlined'
                className={vm.isSurveysEnabled ? 'Mui-selected' : ''}
                startIcon={<SurveysIcon />}
                onClick={() => vm.toggleIsSurveysEnabled()}
              >
                {strings.surveys}
              </Button>
              <Button
                key='chat'
                variant='outlined'
                className={vm.isChatEnabled ? 'Mui-selected' : ''}
                startIcon={<ChatIcon />}
                onClick={() => vm.toggleIsChatEnabled()}
              >
                {strings.chat}
              </Button>
              <Button
                key='calendar'
                variant='outlined'
                className={vm.isCalendarEnabled ? 'Mui-selected' : ''}
                startIcon={<CalendarIcon />}
                onClick={() => vm.toggleIsCalendarEnabled()}
              >
                {strings.calendar}
              </Button>
              <Button
                key='consents'
                variant='outlined'
                className={vm.isConsentsEnabled ? 'Mui-selected' : ''}
                startIcon={<DataUsageIcon />}
                onClick={() => vm.toggleIsConsentsEnabled()}
              >
                {strings.consents}
              </Button>
              <Button
                key='tasks'
                variant='outlined'
                className={vm.isTasksEnabled ? 'Mui-selected' : ''}
                startIcon={<TasksIcon />}
                onClick={() => vm.toggleIsTasksEnabled()}
              >
                {strings.tasks}
              </Button>
              <Button
                key='goals'
                variant='outlined'
                className={vm.isGoalsEnabled ? 'Mui-selected' : ''}
                startIcon={<GoalsIcon />}
                onClick={() => vm.toggleIsGoalsEnabled()}
              >
                {strings.goals}
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item xs={12} className='module-row'>
            <ButtonGroup>
              <Button
                key='trainings'
                variant='outlined'
                className={vm.isTrainingsEnabled ? 'Mui-selected' : ''}
                startIcon={<GoalsIcon />}
                onClick={() => vm.toggleIsTrainingsEnabled()}
              >
                {strings.trainings}
              </Button>
              <Button
                key='custom_collections'
                variant='outlined'
                className={vm.isCustomCollectionsEnabled ? 'Mui-selected' : ''}
                startIcon={<ReorderIcon />}
                onClick={() => vm.toggleIsCustomCollectionsEnabled()}
              >
                {strings.custom_collections}
              </Button>
              <Button
                key='pulse_campaigns'
                variant='outlined'
                className={vm.isPulseCampaignsEnabled ? 'Mui-selected' : ''}
                startIcon={<TimelineIcon />}
                onClick={() => vm.toggleIsPulseCampaignsEnabled()}
              >
                {strings.pulse_campaigns}
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </>
    )
  }

  const renderModulesSection = () => {
    return (
      <div className='details-paper'>
        <h6>{strings.modules}</h6>
        {renderOrgModules()}
        {renderNotifications()}
      </div>
    )
  }

  return (
    <div id='OrganizationModules'>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          {renderModulesSection()}
        </Grid>
      </Grid>
    </div>
  )
}

export default inject('organizationsSAStore', 'localizationStore')(observer(OrganizationModules))
