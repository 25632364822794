import { observable, computed, action } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Attachment } from '../../upload/aggregate/Attachment'
import { FileOpenService } from '../../upload/services/FileOpenService'
import { TaskAttachmentVM } from './TaskAttachmentVM'

export class OldTaskAttachmentVM extends TaskAttachmentVM {

  constructor(rootStore: RootStore, attachment: Attachment) {
    super(rootStore, attachment)
  }

  @computed
  public get isLoaded() {
    return true
  }

  @computed
  public get objectId(): string {
    return this.attachment.objectId
  }

  @computed
  public get fileExtension(): string {
    if (!this.attachment) return ''
    if (!this.attachment.url) return ''
    const index = this.attachment.url.lastIndexOf('.')
    if (index < 0) return ''
    return this.attachment.url.substring(index, this.attachment.url.length)
  }

  @computed
  public get fileName(): string {
    if (!this.attachment) return null
    if (this.attachment.fileName.length > 50)
      return this.attachment.fileName.substring(0, 50) + '..' + this.fileExtension
    return this.attachment.fileName
  }

  @computed
  public get shorterFileName(): string {
    if (!this.attachment) return null
    if (this.attachment.fileName.length > 30)
      return this.attachment.fileName.substring(0, 30) + '..' + this.fileExtension
    return this.attachment.fileName
  }

  @computed
  public get thumbnail(): string {
    if (!this.attachment.thumbnail) return ''
    return this.attachment.thumbnail
  }

  @computed
  public get isVideo() {
    if (!this.attachment) return null
    if (this.type === 'mp4') return true
    if (this.type === 'mov') return true
    if (this.type === 'video') return true
    return false
  }

  @computed
  public get isImage() {
    if (!this.attachment) return null
    if (this.type === 'png') return true
    if (this.type === 'jpg') return true
    if (this.type === 'image') return true
    if (this.type === 'gif') return true
    return false
  }

  @computed
  public get url(): string {
    return this.attachment.url
  }

  @computed
  public get type(): string {
    return this.attachment.type
  }

  public async openAttachment() {
    const svc = new FileOpenService(this.rootStore)
    await svc.openFile(this.attachment)
  }

}
