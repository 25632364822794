import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { CategoriesStore } from 'src/app/categories/CategoriesStore'
import { Button, CardHeader, IconButton, Tooltip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import { LabelsStore } from '../../../../labels/store/LabelsStore'
import Searchbar from '../../../../shared/Searchbar'
import WidgetTitle from './WidgetTitle'
import './WidgetHeader.scss'
import CategoriesImportModal from '../../import/CategoriesImportModal'

interface HeaderProps {
  localizationStore?: LocalizationStore
  categoriesStore?: CategoriesStore
  labelsStore?: LabelsStore
}

const WidgetHeader: React.FC<HeaderProps> = ({
  localizationStore,
  categoriesStore,
  labelsStore,
}) => {
  const { listWidgetVM: vm } = categoriesStore
  const { widgetForm, importVM } = categoriesStore
  if (!vm) return

  const handleAdd = () => {
    widgetForm.initAddForm(categoriesStore.categories)
    widgetForm.openDrawer()
  }

  const handleEdit = () => {
    widgetForm.initEditForm(categoriesStore.categories, widgetForm.objectId)
    widgetForm.openDrawer()
  }

  const handleImportClick = () => {
    categoriesStore.toggleImportModal()
  }

  const renderEditButton = () => {
    if (!categoriesStore.editIconShown) return null
    return (
      <div id='CategoriesEdit' className='action-btn'>
        <React.Fragment>
          <Tooltip
            title={
              localizationStore.language === 'English'
                ? `${localizationStore.lzStrings.categoriesManagementWidget.edit
                } ${labelsStore.getLabelByLanguageAndFor('category')}`
                : `${labelsStore.getLabelByLanguageAndFor('category')} ${localizationStore.lzStrings.categoriesManagementWidget.edit
                }`
            }
            placement='bottom'
          >
            <span>
              <IconButton
                size='small'
                disabled={!Boolean(widgetForm.objectId)}
                onClick={handleEdit}
              >
                <EditOutlinedIcon />
              </IconButton>
            </span>
          </Tooltip>
        </React.Fragment>
      </div>
    )
  }

  const renderTreeButtons = () => {
    if (vm.tabIndex !== 1) return null
    const descriptionsText = vm.categoryTreeVM.showTreeItemDescriptions
      ? 'HIDE DESCRIPTION'
      : 'SHOW DESCRIPTION'
    const collapseText = vm.categoryTreeVM.expandTreeItems ? 'COLLAPSE ALL' : 'EXPAND ALL'
    return (
      <div>
        <Button onClick={() => vm.categoryTreeVM.toggleAllTreeItemDescriptions()}>
          {descriptionsText}
        </Button>
        <Button onClick={() => vm.categoryTreeVM.toggleAllTreeItemsExpanded()}>
          {collapseText}
        </Button>
      </div>
    )
  }

  return (
    <>
      {importVM && <CategoriesImportModal />}
      <CardHeader
        id='WidgetHeader'
        title={<WidgetTitle />}
        action={
          <>
            {renderEditButton()}
            {renderTreeButtons()}
            <div id='CategoriesImport' className='action-btn'>
              <React.Fragment>
                <Tooltip
                  title={
                    localizationStore.language === 'English'
                      ? `${localizationStore.lzStrings.categoriesManagementWidget.import
                      } ${labelsStore.getLabelByLanguageAndFor('category')}`
                      : `${labelsStore.getLabelByLanguageAndFor('category')}
                  ${localizationStore.lzStrings.categoriesManagementWidget.import}`
                  }
                  placement='bottom'
                >
                  <IconButton size='small' onClick={() => handleImportClick()}>
                    <ImportExportIcon />
                  </IconButton>
                </Tooltip>
              </React.Fragment>
            </div>

            <div id='CategoriesAdd' className='action-btn'>
              <React.Fragment>
                <Tooltip
                  title={
                    localizationStore.language === 'English'
                      ? `${localizationStore.lzStrings.categoriesManagementWidget.add_new
                      } ${labelsStore.getLabelByLanguageAndFor('category')}`
                      : `Neue ${labelsStore.getLabelByLanguageAndFor('category')} Hinzufügen`
                  }
                  placement='bottom'
                >
                  <IconButton className='widget-cta' size='small' onClick={() => handleAdd()}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </React.Fragment>
            </div>

            <div id='CategoryListSearch'>
              <Searchbar
                focused={vm.typedFilterText.length > 0}
                className='search'
                searchValue={vm.typedFilterText}
                onSearchChange={(e) => {
                  vm.setQuickFilter(e.target.value)
                }}
              />
            </div>
          </>
        }
      // title={
      //   navigator.language === 'en-US'
      //     ? `${labelsStore.getLabelByLanguageAndFor('categories')} ${
      //         localizationStore.lzStrings.categoriesListWidget.list
      //       }`
      //     : `${
      //         localizationStore.lzStrings.categoriesListWidget.list
      //       } ${labelsStore.getLabelByLanguageAndFor('categories')}`
      // }
      ></CardHeader>
    </>
  )
}

export default inject('localizationStore', 'categoriesStore', 'labelsStore')(observer(WidgetHeader))
