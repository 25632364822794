import React from 'react'
import { Card, IconButton, Typography } from '@material-ui/core'
import './MySurveysWidget.scss'
import { observer, inject, useLocalStore, Observer } from 'mobx-react'
import { RootStore } from '../../../stores/RootStore'
import ActiveUserSurveysTab from './ActiveUserSurveysTab'
import { MySurveysWidgetVM } from '../../view-models/my-surveys-widget/MySurveysWidgetVM'
import CompletedSurveyResponsesTab from './CompletedSurveyResponsesTab'
import InactiveDialog from './dialogs/InactiveDialog'

interface Props {
  rootStore?: RootStore
}

const MySurveysWidget: React.FC<Props> = ({ rootStore }) => {
  const localStore = useLocalStore(() => ({
    vm: new MySurveysWidgetVM(rootStore),
  }))
  const vm = localStore.vm

  return (
    <Card elevation={0} id='MySurveysWidget'>
      <InactiveDialog vm={vm.activeUserSurveysTab} />
      <ActiveUserSurveysTab vm={vm.activeUserSurveysTab} />
      <CompletedSurveyResponsesTab vm={vm.completedSurveyResponsesTab} />
    </Card>
  )
}

export default inject('rootStore')(observer(MySurveysWidget))
