import React from 'react'
import { Card, CircularProgress } from '@material-ui/core'
import { observer, inject } from 'mobx-react'
import { OrganizationsSAStore } from '../../store/OrganizationsSAStore'
import Header from './Header'
import Content from './Content'
import OrganizationEditDrawer from '../drawer/OrganizationEditDrawer'
import './OrganizationsSAListWidget.scss'

interface Props {
  organizationsSAStore?: OrganizationsSAStore
}

const OrganizationsSAListWidget: React.FC<Props> = ({ organizationsSAStore }) => {
  const { listWidgetVM } = organizationsSAStore
  if (!listWidgetVM) return null

  const renderSpinner = () => {
    if (!listWidgetVM.isLoading) return
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  const renderContent = () => {
    if (listWidgetVM.isLoading) return
    return <Content />
  }

  return (
    <>
      <Card id='OrganizationsSAListWidget' elevation={0}>
        <Header />
        {renderSpinner()}
        {renderContent()}
      </Card>
      <OrganizationEditDrawer />
    </>
  )
}

export default inject('organizationsSAStore')(observer(OrganizationsSAListWidget))
