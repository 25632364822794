import Parse from 'parse'
import { observable, computed, action } from 'mobx'
import rootStore, { RootStore } from '../../stores/RootStore'
import { IPulseCampaignDTO } from '../dto/IPulseCampaignDTO'
import { DataStore } from '../../shared/data/DataStore'
import { PulseCampaign } from '../aggregate/PulseCampaign'
import { IPulseCampaignListRequest } from '../interfaces/IPulseCampaignListRequest'
import { PulseCampaignsService } from '../service/PulseCampaignsService'
import { PulseCampaignsViewModelsService } from '../service/PulseCampaignsViewModelsService'
import { PulseCampaignEditVM } from '../view-models/PulseCampaignEditVM'
import { ICampaignTemplateFindRequest } from '../interfaces/ICampaignTemplateFindRequest'
import { IPulseCampaignTemplateGlobalRequest } from '../interfaces/IPulseCampaignTemplateGlobalRequest'

export class PulseCampaignsStore extends DataStore<PulseCampaign, IPulseCampaignDTO> {
  private request: IPulseCampaignListRequest
  public svc: PulseCampaignsService

  @observable public viewModels: PulseCampaignsViewModelsService = undefined

  constructor(rootStore: RootStore) {
    super(rootStore, PulseCampaign, 'pulseCampaigns', [
      'objectId',
      'organizationId',
      'isDeleted',
      'isTemplate',
      'schedule',
      
    ])
    this.paged = true
    this.recordsPerPage = 100
    this.pageNumber = 1
    this.svc = new PulseCampaignsService()
    this.viewModels = new PulseCampaignsViewModelsService(rootStore, this)
  }

  @observable public loaded: boolean = false

  @computed
  public get pulseCampaigns(): PulseCampaign[] {
    return this.records
  }

  protected getBaseQuery() {
    const query = new Parse.Query(this.className)
    query.equalTo('organizationId', rootStore.appStore.currentOrgId)
    query.notEqualTo('isDeleted', true)
    query.notEqualTo('isTemplate', true)
    return query
  }

  @computed
  public get currentOrgPulseCampaigns(): PulseCampaign[] {
    return this.records.filter((e) => e.organizationId === this.rootStore.appStore.currentOrgId)
  }

  public getPulseCampaign(objectId: string): PulseCampaign {
    return this.getRecord(objectId)
  }

  protected getListRecordsApiCall() {
    return async () => {
      const req: IPulseCampaignListRequest = {
        ...this.request,
        organizationId: rootStore.appStore.currentOrgId,
        terms: this.filter,
        skip: this.recordsPerPage * (this.pageNumber - 1),
        limit: this.recordsPerPage,
        sortColumnName: this.sortColumnName,
        sortDirection: this.sortDirection,
        listColumns: this.listColumns,
        isDeleted: false,
        isTemplate: false,
      }
      const result = await this.svc.getPulseCampaigns(req)
      this.totalRecords = result.count

      this.listRecordsQuery = this.getBaseQuery()
      this.listRecordsQuery.select(...this.listColumns)

      return result.pulseCampaigns
    }
  }

  public onListRecordsLoaded() {
    this.loaded = true
  }

  @action
  public async getCampaignTemplates(templateIds: string[]) {
    const req = {
      organizationId: this.rootStore.appStore.currentOrgId,
      templateIds: templateIds,
      listColumns: this.getFullColumns(),
    } as ICampaignTemplateFindRequest
    const svc = new PulseCampaignsService()
    return await svc.getCampaignTemplates(req)
  }

  @action
  public async findGlobalTemplates(templateIds: string[]) {
    const req = {
      organizationId: this.rootStore.appStore.currentOrgId,
      templateIds: templateIds,
      listColumns: this.getFullColumns(),
    } as IPulseCampaignTemplateGlobalRequest
    const svc = new PulseCampaignsService()
    return await svc.findGlobalTemplates(req)
  }

  @action
  public async createPulseCampaignTemplate(id: string) {
    this.viewModels.editVM = new PulseCampaignEditVM(
      this.rootStore,
      PulseCampaign.create(this.rootStore.appStore.currentOrgId),
      true
    )
  }

  protected getFullColumns() {
    return [
      'name',
      'startDate',
      'endDate',
      'organizationId',
      'status',
      'deliveryMode',
      'surveys',
      'fk_owner',
      'ownerUserId',
      'participants',
      'worksheetId',
      'pulseQuestionIds',
      'autoClose',
      'alarms',
      'pulseCampaignTypeId',
      'anonymizeResults',
      'mode',
    ]
  }
}
