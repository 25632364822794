import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../localization/LocalizationStore'
import { QuestionsStore } from '../store/QuestionsStore'
import { SurveyTypesStore } from '../../survey-types/store/SurveyTypesStore'
import { InputLabel } from '@material-ui/core'
import MuiSelect from '../../shared/MuiSelect'
import './DrawerSurveyTypeSection.scss'
import { LabelsStore } from '../../labels/store/LabelsStore'

interface DrawerSurveyTypeSectionProps {
  localizationStore?: LocalizationStore
  questionsStore?: QuestionsStore
  surveyTypesStore?: SurveyTypesStore
  labelsStore?: LabelsStore
}

const DrawerSurveyTypeSection: React.FC<DrawerSurveyTypeSectionProps> = ({
  localizationStore,
  questionsStore,
  surveyTypesStore,
  labelsStore,
}) => {
  const { lzStrings } = localizationStore
  const { editVM: QuestionCatalogWidgetForm } = questionsStore

  const handleSelect = (e) => {
    if (e) {
      QuestionCatalogWidgetForm.setField('surveyTypeId', e.value)
    } else {
      QuestionCatalogWidgetForm.clearSurveyType()
    }
  }

  const options = surveyTypesStore.surveyTypes.map((surveyType) => {
    return {
      label: surveyType.name,
      value: surveyType.objectId,
    }
  })

  const alphaOptions = options.sort((a, b) => a.label.localeCompare(b.label))

  return (
    <section id='surveyTypeSelect'>
      <InputLabel htmlFor='survey-type'>
        {`${labelsStore.getLabelByLanguageAndFor('pulse')} ${lzStrings.surveys.type}`}
      </InputLabel>
      <MuiSelect
        id='survey-type'
        value={QuestionCatalogWidgetForm.surveyTypeName}
        options={alphaOptions}
        onSelect={handleSelect}
        error={QuestionCatalogWidgetForm.surveyTypeError}
      />
    </section>
  )
}

export default inject(
  'localizationStore',
  'questionsStore',
  'surveyTypesStore',
  'labelsStore'
)(observer(DrawerSurveyTypeSection))
