import Parse from 'parse'
import { IGetBySurveyRequest } from '../interfaces/IGetBySurveyRequest'
import { IGetBySurveyResult } from '../interfaces/IGetBySurveyResult'
import { ISurveyEventSummaryResponse } from '../interfaces/ISurveyEventSummaryResponse'
import { ISurveyEventSummaryRequest } from '../interfaces/ISurveyEventSummaryRequest'

export class UserSurveyEventsService {
  public async getUserSurveyEvents(request: IGetBySurveyRequest): Promise<IGetBySurveyResult> {
    const result: IGetBySurveyResult = await Parse.Cloud.run('getUserSurveyEvents', request)
    return result
  }

  public async getSurveySendSummary(
    request: ISurveyEventSummaryRequest
  ): Promise<ISurveyEventSummaryResponse> {
    const result: ISurveyEventSummaryResponse = await Parse.Cloud.run(
      'getSurveySendSummary',
      request
    )
    return result
  }
}
