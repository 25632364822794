import { action, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Attachment } from '../../tasks/aggregates/Attachment'
import { ContentItemType } from '../types/ContentItemType'
import { FileAttachmentVM } from './FileAttachmentVM'
import { FileOpenService } from '../../upload/services/FileOpenService'

export class OldFileAttachmentVM extends FileAttachmentVM {
  constructor(rootStore: RootStore, contentItemType: ContentItemType, attachment: Attachment) {
    super(rootStore, contentItemType, attachment)
  }

  @computed
  public get type(): ContentItemType {
    return this.contentItemType
  }

  @computed
  public get fileType(): string {
    return 'file'
  }

  @computed
  public get isCMSItem(): boolean {
    return false
  }

  @computed
  public get isVideo() {
    return this.fileType === 'video'
  }

  @computed
  public get isImage() {
    return this.fileType === 'image'
  }

  @computed
  public get isDocument() {
    return !this.isImage && !this.isVideo
  }

  @computed
  public get thumbnailUrl() {
    if (!this.attachment) return ''
    if (this.attachment.type === 'video') return this.attachment.thumbnail
    return this.attachment.url
  }

  @computed
  public get url() {
    if (!this.attachment) return ''
    return this.attachment.url
  }

  @computed
  public get fileFormat(): string {
    if (!this.attachment) return ''
    const url = this.attachment.url
    const idx = url.lastIndexOf('.')
    const type = url.substring(idx, url.length)
    return type
  }

  @computed
  public get fileExtension(): string {
    if (!this.attachment) return ''
    if (!this.attachment.url) return ''
    const index = this.attachment.url.lastIndexOf('.')
    if (index < 0) return ''
    return this.attachment.url.substring(index, this.attachment.url.length)
  }

  @computed
  public get fileName(): string {
    if (!this.attachment) return null
    if (this.attachment.fileName.length > 50)
      return this.attachment.fileName.substring(0, 50) + '..' + this.fileExtension
    return this.attachment.fileName
  }

  @computed
  public get shorterFileName(): string {
    if (!this.attachment) return null
    if (this.attachment.fileName.length > 30)
      return this.attachment.fileName.substring(0, 30) + '..' + this.fileExtension
    return this.attachment.fileName
  }

  @computed
  public get hasYouTubeURL(): boolean {
    const url = this.url.toLowerCase()
    return url.includes('youtube') || url.includes('youtu.be')
  }

  @computed
  public get hasVimeoURL(): boolean {
    const url = this.url.toLowerCase()
    return url.includes('vimeo')
  }

  @action
  public async openAttachment() {
    if (!this.attachment) return null
    const svc = new FileOpenService(this.rootStore)
    await svc.openFile(this.attachment)
  }
}
