import React from 'react'
import { IndexRoute, Route } from 'react-router'
import Layout from '../../layout/Layout'
import SurveyEditScreen from '../../surveys/views/edit/SurveyEditScreen'
import { authenticate } from '../../auth/routes/AuthenticatedRoute'
import SurveyTemplateSelectWrapper from '../../survey-templates/views/manageV2/template-select/SurveyTemplateSelectWrapper'
import CreateSurveyScreenWrapper from '../../surveys/views/dialogs/CreateSurveyScreenWrapper'

export default function getSurveyRoutes() {
  return (
    <Route key={'svy0'} component={authenticate(Layout)} path='surveys'>
      <IndexRoute onEnter={(nextState, replace) => replace('/dashboard/surveys')} />
      <Route key={'svy2'} path='editv2/:id' component={SurveyEditScreen} />
      <Route
        key={'svy3'}
        path='forusertask/:templateId/:userTaskId/:userId'
        component={SurveyEditScreen}
      />
      <Route key={'svy4'} path='foruserevent/:eventId/:userId' component={SurveyEditScreen} />
      <Route key={'svy5'} path='foraudienceuser/:userId' component={SurveyEditScreen} />
      <Route key={'svy6'} path='foraudiencegroup/:groupId' component={SurveyEditScreen} />
      <Route key={'svy7'} path='foraudiencerole/:roleId' component={SurveyEditScreen} />
      <Route key={'svy8'} path='fromtemplate/:templateId' component={SurveyEditScreen} />
      <Route key={'svy9'} path='systemadminnew' component={SurveyTemplateSelectWrapper} />
      <Route
        key={'svy10'}
        path='fromsystemtemplate/:systemTemplateId'
        component={SurveyEditScreen}
      />
      <Route
        key={'svy11'}
        path='create'
        component={CreateSurveyScreenWrapper}
      />
    </Route>
  )
}
