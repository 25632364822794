import { observable, computed, action } from 'mobx'
import { CustomCollection } from '../../aggregate/CustomCollection'
import { RootStore } from '../../../stores/RootStore'
import { CustomCollectionsService } from '../../service/CustomCollectionsService'
import { FieldEditVM } from './FieldEditVM'
import { ICustomRecordsQueryResult } from '../../interfaces/ICustomRecordsQueryResult'
import {
  ColDef,
  GridReadyEvent,
  GridApi,
  ColumnApi,
  RowClickedEvent,
  RowNode,
  RowSelectedEvent,
} from 'ag-grid-community'
import { CustomRecordEditVM } from './CustomRecordEditVM'
import { CustomRecordsImportModalVM } from '../import-records/CustomRecordsImportModalVM'
import { CustomCollectionImportModalVM } from '../import/CustomCollectionImportModalVM'
import { IFieldDTO } from '../../dto/IFieldDTO'
import { CustomRecordModalVM } from '../import-records/CustomRecordModalVM'
import { Field } from '../../aggregate/Field'
import env from '../../../../env'
const querystring = require('querystring')

export class CustomCollectionEditVM {
  private rootStore: RootStore
  public gridApi: GridApi
  private columnApi: ColumnApi
  @observable private customCollection: CustomCollection
  @observable private qryResult: ICustomRecordsQueryResult = null
  @observable public selectedIds: Set<string> = new Set()
  @observable public unexpectedErrors: string[] = []
  @observable public isDwImporting = false
  @observable public checkingName = false
  @observable public isNameUnique = true
  @observable public AIStatus: string = 'Loading...'
  @observable public errorMessage: string = ''

  constructor(rootStore: RootStore, customCollection: CustomCollection) {
    this.rootStore = rootStore
    this.customCollection = customCollection
    this.importRecordsModalVM = new CustomRecordsImportModalVM(
      this.rootStore,
      this,
      customCollection
    )
    this.importModalVM = new CustomCollectionImportModalVM(this.rootStore, this, customCollection)
    this.customRecordModalVM = new CustomRecordModalVM(this.rootStore, customCollection, () =>
      this.updateHandler()
    )

    if (customCollection.watsonIBMModelId) {
      this.getStatus()
    }
  }

  @observable public isProcessing: boolean = false
  @observable public tabIndex: number = 0
  @observable public saveTried: boolean = false
  @observable public saveProgress: number = 0
  @observable public importModalVM: CustomCollectionImportModalVM = null
  @observable public importRecordsModalVM: CustomRecordsImportModalVM = null
  @observable public showDeleteDialog: boolean = false
  @observable public showResetDialog: boolean = false
  @observable public pendingImportFile = null
  @observable public customRecordModalVM: CustomRecordModalVM = null

  @action
  public async updateHandler() {
    await this.refresh()
  }

  @action
  public addNew() {
    // call a reset first
    this.customRecordModalVM.clearValues()
    this.customRecordModalVM.toggleShown()
  }

  @action
  public updateRecord() {
    this.customRecordModalVM.setValues(null)
    this.customRecordModalVM.toggleShown()
  }

  @computed
  public get customRecords(): CustomRecordEditVM[] {
    return this.customCollection.customRecords
      .filter((e) => !e.isDeleted)
      .map((e) => new CustomRecordEditVM(this.rootStore, e))
  }

  @action
  public setFields(fields: IFieldDTO[]) {
    this.customCollection.setFields(fields)
  }

  @action
  public setFile(filePath: string) {
    this.customCollection.setImportedFile(filePath)
  }

  public setPendingImportFile(file) {
    this.pendingImportFile = file
  }

  @computed
  public get getFile() {
    return this.customCollection.importedFile
  }

  @computed
  public get fields(): FieldEditVM[] {
    console.log('fields getter called again' + this.customCollection.fields.length)
    return this.customCollection.fields
      .filter((e) => !e.isDeleted)
      .map((e) => new FieldEditVM(this.rootStore, e))
  }

  @action
  public addField() {
    const newField = new Field()
    newField.id = this.customCollection.fields.length
    newField.isNew = true
    console.log(newField)
    this.customCollection.fields.push(newField)
  }

  @action
  public onGridReady(e: GridReadyEvent) {
    this.gridApi = e.api
    this.columnApi = e.columnApi

    this.gridApi.setDatasource({
      rowCount: null,
      getRows: (params) => {
        const svc = new CustomCollectionsService(this.rootStore)
        svc
          .getCustomRecords(
            this.customCollection,
            params.filterModel,
            params.sortModel,
            params.startRow,
            params.endRow - params.startRow
          )
          .then((result) => {
            const records = result.customRecords.map(
              (e) => new CustomRecordEditVM(this.rootStore, e)
            )
            const lastRow =
              records.length < params.endRow - params.startRow
                ? params.startRow + records.length
                : undefined
            params.successCallback(records, lastRow)

            this.updateGridData()
          })
      },
    })
    this.updateGridData()
  }

  @action
  public toggleDeleteDialog = () => {
    this.showDeleteDialog = !this.showDeleteDialog
  }

  @action
  public toggleResetDialog = () => {
    this.showResetDialog = !this.showResetDialog
  }

  @action
  public openImportModal() {
    this.importModalVM.toggleShown()
  }

  @action
  public openImportRecordsModal() {
    this.importRecordsModalVM.toggleShown()
  }

  @action
  public exportRecords() {
    const params = querystring.stringify({
      customCollectionId: this.customCollection.objectId,
      orgId: this.customCollection.organizationId,
      sessionToken: this.rootStore.userStore.parseUserInfo.sessionToken,
    })
    const url = `${env.var.REACT_APP_API_URL}/customRecords/export?${params}`
    window.location.href = url
  }

  @action
  public downloadTemplate() {
    const params = querystring.stringify({
      customCollectionId: this.customCollection.objectId,
      orgId: this.customCollection.organizationId,
      sessionToken: this.rootStore.userStore.parseUserInfo.sessionToken,
    })

    const url = `${env.var.REACT_APP_API_URL}/customRecords/downloadTemplate?${params}`
    window.location.href = url
  }

  @computed
  public get columnDefs(): ColDef[] {
    return this.fields
      .filter((e) => e.displayName)
      .map((e, i) => {
        const def: ColDef = {
          headerName: e.displayName,
          field: e.fieldName,
          editable: false,
          valueGetter: function (params) {
            const editVM: CustomRecordEditVM = params.data
            if (editVM) return params.data.getValue(e.fieldName, e.type)
          },
        }

        if (e.type !== 'User') {
          def.sortable = true
        }

        /*if (e.type === 'Number') {
        def.filter = 'agNumberColumnFilter'
      }*/

        // add checkbox to the first column
        if (i === 0) {
          def.checkboxSelection = true
          def.cellRenderer = function (params) {
            params.colDef
            if (params.value !== undefined) {
              return params.value
            } else {
              return '<div>Loading&nbsp&nbsp<img src="https://www.ag-grid.com/example-assets/loading.gif"></div>'
            }
          }
        }

        return def
      })
  }

  public getRowNodeId(row: CustomRecordEditVM): string {
    return row.objectId
  }

  @computed
  public get objectId(): string {
    return this.customCollection.objectId
  }

  @computed
  public get name(): string {
    return this.customCollection.name
  }

  @computed
  public get isAIModel(): boolean {
    return this.customCollection.watsonIBMModelId ? true : false
  }

  @computed
  public get isNew(): boolean {
    return this.customCollection.isNew
  }

  @action
  public setTab(idx) {
    this.tabIndex = idx
    this.resizeColumns()
  }

  @action
  public async getStatus() {
    this.AIStatus = 'Loading...'
    const svc = new CustomCollectionsService(this.rootStore)
    const result = await svc.getAIModelStatus()

    this.AIStatus = result.status
    this.errorMessage = result.modelErrors
  }

  @action
  public async setName(val) {
    this.customCollection.setName(val)
    this.isNameUnique = false
    this.checkingName = true
    const svc = new CustomCollectionsService(this.rootStore)
    this.isNameUnique = await svc.checkUniqueName(
      val,
      this.customCollection.objectId,
      this.customCollection.organizationId
    )
    this.checkingName = false
  }

  @computed
  public get recordCount(): number {
    const cc: CustomCollection = this.rootStore.customCollectionsStore.getCustomCollection(
      this.objectId
    )

    if (cc) return cc.customRecordsCount

    return 0
  }
  @computed
  public get recordsTabLabel(): string {
    const lbl = `Record Set (${this.recordCount})`
    return lbl
  }

  @action
  public async delete() {
    this.customCollection.delete()
    this.showDeleteDialog = false
    await this.save()
  }

  @action
  public async refresh(beginPercent = 20) {
    this.isProcessing = true
    this.setSaveProgress(beginPercent)
    this.gridApi.refreshInfiniteCache()

    this.gridApi.purgeInfiniteCache()
    this.setSaveProgress(100)
    this.showResetDialog = false
    this.isProcessing = false
  }

  public async dwImport() {
    this.isDwImporting = true
    const svc = new CustomCollectionsService(this.rootStore)
    await svc.dwImport(this.customCollection.objectId, this.customCollection.organizationId)
    this.isDwImporting = false
  }

  @action
  public async deleteSelected() {
    this.setSaveProgress(20)
    this.isProcessing = true

    const svc = new CustomCollectionsService(this.rootStore)
    await svc.deleteCustomRecords(
      Array.from(this.selectedIds),
      this.customCollection.objectId,
      this.customCollection.organizationId
    )

    this.setSaveProgress(50)

    await svc.dwImport(this.customCollection.objectId, this.customCollection.organizationId)

    this.isProcessing = false
    this.selectedIds.clear()

    await this.refresh(75)
  }

  @action
  public onRowSelected(node: RowSelectedEvent) {
    const customRecordEditVM: CustomRecordEditVM = node.data
    customRecordEditVM.toggleSelected()
    if (customRecordEditVM.isSelected) {
      this.selectedIds.add(customRecordEditVM.objectId)
    } else {
      this.selectedIds.delete(customRecordEditVM.objectId)
    }
  }

  @action
  public onRowClicked(e: RowClickedEvent): void {
    const customRecordEditVM: CustomRecordEditVM = e.node.data
    if (!customRecordEditVM) return
    this.customRecordModalVM.setValues(customRecordEditVM.getCustomRecord())
    this.customRecordModalVM.toggleShown()
  }

  @computed
  public get isNameValid(): boolean {
    return this.name && this.isNameUnique
  }

  @computed
  public get invalidReasons(): string[] {
    const invalidReasons = []
    let hasFields = false
    let missingDisplayNames = false
    const userFields = []
    for (const field of this.customCollection.fields) {
      if (!field.isDeleted) {
        hasFields = true

        if (field.type === 'User') {
          userFields.push(field.displayName)
        }

        if (!field.displayName) {
          missingDisplayNames = true
        }
      }
    }

    if (missingDisplayNames) {
      invalidReasons.push(`One or more Field Definitions are missing required field 'Name'`)
    }

    if (!hasFields) {
      invalidReasons.push(
        'Please import Field Definitions by uploading your data file. At least 1 field is required'
      )
    }

    if (userFields.length > 1) {
      invalidReasons.push(
        `Only one field of type 'User' is allowed, found: ${userFields.join(',')}`
      )
    }
    return invalidReasons
  }

  /*
    Are the require fields/types available such that the data
    can be imported into RippleWorx Time Series Metric
  */
  @computed
  public get hasTimeSeriesFields(): boolean {
    let hasValueFactDate = false
    let hasUser = false
    this.customCollection.fields.forEach((field) => {
      if (field.type === 'User') hasUser = true
      if (field.isValueFactDate) hasValueFactDate = true
    })

    return hasValueFactDate && hasUser
  }

  @computed
  public get isValid(): boolean {
    if (this.invalidReasons.length) return false
    if (!this.isNameValid) return false

    return true
  }

  private updateGridData() {
    if (!this.gridApi) return
    this.resizeColumns()
  }

  private resizeColumns() {
    if (!this.columnApi) return
    if (this.tabIndex !== 1) return
    setTimeout(() => this.columnApi.autoSizeAllColumns(), 250)
  }

  @action
  private setSaveProgress(val: number) {
    this.saveProgress = val
  }

  private getProcessingMessage() {
    if (this.customCollection.isDeleted) {
      return `Deleting '${this.customCollection.name}' Collection`
    } else if (this.customCollection.isNew && this.customCollection.fields.length) {
      return `Saving Field Definitions and Uploading Records`
    } else if (!this.customCollection.isNew) {
      return `Saving Custom Collection`
    } else {
      // use default message
      return ''
    }
  }

  @action
  public async save() {
    this.saveTried = true
    if (!this.isValid) return
    this.importModalVM.processingMsg = this.getProcessingMessage()
    this.isProcessing = true
    this.importModalVM.importProcessing = true
    this.importModalVM.toggleShown()
    const svc = new CustomCollectionsService(this.rootStore)
    this.setSaveProgress(20)
    const result = await this.saveCustomCollection(svc)
    this.setSaveProgress(50)

    this.importModalVM.toggleShown()
    this.importModalVM.importProcessing = false

    if (!result.success) {
      this.unexpectedErrors = result.errorMessages
      this.setSaveProgress(100)
      this.isProcessing = false
      // TODO Somehow if there is an error saving the customCollection record
      return
    }

    if (this.customCollection.isDeleted) {
      this.setSaveProgress(100)
      this.rootStore.appStore.router.push('/dashboard/tenantAdmin/config')
      return
    }

    Object.assign(this.customCollection, result.customCollection)
    // make sure the collection is no longer new since save was success
    this.customCollection.isNew = false

    // make sure we do not send the file to be uploaded again
    this.customCollection.importedFile = null
    const importRecordsResult = result.customRecordsImportResult
    if (importRecordsResult && !importRecordsResult.success) {
      this.importRecordsModalVM.toggleShown()
      this.importRecordsModalVM.setResult(result.customRecordsImportResult)
    }

    this.setSaveProgress(100)
    this.isProcessing = false
    this.setTab(1)
    const savedId = result.customCollection.objectId
    this.rootStore.appStore.router.push(`/customCollections/edit/${savedId}`)
  }

  private async saveCustomCollection(svc: CustomCollectionsService) {
    const dto = this.customCollection.serialize()
    return await svc.saveCustomCollection(dto, this.rootStore.appStore.currentOrgId)
  }
}
