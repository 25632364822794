import * as React from 'react'
import './OptionsList.scss'
import { inject, observer } from 'mobx-react'
import { SurveysStore } from '../../../store/SurveysStore'
import { Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import OptionRow from './OptionRow'
import { SortableElement, SortableContainer } from 'react-sortable-hoc'

const SortableItem: any = SortableElement(({ value }) => (
  <div style={{ zIndex: 99999999 }} className='option-draggable-item'>
    {value}
  </div>
))

const SortableList: any = SortableContainer(({ items }) => {
  return (
    <div>
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} value={value} />
      ))}
    </div>
  )
})

interface Props {
  surveysStore: SurveysStore
}

const OptionsList = ({ surveysStore }: Props) => {
  const { editVM } = surveysStore.viewModels
  if (!editVM) return null

  const options = editVM.currentQuestion.options.map((e, idx) => (
    <OptionRow disabled={editVM.isReadOnly} option={e} idx={idx} key={'opt' + idx} />
  ))

  const onSortEnd = ({ oldIndex, newIndex }, e) => {
    if (oldIndex === newIndex) return
    if (editVM.isReadOnly) return
    editVM.moveOption(oldIndex, newIndex)
  }

  return (
    <div id='OptionsList'>
      <SortableList distance={3} items={options} onSortEnd={onSortEnd} useDragHandle={true} />
      {editVM.currentQuestion.canAddMoreOptions ? (
        <Button
          disabled={editVM.isReadOnly}
          className='form-button'
          fullWidth
          onClick={() => editVM.currentQuestion.addOption()}
        >
          <AddIcon />
        </Button>
      ) : null}
    </div>
  )
}

export default inject('surveysStore')(observer(OptionsList))
