import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { UserTasksStore } from '../../store/UserTasksStore'
import { AppBar, Toolbar, Button } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'

interface DrawerButtonControlsProps {
  localizationStore?: LocalizationStore
  userTasksStore?: UserTasksStore
}

const DrawerButtonControls: React.FC<DrawerButtonControlsProps> = ({
  localizationStore,
  userTasksStore,
}) => {
  const { lzStrings } = localizationStore
  const { editVM: VM } = userTasksStore

  const renderMarkCompleted = () => {
    if (VM.isCompleted) return null
    return (
      <Button
        variant='contained'
        disabled={!VM.saveEnabled}
        onClick={() => VM.markUserTaskAsCompleted()}
      >
        {VM.markCompletedSuccessful ? (
          <CheckIcon />
        ) : (
          lzStrings.pendingUserTasksWidgetDrawer.mark_as_complete
        )}
      </Button>
    )
  }

  const renderMarkAsIncomplete = () => {
    if (!VM.isCompleted) return null
    return (
      <Button
        variant='contained'
        disabled={!VM.markAsIncompleteDisabled}
        onClick={() => VM.markUserTaskAsIncomplete()}
      >
        {VM.markCompletedSuccessful ? (
          <CheckIcon />
        ) : (
          lzStrings.pendingUserTasksWidgetDrawer.mark_as_incomplete
        )}
      </Button>
    )
  }

  return (
    <div>
      <AppBar position='relative' elevation={0}>
        <Toolbar disableGutters={true} variant='dense'>
          {renderMarkCompleted()}
          {renderMarkAsIncomplete()}
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default inject('localizationStore', 'userTasksStore')(observer(DrawerButtonControls))
