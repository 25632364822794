import React from 'react'
import { inject, observer } from 'mobx-react'
import { PulseCampaignTypesManageVM } from '../view-models/PulseCampaignTypesManageVM'
import LocalizationStore from '../../localization/LocalizationStore'
import { AppBar, Button, CssBaseline, Toolbar, Typography } from '@material-ui/core'
import clsx from 'clsx'
import isIE11 from 'src/utils/isIE11'

interface Props {
  localizationStore?: LocalizationStore
  vm: PulseCampaignTypesManageVM
}

const TopToolbar: React.FC<Props> = ({ vm, localizationStore }) => {
  if (!vm) return null
  const lz = localizationStore.lzStrings.pulseCampaign

  const renderAddButton = () => {
    return (
      <Button
        variant='contained'
        onClick={() => vm.navigateToNewPulseCampaignType()}
        className='dashboard-action'
      >
        {lz.create_pulse_campaign_type}
      </Button>
    )
  }

  return (
    <div id='DashboardToolbar'>
      <CssBaseline />
      <AppBar
        className={clsx('dashboard-appbar', {
          'no-transition': isIE11,
        })}
        elevation={0}
      >
        <Toolbar className='dashboard-toolbar'>
          <Typography className='dashboard-title'>{lz.manage_pulse_campaign_types}</Typography>
          <div className='dashboard-toolbar-right'>{renderAddButton()}</div>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default inject('localizationStore', 'labelsStore')(observer(TopToolbar))
