import React from 'react'
import clsx from 'clsx'
import { inject, observer } from 'mobx-react'
import { ButtonBase, Tooltip, Typography } from '@material-ui/core'
import './WelcomeBannerEditorToggle.scss'
import { WelcomeBannerEditVM } from '../../../view-models/WelcomeBannerEditVM'
import LocalizationStore from '../../../../localization/LocalizationStore'

interface Props {
  vm: WelcomeBannerEditVM
  localizationStore?: LocalizationStore
}

const WelcomeBannerEditorToggle: React.FC<Props> = ({ vm, localizationStore }) => {
  const { welcomeBanner: lz } = localizationStore.lzStrings
  if (!vm) return

  const renderWYSIWYGEditor = () => {
    return (
      <Tooltip title={lz.text_editor_tooltip} placement='top'>
        <span>
          <ButtonBase
            onClick={() => vm.setEditorTabIndex(0)}
            className={clsx('tab-button', {
              active: vm.editorTabIndex === 0,
            })}
          >
            <Typography className='tab-button-label'>{lz.text}</Typography>
          </ButtonBase>
        </span>
      </Tooltip>
    )
  }

  const renderHTMLEditor = () => {
    return (
      <Tooltip title={lz.html_editor_tooltip} placement='top'>
        <span>
          <ButtonBase
            onClick={() => vm.setEditorTabIndex(1)}
            className={clsx('tab-button', {
              active: vm.editorTabIndex === 1,
            })}
          >
            <Typography className='tab-button-label'>{lz.html}</Typography>
          </ButtonBase>
        </span>
      </Tooltip>
    )
  }

  return (
    <div id='NotificationTemplateEditorToggle'>
      <div className='tab-btns'>
        {renderWYSIWYGEditor()}
        {renderHTMLEditor()}
      </div>
    </div>
  )
}

export default inject('notificationsStore', 'localizationStore')(observer(WelcomeBannerEditorToggle))
