import React from 'react'
import { OrganizationsSAStore } from '../../store/OrganizationsSAStore'
import LocalizationStore from '../../../localization/LocalizationStore'
import { inject, observer } from 'mobx-react'
import EmailIcon from '@material-ui/icons/Email'
import TextsmsIcon from '@material-ui/icons/Textsms'
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid'
import TasksIcon from '@material-ui/icons/Assignment'
import ChatIcon from '@material-ui/icons/ChatBubbleOutlineRounded'
import SurveysIcon from '@material-ui/icons/LiveHelpOutlined'
import CalendarIcon from '@material-ui/icons/CalendarTodayRounded'
import DataUsageIcon from '@material-ui/icons/DataUsage'
import { Paper, Grid, InputLabel, TextField, ButtonGroup, Button } from '@material-ui/core'
import './OrganizationEditDrawer.scss'
import OrganizationInfo from './OrganizationInfo'
import OrganizationModules from './OrganizationModules'
import OrganizationFeatures from './OrganizationFeatures'
import OrganizationLanguages from './OrganizationLanguages'
import OrganizationTemplates from './OrganizationTemplates'
import OrganizationToken from './OrganizationToken'

interface Props {
  organizationsSAStore?: OrganizationsSAStore
  localizationStore?: LocalizationStore
}

const OrganizationDetails: React.FC<Props> = ({ organizationsSAStore, localizationStore }) => {
  const { editVM: vm } = organizationsSAStore
  const { lzStrings } = localizationStore
  if (!vm) return null

  return (
    <div id='OrgDetailsTab'>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <OrganizationInfo />
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <OrganizationLanguages />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <OrganizationToken />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <OrganizationTemplates />
        </Grid>
      </Grid>
    </div>
  )
}

export default inject('organizationsSAStore', 'localizationStore')(observer(OrganizationDetails))
