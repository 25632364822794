import React, { CSSProperties } from 'react'
import { observer } from 'mobx-react'

interface Props {
  providerUuid: string
  height: number
  width: number
  onLoad?: Function
  onError?: Function
  style?: CSSProperties
}

const YouTubeImage: React.FC<Props> = ({ providerUuid, height, width, onLoad, onError, style }) => {
  const handleLoadError = () => {
    if (onError) onError()
  }

  const handleLoad = () => {
    if (onLoad) onLoad()
  }

  return (
    <img
      style={{ height: height, width: width, backgroundSize: 'cover', ...style }}
      src={`https://img.youtube.com/vi/${providerUuid}/0.jpg`}
      onLoad={() => handleLoad()}
      onError={() => handleLoadError()}
      alt={'Preview'}
    />
  )
}

export default observer(YouTubeImage)
