import React from 'react'
import { inject, observer } from 'mobx-react'
import { PulseQuestionsStore } from '../../store/PulseQuestionsStore'
import { Button, Checkbox, FormControlLabel, IconButton, TextField, Tooltip, Typography } from '@material-ui/core'
import { PulseSetVM } from '../../../pulse-sets/view-models/PulseSetVM'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import CheckRounded from '@material-ui/icons/CheckCircleRounded'
import './PulseQuestionSets.scss'

interface Props {
  pulseQuestionsStore?: PulseQuestionsStore
}

const PulseQuestionSets: React.FC<Props> = ({ pulseQuestionsStore }) => {
  const vm = pulseQuestionsStore.editVM
  if (!vm) return null

  const renderSets = () => {
    const sets = []
    vm.pulseSets.forEach((s: PulseSetVM) => {
      sets.push(
        <FormControlLabel
          key={s.objectId}
          htmlFor='languages'
          onClick={() => s.toggleChecked()}
          control={<Checkbox color='primary' checked={s.isChecked} />}
          label={s.name}
        />
      )
    })

    return (
      <div id='pulseSets'>
        <Typography className='sets-header'>Sets</Typography>
        {sets}
        {renderAddSetButton()}
        {renderAddSetField()}
      </div>
    )
  }

  const renderAddSetButton = () => {
    if (vm.pulseSetEditVM) return null
    return (
      <Button className={'pulse-set-add-button'} variant='contained' onClick={() => vm.addSet()}>
        Add
      </Button>
    )
  }

  const renderAddSetField = () => {
    if (!vm.pulseSetEditVM) return null
    return (
      <div className={'pulse-set-edit'}>
        <TextField
          autoFocus
          id='outlined-name'
          className={'pulse-set-name'}
          value={vm.pulseSetEditVM.name}
          onChange={(e) => {
            vm.pulseSetEditVM.setName(e.target.value)
          }}
          margin='none'
          fullWidth
          variant='outlined'
        />
        <Tooltip title={`Cancel`} placement='bottom-start' enterDelay={500}>
          <IconButton
            size='small'
            className={'pulse-set-cancel-button'}
            color='secondary'
            onClick={() => vm.closeAddSet()}
          >
            <HighlightOffRoundedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={`Save`} placement='bottom-start' enterDelay={500}>
          <IconButton
            size='small'
            className={'pulse-set-save-button'}
            color='secondary'
            onClick={() => vm.pulseSetEditVM.save()}
          >
            <CheckRounded />
          </IconButton>
        </Tooltip>
      </div>
    )
  }


  return (
    <div id='PulseQuestionSets'>
      {renderSets()}
    </div>
  )
}

export default inject('pulseQuestionsStore')(observer(PulseQuestionSets))
