import React from 'react'
import { Grid, TextField, Select, MenuItem, Switch } from '@material-ui/core'
import { observer, inject } from 'mobx-react'
import { WorksheetsStore } from '../../store/WorksheetsStore'
import { Survey } from '../../../surveys/aggregate/Survey'
import Autocomplete from '@material-ui/lab/Autocomplete'
import './OptionsTabPanel.scss'
import { LabelsStore } from '../../../labels/store/LabelsStore'
import InfoTooltip from '../../../shared/InfoTooltip'

interface Props {
  worksheetsStore?: WorksheetsStore
  labelsStore?: LabelsStore
}

const OptionsTabPanel: React.FC<Props> = ({ worksheetsStore, labelsStore }) => {
  const { editVM } = worksheetsStore
  if (!editVM) return null

  const renderForSureys = () => {
    if (editVM.canAccessScopedAnalysis) return null

    const tooltip = () => {
      return (
        <div>
          When toggle is active, the visualization is an available option to select on for a{' '}
          {labelsStore.getLabelByLanguageAndFor('pulse').toLowerCase()} on the options tab.
          Additionally this visualization{' '}
          <span className='bold-underlined-infotext'>
            will not be visible in the Analysis section
          </span>{' '}
          as it is meant to be tied to a specific{' '}
          {labelsStore.getLabelByLanguageAndFor('pulse').toLowerCase()}.
        </div>
      )
    }

    return (
      <Grid item xs={12}>
        <Grid item xs={12} md={6} lg={6}>
          <div className='toggle-text'>
            {`For ${labelsStore.getLabelByLanguageAndFor('pulse')}`}
            <InfoTooltip message={tooltip()} />
          </div>
        </Grid>
        <Switch
          color='primary'
          checked={editVM.forSurveys}
          onChange={() => editVM.toggleForSurveys()}
        />
      </Grid>
    )
  }

  const renderForPulseCampaigns = () => {
    if (editVM.canAccessScopedAnalysis) return null

    const tooltip = () => {
      return (
        <div>
          When toggled is active, the visualization will be available to select on the analysis
          section of the pulse campaign. Additionally this visualization{' '}
          <span className='bold-underlined-infotext'>
            will not be visible in the Analysis section
          </span>{' '}
          as it is meant to be tied to a specific pulse campaign.
        </div>
      )
    }

    return (
      <Grid item xs={12}>
        <Grid item xs={12} md={6} lg={6}>
          <div className='toggle-text'>
            For Pulse Campaigns
            <InfoTooltip message={tooltip()} />
          </div>
        </Grid>
        <Switch
          color='primary'
          checked={editVM.forPulseCampaigns}
          onChange={() => editVM.toggleForPulseCampaigns()}
        />
      </Grid>
    )
  }

  const renderDefaultPulseCampaignTemplate = () => {
    if (editVM.canAccessScopedAnalysis) return null
    if (!editVM.forPulseCampaigns) return null
    return (
      <Grid item xs={12}>
        <Grid item xs={12} md={6} lg={6}>
          <div>Default Pulse Campaign Template</div>
        </Grid>
        <Switch
          color='primary'
          checked={editVM.defaultPulseCampaignTemplate}
          onChange={() => editVM.toggleDefaultPulseCampaignsTemplate()}
        />
      </Grid>
    )
  }

  const renderShowTabs = () => {
    return (
      <Grid item xs={12}>
        <Grid item xs={12} md={6} lg={6}>
          <div className='toggle-text'>
            Show Tabs
            <InfoTooltip message='When this toggle is active, if the Tableau workbook was published from Tableau with tabs, then the tabs will show on the Tableau workbook for users to navigate through.' />
          </div>
        </Grid>
        <Switch
          color='primary'
          checked={editVM.showTabs}
          onChange={() => editVM.toggleShowTabs()}
        />
      </Grid>
    )
  }

  const renderForLoggedInUser = () => {
    return (
      <Grid item xs={12}>
        <Grid item xs={12} md={6} lg={6}>
          <div className='toggle-text'>
            Filter For Logged In User
            <InfoTooltip message='If the Tableau workbook is built to use viewingIds to manage the access of data on the visualization, then when this toggle is active the data will be restricted to the users level (based on manager/employee relation and audience setup).' />
          </div>
        </Grid>
        <Switch
          disabled={editVM.canAccessScopedAnalysis}
          color='primary'
          checked={editVM.forLoggedInUser}
          onChange={() => editVM.toggleForLoggedInUser()}
        />
      </Grid>
    )
  }

  const renderSurveySelect = () => {
    if (!editVM.forSurveys) return null
    return (
      <Grid item xs={12}>
        <Grid item xs={12} md={6} lg={6}>
          <div>Survey</div>
        </Grid>
        <Autocomplete
          options={editVM.availableSurveys}
          getOptionLabel={(e) => e.name}
          value={editVM.selectedSurvey}
          onChange={(e, val: Survey) => editVM.setSurvey(val && val.objectId ? val.objectId : '')}
          renderInput={(params) => (
            <TextField {...params} placeholder='Select Survey' variant='outlined' />
          )}
        />
      </Grid>
    )
  }

  const rendeSurveyTypeSelect = () => {
    if (!editVM.forSurveys) return null
    return (
      <Grid item xs={12}>
        <Grid item xs={12} md={6} lg={6}>
          <div>Survey Type</div>
        </Grid>
        <Select
          value={editVM.surveyTypeId}
          onChange={(e) => editVM.setSurveyType(e.target.value)}
          variant='outlined'
        >
          {editVM.availableSurveyTypes.map((e, idx) => (
            <MenuItem value={e.objectId} key={'st' + idx}>
              {e.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    )
  }

  const renderWorksheetOwner = () => {
    return (
      <>
        <Grid item xs={12} md={12} lg={12}>
          <div>Worksheet Owner</div>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Autocomplete
            disabled={editVM.canAccessScopedAnalysis}
            options={editVM.usersOrdered}
            getOptionLabel={(e) => e.name}
            value={editVM.worksheetOwner}
            onChange={(e, val) => editVM.setWorksheetOwner(val ? val.id : '')}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!editVM.isOwnerValid}
                placeholder='Select Worksheet Owner'
                variant='outlined'
                className={'publisher-select'}
                style={{ width: 200 }}
              />
            )}
          />
        </Grid>
      </>
    )
  }

  return (
    <Grid container id='OptionsTabPanel'>
      {renderShowTabs()}
      {renderForLoggedInUser()}
      {renderForSureys()}
      {rendeSurveyTypeSelect()}
      {renderSurveySelect()}
      {renderForPulseCampaigns()}
      {renderDefaultPulseCampaignTemplate()}
      {renderWorksheetOwner()}
    </Grid>
  )
}

export default inject('worksheetsStore', 'labelsStore')(observer(OptionsTabPanel))
