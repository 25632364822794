import React, { FC, useRef } from 'react'
import { inject } from 'mobx-react'
import LocalizationStore from '../localization/LocalizationStore'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
} from '@material-ui/core'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import './TPMuiConfirmDeleteDialog.scss'
import { AudienceMember } from '../training-plans/aggregates/AudienceMember'
import VirtualList from 'react-tiny-virtual-list'
import Avatar from '../shared/Avatar'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'

export interface ConfirmDeleteDialogProps {
  localizationStore?: LocalizationStore
  itemType: string
  itemName: string
  itemParticipants: Array<AudienceMember>
  onClose: (e) => void
  onConfirm: (e) => void
  open: boolean
}

const MuiConfirmDeleteDialog: FC<ConfirmDeleteDialogProps> = ({
  localizationStore,
  itemType,
  itemName,
  itemParticipants,
  onClose,
  onConfirm,
  open,
}) => {
  const strings = localizationStore.lzStrings.moreMenu
  const virtualListRef = useRef<any>(null)

  const renderSelectedItems = () => {
    return (
      <div id='Participant-virtualList'>
        <Grid container className='list-container'>
          <Typography className='list-title'>
            <PeopleAltIcon className={'people-icon'}/>
            {itemParticipants.length}
            {strings.assignee}
          </Typography>
          <VirtualList
            style={{ backgroundColor: '#efeff4', padding: '5px' }}
            height={150}
            itemCount={itemParticipants.length}
            itemSize={45}
            renderItem={({ style, index }) => (
              <div key={index} style={style}>
                <Grid container style={{ display: 'flex' }}>
                  <Avatar
                    user={itemParticipants[index]}
                    size={20}
                    style={{ marginLeft: '1rem', fontSize: '0.9rem', marginRight: '0.5rem' }}
                    showOnlyFirstInitial
                  />
                  {itemParticipants[index].name}
                </Grid>
              </div>
            )}
          />
        </Grid>
      </div>
    )
  }

  return (
    <div>
      <Dialog onClose={onClose} open={open} id='MuiConfirmDeleteDialog'>
        <DialogTitle className='dialog-title'>{strings.confirm_delete}</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item md={1} sm={1}>
              <WarningRoundedIcon className='warning-icon' />
            </Grid>
            <Grid item md={11} sm={11} className='confirm-message'>
              <p>
                {strings.delete}
                {/* {strings.delete} */}
                {itemType}: <span className='item-name-text'>"{itemName}"</span>?
              </p>
              <p className='cannot-undo-text'>{strings.all_content}</p>
              {renderSelectedItems()}
              <p className='confirm-message'>{strings.do_you_wish_to_proceed}</p>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' className='btn-cancel' onClick={onClose}>
            {strings.no_go_back}
          </Button>
          <Button variant='contained' className='btn-remove' onClick={onConfirm}>
            {strings.yes_delete}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default inject('localizationStore')(MuiConfirmDeleteDialog)
