import React from 'react'
import { Button } from '@material-ui/core';
import clsx from 'clsx'
import './ArchivedLabel.scss'

const ArchivedLabel = ({ size = 'default' }) => {
  return (
    <div id='SelectedArchivedLabel'>
      <Button className={clsx('default-label-btn', size === 'small' ? 'small-btn-sizing' : 'default-btn-sizing')} disabled>
        <span className={clsx('default-label-text', size === 'small' ? 'small-text-sizing' : 'default-text-sizing')}>ARCHIVED</span>
      </Button>
    </div>
  )
}

export default ArchivedLabel
