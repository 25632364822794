import { RootStore } from '../../stores/RootStore'
import Location from '../aggregate/Location'
import Parse from 'parse'

export class LocationsService {
  private rootStore: RootStore

  async saveLocation(orgId, location) {
    const savedLocation = await Parse.Cloud.run('saveLocation', { orgId, location })
    return savedLocation
  }

  async deleteLocation(orgId, locationId) {
    await Parse.Cloud.run('deleteLocation', { orgId, locationId })
  }
}
