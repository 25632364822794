import { observable, action, computed, runInAction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import EventType from './aggregate/EventType'
import EventTypeEditForm from './forms/EventTypeEditForm'
import { PagedDataVM } from '../../shared/view-models/PagedDataVM'
import { IEventTypeDTO } from './dtos/IEventTypeDTO'
import { DataStore } from '../../shared/data/DataStore'

export class EventTypesStore extends DataStore<EventType, IEventTypeDTO> {
  pagedData: PagedDataVM

  constructor(rootStore: RootStore) {
    super(rootStore, EventType, 'eventTypes', ['objectId', 'organizationId', 'name', 'description', 'resourceId'])
    this.pagedData = new PagedDataVM(
      this.rootStore,
      this.eventTypes,
      ['name', 'publishedByUserName'],
      '',
      'CalendarManagementWidget'
    )
  }

  @observable public selectedEventTypeId: string
  @observable public editForm: EventTypeEditForm = null
  @observable public showDrawer: boolean = false
  @observable public showDeleteDialog: boolean = false
  @observable public loaded: boolean = false

  @computed
  public get eventTypes() {
    return this.records
  }

  @action
  public onListRecordsLoaded() {
    this.loaded = true
  }

  @computed
  public get currentOrgEventTypes() {
    return this.eventTypes
  }

  public setSelectedEventTypeId(id) {
    this.selectedEventTypeId = id
    this.editForm = new EventTypeEditForm(this.rootStore, this.getEventType(id))
  }

  @action
  public setAddNewEventType() {
    this.selectedEventTypeId = null
    this.editForm = new EventTypeEditForm(this.rootStore)
  }

  @action
  public toggleDeleteDialog = () => {
    this.showDeleteDialog = !this.showDeleteDialog
  }

  public getEventType(objectId): EventType {
    return this.eventTypes.find(e => e.objectId === objectId)
  }
  public getEventTypeByName(name): EventType {
    return this.eventTypes.find(e => e.name ===  name)
  }

  private getEventTypeIndex(objectId): number {
    return this.eventTypes.findIndex(e => e.objectId === objectId)
  }

  public deleteEventType(objectId) {
    this.eventTypes.splice(this.getEventTypeIndex(objectId), 1)
  }

  public clearForms() {
    this.selectedEventTypeId = ''
    this.editForm = null
  }
}
