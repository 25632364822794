import { observable, action, computed} from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { WeightProfile } from '../aggregate/WeightProfile'
import { EditScreenVM } from '../view-models/EditScreenVM'
import WeightProfilesImportVM from '../view-models/WeightProfilesImportVM'
import { IWeightProfileDTO } from '../dtos/IWeightProfileDTO'
import { DataStore } from '../../shared/data/DataStore'

export class WeightProfilesStore extends DataStore<WeightProfile, IWeightProfileDTO> {

  constructor(rootStore: RootStore) {
    super(rootStore, WeightProfile, 'weightProfiles', [
      'organizationId',
      'roleId',
      'categories',
    ])
  }

  @observable public currentAutomationId: string = ''
  @observable public editVM: EditScreenVM = null
  @observable public showImportModal: boolean = false
  @observable public importVM: WeightProfilesImportVM = null
  @observable public loaded: boolean = false

  @computed
  public get weightProfiles(): WeightProfile[] {
    return this.records
  }

  @action
  public onListRecordsLoaded() {
    this.editVM = new EditScreenVM(this.rootStore)
    this.loaded = true
  }

  @computed
  public get currentOrgWeightProfiles() {
    return this.weightProfiles
  }

  public getWeightProfile(objectId): WeightProfile {
    return this.weightProfiles.find(e => e.objectId === objectId)
  }

  public getWeightProfileByRoleId(roleId) {
    return this.currentOrgWeightProfiles.find(e => e.roleId === roleId)
  }

  private getWeightProfileIndex(objectId): number {
    return this.weightProfiles.findIndex(e => e.objectId === objectId)
  }

  public deleteWeightProfile(objectId) {
    this.weightProfiles.splice(this.getWeightProfileIndex(objectId), 1)
  }

  @action
  public toggleImportModal() {
    this.showImportModal = !this.showImportModal
    if (this.showImportModal) this.importVM = new WeightProfilesImportVM(this.rootStore)
    else this.importVM = null
  }
}
