import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { Avatar, CardContent, CircularProgress, Typography } from '@material-ui/core'
import BuildIcon from '@material-ui/icons/Build'
import rootStore from '../../stores/RootStore'
import TableauReport from '../../worksheets/views/TableauReport'
import { TableauScriptService } from '../../tableau/service/TableauScriptService'
import { ContentItemVM } from '../view-models/ContentItemVM'
import './Content.scss'

interface Props {
  vm: ContentItemVM
}

const VisualizationReport: React.FC<Props> = ({ vm }) => {
  if (!vm) return

  useEffect(() => {
    TableauScriptService.loadTableauScript(() => vm.setScriptLoaded(true))
  })

  const renderSpinner = () => {
    if (vm.isLoaded) return
    if (!vm.hasPermission) return
    return (
      <CircularProgress color='secondary' style={{ position: 'fixed', top: '50%', left: '50%' }} />
    )
  }

  const renderNoPermission = () => {
    if (!vm.isLoaded) return
    if (vm.hasPermission) return
    return (
      <div className={'viz-not-authorized-container'}>
          <Avatar className={'viz-not-authorized-avatar'}>
            <BuildIcon className={'viz-not-authorized-icon'} />
          </Avatar>
          <Typography className='viz-not-authorized-header'>
            {rootStore.localizationStore.lzStrings.visualizations.permission_denied}
          </Typography>
          <Typography className='viz-not-authorized-text'>
            {rootStore.localizationStore.lzStrings.visualizations.permission_denied_details}
          </Typography>
      </div>
    )
  }

  const renderReport = () => {
    if (!vm.getTableauAuthToken) return
    if (!vm.scriptLoaded) return
    if (vm.isLoaded && !vm.hasPermission) return
    return (
      <TableauReport
        key={
          vm.worksheet.tableauViewUrl +
          vm.getTableauAuthToken +
          vm.worksheet.tableauQuery
        }
        token={vm.getTableauAuthToken}
        query={vm.worksheet.tableauQuery}
        onVizLoaded={(div, viz) => vm.setTableauVizContainer(div, viz)}
        options={{
          width: '100%',
          height: '90vh',
          device: 'desktop',
          hideTabs: !vm.worksheet.showTabs,
        }}
        url={vm.worksheet.tableauViewUrl}
      />
    )
  }

  const renderChart = () => {
    if (!vm.worksheet) return
    return (
      <div id={'div' + vm.worksheet.objectId} className='chart-container'>
        {renderReport()}
      </div>
    )
  }

  return (
    <CardContent id='VisualizationsContent'>
      {renderNoPermission()}
      {renderSpinner()}
      {renderChart()}
    </CardContent>
  )
}

export default observer(VisualizationReport)
