import React from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import { SurveysStore } from '../../../store/SurveysStore'
import { inject, observer } from 'mobx-react'
import { OptionVM } from '../../../view-models/OptionVM'
import CallSplit from '@material-ui/icons/CallSplit'
import { QuestionVM } from 'src/app/surveys/view-models/QuestionVM'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { Tooltip } from '@material-ui/core'

interface Props {
  idx: number
  surveysStore?: SurveysStore
  option: OptionVM
}

const OptionNextQuestionMenu: React.FC<Props> = ({ option, surveysStore, idx }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const { editVM } = surveysStore.viewModels
  if (!editVM) return null

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChooseMenuItem = (id) => {
    option.setNextQuestion(id)
    handleClose()
  }

  const handleChooseMenuGoToEndItem = () => {
    option.setGoToEnd()
    handleClose()
  }

  const renderNextQuestionButton = () => {
    if (option.nextQuestionId !== null) return
    if (option.goToEnd) return
    if (option.type === 'order') return <CallSplit className='order-gray' />
    return <CallSplit />
  }

  const renderEndInfo = () => {
    if (!option.goToEnd) return
    return 'END'
  }

  const renderQuestionIndexInfo = () => {
    if (option.goToEnd) return
    if (option.nextQuestionId === null) return
    if (option.nextQuestion === null) return
    if (option.nextQuestion.type === 'infoText') return `IT${option.nextQuestion.infoTextIndex + 1}`
    return 'Q' + option.nextQuestion.rank
  }

  const renderNextQuestionMenuItem = (question: QuestionVM, index) => {
    return (
      <MenuItem onClick={() => handleChooseMenuItem(question.id)} key={'qopt' + index}>
        {question.type === 'infoText' ? (
          <ListItemText primary={`INFO TEXT ${question.infoTextIndex + 1}`} />
        ) : (
          <ListItemText primary={'Q' + question.rank + ': ' + question.title} />
        )}
      </MenuItem>
    )
  }

  return (
    <div>
      <Button
        disabled={editVM.isReadOnly || option.type === 'order'}
        className='more-button'
        onClick={handleOpen}
      >
        {renderNextQuestionButton()}
        {renderEndInfo()}
        {renderQuestionIndexInfo()}
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => handleChooseMenuItem(null)}>
          <ListItemText primary={'Default: Next Question'} />
        </MenuItem>
        {option.nextQuestions.map((q, idx) => renderNextQuestionMenuItem(q, idx))}
        <MenuItem onClick={() => handleChooseMenuGoToEndItem()} key={'qopt4' + idx}>
          <ListItemText primary={'Go To End'} />
        </MenuItem>
      </Menu>
    </div>
  )
}

export default inject('surveysStore')(observer(OptionNextQuestionMenu))
