import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { CardContent, List, ListItem } from '@material-ui/core'
import { WorksheetsStore } from '../../../store/WorksheetsStore'

interface Props {
  localizationStore?: LocalizationStore
  worksheetsStore?: WorksheetsStore
}

const WorksheetsList: React.FC<Props> = ({ worksheetsStore }) => {
  const { chartWidgetDrawerVM } = worksheetsStore

  const renderItems = () => {
    return chartWidgetDrawerVM.visibleWorksheets.map((e, index) => (
      <ListItem
        key={index}
        button
        selected={chartWidgetDrawerVM.isSelectedWorksheet(e.objectId)}
        onClick={() => chartWidgetDrawerVM.setSelectedWorksheet(e.objectId)}
      >
        {e.name}
      </ListItem>
    ))
  }

  return <List>{renderItems()}</List>
}

export default inject('worksheetsStore')(observer(WorksheetsList))
