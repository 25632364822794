import { action, observable } from 'mobx'
import { IAnnouncementParticipantDTO } from '../dtos/IAnnouncementParticipantDTO'
import { serializable, serialize, deserialize, date, list, object, primitive } from 'serializr'
import { IAggregate } from 'src/app/shared/data/IAggregate'

export class AnnouncementParticipant implements IAnnouncementParticipantDTO, IAggregate {
  isDeleted: boolean
  isOnServer: boolean = false

  @action
  public markIsOnServer() {
    this.isOnServer = true
  }

  @action
  public markIsNotOnServer() {
    this.isOnServer = true
  }
  @serializable @observable objectId: string
  @serializable @observable announcementId: string
  @serializable @observable organizationId: string
  @serializable @observable userId: string
  @serializable @observable firstName: string
  @serializable @observable lastName: string
  @serializable @observable invitationCreated?: string
  @serializable @observable createdEmailDeliverDate?: string
  @serializable @observable createdEmailProcessedDate?: string
  @serializable @observable createdEmailCreatedDate?: string
  @serializable @observable createdEmailOpenDate?: string
  @serializable @observable createdEmailDropDate?: string
  @serializable @observable createdEmailClickDate?: string
  @serializable @observable createdEmailBounceDate?: string
  @serializable @observable createdEmailErrorDate?: string
  @serializable @observable createdTextDate?: string
  @serializable @observable createdPushDate?: string
  @serializable @observable invitationUpdated?: string
  @serializable @observable updatedEmailDeliverDate?: string
  @serializable @observable updatedEmailProcessedDate?: string
  @serializable @observable updatedEmailCreatedDate?: string
  @serializable @observable updatedEmailOpenDate?: string
  @serializable @observable updatedEmailDropDate?: string
  @serializable @observable updatedEmailClickDate?: string
  @serializable @observable updatedEmailBounceDate?: string
  @serializable @observable updatedEmailErrorDate?: string
  @serializable @observable updatedTextDate?: string
  @serializable @observable updatedPushDate?: string
  @serializable @observable viewedDate?: string
  @serializable @observable likedDate?: string
  @serializable @observable unlikedDate?: string
  @serializable @observable removedDate?: string

  public serialize() {
    const obj = serialize(this)
    return obj
  }

  public clone(): AnnouncementParticipant {
    return deserialize(AnnouncementParticipant, this.serialize())
  }
}
