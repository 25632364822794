import React from 'react'
import { Card } from '@material-ui/core'
import { observer, inject } from 'mobx-react'
import './ManageGoalsWidget.scss'
import Header from './Header'
import Content from './Content'
import { GoalsStore } from '../../store/GoalsStore'

interface Props {
  goalsStore?: GoalsStore
}

const ManageGoalsWidget: React.FC<Props> = ({ goalsStore }) => {
  const { manageGoalsWidgetVM: vm } = goalsStore
  if (!vm) return null
  if (!goalsStore.canCreateGoals) return null

  return (
    <Card id='ManageGoalsWidget' elevation={0}>
      <Header />
      <Content />
    </Card>
  )
}

export default inject('goalsStore')(observer(ManageGoalsWidget))
