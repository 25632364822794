import { computed, action, observable } from 'mobx'
import { RootStore } from 'src/app/stores/RootStore'
import { ICategoryImportRowDTO } from '../dtos/ICategoryImportRowDTO'

export class CategoryImportRowVM {
  private rootStore: RootStore
  private row: ICategoryImportRowDTO

  constructor(rootStore: RootStore, row: ICategoryImportRowDTO) {
    this.row = row
    this.rootStore = rootStore
  }

  @computed
  public get height(): number {
    let height = 135
    height = height + 26 * this.rowsCount
    return height
  }

  @computed
  public get rowsCount(): number {
    let rows = 1
    if (this.hasError) return 0
    return rows
  }

  @computed
  public get hasError() {
    return !this.row.result.success
  }

  @computed
  public get rowIndex(): string {
    return this.row.rowIndex.toString()
  }

  @computed
  public get isNewContact(): boolean {
    return this.row.result.isNew
  }

  @computed
  public get key() {
    return 'cont' + this.row.rowIndex
  }

  @computed
  public get contactErrorMessage(): string {
    if (!this.row.result) return ''
    if (!this.row.result.errorMessage) return ''
    return this.row.result.errorMessage
  }

  @computed
  public get name(): string {
    if (!this.row.result) return ''
    if (!this.row.result.category) return this.row['Name']
    return this.row.result.category.name
  }

  @computed
  public get description(): string {
    if (!this.row.result) return ''
    if (!this.row.result.category) return this.row['Description']
    return this.row.result.category.description
  }

  @computed
  public get path(): string {
    if (!this.row.result) return ''
    if (!this.row.result.category.parentCategoryId) return this.row['Path']
    return ''
  }

  // @computed
  // public get availableForSurveys(): string {
  //   if (!this.row.result) return ''
  //   if (!this.row.result.category.availableForSurveys) return this.row['Available For Surveys']
  //   return this.row.result.category.availableForSurveys ? 'Yes' : 'No'
  // }

  // @computed
  // public get availableForTasks(): string {
  //   if (!this.row.result) return ''
  //   if (!this.row.result.category.availableForTasks) return this.row['Available For Tasks']
  //   return this.row.result.category.availableForTasks ? 'Yes' : 'No'
  // }

  // @computed
  // public get availableForWork(): string {
  //   if (!this.row.result) return ''
  //   if (!this.row.result.category.availableForWork) return this.row['Available For Work']
  //   return this.row.result.category.availableForWork ? 'Yes' : 'No'
  // }

  // @computed
  // public get availableForSkills(): string {
  //   if (!this.row.result) return ''
  //   if (!this.row.result.category.availableForSkills) return this.row['Available For Skills']
  //   return this.row.result.category.availableForSkills ? 'Yes' : 'No'
  // }

}
