import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { EmailTemplatePreviewVM } from '../../view-models/EmailTemplatePreviewVM'
import './EmailTemplatePreview.scss'

interface Props {
  vm: EmailTemplatePreviewVM
  localizationStore?: LocalizationStore
}

const EmailTemplatePreview: React.FC<Props> = ({ vm, localizationStore }) => {
  const { emailTemplateManagement: lz } = localizationStore.lzStrings
  if (!vm) return null


  // mitigates validateDOMNesting html Warning
  const rawHtml = vm.tabIndex === 1 ? vm.replaceKeywordsForPreview : vm.html

  const previewHtml = vm.replaceMarkupForPreview(rawHtml)
  
  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: previewHtml }}></div>
    </>
  )
}

export default inject('localizationStore')(observer(EmailTemplatePreview))
