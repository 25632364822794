import { action, computed, observable } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { IOrganizationUsersListPreferencesFindRequest } from '../../interfaces/IOrganizationUsersListPreferencesFindRequest'
import { OrganizationUsersListPreferencesService } from '../../service/OrganizationUsersListPreferencesService'
import { OrganizationUsersListTableVM } from './OrganizationUsersListTableVM'

export class OrganizationUsersListManageVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.getColumns()
  }

  @observable public tableVM: OrganizationUsersListTableVM = null
  @observable public isLoading: boolean = false
  @observable public isSnackbarOpen: boolean = false
  @observable public snackbarMessage: string = ''
  @observable public columnsLoaded: boolean = false
  @observable public viewColumns: Array<string> = [
    'name',
    'email',
    'username',
    'roles',
    'groups',
    'welcomeEmailStatus',
  ]
  @observable public sortField: string = 'fk_User.lastName'
  @observable public sortOrder: 'asc' | 'desc' = 'asc'

  @computed
  public get shouldRender(): boolean {
    if (!this.columnsLoaded) return false
    if (!this.tableVM) return false
    return true
  }

  @computed
  public get canManage() {
    if (this.rootStore.appStore.isSystemAdmin) return true
    if (!this.rootStore.userStore.currentOrganization) return false
    return false
  }

  @action
  public openSnackbar(msg) {
    this.snackbarMessage = msg
    this.isSnackbarOpen = true
  }

  @action
  public closeSnackbar() {
    this.isSnackbarOpen = false
  }

  @action
  public loadTableVM() {
    this.tableVM = new OrganizationUsersListTableVM(
      this.rootStore,
      this,
      this.viewColumns,
      this.sortField,
      this.sortOrder
    )
    this.tableVM.sizeColumnsToFit = false
  }

  @action
  public async getColumns() {
    const orgId = this.rootStore.appStore.currentOrgId
    const userId = this.rootStore.appStore.currentUserId
    try {
      const req: IOrganizationUsersListPreferencesFindRequest = {
        organizationId: orgId,
        userId: userId,
      }
      const orgSvc = new OrganizationUsersListPreferencesService()
      const result = await orgSvc.getOrganizationUsersListPreferences(req)
      if (result && result.fields) {
        this.viewColumns = result.fields
        if (result.sortField) {
          this.sortField = result.sortField
          this.sortOrder = result.sortOrder ? result.sortOrder : 'asc'
        }
      }
      this.columnsLoaded = true
      this.loadTableVM()
    } catch (error) {
      console.log(error)
    }
  }
}
