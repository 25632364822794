import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../localization/LocalizationStore'
import { QuestionsStore } from '../store/QuestionsStore'
import { InputLabel } from '@material-ui/core'
import DrawerOptionsList from './DrawerOptionsList'

interface Props {
  localizationStore?: LocalizationStore
  questionsStore?: QuestionsStore
}

const DrawerOptionsSection: React.FC<Props> = ({
  localizationStore,
  questionsStore,
}) => {
  const { lzStrings } = localizationStore
  const { editVM } = questionsStore
  if (!editVM.isOptionsQuestion) return null

  return (
    <section>
      <InputLabel htmlFor='optionsType'>
        {lzStrings.questionsCatalogWidgetDrawer.options}
      </InputLabel>
      <DrawerOptionsList />
    </section>
  )
}

export default inject('localizationStore', 'questionsStore')(observer(DrawerOptionsSection))
