import React from 'react'
import clsx from 'clsx'
import { inject, observer } from 'mobx-react'
import { Button, CssBaseline, Grid, Toolbar } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import { GrandCategoryTabVM } from '../../view-models/tree/GrandCategoryTabVM'
import { PulseCampaignSummaryVM } from '../../view-models/PulseCampaignSummaryVM'
import { GrandParentCategoryRowVM } from '../../view-models/tree/GrandParentCategoryRowVM'
import { CategoryQuestionsCountLabelType } from '../../type/QuestionCategoryCountType'
import isIE11 from '../../../../utils/isIE11'
import './TopToolbar.scss'

interface Props {
  vm: PulseCampaignSummaryVM
  countLabelType?: CategoryQuestionsCountLabelType
}

const TopToolbar: React.FC<Props> = ({ vm, countLabelType = 'campaign' }) => {
  if (!vm) return null

  const renderGrandCategoryTabs = () => {
    const tabs = []
    if (vm.grandCategoryTabs) 
      vm.grandCategoryTabs.forEach((tab: GrandCategoryTabVM) => {
        tabs.push(renderCategoryButton(tab))
      })
    return tabs
  }

  const categoryCount = (row: GrandParentCategoryRowVM): number => {
    if (countLabelType == 'survey') return row.currentSurveyParentQuestionCount
    else if (countLabelType == 'campaign') return row.parentQuestionSelectedCount
    else return 0
  }

  const buildTabTitle = (tab: GrandCategoryTabVM) => {
    if (countLabelType == 'none') return tab.name
    const gpFound = vm.categoriesVM.getGrandParentCategory(tab.objectId)
    const count = gpFound ? categoryCount(gpFound) : 0
    return `${tab.name}${count > 0 ? ` (${count})` : ''}`
  }

  const renderCategoryButton = (tab: GrandCategoryTabVM) => {
    const tabTitle = buildTabTitle(tab)
    if (tab.isSelected) {
      return (
        <Grid item key={`cat-btn-${tab.index}-${tab.objectId}-selected`}>
          <Button
            className={`tab-btn-selected border-cat-${tab.index}`}
            key={'wd' + tab.index}
            value={tab.index}
            onClick={() => vm.toggleTabIndex(tab.index)}
            >
            {tabTitle}
            <CheckIcon className='check-icon' />
          </Button>
        </Grid>
      )
    } else {
      return (
        <Grid item key={`cat-btn-${tab.index}-${tab.objectId}-unselected`}>
          <Button
            className={`tab-btn-unselected border-cat-${tab.index}`}
            key={'wd' + tab.index}
            value={tab.index}
            onClick={() => vm.toggleTabIndex(tab.index)}
          >
            {tabTitle}
          </Button>
        </Grid>
      )
    }
  }

  return (
    <div id='SummaryTopToolbar'>
      <CssBaseline />
      <div
        className={clsx('dashboard-appbar', {
          'no-transition': isIE11,
        })}
      >
        <Toolbar className='dashboard-toolbar'>
          <div id='SummaryCategoryToggleFilter' className='tab-btns'>
            <Grid container alignItems='stretch' justifyContent='flex-start' spacing={0} className='tab-btn-grid'>
              {renderGrandCategoryTabs()}
            </Grid>
          </div>
        </Toolbar>
      </div>
    </div>
  )
}

export default inject()(observer(TopToolbar))
