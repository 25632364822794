import { computed, action, observable } from 'mobx'
import { RootStore } from 'src/app/stores/RootStore'
import { IWeightProfileCategoryDTO } from '../dtos/IWeightProfileCategoryDTO'

export class ImportCategoryVM {
  private rootStore: RootStore
  private category: IWeightProfileCategoryDTO

  constructor(rootStore: RootStore, category: IWeightProfileCategoryDTO) {
    this.category = category
    this.rootStore = rootStore
  }

  @computed
  public get categoryName(): string {
    if (!this.categoryId) return ''
    const foundCat = this.rootStore.categoriesStore.getCategory(this.categoryId)
    if (foundCat) return foundCat.name
    return ''
  }

  @computed
  public get categoryId(): string {
    return this.category.categoryId
  }

  @computed
  public get value(): number {
    return this.category.value
  }

  @computed
  public get isChecked(): string {
    if (this.category.isChecked) return 'Yes'
    return 'No'
  }

}
