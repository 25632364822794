import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { ParticipantsSelectVM } from '../view-models/ParticipantsSelectVM'
import { AgGridReact } from 'ag-grid-react'
import Searchbar from '../../shared/Searchbar'
import { RoleRowVM } from '../view-models/RoleRowVM'
import GroupedRow from './GroupedRow'
import '../styles/RolesList.scss'

interface Props {
  participantsSelectVM: ParticipantsSelectVM
}

const RolesList: FC<Props> = ({ participantsSelectVM: vm }) => {
  if (!vm.rolesAGGridVM) return

  const renderNameCell = (props) => {
    const row: RoleRowVM = props.data
    if (!row) return null
    return <GroupedRow row={row} />
  }

  const renderGrid = () => {
    return (
      <div id='RolesListGridContainer'>
        <div
          id='RolesListGrid'
          style={{ display: vm.rolesAGGridVM.shown ? undefined : 'none' }}
          className='ag-theme-alpine'
        >
          <div id='RolesListAGGrid'>
            <AgGridReact
              gridOptions={{
                ...vm.rolesAGGridVM.getGridOptions(),
                frameworkComponents: {
                  nameCell: renderNameCell,
                },
              }}
            />
          </div>
        </div>
      </div>
    )
  }

  const renderSearchbox = () => {
    if (!vm.rolesAGGridVM.shown) return
    return (
      <Searchbar
        focused={vm.rolesAGGridVM.hasFilter}
        className='search'
        searchValue={vm.rolesAGGridVM.typedFilterText}
        onSearchChange={(e) => vm.rolesAGGridVM.setQuickFilter(e.target.value)}
      />
    )
  }

  return (
    <div
      id='ParticipantsSelect_RolesList'
      style={{
        display: vm.rolesAGGridVM.shown ? undefined : 'none',
      }}
    >
      <div id='RolesListCol'>
        {renderSearchbox()}
        {renderGrid()}
      </div>
    </div>
  )
}

export default observer(RolesList)
