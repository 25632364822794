import React from 'react'
import { observer } from 'mobx-react'
import { OptionVM } from '../../view-models/OptionVM'
import { Button, Checkbox, Radio } from '@material-ui/core'
import './OptionButton.scss'
import { UserSurveyTakeVM } from '../../view-models/UserSurveyTakeVM'

interface Props {
  takeVM: UserSurveyTakeVM
  option: OptionVM
}

const Option: React.FC<Props> = ({ option, takeVM }) => {
  const renderEmojiImage = () => {
    if (option.forQuestionType !== 'emoji') return
    return <img src={'../../../assets/emoticons/png/' + option.emoji + '.png'} alt='' />
  }

  const renderCheckbox = () => {
    if (option.forQuestionType !== 'check') return
    return <Checkbox checked={option.isChecked} />
  }

  const renderRadioButton = () => {
    let doRender = false
    if (option.forQuestionType === 'yesNo') doRender = true
    if (option.forQuestionType === 'radio') doRender = true
    if (!doRender) return
    return <Radio checked={option.isChecked} />
  }

  const renderButton = () => {
    if (option.forQuestionType === 'text') return
    return (
      <Button
        disabled={
          option.blurred ||
          takeVM.isSubmitting ||
          !option.optionsEnabled ||
          takeVM.isForResponseDisplay
        }
        className={option.isChecked ? 'active' : ''}
        onClick={() => {
          option.toggleChecked()
        }}
        component='div'
        startIcon={
          <>
            {renderEmojiImage()}
            {renderCheckbox()}
            {renderRadioButton()}
          </>
        }
      >
        {option.text}
      </Button>
    )
  }

  return (
    <div id='OptionButton' className={'sln-question' + option.forQuestionRank + '-option' + option.rank}>
      {renderButton()}
    </div>
  )
}

export default observer(Option)
