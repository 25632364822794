import Parse from 'parse'
import { RootStore } from '../../stores/RootStore'

export class NotificationsWidgetService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public async markAllAsRead(
    surveyIds: string[],
    responseIds: string[] = [],
    goalIds: string[],
    eventIds: string[],
    taskIds: string[],
    orgId: string
  ) {
    await Parse.Cloud.run('markAllNotificationsAsRead', {
      surveyIds,
      responseIds,
      goalIds,
      eventIds,
      taskIds,
      orgId,
    })
  }
}
