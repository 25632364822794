import { Point } from 'konva/types/Util'
import { ReportColors } from '../../shared/ReportColors'

export class Path {
  // private offsetX = 80
  // private offsetY = 234 - window.scrollY
  private offsetX = 0
  private offsetY = 0
  private priorPaths: Array<Path>

  public type: string = 'default'
  public color: string = ''

  constructor(
    fromRect: ClientRect | DOMRect,
    toRect: ClientRect | DOMRect,
    priorPaths: Array<Path>,
    offsetX,
    offsetY,
    type: string = 'default'
  ) {
    if (!toRect || !fromRect) return

    this.priorPaths = priorPaths
    this.type = type
    this.offsetX = offsetX
    this.offsetY = offsetY

    switch (type) {
      case 'default':
        this.drawDefaultPath(fromRect, toRect)
        break
      case 'option':
        this.drawOptionPath(fromRect, toRect)
        break
      case 'end':
        this.drawEndPath(fromRect, toRect)
        break
    }
  }

  drawDefaultPath(fromRect: ClientRect | DOMRect, toRect: ClientRect | DOMRect) {
    const startingPoint = {
      x: fromRect.left + fromRect.width / 2 - this.offsetX,
      y: fromRect.top - this.offsetY + fromRect.height,
    }
    const endingPoint = {
      x: startingPoint.x,
      y: toRect.top - this.offsetY,
    }
    this.points.push(startingPoint)
    this.points.push(endingPoint)
    this.color = '#f0f1f4'
  }

  drawOptionPath(fromRect: ClientRect | DOMRect, toRect: ClientRect | DOMRect) {
    const optionNum = this.getOptionNumber()
    const startingY = fromRect.top - this.offsetY + fromRect.height / 2
    const point0 = {
      x: fromRect.left + fromRect.width - this.offsetX + 1,
      y: startingY,
    }
    const point1 = {
      x: this.getOptionX(fromRect, startingY),
      y: startingY,
    }
    const point2 = {
      x: point1.x,
      y: toRect.top - this.offsetY + 15,
    }
    const point3 = {
      x: point0.x,
      y: point2.y,
    }
    this.points.push(point0)
    this.points.push(point1)
    this.points.push(point2)
    this.points.push(point3)
    this.color = ReportColors[optionNum % ReportColors.length]
  }

  drawEndPath(fromRect: ClientRect | DOMRect, toRect: ClientRect | DOMRect) {
    const optionNum = this.getOptionNumber()
    const startingY = fromRect.top - this.offsetY + fromRect.height / 2
    const point0 = {
      x: fromRect.left + fromRect.width - this.offsetX + 1,
      y: startingY,
    }
    const point1 = {
      x: this.getOptionX(fromRect, startingY),
      y: startingY,
    }
    const point2 = {
      x: point1.x,
      y: toRect.top - this.offsetY + 16,
    }
    const point3 = {
      x: toRect.left + toRect.width - this.offsetX + 1,
      y: point2.y,
    }
    this.points.push(point0)
    this.points.push(point1)
    this.points.push(point2)
    this.points.push(point3)
    this.color = ReportColors[optionNum % ReportColors.length]
  }

  private get priorOptions() {
    return this.priorPaths.filter((e) => e.type === 'option')
  }

  getOptionNumber() {
    return this.priorPaths.length
  }

  getOptionX(fromRect: ClientRect | DOMRect, y) {
    let x = fromRect.left + fromRect.width - this.offsetX + 30
    this.priorPaths.forEach((opt) => {
      if (!opt.points[1]) return
      if (Math.abs(opt.points[1].x - x) < 5 && opt.points[3].y >= y) x += 15
    })
    return x
  }

  points: Array<Point> = []

  getArrowPoint(): Point {
    if (this.points.length === 0) return null
    const point = this.points[this.points.length - 1]
    point.x = point.x - 9
    point.y = point.y - 12
    return point
  }

  toPointsArray() {
    const returnPoints: Array<number> = []
    this.points.forEach((pnt) => {
      returnPoints.push(pnt.x)
      returnPoints.push(pnt.y)
    })
    return returnPoints
  }
}
