import { DataStore } from '../../shared/data/DataStore'
import { RootStore } from '../../stores/RootStore'
import { SurveyResponse } from '../../survey-responses/aggregate/SurveyResponse'
import { ISurveyResponseDTO } from '../../survey-responses/dtos/ISurveyResponseDTO'
import { nameOf } from '../../shared/nameOf'
import Parse from 'parse'
import { IParseDateDTO } from '../../shared/IParseDateDTO'
import { FKUser } from '../../organization-users/aggregate/FKUser'
import { IQuestionDTO } from '../dtos/IQuestionDTO'
import { IAnonymousParticipantDTO } from '../dtos/IAnonymousParticipantDTO'
import { ResponsesAGGridVM } from './ResponsesAGGridVM'
import { action, runInAction } from 'mobx'

export class ResponsesListDataStore extends DataStore<SurveyResponse, ISurveyResponseDTO> {
  private parentVM: ResponsesAGGridVM
  private surveyId: string = ''
  private reloadJustCalled: boolean = false

  constructor(rootStore: RootStore, parentVM: ResponsesAGGridVM, surveyId: string) {
    super(rootStore, SurveyResponse, 'surveyResponses', [
      nameOf<ISurveyResponseDTO, string>((e) => e.objectId),
      nameOf<ISurveyResponseDTO, string>((e) => e.userId),
      nameOf<ISurveyResponseDTO, string>((e) => e.surveyId),
      nameOf<ISurveyResponseDTO, string>((e) => e.email),
      nameOf<ISurveyResponseDTO, IParseDateDTO>((e) => e.respondedAt),
      nameOf<ISurveyResponseDTO, IParseDateDTO>((e) => e.paperSurveyResponseDate),
      nameOf<ISurveyResponseDTO, IParseDateDTO>((e) => e.paperSurveyInputAt),
      nameOf<ISurveyResponseDTO, string>((e) => e.inputByUserId),
      nameOf<ISurveyResponseDTO, IAnonymousParticipantDTO>((e) => e.anonymousParticipant),
      nameOf<ISurveyResponseDTO, FKUser>((e) => e.fk_User),
    ])
    this.paged = true
    this.recordsPerPage = 100
    this.sortColumnName = 'respondedAt'
    this.sortDirection = 'desc'
    this.surveyId = surveyId
    this.parentVM = parentVM
  }

  public async onRecordDeleted() {
    if (this.reloadJustCalled) return
    console.log('deleted event')
    await this.loadListRecords()
    this.parentVM.reload()
    this.parentVM.gridApi.redrawRows()
    this.reloadJustCalled = true
    setTimeout(() => (this.reloadJustCalled = false), 2000)
  }

  protected getBaseQuery() {
    let query = new Parse.Query(this.className)
    query.equalTo('organizationId', this.rootStore.appStore.currentOrgId)
    query.equalTo('surveyId', this.surveyId)
    query.notEqualTo('isDeleted', true)
    return query
  }

  public getSurveyResponse(objectId: string) {
    return this.records.find((e) => e.objectId === objectId)
  }

  public get surveyResponses() {
    return this.records
  }

  protected getFullColumns() {
    return [
      nameOf<ISurveyResponseDTO, IQuestionDTO[]>((e) => e.questions),
      nameOf<ISurveyResponseDTO, string>((e) => e.name),
      nameOf<ISurveyResponseDTO, IParseDateDTO>((e) => e.paperSurveyInputAt),
      nameOf<ISurveyResponseDTO, string>((e) => e.userSurveyId),
      nameOf<ISurveyResponseDTO, string>((e) => e.inputByUserId),
    ]
  }
}
