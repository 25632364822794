import React from 'react'
import { inject, observer } from 'mobx-react'
import { EventsStore } from '../../store/EventsStore'
import { Card, Drawer } from '@material-ui/core'
import CalendarEventDrawerHeader from './CalendarEventDrawerHeader'
import CalendarEventDrawerButtonControls from './CalendarEventDrawerButtonControls'
import CalendarEventDrawerBody from './CalendarEventDrawerBody'
import CalendarEventDrawerParticipantsTab from './CalendarEventDrawerParticipantsTab'
import './CalendarEventDrawer.scss'
import EventSendNotificationsPrompt from '../EventSendNotificationsPrompt'

interface ICalendarEventDrawer {
  eventsStore?: EventsStore
}
const CalendarEventDrawer: React.FC<ICalendarEventDrawer> = ({ eventsStore }) => {
  const { editVM: vm } = eventsStore
  if (!vm) return null

  const renderParticipantsTab = () => {
    if (!vm.isParticipantsTabOpen) return null
    return <CalendarEventDrawerParticipantsTab />
  }

  const renderDrawerBody = () => {
    if (vm.isParticipantsTabOpen) return null
    return <CalendarEventDrawerBody />
  }

  return (
    <Drawer
      anchor='right'
      open={eventsStore.calendarVM.isEventsDrawerOpen}
      onClose={eventsStore.calendarVM.closeCalendarEventDrawer}
      id='CalendarEventDrawer'
    >
      <EventSendNotificationsPrompt vm={vm.eventSendNotificationsVM} />
      <Card elevation={0} className='drawer-card'>
        <CalendarEventDrawerHeader />
        <CalendarEventDrawerButtonControls />
        {renderParticipantsTab()}
        {renderDrawerBody()}
      </Card>
    </Drawer>
  )
}

export default inject('eventsStore')(observer(CalendarEventDrawer))
