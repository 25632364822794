import { observable, computed, action } from 'mobx'
import moment from 'moment'
import { RootStore } from '../../../../stores/RootStore'
import { PulseCampaign } from '../../../aggregate/PulseCampaign'

export class DraftPulseCampaignRowVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, pulseCampaign: PulseCampaign) {
    this.rootStore = rootStore
    this.pulseCampaign = pulseCampaign
  }

  @observable public pulseCampaign: PulseCampaign = null

  @computed
  public get hasPermission(): boolean {
    if (this.rootStore.appStore.isSystemAdmin) return true
    return this.pulseCampaign.ownerUserId === this.rootStore.appStore.currentUserId
  }

  @computed
  public get objectId(): string {
    return this.pulseCampaign.objectId
  }

  @computed
  public get name(): string {
    return this.pulseCampaign.name
  }

  @computed
  public get status(): string {
    return this.pulseCampaign.status
  }

  @computed
  public get createdAt(): string {
    const formattedDate = moment(this.pulseCampaign.createdAt).format('MMM DD YYYY')
    return formattedDate
  }

  @action
  public editPulseCampaign() {
    this.rootStore.appStore.router.push(`/pulse/v1/edit/${this.objectId}`)
  }
}
