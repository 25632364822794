import { RootStore } from '../../../stores/RootStore'
import { AGGridVM } from 'src/app/shared/ag-grid/AGGridVM'
import { ColDef, GridOptions, RowClickedEvent } from 'ag-grid-community'
import { action, computed, observable } from 'mobx'
import { nameOf } from 'src/app/shared/nameOf'
import { UserAnnouncementEventsVM } from 'src/app/userAnnouncementEvents/view-models/UserAnnouncementEventsVM'
import { AnnouncementListVM } from './AnnouncementListVM'
import { AnnouncementListRowVM } from './AnnouncementListRowVM'
import { Announcement } from '../../aggregate/Announcement'
import { AnnouncementListDataStore } from '../../store/AnnouncementListDataStore'
import ActionsCell from '../../views/list/ActionsCell'

export class AnnouncementListTableVM extends AGGridVM {
  private rootStore: RootStore
  public listVM: AnnouncementListVM
  public dataStore: AnnouncementListDataStore

  constructor(rootStore, listVM: AnnouncementListVM, dataStore: AnnouncementListDataStore) {
    super()
    this.rootStore = rootStore
    this.dataStore = dataStore
    this.listVM = listVM
    this.sizeColumnsToFit = true
    this.serverSideLoaded = true
  }

  @observable public announcementId: string = undefined
  @observable public rows = []
  @observable public loadingFirstPage: boolean = true
  @observable public eventsVM: UserAnnouncementEventsVM = undefined
  @observable public showEventDialog: boolean = false
  @observable public loadingDataStore: boolean = false
  @observable public filterAnchorEl = null
  @observable public filterText: string = 'All'
  @observable public filterDisabled: boolean = false

  @action
  public setFilterAnchorEl(element) {
    this.filterAnchorEl = element
  }

  @action
  private onRecordUpdated(obj: Announcement) {
    const rowNode = this.gridApi.getRowNode(obj.objectId)
    if (!rowNode) {
      return
    }
    rowNode.setData(new AnnouncementListRowVM(this.rootStore, obj, this))
  }

  @action
  public toggleShowEventDialog() {
    this.showEventDialog = !this.showEventDialog
  }

  @computed
  public get hasRows() {
    return this.rows.length !== 0
  }

  @computed
  public get shouldRender(): boolean {
    if (this.loadingDataStore) {
      return false
    } else {
      return true
    }
  }

  @computed
  public get columnDefs(): ColDef[] {
    const s = this.rootStore.localizationStore.lzStrings
    return [
      {
        headerName: 'Title',
        field: nameOf<AnnouncementListRowVM, string>((e) => e.title),
        minWidth: 180,
        headerTooltip: 'Title',
        sortable: true,
      },
      {
        headerName: 'Participants',
        field: nameOf<AnnouncementListRowVM, number>((e) => e.participantCount),
        maxWidth: 130,
        headerTooltip: 'Participants',
      },
      {
        headerName: 'Views',
        field: nameOf<AnnouncementListRowVM, number>((e) => e.viewCount),
        maxWidth: 130,
        headerTooltip: 'Views',
      },
      {
        headerName: 'Likes',
        field: nameOf<AnnouncementListRowVM, number>((e) => e.likeCount),
        maxWidth: 130,
        headerTooltip: 'Likes',
      },
      {
        headerName: 'Attachments',
        field: nameOf<AnnouncementListRowVM, number>((e) => e.attachmentCount),
        maxWidth: 130,
        headerTooltip: 'Attachments',
        sortable: false,
      },
      {
        headerName: 'Status',
        field: nameOf<AnnouncementListRowVM, string>((e) => e.status),
        headerTooltip: 'Status',
      },
      {
        headerName: 'Owner',
        field: nameOf<AnnouncementListRowVM, string>((e) => e.owner),
        headerTooltip: 'Announcement Owner',
      },
      {
        headerName: 'Created',
        field: nameOf<AnnouncementListRowVM, string>((e) => e.createdAt),
        headerTooltip: 'Created',
        sort: 'desc',
      },
      {
        headerName: 'Created By',
        field: nameOf<AnnouncementListRowVM, string>((e) => e.createdBy),
        headerTooltip: 'Created By',
      },
      {
        headerName: 'Updated',
        field: nameOf<AnnouncementListRowVM, string>((e) => e.updatedAt),
        headerTooltip: 'Updated',
      },
      {
        headerName: 'Updated By',
        field: nameOf<AnnouncementListRowVM, string>((e) => e.updatedBy),
        headerTooltip: 'Updated By',
      },
      {
        headerName: 'Actions',
        cellRenderer: 'actionsCell',
        sortable: false,
        onCellClicked: null,
        width: 150,
        headerTooltip: 'Actions',
      },
    ]
  }

  public rowClicked(e: RowClickedEvent) {
    const row: AnnouncementListRowVM = e.data
    if (!row) return
    row.click()
  }

  @action
  public getRowNodeId(row): string {
    return row.objectId
  }

  public getGridOptions(): GridOptions {
    return {
      ...this.baseGridOptions,
      rowData: this.rows,
      onRowClicked: (e) => this.rowClicked(e),
      getRowNodeId: (e) => this.getRowNodeId(e),
      suppressRowClickSelection: true,
      suppressCellSelection: true,
      suppressRowHoverHighlight: true,
      rowSelection: 'multiple',
      columnDefs: this.ensureTooltipFields(this.columnDefs),
      pagination: false,
      frameworkComponents: {
        actionsCell: ActionsCell,
      },
    }
  }

  @action
  public hardRefresh() {
    if (!this.gridApi) return
    this.gridApi.refreshInfiniteCache()
    this.gridApi.purgeInfiniteCache()
    this.gridApi.paginationGoToFirstPage()
  }

  @action
  public setQuickFilter(val: string) {
    this.typedFilterText = val
    if (this.quickFilterTO) clearTimeout(this.quickFilterTO)
    this.quickFilterTO = setTimeout(() => this.applyFilter(), 1000)
  }

  private applyFilter() {
    this.dataStore.setFilter(this.typedFilterText)
    this.reload()
    this.columnApi.applyColumnState({
      state: [{ colId: 'createdAt', sort: 'asc' }],
    })
  }

  protected onGridReadied(): void {
    this.gridApi.sizeColumnsToFit()
    this.gridApi.setDatasource({
      rowCount: this.totalRecords,
      getRows: async (params) => {
        params.sortModel.forEach((col: { colId: string; sort: 'asc' | 'desc' }, idx: number) => {
          let dbCol = col.colId
          if (dbCol.includes('owner')) dbCol = 'fk_ownerUser.name'
          if (dbCol.includes('createdBy')) dbCol = 'fk_createdByUser.name'
          if (dbCol.includes('updatedBy')) dbCol = 'fk_updatedByUser.name'
          if (dbCol.includes('participantCount')) dbCol = 'numberOfParticipants'
          if (dbCol.includes('viewCount')) dbCol = 'views'
          if (dbCol.includes('likeCount')) dbCol = 'likes'
          if (idx === 0) this.dataStore.setSort(dbCol, col.sort)
          if (idx > 0) this.dataStore.addSort(dbCol, col.sort)
        })
        if (params.sortModel.length === 0) this.dataStore.setSort('createdAt')
        if (params.startRow === 0) {
          this.dataStore.setPage(0)
          this.loadingFirstPage = true
        }
        await this.dataStore.getNextPage()
        const rows = this.dataStore.announcements.map(
          (e) => new AnnouncementListRowVM(this.rootStore, e, this)
        )
        this.rows = rows
        this.loadingFirstPage = false
        this.filterDisabled = false
        params.successCallback(rows, this.dataStore.totalRecords)
      },
    })
  }
}
