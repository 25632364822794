import { observable } from 'mobx'
import { DataStore } from '../../shared/data/DataStore'
import { RootStore } from '../../stores/RootStore'
import SurveyTemplateFolder from '../aggregate/SurveyTemplateFolder'
import { ISurveyTemplateFolderDTO } from '../dtos/ISurveyTemplateFolderDTO'
import { ISurveyTemplateFoldersFindRequest } from '../interfaces/ISurveyTemplateFoldersFindRequest'
import { SurveyTemplateFoldersService } from '../services/SurveyTemplateFoldersService'

export class SurveyTemplateFoldersFindDataStore extends DataStore<SurveyTemplateFolder, ISurveyTemplateFolderDTO> {
  private request: ISurveyTemplateFoldersFindRequest

  constructor(rootStore: RootStore, request: ISurveyTemplateFoldersFindRequest) {
    super(rootStore, SurveyTemplateFolder, 'surveyTemplateFolders', [
      'name',
      'organizationId',
      'parentFolderId',
      'subscribedOrganizationIds'
    ])
    this.request = request
    this.paged = true
    this.recordsPerPage = 100
    this.pageNumber = 1
    this.sortColumnName = 'name'
    this.listenToListRecordsViaApiUpdates = true
  }

  @observable public isLoaded: boolean = false

  public onListRecordsLoaded() {
    this.isLoaded = true
  }

  public onRecordUpdated(obj: SurveyTemplateFolder) {
    return this.loadListRecords()
  }

  public setRequest(request: ISurveyTemplateFoldersFindRequest) {
    this.request = request
  }

  public getRequest(): ISurveyTemplateFoldersFindRequest {
    return this.request
  }

  protected getListRecordsApiCall() {
    this.isLoaded = false
    return async () => {
      const req: ISurveyTemplateFoldersFindRequest = {
        ...this.request,
        userId: this.rootStore.appStore.currentUserId,
        terms: this.filter,
        skip: this.recordsPerPage * (this.pageNumber - 1),
        limit: this.recordsPerPage,
        sortColumnName: this.sortColumnName,
        sortDirection: this.sortDirection,
        listColumns: this.listColumns,
      }
      const svc = new SurveyTemplateFoldersService(this.rootStore)
      const result = await svc.findSurveyTemplateFolders(req)
      this.totalRecords = result.count
      return result.surveyTemplateFolders
    }
  }

  public get surveyTemplates() {
    return this.records
  }
}
