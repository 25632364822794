import { ColDef, GridOptions, RowClickedEvent } from 'ag-grid-community'
import { action, computed, observable } from 'mobx'
import { AGGridVM } from '../../../shared/ag-grid/AGGridVM'
import { nameOf } from '../../../shared/nameOf'
import { RootStore } from '../../../stores/RootStore'
import { MyAudienceWidgetVM } from './MyAudienceWidgetVM'
import { GroupsDataStore } from './GroupsDataStore'
import { RolesDataStore } from './RolesDataStore'
import { GroupingRowVM } from './GroupingRowVM'

export class GroupingsTabVM extends AGGridVM {
  private rootStore: RootStore
  private dataStore: GroupsDataStore | RolesDataStore
  private tabIndex: number
  @observable private shownOnce: boolean = false

  constructor(rootStore: RootStore, widgetVM: MyAudienceWidgetVM, tabIndex: number) {
    super()
    this.rootStore = rootStore
    this.widgetVM = widgetVM
    this.tabIndex = tabIndex
    this.serverSideLoaded = true
    this.loadDataStore()
  }

  public get type(): 'role' | 'group' {
    if (this.tabIndex === 3) return 'role'
    if (this.tabIndex === 4) return 'group'
  }

  private loadDataStore() {
    if (this.type === 'group') this.dataStore = new GroupsDataStore(this.rootStore)
    if (this.type === 'role') this.dataStore = new RolesDataStore(this.rootStore)
  }

  public widgetVM: MyAudienceWidgetVM

  public getRowNodeId(row: GroupingRowVM): string {
    return row.objectId
  }

  @action
  private setShownOnce() {
    this.shownOnce = true
  }

  @computed
  public get shown(): boolean {
    return this.widgetVM.tabIndex === this.tabIndex
  }

  @computed
  public get shouldRenderGrid(): boolean {
    if (this.shownOnce) return true
    if (this.shown) return true
    return false
  }

  @computed
  public get columnDefs(): ColDef[] {
    const s = this.rootStore.localizationStore.lzStrings
    return [
      {
        headerName: s.dashboard.name,
        field: nameOf<GroupingRowVM, string>((e) => e.name),
        sort: 'asc',
        cellRenderer: 'nameCell',
      },
      {
        headerName: s.surveys.type,
        field: nameOf<GroupingRowVM, string>((e) => e.rowType),
      },
      { headerName: s.userEdit.actions, field: 'actions', cellRenderer: 'actionsCell' },
    ]
  }

  public getGridOptions(): GridOptions {
    return {
      ...this.baseGridOptions,
      onRowClicked: (e) => this.rowClicked(e),
      getRowNodeId: (e) => this.getRowNodeId(e),
      columnDefs: this.ensureTooltipFields(this.columnDefs),
    }
  }

  @action
  public setQuickFilter(val: string) {
    this.typedFilterText = val
    if (this.quickFilterTO) clearTimeout(this.quickFilterTO)
    this.quickFilterTO = setTimeout(() => this.applyFilter(), 1000)
  }

  private applyFilter() {
    this.dataStore.setFilter(this.typedFilterText)
    this.reload()
    this.columnApi.applyColumnState({
      state: [{ colId: 'name', sort: 'asc' }],
    })
  }

  protected onGridReadied() {
    this.gridApi.setDatasource({
      rowCount: this.totalRecords,
      getRows: async (params) => {
        this.setShownOnce()
        params.sortModel.forEach((col: { colId: string; sort: 'asc' | 'desc' }, idx: number) => {
          let dbCol = col.colId
          if (idx === 0) this.dataStore.setSort(dbCol, col.sort)
          if (idx > 0) this.dataStore.addSort(dbCol, col.sort)
        })
        if (params.sortModel.length === 0) this.dataStore.setSort('fk_User.lastName')
        if (params.startRow === 0) this.dataStore.setPage(0)
        await this.dataStore.getNextPage()
        const rows = (this.dataStore.rows as any).map(
          (e) => new GroupingRowVM(this.rootStore, e, this)
        )
        params.successCallback(rows, this.dataStore.totalRecords)
      },
    })
  }

  public get rows(): GroupingRowVM[] {
    return []
  }

  public rowClicked(e: RowClickedEvent) {
    console.debug(e)
  }

  @computed
  protected get totalRecords() {
    return this.dataStore.totalRecords
  }

  @computed
  public get hasRows(): boolean {
    return this.dataStore.hasListRecords
  }

  @computed
  public get noRowsToShowLabel() {
    return this.rootStore.localizationStore.lzStrings.dashboard.no_rows_to_show
  }

  @action
  public setEmailAnchorEl(type: string, val: any, row: any) {
    this.widgetVM.setEmailAnchorEl(type, val, row)
  }
}
