import { ISystemAdminUser } from '../../dto/ISystemAdminUser'
import { RootStore } from '../../../stores/RootStore'
import { computed, action } from 'mobx'
import SystemAdminsListWidgetVM from './SystemAdminsListWidgetVM'

export class SystemAdminRowVM {
  private user: ISystemAdminUser
  private rootStore: RootStore
  private listVm: SystemAdminsListWidgetVM

  constructor(rootStore: RootStore, listVm: SystemAdminsListWidgetVM, user: ISystemAdminUser) {
    this.rootStore = rootStore
    this.user = user
    this.listVm = listVm
  }

  @computed public get name(): string {
    return this.user.name
  }
  @computed public get email(): string {
    return this.user.email
  }
  @computed public get objectId(): string {
    return this.user.objectId
  }

  @action
  public deleteSystemAdmin() {
    this.listVm.showDeleteDialog(this.objectId)
  }
}
