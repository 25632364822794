import React from 'react'
import { inject, observer } from 'mobx-react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Drawer from '@material-ui/core/Drawer'
import DrawerHeader from './DrawerHeader'
import DrawerButtonControls from './DrawerButtonControls'
import './SurveyTagsDrawer.scss'
import { SurveyTagsStore } from '../../store/SurveyTagsStore'
import SurveyTagsEdit from './SurveyTagsEdit'

interface SurveyTagsWidgetDrawerProps {
  surveyTagsStore?: SurveyTagsStore
}

const SurveyTagsDrawer: React.FC<SurveyTagsWidgetDrawerProps> = ({ surveyTagsStore }) => {
  const handleClose = () => {
    surveyTagsStore.closeWidgetDrawer()
  }

  return (
    <Drawer
      id='SurveyTagsDrawer'
      anchor='right'
      open={surveyTagsStore.isWidgetDrawerOpen}
      onClose={handleClose}
    >
      <Card elevation={0}>
        <DrawerHeader />
        <DrawerButtonControls />
        <CardContent>
          <SurveyTagsEdit />
        </CardContent>
      </Card>
    </Drawer>
  )
}

export default inject('surveyTagsStore')(observer(SurveyTagsDrawer))
