import React from 'react'
import { IndexRoute, Route } from 'react-router'
import Layout from '../../layout/Layout'
import { authenticate } from '../../auth/routes/AuthenticatedRoute'
import TrainingItemEditScreen from 'src/app/training-items/views/edit/TrainingItemEditScreen'

export default function getTrainingItemsRoutes() {
  return (
    <Route key={'impact0'} component={authenticate(Layout)} path='trainingitems'>
      <IndexRoute onEnter={(nextState, replace) => replace('/dashboard/impact')} />
      <Route key={'impact2'} path='from-catalog/edit/:id' component={TrainingItemEditScreen} />
      <Route
        key={'impact3'}
        path='from-catalog/clone/:catalogId'
        component={TrainingItemEditScreen}
      />
      <Route key={'impact4'} path='edit/:id' component={TrainingItemEditScreen} />
    </Route>
  )
}
