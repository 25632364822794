import { observable } from 'mobx'
import { AudienceMemberType } from '../../audience-members/types/AudienceMemberType'

export class Group {
  @observable id: string = ''
  @observable name: string = ''
  @observable type: AudienceMemberType = 'group'
  @observable privilegeSetIds: Array<string> = []

  static create(fromObj) {
    const group = new Group()
    group.id = fromObj.id ? fromObj.id : fromObj.objectId
    group.name = fromObj.name
    group.type = fromObj.type
    group.privilegeSetIds = fromObj.privilegeSetIds ? fromObj.privilegeSetIds : []
    return group
  }
}
