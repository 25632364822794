import React from 'react'
import { CardContent, Grid, Typography } from '@material-ui/core'
import AlarmIcon from '@material-ui/icons/Alarm'
import CategoryIcon from '@material-ui/icons/Category'
import PeopleIcon from '@material-ui/icons/People'
import RoomIcon from '@material-ui/icons/Room'
import VideoIcon from '@material-ui/icons/VideocamRounded'
import moment from 'moment'
import { observer, inject } from 'mobx-react'
import PopupParticipants from './PopupParticipants'
import { EventsStore } from '../../../store/EventsStore'
import Linkify from 'react-linkify'
import NotesIcon from '@material-ui/icons/Notes'
import './EventPopup.scss'
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import DescriptionIcon from '@material-ui/icons/Description'
import LocalizationStore from '../../../../localization/LocalizationStore'
import PersonIcon from '@material-ui/icons/Person'
import Avatar from '../../../../shared/Avatar'
import { AttachmentVM } from '../../../../attachments/view-models/AttachmentVM'

interface IEventPopupBody {
  localizationStore?: LocalizationStore
  eventsStore?: EventsStore
}

const EventPopupBody: React.FC<IEventPopupBody> = ({ eventsStore, localizationStore }) => {
  const { eventPopupVM: vm } = eventsStore
  const { lzStrings } = localizationStore
  if (!vm) return null

  const renderNotAllDayAndOneDay = () => {
    if (vm.allDay || !Boolean(moment(vm.startDate).isSame(moment(vm.endDate), 'day'))) return null
    return (
      <Grid item xs={12}>
        <Typography variant='h5' className='popup-date'>
          {moment(vm.startDate).format(lzStrings.userEvent.not_all_day_one_day_start_format)} -{' '}
          {moment(vm.endDate).format(lzStrings.userEvent.not_all_day_one_day_end_format)}
        </Typography>
      </Grid>
    )
  }

  const renderNotAllDayAndMultipleDays = () => {
    if (vm.allDay || !Boolean(moment(vm.startDate).isBefore(moment(vm.endDate), 'day'))) return null
    return (
      <Grid item xs={12}>
        <Typography variant='h5' className='popup-date'>
          {moment(vm.startDate).format(lzStrings.userEvent.not_all_day_multi_day_start_format)} -{' '}
          {moment(vm.endDate).format(lzStrings.userEvent.not_all_day_multi_day_end_format)}
        </Typography>
      </Grid>
    )
  }

  const renderAllDayOneDay = () => {
    if (!vm.allDay || Boolean(moment(vm.startDate).isSame(moment(vm.endDate), 'day'))) return null
    return (
      <Grid item xs={12}>
        <Typography variant='h5' className='popup-date'>
          {moment(vm.startDate).format(lzStrings.userEvent.all_day_one_day_start_format)} -{' '}
          {moment(vm.endDate).format(lzStrings.userEvent.all_day_one_day_end_format)}
        </Typography>
      </Grid>
    )
  }

  const renderAllDayMultipleDays = () => {
    if (!vm.allDay || Boolean(moment(vm.startDate).isBefore(moment(vm.endDate), 'day'))) return null
    return (
      <Grid item xs={12}>
        <Typography variant='h5' className='popup-date'>
          {moment(vm.startDate).format(lzStrings.userEvent.all_day_multi_day_start_format)}
        </Typography>
      </Grid>
    )
  }

  const renderLocation = () => {
    if (!vm.location) return null
    return (
      <Grid
        item
        xs={12}
        container
        spacing={0}
        alignItems='flex-start'
        className='location-container'
      >
        <Grid item xs={1} container justifyContent='center'>
          <RoomIcon className='icon' />
        </Grid>
        <Grid item>{vm.location ? vm.location.name : '--'}</Grid>
      </Grid>
    )
  }

  const renderAttachmentsSection = () => {
    if (vm.attachments.length === 0) return null
    return (
      <Grid item xs={12} container spacing={0} alignItems='flex-start'>
        <Grid item xs={1} container justifyContent='center'>
          <AttachFileIcon className='icon' />
        </Grid>
        <Grid item>{vm.attachments.map((a, index) => renderAttachment(a, index))}</Grid>
      </Grid>
    )
  }

  const renderAttachment = (attachment: AttachmentVM, index) => {
    const renderAttachmentThumbnail = () => {
      if (attachment.isImage) {
        return (
          <div
            className='event-img-container'
            style={{ backgroundImage: `url("${attachment.url}")` }}
          />
        )
      }
      if (attachment.isVideo) {
        if (attachment.url) {
          if (attachment.url.includes('vimeo')) return <VideoIcon />
          if (attachment.url.includes('youtube') || attachment.url.includes('youtu.be'))
            return <VideoIcon />
          if (attachment.url.startsWith('http')) {
            return (
              <div className='event-img-container'>
                <video>
                  <source src={attachment.url} type='video/mp4' />
                </video>
              </div>
            )
          } else return <VideoIcon />
        } else return <VideoIcon />
      }
      return <DescriptionIcon />
    }
    return (
      <Grid item xs={12} key={index} className='event-attachment'>
        <span className='event-attachment-thumbnail'>
          {renderAttachmentThumbnail()}
          <Typography
            variant='body1'
            className='attachment-file'
            onClick={(e) => vm.openAttachment(attachment)}
          >
            {attachment.fileName}
          </Typography>
        </span>
      </Grid>
    )
  }

  const renderResources = () => {
    if (vm.resourcesCSV.length < 1) return null
    return (
      <Grid item xs={12} container spacing={0} alignItems='flex-start'>
        <Grid item xs={1} container justifyContent='center'>
          <CategoryIcon className='icon' />
        </Grid>
        <Grid item>{vm.resourcesCSV}</Grid>
      </Grid>
    )
  }

  const renderParticipants = () => {
    if (vm.event.userIds.length === 0) return null
    return (
      <Grid item xs={12} container spacing={0} alignItems='flex-start'>
        <Grid item xs={1} container justifyContent='center'>
          <PeopleIcon className='icon' />
        </Grid>
        <Grid item xs={10}>
          <PopupParticipants />
        </Grid>
      </Grid>
    )
  }

  const renderAlarms = () => {
    if (vm.alarmsCSV.length === 0) return null
    return (
      <Grid item xs={12} container spacing={0} alignItems='flex-start'>
        <Grid item xs={1} container justifyContent='center'>
          <AlarmIcon className='icon' />
        </Grid>
        <Grid item>{vm.alarmsCSV}</Grid>
      </Grid>
    )
  }

  const renderPlaceholderIndicator = () => {
    if (!vm.placeholderIconShown) return
    return (
      <div className='placeholder-icon'>
        <FiberManualRecordOutlinedIcon />
      </div>
    )
  }

  const renderTitle = () => {
    return (
      <Grid item xs={12} className='title-item'>
        <Typography variant='h6'>
          {renderPlaceholderIndicator()}
          {vm.title}
        </Typography>
      </Grid>
    )
  }

  const componentDecorator = (href, text, key) => (
    <a href={href} key={key} target='_blank'>
      {text}
    </a>
  )

  const renderNotes = () => {
    if (!vm.notes) return null
    return (
      <Grid item xs={12} container spacing={0} alignItems='flex-start'>
        <Grid container>
          <Grid item xs={1} container justifyContent='center'>
            <NotesIcon className='icon' />
          </Grid>
          <Grid item xs={11} className='notes-body'>
            <Linkify componentDecorator={componentDecorator}>{vm.notes}</Linkify>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderOrganizer = () => {
    if (!vm.organizer) return null
    return (
      <Grid item xs={12} container spacing={0} className='organizer-item' alignItems='flex-start'>
        <Grid item xs={1} container justifyContent='center'>
          <PersonIcon className='icon' />
        </Grid>
        <Grid>
          <Avatar
            user={{
              name: vm.organizer.name,
              iconURL: vm.organizer.iconURL,
              objectId: vm.organizer.objectId,
            }}
          />
        </Grid>
        <Grid>
          <Typography className='participant-name'>{vm.organizer.name}</Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <CardContent>
      <Grid container direction='column' spacing={2}>
        {renderTitle()}
        {renderNotAllDayAndOneDay()}
        {renderNotAllDayAndMultipleDays()}
        {renderAllDayOneDay()}
        {renderAllDayMultipleDays()}
        {renderOrganizer()}
        {renderLocation()}
        {renderResources()}
        {renderParticipants()}
        {renderAlarms()}
        {renderNotes()}
        {renderAttachmentsSection()}
      </Grid>
    </CardContent>
  )
}

export default inject('eventsStore', 'localizationStore')(observer(EventPopupBody))
