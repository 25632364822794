import { action } from 'mobx'
import Parse from 'parse'
import { RootStore } from 'src/app/stores/RootStore'

export class CategoriesService {
  private rootStore: RootStore
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }
  public async importCategoriesFromCsv(orgId, parseFile, doSave, batchId) {
    const result = await Parse.Cloud.run('importCategoriesFromCsv', {
      orgId,
      parseFile,
      doSave,
      batchId,
    })
    return result
  }
}
