import { observable, action } from 'mobx'
import { IEventTypeDTO } from '../dtos/IEventTypeDTO'
import { serializable, serialize } from 'serializr'
import { IAggregate } from 'src/app/shared/data/IAggregate'

export default class EventType implements IEventTypeDTO, IAggregate {
  static create(orgId) {
    const eventType = new EventType()
    eventType.organizationId = orgId
    return eventType
  }

  @serializable @observable objectId: string = ''
  @serializable @observable organizationId: string = ''
  @serializable @observable name: string = ''
  @serializable @observable description: string = ''
  @serializable @observable resourceId: string = ''
  @serializable @observable isProcessing: boolean = false
  @serializable @observable isDeleted: boolean = false
  isOnServer: boolean = false

  markIsNotOnServer() {
    this.isOnServer = false
  }

  markIsOnServer() {
    this.isOnServer = true
  }

  @action
  markIsNotProcessing() {
    this.isProcessing = false
  }

  markIsProcessing() {
    this.isProcessing = true
  }

  public serialize(): IEventTypeDTO {
    return serialize(this)
  }
}
