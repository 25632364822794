import React from 'react'
import { inject, observer } from 'mobx-react'
import { WeightProfilesStore } from '../../../store/WeightProfilesStore'
import './RolesSideBar.scss'
import { Tooltip, IconButton } from '@material-ui/core'
import Searchbar from '../../../../shared/Searchbar'
import VirtualList from 'react-tiny-virtual-list'
import RoleRow from './RoleRow'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import WeightProfilesImportModal from '../../import/WeightProfilesImportModal'

interface Props {
  weightProfilesStore?: WeightProfilesStore
}

const RolesSieBar: React.FC<Props> = ({ weightProfilesStore }) => {
  const { editVM } = weightProfilesStore
  if (!editVM) return null

  const renderHeader = () => {
    return (
      <div className='roles-header' >
        <div className='greeting'>Roles</div>
        <div className='sidebar-actions'>
          <div>
            <Searchbar
              focused={editVM.keywordFilter.length > 0}
              searchValue={editVM.keywordFilter}
              onSearchChange={e => editVM.setKeywordFilter(e.target.value)}
            />
          </div>
          <div>
            <Tooltip title={'Import Weight Profiles'} placement='bottom'>
              <IconButton className='icon' size='small' onClick={() => weightProfilesStore.toggleImportModal()}>
                <ImportExportIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>

      // </Grid>
    )
  }

  const renderFlatList = () => {
    return (
      <VirtualList
        height={700}
        itemCount={editVM.rolesToRender.length}
        itemSize={50}
        renderItem={({ index, style }) => (
          <RoleRow role={editVM.rolesToRender[index]} key={index} style={style} editVM={editVM} />
        )}
      />
    )
  }

  return (
    <>
    <WeightProfilesImportModal />
    <div id='RolesSideBar'>
      {renderHeader()}
      {renderFlatList()}
    </div>
    </>
  )
}

export default inject('weightProfilesStore')(observer(RolesSieBar))
