import { persist } from 'mobx-persist'
import { observable, action } from 'mobx'
import { serialize } from 'serializr'
import { IAggregate } from '../../shared/data/IAggregate'
import { IQuizResultDTO } from '../dtos/IQuizResultDTO'

export class QuizResult implements IQuizResultDTO, IAggregate {
  @persist @observable public objectId: string = ''
  @persist @observable public quizId: string = ''
  @persist @observable public trainingPlanId: string = ''
  @persist @observable public organizationId: string = ''
  @persist('object') @observable public questionAnswers: any = null
  @persist @observable public totalResponses: number = 0
  @persist @observable public totalScores: number = 0
  @persist @observable public isDeleted: boolean = false

  isOnServer: boolean = false

  @action
  public markIsOnServer() {
    this.isOnServer = true
  }

  @action
  public markIsNotOnServer() {
    this.isOnServer = false
  }

  public toDTO(): IQuizResultDTO {
    return serialize(this)
  }

  public serialize(): IQuizResultDTO {
    return serialize(this)
  }
}
