import { RootStore } from '../../../stores/RootStore'
import { DataStore } from '../../../shared/data/DataStore'
import { Role } from '../../../roles/aggregate/Role'
import { IRoleDTO } from '../../../roles/dtos/IRoleDTO'
import { OrganizationUsersService } from '../../../organization-users/service/OrganizationUsersService'
import { IOrganizationUsersAudienceRoleSearchRequest } from '../../interfaces/IOrganizationUsersAudienceRoleSearchRequest'

export class RolesDataStore extends DataStore<Role, IRoleDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, Role, 'roles', ['objectId', 'name'])
    this.paged = true
    this.recordsPerPage = 100
    this.sortColumnName = 'name'
  }

  protected getListRecordsApiCall() {
    return async () => {
      const req: IOrganizationUsersAudienceRoleSearchRequest = {
        userId: this.rootStore.appStore.currentUserId,
        orgId: this.rootStore.appStore.currentOrgId,
        terms: this.filter,
        skip: this.recordsPerPage * (this.pageNumber - 1),
        limit: this.recordsPerPage,
        sortColumnName: this.sortColumnName,
        sortDirection: this.sortDirection,
      }
      const svc = new OrganizationUsersService(this.rootStore)
      const result = await svc.searchAudienceRoles(req)
      this.totalRecords = result.count === 0 ? result.roles.length : result.count
      return result.roles
    }
  }

  public get rows() {
    return this.records
  }
}
