import { observable } from 'mobx'
import { serializable} from 'serializr'

export class Participant {

  static create(obj) {
    const participant = new Participant()
    participant.quantity = obj.quantity
    participant.roleId = obj.roleId
    participant.isArchived = obj.isArchived
    return participant
  }

  @serializable @observable roleId: string = ''
  @serializable @observable quantity: number = 0
  @serializable @observable public isArchived?: boolean = false
}
