export const ReportColors = [
  '#3bb2d0',
  '#8a8acb',
  '#56b881',
  '#50667f',
  '#41afa5',
  '#f9886c',
  '#e55e5e',
  '#ed6498',
  '#fbb03b',
  '#142736',
  '#bf0c0c',
  '#e76a05',
  '#ffc600',
  '#47bc00',
  '#05007b',
  '#9748a8',
  '#2bb3f3',
  '#865200',
  '#949483',
  '#f47b7b',
  '#9f1f5c',
  '#ef9020',
  '#00af3e',
  '#85b7e2',
  '#29245c',
  '#ffd616',
  '#e5352b',
  '#e990ab',
  '#0081b4',
  '#96cbb3',
  '#39a6dd',
  '#eb0973',
  '#333c41',
  '#b1b134',
  '#bbd634',
]