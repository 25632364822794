import { action, observable, computed } from 'mobx'
import LocalizationStore from '../../localization/LocalizationStore'
import { IPasswordPolicyInfo } from './IPasswordPolicyInfo'
import { IPasswordPolicyMonitor } from './IPasswordPolicyMonitor'

export class PasswordStrengthMeterVM {
  private localizationStore: LocalizationStore

  private PW_POLICY_CHARS_MIN = 12
  private atLeastOneUppercase = /[A-Z]/g // uppercase letters from A to Z
  private atLeastOneLowercase = /[a-z]/g // lowercase letters from a to z
  private atLeastOneNumeric = /[0-9]/g // numbers from 0 to 9
  private atLeastOneSpecialChar = /[`~!@#$%^&*()=<>_+{}[\]\\|,-.//?;':"]/g // special characters
  private charsOrMore = new RegExp(`.{${this.PW_POLICY_CHARS_MIN},}`, 'g') // n characters or more

  constructor(localizationStore: LocalizationStore) {
    this.localizationStore = localizationStore
  }

  @observable public password: string = ''
  @observable public isPasswordMeterShown: boolean = false

  @action
  public setPassword(password: string) {
    this.password = password
  }

  @computed
  public get newPasswordInfo() {
    const s = this.localizationStore.lzStrings.profile

    let info = ''
    if (this.newPasswordStrengthScore < 5) info = `${s.update_the_password_to_include} `
    if (!this.newPasswordPolicyMonitor.lowercase) info = info + `${s.one} ${s.lowercase_letter}, `
    if (!this.newPasswordPolicyMonitor.uppercase) info = info + `${s.one} ${s.uppercase_letter}, `
    if (!this.newPasswordPolicyMonitor.specialChar)
      info = info + `${s.one} ${s.special_character}, `
    if (!this.newPasswordPolicyMonitor.number) info = info + `${s.one} ${s.number}, `
    if (!this.newPasswordPolicyMonitor.charsOrGreater)
      info = info + `${s.at_least} ${this.PW_POLICY_CHARS_MIN} ${s.characters} `
    if (this.newPasswordStrengthScore < 5) info = info + ` ${s.at_a_minimum}`
    return info
  }

  @computed
  public get newPasswordPolicyMonitor() {
    return {
      uppercase: this.password.match(this.atLeastOneUppercase),
      lowercase: this.password.match(this.atLeastOneLowercase),
      number: this.password.match(this.atLeastOneNumeric),
      specialChar: this.password.match(this.atLeastOneSpecialChar),
      charsOrGreater: this.password.match(this.charsOrMore),
    }
  }

  @computed
  public get newPasswordPolicyMonitorObject(): IPasswordPolicyMonitor {
    const s = this.localizationStore.lzStrings.profile
    return {
      charsOrGreater: {
        isValid: Boolean(this.password.match(this.charsOrMore)),
        info: `${s.at_least} ${this.PW_POLICY_CHARS_MIN} ${s.characters}`,
      } as IPasswordPolicyInfo,
      uppercase: {
        isValid: Boolean(this.password.match(this.atLeastOneUppercase)),
        info: `${s.one} ${s.uppercase_letter}`,
      } as IPasswordPolicyInfo,
      lowercase: {
        isValid: Boolean(this.password.match(this.atLeastOneLowercase)),
        info: `${s.one} ${s.lowercase_letter}`,
      } as IPasswordPolicyInfo,
      number: {
        isValid: Boolean(this.password.match(this.atLeastOneNumeric)),
        info: `${s.one} ${s.number}`,
      } as IPasswordPolicyInfo,
      specialChar: {
        isValid: Boolean(this.password.match(this.atLeastOneSpecialChar)),
        info: `${s.one} ${s.special_character}`,
      } as IPasswordPolicyInfo,
    } as IPasswordPolicyMonitor
  }

  @computed
  public get newPasswordStrengthScore(): number {
    return Object.values(this.newPasswordPolicyMonitor).filter((value) => value).length
  }

  @action
  public setPasswordMeterShown(isPasswordMeterShown: boolean) {
    if (this.password) return
    this.isPasswordMeterShown = isPasswordMeterShown
  }

  @computed
  public get isNewPasswordStrong(): boolean {
    if (this.newPasswordStrengthScore < 5) return false
    return true
  }
}
