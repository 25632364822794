import React from 'react'
import { CardHeader, IconButton, Menu, MenuItem } from '@material-ui/core'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'
import SurveyIcon from '@material-ui/icons/LiveHelpOutlined'
import { inject, observer } from 'mobx-react'
import { EventsStore } from '../../../store/EventsStore'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import LocalizationStore from '../../../../localization/LocalizationStore'
import FileCopyIcon from '@material-ui/icons/FileCopyRounded'

interface Props {
  eventsStore?: EventsStore
  localizationStore?: LocalizationStore
}
const UserEventPopupToolbar: React.FC<Props> = ({ eventsStore, localizationStore }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { userEventPopupVM: vm } = eventsStore
  const s = localizationStore.lzStrings.userEvent

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const renderAllGoingOption = () => {
    if (vm.isFakeEvent) return null
    return (
      <MenuItem onClick={() => vm.handleMarkAllGoing()}>
        <AssignmentTurnedInIcon className='icon' /> {s.mark_all_as_going}
      </MenuItem>
    )
  }

  const renderCopyEventOption = () => {
    return (
      <MenuItem onClick={() => vm.copy()}>
      <FileCopyIcon className='icon' /> {s.copy_event}
    </MenuItem>
    )
  }

  const renderMenu = () => {
    if (!vm.editable) return null
    return (
      <>
        <IconButton
          aria-controls='simple-menu'
          size='small'
          aria-haspopup='true'
          onClick={handleClick}
        >
          <MoreHorizIcon fontSize='small' />
        </IconButton>
        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          style={{ marginTop: 40 }}
        >
          {renderAllGoingOption()}
          {renderCopyEventOption()}
        </Menu>
      </>
    )
  }

  const renderEditAndDelete = () => {
    if (!vm.editable) return null
    return (
      <div className='owner-icons'>
        <div>
          <IconButton size='small' onClick={() => vm.createSurvey()}>
            <SurveyIcon fontSize='small' />
          </IconButton>
        </div>
        <div>
          <IconButton size='small' onClick={() => vm.edit()}>
            <EditOutlinedIcon fontSize='small' />
          </IconButton>
          <IconButton size='small' onClick={() => vm.delete()}>
            <DeleteOutlinedIcon fontSize='small' />
          </IconButton>
        </div>
      </div>
    )
  }

  return (
    <CardHeader
      action={
        <>
          {renderMenu()}
          {renderEditAndDelete()}
          <IconButton size='small' onClick={() => vm.close()}>
            <CloseOutlinedIcon />
          </IconButton>
        </>
      }
    />
  )
}

export default inject('eventsStore', 'localizationStore')(observer(UserEventPopupToolbar))
