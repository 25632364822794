import { action, computed, observable, when } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import SurveyTemplateFolder from '../aggregate/SurveyTemplateFolder'
import { SurveyTemplateFoldersService } from '../services/SurveyTemplateFoldersService'
import { SurveyTemplateFoldersPickerVM } from './SurveyTemplateFoldersPickerVM'

export class SurveyTemplateFolderEditVM {
  private rootStore: RootStore

  constructor(
    rootStore: RootStore,
    pickerVM: SurveyTemplateFoldersPickerVM,
    folder: SurveyTemplateFolder
  ) {
    this.rootStore = rootStore
    this.folder = folder
    this.pickerVM = pickerVM
  }

  @observable public pickerVM: SurveyTemplateFoldersPickerVM = null
  @observable public folder: SurveyTemplateFolder = null
  @observable public saveTried: Boolean = false
  @observable public isSaving: Boolean = false

  @computed
  public get objectId(): string {
    return this.folder.objectId
  }

  @computed
  public get parentFolderId(): string {
    return this.folder.parentFolderId
  }

  @computed
  public get nameValid(): boolean {
    if (!this.saveTried) return true
    if (!this.folder.name || this.folder.name === '') return false
    return true
  }

  @computed
  public get isValid(): boolean {
    if (!this.nameValid) return false
    return true
  }

  @computed
  public get name(): string {
    return this.folder.name
  }

  @action
  public setName(name: string) {
    this.folder.setName(name)
  }

  @action
  public async closeEditFolder() {
    this.pickerVM.closeEditFolder()
  }

  @action
  public async onKeyDown(e) {
    if (e.key === 'Escape') {
      this.closeEditFolder()
    }
    if (e.key === 'Enter') {
      this.save()
    }
  }

  @action
  public async save() {
    this.saveTried = true
    if (!this.isValid) return
    let orgId = this.rootStore.appStore.currentOrgId
    if (this.pickerVM.manageVM.screenType  === 'systemManage') orgId = 'DEFAULT'
    this.closeEditFolder()
    const svc = new SurveyTemplateFoldersService(this.rootStore)
    const result = await svc.saveSurveyTemplateFolder(this.folder.serialize(), orgId)
    if (result.success) {
      this.pickerVM.manageVM.tableVM.openSnackbar('Success: Re-building folder tree.')
      this.pickerVM.dataStore.loadListRecords()
    } else {
      console.log(result.errorMessage)
      this.pickerVM.manageVM.tableVM.openSnackbar(`Error: Something went wrong.`)
    }
  }
}
