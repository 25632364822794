import React from 'react'
import { OrganizationsSAStore } from '../../store/OrganizationsSAStore'
import LocalizationStore from '../../../localization/LocalizationStore'
import { inject, observer } from 'mobx-react'
import { Paper, Grid, InputLabel, TextField, Typography } from '@material-ui/core'
import './OrganizationEditDrawer.scss'

interface Props {
  organizationsSAStore?: OrganizationsSAStore
  localizationStore?: LocalizationStore
}

const OrganizationLabels: React.FC<Props> = ({ organizationsSAStore, localizationStore }) => {
  const { editVM: vm } = organizationsSAStore
  const { lzStrings } = localizationStore
  if (!vm) return null

  const renderTitleBar = () => {
    return (
      <Grid container className='title-bar'>
        <Grid item lg={12} xs={12} className='title-row'>
          <Typography className='title-text'>Selector</Typography>
          <Typography className='title-text'>Language</Typography>
          <Typography className='title-text'>Is Default</Typography>
          <Typography className='title-value'>Value</Typography>
        </Grid>
      </Grid>
    )
  }

  const renderLabels = () => {
    return vm.labels.map(label => {
      return (
        <div key={label.objectId}>
          <Grid container>
            <Grid item lg={12} xs={12} className='label-row'>
              <Typography className='label-text'>{label.selector}</Typography>
              <Typography className='label-text'>{label.language}</Typography>
              <Typography className='label-text'>{label.isDefault ? 'DEFAULT' : '--'}</Typography>
              <Grid container>
                <Grid item lg={12} xs={12} className='label-value'>
                  <TextField
                    variant='outlined'
                    value={label.value}
                    onChange={e => label.setLabel(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )
    })
  }

  return (
    <div id='OrgLabelsTab'>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <Paper elevation={3} className='labels-paper'>
            {renderTitleBar()}

            {renderLabels()}
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default inject('organizationsSAStore', 'localizationStore')(observer(OrganizationLabels))
