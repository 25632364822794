import Parse from 'parse'
import { computed } from 'mobx'
import { Question } from '../aggregates/Question'
import { IQuestionDTO } from '../dtos/IQuestionDTO'
import { DataStore } from '../../shared/data/DataStore'
import { RootStore } from '../../stores/RootStore'
import { QuestionCatalogWidgetVM } from './QuestionCatalogWidgetVM'
import { nameOf } from '../../shared/nameOf'

export class QuestionCatalogWidgetDataStore extends DataStore<Question, IQuestionDTO> {
  private parentVM: QuestionCatalogWidgetVM

  constructor(rootStore: RootStore, widgetVM: QuestionCatalogWidgetVM) {
    super(rootStore, Question, 'questions', [
      nameOf<IQuestionDTO, string>((e) => e.objectId),
      nameOf<IQuestionDTO, string>((e) => e.organizationId),
      nameOf<IQuestionDTO, string>((e) => e.title),
      nameOf<IQuestionDTO, string>((e) => e.categoryId),
      nameOf<IQuestionDTO, string>((e) => e.surveyTypeId),
      nameOf<IQuestionDTO, string>((e) => e.type),
      nameOf<IQuestionDTO, boolean>((e) => e.isDropdownMultiSelect),
      nameOf<IQuestionDTO, string>((e) => e.placeholder),
    ])
    this.paged = true
    this.recordsPerPage = 100
    this.sortColumnName = nameOf<IQuestionDTO, string>((e) => e.title)
    this.filterColumns = [
      nameOf<IQuestionDTO, string>((e) => e.title),
      nameOf<IQuestionDTO, string>((e) => e.type),
    ]
    this.parentVM = widgetVM
  }

  protected getBaseQuery() {
    let query = new Parse.Query(this.className)
    query.equalTo('organizationId', this.rootStore.appStore.currentOrgId)
    query.notEqualTo('isDeleted', true)
    if (!this.parentVM.typesFilterVM.allChecked) {
      query.containedIn('type', this.parentVM.typesFilterVM.typesToShow.slice())
    }
    if (!this.parentVM.categoryPickerVM.selectAllChecked) {
      query.containedIn('categoryId', this.getCategoryIdsFilter())
    }
    return query
  }

  private getCategoryIdsFilter(): string[] {
    const arr = this.parentVM.categoryPickerVM.selectedCategoryIds
      .slice()
      .map((e) => (e === 'no-category' ? undefined : e))
    if (arr.includes(undefined)) arr.push('')
    if (arr.includes(undefined)) arr.push(null)
    return arr
  }

  @computed
  public get questions() {
    return this.records
  }

  public setTypeFilter(types: string[]) {}
}
