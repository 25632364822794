import React from 'react'
import { observer, useLocalStore } from 'mobx-react'
import { Button, Grid, Tooltip, Typography } from '@material-ui/core'
import './PulseCampaignTemplatesDashboard.scss'
import RowIcon from '@material-ui/icons/FormatListBulleted'
import DashIcon from '@material-ui/icons/Dashboard'
import PulseScreenTopToolbar from '../../dashboard/PulseCampaignsTopToolbar'
import { PulseCampaignTemplatesDashboardVM } from '../../../view-models/dashboard/templates/PulseCampaignTemplatesDashboardVM'
import rootStore from '../../../../stores/RootStore'
import CampaignTemplatesList from './CampaignTemplatesList'


const PulseCampaignTemplatesDashboard: React.FC = () => {
  const localStore = useLocalStore(() => ({
    vm: new PulseCampaignTemplatesDashboardVM(rootStore),
  }))

  const vm = localStore.vm
  const lz = vm.lz
  const labelsStore = vm.labelsStore

  const renderTemplatesDashboard = () => {
    return (
      <div className={'pulse-campaignTemplates-dashboard'}>
        <div className={'campaignTemplates-dashboard-header'}>
          <Typography className={'dashboard-title'}>{lz.templates}</Typography>
          {renderTemplateButtons()}
        </div>
        <div className={'pulse-campaignTemplates-dashboard-content'}>
          <CampaignTemplatesList vm={vm.listVM}/>
        </div>
      </div>
    )
  }

  const renderTemplateButtons = () => {
    return (
      <div className={'template-buttons-right'}>
        {/* <div onClick={() => vm.setTemplateLayout(0)} className={`row-layout-button ${vm.templateLayout === 0 ? 'selected' : ''}`}>
          <RowIcon className={'row-icon'} />
        </div>
        <div onClick={() => vm.setTemplateLayout(1)} className={`tile-layout-button  ${vm.templateLayout === 1 ? 'selected' : ''}`}>
          <DashIcon className={'dash-icon'} />
        </div> */}
        {renderCreateTemplateButton()}
      </div>
    )
  }

  const renderCreateTemplateButton = () => {
    if (!vm.canManage) return null
    return (
      <Tooltip
        title={`${lz.create} ${labelsStore.getLabelByLanguageAndFor('pulse')} ${lz.campaign_template
          }`}
        placement='bottom'
      >
        <span>
          <Button
            onClick={() => vm.createPulseTemplate()}
            className={'empty-state-action-btn'}
            variant={'contained'}
          >
            {lz.create_template}
          </Button>
        </span>
      </Tooltip>
    )
  }

  return (
    <div id='PulseCampaignTemplatesDashboard'>
      <PulseScreenTopToolbar />
      {renderTemplatesDashboard()}
    </div>
  )
}

export default observer(PulseCampaignTemplatesDashboard)
