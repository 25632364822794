import { action, computed, observable, reaction, runInAction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import moment from 'moment'
import { ParticipantVM } from '../../participants-select/view-models/ParticipantVM'
import { ParticipantsSelectVM } from '../../participants-select/view-models/ParticipantsSelectVM'
import { CalendarItemsFindService } from '../services/CalendarItemsFindService'
import { BigCalendarViewModeType } from '../types/BigCalendarViewModeType'
import { IEventPillVM } from '../intefaces/IEventPillVM'
import { ExportVM } from './ExportVM'
import { Views } from 'react-big-calendar'

export class CalendarVM {
  private rootStore: RootStore
  private calItemsFindSvc: CalendarItemsFindService
  private navTo: NodeJS.Timeout

  constructor(rootStore) {
    this.rootStore = rootStore
    this.allViews = Object.values(Views)
    this.participantsSelectVM = new ParticipantsSelectVM(rootStore, false, false)
    this.calItemsFindSvc = new CalendarItemsFindService(this.rootStore)
    this.registerReactions()
  }

  private registerReactions() {
    if (process.env.NODE_ENV === 'test') return
    reaction(
      () => this.selectedDate,
      () => this.loadFormattedEvents(),
      { delay: 1500 }
    )
    reaction(
      () => this.isCalendarSurveysChecked,
      () => this.loadFormattedEvents(),
      { delay: 1500 }
    )
    reaction(
      () => this.isCalendarTasksChecked,
      () => this.loadFormattedEvents(),
      { delay: 1500 }
    )
    reaction(
      () => this.isCalendarEventsChecked,
      () => this.loadFormattedEvents(),
      { delay: 1500 }
    )
    reaction(
      () => this.isCalendarTrainingsChecked,
      () => this.loadFormattedEvents(),
      { delay: 1500 }
    )
    reaction(
      () => this.rootStore.userEventsStore.currentOrgUserEvents.length,
      () => this.loadFormattedEvents(),
      { delay: 1500 }
    )
    reaction(
      () => this.selectedOtherCalendars.length,
      () => this.loadFormattedEvents(),
      { delay: 1500 }
    )
    reaction(
      () => this.isMyCalendarChecked,
      () => this.loadFormattedEvents(),
      { delay: 1500 }
    )
  }

  @observable public exportVM: ExportVM = null
  @observable public allViews: Array<string>
  @observable public viewMode: BigCalendarViewModeType = 'month'
  @observable public displayedDate: Date = new Date()
  @observable public selectedDate: Date = new Date()
  @observable public calendarMin: Date = moment().startOf('day').hour(9).toDate()
  @observable public calendarMax: Date = moment().startOf('day').hour(17).toDate()
  @observable public isEventsDrawerOpen: boolean = false
  @observable public selectedOtherCalendars: ParticipantVM[] = []
  @observable public participantsSelectVM: ParticipantsSelectVM = null
  @observable public viewMenuAnchorEl: any = null
  @observable public isMyCalendarChecked: boolean = true
  @observable public isCalendarEventsChecked: boolean = true
  @observable public isCalendarTasksChecked: boolean = false
  @observable public isCalendarSurveysChecked: boolean = false
  @observable public isCalendarTrainingsChecked: boolean = false
  @observable public formattedEvents: IEventPillVM[] = []
  @observable public loading: boolean = true

  @action
  private async loadFormattedEvents() {
    this.loading = true
    this.formattedEvents = await this.calItemsFindSvc.getCalendarPills(
      this.isMyCalendarChecked,
      this.isCalendarEventsChecked,
      this.isCalendarSurveysChecked,
      this.isCalendarTasksChecked,
      this.isCalendarTrainingsChecked,
      this.selectedOtherCalendars,
      this.selectedStartDateMin,
      this.selectedStartDateMax
    )
    this.loading = false
  }

  @computed
  public get selectedStartDateMin(): Date {
    return moment(this.selectedDate).startOf('month').add(-7, 'days').toDate()
  }

  @computed
  public get selectedStartDateMax(): Date {
    return moment(this.selectedDate).endOf('month').add(7, 'days').toDate()
  }

  @computed
  public get selectedMonthUserEvents() {
    return this.rootStore.userEventsStore.currentOrgUserEvents.filter(
      (e) =>
        e.localStartDate >= this.selectedStartDateMin &&
        e.localStartDate <= this.selectedStartDateMax
    )
  }

  @computed
  public get selectedMonthEvents() {
    return this.rootStore.eventsStore.currentOrgEvents.filter(
      (e) =>
        e.localStartDate >= this.selectedStartDateMin &&
        e.localStartDate <= this.selectedStartDateMax
    )
  }

  public eventPropGetter(event: IEventPillVM, start: Date, end: Date, isSelected: boolean) {
    if ((isSelected && event.userEvent) || (isSelected && event.event)) {
      return { className: 'selectedEvent event-pill-event' }
    }
    if (isSelected && event.userSurvey) return { className: 'selectedSurvey event-pill-survey' }
    if (isSelected && event.userTask) {
      if (moment().isAfter(moment(end).endOf('day')) && !event.userTask.isCompleted)
        return { className: 'selectedTask event-pill-task-due' }
      return { className: 'selectedTask event-pill-task' }
    }
    if (isSelected && event.trainingItem) {
      return { className: `selectedTrainingItem event-pill-trainings` }
    }
    if (isSelected && event.userTrainingPlan)
      return { className: `selectedTrainingPlan event-pill-trainings` }
    if (event.event || event.userEvent)
      return { className: `event-pill-container-${event.calendarIndex} event-pill-event` }
    if (event.userSurvey)
      return { className: `event-pill-container-${event.calendarIndex} event-pill-survey` }
    if (event.userTrainingPlan)
      return { className: `event-pill-container-${event.calendarIndex} event-pill-trainings` }
    if (event.trainingItem)
      return { className: `event-pill-container-${event.calendarIndex} event-pill-trainings` }
    if (event.userTask) {
      if (moment().isAfter(moment(end).endOf('day')) && !event.userTask.isCompleted)
        return { className: `event-pill-container-${event.calendarIndex} event-pill-task-due` }
      return { className: `event-pill-container-${event.calendarIndex} event-pill-task` }
    }
  }

  @action
  public openEvent(event: IEventPillVM) {
    this.rootStore.eventsStore.loadPopup(event)
  }

  @action
  public refresh() {
    this.debounceSelectedDate(moment(this.selectedDate).add(1, 'second').toDate())
  }

  @action
  public handleSlotSelected(selectedObj) {
    switch (selectedObj.action) {
      case 'select':
        if (this.viewMode === 'month') {
          this.openNewEvent(selectedObj.start, selectedObj.end)
        } else {
          this.openNewEvent(selectedObj.start, selectedObj.end)
        }
        break
      case 'click':
        if (this.viewMode === 'month') {
          this.openNewEvent(selectedObj.start, selectedObj.end)
        } else {
          this.openNewEvent(selectedObj.start, selectedObj.end)
        }
        break
    }
  }

  @action
  public toggleCalendarTasks() {
    this.isCalendarTasksChecked = !this.isCalendarTasksChecked
  }

  @action
  public toggleCalendarSurveys() {
    this.isCalendarSurveysChecked = !this.isCalendarSurveysChecked
  }

  @action
  public toggleCalendarTrainings() {
    this.isCalendarTrainingsChecked = !this.isCalendarTrainingsChecked
  }

  @action
  public toggleCalendarEvents() {
    this.isCalendarEventsChecked = !this.isCalendarEventsChecked
  }

  @action
  public openExportModal() {
    this.exportVM = new ExportVM(this.rootStore)
  }

  @action
  public setViewMode(mode: string) {
    this.closeViewMenu()
    this.viewMode = mode as BigCalendarViewModeType
  }

  @computed
  public get viewModeName(): string {
    return this.viewMode.replace('_', ' ')
  }

  @action
  public onNavigate(date: Date) {
    this.loading = true
    this.displayedDate = date
    this.debounceSelectedDate(date)
  }

  @action
  private debounceSelectedDate(date) {
    this.formattedEvents = []
    this.loading = true
    if (this.navTo) clearTimeout(this.navTo)
    this.navTo = setTimeout(
      () => requestAnimationFrame(() => runInAction(() => (this.selectedDate = date))),
      10
    )
  }

  @computed
  public get visibleStartDate(): any {
    if (this.viewMode === 'month') return moment(this.selectedDate).startOf('month').toDate()
    if (this.viewMode === 'week') return moment(this.selectedDate).startOf('week').toDate()
    if (this.viewMode === 'work_week')
      return moment(this.selectedDate).startOf('week').add(1, 'day').toDate()
    if (this.viewMode === 'day' || this.viewMode === 'agenda')
      return moment(this.selectedDate).startOf('day').toDate()
  }

  @computed
  public get visibleEndDate(): Date {
    if (this.viewMode === 'month') return moment(this.selectedDate).endOf('month').toDate()
    if (this.viewMode === 'week') return moment(this.selectedDate).endOf('week').toDate()
    if (this.viewMode === 'work_week')
      return moment(this.selectedDate).endOf('week').subtract(1, 'day').toDate()
    if (this.viewMode === 'day' || this.viewMode === 'agenda')
      return moment(this.selectedDate).endOf('day').toDate()
  }

  @action public closeCalendarEventDrawer = () => {
    this.isEventsDrawerOpen = false
    if (this.rootStore && this.rootStore.eventsStore) {
      this.rootStore.eventsStore.destroyEventEditVM()
    }
  }

  @computed
  public get availableOtherCalendars() {
    return this.participantsSelectVM.availableParticipants
      .filter((participant) => participant.id !== this.rootStore.appStore.currentUserId)
      .sort((a, b) => (a.sortNameWithType < b.sortNameWithType ? -1 : 0))
  }

  @action
  public setSelectedOtherCalendars(arr: Array<ParticipantVM>) {
    this.selectedOtherCalendars = arr
  }

  @computed
  public get viewMenuShown(): boolean {
    return this.viewMenuAnchorEl !== null
  }

  @action
  public openViewMenu(e: any) {
    this.viewMenuAnchorEl = e
  }

  @action
  public closeViewMenu() {
    this.viewMenuAnchorEl = null
  }

  @action
  public createNewEvent() {
    this.openNewEvent(this.selectedDate, this.selectedDate)
  }

  @action
  public openNewEvent(startDate: Date, endDate: Date) {
    this.rootStore.eventsStore.lazyLoadEventEditVM('new', startDate, endDate)
    this.isEventsDrawerOpen = true
  }

  @action
  public openEventDrawer() {
    this.isEventsDrawerOpen = true
  }

  @computed
  public get displayedTimeFrame(): string {
    const s = this.rootStore.localizationStore.lzStrings.calendar
    if (this.viewMode === 'month') return moment(this.displayedDate).format(s.view_mode_month)
    if (this.viewMode === 'week') return moment(this.displayedDate).format(s.view_mode_week)
    if (this.viewMode === 'work_week')
      return moment(this.displayedDate).format(s.view_mode_work_week)
    if (this.viewMode === 'day') return moment(this.displayedDate).format(s.view_mode_day)
    if (this.viewMode === 'agenda') return moment(this.displayedDate).format(s.view_mode_agenda)
    return ''
  }

  @action
  public goPrevious() {
    this.loading = true
    let newDate
    if (this.viewMode === 'month') {
      newDate = moment(this.displayedDate).add(-1, 'month').toDate()
    } else if (this.viewMode === 'week' || this.viewMode === 'work_week') {
      newDate = moment(this.displayedDate).add(-1, 'week').toDate()
    } else {
      newDate = moment(this.displayedDate).add(-1, 'day').toDate()
    }
    this.displayedDate = newDate
    this.debounceSelectedDate(newDate)
  }

  @action
  public goNext() {
    this.loading = true
    let newDate
    if (this.viewMode === 'month') {
      newDate = moment(this.displayedDate).add(1, 'month').toDate()
    } else if (this.viewMode === 'week' || this.viewMode === 'work_week') {
      newDate = moment(this.displayedDate).add(1, 'week').toDate()
    } else {
      newDate = moment(this.displayedDate).add(1, 'day').toDate()
    }
    this.displayedDate = newDate
    this.debounceSelectedDate(newDate)
  }

  @action
  public goToday() {
    this.displayedDate = new Date()
    this.selectedDate = new Date()
  }

  @action
  public toggleMyCalendar() {
    this.isMyCalendarChecked = !this.isMyCalendarChecked
  }

  @computed
  public get userName(): string {
    if (!this.rootStore.userStore) return ''
    if (!this.rootStore.userStore.user) return ''
    return this.rootStore.userStore.user.name
  }

  @computed
  public get culture(): string {
    if (!this.rootStore.userStore.loaded) return this.rootStore.localizationStore.browserLocale
    if (!this.rootStore.userStore.user) return this.rootStore.localizationStore.browserLocale
    return this.rootStore.userStore.user.culture
  }
}
