import React from 'react'
import { Route } from 'react-router'
import Layout from '../../layout/Layout'
import { authenticate } from '../../auth/routes/AuthenticatedRoute'
import SurveyEditScreen from '../../surveys/views/edit/SurveyEditScreen'

export default function getSurveyTemplateRoutes() {
  return (
    <Route key={'st0'} component={authenticate(Layout)} path='/'>
      <Route
        key={'st1'}
        path='surveyTemplates/edit/:surveyTemplateId'
        component={SurveyEditScreen}
      />
      <Route
        key={'st2'}
        path='surveyTemplates/edit/:surveyTemplateId/:orgId/:folderId'
        component={SurveyEditScreen}
      />
      <Route
        key={'st3'}
        path='surveyTemplates/edit/:surveyTemplateId/:orgId'
        component={SurveyEditScreen}
      />
    </Route>
  )
}
