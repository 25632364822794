import { RootStore } from '../../stores/RootStore'
import { observable, computed, action } from 'mobx'
import { CalendarVM } from './CalendarVM'
import moment from 'moment'
import { IEventPillVM } from '../intefaces/IEventPillVM'
import { UserTrainingPlan } from '../../training-plans/user-training-plans/aggregate/UserTrainingPlan'

export class TrainingItemPillVM implements IEventPillVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, trainingItem, parentTrainingPlanId: string) {
    this.rootStore = rootStore
    this.parentId = parentTrainingPlanId
    this.trainingItem = trainingItem
  }

  @observable public trainingItem = null
  @observable public parentId: string = ''

  @computed
  public get title(): string {
    return this.trainingItem.name
  }

  @computed
  public get localStartDate(): Date {
    if (this.trainingItem.dueDate === null) return new Date()
    return moment(this.trainingItem.dueDate).toDate()
  }

  @computed
  public get localEndDate(): Date {
    if (this.trainingItem.dueDate === null) return new Date()
    return moment(this.trainingItem.dueDate).toDate()
  }

  @computed
  public get allDay(): boolean {
    return this.trainingItem.hasDueDate === null
  }

  @computed
  public get objectId(): string {
    return this.trainingItem.id
  }

  @computed
  public get calendarVM(): CalendarVM {
    return this.rootStore.eventsStore.calendarVM
  }

  @computed
  public get calendarIndex(): number {
    const idx = this.calendarVM.selectedOtherCalendars.findIndex(
      (e) => e.id === this.trainingItem.id
    )
    if (idx === -1) return 0
    return idx + 1
  }
}
