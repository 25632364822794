import React from 'react'
import { inject, observer } from 'mobx-react'
import { UserTasksStore } from '../../store/UserTasksStore'
import { CardContent, Avatar, Tooltip } from '@material-ui/core'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import { CompletedUserTaskRowVM } from '../../view-models/CompletedUserTaskRowVM'
import AGGridWidgetPagingRow from '../../../shared/ag-grid/AGGridPagingRow'
import AGGridUserCell from '../../../shared/ag-grid/AGGridUserCell'

interface IWidgetContent {
  userTasksStore?: UserTasksStore
}
const WidgetContent: React.FC<IWidgetContent> = ({ userTasksStore }) => {
  const vm = userTasksStore.completedUserTasksWidgetVM
  if (!vm) return null
  if (!vm.shouldRender) return null

  const renderOwnerCell = (props) => {
    const row: CompletedUserTaskRowVM = props.data
    if (!row.assignedByUser) return null
    return <AGGridUserCell userId={row.assignedByUserId} name={row.assignedByUserName} iconURL={row.assignedByUserIconUrl}/>
  }

  const renderGrid = () => {

    return (
      <div className='ag-theme-alpine' style={{ height: '100%', width: '100%' }}>
        <AgGridReact gridOptions={{
          ...vm.getGridOptions(),
          frameworkComponents: {
            creatorCell: renderOwnerCell,
          },
        }} />
      </div>
    )
  }

  return (
    <CardContent>
      {renderGrid()}
      <AGGridWidgetPagingRow vm={vm} />
    </CardContent>
  )
}

export default inject('userTasksStore')(observer(WidgetContent))
