import { RootStore } from '../../stores/RootStore'
import Parse from 'parse'
import axios from 'axios'
import { IUserSurveyDTO } from '../dtos/IUserSurveyDTO'
import env from '../../../env'
import { IAlwaysAvailableUserSurveysResult } from '../interfaces/IAlwaysAvailableUserSurveysResult'
import { IUserSurveysListRequest } from '../interfaces/IUserSurveysListRequest'
import { IUserSurveysListResult } from '../interfaces/IUserSurveysListResult'

export class UserSurveysService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public async findByToken(token: string, isLoggedIn?: boolean): Promise<IUserSurveyDTO> {
    const url = `${
      env.var.REACT_APP_API_URL
    }/userSurveys/find?token=${token}&application=${'web'}&isLoggedIn=${!!isLoggedIn}`
    const response = await axios.get(url)
    if (!response) return null
    if (!response.data) return null
    if (!response.data.data) return null
    if (!response.data.data.success) return null
    return response.data.data.userSurvey
  }

  public async markAsRead(userSurveyId: string, isLoggedIn: boolean) {
    const url = `${
      env.var.REACT_APP_API_URL
    }/userSurveys/markAsRead?userSurveyId=${userSurveyId}&application=${'web'}&isLoggedIn=${isLoggedIn}`

    const response = await axios.get(url)
    if (!response) return null
    if (!response.data) return null
    if (!response.data.data) return null
    if (!response.data.data.success) return null
    return response.data.data.userSurvey
  }

  public async markUserSurveyAsRead(userSurveyId, orgId, isLoggedIn) {
    if (process.env.NODE_ENV === 'test') return
    const application = 'web'
    await Parse.Cloud.run('markUserSurveyAsRead', {
      userSurveyId,
      orgId,
      application,
      isLoggedIn,
    })
  }

  public async getUserSurveyFromAlwaysAvailable(surveyId) {
    const result = await Parse.Cloud.run('getUserSurveyFromAlwaysAvailable', {
      userId: this.rootStore.appStore.currentUserId,
      surveyId: surveyId,
      orgId: this.rootStore.appStore.currentOrgId,
    })
    return result
  }

  public async getActiveSurveys(): Promise<IUserSurveyDTO[]> {
    const result = await Parse.Cloud.run('getUsersActiveSurveys', {
      userId: this.rootStore.appStore.currentUserId,
      orgId: this.rootStore.appStore.currentOrgId,
    })
    return result
  }

  public async getUserSurveysList(
    request: IUserSurveysListRequest
  ): Promise<IUserSurveysListResult> {
    return await Parse.Cloud.run('getUserSurveysList', { request })
  }

  public async getAlwaysAvailableSurveys(): Promise<IAlwaysAvailableUserSurveysResult> {
    const result = await Parse.Cloud.run('getAlwaysAvailableUserSurveys', {
      userId: this.rootStore.appStore.currentUserId,
      orgId: this.rootStore.appStore.currentOrgId,
    })
    return result
  }

  async markUserSurveyAsInactive(objectId: string) {
    const result = await Parse.Cloud.run('markUserSurveyAsInactive', {
      orgId: this.rootStore.appStore.currentOrgId,
      userSurveyId: objectId,
    })
    return result
  }
}
