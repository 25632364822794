import { RootStore } from '../../stores/RootStore'
import { action, computed, observable } from 'mobx'
import { SurveyTemplatesTableVM } from './SurveyTemplatesTableVM'
import { ISurveyTemplatesFindRequest } from '../interfaces/ISurveyTemplatesFindRequest'
import { SurveyTemplatesService } from '../services/SurveyTemplatesService'
import { SurveyTemplateFoldersPickerVM } from '../../survey-template-folders/view-models/SurveyTemplateFoldersPickerVM'
import { ISurveyTemplateFoldersFindRequest } from '../../survey-template-folders/interfaces/ISurveyTemplateFoldersFindRequest'
import { Organization } from '../../organizations-sa/aggregate/Organization'
import { ScreenType } from '../types/ScreenType'
import SurveyTemplateFolder from '../../survey-template-folders/aggregate/SurveyTemplateFolder'
import { ISurveyTemplatePermissionsUpdateRequest } from '../interfaces/ISurveyTemplatePermissionsUpdateRequest'

interface SurveyTemplateFolderForPicker {
  id: string
  name: string
  parentId: string
  children: SurveyTemplateFolderForPicker[]
}

export class SurveyTemplatesManageVM {
  private rootStore: RootStore
  public svc: SurveyTemplatesService

  constructor(rootStore: RootStore, screenType: ScreenType) {
    this.rootStore = rootStore
    this.screenType = screenType
    if (screenType === 'permissions') {
      this.isPermissions = true
      this.loadDefaultOrg()
    }
    this.svc = new SurveyTemplatesService(this.rootStore)
    this.loadSurveyTemplatesTable()
    this.loadSurveyTemplateFoldersPicker()
  }

  @observable public screenType: ScreenType = null
  @observable public tableVM: SurveyTemplatesTableVM
  @observable public foldersVM: SurveyTemplateFoldersPickerVM
  @observable public isMovingTemplatesToFolder: boolean = false
  @observable public moveToFolder: SurveyTemplateFolderForPicker = null
  @observable public selectedOrg: Organization = null
  @observable public isPermissions: boolean = false
  @observable public isLoadingOrg: boolean = false
  @observable public isSavingSubscriptions: boolean = false

  @action
  public setSelectedOrg(org) {
    this.selectedOrg = org
    this.resetTable()
  }

  @action
  public resetTable() {
    if (!this.tableVM) return 
    this.tableVM.hardRefreshTemplatesList()
  }

  @computed
  public get permissionActionsDisabled() {
    if (this.isLoadingOrg || this.isSavingSubscriptions) return true
    return false
  }

  @computed
  public get availableOrgs() {
    return this.rootStore.organizationsSAStore.organizations
  }

  @computed
  public get selectedFolder(): SurveyTemplateFolder {
    return this.foldersVM.validSurveyTemplateFolders.find(
      (e) => e.objectId === this.foldersVM.selectedFolder.id
    )
  }

  @action
  private loadDefaultOrg(attempts: number = 0) {
    this.isLoadingOrg = true
    if (attempts === 14) return
    if (!this.availableOrgs.length) return setTimeout(() => this.loadDefaultOrg(++attempts), 1000)
    const foundOrg = this.rootStore.organizationsSAStore.organizations.find(
      (e) => e.objectId === this.rootStore.appStore.currentOrgId
    )
    this.setSelectedOrg(foundOrg)
    this.isLoadingOrg = false
  }

  @action
  public async resetSubscriptionsTable() {
    this.tableVM.hardRefreshTemplatesList()
  }

  @action
  public getDefaultFolderRequest(): ISurveyTemplateFoldersFindRequest {
    let orgId = 'DEFAULT'
    if (this.screenType === 'tenantManage') orgId = this.rootStore.appStore.currentOrgId
    const req = {
      organizationId: orgId,
    } as ISurveyTemplateFoldersFindRequest
    return req
  }

  @action
  public getDefaultTemplatesRequest(): ISurveyTemplatesFindRequest {
    let orgId = 'DEFAULT'
    if (this.screenType === 'tenantManage') orgId = this.rootStore.appStore.currentOrgId
    const req = {
      organizationId: orgId,
    } as ISurveyTemplatesFindRequest
    return req
  }

  @action
  private loadSurveyTemplateFoldersPicker() {
    this.foldersVM = new SurveyTemplateFoldersPickerVM(
      this.rootStore,
      this,
      this.getDefaultFolderRequest()
    )
    if (this.screenType === 'tenantManage') {
      this.foldersVM.isSystemToggleMode = false
    }
  }

  @action
  private loadSurveyTemplatesTable() {
    this.tableVM = new SurveyTemplatesTableVM(
      this.rootStore,
      this,
      this.getDefaultTemplatesRequest()
    )
  }

  @action
  public getTemplatesByFolder(id: string) {
    let folderId = null
    if (id !== 'no-folder') folderId = id
    let orgId = 'DEFAULT'
    if (!this.foldersVM.isSystemToggleMode) orgId = this.rootStore.appStore.currentOrgId
    const request = this.tableVM.dataStore.getRequest()
    request.organizationId = orgId
    request.folderId = folderId
    this.tableVM.dataStore.setRequest(request)
    this.tableVM.applyFilter()
  }

  @action
  public moveTemplatesToFolder(node) {
    this.moveToFolder = node
    this.tableVM.toggleMoveTemplatesConfirmDialog()
  }

  @action
  public navigateToCreateSurveyTemplate() {
    let orgId = 'DEFAULT'
    let folderId = this.foldersVM.selectedFolder.id
    // if (folderId === 'no-folder') folderId = null
    if (this.screenType === 'tenantManage') orgId = this.rootStore.appStore.currentOrgId
    this.rootStore.appStore.router.push(`/surveyTemplates/edit/new/${orgId}/${folderId}`)
  }

  @action
  public navigateToImportSurveyTemplates() {
    this.rootStore.appStore.router.push('/systemAdmin/manage/surveyTemplates/import')
  }

  @action
  public navigateToSurveyTemplatesPermissions() {
    this.rootStore.appStore.router.push('/systemAdmin/manage/surveyTemplates/permissions')
  }

  @action
  public navigateToSurveyTemplatesScreen() {
    this.rootStore.appStore.router.push(`/systemAdmin/manage/surveyTemplates`)
  }

  @action
  public async saveSubscriptions() {
    this.isSavingSubscriptions = true
    let selectedTemplateIds = []
    let unselectedTemplates = []
    if (!this.tableVM.selectAll) selectedTemplateIds = this.tableVM.getTemplateIds()
    if (this.tableVM.selectAll && this.tableVM.dialogCount !== this.tableVM.totalRecords) {
      unselectedTemplates = await this.tableVM.getUnselectedTemplates()
    }
    let selectedFolder = null
    if (this.selectedFolder) selectedFolder = this.selectedFolder.serialize()
    const req: ISurveyTemplatePermissionsUpdateRequest = {
      orgId: this.selectedOrg.objectId,
      folderId: selectedFolder?.objectId,
      allSelected: this.tableVM.selectAll,
      selectedTemplateIds: selectedTemplateIds,
      unselectedTemplateIds: unselectedTemplates,
    }
    const folderSvc = new SurveyTemplatesService(this.rootStore)
    const result = await folderSvc.saveSurveyTemplatePermissions(req)
    if (result.success === true) {
      this.tableVM.openSnackbar(
        `Success: Templates have been subscribed to ${this.selectedOrg.name}.`
      )
      this.tableVM.hardRefreshTemplatesList()
    } else if (result.success === false) {
      console.log(result.errorMessage)
      this.tableVM.openSnackbar(`Error: Something went wrong.`)
    }
    this.isSavingSubscriptions = false
  }
}
