import { Grid, Typography } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import React from 'react'
import QuizBuilder from '../../../../quiz/views/QuizBuilder'
import { TrainingPlansStore } from '../../../../training-plans/store/TrainingPlansStore'
import './CompletionTabPanel.scss'
import CompletionToolbar from './CompletionToolbar'

interface Props {
  trainingPlansStore?: TrainingPlansStore
}

const CompletionTabPanel: React.FC<Props> = ({ trainingPlansStore }) => {
  const { trainingPlanEditVM: vm } = trainingPlansStore

  const renderBody = () => {
    if (vm.requireQuiz) {
      return <QuizBuilder quiz={vm.quiz} />
    } else {
      return (
        <Grid container spacing={0} id='NoPOCSelected'>
          <Typography variant='h5'>Goal Preview:</Typography>
        </Grid>
      )
    }
  }

  return (
    <Grid
      container
      direction='row'
      id='CompletionTabPanel'
      style={vm.editIsDisabled ? { pointerEvents: 'none' } : {}}
    >
      <CompletionToolbar />

      {renderBody()}
    </Grid>
  )
}

export default inject('trainingPlansStore')(observer(CompletionTabPanel))
