import { observable } from 'mobx'
import { RootStore } from '../../stores/RootStore'

export default class SharedSnackbarVM {
  rootStore: RootStore

  constructor(rootStore: RootStore, shared: boolean) {
    this.rootStore = rootStore
    this.shared = shared
  }

  @observable public shared: boolean = false

}
