import { observable, computed, action } from 'mobx'
import moment from 'moment'
import { RootStore } from '../../../../stores/RootStore'
import { PulseSurveySchedule } from '../../../aggregate/PulseSurveySchedule'

export class UpcomingPulseRowVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, pulseSurveySchedule: PulseSurveySchedule) {
    this.rootStore = rootStore
    this.pulseSurveySchedule = pulseSurveySchedule
  }

  @observable public pulseSurveySchedule: PulseSurveySchedule = null

  @computed
  public get objectId(): string {
    return this.pulseSurveySchedule.surveyId
  }

  @computed
  public get pulseCampaignSurveyId(): string {
    return this.pulseSurveySchedule.pulseCampaignSurveyId
  }

  @computed
  public get campaignId(): string {
    return this.pulseSurveySchedule.pulseCampaignId
  }

  @computed
  public get name(): string {
    return this.pulseSurveySchedule.pulseCampaignSurveyName
  }

  @computed
  public get campaignName(): string {
    if (!this.pulseSurveySchedule.pulseCampaignName) return ''
    let name = this.pulseSurveySchedule.pulseCampaignName
    if (name.length > 11) {
      name = name.substring(0, 10) + '...'
    }
    return name
  }

  @computed
  public get deliveryDate(): string {
    const formattedDate = moment(this.pulseSurveySchedule.effectiveDateTime).format('MMM DD YYYY')
    return formattedDate
  }

  @computed
  public get deliveryMonth(): string {
    const formattedDate = moment(this.pulseSurveySchedule.effectiveDateTime).format('MMM')
    return formattedDate.toUpperCase()
  }

  @computed
  public get deliveryDay(): string {
    const formattedDate = moment(this.pulseSurveySchedule.effectiveDateTime).format('DD')
    return formattedDate
  }

  @computed
  public get deliveryDateTime(): string {
    const formattedDate = moment(this.pulseSurveySchedule.effectiveDateTime).format(
      'MMM DD YYYY hh:mm A'
    )
    return formattedDate
  }

  @action
  public editPulse() {
    this.rootStore.appStore.router.push(
      `/pulse/v1/edit/${this.campaignId}/${this.pulseCampaignSurveyId}`
    )
  }
}
