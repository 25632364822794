import React from 'react'
import { inject, observer } from 'mobx-react'
import VirtualList from 'react-tiny-virtual-list'
import { Button, Card, Grid, Popover, Typography } from '@material-ui/core'
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded'
import LocalizationStore from '../../localization/LocalizationStore'
import { AnnouncementsStore } from '../store/AnnouncementsStore'
import AnnouncementEditTabPanel from './AnnouncementEditTabPanel'
import UserAnnouncementRow from './UserAnnouncementRow'
import './AnnouncementsWidget.scss'
import { LabelsStore } from '../../labels/store/LabelsStore'

interface Props {
  announcementsStore?: AnnouncementsStore
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
}

const AnnouncementsWidget: React.FC<Props> = ({
  announcementsStore,
  localizationStore,
  labelsStore,
}) => {
  const { announcementsWidgetVM: vm } = announcementsStore
  if (!vm) return null
  const { announcements_widget: strings } = localizationStore.lzStrings
  if (!vm.anchorEl) return null

  const renderAddAnnouncementButton = () => {
    if (!vm.canCreateAnnouncements) return null
    return (
      <Button className='new-announcement' variant='text' onClick={() => vm.addNewAnnouncement()}>
        {String(
          strings.new + ' ' + labelsStore.getLabelByLanguageAndFor('announcement')
        ).toUpperCase()}
      </Button>
    )
  }

  const renderAllAnnouncementListButton = () => {
    if (!vm.canCreateAnnouncements) return null
    if (!vm.isAdmin) return null
    return (
      <Button
        className='all-announcements-list'
        variant='outlined'
        onClick={() => vm.goToAllAnnouncementsList()}
      >
        {`VIEW ALL`}
      </Button>
    )
  }

  const renderForLoggedInUserAnnouncementListButton = () => {
    if (!vm.canCreateAnnouncements) return null
    return (
      <Button
        className='user-announcements-list'
        variant='outlined'
        onClick={() => vm.goToForLoggedInUserAnnouncementsList()}
      >
        {`MY ${labelsStore.getLabelByLanguageAndFor('announcements').toUpperCase()}`}
      </Button>
    )
  }

  const renderTitle = () => {
    return (
      <Grid item xs={12} className='widget-title'>
        <Typography className='title-text' variant='body1'>
          {labelsStore.getLabelByLanguageAndFor('announcements')}
        </Typography>
        <div>
          {renderForLoggedInUserAnnouncementListButton()}
          {renderAllAnnouncementListButton()}
          {renderAddAnnouncementButton()}
        </div>
      </Grid>
    )
  }

  const renderNoUserAnnouncements = () => {
    if (vm.rows.length > 0) return null
    return (
      <Typography className='no-announcements'>
        {strings.no_announcements}

        <CheckCircleRoundedIcon className='done-icon' />
      </Typography>
    )
  }

  const renderUserAnnouncementsList = () => {
    if (vm.rows.length === 0) return null
    return (
      <Grid container>
        <VirtualList
          should-force-update={vm.updated}
          height={window.innerHeight - 120}
          itemCount={vm.rows.length}
          itemSize={(index) => vm.rowGetter(index)}
          renderItem={({ style, index }) => {
            const row = vm.rows[index]
            return (
              <UserAnnouncementRow
                row={row}
                key={row.index + row.objectId}
                style={style}
                index={index}
              />
            )
          }}
        />
      </Grid>
    )
  }

  const renderAnnouncementsNewTabPanel = () => {
    if (!vm.editPanelShown) return null
    return (
      <Grid item xs={12}>
        <AnnouncementEditTabPanel />
      </Grid>
    )
  }

  const renderUserAnnouncementsPanel = () => {
    if (vm.editPanelShown) return null
    return (
      <>
        {renderTitle()}
        {renderUserAnnouncementsList()}
        {renderNoUserAnnouncements()}
      </>
    )
  }

  return (
    <Popover
      style={{ top: '0px' }}
      anchorEl={vm.anchorEl}
      open={vm.announcementsWidgetOpen}
      onClose={() => vm.clearAnchorEl()}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      disableEnforceFocus
    >
      <Card id='AnnouncementsWidget'>
        <Grid container>
          {renderUserAnnouncementsPanel()}
          {renderAnnouncementsNewTabPanel()}
        </Grid>
      </Card>
    </Popover>
  )
}

export default inject(
  'announcementsStore',
  'localizationStore',
  'labelsStore'
)(observer(AnnouncementsWidget))
