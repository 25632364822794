import { action, observable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { AuthenticationService } from '../services/AuthenticationService'
import Parse from 'parse'
const querystring = require('querystring')

export class OIDCCallbackVM {
  private rootStore: RootStore
  private issuer: string = ''
  private clientId: string = ''
  private params: any = {}

  @observable public errorMessage: string = ''
  @observable public hasError: boolean = false

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    const urlArray = window.location.href.split('?')
    this.params = querystring.parse(urlArray[1])
    this.checkOIDCParams()
  }

  @observable public invalidUser: boolean = false

  /*
   * Access Token various forms:
   * Keycloak: 'access_token'
   * Azure: '#access_token'
   */
  private getAccessToken() {
    const paramKeys = Object.keys(this.params)
    for (const key of paramKeys) {
      if (key.includes('access_token')) {
        return this.params[key]
      }
    }
  }

  @action
  public async navigateToHome() {
    if (!this.rootStore.isHydrated) {
      setTimeout(() => this.navigateToHome(), 500)
      return
    }
    let link = '/dashboard/userDashboard'
    console.log(`remember link: ${this.rootStore.appStore.rememberLink}`)
    if (this.rootStore.appStore.rememberLink) {
      link = this.rootStore.appStore.rememberLink
      this.rootStore.appStore.setRememberLink(undefined)
    }
    console.log(`link: ${link}`)
    setTimeout(() => this.rootStore.appStore.router.push(link), 1500)
  }

  @action
  public backToLogin() {
    this.hasError = false
    window.location.href = '/'
  }

  private defaultErrorMessage: string = 'Failed to verify Authorization with Provider.'
  @action
  public async checkOIDCParams() {
    const access_token = this.getAccessToken()
    const session_state = this.params.session_state
    const organizationId = this.params.state
    try {
      if (!access_token) {
        console.log('no access token')
        throw new Error('no access token')
      }
      const authSvc = new AuthenticationService()
      const result = await authSvc.oidcLogin({
        token: access_token,
        organizationId,
        session_state,
        platform: 'web',
      })
      if (result.success) {
        await Parse.User.become(result.sessionToken)
        this.rootStore.appStore.setCurrentUser(Parse.User.current().id)
        this.navigateToHome()
      } else {
        this.hasError = true
        this.errorMessage = result.errorMessage ? result.errorMessage : this.defaultErrorMessage
      }
    } catch (e) {
      this.hasError = true
      this.errorMessage = this.defaultErrorMessage
    }
  }
}
