import { computed, action, observable } from 'mobx'
import { RootStore } from 'src/app/stores/RootStore'
import { ManageSurveysWidgetVM } from './ManageSurveysWidgetVM'
import moment from 'moment'

export class FilterPopupVM {
  private rootStore: RootStore
  private parentVM: ManageSurveysWidgetVM

  constructor(rootStore: RootStore | null = null, parentVM: ManageSurveysWidgetVM) {
    if (rootStore) this.rootStore = rootStore
    this.parentVM = parentVM
    this.getFilterFromStore()
  }

  @observable public dateRangeModalOpen: boolean = false
  @observable public startDate: Date = null
  @observable public endDate: Date = null
  @observable public filterSurveyOwnerIds: string[] = []
  @observable public filterSurveyTypeIds: string[] = []
  @observable public filterGroupIds: string[] = []
  @observable public filterRoleIds: string[] = []
  @observable public filterUserIds: string[] = []
  @observable public filterCategoryIds: string[] = []

  @action
  public onFilterChanged() {
    // this.parentVM.onFilterChanged()
  }

  @computed
  public get hasFilter(): boolean {
    if (this.startDate && this.endDate) return true
    if (this.filterSurveyOwnerIds.length > 0) return true
    if (this.filterSurveyTypeIds.length > 0) return true
    if (this.filterGroupIds.length > 0) return true
    if (this.filterRoleIds.length > 0) return true
    if (this.filterUserIds.length > 0) return true
    if (this.filterCategoryIds.length > 0) return true
    return false
  }

  @action
  public onApplyFilters() {
    this.parentVM.onFilterChanged()
  }

  @action
  public handleFiltering() {
    this.parentVM.onFilterChanged()
    this.setFilterOnStore()
  }

  @action
  public getFilterFromStore() {
    const filter = this.rootStore.surveysStore.viewModels.manageSurveysFilter
    if (filter) {
      if (filter.startDate) this.startDate = filter.startDate
      if (filter.endDate) this.endDate = filter.endDate
      if (filter.filterSurveyOwnerIds) this.filterSurveyOwnerIds = filter.filterSurveyOwnerIds.slice()
      if (filter.filterSurveyTypeIds) this.filterSurveyTypeIds = filter.filterSurveyTypeIds.slice()
      if (filter.filterGroupIds) this.filterGroupIds = filter.filterGroupIds.slice()
      if (filter.filterRoleIds) this.filterRoleIds = filter.filterRoleIds.slice()
      if (filter.filterUserIds) this.filterUserIds = filter.filterUserIds.slice()
      if (filter.filterCategoryIds) this.filterCategoryIds = filter.filterCategoryIds.slice()
    }
  }

  @action
  public setFilterOnStore() {
    const filter = {
      startDate: this.startDate,
      endDate: this.endDate,
      filterSurveyOwnerIds: this.filterSurveyOwnerIds.slice(),
      filterSurveyTypeIds: this.filterSurveyTypeIds.slice(),
      filterGroupIds: this.filterGroupIds.slice(),
      filterRoleIds: this.filterRoleIds.slice(),
      filterUserIds: this.filterUserIds.slice(),
      filterCategoryIds: this.filterCategoryIds.slice(),
    }
    this.rootStore.surveysStore.viewModels.setManageSurveysFilter(filter)
  }

  @action
  public clearFilters() {
    this.startDate = null
    this.endDate = null
    this.filterSurveyOwnerIds = []
    this.filterSurveyTypeIds = []
    this.filterGroupIds = []
    this.filterRoleIds = []
    this.filterUserIds = []
    this.filterCategoryIds = []
  }

  @action
  public clearDateRangeFilter() {
    this.startDate = null
    this.endDate = null
  }

  @action
  public setFilterSurveyOwnerIds(ids) {
    this.filterSurveyOwnerIds = ids
  }

  @action
  public setFilterSurveyTypeIds(ids) {
    this.filterSurveyTypeIds = ids
  }

  @action
  public setFilterGroupIds(ids) {
    this.filterGroupIds = ids
  }

  @action
  public setFilterRoleIds(ids) {
    this.filterRoleIds = ids
  }

  @action
  public setFilterUserIds(ids) {
    this.filterUserIds = ids
  }

  @action
  public setFilterCategoryIds(ids) {
    this.filterCategoryIds = ids
  }

  @computed
  public get startDateFormatted(): string {
    const s = this.rootStore.localizationStore.lzStrings.manageSurveysWidget
    if (this.startDate) return moment(this.startDate).format(s.start_date_format)
    return 'No Start Date Selected'
  }

  @computed
  public get endDateFormatted(): string {
    const s = this.rootStore.localizationStore.lzStrings.manageSurveysWidget
    if (this.endDate) return moment(this.endDate).format(s.end_date_format)
    return 'No End Date Selected'
  }

  @action
  public toggleDateRangeModal() {
    if (!this.dateRangeModalOpen) {
      if (!this.startDate) this.startDate = new Date()
      if (!this.endDate) this.endDate = new Date()
      this.dateRangeModalOpen = true
    } else {
      this.dateRangeModalOpen = false
    }
  }

  @computed
  public get dateRange() {
    return {
      startDate: moment(this.startDate).toDate(),
      endDate: moment(this.endDate).toDate(),
    }
  }

  @action
  public setDateRange(range) {
    range.startDate !== undefined && this.setStartDate(range.startDate)
    range.endDate !== undefined && this.setEndDate(range.endDate)
  }

  @action
  public setStartDate(date: Date) {
    this.startDate = date
  }

  @action
  public setEndDate(date: Date) {
    this.endDate = date
  }

  @computed
  public get surveyOwnerOptions() {
    return this.rootStore.audienceMembersStore.audienceUsers
      .sort((a, b) => {
        if (a.name > b.name) return 1
        if (a.name === b.name) return 0
        if (a.name < b.name) return -1
      })
      .map((user, index) => ({
        key: user.objectId,
        value: user.objectId,
        text: user.name,
        type: 'user',
      }))
  }

  @computed
  public get surveyTypeOptions() {
    return this.rootStore.surveyTypesStore.surveyTypes
      .sort((a, b) => {
        if (a.name > b.name) return 1
        if (a.name === b.name) return 0
        if (a.name < b.name) return -1
      })
      .map((type, index) => ({
        key: type.objectId,
        value: type.objectId,
        text: type.name,
        type: 'type',
      }))
  }

  @computed
  public get groupOptions() {
    return this.rootStore.audienceMembersStore.audienceGroups
      .sort((a, b) => {
        if (a.name > b.name) return 1
        if (a.name === b.name) return 0
        if (a.name < b.name) return -1
      })
      .map((group, index) => ({
        key: group.objectId,
        value: group.objectId,
        text: group.name,
        type: 'group',
      }))
  }

  @computed
  public get roleOptions() {
    return this.rootStore.audienceMembersStore.audienceRoles
      .sort((a, b) => {
        if (a.name > b.name) return 1
        if (a.name === b.name) return 0
        if (a.name < b.name) return -1
      })
      .map((role, index) => ({
        key: role.objectId,
        value: role.objectId,
        text: role.name,
        type: 'role',
      }))
  }

  @computed
  public get userOptions() {
    return this.rootStore.audienceMembersStore.audienceUsers
      .sort((a, b) => {
        if (a.name > b.name) return 1
        if (a.name === b.name) return 0
        if (a.name < b.name) return -1
      })
      .map((user, index) => ({
        key: user.objectId,
        value: user.objectId,
        text: user.name,
        type: 'user',
      }))
  }

  @computed
  public get categoryOptions() {
    return this.rootStore.categoriesStore.currentOrgCategories
      .sort((a, b) => {
        if (a.name > b.name) return 1
        if (a.name === b.name) return 0
        if (a.name < b.name) return -1
      })
      .map((cat, index) => ({
        key: cat.objectId,
        value: cat.objectId,
        text: cat.name,
        type: 'category',
      }))
  }
}
