import React from 'react'
import { observer } from 'mobx-react'
import { inject } from 'mobx-react'
import { SurveysStore } from '../../../store/SurveysStore'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core'
import ParticipantsSelect from '../../../../participants-select/views/ParticipantsSelect'

interface Props {
  localizationStore?: LocalizationStore
  surveysStore?: SurveysStore
}

const VisibilityParticipantSelect: React.FC<Props> = ({ surveysStore }) => {
  const { editVM } = surveysStore.viewModels
  const visibilityVM = editVM.visibilityEditorVM
  if (!visibilityVM) return null
  if (!visibilityVM.showParticipantSelectModal) return null

  const toolbar = () => {
    return (
      <div className='participant-toolbar'>
        <Button variant='contained' onClick={() => visibilityVM.toggleShowParticipantSelect()}>
          Apply
        </Button>
      </div>
    )
  }

  return (
    <Dialog
      id='visibility-modal'
      fullWidth
      maxWidth='lg'
      open={visibilityVM.showParticipantSelectModal}
      onClose={() => visibilityVM.toggleShowParticipantSelect()}
    >
      <DialogContent className='visibility-participant-dialog-content'>
        <div className='visibility-participant-select'>
          <ParticipantsSelect participantsSelectVM={visibilityVM.visibilityParticipantVM} />
        </div>
      </DialogContent>
      <DialogActions>{toolbar()}</DialogActions>
    </Dialog>
  )
}

export default inject('surveysStore', 'localizationStore')(observer(VisibilityParticipantSelect))
