import React from 'react'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { EventsStore } from '../../../store/EventsStore'
import { observer, inject } from 'mobx-react'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { Grid, Typography, Button, TextField, InputAdornment } from '@material-ui/core'
import './CalendarExportModal.scss'

interface Props {
  eventsStore?: EventsStore
  localizationStore?: LocalizationStore
}

const SubscribeTab: React.FC<Props> = ({ eventsStore, localizationStore }) => {
  const { exportVM: vm } = eventsStore.calendarVM

  const renderFirstRow = () => {
    return (
      <Grid container className='row'>
        <Grid container alignItems='center' className='instructions'>
          <Grid item>
            <Typography variant='h5' className='row-number'>
              1
            </Typography>
          </Grid>
          <Grid item>
            <Typography>Click the button below to generate an iCal Subscription Link.</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className='row-input'>
          <Button variant='contained' onClick={() => vm.getUserIcsLink()}>
            Get Subscription Link{' '}
          </Button>
        </Grid>
      </Grid>
    )
  }

  const renderConfirmCopied = () => {
    if (!vm.copiedToClipboard) return null
    return <Typography variant='body1'>Copied to clipboard.</Typography>
  }

  const renderSecondRow = () => {
    return (
      <Grid container className='row'>
        <Grid container alignItems='center' className='instructions'>
          <Grid item>
            <Typography variant='h5' className='row-number'>
              2
            </Typography>
          </Grid>
          <Grid item>
            <Typography>Copy the link below.</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className='row-input'>
          <TextField
            id='icsLinkInput'
            onFocus={(e) => e.target.select()}
            variant='outlined'
            fullWidth
            value={vm.calendarLink}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <FileCopyIcon
                    onClick={() => vm.copyLinkToClipboard()}
                    className={vm.copiedToClipboard ? 'copied icon' : 'icon'}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {renderConfirmCopied()}
      </Grid>
    )
  }

  const renderThirdRow = () => {
    return (
      <Grid container className='row'>
        <Grid container alignItems='center'>
          <Grid item>
            <Typography variant='h5' className='row-number'>
              3
            </Typography>
          </Grid>
          <Grid item>
            <Typography>Paste the link in your preferred calendar application.</Typography>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <div id='subscribeTab'>
      {renderFirstRow()}
      {renderSecondRow()}
      {renderThirdRow()}
    </div>
  )
}

export default inject('eventsStore', 'localizationStore')(observer(SubscribeTab))
