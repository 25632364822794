import { observable, action, computed, runInAction, reaction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import Parse from 'parse'
import { Label } from './aggregate/Label'
import { DataStore } from '../../shared/data/DataStore'
import { ILabelDTO } from 'src/app/organizations-sa/dto/ILabelDTO'
import { deserialize } from 'serializr'

export class LabelsStore extends DataStore<Label, ILabelDTO> {

  constructor(rootStore: RootStore) {
    super(rootStore, Label, 'organizationLabels', [
      'organizationId',
      'selector',
      'isDefault',
      'language',
      'value',
    ])
  }

  @observable public loaded: boolean = false

  @computed
  public get labels(): Label[] {
    return this.records
  }

  @action
  public onListRecordsLoaded() {
    this.loaded = true
  }

  protected getBaseQuery() {
    const query = new Parse.Query(this.className)
    query.containedIn('organizationId', [this.rootStore.appStore.currentOrgId, 'DEFAULT'])
    query.notEqualTo('isDeleted', true)
    return query
  }

  @computed
  public get currentOrgLabels(): Array<Label> {
    const orgId = this.rootStore.appStore.currentOrgId
    const labels = this.labels.filter((e) => e.organizationId === orgId)
    const defaultLabels = this.labels.filter((e) => e.organizationId === 'DEFAULT')
    for (let i = 0; i < defaultLabels.length; i++) {
      labels.forEach((label) => {
        if (
          label.selector === defaultLabels[i].selector &&
          label.language === defaultLabels[i].language
        ) {
          defaultLabels.splice(i, 1, label)
        }
      })
    }
    return defaultLabels
  }

  public getLabelByLanguageAndFor(forLabel: string): string {
    let language = navigator.language
    let label

    if (language === 'en-US') language = 'en'

    let labels = this.currentOrgLabels.filter(
      (e) => e.language === language && e.selector === forLabel
    )
    for (let i = 0; i < labels.length; i++) {
      if (labels[i].isDefault === false) {
        label = labels[i]
      }
    }
    if (!label) label = labels[0]

    return label ? label.value : this.jsUcfirst(forLabel)
  }

  private jsUcfirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  public getLabel(objectId): Label {
    return this.labels.find((e) => e.objectId === objectId)
  }

  private getLabelIndex(objectId): number {
    return this.labels.findIndex((e) => e.objectId === objectId)
  }

}
