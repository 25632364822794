import React from 'react'
import { inject, observer } from 'mobx-react'
import './CampaignRowActions.scss'
import { IconButton, Tooltip, Typography } from '@material-ui/core'
import { CampaignRowVM } from '../../../view-models/dashboard/all-campaigns/CampaignRowVM'
import SkillsIcon from '@material-ui/icons/BarChartRounded'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import EditIcon from '@material-ui/icons/Edit'
import LocalizationStore from '../../../../localization/LocalizationStore'

interface Props {
  vm: CampaignRowVM
  localizationStore?: LocalizationStore
}

const CampaignRowActions: React.FC<Props> = ({ vm, localizationStore }) => {
  if (!vm) return null
  const lz = localizationStore.lzStrings.pulseCampaignDashboard

  const renderEditCampaignAnalysis = () => {
    if (!vm.hasVizPermission) return null
    return (
      <Tooltip title={lz.view_analysis} placement='bottom-start' enterDelay={500}>
        <IconButton
          style={{ padding: '3px', backgroundColor: 'transparent' }}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            vm.editPulseAnalysis()
          }}
        >
          <SkillsIcon className='action-icon' />
        </IconButton>
      </Tooltip>
    )
  }

  const renderDuplicateCampaign = () => {
    return (
      <Tooltip title={lz.duplicate} placement='bottom-start' enterDelay={500}>
        <IconButton
          style={{ padding: '3px', backgroundColor: 'transparent' }}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            vm.copyPulseCampaign()
          }}
        >
          <FileCopyIcon className='action-icon' />
        </IconButton>
      </Tooltip>
    )
  }

  const renderEditCampaign = () => {
    return (
      <Tooltip title={lz.edit} placement='bottom-start' enterDelay={500}>
        <IconButton
          style={{ padding: '3px', backgroundColor: 'transparent' }}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            vm.editPulseCampaign()
          }}
        >
          <EditIcon className='action-icon' />
        </IconButton>
      </Tooltip>
    )
  }

  return (
    <div id='CampaignRowActions'>
      {renderEditCampaignAnalysis()}
      {renderDuplicateCampaign()}
      {renderEditCampaign()}
    </div>
  )
}

export default inject('localizationStore')(observer(CampaignRowActions))
