import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { Button, Typography } from '@material-ui/core'
import { Player } from '@lottiefiles/react-lottie-player'
import './UserTrainingPlanCompleted.scss'
import { UserTrainingPlansStore } from '../../store/UserTrainingPlansStore'

interface Props {
  localizationStore?: LocalizationStore
  userTrainingPlansStore?: UserTrainingPlansStore
}

const UserTrainingPlanCompleted: React.FC<Props> = ({ userTrainingPlansStore }) => {
  const { userTrainingPlanTakeVM: vm } = userTrainingPlansStore
  if (!vm) return null
  if (!vm.completionScreenShown) return null

  const renderPlayer = () => {
    return (
      <Player
        autoplay={true}
        loop={true}
        controls={false}
        src='https://assets10.lottiefiles.com/packages/lf20_rcwlnqyn.json'
        style={{ height: '300px', width: 'auto' }}
      ></Player>
    )
  }

  return (
    <div id='CompletedPlan'>
      <Typography className='success-msg'>Great job!</Typography>
      {renderPlayer()}
      <Typography className='success-caption'>
        You've successfully completed this Training Plan!
      </Typography>
      <Typography className='success-caption mtb'>
        Check the Trainings Dashboard to see if there are any more waiting for you.
      </Typography>
      <div className='btn-row'>
        <Button variant='outlined' onClick={() => vm.reviewPlan()}>
          Review this plan
        </Button>
        <Button variant='contained' onClick={() => vm.navigateToDashboard()}>
          Go to Trainings Dashboard
        </Button>
      </div>
    </div>
  )
}

export default inject(
  'localizationStore',
  'userTrainingPlansStore',
  'appStore'
)(observer(UserTrainingPlanCompleted))
