import React from 'react'
import { Button, Grid, InputLabel } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import { EventsStore } from '../../store/EventsStore'
import { Attachment } from '../../../upload/aggregate/Attachment'
import FileUploadModal from '../../../upload/views/FileUploadModal'
import { FileTypeEnum } from '../../../upload/enum/FileTypeEnum'
import CMSItemsPickerDialog from '../../../cms-items/views/dialogs/CMSItemsPickerDialog'
import { CMSItemsTypeEnum } from '../../../cms-items/enum/CMSItemsTypeEnum'
import { OrganizationsStore } from '../../../organizations/store/OrganizationsStore'
import { AttachmentVM } from '../../../attachments/view-models/AttachmentVM'
import { CMSItemAttachmentVM } from '../../../attachments/view-models/CMSItemAttachmentVM'
import { OldAttachmentVM } from '../../../attachments/view-models/OldAttachmentVM'
import CMSThumbnail from '../../../thumbnail/views/CMSThumbnail'
import OldThumbnail from '../../../thumbnail/views/OldThumbnail'

interface Props {
  eventsStore?: EventsStore
  organizationsStore?: OrganizationsStore
}

const CalendarEventDrawerAttachments: React.FC<Props> = ({ eventsStore, organizationsStore }) => {
  const { editVM: vm } = eventsStore

  const renderAttachmentThumbnail = (item: AttachmentVM, index: number) => {
    if (item.isCMSItem)
      return (
        <CMSThumbnail
          key={`event-cms-attach-thumb-${index}`}
          vm={item as CMSItemAttachmentVM}
          index={index}
          onClose={() => vm.deleteAttachmentFromTask(index)}
        />
      )
    return (
      <OldThumbnail
        key={`event-old-attach-thumb-${index}`}
        vm={item as OldAttachmentVM}
        index={index}
        onClose={() => vm.deleteAttachmentFromTask(index)}
      />
    )
  }

  const renderAttachments = () => {
    if (!vm.attachments) return null
    return (
      <form>
        <Grid container className='attachments-list'>
          {vm.attachments.map((doc, index) => {
            return renderAttachmentThumbnail(doc, index)
          })}
        </Grid>
      </form>
    )
  }

  const renderUploadModal = () => {
    if (organizationsStore.currentOrganization?.hasCMSPicker) return renderCMSUploadModal()
    return renderStandardUploadModal()
  }

  const renderCMSUploadModal = () => {
    return (
      <CMSItemsPickerDialog
        allowMultiple={true}
        fileTypes={CMSItemsTypeEnum.DEFAULT}
        addAfterSave={(arr: Attachment[]) => {
          const attachments = arr.map((a) => {
            const attachment = new Attachment()
            attachment.cmsItemId = a.cmsItemId
            return attachment
          })
          vm.addAttachments(attachments)
        }}
        toggleShowModal={() => vm.toggleShowUploadModal()}
        isOpen={vm.showUploadModal}
      />
    )
  }

  const renderStandardUploadModal = () => {
    return (
      <FileUploadModal
        allowMultiple={true}
        fileTypes={FileTypeEnum.DEFAULT}
        addAfterSave={(a: Attachment[]) => vm.addAttachments(a)}
        toggleShowModal={() => vm.toggleShowUploadModal()}
        isOpen={vm.showUploadModal}
      />
    )
  }

  return (
    <Grid item xs={12} className='attachments-section'>
      {renderUploadModal()}
      <div className='attachments-header'>
        <InputLabel>Attachments</InputLabel>
        <Button variant='text' onClick={() => vm.toggleShowUploadModal()}>
          Add Attachments
        </Button>
      </div>
      {renderAttachments()}
    </Grid>
  )
}

export default inject('eventsStore', 'organizationsStore')(observer(CalendarEventDrawerAttachments))
