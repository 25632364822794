import React from 'react'
import { inject, observer } from 'mobx-react'
import { TasksStore } from '../../store/TasksStore'
import DrawerParticipantsTabToolbar from './DrawerParticipantsTabToolbar'
import ParticipantsSelect from '../../../participants-select/views/ParticipantsSelect'

interface Props {
  tasksStore?: TasksStore
}

const DrawerParticipantSelect: React.FC<Props> = ({ tasksStore }) => {
  const { taskEditVM: vm } = tasksStore
  if (!vm.participantsSelectOpen) return null
  return (
    <>
      <DrawerParticipantsTabToolbar />
      <div id='TaskParticipantsList'>
        <ParticipantsSelect participantsSelectVM={tasksStore.taskEditVM.participantsSelect} />
      </div>
    </>
  )
}

export default inject('tasksStore')(observer(DrawerParticipantSelect))
