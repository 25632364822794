import { createTheme } from '@material-ui/core/styles'
import { ThemeOptions } from '@material-ui/core/styles/createTheme'
import isIE11 from '../utils/isIE11'

export const colors = {
  white: '#fff',
  black: '#000',
  blackRock: '#2d2d3c',
  gunPowder: '#4e4e5e',
  bastille: '#292936',
  nightRider: '#333',
  whiteSmoke: '#f8f5f5',
  darkTurquoise: '#05d1cf',
  quartz: '#D7D7E2',
  lavenderGray: '#cacace',
}

export const fontSizes = {
  header: '22px',
  subheader: '19px',
  default: '16px',
  secondary: '14px',
  tertiary: '12px',
}

const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: [
      "'Nunito'",
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      "'sans-serif'",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(', '),
    fontSize: 16,
    h1: { fontSize: fontSizes.header },
    h2: { fontSize: fontSizes.header },
    h3: { fontSize: fontSizes.header },
    h4: { fontSize: fontSizes.subheader },
    h5: { fontSize: fontSizes.default }, // h5 has been used for a long time as a text input label and other such uses, that need to be default size
    h6: { fontSize: fontSizes.subheader },
    body1: { fontSize: fontSizes.default },
    body2: { fontSize: fontSizes.default },
  },
  palette: {
    primary: {
      main: colors.blackRock,
      light: colors.gunPowder,
      dark: colors.bastille,
      contrastText: colors.whiteSmoke,
    },
    secondary: {
      main: colors.whiteSmoke,
      contrastText: colors.nightRider,
      dark: colors.quartz,
    },
    background: {
      paper: colors.whiteSmoke,
      default: colors.whiteSmoke,
    },
    text: {
      primary: colors.nightRider,
    },
    // error: {}
  },
}

if (isIE11) {
  console.log('ie11 - turning off transitions in mui theme')
  themeOptions.transitions = {
    create: () => 'none',
  }
}

export const theme = createTheme(themeOptions)
export default theme
