import React, { FC } from 'react'
import './SurveyOptions.scss'
import WhenToPublish from './WhenToPublish'
import RecurringOptions from './RecurringOptions'
import DueDate from './DueDate'
import AlwaysAvailableSurvey from './AlwaysAvailableSurvey'

interface Props {}

const PublishingOptionsTab: FC<Props> = () => {
  return (
    <>
      <WhenToPublish />
      <RecurringOptions />
      <AlwaysAvailableSurvey />
      <DueDate />
    </>
  )
}

export default PublishingOptionsTab
