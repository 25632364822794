import Parse from 'parse'
import { action, observable, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { TenantLink } from '../aggregate/TenantLink'
import { OrganizationEditVM } from './OrganizationEditVM'

export class TenantLinkRowVM {
  private rootStore: RootStore


  constructor(rootStore: RootStore, editVM: OrganizationEditVM, tenantLink: TenantLink) {
    this.rootStore = rootStore
    this.editVM = editVM
    this.tenantLink = tenantLink
  }

  @observable public tenantLink: TenantLink = null
  @observable public editVM: OrganizationEditVM = null
  @observable public saveTried: Boolean = false

  @computed
  public get name(): string {
    return this.tenantLink.name
  }

  @computed
  public get iconName(): string {
    if (!this.tenantLink.iconName) return ''
    return this.tenantLink.iconName.fileName
  }

  @computed
  public get url(): string {
    return this.tenantLink.url
  }

  @computed
  public get isEmbedded(): boolean {
    return this.tenantLink.isEmbedded
  }

  @computed
  public get embeddedString(): string {
    if (this.isEmbedded) return 'Yes'
    return 'No'
  }
}
