import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { PrivilegeSetsStore } from '../../../privilege-sets/store/PrivilegeSetsStore'
import { PrivilegesStore } from '../../../stores/privileges/PrivilegesStore'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'
import CheckIcon from '@material-ui/icons/Check'
import Typography from '@material-ui/core/Typography'
import './UserPrivilegeSetsTab.scss'
import { OrganizationUsersStore } from '../../store/OrganizationUsersStore'

interface UserPrivilegeSetsTabProps {
  organizationUsersStore?: OrganizationUsersStore
  privilegeSetsStore?: PrivilegeSetsStore
  privilegesStore?: PrivilegesStore
  localizationStore?: LocalizationStore
}

const UserPrivilegeSetsTab: React.FC<UserPrivilegeSetsTabProps> = ({
  organizationUsersStore,
  privilegeSetsStore,
  privilegesStore,
  localizationStore,
}) => {
  const { editVM: vm } = organizationUsersStore
  const privilegeSets = privilegeSetsStore.privilegeSets
  const privileges = privilegesStore.privileges
  const strings = localizationStore.lzStrings.privilegeSetsEdit

  const renderTableHead = () => {
    return (
      <TableHead>
        <TableRow key='priv-table-header'>
          <TableCell key='blank-cell'></TableCell>
          <TableCell key='name-cell'>Name</TableCell>
          <TableCell key={'priv-cell'} align='left'>
            Privileges
          </TableCell>
        </TableRow>
      </TableHead>
    )
  }

  const renderTableBody = () => {
    if (!vm.privilegeSets) return
    return (
      <TableBody>
        {privilegeSets
          .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
          .map((set, index) => {
            const foundInPrivilegeSetsFromGroupsAndRoles =
              vm.privilegeSetsFromGroupsAndRoles && vm.privilegeSetsFromGroupsAndRoles.find
                ? vm.privilegeSetsFromGroupsAndRoles.find(
                    (data) => data.privilegeSetObjectId === set.objectId
                  )
                : null

            const row =
              !vm.isEditable && !vm.privilegeSets.includes(set.objectId) ? null : (
                <TableRow key={set.name + '-' + index + '-row'}>
                  <TableCell key={set.name + '-check'} className='checkbox-cell'>
                    <Checkbox
                      color='primary'
                      onChange={() => vm.toggleCheckedPrivSet(set.objectId)}
                      checked={vm.privilegeSets.includes(set.objectId)}
                      disabled={
                        !vm.isEditable ||
                        Boolean(foundInPrivilegeSetsFromGroupsAndRoles) ||
                        !vm.isOrgAdmin
                      }
                    />
                  </TableCell>
                  <TableCell key={set.name + '-cell'}>
                    <Typography component='div'>{set.name}</Typography>
                    {foundInPrivilegeSetsFromGroupsAndRoles ? (
                      <Typography
                        component='div'
                        variant='caption'
                        style={{ marginTop: '0.25rem' }}
                      >
                        From {foundInPrivilegeSetsFromGroupsAndRoles.originType}:{' '}
                        {foundInPrivilegeSetsFromGroupsAndRoles.originName}
                      </Typography>
                    ) : null}
                  </TableCell>
                  <TableCell key={set.name + '-privs'}>{getPrivileges(set)}</TableCell>
                </TableRow>
              )
            return row
          })}
      </TableBody>
    )
  }

  const getPrivileges = (set) => {
    const privileges = []
    set.privileges.forEach((priv) => {
      const privObj = privilegesStore.getPrivilege(priv)
      if (privObj && privObj.name) {
        const foundName = privileges.find((e) => e === privObj.name)
        if (!foundName) privileges.push(privObj.name)
      }
    })
    const result = privileges.sort((a, b) => (a < b ? -1 : 0)).join(', ')
    if (result === '') return '--'
    return result
  }

  const content =
    vm.privilegeSets.length === 0 && !vm.isEditable ? (
      <Typography>{strings.no_assigned_privileges}</Typography>
    ) : (
      <Paper elevation={0} className='manage-user-table'>
        <Table>
          {renderTableHead()}
          {renderTableBody()}
        </Table>
      </Paper>
    )

  return (
    <div id='UserPrivilegeSetsTab'>
      <div id='privilegeSetsTable'>{content}</div>
    </div>
  )
}

export default inject(
  'organizationUsersStore',
  'privilegeSetsStore',
  'privilegesStore',
  'localizationStore'
)(observer(UserPrivilegeSetsTab))
