import React from 'react'

//MobX Stores
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { OrganizationsStore } from '../../store/OrganizationsStore'

//MaterialUI Components
import { CardHeader, IconButton } from '@material-ui/core'

//Material Icons
import CloseIcon from '@material-ui/icons/Close'

//Components Props Definition
interface DrawerHeaderProps {
  localizationStore?: LocalizationStore
  organizationsStore?: OrganizationsStore
}

//Component
const DrawerHeader: React.FC<DrawerHeaderProps> = ({ localizationStore, organizationsStore }) => {
  const { lzStrings } = localizationStore
  const { editVM: editForm } = organizationsStore

  const handleClose = () => {
    editForm.closeDrawer()
  }

  return (
    <CardHeader
      action={
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      }
      title={lzStrings.organizationWidgetDrawer.org_details}
    />
  )
}

export default inject('localizationStore', 'organizationsStore')(observer(DrawerHeader))
