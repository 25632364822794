import React from 'react'
import { inject, observer } from 'mobx-react'
import { Typography, Grid, Badge, Icon, CircularProgress } from '@material-ui/core'
import './EventPopup.scss'
import Avatar from '../../../../shared/Avatar'
import AddIcon from '@material-ui/icons/Add'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import { EventsStore } from '../../../store/EventsStore'
import styles from './../../../../shared/Avatar.scss'
import { PopupParticipantVM } from '../../../../events/view-models/PopupParticipantVM'

interface IEventPopup {
  eventsStore?: EventsStore
}

const PopupParticipants: React.FC<IEventPopup> = ({ eventsStore }) => {
  const { eventPopupVM: vm } = eventsStore
  if (!vm) return null

  const renderGoingBadge = (participant) => {
    if (participant.goingStatus === 'yes') {
      return (
        <div className={'going-yes-badge'}>
          <CheckIcon className='badge-icon' fontSize='inherit' />
        </div>
      )
    }
    if (participant.goingStatus === 'no') {
      return (
        <div className={'going-no-badge'}>
          <CloseIcon className='badge-icon' fontSize='inherit' />
        </div>
      )
    }
    if (participant.goingStatus === 'maybe') {
      return (
        <div className={'going-maybe-badge'}>
          <CloseIcon className='badge-icon' fontSize='inherit' />
        </div>
      )
    }
    if (participant.goingStatus === 'new') {
      return (
        <div className={'going-new-badge'}>
          <AddIcon className='badge-icon' />
        </div>
      )
    }
  }

  const renderReadIndicator = (readStatus) => {
    if (readStatus) return null
    return <span className='readStatus'></span>
  }

  const renderParticipants = () => {
    if (vm.isLoadingGoingStatuses) return
    return vm.users.map((participant, index) => renderParticipant(participant, index))
  }

  const renderParticipant = (participant: PopupParticipantVM, idx) => {
    const isArchived = participant.isArchived
    return (
      <div key={'part' + idx} className='popup-participant'>
        <Grid container direction='row'>
          <Grid item className='popup-participant-row'>
            <Badge
              className='participant-avatar'
              overlap='rectangular'
              badgeContent={isArchived ? undefined : renderGoingBadge(participant)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              {isArchived ? undefined : renderReadIndicator(participant.userEventReadStatus)}
              <Avatar
                user={{
                  name: participant.name,
                  iconURL: participant.iconURL,
                  objectId: participant.id,
                }}
                style={{ marginLeft: styles.marginLeft, fontSize: styles.fontSize }}
                isArchived={isArchived}
              />
            </Badge>
            <Typography className='participant-name'>{participant.name}</Typography>
          </Grid>
        </Grid>
      </div>
    )
  }

  const renderSpinner = () => {
    if (!vm.isLoadingGoingStatuses) return
    return <CircularProgress color='secondary' size={25} />
  }
  return (
    <div className={'popup-participants'}>
      <Typography variant='h5'>{vm.users.length} Guest(s)</Typography>
      <Typography variant='caption' className='maybe-going'>
        {vm.goingStatusSubTitle}
      </Typography>
      <div className='participants-container'>
        <Grid item xs={12}>
          <Grid container>
            {renderSpinner()}
            {renderParticipants()}
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default inject('eventsStore')(observer(PopupParticipants))
