import { persist } from 'mobx-persist'
import { observable, action, computed } from 'mobx'
import { LanguagePreferenceEnum } from '../../localization/types'
import { IUserDTO } from '../../users/dto/IUserDTO'
import { serialize, deserialize } from 'serializr'

export default class User {
  static create() {
    const usr = new User()
    return usr
  }

  @persist @observable objectId: string = ''
  @persist @observable name: string = ''
  @persist @observable phone: string = ''
  @persist @observable email: string = ''
  @persist @observable username: string = ''
  @persist @observable iconURL: string = ''
  @persist @observable receiveEmails: boolean = false
  @persist @observable receivePushNotifications: boolean = false
  @persist @observable receiveTextMessages: boolean = false
  @persist @observable languagePreference: LanguagePreferenceEnum = ''
  @persist @observable isSystemAdmin: boolean = false
  @persist @observable currentOrgId: string = ''
  @persist @observable userInfoPromptRead: boolean = false
  @persist @observable tableauLicenseType: string = ''

  setName(value: string) {
    this.name = value
  }
  setPhone(value: string) {
    this.phone = value
  }
  setReceiveEmails(value: boolean) {
    this.receiveEmails = value
  }
  setReceivePushNotifications(value: boolean) {
    this.receivePushNotifications = value
  }
  setReceiveTextMessages(value: boolean) {
    this.receiveTextMessages = value
  }
  setLanguage(value: LanguagePreferenceEnum) {
    this.languagePreference = value
  }

  @action
  public setTableauLicenseType(value: string) {
    this.tableauLicenseType = value
  }

  @action
  public setSystemAdmin(val) {
    this.isSystemAdmin = val
  }

  @computed
  public get culture() {
    const pref = this.languagePreference
    if (pref === 'English') return 'en'
    if (pref === 'German') return 'de'
    if (pref === 'Pseudo') return 'en'
    return 'en'
  }

  public clone(): User {
    return deserialize(User, this.serialize())
  }

  public serialize(): IUserDTO {
    try {
      const dto = serialize(this)
      return dto
    } catch (e) {
      console.error(e)
      console.log(this)
    }
  }

  @action
  public updateFromServer(fromUser: any) {
    this.objectId = fromUser.objectId
    this.name = fromUser.name
    this.phone = fromUser.phone
    this.email = fromUser.email
    this.iconURL = fromUser.iconURL ? fromUser.iconURL : ''
    this.receiveEmails = fromUser.receiveEmails
    this.receivePushNotifications = fromUser.receivePushNotifications
    this.receiveTextMessages = fromUser.receiveTextMessages
    this.languagePreference = fromUser.languagePreference
    this.currentOrgId = fromUser.currentOrgId
    this.userInfoPromptRead = fromUser.userInfoPromptRead
    this.username = fromUser.username
    this.tableauLicenseType = fromUser.tableauLicenseType
  }
}
