import React from 'react'
import { IndexRoute, Route } from 'react-router'
import Layout from '../../layout/Layout'
// import CalendarScreenV2 from './Calendar/containers/V2/CalendarScreenV2'
import RippleCalendar from '../../events/views/calendar/RippleCalendar'

import { authenticate } from '../../auth/routes/AuthenticatedRoute'

export default function getCalendarRoutes() {
  return (
    <Route key={'calmain'} component={authenticate(Layout)} path='calendar'>
      <IndexRoute
        onEnter={(nextState, replace) => {
          replace('/calendar/v2')
        }}
      />
      {/* <Route key={'evedit1'} path='events/new(/:eventDate)' component={EventEditScreen} /> */}
      {/* <Route
        key={'evedit2'}
        path='events/new(/:eventDate)(/:endDate)'
        component={EventEditScreen}
      /> */}
      {/* <Route key={'evedit3'} path='events/:id' component={EventEditScreen} /> */}
      <Route key={'v2'} path='v2' component={RippleCalendar} />
      {/* <Route key={'v2-create-event'} path='v2/:groupId' component={RippleCalendar} /> */}
      <Route key={'cal1'} path='v2/foraudiencegroup/:groupId' component={RippleCalendar} />
      <Route key={'cal2'} path='v2/foraudiencerole/:roleId' component={RippleCalendar} />
      <Route key={'cal3'} path='v2/foraudienceuser/:userId' component={RippleCalendar} />
      <Route key={'cal4'} path='v2/:eventId/:organizationId' component={RippleCalendar} />

      {/* <Route key={'v3'} path='v2(/:viewMode)(/:selectedDate)' component={CalendarScreenV2} /> */}
    </Route>
  )
}
