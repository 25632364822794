import React from 'react'
import { inject, observer } from 'mobx-react'
import { Button, CircularProgress, Grid, IconButton, Typography } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { PulseCampaignTypesManageVM } from '../view-models/PulseCampaignTypesManageVM'
import './PulseCampaignTypesList.scss'
import EmptyState from '../../shared/empty-state/EmptyState'
import emptyResults from '../../../assets/img/empty-state/empty-state-results.png'
import LocalizationStore from '../../localization/LocalizationStore'
import PulseCampaignType from '../aggregate/PulseCampaignType'

interface Props {
  vm: PulseCampaignTypesManageVM
  localizationStore?: LocalizationStore
}

const PulseCampaignTypesList: React.FC<Props> = ({ vm, localizationStore }) => {
  if (!vm) return null
  const lz = localizationStore.lzStrings.pulseCampaign

  const renderSpinner = () => {
    if (vm.isLoaded) return
    return (
      <div className='spinner-container'>
        <CircularProgress />
      </div>
    )
  }

  const renderActiveText = (isActive: boolean) => {
    if (isActive) return <Typography className='pulse-type-active'>ACTIVE</Typography>
    return <Typography className='pulse-type-active'>INACTIVE</Typography>
  }

  const renderRow = (row, i) => {
    return (
      <div id={'PulseTypeRow'} key={i}>
        <Grid
          className={row.isActive ? 'pulse-type-row' : 'pulse-type-row pulse-type-row--inactive'}
          item
          xs={12}
        >
          <div className='pulse-type-title'>
            <Typography className='pulse-type-name'>{row.name}</Typography>
          </div>
          <div className='pulse-type-controls'>
            <div>{renderActiveText(row.isActive)}</div>
            <div>
              <IconButton
                size='small'
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  vm.editPulseCampaignType(row.objectId)
                }}
              >
                <EditIcon />
              </IconButton>
            </div>
          </div>
        </Grid>
      </div>
    )
  }

  const renderRows = () => {
    if (!vm.isLoaded) return null
    if (vm.pulseCampaignTypes.length <= 0) return null
    const rows = []
    vm.pulseCampaignTypes.forEach((row: PulseCampaignType, i: number) => {
      rows.push(renderRow(row, i))
    })
    return rows
  }

  const renderEmptyState = () => {
    if (vm.pulseCampaignTypes.length > 0) return null
    return (
      <EmptyState
        image={emptyResults}
        title={lz.no_pulse_campaign_types}
        subtitle1={lz.create_pulse_campaign_type}
        subtitle2={''}
        action={
          <Button
            onClick={() => vm.navigateToNewPulseCampaignType()}
            className={'empty-state-action-btn'}
            variant={'contained'}
          >
            {lz.create_pulse_campaign_type}
          </Button>
        }
      />
    )
  }

  return (
    <div id={'PulseCampaignTypesList'}>
      {renderSpinner()}
      {renderEmptyState()}
      {renderRows()}
    </div>
  )
}

export default inject('localizationStore')(observer(PulseCampaignTypesList))
