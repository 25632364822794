import React from 'react'

//MobX Stores
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../localization/LocalizationStore'
import { NotificationsStore } from '../../notifications/store/NotificationsStore'

//MaterialUI Components
import { CardHeader, IconButton } from '@material-ui/core'

//Material Icons
import CloseIcon from '@material-ui/icons/Close'

//Components Props Definition
interface DrawerHeaderProps {
  localizationStore?: LocalizationStore
  notificationsStore?: NotificationsStore
}

//Component
const DrawerHeader: React.FC<DrawerHeaderProps> = ({ localizationStore, notificationsStore }) => {
  const { lzStrings } = localizationStore
  const { editVM: widgetForm } = notificationsStore

  const handleClose = () => {
    widgetForm.closeEditor()
  }

  return (
    <CardHeader
      action={
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      }
      title={lzStrings.notificationsWidgetDrawer.edit}
    />
  )
}

export default inject('localizationStore', 'notificationsStore')(observer(DrawerHeader))
