import { action, observable, computed } from 'mobx'
import _ from 'lodash'
import { RootStore } from '../../stores/RootStore'
import { OrganizationUser } from '../../organization-users/aggregate/OrganizationUser'

export class SurveyStreakVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public async loadUser() {
    this.isProcessing = true
    const organizationUser = await this.rootStore.organizationUsersStore.getFullRecord(
      this.rootStore.userStore.currentOrgUserId
    )
    this.loadData(organizationUser)
  }

  @action
  private loadData(organizationUser: OrganizationUser) {
    this.organizationUser = organizationUser.clone()
    if (organizationUser.surveyStreak) {
      this.surveyStreak = organizationUser.surveyStreak
    }
    this.isProcessing = false
  }

  @observable public organizationUser: OrganizationUser = null
  @observable public surveyStreak: number = 0
  @observable public isProcessing: boolean = false

  @computed
  public get activeItem(): number {
    return Math.floor(this.surveyStreak / 5) * 5
  }

  @computed
  public get noStreak(): boolean {
    if (this.surveyStreak === 0) return true
    return false
  }

  @computed
  public get showSurveyStreak(): boolean {
    if (!this.rootStore.organizationsStore.currentOrganization) return false
    return this.rootStore.organizationsStore.currentOrganization.hasSurveyStreak
  }
}
