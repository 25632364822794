import { action, computed, observable } from 'mobx'
import moment from 'moment-timezone'
import { RootStore } from '../../stores/RootStore'
import { EventsService } from '../services/EventsService'
import { Event } from '../aggregate/Event'
import env from '../../../env'

export class ExportVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.toggleExportModal()
  }

  @observable isProcessing: boolean = false
  @observable startDate: any = null
  @observable endDate: any = null
  @observable fileType: string = 'CSV'
  @observable calendarLink: any = ''
  @observable public showExportModal: boolean = false
  @observable public fileTypeError: boolean = false
  @observable public copiedToClipboard: boolean = false
  @observable public tabValue: number = 0

  @action
  public setFileType(type: string) {
    this.fileType = type
    this.fileTypeError = false
  }

  @action
  public setStartDate(date: Date) {
    this.startDate = date
  }

  @action
  public setToDate(date: Date) {
    this.endDate = date
  }

  @action
  public toggleExportModal() {
    this.showExportModal = !this.showExportModal
    if (this.showExportModal) {
      this.startDate = this.rootStore.eventsStore.calendarVM.visibleStartDate
      this.endDate = this.rootStore.eventsStore.calendarVM.visibleEndDate
    }
  }

  @action
  public setTabValue(value) {
    this.tabValue = value
  }

  @action
  public copyLinkToClipboard() {
    if (!this.calendarLink) return
    var copyText = document.getElementById('icsLinkInput') as HTMLInputElement
    copyText.select()
    document.execCommand('copy')
    this.copiedToClipboard = true
    setTimeout(() => (this.copiedToClipboard = false), 2000)
  }

  @action
  public async getUserIcsLink() {
    const { currentUserId, currentOrgId } = this.rootStore.appStore
    const svc = new EventsService()
    const result = await svc.getUserIcsLink(currentUserId, currentOrgId)
    this.calendarLink = result
  }

  @action
  public exportEvents() {
    this.isProcessing = true
    const tz = moment.tz.guess()
    console.log(tz)
    const startDate = moment(this.startDate).format()
    const endDate = moment(this.endDate).format()
    const token = this.rootStore.userStore.parseUserInfo.sessionToken
    let url = env.var.REACT_APP_API_URL + '/events/exporttocsv?'
    url +=
      'orgId=' +
      this.rootStore.appStore.currentOrgId +
      '&startDate=' +
      startDate +
      '&endDate=' +
      endDate +
      '&userId=' +
      this.rootStore.appStore.currentUserId +
      '&languageCode=' +
      this.rootStore.localizationStore.lzStrings.name +
      '&dt=' +
      new Date() +
      '&timeZone=' +
      tz +
      '&sessionToken=' +
      token
    window.location.href = url
    setTimeout(() => (this.isProcessing = false), 10000)
  }
}
