import Parse from 'parse'
import { action, observable, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { TenantLink } from '../aggregate/TenantLink'
import { OrganizationLinksVM } from './OrganizationLinksVM'
import uuid from 'uuid/v4'
import { OrganizationWelcomeBannerVM } from './OrganizationWelcomeBannerVM'
import { WelcomeBanner } from '../aggregate/WelcomeBanner'
import { WelcomeBannerAttachmentVM } from './WelcomeBannerAttachmentVM'
import { Attachment } from '../../attachments/aggregate/Attachment'
import { isNumeric } from '../../shared/isNumeric'
import { OldAttachmentVM } from '../../attachments/view-models/OldAttachmentVM'
import { AttachmentVM } from '../../attachments/view-models/AttachmentVM'

export class WelcomeBannerEditVM {
  private rootStore: RootStore

  constructor(
    rootStore: RootStore,
    parentVM: OrganizationWelcomeBannerVM,
    welcomeBanner: WelcomeBanner
  ) {
    this.rootStore = rootStore
    this.parentVM = parentVM
    this.welcomeBanner = welcomeBanner
  }

  @observable public welcomeBanner: WelcomeBanner = null
  @observable public parentVM: OrganizationWelcomeBannerVM = null
  @observable public saveTried: boolean = false
  @observable public showUploadModal: boolean = false
  @observable public editorTabIndex: number = 0

  @computed
  public get titleValid(): boolean {
    if (!this.saveTried) return true
    if (this.welcomeBanner.title === '') return false
    if (!this.welcomeBanner.title) return false
    return true
  }

  @computed
  public get bodyValid(): boolean {
    if (!this.saveTried) return true
    if (this.welcomeBanner.body === '') return false
    if (!this.welcomeBanner.body) return false
    return true
  }

  @computed
  public get isNew(): boolean {
    return this.parentVM.isNew
  }

  @computed
  public get title(): string {
    return this.welcomeBanner.title
  }

  @computed
  public get body(): string {
    return this.welcomeBanner.body
  }

  @computed
  public get isValid(): boolean {
    if (!this.titleValid) return false
    if (!this.body) return false
    return true
  }

  @computed
  public get attachments(): AttachmentVM[] {
    return this.welcomeBanner.attachments.map((e, idx) => {
      if (isNumeric(e.objectId) || e.cmsItemId) {
        return this.rootStore.cmsItemAttachmentStore.loadAttachment(e)
      }
      return new OldAttachmentVM(this.rootStore, e, idx)
    })
  }

  @action
  public setEditorTabIndex(value: number) {
    this.editorTabIndex = value
  }

  public setTitle(title) {
    this.welcomeBanner.setTitle(title)
  }

  public setBody(title) {
    this.welcomeBanner.setBody(title)
  }

  public removeWelcomeBanner() {
    this.parentVM.removeWelcomeBannerFromOrg()
    this.cancel()
  }

  @action
  public removeAttachmentByIndex(index: number) {
    this.welcomeBanner.removeAttachmentByIndex(index)
  }

  @action
  public removeAttachmentByObjectId(objectId: string) {
    this.welcomeBanner.removeAttachmentByObjectId(objectId)
  }

  public toggleShowUploadModal() {
    this.showUploadModal = !this.showUploadModal
  }

  @action
  public addAttachments(attachments: Attachment[]) {
    attachments.forEach((a: Attachment) => this.welcomeBanner.addAttachment(a))
  }

  public cancel() {
    this.parentVM.clearWelcomeBannerEditVM()
  }

  public async apply() {
    this.saveTried = true
    if (!this.isValid) return null
    this.parentVM.addWelcomeBannerToOrg(this.welcomeBanner)
    this.cancel()
  }
}
