import { observable, action } from 'mobx'
import { serializable } from 'serializr'
import { AlarmPeriodType } from '../types/AlarmPeriodType'

export class Alarm {
  public static create(index: number): Alarm {
    const alarm = new Alarm()
    alarm.index = index
    alarm.units = 1
    alarm.period = 'hours'
    return alarm
  }

  @serializable @observable public index: number
  @serializable @observable public units: number
  @serializable @observable public period: AlarmPeriodType

  @action
  public setUnits(val: number) {
    this.units = Number(val)
  }

  @action
  public setPeriod(val: AlarmPeriodType) {
    this.period = val
  }

  @action
  public setIndex(val) {
    this.index = val
  }
}
