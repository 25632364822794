import { action, observable, computed } from 'mobx'
import { CategoryForm } from './CategoryForm'
import { RootStore } from '../../stores/RootStore'
import { Category } from '../aggregate/Category'
import { ParseService } from '../../services/ParseService'

export default class CategoryEditForm implements CategoryForm {
  rootStore: RootStore

  constructor(rootStore: RootStore, category: Category) {
    this.rootStore = rootStore
    this.loadData(category)
  }

  private loadData(category: Category) {
    this.category = category
    this.name = category.name
    this.description = category.description
    this.parentCategoryId = category.parentCategoryId
    this.availableForSurveys = category.availableForSurveys
    this.availableForTasks = category.availableForTasks
  }

  private category: Category
  @observable public name: string = ''
  @observable public description: string = ''
  @observable public parentCategoryId: string = ''
  @observable public availableForSurveys: boolean = false
  @observable public availableForTasks: boolean = false
  @observable public saveTried: boolean = false

  //Widget UI
  @observable isDrawerOpen: boolean = false

  @action openDrawer() {
    this.isDrawerOpen = true
  }

  @action closeDrawer() {
    this.isDrawerOpen = false
  }

  @action
  public markSaveTried() {
    this.saveTried = true
  }

  @computed
  public get isValid() {
    if (!this.saveTried) return true
    if (!this.nameValid) return false
    if (!this.nameUnique) return false
    return true
  }

  @computed
  public get nameValid() {
    if (!this.saveTried) return true
    if (!this.name || this.name.trim() === '') return false
    return true
  }

  @computed
  public get nameUnique() {
    if (!this.saveTried) return true
    const foundCategory = this.rootStore.categoriesStore.getCategoryByName(this.name)
    if (
      foundCategory &&
      foundCategory.objectId !== this.rootStore.categoriesStore.selectedCategoryId
    )
      return false
    return true
  }

  @action
  public async save() {
    this.markSaveTried()
    if (!this.isValid) return false
    try {
      const newCategory = {
        objectId: this.category.objectId,
        name: this.name,
        description: this.description,
        parentCategoryId: this.parentCategoryId,
        availableForSurveys: this.availableForSurveys,
        availableForTasks: this.availableForTasks,
        isDeleted: false,
      }
      await new ParseService().saveCategory(this.rootStore.appStore.currentOrgId, newCategory)
      this.rootStore.categoriesStore.clearForms()
    } catch (e) {
      console.error(e)
    }
  }

  @action
  public async delete() {
    try {
      await new ParseService().deleteCategory(
        this.rootStore.appStore.currentOrgId,
        this.category.objectId
      )
      this.rootStore.categoriesStore.deleteCategory(this.category.objectId)
      this.rootStore.categoriesStore.clearForms()
    } catch (e) {
      console.error(e)
    }
  }
}
