import React from 'react'
import { inject, observer } from 'mobx-react'
import { Grid, Paper, Typography } from '@material-ui/core'
import LocalizationStore from '../../../../localization/LocalizationStore'
import ExerciseSets from './ExerciseSets'
import { UserTrainingPlanTakeVM } from '../../view-models/UserTrainingPlanTakeVM'
import CMSMediaItemAttachment from './CMSMediaItemAttachment'
import OldMediaItemAttachment from './OldMediaItemAttachment'
import { CMSMediaItemAttachmentVM } from '../../../../training-items/view-models/CMSMediaItemAttachmentVM'
import { OldMediaItemAttachmentVM } from '../../../../training-items/view-models/OldMediaItemAttachmentVM'
import './ContentItem.scss'

interface Props {
  takeVM: UserTrainingPlanTakeVM
  localizationStore?: LocalizationStore
}

const Exercise: React.FC<Props> = ({ takeVM, localizationStore }) => {
  const { selectedContentItem: item } = takeVM
  const { training_items: lz } = localizationStore.lzStrings

  if (!item) return null

  const renderExerciseInfo = () => {
    if (item.type !== 'exercise') return
    return (
      <>
        <div id='ContentItemExercise'>
          <Grid
            container
            spacing={1}
            direction='row'
            alignContent='flex-start'
            justifyContent='flex-start'
            className='exercise-take'
          >
            <Grid item xs={7} className='exercise-left'>
              <form className={'create-dialog-container'}>
                <Typography variant='h6' className='input-label-header'>
                  {lz.set_performance_goal}
                </Typography>
                <Grid
                  container
                  spacing={3}
                  alignContent='flex-start'
                  justifyContent='flex-start'
                  className='exercise-items'
                  direction='column'
                >
                  <ExerciseSets vm={item} />
                </Grid>
              </form>
            </Grid>
            <Grid item xs={5} className='exercise-right'>
              <form className={'create-dialog-container'}>
                <Typography variant='h6' className='input-label-header'>
                  {lz.input_exercise_result}
                </Typography>
                <div className='exercise-info-card'>
                  <Paper className='exercise-info-card-paper' elevation={3}>
                    <Grid
                      container
                      spacing={3}
                      alignContent='flex-start'
                      justifyContent='space-evenly'
                      className='exercise-items'
                    >
                      <Grid item xs={2} className='exercise-item'>
                        <Typography variant='body1' className='input-label-center'>
                          {lz.sets}
                        </Typography>
                        <Typography variant='body1' className='input-value-center'>
                          {item.sets}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} className='exercise-item'>
                        <Typography variant='body1' className='input-label-center'>
                          {lz.reps}
                        </Typography>
                        <Typography variant='body1' className='input-value-center'>
                          {item.reps}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} className='exercise-item'>
                        <Typography variant='body1' className='input-label-center'>
                          {lz.weight}
                        </Typography>
                        <Typography variant='body1' className='input-value-center'>
                          {item.weight}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} className='exercise-item'>
                        <Typography variant='body1' className='input-label-center'>
                          {lz.time}
                        </Typography>
                        <Typography variant='body1' className='input-value-center'>
                          {item.time}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} className='exercise-item'>
                        <Typography variant='body1' className='input-label-center'>
                          {'%1RM'}
                        </Typography>
                        <Typography variant='body1' className='input-value-center'>
                          {item.percent}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </div>
              </form>
            </Grid>
          </Grid>
        </div>
      </>
    )
  }

  const renderExerciseContentItem = () => {
    if (item.type !== 'exercise') return null
    const renderImageOrVideo = () => {
      if (!item.media) return null
      const doc = item.attachment

      if (doc.isCMSItem) return <CMSMediaItemAttachment vm={doc as CMSMediaItemAttachmentVM} />
      return <OldMediaItemAttachment vm={doc as OldMediaItemAttachmentVM} />
    }
    return (
      <>
        <Grid item xs={12}>
          <div className='media-background'>{renderImageOrVideo()}</div>
          <div className='exercise-content-item'>{renderExerciseInfo()}</div>
        </Grid>
      </>
    )
  }

  return <>{renderExerciseContentItem()}</>
}

export default inject('localizationStore')(observer(Exercise))
