import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { AppBar, Toolbar, Button } from '@material-ui/core'
import { WorksheetsStore } from '../../../store/WorksheetsStore'

interface Props {
  localizationStore?: LocalizationStore
  worksheetsStore?: WorksheetsStore
}

const Buttons: React.FC<Props> = ({ worksheetsStore }) => {
  const { chartWidgetDrawerVM } = worksheetsStore

  const handleSave = () => {
    chartWidgetDrawerVM.save()
  }

  return (
    <div>
      <AppBar position='relative' elevation={0}>
        <Toolbar disableGutters={true} variant='dense'>
          <Button variant='contained' onClick={handleSave}>
            Save
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default inject('worksheetsStore')(observer(Buttons))
