import { observable, action } from 'mobx'
import { serializable, serialize, deserialize } from 'serializr'
import { ILabelDTO } from '../dto/ILabelDTO'

export class Label {
  static create(orgId) {
    const label = new Label()
    label.organizationId = orgId
    return label
  }

  @serializable @observable objectId: string = ''
  @serializable @observable organizationId: string = ''
  @serializable @observable selector: string = ''
  @serializable @observable isDefault: boolean = true
  @serializable @observable language: string = ''
  @serializable @observable value: string = ''
  @observable isDirty: boolean = false
  isOnServer: boolean = false

  @action
  public setValue(val) {
    this.value = val
    this.isDefault = false
    this.isDirty = true
  }

  @action
  public setOrganization(val) {
    this.organizationId = val
  }

  public clone(): Label {
    return deserialize(Label, this.serialize())
  }

  public serialize(): ILabelDTO {
    return serialize(this)
  }
}
