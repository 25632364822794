import React from 'react'

//MobX Stores
import { observer, inject } from 'mobx-react'
import { QuestionsStore } from '../store/QuestionsStore'

//MaterialUI Components
import { Button, Icon, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core'
import { MenuProps } from '@material-ui/core/Menu'

//Material Icons
import AdjustIcon from '@material-ui/icons/Adjust'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import DropDownRoundedIcon from '@material-ui/icons/ArrowDropDownCircleRounded'

//Styles
import { withStyles } from '@material-ui/core/styles'
import './DrawerResponseTypeMenu.scss'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles((theme) => ({
  // root: {
  //   '&:focus': {
  //     backgroundColor: theme.palette.primary.main,
  //     '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
  //       color: theme.palette.common.white,
  //     },
  //   },
  // },
}))(MenuItem)

interface DrawerEmojiMenuProps {
  questionsStore?: QuestionsStore
  idx: number
}
const EmojiMenu: React.FC<DrawerEmojiMenuProps> = ({ questionsStore, idx }) => {
  const { editVM: QuestionCatalogWidgetForm } = questionsStore

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const emojiClicked = (name) => {
    QuestionCatalogWidgetForm.options[idx].handleEmojiChange(name)
    handleClose()
  }

  const getEmojis = (idx) => {
    let imageItems = require.context('../../../assets/emoticons/png').keys()
    let images = imageItems.map((imageName) => {
      imageName = imageName.substring(imageName.indexOf('/') + 1)
      imageName = imageName.substring(0, imageName.indexOf('.'))
      const isSelected = selectedEmoji === imageName
      return (
        <div key={'img' + idx + imageName} onClick={() => emojiClicked(imageName)}>
          <ListItemIcon>
            <img
              className={isSelected ? 'selected' : ''}
              src={'../../../assets/emoticons/png/' + imageName + '.png'}
              style={{
                width: 20,
                height: 20,
                margin: 5,
              }}
              alt=''
            />
          </ListItemIcon>
        </div>
      )
    })
    return images
  }

  const { emoji: selectedEmoji } = QuestionCatalogWidgetForm.options[idx]
  const type = QuestionCatalogWidgetForm.questionType

  return (
    <div>
      <Button className='emoji-button' onClick={handleClick} disabled={type !== 'emoji'}>
        {selectedEmoji ? (
          <img
            src={'../../../assets/emoticons/png/' + selectedEmoji + '.png'}
            style={{ width: 20, height: 20 }}
            alt=''
          />
        ) : null}
        {type === 'yesNo' ? <RadioButtonUncheckedIcon className='icon' /> : null}
        {type === 'text' ? null : null}
        {type === 'order' ? null : null}
        {type === 'rating' ? <Icon className='icon'>{idx + 1}</Icon> : null}
        {type === 'starRating' ? <Icon className='icon'>{idx + 1}</Icon> : null}
        {type === 'check' ? (
          <Icon className='icon'>
            <i className='far fa-check-square'></i>
          </Icon>
        ) : null}
        {type === 'radio' ? <AdjustIcon className='icon' /> : null}
        {type === 'dropdown' ? <DropDownRoundedIcon className='icon' /> : null}
      </Button>

      {anchorEl && (
        <StyledMenu
          id='customized-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: 200,
              flexWrap: 'wrap',
              justifyContent: 'space-evenly',
            }}
          >
            {QuestionCatalogWidgetForm.questionType === 'emoji' ? getEmojis(idx) : null}
          </div>
        </StyledMenu>
      )}
    </div>
  )
}

export default inject('questionsStore')(observer(EmojiMenu))
