import React, { FC } from 'react'
import { observer } from 'mobx-react'
import {
  Modal,
  Paper,
  Toolbar,
  Typography,
  IconButton,
  Checkbox,
  Grid,
  FormControlLabel,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { ClientRowVM } from '../view-models/ClientRowVM'
import ContactsList from './ContactsList'

interface Props {
  clientRowVM: ClientRowVM
}

const ContactsListModal: FC<Props> = ({ clientRowVM }) => {
  const vm = clientRowVM

  const renderCheckBox = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={vm.isParticipantSelected}
            onClick={(e) => {
              e.stopPropagation()
              vm.toggleParticipant()
            }}
          />
        }
        label='Select All'
      />
    )
  }

  const renderToolBar = () => {
    return (
      <Toolbar className='modal-toolbar'>
        <div className='left-toolbar'>
          <Typography variant='h6'>{vm.name}</Typography>
        </div>
        <IconButton
          onClick={(e) => {
            e.stopPropagation()
            vm.closeContactsListModal()
          }}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
    )
  }

  return (
    <Modal
      disableBackdropClick={true}
      onBackdropClick={(e) => vm.closeContactsListModal()}
      onClose={() => vm.closeContactsListModal()}
      open={vm.isContactsListModalOpen}
      id='UsersListModal'
    >
      <Paper className='modal-list-container'>
        <Grid container direction='column'>
          <Grid item>{renderToolBar()}</Grid>
          <Grid item onClick={(e) => e.stopPropagation()} className='search-bar'>
            {renderCheckBox()}
          </Grid>
        </Grid>
        <ContactsList contactsAGGridVM={vm.contactsAGGridVM} />
      </Paper>
    </Modal>
  )
}

export default observer(ContactsListModal)
