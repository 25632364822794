import React from 'react'
import { inject, observer } from 'mobx-react'
import { LocationsStore } from '../../../../locations/store/LocationsStore'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { CardContent, Drawer, Grid, Button, InputLabel, TextField } from '@material-ui/core'
import MuiDrawerToolbar from '../../../../shared/Mui-Drawer/MuiDrawerToolbar'
import './LocationsDrawer.scss'
import Map from '../../../../components/Map/Map'
import PlacesSearchBarV2 from '../../../../components/PlacesSearchBar/PlacesSearchBarV2'
import MuiDrawerHeader from '../../../../shared/Mui-Drawer/MuiDrawerHeader'

interface Props {
  locationsStore?: LocationsStore
  localizationStore?: LocalizationStore
}

const LocationsDrawer: React.FC<Props> = ({ locationsStore, localizationStore }) => {
  const { editForm } = locationsStore
  const { lzStrings } = localizationStore

  const handleSubmitClick = () => {
    editForm.save()
    locationsStore.selectedLocationId = ''
  }

  const handleLatLngChange = (lat, lng) => {
    editForm.lat = lat
    editForm.lng = lng
  }

  const handlePlaceConfirm = () => {
    editForm.toggleEditAddress()
  }

  const handlePlaceCancel = () => {
    editForm.handlePlaceCancel()
  }

  const handlePlaceSelection = (place) => {
    editForm.handlePlaceSelection(place)
  }

  const handleEditAddress = () => {
    editForm.handleEditCoordinates()
  }

  const toggleDrawer = () => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    locationsStore.showDrawer = !locationsStore.showDrawer
    if (locationsStore.showDrawer === false) locationsStore.selectedLocationId = ''
  }

  return (
    <div id='LocationsDrawer'>
      {editForm && (
        <Drawer
          variant='temporary'
          anchor='right'
          open={locationsStore.showDrawer}
          onClose={toggleDrawer()}
        >
          <MuiDrawerHeader
            title={
              editForm.objectId
                ? lzStrings.locationEdit.manage_location
                : lzStrings.locationEdit.add_location
            }
            toggleDrawer={toggleDrawer()}
          />
          <MuiDrawerToolbar
            handleCancelClick={toggleDrawer()}
            handleSubmitClick={() => handleSubmitClick()}
            showReset={Boolean(editForm.objectId)}
            onConfirmReset={() => locationsStore.setSelectedLocationId(editForm.objectId)}
            moreMenuProps={{
              isEditMode: editForm.isEditMode,
              itemType: localizationStore.lzStrings.moreMenu.obj_type.location,
              itemName: editForm.name,
              onConfirmDelete: () => editForm.delete(),
            }}
          />
          <CardContent id='LocationsBasicInfo'>
            <h6>{lzStrings.locationEdit.basic_info}</h6>
            <form autoComplete='off' noValidate>
              <Grid container>
                <Grid item md={12} sm={12}>
                  <InputLabel required htmlFor='name'>
                    {lzStrings.locationEdit.name}
                  </InputLabel>
                  <TextField
                    error={!editForm.nameValid || !editForm.nameUnique}
                    value={editForm.name}
                    onChange={(e) => (editForm.name = e.target.value)}
                    fullWidth
                    helperText={editForm.errorHelperText}
                    variant='outlined'
                    id='name'
                  />
                </Grid>
              </Grid>
              <Grid container className='addressContainer'>
                <Grid item md={12} sm={12} className='coordinates-header'>
                  <InputLabel className='input-label' htmlFor='address'>
                    {lzStrings.locationEdit.address}
                  </InputLabel>
                  {!editForm.editAddress && (
                    <Button variant='outlined' onClick={() => handleEditAddress()}>
                      {lzStrings.locationEdit.edit_address}
                    </Button>
                  )}
                  {editForm.editAddress && (
                    <PlacesSearchBarV2
                      placeSelected={(place) => handlePlaceSelection(place)}
                      latLngChange={handleLatLngChange}
                      value={''}
                      onSave={handlePlaceConfirm}
                      onCancel={handlePlaceCancel}
                    />
                  )}
                </Grid>
                <Grid item md={12} sm={12}>
                  {editForm.lat && (
                    <div className='google-map-container'>
                      <Map lat={editForm.lat} lng={editForm.lng} className='google-map' />
                    </div>
                  )}
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Drawer>
      )}
    </div>
  )
}

export default inject('locationsStore', 'localizationStore')(observer(LocationsDrawer))
