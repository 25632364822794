import React from 'react'
import { observer } from 'mobx-react'
import { Grid } from '@material-ui/core'
import ContentOptionMenu from './../CMSItemsOptionMenu'
import { CMSItemVM } from '../../view-models/CMSItemVM'
import { CMSItemsLayoutTableRowVM } from '../../view-models/CMSItemsLayoutTableRowVM'
import './ActionsCell.scss'

const ActionsCell = (props) => {
  const data: CMSItemsLayoutTableRowVM = props.data
  if (!data) return <div></div>

  const item = data.item
  if (!item) return <div></div>

  const renderMenu = (item: CMSItemVM) => {
    return (
      <ContentOptionMenu
        key={`action-menu-${item.title}-${item.itemId}`}
        vm={item}
        previewVM={item.viewModels.previewVM}
      />
    )
  }

  const renderPreview = (item: CMSItemVM) => {
    return (
      <Grid item xs={12} key={0} className='preview-container'>
        {renderMenu(item)}
      </Grid>
    )
  }

  return <div id='ActionsCell'>{renderPreview(item)}</div>
}

export default observer(ActionsCell)
