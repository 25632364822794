import { observable, action, reaction, computed } from 'mobx'
import { UserSurvey } from '../aggregate/UserSurvey'
import { RootStore } from '../../stores/RootStore'
import Parse from 'parse'
import { UserSurveyTakeVM } from '../view-models/UserSurveyTakeVM'
import { PendingUserSurveysWidgetVM } from '../view-models/pending-widget/PendingUserSurveysWidgetVM'
import { UserSurveysService } from '../service/UserSurveysService'
import * as Sentry from '@sentry/browser'
import { deserialize } from 'serializr'
import { DataStore } from '../../shared/data/DataStore'
import { IUserSurveyDTO } from '../dtos/IUserSurveyDTO'
import { IUserSurveyFindResult } from '../interfaces/IUserSurveyFindResult'
import { OrganizationsService } from '../../organizations/service/OrganizationsService'
import { IOrganizationMobileAppIdFindRequest } from '../../organizations/interfaces/IOrganizationMobileAppIdFindRequest'
import { IOrganizationMobileAppIdFindResult } from '../../organizations/interfaces/IOrganizationMobileAppIdFindResult'
import isMobile from '../../../utils/isMobile'
import { platform } from '../../../utils/getPlatform'

export class UserSurveysStore extends DataStore<UserSurvey, IUserSurveyDTO> {
  private loadedUsers: string[] = []

  constructor(rootStore: RootStore) {
    super(rootStore, UserSurvey, 'userSurveys', [
      'objectId',
      'organizationId',
      'name',
      'publishedByUserId',
      'dueDate',
      'questionsCount',
      'categoryIds',
      'publishedDateTime',
      'isRead',
      'questionsFlowFormat',
      'questionsCount',
      'surveyIntroduction',
      'userId',
      'surveyId',
      'showResults',
      'alwaysAvailable',
      'isWelcomeEmail',
      'surveyCompletedContent',
    ])
    this.reactToWatchingUsers()
  }

  private reactToWatchingUsers() {
    if (process.env.NODE_ENV === 'test') return
    reaction(
      () => this.watchingUsers,
      () => {
        if (!this.listRecordsLoaded) return
        this.loadListRecords()
      },
      { delay: 500 }
    )
  }

  protected getBaseQuery() {
    const query = new Parse.Query(this.className)
    query.equalTo('organizationId', this.rootStore.appStore.currentOrgId)
    query.containedIn('userId', this.watchingUsers)
    return query
  }

  protected getFullColumns() {
    return ['questions', 'userTaskId']
  }

  @computed
  public get watchingUsers() {
    if (!this.rootStore.eventsStore) {
      return [this.rootStore.appStore.currentUserId]
    }
    const list = this.rootStore.eventsStore.calendarVM.selectedOtherCalendars
      .map((e) => e.id)
      .concat(this.rootStore.appStore.currentUserId)
      .sort((a, b) => (a < b ? -1 : 0))
    const set = new Set(list)
    return Array.from(set)
  }

  public shouldLoadListRecords() {
    if (this.loadedUsers.length === 0) return true
    return JSON.stringify(this.loadedUsers) !== JSON.stringify(this.watchingUsers)
  }

  @action
  private setLoadedUsers(userIds: string[]) {
    this.loadedUsers = userIds
  }

  public onListRecordsLoaded() {
    this.setLoadedUsers(this.watchingUsers.slice())
    this.pendingUserSurveysWidgetVM = new PendingUserSurveysWidgetVM(this.rootStore)
  }

  @observable public takeVM: UserSurveyTakeVM = null
  @observable public pendingUserSurveysWidgetVM: PendingUserSurveysWidgetVM = null
  @observable public anonymousSurveyNotFound: boolean = false

  @action
  public async loadPaperSurveyTakeVM(userSurveyObjectId: string) {
    const query = new Parse.Query('userSurveys')
    query.equalTo('objectId', userSurveyObjectId)
    query.equalTo('organizationId', this.rootStore.appStore.currentOrgId)
    const responseObj: any = await query.first() //TODO: Move this Query to UserSurveysService. We don't make queries too randomly
    if (!responseObj) {
      console.error(`userSurvey (paper survey) with objectId ${userSurveyObjectId} not found.`)
      Sentry.captureException({
        message: `userSurvey (paper survey) with objectId ${userSurveyObjectId} not found.`,
        userId: this.rootStore.appStore.currentUserId,
        orgId: this.rootStore.appStore.currentOrgId,
      })
      return
    }
    const userSurvey = deserialize(UserSurvey, responseObj.toJSON()) as UserSurvey
    this.takeVM = new UserSurveyTakeVM(this.rootStore, userSurvey, 'load takeVM for paper survey')
  }

  @action
  public async loadTakeVM(userSurveyId: string) {
    this.takeVM = undefined
    const foundUserSurvey = await this.getFullRecord(userSurveyId)
    if (!foundUserSurvey) {
      setTimeout(() => this.loadTakeVM(userSurveyId), 1000)
      return
    }
    this.takeVM = new UserSurveyTakeVM(this.rootStore, foundUserSurvey, 'load takeVM')
    if (this.takeVM) {
      // TODO: Move this to the TakeVM constructor, but skip if under test.
      // Lots of things happen when a TakeVM is constructed. This is just another thing.
      // Dont let actions like this bleed out all over the app.
      const svc = new UserSurveysService(this.rootStore)

      svc.markUserSurveyAsRead(
        foundUserSurvey.objectId,
        foundUserSurvey.organizationId,
        this.takeVM.isLoggedIn
      )
    }
    setTimeout(async () => {
      const svc = new UserSurveysService(this.rootStore)
      await svc.markAsRead(foundUserSurvey.objectId, this.takeVM.isLoggedIn)
    }, 100)
  }

  @action
  public async loadAlwaysAvailableUserSurveyTakeVM(surveyId: string) {
    this.takeVM = undefined
    const svc = new UserSurveysService(this.rootStore)
    const result: IUserSurveyFindResult = await svc.getUserSurveyFromAlwaysAvailable(surveyId)
    const userSurveyDTO = result.userSurvey
    if (!userSurveyDTO) {
      console.error(`userSurvey (always available) with objectId ${surveyId} not found.`)
      Sentry.captureException({
        message: `userSurvey (always available) with objectId ${surveyId} not found.`,
        userId: this.rootStore.appStore.currentUserId,
        orgId: this.rootStore.appStore.currentOrgId,
      })
    }
    const userSurvey = deserialize(UserSurvey, userSurveyDTO)
    this.takeVM = new UserSurveyTakeVM(this.rootStore, userSurvey, 'load alwaysAvailable')
    this.takeVM.isForAlwaysAvailable = true
  }

  @action
  public async loadAnonymousTakeVM(token: string, orgId?: string) {
    console.log('LOOKING FOR USER SURVEY BY TOKEN: ' + token)
    this.rootStore.appStore.setTheme('')
    try {
      const svc = new UserSurveysService(this.rootStore)
      const userSurveyDTO = await svc.findByToken(token)
      if (!userSurveyDTO) {
        this.anonymousSurveyNotFound = true
        console.log('USER SURVEY FOR THAT TOKEN WAS NOT FOUND')
        return
      }
      const userSurvey = deserialize(UserSurvey, userSurveyDTO)
      this.takeVM = new UserSurveyTakeVM(this.rootStore, userSurvey, 'load anon vm')
      this.takeVM.anonymousToken = token
      if (isMobile) {
        this.tryRouteToMobile(token, orgId)
      }
    } catch (error) {
      const errorObject = JSON.parse(JSON.stringify(error))
      if (
        errorObject.code === 209 &&
        String(errorObject.message).includes('Invalid session token')
      ) {
        this.rootStore.appStore.logout(
          'UserSurveysStore: error loading anonymous user survey',
          true
        )
      } else {
        throw error
      }
    }
  }

  @computed
  public get currentOrgUserSurveys() {
    return this.records.filter((e) => e.organizationId === this.rootStore.appStore.currentOrgId)
  }

  public getUserSurvey(objectId): UserSurvey {
    return this.records.find((e) => e.objectId === objectId)
  }

  @action
  public async tryRouteToMobile(token: string, orgId: string) {
    if (!token) return
    if (!orgId) return
    const svc = new OrganizationsService(this.rootStore)
    const req: IOrganizationMobileAppIdFindRequest = {
      organizationId: orgId,
      platform: platform,
    }
    const result: IOrganizationMobileAppIdFindResult = await svc.getOrganizationMobileAppId(req)
    if (!result.success) return
    if (!result.mobileAppId) return
    window.location.replace(`${result.mobileAppId}://usersurveys/${token}`)
  }
}
