import Parse from 'parse'
import { IAPIResponse } from '../../shared/IAPIResponse'
import { ISurveyTemplatesBulkRequest } from '../interfaces/ISurveyTemplatesBulkRequest'

export class SurveyTemplatesBulkOperationsService {

  public async copyTemplatesOperation(request: ISurveyTemplatesBulkRequest): Promise<IAPIResponse> {
    return await Parse.Cloud.run('copyTemplatesBulkOperation', { request})
  }

  public async deleteTemplatesOperation(request: ISurveyTemplatesBulkRequest): Promise<IAPIResponse> {
    return await Parse.Cloud.run('deleteTemplatesBulkOperation', { request })
  }

  public async moveTemplatesOperation(request: ISurveyTemplatesBulkRequest): Promise<IAPIResponse> {
    return await Parse.Cloud.run('moveTemplatesBulkOperation', { request })
  }

}
