import { action, computed, observable } from 'mobx'
import moment from 'moment'
import { RootStore } from '../../../../stores/RootStore'
import { IPulseCampaignFindRequest } from '../../../interfaces/IPulseCampaignFindRequest'
import { CampaignsListVM } from './CampaignsListVM'
import { FilterBarVM } from './FilterBarVM'

export class AllCampaignsCardVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.filterBarVM = new FilterBarVM(this.rootStore, this)
    this.campaignsListVM = new CampaignsListVM(this.rootStore, this)
  }

  @observable public filterBarVM: FilterBarVM = null
  @observable public campaignsListVM: CampaignsListVM = null

  @action
  public onFilterChanged() {
    const filters = {
      status: undefined,
    } as IPulseCampaignFindRequest
    filters.terms = this.filterBarVM.typedFilterText
    filters.status = this.filterBarVM.selectedStatuses[0]
    filters.dateStart = moment(this.filterBarVM.startDate).toISOString()
    filters.dateEnd = moment(this.filterBarVM.endDate).toISOString()
    this.campaignsListVM.dataStore.setRequest({
      ...this.campaignsListVM.dataStore.getRequest(),
      ...filters,
    })
    this.campaignsListVM.dataStore.loadListRecords()
  }
}
