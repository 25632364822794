import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { CardContent, Button, CircularProgress } from '@material-ui/core'
import { ChartWidgetVM } from '../../view-models/chart-widget/ChartWidgetVM'
import TableauReport from '../TableauReport'
import './Content.scss'
import { TableauScriptService } from '../../../tableau/service/TableauScriptService'

interface Props {
  widgetVM: ChartWidgetVM
}

const Content: React.FC<Props> = ({ widgetVM }) => {
  const [scriptLoaded, setScriptLoaded] = useState(false)
  useEffect(() => {
    TableauScriptService.loadTableauScript(() => setScriptLoaded(true))
  })

  const renderSelectChart = () => {
    if (widgetVM.hasWorksheet) return null
    return (
      <div className='no-chart'>
        <img src='../../../assets/img/undraw_Growing_qwt2.svg' alt='' />
        <Button variant='contained' onClick={() => widgetVM.openDrawer()}>
          Select a Chart to Display Here
        </Button>
      </div>
    )
  }

  const renderSpinner = () => {
    if (widgetVM.width === 0) return
    if (!widgetVM.hasPermission) return
    if (widgetVM.tableauAuthToken) return
    return (
      <CircularProgress color='secondary' style={{ position: 'fixed', top: '50%', left: '50%' }} />
    )
  }

  const renderNoPermission = () => {
    if (widgetVM.isLoading) return
    if (widgetVM.hasPermission) return
    return (
      <div className='not-authorized'>
        This account does not have permission to access this visualization, please contact your
        administrator.
      </div>
    )
  }

  const renderReport = () => {
    if (widgetVM.width === 0) return
    if (!widgetVM.tableauAuthToken) return
    if (!scriptLoaded) return
    return (
      <TableauReport
        key={widgetVM.tableauViewUrl + widgetVM.tableauAuthToken + widgetVM.tableauQuery}
        token={widgetVM.tableauAuthToken}
        query={widgetVM.tableauQuery}
        onVizLoaded={(div, viz) => widgetVM.setTableauVizContainer(div, viz)}
        options={{
          width: widgetVM.width.toString() + 'px',
          height: widgetVM.height.toString() + 'px',
          device: 'desktop',
          hideTabs: !widgetVM.showTabs,
        }}
        url={widgetVM.tableauViewUrl}
      />
    )
  }

  const renderChart = () => {
    if (!widgetVM.hasWorksheet) return
    return (
      <div id={'div' + widgetVM.key} className='chart-container'>
        {renderReport()}
      </div>
    )
  }

  return (
    <CardContent id='Content'>
      {renderNoPermission()}
      {renderSpinner()}
      {renderSelectChart()}
      {renderChart()}
    </CardContent>
  )
}

export default observer(Content)
