import React from 'react'
import { inject, observer } from 'mobx-react'
import { EventTypesStore } from '../../../../event-types/store/EventTypesStore'
import LocalizationStore from '../../../../localization/LocalizationStore'
import {
  Drawer,
  Grid,
  InputLabel,
  TextField,
  CardContent,
  Select,
  MenuItem,
} from '@material-ui/core'
import MuiDrawerToolbar from '../../../../shared/Mui-Drawer/MuiDrawerToolbar'
import './EventTypesDrawer.scss'
import MuiDrawerHeader from '../../../../shared/Mui-Drawer/MuiDrawerHeader'

interface Props {
  eventTypesStore?: EventTypesStore
  localizationStore?: LocalizationStore
}

const EventTypesDrawer: React.FC<Props> = ({ eventTypesStore, localizationStore }) => {
  const { editForm } = eventTypesStore
  const { lzStrings } = localizationStore

  const handleSubmitClick = () => {
    editForm.save()
    eventTypesStore.selectedEventTypeId = ''
  }

  const toggleDrawer = () => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    eventTypesStore.showDrawer = !eventTypesStore.showDrawer
    if (eventTypesStore.showDrawer === false) eventTypesStore.selectedEventTypeId = ''
  }

  return (
    <div id='EventTypesDrawer'>
      {editForm && (
        <Drawer
          variant='temporary'
          anchor='right'
          open={eventTypesStore.showDrawer}
          onClose={toggleDrawer()}
        >
          <MuiDrawerHeader
            title={
              editForm.objectId
                ? lzStrings.eventTypeEdit.manage_event_type
                : lzStrings.eventTypeEdit.add_event_type
            }
            toggleDrawer={toggleDrawer()}
          />
          <MuiDrawerToolbar
            handleCancelClick={toggleDrawer()}
            handleSubmitClick={() => handleSubmitClick()}
            showReset={Boolean(editForm.objectId)}
            onConfirmReset={() => eventTypesStore.setSelectedEventTypeId(editForm.objectId)}
            moreMenuProps={{
              isEditMode: editForm.isEditMode,
              itemType: lzStrings.moreMenu.obj_type.event_type,
              itemName: editForm.name,
              onConfirmDelete: () => editForm.delete(),
            }}
          />
          <CardContent id='eventTypesBasicInfo'>
            <h6>Basic Info</h6>
            <form autoComplete='off' noValidate>
              <Grid container>
                <Grid item md={12} sm={12}>
                  <InputLabel required htmlFor='name'>
                    Name
                  </InputLabel>
                  <TextField
                    error={!editForm.nameValid || !editForm.nameUnique}
                    value={editForm.name}
                    onChange={(e) => (editForm.name = e.target.value)}
                    fullWidth
                    helperText={editForm.errorHelperText}
                    variant='outlined'
                    id='name'
                  />
                </Grid>
              </Grid>
              <Grid container className='descriptionContainer'>
                <Grid item md={12} sm={12}>
                  <InputLabel htmlFor='description'>Description</InputLabel>
                  <TextField
                    multiline
                    minRows={5}
                    margin='none'
                    value={editForm.description}
                    onChange={(e) => (editForm.description = e.target.value)}
                    fullWidth
                    variant='outlined'
                    id='description'
                  />
                </Grid>
              </Grid>
              <Grid container className='descriptionContainer'>
                <Grid item md={12} sm={12}>
                  <InputLabel htmlFor='description'>Custom Collection</InputLabel>
                  <Select
                    variant='outlined'
                    value={editForm.customCollectionId}
                    fullWidth
                    onChange={(e) => editForm.setCustomCollection(e.target.value)}
                  >
                    {editForm.customCollections.map((e) => (
                      <MenuItem key={e.objectId} value={e.objectId}>{e.name}</MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Drawer>
      )}
    </div>
  )
}

export default inject('eventTypesStore', 'localizationStore')(observer(EventTypesDrawer))
