import React from 'react'
import { inject, observer } from 'mobx-react'
import { Checkbox, Grid, TextField, Typography } from '@material-ui/core'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { ExerciseSetVM } from 'src/app/training-items/view-models/ExerciseSetVM'
import './ContentItem.scss'
import CircleCheckedFilled from '@material-ui/icons/CheckCircle'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked'

interface Props {
  vm: ExerciseSetVM
  localizationStore?: LocalizationStore
}

const ExerciseSet: React.FC<Props> = ({ vm, localizationStore }) => {
  const { training_items: lz } = localizationStore.lzStrings

  if (!vm) return null

  const renderExerciseSetRow = (set: ExerciseSetVM) => {
    if (!set) return
    return (
      <Grid item key={`exercise-set-row-${set.index}`} xs={12}>
        <Grid
          container
          spacing={3}
          alignContent='flex-start'
          justifyContent='flex-start'
          className='exercise-items'
          direction='row'
        >
          <Grid item xs={2} className='exercise-item'>
            <Typography variant='body1' className='input-label'>
              {lz.sets}
            </Typography>
            <TextField
              required
              disabled={set.planIsComplete}
              InputProps={{ inputProps: { min: 0, readOnly: true } }}
              margin='none'
              fullWidth
              variant='outlined'
              autoComplete='off'
              value={set.index}
              type={'number'}
            />
          </Grid>
          <Grid item xs={2} className='exercise-item'>
            <Typography variant='body1' className='input-label'>
              {lz.reps}
            </Typography>
            <TextField
              required
              disabled={set.planIsComplete}
              InputProps={{ inputProps: { min: 0, readOnly: false } }}
              margin='none'
              fullWidth
              variant='outlined'
              autoComplete='off'
              value={set.reps ? set.reps.toString() : '0'}
              placeholder={'#'}
              onChange={(e) => set.setReps(e.target.value)}
              error={!set.repsValid}
              type={'number'}
            />
          </Grid>
          <Grid item xs={2} className='exercise-item'>
            <Typography variant='body1' className='input-label'>
              {lz.weight}
            </Typography>
            <TextField
              required
              disabled={set.planIsComplete}
              InputProps={{ inputProps: { min: 0, readOnly: false } }}
              margin='none'
              fullWidth
              variant='outlined'
              autoComplete='off'
              value={set.weight ? set.weight.toString() : '0'}
              placeholder={'#'}
              onChange={(e) => set.setWeight(e.target.value)}
              error={!set.weightValid}
              type={'number'}
            />
          </Grid>
          <Grid item xs={2} className='exercise-item'>
            <Typography variant='body1' className='input-label'>
              {lz.time}
            </Typography>
            <TextField
              required
              disabled={set.planIsComplete}
              InputProps={{ inputProps: { min: 0, readOnly: false } }}
              margin='none'
              fullWidth
              variant='outlined'
              autoComplete='off'
              value={set.time ? set.time.toString() : '0'}
              placeholder={'#'}
              onChange={(e) => set.setTime(e.target.value)}
              error={!set.timeValid}
              type={'number'}
            />
          </Grid>
          <Grid item xs={3} className='exercise-item'>
            <Typography variant='body1' className='input-label-center-reg'>
              {lz.completed}
            </Typography>
            <div className='completed-checkbox'>
              <Checkbox
                disabled={set.planIsComplete}
                icon={<CircleUnchecked />}
                checkedIcon={<CircleCheckedFilled />}
                checked={set.isComplete}
                onChange={() => set.toggleIsComplete()}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return <>{renderExerciseSetRow(vm)}</>
}

export default inject('localizationStore')(observer(ExerciseSet))
