import React from 'react'
import { Route } from 'react-router'
import RedirectScreen from '../../redirect/views/RedirectScreen'

const getRedirectRoutes = () => (
  <Route key='redirect-route' path='redirect'>
    <Route
      key='rd0'
      component={RedirectScreen}
      path=':mobileAppId/:organizationId/:path'
    />
     <Route
      key='rd1'
      component={RedirectScreen}
      path=':mobileAppId/:organizationId/:path/:id'
    />
  </Route>
)

export default getRedirectRoutes
