import { AppStore } from './AppStore'
import { create } from 'mobx-persist'
import { computed, reaction, action, runInAction, observable } from 'mobx'
import { RolesStore } from '../roles/store/RolesStore'
import { CategoriesStore } from '../categories/CategoriesStore'
import { TaskTemplatesStore } from '../task-types/store/TaskTemplatesStore'
import { LabelsStore } from '../labels/store/LabelsStore'
import { PrivilegeSetsStore } from '../privilege-sets/store/PrivilegeSetsStore'
import { OrganizationsStore } from '../organizations/store/OrganizationsStore'
import { SurveysStore } from '../surveys/store/SurveysStore'
import { UsersStore } from '../users/store/UsersStore'
import { UserStore } from '../user/store/UserStore'
import { AudienceMembersStore } from '../audience-members/store/AudienceMembersStore'
import { PrivilegesStore } from './privileges/PrivilegesStore'
import { GroupsStore } from '../groups/store/GroupsStore'
import { GroupsAndRolesStore } from '../groups-roles-widget/GroupsAndRolesStore'
import { CalendarSettingsStore } from '../calendar-settings/store/CalendarSettingsStore'
import { EventTypesStore } from '../event-types/store/EventTypesStore'
import { LocationsStore } from '../locations/store/LocationsStore'
import { ResourcesStore } from '../resources/store/ResourcesStore'
import { UserSurveysStore } from '../user-surveys/store/UserSurveysStore'
import LocalizationStore from '../localization/LocalizationStore'
import { NotificationsStore } from '../notifications/store/NotificationsStore'
import { ConsentsStore } from '../consents/store/ConsentsStore'
import { SurveyTypesStore } from '../survey-types/store/SurveyTypesStore'
import { QuestionsStore } from '../questions/store/QuestionsStore'
import { TasksStore } from '../tasks/store/TasksStore'
import { ParticipantFiltersStore } from '../dw/participant-filters/ParticipantFiltersStore'
import { WorksheetsStore } from '../worksheets/store/WorksheetsStore'
import { UserWorksheetsStore } from '../user-worksheets/store/UserWorksheetsStore'
import { UserTasksStore } from '../user-tasks/store/UserTasksStore'
import { ClientsStore } from '../clients/store/ClientsStore'
import { TaskCommentsStore } from '../task-comments/store/TaskCommentsStore'
import { ContactsStore } from '../contacts/store/ContactsStore'
import { AuthStore } from '../auth/store/AuthStore'
import { EventsStore } from '../events/store/EventsStore'
import { WeightProfilesStore } from '../weight-profiles/store/WeightProfilesStore'
import { UserEventsStore } from '../events/user-events/store/UserEventsStore'
import { SystemAdminsStore } from '../system-admins/store/SystemAdminsStore'
import { MediaItemsStore } from '../media-items/store/MediaItemsStore'
import { OrganizationsSAStore } from '../organizations-sa/store/OrganizationsSAStore'
import { GoalsStore } from '../goals/store/GoalsStore'
import { UserGoalsStore } from '../user-goals/store/UserGoalsStore'
import { CustomCollectionsStore } from '../custom-collections/store/CustomCollectionStore'
import { AnnouncementsStore } from '../announcements/store/AnnouncementsStore'
import { UserAnnouncementsStore } from '../announcements/store/UserAnnouncementsStore'
import { TrainingItemsStore } from '../training-items/store/TrainingItemsStore'
import { TrainingPlansStore } from '../training-plans/store/TrainingPlansStore'
import { UserTrainingPlansStore } from '../training-plans/user-training-plans/store/UserTrainingPlansStore'
import { TrainingPlansCatalogStore } from '../training-plans/store/TrainingPlansCatalogStore'
import { GoalsCatalogStore } from '../goals/store/GoalsCatalogStore'
import { EventSchedulesStore } from '../events/store/EventSchedulesStore'
import { CompletedSurveyResponsesStore } from '../survey-responses/store/CompletedSurveyResponsesStore'
import { UserDashboardsStore } from '../dashboardsV2/user-dashboards/UserDashboardsStore'
import { OrganizationUsersStore } from '../organization-users/store/OrganizationUsersStore'
import { DashboardsStore } from '../dashboardsV2/DashboardsStore'
import { QuizResultsStore } from '../quiz-results/store/QuizResultsStore'
import { SurveyResultsStore } from '../survey-results/stores/SurveyResultsStore'
import { UserSurveysCountStore } from '../user-surveys/store/UserSurveysCountStore'
import { SurveyTagsStore } from '../survey-tags/store/SurveyTagsStore'
import { PulseSetsStore } from '../pulse-sets/store/PulseSetsStore'
import { PulseQuestionsStore } from '../pulse-questions/store/PulseQuestionsStore'
import { PulseCategoriesStore } from '../pulse-categories/store/PulseCategoriesStore'
import { PulseCampaignsStore } from '../pulse-campaigns/store/PulseCampaignsStore'
import env from '../../env'
import { MobxPersistStorage } from './MobxPersistStorage'
import { EmailTemplatesStore } from '../email-templates/store/EmailTemplatesStore'
import { PulseCampaignTypesStore } from '../pulse-campaign-types/store/PulseCampaignTypesStore'
import { AttachmentsStore } from '../attachments/store/AttachmentsStore'

export class RootStore {
  private storesToHydrate: Array<any> = []
  public taskTemplatesStore: TaskTemplatesStore
  public categoriesStore: CategoriesStore
  public rolesStore: RolesStore
  public labelsStore: LabelsStore
  public privilegeSetsStore: PrivilegeSetsStore
  public organizationsStore: OrganizationsStore
  public dashboardsStore: DashboardsStore
  public usersStore: UsersStore
  public appStore: AppStore
  public localizationStore: LocalizationStore
  public surveysStore: SurveysStore
  public userStore: UserStore
  public audienceMembersStore: AudienceMembersStore
  public privilegesStore: PrivilegesStore
  public groupsStore: GroupsStore
  public groupsAndRolesStore: GroupsAndRolesStore
  public calendarSettingsStore: CalendarSettingsStore
  public eventTypesStore: EventTypesStore
  public locationsStore: LocationsStore
  public resourcesStore: ResourcesStore
  public userSurveysStore: UserSurveysStore
  public notificationsStore: NotificationsStore
  public consentsStore: ConsentsStore
  public surveyTypesStore: SurveyTypesStore
  public pulseCampaignTypesStore: PulseCampaignTypesStore
  public questionsStore: QuestionsStore
  public tasksStore: TasksStore
  public participantFiltersStore: ParticipantFiltersStore
  public completedSurveyResponsesStore: CompletedSurveyResponsesStore
  public worksheetsStore: WorksheetsStore
  public userWorksheetsStore: UserWorksheetsStore
  public userTasksStore: UserTasksStore
  public clientsStore: ClientsStore
  public taskCommentsStore: TaskCommentsStore
  public contactsStore: ContactsStore
  public authStore: AuthStore
  public eventsStore: EventsStore
  public userEventsStore: UserEventsStore
  public systemAdminsStore: SystemAdminsStore
  public weightProfilesStore: WeightProfilesStore
  public mediaItemsStore: MediaItemsStore
  public organizationsSAStore: OrganizationsSAStore
  public goalsStore: GoalsStore
  public userGoalsStore: UserGoalsStore
  public customCollectionsStore: CustomCollectionsStore
  public trainingItemsStore: TrainingItemsStore
  public trainingPlansStore: TrainingPlansStore
  public userTrainingPlansStore: UserTrainingPlansStore
  public announcementsStore: AnnouncementsStore
  public userAnnouncementsStore: UserAnnouncementsStore
  public trainingPlansCatalogStore: TrainingPlansCatalogStore
  public goalsCatalogStore: GoalsCatalogStore
  public eventSchedulesStore: EventSchedulesStore
  public userDashboardsStore: UserDashboardsStore
  public organizationUsersStore: OrganizationUsersStore
  public quizResultsStore: QuizResultsStore
  public surveyResultsStore: SurveyResultsStore
  public userSurveysCountStore: UserSurveysCountStore
  public surveyTagsStore: SurveyTagsStore
  public pulseSetsStore: PulseSetsStore
  public pulseQuestionsStore: PulseQuestionsStore
  public pulseCategoriesStore: PulseCategoriesStore
  public pulseCampaignsStore: PulseCampaignsStore
  public emailTemplatesStore: EmailTemplatesStore
  public cmsItemAttachmentStore: AttachmentsStore

  constructor(stores?: any) {
    this.appStore = this.initializeStore(AppStore, stores)
    this.userDashboardsStore = this.initializeStore(UserDashboardsStore, stores)
    this.taskTemplatesStore = this.initializeStore(TaskTemplatesStore, stores)
    this.categoriesStore = this.initializeStore(CategoriesStore, stores)
    this.rolesStore = this.initializeStore(RolesStore, stores)
    this.labelsStore = this.initializeStore(LabelsStore, stores)
    this.privilegeSetsStore = this.initializeStore(PrivilegeSetsStore, stores)
    this.organizationsStore = this.initializeStore(OrganizationsStore, stores)
    this.surveysStore = this.initializeStore(SurveysStore, stores)
    this.dashboardsStore = this.initializeStore(DashboardsStore, stores)
    this.usersStore = this.initializeStore(UsersStore, stores)
    this.userStore = this.initializeStore(UserStore, stores)
    this.audienceMembersStore = this.initializeStore(AudienceMembersStore, stores)
    this.groupsStore = this.initializeStore(GroupsStore, stores)
    this.groupsAndRolesStore = this.initializeStore(GroupsAndRolesStore, stores)
    this.privilegesStore = this.initializeStore(PrivilegesStore, stores)
    this.localizationStore = this.initializeStore(LocalizationStore, stores)
    this.calendarSettingsStore = this.initializeStore(CalendarSettingsStore, stores)
    this.eventTypesStore = this.initializeStore(EventTypesStore, stores)
    this.resourcesStore = this.initializeStore(ResourcesStore, stores)
    this.locationsStore = this.initializeStore(LocationsStore, stores)
    this.userSurveysStore = this.initializeStore(UserSurveysStore, stores)
    this.notificationsStore = this.initializeStore(NotificationsStore, stores)
    this.consentsStore = this.initializeStore(ConsentsStore, stores)
    this.surveyTypesStore = this.initializeStore(SurveyTypesStore, stores)
    this.pulseCampaignTypesStore = this.initializeStore(PulseCampaignTypesStore, stores)
    this.questionsStore = this.initializeStore(QuestionsStore, stores)
    this.tasksStore = this.initializeStore(TasksStore, stores)
    this.participantFiltersStore = this.initializeStore(ParticipantFiltersStore, stores)
    this.completedSurveyResponsesStore = this.initializeStore(CompletedSurveyResponsesStore, stores)
    this.worksheetsStore = this.initializeStore(WorksheetsStore, stores)
    this.userWorksheetsStore = this.initializeStore(UserWorksheetsStore, stores)
    this.userTasksStore = this.initializeStore(UserTasksStore, stores)
    this.clientsStore = this.initializeStore(ClientsStore, stores)
    this.taskCommentsStore = this.initializeStore(TaskCommentsStore, stores)
    this.contactsStore = this.initializeStore(ContactsStore, stores)
    this.authStore = this.initializeStore(AuthStore, stores)
    this.userEventsStore = this.initializeStore(UserEventsStore, stores)
    this.eventsStore = this.initializeStore(EventsStore, stores)
    this.eventSchedulesStore = this.initializeStore(EventSchedulesStore, stores)
    this.systemAdminsStore = this.initializeStore(SystemAdminsStore, stores)
    this.weightProfilesStore = this.initializeStore(WeightProfilesStore, stores)
    this.mediaItemsStore = this.initializeStore(MediaItemsStore, stores)
    this.organizationsSAStore = this.initializeStore(OrganizationsSAStore, stores)
    this.goalsStore = this.initializeStore(GoalsStore, stores)
    this.userGoalsStore = this.initializeStore(UserGoalsStore, stores)
    this.customCollectionsStore = this.initializeStore(CustomCollectionsStore, stores)
    this.trainingItemsStore = this.initializeStore(TrainingItemsStore, stores)
    this.trainingPlansStore = this.initializeStore(TrainingPlansStore, stores)
    this.userTrainingPlansStore = this.initializeStore(UserTrainingPlansStore, stores)
    this.userAnnouncementsStore = this.initializeStore(UserAnnouncementsStore, stores)
    this.announcementsStore = this.initializeStore(AnnouncementsStore, stores)
    this.trainingPlansCatalogStore = this.initializeStore(TrainingPlansCatalogStore, stores)
    this.goalsCatalogStore = this.initializeStore(GoalsCatalogStore, stores)
    this.organizationUsersStore = this.initializeStore(OrganizationUsersStore, stores)
    this.quizResultsStore = this.initializeStore(QuizResultsStore, stores)
    this.surveyResultsStore = this.initializeStore(SurveyResultsStore, stores)
    this.userSurveysCountStore = this.initializeStore(UserSurveysCountStore, stores)
    this.surveyTagsStore = this.initializeStore(SurveyTagsStore, stores)
    this.pulseSetsStore = this.initializeStore(PulseSetsStore, stores)
    this.pulseQuestionsStore = this.initializeStore(PulseQuestionsStore, stores)
    this.pulseCategoriesStore = this.initializeStore(PulseCategoriesStore, stores)
    this.pulseCampaignsStore = this.initializeStore(PulseCampaignsStore, stores)
    this.emailTemplatesStore = this.initializeStore(EmailTemplatesStore, stores)
    this.cmsItemAttachmentStore = this.initializeStore(AttachmentsStore, stores)

    this.storesToHydrate = [{ name: 'app', class: this.appStore }]

    reaction(
      () => this.isHydrated === true,
      () => this.appStore.loadData('hydrated'),
      { delay: 400 }
    )
  }

  private initializeStore = (storeType, stores: any) => {
    let store = stores && stores[storeType.name]
    if (!store) store = new storeType(this)
    if (typeof store.setRootStore === 'function') store.setRootStore(this)
    return store
  }

  @observable hydratedCount: number = 0

  @action
  public hydrate() {
    const storage = new MobxPersistStorage()
    const hydrate = create({
      storage: storage,
    })
    this.storesToHydrate.forEach((store) => {
      hydrate(store.name, store.class).then(() => this.hydratedCount++)
    })
  }

  @computed
  public get isHydrated() {
    if (this.hydratedCount === 0) return false
    const isHydrated = this.hydratedCount === this.storesToHydrate.length
    return isHydrated
  }
}

const store = new RootStore()
export default store
