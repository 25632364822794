import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core'
import Avatar from '../../shared/Avatar'
import { UserRowVM } from '../view-models/UserRowVM'

interface Props {
  user: UserRowVM
  style: any
}

const UserRow: FC<Props> = ({ user, style }) => {
  return (
    <ListItem
      disabled={user.isReadOnly}
      // key={`userrow-${user.objectId}`} 
      style={style}
      button
      // onClick={(e) => {
      //   e.stopPropagation()
      //   user.toggleParticipant()
      // }}
      selected={user.isParticipantSelected}
      className='user-row'
    >
      <ListItemAvatar>
        <Avatar size={30} user={user} style={{ fontSize: '1rem' }} />
      </ListItemAvatar>
      <ListItemText primary={user.name} secondary={user.rolesAndGroups} />
    </ListItem>
  )
}

export default observer(UserRow)
