import React from 'react'
import { inject, observer, useLocalStore } from 'mobx-react'
import { Dialog, DialogTitle, Divider, Grid, DialogActions, Button } from '@material-ui/core'
import rootStore from '../../../stores/RootStore'
import LocalizationStore from '../../../localization/LocalizationStore'
import ContentLayout from '../CMSItemsLayout'
import { Attachment } from '../../../upload/aggregate/Attachment'
import { CMSItemsPickerVM } from '../../view-models/CMSItemsPickerVM'
import { CMSItemsTypeEnum } from '../../enum/CMSItemsTypeEnum'
import { MediaItemType } from '../../../upload/types/MediaItemType'
import './CMSItemsPickerDialog.scss'

interface Props {
  allowMultiple: boolean
  fileTypes: CMSItemsTypeEnum
  addAfterSave: Function
  toggleShowModal: Function
  isOpen: boolean
  localizationStore?: LocalizationStore
  selected?: Attachment[]
}

const CMSItemsPickerDialog: React.FC<Props> = ({
  allowMultiple,
  fileTypes,
  addAfterSave,
  toggleShowModal,
  isOpen,
  selected,
  localizationStore,
}) => {
  const { contentManagement: lz } = localizationStore.lzStrings

  const localStore = useLocalStore(() => ({
    vm: new CMSItemsPickerVM(rootStore, allowMultiple, addAfterSave, toggleShowModal, fileTypes),
  }))
  const vm = localStore.vm

  if (selected) vm.manageVM.setInitialSelect(selected)

  const renderDialogActions = () => {
    return (
      <DialogActions>
        <Grid
          container
          className='dialog-actions'
          alignItems='center'
          justifyContent='space-between'
        >
          <Grid item className='dialog-actions-right'>
            <Button
              variant='text'
              size='large'
              disabled={vm.manageVM.fileUploadVM.uploadProcessing}
              onClick={() => vm.manageVM.fileUploadVM.toggleModal()}
              className='cancelButton'
            >
              {lz.cancel}
            </Button>
            <Button
              variant='contained'
              size='large'
              onClick={() => {
                const added = vm.manageVM.selected.map((item) => {
                  const attachment = new Attachment()
                  attachment.cmsItemId = item.itemId
                  attachment.objectId = item.itemId.toString()
                  attachment.fileName = item.title
                  attachment.url = item.url
                  attachment.thumbnail = item.thumbnail
                  attachment.type = item.type as MediaItemType
                  attachment.unsignedUrl = item.unsignedUrl

                  return attachment
                })
                addAfterSave(added)

                vm.manageVM.fileUploadVM.finishUpload()
                vm.manageVM.clearSelections()
              }}
              className='saveButton'
            >
              {lz.done}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    )
  }

  const renderSelected = () => {
    const selectedCount = vm.manageVM.selectedCount
    if (selectedCount < 1) return
    return (
      <div className='dialog-header-selected'>
        <Divider variant='fullWidth' orientation='vertical' />
        <DialogTitle className={'dialog-header-title-light'} disableTypography>
          {`${selectedCount} ${lz.selected}`}
        </DialogTitle>
      </div>
    )
  }

  const renderTitle = () => {
    const title = `${vm.manageVM.isPrivateAccess ? 'Private ' : ''}${lz.content_library}`
    return (
      <Grid className='dialog-title' container justifyContent='space-between' alignItems='center'>
        <Grid item className='dialog-item'>
          <DialogTitle className={'dialog-header-title'} disableTypography>
            {title}
          </DialogTitle>
          {renderSelected()}
        </Grid>
      </Grid>
    )
  }

  const renderDialogContent = () => {
    if (vm.manageVM.fileUploadVM.showErrors) return null
    return (
      <>
        {renderTitle()}
        {renderContentLayout()}
        {renderDialogActions()}
      </>
    )
  }

  const renderContentLayout = () => {
    return <ContentLayout vm={vm} />
  }

  return (
    <Dialog
      id='ContentPickerDialog'
      onClose={() => vm.manageVM.fileUploadVM.toggleModal()}
      open={isOpen}
      fullWidth
      fullScreen
    >
      {renderDialogContent()}
    </Dialog>
  )
}

export default inject('localizationStore')(observer(CMSItemsPickerDialog))
