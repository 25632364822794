import { action, computed, observable } from 'mobx'
import { serializable, serialize, deserialize, list, object, primitive, date } from 'serializr'
import { IPulseCampaignDTO } from '../dto/IPulseCampaignDTO'
import { IAggregate } from '../../shared/data/IAggregate'
import { PulseCampaignStatus } from '../type/PulseCampaignStatus'
import { Participant } from '../../surveys/aggregate/Participant'
import PulseQuestion from '../../pulse-questions/aggregate/PulseQuestion'
import { FKUser } from '../../organization-users/aggregate/FKUser'
import { PulseCampaignSurvey } from './PulseCampaignSurvey'
import { AutoSchedule } from './AutoSchedule'
import { CampaignStats } from './CampaignStats'
import { Alarms } from './Alarms'
import { AutoClose } from './AutoClose'

export class PulseCampaign implements IPulseCampaignDTO, IAggregate {
  public static create(orgId: string, isTemplate?: boolean) {
    const sht = new PulseCampaign()
    sht.organizationId = orgId
    sht.isDeleted = false
    sht.isTemplate = isTemplate ? isTemplate : false
    return sht
  }

  @serializable @observable public objectId: string = undefined
  @serializable @observable public name: string = ''
  @serializable @observable public organizationId: string = ''
  @serializable @observable public ownerUserId: string = ''
  @serializable @observable public worksheetId: string = ''
  @serializable(list(primitive())) @observable public categoryIds: string[] = []
  @serializable @observable public isDeleted: boolean = false
  @serializable @observable public isTemplate: boolean = false
  @serializable(list(object(PulseQuestion))) @observable public questions: PulseQuestion[] = []
  @serializable(list(object(Participant))) @observable public participants: Participant[] = []
  @serializable(list(object(PulseCampaignSurvey)))
  @observable
  public surveys: PulseCampaignSurvey[] = []
  @serializable(object(FKUser)) @observable public fk_owner: FKUser = undefined
  @serializable @observable public nextSurveyDeliveryDate: string = ''
  @serializable @observable public status: PulseCampaignStatus = 'draft'
  @serializable @observable public deliveryMode: string = 'time-of-day'
  @serializable @observable public templateOnlyEditableByOwner: boolean = true
  @serializable @observable published: boolean = false
  @serializable @observable publishedDateTime: number = null
  @serializable @observable startDate: string = ''
  @serializable @observable endDate: string = ''
  @serializable @observable publishedByUserId: string = ''
  @serializable @observable publishedByUserName: string = ''
  @serializable @observable isClosed: boolean = false
  @serializable @observable pulseCampaignTypeId: string = ''
  @serializable(object(AutoSchedule)) @observable schedule: AutoSchedule = new AutoSchedule()
  @serializable(list(primitive())) @observable public pulseQuestionIds: string[] = []
  @serializable(date()) @observable public createdAt: Date = new Date()
  @serializable(object(CampaignStats)) @observable stats: CampaignStats = new CampaignStats()
  @serializable(object(Alarms)) @observable alarms: Alarms = new Alarms()
  @serializable(object(AutoClose)) @observable autoClose: AutoClose = new AutoClose()
  @serializable @observable anonymizeResults: boolean = true

  public isOnServer: boolean = false

  @computed
  public get isNew(): boolean {
    return Boolean(!this.objectId)
  }

  @action
  public markIsNotOnServer() {
    this.isOnServer = false
  }

  @action
  public markIsOnServer() {
    this.isOnServer = true
  }

  @action
  public updateFromServer(fromObject: any) {
    if (!fromObject.User) fromObject.User = {}
    this.organizationId = fromObject.organizationId
    this.isDeleted = fromObject.isDeleted
    this.isTemplate = fromObject.isTemplate
    this.isOnServer = true
  }

  @action
  public setName(name: string) {
    this.name = name
  }

  @action
  public setObjectId(objectId: string) {
    this.objectId = objectId
  }

  @action
  public setWorksheetId(worksheetId: string) {
    this.worksheetId = worksheetId
  }

  @action
  public toggleIsTemplate() {
    this.isTemplate = !this.isTemplate
  }

  public serialize(): IPulseCampaignDTO {
    try {
      const dto = serialize(this)
      return dto
    } catch (e) {
      console.error(e)
      console.log(this)
    }
  }

  public clone(): PulseCampaign {
    return deserialize(PulseCampaign, this.serialize())
  }

  @action
  public toggleWeekday(day) {
    this.schedule.toggleDay(day)
  }

  @computed
  public get weekdays() {
    return this.schedule.days
  }

  @action
  public moveCampaignPulse(newIndex, oldIndex) {
    let surveysObj = this.surveys.splice(oldIndex, 1)
    this.surveys.splice(newIndex, 0, surveysObj[0])
  }

  @action
  public setStartDate(val: Date) {
    this.startDate = val.toISOString()
    this.schedule.setStartDate(val)
  }

  @action
  public setEndDate(val: Date) {
    this.endDate = val.toISOString()
    this.schedule.setEndDate(val)
  }

  @action
  public setDeliveryTime(val: Date) {
    this.schedule.setDeliveryTime(val)
  }

  @action
  public setTimeZone(timeZone: any) {
    this.schedule.setDeliveryTimeZone(timeZone)
  }

  @action
  public setStatus(status: PulseCampaignStatus) {
    this.status = status
  }

  @action
  public toggleSendReminders() {
    this.alarms.toggleSendReminders()
    if (!this.alarms.enabled) this.alarms.resetAlarms()
  }

  @computed
  public get sendReminders(): boolean {
    return this.alarms.enabled
  }

  @action
  public addAlarm(obj) {
    this.alarms.addAlarm(obj)
  }

  @action
  public deleteAlarm(mode) {
    this.alarms.deleteAlarm(mode)
  }

  @action
  public toggleAutoClose() {
    this.autoClose.toggleIsEnabled()
  }

  @action
  public setOwnerUserId(val: string) {
    this.ownerUserId = val
  }

  @action
  public setPulseCampaignTypeId(pulseCampaignTypeId: string) {
    this.pulseCampaignTypeId = pulseCampaignTypeId
  }

  @action
  public toggleAnonymizeResults() {
    this.anonymizeResults = !this.anonymizeResults
  }
}
