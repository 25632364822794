import React from 'react'
import clsx from 'clsx'
import { inject, observer } from 'mobx-react'
import { IconButton, Tooltip } from '@material-ui/core'
import PublicIcon from '@material-ui/icons/PublicRounded'
import PrivateIcon from '@material-ui/icons/VpnLockRounded'
import LocalizationStore from '../../localization/LocalizationStore'
import { LabelsStore } from '../../labels/store/LabelsStore'
import { CMSItemsManageVM } from '../view-models/CMSItemsManageVM'
import './CMSItemsToggle.scss'

interface Props {
  vm: CMSItemsManageVM
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
}

const CMSItemsToggleAccess: React.FC<Props> = ({ vm, localizationStore, labelsStore }) => {
  const { lzStrings } = localizationStore

  const renderPublicButton = () => {
    if (vm.isPrivateAccess) return
    return (
      <Tooltip title={'Change to see my private uploads'} placement='bottom-start'>
        <span>
          <IconButton
            disabled={vm.isLoading || vm.isSaving}
            onClick={() => vm.setAccessIndex(1)}
            className={clsx('tab-button', {
              active: vm.accessIndex === 1,
            })}
          >
            <PublicIcon />
          </IconButton>
        </span>
      </Tooltip>
    )
  }

  const renderPrivateButton = () => {
    if (!vm.isPrivateAccess) return
    return (
      <Tooltip title={'Change to see global tenant uploads'} placement='bottom-start'>
        <span>
          <IconButton
            disabled={vm.isLoading || vm.isSaving}
            onClick={() => vm.setAccessIndex(0)}
            className={clsx('tab-button', {
              active: vm.accessIndex === 1,
            })}
          >
            <PrivateIcon />
          </IconButton>
        </span>
      </Tooltip>
    )
  }

  return (
    <div className='content-toggle'>
      <div className='tab-btns'>
        {renderPublicButton()}
        {renderPrivateButton()}
      </div>
    </div>
  )
}

export default inject('localizationStore', 'labelsStore')(observer(CMSItemsToggleAccess))
