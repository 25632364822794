import React from 'react'

import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { OrganizationsStore } from '../../store/OrganizationsStore'
import { Tab, Tabs } from '@material-ui/core'

interface DrawerTabsProps {
  localizationStore?: LocalizationStore
  organizationsStore?: OrganizationsStore
}

const DrawerTabs: React.FC<DrawerTabsProps> = ({ localizationStore, organizationsStore }) => {
  const { lzStrings } = localizationStore
  const { editVM: vm } = organizationsStore


  return (
    <Tabs
      variant='fullWidth'
      centered
      value={vm.currentTabIndex}
      onChange={(e, idx) => vm.setCurrentTab(idx)}
    >
      <Tab label={'Basic Info'} />
      <Tab label={'Links'} />
      <Tab label={'Welcome Banner'} />
    </Tabs>
  )
}

export default inject('localizationStore', 'organizationsStore')(observer(DrawerTabs))
