import { ColDef, ColumnApi, GridApi, GridReadyEvent } from 'ag-grid-community'
import { action, computed } from 'mobx'
import { UserSurveyEventsService } from '../../userSurveyEvents/service/UserSurveyEventsService'
import { HistoryRowVM } from './HistoryRowVM'

export class HistoryVM {
  private orgId: string
  private userId: string
  private surveyId: string
  private userName: string
  private email: string
  public gridApi: GridApi
  private columnApi: ColumnApi

  constructor(orgId: string, userId: string, surveyId: string, userName: string, email: string) {
    this.orgId = orgId
    this.userId = userId
    this.surveyId = surveyId
    this.userName = userName
    this.email = email
  }

  @computed
  public get fields() {
    return [
      { name: 'Type', field: 'eventType' },
      { name: 'Source', field: 'eventSource' },
      { name: 'Date', field: 'eventDateTime' },
      { name: 'Additional Info', field: 'additionalInfo' },
    ]
  }

  @computed
  public get getUserName() {
    return this.userName
  }

  @action
  public onGridReady(e: GridReadyEvent) {
    this.gridApi = e.api
    this.columnApi = e.columnApi

    this.gridApi.setDatasource({
      rowCount: null,
      getRows: (params) => {
        const svc = new UserSurveyEventsService()
        svc
          .getUserSurveyEvents({
            organizationId: this.orgId,
            userId: this.userId,
            surveyId: this.surveyId,
            email: this.email,
            skip: params.startRow,
            limit: params.endRow - params.startRow,
          })
          .then((result) => {
            const records = result.userSurveyEvents.map((e) => new HistoryRowVM(e))
            const lastRow =
              records.length < params.endRow - params.startRow
                ? params.startRow + records.length
                : undefined
            params.successCallback(records, lastRow)
            this.updateGridData()
          })
      },
    })
    this.updateGridData()
  }

  private updateGridData() {
    if (!this.gridApi) return
    this.gridApi.sizeColumnsToFit()
    //this.resizeColumns()
  }

  private resizeColumns() {
    if (!this.columnApi) return
    setTimeout(() => this.columnApi.autoSizeAllColumns(), 250)
  }

  @computed
  public get columnDefs(): ColDef[] {
    return this.fields.map((e) => {
      let def: ColDef = {
        headerName: e.name,
        sortable: true,
        field: e.field,
        editable: false,
        resizable: true,
        headerTooltip: e.name,
      }
      if (e.field === 'eventType' || e.field === 'eventSource') {
        def.width = 30
      } else if (e.field === 'eventDateTime') {
        def.width = 80
      }
      return def
    })
  }

  @action
  public getRowNodeId(row: HistoryRowVM) {
    return row.objectId
  }
}
