import React from 'react'
import { inject, observer, useLocalStore } from 'mobx-react'
import { Card, CardHeader } from '@material-ui/core'
import './AllCampaignsCard.scss'
import { AllCampaignsCardVM } from '../../../view-models/dashboard/all-campaigns/AllCampaignsCardVM'
import FilterBar from './FilterBar'
import CampaignsList from './CampaignsList'
import CampaignDetails from '../campaign-dialog/CampaignDetails'
import rootStore from '../../../../stores/RootStore'
import LocalizationStore from '../../../../localization/LocalizationStore'

interface Props {
  localizationStore?: LocalizationStore
}

const AllCampaignsCard: React.FC<Props> = ({ localizationStore }) => {
  const localStore = useLocalStore(() => ({
    vm: new AllCampaignsCardVM(rootStore),
  }))
  const vm = localStore.vm
  const lz = localizationStore.lzStrings.pulseCampaignDashboard

  const renderCard = () => {
    return (
      <Card className={'all-campaigns-card'}>
        <CardHeader className='header-text' title={lz.all_campaigns} />
        <FilterBar vm={vm.filterBarVM} />
        <CampaignsList vm={vm.campaignsListVM} />
        <CampaignDetails vm={vm.campaignsListVM} />
      </Card>
    )
  }

  return <div id='AllCampaignsCard'>{renderCard()}</div>
}

export default inject('localizationStore')(observer(AllCampaignsCard))
