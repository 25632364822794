import { action, observable } from 'mobx'
import { serializable } from 'serializr'
import { IAdditionalResponseNotificationParticipantDTO } from '../dtos/IAdditionalResponseNotificationParticipantDTO'
import { ParticipantType } from '../types/ParticipantType'

export class AdditionalResponseNotificationParticipant
  implements IAdditionalResponseNotificationParticipantDTO
{
  public static create(fromPart: any) {
    const part = new AdditionalResponseNotificationParticipant()
    part.id = fromPart.id
    part.type = fromPart.type
    part.name = fromPart.name
    return part
  }

  @serializable @observable public id: string = ''
  @serializable @observable public type: ParticipantType
  @serializable @observable public name: string = ''
}
