import { action, computed, observable } from 'mobx'
import { RootStore } from '../../../../stores/RootStore'
import { CampaignTemplateRowVM } from './CampaignTemplateRowVM'
import { PulseCampaignTemplatesDashboardDataStore } from './PulseCampaignTemplatesDashboardDataStore'
import { PulseCampaignTemplatesDashboardVM } from './PulseCampaignTemplatesDashboardVM'

export class PulseCampaignTemplatesListVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, parentVM: PulseCampaignTemplatesDashboardVM) {
    this.rootStore = rootStore
    this.lz = this.rootStore.localizationStore.lzStrings.pulseCampaign
    this.labelsStore = this.rootStore.labelsStore
    this.parentVM = parentVM
    this.dataStore = new PulseCampaignTemplatesDashboardDataStore(this.rootStore, {
      organizationId: this.rootStore.appStore.currentOrgId,
    })
    this.loadStore()
  }

  @observable public lz = undefined
  @observable public labelsStore = undefined
  @observable public parentVM: PulseCampaignTemplatesDashboardVM = undefined
  @observable public dataStore: PulseCampaignTemplatesDashboardDataStore = undefined

  @computed
  public get templateLayout(): number {
    return this.parentVM.templateLayout
  }

  public loadStore() {
    this.dataStore.loadListRecords()
  }

  @computed
  public get pulseCampaigns(): CampaignTemplateRowVM[] {
    return this.dataStore.rows.map((p) => new CampaignTemplateRowVM(this.rootStore, p))
  }
}
