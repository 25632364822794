import React from 'react'
import { Card } from '@material-ui/core'
import './AlwaysAvailableSurveysWidget.scss'
import { observer, inject, useLocalStore, Observer } from 'mobx-react'
import { RootStore } from '../../../stores/RootStore'
import { AlwaysAvailableSurveysWidgetVM } from '../../view-models/always-available-surveys-widget/AlwaysAvailableSurveysWidgetVM'
import WidgetHeader from './WidgetHeader'
import WidgetTable from './WidgetTable'

interface Props {
  rootStore?: RootStore
}

const AlwaysAvailableSurveysWidget: React.FC<Props> = ({ rootStore }) => {
  const localStore = useLocalStore(() => ({
    vm: new AlwaysAvailableSurveysWidgetVM(rootStore),
  }))
  const vm = localStore.vm

  return (
    <Card elevation={0} id='MyAvailableSurveysWidget'>
      <WidgetHeader vm={vm} />
      <WidgetTable vm={vm} />
    </Card>
  )
}

export default inject('rootStore')(observer(AlwaysAvailableSurveysWidget))
