import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Tooltip,
  Typography,
} from '@material-ui/core'
import React from 'react'
import { UserTrainingPlanTakeVM } from '../../view-models/UserTrainingPlanTakeVM'
import { observer } from 'mobx-react'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import UserTrainingPlanMenuContentItem from './UserTrainingPlanMenuContentItem'
import HelpIcon from '@material-ui/icons/Help'
import CheckIcon from '@material-ui/icons/Check'
import { Quiz } from '../../../../quiz/aggregate/Quiz'

interface Props {
  takeVM: UserTrainingPlanTakeVM
}

const UserTrainingPlanMenu: React.FC<Props> = ({ takeVM }) => {
  const renderItemDueDate = (item) => {
    if (!takeVM.dueDateVariesByTrainingItem) return null
    return (
      <Typography variant='h5' className='due-date'>
        {item.dueDate}
      </Typography>
    )
  }

  const renderQuizIndicator = (quiz: Quiz) => {
    if (quiz.isComplete) {
      return (
        <div className='completed-item'>
          <CheckIcon />
        </div>
      )
    } else {
      return (
        <div
          className={
            takeVM.quizTakeVM && quiz.id === takeVM.quizTakeVM.id
              ? 'content-item-mark-selected'
              : 'content-item-mark'
          }
        />
      )
    }
  }

  const renderQuiz = (item) => {
    if (!item.quiz) return null
    return (
      <AccordionDetails
        key={'quiz'}
        className={item.menuQuizDisabled ? 'content-item-disabled content-item' : 'content-item'}
      >
        <Grid container>
          <Grid container>
            <Grid item xs={1} className='content-mark-container'>
              {renderQuizIndicator(item.quiz)}
            </Grid>
            <Grid
              item
              xs={11}
              className={
                takeVM.selectedQuiz && item.quiz.id === takeVM.selectedQuiz.id
                  ? 'selected-content-item content-item-container'
                  : 'content-item-container'
              }
            >
              <Grid
                className='content-item-body'
                container
                onClick={() => {
                  if (item.menuQuizDisabled) return
                  takeVM.openQuiz(item.quiz)
                }}
              >
                <Grid item xs={12}>
                  <div className='thumbnail-container'>
                    <HelpIcon style={{ color: 'white' }} />
                  </div>
                  <span className='content-item-info'>
                    <Typography variant='caption' className='content-item-subtitle'>
                      QUIZ
                    </Typography>
                    <Typography variant='body1' className='content-item-title'>
                      {item.quiz.questions.length} Questions
                    </Typography>
                  </span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    )
  }

  const renderCertificateIndicator = (hasCertificate: boolean, id: string) => {
    if (hasCertificate) {
      return (
        <div className='completed-item'>
          <CheckIcon />
        </div>
      )
    } else {
      return (
        <div
          className={
            takeVM.selectedCertificateUploadId === id
              ? 'content-item-mark-selected'
              : 'content-item-mark'
          }
        />
      )
    }
  }

  const renderCertificate = (item) => {
    if (!item.requireCertificate) return null
    return (
      <AccordionDetails
        key={`cert-${item.id}`}
        className={
          item.menuCertificateDisabled ? 'content-item content-item-disabled' : 'content-item'
        }
      >
        <Grid container>
          <Grid container>
            <Grid item xs={1} className='content-mark-container'>
              {renderCertificateIndicator(item.hasCertificate, item.id)}
            </Grid>
            <Grid
              item
              xs={11}
              className={
                takeVM.selectedCertificateUploadId === item.id
                  ? 'selected-content-item content-item-container'
                  : 'content-item-container'
              }
            >
              <Grid
                className='content-item-body'
                container
                onClick={() => {
                  if (item.menuCertificateDisabled) return
                  takeVM.setSelectedCertificateUpload(item.id)
                }}
              >
                <Grid item xs={12}>
                  <div className='thumbnail-container'>
                    <HelpIcon style={{ color: 'white' }} />
                  </div>
                  <span className='content-item-info'>
                    <Typography variant='caption' className='content-item-subtitle'>
                      UPLOAD CERTIFICATE
                    </Typography>
                  </span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    )
  }

  const renderTrainingPlanQuiz = () => {
    if (!takeVM.planQuiz || !takeVM.requireQuiz) return null
    return (
      <Grid
        container
        className={takeVM.planQuizSelected ? 'quiz-item selected-quiz-item' : 'quiz-item'}
        onClick={() => takeVM.openQuiz(takeVM.planQuiz)}
      >
        <Grid item xs={12}>
          <Grid container className='quiz-item-body'>
            <HelpIcon className='quiz-icon' />
            <Grid item>
              <Typography variant='h5' className='left-menu-item-title'>
                Completion Quiz
              </Typography>
              <Typography variant='body2' className='item-subtitle'>
                {takeVM.planQuiz.questions.length} Questions
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderTrainingPlanCertificate = () => {
    if (!takeVM.requireCertificate) return null
    return (
      <Grid
        container
        className={
          takeVM.selectedCertificateUploadId === 'for-plan'
            ? 'quiz-item selected-quiz-item'
            : 'quiz-item'
        }
        onClick={() => takeVM.setSelectedCertificateUpload('for-plan')}
      >
        <Grid item xs={12}>
          <Grid container className='quiz-item-body'>
            <HelpIcon className='quiz-icon' />
            <Grid item>
              <Typography variant='h5' className='left-menu-item-title'>
                Certificate Upload
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderTrainingItemsMenu = () => {
    return takeVM.trainingItemRows.map((item, index) => {
      return (
        <Accordion
          elevation={0}
          expanded={item.isExpanded}
          key={index}
          className='training-item'
          id={`training-item-${item.id}`}
        >
          <AccordionSummary
            className='training-item-container'
            onClick={() => item.toggleIsExpanded()}
          >
            <Grid container>
              <Grid item xs={12}>
                <Grid
                  container
                  className={
                    item.completionAllowed
                      ? 'training-item-body'
                      : 'training-item-body training-item-body-disabled'
                  }
                >
                  <Grid item xs={12} className='flex'>
                    <span className='flex'>
                      <KeyboardArrowRightIcon
                        className={item.isExpanded ? 'expanded-icon menu-icon' : 'menu-icon'}
                      />
                      <Typography variant='h5' className='left-menu-item-title'>
                        {item.name}
                      </Typography>
                    </span>
                    {renderItemDueDate(item)}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='body2' className='item-subtitle'>
                      <span className='block'>{item.block ? item.blockString : null}</span>
                      <span className='title'>{item.subtitle}</span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </AccordionSummary>
          {item.contentItems.map((item, index) => {
            return (
              <Grid item xs={12} key={index}>
                <UserTrainingPlanMenuContentItem takeVM={takeVM} item={item} index={index} />
              </Grid>
            )
          })}
          {renderQuiz(item)}
          {renderCertificate(item)}
        </Accordion>
      )
    })
  }

  return (
    <>
      {renderTrainingItemsMenu()}
      {renderTrainingPlanQuiz()}
      {renderTrainingPlanCertificate()}
    </>
  )
}

export default observer(UserTrainingPlanMenu)
