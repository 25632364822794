import { action, observable, computed } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { ParseService } from '../../../services/ParseService';
import Resource from '../aggregate/Resource';


export default class ResourceEditForm {

  rootStore: RootStore

  constructor(rootStore: RootStore, resource: Resource = null) {
    this.rootStore = rootStore
    if (resource) this.loadData(resource)
  }

  private loadData(resource: Resource) {
    this.objectId = resource.objectId
    this.name = resource.name
    this.description = resource.description
  }

  @observable objectId: string = ''
  @observable name: string = ''
  @observable description: string = ''
  @observable saveTried: boolean = false

  @action
  public markSaveTried() {
    this.saveTried = true
  }

  @computed public get isEditMode() {
    return Boolean(this.objectId)
  }

  @computed
  public get errorHelperText() {
    if (!this.nameValid) return 'Please enter a name for this resource.'
    if (!this.nameUnique) return 'This name is being used by another resource.'
  }

  @computed
  public get nameValid() {
    if (!this.saveTried) return true
    if (!this.name || this.name.trim() === '') return false
    return true
  }

  @computed
  public get nameUnique() {
    if (!this.saveTried) return true
    const foundResource = this.rootStore.resourcesStore.getResourceByName(this.name)
    if (foundResource && foundResource.objectId !== this.objectId) return false
    return true
  }


  @computed
  public get isValid() {
    if (!this.saveTried) return true
    if (!this.nameValid) return false
    if (!this.nameUnique) return false
    return true
  }


  @action
  public async save() {
    this.markSaveTried()
    if (!this.isValid) return false
    this.rootStore.resourcesStore.showDrawer = false

    try {
      const newResource = {
        objectId: this.objectId ? this.objectId : null,
        name: this.name,
        description: this.description
      }

      await new ParseService().saveResource(this.rootStore.appStore.currentOrgId, newResource)

      this.rootStore.resourcesStore.clearForms()

    }
    catch (e) {
      console.error(e)
    }
  }

  @action
  public async delete() {
    try {
      this.rootStore.resourcesStore.showDeleteDialog = false
      this.rootStore.resourcesStore.showDrawer = false

      await new ParseService().deleteResource(this.rootStore.appStore.currentOrgId, this.objectId)
    }
    catch (e) {
      console.error(e)
    }
  }
}