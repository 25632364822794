import Parse from 'parse'
import { RootStore } from '../../stores/RootStore'
import { IOrganizationUsersListPreferencesSaveRequest } from '../interfaces/IOrganizationUsersListPreferencesSaveRequest'
import { IOrganizationUsersListPreferencesSaveResult } from '../interfaces/IOrganizationUsersListPreferencesSaveResult'
import { IOrganizationUsersListPreferencesFindRequest } from '../interfaces/IOrganizationUsersListPreferencesFindRequest'
import { IOrganizationUsersListPreferencesFindResult } from '../interfaces/IOrganizationUsersListPreferencesFindResult'

export class OrganizationUsersListPreferencesService {
  public async saveOrganizationUsersListPreferences(
    req: IOrganizationUsersListPreferencesSaveRequest
  ): Promise<IOrganizationUsersListPreferencesSaveResult> {
    return await Parse.Cloud.run('saveOrganizationUsersListPreferences', req)
  }

  public async getOrganizationUsersListPreferences(
    req: IOrganizationUsersListPreferencesFindRequest
  ): Promise<IOrganizationUsersListPreferencesFindResult> {
    return await Parse.Cloud.run('findOrganizationUsersListPreferences', req)
  }
}
