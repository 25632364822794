import { action, computed, IReactionDisposer, observable, reaction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { TrainingItemsStore } from '../store/TrainingItemsStore'
import { IExerciseInfoDTO } from '../dto/IExerciseInfoDTO'
import { ContentVM } from '../../training-plans/user-training-plans/view-models/ContentVM'
import { ExerciseSetVM } from './ExerciseSetVM'
import { IExerciseDTO } from '../dto/IExerciseDTO'
import { Exercise } from '../aggregate/Exercise'
import { ExerciseInfo } from '../aggregate/ExerciseInfo'

export class ExerciseVM {
  private rootStore: RootStore
  private dataStore: TrainingItemsStore
  private reactions: IReactionDisposer[] = []

  constructor(rootStore: RootStore, manageVM: ContentVM, exercise: Exercise) {
    this.rootStore = rootStore
    this.exercise = exercise
    this.manageVM = manageVM
    this.registerReactions()
  }

  @observable public exercise: Exercise = null
  @observable public isComplete: boolean = false
  @observable public manageVM: ContentVM
  @observable public index: number = 0

  private registerReactions() {
    if (process.env.NODE_ENV === 'test') return

    this.reactions.forEach((dispose: IReactionDisposer) => dispose())
    this.reactions.push(
      reaction(
        () => this.areAllComplete,
        () => {
          if (this.areAllComplete) this.manageVM.markIsComplete()
          else this.manageVM.markIsIncomplete()
        },
        { delay: 0 }
      )
    )
  }

  @computed
  public get goal(): ExerciseInfo {
    return this.exercise.goal
  }

  @computed
  public get sets(): ExerciseSetVM[] {
    return this.loadSets()
  }

  @computed
  public get areAllComplete() {
    return this.sets.filter((set) => !set.isComplete).length === 0
  }

  @computed
  public get isLoaded(): boolean {
    return this.dataStore.loaded
  }

  @computed
  public get title(): string {
    return this.manageVM.title
  }

  @computed
  public get id(): string {
    return this.manageVM.id
  }

  @computed
  public get isContentComplete(): boolean {
    return this.manageVM.isComplete
  }

  @action
  public toggleIsComplete() {
    this.isComplete = !this.isComplete
  }

  @action
  public loadSets() {
    const sets = []
    for (let i = 0; i < this.manageVM.contentItem.exercise.goal.sets; i++) {
      const set = this.exercise.sets[i]
      const isComplete = set.isComplete ? set.isComplete : false
      const setVM = new ExerciseSetVM(this.rootStore, this.manageVM, this.exercise, set, isComplete)
      setVM.setIndex(i + 1)
      sets.push(setVM)
    }
    return sets
  }

  @action
  public setIndex(index: number) {
    this.index = index
  }

  @action
  public toggleMarkSetsComplete() {
    if (!this.exercise) return
    let someComplete = false
    this.exercise.sets.forEach((set) => {
      if (set.isComplete) someComplete = true
    })
    this.sets.forEach((set) => {
      if (this.isContentComplete && someComplete) return
      if (this.isContentComplete && !someComplete) set.markAsComplete()
      else set.markAsIncomplete()
    })
    this.exercise.sets.forEach((set) => {
      if (this.isContentComplete && someComplete) return
      else set.isComplete = this.isContentComplete
    })
    this.onAction()
  }

  @action
  public onAction() {
    if (!this.manageVM.takeVM) return
    this.manageVM.takeVM.save()
  }

  @computed
  public get planIsComplete(): boolean {
    if (!this.manageVM) return false
    if (!this.manageVM.takeVM) return false
    return this.manageVM.takeVM.planIsComplete
  }

  public toDTO(): IExerciseDTO {
    return {
      id: this.id,
      goal: this.goal,
      sets: this.sets,
    }
  }
}
