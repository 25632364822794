import { computed, observable, action, when, reaction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { QuestionRowVM } from './QuestionRowVM'
import { QuestionsImportVM } from './QuestionsImportVM'
import { ColDef, GridOptions, RowClickedEvent } from 'ag-grid-community'
import { CategoryPickerVM } from '../../../app/shared/category-picker/CategoryPickerVM'
import { Question } from '../aggregates/Question'
import { QuestionCatalogWidgetDataStore } from './QuestionCatalogWidgetDataStore'
import { AGGridVM } from '../../shared/ag-grid/AGGridVM'
import { nameOf } from '../../shared/nameOf'
import { QuestionTypesFilterVM } from './QuestionTypesFilterVM'

export class QuestionCatalogWidgetVM extends AGGridVM {
  private rootStore: RootStore
  private dataStore: QuestionCatalogWidgetDataStore

  constructor(rootStore: RootStore) {
    super()
    this.rootStore = rootStore
    this.serverSideLoaded = true
    // this.usePrettyTooltip = true
    this.categoryPickerVM = new CategoryPickerVM(rootStore, true, true, true, undefined, undefined)
    this.typesFilterVM = new QuestionTypesFilterVM(this)
    this.importVM = new QuestionsImportVM(this.rootStore)
    this.loadDataStore()
    this.loadReactions()
  }

  @observable public importVM: QuestionsImportVM = null
  @observable public categoryPickerVM: CategoryPickerVM = null
  @observable public typesFilterVM: QuestionTypesFilterVM = null

  private loadDataStore() {
    this.dataStore = new QuestionCatalogWidgetDataStore(this.rootStore, this)
    this.dataStore.onRecordUpdated = (e) => this.onRecordUpdated(e)
  }

  private loadReactions() {
    reaction(
      () =>
        this.rootStore.questionsStore.editVM &&
        this.rootStore.questionsStore.editVM.newQuestionJustSaved,
      (val) => val && this.reload()
    )
    reaction(
      () =>
        this.rootStore.questionsStore.editVM &&
        this.rootStore.questionsStore.editVM.questionJustDeleted,
      (val) => val && this.reload()
    )
    reaction(
      () => JSON.stringify(this.rootStore.questionsStore.updated),
      () => {
        this.reload()
      }
    )
  }

  @action
  public getRecord(id: string): Question {
    return this.getRecord(id)
  }

  public onRecordUpdated(obj: Question) {
    const rowNode = this.gridApi.getRowNode(obj.objectId)
    if (!rowNode) return
    rowNode.setData(new QuestionRowVM(this.rootStore, this, obj))
  }

  public rowClicked(e: RowClickedEvent) {
    const row: QuestionRowVM = e.data
    if (!row) return
    row.click()
  }

  public get rows(): QuestionRowVM[] {
    return []
  }

  protected onGridReadied() {
    this.gridApi.setDatasource({
      rowCount: this.totalRecords,
      getRows: async (params) => {
        params.sortModel.forEach((col: { colId: string; sort: 'asc' | 'desc' }, idx: number) => {
          let dbCol = col.colId
          if (idx === 0) this.dataStore.setSort(dbCol, col.sort)
          if (idx > 0) this.dataStore.addSort(dbCol, col.sort)
        })
        if (params.sortModel.length === 0) this.dataStore.setSort('label')
        if (params.startRow === 0) this.dataStore.setPage(0)
        await this.dataStore.getNextPage()
        const rows = this.dataStore.questions.map((e) => new QuestionRowVM(this.rootStore, this, e))
        params.successCallback(rows, this.dataStore.totalRecords)
      },
    })
  }

  @computed
  public get hasSurveyPrivilege(): boolean {
    return this.rootStore.appStore.canSurvey
  }

  @computed
  public get shouldRender(): boolean {
    if (!this.dataStore) return false
    return true
  }

  @action
  public getRowNodeId(row): string {
    return row.objectId
  }

  @action
  public setQuickFilter(val: string) {
    this.typedFilterText = val
    if (this.quickFilterTO) clearTimeout(this.quickFilterTO)
    this.quickFilterTO = setTimeout(() => this.applyFilter(), 1000)
  }

  private applyFilter() {
    this.dataStore.setFilter(this.typedFilterText)
    this.reload()
  }

  public get columnDefs(): ColDef[] {
    const s = this.rootStore.localizationStore.lzStrings
    return [
      {
        headerName: s.questionsCatalogWidget.label,
        field: nameOf<QuestionRowVM, string>((e) => e.title),
        sort: 'asc',
        width: 100,
      },
      {
        headerName: s.questionsCatalogWidget.type,
        field: nameOf<QuestionRowVM, string>((e) => e.questionType),
        width: 30,
      },
      {
        headerName: this.rootStore.labelsStore.getLabelByLanguageAndFor('category'),
        field: nameOf<QuestionRowVM, string>((e) => e.categoryLabel),
        width: 75,
      },
      {
        headerName:
          this.rootStore.labelsStore.getLabelByLanguageAndFor('pulse') +
          ' ' +
          s.questionsCatalogWidget.type,
        field: nameOf<QuestionRowVM, string>((e) => e.surveyTypeLabel),
        width: 50,
      },
    ]
  }

  public getGridOptions(): GridOptions {
    return {
      ...this.baseGridOptions,
      onRowClicked: (e) => this.rowClicked(e),
      getRowNodeId: (e) => this.getRowNodeId(e),
      columnDefs: this.ensureTooltipFields(this.columnDefs),
    }
  }

  @action
  public toggleImportModal() {
    this.importVM.clear()
    this.importVM.toggle()
  }

  public addQuestion() {
    this.rootStore.questionsStore.loadEditVM()
    this.rootStore.questionsStore.forceUpdate()
  }
}
