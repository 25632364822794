import React from 'react'
import { inject, observer } from 'mobx-react'
import { TabEnums } from '../../../types/TabEnums'
import { GoalsStore } from '../../../store/GoalsStore'
import './SummaryTabPanel.scss'
import {
  Typography,
  Grid,
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
} from '@material-ui/core'
import LocalizationStore from '../../../../localization/LocalizationStore'
import Avatar from '../../../../shared/Avatar'

interface Props {
  goalsStore?: GoalsStore
  localizationStore?: LocalizationStore
}

const PreviewTabPanel: React.FC<Props> = ({ goalsStore, localizationStore }) => {
  const { goal_create: strings } = localizationStore.lzStrings
  const { goalEditVM: vm } = goalsStore
  if (!vm) return null
  if (vm.currentTab !== TabEnums.SUMMARY) return null

  const renderLabels = () => {
    return (
      <Grid container direction='column' className='details-section'>
        <Grid item xs={12} className='details'>
          <Typography className='preview-details-row' variant='h6'>
            {strings.creator}
          </Typography>
          <Typography variant='body2'>{vm.createdBy ? vm.createdBy.name : ''}</Typography>
        </Grid>
        <Grid item xs={12} className='details'>
          <Typography className='preview-details-row' variant='h6'>
            {strings.due_date}
          </Typography>
          <Typography variant='body2'>{vm.endDate}</Typography>
        </Grid>
        <Grid item xs={12} className='details'>
          <Typography className='preview-details-row' variant='h6'>
            {strings.category}
          </Typography>
          <Typography variant='body2'>{vm.categoryId ? vm.category.name : ''}</Typography>
        </Grid>
        <Grid item xs={12} className='details'>
          <Typography className='preview-details-row' variant='h6'>
            {strings.description}
          </Typography>
          <Typography variant='body2'>{vm.description}</Typography>
        </Grid>
        <Grid item xs={12} className='details'>
          <Typography className='preview-details-row' variant='h6'>
            {strings.data_source}
          </Typography>
          <Typography variant='body2'>{vm.dataSource ? vm.dataSource.name : ''}</Typography>
        </Grid>
        <Grid item xs={12} className='details'>
          <Typography className='preview-details-row' variant='h6'>
            {strings.data_facet}
          </Typography>
          <Typography variant='body2'>{vm.facet ? vm.facet.field : ''}</Typography>
        </Grid>
      </Grid>
    )
  }

  const participantRow = (VM, index) => {
    if (!VM) return null
    if (!VM.participant) return null
    return (
      <TableRow key={index}>
        <TableCell>
          <div className='user-cell'>
            <Avatar
              user={VM.participant}
              showOnlyFirstInitial={true}
              size={30}
              style={{
                fontSize: 12,
              }}
            />
            <span className='name'>{VM.name}</span>
          </div>
        </TableCell>
        <TableCell>{VM.current}</TableCell>
        <TableCell>{vm.target}</TableCell>
      </TableRow>
    )
  }

  const renderParticipants = () => {
    if (vm.participants.length === 0) return null
    return (
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align='left' className='table-header-cell'>
              Participants
            </TableCell>
            <TableCell align='left' className='table-header-cell'>
              Current Value
            </TableCell>
            <TableCell align='left' className='table-header-cell'>
              Target Value
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {vm.participants.map((VM, index) => {
            return participantRow(VM, index)
          })}
        </TableBody>
      </Table>
    )
  }

  const renderNoParticipants = () => {
    if (vm.participants.length > 0) return null

    return (
      <Typography variant='h6' className='no-participants-selected'>
        No Participants Selected
      </Typography>
    )
  }

  const renderMeasurables = () => {
    if (!vm.measureGoal) return null
    return (
      <Grid container direction='column' className='measurable-summary'>
        <Grid item className='measurable-title'>
          <Typography variant='h6'>Measurable:</Typography>
          <Typography variant='body2'> (In order for this goal to be met...)</Typography>
        </Grid>
        <Typography className='measurable-statement'>
          {' '}
          The <span className='detail'>{vm.aggregationMethod}</span> of{' '}
          <span className='detail'>{vm.facet ? vm.facet.field : ''}</span> must meet or exceed{' '}
          <span className='detail'>{vm.targetPercentage}%</span> atleast{' '}
          <span className='detail'>{vm.consistencyThreshold}%</span> of the{' '}
          <span className='detail'>{vm.aggregationPeriod}</span> between{' '}
          <span className='detail'>{vm.startDate}</span> and{' '}
          <span className='detail'>{vm.endDate}</span>.
        </Typography>
      </Grid>
    )
  }

  return (
    <div id='PreviewTabPanel'>
      <Typography variant='h4'>{vm.name}</Typography>
      {renderLabels()}
      <Grid container className={vm.measureGoal ? 'participants-summary' : ''}>
        <Grid item xs={6} className='participant-table'>
          {renderParticipants()}
          {renderNoParticipants()}
        </Grid>
      </Grid>
      {renderMeasurables()}
    </div>
  )
}

export default inject('goalsStore', 'localizationStore')(observer(PreviewTabPanel))
