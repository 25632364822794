import { RootStore } from '../../../stores/RootStore'
import { AudienceMember } from 'src/app/audience-members/aggregate/AudienceMember'
import { OrganizationUserEditVM } from './OrganizationUserEditVM'
import { OrganizationUser } from '../../aggregate/OrganizationUser'

export class AudienceRowVM {
  private rootStore: RootStore
  private editVM: OrganizationUserEditVM
  private orgUser: OrganizationUser

  constructor(rootStore: RootStore, editVM: OrganizationUserEditVM, orgUser: OrganizationUser) {
    this.orgUser = orgUser
    this.rootStore = rootStore
    this.editVM = editVM
  }

  public get objectId(): string {
    return this.orgUser.objectId
  }

  public get userId(): string {
    if (!this.orgUser.fk_User) return ''
    return this.orgUser.fk_User.objectId
  }

  public get name(): string {
    if (!this.orgUser.fk_User) return ''
    return this.orgUser.fk_User.lastName + ', ' + this.orgUser.fk_User.firstName
  }

  public get iconURL(): string {
    return ''
    // return this.orgUser.fk_User.iconURL
  }

  public get type(): string {
    const isDirect = Boolean(this.editVM.subordinates.find((e) => e === this.orgUser.userId))
    if (isDirect) return 'Direct Report'
    return 'Other'
  }
}
