import { observable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Attachment } from '../../upload/aggregate/Attachment'

export abstract class TaskAttachmentVM {
  protected rootStore: RootStore
  @observable protected attachment: Attachment

  constructor(rootStore: RootStore, attachment: Attachment) {
    this.rootStore = rootStore
    this.attachment = attachment
  }

  public abstract get isVideo(): boolean
  public abstract get isImage(): boolean
  public abstract get fileName(): string
  public abstract get shorterFileName(): string
  public abstract get thumbnail(): string
  public abstract get url(): string
  public abstract get type(): string
  public abstract get isLoaded(): boolean

  public abstract openAttachment(): Promise<any>

}
