import { action, computed } from 'mobx'
import { IParticipant } from '../../participants-select/interfaces/IParticipant'
import { ParticipantVM } from '../../participants-select/view-models/ParticipantVM'
import { VisibleParticipant } from '../aggregate/VisibleParticipant'

export class VisibleParticipantVM extends ParticipantVM {
  private participant: VisibleParticipant
  constructor(rootStore, participant: VisibleParticipant) {
    super(rootStore, participant as IParticipant)
    this.participant = participant
  }

  @action
  public toggleLimited(): void {
    this.participant.toggleLimited()
  }

  @computed
  public get limited(): boolean {
    return this.participant.limited
  }

  public toDTO(): VisibleParticipant {
    const dto = super.toDTO() as VisibleParticipant
    dto.limited = this.limited
    return dto
  }
}
