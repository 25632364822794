import { observable } from "mobx";
import { serializable } from 'serializr'
import { IAutoCancelDTO } from "../dtos/IAutoCloseDTO";
import { AutoCancelModeType } from "../types/AutoCancelModeType";
import { PeriodType } from "../types/PeriodType";

export class AutoCancel implements IAutoCancelDTO {
    @serializable @observable public enabled: boolean = false;
    @serializable @observable public mode: AutoCancelModeType = 'when-due';
    @serializable @observable public periodValue?: number;
    @serializable @observable public periodType?: PeriodType;
    @serializable @observable public specificCloseDate?: string;
    @serializable @observable public specificCloseDateTimeZone?: string;

}