import { computed, action, observable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { ConsentsStore } from '../store/ConsentsStore'
import { Consent } from '../aggregate/Consent'
import { ConsentDrawerVM } from './ConsentDrawerVM'

type DrawerModeType = 'create' | 'edit'
type LanguageType = 'en' | 'de'

export class ConsentWidgetVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.drawerVM = new ConsentDrawerVM(rootStore)
  }

  @observable public isDrawerOpen: boolean = false
  @observable public drawerMode: DrawerModeType = 'create'
  @observable public english: string = ''
  @observable public german: string = ''
  @observable public currentLanguage: LanguageType = 'en'
  @observable public consentRequiredChangeProcessing: boolean = false
  @observable public drawerVM: ConsentDrawerVM = null

  @computed
  public get consentsStore(): ConsentsStore {
    return this.rootStore.consentsStore
  }

  @computed
  public get latestConsent(): Consent {
    return this.consentsStore.latestConsent
  }

  @computed
  public get currentOrganization() {
    return this.rootStore.organizationsStore.currentOrganization
  }

  @computed
  public get isConsentRequiredForLogin() {
    if (!this.currentOrganization) return false
    return this.currentOrganization.isConsentRequiredForLogin
  }

  @action
  public toggleDrawer() {
    this.drawerVM.toggleDrawer()
  }

  @action
  public setDrawerMode(mode: DrawerModeType) {
    this.drawerMode = mode
    if (mode === 'edit') {
      this.english = this.latestConsent.text.en
      this.german = this.latestConsent.text.de
    }
  }

  @action
  public setLanguage(language: LanguageType) {
    this.currentLanguage = language
  }

  @computed
  public get changeConsentRequiredEnabled() {
    if (!this.latestConsent) return false
    else return true
  }
}
