import React from 'react'
import { observer, inject } from 'mobx-react'
import './InvitationsLinkDialog.scss'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import MuiConfirmPrompt from '../../../../../shared/MuiConfirmPrompt'
import { CampaignDetailsVM } from '../../../../view-models/dashboard/campaign-details/CampaignDetailsVM'

interface Props {
  campaignDetailsVM?: CampaignDetailsVM
  localizationStore?: LocalizationStore
}

const RemindDialog: React.FC<Props> = ({ campaignDetailsVM, localizationStore }) => {
  const detailsVM = campaignDetailsVM
  if (!detailsVM) return null
  const vm = detailsVM.surveyParticipantsList
  if (!vm) return
  if (!vm.showRemindDialog) return null
  const lz = localizationStore.lzStrings.surveyInvitationsList

  const renderMessage = () => {
    if (vm.newSelectedRow) return lz.remind_single
  }

  const confirm = () => {
    if (vm.newSelectedRow) vm.remindInvite()
  }

  return (
    <MuiConfirmPrompt
      confirmMessage={renderMessage()}
      onClose={() => vm.toggleRemindDialog()}
      open={vm.showRemindDialog}
      onConfirm={() => confirm()}
      title={lz.send_invitation_reminder}
      cancelFullWidth={true}
    />
  )
}

export default inject('localizationStore')(observer(RemindDialog))
