import React from 'react'
import { OrganizationsSAStore } from '../../store/OrganizationsSAStore'
import { inject, observer } from 'mobx-react'
import { Grid, FormControlLabel, Checkbox, InputLabel, Switch, TextField } from '@material-ui/core'
import './OrganizationEditDrawer.scss'
import LocalizationStore from '../../../localization/LocalizationStore'
import InfoTooltip from '../../../shared/InfoTooltip'

interface Props {
  organizationsSAStore?: OrganizationsSAStore
  localizationStore?: LocalizationStore
}

const OrganizationAdditionalFileTypes: React.FC<Props> = ({ organizationsSAStore, localizationStore }) => {
  const { editVM: vm } = organizationsSAStore
  if (!vm || vm.isNew) return null

  const renderAnalysis = () => {
    return (
      <div id='file-types'>
        <div style={{ marginTop: '10px', color: 'black' }}>
          <div className={'file-types-header'}>
          <InputLabel className='details-info-label'>{'Additional File Types'}</InputLabel>
          <InfoTooltip message='Add additional file types for this organization. This is a comma separated list of file extensions.' />
          </div>
          <TextField
            className='details-info-input'
            value={vm.fileTypesAsString}
            onChange={(e) => vm.setFileTypesAsString(e.target.value)}
            fullWidth
            variant='outlined'
            id='orgFileTypes'
          />
        </div>
      </div>
    )
  }

  return (
    <div id='OrganizationAdditionalFileTypes'>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          {renderAnalysis()}
        </Grid>
      </Grid>
    </div>
  )
}

export default inject('organizationsSAStore')(observer(OrganizationAdditionalFileTypes))
