import React from 'react'
import { inject, observer } from 'mobx-react'
import { UserTasksStore } from '../../store/UserTasksStore'
import { UsersStore } from '../../../users/store/UsersStore'
import LocalizationStore from '../../../localization/LocalizationStore'
import { Grid } from '@material-ui/core'
import DrawerCommentCard from './DrawerCommentCard'
import DrawerCommentInput from './DrawerCommentInput'
import TaskCommentCard from '../../../task-comments/views/TaskCommentCard'

interface IDrawerCommentsContainer {
  localizationStore?: LocalizationStore
  userTasksStore?: UserTasksStore
  usersStore?: UsersStore
}

const DrawerCommentsContainer: React.FC<IDrawerCommentsContainer> = ({
  localizationStore,
  userTasksStore,
  usersStore,
}) => {
  const { lzStrings } = localizationStore
  const { editVM: VM } = userTasksStore
  return (
    <section>
      <h6>{lzStrings.tasksManagementWidgetDrawer.comments}</h6>
      <Grid container spacing={1}>
        {VM.taskComments.map((comment) => {
          return <TaskCommentCard key={comment.objectId} comment={comment} />
        })}

        <DrawerCommentInput />
      </Grid>
    </section>
  )
}

export default inject(
  'localizationStore',
  'userTasksStore',
  'usersStore'
)(observer(DrawerCommentsContainer))
