import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { CardContent, Drawer, Tabs, Tab } from '@material-ui/core'
import './OrganizationEditDrawer.scss'
import MuiDrawerToolbar from '../../../shared/Mui-Drawer/MuiDrawerToolbar'
import MuiDrawerHeader from '../../../shared/Mui-Drawer/MuiDrawerHeader'
import DrawerTabPanel from '../../../shared/DrawerTabPanel'
import { OrganizationsSAStore } from '../../store/OrganizationsSAStore'
import OrganizationDetails from './OrganizationDetailsTab'
import OrganizationLabels from './OrganizationLabelsTab'
import OrganizationSettingsTab from './OrganizationSettingsTab'
import OrganizationAuthTab from './OrganizationAuthTab'

interface Props {
  organizationsSAStore?: OrganizationsSAStore
  localizationStore?: LocalizationStore
}

const OrganizationEditDrawer: React.FC<Props> = ({ organizationsSAStore, localizationStore }) => {
  const { organizationsSADrawer: strings } = localizationStore.lzStrings
  const { editVM: vm } = organizationsSAStore
  const [value, setValue] = React.useState(0)
  if (!vm) return null

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    vm.setTabValue(newValue)
  }

  const loadDrawer = () => {
    return (
      <div>
        <MuiDrawerToolbar
          handleCancelClick={() => {
            organizationsSAStore.toggleDrawer()
            setValue(0)
          }}
          handleSubmitClick={() => {
            vm.save()
          }}
          showReset={Boolean(vm.objectId)}
          onConfirmReset={() => organizationsSAStore.reloadEditVM(vm.objectId)}
          disabled={vm.isProcessing || vm.nameError}
          moreMenuProps={{
            hidden: !vm.objectId,
            isEditMode: vm.isEditMode,
            itemType: localizationStore.lzStrings.moreMenu.obj_type.tenant,
            itemName: vm.name,
            onConfirmDelete: () => vm.delete(),
          }}
        />
        <Tabs value={vm.tabValue} onChange={handleChange} variant='fullWidth'>
          <Tab label={strings.details} />
          <Tab label={'Settings'} disabled={vm.isNew} />
          <Tab label={strings.labels} disabled={vm.isNew} />
          <Tab label={'Auth'} disabled={vm.isNew} />
        </Tabs>
        <CardContent>
          <DrawerTabPanel value={vm.tabValue} index={0}>
            <OrganizationDetails />
          </DrawerTabPanel>
          <DrawerTabPanel value={vm.tabValue} index={1}>
            <OrganizationSettingsTab />
          </DrawerTabPanel>
          <DrawerTabPanel value={vm.tabValue} index={2}>
            <OrganizationLabels />
          </DrawerTabPanel>
          <DrawerTabPanel value={vm.tabValue} index={3}>
            <OrganizationAuthTab   />
          </DrawerTabPanel>
        </CardContent>
      </div>
    )
  }
  return (
    <div id='OrganizationEditDrawer'>
      <Drawer
        variant='temporary'
        anchor='right'
        open={organizationsSAStore.isDrawerOpen}
        onClose={() => organizationsSAStore.toggleDrawer()}
      >
        <MuiDrawerHeader
          title={vm.isNew ? strings.add_tenant : strings.edit_tenant}
          toggleDrawer={() => {
            organizationsSAStore.toggleDrawer()
          }}
        />
        {loadDrawer()}
      </Drawer>
    </div>
  )
}

export default inject('organizationsSAStore', 'localizationStore')(observer(OrganizationEditDrawer))
