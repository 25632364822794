import { Card } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { GoalsStore } from '../../store/GoalsStore'
import Content from './Content'
import Header from './Header'
import './GoalsCatalogWidget.scss'
import { GoalsCatalogStore } from '../../store/GoalsCatalogStore'

interface Props {
  goalsCatalogStore?: GoalsCatalogStore
}

const GoalsCatalogWidget: React.FC<Props> = ({ goalsCatalogStore }) => {
  const { goalsCatalogWidgetVM: vm } = goalsCatalogStore
  if (!vm) return null
  if (!goalsCatalogStore.canCreateGoals) return null

  return (
    <Card id='GoalsCatalogWidget' elevation={0}>
      <Header />
      <Content />
    </Card>
  )
}

export default inject('goalsCatalogStore')(observer(GoalsCatalogWidget))
