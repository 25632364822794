import { observable, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { SurveyEditVM } from './SurveyEditVM'
import { SurveyResponse } from '../../survey-responses/aggregate/SurveyResponse'
import moment from 'moment'
import { AnalysisVM } from './AnalysisVM'
import { AudienceMember } from 'src/app/audience-members/aggregate/AudienceMember'
import { FKUser } from '../../organization-users/aggregate/FKUser'

export class ResponseRowVM {
  private rootStore: RootStore
  private analysisVM: AnalysisVM
  private editVM: SurveyEditVM

  constructor(
    rootStore: RootStore,
    editVM: SurveyEditVM,
    analysisVM: AnalysisVM,
    response: SurveyResponse
  ) {
    this.rootStore = rootStore
    this.response = response
    this.editVM = editVM
    this.analysisVM = analysisVM
  }

  @observable public response: SurveyResponse = null

  @computed
  public get objectId(): string {
    return this.response.objectId
  }

  @computed
  public get anonymizeResults(): boolean {
    return this.editVM.anonymizeResults
  }

  @computed
  public get userId(): string {
    return this.response.userId
  }

  @computed
  public get anonymousParticipantName(): string {
    if (!this.response.anonymousParticipant) return this.response.email
    if (!this.response.anonymousParticipant.firstName) return this.response.email
    const participantName =
      this.response.anonymousParticipant.firstName +
      ' ' +
      this.response.anonymousParticipant.lastName
    return participantName ? participantName : this.response.email
  }

  @computed
  public get fkUser(): FKUser {
    return this.response.fk_User
  }

  @computed
  public get userName(): string {
    if (this.editVM.anonymizeResults) return '(Anonymous)'
    if (this.anonymousParticipantName) return this.anonymousParticipantName
    if (!this.fkUser) return 'unknown user'
    return this.fkUser.name
  }

  @computed
  public get responseId(): string {
    return this.response.objectId
  }

  @computed
  public get isSelected(): boolean {
    return this.analysisVM.selectedResponseId === this.responseId
  }

  @computed
  public get responseDate(): Date {
    return this.response.respondedAtAsDate
  }

  @computed
  public get responseDateFormatted(): string {
    if (this.editVM.anonymizeResults) return '(Anonymous)'
    const s = this.rootStore.localizationStore.lzStrings.surveys
    if (!this.response.respondedAt) return ''
    return moment(this.response.respondedAtAsDate).format(s.responded_at_date_format2)
  }

  @computed
  public get isPaperSurvey(): boolean {
    // if (this.editVM.anonymizeResults) return false
    return (
      Boolean(this.response.inputByUserId)
    )
  }

  @computed
  public get inputByUserId(): string {
    return this.response.inputByUserId
  }

  @computed
  public get inputByUser(): AudienceMember {
    if (!this.inputByUserId) return null
    const user = this.rootStore.audienceMembersStore.getUser(this.inputByUserId)
    if (!user) return null
    return user
  }

  @computed
  public get inputByUserName(): string {
    if (!this.inputByUser) return 'unknown user'
    return this.inputByUser.name
  }

  @computed
  public get paperSurveyInputAt(): Date {
    return this.response.paperSurveyInputAtAsDate
  }

  @computed
  public get paperSurveyResponseDate(): Date {
    return this.response.paperSurveyResponseDateAsDate
  }
  

  @computed
  public get userNameTooltip(): string {
    if (this.editVM.anonymizeResults) return '(Anonymous)'
    const lzStrings = this.rootStore.localizationStore.lzStrings
    if (this.isPaperSurvey) return `${lzStrings.responseList.input_by} ${this.inputByUserName}`
    if (!this.fkUser) return 'unknown user'
    return `${this.fkUser.name}`
    // const inputByAt =
    //   this.inputByUserName && this.paperSurveyInputAt
    //     ? `${lzStrings.responseList.input_by} ${this.inputByUserName}, ${moment(
    //         this.paperSurveyInputAt
    //       ).calendar()}`
    //     : ''
    // if (inputByAt) return inputByAt
    // const inputBy = this.inputByUserName
    //   ? `${lzStrings.responseList.input_by} ${this.inputByUserName}`
    //   : ''
    // if (inputBy) return inputBy
    // const inputAt = this.paperSurveyInputAt
    //   ? `${lzStrings.responseList.input} ${moment(this.paperSurveyInputAt).calendar()}`
    //   : ''
    // if (inputAt) return inputAt
    // return null
  }
}
