import { action, observable, computed } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { ParseService } from '../../../services/ParseService'
import EventType from '../aggregate/EventType'

export default class EventTypeEditForm {
  rootStore: RootStore

  constructor(rootStore: RootStore, eventType: EventType = null) {
    this.rootStore = rootStore
    if (eventType) this.loadData(eventType)
  }

  private loadData(eventType: EventType) {
    this.objectId = eventType.objectId
    this.name = eventType.name
    this.description = eventType.description
  }

  @observable objectId: string = ''
  @observable name: string = ''
  @observable description: string = ''
  @observable saveTried: boolean = false
  @observable customCollectionId: string = ''

  @action
  public setCustomCollection(val) {
    this.customCollectionId = val
  }

  @computed
  public get customCollections() {
    return this.rootStore.customCollectionsStore.currentOrgCustomCollections.filter(
      (e) => !e.isDeleted
    )
  }

  @action
  public markSaveTried() {
    this.saveTried = true
  }

  @computed
  public get errorHelperText() {
    if (!this.nameValid) return 'Please enter a name for this event type.'
    if (!this.nameUnique) return 'This name is being used by another event type.'
  }

  @computed
  public get nameValid() {
    if (!this.saveTried) return true
    if (!this.name || this.name.trim() === '') return false
    return true
  }

  @computed
  public get nameUnique() {
    if (!this.saveTried) return true
    const foundEventType = this.rootStore.eventTypesStore.getEventTypeByName(this.name)
    if (foundEventType && foundEventType.objectId !== this.objectId) return false
    return true
  }

  @computed
  public get isValid() {
    if (!this.saveTried) return true
    if (!this.nameValid) return false
    if (!this.nameUnique) return false
    return true
  }

  @computed
  public get isEditMode() {
    return Boolean(this.objectId)
  }

  @action
  public async save() {
    this.markSaveTried()
    if (!this.isValid) return false
    this.rootStore.eventTypesStore.showDrawer = false

    try {
      const newEventType = {
        objectId: this.objectId ? this.objectId : null,
        name: this.name,
        description: this.description,
      }

      await new ParseService().saveEventType(this.rootStore.appStore.currentOrgId, newEventType)

      this.rootStore.eventTypesStore.clearForms()
    } catch (e) {
      console.error(e)
    }
  }

  @action
  public async delete() {
    try {
      this.rootStore.eventTypesStore.showDeleteDialog = false
      this.rootStore.eventTypesStore.showDrawer = false

      await new ParseService().deleteEventType(this.rootStore.appStore.currentOrgId, this.objectId)
    } catch (e) {
      console.error(e)
    }
  }
}
