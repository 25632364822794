import Parse from 'parse'
import { CloudinaryResult } from '../aggregate/CloudinaryResult'
import { IFileUploadStatusFn } from '../interfaces/IFileUploadStatusFn'
import { IParseFileSaveResult } from '../interfaces/IParseFileSaveResult'

export class FileUploadService {
  private currentSize: number
  private totalSize: number

  public async uploadFilesToAzureStorage(
    orgId: string,
    files: Array<File>
  ): Promise<IParseFileSaveResult[]> {
    const res: IParseFileSaveResult[] = []
    for (let i = 0; i < files.length; i++) {
      const result: IParseFileSaveResult = {
        attachment: null,
        fileName: '',
        success: true,
        error: '',
        type: undefined,
      }
      try {
        let file = files[i]
        const fileName = file.name
        const cleanFileName = fileName.replace(/[^a-zA-Z0-9. ~_-]/gi, '_')
        result.fileName = cleanFileName
        const parseFile = new Parse.File(cleanFileName, file)
        parseFile.setTags({ organizationId: orgId })
        const savedFile = await parseFile.save()
        if (savedFile) result.attachment = savedFile
        if (file.type.includes('image')) result.type = 'image'
        if (file.type.includes('video')) result.type = 'video'
      } catch (e) {
        if (e) {
          let msg = e.toString()
          if (msg === 'ParseError: undefined undefined') {
            msg = 'Please verify the file type and size and try again. '
            msg += 'Contact administrator if you are still having trouble.'
          }
          result.success = false
          result.error = msg
        }
      }
      res.push(result)
    }
    return res
  }

  public async getCloudinarySignature(): Promise<any> {
    var millisecondsToSeconds = 1000
    var timestamp = Math.round(Date.now() / millisecondsToSeconds)

    const paramsToSign = {
      timestamp: timestamp,
    }

    const resp = await Parse.Cloud.run('getCloudinarySignature', { paramsToSign })

    const data = {
      timestamp: timestamp,
      signature: resp.signature,
      apiKey: resp.apiKey,
    }

    return data
  }

  public async uploadMediaItemsToCloudinary(
    mediaFiles: Array<File>,
    cb?: IFileUploadStatusFn
  ): Promise<CloudinaryResult[]> {
    const res = []
    this.currentSize = 0
    this.totalSize = mediaFiles.reduce((prevValue, currentFile) => prevValue + currentFile.size, 0)
    for (let i = 0; i < mediaFiles.length; i++) {
      const file = mediaFiles[i]
      const result: CloudinaryResult = await this.processFile(file, cb)
      if (result.resource_type === 'video') {
        const thumbnailURL = await this.buildThumbnailURL(
          result.public_id,
          '.jpg',
          result.secure_url
        )
        result.thumbnail = thumbnailURL
      }
      result.original_filename = file.name
      res.push(result)
    }
    return res
  }

  private isVideo(file: File) {
    return file && file.type ? !!file.type.match('video.*') : false
  }

  private async processFile(file: File, cb?: IFileUploadStatusFn) {
    var size = file.size
    const isVideo = this.isVideo(file)
    const sliceSize = 20000000
    var start = 0
    const cloudinarySignatureData = await this.getCloudinarySignature()
    const uniqueUploadId = new Date().toString()
    const that = this

    return await sendChunk()
    async function sendChunk() {
      let end = start + sliceSize
      const startTime = window.performance.now()

      if (end > size) {
        end = size
      }
      const chunk = end - start
      that.currentSize =
        that.currentSize + chunk >= that.totalSize ? that.totalSize - 1 : that.currentSize + chunk

      if (cb) {
        let heading = `Processing ${file.name} Upload`
        let details = ''
        if (end >= size) heading = `Finalizing ${file.name} Upload`
        if (end >= size && isVideo) details = `Encoding can take 1 to 2 minutes`
        cb(Math.floor((that.currentSize / that.totalSize) * 100), heading, details)
      }

      const s = that.slice(file, start, end)
      const response = await that.send(
        s,
        start,
        end - 1,
        size,
        cloudinarySignatureData,
        uniqueUploadId
      )
      if (response) {
        return response
      }
      if (end < size) {
        start += sliceSize
        return await sendChunk()
      }
    }
  }

  private slice(file, start, end) {
    let slice
    if (file.mozSlice) slice = file.mozSlice
    if (file.webkitSlice) slice = file.webkitSlice
    if (file.slice) slice = file.slice
    return slice.bind(file)(start, end)
  }

  private async send(piece, start, end, size, cloudinarySignatureData, uniqueUploadId) {
    const formData = new FormData()
    const url = 'https://api.cloudinary.com/v1_1/rippleworx/auto/upload'

    formData.append('file', piece)
    formData.append('api_key', String(cloudinarySignatureData.apiKey))
    formData.append('timestamp', String(cloudinarySignatureData.timestamp))
    formData.append('signature', String(cloudinarySignatureData.signature))
    const response = await fetch(url, {
      method: 'POST',
      body: formData,
      headers: {
        'Content-Range': 'bytes ' + start + '-' + end + '/' + size,
        'X-Unique-Upload-Id': uniqueUploadId,
      },
    })
    const data = await response.text()
    const result: CloudinaryResult = JSON.parse(data)
    if (result.secure_url) return result
  }

  public async getCloudinarySignatureForVideoThumbnail(public_id, desiredThumbnailExtension) {
    const thumbnailSignature = await Parse.Cloud.run('getCloudinarySignatureForVideoThumbnail', {
      public_id,
      desiredThumbnailExtension,
    })
    return thumbnailSignature
  }

  public async buildThumbnailURL(public_id, desiredThumbnailExtension, url) {
    const thumbnailSignature = await this.getCloudinarySignatureForVideoThumbnail(
      public_id,
      desiredThumbnailExtension
    )
    const thumbnailURL =
      url.slice(0, url.indexOf('/s--') + 4) +
      thumbnailSignature +
      url.slice(url.lastIndexOf('--/'), url.lastIndexOf('.')) +
      desiredThumbnailExtension
    return thumbnailURL
  }
}
