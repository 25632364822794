import { observable, action } from 'mobx'
import { serializable, deserialize, serialize, list, object } from 'serializr'
import { AudienceMember } from './AudienceMember'
import moment from 'moment'
import { ITaskDTO } from '../dtos/ITaskDTO'
import { Alarm } from './Alarm'
import { IAggregate } from '../../shared/data/IAggregate'
import { UserTaskSnapshot } from './UserTaskSnapshot'
import { Attachment } from '../../attachments/aggregate/Attachment'

export class Task implements ITaskDTO, IAggregate {
  static create(orgId: string, assignedBy: string, endOfDay: Date): Task {
    const task = new Task()
    task.objectId = null
    task.organizationId = orgId
    task.assignedByUserId = assignedBy
    const roundedUp = Math.ceil(moment().minute() / 15) * 15
    const newDate = moment().minute(roundedUp).second(0)
    task.dueDate = moment(endOfDay ? endOfDay : newDate)
      .second(0)
      .add(1, 'days')
      .unix()
    return task
  }

  @serializable @observable public objectId: string = ''
  @serializable @observable public organizationId: string = ''
  @serializable @observable public assignedByUserId: string = ''
  @serializable @observable public taskTypeId: string = ''
  @serializable @observable public categoryId: string = ''
  @serializable @observable public dueDate: number = 0
  @serializable @observable public channelPartnerId: string = ''
  @serializable @observable public name: string = ''
  @serializable @observable public description: string = ''
  @serializable @observable public isDeleted: boolean = false
  @serializable @observable public feedbackSurveyTemplateId: string = ''
  @serializable @observable public createdAt: number = 0
  @serializable @observable public autoPublishFeedback: boolean = false
  @serializable(list(object(UserTaskSnapshot))) @observable public snapshots: UserTaskSnapshot[] =
    []
  @serializable(list(object(Attachment))) @observable public attachments: Attachment[] = []
  @serializable(list(object(AudienceMember)))
  @observable
  public assignedToAudienceMembers: AudienceMember[] = []
  @serializable(list(object(Alarm))) @observable public alarms: Alarm[] = []

  isOnServer = false
  isProcessing = false

  @action
  public setName(name: string) {
    this.name = name
  }

  @action
  public setDescription(description: string) {
    this.description = description
  }

  @action
  public setAutoPublishFeedback(boolean: boolean) {
    this.autoPublishFeedback = boolean
  }

  @action
  public setFeedbackTemplateSurveyId(id: string) {
    this.feedbackSurveyTemplateId = id
    this.autoPublishFeedback = false
    if (this.feedbackSurveyTemplateId !== 'new') this.autoPublishFeedback = true
  }

  @action
  public setCategoryId(id: string) {
    this.categoryId = id
  }

  @action
  public setDueDate(dateUnix: number) {
    this.dueDate = dateUnix
  }

  @action
  public setChannelPartnerId(id: string) {
    this.channelPartnerId = id
  }

  @action
  public toggleAutoPublishFeedback() {
    this.autoPublishFeedback = !this.autoPublishFeedback
  }

  @action
  public setTaskTypeId(id: string) {
    this.taskTypeId = id
  }

  @action
  public addAlarm() {
    const newAlarm = Alarm.create()
    this.alarms.push(newAlarm)
  }

  @action
  public addOnTimeAlarm() {
    const newAlarm = Alarm.createOnTimeAlarm()
    this.alarms.push(newAlarm)
  }

  @action
  public deleteAlarm(id: string) {
    this.alarms = this.alarms.filter((alarm) => alarm.id !== id)
  }

  @action
  markIsNotOnServer() {
    this.isOnServer = false
  }

  @action
  markIsNotProcessing() {
    this.isProcessing = false
  }

  @action
  setCreator(assignedByUserId: string) {
    this.assignedByUserId = assignedByUserId
  }

  @action
  public addAttachment(attachment: Attachment) {
    this.attachments.push(attachment)
  }

  @action
  public deleteAttachment(index: number) {
    if (index < 0) return
    this.attachments.splice(index, 1)
  }

  @action
  markIsProcessing() {
    this.isProcessing = true
  }

  @action
  public markIsOnServer() {
    this.isOnServer = true
  }

  public clone(): Task {
    return deserialize(Task, this.serialize())
  }

  public serialize() {
    return serialize(this)
  }
}
