import React from 'react'
import { inject, observer, useLocalStore } from 'mobx-react'
import Dropzone from 'react-dropzone'
import CloseIcon from '@material-ui/icons/Close'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  DialogActions,
  Button,
  CircularProgress,
  Tooltip,
  Typography,
} from '@material-ui/core'
import './FileUploadModal.scss'
import { FileUploadModalVM } from '../view-models/FileUploadModalVM'
import { FileTypeEnum } from '../enum/FileTypeEnum'
import { FileVM } from '../view-models/FileVM'
import DescriptionIcon from '@material-ui/icons/Description'
import { IParseFileSaveResult } from '../interfaces/IParseFileSaveResult'
import { RootStore } from '../../stores/RootStore'
import env from '../../../env'

interface FileUploadModalProps {
  allowMultiple: boolean
  fileTypes: FileTypeEnum
  addAfterSave: Function
  toggleShowModal: Function
  isOpen: boolean
  rootStore?: RootStore
}

const FileUploadModal: React.FC<FileUploadModalProps> = ({
  allowMultiple,
  fileTypes,
  addAfterSave,
  toggleShowModal,
  isOpen,
  rootStore,
}) => {
  const localStore = useLocalStore(() => ({
    vm: new FileUploadModalVM(rootStore, allowMultiple, addAfterSave, toggleShowModal, fileTypes),
  }))
  const vm = localStore.vm

  const renderFile = (file: FileVM, index: number) => {
    const renderThumbnail = () => {
      if (file.type === 'image') return <img src={file.preview} />
      if (file.type === 'video') return <video src={file.preview} />
      return <DescriptionIcon />
    }

    return (
      <Grid item xs={12} key={index} className='attachment-container'>
        <div className='attachment-content'>
          <div
            className={
              file.type === 'image' || file.type === 'video'
                ? 'thumbnail-container'
                : 'file-container'
            }
          >
            {renderThumbnail()}
          </div>
          <Tooltip title={file.name} placement='bottom-start' enterDelay={500}>
            <span>{file.name}</span>
          </Tooltip>
        </div>
        <div>
          <IconButton disabled={vm.uploadProcessing} onClick={() => vm.removeFile(index)}>
            <CloseIcon />
          </IconButton>
        </div>
      </Grid>
    )
  }

  const renderFiles = () => {
    return (
      <Grid container className='filesContainer'>
        {vm.files.map((file, index) => {
          return renderFile(file, index)
        })}
      </Grid>
    )
  }

  const renderFileTooBig = (file: File, index: number) => {
    return (
      <Grid item xs={12} key={index} className={'errorContainer'}>
        <div className={'errorText'}>{file.name} exceeds the maximum file size.</div>
      </Grid>
    )
  }

  const renderFilesTooBig = () => {
    if (!vm.filesTooBig) return null
    return (
      <Grid container className='errorContainer'>
        {vm.filesTooBig.map((file, index) => {
          return renderFileTooBig(file, index)
        })}
      </Grid>
    )
  }

  const renderDialogActions = () => {
    return (
      <DialogActions>
        <Grid
          container
          className='dialog-actions'
          alignItems='center'
          justifyContent='space-between'
        >
          <Grid item>
            <Button
              variant='text'
              size='large'
              disabled={vm.uploadProcessing}
              onClick={() => vm.toggleModal()}
              className='cancelButton'
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              size='large'
              onClick={() => vm.uploadFiles()}
              disabled={!vm.saveEnabled}
              className='saveButton'
            >
              Upload
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    )
  }

  const renderDefault = () => {
    if (vm.uploadProcessing) return
    return (
      <Grid container className='dialog-content'>
        <DialogContent>
          <Dropzone
            accept={[
              "application/pdf",
              "application/msword",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              "application/vnd.ms-excel",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              "text/csv",
              "application/zip",
              "image/bmp",
              "text/plain",
              "application/vnd.oasis.opendocument.text",
              "application/rtf",
              "video/mp4",
              "video/quicktime",
              "video/x-ms-wmv",
              "image/jpeg",
              "image/png",
              "image/gif",
              "image/svg+xml",
              "image/heic",
            ]}
            noDragEventsBubbling={true}
            onDrop={(files) => vm.handleFilesDrop(files)}
            multiple={vm.allowMultiple}
            minSize={0}
          >
            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
              <>
                <div className='dropzone' {...getRootProps()}>
                  <input {...getInputProps()} />

                  {!isDragActive && (
                    <div className='iconContainer'>
                      <NoteAddIcon className='noteAddIcon' />
                      <p className='dropzoneText'>
                        Drag and drop or <span className='dropLink'>browse</span> your files
                      </p>
                    </div>
                  )}

                  {isDragActive && !isDragReject && (
                    <div className='iconContainer'>
                      <ThumbUpIcon className='thumbUpIcon' />
                      <p className='dropzoneText'>This file looks good to go!</p>
                    </div>
                  )}

                  {isDragActive && isDragReject && (
                    <div className='iconContainer'>
                      <ThumbDownIcon className='thumbDownIcon' />
                      <p className='dropzoneText'>Sorry, this file type isn't accepted</p>
                    </div>
                  )}
                </div>
              </>
            )}
          </Dropzone>
          <div className='dialogText'>
            <DialogContentText className={'file-details'}>
              <span className={'file-details-title'}>Accepted File Types:</span>{' '}
              {vm.acceptedFileTypes}
            </DialogContentText>
            <DialogContentText className={'file-details'}>
              <span className={'file-details-title'}>Max File Size:</span> {vm.fileSizePrompt}
            </DialogContentText>
          </div>
        </DialogContent>
      </Grid>
    )
  }

  const renderProgress = () => {
    const useCloudinary = env.var.REACT_APP_MEDIA_UPLOAD_SERVICE === 'cloudinary'
    return (
      <div className='dropzone'>
        <div className='iconContainer'>
          <Typography variant='body1' className='dropzoneText'>
            {vm.uploadProcessingText}
          </Typography>
          <Typography variant='caption' className='dropzoneText'>
            {vm.uploadProcessingSubText}
          </Typography>
          {useCloudinary && (
            <Box position='relative' display='inline-flex'>
              <CircularProgress
                className={'uploadProgress'}
                variant='determinate'
                value={vm.uploadProcessingProgress}
              />
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position='absolute'
                display='flex'
                alignItems='center'
                justifyContent='center'
              >
                <Typography variant='caption' component='div' color='textSecondary'>{`${Math.round(
                  vm.uploadProcessingProgress
                )}%`}</Typography>
              </Box>
            </Box>
          )}
        </div>
      </div>
    )
  }

  const renderProcessing = () => {
    if (!vm.uploadProcessing) return
    return (
      <Grid container className='dialog-content'>
        <DialogContent>
          {renderProgress()}
          <div className='dialogText'>
            <DialogContentText>Accepted File Types: {vm.acceptedFileTypes}</DialogContentText>
            <DialogContentText>Maximum File Size: {vm.fileSizePrompt}</DialogContentText>
          </div>
        </DialogContent>
      </Grid>
    )
  }

  const renderTitle = () => {
    return (
      <Grid
        className='dialog-title'
        container
        justifyContent='space-between'
        alignItems='center'
        style={{ paddingRight: '10px' }}
      >
        <Grid item>
          <DialogTitle>File Upload</DialogTitle>
        </Grid>
        <Grid item>
          <IconButton disabled={vm.uploadProcessing} onClick={() => vm.toggleModal()}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    )
  }

  const renderDropzone = () => {
    if (vm.showErrors) return null
    return (
      <>
        {renderTitle()}
        {renderDefault()}
        {renderProcessing()}
        {renderFilesTooBig()}
        {renderFiles()}
        {renderDialogActions()}
      </>
    )
  }

  const renderError = (error: IParseFileSaveResult, index) => {
    return (
      <Grid item xs={12} key={index} className={'errorContainer'}>
        <div>{error.fileName}</div>
        <div className={'errorText'}>{error.error}</div>
      </Grid>
    )
  }

  const renderErrors = () => {
    if (!vm.showErrors) return null
    return (
      <>
        <Grid
          className='dialog-title'
          container
          justifyContent='space-between'
          alignItems='center'
          style={{ paddingRight: '10px' }}
        >
          <Grid item>
            <DialogTitle>File Upload Error</DialogTitle>
          </Grid>
          <Grid item>
            <IconButton onClick={() => vm.toggleModal()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContentText className='errorDialog'>
          There was an error uploading one or more of your files:
        </DialogContentText>
        <Grid container className='errorContainer'>
          {vm.errors.map((error, index) => {
            return renderError(error, index)
          })}
        </Grid>
        <DialogActions>
          <Grid
            container
            className='dialog-actions'
            alignItems='center'
            justifyContent='space-between'
          >
            <Grid item>
              <Button
                variant='contained'
                size='large'
                onClick={() => vm.toggleModal()}
                className='saveButton'
              >
                Ok
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </>
    )
  }

  return (
    <Dialog id='FileUploadModal' onClose={() => vm.toggleModal()} open={isOpen} fullWidth>
      {renderDropzone()}
      {renderErrors()}
    </Dialog>
  )
}

export default inject('rootStore')(observer(FileUploadModal))
