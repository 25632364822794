import React, { useRef } from 'react'
import { observer, inject } from 'mobx-react'
import LocalizationStore from '../../../../localization/LocalizationStore'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core'
import './DetailsTabPanel.scss'
import { Editor } from '@tinymce/tinymce-react'
import { PLUGINS, TOOLBAR } from '../../../../config/TinyMCEconfig'
import FileUploadModal from '../../../../upload/views/FileUploadModal'
import { FileTypeEnum } from '../../../../upload/enum/FileTypeEnum'
import { Attachment } from '../../../../upload/aggregate/Attachment'
import TinyMCEMediaPicker from '../TinyMCEMediaPicker/TinyMCEMediaPicker'
import { LabelsStore } from '../../../../labels/store/LabelsStore'
import { SurveyCompletedContentVM } from '../../../view-models/SurveyCompletedContentVM'
import CMSItemsPickerDialog from '../../../../cms-items/views/dialogs/CMSItemsPickerDialog'
import { CMSItemsTypeEnum } from '../../../../cms-items/enum/CMSItemsTypeEnum'
import { OrganizationsStore } from '../../../../organizations/store/OrganizationsStore'

interface Props {
  vm: SurveyCompletedContentVM
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
  organizationsStore?: OrganizationsStore
}

const SurveyCompletionContent: React.FC<Props> = ({ vm, localizationStore, labelsStore, organizationsStore }) => {
  const editorRef: any = useRef()

  if (!vm) return null
  const mediaPickerVM = vm.mediaUploadPickerVM

  const { surveys: strings } = localizationStore.lzStrings

  const renderSurveyCompletedContentRow = () => {
    return (
      <Grid
        item
        className={vm.isReadOnly ? 'details-tab-panel-right disabled' : 'details-tab-panel-right'}
      >
        <Button className='add-button' variant='outlined' onClick={() => vm.toggleButtonModal()}>
          Add A Button
        </Button>
        <h6>{`${labelsStore.getLabelByLanguageAndFor('pulse')} Completed Content`}</h6>
        <Grid item xs={12}>
          <Editor
            ref={editorRef}
            key={vm.objectId}
            value={vm.surveyCompletedContent}
            init={{
              height: 400,
              resize: false,
              menubar: false,
              plugins: `${PLUGINS} image media`,
              toolbar: `customInsertButton ${TOOLBAR}`,
              contextmenu: false,
              extended_valid_elements:
                'video[poster|src|controls|preload|width|height|data-setup],source[src|type]',
              images_reuse_filename: true,
              images_upload_handler: (blobInfo, success, failure, progress) =>
                vm.uploadToCloudinary(blobInfo, success, failure, progress),
              placeholder:
                'Add content for the  completion screen at the end of the ' +
                labelsStore.getLabelByLanguageAndFor('pulse').toLowerCase() +
                '...',
              setup: (editor) => {
                editor.on('init', (e) => {
                  vm.setEditorRef(editorRef)
                })
                editor.ui.registry.addMenuButton('customInsertButton', {
                  text: 'Upload Media',
                  tooltip:
                    'Upload an image or video from the rippleworx media library or your computer.',
                  icon: 'upload',
                  fetch: function (callback) {
                    let items: any = []
                    if (organizationsStore.currentOrganization?.hasCMSPicker) {
                      items = [
                        {
                          type: 'menuitem',
                          icon: 'gallery',
                          text: 'Content Management System',
                          onAction: () => {
                            vm.toggleMediaUploadPicker()
                          },
                        }
                      ]
                    } else {
                      items = [
                        {
                          type: 'menuitem',
                          icon: 'gallery',
                          text: 'RippleWorx Media Library',
                          onAction: () => {
                            vm.toggleMediaUploadPicker()
                          },
                        },
                        {
                          type: 'menuitem',
                          icon: 'browse',
                          text: 'My Computer',
                          onAction: () => {
                            vm.toggleMediaUploadModal()
                          },
                        },
                      ]
                    }
                    callback(items)
                  },
                })
              },
              browser_spellcheck: true,
            }}
            onEditorChange={(e) => vm.setSurveyCompletedContent(e)}
          />
        </Grid>
      </Grid>
    )
  }

  const renderMediaUploadModal = () => {
    if (organizationsStore.currentOrganization?.hasCMSPicker) return renderCMSUploadModal()
    return renderStandardUploadModal()
  }

  const renderCMSUploadModal = () => {
    return (
      <CMSItemsPickerDialog
        key={`surveyIntroFile-${vm.objectId}`}
        allowMultiple={false}
        fileTypes={CMSItemsTypeEnum.IMAGES_VIDEOS}
        addAfterSave={(a: Attachment[]) => vm.addMediaToInfoTextFromComputer(a[0])}
        toggleShowModal={() => vm.toggleMediaUploadModal()}
        isOpen={vm.showMediaModal}
      />
    )
  }

  const renderStandardUploadModal = () => {
    return (
      <FileUploadModal
        key={`surveyIntroFile-${vm.objectId}`}
        allowMultiple={false}
        fileTypes={FileTypeEnum.IMAGES_VIDEOS}
        addAfterSave={(a: Attachment[]) => vm.addMediaToInfoTextFromComputer(a[0])}
        toggleShowModal={() => vm.toggleMediaUploadModal()}
        isOpen={vm.showMediaModal}
      />
    )
  }

  const renderAddButtonModal = () => {
    return (
      <Dialog open={vm.showButtonModal} id='CantDeleteDialog'>
        <DialogTitle className='add-button-modal-header'>
          Add a button to Completed Content
        </DialogTitle>
        <DialogContent className='add-button-modal-content'>
          <InputLabel htmlFor='button text' required>
            Button Text
          </InputLabel>
          <OutlinedInput
            id='ButtonText'
            fullWidth
            labelWidth={0}
            autoComplete='off'
            value={vm.buttonText}
            onChange={(e) => vm.setButtonText(e.target.value)}
            error={!vm.isButtonTextValid}
          />
          <InputLabel htmlFor='button URL' required>
            Button URL
          </InputLabel>
          <OutlinedInput
            id='ButtonURL'
            fullWidth
            labelWidth={0}
            autoComplete='off'
            value={vm.buttonURL}
            onChange={(e) => vm.setButtonURL(e.target.value)}
            error={!vm.isButtonURLValid}
          />
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={() => vm.addButtonToContent()}>
            OK
          </Button>
          <Button variant='contained' onClick={() => vm.closeButtonModal()}>
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const renderMediaUploadPicker = () => {
    return <TinyMCEMediaPicker vm={mediaPickerVM} />
  }

  return (
    <>
      {renderMediaUploadModal()}
      {renderAddButtonModal()}
      {renderMediaUploadPicker()}
      {renderSurveyCompletedContentRow()}
    </>
  )
}

export default inject(
  'surveysStore',
  'localizationStore',
  'labelsStore',
  'organizationsStore'
)(observer(SurveyCompletionContent))
