import React, { FC } from 'react'
import { inject, observer } from 'mobx-react'
import clsx from 'clsx'
import { SurveysStore } from 'src/app/surveys/store/SurveysStore'
import './Steppers.scss'
import { SurveyEditTabsEnum } from 'src/app/surveys/types/SurveyEditTabsEnum'

interface Props {
  surveysStore?: SurveysStore
  icon: any
  label: string
  index: number
  error?: boolean
  visited?: boolean
  className?: string
}

const Step: FC<Props> = ({ surveysStore, index, error, visited, icon, label }) => {
  const surveyEditVM = surveysStore.viewModels.editVM
  if (!surveyEditVM) return null
  const vm = surveyEditVM.steppersVM
  if (!vm) return null
  const disabled = !vm.isNewSurvey || index === SurveyEditTabsEnum.PUBLISH
  return (
    <div
      className={clsx('step', 'sln-survey-step-' + label.toLowerCase(), {
        active: index === surveyEditVM.currentTabIndex && !vm.allCompleted,
        invalid: error,
        visited,
        'disabled-stepper': disabled,
      })}
      onClick={() => {
        if (disabled) return
        surveyEditVM.setCurrentTab(index)
      }}
    >
      <div className='step-left'>{icon}</div>
      <div className='step-right'>{label}</div>
    </div>
  )
}

export default inject('surveysStore')(observer(Step))
