import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { UserTasksStore } from '../../store/UserTasksStore'
import { Grid, TextField, Button } from '@material-ui/core'
import Avatar from '../../../shared/Avatar'
import moment from 'moment'
import { SurveysStore } from '../../../surveys/store/SurveysStore'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Linkify from 'react-linkify'
import { Survey } from '../../../surveys/aggregate/Survey'
import { AttachmentVM } from '../../../attachments/view-models/AttachmentVM'
import { CMSItemAttachmentVM } from '../../../attachments/view-models/CMSItemAttachmentVM'
import { OldAttachmentVM } from '../../../attachments/view-models/OldAttachmentVM'
import CMSThumbnail from '../../../thumbnail/views/CMSThumbnail'
import OldThumbnail from '../../../thumbnail/views/OldThumbnail'
import './Drawer.scss'

interface Props {
  localizationStore?: LocalizationStore
  userTasksStore?: UserTasksStore
  surveysStore?: SurveysStore
}

const DrawerTaskDetails: React.FC<Props> = ({ localizationStore, userTasksStore }) => {
  const { lzStrings } = localizationStore
  const { editVM } = userTasksStore

  const renderGetFeedbackButton = () => {
    if (!editVM.getFeedbackButtonShown) return null
    return (
      <Button
        disabled={editVM.generatingSurvey}
        className='go-button'
        variant='outlined'
        onClick={() => editVM.getFeedback()}
      >
        Get Feedback
      </Button>
    )
  }

  const renderOpenSurvey = () => {
    if (!editVM.openSurveyButtonVisible) return null
    return (
      <>
        <Button
          className='go-button'
          variant='outlined'
          disabled={!editVM.hasSurvey}
          onClick={(e) => editVM.openSurvey(e)}
        >
          Open Feedback Survey
        </Button>
        <span>{editVM.attachedSurvey.name}</span>
      </>
    )
  }

  const renderTemplateSelect = () => {
    if (!editVM.templateSelectShown) return null
    return (
      <>
        <Autocomplete
          className='feedback-autocomplete'
          options={editVM.availableSurveyTemplates}
          getOptionLabel={(e) => e.name}
          value={editVM.selectedSurveyTemplate}
          onChange={(e, val: Survey) => editVM.setSelectedSurveyTemplate(val && val.objectId)}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              placeholder='Select Template'
              variant='outlined'
              className='feedback-input'
            />
          )}
        />
        <Button
          className='go-button'
          disabled={!editVM.hasSelectedSurvey}
          onClick={() => editVM.goToNewSurveyFromTemplate()}
        >
          Go
        </Button>
      </>
    )
  }

  const renderFeedbackSection = () => {
    if (!editVM.feedbackSectionVisible) return null
    return (
      <Grid item xs={12}>
        <span className='task-detail-header'>
          {lzStrings.pendingUserTasksWidgetDrawer.feedback}
        </span>
        <span className='feedback-container'>
          {renderOpenSurvey()}
          {renderGetFeedbackButton()}
          {renderTemplateSelect()}
        </span>
      </Grid>
    )
  }

  const renderCreatorAvatar = () => {
    if (!editVM.assignedByUser) return null
    return (
      <Avatar
        user={editVM.assignedByUser}
        size={20}
        style={{ fontSize: 12, float: 'left', marginRight: '8px', cursor: 'inherit' }}
      />
    )
  }

  const renderAttachmentThumbnail = (item: AttachmentVM, index: number) => {
    if (item.isCMSItem)
      return (
        <CMSThumbnail
          key={`task-cms-attach-thumb-${index}`}
          vm={item as CMSItemAttachmentVM}
          index={index}
          showDelete={false}
        />
      )
    return (
      <OldThumbnail
        key={`task-old-attach-thumb-${index}`}
        vm={item as OldAttachmentVM}
        index={index}
        showDelete={false}
      />
    )
  }

  const renderAttachments = () => {
    if (editVM.attachments.length === 0) return null
    return (
      <>
        <span className='task-detail-header'>
          {lzStrings.pendingUserTasksWidgetDrawer.attachments}
        </span>
        <Grid container className='attachments-list'>
          {editVM.attachments.map((doc, index) => {
            return renderAttachmentThumbnail(doc, index)
          })}
        </Grid>
      </>
    )
  }

  const renderTaskDiscipline = () => {
    if (!editVM.allowDiscipline) return null
    return (
      <Grid item xs={6}>
        <span className='task-detail-header'>
          {lzStrings.pendingUserTasksWidgetDrawer.discipline}
        </span>
        <span className='task-detail-field'>{editVM.categoryName}</span>
      </Grid>
    )
  }

  const renderTaskPartnerChannel = () => {
    if (!editVM.allowPartnerChannel) return null
    return (
      <Grid item xs={6}>
        <span className='task-detail-header'>
          {lzStrings.pendingUserTasksWidgetDrawer.partner_channel}
        </span>
        <span className='task-detail-field'>{editVM.clientName}</span>
      </Grid>
    )
  }

  const componentDecorator = (href, text, key) => (
    <a href={href} key={key} target='_blank'>
      {text}
    </a>
  )

  return (
    <section>
      <h6>{lzStrings.pendingUserTasksWidgetDrawer.details}</h6>
      <Grid container spacing={2} className='task-details-container'>
        <Grid item xs={6}>
          <span className='task-detail-header'>
            {lzStrings.pendingUserTasksWidgetDrawer.task_name}
          </span>
          <span className='task-detail-field'>{editVM.taskName}</span>
        </Grid>
        {renderTaskDiscipline()}
        <Grid item xs={6}>
          <span className='task-detail-header'>
            {lzStrings.pendingUserTasksWidgetDrawer.due_date}
          </span>
          <span className='task-detail-field'>
            {moment
              .unix(editVM.task.dueDate)
              .format(lzStrings.pendingUserTasksWidgetDrawer.due_date_format)}
          </span>
        </Grid>
        {renderTaskPartnerChannel()}
        <Grid item xs={12}>
          <span className='task-detail-header'>
            {lzStrings.pendingUserTasksWidgetDrawer.description}
          </span>
          <span className='task-detail-field'>
            <Linkify componentDecorator={componentDecorator}>
              {editVM.task.description ? editVM.task.description : '(No description provided)'}
            </Linkify>
          </span>
        </Grid>
        <Grid item xs={12}>
          <span className='task-detail-header'>
            {lzStrings.pendingUserTasksWidgetDrawer.creator}
          </span>
          <span className='creator-container'>
            {renderCreatorAvatar()}
            {editVM.creatorName}
          </span>
        </Grid>

        {renderFeedbackSection()}
        <Grid item xs={12}>
          {renderAttachments()}
        </Grid>
      </Grid>
    </section>
  )
}

export default inject('localizationStore', 'userTasksStore')(observer(DrawerTaskDetails))
