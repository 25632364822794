import { computed, observable, action } from 'mobx'
import { IPulseQuestionDTO } from '../dtos/IPulseQuestionDTO'
import { RootStore } from '../../stores/RootStore'

export class VimeoPreviewVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, pulseQuestion: IPulseQuestionDTO) {
    this.rootStore = rootStore
    this.pulseQuestion = pulseQuestion
  }

  @observable public pulseQuestion: IPulseQuestionDTO = null

  @action
  public closeVimeoPreviewVM() {
    return this.rootStore.pulseQuestionsStore.closeVimeoPreviewVM()
  }

  @computed
  public get vimeoId() {
    let regExp = /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/
    if (this.pulseQuestion.vimeoURL.includes('player.vimeo.com')) {
      regExp = /(http|https)?:\/\/(www\.)?player.vimeo.com\/(?:video\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/
    }
    const match = this.pulseQuestion.vimeoURL.match(regExp)

    return match && match.length ? match[4] : ''
  }

  @computed
  public get embedUrl() {
    return `https://player.vimeo.com/video/${this.vimeoId}`
  }
}
