import { computed, action } from 'mobx'
import { GroupsAndRolesWidgetVM } from 'src/app/groups-roles-widget/view-models/GroupsAndRolesWidgetVM'
import { Group } from 'src/app/groups/aggregate/Group'
import { Role } from 'src/app/roles/aggregate/Role'
import { AGGridRowVM } from '../../shared/ag-grid/AGGridRowVM'
import { RootStore } from '../../stores/RootStore'
import { WidgetRowType } from '../types/WidgetRowType'

export class GroupsAndRolesWidgetRowVM extends AGGridRowVM<Group | Role> {
  public type: WidgetRowType
  listVM: GroupsAndRolesWidgetVM

  constructor(
    rootStore: RootStore,
    aggregate: Group | Role,
    listVM: GroupsAndRolesWidgetVM = null,
    type: WidgetRowType
  ) {
    super(rootStore, aggregate, listVM)
    this.listVM = listVM
    this.type = type
  }

  @computed
  public get objectId(): string {
    return this.aggregate.objectId
  }

  @computed
  public get name(): string {
    return this.aggregate.name
  }

  @computed
  public get description(): string {
    return this.aggregate.description
  }

  @computed
  public get memberCount(): number {
    return this.aggregate.memberCount
  }

  @action
  public openWeightProfileModal() {
    this.listVM.openWeightProfileModal(this.objectId)
  }

  @action
  public openEditDrawer() {
    if (this.type === 'group') {
      this.rootStore.groupsStore.setGroupToEdit(this.objectId)
    }
    if (this.type === 'role') {
      this.rootStore.rolesStore.setRoleToEdit(this.objectId)
    }
  }
}
