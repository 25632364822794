import React from 'react'
import { inject, observer } from 'mobx-react'
import { WeightProfilesStore } from '../../store/WeightProfilesStore'
import Dropzone from 'react-dropzone'
import CloseIcon from '@material-ui/icons/Close'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  DialogActions,
  Button,
  CircularProgress,
} from '@material-ui/core'
import './WeightProfileImportModal.scss'

interface Props {
  weightProfilesStore?: WeightProfilesStore
}

const ImportModal: React.FC<Props> = ({ weightProfilesStore }) => {
  const { editVM } = weightProfilesStore
  if (!editVM) return null
  if (!editVM.showImportDialog) return null
  const importWeightProfileVM = editVM.importWeightProfileVM

  return (
    <div id='WeightProfileImportModal'>
      <Dialog onClose={() => editVM.toggleImportDialog()} open={editVM.showImportDialog} fullWidth>
        <Grid
          className='dialog-title'
          container
          spacing={3}
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item>
            <DialogTitle>File Upload</DialogTitle>
          </Grid>
          <Grid item>
            <IconButton onClick={() => editVM.toggleImportDialog()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        {!importWeightProfileVM.importProcessing ? (
          <Grid container className='dialog-content'>
            <DialogContent>
              {!importWeightProfileVM.file ? (
                <Dropzone
                  onDrop={file => importWeightProfileVM.handleFileDrop(file)}
                  accept='.csv,text/csv'
                  multiple={false}
                  minSize={0}
                  maxSize={5242880}
                >
                  {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                    <section>
                      <div className='dropzone' {...getRootProps()}>
                        <input {...getInputProps()} />

                        {!isDragActive && (
                          <div className='iconContainer'>
                            <NoteAddIcon className='noteAddIcon' />
                            <p className='dropzoneText'>
                              Drag and drop or <span className='dropLink'>browse</span> your file
                            </p>
                          </div>
                        )}

                        {isDragActive && !isDragReject && (
                          <div className='iconContainer'>
                            <ThumbUpIcon className='thumbUpIcon' />
                            <p className='dropzoneText'>This file looks good to go!</p>
                          </div>
                        )}

                        {isDragActive && isDragReject && (
                          <div className='iconContainer'>
                            <ThumbDownIcon className='thumbDownIcon' />
                            <p className='dropzoneText'>Sorry, this file type isn't accepted</p>
                          </div>
                        )}
                      </div>
                    </section>
                  )}
                </Dropzone>
              ) : (
                  <Dropzone
                    onDrop={file => importWeightProfileVM.handleFileDrop(file)}
                    accept='.csv,text/csv'
                    multiple={false}
                    minSize={0}
                    maxSize={5242880}
                  >
                    {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                      <section>
                        <div className='dropzone' {...getRootProps()}>
                          <input {...getInputProps()} />

                          {!isDragActive && (
                            <div className='iconContainer'>
                              <ThumbUpIcon className='thumbUpIcon' />
                              <p className='dropzoneText'>{importWeightProfileVM.file.name}</p>
                              <p className='dropzoneText'>This file looks good to go!</p>
                            </div>
                          )}

                          {isDragActive && !isDragReject && (
                            <div className='iconContainer'>
                              <ThumbUpIcon className='thumbUpIcon' />
                              <p className='dropzoneText'>This file looks good to go!</p>
                            </div>
                          )}

                          {isDragActive && isDragReject && (
                            <div className='iconContainer'>
                              <ThumbDownIcon className='thumbDownIcon' />
                              <p className='dropzoneText'>Sorry, this file type isn't accepted</p>
                            </div>
                          )}
                        </div>
                      </section>
                    )}
                  </Dropzone>
                )}
              <DialogContentText className='dialogText'>
                {' '}
                Acceptable formats: csv{' '}
              </DialogContentText>
            </DialogContent>
          </Grid>
        ) : (
            <Grid container className='dialog-content'>
              <DialogContent>
                <div className='dropzone'>
                  <div className='iconContainer'>
                    <CircularProgress className='progressCircle' />
                    <p className='dropzoneText'>Processing Weight Profile Upload</p>
                  </div>
                </div>
                <DialogContentText className='dialogText'>
                  {' '}
                  Acceptable formats: csv{' '}
                </DialogContentText>
              </DialogContent>
            </Grid>
          )}
        <DialogActions>
          <Grid container className='dialog-actions' alignItems='center' justifyContent='space-between'>
            <Grid item>
              <Button
                variant='outlined'
                size='large'
                onClick={() => importWeightProfileVM.handleTemplateDownload()}
                className='downloadButton'
              >
                Download Template
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant='text'
                size='large'
                onClick={() => editVM.toggleImportDialog()}
                className='cancelButton'
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                size='large'
                onClick={() => importWeightProfileVM.save()}
                disabled={!importWeightProfileVM.saveEnabled || importWeightProfileVM.importProcessing}
                className='saveButton'
              >
                Upload
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default inject('weightProfilesStore')(observer(ImportModal))
