import Parse from 'parse'
import { IDashboardDTO } from '../dtos/IDashboardDTO'

export class DashboardsUpdateService {

  public async updateDashboard(dashboard: IDashboardDTO) {
    const savedDash = await Parse.Cloud.run('updateDashboard', { dashboard })
    return savedDash
  }

  public async deleteDashboard(objectId: string) {
    await Parse.Cloud.run('deleteDashboard', { objectId })
  }
}
