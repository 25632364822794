import React from 'react'
import { IconButton, Snackbar } from '@material-ui/core'
import { SurveyTemplatesTableVM } from '../../view-models/SurveyTemplatesTableVM'
import Close from '@material-ui/icons/Close'
import './ActionSnackbar.scss'
import { inject, observer } from 'mobx-react'

interface Props {
  vm: SurveyTemplatesTableVM
}

const ActionSnackbar: React.FC<Props> = ({ vm }) => {
  if (!vm) return
  return (
    <Snackbar
      id='ActionSnackbar'
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      className='snackbar-success'
      open={vm.shouldShowSnackbar}
      autoHideDuration={6000}
      onClose={() => vm.closeSnackbar()}
      message={vm.snackbarMessage}
      action={[
        <IconButton
          key={'close'}
          aria-label='Close'
          color='inherit'
          onClick={() => vm.closeSnackbar()}
        >
          <Close />
        </IconButton>,
      ]}
    />
  )
}

export default inject()(observer(ActionSnackbar))
