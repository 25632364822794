import { action, computed, observable } from 'mobx'
import moment from 'moment'
import { AudienceMember } from '../../../audience-members/aggregate/AudienceMember'
import { FKUser } from '../../../organization-users/aggregate/FKUser'
import { AGGridRowVM } from '../../../shared/ag-grid/AGGridRowVM'
import { RootStore } from '../../../stores/RootStore'
import { UserSurvey } from '../../aggregate/UserSurvey'
import { UserSurveysService } from '../../service/UserSurveysService'
import { AlwaysAvailableSurveysWidgetVM } from './AlwaysAvailableSurveysWidgetVM'

export class AlwaysAvailableUserSurveyRowVM extends AGGridRowVM<UserSurvey> {
  private parentVM: AlwaysAvailableSurveysWidgetVM

  constructor(rootStore: RootStore, userSurvey: UserSurvey, gridVM: AlwaysAvailableSurveysWidgetVM) {
    super(rootStore, userSurvey, gridVM)
    this.parentVM = gridVM
  }

  @computed
  public get objectId(): string {
    return this.aggregate.objectId
  }

  @computed
  public get name(): string {
    return this.aggregate.name
  }

  @computed
  public get surveyTypeName(): string {
    if (!this.aggregate.fk_surveyTypeId) return ''
    return this.aggregate.fk_surveyTypeId.name
  }

  @computed
  public get fromUser(): FKUser {
    return this.aggregate.fk_publishedByUserId
  }

  @computed
  public get fromUserId(): string {
    if (!this.fromUser) return ''
    return this.fromUser.objectId
  }

  @computed
  public get fromUserName(): string {
    if (!this.fromUser) return '--'
    return this.fromUser.name
  }

  @computed
  public get fromUserIconURL(): string {
    if (!this.fromUser) return undefined
    return this.fromUser.iconURL
  }

  @computed
  public get questionsCount(): number {
    if (!this.aggregate.questionsCount) return 0
    return this.aggregate.questionsCount
  }

  @computed
  public get categories(): string {
    if (!this.aggregate.categoryIds.length) return '--'
    let categories = []
    this.aggregate.categoryIds.forEach((id) => {
      const foundCategory = this.rootStore.categoriesStore.getCategory(id)
      if (foundCategory) categories.push(foundCategory.name)
    })
    return categories.join(', ')
  }

  @computed
  public get publishedDateFormated() {
    const s = this.rootStore.localizationStore.lzStrings.manageSurveysWidget
    const formattedDate = moment(this.aggregate.publishedDateTime).format(s.published_date_format)
    return formattedDate
  }

  @action
  public openUserSurvey() {
    this.rootStore.appStore.router.push('/usersurveys/alwaysAvailable/' + this.objectId)
  }

}
