import React, { FC } from 'react'
import { observer, inject } from 'mobx-react'
import './SurveyOptions.scss'
import { SurveysStore } from '../../../store/SurveysStore'
import { FormControlLabel, Card, Radio } from '@material-ui/core'
import clsx from 'clsx'
import LocalizationStore from '../../../../localization/LocalizationStore'

interface Props {
  surveysStore?: SurveysStore
  localizationStore?: LocalizationStore
}

const QuestionFlow: FC<Props> = ({ surveysStore, localizationStore }) => {
  const surveyEditVM = surveysStore.viewModels.editVM
  const vm = surveyEditVM.surveyOptionsVM
  if (!surveyEditVM) return null
  if (!vm) return null
  const s = localizationStore.lzStrings

  return (
    <div className='section question-flow'>
      <div className='primary-text'>Question Flow</div>
      <div className='secondary-text'>
        Scrolling: show all questions at once, with the ability to scroll through. Paged: show one
        question at a time, with 'Back' and 'Next' buttons.
      </div>
      <div className='section-content'>
        <Card
          className={clsx('question-flow-card', {
            active: surveyEditVM.questionsFlowFormat === 'scrolling',
          })}
          onClick={() => surveyEditVM.setQuestionsFlowFormat('scrolling')}
        >
          <div className='card-header'>
            <FormControlLabel
              control={
                <Radio
                  disabled={surveyEditVM.isReadOnly}
                  className='radio'
                  value='scrolling'
                  checked={surveyEditVM.questionsFlowFormat === 'scrolling'}
                />
              }
              label='Scrolling'
            />
          </div>
          <div className='image-container'>
            <img
              src='../../../assets/img/survey-flow-options/Scrolling-Skeleton.svg'
              alt=''
              width='90%'
            />
          </div>
        </Card>
        <Card
          className={clsx('question-flow-card', {
            active: surveyEditVM.questionsFlowFormat === 'paged',
          })}
          onClick={() => surveyEditVM.setQuestionsFlowFormat('paged')}
        >
          <div className='card-header'>
            <FormControlLabel
              control={
                <Radio
                  disabled={surveyEditVM.isReadOnly}
                  className='radio'
                  value='paged'
                  checked={surveyEditVM.questionsFlowFormat === 'paged'}
                />
              }
              label='Paged'
            />
          </div>
          <div className='image-container'>
            <img
              src='../../../assets/img/survey-flow-options/Paging-Skeleton.svg'
              alt=''
              width='90%'
            />
          </div>
        </Card>
      </div>
    </div>
  )
}

export default inject('surveysStore', 'localizationStore')(observer(QuestionFlow))
