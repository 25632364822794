import { Checkbox, FormControlLabel, Grid, Switch, Typography } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import React from 'react'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { TrainingPlansStore } from '../../../../training-plans/store/TrainingPlansStore'
import './CompletionTabPanel.scss'
import InfoTooltip from '../../../../shared/InfoTooltip'

interface Props {
  trainingPlansStore?: TrainingPlansStore
  localizationStore?: LocalizationStore
}

const CompletionToolbar: React.FC<Props> = ({ trainingPlansStore, localizationStore }) => {
  const { trainingPlanEditVM: vm } = trainingPlansStore
  const { training_plans: strings } = localizationStore.lzStrings
  if (!vm) return null

  return (
    <div id='CompletionToolbar'>
      <Grid container>
        <Grid item xs={12} className='flex-align'>
          <Typography variant='h5' className='input-label'>
            {strings.require_completion}
            <InfoTooltip message={strings.completion_message} />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Switch
            disabled={vm.editIsDisabled}
            checked={vm.requireProofOfCompletion}
            onChange={() => vm.toggleRequireCompletion()}
          />
          <FormControlLabel
            control={
              <Checkbox
                disabled={!vm.requireProofOfCompletion || vm.editIsDisabled}
                checked={vm.requireQuiz}
                onChange={() => vm.toggleRequireQuiz()}
                color='default'
                className={vm.proofOfCompletionValid ? '' : 'check-error'}
              />
            }
            label={strings.quiz}
          />
          <FormControlLabel
            control={
              <Checkbox
                disabled={!vm.requireProofOfCompletion || vm.editIsDisabled}
                checked={vm.requireCertificate}
                onChange={() => vm.toggleRequireCertificate()}
                color='default'
                className={vm.proofOfCompletionValid ? '' : 'check-error'}
              />
            }
            label={strings.certificate}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default inject('trainingPlansStore', 'localizationStore')(observer(CompletionToolbar))
