import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Modal,
  Paper,
  Typography,
} from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import React from 'react'
import ParticipantsSelect from '../../../../participants-select/views/ParticipantsSelect'
import { TrainingPlansStore } from '../../../../training-plans/store/TrainingPlansStore'
import CloseIcon from '@material-ui/icons/Close'
import './SelectModals.scss'
import LocalizationStore from '../../../../localization/LocalizationStore'

interface Props {
  trainingPlansStore?: TrainingPlansStore
  localizationStore?: LocalizationStore
}

const TrainerSelectModal: React.FC<Props> = ({ trainingPlansStore, localizationStore }) => {
  const { trainingPlanEditVM: vm } = trainingPlansStore
  const lz = localizationStore.lzStrings.training_plans
  return (
    <Dialog
      onClose={() => {
        vm.toggleTrainerSelectOpen()
      }}
      open={vm.trainerSelectVMOpen}
      id='TrainerSelectModal'
    >
      <DialogTitle>
        <Grid className='modal-title' container justifyContent='space-between' alignItems='center'>
          <Grid item>{lz.select_trainer}</Grid>
          <Grid item>
            <IconButton onClick={() => vm.toggleTrainerSelectOpen()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className='trainer-content'>
        <ParticipantsSelect participantsSelectVM={vm.trainerSelectVM} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => vm.toggleTrainerSelectOpen()}>{lz.cancel}</Button>
        <Button variant='contained' onClick={() => vm.setTrainer()}>
          {lz.apply}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default inject('trainingPlansStore', 'localizationStore')(observer(TrainerSelectModal))
