import React from 'react'
import { inject, observer } from 'mobx-react'
import { PrivilegeSetsStore } from '../store/PrivilegeSetsStore'
import LocalizationStore from '../../localization/LocalizationStore'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import './PrivilegeSetsWidget.scss'
import AddIcon from '@material-ui/icons/Add'
import PrivilegeSetDrawer from './PrivilegeSetDrawer'
import { AgGridReact } from 'ag-grid-react/lib/agGridReact'
import AGGridWidgetPagingRow from '../../shared/ag-grid/AGGridPagingRow'

interface Props {
  localizationStore?: LocalizationStore
  privilegeSetsStore?: PrivilegeSetsStore
}

const PrivilegeSetsWidget: React.FC<Props> = ({ localizationStore, privilegeSetsStore }) => {
  const vm = privilegeSetsStore.privilegeSetsWidgetVM
  const s = localizationStore.lzStrings
  if (!vm) return null
  if (!vm.shouldRender) return null

  const renderGrid = () => {
    return (
      <div className='ag-theme-alpine' style={{ height: '100%', width: '100%' }}>
        <AgGridReact
          gridOptions={{
            ...vm.getGridOptions(),
          }}
        />
      </div>
    )
  }

  return (
    <div id='PrivilegeSetsWidget'>
      <PrivilegeSetDrawer />
      <Card elevation={0}>
        <CardHeader
          className={'cardHeader'}
          action={
            <React.Fragment>
              <Tooltip title={s.privilegeSetsWidget.add_new_privilege_set} placement='bottom'>
                <IconButton
                  className='widget-cta'
                  size='small'
                  onClick={() => vm.addPrivilegeSet()}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </React.Fragment>
          }
          title={s.privilegeSetsWidget.manage_privilege_sets}
        />
      </Card>
      <CardContent className='cardContent'>
        {renderGrid()}
        <AGGridWidgetPagingRow vm={vm} />
      </CardContent>
    </div>
  )
}

export default inject('privilegeSetsStore', 'localizationStore')(observer(PrivilegeSetsWidget))
