import React from 'react'
import { inject, observer } from 'mobx-react'
import { Card, CircularProgress, Fade, Popover } from '@material-ui/core'
import './EventPopup.scss'
import { EventsStore } from '../../../store/EventsStore'
import EventPopupToolbar from './EventPopupToolbar'
import EventPopupBody from './EventPopupBody'
import MasterEventPrompt from './MasterEventPrompt'
import EventSendNotificationsPrompt from '../../EventSendNotificationsPrompt'

interface IEventPopup {
  eventsStore?: EventsStore
}

const EventPopup: React.FC<IEventPopup> = ({ eventsStore }) => {
  const { eventPopupVM: vm } = eventsStore
  if (!vm) return null
  if (!vm.anchorEl) return null

  const renderSpinner = () => {
    if (vm.event) return
    return <CircularProgress color='secondary' size={25} />
  }

  const renderBody = () => {
    if (!vm.event) return
    return (
      <>
        <EventPopupToolbar />
        <EventPopupBody />
      </>
    )
  }

  return (
    <>
      <Popover
        open={true}
        anchorEl={vm.anchorEl}
        onClose={() => vm.close()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className='calendarEventModal'
      >
        <Card elevation={5} className='eventPopupCard'>
          {renderSpinner()}
          {renderBody()}
        </Card>
        <MasterEventPrompt />
        <EventSendNotificationsPrompt vm={vm.eventSendNotificationsVM} />
      </Popover>
    </>
  )
}

export default inject('eventsStore')(observer(EventPopup))
