import { Button, Checkbox, Radio, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'
import React from 'react'
import { OptionTakeVM } from '../../view-models/OptionTakeVM'
import { QuizTakeVM } from '../../view-models/QuizTakeVM'
import './OptionRow.scss'

interface Props {
  vm: QuizTakeVM
  option: OptionTakeVM
}

const OptionRow: React.FC<Props> = ({ vm, option }) => {
  const renderEmojiImage = () => {
    if (option.type !== 'emoji') return
    return <img src={'../../../assets/emoticons/png/' + option.emoji + '.png'} alt='' />
  }

  const renderCheckbox = () => {
    if (option.type !== 'check') return
    return <Checkbox disabled={vm.isComplete} checked={option.isChecked} />
  }

  const renderRadioButton = () => {
    if (option.type === 'check') return null
    if (option.type === 'emoji') return null
    if (option.type === 'rating') return null
    if (option.type === 'starRating') return null
    if (option.type === 'text') return null
    return <Radio disabled={vm.isComplete} checked={option.isChecked} />
  }

  const renderOptionHighlightClassName = () => {
    if (option.isChecked && vm.isComplete && option.isCorrectAnswer) return 'correct-answer'
    if (option.isChecked && vm.isComplete && !option.isCorrectAnswer) return 'incorrect-answer'
    if (option.isChecked && !vm.isComplete) return 'active'
    return ''
  }

  const renderButton = () => {
    if (option.type === 'text') return
    return (
      <Button
        disabled={vm.isSubmitting || vm.isComplete}
        className={renderOptionHighlightClassName()}
        onClick={() => option.toggleChecked()}
        component='div'
        startIcon={
          <>
            {renderEmojiImage()}
            {renderCheckbox()}
            {renderRadioButton()}
          </>
        }
      >
        <Typography className={'option-text'}>{option.text}</Typography>
      </Button>
    )
  }

  return <div id='OptionRow'>{renderButton()}</div>
}
export default observer(OptionRow)
