import Parse from 'parse'
import { observable, computed, action } from 'mobx'
import rootStore, { RootStore } from '../../stores/RootStore'
import { IUserWorksheetDTO } from '../dto/IUserWorksheetDTO'
import { DataStore } from '../../shared/data/DataStore'
import { UserWorksheet } from '../aggregate/UserWorksheet'
import { ContentVM } from '../view-models/ContentVM'
import { ContentItemVM } from '../view-models/ContentItemVM'
import { IUserWorksheetListRequest } from '../interfaces/IUserWorksheetListRequest'
import { UserWorksheetsService } from '../service/UserWorksheetsService'

export class UserWorksheetsStore extends DataStore<UserWorksheet, IUserWorksheetDTO> {
  private request: IUserWorksheetListRequest
  public svc: UserWorksheetsService

  constructor(rootStore: RootStore) {
    super(rootStore, UserWorksheet, 'userWorksheets', [
      'objectId',
      'organizationId',
      'isBookmark',
      'sortOrder',
      'worksheetId',
      'userId',
    ])
    this.paged = true
    this.recordsPerPage = 100
    this.pageNumber = 1
    this.sortColumnName = 'sortOrder'
    this.contentVM = new ContentVM(rootStore)
    this.svc = new UserWorksheetsService()
    this.listenToListRecordsViaApiUpdates = true
  }

  @observable public contentVM: ContentVM = null
  @observable public loaded: boolean = false

  @computed
  public get userWorksheets(): UserWorksheet[] {
    return this.records
  }

  protected getBaseQuery() {
    const query = new Parse.Query(this.className)
    query.equalTo('organizationId', rootStore.appStore.currentOrgId)
    query.equalTo('userId', rootStore.appStore.currentUserId)
    return query
  }

  public async updateUserWorksheet(obj: Parse.Object) {
    const serverObj = obj.toJSON()
    const userWorksheet = this.getUserWorksheet(obj.id)
    if (userWorksheet) userWorksheet.updateFromServer(serverObj)
  }

  protected getListRecordsApiCall() {
    return async () => {
      const req: IUserWorksheetListRequest = {
        ...this.request,
        userId: rootStore.appStore.currentUserId,
        organizationId: rootStore.appStore.currentOrgId,
        isBookmark: false,
        terms: this.filter,
        skip: this.recordsPerPage * (this.pageNumber - 1),
        limit: this.recordsPerPage,
        sortColumnName: this.sortColumnName,
        sortDirection: this.sortDirection,
        listColumns: this.listColumns,
        verifyVisibility: true,
      }
      const result = await this.svc.getUserWorksheetList(req)
      this.totalRecords = result.count
      return result.userWorksheets
    }
  }

  public onListRecordsLoaded() {
    this.loaded = true
  }

  public currentOrgWorksheets(organizationId: string): Array<UserWorksheet> {
    return this.userWorksheets.filter((e) => e.organizationId === organizationId)
  }

  public getUserWorksheet(worksheetId: string): UserWorksheet {
    return this.userWorksheets.find((e) => e.worksheetId === worksheetId)
  }

  public hasUserWorksheet(worksheetId: string): boolean {
    return this.userWorksheets.findIndex((e) => e.worksheetId === worksheetId) > -1
  }

  public deleteUserWorksheet(worksheetId: string) {
    const idx = this.userWorksheets.findIndex((e) => e.worksheetId === worksheetId)
    if (idx > -1) this.userWorksheets.splice(idx, 1)
  }

  @action
  public lazyLoadVM(id: string, attempts: number = 0) {
    if (attempts === 14) return
    if (!rootStore.userStore.currentOrganization && process.env.NODE_ENV !== 'test') {
      setTimeout(() => this.lazyLoadVM(id, ++attempts), 500)
      return
    }
    const foundItem = this.getUserWorksheet(id)
    if (!foundItem) {
      setTimeout(() => this.lazyLoadVM(id, attempts++), 500)
      return
    }
    const worksheet = rootStore.worksheetsStore.getWorksheet(foundItem.worksheetId)
    if (!worksheet) {
      setTimeout(() => this.lazyLoadVM(id, attempts++), 500)
      return
    }

    const existItemVM = this.contentVM.getContentItemById(foundItem.objectId)
    const contentItemVM = !existItemVM
      ? new ContentItemVM(rootStore, worksheet, foundItem)
      : existItemVM
    if (!existItemVM) contentItemVM.setWorksheet(worksheet)
    this.contentVM.updateContentItem(contentItemVM)
    contentItemVM.loadTableauAuthToken()
    this.contentVM.setSelectedItemVM(contentItemVM)
    setTimeout(() => this.contentVM.setOpen(true), 500)
  }
}
