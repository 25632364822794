import React from 'react'
import { CardHeader, IconButton } from '@material-ui/core'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'
import { inject, observer } from 'mobx-react'
import { EventsStore } from '../../../store/EventsStore'

interface Props {
  eventsStore?: EventsStore
}
const UserTrainingPlanPopupToolbar: React.FC<Props> = ({ eventsStore }) => {
  const { trainingPlanPopupVM: vm } = eventsStore

  return (
    <CardHeader
      action={
        <>
          <IconButton size='small' onClick={() => vm.close()}>
            <CloseOutlinedIcon />
          </IconButton>
        </>
      }
    />
  )
}

export default inject('eventsStore')(observer(UserTrainingPlanPopupToolbar))
