import React from 'react'
import { inject, observer, useLocalStore } from 'mobx-react'
import { AnnouncementAnalyticsVM } from '../../view-models/analytics/AnnouncementAnalyticsVM'
import rootStore from '../../../stores/RootStore'
import './AnnouncementAnalytics.scss'
import { Card, CircularProgress, Link, Typography } from '@material-ui/core'
import AnnouncementParticipantsList from './AnnouncementParticipantsList'
import AnnouncementParticipantsHeader from './AnnouncementParticipantsHeader'

interface Props {
  announcementId: string
}

const AnnouncementAnalytics: React.FC<Props> = ({ announcementId }) => {
  let localStore = useLocalStore(() => ({
    vm: new AnnouncementAnalyticsVM(rootStore, announcementId),
  }))

  let vm = localStore.vm
  if (announcementId !== vm.announcementId) {
    localStore.vm = new AnnouncementAnalyticsVM(rootStore, announcementId)
    vm = localStore.vm
  }
  if (!vm) return

  const renderSpinner = () => {
    if (!vm.isLoadingAnnouncement) return
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  const renderNoAnnouncement = () => {
    if (vm.isLoadingAnnouncement || vm.announcement) return
    return <div className='spinner-container'>No Announcement Found</div>
  }

  const renderBreadcrumbs = () => {
    return (
      <div className={'announcement-bread-crumbs'}>
        <Link onClick={() => vm.navigateBackToMainScreen()}>
          <Typography className='announcements-title spaced back'>{`Announcements`}</Typography>
        </Link>
        <Typography className='announcements-title spaced'>{' > '}</Typography>
        <Typography className='announcements-title spaced'>{'Stats'}</Typography>
      </div>
    )
  }

  const renderTable = () => {
    if (vm.isLoadingAnnouncement || !vm.announcement) return
    return (
      <Card elevation={0} id='AnnouncementParticipantsListTable'>
        <AnnouncementParticipantsHeader
          announcementAnalyticsVM={vm}
          announcementId={announcementId}
        />
        <AnnouncementParticipantsList tableVM={vm.tableVM} announcementId={announcementId} />
      </Card>
    )
  }

  return (
    <div id='AnnouncementAnalytics'>
      <div className='list-manage-container'>
        {renderSpinner()}
        {renderNoAnnouncement()}
        {renderBreadcrumbs()}
        {renderTable()}
      </div>
    </div>
  )
}

export default inject()(observer(AnnouncementAnalytics))
