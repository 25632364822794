import Parse from 'parse'
import { IEventsParticipantsSearchRequest } from '../intefaces/IEventsParticipantsSearchRequest'
import { IEventStatusReadResult } from '../intefaces/IEventStatusReadResult'

export class EventsService {
  public async deleteEvent(orgId, eventId) {
    await Parse.Cloud.run('deleteEvent', { orgId, eventId })
  }

  public async updateEvent(event, organizationId, skipNotifications) {
    await Parse.Cloud.run('updateEvent', { organizationId, event, skipNotifications })
  }

  public async markEventAsRead(userId, eventId, orgId) {
    await Parse.Cloud.run('markEventAsReadV2', { userId, eventId, orgId })
  }

  public async setGoingStatusYes(organizationId, eventId, userId) {
    await Parse.Cloud.run('setGoingStatusYes', { organizationId, eventId, userId })
  }

  public async markUserEventAsRead(userEventId, orgId) {
    await Parse.Cloud.run('markUserEventAsRead', { userEventId, orgId })
  }

  public async setGoingStatus(orgId, userId, eventId, status, startDate, once) {
    await Parse.Cloud.run('setGoingStatusV2', {
      orgId,
      userId,
      eventId,
      status,
      startDate,
      once,
    })
  }

  public async getGoingStatuses(
    orgId: string,
    eventId: string,
    date: string,
    isFakeEvent: boolean
  ): Promise<IEventStatusReadResult> {
    return await Parse.Cloud.run('getGoingStatuses', { eventId, orgId, date, isFakeEvent })
  }

  public async getUserIcsLink(userId, orgId) {
    const result = await Parse.Cloud.run('getUserIcsLink', { userId, orgId })
    return result
  }

  async saveResource(orgId, resource) {
    const result = await Parse.Cloud.run('saveResource', { orgId, resource })
    return result
  }

  async saveEventType(orgId, type) {
    const result = await Parse.Cloud.run('saveEventType', { orgId, type })
    return result
  }

  public async searchEventsParticipants(req: IEventsParticipantsSearchRequest) {
    const result = await Parse.Cloud.run('searchEventsParticipants', req)
    return result
  }
}
