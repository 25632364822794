import { action, computed, observable } from 'mobx'
import { IPulseQuestionDTO } from '../dtos/IPulseQuestionDTO'
import { RootStore } from '../../stores/RootStore'
import PulseQuestion from '../aggregate/PulseQuestion'
import { deserialize } from 'serializr'
import { ChildCategoryRowVM } from '../../pulse-categories/view-models/ChildCategoryRowVM'
import { PulseQuestionType } from '../types/PulseQuestionType'

export class PulseQuestionVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, parentVM: ChildCategoryRowVM, pulseQuestion: IPulseQuestionDTO) {
    this.rootStore = rootStore
    this.parentVM = parentVM
    this.pulseQuestion = pulseQuestion
  }

  @observable public pulseQuestion: IPulseQuestionDTO = null
  @observable public parentVM: ChildCategoryRowVM = null

  @computed
  public get questionTypeTitle(): string {
    if (this.questionType === 'yesNo') return 'Yes or No'
    if (this.questionType === 'rating') return 'Rating (Scale)'
    if (this.questionType === 'emoji') return 'Emoji'
    if (this.questionType === 'text') return 'Short Answer (Text)'
    if (this.questionType === 'radio') return 'Multiple Choice (Radio)'
    if (this.questionType === 'starRating') return 'Star Rating'
    if (this.questionType === 'dropdown') return 'Dropdown'
    return this.questionType
  }

  @computed 
  public get objectId(): string {
    return this.pulseQuestion.objectId
  }

  @computed
  public get childCategoryId(): string {
    return this.pulseQuestion.pulseCategoryId
  }

  @computed 
  public get title(): string {
    return this.pulseQuestion.title
  }

  @computed
  public get questionType(): PulseQuestionType {
    return this.pulseQuestion.type
  }

  @computed
  public get pulseSetNames(): string {
    return Object.values(this.pulseQuestion.pulseSets).join(', ')
  }

  @computed
  public get hasFollowUp(): boolean {
    return this.pulseQuestion.options.some((o) => o.hasFollowUp)
  }

  @action
  public editQuestion() {
    this.rootStore.appStore.router.push(`/systemAdmin/pulseQuestion/edit/${this.objectId}/${this.childCategoryId}`)
  }

}
