import React from 'react'
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Grid,
  IconButton,
  Typography,
  Button,
  CardContent,
} from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import LeftIcon from '@material-ui/icons/ChevronLeft'
import RightIcon from '@material-ui/icons/ChevronRight'
import './Content.scss'
import { SystemAdminsStore } from '../../store/SystemAdminsStore'
import { ISystemAdminUser } from '../../dto/ISystemAdminUser'
import MuiConfirmDeleteDialog from '../../../shared/MuiConfirmDeleteDialog'
import { SystemAdminRowVM } from '../../view-models/list-widget/SystemAdminRowVM'

interface Props {
  systemAdminsStore?: SystemAdminsStore
}

const Content: React.FC<Props> = ({ systemAdminsStore }) => {
  const { listWidgetVM: vm } = systemAdminsStore
  if (!vm) return null

  const pagedDataVM = vm.pagedData

  const renderSortIndicator = (key: string) => {
    if (pagedDataVM.sort === key) return <i className='fa fa-caret-down' />
    if (pagedDataVM.sort === '-' + key) return <i className='fa fa-caret-up' />
  }

  const renderPagingRow = () => {
    return (
      <Grid container direction='row' justifyContent='flex-end' alignItems='center' className='paging-row'>
        <Grid item>{pagedDataVM.recordsDisplayedInfo}</Grid>
        <Grid item>
          <IconButton onClick={() => pagedDataVM.goPrevPage()} disabled={pagedDataVM.isFirstPage}>
            <LeftIcon />
          </IconButton>
          <IconButton onClick={() => pagedDataVM.goNextPage()} disabled={pagedDataVM.isLastPage}>
            <RightIcon />
          </IconButton>
        </Grid>
      </Grid>
    )
  }

  const renderNoRowsContent = () => {
    if (vm.isLoading) return
    if (pagedDataVM.displayRows.length > 0) return
    return (
      <Typography component='div' className='no-rows'>
        No Records Found
      </Typography>
    )
  }

  const renderDeleteDialog = () => {
    if (!vm.deletingUser) return
    return (
      <MuiConfirmDeleteDialog
        onClose={() => vm.toggleDeleteDialog()}
        open={vm.deleteConfirmShown}
        itemType='System Admin'
        itemName={vm.deletingUser.name}
        onConfirm={() => vm.deleteSystemAdmin()}
      />
    )
  }

  return (
    <CardContent id='Content'>
      <Table stickyHeader size='small' id='WidgetTable'>
        <TableHead>
          <TableRow style={{ cursor: 'pointer' }}>
            <TableCell onClick={() => pagedDataVM.setSort('name')}>
              Name {renderSortIndicator('name')}
            </TableCell>
            <TableCell onClick={() => pagedDataVM.setSort('email')}>
              Email {renderSortIndicator('email')}
            </TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pagedDataVM.displayRows.map((row: SystemAdminRowVM) => {
            return (
              <TableRow hover key={row.objectId}>
                <TableCell align='left' style={{ width: '40%' }} className='name'>
                  {row.name}
                </TableCell>
                <TableCell align='left' className='name'>
                  {row.email}
                </TableCell>
                <TableCell align='left' className='delete-cell'>
                  <Button className='delete-button' onClick={() => row.deleteSystemAdmin()}>
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      {renderDeleteDialog()}
      {renderNoRowsContent()}
      {renderPagingRow()}
    </CardContent>
  )
}

export default inject('systemAdminsStore')(observer(Content))
