import { observable } from 'mobx'
import { serializable } from 'serializr'
import { IFKCreatedByUserDTO } from '../dtos/IFKCreatedByUserDTO'

export class FKCreatedByUser implements IFKCreatedByUserDTO {
  public static create(firstName, lastName, name, iconUrl): FKCreatedByUser {
    const pt = new FKCreatedByUser()
    pt.firstName = firstName
    pt.name = name
    pt.lastName = lastName
    pt.iconURL = iconUrl
    return pt
  }

  @serializable @observable public firstName: string = ''
  @serializable @observable public lastName: string = ''
  @serializable @observable public iconURL: string = null
  @serializable @observable public name: string = ''
}
