import { ColDef, GridOptions, RowClickedEvent } from 'ag-grid-community'
import { action, computed, reaction } from 'mobx'
import { AGGridVM } from '../../shared/ag-grid/AGGridVM'
import { nameOf } from '../../shared/nameOf'
import { RootStore } from '../../stores/RootStore'
import { SurveyResponse } from '../../survey-responses/aggregate/SurveyResponse'
import { AnalysisVM } from './AnalysisVM'
import { ResponseRowVM } from './ResponseRowVM'
import { ResponsesListDataStore } from './ResponsesListDataStore'

export class ResponsesAGGridVM extends AGGridVM {
  private rootStore: RootStore
  private parentVM: AnalysisVM
  public dataStore: ResponsesListDataStore
  private surveyId: string

  constructor(rootStore: RootStore, parentVM: AnalysisVM, surveyId: string) {
    super()
    this.rootStore = rootStore
    this.parentVM = parentVM
    this.surveyId = surveyId
    this.sizeColumnsToFit = false
    this.serverSideLoaded = true
    this.dataStore = new ResponsesListDataStore(this.rootStore, this, this.surveyId)
    this.loadReactions()
  }

  public getRowNodeId(row: ResponseRowVM): string {
    return row.objectId
  }

  public get shouldRender(): boolean {
    if (!this.dataStore.listRecordsLoaded) return false
    return true
  }

  public loadDataStore() {
    this.dataStore.onRecordUpdated = (e) => this.onRecordUpdated(e)
  }

  private loadReactions() {
    reaction(
      () =>
        this.rootStore.surveysStore.viewModels.editVM &&
        this.rootStore.surveysStore.viewModels.editVM.responseJustDeleted,
      (val) => {
        if (val) {
          this.reload()
          this.rootStore.surveysStore.viewModels.editVM.responseJustDeleted = false
        }
      }
    )
  }

  public onRecordUpdated(obj: SurveyResponse) {
    const rowNode = this.gridApi.getRowNode(obj.objectId)
    if (!rowNode) return
    rowNode.setData(new ResponseRowVM(this.rootStore, this.parentVM.editVM, this.parentVM, obj))
  }

  @computed
  public get getWidth() {
    return Math.max(document.documentElement.offsetWidth, document.documentElement.clientWidth)
  }

  @computed
  public get anonymizeResults(): boolean {
    return this.parentVM.editVM.anonymizeResults
  }

  @computed
  public get columnDefs(): ColDef[] {
    const s = this.rootStore.localizationStore.lzStrings
    if (this.anonymizeResults)
      return [
        {
          headerName: s.surveys.name,
          width: 190,
          tooltipField: nameOf<ResponseRowVM, string>((e) => e.userNameTooltip),
          field: nameOf<ResponseRowVM, string>((e) => e.userName),
          sortable: false,
          cellClass: (params) => {
            if (!params.data) return null
            if (params.data.isSelected) return 'is-selected'
            return ''
          },
        },
      ]
    return [
      {
        headerName: s.surveys.date,
        width: this.getWidth < 1200 ? 110 : 200,
        field: nameOf<ResponseRowVM, string>((e) => e.responseDateFormatted),
        sort: 'desc',
        cellClass: (params) => {
          if (!params.data) return null
          if (params.data.isSelected && params.data.isPaperSurvey)
            return 'is-selected is-paper-survey'
          if (params.data.isSelected) return 'is-selected'
          if (params.data.isPaperSurvey) return 'is-paper-survey'
          return ''
        },
      },
      {
        headerName: s.surveys.name,
        width: 190,
        tooltipField: nameOf<ResponseRowVM, string>((e) => e.userNameTooltip),
        field: nameOf<ResponseRowVM, string>((e) => e.userName),
        cellClass: (params) => {
          if (!params.data) return null
          if (params.data.isSelected) return 'is-selected'
          return ''
        },
      },
    ]
  }

  public getGridOptions(): GridOptions {
    return {
      ...this.baseGridOptions,
      onRowClicked: (e) => this.rowClicked(e),
      getRowNodeId: (e) => this.getRowNodeId(e),
      columnDefs: this.ensureTooltipFields(this.columnDefs),
      pagination: false,
    }
  }

  @action
  public setQuickFilter(val: string) {
    this.typedFilterText = val
    if (this.quickFilterTO) clearTimeout(this.quickFilterTO)
    this.quickFilterTO = setTimeout(() => this.applyFilter(), 1000)
  }

  private applyFilter() {
    this.dataStore.setFilter(this.typedFilterText)
    this.reload()
    this.columnApi.applyColumnState({
      state: [{ colId: 'responseDateFormatted', sort: 'desc' }],
    })
  }

  protected onGridReadied() {
    this.gridApi.setDatasource({
      rowCount: this.totalRecords,
      getRows: async (params) => {
        params.sortModel.forEach((col: { colId: string; sort: 'asc' | 'desc' }, idx: number) => {
          let dbCol = col.colId
          if (dbCol === 'userName') dbCol = 'fk_User.lastName'
          if (dbCol === 'responseDateFormatted') dbCol = 'respondedAt'
          if (idx === 0) this.dataStore.setSort(dbCol, col.sort)
          if (idx > 0) this.dataStore.addSort(dbCol, col.sort)
        })
        if (params.sortModel.length === 0)
          if (!this.anonymizeResults) {
            this.dataStore.setSort('respondedAt')
          } else {
            this.dataStore.setSort('responseId')
          }
        if (params.startRow === 0) this.dataStore.setPage(0)
        await this.dataStore.getNextPage()
        const rows = this.dataStore.surveyResponses.map(
          (e) => new ResponseRowVM(this.rootStore, this.parentVM.editVM, this.parentVM, e)
        )
        params.successCallback(rows, this.dataStore.totalRecords)
      },
    })
  }

  public get rows(): ResponseRowVM[] {
    return []
  }

  public rowClicked(e: RowClickedEvent) {
    if (!e) return
    if (!e.data) return
    const id = e.data.objectId
    this.parentVM.setSelectedResponse(id)
    this.gridApi.redrawRows()
  }

  @computed
  public get hasRows(): boolean {
    return this.dataStore.hasListRecords
  }

  @computed
  public get surveyResponses() {
    return this.dataStore.surveyResponses
  }

  @computed
  protected get totalRecords() {
    return this.dataStore.totalRecords
  }

  @computed
  public get anonymousSurveyResultCount() {
    if (!this.rootStore.organizationsStore.currentOrganization) return null
    if (!this.rootStore.organizationsStore.currentOrganization.anonymousSurveyResultCount) return 10
    return this.rootStore.organizationsStore.currentOrganization.anonymousSurveyResultCount
  }

  @computed
  public get hideResponses() {
    if (
      this.hasRows &&
      this.anonymizeResults &&
      this.surveyResponses.length < this.anonymousSurveyResultCount
    )
      return true
    return false
  }

  @computed
  public get gridClassName() {
    if (this.hasRows && !this.hideResponses) return 'ag-theme-alpine'
    return 'ag-theme-alpine ag-grid-no-rows'
  }
}
