import React from 'react'
import { Button, Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'
import { observer } from 'mobx-react'
import { QuizVM } from '../../view-models/QuizVM'
import './OptionsList.scss'

interface OptionMenuProps {
  quiz: QuizVM
  idx: number
}

const CorrectMenu: React.FC<OptionMenuProps> = ({ quiz, idx }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  if (!quiz.currentQuestion) return null

  const renderButtonIcon = () => {
    if (!quiz.currentQuestion.options[idx]) return
    if (quiz.currentQuestion.options[idx].isCorrectAnswer)
      return <CheckIcon className='correct-answer-icon' />
    if (!quiz.currentQuestion.options[idx].isCorrectAnswer)
      return <CloseIcon className='incorrect-answer-icon' />
  }

  return (
    <div>
      <Button
        className={
          quiz.currentQuestion.optionsError && quiz.currentQuestion.options.length > 0
            ? 'more-button answer-error'
            : 'more-button'
        }
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        {renderButtonIcon()}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        elevation={1}
      >
        <MenuItem
          onClick={() => {
            quiz.currentQuestion.setOptionIsCorrect(idx)
            setAnchorEl(null)
          }}
        >
          <ListItemIcon>
            <CheckIcon className='correct-answer-icon' />
          </ListItemIcon>
          <ListItemText primary='Correct' />
        </MenuItem>
        <MenuItem
          onClick={() => {
            quiz.currentQuestion.setOptionIsIncorrect(idx)
            setAnchorEl(null)
          }}
        >
          <ListItemIcon>
            <CloseIcon className='incorrect-answer-icon' />
          </ListItemIcon>
          <ListItemText primary='Incorrect' />
        </MenuItem>
      </Menu>
    </div>
  )
}

export default observer(CorrectMenu)
