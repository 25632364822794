import React, { useEffect } from 'react'
import { Observer } from 'mobx-react'
import LocalizationStore from '../../localization/LocalizationStore'
import { AuthStore } from '../store/AuthStore'
import rootStore from '../../stores/RootStore'
import MDSpinner from 'react-md-spinner'
import fullLogo from '../../../assets/img/logos/RippleWorx-White.png'
import './Login.scss'
import { Checkbox, FormControlLabel, InputLabel, Radio, RadioGroup, Tooltip } from '@material-ui/core'

interface ILogin {
  localizationStore?: LocalizationStore
  authStore?: AuthStore
  router: any
}

const Login: React.FC<ILogin> = ({ router }) => {
  const { lzStrings } = rootStore.localizationStore
  const { LoginVM: vm } = rootStore.authStore
  const { ForgotPasswordVM: passwordVM } = rootStore.authStore
  rootStore.appStore.setRouter(router)

  useEffect(() => {
    rootStore.authStore.loadLoginVM()
  })

  const renderErrorMessage = () => {
    if (!vm.errorMessage) return
    return <span className='error-message'>{vm.errorMessage}</span>
  }

  const renderOfflineMessage = () => {
    if (!vm.isOffline) return
    return <span className='error-message'>{lzStrings.login.offline_message}</span>
  }

  const renderIEMessage = () => {
    if (!vm.isIE) return
    return <span className='error-message'>{lzStrings.login.ie_message}</span>
  }

  const renderSpinner = () => {
    if (!vm.isProcessing && !vm.handlingOIDCLogin) return
    return (
      <div className='spinner-container'>
        <MDSpinner size={40} singleColor='#7c818e' />
      </div>
    )
  }

  const renderPassword = () => {
    if (!vm.passwordShown) return
    return (
      <input
        style={{ marginTop: '0px' }}
        type='password'
        name='password'
        placeholder={lzStrings.login.password_placeholder}
        value={vm.password}
        onChange={(e) => {
          vm.setPassword(e.target.value)
        }}
      />
    )
  }

  const renderNextButton = () => {
    if (vm.isProcessing) return
    if (!vm.nextButtonShown) return
    return (
      <div className='button-container'>
        <button className='sign-in-button' disabled={vm.loginButtonDisabled} type='submit'>
          {lzStrings.login.next}
        </button>
      </div>
    )
  }

  const renderSignInButton = () => {
    if (vm.handlingOIDCLogin) return
    if (vm.isProcessing) return
    if (!vm.signInButtonShown) return
    return (
      <div className='button-container'>
        <button
          className='sign-in-button'
          disabled={vm.loginButtonDisabled}
          type='submit'
          autoFocus
        >
          {lzStrings.login.signin_button}
        </button>
      </div>
    )
  }

  const renderRememberMe = () => {
    if (vm.isIE) return
    return (
      <FormControlLabel
        control={<Checkbox checked={vm.rememberMe} onChange={() => vm.toggleRememberMe()} />}
        label={lzStrings.login.remember_me}
      />
    )
  }

  const renderForgotPassword = () => {
    if (!vm.passwordShown) return
    return (
      <button
        className='forgot-password'
        onClick={(e) => {
          passwordVM.setForgotPasswordUsername(vm.username)
          vm.goToForgotPassword()
        }}
        type='button'
      >
        <Tooltip
          open={vm.errorMessage ? true : false}
          title={lzStrings.login.forgot_password_tooltip}
          placement='right'
          arrow
        >
          <span className='forgot-password-text'>{lzStrings.login.forgot_password}</span>
        </Tooltip>
      </button>
    )
  }

  const renderOrgOptions = () => {
    if (!vm.signInMethods.length) return
    if (vm.passwordShown) return
    return (
      <>
        <InputLabel>Choose Method</InputLabel>
        <RadioGroup
          key={'rgrp' + vm.selectedOrgOption.toString()}
          value={vm.selectedOrgOption}
          onChange={(e) => vm.setSelectedOrgOption(e.target.value as unknown as number)}
        >
          {vm.signInMethods.map((e, idx) => (
            <FormControlLabel
              key={'opt' + idx.toString() + vm.selectedOrgOption.toString()}
              value={idx}
              control={
                <Radio
                  checked={idx.toString() === vm.selectedOrgOption.toString()}
                  key={'optrd' + idx.toString() + vm.selectedOrgOption.toString()}
                />
              }
              label={e.name}
            />
          ))}
        </RadioGroup>
      </>
    )
  }

  const renderDomainCheckSpinner = () => {
    if (!vm.checkingSSOSettings) return
    return <MDSpinner size={50} singleColor='#ffffff' />
  }

  const renderSupport = () => {
    return (
      <div className={'support-view'}>
        {lzStrings.login.need_help}
        <a href='mailto:support@rippleworx.com'>
            support@rippleworx.com
          </a>
      </div>
    )
  }

  const renderForm = () => {
    if (vm.checkingSSOSettings) return
    return (
      <>
      <form onSubmit={(e) => vm.handleSubmit(e)}>
      <div className='card'>
        <h4>{lzStrings.login.header}</h4>
        {passwordVM.resetPasswordSuccess && (
          <span className='forgot-password'>{lzStrings.login.check_email}</span>
        )}
        <input
          type='text'
          name='username'
          placeholder={lzStrings.login.email_placeholder}
          value={vm.username}
          onChange={(e) => vm.setUsername(e.target.value)}
          disabled={vm.isIE}
          className='email-username-input'
        />
        {renderRememberMe()}
        {renderErrorMessage()}
        {renderOfflineMessage()}
        {renderIEMessage()}
        {renderNextButton()}
        {renderOrgOptions()}
        {renderPassword()}
        {renderForgotPassword()}
        {renderSignInButton()}
        {renderSpinner()}
        {renderSupport()}
      </div>
    </form>
    </>
    )
  }

  return (
    <Observer>
      {() => (
        <div className='login'>
          <div className='center-body'>
            <div>
              <img className='logo' alt='RippleWorx Logo' src={fullLogo} />
            </div>
            {renderDomainCheckSpinner()}
            {renderForm()}
          </div>
        </div>
      )}
    </Observer>
  )
}

export default Login
