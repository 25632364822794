import moment, { ISO_8601 } from 'moment'
import { IEventScheduleDTO } from '../dtos/IEventScheduleDTO'
import { IUserEventDTO } from '../user-events/dtos/IUserEventDTO'
import { RootStore } from 'src/app/stores/RootStore'
import { Event } from '../aggregate/Event'
import { UserEvent } from '../user-events/aggregate/UserEvent'

export class EventsScheduleService {
  private event: Event
  private userEvent: UserEvent | Event
  private schedule: IEventScheduleDTO
  private startDate: moment.Moment
  private endDate: moment.Moment
  private returnEvents: IUserEventDTO[]
  private fromDate: Date
  private thruDate: Date
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public getMissingSeriesUserEvents(
    userEvent: UserEvent | Event,
    fromDate: Date,
    thruDate: Date
  ): IUserEventDTO[] {
    if (userEvent.masterEventId) return []
    this.userEvent = userEvent
    this.event = this.rootStore.eventsStore.getEvent(this.userEvent.eventId)
    if (!this.event) this.rootStore.eventsStore.getEvent(this.userEvent.objectId)
    // if (!this.event) return []
    // if (!this.event.schedule.loaded) return []
    this.returnEvents = []
    this.schedule = this.userEvent.schedule
    this.fromDate = fromDate
    this.thruDate = thruDate
    this.loadStartDate()
    this.loadEndDate()
    // this.cleanScheduleData()
    // this.setUnseparatedEventsToUnknown()
    this.checkDailySchedule()
    this.checkWeeklySchedule()
    this.checkMonthlySchedule()
    this.checkYearlySchedule()
    // this.setRemainingEventsToDelete()
    return this.returnEvents
  }

  // private cleanScheduleData() {
  //   this.schedule = this.event.schedule
  //   this.schedule.eventId = this.event.objectId
  //   this.schedule.startDate = this.event.startDate
  //   if (!this.schedule.events) this.schedule.events = []
  //   if (!this.schedule.endingMode) {
  //     if (this.schedule.endDate) this.schedule.endingMode = 'specific date'
  //     else this.schedule.endingMode = 'never'
  //   }
  //   this.schedule.repeat = this.schedule.repeat.toLowerCase() as RepeatModeType
  //   this.schedule.endingMode = this.schedule.endingMode.toLowerCase() as EndingModeType
  //   this.schedule.events
  //     .filter((e) => e.status.toLowerCase() === 'processed')
  //     .forEach((e) => (e.status = 'active'))
  //   this.schedule.events
  //     .filter((e) => e.status.toLowerCase() === 'not processed')
  //     .forEach((e) => (e.status = 'active'))
  //   this.schedule.events
  //     .filter((e) => e.status.toLowerCase() === 'to update')
  //     .forEach((e) => (e.status = 'active'))
  // }

  private loadStartDate() {
    this.startDate = moment(this.userEvent.localStartDate)
  }

  private loadEndDate() {
    this.endDate = null
    if (this.schedule.endingMode.toLowerCase() === 'never' || !this.schedule.endingMode) {
      this.endDate = moment(this.thruDate, 'MM/DD/YYYY')
    } else {
      this.endDate = moment(this.schedule.endDate, moment.ISO_8601)
      if (this.endDate.isAfter(this.thruDate)) this.endDate = moment(this.thruDate)
    }
  }

  private checkDailySchedule() {
    if (this.schedule.repeat !== 'daily') return
    const daysBetween = this.endDate.diff(this.startDate, 'days')
    let iteratedDate = this.startDate.clone().add(1, 'day')
    let daysIterated = 0
    while (daysIterated <= daysBetween) {
      const existingEvent = this.getExistingOccurrence(iteratedDate)
      if (!existingEvent) {
        this.addChildEvent(iteratedDate, iteratedDate.isoWeekday())
      }
      //  else if (existingEvent.status !== 'separated') {
      //   existingEvent.effectiveDateTime = iteratedDate.clone().toISOString()
      //   existingEvent.status = 'active'
      // }
      iteratedDate.add(1, 'days')
      daysIterated++
    }
  }

  private checkWeeklySchedule() {
    if (this.schedule.repeat !== 'weekly') return
    const daysBetween: number = this.endDate.diff(this.startDate, 'days')
    let daysIterated: number = 0
    let iteratedDate: moment.Moment = this.startDate.clone().local().add(1, 'day')
    while (daysIterated <= daysBetween) {
      let dayInScope: boolean = false
      const days = this.schedule.days
      let dayIndex = -1
      const currentDayOfWeek = iteratedDate.format('dddd')
      if (currentDayOfWeek === 'Monday' && days.monday === true) {
        dayInScope = true
        dayIndex = 1
      }
      if (currentDayOfWeek === 'Tuesday' && days.tuesday === true) {
        dayInScope = true
        dayIndex = 2
      }
      if (currentDayOfWeek === 'Wednesday' && days.wednesday === true) {
        dayInScope = true
        dayIndex = 3
      }
      if (currentDayOfWeek === 'Thursday' && days.thursday === true) {
        dayInScope = true
        dayIndex = 4
      }
      if (currentDayOfWeek === 'Friday' && days.friday === true) {
        dayInScope = true
        dayIndex = 5
      }
      if (currentDayOfWeek === 'Saturday' && days.saturday === true) {
        dayInScope = true
        dayIndex = 6
      }
      if (currentDayOfWeek === 'Sunday' && days.sunday === true) {
        dayInScope = true
        dayIndex = 7
      }
      if (dayInScope) {
        const existingEvent = this.getExistingOccurrence(iteratedDate)
        if (!existingEvent) this.addChildEvent(iteratedDate, dayIndex)
        // else if (existingEvent.status !== 'separated') {
        //   existingEvent.effectiveDateTime = iteratedDate.clone().toISOString()
        //   existingEvent.status = 'active'
        // }
        // } else if (!dayInScope && existingEvent && existingEvent.status !== 'separated') {
        //   existingEvent.status = 'deleted'
      }
      iteratedDate.add(1, 'days')
      daysIterated++
    }
  }

  private checkMonthlySchedule() {
    if (this.schedule.repeat !== 'monthly') return
    const monthsBetween = this.endDate.diff(this.startDate, 'months')
    let monthsIterated = 0
    let iteratedDate = this.startDate.clone().add(1, 'month')
    while (monthsIterated < monthsBetween) {
      const existingEvent = this.getExistingOccurrence(iteratedDate)
      if (!existingEvent) {
        this.addChildEvent(iteratedDate, iteratedDate.isoWeekday())
      }
      // else if (existingEvent.status !== 'separated') {
      //   existingEvent.effectiveDateTime = iteratedDate.clone().toISOString()
      //   existingEvent.status = 'active'
      // }
      iteratedDate.add(1, 'month')
      monthsIterated++
    }
  }

  private checkYearlySchedule() {
    if (this.schedule.repeat !== 'yearly') return
    const yearsBetween = this.endDate.diff(this.startDate, 'years')
    let yearsIterated = 0
    let iteratedDate = this.startDate.clone().add(1, 'year')
    while (yearsIterated < yearsBetween) {
      const existingEvent = this.getExistingOccurrence(iteratedDate)
      if (!existingEvent) {
        this.addChildEvent(iteratedDate, iteratedDate.isoWeekday())
      }
      // else if (existingEvent.status !== 'separated') {
      //   existingEvent.effectiveDateTime = iteratedDate.clone().toISOString()
      //   existingEvent.status = 'active'
      // }
      iteratedDate.add(1, 'years')
      yearsIterated++
    }
  }

  private getLastGoingStatus(effectiveDateTime: moment.Moment) {
    const pastUserEventsForEvent = this.rootStore.userEventsStore.currentOrgUserEvents
      .filter((e) => {
        if (e.userId !== this.userEvent.userId) return false
        if (e.goingStatus === 'new') return false
        if (e.goingStatusOnce) return false
        if (moment(e.startDate).toDate() > effectiveDateTime.toDate()) return false
        if (e.eventId === this.userEvent.eventId) return true
        if (e.masterEventId === this.userEvent.eventId) return true
        return false
      })
      .sort((a, b) => {
        if (a.localStartDate < b.localStartDate) return 0
        return -1
      })
    let stat = 'new'
    if (pastUserEventsForEvent.length > 0) stat = pastUserEventsForEvent[0].goingStatus
    return stat
  }

  private addChildEvent(effectiveDateTime: moment.Moment, dayIndex: number) {
    const childEvent = JSON.parse(JSON.stringify(this.userEvent)) as IUserEventDTO
    childEvent.startDate = effectiveDateTime.toISOString()
    childEvent.readStatus = 'read'
    childEvent.goingStatus = this.getLastGoingStatus(effectiveDateTime)
    if (this.userEvent.allDay) {
      childEvent.startDate = effectiveDateTime.clone().startOf('day').toISOString()
      childEvent.endDate = effectiveDateTime.clone().endOf('day').toISOString()
      childEvent.dayIndex = dayIndex
    } else {
      const duration = moment(this.userEvent.endDate, moment.ISO_8601).diff(
        moment(this.userEvent.startDate, moment.ISO_8601),
        'minutes'
      )
      childEvent.endDate = effectiveDateTime.clone().add(duration, 'minutes').toISOString()
    }
    childEvent.masterEventId = this.getMasterEventId()
    childEvent.isFakeEvent = true
    this.returnEvents.push(childEvent)
  }

  private getMasterEventId() {
    if (!this.userEvent.userId) return this.userEvent.objectId
    return this.userEvent.eventId
  }

  private getExistingOccurrence(iteratedDate: moment.Moment) {
    let occurrence = this.getThisUserEventIfSameDay(iteratedDate)
    if (!occurrence) occurrence = this.getExistingOccurrenceFromSchedule(iteratedDate)
    if (!occurrence) occurrence = this.getExistingOccurrenceFromSeparatedUserEvents(iteratedDate)
    if (!occurrence) occurrence = this.getExistingOccurrenceFromUserEvents(iteratedDate)
    return occurrence
  }

  private getThisUserEventIfSameDay(iteratedDate: moment.Moment): UserEvent | Event {
    const eventDateTime = moment(this.userEvent.localStartDate)
    if (!iteratedDate.isSame(eventDateTime, 'day')) return
    return this.userEvent
  }

  private getExistingOccurrenceFromUserEvents(iteratedDate: moment.Moment): UserEvent | Event {
    if (!this.userEvent.userId) return this.getExistingOccurrenceFromEvents(iteratedDate)
    return this.rootStore.eventsStore.calendarVM.selectedMonthUserEvents.find((e) => {
      if (e.userId !== this.userEvent.userId) return false
      if (e.masterEventId !== this.userEvent.eventId) return false
      const eventDateTime = moment(e.localStartDate)
      return iteratedDate.isSame(eventDateTime, 'day')
    })
  }

  private getExistingOccurrenceFromEvents(iteratedDate: moment.Moment): Event {
    return this.rootStore.eventsStore.calendarVM.selectedMonthEvents.find((e) => {
      if (e.masterEventId !== this.userEvent.objectId) return false
      const eventDateTime = moment(e.localStartDate)
      return iteratedDate.isSame(eventDateTime, 'day')
    })
  }

  private getExistingOccurrenceFromSeparatedUserEvents(iteratedDate: moment.Moment): UserEvent | Event {
    if (!this.userEvent.userId) return this.getExistingOccurrenceFromSeparatedEvents(iteratedDate)
    return this.rootStore.eventsStore.calendarVM.selectedMonthUserEvents.find((e) => {
      if (e.userId !== this.userEvent.userId) return false
      if (e.separateFromMasterEventId !== this.userEvent.eventId) return false
      if (!e.occurrenceStartDate) return false
      const eventDateTime = moment(e.occurrenceStartDate, ISO_8601)
      return iteratedDate.isSame(eventDateTime, 'day')
    })
  }

  private getExistingOccurrenceFromSeparatedEvents(iteratedDate: moment.Moment): Event {
    return this.rootStore.eventsStore.calendarVM.selectedMonthUserEvents.find((e) => {
      if (e.separateFromMasterEventId !== this.userEvent.objectId) return false
      if (!e.occurrenceStartDate) return false
      const eventDateTime = moment(e.occurrenceStartDate, ISO_8601)
      return iteratedDate.isSame(eventDateTime, 'day')
    })
  }

  private getExistingOccurrenceFromSchedule(iteratedDate: moment.Moment): UserEvent {
    let eventSchedule = this.rootStore.eventSchedulesStore.getEventSchedule(this.userEvent.eventId)
    if (!eventSchedule) eventSchedule = this.rootStore.eventSchedulesStore.getEventSchedule(this.userEvent.objectId)
    if (!eventSchedule) return
    if (!eventSchedule.events) return
    const scheduledEvent = eventSchedule.events.find((e) => {
      if (e.effectiveDateTime === iteratedDate.clone().utc().toISOString()) return true
      return iteratedDate.isSame(moment(e.effectiveDateTime, ISO_8601), 'day')
    })
    if (!scheduledEvent) return
    return this.rootStore.userEventsStore.currentOrgUserEvents.find(
      (e) => e.occurrenceId === scheduledEvent.occurrenceId
    )
  }

  // private setRemainingEventsToDelete() {
  //   this.schedule.events
  //     .filter((e) => e.status === 'unknown')
  //     .forEach((e) => (e.status = 'deleted'))
  // }

  // private setUnseparatedEventsToUnknown() {
  //   this.schedule.events
  //     .filter((e) => e.status !== 'separated')
  //     .forEach((e) => (e.status = 'unknown'))
  // }
}
