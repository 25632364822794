import { observable } from 'mobx'
import { serializable, deserialize, serialize, object, list, primitive } from 'serializr'
import { IUserFederationSettings } from '../../federatedImports/interfaces/IUserFederationSettings'
import { IFederatedGroupDTO } from '../../federatedImports/dtos/IFederatedGroupDTO'
import { FederatedGroup } from './FederatedGroup'

export class UserFederationSettings implements IUserFederationSettings {
  public static create() {
    const settings = new UserFederationSettings()

    return settings
  }

  @serializable @observable public enabled: boolean = false
  @serializable @observable public adapter: 'AzureGraphFederationAdapter' =
    'AzureGraphFederationAdapter'
  @serializable @observable public tenantId?: string = undefined
  @serializable @observable public clientId?: string = undefined
  @serializable @observable public clientSecret?: string = undefined
  @serializable(list(object(FederatedGroup))) @observable public groupNames?: IFederatedGroupDTO[] =
    []
  @serializable(list(primitive())) @observable public groupByFields?: string[] = []
  @serializable @observable public preferEmailOverUPN: boolean = false
  @serializable @observable public customAuthDomain?: string = undefined

  public serialize(): IUserFederationSettings {
    return serialize(this)
  }
}
