import React, { useState } from 'react'
import { inject, observer, useLocalStore } from 'mobx-react'
import rootStore from '../../../stores/RootStore'
import './AnnouncementList.scss'
import { Card } from '@material-ui/core'
import { AnnouncementListVM } from '../../view-models/list/AnnouncementListVM'
import AnnouncementListTable from './AnnouncementListTable'
import AnnouncementListHeader from './AnnouncementListHeader'

interface Props {
  userId?: string
}

const AnnouncementList: React.FC<Props> = ({ userId }) => {
  let localStore = useLocalStore(() => ({
    vm: new AnnouncementListVM(rootStore, userId),
  }))

  let vm = localStore.vm
  if (userId !== vm.userId) {
    localStore.vm = new AnnouncementListVM(rootStore, userId)
    vm = localStore.vm
  }
  if (!vm) return
  if (userId) {
    vm.toggleForLoggedInUser()
  }

  const renderTable = () => {
    return (
      <Card elevation={0} id='AnnouncementListTable'>
        <AnnouncementListHeader announcementListVM={vm} />
        <AnnouncementListTable tableVM={vm.tableVM} />
      </Card>
    )
  }

  return (
    <div id='AnnouncementList'>
      <div className='list-manage-container'>{renderTable()}</div>
    </div>
  )
}

export default inject()(observer(AnnouncementList))
