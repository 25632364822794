import { RootStore } from '../../../../stores/RootStore'
import { observable, computed, action } from 'mobx'
import { TrainingPlanRowVM } from './TrainingPlanRowVM'
import { UserTrainingPlan } from '../../../../training-plans/user-training-plans/aggregate/UserTrainingPlan'
import User from '../../../../users/aggregate/User'
import { AudienceMember } from 'src/app/audience-members/aggregate/AudienceMember'

export class UserTrainingPlanRowVM {
  private rootStore: RootStore
  private trainingPlanVM: TrainingPlanRowVM
  public userTrainingPlan: UserTrainingPlan

  constructor(
    rootStore: RootStore,
    trainingPlanVM: TrainingPlanRowVM,
    userTrainingPlan: UserTrainingPlan
  ) {
    this.rootStore = rootStore
    this.userTrainingPlan = userTrainingPlan
    this.trainingPlanVM = trainingPlanVM
  }

  @computed
  public get title(): string {
    return this.userTrainingPlan.title
  }

  @computed
  public get user(): AudienceMember {
    return this.rootStore.audienceMembersStore.getUser(this.userTrainingPlan.userId)
  }

  @computed
  public get userOwned(): boolean {
    return this.userTrainingPlan.userId === this.userTrainingPlan.assignedByUserId
  }

  @computed
  public get progress(): number {
    return this.userTrainingPlan.completion
  }

  @computed
  public get mailToLink() {
    let foundUser = this.rootStore.audienceMembersStore.getUser(
      this.userTrainingPlan.assignedByUserId
    )
    if (foundUser) {
      let href = 'mailto:' + foundUser.email
      return href
    }
  }
}
