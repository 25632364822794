import { action, observable } from 'mobx'
import { IAggregate } from 'src/app/shared/data/IAggregate'
import { IPulseCampaignTypeDTO } from '../dtos/IPulseCampaignTypeDTO'
import { serializable, serialize, deserialize } from 'serializr'

export default class PulseCampaignType implements IPulseCampaignTypeDTO, IAggregate {
  public static create() {
    const pulseCampaignType = new PulseCampaignType()
    return pulseCampaignType
  }

  @serializable @observable public objectId: string = ''
  @serializable @observable public name: string = ''
  @serializable @observable public isActive: boolean = true
  @serializable @observable public isDeleted: boolean = false
  public isOnServer: boolean = false

  @action
  public markIsNotOnServer(): void {
    this.isOnServer = false
  }

  @action
  public markIsOnServer(): void {
    this.isOnServer = true
  }

  @action
  public setName(name: string) {
    this.name = name
  }

  @action
  public toggleIsActive() {
    this.isActive = !this.isActive
  }

  public clone(): PulseCampaignType {
    return deserialize(PulseCampaignType, this.serialize())
  }

  public serialize(): IPulseCampaignTypeDTO {
    return serialize(this)
  }
}
