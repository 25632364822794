import { RootStore } from '../../stores/RootStore'
import { action, observable, computed, reaction, values } from 'mobx'
import { Survey } from '../aggregate/Survey'
import { SurveyEditVM } from './SurveyEditVM'
import { CustomPublishGroup } from '../aggregate/CustomPublishGroup'
import { CustomPublishGroupVM } from './CustomPublishGroupVM'
import { IPublishGroup } from '../dtos/IPublishGroup'
import { DateUtils } from '../../shared/data/DateUtils'

export class CustomPublishGroupsVM {
  private rootStore: RootStore
  private surveyEditVM: SurveyEditVM
  private surveyId: string

  constructor(rootStore: RootStore, survey: Survey, surveyEditVM: SurveyEditVM) {
    this.rootStore = rootStore
    this.surveyEditVM = surveyEditVM
    this.surveyRef = survey
    this.surveyId = survey.objectId

    this.loadVMData()

    reaction(
      () => this.surveyEditVM.participantsSelectVM.deleteParticipantId,
      () => {
        const deleteParticipantId = this.surveyEditVM.participantsSelectVM.deleteParticipantId
        if (deleteParticipantId === '') return
        this.customPublishGroupListVM.forEach((grp) => {
          const pExist = grp.participants.find((p) => p.id === deleteParticipantId)
          if (pExist) {
            grp.customPublishGroupSelectVM.deleteParticipant(deleteParticipantId)
            grp.deleteParticipantById(deleteParticipantId)
            this.updateCustomPublishGroup(grp)
          }
        })
        this.surveyEditVM.participantsSelectVM.setDeleteParticipantId('')
      }
    )
  }

  @observable public surveyRef: Survey = null
  @observable public customPublishGroupDialogOpen: boolean = false
  @observable public showConfirmDialog: boolean = false
  @observable public editing: CustomPublishGroupVM = null
  @observable public customPublishGroupListVM: Array<CustomPublishGroupVM> = []
  @observable public customPublishGroupNew: boolean = false

  private async loadVMData() {
    if (this.rootStore.audienceMembersStore) await this.rootStore.audienceMembersStore.loadAudienceMembers()
    setTimeout(() => {
      this.loadPublishData()
    }, 500)
  }

  @computed
  public get isCustomPublishGroupDialogOpen(): boolean {
    return this.customPublishGroupDialogOpen
  }

  @computed
  public get isCustomPublishGroupNew(): boolean {
    return this.customPublishGroupNew
  }

  @computed
  public get customPublishGroups() {
    return this.customPublishGroupListVM
  }

  @action
  public toggleCustomPublishGroupDialogOpen() {
    this.customPublishGroupDialogOpen = !this.customPublishGroupDialogOpen
  }

  @action
  public setCustomPublishGroupNew(val: boolean) {
    this.customPublishGroupNew = val
  }

  @action
  public setSurveyRef(surveyRef: Survey) {
    this.surveyRef = surveyRef
  }

  @action
  public loadPublishData() {
    this.customPublishGroupListVM = this.publishGroups
  }

  @computed
  public get publishGroups(): Array<CustomPublishGroupVM> {
    return this.surveyRef.schedule.publishGroups
      .map((grp) => new CustomPublishGroupVM(this.rootStore, this.surveyEditVM, grp))
      .slice()
  }

  @action
  public toggleShowConfirmDialog() {
    this.showConfirmDialog = !this.showConfirmDialog
  }

  @action
  public setEditing(val: CustomPublishGroupVM) {
    this.editing = val
  }

  @action
  public resetEditing(grp: IPublishGroup) {
    this.editing = new CustomPublishGroupVM(this.rootStore, this.surveyEditVM, grp)
  }

  @action
  public createNewGroup() {
    return new CustomPublishGroupVM(this.rootStore, this.surveyEditVM, new CustomPublishGroup())
  }
  
  @action
  public setAllCustomPublishGroups(val: Array<CustomPublishGroupVM>) {
    this.customPublishGroupListVM = val.map(
      (e) => new CustomPublishGroupVM(this.rootStore, this.surveyEditVM, e.customPublishGroup)
    )
  }

  @action
  public addCustomPublishGroup(val: CustomPublishGroupVM) {
    this.customPublishGroupListVM.unshift(val)
    val.customPublishGroup.deliveryTime = DateUtils.getISOStringFromBrowserInput(
      val.deliveryTime.toISOString(),
      val.startDate.toISOString(),
      val.deliveryTimeZoneVal
    )
    val.customPublishGroup.deliveryTimeZone = val.deliveryTimeZoneVal
    this.surveyEditVM.survey.schedule.addPublishGroup(val.customPublishGroup)
    this.addParticipantToSelectVM(val)
  }

  @action
  public updateCustomPublishGroup(val: CustomPublishGroupVM) {
    const oldCustomPublishGroup = this.surveyEditVM.survey.schedule.publishGroups.find((grp) =>
    grp.id === val.id
    )

    this.customPublishGroupListVM = this.customPublishGroupListVM.map((grp) =>
      grp.id === val.id ? val : grp
    )

    const oldIds = oldCustomPublishGroup.participants.map(p => p.id)
    const newIds = val.participants.map(p => p.id)

    const removed = this.difference(oldIds, newIds)
    if (removed.length > 0) removed.forEach(r => this.removeParticipantFromSelectVM(val.id, r))

    val.customPublishGroup.deliveryTime = DateUtils.getISOStringFromBrowserInput(
      val.deliveryTime.toISOString(),
      val.startDate.toISOString(),
      val.deliveryTimeZoneVal
    )
    val.customPublishGroup.deliveryTimeZone = val.deliveryTimeZoneVal
    this.surveyEditVM.survey.schedule.updatePublishGroup(val.customPublishGroup)
    this.addParticipantToSelectVM(val)
  }

  private difference(a1, a2) {
    let a2Set = new Set(a2)
    return a1.filter((x) => { return !a2Set.has(x) })
  }

  private removeParticipantFromSelectVM(grpId: string, valId: string) {
    let isRemove = true
    this.customPublishGroupListVM
      .filter((grp) => grpId !== grp.id)
      .forEach((grp) =>
        grp.participants.forEach((p) => {
          if (p.id === valId) {
            isRemove = false
          }
        })
      )

    if (isRemove) this.surveyEditVM.participantsSelectVM.deleteParticipant(valId)
  }

  private addParticipantToSelectVM(val: CustomPublishGroupVM) {
    val.customPublishGroup.participants.forEach((pCand) => {
      const pExist = this.surveyEditVM.participantsSelectVM.participants.find(
        (p) => p.id === pCand.id
      )
      if (!pExist) {
        this.surveyEditVM.participantsSelectVM.addParticipant(pCand.id, pCand.name, pCand.type, pCand.isArchived)
      }
    })
  }

  @action
  public deleteCustomPublishGroup(grp: IPublishGroup) {
    if (grp) grp.participants.forEach(p => this.removeParticipantFromSelectVM(grp.id, p.id))
    this.customPublishGroupListVM = this.customPublishGroupListVM.filter((e) => e.id !== grp.id)
    this.surveyEditVM.survey.schedule.deletePublishGroupById(grp.id)
  }

  @action
  public deleteCustomPublishGroupById(id: string) {
    const grp = this.customPublishGroupListVM.find((grp) => grp.id === id)
    if (grp) grp.participants.forEach(p => this.removeParticipantFromSelectVM(id, p.id))
    this.customPublishGroupListVM = this.customPublishGroupListVM.filter((e) => e.id !== id)
    this.surveyEditVM.survey.schedule.deletePublishGroupById(id)
  }

  @action
  public clearAllCustomPublishGroups() {
    this.customPublishGroupListVM = []
    this.surveyEditVM.survey.schedule.setAllPublishGroups([])
  }
}
