import { RootStore } from '../../stores/RootStore'
import env from '../../../env'
import axios from 'axios'
import { Attachment } from '../../attachments/aggregate/Attachment'
import Parse from 'parse'
import { CMSAuthService } from '../../cms-auth/services/CMSAuthService'

export class FileOpenService {
  private rootStore: RootStore
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public async openFile(attachment: Attachment) {
    if (attachment.type !== 'image' && attachment.type !== 'video')
      return await this.openFileViaUrl(attachment.url)
    window.open(attachment.url)
  }

  private async openFileViaUrl(fileUrl: string) {
    const regExp = /^(?:https?:\/\/)?(?:[^\.]+\.)?cms.*\.com(\/.*)?$/
    const match = fileUrl.match(regExp)
    if (match && match.length) {
      const tokenRegExp = /(\&|\?)jwt=[^&\n]*/
      const matchToken = fileUrl.match(tokenRegExp)
      if (matchToken && matchToken.length) {
        window.open(fileUrl)
        return
      }

      window.open(`${fileUrl}`)
      return
    }

    if (fileUrl.includes('/parse/files')) {
      window.open(fileUrl)
      return
    }

    if (fileUrl.includes('vimeo')) return window.open(fileUrl)
    if (fileUrl.includes('youtube') || fileUrl.includes('youtu.be')) return window.open(fileUrl)

    const token = await this.getFileDownloadToken(fileUrl)
    if (!token) return
    const url =
      env.var.REACT_APP_API_URL +
      '/files/fromurl?fileUrl=' +
      encodeURIComponent(fileUrl) +
      '&token=' +
      token
    const fileExtension = fileUrl.substring(fileUrl.lastIndexOf('.'))
    if (fileExtension === '.pdf') {
      window.open(url, '_blank', 'location=yes,height=600,width=550,scrollbars=yes,status=yes')
      return
    }
    window.open(url)
  }

  private async getFileDownloadToken(fileName: string): Promise<string> {
    const request = { organizationId: this.rootStore.appStore.currentOrgId, fileName }
    const response = await Parse.Cloud.run('generateFileDownloadToken', {
      request,
    })
    if (!response.success) {
      alert(response.errorMessage)
      return undefined
    }
    return response.fileDownloadToken
  }

  private async openFileViaPost(fileUrl: string) {
    const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1)
    const url = env.var.REACT_APP_API_URL + '/files/' + fileName
    const token = this.rootStore.userStore.parseUserInfo.sessionToken
    const postData = { sessionToken: token }
    const response = await axios.post(url, postData)
    const attachmentData = response.data ? response.data : null
    if (!attachmentData) return
    const blob = new Blob([attachmentData], { type: 'application/pdf' })
    const attachmentUrl = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = attachmentUrl
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}
