import { observable } from 'mobx'
import { AudienceMemberType } from '../../audience-members/types/AudienceMemberType'

export class Role {
  @observable id: string = ''
  @observable name: string = ''
  @observable type: AudienceMemberType = 'role'
  @observable privilegeSetIds: Array<string> = []

  static create(fromObj) {
    const role = new Role()
    role.id = fromObj.id ? fromObj.id : fromObj.objectId
    role.name = fromObj.name
    role.type = 'role'
    role.privilegeSetIds = fromObj.privilegeSetIds ? fromObj.privilegeSetIds : []
    return role
  }
}
