import React from 'react'
import { observer, inject } from 'mobx-react'
import { CardHeader } from '@material-ui/core'
import LocalizationStore from '../../../localization/LocalizationStore'
import './OrganizationUsersImportHeader.scss'

interface Props {
  localizationStore?: LocalizationStore
}

const OrganizationUsersImportHeader: React.FC<Props> = ({ localizationStore }) => {
  const lz = localizationStore.lzStrings.usersImport

  return <CardHeader id='TableHeader' title='User Importer'></CardHeader>
}

export default inject(
  'organizationUsersStore',
  'localizationStore'
)(observer(OrganizationUsersImportHeader))
