import React from 'react'
import { inject, observer, useLocalStore } from 'mobx-react'
import { PulseQuestionOptionVM } from '../../view-models/PulseQuestionOptionVM'
import { Button, FormControlLabel, IconButton, MenuItem, Select, Switch, TextField } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import { Icon } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import './PulseQuestionOptionRow.scss'
import EmojiMenu from './EmojiMenu'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import { SortableHandle } from 'react-sortable-hoc'
import PulseOptionFollowUp from './PulseOptionFollowUp'

interface Props {
  vm: PulseQuestionOptionVM
}

const DragHandle = SortableHandle(() => (
  <Button className='drag-handle'>
    <DragIndicatorIcon />
  </Button>
))

const PulseQuestionOptionRow: React.FC<Props> = ({ vm }) => {
  if (!vm) return null

  const renderResponseTypeSelect = () => {
    if (vm.questionType === 'text') return null
    return (
      <Select
        className={'response-type-select'}
        value={vm.responseType}
        onChange={(e) => vm.setResponseType(e.target.value)}
        variant='outlined'
        disabled={!vm.isSystemAdmin}
      >
        {vm.responseTypeOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {renderResponseTypeIcon(option.value)}
            {renderResponseTypeLabel(option.value)}
          </MenuItem>
        ))}
      </Select>
    )
  }

  const renderResponseTypeIcon = (responseType: string) => {
    if (responseType === 'very positive') return <><AddIcon className='pulse-positive' /><AddIcon className='pulse-positive' /></>
    if (responseType === 'positive') return <AddIcon className='pulse-positive' />
    if (responseType === 'neutral') return <Icon className='pulse-neutral'>--</Icon>
    if (responseType === 'negative') return <RemoveIcon className='pulse-negative' />
    if (responseType === 'very negative') return <><RemoveIcon className='pulse-negative' /><RemoveIcon className='pulse-negative' /></>

  }

  const renderResponseTypeLabel = (responseType: string) => {
    if (responseType === 'very positive') return 'Excellent'
    if (responseType === 'positive') return 'Good'
    if (responseType === 'neutral') return 'Average'
    if (responseType === 'negative') return 'Below Average'
    if (responseType === 'very negative') return 'Poor'
  }

  const renderEmojiMenu = () => {
    if (vm.questionType === 'text') return null
    if (vm.questionType !== 'emoji') return null
    return <EmojiMenu vm={vm} />
  }

  const renderOptionName = () => {
    if (vm.questionType === 'text') return null
    return (
      <TextField
        autoFocus
        className='option-name'
        error={!vm.textValid}
        id='outlined-name'
        value={vm.text}
        onChange={(e) => {
          vm.setText(e.target.value)
        }}
        fullWidth
        margin='none'
        variant='outlined'
      />
    )
  }

  const renderDeleteOption = () => {
    if (vm.questionType === 'text') return null
    if (!vm.isSystemAdmin) return null
    return (
      <IconButton
        disableRipple
        onClick={() => vm.deleteOption()}
        className={'icon-container'}
        size='small'
      >
        <DeleteIcon className={'delete-icon'} />
      </IconButton>
    )
  }

  const renderFollowUpOption = () => {
    if (vm.isFollowUp) return null
    return (
      <div className={'follow-up-switch'}>
        <Switch
          className={'prompt-switch'}
          checked={vm.hasFollowUp}
          onChange={() => vm.toggleHasFollowUpQuestion()}
        />
        <span>Follow Up?</span>
      </div>
    )
  }

  const renderDragHandle = () => {
    if (vm.questionType === 'text') return null
    if (!vm.isSystemAdmin) return null
    return (
      <DragHandle />
    )
  }

  return (
    <>
      <div id={'PulseQuestionOptionRow'}>
        {renderDragHandle()}
        {renderResponseTypeSelect()}
        {renderEmojiMenu()}
        {renderOptionName()}
        {renderFollowUpOption()}
        {renderDeleteOption()}
      </div>
      <PulseOptionFollowUp optionVM={vm} />
    </>
  )
}

export default inject()(observer(PulseQuestionOptionRow))
