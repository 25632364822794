const fileSizer = (fileSize: number): string => {
    let sizeNum: number
    let size: string
    if (fileSize < 0) return ''
    else if (fileSize > 1000 && fileSize < 1000000) {
        sizeNum = Math.round(fileSize / 1000)
        size = sizeNum + ' KB'
    } 
    else if (fileSize > 1000000 && fileSize < 1000000000) {
        sizeNum = Math.round(fileSize / 1000000)
        size = sizeNum + ' MB'
    } 
    else if (fileSize > 1000000000) {
        sizeNum = Math.round(fileSize / 1000000000)
        size = sizeNum + ' GB'
    } 
    return size
}

export default fileSizer