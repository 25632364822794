import React from 'react'
import { inject, observer } from 'mobx-react'
import { Button, CircularProgress } from '@material-ui/core'
import { PulseSetsManageVM } from '../../view-models/manage/PulseSetsManageVM'
import { PulseSetRowVM } from '../../view-models/manage/PulseSetRowVM'
import PulseSetRow from './PulseSetRow'
import './PulseSetsList.scss'
import EmptyState from '../../../shared/empty-state/EmptyState'
import emptyResults from '../../../../assets/img/empty-state/empty-state-results.png'
import LocalizationStore from '../../../localization/LocalizationStore'

interface Props {
  vm: PulseSetsManageVM
  localizationStore?: LocalizationStore
}

const PulseSetsList: React.FC<Props> = ({ vm, localizationStore }) => {
  if (!vm) return null
  const lz = localizationStore.lzStrings.pulseSetMGMT

  const renderRows = () => {
    if (!vm.isLoaded) return null
    if (vm.pulseSets.length <= 0) return null
    const rows = []
    vm.pulseSets.forEach((row: PulseSetRowVM, i: number) => {
      rows.push(
        <PulseSetRow key={i} vm={row} />
      )
    })
    return rows
  }

  const renderEmptyState = () => {
    if (!vm.isLoaded) return null
    if (vm.pulseSets.length > 0) return null
    return (
      <EmptyState
        image={emptyResults}
        title={lz.no_pulse_sets}
        subtitle1={lz.create_now}
        subtitle2={''}
        action={
          <Button
            onClick={() => vm.createNewPulseSet()}
            className={'empty-state-action-btn'}
            variant={'contained'}
          >
            {lz.create_pulse_set}
          </Button>
        }
      />
    )
  }

  const renderSpinner = () => {
    if (vm.isLoaded) return null
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  return (
    <div id={'PulseSetsList'}>
      {renderSpinner()}
      {renderEmptyState()}
      {renderRows()}
    </div>
  )
}

export default inject('localizationStore')(observer(PulseSetsList))
