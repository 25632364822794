import { observable } from 'mobx'
import { serializable, serialize, deserialize } from 'serializr'
import { IAggregate } from '../../shared/data/IAggregate'
import { ICMSItemDTO } from '../interfaces/ICMSItemDTO'

export class CMSItem implements ICMSItemDTO, IAggregate {
  public static create(item: ICMSItemDTO) {
    const cmsItem = new CMSItem()

    cmsItem.objectId = item.id.toString()
    cmsItem.id = item.id

    cmsItem.Title = item.Title
    cmsItem.Type = item.Type
    cmsItem.Description = item.Description

    cmsItem.createdAt = item.createdAt
    cmsItem.updatedAt = item.updatedAt
    cmsItem.createdBy = item.createdBy
    cmsItem.updatedBy = item.updatedBy
    cmsItem.Is_Private = item.Is_Private
    cmsItem.Private_To = item.Private_To

    return cmsItem
  }

  @serializable @observable public objectId: string

  @serializable @observable public id: number
  @serializable @observable public organizationId: string

  @serializable @observable public Title: string
  @serializable @observable public Description: string
  @serializable @observable public Type: string
  @serializable @observable public Is_Private: boolean
  @serializable @observable public Private_To: string

  @serializable @observable public createdAt: string
  @serializable @observable public updatedAt: string
  @serializable @observable public createdBy?: number
  @serializable @observable public updatedBy?: string

  isDeleted: boolean
  isOnServer: boolean
  markIsOnServer(): void {}
  markIsNotOnServer(): void {}

  public serialize() {
    const obj = serialize(this)
    return obj
  }

  public clone(): CMSItem {
    return deserialize(CMSItem, this.serialize())
  }
}
