import React from 'react'
import WidgetHeader from './WidgetHeader'
import Drawer from './Drawer'
import { Card, CardContent } from '@material-ui/core'
import { inject, observer, useLocalStore } from 'mobx-react'
import { RootStore } from '../../stores/RootStore'
import { QuestionCatalogWidgetVM } from '../view-models/QuestionCatalogWidgetVM'
import { AgGridReact } from 'ag-grid-react'
import AGGridWidgetPagingRow from '../../shared/ag-grid/AGGridPagingRow'
import SpinnerOverlay from '../../shared/SpinnerOverlay'
import './QuestionCatalogWidget.scss'

interface Props {
  rootStore?: RootStore
}

const QuestionCatalogWidget: React.FC<Props> = ({ rootStore }) => {
  const localStore = useLocalStore(() => ({
    vm: new QuestionCatalogWidgetVM(rootStore),
  }))
  const vm = localStore.vm

  if (!vm) return null

  const renderGrid = () => {
    if (!vm.shouldRender) return
    return (
      <div className='ag-theme-alpine' style={{ height: '100%', width: '100%' }}>
        <AgGridReact
          gridOptions={{
            ...vm.getGridOptions(),
          }}
        />
      </div>
    )
  }

  const renderSpinner = () => {
    if (vm.shouldRender) return
    return <SpinnerOverlay />
  }

  const renderPagingRow = () => {
    if (!vm.shouldRender) return
    return <AGGridWidgetPagingRow vm={vm} />
  }

  return (
    <>
      <Card id='QuestionCatalogWidget' elevation={0}>
        <WidgetHeader vm={vm} />
        <CardContent className='no-padding'>
          {renderGrid()}
          {renderSpinner()}
          {renderPagingRow()}
        </CardContent>
      </Card>
      <Drawer />
    </>
  )
}

export default inject('rootStore')(observer(QuestionCatalogWidget))
