import React from 'react'
import { inject, observer } from 'mobx-react'
import Dropzone from 'react-dropzone'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  LinearProgress,
  CircularProgress,
} from '@material-ui/core'
import VirtualList from 'react-tiny-virtual-list'
import { UserImportVM } from '../../view-models/importV2/UserImportVM'
import UserImportRow from './UserImportRow'
import ErrorRow from './ErrorRow'
import './OrganizationUsersImportContent.scss'
import LocalizationStore from '../../../localization/LocalizationStore'
import MuiSelectPrompt from './MuiSelectPrompt'
import MuiConfirmPrompt from '../../../shared/MuiConfirmPrompt'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'

interface Props {
  vm: UserImportVM
  localizationStore?: LocalizationStore
}

const OrganizationUsersImportContent: React.FC<Props> = ({ vm, localizationStore }) => {
  if (!vm) return
  const lz = localizationStore.lzStrings.usersImport

  const renderConfirmReset = () => {
    if (!vm.confirmResetDialogOpen) return
    return (
      <MuiConfirmPrompt
        confirmMessage='Are you sure you want to reset the importer?'
        confirmMessageSecondary='All of your progress will be lost.'
        open={vm.confirmResetDialogOpen}
        onConfirm={() => vm.resetImport()}
        title={'User Import Tracking'}
        onClose={() => {
          vm.confirmResetDialogOpen = false
        }}
        icon={<WarningRoundedIcon className='warning-icon' />}
      />
    )
  }

  const renderSheetSelectDialog = () => {
    if (!vm.sheetSelectedDialogOpen) return
    return (
      <MuiSelectPrompt
        confirmMessage='Please select Which sheet youd like to import'
        onClose={() => vm.toggleSheetSelectionDialog()}
        open={vm.sheetSelectedDialogOpen}
        onConfirm={() => vm.convertSheetToCsv()}
        onSelect={(e) => vm.setSelectedSheet(e.target.value)}
        selectedOption={vm.selectedSheet}
        options={vm.xlsxSheets}
        title='XLSX Sheet Selector'
        // disabled={vm.isProcessing}
      />
    )
  }

  const renderUserImportVirtualList = () => {
    if (vm.userRows.length === 0) return null
    return (
      <VirtualList
        className='user-import-list'
        height={window.innerHeight - 120}
        width='100%'
        itemCount={vm.userRows.length}
        itemSize={(index) => vm.userRows[index].height}
        renderItem={({ style, index }) => {
          const row = vm.userRows[index]
          return <UserImportRow key={'usr' + row.key} row={row} style={style} vm={vm} />
        }}
      />
    )
  }

  const renderErrors = () => {
    if (!vm.hasErrors) return null
    return vm.errorRows.map((e, idx) => <ErrorRow key={'er' + idx} row={e} />)
  }

  const renderSpinner = () => {
    if (vm.showImportingSpinner && vm.userRows.length === 0) {
      return (
        <div className='spinner-container'>
          <CircularProgress className='spinner' />
        </div>
      )
    } else {
      return
    }
  }

  const renderDropZone = () => {
    if (!vm.showImportingSpinner && vm.userRows.length !== 0) return null
    if (!vm.showDropZone) return null
    return (
      <Grid container className='dialog-content'>
        <DialogContent>
          {!vm.file ? (
            <Dropzone
              onDrop={(file) => vm.handleFileDrop(file)}
              accept='.csv,text/csv,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              multiple={false}
              minSize={0}
              maxSize={5242880}
            >
              {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                <section>
                  <div className='dropzone' {...getRootProps()}>
                    <input {...getInputProps()} />

                    {!isDragActive && (
                      <div className='iconContainer'>
                        <NoteAddIcon className='noteAddIcon' />
                        <p className='dropzoneText'>
                          {lz.drag_and_drop} <span className='dropLink'>{lz.browse}</span>{' '}
                          {lz.your_file}
                        </p>
                      </div>
                    )}

                    {isDragActive && !isDragReject && (
                      <div className='iconContainer'>
                        <ThumbUpIcon className='thumbUpIcon' />
                        <p className='dropzoneText'>{lz.this_file_looks}</p>
                      </div>
                    )}

                    {isDragActive && isDragReject && (
                      <div className='iconContainer'>
                        <ThumbDownIcon className='thumbDownIcon' />
                        <p className='dropzoneText'>{lz.sorry_this_file_type}</p>
                      </div>
                    )}
                  </div>
                </section>
              )}
            </Dropzone>
          ) : (
            <Dropzone
              onDrop={(file) => vm.handleFileDrop(file)}
              accept='.csv,text/csv,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              multiple={false}
              minSize={0}
              maxSize={5242880}
            >
              {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                <section>
                  <div className='dropzone' {...getRootProps()}>
                    <input {...getInputProps()} />

                    {!isDragActive && (
                      <div className='iconContainer'>
                        <ThumbUpIcon className='thumbUpIcon' />
                        <p className='dropzoneText'>{vm.file.name}</p>
                        <p className='dropzoneText'>{lz.this_file_looks}</p>
                      </div>
                    )}

                    {isDragActive && !isDragReject && (
                      <div className='iconContainer'>
                        <ThumbUpIcon className='thumbUpIcon' />
                        <p className='dropzoneText'>{lz.this_file_looks}</p>
                      </div>
                    )}

                    {isDragActive && isDragReject && (
                      <div className='iconContainer'>
                        <ThumbDownIcon className='thumbDownIcon' />
                        <p className='dropzoneText'>{lz.sorry_this_file_type}</p>
                      </div>
                    )}
                  </div>
                </section>
              )}
            </Dropzone>
          )}
          <DialogContentText className='dialogText'>
            {' '}
            {lz.acceptable_formats}csv, xlsx{' '}
          </DialogContentText>
        </DialogContent>
      </Grid>
    )
  }

  const renderDropZoneNotProcessing = () => {
    if (!vm.showValidatingSpinner) return null
    return (
      <Grid container className='dialog-content'>
        <DialogContent>
          <div className='dropzone'>
            <div className='iconContainer'>
              <p className='dropzoneText'>{lz.processing_users}</p>
            </div>
          </div>
        </DialogContent>
      </Grid>
    )
  }

  const renderLinearProgress = () => {
    if (!vm.importProcessing) return null
    if (!vm.importProgress) return null
    return <LinearProgress variant='determinate' value={vm.importProgress} />
  }

  return (
    <div id='UserImport'>
      <Grid
        className='dialog-title'
        container
        justifyContent='space-between'
        alignItems='center'
        style={{ paddingRight: '10px' }}
      >
        <Grid item>
          <DialogTitle>{vm.dialogTitle}</DialogTitle>
        </Grid>
      </Grid>
      {renderLinearProgress()}
      <Grid className={vm.userRows.length > 0 ? 'dialog-body' : 'dialog-empty'}>
        {renderSpinner()}
        {renderErrors()}
        {renderUserImportVirtualList()}
        {renderDropZone()}
        {renderDropZoneNotProcessing()}
      </Grid>
      {renderSheetSelectDialog()}
      {renderConfirmReset()}
    </div>
  )
}

export default inject('localizationStore')(observer(OrganizationUsersImportContent))
