import { RootStore } from '../../stores/RootStore'
import { observable, action, computed } from 'mobx'
import { Announcement } from '../aggregate/Announcement'
import { AnnouncementEditVM } from '../view-models/AnnouncementEditVM'
import { AnnouncementsWidgetVM } from '../view-models/AnnouncementsWidgetVM'
import { IAnnouncementDTO } from '../dtos/IAnnouncementDTO'
import { DataStore } from '../../shared/data/DataStore'

export class AnnouncementsStore extends DataStore<Announcement, IAnnouncementDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, Announcement, 'announcements', [
      'organizationId',
      'body',
      'title',
      'createdByUserId',
      'createdAt',
      'updatedAt',
      'participants',
      'attachments',
      'likes',
      'views',
      'fk_createdByUser',
      'numberOfParticipants',
      'updatedByUserId',
      'fk_updatedByUser',
      'status',
      'fk_ownerUser',
      'schedule',
      'ownerUserId',
      'showAnnouncementOwner',
    ])
  }

  @observable public editVM: AnnouncementEditVM = null
  @observable public announcementsWidgetVM: AnnouncementsWidgetVM
  @observable public loaded: boolean = false

  @computed
  public get announcements(): Announcement[] {
    return this.records
  }

  @action
  public onListRecordsLoaded() {
    if (!this.announcementsWidgetVM)
      this.announcementsWidgetVM = new AnnouncementsWidgetVM(this.rootStore)
    this.announcementsWidgetVM.prefetch()
    this.loaded = true
  }

  public onRecordUpdated(obj: Announcement) {
    if (!this.announcementsWidgetVM) return
    this.announcementsWidgetVM.forceRefresh()
    // return this.loadListRecords()
  }

  public setRootStore(root) {
    this.rootStore = root
  }

  public getAnnouncement(objectId): Announcement {
    return this.announcements.find((announcement) => announcement.objectId === objectId)
  }

  public deleteAnnouncement(objectId) {
    this.announcements.splice(this.getAnnouncementIndex(objectId), 1)
  }

  private getAnnouncementIndex(objectId): number {
    return this.announcements.findIndex((e) => e.objectId === objectId)
  }

  @action
  public lazyLoadEditVM(id: string, attempts: number = 0) {
    if (attempts === 14) return
    if (!this.rootStore.userStore.currentOrganization && process.env.NODE_ENV !== 'test') {
      setTimeout(() => this.lazyLoadEditVM(id, ++attempts), 500)
      return
    }
    if (id === 'empty') this.editVM = null
    else if (id === 'new')
      this.editVM = new AnnouncementEditVM(
        this.rootStore,
        Announcement.create(
          this.rootStore.appStore.currentUserId,
          this.rootStore.appStore.currentOrgId
        )
      )
    else {
      const foundItem = this.getAnnouncement(id)
      if (!foundItem) setTimeout(() => this.lazyLoadEditVM(id, attempts++), 500)
      else this.editVM = new AnnouncementEditVM(this.rootStore, foundItem.clone())
    }
  }
}
