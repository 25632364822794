import { RootStore } from '../../stores/RootStore'
import { observable, computed, action } from 'mobx'
import { CalendarVM } from './CalendarVM'
import { ILocationDTO } from '../../locations/dtos/ILocationDTO'
import { EventsService } from '../services/EventsService'
import { PopupParticipantVM } from './PopupParticipantVM'
import { IEventPillVM } from '../intefaces/IEventPillVM'
import { IEventStatusReadResult } from '../intefaces/IEventStatusReadResult'
import { Event } from '../aggregate/Event'
import moment from 'moment'
import { deserialize } from 'serializr'
import { EventEditVM } from './EventEditVM'
import { EventSendNotificationsVM } from './EventSendNotificationsVM'
import { MasterEventPromptType } from '../types/MasterEventPromptType'
import { EventsCopyService } from '../services/EventsCopyService'
import { isNumeric } from '../../shared/isNumeric'
import { AttachmentVM } from '../../attachments/view-models/AttachmentVM'
import { OldAttachmentVM } from '../../attachments/view-models/OldAttachmentVM'

export class EventPopupVM {
  private rootStore: RootStore
  private calendarVM: CalendarVM
  @observable private goingStatusResult: IEventStatusReadResult

  constructor(rootStore: RootStore, calendarVM: CalendarVM, pillVM: IEventPillVM) {
    this.rootStore = rootStore
    this.calendarVM = calendarVM
    this.eventPill = pillVM
    this.loadEvent()
    this.getAnchorElement()
  }

  @observable private eventPill: IEventPillVM
  @observable public isProcessing: boolean = false
  @observable public isLoadingGoingStatuses: boolean = false
  @observable public masterEventPromptShown: boolean = false
  @observable public masterEventPromptMode: MasterEventPromptType = 'edit'
  @observable public event: Event = null
  @observable public eventSendNotificationsVM: EventSendNotificationsVM = null

  @action
  public async getGoingStatuses() {
    this.isLoadingGoingStatuses = true
    const svc = new EventsService()
    this.goingStatusResult = await svc.getGoingStatuses(
      this.rootStore.appStore.currentOrgId,
      this.event.objectId,
      this.event.startDate,
      this.event.isFakeEvent
    )
    this.isLoadingGoingStatuses = false
  }

  @action
  public async loadEvent() {
    const event = await this.rootStore.eventsStore.getFullRecord(this.eventPill.objectId)
    if (!event) return
    this.event = event
    this.getGoingStatuses()
  }

  @action
  private getAnchorElement() {
    setTimeout(() => (this.anchorEl = document.getElementsByClassName('selectedEvent')[0]), 20)
  }

  @observable public anchorEl: any = null

  @action
  public createSurvey() {
    let url = '/surveys/foruserevent'
    url += '/' + this.event.objectId
    url += '/' + this.rootStore.appStore.currentUserId
    this.rootStore.appStore.router.push(url)
    this.close()
  }

  @action
  public edit() {
    if (this.eventPill.event.masterEventId && !this.eventPill.event.separateFromMasterEventId) {
      this.toggleMasterEventPrompt('edit')
      return
    }
    this.editThisEvent()
  }

  @action
  public copy() {
    if (this.eventPill.event.masterEventId && !this.eventPill.event.separateFromMasterEventId) {
      return this.toggleMasterEventPrompt('copy')
    }
    this.copyThisEvent()
  }

  @action
  public async copyMasterEvent() {
    const svc = new EventsCopyService(this.rootStore)
    const copiedEvent = await svc.copyEvent(this.eventPill.event.masterEventId)
    this.rootStore.eventsStore.loadEventEditVMFromEvent(copiedEvent)
    this.rootStore.eventsStore.calendarVM.openEventDrawer()
    this.close()
  }

  @action
  public async copyThisEvent() {
    const svc = new EventsCopyService(this.rootStore)
    if (this.eventPill.event.isFakeEvent) {
      const newEvent = await svc.copyEvent(this.objectId)
      newEvent.startDate = this.eventPill.event.startDate
      newEvent.endDate = this.eventPill.event.endDate
      newEvent.occurrenceStartDate = this.eventPill.event.startDate
      this.rootStore.eventsStore.loadEventEditVMFromEvent(newEvent)
      this.rootStore.eventsStore.calendarVM.openEventDrawer()
      return this.close()
    }
    const copiedEvent = await svc.copyEvent(this.objectId)
    this.rootStore.eventsStore.loadEventEditVMFromEvent(copiedEvent)
    this.rootStore.eventsStore.calendarVM.openEventDrawer()
    this.close()
  }

  @action
  public loadNotificationsVM(afterConfirm: Function) {
    if (this.masterEventPromptShown) this.toggleMasterEventPrompt()
    this.eventSendNotificationsVM = new EventSendNotificationsVM(this.rootStore, afterConfirm)
    setTimeout(() => this.eventSendNotificationsVM.toggleShowNotificationsModal(), 500)
  }

  @action
  public delete() {
    this.toggleMasterEventPrompt('delete')
  }

  @action
  public toggleMasterEventPrompt(promptType?: MasterEventPromptType) {
    if (promptType === 'delete') this.masterEventPromptMode = 'delete'
    if (promptType === 'copy') this.masterEventPromptMode = 'copy'
    if (promptType === 'edit') this.masterEventPromptMode = 'edit'
    this.masterEventPromptShown = !this.masterEventPromptShown
  }

  @action
  public handleMasterEventSeries() {
    if (this.masterEventPromptMode === 'delete')
      return this.loadNotificationsVM(() => this.deleteMasterEvent())
    if (this.masterEventPromptMode === 'copy') return this.copyMasterEvent()
    this.editMasterEvent()
  }

  @action
  public handleMasterEventThisOccurrence() {
    if (this.masterEventPromptMode === 'delete')
      return this.loadNotificationsVM(() => this.deleteThisEvent())
    if (this.masterEventPromptMode === 'copy') return this.copyThisEvent()
    this.editThisEvent()
  }

  @action
  public editMasterEvent() {
    this.rootStore.eventsStore.lazyLoadEventEditVM(this.eventPill.event.masterEventId)
    this.rootStore.eventsStore.calendarVM.openEventDrawer()
    this.close()
  }

  @action
  public editThisEvent() {
    if (this.eventPill.event.isFakeEvent) {
      const newEvent = this.getEventObject()
      this.rootStore.eventsStore.editVM = new EventEditVM(this.rootStore, newEvent)
      this.rootStore.eventsStore.calendarVM.openEventDrawer()
      this.close()
      return
    }
    this.rootStore.eventsStore.lazyLoadEventEditVM(this.objectId)
    this.rootStore.eventsStore.calendarVM.openEventDrawer()
    this.close()
  }

  private getEventObject() {
    let masterEvent = this.rootStore.eventsStore.getEvent(this.objectId)
    if (!masterEvent) throw 'unable to find master event'
    if (!this.eventPill.event.isFakeEvent) return masterEvent
    const newEventDTO = masterEvent.serialize()
    newEventDTO.separateFromMasterEventId = this.objectId
    newEventDTO.objectId = null
    newEventDTO.startDate = this.eventPill.event.startDate
    newEventDTO.endDate = this.eventPill.event.endDate
    newEventDTO.occurrenceStartDate = this.eventPill.event.startDate
    newEventDTO.isFakeEvent = true
    newEventDTO.schedule = null
    const newEvent = deserialize(Event, newEventDTO)
    return newEvent
  }

  @action
  public async deleteThisEvent() {
    this.close()
    const event = this.getEventObject()
    event.markAsDeleted()
    const dto = event.serialize()
    const svc = new EventsService()
    await svc.updateEvent(dto, dto.organizationId, event.skipNotifications)
  }

  @action
  public async deleteMasterEvent() {
    this.close()
    const masterEvent = this.rootStore.eventsStore.getEvent(this.eventPill.event.masterEventId)
    masterEvent.markAsDeleted()
    const dto = masterEvent.serialize()
    const svc = new EventsService()
    await svc.updateEvent(dto, dto.organizationId, masterEvent.skipNotifications)
  }

  @computed
  public get goingYesUserIds(): string[] {
    if (this.goingStatusResult) return this.goingStatusResult.goingYesUserIds
    return this.event.goingYesUserIds
  }

  @computed
  public get allDay(): boolean {
    return this.event.allDay
  }

  @computed
  public get goingNoUserIds(): string[] {
    if (this.goingStatusResult) return this.goingStatusResult.goingNoUserIds
    return this.event.goingNoUserIds
  }

  @computed
  public get goingMaybeUserIds(): string[] {
    if (this.goingStatusResult) return this.goingStatusResult.goingMaybeUserIds
    return this.event.goingMaybeUserIds
  }

  public get notes(): string {
    // if(!this.event.notes) return ''
    return this.event.notes
  }

  @computed
  public get isMasterEvent(): boolean {
    return !Boolean(this.eventPill.event.masterEventId)
  }

  @computed
  public get goingStatusSubTitle(): string {
    if (!this.goingStatusResult) return ''
    const yesStr = `${this.goingStatusResult.goingYesUserIds.length} Yes, `
    const noStr = `${this.goingStatusResult.goingNoUserIds.length} No, `
    const maybeStr = `${this.goingStatusResult.goingMaybeUserIds.length} Maybe, `
    const awaitingStr = `${this.goingStatusResult.goingNewUserIds.length} Awaiting`
    return yesStr + noStr + maybeStr + awaitingStr
  }

  @computed
  public get attachments(): AttachmentVM[] {
    return this.event.attachments.map((e, idx) => {
      if (isNumeric(e.objectId) || e.cmsItemId) {
        return this.rootStore.cmsItemAttachmentStore.loadAttachment(e)
      }
      return new OldAttachmentVM(this.rootStore, e, idx)
    })
  }

  @computed
  public get title(): string {
    return this.eventPill.title
  }

  @computed
  public get startDate(): Date {
    return this.eventPill.localStartDate
  }

  @computed
  public get endDate(): Date {
    return this.eventPill.localEndDate
  }

  @computed
  public get objectId(): string {
    return this.event.objectId
  }

  @computed
  public get editable(): boolean {
    if (this.rootStore.appStore.isOrgAdmin) return true
    return this.event.organizer === this.rootStore.appStore.currentUserId
  }

  @computed
  public get resourcesCSV(): string {
    if (this.event.resources.length === 0) return ''
    const resourceArray = this.event.resources.map((resource) => {
      const foundRsrce = this.rootStore.resourcesStore.getResource(resource.resourceId)
      if (foundRsrce) return foundRsrce.name
      return null
    })
    return resourceArray.join(', ')
  }

  @computed
  public get users(): Array<PopupParticipantVM> {
    return this.event.userIds.map((userId) => new PopupParticipantVM(this.rootStore, this, userId))
  }

  @computed
  public get location(): ILocationDTO {
    return (
      this.rootStore.locationsStore.locations.find(
        (location) => location.objectId === this.event.locationId
      ) ?? null
    )
  }

  @computed
  public get organizer() {
    const foundOrganizer = this.rootStore.audienceMembersStore.getUser(this.event.organizer)
    if (foundOrganizer) return foundOrganizer
    return null
  }

  @computed
  public get alarmsCSV(): string {
    if (this.event.alarms.length === 0) return ''
    const alarmArray = this.event.alarms
      .filter((e) => typeof e.period === 'string')
      .sort((a, b) => (a.units < b.units ? -1 : 0))
      .map((alarm) => {
        if (alarm.units === 0) {
          return 'At event start'
        } else if (Number(alarm.units) === 1) {
          const alarmSingularPeriod = alarm.period.substr(0, alarm.period.length - 1)
          const str = `${alarm.units} ${alarmSingularPeriod}`
          return str
        } else {
          const str = `${alarm.units} ${alarm.period}`
          return str
        }
      })
      .filter((x, i, a) => a.indexOf(x) == i)
    return alarmArray
      .join(', ')
      .concat(alarmArray[0] === 'At event start' && alarmArray.length === 1 ? '' : ' before')
  }

  @computed
  public get placeholderIconShown() {
    if (this.rootStore.appStore.environmentLabel !== 'LOCAL') return false
    return this.eventPill.event.isFakeEvent
  }

  @action
  public close() {
    this.anchorEl = null
  }

  public async openAttachment(attachment: AttachmentVM) {
    attachment.openAttachment()
  }
}
