import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { ContentVM } from '../../view-models/ContentVM'
import ExerciseSet from './ExerciseSet'
import './ContentItem.scss'

interface Props {
  vm: ContentVM
  localizationStore?: LocalizationStore
}

const ExerciseSets: React.FC<Props> = ({ vm, localizationStore }) => {
  const { training_items: lz } = localizationStore.lzStrings

  if (!vm) return null

  const renderExerciseItemRows = (vm: ContentVM) => {
    if (!vm.exerciseVM) return
    return vm.exerciseVM.sets.map((set) => {
      return <ExerciseSet key={`exercise-set-${set.index}`} vm={set} />
    })
  }

  return <>{renderExerciseItemRows(vm)}</>
}

export default inject('localizationStore')(observer(ExerciseSets))
