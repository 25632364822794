import { SurveyEditVM } from './SurveyEditVM'
import { RootStore } from '../../stores/RootStore'
import { QuestionVM } from './QuestionVM'

export class TestDataVM {
  private editVM: SurveyEditVM
  private rootStore: RootStore

  constructor(rootStore: RootStore, editVM: SurveyEditVM) {
    this.editVM = editVM
    this.rootStore = rootStore
  }

  public populateTestData(version: string) {
    // if (version === 'xmas' || window.location.hash.includes('v=xmas'))
      this.loadChristmasPartyTestData()
    // else if (version === 'self-assessment') this.loadSelfAssessmentTestData()
  }

  private loadSelfAssessmentTestData() {
    if (this.editVM.name === '')
      this.editVM.setName('Self Assessment: ' + this.rootStore.userStore.user.name)
    this.editVM.clearQuestions()
    this.rootStore.categoriesStore.currentOrgCategories
      .filter((e) => e.hasChildren === false)
      .sort((a, b) => (a.pathName < b.pathName ? -1 : 0))
      .forEach((e, i) => {
        this.editVM.addQuestionByType('rating')
        const q: QuestionVM = this.editVM.questions[i]
        q.setCategory(e.objectId)
        q.setTitle(e.parentPathName + ': How would you rank your skills in ' + e.name + '?')
        q.options[0].setText('Fundamental Awareness')
        q.options[0].setSubText('(basic knowledge)') 
        q.options[1].setText('Novice')
        q.options[1].setSubText('(limited experience)')
        q.options[2].setText('Intermediate')
        q.options[2].setSubText('(practical application)')
        q.addOption()
        q.options[3].setText('Advanced')
        q.options[3].setSubText('(applied theory)')
        q.addOption()
        q.options[4].setText('Expert')
        q.options[4].setSubText('(demonstrated excellence)')
      })
  }

  private loadChristmasPartyTestData() {
    if (this.editVM.name === '') this.editVM.setName('Christmas Party Survey')
    this.editVM.clearQuestions()
    this.editVM.addQuestionByType('emoji')
    this.editVM.currentQuestion.setTitle( "Did you enjoy last year's Holiday Potluck?")
    this.editVM.currentQuestion.options[0].setEmoji('crying')
    this.editVM.currentQuestion.options[0].setText('It was awful')
    this.editVM.currentQuestion.options[1].setEmoji('confused')
    this.editVM.currentQuestion.options[1].setText('meh')
    this.editVM.currentQuestion.options[2].setEmoji('happy')
    this.editVM.currentQuestion.options[2].setText('It was fun')
    this.editVM.currentQuestion.options[3].setEmoji('in-love')
    this.editVM.currentQuestion.options[3].setText('I had a blast!')
    this.editVM.addQuestionByType('yesNo')
    this.editVM.currentQuestion.setTitle("Are you attending this year's Holiday Potluck?")
    this.editVM.currentQuestion.options[0].setText('Yes')
    this.editVM.currentQuestion.options[1].setText('No')
    this.editVM.currentQuestion.options[1].setGoToEnd()
    this.editVM.addQuestionByType('text')
    this.editVM.currentQuestion.setTitle('What item(s) will you be bringing?')
    this.editVM.currentQuestion.options[0].setText('(Textbox)')
    this.editVM.addQuestionByType('check')
    this.editVM.currentQuestion.setTitle("Please indicate which volunteer activities you'd like to help with (Check all that apply):")
    this.editVM.currentQuestion.options[0].setText('Coordinating items to be brought')
    this.editVM.currentQuestion.options[1].setText('Setting up the event space')
    this.editVM.currentQuestion.options[2].setText('Guest coordination')
    this.editVM.currentQuestion.options[3].setText('Clean-up after the event')
    this.editVM.addQuestionByType('radio')
    this.editVM.currentQuestion.setTitle('What do you want most for Christmas?')
    this.editVM.currentQuestion.options[0].setText('A pony')
    this.editVM.currentQuestion.options[1].setText('Firetruck')
    this.editVM.currentQuestion.options[2].setText('Sega Dreamcast')
    this.editVM.currentQuestion.options[3].setText('Official Red Ryder, carbine action, 200-shot, range model air rifle, with a compass in the stock and this thing that tells time.')
    this.editVM.addQuestionByType('yesNo')
    this.editVM.currentQuestion.setTitle('Are you excited for the event?')
    this.editVM.currentQuestion.options[0].setText('Yes')
    this.editVM.currentQuestion.options[1].setText('No')
    this.editVM.addQuestionByType('text')
    this.editVM.currentQuestion.setTitle('Which is your favorite Reindeer?')
    this.editVM.currentQuestion.options[0].setText('(Textbox)')
    this.editVM.addQuestionByType('dropdown')
    this.editVM.currentQuestion.setTitle('What option do you like best?')
    this.editVM.currentQuestion.options[0].setText('This option')
    this.editVM.currentQuestion.options[1].setText('Or this option')
    this.editVM.currentQuestion.options[2].setText('Maybe this option')
    this.editVM.currentQuestion.options[3].setText('Finally this option')
    this.editVM.setCurrentQuestion(0)
    // this.currentQuestion.options[0].nextQuestionId = this.questions[1].id
    this.editVM.questions[1].options[0].setNextQuestion(this.editVM.questions[2].id)
    // this.questions[1].options[1].nextQuestionId = this.questions[2].id
  }
}
