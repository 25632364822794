import { RootStore } from '../../stores/RootStore'
import { computed, observable, action } from 'mobx'
import {
  ColDef,
  Column,
  GridOptions,
  RowClickedEvent,
  RowNode,
  SortChangedEvent,
} from 'ag-grid-community'
import { nameOf } from '../../shared/nameOf'
import { AGGridVM } from '../../shared/ag-grid/AGGridVM'
import NameCell from '../views/table/NameCell'
import CheckboxCell from '../views/table/CheckboxCell'
import PreviewCell from '../views/table/PreviewCell'
import ActionsCell from '../views/table/ActionsCell'
import { CMSItemsStore } from '../store/CMSItemsStore'
import { CMSItemsManageVM } from './CMSItemsManageVM'
import { CMSItemsLayoutTableRowVM } from './CMSItemsLayoutTableRowVM'

export class CMSItemsLayoutTableVM extends AGGridVM {
  private rootStore: RootStore
  public dataStore: CMSItemsStore
  public manageVM: CMSItemsManageVM
  protected quickSaveTO: NodeJS.Timer

  constructor(rootStore: RootStore, dataStore: CMSItemsStore, manageVM: CMSItemsManageVM) {
    super()
    this.rootStore = rootStore
    this.manageVM = manageVM
    this.columnsLoaded = true
    this.dataStore = dataStore
    this.sizeColumnsToFit = true
    this.serverSideLoaded = true
  }

  @observable public newSelectedRows: Array<any> = []
  @observable public calculatedSelected: number = 0
  @observable public isProcessing: boolean = false
  @observable public filterPopupAnchorEl = null
  @observable public rows = undefined
  @observable public columnPopupAnchorEl = null
  @observable public columnsLoaded: boolean = false
  @observable public viewColumns: Array<string> = [
    'preview',
    'Title',
    'createdAt',
    'modifiedAt',
    'actions',
  ]
  @observable public modifyMenuAnchorEl = null
  @observable public tableHasBeenEdited: boolean = false
  @observable public editedRowEvents: Array<any> = []
  @observable public showTable: boolean = true
  @observable public moreMenuAnchorEl = null
  @observable public loadingFirstPage: boolean = true
  @observable public isLoading: boolean = true

  @computed
  public get isLoaded(): boolean {
    if (!this.dataStore.isLoaded) return false
    if (!this.columnsLoaded) return false
    return true
  }

  @computed
  public get isSystemAdmin(): boolean {
    return this.rootStore.appStore.isSystemAdmin
  }

  @computed
  public get hasRows() {
    if (!this.rows) return false
    return this.rows.length !== 0
  }

  @action
  public setQuickFilter(val: string) {
    this.typedFilterText = val
    if (this.quickFilterTO) clearTimeout(this.quickFilterTO)
    this.quickFilterTO = setTimeout(() => this.applyFilter(), 1000)
  }

  private applyFilter() {
    this.reload()
    this.columnApi.applyColumnState({
      state: [{ colId: 'title', sort: 'asc' }],
    })
  }

  @action
  public scrollToTop() {
    this.gridApi.ensureIndexVisible(0)
  }

  @action
  public refresh() {
    if (!this.gridApi) return
    this.gridApi.refreshInfiniteCache()
    this.gridApi.purgeInfiniteCache()
  }

  @computed
  public get totalRecords(): number {
    return this.dataStore.rows.length
  }

  protected onGridReadied(): void {
    this.gridApi.sizeColumnsToFit()
    this.gridApi.setDatasource({
      //behave as infinite scroll - undefined
      rowCount: undefined,
      getRows: async (params) => {
        console.debug('table asking for ' + params.startRow + ' to ' + params.endRow)

        if (!this.rows || params.endRow > this.rows.length) await this.manageVM.loadNextPage()

        const rows = this.manageVM.mediaItems.map((e) => {
          return new CMSItemsLayoutTableRowVM(this.rootStore, e, this)
        })
        this.rows = rows

        // take a slice of the total rows
        const rowsThisPage = this.rows.slice(params.startRow, params.endRow)

        // if on or after the last page, work out the last row.
        let lastRow = -1
        if (this.rows.length <= params.endRow) {
          lastRow = this.rows.length
        }

        this.loadingFirstPage = false

        // call the success callback
        params.successCallback(rowsThisPage, lastRow)

        this.isLoading = true
        this.gridApi.forEachNode((node) => {
          if (!node) return
          if (!node.data) return
          if (this.manageVM.isItemSelected(node.data.item.itemIdStr)) node.setSelected(true)
        })
        setTimeout(() => (this.isLoading = false), 300)
      },
    })
  }

  @computed
  public get selectedCount() {
    let count = this.newSelectedRows.length
    return count
  }

  @action
  public getRowNodeId(row): string {
    return row.objectId
  }

  @computed
  public get hasFilter(): boolean {
    return Boolean(this.typedFilterText)
  }

  @computed
  public get canManage() {
    if (this.rootStore.appStore.isSystemAdmin) return true
    if (!this.rootStore.userStore.currentOrganization) return false
    return false
  }

  public getGridOptions(): GridOptions {
    return {
      ...this.baseGridOptions,
      rowData: this.rows,
      rowHeight: 48,
      // tell grid we want virtual row model type
      rowModelType: 'infinite',
      // how big each page in our page cache will be, default is 100
      cacheBlockSize: 50,
      // how many extra blank rows to display to the user at the end of the dataset,
      // which sets the vertical scroll and then allows the grid to request viewing more rows of data.
      // default is 1, ie show 1 row.
      cacheOverflowSize: 2,
      // how many server side requests to send at a time. if user is scrolling lots, then the requests
      // are throttled down
      maxConcurrentDatasourceRequests: 1,
      // how many rows to initially show in the grid. having 1 shows a blank row, so it looks like
      // the grid is loading from the users perspective (as we have a spinner in the first col)
      infiniteInitialRowCount: 50,
      // how many pages to store in cache. default is undefined, which allows an infinite sized cache,
      // pages are never purged. this should be set for large data to stop your browser from getting
      // full of data
      maxBlocksInCache: 20,
      onRowClicked: (e) => this.rowClicked(e),
      getRowNodeId: (e) => this.getRowNodeId(e),
      suppressRowClickSelection: true,
      suppressCellSelection: true,
      suppressRowHoverHighlight: false,
      rowSelection: this.manageVM.allowMultiple ? 'multiple' : 'single',
      onRowSelected: (e) => this.onRowSelected(e.node),
      columnDefs: this.ensureTooltipFields(this.columnDefs),
      pagination: false,
      sortingOrder: ['desc', 'asc'],
      onSortChanged: (e: SortChangedEvent) => {
        const col = (e.api as any).sortController.getColumnsWithSortingOrdered()[0] as Column
        if (!col) return
        const sortType = `${col.getColId()}-${col.getSort()}`
        this.manageVM.setSortType(sortType)
      },
      frameworkComponents: {
        checkboxCell: CheckboxCell,
        previewCell: PreviewCell,
        nameCell: NameCell,
        actionsCell: ActionsCell,
      },
      isRowSelectable: (rowNode) => {
        if (!rowNode.data) return false
        return true
      },
    }
  }

  public rowClicked(e: RowClickedEvent) {
    const row: CMSItemsLayoutTableRowVM = e.data
    if (!row) return

    if (e.event.defaultPrevented) {
      return null
    }

    row.click(e.rowIndex)
  }

  @computed
  public get columnDefs(): ColDef[] {
    const s = this.rootStore.localizationStore.lzStrings
    return [
      {
        width: 50,
        suppressSizeToFit: true,
        checkboxSelection: false,
        sortable: false,
        headerComponentParams: { vm: this },
        cellRenderer: 'checkboxCell',
      },
      {
        headerName: 'Preview',
        headerTooltip: 'Preview',
        onCellClicked: null,
        width: 100,
        maxWidth: 100,
        suppressSizeToFit: true,
        checkboxSelection: false,
        tooltipField: 'null',
        hide: !this.showPreviewCol,
        cellRenderer: 'previewCell',
        cellClass: 'centerCell',
        sortable: false,
      },
      {
        headerName: 'Name',
        headerTooltip: 'Name',
        field: nameOf<CMSItemsLayoutTableRowVM, string>((e) => e.Title),
        width: 260,
        suppressSizeToFit: true,
        checkboxSelection: false,
        sortable: true,
        tooltipField: 'null',
        hide: !this.showNameCol,
        cellRenderer: 'nameCell',
        sortingOrder: ['desc', 'asc'],
        sort: this.manageVM.sortType.includes('Title-')
          ? this.manageVM.sortType.endsWith('asc')
            ? 'asc'
            : 'desc'
          : undefined,
        comparator: undefined,
      },
      {
        headerName: 'Type',
        width: 100,
        suppressSizeToFit: true,
        headerTooltip: 'Content Item Type',
        field: nameOf<CMSItemsLayoutTableRowVM, string>((e) => e.item.type),
        hide: !this.showTypeCol,
        editable: false,
        sortable: true,
        cellEditor: 'agTextCellEditor',
        cellEditorParams: {
          useFormatter: true,
          maxLength: 25,
        },
      },
      {
        headerName: 'Created At',
        width: 240,
        suppressSizeToFit: true,
        headerTooltip: 'Content Item Created Date',
        field: nameOf<CMSItemsLayoutTableRowVM, string>((e) => e.createdAt),
        hide: !this.showCreatedAtCol,
        editable: false,
        sortable: true,
        sortingOrder: ['desc', 'asc'],
        sort: this.manageVM.sortType.includes('createdAt-')
          ? this.manageVM.sortType.endsWith('asc')
            ? 'asc'
            : 'desc'
          : undefined,
        comparator: undefined,
      },
      {
        headerName: 'Modified At',
        width: 240,
        suppressSizeToFit: true,
        headerTooltip: 'Content Item Last Modified Date',
        field: nameOf<CMSItemsLayoutTableRowVM, string>((e) => e.updatedAt),
        hide: !this.showModifiedAtCol,
        editable: false,
        sortable: true,
        sortingOrder: ['desc', 'asc'],
        sort: this.manageVM.sortType.includes('updatedAt-')
          ? this.manageVM.sortType.endsWith('asc')
            ? 'asc'
            : 'desc'
          : undefined,
        comparator: undefined,
      },
      {
        headerName: 'Actions',
        headerTooltip: 'Actions',
        onCellClicked: null,
        checkboxSelection: false,
        tooltipField: 'null',
        hide: !this.showActionsCol,
        cellRenderer: 'actionsCell',
        sortable: false,
      },
    ]
  }

  @action
  public verifyNewSelection() {
    if (!this.manageVM.allowMultiple && this.manageVM.hasSelections) this.resetNodesSelected()
  }

  @action
  public manageSelectedRows(node) {
    const row: CMSItemsLayoutTableRowVM = node.data

    if (row && !this.isLoading) {
      this.manageVM.toggleItem(row.item)
    }

    let index = this.newSelectedRows.findIndex((e) => e.itemId === row.item.itemId)
    if (index === -1) {
      this.newSelectedRows.push(row.item)
    } else {
      this.newSelectedRows.splice(index, 1)
    }
  }

  @action
  public onRowSelected(node: RowNode) {
    this.manageSelectedRows(node)
  }

  @action
  public resetNodesSelected() {
    this.isLoading = true
    this.newSelectedRows.forEach((row) => (row.isChecked = false))
    this.newSelectedRows = this.newSelectedRows.splice(0, this.newSelectedRows.length)
    if (this.gridApi) this.gridApi.deselectAll()
    setTimeout(() => (this.isLoading = false), 300)
  }

  @computed
  public get dialogCount() {
    return this.newSelectedRows.length
  }

  @computed
  public get showPreviewCol() {
    return this.viewColumns.includes('preview')
  }

  @computed
  public get showNameCol() {
    return this.viewColumns.includes('Title')
  }

  @computed
  public get showTypeCol() {
    return this.viewColumns.includes('type')
  }

  @computed
  public get showCreatedAtCol() {
    return this.viewColumns.includes('createdAt')
  }

  @computed
  public get showModifiedAtCol() {
    return this.viewColumns.includes('modifiedAt')
  }

  @computed
  public get showActionsCol() {
    return this.viewColumns.includes('actions')
  }
}
