import { action, computed, observable, reaction } from 'mobx'
import { IPulseCategoryDTO } from '../dtos/IPulseCategoryDTO'
import { RootStore } from '../../stores/RootStore'
import { PulseQuestionVM } from '../../pulse-questions/view-models/PulseQuestionVM'
import { IPulseQuestionDTO } from '../../pulse-questions/dtos/IPulseQuestionDTO'
import { PulseQuestionsDataStore } from '../../pulse-questions/view-models/PulseQuestionsDataStore'
import { PulseCategoriesManageVM } from './PulseCategoriesManageVM'
import { IPulseQuestionsFindRequest } from '../../pulse-questions/interfaces/IPulseQuestionsFindRequest'

export class ChildCategoryRowVM {
  private rootStore: RootStore
  private dataStore: PulseQuestionsDataStore

  constructor(rootStore: RootStore, manageVM: PulseCategoriesManageVM, category: IPulseCategoryDTO) {
    this.rootStore = rootStore
    this.manageVM = manageVM
    this.category = category
    this.dataStore = new PulseQuestionsDataStore(this.rootStore, {} as IPulseQuestionsFindRequest)
    this.registerReactions()
  }

  @observable public category: IPulseCategoryDTO = null
  @observable public isExpanded: boolean = false
  @observable public pulseQuestions: IPulseQuestionDTO[] = []
  @observable public manageVM: PulseCategoriesManageVM = null
  @observable public index: number = 0

  private registerReactions() {
    if (process.env.NODE_ENV === 'test') return
    reaction(
      () => this.manageVM.checkedPulseSetIds,
      () => this.getPulseQuestions(),
      { delay: 1000 }
    )
  }

  @computed
  public get isLoaded(): boolean {
    return this.dataStore.isLoaded
  }

  @computed
  public get name(): string {
    return this.category.name
  }

  @computed
  public get objectId(): string {
    return this.category.objectId
  }

  @computed
  public get grandParentPulseCategoryId(): string {
    return this.category.grandCategoryId
  }

  @computed
  public get parentPulseCategoryId(): string {
    return this.category.parentCategoryId
  }

  @action
  public setIndex(index: number) {
    this.index = index
  }

  @action
  public toggleIsExpanded() {
    this.isExpanded = !this.isExpanded
    if (this.isExpanded) this.getPulseQuestions()
  }

  @action
  public async getPulseQuestions() {
    if (!this.isExpanded) return 
    let pulseSets = undefined
    if (this.manageVM.checkedPulseSetIds.length) pulseSets = this.manageVM.checkedPulseSetIds
    this.dataStore.setRequest(
      {
        pulseCategoryId: this.objectId,
        pulseSets: pulseSets
      }
    )
    this.dataStore.loadListRecords()
  }

  @computed
  public get pulseQuestionRows(): PulseQuestionVM[] {
    return this.dataStore.rows.map((q) => new PulseQuestionVM(this.rootStore, this, q))
  }

  @action
  public editPulseCategory() {
    this.rootStore.pulseCategoriesStore.viewModels.loadPulseCategoryEditVM(this.objectId)
  }

  @action
  public createNewPulseQuestion() {
    this.rootStore.appStore.router.push(`/systemAdmin/pulseQuestion/edit/new/${this.objectId}`)
  }

}
