import { RootStore } from '../../stores/RootStore'
import { DataStore } from '../../shared/data/DataStore'
import { IOrganizationUserAudienceSearchRequest } from '../../organization-users/interfaces/IOrganizationUserAudienceSearchRequest'
import { Group } from '../../groups/aggregate/Group'
import { IGroupDTO } from '../../groups/dtos/IGroupDTO'
import { OrganizationUsersService } from '../../organization-users/service/OrganizationUsersService'

export class GroupsListDataStore extends DataStore<Group, IGroupDTO> {
  private userId: string

  constructor(rootStore: RootStore, userId: string) {
    super(rootStore, Group, 'groups', ['objectId', 'name'])
    this.userId = userId
    this.paged = true
    this.recordsPerPage = 100
    this.sortColumnName = 'name'
  }

  protected getListRecordsApiCall() {
    return async () => {
      const req: IOrganizationUserAudienceSearchRequest = {
        userId: this.userId,
        orgId: this.rootStore.appStore.currentOrgId,
        terms: this.filter,
        skip: this.recordsPerPage * (this.pageNumber - 1),
        limit: this.recordsPerPage,
        sortColumnName: this.sortColumnName,
      }
      const svc = new OrganizationUsersService(this.rootStore)
      const result = await svc.searchAudienceGroups(req)
      this.totalRecords = result.count === 0 ? result.groups.length : result.count
      return result.groups
    }
  }

  public get groups() {
    return this.records
  }
}
