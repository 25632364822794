import { RootStore } from '../../stores/RootStore'
import { observable, computed, action } from 'mobx'
import { Quiz } from '../aggregate/Quiz'
import { QuestionVM } from './QuestionVM'
import { QuestionType } from '../types/QuestionType'
import HashId from '../../shared/HashId'

export class QuizVM {
  private rootStore: RootStore
  public quiz: Quiz

  constructor(rootStore: RootStore, quiz: Quiz, isDisabled?: boolean) {
    this.rootStore = rootStore
    this.quiz = quiz ? quiz : Quiz.create()
    this.isDisabled = isDisabled
    setTimeout(() => this.setCurrentQuestion(0), 500)
  }

  @observable public drawerOpen: boolean = false
  @observable public currentQuestionIndex: number = 0
  @observable public newIndex: number = 0
  @observable public oldIndex: number = 0
  @observable public cantDeleteLastQuestion: boolean = false
  @observable public enabled: boolean = false
  @observable public isDisabled: boolean = false
  @observable public questionTypes: Array<string> = [
    'emoji',
    'check',
    'radio',
    'rating',
    'starRating',
    'text',
    'yesNo',
    'dropdown',
  ]

  @computed
  public get questions(): QuestionVM[] {
    return this.quiz.questions.map(
      (question, index) => new QuestionVM(this.rootStore, question, index)
    )
  }

  @computed
  public get currentQuestion(): QuestionVM {
    return this.questions.filter((question) => question.index === this.currentQuestionIndex)[0]
  }

  @action
  public enableQuiz(boolean) {
    this.enabled = boolean
  }

  @action
  public moveQuestion(moveToIndex?: number) {
    this.spliceQuestion(this.newIndex)
    this.setCurrentQuestion(this.newIndex)
  }

  @action
  public spliceQuestion(newIdx) {
    this.quiz.spliceQuestion(this.currentQuestionIndex, newIdx)
    // this.setQuestionRanks()
  }

  @action
  public deleteQuestionByCard(index) {
    this.setCurrentQuestion(index)
    setTimeout(() => this.deleteQuestion(), 100)
  }

  @action
  public deleteQuestion() {
    if (this.questions.length === 1) return this.toggleDeleteLastQuestion()
    this.quiz.deleteQuestion(this.currentQuestionIndex)
    if (this.currentQuestionIndex === 0) {
      this.setCurrentQuestion(0)
    } else if (this.currentQuestionIndex > 0) {
      this.setCurrentQuestion(this.currentQuestionIndex - 1)
    } else {
      this.setCurrentQuestion(null)
    }
  }

  @action
  public copyQuestion(id: string) {
    const questionToCopy = this.quiz.questions.filter((q) => q.id === id)[0].clone()
    questionToCopy.id = HashId.generate()
    this.quiz.questions.push(questionToCopy)
    this.setCurrentQuestion(this.questions.length - 1)
  }

  @action
  public addQuestion(question) {
    this.quiz.addQuestion(question)
  }

  @action
  public toggleDeleteLastQuestion() {
    this.cantDeleteLastQuestion = !this.cantDeleteLastQuestion
  }

  @action
  public setNewIndex(value) {
    this.newIndex = value
  }

  @action
  public setOldIndex(value) {
    this.oldIndex = value
  }

  @action
  public setCurrentQuestion(idx: number) {
    if (idx !== null) this.drawerOpen = true
    this.currentQuestionIndex = idx
  }

  @action
  public addQuestionByType(val: QuestionType) {
    this.quiz.addQuestion(val)
  }

  public updateCurrentQuestionByType(type: QuestionType) {
    this.currentQuestion.changeQuestionType(type)
  }

  @action
  public checkQuizErrors() {
    this.questions.forEach((question) => {
      if (!question.title) {
        question.setTitleError(true)
      } else {
        question.setTitleError(false)
      }
      if (
        question.options.length === 0 ||
        !question.options.some((option) => option.isCorrectAnswer)
      ) {
        if (question.type === 'text') return
        question.setOptionsError(true)
      } else {
        question.setOptionsError(false)
      }
    })
  }
}
