import React from 'react'
import { observer } from 'mobx-react'
import { IconButton, Tooltip } from '@material-ui/core'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import LiveHelpIcon from '@material-ui/icons/LiveHelp'
import { UserRowVM } from '../../view-models/my-audience-widget/UserRowVM'

const ActionsCell = (props) => {
  const row: UserRowVM = props.data
  if (!row) return

  const createSurveyAction = () => {
    if (!row.canSurvey) return null
    return (
      <Tooltip
        title={`Create ${row.labelsStore.getLabelByLanguageAndFor('pulse')} for ${row.name}`}
        placement='bottom-start'
        enterDelay={500}
      >
        <IconButton aria-label='survey' size='small' onClick={() => row.goToSurvey()}>
          <LiveHelpIcon className='action-link survey' />
        </IconButton>
      </Tooltip>
    )
  }

  return (
    <div className='audience-members-widget-aggrid-actions-cell'>
      <Tooltip title={`Send Email to ${row.name}`} placement='bottom-start' enterDelay={500}>
        <IconButton
          aria-label='mail'
          size='small'
          onClick={(e) => row.setEmailAnchorEl(e.currentTarget)}
        >
          <a
            className='action-link mail'
            // href={row.rowType === 'user' ? row.mailToLink : null}
            target='_blank'
          >
            <MailOutlineIcon />
          </a>
        </IconButton>
      </Tooltip>

      <Tooltip title={`Create Event for ${row.name}`} placement='bottom-start' enterDelay={500}>
        <IconButton aria-label='calendar' size='small' onClick={() => row.goToEvent()}>
          <CalendarTodayIcon className='action-link event' />
        </IconButton>
      </Tooltip>
      {createSurveyAction()}
    </div>
  )
}

export default observer(ActionsCell)
