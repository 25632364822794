import { computed, action, observable } from 'mobx'
import { RootStore } from 'src/app/stores/RootStore'
import { IWeightProfileImportRowDTO } from '../dtos/IWeightProfileImportRowDTO'
import { ImportCategoryVM } from './ImportCategoryVM'
import WeightProfilesImportVM from './WeightProfilesImportVM'

export class WeightProfileImportRowVM {
  private rootStore: RootStore
  private row: IWeightProfileImportRowDTO
  private importVM: WeightProfilesImportVM
  private index: number

  constructor(
    rootStore: RootStore,
    row: IWeightProfileImportRowDTO,
    importVM: WeightProfilesImportVM,
    index
  ) {
    this.row = row
    this.rootStore = rootStore
    this.importVM = importVM
    this.index = index
  }

  @computed
  public get textStyle(): string {
    let textStyle = 'import-row-text'
    if (this.isDeleted) textStyle = 'import-row-text is-deleted'
    if (this.isNew) textStyle = 'import-row-text is-new'
    return textStyle
  }

  @computed
  public get isNew(): boolean {
    if (!this.row.result) return true
    if (!this.row.result.weightProfile) return true
    return Boolean(!this.row.result.weightProfile.objectId)
  }

  @computed
  public get isDeleted(): boolean {
    if (!this.row.result) return false
    if (!this.row.result.weightProfile) return false
    return this.row.result.weightProfile.isDeleted
  }

  @computed
  public get height(): number {
    let height = 135
    height = height + 26 * this.rowsCount
    return height
  }

  @computed
  public get rowsCount(): number {
    let rows = 1
    if (this.hasError) return 0
    return rows
  }

  @computed
  public get role(): string {
    if (!this.row.result) return ''
    if (!this.row.result.weightProfile) return this.row['Role']
    const foundRole = this.rootStore.rolesStore.getRole(this.row.result.weightProfile.roleId)
    if (foundRole) return foundRole.name
    return this.row['Role']
  }

  @computed
  public get categories(): Array<ImportCategoryVM> {
    if (!this.row.result) return []
    if (!this.row.result.weightProfile) return []
    if (!this.row.result.weightProfile.categories) return []
    return this.row.result.weightProfile.categories.map((o) => new ImportCategoryVM(this.rootStore, o))
  }

  @computed
  public get hasError() {
    return !this.row.result.success
  }

  @computed
  public get rowIndex(): string {
    return this.row.rowIndex.toString()
  }

  @computed
  public get isNewContact(): boolean {
    return this.row.result.isNew
  }

  @computed
  public get key() {
    return 'cont' + this.row.rowIndex
  }

  @computed
  public get weightProfileErroMessage(): string {
    if (!this.row.result) return ''
    if (!this.row.result.errorMessage) return ''
    return this.row.result.errorMessage
  }

  @action
  public setHeight(height, index) {
    const indexObject = {
      index: index,
      height: height,
    }
    this.importVM.cardHeights.push(indexObject)
  }
}
