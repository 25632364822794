import { computed, observable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { EmailTemplatePreviewVM } from './EmailTemplatePreviewVM'

export class EmailTemplatesEditorHTMLTabVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, vm: EmailTemplatePreviewVM) {
    this.rootStore = rootStore
    this.vm = vm
  }

  public vm: EmailTemplatePreviewVM
  public tabIndex: number = 0
  @observable public showInactiveUserSurveys: boolean = false

  @computed
  public get shown(): boolean {
    return this.vm.tabIndex === this.tabIndex
  }

  @computed
  public get shouldRender(): boolean {
    if (!this.rootStore.emailTemplatesStore.loaded) return false
    return true
  }
}
