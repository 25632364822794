import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { EventsStore } from '../../store/EventsStore'
import { AlarmVM } from '../../view-models/AlarmVM'
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  Select,
  MenuItem,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  InputLabel,
} from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import Autocomplete from '@material-ui/lab/Autocomplete'

interface ICalendarEventDrawerAlarms {
  localizationStore?: LocalizationStore
  eventsStore?: EventsStore
}

const CalendarEventDrawerAlarms: React.FC<ICalendarEventDrawerAlarms> = ({
  localizationStore,
  eventsStore,
}) => {
  const { lzStrings } = localizationStore
  const { editVM: vm } = eventsStore

  const renderRow = (row: AlarmVM, index: number) => {
    return (
      <Grid item key={index}>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Grid item xs={2}>
            {/* <TextField
              fullWidth
              variant='outlined'
              type='number'
              value={row.units.toString()}
              onChange={(e) => row.setUnits(e.target.value)}
            /> */}
            <Autocomplete
              id='eventDrawerUnitsSelect'
              className='resources-input'
              disableClearable
              options={row.availableUnits.slice()}
              getOptionLabel={(option) => option}
              onChange={(e, value) => row.setUnits(value)}
              value={row.units.toString()}
              renderInput={(params) => (
                <TextField {...params} variant='outlined' fullWidth margin='none' />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl variant='outlined' fullWidth>
              <Select value={row.period} onChange={(e) => row.setPeriod(e.target.value.toString())}>
                {row.alarmOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {row.units === 1 ? option.substring(0, option.length - 1) : option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Typography variant='body1' className='alarm-text'>
              before the event starts
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={(e) => row.delete()} aria-label='Delete Alarm'>
              <CancelIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderRows = () => {
    return vm.alarms.map((e, idx) => renderRow(e, idx))
  }

  return (
    <>
      <Grid item xs={12}>
        <InputLabel>{lzStrings.calendarEventDrawer.alarms}</InputLabel>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox checked={vm.onTimeAlarm} onChange={() => vm.handleOnTimeAlarmToggle()} />
          }
          label={
            <Typography variant='body1' className='alarm-text'>
              When the event starts
            </Typography>
          }
        />
      </Grid>
      {renderRows()}
      <Grid item xs={12} className='event-edit-drawer-add-alarm-button-grid'>
        <Button onClick={() => vm.addAlarm()}>Add Alarm</Button>
      </Grid>
    </>
  )
}

export default inject('localizationStore', 'eventsStore')(observer(CalendarEventDrawerAlarms))
