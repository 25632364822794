import { computed, observable, action } from 'mobx'
import { AGGridVM } from '../../shared/ag-grid/AGGridVM'

export class QuestionTypesFilterVM {
  private parentVM: AGGridVM

  constructor(parentVM: AGGridVM) {
    this.typesToShow = this.types
    this.parentVM = parentVM
  }

  @observable public typesToShow: string[] = []

  public get types(): string[] {
    return ['radio', 'check', 'rating', 'starRating', 'emoji', 'yesNo', 'text', 'number', 'order', 'dropdown']
  }

  @computed
  public get allChecked(): boolean {
    return this.types.length === this.typesToShow.length
  }

  @computed
  public get selectedCountLabel(): string {
    if (this.allChecked) return 'All'
    return this.typesToShow.length.toString()
  }

  @action
  public handleClickSelectAllTypes() {
    if (this.allChecked) {
      this.typesToShow = []
      this.parentVM.reload()
      return
    }
    this.typesToShow = this.types.slice()
    this.parentVM.reload()
  }

  @action
  public handleChangeTypeFilter(type) {
    if (this.typesToShow.includes(type)) {
      this.typesToShow = this.typesToShow.filter((t) => t !== type)
      this.parentVM.reload()
      return
    }
    this.typesToShow.push(type)
    this.parentVM.reload()
  }

  public prettierTypeName(name) {
    if (name === 'radio') return 'Radio'
    if (name === 'check') return 'Check'
    if (name === 'rating') return 'Rating'
    if (name === 'starRating') return 'Star Rating'
    if (name === 'emoji') return 'Emoji'
    if (name === 'yesNo') return 'Yes/No'
    if (name === 'text') return 'Text'
    if (name === 'number') return 'Number'
    if (name === 'order') return 'Order'
    if (name === 'dropdown') return 'Dropdown'
    return name
  }
}
