import { action, observable, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { UserTask } from '../aggregates/UserTask'
import { CompletedUserTaskRowVM } from './CompletedUserTaskRowVM'
import { ColDef, GridOptions, RowClickedEvent } from 'ag-grid-community'
import { AGGridVM } from '../../shared/ag-grid/AGGridVM'
import { nameOf } from '../../shared/nameOf'

export class CompletedUserTasksWidgetVM extends AGGridVM {
  private rootStore: RootStore
  constructor(rootStore: RootStore) {
    super()
    this.rootStore = rootStore
  }

  @action
  public getRowNodeId(row): string {
    return row.objectId
  }

  @computed
  public get columnDefs(): ColDef[] {
    const s = this.rootStore.localizationStore.lzStrings
    return [
      {
        headerName: s.completedUserTasksWidget.name,
        field: nameOf<CompletedUserTaskRowVM, string>((e) => e.taskName),
        sort: 'asc',
      },
      {
        headerName: s.completedUserTasksWidget.discipline,
        field: nameOf<CompletedUserTaskRowVM, string>((e) => e.disciplineName),
        hide: !this.allowDiscipline,
      },
      {
        headerName: s.completedUserTasksWidget.due,
        field: nameOf<CompletedUserTaskRowVM, string>((e) => e.dueDateFormatted),
      },
      {
        headerName: s.completedUserTasksWidget.creator,
        field: nameOf<CompletedUserTaskRowVM, string>((e) => e.assignedByUserName),
        cellRenderer: 'creatorCell',
      },
      {
        headerName: 'Feedback',
        field: nameOf<CompletedUserTaskRowVM, string>((e) => e.feedbackSurveyStatus),
        hide: !this.allowFeedback,
      },
    ]
  }

  @computed
  public get allowFeedback(): boolean {
    if (!this.rootStore.organizationsStore.currentOrganization) return false
    return this.rootStore.organizationsStore.currentOrganization.tasksAllowFeedback
  }

  @computed
  public get allowDiscipline(): boolean {
    if (!this.rootStore.organizationsStore.currentOrganization) return false
    return this.rootStore.organizationsStore.currentOrganization.tasksAllowDiscipline
  }

  @computed
  public get completedUserTasks(): Array<UserTask> {
    return this.rootStore.userTasksStore.userTasks.filter(
      (e) => e.isCompleted && e.userId === this.rootStore.appStore.currentUserId
    )
  }

  public getGridOptions(): GridOptions {
    return {
      ...this.baseGridOptions,
      rowData: this.rows,
      onRowClicked: (e) => this.rowClicked(e),
      getRowNodeId: (e) => this.getRowNodeId(e),
      columnDefs: this.ensureTooltipFields(this.columnDefs),
    }
  }

  public rowClicked(e: RowClickedEvent) {
    const row: CompletedUserTaskRowVM = e.data
    if (!row) return
    row.openUserTask()
  }

  @computed
  public get shouldRender(): boolean {
    if (!this.rootStore.tasksStore.loaded) return false
    if (!this.rootStore.audienceMembersStore.loaded) return false
    return true
  }

  @computed
  public get rows() {
    return this.completedUserTasks.map((e) => new CompletedUserTaskRowVM(this.rootStore, this, e))
  }
}
