import React from 'react'
import { inject, observer } from 'mobx-react'
import { Box, AppBar, Toolbar, Tabs, Tab } from '@material-ui/core'
import ReorderIcon from '@material-ui/icons/Reorder'
import TurnedInIcon from '@material-ui/icons/TurnedIn'
import TuneIcon from '@material-ui/icons/Tune'
import { CustomCollectionsStore } from '../../store/CustomCollectionStore'
import FieldsTab from './FieldsTab'
import RecordsTab from './RecordsTab'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div hidden={value !== index} {...other}>
      <Box component='div'>{children}</Box>
    </div>
  )
}

interface Props {
  customCollectionsStore?: CustomCollectionsStore
}

const TabsBar: React.FC<Props> = ({ customCollectionsStore }) => {
  const { editVM: vm } = customCollectionsStore
  if (!vm) return null

  return (
    <>
      <div id='TabsBar'>
        <AppBar position='static' elevation={0}>
          <Toolbar>
            <div>
              <Tabs
                value={vm.tabIndex}
                onChange={(e, idx) => vm.setTab(idx)}
                indicatorColor={'primary'}
                textColor='primary'
                variant='fullWidth'
              >
                <Tab icon={<TuneIcon />} label='Field Definitions' value={0} />
                <Tab icon={<ReorderIcon />} label={vm.recordsTabLabel} value={1} disabled={vm.isNew} />
              </Tabs>
            </div>
          </Toolbar>
        </AppBar>

        <TabPanel value={vm.tabIndex} index={0}>
          <FieldsTab />
        </TabPanel>
        <TabPanel value={vm.tabIndex} index={1}>
          <RecordsTab />
        </TabPanel>
      </div>
    </>
  )
}

export default inject('customCollectionsStore')(observer(TabsBar))
