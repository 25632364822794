import React from 'react'
import { inject, observer, useLocalStore } from 'mobx-react'
import { AppBar, Button, ButtonBase, Chip, CssBaseline, Toolbar, Tooltip, Typography } from '@material-ui/core'
import clsx from 'clsx'
import isIE11 from '../../../../utils/isIE11'
import { PulseSetsManageVM } from '../../view-models/manage/PulseSetsManageVM'
import LocalizationStore from '../../../localization/LocalizationStore'
import { LabelsStore } from '../../../labels/store/LabelsStore'

interface Props {
  vm: PulseSetsManageVM
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
}

const TopToolbar: React.FC<Props> = ({ vm, localizationStore, labelsStore }) => {
  if (!vm) return null
  const lz = localizationStore.lzStrings.pulseSetMGMT
  const lz2 = localizationStore.lzStrings.pulseCampaign

  const renderAddButton = () => {
    return (
      <Button
        variant='contained'
        onClick={() => vm.createNewPulseSet()}
        className='dashboard-action'
      >
        {lz.create_pulse_set}
      </Button>)
  }

  const renderCreateTemplate = () => {
    return (
      <Tooltip
        title={`${lz2.create} ${labelsStore.getLabelByLanguageAndFor('pulse')} ${lz2.campaign_template
          }`}
        placement='bottom'
      >
        <span>
          <Button
            onClick={() => vm.createPulseTemplate()}
            className={'dashboard-action'}
            variant={'contained'}
          >
            {lz2.create_template}
          </Button>
        </span>
      </Tooltip>
    )
  }

  return (
    <div id='DashboardToolbar'>
      <CssBaseline />
      <AppBar
        className={clsx('dashboard-appbar', {
          'no-transition': isIE11,
        })}
        elevation={0}
      >
        <Toolbar className='dashboard-toolbar'>
          <Typography className='dashboard-title'>
            {lz.manage_pulse_sets}
          </Typography>
          <div className='dashboard-toolbar-right'>
            {renderAddButton()}
            {renderCreateTemplate()}
          </div>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  )
}

export default inject('localizationStore', 'labelsStore')(observer(TopToolbar))
