import React from 'react'
import { inject, observer } from 'mobx-react'
import { PrivilegeSetsStore } from '../store/PrivilegeSetsStore'
import LocalizationStore from '../../localization/LocalizationStore'
import { Drawer, Grid, InputLabel, TextField, CardContent } from '@material-ui/core'
import './PrivilegeSetDrawer.scss'

import MuiDrawerToolbar from '../../shared/Mui-Drawer/MuiDrawerToolbar'
import MuiDrawerHeader from '../../shared/Mui-Drawer/MuiDrawerHeader'
import PrivilegesTable from './PrivilegesTable'

interface Props {
  privilegeSetsStore?: PrivilegeSetsStore
  localizationStore?: LocalizationStore
}

const PrivilegeSetDrawer: React.FC<Props> = ({ privilegeSetsStore, localizationStore }) => {
  const { privilegeSetDrawerVM: vm } = privilegeSetsStore
  if (!vm) return null

  const strings = localizationStore.lzStrings.privilegeSetsEdit

  const toggleDrawer = () => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    privilegeSetsStore.showDrawer = !privilegeSetsStore.showDrawer
    if (privilegeSetsStore.showDrawer === false) privilegeSetsStore.selectedPrivilegeSetId = ''
  }

  return (
    <Drawer
      variant='temporary'
      anchor='right'
      open={privilegeSetsStore.showDrawer}
      onClose={toggleDrawer()}
      id='PrivilegeSetDrawer'
    >
      <MuiDrawerHeader
        title={vm.objectId ? strings.manage_privilege_set : strings.add_privilege_set}
        toggleDrawer={toggleDrawer()}
      />
      <MuiDrawerToolbar
        moreMenuProps={{
          isEditMode: vm.isEditMode,
          itemType: localizationStore.lzStrings.moreMenu.obj_type.privilege_set,
          itemName: vm.name,
          onConfirmDelete: () => vm.delete(),
        }}
        showReset={Boolean(vm.objectId)}
        onConfirmReset={() => privilegeSetsStore.setSelectedPrivilegeSet(vm.objectId)}
        handleCancelClick={toggleDrawer()}
        handleSubmitClick={() => vm.save()}
      />
      <CardContent id='privilegeSetBasicInfo'>
        <h6>Basic Info</h6>
        <form autoComplete='off' noValidate>
          <Grid container>
            <Grid item md={12} sm={12}>
              <InputLabel required htmlFor='name'>
                Name
              </InputLabel>
              <TextField
                error={!vm.nameValid || !vm.nameUnique}
                value={vm.name}
                onChange={(e) => (vm.name = e.target.value)}
                fullWidth
                helperText={vm.errorHelperText}
                variant='outlined'
                id='name'
              />
            </Grid>
          </Grid>
          <Grid container className='descriptionContainer'>
            <Grid item md={12} sm={12}>
              <InputLabel htmlFor='description'>Description</InputLabel>
              <TextField
                multiline
                minRows={5}
                margin='none'
                value={vm.description}
                onChange={(e) => (vm.description = e.target.value)}
                fullWidth
                variant='outlined'
                id='description'
              />
            </Grid>
          </Grid>
        </form>
        <Grid container className='privilegesTableContainer'>
          <Grid item md={12} sm={12}>
            <h6 className='title'>Enabled Privileges</h6>
            <PrivilegesTable />
          </Grid>
        </Grid>
      </CardContent>
    </Drawer>
  )
}

export default inject('privilegeSetsStore', 'localizationStore')(observer(PrivilegeSetDrawer))
