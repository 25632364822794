import React from 'react'
import { inject, observer } from 'mobx-react'
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
  Tooltip,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import DescriptionIcon from '@material-ui/icons/DescriptionRounded'
import EditIcon from '@material-ui/icons/EditOutlined'
import rootStore from '../../../stores/RootStore'
import { PulseCampaignSurveyEditVM } from '../../view-models/PulseCampaignSurveyEditVM'
import './PulseCampaignSurveyIntroductionCard.scss'

interface Props {
  vm: PulseCampaignSurveyEditVM
}

const PulseCampaignSurveyCompletedContentCard: React.FC<Props> = ({ vm }) => {
  if (!vm) return null
  const { pulseCampaign: lz } = rootStore.localizationStore.lzStrings
  const labelsStore = rootStore.labelsStore

  const renderHeader = () => {
    if (!vm) return

    const parser = new DOMParser()
    const surveyCompletedContent = parser.parseFromString(vm.surveyCompletedContent, 'text/html')
      .documentElement.textContent

    const renderThumbnail = () => {
      if (!vm.surveyIntroAttachment) return
      const attachment = vm.surveyIntroAttachment
      if (attachment.type === 'image') return <img src={attachment.url} />
      if (attachment.type === 'video') return <img src={attachment.thumbnail} />
      if (attachment.type !== 'image' || 'video') return <DescriptionIcon />
    }

    return (
      <CardHeader
        avatar={
          <Tooltip title={''} placement='bottom-start'>
            <span>
              <div className='question-card-header-attachment'>{renderThumbnail()}</div>
            </span>
          </Tooltip>
        }
        action={
          <div className={'question-card-header-actions'}>
            <Tooltip
              title={
                vm.isSent
                  ? `${labelsStore.getLabelByLanguageAndFor('pulse')} ${lz.already_sent}`
                  : lz.delete
              }
              placement='bottom-start'
            >
              <span>
                <IconButton
                  disabled={vm.isSent}
                  aria-label='question-card-header-action-delete'
                  className={'question-card-btn'}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    vm.setSurveyCompletedContent('')
                  }}
                >
                  <Avatar className={`question-card-btn-avatar ${vm.isSent ? 'disabled' : ''}`}>
                    <DeleteIcon fontSize='small' />
                  </Avatar>
                </IconButton>
              </span>
            </Tooltip>
          </div>
        }
        title={
          <Typography className='question-card-header-title'>{surveyCompletedContent}</Typography>
        }
      />
    )
  }

  const renderEditButton = () => {
    if (!vm) return
    return (
      <div className={'question-card-edit'}>
        <Tooltip
          title={
            vm.isSent
              ? `${labelsStore.getLabelByLanguageAndFor('pulse')} ${lz.already_sent}`
              : lz.edit
          }
          placement='bottom-start'
        >
          <span>
            <IconButton
              disabled={vm.isSent}
              className={'question-card-btn'}
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                vm.openCompletionContentModal()
              }}
            >
              <Avatar className={`question-card-btn-avatar ${vm.isSent ? 'disabled' : ''}`}>
                <EditIcon fontSize='small' />
              </Avatar>
            </IconButton>
          </span>
        </Tooltip>
      </div>
    )
  }

  const renderCard = () => {
    return (
      <div id='PulseCampaignSurveyIntroductionCard'>
        <Card>{renderHeader()}</Card>
        {renderEditButton()}
      </div>
    )
  }

  return <>{renderCard()}</>
}

export default inject()(observer(PulseCampaignSurveyCompletedContentCard))
