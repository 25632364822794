import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { EventsStore } from '../../store/EventsStore'
import { Grid, TextField, Button, InputLabel } from '@material-ui/core'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import Map from '../../../components/MobX-Calendar-Map/Map'
import Marker from '../../../components/MobX-Calendar-Map/Marker'
import { ILocationDTO } from '../../../locations/dtos/ILocationDTO'
import CalendarEventDrawerLocationSearchBar from './components/CalendarEventDrawerLocationSearchBar'

interface ICalendarEventDrawerLocation {
  localizationStore?: LocalizationStore
  eventsStore?: EventsStore
}

const CalendarEventDrawerLocation: React.FC<ICalendarEventDrawerLocation> = ({
  localizationStore,
  eventsStore,
}) => {
  const { lzStrings } = localizationStore
  const { editVM: vm } = eventsStore

  const renderNewLocationMarker = () => {
    if (vm.selectedLocationOption) return null
    return (
      <Marker text={vm.newLocation?.name} lat={vm.newLocation?.lat} lng={vm.newLocation?.lng} />
    )
  }

  const renderLocationMarker = () => {
    if (vm.newLocation.name) return null
    if (!vm.selectedLocationOption) return null
    return (
      <Marker
        text={vm.selectedLocationOption.name}
        lat={vm.selectedLocationOption.lat}
        lng={vm.selectedLocationOption.lng}
      />
    )
  }

  const renderMap = () => {
    if (!vm.selectedLocationOption && !vm.newLocation) return null
    if (!vm.selectedLocationOptionCoordinates) return null
    return (
      <Grid item xs={12}>
        <div style={{ height: '200px', width: '100%' }}>
          <Map center={vm.selectedLocationOptionCoordinates} zoom={vm.mapZoom}>
            {renderLocationMarker()}
            {renderNewLocationMarker()}
          </Map>
        </div>
      </Grid>
    )
  }

  const renderLocationDropdown = () => {
    if (vm.addNewLocation) return null

    const filter = createFilterOptions<ILocationDTO>()

    return (
      <Grid container>
        <Grid item xs={12}>
          <Autocomplete
            className='location-input'
            options={vm.locations.slice()}
            getOptionLabel={(option: ILocationDTO) => option.name || ''}
            id='locationSearch'
            disabled={vm.addNewResource}
            value={vm.selectedLocationOption}
            filterOptions={(options, params) => {
              const filtered = filter(options, params) as ILocationDTO[]
              if (filtered.length === 0) {
                filtered.push({
                  objectId: '',
                  name: 'Add New',
                  organizationId: '',
                  lng: 0,
                  lat: 0,
                  isDeleted: false,
                } as ILocationDTO)
              }
              return filtered
            }}
            freeSolo
            onChange={(e, value) => vm.handleLocationSelect(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                onChange={(e) => vm.setLocationText(e.target.value)}
                margin='none'
                variant='outlined'
                fullWidth
                required
                onKeyDown={(e) => {
                  if (e.keyCode === 13 && e.target) {
                    vm.handleLocationSelect({ name: 'Add New' })
                  }
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    )
  }

  const renderAddNewSearchBar = () => {
    if (!vm.addNewLocation) return null

    return (
      <Grid container>
        <Grid item xs={12}>
          <CalendarEventDrawerLocationSearchBar />
        </Grid>
        <Grid item xs={12} className='location-save-cancel'>
          <Button disabled={!vm.newLocation.name} onClick={() => vm.saveNewLocation()}>
            Save
          </Button>
          <Button onClick={() => vm.toggleAddNewLocation()}>Cancel</Button>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <Grid item xs={12} style={{ width: '100%' }}>
        <InputLabel className={vm.addNewResource ? 'location-disabled' : ''}>
          {lzStrings.calendarEventDrawer.location}
        </InputLabel>
        <Grid item xs={12}>
          {renderLocationDropdown()}
          {renderAddNewSearchBar()}
        </Grid>
      </Grid>
      {renderMap()}
    </>
  )
}

export default inject('localizationStore', 'eventsStore')(observer(CalendarEventDrawerLocation))
