import React from 'react'
import { CardHeader, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import './MuiDrawerHeader.scss'

const MuiDrawerHeader = (props) => {
  return (
    <div id='MuiDrawerHeader'>
      <CardHeader
        action={
          <IconButton onClick={props.toggleDrawer} style={{ marginTop: -4 }}>
            <CloseIcon />
          </IconButton>
        }
        title={props.title}
      />
    </div>
  )
}

export default MuiDrawerHeader
