import { action, computed, observable } from 'mobx'
import { Question } from './Question'
import { Participant } from './Participant'
import { Schedule } from './Schedule'
import { DueAfter } from './DueAfter'
import { Alarms } from './Alarms'
import { AnonymousParticipant } from './AnonymousParticipant'
import { EmailTemplate } from './EmailTemplate'
import { FlowFormatType } from '../types/FlowFormatType'
import { VisibilityType } from '../types/VisibilityType'
import { serializable, serialize, deserialize, list, primitive, date, object } from 'serializr'
import { ISurveyDTO } from '../dtos/ISurveyDTO'
import { PeriodType } from '../types/PeriodType'
import { RepeatModeType } from '../types/RepeatModeType'
import { EndingModeType } from '../types/EndingModeType'
import { QuestionType } from '../types/QuestionType'
import { ParseDate } from '../../shared/ParseDate'
import { IAggregate } from 'src/app/shared/data/IAggregate'
import { FKUser } from '../../organization-users/aggregate/FKUser'
import moment, { ISO_8601 } from 'moment'
import { NotificationTemplate } from '../../notifications/store/aggregates/NotificationTemplate'
import { DueAfterModelType } from '../types/DueAfterModelType'
import { AutoCancel } from './AutoCancel'
import { FKSurveyType } from './FKSurveyType'
import { VisibleParticipant } from '../aggregate/VisibleParticipant'
import { IParticipant } from 'src/app/participants-select/interfaces/IParticipant'
import { AdditionalResponseNotificationParticipant } from './AdditionalResponseNotificationParticipant'
export class Survey implements ISurveyDTO, IAggregate {
  static create(orgId, userId) {
    const svy = new Survey()
    svy.organizationId = orgId
    svy.publishedByUserId = userId
    return svy
  }

  @serializable @observable objectId: string = ''
  @serializable @observable organizationId: string = ''
  @serializable @observable categoryId: string = ''
  @serializable @observable name: string = ''
  @serializable @observable isTemplate: boolean = false
  @serializable @observable isDeleted: boolean = false
  @serializable @observable published: boolean = false
  @serializable @observable publishedDateTime: number = null
  @serializable @observable publishedByUserId: string = ''
  @serializable @observable publishedByUserName: string = ''
  @serializable @observable isClosed: boolean = false
  @serializable @observable linksNeverExpire: boolean = false
  @serializable(list(primitive())) @observable participatingUsers: Array<string> = []
  @serializable(list(object(Participant))) @observable feedbackParticipants: Array<Participant> = []
  @serializable @observable iconURL: string = ''
  @serializable(list(object(Question))) @observable questions: Array<Question> = []
  @serializable(list(object(Participant))) @observable participants: Array<Participant> = []
  @serializable(list(object(AnonymousParticipant)))
  @observable
  anonymousParticipants: Array<AnonymousParticipant> = []
  @serializable(list(primitive())) @observable uniqueUserResponders: Array<string> = []
  @serializable @observable uniqueUserResponses: number = 0
  @serializable @observable anonymizeResults: boolean = false
  @serializable @observable showResults: boolean = false
  @serializable @observable forceEmails: boolean = false
  @serializable @observable sendEmailOnResponse: boolean = false
  @serializable @observable sendEmailToAdditionalUsersOnResponse: boolean = false
  @serializable @observable forceTextMessages: boolean = false
  @serializable @observable mainCategoryId: string = ''
  @serializable @observable surveyTypeId: string = ''
  @serializable @observable worksheetId: string = ''
  @serializable @observable eventId: string = ''
  @serializable(object(Schedule)) @observable schedule: Schedule = new Schedule()
  @serializable(object(DueAfter)) @observable dueAfter: DueAfter = new DueAfter()
  @serializable(object(AutoCancel)) @observable autoCancel: AutoCancel = new AutoCancel()
  @serializable(object(Alarms)) @observable alarms: Alarms = new Alarms()
  @serializable @observable userTaskId: string = ''
  @serializable @observable emailFromName: string = ''
  @serializable @observable emailFromAddress: string = ''
  @serializable @observable questionsFlowFormat: FlowFormatType = 'scrolling'
  @serializable @observable visibility: VisibilityType = 'default'
  @serializable(list(object(Participant))) @observable visibleTo: Array<any> = []
  @serializable(list(object(VisibleParticipant)))
  @observable
  visibleParticipants: Array<VisibleParticipant> = []
  @serializable(list(object(AdditionalResponseNotificationParticipant)))
  @observable
  additionalResponseNotificationParticipants: Array<AdditionalResponseNotificationParticipant> = []
  @serializable @observable lastResponseReceived: number = null
  @serializable(object(ParseDate)) @observable closedDate: ParseDate = null
  @serializable @observable closedByUserId: string = ''
  @serializable @observable feedbackForLocked: boolean = false
  @serializable @observable surveyIntroduction: string = ''
  @serializable @observable public templateOnlyEditableByOwner: boolean = true
  @serializable @observable templateId: string = ''
  @serializable @observable public specificDate: boolean = false
  @serializable @observable public dueAfterSetPeriod: boolean = false
  @serializable @observable public alwaysAvailable: boolean = false
  @serializable @observable public questionsCount: number = 0
  @serializable(date()) @observable createdAt: Date = null
  @serializable(object(FKUser)) @observable public fk_publishedByUserId: FKUser = null
  @serializable(object(FKSurveyType)) @observable public fk_surveyTypeId: FKSurveyType = null
  isOnServer: boolean = false
  @serializable(list(object(EmailTemplate))) @observable emailTemplates: Array<EmailTemplate> = []
  @serializable(list(object(NotificationTemplate)))
  @observable
  publishedTemplates: Array<NotificationTemplate> = []
  @serializable(list(object(NotificationTemplate)))
  @observable
  reminderTemplates: Array<NotificationTemplate> = []
  @serializable(list(primitive())) @observable surveyTagIds: Array<string> = []
  @serializable @observable isAutoSaveDraft: boolean = false
  @serializable @observable isPulseSurvey: boolean = false
  @serializable @observable pulseCampaignId?: string = ''
  @serializable @observable isWelcomeEmail: boolean = false
  @serializable @observable surveyCompletedContent: string = ''
  @observable isFromSystemTemplate: boolean = false
  @serializable @observable numberOfParticipants: number

  @computed
  public get isNew(): boolean {
    return Boolean(!this.objectId)
  }

  @action
  public markIsNotOnServer() {
    this.isOnServer = false
  }

  @action
  public markIsOnServer() {
    this.isOnServer = true
  }

  @action
  public toggleTemplateEditability() {
    this.templateOnlyEditableByOwner = !this.templateOnlyEditableByOwner
  }

  @action
  public cleanUpEmojis() {
    this.questions.forEach((e) => {
      if (e.type !== 'emoji') return
      e.options.forEach((o) => {
        o.emoji = o.emoji
        if (!o.emoji) o.emoji = o.iconName
      })
    })
  }

  @action
  public setName(val: string) {
    this.name = val
  }

  @action
  public setPublishedByUserId(val: string) {
    this.publishedByUserId = val
  }

  @action
  public setFKPublishedByUserId(val: FKUser) {
    this.fk_publishedByUserId = val
  }

  @action
  public clearQuestions() {
    this.questions = []
  }

  @action
  public addQuestion(question: Question) {
    this.questions.push(Question.create(question))
  }

  @action
  public addQuestionByType(type: QuestionType) {
    if (type === 'emoji') this.questions.push(Question.createFromEmojiType())
    if (type === 'rating') this.questions.push(Question.createFromRatingType())
    if (type === 'starRating') this.questions.push(Question.createFromStarRatingType())
    if (type === 'radio') this.questions.push(Question.createFromRadioType())
    if (type === 'check') this.questions.push(Question.createFromCheckType())
    if (type === 'yesNo') this.questions.push(Question.createFromYesNoType())
    if (type === 'text') this.questions.push(Question.createFromTextType())
    if (type === 'infoText') this.questions.push(Question.createFromInfoTextType())
    if (type === 'number') this.questions.push(Question.createFromNumberType())
    if (type === 'order') this.questions.push(Question.createFromOrderType())
    if (type === 'dropdown') this.questions.push(Question.createFromDropdownType())
  }

  @action
  public toggleIsTemplate() {
    this.isTemplate = !this.isTemplate
  }

  @action
  public toggleLinksNeverExpire() {
    this.linksNeverExpire = !this.linksNeverExpire
  }

  @action
  public toggleDueAfterEnabled() {
    this.dueAfter.toggleIsEnabled()
    if (!this.dueAfterEnabled) {
      this.alarms.enabled = false
      this.alarms.resetAlarms()
    }
    if (this.alarms.enabled) this.toggleSendRemindersSpecificDate()
  }

  @action
  public toggleAlwaysAvailable() {
    this.alwaysAvailable = !this.alwaysAvailable
  }

  @action
  public setMode(mode: DueAfterModelType) {
    this.dueAfter.setMode(mode)
  }

  @action
  public toggleScheduleEnabled() {
    this.schedule.toggleIsEnabled()
  }

  @action
  public toggleSendReminders() {
    this.alarms.toggleSendReminders()
    if (!this.dueAfterEnabled) {
      this.toggleSendRemindersSpecificDate()
    }
    if (!this.alarms.enabled) this.alarms.resetAlarms()
  }

  @action
  public toggleSendRemindersOnDueDate() {
    this.alarms.toggleSendRemindersOnDueDate()
  }

  @action
  public toggleSendReminders15BeforeDueDate() {
    this.alarms.toggleSendReminders15BeforeDueDate()
  }

  @action
  public toggleSendRemindersCustomPeriod() {
    this.alarms.toggleSendRemindersCustomPeriod()
  }

  @action
  public toggleSendRemindersSpecificDate() {
    this.alarms.toggleSendRemindersSpecificDate()
  }

  @action
  public setDueAfterPeriod(val: PeriodType) {
    this.dueAfter.setPeriod(val)
  }

  @action
  public setDueAfterValue(val: number) {
    this.dueAfter.setValue(val)
  }

  @action
  public setObjectId(objectId: string) {
    this.objectId = objectId
  }

  @action
  public clearObjectId() {
    this.objectId = ''
  }

  @action
  public setEventId(eventId: string) {
    this.eventId = eventId
  }

  @action
  public toggleAnonymizeResults() {
    this.anonymizeResults = !this.anonymizeResults
  }

  @action
  public setAnonymizeResults(val: boolean) {
    this.anonymizeResults = val
  }

  @action
  public toggleShowResults() {
    this.showResults = !this.showResults
  }

  @action
  public toggleForceEmails() {
    this.forceEmails = !this.forceEmails
  }

  @action
  public toggleSendEmailOnResponse() {
    this.sendEmailOnResponse = !this.sendEmailOnResponse
  }

  @action
  public toggleSendEmailToAdditionalUsersOnResponse() {
    this.sendEmailToAdditionalUsersOnResponse = !this.sendEmailToAdditionalUsersOnResponse
  }

  @action
  public setForceEmails(val: boolean) {
    this.forceEmails = val
  }

  @action
  public toggleForceTextMessages() {
    this.forceTextMessages = !this.forceTextMessages
  }

  @action
  public setForceTextMessages(val: boolean) {
    this.forceTextMessages = val
  }

  @action
  public setSurveyTypeId(surveyTypeId: string) {
    this.surveyTypeId = surveyTypeId
  }

  @action
  public setSurveyTagId(surveyTagId: string) {
    this.surveyTagIds = [surveyTagId]
  }

  @action
  public setWorksheetId(worksheetId: string) {
    this.worksheetId = worksheetId
  }

  @action
  public setUserTaskId(userTaskId: string) {
    this.userTaskId = userTaskId
  }

  @action
  public setEmailFromName(name: string) {
    this.emailFromName = name
  }

  @action
  public setEmailFromAddress(address: string) {
    this.emailFromAddress = address
  }

  @action
  public setQuestionsFlowFormat(format: FlowFormatType) {
    this.questionsFlowFormat = format
  }

  @action
  public setMainCategoryId(mainCategoryId: string) {
    this.mainCategoryId = mainCategoryId
  }

  @action
  public setRepeatMode(val: RepeatModeType) {
    this.schedule.setRepeat(val)
  }

  @action
  public setVisibility(val) {
    this.visibility = val
  }

  @action
  public setStartDate(val: Date) {
    this.schedule.setStartDate(val)
  }

  @action
  public setOrganizationId(id: string) {
    this.organizationId = id
  }

  @action
  public setTemplate(val: string) {
    this.isTemplate = false
    this.templateId = val
  }

  @action
  public setEndDate(val: Date) {
    this.schedule.setEndDate(val)
  }

  @action
  public setDeliveryTime(val: Date) {
    this.schedule.setDeliveryTime(val)
  }

  @action
  public setEndingMode(val) {
    this.schedule.setEndingMode(val)
  }

  @action
  public setDeliveryTimeZone(val) {
    this.schedule.setDeliveryTimeZone(val)
  }

  @action
  public toggleWeekday(day) {
    this.schedule.toggleDay(day)
  }

  @action
  public addAlarm(obj) {
    this.alarms.addAlarm(obj)
  }

  @action
  public deleteAlarm(mode) {
    this.alarms.deleteAlarm(mode)
  }

  @action
  public deleteQuestion(index: number) {
    const questions = this.questions.filter((e) => !e.isDeleted)
    questions[index].markAsDeleted()
  }

  @action
  public setSurveyintroduction(val: string) {
    this.surveyIntroduction = val
  }

  @action
  public setSurveyCompletedContent(val: string) {
    this.surveyCompletedContent = val
  }

  @action
  public spliceQuestion(index: number, newIdx: number) {
    const questions = this.questions.filter((e) => !e.isDeleted)
    const questionObj = questions.splice(index, 1)
    questions.splice(newIdx, 0, questionObj[0])
    const deletedQuestions = this.questions.filter((e) => e.isDeleted)
    this.questions = questions.concat(deletedQuestions)
  }

  @action
  private parseISOString(s) {
    var b = s.split(/\D+/)
    return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]))
  }

  @computed
  public get closedDateAsDate(): Date {
    if (!this.closedDate) return null
    return this.closedDate.date
  }

  @computed
  public get createdAtAsDate(): Date {
    if (!this.createdAt) return null
    return this.createdAt
  }

  @computed
  public get sendReminders(): boolean {
    return this.alarms.enabled
  }

  @computed
  public get sendRemindersOnDueDate(): boolean {
    return this.alarms.sendRemindersOnDueDate
  }

  @computed
  public get sendReminders15BeforeDueDate(): boolean {
    return this.alarms.sendReminders15BeforeDueDate
  }

  @computed
  public get sendRemindersCustomPeriod(): boolean {
    return this.alarms.sendRemindersCustomPeriod
  }

  @computed
  public get sendRemindersSpecificDate(): boolean {
    return this.alarms.sendRemindersSpecificDate
  }

  @computed
  public get weekdays() {
    return this.schedule.days
  }

  @computed
  public get deliveryTimeZone() {
    return this.schedule.deliveryTimeZone
  }

  @computed
  public get endingMode(): EndingModeType {
    return this.schedule.endingMode
  }

  @computed
  public get deliveryTime(): Date {
    return this.parseISOString(this.schedule.deliveryTime)
  }

  @computed
  public get dueAfterEnabled(): boolean {
    return this.dueAfter.enabled
  }

  @computed
  public get dueAfterPeriod(): PeriodType {
    return this.dueAfter.period
  }

  @computed
  public get dueAfterValue(): number {
    return this.dueAfter.value
  }

  @computed
  public get scheduleEnabled(): boolean {
    return this.schedule.enabled
  }

  @computed
  public get repeatMode(): RepeatModeType {
    return this.schedule.repeat
  }

  @computed
  public get startDate(): Date {
    return moment(this.schedule.startDate).toDate()
  }

  @computed
  public get endDate(): Date {
    return moment(this.schedule.endDate).toDate()
  }

  @action
  public setDueDate(date: Date) {
    this.dueAfter.setDate(date)
  }

  @action
  public setSpecificDueDate() {
    // this.specificDate = true
    // this.dueAfterSetPeriod = false
    this.dueAfter.setMode('specific')
    // this.dueAfter.afterEnabled = false
  }

  @action
  public setAfterSetPeriod() {
    this.dueAfter.setMode('period')
    // this.specificDate = false
    // this.dueAfterSetPeriod = true
    // this.dueAfter.toggleAfterIsEnabled()
    // this.dueAfter.dateEnabled = false
  }

  @action
  public setDueTime(hour: number, minute: number) {
    const newDate = moment(this.dueAfter.dueDate).hour(hour).minute(minute)
    this.setDueDate(newDate.toDate())
  }

  @action
  public setDueTimeZone(val) {
    this.dueAfter.dueTimeZone = val
  }

  public clone(): Survey {
    return deserialize(Survey, this.serialize())
  }

  public serialize(): ISurveyDTO {
    try {
      const dto = serialize(this)
      return dto
    } catch (e) {
      console.error(e)
      console.log(this)
    }
  }

  @action
  public addVisibleParticipant(participant: IParticipant) {
    this.visibleParticipants.push(VisibleParticipant.create(participant))
  }

  @action
  public updateVisibleParticipants(participants) {
    this.visibleParticipants = participants
  }

  @action
  public addAdditionalResponseNotificationParticipant(participant: IParticipant) {
    this.additionalResponseNotificationParticipants.push(
      AdditionalResponseNotificationParticipant.create(participant)
    )
  }

  @action
  public updateAdditionalResponseNotificationParticipants(participants) {
    console.log(participants)
    this.additionalResponseNotificationParticipants = participants
  }
}
