import React, { FC, CSSProperties } from 'react'
import { observer, inject } from 'mobx-react'
import { CategoryPickerVM } from './CategoryPickerVM'
import DropdownTreeSelect, { Mode, ShowDropdownState } from 'react-dropdown-tree-select'
import {
  AppBar,
  Button,
  Chip,
  Dialog,
  IconButton,
  Paper,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { QuestionVM } from '../../surveys/view-models/QuestionVM'
import 'react-dropdown-tree-select/dist/styles.css'
import './CategoryPicker.scss'
import { LabelsStore } from 'src/app/labels/store/LabelsStore'

export interface CategoryPickerProps {
  containerStyle?: CSSProperties
  mode?: Mode
  disabled?: boolean
  onApply?: (selectedId: any) => void
  placeholder?: string
  showDropdown?: ShowDropdownState
  onChipDelete?: (id: string) => void
  vm: CategoryPickerVM
  currentQuestionVM?: QuestionVM
  error?: boolean
  fullWidth?: boolean
  labelsStore?: LabelsStore
  renderCustomButtonComponent?: () => React.ReactNode
}

const CategoryPicker: FC<CategoryPickerProps> = ({
  containerStyle,
  mode,
  disabled,
  onApply,
  placeholder,
  showDropdown,
  onChipDelete,
  vm,
  currentQuestionVM,
  error,
  fullWidth,
  labelsStore,
  renderCustomButtonComponent,
}) => {
  if (!vm) return null
  if (currentQuestionVM) vm.setCurrentQuestionVM(currentQuestionVM)

  const renderSingleSelectButton = () => {
    if (mode === 'multiSelect') return null
    return (
      <Button
        disabled={disabled}
        variant='outlined'
        onClick={() => vm.toggleShowPicker()}
        className={`select-button ${vm.appliedCategory ? 'select-button-with-clear' : null}  ${
          error ? 'border-error' : ''
        }`}
      >
        Select
      </Button>
    )
  }

  const renderMultiSelectArrow = () => {
    if (mode !== 'multiSelect') return null
    return (
      <IconButton
        disabled={disabled}
        onClick={() => vm.toggleShowPicker()}
        className={`select-arrow  ${error ? 'border-error' : ''}`}
      >
        <ArrowDropDownIcon />
      </IconButton>
    )
  }

  const renderPicker = () => {
    if (!vm.showPicker) return null
    return (
      <Dialog open={vm.showPicker} onClose={() => vm.toggleShowPicker()} maxWidth={'md'} fullWidth>
        <Paper elevation={3} className='category-picker-modal'>
          <AppBar color='default' position='relative' elevation={0}>
            <Toolbar>
              <Typography variant='h5'>
                Select{` `}
                {mode !== 'multiSelect' && labelsStore.getLabelByLanguageAndFor('category')}
                {mode == 'multiSelect' && labelsStore.getLabelByLanguageAndFor('categories')}
              </Typography>
            </Toolbar>
          </AppBar>
          <div className='picker-actions'>
            <Button
              onClick={() => {
                vm.toggleShowPicker()
                vm.selectedCategoryIds = vm.appliedCategoryIds.slice()
              }}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              onClick={() => {
                if (vm.currentQuestionVM) {
                  vm.currentQuestionVM.setCategory(vm.selectedCategoryId)
                } else if (mode === 'multiSelect') {
                  onApply(vm.selectedCategoryIds)
                  vm.setAppliedCategories()
                } else {
                  onApply(vm.selectedCategoryId)
                  vm.setAppliedCategory(vm.selectedCategoryId)
                }
                vm.toggleShowPicker()
              }}
            >
              Apply
            </Button>
          </div>
          <div className='picker-modal-content'>
            <DropdownTreeSelect
              className='picker-modal-content-tree'
              data={vm.data}
              onChange={(selected) => {
                if (mode === 'multiSelect') {
                  vm.handleSelectOne(selected, false)
                } else {
                  vm.setSelectedCategoryId(selected.id)
                }
              }}
              texts={{
                placeholder: placeholder ? placeholder : 'Search',
              }}
              mode={mode ? mode : 'radioSelect'}
              showDropdown={showDropdown ? showDropdown : 'always'}
              keepTreeOnSearch
              keepOpenOnSelect
              keepChildrenOnSearch
              onNodeToggle={(node) => vm.handleNodeToggle(node)}
            />
          </div>
        </Paper>
      </Dialog>
    )
  }

  const renderCategories = () => {
    if (mode !== 'multiSelect') return null
    return (
      <Typography
        component='div'
        variant='body1'
        className={`category-name-display categories-overflow ${error ? 'border-error' : ''}`}
        onClick={() => {
          if (disabled) return
          vm.toggleShowPicker()
        }}
      >
        {vm.appliedCategories.map((cat, index) => {
          return (
            <Chip
              disabled={disabled}
              onClick={(e) => e.stopPropagation()}
              key={index}
              className='chip'
              label={cat.name}
              onDelete={() => {
                onChipDelete(cat.id)
                vm.removeCategory(cat.id)
              }}
            />
          )
        })}
      </Typography>
    )
  }

  const renderSingleCategory = () => {
    if (mode === 'multiSelect') return null
    return (
      <Tooltip
        title={vm.appliedCategory && vm.appliedCategory.pathName ? vm.appliedCategory.pathName : ''}
        placement={vm.currentQuestionVM ? 'bottom-start' : undefined}
      >
        <Typography
          variant='body1'
          component='div'
          className={`category-name-display single-select  ${error ? 'border-error' : ''}`}
          noWrap
          onClick={() => {
            if (disabled) return
            vm.toggleShowPicker()
          }}
        >
          {vm.appliedCategoryName}
        </Typography>
      </Tooltip>
    )
  }

  if (renderCustomButtonComponent) {
    return (
      <div
        className={`category-picker-container ${
          vm.currentQuestionVM || fullWidth ? 'category-picker-container-full-width' : null
        } `}
        style={containerStyle ? containerStyle : null}
      >
        {renderCustomButtonComponent()}
        {renderPicker()}
      </div>
    )
  }

  return (
    <div
      className={`category-picker-container ${
        vm.currentQuestionVM || fullWidth ? 'category-picker-container-full-width' : null
      } ${mode !== 'multiSelect' ? 'container-single-select' : null}`}
      style={containerStyle ? containerStyle : null}
    >
      {renderCategories()}
      {renderSingleCategory()}
      {renderSingleSelectButton()}
      {renderMultiSelectArrow()}
      {vm.appliedCategory ? (
        <Button
          disabled={disabled}
          variant='outlined'
          onClick={() => {
            onApply('')
            if (vm.appliedCategoryId) vm.appliedCategoryId = ''
            if (vm.currentQuestionVM && vm.currentQuestionVM.categoryId)
              vm.currentQuestionVM.setCategory('')
          }}
          className='clear-button'
        >
          Clear
        </Button>
      ) : null}
      {renderPicker()}
    </div>
  )
}

export default inject('labelsStore')(observer(CategoryPicker))
