import React from 'react'
import { observer, inject } from 'mobx-react'
import { CardHeader, IconButton, Card, Button } from '@material-ui/core'
import Popover from '@material-ui/core/Popover'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import CloseIcon from '@material-ui/icons/Close'
import { OrganizationUsersListTableVM } from '../../view-models/list/OrganizationUsersListTableVM'
import ParticipantsSelect from '../../../participants-select/views/ParticipantsSelect'
import './FilterPopup.scss'

interface FilterPopupProps {
  vm: OrganizationUsersListTableVM
  localizationStore?: LocalizationStore
}

const FilterPopup: React.FC<FilterPopupProps> = ({ vm, localizationStore }) => {
  if (!vm) return null
  const lz = localizationStore.lzStrings.orgUsersList

  const close = () => vm.setFilterPopupAnchorEl(null)

  const renderCardHeader = () => {
    return (
      <>
        <CardHeader
          title={
            <div className='card-header-title-container'>
              <div>{lz.filter_users}</div>
              <div>
                <IconButton onClick={() => close()}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          }
          className='filter-popover-card-header'
        />
        <div className='org-users-apply'>
          <Button
            variant='contained'
            style={{ margin: '5px' }}
            onClick={() => vm.applyParticipantSelections()}
          >
            {lz.apply}
          </Button>
        </div>
      </>
    )
  }

  const renderCardContent = () => {
    return (
      <div>
        <ParticipantsSelect participantsSelectVM={vm.participantsSelectVM} />
      </div>
    )
  }

  return (
    <Popover
      open={Boolean(vm.filterPopupAnchorEl)}
      anchorEl={vm.filterPopupAnchorEl}
      onClose={() => close()}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Card id='OrgUsersListFilterPopover'>
        {renderCardHeader()}
        {renderCardContent()}
      </Card>
    </Popover>
  )
}

export default inject('surveysStore', 'localizationStore')(observer(FilterPopup))
