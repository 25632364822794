import React from 'react'
import clsx from 'clsx'
import { inject, observer } from 'mobx-react'
import { ButtonBase } from '@material-ui/core'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { UserTrainingPlansStore } from '../../store/UserTrainingPlansStore'

interface WidgetTitleProps {
  localizationStore?: LocalizationStore
  userTrainingPlansStore?: UserTrainingPlansStore
}

const Subtitle: React.FC<WidgetTitleProps> = ({ localizationStore, userTrainingPlansStore }) => {
  const { training_plans: strings } = localizationStore.lzStrings
  const { myTrainingPlansWidgetVM: vm } = userTrainingPlansStore

  if (!vm) return
  if (vm.tabIndex === 1) return

  return (
    <div className='widget-title-tabs'>
      <div className='tab-btns'>
        <ButtonBase
          onClick={() => vm.toggleShowTimeline()}
          className={clsx('tab-button', {
            active: vm.showTimeline,
          })}
        >
          {strings.timeline}
        </ButtonBase>
        <ButtonBase
          onClick={() => vm.toggleShowTimeline()}
          className={clsx('tab-button', {
            active: !vm.showTimeline,
          })}
        >
          {strings.list}
        </ButtonBase>
      </div>
    </div>
  )
}

export default inject('localizationStore', 'userTrainingPlansStore')(observer(Subtitle))
