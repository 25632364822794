import React from 'react'
import { SystemAdminsStore } from '../../store/SystemAdminsStore'
import { inject, observer } from 'mobx-react'
import {
  Drawer,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  OutlinedInput,
  InputLabel,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import MuiDrawerToolbar from '../../../shared/Mui-Drawer/MuiDrawerToolbar'
import LocalizationStore from '../../../localization/LocalizationStore'
import './AddSystemAdminDrawer.scss'

interface AddSystemAdminDrawerProp {
  systemAdminsStore?: SystemAdminsStore
}

const SystemAdminDrawer: React.FC<AddSystemAdminDrawerProp> = ({ systemAdminsStore }) => {
  const { newVM: vm } = systemAdminsStore
  if (!vm) return null

  return (
    <Drawer anchor='right' open={vm.isOpen} onClose={() => vm.toggleDrawer()}>
      <Card id='AddSystemAdminDrawer' elevation={0}>
        <CardHeader
          action={
            <IconButton onClick={() => vm.toggleDrawer()}>
              <CloseIcon />
            </IconButton>
          }
          title='Add System Administrator'
        />
        <MuiDrawerToolbar
          handleSubmitClick={e => vm.save()}
          handleCancelClick={() => vm.toggleDrawer()}
        />
        <CardContent className='editor-container'>
          <h6>System Administrator Details</h6>
          <form>
            <InputLabel htmlFor='email'>Email Address</InputLabel>
            <OutlinedInput
              id='email'
              required
              fullWidth
              labelWidth={0}
              autoComplete='off'
              value={vm.email}
              onChange={e => vm.setEmail(e.target.value)}
              error={!vm.emailNotValid}
            />
          </form>
        </CardContent>
      </Card>
    </Drawer>
  )
}

export default inject('systemAdminsStore')(observer(SystemAdminDrawer))
