import { RootStore } from '../../stores/RootStore'
import { observable, computed, action } from 'mobx'
import { Quiz } from '../aggregate/Quiz'
import { QuestionTakeVM } from './QuestionTakeVM'
import { QuizResultsVM } from '../../quiz-results/view-models/QuizResultsVM'
import { TrainingPlan } from '../../training-plans/aggregates/TrainingPlan'
import { UserTrainingPlan } from '../../training-plans/user-training-plans/aggregate/UserTrainingPlan'

export class QuizTakeVM {
  private rootStore: RootStore
  public quiz: Quiz
  public parentId: string

  constructor(
    rootStore,
    quiz: Quiz,
    userTrainingPlan: UserTrainingPlan,
    parentId: string,
    submitQuiz: Function
  ) {
    this.rootStore = rootStore
    this.quiz = quiz
    this.parentId = parentId
    this.userTrainingPlan = userTrainingPlan
    this.submitQuiz = submitQuiz
    this.resultsVM = new QuizResultsVM(this.rootStore, this.quiz, this.id, this.userTrainingPlan.trainingPlanId)
  }

  @observable public isSubmitting: boolean = false
  @observable public submitQuiz: Function = null
  @observable public userTrainingPlan: UserTrainingPlan = null
  @observable public showConfirmDialog: boolean = false
  @observable public resultsVM: QuizResultsVM = null

  @computed
  public get questions(): QuestionTakeVM[] {
    return this.quiz.questions.map((question) => new QuestionTakeVM(question))
  }

  @computed
  public get id(): string {
    return this.quiz.id
  }

  @computed
  public get isComplete(): boolean {
    return this.quiz.isComplete
  }

  @computed
  public get canSubmit(): boolean {
    if (!this.userTrainingPlan.objectId) return false
    if (this.questions.some((question) => question.answers.length === 0)) return false
    return true
  }

  @computed
  public get numberOfQuestionsAnswered(): number {
    return this.questions.filter((question) => {
      if (!question.hasAnswer) return false
      return true
    }).length
  }

  @computed
  public get numberOfQuestions(): number {
    return this.questions.length
  }

  @computed
  public get questionsCorrect(): number {
    const questions = []
    this.questions.forEach((question) => {
      if (question.type === 'text') questions.push(question.id)
      if (question.isCorrect) questions.push(question.id)
    })
    return questions.length
  }

  @computed
  public get questionsIncorrect(): number {
    const questions = []
    this.questions.forEach((question) => {
      if (!question.isCorrect && question.type !== 'text') questions.push(question.id)
    })
    return questions.length
  }

  @computed
  public get score(): number {
    return Math.ceil((this.questionsCorrect / this.numberOfQuestions) * 100)
  }

  @computed
  public get showQuizResults() {
    if (!this.userTrainingPlan.showQuizResults) return false
    if (!this.quiz.isComplete) return false
    return true
  }

  @action
  public toggleShowConfirmDialog() {
    return (this.showConfirmDialog = !this.showConfirmDialog)
  }

  @action
  public submit() {
    this.quiz.completeQuiz(this.score)
    this.submitQuiz(this.quiz)
    this.toggleShowConfirmDialog()
  }

  @action
  public reset() {
    this.questions.forEach((question) => {
      question.options.forEach((option) => {
        option.setChecked(false)
        if (option.type === 'text') option.setText('')
      })
    })
  }
}
