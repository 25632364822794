import React from 'react'
import { observer, inject } from 'mobx-react'
import { WorksheetsStore } from '../../store/WorksheetsStore'
import ParticipantsSelect from '../../../participants-select/views/ParticipantsSelect'
import './PermissionsTabPanel.scss'

interface Props {
  worksheetsStore?: WorksheetsStore
}

const PermissionsTabPanel: React.FC<Props> = ({ worksheetsStore }) => {
  const { editVM } = worksheetsStore
  if (!editVM) return null
  return (
    <>
      <div id='PermissionsTabPanel'>
        <ParticipantsSelect participantsSelectVM={editVM.permissionsSelectVM} />
      </div>
    </>
  )
}

export default inject('worksheetsStore')(observer(PermissionsTabPanel))
