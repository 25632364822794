import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { inject, observer } from 'mobx-react'
import { Box, Grid } from '@material-ui/core'
import './TabsBarV2.scss'
import { TabEnums } from '../../types/TabsEnums'
import ChartIcon from '@material-ui/icons/InsertChart'
import { TrainingPlansStore } from '../../store/TrainingPlansStore'
import DetailsTabPanel from './details/DetailsTabPanel'
import ParticipantsTabPanel from './participants/ParticipantsTabPanel'
import BuilderTabPanel from './builder/BuilderTabPanel'
import PreviewTabPanel from './preview/PreviewTabPanel'
import CompletionTabPanel from './completion/CompletionTabPanel'
import clsx from 'clsx'
import InfoIcon from '@material-ui/icons/Info'
import TuneIcon from '@material-ui/icons/Tune'
import PersonPinIcon from '@material-ui/icons/PersonPin'
import PreviewIcon from '@material-ui/icons/RemoveRedEye'
import Menu from './Menu'
import CheckIcon from '@material-ui/icons/Check'
import LocalizationStore from '../../../localization/LocalizationStore'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div hidden={value !== index} {...other}>
      <Box component='div'>{children}</Box>
    </div>
  )
}

interface Props {
  trainingPlansStore?: TrainingPlansStore
  localizationStore?: LocalizationStore
}

const TabsBar: React.FC<Props> = ({ trainingPlansStore, localizationStore }) => {
  const { trainingPlanEditVM: vm } = trainingPlansStore
  const { training_plans: lz } = localizationStore.lzStrings
  if (!vm) return null

  const renderAssignTab = () => {
    if (vm.isCatalogPlan) return null
    return (
      <Step
        key='3'
        active={vm.currentTab === 3}
        completed={vm.assignTabValid && vm.tabFourHasOpened}
        className={vm.tabsDisabled ? 'disabled' : null}
      >
        <StepLabel
          error={!vm.assignTabValid}
          disabled={vm.tabsDisabled}
          onClick={() => vm.setCurrentTab(3)}
          StepIconComponent={ColorlibStepIcon}
        >
          {lz.assign}
        </StepLabel>
      </Step>
    )
  }

  const useColorlibStepIconStyles = makeStyles({
    root: {
      color: '#68686A',
      backgroundColor: '#C1C1C9',
      zIndex: 1,
      // color: '#fff',
      width: 35,
      height: 35,
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
  })

  function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles()
    const { active, completed, error } = props

    const icons = {
      1: vm.detailsTabValid && vm.tabOneHasOpened ? <CheckIcon /> : <InfoIcon />,
      2: vm.builderTabValid && vm.tabTwoHasOpened ? <CheckIcon /> : <ChartIcon />,
      3: vm.quizValid && vm.tabThreeHasOpened ? <CheckIcon /> : <TuneIcon />,
      4: vm.isCatalogPlan ? (
        <PreviewIcon />
      ) : vm.assignTabValid && vm.tabFourHasOpened ? (
        <CheckIcon />
      ) : (
        <PersonPinIcon />
      ),
      5: <PreviewIcon />,
    }

    return (
      <div
        className={clsx(classes.root, {
          active: active,
          completed: completed,
          error: error,
        })}
      >
        {icons[String(props.icon)]}
      </div>
    )
  }

  return (
    <div id='StepperBar'>
      <div className='stepper-root'>
        <Grid container>
          <Grid item xs={10}>
            <Stepper nonLinear>
              <Step
                key='0'
                active={vm.currentTab === 0}
                completed={vm.detailsTabValid && vm.tabOneHasOpened}
              >
                <StepLabel
                  StepIconComponent={ColorlibStepIcon}
                  onClick={() => vm.setCurrentTab(0)}
                  error={!vm.detailsTabValid}
                >
                  {lz.details}
                </StepLabel>
              </Step>

              <Step
                key='1'
                active={vm.currentTab === 1}
                completed={vm.builderTabValid && vm.tabTwoHasOpened}
              >
                <StepLabel
                  error={!vm.builderTabValid}
                  onClick={() => vm.setCurrentTab(1)}
                  StepIconComponent={ColorlibStepIcon}
                  //   icon={<InfoIcon />}
                >
                  {lz.builder}
                </StepLabel>
              </Step>

              <Step
                key='2'
                active={vm.currentTab === 2}
                completed={vm.completionTabCompleted}
                onClick={() => vm.setCurrentTab(2)}
                className={vm.tabsDisabled ? 'disabled' : null}
              >
                <StepLabel
                  error={!vm.quizValid}
                  StepIconComponent={ColorlibStepIcon}
                >
                  {lz.completion}
                </StepLabel>
              </Step>

              {renderAssignTab()}

              <Step
                key='4'
                active={vm.currentTab === 4}
                className={vm.tabsDisabled ? 'disabled' : null}
              >
                <StepLabel
                  disabled={vm.tabsDisabled}
                  onClick={() => vm.setCurrentTab(4)}
                  StepIconComponent={ColorlibStepIcon}
                >
                  {lz.preview}
                </StepLabel>
              </Step>
            </Stepper>
          </Grid>
          <Grid container item xs={2} id='ToolbarMenu' alignItems='center'>
            <Grid container justifyContent='flex-end' alignItems='center'>
              <Menu />
            </Grid>
          </Grid>
        </Grid>
        <div>
          <TabPanel key={TabEnums.DETAILS} value={vm.currentTab} index={TabEnums.DETAILS}>
            <DetailsTabPanel />
          </TabPanel>
          <TabPanel key={TabEnums.BUILDER} value={vm.currentTab} index={TabEnums.BUILDER}>
            <BuilderTabPanel />
          </TabPanel>
          <TabPanel key={TabEnums.COMPLETION} value={vm.currentTab} index={TabEnums.COMPLETION}>
            <CompletionTabPanel />
          </TabPanel>
          <TabPanel key={TabEnums.PARTICIPANTS} value={vm.currentTab} index={TabEnums.PARTICIPANTS}>
            <ParticipantsTabPanel />
          </TabPanel>
          <TabPanel key={TabEnums.PREVIEW} value={vm.currentTab} index={TabEnums.PREVIEW}>
            <PreviewTabPanel />
          </TabPanel>
        </div>
      </div>
    </div>
  )
}

export default inject('trainingPlansStore', 'localizationStore')(observer(TabsBar))
