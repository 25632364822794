import { RootStore } from '../../../stores/RootStore'
import { observable, autorun, computed, action } from 'mobx'
import { PagedDataVM } from '../../../shared/view-models/PagedDataVM'
import { SystemAdminService } from '../../service/SystemAdminService'
import { SystemAdminRowVM } from './SystemAdminRowVM'

export class SystemAdminsListWidgetVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.pagedData = new PagedDataVM(
      this.rootStore,
      [],
      ['name', 'email'],
      '',
      'SystemAdminsListWidget',
      ''
    )
    autorun(() => this.pagedData.setData(this.rows), {
      delay: 1,
    })
  }

  @observable public keywordFilter: string = ''
  @observable public deleteConfirmShown: boolean = false
  @observable public deletingUserId: string = ''

  @computed public get isLoading(): boolean {
    if (!this.rootStore.systemAdminsStore) return true
    return this.rootStore.systemAdminsStore.isLoading
  }

  @computed public get rows(): SystemAdminRowVM[] {
    return this.rootStore.systemAdminsStore.systemAdmins.map(
      e => new SystemAdminRowVM(this.rootStore, this, e)
    )
  }
  @computed public get deletingUser(): SystemAdminRowVM {
    return this.rows.find(e => e.objectId === this.deletingUserId)
  }

  @action
  public async deleteSystemAdmin() {
    this.toggleDeleteDialog()
    const svc = new SystemAdminService()
    const response = await svc.removeSystemAdmin(this.deletingUserId)
    if (response !== 'success') alert('Unable to remove System Admin')
    this.rootStore.systemAdminsStore.loadSystemAdmins()
  }

  @action setKeywordFilter(val) {
    this.keywordFilter = val
    this.pagedData.setFilter(this.keywordFilter)
  }

  @action
  public openAddDrawer() {
    this.rootStore.systemAdminsStore.newVM.toggleDrawer()
  }

  @action
  public toggleDeleteDialog() {
    this.deleteConfirmShown = !this.deleteConfirmShown
  }

  @action
  public showDeleteDialog(userId: string) {
    this.deletingUserId = userId
    this.deleteConfirmShown = true
  }

  @observable public pagedData: PagedDataVM = null
}
export default SystemAdminsListWidgetVM
