import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../localization/LocalizationStore'
import { QuestionsStore } from '../store/QuestionsStore'
import { InputLabel, MenuItem, Select } from '@material-ui/core'
import Icon from '../../surveys/views/edit/builder/Icon'
import { QuestionType } from '../types/QuestionTypes'
import './DrawerQuestionTypeSection.scss'
interface DrawerQuestionTypeSectionProps {
  localizationStore?: LocalizationStore
  questionsStore?: QuestionsStore
}

const DrawerQuestionTypeSection: React.FC<DrawerQuestionTypeSectionProps> = ({
  localizationStore,
  questionsStore,
}) => {
  const { lzStrings } = localizationStore
  const { editVM: QuestionCatalogWidgetForm } = questionsStore

  const options = ['emoji', 'check', 'radio', 'rating', 'starRating', 'text', 'yesNo', 'number', 'order', 'dropdown']

  const renderQuestionTypes = () => {
    return options.map((type, index) => {
      let icon = ''
      let color = ''
      let label = ''
      if (type === 'emoji') {
        icon = 'far fa-smile'
        color = 'brown'
        label = 'Emoji'
      }
      if (type === 'check') {
        icon = 'far fa-check-square'
        color = 'blue'
        label = 'Multi-select'
      }
      if (type === 'radio') {
        icon = 'far fa-dot-circle'
        color = 'green'
        label = 'Multiple Choice'
      }
      if (type === 'rating') {
        icon = 'fas fa-list-ol'
        color = 'orange'
        label = 'Rating'
      }
      if (type === 'starRating') {
        icon = 'fas fa-star'
        color = 'yellow'
        label = 'Star Rating'
      }
      if (type === 'text') {
        icon = 'fas fa-font'
        color = 'red'
        label = 'Short Answer'
      }
      if (type === 'yesNo') {
        icon = 'far fa-thumbs-up'
        color = 'purple'
        label = 'Yes or No'
      }
      if (type === 'number') {
        icon = 'fas fa-calculator'
        color = 'orange'
        label = 'Number'
      }
      if (type === 'order') {
        icon = 'fas fa-sort-amount-up'
        color = 'pink'
        label = 'Order'
      }
      if (type === 'dropdown') {
        icon = 'fas fa-caret-square-down'
        color = 'green'
        label = 'Dropdown'
      }

      return (
        <MenuItem key={index} value={type} className={`type-menu-item ${color}`}
          onClick={e => {
            QuestionCatalogWidgetForm.setQuestionType(type as QuestionType)
          }}>
          <Icon icon={icon} className='icon' />
          {label}
        </MenuItem>
      )
    })
  }

  const handleSelect = (e) => {
    if (e) {
      QuestionCatalogWidgetForm.setQuestionType(e.target.value)
    } else {
      QuestionCatalogWidgetForm.clearOptions()
    }
  }

  return (
    <section>
      <InputLabel required htmlFor='questionType'>
        {lzStrings.questionsCatalogWidgetDrawer.question_type}
      </InputLabel>
      <Select
        id='QuestionType'
        variant='outlined'
        value={QuestionCatalogWidgetForm.questionType}
        onChange={handleSelect}
        error={QuestionCatalogWidgetForm.questionTypeError}
      >
        {renderQuestionTypes()}
      </Select>
    </section>
  )
}

export default inject('localizationStore', 'questionsStore')(observer(DrawerQuestionTypeSection))
