import Parse from 'parse'
import { ITrainingPlanDTO } from '../dtos/ITrainingPlanDTO'

export class TrainingPlansUpdateService {
  public async saveTrainingPlan(trainingPlan: ITrainingPlanDTO) {
    return await Parse.Cloud.run('saveTrainingPlan', { trainingPlan })
  }
  public async deleteTrainingPlan(orgId: string, id: string) {
    return await Parse.Cloud.run('deleteTrainingPlan', { id, orgId })
  }

  public async saveCatalogPlan(trainingPlan: ITrainingPlanDTO) {
    return await Parse.Cloud.run('saveCatalogPlan', { trainingPlan })
  }

  public async deleteCatalogPlan(id: string, orgId: string) {
    return await Parse.Cloud.run('deleteCatalogPlan', { id, orgId })
  }

  public async archiveTrainingPlan(orgId: string, id: string) {
    return await Parse.Cloud.run('archiveTrainingPlan', { id, orgId })
  }

  public async restoreTrainingPlan(orgId: string, id: string) {
    return await Parse.Cloud.run('restoreTrainingPlan', { id, orgId })
  }

  public async refreshInDW(orgId: string, id: string) {
    return await Parse.Cloud.run('refreshTrainingPlanInDW', { id, orgId })
  }
}
