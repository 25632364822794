import React from 'react'
import { observer, inject } from 'mobx-react'
import { CardHeader } from '@material-ui/core'
import Searchbar from '../../../shared/Searchbar'
import LocalizationStore from '../../../localization/LocalizationStore'
import { EmailTemplatesStore } from '../../store/EmailTemplatesStore'
import { EmailTemplatesTableVM } from '../../view-models/EmailTemplatesTableVM'
import './EmailTemplatesTableHeader.scss'

interface Props {
  emailTemplatesStore?: EmailTemplatesStore
  localizationStore?: LocalizationStore
  vm: EmailTemplatesTableVM
}

const EmailTemplatesTableHeader: React.FC<Props> = ({ emailTemplatesStore, localizationStore, vm }) => {
  if (!vm) return null

  const lz = localizationStore.lzStrings.emailTemplateManagement

  return (
    <CardHeader
      id='TableHeader'
      title={lz.email_templates}
      action={
        <>
          <div className='search'>
            <Searchbar
              focused={vm.hasFilter}
              searchValue={vm.typedFilterText}
              onSearchChange={(e) => {
                vm.setQuickFilter(e.target.value)
              }}
            />
          </div>
        </>
      }
    ></CardHeader>
  )
}

export default inject('emailTemplatesStore', 'localizationStore')(observer(EmailTemplatesTableHeader))
