import { action, computed, observable } from 'mobx'
import { IPulseCategoryDTO } from '../dtos/IPulseCategoryDTO'
import { RootStore } from '../../stores/RootStore'
import { ParentCategoryRowVM } from './ParentCategoryRowVM'
import { PulseCategoriesManageVM } from './PulseCategoriesManageVM'
import { PulseCategoriesDataStore } from './PulseCategoriesDataStore'
import { IPulseCategoriesFindRequest } from '../interfaces/IPulseCategoriesFindRequest'
import { PulseCategoryEditVM } from './PulseCategoryEditVM'
import PulseCategory from '../aggregate/PulseCategory'


export class GrandParentCategoryRowVM {
  private rootStore: RootStore
  private dataStore: PulseCategoriesDataStore

  constructor(rootStore: RootStore, manageVM: PulseCategoriesManageVM, category: IPulseCategoryDTO) {
    this.rootStore = rootStore
    this.manageVM = manageVM
    this.category = category
    this.dataStore = new PulseCategoriesDataStore(this.rootStore, { grandCategoryId: this.objectId } as IPulseCategoriesFindRequest)
    this.loadDataStore()
  }

  @observable public category: IPulseCategoryDTO = null
  @observable public manageVM: PulseCategoriesManageVM
  @observable public index: number = 0

  @action
  private loadDataStore() {
    this.dataStore.loadListRecords()
  }

  @action
  public setIndex(index: number) {
    this.index = index
  }

  @computed
  public get name(): string {
    return this.category.name
  }

  @computed
  public get objectId(): string {
    return this.category.objectId
  }

  @computed
  public get parentPulseCategories(): ParentCategoryRowVM[] {
    const categories = this.dataStore.rows.filter((c: IPulseCategoryDTO) => c.grandCategoryId === this.objectId)
    return categories.map((c) => {
      const parent = new ParentCategoryRowVM(this.rootStore, this.manageVM, c)
      parent.setIndex(this.index)
      return parent
    })
  }

  @action
  public createNewPulseCategory() {
    this.rootStore.pulseCategoriesStore.viewModels.editVM = new PulseCategoryEditVM(this.rootStore, PulseCategory.create(this.objectId, undefined))
  }

  @action
  public editPulseCategory() {
    this.rootStore.pulseCategoriesStore.viewModels.loadPulseCategoryEditVM(this.objectId)
  }

}
