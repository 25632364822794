import React from 'react'
import { TrainingItemsStore } from '../../store/TrainingItemsStore'
import { ContentItem } from '../../aggregate/ContentItem'
import { inject, observer } from 'mobx-react'
import { Typography, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import './ContentCard.scss'
import { ContentItemEditVM } from '../../view-models/ContentItemEditVM'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import LinkIcon from '@material-ui/icons/Link'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import PermMediaIcon from '@material-ui/icons/PermMedia'
import PanToolIcon from '@material-ui/icons/PanTool'
import ExerciseIcon from '@material-ui/icons/FitnessCenterRounded'

interface Props {
  trainingItemsStore?: TrainingItemsStore
  content: ContentItemEditVM
  index: number
}

const ContentCard: React.FC<Props> = ({ trainingItemsStore, content, index }) => {
  const { trainingItemEditVM: vm } = trainingItemsStore
  if (!vm) return null

  const renderIcon = () => {
    if (!content.type) return null
    if (content.type === 'Link') return <LinkIcon />
    if (content.type === 'File') return <AttachFileIcon />
    if (content.type === 'Media') return <PermMediaIcon />
    if (content.type === 'Hands-on') return <PanToolIcon />
    if (content.type === 'Exercise') return <ExerciseIcon />
  }

  return (
    <div
      id='ContentCard'
      className={`${content.isActive ? 'active-content-card card' : 'card'} ${
        content.isValid ? '' : 'content-card-error'
      }`}
      onClick={() => vm.openContentItem(content.id)}
    >
      <div className={content.isActive ? 'content-number active' : 'content-number'}>
        <div className='drag-indicator'>
          <DragIndicatorIcon />
          <Typography variant='h5'>{index + 1}</Typography>
        </div>
      </div>
      <div className='content-body'>
        <div className='add-content'>
          <Typography variant='h5' className={content.title ? '' : 'title-placeholder'}>
            {content.title ? content.title : '(Untitled)'}
          </Typography>
          <Typography variant='h5' className='content-type'>
            {renderIcon()}
            {content.type}
          </Typography>
        </div>
        <IconButton onClick={() => vm.deleteContentItem(content.id)}>
          <CloseIcon className='close-icon' />
        </IconButton>
      </div>
    </div>
  )
}

export default inject('trainingItemsStore')(observer(ContentCard))
