import { action, observable, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import PrivilegeSet from '../aggregate/PrivilegeSet'
import { ParseService } from '../../services/ParseService'
import Privilege from '../../stores/privileges/aggregate/Privilege'

export class PrivilegeSetDrawerVM {
  rootStore: RootStore

  constructor(rootStore: RootStore, privilegeSet: PrivilegeSet = null) {
    this.rootStore = rootStore
    if (privilegeSet) this.loadData(privilegeSet)
  }

  private loadData(privilegeSet: PrivilegeSet) {
    this.objectId = privilegeSet.objectId
    this.name = privilegeSet.name
    this.description = privilegeSet.description
    this.privileges = privilegeSet.privileges
  }

  @observable objectId: string = ''
  @observable name: string = ''
  @observable description: string = ''
  @observable privileges: Array<any> = []
  @observable saveTried: boolean = false

  @action
  public markSaveTried() {
    this.saveTried = true
  }

  @action
  public toggleCheckedPriv(privilegeId) {
    let privileges = JSON.parse(JSON.stringify(this.privileges))
    if (this.privileges.includes(privilegeId)) {
      privileges = privileges.filter((e) => e !== privilegeId)
      this.privileges = privileges
    } else {
      privileges.push(privilegeId)
      this.privileges = privileges
    }
  }

  @computed
  public get isEditMode() {
    return Boolean(this.objectId)
  }

  @computed
  public get errorHelperText() {
    if (!this.nameValid) return 'Please enter a name for this privilege set.'
    if (!this.nameUnique) return 'This name is being used by another privilege set.'
  }

  @computed
  public get availablePrivilegs(): Array<Privilege> {
    return this.rootStore.privilegesStore.privileges.filter((e) => {
      if (
        e.objectId === 'can_impersonate_users' &&
        !this.rootStore.organizationsStore.currentOrganization.canImpersonateUsers
      ) {
        return false
      }
      return true
    })
  }

  @computed
  public get nameValid() {
    if (!this.saveTried) return true
    if (!this.name || this.name.trim() === '') return false
    return true
  }

  @computed
  public get nameUnique() {
    if (!this.saveTried) return true
    const foundPrivilegeSet = this.rootStore.privilegeSetsStore.getPrivilegeSetByName(this.name)
    if (foundPrivilegeSet && foundPrivilegeSet.objectId !== this.objectId) return false
    return true
  }

  @computed
  public get isValid() {
    if (!this.saveTried) return true
    if (!this.nameValid) return false
    if (!this.nameUnique) return false
    return true
  }

  @action
  public async save() {
    this.markSaveTried()
    if (!this.isValid) return false
    this.rootStore.privilegeSetsStore.showDrawer = false

    try {
      const privilegeSetObj = {
        objectId: this.objectId ? this.objectId : null,
        name: this.name,
        description: this.description,
        privileges: this.privileges.slice(),
      }

      await new ParseService().savePrivilegeSet(
        this.rootStore.appStore.currentOrgId,
        privilegeSetObj
      )

      this.rootStore.privilegeSetsStore.clearForms()
    } catch (e) {
      console.error(e)
    }
  }

  @action
  public async delete() {
    try {
      this.rootStore.privilegeSetsStore.showDeleteDialog = false
      this.rootStore.privilegeSetsStore.showDrawer = false

      await new ParseService().deletePrivilegeSet(
        this.rootStore.appStore.currentOrgId,
        this.objectId
      )
    } catch (e) {
      console.error(e)
    }
  }
}
