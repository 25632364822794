import React from 'react'
import { inject, observer } from 'mobx-react'
import { GroupsStore } from '../store/GroupsStore'
import ParticipantsSelect from '../../participants-select/views/ParticipantsSelect'
import './GroupEditDrawer.scss'

interface Props {
  groupsStore?: GroupsStore
}

const GroupMembersList: React.FC<Props> = ({ groupsStore }) => {
  const { editVM: vm } = groupsStore

  return (
    <div id='GroupMembersList'>
      <ParticipantsSelect participantsSelectVM={vm.participantsSelectVM} />
    </div>
  )
}

export default inject('groupsStore')(observer(GroupMembersList))
