import { observable, action, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Contact } from '../aggregate/Contact'
import { Client } from '../../clients/aggregate/Client'
import { ParseService } from '../../services/ParseService'

export class ContactEditVM {
  constructor(rootStore: RootStore, contact?: Contact, client?: Client) {
    if (rootStore) this.rootStore = rootStore
    if (rootStore) this.userId = rootStore.appStore.currentUserId
    if (contact) this.loadData(contact)
    if (client) this.clientObj = client
    if (!client) this.clientObj = new Client()
    if (contact && rootStore) {
      this.clientId = contact.clientId
      this.clientObj = this.rootStore.clientsStore.getClient(contact.clientId)
      if (!this.clientObj) this.clientObj = new Client()
    }
  }

  loadData(contact) {
    ;(this.objectId = contact.objectId),
      (this.name = contact.name),
      (this.email = contact.email),
      (this.title = contact.title)
    this.organizationId = contact.organizationId
    this.contact = contact
  }

  private rootStore: RootStore
  public contact: Contact = null
  @observable public objectId: string = ''
  @observable public name: string = ''
  @observable public email: string = ''
  @observable public title: string = ''
  @observable public clientId: string = ''
  @observable public clientObj: Client = null
  @observable public organizationId: string = ''
  @observable public userId: string = ''
  @observable public deleteSuccessful: boolean = false
  @observable public saveSuccessful: boolean = false
  @observable public saveTried: boolean = false

  @computed
  public get isEditMode() {
    return Boolean(this.objectId)
  }

  @computed
  public get nameNotValid() {
    if (!this.saveTried) return false
    if (!this.name) return true
    return false
  }

  @computed
  public get emailNotValid() {
    if (!this.saveTried) return true
    let pattern = new RegExp(/[^\s@]+@[^\s@]+\.[^\s@]+/i)
    return pattern.test(this.email)
  }

  @computed
  public get emailDuplicate(): boolean {
    const foundContact = this.rootStore.contactsStore.getContactByEmail(this.email)
    if (foundContact) {
      if (foundContact.objectId === this.objectId) return false
      return true
    }
    return false
  }

  @computed
  public get error() {
    if (this.nameNotValid) return true
    if (!this.emailNotValid) return true
    if (this.emailDuplicate) return true
    return false
  }

  @computed
  public get saveEnabled(): boolean {
    if (!this.error) return true
  }

  @computed
  public get deleteEnabled(): boolean {
    return this.objectId.length > 0
  }

  @computed
  public get clientOptions(): Array<any> {
    let array = []
    this.rootStore.clientsStore.clients.forEach((client) => {
      array.push(client)
    })

    return array
  }

  @action
  public setName(val) {
    this.name = val
  }

  @action
  public setTitle(val) {
    this.title = val
  }

  @action
  public setEmail(val) {
    this.email = val
  }

  @action
  public setClient(val) {
    this.clientObj = val
  }

  @action
  public async saveContact() {
    this.saveTried = true

    if (this.error) return
    const contact = {
      userId: this.rootStore.appStore.currentUserId,
      organizationId: this.rootStore.appStore.currentOrgId,
      objectId: this.objectId.trim(),
      name: this.name.trim(),
      email: this.email.trim(),
      title: this.title.trim(),
      clientId: this.clientObj ? this.clientObj.objectId.trim() : null,
    }
    const svc = new ParseService()
    const result = await svc.saveContact(this.rootStore.appStore.currentOrgId, contact)
    if (result) {
      this.saveSuccessful = true
      if (this.clientId) this.rootStore.contactsStore.getContactsByClient(this.clientId)
      setTimeout(() => {
        this.rootStore.contactsStore.toggleWidgetDrawer()
      }, 1000)
    }
  }

  @action
  public async deleteContact() {
    this.rootStore.contactsStore.toggleWidgetDrawer()
    const svc = new ParseService()
    const result = await svc.deleteContact(this.rootStore.appStore.currentOrgId, this.objectId)
    if (result) {
      this.deleteSuccessful = true
    }
  }
}
