import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../localization/LocalizationStore'
import { QuestionsStore } from '../store/QuestionsStore'
import { InputLabel, OutlinedInput } from '@material-ui/core'

interface Props {
  localizationStore?: LocalizationStore
  questionsStore?: QuestionsStore
}

const DrawerLabelSection: React.FC<Props> = ({ localizationStore, questionsStore }) => {
  const { lzStrings } = localizationStore
  const { editVM } = questionsStore

  return (
    <section>
      <h6>{lzStrings.questionsCatalogWidgetDrawer.question}</h6>
      <InputLabel required htmlFor='label'>
        {lzStrings.questionsCatalogWidgetDrawer.label}
      </InputLabel>
      <OutlinedInput
        id='title'
        fullWidth
        labelWidth={0}
        autoComplete='off'
        value={editVM.title}
        onChange={(e) => editVM.setTitle(e.target.value)}
        error={editVM.labelError}
      />
    </section>
  )
}

export default inject('localizationStore', 'questionsStore')(observer(DrawerLabelSection))
