import React, { FC } from 'react'
import { inject, observer } from 'mobx-react'
import { Grid, Typography } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/CheckCircleRounded'
import OffCircleIcon from '@material-ui/icons/HighlightOffRounded'
import { IPasswordPolicyInfo } from './IPasswordPolicyInfo'
import { PasswordStrengthMeterVM } from './PasswordStrengthMeterVM'
import './PasswordStrengthChecklist.scss'

interface Props {
  vm: PasswordStrengthMeterVM
  isVisible?: boolean
  showScore?: boolean
}

const PasswordStrengthChecklist: FC<Props> = ({ vm }) => {

  const renderPassIcon = (val: IPasswordPolicyInfo) => {
    return (
      <div className='checklist-item'>
        <CheckIcon className='green' />
        <Typography className='checklist-text'>{val.info}</Typography>
      </div>
    )
  }

  const renderFailIcon = (val: IPasswordPolicyInfo) => {
    return (
      <div className='checklist-item'>
        <OffCircleIcon className='red' />
        <Typography className='checklist-text'>{val.info}</Typography>
      </div>
    )
  }

  const renderPasswordStrengthChecklist = () => {
    return (
      <Grid
        container
        className='checklist'
        direction='column'
        justifyContent='center'
        alignItems='flex-start'
      >
        {Object.values(vm.newPasswordPolicyMonitorObject).map((val: IPasswordPolicyInfo, i) => (
          <Grid key={`checklist-item-${i}`} item>{val.isValid ? renderPassIcon(val) : renderFailIcon(val)}</Grid>
        ))}
      </Grid>
    )
  }

  return <div id='PasswordStrengthChecklist'>{renderPasswordStrengthChecklist()}</div>
}

export default inject(
  'organizationUsersStore',
  'localizationStore'
)(observer(PasswordStrengthChecklist))
