import { computed, observable, action } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { Worksheet } from '../../aggregate/Worksheet'

export class DrawerItemVM {
  rootStore: RootStore
  worksheet: Worksheet

  constructor(rootStore: RootStore, worksheet: Worksheet) {
    this.rootStore = rootStore
    this.worksheet = worksheet
  }

  @observable public isOpen: boolean = true

  @computed 
  public get objectId() {
    return this.worksheet.objectId
  }

  @computed
  public get name() {
    return this.worksheet.name
  }

  @computed
  public get isVisible() {
    let isVisible = false
    if (this.rootStore.appStore.isOrgAdmin) return true
    if (this.worksheet.owner === this.rootStore.appStore.currentUserId) return true
    if (!this.worksheet.hasTableauWorkbook) return false

    if (this.worksheet.visibleTo.length) {

      const foundUser = this.worksheet.visibleTo.find(
        e => e.id === this.rootStore.appStore.currentUserId
      )

      if (foundUser) isVisible = true

      const roles = this.worksheet.visibleTo.filter(e => e.type === 'role')
      if (roles.length) {
        roles.forEach(role => {
          const foundRole = this.rootStore.userStore.currentOrganization.roles.find(
            e => e.id == role.id
          )
          if (foundRole) isVisible = true
        })
      }

      const groups = this.worksheet.visibleTo.filter(e => e.type === 'group')
      if (groups.length) {
        groups.forEach(group => {
          const foundRole = this.rootStore.userStore.currentOrganization.groups.find(
            e => e.id == group.id
          )
          if (foundRole) isVisible = true
        })
      }
    }

    return isVisible
  }
  
}
