import { observable } from 'mobx'
import { serializable } from 'serializr'

export class Participant {
  static create(fromPart: any) {
    const part = new Participant()
    part.id = fromPart.id
    part.type = fromPart.type
    part.name = fromPart.name.replace('User: ', '').replace('Role: ', '').replace('Group: ', '')
    part.isArchived = fromPart.isArchived
    return part
  }

  @serializable @observable id: string = ''
  @serializable @observable type: string = ''
  @serializable @observable name: string = ''
  @serializable @observable isArchived?: boolean = false
}
