import React, { FC } from 'react'
import { observer, inject } from 'mobx-react'
import './SurveyOptions.scss'
import { SurveysStore } from '../../../store/SurveysStore'
import LocalizationStore from '../../../../localization/LocalizationStore'
import VisibilityEditor from '../options/VisibilityEditor'
import { LabelsStore } from '../../../../labels/store/LabelsStore'

interface Props {
  surveysStore?: SurveysStore
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
}

const Visibility: FC<Props> = ({ surveysStore, localizationStore, labelsStore }) => {
  const surveyEditVM = surveysStore.viewModels.editVM
  const vm = surveyEditVM.surveyOptionsVM
  if (!surveyEditVM) return null
  if (surveyEditVM.limited) return null
  if (!vm) return null
  const s = localizationStore.lzStrings.surveys
  return (
    <div>
      <div className='primary-text'>{s.visibility}</div>
      <div className='secondary-text'>{`${s.users_allowed} ${labelsStore
        .getLabelByLanguageAndFor('pulse')
        .toLowerCase()}`}</div>
      <div className='section-content'>
        <VisibilityEditor />
      </div>
    </div>
  )
}

export default inject('surveysStore', 'localizationStore', 'labelsStore')(observer(Visibility))
