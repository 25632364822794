import React from 'react'
import { OrganizationsSAStore } from '../../store/OrganizationsSAStore'
import LocalizationStore from '../../../localization/LocalizationStore'
import { inject, observer } from 'mobx-react'
import { Grid, Button, InputAdornment, TextField, CircularProgress } from '@material-ui/core'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import './OrganizationEditDrawer.scss'
import InfoTooltip from 'src/app/shared/InfoTooltip'


interface Props {
    organizationsSAStore?: OrganizationsSAStore
    localizationStore?: LocalizationStore
}

const OrganizationToken: React.FC<Props> = ({ organizationsSAStore, localizationStore }) => {
    const { editVM: vm } = organizationsSAStore
    if (!vm) return null

    const renderLoadingSpinner = () => {
        if (vm.isGeneratingToken) return (<CircularProgress className='progressCircle'/>)
        return 'Generate Token'
    }
    const renderTokenSection = () => {
        if (vm.isNew) return null
        return (
            <div id = "token">
              <Button disabled={vm.isGeneratingToken} onClick = {() => {vm.generateToken()}}>{renderLoadingSpinner()}</Button> 
            </div>
        )
    }

    const renderCopyTokenInput = () => {
        return (
            <TextField
                id='copyToken'
                onFocus={(e) => e.target.select()}
                variant='outlined'
                fullWidth
                value={vm.customCollectionAPIToken}
                InputProps={{
                endAdornment: (
                    <InputAdornment position='end'>
                    <FileCopyIcon
                        onClick={() => vm.copyLinkToClipboard()}
                        className={'icon'}
                    />
                    </InputAdornment>
                ),
                }}
            />
            
        )
    }

    return (
        <div id='OrganizationToken'>
            <Grid container>
                <Grid item xs={12} md={12} lg={12}>
                  <div className='details-paper'>
                    <div className='token-label'>
                        <h6>Organization Token</h6>
                        <InfoTooltip message={'A token that will allow permanent access of the public API'} />
                    </div>
                    
                        {renderCopyTokenInput()}
                        {renderTokenSection()}   

                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default inject('organizationsSAStore', 'localizationStore')(observer(OrganizationToken))
