import React from 'react'
import { observer, Observer, inject } from 'mobx-react'
import { IconButton, Snackbar, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import initiate from '../../../../assets/img/steps/initiate.png'
import LocalizationStore from '../../../localization/LocalizationStore'
import { WorksheetsStore } from '../../store/WorksheetsStore'
import { WorksheetEditVM } from '../../view-models/edit/WorksheetEditVM'
import './WorksheetsSnackbar.scss'

interface Props {
  vm: WorksheetEditVM
}

interface Props {
    vm: WorksheetEditVM
    worksheetsStore?: WorksheetsStore
    localizationStore?: LocalizationStore
  }

const WorksheetsSnackbar: React.FC<Props> = ({ vm,  }) => {
  if (!vm) return undefined

  const renderSnackbarIcon = () => {
    if (vm.snackbarMessage.includes('Success'))
      return <img className='custom-icon' alt='initiate icon' src={initiate} />
  }

  const className = () => {
    if (vm.snackbarMessage.includes('Success')) return 'snackbar-success'
    if (vm.snackbarMessage.includes('Error')) return 'snackbar-error'
    return 'snackbar-info'
  }

  return (
    <Snackbar
      id='WorksheetsSnackbar'
      className={className()}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={vm.isSnackbarOpen}
      autoHideDuration={10000}
      onClose={() => vm.closeSnackbar()}
      message={
        <>
          <Typography className='snackbarText'>
            {renderSnackbarIcon()}
            {vm.snackbarMessage}
          </Typography>
        </>
      }
      action={[
        <Observer key={'1'}>
          {() => (
            <IconButton
              key='close'
              aria-label='close'
              color='inherit'
              onClick={() => vm.closeSnackbar()}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Observer>,
      ]}
    />
  )
}

export default inject('worksheetsStore', 'localizationStore')(observer(WorksheetsSnackbar))
