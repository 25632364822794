import React from 'react'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { Avatar, Chip, Grid, IconButton, TextField, Tooltip } from '@material-ui/core'
import CopyIcon from '@material-ui/icons/FileCopyOutlined'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import rootStore from '../../../stores/RootStore'
import AddSurveyIntroductionButton from './AddSurveyIntroductionButton'
import PulseCampaignSurveyIntroductionCard from './PulseCampaignSurveyIntroductionCard'
import PulseCampaignSurveyQuestionCard from './PulseCampaignSurveyQuestionCard'
import PulseQuestion from 'src/app/pulse-questions/aggregate/PulseQuestion'
import { PulseQuestionVM } from '../../view-models/tree/PulseQuestionVM'
import { PulseCategoriesManageVM } from '../../view-models/PulseCategoriesManageVM'
import './PulseCampaignSurveySelector.scss'
import LocalizationStore from '../../../localization/LocalizationStore'
import { LabelsStore } from '../../../labels/store/LabelsStore'
import PreviewIcon from '@material-ui/icons/RemoveRedEye'
import PulseCampaignSurveyPreviewModal from './PulseCampaignSurveyPreviewModal'
import AddSurveyCompletedContentButton from './AddSurveyCompletedContentButton'
import PulseCampaignSurveyCompletedContentCard from './PulseCampaignSurveyCompletedContentCard'
import PulseCampaignQuestionEdit from '../questions/edit/PulseCampaignQuestionEdit'
import PulseQuestionMediaPicker from '../../../pulse-questions/views/media-picker/PulseQuestionMediaPicker'

interface Props {
  vm: PulseCategoriesManageVM
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
}

const PulseCampaignSurveySelector: React.FC<Props> = ({ vm, localizationStore, labelsStore }) => {
  const { pulseCampaign: lz } = localizationStore.lzStrings
  if (!vm) return null

  const editVM = rootStore.pulseCampaignsStore.viewModels.editVM
  const questionEditVM = rootStore.pulseQuestionsStore.editVM
  if (!editVM) return

  const handleCopySurvey = (event: React.MouseEvent<HTMLElement>) => {
    editVM.copySurveyToCurrentCampaign(editVM.currentSurvey.pulseCampaignSurvey)
  }

  const handleDeleteSurvey = (event: React.MouseEvent<HTMLElement>) => {
    editVM.deleteSurveyFromCurrentCampaign(editVM.currentSurvey.id)
    if (editVM.currentSurveyIndex >= editVM.surveys.length)
      editVM.setCurrentSurveyIndex(editVM.numberOfSurveys - 1)
  }

  const renderCurrentSurveyName = () => {
    if (!editVM.currentSurvey) return
    return (
      <TextField
        required
        fullWidth
        autoComplete='off'
        disabled={editVM.currentSurvey && editVM.isCurrentSurveySent}
        value={editVM.currentSurvey.name}
        placeholder='(Untitled Pulse)'
        onChange={(e) => editVM.currentSurvey.setName(e.target.value)}
        error={!editVM.currentSurvey.nameValid}
        className='survey-selector-survey-card-name'
        variant='outlined'
      />
    )
  }

  const renderCurrentSurveyStatus = () => {
    if (!editVM.currentSurvey) return
    if (editVM.currentSurvey.isTemplate) return

    const dateVM = editVM.surveyDates[editVM.currentSurveyIndex]
    if (!dateVM) return
    if (!dateVM.sendDate) return

    const time = moment(dateVM.sendDate).format('MMM D YYYY')
    const status = editVM.currentSurvey.status
    const label = `${status} ${time}`
    return (
      <div className='survey-selector-survey-card-status'>
        <Chip variant='outlined' color='primary' label={label} size='small' />
      </div>
    )
  }

  const renderCurrentSurveyToolbar = () => {
    if (!editVM.currentSurvey) return

    return (
      <div className={'survey-card-header-actions'}>
        <Tooltip title={'Preview'} placement='bottom-start'>
          <span>
            <IconButton
              className={'survey-card-btn'}
              onClick={() => editVM.previewSurvey()}
              disabled={
                !editVM.currentSurvey.questions || editVM.currentSurvey.questions.length === 0
              }
            >
              <Avatar
                className={`survey-card-btn-avatar ${
                  !editVM.currentSurvey.questions || editVM.currentSurvey.questions.length === 0
                    ? 'disabled'
                    : ''
                }`}
              >
                <PreviewIcon fontSize='small' />
              </Avatar>
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={lz.copy} placement='bottom-start'>
          <span>
            <IconButton
              className={'survey-card-btn'}
              disabled={editVM.isMaxSurveys}
              onClick={handleCopySurvey}
            >
              <Avatar className={`survey-card-btn-avatar ${editVM.isMaxSurveys ? 'disabled' : ''}`}>
                <CopyIcon fontSize='small' />
              </Avatar>
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip
          title={
            editVM.currentSurvey && editVM.isCurrentSurveySent
              ? `${labelsStore.getLabelByLanguageAndFor('pulse')} ${lz.already_sent}`
              : lz.delete
          }
          placement='bottom-start'
        >
          <span>
            <IconButton
              className={'survey-card-btn'}
              disabled={
                (editVM.currentSurvey && editVM.isCurrentSurveySent) || editVM.surveys.length < 2
              }
              onClick={handleDeleteSurvey}
            >
              <Avatar
                className={`survey-card-btn-avatar ${
                  (editVM.currentSurvey && editVM.isCurrentSurveySent) || editVM.surveys.length < 2
                    ? 'disabled'
                    : ''
                }`}
              >
                <DeleteIcon fontSize='small' />
              </Avatar>
            </IconButton>
          </span>
        </Tooltip>
      </div>
    )
  }

  const renderCurrentSurveyIntroduction = () => {
    if (!editVM.currentSurvey) return
    if (!editVM.currentSurvey.surveyIntroductionValid)
      return (
        <Grid item className={'survey-selector-survey-card-intro'}>
          <AddSurveyIntroductionButton vm={editVM} />
        </Grid>
      )
    return (
      <Grid item className={'survey-selector-survey-card-intro hasValue'}>
        <PulseCampaignSurveyIntroductionCard vm={editVM.currentSurvey} />
      </Grid>
    )
  }

  const renderCurrentSurveyCompletedContent = () => {
    if (!editVM.currentSurvey) return
    if (!editVM.currentSurvey.surveyCompletedContentValid)
      return (
        <Grid item className={'survey-selector-survey-card-outro'}>
          <AddSurveyCompletedContentButton vm={editVM} />
        </Grid>
      )
    return (
      <Grid item className={'survey-selector-survey-card-outro hasValue'}>
        <PulseCampaignSurveyCompletedContentCard vm={editVM.currentSurvey} />
      </Grid>
    )
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    move(editVM.currentSurvey.questions, oldIndex, newIndex) as PulseQuestion[]
  }

  const move = (arr, old_index, new_index) => {
    while (old_index < 0) {
      old_index += arr.length
    }
    while (new_index < 0) {
      new_index += arr.length
    }
    if (new_index >= arr.length) {
      let k = new_index - arr.length
      while (k-- + 1) {
        arr.push(undefined)
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
    return arr
  }

  const SortableItem: any = SortableElement(({ value }) => (
    <div className='survey-question-container'>{value}</div>
  ))

  const SortableList: any = SortableContainer(({ items }) => {
    return (
      <Grid
        container
        className={'survey-question-sortable-grid'}
        direction='column'
        justifyContent='flex-start'
        alignItems='center'
        alignContent='center'
        spacing={2}
      >
        {items.map((value, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            value={value}
            disabled={editVM.currentSurvey && editVM.isCurrentSurveySent}
          />
        ))}
      </Grid>
    )
  })

  const renderCurrentSurveyQuestions = () => {
    if (!editVM.currentSurvey) return
    if (editVM.currentSurvey.questions.length === 0) return renderNoQuestions()
    return (
      <div id='SortableQuestionsGrid'>
        <SortableList
          pressDelay={150}
          axis='y'
          useDragHandle
          items={editVM.currentSurvey.questions.map((row, index) => {
            return (
              <Grid
                item
                xs
                sm
                md
                lg
                xl
                key={`survey-question-sortable-grid-item-${row.objectId}`}
                className={'survey-selector-survey-card-question'}
              >
                {renderCard(row)}
              </Grid>
            )
          })}
          onSortEnd={onSortEnd}
        />
      </div>
    )
  }

  const renderCard = (question: PulseQuestion) => {
    return (
      <PulseCampaignSurveyQuestionCard
        vm={new PulseQuestionVM(rootStore, undefined, question, false, true)}
      />
    )
  }

  const renderNoQuestions = () => {
    const error = !editVM.currentSurvey.questions.length && editVM.publishTried
    const heading = error ? 'no-questions-heading quest-error' : 'no-questions-heading'
    const subHeading = error ? 'no-questions-subheading quest-error' : 'no-questions-subheading'
    return (
      <div className={'no-questions-container'}>
        <span className={heading}>{lz.no_questions_yet}</span>
        <span className={subHeading}>{lz.select_questions_to}</span>
      </div>
    )
  }

  const renderCurrentSurvey = () => {
    if (!editVM.currentSurvey) return
    if (questionEditVM) return
    return (
      <div id='PulseCampaignCurrentSurvey'>
        <PulseCampaignSurveyPreviewModal vm={editVM.currentSurvey} />
        <Grid item className={'survey-selector-survey-card'}>
          <Grid item className={'survey-selector-survey-card-header'}>
            {renderCurrentSurveyName()}
            {renderCurrentSurveyStatus()}
            {renderCurrentSurveyToolbar()}
          </Grid>
          {renderCurrentSurveyIntroduction()}
          {renderCurrentSurveyCompletedContent()}
          <Grid item className={'survey-selector-survey-card-questions'}>
            {renderCurrentSurveyQuestions()}
          </Grid>
        </Grid>
      </div>
    )
  }

  const renderQuestionEdit = () => {
    if (!questionEditVM) return
    return <PulseCampaignQuestionEdit />
  }

  return (
    <>
      <div id='PulseCampaignSurveySelectorGrid'>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='flex-start'
          className='survey-selector-grid-top-container'
        ></Grid>
        <Grid item xs={12} className='survey-selector-grid-bottom-item'>
          <div className='survey-selector-grid-bottom-container'>
            {renderCurrentSurvey()}
            {renderQuestionEdit()}
          </div>
        </Grid>
      </div>
    </>
  )
}

export default inject(
  'pulseCampaignsStore',
  'localizationStore',
  'labelsStore'
)(observer(PulseCampaignSurveySelector))
