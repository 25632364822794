import { RootStore } from '../../../stores/RootStore'
import { observable, computed, action } from 'mobx'
import { PagedDataVM } from '../../../shared/view-models/PagedDataVM'
import { OrganizationsSAService } from '../../service/OrganizationsSAService'
import { OrganizationsSARowVM } from './OrganizationSARowVM'

export class OrganizationsSAListWidgetVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @observable public keywordFilter: string = ''
  @observable public deleteConfirmShown: boolean = false
  @observable public deletingOrgId: string = ''

  @computed public get rows(): OrganizationsSARowVM[] {
    return this.rootStore.organizationsSAStore.organizations.map(
      (e) => new OrganizationsSARowVM(this.rootStore, this, e)
    )
  }

  @computed
  public get pagedData(): PagedDataVM {
    return new PagedDataVM(
      this.rootStore,
      this.rows,
      ['name', 'email', 'phone', 'zone'],
      '',
      'OrganizationsSAListWidget',
      ''
    )
  }

  @computed public get isLoading(): boolean {
    if (!this.rootStore.organizationsSAStore) return true
    return this.rootStore.organizationsSAStore.isLoading
  }

  @action setKeywordFilter(val) {
    this.keywordFilter = val
    this.pagedData.setFilter(this.keywordFilter)
  }

  @action
  public openAddDrawer() {
    this.rootStore.organizationsSAStore.addOrganization()
  }

  @action
  public toggleDeleteDialog() {
    this.deleteConfirmShown = !this.deleteConfirmShown
  }

  @action
  public showDeleteDialog(orgId: string) {
    this.deletingOrgId = orgId
    this.deleteConfirmShown = true
  }
}
export default OrganizationsSAListWidgetVM
