import React, { FC } from 'react'
import { Typography } from '@material-ui/core'
import './EmptyState.scss'

export interface EmptyStateProps {
  image: string
  title: string
  subtitle1: string
  subtitle2: string
  action: React.ReactNode
}

const EmptyState: FC<EmptyStateProps> = ({
  image = '',
  title = '',
  subtitle1 = '',
  subtitle2 = '',
  action,
}) => {
  return (
    <div id='EmptyState'>
      <div className={'empty-state-container'}>
        <div className={'empty-state-avatar'}>
          <img className={'empty-state-img'} alt='Empty' src={image} />
        </div>
        <Typography className='empty-state-header'>{title}</Typography>
        <div id='EmptyStateText'>
          <Typography className='empty-state-text'>{subtitle1}</Typography>
          <Typography className='empty-state-text'>{subtitle2}</Typography>
          {action}
        </div>
      </div>
    </div>
  )
}

export default EmptyState
