import React from 'react'
import { IndexRoute, Route } from 'react-router'
import Layout from '../../layout/Layout'
import { authenticate } from '../../auth/routes/AuthenticatedRoute'
import PulseCategoriesManageWrapper from '../../pulse-categories/views/PulseCategoriesManageWrapper'
import PulseCampaignEditScreen from '../../pulse-campaigns/views/edit/PulseCampaignEditScreen'
import PulseCampaignsWrapper from '../../pulse-campaigns/views/dashboard/PulseCampaignsWrapper'
import CampaignAnalysisEditScreen from '../../pulse-campaigns/views/analysis/CampaignAnalysisEditScreen'
import PulseSetsManageWrapper from '../../pulse-sets/views/manage/PulseSetsManageWrapper'
import PulseCampaignTemplatesWrapper from '../../pulse-campaigns/views/dashboardV2/PulseCampaignTemplatesWrapper'

export default function getPulseRoutes() {
  return (
    <Route key={'pulse0'} component={authenticate(Layout)} path='pulse'>
      <IndexRoute onEnter={(nextState, replace) => replace('/pulse/v1')} />
      <Route key={'pulse1'} path='v1/campaigns' component={PulseCampaignsWrapper} />
      <Route key={'pulse7'} path='v1/campaignTemplates' component={PulseCampaignTemplatesWrapper} />
      <Route key={'pulse8'} path='v1/edit/:id/:surveyId' component={PulseCampaignEditScreen} />
      <Route key={'pulse3'} path='v1/edit/:id' component={PulseCampaignEditScreen} />
      <Route
        key={'pulse4'}
        path='v1/fromtemplate/:templateId'
        component={PulseCampaignEditScreen}
      />
      <Route key={'pulse5'} path='v1/fromCopy/:copyId' component={PulseCampaignEditScreen} />
      <Route key={'pulse6'} path='v1/analysis/:id' component={CampaignAnalysisEditScreen} />
      <Route
        key={'pulse9'}
        path='v1/fromtemplate/edit/:templateEditId'
        component={PulseCampaignEditScreen}
      />
      <Route
        key={'pulse10'}
        path='v1/fromtemplate/fromCopy/:templateCopyId'
        component={PulseCampaignEditScreen}
      />
    </Route>
  )
}
