import { observable, action, computed } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { MediaLibraryItemVM } from './MediaLibraryItemVM'
import { MediaItemsService } from '../../service/MediaItemsService'
import { MediaItemType } from '../../../upload/types/MediaItemType'

export class MediaLibraryVM {
    private mediaItemService: MediaItemsService
    private rootStore: RootStore

    @observable public filterByType?: MediaItemType = null
    @observable public searchValue: string
    @observable public showVideos: boolean = true
    @observable public showImages: boolean = true

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.mediaItemService = new MediaItemsService()
    }

    @computed
    public get mediaItems(): MediaLibraryItemVM[] {
        let mediaItems = this.rootStore
            .mediaItemsStore
            .currentOrgMediaItems
            .map(itm => new MediaLibraryItemVM(itm))
        if (!this.showImages)
            mediaItems = mediaItems.filter(itm => itm.type !== 'image' )
        if (!this.showVideos)
            mediaItems = mediaItems.filter(itm => itm.type !== 'video' )
        if (Boolean(this.searchValue))
            mediaItems = mediaItems.filter(
                item =>
                    typeof item.name === 'string' &&
                    item.name.toLowerCase().includes(this.searchValue.toLowerCase())
            )
          
        return mediaItems
    }

    @action
    public setFilterByType(type?: MediaItemType) {
        this.filterByType = type
    }

    @action
    public setSearchValue(value: string) {
        this.searchValue = value
    }

    @action
    public toggleShowVideos() {
        this.showVideos = !this.showVideos
    }

    @action
    public toggleShowImages() {
        this.showImages = !this.showImages
    }
}
