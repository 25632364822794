import React from 'react'
import { CardContent } from '@material-ui/core'
import DrawerTaskDetails from './DrawerTaskDetails'
import DrawerCommentsContainer from './DrawerCommentsContainer'
import DrawerStatusContainer from './DrawerStatusContainer'
import DrawerFeedbackDetails from './DrawerFeedbackDetails'
import DrawerDocumentSection from './DrawerDocumentSection'

const DrawerTaskDetailsFormTab: React.FC = () => {
  return (
    <>
      <DrawerTaskDetails />
      <DrawerFeedbackDetails />
      <DrawerDocumentSection />
      <DrawerStatusContainer />
      <DrawerCommentsContainer />
    </>
  )
}

export default DrawerTaskDetailsFormTab
