import {  computed } from 'mobx'
import { AGGridRowVM } from '../../shared/ag-grid/AGGridRowVM'
import { RootStore } from '../../stores/RootStore'
import { Client } from '../aggregate/Client'
import { ClientManagementWidgetVM } from './ClientManagementWidgetVM'

export class ClientRowVM extends AGGridRowVM<Client> {

  constructor(rootStore: RootStore, client: Client, listVM: ClientManagementWidgetVM) {
    super(rootStore, client, listVM)
  }

  @computed
  public get name(): string {
    return this.aggregate.name
  }

  @computed
  public get objectId(): string {
    return this.aggregate.objectId
  }

  @computed
  public get description(): string {
    return this.aggregate.description
  }

  openClient() {
    this.rootStore.clientsStore.setSelectedClientToEdit(this.aggregate)
  }
}
