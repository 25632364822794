import React from 'react'
import { AGGridVM } from './AGGridVM'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../localization/LocalizationStore'
import { IconButton } from '@material-ui/core'

interface Props {
  vm: AGGridVM
  localizationStore?: LocalizationStore
}

const AGGridPagingRow: React.FC<Props> = ({ vm, localizationStore }) => {
  return (
    <div className='custom-pagination-for-aggrid'>
      {vm.paginationFrom}-{vm.paginationTo} {localizationStore.lzStrings.pagination.of}{' '}
      {vm.paginationRowCount}
      <IconButton
        size='small'
        onClick={() => vm.goToPreviousPage()}
        disabled={vm.isPaginationBackDisabled}
      >
        <ChevronLeftIcon />
      </IconButton>
      <IconButton
        size='small'
        onClick={() => vm.goToNextPage()}
        disabled={vm.isPaginationForwardDisabled}
      >
        <ChevronRightIcon />
      </IconButton>
    </div>
  )
}

export default inject('localizationStore')(observer(AGGridPagingRow))
