import { computed, observable } from 'mobx'
import Parse from 'parse'
import PulseCategory from '../../pulse-categories/aggregate/PulseCategory'
import { IPulseCategoryDTO } from '../../pulse-categories/dtos/IPulseCategoryDTO'
import { DataStore } from '../../shared/data/DataStore'
import { RootStore } from '../../stores/RootStore'
import { IPulseCategoriesFindRequest } from '../interfaces/IPulseCategoriesFindRequest'
import { PulseCategoriesService } from '../services/PulseCategoriesService'

export class PulseCategoriesDataStore extends DataStore<PulseCategory, IPulseCategoryDTO> {
  private request: IPulseCategoriesFindRequest

  constructor(rootStore: RootStore, request: IPulseCategoriesFindRequest) {
    super(rootStore, PulseCategory, 'pulseCategories', [
      'objectId',
      'name',
      'grandCategoryId',
      'parentCategoryId',
      'isDefault'
    ])
    this.request = request
    this.paged = true
    this.listenToListRecordsViaApiUpdates = true
  }

  @observable public isLoaded: boolean = false

  public onRecordUpdated(obj: PulseCategory) {
    return this.loadListRecords()
  }

  public onListRecordsLoaded() {
    this.isLoaded = true
  }

  public setRequest(request: IPulseCategoriesFindRequest) {
    this.request = request
  }

  public getRequest(): IPulseCategoriesFindRequest {
    return this.request
  }

  protected getBaseQuery() {
    const query = new Parse.Query(this.className)
    query.equalTo('grandCategoryId', this.request.grandCategoryId)
    query.equalTo('parentCategoryId', this.request.parentCategoryId)
    return query
  } 

  protected getListRecordsApiCall() {
    return async () => {
      const req: IPulseCategoriesFindRequest = {
        ...this.request,
        listColumns: this.listColumns,
      }
      const svc = new PulseCategoriesService(this.rootStore)
      const result = await svc.getPulseCategories(req)
      this.totalRecords = result.count
      return result.pulseCategories
    }
  }

  public get rows() {
    return this.records
  }

}
