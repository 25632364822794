import React from 'react'
import { inject, observer, useLocalStore } from 'mobx-react'
import { MyAudienceWidgetVM } from '../../view-models/my-audience-widget/MyAudienceWidgetVM'
import { RootStore } from '../../../stores/RootStore'
import { Card, ListItemText, Menu, MenuItem } from '@material-ui/core'
import UsersTab from './UsersTab'
import './MyAudienceWidget.scss'
import GroupingsTab from './GroupingsTab'

interface Props {
  rootStore?: RootStore
}

const MyAudienceWidget: React.FC<Props> = ({ rootStore }) => {
  const localStore = useLocalStore(() => ({
    vm: new MyAudienceWidgetVM(rootStore),
  }))
  const vm = localStore.vm

  const renderEmailMenu = () => {
    return (
      <Menu
        elevation={2}
        anchorEl={vm.emailAnchorEl}
        keepMounted
        open={Boolean(vm.emailAnchorEl)}
        onClose={() => vm.setEmailAnchorEl('', null, null)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem key='to' onClick={() => vm.sendEmailOnToLine()}>
          <ListItemText primary={'Send email on To line'} />
        </MenuItem>
        <MenuItem key='bcc' onClick={() => vm.sendEmailOnBCCLine()}>
          <ListItemText primary={'Send email on BCC line'} />
        </MenuItem>
      </Menu>
    )
  }
  return (
    <Card elevation={0} id='MyAudienceWidget'>
      <UsersTab vm={vm.directReportsTab} />
      <UsersTab vm={vm.allReportsTab} />
      <UsersTab vm={vm.additionalUsersTab} />
      <GroupingsTab vm={vm.rolesTab} />
      <GroupingsTab vm={vm.groupsTab} />
      {renderEmailMenu()}
    </Card>
  )
}

export default inject('rootStore')(observer(MyAudienceWidget))
