import React from 'react'
import * as Sentry from '@sentry/browser'

interface Props {
  render: (e) => React.ReactNode
}

interface State {
  hasError: boolean
  error: Error
}

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
    }
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error: error }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    Sentry.captureException({
      error,
      errorInfo,
    })
  }

  render() {
    if (this.state.hasError) {
      return this.props.render(this.state.error)
    }
    return this.props.children
  }
}
