import { RootStore } from '../../stores/RootStore'
import { computed } from 'mobx'
import { DataStore } from '../../shared/data/DataStore'
import { Contact } from '../../contacts/aggregate/Contact'
import { IContactDTO } from '../../contacts/dtos/IContactDTO'

export class ContactsExpansionDataStore extends DataStore<Contact, IContactDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, Contact, 'contacts', ['organizationId', 'name'])
  }

  @computed
  public get contacts() {
    return this.records
  }
}
