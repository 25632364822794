import { action, observable, computed, reaction, runInAction, autorun } from 'mobx'
import { TrainingItem } from '../../../training-items/aggregate/TrainingItem'
import { RootStore } from '../../../stores/RootStore'
import { TrainingPlanItem } from '../../aggregates/TrainingPlanItem'
import { TrainingPlansUpdateService } from '../../service/TrainingPlansUpdateService'
import { ContentVM } from './ContentVM'
import { UserTrainingPlanTakeVM } from './UserTrainingPlanTakeVM'
import { Quiz } from '../../../quiz/aggregate/Quiz'
import { Attachment } from '../../aggregates/Attachment'
import moment from 'moment'
import { tr } from 'date-fns/locale'

export class PlanItemVM {
  private rootStore: RootStore
  public trainingPlanItem: TrainingPlanItem
  public takeVM: UserTrainingPlanTakeVM
  public svc: TrainingPlansUpdateService

  constructor(
    rootStore: RootStore,
    trainingPlanItem: TrainingPlanItem,
    takeVM: UserTrainingPlanTakeVM
  ) {
    this.rootStore = rootStore
    this.trainingPlanItem = trainingPlanItem
    this.takeVM = takeVM
    this.svc = new TrainingPlansUpdateService()
    setTimeout(() => this.setExpanded(), 1050)

    setTimeout(() => this.getElement(), 2000)
    reaction(
      () => this.isExpanded,
      () => setTimeout(() => this.getElement(), 500)
    )
  }

  @observable public isExpanded: boolean = false
  @observable public element: any = null

  @action
  public setExpanded() {
    let shouldExpand = false
    if (this.trainingPlanItem.contentItems && this.trainingPlanItem.contentItems.length > 0) {
      this.trainingPlanItem.contentItems.map((item) =>
        item.type === 'exercise' ? (shouldExpand = true) : null
      )
    }
    this.isExpanded = shouldExpand
  }

  @computed
  public get isLastItem(): boolean {
    return this.takeVM.trainingItemRows[this.takeVM.trainingItemRows.length - 1].id === this.id
  }

  @computed
  public get isFirstTrainingItem(): boolean {
    return this.takeVM.trainingItemRows[0].id === this.id
  }

  @computed
  public get previousTrainingItem(): PlanItemVM {
    const idx = this.takeVM.trainingItemRows.findIndex((row) => row.id === this.id)
    if (idx === 0) return null
    return this.takeVM.trainingItemRows[idx - 1]
  }

  @computed
  public get mustCompleteInOrder(): boolean {
    return this.trainingPlanItem.mustCompleteInOrder
  }

  @computed
  public get requireCertificate(): boolean {
    return this.trainingPlanItem.requireCertificate
  }

  @computed
  public get hasCertificate(): boolean {
    return Boolean(this.trainingPlanItem.certificate)
  }

  @action
  public setCertificate(result: Attachment) {
    this.trainingPlanItem.setCertificate(result)
  }

  @computed
  public get previousTrainingItemsAreIncomplete(): boolean {
    let isTrue = false
    const idx = this.takeVM.trainingItemRows.findIndex((row) => row.id === this.id)
    for (let i = 0; i < idx; i++) {
      if (!this.takeVM.trainingItemRows[i].isComplete) isTrue = true
    }
    return isTrue
  }

  @computed
  public get completionAllowed(): boolean {
    if (this.previousTrainingItemsAreIncomplete && this.takeVM.mustCompleteItemsInOrder) {
      return false
    }
    return true
  }

  @computed
  public get onlyQuizIsLeft(): boolean {
    if (!this.requireQuiz) return false
    if (this.contentItems.some((item) => !item.isComplete)) return false
    return true
  }

  @computed
  public get menuQuizDisabled(): boolean {
    if (!this.takeVM.userTrainingPlan.objectId) return false
    if (this.takeVM.reviewingPlan) return false
    if (!this.completionAllowed) return true
    if (this.mustCompleteInOrder && this.contentItems.some((item) => !item.isComplete)) return true
    return false
  }

  @computed
  public get menuCertificateDisabled(): boolean {
    if (!this.takeVM.userTrainingPlan.objectId) return false
    if (this.takeVM.reviewingPlan) return false
    if (!this.completionAllowed) return true
    if (this.quiz && this.menuQuizDisabled) return true
    return false
  }

  @action
  public getElement() {
    this.element = null
    this.element = document.getElementById(`training-item-${this.id}`)
    if (!this.element) setTimeout(() => this.getElement(), 100)
  }

  @computed
  public get height(): number {
    let height
    if (!this.isExpanded && this.takeVM.trainingItemRows.length === 1) {
      return 0
    } else if (!this.isExpanded && this.takeVM.trainingItemRows.length > 0 && !this.isLastItem) {
      return this.element ? this.element.clientHeight : 68
    } else if (!this.isExpanded && this.takeVM.trainingItemRows.length > 0 && this.isLastItem) {
      return this.element ? this.element.clientHeight : 10
    }
    height = this.element ? this.element.clientHeight - 20 : 68
    if (this.isLastItem && this.isExpanded) height = height - 30
    if (this.nextTrainingItem) height = height + 15
    return height
  }

  @computed
  public get name(): string {
    return this.trainingPlanItem.name.toUpperCase()
  }

  @computed
  public get id(): string {
    return this.trainingPlanItem.id
  }

  @computed
  public get certificateUrl(): string {
    if (!this.trainingPlanItem.certificate) return ''
    return this.trainingPlanItem.certificate.url
  }

  @computed
  public get certificateFileName(): string {
    const fileName = `${this.trainingPlanItem.certificate.fileName}`
    return fileName
  }

  @computed
  public get trainingItemCompletionString(): string {
    if (this.trainingPlanItem.requireQuiz && this.trainingPlanItem.requireCertificate)
      return '+ Quiz + Certificate'
    if (this.trainingPlanItem.requireQuiz) return '+ Quiz'
    if (this.trainingPlanItem.requireCertificate) return '+ Certificate'
    return ''
  }

  @computed
  public get requireQuiz(): boolean {
    return this.trainingPlanItem.requireQuiz
  }

  @computed
  public get blockString(): string {
    if (!this.trainingPlanItem.block) return undefined
    return `Block-${this.trainingPlanItem.block}:  `
  }

  @computed
  public get block(): string {
    return this.trainingPlanItem.block
  }

  @computed
  public get subtitleItemsString(): string {
    if (this.contentItems.length > 1) return 'items'
    return 'item'
  }

  @computed
  public get subtitle(): string {
    return `${this.trainingPlanItem.contentItems.length} ${this.subtitleItemsString} ${this.trainingItemCompletionString}`
  }

  @computed
  public get contentItems(): ContentVM[] {
    return this.trainingPlanItem.contentItems.map(
      (item) => new ContentVM(this.rootStore, item, this.trainingPlanItem.id, this.takeVM)
    )
  }

  @computed
  public get quiz(): Quiz {
    if (!this.trainingPlanItem.requireQuiz) return null
    return this.trainingPlanItem.quiz
  }

  @computed
  public get isComplete(): boolean {
    return this.trainingPlanItem.isComplete
  }

  @computed
  public get nextContentItem(): ContentVM {
    return this.contentItems.find((item) => !item.isComplete)
  }

  @computed
  public get nextTrainingItem(): PlanItemVM {
    const nextIndex = this.takeVM.trainingItemRows.findIndex((row) => row.id === this.id)
    return this.takeVM.trainingItemRows[nextIndex + 1]
  }

  @computed
  public get dueDate(): string {
    return moment(this.trainingPlanItem.dueDate).format('M/D/YY')
  }

  @action
  public markAsComplete() {
    this.trainingPlanItem.markIsComplete()
    // if (this.takeVM.dueDateVariesByTrainingItem) this.trainingPlanItem.setMissedDueDate()
  }

  @action
  public markAsIncomplete() {
    this.trainingPlanItem.markIsIncomplete()
  }

  @action setActiveItem() {
    this.takeVM.setActiveTrainingItem(this.trainingPlanItem.id)
    this.takeVM.setSelectedContentItem(
      this.nextContentItem ? this.nextContentItem.id : this.contentItems[0].id,
      this.id
    )
  }

  @action
  public toggleIsExpanded() {
    this.isExpanded = !this.isExpanded
    if (this.isExpanded) {
      if (!this.completionAllowed) return
      this.setActiveItem()
    }
    if (this.takeVM.planIsComplete) return
    this.takeVM.save()
  }
}
