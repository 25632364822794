import React, { FC } from 'react'
import { inject, observer } from 'mobx-react'
import clsx from 'clsx'
import { PulseCampaignsStore } from '../../store/PulseCampaignsStore'
import { PulseCampaignEditTabsEnum } from '../../type/PulseCampaignEditTabsEnum'
import './PulseCampaignEditStepper.scss'

interface Props {
  pulseCampaignsStore?: PulseCampaignsStore
  icon: any
  label: string
  index: number
  error?: boolean
  visited?: boolean
  className?: string
}

const Step: FC<Props> = ({ pulseCampaignsStore, index, error, visited, icon, label }) => {
  const pulseCampaignEditVM = pulseCampaignsStore.viewModels.editVM
  if (!pulseCampaignEditVM) return null
  const vm = pulseCampaignEditVM.steppersVM
  if (!vm) return null
  const disabled = index === PulseCampaignEditTabsEnum.LAUNCHED || pulseCampaignEditVM.isLoading
  return (
    <div
      className={clsx('step', 'sln-survey-step-' + label.toLowerCase(), {
        active: index === pulseCampaignEditVM.currentTabIndex && !vm.allCompleted,
        invalid: error,
        visited,
        'disabled-stepper': disabled,
      })}
      onClick={() => {
        if (disabled) return
        pulseCampaignEditVM.setCurrentTab(index)
      }}
    >
      {icon ? <div className='step-left'>{icon}</div> : undefined}
      <div className='step-right'>{index === PulseCampaignEditTabsEnum.INITIATE ? '' : label}</div>
    </div>
  )
}

export default inject('pulseCampaignsStore')(observer(Step))
