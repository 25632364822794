import React from 'react'
import { inject, observer } from 'mobx-react'
import Button from '@material-ui/core/Button'
import LocalizationStore from '../../../localization/LocalizationStore'
import { TasksStore } from '../../store/TasksStore'
import { Grid } from '@material-ui/core'
import './Drawer.scss'
import FileUploadModal from '../../../upload/views/FileUploadModal'
import { FileTypeEnum } from '../../../upload/enum/FileTypeEnum'
import { Attachment } from '../../../attachments/aggregate/Attachment'
import CMSItemsPickerDialog from '../../../cms-items/views/dialogs/CMSItemsPickerDialog'
import { CMSItemsTypeEnum } from '../../../cms-items/enum/CMSItemsTypeEnum'
import { OrganizationsStore } from '../../../organizations/store/OrganizationsStore'
import { AttachmentVM } from '../../../attachments/view-models/AttachmentVM'
import { CMSItemAttachmentVM } from '../../../attachments/view-models/CMSItemAttachmentVM'
import { OldAttachmentVM } from '../../../attachments/view-models/OldAttachmentVM'
import CMSThumbnail from '../../../thumbnail/views/CMSThumbnail'
import OldThumbnail from '../../../thumbnail/views/OldThumbnail'

interface DrawerDocumentsSectionProps {
  localizationStore?: LocalizationStore
  tasksStore?: TasksStore
  organizationsStore?: OrganizationsStore
}

declare global {
  interface Window {
    cloudinary: any
  }
}

const DrawerDocumentsSection: React.FC<DrawerDocumentsSectionProps> = ({
  localizationStore,
  tasksStore,
  organizationsStore,
}) => {
  const { tasksManagementWidgetDrawer: strings } = localizationStore.lzStrings
  const { taskEditVM: vm } = tasksStore

  const renderAttachmentThumbnail = (item: AttachmentVM, index: number) => {
    if (item.isCMSItem)
      return (
        <CMSThumbnail
          key={`tasks-cms-attach-thumb-${index}`}
          vm={item as CMSItemAttachmentVM}
          index={index}
          onClose={() => vm.deleteAttachmentFromTask(index)}
        />
      )
    return (
      <OldThumbnail
        key={`tasks-old-attach-thumb-${index}`}
        vm={item as OldAttachmentVM}
        index={index}
        onClose={() => vm.deleteAttachmentFromTask(index)}
      />
    )
  }

  const renderAttachments = () => {
    if (!vm.attachments) return null
    return (
      <form>
        <Grid container className='attachments-list'>
          {vm.attachments.map((doc, index) => {
            return renderAttachmentThumbnail(doc, index)
          })}
        </Grid>
      </form>
    )
  }

  const renderTaskUploadModal = () => {
    if (organizationsStore.currentOrganization?.hasCMSPicker) return renderCMSUploadModal()
    return renderStandardUploadModal()
  }

  const renderCMSUploadModal = () => {
    return (
      <CMSItemsPickerDialog
        allowMultiple={true}
        fileTypes={CMSItemsTypeEnum.DEFAULT}
        addAfterSave={(arr: Attachment[]) => {
          const attachments = arr.map((a) => {
            const attachment = new Attachment()
            attachment.cmsItemId = a.cmsItemId
            return attachment
          })
          vm.addAttachmentsToTask(attachments)
        }}
        toggleShowModal={() => vm.toggleTaskUploadModal()}
        isOpen={vm.showTaskUploadModal}
      />
    )
  }

  const renderStandardUploadModal = () => {
    return (
      <FileUploadModal
        allowMultiple={true}
        fileTypes={FileTypeEnum.DEFAULT}
        addAfterSave={(a: Attachment[]) => vm.addAttachmentsToTask(a)}
        toggleShowModal={() => vm.toggleTaskUploadModal()}
        isOpen={vm.showTaskUploadModal}
      />
    )
  }

  return (
    <section id='attachmentsSection'>
      {renderTaskUploadModal()}
      <div className='documents-section-header-row'>
        <h6>{strings.attachments}</h6>
        <Button variant='text' onClick={() => vm.toggleTaskUploadModal()}>
          {strings.add_attachments}
        </Button>
      </div>
      {renderAttachments()}
    </section>
  )
}

export default inject(
  'tasksStore',
  'localizationStore',
  'organizationsStore'
)(observer(DrawerDocumentsSection))
