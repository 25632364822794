import React from 'react'
import { UserTrainingPlansStore } from '../../training-plans/user-training-plans/store/UserTrainingPlansStore'
import { inject, observer } from 'mobx-react'
import { Grid, Typography } from '@material-ui/core'
import { QuizResultQuestionVM } from '../view-models/QuizResultQuestionVM'
import QuizResultOption from './QuizResultOption'
import './QuizResultQuestion.scss'

interface Props {
  vm: QuizResultQuestionVM
  userTrainingPlansStore?: UserTrainingPlansStore
}

const QuizResultQuestion: React.FC<Props> = ({ vm, userTrainingPlansStore }) => {
  if (!vm) return null

  const renderQuestionIndex = () => {
    return (
      <Grid item xs={1}>
        <Typography className={'question-text'}>{vm.index}.</Typography>
      </Grid>
    )
  }

  const renderQuestionTitle = () => {
    return (
      <Grid item xs={3}>
        <Typography className={'question-text'}>{vm.title}</Typography>
      </Grid>
    )
  }

  const renderOptions = () => {
    return (
      <Grid item xs={8} className={'quiz-question-options'}>
        {vm.options.map((o, i) => <QuizResultOption vm={o} key={i} />)}
      </Grid>
    )
  }

  const renderQuestion = () => {
    return (
      <Grid container item xs={12} className={'quiz-result-question'}>
        {renderQuestionIndex()}
        {renderQuestionTitle()}
        {renderOptions()}
      </Grid>
    )
  }



  return (
    <div id='QuizResultQuestion'>
      <div className={vm.isEvenQuestion ? 'isEven' : ''}>
        {renderQuestion()}
      </div>
    </div>
  )
}

export default inject('userTrainingPlansStore')(observer(QuizResultQuestion))
