import { action, observable, computed, reaction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Goal } from '../aggregate/Goal'
import { CategoryPickerVM } from '../../shared/category-picker/CategoryPickerVM'
import { ParticipantsSelectVM } from '../../participants-select/view-models/ParticipantsSelectVM'
import moment, { Moment } from 'moment'
import { Category } from '../../categories/aggregate/Category'
import { GoalsService } from '../service/GoalsService'
import { TargetModeType } from '../types/TargetModeType'
import { Worksheet } from '../../worksheets/aggregate/Worksheet'
import { IFacetDTO } from '../dto/IFacetDTO'
import { IValueFactResponse } from '../dto/IValueFactResponse'
import ParticipantVM from './ParticipantVM'
import { IntervalType } from '../types/IntervalType'
import { MediaItemsService } from '../../media-items/service/MediaItemsService'
import { TrainingPlan } from '../../training-plans/aggregates/TrainingPlan'
import PlanPreviewVM from './PlanPreviewVM'
import { ActiveCatalogPlan } from '../aggregate/ActiveCatalogPlan'
import { AudienceMember } from 'src/app/audience-members/aggregate/AudienceMember'

export class GoalEditVM {
  private rootStore: RootStore
  public goal: Goal
  private svc: GoalsService

  constructor(rootStore: RootStore, goal: Goal) {
    this.rootStore = rootStore
    this.goal = goal
    this.svc = new GoalsService()
    this.categoryPickerVM = new CategoryPickerVM(this.rootStore)
    this.participantsSelectVM = new ParticipantsSelectVM(this.rootStore, false, false)

    // this.loadSources()
    if (this.goal) {
      this.participantsSelectVM.setParticipants(this.goal.participants)
      this.categoryPickerVM.setAppliedCategory(this.goal.categoryId)
    }
    setTimeout(() => this.loadValueFacts(), 1000)

    reaction(
      () => this.participantsSelectVM.participants.length,
      () => setTimeout(() => this.loadValueFacts(), 500)
    )

    if (!goal.objectId && goal.isCatalogGoal) this.toggleAddToCatalog()

    reaction(
      () => JSON.stringify(this.goal),
      () => this.setIsDirty()
    )
    reaction(
      () => this.participantsSelectVM.participants.length,
      () => this.setIsDirty()
    )
  }

  @observable public participantsSelectVM: ParticipantsSelectVM = null
  @observable public categoryPickerVM: CategoryPickerVM = null
  @observable public currentTab: number = 0
  @observable public saveTried: boolean = false
  @observable public changingDataSource: boolean = false
  @observable public dateRangeModalOpen: boolean = false
  @observable public goalValueFacts: IValueFactResponse[] = []
  @observable public dataSourceOptions: any = []
  @observable public facetOptions: IFacetDTO[] = []
  @observable public measureModalOpen: boolean = false
  @observable public progressAnchorEl: any = null
  @observable public reminderAnchorEl: any = null
  @observable public toolbarAnchorEl: any = null
  @observable public trainingPlansModalOpen: boolean = false
  @observable public stagedTrainingPlanIds: string[] = []
  @observable public planToPreview: PlanPreviewVM = null
  @observable public addToCatalog: boolean = false
  @observable public isDirty: boolean = false
  @observable public stagedActivePlansToRemove: ActiveCatalogPlan[] = []

  @computed
  public get canCreateTrainings(): boolean {
    if (!this.rootStore.appStore.canCreateTrainings) return false
    if (!this.rootStore.organizationsStore.currentOrganization.isTrainingsEnabled) return false
    return true
  }

  @computed
  public get range(): Object {
    return { startDate: this.goal.startDate, endDate: this.goal.endDate }
  }

  @computed
  public get selectedAssociatedCatalogPlanIds(): string[] {
    if (this.goal.activeCatalogPlans.length === 0) {
      return []
    } else {
      return this.goal.activeCatalogPlans
        .filter(
          (e) => !this.stagedActivePlansToRemove.some((ee) => ee.catalogPlanId === e.catalogPlanId)
        )
        .map((e) => e.catalogPlanId)
    }
  }

  @computed
  public get selectedTrainingPlans(): TrainingPlan[] {
    const ids = this.stagedTrainingPlanIds.concat(this.selectedAssociatedCatalogPlanIds)
    return ids.map((id) => this.rootStore.trainingPlansCatalogStore.getCatalogTrainingPlan(id))
  }

  @computed
  public get availableTrainingPlans(): TrainingPlan[] {
    return this.rootStore.trainingPlansCatalogStore.orgCatalogTrainingPlans.filter(
      (tp) =>
        !this.selectedAssociatedCatalogPlanIds.includes(tp.objectId) &&
        !this.stagedTrainingPlanIds.includes(tp.objectId)
    )
  }

  @action
  public setIsDirty() {
    this.isDirty = true
  }

  @action
  public addToStagedPlanIds(id: string) {
    this.stagedTrainingPlanIds.push(id)
  }

  @action
  public addToIdsToRemove(id: string) {
    const obj = this.goal.activeCatalogPlans.filter((e) => e.catalogPlanId === id)[0]
    this.stagedActivePlansToRemove.push(obj)
  }

  @action
  public cancelAssociateTrainingPlans() {
    this.closeTrainingPlansModal()

    this.stagedTrainingPlanIds = []
    this.stagedActivePlansToRemove = []
  }

  @action
  public saveAssociatedTrainingPlans() {
    this.stagedTrainingPlanIds.forEach((id) => this.goal.addTrainingPlanId(id))
    this.stagedActivePlansToRemove.forEach((e) => this.goal.removeTrainingPlanId(e.catalogPlanId))
    if (this.goal.associatedTrainingPlanIds.length > 0) {
      this.goal.setAssociateToTrainingPlans(true)
    } else {
      this.goal.setAssociateToTrainingPlans(false)
    }
    this.closeTrainingPlansModal()
    this.stagedTrainingPlanIds = []
  }

  @action
  public addTrainingPlanToGoal(id: string) {
    this.goal.setAssociateToTrainingPlans(true)
    this.stagedTrainingPlanIds.push(id)
    // this.goal.addTrainingPlanId(id)
  }

  @action
  public removeTrainingPlanFromGoal(id: string) {
    const obj = this.goal.activeCatalogPlans.find((obj) => obj.catalogPlanId === id)
    this.stagedActivePlansToRemove.push(obj)
    this.goal.removeTrainingPlanId(id)
  }

  @action
  public setPlanToPreview(plan: TrainingPlan) {
    this.planToPreview = new PlanPreviewVM(this.rootStore, this, plan)
  }

  @action
  public clearPreviewPlan() {
    this.planToPreview = null
  }

  @action
  public async loadSources() {
    this.dataSourceOptions = await this.svc.getSources(this.rootStore.appStore.currentOrgId)
    if (this.dataSource) this.setFacetOptions(this.dataSource.id)
  }

  @computed
  public get participants(): ParticipantVM[] {
    return this.goalValueFacts.map(
      (valueFact) => new ParticipantVM(this.rootStore, this, valueFact)
    )
  }

  @action
  public async loadValueFacts() {
    const dto = this.goal.serialize()
    dto.participants = this.participantsSelectVM.participantsToDTO()
    this.goalValueFacts = await this.svc.getValueFacts(dto)
  }

  @computed
  public get worksheet(): Worksheet {
    return this.rootStore.worksheetsStore.getWorksheet(this.goal.worksheetId)
  }

  @computed
  public get dataSource(): any {
    if (!this.dataSourceOptions) return null
    return this.dataSourceOptions.filter((option) => option.id === this.goal.dataSourceId)[0]
  }

  @computed
  public get facet(): IFacetDTO {
    if (!this.facetOptions) return null
    return this.facetOptions.filter((option) => option.id === this.goal.facetId)[0]
  }

  @computed
  public get objectId(): string {
    return this.goal.objectId
  }

  @computed
  public get measureGoal(): boolean {
    return this.goal.measureGoal
  }

  @computed
  public get askAboutProgress(): boolean {
    return this.goal.askAboutProgress
  }

  @computed
  public get sendReminders(): boolean {
    return this.goal.sendReminders
  }

  @computed
  public get progressInterval(): IntervalType {
    return this.goal.progressInterval
  }

  @computed
  public get reminderInterval(): IntervalType {
    return this.goal.reminderInterval
  }

  @action
  public toggleDateRangeModal() {
    this.dateRangeModalOpen = !this.dateRangeModalOpen
  }

  @action
  public toggleMeasureModal() {
    this.measureModalOpen = !this.measureModalOpen
  }

  @action
  public setProgressAnchorEl(e) {
    this.progressAnchorEl = e
  }

  @action
  public setReminderAnchorEl(e) {
    this.reminderAnchorEl = e
  }

  @action
  public setCurrentTab(idx: number) {
    this.currentTab = idx
    if (this.currentTab === 4 && !this.goal.createdByUserId)
      this.goal.createdByUserId = this.rootStore.appStore.currentUserId
  }

  @action
  public setMeasureGoal(val) {
    this.goal.setMeasureGoal(val)
    if (val) this.toggleMeasureModal()
  }

  @action
  public toggleAskAboutProgress(e) {
    this.goal.toggleAskAboutProgress()
    if (this.askAboutProgress) this.setProgressAnchorEl(e)
  }

  @action
  public toggleSendReminders(e) {
    this.goal.toggleSendReminders()
    if (this.sendReminders) this.setReminderAnchorEl(e)
  }

  @action
  public createNewTrainingPlan() {
    const win = window.open('/#/trainingplans/edit/new', '_blank')
    if (win !== null) {
      win.focus()
    }
  }

  @action
  public openTrainingPlansModal() {
    this.trainingPlansModalOpen = true
  }

  @action
  public closeTrainingPlansModal() {
    this.trainingPlansModalOpen = false
  }

  @action
  public setToolbarAnchorEl(e: any) {
    this.toolbarAnchorEl = e
  }

  @action
  public setProgressInterval(val: IntervalType) {
    this.goal.setProgressInterval(val)
  }

  @action
  public setReminderInterval(val: IntervalType) {
    this.goal.setReminderInterval(val)
  }

  @computed
  public get dateRange() {
    return {
      startDate: moment(this.goal.startDate).toDate(),
      endDate: moment(this.goal.endDate).toDate(),
    }
  }

  @computed
  public get worksheets(): Worksheet[] {
    return this.rootStore.worksheetsStore.currentOrgWorksheets
  }

  @action
  public setWorksheetId(id: string) {
    this.goal.setWorkSheetId(id)
  }

  @computed
  public get createdBy(): AudienceMember {
    return this.rootStore.audienceMembersStore.getUser(this.goal.createdByUserId)
  }

  @computed
  public get category(): Category {
    return this.rootStore.categoriesStore.getCategory(this.categoryId)
  }

  @computed
  public get target(): number {
    return Math.floor(this.goal.target)
  }

  @computed
  public get name(): string {
    if (!this.goal) return ''
    return this.goal.name
  }
  @action
  public setName(val: string) {
    this.goal.setName(val)
  }

  @computed
  public get categoryId(): string {
    return this.goal.categoryId
  }
  @action
  public setCategory(id: string) {
    console.log(id)
    this.goal.setCategory(id)
  }

  @action
  public setRange(range) {
    range.startDate !== undefined && this.setStartDate(range.startDate)
    range.endDate !== undefined && this.setEndDate(range.endDate)
  }

  @computed
  public get startDate(): string {
    const s = this.rootStore.localizationStore.lzStrings.goals
    return moment(this.goal.startDate).format(s.start_date_format)
  }
  @action
  public setStartDate(date: Date) {
    this.goal.setStartDate(date)
    if (moment(date).isSameOrAfter(moment(this.endDate))) this.goal.setEndDate(date)
  }

  @computed
  public get endDate(): string {
    const s = this.rootStore.localizationStore.lzStrings.goals
    return moment(this.goal.endDate).format(s.end_date_format)
  }
  @action
  public setEndDate(date: Date) {
    this.goal.setEndDate(date)
  }

  @computed
  public get description(): string {
    return this.goal.description
  }
  @action
  public setDescription(val: string) {
    this.goal.setDescription(val)
  }

  @computed public get obstacles(): string {
    return this.goal.potentialObstacles
  }
  @action public setObstacles(val: string) {
    this.goal.setObstacles(val)
  }

  @computed public get dataSourceId(): string {
    return String(this.goal.dataSourceId)
  }
  @action public setDataSource(val) {
    this.changingDataSource = true
    this.goal.setDataSource(val)
    this.setFacetOptions(val)
  }

  @computed
  public get isCatalogGoal(): boolean {
    return this.goal.isCatalogGoal
  }

  @action
  public setFacetOptions(val: string) {
    if (!this.dataSourceOptions) return
    const source = this.dataSourceOptions.filter((source) => source.id === val)[0]
    this.facetOptions = source.facets
    if (this.changingDataSource) this.setFacetId(this.facetOptions[0].id)
    this.changingDataSource = false
  }

  @computed
  public get thumbnail(): string {
    return this.goal.thumbnail
  }

  @computed
  public get thumbnailFileName(): string {
    return this.goal.thumbnailFileName
  }

  @action
  public async generateSignature(callback: Function, paramsToSign: object): Promise<void> {
    const svc = new MediaItemsService()
    const signature = await svc.getCloudinarySignature(paramsToSign)
    callback(signature)
  }

  @action
  public toggleAddToCatalog() {
    this.addToCatalog = !this.addToCatalog
  }

  @action
  public showWidget() {
    window.cloudinary.openUploadWidget(
      {
        multiple: false,
        cloudName: 'rippleworx',
        resourceType: 'image',
        sources: ['local', 'url', 'image_search', 'camera', 'dropbox', 'google_drive'],
        showPoweredBy: false,
        apiKey: '914141713215552',
        uploadSignature: this.generateSignature,
      },
      (error, result) => {
        if (!error && result && result.event === 'success') {
          this.goal.setThumbnail(result.info)
        }
      }
    )
  }

  @computed
  public get facetId(): string {
    return String(this.goal.facetId)
  }
  @action
  public setFacetId(val) {
    this.goal.setFacetId(val)
  }

  @computed
  public get goalDirection(): string {
    return this.goal.goalDirection
  }
  @action
  public setGoalDirection(val) {
    this.goal.setGoalDirection(val)
  }

  @computed
  public get targetPercentage(): string {
    return String(this.goal.targetPercentage)
  }
  @action
  public setTargetPercentage(val) {
    this.goal.setTargetPercentage(val)
  }

  @computed
  public get targetInputValue(): string {
    return String(this.goal.targetInputValue)
  }
  @action
  public setTargetInputValue(val) {
    this.goal.setTargetInputValue(val)
  }

  @computed
  public get aggregationMethod(): string {
    return this.goal.aggregationMethod
  }
  @action
  public setAggregationMethod(val) {
    this.goal.setAggregationMethod(val)
  }

  @computed
  public get aggregationPeriod(): string {
    return this.goal.aggregationPeriod
  }
  @action
  public setAggregationPeriod(val) {
    this.goal.setAggregationPeriod(val)
  }

  @computed
  public get consistencyThreshold(): number {
    return this.goal.consistencyThreshold
  }
  @action
  public setConsistencyThreshold(val) {
    this.goal.setConsistencyThreshold(val)
  }

  @computed
  public get targetMode(): TargetModeType {
    return this.goal.targetMode
  }
  @action
  public setTargetMode(val: TargetModeType) {
    this.goal.setTargetMode(val)
  }

  @computed
  public get nameValid(): boolean {
    if (!this.name && this.saveTried) return false
    return true
  }

  @computed
  public get categoryValid(): boolean {
    if (!this.categoryId && this.saveTried) return false
    return true
  }

  @computed
  public get dateRangeValid(): boolean {
    if (!this.startDate && this.saveTried) return false
    if (!this.endDate && this.saveTried) return false
    return true
  }

  @computed
  public get dataSourceValid(): boolean {
    return true
    if (!this.dataSourceId && this.saveTried) return false
    return true
  }

  @computed
  public get facetValid(): boolean {
    return true
    if (!this.facetId && this.saveTried) return false
    return true
  }

  @computed
  public get targetValueValid(): boolean {
    if (!this.targetInputValue && this.targetMode === 'amount' && this.saveTried) return false
    return true
  }

  @computed
  public get targetPercentageValid(): boolean {
    if (this.objectId && this.targetValueValid) return true
    if (!this.targetPercentage && this.targetMode === 'percentage' && this.saveTried) return false
    return true
  }

  @computed
  public get participantsValid(): boolean {
    if (this.participantsSelectVM.participants.length === 0 && this.saveTried) return false
    return true
  }

  @computed
  public get saveValid(): boolean {
    if (
      !this.nameValid ||
      !this.categoryValid ||
      !this.dateRangeValid ||
      !this.dataSourceValid ||
      !this.facetValid ||
      !this.participantsValid
    )
      return false
    if (!this.targetValueValid && !this.targetPercentageValid) return false

    return true
  }

  @action
  public continue() {
    if (this.currentTab === 2) {
      this.save()
    } else {
      this.setCurrentTab(this.currentTab + 1)
    }
  }

  @action
  public delete() {
    this.svc.deleteGoal(this.objectId)
    this.rootStore.appStore.router.push('/dashboard/impact/goals')
  }

  @action
  public async save() {
    this.saveTried = true
    if (!this.saveValid) return
    const dto = this.goal.serialize()
    if (this.addToCatalog) await this.createCatalogGoal() //Create Goal in Catalog
    dto.participants = this.participantsSelectVM.participantsToDTO()
    if (dto.participants.length === 0) {
      this.isDirty = false
      this.rootStore.appStore.router.push('/dashboard/impact/goals')
      return
    }
    if (this.objectId) {
      await this.svc.editGoal(dto)
    } else {
      await this.svc.saveGoal(dto)
    }
    this.isDirty = false
    this.rootStore.appStore.router.push('/dashboard/impact/goals')
  }

  @action
  public async createCatalogGoal() {
    const goalClone = this.goal.clone()
    const dto = goalClone.serialize()
    dto.objectId = ''
    dto.createdByUserId = ''
    dto.participatingUserIds = []
    dto.participants = []
    dto.isCatalogGoal = true
    await this.svc.saveCatalogGoal(dto)
  }
}
