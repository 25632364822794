import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { GroupsAndRolesStore } from '../../GroupsAndRolesStore'
import SortableTree from 'react-sortable-tree'
import './WeightProfileModal.scss'
import MuiConfirmDialog from 'src/app/shared/MuiConfirmDialog'
import CopyWeightProfile from './CopyWeightProfile'
import Searchbar from 'src/app/shared/Searchbar'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import MuiConfirmDeleteDialog from 'src/app/shared/MuiConfirmDeleteDialog'
import { CategoryVM } from '../../view-models/CategoryVM'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import WeightProfileImportModal from './import/WeightProfileImportModal'
import ImportModal from './ImportModal'

interface Props {
  groupsAndRolesStore?: GroupsAndRolesStore
}

const WeightProfileModal: React.FC<Props> = ({ groupsAndRolesStore }) => {
  const { groupsAndRolesWidgetVM: parentVM } = groupsAndRolesStore
  const { weightProfileVM: vm } = groupsAndRolesStore.groupsAndRolesWidgetVM
  if (!vm) return null
  const renderDialogTitle = () => {
    return (
      <DialogTitle>
        <div>
          Skills to Role Map
          <Tooltip title={'Import Weight Profiles'} placement='bottom'>
            <IconButton className='icon' size='small' onClick={() => parentVM.toggleImportModal()}>
              <ImportExportIcon />
            </IconButton>
          </Tooltip>
        </div>
        <IconButton onClick={() => parentVM.tryWeightProfileModalClose()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
    )
  }

  const renderChildrenMissingError = () => {
    if (!vm.showChildrenMissingMessage) return null
    return (
      <Typography className='error-msg'>
        <WarningRoundedIcon />
        All selected Skills must have atleast one selected child
      </Typography>
    )
  }

  const renderWeightInvalidError = () => {
    if (!vm.showWeightInvalidMessage) return null
    return (
      <Typography className='error-msg weight-invalid-msg'>
        <WarningRoundedIcon />
          The total weight of parent Skills must equal '100%'.
      </Typography>
    )
  }

  const renderDeleteButton = () => {
    if (!vm.objectId) return null
    return (
      <Button
        disabled={vm.isProcessing}
        variant='outlined'
        className='white-button'
        onClick={() => parentVM.toggleDeleteDialog()}
        size='small'
      >
        <DeleteIcon />
      </Button>
    )
  }

  const renderToolbar = () => {
    return (
      <Grid item xs={12} className='weight-profile-toolbar'>
        {renderRoleSelector()}
        <div className='toolbar-buttons'>
          <CopyWeightProfile />
          <Button
            disabled={vm.isDirty}
            variant='text'
            onClick={() => parentVM.toggleShowCopyOptions()}
          >
            COPY WEIGHTS
          </Button>
          {renderDeleteButton()}
          <Button variant='text' onClick={() => parentVM.tryWeightProfileModalClose()}>
            CANCEL
          </Button>
          <Button className='white-button' variant='outlined' onClick={() => vm.save()}>
            SAVE
          </Button>
        </div>
      </Grid>
    )
  }

  const renderSearchbar = () => {
    return (
      <Grid
        container
        spacing={2}
        className={
          vm.showChildrenMissingMessage || vm.showWeightInvalidMessage
            ? 'weight-profile-searchbar child-message-shown'
            : 'weight-profile-searchbar'
        }
      >
        <Grid item xs={8}>
          {renderChildrenMissingError()}
          {renderWeightInvalidError()}
        </Grid>

        <Grid item xs={4}>
          <Searchbar
            focused={parentVM.typedFilterText.length > 0}
            className='search'
            searchValue={parentVM.typedFilterText}
            onSearchChange={e => {
              parentVM.setQuickFilter(e.target.value)
            }}
          />
        </Grid>
      </Grid>
    )
  }

  const renderRoleSelector = () => {
    return (
      <Select
        className='role-selector'
        variant='outlined'
        value={parentVM.selectedRoleId}
        onChange={e => parentVM.tryRoleChange((e.target.value as unknown) as string)}
      >
        {parentVM.availableRoles.map((e, index) => (
          <MenuItem key={index} value={e.id}>
            {e.name}
          </MenuItem>
        ))}
      </Select>
    )
  }

  const renderCategoryTree = () => {
    return (
      <div id='CategoriesTree'>
        <SortableTree
          canDrag={false}
          treeData={[...vm.treeData]}
          onChange={(treeData: CategoryVM[]) => {
            vm.onChange(treeData)
          }}
          scaffoldBlockPxWidth={30}
          onlyExpandSearchedNodes={true}
          searchQuery={parentVM.typedFilterText}
          rowHeight={60}
          generateNodeProps={node => ({
            className: `tree-item primary-border-${node.node.depth} primary-${node.node.depth -
              1} selected-${node.node.isChecked} node-invalid-${node.node.inValid}`,
            id: `node-${node.node.id}`,
            buttons: [
              <TextField
                className={node.node.isChecked ? '' : 'cat-input-hidden'}
                value={String(Math.floor(Number(node.node.weightValue)))}
                variant='outlined'
                onFocus={e => e.target.select()}
                onClick={e => e.stopPropagation()}
                onChange={e => {
                  vm.setCategoryWeightValue(node.node.id, e.target.value)
                }}
                // disabled={!node.node.isChecked}
                InputProps={{
                  endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                }}
              />,
            ],
            onClick: e => {
              vm.toggleCatSelected(node.node.id, e)
            },
          })}
        />
      </div>
    )
  }

  const renderDialogContent = () => {
    return (
      <DialogContent>
        {renderToolbar()}
        {renderSearchbar()}
        {renderCategoryTree()}
      </DialogContent>
    )
  }

  return (
    <Dialog
      id='weightProfileModal'
      open={parentVM.weightProfileModalOpen}
      onClose={() => parentVM.tryWeightProfileModalClose()}
    >
      {renderDialogTitle()}
      {renderDialogContent()}
      <MuiConfirmDialog
        key={1}
        open={parentVM.confirmationMessageShown}
        onClose={() => parentVM.toggleConfirmDialog()}
        onConfirm={() => parentVM.toggleWeightProfileModalOpen()}
        confirmMessage={'All changes will be lost when you leave, are you sure?'}
      />
      <MuiConfirmDialog
        key={2}
        open={parentVM.roleConfirmationMessageShown}
        onClose={() => parentVM.toggleRoleConfirmDialog()}
        onConfirm={() => parentVM.setRole()}
        confirmMessage={'All changes will be lost when you switch Roles, are you sure?'}
      />
      <MuiConfirmDeleteDialog
        onClose={() => parentVM.toggleDeleteDialog()}
        open={parentVM.showDeleteDialog}
        itemName={parentVM.selectedRoleName}
        itemType={'Weight Profile'}
        onConfirm={() => vm.delete()}
      />
      <WeightProfileImportModal />
      <ImportModal />
    </Dialog>
  )
}

export default inject(
  'weightProfilesStore',
  'localizationStore',
  'groupsAndRolesStore'
)(observer(WeightProfileModal))
