import React from 'react'
import { inject, observer } from 'mobx-react'
import {
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ChevronDownIcon from '@material-ui/icons/ExpandMore'
import rootStore from '../../../stores/RootStore'
import { ChildCategoryRowVM } from '../../view-models/tree/ChildCategoryRowVM'
import { PulseQuestionVM } from '../../view-models/tree/PulseQuestionVM'
import PulseQuestion from './PulseQuestion'
import './CategoryRow.scss'

interface Props {
  vm: ChildCategoryRowVM
}

const ChildCategoryRow: React.FC<Props> = ({ vm }) => {
  if (!vm) return null

  const renderExpandIcon = () => {
    let icon = <ChevronRightIcon className={'expand-icon'} />
    if (vm.isExpanded) icon = <ChevronDownIcon className={'expand-icon'} />
    return (
      <IconButton
        disableRipple
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          vm.toggleIsExpanded(!vm.isExpanded)
        }}
        className={'icon-container'}
      >
        {icon}
      </IconButton>
    )
  }

  const renderBuildCard = () => {
    if (vm.pulseQuestionsCount === 0) return
    if (vm.pulseSelectedQuestionsCount === 0) return
    return (
      <Grid container className={'child-container-build'}>
        <Grid className={'child-sub-container'} item xs={12}>
          <Grid
            className={'child-card'}
            item
            xs={12}
            onClick={() => vm.toggleIsExpanded(!vm.isExpanded)}
          >
            <div id={'ChildCardTitleBuild'}>
              <div className={'child-left'}>
                {renderExpandIcon()}
                <Typography className='child-header'>{`${vm.name} (${vm.pulseSelectedQuestionsCount})`}</Typography>
              </div>
              {renderCurrentSurveySelectedQuestionsCount()}
            </div>
          </Grid>
          {renderBuildQuestions()}
        </Grid>
      </Grid>
    )
  }

  const renderCurrentSurveySelectedQuestionsCount = () => {
    if (!vm.currentSurveyPulseSelectedQuestionsCount) return null
    return (
      <Typography className='child-header'>{`${vm.currentSurveyPulseSelectedQuestionsCount} selected`}</Typography>
    )
  }

  const handleClick = (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    event.preventDefault()
    vm.areAllQuestionsSelected
      ? vm.unselectRecursiveCategoryQuestions()
      : vm.selectRecursiveCategoryQuestions()
  }

  const renderFocusCard = () => {
    if (vm.pulseQuestionsCount === 0) return
    return (
      <Grid container className={`child-container-focus ${vm.isExpanded ? 'expanded' : ''}`}>
        <Grid className={'child-sub-container'} item xs={12}>
          <Grid
            className={'child-card'}
            item
            xs={12}
            onClick={() => vm.toggleIsExpanded(!vm.isExpanded)}
          >
            <div id={'ChildCardTitle'}>
              {renderExpandIcon()}
              <Typography className='child-header'>{`${vm.name}`}</Typography>
              <Typography component='span' className='category-header-element-questions-number'>
                {vm.pulseQuestionsCount}
              </Typography>
            </div>
            {vm.pulseQuestionsCount > 0 ? (
              <div className='child-category-header-element-selection-btn' onClick={handleClick}>
                <Checkbox
                  checked={vm.areAllQuestionsSelected}
                  onClick={handleClick}
                  className='sub-category-header-element-check'
                />
                <Typography className='sub-category-header-element-text'>{`${vm.areAllQuestionsSelected ? 'Unselect' : 'Select'
                  }  All (${vm.pulseQuestionsCount})`}</Typography>
              </div>
            ) : undefined}
          </Grid>
          {renderFocusQuestions()}
        </Grid>
      </Grid>
    )
  }

  const renderBuildQuestions = () => {
    if (!vm.isExpanded) return null
    if (!vm.isLoaded) return renderSpinner()
    const questions = []
    const existing = rootStore.pulseCampaignsStore.viewModels.editVM.pulseCampaign.questions

    vm.pulseQuestionRows.forEach((q: PulseQuestionVM, i: number) => {
      const found = existing.find((e) => e.objectId === q.objectId)
      if (found) questions.push(<PulseQuestion key={i} vm={q} />)
    })
    return (
      <>
        <Grid item className={'questions-container'}>
          {questions}
        </Grid>
      </>
    )
  }

  const renderFocusQuestions = () => {
    if (!vm.isExpanded) return null
    if (!vm.isLoaded) return renderSpinner()
    const questions = []

    vm.pulseQuestionRows.forEach((q: PulseQuestionVM, i: number) => {
      questions.push(<PulseQuestion key={i} vm={q} />)
    })
    return (
      <>
        <Grid item className={'questions-container'}>
          {questions}
        </Grid>
        <div className={'no-child-container'}>{renderNoQuestions()}</div>
      </>
    )
  }

  const renderSpinner = () => {
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  const renderNoQuestions = () => {
    if (vm.pulseQuestionRows.length) return null
    return <span>No Questions To Show</span>
  }

  return <>{vm.isBuild ? renderBuildCard() : renderFocusCard()}</>
}

export default inject()(observer(ChildCategoryRow))
