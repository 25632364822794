import { action, computed, observable } from 'mobx'
import moment from 'moment'
import { RootStore } from '../../../../stores/RootStore'
import { PulseCampaignStatus } from '../../../type/PulseCampaignStatus'
import { AllCampaignsCardVM } from './AllCampaignsCardVM'

export class FilterBarVM {
  private rootStore: RootStore
  protected quickFilterTO: NodeJS.Timer

  constructor(rootStore: RootStore, parentVM: AllCampaignsCardVM) {
    this.rootStore = rootStore
    this.parentVM = parentVM
  }

  @observable public typedFilterText: string = ''
  @observable public selectedStatuses: PulseCampaignStatus[] = ['initiated']
  @observable public startDate: Date = null
  @observable public endDate: Date = null
  @observable public dateRangeModalOpen: boolean = false
  @observable public parentVM: AllCampaignsCardVM = null

  @computed
  public get lzStrings() {
    return this.rootStore.localizationStore.lzStrings
  }

  @computed
  public get isInitiatedSelected() {
    return this.selectedStatuses.includes('initiated')
  }

  @computed
  public get isCompletedSelected() {
    return this.selectedStatuses.includes('completed')
  }

  @action
  public toggleStatusSelection(status: PulseCampaignStatus) {
    this.selectedStatuses = [status]
    this.parentVM.onFilterChanged()
  }

  @action
  public setQuickFilter(val: string) {
    this.typedFilterText = val
    if (this.quickFilterTO) clearTimeout(this.quickFilterTO)
    this.quickFilterTO = setTimeout(() => this.parentVM.onFilterChanged(), 1000)
  }

  @action
  public setDateRange(range) {
    range.startDate !== undefined && this.setStartDate(range.startDate)
    range.endDate !== undefined && this.setEndDate(range.endDate)
  }

  @action
  public setStartDate(date: Date) {
    this.startDate = date
  }

  @action
  public setEndDate(date: Date) {
    this.endDate = date
  }

  @action
  public clearDateRange() {
    this.startDate = null
    this.endDate = null
    this.parentVM.onFilterChanged()
  }

  @action
  public toggleDateRangeModal() {
    if (!this.dateRangeModalOpen) {
      if (!this.startDate) this.startDate = new Date()
      if (!this.endDate) this.endDate = new Date()
      this.dateRangeModalOpen = true
    } else {
      this.dateRangeModalOpen = false
      this.parentVM.onFilterChanged()
    }
  }

  @computed
  public get dateRange() {
    return {
      startDate: moment(this.startDate).toDate(),
      endDate: moment(this.endDate).toDate(),
    }
  }

  @computed
  public get hasDateRange(): boolean {
    if (this.startDate && this.endDate) return true
    return false
  }

  @computed
  public get dateRangeButtonText() {
    if (!this.hasDateRange) return 'All Time'
    return `${moment(this.startDate).format(this.lzStrings.calendar.short_date_format)} - ${moment(
      this.endDate
    ).format(this.lzStrings.calendar.short_date_format)}`
  }
}
