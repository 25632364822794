import { RootStore } from '../../stores/RootStore'
import { computed, observable, action } from 'mobx'
import { CellClickedEvent, ColDef, GridOptions, RowClickedEvent } from 'ag-grid-community'
import { EmailTemplateRowVM } from './EmailTemplateRowVM'
import { nameOf } from '../../shared/nameOf'
import { AGGridVM } from '../../shared/ag-grid/AGGridVM'
import { EmailTemplate } from '../aggregate/EmailTemplate'

export class EmailTemplatesTableVM extends AGGridVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    super()
    this.rootStore = rootStore
  }

  @observable public confirmRevertDialogOpen: boolean = false
  @observable public confirmDeleteDialogOpen: boolean = false
  @observable public selectedRow: EmailTemplateRowVM = undefined
  @observable public isSystemTable: boolean = true

  public async onRecordUpdated(obj: EmailTemplate) {
    const foundTemplate = this.rootStore.emailTemplatesStore.allEmailTemplates.find(r => r.objectId === obj.objectId)
    if (!foundTemplate) return
    const rowNode = this.gridApi.getRowNode(obj.objectId)
    if (!rowNode) return
    rowNode.setData(new EmailTemplateRowVM(this.rootStore, obj, this))
    this.refresh()
  }

  @action
  public refresh() {
    if (!this.gridApi) return
    this.gridApi.refreshCells()
    this.gridApi.redrawRows()
    this.rootStore.emailTemplatesStore.refreshEmailTemplates()
  }

  protected onGridReadied(): void {
    this.autoSizeAll()
    this.rootStore.emailTemplatesStore.onRecordUpdated = (e) => this.onRecordUpdated(e)
  }

private autoSizeAll() {
  var allColumnIds = [];
  this.columnApi.getAllColumns().forEach(function(column) {
      allColumnIds.push(column.getColId);
  });
  this.columnApi.autoSizeColumns(allColumnIds);
}

  @computed
  public get rows() {
    if (this.isSystemTable){
      return this.rootStore.emailTemplatesStore.sysEmailTemplates.map(
        (template) => new EmailTemplateRowVM(this.rootStore, template, this)
      )
    } else {
      return this.rootStore.emailTemplatesStore.orgEmailTemplates.map(
        (template) => new EmailTemplateRowVM(this.rootStore, template, this)
      )
    }
  }

  @action
  public revertEmailTemplate() {
    this.selectedRow.revertEmailTemplate()
    this.selectedRow = undefined
    this.confirmRevertDialogOpen = false
  }

  @action
  public deleteEmailTemplate() {
    this.selectedRow.deleteEmailTemplate()
    this.selectedRow = undefined
    this.confirmDeleteDialogOpen = false
  }

  @action
  public cancelRevertConfirmDialog() {
    this.selectedRow = undefined
    this.confirmRevertDialogOpen = false
  }

  @action
  public cancelDeleteConfirmDialog() {
    this.selectedRow = undefined
    this.confirmDeleteDialogOpen = false
  }

  @action
  public setQuickFilter(val: string) {
    this.typedFilterText = val
    if (this.quickFilterTO) clearTimeout(this.quickFilterTO)
    this.quickFilterTO = setTimeout(() => {
      this.gridApi.setQuickFilter(this.typedFilterText)
      this.updatePagination()
      this.gridApi.refreshCells()
    }, 1000)
  }

  @action
  public getRowNodeId(row): string {
    return row.objectId
  }

  @computed
  public get shouldRender(): boolean {
    if (!this.rootStore.emailTemplatesStore.loaded) return false
    return true
  }

  @computed
  public get hasFilter(): boolean {
    return Boolean(this.typedFilterText)
  }

  @computed
  public get canManage() {
    if (this.rootStore.appStore.isSystemAdmin) return true
    if (this.rootStore.appStore.isOrgAdmin) return true
    if (!this.rootStore.userStore.currentOrganization) return false
    return false
  }

  @action
  public canDeleteType(notificationTypeId: string) {
    const replacedText = notificationTypeId
    if (replacedText === 'DEFAULT') return false
    if (replacedText === 'password-reset') return false
    if (replacedText === `campaign-survey-published`) return false
    if (replacedText === `survey-published`) return false
    return true
  }

  public getGridOptions(): GridOptions {
    return {
      ...this.baseGridOptions,
      rowData: this.rows,
      onRowClicked: (e) => this.rowClicked(e),
      getRowNodeId: (e) => this.getRowNodeId(e),
      columnDefs: this.ensureTooltipFields(this.columnDefs),
    }
  }

  public rowClicked(e: RowClickedEvent) {
    console.debug(e)
  }

  @computed
  public get isLoading(): boolean {
    return this.rootStore.emailTemplatesStore.loaded
  }

  @computed
  public get columnDefs(): ColDef[] {
    const s = this.rootStore.localizationStore.lzStrings
    return [
      {
        headerName: 'Preview',
        cellRenderer: 'previewCell',
        sortable: false,
        onCellClicked: null,
        width: 110,
        maxWidth: 110,
      },
      {
        headerName: 'Name',
        field: nameOf<EmailTemplateRowVM, string>((e) => e.name),
        sort: 'asc',
        width: 140,
        colId: 'name',
        onCellClicked: (e: CellClickedEvent) => {
          const rowVM: EmailTemplateRowVM = e.data
          if (rowVM && rowVM.openEmailTemplate) rowVM.openEmailTemplate()
        },
      },
      {
        headerName: 'Type',
        field: nameOf<EmailTemplateRowVM, string>((e) => e.notificationTypeId),
        width: 140,
        colId: 'notificationTypeId',
        onCellClicked: (e: CellClickedEvent) => {
          const rowVM: EmailTemplateRowVM = e.data
          if (rowVM && rowVM.openEmailTemplate) rowVM.openEmailTemplate()
        },
        valueFormatter: ({ value }) => {
          return value
        },
      },
      {
        headerName: 'Customized',
        field: nameOf<EmailTemplateRowVM, boolean>((e) => e.isDefault),
        width: 130,
        maxWidth: 130,
        hide: this.isSystemTable,
        valueFormatter: ({ value }) => {
          const isDefault = value
          return isDefault ? 'No' : 'Yes'
        },
        colId: 'isDefault',
        onCellClicked: (e: CellClickedEvent) => {
          const rowVM: EmailTemplateRowVM = e.data
          if (rowVM && rowVM.openEmailTemplate) rowVM.openEmailTemplate()
        },
      },
      {
        headerName: 'Action',
        cellRenderer: 'actionsCell',
        sortable: false,
        onCellClicked: null,
      },
    ]
  }
}
