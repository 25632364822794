import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { UserRowVM } from '../view-models/UserRowVM'
import { AgGridReact } from 'ag-grid-react'
import UserRow from './UserRow'
import Searchbar from '../../shared/Searchbar'
import { UsersAGGridVM } from '../view-models/UsersAGGridVM'
import '../styles/UsersList.scss'
import HiddenParticipantsMenu from './HiddenParticipantsMenu'

interface Props {
  usersAGGridVM: UsersAGGridVM
}

const UsersList: FC<Props> = ({ usersAGGridVM }) => {
  if (!usersAGGridVM) return null

  const renderNameCell = (props) => {
    const row: UserRowVM = props.data
    if (!row) return null
    return <UserRow user={row} style={{}} />
  }

  const renderGrid = () => {

    const computeHiddenClass = (params) => {
      if (params && params.data && params.data.orgUser && params.data.orgUser.isHiddenInOrg)
        return 'hidden'
      return ''
    }

    return (
      <div id='UsersListGridContainer'>
        <div
          id='UsersListGrid'
          style={{ display: usersAGGridVM.shown ? undefined : 'none' }}
          className='ag-theme-alpine'
        >
          <div id='UsersListAGGrid'>
            <AgGridReact
              gridOptions={{
                ...usersAGGridVM.getGridOptions(),
                frameworkComponents: {
                  nameCell: renderNameCell,
                },
              }}
              getRowClass={computeHiddenClass}
            />
          </div>
        </div>
      </div>
    )
  }

  const renderSearchbox = () => {
    if (!usersAGGridVM.shown) return
    return (
      <Searchbar
        focused={usersAGGridVM.hasFilter}
        className='search'
        searchValue={usersAGGridVM.typedFilterText}
        onSearchChange={(e) => usersAGGridVM.setQuickFilter(e.target.value)}
      />
    )
  }

  const renderHiddenParticipantsMenu = () => (
    <HiddenParticipantsMenu className='hidden-menu' vm={usersAGGridVM} />
  )

  return (
    <div
      id='UsersList'
      style={{
        display: usersAGGridVM.shown ? undefined : 'none',
      }}
    >
      <div id='UsersListCol'>
        <div className={'col-actions'}>
          {renderSearchbox()}
          {renderHiddenParticipantsMenu()}
        </div>
        {renderGrid()}
      </div>
    </div>
  )
}

export default observer(UsersList)
