import React from 'react'
import { inject, observer, useLocalStore } from 'mobx-react'
import { PulseCategoriesManageVM } from '../view-models/PulseCategoriesManageVM'
import rootStore from '../../stores/RootStore'
import TopToolbar from './TopToolbar'
import PulseCategoriesTree from './PulseCategoriesTree'
import './PulseCategoriesManage.scss'

const PulseCategoriesManage: React.FC = () => {
  const localStore = useLocalStore(() => ({
    vm: new PulseCategoriesManageVM(rootStore),
  }))

  const vm = localStore.vm
  return (
    <div id='PulseCategoriesManage'>
      <div className='pulse-categories-container'>
        <TopToolbar vm={vm} />
        <PulseCategoriesTree vm={vm} />
      </div>
    </div>
  )
}

export default inject()(observer(PulseCategoriesManage))
