import React from 'react'
import { inject, observer, useLocalStore } from 'mobx-react'
import { Card, CircularProgress } from '@material-ui/core'
import rootStore from '../../../stores/RootStore'
import './OrganizationUsersList.scss'
import OrganizationUsersListTableHeader from './OrganizationUsersListTableHeader'
import OrganizationUsersListTable from './OrganizationUsersListTable'
import { OrganizationUsersListManageVM } from '../../view-models/list/OrganizationUsersListManageVM'
import FilterPopup from './FilterPopup'
import OrganizationUsersSnackbar from '../snackbar/OrganizationUsersSnackbar'
import ColumnPopup from './ColumnPopup'

const OrganizationUsersList: React.FC = () => {
  const localStore = useLocalStore(() => ({
    vm: new OrganizationUsersListManageVM(rootStore),
  }))
  const vm = localStore.vm

  if (!vm) return

  const renderSpinner = () => {
    if (vm.shouldRender && !vm.isLoading) return
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  const renderTable = () => {
    if (vm.isLoading) return
    return (
      <Card elevation={0} id='OrgUsersListTable'>
        <OrganizationUsersListTableHeader organizationUsersListManageVM={vm} />
        <OrganizationUsersListTable
          organizationUsersListManageVM={vm}
          localizationStore={rootStore.localizationStore}
        />
      </Card>
    )
  }

  return (
    <div id='OrgUsersListManage'>
      {renderSpinner()}
      <div className='org-users-list-manage-container'>
        <FilterPopup vm={vm.tableVM} />
        <ColumnPopup vm={vm.tableVM} />
        {renderTable()}
      </div>

      <OrganizationUsersSnackbar vm={vm} />
    </div>
  )
}

export default inject()(observer(OrganizationUsersList))
