import React from 'react'
import clsx from 'clsx'
import { inject, observer } from 'mobx-react'
import { ButtonBase } from '@material-ui/core'
import LocalizationStore from '../../../localization/LocalizationStore'
import { LabelsStore } from '../../../labels/store/LabelsStore'
import { EmailTemplatePreviewVM } from '../../view-models/EmailTemplatePreviewVM'
import './EmailTemplatesEditorViewToggle.scss'

interface Props {
  vm: EmailTemplatePreviewVM
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
}

const EmailTemplatesEditorViewToggle: React.FC<Props> = ({ vm, localizationStore, labelsStore }) => {
  const { lzStrings } = localizationStore

  const renderActive = () => {
    return (
      <ButtonBase
        disabled={vm.isLoading || vm.isSaving}
        onClick={() => vm.setTabIndex(0)}
        className={clsx('tab-button', {
          active: vm.tabIndex === 0,
        })}
      >
        HTML
      </ButtonBase>
    )
  }

  const renderResponses = () => {
    return (
      <ButtonBase
        disabled={vm.isLoading || vm.isSaving}
        onClick={() => vm.setTabIndex(1)}
        className={clsx('tab-button', {
          active: vm.tabIndex === 1,
        })}
      >
        Test Data
      </ButtonBase>
    )
  }

  return (
    <div id='EmailTemplateEditorTabs'>
      <div className='tab-btns'>
        {renderActive()}
        {renderResponses()}
      </div>
    </div>
  )
}

export default inject('localizationStore', 'labelsStore')(observer(EmailTemplatesEditorViewToggle))
