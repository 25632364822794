import React from 'react'
import { IndexRoute, Route } from 'react-router'
import Layout from '../../layout/Layout'
import SurveyEditScreen from '../../surveys/views/edit/SurveyEditScreen'
import { authenticate } from '../../auth/routes/AuthenticatedRoute'
import SurveyTemplateSelectWrapper from '../../survey-templates/views/manageV2/template-select/SurveyTemplateSelectWrapper'
import CreateSurveyScreenWrapper from '../../surveys/views/dialogs/CreateSurveyScreenWrapper'
import SurveyTypesSAEditWrapper from '../../survey-types/views/system/SurveyTypesSAEditWrapper'

export default function getSurveyTypeRoutes() {
  return (
    <Route key={'svy0'} component={authenticate(Layout)} path='surveyTypes'>
      <IndexRoute onEnter={(nextState, replace) => replace('/dashboard/surveys')} />
      <Route key={'svy2'} path='edit/:screenType/:objectId' component={SurveyTypesSAEditWrapper} />
    </Route>
  )
}
