import { observable, action, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { PulseSet } from '../aggregate/PulseSet'
import { PulseSetsStore } from '../store/PulseSetsStore'
import { PulseSetEditVM } from '../view-models/PulseSetEditVM'


export class PulseSetsViewModelsService {
  private rootStore: RootStore
  private pulseSetsStore: PulseSetsStore

  constructor(rootStore: RootStore, pulseSetsStore: PulseSetsStore) {
    this.rootStore = rootStore
    this.pulseSetsStore = pulseSetsStore
  }

  @observable public editVM: PulseSetEditVM = null

  public async loadPulseSetEditVM(objectId: string) {
    if (objectId === 'empty') return this.editVM = null
    if (objectId === 'new') return this.editVM = new PulseSetEditVM(this.rootStore, PulseSet.create())
    const pulseSet = await this.pulseSetsStore.getPulseSet(objectId)
    this.editVM = new PulseSetEditVM(this.rootStore, pulseSet.clone())
  }

}
