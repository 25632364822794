import { RootStore } from '../../stores/RootStore'
import Parse from 'parse'
import { observable, action, computed } from 'mobx'
import { deserialize } from 'serializr'
import { UserAnnouncement } from '../aggregate/UserAnnouncement'
import { IUserAnnouncementDTO } from '../dtos/IUserAnnouncementDTO'
import { DataStore } from '../../shared/data/DataStore'

export class UserAnnouncementsStore extends DataStore<UserAnnouncement, IUserAnnouncementDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, UserAnnouncement, 'userAnnouncements', [
      'userId',
      'isRead',
      'announcementId',
      'readDateTime',
      'organizationId',
      'body',
      'title',
      'createdByUserId',
      'createdAt',
      'updatedAt',
      'participants',
      'attachments',
      'likes',
      'views',
      'liked',
      'ownerUserId',
      'showAnnouncementOwner',
      'fk_createdByUser',
      'fk_ownerUser',
    ])
  }

  @observable public loaded: boolean = false

  @computed
  public get userAnnouncements(): UserAnnouncement[] {
    return this.records
  }

  @action
  public onListRecordsLoaded() {
    this.loaded = true
  }

  protected getBaseQuery() {
    const query = new Parse.Query(this.className)
    query.equalTo('organizationId', this.rootStore.appStore.currentOrgId)
    query.equalTo('userId', this.rootStore.appStore.currentUserId)
    query.notEqualTo('isDeleted', true)
    return query
  }

  public getUserAnnouncement(objectId): UserAnnouncement {
    return this.userAnnouncements.find((userAnnouncement) => userAnnouncement.objectId === objectId)
  }

  public deleteUserAnnouncement(objectId) {
    this.userAnnouncements.splice(this.getUserAnnouncementIndex(objectId), 1)
  }

  private getUserAnnouncementIndex(objectId): number {
    return this.userAnnouncements.findIndex((e) => e.objectId === objectId)
  }
}
