import { RootStore } from '../../stores/RootStore'
import { IUserInfoOrganizationResult } from '../interfaces/IUserInfoResult'

export class OrganizationSelectService {
  private rootStore: RootStore
  private organizations: IUserInfoOrganizationResult[]

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public selectOrganization(organizations: IUserInfoOrganizationResult[]): string {
    this.organizations = organizations
    //TODO: Refactor each one of these blocks into their own methods
    console.log('selecting org: currentOrgId: ' + this.rootStore.appStore.currentOrgId)

    //if currentOrgId in localstorage is not viable then setOrg('')
    if (
      this.rootStore.appStore.currentOrgId &&
      !this.userHasAccessToOrg(this.rootStore.appStore.currentOrgId)
    ) {
      console.log('clearing org. user doesnt have access')
      this.rootStore.appStore.clearCurrentOrg()
    }

    // has last visited org and it is found in their available orgs?
    if (
      !this.rootStore.appStore.currentOrgId &&
      this.rootStore.userStore.user.currentOrgId &&
      this.userHasAccessToOrg(this.rootStore.userStore.user.currentOrgId)
    ) {
      console.log('using last visited org')
      this.rootStore.appStore.setCurrentOrg(this.rootStore.userStore.user.currentOrgId)
      return this.rootStore.userStore.user.currentOrgId
    }

    // current org from localstore not found?
    if (
      this.rootStore.appStore.currentOrgId &&
      !this.userHasAccessToOrg(this.rootStore.appStore.currentOrgId)
    ) {
      console.log('using first org found bc user cannot access stored orgid')
      this.rootStore.appStore.setCurrentOrg(organizations[0].organizationId)
      return organizations[0].organizationId
    }

    // user doesn't have a last visited org?
    if (!this.rootStore.userStore.user.currentOrgId) {
      console.log('using first org found bc nothing else worked')
      this.rootStore.appStore.setCurrentOrg(organizations[0].organizationId)
      return organizations[0].organizationId
    }

    // no orgs found for this user
    if (organizations.length === 0) {
      this.rootStore.appStore.navigateToNoOrganizationsFound()
      return undefined
    }

    // still no org? Use the first one
    if (!this.rootStore.appStore.currentOrgId) {
      this.rootStore.appStore.setCurrentOrg(organizations[0].organizationId)
      return organizations[0].organizationId
    }

    // all is well. using last known orgid
    console.log('using last known orgid: ' + this.rootStore.appStore.currentOrgId)
    return this.rootStore.appStore.currentOrgId
  }

  private userHasAccessToOrg(orgId: string) {
    return this.organizations.find((e) => e.organizationId === orgId)
  }
}
