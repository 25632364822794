import { action, computed, observable } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { PulseCategoriesDataStore } from '../../../pulse-categories/view-models/PulseCategoriesDataStore'
import { ParentCategoryRowVM } from './ParentCategoryRowVM'
import { IPulseCategoryDTO } from '../../../pulse-categories/dtos/IPulseCategoryDTO'
import { IPulseCategoriesFindRequest } from '../../../pulse-categories/interfaces/IPulseCategoriesFindRequest'
import { PulseCategoriesManageVM } from '../PulseCategoriesManageVM'

export class GrandParentCategoryRowVM {
  private rootStore: RootStore

  constructor(
    rootStore: RootStore,
    category: IPulseCategoryDTO,
    manageVM: PulseCategoriesManageVM
  ) {
    this.rootStore = rootStore
    this.category = category
    this.manageVM = manageVM
    this.loadParentPulseCategories()
  }

  @observable public category: IPulseCategoryDTO = null
  @observable public childPulseCategories: IPulseCategoryDTO[] = []
  @observable public manageVM: PulseCategoriesManageVM = null
  @observable public index: number = 0
  @observable public categoryVMs: ParentCategoryRowVM[] = []
  @observable public parentPulseCategories: ParentCategoryRowVM[] = []

  @computed
  public get isBuild() {
    return this.manageVM.isBuild
  }

  @action
  public setIndex(index: number) {
    this.index = index
  }

  @computed
  public get isLoaded(): boolean {
    return this.manageVM.dataStore.isLoaded
  }

  @computed
  public get name(): string {
    return this.category.name
  }

  @computed
  public get objectId(): string {
    return this.category.objectId
  }

  @action
  private loadParentPulseCategories() {
    const categories = this.manageVM.dataStore.rows.filter(
      (c: IPulseCategoryDTO) => c.grandCategoryId === this.objectId
    )
    this.parentPulseCategories = categories.map((c) => {
      let foundVM = this.categoryVMs.find((cVM) => c.objectId === cVM.objectId)
      if (!foundVM) {
        foundVM = new ParentCategoryRowVM(this.rootStore, c, this.name, this.manageVM)
        foundVM.setIndex(this.index)
        this.addCategoryVM(foundVM)
      }
      return foundVM
    })
  }

  @action
  public addCategoryVM(categoryVM: ParentCategoryRowVM) {
    this.categoryVMs.push(categoryVM)
    this.categoryVMs.slice()
  }

  @computed
  public get parentQuestionCount(): number {
    return this.parentPulseCategories
      .map((cc) => cc.childPulseQuestionCount)
      .reduce((acc, val) => acc + val, 0)
  }

  @computed
  public get currentSurveyParentQuestionCount(): number {
    return this.parentPulseCategories
      .map((cc) => cc.currentSurveyChildPulseSelectedQuestionCount)
      .reduce((acc, val) => acc + val, 0)
  }

  @computed
  public get parentQuestionSelectedCount(): number {
    return this.parentPulseCategories
      .map((cc) => cc.childPulseSelectedQuestionCount)
      .reduce((acc, val) => acc + val, 0)
  }

  @computed
  public get areAllQuestionsSelected() {
    return (
      this.parentPulseCategories.filter(
        (p) => p.childPulseQuestionCount > 0 && !p.areAllQuestionsSelected
      ).length === 0
    )
  }

  @computed
  public get areAllExpanded() {
    return (
      this.parentPulseCategories.filter((p) => p.childPulseQuestionCount > 0 && !p.areAllExpanded)
        .length === 0
    )
  }

  @action
  public selectRecursiveCategoryQuestions() {
    this.parentPulseCategories.forEach((r) => {
      r.selectRecursiveCategoryQuestions()
    })
  }

  @action
  public unselectRecursiveCategoryQuestions() {
    this.parentPulseCategories.forEach((r) => {
      r.unselectRecursiveCategoryQuestions()
    })
  }

  @action
  public toggleVisibilityForRecursiveCategories(isExpanded: boolean) {
    this.parentPulseCategories.forEach((r) => {
      r.toggleVisibilityForRecursiveCategories(isExpanded)
    })
  }
}
