import { observable } from 'mobx'
import { serializable } from 'serializr'
import { ICloudinaryResult } from 'src/app/media-items/dtos/ICloudinaryResult'

export class Attachment {
  static create(id: string, thumbnail: string, url: string, format: string) {
    const attachment = new Attachment()
    attachment.objectId = id
    attachment.thumbnail = thumbnail
    attachment.url = url
    attachment.format = format
    return attachment
  }

  static createFromCloudinaryResult(doc: ICloudinaryResult): Attachment {
    const attachment = new Attachment()
    attachment.objectId = doc.asset_id
    attachment.thumbnail = doc.thumbnail_url
    attachment.url = doc.secure_url
    attachment.format = doc.format
    attachment.bytes = doc.bytes
    attachment.fileName = doc.original_filename || 'camera_upload'
    attachment.type = doc.resource_type
    return attachment
  }

  @serializable @observable objectId: string = ''
  @serializable @observable fileName: string = ''
  @serializable @observable thumbnail: string = ''
  @serializable @observable url: string = ''
  @serializable @observable format: string = ''
  @serializable @observable bytes: number = 0
  @serializable @observable type: string = ''
}

