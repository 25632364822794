import Parse from 'parse'
import { IUserTrainingPlanDTO } from '../dto/IUserTrainingPlanDTO'

export class UserTrainingPlansUpdateService {
  public async saveUserTrainingPlan(userTrainingPlan: IUserTrainingPlanDTO) {
    await Parse.Cloud.run('updateUserTrainingPlan', { userTrainingPlan })
  }

  public async markUserTrainingPlanAsRead(id: string, orgId: string) {
    await Parse.Cloud.run('markUserTrainingPlanAsRead', { id, orgId })
  }
}
