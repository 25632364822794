import React, { FC } from 'react'
import { inject, observer } from 'mobx-react'
import { ConsentsStore } from '../store/ConsentsStore'
import Drawer from '@material-ui/core/Drawer'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import MuiDrawerToolbar from '../../shared/Mui-Drawer/MuiDrawerToolbar'
import { Editor } from '@tinymce/tinymce-react'
import { PLUGINS, TOOLBAR } from '../../config/TinyMCEconfig'
import ConsentDrawerAttachments from './ConsentDrawerAttachments'

interface Props {
  consentsStore?: ConsentsStore
}

const ConsentDrawer: FC<Props> = ({ consentsStore }) => {
  if (!consentsStore.consentWidgetVM) return null
  const vm = consentsStore.consentWidgetVM.drawerVM
  if (!vm) return null

  const handleLanguageChange = (e) => vm.setLanguage(e.target.value)

  const renderToolBar = () => {
    return (
      <MuiDrawerToolbar
        disabled={vm.isSaving}
        handleCancelClick={() => {
          vm.toggleDrawer()
        }}
        handleSubmitClick={() => vm.save()}
        showReset={Boolean((vm.drawerMode = 'edit'))}
        onConfirmReset={() => vm.resetText()}
        moreMenuProps={{
          hidden: true,
          isEditMode: true,
          itemType: '',
          itemName: '',
          onConfirmDelete: () => null,
        }}
      />
    )
  }

  const makeConsentRequiredContent = vm.isConsentRequiredForLogin ? null : (
    <div className='consent-required-change-container'>
      <Typography variant='body1' className='consent-row'>
        You can make accepting the consent a requirement to use Ripple. Users will be prompted to
        read and accept the consent on their next login.
      </Typography>
      <Button
        variant='contained'
        onClick={() => vm.makeConsentRequired()}
        disabled={!vm.changeConsentRequiredEnabled}
        className='consent-required-change-button'
      >
        Make Consent Required
      </Button>
    </div>
  )

  const makeConsentNotRequiredContent = !vm.isConsentRequiredForLogin ? null : (
    <div className='consent-required-change-container'>
      <Typography variant='body1' className='consent-row'>
        Users are prompted to read and accept the consent on their first login.
      </Typography>
      <Button
        variant='contained'
        onClick={() => vm.makeConsentNotRequired()}
        className='consent-required-change-button'
        disabled={!vm.changeConsentRequiredEnabled}
      >
        Make Consent Not Required
      </Button>
    </div>
  )

  return (
    <>
      <Drawer
        anchor='right'
        open={vm.isDrawerOpen}
        onClose={() => vm.toggleDrawer()}
        id='ConsentDrawer'
      >
        <Card elevation={0}>
          <CardHeader
            action={
              <IconButton onClick={() => vm.toggleDrawer()}>
                <CloseIcon />
              </IconButton>
            }
            title='Manage Consents'
            className='consent-drawer-header'
          />
          {renderToolBar()}
          <CardContent>
            <FormControl variant='outlined' className='language-select'>
              <Select
                value={vm.currentLanguage}
                onChange={handleLanguageChange}
                className='consent-drawer-language-select'
              >
                <MenuItem value='en'>English</MenuItem>
                <MenuItem value='de'>German</MenuItem>
              </Select>
            </FormControl>
            <div className='consent-drawer-textarea-container'>
              <Editor
                value={vm.currentLanguage === 'en' ? vm.english : vm.german}
                init={{
                  height: 350,
                  resize: false,
                  menubar: false,
                  plugins: PLUGINS,
                  toolbar: TOOLBAR,
                  placeholder:
                    'Enter or copy and paste your consent text here. Consents can currently be created in English and German.',
                  browser_spellcheck: true,
                  extended_valid_elements: 'a[href|target=_blank]',
                  contextmenu: false,
                }}
                onEditorChange={(e) => vm.setText(e)}
              />
            </div>
            <div>
              <ConsentDrawerAttachments />
            </div>
            {vm.latestConsent ? (
              <div className='edit-consent-footer'>
                <Typography variant='subtitle1' className='consent-row'>
                  Consent is currently{' '}
                  <span style={{ color: 'red' }}>
                    {vm.isConsentRequiredForLogin ? 'required' : 'not required'}
                  </span>{' '}
                  for login.
                </Typography>
                {makeConsentRequiredContent}
                {makeConsentNotRequiredContent}
              </div>
            ) : null}
          </CardContent>
        </Card>
      </Drawer>
      {/* <ConsentModal vm={vm.importVM} /> */}
    </>
  )
}

export default inject('consentsStore')(observer(ConsentDrawer))
