import React from 'react'
import { Card } from '@material-ui/core'
import { observer, inject } from 'mobx-react'
import './CatalogWidget.scss'
import Header from './Header'
import Content from './Content'
import { TrainingPlansCatalogStore } from 'src/app/training-plans/store/TrainingPlansCatalogStore'

interface Props {
  trainingPlansCatalogStore?: TrainingPlansCatalogStore
}

const CatalogPlansWidget: React.FC<Props> = ({ trainingPlansCatalogStore }) => {
  const { catalogPlansWidgetVM: vm } = trainingPlansCatalogStore
  if (!vm) return null
  if (!trainingPlansCatalogStore.canCreateTrainingPlans) return null

  return (
    <>
      <Card id='CatalogPlansWidget' elevation={0}>
        <Header />
        <Content />
      </Card>
    </>
  )
}

export default inject('trainingPlansCatalogStore')(observer(CatalogPlansWidget))
