import React from 'react'
import rootStore from '../../../stores/RootStore'
import './TrainingPlanEditScreen.scss'
import TopToolbar from './TopToolbar'
import TabsBar from './TabsBarV2'
import GoalsModal from './goalsModal/GoalsModal'
import { IconButton, Snackbar, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { observer, Observer } from 'mobx-react'
import TrainingPlanEditSnackbar from './TrainingPlanEditSnackbar'
import EditExerciseDetailsDialog from '../../../training-items/views/dialog/EditExerciseDetailsDialog'

@observer
export default class TrainingPlanEditScreen extends React.Component<any, any> {
  constructor(props) {
    super(props)

    if (props.params.id && props.params.id !== 'new') {
      rootStore.trainingPlansStore.editTrainingPlan(props.params.id)
    }
    if (props.params.id && props.params.id === 'new') {
      rootStore.trainingPlansStore.createNewTrainingPlan()
    }
    if (props.params.catalogId && props.params.catalogId !== 'new') {
      rootStore.trainingPlansCatalogStore.editCatalogTrainingPlan(props.params.catalogId)
    }
    if (props.params.catalogId && props.params.catalogId === 'new') {
      rootStore.trainingPlansCatalogStore.createNewCatalogPlan()
    }
    if (props.params.assignCatalogId) {
      rootStore.trainingPlansStore.createPlanFromCatalog(props.params.assignCatalogId)
    }
  }

  routerWillLeave(nextLocation) {
    if (!rootStore.trainingPlansStore.trainingPlanEditVM) return
    if (rootStore.trainingPlansStore.trainingPlanEditVM.isDirty) {
      return 'You have unsaved information, are you sure you want to leave this page?'
    }
  }

  componentDidMount() {
    this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave.bind(this))
  }

  render() {
    if (!rootStore.trainingPlansStore.trainingPlanEditVM) return null
    return (
      <div id='TrainingPlanEditScreen'>
        <TopToolbar />
        <TabsBar />
        <GoalsModal />
        <TrainingPlanEditSnackbar />
        <EditExerciseDetailsDialog />
      </div>
    )
  }
}
