import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { CMSItemAttachmentVM } from '../../attachments/view-models/CMSItemAttachmentVM'
import MuxVideo from '../../shared/MuxVideo'
import { CircularProgress } from '@material-ui/core'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import 'swiper/swiper.scss'
import 'swiper/modules/effect-fade/effect-fade.scss'
import 'swiper/modules/lazy/lazy.scss'
import 'swiper/modules/navigation/navigation.scss'
import 'swiper/modules/pagination/pagination.scss'
import './AnnouncementsWidget.scss'
import { useIsMounted } from '../../shared/useIsMounted'
import { isNumeric } from '../../shared/isNumeric'

interface Props {
  vm: CMSItemAttachmentVM
}

const CMSItemAttachment: React.FC<Props> = ({ vm }) => {
  const isMounted = useIsMounted()

  const handleLoad = () => {
    if (!isMounted) return
    if (vm.isLoading) return
    vm.loadCMSItem()
  }

  useEffect(() => {
    handleLoad()
  }, [])

  const renderSpinner = () => {
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  if (!vm) return

  if (!vm.isLoaded) {
    handleLoad()
    return renderSpinner()
  }

  const renderCMSImage = (item: CMSItemAttachmentVM) => {
    if (!item.isCMSItem) return
    if (!item.isImage) return
    if (item.hasVimeoURL) return
    if (item.hasYouTubeURL) return
    return <img src={`${item.url}`} className='swiper-lazy' onClick={() => item.toggleLightBox()} />
  }

  const renderYouTubeVideo = (item: CMSItemAttachmentVM) => {
    if (!item.isCMSItem) return
    if (!item.hasYouTubeURL) return
    return (
      <div className={'media-preview youtube iframewrapper'}>
        <iframe
          className='swiper-lazy'
          width={'100%'}
          height={'auto'}
          src={item.embedUrl}
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        />
      </div>
    )
  }

  const renderVimeoVideo = (item: CMSItemAttachmentVM) => {
    if (!item.isCMSItem) return
    if (!item.hasVimeoURL) return
    return (
      <div className={'media-preview vimeo iframewrapper'}>
        <iframe
          className='swiper-lazy'
          width={'100%'}
          height={'auto'}
          src={item.embedUrl}
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        />
      </div>
    )
  }

  const renderCMSVideo = (item: CMSItemAttachmentVM) => {
    if (!item.isVideo) return
    if (!item.isCMSItem) return
    if (item.isMuxVideo) return
    if (item.hasVimeoURL) return
    if (item.hasYouTubeURL) return

    return (
      <video className='swiper-lazy' controls width='100%' src={`${item.url}`}>
        <source type='video/mp4' src={`${item.url}`} />
      </video>
    )
  }

  const renderMuxVideo = (item: CMSItemAttachmentVM) => {
    if (!item.isVideo) return
    if (!item.isMuxVideo) return

    const cmsItemId = isNumeric(item.objectId) ? Number(item.objectId) : item.cmsItemId

    return (
      <div className={'mux-player-container'}>
        <MuxVideo
          cmsItemId={`${cmsItemId}`}
          playbackUrl={item.url}
          playbackId={item.thumbnailUrl}
          videoTitle={item.fileName}
        />
      </div>
    )
  }

  const renderLightBox = (item: CMSItemAttachmentVM) => {
    if (!item.isCMSItem) return
    if (!item.isImage) return
    if (item.hasVimeoURL) return
    if (item.hasYouTubeURL) return
    if (!item.showLightBox) return
    const lightBoxStyles = { overlay: { zIndex: 10000 } }
    return (
      <Lightbox
        mainSrc={item.url}
        reactModalStyle={lightBoxStyles}
        onCloseRequest={() => item.toggleLightBox()}
      ></Lightbox>
    )
  }

  return (
    <>
      {renderCMSImage(vm)}
      {renderYouTubeVideo(vm)}
      {renderVimeoVideo(vm)}
      {renderCMSVideo(vm)}
      {renderMuxVideo(vm)}
      {renderLightBox(vm)}
    </>
  )
}

export default inject()(observer(CMSItemAttachment))
