import React from 'react'
import { AgGridReact } from 'ag-grid-react'
import { SurveyTemplatesTableVM } from '../view-models/SurveyTemplatesTableVM'
import { inject, observer } from 'mobx-react'
import MuiConfirmDeleteDialog from '../../shared/MuiConfirmDeleteDialog'
import ActionSnackbar from './snackbars/ActionSnackbar'
import SelectAll from './SelectAll'

interface Props {
  vm: SurveyTemplatesTableVM
}

const SurveyTemplatesTable: React.FC<Props> = ({ vm }) => {
  if (!vm) return

  const renderGrid = () => {
    return (
      <div className={vm.hasRows ? 'ag-theme-alpine' : 'ag-theme-alpine ag-grid-no-rows'}>
        <AgGridReact
          gridOptions={{
            ...vm.getGridOptions(),
            frameworkComponents: {
              selectAll: SelectAll,
            },
          }}
        />
      </div>
    )
  }

  const renderDeleteDialog = () => {
    if (!vm.selectedRow) return <div></div>
    return (
      <MuiConfirmDeleteDialog
        onClose={() => vm.selectedRow.closeDeleteDialog()}
        open={vm.selectedRow.deleteConfirm}
        itemType='Survey Template'
        itemName={vm.selectedRow.name}
        onConfirm={() => vm.selectedRow.deleteSurveyTemplate()}
      />
    )
  }

  const renderNoTemplates = () => {
    if (!vm.isLoaded) return 
    if (vm.hasRows) return
    return <div className='no-templates-container'>No templates found</div>
  }

  const renderTable = () => {
    if (!vm.shouldRender) return
    return (
      <div id='SurveyTemplatesTable'>
        {renderGrid()}
        {renderDeleteDialog()}
        {renderNoTemplates()}
      </div>
    )
  }

  return (
    <>
      <ActionSnackbar vm={vm} />
      {renderTable()}
    </>
  )
}

export default inject()(observer(SurveyTemplatesTable))
