import React, { Component } from 'react'
import { Checkbox, FormControlLabel, Tooltip } from '@material-ui/core'
import { observer } from 'mobx-react'
import { OrganizationUsersListTableVM } from '../../view-models/list/OrganizationUsersListTableVM'

class SelectAll extends Component {
  public props: any
  public vm: OrganizationUsersListTableVM

  constructor(props) {
    super(props)
    this.vm = props.vm
  }

  renderCheckbox() {
    if (!this.vm) return null
    return (
      <>
        <Tooltip title={'Select all users'} placement='bottom-start'>
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                indeterminate={this.vm.allSelectedIndeterminate}
                onClick={() => this.vm.toggleSelectAll()}
                checked={this.vm.allSelectedChecked}
              />
            }
            label={''}
          />
        </Tooltip>
      </>
    )
  }

  render() {
    return this.renderCheckbox()
  }
}

export default observer(SelectAll)
