import { Avatar, Box, Card, CardContent, Grid, makeStyles, Slider, TextField } from '@material-ui/core'
import { observer } from 'mobx-react'
import React from 'react'
import { QuestionTakeVM } from '../../view-models/QuestionTakeVM'
import isMobile from '../../../../utils/isMobile'
import { QuizTakeVM } from '../../view-models/QuizTakeVM'
import OptionRow from './OptionRow'
import clsx from 'clsx'
import StarIcon from '@material-ui/icons/Star'
import StarOutlineIcon from '@material-ui/icons/StarOutline'
import Rating from '@material-ui/lab/Rating'
import './QuestionCard.scss'

interface Props {
  vm: QuizTakeVM
  question: QuestionTakeVM
  index: number
}

const QuestionCard: React.FC<Props> = ({ vm, question, index }) => {
  if (!question) return null

  const styles = makeStyles((theme) => ({
    vertical: {
      height: isMobile
        ? `${question.options.length * 3 * 12 + 6}px !important`
        : `${question.options.length * 2 * 19 + 10}px !important`,
    },
    markLabelMobile: {
      fontSize: '0.8em',
      minWidth: '100px !important',
      maxHeight: `${15 * 3}px !important`,
    },
  }))()

  const renderButtons = () => {
    if (question.type === 'rating') return null
    if (question.type === 'starRating') return null
    if (question.type === 'text') return null
    return question.options.map((opt, idx) => <OptionRow vm={vm} key={'opt' + idx} option={opt} />)
  }

  const createMarksArray = () => {
    const marks: Array<any> = []
    question.options.forEach((e, index) => {
      marks.push({
        value: index + 1,
        label: e.text,
      })
    })
    return marks
  }

  const renderSlider = () => {
    if (question.type === 'starRating') return null
    if (question.type === 'emoji') return null
    if (question.type === 'text') return null
    if (question.type === 'check') return null
    if (question.type === 'radio') return null
    if (question.type === 'yesNo') return null
    if (question.type === 'infoText') return null
    if (question.type === 'dropdown') return null
    const isVertical = isMobile || question.ratingDisplay === 'vertical'
    const marks = createMarksArray()
    return (
      <div
        className={clsx('slider-container', '', {
          'mui-slider-container-vertical': isVertical,
          'mui-slider-container-mobile': isMobile,
        })}
      >
        <Slider
          // track={question.ratingDisplay === 'vertical' ? 'normal' : 'normal'}
          orientation={question.ratingDisplay}
          disabled={vm.isSubmitting || vm.isComplete}
          key={'slider' + question.id}
          component='div'
          step={null}
          className={clsx({
            'has-answer': question.hasAnswer,
            'is-mobile': isMobile,
          })}
          classes={{
            vertical: isVertical ? styles.vertical : null,
            markLabel: isMobile ? styles.markLabelMobile : null,
          }}
          min={1}
          // defaultValue={question.value}
          valueLabelDisplay='off'
          max={question.options.length}
          marks={marks}
          onChangeCommitted={(e, val) => {
            question.setValue(val)
          }}
        />
      </div>
    )
  }

  const renderTextBox = () => {
    if (question.type !== 'text') return
    const option = question.options[0]
    return (
      <TextField
        className={'question-text-box'}
        id='outlined-name'
        disabled={vm.isSubmitting || vm.isComplete}
        value={option.text}
        onChange={(e) => option.setText(e.target.value)}
        margin='none'
        multiline
        fullWidth
        minRows={3}
        variant='outlined'
        onFocus={(e) => e.target.select()}
        placeholder={'Answer here'}
      />
    )
  }

  const renderStarRating = () => {
    if (question.type !== 'starRating') return
    return (
      <Grid container direction='column' justifyContent='center' alignItems='flex-start'>
      <div className={'flex-row'}>
        {question.value !== undefined ? (
          <Box className={'star-text'}>{question.ratingText}</Box>
        ) : undefined}
        <Rating
          name={'rating' + question.id + '_' + question.value}
          key={'rating' + question.id + '_' + question.value}
          className={clsx({
            'has-answer': question.hasAnswer,
            'is-mobile': isMobile,
          })}
          onChange={(e, val) => {
            question.setValue(val)
          }}
          value={question.value}
          onChangeActive={(event, newHover) => {
            question.setHover(newHover)
          }}
          defaultValue={question.value}
          precision={1}
          max={question.options ? question.options.length : 0}
          icon={<StarIcon className='star-icon'></StarIcon>}
          emptyIcon={<StarOutlineIcon className='star-icon'></StarOutlineIcon>}
        />
      </div>
    </Grid>
    )
  }

  return (
    <div id='QuestionCard' key={index}>
      <Avatar className='indicator'>Q{index + 1}</Avatar>
      <div className='hr' />
      <Card className={'question-card'} elevation={0}>
        <CardContent>
          <div className='question-row-title'>{question.title}</div>
          <Grid container direction='column' justifyContent='flex-start' alignItems='stretch' spacing={1}>
            <Grid item lg md sm xs style={{ flexShrink: 0, flexBasis: 'auto' }}>
              {renderButtons()}
              {renderSlider()}
              {renderTextBox()}
              {renderStarRating()}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}

export default observer(QuestionCard)
