import { action, observable } from 'mobx'
import { IAggregate } from '../../../shared/data/IAggregate'
import { ISurveyTypeDTO } from '../dtos/ISurveyTypeDTO'
import { serializable, serialize } from 'serializr'

export default class SurveyType implements ISurveyTypeDTO, IAggregate {
  static create(orgId) {
    const surveyType = new SurveyType()
    surveyType.organizationId = orgId
    return surveyType
  }

  @serializable @observable objectId: string = ''
  @serializable @observable organizationId: string = ''
  @serializable @observable name: string = ''
  @serializable @observable description: string = ''
  @serializable @observable worksheetId: string = ''
  @serializable @observable isFeedbackType: boolean = false
  @serializable @observable isDefaultSurveyType: boolean = false
  @serializable @observable forPrompt: string = ''
  @serializable @observable allowMultipleForUsers: boolean = false
  @serializable @observable responseForParticipant: boolean = false
  @serializable @observable isProcessing: boolean = false
  @serializable @observable isDeleted: boolean = false
  isOnServer: boolean = false

  @action
  setName(name) {
    this.name = name
  }

  @action
  setPrompt(prompt) {
    this.forPrompt = prompt
  }

  @action
  setDescription(val) {
    this.description = val
  }

  @action
  toggleResponseForParticipant() {
    this.responseForParticipant = !this.responseForParticipant
  }

  @action
  toggleIsFeedback() {
    this.isFeedbackType = !this.isFeedbackType
  }

  @action
  toggleMultipleFor() {
    this.allowMultipleForUsers = !this.allowMultipleForUsers
  }

  @action
  markIsNotOnServer() {
    this.isOnServer = false
  }

  @action
  markIsOnServer() {
    this.isOnServer = true
  }

  @action
  markIsNotProcessing() {
    this.isProcessing = false
  }

  @action
  markIsProcessing() {
    this.isProcessing = true
  }

  public serialize(): ISurveyTypeDTO {
    return serialize(this)
  }
}
