import React, { FC } from 'react'
// import { inject } from 'mobx-react'

import { Tooltip, IconButton } from '@material-ui/core'
// import LocalizationStore from '../localization/LocalizationStore'
import InfoRoundedIcon from '@material-ui/icons/InfoRounded'
import './InfoTooltip.scss'
import isIE11 from '../../utils/isIE11'

export interface InfoTooltipProps {
  message: any
}

const InfoTooltip: FC<InfoTooltipProps> = ({ message }) => {
  return (
    <div id='InfoTooltipBtn'>
      <Tooltip arrow={true} title={message} placement={isIE11 ? 'bottom' : 'right-start'}>
        <IconButton className='' size='small'>
          <InfoRoundedIcon />
        </IconButton>
      </Tooltip>
    </div>
  )
}

export default InfoTooltip
