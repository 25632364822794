import Parse from 'parse'
import { RootStore } from '../../stores/RootStore'
import { DataStore } from '../../shared/data/DataStore'
import { SurveyResponse } from '../aggregate/SurveyResponse'
import { ISurveyResponseDTO } from '../dtos/ISurveyResponseDTO'
import { action, computed, observable } from 'mobx'
import { WidgetVM } from '../view-models/survey-responses-widget/WidgetVM'
import { UserSurveyTakeVM } from '../../user-surveys/view-models/UserSurveyTakeVM'

export class CompletedSurveyResponsesStore extends DataStore<SurveyResponse, ISurveyResponseDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, SurveyResponse, 'surveyResponses', [
      'objectId',
      'organizationId',
      'name',
      'respondedAt',
      'publishedByUserId',
      'questionsCount',
      'userSurveyId',
      'surveyId',
      'showResults',
    ])
  }

  @observable public listWidgetVM: WidgetVM
  @observable public takeVM: UserSurveyTakeVM

  public onListRecordsLoaded() {
    this.listWidgetVM = new WidgetVM(this.rootStore)
  }

  protected getBaseQuery() {
    const query = new Parse.Query(this.className)
    query.equalTo('organizationId', this.rootStore.appStore.currentOrgId)
    query.equalTo('userId', this.rootStore.appStore.currentUserId)
    query.notEqualTo('isDeleted', true)
    return query
  }

  protected getFullColumns() {
    return ['questions']
  }

  @computed
  public get currentOrgCompletedSurveyResponses() {
    return this.records.filter((e) => e.organizationId === this.rootStore.appStore.currentOrgId)
  }

  public getCompletedSurveyResponse(objectId: string) {
    return this.records.find((e) => e.objectId === objectId)
  }

  @action
  public async loadTakeVM(objectId: string) {
    if (objectId === this.loadedTakeVMObjectId) return
    this.takeVM = undefined
    const foundResponse = await this.getFullRecord(objectId)
    if (!foundResponse) return
    this.takeVM = new UserSurveyTakeVM(
      this.rootStore,
      foundResponse.clone(),
      'load takeVM',
      false,
      true,
      true,
      objectId
    )
  }

  @computed
  private get loadedTakeVMObjectId() {
    if (!this.takeVM) return undefined
    return this.takeVM.userSurvey.objectId
  }
}
