import { inject, observer } from 'mobx-react'
import React from 'react'
import beautify from 'js-beautify'
import AceEditor from 'react-ace'
import 'ace-builds/webpack-resolver'
import 'ace-builds/src-noconflict/mode-html'
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/theme-monokai'
import 'ace-builds/src-noconflict/ext-language_tools'
import 'ace-builds/src-noconflict/ext-searchbox'
import LocalizationStore from '../../../localization/LocalizationStore'
import { EmailTemplatePreviewVM } from '../../view-models/EmailTemplatePreviewVM'
import './EmailTemplateEditor.scss'

interface Props {
  vm: EmailTemplatePreviewVM
  localizationStore?: LocalizationStore
}

const EmailTemplateEditor: React.FC<Props> = ({ vm, localizationStore }) => {
  const { emailTemplateManagement: strings } = localizationStore.lzStrings

  if (!vm) return null

  const onChange = (newValue) => {
    vm.tabIndex === 0 ? vm.setPreviewHtml(newValue) : vm.setPreviewTestData(newValue)
  }

  const onLoad = (newValue) => {
    console.debug('on load')
  }

  return (
    <AceEditor
      placeholder='Email Template HTML Editor'
      mode={vm.tabIndex === 0 ? 'html' : 'json'}
      theme='monokai'
      name='EmailTemplateEditor'
      onLoad={onLoad}
      onChange={onChange}
      fontSize={14}
      showPrintMargin={true}
      showGutter={true}
      highlightActiveLine={true}
      value={vm.tabIndex === 0 ? vm.html : vm.testData}
      wrapEnabled={true}
      setOptions={{
        enableBasicAutocompletion: false,
        enableLiveAutocompletion: false,
        enableSnippets: false,
        showLineNumbers: true,
        tabSize: 2,
      }}
    />
  )
}

export default inject('localizationStore')(observer(EmailTemplateEditor))
