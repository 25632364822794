import React from 'react'
import { ParticipantsSelectVM } from '../view-models/ParticipantsSelectVM'
import {
  Button,
  TextField,
  Grid,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'
import { observer, inject } from 'mobx-react'
import { LabelsStore } from '../../labels/store/LabelsStore'
import Autocomplete from '@material-ui/lab/Autocomplete'
import '../styles/ByEmailTab.scss'

interface Props {
  participantsSelectVM: ParticipantsSelectVM
  labelsStore?: LabelsStore
}

const ByEmailTab: React.FC<Props> = ({ participantsSelectVM, labelsStore }) => {
  const client = labelsStore.getLabelByLanguageAndFor('client')
  const vm = participantsSelectVM
  if (vm.currentTab !== 4) return null

  const renderClientSelect = () => {
    if (!vm.saveAsContact) return null
    return (
      <Grid item xs={12}>
        <Autocomplete
          className='client-autocomplete'
          options={vm.partnerChannels}
          getOptionLabel={(e) => e.name}
          value={vm.anonymousPartnerChannel}
          onChange={(e, val) => vm.setAnonymousPartnerChannel(val)}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={`Select ${labelsStore.getLabelByLanguageAndFor('client')}`}
              variant='outlined'
              className={'client-select'}
            />
          )}
        />
      </Grid>
    )
  }

  return (
    <div id='ByEmailTab'>
      <Grid container>
        <Grid item xs={12}>
          <TextField
            autoFocus
            required
            disabled={vm.isReadOnly}
            value={vm.anonymousEmail}
            onChange={(e) => vm.setAnonymousEmail(e.target.value)}
            margin='dense'
            variant='outlined'
            placeholder={'Email'}
            type='email'
            error={!vm.isValidEmail}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={vm.isReadOnly}
            value={vm.anonymousFirstName}
            onChange={(e) => vm.setAnonymousFirstName(e.target.value)}
            margin='dense'
            variant='outlined'
            placeholder={'First'}
            type='text'
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={vm.isReadOnly}
            value={vm.anonymousLastName}
            onChange={(e) => vm.setAnonymousLastName(e.target.value)}
            margin='dense'
            variant='outlined'
            placeholder={'Last'}
            type='text'
          />
        </Grid>

        <Grid item xs={12}>
          <Select
            style={{ width: '240px', marginBottom: '12px' }}
            id='language-select'
            className={'template-select'}
            value={vm.anonymousLanguage}
            onChange={(e) => vm.setAnonymousLanguage(e.target.value)}
            variant='outlined'
          >
            {vm.languageOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        {renderClientSelect()}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox checked={vm.saveAsContact} onChange={(e) => vm.toggleSaveAsContact()} />
            }
            label={'Save as new Contact'}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            disabled={vm.isReadOnly}
            variant='contained'
            onClick={() => vm.addAnonymousParticipant()}
          >
            Add
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default inject('labelsStore')(observer(ByEmailTab))
