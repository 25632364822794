import { action, computed, observable } from 'mobx'
import moment from 'moment'
import { RootStore } from '../../stores/RootStore'
import { PulseCampaignEditVM } from './PulseCampaignEditVM'

export class DateEditVM {
  private rootStore: RootStore
  private editVM: PulseCampaignEditVM

  constructor(rootStore: RootStore, editVM: PulseCampaignEditVM, deliveryDate, timezone) {
    this.rootStore = rootStore
    this.editVM = editVM
    this.sendDate = deliveryDate
    this.timezone = timezone
  }

  @observable public sendDate: any = null
  @observable public closeDate: any = null
  @observable public timezone: any = null

  @action
  public setCloseDate(e) {
    this.closeDate = e
  }

  @action
  public setDate(e) {
    this.sendDate = e
  }

  @action
  public setDateManually(e) {
    this.sendDate = e
  }
}
