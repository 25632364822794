import React from 'react'
import { inject, observer } from 'mobx-react'
import { EventsStore } from '../../store/EventsStore'
import TasksIcon from '@material-ui/icons/AssignmentOutlined'
import SurveysIcon from '@material-ui/icons/LiveHelpOutlined'
import EventIcon from '@material-ui/icons/Event'
import './Calendar.scss'
import { Checkbox, Grid } from '@material-ui/core'

interface Props {
  eventsStore?: EventsStore
}

const MyCalendarsSelect: React.FC<Props> = ({ eventsStore }) => {
  const { calendarVM: vm } = eventsStore

  return (
    <Grid container className='my-calendars-select'>
      <Grid item xs={8} className='my-calendars-items' onClick={() => vm.toggleMyCalendar()}>
        <Checkbox checked={vm.isMyCalendarChecked} value='my-calendar' />
        {vm.userName}
      </Grid>
    </Grid>
  )
}

export default inject('eventsStore')(observer(MyCalendarsSelect))
