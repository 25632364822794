import React from 'react'
import { inject, observer } from 'mobx-react'
import { TasksStore } from '../../store/TasksStore'
import LocalizationStore from '../../../localization/LocalizationStore'
import { Grid, Tooltip, Badge } from '@material-ui/core'
import Avatar from '../../../shared/Avatar'
import './DrawerStatusContainer.scss'
import WidgetUserTaskBadge from '../widget/WidgetUserTaskBadge'
import { AudienceMembersStore } from 'src/app/audience-members/store/AudienceMembersStore'

interface IDrawerCommentsContainer {
  localizationStore?: LocalizationStore
  tasksStore?: TasksStore
  audienceMembersStore?: AudienceMembersStore
}

const DrawerStatusContainer: React.FC<IDrawerCommentsContainer> = ({
  localizationStore,
  tasksStore,
  audienceMembersStore,
}) => {
  const { lzStrings } = localizationStore
  const { taskEditVM } = tasksStore

  if (!taskEditVM.objectId) return null

  return (
    <section>
      <h6>Status</h6>
      <Grid container spacing={1} id='DrawerStatusContainer'>
        {taskEditVM.userTasks.map((userTask, index) => {
          const user = audienceMembersStore.getUser(userTask.userId)

          if (!user) return

          return (
            <Tooltip
              key={userTask.objectId}
              title={`${user.name}: ${lzStrings.tasksManagementWidget.task} ${
                userTask.isCompleted
                  ? lzStrings.tasksManagementWidget.completed
                  : lzStrings.tasksManagementWidget.incomplete
              }`}
              placement='bottom'
            >
              <Badge
                badgeContent={<WidgetUserTaskBadge isCompleted={userTask.isCompleted} />}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                <Avatar
                  key={`${userTask.objectId}-${user}`}
                  user={user}
                  size={40}
                  style={{ fontSize: 20, float: 'left', marginLeft: 6, marginTop: 10 }}
                  showOnlyFirstInitial
                />
              </Badge>
            </Tooltip>
          )
        })}
      </Grid>
    </section>
  )
}

export default inject(
  'localizationStore',
  'tasksStore',
  'audienceMembersStore'
)(observer(DrawerStatusContainer))
