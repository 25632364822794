import React from 'react'
import { inject, observer } from 'mobx-react'
import { AppBar, TextField, Toolbar, Typography } from '@material-ui/core'
import { AppStore } from '../../../stores/AppStore'
import { PulseCampaignsStore } from '../../store/PulseCampaignsStore'
import LocalizationStore from '../../../localization/LocalizationStore'
import { LabelsStore } from '../../../labels/store/LabelsStore'
import EmptyState from '../../../shared/empty-state/EmptyState'
import emptyResults from '../../../../assets/img/empty-state/empty-state-results.png'
import './CampaignAnalysisEmptyState.scss'

interface Props {
  pulseCampaignsStore?: PulseCampaignsStore
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
}

const CampaignAnalysisEmptyState: React.FC<Props> = ({
  pulseCampaignsStore,
  localizationStore,
  labelsStore,
}) => {
  const { pulseCampaign: lz } = localizationStore.lzStrings
  const vm = pulseCampaignsStore.viewModels.campaignAnalysisEditVM
  if (!vm) return null
  if (vm.worksheetId) return null

  const renderEmptyState = () => {
    return (
      <EmptyState
        image={emptyResults}
        title={'No Worksheet'}
        subtitle1={'Assign a worksheet to this pulse campaign. '}
        subtitle2={''}
        action={
          <></>
        }
      />
    )
  }

  return (
    <div id={'CampaignAnalysisEmptyState'}>
      {renderEmptyState()}
    </div>
  )
}

export default inject(
  'pulseCampaignsStore',
  'localizationStore',
  'labelsStore'
)(observer(CampaignAnalysisEmptyState))
