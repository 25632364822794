import { action, computed, observable, when } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { SurveyTemplateFoldersFindDataStore } from '../../../survey-template-folders/stores/SurveyTemplateFoldersFindDataStore'
import { ISurveyTemplateFoldersFindRequest } from '../../../survey-template-folders/interfaces/ISurveyTemplateFoldersFindRequest'
import SurveyTemplateFolder from '../../../survey-template-folders/aggregate/SurveyTemplateFolder'
import { SurveyTemplatesSelectVM } from './SurveyTemplatesSelectVM'
import { SurveyTemplateFoldersSelectDataStore } from '../../../survey-template-folders/stores/SurveyTemplateFoldersSelectDataStore'

interface SurveyTemplateFolderForPicker {
  id: string
  name: string
  parentId: string
  children: SurveyTemplateFolderForPicker[]
}

const noFolder = {
  id: 'no-folder',
  name: `[ No Folder ]`,
  parentId: null,
  children: [],
}

export class SurveyTemplatesSelectFoldersVM {
  private rootStore: RootStore
  @observable public dataStore: SurveyTemplateFoldersSelectDataStore

  constructor(
    rootStore: RootStore,
    selectVM: SurveyTemplatesSelectVM,
    req: ISurveyTemplateFoldersFindRequest
  ) {
    this.rootStore = rootStore
    this.selectVM = selectVM
    this.dataStore = new SurveyTemplateFoldersSelectDataStore(this.rootStore, req)
    this.dataStore.loadListRecords()
  }

  @observable public selectVM: SurveyTemplatesSelectVM = null
  @observable public selectedFolder: SurveyTemplateFolderForPicker = noFolder
  @observable public isSystemToggleMode: boolean = false
  @observable public expanded: string[] = []

  @computed
  public get isLoaded(): boolean {
    return this.dataStore.isLoaded
  }

  @action
  public getFoldersForMode() {
    const request = this.dataStore.getRequest()
    if (this.isSystemToggleMode)  {
      request.isSystem = true
    } else {
      request.isSystem = false
    }
    this.dataStore.setRequest(request)
    this.dataStore.loadListRecords()
  }

  @action
  public toggleSystemMode() {
    this.isSystemToggleMode = !this.isSystemToggleMode
    this.getFoldersForMode()
    this.selectVM.getTemplatesByFolder('no-folder')
  }

  @computed
  public get validSurveyTemplateFolders(): SurveyTemplateFolder[] {
    return this.dataStore.surveyTemplates.slice().filter((folder) => !folder.isDeleted)
  }

  @computed
  public get formattedFolders(): any {
    let folders: Array<SurveyTemplateFolderForPicker> = this.validSurveyTemplateFolders
      .sort((a: SurveyTemplateFolder, b: SurveyTemplateFolder) => {
        const nameA = a.name.toLowerCase()
        const nameB = b.name.toLowerCase()
        if (nameA < nameB) return -1
        if (nameB < nameA) return 1
        return 0
      })
      .map((e: SurveyTemplateFolder) => ({
        id: e.objectId,
        name: e.name,
        parentId: e.parentFolderId,
        children: [],
      }))

    folders.unshift(noFolder)

    return folders
  }

  @computed
  public get defaultExpanded(): string[] {
    return this.formattedFolders.map((e: SurveyTemplateFolderForPicker) => e.id)
  }

  private listToTree(list) {
    const map = {}
    const roots = []
    for (let i = 0; i < list.length; i++) {
      map[list[i].id] = i
      list[i].children = []
    }

    for (let i = 0; i < list.length; i++) {
      const node = list[i]
      if (node.parentId) {
        list[map[node.parentId]].children.push(node)
      } else {
        roots.push(node)
      }
    }
    return roots
  }

  @computed
  public get data(): any {
    const tree = this.listToTree(this.formattedFolders)
    return tree
  }

  @action
  public setSelectedFolder(node) {
    this.selectedFolder = node
    this.selectVM.getTemplatesByFolder(node.id)
  }
}
