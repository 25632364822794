import React from 'react'
import { observer } from 'mobx-react'
import rootStore from '../../../stores/RootStore'
import { PulseCampaignEditVM } from '../../view-models/PulseCampaignEditVM'
import './CampaignAnalysisEditScreen.scss'
import TopToolbar from './TopToolbar'
import CampaignWorksheet from './CampaignWorksheet'
import CampaignAnalysisEmptyState from './CampaignAnalysisEmptyState'
import CampaignAnalysisEditSnackbar from './CampaignAnalysisEditSnackbar'

@observer
export default class CampaignAnalysisEditScreen extends React.Component<any, any> {
  private vm: PulseCampaignEditVM

  constructor(props) {
    super(props)

    // this.vm = rootStore.pulseCampaignsStore.viewModels.editVM

    if (props.params.id) {
      rootStore.pulseCampaignsStore.viewModels.loadCampaignAnalysisEditVM(props.params.id)
    }
  }

  // routerWillLeave(nextLocation) {
  //   if (!rootStore.pulseCampaignsStore.viewModels.editVM) return
  //   if (rootStore.pulseCampaignsStore.viewModels.editVM.isDirty) {
  //     return 'You have unsaved information, are you sure you want to leave this page?'
  //   }
  // }

  // componentDidMount() {
  //   this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave.bind(this))
  // }

  componentWillUnmount() {
    rootStore.pulseCampaignsStore.viewModels.loadCampaignAnalysisEditVM('empty')
  }

  render() {
    return (
      <div id='CampaignAnalysisEditScreen'>
        <CampaignAnalysisEditSnackbar />
        <TopToolbar />
        <CampaignAnalysisEmptyState />
        <CampaignWorksheet />
      </div>
    )
  }
}
