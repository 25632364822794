import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Menu, { MenuProps } from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Icon from '@material-ui/core/Icon'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import DropDownRoundedIcon from '@material-ui/icons/ArrowDropDownCircleRounded'
import AdjustIcon from '@material-ui/icons/Adjust'
import { observer, inject } from 'mobx-react'
import { SurveysStore } from '../../../store/SurveysStore'
import './ResponseTypeMenu.scss'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles((theme) => ({
  // root: {
  //   '&:focus': {
  //     backgroundColor: theme.palette.primary.main,
  //     '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
  //       color: theme.palette.common.white,
  //     },
  //   },
  // },
}))(MenuItem)

interface WeightMenuProps {
  surveysStore?: SurveysStore
  idx: number
}

const WeightMenu: React.FC<WeightMenuProps> = ({ surveysStore, idx }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const { editVM } = surveysStore.viewModels
  if (!editVM) return null

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const emojiClicked = (name) => {
    handleClose()
    editVM.currentQuestion.options[idx].setEmoji(name)
  }

  const getEmojis = (idx) => {
    let imageItems = require.context('../../../../../assets/emoticons/png').keys()
    let images = imageItems.map((imageName) => {
      imageName = imageName.substring(imageName.indexOf('/') + 1)
      imageName = imageName.substring(0, imageName.indexOf('.'))
      const isSelected = editVM.currentQuestion.options[idx].emoji === imageName
      return (
        <div key={'img' + idx + imageName} onClick={() => emojiClicked(imageName)}>
          <ListItemIcon>
            <img
              className={isSelected ? 'selected' : ''}
              src={'../../../../../assets/emoticons/png/' + imageName + '.png'}
              style={{
                width: 20,
                height: 20,
                margin: 5,
              }}
            />
          </ListItemIcon>
        </div>
      )
    })
    return images
  }

  let selectedEmoji = null
  if (
    editVM &&
    editVM.currentQuestion &&
    editVM.currentQuestion.options &&
    editVM.currentQuestion.options[idx] &&
    editVM.currentQuestion.options[idx].emoji
  ) {
    selectedEmoji = editVM.currentQuestion.options[idx].emoji
  }
  const type = editVM.currentQuestion.type
  return (
    <div>
      <Button
        className='emoji-button'
        onClick={handleClick}
        disabled={type !== 'emoji' || editVM.isReadOnly}
      >
        {selectedEmoji ? (
          <img
            src={'../../../../../assets/emoticons/png/' + selectedEmoji + '.png'}
            style={{ width: 20, height: 20 }}
          />
        ) : null}
        {type === 'yesNo' ? <RadioButtonUncheckedIcon className='icon' /> : null}
        {type === 'text' ? null : null}
        {type === 'rating' ? <Icon className='icon'>{idx + 1}</Icon> : null}
        {type === 'starRating' ? <Icon className='icon'>{idx + 1}</Icon> : null}
        {type === 'check' ? (
          <Icon className='icon'>
            <i className='far fa-check-square'></i>
          </Icon>
        ) : null}
        {type === 'order' ? null : null}
        {type === 'radio' ? <AdjustIcon className='icon' /> : null}
        {type === 'dropdown' ? <DropDownRoundedIcon className='icon' /> : null}
      </Button>

      {anchorEl ? (
        <StyledMenu
          id='customized-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: 240,
              flexWrap: 'wrap',
              justifyContent: 'space-evenly',
            }}
          >
            {editVM.currentQuestion.type === 'emoji' ? getEmojis(idx) : null}
          </div>
        </StyledMenu>
      ) : null}
    </div>
  )
}

export default inject('surveysStore')(observer(WeightMenu))
