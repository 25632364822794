import { action, computed, observable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { ContentItemEditVM } from './ContentItemEditVM'
import { TrainingItemEditVM } from './TrainingItemEditVM'

export class EditExerciseDetailsDialogVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.lz = this.rootStore.localizationStore.lzStrings.emailTemplateManagement
  }

  @observable public contentItem: TrainingItemEditVM = null
  @observable public applyTried: boolean = false
  @observable public isLoading: boolean = false
  @observable public isSaving: boolean = false
  @observable public isOpen: boolean = false
  @observable public lz = undefined
  @observable public index: number = 0

  @action
  public openDialog() {
    this.isOpen = true
  }

  @action
  public closeDialog() {
    this.isOpen = false
    this.reset()
  }

  @action
  public reset() {
    this.applyTried = false
  }

  @computed
  public get isLoaded(): boolean {
    return this.rootStore.emailTemplatesStore.loaded
  }

  @computed
  public get isValid() {
    if (!this.exerciseValid) return false
    return true
  }

  @computed
  public get currentExercise(): ContentItemEditVM {
    if (!this.contentItem) return
    if (!this.contentItem.contentItemVMs) return
    return this.contentItem.contentItemVMs[this.index]
  }

  @computed
  public get exerciseValid(): boolean {
    if (!this.repsValid && this.applyTried) return false
    if (!this.setsValid && this.applyTried) return false
    if (!this.weightValid && this.applyTried) return false
    if (!this.timeValid && this.applyTried) return false
    return true
  }

  @computed
  public get setsValid(): boolean {
    if (!this.currentExercise.sets) return false
    if (this.currentExercise.sets <= 0) return false
    return true
  }

  @computed
  public get repsValid(): boolean {
    if (!this.currentExercise.reps && this.currentExercise.reps !== 0) return false
    if (this.currentExercise.reps < 0) return false
    return true
  }

  @computed
  public get weightValid(): boolean {
    if (!this.currentExercise.weight && this.currentExercise.weight !== 0) return false
    if (this.currentExercise.weight < 0) return false
    return true
  }

  @computed
  public get timeValid(): boolean {
    if (!this.currentExercise.time && this.currentExercise.time !== 0) return false
    if (this.currentExercise.time < 0) return false
    return true
  }

  @computed
  public get percentValid(): boolean {
    if (!this.currentExercise.percent && this.currentExercise.percent !== 0) return false
    if (this.currentExercise.percent < 0) return false
    return true
  }
}
