import { RootStore } from '../../stores/RootStore'
import { ColDef, GridOptions, RowClickedEvent } from 'ag-grid-community'
import { action, computed, IReactionDisposer, observable, reaction } from 'mobx'
import { AnnouncementEditVM } from '../../announcements/view-models/AnnouncementEditVM'
import { PostTemplatesRowVM } from './PostTemplatesRowVM'
import { CMSPostTemplatesStore } from '../store/CMSPostTemplatesStore'
import { PostTemplate } from '../aggregate/PostTemplate'
import { UserAnnouncementEventsVM } from '../../userAnnouncementEvents/view-models/UserAnnouncementEventsVM'
import { AGGridVM } from '../../shared/ag-grid/AGGridVM'
import { nameOf } from '../../shared/nameOf'

export class PostTemplatesTableVM extends AGGridVM {
  private rootStore: RootStore
  public editVM: AnnouncementEditVM
  private dataStore: CMSPostTemplatesStore
  private reactions: IReactionDisposer[] = []

  constructor(rootStore, editVM: AnnouncementEditVM) {
    super()
    this.rootStore = rootStore
    this.editVM = editVM
    this.dataStore = new CMSPostTemplatesStore(this.rootStore)
    this.sizeColumnsToFit = true
    this.serverSideLoaded = true

    this.reactions.push(
      reaction(
        () => this.dataStore.rows,
        () => {
          if (this.gridApi) this.gridApi.refreshInfiniteCache()
        }
      )
    )
  }

  @observable public announcementId: string = undefined
  @observable public rows = []
  @observable public loadingFirstPage: boolean = true
  @observable public eventsVM: UserAnnouncementEventsVM = undefined
  @observable public showEventDialog: boolean = false
  @observable public filterAnchorEl = null
  @observable public filterText: string = 'All'
  @observable public filterDisabled: boolean = false

  @action
  public setFilterAnchorEl(element) {
    this.filterAnchorEl = element
  }

  @action
  private onRecordUpdated(obj: PostTemplate) {
    const rowNode = this.gridApi.getRowNode(obj.objectId)
    if (!rowNode) {
      return
    }
    rowNode.setData(new PostTemplatesRowVM(this.rootStore, obj, this))
  }

  @action
  public toggleShowEventDialog() {
    this.showEventDialog = !this.showEventDialog
  }

  @computed
  public get hasRows() {
    return this.rows.length !== 0
  }

  @computed
  public get shouldRender(): boolean {
    return this.dataStore.isLoaded
  }

  @computed
  public get columnDefs(): ColDef[] {
    const s = this.rootStore.localizationStore.lzStrings
    return [
      {
        headerName: 'Title',
        field: nameOf<PostTemplatesRowVM, string>((e) => e.title),
        minWidth: 180,
        headerTooltip: 'Title',
        sortable: false,
        sort: 'asc',
      },
      {
        headerName: 'Created',
        field: nameOf<PostTemplatesRowVM, string>((e) => e.createdAt),
        headerTooltip: 'Created At',
        sortable: false,
      },
      {
        headerName: 'Created By',
        field: nameOf<PostTemplatesRowVM, string>((e) => e.createdBy),
        headerTooltip: 'Created By',
        sortable: false,
      },
      {
        headerName: 'Updated',
        field: nameOf<PostTemplatesRowVM, string>((e) => e.updatedAt),
        headerTooltip: 'Updated At',
        sortable: false,
      },
      {
        headerName: 'Updated By',
        field: nameOf<PostTemplatesRowVM, string>((e) => e.updatedBy),
        headerTooltip: 'Updated By',
        sortable: false,
      },
    ]
  }

  public rowClicked(e: RowClickedEvent) {
    const row: PostTemplatesRowVM = e.data
    if (!row) return
    row.click()
  }

  @action
  public getRowNodeId(row): string {
    return row.objectId
  }

  public getGridOptions(): GridOptions {
    return {
      ...this.baseGridOptions,
      rowData: this.rows,
      onRowClicked: (e) => this.rowClicked(e),
      getRowNodeId: (e) => this.getRowNodeId(e),
      suppressRowClickSelection: true,
      suppressCellSelection: true,
      suppressRowHoverHighlight: true,
      rowSelection: 'multiple',
      columnDefs: this.ensureTooltipFields(this.columnDefs),
      pagination: false,
      frameworkComponents: {},
    }
  }

  @action
  public hardRefresh() {
    if (!this.gridApi) return
    this.gridApi.refreshInfiniteCache()
    this.gridApi.purgeInfiniteCache()
    this.gridApi.paginationGoToFirstPage()
  }

  protected onGridReadied(): void {
    this.gridApi.sizeColumnsToFit()
    this.gridApi.setDatasource({
      rowCount: this.totalRecords,
      getRows: async (params) => {
        params.sortModel.forEach((col: { colId: string; sort: 'asc' | 'desc' }, idx: number) => {
          let dbCol = col.colId
          if (dbCol.includes('createdBy')) dbCol = 'fk_createdByUser.name'
          if (dbCol.includes('updatedBy')) dbCol = 'fk_updatedByUser.name'
        })
        if (params.startRow === 0) {
          // this.dataStore.setPage(0)
          this.loadingFirstPage = true
        }
        // await this.dataStore.getNextPage()
        const rows = this.dataStore.rows.map((e) => new PostTemplatesRowVM(this.rootStore, e, this))
        this.rows = rows
        this.loadingFirstPage = false
        this.filterDisabled = false
        params.successCallback(rows, this.dataStore.totalRecords)
      },
    })
  }

  @computed
  public get hasFilter(): boolean {
    return Boolean(this.typedFilterText)
  }

  @action
  public setQuickFilter(val: string) {
    this.typedFilterText = val
    if (this.quickFilterTO) clearTimeout(this.quickFilterTO)
    this.quickFilterTO = setTimeout(() => this.applyFilter(), 1000)
  }

  private applyFilter() {
    this.dataStore.setFilter(this.typedFilterText)
    this.reload()
  }
}
