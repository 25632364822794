import { action, computed, observable } from 'mobx'
import moment from 'moment'
import { LabelsStore } from '../../labels/store/LabelsStore'
import { AGGridRowVM } from '../../shared/ag-grid/AGGridRowVM'
import { RootStore } from '../../stores/RootStore'
import { CMSItem } from '../aggregate/CMSItem'
import { CMSItemVM } from './CMSItemVM'
import { CMSItemsLayoutTableVM } from './CMSItemsLayoutTableVM'

export class CMSItemsLayoutTableRowVM extends AGGridRowVM<CMSItem> {
  constructor(rootStore: RootStore, contentItem: CMSItemVM, listVM: CMSItemsLayoutTableVM) {
    super(rootStore, contentItem.aggregate, listVM)
    this.labelsStore = this.rootStore.labelsStore
    this.listVM = listVM
    this.item = contentItem
    this.loadData()
  }

  @observable public item: CMSItemVM = null
  @observable public labelsStore: LabelsStore = null
  @observable public listVM: CMSItemsLayoutTableVM = null
  @observable public popoverOpen: boolean = false
  @observable public anchorEl: HTMLElement | null = null
  @observable public Title: string = '--'
  @observable public Description: string = '--'
  @observable public createdAt: string = '--'
  @observable public updatedAt: string = '--'

  @action
  public loadData() {
    if (this.aggregate.Title) this.Title = this.aggregate.Title
    if (this.aggregate.Description) this.Description = this.aggregate.Description
    if (this.aggregate.createdAt)
      this.createdAt = moment(this.aggregate.createdAt).format('MMMM Do YYYY, h:mm:ss a').toString()
    if (this.aggregate.updatedAt)
      this.updatedAt = moment(this.aggregate.updatedAt).format('MMMM Do YYYY, h:mm:ss a').toString()
  }

  @computed
  public get objectId(): string {
    return this.aggregate.objectId
  }

  @action
  public setAnchorEl(anchorEl: HTMLElement | null) {
    this.anchorEl = anchorEl
  }

  public toDTO() {
    return {
      id: this.objectId,
      title: this.Title,
      description: this.Description,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    }
  }

  public click(rowIndex: number) {
    const rowNode = this.widgetVM.gridApi.getRowNode(this.rowNodeId)
    if (rowNode) {
      this.listVM.verifyNewSelection()
      rowNode.setSelected(!rowNode.isSelected())
      this.item.isChecked = rowNode.isSelected()
    }
  }
}
