import { IPulseSetDTO } from '../dtos/IPulseSetDTO'
import { observable, action } from 'mobx'
import { serializable, serialize, deserialize, date, list, object, primitive } from 'serializr'
import { IAggregate } from '../../shared/data/IAggregate'

export class PulseSet implements IPulseSetDTO, IAggregate {
  static create() {
    const pulseSet = new PulseSet()
    return pulseSet
  }

  @serializable @observable public objectId: string = ''
  @serializable @observable public name: string = ''
  @serializable(list(primitive())) @observable public subscribedOrganizationIds: string[] = []
  @serializable(list(primitive())) @observable public pulseCampaignTemplateIds: string[] = []
  @serializable @observable isDeleted: boolean = false

  public isOnServer: boolean = false

  setName(name: string) {
    this.name = name
  }

  setOrgs(orgs: string[]) {
    this.subscribedOrganizationIds = orgs
  }

  markIsNotOnServer() {
    this.isOnServer = false
  }

  markIsOnServer() {
    this.isOnServer = true
  }

  addCampaignTemplateIds(ids: string[]) {
    ids.forEach((id) => this.pulseCampaignTemplateIds.push(id))
  }

  removeCampaignTemplateId(id: string) {
    this.pulseCampaignTemplateIds = this.pulseCampaignTemplateIds.filter(
      (template) => template !== id
    )
  }

  public clone(): PulseSet {
    return deserialize(PulseSet, this.serialize())
  }

  public serialize(): IPulseSetDTO {
    return serialize(this)
  }
}
