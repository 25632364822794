import { action, computed, observable, when } from 'mobx'
import { PulseSetsFilterVM } from '../../pulse-sets/view-models/PulseSetsFilterVM'
import { RootStore } from '../../stores/RootStore'
import PulseCategory from '../aggregate/PulseCategory'
import { IPulseCategoryDTO } from '../dtos/IPulseCategoryDTO'
import { IPulseCategoriesFindRequest } from '../interfaces/IPulseCategoriesFindRequest'
import { GrandCategoryTabVM } from './GrandCategoryTabVM'
import { GrandParentCategoryRowVM } from './GrandParentCategoryRowVM'
import { PulseCategoriesDataStore } from './PulseCategoriesDataStore'
import { PulseCategoryEditVM } from './PulseCategoryEditVM'


export class PulseCategoriesManageVM {
  private rootStore: RootStore
  public dataStore: PulseCategoriesDataStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.pulseSetsFilterVM = new PulseSetsFilterVM(this.rootStore, this)
    this.dataStore = new PulseCategoriesDataStore(this.rootStore, {} as IPulseCategoriesFindRequest)
    this.dataStore.loadListRecords()
  }

  @observable public pulseSetsFilterVM: PulseSetsFilterVM = null

  @computed
  public get isLoaded(): boolean {
    return this.dataStore.isLoaded
  }

  @computed
  public get checkedPulseSetIds(): string[] {
    return this.pulseSetsFilterVM.checkedPulseSetIds
  }

  @computed
  public get grandCategoryTabs(): GrandCategoryTabVM[] {
    return this.dataStore.rows.map((c, i) => new GrandCategoryTabVM(this.rootStore, this, c, i + 1))
  }

  @computed
  public get grandParentCategoryRows(): GrandParentCategoryRowVM[] {
    return this.dataStore.rows.map((c) => new GrandParentCategoryRowVM(this.rootStore, this, c))
  }

  @computed
  public get grandParentCategoryRowsToShow(): GrandParentCategoryRowVM[] {
    const selectedIds = []
    const allIds = []
    this.grandCategoryTabs.forEach((t) => {
      if (t.isSelected) {
        selectedIds.push(t.objectId)
      }
      allIds.push(t.objectId)
    })
    return this.grandParentCategoryRows.filter((e) => {
      if (selectedIds.includes(e.objectId)) return true
      return false
    })
  }

  @action
  public createNewPulseCategory() {
    this.rootStore.pulseCategoriesStore.viewModels.editVM = new PulseCategoryEditVM(
      this.rootStore,
      PulseCategory.create(undefined, undefined)
    )
  }
}
