import { RootStore } from '../../stores/RootStore'
import { action, observable, computed } from 'mobx'
import { TaskComment } from '../aggregates/TaskComment'
import { TaskCommentsService } from '../service/TaskCommentsService'
import { MediaItemsService } from '../../media-items/service/MediaItemsService'
import { AudienceMember } from '../../audience-members/aggregate/AudienceMember'
import { isNumeric } from '../../shared/isNumeric'
import { OldAttachmentVM } from '../../attachments/view-models/OldAttachmentVM'
import { AttachmentVM } from '../../attachments/view-models/AttachmentVM'
import { Attachment } from '../../attachments/aggregate/Attachment'

export class TaskCommentEditVM {
  private rootStore: RootStore
  public taskComment: TaskComment
  private svc: TaskCommentsService

  constructor(rootStore: RootStore, taskComment: TaskComment) {
    this.rootStore = rootStore
    this.taskComment = taskComment
    this.svc = new TaskCommentsService()
  }

  @observable public isInEditMode: boolean = false
  @observable public editedText: string = ''
  @observable public showEdit: boolean = false
  @observable public deleteDialogOpen: boolean = false
  @observable public shouldDeleteAttachment: boolean = false

  @computed
  public get objectId(): string {
    return this.taskComment.objectId
  }

  @computed
  public get currentCommentText(): string {
    return this.taskComment.text
  }

  @computed
  public get attachment(): AttachmentVM {
    if (!this.taskComment.attachment) return null
    if (isNumeric(this.taskComment.attachment.objectId) || this.taskComment.attachment.cmsItemId) {
      return this.rootStore.cmsItemAttachmentStore.loadAttachment(this.taskComment.attachment)
    }
    return new OldAttachmentVM(this.rootStore, this.taskComment.attachment)
  }

  @computed
  public get commentedBy(): AudienceMember {
    return this.rootStore.audienceMembersStore.getUser(this.taskComment.userId)
  }

  @computed
  public get userId(): string {
    return this.taskComment.userId
  }

  @computed
  public get datePosted(): number {
    return this.taskComment.datePosted
  }

  @computed
  public get text(): string {
    return this.taskComment.text
  }

  @computed
  public get isUserOwned(): boolean {
    if (this.userId !== this.rootStore.appStore.currentUserId) return false
    return true
  }

  @computed
  public get editIconShown(): boolean {
    if (this.isInEditMode) return false
    if (!this.showEdit) return false
    return true
  }

  @computed
  public get showAttachment(): boolean {
    if (!this.attachment) return false
    if (this.shouldDeleteAttachment) return false
    return true
  }

  @computed
  public get showDeleteIcon(): boolean {
    if (!this.isUserOwned) return false
    if (!this.isInEditMode) return false
    return true
  }

  @action
  public async generateSignature(callback: Function, paramsToSign: object): Promise<void> {
    const svc = new MediaItemsService()
    const signature = await svc.getCloudinarySignature(paramsToSign)
    callback(signature)
  }

  @action
  public showWidget() {
    this.toggleEditComment()
    window.cloudinary.openUploadWidget(
      {
        maxFiles: 5,
        multiple: true,
        cloudName: 'rippleworx',
        sources: ['local', 'image_search', 'camera', 'dropbox', 'google_drive'],
        showPoweredBy: false,
        apiKey: '914141713215552',
        uploadSignature: this.generateSignature,
      },
      async (error, result) => {
        if (!error && result && result.event === 'success') {
          const attachment = new Attachment()
          attachment.objectId = result.info.asset_id
          attachment.thumbnail = result.info.thumbnail_url
          attachment.url = result.info.secure_url
          attachment.format = result.info.format
          attachment.bytes = result.info.bytes
          attachment.fileName = result.info.original_filename || 'camera_upload'
          attachment.type = result.info.resource_type
          if (result.info.resource_type.includes('video')) {
            const thumbnail = await this.getThumbnailUrl(result.info)
            attachment.thumbnail = thumbnail
          }
          this.addAttachment(attachment)
        }
      }
    )
  }

  public async getThumbnailUrl(info) {
    const svc = new MediaItemsService()
    const thumbnailUrl = await svc.buildThumbnailURL(info.public_id, '.jpg', info.secure_url)
    return thumbnailUrl
  }

  @action
  public showEditIcon() {
    if (!this.isUserOwned) return

    this.showEdit = true
  }

  @action
  public hideEditIcon() {
    this.showEdit = false
  }

  @action
  public deleteAttachmentFromComment() {
    this.shouldDeleteAttachment = true
  }

  @action
  public toggleEditComment() {
    if (!this.isUserOwned) return
    this.isInEditMode = !this.isInEditMode
    if (this.isInEditMode) this.editedText = this.taskComment.text
    if (!this.isInEditMode) this.shouldDeleteAttachment = false
  }

  @action
  public toggleDeleteDialog() {
    this.deleteDialogOpen = !this.deleteDialogOpen
  }

  @action
  public cancelEdit() {
    this.toggleEditComment()
    this.taskComment.setText(this.currentCommentText)
  }

  @action
  public addAttachment(attachment: Attachment) {
    this.taskComment.addAttachment(attachment)
  }

  @action
  public setText(val: string) {
    this.editedText = val
  }

  @action
  public openAttachment() {
    this.attachment.openAttachment()
  }

  @action
  public markAsDeleted() {
    this.taskComment.markAsDeleted()
  }

  @computed
  public get isEmptyComment(): boolean {
    if (!this.editedText && this.shouldDeleteAttachment) return true
    if (!this.editedText && !this.attachment) return true
    return false
  }

  @action
  public saveTaskComment() {
    if (this.shouldDeleteAttachment) this.taskComment.deleteAttachment()
    this.taskComment.setText(this.editedText)
    this.toggleEditComment()
    if (this.isEmptyComment) {
      this.deleteTaskComment()
    } else {
      this.svc.saveTaskComment(this.rootStore.appStore.currentOrgId, this.taskComment)
    }
  }

  @action
  public deleteTaskComment() {
    this.taskComment.markAsDeleted()
    this.svc.saveTaskComment(this.rootStore.appStore.currentOrgId, this.taskComment)
    const idx = this.rootStore.taskCommentsStore.taskComments.findIndex(
      (comment) => comment.objectId === this.objectId
    )
    this.rootStore.taskCommentsStore.taskComments.splice(idx, 1)
  }
}
