import { observable, action } from 'mobx'
import { serializable, serialize, deserialize, object, date, list, primitive } from 'serializr'
import { TrainingItem } from '../../training-items/aggregate/TrainingItem'
import { ContentItem } from '../../training-items/aggregate/ContentItem'
import { ITrainingPlanItemDTO } from '../dtos/ITrainingPlanItemDTO'
import moment from 'moment'
import { Quiz } from '../../quiz/aggregate/Quiz'
import uuid from 'uuid/v4'
import { Attachment } from './Attachment'

export class TrainingPlanItem implements ITrainingPlanItemDTO {
  public static create(trainingItem: TrainingItem, dueDate: Date): TrainingPlanItem {
    const trainingPlanItem = new TrainingPlanItem()
    trainingPlanItem.id = uuid()
    trainingPlanItem.trainingItemId = trainingItem.objectId
    trainingPlanItem.name = trainingItem.name
    trainingPlanItem.description = trainingItem.description
    trainingPlanItem.categories = trainingItem.categories
    trainingPlanItem.mustCompleteInOrder = trainingItem.mustCompleteInOrder
    trainingPlanItem.contentItems = trainingItem.contentItems
    trainingPlanItem.dueDate = moment(dueDate).toISOString()
    trainingPlanItem.quiz = trainingItem.quiz
    trainingPlanItem.requireQuiz = trainingItem.requireQuiz
    trainingPlanItem.requireCertificate = trainingItem.requireCertificate
    return trainingPlanItem
  }

  @serializable @observable public id: string = ''
  @serializable @observable public trainingItemId: string
  @serializable @observable public name: string
  @serializable @observable public description: string
  @serializable(list(primitive())) @observable public categories: string[]
  @serializable @observable public mustCompleteInOrder: boolean
  @serializable(list(object(ContentItem))) @observable public contentItems: ContentItem[]
  @serializable @observable public dueDate: string = ''
  @serializable @observable public isComplete: boolean = false
  @serializable(object(Quiz)) @observable public quiz: Quiz = null
  @serializable @observable public requireQuiz: boolean = false
  @serializable @observable public requireCertificate: boolean = false
  @serializable @observable public completedDate: string = ''
  @serializable @observable public completion: number = 0
  @serializable(object(Attachment)) @observable public certificate: Attachment = null
  @serializable @observable public block: string = ''

  @action
  public setDueDate(val: Date) {
    this.dueDate = moment(val).toISOString()
  }

  @action
  public setBlock(val: string) {
    this.block = val
  }

  @action
  public setCertificate(result: Attachment) {
    this.certificate = result
  }

  @action
  public markIsComplete() {
    this.isComplete = true
    this.completedDate = moment(new Date()).toISOString()
  }

  @action
  public markIsIncomplete() {
    this.isComplete = false
    this.completedDate = ''
  }

  public serialize() {
    const obj = serialize(this)
    obj.dueDate = moment(this.dueDate).toISOString()
    return obj
  }

  public clone(): TrainingPlanItem {
    return deserialize(TrainingPlanItem, this.serialize())
  }
}
