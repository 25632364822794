import React from 'react'
import clsx from 'clsx'
import { inject, observer } from 'mobx-react'
import { ButtonBase, Tooltip, Typography } from '@material-ui/core'
import LocalizationStore from '../../../localization/LocalizationStore'
import { LabelsStore } from '../../../labels/store/LabelsStore'
import { EmailTemplatePreviewVM } from '../../view-models/EmailTemplatePreviewVM'
import DesktopIcon from '@material-ui/icons/DesktopWindowsRounded'
import MobileIcon from '@material-ui/icons/SmartphoneRounded'
import EmailIcon from '@material-ui/icons/MailOutlineRounded'
import './EmailTemplatesPreviewToggle.scss'

interface Props {
  vm: EmailTemplatePreviewVM
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
}

const EmailTemplatesPreviewToggle: React.FC<Props> = ({ vm, localizationStore, labelsStore }) => {
  const { emailTemplateManagement: lz } = localizationStore.lzStrings

  const renderEmailPreview = () => {
    return (
      <Tooltip title={lz.email_preview_tooltip} placement='bottom'>
        <span>
          <ButtonBase
            disabled={vm.isSaving}
            onClick={() => vm.setPreviewTabIndex(0)}
            className={clsx('tab-button', {
              active: vm.previewTabIndex === 0,
            })}
          >
            <EmailIcon />
            <Typography className='tab-button-label'>{lz.email}</Typography>
          </ButtonBase>
        </span>
      </Tooltip>
    )
  }

  const renderDesktopTakePreview = () => {
    return (
      <Tooltip title={lz.desktop_web_preview_tooltip} placement='bottom'>
        <span>
          <ButtonBase
            disabled={vm.isSaving}
            onClick={() => vm.setPreviewTabIndex(1)}
            className={clsx('tab-button', {
              active: vm.previewTabIndex === 1,
            })}
          >
            <DesktopIcon />
            <Typography className='tab-button-label'>{lz.desktop_web}</Typography>
          </ButtonBase>
        </span>
      </Tooltip>
    )
  }

  const renderMobileTakePreview = () => {
    return (
      <Tooltip title={lz.mobile_web_preview_tooltip} placement='bottom'>
        <span>
          <ButtonBase
            disabled={vm.isSaving}
            onClick={() => vm.setPreviewTabIndex(2)}
            className={clsx('tab-button', {
              active: vm.previewTabIndex === 2,
            })}
          >
            <MobileIcon />
            <Typography className='tab-button-label'>{lz.mobile_web}</Typography>
          </ButtonBase>
        </span>
      </Tooltip>
    )
  }

  return (
    <div id='EmailTemplatePreviewTabs'>
      <div className='tab-btns'>
        {renderEmailPreview()}
        {renderDesktopTakePreview()}
        {renderMobileTakePreview()}
      </div>
    </div>
  )
}

export default inject('localizationStore', 'labelsStore')(observer(EmailTemplatesPreviewToggle))
