import { RootStore } from '../../../stores/RootStore'
import { computed, action } from 'mobx'
import { CustomCollectionsWidgetVM } from './CustomCollectionsWidgetVM'
import { CustomCollection } from '../../aggregate/CustomCollection'
import { AGGridRowVM } from '../../../shared/ag-grid/AGGridRowVM'

export class CustomCollectionRowVM extends AGGridRowVM<CustomCollection> {
  constructor(
    rootStore: RootStore,
    widgetVM: CustomCollectionsWidgetVM,
    customCollection: CustomCollection
  ) {
    super(rootStore, customCollection, widgetVM)
  }

  @computed
  public get objectId(): string {
    return this.aggregate.objectId
  }

  @computed
  public get name(): string {
    return this.aggregate.name
  }

  @computed
  public get fieldsCount(): number {
    return this.aggregate.fields.length
  }

  @computed
  public get recordsCount(): number {
    return this.aggregate.customRecordsCount
  }

  @action
  public editCustomCollection() {
    this.rootStore.appStore.router.push('/customCollections/edit/' + this.aggregate.objectId)
  }
}
