import { action } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { OrganizationsService } from '../../organizations/service/OrganizationsService'
import { IOrganizationMobileAppIdFindRequest } from '../../organizations/interfaces/IOrganizationMobileAppIdFindRequest'
import isMobile from '../../../utils/isMobile'
import { IOrganizationMobileAppIdFindResult } from '../../organizations/interfaces/IOrganizationMobileAppIdFindResult'
import { platform } from '../../../utils/getPlatform'

export class RedirectVM {
  private rootStore: RootStore
  private mobileAppId: string
  private organizationId: string
  private path: string
  private id: string
  constructor(rootStore: RootStore, mobileAppId, organizationId, path, id) {
    this.rootStore = rootStore
    this.mobileAppId = mobileAppId
    this.organizationId = organizationId
    this.path = path
    this.id = id
  }

  @action
  public async redirect() {
    if (isMobile) {
      if (this.mobileAppId !== 'none') {
        await this.tryRouteToMobile()
      }
    } else if (this.path === 'announcements') {
      window.location.href = `/#/dashboard/userDashboard`
    }
  }

  @action
  public async tryRouteToMobile() {
    const svc = new OrganizationsService(this.rootStore)
    const req: IOrganizationMobileAppIdFindRequest = {
      organizationId: this.organizationId,
      platform: platform,
    }
    const result: IOrganizationMobileAppIdFindResult = await svc.getOrganizationMobileAppId(req)
    if (!result.success) return
    if (!result.mobileAppId) return

    window.location.replace(
      `${result.mobileAppId}://redirect/${this.organizationId}/${this.path}/${
        this.id ? this.id : ''
      }`
    )
  }
}
