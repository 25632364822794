import React from 'react'
import { inject, observer } from 'mobx-react'
import { ClientsStore } from '../store/ClientsStore'
import { Card, CardContent, Drawer, Tabs, Tab } from '@material-ui/core'
import DrawerHeader from './DrawerHeader'
import DrawerButtonControls from './DrawerButtonControls'
import ClientContactList from './ClientContactList'
import ClientInfo from './ClientInfo'
import TabPanel from '../../groups-roles-widget/views/TabPanel'
import { LabelsStore } from '../../labels/store/LabelsStore'
import './ClientManagementWidget.scss'

interface ClientDrawerProps {
  labelsStore?: LabelsStore
  clientsStore?: ClientsStore
}

const ClientDrawer: React.FC<ClientDrawerProps> = ({ labelsStore, clientsStore }) => {
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  const handleClose = () => {
    clientsStore.toggleWidgetDrawer()
  }

  return (
    <Drawer
      id='ClientDrawer'
      anchor='right'
      open={clientsStore.isWidgetDrawerOpen}
      onClose={handleClose}
    >
      <Card elevation={0}>
        <DrawerHeader />
        <DrawerButtonControls />
        <Tabs value={value} onChange={handleChange} variant='fullWidth'>
          <Tab label={`${labelsStore.getLabelByLanguageAndFor('client')} Information`} />
          <Tab label='Contacts' />
        </Tabs>
        <CardContent>
          <TabPanel value={value} index={0}>
            <ClientInfo />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ClientContactList />
          </TabPanel>
        </CardContent>
      </Card>
    </Drawer>
  )
}

export default inject('labelsStore', 'clientsStore')(observer(ClientDrawer))
