import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../localization/LocalizationStore'
import { AppBar, Button, CssBaseline, Toolbar, Typography } from '@material-ui/core'
import clsx from 'clsx'
import isIE11 from '../../../utils/isIE11'
import { SurveyTemplatesManageVM } from '../view-models/SurveyTemplatesManageVM'

interface Props {
  localizationStore?: LocalizationStore
  vm: SurveyTemplatesManageVM
}

const TopToolbar: React.FC<Props> = ({ localizationStore, vm }) => {
  if (!vm) return null
  const lz = localizationStore.lzStrings.surveys

  const renderManagePermissionsButton = () => {
    if (vm.screenType !== 'systemManage') return null
    return (
      <Button
        variant='contained'
        onClick={() => vm.navigateToSurveyTemplatesPermissions()}
        className='dashboard-action'
      >
        {'Manage Subscriptions'}
      </Button>
    )
  }

  return (
    <div id='DashboardToolbar'>
      <CssBaseline />
      <AppBar
        className={clsx('dashboard-appbar', {
          'no-transition': isIE11,
        }, vm.screenType === 'systemManage' ? 'strong' : '')}
        elevation={0}
      >
        <Toolbar className='dashboard-toolbar'>
          <Typography className='dashboard-title'>{lz.manage_survey_templates}</Typography>
          <div className='dashboard-toolbar-right'>
            {renderManagePermissionsButton()}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default inject('localizationStore')(observer(TopToolbar))
