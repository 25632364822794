import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { IconButton, Tooltip, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import './DrawerLinks.scss'
import { OrganizationLinksVM } from '../../../view-models/OrganizationLinksVM'
import TenantLinkEdit from './TenantLinkEdit'
import { TenantLinkRowVM } from '../../../view-models/TenantLinkRowVM'

interface Props {
  vm: OrganizationLinksVM
  localizationStore?: LocalizationStore
}

const DrawerLinks: React.FC<Props> = ({ localizationStore, vm }) => {
  const { lzStrings } = localizationStore
  if (!vm) return null

  if (vm.currentTabIndex !== 1) return null

  const renderAddButton = () => {
    return (
      <Tooltip title={'Add new link'} placement='bottom'>
        <IconButton
          className='add-btn'
          size='small'
          onClick={() => vm.loadLinkEditVM()}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
    )
  }

  const renderHeader = () => {
    return (
      <div className={'links-header'}>
        <Typography className={'header-text'}>{'Links'}</Typography>
        {renderAddButton()}
      </div>
    )
  }

  const renderLinkRowName = (link: TenantLinkRowVM) => {
    return (
      <div>
        <div className={'link-header'}>Name</div>
        <div>{link.name}</div>
      </div>
    )
  }

  const renderLinkRowUrl = (link: TenantLinkRowVM) => {
    return (
      <div>
        <div className={'link-header'}>URL</div>
        <div>{link.url}</div>
      </div>
    )
  }

  const renderLinkRowIconName = (link: TenantLinkRowVM) => {
    return (
      <div>
        <div className={'link-header'}>Icon Image</div>
        <div>{link.iconName}</div>
      </div>
    )
  }

  const renderLinkRowIsEmbedded = (link: TenantLinkRowVM) => {
    return (
      <div>
        <div className={'link-header'}>Embedded</div>
        <div>{link.embeddedString}</div>
      </div>
    )
  }

  const renderLinks = () => {
    const links = []
    vm.tenantLinks.forEach((e: TenantLinkRowVM, i) => {
      links.push(
        <div key={i} className={'link-row'} onClick={() => vm.loadLinkEditVM(e.tenantLink)}>
          {renderLinkRowName(e)}
          {renderLinkRowUrl(e)}
          {/* {renderLinkRowIconName(e)} */}
          {renderLinkRowIsEmbedded(e)}
        </div>
      )
    })

    return (
      <div className={'links-container'}>
        {links}
      </div>
    )

  }

  const renderLinkEdit = () => {
    if (!vm.linkEditVM) return null
    return <TenantLinkEdit vm={vm.linkEditVM} />
  }

  const renderLinksContainer = () => {
    if (vm.linkEditVM) return null
    return (
      <div>
        {renderHeader()}
        {renderLinks()}
      </div>
    )
  }

  return (
    <div id={'DrawerLinks'}>
      {renderLinkEdit()}
      {renderLinksContainer()}
    </div>
  )
}

export default inject('localizationStore', 'organizationsStore')(observer(DrawerLinks))
