import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { TasksStore } from '../../store/TasksStore'
import MuiDrawerToolbar from '../../../shared/Mui-Drawer/MuiDrawerToolbar'

interface DrawerButtonControlsProps {
  localizationStore?: LocalizationStore
  tasksStore?: TasksStore
}

const DrawerButtonControls: React.FC<DrawerButtonControlsProps> = ({
  localizationStore,
  tasksStore,
}) => {
  const { lzStrings } = localizationStore
  const { taskEditVM: vm } = tasksStore

  return (
    <div>
      <MuiDrawerToolbar
        handleCancelClick={() => {
          tasksStore.closeDrawer()
        }}
        disabled={vm.saveDisabled}
        handleSubmitClick={() => vm.saveTask()}
        showReset={Boolean(vm.objectId)}
        onConfirmReset={() => tasksStore.openTask(vm.task.objectId)}
        moreMenuProps={{
          hidden: !vm.objectId,
          isEditMode: true,
          itemType: lzStrings.tasksManagementWidgetDrawer.task,
          itemName: vm.name,
          onConfirmDelete: () => vm.deleteTask(),
        }}
      />
    </div>
  )
}

export default inject('localizationStore', 'tasksStore')(observer(DrawerButtonControls))
