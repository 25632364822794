import React from 'react'
import { inject, observer } from 'mobx-react'
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
  Theme,
  DialogActions,
  InputLabel,
  TextField,
  FormControl,
  CircularProgress,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { EmailTemplatesStore } from '../../store/EmailTemplatesStore'
import LocalizationStore from '../../../localization/LocalizationStore'
import EmailTemplateDialogHeader from './EmailTemplateDialogHeader'
import './EmailTemplateCreateDialog.scss'

interface Props {
  emailTemplatesStore: EmailTemplatesStore
  localizationStore: LocalizationStore
}

const useStyles = makeStyles((theme: Theme) => ({
  backDrop: {
    backdropFilter: 'blur(1px)',
    backgroundColor: 'rgba(0,0,30,0.9)',
  },
  input: {
    textAlign: 'center',
    '&::placeholder': {
      textAlign: 'center',
    },
  },
}))

const EmailTemplateCreateDialog: React.FC<Props> = ({ emailTemplatesStore, localizationStore }) => {
  const classes = useStyles()

  const vm = emailTemplatesStore.viewModels.createDialogVM
  if (!vm) return null

  const { emailTemplateManagement: lz } = localizationStore.lzStrings

  const handleClose = () => {
    vm.applyTried = false
    vm.closeDialog()
    vm.reset()
  }

  const handleApply = () => {
    vm.applyTried = true
    if (!vm.isValid) return
    vm.isSaving = true
    vm.handleNewEmailTemplate()
  }

  const renderDialogTitle = () => {
    return (
      <div className='intro-text intro-header'>
        <EmailTemplateDialogHeader
          emailTemplatesStore={emailTemplatesStore}
          title={lz.create_email_template}
          onClick={() => vm.closeDialog()}
        />
      </div>
    )
  }

  const handleNameChange = (event: React.ChangeEvent<{ value: string }>) => {
    vm.name = event.target.value || ''
  }

  const handleTypeChange = (event: React.ChangeEvent<{ value: string }>) => {
    vm.type = event.target.value || ''
  }

  const renderEmailInfo = () => {
    return (
      <div id='EmailTemplateCreateDialogName'>
        <form className={'create-dialog-container'}>
          <Grid container spacing={2} direction='column' className='settings-group'>
            <Grid item sm={12}>
              <InputLabel required htmlFor='email-template-name' className={'settings-group-label'}>
                {lz.template_name}
              </InputLabel>
              <TextField
                error={!vm.isNameValid}
                value={vm.name}
                onChange={handleNameChange}
                fullWidth
                variant='outlined'
                id='email-template-name'
                disabled={vm.isLoading || vm.isSaving}
              />
            </Grid>
            <Grid item sm={12}>
              <InputLabel required htmlFor='email-template-type' className={'settings-group-label'}>
                {lz.notification_type}
              </InputLabel>
              <FormControl variant='outlined' className={'formControl'}>
                <Autocomplete
                  id='email-template-notification-type-autocomplete'
                  value={vm.type}
                  onChange={(e, val) => (vm.type = val)}
                  options={vm.availableNotificationTemplates.map((template) => template.notificationTypeId)}
                  getOptionSelected={(option, value) => option === value}
                  renderInput={(params) => (
                    <TextField
                      required
                      id='emailTemplateType'
                      error={!vm.isTypeValid}
                      label=''
                      name='emailTemplateType'
                      type='search'
                      variant='outlined'
                      {...params}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </div>
    )
  }

  const renderBody = () => {
    return (
      <Grid item xs={12} className='body'>
        <Grid container spacing={2} direction='row'>
          <Grid item xs={7} className='email-info'>
            {renderEmailInfo()}
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderActionButtons = () => {
    return (
      <Grid item xs={12} className='intro-buttons'>
        <Grid item>
          <Button
            disabled={vm.isLoading || vm.isSaving}
            variant='outlined'
            onClick={(e) => vm.closeDialog()}
          >
            {lz.cancel}
          </Button>
          <Button
            disabled={!vm.isValid || vm.isLoading || vm.isSaving}
            variant='contained'
            onClick={handleApply}
          >
            {lz.apply}
          </Button>
        </Grid>
      </Grid>
    )
  }

  const renderDialogContent = () => {
    return (
      <DialogContent>
        <Grid
          id='EmailTemplateCreateDialogContent'
          container
          direction='column'
          justifyContent='center'
          alignItems='stretch'
        >
          {renderBody()}
        </Grid>
      </DialogContent>
    )
  }

  const renderDialogActions = () => {
    return (
      <DialogActions>
        <Grid
          id='EmailTemplateCreateDialogActions'
          container
          direction='column'
          justifyContent='center'
          alignItems='stretch'
        >
          {renderActionButtons()}
        </Grid>
      </DialogActions>
    )
  }

  const renderSpinner = () => {
    if (!vm.isLoading && !vm.isSaving) return
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  return (
    <Dialog
      id='EmailTemplateCreateDialog'
      open={vm.isOpen}
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
      onClose={handleClose}
      fullWidth
    >
      <div className={'dialog-container'}>{renderDialogTitle()}</div>
      {renderDialogContent()}
      {renderDialogActions()}
      {renderSpinner()}
    </Dialog>
  )
}

export default inject('emailTemplatesStore')(observer(EmailTemplateCreateDialog))
