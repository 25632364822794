import { addDays, addMonths } from 'date-fns'

const janOne = new Date(new Date().getFullYear(), 0, 1)
const qOneEnd = addMonths(janOne, 3)
const qTwoEnd = addMonths(qOneEnd, 3)
const qThreeEnd = addMonths(qTwoEnd, 3)
const qFourEnd = addMonths(qThreeEnd, 3)

const staticRanges = [
  {
    label: '30 Days',
    range: () => ({
      startDate: new Date(),
      endDate: addDays(new Date(), 29),
    }),
    isSelected() {
      return true
    },
  },
  {
    label: '60 Days',
    range: () => ({
      startDate: new Date(),
      endDate: addDays(new Date(), 59),
    }),
    isSelected() {
      return true
    },
  },
  {
    label: 'Quarter 1',
    range: () => ({
      startDate: janOne,
      endDate: qOneEnd,
    }),
    isSelected() {
      return true
    },
  },
  {
    label: 'Quarter 2',
    range: () => ({
      startDate: qOneEnd,
      endDate: qTwoEnd,
    }),
    isSelected() {
      return true
    },
  },
  {
    label: 'Quarter 3',
    range: () => ({
      startDate: qTwoEnd,
      endDate: qThreeEnd,
    }),
    isSelected() {
      return true
    },
  },
  {
    label: 'Quarter 4',
    range: () => ({
      startDate: qThreeEnd,
      endDate: qFourEnd,
    }),
    isSelected() {
      return true
    },
  },
]

export default staticRanges
