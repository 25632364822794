import { action, computed, observable, reaction } from 'mobx'
import { AGGridRowVM } from '../../../../shared/ag-grid/AGGridRowVM'
import { RootStore } from '../../../../stores/RootStore'
import { Question } from '../../../../questions/aggregates/Question'
import { QuestionsCatalogVM } from './QuestionsCatalogVM'
import { OptionRowVM } from './OptionRowVM'

export class QuestionRowVM extends AGGridRowVM<Question> {
  private actualParentVM: QuestionsCatalogVM

  constructor(rootStore: RootStore, parentVM: QuestionsCatalogVM, question: Question) {
    super(rootStore, question, parentVM)
    this.actualParentVM = parentVM
    reaction(
      () => question.title,
      () => this.updateRow()
    )
  }

  @observable public height: number = 60

  @computed
  public get objectId() {
    return this.aggregate.objectId
  }

  @computed
  public get isSelected(): boolean {
    return this.actualParentVM.isQuestionSelected(this.objectId)
  }

  @computed
  public get title(): string {
    if (!this.aggregate.title) return '-'
    return this.aggregate.title
  }

  @computed
  public get questionType(): string {
    return this.aggregate.type
  }

  @computed
  public get options() {
    return this.aggregate.options
      .filter((e) => !e.isDeleted)
      .sort((a, b) => (a.rank < b.rank ? -1 : 0))
      .map((e) => new OptionRowVM(e))
  }

  @computed
  public get categoryLabel() {
    if (!this.rootStore.categoriesStore) return '(No Category)'
    if (!this.aggregate.categoryId) return '(No Category)'
    const foundCat = this.rootStore.categoriesStore.getCategory(this.aggregate.categoryId)
    if (!foundCat) return '(No Category)'
    return foundCat.label
  }

  @computed
  public get surveyTypeLabel() {
    if (!this.rootStore.surveyTypesStore) return '-'
    if (!this.aggregate.surveyTypeId) return '-'
    const foundSurveyType = this.rootStore.surveyTypesStore.getSurveyType(
      this.aggregate.surveyTypeId
    )
    if (!foundSurveyType) return '-'
    return foundSurveyType.name
  }

  @computed
  public get ratingOptionsText() {
    return this.options.map((e) => e.rank + ': ' + e.text).join(', ')
  }

  @computed
  public get orderOptionsText() {
    return this.options.map((e) => e.text).join(', ')
  }

  @action
  public toggle() {
    this.actualParentVM.toggleQuestion(this)
  }

  @computed
  public get isDropdownMultiSelect(): boolean {
    return this.aggregate.isDropdownMultiSelect
  }

  public get question(): Question {
    return this.aggregate
  }
}
