import { RootStore } from '../../stores/RootStore'
import Parse from 'parse'

export class UserTasksService {
  private rootStore: RootStore

  public async autoPublishSurvey(userTaskId, orgId) {
    await Parse.Cloud.run('autoPublishSurvey', { userTaskId, orgId })
  }

  public async markAsRead(userTaskId, orgId) {
    await Parse.Cloud.run('markUserTaskAsRead', { userTaskId, orgId })
  }
}
