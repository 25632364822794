import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import { inject, observer } from 'mobx-react'
import { TasksStore } from '../../store/TasksStore'
import { UserStore } from '../../../user/store/UserStore'
import LocalizationStore from '../../../localization/LocalizationStore'
import { Avatar, Button, Grid, TextField, Typography } from '@material-ui/core'
import DescriptionIcon from '@material-ui/icons/Description'
import VideoIcon from '@material-ui/icons/VideocamRounded'
import './Drawer.scss'
import { MediaItemsService } from '../../../media-items/service/MediaItemsService'
import FileUploadModal from '../../../upload/views/FileUploadModal'
import { FileTypeEnum } from '../../../upload/enum/FileTypeEnum'
import { Attachment } from '../../../upload/aggregate/Attachment'
import CMSItemsPickerDialog from '../../../cms-items/views/dialogs/CMSItemsPickerDialog'
import { CMSItemsTypeEnum } from '../../../cms-items/enum/CMSItemsTypeEnum'
import { OrganizationsStore } from '../../../organizations/store/OrganizationsStore'

interface IDrawerCommentInput {
  tasksStore?: TasksStore
  userStore?: UserStore
  localizationStore?: LocalizationStore
  organizationsStore?: OrganizationsStore
}

const DrawerCommentInput: React.FC<IDrawerCommentInput> = ({
  userStore,
  tasksStore,
  localizationStore,
  organizationsStore,
}) => {
  const { tasksManagementWidgetDrawer: strings } = localizationStore.lzStrings
  const { taskEditVM: vm } = tasksStore

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    vm.setCommentText(event.target.value)
  }

  const handleAddCommentToUnsavedTask = (event: React.MouseEvent<HTMLButtonElement>): void => {
    vm.addCommentToUnsavedTask()
  }

  const handleAddCommentToSavedTask = (event: React.MouseEvent<HTMLButtonElement>): void => {
    vm.addCommentToSavedTask()
  }

  const renderAddAttachmentButton = () => {
    if (vm.commentAttachmentVM) return null
    return (
      <Button variant='outlined' onClick={() => vm.toggleCommentUploadModal()}>
        {strings.add_attachment}
      </Button>
    )
  }

  const renderAttachment = () => {
    if (!vm.commentAttachment || !vm.commentAttachmentVM) return null

    const renderThumbnail = () => {
      if (vm.commentAttachmentVM.isImage) {
        return (
          <div
            className='img-container'
            style={{ backgroundImage: `url("${vm.commentAttachment.url}")` }}
          />
        )
      }
      if (vm.commentAttachmentVM.isVideo) {
        if (vm.commentAttachmentVM.url) {
          if (vm.commentAttachmentVM.url.includes('vimeo')) return <VideoIcon />
          if (vm.commentAttachmentVM.url.includes('youtube') || vm.commentAttachmentVM.url.includes('youtu.be')) return <VideoIcon />
          if (vm.commentAttachmentVM.url.startsWith('http')) {
            return (
              <div className='img-container'>
                <video>
                  <source src={vm.commentAttachmentVM.url} type='video/mp4' />
                </video>
              </div>
            )
          }
          else return <VideoIcon />
        }
        else return <VideoIcon />
      }

      return <DescriptionIcon />
    }

    return (
      <Grid item xs={6} className='input-attachment-row'>
        <span className='attachment-body'>
          {renderThumbnail()}
          <Typography
            variant='body1'
            style={{ cursor: 'pointer' }}
            onClick={() => vm.commentAttachmentVM.openAttachment()}
          >
            {vm.commentAttachmentVM.fileName}
          </Typography>
        </span>
        <span className='delete'>
          <CloseIcon onClick={() => vm.deleteAttachmentFromCommentInput()} />
        </span>
      </Grid>
    )
  }

  const renderCommentUploadModal = () => {
    if (organizationsStore.currentOrganization?.hasCMSPicker) return renderCMSUploadModal()
    return renderStandardUploadModal()
  }

  const renderCMSUploadModal = () => {
    return (
      <CMSItemsPickerDialog
        allowMultiple={false}
        fileTypes={CMSItemsTypeEnum.DEFAULT}
        addAfterSave={(a: Attachment[]) => { 
          const attachment = new Attachment()
          attachment.cmsItemId = a[0].cmsItemId
          vm.addAttachmentToTaskComment(attachment)
        }}
        toggleShowModal={() => vm.toggleCommentUploadModal()}
        isOpen={vm.showCommentUploadModal}
      />
    )
  }

  const renderStandardUploadModal = () => {
    return (
      <FileUploadModal
        allowMultiple={false}
        fileTypes={FileTypeEnum.DEFAULT}
        addAfterSave={(a: Attachment[]) => vm.addAttachmentToTaskComment(a[0])}
        toggleShowModal={() => vm.toggleCommentUploadModal()}
        isOpen={vm.showCommentUploadModal}
      />
    )
  }

  return (
    <Grid item xs={12} container spacing={1} id='commentInputSection'>
      {renderCommentUploadModal()}
      <Grid item xs={12} container spacing={1} alignItems='center'>
        <Grid item xs='auto'>
          <Avatar
            className='comments-avatar'
            alt={`${userStore.user.name}'s Avatar`}
            src={userStore.user.iconURL}
          />
        </Grid>
        <Grid item xs={true}>
          <TextField
            style={{ marginTop: '10px' }}
            variant='outlined'
            fullWidth
            multiline
            placeholder={strings.comment_placeholder}
            minRows={1}
            maxRows={4}
            onChange={handleCommentChange}
            value={vm.commentText}
          />
        </Grid>
      </Grid>
      {/* <Grid item xs={12} container spacing={1}>
        
      </Grid> */}

      <Grid item xs={12} container spacing={1} justifyContent='flex-end'>
        {renderAttachment()}

        <Grid item xs='auto'>
          {renderAddAttachmentButton()}
          <Button
            variant='outlined'
            disabled={vm.commentSaveDisabled}
            onClick={
              Boolean(vm.objectId) ? handleAddCommentToSavedTask : handleAddCommentToUnsavedTask
            }
          >
            {strings.add_comment}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default inject('userStore', 'tasksStore', 'localizationStore', 'organizationsStore')(observer(DrawerCommentInput))
