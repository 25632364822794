import { observable, action, computed } from 'mobx'
import { Option } from './Option'
import { Media } from './Media'
import { QuestionType } from '../types/QuestionType'
import { RatingDisplayType } from '../types/RatingDisplayType'
import { IQuestionDTO } from '../dtos/IQuestionDTO'
import { serializable, serialize, deserialize, list, object } from 'serializr'
import HashId from '../../shared/HashId'

export class Question implements IQuestionDTO {
  static create(q: IQuestionDTO) {
    const question = new Question()
    question.type = q.type
    question.rank = q.rank
    question.id = HashId.generate()
    question.title = q.label ? q.label : q.title
    // question.originalIndex = q.originalIndex
    question.isDeleted = q.isDeleted
    question.required = q.required
    question.ratingDisplay = q.ratingDisplay as RatingDisplayType
    question.categoryId = q.categoryId
    question.numberStep = q.numberStep
    question.decimalsAllowed = q.decimalsAllowed
    question.minimumValueAllowed = q.minimumValueAllowed
    question.maximumValueAllowed = q.maximumValueAllowed
    question.maximumRequired = q.maximumRequired
    question.minimumRequired = q.minimumRequired
    question.numberStepRequired = q.numberStepRequired
    question.defaultValueRequired = q.defaultValueRequired
    question.defaultValueNumeric = q.defaultValueNumeric
    question.isDropdownMultiSelect = q.isDropdownMultiSelect ? q.isDropdownMultiSelect : false
    question.placeholder = q.placeholder ? q.placeholder : ''
    question.pulseCategoryId = q.pulseCategoryId ? q.pulseCategoryId : ''

    if (q.required === undefined || q.required === null) {
      question.required = true
    }

    if (q.type === 'text' && !q.options[0]) {
      question.options.push(new Option())
      question.options[0].text = ''
    }

    if (q.media) {
      question.media = Media.create(q.media)
    }
    if (q.options) {
      for (let opt of q.options) {
        if (opt.isDeleted) continue
        question.options.push(Option.create(opt))
      }
    }

    return question
  }

  public static createDefaultFromEmojiType() {
    const question = new Question()
    question.type = 'emoji'
    question.title = 'How is it going?'
    question.addOption()
    question.options[0].setText('Excellent')
    question.options[0].setEmoji('happy-1')
    question.addOption()
    question.options[1].setText('Very Good')
    question.options[1].setEmoji('smile')
    question.addOption()
    question.options[2].setText('Average')
    question.options[2].setEmoji('bored')
    question.addOption()
    question.options[3].setText('Below Average')
    question.options[3].setEmoji('confused')
    question.addOption()
    question.options[4].setText('Extremely Poor')
    question.options[4].setEmoji('unhappy')
    return question
  }

  public static createFromEmojiType() {
    const question = new Question()
    question.type = 'emoji'
    question.addOption()
    question.options[0].setText('Excellent')
    question.options[0].setEmoji('happy-1')
    question.addOption()
    question.options[1].setText('Very Good')
    question.options[1].setEmoji('smile')
    question.addOption()
    question.options[2].setText('Average')
    question.options[2].setEmoji('bored')
    question.addOption()
    question.options[3].setText('Below Average')
    question.options[3].setEmoji('confused')
    question.addOption()
    question.options[4].setText('Extremely Poor')
    question.options[4].setEmoji('unhappy')
    return question
  }

  public static createFromRatingType() {
    const question = new Question()
    question.type = 'rating'
    question.addOption()
    question.options[0].setText('Very Poor')
    question.options[0].setRank(1)
    question.addOption()
    question.options[1].setText('Below Average')
    question.options[1].setRank(2)
    question.addOption()
    question.options[2].setText('Average')
    question.options[2].setRank(3)
    question.addOption()
    question.options[3].setText('Above Average')
    question.options[3].setRank(4)
    question.addOption()
    question.options[4].setText('Excellent')
    question.options[4].setRank(5)
    return question
  }

  public static createFromStarRatingType() {
    const question = new Question()
    question.type = 'starRating'
    question.addOption()
    question.options[0].setText('Very Poor')
    question.options[0].setRank(1)
    question.addOption()
    question.options[1].setText('Below Average')
    question.options[1].setRank(2)
    question.addOption()
    question.options[2].setText('Average')
    question.options[2].setRank(3)
    question.addOption()
    question.options[3].setText('Above Average')
    question.options[3].setRank(4)
    question.addOption()
    question.options[4].setText('Excellent')
    question.options[4].setRank(5)
    return question
  }

  public static createFromRadioType() {
    const question = new Question()
    question.type = 'radio'
    question.addOption()
    question.options[0].setText('Strongly Agree')
    question.addOption()
    question.options[1].setText('Agree')
    question.addOption()
    question.options[2].setText('Neutral')
    question.addOption()
    question.options[3].setText('Disagree')
    question.addOption()
    question.options[4].setText('Strongly Disagree')
    return question
  }

  public static createFromDropdownType() {
    const question = new Question()
    question.type = 'dropdown'
    question.addOption()
    question.options[0].setText('Strongly Disagree')
    question.addOption()
    question.options[1].setText('Disagree')
    question.addOption()
    question.options[2].setText('Neutral')
    question.addOption()
    question.options[3].setText('Agree')
    question.addOption()
    question.options[4].setText('Strongly Agree')
    return question
  }

  public static createFromCheckType() {
    const question = new Question()
    question.type = 'check'
    question.addOption()
    question.options[0].setText('Option 1')
    question.addOption()
    question.options[1].setText('Option 2')
    question.addOption()
    question.options[2].setText('Option 3')
    question.addOption()
    question.options[3].setText('Option 4')
    return question
  }

  public static createFromYesNoType() {
    const question = new Question()
    question.type = 'yesNo'
    question.addOption()
    question.options[0].setText('Yes')
    question.addOption()
    question.options[1].setText('No')
    return question
  }

  public static createFromTextType() {
    const question = new Question()
    question.type = 'text'
    question.addOption()
    question.options[0].text = ''
    return question
  }

  public static createFromInfoTextType() {
    const question = new Question()
    question.type = 'infoText'
    return question
  }

  public static createFromNumberType() {
    const question = new Question()
    question.type = 'number'
    question.addOption()
    question.options[0].text = ''
    return question
  }

  public static createFromOrderType() {
    const question = new Question()
    question.type = 'order'
    question.addOption()
    question.options[0].setText('Option One')
    question.addOption()
    question.options[1].setText('Option Two')
    question.addOption()
    question.options[2].setText('Option Three')
    question.addOption()
    question.options[3].setText('Option Four')
    return question
  }

  @serializable @observable type: QuestionType = 'emoji'
  @serializable @observable readOnly: boolean = false
  @serializable @observable rank: number = 0
  @serializable @observable id: string = HashId.generate()
  @serializable @observable title: string = ''
  @serializable @observable html: string = ''
  @serializable @observable categoryId: string = ''
  // @serializable @observable originalIndex: number = 0
  @serializable @observable isDeleted: boolean = false
  @serializable @observable required: boolean = true
  @serializable @observable ratingDisplay: RatingDisplayType = 'horizontal'
  @serializable @observable analyzeResponse: boolean = false
  @serializable @observable responseEntities: boolean = false
  @serializable @observable responseEmotions: boolean = false
  @serializable @observable responseKeywords: boolean = false
  @serializable @observable responseConcepts: boolean = false
  @serializable @observable responseRangeMin: number = -1
  @serializable @observable responseRangeMax: number = 1
  @serializable(object(Media)) @observable media: Media = new Media()
  @serializable(list(object(Option))) @observable options: Array<Option> = []
  @serializable @observable public decimalsAllowed: number = 0
  @serializable @observable public minimumValueAllowed: number = 0
  @serializable @observable public maximumValueAllowed: number = 0
  @serializable @observable public numberStep: number = 0
  @serializable @observable public minimumRequired: boolean = false
  @serializable @observable public maximumRequired: boolean = false
  @serializable @observable public numberStepRequired: boolean = false
  @serializable @observable public defaultValueNumeric: number = 0
  @serializable @observable public defaultValueRequired: boolean = false
  @serializable @observable public showMediaFirst: boolean = false
  @serializable @observable public youTubeURL: string = ''
  @serializable @observable public vimeoURL: string = ''
  @serializable @observable public pulseCategoryId: string = ''
  @observable public dirtyMinimumValueAllowed: string = ''
  @observable public dirtyMaximumValueAllowed: string = ''
  @observable public dirtyNumberStep: string = ''
  @observable public dirtyDefaultValueNumeric: string = ''
  @serializable @observable isDropdownMultiSelect: boolean = false
  @serializable @observable placeholder: string = 'Please Select'

  @computed
  public get numberStepAsString(): string {
    if (this.dirtyNumberStep) return this.dirtyNumberStep
    return String(this.numberStep)
  }

  @computed
  public get defaultValueNumericAsString(): string {
    if (this.dirtyDefaultValueNumeric) return this.dirtyDefaultValueNumeric
    return String(this.defaultValueNumeric)
  }

  @computed
  public get minimumValueAllowedAsString(): string {
    if (this.dirtyMinimumValueAllowed) return this.dirtyMinimumValueAllowed
    return String(this.minimumValueAllowed)
  }

  @computed
  public get maximumValueAllowedAsString(): string {
    if (this.dirtyMaximumValueAllowed) return this.dirtyMaximumValueAllowed
    return String(this.maximumValueAllowed)
  }

  @action
  public setDecimalsAllowed(val: number) {
    if (!val) val = 0
    this.decimalsAllowed = val
  }

  @action
  public setMinimumValueAllowed(val: string) {
    this.dirtyMinimumValueAllowed = val
    let decimals = 0
    const decimalIndex = val.lastIndexOf('.')
    if (isNaN(Number(val))) {
      this.minimumValueAllowed = 0
      return
    }
    if (decimalIndex) {
      decimals = val.length - decimalIndex - 1
    }
    if (decimalIndex === -1) {
      this.minimumValueAllowed = Number(val)
    }
    if (decimalIndex && decimals <= this.decimalsAllowed) {
      this.minimumValueAllowed = Number(val)
    }
  }

  @action
  public setMaximumValueAllowed(val: string) {
    this.dirtyMaximumValueAllowed = val
    if (isNaN(Number(val))) {
      this.maximumValueAllowed = 0
      return
    }
    let decimals = 0
    const decimalIndex = val.lastIndexOf('.')
    if (decimalIndex) {
      decimals = val.length - decimalIndex - 1
    }
    if (decimalIndex === -1) {
      this.maximumValueAllowed = Number(val)
    }
    if (decimalIndex && decimals <= this.decimalsAllowed) {
      this.maximumValueAllowed = Number(val)
    }
  }

  @action
  public setNumberStep(val: string) {
    this.dirtyNumberStep = val
    if (isNaN(Number(val))) {
      this.numberStep = 0
      return
    }
    this.numberStep = Number(val)
  }

  @action
  public toggleRequired() {
    this.required = !this.required
  }

  @action
  public toggleMinimumRequired() {
    this.minimumRequired = !this.minimumRequired
  }

  @action
  public toggleMaximumRequired() {
    this.maximumRequired = !this.maximumRequired
  }

  @action
  public toggleDefaultValueRequired() {
    this.defaultValueRequired = !this.defaultValueRequired
  }

  @action
  public toggleNumberStepRequired() {
    this.numberStepRequired = !this.numberStepRequired
  }

  @action
  public toggleDropdownMultiSelect() {
    this.isDropdownMultiSelect = !this.isDropdownMultiSelect
  }

  @action
  public increaseDefaultValueNumeric() {
    this.setDefaultValueNumeric(String(Number(this.defaultValueNumeric) + 1))
  }

  @action
  public decreaseDefaultValueNumeric() {
    this.setDefaultValueNumeric(String(Number(this.defaultValueNumeric) - 1))
  }

  @action
  public increaseMinimumValueAllowed() {
    this.setMinimumValueAllowed(String(Number(this.minimumValueAllowed) + 1))
  }

  @action
  public decreaseMinimumValueAllowed() {
    this.setMinimumValueAllowed(String(Number(this.minimumValueAllowed) - 1))
  }

  @action
  public increaseMaximumValueAllowed() {
    this.setMaximumValueAllowed(String(Number(this.maximumValueAllowed) + 1))
  }

  @action
  public decreaseMaximumValueAllowed() {
    this.setMaximumValueAllowed(String(Number(this.maximumValueAllowed) - 1))
  }

  @action
  public increaseNumberStep() {
    this.setNumberStep(String(Number(this.numberStep) + 1))
  }

  @action
  public decreaseNumberStep() {
    this.setNumberStep(String(Number(this.numberStep) - 1))
  }

  @action
  public setDefaultValueNumeric(val: string) {
    this.dirtyDefaultValueNumeric = val
    if (isNaN(Number(val))) {
      this.defaultValueNumeric = 0
      return
    }
    let decimals = 0
    const decimalIndex = val.lastIndexOf('.')
    if (decimalIndex) {
      decimals = val.length - decimalIndex - 1
    }
    if (decimalIndex === -1) {
      this.defaultValueNumeric = Number(val)
    }
    if (decimalIndex && decimals <= this.decimalsAllowed) {
      this.defaultValueNumeric = Number(val)
    }
  }

  @action
  public removeMedia() {
    this.media = new Media()
  }

  @action
  public addMedia(frm) {
    this.media = Media.create(frm)
  }

  @action
  public setHTML(html) {
    this.html = html
  }

  @action
  public setTitle(title) {
    this.title = title
  }

  @action
  public setCategoryId(id: string) {
    this.categoryId = id
  }

  @action
  public setPulseCategoryId(id: string) {
    this.pulseCategoryId = id
  }

  @action
  public addOption() {
    this.options.push(new Option())
    if (this.type === 'emoji') {
      this.options[this.options.length - 1].setEmoji('happy-4')
    }
  }

  @action
  public moveOption(index, newIndex) {
    const optionObj = this.options.splice(index, 1)
    this.options.splice(newIndex, 0, optionObj[0])
  }

  public setOptionRanks() {
    this.options.forEach((opt, idx) => {
      opt.setRank(idx + 1)
    })
  }

  @action
  public moveOptionUp(idx: number) {
    const newIdx = idx - 1
    const optionObj = this.options.splice(idx, 1)
    this.options.splice(newIdx, 0, optionObj[0])
  }

  @action
  public moveOptionDown(idx: number) {
    const newIdx = idx + 1
    const optionObj = this.options.splice(idx, 1)
    this.options.splice(newIdx, 0, optionObj[0])
  }

  @action
  public deleteOption(idx: number) {
    this.options.splice(idx, 1)
  }

  @action
  public markAsDeleted() {
    this.isDeleted = true
  }

  @action
  public changeQuestionType(val: QuestionType) {
    if (this.type === 'emoji' && val !== 'emoji') {
      this.options.forEach((option) => {
        option.emoji = ''
      })
    }
    if (this.type === 'text' && val !== 'text') {
      this.options[0].text = 'Good'
      this.addOption()
      this.options[1].text = 'Well'
      this.addOption()
      this.options[2].text = 'Ok'
      this.addOption()
      this.options[3].text = 'Bad'
    }
    if (this.type === 'text' && val === 'check') {
      this.options = []
      this.addDefaultCheckOptions()
    }
    if (this.type === 'text' && val === 'yesNo') {
      this.options = []
      this.addDefaultYesNoOptions()
    }
    if (this.type === 'yesNo' && val !== 'yesNo') {
      this.addOption()
      this.addOption()
    }
    if (val === 'emoji') {
      while (this.options.length < 5) this.addOption()
      this.addDefaultEmojiIcons()
    }
    if (val === 'radio') {
      this.options = []
      this.addOption()
      this.options[0].text = 'Strongly Agree'
      this.addOption()
      this.options[1].text = 'Agree'
      this.addOption()
      this.options[2].text = 'Neutral'
      this.addOption()
      this.options[3].text = 'Disagree'
      this.addOption()
      this.options[4].text = 'Strongly Disagree'
    }
    if (val === 'dropdown') {
      this.options = []
      this.addOption()
      this.options[0].text = 'Strongly Disagree'
      this.addOption()
      this.options[1].text = 'Disagree'
      this.addOption()
      this.options[2].text = 'Neutral'
      this.addOption()
      this.options[3].text = 'Agree'
      this.addOption()
      this.options[4].text = 'Strongly Agree'
    }
    if (val === 'text') {
      this.options = []
      this.addDefaultTextOptions()
    }
    if (val === 'yesNo') {
      this.options = []
      while (this.options.length < 2) {
        this.addOption()
      }
      this.options[0].text = 'Yes'
      this.options[1].text = 'No'
    }
    if (val === 'number') {
      this.options = []
      this.addDefaultTextOptions()
    }
    if (val === 'order') {
      this.options = []
      this.addDefaultCheckOptions()
    }

    if (this.type === 'infoText' && val !== 'infoText') {
      this.required = true
    } else if (this.type !== 'infoText' && val === 'infoText') {
      this.options = []
      this.required = false
    }

    if (val === 'rating') {
      this.options = []
      this.addDefaultRatingOptions()
    }

    if (val === 'starRating') {
      this.options = []
      this.addDefaultStarRatingOptions()
    }

    this.type = val
  }

  addDefaultCheckOptions() {
    this.addOption()
    this.options[0].text = 'Option one'
    // this.options[0].check = null
    this.addOption()
    this.options[1].text = 'Option two'
    // this.options[1].check = null
    this.addOption()
    this.options[2].text = 'Option three'
    // this.options[2].check = null
    this.addOption()
    this.options[3].text = 'Option four'
    // this.options[3].check = null
  }

  @action
  private addDefaultYesNoOptions() {
    this.addOption()
    this.options[0].text = 'Yes'
    this.addOption()
    this.options[1].text = 'No'
  }

  @action
  private addDefaultRatingOptions() {
    this.addOption()
    this.options[0].text = 'Very Poor'
    this.options[0].rank = 1
    this.addOption()
    this.options[1].text = 'Below Average'
    this.options[1].rank = 2
    this.addOption()
    this.options[2].text = 'Average'
    this.options[2].rank = 3
    this.addOption()
    this.options[3].text = 'Above Average'
    this.options[3].rank = 4
    this.addOption()
    this.options[4].text = 'Excellent'
    this.options[4].rank = 5
    this.options.push()
  }

  @action
  private addDefaultStarRatingOptions() {
    this.addOption()
    this.options[0].text = 'Very Poor'
    this.options[0].rank = 1
    this.options[0].value = 1
    this.addOption()
    this.options[1].text = 'Below Average'
    this.options[1].rank = 2
    this.options[1].value = 2
    this.addOption()
    this.options[2].text = 'Average'
    this.options[2].rank = 3
    this.options[2].value = 3
    this.addOption()
    this.options[3].text = 'Above Average'
    this.options[3].rank = 4
    this.options[3].value = 4
    this.addOption()
    this.options[4].text = 'Excellent'
    this.options[4].rank = 5
    this.options[4].value = 5
    this.options.push()
  }

  @action
  private addDefaultEmojiIcons() {
    this.options[0].emoji = 'happy-1'
    this.options[0].text = 'Excellent'
    this.options[1].emoji = 'smile'
    this.options[1].text = 'Very Good'
    this.options[2].emoji = 'bored'
    this.options[2].text = 'Average'
    this.options[3].emoji = 'confused'
    this.options[3].text = 'Below Average'
    this.options[4].emoji = 'unhappy'
    this.options[4].text = 'Extremely Poor'
  }

  @action
  private addDefaultTextOptions() {
    this.addOption()
    this.options[0].text = ''
  }

  @action
  public setRank(val: number) {
    this.rank = val
  }

  @action
  public setRatingDisplay(val: RatingDisplayType) {
    this.ratingDisplay = val
  }

  @action
  public setPlaceholder(placeholder: string) {
    this.placeholder = placeholder
  }

  public clone(): Question {
    return deserialize(Question, this.toDTO())
  }

  public toDTO(): IQuestionDTO {
    const dto = serialize(this)
    return dto
  }
}
