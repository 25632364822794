import React from 'react'

//MobX Stores
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { UserTasksStore } from '../../store/UserTasksStore'

//MaterialUI Components
import { Button, Card, CardContent, CardActions, Typography, Grid, Paper } from '@material-ui/core'
import Avatar from '../../../shared/Avatar'
//Components
import WidgetTimelineHeader from './WidgetTimelineHeader'
// import Avatar from '../../../shared/Avatar'

interface IWidgetTimelineTodaySection {
  localizationStore?: LocalizationStore
  userTasksStore?: UserTasksStore
}

const WidgetTimelineTodaySection: React.FC<IWidgetTimelineTodaySection> = ({
  localizationStore,
  userTasksStore,
}) => {
  const { lzStrings } = localizationStore
  const { userTasksTimelineVM: vm } = userTasksStore

  if (!vm) return null

  const renderNoTasksDueToday = () => {
    if (Boolean(vm.overDueTasks.length)) return null
    if (Boolean(vm.tasksDueToday.length)) return null

    return (
      <Card elevation={1} className='task-item none'>
        <CardContent>
          <Typography className='task-name'>
            {lzStrings.pendingUserTasksWidget.no_tasks_due_today}
          </Typography>
        </CardContent>
      </Card>
    )
  }

  const renderTodayTasks = () => {
    if (!Boolean(vm.tasksDueToday.length)) return null
    return vm.tasksDueToday.map((userTask) => {
      return (
        <Card
          elevation={1}
          className='task-item'
          key={userTask.objectId}
          onClick={() => vm.loadUserTask(userTask.objectId)}
        >
          <CardContent>
            <Typography gutterBottom className='task-name'>
              {userTask.name}
            </Typography>
            <Typography color='textSecondary' className='task-desc'>
              {(userTask.description && <span>{userTask.description}</span>) || (
                <span>No description provided</span>
              )}
            </Typography>
          </CardContent>
          <CardActions className='card-actions'>
            <Typography variant='body2' component='span' className='discipline-chip'>
              {userTask.discipline}
            </Typography>
            <Avatar user={userTask.creator} size={26} fontSize={13} />
          </CardActions>
        </Card>
      )
    })
  }

  const renderOverdueTasks = () => {
    if (!Boolean(vm.overDueTasks.length)) return null
    return vm.overDueTasks.map((userTask) => {
      return (
        <Card
          elevation={1}
          className='task-item over-due'
          key={userTask.objectId}
          onClick={() => vm.loadUserTask(userTask.objectId)}
        >
          <CardContent>
            <Grid container direction='row' justifyContent='space-between'>
              <Typography gutterBottom className='task-name'>
                {userTask.name}
              </Typography>
              <Typography variant='h5' className='over-due-days'>
                {userTask.daysOverDue} days overdue
              </Typography>
            </Grid>

            <Typography color='textSecondary' className='task-desc'>
              {(userTask.description && <span>{userTask.description}</span>) || (
                <span>No description provided</span>
              )}
            </Typography>
          </CardContent>
          <CardActions className='card-actions'>
            <Typography variant='body2' component='span' className='discipline-chip'>
              {userTask.discipline}
            </Typography>
            <Avatar user={userTask.creator} size={26} fontSize={13} />
          </CardActions>
        </Card>
      )
    })
  }

  return (
    <Grid container wrap='wrap'>
      <Grid
        item
        xs={1}
        container
        spacing={0}
        direction='column'
        justifyContent='center'
        alignItems='center'
        className='dateContainer'
      >
        <span className='month'>{vm.today.format('MMM')}</span>
        <span className='day'>{vm.today.format('DD')}</span>
        <span className='circle'></span>
      </Grid>
      <Grid item xs={11} className='paddedContainer'>
        <Grid container direction='column' wrap='nowrap'>
          {renderTodayTasks()}
          {renderOverdueTasks()}
          {renderNoTasksDueToday()}
        </Grid>
      </Grid>
    </Grid>
  )
}
export default inject('localizationStore', 'userTasksStore')(observer(WidgetTimelineTodaySection))
