import { observable, computed, action, reaction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { IOption } from '../types/Types'
import { ParticipantsSelectVM } from '../../participants-select/view-models/ParticipantsSelectVM'
import { IParticipant } from '../../participants-select/interfaces/IParticipant'
import { Survey } from '../../surveys/aggregate/Survey'
import { Task } from '../aggregates/Task'
import { TaskCommentEditVM } from '../../task-comments/view-models/TaskCommentEditVM'
import { TaskComment } from '../../task-comments/aggregates/TaskComment'
import { TaskCommentsService } from '../../task-comments/service/TaskCommentsService'
import { MediaItemsService } from '../../media-items/service/MediaItemsService'
import { AlarmVM } from './AlarmVM'
import moment from 'moment'
import { IUserTaskDTO } from '../../user-tasks/dtos/IUserTaskDTO'
import { TasksService } from '../service/TasksService'
import { UserTaskSnapshot } from '../aggregates/UserTaskSnapshot'
import { Participant } from '../../events/aggregate/Participant'
import { Attachment } from '../../attachments/aggregate/Attachment'
import { isNumeric } from '../../shared/isNumeric'
import { OldAttachmentVM } from '../../attachments/view-models/OldAttachmentVM'
import { AttachmentVM } from '../../attachments/view-models/AttachmentVM'

export class TaskEditVM {
  private rootStore: RootStore
  private taskCommentsSvc: TaskCommentsService
  private taskSvc: TasksService

  constructor(rootStore: RootStore, task: Task) {
    this.rootStore = rootStore
    this.task = task
    this.taskParticipants = task.assignedToAudienceMembers
    this.participantsSelect = new ParticipantsSelectVM(rootStore, false, false)
    this.participantsSelect.setParticipants(this.taskParticipants)
    // this.participantsSelect.setVisible()
    setTimeout(() => this.setDefaultSurveyAndAutoPublish(), 100)
    this.taskCommentsSvc = new TaskCommentsService()
    this.taskSvc = new TasksService()
    if (this.task.alarms) this.checkForOnTimeAlarm()
    reaction(
      () => this.assignedToAudienceMembers.length,
      () => (this.isDirty = true)
    )
  }

  @observable public task: Task = null
  @observable public assignedToAudienceMembers: Array<IParticipant> = []
  @observable public newTaskComments: Array<TaskCommentEditVM> = []
  @observable public commentText: string = ''
  @observable public isDirty: boolean = false
  @observable public saveTried: boolean = false
  @observable public saveRequested: boolean = false
  @observable public saveSuccessful: boolean = false
  @observable public saveFailed: boolean = false
  @observable public createdAt: number = 0
  @observable public participantsSelectOpen: boolean = false
  @observable public participantsPickerTabIndex: number = 0
  @observable public participantsSelect: ParticipantsSelectVM = null
  @observable public tabIndex: number = 0
  @observable public onTimeAlarm: boolean = false
  @observable public commentAttachment: Attachment = null
  @observable public userTasks: IUserTaskDTO[] = []
  @observable public taskParticipants: Array<Participant> = []
  @observable public showTaskUploadModal: boolean = false
  @observable public showCommentUploadModal: boolean = false

  @computed
  public get snapshots(): UserTaskSnapshot[] {
    return this.task.snapshots
  }

  @action
  public checkForOnTimeAlarm() {
    const onTimeAlarm = this.alarms.filter((alarm) => alarm.units === 0)[0]
    if (onTimeAlarm) this.handleOnTimeAlarmToggle()
  }

  @computed
  public get alarms(): AlarmVM[] {
    return this.task.alarms.map((alarm) => new AlarmVM(this.rootStore, this, alarm))
  }

  @computed
  public get allowFeedback(): boolean {
    if (!this.rootStore.organizationsStore.currentOrganization) return false
    if (this.availableSurveyTemplates.length < 1) return false
    return this.rootStore.organizationsStore.currentOrganization.tasksAllowFeedback
  }

  @computed
  public get allowDiscipline(): boolean {
    if (!this.rootStore.organizationsStore.currentOrganization) return false
    return this.rootStore.organizationsStore.currentOrganization.tasksAllowDiscipline
  }

  @computed
  public get allowPartnerChannel(): boolean {
    if (!this.rootStore.organizationsStore.currentOrganization) return false
    return this.rootStore.organizationsStore.currentOrganization.tasksAllowPartnerChannel
  }

  @computed
  public get disciplineRequired(): boolean {
    if (!this.allowDiscipline) return false
    return true
  }

  @action
  public handleOnTimeAlarmToggle() {
    this.onTimeAlarm = !this.onTimeAlarm
    if (!this.onTimeAlarm) this.removeOnTimeAlarmFromTask()
  }

  @action
  public addAlarm() {
    this.task.addAlarm()
  }

  @action
  public addOnTimeAlarmToTask() {
    this.task.addOnTimeAlarm()
  }

  @action
  public removeOnTimeAlarmFromTask() {
    const onTimeAlarm = this.alarms.filter((alarm) => alarm.units === 0)[0]
    this.deleteAlarm(onTimeAlarm.id)
  }

  @action
  public deleteAlarm(id: string) {
    this.task.deleteAlarm(id)
  }

  @action
  public setTabIndex(val: number) {
    this.tabIndex = val
    if (this.tabIndex === 1 && !this.hasParticipants) this.openParticipantsSelect()
  }

  @computed
  public get hasParticipants(): boolean {
    if (
      this.task.assignedToAudienceMembers.length === 0 &&
      this.assignedToAudienceMembers.length === 0
    )
      return false
    return true
  }

  @computed
  public get availableSurveyTemplates(): Array<Survey> {
    if (!this.rootStore.surveysStore) return []
    if (!this.rootStore.surveyTypesStore) return []
    const results = []
    // const results = this.rootStore.surveyTemplatesStore.currentOrgSurveyTemplates.filter((e) => {
    //   const foundType = this.rootStore.surveyTypesStore.getSurveyType(e.surveyTypeId)
    //   if (!foundType) return false
    //   if (!foundType.isFeedbackType) return false
    //   return true
    // })
    return results.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0))
  }

  @computed
  public get selectedSurveyTemplate(): Survey {
    if (!this.feedbackSurveyTemplateId) return null
    return this.availableSurveyTemplates.find((e) => e.objectId === this.feedbackSurveyTemplateId)
  }

  @action
  public setSelectedSurveyTemplate(id: string) {
    this.task.setFeedbackTemplateSurveyId(id)
    this.isDirty = true
  }

  @action
  public setDefaultSurveyAndAutoPublish() {
    if (this.task.objectId) return
    if (this.task.feedbackSurveyTemplateId) return
    if (!this.availableSurveyTemplates) return
    const surveys = this.availableSurveyTemplates.filter((e) => e.objectId !== 'new')
    if (surveys.length === 0) return
    this.setSelectedSurveyTemplate(surveys[0].objectId)
  }

  @computed
  public get feedbackSurveyTemplateId(): string {
    return this.task.feedbackSurveyTemplateId
  }

  @computed
  public get assignedByUserId(): string {
    return this.task.assignedByUserId
  }

  @computed
  public get organizationId(): string {
    return this.task.organizationId
  }

  @computed
  public get objectId(): string {
    return this.task.objectId
  }

  @computed
  public get name(): string {
    return this.task.name
  }

  @computed
  public get attachments(): AttachmentVM[] {
    return this.task.attachments.map((e, idx) => {
      if (isNumeric(e.objectId) || e.cmsItemId) {
        return this.rootStore.cmsItemAttachmentStore.loadAttachment(e)
      }
      return new OldAttachmentVM(this.rootStore, e, idx)
    })
  }

  @action
  public setTaskName(name: string) {
    this.task.setName(name)
    this.isDirty = true
  }

  @computed
  public get categoryId(): string {
    return this.task.categoryId
  }

  @action
  public setTaskDisciplineId(id: string) {
    this.task.setCategoryId(id)
    this.isDirty = true
  }

  @computed
  public get dueDate(): number {
    return this.task.dueDate
  }

  @computed
  public get dueDateAsDate(): Date {
    return moment.unix(this.task.dueDate).toDate()
  }

  @action
  public addAttachmentsToTask(attachments: Attachment[]) {
    attachments.forEach((a: Attachment) => this.task.addAttachment(a))
    this.isDirty = true
  }

  @action
  public deleteAttachmentFromTask(index: number) {
    this.task.deleteAttachment(index)
    this.isDirty = true
  }

  @computed
  public get dueDateValid(): boolean {
    if (!this.dueDate) return false
    return true
  }

  @action
  public setDueDate(unixTimecode) {
    this.task.setDueDate(unixTimecode)
    this.isDirty = true
  }

  @action
  public setDueDateTime(hour: number, minute: number) {
    const newTime = moment.unix(this.dueDate).hour(hour).minute(minute).second(0)
    this.task.setDueDate(moment(newTime).unix())
  }

  @action
  public setAssignedByUserId(userId: string) {
    this.task.setCreator(userId)
  }

  @computed
  public get channelPartnerId(): string {
    return this.task.channelPartnerId
  }

  @action
  setTaskPartnerChannel(id: string) {
    this.task.setChannelPartnerId(id)
    this.isDirty = true
  }

  @computed
  public get autoPublishFeedback(): boolean {
    return this.task.autoPublishFeedback
  }

  @action
  public toggleAutoPublishFeedback() {
    this.task.toggleAutoPublishFeedback()
    this.isDirty = true
  }

  @computed
  public get description(): string {
    return this.task.description
  }

  @action
  public setTaskDescription(description: string) {
    this.task.setDescription(description)
    this.isDirty = true
  }

  @computed
  public get taskTypeId(): string {
    return this.task.taskTypeId
  }

  @action
  public setTaskTypeId(id: string) {
    this.task.setTaskTypeId(id)
    this.isDirty = true
  }

  @action
  public openParticipantsSelect() {
    this.participantsSelect = new ParticipantsSelectVM(this.rootStore, false, false)
    this.participantsSelect.setParticipants(this.taskParticipants)
    this.participantsSelect.setVisible()
    this.participantsSelectOpen = true
  }

  @action
  public closeParticipantsSelect() {
    this.taskParticipants = this.participantsSelect.participants
    this.participantsSelectOpen = false
  }

  @action
  public setParticipantsPickerTabIndex(value: number) {
    this.participantsPickerTabIndex = value
  }

  @action
  public removeParticipant(id) {
    this.participantsSelect.deleteParticipant(id)
    this.assignedToAudienceMembers = this.assignedToAudienceMembers.filter(
      (member) => member.id !== id
    )
  }

  @computed
  public get showAutoPublish(): boolean {
    if (this.feedbackSurveyTemplateId === 'new') return false
    if (!this.feedbackSurveyTemplateId) return false
    return true
  }

  // @computed
  // public get userTasks() {
  //   return this.rootStore.userTasksStore.userTasks.filter(
  //     (e) => e.taskId === this.objectId && !e.isDeleted
  //   )
  // }

  @action
  public async generateSignature(callback: Function, paramsToSign: object): Promise<void> {
    const svc = new MediaItemsService()
    const signature = await svc.getCloudinarySignature(paramsToSign)
    callback(signature)
  }

  // @action
  // public showWidget(forComment: boolean) {
  //   window.cloudinary.openUploadWidget(
  //     {
  //       multiple: true,
  //       maxFiles: 5,
  //       cloudName: 'rippleworx',
  //       // clientAllowedFormats: ['jpg', 'png', 'gif', 'jpeg', 'svg', 'mp4', 'mov', 'csv'],
  //       sources: ['local', 'image_search', 'camera', 'dropbox', 'google_drive'],
  //       showPoweredBy: false,
  //       apiKey: '914141713215552',
  //       uploadSignature: this.generateSignature,
  //     },
  //     async (error, result) => {
  //       if (!error && result && result.event === 'success') {
  //         const attachment = Attachment.createFromCloudinaryResult(result.info)
  //         if (result.info.resource_type.includes('video')) {
  //           const thumbnail = await this.getThumbnailUrl(result.info)
  //           attachment.thumbnail = thumbnail
  //         }
  //         if (forComment) {
  //           this.addAttachmentToTaskComment(attachment)
  //         } else {
  //           this.addAttachmentToTask(attachment)
  //         }
  //       }
  //     }
  //   )
  // }

  public toggleTaskUploadModal() {
    this.showTaskUploadModal = !this.showTaskUploadModal
  }

  public async getThumbnailUrl(info) {
    const svc = new MediaItemsService()
    const thumbnailUrl = await svc.buildThumbnailURL(info.public_id, '.jpg', info.secure_url)
    return thumbnailUrl
  }

  @computed
  public get disciplineOptions(): Array<IOption> {
    return this.rootStore.categoriesStore.categories
      .filter((category) => {
        return !Boolean(category.parentCategoryId)
      })
      .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0))
      .map((category) => ({ objectId: category.objectId, label: category.name }))
  }

  @computed
  public get channelPartnerOptions(): Array<IOption> {
    return this.rootStore.clientsStore.clients.map((client) => ({
      objectId: client.objectId,
      label: client.name,
    }))
  }

  @computed
  public get taskTypeOptions(): Array<IOption> {
    return this.rootStore.taskTemplatesStore.taskTemplates.map((template) => ({
      objectId: template.objectId,
      label: template.name,
    }))
  }

  @computed
  public get commentAttachmentVM(): AttachmentVM {
    if (!this.commentAttachment) return null
    if (isNumeric(this.commentAttachment.objectId) || this.commentAttachment.cmsItemId) {
      return this.rootStore.cmsItemAttachmentStore.loadAttachment(this.commentAttachment)
    }
    return new OldAttachmentVM(this.rootStore, this.commentAttachment)
  }

  @computed
  public get savedComments(): Array<TaskCommentEditVM> {
    return this.rootStore.taskCommentsStore.taskComments
      .filter((comment) => comment.taskId === this.objectId)
      .sort((a, b) => a.datePosted - b.datePosted)
      .map((comment) => new TaskCommentEditVM(this.rootStore, comment))
  }

  @computed
  public get comments(): Array<TaskCommentEditVM> {
    return Boolean(this.objectId) ? this.savedComments : this.newTaskComments
  }

  @action
  public setCommentText(text: string) {
    this.commentText = text
    this.isDirty = true
  }

  @action
  public toggleCommentUploadModal() {
    this.showCommentUploadModal = !this.showCommentUploadModal
  }

  @action public addAttachmentToTaskComment(attachment: Attachment) {
    this.commentAttachment = attachment
  }

  @action
  public deleteAttachmentFromCommentInput() {
    this.commentAttachment = null
  }

  @action
  public addCommentToUnsavedTask(): void {
    if (!this.commentText && !this.commentAttachment) return
    const taskComment = TaskComment.create(
      this.organizationId,
      this.rootStore.appStore.currentUserId,
      this.commentText ? this.commentText.trim() : '',
      null,
      this.commentAttachment
    )
    const comment = new TaskCommentEditVM(this.rootStore, taskComment)

    this.newTaskComments.push(comment)
    this.commentText = ''
    this.commentAttachment = null
  }

  @computed
  public get commentSaveDisabled(): boolean {
    if (!this.commentText && !this.commentAttachment) return true
    return false
  }

  @action
  public async addCommentToSavedTask() {
    if (!this.commentText && !this.commentAttachment) return
    const taskComment = TaskComment.create(
      this.organizationId,
      this.rootStore.appStore.currentUserId,
      this.commentText ? this.commentText.trim() : '',
      this.objectId,
      this.commentAttachment
    )

    await this.taskCommentsSvc.saveTaskComment(this.organizationId, taskComment)
    this.commentText = ''
    this.commentAttachment = null
  }

  @action
  public saveNewTaskComments(id: string) {
    this.newTaskComments.forEach((taskCommentVM) => {
      const taskComment = TaskComment.create(
        this.organizationId,
        this.rootStore.appStore.currentUserId,
        taskCommentVM.text.trim(),
        id,
        taskCommentVM.taskComment.attachment
      )
      this.taskCommentsSvc.saveTaskComment(this.organizationId, taskComment)
    })
  }

  @action
  public addOnTimeAlarm() {
    const existingOnTimeAlarm = this.alarms.filter((alarm) => alarm.units === 0)[0]
    if (!existingOnTimeAlarm) this.addOnTimeAlarmToTask()
  }

  @action
  public async saveTask() {
    this.saveTried = true
    if (!this.saveValid) return
    this.saveRequested = true
    if (this.onTimeAlarm) this.addOnTimeAlarm()
    const dto = this.task.serialize()
    dto.assignedToAudienceMembers = this.participantsSelect.participantsToDTO()
    try {
      const result = await this.taskSvc.saveTask(this.organizationId, dto)
      if (result) {
        this.saveSuccessful = true
        this.saveRequested = false
        this.saveNewTaskComments(result)
        setTimeout(() => this.rootStore.tasksStore.closeDrawer(), 500)
      } else {
        this.saveFailed = true
        this.saveRequested = false
      }
    } catch (error) {
      this.saveFailed = true
      this.saveRequested = false
      throw error
    }
  }

  @action
  public async deleteTask() {
    const result = await this.taskSvc.deleteTask(this.objectId, this.organizationId)
    if (result) {
      setTimeout(() => this.rootStore.tasksStore.closeDrawer(), 1000)
    }
  }

  @action
  public resetForm() {
    this.assignedToAudienceMembers = []
    this.newTaskComments = []
    this.isDirty = false
    this.saveRequested = false
    this.saveSuccessful = false
    this.saveFailed = false
  }

  @computed
  public get detailsTabValid(): boolean {
    if (this.disciplineError || this.nameError) return false
    return true
  }

  @computed
  public get participantsTabValid(): boolean {
    if (this.participantsError) return false
    return true
  }

  @computed
  public get saveValid(): boolean {
    if (this.disciplineError || this.participantsError || this.nameError) return false
    return true
  }

  @computed
  public get saveDisabled(): boolean {
    return !this.saveValid || this.saveRequested
  }

  @computed
  public get disciplineError(): boolean {
    if (this.disciplineRequired && !this.categoryId && this.saveTried) return true
    return false
  }

  @computed
  public get participantsError(): boolean {
    if (!this.hasParticipants && this.saveTried) return true
    return false
  }

  @computed
  public get nameError() {
    if (!this.objectId && !this.name && this.saveTried) return true
    return false
  }

  @computed
  public get categoryError() {
    if (this.disciplineRequired && !this.categoryId) return true
    return false
  }

  @computed
  public get selectedDisciplineOption(): IOption {
    if (!Boolean(this.categoryId)) return null
    return this.disciplineOptions.filter((option) => option.objectId === this.categoryId)[0]
  }

  @computed
  public get selectedChannelPartnerOption(): IOption {
    if (!Boolean(this.channelPartnerId)) return null
    return this.channelPartnerOptions.filter(
      (option) => option.objectId === this.channelPartnerId
    )[0]
  }

  @computed
  public get selectedTaskTypeOption(): IOption {
    if (!Boolean(this.taskTypeId)) return null
    return this.taskTypeOptions.filter((option) => option.objectId === this.taskTypeId)[0]
  }

  @computed
  public get addSelectedParticipantsButtonEnabled(): boolean {
    return true
  }

  @action
  public async getCloudinarySignature(paramsToSign) {
    const svc = new MediaItemsService()
    return await svc.getCloudinarySignature(paramsToSign)
  }
}
