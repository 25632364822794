import React from 'react'
import { inject, observer } from 'mobx-react'
import { AppStore } from '../../../stores/AppStore'
import { SurveysStore } from '../../store/SurveysStore'
import LocalizationStore from '../../../localization/LocalizationStore'
import { AppBar, Toolbar, LinearProgress, TextField, Button } from '@material-ui/core'
import BackIcon from '@material-ui/icons/KeyboardBackspace'
import MuiConfirmDeleteDialog from '../../../shared/MuiConfirmDeleteDialog'
import './TopToolbar.scss'
import TopToolbarEndButtons from './TopToolbarEndButtons'
import MuiConfirmPrompt from '../../../shared/MuiConfirmPrompt'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import { LabelsStore } from '../../../labels/store/LabelsStore'

interface Props {
  surveysStore?: SurveysStore
  appStore?: AppStore
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
  routeParams?: any
}

const TopToolbar: React.FC<Props> = ({
  surveysStore,
  appStore,
  localizationStore,
  labelsStore,
  routeParams,
}) => {
  const s = localizationStore.lzStrings
  const vm = surveysStore.viewModels.editVM
  if (!vm) return null

  const renderConfirmDelete = () => {
    if (!vm.showDeleteDialog) return
    let itemType = labelsStore.getLabelByLanguageAndFor('pulse').toLowerCase()
    if (vm.isSurveyTemplateEdit) itemType = 'template'
    return (
      <MuiConfirmDeleteDialog
        onClose={() => vm.toggleDeleteDialog()}
        open={vm.showDeleteDialog}
        itemType={itemType}
        itemName={vm.name}
        onConfirm={() => vm.delete()}
      />
    )
  }

  const renderConfirmSave = () => {
    if (!vm.confirmSaveDialogOpen) return
    return (
      <MuiConfirmPrompt
        confirmMessage={s.surveys.are_you_sure_publish}
        confirmMessageSecondary={`${labelsStore.getLabelByLanguageAndFor('pulses')} ${
          s.surveys.will_be_sent_based
        } ${labelsStore.getLabelByLanguageAndFor('pulses').toLowerCase()} ${
          s.surveys.will_be_sent_imediately
        }`}
        onClose={() => vm.toggleConfirmSave()}
        open={vm.confirmSaveDialogOpen}
        onConfirm={() => vm.save()}
        title={`${s.surveys.publish} ${labelsStore.getLabelByLanguageAndFor('pulse')}`}
      />
    )
  }

  const renderConfirmClose = () => {
    return (
      <MuiConfirmPrompt
        confirmMessage={vm.closeSurveyMessage}
        confirmMessageSecondary={`${s.surveys.this_action_will_close} ${labelsStore
          .getLabelByLanguageAndFor('pulse')
          .toLowerCase()} ${s.surveys.and_delete_all}`}
        onClose={() => vm.toggleCloseSurveyDialog()}
        open={vm.closeSurveyDialogOpen}
        onConfirm={() => vm.closeSurvey()}
        title={`${s.surveys.close} ${labelsStore.getLabelByLanguageAndFor('pulse')}`}
        icon={<WarningRoundedIcon className='warning-icon' />}
      />
    )
  }

  const renderBackButtonText = () => {
    if (vm.isSurveyTemplateEdit) return `${s.surveys.back_to} Survey Templates`
    return `${s.surveys.back_to} ${labelsStore.getLabelByLanguageAndFor('pulses')}`
  }

  const renderBackButton = () => {
    return (
      <Button
        className='back-surveys-btn'
        variant='text'
        aria-label='back'
        onClick={() => {
          vm.goBack()
        }}
      >
        <BackIcon />
        {renderBackButtonText()}
      </Button>
    )
  }

  const renderSurveyName = () => {
    return (
      <TextField
        value={vm.name}
        onChange={(e) => vm.setName(e.target.value)}
        style={{ width: '100%' }}
        placeholder={`${s.surveys.untitled} ${labelsStore.getLabelByLanguageAndFor('pulse')}`}
        className={vm.isNameValid ? 'toolbar-input' : 'toolbar-input error'}
        disabled={vm.isSaving || vm.isReadOnly}
        error={!vm.isNameValid}
        variant='standard'
      />
    )
  }

  const renderTopToolbarEndButtons = () => {
    if (vm.isNewSurvey) return
    if (routeParams.surveyTemplateId) {
      return <TopToolbarEndButtons surveyTemplateId={routeParams.surveyTemplateId} />
    }
    return <TopToolbarEndButtons />
  }

  return (
    <>
      <AppBar position='static' color='default' id='MainForm'>
        {renderConfirmDelete()}
        {renderConfirmSave()}
        {renderConfirmClose()}
        <Toolbar className='tabs-toolbar'>
          <div slot='start'>{renderBackButton()}</div>
          {renderSurveyName()}
          {renderTopToolbarEndButtons()}
        </Toolbar>
        {vm.isSaving && <LinearProgress variant='determinate' value={vm.saveProgress} />}
      </AppBar>
    </>
  )
}

export default inject(
  'surveysStore',
  'appStore',
  'localizationStore',
  'labelsStore'
)(observer(TopToolbar))
