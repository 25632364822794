import Parse from 'parse'
import { IMediaItemDTO } from '../dtos/IMediaItemDTO'

export class MediaItemsService {
  public async saveMediaItem(mediaItem: IMediaItemDTO): Promise<any> {
    const result = await Parse.Cloud.run('saveMediaItem', { mediaItem })
    return result
  }

  public async updateMediaItem(mediaItem: IMediaItemDTO): Promise<any> {
    const result = await Parse.Cloud.run('saveMediaItem', { mediaItem })
    return result
  }

  public async deleteMediaItem(id: string, orgId: string): Promise<any> {
    await Parse.Cloud.run('deleteMediaItem', { id, orgId })
  }

  public async getCloudinarySignature(paramsToSign): Promise<any> {
    const signature = await Parse.Cloud.run('getCloudinarySignature', { paramsToSign })
    return signature
  }

  public async uploadMediaItem(mediaItem: IMediaItemDTO) {
    const result = await Parse.Cloud.run('saveMediaItem', { mediaItem })
    return result
  }

  public async getCloudinarySignatureForVideoThumbnail(public_id, desiredThumbnailExtension) {
    const thumbnailSignature = await Parse.Cloud.run('getCloudinarySignatureForVideoThumbnail', {
      public_id,
      desiredThumbnailExtension,
    })
    return thumbnailSignature
  }

  public async buildThumbnailURL(public_id, desiredThumbnailExtension, url) {
    const thumbnailSignature = await this.getCloudinarySignatureForVideoThumbnail(
      public_id,
      desiredThumbnailExtension
    )
    const thumbnailURL =
      url.slice(0, url.indexOf('/s--') + 4) +
      thumbnailSignature +
      url.slice(url.lastIndexOf('--/'), url.lastIndexOf('.')) +
      desiredThumbnailExtension
    return thumbnailURL
  }
}
