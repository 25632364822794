import { observable, action, computed } from 'mobx'
import { serializable, serialize, deserialize, list, object, date, raw, primitive } from 'serializr'
import { IAggregate } from '../../shared/data/IAggregate'
import { IAdditionalAudienceMemberDTO } from '../dtos/IAdditionalAudienceMemberDTO'
import { IGroupsDTO } from '../dtos/IGroupsDTO'
import { IOrganizationUserDTO } from '../dtos/IOrganizationUserDTO'
import { IRolesDTO } from '../dtos/IRolesDTO'
import { IUserDTO } from '../dtos/IUserDTO'
import { AdditionalAudienceMember } from './AdditionalAudienceMember'
import { FKGroup } from './FKGroup'
import { FKRole } from './FKRole'
import { FKUser } from './FKUser'
import { User } from './User'
import { FKPrivilegeSet } from './FKPrivilegeSet'

export class OrganizationUser implements IOrganizationUserDTO, IAggregate {
  public static create(orgId: string) {
    const orgUser = new OrganizationUser()
    orgUser.organizationId = orgId
    return orgUser
  }

  @serializable @observable public objectId: string
  @serializable @observable public organizationId: string
  @serializable @observable public primaryRoleId?: string
  @serializable @observable public primaryGroupId?: string
  @serializable(object(User)) @observable public User: User = new User()
  @serializable(object(FKUser)) @observable public fk_User: FKUser = new FKUser()
  @serializable(list(object(FKRole))) @observable public fk_roles: FKRole[]
  @serializable(list(object(FKGroup))) @observable public fk_groups: FKGroup[]
  @serializable(list(object(FKPrivilegeSet))) @observable public fk_privilegeSets: FKPrivilegeSet[]
  @serializable(list(primitive())) @observable public resolvedPrivileges?: string[]
  @serializable @observable public disabled?: boolean
  @serializable @observable public isDisabled?: boolean
  @serializable @observable public isArchived?: boolean
  @serializable @observable public archivedDate?: string
  @serializable @observable public userId: string
  @serializable @observable public bypassOIDCAuth?: boolean = false
  @serializable @observable public title?: string = ''
  @serializable(list(primitive())) @observable public privilegeSets?: string[] = []
  @serializable @observable public isOrgAdmin: boolean
  @serializable @observable public employeeId?: string
  @serializable @observable public jobNumber?: string
  @serializable @observable public roleStartDate?: string
  @serializable(list(primitive())) @observable public superiors?: string[] = []
  @serializable(list(primitive())) @observable public subordinates?: string[] = []
  @serializable(list(primitive())) @observable public allSubordinates?: string[] = [] // needed?
  @serializable(list(object(AdditionalAudienceMember)))
  @observable
  public additionalAudienceMembers?: IAdditionalAudienceMemberDTO[] = []
  @serializable(raw()) @observable roles: IRolesDTO = {}
  @serializable(raw()) @observable groups: IGroupsDTO = {}
  @serializable @observable public surveyStreak?: number
  @serializable @observable public welcomeEmailStatus?: string
  @serializable @observable public resetEmailStatus?: string
  @serializable @observable public hasConsented?: boolean
  @serializable @observable public isHiddenInOrg?: boolean
  @serializable(date()) @observable public createdAt: Date = undefined
  @serializable(date()) @observable public updatedAt: Date = undefined

  isDeleted: boolean
  isOnServer: boolean
  markIsOnServer(): void {}
  markIsNotOnServer(): void {}

  @computed
  public get rolesAsArray(): string[] {
    return Object.keys(this.roles)
  }

  @computed
  public get groupsAsArray(): string[] {
    return Object.keys(this.groups || {})
  }

  public serialize(): IOrganizationUserDTO {
    return serialize(this)
  }

  public clone(): OrganizationUser {
    return deserialize(OrganizationUser, this.serialize())
  }
}
