import { RootStore } from '../../../stores/RootStore'
import { observable, computed, action } from 'mobx'
import { PagedDataVM } from '../../../shared/view-models/PagedDataVM'
import { GoalsCatalogWidgetRowVM } from './GoalsCatalogWidgetRowVM'
import { GoalsService } from '../../service/GoalsService'
import env from '../../../../env'

export class GoalsCatalogWidgetVM {
  private rootStore: RootStore
  public svc: GoalsService

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.svc = new GoalsService()
    // setTimeout(() => this.getSources(), 500)
  }

  @observable public keywordFilter: string = ''
  @observable public deletingOrgId: string = ''
  @observable public dataSources: any = []
  @observable public sortType: string = 'Date'

  @action
  public async getSources() {
    return
    if (env.var.REACT_APP_ENVIRONMENT === 'STAGING') return
    if (env.var.REACT_APP_ENVIRONMENT === 'PRODUCTION') return
    this.dataSources = await this.svc.getSources(this.rootStore.appStore.currentOrgId)
  }

  @action setSortType(type) {
    this.sortType = type
  }

  @computed public get rows(): GoalsCatalogWidgetRowVM[] {
    return this.rootStore.goalsCatalogStore.currentOrgCatalogGoals.map(
      (e) => new GoalsCatalogWidgetRowVM(this.rootStore, this, e)
    )
  }

  @computed
  public get pagedData(): PagedDataVM {
    return new PagedDataVM(
      this.rootStore,
      this.rows,
      ['name', 'category'],
      '',
      'ManageGoalsWidget',
      this.keywordFilter
    )
  }

  @action setKeywordFilter(val) {
    this.keywordFilter = val
    this.pagedData.setFilter(this.keywordFilter)
  }

  @action createNewCatalogGoal() {
    this.rootStore.appStore.router.push('/goals/from-catalog/edit/new')
  }
}
export default GoalsCatalogWidgetVM
