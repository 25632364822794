import { computed, reaction, observable, action } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Category } from '../aggregate/Category'
import { ListWidgetRowVM } from './ListWidgetRowVM'
import { ColDef, RowClickedEvent, GridOptions } from 'ag-grid-community'
import { CategoriesStore } from '../../categories/CategoriesStore'
import { AGGridVM } from '../../shared/ag-grid/AGGridVM'
import { nameOf } from '../../shared/nameOf'
import { CategoryTreeVM } from './CategoryTreeVM'

export class ListWidgetVM extends AGGridVM {
  private rootStore: RootStore
  public category: Category
  public categoryTreeVM: CategoryTreeVM
  private filterTO: NodeJS.Timer

  constructor(rootStore: RootStore) {
    super()
    this.rootStore = rootStore
    this.tabIndex = 0
    this.categoryTreeVM = new CategoryTreeVM(this.rootStore, this)
  }

  @observable public tabIndex: number = 0
  @observable public typedFilter: string = ''

  @computed
  public get isPaginationBackDisabled(): boolean {
    if (!this.paginationFrom || this.paginationFrom === 1) return true
    return false
  }

  @computed
  public get isPaginationForwardDisabled(): boolean {
    if (!this.paginationTo || this.paginationTo === this.rows.length) return true
    return false
  }

  @action
  public getRowNodeId(row: ListWidgetRowVM): string {
    return row.objectId
  }

  @computed
  public get columnDefs(): ColDef[] {
    const s = this.rootStore.localizationStore.lzStrings
    return [
      { headerName: s.categoriesListWidget.name, field: nameOf<ListWidgetRowVM, string>((e) => e.name), sort: 'asc' },
      { headerName: s.categoriesListWidget.parent, field: nameOf<ListWidgetRowVM, string>((e) => e.parentPathName) },
      { headerName: s.categoriesListWidget.available_for_skills, field: nameOf<ListWidgetRowVM, string>((e) => e.availableForSkills) },
      { headerName: s.categoriesListWidget.available_for_surveys, field: nameOf<ListWidgetRowVM, string>((e) => e.availableForSurveys) },
      { headerName: s.categoriesListWidget.available_for_tasks, field: nameOf<ListWidgetRowVM, string>((e) => e.availableForTasks) },
    ]
  }

  @computed
  private get categoriesStore(): CategoriesStore {
    return this.rootStore.categoriesStore
  }

  @action public editCategory(e) {
    if (e.data && e.data.objectId) {
      // const rowVM: ListWidgetRowVM = e.data
      // if (!rowVM.isSelected) rowVM.toggleSelected()
      if (this.tabIndex === 1) {
        this.categoriesStore.showEditButton()
      }
      this.categoriesStore.widgetForm.setField('objectId', e.data.objectId)
      this.categoriesStore.widgetForm.initEditForm(
        this.categoriesStore.categories,
        this.categoriesStore.widgetForm.objectId
      )
      if (this.tabIndex === 0) this.categoriesStore.widgetForm.openDrawer()
    }
  }

  @computed
  public get rows(): ListWidgetRowVM[] {
    const result = this.categoriesStore.categories.map(
      (category) => new ListWidgetRowVM(this.rootStore, category, this)
    )
    return result
  }

  public get shouldRender(): boolean {
    if (!this.rootStore.categoriesStore.loaded) return false
    return true
  }

  public getGridOptions(): GridOptions {
    return {
      ...this.baseGridOptions,
      rowData: this.rows,
      onRowClicked: (e) => this.rowClicked(e),
      getRowNodeId: (e) => this.getRowNodeId(e),
      columnDefs: this.ensureTooltipFields(this.columnDefs),
    }
  }

  public rowClicked(e: RowClickedEvent) {
    const row: ListWidgetRowVM = e.data
    if (!row) return
    row.editCategory()
  }

  @action
  public setTabIndex(index: number) {
    this.tabIndex = index
    this.gridApi.paginationGoToFirstPage()
    this.updatePagination()
  }
}
