import { RootStore } from '../../stores/RootStore'
import { DataStore } from '../../shared/data/DataStore'
import { OrganizationUser } from '../../organization-users/aggregate/OrganizationUser'
import { IOrganizationUserDTO } from '../../organization-users/dtos/IOrganizationUserDTO'
import { IOrganizationUserAudienceSearchRequest } from '../../organization-users/interfaces/IOrganizationUserAudienceSearchRequest'
import { GroupedRowVM } from './GroupedRowVM'
import { OrganizationUsersService } from '../../organization-users/service/OrganizationUsersService'
import { EventsService } from '../../events/services/EventsService'
import { IEventsParticipantsSearchRequest } from '../../events/intefaces/IEventsParticipantsSearchRequest'
import { EventParticipantGroupType } from '../types/EventParticipantGroupType'

export class UsersListDataStore extends DataStore<OrganizationUser, IOrganizationUserDTO> {
  private userId: string
  private forGroupedRow: GroupedRowVM
  private showHidden: boolean = false

  constructor(rootStore: RootStore, userId: string, forGroupedRow?: GroupedRowVM) {
    super(rootStore, OrganizationUser, 'organizationUsers', [
      'objectId',
      'organizationId',
      'userId',
      'primaryRoleId',
      'fk_User',
      'fk_roles',
      'isHiddenInOrg',
      'isArchived',
    ])
    this.userId = userId
    this.paged = true
    this.recordsPerPage = 100
    this.sortColumnName = 'fk_User.firstName'
    this.forGroupedRow = forGroupedRow
  }

  protected getListRecordsApiCall() {
    if (this.forGroupedRow && this.forGroupedRow.type === 'from-event')
      return this.getEventParticipantsListRecordsApiCall()
    return this.getAudienceUsersListRecordsApiCall()
  }

  private getEventParticipantsListRecordsApiCall() {
    return async () => {
      const req: IEventsParticipantsSearchRequest = {
        organizationId: this.rootStore.appStore.currentOrgId,
        eventId: this.forGroupedRow.eventId,
        terms: this.filter,
        skip: this.recordsPerPage * (this.pageNumber - 1),
        limit: this.recordsPerPage,
        sortColumnName: this.sortColumnName,
        listColumns: this.listColumns,
      }
      if (
        (this.forGroupedRow.objectId as EventParticipantGroupType) === 'goingYesAndMaybeUserIds'
      ) {
        req.goingYesUserIds = true
        req.goingMaybeUserIds = true
      }
      if ((this.forGroupedRow.objectId as EventParticipantGroupType) === 'goingYesUserIds') {
        req.goingYesUserIds = true
      }
      if ((this.forGroupedRow.objectId as EventParticipantGroupType) === 'goingNoUserIds') {
        req.goingNoUserIds = true
      }
      const svc = new EventsService()
      const result = await svc.searchEventsParticipants(req)
      this.totalRecords = result.count
      return result.users
    }
  }

  public setShowHidden(val: boolean): void {
    this.showHidden = val
  }

  public reloadUsers(): void {
    this.getAudienceUsersListRecordsApiCall()
  }

  private getAudienceUsersListRecordsApiCall() {
    return async () => {
      const req: IOrganizationUserAudienceSearchRequest = {
        userId: this.userId,
        orgId: this.rootStore.appStore.currentOrgId,
        terms: this.filter,
        skip: this.recordsPerPage * (this.pageNumber - 1),
        limit: this.recordsPerPage,
        sortColumnName: this.sortColumnName,
        listColumns: this.listColumns,
        isArchived: false,
        showHidden: this.showHidden,
      }
      if (this.forGroupedRow) {
        req[this.forGroupedRow.type + 'Id'] = this.forGroupedRow.objectId
      }
      const svc = new OrganizationUsersService(this.rootStore)
      const result = await svc.searchAudienceUsers(req)
      this.totalRecords = result.count
      return result.users
    }
  }

  public get organizationUsers() {
    return this.records
  }
}
