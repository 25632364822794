import { Dialog, DialogContent, DialogTitle, Grid, IconButton } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import React from 'react'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import { TrainingItemsStore } from '../../../../training-items/store/TrainingItemsStore'
import TrainingItemEditScreen from '../../../../training-items/views/edit/TrainingItemEditScreen'
import { TrainingPlansStore } from '../../../../training-plans/store/TrainingPlansStore'
import CloseIcon from '@material-ui/icons/Close'
interface Props {
  trainingPlansStore?: TrainingPlansStore
  trainingItemsStore?: TrainingItemsStore
  localizationStore?: LocalizationStore
}

const TrainingItemCreationModal: React.FC<Props> = ({
  trainingPlansStore,
  trainingItemsStore,
  localizationStore,
}) => {
  const { trainingItemEditVM: vm } = trainingItemsStore
  const { trainingPlanEditVM: trainingPlanVM } = trainingPlansStore
  const { training_items: strings } = localizationStore.lzStrings
  if (!vm) return null

  const renderTitle = () => {
    return (
      <Grid className='dialog-title' container justifyContent='space-between' alignItems='center'>
        <Grid item>
          <DialogTitle>New Training Item</DialogTitle>
        </Grid>
        <Grid item>
          <IconButton onClick={() => trainingPlanVM.toggleItemConfirmDialogOpen()}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    )
  }
  return (
    <Dialog
      onClose={() => trainingPlanVM.toggleItemConfirmDialogOpen()}
      open={trainingPlanVM.itemModalOpen}
      id='trainingItemModal'
    >
      {renderTitle()}
      <TrainingItemEditScreen />
    </Dialog>
  )
}

export default inject(
  'trainingPlansStore',
  'trainingItemsStore',
  'localizationStore'
)(observer(TrainingItemCreationModal))
