import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { inject, observer } from 'mobx-react'
// import './AddQuestionMenu.scss'
import { SurveysStore } from 'src/app/surveys/store/SurveysStore'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import Icon from './Icon'
import { QuizVM } from '../view-models/QuizVM'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: any) => (
  <Menu
    id={props.id}
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem)

interface Props {
  quiz: QuizVM
  localizationStore?: LocalizationStore
}

const AddQuestionMenu: React.FC<Props> = ({ quiz, localizationStore }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  if (!quiz) return null

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div id='AddQuestionMenu'>
      <Button
        disabled={quiz.isDisabled}
        aria-controls='customized-menu'
        aria-haspopup='true'
        variant='contained'
        className='add-question-button'
        onClick={handleClick}
      >
        Add Question
        <Icon icon={'fa fa-caret-down'} />
      </Button>
      <StyledMenu
        id='customized-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem
          className='menu-item'
          onClick={() => {
            quiz.addQuestionByType('emoji')
            handleClose()
          }}
        >
          <ListItemIcon className='icon brown'>
            <Icon icon={'far fa-smile'} />
          </ListItemIcon>
          <ListItemText primary='Emoji' />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            quiz.addQuestionByType('check')
            handleClose()
          }}
        >
          <ListItemIcon className='icon blue'>
            <Icon icon={'far fa-check-square'} />
          </ListItemIcon>
          <ListItemText primary='Multi-select' />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            quiz.addQuestionByType('radio')
            handleClose()
          }}
        >
          <ListItemIcon className='icon green'>
            <Icon icon={'far fa-dot-circle'} />
          </ListItemIcon>
          <ListItemText primary='Multiple Choice' />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            quiz.addQuestionByType('rating')
            handleClose()
          }}
        >
          <ListItemIcon className='icon orange'>
            <Icon icon={'fas fa-list-ol'} />
          </ListItemIcon>
          <ListItemText primary='Rating' />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            quiz.addQuestionByType('starRating')
            handleClose()
          }}
        >

        <ListItemIcon className='icon yellow'>
            <Icon icon={'fas fa-star'} />
          </ListItemIcon>
          <ListItemText primary='Star Rating' />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            quiz.addQuestionByType('text')
            handleClose()
          }}
        >
          <ListItemIcon className='icon red'>
            <Icon icon={'fas fa-font'} />
          </ListItemIcon>
          <ListItemText primary='Short Answer' />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            quiz.addQuestionByType('yesNo')
            handleClose()
          }}
        >
          <ListItemIcon className='icon purple'>
            <Icon icon={'far fa-thumbs-up'} />
          </ListItemIcon>
          <ListItemText primary='Yes or No' />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            quiz.addQuestionByType('dropdown')
            handleClose()
          }}
        >
          <ListItemIcon className='icon green'>
            <Icon icon={'fas fa-caret-square-down'} />
          </ListItemIcon>
          <ListItemText primary='Dropdown' />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  )
}

export default inject('localizationStore')(observer(AddQuestionMenu))
