import React from 'react'
import rootStore from '../../../stores/RootStore'
import { observer, Observer } from 'mobx-react'
import PulseQuestionEditHeader from './PulseQuestionEditHeader'
import './PulseQuestionEditScreen.scss'
import PulseQuestionMain from './PulseQuestionMain'
import PulseQuestionDetails from './PulseQuestionDetails'

@observer
export default class PulseQuestionEditScreen extends React.Component<any, any> {
  constructor(props) {
    super(props)

    if (props.params.id && props.params.childCategoryId) {
      rootStore.pulseQuestionsStore.loadPulseQuestionEditVM(props.params.id, props.params.childCategoryId)
    }
  }

  routerWillLeave(nextLocation) {
    // if (!rootStore.trainingPlansStore.trainingPlanEditVM) return
    // if (rootStore.trainingPlansStore.trainingPlanEditVM.isDirty) {
    //   return 'You have unsaved information, are you sure you want to leave this page?'
    // }
  }

  componentDidMount() {
    this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave.bind(this))
  }

  componentWillUnmount() {
    rootStore.pulseQuestionsStore.loadPulseQuestionEditVM('empty')
  }

  render() {
    return (
      <div id='PulseQuestionEditScreen'>
        <PulseQuestionEditHeader />
        <div className={'pulse-question-content'}>
          <PulseQuestionMain />
          <PulseQuestionDetails />
        </div>
      </div>
    )
  }
}
