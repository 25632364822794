import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { AppBar, Button, CssBaseline, Link, Toolbar, Typography } from '@material-ui/core'
import clsx from 'clsx'
import isIE11 from '../../../../../utils/isIE11'
import { SurveyTemplatesManageVM } from '../../../view-models/SurveyTemplatesManageVM'

interface Props {
  localizationStore?: LocalizationStore
  vm: SurveyTemplatesManageVM
}

const TopToolbarPermissions: React.FC<Props> = ({ localizationStore, vm }) => {
  const lz = localizationStore.lzStrings.surveys

  const renderSaveBtn = () => {
    return (
      <Button disabled={vm.permissionActionsDisabled} variant='contained' onClick={() => vm.saveSubscriptions()}>
        {`Save`}
      </Button>
    )
  }

  const renderResetBtn = () => {
    return (
      <Button
        disabled={vm.permissionActionsDisabled}
        variant='outlined'
        onClick={() => vm.resetSubscriptionsTable()}
      >
        {`Reset`}
      </Button>
    )
  }

  return (
    <div id='DashboardToolbar'>
      <CssBaseline />
      <AppBar
        className={clsx('dashboard-appbar strong', {
          'no-transition': isIE11,
        })}
        elevation={0}
      >
        <Toolbar className='dashboard-toolbar'>
          <Link onClick={() => vm.navigateToSurveyTemplatesScreen()} underline='always'>
            <Typography className='dashboard-title spaced'>{'Survey Templates'}</Typography>
          </Link>
          <Typography className='dashboard-title spaced'>{' > '}</Typography>
          <Typography className='dashboard-title spaced'>{'Subscriptions'}</Typography>
          <div className='dashboard-toolbar-right'>
            {renderResetBtn()}
            {renderSaveBtn()}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default inject('localizationStore')(observer(TopToolbarPermissions))
