import { observable, computed, action } from 'mobx'
import { Role } from '../../../roles/aggregate/Role'
import { RootStore } from '../../../stores/RootStore'

export class ParticipantForm {

  private rootStore: RootStore

  constructor(rootStore, part) {
    this.rootStore = rootStore
    if (!part) return
    this.roleId = part.roleId ? part.roleId : ''
    this.quantity = part.quantity
  }

  @observable roleId: string = ''
  @observable quantity: number = 1
  @observable saveTried: boolean = false

  @action
  public setSelectedRole(roleId) {
    this.roleId = roleId
  }

  @computed
  public get isValid() {
    if (!this.saveTried) return true
    if (!this.quantityValid) return false
    return true
  }

  @computed
  public get quantityValid() {
    if (!this.saveTried) return true
    if (!this.quantity || this.quantity === 0) return false
    return true
  }

  @computed 
  public get roleOptions(): Array<Role> {
    const options = []
    this.rootStore.rolesStore.roles.forEach(e => {
      options.push({
        label: e.name,
        value: e.objectId
      })
    })
    return options
  }

  public toDTO() {
    return {
      roleId: this.roleId,
      quantity: this.quantity
    }
  }

}
