import axios from 'axios'
import * as Sentry from '@sentry/browser'
import env from '../../../env'
import { RootStore } from '../../stores/RootStore'
import { ISortParams } from '../interfaces/ISortParams'
import { IPaginationParams } from '../interfaces/IPaginationParams'
import { ICMSPostTemplateRequest } from '../interfaces/ICMSPostTemplateRequest'
import { PostTemplate } from '../aggregate/PostTemplate'
import { CMSAuthService } from '../../cms-auth/services/CMSAuthService'

export class CMSPostTemplatesFindService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  private DEFAULT_SORT: ISortParams = {
    field: 'title',
    dir: 'asc',
  }

  public DEFAULT_PAGINATION: IPaginationParams = {
    page: 1,
    pageSize: 200,
    start: 0,
    limit: 200,
  }

  private getCMSApiUrl(): string {
    return env.var.REACT_APP_CMS_API_URL
  }

  public async getItems(req: ICMSPostTemplateRequest): Promise<PostTemplate[]> {
    try {
      let url =
        this.getCMSApiUrl() +
        `/api/post-templates?pagination[pageSize]=${this.DEFAULT_PAGINATION.pageSize}&pagination[page]=${this.DEFAULT_PAGINATION.page}&sort=createdAt:DESC&populate=*`
      if (req.search) {
        url =
          this.getCMSApiUrl() +
          `/api/post-templates?pagination[pageSize]=${this.DEFAULT_PAGINATION.pageSize}&pagination[page]=${this.DEFAULT_PAGINATION.page}&sort=createdAt:DESC&filters[title][$containsi]=${req.search}&populate=*`
      }
      const jwt = await CMSAuthService.getJWT()
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt}`,
          Accept: '*/*',
        },
      }
      const response = await axios.get(url, config)
      if (!response.data) return []
      return response.data.data
    } catch (e) {
      console.error(e)
      Sentry.captureException({
        message: 'Unable to get cms post templates',
        exception: e,
        userId: this.rootStore.appStore.currentUserId,
        orgId: this.rootStore.appStore.currentOrgId,
      })
    }
  }
}
