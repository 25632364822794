import env from './env'

export class ParseStorageController {
  public static async: number = 0

  public static get storage() {
    if (env.var.REACT_APP_USE_SESSION_STORAGE === '1') return sessionStorage
    return localStorage
  }

  public static getItem(path: string) {
    return this.storage.getItem(path)
  }

  public static setItem(path: string, value: string) {
    try {
      this.storage.setItem(path, value)
    } catch (e) {
      // Quota exceeded, possibly due to Safari Private Browsing mode
      console.log(e.message)
    }
  }

  public static removeItem(path: string) {
    this.storage.removeItem(path)
  }

  public static getAllKeys() {
    const keys = []
    for (let i = 0; i < this.storage.length; i += 1) {
      keys.push(this.storage.key(i))
    }
    return keys
  }

  public static clear() {
    this.storage.clear()
  }
}
