import React, { FC } from 'react'
import { inject, observer } from 'mobx-react'
import { Grid } from '@material-ui/core'
import rootStore from '../../../stores/RootStore'
import { LabelsStore } from '../../../labels/store/LabelsStore'
import { EmailTemplatePreviewVM } from '../../view-models/EmailTemplatePreviewVM'
import EmailTemplatePreview from './../previewer/EmailTemplatePreview'
import EmailTemplateEditor from './../editor/EmailTemplateEditor'
import './EmailTemplatesEditorTestDataTab.scss'

interface Props {
  vm: EmailTemplatePreviewVM
  labelsStore?: LabelsStore
}

const EmailTemplatesEditorTestDataTab: FC<Props> = ({ vm }) => {
  if (!vm) return null

  const renderEmailTemplateEditor = () => {
    if (vm.isLoading) return
    if (!vm.emailTemplate) return
    return (
      <Grid item md={7} sm={7} className='email-template-edit-dialog-editor'>
        <EmailTemplateEditor vm={vm} localizationStore={rootStore.localizationStore} />
      </Grid>
    )
  }

  const renderEmailTemplatePreview = () => {
    if (vm.isLoading) return
    if (!vm.emailTemplate) return
    return (
      <Grid item md={5} sm={5} className='email-template-edit-dialog-preview'>
        <EmailTemplatePreview vm={vm} />
      </Grid>
    )
  }

  return (
    <>
      {renderEmailTemplateEditor()}
      {renderEmailTemplatePreview()}
    </>
  )
}

export default inject()(observer(EmailTemplatesEditorTestDataTab))
