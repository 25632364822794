import React from 'react'
import { Route } from 'react-router'
import Layout from '../../layout/Layout'
import WorksheetEditScreen from '../views/edit/WorksheetEditScreen'
import { authenticate } from '../../auth/routes/AuthenticatedRoute'

export default function getWorksheetsRoutes() {
  return (
    <Route key='ws0' component={authenticate(Layout)} path='/worksheets'>
      <Route path='edit/:id' component={WorksheetEditScreen} />
    </Route>
  )
}
