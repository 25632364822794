import React from 'react'
import { observer, inject } from 'mobx-react'
import {
  Button,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core'
import { PulseQuestionType } from '../../types/PulseQuestionType'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ChevronDownIcon from '@material-ui/icons/ExpandMore'
import LocalizationStore from '../../../localization/LocalizationStore'
import './PulseOptionFollowUp.scss'
import PulseQuestionOptions from '../edit/PulseQuestionOptions'
import MuiDialogMessage from '../../../shared/MuiDialogMessage'
import { PulseQuestionOptionVM } from '../../view-models/PulseQuestionOptionVM'
import { FileVM } from '../../../upload/view-models/FileVM'
import { Media } from '../../../questions/aggregates/Media'
import PulseQuestionMedia from './PulseQuestionMedia'

interface Props {
  optionVM: PulseQuestionOptionVM
  localizationStore?: LocalizationStore
}

const PulseOptionFollowUp: React.FC<Props> = ({ optionVM, localizationStore }) => {
  if (!optionVM) return null
  const vm = optionVM.followUpQuestionVM
  if (!vm) return null

  const { lzStrings: lz } = localizationStore

  const renderQuestionTypes = () => {
    return vm.questionTypes.map((type, index) => {
      let icon = ''
      let color = ''
      let label = ''
      if (type === 'emoji') {
        icon = 'far fa-smile'
        color = 'brown'
        label = 'Emoji'
      }
      if (type === 'check') {
        icon = 'far fa-check-square'
        color = 'blue'
        label = 'Multi-select'
      }
      if (type === 'radio') {
        icon = 'far fa-dot-circle'
        color = 'green'
        label = 'Multiple Choice'
      }
      if (type === 'rating') {
        icon = 'fas fa-list-ol'
        color = 'orange'
        label = 'Rating'
      }
      if (type === 'starRating') {
        icon = 'fas fa-star'
        color = 'yellow'
        label = 'Star Rating'
      }
      if (type === 'text') {
        icon = 'fas fa-font'
        color = 'red'
        label = 'Short Answer'
      }
      if (type === 'yesNo') {
        icon = 'far fa-thumbs-up'
        color = 'purple'
        label = 'Yes or No'
      }
      if (type === 'infoText') {
        icon = 'fas fa-info-circle'
        color = 'blue'
        label = 'Info Text'
      }
      if (type === 'number') {
        icon = 'fas fa-calculator'
        color = 'orange'
        label = 'Number'
      }
      if (type === 'order') {
        icon = 'fas fa-sort-amount-up'
        color = 'pink'
        label = 'Order'
      }
      if (type === 'dropdown') {
        icon = 'fas fa-caret-square-down'
        color = 'green'
        label = 'Dropdown'
      }

      return (
        <MenuItem key={index} value={type} className={`type-menu-item ${color}`}
          onClick={e => {
            vm.updateQuestionByType(type as PulseQuestionType)
          }
          }>
          {/* <Icon icon={icon} className='icon' /> */}
          {label}
        </MenuItem>
      )
    })
  }

  const renderQuestionType = () => {
    return (
      <div className='follow-up-question-type'>
        <div>
          <Typography className='follow-up-question-type-header'>Question Type</Typography>
          <Select
            className='type-select'
            disabled={!vm.isSystemAdmin}
            variant='outlined'
            value={vm.questionType}
            onChange={(e) => vm.changeQuestionType(e.target.value)}
          >
            {renderQuestionTypes()}
          </Select>
        </div>
      </div>
    )
  }

  const renderQuestionTitle = () => {
    return (
      <div className='follow-up-question-title'>
        <Typography className='folloow-up-question-title-header'>Title</Typography>
        <TextField
          autoFocus
          error={!vm.titleValid}
          id='outlined-name'
          value={vm.title}
          onChange={(e) => {
            vm.setTitle(e.target.value)
          }}
          margin='none'
          fullWidth
          variant='outlined'
        />
      </div>
    )
  }

  const renderExpandIcon = () => {
    let icon = <ChevronRightIcon className={'expand-icon'} />
    if (vm.isExpanded) icon = <ChevronDownIcon className={'expand-icon'} />
    return (
      <IconButton
        disableRipple
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          vm.toggleIsExpanded()
        }
        }
        className={'icon-container'}
      >
        {icon}
      </IconButton >
    )
  }

  const renderRequired = () => {
    return (
      <div className={'question-required'}>
        <Typography className='required-header'>Required</Typography>
        <Switch checked={vm.required} onChange={() => vm.toggleRequred()} />
      </div>
    )
  }

  const renderRatingDisplay = () => {
    if (!vm.isExpanded) return null
    if (vm.questionType !== 'rating') return null
    return (
      <div className={'rating-display'}>
        <Typography className={'rating-display-header'}>Rating Display</Typography>
        <RadioGroup value={vm.ratingDisplay} onChange={(e) => vm.setRatingDisplay(e.target.value)}>
          <FormControlLabel value='horizontal' control={<Radio />} label='Horizontal' />
          <FormControlLabel value='vertical' control={<Radio />} label='Vertical' />
        </RadioGroup>
      </div>
    )
  }

  const renderQuestionOptions = () => {
    if (!vm.isExpanded) return null
    if (vm.questionType === 'text') return null
    return (
      <div className='question-options'>
        <PulseQuestionOptions vm={vm} />
      </div>
    )
  }

  const renderDropdownPlaceholder = () => {
    if (!vm.isExpanded) return null
    if (vm.questionType !== 'dropdown') return null
    return (
      <div className='dropdown-label'>
        <InputLabel>{lz.questionsCatalogWidgetDrawer.dropdown_placeholder}</InputLabel>
        <TextField
          error={!vm.placeholderValid}
          id='outlined-placeholder'
          value={vm.placeholder}
          onChange={(e) => {
            vm.setPlaceholder(e.target.value)
          }}
          fullWidth
          margin='none'
          variant='outlined'
        />
      </div>
    )
  }

  const renderDropdownMultiSelectToggle = () => {
    if (!vm.isExpanded) return null
    if (vm.questionType !== 'dropdown') return null
    return (
      <div className={'dropdown-toggle'}>
        <InputLabel>{lz.questionsCatalogWidgetDrawer.dropdown_multiselect}</InputLabel>
        <Switch
          checked={vm.isDropdownMultiSelect}
          onChange={() => vm.toggleDropdownMultiSelect()}
        />
      </div>
    )
  }

  const renderDropdownInfo = () => {
    if (!vm.isExpanded) return null
    if (vm.questionType !== 'dropdown') return null
    return (
      <div className={'dropdown-info'}>
        {renderDropdownPlaceholder()}
        {renderDropdownMultiSelectToggle()}
      </div>
    )
  }

  const renderFollowUp = () => {
    return (
      <div>
        <div className={'follow-up-container'}>
          <div className={'follow-up-header'}>
            {renderExpandIcon()}
            {renderQuestionType()}
            {renderQuestionTitle()}
            {renderRequired()}
          </div>
          {renderRatingDisplay()}
          <div className={'media-row'}>
            <PulseQuestionMedia vm={vm} />
          </div>
          {renderQuestionOptions()}
          {renderDropdownInfo()}
        </div>
      </div>

    )
  }

  return (
    <div id={'PulseOptionFollowUpEdit'}>
      <MuiDialogMessage
        open={vm.cantDeleteLastOption}
        onClose={() => vm.toggleDeleteLastOption()}
        message='Cannot delete the last option of a pulse question.'
      />
      {renderFollowUp()}
    </div>
  )
}

export default inject('localizationStore')(observer(PulseOptionFollowUp))
