import React from 'react'
import './CalendarEventDrawerLocationSearchBar.scss'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import { Grid } from '@material-ui/core'

interface Props {
  suggestion: any
}

const LocationSearchBarSuggestion: React.FC<Props> = ({ suggestion }) => {
  const { formattedSuggestion: string } = suggestion
  return (
    <>
      <Grid container direction='row' style={{ margin: '5px 0', cursor: 'pointer' }}>
        <Grid
          item
          xs={1}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <LocationOnIcon />
        </Grid>
        <Grid item>
          <Grid container direction='column'>
            <Grid item>
              <h6 style={{ marginBottom: '0px' }}>{string.mainText}</h6>
            </Grid>
            <Grid item>{string.secondaryText}</Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default LocationSearchBarSuggestion
