import React from 'react'
import { inject, observer } from 'mobx-react'
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
  Theme,
  DialogActions,
  TextField,
  CircularProgress,
  Typography,
} from '@material-ui/core'
import { TrainingItemsStore } from '../../store/TrainingItemsStore'
import { TrainingPlansStore } from '../../../training-plans/store/TrainingPlansStore'
import LocalizationStore from '../../../localization/LocalizationStore'
import EditDetailsDialogHeader from './EditDetailsDialogHeader'
import './EditExerciseDetailsDialog.scss'

interface Props {
  trainingItemsStore?: TrainingItemsStore
  trainingPlansStore?: TrainingPlansStore
  localizationStore?: LocalizationStore
}

const useStyles = makeStyles((theme: Theme) => ({
  backDrop: {
    backdropFilter: 'blur(1px)',
    backgroundColor: 'rgba(0,0,30,0.9)',
  },
  input: {
    textAlign: 'center',
    '&::placeholder': {
      textAlign: 'center',
    },
  },
}))

const EditExerciseDetailsDialog: React.FC<Props> = ({
  trainingItemsStore,
  trainingPlansStore,
  localizationStore,
}) => {
  const classes = useStyles()

  const dialogVM = trainingItemsStore.exerciseDetailsDialogVM
  if (!dialogVM) return null

  const editVM = dialogVM.currentExercise
  if (!editVM) return null

  const { trainingPlanEditVM } = trainingPlansStore
  const { training_items: lz } = localizationStore.lzStrings

  const handleClose = () => {
    dialogVM.applyTried = false
    dialogVM.closeDialog()
    dialogVM.reset()
  }

  const handleApply = () => {
    const contentItemVM = dialogVM.contentItem
    if (!contentItemVM) return null

    dialogVM.applyTried = true
    if (!dialogVM.isValid) return
    dialogVM.isSaving = true
    if (!trainingPlanEditVM) return

    const foundItem = trainingPlanEditVM.trainingPlan.trainingPlanItems.find(
      (t) => t.trainingItemId === contentItemVM.trainingItem.objectId
    )
    if (!foundItem) return
    contentItemVM.trainingItem.contentItems.forEach((item) => item.loadSets())
    foundItem.contentItems = contentItemVM.trainingItem.contentItems
    dialogVM.closeDialog()
    dialogVM.isSaving = false
  }

  const renderDialogTitle = () => {
    return (
      <div className='intro-text intro-header'>
        <EditDetailsDialogHeader
          trainingItemsStore={trainingItemsStore}
          title={lz.set_exercise_details}
          onClick={() => dialogVM.closeDialog()}
        />
      </div>
    )
  }

  const renderExerciseInfo = () => {
    if (editVM.type !== 'Exercise') return
    return (
      <>
        <div id='EditExerciseDetailsDialogInfo'>
          <form className={'create-dialog-container'}>
            <Grid container spacing={3} alignContent='flex-start' justifyContent='center'>
              <Grid item xs={12}>
                <Typography variant='h6' className='input-label-header'>
                  {lz.exercise_name}
                </Typography>
                <TextField
                  required
                  disabled
                  InputProps={{ inputProps: { readOnly: true } }}
                  margin='none'
                  fullWidth
                  variant='outlined'
                  autoComplete='off'
                  value={editVM.title}
                  type={'text'}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6' className='input-label-header'>
                  {lz.set_performance_goal}
                </Typography>
                <Grid container spacing={2} alignContent='flex-start' justifyContent='center'>
                  <Grid item xs={2}>
                    <Typography variant='body1' className='input-label'>
                      {lz.sets}
                      <span className='required'>*</span>
                    </Typography>
                    <TextField
                      required
                      InputProps={{ inputProps: { min: 0 } }}
                      margin='none'
                      fullWidth
                      variant='outlined'
                      autoComplete='off'
                      value={editVM.sets ? editVM.sets.toString() : '0'}
                      placeholder={'#'}
                      onChange={(e) => editVM.setSets(e.target.value)}
                      error={!dialogVM.setsValid}
                      type={'number'}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant='body1' className='input-label'>
                      {lz.reps}
                    </Typography>
                    <TextField
                      required
                      InputProps={{ inputProps: { min: 0 } }}
                      margin='none'
                      fullWidth
                      variant='outlined'
                      autoComplete='off'
                      value={editVM.reps ? editVM.reps.toString() : '0'}
                      placeholder={'#'}
                      onChange={(e) => editVM.setReps(e.target.value)}
                      error={!dialogVM.repsValid}
                      type={'number'}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant='body1' className='input-label'>
                      {lz.weight}
                    </Typography>
                    <TextField
                      required
                      InputProps={{ inputProps: { min: 0 } }}
                      margin='none'
                      fullWidth
                      variant='outlined'
                      autoComplete='off'
                      value={editVM.weight ? editVM.weight.toString() : '0'}
                      placeholder={'#'}
                      onChange={(e) => editVM.setWeight(e.target.value)}
                      error={!dialogVM.weightValid}
                      type={'number'}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant='body1' className='input-label'>
                      {lz.time}
                    </Typography>
                    <TextField
                      required
                      InputProps={{ inputProps: { min: 0 } }}
                      margin='none'
                      fullWidth
                      variant='outlined'
                      autoComplete='off'
                      value={editVM.time ? editVM.time.toString() : '0'}
                      placeholder={'#'}
                      onChange={(e) => editVM.setTime(e.target.value)}
                      error={!dialogVM.timeValid}
                      type={'number'}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant='body1' className='input-label'>
                      {'%1RM'}
                    </Typography>
                    <TextField
                      required
                      InputProps={{ inputProps: { min: 0 } }}
                      margin='none'
                      fullWidth
                      variant='outlined'
                      autoComplete='off'
                      value={editVM.percent ? editVM.percent.toString() : '0'}
                      placeholder={'#'}
                      onChange={(e) => editVM.setPercent(e.target.value)}
                      error={!dialogVM.percentValid}
                      type={'number'}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </div>
      </>
    )
  }

  const renderBody = () => {
    return (
      <Grid item xs={12} className='body'>
        <Grid container spacing={2} direction='row'>
          <Grid item xs={12}>
            {renderExerciseInfo()}
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderActionButtons = () => {
    return (
      <Grid item xs={12} className='intro-buttons'>
        <Grid item>
          <Button
            disabled={!dialogVM.isValid || dialogVM.isLoading || dialogVM.isSaving}
            variant='contained'
            onClick={handleApply}
          >
            {lz.set_new_goal}
          </Button>
        </Grid>
      </Grid>
    )
  }

  const renderDialogContent = () => {
    return (
      <DialogContent>
        <Grid
          id='EditExerciseDetailsDialogContent'
          container
          direction='column'
          justifyContent='center'
          alignItems='stretch'
        >
          {renderBody()}
        </Grid>
      </DialogContent>
    )
  }

  const renderDialogActions = () => {
    return (
      <DialogActions>
        <Grid
          id='EditExerciseDetailsDialogActions'
          container
          direction='column'
          justifyContent='center'
          alignItems='stretch'
        >
          {renderActionButtons()}
        </Grid>
      </DialogActions>
    )
  }

  const renderSpinner = () => {
    if (!dialogVM.isLoading && !dialogVM.isSaving) return
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  return (
    <Dialog
      id='EditExerciseDetailsDialog'
      open={dialogVM.isOpen}
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
      onClose={handleClose}
      fullWidth
    >
      <div className={'dialog-container'}>{renderDialogTitle()}</div>
      {renderDialogContent()}
      {renderDialogActions()}
      {renderSpinner()}
    </Dialog>
  )
}

export default inject(
  'trainingItemsStore',
  'trainingPlansStore',
  'localizationStore'
)(observer(EditExerciseDetailsDialog))
