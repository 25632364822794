import React from 'react'
import {
  TextField,
  InputLabel,
  FormControlLabel,
  Tooltip,
  Select,
  MenuItem,
} from '@material-ui/core'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { observer } from 'mobx-react'
import { inject } from 'mobx-react'
import './QuestionForm.scss'
import PropTypes from 'prop-types'
import Icon from '../Icon'
import { LabelsStore } from '../../../labels/store/LabelsStore'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import { QuizVM } from '../../view-models/QuizVM'
import OptionsList from './OptionsList'
import { QuestionType } from '../../types/QuestionType'

interface QuestionFormProps {
  quiz: QuizVM
  labelsStore?: LabelsStore
  localizationStore?: LocalizationStore
}

const QuestionForm: React.FC<QuestionFormProps> = ({ quiz, labelsStore, localizationStore }) => {
  if (!quiz.currentQuestion) return null

  const { lzStrings } = localizationStore

  function tooltip(props) {
    const { children, value, open } = props

    const popperRef = React.useRef(null)
    React.useEffect(() => {
      if (popperRef.current) {
        popperRef.current.update()
      }
    })

    return (
      <Tooltip
        PopperProps={{
          popperRef,
        }}
        open={open}
        enterTouchDelay={0}
        placement='bottom'
        title={value}
      >
        {children}
      </Tooltip>
    )
  }

  tooltip.propTypes = {
    children: PropTypes.element.isRequired,
    value: PropTypes.number.isRequired,
    open: PropTypes.bool.isRequired,
  }

  const renderRatingDisplay = () => {
    if (quiz.currentQuestion.type !== 'rating' && quiz.currentQuestion.type !== 'starRating') return null

    return (
      <div>
        <InputLabel>{lzStrings.surveyEditQuestionForm.rating}</InputLabel>
        <RadioGroup
          value={quiz.currentQuestion.ratingDisplay}
          onChange={(e) => quiz.currentQuestion.setRatingDisplay(e.target.value)}
        >
          <FormControlLabel
            value='horizontal'
            control={<Radio disabled={quiz.isDisabled} />}
            label='Horizontal'
          />
          <FormControlLabel
            value='vertical'
            control={<Radio disabled={quiz.isDisabled} />}
            label='Vertical'
          />
        </RadioGroup>
      </div>
    )
  }

  const renderQuestionTypes = () => {
    return quiz.questionTypes.map((type, index) => {
      let icon = ''
      let color = ''
      let label = ''
      if (type === 'emoji') {
        icon = 'far fa-smile'
        color = 'brown'
        label = 'Emoji'
      }
      if (type === 'check') {
        icon = 'far fa-check-square'
        color = 'blue'
        label = 'Multi-select'
      }
      if (type === 'radio') {
        icon = 'far fa-dot-circle'
        color = 'green'
        label = 'Multiple Choice'
      }
      if (type === 'rating') {
        icon = 'fas fa-list-ol'
        color = 'orange'
        label = 'Rating'
      }
      if (type === 'starRating') {
        icon = 'fas fa-star'
        color = 'yellow'
        label = 'Star Rating'
      }
      if (type === 'text') {
        icon = 'fas fa-font'
        color = 'red'
        label = 'Short Answer'
      }
      if (type === 'yesNo') {
        icon = 'far fa-thumbs-up'
        color = 'purple'
        label = 'Yes or No'
      }
      if (type === 'infoText') {
        icon = 'fas fa-info-circle'
        color = 'blue'
        label = 'Info Text'
      }
      if (type === 'dropdown') {
        icon = 'fas fa-caret-square-down'
        color = 'green'
        label = 'Dropdown'
      }

      return (
        <MenuItem
          key={index}
          value={type}
          className={`type-menu-item ${color}`}
          onClick={(e) => {
            quiz.updateCurrentQuestionByType(type as QuestionType)
          }}
        >
          <Icon icon={icon} className='icon' />
          {label}
        </MenuItem>
      )
    })
  }

  const renderTitleRow = () => {
    return (
      <>
        <InputLabel>{lzStrings.surveyEditQuestionForm.question}</InputLabel>
        <TextField
          disabled={quiz.isDisabled}
          error={quiz.currentQuestion.titleError}
          id='outlined-name'
          value={quiz.currentQuestion.title}
          onChange={(e) => {
            quiz.currentQuestion.setTitle(e.target.value)
          }}
          margin='none'
          fullWidth
          variant='outlined'
          placeholder={"What's your question?"}
        />
      </>
    )
  }

  const renderTypeRow = () => {
    return (
      <>
        <InputLabel>{lzStrings.surveyEditQuestionForm.type}</InputLabel>
        <Select
          disabled={quiz.isDisabled}
          className='type-select'
          variant='outlined'
          value={quiz.currentQuestion.type}
          onChange={(e) => quiz.currentQuestion.changeQuestionType(e.target.value)}
        >
          {renderQuestionTypes()}
        </Select>
      </>
    )
  }

  const renderOptionsRow = () => {
    if (!quiz.currentQuestion.canHaveOptions) return null
    return (
      <>
        <InputLabel>{lzStrings.surveyEditQuestionForm.choices}</InputLabel>
        <OptionsList quiz={quiz} />
      </>
    )
  }

  return (
    <form noValidate autoComplete='off' id='QuestionForm'>
      {renderTitleRow()}
      {renderTypeRow()}
      {renderOptionsRow()}
      {renderRatingDisplay()}
    </form>
  )
}

export default inject('localizationStore', 'labelsStore')(observer(QuestionForm))
