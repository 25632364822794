import React from 'react'
import {
  Dialog,
  DialogTitle,
  IconButton,
  Grid,
  DialogContent,
  Button,
  Typography,
} from '@material-ui/core'
import { EventsStore } from '../../../store/EventsStore'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { observer, inject } from 'mobx-react'
import CloseIcon from '@material-ui/icons/Close'

interface Props {
  eventsStore?: EventsStore
  localizationStore?: LocalizationStore
}

const MasterEventPrompt: React.FC<Props> = ({ eventsStore }) => {
  const { userEventPopupVM: vm } = eventsStore
  if (!vm) return null

  const renderSeriesText = () => {
    if (vm.masterEventPromptMode === 'delete') return 'Delete'
    if (vm.masterEventPromptMode === 'copy') return 'Copy'
    return 'Edit'
  }

  const renderOccurrenceText = () => {
    if (vm.masterEventPromptMode === 'delete') return 'Delete'
    if (vm.masterEventPromptMode === 'copy') return 'Copy'
    return 'Edit'
  }

  const renderSeriesBody = () => {
    if (vm.event.isMasterEvent) return null
    return (
      <DialogContent id='modalBody' style={{ textAlign: 'center', padding: '50px' }}>
        <Button variant='outlined' onClick={() => vm.handleMasterEventSeries()}>
          {renderSeriesText()}
        </Button>
        <Button variant='outlined' onClick={() => vm.handleMasterEventThisOccurrence()}>
          {renderOccurrenceText()}
        </Button>
      </DialogContent>
    )
  }

  const renderMasterEventBody = () => {
    if (!vm.event.isMasterEvent) return null
    return (
      <DialogContent id='masterModalBody' style={{ textAlign: 'center', padding: '50px' }}>
        <Typography className='master-event-text' variant='body1'>
          Are you sure you want to delete this event?
        </Typography>
        <Button
          variant='outlined'
          onClick={() => vm.loadNotificationsVM(() => vm.deleteThisEvent())}
        >
          {renderSeriesText()}
        </Button>
      </DialogContent>
    )
  }

  return (
    <Dialog onClose={() => vm.toggleMasterEventPrompt()} open={vm.masterEventPromptShown} fullWidth>
      <Grid
        id='modalHeader'
        className='dialog-title'
        container
        justifyContent='space-between'
        alignItems='center'
      >
        <Grid item>
          <DialogTitle>Event</DialogTitle>
        </Grid>
        <Grid item>
          <IconButton onClick={() => vm.toggleMasterEventPrompt()}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      {renderSeriesBody()}
      {renderMasterEventBody()}
    </Dialog>
  )
}

export default inject('eventsStore')(observer(MasterEventPrompt))
