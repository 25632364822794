import React from 'react'
import { inject, observer } from 'mobx-react'
import { Card, Popover, Link } from '@material-ui/core'
import UserTaskPopupToolbar from './UserTaskPopupToolbar'
import UserTaskPopupBody from './UserTaskPopupBody'
import './UserTaskPopup.scss'
import { EventsStore } from '../../../store/EventsStore'

interface IUserTaskPopup {
  eventsStore?: EventsStore
}

const UserTaskPopup: React.FC<IUserTaskPopup> = ({ eventsStore }) => {
  const { taskPopupVM: vm } = eventsStore
  if (!vm) return null
  if (!vm.anchorEl) return null

  const renderTaskLink = () => {
    if (!vm.userOwned) return null

    return (
      <Link onClick={() => vm.navigateToTask()} className='task-link'>
        Open Task
      </Link>
    )
  }

  const renderUserTaskLink = () => {
    if (vm.userOwned) return null

    return (
      <Link onClick={() => vm.navigateToUserTask()} className='task-link'>
        Open Task
      </Link>
    )
  }

  return (
    <Popover
      open={true}
      anchorEl={vm.anchorEl}
      onClose={() => vm.close()}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      className='calendarEventModal'
    >
      <Card elevation={5} className='userTaskPopupCard'>
        <UserTaskPopupToolbar />
        <UserTaskPopupBody />
        {renderTaskLink()}
        {renderUserTaskLink()}
      </Card>
    </Popover>
  )
}

export default inject('eventsStore')(observer(UserTaskPopup))
