import { action, computed, observable } from 'mobx'
import Parse from 'parse'
import moment from 'moment'
import { ErrorRowVM } from './ErrorRowVM'
import { RootStore } from 'src/app/stores/RootStore'
import { ICategoriesImportResultDTO } from '../dtos/ICategoriesImportResult'
import { CategoryImportRowVM } from './CategoryImportRowVM'
import { ICategoryImportRowDTO } from '../dtos/ICategoryImportRowDTO'
import { CategoriesService } from '../services/CategoriesService'
import env from '../../../env'

export default class ContactImportVM {
  private rootStore: RootStore
  private svc: CategoriesService
  private subscription: Parse.LiveQuerySubscription
  private query: Parse.Query

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.svc = new CategoriesService(this.rootStore)
  }

  @observable public file: File = null
  @observable public importProcessing: boolean = false
  @observable public result: ICategoriesImportResultDTO = null

  public handleFileDrop(file) {
    this.file = file[0]
    return
  }

  @computed
  public get hasErrors(): boolean {
    if (!this.result) return false
    if (this.errorRows.length !== 0) return true
    return this.result.rows.some((row) => {
      if (!row.result) return false
      if (row.result.errorMessage) return true
    })
  }

  @computed
  public get validateEnabled() {
    if (this.importProcessing) return false
    if (this.file) return true
    return false
  }

  @computed
  public get errorRows(): ErrorRowVM[] {
    if (!this.result) return []
    return this.result.errorMessages.map((err, index) => new ErrorRowVM(this.rootStore, err, index))
  }

  @computed
  public get importProgress(): number {
    if (!this.result) return 0
    const val = (this.categoryRows.length / this.result.rows.length) * 100
    return val
  }

  @computed
  public get categoryRows(): CategoryImportRowVM[] {
    if (!this.result) return []
    return this.result.rows
      .filter((e) => e.result)
      .map((row) => new CategoryImportRowVM(this.rootStore, row))
  }

  @action
  public downloadTemplate() {
    window.location.href = `${env.var.REACT_APP_API_URL}/exportCategoriesToCsv?orgId=${this.rootStore.appStore.currentOrgId}`
  }

  @action
  public async acceptChanges() {
    const s = this.rootStore.localizationStore.lzStrings.importModal
    const batchId = moment().format(s.batch_format)
    const fileName = batchId + '.csv'
    const parseFile = await new Parse.File(fileName, this.file, 'text/csv')
    parseFile.setTags({ organizationId: this.rootStore.appStore.currentOrgId })
    await parseFile.save()
    this.rootStore.categoriesStore.toggleImportModal()
    await this.svc.importCategoriesFromCsv(
      this.rootStore.appStore.currentOrgId,
      parseFile,
      true,
      batchId
    )
  }

  @computed
  public get processedRowsCount(): number {
    return this.result.rows.filter((e) => e.result).length
  }

  @computed
  public get allowAcceptChanges(): boolean {
    if (!this.result) return false
    if (!this.validateEnabled) return false
    if (this.hasErrors) return false
    if (this.importProcessing) return false
    if (!this.file) return false
    return true
  }

  @action
  private setResult(result) {
    this.result = result
    if (!this.result) return
    if (!this.result.success) this.importProcessing = false
    if (this.result.success && this.processedRowsCount === this.result.rows.length)
      this.importProcessing = false
    if (!this.result.success) this.file = null
  }

  @action
  public async validate() {
    const s = this.rootStore.localizationStore.lzStrings.importModal
    this.importProcessing = true
    this.setResult(null)
    const batchId = moment().format(s.batch_format)
    const fileName = batchId + '.csv'
    const parseFile = await new Parse.File(fileName, this.file, 'text/csv')
    parseFile.setTags({ organizationId: this.rootStore.appStore.currentOrgId })
    await parseFile.save()
    await this.listenToChanges(batchId)
    const result = await this.svc.importCategoriesFromCsv(
      this.rootStore.appStore.currentOrgId,
      parseFile,
      false,
      batchId
    )
    this.setResult(result)
  }

  @computed
  public get showDropZone(): boolean {
    if (this.categoryRows.length === 0 && !this.importProcessing && this.errorRows.length > 0) {
      return true
    }
    if (this.categoryRows.length) return false
    if (this.importProcessing) return false
    return true
  }

  @computed
  public get showSpinner(): boolean {
    if (this.allowAcceptChanges) return false
    if (this.categoryRows.length > 0) return false
    if (this.importProcessing) return true
    return false
  }

  private async listenToChanges(batchId: string) {
    if (process.env.NODE_ENV === 'test') return
    if (this.subscription) this.subscription.unsubscribe()
    this.query = new Parse.Query('categoryImportUpdates')
    this.query.equalTo('batchId', batchId)
    await this.query.find()
    this.subscription = await this.query.subscribe()
    this.subscription.on('create', (e: Parse.Object) => {
      const obj = e.toJSON() as any
      if (obj.updateType === 'result') this.setResult(obj.resultData)
      if (obj.updateType === 'row') this.processRowResult(obj.rowData)
    })
  }

  @action
  private processRowResult(row: ICategoryImportRowDTO) {
    if (!this.result) return
    this.result.rows[row.rowIndex - 1] = row
  }
}
