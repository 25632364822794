import React from 'react'
import { Observer } from 'mobx-react'
import rootStore from '../../stores/RootStore'
import fullLogo from '../../../assets/img/logos/RippleWorx-White.png'
import './NoOrganizationsFound.scss'

interface Props {
  router: any
}

const NoOrganizationsFound: React.FC<Props> = ({ router }) => {
  const { lzStrings } = rootStore.localizationStore
  const { ForgotPasswordVM: vm } = rootStore.authStore
  rootStore.appStore.setRouter(router)

  return (
    <Observer>
      {() => (
        <div className='login' id='NoOrganizationsFound'>
          <div className='center-body'>
            <div>
              <img className='logo' alt='RippleWorx Logo' src={fullLogo} />
            </div>
            <form>
              <div className='card'>
                <h4>{lzStrings.noOrganizationsFound.title}</h4>
                <div className='helper-text'>{lzStrings.noOrganizationsFound.instruction}</div>
                <a onClick={() => vm.goToLogin()} className='forgot-password'>
                  <span className='fa fa-arrow-left' />
                  &nbsp;
                  {lzStrings.forgotPassword.backToLogin}
                </a>
              </div>
            </form>
          </div>
        </div>
      )}
    </Observer>
  )
}

export default NoOrganizationsFound
