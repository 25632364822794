import { reaction, action, computed, observable } from 'mobx'
import { LabelsStore } from '../../../labels/store/LabelsStore'
import { AGGridRowVM } from '../../../shared/ag-grid/AGGridRowVM'
import { RootStore } from '../../../stores/RootStore'
import { NotificationTemplate } from '../aggregates/NotificationTemplate'
import { NotificationsWidgetVM } from './NotificationsWidgetVM'

export class NotificationTemplateRowVM extends AGGridRowVM<NotificationTemplate> {
  private listVM: NotificationsWidgetVM

  constructor(
    rootStore: RootStore,
    notificationTemplate: NotificationTemplate,
    listVM: NotificationsWidgetVM
  ) {
    super(rootStore, notificationTemplate, listVM)
    this.labelsStore = this.rootStore.labelsStore
    this.listVM = listVM
  }

  @observable public labelsStore: LabelsStore = null

  @computed
  public get infoString(): string {
    return this.notificationTypeId + this.channel + this.language + this.isDefault
  }

  @computed
  public get notificationTypeId(): string {
    const type = this.aggregate.notificationTypeId
    return type
  }

  @computed
  public get objectId(): string {
    return this.aggregate.objectId
  }

  @computed
  public get channel(): string {
    return this.aggregate.channel
  }

  @computed
  public get language(): string {
    return this.aggregate.language
  }

  @computed
  public get isDefault(): boolean {
    return this.aggregate.isDefault
  }

  openNotification() {
    this.rootStore.notificationsStore.initWidgetForm(this.objectId, this.listVM.isSystemOnly)
  }
}
