import { computed, observable, action } from 'mobx'
import { PulseCategoriesManageVM } from '../../pulse-categories/view-models/PulseCategoriesManageVM'
import { AGGridVM } from '../../shared/ag-grid/AGGridVM'
import { RootStore } from '../../stores/RootStore'
import { PulseSet } from '../aggregate/PulseSet'

export class PulseSetFilterRowVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, pulseSet: PulseSet) {
    this.rootStore = rootStore
    this.pulseSet = pulseSet
  }

  @observable public pulseSet: PulseSet = null
  @observable public isChecked: boolean = false

  public get objectId(): string {
    return this.pulseSet.objectId
  }

  public get name(): string {
    return this.pulseSet.name
  }

  public toggleIsChecked() {
    this.isChecked = !this.isChecked
  }

}
