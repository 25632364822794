import React from 'react'
import CalendarEventDrawerLocation from './CalendarEventDrawerLocation'
import CalendarEventDrawerResources from './CalendarEventDrawerResources'

const CalendarEventDrawerLocationsTab = () => (
  <>
    <CalendarEventDrawerLocation />
    <CalendarEventDrawerResources />
  </>
)

export default CalendarEventDrawerLocationsTab
