import React from 'react'
import { observer, inject } from 'mobx-react'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import { ManageSurveysWidgetVM } from '../../../view-models/manage-surveys-widget/ManageSurveysWidgetVM'
import MuiConfirmPrompt from '../../../../shared/MuiConfirmPrompt'
import { LabelsStore } from '../../../../labels/store/LabelsStore'

interface Props {
  widgetVM: ManageSurveysWidgetVM
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
}

const RemindDialog: React.FC<Props> = ({ widgetVM, localizationStore, labelsStore }) => {
  if (!widgetVM.sendRemindersDialogOpen) return null
  const lz = localizationStore.lzStrings.manageSurveysWidget

  const renderMessage = () => {
    return (
      <>
        <span>{lz.send_survey_reminders}</span>
      </>
    )
  }

  return (
    <MuiConfirmPrompt
      confirmMessage={renderMessage()}
      confirmMessageSecondary={`${lz.this_action_remind} ${labelsStore
        .getLabelByLanguageAndFor('pulse')
        .toLowerCase()}`}
      onClose={() => widgetVM.toggleSendRemindersDialog()}
      open={widgetVM.sendRemindersDialogOpen}
      onConfirm={() => widgetVM.sendReminders()}
      title={lz.send_reminders}
    />
  )
}

export default inject('localizationStore', 'labelsStore')(observer(RemindDialog))
