import { computed, observable } from 'mobx'
import Parse from 'parse'
import { DataStore } from '../../../../shared/data/DataStore'
import { RootStore } from '../../../../stores/RootStore'
import { PulseCampaign } from '../../../aggregate/PulseCampaign'
import { IPulseCampaignDTO } from '../../../dto/IPulseCampaignDTO'
import { ICampaignTemplateFindRequest } from '../../../interfaces/ICampaignTemplateFindRequest'
import { IPulseCampaignFindResult } from '../../../interfaces/IPulseCampaignFindResult'
import { PulseCampaignsService } from '../../../service/PulseCampaignsService'


export class PulseCampaignTemplatesDashboardDataStore extends DataStore<PulseCampaign, IPulseCampaignDTO> {
  private request: ICampaignTemplateFindRequest

  constructor(rootStore: RootStore, request: ICampaignTemplateFindRequest) {
    super(rootStore, PulseCampaign, 'pulseCampaigns', ['name', 'surveys'])
    this.request = request
    this.paged = true
    this.listenToListRecordsViaApiUpdates = true
  }

  @observable public isLoaded: boolean = false

  public onRecordUpdated(obj: PulseCampaign) {
    return this.loadListRecords()
  }

  public onListRecordsLoaded() {
    this.isLoaded = true
  }

  public setRequest(request: ICampaignTemplateFindRequest) {
    this.request = request
  }

  public getRequest(): ICampaignTemplateFindRequest {
    return this.request
  }

  protected getBaseQuery() {
    const query = new Parse.Query(this.className)
    return query
  }

  protected getListRecordsApiCall() {
    return async () => {
      const req: ICampaignTemplateFindRequest = {
        ...this.request,
        listColumns: this.listColumns,
      }
      const svc = new PulseCampaignsService()
      const result: IPulseCampaignFindResult = await svc.getCampaignTemplates(req)
      this.totalRecords = result.count
      return result.pulseCampaigns
    }
  }

  public get rows() {
    return this.records
  }
}
