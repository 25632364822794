import { RootStore } from '../../stores/RootStore'
import { computed } from 'mobx'

export class ErrorRowVM {
  private rootStore: RootStore
  private error: string
  private index: number

  constructor(rootStore: RootStore, error: string, index: number) {
    this.error = error
    this.index = index
    this.rootStore = rootStore
  }

  public type: string = 'error'

  @computed
  public get message(): string {
    return this.error
  }

  @computed
  public get height() {
    return 90
  }
}
