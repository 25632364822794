import { IDTO } from './IDTO'
import Parse from 'parse'
import { ParsePagedQueryResponse } from './ParsePagedQueryResponse'

export class ParseQueryService {
  public async getAllFromQuery(query: Parse.Query, recordsPerPage: number = 1000): Promise<IDTO[]> {
    let allRecords = []
    let pageNumber = 1
    let rows = []
    while ((rows.length === recordsPerPage && recordsPerPage !== 1) || pageNumber === 1) {
      const result = await this.getPageFromQuery(query, pageNumber, recordsPerPage)
      rows = result.rows
      allRecords = allRecords.concat(rows)
      pageNumber = pageNumber + 1
    }
    return allRecords
  }

  public async getPageFromQuery(
    query: Parse.Query,
    pageNumber: number,
    recordsPerPage: number = 1000
  ): Promise<ParsePagedQueryResponse> {
    let doWithCount = pageNumber === 1
    if (process.env.NODE_ENV === 'test') doWithCount = false
    query.limit(recordsPerPage)
    query.skip(recordsPerPage * (pageNumber - 1))
    query.withCount(doWithCount)
    const result = await query.find()
    if (doWithCount) {
      return {
        count: result['count'],
        rows: result['results'].map((e) => e.toJSON()),
      }
    }
    return {
      count: undefined,
      rows: result.map((e) => e.toJSON()),
    }
  }
}
