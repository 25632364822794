import React from 'react'
import { observer } from 'mobx-react'
import rootStore from '../../stores/RootStore'
import { WorksheetsStore } from '../../worksheets/store/WorksheetsStore'
import VisualizationsGrid from './VisualizationsGrid'
import VisualizationsTopToolbar from './VisualizationsTopToolbar'
import './Visualizations.scss'

interface Props {
  props: any
  worksheetsStore?: WorksheetsStore
}

@observer
export default class Visualizations extends React.Component<Props, any> {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div id='VisualizationsLayout'>
        <div className='visualizations-container'>
          <VisualizationsTopToolbar worksheetsStore={rootStore.worksheetsStore} vm={rootStore.userWorksheetsStore.contentVM} />
          <VisualizationsGrid worksheetsStore={rootStore.worksheetsStore} userWorksheetsStore={rootStore.userWorksheetsStore} vm={rootStore.userWorksheetsStore.contentVM} />
        </div>
      </div>
    )
  }
}
