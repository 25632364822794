import React from 'react'

//MobX Stores
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { OrganizationsStore } from '../../store/OrganizationsStore'

//MaterialUI Components
import { CardContent, Divider } from '@material-ui/core'
import { LabelsStore } from '../../../labels/store/LabelsStore'

//Components Props Definition
interface ContentProps {
  localizationStore?: LocalizationStore
  organizationsStore?: OrganizationsStore
  labelsStore?: LabelsStore
}

//Component
const WidgetContent: React.FC<ContentProps> = ({
  localizationStore,
  organizationsStore,
  labelsStore,
}) => {
  const { lzStrings } = localizationStore
  const { currentOrganization } = organizationsStore
  if (!currentOrganization) return null

  const modules = []

  if (currentOrganization.isCalendarEnabled) modules.push(lzStrings.organizationWidget.calendar)
  if (currentOrganization.isChatEnabled) modules.push(lzStrings.organizationWidget.chat)
  if (currentOrganization.isSurveysEnabled)
    modules.push(labelsStore.getLabelByLanguageAndFor('pulses'))
  if (currentOrganization.isConsentsEnabled) modules.push(lzStrings.organizationWidget.consents)
  if (currentOrganization.isTasksEnabled) modules.push(lzStrings.organizationWidget.tasks)
  if (currentOrganization.isGoalsEnabled) modules.push(lzStrings.organizationWidget.goals)
  if (currentOrganization.isTrainingsEnabled) modules.push(lzStrings.organizationWidget.trainings)
  if (currentOrganization.isCustomCollectionsEnabled)
    modules.push(lzStrings.organizationWidget.custom_collections)

  const modulesString = modules.join(', ')

  const notifications = []

  if (currentOrganization.isEmailChecked) notifications.push(lzStrings.organizationWidget.email)
  if (currentOrganization.isPushChecked) notifications.push(lzStrings.organizationWidget.push)
  if (currentOrganization.isTextChecked) notifications.push(lzStrings.organizationWidget.texts)

  const notificationsString = notifications.join(', ')

  return (
    <CardContent>
      <section>
        <h5>{lzStrings.organizationWidget.basic_info}</h5>
        <div className={'section-indent'}>
          <div className={'left-col'}>
            <p className={'field-label'}>{lzStrings.organizationWidget.organization}</p>
            <p className={'field-label'}>{lzStrings.organizationWidget.email_address}</p>
          </div>
          <div className={'right-col'}>
            <div>
              {<p>{currentOrganization.name}</p>}
              {<p className='email'> {currentOrganization.adminEmail}</p>}
            </div>
          </div>
        </div>
        <Divider />
      </section>
      <section>
        <h5>{lzStrings.organizationWidget.modules}</h5>
        <div className={'section-indent'}>
          <div className={'left-col'}>
            <p className={'field-label'}>{lzStrings.organizationWidget.enabled}</p>
          </div>
          <div className={'right-col modules'}>{modulesString}</div>
        </div>
        <Divider />
      </section>
      <section>
        <h5>{lzStrings.organizationWidget.notifications}</h5>
        <div className={'section-indent'}>
          <div className={'left-col'}>
            <p className={'field-label'}>{lzStrings.organizationWidget.enabled}</p>
          </div>
          <div className={'right-col notifications'}>
            {currentOrganization.isEmailChecked && (
              <>{lzStrings.organizationWidget.email + ', '} </>
            )}
            {currentOrganization.isPushChecked && <>{lzStrings.organizationWidget.push + ', '} </>}
            {currentOrganization.isTextChecked && <>{lzStrings.organizationWidget.texts} </>}
          </div>
        </div>
      </section>
    </CardContent>
  )
}

export default inject(
  'localizationStore',
  'organizationsStore',
  'labelsStore'
)(observer(WidgetContent))
