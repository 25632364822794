import React from 'react'
import { Observer } from 'mobx-react'
import rootStore from '../../stores/RootStore'
import fullLogo from '../../../assets/img/logos/RippleWorx-White.png'
import './Login.scss'

interface IForgotPassword {
  router: any
}

const ForgotPassword: React.FC<IForgotPassword> = ({ router }) => {
  const { lzStrings } = rootStore.localizationStore
  const { ForgotPasswordVM: vm } = rootStore.authStore
  rootStore.appStore.setRouter(router)

  return (
    <Observer>
      {() => (
        <div className='login'>
          <div className='center-body'>
            <div>
              <img className='logo' alt='RippleWorx Logo' src={fullLogo} />
            </div>
            <form>
              <div className='card'>
                <h4>{lzStrings.forgotPassword.title}</h4>

                <div className='helper-text'>{lzStrings.forgotPassword.instruction}</div>

                {vm.resetUnknownError && (
                  <span className='error-message'>{lzStrings.forgotPassword.unknown_error}</span>
                )}

                <input
                  type='email'
                  name='email'
                  placeholder={lzStrings.forgotPassword.email_placeholder}
                  value={vm.username}
                  onChange={(e) => vm.setForgotPasswordUsername(e.target.value)}
                />

                <div className='button-container'>
                  <button
                    className='request-pw-button'
                    disabled={vm.fetching}
                    type='button'
                    onClick={vm.handleSubmit}
                  >
                    {lzStrings.forgotPassword.requestResetButton}
                  </button>
                </div>

                <button onClick={() => vm.goToLogin()} className='forgot-password'>
                  <span className={'fa fa-arrow-left'}>&nbsp;</span>
                  <span className={'forgot-password-text'}>
                    {lzStrings.forgotPassword.backToLogin}
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </Observer>
  )
}

export default ForgotPassword
