import { RootStore } from '../../../stores/RootStore'
import { AnnouncementAnalyticsVM } from './AnnouncementAnalyticsVM'
import { AGGridVM } from 'src/app/shared/ag-grid/AGGridVM'
import { ColDef, GridOptions, RowClickedEvent } from 'ag-grid-community'
import { action, computed, observable } from 'mobx'
import AnnouncementParticipantsDataStore from '../../store/AnnouncementParticipantsDataStore'
import { AnnouncementParticipantsRowVM } from './AnnouncementParticipantsRowVM'
import { nameOf } from 'src/app/shared/nameOf'
import { UserAnnouncementEventsVM } from 'src/app/userAnnouncementEvents/view-models/UserAnnouncementEventsVM'
import { AnnouncementParticipant } from '../../aggregate/AnnouncementParticipant'

export class AnnouncementParticipantsListVM extends AGGridVM {
  private rootStore: RootStore
  public analyticsVM: AnnouncementAnalyticsVM
  public dataStore: AnnouncementParticipantsDataStore

  constructor(rootStore, analyticsVm: AnnouncementAnalyticsVM, announcementId: string) {
    super()
    this.rootStore = rootStore
    this.analyticsVM = analyticsVm
    this.announcementId = announcementId
    this.loadDataStore()
    this.sizeColumnsToFit = true
    this.serverSideLoaded = true
  }

  @observable public announcementId: string = undefined
  @observable public rows = []
  @observable public loadingFirstPage: boolean = true
  @observable public eventsVM: UserAnnouncementEventsVM = undefined
  @observable public showEventDialog: boolean = false
  @observable public loadingDataStore: boolean = false
  @observable public filterAnchorEl = null
  @observable public filterText: string = 'All'
  @observable public filterDisabled: boolean = false

  @action
  public setFilterAnchorEl(element) {
    this.filterAnchorEl = element
  }

  @action
  public loadDataStore() {
    this.loadingDataStore = true
    this.dataStore = new AnnouncementParticipantsDataStore(this.rootStore, {
      announcementId: this.announcementId,
      organizationId: this.rootStore.appStore.currentOrgId,
    })
    this.dataStore.onRecordUpdated = (e) => this.onRecordUpdated(e)
    this.loadingDataStore = false
  }

  @action
  private onRecordUpdated(obj: AnnouncementParticipant) {
    const rowNode = this.gridApi.getRowNode(obj.objectId)
    if (!rowNode) {
      return
    }
    rowNode.setData(new AnnouncementParticipantsRowVM(this.rootStore, obj, this))
  }

  @action
  public toggleShowEventDialog() {
    this.showEventDialog = !this.showEventDialog
  }

  @computed
  public get hasRows() {
    return this.rows.length !== 0
  }

  @computed
  public get shouldRender(): boolean {
    if (this.loadingDataStore) {
      return false
    } else {
      return true
    }
  }

  @computed
  public get columnDefs(): ColDef[] {
    const s = this.rootStore.localizationStore.lzStrings
    return [
      {
        headerName: 'Name',
        field: nameOf<AnnouncementParticipantsRowVM, string>((e) => e.name),
        sort: 'asc',
      },
      {
        headerName: 'Liked',
        field: nameOf<AnnouncementParticipantsRowVM, string>((e) => e.likedDate),
      },
      {
        headerName: 'Unliked',
        field: nameOf<AnnouncementParticipantsRowVM, string>((e) => e.unlikedDate),
      },
      {
        headerName: 'Viewed',
        field: nameOf<AnnouncementParticipantsRowVM, string>((e) => e.viewedDate),
      },
      {
        headerName: 'Invitation Created',
        field: nameOf<AnnouncementParticipantsRowVM, string>((e) => e.invitationCreated),
      },
      {
        headerName: 'Created Email Status',
        field: nameOf<AnnouncementParticipantsRowVM, string>((e) => e.createdEmailStatus),
      },
      {
        headerName: 'Invitation Updated',
        field: nameOf<AnnouncementParticipantsRowVM, string>((e) => e.invitationUpdated),
      },
      {
        headerName: 'Updated Email Status',
        field: nameOf<AnnouncementParticipantsRowVM, string>((e) => e.updatedEmailStatus),
      },
    ]
  }

  public rowClicked(e: RowClickedEvent) {
    const row: AnnouncementParticipantsRowVM = e.data
    if (!row) return
    row.click()
  }

  @action
  public getRowNodeId(row): string {
    return row.objectId
  }

  public getGridOptions(): GridOptions {
    return {
      ...this.baseGridOptions,
      rowData: this.rows,
      onRowClicked: (e) => this.rowClicked(e),
      getRowNodeId: (e) => this.getRowNodeId(e),
      suppressRowClickSelection: true,
      suppressCellSelection: true,
      suppressRowHoverHighlight: true,
      rowSelection: 'multiple',
      columnDefs: this.ensureTooltipFields(this.columnDefs),
      pagination: false,
    }
  }

  @action
  public hardRefresh() {
    if (!this.gridApi) return
    this.gridApi.refreshInfiniteCache()
    this.gridApi.purgeInfiniteCache()
    this.gridApi.paginationGoToFirstPage()
  }

  @action
  public setListFilter(value: string) {
    this.filterDisabled = true
    if (value === 'all') {
      this.filterText = 'All'
      const request = this.dataStore.getRequest()
      request.liked = false
      request.viewed = false
      this.dataStore.setRequest(request)
    } else if (value === 'viewed') {
      this.filterText = 'Viewed'
      const request = this.dataStore.getRequest()
      request.liked = false
      request.viewed = true
      this.dataStore.setRequest(request)
    } else if (value === 'liked') {
      this.filterText = 'Liked'
      const request = this.dataStore.getRequest()
      request.liked = true
      request.viewed = false
      this.dataStore.setRequest(request)
    }
    this.setQuickFilter('')
  }

  @action
  public setQuickFilter(val: string) {
    this.typedFilterText = val
    if (this.quickFilterTO) clearTimeout(this.quickFilterTO)
    this.quickFilterTO = setTimeout(() => this.applyFilter(), 1000)
  }

  private applyFilter() {
    this.dataStore.setFilter(this.typedFilterText)
    this.reload()
    this.columnApi.applyColumnState({
      state: [{ colId: 'lastName', sort: 'asc' }],
    })
  }

  protected onGridReadied(): void {
    this.gridApi.sizeColumnsToFit()
    this.gridApi.setDatasource({
      rowCount: this.totalRecords,
      getRows: async (params) => {
        params.sortModel.forEach((col: { colId: string; sort: 'asc' | 'desc' }, idx: number) => {
          let dbCol = col.colId
          if (dbCol === 'name') dbCol = 'lastName'
          if (idx === 0) this.dataStore.setSort(dbCol, col.sort)
          if (idx > 0) this.dataStore.addSort(dbCol, col.sort)
        })
        if (params.sortModel.length === 0) this.dataStore.setSort('lastName')
        if (params.startRow === 0) {
          this.dataStore.setPage(0)
          this.loadingFirstPage = true
        }
        await this.dataStore.getNextPage()
        const rows = this.dataStore.announcementParticipants.map(
          (e) => new AnnouncementParticipantsRowVM(this.rootStore, e, this)
        )
        this.rows = rows
        this.loadingFirstPage = false
        this.filterDisabled = false
        params.successCallback(rows, this.dataStore.totalRecords)
      },
    })
  }
}
