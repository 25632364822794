import { inject, observer } from 'mobx-react'
import React from 'react'
import {
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Typography,
  TextField,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CloseIcon from '@material-ui/icons/Close'
import PreviewIcon from '@material-ui/icons/Visibility'
import PreviewGoal from './PreviewGoal'
import { TrainingPlansStore } from '../../../../training-plans/store/TrainingPlansStore'
import { Goal } from '../../../../goals/aggregate/Goal'

interface Props {
  trainingPlansStore?: TrainingPlansStore
}

const AddGoals: React.FC<Props> = ({ trainingPlansStore }) => {
  const { trainingPlanEditVM: vm } = trainingPlansStore

  const renderSelectedGoal = () => {
    if (!vm.selectedGoal) return null
    return <PreviewGoal />
  }

  // const renderSelectedGoal = () => {       // original
  //   if (!vm.selectedGoal) return null
  //   return (
  //     <Paper elevation={2} className='plan-pill'>
  //       <Typography>{vm.selectedGoal.name}</Typography>
  //       <div className='icons'>
  //         <PreviewIcon onClick={() => vm.setGoalToPreview(vm.selectedGoal)} />
  //         <CloseIcon onClick={() => vm.addGoalToTrainingPlan('')} />
  //       </div>
  //     </Paper>
  //   )
  // }

  return (
    <>
      <Grid container id='Header'>
        <Grid item xs={12} className='title'>
          <DialogTitle>Associate a Goal to a Training Plan</DialogTitle>
          <CloseIcon className='close-modal-button' onClick={() => vm.cancelAssociateGoals()} />
        </Grid>
        <Grid item xs={12} className='body-toolbar'>
          <div>
            <Button variant='text' onClick={() => vm.cancelAssociateGoals()}>
              Cancel
            </Button>
            <Button variant='outlined' onClick={() => vm.saveAssociatedGoals()}>
              Save
            </Button>
          </div>
        </Grid>
      </Grid>
      <DialogContent>
        <Grid container className='body'>
          <Grid item xs={12} className='left-body'>
            <Typography variant='h6' className='select-label'>
              Every plan needs a goal, choose yours:
            </Typography>
            <Autocomplete
              className='select-goal-dropdown'
              options={vm.availableGoals}
              getOptionLabel={(goal) => goal.name}
              value={vm.selectedGoal || { name: '' }}
              onChange={(e, goal: Goal) => {
                vm.setStagedGoalId(goal && goal.objectId ? goal.objectId : '')
                vm.setGoalToPreview(vm.selectedGoal)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder='Select a Goal'
                  variant='outlined'
                  className='select-goal-input'
                />
              )}
            />
            {/* <Select
              // multiple={false}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'center',
                  horizontal: 'center',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
                getContentAnchorEl: null,
              }}
              variant='outlined'
              value={vm.title || ''}
              // displayEmpty={true}
              fullWidth
              placeholder='(Training Plan Name)'
            >
              {vm.availableGoals.map((goal, index) => (
                <MenuItem
                  value={goal.objectId}
                  key={index}
                  onClick={() => {
                    vm.addGoalToTrainingPlan(goal.objectId);
                    vm.setGoalToPreview(vm.selectedGoal)
                  }}
                  >
                  {goal.name}
                </MenuItem>
              ))}
              <MenuItem value={''} key={'create-new'} onClick={() => vm.createNewGoal()}>
                Create New Goal...
              </MenuItem>
            </Select> */}
            {renderSelectedGoal()}
          </Grid>
          {/* <Grid item xs={6} className='right-body'>
            <Typography variant='h6'>Selected Goal:</Typography>
            {renderSelectedGoal()}
          </Grid> */}
        </Grid>
      </DialogContent>
    </>
  )
}

export default inject('trainingPlansStore')(observer(AddGoals))
