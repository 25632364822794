import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { CategoriesStore } from 'src/app/categories/CategoriesStore'
import { Checkbox, Divider, FormControlLabel, FormGroup } from '@material-ui/core'
import { LabelsStore } from '../../../../labels/store/LabelsStore'

interface DrawerModulesProps {
  localizationStore?: LocalizationStore
  categoriesStore?: CategoriesStore
  labelsStore?: LabelsStore
}

const DrawerModules: React.FC<DrawerModulesProps> = ({
  localizationStore,
  categoriesStore,
  labelsStore,
}) => {
  const { lzStrings } = localizationStore
  const { widgetForm } = categoriesStore

  const handleChange = (event) => {
    widgetForm.setField(event.target.value, !widgetForm[event.target.value])
  }

  return (
    <section>
      <h6 className='m-0'>{lzStrings.organizationWidgetDrawer.modules}</h6>
      <form>
        <FormGroup row>
          <FormControlLabel
            key='categories'
            control={
              <Checkbox
                id='categoriesCheckbox'
                checked={widgetForm.availableForSkills}
                onChange={handleChange}
                value={'availableForSkills'}
                color='default'
              />
            }
            label={labelsStore.getLabelByLanguageAndFor('categories')}
          />
          <FormControlLabel
            key='surveysd'
            htmlFor='surveysCheckbox'
            control={
              <Checkbox
                id='surveysCheckbox'
                checked={widgetForm.availableForSurveys}
                onChange={handleChange}
                value={'availableForSurveys'}
                color='default'
              />
            }
            label={lzStrings.categoriesManagementWidgetDrawer.surveys}
          />
          <FormControlLabel
            key='tasks'
            htmlFor='tasksCheckbox'
            control={
              <Checkbox
                id='tasksCheckbox'
                checked={widgetForm.availableForTasks}
                onChange={handleChange}
                value={'availableForTasks'}
                color='default'
              />
            }
            label={lzStrings.categoriesManagementWidgetDrawer.tasks}
          />
        </FormGroup>
      </form>
      <Divider className='delete-divider' />
    </section>
  )
}

export default inject(
  'localizationStore',
  'categoriesStore',
  'labelsStore'
)(observer(DrawerModules))
