import { action, computed, observable } from 'mobx'
import * as Sentry from '@sentry/browser'
import { NotificationTemplate } from '../../notifications/store/aggregates/NotificationTemplate'
import { RootStore } from '../../stores/RootStore'
import { EmailTemplatesService } from '../service/EmailTemplatesService'
import { IEmailTemplatesGetVersionRequest } from '../interfaces/IEmailTemplatesGetVersionRequest'
import { IEmailTemplatesInitializeRequest } from '../interfaces/IEmailTemplatesInitializeRequest'
import { IEmailTemplatesInitializeResponse } from '../interfaces/IEmailTemplatesInitializeResponse'

export class EmailTemplateCreateDialogVM {
  private rootStore: RootStore
  private DEFAULT: string = ''

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.lz = this.rootStore.localizationStore.lzStrings.emailTemplateManagement
  }

  @observable public applyTried: boolean = false
  @observable public isLoading: boolean = false
  @observable public isSaving: boolean = false
  @observable public isOpen: boolean = false
  @observable public name: string = ''
  @observable public type: string = ''
  @observable public lz = undefined

  @computed
  public get notificationTemplates(): Array<NotificationTemplate> {
    if (!this.rootStore) return []
    if (!this.rootStore.notificationsStore) return []

    const orgTemplates = this.rootStore.notificationsStore.currentOrgNotificationTemplates.filter(
      (t) => t.channel === 'EMAIL'
    )
    return orgTemplates
  }

  @computed
  public get language(): string {
    const pref = this.rootStore.userStore.user.languagePreference
    if (!pref) return this.rootStore.localizationStore.language
    if (pref === 'English') return 'en'
    if (pref === 'German') return 'de'
    return 'en'
  }

  @computed
  public get availableNotificationTemplates(): Array<NotificationTemplate> {
    if (!this.rootStore) return []
    if (!this.rootStore.emailTemplatesStore) return []
    const orgTemplates = this.rootStore.emailTemplatesStore.allEmailTemplates.filter(
      (t) => t.notificationTypeId !== this.DEFAULT
    )

    const orgTemplatesTypeArr = orgTemplates.map((itemY) => {
      return itemY.notificationTypeId
    })
    const filtered = this.notificationTemplates
      .filter((template) => {
        if (template.notificationTypeId.startsWith('chat-')) return false
        if (this.language !== template.language) return false
        return !orgTemplatesTypeArr.includes(template.notificationTypeId)
      })
      .sort((e1, e2) =>
        e1.notificationTypeId > e2.notificationTypeId
          ? 1
          : e1.notificationTypeId < e2.notificationTypeId
          ? -1
          : 0
      )
      .map((e) => {
        const ee = e.clone()
        ee.notificationTypeId = e.notificationTypeId
        return ee
      })
    return filtered
  }

  @action
  public openDialog() {
    this.isOpen = true
  }

  @action
  public closeDialog() {
    this.isOpen = false
    this.reset()
  }

  @action
  public reset() {
    this.name = ''
    this.type = this.DEFAULT
    this.applyTried = false
  }

  @computed
  public get isLoaded(): boolean {
    return this.rootStore.emailTemplatesStore.loaded
  }

  @action
  public async handleNewEmailTemplate() {
    const req: IEmailTemplatesInitializeRequest = {
      name: this.name,
      generation: 'dynamic',
      notificationTypeId: this.type,
    }
    const svc = new EmailTemplatesService()
    let response: IEmailTemplatesInitializeResponse
    try {
      response = await svc.initializeDefaultEmailTemplate(req, this.rootStore.appStore.currentOrgId)
      if (response && response.success) {
        this.rootStore.emailTemplatesStore.viewModels.previewVM.openSnackbar(
          `${this.lz.success} System ${this.lz.email_template} Created `
        )
      } else {
        this.rootStore.emailTemplatesStore.viewModels.previewVM.openSnackbar(
          `${this.lz.error} ${this.lz.unable_to_initialize}`
        )
        const msg = response.errorMessage
        console.debug(msg)
      }
    } catch (e) {
      console.error(e)
      Sentry.captureException({
        message: this.lz.unable_to_initialize,
        exception: e,
        userId: this.rootStore.appStore.currentUserId,
        orgId: this.rootStore.appStore.currentOrgId,
      })
    }
    this.isSaving = false
    this.closeDialog()
    return response
  }

  @action
  public async getEmailTemplateVersion(sgTemplateId: string, sgTemplateVersionId: string) {
    const req: IEmailTemplatesGetVersionRequest = {
      template_id: sgTemplateId,
      version_id: sgTemplateVersionId,
    }
    const svc = new EmailTemplatesService()
    return await svc.getEmailTemplateVersion(req, this.rootStore.appStore.currentOrgId)
  }

  @computed
  public get isValid() {
    if (!this.isNameValid) return false
    if (!this.isTypeValid) return false
    return true
  }

  @computed
  public get isNameValid() {
    if (!this.name && this.applyTried) return false
    if (this.name.trim() === '' && this.applyTried) return false
    return true
  }

  @computed
  public get isTypeValid() {
    if (!this.type && this.applyTried) return false
    if (this.type && this.type.trim() === '' && this.applyTried) return false
    return true
  }
}
