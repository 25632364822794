import React from 'react'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { EventsStore } from '../../../store/EventsStore'
import { observer, inject } from 'mobx-react'
import { DialogContent, Tabs, Tab, Grid } from '@material-ui/core'
import TabPanel from '../../../../shared/TabPanel'
import DownloadTab from './DownloadTab'
import SubscribeTab from './SubscribeTab'
import './CalendarExportModal.scss'

interface Props {
  eventsStore?: EventsStore
  localizationStore?: LocalizationStore
}

const ModalBody: React.FC<Props> = ({ eventsStore, localizationStore }) => {
  const { exportVM: vm } = eventsStore.calendarVM

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    vm.setTabValue(newValue)
  }

  return (
    <DialogContent id='modalBody'>
      <Grid container className='tab-bar'>
        <Grid item xs={6}>
          <Tabs value={vm.tabValue} onChange={handleChange} variant='fullWidth'>
            <Tab label='Download' />
            <Tab label='Subscribe' />
          </Tabs>
        </Grid>
      </Grid>
      <TabPanel value={vm.tabValue} index={0}>
        <DownloadTab />
      </TabPanel>
      <TabPanel value={vm.tabValue} index={1}>
        <SubscribeTab />
      </TabPanel>
    </DialogContent>
  )
}

export default inject('eventsStore', 'localizationStore')(observer(ModalBody))
