import React from 'react'
import {
  InputLabel,
  OutlinedInput,
} from '@material-ui/core'

const NumberInput = props => {
  const renderError = () => {
    if (!props.error) return null
    return  (
      <div className='input-error'>{props.error}</div>
    )
  }
  return (
    <div className={props.className}>
      <InputLabel htmlFor={props.id} required={props.required}>
          {props.displayName}
      </InputLabel>
      <OutlinedInput
        disabled={props.disabled}
        type='number'
        id={props.id}
        fullWidth
        labelWidth={0}
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
        error={props.error ? true : false}
        required={props.required}
      />
      {renderError()}
    </div>  
  )
}

export default NumberInput
