import { computed, action } from 'mobx'
import { AGGridRowVM } from '../../shared/ag-grid/AGGridRowVM'
import { RootStore } from '../../stores/RootStore'
import { Task } from '../aggregates/Task'
import { UserTaskSnapshot } from '../aggregates/UserTaskSnapshot'
import { TasksManagementWidgetVM } from './TasksManagementWidgetVM'

export class TasksManagementWidgetRowVM extends AGGridRowVM<Task> {

  constructor(rootStore: RootStore, task: Task, widgetVM: TasksManagementWidgetVM) {
    super(rootStore, task, widgetVM)
  }

  @computed
  public get objectId(): string {
    return this.aggregate.objectId
  }

  @computed
  public get assignedByUserId(): string {
    return this.aggregate.assignedByUserId
  }

  @computed
  public get name(): string {
    return this.aggregate.name
  }

  @computed
  public get dueDate(): number {
    return this.aggregate.dueDate
  }

  @computed
  public get assignedToAudienceMembers(): Array<UserTaskSnapshot> {
    return this.aggregate.snapshots
  }

  @computed
  public get isComplete(): boolean {
    return !this.aggregate.snapshots.some((e) => !e.completed)
  }

  @action
  public openTask() {
    this.rootStore.tasksStore.openTask(this.objectId)
    this.rootStore.tasksStore.openDrawer()
  }
}
