import { action, computed, observable } from 'mobx'
import { IPulseQuestionDTO } from '../dtos/IPulseQuestionDTO'
import { PulseQuestionEditVM } from './PulseQuestionEditVM'
import { RootStore } from '../../stores/RootStore'
import PulseQuestion from '../aggregate/PulseQuestion'
import { deserialize } from 'serializr'
import { ChildCategoryRowVM } from '../../pulse-categories/view-models/ChildCategoryRowVM'
import { PulseQuestionType } from '../types/PulseQuestionType'


export class PulseQuestionTypeSelectModalVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, parentVM: ChildCategoryRowVM) {
    this.rootStore = rootStore
    this.parentVM = parentVM
  }

  @observable public parentVM: ChildCategoryRowVM = null

  public createQuestionFromType(type: PulseQuestionType) {
    const question: PulseQuestion = PulseQuestion.create()
    question.setQuestionType(type)
    question.setPulseCategoryId(this.parentVM.objectId)
    question.setDefaultOptions(type)
    this.closeModal()
    // this.rootStore.pulseQuestionsStore.editVM = new PulseQuestionEditVM(this.rootStore, this.parentVM, question.clone())
  }

  public closeModal() {
    this.rootStore.pulseQuestionsStore.closeTypeSelectModal()
  }

}
