import React from 'react'
import { inject, observer } from 'mobx-react'
import { Avatar, Button, Paper } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import './SurveyOptions.scss'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { SurveysStore } from '../../../store/SurveysStore'

interface ICustomPublishGroup {
  localizationStore?: LocalizationStore
  surveysStore?: SurveysStore
}

const CustomPublishGroup: React.FC<ICustomPublishGroup> = ({ localizationStore, surveysStore }) => {
  const surveyEditVM = surveysStore.viewModels.editVM
  const vm = surveyEditVM.surveyOptionsVM
  return (
    <div className={'custom-publish-group-paper'}>
      <Paper
        elevation={0}
        onClick={() => {
          vm.customPublishVM.setEditing(vm.customPublishVM.createNewGroup())
          vm.customPublishVM.setCustomPublishGroupNew(true)
          vm.customPublishVM.editing.candidatePublishGroupSelectVM.setParticipants(vm.customPublishVM.editing.customPublishGroupSelectVM.participants)
          vm.customPublishVM.editing.candidatePublishGroupSelectVM.setVisible()
          vm.toggleCustomPublishGroupDialogOpen()
        }}
      >
        <Button className={'custom-publish-create-btn'} color='primary' fullWidth>
          <Avatar className={'custom-publish-avatar'}>
            <AddIcon fontSize='small' />
          </Avatar>
          <p className={'custom-publish-label'}>Create Custom Publish Group</p>
        </Button>
      </Paper>
    </div>
  )
}

export default inject('localizationStore', 'surveysStore')(observer(CustomPublishGroup))
