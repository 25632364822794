import { action, computed } from 'mobx'
import moment from 'moment'
import { ParticipantVM } from 'src/app/participants-select/view-models/ParticipantVM'
import { RootStore } from '../../stores/RootStore'
import { ParticipantDueDate } from '../aggregates/ParticipantDueDate'
import { TrainingPlanEditVM } from './TrainingPlanEditVM'

export class ParticipantDueDateVM {
  private rootStore: RootStore
  public participantDueDate: ParticipantDueDate
  public trainingPlanVM: TrainingPlanEditVM

  constructor(
    rootStore: RootStore,
    participantDueDate: ParticipantDueDate,
    trainingPlanVM: TrainingPlanEditVM
  ) {
    this.participantDueDate = participantDueDate
    this.trainingPlanVM = trainingPlanVM
  }

  @computed
  public get participant(): ParticipantVM {
    return this.trainingPlanVM.participantsSelectVM.participants.filter(
      (part) => part.id === this.participantId
    )[0]
  }

  @computed
  public get participantId(): string {
    return this.participantDueDate.participantId
  }

  @computed
  public get dueDate(): Date {
    return moment(this.participantDueDate.dueDate).toDate()
  }

  @computed
  public get dueDateValid(): boolean {
    if (this.trainingPlanVM.dueDateType !== 'variesByParticipant') return true
    if (moment(this.trainingPlanVM.activeDate).isAfter(this.dueDate)) return false
    return true
  }

  @action
  public setDueDate(val: Date) {
    this.participantDueDate.setDueDate(val)
  }

  @action
  public setDueTime(hour: number, minute: number) {
    const newDate = moment(this.dueDate).hour(hour).minute(minute)
    this.setDueDate(newDate.toDate())
  }
}
