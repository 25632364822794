import { RootStore } from '../../../stores/RootStore'
import { computed, action } from 'mobx'
import { WidgetVM } from './WidgetVM'
import { SurveyResponse } from '../../aggregate/SurveyResponse'
import moment from 'moment'
import { AudienceMember } from '../../../audience-members/aggregate/AudienceMember'
import { AGGridRowVM } from '../../../shared/ag-grid/AGGridRowVM'

export class RowVM extends AGGridRowVM<SurveyResponse> {
  constructor(rootStore: RootStore, listVM: WidgetVM, response: SurveyResponse) {
    super(rootStore, response, listVM)
  }

  @computed
  public get objectId(): string {
    return this.aggregate.objectId
  }

  @computed
  public get userSurveyId(): string {
    return this.aggregate.userSurveyId
  }

  @computed
  public get name(): string {
    return this.aggregate.name
  }

  @computed
  public get respondedAtFormatted(): string {
    const s = this.rootStore.localizationStore.lzStrings.surveys
    return moment(this.aggregate.respondedAtAsDate).format(s.responded_at_date_format)
  }

  @computed
  public get fromUser(): AudienceMember {
    return this.rootStore.audienceMembersStore.getUser(this.aggregate.publishedByUserId)
  }

  @computed
  public get fromUserId(): string {
    if (!this.aggregate.publishedByUserId) return ''
    return this.aggregate.publishedByUserId
  }

  @computed
  public get fromUserName(): string {
    if (!this.fromUser) return '--'
    return this.fromUser.name
  }

  @computed
  public get fromUserIconURL(): string {
    if (!this.fromUser) return undefined
    return this.fromUser.iconURL
  }

  @computed
  public get questionsCount(): number {
    if (!this.aggregate.questionsCount) return 0
    return this.aggregate.questionsCount
    // if (this.aggregate.questions) return this.aggregate.questions.length
    // return 0
  }

  @action
  public openUserSurvey() {
    let url
    if (this.aggregate.showResults) {
      url = '/surveyresults/view/' + this.aggregate.surveyId + '/' + this.userSurveyId
    } else {
      url = '/surveyresponses/view/' + this.objectId
    }
    this.rootStore.appStore.router.push(url)
  }
}
