import React, { FC } from 'react'
import { observer, inject } from 'mobx-react'
import './SurveyOptions.scss'
import { SurveysStore } from 'src/app/surveys/store/SurveysStore'
import { FormControl, MenuItem, Select, Switch, TextField } from '@material-ui/core'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import TimePicker from '../../../../shared/time-picker/TimePicker'
import { KeyboardDatePicker } from '@material-ui/pickers'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { LabelsStore } from '../../../../labels/store/LabelsStore'

interface Props {
  surveysStore?: SurveysStore
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
}

const DueDate: FC<Props> = ({ surveysStore, localizationStore, labelsStore }) => {
  const surveyEditVM = surveysStore.viewModels.editVM
  const vm = surveyEditVM.surveyOptionsVM
  if (!surveyEditVM) return null
  if (!vm) return null
  const s = localizationStore.lzStrings.surveys

  const renderDueAfterRow = () => {
    if (!surveyEditVM.dueAfterEnabled) return null
    if (surveyEditVM.dueDateMode !== 'period') return null
    return (
      <div className='due-after-row'>
        <div className='primary-text textfield-label'>{`${labelsStore.getLabelByLanguageAndFor(
          'pulse'
        )} ${s.due_in}`}</div>
        <TextField
          value={surveyEditVM.dueAfterValue}
          onChange={(e) => surveyEditVM.setDueAfterValue(e.target.value as string)}
          variant='outlined'
        />
        <FormControl variant='outlined' className='survey-due-in-select'>
          <Select
            value={surveyEditVM.dueAfterPeriod}
            onChange={(e) => {
              surveyEditVM.setDueAfterPeriod(e.target.value as string)
            }}
          >
            <MenuItem value={'hour'}>{s.hours}</MenuItem>
            <MenuItem value={'minute'}>{s.minutes}</MenuItem>
            <MenuItem value={'day'}>{s.days}</MenuItem>
            <MenuItem value={'week'}>{s.weeks}</MenuItem>
            <MenuItem value={'month'}>{s.months}</MenuItem>
          </Select>
        </FormControl>
      </div>
    )
  }

  const renderAutoCancelButtons = () => {
    if (!surveyEditVM.survey.dueAfterEnabled) return null
    return (
      <div className='section'>
        <div className='section-content'>
          <div className='primary-text'>{s.set_auto_close}</div>
          <Switch
            checked={surveyEditVM.autoCancelWhenDue}
            onChange={() => surveyEditVM.toggleAutoCancel()}
          />
          {surveyEditVM.autoCancelWhenDue ? 'On' : 'Off'}
        </div>
      </div>
    )
  }

  const renderDueDateButtons = () => {
    if (!surveyEditVM.survey.dueAfterEnabled) return null
    return (
      <div className='section'>
        <div className='section-content'>
          <ToggleButtonGroup
            value={surveyEditVM.dueDateMode}
            exclusive
            onChange={(e, value) => surveyEditVM.setDueDateMode(value)}
          >
            <ToggleButton value={'specific'}>{s.set_specific_due_date}</ToggleButton>
            <ToggleButton value={'period'}>{s.set_due_after_period}</ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
    )
  }

  const renderSurveyDue = () => {
    if (!surveyEditVM.survey.dueAfterEnabled) return null
    if (surveyEditVM.dueDateMode !== 'specific') return null
    return (
      <>
        <div className='primary-text textfield-label'>{s.due_on_specific_date_and_time}</div>
        <div className='due-at-specific-date-time-content'>
          <div>
            <div className='secondary-text textfield-label'>Due Date</div>
            <KeyboardDatePicker
              disablePast={surveyEditVM.isNewSurvey ? true : false}
              inputVariant='outlined'
              format='MM/dd/yyyy'
              margin='none'
              disableToolbar
              className='deliver-on-specific-date-picker'
              autoOk
              error={!surveyEditVM.dueDateValid}
              helperText={null}
              id='date-picker-inline6'
              value={surveyEditVM.dueDateDate}
              onChange={(e) => surveyEditVM.setDueDate(e)}
            />
          </div>
          <div>
            <div className='secondary-text textfield-label'>Time</div>
            <TimePicker
              error={false}
              value={surveyEditVM.dueDateTime}
              key={String(surveyEditVM.vmCreatedAt)}
              onChange={(hr, min) => surveyEditVM.setDueTime(hr, min)}
            />
          </div>
          <div>
            <div className='secondary-text textfield-label'>Time Zone</div>
            <Autocomplete
              options={surveyEditVM.timeZones}
              getOptionLabel={(e) => e.label}
              value={surveyEditVM.dueTimeZone}
              onChange={(e, val) => surveyEditVM.setDueTimeZone(val)}
              renderInput={(params) => (
                <TextField {...params} placeholder='Select Time Zone' variant='outlined' />
              )}
              className='time-zone-autocomplete'
            />
          </div>
        </div>
      </>
    )
  }

  return (
    <div className='section due-date-content'>
      <div className='primary-text'>{`${s.set_a_due_date} ${labelsStore.getLabelByLanguageAndFor(
        'pulse'
      )}`}</div>
      <div className='section-content'>
        <Switch
          checked={surveyEditVM.dueAfterEnabled}
          onChange={() => surveyEditVM.toggleDueAfterEnabled()}
        />
        {surveyEditVM.dueAfterEnabled ? 'On' : 'Off'}
        {renderAutoCancelButtons()}
        {renderDueDateButtons()}
        {renderDueAfterRow()}
        {renderSurveyDue()}
      </div>
    </div>
  )
}

export default inject('surveysStore', 'localizationStore', 'labelsStore')(observer(DueDate))
