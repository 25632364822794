import { RootStore } from '../../../stores/RootStore'
import { observable, autorun, computed, action } from 'mobx'
import { Worksheet } from '../../aggregate/Worksheet'

export class WorksheetsListRowVM {
  private rootStore: RootStore
  private worksheet: Worksheet

  constructor(rootStore: RootStore, worksheet: Worksheet) {
    this.rootStore = rootStore
    this.worksheet = worksheet
  }

  @computed
  public get objectId() {
    return this.worksheet.objectId
  }

  @computed
  public get ownerId() {
    return this.worksheet.owner
  }

  @computed
  public get worksheetOwner() {
    const foundUser = this.rootStore.audienceMembersStore.getUser(this.worksheet.owner)
    return foundUser
  }

  @computed
  public get ownerName() {
    if (!this.worksheetOwner) return ''
    return this.worksheetOwner.name
  }

  @computed
  public get ownerIconURL() {
    if (!this.worksheetOwner) return ''
    return this.worksheetOwner.iconURL
  }

  @computed
  public get name() {
    return this.worksheet.name
  }

  @computed
  public get chartTypeId() {
    return this.worksheet.chartTypeId
  }

  @computed
  public get tableauWorkbook() {
    return this.worksheet.tableauWorkbook
  }

  @computed
  public get tableauSiteName() {
    return this.worksheet.tableauSiteName
  }

  @computed
  public get tableauWorksheetDisplayName() {
    return this.worksheet.tableauWorksheetDisplayName
  }

  @computed
  public get createdAt() {
    return this.worksheet.createdAt
  }

  @computed
  public get updatedAt() {
    return this.worksheet.updatedAt
  }

  @computed
  public get isDeleted() {
    return this.worksheet.isDeleted
  }

  @computed
  public get forSurveys() {
    return this.worksheet.forSurveys
  }

  @computed
  public get showTabs() {
    return this.worksheet.showTabs
  }

  @computed
  public get visibleTo() {
    const permissions = []
    this.worksheet.visibleTo.forEach(participant => {
      if (participant.name) {
        if (participant.type === 'group') permissions.push('Group: ' + participant.name)
        else if (participant.type === 'role') permissions.push('Role: ' + participant.name)
        else if (participant.type === 'user') permissions.push(participant.name)
      }
    })
    const result = permissions.sort((a, b) => (a < b ? -1 : 0)).join(', ')
    if (result === '') return '--'
    return result
  }

  @action
  public openWorksheet() {
    this.rootStore.appStore.router.push('/worksheets/edit/' + this.objectId)
  }
}
