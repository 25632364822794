import { Option } from '../../../../questions/aggregates/Option'

export class OptionRowVM {
  private option: Option

  constructor(opt: Option) {
    this.option = opt
  }

  public get rank(): number {
    return this.option.rank
  }

  public get text(): string {
    if (!this.option.text) return '(blank)'
    return this.option.text
  }

  public get emoji(): string {
    return this.option.emoji
  }

  public get id(): string {
    return this.option.id
  }
}
