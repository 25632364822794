import React from 'react'
import { observer } from 'mobx-react'
import HTMLRenderer from 'react-html-renderer'
import { QuestionVM } from '../../view-models/QuestionVM'
import { Card, CardContent, Avatar } from '@material-ui/core'
import { UserSurveyTakeVM } from '../../view-models/UserSurveyTakeVM'
import InfoIcon from '@material-ui/icons/Info'
import isMobile from '../../../../utils/isMobile'
import './QuestionRow.scss'

interface Props {
  takeVM: UserSurveyTakeVM
  question: QuestionVM
  index: number
}

const InfoTextRow: React.FC<Props> = ({ question, takeVM }) => {
  const blurredClass = question.blurred ? 'blurred' : ''

  const getMobileClassNameForRow = () => {
    if (!isMobile) return ''
    else if (isMobile && blurredClass === '') return 'is-mobile'
    return ' is-mobile'
  }

  const renderQuestionAvatar = () => {
    if (isMobile) return
    return (
      <>
        <Avatar className={question.isCurrentQuestion ? 'indicator active' : 'indicator'}>
          <InfoIcon fontSize='large' />
        </Avatar>
        <div className='hr' />
      </>
    )
  }  

  return (
    <div id='QuestionRow' className={`${blurredClass}${getMobileClassNameForRow()}`}>
      {renderQuestionAvatar()}
      <Card elevation={0}>
        <CardContent>
          <div
            className='question-row-title'
            style={{ marginBottom: question.type === 'infoText' ? 0 : 10 }}
          >
            <HTMLRenderer html={question.html} />
          </div>
        </CardContent>
      </Card>
    </div>
  )
}

export default observer(InfoTextRow)
