import React from 'react'
import './NotificationsWidget.scss'
import { Popover, Typography, Grid, Link, Card, Button, CircularProgress } from '@material-ui/core'
import { AppStore } from '../../stores/AppStore'
import { Observer, inject, observer } from 'mobx-react'
import LocalizationStore from '../../localization/LocalizationStore'
import VirtualList from 'react-tiny-virtual-list'
import { RowType } from '../types/RowType'
import GoalsIcon from '@material-ui/icons/TrackChanges'
import TasksIcon from '@material-ui/icons/Assignment'
import SurveysIcon from '@material-ui/icons/LiveHelpOutlined'
import CalendarIcon from '@material-ui/icons/CalendarTodayRounded'
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded'
import { NotificationRowVMType } from '../types/NotificationRowVMType'

interface Props {
  appStore?: AppStore
  localizationStore?: LocalizationStore
}

const NotificationWidget: React.FC<Props> = ({ appStore, localizationStore }) => {
  const { notificationsWidgetVM: vm } = appStore
  const { notifications_widget: strings } = localizationStore.lzStrings
  if (!vm) return null

  const title = () => {
    return (
      <Grid item xs={12} className='widget-title'>
        <Typography className='title-text' variant='body1'>
          {strings.notifications}
        </Typography>
        {renderMarkAllButton()}
        {renderSpinner()}
      </Grid>
    )
  }

  const renderMarkAllButton = () => {
    if (vm.markingAllRead) return null
    return (
      <Button
        className={vm.rows.length > 0 ? 'mark-all-btn' : 'mark-all-btn hide'}
        variant='text'
        onClick={() => vm.markAllAsRead()}
      >
        Mark All as Read
      </Button>
    )
  }

  const renderSpinner = () => {
    if (!vm.markingAllRead) return null
    return <CircularProgress color='secondary' className={'read-spinner'} />
  }

  const renderNoNotifications = () => {
    if (vm.rows.length > 0) return null
    return (
      <Typography className='no-notifications'>
        {strings.no_notifications}
        <CheckCircleRoundedIcon className='done-icon' />
      </Typography>
    )
  }

  const icon = (type: RowType) => {
    let icon = null
    if (type === 'userGoal') icon = <GoalsIcon />
    if (type === 'userTask') icon = <TasksIcon />
    if (type === 'userSurvey' || type === 'surveyResponse') icon = <SurveysIcon />
    if (type === 'userEvent') icon = <CalendarIcon />
    return <div className='notification-type-icon-container'>{icon}</div>
  }

  const row = (row: NotificationRowVMType, index, style) => {
    return (
      <Grid
        item
        xs={12}
        style={style}
        key={index}
        className={row.hasDuedate ? 'card-wrapper due' : 'card-wrapper'}
      >
        <Grid container direction='row' className='notification-card'>
          <Grid item xs={8} className='left-card'>
            <Typography variant='body1' className='notification-title'>
              {row.title}
            </Typography>
            <Typography variant='body2' className='notification-created-at'>
              {row.createdAt}
            </Typography>
            {icon(row.type)}
          </Grid>
          <Grid item xs={4} className='right-card'>
            <Typography variant='body1' className='due-date'>
              {row.dueDate && `Due ${row.dueDate}`}
            </Typography>
            <Button
              onClick={() => {
                vm.toggleNotificationsWidgetOpen()
                row.onAction()
              }}
              variant='contained'
            >
              {row.buttonText}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const notificationsList = () => {
    if (vm.rows.length === 0) return null
    return (
      <Grid item xs={12}>
        <VirtualList
          height={500}
          itemCount={vm.rows.length}
          itemSize={100}
          renderItem={({ index, style }) => (
            <Observer key={vm.rows[index].id}>{() => row(vm.rows[index], index, style)}</Observer>
          )}
        />
      </Grid>
    )
  }
  return (
    <Popover
      anchorEl={vm.anchorEl}
      open={vm.notificationsWidgetOpen}
      onClose={() => vm.clearAnchorEl()}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Card id='NotificationsNavWidget'>
        <Grid container>
          {title()}
          {notificationsList()}
          {renderNoNotifications()}
        </Grid>
      </Card>
    </Popover>
  )
}

export default inject('appStore', 'localizationStore')(observer(NotificationWidget))
