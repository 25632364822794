import React, { useEffect, useRef } from 'react'
import Dropzone from 'react-dropzone'
import { inject, observer, Observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { Grid, InputLabel, DialogContentText, Paper, Button, Typography } from '@material-ui/core'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import './MediaItemFileUploader.scss'
import { MediaUploadPickerVM } from '../../view-models/media-upload-picker/MediaUploadPickerVM'

interface MediaItemsFileUploaderProps {
  localizationStore?: LocalizationStore
  mediaUploadPickerVM: MediaUploadPickerVM
}

const MediaItemsFileUploader: React.FC<MediaItemsFileUploaderProps> = ({
  localizationStore,
  mediaUploadPickerVM,
}) => {
  const { lzStrings } = localizationStore
  const { mediaDrawerVM: VM } = mediaUploadPickerVM
  if (!VM) return null
  const renderMediaPreview = () => {
    if (!VM.mediaItem) return null
    if (VM.type === 'image') return <img className='media-item-preview' src={VM.fileUrl} />
    if (VM.type === 'video') return <video className='media-item-preview' src={VM.fileUrl} />
  }

  const renderMediaUploadButton = () => {
    if (VM.fileUrl) return null
    if (VM.widget && VM.widget.isShowing()) return null
    return <Button onClick={() => VM.toggleMediaUploadModal()}>Upload Media</Button>
  }

  const renderUploadErrorMessage = () => {
    if (!VM.showUploadError) return null
    return (
      <Typography variant='h6' className='error-message'>
        File type is not supported. <br></br>Acceptable formats: jpeg, png, gif, bmp, mp4
      </Typography>
    )
  }

  return (
    <div id='MediaFileUploader'>
      {/* <form> */}
      <Grid item xs={12} style={{ padding: '0 8px' }}>
        <Grid container justifyContent='space-between' alignItems='center'>
          {renderMediaUploadButton()}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {renderMediaPreview()}
        {renderUploadErrorMessage()}
      </Grid>
      {/* </form> */}
    </div>
  )
}

export default inject('localizationStore')(observer(MediaItemsFileUploader))
