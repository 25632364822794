import React from 'react'

//MobX Stores
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'

//MaterialUI Components
import { Grid } from '@material-ui/core'

interface IWidgetTimelineHeader {
  localizationStore?: LocalizationStore
}

const WidgetTimelineHeader: React.FC<IWidgetTimelineHeader> = ({ localizationStore }) => {
  const { lzStrings } = localizationStore

  return (
    <Grid item xs={12} container spacing={0} className='headerRow'>
      <Grid item xs={1} className='date-column'>
        <span className='timelineHeader'>{lzStrings.pendingUserTasksWidget.due}</span>
      </Grid>
      <Grid item xs={11}>
        <span className='timelineHeader details-column'>
          {lzStrings.pendingUserTasksWidget.task_details}
        </span>
      </Grid>
      {/* <Grid item xs={2}>
        <span className='timelineHeader'>{lzStrings.pendingUserTasksWidget.discipline}</span>
      </Grid>
      <Grid item xs={2}>
        <span className='timelineHeader'>{lzStrings.pendingUserTasksWidget.creator}</span>
      </Grid>
      <Grid item xs={2}>
        <span className='timelineHeader'>{lzStrings.pendingUserTasksWidget.action}</span>
      </Grid> */}
    </Grid>
  )
}
export default inject('localizationStore')(observer(WidgetTimelineHeader))
