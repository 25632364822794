import Parse from 'parse'
import { action, observable, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Organization } from '../aggregate/Organization'
import { OrganizationsService } from '../service/OrganizationsService'
import { MediaItemsService } from '../../media-items/service/MediaItemsService'
import defaultLogo from '../../../assets/img/logos/RippleWorx-BlueWhiteGrey.png'
import { Attachment } from '../../upload/aggregate/Attachment'
import { OrganizationEditVM } from './OrganizationEditVM'
import { TenantLinkEditVM } from './TenantLinkEditVM'
import { TenantLink } from '../aggregate/TenantLink'
import { TenantLinkRowVM } from './TenantLinkRowVM'

export class OrganizationLinksVM {
  private rootStore: RootStore


  constructor(rootStore: RootStore, editVM: OrganizationEditVM) {
    this.rootStore = rootStore
    this.editVM = editVM
  }

  @observable public editVM: OrganizationEditVM = null
  @observable public linkEditVM: TenantLinkEditVM = null

  @computed
  public get currentTabIndex(): number {
    return this.editVM.currentTabIndex
  }

  @computed
  public get tenantLinks(): TenantLinkRowVM[] {
    return this.editVM.organization.tenantLinks.map((e) => { return new TenantLinkRowVM(this.rootStore, this.editVM, e)})
  }

  @action
  public loadLinkEditVM(link?: TenantLink) {
    if (!link) return this.linkEditVM = new TenantLinkEditVM(this.rootStore, this, TenantLink.create())
    return this.linkEditVM = new TenantLinkEditVM(this.rootStore, this, link)
  }

  @action
  public clearLinkEditVM() {
    this.linkEditVM = null
  }

  @action
  public addLinkToOrg(link: TenantLink) {
    this.editVM.addLinkToOrg(link)
  }

  @action
  public removeLinkFromOrg(link: TenantLink) {
    this.editVM.removeLinkFromOrg(link)
  }

}
