import React, { useRef, useEffect } from 'react'
import { observer } from 'mobx-react'
import { ParticipantsSelectVM } from '../../../../participants-select/view-models/ParticipantsSelectVM'
import { Chip, Grid } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import Avatar, { AvatarUserMinimum } from '../../../../shared/Avatar'
import VirtualList from 'react-tiny-virtual-list'
import { ParticipantVM } from '../../../../participants-select/view-models/ParticipantVM'
import { AnonymousParticipantVM } from '../../../../participants-select/view-models/AnonymousParticipantVM'
import User from 'src/app/user/aggregate/User'
import ArchivedLabel from './../../../../shared/ArchivedLabel'
import styles from './../../../../shared/Avatar.scss'

interface Props {
  participantsSelectVM: ParticipantsSelectVM
}
const CustomPublishGroupParticipants: React.FC<Props> = ({ participantsSelectVM }) => {
  const virtualListRef = useRef<any>(null)
  useEffect(
    () => participantsSelectVM.setVirtualListParent('CustomPublishGroupParticipants_VirtualList'),
    [participantsSelectVM.isVisible]
  )
  useEffect(() => virtualListRef.current.forceUpdate(), [participantsSelectVM?.participants])

  const vm = participantsSelectVM

  const renderAvatar = (participant) => {
    if (participant.type !== 'user' && participant.type !== 'contact') return null
    return (
      <Avatar
        user={participant as User | AvatarUserMinimum}
        size={15}
        style={{ marginLeft: styles.marginLeft, fontSize: styles.fontSize }}
        showOnlyFirstInitial
        isArchived={participant.isArchived}
      />
    )
  }

  const renderRow = (idx, style) => {
    const chips = []
    for (let row = 0; row < vm.filteredParticipantsCount; row++) {
      const nextPart = vm.filteredParticipants[row]
      chips.push(renderChip(nextPart))
    }

    return (
      <Grid
        container
        style={{ width: '100%' }}
        key={'row' + idx.toString() + vm.filteredParticipantsCount.toString()}
        direction='row'
        spacing={1}
        alignItems='center'
        justifyContent='flex-start'
      >
        {chips}
      </Grid>
    )
  }

  const renderChip = (participant: AnonymousParticipantVM | ParticipantVM) => {
    if (!participant) return
    return (
      <Grid item key={'grid-' + 'chp' + participant.key}>
        <Chip
          disabled={vm.isReadOnly}
          size='small'
          avatar={renderAvatar(participant)}
          label={
            <div
              style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center' }}
            >
              <span style={participant.isArchived ? { fontStyle: styles.archivedFontStyle } : {}}>
                {participant.chipLabel}
              </span>
              {participant.isArchived ? <ArchivedLabel size='small' /> : undefined}
            </div>
          }
          key={'chp' + participant.key}
          onClick={() => vm.explodeParticipant((participant as ParticipantVM).id)}
          icon={participant.explodable ? <AddIcon /> : null}
        />
      </Grid>
    )
  }

  const renderSelectedItems = () => {
    return (
        <VirtualList
          ref={virtualListRef}
          height={60}
          width={'100%'}
          itemCount={1}
          key={vm.filteredParticipantsCount + vm.renderKey}
          itemSize={25}
          renderItem={({ index, style }) => renderRow(index, style)}
        />
    )
  }

  return <>{renderSelectedItems()}</>
}

export default observer(CustomPublishGroupParticipants)
