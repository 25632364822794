import { observable, computed, action } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Question } from '../aggregates/Question'
import { MediaItem } from '../../media-items/aggregate/MediaItem'
import { Category } from '../../categories/aggregate/Category'
import { QuestionType } from '../types/QuestionTypes'
import { OptionForm } from '../forms/OptionsForm'
import { MediaItemForm } from '../forms/MediaItemForm'
import { Icon } from '../../surveys/models/Icon'
import { MediaLibraryVM } from '../../media-items/view-models/media-library/MediaLibraryVM'
import SurveyType from '../../survey-types/store/aggregate/SurveyType'
import { MediaUploadPickerVM } from '../../media-items/view-models/media-upload-picker/MediaUploadPickerVM'
import { RatingDisplayType } from 'src/app/surveys/types/RatingDisplayType'
import { CategoryPickerVM } from '../../shared/category-picker/CategoryPickerVM'
import { IQuestionDTO } from '../dtos/IQuestionDTO'
import { QuestionsService } from '../services/QuestionsService'

export class QuestionEditVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, questionId?: string) {
    this.rootStore = rootStore
    this.rootStore.mediaItemsStore.mediaLibraryVM = new MediaLibraryVM(rootStore)
    this.categoryPickerVM = new CategoryPickerVM(this.rootStore)
    this.media = new MediaItemForm(this.rootStore)
    this.mediaUploadPickerVM = new MediaUploadPickerVM(
      this.rootStore,
      (id) => this.setSelectedMediaItem(id),
      true
    )
    if (!questionId) this.setupForNew()
  }

  private setupForNew() {
    this.questionType = 'emoji'
    this.addDefaultEmojiOptions()
    this.isLoading = false
    this.isNewQuestion = true
  }

  public loadData(question: Question, isNewQuestion?: boolean) {
    if (isNewQuestion) this.isNewQuestion = true
    this.objectId = question.objectId
    this.title = question.title
    this.categoryId = question.categoryId
    this.categoryPickerVM.setAppliedCategory(this.categoryId)
    this.maximumRequired = question.maximumRequired
    this.minimumRequired = question.minimumRequired
    this.numberStepRequired = question.numberStepRequired
    this.maximumValueAllowed = String(question.maximumValueAllowed)
    this.minimumValueAllowed = String(question.minimumValueAllowed)
    this.decimalsAllowed = String(question.decimalsAllowed)
    this.defaultValueRequired = question.defaultValueRequired
    this.defaultValueNumeric = String(question.defaultValueNumeric)
    this.numberStep = String(question.numberStep)
    this.surveyTypeId = question.surveyTypeId
    this.questionType = question.type
    this.options = question.options.map((opt) => new OptionForm(this.rootStore, this, opt))
    this.media = new MediaItemForm(this.rootStore, question.media)
    this.required = question.required
    this.selectedMediaItemId = question.media.objectId
    this.isLoading = false
    this.isDropdownMultiSelect = question.isDropdownMultiSelect
    this.placeholder = question.placeholder
    if (this.questionType === 'rating' || this.questionType === 'starRating')
      this.ratingDisplay = question.ratingDisplay ? question.ratingDisplay : 'horizontal'
  }

  @observable public objectId: string = ''
  @observable public title: string = ''
  @observable public categoryId: string = ''
  @observable public surveyTypeId: string = ''
  @observable public questionType: QuestionType = null
  @observable public options: OptionForm[] = []
  @observable public media: MediaItemForm = null
  @observable public required: boolean = true
  @observable public selectedMediaItemId: string = ''
  @observable public isNewQuestion: boolean = false
  @observable public isDirty: boolean = false
  @observable public toolTipOpen: boolean = false
  @observable public saveTried: boolean = false
  @observable public savePending: boolean = false
  @observable public saveSuccessful: boolean = false
  @observable public deleteSuccessful: boolean = false
  @observable public deleteDialogOpen: boolean = false
  @observable public ratingDisplay: RatingDisplayType = 'horizontal'
  @observable public decimalsAllowed: string = ''
  @observable public minimumValueAllowed: string = '0'
  @observable public maximumValueAllowed: string = '0'
  @observable public numberStep: string = '0'
  @observable public minimumRequired: boolean = false
  @observable public maximumRequired: boolean = false
  @observable public numberStepRequired: boolean = false
  @observable public defaultValueRequired: boolean = false
  @observable public defaultValueNumeric: string = '0'
  @observable public shown: boolean = true
  @observable public isLoading: boolean = true
  @observable public categoryPickerVM: CategoryPickerVM = null
  @observable public newQuestionJustSaved: boolean = false
  @observable public questionJustDeleted: boolean = false
  @observable public mediaUploadPickerVM: MediaUploadPickerVM
  @observable isDropdownMultiSelect: boolean = false
  @observable placeholder: string = 'Please Select'

  @action
  public setDecimalsAllowed(val: number) {
    this.decimalsAllowed = String(val)
  }

  @action
  public increaseDecimalsAllowed() {
    this.setDecimalsAllowed(Number(this.decimalsAllowed) + 1)
  }

  @action
  public decreaseDecimalsAllowed() {
    this.setDecimalsAllowed(Number(this.decimalsAllowed) - 1)
  }

  @action
  public increaseMinimumValueAllowed() {
    this.setMinimumValueAllowed(String(Number(this.minimumValueAllowed) + 1))
  }

  @action
  public decreaseMinimumValueAllowed() {
    this.setMinimumValueAllowed(String(Number(this.minimumValueAllowed) - 1))
  }

  @action
  public increaseMaximumValueAllowed() {
    this.setMaximumValueAllowed(String(Number(this.maximumValueAllowed) + 1))
  }

  @action
  public decreaseMaximumValueAllowed() {
    this.setMaximumValueAllowed(String(Number(this.maximumValueAllowed) - 1))
  }

  @action
  public increaseNumberStep() {
    this.setNumberStep(String(Number(this.numberStep) + 1))
  }

  @action
  public decreaseNumberStep() {
    this.setNumberStep(String(Number(this.numberStep) - 1))
  }

  @computed
  public get decimalRegex(): any {
    if (Number(this.decimalsAllowed) === 0) return /^[\d ()+-]+$/
    return /^[^A-Za-z]+$/
  }

  @action
  public setMinimumValueAllowed(val: string) {
    if (!this.decimalRegex.test(val)) return
    let decimals = 0
    const decimalIndex = val.lastIndexOf('.')
    if (decimalIndex) {
      decimals = val.length - decimalIndex - 1
    }
    if (decimalIndex === -1) {
      this.minimumValueAllowed = val
    }
    if (decimalIndex && decimals <= Number(this.decimalsAllowed)) {
      this.minimumValueAllowed = val
    }
  }

  @action
  public setMaximumValueAllowed(val: any) {
    if (!val) val = '0'
    let decimals = 0
    if (!this.decimalRegex.test(val)) return
    const decimalIndex = val.lastIndexOf('.')
    if (decimalIndex) {
      decimals = val.length - decimalIndex - 1
    }
    if (decimalIndex === -1) {
      this.maximumValueAllowed = val
    }
    if (decimalIndex && decimals <= Number(this.decimalsAllowed)) {
      this.maximumValueAllowed = val
    }
  }

  @computed
  public get minMaxValid(): boolean {
    if (this.questionType !== 'number') return true
    if (this.maximumValueAllowed < this.minimumValueAllowed) return false
    return true
  }

  @computed
  public get placeholderValid(): boolean {
    if (this.questionType !== 'dropdown') return true
    if (this.placeholder === undefined) return false
    if (!this.saveTried) return true
    if (!this.placeholder || this.placeholder === '') return false
    return true
  }

  @action
  public setNumberStep(val: string) {
    if (!this.decimalRegex.test(val)) return
    let decimals = 0
    const decimalIndex = val.lastIndexOf('.')
    if (decimalIndex) {
      decimals = val.length - decimalIndex - 1
    }
    if (decimalIndex === -1) {
      this.numberStep = val
    }
    if (decimalIndex && decimals <= Number(this.decimalsAllowed)) {
      this.numberStep = val
    }
  }

  @action
  public toggleMinimumRequired() {
    this.minimumRequired = !this.minimumRequired
  }

  @action
  public toggleMaximumRequired() {
    this.maximumRequired = !this.maximumRequired
  }

  @action
  public toggleNumberStepRequired() {
    this.numberStepRequired = !this.numberStepRequired
  }

  @action
  public increaseDefaultValueNumeric() {
    this.setDefaultValueNumeric(String(Number(this.defaultValueNumeric) + 1))
  }

  @action
  public decreaseDefaultValueNumeric() {
    this.setDefaultValueNumeric(String(Number(this.defaultValueNumeric) - 1))
  }

  @action
  public toggleDefaultValueRequired() {
    this.defaultValueRequired = !this.defaultValueRequired
  }

  @action
  public setDefaultValueNumeric(val: string) {
    if (!this.decimalRegex.test(val)) return
    let decimals = 0
    const decimalIndex = val.lastIndexOf('.')
    if (decimalIndex) {
      decimals = val.length - decimalIndex - 1
    }
    if (decimalIndex === -1) {
      this.defaultValueNumeric = val
    }
    if (decimalIndex && decimals <= Number(this.decimalsAllowed)) {
      this.defaultValueNumeric = val
    }
  }

  @action
  public setField(key: string, value: string | boolean) {
    this[key] = value
    this.isDirty = true
  }

  @action
  public toggleRequired() {
    this.required = !this.required
    this.isDirty = true
  }

  @action
  public clearSurveyType() {
    this.surveyTypeId = ''
  }

  @action
  public removeMedia() {
    this.media = new MediaItemForm(this.rootStore)
    this.selectedMediaItemId = ''
  }

  @action
  public setRatingDisplay(val) {
    this.ratingDisplay = val
  }

  @action
  public setQuestionType(optionType: QuestionType) {
    this.isDirty = true
    this.clearOptions()
    this.questionType = optionType
    switch (this.questionType) {
      case 'radio':
        this.addDefaultRadioOptions()
        break
      case 'check':
        this.addDefaultCheckOptions()
        break
      case 'order':
        this.addDefaultOrderOptions()
        break
      case 'rating':
        this.addDefaultRatingOptions()
        this.setRatingDisplay('horizontal')
        break
      case 'starRating':
        this.addDefaultStarRatingOptions()
        this.setRatingDisplay('horizontal')
        break
      case 'emoji':
        this.addDefaultEmojiOptions()
        break
      case 'yesNo':
        this.addDefaultYesNoOptions()
        break
      case 'number':
        this.questionType = 'number'
        this.addDefaultNumberOptions()
        break
      case 'text':
        this.addDefaultTextOptions()
        break
      case 'dropdown':
        this.addDefaultDropdownOptions()
        break
    }
  }

  @computed
  public get mediaPanelShown(): boolean {
    return this.mediaUploadPickerVM.showMediaUploadPicker
  }

  @computed
  public get surveyTypeName(): string {
    const type = this.rootStore.surveyTypesStore.getSurveyType(this.surveyTypeId)
    if (!type) return ''
    return type.name
  }

  @action
  public clearOptions() {
    this.questionType = null
    this.options = []
  }

  @action
  public showMediaPanel() {
    this.mediaUploadPickerVM.toggleShowMediaUploadPicker()
    if (this.media.objectId) this.mediaUploadPickerVM.selectMediaItem(this.media.objectId)
  }

  @action
  public hideMediaPanel() {
    this.mediaUploadPickerVM.toggleShowMediaUploadPicker()
  }

  @computed
  public get saveEnabled() {
    return this.isDirty && this.title && this.questionType
  }

  @computed
  public get deleteEnabled() {
    return !this.isNewQuestion
  }

  @computed
  public get labelError() {
    if (!this.saveTried) return false
    return !Boolean(this.title)
  }

  @computed
  public get categoryError() {
    return !Boolean(this.categoryId)
  }

  @computed
  public get decimalError(): boolean {
    if (this.questionType !== 'number') return false
    if (Number(this.decimalsAllowed) > 4) return true
    if (Number(this.decimalsAllowed) < 0) return true
    return false
  }

  @computed
  public get surveyTypeError() {
    return false
  }

  @computed
  public get questionTypeError() {
    if (this.saveTried && !this.questionType) return true
    return false
  }

  @computed
  public get optionError() {
    if (this.options.some((option) => !option.weightValid)) return true
    return false
  }

  @computed
  public get isOptionsQuestion() {
    if (
      Boolean(this.questionType) &&
      this.questionType !== 'text' &&
      this.questionType !== 'number'
    )
      return true
    return false
  }

  @computed
  public get canHaveOptions() {
    if (this.questionType === 'text') return false
    return true
  }

  @computed
  public get canAddMoreOptions() {
    switch (this.questionType) {
      case 'emoji':
        return true
      case 'radio':
        return true
      case 'check':
        return true
      case 'yesNo':
        return false
      case 'rating':
        return true
      case 'starRating':
        return true
      case 'text':
        return false
      case 'number':
        return false
      case 'order':
        return true
      case 'dropdown':
        return true
    }
  }

  @computed
  public get icon(): Icon {
    switch (this.questionType) {
      case 'emoji':
        return new Icon('far fa-smile', 'brown')
      case 'rating':
        return new Icon('fas fa-list-ol', 'orange')
      case 'starRating':
        return new Icon('fas fa-star', 'yellow')
      case 'radio':
        return new Icon('far fa-dot-circle', 'green')
      case 'check':
        return new Icon('far fa-check-square', 'blue')
      case 'yesNo':
        return new Icon('far fa-thumbs-up', 'purple')
      case 'text':
        return new Icon('fas fa-font', 'red')
      case 'dropdown':
        return new Icon('fas fa-caret-square-down', 'green')
      default:
    }
  }

  @action
  public setCategory(id: string) {
    this.categoryId = id ? id : ''
    this.categoryPickerVM.setAppliedCategory(this.categoryId)
  }

  @action
  public setTitle(val: string) {
    this.title = val
  }

  @computed
  public get category(): Category {
    if (!this.rootStore) return null
    return this.rootStore.categoriesStore.getCategory(this.categoryId)
  }

  @action
  public addOption() {
    this.options.push(new OptionForm(this.rootStore, this))
    this.setOptionRanks()
  }

  @action
  public setWatchPercentage(value) {
    this.toolTipOpen = true
    this.media.setWatchPercentage(value)
  }

  @action
  public moveOptionUp(idx: number) {
    const newIdx = idx - 1
    let optionObj = this.options.splice(idx, 1)
    this.options.splice(newIdx, 0, optionObj[0])
    this.setOptionRanks()
  }

  @action
  public moveOptionDown(idx: number) {
    const newIdx = idx + 1
    let optionObj = this.options.splice(idx, 1)
    this.options.splice(newIdx, 0, optionObj[0])
    this.setOptionRanks()
  }

  @action
  public deleteOption(idx: number) {
    this.options.splice(idx, 1)
    this.setOptionRanks()
  }

  @action
  addDefaultEmojiOptions() {
    this.addOption()
    this.options[0].text = 'Excellent'
    this.options[0].emoji = 'happy-1'
    this.addOption()
    this.options[1].text = 'Very Good'
    this.options[1].emoji = 'smile'
    this.addOption()
    this.options[2].text = 'Average'
    this.options[2].emoji = 'bored'
    this.addOption()
    this.options[3].text = 'Below Average'
    this.options[3].emoji = 'confused'
    this.addOption()
    this.options[4].text = 'Extremely Poor'
    this.options[4].emoji = 'unhappy'
  }

  addDefaultRatingOptions() {
    this.addOption()
    this.options[0].text = 'Very Poor'
    this.options[0].rating = 1
    this.options[0].value = 1
    this.addOption()
    this.options[1].text = 'Below Average'
    this.options[1].rating = 2
    this.options[1].value = 2
    this.addOption()
    this.options[2].text = 'Average'
    this.options[2].rating = 3
    this.options[2].value = 3
    this.addOption()
    this.options[3].text = 'Above Average'
    this.options[3].rating = 4
    this.options[3].value = 4
    this.addOption()
    this.options[4].text = 'Excellent'
    this.options[4].rating = 5
    this.options[4].value = 5
  }

  addDefaultStarRatingOptions() {
    this.addOption()
    this.options[0].text = 'Very Poor'
    this.options[0].rating = 1
    this.options[0].value = 1
    this.addOption()
    this.options[1].text = 'Below Average'
    this.options[1].rating = 2
    this.options[1].value = 2
    this.addOption()
    this.options[2].text = 'Average'
    this.options[2].rating = 3
    this.options[2].value = 3
    this.addOption()
    this.options[3].text = 'Above Average'
    this.options[3].rating = 4
    this.options[3].value = 4
    this.addOption()
    this.options[4].text = 'Excellent'
    this.options[4].rating = 5
    this.options[4].value = 5
  }

  addDefaultRadioOptions() {
    this.addOption()
    this.options[0].text = 'A'
    this.addOption()
    this.options[1].text = 'B'
    this.addOption()
    this.options[2].text = 'C'
    this.addOption()
    this.options[3].text = 'D'
    this.setOptionRanks()
  }

  addDefaultDropdownOptions() {
    this.addOption()
    this.options[0].text = 'Strongly Disagree'
    this.options[0].check = null
    this.addOption()
    this.options[1].text = 'Disagree'
    this.options[1].check = null
    this.addOption()
    this.options[2].text = 'Neutral'
    this.options[2].check = null
    this.addOption()
    this.options[3].text = 'Agree'
    this.options[3].check = null
    this.addOption()
    this.options[4].text = 'Strongly Agree'
    this.options[4].check = null
    this.setOptionRanks()
  }

  addDefaultCheckOptions() {
    this.addOption()
    this.options[0].text = 'Option one'
    this.options[0].check = null
    this.addOption()
    this.options[1].text = 'Option two'
    this.options[1].check = null
    this.addOption()
    this.options[2].text = 'Option three'
    this.options[2].check = null
    this.addOption()
    this.options[3].text = 'Option four'
    this.options[3].check = null
  }

  addDefaultOrderOptions() {
    this.addOption()
    this.options[0].text = 'Option one'
    this.options[0].check = null
    this.addOption()
    this.options[1].text = 'Option two'
    this.options[1].check = null
    this.addOption()
    this.options[2].text = 'Option three'
    this.options[2].check = null
    this.addOption()
    this.options[3].text = 'Option four'
    this.options[3].check = null
  }

  addDefaultTextOptions() {
    this.addOption()
    this.options[0].text = ''
  }

  addDefaultNumberOptions() {
    this.addOption()
    this.options[0].text = ''
    this.options[0].responseNumber = 0
  }

  addDefaultYesNoOptions() {
    this.addOption()
    this.options[0].text = 'Yes'
    this.addOption()
    this.options[1].text = 'No'
  }

  public setOptionRanks() {
    this.options.forEach((option, idx) => {
      option.setRank(idx + 1)
    })
  }

  @action
  public moveOption(index, newIndex) {
    const optionObj = this.options.splice(index, 1)
    this.options.splice(newIndex, 0, optionObj[0])
    this.setOptionRanks()
  }

  @action
  public setSelectedMediaItem(id: string) {
    this.isDirty = true
    this.selectedMediaItemId = this.mediaUploadPickerVM.selectedMediaItemId
    const foundMedia = this.rootStore.mediaItemsStore.getMediaItem(this.selectedMediaItemId)
    this.addMedia(foundMedia)
  }

  @action
  public addMedia(mediaObject: MediaItem) {
    const frm = new MediaItemForm(this.rootStore)
    frm.objectId = mediaObject.objectId
    frm.type = mediaObject.type
    frm.name = mediaObject.name
    frm.path = mediaObject.fileUrl
    this.media = frm
  }

  @action
  public toggleDropdownMultiSelect() {
    this.isDropdownMultiSelect = !this.isDropdownMultiSelect
  }

  @action
  public setPlaceholder(placeholder: string) {
    this.placeholder = placeholder
  }

  public toDTO(): IQuestionDTO {
    return {
      objectId: this.objectId,
      organizationId: this.rootStore.appStore.currentOrgId,
      categoryId: this.categoryId,
      title: this.title,
      type: this.questionType,
      surveyTypeId: this.surveyTypeId,
      options: this.options.map((e) => e.toDTO()),
      media: this.media.toDTO(),
      required: this.required,
      decimalsAllowed: Number(this.decimalsAllowed),
      minimumValueAllowed: Number(this.minimumValueAllowed),
      maximumValueAllowed: Number(this.maximumValueAllowed),
      defaultValueRequired: this.defaultValueRequired,
      defaultValueNumeric: Number(this.defaultValueNumeric),
      numberStep: Number(this.numberStep),
      minimumRequired: this.minimumRequired,
      maximumRequired: this.maximumRequired,
      numberStepRequired: this.numberStepRequired,
      ratingDisplay:
        this.questionType === 'rating' || this.questionType === 'starRating'
          ? this.ratingDisplay
          : '',
      isDropdownMultiSelect: this.isDropdownMultiSelect,
      placeholder: this.placeholder
    }
  }

  @action
  public closeDeleteDialog() {
    this.deleteDialogOpen = false
  }

  @action
  public openDeleteDialog() {
    this.deleteDialogOpen = true
  }

  @action
  public async deleteQuestion() {
    const svc = new QuestionsService(this.rootStore)
    const result = await svc.deleteCatalogQuestion(
      this.objectId,
      this.rootStore.appStore.currentOrgId
    )
    if (result) {
      this.deleteSuccessful = true
      this.closeModal()
      this.questionJustDeleted = true
    }
  }

  private get hasGeneralError() {
    if (this.labelError) return true
    if (this.surveyTypeError) return true
    if (this.questionTypeError) return true
    if (this.decimalError) return true
    if (!this.minMaxValid) return true
    if (this.optionError) return true
    if (!this.placeholderValid) return true
    return false
  }

  @computed
  public get saveButtonDisabled(): boolean {
    if (this.savePending) return true
    return false
  }

  @action
  public async saveQuestion() {
    this.saveTried = true
    if (this.hasGeneralError) return
    try {
      this.savePending = true
      const data = this.toDTO()
      const orgId = this.rootStore.appStore.currentOrgId
      const svc = new QuestionsService(this.rootStore)
      const result = await svc.saveCatalogQuestion(orgId, data)
      if (result) {
        this.savePending = false
        this.saveSuccessful = true
        this.saveTried = false
        if (!data.objectId) this.newQuestionJustSaved = true
        setTimeout(() => this.closeModal(), 500)
      }
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  @action
  public closeModal() {
    this.shown = false
  }

  @action
  public async copyQuestion(): Promise<QuestionEditVM> {
    return await this.rootStore.questionsStore.loadEditVM(this.objectId, true)
  }
}
