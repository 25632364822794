import React, { useRef, useEffect, FC, useState } from 'react'
import { OutlinedInput, MenuItem, Button, Menu, Tooltip } from '@material-ui/core'
import { observer } from 'mobx-react'
import { inject } from 'mobx-react'
import { SurveysStore } from '../../../store/SurveysStore'
import LocalizationStore from '../../../../localization/LocalizationStore'
import Select from '@material-ui/core/Select'
import './NotificationTemplatesEditor.scss'
import MuiConfirmDialog from '../../../../shared/MuiConfirmDialog'
import { Editor } from '@tinymce/tinymce-react'
import { TOOLBAR, PLUGINS } from '../../../../config/TinyMCEconfig'
import FileUploadModal from '../../../../upload/views/FileUploadModal'
import { FileTypeEnum } from '../../../../upload/enum/FileTypeEnum'
import { Attachment } from '../../../../upload/aggregate/Attachment'
import { LabelsStore } from '../../../../labels/store/LabelsStore'
import ErrorIcon from '@material-ui/icons/ErrorRounded'
import CMSItemsPickerDialog from '../../../../cms-items/views/dialogs/CMSItemsPickerDialog'
import { CMSItemsTypeEnum } from '../../../../cms-items/enum/CMSItemsTypeEnum'
import { OrganizationsStore } from '../../../../organizations/store/OrganizationsStore'

interface Props {
  localizationStore?: LocalizationStore
  surveysStore?: SurveysStore
  id?: string
  labelsStore?: LabelsStore
  organizationsStore?: OrganizationsStore
}

const NotificationTemplatesEditor: FC<Props> = ({
  surveysStore,
  localizationStore,
  id,
  labelsStore,
  organizationsStore
}) => {
  const surveyEditVM = surveysStore.viewModels.editVM
  const surveyOptionsVM = surveyEditVM.surveyOptionsVM
  const vm = surveyOptionsVM.notificationTemplatesEditorVM
  const s = localizationStore.lzStrings.surveys
  const editorRef: any = useRef()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  if (!vm) return null

  useEffect(() => {
    vm.setEditorRef(editorRef)
  }, [])

  const handleFieldSelect = (val: string) => {
    vm.currentTemplate.handleFieldSelect(val, editorRef)
  }

  const handleResetToDefault = () => {
    vm.resetToDefaultTemplate(editorRef)
    vm.toggleShowConfirmDialog()
  }

  const handleSubjectChange = (event) => {
    vm.currentTemplate.handleSubjectChange(event.target.value)
  }

  const renderConfirmDialog = () => {
    return (
      <MuiConfirmDialog
        open={vm.showConfirmDialog}
        confirmMessage={
          'Are you sure you would like to reset the currently selected email template to default?'
        }
        onConfirm={() => handleResetToDefault()}
        onClose={() => vm.toggleShowConfirmDialog()}
      />
    )
  }

  const renderTemplateSelect = () => {
    if (!vm.selectOptions) return null
    if (vm.selectOptions.length < 2) return null
    return (
      <div>
        <div className='primary-text'>{s.language}</div>
        <Select
          disabled={surveyEditVM.isReadOnly}
          id='notification-template-editor-select'
          className={'template-select'}
          value={vm.selectedOptionValue}
          onFocus={(e) => (surveyEditVM.isDirty = true)}
          onChange={(e) => vm.setSelectedOptionValue(e.target.value)}
          variant='outlined'
        >
          {vm.selectOptions.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </div>
    )
  }

  const renderSubjectInput = () => {
    if (!vm.currentTemplate) return null
    return (
      <div>
        <div className='primary-text'>{s.subject}</div>
        <OutlinedInput
          onFocus={(e) => (surveyEditVM.isDirty = true)}
          disabled={surveyEditVM.isReadOnly}
          id={`${id}-subject`}
          fullWidth
          labelWidth={0}
          autoComplete='off'
          value={vm.currentTemplate.subject}
          onChange={handleSubjectChange}
        />
      </div>
    )
  }

  const renderNameInput = () => {
    if (!vm.currentTemplate) return null
    if (surveyOptionsVM.tabIndex === 2 && surveyOptionsVM.publishedNotificationTabIndex !== 0)
      return null
    if (surveyOptionsVM.tabIndex === 3 && surveyOptionsVM.reminderTemplatesTabIndex !== 0)
      return null
    return (
      <div className='sender-display-name-input'>
        <div className='primary-text'>{s.sender_display_name}</div>
        <OutlinedInput
          onFocus={(e) => (surveyEditVM.isDirty = true)}
          disabled={surveyEditVM.isReadOnly}
          error={!surveyEditVM.nameValid}
          className={'template-select'}
          id={`${id}-name`}
          labelWidth={0}
          autoComplete='off'
          value={surveyEditVM.emailFromName}
          onChange={(e) => surveyEditVM.setEmailFromName(e.target.value)}
        />
      </div>
    )
  }

  const renderEmailInput = () => {
    if (!vm.currentTemplate) return null
    if (surveyOptionsVM.tabIndex === 2 && surveyOptionsVM.publishedNotificationTabIndex !== 0)
      return null
    if (surveyOptionsVM.tabIndex === 3 && surveyOptionsVM.reminderTemplatesTabIndex !== 0)
      return null
    return (
      <div>
        <div className='primary-text'>{s.sender_email_address}</div>
        <OutlinedInput
          onFocus={(e) => (surveyEditVM.isDirty = true)}
          disabled={surveyEditVM.isReadOnly}
          error={!surveyEditVM.emailValid}
          className={'template-select'}
          id={`${id}-email`}
          labelWidth={0}
          autoComplete='off'
          value={surveyEditVM.emailFromAddress}
          onChange={(e) => surveyEditVM.setEmailFromAddress(e.target.value)}
        />
      </div>
    )
  }

  const renderBodyInputErrorIcon = () => {
    let isValid = true
    if (surveyOptionsVM.tabIndex === 2) {
      if (surveyOptionsVM.publishedNotificationTabIndex > 0) return
      isValid = surveyEditVM.isNotificationTemplatesValid
    }
    if (surveyOptionsVM.tabIndex === 3) {
      if (surveyOptionsVM.reminderTemplatesTabIndex > 0) return
      isValid = surveyEditVM.isReminderTemplatesValid
    }
    if (isValid) return
    return (
      <Tooltip title={`${s.template_editor_link_invalid}`} placement='top'>
        <ErrorIcon className='icon-error' />
      </Tooltip>
    )
  }

  const renderBodyInput = () => {
    if (!vm.currentTemplate) return null
    return (
      <>
        <div className='notification-templates-editor-row'>
          <div className='editor-row-label'>
            <div className='primary-text'>{s.body}</div>
            {renderBodyInputErrorIcon()}
          </div>
          {renderInsertFieldSelect()}
        </div>
        <div className={!vm.isTemplateLinkValid ? 'editor-container error' : 'editor-container'}>
          <Editor
            key={vm.tinyMCEKey}
            disabled={surveyEditVM.isTemplateAndDisabled}
            value={vm.currentTemplate.body}
            onFocus={(e) => (surveyEditVM.isDirty = true)}
            init={vm.tinyMCEInit}
            outputFormat={vm.currentlyEditingChannel === 'EMAIL' ? 'html' : 'text'}
            onEditorChange={(e) => {
              vm.currentTemplate.setBody(e)
            }}
            ref={editorRef}
          />
        </div>
      </>
    )
  }

  const renderInsertFieldSelect = () => {
    return (
      <>
        <Button
          className='default-button'
          variant='outlined'
          onClick={(e) => setAnchorEl(e.currentTarget)}
          disabled={surveyEditVM.isReadOnly}
        >
          {s.insert_field}
        </Button>
        <Menu
          id='MoreMenu'
          elevation={2}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          <MenuItem
            onClick={() => handleFieldSelect('{surveyName}')}
          >{`${labelsStore.getLabelByLanguageAndFor('pulse')} Name`}</MenuItem>
          <MenuItem onClick={() => handleFieldSelect('{userName}')}>User Name</MenuItem>
          <MenuItem onClick={() => handleFieldSelect('{userFirstName}')}>First Name</MenuItem>
          <MenuItem onClick={() => handleFieldSelect('{userLastName}')}>Last Name</MenuItem>
          <MenuItem onClick={() => handleFieldSelect('{userJobTitle}')}>Job Title</MenuItem>
          <MenuItem
            onClick={() => handleFieldSelect('{surveyLink}')}
          >{`${labelsStore.getLabelByLanguageAndFor('pulse')} Link`}</MenuItem>
          <MenuItem
            onClick={() => handleFieldSelect('{surveyDue}')}
          >{`${labelsStore.getLabelByLanguageAndFor('pulse')} Due Date`}</MenuItem>
          <MenuItem onClick={() => handleFieldSelect('{publishedByUserName}')}>
            {s.published_by}
          </MenuItem>
          <MenuItem onClick={() => handleFieldSelect('{orgName}')}>Organization Name</MenuItem>
          <MenuItem onClick={() => handleFieldSelect('{taskName}')}>Task Name</MenuItem>
          <MenuItem onClick={() => handleFieldSelect('{taskDescription}')}>
            {s.task_description}
          </MenuItem>
        </Menu>
      </>
    )
  }

  const renderResetToDefault = () => {
    return (
      <Button
        disabled={surveyEditVM.isReadOnly}
        className='default-button'
        variant='outlined'
        onClick={() => vm.toggleShowConfirmDialog()}
      >
        Reset Template to Default
      </Button>
    )
  }

  const renderMediaUploadModal = () => {
    if (organizationsStore.currentOrganization?.hasCMSPicker) return renderCMSUploadModal()
    return renderStandardUploadModal()
  }

  const renderCMSUploadModal = () => {
    return (
      <CMSItemsPickerDialog
        key={`file-${vm.tinyMCEKey}`}
        allowMultiple={false}
        fileTypes={CMSItemsTypeEnum.IMAGES_VIDEOS}
        addAfterSave={(a: Attachment[]) => vm.addMediaToInfoTextFromComputer(a[0])}
        toggleShowModal={() => vm.toggleMediaUploadModal()}
        isOpen={vm.showMediaModal}
      />
    )
  }

  const renderStandardUploadModal = () => {
    return (
      <FileUploadModal
        key={`file-${vm.tinyMCEKey}`}
        allowMultiple={false}
        fileTypes={FileTypeEnum.IMAGES_VIDEOS}
        addAfterSave={(a: Attachment[]) => vm.addMediaToInfoTextFromComputer(a[0])}
        toggleShowModal={() => vm.toggleMediaUploadModal()}
        isOpen={vm.showMediaModal}
      />
    )
  }

  return (
    <div id='NotificationTemplatesEditor'>
      {renderMediaUploadModal()}
      <div className='notification-templates-editor-row'>
        {renderNameInput()}
        {renderEmailInput()}
        {renderTemplateSelect()}
      </div>
      {renderConfirmDialog()}
      {renderSubjectInput()}
      {renderBodyInput()}
      {renderResetToDefault()}
    </div>
  )
}

export default inject(
  'surveysStore',
  'localizationStore',
  'labelsStore',
  'organizationsStore'
)(observer(NotificationTemplatesEditor))
