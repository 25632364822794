import { RootStore } from '../../stores/RootStore'
import { action, computed, observable } from 'mobx'
import { AnonymousParticipantVM } from './AnonymousParticipantVM'
import { AudienceMember } from '../../audience-members/aggregate/AudienceMember'
import { ParticipantVM } from './ParticipantVM'
import { IParticipant } from '../interfaces/IParticipant'
import { IAnonymousParticipantDTO } from '../interfaces/IAnonymousParticipantDTO'
import { Event } from '../../events/aggregate/Event'
import { ClientRowVM } from './ClientRowVM'
import { EventParticipantGroupRowVM } from './EventParticipantGroupRowVM'
import { Role } from 'src/app/roles/aggregate/Role'
import { Group } from 'src/app/groups/aggregate/Group'
import { Client } from '../../clients/aggregate/Client'
import { UsersAGGridVM } from './UsersAGGridVM'
import { GroupsAGGridVM } from './GroupsAGGridVM'
import { RolesAGGridVM } from './RolesAGGridVM'
import { GroupedRowVM } from './GroupedRowVM'
import uuid from 'uuid/v4'
import { ClientsAGGridVM } from './ClientsAGGridVM'
import { UsersExpansionService } from '../services/UsersExpansionService'
import { IOrganizationUserDTO } from '../../organization-users/dtos/IOrganizationUserDTO'
import { IContactDTO } from '../../contacts/dtos/IContactDTO'
import { ContactsExpansionService } from '../services/ContactsExpansionService'
import { IEventParticipantGroup } from '../interfaces/IEventParticipantGroup'
import { ParticipantType } from '../types/ParticipantType'
import { ParseService } from '../../services/ParseService'

export class ParticipantsSelectVM {
  private rootStore: RootStore
  @observable private eventId: string = ''
  private filterTO: NodeJS.Timer
  private addParticipantCallback: Function

  constructor(
    rootStore: RootStore,
    showClients: boolean,
    showEmail: boolean,
    showRoles: boolean = true,
    showGroups: boolean = true,
    allowClientSelect: boolean = false,
    allowMultipleUsers: boolean = true,
    showEventUsers: boolean = false,
    audienceUserId: string = ''
  ) {
    this.rootStore = rootStore
    this.showClients = showClients
    this.showEmail = showEmail
    this.allowClientSelect = allowClientSelect
    this.showRoles = showRoles
    this.showGroups = showGroups
    this.allowMultipleUsers = allowMultipleUsers
    this.showEventUsers = showEventUsers
    this.audienceUserId = audienceUserId
  }

  @observable public participants: Array<ParticipantVM> = []
  @observable public anonymousParticipants: Array<AnonymousParticipantVM> = []
  @observable public anonymousEmail: string = ''
  @observable public anonymousPartnerChannel: Client = null
  @observable public anonymousAddTried: boolean = false
  @observable public filter: string = ''
  @observable public currentTab: number = 0
  @observable public showClients: boolean = false
  @observable public showEmail: boolean = false
  @observable public otherContactsOpen: boolean = false
  @observable public allowClientSelect: boolean = false
  @observable public showRoles: boolean = true
  @observable public showGroups: boolean = true
  @observable public showEventUsers: boolean = false
  @observable public allowMultipleUsers: boolean = true
  @observable public allEventUsersSelected: boolean = false
  @observable public yesEventUsersSelected: boolean = false
  @observable public yesMaybeEventUsersSelected: boolean = false
  @observable public noEventUsersSelected: boolean = false
  @observable public isArchived: boolean = false
  @observable public eventGroupsSelected: String[] = []
  @observable public isReadOnly: boolean = false
  @observable public anonymousFirstName: string = ''
  @observable public anonymousLastName: string = ''
  @observable public anonymousLanguage: string = 'en'
  @observable public saveAsContact: boolean = false
  @observable public virtualListParent: any = null
  @observable public virtualListContainerId: string = ''
  @observable public virtualListContainer: any = null
  @observable public excludeCurrentUser: boolean = false
  @observable public usersAGGridVM: UsersAGGridVM = null
  @observable public groupsAGGridVM: GroupsAGGridVM = null
  @observable public rolesAGGridVM: RolesAGGridVM = null
  @observable public clientsAGGridVM: ClientsAGGridVM = null
  @observable public isVisible: boolean = false
  @observable public openGroupedRow: GroupedRowVM
  @observable public openClientRow: ClientRowVM
  @observable public typedFilterText: string = ''
  @observable public pillsPerRow: number = 1
  @observable public height: number = undefined
  @observable public renderKey: number = 0
  @observable public deleteParticipantId: string = ''
  @observable public audienceUserId: string = ''

  @action
  public setPillsPerRow(val: number) {
    this.pillsPerRow = val
  }

  @action
  public setVisible() {
    this.isVisible = true
    this.setCurrentTab(this.currentTab)
  }

  @action
  public setHidden() {
    this.isVisible = false
    this.hideAllGrids()
  }

  public get userId() {
    if (this.audienceUserId) return this.audienceUserId
    return this.rootStore.appStore.currentUserId
  }

  @action
  public clearParticipants() {
    this.participants = []
    this.anonymousParticipants = []
  }

  @action
  public setExcludeCurrentUser() {
    this.excludeCurrentUser = true
  }

  @computed
  public get languageOptions(): any[] {
    // TODO: Move to a EmailTabVM?
    let options = []
    const org = this.rootStore.organizationsStore.currentOrganization
    if (org.hasEnglish) {
      options.push({ value: 'en', label: 'English' })
    }
    if (org.hasGerman) {
      options.push({ value: 'de', label: 'German' })
    }

    return options
  }

  @action
  public setReadOnly(val: boolean) {
    this.isReadOnly = val
  }

  @action
  public updateParticipants(list: IParticipant[]) {
    if (!list) return
    list.forEach((el) => {
      const ndx = this.participants.findIndex((p) => p.id === el.id)
      if (ndx > -1) {
        this.participants[ndx] = new ParticipantVM(this.rootStore, el)
      } else {
        this.participants.push(new ParticipantVM(this.rootStore, el))
      }
    })
  }

  @action
  public setParticipants(list: IParticipant[]) {
    if (!list) return
    this.participants = list.map((e) => new ParticipantVM(this.rootStore, e))
    if (!this.allowMultipleUsers) {
      let userList = list.filter((e) => e.type === 'user')
      if (userList.length) this.participants = [new ParticipantVM(this.rootStore, userList[0])]
      else this.participants = []
    }
  }

  @action
  public setAnonymousParticipants(list: IAnonymousParticipantDTO[]) {
    this.anonymousParticipants = list.map(
      (e) =>
        new AnonymousParticipantVM(
          e.token,
          e.email,
          e.firstName,
          e.lastName,
          e.language,
          e.isArchived
        )
    )
  }

  @action
  public setEvent(eventId: string) {
    this.eventId = eventId
    this.showEventUsers = true
  }

  @action
  public setVirtualListParent(parentEl: string) {
    // this.virtualListParent = undefined
    this.renderKey + this.renderKey + 1
    // setTimeout(() => {
    this.virtualListParent = document.getElementById(parentEl)
    // console.log('set')
    // }, 500)
  }

  @computed
  public get event(): Event {
    if (!this.eventId) return null
    return this.rootStore.eventsStore.getEvent(this.eventId)
  }

  @action
  public setHeight(val) {
    this.height = val
  }

  @action
  public setDeleteParticipantId(val: string) {
    this.deleteParticipantId = val
  }

  @computed
  public get tabBarHeight(): number {
    const tabBar = document.getElementById('virtualListHeader')
    if (!tabBar) return 65
    return tabBar.clientHeight + 20
  }

  @computed
  public get virtualListHeight(): number {
    if (!this.virtualListParent) {
      return 500
    }
    if (this.virtualListParent.offsetHeight === 0) {
      return 500
    }
    const num = this.virtualListParent.offsetHeight - this.tabBarHeight - 20
    return num
  }

  @computed
  public get clientLabel(): string {
    return this.rootStore.labelsStore.getLabelByLanguageAndFor('client')
  }

  @computed
  public get filteredRoles(): Role[] {
    if (!this.filter) return []
    return this.rootStore.rolesStore.roles.filter((role) =>
      role.name.toLowerCase().includes(this.filter.toLowerCase())
    )
  }

  @computed
  public get filteredGroups(): Group[] {
    if (!this.filter) return []
    return this.rootStore.groupsStore.groups.filter((group) =>
      group.name.toLowerCase().includes(this.filter.toLowerCase())
    )
  }

  @computed
  public get clientsList(): ClientRowVM[] {
    return this.rootStore.clientsStore.clients
      .filter((client) => client.name.toLowerCase().includes(this.filter.toLowerCase()))
      .map((e) => new ClientRowVM(this.rootStore, this, e))
      .sort((a, b) => (a.name < b.name ? -1 : 0))
  }

  @computed
  public get eventParticipantGroups(): EventParticipantGroupRowVM[] {
    return (
      [
        { key: 'goingUserIds', desc: 'All Users' },
        { key: 'goingYesUserIds', desc: 'Attending' },
        { key: 'goingYesAndMaybeUserIds', desc: 'Attending and Maybe Attending' },
        { key: 'goingNoUserIds', desc: 'Not Attending' },
      ] as any[]
    )
      .map(
        (e) =>
          ({
            objectId: e.key,
            name: e.desc,
            participantGroupType: e.key,
            type: 'from-event',
          } as IEventParticipantGroup)
      )
      .map((e) => new EventParticipantGroupRowVM(this.rootStore, this, e))
      .sort((a, b) => (a.name < b.name ? -1 : 0))
  }

  @action
  public toggleOtherContacts() {
    this.otherContactsOpen = !this.otherContactsOpen
  }

  @computed
  public get filterOtherContacts() {
    let otherContacts = 'Other Contacts'
    if (otherContacts.toLowerCase().includes(this.filter)) return true
    return false
  }

  @action
  public openUsersListModal(row: GroupedRowVM) {
    this.openGroupedRow = row
  }

  @computed
  public get isUsersListModalOpen(): boolean {
    return Boolean(this.openGroupedRow)
  }

  @action
  public closeUsersListModal() {
    this.openGroupedRow = null
  }

  @action
  public openContactsListModal(row: ClientRowVM) {
    this.openClientRow = row
  }

  @computed
  public get isContactsListModalOpen(): boolean {
    return Boolean(this.openClientRow)
  }

  @action
  public closeContactsListModal() {
    this.openClientRow = null
  }

  @computed
  public get allEventUsers(): any[] {
    const users = []
    this.event.userIds.forEach((userId) => {
      const foundUser = this.rootStore.audienceMembersStore.getAudienceMember(userId)
      users.push(foundUser)
    })
    return users
  }

  @computed
  public get goingYesUsers(): any[] {
    const users = []
    this.event.goingYesUserIds.forEach((userId) => {
      const foundUser = this.rootStore.audienceMembersStore.getAudienceMember(userId)
      users.push(foundUser)
    })
    return users
  }

  @computed
  public get goingYesAndMaybeUsers(): any[] {
    const users = []
    const userIds = this.event.goingYesUserIds.concat(this.event.goingMaybeUserIds)
    userIds.forEach((userId) => {
      const foundUser = this.rootStore.audienceMembersStore.getAudienceMember(userId)
      users.push(foundUser)
    })

    return users
  }

  @computed
  public get goingNoUsers(): any[] {
    const users = []
    this.event.goingNoUserIds.forEach((userId) => {
      const foundUser = this.rootStore.audienceMembersStore.getAudienceMember(userId)
      users.push(foundUser)
    })

    return users
  }

  @action
  public setFilter(val: string) {
    this.typedFilterText = val
    if (this.filterTO) clearTimeout(this.filterTO)
    this.filterTO = setTimeout(() => this.applyFilter(), 1000)
  }

  private applyFilter() {
    this.filter = this.typedFilterText
  }

  @computed
  public get hasFilter(): boolean {
    return Boolean(this.typedFilterText)
  }

  @action
  public setAnonymousEmail(val: string) {
    this.anonymousEmail = val
  }

  @action
  public setAnonymousFirstName(val: string) {
    this.anonymousFirstName = val
  }

  @action
  public setAnonymousLastName(val: string) {
    this.anonymousLastName = val
  }

  @action
  public setAnonymousLanguage(val) {
    this.anonymousLanguage = val
  }

  @action
  public toggleSaveAsContact() {
    this.saveAsContact = !this.saveAsContact
  }

  @computed
  public get partnerChannels(): Client[] {
    return this.rootStore.clientsStore.clients
  }

  @action
  public setAnonymousPartnerChannel(val) {
    this.anonymousPartnerChannel = val
  }

  private hideAllGrids() {
    if (this.groupsAGGridVM) this.groupsAGGridVM.hide()
    if (this.usersAGGridVM) this.usersAGGridVM.hide()
    if (this.rolesAGGridVM) this.rolesAGGridVM.hide()
    if (this.clientsAGGridVM) this.clientsAGGridVM.hide()
  }

  @action
  public setCurrentTab(idx) {
    this.currentTab = idx
    this.hideAllGrids()
    if (this.currentTab === 0) {
      if (!this.usersAGGridVM) this.usersAGGridVM = new UsersAGGridVM(this.rootStore, this)
      this.usersAGGridVM.show()
      if (this.height) this.usersAGGridVM.setHeight(this.height)
    }
    if (this.currentTab === 1) {
      if (!this.groupsAGGridVM) this.groupsAGGridVM = new GroupsAGGridVM(this.rootStore, this)
      this.groupsAGGridVM.show()
      if (this.height) this.groupsAGGridVM.setHeight(this.height)
    }
    if (this.currentTab === 2) {
      if (!this.rolesAGGridVM) this.rolesAGGridVM = new RolesAGGridVM(this.rootStore, this)
      this.rolesAGGridVM.show()
      if (this.height) this.rolesAGGridVM.setHeight(this.height)
    }
    if (this.currentTab === 3) {
      if (!this.clientsAGGridVM) this.clientsAGGridVM = new ClientsAGGridVM(this.rootStore, this)
      this.clientsAGGridVM.show()
    }
  }

  @action
  public toggleParticipant(audienceMember: AudienceMember) {
    if (this.isReadOnly) return
    const idx = this.participants.findIndex((e) => e.id === audienceMember.objectId)
    if (idx === -1) {
      this.addParticipantFromAudienceMember(audienceMember)
    } else {
      this.deleteParticipant(audienceMember.objectId)
    }
  }

  public setAddParticipantCallback(callback: Function) {
    this.addParticipantCallback = callback
  }

  @action
  public addParticipantFromAudienceMember(audienceMember: AudienceMember) {
    if (!this.allowMultipleUsers && this.participants.length >= 1) {
      this.participants = []
    }
    const frm = new ParticipantVM(this.rootStore)
    frm.id = audienceMember.objectId
    frm.name = audienceMember.name
    frm.type = audienceMember.type as ParticipantType
    frm.isArchived = audienceMember.isArchived
    if (!audienceMember.type) frm.type = 'client'
    this.participants.push(frm)
    console.log('im here', frm)
    if (this.addParticipantCallback) this.addParticipantCallback(frm)
  }

  private makeParticipant(id: string, name: string, type: ParticipantType, isArchived: boolean) {
    const part = new ParticipantVM(this.rootStore)
    part.id = id
    part.name = name
    part.type = type
    part.isArchived = isArchived
    return part
  }

  @action
  public addParticipant(id: string, name: string, type: ParticipantType, isArchived?: boolean) {
    if (!this.allowMultipleUsers && this.participants.length >= 1) this.participants = []
    const participant = this.makeParticipant(id, name, type, isArchived || false)
    this.participants.push(participant)
    if (this.addParticipantCallback) this.addParticipantCallback(participant)
  }

  @action
  public deleteParticipant(id: string) {
    const part = this.allParticipants.find((e) => e.id === id)
    if (part && part.type === 'anonymous') {
      const idx = this.anonymousParticipants.findIndex((e) => e.id === id)
      if (idx !== -1) this.anonymousParticipants.splice(idx, 1)
      return
    }
    const idx = this.participants.findIndex((e) => e.id === id)
    if (idx === -1) return
    this.participants.splice(idx, 1)
    this.setDeleteParticipantId(id)
  }

  @computed
  public get isValidEmail() {
    if (!this.anonymousAddTried) return true
    const re = new RegExp(/[^\s@]+@[^\s@]+\.[^\s@]+/i)
    return re.test(this.anonymousEmail)
  }

  @computed
  public get isValidFirstName() {
    if (this.anonymousAddTried && this.anonymousFirstName === '') return false
    return true
  }

  @computed
  public get isValidLastName() {
    if (this.anonymousAddTried && this.anonymousLastName === '') return false
    return true
  }

  @action
  public addAnonymousParticipant() {
    this.anonymousAddTried = true
    if (this.isValidEmail) {
      const participant = new AnonymousParticipantVM(
        uuid(),
        this.anonymousEmail,
        this.anonymousFirstName,
        this.anonymousLastName,
        this.anonymousLanguage,
        this.isArchived
      )
      this.anonymousParticipants.push(participant)
      if (this.saveAsContact) this.saveContact()
      this.anonymousEmail = ''
      this.anonymousFirstName = ''
      this.anonymousLastName = ''
      this.anonymousLanguage = ''
      this.anonymousPartnerChannel = null
      this.saveAsContact = false
      this.anonymousAddTried = false
    }
  }

  @action
  private async saveContact() {
    const name = this.anonymousFirstName + ' ' + this.anonymousLastName
    const contact = {
      userId: this.rootStore.appStore.currentUserId,
      organizationId: this.rootStore.appStore.currentOrgId,
      objectId: '',
      name: name.trim(),
      email: this.anonymousEmail.trim(),
      title: '',
      clientId: this.anonymousPartnerChannel ? this.anonymousPartnerChannel.objectId.trim() : null,
    }
    const pSvc = new ParseService()
    const result = await pSvc.saveContact(this.rootStore.appStore.currentOrgId, contact)
  }

  @action
  public getUsersByEventGroup(id) {
    if (id === 'goingUserIds') return this.allEventUsers
    if (id === 'goingYesUserIds') return this.goingYesUsers
    if (id === 'goingYesAndMaybeUserIds') return this.goingYesAndMaybeUsers
    if (id === 'goingNoUserIds') return this.goingNoUsers
  }

  @action
  public async explodeParticipant(id) {
    const thisParticipant = this.allParticipants.find((e) => e.id === id)
    if (!thisParticipant) return
    if (!thisParticipant.explodable) return
    const type = thisParticipant.type
    if (type === 'role' || type === 'group') {
      const svc = new UsersExpansionService(this.rootStore, this.audienceUserId)
      const users = await svc.getAllUsers(id, type)
      this.deleteParticipant(id)
      this.addUsers(users)
    }
    if (thisParticipant.type === 'from-event') {
      const svc = new UsersExpansionService(this.rootStore, this.audienceUserId)
      const users = await svc.getAllUsers(id, type, this.event.objectId)
      this.deleteParticipant(id)
      this.addUsers(users)
    }
    if (thisParticipant.type === 'client') {
      const svc = new ContactsExpansionService(this.rootStore)
      const contacts = await svc.getAllContacts(id)
      this.deleteParticipant(id)
      this.addContacts(contacts)
    }
  }

  private addUsers(users: IOrganizationUserDTO[]) {
    this.addParticipants(users, 'fk_User', 'name', 'user')
  }

  private addContacts(contacts: IContactDTO[]) {
    this.addParticipants(contacts, 'name', undefined, 'contact')
  }

  private addParticipants(
    participants: { objectId: string; userId?: string; isArchived?: boolean }[],
    nameKey: string,
    nameKey2: string,
    type: ParticipantType
  ) {
    const arr = this.participants as any
    arr.replace(
      this.participants.concat(
        participants
          .map((e) => {
            const id = e.userId ? e.userId : e.objectId
            if (this.participants.find((ee) => ee.id === id && ee.type === type)) {
              return undefined
            }
            if (nameKey2)
              return this.makeParticipant(id, e[nameKey][nameKey2], type, e.isArchived || false)
            return this.makeParticipant(id, e[nameKey], type, e.isArchived || false)
          })
          .filter((e) => e)
      )
    )
  }

  @computed
  public get selectedClients(): ParticipantVM[] {
    return this.participants.filter((e) => e.type === 'client')
  }

  @computed
  public get selectedRoles(): Array<ParticipantVM> {
    return this.participants.filter((e) => e.type === 'role')
  }

  @computed
  public get selectedGroups(): Array<ParticipantVM> {
    return this.participants.filter((e) => e.type === 'group')
  }

  @computed
  public get selectedEvents(): Array<ParticipantVM> {
    return this.participants.filter((e) => e.type === 'from-event')
  }

  @computed
  public get filteredParticipants(): Array<AnonymousParticipantVM | ParticipantVM> {
    if (!this.hasFilter) {
      return this.allParticipants
        .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0))
        .sort((a, b) => (a.type < b.type ? -1 : 0))
    }
    return this.allParticipants
      .filter((e) => {
        if (e.name.toLowerCase().indexOf(this.filter.toLowerCase()) > -1) return true
        return false
      })
      .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0))
      .sort((a, b) => (a.type < b.type ? -1 : 0))
  }

  @computed
  public get filteredParticipantsCount(): number {
    return this.filteredParticipants.length
  }

  @computed
  public get virtualListRowsCount(): number {
    const num = Math.ceil(this.filteredParticipants.length / this.pillsPerRow)
    return num
  }

  @computed
  public get allParticipants(): Array<AnonymousParticipantVM | ParticipantVM> {
    return (this.anonymousParticipants as any[]).concat(this.participants)
  }

  @computed
  public get availableParticipants(): Array<ParticipantVM> {
    // TODO: Deprecate this.  Tranining Plans and the Calendar Selects are using this.
    // Needs to use some other mechanism that uses searchAudienceUsers / Datastore
    const userRows = this.rootStore.audienceMembersStore.currentOrgUsers.map(
      (e) => new ParticipantVM(this.rootStore, e as unknown as IParticipant)
    )
    const roleRows = this.rootStore.audienceMembersStore.currentOrgRoles.map(
      (e) => new ParticipantVM(this.rootStore, e as unknown as IParticipant)
    )
    const groupRows = this.rootStore.audienceMembersStore.currentOrgGroups.map(
      (e) => new ParticipantVM(this.rootStore, e as unknown as IParticipant)
    )
    const rows = userRows.concat(roleRows).concat(groupRows)
    return rows
  }

  public participantsToDTO(): IParticipant[] {
    return this.participants.map((e) => e.toDTO())
  }

  public anonymousParticipantsToDTO(): IAnonymousParticipantDTO[] {
    return this.anonymousParticipants.map((e) => e.toDTO())
  }
}
