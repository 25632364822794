import { RootStore } from '../../stores/RootStore'
import { observable, computed, action } from 'mobx'
import { CalendarVM } from './CalendarVM'
import moment from 'moment'
import { IEventPillVM } from '../intefaces/IEventPillVM'
import { UserTrainingPlan } from '../../training-plans/user-training-plans/aggregate/UserTrainingPlan'

export class UserTrainingPlanPillVM implements IEventPillVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, userTrainingPlan: UserTrainingPlan, id?: string) {
    this.rootStore = rootStore
    this.userTrainingPlan = userTrainingPlan
    this.id = id
  }

  @observable public userTrainingPlan: UserTrainingPlan = null
  @observable public id: string = ''

  @computed
  public get title(): string {
    return this.userTrainingPlan.title
  }

  @computed
  public get localStartDate(): Date {
    if (this.userTrainingPlan.dueDate === null) return new Date()
    return moment(this.userTrainingPlan.dueDate).toDate()
  }

  @computed
  public get localEndDate(): Date {
    if (this.userTrainingPlan.dueDate === null) return new Date()
    return moment(this.userTrainingPlan.dueDate).toDate()
  }

  @computed
  public get allDay(): boolean {
    return this.userTrainingPlan.hasDueDate === null
  }

  @computed
  public get objectId(): string {
    return this.userTrainingPlan.objectId
  }

  @computed
  public get calendarVM(): CalendarVM {
    return this.rootStore.eventsStore.calendarVM
  }

  @computed
  public get calendarIndex(): number {
    const idx = this.calendarVM.selectedOtherCalendars.findIndex((e) => e.id === this.id)
    if (idx === -1) return 0
    return idx + 1
  }
}
