import { observable, action, computed } from 'mobx'
import { serializable, serialize, deserialize, list, object, primitive } from 'serializr'
import moment, { ISO_8601, Moment } from 'moment'
import { Resource } from './Resource'
import { Schedule } from './Schedule'
import { Participant } from './Participant'
import { RepeatType } from '../types/RepeatTypes'
import { Alarm } from './Alarm'
import { AlarmPeriodType } from '../types/AlarmPeriodType'
import { IEventDTO } from '../dtos/IEventDTO'
import { IAggregate } from '../../shared/data/IAggregate'
import { Attachment } from '../../upload/aggregate/Attachment'

export class Event implements IEventDTO, IAggregate {
  public static create(orgId: string) {
    const ev = new Event()
    ev.organizationId = orgId
    ev.startDate = moment().utc().clone().toISOString()
    ev.endDate = moment().utc().clone().add(1, 'h').toISOString()
    ev.isNew = true
    ev.addAlarm()
    ev.schedule = new Schedule()
    return ev
  }

  @serializable @observable public objectId: string = ''
  @serializable @observable public organizationId: string = ''
  @serializable @observable public startDate: string = ''
  @serializable @observable public endDate: string = ''
  @serializable @observable public dayIndex: number = 0
  @serializable @observable public occurrenceStartDate: string = ''
  @serializable @observable public allDay: boolean = false
  @serializable @observable public title: string = ''
  @serializable @observable public notes: string = ''
  @serializable @observable public isDeleted: boolean = false
  @serializable @observable public locationId: string = ''
  @serializable @observable public eventTypeId: string = ''
  @serializable @observable public repeats: boolean = false
  @serializable @observable public organizer: string = ''
  @serializable @observable public masterEventId: string = ''
  @serializable @observable public onTimeAlarm: boolean = true
  @serializable @observable public separateFromMasterEventId: string = undefined
  @serializable(object(Schedule)) public schedule: Schedule = undefined
  @serializable(list(object(Participant))) @observable public participants: Array<Participant> = []
  @serializable(list(object(Resource))) @observable public resources: Array<Resource> = []
  @serializable(list(object(Alarm))) @observable public alarms: Array<Alarm> = []
  @serializable(list(primitive())) @observable public userIds: Array<string> = []
  @serializable(list(primitive())) @observable public readUserIds: Array<string> = []
  @serializable(list(primitive())) @observable public goingNewUserIds: Array<string> = []
  @serializable(list(primitive())) @observable public goingYesUserIds: Array<string> = []
  @serializable(list(primitive())) @observable public goingMaybeUserIds: Array<string> = []
  @serializable(list(primitive())) @observable public goingNoUserIds: Array<string> = []
  @serializable @observable public isFakeEvent: boolean = false
  @serializable @observable public occurrenceId: string = undefined
  @serializable(list(object(Attachment))) @observable public attachments: Attachment[] = []
  @observable public skipNotifications: boolean = false
  public isOnServer: boolean = false
  public isNew: boolean = false
  eventId?: string = ''
  userId?: string = ''

  @computed
  public get isMasterEvent(): boolean {
    return !Boolean(this.masterEventId)
  }

  @computed
  public get localStartDate(): Date {
    const local = moment.utc(this.startDate, ISO_8601).local()
    if (this.allDay && local.isoWeekday() !== this.dayIndex && !this.isFakeEvent) {
      local.isoWeekday(this.dayIndex)
    }
    return local.toDate()
  }

  @computed
  public get localOccurrenceStartDate(): Date {
    const local = moment.utc(this.occurrenceStartDate, ISO_8601).local()
    if (this.allDay && local.isoWeekday() !== this.dayIndex && !this.isFakeEvent) {
      local.isoWeekday(this.dayIndex)
    }
    return local.toDate()
  }

  @computed
  public get localEndDate(): Date {
    // if (this.allDay) return moment(this.localStartDate).clone().endOf('day').toDate()
    return moment.utc(this.endDate, ISO_8601).local().toDate()
  }

  @action
  public markAsDeleted() {
    this.isDeleted = true
  }

  @action
  public addAttachmentsToEvent(attachment: Attachment) {
    this.attachments.push(attachment)
  }

  @action
  public deleteAttachment(index: number) {
    if (index < 0) return
    this.attachments.splice(index, 1)
  }

  @action
  public addResource(resourceId: string): Resource {
    const rsc = Resource.create(resourceId)
    this.resources.push(rsc)
    return rsc
  }

  @action
  public setSchedule(sch: Schedule) {
    this.schedule = sch
  }

  @action
  public addAlarm() {
    const al = Alarm.create(this.alarms.length)
    this.alarms.push(al)
    return al
  }

  public getResource(resourceId: string): Resource {
    return this.resources.find((e) => e.resourceId === resourceId)
  }

  public toggleWeekdayRepeat(val: string) {
    this.schedule.toggleWeekdayRepeat(val)
  }

  @action
  public clearResources() {
    this.resources = []
  }

  @action
  public clearEventTypeId() {
    this.eventTypeId = ''
  }

  @action
  public toggleOnTimeAlarm() {
    this.onTimeAlarm = !this.onTimeAlarm
  }

  @action
  public clearSelectedParticipants() {
    this.participants = []
  }

  @action
  public deleteResource(resourceId: string) {
    this.resources = this.resources.filter((resource) => resource.resourceId !== resourceId)
  }

  @action
  public deleteAlarm(index: number) {
    this.alarms = this.alarms.filter((e) => e.index !== index)
    this.alarms.forEach((e, idx) => e.setIndex(idx))
  }

  @action
  public setTitle(val: string) {
    this.title = val
  }

  @action
  public setOrganizer(userId: string) {
    this.organizer = userId
  }

  @action
  public setNotes(notes: string) {
    this.notes = notes
  }

  @action
  public toggleAllday() {
    this.allDay = !this.allDay
  }

  @action
  public setEventType(val: string) {
    this.eventTypeId = val
  }

  @action
  public setEndingMode(val) {
    this.schedule.setEndingMode(val)
  }

  @action
  public setStartDate(val: Moment) {
    val = val.clone()
    if (!val.isUTC) val.utc()
    this.startDate = val.toISOString()
    this.dayIndex = val.isoWeekday() // sunday = 7
  }

  @computed
  public get isInSeries(): boolean {
    return this.masterEventId && !this.separateFromMasterEventId
  }

  @action
  public separateFromMasterEvent() {
    this.separateFromMasterEventId = this.masterEventId
  }

  @action
  public setEndDate(val: Moment) {
    if (!val.isUTC) val.utc()
    this.endDate = val.toISOString()
  }

  @computed
  public get scheduleEndDate(): Date {
    if (!this.schedule.endDate) return moment(this.localStartDate).endOf('day').toDate()
    return moment.utc(this.schedule.endDate, ISO_8601).local().toDate()
  }

  @action
  public setScheduleEndDate(date: Date) {
    this.schedule.setScheduleEndDate(moment(date).toISOString())
  }

  @action
  public toggleSchedule() {
    this.schedule.toggleScheduleEnabled()
  }

  @action
  public toggleSkipNotifications() {
    this.skipNotifications = !this.skipNotifications
  }

  @action
  public setScheduleFrequency(value: number) {
    this.schedule.every = value
  }

  @action
  public setRepeatType(value: RepeatType) {
    this.schedule.setRepeatType(value)
  }

  @action
  public setLocation(val: string) {
    this.locationId = val
  }

  @action
  public markIsOnServer() {
    this.isOnServer = true
  }

  @action
  public markIsNotOnServer() {
    this.isOnServer = true
  }

  public serialize(): IEventDTO {
    const obj = serialize(this)
    // obj.startDate = moment(this.startDate).toISOString()
    // obj.occurrenceStartDate = moment(this.occurrenceStartDate).toISOString()
    // obj.endDate = moment(this.endDate).toISOString()
    // if (obj.schedule) obj.schedule.endDate = moment(this.schedule.endDate).toISOString()
    return obj
  }

  public clone(): Event {
    return deserialize(Event, this.serialize())
  }

  public cleanAlarmValues() {
    this.alarms.forEach((alarm) => {
      const badVal = alarm.units
      if (!badVal) return
      if (typeof badVal !== 'string') return
      if (String(badVal).indexOf('s') > -1) {
        const alarmCopy = JSON.parse(JSON.stringify(alarm))
        alarm.setUnits(Number(alarmCopy.period))
        alarm.setPeriod(alarmCopy.units.toString().toLowerCase() as AlarmPeriodType)
      }
    })
  }
}
