import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { TasksStore } from '../../store/TasksStore'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import { Avatar, Paper } from '@material-ui/core'
import GroupIcon from '@material-ui/icons/Group'
import './DrawerParticipantsList.scss'

interface DrawerParticipantsListProps {
  localizationStore?: LocalizationStore
  tasksStore?: TasksStore
}

const DrawerParticipantsList: React.FC<DrawerParticipantsListProps> = ({
  localizationStore,
  tasksStore,
}) => {
  const { lzStrings } = localizationStore
  const { taskEditVM: vm } = tasksStore

  return (
    <section id='ParticipantsList'>
      <div className='participants-section-header-row'>
        <h6>{lzStrings.tasksManagementWidgetDrawer.assign_participants}</h6>
        <Button
          variant='text'
          className={vm.participantsError ? 'error' : ''}
          onClick={() => vm.openParticipantsSelect()}
        >
          Add/Manage
        </Button>
      </div>
      <Grid container spacing={2}>
        {vm.participantsSelect.participants.map((audienceMember, index) => {
          return (
            <Grid item xs={6} key={index}>
              <Paper elevation={2} style={{ padding: '1em' }}>
                <Grid container spacing={1} alignItems='center' justifyContent='space-between'>
                  <Grid item>
                    <Grid container alignItems='center'>
                      {audienceMember.type === 'user' ? (
                        <Avatar alt={`${name}'s Avatar`} src={audienceMember.iconURL} />
                      ) : (
                        <Avatar>
                          <GroupIcon />
                        </Avatar>
                      )}
                      <Grid item className='participant-card-name'>
                        <h6>{audienceMember.name}</h6>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <CloseIcon
                      className='delete-participant'
                      onClick={() => vm.removeParticipant(audienceMember.id)}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )
        })}
      </Grid>
    </section>
  )
}

export default inject('localizationStore', 'tasksStore')(observer(DrawerParticipantsList))
