import { persist } from 'mobx-persist'
import { action, observable } from 'mobx'
import { Days } from './Days'
import { serializable } from 'serializr'
import moment from 'moment-timezone'
import { IAutoScheduleDTO } from '../dto/IAutoScheduleDTO'

export class AutoSchedule implements IAutoScheduleDTO {
  static create(sch: any) {
    const schedule = new AutoSchedule()
    schedule.startDate = sch.startDate
    schedule.endDate = sch.endDate
    schedule.days = Days.create(sch.days)
    schedule.deliveryTime = sch.deliveryTime
    schedule.deliveryTimeZone = sch.deliveryTimeZone
    return schedule
  }

  @serializable @observable startDate: string = moment(
    moment()
      .minute(Math.ceil(moment().minute() / 15) * 15)
      .second(0)
  ).toISOString()
  @serializable @observable endDate: string = moment(new Date()).toISOString()
  @persist('object', Days) @observable days: Days = new Days()
  @serializable @observable deliveryTime: string = moment(new Date()).toISOString()
  @serializable @observable deliveryTimeZone: string = moment.tz.guess()

  @action
  public setStartDate(val: Date) {
    this.startDate = moment(val).toISOString()
  }

  @action
  public setEndDate(val: Date) {
    this.endDate = moment(val).toISOString()
  }

  @action
  public setDeliveryTime(val: Date) {
    this.deliveryTime = moment(val).toISOString()
  }

  @action
  public setDeliveryTimeZone(val) {
    this.deliveryTimeZone = val
  }

  @action
  public toggleDay(day) {
    this.days[day] = !this.days[day]
  }
}
