import React from 'react'
import WidgetHeader from './WidgetHeader'
import WidgetContent from './WidgetContent'
import Drawer from './Drawer'
import { Card } from '@material-ui/core'
import './OrganizationGeneralWidget.scss'

const OrganizationGeneralWidget: React.FC = () => {
  return (
    <>
      <Card elevation={0} id='OrganizationGeneralWidget'>
        <WidgetHeader />
        <WidgetContent />
      </Card>
      <Drawer />
    </>
  )
}

export default OrganizationGeneralWidget
