import React from 'react'
import { observer } from 'mobx-react'
import { Button, Typography, Icon, Grid } from '@material-ui/core'
import './SurveyResultsScreen.scss'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { SurveyResultsVM } from '../view-models/SurveyResultsVM'

interface Props {
  resultsVM: SurveyResultsVM
}

const ModalButtons: React.FC<Props> = ({ resultsVM }) => {
  if (!resultsVM) return null

  const renderNextButton = () => {
    if (!resultsVM.nextQuestion) return <Grid />
    return (
      <>
        <Button onClick={() => resultsVM.goNext()}>
          <ArrowForwardIcon className='arrow-icon' />
        </Button>
      </>
    )
  }

  const renderPreviousButton = () => {
    if (resultsVM.questionIndex === 0) return <Grid />
    return (
      <>
        <Button onClick={() => resultsVM.goPrevious()}>
          <ArrowBackIcon className='arrow-icon' />
        </Button>
      </>
    )
  }

  return (
    <Grid className='results-nav-container'>
      <Grid className='results-nav-buttons'>
        {renderPreviousButton()}
        {renderNextButton()}
      </Grid>
    </Grid>
  )
}

export default observer(ModalButtons)
