import { action, observable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import PrivilegeSet from '../../privilege-sets/aggregate/PrivilegeSet'

export default class PrivilegeSetRowVM {
  rootStore: RootStore
  constructor(rootStore: RootStore, editVM: any, privilegeSet: PrivilegeSet) {
    this.rootStore = rootStore
    if (editVM) this.selectedPrivs = editVM.selectedPrivilegeSets
    this.privilegeSet = privilegeSet
    if (privilegeSet) this.loadData(privilegeSet)
  }

  loadData(privilegeSet) {
    this.objectId = privilegeSet.objectId
    this.name = privilegeSet.name
    this.description = privilegeSet.description
    this.organizationId = privilegeSet.organizationId
    this.privileges = privilegeSet.privileges
    this.setSelected()
  }

  setSelected() {
    if (!this.selectedPrivs) return
    this.selectedPrivs.forEach(priv => {
      if (priv === this.objectId) this.isSelected = true
    })
  }

  private privilegeSet: PrivilegeSet = null
  @observable public name: string = ''
  public objectId: string = ''
  private organizationId: string = ''
  private privileges: Array<string> = []
  @observable public description: string = ''
  @observable public isSelected: boolean = false
  private selectedPrivs: Array<any> = []

  @action
  public toggleIsSelected() {
    this.isSelected = !this.isSelected
  }
}
