import { computed, observable, action } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { ChartWidgetVM } from './ChartWidgetVM'
import { Worksheet } from '../../aggregate/Worksheet'
import { DrawerItemVM } from './DrawerItemVM'

export class ChartWidgetDrawerVM {
  rootStore: RootStore
  worksheetWidgetVM: ChartWidgetVM

  constructor(rootStore: RootStore, worksheetWidgetVM: ChartWidgetVM) {
    this.rootStore = rootStore
    this.worksheetWidgetVM = worksheetWidgetVM
  }

  @observable public isOpen: boolean = true
  @observable public workSheetId: string = ''

  @computed
  public get dashboardWidgetIndex(): string {
    return this.worksheetWidgetVM.dashboardWidgetIndex
  }

  @computed
  public get worksheets(): Array<DrawerItemVM> {
    return this.rootStore.worksheetsStore.currentOrgWorksheets.map(
      (worksheet) => new DrawerItemVM(this.rootStore, worksheet)
    )
  }

  @computed
  public get visibleWorksheets(): Array<DrawerItemVM> {
    return this.worksheets.filter((e) => e.isVisible)
  }

  @action
  public close() {
    this.isOpen = false
  }

  @action
  public save() {
    this.worksheetWidgetVM.dashboardWidgetInfo.setWorksheet(this.workSheetId)
    this.close()
  }

  public isSelectedWorksheet(objectId): boolean {
    return objectId === this.workSheetId
  }

  @action
  public setSelectedWorksheet(objectId: string) {
    this.workSheetId = objectId
  }
}
