import React from 'react'
import { observer, inject } from 'mobx-react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Paper,
  InputBase,
  Divider,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import CloseIcon from '@material-ui/icons/Close'
import './InvitationsLinkDialog.scss'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import { LabelsStore } from '../../../../../labels/store/LabelsStore'
import { CampaignDetailsVM } from '../../../../view-models/dashboard/campaign-details/CampaignDetailsVM'

interface Props {
  campaignDetailsVM?: CampaignDetailsVM
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
}

const LinkDialog: React.FC<Props> = ({ campaignDetailsVM, localizationStore, labelsStore }) => {
  const detailsVM = campaignDetailsVM
  if (!detailsVM) return null
  const vm = detailsVM.surveyParticipantsList
  if (!vm) return
  const { lzStrings } = localizationStore

  const handleCopyPress = () => {
    const copyText = document.getElementById('surveyLinkField') as HTMLInputElement
    copyText.select()
    document.execCommand('copy')
    vm.toggleCopySnackbar()
  }

  const renderLinkField = () => {
    return (
      <>
        <Grid item xs={12}>
          <Paper component='form' className='linkFieldPaper'>
            <InputBase
              disabled={!vm.surveyLink}
              value={vm.surveyLink}
              className='linkFieldInput'
              id='surveyLinkField'
            />
            <Divider className='linkFieldDivider' orientation='vertical' />
            <Tooltip title={lzStrings.surveyInvitationsList.copy_survey_link} placement='bottom'>
              <span>
                <IconButton
                  color='primary'
                  disabled={!vm.surveyLink}
                  className='linkIconButton'
                  aria-label='copy link'
                  onClick={(e) => handleCopyPress()}
                >
                  <FileCopyIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Paper>
        </Grid>
      </>
    )
  }

  return (
    <>
      <Dialog
        onClose={() => vm.toggleLinkDialog()}
        open={vm.showLinkDialog}
        id='InvitationsLinkDialog'
      >
        <Grid container spacing={3} className='surveyLinkDialogTitle'>
          <Grid item>
            <DialogTitle>{`${labelsStore.getLabelByLanguageAndFor('pulse')} ${
              lzStrings.surveyInvitationsList.link
            }`}</DialogTitle>
          </Grid>

          <Grid item>
            <IconButton aria-label='close' onClick={() => vm.toggleLinkDialog()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>

        <DialogContent className='surveyLinkDialogContent'>
          <Grid container>{renderLinkField()}</Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default inject('localizationStore', 'labelsStore')(observer(LinkDialog))
