import React, { useEffect } from 'react'
import { Observer } from 'mobx-react'
import LocalizationStore from '../../localization/LocalizationStore'
import { AuthStore } from '../store/AuthStore'
import rootStore from '../../stores/RootStore'
import MDSpinner from 'react-md-spinner'
import fullLogo from '../../../assets/img/logos/RippleWorx-White.png'
import './OIDCCallback.scss'
import { observer, useLocalStore } from 'mobx-react'
import { Button } from '@material-ui/core'
import { OIDCCallbackVM } from '../view-models/OIDCCallbackVM'

interface Props {
  localizationStore?: LocalizationStore
  authStore?: AuthStore
  router: any
}

const OIDCCallback: React.FC<Props> = ({ router }) => {
  const localStore = useLocalStore(() => ({
    vm: new OIDCCallbackVM(rootStore),
  }))
  const vm = localStore.vm
  rootStore.appStore.setRouter(router)

  const renderSpinner = () => {
    if (vm.hasError) return
    return <MDSpinner size={50} singleColor='#ffffff' />
  }

  const renderErrorMessage = () => {
    if (!vm.hasError) return
    return (
      <div className='error-msg'>
        {vm.errorMessage}
        <br />
        <br />
        {renderBackToLoginButton()}
      </div>
    )
  }

  const renderBackToLoginButton = () => {
    if (!vm.hasError) return
    if (vm.invalidUser) return
    return (
      <Button variant='contained' onClick={() => vm.backToLogin()}>
        Back to Login
      </Button>
    )
  }

  return (
    <Observer>
      {() => (
        <div id='OIDCAuthCallback'>
          <div className='center-body'>
            <div>
              <img className='logo' alt='RippleWorx Logo' src={fullLogo} />
            </div>
            {renderErrorMessage()}
            {renderSpinner()}
          </div>
        </div>
      )}
    </Observer>
  )
}

export default OIDCCallback
