import { action, computed, observable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { TrainingItemsStore } from '../store/TrainingItemsStore'
import { ContentVM } from '../../training-plans/user-training-plans/view-models/ContentVM'
import { IExerciseSetDTO } from '../dto/IExerciseSetDTO'
import moment from 'moment'
import { Exercise } from '../aggregate/Exercise'
import { ExerciseSet } from '../aggregate/ExerciseSet'

export class ExerciseSetVM {
  private rootStore: RootStore
  private dataStore: TrainingItemsStore

  constructor(
    rootStore: RootStore,
    manageVM: ContentVM,
    exercise: Exercise,
    set: ExerciseSet,
    isComplete: boolean = false
  ) {
    this.rootStore = rootStore
    this.manageVM = manageVM

    this.exercise = exercise
    this.set = set
    this.reps = set.reps
    this.weight = set.weight
    this.time = set.time
    this.percent = set.percent
    this.completedAt = set.completedAt
    this.isComplete = isComplete
  }

  @observable public exercise: Exercise = null
  @observable public set: ExerciseSet = null
  @observable public manageVM: ContentVM = null

  @observable public reps: number = 0
  @observable public weight: number = 0
  @observable public time: number = 0
  @observable public percent: number = 0
  @observable public isComplete: boolean = false
  @observable public index: number = 0
  @observable public completedAt: string = ''

  @computed
  public get isLoaded(): boolean {
    return this.dataStore.loaded
  }

  @computed
  public get title(): string {
    return this.manageVM.title
  }

  @computed
  public get id(): string {
    return this.manageVM.id
  }

  @computed
  public get isContentComplete(): boolean {
    return this.manageVM.isComplete
  }

  @action
  public toggleMarkIsComplete() {
    this.isComplete = !this.isComplete
    if (this.isComplete) this.completedAt = moment().toISOString()
    else this.completedAt = ''
  }

  @action
  public markAsComplete() {
    this.isComplete = true
    this.completedAt = moment(new Date()).toISOString()
  }

  @action
  public markAsIncomplete() {
    this.isComplete = false
    this.completedAt = ''
  }

  @action
  public setReps(val: string) {
    if (!val || val === '0') {
      this.reps = 0
      return
    }
    this.reps = parseInt(val.replace(/^0+/, ''), 10)
    this.onAction()
  }

  @action
  public setWeight(val: string) {
    if (!val || val === '0') {
      this.weight = 0
      return
    }
    this.weight = parseInt(val.replace(/^0+/, ''), 10)
    this.onAction()
  }

  @action
  public setTime(val: string) {
    if (!val || val === '0') {
      this.time = 0
      return
    }
    this.time = parseInt(val.replace(/^0+/, ''), 10)
    this.onAction()
  }

  @computed
  public get repsValid(): boolean {
    if (!this.reps && this.reps !== 0) return false
    if (this.reps < 0) return false
    return true
  }

  @computed
  public get weightValid(): boolean {
    if (!this.weight && this.weight !== 0) return false
    if (this.weight < 0) return false
    return true
  }

  @computed
  public get timeValid(): boolean {
    if (!this.time && this.time !== 0) return false
    if (this.time < 0) return false
    return true
  }

  @action
  public setIndex(index: number) {
    this.index = index
  }

  @action
  public onAction() {
    if (!this.manageVM.takeVM) return
    this.set.index = this.index
    this.set.reps = this.reps
    this.set.weight = this.weight
    this.set.time = this.time
    this.set.isComplete = this.isComplete
    this.set.completedAt = this.completedAt
    this.manageVM.takeVM.save()
  }

  @action
  public toggleIsComplete() {
    this.isComplete = !this.isComplete
    if (this.isComplete) this.completedAt = moment().toISOString()
    else this.completedAt = ''
    this.onAction()
  }

  @computed
  public get planIsComplete(): boolean {
    if (!this.manageVM) return false
    if (!this.manageVM.takeVM) return false
    return this.manageVM.takeVM.planIsComplete
  }

  public toDTO(): IExerciseSetDTO {
    return {
      id: this.id,
      reps: this.reps,
      weight: this.weight,
      time: this.time,
      percent: this.percent,
      index: this.index,
      isComplete: this.isComplete,
      completedAt: this.completedAt,
    }
  }
}
