import { observable, action } from 'mobx'
import { serializable, deserialize, serialize, list, object } from 'serializr'
import { ITaskCommentDTO } from '../dtos/ITaskCommentDTO'
import moment from 'moment'
import { IAggregate } from '../../shared/data/IAggregate'
import { Attachment } from '../../attachments/aggregate/Attachment'

export class TaskComment implements ITaskCommentDTO, IAggregate {
  static create(
    orgId: string,
    userId: string,
    text: string,
    taskId: string,
    attachment?: Attachment
  ): TaskComment {
    const taskComment = new TaskComment()
    taskComment.objectId = null
    taskComment.organizationId = orgId
    taskComment.userId = userId
    taskComment.taskId = taskId
    taskComment.text = text
    taskComment.attachment = attachment && attachment
    taskComment.datePosted = moment().unix()
    taskComment.isDeleted = false
    taskComment.isOnServer = true
    taskComment.isProcessing = false
    return taskComment
  }

  @serializable @observable public objectId: string
  @serializable @observable public organizationId: string
  @serializable @observable public userId: string
  @serializable @observable public taskId: string
  @serializable @observable public text: string
  @serializable @observable public datePosted: number
  @serializable @observable public isDeleted: boolean
  @serializable(object(Attachment)) @observable public attachment: Attachment = null

  isOnServer = false
  isProcessing = false

  @action
  public setText(val: string) {
    this.text = val
  }

  @action
  public addAttachment(attachment: Attachment) {
    if (this.attachment) return
    this.attachment = attachment
  }

  @action
  public deleteAttachment() {
    this.attachment = null
  }

  @action
  markIsNotOnServer() {
    this.isOnServer = false
  }

  @action
  markIsNotProcessing() {
    this.isProcessing = false
  }

  @action
  markIsProcessing() {
    this.isProcessing = true
  }

  @action
  public markIsOnServer() {
    this.isOnServer = true
  }

  @action
  public markAsDeleted() {
    this.isDeleted = true
  }

  public clone(): TaskComment {
    return deserialize(TaskComment, this.serialize())
  }

  public serialize(): TaskComment {
    return serialize(this)
  }
}
