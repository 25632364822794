import React from 'react'
import './DetailsTabPanel.scss'
import { inject, observer } from 'mobx-react'
import {
  Grid,
  OutlinedInput,
  Typography,
  TextField,
  Switch,
  Button,
  InputAdornment,
  Paper,
  Tooltip,
} from '@material-ui/core'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import { LabelsStore } from 'src/app/labels/store/LabelsStore'
import CategoryPicker from 'src/app/shared/category-picker/CategoryPicker'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TrainingPlansStore } from '../../../store/TrainingPlansStore'
import AddIcon from '@material-ui/icons/Add'
import TrainerSelectModal from './TrainerSelectModal'
import Avatar from '../../../../shared/Avatar'
import InfoIcon from '@material-ui/icons/Info'
import InfoTooltip from '../../../../shared/InfoTooltip'
import FileUploadModal from '../../../../upload/views/FileUploadModal'
import { Attachment } from '../../../../upload/aggregate/Attachment'
import { FileTypeEnum } from '../../../../upload/enum/FileTypeEnum'
import { IApplicableParticipantDTO } from '../../../../training-items/dto/IApplicableParticipantDTO'
import CMSItemsPickerDialog from '../../../../cms-items/views/dialogs/CMSItemsPickerDialog'
import { CMSItemsTypeEnum } from '../../../../cms-items/enum/CMSItemsTypeEnum'
import { OrganizationsStore } from '../../../../organizations/store/OrganizationsStore'

interface Props {
  trainingPlansStore?: TrainingPlansStore
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
  organizationsStore?: OrganizationsStore
}
const DetailsTabPanel: React.FC<Props> = ({
  trainingPlansStore,
  localizationStore,
  labelsStore,
  organizationsStore,
}) => {
  const { trainingPlanEditVM: vm } = trainingPlansStore
  const { training_plans: strings } = localizationStore.lzStrings
  if (!vm) return null

  const renderDetailFields = () => {
    return (
      <>
        <Grid item className='details-item'>
          <Typography variant='h5' className='input-label'>
            {strings.title}
            <span className='required'>*</span>
          </Typography>
          <OutlinedInput
            disabled={vm.editIsDisabled}
            required
            fullWidth
            labelWidth={0}
            autoComplete='off'
            value={vm.title}
            onChange={(e) => vm.setTitle(e.target.value)}
            error={!vm.titleValid}
          />
        </Grid>
        <Grid item className='details-item'>
          <Typography variant='h5' className='input-label'>
            {strings.description}
          </Typography>
          <OutlinedInput
            disabled={vm.editIsDisabled}
            multiline
            fullWidth
            rows={3}
            labelWidth={0}
            autoComplete='off'
            value={vm.description}
            onChange={(e) => vm.setDescription(e.target.value)}
          />
        </Grid>
        <Grid item className='details-item'>
          <Typography variant='h5' className='input-label'>
            {strings.program}
          </Typography>
          <OutlinedInput
            disabled={vm.editIsDisabled}
            required
            fullWidth
            labelWidth={0}
            autoComplete='off'
            value={vm.program}
            onChange={(e) => vm.setProgram(e.target.value)}
          />
        </Grid>
        {renderTagsSection()}
        <Grid item className='details-item'>
          <Typography variant='h5' className='input-label'>
            {strings.thumbnail_image}
          </Typography>
          <OutlinedInput
            disabled={vm.editIsDisabled}
            // onClick={() => vm.toggleShowThumbnailModal()}
            className='link-input'
            required
            fullWidth
            labelWidth={0}
            autoComplete='off'
            placeholder='Add Thumbnail'
            value={vm.thumbnailFileName}
            startAdornment={
              <InputAdornment position='start' className='start-adornment'>
                {vm.thumbnail && <img src={vm.thumbnail} />}
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position='end'>
                <Button
                  disabled={vm.editIsDisabled}
                  className='browse-button'
                  variant='outlined'
                  onClick={() => vm.toggleShowThumbnailModal()}
                >
                  Browse
                </Button>
              </InputAdornment>
            }
          />
        </Grid>
      </>
    )
  }

  const renderTagsSection = () => {
    return (
      <>
        <Grid item>
          <Typography variant='h5' className='input-label'>
            {strings.tags}
          </Typography>
        </Grid>
        <form>
          <Grid item xs={12} className='details-item'>
            <Typography variant='h5' className='input-label'>
              {labelsStore.getLabelByLanguageAndFor('categories')}
            </Typography>
            <CategoryPicker
              disabled={vm.editIsDisabled}
              mode='multiSelect'
              onApply={(val) => vm.setCategories(val)}
              onChipDelete={(id) => vm.removeCategory(id)}
              vm={vm.planCategoryPickerVM}
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={12} className='details-item'>
            <Typography variant='h5' className='input-label'>
              {strings.roles_groups}
            </Typography>
            <Autocomplete
              disabled={vm.editIsDisabled}
              className='role-groups-input'
              value={vm.selectedRolesAndGroups.slice()}
              multiple
              filterSelectedOptions
              options={vm.availableRolesAndGroups.slice()}
              getOptionLabel={(e) =>
                `${e.type.charAt(0).toUpperCase() + e.type.slice(1)}: ${e.name}`
              }
              onChange={(e, arr: IApplicableParticipantDTO[]) => vm.setSelectedRolesAndGroups(arr)}
              disableClearable={true}
              renderInput={(params) => (
                <TextField
                  className={
                    vm.groupsAndRolesValid
                      ? 'text-field-input'
                      : 'text-field-input groups-roles-error'
                  }
                  placeholder={vm.selectedRolesAndGroups.length < 1 ? 'Roles or Groups...' : ''}
                  {...params}
                  variant='outlined'
                  fullWidth
                  margin='none'
                />
              )}
            />
          </Grid>
        </form>
      </>
    )
  }

  const renderAddToCatalog = () => {
    if (vm.isCatalogPlan) return null
    return (
      <Grid item className='details-item add-to-catalog'>
        <Typography variant='h5' className='input-label'>
          {strings.add_to_catalog} <InfoTooltip message={strings.add_to_catalog_tooltip} />
        </Typography>
        <Switch
          disabled={vm.editIsDisabled}
          onChange={() => vm.toggleAddToCatalog()}
          checked={vm.addToCatalog}
        />
      </Grid>
    )
  }

  const renderForAutomatedFlow = () => {
    if (!vm.canManageTrainingPlanFlows) return
    return (
      <Grid item className='details-item add-to-catalog'>
        <Typography variant='h5' className='input-label'>
          {strings.for_training_flow} <InfoTooltip message={strings.for_training_flow_tooltip} />
        </Typography>
        <Switch
          disabled={vm.editIsDisabled}
          onChange={() => vm.toggleIsForAutomatedTraining()}
          checked={vm.isForAutomatedTraining}
        />
      </Grid>
    )
  }

  const renderShowQuizResults = () => {
    return (
      <Grid item xs={12} className='details-item show-quiz-results'>
        <Typography variant='h5' className='input-label'>
          {strings.show_quiz_results} <InfoTooltip message={strings.show_quiz_results_tooltip} />
        </Typography>
        <Switch
          disabled={vm.editIsDisabled}
          onChange={() => vm.toggleShowQuizResults()}
          checked={vm.showQuizResults}
        />
      </Grid>
    )
  }

  const renderGoalName = () => {
    if (!vm.associatedGoal) return null
    return <Typography variant='h6'>{vm.associatedGoal.name}</Typography>
  }

  const renderAssociateToGoals = () => {
    if (!vm.canCreateGoals) return null
    return (
      <Grid item xs={12} className='details-item associate-goals'>
        <Typography variant='h5' className='input-label'>
          {strings.associate_goal}
        </Typography>
        <span className='option-inputs flex-align'>
          <Switch
            disabled={vm.editIsDisabled}
            checked={Boolean(vm.associatedGoalId) || vm.goalsModalOpen}
            onChange={() => vm.openGoalsModal()}
          />
          {renderGoalName()}
        </span>
      </Grid>
    )
  }

  const renderCatalogAndThumbnailInputs = () => {
    return (
      <Grid container>
        {renderAddToCatalog()}
        {renderAssociateToGoals()}
        {renderShowQuizResults()}
        {renderForAutomatedFlow()}
        <Grid item xs={12} className='details-item'>
          <Typography variant='h5' className='input-label'>
            {strings.mark_as_peer_training} <InfoTooltip message={strings.peer_training_tooltip} />
          </Typography>

          <Switch
            disabled={vm.editIsDisabled}
            onChange={() => vm.toggleMarkAsPeerTraining()}
            checked={vm.isForPeerTraining}
          />
        </Grid>
        {renderCards()}
      </Grid>
    )
  }

  const renderCards = () => {
    if (!vm.isForPeerTraining) return null
    return (
      <>
        <Grid item xs={12} className='details-item peer-input'>
          <Typography variant='h5' className='input-label'>
            {strings.trainer}
          </Typography>
          <Grid item xs={4}>
            {renderAddTrainerCard()}
            {renderTrainerCard()}
          </Grid>
        </Grid>
        {/* <Grid item xs={12} className='details-item peer-input'>
          <Typography variant='h5' className='input-label'>
            {strings.trainee}
          </Typography>
          <Grid item xs={4}>
            {renderAddTraineeCard()}
            {renderTraineeCard()}
          </Grid>
        </Grid> */}
      </>
    )
  }

  const renderAddTrainerCard = () => {
    if (vm.trainer) return null
    return (
      <Paper
        elevation={1}
        onClick={() => vm.toggleTrainerSelectOpen()}
        className={!vm.trainerValid ? 'audience-member trainer-error' : 'audience-member'}
      >
        <Grid container direction='row' className='audience-container'>
          <Grid item className='avatar-container'>
            <div className='add-icon-container'>
              <AddIcon />
            </div>
          </Grid>
          <Grid item className='name-container'>
            <h6 className='add-participant-text'>{strings.select_trainer}</h6>
          </Grid>
        </Grid>
      </Paper>
    )
  }

  // const renderAddTraineeCard = () => {
  //   if (vm.trainee) return null
  //   return (
  //     <Paper elevation={1} onClick={() => vm.toggleTraineeSelectOpen()} className='audience-member'>
  //       <Grid container direction='row' className='audience-container'>
  //         <Grid item className='avatar-container'>
  //           <div className='add-icon-container'>
  //             <AddIcon />
  //           </div>
  //         </Grid>
  //         <Grid item className='name-container'>
  //           <h6 className={vm.trainee ? 'add-participant-text selected' : 'add-participant-text'}>
  //             {strings.select_trainee}
  //           </h6>
  //         </Grid>
  //       </Grid>
  //     </Paper>
  //   )
  // }

  const renderTrainerCard = () => {
    if (!vm.trainer) return null
    return (
      <Paper elevation={2} onClick={() => vm.toggleTrainerSelectOpen()} className='audience-member'>
        <Grid container direction='row' className='audience-container'>
          <Grid item className='avatar-container'>
            <div className='add-icon-container'>
              <Avatar user={vm.trainer} />
            </div>
          </Grid>
          <Grid item className='name-container'>
            <h6 className={vm.trainer ? 'add-participant-text filled' : 'add-participant-text'}>
              {vm.trainer.name}
            </h6>
          </Grid>
        </Grid>
      </Paper>
    )
  }

  // const renderTraineeCard = () => {
  //   if (!vm.trainee) return null
  //   return (
  //     <Paper elevation={2} onClick={() => vm.toggleTraineeSelectOpen()} className='audience-member'>
  //       <Grid container direction='row' className='audience-container'>
  //         <Grid item className='avatar-container'>
  //           <div className='add-icon-container'>
  //             <Avatar user={vm.trainee} />
  //           </div>
  //         </Grid>
  //         <Grid item className='name-container'>
  //           <h6 className={vm.trainee ? 'add-participant-text filled' : 'add-participant-text'}>
  //             {vm.trainee.name}
  //           </h6>
  //         </Grid>
  //       </Grid>
  //     </Paper>
  //   )
  // }

  const renderThumbnailUploadModal = () => {
    if (organizationsStore.currentOrganization?.hasCMSPicker) return renderCMSUploadModal()
    return renderStandardUploadModal()
  }

  const renderCMSUploadModal = () => {
    return (
      <CMSItemsPickerDialog
        allowMultiple={false}
        fileTypes={CMSItemsTypeEnum.IMAGES}
        addAfterSave={(a: Attachment[]) => {
          vm.setThumbnail(a)
        }}
        toggleShowModal={() => vm.toggleShowThumbnailModal()}
        isOpen={vm.showThumbnailUploadModal}
      />
    )
  }

  const renderStandardUploadModal = () => {
    return (
      <FileUploadModal
        allowMultiple={false}
        fileTypes={FileTypeEnum.IMAGES}
        addAfterSave={(a: Attachment[]) => vm.setThumbnail(a)}
        toggleShowModal={() => vm.toggleShowThumbnailModal()}
        isOpen={vm.showThumbnailUploadModal}
      />
    )
  }

  return (
    <Grid container direction='row' id='TPDetailsTabPanel'>
      {renderThumbnailUploadModal()}
      <Grid item xs={5} className='left-panel'>
        {renderDetailFields()}
      </Grid>
      <Grid item xs={7} className='right-panel'>
        {renderCatalogAndThumbnailInputs()}
      </Grid>
      <TrainerSelectModal />
      {/* <TraineeSelectModal /> */}
    </Grid>
  )
}

export default inject(
  'trainingPlansStore',
  'localizationStore',
  'labelsStore',
  'organizationsStore'
)(observer(DetailsTabPanel))
