import React from 'react'

//MobX Stores
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../localization/LocalizationStore'
import { QuestionsStore } from '../store/QuestionsStore'

//MaterialUI Components
import { InputLabel, Switch } from '@material-ui/core'

//Props Definitions
interface DrawerOptionsSectionProps {
  localizationStore?: LocalizationStore
  questionsStore?: QuestionsStore
}

const DrawerOptionsSection: React.FC<DrawerOptionsSectionProps> = ({
  localizationStore,
  questionsStore,
}) => {
  const { lzStrings } = localizationStore
  const { editVM: QuestionCatalogWidgetForm } = questionsStore

  const handleToggle = () => {
    QuestionCatalogWidgetForm.toggleRequired()
  }

  return (
    <section>
      <InputLabel htmlFor='required'>
        {lzStrings.questionsCatalogWidgetDrawer.response_required}
      </InputLabel>
      <Switch
        checked={QuestionCatalogWidgetForm.required}
        onChange={handleToggle}
        value='required'
      />
    </section>
  )
}

export default inject('localizationStore', 'questionsStore')(observer(DrawerOptionsSection))
