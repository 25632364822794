import { observable, computed, action } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { OptionVM } from './OptionVM'
import { MediaItemVM } from './MediaItemVM'
import { QuestionType } from '../types/QuestionType'
import { UserSurveyTakeVM } from './UserSurveyTakeVM'
import { Question } from '../../surveys/aggregate/Question'
import { Question as SurveyResponseQuestion } from '../../survey-responses/aggregate/Question'
import { Option } from '../../surveys/aggregate/Option'
import isMobile from '../../../utils/isMobile'
import { SentimentAnalysisVM } from './SentimentAnalysisVM'
import { TrainAIVM } from './train-ai/TrainAIVM'
import { isNumeric } from '../../shared/isNumeric'
import { OldMediaItemVM } from './OldMediaItemVM'
import { CMSMediaItemVM } from './CMSMediaItemVM'

export class QuestionVM {
  private rootStore: RootStore
  private question: Question | SurveyResponseQuestion
  private takeForm: UserSurveyTakeVM

  constructor(
    rootStore,
    takeForm: UserSurveyTakeVM,
    question: Question | SurveyResponseQuestion,
    index: number
  ) {
    this.rootStore = rootStore
    this.takeForm = takeForm
    this.question = question
    this.index = index
    this.loadData(question)
    this.declareMediaFirstQuestion(question)
  }

  private loadData(question: Question) {
    this.type = question.type as QuestionType
    this.required = question.required
    this.ratingDisplay = question.ratingDisplay

    if (isNumeric(question.media.objectId) || question.media.cmsItemId) {
      this.media = new CMSMediaItemVM(this.rootStore, question.media)
    } else
      this.media = new OldMediaItemVM(
        this.rootStore,
        question.media,
        question.youTubeURL,
        question.vimeoURL
      )

    if (this.media.isCMSItem) (this.media as CMSMediaItemVM).loadCMSItem()

    if (
      !this.media.path ||
      this.media.type === 'image' ||
      this.question.media.watchPercentageRequirement === 0
    )
      this.watchPercentageReached = true
    question.options.forEach((opt: Option) => {
      this.options.push(new OptionVM(this.rootStore, this.takeForm, this, opt))
    })
    if (this.type === 'rating' || this.type === 'starRating') {
      if (this.takeForm.isForResponse) return
      if (this.takeForm.isForResponseDisplay) return
      if (this.takeForm.isForCompletedSurvey) return
    }
    this.isDropdownMultiSelect = question.isDropdownMultiSelect
    this.placeholder = question.placeholder
  }

  @observable type: QuestionType = null
  @observable index: number = 0
  @observable options: Array<OptionVM> = []
  @observable media: MediaItemVM = null
  @observable required: boolean = false
  @observable saveTried: boolean = false
  @observable playedSeconds: number = 0
  @observable mediaTimeLeft: number = 0
  @observable watchSecondsNeeded: number = 0
  @observable watchPercentageReached: boolean = false
  @observable ratingDisplay: string = ''
  @observable isDirty: boolean = false
  displayedIndex: number = 0
  @observable showLightBox: boolean = false
  @observable oldIndex: number = 0
  @observable newIndex: number = 0
  @observable hover: number = 0
  @observable public showSentimentAnalysis: boolean = false
  @observable public showTrainDialog: boolean = false
  @observable public sentimentAnalysisVM: SentimentAnalysisVM
  @observable public trainAIVM: TrainAIVM
  @observable public isMediaFirstQuestion: boolean = false
  @observable public showMedia: boolean = false
  @observable public isDropdownMultiSelect: boolean = false
  @observable public placeholder: string = 'Please Select'

  @computed
  public get html(): string {
    return this.question.html
  }

  @computed
  public get isMediaFirstQuestionAndMediaShown(): boolean {
    if (this.isMediaFirstQuestion) {
      if (this.showMedia) return true
    }
    return false
  }

  @computed
  public get isMediaFirstQuestionAndMediaNotShown(): boolean {
    if (this.isMediaFirstQuestion) {
      if (!this.showMedia) return true
    }
    return false
  }

  @action
  public setShowMedia() {
    this.showMedia = true
  }

  @action
  public setDontShowMedia() {
    this.showMedia = false
  }

  @action
  private declareMediaFirstQuestion(question) {
    if (this.takeForm.questionsFlowFormat !== 'paged') return
    if (this.takeForm.isForResponse) return
    if (this.takeForm.isForResponseDisplay) return
    if (this.takeForm.isForCompletedSurvey) return
    if (!question.showMediaFirst) return
    this.isMediaFirstQuestion = true
    this.showMedia = true
  }

  @action
  public showSentiments() {
    this.sentimentAnalysisVM = new SentimentAnalysisVM(
      this.takeForm.surveyResponseId,
      this.question.id,
      this.rootStore.appStore.currentOrgId
    )
    this.toggleSentimentAnalysis()
  }

  @action
  public toggleSentimentAnalysis() {
    this.showSentimentAnalysis = !this.showSentimentAnalysis
  }

  @action
  public showTrainAI() {
    this.trainAIVM = new TrainAIVM(this.rootStore, this.textAnswer)
    this.toggleSentimentAnalysis()
    this.toggleTrainDialog()
  }

  @action
  public toggleTrainDialog() {
    this.trainAIVM.toggleTrainDialog()
  }

  @action
  public setIsDirty() {
    this.isDirty = true
  }

  @action
  public setHover(newHover: number) {
    this.hover = newHover
  }

  @action
  public setNewRank(value) {
    this.newIndex = value
  }

  @action
  public setOldRank(value) {
    this.oldIndex = value
  }

  @action
  public moveOption() {
    this.question.moveOption(this.oldIndex, this.newIndex)
    this.question.setOptionRanks()
    this.options = []
    this.question.options.forEach((opt: Option) => {
      this.options.push(new OptionVM(this.rootStore, this.takeForm, this, opt))
    })
  }

  @computed
  public get isForResponseDisplay(): boolean {
    return this.takeForm.isForResponseDisplay
  }

  @computed
  public get decimalsAllowed(): number {
    return this.question.decimalsAllowed
  }

  @computed
  public get defaultValueNumeric(): string {
    return String(this.question.defaultValueNumeric)
  }

  @action
  public toggleLightBox() {
    this.showLightBox = !this.showLightBox
  }

  @action
  public setValue(val) {
    if (!val) return
    this.options.forEach((e) => (e.isChecked = false))
    this.options.filter((e) => e.value === val)[0].isChecked = true
    this.takeForm.setCurrentQuestionIndex()
  }

  @action
  public setValues(vals) {
    if (!vals) return
    this.options.forEach((e) => {
      const isArray = Array.isArray(vals)
      if (isArray) {
        const found = vals.find((val) => val === e.text)
        if (found) e.isChecked = true
        else e.isChecked = false
      } else {
        this.options.forEach((e) => (e.isChecked = false))
        this.options.filter((e) => e.text === vals)[0].isChecked = true
      }
    })
    this.takeForm.setCurrentQuestionIndex()
  }

  @action
  public watchProgress(event) {
    this.playedSeconds++
    if (this.mediaTimeLeft > 0) this.mediaTimeLeft--
    if (Math.floor(event.playedSeconds) > this.watchSecondsNeeded) {
      this.watchPercentageReached = true
    }
  }

  @action
  public setWatchPercentageNeeded(event) {
    const duration = Math.floor(event)
    if (this.question.media.watchPercentageRequirement > 0) {
      this.watchSecondsNeeded = duration * (this.question.media.watchPercentageRequirement / 100)
      this.mediaTimeLeft = this.watchSecondsNeeded
    }
  }

  @computed
  public get isShown(): boolean {
    let takeLinear = this.takeForm.questionsFlowFormat
    if (this.isCurrentQuestion || takeLinear !== 'paged') return true
    else return false
  }

  @computed
  public get value(): number {
    if (this.type === 'rating' || this.type === 'starRating') {
      const currentSelectedOption = this.options.find((e) => e.isChecked === true)
      if (currentSelectedOption) return currentSelectedOption.value
      return 0
    }
    return null
  }

  @computed
  public get ratingDisplayVal(): string {
    return this.question.ratingDisplay
  }

  public setDisplayedIndex(val: number) {
    this.displayedIndex = val
  }

  @computed
  public get validatedNumberResponse(): boolean {
    if (this.takeForm.isForResponseDisplay) return true
    if (!this.isDirty) return false
    if (
      Number(this.options[0].responseNumber) >= Number(this.minimumValueAllowed) &&
      Number(this.options[0].responseNumber) <= Number(this.maximumValueAllowed)
    )
      return true
    return false
  }

  @computed
  public get hasAnswer(): boolean {
    if (this.type === 'infoText') return true
    if (this.type === 'order') return true
    if (this.type === 'number') return this.validatedNumberResponse
    if (this.type !== 'text') {
      return this.options.filter((e) => e.isChecked).length !== 0
    } else {
      return this.options[0].text !== ''
    }
  }

  @computed
  public get textAnswer(): string {
    if (this.hasAnswer) {
      return this.options[0].text
    } else {
      return ''
    }
  }

  @computed
  public get goToEnd(): boolean {
    const toEnd = this.options.find((opt) => opt.goToEnd) !== undefined
    return toEnd
  }

  @computed
  public get nextQuestionIndexes(): Array<number> {
    if (this.goToEnd && this.type !== 'check' && this.type !== 'dropdown') return []
    if (this.type === 'infoText') return [this.index + 1]
    if (!this.hasAnswer) {
      if (this.options[0].nextQuestion) return [this.options[0].nextQuestion.index]
      else return []
    }
    if (this.hasAnswer && this.type === 'text') {
      if (this.options[0].nextQuestion) return [this.options[0].nextQuestion.index]
      else return []
    }
    if (this.hasAnswer && this.type === 'number') {
      if (this.options[0].nextQuestion) return [this.options[0].nextQuestion.index]
      else return []
    }
    if (this.hasAnswer && this.type === 'order') {
      if (this.options[0].nextQuestion) return [this.options[0].nextQuestion.index]
      else return []
    }
    if (this.type === 'check' || this.type === 'dropdown') {
      const branchedOptions = this.options.filter((e) => e.isBranched)
      const goToEndOptions = this.options.filter((e) => e.goToEnd)
      if (branchedOptions.length === 1) {
        const foundOption = this.options.find((e) => e.isBranched)
        if (foundOption.isChecked) return [foundOption.nextQuestion.index]
      }
      if (branchedOptions.length > 1) {
        const nextQuestion = this.takeForm.allQuestions[this.index + 1]
        if (!nextQuestion) return []
        const checkedOpt = branchedOptions.find((e) => e.nextQuestionId === nextQuestion.id)
        if (checkedOpt && checkedOpt.isChecked) return [nextQuestion.index]
      }
      if (goToEndOptions.length === 1) {
        const foundOption = this.options.find((e) => e.goToEnd)
        if (foundOption.isChecked) return []
      }
    }
    const indexes = []
    this.options
      .filter((e) => e.isChecked && e.nextQuestion)
      .forEach((e) => {
        indexes.push(e.nextQuestion.index)
      })
    return indexes.sort((a, b) => a - b)
  }

  @computed
  public get hasBranch(): boolean {
    if (this.type === 'text' && !this.hasAnswer) return false
    if (this.type === 'number' && !this.hasAnswer) return false
    return this.options.filter((e) => e.isBranched).length !== 0
  }

  @computed
  public get blurred(): boolean {
    const takeForm = this.takeForm
    if (takeForm.questionsFlowFormat === 'paged') return false
    return this.displayedIndex > takeForm.firstUnansweredBranchIndex
  }

  @computed
  public get ratingText(): string {
    const index = this.hover - 1 > -1 ? this.hover - 1 : this.value - 1
    if (index < 0) return `${isMobile ? 'Tap' : 'Click'} to Select`
    if (this.type === 'rating' || this.type === 'starRating') {
      const currentSelectedOption = this.options[index].text
      return currentSelectedOption ? currentSelectedOption : ''
    }
    return null
  }

  @computed
  public get id(): string {
    return this.question.id
  }

  @computed
  public get rank(): number {
    return this.question.rank
  }

  @computed
  public get title(): string {
    return this.question.title
  }

  @computed
  public get maximumValueAllowed(): string {
    return Number(this.question.maximumValueAllowed) === 0
      ? String(10 ** 22 - 1)
      : String(this.question.maximumValueAllowed)
  }

  @computed
  public get minimumValueAllowed(): string {
    return String(this.question.minimumValueAllowed)
  }

  @computed
  public get numberStep(): string {
    if (Number(this.question.numberStep) === 0) return '1'
    return String(this.question.numberStep)
  }

  @computed
  public get isCurrentQuestion(): boolean {
    const takeForm = this.takeForm
    if (takeForm.isComplete && takeForm.questionsFlowFormat !== 'paged') return false
    return takeForm.currentQuestionId === this.id
  }

  @computed
  public get hasMedia(): boolean {
    if (this.hasYouTubeURL) return true
    return this.media && this.media.hasMedia
  }

  @computed
  public get hasYouTubeURL(): boolean {
    return Boolean(this.question.youTubeURL)
  }

  @computed
  public get hasVimeoURL(): boolean {
    return Boolean(this.question.vimeoURL)
  }

  @computed
  public get youTubeId(): string {
    if (!this.hasYouTubeURL) return ''
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
    const match = this.question.youTubeURL.match(regExp)

    return match && match[2].length === 11 ? match[2] : null
  }

  @computed
  public get vimeoId(): string {
    if (!this.hasVimeoURL) return ''
    let regExp =
      /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/
    if (this.question.vimeoURL.includes('player.vimeo.com')) {
      regExp =
        /(http|https)?:\/\/(www\.)?player.vimeo.com\/(?:video\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/
    }
    const match = this.question.vimeoURL.match(regExp)

    return match && match.length ? match[4] : ''
  }

  @computed
  public get embedUrl(): string {
    if (!this.hasYouTubeURL && !this.hasVimeoURL) return ''
    if (this.hasYouTubeURL) return `https://www.youtube.com/embed/${this.youTubeId}`
    else if (this.hasVimeoURL) return `https://player.vimeo.com/video/${this.vimeoId}`
    else return ''
  }

  @computed
  public get isValid() {
    if (!this.saveTried) return true
    return true
  }

  public toDTO() {
    return {
      id: this.question.id,
      rank: this.rank,
      title: this.title,
      type: this.type,
      options: this.options.map((e) => e.toDTO()),
      media: this.media.toDTO(),
      required: this.required,
      html: this.html,
      ratingDisplay: this.ratingDisplay,
      decimalsAllowed: this.question.decimalsAllowed,
      minimumValueAllowed: this.question.minimumValueAllowed,
      maximumValueAllowed: this.question.maximumValueAllowed,
      numberStep: this.question.numberStep,
      defaultValueNumeric: this.question.defaultValueNumeric,
      isDropdownMultiSelect: this.isDropdownMultiSelect,
      placeholder: this.placeholder,
    }
  }
}
