import { RootStore } from '../../stores/RootStore'
import { observable, computed, action } from 'mobx'
import { CalendarVM } from './CalendarVM'
import { UserSurvey } from '../../user-surveys/aggregate/UserSurvey'
import moment from 'moment'
import { IEventPillVM } from '../intefaces/IEventPillVM'

export class UserSurveyPillVM implements IEventPillVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, userSurvey: UserSurvey, id?: string) {
    this.rootStore = rootStore
    this.userSurvey = userSurvey
    this.id = id
  }

  @observable public userSurvey: UserSurvey = null
  @observable public id: string = ''

  @computed
  public get title(): string {
    return this.userSurvey.name
  }

  @computed
  public get localStartDate(): Date {
    if (this.userSurvey.dueDate === null) return new Date()
    return moment(this.userSurvey.dueDate).toDate()
  }

  @computed
  public get localEndDate(): Date {
    if (this.userSurvey.dueDate === null) return new Date()
    return moment(this.userSurvey.dueDate).toDate()
  }

  @computed
  public get allDay(): boolean {
    return this.userSurvey.hasDueDate === null
  }

  @computed
  public get objectId(): string {
    return this.userSurvey.objectId
  }

  @computed
  public get calendarVM(): CalendarVM {
    return this.rootStore.eventsStore.calendarVM
  }

  @computed
  public get calendarIndex(): number {
    const idx = this.calendarVM.selectedOtherCalendars.findIndex((e) => e.id === this.id)
    if (idx === -1) return 0
    return idx + 1
  }
}
