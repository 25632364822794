import React from 'react'
import { inject, observer } from 'mobx-react'
import { AgGridReact } from 'ag-grid-react'
import { CircularProgress, Typography } from '@material-ui/core'
import './PostTemplatesTable.scss'
import { AnnouncementEditVM } from '../../announcements/view-models/AnnouncementEditVM'
import Searchbar from '../../shared/Searchbar'

interface Props {
  editVM: AnnouncementEditVM
}
const PostTemplatesTable: React.FC<Props> = ({ editVM }) => {
  if (!editVM.tableVM) editVM.loadTable()
  const vm = editVM.tableVM
  if (!vm) return

  const renderSpinner = () => {
    if (vm.shouldRender) return
    return (
      <div className={'table-spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  const renderSearch = () => {
    if (!vm.shouldRender) return
    return (
      <div className='search'>
        <Searchbar
          focused={vm.hasFilter}
          searchValue={vm.typedFilterText}
          onSearchChange={(e) => {
            vm.setQuickFilter(e.target.value)
          }}
          className='action-btn'
        />
      </div>
    )
  }

  const renderNoRowsContent = () => {
    if (vm.hasRows) return
    if (vm.loadingFirstPage) return
    return (
      <Typography component='div' className='no-rows'>
        No Templates To Show
      </Typography>
    )
  }

  const renderGrid = () => {
    if (!vm.shouldRender) return
    return (
      <div className={vm.hasRows ? 'ag-theme-alpine' : 'ag-theme-alpine ag-grid-no-rows'}>
        <AgGridReact
          gridOptions={{
            ...vm.getGridOptions(),
          }}
        />
      </div>
    )
  }

  return (
    <div id='PostTemplatesTable'>
      {renderSearch()}
      {renderSpinner()}
      {renderGrid()}
      {renderNoRowsContent()}
    </div>
  )
}

export default inject()(observer(PostTemplatesTable))
