import Parse from 'parse'
import { RootStore } from 'src/app/stores/RootStore'
import { IQuestionDTO } from '../dtos/IQuestionDTO'
import { IQuestionDTO as ISurveyQuestionDTO } from '../../surveys/dtos/IQuestionDTO'
import axios from 'axios'
import env from '../../../env'

export class QuestionsService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public async importQuestionsFromCsv(orgId, parseFile, doSave, batchId) {
    const result = await Parse.Cloud.run('importQuestionsFromCsv', {
      orgId,
      parseFile,
      doSave,
      batchId,
    })
    return result
  }

  public async saveCatalogQuestion(orgId: string, data: IQuestionDTO) {
    const result = await Parse.Cloud.run('saveCatalogQuestion', {
      orgId,
      data,
    })
    return result
  }

  public async deleteCatalogQuestion(objectId, orgId) {
    const result = await Parse.Cloud.run('deleteCatalogQuestion', {
      orgId,
      objectId,
    })
    return result
  }

  private getApiUrl(): string {
    return env.var.REACT_APP_API_URL
  }

  public async addToCatalog(orgId: string, data: IQuestionDTO) {
    const result = await Parse.Cloud.run('addQuestionToCatalog', {
      orgId,
      data,
    })
    return result
  }

  public async exportQuestionsFromSurveyToCsv(orgId: string, questions: ISurveyQuestionDTO[]) {
    const token = this.rootStore.userStore.parseUserInfo.sessionToken
    const url =
      this.getApiUrl() +
      '/surveys/exportquestions?sessionToken=' +
      token +
      '&organizationId=' +
      this.rootStore.appStore.currentOrgId
    const response = await axios.post(url, { questions })
    return response.data ? response.data : null
  }
}
