import React from 'react'
import { inject, observer } from 'mobx-react'
import { CustomCollectionsStore } from '../../store/CustomCollectionStore'
import { AgGridReact } from 'ag-grid-react'
import AGGridWidgetPagingRow from '../../../shared/ag-grid/AGGridPagingRow'

interface Props {
  customCollectionsStore?: CustomCollectionsStore
}

const Content: React.FC<Props> = ({ customCollectionsStore }) => {
  const { widgetVM: vm } = customCollectionsStore
  if (!vm) return null
  if (!vm.shouldRender) return null

  const renderGrid = () => {
    return (
      <div className='ag-theme-alpine' style={{ height: '100%', width: '100%' }}>
        <AgGridReact
          gridOptions={{
            ...vm.getGridOptions(),
          }}
        />
      </div>
    )
  }

  return (
    <>
      {renderGrid()}
      <AGGridWidgetPagingRow vm={vm} />
    </>
  )
}

export default inject('customCollectionsStore')(observer(Content))
