import React from 'react'
import { inject, observer } from 'mobx-react'
import { Button, Grid, Tooltip, Typography } from '@material-ui/core'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import LocalizationStore from '../../../../localization/LocalizationStore'
import Exercise from './Exercise'
import FileUploadModal from '../../../../upload/views/FileUploadModal'
import { Attachment } from '../../../../upload/aggregate/Attachment'
import { FileTypeEnum } from '../../../../upload/enum/FileTypeEnum'
import { UserTrainingPlanTakeVM } from '../../view-models/UserTrainingPlanTakeVM'
import CMSItemsPickerDialog from '../../../../cms-items/views/dialogs/CMSItemsPickerDialog'
import { CMSItemsTypeEnum } from '../../../../cms-items/enum/CMSItemsTypeEnum'
import { OrganizationsStore } from '../../../../organizations/store/OrganizationsStore'
import CMSMediaItemAttachment from './CMSMediaItemAttachment'
import OldMediaItemAttachment from './OldMediaItemAttachment'
import { OldMediaItemAttachmentVM } from '../../../../training-items/view-models/OldMediaItemAttachmentVM'
import { CMSMediaItemAttachmentVM } from '../../../../training-items/view-models/CMSMediaItemAttachmentVM'
import './ContentItem.scss'

interface Props {
  takeVM: UserTrainingPlanTakeVM
  localizationStore?: LocalizationStore
  organizationsStore?: OrganizationsStore
}

const ContentItem: React.FC<Props> = ({ takeVM, localizationStore, organizationsStore }) => {
  const { selectedContentItem: item } = takeVM
  const { training_items: lz } = localizationStore.lzStrings

  if (!item && !takeVM.selectedCertificateUploadId) return null

  const renderLinkContentItem = () => {
    if (item.type !== 'link') return null
    return (
      <>
        <Grid item xs={12}>
          <form>
            <Typography
              variant='body1'
              className='link-string'
              onClick={() => window.open(item.link)}
            >
              {item.link}
            </Typography>
          </form>
        </Grid>
      </>
    )
  }

  const renderHandsOnContentItem = () => {
    if (item.type !== 'hands-on') return null
    return (
      <>
        <Grid item xs={12}>
          <form>
            {item.trainer ? (
              <Grid item xs={12}>
                <span className='trainer-container'>
                  <AccountCircleIcon fontSize='large' />
                  <Typography variant='h6' style={{ marginLeft: '5px' }}>
                    ASSIGNED TRAINER:{' '}
                    <Tooltip title={`Email Trainer`} placement='bottom' enterDelay={100}>
                      <a className='mail-link' href={item.mailToLink} target='_blank'>
                        {item.trainer.name.toUpperCase()}
                      </a>
                    </Tooltip>
                  </Typography>
                </span>
              </Grid>
            ) : null}
          </form>
        </Grid>
      </>
    )
  }

  const renderExerciseContentItem = () => {
    if (item.type !== 'exercise') return null
    return (
      <>
        <Exercise takeVM={takeVM} />
      </>
    )
  }

  const renderMediaContentItem = () => {
    if (item.type !== 'media') return null
    if (!item.media) return null
    const doc = item.attachment

    const renderImageOrVideo = () => {
      if (doc.isCMSItem) return <CMSMediaItemAttachment vm={doc as CMSMediaItemAttachmentVM} />
      return <OldMediaItemAttachment vm={doc as OldMediaItemAttachmentVM} />
    }
    return (
      <>
        <Grid item xs={12}>
          <div className='media-background'>{renderImageOrVideo()}</div>
        </Grid>
      </>
    )
  }

  const renderFileContentItem = () => {
    if (item.type !== 'file') return null
    return (
      <>
        <Grid item xs={12}>
          <form>
            <Typography
              variant='body1'
              className='link-string'
              onClick={() => item.openAttachment()}
            >
              {item.fileName}
            </Typography>
          </form>
        </Grid>
      </>
    )
  }

  const renderDescriptionTitle = () => {
    if (!item) return
    if (!item.descriptionTitle) return
    return (
      <Typography className='description-area-title' variant='h5'>
        {item.descriptionTitle}
      </Typography>
    )
  }

  const renderDescriptionBody = () => {
    if (!item) return
    if (!item.descriptionBody) return
    return (
      <Typography
        className='description-area-body'
        variant='body1'
        style={{ whiteSpace: 'pre-line' }}
      >
        {item.descriptionBody}
      </Typography>
    )
  }

  const renderContentItem = () => {
    if (!item) return null
    return (
      <Grid container alignContent='flex-start' className='preview-container'>
        <Grid item xs={10} className='take-title-row'>
          <Typography variant='h5'>{item.title}</Typography>
        </Grid>
        <Grid item xs={2} className='mark-button-container'>
          <form>
            <Button
              variant='contained'
              className='link-button'
              type='button'
              disabled={!item.completionAllowed || takeVM.isReadOnly || item.planIsComplete}
              onClick={(e) => item.onAction()}
            >
              {item.buttonString}
            </Button>
          </form>
        </Grid>
        {renderLinkContentItem()}
        {renderMediaContentItem()}
        {renderFileContentItem()}
        {renderHandsOnContentItem()}
        {renderExerciseContentItem()}
        <Grid className='description-area' item xs={12}>
          {renderDescriptionTitle()}
          {renderDescriptionBody()}
        </Grid>
      </Grid>
    )
  }

  const renderUploadString = () => {
    if (takeVM.itemHasCertificate) {
      return takeVM.certificateUploadedString
    } else {
      return takeVM.certificateTitleString
    }
  }

  const renderUploadedFile = () => {
    if (!takeVM.itemHasCertificate) return null
    return (
      <Grid item xs={12} className='certificate-link'>
        <Typography variant='h6' onClick={() => takeVM.openCertificate()}>
          {takeVM.certificateFileName}
        </Typography>
      </Grid>
    )
  }

  const renderCertificateUpload = () => {
    if (item) return null
    return (
      <Grid container alignContent='flex-start' className='preview-container'>
        <Grid item xs={12} className='certificate-title'>
          <Typography variant='h5'>{renderUploadString()}</Typography>
        </Grid>
        {renderUploadedFile()}
        <Grid item xs={12} className='certificate-title'>
          <Button
            disabled={takeVM.certificateOpenAndDisabled}
            onClick={() => takeVM.toggleShowCompletionUploadModal()}
            variant='contained'
          >
            {takeVM.itemHasCertificate ? 'CHANGE FILE' : 'SELECT FILE'}
          </Button>
        </Grid>
      </Grid>
    )
  }

  const renderCertificateUploadModal = () => {
    if (organizationsStore.currentOrganization?.hasCMSPicker) return renderCMSUploadModal()
    return renderStandardUploadModal()
  }

  const renderCMSUploadModal = () => {
    return (
      <CMSItemsPickerDialog
        allowMultiple={false}
        fileTypes={CMSItemsTypeEnum.DEFAULT}
        addAfterSave={(a: Attachment[]) => {
          takeVM.setCertificate(a[0])
        }}
        toggleShowModal={() => takeVM.toggleShowCompletionUploadModal()}
        isOpen={takeVM.showCompletionUploadModal}
      />
    )
  }

  const renderStandardUploadModal = () => {
    return (
      <FileUploadModal
        allowMultiple={false}
        fileTypes={FileTypeEnum.DEFAULT}
        addAfterSave={(a: Attachment[]) => takeVM.setCertificate(a[0])}
        toggleShowModal={() => takeVM.toggleShowCompletionUploadModal()}
        isOpen={takeVM.showCompletionUploadModal}
      />
    )
  }

  return (
    <>
      {renderCertificateUploadModal()}
      {renderContentItem()}
      {renderCertificateUpload()}
    </>
  )
}

export default inject('localizationStore', 'organizationsStore')(observer(ContentItem))
