import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../localization/LocalizationStore'
import { QuestionsStore } from '../store/QuestionsStore'
import { CardHeader, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

interface Props {
  localizationStore?: LocalizationStore
  questionsStore?: QuestionsStore
}

const DrawerHeader: React.FC<Props> = ({ localizationStore, questionsStore }) => {
  const { lzStrings } = localizationStore
  const { editVM } = questionsStore

  return (
    <CardHeader
      action={
        <IconButton onClick={() => editVM.closeModal()}>
          <CloseIcon />
        </IconButton>
      }
      title={
        editVM.isNewQuestion
          ? lzStrings.questionsCatalogWidgetDrawer.add_question
          : lzStrings.questionsCatalogWidgetDrawer.edit_question
      }
    />
  )
}

export default inject('localizationStore', 'questionsStore')(observer(DrawerHeader))
