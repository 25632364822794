import React, { Component } from 'react'
import { observer } from 'mobx-react'
import rootStore from '../../stores/RootStore'
import './DashboardEditLayout.scss'
import DashboardEditWidgets from './DashboardEditWidgets'
import DashboardEditVM from '../view-models/DashboardEditVM'
import DashboardEditToolbar from './Toolbars/DashboardEditToolbar'

interface DashboardEditProps {
  children?: React.ReactElement
}

interface DashboardEditState {
  rowHeight: number
}

class DashboardEditLayout extends Component<DashboardEditProps, DashboardEditState> {
  layout: Array<any>
  rowsOnScreen: number
  margin: Array<number>
  RGLprops: any
  minRowHeight: number
  dashboard: any
  vm: DashboardEditVM

  constructor(props) {
    super(props)

    this.rowsOnScreen = 3

    const margin = [15, 15]
    this.margin = margin

    this.minRowHeight = 200
    const rowHeight = this.calcRowHeight()

    const breakpoints = { lg: 1200, md: 900, sm: 500, xs: 300, xxs: 0 }
    const cols = { lg: 4, md: 3, sm: 3, xs: 1, xxs: 1 }
    this.vm = rootStore.dashboardsStore.editVM
    this.RGLprops = {
      className: 'layout',
      breakpoints,
      cols,
      margin,
      compactType: 'vertical',
      onDragStart: this.onDragStart,
      onDrag: this.onDrag,
      onDragStop: this.onDragStop,
      onResize: this.setRect,
      onResizeStop: this.setRectFinish,
      onLayoutChange: this.onLayoutChange,
      preventCollision: false,
      isDraggable: true,
      isResizable: true,
    }
    this.state = { rowHeight }
  }

  componentDidMount() {
    this.resizeRows()
    window.addEventListener('resize', this.debounce(this.resizeRows, 200))
    rootStore.appStore.incrementWidgetResizeCounter()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debounce(this.resizeRows, 200))
  }

  render() {
    return (
      <div id='DashboardEditContainer' className={'edit-mode'} >
        <DashboardEditWidgets
          RGLprops={this.RGLprops}
          rowHeight={this.state.rowHeight}
          minRowHeight={this.minRowHeight}
          vm={this.vm}
        />
      </div>
    )
  }

  onLayoutChange = (layout) => {
    this.vm.setLayoutChange(layout)
  }

  onDragStart = (layout, oldItem, newItem, placeholder, e, element) => { }
  onDrag = (layout, oldItem, newItem, placeholder, e, element) => { }
  onDragStop = (layout, oldItem, newItem, placeholder, e, element) => { }

  setRect() {
    rootStore.appStore.incrementWidgetResizeCounter()
  }

  setRectFinish() {
    setTimeout(() => rootStore.appStore.incrementWidgetResizeCounter(), 500)
  }

  // Add delay to row resize to reduce re-render frequency
  debounce = (fn, delay) => {
    let timer
    return () => {
      clearTimeout(timer)
      timer = setTimeout(function () {
        timer = null
        fn.apply(this, arguments)
      }, delay)
    }
  }

  resizeRows = () => {
    let rowHeight = this.calcRowHeight()
    this.setState({ rowHeight })
  }

  calcRowHeight = () => {
    const container = document.getElementById('DashboardContainer')
    const dashHeader = document.getElementsByClassName('dashboard-header')[0]
    if (container && dashHeader) {
      const availableHeight =
        container.clientHeight - dashHeader.clientHeight - (this.rowsOnScreen + 1) * this.margin[1]
      return availableHeight / this.rowsOnScreen
    } else {
      return 200
    }
  }
}

export default observer(DashboardEditLayout)
