import React from 'react'
import { Button, CircularProgress, Grid, IconButton, Snackbar, Tooltip } from '@material-ui/core'
import { observer, inject } from 'mobx-react'
import { SurveysStore } from '../../../store/SurveysStore'
import { SurveyEditTabsEnum } from '../../../types/SurveyEditTabsEnum'
import './InvitationsTabPanel.scss'
import Searchbar from '../../../../shared/Searchbar'
import LinkDialog from './../../../../surveyParticipants/views/Dialogs/LinkDialog'
import CloseIcon from '@material-ui/icons/Close'
import CancelDialog from './../../../../surveyParticipants/views/Dialogs/CancelDialog'
import RemindDialog from './../../../../surveyParticipants/views/Dialogs/RemindDialog'
import SendDialog from './../../../../surveyParticipants/views/Dialogs/SendDialog'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import HistoryDialog from './../../../../surveyParticipants/views/Dialogs/HistoryDialog'
import SurveyParticipantsList from '../../../../surveyParticipants/views/SurveyParticipantsList'
import { LabelsStore } from '../../../../labels/store/LabelsStore'
import RefreshIcon from '@material-ui/icons/Refresh'
import SurveyEventSummaryDialog from 'src/app/userSurveyEvents/views/SurveyEventSummaryDialog'

interface Props {
  surveysStore?: SurveysStore
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
}

const InvitationsTabPanel: React.FC<Props> = ({ surveysStore, localizationStore, labelsStore }) => {
  const { editVM } = surveysStore.viewModels
  const { lzStrings } = localizationStore

  if (!editVM) return null
  if (!editVM.surveyParticipantsList) return null

  const { surveyParticipantsList: vm } = editVM

  const renderCopySnackbar = () => {
    if (!vm.showCopySnackbar) return null
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={vm.showCopySnackbar}
        autoHideDuration={6000}
        onClose={() => vm.toggleCopySnackbar()}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={
          <span id='message-id'>
            {`${labelsStore.getLabelByLanguageAndFor('pulse')} ${
              lzStrings.surveyInvitationsList.link_copied_toast
            }`}
          </span>
        }
        action={[
          <IconButton
            key='close'
            aria-label='close'
            color='inherit'
            onClick={() => vm.toggleCopySnackbar()}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    )
  }

  const renderSurveyParticipantsList = () => {
    return <SurveyParticipantsList />
  }

  const cancelButtonText = () => {
    if (vm.allSelected) return lzStrings.surveys.cancel_all
    else return lzStrings.surveys.cancel
  }

  const sendButtonText = () => {
    if (vm.allSelected) return lzStrings.surveys.send_all_invitations
    else return lzStrings.surveys.send_invitations
  }

  const remindButtonText = () => {
    if (vm.allSelected) return lzStrings.surveys.remind_all
    else return lzStrings.surveys.remind
  }

  const renderEmailSummaryButton = () => {
    if (!vm.isEmailSummaryAvailable) return null
    return (
      <Button
      variant='outlined'
      size='large'
      onClick={() => vm.showEmailSummaryDialog()}
      className='viewEmailSummaryButton'
      >
        {lzStrings.surveys.view_email_summary}
      </Button>
    )
  }

  const renderButtons = () => {
    if (!vm.hasResponses) return null
    return (
      <Grid container className='buttons'>
        <Grid item lg={12} md={12} className='buttons-container'>
          <Grid item lg={8} className='buttons-item'>
            <Tooltip title='Refresh participant list'>
              <Button
                variant='outlined'
                size='large'
                onClick={() => vm.hardRefreshParticipantsList()}
                className='refreshButton'
              >
                <RefreshIcon />
              </Button>
            </Tooltip>
            <Button
              disabled={vm.newSelectedRows.length === 0 || !vm.batchActionButtonsActive}
              variant='outlined'
              size='large'
              onClick={() => vm.toggleCancelDialog()}
              className='cancelButton'
            >
              {cancelButtonText()}
            </Button>
            <Button
              disabled={vm.newSelectedRows.length === 0 || !vm.batchActionButtonsActive}
              variant='outlined'
              size='large'
              onClick={() => vm.toggleRemindDialog()}
              className='remindButton'
            >
              {remindButtonText()}
            </Button>
            <Button
              disabled={vm.newSelectedRows.length === 0 || !vm.batchActionButtonsActive}
              variant='outlined'
              size='large'
              onClick={() => vm.toggleSendDialog()}
              className='sendInviteButton'
            >
              {sendButtonText()}
            </Button>
            {renderSelectedCount()}
            {renderSpinner()}
          </Grid>
          <Grid item>
            {renderEmailSummaryButton()}
            <Searchbar
              focused={vm.typedFilterText.length > 0}
              className='search'
              searchValue={vm.typedFilterText}
              onSearchChange={(e) => vm.setQuickFilter(e.target.value)}
            />
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderSelectedCount = () => {
    if (!vm.hasResponses) return null
    if (!vm.newSelectedRows.length) return null
    if (!vm.batchActionButtonsActive) return null
    if (vm.selectAll) {
      return (
        <span className={'number-selected'}>
          {vm.calculatedSelected} {lzStrings.surveys.selected}
        </span>
      )
    } else {
      return (
        <span className={'number-selected'}>
          {vm.newSelectedRows.length} {lzStrings.surveys.selected}
        </span>
      )
    }
  }

  const renderSpinner = () => {
    if (vm.batchActionButtonsActive) return null
    return (
      <div className='search'>
        <CircularProgress className='progressCircle' size='1.5rem' />
        <p className='progress-text'>{vm.processingMsg}</p>
      </div>
    )
  }

  return (
    <>
      <div id='InvitationsTabPanel'>
        {renderCopySnackbar()}
        <LinkDialog />
        <CancelDialog />
        <RemindDialog />
        <SendDialog />
        <HistoryDialog />
        <SurveyEventSummaryDialog />
        {renderButtons()}
        {renderSurveyParticipantsList()}
      </div>
    </>
  )
}

export default inject(
  'surveysStore',
  'localizationStore',
  'labelsStore'
)(observer(InvitationsTabPanel))
