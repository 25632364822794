import { Grid, LinearProgress, Typography } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import React from 'react'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { TrainingPlansStore } from '../../../../training-plans/store/TrainingPlansStore'
import { TabEnums } from '../../../../training-plans/types/TabsEnums'
import UserTrainingPlanTakeWidget from '../../../user-training-plans/views/take/UserTrainingPlanTakeWidget'
import './PreviewTabPanel.scss'

interface Props {
  trainingPlansStore?: TrainingPlansStore
  localizationStore?: LocalizationStore
}

const PreviewTabPanel: React.FC<Props> = ({ trainingPlansStore, localizationStore }) => {
  const { training_plans: strings } = localizationStore.lzStrings
  const { trainingPlanEditVM: vm } = trainingPlansStore
  if (vm.currentTab !== TabEnums.PREVIEW) return null

  return (
    <Grid container id='TrainingPlanPreviewTabPanel'>
      <UserTrainingPlanTakeWidget takeVM={vm.previewVM} />
    </Grid>
  )
}

export default inject('trainingPlansStore', 'localizationStore')(observer(PreviewTabPanel))
