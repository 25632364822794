import React, { FC } from 'react'
import { inject, observer } from 'mobx-react'
import { ParticipantsSelectVM } from '../view-models/ParticipantsSelectVM'
import { GroupRowVM } from '../view-models/GroupRowVM'
import { AgGridReact } from 'ag-grid-react'
import Searchbar from '../../shared/Searchbar'
import GroupedRow from './GroupedRow'
import '../styles/GroupsList.scss'

interface Props {
  participantsSelectVM: ParticipantsSelectVM
}

const GroupsList: FC<Props> = ({ participantsSelectVM: vm }) => {
  if (!vm.groupsAGGridVM) return

  const renderNameCell = (props) => {
    const row: GroupRowVM = props.data
    if (!row) return null
    return <GroupedRow row={row} />
  }

  const renderGrid = () => {
    return (
      <div id='GroupsListGridContainer'>
        <div
          id='GroupsListGrid'
          style={{ display: vm.groupsAGGridVM.shown ? undefined : 'none' }}
          className='ag-theme-alpine'
        >
          <div id='GroupsListAGGrid'>
            <AgGridReact
              gridOptions={{
                ...vm.groupsAGGridVM.getGridOptions(),
                frameworkComponents: {
                  nameCell: renderNameCell,
                },
              }}
            />
          </div>
        </div>
      </div>
    )
  }

  const renderSearchbox = () => {
    if (!vm.groupsAGGridVM.shown) return
    return (
      <Searchbar
        focused={vm.groupsAGGridVM.hasFilter}
        className='search'
        searchValue={vm.groupsAGGridVM.typedFilterText}
        onSearchChange={(e) => vm.groupsAGGridVM.setQuickFilter(e.target.value)}
      />
    )
  }

  return (
    <div
      id='ParticipantsSelect_GroupsList'
      style={{
        display: vm.groupsAGGridVM.shown ? undefined : 'none',
      }}
    >
      <div id='GroupsListCol'>
        {renderSearchbox()}
        {renderGrid()}
      </div>
    </div>
  )
}

export default inject('rootStore')(observer(GroupsList))
