import { action, observable, computed } from 'mobx'
import { IAggregate } from '../../shared/data/IAggregate'
import { IContactDTO } from '../dtos/IContactDTO'
import { serializable, serialize } from 'serializr'

export class Contact implements IContactDTO, IAggregate {
  @serializable  @observable objectId: string = ''
  @serializable  @observable organizationId: string = ''
  @serializable  @observable name: string = ''
  @serializable  @observable clientId: any = null
  @serializable  @observable title: string = ''
  @serializable  @observable email: string = ''
  @serializable  @observable isDefault: boolean = false
  @serializable  @observable isProcessing: boolean = false
  @serializable  @observable isDeleted: boolean = false
  @serializable  @observable type: string = 'contact'
  isOnServer: boolean = false

  static create(orgId) {
    const surveyType = new Contact()
    surveyType.organizationId = orgId
    return surveyType
  }

  @action
  markIsNotOnServer() {
    this.isOnServer = false
  }

  @action
  markIsOnServer() {
    this.isOnServer = true
  }

  @action
  markIsNotProcessing() {
    this.isProcessing = false
  }

  @action
  markIsProcessing() {
    this.isProcessing = true
  }

  public serialize(): IContactDTO {
    return serialize(this)
  }

}
