import { observable, action } from 'mobx'
import { serializable, serialize, deserialize, list, object } from 'serializr'
import { Attachment } from '../../attachments/aggregate/Attachment'
import { IAggregate } from '../../shared/data/IAggregate'
import { MediaItemType } from '../../upload/types/MediaItemType'
import { ICMSPostTemplateDTO } from '../dtos/ICMSPostTemplateDTO'

export class PostTemplate implements ICMSPostTemplateDTO, IAggregate {
  public static create(template): PostTemplate {
    const postTemplate = new PostTemplate()
    postTemplate.objectId = template.id
    postTemplate.title = template.title
    postTemplate.body = template.body
    postTemplate.createdAt = template.createdAt
    postTemplate.createdBy = template.createdBy
    postTemplate.updatedAt = template.updatedAt
    postTemplate.updatedBy = template.updatedBy
    if (template.items) {
      const added = template.items.map((item) => {
        const attachment = new Attachment()
        attachment.cmsItemId = item.id
        attachment.objectId = item.id.toString()
        attachment.fileName = item.Title
        attachment.type = item.Type as MediaItemType
        return attachment
      })
      postTemplate.attachments = added
    }
    return postTemplate
  }

  @serializable @observable public body: string = ''
  @serializable @observable public title: string = ''
  @serializable @observable public objectId: string = ''
  @serializable @observable public isDeleted: boolean = false
  @serializable @observable public createdBy: string = ''
  @serializable @observable public updatedBy: string = ''
  @serializable @observable public createdAt: string = ''
  @serializable @observable public updatedAt: string = ''
  @serializable(list(object(Attachment))) @observable public attachments: Attachment[] = []

  isOnServer: boolean = false

  @action
  public markIsOnServer() {
    this.isOnServer = true
  }

  @action
  public markIsNotOnServer() {
    this.isOnServer = true
  }

  public serialize() {
    const obj = serialize(this)
    return obj
  }

  public clone(): PostTemplate {
    return deserialize(PostTemplate, this.serialize())
  }
}
