import React from 'react'
import clsx from 'clsx'
import { inject, observer, useLocalStore } from 'mobx-react'
import {
  AppBar,
  ButtonBase,
  CssBaseline,
  Toolbar,
  Typography,
} from '@material-ui/core'
import rootStore from '../../../stores/RootStore'
import isIE11 from '../../../../utils/isIE11'
import '../../../css/dashboard-toolbar.scss'
import { PulseCampaignsTopToolbarVM } from '../../view-models/dashboard/PulseCampaignsTopToolbarVM'

const PulseCampaignsTopToolbar: React.FC = () => {
  const localStore = useLocalStore(() => ({
    vm: new PulseCampaignsTopToolbarVM(rootStore),
  }))
  const vm = localStore.vm
  const { pulseCampaignDashboard: lz } = rootStore.localizationStore.lzStrings

  const renderTabs = () => {
    return (
      <div className='tab-btns'>
          <ButtonBase
            className={vm.isCampaignsSelected ? 'tab-button active' : 'tab-button'}
            onClick={() => vm.setSelectedCampaignsRoute(0)}
          >
            {lz.dashboard}
          </ButtonBase>
          {/* <ButtonBase
            className={vm.isCampaignTemplatesSelected ? 'tab-button active' : 'tab-button'}
            onClick={() => vm.setSelectedCampaignsRoute(1)}
          >
            {lz.templates}
          </ButtonBase> */}
      </div>
    )
  }

  return (
    <div id='DashboardToolbar' className={'dashboardToolbar-header'}>
      <CssBaseline />
      <AppBar
        className={clsx('dashboard-appbar', {
          'no-transition': isIE11,
        })}
        elevation={0}
      >
        <Toolbar className={'dashboard-toolbar'}>
          <Typography className='dashboard-title'>{lz.campaign}</Typography>
          {renderTabs()}
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default inject('labelsStore')(observer(PulseCampaignsTopToolbar))
