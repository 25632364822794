import { action, computed, observable } from 'mobx'
import { debounce } from 'lodash'
import { RootStore } from '../../stores/RootStore'
import { ICMSItemUploadRequest } from '../interfaces/ICMSItemUploadRequest'
import { ICMSItemDTO } from '../interfaces/ICMSItemDTO'
import { CMSItemsFindService } from '../service/CMSItemsFindService'
import { IFilterParams } from '../interfaces/IFilterParams'
import { ICMSItemsListRequest } from '../interfaces/ICMSItemsListRequest'
import { CMSItemsTypeEnum } from '../enum/CMSItemsTypeEnum'
import { ICMSItemTag } from '../../cms-item-tags/interfaces/ICMSItemTag'
import { CMSItemTagsFindService } from 'src/app/cms-item-tags/services/CMSItemTagsFindService'
import { CMSAuthService } from '../../cms-auth/services/CMSAuthService'

export class CMSItemsStore {
  private rootStore: RootStore
  private request: ICMSItemUploadRequest
  private svc: CMSItemsFindService

  constructor(rootStore: RootStore, contentType: CMSItemsTypeEnum = CMSItemsTypeEnum.DEFAULT) {
    this.rootStore = rootStore
    this.svc = new CMSItemsFindService(this.rootStore)
    this.contentType = contentType
    this.loadData().then(() => {
      const req = {
        userId: this.rootStore.appStore.currentUserId,
        organizationId: this.rootStore.appStore.currentOrgId,
        sort: undefined,
        pagination: undefined,
        search: undefined,
        filter: {
          types: this.fileTypes,
        } as IFilterParams,
        tags: undefined,
        access: undefined,
      } as ICMSItemsListRequest

      this.loadListRecords(req)
    })
  }

  @observable public isLoaded: boolean = false
  @observable public hasNextPage: boolean = false
  @observable public isNextPageLoading: boolean = false
  @observable.shallow private records: ICMSItemDTO[] = []
  @observable public tags: ICMSItemTag[] = []
  @observable public isRefreshing: boolean = true
  @observable public contentType: CMSItemsTypeEnum = CMSItemsTypeEnum.DEFAULT

  public async loadData() {
    const tags = await new CMSItemTagsFindService(this.rootStore).getTags()
    this.setTagValues(tags)
    return true
  }

  @action
  private setTagValues(tags: ICMSItemTag[]) {
    this.tags = tags
  }

  @action
  public setRecords(records: ICMSItemDTO[]) {
    this.records = records
  }

  @action
  private debounceFn = debounce((fn: Function) => {
    this.handleDebounceFn(fn)
  }, 350)

  @action
  private handleDebounceFn(fn: Function) {
    fn()
  }

  public async loadNextPage(req: ICMSItemsListRequest): Promise<boolean> {
    const loadFn = async () => {
      if (this.isNextPageLoading) return
      this.isNextPageLoading = true

      const newRecords = await this.svc.getItems(req)

      this.setRecords([...this.records].concat(newRecords))
      if (newRecords.length < this.svc.DEFAULT_PAGINATION.limit) this.hasNextPage = false
      else this.hasNextPage = true

      this.onListRecordsLoaded()
    }
    this.debounceFn(loadFn)

    return true
  }

  public async loadListRecords(req: ICMSItemsListRequest): Promise<boolean> {
    this.isRefreshing = true
    const newRecords = await this.svc.getItems(req)

    this.setRecords(newRecords ? newRecords : [])
    if (newRecords && newRecords.length < this.svc.DEFAULT_PAGINATION.limit)
      this.hasNextPage = false
    else this.hasNextPage = true

    this.onListRecordsLoaded()

    return true
  }

  @action
  public onListRecordsLoaded() {
    this.isRefreshing = false
    this.isLoaded = true
    this.isNextPageLoading = false
  }

  @action
  public setRequest(request: ICMSItemUploadRequest) {
    this.request = request
  }

  @action
  public getRequest(): ICMSItemUploadRequest {
    return this.request
  }

  @computed
  public get rows() {
    return this.records
  }

  @computed
  public get totalRecords(): number {
    return this.records.length
  }

  @computed
  public get fileTypes(): string[] {
    return this.contentType.split(',')
  }
}
