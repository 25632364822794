import { observable, computed, action, reaction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { WeightProfile } from '../aggregate/WeightProfile'
import { RoleVM } from './RoleVM'
import { Category } from '../aggregate/Category'
import { ParseService } from '../../services/ParseService'

export class WeightProfileVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, roleVM: RoleVM, weightProfile: WeightProfile) {
    this.rootStore = rootStore
    this.weightProfile = weightProfile
    this.roleVM = roleVM
    if (this.weightProfile.isNew) {
      this.loadDefaultCategories()
      this.loadDefaultRoleId()
    }
    this.ensureAllCategories()
  }

  @observable public weightProfile: WeightProfile = null
  @observable public roleVM: RoleVM = null
  @observable public saveTried: boolean = false
  @observable public isProcessing: boolean = false
  @observable public isDirty: boolean = false
  @observable public showSaveSnackbar: boolean = false
  @observable public showCopySnackbar: boolean = false

  @action
  public ensureAllCategories() {
    if (this.isNew) return
    this.rootStore.categoriesStore.currentOrgCategories.forEach((cat) => {
      const foundCat = this.weightProfile.categories.find((e) => e.categoryId === cat.objectId)
      if (!foundCat) this.weightProfile.categories.push(Category.create(cat))
    })
  }

  @action
  public loadDefaultCategories() {
    this.weightProfile.categories = this.rootStore.categoriesStore.currentOrgCategories.map((cat) =>
      Category.create(cat)
    )
  }

  @action
  public loadDefaultRoleId() {
    this.weightProfile.setRoleId(this.roleVM.objectId)
  }

  @computed
  public get parentCategoryWeightsValid() {
    if (!this.saveTried) return true
    let checkedParents = this.roleVM.categories.filter((e) => e.isChecked)
    if (!checkedParents.length) return true
    let totalWeight = 0
    this.roleVM.categories.forEach((cat) => {
      if (cat.isChecked) totalWeight += Number(cat.weight)
    })
    return Boolean(totalWeight === 100 || totalWeight === 99)
  }

  @computed
  public get subCategoryWeightsValid() {
    if (!this.saveTried) return true
    let isValid = true
    const findInvalidWeight = (categories) => {
      const invalidWeight = categories.find((e) => !e.childrenWeightValid)
      if (invalidWeight) isValid = false
      else
        categories.forEach((cat) => {
          if (cat.hasChildren) findInvalidWeight(cat.children)
        })
    }

    findInvalidWeight(this.roleVM.categories)

    return isValid
  }

  @computed
  public get subCategoryCheckedValid() {
    // if (!this.saveTried) return true
    let isValid = true
    const findInvalidCheck = (categories) => {
      const invalidCheck = categories.find((e) => !e.childrenSelectedValid)
      if (invalidCheck) isValid = false
      else
        categories.forEach((cat) => {
          if (cat.hasChildren) findInvalidCheck(cat.children)
        })
    }

    findInvalidCheck(this.roleVM.categories)

    return isValid
  }

  @computed
  public get isNew() {
    return this.weightProfile.isNew
  }

  @computed
  public get roleId() {
    return this.weightProfile.roleId
  }

  @computed
  public get categories() {
    return this.weightProfile.categories
  }

  @action
  public toggleSaveSnackbar() {
    this.showSaveSnackbar = !this.showSaveSnackbar
  }

  @action
  public toggleCopySnackbar() {
    this.showCopySnackbar = !this.showCopySnackbar
  }

  @action
  public markSaveTried() {
    this.saveTried = true
  }

  @action
  public async save() {
    this.markSaveTried()
    if (!this.subCategoryWeightsValid) return
    if (!this.parentCategoryWeightsValid) return
    if (!this.subCategoryCheckedValid) return
    this.isProcessing = true
    const svc = new ParseService()

    await svc.saveWeightProfile(
      this.rootStore.appStore.currentOrgId,
      this.weightProfile.serialize()
    )

    this.toggleSaveSnackbar()

    setTimeout(() => {
      this.isProcessing = false
      this.roleVM.lazyLoadWeightProfileVM(this.roleVM.objectId)
    }, 1000)
  }

  @action
  public async delete() {
    this.isProcessing = true
    this.roleVM.editVM.toggleDeleteDialog()
    const svc = new ParseService()
    await svc.deleteWeightProfile(this.weightProfile.objectId, this.rootStore.appStore.currentOrgId)

    setTimeout(() => {
      this.roleVM.lazyLoadWeightProfileVM('new')
      this.isProcessing = false
    }, 1000)
  }
}
