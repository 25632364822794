import React from 'react'
import { Tooltip } from '@material-ui/core'
import SkillsIcon from '@material-ui/icons/BarChartRounded'
import { IconButton } from '@material-ui/core'
import './ActionsCell.scss'
import { observer } from 'mobx-react'
import { AnnouncementListRowVM } from '../../view-models/list/AnnouncementListRowVM'
import EditIcon from '@material-ui/icons/Edit'

const ActionsCell = (props) => {
  const data: AnnouncementListRowVM = props.data
  if (!data) return <div></div>

  const renderEditAnnouncement = () => {
    return (
      <Tooltip title='Edit' placement='bottom-start' enterDelay={500}>
        <IconButton
          style={{ padding: '3px' }}
          onClick={(e) => data.editAnnouncement(e.currentTarget)}
        >
          <EditIcon id='announcement-edit' />
        </IconButton>
      </Tooltip>
    )
  }

  const renderGetAnnouncementStats = () => {
    return (
      <Tooltip title={'Stats'} placement='bottom-start' enterDelay={500}>
        <IconButton style={{ padding: '3px' }} onClick={() => data.viewAnalytics()}>
          <SkillsIcon id='announcement-stats' />
        </IconButton>
      </Tooltip>
    )
  }

  return (
    <div className='announcements-list-actions-cell'>
      {renderEditAnnouncement()}
      {renderGetAnnouncementStats()}
    </div>
  )
}

export default observer(ActionsCell)
