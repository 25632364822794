import Parse from 'parse'
import { RootStore } from '../../stores/RootStore'
import { ISurveysFindRequest } from '../interfaces/ISurveysFindRequest'
import { ISurveysFindResult } from '../interfaces/ISurveysFindResult'
import { ISurveyTemplatesFindResult } from '../interfaces/ISurveyTemplatesFindResult'
import { ISurveyTemplatesFindRequest } from '../interfaces/ISurveyTemplatesFindRequest'

export class SurveysService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public async closeSurvey(surveyId, orgId) {
    const result = await Parse.Cloud.run('closeSurvey', { surveyId, orgId })
    return result
  }

  public async refreshSurveyParticipants(surveyId, orgId) {
    const result = await Parse.Cloud.run('refreshSurveyParticipants', { surveyId, orgId })
    return result
  }

  public async processShortAnswers(surveyId, orgId) {
    const result = await Parse.Cloud.run('processShortAnswers', { surveyId, orgId })
    return result
  }

  public async openSurvey(surveyId: string, orgId) {
    const result = await Parse.Cloud.run('openSurvey', { surveyId, orgId })
    return result
  }

  public async getSurveysForUser(request: ISurveysFindRequest): Promise<ISurveysFindResult> {
    return await Parse.Cloud.run('getSurveysForUser', { request })
  }

  public async getSurveyTemplatesForUser(
    request: ISurveyTemplatesFindRequest
  ): Promise<ISurveyTemplatesFindResult> {
    return await Parse.Cloud.run('getSurveyTemplatesForUser', { request })
  }

  public async sendSurveyRemindersToAllOpenUserSurveys(orgId: string, surveyId: string) {
    return await Parse.Cloud.run('sendSurveyReminders', { orgId, surveyId })
  }

  public async refreshSurveyResponses(orgId: string, surveyId: any) {
    return await Parse.Cloud.run('refreshSurveyResponses', { orgId, surveyId })
  }
}
