import { CardContent, CircularProgress, Grid, Typography } from '@material-ui/core'
import React, { Fragment } from 'react'
import { QuizTakeVM } from '../../quiz/view-models/QuizTakeVM'
import { UserTrainingPlansStore } from '../../training-plans/user-training-plans/store/UserTrainingPlansStore'
import { inject, observer } from 'mobx-react'
import './QuizStats.scss'
import { QuizResultsVM } from '../view-models/QuizResultsVM'
import CircularProgressBar from './CircularProgressBar'
import LocalizationStore from '../../localization/LocalizationStore'

interface Props {
  vm: QuizResultsVM
  localizationStore?: LocalizationStore
}

const QuizStats: React.FC<Props> = ({ vm, localizationStore }) => {
  if (!vm) return null
  const lz = localizationStore.lzStrings.quiz

  const renderTitle = () => {
    return (
      <Grid item xs={12} className={'quiz-stats-title'}>
        <Typography className={'quiz-stats-title-text'}>{lz.quiz_stats}</Typography>
      </Grid>
    )
  }

  const renderYourScore = () => {
    return (
      <Grid container item xs={2} className={'your-score-col'}>
        <CircularProgressBar progress={vm.myScore} />
        <div className={'your-score-view'}>
          <Typography className={'quiz-stats-col-text'}>{lz.your_score}</Typography>
          <Typography className={'your-score-text'}>{vm.myScore}%</Typography>
        </div>
      </Grid>
    )
  }

  const renderAverageScore = () => {
    return (
      <Grid item xs={2} className={'quiz-stats-col'}>
        <Typography className={'quiz-stats-col-text'}>{lz.average_score}</Typography>
        <Typography className={'stat-text'}>{vm.averageScore}%</Typography>
      </Grid>
    )
  }

  const renderCorrectResponses = () => {
    return (
      <Grid item xs={2} className={'quiz-stats-col'}>
        <Typography className={'quiz-stats-col-text'}>{lz.correct_responses}</Typography>
        <Typography className={'stat-text'}>{vm.questionsCorrect}/{vm.questionsToScore}</Typography>
      </Grid>
    )
  }

  const renderCompletionDate = () => {
    return (
      <Grid item xs={4} className={'quiz-stats-col'}>
        <Typography className={'quiz-stats-col-text'}>{lz.completion_date}</Typography>
        <Typography className={'stat-text'}>{vm.formattedCompletionDate}</Typography>
      </Grid>
    )
  }

  const renderContent = () => {
    return (
      <Grid container className={'quiz-stats-container'}>
        {renderTitle()}
        {renderYourScore()}
        {renderAverageScore()}
        {renderCorrectResponses()}
        {renderCompletionDate()}
      </Grid>
    )
  }

  return (
    <div id='QuizStats'>
      {renderContent()}
    </div>
  )
}

export default inject('localizationStore')(observer(QuizStats))
