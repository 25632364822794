import React, { FC } from 'react'
import { observer, inject } from 'mobx-react'
import './SurveyOptions.scss'
import { SurveysStore } from 'src/app/surveys/store/SurveysStore'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ScheduleTimePicker from './ScheduleTimePicker'
import CreateCustomPublishGroup from './CreateCustomPublishGroup'
import CustomPublishGroupDialog from './CustomPublishGroupDialog'
import { LabelsStore } from '../../../../labels/store/LabelsStore'

interface Props {
  surveysStore?: SurveysStore
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
}

const WhenToPublish: FC<Props> = ({ surveysStore, localizationStore, labelsStore }) => {
  const surveyEditVM = surveysStore.viewModels.editVM
  const vm = surveyEditVM.surveyOptionsVM
  if (!surveyEditVM) return null
  if (!vm) return null
  const s = localizationStore.lzStrings.surveys

  const handleOnChange = (evt, val) => {
    if (val !== null) {
      surveyEditVM.setSurveyPublishModeType(val)
      if (val === 'later') {
        if (surveyEditVM.customPublishEnabled) surveyEditVM.toggleCustomPublishGroup()
        if (!surveyEditVM.scheduleEnabled) surveyEditVM.toggleScheduleEnabled()
        vm.customPublishVM.clearAllCustomPublishGroups()
      } else if (val === 'multiple') {
        if (!surveyEditVM.scheduleEnabled) surveyEditVM.toggleScheduleEnabled()
        if (!surveyEditVM.customPublishEnabled) surveyEditVM.toggleCustomPublishGroup()
      } else {
        if (surveyEditVM.customPublishEnabled) surveyEditVM.toggleCustomPublishGroup()
        if (surveyEditVM.scheduleEnabled) surveyEditVM.toggleScheduleEnabled()
        vm.customPublishVM.clearAllCustomPublishGroups()
      }
    }
  }

  return (
    <div className='section'>
      <div className='primary-text'>{`${s.when_to_publish} ${labelsStore.getLabelByLanguageAndFor(
        'pulse'
      )}`}</div>
      <div className='section-content'>
        <ToggleButtonGroup
          value={surveyEditVM.surveyPublishModeType}
          exclusive
          onChange={handleOnChange}
        >
          <ToggleButton value={'immediately'}>{s.send_immediately}</ToggleButton>
          <ToggleButton value={'later'}>{s.at_a_later_time}</ToggleButton>
          <ToggleButton value={'multiple'}>{s.on_multiple_dates}</ToggleButton>
        </ToggleButtonGroup>
      </div>
      {surveyEditVM.customPublishEnabled ? <CreateCustomPublishGroup /> : null}
      {surveyEditVM.scheduleEnabled ? <ScheduleTimePicker /> : null}
      <CustomPublishGroupDialog />
    </div>
  )
}

export default inject('surveysStore', 'localizationStore', 'labelsStore')(observer(WhenToPublish))
