import React from 'react'
import { inject, observer } from 'mobx-react'
import { Button, Menu, MenuItem, IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import RefreshIcon from '@material-ui/icons/Refresh'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import SendSharpIcon from '@material-ui/icons/SendSharp'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { LabelsStore } from '../../../../labels/store/LabelsStore'
import { CampaignDetailsVM } from '../../../view-models/dashboard/campaign-details/CampaignDetailsVM'

interface Props {
  detailsVM: CampaignDetailsVM
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
}

const EndButtons: React.FC<Props> = ({ detailsVM, localizationStore, labelsStore }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const s = localizationStore.lzStrings
  if (!detailsVM) return null

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const renderCloseSurveyOption = () => {
    if (!detailsVM.canCloseSurvey) return null
    return (
      <MenuItem
        onClick={() => {
          detailsVM.toggleCloseSurveyDialog()
          setAnchorEl(null)
        }}
      >
        <DeleteIcon className='icon' />
        {`Close ${labelsStore.getLabelByLanguageAndFor('pulse')}`}
      </MenuItem>
    )
  }

  const renderEditSurveyOption = () => {
    if (!detailsVM.canEditSurvey) return null
    return (
      <MenuItem
        onClick={() => {
          detailsVM.openSurveyForEdit()
          setAnchorEl(null)
        }}
      >
        <EditIcon className='icon' />
        {`Edit ${labelsStore.getLabelByLanguageAndFor('pulse')}`}
      </MenuItem>
    )
  }

  const renderRefreshSurveyOption = () => {
    if (!detailsVM.canRefreshSurvey) return null
    return (
      <MenuItem
        onClick={() => {
          detailsVM.refreshSurveyInDW()
          setAnchorEl(null)
        }}
      >
        <RefreshIcon className='icon' />
        Refresh DW Data
      </MenuItem>
    )
  }

  const renderSendSurveyNowOption = () => {
    if (!detailsVM.canSendSurveyNow) return null
    return (
      <MenuItem
        onClick={() => {
          detailsVM.sendSurveyNow()
          setAnchorEl(null)
        }}
      >
        <SendSharpIcon className='icon' />
        Send Pulse Now
      </MenuItem>
    )
  }

  const renderMenu = () => {
    return (
      <>
        <IconButton aria-controls='simple-menu' aria-haspopup='true' onClick={handleClick}>
          <MoreHorizIcon />
        </IconButton>
        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          style={{ marginTop: 40 }}
        >
          {renderEditSurveyOption()}
          {renderRefreshSurveyOption()}
          {renderCloseSurveyOption()}
          {renderSendSurveyNowOption()}
        </Menu>
      </>
    )
  }

  return (
    <div slot='end' className='end-buttons'>
      {renderMenu()}
    </div>
  )
}

export default inject('localizationStore', 'labelsStore')(observer(EndButtons))
