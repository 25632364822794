import { serializable } from 'serializr'
import { RootStore } from '../../../stores/RootStore'
import { action, computed, observable } from 'mobx'
import { ColDef, GridOptions, RowClickedEvent } from 'ag-grid-community'
import { NotificationTemplateRowVM } from './NotificationTemplateRowVM'
import { nameOf } from '../../../shared/nameOf'
import { AGGridVM } from '../../../shared/ag-grid/AGGridVM'

export class NotificationsWidgetVM extends AGGridVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    super()
    this.rootStore = rootStore
  }

  @serializable @observable public isSystemOnly: boolean = false
  @serializable @observable public channels: string[] = ['PUSH', 'TEXT', 'EMAIL']

  @computed
  public get rows() {
    const notificationsStore = this.rootStore.notificationsStore
    let templates = notificationsStore.currentOrgNotificationTemplates
    if (this.isSystemOnly) templates = notificationsStore.currentOrgDefaultNotificationTemplates
    if (this.channels)
      templates = templates.filter((t) => this.channels.includes(t.channel))

    return templates.map(
      (template) => new NotificationTemplateRowVM(this.rootStore, template, this)
    )
  }

  @action
  public getRowNodeId(row): string {
    return row.objectId
  }

  public get shouldRender(): boolean {
    if (!this.rootStore.notificationsStore.loaded) return false
    return true
  }

  public getGridOptions(): GridOptions {
    return {
      ...this.baseGridOptions,
      rowData: this.rows,
      onRowClicked: (e) => this.rowClicked(e),
      getRowNodeId: (e) => this.getRowNodeId(e),
      columnDefs: this.ensureTooltipFields(this.columnDefs),
    }
  }

  public rowClicked(e: RowClickedEvent) {
    const row: NotificationTemplateRowVM = e.data
    if (!row) return
    row.openNotification()
  }

  @computed
  public get isLoading(): boolean {
    return this.rootStore.notificationsStore.loaded
  }

  @computed
  public get columnDefs(): ColDef[] {
    const s = this.rootStore.localizationStore.lzStrings
    return [
      {
        headerName: s.notificationsWidget.type,
        field: nameOf<NotificationTemplateRowVM, string>((e) => e.notificationTypeId),
        sort: 'asc',
      },
      {
        headerName: s.notificationsWidget.channel,
        field: nameOf<NotificationTemplateRowVM, string>((e) => e.channel),
        width: 100,
      },
      {
        headerName: s.notificationsWidget.language,
        field: nameOf<NotificationTemplateRowVM, string>((e) => e.language),
        width: 110,
      },
      {
        headerName: s.notificationsWidget.customized,
        hide: this.isSystemOnly,
        field: nameOf<NotificationTemplateRowVM, boolean>((e) => e.isDefault),
        valueFormatter: ({ value }) => {
          const isDefault = value
          return isDefault ? s.notificationsWidget.no : s.notificationsWidget.yes
        },
      }
    ]
  }
}
