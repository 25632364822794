import { action, observable, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Group } from '../aggregate/Group'
import PrivilegeSetRowVM from './PrivilegeSetRowVM'
import _ from 'lodash'

export default class GroupBasicInfoVM {
  rootStore: RootStore
  constructor(rootStore: RootStore, group?: Group) {
    this.rootStore = rootStore
    if (group) this.loadData(group)
    this.loadPrivilegeSets()
  }

  private loadData(group: Group) {
    this.group = group
    this.name = group.name
    this.objectId = group.objectId
    this.organizationId = group.organizationId
    if (group.privilegeSets) this.selectedPrivilegeSets = group.privilegeSets
    this.description = group.description
    this.defaultDashboardId = group.defaultDashboardId
    if (group.forceDashboardRoute) this.forceDashboardRoute = group.forceDashboardRoute
  }

  private loadPrivilegeSets() {
    this.rootStore.privilegeSetsStore.currentOrgPrivilegeSets.forEach((privilege) => {
      const newRow = new PrivilegeSetRowVM(this.rootStore, this, privilege)
      this.privilegeSetRows.push(newRow)
    })
  }

  private group: Group
  @observable public privilegeSetRows: Array<PrivilegeSetRowVM> = []
  @observable public objectId: string = ''
  @observable public organizationId: string = ''
  @observable public name: string = ''
  @observable public description: string = ''
  @observable public selectedPrivilegeSets: Array<any> = []
  @observable public defaultDashboardId: string = ''
  @observable public forceDashboardRoute: boolean = false

  @computed
  public get nameHasPeriod(): boolean {
    if (!this.name) return false
    return this.name.includes('.')
  }

  @action
  public setName(value) {
    this.name = value
  }

  @action
  public setDescription(value) {
    this.description = value
  }

  @computed
  public get availableDashboards() {
    return this.rootStore.dashboardsStore.currentOrgDashboards.filter((e) =>
      e.sharedWith.find((e) => e.id === this.objectId)
    )
  }

  @computed
  public get defaultDashboard() {
    let foundDash = this.rootStore.dashboardsStore.getDashboard(this.defaultDashboardId)
    if (!foundDash) return { name: '' }
    return foundDash
  }

  @action
  public toggleForceDashboardRoute() {
    this.forceDashboardRoute = !this.forceDashboardRoute
  }

  @action
  public setDefaultDashboard(val) {
    this.defaultDashboardId = val ? val.objectId : null
  }

  @computed
  public get privilegeSetIds(): Array<string> {
    const privilegeSetIds = []

    this.privilegeSetRows.forEach((set) => {
      if (set.isSelected) privilegeSetIds.push(set.objectId)
    })

    return privilegeSetIds
  }
}
