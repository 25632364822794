import React from 'react'
import { inject, observer } from 'mobx-react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Button,
  DialogContentText,
  Typography,
} from '@material-ui/core'
import Warning from '@material-ui/icons/WarningRounded'
import { QuizTakeVM } from '../../view-models/QuizTakeVM'
import LocalizationStore from '../../../localization/LocalizationStore'
import './QuizConfirmDialog.scss'

interface Props {
  vm: QuizTakeVM
  localizationStore?: LocalizationStore
}

const QuizConfirmDialog: React.FC<Props> = ({ vm, localizationStore }) => {
  const { quiz: strings } = localizationStore.lzStrings

  const renderTitle = () => {
    return (
      <DialogTitle className={'quiz-dialog-title-div'}><Typography className={'quiz-dialog-title'}>{strings.quiz_dialog_title}</Typography></DialogTitle>
    )
  }

  const renderSubtitle = () => {
    return (
      <Grid container className={'quiz-dialog-subtitle'}>
        <Grid item xs={2} className={'warning-icon-div'}><Warning className={'warning-icon'} /></Grid>
        <Grid item xs={10}>
            <Typography>{strings.quiz_dialog_subtitle_1}</Typography>
            <Typography>{strings.quiz_dialog_subtitle_2}</Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <Dialog id={'QuizConfirmDialog'} onClose={() => vm.toggleShowConfirmDialog()} open={vm.showConfirmDialog}>
        {renderTitle()}
        <DialogContent className={'quiz-confirm-content'}>
          {renderSubtitle()}
          <Grid container justifyContent='flex-end'>
            <Button className={'go-back-button'} variant="outlined" onClick={() => vm.toggleShowConfirmDialog()}>
              {strings.go_back}
            </Button>
            <Button variant="contained" onClick={() => vm.submit()}>
              {strings.yes_submit}
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default inject('localizationStore')(observer(QuizConfirmDialog))
