import { action, observable, computed } from 'mobx'
import { QuizResult } from '../aggregate/QuizResult'
import { RootStore } from '../../stores/RootStore'
import { Quiz } from '../../quiz/aggregate/Quiz'
import { Question } from '../../quiz/aggregate/Question'
import { Option } from '../../quiz/aggregate/Option'
import { QuizResultQuestionVM } from './QuizResultQuestionVM'
import { QuestionType } from '../../quiz/types/QuestionType'

export class QuizResultOptionVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, option: Option, questionResult: object, questionVM: QuizResultQuestionVM) {
    this.rootStore = rootStore
    this.option = option
    this.questionResult = questionResult
    this.questionVM = questionVM
  }

  @observable public questionVM: QuizResultQuestionVM = null
  @observable public option: Option = null
  @observable public questionResult: any = null

  @computed
  public get questionType(): QuestionType {
    return this.questionVM.type
  }

  @computed
  public get text(): string {
    return this.option.text
  }

  @computed
  public get isCorrectAnswer(): boolean {
    return this.option.isCorrectAnswer
  }

  @computed
  public get isChecked(): boolean {
    return this.option.isChecked
  }

  @computed
  public get isCorrect(): boolean {
    return Boolean(this.option.isChecked && this.option.isCorrectAnswer)
  }

  @computed
  public get progress(): number {
    return this.questionResult[this.option.id] / this.questionResult.totalResponses
  }

  @computed
  public get percent(): string {
    return `${String(Math.round(this.progress * 100))} %`
  }

}
