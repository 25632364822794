import React from 'react'
import { inject, observer } from 'mobx-react'
import { AppBar, Button, TextField, Toolbar, Typography } from '@material-ui/core'
import rootStore from '../../../stores/RootStore'
import { PulseCampaignsStore } from '../../store/PulseCampaignsStore'
import LocalizationStore from '../../../localization/LocalizationStore'
import LeftIcon from '@material-ui/icons/ChevronLeft'
import './TopToolbar.scss'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Worksheet } from '../../../worksheets/aggregate/Worksheet'

interface Props {
  pulseCampaignsStore?: PulseCampaignsStore
  localizationStore?: LocalizationStore
}

const TopToolbar: React.FC<Props> = ({ pulseCampaignsStore, localizationStore }) => {
  const { pulseCampaign: lz } = localizationStore.lzStrings
  const vm = pulseCampaignsStore.viewModels.campaignAnalysisEditVM
  if (!vm) return null

  const renderPulseCampaignName = () => {
    return <Typography className={'analysis-campaign-name'}>{vm.pulseCampaignName}</Typography>
  }

  const renderWorksheetPicker = () => {
    return (
      <Autocomplete
        // disabled={vm.isReadOnly}
        className='worksheet-picker'
        options={vm.availableWorksheets}
        getOptionLabel={(type) => type.name}
        value={vm.worksheet}
        onChange={(e, type: Worksheet) =>
          vm.setWorksheetId(type && type.objectId ? type.objectId : '')
        }
        renderInput={(params) => (
          <TextField {...params} placeholder={'Select Worksheet'} variant='outlined' />
        )}
      />
    )
  }

  const renderBackButton = () => {
    return (
      <Button
        className='back-to-campaigns-btn'
        variant='text'
        aria-label='back'
        onClick={() => {
          rootStore.appStore.router.push('/pulse/v1/campaigns')
        }}
      >
        <LeftIcon />
        {lz.back_to_campaigns}
      </Button>
    )
  }

  const renderSaveButton = () => {
    return (
      <Button variant='contained' disabled={vm.isSaving} onClick={() => vm.save()}>
        Save
      </Button>
    )
  }

  return (
    <>
      <AppBar position='static' color='default' id='AnalysisTopToolbar'>
        <Toolbar className='tabs-toolbar'>
          <div>{renderBackButton()}</div>
          <div className='campaign-name-container' style={{ flexGrow: 1 }}>
            {renderPulseCampaignName()}
          </div>
          <div className='worksheet-picker-container'>{renderWorksheetPicker()}</div>
          <div slot='end' className='end-buttons'>
            {renderSaveButton()}
          </div>
        </Toolbar>
      </AppBar>
    </>
  )
}

export default inject(
  'pulseCampaignsStore',
  'localizationStore'
)(observer(TopToolbar))
