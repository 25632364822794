import React, { useEffect, useRef, Fragment } from 'react'
import clsx from 'clsx'
import { inject, observer } from 'mobx-react'
import Linkify from 'react-linkify'
import parse from 'html-react-parser'
import {
  Box,
  Card,
  CardActions,
  CardActionArea,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  FormControlLabel,
  Typography,
  Tooltip,
  CircularProgress,
} from '@material-ui/core'
import { IconButton } from '@material-ui/core'
import DescriptionIcon from '@material-ui/icons/Description'
import SkillsIcon from '@material-ui/icons/BarChartRounded'
import EditIcon from '@material-ui/icons/Edit'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import FavoriteIcon from '@material-ui/icons/Favorite'
import TheatersIcon from '@material-ui/icons/Theaters'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { EffectFade, Lazy, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import 'swiper/swiper.scss'
import 'swiper/modules/effect-fade/effect-fade.scss'
import 'swiper/modules/lazy/lazy.scss'
import 'swiper/modules/navigation/navigation.scss'
import 'swiper/modules/pagination/pagination.scss'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { UserAnnouncementVM } from '../view-models/UserAnnouncementVM'
import { AnnouncementAttachmentVM } from '../view-models/AnnouncementAttachmentVM'
import Avatar from '../../shared/Avatar'
import { ReactComponent as AnnounceSvg } from '../../../assets/img/campaign-24px-outlined.svg'
import './AnnouncementsWidget.scss'
import styles from './../../shared/Avatar.scss'
import { LabelsStore } from '../../labels/store/LabelsStore'
import { AttachmentVM } from '../../attachments/view-models/AttachmentVM'
import { OldAttachmentVM } from '../../attachments/view-models/OldAttachmentVM'
import { CMSItemAttachmentVM } from '../../attachments/view-models/CMSItemAttachmentVM'
import CMSItemAttachment from './CMSItemAttachment'
import OldItemAttachment from './OldItemAttachment'
import { useIsMounted } from 'src/app/shared/useIsMounted'

interface Props {
  row: UserAnnouncementVM
  style: any
  index: number
  labelsStore?: LabelsStore
}

const UserAnnouncementRow: React.FC<Props> = ({ row, style, index, labelsStore }) => {
  const userAnnouncement = useRef(null)

  const isMounted = useIsMounted()

  useEffect(() => {
    if (userAnnouncement.current) {
      row.setHeight(userAnnouncement.current.offsetHeight)
    }
  }, [row.updated])

  useEffect(() => {
    if (userAnnouncement.current) {
      row.setHeight(userAnnouncement.current.offsetHeight)
    }
  }, [row.body])

  if (!isMounted) return

  const unreadIndicator = () => {
    if (row.isRead) return null
    return <div className='read-indicator'></div>
  }

  const analyticsIcon = () => {
    if (!row.canViewAnalytics) return null
    return (
      <SkillsIcon
        className={clsx('edit-icon')}
        onClick={(e) => {
          e.stopPropagation()
          row.viewAnalytics()
        }}
      />
    )
  }

  const editIcon = () => {
    if (!row.editable) return null
    return (
      <EditIcon
        className={clsx('edit-icon', 'right-align-item')}
        onClick={(e) => {
          e.stopPropagation()
          row.editAnnouncement()
        }}
      />
    )
  }

  const componentDecorator = (href, text, key) => (
    <a href={href} key={key} target='_blank'>
      {text}
    </a>
  )

  const renderItemAttachment = (item: AttachmentVM) => {
    if (item.isCMSItem) return <CMSItemAttachment vm={item as CMSItemAttachmentVM} />
    return <OldItemAttachment vm={item as OldAttachmentVM} />
  }

  const renderFileAttachments = (row: UserAnnouncementVM) => {
    return row.fileAttachments.map((e, index) => renderAttachment(e, index))
  }

  const renderMedia = (row: UserAnnouncementVM) => {
    const media = row.swiperAttachments
    if (media && media.length < 1) return
    return (
      <Swiper
        key={`swiper-media-${row.objectId}`}
        autoHeight
        simulateTouch
        spaceBetween={50}
        lazy={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[EffectFade, Lazy, Pagination, Navigation]}
        className='swiper-swipe'
      >
        {row.swiperAttachments.map((e, index) => {
          return (
            <SwiperSlide key={`swiper-slide-${row.objectId}-${index}`}>
              {({ isActive, isVisible }) => {
                return (
                  <div className={'swiper-slide-container'}>
                    {renderItemAttachment(e)}
                    <div className='swiper-lazy-preloader swiper-lazy-preloader-white' />
                  </div>
                )
              }}
            </SwiperSlide>
          )
        })}
      </Swiper>
    )
  }

  const renderAttachment = (vm: AttachmentVM, index) => {
    if (!vm || !vm.isLoaded) return null

    const renderThumbnail = () => {
      if (vm.isImage)
        return <div className='img-container' style={{ backgroundImage: `url('${vm.url}')` }} />
      if (vm.isVideo) return <TheatersIcon />
      if (!vm.isImage || !vm.isVideo) return <DescriptionIcon />
    }

    return (
      <Grid
        item
        xs={8}
        key={index}
        className='input-attachment-row'
        style={{ cursor: 'pointer' }}
        onClick={() => vm.openAttachment()}
      >
        <span className='attachment-body'>
          {renderThumbnail()}
          <Typography variant='body1' className='file-name-text'>
            {vm.shorterFileName}
          </Typography>
        </span>
      </Grid>
    )
  }

  const toggleCollapsed = () => {
    row.toggleCollapsed()
    row.forceUpdate()
  }

  const renderExpandButton = () => {
    if (!row.isCollapsible) return
    return (
      <div className='expand-button'>
        <span className={'expand-button-dot'}>{row.isCollapsed ? `... ` : undefined}</span>
        <span onClick={toggleCollapsed} className={'expand-button-text'}>
          {row.isCollapsed ? `See more` : `See less`}
        </span>
      </div>
    )
  }

  const renderBody = () => {
    const html = row.isCollapsed ? row.truncated : row.body

    return (
      <div className='announcement-body-trunc'>
        <div className='announcement-body-html'>{parse(html)}</div>
        {renderExpandButton()}
      </div>
    )
  }

  const handleVisibilityChange = (isVisible: boolean) => {
    if (isVisible) verifyReadStatus()
  }

  const verifyReadStatus = () => {
    if (!row.isRead) row.markAsRead()
  }

  const renderHeader = () => {
    if (!row.showAnnouncementOwner) return
    return (
      <CardHeader
        avatar={
          <Tooltip title='Announcement' placement='bottom' enterDelay={500}>
            <IconButton
              disableRipple
              className={clsx('announce-icon', 'right-align-item')}
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              <AnnounceIcon />
            </IconButton>
          </Tooltip>
        }
        action={
          <Avatar
            user={row.avatarUser}
            size={40}
            style={{ marginTop: '18px', marginRight: '18px' }}
            showOnlyFirstInitial
            isArchived={row.isArchived}
          />
        }
        title={
          <>
            <Typography>
              {row.displayDate} by{' '}
              <Box component='span' fontWeight={700}>
                {row.username}
              </Box>
            </Typography>
          </>
        }
      />
    )
  }

  const renderNoOwnerHeader = () => {
    if (row.showAnnouncementOwner) return
    return (
      <CardHeader
        className='no-owner'
        avatar={
          <Tooltip title='Announcement' placement='bottom' enterDelay={500}>
            <IconButton
              disableRipple
              className={clsx('announce-icon', 'left-align-item')}
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              <AnnounceIcon />
            </IconButton>
          </Tooltip>
        }
        title={<Typography className={'updated-at'}>{row.displayDate}</Typography>}
      />
    )
  }

  return (
    <ReactVisibilitySensor
      partialVisibility
      onChange={handleVisibilityChange}
      resizeCheck
      scrollCheck
      minTopValue={360}
      resizeDelay={500}
      scrollDelay={500}
      intervalDelay={500}
    >
      {({ isVisible }) => {
        return (
          <div style={style}>
            <Grid
              item
              ref={userAnnouncement}
              xs={12}
              className={row.isRead ? 'card-wrapper' : 'card-wrapper  is-not-read'}
              onClick={() => verifyReadStatus()}
            >
              <div className='read-indicator-row'>{unreadIndicator()}</div>
              <div className='announcement-container'>
                <Card className={'announcement-card'}>
                  {renderHeader()}
                  {renderNoOwnerHeader()}
                  <CardActionArea>
                    <CardContent className='card-content'>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          className={clsx('card-item', 'swiper-body')}
                          id={`${index}-images`}
                        >
                          {renderMedia(row)}
                        </Grid>
                        <Grid item xs={12} className={clsx('card-inset', 'row-title-bar')}>
                          <Tooltip title={row.title} arrow={true} placement='bottom-start'>
                            <Typography variant='h6' className='announcement-title'>
                              <Linkify componentDecorator={componentDecorator}>{row.title}</Linkify>
                            </Typography>
                          </Tooltip>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          className={clsx('card-inset', 'card-item', 'row-body')}
                          id={`${index}-body`}
                        >
                          {renderBody()}
                        </Grid>
                      </Grid>
                      <Grid container className={'card-inset'}>
                        {renderFileAttachments(row)}
                      </Grid>
                    </CardContent>
                    <Divider variant='middle' />
                  </CardActionArea>
                  <CardActions disableSpacing>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={row.liked}
                          style={{ marginLeft: '5px' }}
                          onChange={(evt) => row.toggleLikeAnnouncement()}
                          inputProps={{ 'aria-label': 'controlled' }}
                          icon={<FavoriteBorderIcon />}
                          checkedIcon={<FavoriteIcon style={{ color: 'red' }} />}
                          name='Likes'
                          disabled={row.updatingLikeStatus}
                        />
                      }
                      label={
                        <Typography
                          className={'announcement-action-btn'}
                          variant='body2'
                          color='textPrimary'
                          component='div'
                        >
                          {row.updatingLikeStatus ? (
                            <CircularProgress size={20} className='spinner' />
                          ) : (
                            row.likes
                          )}
                        </Typography>
                      }
                    />
                    <div id='announce-form-label'>
                      <IconButton disableRipple className={'announcement-action-btn-container'}>
                        <VisibilityIcon />
                        <Typography
                          className={'announcement-action-btn'}
                          variant='body2'
                          color='textPrimary'
                          component='p'
                        >
                          {row.views}
                        </Typography>
                      </IconButton>
                    </div>
                    {analyticsIcon()}
                    {editIcon()}
                  </CardActions>
                </Card>
              </div>
            </Grid>
          </div>
        )
      }}
    </ReactVisibilitySensor>
  )
}

const AnnounceIcon = (props) => {
  return (
    <div id='announce-icon-div'>
      <AnnounceSvg />
    </div>
  )
}

export default inject('labelsStore')(observer(UserAnnouncementRow))
