import React from 'react'
import { inject, observer } from 'mobx-react'
import Dropzone from 'react-dropzone'
import CloseIcon from '@material-ui/icons/Close'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  DialogActions,
  Button,
  CircularProgress,
  LinearProgress,
} from '@material-ui/core'
import VirtualList from 'react-tiny-virtual-list'
import ErrorRow from './ErrorRow'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import WeightProfileImportRow from './WeightProfileImportRow'
import './WeightProfileImportRow.scss'
import ExportScreen from './ExportScreen'
import { GroupsAndRolesStore } from 'src/app/groups-roles-widget/GroupsAndRolesStore'

interface Props {
  groupsAndRolesStore?: GroupsAndRolesStore
  localizationStore?: LocalizationStore
}

const WeightProfileImportModal: React.FC<Props> = ({ groupsAndRolesStore, localizationStore }) => {
  const { importVM: vm } = groupsAndRolesStore.groupsAndRolesWidgetVM
  const { groupsAndRolesWidgetVM: parentVM } = groupsAndRolesStore
  if (!vm) return null
  const { lzStrings } = localizationStore

  const renderWeightProfilesImportVirtualList = () => {
    if (vm.weightProfileRows.length === 0) return null
    return (
      <VirtualList
        should-force-update={vm.cardHeights}
        className='weight-profiles-import-list'
        height={400}
        width='100%'
        itemCount={vm.weightProfileRows.length}
        overscanCount={1000}
        itemSize={(index) => vm.rowGetter(index)}
        renderItem={({ style, index }) => {
          const row = vm.weightProfileRows[index]
          return <WeightProfileImportRow key={row.key} row={row} style={style} index={index} />
        }}
      />
    )
  }

  const renderErrors = () => {
    if (!vm.hasErrors) return null
    return vm.errorRows.map((e, idx) => <ErrorRow key={'er' + idx} row={e} />)
  }

  const renderDropZone = () => {
    if (!vm.showDropZone) return null
    return (
      <Grid container className='dialog-content'>
        <DialogContent>
          {!vm.file ? (
            <Dropzone
              onDrop={(file) => vm.handleFileDrop(file)}
              accept='.csv,text/csv'
              multiple={false}
              minSize={0}
              maxSize={5242880}
            >
              {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                <section>
                  <div className='dropzone' {...getRootProps()}>
                    <input {...getInputProps()} />

                    {!isDragActive && (
                      <div className='iconContainer'>
                        <NoteAddIcon className='noteAddIcon' />
                        <p className='dropzoneText'>
                          {lzStrings.contact_import.drag_and_drop}
                          <span className='dropLink'>{lzStrings.contact_import.browse}</span>
                          {lzStrings.contact_import.your_file}
                        </p>
                      </div>
                    )}

                    {isDragActive && !isDragReject && (
                      <div className='iconContainer'>
                        <ThumbUpIcon className='thumbUpIcon' />
                        <p className='dropzoneText'>{lzStrings.contact_import.looks_good}</p>
                      </div>
                    )}

                    {isDragActive && isDragReject && (
                      <div className='iconContainer'>
                        <ThumbDownIcon className='thumbDownIcon' />
                        <p className='dropzoneText'>{lzStrings.contact_import.not_good}</p>
                      </div>
                    )}
                  </div>
                </section>
              )}
            </Dropzone>
          ) : (
            <Dropzone
              onDrop={(file) => vm.handleFileDrop(file)}
              accept='.csv,text/csv'
              multiple={false}
              minSize={0}
              maxSize={5242880}
            >
              {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                <section>
                  <div className='dropzone' {...getRootProps()}>
                    <input {...getInputProps()} />

                    {!isDragActive && (
                      <div className='iconContainer'>
                        <ThumbUpIcon className='thumbUpIcon' />
                        <p className='dropzoneText'>{vm.file.name}</p>
                        <p className='dropzoneText'>{lzStrings.contact_import.looks_good}</p>
                      </div>
                    )}

                    {isDragActive && !isDragReject && (
                      <div className='iconContainer'>
                        <ThumbUpIcon className='thumbUpIcon' />
                        <p className='dropzoneText'>{lzStrings.contact_import.looks_good}</p>
                      </div>
                    )}

                    {isDragActive && isDragReject && (
                      <div className='iconContainer'>
                        <ThumbDownIcon className='thumbDownIcon' />
                        <p className='dropzoneText'>{lzStrings.contact_import.not_good}</p>
                      </div>
                    )}
                  </div>
                </section>
              )}
            </Dropzone>
          )}
          <DialogContentText className='dialogText'>
            {' '}
            {lzStrings.contact_import.acceptable_formats}: csv{' '}
          </DialogContentText>
        </DialogContent>
      </Grid>
    )
  }

  const renderDropZoneNotProcessing = () => {
    if (!vm.showSpinner) return null
    return (
      <Grid container className='dialog-content'>
        <DialogContent>
          <div className='dropzone'>
            <div className='iconContainer'>
              <CircularProgress className='progressCircle' />
              <p className='dropzoneText'>Processing Weight Profiles Upload</p>
            </div>
          </div>
        </DialogContent>
      </Grid>
    )
  }

  const renderLinearProgress = () => {
    if (!vm.importProcessing) return null
    if (!vm.importProgress) return null
    return <LinearProgress variant='determinate' value={vm.importProgress} />
  }

  const renderExportScreen = () => {
    if (!vm.exportVM) return null
    return <ExportScreen />
  }

  const renderImportScreen = () => {
    if (vm.exportVM) return null
    return (
      <>
        <Grid
          className='dialog-title'
          container
          justifyContent='space-between'
          alignItems='center'
          style={{ paddingRight: '10px' }}
        >
          <Grid item>
            <DialogTitle>{lzStrings.contact_import.file_upload}</DialogTitle>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => groupsAndRolesStore.groupsAndRolesWidgetVM.toggleImportModal()}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        {renderLinearProgress()}
        <Grid className={vm.weightProfileRows.length > 0 ? 'dialog-body' : ''}>
          {renderErrors()}
          {renderWeightProfilesImportVirtualList()}
          {renderDropZone()}
          {renderDropZoneNotProcessing()}
        </Grid>
        <DialogActions>
          <Grid container className='dialog-actions' alignItems='center' justifyContent='space-between'>
            <Grid item>
              <Button
                variant='outlined'
                size='large'
                onClick={() => vm.toggleExportScreen()}
                className='downloadButton'
              >
                {lzStrings.importModal.downloadTemplate}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant='text'
                size='large'
                onClick={() => groupsAndRolesStore.groupsAndRolesWidgetVM.toggleImportModal()}
                disabled={vm.importProcessing}
                className='cancelButton'
              >
                {lzStrings.contact_import.cancel}
              </Button>
              <Button
                variant='contained'
                size='large'
                onClick={() => vm.validate()}
                disabled={!vm.validateEnabled}
                className='saveButton'
              >
                {lzStrings.contact_import.validate}
              </Button>
              <Button
                variant='contained'
                size='large'
                onClick={() => vm.acceptChanges()}
                disabled={!vm.allowAcceptChanges}
                className='saveButton'
              >
                {lzStrings.contact_import.accept_changes}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </>
    )
  }

  return (
    <div id='WeightProfilesImportModal'>
      <Dialog
        onClose={() => parentVM.toggleImportModal()}
        open={parentVM.showImportModal}
        fullWidth
      >
        {renderImportScreen()}
        {renderExportScreen()}
      </Dialog>
    </div>
  )
}

export default inject(
  'groupsAndRolesStore',
  'localizationStore'
)(observer(WeightProfileImportModal))
