import React from 'react'
import { observer } from 'mobx-react'
import rootStore from '../../stores/RootStore'
import DashboardEditContent from './DashboardEditContent'

@observer
export default class DashboardEditScreen extends React.Component<any, any> {
  constructor(props) {
    super(props)

    if (props.params && props.params.id) {
      rootStore.dashboardsStore.loadEditVM(props.params.id)
    }
    if (props.params && props.params.dashboardId) {
      rootStore.dashboardsStore.cloneDashboard(props.params.dashboardId)
    }
  }

  componentWillUnmount() {
    rootStore.dashboardsStore.loadEditVM('empty')
  }


  render() {
    return (
      <>
        <DashboardEditContent />
      </>
    )
  }
}

