import { observable } from 'mobx'
import { serializable } from 'serializr'

export class AudienceMember {
  static create(member) {
    const audienceMember = new AudienceMember()

    return audienceMember
  }

  @serializable @observable objectId: string = ''
  @serializable @observable id: string = ''
  @serializable @observable name: string = ''
  @serializable @observable type: any = null
}
