import React from 'react'
import { inject, observer } from 'mobx-react'
import { Card, Drawer } from '@material-ui/core'
import { WorksheetsStore } from '../../../store/WorksheetsStore'
import Header from './Header'
import Buttons from './Buttons'
import Content from './Content'

interface Props {
  worksheetsStore?: WorksheetsStore
}

const ChartWidgetDrawer: React.FC<Props> = ({ worksheetsStore }) => {
  const { chartWidgetDrawerVM } = worksheetsStore
  if (!chartWidgetDrawerVM) return null

  const handleClose = () => {
    chartWidgetDrawerVM.close()
  }

  return (
    <Drawer anchor='right' open={chartWidgetDrawerVM.isOpen} onClose={handleClose}>
      <Card elevation={0}>
        <Header />
        <Buttons />
        <Content />
      </Card>
    </Drawer>
  )
}

export default inject('worksheetsStore')(observer(ChartWidgetDrawer))
