import { RootStore } from '../../../../stores/RootStore'
import { observable, computed, action } from 'mobx'
import { CatalogPlanRowVM } from './CatalogPlanRowVM'

export class CatalogPlansWidgetVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.setPlanRows('')
  }

  @observable public keywordFilter: string = ''
  @observable public deletingOrgId: string = ''
  @observable public sortType: string = 'Date'
  @observable public catalogPlanRows: CatalogPlanRowVM[] = []

  @action
  public setPlanRows(filter: string = '') {
    this.catalogPlanRows = this.rootStore.trainingPlansCatalogStore.orgCatalogTrainingPlans
      .filter(
        (trainingPlan) =>
          (trainingPlan.assignedByUserId === this.rootStore.appStore.currentUserId ||
            this.rootStore.appStore.isOrgAdmin ||
            this.rootStore.appStore.canCreateTrainings) &&
          trainingPlan.isCatalogPlan &&
          trainingPlan.title.toLowerCase().includes(filter)
      )
      .map((e) => new CatalogPlanRowVM(this.rootStore, this, e))
      .sort((a, b) => {
        if (this.sortType === 'Name') return a.title < b.title ? -1 : 0
        if (this.sortType === 'Date') return a.dueDate > b.dueDate ? -1 : 0
      })
  }

  private filterTimeout = undefined
  @action setKeywordFilter(val) {
    // set to lowercase because the filter lowercases the searchable content
    this.keywordFilter = val
    if (this.filterTimeout) clearTimeout(this.filterTimeout)
    this.filterTimeout = setTimeout(() => this.setPlanRows(this.keywordFilter.toLowerCase()), 1000)
  }

  @action createNewCatalogPlan() {
    this.rootStore.appStore.router.push('/trainingPlans/from-catalog/edit/new')
  }
}
export default CatalogPlansWidgetVM
