import { observable, action, computed } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { IAggregate } from '../../../shared/data/IAggregate'
import { AGGridVM } from '../../../shared/ag-grid/AGGridVM'
import { AlwaysAvailableUserSurveysDataStore } from './AlwaysAvailableUserSurveysDataStore'
import moment from 'moment'
import { ColDef, GridOptions, RowClickedEvent } from 'ag-grid-community'
import { AlwaysAvailableUserSurveyRowVM } from './AlwaysAvailableUserSurveyRowVM'
import { nameOf } from '../../../shared/nameOf'

export class AlwaysAvailableSurveysWidgetVM extends AGGridVM {
  private rootStore: RootStore
  private dataStore: AlwaysAvailableUserSurveysDataStore
  @observable private shownOnce: boolean = false

  constructor(rootStore: RootStore) {
    super()
    this.rootStore = rootStore
    this.dataStore = new AlwaysAvailableUserSurveysDataStore(this.rootStore)
    this.serverSideLoaded = true
  }

  @action
  public getRowNodeId(row: IAggregate): string {
    return row.objectId
  }

  @action
  private setShownOnce() {
    this.shownOnce = true
  }

  @action
  protected isExternalFilterPresent(): boolean {
    return true
  }

  @computed
  public get columnDefs(): ColDef[] {
    const s = this.rootStore.localizationStore.lzStrings
    return [
      {
        headerName: s.availableSurveysWidget.name,
        sortable: false,
        field: nameOf<AlwaysAvailableUserSurveyRowVM, string>((e) => e.name),
      },
      {
        headerName: s.availableSurveysWidget.from,
        sortable: false,
        field: nameOf<AlwaysAvailableUserSurveyRowVM, string>((e) => e.fromUserName),
        cellRenderer: 'fromCell',
      },
      {
        headerName: s.availableSurveysWidget.type,
        sortable: false,
        field: nameOf<AlwaysAvailableUserSurveyRowVM, string>((e) => e.surveyTypeName),
      },
      {
        headerName: s.availableSurveysWidget.questions,
        sortable: false,
        field: nameOf<AlwaysAvailableUserSurveyRowVM, number>((e) => e.questionsCount),
      },
      {
        headerName: s.availableSurveysWidget.published,
        sortable: false,
        field: nameOf<AlwaysAvailableUserSurveyRowVM, string>((e) => e.publishedDateFormated),
        comparator: (a, b) => this.comparator(a, b),
      },
      // {
      //   headerName: s.availableSurveysWidget.categories,
      //   sortable: false,
      //   field: nameOf<AlwaysAvailableUserSurveyRowVM, string>((e) => e.categories),
      // },
      {
        headerName: s.availableSurveysWidget.action,
        cellRenderer: 'actionsCell',
        sortable: false,
        onCellClicked: null,
      }
    ]
  }

  public getGridOptions(): GridOptions {
    return {
      ...this.baseGridOptions,
      onRowClicked: (e) => this.rowClicked(e),
      getRowNodeId: (e) => this.getRowNodeId(e),
      columnDefs: this.ensureTooltipFields(this.columnDefs),
    }
  }

  protected onGridReadied() {
    this.gridApi.setDatasource({
      rowCount: this.totalRecords,
      getRows: async (params) => {
        this.setShownOnce()
        if (params.startRow === 0) this.dataStore.setPage(0)
        await this.dataStore.getNextPage()
        const rows = this.dataStore.rows.map(
          (e) => new AlwaysAvailableUserSurveyRowVM(this.rootStore, e, this)
        )
        params.successCallback(rows, this.dataStore.totalRecords)
      },
    })
  }

  public get rows(): AlwaysAvailableUserSurveyRowVM[] {
    return []
  }

  public rowClicked(e: RowClickedEvent) {
    const row: AlwaysAvailableUserSurveyRowVM = e.data
    if (!row) return
    row.openUserSurvey()
  }

  @computed
  protected get totalRecords() {
    return this.dataStore.totalRecords
  }

  @computed
  public get hasRows(): boolean {
    return this.dataStore.hasListRecords
  }

  @computed
  public get noRowsToShowLabel() {
    return this.rootStore.localizationStore.lzStrings.dashboard.no_rows_to_show
  }

  @action
  public comparator(a, b) {
    if (a === '-' && b !== '-') return 1
    if (a !== '-' && b === '-') return -1
    if (a === '-' && b === '-') return 0
    let momentA = moment(a)
    let momentB = moment(b)
    if (momentA.isBefore(momentB)) return 1
    else if (momentA.isSame(momentB)) return 0
    else if (momentA.isAfter(momentB)) return -1
  }

}
