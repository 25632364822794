import React from 'react'
import { inject, observer, useLocalStore } from 'mobx-react'
import { SurveyTemplatesManageVM } from '../../view-models/SurveyTemplatesManageVM'
import { RootStore } from '../../../stores/RootStore'
import './SurveyTemplatesManageV2.scss'
import SurveyTemplatesHeader from './SurveyTemplatesHeader'
import TopToolbar from '../TopToolbar'
import SurveyTemplatesFoldersTable from './SurveyTemplatesFoldersTable'
import TemplateSelectTopToolbar from './template-select/TemplateSelectTopToolbar'
import { ScreenType } from '../../types/ScreenType'
import TopToolbarPermissions from './permissions/TopToolbarPermissions'

interface Props {
  rootStore?: RootStore
  screenType: ScreenType
}

const SurveyTemplatesManage: React.FC<Props> = ({ rootStore, screenType }) => {
  const localStore = useLocalStore(() => ({
    vm: new SurveyTemplatesManageVM(rootStore, screenType),
  }))
  const vm = localStore.vm

  const renderSubscriptionsToolbar = () => {
    if (vm.screenType !== 'permissions') return null
    return <TopToolbarPermissions vm={vm}/>
  }

  const renderManageToolbar = () => {
    if (vm.screenType === 'permissions') return null
    return <TopToolbar vm={vm} />
  }

  const renderTemplatesFoldersTable = () => {
    if (vm.isLoadingOrg) return null
    return (
      <SurveyTemplatesFoldersTable tableVM={vm.tableVM} folderVM={vm.foldersVM}/>
    )
  }

  return (
    <div id='SurveyTemplatesManage'>
      {renderSubscriptionsToolbar()}
      {renderManageToolbar()}
      <SurveyTemplatesHeader vm={vm} />
      {renderTemplatesFoldersTable()}
    </div>
  )
}

export default inject('rootStore')(observer(SurveyTemplatesManage))
