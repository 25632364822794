import { RootStore } from '../../stores/RootStore'
import { computed, action } from 'mobx'
import { Goal } from '../aggregate/Goal'
import { ManageGoalsWidgetVM } from './ManageGoalsWidgetVM'
import { IAudienceMemberDTO } from '../../audience-members/dtos/IAudienceMemberDTO'
import { AudienceMember } from 'src/app/audience-members/aggregate/AudienceMember'

export class ManageGoalsWidgetRowVM {
  private goal: Goal
  private rootStore: RootStore
  private manageWidgetVM: ManageGoalsWidgetVM

  constructor(rootStore: RootStore, manageWidgetVM: ManageGoalsWidgetVM, goal: Goal) {
    this.rootStore = rootStore
    this.goal = goal
    this.manageWidgetVM = manageWidgetVM
  }

  @computed
  public get objectId(): string {
    return this.goal.objectId
  }

  @computed
  public get name(): string {
    return this.goal.name
  }

  @computed
  public get category(): string {
    const category = this.rootStore.categoriesStore.getCategory(this.goal.categoryId)
    if (!category) return ''
    return category.name
  }

  @computed
  public get dueDate(): Date {
    return this.goal.endDate
  }

  @computed
  public get participantIds(): IAudienceMemberDTO[] {
    return this.goal.participants
  }

  @computed
  public get participatingUsers(): AudienceMember[] {
    return this.participantIds.map(
      (e) => e.type === 'user' && this.rootStore.audienceMembersStore.getUser(e.id)
    )
  }

  @action
  public editGoal() {
    this.rootStore.appStore.router.push(`/goals/edit/${this.objectId}`)
  }
}
