import React from 'react'
import { observer } from 'mobx-react'
import './OptionButton.scss'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'

interface Props {
  value: any
  index: number
  disabled: boolean
}

const OrderTile: React.FC<Props> = ({ value, index, disabled }) => {
  return (
    <div
      style={{
        position: 'relative',
        display: 'grid',
        alignItems: 'center',
      }}
    >
      {/* Doesnt show drag icon if read only (disabled) */}
      {disabled ? (
        <div
          style={{
            width: '88%',
            display: 'inline-block',
            verticalAlign: 'middle',
            marginRight: '40px',
          }}
        >
          {value.text}
        </div>
      ) : (
        <>
          <div
            style={{
              verticalAlign: 'middle',
              marginLeft: 'auto',
              position: 'absolute',
              width: '10%',
              paddingTop: '5px',
            }}
          >
            <DragIndicatorIcon />
          </div>
          <div
            style={{
              width: '88%',
              display: 'inline-block',
              verticalAlign: 'middle',
              marginRight: '40px',
              marginLeft: '40px',
              paddingRight: '40px',
            }}
          >
            {value.text}
          </div>
        </>
      )}

      <div
        style={{
          verticalAlign: 'middle',
          marginRight: 'auto',
          position: 'absolute',
          right: '2px',
        }}
      >
        <div
          style={{
            display: 'inline-block',
            justifyContent: 'flex-end',
            verticalAlign: 'middle',
            borderRadius: '50px',
            backgroundColor: '#cfcece',
          }}
        >
          <div
            style={{
              verticalAlign: 'middle',
              marginLeft: 'auto',
              width: '30px',
              height: '30px',
              color: '#50515f',
              paddingTop: '4px',
              paddingLeft: '9px',
            }}
          >
            {index + 1}
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(OrderTile)
