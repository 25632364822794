import React from 'react'
import PulseCampaignTypesEdit from './PulseCampaignTypesEdit'
import { observer } from 'mobx-react'

@observer
export default class PulseCampaignTypesEditWrapper extends React.Component<any, any> {
  constructor(props) {
    super(props)
  }

  render() {
    return <PulseCampaignTypesEdit objId={this.props.params.objectId} />
  }
}
