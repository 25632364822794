import React, { FC } from 'react'
import { observer, inject } from 'mobx-react'
import './SurveyOptions.scss'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { SurveysStore } from '../../../../surveys/store/SurveysStore'
import { TextField } from '@material-ui/core'
import TimePicker from '../../../../shared/time-picker/TimePicker'
import { KeyboardDatePicker } from '@material-ui/pickers'

import Autocomplete from '@material-ui/lab/Autocomplete'

interface Props {
  surveysStore?: SurveysStore
  localizationStore?: LocalizationStore
}

const CustomPublishTimePicker: FC<Props> = ({ surveysStore, localizationStore }) => {
  const surveyEditVM = surveysStore.viewModels.editVM
  const vm = surveyEditVM.surveyOptionsVM

  if (!surveyEditVM) return null
  if (!vm) return null

  return (
    <div className='section-content publish-at-specific-date-time-content'>
      <div>
        <div className='secondary-text textfield-label'>Delivery Date</div>
        <KeyboardDatePicker
          disableToolbar
          autoOk
          error={!vm.customPublishVM.editing ? true : !vm.customPublishVM.editing.isStartDateValid}
          variant='inline'
          inputVariant='outlined'
          format='MM/dd/yyyy'
          invalidDateMessage={null}
          margin='none'
          id='date-picker-inline4'
          value={vm.customPublishVM.editing.startDate}
          onChange={(e) => vm.customPublishVM.editing.setStartDate(e)}
          className='deliver-on-specific-date-picker'
        />
      </div>
      <div>
        <div className='secondary-text textfield-label'>Time</div>
        <TimePicker
          error={false}
          value={vm.customPublishVM.editing.deliveryTime}
          onChange={(hr, min) => vm.customPublishVM.editing.setDeliveryTime(hr, min)}
        />
      </div>
      <div>
        <div className='secondary-text textfield-label'>Time Zone</div>
        <Autocomplete
          options={surveyEditVM.timeZones}
          getOptionLabel={(e) => e.label}
          value={vm.customPublishVM.editing.deliveryTimeZoneType}
          onChange={(e, val) => vm.customPublishVM.editing.setDeliveryTimeZone(val)}
          renderInput={(params) => (
            <TextField {...params} placeholder='Select Time Zone' variant='outlined' />
          )}
          className='time-zone-autocomplete'
        />
      </div>
    </div>
  )
}

export default inject('surveysStore', 'localizationStore')(observer(CustomPublishTimePicker))
