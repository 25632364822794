import { action, computed, IReactionDisposer, observable } from 'mobx'
import PulseQuestion from '../../pulse-questions/aggregate/PulseQuestion'
import { RootStore } from '../../stores/RootStore'
import { Attachment } from '../../upload/aggregate/Attachment'
import { UserSurvey } from '../../user-surveys/aggregate/UserSurvey'
import { UserSurveyTakeVM } from '../../user-surveys/view-models/UserSurveyTakeVM'
import { PulseCampaignSurvey } from '../aggregate/PulseCampaignSurvey'
import { PulseCampaignsService } from '../service/PulseCampaignsService'
import { PulseCampaignSurveyStatusType } from '../type/PulseCampaignSurveyStatusType'
import { PulseCampaignEditVM } from './PulseCampaignEditVM'
import { PulseCampaignSurveyAttachmentVM } from './PulseCampaignSurveyAttachmentVM'
import { SurveyTemplate } from '../../survey-templates/aggregates/SurveyTemplate'
import moment from 'moment'
import { SurveyTemplatesService } from '../../survey-templates/services/SurveyTemplatesService'

export class PulseCampaignSurveyEditVM {
  private rootStore: RootStore
  private editVM: PulseCampaignEditVM
  private templateSvc: SurveyTemplatesService

  constructor(
    rootStore: RootStore,
    editVM: PulseCampaignEditVM,
    pulseCampaignSurvey: PulseCampaignSurvey,
    fromTemplate: boolean
  ) {
    this.rootStore = rootStore
    this.pulseCampaignSurvey = pulseCampaignSurvey
    this.cleanPulseCampaignSurvey = pulseCampaignSurvey.clone()
    this.editVM = editVM
    this.fromTemplate = fromTemplate
    this.setCurrentQuestion(0)
    this.templateSvc = new SurveyTemplatesService(this.rootStore)
  }

  @observable public pulseCampaignSurvey: PulseCampaignSurvey
  @observable public selectedSurveyTemplate: SurveyTemplate = null
  @observable public currentQuestionIndex: number = 0
  @observable public cleanPulseCampaignSurvey: PulseCampaignSurvey = null
  @observable public showSurveyPreview: boolean = false
  @observable public previewTakeVM: UserSurveyTakeVM = null
  private fromTemplate: boolean = false
  private reactions: IReactionDisposer[] = []
  private blankValue = '-'
  private timeZone: string = moment.tz.guess()

  @action
  public async loadSurveyTemplate() {
    if (this.isSurveyTemplate && !this.selectedSurveyTemplate) {
      const surveyTemplate = await this.templateSvc.getSurveyTemplate(
        this.pulseCampaignSurvey.surveyTemplateId,
        this.rootStore.appStore.currentOrgId
      )
      this.setSelectedTemplate(surveyTemplate)
    }
  }

  @action
  public setCurrentQuestion(idx: number) {
    this.currentQuestionIndex = idx
  }

  @action
  public openCompletionContentModal() {
    this.editVM.openCompletedContentScreenDialog()
  }

  @action
  public setName(val) {
    this.pulseCampaignSurvey.name = val
  }

  @action
  public setOriginalSurveyIntroduction(val) {
    this.pulseCampaignSurvey.originalSurveyIntroduction = val
  }

  @action
  public setSurveyIntroduction(val) {
    this.pulseCampaignSurvey.surveyIntroduction = val
  }

  @action
  public setOriginalSurveyCompletedContent(val) {
    this.pulseCampaignSurvey.originalSurveyCompletedContent = val
  }

  @action
  public setSurveyCompletedContent(val) {
    this.pulseCampaignSurvey.surveyCompletedContent = val
  }

  @action
  public setQuestions(questions: PulseQuestion[]) {
    this.clearAllQuestions()
    this.pulseCampaignSurvey.questions = questions
  }

  @action
  public updateQuestion(question: PulseQuestion) {
    const ndx = this.questions.findIndex((q) => q.id === question.id)
    if (ndx > -1) this.questions[ndx] = question.clone()
  }

  @action
  public addQuestion(question: PulseQuestion) {
    const ndx = this.questions.findIndex((q) => q.objectId === question.objectId)
    if (ndx === -1) {
      this.questions.push(question.clone())
    } else {
      this.questions[ndx] = question.clone()
    }
  }

  @action
  public addAllQuestions(questions: PulseQuestion[]) {
    questions.forEach((q) => this.questions.push(q))
  }

  @action
  public deleteQuestionById(id: string) {
    const ndx = this.getQuestionIndex(id)
    if (ndx !== -1) this.questions.splice(ndx, 1)
  }

  @action
  public deleteAllQuestionsById(objectId: string) {
    this.pulseCampaignSurvey.questions = this.questions.filter(
      (q) => q.originalPulseQuestionId !== objectId
    )
  }

  @action
  public clearAllQuestions() {
    this.pulseCampaignSurvey.questions = []
  }

  @action
  public getQuestionIndex(id: string): number {
    return this.questions.findIndex((e) => e.id === id)
  }

  @action
  public setAttachment(attachment: Attachment) {
    this.pulseCampaignSurvey.attachment = attachment
  }

  @action
  public openIntroScreenDialog() {
    this.editVM.openIntroScreenDialog()
  }

  @computed
  public get isEditable(): boolean {
    if (this.pulseCampaignSurvey.status === 'sent') return false
    if (this.pulseCampaignSurvey.status === 'closed') return false
    return true
  }

  @computed
  public get name(): string {
    return this.pulseCampaignSurvey.name
  }

  @computed
  public get id(): string {
    return this.pulseCampaignSurvey.id
  }

  @computed
  public get isTemplate(): boolean {
    return this.fromTemplate
  }

  @computed
  public get isDeleted(): boolean {
    return this.pulseCampaignSurvey.isDeleted
  }

  @computed
  public get originalSurveyIntroduction(): string {
    return this.pulseCampaignSurvey.originalSurveyIntroduction
  }

  @computed
  public get originalSurveyIntroductionValid() {
    return this.pulseCampaignSurvey.originalSurveyIntroduction.trim() !== ''
  }

  @computed
  public get isSurveyTemplate() {
    return this.pulseCampaignSurvey.surveyTemplateId
  }

  @computed
  public get surveyIntroductionValid() {
    if (this.isSurveyTemplate) return true
    if (!this.pulseCampaignSurvey.surveyIntroduction) return false
    if (this.pulseCampaignSurvey.surveyIntroduction.trim() === '') return false
    return true
  }

  @computed
  public get surveyIntroduction(): string {
    return this.pulseCampaignSurvey.surveyIntroduction
  }

  @computed
  public get originalSurveyCompletedContent(): string {
    return this.pulseCampaignSurvey.originalSurveyCompletedContent
  }

  @computed
  public get surveyCompletedContent(): string {
    return this.pulseCampaignSurvey.surveyCompletedContent
  }

  @computed
  public get surveyCompletedContentValid() {
    if (!this.pulseCampaignSurvey.surveyCompletedContent) return false
    if (this.pulseCampaignSurvey.surveyCompletedContent.trim() === '') return false
    return true
  }

  @computed
  public get originalSurveyCompletedContentValid() {
    return this.pulseCampaignSurvey.originalSurveyCompletedContent.trim() !== ''
  }

  @computed
  public get isSent(): boolean {
    if (this.status === 'sent') return true
    return false
  }

  @computed
  public get status(): PulseCampaignSurveyStatusType {
    return this.pulseCampaignSurvey.status
  }

  @computed
  public get nameValid() {
    if (!this.editVM.publishTried) return true
    if (!this.name) return false
    if (this.name.trim() === '') return false
    return true
  }

  @computed
  public get questions(): Array<PulseQuestion> {
    return this.pulseCampaignSurvey.questions
  }

  @computed
  public get attachment(): Attachment {
    return this.pulseCampaignSurvey.attachment
  }

  @computed
  public get surveyIntroAttachment(): PulseCampaignSurveyAttachmentVM {
    if (!this.attachment) return
    return new PulseCampaignSurveyAttachmentVM(this.rootStore, this.attachment)
  }

  @computed
  public get publishTried() {
    return this.editVM.publishTried
  }

  @computed
  public get deliveryDate() {
    return this.pulseCampaignSurvey.deliveryTime
  }

  @computed
  public get deliveryTimeZone() {
    return this.pulseCampaignSurvey.deliveryTimeZone
  }

  @computed
  public get allValid() {
    if (!this.publishTried) return true
    if (!this.nameValid) return false
    if (!this.surveyIntroductionValid) return false
    if (!this.isSurveyTemplate && this.questions.length === 0) return false
    return true
  }

  @computed
  public get campaignEditVM(): PulseCampaignEditVM {
    return this.editVM
  }

  @action
  public setDate(e) {
    this.pulseCampaignSurvey.setDeliveryTime(e)
  }

  @action
  public setDeliveryTimeZone(timeZone: string) {
    this.pulseCampaignSurvey.setDeliveryTimeZone(timeZone)
  }

  @action
  public toggleSurveyPreview() {
    this.showSurveyPreview = !this.showSurveyPreview
    if (this.showSurveyPreview) return this.loadPreviewTakeVM()
    if (!this.showSurveyPreview) {
      if (this.editVM.isSimplePreviewMode) {
        this.editVM.currentSurvey.setSurveyTemplateId(undefined)
        this.editVM.toggleSimplePreviewMode()
      }
      this.editVM.previewTakeVM = null
    }
    this.clearPreviewTakeVM()
  }

  @action
  public clearPreviewTakeVM() {
    this.previewTakeVM = null
  }

  @action
  public setIsManuallySet(bool) {
    this.pulseCampaignSurvey.setDeliveryDateManuallySet(bool)
  }

  @action
  public setSurveyTemplateId(val) {
    this.pulseCampaignSurvey.setSurveyTemplateId(val)
  }

  @action
  public setSelectedTemplate(val) {
    this.selectedSurveyTemplate = val
  }

  @action
  public resetSelectedSurveyTemplate() {
    this.pulseCampaignSurvey.setSurveyTemplateId(undefined)
  }

  @computed
  public get selectedSurveyTemplateName(): string {
    if (!this.selectedSurveyTemplate) return null
    if (!this.selectedSurveyTemplate.name) return this.blankValue
    return this.selectedSurveyTemplate.name
  }

  @computed
  public get selectedSurveyTemplateQuestionsCount(): string {
    if (this.selectedSurveyTemplate.questionsCount === 1)
      return this.selectedSurveyTemplate.questionsCount + ' Question'
    return this.selectedSurveyTemplate.questionsCount + ' Questions'
  }

  @computed
  public get selectedSurveyTemplateOwnerName(): string {
    if (!this.selectedSurveyTemplate.fk_owner) return 'Unknown'
    return this.selectedSurveyTemplate.fk_owner.name
  }

  @computed
  public get selectedSurveyTemplateCreatedByName(): string {
    if (!this.selectedSurveyTemplate.fk_createdByUser) return 'Unknown'
    return this.selectedSurveyTemplate.fk_createdByUser.name
  }

  @computed
  public get selectedSurveyTemplateCreatedAt() {
    if (!this.selectedSurveyTemplate) return this.blankValue
    const formattedDate = moment(this.selectedSurveyTemplate.createdAt)
      .tz(this.timeZone)
      .format('M/D/YYYY, h:mm A')
    return formattedDate
  }

  @computed
  public get selectedSurveyTemplateUpdatedByName() {
    if (!this.selectedSurveyTemplate.fk_updatedByUser) return 'Unknown'
    return this.selectedSurveyTemplate.fk_updatedByUser.name
  }

  @computed
  public get selectedSurveyTemplateUpdatedAt() {
    if (!this.selectedSurveyTemplate) return this.blankValue
    const formattedDate = moment(this.selectedSurveyTemplate.updatedAt)
      .tz(this.timeZone)
      .format('M/D/YYYY, h:mm A')
    return formattedDate
  }

  @computed
  public get areCreatedAtAndUpdatedAtTheSame() {
    if (this.selectedSurveyTemplateCreatedAt === this.selectedSurveyTemplateUpdatedAt) return true
    return false
  }

  @action
  public navigateToSelectedSurveyTemplateEditPage() {
    this.rootStore.appStore.router.push(
      `/surveyTemplates/edit/${this.selectedSurveyTemplate.objectId}`
    )
  }

  @computed
  public get isManuallySet() {
    return this.pulseCampaignSurvey.deliveryDateManuallySet
  }

  @action
  public async loadPreviewTakeVM() {
    const svc = new PulseCampaignsService()
    const campaign = this.editVM.toDTO()
    const campaignSurvey = campaign.surveys.find((s) => s.id === this.pulseCampaignSurvey.id)
    const survey = await svc.getConvertedCampaignSurvey(campaign, campaignSurvey)
    survey.surveyIntroduction = this.surveyIntroduction
    survey.questionsFlowFormat = 'paged'
    this.previewTakeVM = new UserSurveyTakeVM(
      this.rootStore,
      survey as UserSurvey,
      'previewer',
      true
    )
  }
}
