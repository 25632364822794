import React from 'react'
import { inject, observer, useLocalStore } from 'mobx-react'
import { Button, CircularProgress, Grid } from '@material-ui/core'
import rootStore from '../../../stores/RootStore'
import { PulseCampaignsStore } from '../../store/PulseCampaignsStore'
import PulseCampaignTemplatePreviewer from '../edit/PulseCampaignTemplatePreviewer'
import PulseCampaignTemplateSelector from '../edit/PulseCampaignTemplateSelector'
import { PulseCategoriesManageVM } from '../../view-models/PulseCategoriesManageVM'
import { PulseCampaignTemplatesVM } from '../../view-models/PulseCampaignTemplatesVM'
import { PulseCampaignTemplatePreviewerVM } from '../../view-models/PulseCampaignTemplatePreviewerVM'
import EmptyState from '../../../shared/empty-state/EmptyState'
import emptyResults from '../../../../assets/img/empty-state/empty-state-results.png'
import './TemplateStep.scss'

interface Props {
  pulseCampaignsStore?: PulseCampaignsStore
  buildVM: PulseCategoriesManageVM
  templatesVM: PulseCampaignTemplatesVM
}

const TemplateStep: React.FC<Props> = ({ buildVM, templatesVM }) => {
  const { pulseCampaign: lz } = rootStore.localizationStore.lzStrings

  const { editVM } = rootStore.pulseCampaignsStore.viewModels
  if (!editVM) return null

  const localStore = useLocalStore(() => ({
    vm: buildVM,
  }))
  const vm = localStore.vm

  const renderSpinner = () => {
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  const renderEmptyState = () => {
    if (templatesVM.templates.length > 0) return null
    return (
      <EmptyState
        image={emptyResults}
        title={`${lz.no_templates}`}
        subtitle1={templatesVM.canManage && `${lz.create_a_company} ${lz.template} ${lz.now}.`}
        subtitle2={''}
        action={
          templatesVM.canManage &&
          <Button
            onClick={() => templatesVM.createPulseTemplate()}
            className={'empty-state-action-btn'}
            variant={'contained'}
          >
            {lz.create_template}
          </Button>
        }
      />
    )
  }

  const renderTemplatePreviewer = () => {
    if (!templatesVM.isLoaded || templatesVM.isLoading) return renderSpinner()
    if (templatesVM.templates.length < 1) return renderEmptyState()
    return <PulseCampaignTemplatePreviewer vm={new PulseCampaignTemplatePreviewerVM(rootStore, templatesVM, buildVM)} manageVM={vm} />
  }

  return (
    <div id='TemplateStep'>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='stretch'
        className={'pulse-campaign-build'}
      >
        <Grid item xs={3} className={'pulse-campaign-build-questions-panel'}>
          <PulseCampaignTemplateSelector manageVM={vm} templatesVM={templatesVM} />
        </Grid>
        <Grid item xs={9} className={'pulse-campaign-build-survey-selector'}>
          {renderTemplatePreviewer()}
        </Grid>
      </Grid>
    </div>
  )
}

export default inject('pulseCampaignsStore')(observer(TemplateStep))
