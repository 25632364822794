import { RootStore } from '../../../stores/RootStore'
import { CustomRecord } from '../../aggregate/CustomRecord'
import { observable, action } from 'mobx'
import { FieldType } from '../../types/FieldType'

export class CustomRecordEditVM {
  private rootStore: RootStore
  @observable private customRecord: CustomRecord
  @observable public isSelected: boolean = false

  constructor(rootStore: RootStore, customRecord: any) {
    this.rootStore = rootStore
    this.customRecord = customRecord
  }

  @action
  public toggleSelected() {
    this.isSelected = !this.isSelected
  }

  public get objectId(): string {
    return this.customRecord.isNew ? this.customRecord.offlineObjectId : this.customRecord.objectId
  }

  public getValue(key, type: FieldType) {
    const recordData = this.customRecord[key]
    if (type === 'Date' && recordData) {
      return new Date(recordData.iso).toLocaleDateString()
    } else if (type === 'User' && recordData) {
      return recordData.name
    }
    return recordData
  }

  public getCustomRecord() {
    return this.customRecord
  }
}
