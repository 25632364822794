import { ColDef, GridOptions, RowClickedEvent } from 'ag-grid-community'
import { action, computed } from 'mobx'
import { AGGridVM } from '../../../shared/ag-grid/AGGridVM'
import { nameOf } from '../../../shared/nameOf'
import { RootStore } from '../../../stores/RootStore'
import { AudienceRowVM } from './AudienceRowVM'
import { AudienceUsersListDataStore } from './AudienceUsersListDataStore'
import { OrganizationUserEditVM } from './OrganizationUserEditVM'

export class AudienceUsersAGGridVM extends AGGridVM {
  private rootStore: RootStore
  private editVM: OrganizationUserEditVM
  private dataStore: AudienceUsersListDataStore

  constructor(rootStore: RootStore, editVM: OrganizationUserEditVM) {
    super()
    this.rootStore = rootStore
    this.editVM = editVM
    this.dataStore = new AudienceUsersListDataStore(this.rootStore, this.editVM.userId)
    this.sizeColumnsToFit = false
    this.serverSideLoaded = true
  }

  public getRowNodeId(row: AudienceRowVM): string {
    return row.objectId
  }

  public get shouldRender(): boolean {
    if (!this.rootStore.rolesStore.loaded) return false
    if (!this.rootStore.groupsStore.loaded) return false
    return true
  }

  @computed
  public get columnDefs(): ColDef[] {
    const s = this.rootStore.localizationStore.lzStrings
    return [
      {
        headerName: s.orgUsersTableWidget.name,
        field: nameOf<AudienceRowVM, string>((e) => e.name),
        sort: 'asc',
        cellRenderer: 'nameCell',
        width: 540,
      },
      // {
      //   headerName: s.userEdit.type,
      //   field: nameOf<AudienceRowVM, string>((e) => e.type),
      //   sortable: false,
      //   width: 100
      // },
    ]
  }

  public getGridOptions(): GridOptions {
    return {
      ...this.baseGridOptions,
      onRowClicked: (e) => this.rowClicked(e),
      getRowNodeId: (e) => this.getRowNodeId(e),
      columnDefs: this.ensureTooltipFields(this.columnDefs),
      pagination: false,
    }
  }

  @action
  public setQuickFilter(val: string) {
    this.typedFilterText = val
    if (this.quickFilterTO) clearTimeout(this.quickFilterTO)
    this.quickFilterTO = setTimeout(() => this.applyFilter(), 1000)
  }

  private applyFilter() {
    this.dataStore.setFilter(this.typedFilterText)
    this.reload()
    this.columnApi.applyColumnState({
      state: [{ colId: 'name', sort: 'asc' }],
    })
  }

  protected onGridReadied() {
    this.gridApi.setDatasource({
      rowCount: this.totalRecords,
      getRows: async (params) => {
        params.sortModel.forEach((col: { colId: string; sort: 'asc' | 'desc' }, idx: number) => {
          let dbCol = col.colId
          if (dbCol === 'name') dbCol = 'fk_User.lastName'
          if (dbCol === 'email') dbCol = 'fk_User.email'
          if (idx === 0) this.dataStore.setSort(dbCol, col.sort)
          if (idx > 0) this.dataStore.addSort(dbCol, col.sort)
        })
        if (params.sortModel.length === 0) this.dataStore.setSort('fk_User.lastName')
        if (params.startRow === 0) this.dataStore.setPage(0)
        await this.dataStore.getNextPage()
        const rows = this.dataStore.organizationUsers.map(
          (e) => new AudienceRowVM(this.rootStore, this.editVM, e)
        )
        params.successCallback(rows, this.dataStore.totalRecords)
      },
    })
  }

  public get rows(): AudienceRowVM[] {
    return []
  }

  public rowClicked(e: RowClickedEvent) {
    console.log(e)
  }

  @computed
  protected get totalRecords() {
    return this.dataStore.totalRecords
  }
}
