import React, { useEffect, useRef } from 'react'
import { ListItem, Grid, Typography, Paper } from '@material-ui/core'
import { observer } from 'mobx-react'
import CheckIcon from '@material-ui/icons/Check'
import WarningIcon from '@material-ui/icons/Warning'
import './QuestionImportRow.scss'
import QuestionImportRowOption from './QuestionImportRowOption'
import { QuestionImportRowVM } from '../../view-models/QuestionImportRowVM'

interface Props {
  row: QuestionImportRowVM
  style: any
  index: number
}

const QuestionImportRow: React.FC<Props> = ({ row, style, index }) => {

  const questionRef = useRef(null);

  useEffect(() => {
    if (questionRef.current) {
      row.setHeight(questionRef.current.offsetHeight, index)
    }
  }, [questionRef]);

  const renderType = () => {
    return (
      <Grid container item>
        <Typography className={row.textStyle}><strong>Type:</strong> {row.type}</Typography>
      </Grid>
    )
  }

  const renderCategory = () => {
    return (
      <Grid container item>
        <Typography className={row.textStyle}><strong>Category:</strong> {row.category}</Typography>
      </Grid>
    )
  }

  const renderPath = () => {
    return (
      <Grid container item>
        <Typography className={row.textStyle}><strong>Path:</strong> {row.path}</Typography>
      </Grid>
    )
  }

  const renderSurveyType = () => {
    return (
      <Grid container item>
        <Typography className={row.textStyle}><strong>Survey Type:</strong> {row.surveyType}</Typography>
      </Grid>
    )
  }

  const renderOptions = () => {
    if (row.options.length === 0) return null
    return (
      <Grid container item>
        <Typography className={row.textStyle}><strong>Options:</strong></Typography>
        {row.options.map((o, index) => {
          return <QuestionImportRowOption key={index} option={o} row={row} />
        })}
      </Grid>
    )
  }

  const renderNumberOptions = () => {
    if (row.type !== 'number') return null
    return (
      <>
        <Typography className={row.textStyle}><strong>Options:</strong></Typography>
        <Grid container className={'number-options'} item>
          <Typography className={row.textStyle}>Decimals Allowed: {row.decimalsAllowed}</Typography>
          <Typography className={row.textStyle}>Default Value: {row.defaultValue}</Typography>
          <Typography className={row.textStyle}>Number Step: {row.numberStep}</Typography>
          <Typography className={row.textStyle}>Minimum Value: {row.minimumValue}</Typography>
          <Typography className={row.textStyle}>Maximum Value: {row.maximumValue}</Typography>
        </Grid>
      </>
    )
  }

  const renderQuestionErrorMessage = () => {
    if (!row.questionErrorMessage) return
    return (
      <Grid container item className='row-error-text'>
        <strong>Error: </strong> {row.questionErrorMessage}
      </Grid>
    )
  }

  const renderSuccessfulRow = () => {
    if (row.hasError) return
    return (
      <>
        {renderType()}
        {renderCategory()}
        {renderPath()}
        {renderSurveyType()}
        {renderOptions()}
        {renderNumberOptions()}
      </>
    )
  }

  return (
    <div style={style}>
      <Paper
        elevation={1}
        ref={questionRef}
        id='QuestionImportRow'>
        <Grid
          container
          item>
          <Typography className={row.textStyle}><strong>Question: {row.title}</strong></Typography>
        </Grid>
        {renderQuestionErrorMessage()}
        {renderSuccessfulRow()}
      </Paper>
    </div>

  )
}

export default observer(QuestionImportRow)
