import { observable, computed, action } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { UserTask } from '../aggregates/UserTask'
import moment, { Moment } from 'moment'
import _ from 'lodash'

interface IUserTasksTimelineVM {}

export class UserTasksTimelineVM implements IUserTasksTimelineVM {
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.today = moment()
  }

  @observable private rootStore: RootStore
  @observable today: Moment

  @computed get userTasks(): Array<UserTask> {
    return this.rootStore.userTasksStore.userTasks.filter((userTask) => {
      return userTask.userId === this.rootStore.appStore.currentUserId && !userTask.isDeleted
    })
  }

  @computed get pendingUserTasks(): Array<UserTask> {
    return this.userTasks.filter((userTask) => !userTask.isCompleted)
  }

  @computed get completedUserTasks(): Array<UserTask> {
    return this.userTasks.filter((userTask) => userTask.isCompleted)
  }

  @computed get tasksWithNoDueDate(): Array<UserTask> {
    return this.userTasks.filter((userTask) => {
      return (
        this.rootStore.tasksStore.tasks
          .filter((task) => task.objectId === userTask.taskId)
          .filter((task) => !Boolean(task.dueDate)).length > 0
      )
    })
  }

  @computed
  public get overDueTasks() {
    if (
      this.rootStore.tasksStore.tasks.length < 1 ||
      this.rootStore.audienceMembersStore.allCurrentOrgUsers.length < 1 ||
      this.rootStore.categoriesStore.currentOrgCategories.length < 1
    )
      return []
    return this.pendingUserTasks
      .filter((userTask) => {
        const parentTask = this.rootStore.tasksStore.tasks.filter(
          (task) => task.objectId === userTask.taskId
        )[0]
        if (parentTask) {
          const today = moment()
          const dueDate = moment.unix(parentTask.dueDate)
          return today.isAfter(dueDate, 'day')
        }
      })
      .map((userTask) => {
        const parentTask = this.rootStore.tasksStore.tasks.filter(
          (task) => task.objectId === userTask.taskId
        )[0]
        const discipline = this.rootStore.categoriesStore.categories.filter(
          (category) => category.objectId === parentTask.categoryId
        )[0]
        const creator = this.rootStore.audienceMembersStore.getUser(parentTask.assignedByUserId)

        const today = moment()
        const daysOverDue = Math.abs(today.diff(moment.unix(parentTask.dueDate), 'days'))

        return {
          ...userTask,
          creator: creator ? creator : null,
          name: parentTask ? parentTask.name : '--',
          discipline: discipline ? discipline.name : '--',
          dueDate: parentTask ? parentTask.dueDate : '--',
          description: parentTask ? parentTask.description : '--',
          daysOverDue: daysOverDue,
        }
      })
      .sort((a, b) => (a.daysOverDue < b.daysOverDue ? 1 : -1))
  }

  @computed get tasksDueToday() {
    if (
      this.rootStore.tasksStore.tasks.length < 1 ||
      this.rootStore.audienceMembersStore.currentOrgUsers.length < 1 ||
      this.rootStore.categoriesStore.currentOrgCategories.length < 1
    )
      return []
    return this.pendingUserTasks
      .filter((userTask) => {
        const parentTask = this.rootStore.tasksStore.tasks.filter(
          (task) => task.objectId === userTask.taskId
        )[0]
        if (parentTask) {
          const today = moment()
          const dueDate = moment.unix(parentTask.dueDate)
          return today.isSame(dueDate, 'day')
        }
      })
      .map((userTask) => {
        const parentTask = this.rootStore.tasksStore.tasks.filter(
          (task) => task.objectId === userTask.taskId
        )[0]
        const discipline = this.rootStore.categoriesStore.categories.filter(
          (category) => category.objectId === parentTask.categoryId
        )[0]
        const creator = this.rootStore.audienceMembersStore.getUser(parentTask.assignedByUserId)

        return {
          ...userTask,
          creator: creator ? creator : null,
          name: parentTask ? parentTask.name : '--',
          discipline: discipline ? discipline.name : '--',
          dueDate: parentTask ? parentTask.dueDate : '--',
          description: parentTask ? parentTask.description : '--',
        }
      })
  }

  @computed get upcomingPendingTasksTotal(): number {
    if (this.rootStore.tasksStore.tasks.length < 1) return 0
    return this.pendingUserTasks.filter((userTask) => {
      const parentTask = this.rootStore.tasksStore.tasks.filter(
        (task) => task.objectId === userTask.taskId
      )[0]
      const dueDate = moment.unix(parentTask && parentTask.dueDate)
      return this.today.isBefore(dueDate, 'day')
    }).length
  }

  @computed get upcomingPendingTasksGroupedByDueDate() {
    if (
      this.rootStore.tasksStore.tasks.length < 1 ||
      this.rootStore.audienceMembersStore.currentOrgUsers.length < 1 ||
      this.rootStore.categoriesStore.currentOrgCategories.length < 1
    )
      return []
    const upcomingPendingTasks = this.pendingUserTasks
      .filter((userTask) => {
        const parentTask = this.rootStore.tasksStore.tasks.filter(
          (task) => task.objectId === userTask.taskId
        )[0]
        const dueDate = moment.unix(parentTask && parentTask.dueDate)
        return dueDate.isAfter(this.today, 'day')
      })
      .map((userTask) => {
        const parentTask = this.rootStore.tasksStore.tasks.filter(
          (task) => task.objectId === userTask.taskId
        )[0]
        const discipline = this.rootStore.categoriesStore.categories.filter(
          (category) => category.objectId === parentTask.categoryId
        )[0]
        const creator = this.rootStore.audienceMembersStore.getUser(parentTask.assignedByUserId)

        return {
          ...userTask,
          creator: creator ? creator : null,
          name: parentTask ? parentTask.name : '--',
          discipline: discipline ? discipline.name : '--',
          dueDate: parentTask ? parentTask.dueDate : '--',
          description: parentTask ? parentTask.description : '--',
        }
      })

    const reducedData = _.groupBy(upcomingPendingTasks, 'dueDate')

    return reducedData
  }

  @computed get daysTilNextPendingTaskDue(): string {
    const timestampArray = Object.keys(this.upcomingPendingTasksGroupedByDueDate).map(
      (unixString) => {
        return parseInt(unixString)
      }
    )
    const sortedByDueDate = timestampArray.sort((a, b) => a - b)
    const nextPendingTaskDueDate = moment.unix(sortedByDueDate[0])
    const ref = this

    return nextPendingTaskDueDate.calendar(this.today, {
      nextDay: `[${this.rootStore.localizationStore.lzStrings.pendingUserTasksWidget.next_due_tomorrow}]`,
      nextWeek: function (now) {
        return `[${
          ref.rootStore.localizationStore.lzStrings.pendingUserTasksWidget.next_due_in
        } ${this.diff(now, 'days')} ${
          ref.rootStore.localizationStore.lzStrings.pendingUserTasksWidget.days
        }]`
      },
      sameElse: function (now) {
        return `[${
          ref.rootStore.localizationStore.lzStrings.pendingUserTasksWidget.next_due_in
        } ${this.diff(now, 'days')} ${
          ref.rootStore.localizationStore.lzStrings.pendingUserTasksWidget.days
        }]`
      },
    })
  }

  @action
  public loadUserTask(userTaskId) {
    this.rootStore.userTasksStore.loadEditVM(userTaskId)
  }
}
