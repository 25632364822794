import React from 'react'
import { inject, observer } from 'mobx-react'
import { AppBar, Button, Toolbar } from '@material-ui/core'
import { AppStore } from '../../../stores/AppStore'
import { PulseCampaignsStore } from '../../store/PulseCampaignsStore'
import LocalizationStore from '../../../localization/LocalizationStore'
import LeftIcon from '@material-ui/icons/ChevronLeft'
import RightIcon from '@material-ui/icons/ChevronRight'
import './PulseCampaignBottomToolbar.scss'

interface Props {
  pulseCampaignsStore?: PulseCampaignsStore
  appStore?: AppStore
  localizationStore?: LocalizationStore
}

const PulseCampaignBottomToolbar: React.FC<Props> = ({
  pulseCampaignsStore,
  appStore,
  localizationStore,
}) => {
  const { pulseCampaign: lz } = localizationStore.lzStrings
  const vm = pulseCampaignsStore.viewModels.editVM
  if (!vm) return null

  const renderBackButton = () => {
    if (vm.currentTabIndex === 0) return
    return (
      <Button
        className='back-to-step-btn'
        variant='outlined'
        aria-label='back'
        onClick={() => {
          vm.goToPreviousTab()
        }}
      >
        <LeftIcon />
        {`${lz.back}: ${vm.tabNavigationBackButtonText}`}
      </Button>
    )
  }

  const renderUpdateTemplateButton = () => {
    if (!vm.isTemplate) return
    if (vm.currentTabIndex === 1 || vm.currentTabIndex === 4) return
    if (vm.isNewPulseCampaign) return
    return (
      <Button
        className='draft-btn'
        variant='outlined'
        aria-label='back'
        onClick={() => {
          vm.toggleConfirmSave()
          vm.isTemplateUpdate = true
        }}
      >
        {lz.update_template}
      </Button>
    )
  }

  const renderDraftButton = () => {
    if (vm.isTemplate) return
    if (vm.campaignStatus === 'initiated') return
    return (
      <Button
        className='draft-btn'
        variant='outlined'
        aria-label='back'
        onClick={() => {
          vm.toggleConfirmSave()
        }}
      >
        {lz.save_as_draft}
      </Button>
    )
  }

  const renderButtonText = () => {
    if (vm.isTemplate && vm.objectId) return 'Update Template'
    if (vm.isTemplate && !vm.objectId) return lz.publish_template
    if (vm.pulseCampaign.status === 'initiated') return lz.update_campaign
    return lz.initiate_campaign
  }

  const renderInitiateButton = () => {
    if (
      ((!vm.isTemplate || vm.isPreview) && vm.currentTabIndex === 4) ||
      (vm.isTemplate && vm.currentTabIndex === 2)
    )
      return (
        <Button
          className='initiate-btn'
          disabled={vm.isSaving || vm.isLoading}
          variant='contained'
          aria-label='back'
          onClick={() => {
            vm.goToNextTab()
          }}
        >
          {renderButtonText()}
        </Button>
      )
    else
      return (
        <Button
          className='initiate-btn'
          disabled={vm.isLoading}
          variant='contained'
          aria-label='back'
          onClick={() => {
            vm.goToNextTab()
          }}
        >
          {`${lz.next}: ${vm.tabNavigationNextButtonText}`}
          <RightIcon />
        </Button>
      )
  }

  const renderTopToolbarEndButtons = () => {
    return (
      <div>
        {renderDraftButton()}
        {renderInitiateButton()}
      </div>
    )
  }

  return (
    <>
      <AppBar position='static' color='default' id='MainFooterForm'>
        <Toolbar className='tabs-toolbar'>
          <div slot='start'>{renderBackButton()}</div>
          {renderTopToolbarEndButtons()}
        </Toolbar>
      </AppBar>
    </>
  )
}

export default inject(
  'pulseCampaignsStore',
  'appStore',
  'localizationStore'
)(observer(PulseCampaignBottomToolbar))
