import { RootStore } from '../../../stores/RootStore'
import { action, computed } from 'mobx'
import { ColDef, GridOptions, RowClickedEvent } from 'ag-grid-community'
import { AGGridVM } from '../../../shared/ag-grid/AGGridVM'
import { nameOf } from '../../../shared/nameOf'
import { TaskTypesWidgetRowVM } from './TaskTypesWidgetRowVM'

export class TaskTypesWidgetVM extends AGGridVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    super()
    this.rootStore = rootStore
  }

  @computed
  public get rows(): TaskTypesWidgetRowVM[] {
    return this.rootStore.taskTemplatesStore.currentOrgTaskTemplates.map(
      (row) => new TaskTypesWidgetRowVM(this.rootStore, row, this)
    )
  }

  addNewTaskType() {
      this.rootStore.taskTemplatesStore.setAddNewTaskTemplate()
      this.rootStore.taskTemplatesStore.showDrawer = true
  }

  @action
  public getRowNodeId(row): string {
    return row.objectId
  }

  public get shouldRender(): boolean {
    if (!this.rootStore.taskTemplatesStore.loaded) return false
    return true
  }

  public getGridOptions(): GridOptions {
    return {
      ...this.baseGridOptions,
      rowData: this.rows,
      onRowClicked: (e) => this.rowClicked(e),
      getRowNodeId: (e) => this.getRowNodeId(e),
      columnDefs: this.ensureTooltipFields(this.columnDefs),
    }
  }

  public rowClicked(e: RowClickedEvent) {
    const row: TaskTypesWidgetRowVM = e.data
    if (!row) return
    row.openTaskType()
  }

  @computed
  public get columnDefs(): ColDef[] {
    const s = this.rootStore.localizationStore.lzStrings
    return [
      {
        headerName: s.clientManagementWidget.name,
        field: nameOf<TaskTypesWidgetRowVM, string>((e) => e.name),
        sort: 'asc',
      },
    ]
  }
}
