import { action, computed, IReactionDisposer, observable, reaction } from 'mobx'
import { Organization } from '../../organizations-sa/aggregate/Organization'
import { PulseCampaign } from '../../pulse-campaigns/aggregate/PulseCampaign'
import { IPulseCampaignsSAFindRequest } from '../../pulse-campaigns/interfaces/IPulseCampaignsSAFindRequest'
import { IPulseCampaignTemplateGlobalRequest } from '../../pulse-campaigns/interfaces/IPulseCampaignTemplateGlobalRequest'
import { CampaignTemplatesGlobalDataStore } from '../../pulse-campaigns/view-models/CampaignTemplatesGlobalDataStore'
import { RootStore } from '../../stores/RootStore'
import { PulseSet } from '../aggregate/PulseSet'
import { PulseSetsService } from '../services/PulseSetsService'
import { PulseSetCampaignTemplatesDataStore } from './manage/PulseSetCampaignTemplatesDataStore'

export class PulseSetEditVM {
  private rootStore: RootStore
  private reactions: IReactionDisposer[] = []

  constructor(rootStore: RootStore, pulseSet: PulseSet) {
    this.rootStore = rootStore
    this.pulseSet = pulseSet
    this.availableTemplatesDataStore = new CampaignTemplatesGlobalDataStore(
      this.rootStore,
      {} as IPulseCampaignTemplateGlobalRequest
    )
    this.pulseSetTemplatesDataStore = new PulseSetCampaignTemplatesDataStore(this.rootStore, {
      pulseCampaignIds: this.pulseSet.pulseCampaignTemplateIds.slice(),
    } as IPulseCampaignsSAFindRequest)
    this.loadDataStores()
    this.reactions.push(
      reaction(
        () => this.pulseSet.pulseCampaignTemplateIds.length,
        () => {
          this.pulseSetTemplatesDataStore.setRequest({
            pulseCampaignIds: this.pulseSet.pulseCampaignTemplateIds.slice(),
          } as IPulseCampaignsSAFindRequest)
          this.pulseSetTemplatesDataStore.loadListRecords()
        }
      )
    )
  }

  @observable public pulseSet: PulseSet = null
  @observable public showConfirmDelete: boolean = false
  @observable public saveTried: boolean = false
  @observable public showAddTemplate: boolean = false
  @observable public selectedAddTemplates: PulseCampaign[] = []
  @observable public availableTemplatesDataStore: CampaignTemplatesGlobalDataStore = null
  @observable public pulseSetTemplatesDataStore: PulseSetCampaignTemplatesDataStore = null

  @action
  private loadDataStores() {
    this.availableTemplatesDataStore.loadListRecords()
    this.pulseSetTemplatesDataStore.loadListRecords()
  }

  @computed
  public get isNew(): boolean {
    return Boolean(!this.objectId)
  }

  @computed
  public get objectId(): string {
    return this.pulseSet.objectId
  }

  @computed
  public get name(): string {
    return this.pulseSet.name
  }

  @computed
  public get nameValid(): boolean {
    if (!this.saveTried) return true
    if (!this.pulseSet.name || this.pulseSet.name === '') return false
    return true
  }

  @computed
  public get isValid(): boolean {
    if (!this.nameValid) return false
    return true
  }

  @computed
  public get pulseSetOrgs() {
    const orgs = []
    this.pulseSet.subscribedOrganizationIds.forEach((id) => {
      const foundOrg = this.availableOrgs.find((e) => e.objectId === id)
      if (foundOrg) orgs.push(foundOrg)
    })
    return orgs
  }

  @computed
  public get availableOrgs() {
    return this.rootStore.organizationsSAStore.organizations
  }

  @action
  public setSelectedOrgs(orgs: Organization[]) {
    this.pulseSet.setOrgs(orgs.map((o) => o.objectId))
  }

  @computed
  public get pulseSetTemplates() {
    return this.pulseSetTemplatesDataStore.rows
  }

  @computed
  public get availableTemplates() {
    return this.availableTemplatesDataStore.rows.filter((e) => {
      if (this.pulseSet.pulseCampaignTemplateIds.includes(e.objectId)) return false
      return true
    })
  }

  @action
  public removeCampaignTemplate(id: string) {
    this.pulseSet.removeCampaignTemplateId(id)
  }

  @action
  public addCampaignTemplate() {
    if (!this.selectedAddTemplates) return
    this.pulseSet.addCampaignTemplateIds(this.selectedAddTemplates.map((e) => e.objectId))
    this.selectedAddTemplates = []
  }

  @action
  public setName(name: string) {
    this.pulseSet.setName(name)
  }

  @action
  public toggleConfirmDelete() {
    this.showConfirmDelete = !this.showConfirmDelete
  }

  @action
  public setSelectedAddTemplates(templates: PulseCampaign[]) {
    this.selectedAddTemplates = templates
  }

  @action
  public async delete() {
    const svc = new PulseSetsService(this.rootStore)
    const req = { pulseSetIds: [this.objectId] }
    await svc.deletePulseSet(req)
    this.rootStore.pulseSetsStore.loadListRecords()
    this.closeEditModal()
  }

  @action
  public async save() {
    this.saveTried = true
    if (!this.isValid) return
    const svc = new PulseSetsService(this.rootStore)
    await svc.savePulseSet(this.pulseSet.serialize())
    this.closeEditModal()
  }

  @action
  public closeEditModal() {
    this.rootStore.pulseSetsStore.viewModels.loadPulseSetEditVM('empty')
    this.reactions.forEach((dispose: IReactionDisposer) => dispose())
  }
}
