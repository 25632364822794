import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  LinearProgress,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
  Tooltip,
  Typography,
  IconButton,
} from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { TrainingPlanRowVM } from '../../../view-models/widgets/manage-plans/TrainingPlanRowVM'
import './TrainingPlanRow.scss'
import LocalizationStore from '../../../../localization/LocalizationStore'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import TrackChangesIcon from '@material-ui/icons/TrackChanges'
import EditIcon from '@material-ui/icons/Edit'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import WavesRoundedIcon from '@material-ui/icons/WavesRounded'
import Avatar from '../../../../shared/Avatar'
import DeleteIcon from '@material-ui/icons/Delete'
import ArchiveIcon from '@material-ui/icons/Archive'
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded'
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded'
import { UserTrainingPlanRowVM } from '../../../view-models/widgets/manage-plans/UserTrainingPlanRowVM'
import TPMuiConfirmDeleteDialog from '../../../../shared/TPMuiConfirmDeleteDialog'
import MuiConfirmDialog from '../../../../shared/MuiConfirmDialog'
import RefreshIcon from '@material-ui/icons/Refresh'

interface Props {
  plan: TrainingPlanRowVM
  key: number
  localizationStore?: LocalizationStore
}

const TrainingPlanRow: React.FC<Props> = ({ plan, key, localizationStore }) => {
  const [isExpanded, setExpanded] = React.useState(false)

  const { training_plans: strings } = localizationStore.lzStrings

  const renderEditIcon = () => {
    if (!plan.userOwned && !plan.canEditAllTrainings) return null
    if (plan.hasActiveTrainingPlanFlows) return null
    return (
      <Tooltip title='Edit Training Plan' enterDelay={100}>
        <IconButton size='small' onClick={() => plan.editTrainingPlan()}>
          <EditIcon className='edit' />
        </IconButton>
      </Tooltip>
    )
  }

  const renderTrainerMenu = (plan) => {
    if (!plan.trainer || !plan.userOwned) return null
    return (
      <Popover
        open={Boolean(plan.trainerAnchorEl)}
        anchorEl={plan.trainerAnchorEl}
        onClose={(e) => {
          plan.setTrainerAnchorEl(null)
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className='trainer-menu'
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography variant='subtitle2' className='trainer-label'>
              Assigned Trainer
            </Typography>
            <Typography variant='subtitle2' className='trainer-label'>
              Contact
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <span className='trainer-container'>
              <Avatar user={plan.trainer} />
              <Typography variant='h6'>{plan.trainer.name}</Typography>
            </span>
            <a className='mail-link' href={plan.mailToLink} target='_blank'>
              <MailOutlineIcon className='mail' />
            </a>
          </Grid>
        </Grid>
      </Popover>
    )
  }

  const renderTrainerIcon = () => {
    if (!plan.trainer || !plan.userOwned) return null
    return (
      <Tooltip title='View trainer information' placement='bottom' enterDelay={100}>
        <IconButton size='small' onClick={(e) => plan.setTrainerAnchorEl(e.currentTarget)}>
          <PeopleAltIcon className='trainer' />
        </IconButton>
      </Tooltip>
    )
  }

  const renderImpactIcon = () => {
    if (!plan.associateToGoal) return null
    return (
      <Tooltip
        title={`Associated to ${plan.associatedGoalName}`}
        placement='bottom'
        enterDelay={100}
      >
        <IconButton size='small'>
          <TrackChangesIcon className='skills' />
        </IconButton>
      </Tooltip>
    )
  }

  const renderExpandPlanIcon = () => {
    if (isExpanded) return <ExpandLessRoundedIcon />
    if (!isExpanded) return <ExpandMoreRoundedIcon />
  }

  const renderUserTrainingPlan = (row: UserTrainingPlanRowVM, index: number) => {
    if (!row.user) return null
    return (
      <Grid item xs={12} key={index} className='user-plan-container'>
        <Tooltip title={row.user.name ? row.user.name : ''} placement='bottom' enterDelay={100}>
          <span>
            <Avatar user={row.user} size={30} />
          </span>
        </Tooltip>

        <LinearProgress
          variant='determinate'
          value={row.progress}
          className={row.userOwned ? 'owned' : 'not-owned'}
        />
        <Typography className='progress-label'>{row.progress}%</Typography>
      </Grid>
    )
  }

  const renderArchiveOption = () => {
    if (plan.hasActiveTrainingPlanFlows && !plan.canManageTrainingPlanFlows) return null
    return (
      <MenuItem key='restore' onClick={() => plan.toggleConfirmDialogOpen()}>
        <ArchiveIcon className='archive-menu-item' />
        <ListItemText primary={plan.archivedText} />
      </MenuItem>
    )
  }

  const renderDeleteOption = () => {
    if (plan.hasActiveTrainingPlanFlows && !plan.canManageTrainingPlanFlows) return null
    return (
      <MenuItem key='delete' onClick={() => plan.toggleDeleteDialog()}>
        <DeleteIcon className='delete-menu-item' />
        <ListItemText primary={'Delete'} />
      </MenuItem>
    )
  }

  const renderRefreshInDWOption = () => {
    // if (plan.editIsDisabled) return null
    return (
      <MenuItem key='refresh' onClick={() => plan.refreshInDW()}>
        <RefreshIcon />
        <ListItemText primary={'Refresh in DW'} />
      </MenuItem>
    )
  }

  const renderMenu = () => {
    if (!plan.userOwned && !plan.canEditAllTrainings) return null
    return (
      <>
        <Tooltip title='More' placement='bottom' enterDelay={100}>
          <IconButton size='small' onClick={(e) => plan.setAnchorEl(e.currentTarget)}>
            <MoreVertIcon className='menu-button' />
          </IconButton>
        </Tooltip>
        <Menu
          className='training-plan-menu'
          elevation={2}
          anchorEl={plan.menuAnchorEl}
          keepMounted
          open={Boolean(plan.menuAnchorEl)}
          onClose={() => plan.setAnchorEl(null)}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          {renderArchiveOption()}
          {renderRefreshInDWOption()}
          {renderDeleteOption()}
        </Menu>
        {renderConfirmDelete()}
      </>
    )
  }

  const renderConfirmDelete = () => {
    if (!plan.showDeleteDialog) return null
    return (
      <TPMuiConfirmDeleteDialog
        onClose={() => plan.toggleDeleteDialog()}
        open={plan.showDeleteDialog}
        itemName={plan.title}
        itemType={'Training Plan'}
        itemParticipants={plan.assignedToAudienceMembersWithMissingData}
        onConfirm={() => plan.deleteTrainingPlan()}
      />
    )
  }

  const renderFlowIndicator = () => {
    if (!plan.isForTrainingFlow) return null
    return (
      <div className='flow-container'>
        <Tooltip
          title={strings.training_plan_automated_info}
          placement='right'
          enterDelay={500}
        >
          <WavesRoundedIcon className={'flow-icon'} />
        </Tooltip>
      </div>
    )
  }

  return (
    <>
      <Accordion key={key} expanded={isExpanded} id='TrainingPlanRow'>
        <AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
          <Grid container>
            <Grid item xs={12} className='title-row'>
              <Typography variant='h6' className='title-text'>
                {plan.title}
              </Typography>
              {renderFlowIndicator()}
              <span className='icons'>
                {renderTrainerIcon()}
                {renderImpactIcon()}
                {renderEditIcon()}
                {renderMenu()}
              </span>
            </Grid>
            <Grid item xs={12} className='subtitle' onClick={() => setExpanded(!isExpanded)}>
              <Typography variant='body1' className='subtitle-text'>
                {plan.subTitle}
              </Typography>
              <div className='expand-icon'>{renderExpandPlanIcon()}</div>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {plan.userTrainingPlanRows.map((row, index) => renderUserTrainingPlan(row, index))}
          </Grid>
        </AccordionDetails>
      </Accordion>
      {renderTrainerMenu(plan)}
      <MuiConfirmDialog
        open={plan.confirmArchiveDialogOpen}
        onClose={() => plan.toggleConfirmDialogOpen()}
        confirmMessage={plan.archiveMessage}
        onConfirm={() => plan.archivePlan()}
      />
    </>
  )
}

export default inject('trainingPlansStore', 'localizationStore')(observer(TrainingPlanRow))
