import React from 'react'
import { Dialog } from '@material-ui/core'
import { EventsStore } from '../../../store/EventsStore'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { observer, inject } from 'mobx-react'
import ModalHeader from './ModalHeader'
import ModalBody from './ModalBody'
import './CalendarExportModal.scss'

interface Props {
  eventsStore?: EventsStore
  localizationStore?: LocalizationStore
}

const CalendarExportModal: React.FC<Props> = ({ eventsStore }) => {
  const { exportVM: vm } = eventsStore.calendarVM
  if (!vm) return null
  return (
    <Dialog onClose={() => vm.toggleExportModal()} open={vm.showExportModal} fullWidth>
      <ModalHeader />
      <ModalBody />
    </Dialog>
  )
}

export default inject('eventsStore', 'localizationStore')(observer(CalendarExportModal))
