import { RootStore } from '../../stores/RootStore'
import { observable, computed, action } from 'mobx'
import { UserTask } from '../../user-tasks/aggregates/UserTask'
import { RowType } from '../types/RowType'
import moment from 'moment'
import { INotificationRow } from '../interfaces/INotificationRow'

export class TaskRowVM implements INotificationRow {
  private rootStore: RootStore

  constructor(rootStore: RootStore, userTask: UserTask) {
    this.rootStore = rootStore
    this.userTask = userTask
  }

  @observable public userTask: UserTask = null
  @observable public type: RowType = 'userTask'
  @observable public buttonText: string = 'VIEW TASK'

  @computed
  public get id(): string {
    return this.userTask.objectId
  }

  @computed
  public get title(): string {
    return this.userTask.name
  }

  @computed
  public get createdSeconds(): number {
    const now = new Date()
    const createdAt = new Date(this.userTask.createdAt)
    return (now.getTime() - createdAt.getTime()) / 1000
  }

  @computed
  public get createdAt(): string {
    return moment(this.userTask.createdAt).fromNow()
  }

  @computed
  public get dueDate(): string {
    const s = this.rootStore.localizationStore.lzStrings.notifications_widget
    return moment.unix(this.userTask.dueDate).format(s.task_due_date_format)
  }

  @computed
  public get hasDuedate(): boolean {
    if (!this.dueDate) return false
    return true
  }

  @action
  public onAction() {
    this.rootStore.appStore.router.push('/dashboard/tasks')
    setTimeout(() => this.rootStore.userTasksStore.loadEditVM(this.userTask.objectId), 1000)
  }
}
