import { computed, observable, action } from 'mobx'

export class CMSItemExternalVideoPreviewVM {

  constructor(url: string) {
    this.url = url
  }

  @observable public url: string = ''

  @action
  public setUrl(url: string) {
    this.url = url
  }

  @computed
  public get isVimeo(): boolean {
    if (this.url.includes('vimeo')) return true
    return false
  }

  @computed
  public get isYouTube(): boolean {
    if (this.url.includes('youtube')) return true
    if (this.url.includes('youtu.be')) return true
    return false
  }

  @computed
  public get provider(): string {
    if (this.isVimeo) return 'vimeo'
    if (this.isYouTube) return 'youtube'
    return ''
  }

  @computed
  public get providerUid(): string {
    let uuid = ''

    if (this.isVimeo) {
      let regExp = /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/
      if (this.url.includes('player.vimeo.com')) {
        regExp = /(http|https)?:\/\/(www\.)?player.vimeo.com\/(?:video\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/
      }
      const match = this.url.match(regExp)
      uuid = match && match.length ? match[4] : ''
    } else if (this.isYouTube) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
      const match = this.url.match(regExp)
      uuid = match && match[2].length === 11 ? match[2] : ''
    }

    return uuid
  }

  @computed
  public get embedUrlStatic(): string {
    if (this.isVimeo) return `https://player.vimeo.com/video/${this.providerUid}?controls=0`
    else if (this.isYouTube)
      return `https://www.youtube-nocookie.com/embed/${this.providerUid}?modestbranding=&showinfo=0&controls=0&rel=0`
    
      return ''
  }

  @computed
  public get embedUrlPlay(): string {
    if (this.isVimeo) return `https://player.vimeo.com/video/${this.providerUid}`
    else if (this.isYouTube)
      return `https://www.youtube-nocookie.com/embed/${this.providerUid}?modestbranding=1&showinfo=0&rel=0`
    
      return ''
  }
}
