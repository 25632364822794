import { action, computed, observable, when } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { IPulseCategoriesFindRequest } from '../../pulse-categories/interfaces/IPulseCategoriesFindRequest'
import { GrandCategoryTabVM } from './tree/GrandCategoryTabVM'
import { GrandParentCategoryRowVM } from './tree/GrandParentCategoryRowVM'
import { PulseSetsFilterVM } from '../../pulse-sets/view-models/PulseSetsFilterVM'
import { PulseCategoriesDataStore } from './../../pulse-categories/view-models/PulseCategoriesDataStore'
import { CategoryQuestionsCountLabelType } from '../type/QuestionCategoryCountType'
import { PulseQuestionsDataStore } from '../../pulse-questions/view-models/PulseQuestionsDataStore'
import { IPulseQuestionsFindRequest } from '../../pulse-questions/interfaces/IPulseQuestionsFindRequest'
import { PulseCampaignEditVM } from './PulseCampaignEditVM'

export class PulseCategoriesManageVM {
  private rootStore: RootStore
  public dataStore: PulseCategoriesDataStore
  public pqDataStore: PulseQuestionsDataStore

  constructor(rootStore: RootStore, isBuild?: boolean, editVM?: PulseCampaignEditVM) {
    this.rootStore = rootStore
    this.pulseSetsFilterVM = new PulseSetsFilterVM(this.rootStore)
    this.loadDataStore()
    this.isBuild = isBuild ? isBuild : false
    this.editVM = editVM
  }

  @observable public selectedTabs: number[] = []
  @observable public categoryVMs: GrandParentCategoryRowVM[] = []
  @observable public pulseSetsFilterVM: PulseSetsFilterVM = null
  @observable public editVM: PulseCampaignEditVM = null
  @observable public isBuild: boolean = false
  @observable public questionCategoryCountType: CategoryQuestionsCountLabelType = 'none'

  @action
  public loadDataStore() {
    this.pqDataStore = new PulseQuestionsDataStore(this.rootStore, {} as IPulseQuestionsFindRequest)
    this.pqDataStore.loadListRecords()
    this.dataStore = new PulseCategoriesDataStore(this.rootStore, {
      includeAll: true,
    } as IPulseCategoriesFindRequest)
    this.dataStore.loadListRecords()

    when(
      () => this.dataStore.isLoaded,
      () => {
        this.dataStore.rows.forEach((p, i) => this.toggleTabIndex(i + 1))
      }
    )
  }

  @computed
  public get isLoaded(): boolean {
    return this.dataStore.isLoaded
  }

  @action
  public toggleTabIndex(index: number) {
    if (this.selectedTabs.includes(index)) {
      const indexOf = this.selectedTabs.indexOf(index)
      return this.selectedTabs.splice(indexOf, 1)
    }
    return this.selectedTabs.push(index)
  }

  @computed
  public get grandCategoryTabs(): GrandCategoryTabVM[] {
    return this.dataStore.rows
      .filter((e) => !e.grandCategoryId)
      .map((c, i) => new GrandCategoryTabVM(this.selectedTabs, c, i + 1))
  }

  @computed
  public get selectedGrandCategoryTabs(): string[] {
    return this.grandCategoryTabs.filter((gTab) => gTab.isSelected).map((gTab) => gTab.objectId)
  }

  public getGrandParentCategory(objectId: string): GrandParentCategoryRowVM {
    return this.categoryVMs.find((cat) => cat.objectId === objectId)
  }

  public getGrandParentCategoryIndex(objectId: string): number {
    const found = this.getGrandParentCategory(objectId)
    if (!found) return undefined
    return found.index
  }

  @action
  public addCategoryVM(categoryVM: GrandParentCategoryRowVM) {
    this.categoryVMs.push(categoryVM)
    this.categoryVMs.slice()
  }

  public grandParentCategoryRows(): GrandParentCategoryRowVM[] {
    const selectedIds = []
    const allIds = []
    this.grandCategoryTabs.forEach((t) => {
      if (t.isSelected) {
        selectedIds.push(t.objectId)
      }
      allIds.push(t.objectId)
    })
    const categories = this.dataStore.rows.filter((e) => {
      if (selectedIds.includes(e.objectId)) return true
      return false
    })
    return categories.map((c) => {
      let foundCatVM = this.categoryVMs.find((cVM) => c.objectId === cVM.objectId)
      let index = allIds.findIndex((id) => c.objectId === id)

      if (!foundCatVM) {
        foundCatVM = new GrandParentCategoryRowVM(this.rootStore, c, this)
        foundCatVM.setIndex(index + 1)
        this.addCategoryVM(foundCatVM)
      }
      return foundCatVM
    })
  }
}
