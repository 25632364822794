import React from 'react'
import { observer, inject } from 'mobx-react'
import LocalizationStore from '../../localization/LocalizationStore'
import { AppBar, Grid, Toolbar } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import './SurveyResultsHeader.scss'
import { SurveyResultsVM } from '../view-models/SurveyResultsVM'

interface Props {
  localizationStore?: LocalizationStore
  resultsVM: SurveyResultsVM
}

const SurveyResultsHeader: React.FC<Props> = ({ resultsVM, localizationStore }) => {
  const lzStrings = localizationStore.lzStrings

  const renderSurveyText = () => {
    if (!resultsVM) {
      return <Skeleton variant='text' width='60%' height='30px' />
    }
    return <span className='survey-text'>Survey Results</span>
  }

  return (
    <AppBar id='ScreenHeader' position='sticky' elevation={0}>
      <Toolbar>
        <Grid container>
          <Grid item xs={12} md={7} className='survey-and-link'>
            {renderSurveyText()}
          </Grid>
          <Grid item xs={12} md={5}></Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default inject('localizationStore')(observer(SurveyResultsHeader))
