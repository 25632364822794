import { action, observable } from 'mobx'
import { RootStore } from '../../../stores/RootStore'

export class CreateCampaignDialogVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @observable public isCreateCampaignDialogOpen: boolean = false

  @action
  public handleStartFromScratch = () => {
    this.closeCreateCampaignDialog()
    this.rootStore.appStore.router.push('/pulse/v1/edit/new')
  }

  @action
  public handleStartFromTemplate = () => {
    this.closeCreateCampaignDialog()
    this.rootStore.appStore.router.push('/pulse/v1/fromtemplate/preview')
  }

  @action
  public openCreateCampaignDialog() {
    this.isCreateCampaignDialogOpen = true
  }

  @action
  public closeCreateCampaignDialog() {
    this.isCreateCampaignDialogOpen = false
  }
  
}
