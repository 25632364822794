import React from 'react'
import { observer, inject } from 'mobx-react'
import { SurveysStore } from '../../../store/SurveysStore'
import { SurveyEditTabsEnum } from '../../../types/SurveyEditTabsEnum'
import UserSurveyTakeWidget from '../../../../user-surveys/views/take/UserSurveyTakeWidget'
import './PreviewTabPanel.scss'

interface Props {
  surveysStore?: SurveysStore
}

const PreviewTabPanel: React.FC<Props> = ({ surveysStore }) => {
  const vm = surveysStore.viewModels.editVM
  if (!vm) return null

  if (vm.currentTabIndex !== SurveyEditTabsEnum.PREVIEW) return null

  return (
    <div id='PreviewTabPanel'>
      <UserSurveyTakeWidget takeVM={vm.previewVM} />
    </div>
  )
}

export default inject('surveysStore')(observer(PreviewTabPanel))
