import React from 'react'
import { inject, observer } from 'mobx-react'
import { QuestionsStore } from '../store/QuestionsStore'
import { AppBar, Button, Card, CardContent, Drawer, IconButton, Paper, Slide, Toolbar, Tooltip, Typography } from '@material-ui/core'
import DrawerHeader from './DrawerHeader'
import DrawerButtonControls from './DrawerButtonControls'
import DrawerLabelSection from './DrawerLabelSection'
import DrawerCategorySection from './DrawerCategorySection'
import DrawerSurveyTypeSection from './DrawerSurveyTypeSection'
import DrawerQuestionTypeSection from './DrawerQuestionTypeSection'
import DrawerOptionsSection from './DrawerOptionsSection'
import MediaSelect from './MediaSelect'
import DrawerRequiredToggleSection from './DrawerRequiredToggleSection'
import MediaUploadPicker from '../../media-items/views/media-upload-picker/MediaUploadPicker'
import RatingDisplay from './RatingDisplay'
import DrawerNumberInputs from './DrawerNumberInputs'
import SpinnerOverlay from '../../shared/SpinnerOverlay'
import CheckIcon from '@material-ui/icons/Check'
import PublishIcon from '@material-ui/icons/Publish'
import DropdownInfo from './DropdownInfo'
import './Drawer.scss'

interface QuestionCatalogWidgetDrawerProps {
  questionsStore?: QuestionsStore
}

const QuestionCatalogWidgetDrawer: React.FC<QuestionCatalogWidgetDrawerProps> = ({
  questionsStore,
}) => {
  const { editVM } = questionsStore
  if (!editVM) return null

  const renderMediaPanel = () => {
    if (!editVM.mediaPanelShown) return
    return (
      <Slide direction={'left'} in={editVM.mediaPanelShown}>
        <Paper square elevation={6} >
          <AppBar color='default' position='sticky' elevation={0}>
            <Toolbar className={'media-toolbar'}>
              <Typography variant='h5' style={{ flexGrow: 1 }}>
                Media Upload
              </Typography>
              {renderMediaUploadButton()}
              {renderMediaSelectButton()}
            </Toolbar>
          </AppBar>
          <MediaUploadPicker mediaUploadPickerVM={editVM.mediaUploadPickerVM} />
        </Paper>
      </Slide>
    )
  }

  const renderMediaUploadButton = () => {
    if (!editVM.mediaPanelShown) return null
    const vm = editVM.mediaUploadPickerVM
    return (
      <Tooltip title={`Upload new media`} placement='bottom-start' enterDelay={500}>
        <IconButton onClick={() => vm.openDrawer()} className='media-upload-button'>
          <PublishIcon />
        </IconButton>
      </Tooltip>
    )
  }

  const renderMediaSelectButton = () => {
    if (!editVM.mediaPanelShown) return null
    const vm = editVM.mediaUploadPickerVM
    return (
      <Tooltip title={`Add selected media`} placement='bottom-start' enterDelay={500}>
        <span>
          <Button
            variant='contained'
            disabled={!vm.selectedMediaItemId}
            onClick={() => vm.addMedia()}
            startIcon={<CheckIcon />}
          >
            Add Media
          </Button>
        </span>
      </Tooltip>
    )
  }

  const renderNormal = () => {
    if (editVM.mediaPanelShown) return
    if (editVM.isLoading) return
    return (
      <>
        <DrawerButtonControls />
        <CardContent>
          <DrawerLabelSection />
          <DrawerCategorySection />
          <DrawerSurveyTypeSection />
          <DrawerQuestionTypeSection />
          <DrawerOptionsSection />
          <DrawerNumberInputs />
          <RatingDisplay />
          <MediaSelect />
          <DrawerRequiredToggleSection />
          <DropdownInfo />
        </CardContent>
      </>
    )
  }

  const renderSpinner = () => {
    if (!editVM.isLoading) return
    return <SpinnerOverlay />
  }

  return (
    <Drawer
      anchor='right'
      open={editVM.shown}
      onClose={() => editVM.closeModal()}
      id='QuestionDrawer'
    >
      <Card elevation={0}>
        <DrawerHeader />
        {renderMediaPanel()}
        {renderNormal()}
        {renderSpinner()}
      </Card>
    </Drawer>
  )
}

export default inject('questionsStore')(observer(QuestionCatalogWidgetDrawer))
