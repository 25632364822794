import React from 'react'
import { inject, observer } from 'mobx-react'
import { AgGridReact } from 'ag-grid-react/lib/agGridReact'
import { CircularProgress, Typography } from '@material-ui/core'
import LocalizationStore from '../../localization/LocalizationStore'
import { CMSItemsManageVM } from '../view-models/CMSItemsManageVM'
import './CMSItemsLayoutTable.scss'

interface Props {
  manageVM: CMSItemsManageVM
  localizationStore: LocalizationStore
}

const CMSItemsLayoutTable: React.FC<Props> = ({ manageVM, localizationStore }) => {
  const vm = manageVM.tableVM
  const lz = localizationStore.lzStrings.orgUsersList
  if (!vm) return

  const renderSpinner = () => {
    if (vm && vm.shouldRender) return
    if (!vm.columnsLoaded) return
    if (vm.showTable) return
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  const renderGrid = () => {
    if (!vm.shouldRender) return
    if (!vm.showTable) return
    const computeHiddenClass = (params) => {
      if (params && params.data && params.data.aggregate && params.data.aggregate.isHiddenInOrg)
        return 'hidden'
      return ''
    }
    return (
      <div
        className={
          vm.hasRows ? 'ag-theme-alpine ag-theme-content' : 'ag-theme-alpine ag-grid-no-rows'
        }
      >
        <AgGridReact
          gridOptions={{
            ...vm.getGridOptions(),
          }}
          getRowClass={computeHiddenClass}
        />
      </div>
    )
  }

  const renderNoRowsContent = () => {
    if (vm.hasRows && vm.columnsLoaded) return
    return (
      <Typography component='div' className='no-rows'>
        {lz.no_rows_to_show}
      </Typography>
    )
  }

  return (
    <>
      <div id='ContentLayoutTable'>
        {renderSpinner()}
        {renderGrid()}
        {renderNoRowsContent()}
      </div>
    </>
  )
}

export default inject('localizationStore')(observer(CMSItemsLayoutTable))
