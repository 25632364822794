import React, { FC, useState, CSSProperties } from 'react'
import clsx from 'clsx'
import { makeStyles, alpha } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    borderRadius: '16px',
    width: '34px',
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeInOut,
    }),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.04),
      cursor: 'pointer',
    },
  },
  searchFocused: {
    width: '180px',
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeInOut,
    }),
    backgroundColor: alpha(theme.palette.common.black, 0.05),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.1),
    },
  },
  searchIcon: {
    width: '34px',
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: '10px 10px 10px 34px',
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}))

export interface SearchbarProps {
  iconColor?: string
  disabled?: boolean
  focused?: boolean
  searchValue: string
  onSearchChange: (e) => void
  className?: string
  containerStyle?: CSSProperties
}

const Searchbar: FC<SearchbarProps> = ({
  iconColor,
  disabled,
  searchValue,
  onSearchChange,
  className,
  containerStyle,
  focused,
}) => {
  const classes = useStyles('')
  const [focus, setFocus] = useState(false)

  return (
    <Tooltip title={focus ? '' : 'Search'} placement='bottom'>
      <div
        className={clsx({
          [classes.search]: true,
          [classes.searchFocused]: focus ? focus : focused,
          [className]: true,
        })}
        style={containerStyle || null}
      >
        <div className={classes.searchIcon} style={{ color: iconColor ? iconColor : '#4e4e5e' }}>
          <SearchIcon />
        </div>
        <InputBase
          disabled={disabled}
          placeholder={focus ? 'Search' : ''}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ 'aria-label': 'search' }}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          value={searchValue}
          onChange={onSearchChange}
        />
      </div>
    </Tooltip>
  )
}

export default Searchbar
