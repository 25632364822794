import { RootStore } from '../../../stores/RootStore'
import { action, computed } from 'mobx'
import { RowVM } from './RowVM'
import { ColDef, RowClickedEvent, GridOptions } from 'ag-grid-community'
import { AGGridVM } from '../../../shared/ag-grid/AGGridVM'
import { nameOf } from '../../../shared/nameOf'
import moment from 'moment'

export class PendingUserSurveysWidgetVM extends AGGridVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    super()
    this.rootStore = rootStore
  }

  @computed
  public get hasSurveyPrivilege(): boolean {
    return this.rootStore.appStore.canSurvey
  }

  public getRowNodeId(row): string {
    return row.objectId
  }

  public get columnDefs(): ColDef[] {
    const s = this.rootStore.localizationStore.lzStrings
    return [
      {
        headerName: s.surveys.name,
        field: nameOf<RowVM, string>((e) => e.name),
        sort: 'asc',
        cellClass: 'pending-user-survey-name-cell',
      },
      {
        headerName: s.completedSurveysWidget.from,
        field: nameOf<RowVM, string>((e) => e.fromUserName),
        cellRenderer: 'fromCell',
      },
      {
        headerName: s.completedSurveysWidget.questions,
        field: nameOf<RowVM, number>((e) => e.questions),
      },
      {
        headerName: s.dashboard.published,
        field: nameOf<RowVM, string>((e) => e.publishedDateFormatted),
        comparator: (a, b) => this.comparator(a, b),
      },
      {
        headerName: s.surveys.due,
        field: nameOf<RowVM, string>((e) => e.dueDate),
        comparator: (a, b) => this.comparator(a, b),
      },
      {
        headerName: this.rootStore.labelsStore.getLabelByLanguageAndFor('categories'),
        field: nameOf<RowVM, string>((e) => e.categoryNames),
      },
    ]
  }

  @action
  public comparator(a, b) {
    if (a === '-' && b !== '-') return 1
    if (a !== '-' && b === '-') return -1
    if (a === '-' && b === '-') return 0
    let momentA = moment(a)
    let momentB = moment(b)
    if (momentA.isBefore(momentB)) return 1
    else if (momentA.isSame(momentB)) return 0
    else if (momentA.isAfter(momentB)) return -1
  }

  @computed
  public get rows(): RowVM[] {
    return this.rootStore.userSurveysStore.currentOrgUserSurveys.map(
      (e) => new RowVM(this.rootStore, e, this)
    )
  }

  @computed
  public get shouldRender(): boolean {
    if (!this.rootStore.audienceMembersStore.loaded) return false
    if (!this.rootStore.categoriesStore.loaded) return false
    if (!this.rootStore.labelsStore.loaded) return false
    return true
  }

  public getGridOptions(): GridOptions {
    return {
      ...this.baseGridOptions,
      rowData: this.rows,
      onRowClicked: (e) => this.rowClicked(e),
      getRowNodeId: (e) => this.getRowNodeId(e),
      columnDefs: this.ensureTooltipFields(this.columnDefs),
    }
  }

  public rowClicked(e: RowClickedEvent) {
    const row: RowVM = e.data
    if (!row) return
    row.openUserSurvey()
  }
}
