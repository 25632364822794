import React from 'react'
import { inject, observer } from 'mobx-react'
import { SurveyTemplateFolderEditVM } from '../view-models/SurveyTemplateFolderEditVM'
import { IconButton, TextField, Tooltip } from '@material-ui/core'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import CheckRounded from '@material-ui/icons/CheckCircleRounded'
import './SurveyTemplateFolderEdit.scss'

interface Props {
  vm: SurveyTemplateFolderEditVM
}

const SurveyTemplateFolderEdit: React.FC<Props> = ({ vm }) => {
  if (!vm) return null

  const renderEditFolderField = () => {
    return (
      <div className={'template-folder-edit'}>
        <TextField
          autoFocus
          onKeyDown={(e) => vm.onKeyDown(e)}
          error={!vm.nameValid}
          id='outlined-name'
          className={'template-folder-name'}
          value={vm.name}
          onChange={(e) => {
            vm.setName(e.target.value)
          }}
          margin='none'
          fullWidth
          variant='outlined'
        />
        <Tooltip title={`Cancel`} placement='bottom-start' enterDelay={500}>
          <IconButton
            size='small'
            className={'template-folder-cancel-button'}
            color='secondary'
            onClick={() => vm.closeEditFolder()}
          >
            <HighlightOffRoundedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={`Save`} placement='bottom-start' enterDelay={500}>
          <IconButton
            size='small'
            className={'template-folder-save-button'}
            color='secondary'
            onClick={() => vm.save()}
          >
            <CheckRounded />
          </IconButton>
        </Tooltip>
      </div>
    )
  }

  return <div id={'SurveyTemplateFolderEdit'}>{renderEditFolderField()}</div>
}

export default inject('localizationStore')(observer(SurveyTemplateFolderEdit))
