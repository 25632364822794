import { action, observable, computed } from 'mobx'
import { MediaItem } from '../../../media-items/aggregate/MediaItem'
import { ContentItem } from '../../../training-items/aggregate/ContentItem'
import { RootStore } from '../../../stores/RootStore'
import { TrainingPlansUpdateService } from '../../service/TrainingPlansUpdateService'
import { UserTrainingPlanTakeVM } from './UserTrainingPlanTakeVM'
import { PlanItemVM } from './PlanItemVM'
import { Attachment } from '../../../attachments/aggregate/Attachment'
import { ExerciseVM } from '../../../training-items/view-models/ExerciseVM'
import { ContentAttachmentType } from '../../../training-items/types/ContentAttachmentType'
import { isNumeric } from '../../../shared/isNumeric'
import { CMSFileAttachmentVM } from '../../../training-items/view-models/CMSFileAttachmentVM'
import { OldFileAttachmentVM } from '../../../training-items/view-models/OldFileAttachmentVM'
import { FileAttachmentVM } from '../../../training-items/view-models/FileAttachmentVM'
import { CMSMediaItemAttachmentVM } from '../../../training-items/view-models/CMSMediaItemAttachmentVM'
import { OldMediaItemAttachmentVM } from '../../../training-items/view-models/OldMediaItemAttachmentVM'
import { MediaItemAttachmentVM } from '../../../training-items/view-models/MediaItemAttachmentVM'

export class ContentVM {
  private rootStore: RootStore
  public contentItem: ContentItem
  public trainingItemId: string
  public takeVM: UserTrainingPlanTakeVM
  public svc: TrainingPlansUpdateService
  public exerciseVM: ExerciseVM

  constructor(
    rootStore: RootStore,
    contentItem: ContentItem,
    trainingItemId: string,
    takeVM: UserTrainingPlanTakeVM
  ) {
    this.rootStore = rootStore
    this.contentItem = contentItem
    this.trainingItemId = trainingItemId
    this.takeVM = takeVM
    this.svc = new TrainingPlansUpdateService()
    if (this.contentItem.exercise)
      this.exerciseVM = new ExerciseVM(rootStore, this, this.contentItem.exercise)
  }

  @action
  public loadMediaWatchSeconds(e: any) {
    if (!e) return
    if (this.takeVM.isReadOnly) return
    if (!this.isVideoContent) return
    this.videoEl.currentTime = this.mediaWatchSeconds
  }

  @computed
  public get videoEl(): HTMLMediaElement {
    if (!this.isVideoContent) return null
    const el = document.getElementById('contentVideo') as HTMLMediaElement
    if (!el) return null
    return el
  }

  @computed
  public get mediaWatchSeconds(): number {
    if (!this.isVideoContent) return 0
    return this.contentItem.mediaWatchSeconds
  }

  @computed
  public get height(): number {
    if (this.isLastContentItem && !this.parentTrainingItem.requireQuiz) return 36
    return 70
  }

  @computed
  public get parentTrainingItem(): PlanItemVM {
    return this.takeVM.trainingItemRows.find((row) => row.id === this.trainingItemId)
  }

  @computed
  public get isSelected(): boolean {
    if (!this.takeVM.selectedContentItem) return false
    if (this.id === this.takeVM.selectedContentItem.id) return true
    return false
  }

  @computed
  public get buttonString(): string {
    if (this.isComplete) return 'Mark as Incomplete'
    return 'Mark as Complete'
  }

  @computed
  public get id(): string {
    return this.contentItem.id
  }

  @computed
  public get title(): string {
    return this.contentItem.title
  }

  @computed
  public get type(): string {
    return this.contentItem.type.toLowerCase()
  }

  @computed
  public get typeUpperCase(): string {
    return this.contentItem.type.toUpperCase()
  }

  @computed
  public get isFirstContentItem(): boolean {
    return this.parentTrainingItem.contentItems[0].id === this.id
  }

  @computed
  public get isLastContentItem(): boolean {
    return (
      this.parentTrainingItem.contentItems[this.parentTrainingItem.contentItems.length - 1].id ===
      this.id
    )
  }

  @computed
  public get previousItem(): ContentVM {
    if (!this.takeVM.activeTrainingItem) return null
    const idx = this.takeVM.activeTrainingItem.contentItems.findIndex((row) => row.id === this.id)
    if (idx === 0) return this
    return this.takeVM.activeTrainingItem.contentItems[idx - 1]
  }

  @computed
  public get nextContentItem(): ContentVM {
    return this.parentTrainingItem.contentItems.filter(
      (item) => item.id === this.nextContentItemId
    )[0]
  }

  @computed
  public get contentMenuItemEnabled(): boolean {
    if (!this.takeVM.userTrainingPlan.objectId) return true
    if (this.takeVM.userTrainingPlan.isComplete) return true
    if (this.takeVM.reviewingPlan) return true
    if (!this.completionAllowed) return false
    return true
  }

  @computed
  public get completionAllowed(): boolean {
    if (!this.parentTrainingItem.completionAllowed) return false
    if (
      this.parentTrainingItem.mustCompleteInOrder &&
      !this.isFirstContentItem &&
      this.previousItem &&
      !this.previousItem.isComplete
    )
      return false
    if (
      this.isComplete &&
      this.nextContentItem &&
      this.nextContentItem.isComplete &&
      this.parentTrainingItem.mustCompleteInOrder
    )
      return false
    return true
  }

  @computed
  public get planIsComplete(): boolean {
    if (!this.takeVM) return false
    return this.takeVM.planIsComplete
  }

  @computed
  public get media(): MediaItem {
    if (this.contentItem.mediaItem) return this.contentItem.mediaItem

    if (!this.contentItem.mediaItemId) return null
    return this.rootStore.mediaItemsStore.getMediaItem(this.contentItem.mediaItemId)
  }

  @computed
  public get fileName(): string {
    if (this.type !== 'file') return ''
    return this.attachment.fileName
  }

  @computed
  public get link(): string {
    if (this.contentItem.type !== 'link') return ''
    if (!this.contentItem.link.startsWith('http')) return 'http://'.concat(this.contentItem.link)
    return this.contentItem.link
  }

  @computed
  public get descriptionTitle(): string {
    return this.contentItem.descriptionTitle
  }

  @computed
  public get descriptionBody(): string {
    return this.contentItem.descriptionBody
  }

  @computed
  public get attachment(): ContentAttachmentType {
    if (this.type === 'media' && this.media) {
      if (isNumeric(this.media.cmsItemId) || this.media.cmsItemId) {
        const vm = new CMSMediaItemAttachmentVM(this.rootStore, this.contentItem.type, this.media)
        vm.loadCMSItem()
        return vm
      }
      return new OldMediaItemAttachmentVM(this.rootStore, this.contentItem.type, this.media)
    }
    if (this.type === 'exercise' && this.media) {
      if (isNumeric(this.media.cmsItemId) || this.media.cmsItemId) {
        const vm = new CMSMediaItemAttachmentVM(this.rootStore, this.contentItem.type, this.media)
        vm.loadCMSItem()
        return vm
      }
      return new OldMediaItemAttachmentVM(this.rootStore, this.contentItem.type, this.media)
    }
    if (this.type === 'file' && this.file) {
      if (isNumeric(this.file.cmsItemId) || this.file.cmsItemId) {
        const vm = new CMSFileAttachmentVM(this.rootStore, this.contentItem.type, this.file)
        vm.loadCMSItem()
        return vm
      }
      return new OldFileAttachmentVM(this.rootStore, this.contentItem.type, this.file)
    }
  }

  @computed
  public get file(): Attachment {
    return this.contentItem.fileAttachment
  }

  @computed
  public get isComplete(): boolean {
    return this.contentItem.isComplete
  }

  @computed
  public get isVideoContent(): boolean {
    if (this.type !== 'media') return false
    if (!this.media) return false
    if (this.media.type !== 'video') return false
    return true
  }

  @action
  public onAction() {
    if (!this.completionAllowed) return
    this.toggleMarkAsComplete()
    if (!this.isComplete) {
      this.takeVM.reviewingPlan = false
    }
    this.takeVM.save()
    if (this.isComplete) {
      this.continue()
    }
  }

  @action
  public toggleMarkAsComplete() {
    this.contentItem.toggleMarkIsComplete()
    if (this.contentItem.exercise) this.exerciseVM.toggleMarkSetsComplete()
  }

  @action
  public markIsComplete() {
    this.contentItem.markIsComplete()
  }

  @action
  public markIsIncomplete() {
    this.contentItem.markIsIncomplete()
  }

  @action
  public setMediaWatchSeconds(val: number) {
    if (!this.isVideoContent) return
    if (this.takeVM.isReadOnly) return
    this.contentItem.setMediaWatchSeconds(val)
  }

  @computed
  public get nextContentItemId(): string {
    const idx = this.parentTrainingItem.contentItems.findIndex((item) => item.id === this.id)
    return this.parentTrainingItem.contentItems[idx + 1]
      ? this.parentTrainingItem.contentItems[idx + 1].id
      : null
  }

  @computed
  public get nextTrainingItem(): string {
    if (!this.takeVM.activeTrainingItem.nextTrainingItem) return null
    return this.takeVM.activeTrainingItem.nextTrainingItem.id
  }

  @action
  public async openAttachment() {
    if (this.type === 'media' && this.media) {
      ;(this.attachment as MediaItemAttachmentVM).openAttachment()
    }
    if (this.type === 'exercise' && this.media) {
      ;(this.attachment as MediaItemAttachmentVM).openAttachment()
    }
    if (this.type === 'file' && this.file) {
      ;(this.attachment as FileAttachmentVM).openAttachment()
    }
  }

  @action
  public continue() {
    if (this.nextContentItem) {
      this.takeVM.setSelectedContentItem(this.nextContentItemId, this.parentTrainingItem.id)
    } else if (!this.nextContentItem && this.parentTrainingItem.requireQuiz) {
      this.takeVM.openQuiz(this.parentTrainingItem.quiz)
      // return
    } else if (!this.nextContentItem && this.parentTrainingItem.requireCertificate) {
      this.takeVM.setSelectedCertificateUpload(this.parentTrainingItem.id)
    } else if (!this.nextContentItem && this.nextTrainingItem) {
      this.takeVM.setActiveTrainingItem(this.nextTrainingItem)
      this.takeVM.activeTrainingItem.isExpanded = true
      this.takeVM.activeTrainingItem.setActiveItem()
    } else if (!this.nextTrainingItem && this.takeVM.planQuiz && this.takeVM.requireQuiz) {
      this.takeVM.openQuiz(this.takeVM.planQuiz)
    } else if (!this.nextTrainingItem && this.takeVM.requireCertificate) {
      this.takeVM.setSelectedCertificateUpload('for-plan')
    }
    this.takeVM.save()
  }

  @computed
  public get trainer(): any {
    if (!this.takeVM.trainingPlan) return ''
    return this.takeVM.trainingPlan.trainer
  }

  @computed
  public get mailToLink(): any {
    if (!this.takeVM.trainingPlan) return ''
    let foundUser = this.rootStore.audienceMembersStore.getUser(this.takeVM.trainingPlan.trainer.id)
    if (foundUser) {
      let href = 'mailto:' + foundUser.email
      return href
    }
  }

  @computed
  public get sets(): number {
    if (!this.contentItem) return 0
    if (!this.contentItem.exercise) return 0
    return this.contentItem.exercise.goal.sets
  }

  @computed
  public get reps(): number {
    if (!this.contentItem) return 0
    if (!this.contentItem.exercise) return 0
    return this.contentItem.exercise.goal.reps
  }

  @computed
  public get weight(): number {
    if (!this.contentItem) return 0
    if (!this.contentItem.exercise) return 0
    return this.contentItem.exercise.goal.weight
  }

  @computed
  public get time(): number {
    if (!this.contentItem) return 0
    if (!this.contentItem.exercise) return 0
    return this.contentItem.exercise.goal.time
  }

  @computed
  public get percent(): number | string {
    if (!this.contentItem) return 0
    if (!this.contentItem.exercise) return 0
    if (this.contentItem.exercise.goal.percent === 0) return '-'
    return this.contentItem.exercise.goal.percent
  }

  @computed
  public get setsValid(): boolean {
    if (!this.sets) return false
    if (this.sets <= 0) return false
    return true
  }

  @computed
  public get repsValid(): boolean {
    if (!this.reps && this.reps !== 0) return false
    if (this.reps < 0) return false
    return true
  }

  @computed
  public get weightValid(): boolean {
    if (!this.weight && this.weight !== 0) return false
    if (this.weight < 0) return false
    return true
  }

  @computed
  public get timeValid(): boolean {
    if (!this.time && this.time !== 0) return false
    if (this.time < 0) return false
    return true
  }

  @computed
  public get percentValid(): boolean {
    if (!this.percent) return false
    return true
  }
}
