import { inject, observer } from 'mobx-react'
import React from 'react'
import beautify from 'js-beautify'
import AceEditor from 'react-ace'
import 'ace-builds/webpack-resolver'
import 'ace-builds/src-noconflict/mode-html'
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/theme-monokai'
import 'ace-builds/src-noconflict/ext-language_tools'
import 'ace-builds/src-noconflict/ext-searchbox'
import LocalizationStore from '../../localization/LocalizationStore'
import { NotificationsStore } from '../store/NotificationsStore'
import './NotificationTemplateEditor.scss'

interface Props {
  notificationsStore?: NotificationsStore
  localizationStore?: LocalizationStore
}

const NotificationTemplateEditor: React.FC<Props> = ({ notificationsStore, localizationStore }) => {
  const { notificationTemplate: strings } = localizationStore.lzStrings
  const { editVM: vm } = notificationsStore

  if (!vm) return null

  const onChange = (newValue) => {
    vm.setBody(newValue)
  }

  const onLoad = (newValue) => {
    console.debug('on load')
  }

  const beautify_opts = {
    'brace_style': 'expand',
    'indent_scripts': 'separate',
    'wrap-attributes': 'force-expand-multiline',
    'wrap_line_length': '350',
    // 'extra_liners': ['head', 'body', '/html', 'p', '/p', 'a'],
    'wrap-attributes-indent-size': 1,
    'indent-inner-html': false,
    "indent_size": "1",
    "indent_char": " ",
    "jslint_happy": true,
  }

  return (
    <div id='EditorContainer'>
      <AceEditor
        placeholder='Notification Template HTML Editor'
        mode={'html'}
        theme='monokai'
        name='NotificationTemplateEditor'
        width='100%'
        onLoad={onLoad}
        readOnly={vm.isSaving || vm.resetDialog}
        onChange={onChange}
        fontSize={14}
        showPrintMargin={true}
        showGutter={true}
        highlightActiveLine={true}
        value={vm.body ? vm.body : ''}
        wrapEnabled={true}
        setOptions={{
          enableBasicAutocompletion: false,
          enableLiveAutocompletion: false,
          enableSnippets: false,
          showLineNumbers: true,
          tabSize: 2,
        }}
      />
    </div>
  )
}

export default inject(
  'notificationsStore',
  'localizationStore'
)(observer(NotificationTemplateEditor))
