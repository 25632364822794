import { INLPResponseDTO } from '../dtos/INLPResponseDTO'
import { INLPResponseGetRequest } from '../interfaces/INLPResponseGetRequest'
import Parse from 'parse'
import { INLPModelRecord } from '../interfaces/INLPModelRecord'
import { IUniqueTagsResponse } from '../interfaces/IUniqueTagsResponse'

export class NLPResponsesService {
  public async getNLPResponses(req: INLPResponseGetRequest): Promise<INLPResponseDTO[]> {
    const query = new Parse.Query('nlpResponses')
    query.equalTo('surveyResponseId', req.surveyResponseId)
    query.equalTo('questionId', req.questionId)
    query.equalTo('organizationId', req.organizationId)
    query.skip(req.skip)
    query.limit(req.limit)
    query.addDescending('createdAt')
    const results = await query.find()

    return results.map((a) => a.toJSON() as any)
  }

  public async getUniqueLabels() {
    const result: IUniqueTagsResponse = await Parse.Cloud.run('getUniqueAILabels')
    return result
  }

  public async trainAIModel(record: INLPModelRecord) {
    const result = await Parse.Cloud.run('trainAIModel', record)
    return result
  }
}
