import Parse from 'parse'
import { observable, action, computed } from 'mobx'
import { DataStore } from '../../shared/data/DataStore'
import { deserialize } from 'serializr'
import { PulseSet } from '../aggregate/PulseSet'
import { IPulseSetDTO } from '../dtos/IPulseSetDTO'
import { RootStore } from '../../stores/RootStore'
import { PulseSetsViewModelsService } from '../services/PulseSetsViewModelsService'

export class PulseSetsStore extends DataStore<PulseSet, IPulseSetDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, PulseSet, 'pulseSets', [
      'name',
      'subscribedOrganizationIds',
      'pulseCampaignTemplateIds',
    ])
    this.viewModels = new PulseSetsViewModelsService(this.rootStore, this)
  }

  @observable public isLoaded: boolean = false
  @observable public viewModels: PulseSetsViewModelsService = null

  protected getBaseQuery() {
    const query = new Parse.Query(this.className)
    return query
  }

  @computed
  public get pulseSets(): PulseSet[] {
    return this.records
  }

  @action
  public onListRecordsLoaded() {
    this.isLoaded = true
  }

  public getPulseSet(objectId): PulseSet {
    return this.pulseSets.find((e) => e.objectId === objectId)
  }
}
