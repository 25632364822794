import React from 'react'
import rootStore from '../../../stores/RootStore'
import TopToolbar from './TopToolbar'
import Tabs from './TabsBar'
import './TrainingItemEditScreen.scss'

export default class TrainingItemEditScreen extends React.Component<any, any> {
  constructor(props) {
    super(props)
    
    if (props.params && props.params.id && props.params.id !== 'new') {
      rootStore.trainingItemsStore.editTrainingItem(props.params.id)
    }
    if (props.params && props.params.id && props.params.id === 'new') {
      rootStore.trainingItemsStore.createNewTrainingItem()
    }
    if (props.params && props.params.catalogId) {
      rootStore.trainingItemsStore.createItemFromCatalog(props.params.catalogId)
    }
  }

  routerWillLeave(nextLocation) {
    if (!rootStore.appStore.router.location.pathname.startsWith('/trainingitems')) return
    if (!rootStore.trainingItemsStore.trainingItemEditVM) return
    if (rootStore.trainingItemsStore.trainingItemEditVM.isDirty) {
      return 'You have unsaved information, are you sure you want to leave this page?'
    }
  }

  componentDidMount() {
    if (!this.props.router) return
    this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave.bind(this))
  }

  render() {
    if (!rootStore.trainingItemsStore) return null
    return (
      <div
        id='TrainingItemEditScreen2'
        className={
          rootStore.trainingItemsStore.trainingItemEditVM &&
          rootStore.trainingItemsStore.trainingItemEditVM.isCreatingFromModal
            ? 'TrainingItemFromModal'
            : ''
        }
      >
        <TopToolbar />
        <Tabs />
      </div>
    )
  }
}
