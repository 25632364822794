import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core'
import Avatar from '../../shared/Avatar'
import { ContactRowVM } from '../view-models/ContactRowVM'

interface Props {
  contact: ContactRowVM
  style: any
}

const ContactRow: FC<Props> = ({ contact, style }) => {
  return (
    <ListItem
      id='ContactRow'
      disabled={contact.isReadOnly}
      style={style}
      button
      selected={contact.isParticipantSelected}
    >
      <ListItemAvatar>
        <Avatar size={30} user={contact} style={{ fontSize: '1rem' }} />
      </ListItemAvatar>
      <ListItemText primary={contact.name} secondary={contact.filterSubstring} />
    </ListItem>
  )
}

export default observer(ContactRow)
