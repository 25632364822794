import { RootStore } from '../../stores/RootStore'
import { computed } from 'mobx'
import moment from 'moment'
import { PostTemplatesTableVM } from './PostTemplatesTableVM'
import { PostTemplate } from '../aggregate/PostTemplate'
import { AGGridRowVM } from '../../shared/ag-grid/AGGridRowVM'

export class PostTemplatesRowVM extends AGGridRowVM<PostTemplate> {
  private tableVM: PostTemplatesTableVM
  constructor(rootStore: RootStore, postTemplate: PostTemplate, tableVM: PostTemplatesTableVM) {
    super(rootStore, postTemplate, tableVM)
    this.tableVM = tableVM
  }

  private blankValue = '--'

  private formatDate(isoString: string) {
    if (!isoString) return this.blankValue
    return moment(isoString).format('l, LT')
  }

  @computed
  public get title(): string {
    return this.aggregate.title
  }

  @computed
  public get createdAt(): string {
    if (this.aggregate.createdAt) return this.formatDate(this.aggregate.createdAt)
    return this.blankValue
  }

  @computed
  public get createdBy(): string {
    if (this.aggregate.createdBy) return this.aggregate.createdBy
    return this.blankValue
  }

  @computed
  public get updatedAt(): string {
    if (this.aggregate.updatedAt) return this.formatDate(this.aggregate.updatedAt)
    return this.blankValue
  }

  @computed
  public get updatedBy(): string {
    if (this.aggregate.updatedBy) return this.aggregate.updatedBy
    return this.blankValue
  }

  public click() {
    const editVM = this.rootStore.announcementsStore.editVM
    editVM.setTitle(this.aggregate.title)
    editVM.setBody(this.aggregate.body)
    editVM.clearAttachments()
    if (this.aggregate.attachments) editVM.addAttachments(this.aggregate.attachments)
  }
}
