import * as React from 'react'
import './OptionsList.scss'
import { observer } from 'mobx-react'
import { TextField, InputAdornment, Button } from '@material-ui/core'
import { SortableHandle } from 'react-sortable-hoc'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import { OptionVM } from '../../view-models/OptionVM'
import EmojiMenu from './EmojiMenu'
import { QuizVM } from '../../view-models/QuizVM'
import OptionMenu from './OptionMenu'
import CorrectMenu from './CorrectMenu'

interface Props {
  option: OptionVM
  quiz: QuizVM
  idx: number
}

const DragHandle = SortableHandle(() => (
  <Button className='drag-handle'>
    <DragIndicatorIcon />
  </Button>
))

const OptionRow = ({ quiz, option, idx }: Props) => (
  <TextField
    className='survey-edit-side-panel-option-row-textfield'
    key={'opttxt' + idx}
    id={'opttxt' + idx}
    value={option.text || ''}
    onChange={(e) => option.setText(e.target.value)}
    margin='none'
    fullWidth
    variant='outlined'
    InputProps={{
      startAdornment: (
        <InputAdornment position='start'>
          <EmojiMenu idx={idx} quiz={quiz} />
        </InputAdornment>
      ),
      endAdornment: (
        <InputAdornment position='end'>
          <CorrectMenu idx={idx} quiz={quiz} />
          <OptionMenu idx={idx} quiz={quiz} />
          <DragHandle />
        </InputAdornment>
      ),
    }}
  />
)

export default observer(OptionRow)
