import { observable, action } from 'mobx'
import { IAggregate } from '../../shared/data/IAggregate'
import { IConsentDTO } from '../dtos/IConsentDTO'
import { serializable, serialize, object, list } from 'serializr'
import { ConsentText } from './ConsentText'
import { Attachment } from '../../upload/aggregate/Attachment'

export class Consent implements IConsentDTO, IAggregate {
  static create(orgId) {
    const consent = new Consent()
    consent.organizationId = orgId
    return consent
  }

  @serializable @observable public objectId: string = ''
  @serializable @observable public versionNumber: number = 1
  @serializable @observable public createdByUserId: string = ''
  @serializable(object(ConsentText)) @observable public text: ConsentText = new ConsentText()
  @serializable @observable public organizationId: string = ''
  @serializable @observable public location: string = ''
  @serializable @observable public isDeleted: boolean = false
  @serializable(list(object(Attachment))) @observable public attachments: Attachment[] = []

  public isOnServer: boolean = false
  public language: string

  public markIsNotOnServer() {
    this.isOnServer = false
  }

  public markIsOnServer() {
    this.isOnServer = true
  }

  public serialize(): IConsentDTO {
    return serialize(this)
  }
}
