import { action, computed, observable } from 'mobx'
import moment from 'moment'
import { RootStore } from '../../../../stores/RootStore'
import { ICampaignStatsDTO } from '../../../dto/ICampaignStatsDTO'
import { IPulseCampaignDTO } from '../../../dto/IPulseCampaignDTO'
import { IPulseCampaignSurveyCloseRequest } from '../../../interfaces/IPulseCampaignSurveyCloseRequest'
import { PulseCampaignsService } from '../../../service/PulseCampaignsService'
import { CampaignRowVM } from '../all-campaigns/CampaignRowVM'
import { CampaignsListVM } from '../all-campaigns/CampaignsListVM'
import { SurveyParticipantsAGGridVM } from './SurveyParticipantsAGGridVM'
import * as Sentry from '@sentry/browser'

export class CampaignDetailsVM {
  private rootStore: RootStore

  constructor(
    rootStore: RootStore,
    objectId: string,
    listVM: CampaignsListVM,
    activeIndex?: number
  ) {
    this.rootStore = rootStore
    this.objectId = objectId
    this.activeSurveyIndex = activeIndex
    this.listVM = listVM
    this.lz = this.rootStore.localizationStore.lzStrings.pulseCampaign
    this.loadInvitationsList()
  }

  private loadInvitationsList() {
    this.surveyParticipantsList = undefined
    setTimeout(() => {
      this.surveyParticipantsList = new SurveyParticipantsAGGridVM(
        this.rootStore,
        this.selectedSurvey.surveyId,
        this.isAnonymous
      )
    }, 100)
  }

  @observable public listVM: CampaignsListVM = null
  @observable public objectId: string = null
  @observable public surveyParticipantsList: SurveyParticipantsAGGridVM = null
  @observable public activeSurveyIndex: number = 0
  @observable public showCloseSurveyDialog: boolean = false
  @observable public lz = undefined
  @observable public isSnackbarOpen: boolean = false
  @observable public snackbarMessage: string = ''

  @computed
  public get selectedSurvey() {
    return this.campaign.surveys[this.activeSurveyIndex]
  }

  @computed
  public get isSystemAdmin(): boolean {
    return this.rootStore.appStore.isSystemAdmin
  }

  @computed
  public get canSendSurveyNow(): boolean {
    if (!this.isSystemAdmin && !this.rootStore.appStore.canManagePulseCampaigns) return false
    if (this.isSelectedSurveySent) return false
    if (this.campaign.status !== 'initiated') return false
    return true
  }

  @computed
  public get canEditSurvey(): boolean {
    if (!this.isSystemAdmin) return false
    if (!this.isSelectedSurveySent) return false
    return true
  }

  @computed
  public get canRefreshSurvey(): boolean {
    if (!this.isSystemAdmin) return false
    if (!this.isSelectedSurveySent) return false
    return true
  }

  @action
  public handleNoCampaign() {
    alert(`This campaign has moved to another state. Dialog will be closed.`)
    this.listVM.closeDetailsModal()
  }

  @action
  public openSurveyForEdit() {
    let url = '/surveys/editv2/'
    url += this.selectedSurvey.surveyId
    this.rootStore.appStore.router.push(url)
  }

  @action
  public async refreshSurveyInDW() {
    const svc = new PulseCampaignsService()
    await svc.refreshPulseCampaignSurveyInDW(
      this.rootStore.appStore.currentOrgId,
      this.campaign.objectId,
      this.selectedSurvey.surveyId
    )
  }

  @action
  public async sendSurveyNow() {
    const svc = new PulseCampaignsService()
    await svc.sendSurveyNow(
      this.rootStore.appStore.currentOrgId,
      this.campaign.objectId,
      this.selectedSurvey.id
    )
  }

  @computed
  public get campaign() {
    const pulseCampaign = this.listVM.dataStore.rows.find((e) => e.objectId === this.objectId)
    if (!pulseCampaign) return null
    return pulseCampaign
  }

  @computed public get name() {
    return this.campaign.name
  }

  @computed
  public get type() {
    let pc = this.rootStore.pulseCampaignTypesStore.pulseCampaignTypes.find(
      (e) => e.objectId === this.campaign.pulseCampaignTypeId
    )
    if (!pc) return '--'
    if (this.rootStore.pulseCampaignTypesStore.isLoaded) return pc.name
  }

  @computed
  public get owner() {
    let owner = this.rootStore.audienceMembersStore.getUser(this.campaign.ownerUserId)
    if (!owner) return '--'
    if (this.rootStore.audienceMembersStore.loaded) return owner.name
  }

  @computed
  public get startDate(): string {
    return `${moment(this.campaign.startDate).format('ll')}`
  }

  @computed
  public get endDate(): string {
    return `${moment(this.campaign.endDate).format('ll')}`
  }

  @computed
  public get effectiveDate() {
    return `${this.startDate} - ${this.endDate}`
  }

  private capitalize(str) {
    const lower = str.toLowerCase()
    return str.charAt(0).toUpperCase() + lower.slice(1)
  }

  @computed
  public get status() {
    return this.capitalize(this.campaign.status)
  }

  @computed
  public get stats(): ICampaignStatsDTO {
    return this.campaign.stats
  }

  @computed
  public get polarityScore() {
    if (!this.stats) return null
    if (!this.stats.averagePolarityScore && this.stats.averagePolarityScore !== 0) return null
    return Math.round(this.stats.averagePolarityScore * 100)
  }

  @computed
  public get responsesText(): string {
    if (!this.stats) return '--'
    if (!this.stats.responses) return '--'
    return `${this.responseCount} of ${this.invitationsCount} (${this.responsesPercentage})`
  }

  @computed
  public get responseCount(): number {
    if (!this.stats) return 0
    if (!this.stats.responses) return 0
    return this.stats.responses
  }

  @computed
  public get invitationsCount(): number {
    if (!this.stats) return 0
    if (!this.stats.invitations) return 0
    return this.stats.invitations
  }

  @computed
  public get responsesPercentage(): string {
    const val = this.responseCount / this.invitationsCount
    if (!val) return '--'
    return `${String(Math.round(val * 100))}%`
  }

  @action
  public editPulseCampaign() {
    this.rootStore.appStore.router.push(`/pulse/v1/edit/${this.objectId}`)
  }

  @computed public get campaignRow(): CampaignRowVM {
    const row = new CampaignRowVM(this.rootStore, this.campaign, this.listVM, true)
    row.isExpanded = true
    return row
  }

  @action
  public copyPulseCampaign() {
    this.rootStore.appStore.router.push(`/pulse/v1/fromCopy/${this.objectId}`)
  }

  @action
  public editPulseAnalysis() {
    this.rootStore.appStore.router.push(`/pulse/v1/analysis/${this.objectId}`)
  }

  @action
  public close() {
    this.listVM.closeDetailsModal()
  }

  @action
  public setActiveSurvey(idx) {
    this.activeSurveyIndex = idx
    this.loadInvitationsList()
  }

  @computed
  public get activeSurveyName() {
    return this.selectedSurvey.name
  }

  @computed
  public get canCloseSurvey(): boolean {
    if (!this.isSelectedSurveySent) return false
    if (this.selectedSurvey.status !== 'sent') return false
    return true
  }

  @computed
  public get isSelectedSurveySent() {
    if (!this.selectedSurvey.surveyId) return false
    if (this.selectedSurvey.status === 'sent') return true
    if (this.selectedSurvey.status === 'closed') return true
    return false
  }

  @action
  public openSnackbar(msg) {
    this.snackbarMessage = msg
    this.isSnackbarOpen = true
  }

  @action
  public closeSnackbar() {
    this.isSnackbarOpen = false
  }

  @action
  public toggleCloseSurveyDialog() {
    this.showCloseSurveyDialog = !this.showCloseSurveyDialog
  }

  @action
  public async closeSurvey() {
    this.showCloseSurveyDialog = false
    let result
    const svc = new PulseCampaignsService()
    const surveyId = this.selectedSurvey.surveyId
    if (!surveyId) return
    const req = {
      organizationId: this.rootStore.appStore.currentOrgId,
      pulseCampaignId: this.campaign.objectId,
      surveyId: surveyId,
    } as IPulseCampaignSurveyCloseRequest
    try {
      result = await svc.closePulseCampaignSurvey(req)
      if (result && result.success) {
        this.openSnackbar(`${this.lz.success}  This survey has been closed`)
        // setTimeout(() => this.rootStore.appStore.router.push('/pulse/v1/campaigns'), 1000)
      }
    } catch (e) {
      console.error(e)
      this.openSnackbar(`${this.lz.error} Unable to close campaign`)
      Sentry.captureException({
        message: 'Unable to close pulse campaign',
        exception: e,
        userId: this.rootStore.appStore.currentUserId,
        orgId: this.rootStore.appStore.currentOrgId,
      })
      return
    }
  }

  @computed
  public get isAnonymous() {
    return this.campaign.anonymizeResults
  }
}
