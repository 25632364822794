import { RootStore } from '../../stores/RootStore'
import { observable, action, computed } from 'mobx'
import { Goal } from '../aggregate/Goal'
import { GoalEditVM } from '../view-models/GoalEditVM'
import GoalsCatalogWidgetVM from '../view-models/goal-catalog-widget/GoalCatalogWidgetVM'
import { DataStore } from '../../shared/data/DataStore'
import { IGoalDTO } from '../dto/IGoalDTO'

export class GoalsCatalogStore extends DataStore<Goal, IGoalDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, Goal, 'catalogGoals', [
      'name',
      'categoryId',
      'organizationId',
      'startDate',
      'endDate',
      'description',
      'potentialObstacles',
      'dataSourceId',
      'facetId',
      'targetInputValue',
      'goalDirection',
      'aggregationMethod',
      'consistencyThreshold',
      'aggregationPeriod',
      'createdByUserId',
      'worksheetId',
      'targetMode',
      'target',
      'current',
      'measureGoal',
      'participatingUserIds',
      'participants',
      'targetPercentage',
      'askAboutProgress',
      'sendReminders',
      'progressInterval',
      'reminderInterval',
      'thumbnail',
      'thumbnailFileName',
      'associateToTrainingPlans',
      'associatedTrainingPlanIds',
      'isCatalogGoal',
      'activeCatalogPlans',
    ])
  }

  @observable public goalsCatalogWidgetVM: GoalsCatalogWidgetVM = null
  @observable public loaded: boolean = false

  @computed
  public get currentOrgCatalogGoals(): Goal[] {
    return this.records
  }

  @action
  public onListRecordsLoaded() {
    this.goalsCatalogWidgetVM = new GoalsCatalogWidgetVM(this.rootStore)
    this.loaded = true
  }

  @computed
  public get canCreateGoals(): boolean {
    if (
      this.rootStore.organizationsStore.currentOrganization &&
      !this.rootStore.organizationsStore.currentOrganization.isGoalsEnabled
    )
      return false
    return this.rootStore.appStore.canCreateGoals
  }

  public getCatalogGoal(objectId): Goal {
    return this.currentOrgCatalogGoals.find((goal) => goal.objectId === objectId)
  }

  public deleteCatalogGoal(objectId) {
    this.currentOrgCatalogGoals.splice(this.getCatalogGoalIndex(objectId), 1)
  }

  private getCatalogGoalIndex(objectId): number {
    return this.currentOrgCatalogGoals.findIndex((e) => e.objectId === objectId)
  }

  @action
  public editCatalogGoal(id: string, attempts: number = 0) {
    if (attempts > 10) console.log('Error: No goal found')
    const goal = this.getCatalogGoal(id)
    if (!goal || goal === undefined) {
      setTimeout(() => this.editCatalogGoal(id, attempts++), 500)
    } else {
      this.rootStore.goalsStore.goalEditVM = new GoalEditVM(this.rootStore, goal.clone())
    }
  }

  @action
  public createNewCatalogGoal() {
    this.rootStore.goalsStore.goalEditVM = new GoalEditVM(
      this.rootStore,
      Goal.createCatalogGoal(
        this.rootStore.appStore.currentUserId,
        this.rootStore.appStore.currentOrgId
      )
    )
  }
}
