import React from 'react'
import { inject, observer } from 'mobx-react'
import { EventsStore } from '../../store/EventsStore'
import { DatePicker } from '@material-ui/pickers'

interface IMiniCalendar {
  eventsStore?: EventsStore
}

const MiniCalendar: React.FC<IMiniCalendar> = ({ eventsStore }) => {
  const { calendarVM: vm } = eventsStore

  return (
      <DatePicker
        disableToolbar
        orientation='portrait'
        variant='static'
        openTo='date'
        value={vm.displayedDate}
        onChange={(date) => vm.onNavigate(date)}
      />
  )
}

export default inject('eventsStore')(observer(MiniCalendar))
