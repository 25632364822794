import { observable, action } from 'mobx'
import { serializable, serialize, deserialize, date, list, object, primitive } from 'serializr'
import { IWelcomeBannerDTO } from '../dtos/IWelcomeBannerDTO'
import { Attachment } from '../../attachments/aggregate/Attachment'

export class WelcomeBanner implements IWelcomeBannerDTO {
  public static create(): WelcomeBanner {
    const announcement = new WelcomeBanner()
    return announcement
  }

  @serializable @observable public body: string = ''
  @serializable @observable public title: string = ''
  @serializable(list(object(Attachment))) @observable public attachments: Attachment[] = []

  isOnServer: boolean = false

  @action
  public setTitle(val: string) {
    this.title = val
  }

  @action
  public setBody(val: string) {
    this.body = val
  }

  @action
  public addAttachment(attachment: Attachment) {
    this.attachments.push(attachment)
  }

  @action
  public removeAttachmentByIndex(index: number) {
    if (index < 0) return
    this.attachments.splice(index, 1)
  }

  @action
  public removeAttachmentByObjectId(objectId: string) {
    this.attachments = this.attachments.filter((e) => e.objectId !== objectId)
  }

  @action
  public markIsOnServer() {
    this.isOnServer = true
  }

  @action
  public markIsNotOnServer() {
    this.isOnServer = true
  }

  public serialize() {
    const obj = serialize(this)
    return obj
  }

  public clone(): WelcomeBanner {
    return deserialize(WelcomeBanner, this.serialize())
  }
}
