import { computed } from 'mobx'
import moment from 'moment'
import { IUserSurveyEventDTO } from '../../userSurveyEvents/dtos/IUserSurveyEventDTO'
export class HistoryRowVM {
  private userSurveyEvent: IUserSurveyEventDTO
  constructor(userSurveyEvent: IUserSurveyEventDTO) {
    this.userSurveyEvent = userSurveyEvent
  }

  @computed
  public get eventType() {
    return this.userSurveyEvent.eventType
  }

  @computed
  public get eventSource() {
    return this.userSurveyEvent.eventSource
  }

  private formatDate(isoString: string) {
    const date = moment(isoString)
    return date.format('LLL')
  }

  @computed
  public get eventDateTime() {
    const date = moment(this.userSurveyEvent.eventDateTime)
    return date.format('LLL')
  }

  private getRespondedByAdditionalInfo(userSurveyEvent: IUserSurveyEventDTO) {
    const publisher = userSurveyEvent.additionalInfo.causedByUserName
    const additionalInfo = userSurveyEvent.additionalInfo
    if (additionalInfo.isPaperSurvey) {
      return `Paper Survey Response By: ${publisher}`
    } else {
      return `Responded by user`
    }
  }

  private getPublishedByAdditionalInfo(userSurveyEvent: IUserSurveyEventDTO) {
    const publisher = this.userSurveyEvent.additionalInfo.causedByUserName

    if (userSurveyEvent.eventSource === 'system') {
      return `Scheduled Publish by: ${publisher}`
    } else if (userSurveyEvent.additionalInfo.isPaperSurvey) {
      return `Paper Survey Published by: ${publisher}`
    } else {
      let info = `Published by: ${publisher}`
      if (userSurveyEvent.additionalInfo.reason) {
        info = `${info} for ${userSurveyEvent.additionalInfo.reason}`
      }
      return info
    }
  }

  private getCancelledByAdditionalInfo(userSurveyEvent: IUserSurveyEventDTO) {
    const canceler = this.userSurveyEvent.additionalInfo.causedByUserName
    const reason = this.userSurveyEvent.additionalInfo.reason
    if (userSurveyEvent.eventSource === 'system') {
      return reason
    } else {
      return `${reason} by: ${canceler}`
    }
  }

  private getMarkedAsAdditionalInfo(userSurveyEvent: IUserSurveyEventDTO) {
    const causedByName = this.userSurveyEvent.additionalInfo.causedByUserName

    return `Marked as ${userSurveyEvent.eventType} by: ${causedByName}`
  }

  private getPublishedAdditionalInfo(userSurveyEvent: IUserSurveyEventDTO) {
    if (userSurveyEvent.additionalInfo) {
      const status = userSurveyEvent.additionalInfo.reason
      return `${status}`
    }
  }

  private getScheduledAdditionalInfo(userSurveyEvent: IUserSurveyEventDTO) {
    let sourceText = ''
    if (userSurveyEvent.eventSource === 'user') {
      sourceText = `By: ${userSurveyEvent.additionalInfo.causedByUserName}`
    }

    let forTime = ''
    if (userSurveyEvent.additionalInfo.scheduledTime) {
      forTime = `Scheduled Time: ${this.formatDate(userSurveyEvent.additionalInfo.scheduledTime)}`
    }

    return `${forTime}${sourceText}`
  }

  private getDefaultAdditionalInfo(userSurveyEvent: IUserSurveyEventDTO) {
    if (userSurveyEvent.additionalInfo && userSurveyEvent.additionalInfo.reason) {
      return userSurveyEvent.additionalInfo.reason
    }
  }

  @computed
  public get additionalInfo() {
    const eventType = this.userSurveyEvent.eventType
    if (eventType === 'responded') {
      return this.getRespondedByAdditionalInfo(this.userSurveyEvent)
    } else if (eventType === 'published') {
      return this.getPublishedByAdditionalInfo(this.userSurveyEvent)
    } else if (eventType === 'cancelled') {
      return this.getCancelledByAdditionalInfo(this.userSurveyEvent)
    } else if (eventType === 'viewed') {
      return `Viewed by user`
    } else if (eventType === 'late') {
      return 'Marked as Late by the System'
    } else if (eventType === 'archived' || eventType === 'inactive') {
      return this.getMarkedAsAdditionalInfo(this.userSurveyEvent)
    } else if (eventType === 'published-email' || eventType === 'reminder-email') {
      return this.getPublishedAdditionalInfo(this.userSurveyEvent)
    } else if (eventType === 'scheduled') {
      return this.getScheduledAdditionalInfo(this.userSurveyEvent)
    } else if (eventType === 'update-due-date') {
      return `New Due Date: ${this.formatDate(this.userSurveyEvent.additionalInfo.dueDate)}`
    } else {
      return this.getDefaultAdditionalInfo(this.userSurveyEvent)
    }
  }

  @computed
  public get objectId() {
    return this.userSurveyEvent.objectId
  }
}
