import Parse from 'parse'
import { observable, action, computed, runInAction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { UserSurvey } from '../aggregate/UserSurvey'
import { DataStore } from '../../shared/data/DataStore'
import { IUserSurveyDTO } from '../dtos/IUserSurveyDTO'
import moment from 'moment'

export class UserSurveysCountStore extends DataStore<UserSurvey, IUserSurveyDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, UserSurvey, 'userSurveys', [])
  }

  protected getBaseQuery() {
    const queryDueDate = new Parse.Query(this.className)
    queryDueDate.greaterThan('dueDate', moment.utc(new Date()).add(-14, 'days').toISOString())
    const queryNoDueDate = new Parse.Query(this.className)
    queryNoDueDate.equalTo('dueDate', null)
    const query = Parse.Query.or(queryNoDueDate, queryDueDate)
    query.equalTo('userId', this.rootStore.appStore.currentUserId)
    query.equalTo('organizationId', this.rootStore.appStore.currentOrgId)
    query.equalTo('isActive', true)
    query.equalTo('isWelcomeEmail', false)
    return query
  }

  @computed
  public get currentOrgActiveUserSurveys(): UserSurvey[] {
    return this.records
  }

  public clearData() {
    this.currentOrgActiveUserSurveys.splice(0, this.currentOrgActiveUserSurveys.length)
  }
}
