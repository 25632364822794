import { observable, action, computed, runInAction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import Resource from './aggregate/Resource'
import ResourceEditForm from './view-models/ResourceEditVM'
import { PagedDataVM } from '../../shared/view-models/PagedDataVM'
import { IResourceDTO } from './dtos/IResourceDTO'
import { DataStore } from '../../shared/data/DataStore'

export class ResourcesStore extends DataStore<Resource, IResourceDTO> {
  pagedData: PagedDataVM

  constructor(rootStore: RootStore) {
    super(rootStore, Resource, 'resources', ['organizationId', 'name', 'description'])
    this.pagedData = new PagedDataVM(
      this.rootStore,
      this.resources,
      ['name', 'publishedByUserName'],
      '',
      'CalendarManagementWidget'
    )
  }

  @observable public selectedResourceId: string
  @observable public editForm: ResourceEditForm = null
  @observable public showDrawer: boolean = false
  @observable public showDeleteDialog: boolean = false
  @observable public loaded: boolean = false

  @computed
  public get resources() {
    return this.records
  }

  @action
  public onListRecordsLoaded() {
    this.loaded = true
  }

  @computed
  public get currentOrgResources() {
    return this.resources
  }

  public setSelectedResourceId(id) {
    this.selectedResourceId = id
    this.editForm = new ResourceEditForm(this.rootStore, this.getResource(id))
  }

  @action
  public setAddNewResource() {
    this.selectedResourceId = null
    this.editForm = new ResourceEditForm(this.rootStore)
  }

  @action
  public toggleDeleteDialog = () => {
    this.showDeleteDialog = !this.showDeleteDialog
  }

  public getResource(objectId): Resource {
    return this.resources.find(e => e.objectId === objectId)
  }
  public getResourceByName(name): Resource {
    return this.resources.find(e => e.name === name)
  }

  private getResourceIndex(objectId): number {
    return this.resources.findIndex(e => e.objectId === objectId)
  }

  public deleteResource(objectId) {
    this.resources.splice(this.getResourceIndex(objectId), 1)
  }

  public clearForms() {
    this.selectedResourceId = ''
    // this.editForm = null
  }

}
