import { action, observable, computed, reaction } from 'mobx'
import * as Sentry from '@sentry/browser'
import _ from 'lodash'
import Parse from 'parse'
import { RootStore } from '../../../stores/RootStore'
import { OrganizationUser } from '../../aggregate/OrganizationUser'
import { OrganizationUsersService } from '../../service/OrganizationUsersService'
import { AuthenticationService } from '../../../auth/services/AuthenticationService'
import { PasswordStrengthMeterVM } from '../../../shared/password-strength/PasswordStrengthMeterVM'

export class OrganizationUserPasswordEditVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.meterVM = new PasswordStrengthMeterVM(rootStore.localizationStore)

    reaction(
      () => this.newPassword,
      () => {
        this.meterVM.setPassword(this.newPassword)
      }
    )
  }

  public async loadUser(objectId: string) {
    this.isOpen = true
    this.isProcessing = true
    const organizationUser = await this.rootStore.organizationUsersStore.getFullRecord(objectId)
    this.loadData(organizationUser)
  }

  @action
  private loadData(organizationUser: OrganizationUser) {
    this.organizationUser = organizationUser.clone()
    this.objectId = organizationUser.objectId
    this.userId = organizationUser.userId
    this.email = organizationUser.User.publicEmail
    this.isProcessing = false
  }

  @observable public organizationUser: OrganizationUser = null
  @observable public email: string = ''
  @observable public objectId: string = ''
  @observable public password: string = ''
  @observable public newPassword: string = ''
  @observable public confirmNewPassword: string = ''
  @observable public userId: string = ''
  @observable public showPassword: boolean = false
  @observable public showNewPassword: boolean = false
  @observable public showConfirmNewPassword: boolean = false
  @observable public saveTried: boolean = false
  @observable public isProcessing: boolean = false
  @observable public virtualListParent: any = null
  @observable public isOpen: boolean = false
  @observable public existingPasswordsDontMatch: boolean = false
  @observable public meterVM: PasswordStrengthMeterVM = null

  @computed
  public get isUserOwned(): boolean {
    if (this.userId === this.rootStore.appStore.currentUserId) return true
    return false
  }

  @computed
  public get isEditable(): boolean {
    // if (this.isOrgAdmin) return true
    return false
  }

  @computed
  public get saveDisabled(): boolean {
    if (!this.password) return true
    return false
  }

  @action
  public setVirtualListParent(parentEl: string) {
    this.virtualListParent = document.getElementById(parentEl)
  }

  @action
  public markSaveTried() {
    this.saveTried = true
  }

  public get isUserInfoValid() {
    if (!this.passwordValid) return false
    return true
  }

  public get passwordValid() {
    if (!this.saveTried) return true
    if (!this.password || this.password.trim() === '') return false
    return true
  }

  public get newPasswordValid() {
    if (!this.saveTried) return true
    if (!this.newPassword || this.newPassword.trim() === '') return false
    if (!this.isNewPasswordStrong) return false
    return true
  }

  public get confirmNewPasswordValid() {
    if (!this.saveTried) return true
    if (!this.confirmNewPassword || this.confirmNewPassword.trim() === '') return false
    return true
  }

  public get newPasswordsMatch() {
    if (!this.saveTried) return true
    if (this.newPassword !== this.confirmNewPassword) return false
    return true
  }

  @computed
  public get isNewPasswordStrong(): boolean {
    if (!this.saveTried) return true
    if (!this.newPassword || this.newPassword.trim() === '') return true
    if (this.meterVM.newPasswordStrengthScore < 5) return false
    return true
  }

  @computed
  public get isValid() {
    if (!this.saveTried) return true
    if (!this.newPasswordValid) return false
    if (!this.confirmNewPasswordValid) return false
    if (this.editingSelf && !this.passwordValid) return false
    return true
  }

  @action
  public toggleShowPassword() {
    this.showPassword = !this.showPassword
  }

  @computed
  public get isPasswordMeterShown(): boolean {
    return this.meterVM.isPasswordMeterShown
  }

  @action
  public setPasswordMeterShown(isPasswordMeterShown: boolean) {
    if (this.newPassword) return
    this.meterVM.setPasswordMeterShown(isPasswordMeterShown)
  }

  @action
  public toggleShowNewPassword() {
    this.showNewPassword = !this.showNewPassword
  }

  @action
  public toggleShowConfirmNewPassword() {
    this.showConfirmNewPassword = !this.showConfirmNewPassword
  }

  @action
  public clearPasswords() {
    this.password = ''
    this.newPassword = ''
    this.confirmNewPassword = ''
  }

  public handleUserLogout() {
    this.rootStore.appStore.router.push('/auth/login')

    this.rootStore.appStore.clearData()
    window.location.reload()
  }

  @action
  public async saveNewPassword() {
    this.existingPasswordsDontMatch = false
    this.markSaveTried()
    if (!this.isValid) return
    if (!this.newPasswordsMatch) return
    if (!this.isNewPasswordStrong) return
    this.isProcessing = true
    try {
      const svc = new OrganizationUsersService(this.rootStore)
      const result = await svc.updateUserPassword(this.userId, this.password, this.newPassword)
      this.isProcessing = false
      if (!result.success) {
        if (result.errorMessage === 'Current Password is invalid.')
          this.existingPasswordsDontMatch = true
        throw result.errorMessage
      }
      if (result.success) {
        this.clearPasswords()
        this.hideDrawer()
        if (this.editingSelf) this.handleUserLogout()
      }
    } catch (error) {
      console.error(error)
      const orgId = this.rootStore.appStore.currentOrgId
      Sentry.captureException({
        message: 'Error updating users password',
        orgId,
        error,
      })
      return false
    }
  }

  @action
  public toggleDrawer(): void {
    this.isOpen = !this.isOpen
  }

  @action
  public hideDrawer() {
    this.isOpen = false
  }

  @computed
  public get editingSelf(): boolean {
    if (this.rootStore.appStore.currentUserId === this.userId) return true
    return false
  }
}
