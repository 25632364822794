import { computed, action } from 'mobx'
import { IMediaDTO } from '../dtos/IMediaDTO'
import { MediaItemVM } from './MediaItemVM'
import { MediaItemType } from '../../upload/types/MediaItemType'

export class OldMediaItemVM extends MediaItemVM {
  constructor(rootStore, incomingMediaItem?: IMediaDTO) {
    super(rootStore, incomingMediaItem)
  }

  @computed
  public get isLoaded(): boolean {
    return true
  }

  @computed
  public get isCMSItem(): boolean {
    return false
  }

  @computed
  public get type(): MediaItemType {
    if (!this.media?.type) return null
    return this.media.type
  }

  @computed
  public get url(): string {
    if (!this.media?.path) return ''
    return this.media.path
  }

  @action
  public setWatchPercentage(value) {
    this.mediaWatchPercentage = value
  }

  @computed
  public get displayName() {
    if (this.objectId === '') return ''
    return this.type + 's/' + this.name
  }

  @computed get uploadNameValid() {
    if (Boolean(this.uploadName)) return true
    else return false
  }

  @computed
  public get saveEnabled() {
    if (Boolean(this.uploadBase64) && this.uploadNameValid) return true
    else return false
  }

  public toDTO(): IMediaDTO {
    return {
      objectId: this.objectId,
      type: this.type,
      name: this.name,
      path: this.path,
      mediaWatchPercentage: this.mediaWatchPercentage,
      cmsItemId: this.cmsItemId,
    }
  }
}
