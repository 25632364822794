import { observable, computed, action, reaction, IReactionDisposer } from 'mobx'
import * as Sentry from '@sentry/browser'
import beautify from 'js-beautify'
import { RootStore } from '../../stores/RootStore'
import { QuestionType } from '../../user-surveys/types/QuestionType'
import { EmailTemplate } from '../aggregate/EmailTemplate'
import { EmailTemplatesService } from '../service/EmailTemplatesService'
import { IEmailTemplatesGetVersionRequest } from '../interfaces/IEmailTemplatesGetVersionRequest'
import { IEmailTemplatesGetVersionResponse } from '../interfaces/IEmailTemplatesGetVersionResponse'
import { IEmailTemplatesUpdateRequest } from '../interfaces/IEmailTemplatesUpdateRequest'
import { IEmailTemplatesUpdateResponse } from '../interfaces/IEmailTemplatesUpdateResponse'
import { OrganizationUser } from '../../organization-users/aggregate/OrganizationUser'
import { IEmailTemplatesTestEmailRequest } from '../interfaces/IEmailTemplatesTestEmailRequest'
import { IEmailTemplatesTestEmailResponse } from '../interfaces/IEmailTemplatesTestEmailResponse'
import { IEmailTemplatesCreateRequest } from '../interfaces/IEmailTemplatesCreateRequest'
import { IEmailTemplatesCreateResponse } from '../interfaces/IEmailTemplatesCreateResponse'
import { EmailTemplateEditorTestDataTabVM } from './EmailTemplateEditorTestDataTabVM'
import { EmailTemplatesEditorHTMLTabVM } from './EmailTemplateEditorHTMLTabVM'
import { IEmailTemplatesInitializeRequest } from '../interfaces/IEmailTemplatesInitializeRequest'
import { IEmailTemplatesInitializeResponse } from '../interfaces/IEmailTemplatesInitializeResponse'

export class EmailTemplatePreviewVM {
  private rootStore: RootStore
  private reactions: IReactionDisposer[] = []
  private svc: EmailTemplatesService

  constructor(rootStore) {
    this.rootStore = rootStore
    this.lz = this.rootStore.localizationStore.lzStrings.emailTemplateManagement
    this.svc = new EmailTemplatesService()
    this.htmlTab = new EmailTemplatesEditorHTMLTabVM(this.rootStore, this)
    this.testDataTab = new EmailTemplateEditorTestDataTabVM(this.rootStore, this)
  }

  @observable emailTemplate: EmailTemplate
  @observable cleanEmailTemplate: EmailTemplate
  @observable public isOpen: boolean = false
  @observable public isSaving: boolean = false
  @observable public isSending: boolean = false
  @observable public isLoading: boolean = false
  @observable public confirmDialogOpen: boolean = false
  @observable public doReset: boolean = false
  @observable public doClose: boolean = false
  @observable public isSnackbarOpen: boolean = false
  @observable public snackbarMessage: string = ''
  @observable public lz = undefined
  @observable public sysDefault: IEmailTemplatesGetVersionResponse = undefined
  @observable public tabIndex: number = 0
  @observable public previewTabIndex: number = 0
  @observable public htmlTab: EmailTemplatesEditorHTMLTabVM = undefined
  @observable public testDataTab: EmailTemplateEditorTestDataTabVM = undefined

  @observable type: QuestionType = null
  @observable saveTried: boolean = false
  @observable sendTried: boolean = false
  @observable isDirty: boolean = false
  @observable hover: number = 0
  @observable public isExpanded: boolean = true
  @observable public organizationUser: OrganizationUser = undefined
  @observable public email = ''
  @observable public username = ''

  @observable public previewHtml = ''
  @observable public previewName = ''
  @observable public previewSubject = ''
  @observable public previewFromEmail = ''
  @observable public previewToEmail = ''
  @observable public previewTestData = ''
  @observable public isSystemTemplate: boolean = false

  public async loadData(emailTemplate: EmailTemplate, isSystemTemplate: boolean = false, loadUser: boolean = true) {
    this.isLoading = true

    this.emailTemplate = emailTemplate.clone()
    this.cleanEmailTemplate = this.emailTemplate.clone()

    this.isSystemTemplate = isSystemTemplate
    this.sysDefault = await this.getSystemDefaultEmailTemplateVersion()

    this.reactions.forEach((dispose: IReactionDisposer) => dispose())

    if (loadUser && !this.organizationUser) await this.loadUser(this.rootStore.userStore.currentOrgUserId)

    this.setPreviewHtml(beautify.html(emailTemplate.html))
    this.setPreviewName(emailTemplate.name)
    this.setPreviewSubject(emailTemplate.subject)
    this.setPreviewTestData(beautify.js(emailTemplate.test_data))
    this.setPreviewToEmail('')

    this.reactions.push(
      reaction(
        () => JSON.stringify(this.previewHtml),
        () => {
          this.deepEqual(this.previewHtml, this.emailTemplate.html)
        }
      )
    )

    this.reactions.push(
      reaction(
        () => JSON.stringify(this.previewName),
        () => {
          this.deepEqual(this.previewName, this.emailTemplate.name)
        }
      )
    )

    this.reactions.push(
      reaction(
        () => JSON.stringify(this.previewSubject),
        () => {
          this.deepEqual(this.previewSubject, this.emailTemplate.subject)
        }
      )
    )

    this.reactions.push(
      reaction(
        () => JSON.stringify(this.previewTestData),
        () => {
          this.deepEqual(this.previewTestData, this.emailTemplate.test_data)
        }
      )
    )

    if(!loadUser) this.isLoading = false
  }

  @computed
  public get isLoaded(): boolean {
    if (!this.rootStore.organizationUsersStore.listRecordsLoaded) return false
    return true
  }

  @computed
  public get canManage() {
    if (this.rootStore.appStore.isSystemAdmin) return true
    if (this.rootStore.appStore.isOrgAdmin) return true
    if (!this.rootStore.userStore.currentOrganization) return false
    return false
  }

  @computed
  public get isShown(): boolean {
    return true
  }

  @computed
  public get objectId(): string {
    return this.emailTemplate.objectId
  }

  @computed
  public get title(): string {
    return this.emailTemplate.notificationTypeId
  }

  @computed
  public get isDefault(): boolean {
    return this.emailTemplate.isDefault
  }

  @computed
  public get isValid() {
    if (!this.saveTried) return true
    return true
  }

  @computed
  public get html(): string {
    return this.previewHtml
  }

  @computed
  public get name(): string {
    return this.previewName
  }

  @computed
  public get subject(): string {
    return this.previewSubject
  }

  @computed
  public get testData(): string {
    return this.previewTestData
  }

  @computed
  public get emailTemplateHtmlValid() {
    if (!this.saveTried) return true
    if (!this.previewHtml) return false
    if (this.previewHtml.trim() === '') return false
    if (!this.previewTestData) return false
    if (this.previewTestData.trim() === '') return false
    return true
  }

  @computed
  public get emailTemplateNameValid() {
    if (!this.saveTried) return true
    if (!this.previewName) return false
    if (this.previewName.trim() === '') return false
    return true
  }

  @computed
  public get emailTemplateSubjectValid() {
    if (!this.saveTried) return true
    if (!this.previewSubject) return false
    if (this.previewSubject.trim() === '') return false
    return true
  }

  @computed
  public get toEmailValid() {
    if (!this.sendTried) return true
    let pattern = new RegExp(/[^\s@]+@[^\s@]+\.[^\s@]+/i)
    return pattern.test(this.previewToEmail)
  }

  @computed
  public get fromEmailValid() {
    if (!this.sendTried) return true
    let pattern = new RegExp(/[^\s@]+@[^\s@]+\.[^\s@]+/i)
    return pattern.test(this.previewFromEmail)
  }

  @computed
  public get isSaveValid() {
    if (!this.saveTried) return true
    if (!this.emailTemplateNameValid) return false
    if (!this.emailTemplateSubjectValid) return false
    if (!this.emailTemplateHtmlValid) return false
    return true
  }

  @computed
  public get isSendValid() {
    if (!this.sendTried) return true
    if (!this.toEmailValid) return false
    if (!this.fromEmailValid) return false
    if (!this.emailTemplateSubjectValid) return false
    if (!this.emailTemplateHtmlValid) return false
    return true
  }

  @action
  private async loadUser(objectId: string, attempts: number = 0) {
    if (this.organizationUser) return
    if (attempts === 50) {
      alert('Failed to load user data. Please try reloading the page.')
      this.isLoading = false
      return
    }

    if (!objectId)
      return setTimeout(
        () => this.loadUser(this.rootStore.userStore.currentOrgUserId, ++attempts),
        1000
      )

    const orgUser = await this.rootStore.organizationUsersStore.getFullRecord(objectId)
    if (!orgUser) return setTimeout(() => this.loadUser(objectId, ++attempts), 1000)
    this.organizationUser = orgUser.clone()
    this.email = this.organizationUser.User.publicEmail
    this.username = this.organizationUser.User.username
      ? this.organizationUser.User.username.toLowerCase()
      : this.email
    this.previewFromEmail = this.email
    this.isLoading = false
    this.isOpen = true
  }

  @action
  public async getEmailTemplateVersion(sgTemplateId: string, sgTemplateVersionId: string) {
    const req: IEmailTemplatesGetVersionRequest = {
      template_id: sgTemplateId,
      version_id: sgTemplateVersionId,
    }
    const result = await this.svc.getEmailTemplateVersion(req, this.rootStore.appStore.currentOrgId)
    return result
  }

  @action
  public async getSystemDefaultEmailTemplateVersion() {
    const req: IEmailTemplatesGetVersionRequest = {
      template_id: '',
      version_id: '',
    }
    const result = await this.svc.getSystemDefaultEmailTemplateVersion(
      req,
      this.rootStore.appStore.currentOrgId
    )
    return result
  }

  @action
  public async sendTestEmail() {
    this.markSendTried()

    if (!this.isSendValid) return false
    this.isSending = true
    await this.saveTestEmailVersion()

    if (!this.emailTemplate || !this.emailTemplate.test_data) {
      this.isSending = false
      const e = new Error(
        `Email data is invalid (survey link, body, or subject). Please try again.`
      )
      console.error(e)
      this.openSnackbar(`${this.lz.error} ${this.lz.unable_to_send}`)
      Sentry.captureException({
        message: this.lz.unable_to_send,
        exception: e,
        userId: this.rootStore.appStore.currentUserId,
        orgId: this.rootStore.appStore.currentOrgId,
      })
      return
    }
    const testData = JSON.parse(this.emailTemplate.test_data)
    const svc = new EmailTemplatesService()
    const req = {
      from_address: this.previewFromEmail,
      emails: [this.previewToEmail],
      template_id: this.emailTemplate.sgTemplateId,
      version_id_override: this.emailTemplate.sgTemplateTestVersionId,
      substitutions: testData,
    } as IEmailTemplatesTestEmailRequest
    let response: IEmailTemplatesTestEmailResponse
    try {
      response = await svc.sendTestEmail(req, this.rootStore.appStore.currentOrgId)
      if (response && response.success) {
        this.openSnackbar(`${this.lz.success} ${this.lz.test_email} ${this.lz.sent}`)
        this.isSending = false
      } else {
        const msg = response.errorMessage
        console.debug(msg)
        this.openSnackbar(`${this.lz.error} ${this.lz.unable_to_send}`)
        this.isSending = false
        this.isDirty = true
        return
      }
    } catch (e) {
      this.isSending = false
      console.error(e)
      this.openSnackbar(`${this.lz.error} ${this.lz.unable_to_send}`)
      Sentry.captureException({
        message: this.lz.unable_to_send,
        exception: e,
        userId: this.rootStore.appStore.currentUserId,
        orgId: this.rootStore.appStore.currentOrgId,
      })
      return
    }
  }

  @action
  public setOpen(isOpen: boolean) {
    this.isOpen = isOpen
  }

  @action
  public setIsDirty() {
    this.isDirty = true
  }

  @action
  public setHover(newHover: number) {
    this.hover = newHover
  }

  @action
  public toggleIsExpanded(isExpanded: boolean) {
    this.isExpanded = isExpanded
  }

  @action
  public setEmailTemplate(emailTemplate: EmailTemplate) {
    this.emailTemplate = emailTemplate
    this.cleanEmailTemplate = emailTemplate ? this.emailTemplate.clone() : undefined
  }

  @action
  public setPreviewHtml(previewHtml: string) {
    this.previewHtml = previewHtml
  }

  @action
  public setPreviewSubject(previewSubject: string) {
    this.previewSubject = previewSubject
  }

  @action
  public setPreviewName(previewName: string) {
    this.previewName = previewName
  }

  @action
  public setPreviewFromEmail(previewFromEmail: string) {
    this.previewFromEmail = previewFromEmail
  }

  @action
  public setPreviewToEmail(previewToEmail: string) {
    this.previewToEmail = previewToEmail
  }

  @action
  public setPreviewTestData(previewTestData: string) {
    try {
      //validate json before setting it as the preview
      JSON.parse(previewTestData)
      this.previewTestData = previewTestData
    } catch (e) {
      console.debug(e)
      this.openSnackbar(`${this.lz.error}: Fix the JSON test data to see a valid preview.`)
    }
  }

  @action
  public close() {
    this.confirmDialogOpen = false

    this.setOpen(false)
    this.setTabIndex(0)
    this.setEmailTemplate(undefined)
    this.organizationUser = undefined
    this.doClose = false
  }

  @action
  public toggleConfirmDialog() {
    this.confirmDialogOpen = !this.confirmDialogOpen
  }

  @action
  public cancelConfirmDialog() {
    this.confirmDialogOpen = false
    this.doClose = false
    this.doReset = false
  }

  @action
  public resetTemplateEditor() {
    this.confirmDialogOpen = false
    this.isDirty = false

    this.emailTemplate = this.cleanEmailTemplate.clone()
    this.cleanEmailTemplate = this.emailTemplate.clone()

    this.setPreviewHtml(beautify.html(this.emailTemplate.html))
    this.setPreviewName(this.emailTemplate.name)
    this.setPreviewSubject(this.emailTemplate.subject)
    this.setPreviewTestData(beautify.js(this.emailTemplate.test_data))
    this.previewFromEmail = this.email
    this.previewToEmail = ''

    this.doReset = false

    this.saveTried = false
    this.sendTried = false
  }

  @action
  public openSnackbar(msg) {
    this.snackbarMessage = msg
    this.isSnackbarOpen = true
  }

  @action
  public closeSnackbar() {
    this.isSnackbarOpen = false
  }

  @action
  public markSaveTried() {
    this.saveTried = true
  }

  @action
  public markSendTried() {
    this.sendTried = true
  }

  @action
  public async saveTestEmailVersion() {
    this.markSaveTried()

    if (!this.isSaveValid) return false

    if (this.emailTemplate.sgTemplateTestVersionId === '') {
      await this.createCustomVersionForTestEmail()
    }

    const svc = new EmailTemplatesService()
    const req = {
      objectId: this.objectId,
      template_id: this.emailTemplate.sgTemplateId,
      version_id: this.emailTemplate.sgTemplateTestVersionId,
      name: `Test - ${this.emailTemplate.name}`,
      html_content: this.previewHtml,
      generate_plain_content: true,
      subject: this.previewSubject,
      test_data: this.previewTestData,
    } as IEmailTemplatesUpdateRequest
    let response: IEmailTemplatesUpdateResponse
    try {
      response = await svc.updateEmailTemplateTestVersion(req, this.rootStore.appStore.currentOrgId)
      if (response && response.success) {
        this.rootStore.emailTemplatesStore.getEmailTemplate(this.objectId)
        return response
      } else {
        const msg = response.errorMessage
        console.debug(msg)
        return response
      }
    } catch (e) {
      console.error(e)
      Sentry.captureException({
        message: this.lz.unable_to_save,
        exception: e,
        userId: this.rootStore.appStore.currentUserId,
        orgId: this.rootStore.appStore.currentOrgId,
      })
      return {
        success: false,
        errorMessage: this.lz.unable_to_save,
      } as IEmailTemplatesUpdateResponse
    }
  }

  @action
  public async initializeCustomVersion() {
    const req: IEmailTemplatesInitializeRequest = {
      name: this.previewName,
      generation: 'dynamic',
      notificationTypeId: this.emailTemplate.notificationTypeId,
    }
    const svc = new EmailTemplatesService()
    let response: IEmailTemplatesInitializeResponse
    try {
      response = await svc.initializeEmailTemplate(req, this.rootStore.appStore.currentOrgId)
      if (response && !response.success) console.debug(response.errorMessage)
    } catch (e) {
      console.error(e)
      Sentry.captureException({
        message: this.lz.unable_to_initialize,
        exception: e,
        userId: this.rootStore.appStore.currentUserId,
        orgId: this.rootStore.appStore.currentOrgId,
      })
    }
    return response
  }

  @action
  public async createCustomVersion() {
    const svc = new EmailTemplatesService()

    const initTemplate = await this.initializeCustomVersion()
    const req = {
      objectId: initTemplate.emailTemplate.objectId,
      template_id: initTemplate.id,
      name: this.previewName,
      html_content: this.previewHtml,
      generate_plain_content: true,
      subject: this.previewSubject,
      test_data: this.previewTestData,
      notificationTypeId: this.emailTemplate.notificationTypeId,
      editor: 'code',
      active: 1,
    } as IEmailTemplatesCreateRequest
    let response: IEmailTemplatesCreateResponse
    try {
      response = await svc.createEmailTemplateVersion(req, this.rootStore.appStore.currentOrgId)
      if (response && response.success) {
        this.openSnackbar(`${this.lz.success} ${this.lz.email_template} Created`)
        const newTemp = await this.rootStore.emailTemplatesStore.getEmailTemplate(
          response.emailTemplate.objectId
        )
        this.cleanEmailTemplate.isDefault = false
        if (newTemp) {
          this.emailTemplate = newTemp.clone()
          this.cleanEmailTemplate = this.emailTemplate.clone()
        } else {
          console.debug(response)
        }
        this.isSaving = false
      } else {
        const msg = response.errorMessage
        console.debug(msg)
        this.openSnackbar(`${this.lz.error} ${this.lz.unable_to_save}`)
        this.isSaving = false
        this.isDirty = true
        return
      }
    } catch (e) {
      this.isSaving = false
      console.error(e)
      this.openSnackbar(`${this.lz.error} ${this.lz.unable_to_save}`)
      Sentry.captureException({
        message: this.lz.unable_to_save,
        exception: e,
        userId: this.rootStore.appStore.currentUserId,
        orgId: this.rootStore.appStore.currentOrgId,
      })
      return
    }
  }


  @action
  public async createCustomVersionForTestEmail() {
    const svc = new EmailTemplatesService()

    const initTemplate = await this.initializeCustomVersion()
    const req = {
      objectId: initTemplate.emailTemplate.objectId,
      template_id: initTemplate.id,
      name: this.previewName,
      html_content: this.sysDefault.html_content,
      generate_plain_content: true,
      subject: this.previewSubject,
      test_data: this.previewTestData,
      notificationTypeId: this.emailTemplate.notificationTypeId,
      editor: 'code',
      active: 1,
    } as IEmailTemplatesCreateRequest
    let response: IEmailTemplatesCreateResponse
    try {
      response = await svc.createEmailTemplateVersion(req, this.rootStore.appStore.currentOrgId)
      if (response && response.success) {
        const newTemp = await this.rootStore.emailTemplatesStore.getEmailTemplate(
          response.emailTemplate.objectId
        )
        if (newTemp) {
          this.emailTemplate = newTemp.clone()
          this.cleanEmailTemplate = this.emailTemplate.clone()
        } else {
          console.debug(response)
        }
      } else {
        const msg = response.errorMessage
        console.debug(msg)
        return
      }
    } catch (e) {
      console.error(e)
      Sentry.captureException({
        message: this.lz.unable_to_save,
        exception: e,
        userId: this.rootStore.appStore.currentUserId,
        orgId: this.rootStore.appStore.currentOrgId,
      })
      return
    }
  }

  @action
  public async updateCustomVersion() {
    const svc = new EmailTemplatesService()

    const req = {
      objectId: this.objectId,
      template_id: this.emailTemplate.sgTemplateId,
      version_id: this.emailTemplate.sgTemplateVersionId,
      name: this.previewName,
      html_content: this.previewHtml,
      generate_plain_content: true,
      subject: this.previewSubject,
      test_data: this.previewTestData,
      notificationTypeId: this.emailTemplate.notificationTypeId,
    } as IEmailTemplatesUpdateRequest
    let response: IEmailTemplatesUpdateResponse
    try {
      response = await svc.updateEmailTemplate(req, this.rootStore.appStore.currentOrgId)
      if (response && response.success) {
        this.openSnackbar(`${this.lz.success} ${this.lz.email_template} ${this.lz.updated}`)
        const newTemp = await this.rootStore.emailTemplatesStore.getEmailTemplate(this.objectId)
        if (newTemp) {
          this.emailTemplate = newTemp.clone()
          this.cleanEmailTemplate = this.emailTemplate.clone()
        } else {
          console.debug(response)
        }
        this.isSaving = false
      } else {
        const msg = response.errorMessage
        console.debug(msg)
        this.openSnackbar(`${this.lz.error} ${this.lz.unable_to_save}`)
        this.isSaving = false
        this.isDirty = true
        return
      }
    } catch (e) {
      this.isSaving = false
      console.error(e)
      this.openSnackbar(`${this.lz.error} ${this.lz.unable_to_save}`)
      Sentry.captureException({
        message: this.lz.unable_to_save,
        exception: e,
        userId: this.rootStore.appStore.currentUserId,
        orgId: this.rootStore.appStore.currentOrgId,
      })
      return
    }
  }

  @action
  public replaceMarkupForPreview(body: string) {
    if(!body) return ''

    return body
    .replaceAll('<!DOCTYPE html>', '')
    .replaceAll('<html', '<div')
    .replaceAll('</html', '</div')
    .replaceAll('<body', '<div')
    .replaceAll('</body', '</div')
    .replaceAll('<head', '<div')
    .replaceAll('</head', '</div')
    .replaceAll(/\{\w*Link\}/g, 'javascript:void(0)')
  }

  @computed
  public get replaceKeywordsForPreview() {
    if (!this.emailTemplate) return ''

    let replaced = this.previewHtml ? this.previewHtml : ''
    try {
      const testData = this.previewTestData ? JSON.parse(this.previewTestData) : undefined
      if (!testData) return replaced
      Object.keys(testData).forEach((key) => {
        replaced = replaced.replaceAll(`{{{${key}}}}`, testData[key])
      })
    } catch (e) {
      console.debug(e)
      this.openSnackbar(`${this.lz.error}: Fix the JSON test data to see a valid preview.`)
    }

    replaced = replaced.replaceAll(`target="_blank"`, '')
    replaced = replaced.replaceAll(`javascript:void(0);`, '#')

    return replaced
  }

  @action
  public setTabIndex(val: number) {
    this.tabIndex = val
  }

  @action
  public setPreviewTabIndex(val: number) {
    this.previewTabIndex = val
  }

  @action
  public async save() {
    this.markSaveTried()

    this.confirmDialogOpen = false
    if (!this.isSaveValid) return false
    this.isSaving = true
    this.isDirty = false

    if (this.isSystemTemplate && this.emailTemplate.sgTemplateVersionId !== ''){
      this.updateCustomVersion()
      return
    }

    if (
      this.isDefault && this.emailTemplate.organizationId == 'DEFAULT'
    ) {
      this.createCustomVersion()
    } else {
      if (this.emailTemplate.sgTemplateVersionId === '') this.createCustomVersion()
      else this.updateCustomVersion()
    }
  }

  @action
  public deepEqual(object1, object2) {
    if (this.isSaving) return
    const keys1 = Object.keys(object1)
    const keys2 = Object.keys(object2)
    if (keys1.length !== keys2.length) {
      this.isDirty = true
    }
    for (const key of keys1) {
      const val1 = object1[key]
      const val2 = object2[key]
      // added to avoid false flag on empty mobx arrays
      if (JSON.stringify(val1) === '[]' && JSON.stringify(val2) === '[]') return
      if (val1 !== val2) {
        this.isDirty = true
      }
      const areObjects = this.isObject(val1) && this.isObject(val2)
      if (areObjects && !this.isDirty) this.deepEqual(val1, val2)
    }
    if (!this.isObject(object1) && !this.isObject(object2)) {
      if (object1 !== object2) {
        this.isDirty = true
      }
    }
  }

  @action
  public disposeReactions() {
    if (!this.reactions) return
    this.reactions.forEach((dispose: IReactionDisposer) => dispose())
  }

  @action
  public isObject(object) {
    return object != null && typeof object === 'object'
  }

  public toDTO() {
    return {
      objectId: this.emailTemplate.objectId,
      body: this.emailTemplate.body,
      channel: this.emailTemplate.channel,
      isDefault: this.emailTemplate.isDefault,
      language: this.emailTemplate.language,
      notificationTypeId: this.emailTemplate.notificationTypeId,
      organizationId: this.emailTemplate.organizationId,
      subject: this.emailTemplate.subject,
      isDeleted: this.emailTemplate.isDeleted,
      id: this.emailTemplate.id,
      name: this.emailTemplate.name,
      generation: this.emailTemplate.generation,
      sgTemplateId: this.emailTemplate.sgTemplateId,
      sgTemplateVersionId: this.emailTemplate.sgTemplateVersionId,
      sgTemplateTestVersionId: this.emailTemplate.sgTemplateTestVersionId,
      thumbnailUrl: this.emailTemplate.thumbnailUrl,
      html: this.emailTemplate.html,
      parentId: this.emailTemplate.parentId,
      test_data: this.emailTemplate.test_data,
    }
  }
}
