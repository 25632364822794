import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import { inject, observer } from 'mobx-react'
import { UserStore } from '../../store/UserStore'

interface Props {
  userStore?: UserStore
}

const LogoutButton: React.FC<Props> = ({ userStore }) => {
  const { avatarMenuVM: vm } = userStore
  if (!vm) return null
  return <MenuItem className='avatar-menu-item' onClick={() => vm.handleLogout()}>Logout</MenuItem>
}

export default inject('userStore')(observer(LogoutButton))
