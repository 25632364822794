import React from 'react'
import { inject, observer } from 'mobx-react'
import { Typography, Grid, LinearProgress, Button, Tooltip } from '@material-ui/core'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import LockRoundedIcon from '@material-ui/icons/LockRounded'
import LockOpenRoundedIcon from '@material-ui/icons/LockOpenRounded'
import WavesRoundedIcon from '@material-ui/icons/WavesRounded'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { UserTrainingPlansStore } from '../../store/UserTrainingPlansStore'
import { MyTrainingsTimelinePlanRowVM } from '../../view-models/widgets/MyTrainingsTimelinePlanRowVM'
import { MyTrainingsTimelineItemRowVM } from '../../view-models/widgets/MyTrainingsTimelineItemRowVM'
import './Timeline.scss'

interface Props {
  row: MyTrainingsTimelinePlanRowVM
  index: number
  userTrainingPlansStore?: UserTrainingPlansStore
  localizationStore?: LocalizationStore
}

const TimelineRow: React.FC<Props> = ({
  row,
  index,
  userTrainingPlansStore,
  localizationStore,
}) => {
  const [isExpanded, setExpanded] = React.useState(false)
  const [isOuterTooltipOpen, setOuterTooltipOpen] = React.useState(false)

  const { training_plans: strings } = localizationStore.lzStrings

  const renderTimelinePlanItems = (row: MyTrainingsTimelinePlanRowVM, index: number) => {
    if (!isExpanded) return
    return (
      <Grid container direction='column' className='item-container'>
        {row.itemRows.map((item, index) => renderPlanItem(item, index))}
        {renderPlanQuizItem(row, index)}
        {renderPlanCertificateItem(row, index)}
      </Grid>
    )
  }

  const renderPlanCertificateItem = (row, index) => {
    if (!row.requireCertificate) return
    return (
      <Grid container key={`training-cert-${index}`}>
        <Grid
          item
          xs={1}
          container
          spacing={0}
          direction='column'
          justifyContent='center'
          alignItems='center'
          id='ItemDateContainer'
          className='dateContainer'
        >
          <span className={row.isPastDue ? 'due-month' : 'month'}>{row.dueMonth}</span>
          <span className={row.isPastDue ? 'due-day' : 'day'}>{row.dueDay}</span>
          <span className={row.isPastDue ? 'circle circle-due' : 'circle circle-active'}></span>
        </Grid>
        <Grid item xs={1} className='item-line'>
          <hr></hr>
        </Grid>
        <Grid item xs={10} className='timeline-item-card'>
          <Grid container direction='row' justifyContent='space-between' alignItems='center'>
            <Grid item xs={4}>
              <Typography className='time-plan-title'>{strings.assesment}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className='time-plan-type'>{strings.cert}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className={row.certificateUploaded ? 'time-plan-uploated' : 'time-plan-notup'}
              >
                {row.certificateUploaded ? 'Uploaded' : 'Not Uploaded'}
              </Typography>
            </Grid>
            <Grid item xs={2} className='button-container'>
              <Grid container justifyContent='flex-end'>
                <Button
                  disabled={!row.certUploadAllowed}
                  // disabled={true}
                  variant='contained'
                  onClick={() => row.openUserTrainingPlanCertificate()}
                >
                  {strings.upload}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderPlanQuizItem = (row, index) => {
    if (!row.requireQuiz) return
    return (
      <Grid container key={`training-quiz-${index}`}>
        <Grid
          item
          xs={1}
          container
          spacing={0}
          direction='column'
          justifyContent='center'
          alignItems='center'
          id='ItemDateContainer'
          className='dateContainer'
        >
          <span className={row.isPastDue ? 'due-month' : 'month'}>{row.dueMonth}</span>
          <span className={row.isPastDue ? 'due-day' : 'day'}>{row.dueDay}</span>
          <span className={row.isPastDue ? 'circle circle-due' : 'circle circle-active'}></span>
        </Grid>
        <Grid item xs={1} className='item-line'>
          <hr></hr>
        </Grid>
        <Grid item xs={10} className='timeline-item-card'>
          <Grid container direction='row' justifyContent='space-between' alignItems='center'>
            <Grid item xs={4}>
              <Typography className='time-plan-title'>{strings.assesment}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className='time-plan-type'>{strings.quiz}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className='time-plan-questions'>{row.quizQuestionCount}</Typography>
            </Grid>
            <Grid item xs={2} className='button-container'>
              <Grid container justifyContent='flex-end'>
                <Button
                  disabled={!row.quizCompletionAllowed}
                  // disabled={true}
                  variant='contained'
                  onClick={() => row.openUserTrainingPlanQuiz()}
                >
                  {strings.open}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderPlanItem = (row: MyTrainingsTimelineItemRowVM, index: number) => {
    return (
      <Grid container key={`training-item-${index}`}>
        <Grid
          item
          xs={1}
          container
          spacing={0}
          direction='column'
          justifyContent='center'
          alignItems='center'
          id='ItemDateContainer'
          className='dateContainer'
        >
          <span className={row.isPastDue ? 'due-month' : 'month'}>{row.dueMonth}</span>
          <span className={row.isPastDue ? 'due-day' : 'day'}>{row.dueDay}</span>
          <span className={row.isPastDue ? 'circle circle-due' : 'circle circle-active'}></span>
        </Grid>
        <Grid item xs={1} className='item-line'>
          <hr></hr>
        </Grid>
        <Grid item xs={10} className='timeline-item-card'>
          <Grid container direction='row' justifyContent='space-between' alignItems='center'>
            <Grid item xs={4}>
              <Typography className='time-plan-title'>{row.name}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className='time-plan-type'>{row.contentItemTypes}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={row.isPastDue ? 'time-plan-overdue' : 'time-plan-due'}>
                {row.timeUntilDue}
              </Typography>
            </Grid>
            <Grid item xs={2} className='button-container'>
              <Grid container justifyContent='flex-end'>
                <Button
                  disabled={!row.completionAllowed}
                  variant='contained'
                  onClick={() => row.openUserTrainingItem()}
                >
                  {strings.open}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderTimelinePlanDate = (row: MyTrainingsTimelinePlanRowVM) => {
    if (!row.isActive && !row.isPastDue) return
    return (
      <Tooltip
        title={row.isReadonly ? strings.training_plan_locked_info : strings.expand_training_plan}
        placement='right'
        enterDelay={250}
      >
        <Grid
          item
          xs={1}
          container
          spacing={0}
          direction='column'
          justifyContent='center'
          alignItems='center'
          id='PlanDateContainer'
          className='dateContainer'
          onClick={() => {
            if (row.isActive || row.isPastDue) setExpanded(!isExpanded)
          }}
        >
          <span className={row.isPastDue ? 'month past-due' : 'month date-color'}>
            {row.dueMonth}
          </span>
          <span className={row.isPastDue ? 'day past-due' : 'day date-color'}>{row.dueDay}</span>
          <span>
            <KeyboardArrowRightIcon className={row.arrowClasses} />
          </span>
        </Grid>
      </Tooltip>
    )
  }

  const renderUpcomingPlanDate = (row: MyTrainingsTimelinePlanRowVM) => {
    if (row.isActive || row.isPastDue) return
    return (
      <Grid
        item
        xs={1}
        container
        spacing={0}
        direction='column'
        justifyContent='center'
        alignItems='center'
        id='PlanDateContainer'
        className='dateContainer'
      >
        <span className={row.isPastDue ? 'month past-due' : 'month date-color'}>
          {row.dueMonth}
        </span>
        <span className={row.isPastDue ? 'day past-due' : 'day date-color'}>{row.dueDay}</span>
        <span>
          <KeyboardArrowRightIcon className={row.arrowClasses} />
        </span>
      </Grid>
    )
  }

  const handleOuterTooltipClose = () => {
    setOuterTooltipOpen(false)
  }

  const handleOuterTooltipOpen = () => {
    setOuterTooltipOpen(true)
  }

  const renderReadonlyIcon = (row: MyTrainingsTimelinePlanRowVM) => {
    if (row.isReadonly) return <LockRoundedIcon className={row.lockClasses} />
    else return <LockOpenRoundedIcon className={row.lockClasses} />
  }

  const renderReadonlyIndicator = () => {
    return (
      <Tooltip
        title={
          row.isReadonly ? strings.training_plan_locked_info : strings.training_plan_unlocked_info
        }
        placement='right'
        enterDelay={250}
      >
        <div
          onMouseOver={(e) => {
            e.stopPropagation()
            e.preventDefault()
            handleOuterTooltipClose()
          }}
        >
          {renderReadonlyIcon(row)}
        </div>
      </Tooltip>
    )
  }

  const renderFlowIndicator = () => {
    if (!row.isForTrainingFlow) return null
    return (
      <Tooltip title={strings.training_plan_automated_info} placement='right' enterDelay={250}>
        <div
          onMouseOver={(e) => {
            e.stopPropagation()
            e.preventDefault()
            handleOuterTooltipClose()
          }}
        >
          <WavesRoundedIcon className={row.lockClasses} />
        </div>
      </Tooltip>
    )
  }

  const renderTimelinePlanCard = (row: MyTrainingsTimelinePlanRowVM) => {
    if (!row.isActive && !row.isPastDue) return
    return (
      <Tooltip
        title={row.isReadonly ? strings.training_plan_locked_info : strings.open_training_plan}
        placement='right'
        enterDelay={250}
        open={isOuterTooltipOpen}
        onOpen={handleOuterTooltipOpen}
        onClose={handleOuterTooltipClose}
      >
        <Grid
          item
          xs={12}
          onClick={() => {
            row.openUserTrainingPlan()
          }}
          className='cursor'
        >
          <Grid item xs={4}>
            <Grid container direction='column' className='time-plan-text'>
              <div className='readonly-status'>
                <Typography className='time-plan-title'>{row.title}</Typography>
                {renderReadonlyIndicator()}
                {renderFlowIndicator()}
              </div>
              <Typography className='time-plan-subtitle'>{row.subTitle}</Typography>
            </Grid>
          </Grid>

          <Grid item xs={8}>
            <Grid
              container
              direction='column'
              justifyContent='space-between'
              className='time-plan-due-progress'
            >
              <Grid container justifyContent='flex-end' className='due'>
                <Typography className={row.dueClass}>{row.dueText}</Typography>
              </Grid>
              <Grid container direction='row'>
                <LinearProgress
                  variant='determinate'
                  value={row.progress}
                  className={row.userOwned ? 'owned' : 'not-owned'}
                />
                <Typography className='progress-label2'>{row.progress}%</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Tooltip>
    )
  }

  const renderUpcomingPlanCard = (row: MyTrainingsTimelinePlanRowVM) => {
    if (row.isActive || row.isPastDue) return
    return (
      <Grid item xs={12} className='cursor'>
        <Grid item xs={4}>
          <Grid container direction='column' className='time-plan-text'>
            <Typography className='time-plan-title'>{row.title}</Typography>

            <Typography className='time-plan-subtitle'>{row.subTitle}</Typography>
          </Grid>
        </Grid>

        <Grid item xs={8}>
          <Grid
            container
            direction='column'
            justifyContent='space-between'
            className='time-plan-due-progress'
          >
            <Grid container justifyContent='flex-end' className='due'>
              <Typography className={row.dueClass}>{row.dueText}</Typography>
            </Grid>
            <Grid container direction='row'>
              <LinearProgress
                variant='determinate'
                value={row.progress}
                className={row.userOwned ? 'owned' : 'not-owned'}
              />
              <Typography className='progress-label2' variant='h6'>
                {row.progress}%
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container key={index}>
      {renderTimelinePlanDate(row)}
      {renderUpcomingPlanDate(row)}
      <Grid item xs={11} className='paddedContainer'>
        <Grid container direction='column'>
          <Grid container direction='row'>
            <Grid container key={index} direction='row' id='TimelineRow'>
              {renderTimelinePlanCard(row)}
              {renderUpcomingPlanCard(row)}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {renderTimelinePlanItems(row, index)}

      <Grid item xs={1}>
        <Grid container direction='column' className='marginContainer'></Grid>
      </Grid>
      <Grid item xs={11}>
        <Grid container direction='column' className='margin'></Grid>
      </Grid>
    </Grid>
  )
}

export default inject('userTrainingPlansStore', 'localizationStore')(observer(TimelineRow))
