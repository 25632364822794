import { action, observable, computed } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import moment from 'moment'
import { ParseService } from '../../../services/ParseService'
import CalendarSetting from '../aggregate/CalendarSetting'

export default class CalendarSettingsEditForm {
  rootStore: RootStore

  constructor(rootStore: RootStore, calendarSetting: CalendarSetting = null) {
    this.rootStore = rootStore
    if (calendarSetting) this.loadData(calendarSetting)
  }

  private loadData(calendarSetting: CalendarSetting) {
    this.objectId = calendarSetting.objectId
    this.originalStart = calendarSetting.eventStart
    this.originalEnd = calendarSetting.eventEnd
    this.eventStart = calendarSetting.eventStart
    this.eventEnd = calendarSetting.eventEnd
  }

  @observable public objectId: string
  @observable public originalStart: Date
  @observable public originalEnd: Date
  @observable public eventStart: Date = new Date()
  @observable public eventEnd: Date = new Date()
  @observable public editScreen: boolean = false
  @observable public saveTried: boolean = false
  @observable public tabIndex: number = 0

  @action
  public openEditScreen() {
    this.editScreen = true
  }

  @action
  public closeEditScreen() {
    this.editScreen = false
    if (this.objectId) {
      this.eventStart = this.originalStart
      this.eventEnd = this.originalEnd
    } else {
      this.eventStart = new Date()
      this.eventEnd = new Date()
    }
    this.saveTried = false
  }

  @action
  public markSaveTried() {
    this.saveTried = true
  }

  @computed
  public get isValid() {
    if (!this.saveTried) return true
    if (!this.timesValid) return false
    return true
  }

  @computed
  public get timesValid() {
    if (!this.saveTried) return true
    if (moment(this.eventStart).isAfter(this.eventEnd)) return false
    return true
  }

  @action
  public async save() {
    this.markSaveTried()
    const s = this.rootStore.localizationStore.lzStrings.calendar
    if (!this.isValid) return false
    try {
      this.editScreen = false
      const newTimes = {
        objectId: this.objectId ? this.objectId : null,
        eventStart: moment(this.eventStart).format(s.event_time_format),
        eventEnd: moment(this.eventEnd).format(s.event_time_format),
      }

      await new ParseService().saveCalendarTimes(this.rootStore.appStore.currentOrgId, newTimes)

      this.rootStore.calendarSettingsStore.loadCalendarSettings()
    } catch (e) {
      console.error(e)
    }
  }

  @action
  public setTabIndex(value: number) {
    this.tabIndex = value
  }
}
