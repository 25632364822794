import React from 'react'
import { IndexRoute, Route } from 'react-router'
import Layout from '../../layout/Layout'
import { authenticate } from '../../auth/routes/AuthenticatedRoute'
import TrainingPlanEditScreen from '../../training-plans/views/edit/TrainingPlanEditScreen'

export default function getTrainingPlansRoutes() {
  return (
    <Route key={'impact0'} component={authenticate(Layout)} path='trainingplans'>
      <IndexRoute onEnter={(nextState, replace) => replace('/dashboard/impact')} />
      <Route key={'impact2'} path='edit/:id' component={TrainingPlanEditScreen} />
      <Route
        key={'impact3'}
        path='from-catalog/edit/:catalogId'
        component={TrainingPlanEditScreen}
      />
      <Route
        key={'impact3'}
        path='from-catalog/assign/:assignCatalogId'
        component={TrainingPlanEditScreen}
      />
    </Route>
  )
}
