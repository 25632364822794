import React from 'react'
import clsx from 'clsx'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { Avatar, ClickAwayListener, Drawer, Grid, IconButton, Typography } from '@material-ui/core/'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import SettingsIcon from '@material-ui/icons/SettingsRounded'
import './EmailTemplateSettingsDrawer.scss'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerOpen: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      paddingLeft: '10px',
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: `40px !important`,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
      paddingLeft: '30px',
    },
    toolbar: {
      zIndex: theme.zIndex.drawer + 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
  })
)

export default function EmailTemplateSettingsDrawer(props: any) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  const toggleDrawer = (evt?: React.MouseEvent<HTMLDivElement>) => {
    if (evt) {
      evt.stopPropagation()
      evt.preventDefault()
    }
    setOpen(!open)
  }

  const renderSettings = () => {
    if (!open) return renderSettingsClosed()
    return renderSettingsOpen()
  }

  const renderSettingsClosed = () => {
    return (
      <Grid item xs={12} className='settings-label-rotate' onClick={toggleDrawer}>
        <IconButton aria-label='email-template-header-action-copy' className={'email-template-btn'}>
          <Avatar className={'email-template-btn-avatar'}>
            <SettingsIcon fontSize='small' />
          </Avatar>
        </IconButton>
        <Typography component='h4'>Settings</Typography>
      </Grid>
    )
  }

  const renderSettingsOpen = () => {
    return (
      <Grid item xs={12} className='settings-label-normal' onClick={toggleDrawer}>
        <IconButton aria-label='email-template-header-action-copy' className={'email-template-btn'}>
          <Avatar className={'email-template-btn-avatar'}>
            <ChevronLeftIcon fontSize='small' />
          </Avatar>
        </IconButton>
        <Typography component='h6'>Settings</Typography>
      </Grid>
    )
  }

  const handleClickAway = () => {
    if (!open) return
    toggleDrawer()
  }

  const handleDrawerClick = () => {
    if (open) return
    toggleDrawer()
  }

  return (
    <div id='EmailTemplateSettings'>
      <div className={'summary-drawer-root'}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <Drawer
            variant='permanent'
            anchor='left'
            onClick={handleDrawerClick}
            className={clsx({
              ['summary-drawer']: true,
              ['summary-drawer-open']: open,
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                ['summary-drawer']: true,
                ['summary-drawer-open']: open,
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
            <div className={classes.toolbar}>{renderSettings()}</div>
            {open ? props.children : undefined}
          </Drawer>
        </ClickAwayListener>
      </div>
    </div>
  )
}
