import { action, computed, observable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { TaskEditVM } from './TaskEditVM'
import { AlarmPeriodType } from '../types/AlarmPeriodType'
import { Alarm } from '../aggregates/Alarm'

export class AlarmVM {
  private rootStore: RootStore
  private editVM: TaskEditVM
  @observable private alarm: Alarm

  constructor(rootStore: RootStore, editVM: TaskEditVM, alarm: Alarm) {
    this.rootStore = rootStore
    this.alarm = alarm
    this.editVM = editVM
  }

  @computed
  public get id(): string {
    return this.alarm.id
  }

  @computed
  public get alarmOptions(): Array<string> {
    return ['minutes', 'hours', 'days', 'weeks']
  }

  @computed
  public get availableUnits(): Array<string> {
    if (this.period === 'minutes') return ['15', '30', '45']
    if (this.period === 'hours') return ['1', '2', '3', '4', '5', '6', '7', '8']
    return ['1', '2', '3', '4']
  }

  @computed
  public get units(): number {
    return this.alarm.units
  }

  @action
  public setUnits(val) {
    this.alarm.setUnits(val)
  }

  @computed
  public get period(): AlarmPeriodType {
    return this.alarm.period.toLowerCase() as AlarmPeriodType
  }

  @action
  public setPeriod(val: string) {
    if (val === 'minutes') this.setUnits(15)
    else this.setUnits(1)
    this.alarm.setPeriod(val.toLowerCase() as AlarmPeriodType)
  }

  @action
  public delete() {
    this.editVM.deleteAlarm(this.id)
  }
}
