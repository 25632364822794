import { action, observable } from 'mobx'
import Parse from 'parse'
import { IOrganizationDTO } from '../dtos/IOrganizationDTO'
import { serializable, serialize, deserialize, list, primitive, object } from 'serializr'
import { IAggregate } from 'src/app/shared/data/IAggregate'
import { TenantLink } from './TenantLink'
import { WelcomeBanner } from './WelcomeBanner'

export class Organization implements IOrganizationDTO, IAggregate {
  adminPhone?: string
  theme: string
  zone?: any
  static create(orgId) {
    const org = new Organization()
    org.objectId = orgId
    org.hasEnglish = true
    return org
  }

  @serializable @observable public objectId: string = ''
  @serializable @observable public name: string = ''
  @serializable @observable public adminEmail: string = ''
  @serializable @observable public isCalendarEnabled: boolean = false
  @serializable @observable public isChatEnabled: boolean = false
  @serializable @observable public isSurveysEnabled: boolean = false
  @serializable @observable public isPulseCampaignsEnabled: boolean = false
  @serializable @observable public isTasksEnabled: boolean = false
  @serializable @observable public isConsentsEnabled: boolean = false
  @serializable @observable public isGoalsEnabled: boolean = false
  @serializable @observable public isTrainingsEnabled: boolean = false
  @serializable @observable public isCustomCollectionsEnabled: boolean = false
  @serializable @observable public isEmailChecked: boolean = false
  @serializable @observable public isPushChecked: boolean = false
  @serializable @observable public isTextChecked: boolean = false
  @serializable @observable public anonymizeSurveys: boolean = false
  @serializable @observable public forceSurveyEmails: boolean = false
  @serializable @observable public forceSurveyTextMessages: boolean = false
  @serializable @observable public hasEmployeeId: boolean = false
  @serializable @observable public hasCMSPicker: boolean = false
  @serializable @observable public hasTrainingPlanFlows: boolean = false
  @serializable @observable public hasJobNumber: boolean = false
  @serializable @observable public hasSurveyStreak: boolean = false
  @serializable @observable public hasEnglish: boolean = false
  @serializable @observable public hasGerman: boolean = false
  @serializable @observable public hasPseudo: boolean = false
  @serializable @observable public logoUrl: string = ''
  @serializable @observable public tableauSiteName: string = ''
  @serializable @observable public isConsentRequiredForLogin: boolean = false
  @serializable @observable public canImpersonateUsers: boolean = false
  @serializable @observable public tasksAllowPartnerChannel: boolean = false
  @serializable @observable public tasksAllowFeedback: boolean = false
  @serializable @observable public tasksAllowDiscipline: boolean = false
  @serializable @observable public isDeleted: boolean = false
  @serializable @observable public analyzeResponse: boolean = false
  @serializable @observable public gaTrackingName: string = ''
  @serializable @observable public customCollectionAPIToken: string = ''
  @serializable @observable public allowSurveyTags: boolean = false
  @serializable @observable public autoSaveSurveys: boolean = false
  @serializable @observable public allowSeparateUsernames: boolean = false
  @serializable(object(WelcomeBanner)) @observable public welcomeBanner: WelcomeBanner =
    new WelcomeBanner()
  @serializable(list(primitive())) @observable public additionalFileTypes: string[] = []
  @serializable(list(primitive())) @observable public defaultFileTypes: string[] = []
  @serializable(list(object(TenantLink))) @observable public tenantLinks: TenantLink[] = []
  @serializable @observable public anonymousSurveyResultCount: number = null
  @serializable @observable public mobileAppId: string = ''
  @serializable @observable public canArchiveSurveys: boolean = true
  @serializable @observable public archivedAfterDays: number = 14

  @observable public logo: Parse.File = null
  public isOnServer: boolean = false

  @action
  public setName(val: any) {
    this.name = val
  }

  @action
  public setAdminEmail(val: any) {
    this.adminEmail = val
  }

  @action
  public setLogoUrl(val) {
    this.logoUrl = val
  }

  @action
  public setConsentRequiredForLogin() {
    this.isConsentRequiredForLogin = true
  }

  @action
  public setConsentNotRequiredForLogin() {
    this.isConsentRequiredForLogin = false
  }

  @action
  public addTenantLink(link: TenantLink) {
    const idx = this.tenantLinks.findIndex((e) => e.id === link.id)
    if (idx !== -1) this.tenantLinks.splice(idx, 1)
    this.tenantLinks.push(link)
  }

  @action
  public removeTenantLink(link: TenantLink) {
    const idx = this.tenantLinks.findIndex((e) => e.id === link.id)
    if (idx !== -1) this.tenantLinks.splice(idx, 1)
  }

  @action
  public addWelcomeBanner(welcomeBanner: WelcomeBanner) {
    this.welcomeBanner = welcomeBanner
  }

  @action
  public removeWelcomeBanner() {
    this.welcomeBanner = new WelcomeBanner()
  }

  @action
  public markIsNotOnServer() {
    this.isOnServer = false
  }

  @action
  public markIsOnServer() {
    this.isOnServer = true
  }

  public serialize(): IOrganizationDTO {
    return serialize(this)
  }

  public clone(): Organization {
    return deserialize(Organization, this.serialize())
  }
}
