import { Button, Grid, Switch, Typography } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import React from 'react'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { TrainingPlansStore } from '../../../store/TrainingPlansStore'
import { TabEnums } from '../../../types/TabsEnums'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import './BuilderTabPanel.scss'
import Drawer from './Drawer'
import TrainingItemCard from './TrainingItemCard'
import InfoIcon from '@material-ui/icons/Info'
import InfoTooltip from '../../../../shared/InfoTooltip'
import TrainingItemCreationModal from './TrainingItemCreationModal'
import MuiConfirmDialog from 'src/app/shared/MuiConfirmDialog'

interface Props {
  trainingPlansStore?: TrainingPlansStore
  localizationStore?: LocalizationStore
}

const SortableItem: any = SortableElement(({ value }) => (
  <div style={{ zIndex: 99999999 }}>{value}</div>
))

const SortableList: any = SortableContainer(({ items }) => {
  return (
    <div>
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} value={value} />
      ))}
    </div>
  )
})

const BuilderTabPanel: React.FC<Props> = ({ trainingPlansStore, localizationStore }) => {
  const { training_plans: strings } = localizationStore.lzStrings
  const { trainingPlanEditVM: vm } = trainingPlansStore
  if (!vm) return null
  if (vm.currentTab !== TabEnums.BUILDER) return null

  const renderTrainingItemsBuilder = () => {
    return (
      <Grid item xs={7} className='training-item-builder'>
        <div className='elements-container'>
          <div className='start-end-badge' id='start-badge'>
            <Typography>Start</Typography>
          </div>
          {renderTrainingItemCards()}
          {/* {addTrainingItemMenuButton()} */}
          {noTrainingItems()}
          <div className='start-end-badge' id='end-badge'>
            <Typography>End</Typography>
          </div>
        </div>
      </Grid>
    )
  }

  const trainingItems = vm.trainingPlanItemVMs.map((content, index) => (
    <TrainingItemCard key={`item-${index}`} index={index} trainingItem={content} />
  ))

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (vm.editIsDisabled) return
    vm.setOldIndex(oldIndex)
    vm.setNewIndex(newIndex)
    vm.moveTrainingItem()
  }

  const renderTrainingItemCards = () => {
    return (
      <SortableList
        disabled={vm.editIsDisabled}
        distance={3}
        items={trainingItems}
        onSortEnd={onSortEnd}
        shouldCancelStart={() => vm.editIsDisabled}
      />
    )
  }

  const noTrainingItems = () => {
    if (vm.trainingPlanItemVMs.length > 0) return
    return (
      <Typography variant='h5' className='no-items'>
        Please add a training item to continue
      </Typography>
    )
  }

  const addTrainingItemMenuButton = () => {
    return (
      <div id='AddTrainingItemButton'>
        <Button
          disabled={vm.editIsDisabled}
          variant='contained'
          className='add-content-button'
          onClick={() => vm.toggleBuilderDrawerOpen()}
        >
          Add Another
        </Button>
      </div>
    )
  }

  return (
    <Grid container id='BuilderTabPanel'>
      <Grid item xs={12} className='tab-body'>
        <Typography variant='h5' className='tab-title'>
          {strings.build_your_training_plan_outline}
        </Typography>
        <form className='content-order'>
          <Typography variant='h6' className='input-label'>
            {strings.completed_in_order} <InfoTooltip message={strings.complete_in_order_message} />
          </Typography>
          <Switch
            disabled={vm.editIsDisabled}
            checked={vm.mustCompleteInOrder}
            onChange={() => vm.toggleMustCompleteInOrder()}
          />
        </form>
        {renderTrainingItemsBuilder()}
      </Grid>
      <Drawer />
      <TrainingItemCreationModal />
      <MuiConfirmDialog
        open={vm.itemConfirmDialogOpen}
        onClose={() => vm.toggleItemConfirmDialogOpen()}
        confirmMessage={'You have unsaved information, are you sure you want to leave?'}
        onConfirm={() => vm.toggleItemModalOpen()}
      />
    </Grid>
  )
}

export default inject('trainingPlansStore', 'localizationStore')(observer(BuilderTabPanel))
