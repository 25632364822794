import { observable, computed, action, reaction } from 'mobx'
import { Category } from 'src/app/categories/aggregate/Category'
import { Category as WeightProfileCategory } from '../../weight-profiles/aggregate/Category'
import { RootStore } from 'src/app/stores/RootStore'
import { WeightProfileModalVM } from './WeightProfileModalVM'

export class CategoryVM {
  private rootStore: RootStore
  private category: Category
  public depth: number
  public name: string
  public title: string
  public objectId: string
  public id: string
  public weightValue: number
  public isChecked: boolean
  public subtitle: string
  public inValid: boolean

  constructor(rootStore: RootStore, editVM: WeightProfileModalVM, category: Category) {
    this.rootStore = rootStore
    this.editVM = editVM
    this.category = category
    this.name = this.category.name
    this.title = this.category.name
    this.objectId = this.category.objectId
    this.id = this.category.objectId
    this.subtitle = this.category.description
    this.inValid = false
    this.setWeightProfileValues(category.objectId)
  }

  @observable public editVM: WeightProfileModalVM = null
  @observable public allChildrenChecked: boolean = false
  @observable public children: CategoryVM[] = []
  @observable public expanded: boolean = false

  @action
  public setInvalid() {
    this.inValid = true
  }

  @action
  public setWeightProfileValues(categoryId: string) {
    const weightProfileCategory = this.editVM.weightProfile.categories.find(
      (e) => e.categoryId === categoryId
    )
    if (!weightProfileCategory) return
    this.isChecked = weightProfileCategory.isChecked
    this.weightValue = weightProfileCategory.value
  }

  @computed
  public get description(): string {
    return this.category.description
  }

  @computed
  public get parentCategory(): CategoryVM {
    if (!this.parentCategoryId) return null

    let parent

    const findParentCat = (categories) => {
      categories.forEach((cat) => {
        const foundParent = categories.find((e) => e.objectId === this.parentCategoryId)
        if (foundParent) parent = foundParent
        else if (!foundParent && cat.hasChildren) findParentCat(cat.children)
      })
    }

    findParentCat(this.editVM.orgCategoryTreeData)

    if (parent) return parent
  }

  @computed
  public get parentCategoryId() {
    return this.category.parentCategoryId
  }

  @computed
  public get checkedChildren(): Array<CategoryVM> {
    return this.children.filter((child) => child.isChecked)
  }

  @computed
  public get computedParentWeight() {
    if (this.parentCategory) return Math.floor(100 / this.parentCategory.checkedChildren.length)
  }

  @computed
  public get computedChildrenWeight() {
    return Math.floor(100 / this.checkedChildren.length)
  }

  @computed
  public get totalSiblingWeight(): number {
    let weight = 0
    const siblings = this.parentCategory
      ? this.parentCategory.checkedChildren
      : this.editVM.treeData
    siblings.forEach((child) => {
      if (!child.isChecked) return
      weight += Number(child.weightValue)
    })
    return weight
  }

  @computed
  public get siblingWeightValid(): boolean {
    if (!this.isChecked) return true
    if (!this.editVM.saveTried) return true
    if (this.totalSiblingWeight !== 100) return false
    return true
  }

  @computed
  public get hasChildren() {
    return Boolean(this.children.length)
  }

  @computed
  public get categoryWeightValid(): boolean {
    if (this.weightValue > 100) return false
    if (this.weightValue < 0) return false
    return true
  }
}
