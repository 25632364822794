import React from 'react'
import { inject, observer } from 'mobx-react'
import { Button, CircularProgress, Grid, Typography } from '@material-ui/core'
import rootStore from '../../../stores/RootStore'
import { GrandParentCategoryRowVM } from '../../view-models/tree/GrandParentCategoryRowVM'
import { ParentCategoryRowVM } from '../../view-models/tree/ParentCategoryRowVM'
import ParentCategoryRow from './ParentCategoryRow'
import './CategoryRow.scss'

interface Props {
  vm: GrandParentCategoryRowVM
}

const GrandCategoryRow: React.FC<Props> = ({ vm }) => {
  if (!vm) return null
  const { pulseCampaign: lz } = rootStore.localizationStore.lzStrings

  const renderBuildHeader = () => {
    if (!vm.isLoaded) return
    if (vm.parentQuestionCount === 0) return
    if (vm.parentQuestionSelectedCount === 0) return
    return (
      <Grid item xs={12} className={'grand-row'}>
        <div className='category-row-left'>
          <Typography className='grand-header'>{`${vm.name} (${vm.parentQuestionSelectedCount})`}</Typography>
        </div>
      </Grid>
    )
  }

  const renderFocusHeader = () => {
    if (!vm.isLoaded) return
    if (vm.parentQuestionCount === 0) return
    return (
      <Grid item xs={12} className={'grand-row'}>
        <div className='category-row-left'>
          <Typography className='grand-header'>{`${vm.name} (${vm.parentQuestionCount})`}</Typography>
          {renderExpandButton()}
        </div>
        {renderSelectAllButton()}
      </Grid>
    )
  }

  const renderSpinner = () => {
    if (vm.isLoaded) return
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  const renderBuildParentCategories = () => {
    if (!vm.isLoaded) return
    if (vm.parentQuestionSelectedCount === 0) return
    const categories = []
    vm.parentPulseCategories.forEach((row: ParentCategoryRowVM, i) => {
      categories.push(<ParentCategoryRow key={i} vm={row} />)
    })
    return categories
  }

  const renderFocusParentCategories = () => {
    if (!vm.isLoaded) return
    const categories = []
    vm.parentPulseCategories.forEach((row: ParentCategoryRowVM, i) => {
      categories.push(<ParentCategoryRow key={i} vm={row} />)
    })
    return categories
  }

  const renderExpandButton = () => {
    return (
      <Button
        size='small'
        color='primary'
        className={`category-${
          vm.isBuild ? 'build' : 'focus'
        }-header-element category-header-element-text`}
        onClick={() => vm.toggleVisibilityForRecursiveCategories(!vm.areAllExpanded)}
      >
        {`${vm.areAllExpanded ? lz.collapse : lz.expand} ${lz.all}`}
      </Button>
    )
  }

  const renderSelectAllButton = () => {
    return vm.parentQuestionCount > 0 ? (
      <Button
        disableRipple
        onClick={() =>
          vm.areAllQuestionsSelected
            ? vm.unselectRecursiveCategoryQuestions()
            : vm.selectRecursiveCategoryQuestions()
        }
        size='small'
        color='primary'
        className='category-header-element-text'
      >
        {`${vm.areAllQuestionsSelected ? lz.unselect : lz.select}  ${lz.all} (${
          vm.parentQuestionCount
        })`}
      </Button>
    ) : undefined
  }

  return (
    <div className={'grand-container'}>
      {renderSpinner()}
      {vm.isBuild ? renderBuildHeader() : renderFocusHeader()}
      {vm.isBuild ? renderBuildParentCategories() : renderFocusParentCategories()}
    </div>
  )
}

export default inject()(observer(GrandCategoryRow))
