import React from 'react'
import { inject, observer } from 'mobx-react'
import { Button, CircularProgress } from '@material-ui/core'
import { EmailTemplatesStore } from '../../store/EmailTemplatesStore'
import LocalizationStore from '../../../localization/LocalizationStore'
import { LabelsStore } from '../../../labels/store/LabelsStore'
import EmptyState from '../../../shared/empty-state/EmptyState'
import { EmailTemplatesManageVM } from '../../view-models/EmailTemplatesManageVM'
import emptyResults from '../../../../assets/img/empty-state/empty-state-results.png'
import './EmailTemplatesEmptyState.scss'

interface Props {
  emailTemplatesStore?: EmailTemplatesStore
  localizationStore?: LocalizationStore
  vm: EmailTemplatesManageVM
  labelsStore?: LabelsStore
}

const EmailTemplatesEmptyState: React.FC<Props> = ({
  emailTemplatesStore,
  localizationStore,
  vm,
  labelsStore,
}) => {
  const { emailTemplateManagement: lz } = localizationStore.lzStrings
  if (!vm) return null

  const renderSpinner = () => {
    if (vm.isLoaded) return
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  const renderEmptyState = () => {
    if (!vm.isLoaded) return
    if (vm.templates.length > 0) return null
    return (
      <EmptyState
        image={emptyResults}
        title={`${lz.no_templates}`}
        subtitle1={vm.canManage && lz.create_a_company_template_now}
        subtitle2={''}
        action={
          vm.canManage && (
            <Button
              onClick={() => vm.createNewEmailTemplate()}
              className={'empty-state-action-btn'}
              variant={'contained'}
            >
              {`${lz.create} ${lz.new} ${lz.email_template}`}
            </Button>
          )
        }
      />
    )
  }

  return (
    <div id={'EmailTemplatesEmptyState'}>
      {renderEmptyState()}
    </div>
  )
}

export default inject(
  'emailTemplatesStore',
  'localizationStore',
  'labelsStore'
)(observer(EmailTemplatesEmptyState))
