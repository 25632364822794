import { RootStore } from '../../stores/RootStore'
import Parse from 'parse'

export class ContactService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public async importContactsFromCsv(orgId, parseFile, doSave, batchId) {
    const result = await Parse.Cloud.run('importContactsFromCsv', {
      orgId,
      parseFile,
      doSave,
      batchId,
    })
    return result
  }

}
