import Parse from 'parse'
import { RootStore } from '../../stores/RootStore'
import { IDashboardsFindRequest } from '../interfaces/IDashboardsFindRequest'

export class DashboardsFindService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public async getDashboards(request: IDashboardsFindRequest) {
    const result = await Parse.Cloud.run('getDashboards', { request })
    return result
  }
}
