import { RootStore } from '../../stores/RootStore'
import { observable, computed, action } from 'mobx'
import { EmailTemplate } from '../aggregate/EmailTemplate'

export class EmailTemplateVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, emailTemplate: EmailTemplate) {
    this.rootStore = rootStore
    this.emailTemplate = emailTemplate
    if (emailTemplate) this.loadData()
  }

  @observable public emailTemplate: EmailTemplate = null
  @observable public body: string = ''
  @observable public subject: string = ''

  @action
  private loadData() {
    this.subject = this.emailTemplate.subject
    this.body = this.emailTemplate.body
  }

  @action
  public handleSubjectChange(val: string) {
    this.subject = val
  }

  @action
  public handleFieldSelect(val: string, editorRef: any) {
    let contentToInsert = val
    if (val === '{surveyLink}') {
      if (this.language === 'en') {
        contentToInsert = '<a href="{surveyLink}">Take Survey</a>'
      } else if (this.language === 'de') {
        contentToInsert =
          '<a href="{surveyLink}" target="_self">Nehmen Sie an einer Umfrage teil</a>'
      }
    }
    editorRef?.current?.editor?.insertContent(contentToInsert)
  }

  @action
  public setEmailBody(body: string) {
    this.body = body
  }

  @computed
  public get language() {
    return this.emailTemplate.language
  }

  @computed
  public get notificationTypeId() {
    return this.emailTemplate.notificationTypeId
  }

  @action
  public toDTO() {
    return {
      channel: 'EMAIL',
      subject: this.subject,
      body: this.body,
      notificationTypeId: this.notificationTypeId,
      language: this.language,
      organizationId: this.rootStore.appStore.currentOrgId,
    }
  }
}
