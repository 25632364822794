import React from 'react'
import { observer, inject } from 'mobx-react'
import { CardHeader, Tooltip, IconButton } from '@material-ui/core'
import Searchbar from '../../../shared/Searchbar'
import AddIcon from '@material-ui/icons/Add'
import LocalizationStore from '../../../localization/LocalizationStore'
import { GoalsStore } from '../../store/GoalsStore'

interface Props {
  goalsStore?: GoalsStore
  localizationStore?: LocalizationStore
}

const Header: React.FC<Props> = ({ goalsStore, localizationStore }) => {
  const { manageGoalsWidgetVM: vm } = goalsStore
  if (!vm) return null
  const { manage_goals: strings } = localizationStore.lzStrings

  return (
    <CardHeader
      id='Header'
      title={strings.manage_goals}
      action={
        <>
          <div className='search'>
            <Searchbar
              focused={vm.keywordFilter.length > 0}
              searchValue={vm.keywordFilter}
              onSearchChange={(e) => vm.setKeywordFilter(e.target.value)}
            />
          </div>
          {/* <React.Fragment>
            <Tooltip title={strings.add_new_tooltip} placement='bottom'>
              <IconButton className='widget-cta' size='small' onClick={() => vm.createNewGoal()}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </React.Fragment> */}
        </>
      }
    ></CardHeader>
  )
}

export default inject('goalsStore', 'localizationStore')(observer(Header))
