import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../localization/LocalizationStore'
import { AppBar, CssBaseline, Link, Toolbar, Typography } from '@material-ui/core'
import clsx from 'clsx'
import isIE11 from '../../../utils/isIE11'
import { SurveyTemplatesImportVM } from '../view-models/SurveyTemplatesImportVM'

interface Props {
  localizationStore?: LocalizationStore
  vm: SurveyTemplatesImportVM
}

const TopToolbarImport: React.FC<Props> = ({ localizationStore, vm }) => {
  const lz = localizationStore.lzStrings.surveys

  return (
    <div id='DashboardToolbar'>
      <CssBaseline />
      <AppBar
        className={clsx('dashboard-appbar', {
          'no-transition': isIE11,
        })}
        elevation={0}
      >
        <Toolbar className='dashboard-toolbar'>
          <Link onClick={() => vm.navigateToSurveyTemplatesScreen()} underline='always'>
            <Typography className='dashboard-title spaced'>{lz.system_survey_templates}</Typography>
          </Link>
          <Typography className='dashboard-title spaced'>{' > '}</Typography>
          <Typography className='dashboard-title spaced'>{lz.import}</Typography>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default inject('localizationStore')(observer(TopToolbarImport))
