import Parse from 'parse'
import { RootStore } from '../../stores/RootStore'
import PulseQuestion from '../aggregate/PulseQuestion'
import { IPulseQuestionDTO } from '../dtos/IPulseQuestionDTO'
import { deserialize } from 'serializr'
import { IPulseQuestionsFindRequest } from '../interfaces/IPulseQuestionsFindRequest'
import { IPulseQuestionsFindResult } from '../interfaces/IPulseQuestionsFindResult'
import { IPulseQuestionsSaveResult } from '../interfaces/IPulseQuestionSaveResult'

export class PulseQuestionsService {
  rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public async getPulseQuestions(
    request: IPulseQuestionsFindRequest
  ): Promise<IPulseQuestionsFindResult> {
    return await Parse.Cloud.run('getPulseQuestions', { request })
  }

  public async savePulseQuestion(pulseQuestion: IPulseQuestionDTO): Promise<IPulseQuestionsSaveResult> {
    return await Parse.Cloud.run('savePulseQuestion', { pulseQuestion })
  }

  public async deletePulseQuestion(objectId: string) {
    return await Parse.Cloud.run('deletePulseQuestion', { pulseQuestionId: objectId })
  }
}
