import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../localization/LocalizationStore'
import { NotificationsStore } from '../../notifications/store/NotificationsStore'
import MuiDrawerToolbar from '../../shared/Mui-Drawer/MuiDrawerToolbar'
import { NotificationsWidgetVM } from '../store/view-models/NotificationsWidgetVM'

interface DrawerButtonControlsProps {
  localizationStore?: LocalizationStore
  notificationsStore?: NotificationsStore
  vm: NotificationsWidgetVM
}

const DrawerButtonControls: React.FC<DrawerButtonControlsProps> = ({
  localizationStore,
  notificationsStore,
  vm,
}) => {
  const { lzStrings } = localizationStore
  const { editVM } = notificationsStore

  const handleSave = () => {
    editVM.saveNotification()
  }

  return (
    <div>
      <MuiDrawerToolbar
        disabled={editVM.isSaving || editVM.resetDialog}
        handleCancelClick={() => {
          editVM.closeEditor()
        }}
        handleSubmitClick={handleSave}
        showReset={Boolean(editVM.objectId)}
        onConfirmReset={() => notificationsStore.initWidgetForm(editVM.objectId, vm.isSystemOnly)}
        moreMenuProps={{
          hidden: true,
          isEditMode: true,
          itemType: lzStrings.moreMenu.obj_type.notification,
          itemName: editVM.subject,
          onConfirmDelete: () => console.log('under construction'),
        }}
      />
    </div>
  )
}

export default inject('localizationStore', 'notificationsStore')(observer(DrawerButtonControls))
