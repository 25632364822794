import { action, computed, observable } from 'mobx'
import moment from 'moment'
import { IPulseQuestionDTO } from '../../../../pulse-questions/dtos/IPulseQuestionDTO'
import { RootStore } from '../../../../stores/RootStore'
import { IPulseCampaignSurveyDTO } from '../../../dto/IPulseCampaignSurveyDTO'
import { PulseCampaignSurveyStatusType } from '../../../type/PulseCampaignSurveyStatusType'
import { CampaignRowVM } from './CampaignRowVM'
import { SurveyPulseCategoriesDataStore } from './SurveyPulseCategoriesDataStore'
import { SurveyTemplatesService } from '../../../../survey-templates/services/SurveyTemplatesService'

export class TimelineSurveyVM {
  private rootStore: RootStore

  constructor(
    rootStore: RootStore,
    survey: IPulseCampaignSurveyDTO,
    index: number,
    parentVM: CampaignRowVM
  ) {
    this.rootStore = rootStore
    this.survey = survey
    this.index = index
    this.pulseCategoriesStore = new SurveyPulseCategoriesDataStore(
      this.rootStore,
      this.survey.questions.map((q) => q.pulseCategoryId)
    )
    this.parentVM = parentVM
    this.loadSurveyTemplateQuestions()
    // this.loadDataStores()
  }

  @observable public survey: IPulseCampaignSurveyDTO = null
  @observable public index: number = 1
  @observable public pulseCategoriesStore: SurveyPulseCategoriesDataStore = null
  @observable public parentVM: CampaignRowVM = null
  @observable public surveyQuestionCount: number = 0

  @action
  private loadDataStores() {
    this.pulseCategoriesStore.loadListRecords()
  }

  @computed
  public get isActive(): boolean {
    if (!this.parentVM.isDetailsModal) return null
    return this.parentVM.activeSurveyIndex === this.index - 1
  }

  @computed
  public get name(): string {
    let name = this.survey.name
    if (name.length > 31) {
      name = name.substring(0, 30) + '...'
    }
    return name
  }

  @computed
  public get questions(): IPulseQuestionDTO[] {
    return this.survey.questions
  }

  @action
  public async loadSurveyTemplateQuestions() {
    if (!this.survey.surveyTemplateId) return
    if (this.survey.surveyTemplateId) {
      const svc = new SurveyTemplatesService(this.rootStore)
      const template = await svc.getSurveyTemplate(
        this.survey.surveyTemplateId,
        this.rootStore.appStore.currentOrgId
      )
      if (!template) return 0
      this.surveyQuestionCount = template.questionsCount
    }
  }

  @computed
  public get questionsCount() {
    if (this.survey.surveyTemplateId) {
      if (this.surveyQuestionCount === 1) return `${this.surveyQuestionCount} Question`
      return `${this.surveyQuestionCount} Questions`
    } else {
      if (this.survey.questions.length === 1) return `${this.survey.questions.length} Question`
      return `${this.survey.questions.length} Questions`
    }
  }

  @computed
  public get deliveryTime(): string {
    return this.survey.deliveryTime
  }

  @computed
  public get deliveryTimeFormatted(): string {
    return moment(this.deliveryTime).format('MMM D hh:mm A')
  }

  @computed
  public get endTime(): string {
    return this.survey.endTime
  }

  @computed
  public get endTimeFormatted(): string {
    return moment(this.endTime).format('MMM D YYYY')
  }

  @computed
  public get pulseCategoryIds(): string[] {
    return this.questions.map((q) => q.pulseCategoryId)
  }

  @computed
  public get status(): PulseCampaignSurveyStatusType {
    return this.survey.status
  }

  @computed
  public get statusString(): string {
    if (this.status === 'scheduled') return 'Not started'
    if (this.status === 'sent') return 'In progress'
    return ''
  }

  @computed
  public get lzStrings() {
    return this.rootStore.localizationStore.lzStrings
  }

  @action
  public openCampaignDetails() {
    this.parentVM.openDetails(this.index - 1)
  }
}
