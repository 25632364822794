import React, { Component } from 'react'
import { Button, IconButton, Modal, Tooltip, Typography } from '@material-ui/core'
import LinkIcon from '@material-ui/icons/Link'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import ClearIcon from '@material-ui/icons/Clear'
import SendIcon from '@material-ui/icons/Send'
import ListIcon from '@material-ui/icons/FormatListBulleted'
import { SurveyParticipantRowVM } from '../view-models/SurveyParticipantRowVM'

class RowActions extends Component {
  public props: any
  public data: SurveyParticipantRowVM
  private archivedTooltip: string = 'This action is not available for archived users.'

  constructor(props) {
    super(props)
    this.data = props.data
  }
  renderShowHistory = () => {
    if (this.data.isSystemAdmin || this.data.canViewInvitationHistory) {
      return (
        <Tooltip title={`History`} placement='bottom-start'>
          <IconButton
            className='history'
            aria-label='history'
            size='small'
            onClick={() =>
              this.props.history(
                this.data.surveyId,
                this.data.userId,
                this.data.userName,
                this.data.email
              )
            }
          >
            <ListIcon />
          </IconButton>
        </Tooltip>
      )
    }
  }

  renderActions() {
    if (!this.data) return ''
    if (this.data.userSurveyId) {
      return (
        <Typography className='text' variant='inherit' noWrap>
          <Tooltip
            title={this.data.isArchived ? this.archivedTooltip : `Get Link`}
            placement='bottom-start'
          >
            <span>
              <IconButton
                disabled={this.data.isArchived}
                className='link'
                aria-label='link'
                size='small'
                onClick={() => this.props.link(this.data.userSurveyId)}
              >
                <LinkIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip
            title={this.data.isArchived ? this.archivedTooltip : `Send Reminder`}
            placement='bottom-start'
          >
            <span>
              <IconButton
                disabled={this.data.isArchived}
                aria-label='remind'
                size='small'
                onClick={() => this.props.remind(this.data.toDTO())}
              >
                <MailOutlineIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={`Cancel Invitation`} placement='bottom-start'>
            <IconButton
              aria-label='cancel'
              size='small'
              onClick={() => this.props.cancel(this.data.toDTO())}
            >
              <ClearIcon />
            </IconButton>
          </Tooltip>
          {this.renderShowHistory()}
        </Typography>
      )
    }
    if (!this.data.userSurveyId) {
      return (
        <Typography className='text' variant='inherit' noWrap>
          <Tooltip
            title={this.data.isArchived ? this.archivedTooltip : `Send Invitation`}
            placement='bottom-start'
          >
            <span>
              <IconButton
                disabled={this.data.isArchived}
                aria-label='sendInvite'
                size='small'
                onClick={() => this.props.send(this.data.toDTO())}
              >
                <SendIcon />
              </IconButton>
            </span>
          </Tooltip>
          {this.renderShowHistory()}
        </Typography>
      )
    }
  }

  render() {
    return this.renderActions()
  }
}

export default RowActions
