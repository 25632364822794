import React from 'react'
import { observer } from 'mobx-react'
import './TimelineSurvey.scss'
import { TimelineSurveyVM } from '../../../view-models/dashboard/all-campaigns/TimelineSurveyVM'
import TaskAltIcon from '@material-ui/icons/CheckCircleOutline'

interface Props {
  vm: TimelineSurveyVM
}

const TimelineSurvey: React.FC<Props> = ({ vm }) => {
  if (!vm) return null

  const renderDeliveryTime = () => {
    if (vm.status === 'closed') return null
    return (
      <div className={'survey-delivery-time'}>
        <div className={`line-left ${vm.status}`} />
        <div className={`line-right ${vm.status}`}>
          <div className={`delivery-time ${vm.status}`}>
            <div className={`survey-index ${vm.status}`}>{vm.index}</div>
            <div className={`survey-time ${vm.status}`}>
              {vm.status} {vm.deliveryTimeFormatted}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderStatus = () => {
    if (vm.status === 'closed') return null
    return (
      <div className={'survey-status'}>
        <div className={`survey-status-${vm.status}`}></div>
        <div className={`survey-status-text`}>{vm.statusString}</div>
      </div>
    )
  }

  const renderName = () => {
    return <div className={'survey-name'}>{vm.name}</div>
  }

  const renderQuestionsCount = () => {
    return (
      <div className={'survey-questions-count'}>
        <div className={'questions-count-text'}>{vm.questionsCount}</div>
      </div>
    )
  }

  const renderCompleted = () => {
    if (vm.status !== 'closed') return null
    return (
      <div className={'survey-completed'}>
        <TaskAltIcon className={'survey-completed-icon'} />
        <div className={'survey-completed-text'}>{`Completed (${vm.endTimeFormatted})`}</div>
      </div>
    )
  }

  const renderSurveyCard = () => {
    return (
      <div
        className={`survey-card-details ${vm.isActive ? 'active' : null}`}
        onClick={(e) => vm.openCampaignDetails()}
      >
        {renderStatus()}
        {renderName()}
        {renderQuestionsCount()}
        <div className={'survey-card-details-complete'}>{renderCompleted()}</div>
      </div>
    )
  }

  return (
    <div id='TimelineSurvey'>
      {renderDeliveryTime()}
      {renderSurveyCard()}
    </div>
  )
}

export default observer(TimelineSurvey)
