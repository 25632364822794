import React from 'react'
import { Route } from 'react-router'
import Layout from '../../layout/Layout'
import { authenticate } from '../../auth/routes/AuthenticatedRoute'
import CompletedSurveyResponseScreen from '../../survey-responses/views/CompletedSurveyResponseScreen'

export default function getSurveyResponsesRoutes() {
  return (
    <Route key={'sr0'} path='surveyresponses' component={authenticate(Layout)}>
      <Route key={'sr1'} path='view/:surveyResponseId' component={CompletedSurveyResponseScreen} />
    </Route>
  )
}
