import { RootStore } from '../../stores/RootStore'
import { UserEvent } from '../user-events/aggregate/UserEvent'
import { observable, computed, action } from 'mobx'
import { CalendarVM } from './CalendarVM'
import { IEventPillVM } from '../intefaces/IEventPillVM'
import moment from 'moment'
export class UserEventPillVM implements IEventPillVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, userEvent: UserEvent) {
    this.rootStore = rootStore
    this.event = userEvent
  }

  @observable public event: UserEvent = null
  @observable public isSelectedEvent: boolean = false

  @computed
  public get userEvent(): UserEvent {
    return this.event
  }

  @computed
  public get userNotGoing(): boolean {
    return this.userEvent.goingStatus === 'no'
  }

  @computed
  public get title(): string {
    return this.userEvent.title
  }

  @computed
  public get localStartDate(): Date {
    return this.userEvent.localStartDate
  }

  @computed
  public get localEndDate(): Date {
    return this.userEvent.localEndDate
  }

  @computed
  public get allDay(): boolean {
    return this.userEvent.allDay
  }

  @computed
  public get objectId(): string {
    return this.userEvent.objectId
  }

  @computed
  public get userId(): string {
    return this.userEvent.userId
  }

  @computed
  public get unread(): boolean {
    if (this.userEvent.readStatus !== 'unread') return false
    return true
  }

  @computed
  public get isRead(): boolean {
    if (moment(this.event.startDate).diff(moment().startOf('day')) <= 0) return true
    return this.event.readUserIds.includes(this.userId)
  }

  @computed
  public get userName(): string {
    const foundUser = this.rootStore.audienceMembersStore.getUser(this.userEvent.userId)
    if (!foundUser) return ''
    return foundUser.name
  }

  @computed
  public get calendarVM(): CalendarVM {
    return this.rootStore.eventsStore.calendarVM
  }

  @computed
  public get calendarIndex(): number {
    const idx = this.calendarVM.selectedOtherCalendars.findIndex((e) => e.id === this.userId)
    if (idx === -1) return 0
    return idx + 1
  }

  @action
  public async edit() {
    await this.rootStore.eventsStore.lazyLoadEventEditVM(this.userEvent.eventId)
    this.rootStore.eventsStore.calendarVM.isEventsDrawerOpen = true
  }

  @action
  public loadPopup(pillVM) {
    this.isSelectedEvent = true
    this.rootStore.eventsStore.loadPopup(pillVM)
  }
}
