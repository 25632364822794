import { action, observable } from 'mobx'
import { serializable } from 'serializr'
import moment from 'moment-timezone'
import { IAnnouncementScheduleDTO } from '../dtos/IAnnouncementScheduleDTO'

export class Schedule implements IAnnouncementScheduleDTO {
  static create(sch: any) {
    const schedule = new Schedule()
    schedule.enabled = sch.enabled
    schedule.deliveryDateTime = sch.deliveryDateTime
    schedule.deliveryTimeZone = sch.deliveryTimeZone
    schedule.sendDateTime = sch.sendDateTime
    console.log(schedule)
    return schedule
  }

  @serializable @observable enabled: boolean = false
  @serializable @observable deliveryDateTime: string = moment(
    moment()
      .minute(Math.ceil(moment().minute() / 15) * 15)
      .second(0)
      .add(2, 'hours')
  ).toISOString()
  @serializable @observable deliveryTimeZone: string = moment.tz.guess()
  @serializable @observable sendDateTime: string = ''

  @action
  public setDeliveryDateTime(val: Date) {
    this.deliveryDateTime = moment(val).toISOString()
  }

  @action
  public setDeliveryTimeZone(val) {
    this.deliveryTimeZone = val
  }

  @action
  public toggleIsEnabled() {
    this.enabled = !this.enabled
  }
}
