import React, { FC } from 'react'
import { inject, observer } from 'mobx-react'
import { CardContent, CardHeader, IconButton, Typography } from '@material-ui/core'
import { AgGridReact } from 'ag-grid-react'
import FilterListIcon from '@material-ui/icons/FilterList'
import { ManageSurveysWidgetRowVM } from '../../view-models/manage-surveys-widget/ManageSurveysWidgetRowVM'
import { SurveysTabVM } from '../../view-models/manage-surveys-widget/SurveysTabVM'
import AGGridUserCell from '../../../shared/ag-grid/AGGridUserCell'
import WidgetTitle from './WidgetTitle'
import Searchbar from '../../../shared/Searchbar'
import AGGridWidgetPagingRow from '../../../shared/ag-grid/AGGridPagingRow'
import ActionsCell from './ActionsCell'

interface Props {
  vm: SurveysTabVM
}

const SurveysTab: FC<Props> = ({ vm }) => {
  if (!vm) return
  
  const renderNameCell = (props) => {
    const row: ManageSurveysWidgetRowVM = props.data
    if (!row) return null
    return <AGGridUserCell userId={row.ownerUserId} name={row.ownerName} iconURL={row.ownerIconURL} />
  }

  const renderGrid = () => {
    if (!vm.shouldRenderGrid) return
    return (
        <AgGridReact
          gridOptions={{
            ...vm.getGridOptions(),
            frameworkComponents: {
              ownerCell: renderNameCell,
              actionsCell: ActionsCell,
            },
          }}
        />
    )
  }

  const renderHeader = () => {
    if (!vm.shown) return
    return (
      <CardHeader
        title={<WidgetTitle vm={vm.widgetVM} />}
        action={
          <>
            <IconButton
              size='small'
              onClick={(e) => {
                vm.widgetVM.setFilterPopupAnchorEl(e.currentTarget)
              }}
              className={vm.widgetVM.hasFilter ? 'filter-is-active' : ''}
            >
              <FilterListIcon />
            </IconButton>
            <div id='ManageSurveysSearch'>
              <Searchbar
                focused={vm.typedFilterText.length > 0}
                className='search'
                searchValue={vm.typedFilterText}
                onSearchChange={(e) => vm.setQuickFilter(e.target.value)}
              />
            </div>
          </>
        }
      />
    )
  }

  const renderPagingRow = () => {
    if (!vm.shown) return
    return <AGGridWidgetPagingRow vm={vm} />
  }

  const renderNoRowsContent = () => {
    if (vm.hasRows) return
    return (
      <Typography component='div' className='no-rows'>
        {vm.noRowsToShowLabel}
      </Typography>
    )
  }

  return (
    <>
      {renderHeader()}
      <CardContent
        className={vm.hasRows ? 'ag-theme-alpine' : 'ag-theme-alpine ag-grid-no-rows'}
        style={{
          height: '100%',
          width: '100%',
          display: vm.shown ? undefined : 'none',
        }}
      >
        {renderGrid()}
        {renderNoRowsContent()}
      </CardContent>
      {renderPagingRow()}
    </>
  )
}

export default inject('organizationUsersStore')(observer(SurveysTab))
