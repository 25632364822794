import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../localization/LocalizationStore'
import { NotificationsStore } from '../store/NotificationsStore'
import './NotificationTemplatePreview.scss'

interface Props {
  notificationsStore?: NotificationsStore
  localizationStore?: LocalizationStore
}

const NotificationTemplatePreview: React.FC<Props> = ({notificationsStore, localizationStore }) => {
  const { editVM: vm } = notificationsStore
  if (!vm) return

  // mitigates validateDOMNesting html Warning  
  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: vm.currentHTML }}></div>
    </>
  )
}

export default inject('notificationsStore', 'localizationStore')(observer(NotificationTemplatePreview))
