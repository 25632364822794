import React from 'react'
import { Tooltip } from '@material-ui/core'
import { IconButton } from '@material-ui/core'
import './WeightProfileCell.scss'
import { GroupsAndRolesWidgetRowVM } from '../view-models/GroupsAndRolesWidgetRowVM'
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter'

const WeightProfileCell = (props) => {
  const data: GroupsAndRolesWidgetRowVM = props.data
  if (!data) return <div></div>

  return (
    <div className='weight-profiles-cell'>
      <Tooltip title={`Set Role weight profile`} placement='bottom-start' enterDelay={500}>
        <IconButton
          style={{ padding: '3px' }}
          className='weight-profile-button'
          onClick={() => data.openWeightProfileModal()}
        >
          <FitnessCenterIcon />
        </IconButton>
      </Tooltip>
    </div>
  )
}

export default WeightProfileCell
