import { action, computed, observable, when } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { SurveyTypesTableVM } from './SurveyTypesTableVM'
import { ISurveyTypesFindRequest } from '../interfaces/ISurveyTypesFindRequest'
import { ScreenType } from '../types/ScreenType'

export class SurveyTypesManageVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, screenType: ScreenType) {
    this.rootStore = rootStore
    this.screenType = screenType
    this.loadTableVM()
  }

  @observable public tableVM: SurveyTypesTableVM = null
  @observable public screenType: ScreenType = null

  @action
  public loadTableVM() {
    let orgId = 'DEFAULT'
    if (this.screenType === 'tenantManage') {
      orgId = this.rootStore.appStore.currentOrgId
    }
    this.tableVM = new SurveyTypesTableVM(this.rootStore, this, {
      organizationId: orgId,
    } as ISurveyTypesFindRequest)
  }

  @action
  public navigateToSurveyTypeEdit() {
    this.rootStore.appStore.router.push(`/surveyTypes/edit/${this.screenType}/new`)
  }
}
