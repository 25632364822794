import React from 'react'
import { inject, observer } from 'mobx-react'
import ReactPlayer from 'react-player'
import PulseQuestionRowImage from './PulseQuestionRowImage'
import { OldMediaItemVM } from '../../../user-surveys/view-models/OldMediaItemVM'
import { PulseQuestionVM } from '../../view-models/questions/PulseQuestionVM'
import './PulseQuestionRow.scss'

interface Props {
  question: PulseQuestionVM
  vm: OldMediaItemVM
}

const PulseQuestionOldMedia: React.FC<Props> = ({ question, vm }) => {

  if (!vm) return

  const renderImage = (item: OldMediaItemVM) => {
    if (item.isCMSItem) return
    if (!item.isImage) return
    if (item.hasVimeoURL) return
    if (item.hasYouTubeURL) return
    return <PulseQuestionRowImage url={item.path} toggleLightBox={() => question.toggleLightBox() }/>
  }

  const renderYouTubeVideo = (item: OldMediaItemVM) => {
    if (item.isCMSItem) return
    if (!item.isVideo) return
    if (!item.hasYouTubeURL) return

    return (
      <div>
        <iframe
          width={'100%'}
          className='media-youTube'
          src={question.embedUrl}
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        />
      </div>
    )
  }

  const renderVimeoVideo = (item: OldMediaItemVM) => {
    if (item.isCMSItem) return
    if (!item.hasVimeoURL) return

    return (
      <div>
        <iframe
          width={'100%'}
          className='media-vimeo'
          src={question.embedUrl}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    )
  }

  const renderVideo = (item: OldMediaItemVM) => {
    if (item.isCMSItem) return
    if (item.isImage) return
    return (
        <ReactPlayer
          id={`campaign-video-${question.objectId}`}
          height={''}
          width={'100%'}
          progressInterval={1000}
          onProgress={(e) => question.watchProgress(e)}
          onDuration={(e) => question.setWatchPercentageNeeded(e)}
          url={question.media.path}
          onSeek={() => overrideSeek()}
          controls
          className='media-video'
        />
    )
  }

  const overrideSeek = () => {
    let video = document.querySelector('video')
    if (video.currentTime === question.playedSeconds) return

    if (question.playedSeconds <= question.watchSecondsNeeded)
      video.currentTime = question.playedSeconds
  }

  return (
    <>
      {renderImage(vm)}
      {renderYouTubeVideo(vm)}
      {renderVimeoVideo(vm)}
      {renderVideo(vm)}
    </>
  )
}

export default inject()(observer(PulseQuestionOldMedia))
