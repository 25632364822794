import { RootStore } from 'src/app/stores/RootStore'
import { action, computed, observable } from 'mobx'
import { PulseCampaignTypesService } from '../service/PulseCampaignTypesService'
import { IPulseCampaignTypeDTO } from '../dtos/IPulseCampaignTypeDTO'

export default class PulseCampaignTypeEditVM {
	rootStore: RootStore
	
	constructor(rootStore: RootStore, pulseCampaignType?: IPulseCampaignTypeDTO) {
		this.rootStore = rootStore
		if (pulseCampaignType) this.loadData(pulseCampaignType)
	}

	@observable objectId: string = ''
	@observable name: string = ''
	@observable isActive: boolean = true
	@observable saveTried: boolean = false
  @observable saveSuccessful: boolean = false
  @observable deletePending: boolean = false
  @observable deleteSuccessful: boolean = false
	@observable deleteConfirmShown: boolean = false

	private loadData(incomingPulseCampaignType: IPulseCampaignTypeDTO) {
		this.objectId = incomingPulseCampaignType.objectId
		this.name = incomingPulseCampaignType.name,
		this.isActive = incomingPulseCampaignType.isActive
	}

	private toDTO() {
		const newPulseCampaignType: IPulseCampaignTypeDTO = {
			objectId: this.objectId,
			name: this.name,
			isActive: this.isActive,
			isDeleted: false,
		}
		return newPulseCampaignType
	}

	@computed
	public get nameValid(): boolean {
		if (!this.saveTried) return true
		if (!this.name || this.name === '') return false
		return true
	}

	@computed
	public get isValid(): boolean {
		if (!this.nameValid) return false
		return true
	}

	@action
	public setName(name: string) {
		this.name = name
	}

	@action
	public toggleIsActive() {
		this.isActive = !this.isActive
	}

	@action
	public toggleDeleteDialog() {
		this.deleteConfirmShown = !this.deleteConfirmShown
	}

	@action
	public async deletePulseCampaignType() {
		this.deletePending = true
		const svc = new PulseCampaignTypesService(this.rootStore)
		const result = await svc.deletePulseCampaignType(
			this.rootStore.appStore.currentOrgId,
			this.objectId
		)
		if (result) {
			this.deletePending = false
			this.deleteSuccessful = true
			this.navigateBackToMainScreen()
		}
	}

	@action
	public async savePulseCampaignType() {
		this.saveTried = true
		if (!this.isValid) return
		const svc = new PulseCampaignTypesService(this.rootStore)
		const result = await svc.savePulseCampaignType(
			this.rootStore.appStore.currentOrgId,
			this.toDTO()
		)
		if (result) {
			this.saveSuccessful = true
			this.navigateBackToMainScreen()
		}
	}

	@action
	public navigateBackToMainScreen() {
		this.rootStore.appStore.router.push('/systemAdmin/manage/pulseCampaignTypes')
	}
}