import axios from 'axios'
import * as Sentry from '@sentry/browser'
import env from '../../../env'
import { delay } from '../../shared/delay'
import { ICMSAuthRequest } from '../interfaces/ICMSAuthRequest'
import { ICMSAuthResponse } from '../interfaces/ICMSAuthResponse'

export class CMSAuthService {
  private static jwt: string = undefined
  private static gettingJwt: boolean = false
  private static cmsTenantId: number = undefined

  public static async getJWT(): Promise<string> {
    if (this.jwt) return this.jwt
    if (this.gettingJwt) {
      await delay(500)
      if (this.jwt) return this.jwt
      return await this.getJWT()
    }
    this.gettingJwt = true
    const authUrl = `${env.var.REACT_APP_API_URL}/cmsItems/cmsAuth`
    const req = {
      email: 'uploader@rippleworx.com',
      organizationId: (window as any).rootStore.appStore.currentOrgId,
    } as ICMSAuthRequest
    try {
      const response = await axios.post(authUrl, req).finally(() => {
        this.gettingJwt = false
      })
      if (!response?.data) return
      if (!response.data.data.auth) return
      const authRes = response.data.data.auth as ICMSAuthResponse
      this.jwt = authRes.jwt
      this.cmsTenantId = authRes.cmsTenantId

      const cookieName = 'userJwt'
      const cookieReqUrl = `${env.var.REACT_APP_CMS_API_URL}/api/asset/setCookie`
      const config = {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.jwt}`,
          Accept: '*/*',
        },
      }
      const cookieReq = {
        cookie: cookieName,
        token: this.jwt,
      }
      
      await axios.post(cookieReqUrl, { data: cookieReq }, config)

      return this.jwt
    } catch (e) {
      console.error(e)
      Sentry.captureException({
        message: 'Unable to retrieve cms token',
        exception: e,
      })
      return undefined
    }
  }
}
