import React from 'react'
import { observer } from 'mobx-react'
import fullLogo from '../../../../assets/img/survey-not-found.png'
import './PlanNotFound.scss'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import { Button } from '@material-ui/core'
import { UserTrainingPlansStore } from '../../user-training-plans/store/UserTrainingPlansStore'
import rootStore from '../../../stores/RootStore'

interface Props {
  router: any
  userTrainingPlansStore?: UserTrainingPlansStore
  localizationStore?: LocalizationStore
}

class PlanNotFound extends React.Component<any, any> {
  constructor(props) {
    super(props)
  }

  render() {
    const { training_plans: strings } = rootStore.localizationStore.lzStrings
    return (
      <div id='PlanNotFound'>
        <div className='plan-not-found-content'>
          <div className='plan-not-found-card'>
            <img className='logo' alt='RippleWorx Logo' src={fullLogo} />

            <h4>{strings.plan_not_found}</h4>
            <div className='plan-helper-subtitle plan-helper-text'>
              {strings.plan_not_found_description}
            </div>
            <div>{strings.plan_not_found_removed}</div>
            <div className='plan-helper-text'>{strings.plan_not_found_active}</div>
            <div className='plan-helper-text'>{strings.plan_not_found_error}</div>
            <Button
              onClick={() => rootStore.userTrainingPlansStore.goBackToDashboard()}
              className='forgot-password'
            >
              {strings.back_to_dashboard}
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default observer(PlanNotFound)
