import { RootStore } from '../../stores/RootStore'
import Parse from 'parse'
import { observable, runInAction, action, computed } from 'mobx'
import { deserialize } from 'serializr'
import { TrainingPlan } from '../aggregates/TrainingPlan'
import { TrainingPlanEditVM } from '../view-models/TrainingPlanEditVM'
import { ManageTrainingsWidgetVM } from '../view-models/widgets/manage-plans/ManageTrainingsWidgetVM'
import { TrainerWidgetVM } from '../view-models/widgets/trainer-widget/TrainerWidgetVM'
import moment from 'moment'
import { ITrainingPlanDTO } from '../dtos/ITrainingPlanDTO'
import { DataStore } from '../../shared/data/DataStore'

export class TrainingPlansStore extends DataStore<TrainingPlan, ITrainingPlanDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, TrainingPlan, 'trainingPlans', [
      'organizationId',
      'title',
      'description',
      'assignedToAudienceMembers',
      'thumbnail',
      'thumbnailFileName',
      'trainingPlanItems',
      'isRecurring',
      'activeDate',
      'dueDate',
      'dueDateSameForAll',
      'dueDateVariesByUser',
      'dueDateVariesByTrainingItem',
      'requireProofOfCompletion',
      'requireQuiz',
      'requireCertificate',
      'certificate',
      'categories',
      'applicableParticipants',
      'mustCompleteInOrder',
      'participantDueDates',
      'assignedByUserId',
      'isForPeerTraining',
      'trainer',
      'trainee',
      'quiz',
      'associateToGoals',
      'associatedGoalId',
      'associatedCatalogGoalId',
      'version',
      'isCatalogPlan',
      'planSnapshots',
      'isArchived',
      'showQuizResults',
      'trainingPlanFlowId',
      'isForAutomatedTraining',
      'program',
    ])
  }

  @observable public trainingPlanEditVM: TrainingPlanEditVM = null
  @observable public manageTrainingsWidgetVM: ManageTrainingsWidgetVM = null
  @observable public trainerWidgetVM: TrainerWidgetVM = null
  @observable public loaded: boolean = false

  @computed
  public get orgTrainingPlans(): TrainingPlan[] {
    return this.records
  }

  @action
  public onListRecordsLoaded() {
    this.manageTrainingsWidgetVM = new ManageTrainingsWidgetVM(this.rootStore)
    this.trainerWidgetVM = new TrainerWidgetVM(this.rootStore)
    this.loaded = true
  }

  @computed
  public get canCreateTrainingPlans(): boolean {
    return this.rootStore.appStore.canCreateTrainings
  }

  @action
  public createNewTrainingPlan() {
    this.trainingPlanEditVM = new TrainingPlanEditVM(
      this.rootStore,
      TrainingPlan.create(
        this.rootStore.appStore.currentOrgId,
        this.rootStore.appStore.currentUserId
      )
    )
  }

  @action
  public getCatalogPlan(id: string) {
    return this.rootStore.trainingPlansCatalogStore.getCatalogTrainingPlan(id)
  }

  @action
  public createPlanFromCatalog(id: string, attempts: number = 0) {
    if (attempts === 10) console.log('No Training Plan Found')
    const foundTrainingPlan = this.getCatalogPlan(id)
    if (!foundTrainingPlan) {
      setTimeout(() => this.createPlanFromCatalog(id, attempts++), 100)
      return
    }
    const clonedPlan = foundTrainingPlan.clone()
    clonedPlan.objectId = ''
    clonedPlan.isCatalogPlan = false
    clonedPlan.isForAutomatedTraining = false
    clonedPlan.assignedByUserId = this.rootStore.appStore.currentUserId
    clonedPlan.activeDate = moment().format()
    clonedPlan.dueDate = moment().add(1, 'week').format()
    this.trainingPlanEditVM = new TrainingPlanEditVM(this.rootStore, clonedPlan)
  }

  public getTrainingPlan(objectId): TrainingPlan {
    return this.orgTrainingPlans.find((trainingPlan) => trainingPlan.objectId === objectId)
  }

  public deleteTrainingPlan(objectId) {
    this.orgTrainingPlans.splice(this.getTrainingPlanIndex(objectId), 1)
  }

  private getTrainingPlanIndex(objectId): number {
    return this.orgTrainingPlans.findIndex((e) => e.objectId === objectId)
  }

  @action
  public editTrainingPlan(id: string, attempts: number = 0) {
    if (attempts > 10) console.log('Error finding training plan to edit')
    const trainingPlan = this.getTrainingPlan(id)
    if (!trainingPlan || trainingPlan === undefined) {
      setTimeout(() => this.editTrainingPlan(id, attempts++), 500)
    } else {
      this.trainingPlanEditVM = new TrainingPlanEditVM(this.rootStore, trainingPlan.clone())
    }
  }
}
