import { observable, computed } from 'mobx'
import { IAnonymousParticipantDTO } from '../interfaces/IAnonymousParticipantDTO'
import { ParticipantType } from '../types/ParticipantType'

export class AnonymousParticipantVM {
  constructor(token, email, firstName, lastName, language, isArchived) {
    this.email = email
    this.token = token
    this.firstName = firstName
    this.lastName = lastName
    this.language = language
    this.isArchived = isArchived
  }

  @observable public email: string = ''
  @observable public token: string = ''
  @observable public firstName: string = ''
  @observable public lastName: string = ''
  @observable public language: string = ''
  @observable public isArchived: boolean = false
  public explodable: boolean = false
  public type: ParticipantType = 'anonymous'

  @computed
  public get chipLabel(): string {
    return this.name
  }

  public get id(): string {
    return this.token
  }

  public get key(): string {
    return this.token
  }

  @computed
  public get name(): string {
    if (!this.firstName || !this.lastName) return this.email
    const name = this.firstName + ' ' + this.lastName
    return name
  }

  public toDTO(): IAnonymousParticipantDTO {
    return {
      email: this.email,
      token: this.token,
      firstName: this.firstName,
      lastName: this.lastName,
      language: this.language,
      id: undefined,
      isArchived: this.isArchived,
    }
  }
}
