import { CellClickedEvent, ColDef, GridOptions, RowClickedEvent } from 'ag-grid-community'
import { action, computed, observable } from 'mobx'
import moment from 'moment'
import { AGGridVM } from '../../../shared/ag-grid/AGGridVM'
import { nameOf } from '../../../shared/nameOf'
import { RootStore } from '../../../stores/RootStore'
import { ActiveUserSurveyRowVM } from './ActiveUserSurveyRowVM'
import { CompletedSurveyResponseRowVM } from './CompletedSurveyResponseRowVM'
import { CompletedSurveyResponsesDataStore } from './CompletedSurveyResponsesDataStore'
import { MySurveysWidgetVM } from './MySurveysWidgetVM'

export class CompletedSurveyResponsesTabVM extends AGGridVM {
  private rootStore: RootStore
  private dataStore: CompletedSurveyResponsesDataStore
  @observable private shownOnce: boolean = false

  constructor(rootStore: RootStore, widgetVM: MySurveysWidgetVM) {
    super()
    this.rootStore = rootStore
    this.widgetVM = widgetVM
    this.dataStore = new CompletedSurveyResponsesDataStore(this.rootStore)
    this.serverSideLoaded = true
  }

  public widgetVM: MySurveysWidgetVM
  public tabIndex: number = 1

  public getRowNodeId(row: ActiveUserSurveyRowVM): string {
    return row.objectId
  }

  @action
  private setShownOnce() {
    this.shownOnce = true
  }

  @computed
  public get shown(): boolean {
    return this.widgetVM.tabIndex === this.tabIndex
  }

  @computed
  public get shouldRenderGrid(): boolean {
    if (this.shownOnce) return true
    if (this.shown) return true
    return false
  }

  public get shouldRender(): boolean {
    // if (!this.rootStore.rolesStore.loaded) return false
    // if (!this.rootStore.groupsStore.loaded) return false
    return true
  }

  @computed
  public get columnDefs(): ColDef[] {
    const s = this.rootStore.localizationStore.lzStrings
    return [
      {
        headerName: s.mySurveysWidget.responded,
        field: nameOf<CompletedSurveyResponseRowVM, string>((e) => e.respondedAtFormatted),
        comparator: this.dateComparator,
        onCellClicked: (e: CellClickedEvent) => {
          const rowVM: CompletedSurveyResponseRowVM = e.data
          if (rowVM && rowVM.openResultsOrResponse) rowVM.openResultsOrResponse()
        },
      },
      {
        headerName: s.mySurveysWidget.name,
        sortable: false,
        field: nameOf<CompletedSurveyResponseRowVM, string>((e) => e.name),
        onCellClicked: (e: CellClickedEvent) => {
          const rowVM: CompletedSurveyResponseRowVM = e.data
          if (rowVM && rowVM.openResultsOrResponse) rowVM.openResultsOrResponse()
        },
      },
      {
        headerName: s.mySurveysWidget.from,
        sortable: false,
        field: nameOf<CompletedSurveyResponseRowVM, string>((e) => e.fromUserName),
        cellRenderer: 'fromCell',
        onCellClicked: (e: CellClickedEvent) => {
          const rowVM: CompletedSurveyResponseRowVM = e.data
          if (rowVM && rowVM.openResultsOrResponse) rowVM.openResultsOrResponse()
        },
      },
      // {
      //   headerName: s.mySurveysWidget.questions,
      //   sortable: false,
      //   field: nameOf<CompletedSurveyResponseRowVM, number>((e) => e.questionsCount),
      // },
      // {
      //   headerName: s.mySurveysWidget.categories,
      //   sortable: false,
      //   field: nameOf<CompletedSurveyResponseRowVM, string>((e) => e.categories),
      // },
      {
        headerName: s.mySurveysWidget.action,
        cellRenderer: 'actionsCell',
        sortable: false,
        onCellClicked: null,
      },
    ]
  }

  @action
  public comparator(a, b) {
    if (a === '-' && b !== '-') return 1
    if (a !== '-' && b === '-') return -1
    if (a === '-' && b === '-') return 0
    let momentA = moment(a)
    let momentB = moment(b)
    if (momentA.isBefore(momentB)) return 1
    else if (momentA.isSame(momentB)) return 0
    else if (momentA.isAfter(momentB)) return -1
  }

  public getGridOptions(): GridOptions {
    return {
      ...this.baseGridOptions,
      onRowClicked: (e) => this.rowClicked(e),
      getRowNodeId: (e) => this.getRowNodeId(e),
      columnDefs: this.ensureTooltipFields(this.columnDefs),
    }
  }

  // @action
  // public setQuickFilter(val: string) {
  //   this.typedFilterText = val
  //   if (this.quickFilterTO) clearTimeout(this.quickFilterTO)
  //   this.quickFilterTO = setTimeout(() => this.applyFilter(), 1000)
  // }

  // private applyFilter() {
  //   this.dataStore.setFilter(this.typedFilterText)
  //   this.reload()
  // }

  protected onGridReadied() {
    this.gridApi.setDatasource({
      rowCount: this.totalRecords,
      getRows: async (params) => {
        this.setShownOnce()
        if (params.startRow === 0) this.dataStore.setPage(0)
        await this.dataStore.getNextPage()
        const rows = this.dataStore.rows.map(
          (e) => new CompletedSurveyResponseRowVM(this.rootStore, e, this)
        )
        params.successCallback(rows, this.dataStore.totalRecords)
      },
    })
  }

  public get rows(): CompletedSurveyResponseRowVM[] {
    return []
  }

  public rowClicked(e: RowClickedEvent) {
    console.debug(e)
  }

  @computed
  protected get totalRecords() {
    return this.dataStore.totalRecords
  }

  @computed
  public get hasRows(): boolean {
    return this.dataStore.hasListRecords
  }

  @computed
  public get noRowsToShowLabel() {
    return this.rootStore.localizationStore.lzStrings.dashboard.no_rows_to_show
  }
}
