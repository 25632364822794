import { observable, action, computed } from 'mobx'
import RGLItem from './RGLItem'
import { IDashboardDTO } from '../dtos/IDashboardDTO'
import { IAggregate } from 'src/app/shared/data/IAggregate'
import { serializable, serialize, deserialize, list, object } from 'serializr'
import { DashboardType } from '../types/DashboardType'
import { Participant } from './Participant'

export default class Dashboard implements IDashboardDTO, IAggregate {
  static create(orgId, userId) {
    const dashboard = new Dashboard()
    dashboard.organizationId = orgId
    dashboard.userId = userId
    return dashboard
  }

  @serializable @observable objectId: string = ''
  @serializable @observable organizationId: string = ''
  @serializable @observable type: DashboardType = 'custom'
  @serializable @observable name: string = ''
  @serializable(list(object(Participant))) @observable sharedWith: Array<Participant> = []
  @serializable @observable userId: string = ''
  @serializable(list(object(RGLItem))) @observable lgLayout: Array<RGLItem> = []
  @serializable @observable isDeleted: boolean = false
  isOnServer: boolean = false

  @action
  public setName(val: string) {
    this.name = val
  }

  setSharedWith(sharedWith: Participant[]) {
    this.sharedWith = sharedWith
  }

  setLayout(layout: RGLItem[]) {
    this.lgLayout = layout
  }

  markIsNotOnServer() {
    this.isOnServer = false
  }

  markIsOnServer() {
    this.isOnServer = true
  }

  public clone(): Dashboard {
    return deserialize(Dashboard, this.serialize())
  }

  public serialize(): IDashboardDTO {
    const obj = serialize(this)
    return obj
  }
}
