import React from 'react'
import { inject, observer, useLocalStore } from 'mobx-react'
import { Button, Card, CardHeader, Grid, IconButton, Tooltip } from '@material-ui/core'
import './FilterBar.scss'
import Searchbar from '../../../../shared/Searchbar'
import CheckIcon from '@material-ui/icons/Check'
import DateRangeModal from '../../../../surveys/views/manage-surveys-widget/DateRangeModal/DateRangeModal'
import { FilterBarVM } from '../../../view-models/dashboard/all-campaigns/FilterBarVM'
import EventIcon from '@material-ui/icons/Event'
import CloseIcon from '@material-ui/icons/Close'
import LocalizationStore from '../../../../localization/LocalizationStore'

interface Props {
  vm: FilterBarVM
  localizationStore?: LocalizationStore
}

const FilterBar: React.FC<Props> = ({ vm, localizationStore }) => {
  if (!vm) return null
  const lz = localizationStore.lzStrings.pulseCampaignDashboard

  const renderFilterBar = () => {
    return (
      <div className={'filter-bar'}>
        <div className={'filter-bar-left'}>
          {renderStatusButtons()}
          {renderDateRangeButton()}
          {renderDateRangeModal()}
        </div>
        {renderSearch()}
      </div>
    )
  }

  const renderSearch = () => {
    return (
      <Searchbar
        containerStyle={{
          backgroundColor: '#DCE1ED',
          color: 'black',
          height: '36px',
          borderRadius: '4px',
        }}
        focused={vm.typedFilterText.length > 0}
        className='search'
        searchValue={vm.typedFilterText}
        onSearchChange={(e) => {
          vm.setQuickFilter(e.target.value)
        }}
      />
    )
  }

  const renderStatusButtons = () => {
    return (
      <div className={'status-btns'}>
        {renderInitiatedButton()}
        {renderCompletedButton()}
      </div>
    )
  }

  const renderInitiatedButton = () => {
    if (vm.isInitiatedSelected) {
      return (
        <Button
          className={`tab-btn-selected border-status-initiated`}
          onClick={() => vm.toggleStatusSelection('initiated')}
        >
          {lz.initiated}
          <CheckIcon className='check-icon' />
        </Button>
      )
    } else {
      return (
        <Button
          className={`tab-btn-unselected border-status-initiated`}
          onClick={() => vm.toggleStatusSelection('initiated')}
        >
          {lz.initiated}
        </Button>
      )
    }
  }

  const renderCompletedButton = () => {
    if (vm.isCompletedSelected) {
      return (
        <Button
          className={`tab-btn-selected border-status-completed`}
          onClick={() => vm.toggleStatusSelection('completed')}
        >
          {lz.completed}
          <CheckIcon className='check-icon' />
        </Button>
      )
    } else {
      return (
        <Button
          className={`tab-btn-unselected border-status-completed`}
          onClick={() => vm.toggleStatusSelection('completed')}
        >
          {lz.completed}
        </Button>
      )
    }
  }

  const renderDateRangeButton = () => {
    return (
      <div className={'date-range-btns'}>
        <Button className={`date-range-btn`} onClick={() => vm.toggleDateRangeModal()}>
          <EventIcon className='event-icon' />
          {vm.dateRangeButtonText}
        </Button>
        {vm.hasDateRange && (
          <>
            <Tooltip title={lz.clear_date_range} placement='bottom' enterDelay={100}>
              <IconButton className={'clear-date-range-btn'} onClick={() => vm.clearDateRange()}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
      </div>
    )
  }

  const renderDateRangeModal = () => {
    return (
      <DateRangeModal
        onChange={(range) => {
          vm.setDateRange(range)
          // vm.onFilterChanged()
        }}
        range={vm.dateRange}
        open={vm.dateRangeModalOpen}
        onClose={() => vm.toggleDateRangeModal()}
      />
    )
  }

  return <div id='FilterBar'>{renderFilterBar()}</div>
}

export default inject('localizationStore')(observer(FilterBar))
