import { observable, computed, action } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { ResponseType } from '../types/ResponseType'
import { QuestionVM } from './QuestionVM'
import { IOptionDTO } from '../dtos/IOptionDTO'
import { Option } from '../aggregate/Option'
import { SurveyEditVM } from './SurveyEditVM'
import { QuestionType } from '../types/QuestionType'

export class OptionVM {
  private rootStore: RootStore
  private forQuestion: QuestionVM
  private editVM: SurveyEditVM

  constructor(
    rootStore: RootStore,
    editVM: SurveyEditVM,
    forQuestion: QuestionVM = null,
    option = null
  ) {
    this.rootStore = rootStore
    this.forQuestion = forQuestion
    this.option = option
    this.editVM = editVM
  }

  @observable public option: Option
  @observable saveTried: boolean = false
  rect: ClientRect | DOMRect = null

  @computed
  public get text(): string {
    return this.option.text
  }

  @computed
  public get subText(): string {
    return this.option.subText
  }

  @computed
  public get id(): string {
    return this.option.id
  }

  @computed
  public get rank(): number {
    return this.option.rank
  }

  @computed
  public get responseType(): ResponseType {
    return this.option.responseType
  }

  @computed
  public get responseNumber(): number {
    return this.option.responseNumber
  }

  @computed
  public get emoji(): string {
    return this.option.emoji
  }

  @computed
  public get nextQuestionId(): string {
    return this.option.nextQuestionId
  }

  @computed
  public get goToEnd(): boolean {
    return this.option.goToEnd
  }

  @computed
  public get weight(): number {
    return this.option.weight
  }

  @computed
  public get optionlessBranch(): string {
    if (this.goToEnd) return 'Go To End'
    if (!this.nextQuestionId && !this.goToEnd) return 'Default: Next Question'
    return this.nextQuestionId
  }

  @computed
  public get nextQuestions(): QuestionVM[] {
    const questions = []
    this.editVM.questions.forEach((q) => {
      if (q.rank > this.forQuestion.rank) questions.push(q)
    })
    return questions
  }

  @action
  public setWeight(val) {
    this.option.setWeight(val)
  }

  @action
  public setValue(val) {
    this.option.setValue(val)
  }

  @computed
  public get weightValid() {
    const val = this.option.weight
    if (val != Math.floor(val)) return false
    if (val < 0) return false
    return true
  }

  @action
  public setResponseType(val: ResponseType) {
    this.option.setResponseType(val)
  }

  @action
  public setOptionlessBranching(val) {
    if (val === 'Go To End') {
      this.option.setGoToEnd(true)
      this.option.setNextQuestionId(null)
    } else if (val === 'Default: Next Question') {
      this.setNextQuestion(null)
      this.option.setGoToEnd(false)
    } else {
      this.setNextQuestion(val)
      this.option.setGoToEnd(false)
    }
    this.editVM.resetDiagram()
  }

  @action
  public setNextQuestion(id) {
    if (this.forQuestion.type === 'check') {
      this.forQuestion.handleMultiSelectBranching(id, this)
      if (!id) this.forQuestion.hideMultiSelectBranchMessage()
    }
    this.option.setNextQuestionId(id)
    this.option.setGoToEnd(false)
    this.editVM.resetDiagram()
  }

  @action
  public setGoToEnd() {
    if (this.forQuestion.type === 'check') {
      this.forQuestion.options.forEach((e) => {
        e.option.setNextQuestionId(null)
        e.option.setGoToEnd(false)
      })
    }
    this.option.setGoToEnd(true)
    this.option.setNextQuestionId(null)
    this.editVM.resetDiagram()
  }

  @computed
  public get nextQuestion() {
    if (!this.rootStore) return null
    if (this.nextQuestionId === null) return null
    const q = this.editVM.questions.find((q) => q.id === this.nextQuestionId)
    if (!q) {
      console.log('next question not found: ' + this.nextQuestionId)
      console.log(this.editVM.questions)
      return null
    }
    return q
  }

  @computed
  public get isValid() {
    if (!this.saveTried) return true
    if (!this.textValid) return false
    return true
  }

  @computed
  public get textValid() {
    if (!this.saveTried) return true
    if (!this.text || this.text === '') return false
    return true
  }

  @computed
  public get type(): QuestionType {
    return this.forQuestion.type
  }

  public setRect(rect: ClientRect | DOMRect) {
    // if (this.rect) return
    this.rect = rect
    // this.rootStore.surveysStore.editForm.getDiagramPaths()
  }

  @action
  public setRank(val: number) {
    this.option.setRank(val)
  }

  @action
  public setText(val) {
    this.option.setText(val)
  }

  @action
  public setSubText(val) {
    this.option.setSubText(val)
  }

  @action
  public setEmoji(val) {
    this.option.setEmoji(val)
  }

  @action
  public clearRect() {
    this.rect = null
  }

  @computed
  public get icon(): string {
    switch (this.forQuestion.type) {
      case 'emoji':
        return null
      case 'rating':
        return null
      case 'starRating':
        return null
      case 'radio':
        return 'far fa-dot-circle'
      case 'check':
        return 'far fa-check-square'
      case 'yesNo':
        return 'far fa-dot-circle'
      case 'text':
        return null
      case 'dropdown':
        return 'fas fa-caret-square-down'
      default:
        return null
    }
  }

  public toDTO(): IOptionDTO {
    return this.option.toDTO()
  }
}
