import React from 'react'
import clsx from 'clsx'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { Avatar, Drawer, IconButton } from '@material-ui/core/'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import './PersistentDrawer.scss'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerOpen: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      zIndex: theme.zIndex.drawer + 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
  })
)

export default function PersistentDrawer(props: any) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(true)

  const toggleDrawer = () => {
    setOpen(!open)
  }

  return (
    <div className={'summary-drawer-root'}>
      <Drawer
        variant='permanent'
        anchor='right'
        className={clsx({
          ['summary-drawer']: true,
          ['summary-drawer-open']: open,
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            ['summary-drawer']: true,
            ['summary-drawer-open']: open,
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton
            aria-label='question-card-header-action-copy'
            className={'question-card-btn'}
            onClick={toggleDrawer}
          >
            <Avatar className={'question-card-btn-avatar'}>
              {open ? <ChevronRightIcon fontSize='small' /> : <ChevronLeftIcon fontSize='small' />}
            </Avatar>
          </IconButton>
        </div>
        {open ? props.children : undefined}
      </Drawer>
    </div>
  )
}
