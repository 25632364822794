import { computed, action } from 'mobx'
import { Contact } from '../../contacts/aggregate/Contact'
import { RootStore } from '../../stores/RootStore'
import { ParticipantsSelectVM } from './ParticipantsSelectVM'

export class ContactRowVM {
  private rootStore: RootStore
  private selectVM: ParticipantsSelectVM
  private contact: Contact

  constructor(rootStore: RootStore, selectVM: ParticipantsSelectVM, contact: Contact) {
    this.contact = contact
    this.rootStore = rootStore
    this.selectVM = selectVM
  }

  @computed
  public get objectId(): string {
    return this.contact.objectId
  }

  @computed
  public get name(): string {
    return this.contact.name
  }

  @computed
  public get iconURL(): string {
    return ''
    // return this.user.iconURL
  }

  @computed
  public get groups(): string[] {
    return [] // TODO: implement fk_groups
  }

  @computed
  public get roles(): string[] {
    return [] // TODO: implement fk_roles
  }

  @computed
  public get filterSubstring(): string {
    if (!this.filteredGroupsAndRoles) return ''
    const names = []
    this.selectVM.filteredGroups.forEach((group) => {
      if (this.groups.some((name) => name === group.name)) names.push(group.name)
    })
    this.selectVM.filteredRoles.forEach((role) => {
      if (this.roles.some((name) => name === role.name)) names.push(role.name)
    })

    return names.join(', ')
  }

  @computed
  public get filteredGroupsAndRoles(): boolean {
    if (this.selectVM.filteredGroups.length > 0) return true
    if (this.selectVM.filteredRoles.length > 0) return true
    return false
  }

  @computed
  public get isReadOnly(): boolean {
    return this.selectVM.isReadOnly
  }

  @computed
  public get isParticipantSelected(): boolean {
    const isSelectedDirectly =
      this.selectVM.participants.filter((e) => e.id === this.objectId).length !== 0
    let isSelectedIndirectly = false
    this.selectVM.clientsList
    this.selectVM.selectedRoles.forEach((e) => {
      if (
        this.rootStore.audienceMembersStore
          .currentOrgUsersInRole(e.id)
          .find((e) => e.objectId === this.objectId)
      )
        isSelectedIndirectly = true
    })

    this.selectVM.selectedEvents.forEach((e) => {
      if (this.selectVM.getUsersByEventGroup(e.id).find((e) => e.objectId === this.objectId))
        isSelectedIndirectly = true
    })

    this.selectVM.selectedGroups.forEach((e) => {
      if (
        this.rootStore.audienceMembersStore
          .currentOrgUsersInGroup(e.id)
          .filter((e) => e.objectId === this.objectId).length !== 0
      )
        isSelectedIndirectly = true
    })
    this.selectVM.selectedClients.forEach((e) => {
      if (
        this.rootStore.contactsStore
          .getContactsByClient(e.id)
          .filter((e) => e.objectId === this.objectId).length !== 0
      )
        isSelectedIndirectly = true
    })
    return isSelectedDirectly || isSelectedIndirectly
  }

  public get rowNodeId() {
    return this.objectId
  }

  @action
  public click() {
    const idx = this.selectVM.participants.findIndex((e) => e.id === this.objectId)
    if (idx === -1) {
      this.selectVM.addParticipant(this.contact.objectId, this.contact.name, 'contact')
    } else {
      this.selectVM.deleteParticipant(this.objectId)
    }
  }
}
