import Parse from 'parse'
import { ITrainingPlanFlowsUsersBulkOpsRequest } from '../interfaces/ITrainingPlanFlowsUsersBulkOpsRequest'
import { ITrainingPlanFlowsUsersBulkOpsResponse } from '../interfaces/ITrainingPlanFlowsUsersBulkOpsResponse'

export class TrainingPlanFlowBulkOperationsService {
  public async startTrainingPlanFlowUsersBulkOps(
    req: ITrainingPlanFlowsUsersBulkOpsRequest
  ): Promise<ITrainingPlanFlowsUsersBulkOpsResponse> {
    return await Parse.Cloud.run('startTrainingPlanFlowUsersBulkOps', { req })
  }

  public async cancelTrainingPlanFlowUsersBulkOps(
    req: ITrainingPlanFlowsUsersBulkOpsRequest
  ): Promise<ITrainingPlanFlowsUsersBulkOpsResponse> {
    return await Parse.Cloud.run('cancelTrainingPlanFlowUsersBulkOps', { req })
  }

}
