import React from 'react'
import WidgetHeader from './WidgetHeader'
import WidgetContent from './WidgetContent'
import { Card } from '@material-ui/core'
import './TasksManagementWidget.scss'
import { TasksStore } from '../../store/TasksStore'
import { observer, inject } from 'mobx-react'
import Drawer from '../drawer/Drawer'

interface Props {
  tasksStore?: TasksStore
}

const TasksManagementWidget: React.FC<Props> = ({ tasksStore }) => {
  const { taskManagementWidgetVM: vm } = tasksStore
  if (!vm) return null
  if (!vm.hasWorkAccess) return null
  if (!vm.shouldRender) return null

  return (
    <>
      <Card elevation={0} id='TasksManagementWidget'>
        <WidgetHeader />
        <WidgetContent />
      </Card>
      <Drawer />
    </>
  )
}

export default inject('tasksStore')(observer(TasksManagementWidget))
