import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import MuiConfirmPrompt from '../../../shared/MuiConfirmPrompt'
import { EmailTemplatePreviewVM } from '../../view-models/EmailTemplatePreviewVM'

interface Props {
  vm: EmailTemplatePreviewVM
  localizationStore?: LocalizationStore
}

const EmailTemplateConfirmDialog: React.FC<Props> = ({ vm, localizationStore }) => {
  const { emailTemplateManagement: lz } = localizationStore.lzStrings

  if (!vm) return null

  if (!vm.confirmDialogOpen) return
  if (vm.doReset && vm.isDirty) {
    return (
      <MuiConfirmPrompt
        confirmMessage={lz.are_you_sure_reset}
        onClose={() => vm.cancelConfirmDialog()}
        open={vm.confirmDialogOpen}
        onConfirm={() => vm.resetTemplateEditor()}
        title={`${lz.reset} ${lz.email_template}`}
      />
    )
  }
  if (vm.doClose && vm.isDirty) {
    return (
      <MuiConfirmPrompt
        confirmMessage={lz.are_you_sure_close}
        onClose={() => vm.cancelConfirmDialog()}
        open={vm.confirmDialogOpen}
        onConfirm={() => vm.close()}
        title={`${lz.close} ${lz.email_template}`}
      />
    )
  }
  return (
    <MuiConfirmPrompt
      confirmMessage={lz.are_you_sure_save}
      onClose={() => vm.cancelConfirmDialog()}
      open={vm.confirmDialogOpen}
      onConfirm={() => vm.save()}
      title={`${lz.save} ${lz.email_template}`}
      isSystemWarn={vm.isSystemTemplate}
    />
  )
}

export default inject('localizationStore')(observer(EmailTemplateConfirmDialog))
