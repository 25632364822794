import React from 'react'
import { inject, observer, useLocalStore } from 'mobx-react'
import { SurveyTypesManageVM } from '../../view-models/SurveyTypesManageVM'
import rootStore from '../../../stores/RootStore'
import TopToolbar from './TopToolbar'
import { Card, CardContent, CardHeader, IconButton, Tooltip, Typography } from '@material-ui/core'
import SurveyTypesTable from './SurveyTypesTable'
import Searchbar from '../../../shared/Searchbar'
import AddIcon from '@material-ui/icons/Add'
import LocalizationStore from '../../../localization/LocalizationStore'
import { ScreenType } from '../../types/ScreenType'

interface Props {
  localizationStore?: LocalizationStore
  screenType: ScreenType
}

const SurveyTypesSAManage: React.FC<Props> = ({ localizationStore, screenType }) => {
  const localStore = useLocalStore(() => ({
    vm: new SurveyTypesManageVM(rootStore, screenType),
  }))

  const vm = localStore.vm
  const tableVM = vm.tableVM
  const { lzStrings } = localizationStore

  return (
    <div id='SurveyTypesManage'>
      <TopToolbar vm={vm} />
      <>
        <Card elevation={0} id='SurveyTypesTable'>
          <CardHeader
            id='SurveyTypesTableHeader'
            action={
              <div className={'header-actions'}>
                <>
                  <div id='SurveyTypesTableSearch'>
                    <Searchbar
                      focused={tableVM.typedFilterText.length > 0}
                      className='search'
                      searchValue={tableVM.typedFilterText}
                      onSearchChange={(e) => tableVM.setQuickFilter(e.target.value)}
                    />
                  </div>
                </>
                <div className='action-btn'>
                  <React.Fragment>
                    <Tooltip
                      title={`${
                        lzStrings.surveyTypesWidget.add_new_
                      } Survey ${
                        lzStrings.surveyTypesWidget._type
                      }`}
                      placement='bottom'
                    >
                      <IconButton
                        className='widget-cta'
                        size='small'
                        onClick={() => vm.navigateToSurveyTypeEdit()}
                      >
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                  </React.Fragment>
                </div>
              </div>
            }
            title={<Typography className='type-title'>{`Survey Types`}</Typography>}
          />
          <CardContent className='no-padding'>
            <SurveyTypesTable vm={tableVM} />
          </CardContent>
        </Card>
      </>
    </div>
  )
}

export default inject('localizationStore')(observer(SurveyTypesSAManage))
