import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { TasksStore } from '../../store/TasksStore'
import { CardHeader, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

interface DrawerHeaderProps {
  localizationStore?: LocalizationStore
  tasksStore?: TasksStore
}

const DrawerHeader: React.FC<DrawerHeaderProps> = ({ localizationStore, tasksStore }) => {
  const { lzStrings } = localizationStore

  const handleClose = () => {
    tasksStore.closeDrawer()
  }

  return (
    <CardHeader
      action={
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      }
      title={
        tasksStore.taskEditVM.objectId
          ? lzStrings.tasksManagementWidget.edit_task
          : lzStrings.tasksManagementWidget.add_task
      }
    />
  )
}

export default inject('localizationStore', 'tasksStore')(observer(DrawerHeader))
