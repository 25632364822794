import React from 'react'
import { inject, observer } from 'mobx-react'
import { NotificationsStore } from '../store/NotificationsStore'
import LocalizationStore from '../../localization/LocalizationStore'
import { Card } from '@material-ui/core'
import { NotificationsWidgetVM } from '../store/view-models/NotificationsWidgetVM'
import Header from './Header'
import Content from './Content'
import './NotificationTemplatesCard.scss'

interface Props {
  notificationsStore?: NotificationsStore
  localizationStore?: LocalizationStore
  vm?: NotificationsWidgetVM
}

const NotificationTemplatesCard: React.FC<Props> = ({
  notificationsStore,
  localizationStore,
  vm,
}) => {
  if (!vm) return

  return (
    <>
      <Card elevation={0} id='NotificationTemplatesCard'>
        <Header vm={vm} />
        <Content vm={vm} />
      </Card>
    </>
  )
}

export default inject(
  'localizationStore',
  'notificationsStore'
)(observer(NotificationTemplatesCard))
