import { ColDef, GridOptions, RowClickedEvent } from 'ag-grid-community'
import { action, computed, observable } from 'mobx'
import { AGGridVM } from '../../shared/ag-grid/AGGridVM'
import { nameOf } from '../../shared/nameOf'
import { RootStore } from '../../stores/RootStore'
import { ClientRowVM } from './ClientRowVM'
import { ContactRowVM } from './ContactRowVM'
import { ContactsListDataStore } from './ContactsListDataStore'
import { ParticipantsSelectVM } from './ParticipantsSelectVM'

export class ContactsAGGridVM extends AGGridVM {
  private rootStore: RootStore
  private parentVM: ParticipantsSelectVM
  private dataStore: ContactsListDataStore
  private forClientRow: ClientRowVM

  constructor(rootStore: RootStore, parentVM: ParticipantsSelectVM, forClientRow: ClientRowVM) {
    super()
    this.rootStore = rootStore
    this.parentVM = parentVM
    this.forClientRow = forClientRow
    this.dataStore = new ContactsListDataStore(this.rootStore, this.forClientRow)
    this.sizeColumnsToFit = false
    this.serverSideLoaded = true
  }

  @observable public shown: boolean = false

  @computed
  public get hasFilter(): boolean {
    return this.dataStore.hasFilter
  }

  public getRowNodeId(row: ContactRowVM): string {
    return row.rowNodeId
  }

  public get shouldRender(): boolean {
    if (!this.rootStore.rolesStore.loaded) return false
    if (!this.rootStore.groupsStore.loaded) return false
    return true
  }

  @computed
  public get columnDefs(): ColDef[] {
    const s = this.rootStore.localizationStore.lzStrings
    return [
      {
        headerName: s.orgUsersTableWidget.name,
        field: nameOf<ContactRowVM, string>((e) => e.name),
        sort: 'asc',
        cellRenderer: 'nameCell',
        width: 440,
      },
    ]
  }

  public getGridOptions(): GridOptions {
    return {
      ...this.baseGridOptions,
      onRowClicked: (e) => this.rowClicked(e),
      getRowNodeId: (e) => this.getRowNodeId(e),
      columnDefs: this.ensureTooltipFields(this.columnDefs),
      pagination: false,
      headerHeight: 0,
      rowHeight: 44,
    }
  }

  @action
  public setQuickFilter(val: string) {
    this.typedFilterText = val
    if (this.quickFilterTO) clearTimeout(this.quickFilterTO)
    this.quickFilterTO = setTimeout(() => this.applyFilter(), 1000)
  }

  private applyFilter() {
    this.dataStore.setFilter(this.typedFilterText)
    this.reload()
    this.columnApi.applyColumnState({
      state: [{ colId: 'name', sort: 'asc' }],
    })
  }

  protected onGridReadied() {
    this.gridApi.setDatasource({
      rowCount: this.totalRecords,
      getRows: async (params) => {
        params.sortModel.forEach((col: { colId: string; sort: 'asc' | 'desc' }, idx: number) => {
          let dbCol = col.colId
          if (idx === 0) this.dataStore.setSort(dbCol, col.sort)
          if (idx > 0) this.dataStore.addSort(dbCol, col.sort)
        })
        if (params.sortModel.length === 0) this.dataStore.setSort('name')
        if (params.startRow === 0) this.dataStore.setPage(0)
        await this.dataStore.getNextPage()
        const rows = this.dataStore.contacts.map(
          (e) => new ContactRowVM(this.rootStore, this.parentVM, e)
        )
        params.successCallback(rows, this.dataStore.totalRecords)
      },
    })
  }

  public get rows(): ContactRowVM[] {
    return []
  }

  public rowClicked(e: RowClickedEvent) {
    const row: ContactRowVM = e.data
    row.click()
  }

  @computed
  protected get totalRecords() {
    return this.dataStore.totalRecords
  }

  @action
  public show() {
    this.shown = true
  }

  @action
  public hide() {
    this.shown = false
  }
}
