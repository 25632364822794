import React, { FC } from 'react'
import { inject, observer } from 'mobx-react'
import {
  Button,
  Grid,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
  Divider,
} from '@material-ui/core'
import SendRoundedIcon from '@material-ui/icons/SendRounded'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import { EmailTemplatePreviewVM } from '../../view-models/EmailTemplatePreviewVM'
import './EmailTemplateSettingsForm.scss'

interface Props {
  vm: EmailTemplatePreviewVM
  localizationStore: LocalizationStore
}

const EmailTemplateSettingsForm: FC<Props> = ({ vm, localizationStore }) => {
  const { emailTemplateManagement: lz } = localizationStore.lzStrings

  if (!vm) return

  const onNameChange = (e) => {
    vm.setPreviewName(e.target.value)
  }

  const onSubjectChange = (e) => {
    vm.setPreviewSubject(e.target.value)
  }

  const onSendFromEmailChange = (e) => {
    vm.setPreviewFromEmail(e.target.value)
  }

  const onSendToEmailChange = (e) => {
    vm.setPreviewToEmail(e.target.value)
  }

  const handleSendTestEmail = () => {
    vm.sendTestEmail()
  }

  const renderSendTestEmailButton = () => {
    if (!vm.canManage) return
    return (
      <Tooltip title={lz.send_test_email_info} placement='bottom-start' arrow>
        <span>
          <Button
            className='email-template-dialog-action'
            variant='contained'
            onClick={handleSendTestEmail}
            size='small'
            startIcon={<SendRoundedIcon />}
            disabled={vm.isLoading || vm.isSaving || vm.isSending}
          >
            {`${lz.send} ${lz.test_email}`}
          </Button>
        </span>
      </Tooltip>
    )
  }

  const renderGeneralSettings = () => {
    return (
      <div id='templateGeneral'>
        <Typography component='h6' className='settings-group-label'>
          {lz.general_settings}
        </Typography>
        <Grid container spacing={2} direction='column' className='settings-group'>
          <Grid item sm={12}>
            <InputLabel required htmlFor='email-template-name'>
              {lz.template_name}
            </InputLabel>
            <TextField
              error={!vm.emailTemplateNameValid}
              value={vm.name}
              onChange={onNameChange}
              fullWidth
              variant='outlined'
              id='email-template-name'
              disabled={vm.isLoading || vm.isSaving}
            />
          </Grid>
          <Grid item sm={12}>
            <InputLabel required htmlFor='email-template-subject'>
              {lz.template_subject}
            </InputLabel>
            <TextField
              error={!vm.emailTemplateSubjectValid}
              value={vm.subject}
              onChange={onSubjectChange}
              fullWidth
              variant='outlined'
              id='email-template-subject'
              disabled={vm.isLoading || vm.isSaving}
            />
          </Grid>
        </Grid>
      </div>
    )
  }

  const renderSendTestEmail = () => {
    return (
      <div id='templateAdvanced'>
        <Typography component='h6' className='settings-group-label'>
          {lz.test_email}
        </Typography>
        <Grid container spacing={2} direction='column' className='settings-group'>
          <Grid item sm={12}>
            <InputLabel required htmlFor='email-template-from-address'>
              {lz.from_address}
            </InputLabel>
            <TextField
              error={!vm.fromEmailValid}
              value={vm.previewFromEmail}
              type='text'
              autoComplete='off'
              InputProps={{
                autoComplete: 'off',
              }}
              onChange={onSendFromEmailChange}
              fullWidth
              variant='outlined'
              id='email-template-from-address'
              disabled={vm.isLoading || vm.isSaving || vm.isSending}
            />
          </Grid>
          <Grid item sm={12}>
            <InputLabel required htmlFor='email-template-to-address'>
              {lz.to_address}
            </InputLabel>
            <TextField
              error={!vm.toEmailValid}
              value={vm.previewToEmail}
              type='text'
              autoComplete='off'
              InputProps={{
                autoComplete: 'off',
              }}
              onChange={onSendToEmailChange}
              placeholder={lz.email_address}
              fullWidth
              variant='outlined'
              id='email-template-to-address'
              disabled={vm.isLoading || vm.isSaving || vm.isSending}
            />
          </Grid>
          <Grid item sm={12} className='send-test-email-button'>
            {renderSendTestEmailButton()}
          </Grid>
        </Grid>
      </div>
    )
  }

  return (
    <div id='EmailTemplateSettingsForm'>
      <form autoComplete='off' noValidate>
        {renderGeneralSettings()}
        <Divider variant='fullWidth' />
        {renderSendTestEmail()}
      </form>
    </div>
  )
}

export default inject('usersStore', 'organizationsStore')(observer(EmailTemplateSettingsForm))
