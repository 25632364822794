import { action, computed, observable } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { PulseQuestionsDataStore } from '../../../pulse-questions/view-models/PulseQuestionsDataStore'
import { PulseQuestionVM } from './PulseQuestionVM'
import { IPulseCategoryDTO } from '../../../pulse-categories/dtos/IPulseCategoryDTO'
import { IPulseQuestionDTO } from '../../../pulse-questions/dtos/IPulseQuestionDTO'
import { PulseCampaignSurveyEditVM } from '../PulseCampaignSurveyEditVM'
import PulseQuestion from '../../../pulse-questions/aggregate/PulseQuestion'
import { PulseCategoriesManageVM } from '../PulseCategoriesManageVM'

export class ChildCategoryRowVM {
  private rootStore: RootStore

  constructor(
    rootStore: RootStore,
    category: IPulseCategoryDTO,
    hierarchyString: string,
    manageVM: PulseCategoriesManageVM
  ) {
    this.rootStore = rootStore
    this.category = category
    this.hierarchyString = hierarchyString
    this.manageVM = manageVM
  }

  @observable public category: IPulseCategoryDTO = null
  @observable public isExpanded: boolean = false
  @observable public pulseQuestionsVMs: PulseQuestionVM[] = []
  @observable public manageVM: PulseCategoriesManageVM = null
  @observable public index: number = 0
  @observable public hierarchyString: string = ''

  @computed
  public get isBuild() {
    return this.manageVM.isBuild
  }

  @computed
  public get isLoaded(): boolean {
    return this.manageVM.pqDataStore.isLoaded
    // return this.dataStore.isLoaded
  }

  @computed
  public get name(): string {
    return this.category.name
  }

  @computed
  public get objectId(): string {
    return this.category.objectId
  }

  @computed
  public get grandParentPulseCategoryId(): string {
    return this.category.grandCategoryId
  }

  @computed
  public get parentPulseCategoryId(): string {
    return this.category.parentCategoryId
  }

  @computed
  public get pulseQuestions() {
    return this.manageVM.pqDataStore.rows.filter((e: IPulseQuestionDTO) => e.pulseCategoryId === this.objectId)
  }

  @computed
  public get pulseQuestionsCount() {
    return this.pulseQuestions.length
    // return this.dataStore ? this.dataStore.rows.length : 0
  }

  @computed
  public get pulseSelectedQuestionsCount() {
    if (this.isBuild) {
      return this.pulseQuestionRows.filter((row) =>
        this.currentSelectedQuestions.some((sel) => sel.objectId === row.objectId)
      ).length
    }
    return this.pulseQuestionRows.filter((p) => p.isSelected).length
  }

  @computed
  public get currentSurveyPulseSelectedQuestionsCount() {
    return this.pulseQuestionRows.filter((row) =>
      this.currentSurvey.questions.some((sel) => sel.originalPulseQuestionId === row.objectId)
    ).length
  }

  @computed
  public get currentSurveyIndex(): number {
    return this.rootStore.pulseCampaignsStore.viewModels.editVM.currentSurveyIndex
  }

  @computed
  public get currentSurvey(): PulseCampaignSurveyEditVM {
    if (this.currentSurveyIndex === -1) return
    if (!this.rootStore.pulseCampaignsStore.viewModels.editVM.surveys) return
    if (this.rootStore.pulseCampaignsStore.viewModels.editVM.surveys.length === 0) return
    return this.rootStore.pulseCampaignsStore.viewModels.editVM.surveys[this.currentSurveyIndex]
  }

  @computed
  public get currentSelectedQuestions(): PulseQuestion[] {
    return this.rootStore.pulseCampaignsStore.viewModels.editVM.pulseCampaign.questions
  }

  @computed
  public get pulseQuestionRows(): PulseQuestionVM[] {
    if (this.isBuild) {
      const surveyQuestions = this.currentSurvey ? this.currentSurvey.questions : []
      return this.pulseQuestions.map((row) => {
        const filter = surveyQuestions.filter((q) => q.originalPulseQuestionId === row.objectId)
        return new PulseQuestionVM(this.rootStore, this, row, filter.length > 0, this.isBuild)
      })
    } else {
      const existing = this.rootStore.pulseCampaignsStore.viewModels.editVM.pulseCampaign.questions
      return this.pulseQuestions.map((row) => {
        const rowExist = existing.find((q) => q.objectId === row.objectId)
        return new PulseQuestionVM(this.rootStore, this, row, Boolean(rowExist), this.isBuild)
      })
    }
  }

  @computed
  public get pulseQuestionRowsSelected(): PulseQuestionVM[] {
    return this.pulseQuestionRows.filter((p) => p.isSelected)
  }

  @action
  public setIndex(index: number) {
    this.index = index
  }

  @action
  public toggleIsExpanded(isExpanded: boolean) {
    this.isExpanded = isExpanded
  }

  @computed
  public get areAllQuestionsSelected() {
    return this.pulseQuestionRows.filter((p) => !p.isSelected).length === 0
  }

  @action
  public selectRecursiveCategoryQuestions() {
    this.pulseQuestions.forEach((r) => {
      this.rootStore.pulseCampaignsStore.viewModels.editVM.addQuestion(r)
    })
  }

  @action
  public unselectRecursiveCategoryQuestions() {
    this.pulseQuestions.forEach((r) => {
      this.rootStore.pulseCampaignsStore.viewModels.editVM.deleteQuestionById(r.objectId)
    })
  }

  @action
  public toggleVisibilityForRecursiveCategories(isExpanded: boolean) {
    this.toggleIsExpanded(isExpanded)
  }

  @computed
  public get tooltipString(): string {
    return `${this.hierarchyString} - ${this.name}`
  }
}
