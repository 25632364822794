import { observable, computed, action } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Survey } from '../aggregate/Survey'
import { ParticipantsSelectVM } from '../../participants-select/view-models/ParticipantsSelectVM'
import { PagedDataVM } from '../../shared/view-models/PagedDataVM'
import { IParticipant } from '../../participants-select/interfaces/IParticipant'
import { VisibleParticipantVM } from './VisibleParticipantVM'
import { SurveyEditVM } from './SurveyEditVM'
import { VisibleParticipant } from '../aggregate/VisibleParticipant'

export class VisibilityEditorVM {
  rootStore: RootStore
  survey: Survey

  constructor(rootStore: RootStore, survey: Survey) {
    this.rootStore = rootStore
    this.visibilityParticipantVM = new ParticipantsSelectVM(this.rootStore, false, false, true)
    this.visibilityParticipantVM.setVisible()
    this.survey = survey
    this.loadVMData(survey)
    this.visibilityParticipantVM.setAddParticipantCallback((e) => this.addParticipant(e))
  }

  private addParticipant(e: IParticipant) {
    this.survey.addVisibleParticipant(e)
  }

  @observable public showParticipantSelectModal: boolean = false
  @observable public visibleUsers: Array<any> = []
  @observable public visibilityParticipantVM: ParticipantsSelectVM = null
  @observable public participantsKeywordFilter: string = ''
  @observable public participantsLoaded: boolean = false

  private async loadVMData(survey: Survey) {
    if (this.rootStore.audienceMembersStore)
      await this.rootStore.audienceMembersStore.loadAudienceMembers()
    setTimeout(() => {
      if (survey.visibleParticipants.length)
        this.visibilityParticipantVM.setParticipants(survey.visibleParticipants)
      this.participantsLoaded = true
    }, 500)
  }

  @action
  public removeParticipantById(id: string) {
    const newParticipants = this.visibilityParticipantVM.participants.filter((p) => p.id !== id)
    this.visibilityParticipantVM.setParticipants(newParticipants)
    this.survey.updateVisibleParticipants(this.visibilityParticipantVM.allParticipants)
  }

  @computed
  public get selectedParticipantsPagedData() {
    return new PagedDataVM(
      this.rootStore,
      this.selectedParticipants,
      ['name'],
      '',
      'VisibilityEditor',
      this.participantsKeywordFilter
    )
  }

  @computed
  public get selectedParticipants() {
    return this.survey.visibleParticipants.map((e) => new VisibleParticipantVM(this.rootStore, e))
  }

  @computed
  public get selectedOptionValue() {
    return this.survey.visibility
  }

  @computed
  public get selectedVisibility() {
    return this.selectOptions.find((e) => e.value === this.survey.visibility)
  }

  @computed
  public get selectOptions() {
    return [
      {
        label: 'Default (Survey Owner and Manager',
        value: 'default',
      },
      {
        label: 'Private (Survey Owner Only)',
        value: 'private',
      },
      {
        label: 'Public (Everyone)',
        value: 'public',
      },
      {
        label: 'Specific Users',
        value: 'specific',
      },
    ]
  }

  @action
  public toggleShowParticipantSelect() {
    this.showParticipantSelectModal = !this.showParticipantSelectModal
    if (!this.showParticipantSelectModal) {
      this.visibilityParticipantVM.setHidden()
    } else {
      this.visibilityParticipantVM.setVisible()
    }
  }

  @action
  public setSelectedVisOption(val) {
    this.survey.setVisibility(val)
  }
}
