import { IFederatedGroupDTO } from 'src/app/federatedImports/dtos/IFederatedGroupDTO'
import { observable } from 'mobx'
import { serializable, deserialize, serialize, object, list, primitive } from 'serializr'

export class FederatedGroup implements IFederatedGroupDTO {
  public static create() {
    const group = new FederatedGroup()

    return group
  }
  @serializable @observable public name: string = ''
  @serializable @observable public externalId: string = ''
  @serializable @observable public organizationId: string
  @serializable @observable public errorMessage?: string
  @serializable @observable public jobExecutionId: string
  @serializable @observable public enabled: boolean = true

  public serialize(): IFederatedGroupDTO {
    return serialize(this)
  }
}
