import React from 'react'
import { inject, observer } from 'mobx-react'
import { TasksStore } from '../../store/TasksStore'
import { Box, Card, CardContent, Drawer, Tab, Tabs } from '@material-ui/core'
import DrawerHeader from './DrawerHeader'
import DrawerTaskDetailsFormTab from './DrawerTaskDetailsFormTab'
import DrawerParticipantsTab from './DrawerParticipantsTab'
import '../widget/TasksManagementWidget.scss'
import DrawerButtonControls from './DrawerButtonControls'
import DrawerParticipantSelect from './DrawerParticipantSelect'
import DrawerAlarmsTab from './DrawerAlarmsTab'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div hidden={value !== index} {...other}>
      <Box component='div'>{children}</Box>
    </div>
  )
}

interface TasksManagementWidgetDrawerProps {
  tasksStore?: TasksStore
}

const TasksManagementDrawer: React.FC<TasksManagementWidgetDrawerProps> = ({ tasksStore }) => {
  const { taskEditVM: vm } = tasksStore
  if (!vm) return null

  const handleClose = () => {
    tasksStore.closeDrawer()
  }

  const renderDrawerBody = () => {
    if (vm.participantsSelectOpen) return null
    return (
      <Card elevation={0} id='TasksDrawer'>
        <DrawerButtonControls />
        <Tabs value={vm.tabIndex} onChange={(e, val) => vm.setTabIndex(val)} variant='fullWidth'>
          <Tab className={vm.detailsTabValid ? '' : 'error'} label='Details' />
          <Tab className={vm.participantsTabValid ? '' : 'error'} label='Participants' />
          <Tab label='Reminders' />
        </Tabs>
        <CardContent className={vm.participantsSelectOpen ? 'participant-select' : ''}>
          <TabPanel value={vm.tabIndex} index={0}>
            <DrawerTaskDetailsFormTab />
          </TabPanel>
          <TabPanel value={vm.tabIndex} index={1}>
            <DrawerParticipantsTab />
          </TabPanel>
          <TabPanel value={vm.tabIndex} index={2}>
            <DrawerAlarmsTab />
          </TabPanel>
        </CardContent>
      </Card>
    )
  }

  const renderParticipantSelect = () => {
    if (!vm.participantsSelectOpen) return null
    return <DrawerParticipantSelect />
  }

  return (
    <Drawer
      anchor='right'
      open={tasksStore.isTasksManagementDrawerOpen}
      onClose={handleClose}
      id='TasksManagementWidgetDrawer'
    >
      <DrawerHeader />
      {renderParticipantSelect()}
      {renderDrawerBody()}
    </Drawer>
  )
}

export default inject('tasksStore')(observer(TasksManagementDrawer))
