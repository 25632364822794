import { RootStore } from '../../../stores/RootStore'
import { DataStore } from '../../../shared/data/DataStore'
import { OrganizationUser } from '../../aggregate/OrganizationUser'
import { IOrganizationUserDTO } from '../../dtos/IOrganizationUserDTO'
import { IOrganizationUserAudienceSearchRequest } from '../../interfaces/IOrganizationUserAudienceSearchRequest'
import { OrganizationUsersService } from '../../service/OrganizationUsersService'

export class AudienceUsersListDataStore extends DataStore<OrganizationUser, IOrganizationUserDTO> {
  private userId: string

  constructor(rootStore: RootStore, userId: string) {
    super(rootStore, OrganizationUser, 'organizationUsers', [
      'objectId',
      'organizationId',
      'roles',
      'groups',
      'title',
      'primaryRoleId',
      'fk_User',
    ])
    this.userId = userId
    this.paged = true
    this.recordsPerPage = 100
    this.sortColumnName = 'fk_User.lastName'
  }

  protected getListRecordsApiCall() {
    if (!this.userId) return
    return async () => {
      const req: IOrganizationUserAudienceSearchRequest = {
        userId: this.userId,
        orgId: this.rootStore.appStore.currentOrgId,
        terms: this.filter,
        skip: this.recordsPerPage * (this.pageNumber - 1),
        limit: this.recordsPerPage,
        listColumns: this.listColumns,
        sortColumnName: this.sortColumnName,
        sortDirection: this.sortDirection,
        isArchived: false,
      }
      const svc = new OrganizationUsersService(this.rootStore)
      const result = await svc.searchAudienceUsers(req)
      this.totalRecords = result.count
      return result.users
    }
  }

  public get organizationUsers() {
    return this.records
  }
}
