import { observable, action, runInAction, autorun, computed, reaction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { ParticipantFilter } from './model/ParticipantFilter'
import { PartificantFiltersQueryModel } from './query-model/QueryModel'
import { deserialize } from 'serializr'

export class ParticipantFiltersStore {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @observable public participantFilters: Array<ParticipantFilter> = []

  @action
  public async loadParticipantFilters(collectionName, surveyId: string = null) {
    const query = new PartificantFiltersQueryModel()
    query.organizationId = this.rootStore.appStore.currentOrgId
    query.collectionName = collectionName
    query.surveyId = surveyId
    // const svc = new DWService()
    // let response = await svc.getParticipantFilters(query)
    // if (response) {
    //   runInAction(() => {
    //     this.participantFilters = []
    //     response.forEach(e => this.addParticipantFilter(e))
    //   })
    // }
  }

  private addParticipantFilter(e: any) {
    const filter = deserialize(ParticipantFilter, e)
    this.participantFilters.push(filter)
  }

  @action
  public clearData() {
    this.participantFilters = []
  }

  @computed
  public get groups(): Array<ParticipantFilter> {
    return this.participantFilters.filter(e => e.type === 'Group')
  }

  @computed
  public get roles(): Array<ParticipantFilter> {
    return this.participantFilters.filter(e => e.type === 'Role')
  }

  @computed
  public get users(): Array<ParticipantFilter> {
    return this.participantFilters.filter(e => e.type === 'User')
  }

  public getUser(id: string): ParticipantFilter {
    return this.participantFilters.find(e => e.id === id && e.type === 'User')
  }
}
