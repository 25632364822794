import Parse from 'parse'
import { IMobileAppSchemesResult } from '../interfaces/IMobileAppSchemesResult'

export class MobileAppsService {
  public async getMobileAppSchemes(): Promise<IMobileAppSchemesResult> {
    const result: IMobileAppSchemesResult = await Parse.Cloud.run('getMobileAppSchemes')

    return result
  }
}
