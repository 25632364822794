import React from 'react'

//MobX Stores
import { inject, observer } from 'mobx-react'
import { QuestionsStore } from '../store/QuestionsStore'

//MaterialUI Components
import { Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core'

//Material Icons
import MoreIcon from '@material-ui/icons/MoreVert'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import DeleteIcon from '@material-ui/icons/Delete'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'

interface DrawerOptionMenuProps {
  questionsStore?: QuestionsStore
  idx: number
}

const DrawerOptionMenu: React.FC<DrawerOptionMenuProps> = ({ questionsStore, idx }) => {
  const { editVM: QuestionCatalogWidgetForm } = questionsStore

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button className='more-button' onClick={handleClick}>
        <MoreIcon />
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} elevation={1}>
        {idx === 0 ? null : (
          <MenuItem onClick={() => QuestionCatalogWidgetForm.moveOptionUp(idx)}>
            <ListItemIcon>
              <ArrowUpwardIcon />
            </ListItemIcon>
            <ListItemText primary='Move Up' />
          </MenuItem>
        )}
        {idx >= QuestionCatalogWidgetForm.options.length - 1 ? null : (
          <MenuItem onClick={() => QuestionCatalogWidgetForm.moveOptionDown(idx)}>
            <ListItemIcon>
              <ArrowDownwardIcon />
            </ListItemIcon>
            <ListItemText primary='Move Down' />
          </MenuItem>
        )}
        <Divider />
        <MenuItem
          onClick={() => {
            QuestionCatalogWidgetForm.deleteOption(idx)
            handleClose()
          }}
        >
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText primary='Delete' />
        </MenuItem>
      </Menu>
    </div>
  )
}

export default inject('questionsStore')(observer(DrawerOptionMenu))
