import React from 'react'
import { inject, observer } from 'mobx-react'
import './CategoriesTree.scss'
import { ClickAwayListener } from '@material-ui/core'
import { CategoriesStore } from '../../../../categories/CategoriesStore'
import SortableTree from 'react-sortable-tree'

interface Props {
  categoriesStore?: CategoriesStore
}

const CategoriesTree: React.FC<Props> = ({ categoriesStore }) => {
  const { categoryTreeVM: vm } = categoriesStore.listWidgetVM
  if (vm.treeData.length === 0) return null
  return (
    <div
      id='CategoriesTree'
      className={vm.showTreeItemDescriptions ? '' : 'hide-category-descriptions'}
    >
      <SortableTree
        canDrag={false}
        treeData={JSON.parse(JSON.stringify(vm.treeData))}
        onChange={(treeData) => {
          vm.onChange(treeData)
        }}
        scaffoldBlockPxWidth={30}
        onlyExpandSearchedNodes={true}
        searchQuery={vm.keywordFilter}
        rowHeight={60}
        generateNodeProps={(node) => ({
          className: `tree-item primary-border-${node.node.depth} primary-${node.node.depth - 1}`,
          id: `node-${node.node.id}`,
          onClick: (e) => {
            vm.openCategory(node.node.id, e)
          },
        })}
      />
    </div>
  )
}

export default inject('categoriesStore')(observer(CategoriesTree))
