import { computed, action, observable } from 'mobx'
import { RootStore } from 'src/app/stores/RootStore'
import CatalogItemsWidgetVM from './CatalogItemsWidgetVM'

export class FilterPopupVM {
  private rootStore: RootStore
  private parentVM: CatalogItemsWidgetVM

  constructor(rootStore: RootStore | null = null, parentVM: CatalogItemsWidgetVM) {
    if (rootStore) this.rootStore = rootStore
    this.parentVM = parentVM
  }

  @observable public filterCategoryIds: string[] = []

  @action
  public onFilterChanged() {
    // this.parentVM.onFilterChanged()
  }

  @computed
  public get hasFilter(): boolean {
    if (this.filterCategoryIds.length > 0) return true
    return false
  }

  @action
  public onApplyFilters() {
    this.parentVM.onFilterChanged()
  }

  @action
  public handleFiltering() {
    this.parentVM.onFilterChanged()
  }

  @action
  public clearFilters() {
    this.filterCategoryIds = []
  }

  @action
  public setFilterCategoryIds(ids) {
    this.filterCategoryIds = ids
  }

  @computed
  public get categoryOptions() {
    return this.rootStore.categoriesStore.currentOrgCategories
      .sort((a, b) => {
        if (a.name > b.name) return 1
        if (a.name === b.name) return 0
        if (a.name < b.name) return -1
      })
      .map((cat, index) => ({
        key: cat.objectId,
        value: cat.objectId,
        text: cat.name,
        type: 'category',
      }))
  }
}
