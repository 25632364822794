import React from 'react'
import { observer, inject } from 'mobx-react'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import { ManageSurveysWidgetVM } from '../../../view-models/manage-surveys-widget/ManageSurveysWidgetVM'
import MuiConfirmPrompt from '../../../../shared/MuiConfirmPrompt'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import { LabelsStore } from '../../../../labels/store/LabelsStore'

interface Props {
  localizationStore?: LocalizationStore
  widgetVM: ManageSurveysWidgetVM
  labelsStore?: LabelsStore
}

const RemindDialog: React.FC<Props> = ({ widgetVM, localizationStore, labelsStore }) => {
  if (!widgetVM.closeSurveyDialogOpen) return null
  const lz = localizationStore.lzStrings.manageSurveysWidget

  const renderMessage = () => {
    return (
      <>
        <span>{`${lz.close} ${labelsStore.getLabelByLanguageAndFor('pulse')} ${
          lz.and_delete_all
        }`}</span>
      </>
    )
  }

  return (
    <MuiConfirmPrompt
      confirmMessage={renderMessage()}
      confirmMessageSecondary={`${lz.this_action_will} ${labelsStore
        .getLabelByLanguageAndFor('pulse')
        .toLowerCase()} ${lz.and_delete_all.toLowerCase()}`}
      onClose={() => widgetVM.toggleCloseSurveyDialog()}
      open={widgetVM.closeSurveyDialogOpen}
      onConfirm={() => widgetVM.closeSurvey()}
      title={`${lz.close} ${labelsStore.getLabelByLanguageAndFor('pulse')}`}
      icon={<WarningRoundedIcon className='warning-icon' />}
    />
  )
}

export default inject('surveysStore', 'localizationStore', 'labelsStore')(observer(RemindDialog))
