import { RootStore } from '../../../stores/RootStore'
import { action, computed } from 'mobx'
import { WorksheetsStore } from '../../store/WorksheetsStore'
import { WorksheetsListRowVM } from './WorksheetsListRowVM'
import { ColDef, RowClickedEvent, GridOptions } from 'ag-grid-community'
import { AGGridVM } from '../../../shared/ag-grid/AGGridVM'
import { nameOf } from '../../../shared/nameOf'

export class WorksheetsListWidgetVM extends AGGridVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    super()
    this.rootStore = rootStore
  }

  @action
  public getRowNodeId(row): string {
    return row.objectId
  }

  @computed
  public get canAccessScopedAnalysis(): boolean {
    return this.rootStore.appStore.canAccessScopedAnalysis
  }

  @computed
  public get columnDefs(): ColDef[] {
    const s = this.rootStore.localizationStore.lzStrings
    return [
      {
        headerName: s.completedSurveysWidget.name,
        field: nameOf<WorksheetsListRowVM, string>((e) => e.name),
        sort: 'asc',
        cellClass: 'worksheets-list-widget-name-cell',
      },
      { headerName: 'Type', field: nameOf<WorksheetsListRowVM, string>((e) => e.chartTypeId) },
      {
        headerName: 'Workbook',
        field: nameOf<WorksheetsListRowVM, string>((e) => e.tableauWorkbook),
      },
      {
        headerName: 'Worksheet',
        field: nameOf<WorksheetsListRowVM, string>((e) => e.tableauWorksheetDisplayName),
      },
      {
        headerName: 'Owner',
        field: nameOf<WorksheetsListRowVM, string>((e) => e.ownerName),
        cellRenderer: 'ownerCell',
      },
      { headerName: 'Permissions', field: nameOf<WorksheetsListRowVM, string>((e) => e.visibleTo) },
    ]
  }

  @computed
  public get shouldRender(): boolean {
    if (!this.rootStore.worksheetsStore.loaded) return false
    return true
  }

  @computed
  public get rows(): WorksheetsListRowVM[] {
    const worksheets = this.worksheetsStore.currentOrgWorksheets.map(
      (e) => new WorksheetsListRowVM(this.rootStore, e)
    )
    if (this.canAccessScopedAnalysis)
      return worksheets.filter((e) => e.ownerId === this.rootStore.appStore.currentUserId)
    return worksheets
  }

  @computed
  public get worksheetListData(): WorksheetsListRowVM[] {
    return this.rows
  }

  @computed
  private get worksheetsStore(): WorksheetsStore {
    return this.rootStore.worksheetsStore
  }

  public getGridOptions(): GridOptions {
    return {
      ...this.baseGridOptions,
      rowData: this.rows,
      onRowClicked: (e) => this.rowClicked(e),
      getRowNodeId: (e) => this.getRowNodeId(e),
      columnDefs: this.ensureTooltipFields(this.columnDefs),
    }
  }

  public setSearchFilter(val: string) {
    this.typedFilterText = val
    if (this.quickFilterTO) clearTimeout(this.quickFilterTO)
    this.quickFilterTO = setTimeout(() => this.gridApi.setQuickFilter(this.typedFilterText), 1000)
  }

  public rowClicked(e: RowClickedEvent) {
    const row: WorksheetsListRowVM = e.data
    if (!row) return
    row.openWorksheet()
  }
}
