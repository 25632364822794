import Parse from 'parse'
import { observable, action, autorun, reaction, runInAction, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { PagedDataVM } from '../../shared/view-models/PagedDataVM'
import { ResponseRowVM } from './ResponseRowVM'
import { SurveyEditVM } from './SurveyEditVM'
import { Worksheet } from '../../worksheets/aggregate/Worksheet'
import { UserSurveyTakeVM } from '../../user-surveys/view-models/UserSurveyTakeVM'
import { TableauAuthTokensService } from '../../tableauAuthTokens/service/TableauAuthTokensService'
import { ResponsesAGGridVM } from './ResponsesAGGridVM'

export class AnalysisVM {
  private rootStore: RootStore
  private surveyId: string
  private tableauViz: tableau.Viz
  private tableauVizDiv: any
  private tableauEventsSubscription: Parse.LiveQuerySubscription
  @observable private refreshLastCalled: Date
  public editVM: SurveyEditVM

  constructor(rootStore: RootStore, surveyId: string, editVM: SurveyEditVM) {
    this.rootStore = rootStore
    this.surveyId = surveyId
    this.editVM = editVM
    // this.dwSvc = new DWService()
    this.loadTableauAuthToken()
    this.clearSelectedResponse()
    this.responsesListVM = new ResponsesAGGridVM(this.rootStore, this, this.surveyId)
    this.responsesListVM.dataStore.loadListRecords()
    this.listenToTableauEvents()
    setTimeout(() => this.refreshDimensions(), 700)
    reaction(
      () => this.responses.length,
      () => this.checkResponseSelected(),
      { delay: 500 }
    )
  }

  @observable public dateSort?: 'asc' | 'des' = undefined
  @observable public nameSort?: 'asc' | 'des' = undefined

  public async listenToTableauEvents() {
    if (process.env.NODE_ENV === 'test') return
    if (this.tableauEventsSubscription) this.tableauEventsSubscription.unsubscribe()
    const query = new Parse.Query('tableauEvents')
    query.equalTo('orgId', this.rootStore.appStore.currentOrgId)
    const sub = await query.subscribe()
    sub.on('create', (e) => {
      // console.log(e.toJSON())
      this.refreshTableauData(true)
    })
    this.tableauEventsSubscription = sub
  }

  @observable public responsesLoading: boolean = false
  @observable public selectedResponseId: string = null
  @observable public tableauAuthToken: string = null
  @observable public width: number = 0
  @observable public height: number = 0
  @observable public responsesListVM: ResponsesAGGridVM

  // @computed
  // public get responses(): ResponseRowVM[] {
  // return this.rootStore.surveyResponsesStore.surveyResponses
  //   .filter((e) => e.surveyId === this.surveyId)
  //   .sort((a, b) => (a.respondedAt < b.respondedAt ? 0 : -1))
  //   .map((e) => new ResponseRowVM(this.rootStore, this.surveyEditVM, this, e))
  // }

  @action
  public checkResponseSelected() {
    if (this.selectedResponseId) return
    if (this.responses.length === 0) return
    this.setSelectedResponse(this.responses[0].responseId)
  }

  @action
  public loadResponsesList() {
    this.responsesListVM.loadDataStore()
  }

  @computed
  public get worksheetId(): string {
    return this.editVM.worksheetId
  }

  @computed
  public get worksheet(): Worksheet {
    if (!this.editVM.worksheetId) return null
    const ws = this.rootStore.worksheetsStore.getWorksheet(this.editVM.worksheetId)
    if (!ws) return null
    const clonedWS = ws.clone()
    clonedWS.setSurvey(this.editVM.objectId)
    clonedWS.setLoggedInUser(this.rootStore.appStore.currentUserId)
    return clonedWS
  }

  private getTime(date: any): number {
    if (date) return new Date(date).getTime()
    else return 0
  }

  private sortArrayByString(
    propertyName: string,
    array: Array<any>,
    sortOrder?: 'asc' | 'des'
  ): Array<any> {
    const desc = sortOrder === 'des'
    const sortFunction = (a, b) => {
      const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890-'
      let valueA = a && a[propertyName] ? String(a[propertyName]).trim().toLowerCase() : ''
      for (let i = 0; i <= valueA.length; i++) {
        if (!alphabet.includes(valueA.charAt(i))) {
          valueA = valueA.slice(0, i) + valueA.slice(i + 1)
        }
      }
      let valueB = b && b[propertyName] ? String(b[propertyName]).trim().toLowerCase() : ''
      for (let j = 0; j <= valueB.length; j++) {
        if (!alphabet.includes(valueB.charAt(j))) {
          valueB = valueB.slice(0, j) + valueB.slice(j + 1)
        }
      }
      if (!valueA || valueA === '-' || valueA === '--') return desc ? -1 : 1
      if (!valueB || valueB === '-' || valueB === '--') return desc ? 1 : -1
      const result = valueA > valueB ? -1 : 1
      return desc ? result : -result
    }
    const rows = array.sort(sortFunction)
    return rows
  }

  @computed
  public get selectedResponse(): ResponseRowVM {
    return this.responses.find((e) => e.isSelected)
  }

  @computed
  public get selectedResponseUserName(): string {
    if (!this.selectedResponse) return ''
    return this.selectedResponse.userName
  }

  @computed
  public get surveyResponsesTotalCount(): number {
    return this.responsesListVM.dataStore.totalRecords
  }

  @computed
  public get responses(): ResponseRowVM[] {
    return this.responsesListVM.dataStore.surveyResponses
      .map((e) => new ResponseRowVM(this.rootStore, this.editVM, this, e))
      .slice()
  }

  private switchSortOrder(currentOrder: string): 'asc' | 'des' | undefined {
    switch (currentOrder) {
      case 'asc':
        return 'des'
      case 'des':
        return undefined
      default:
        return 'asc'
    }
  }

  @action
  public sortDate() {
    this.nameSort = undefined
    this.dateSort = this.switchSortOrder(this.dateSort)
  }

  @action
  public sortName() {
    this.dateSort = undefined
    this.nameSort = this.switchSortOrder(this.nameSort)
  }

  @action
  public refreshDimensions() {
    const div = document.getElementById('TableauReportContainer')
    if (!div) {
      setTimeout(() => this.refreshDimensions(), 200)
      return
    }
    const rect = div.getBoundingClientRect()
    this.width = rect.width
    this.height = rect.height - 20
  }

  @action
  public async setSelectedResponse(id: string) {
    this.clearSelectedResponse()
    this.selectedResponseId = id
    const selectedResponse = await this.responsesListVM.dataStore.getFullRecord(id)
    const responseForTakeVM = selectedResponse.clone()
    responseForTakeVM.questionsFlowFormat = 'scrolling'
    this.editVM.responseVM = new UserSurveyTakeVM(
      this.rootStore,
      responseForTakeVM,
      'responses',
      true,
      true,
      false,
      selectedResponse.objectId
    )
  }

  @action
  public clearSelectedResponse() {
    this.selectedResponseId = null
    this.editVM.responseVM = null
  }

  @computed
  public get hasResponses(): boolean {
    return this.responses.length !== 0
  }

  @computed
  public get showTabs(): boolean {
    if (!this.worksheet) return false
    return this.worksheet.showTabs
  }

  @computed
  public get tableauViewUrl(): string {
    if (!this.worksheet) return ''
    return this.worksheet.tableauViewUrl
  }

  @computed
  public get tableauQuery(): string {
    if (!this.worksheet) return ''
    return this.worksheet.tableauQuery
  }

  @computed
  public get showSpinner(): boolean {
    if (this.responsesLoading) return true
    if (!this.responsesLoading && !this.hasResponses) {
      return false
    }
    if (!this.worksheetId) return false
    if (this.tableauAuthToken === '') return true
    return false
  }

  @computed
  public get showNoResponsesMsg(): boolean {
    if (this.responsesLoading) return false
    if (!this.hasResponses) return true
    return false
  }

  @computed
  public get showNoWorksheetMessage(): boolean {
    if (this.showNoResponsesMsg) return false
    if (!this.worksheetId) return true
    if (!this.tableauViewUrl) return true
    return false
  }

  @action
  public async loadTableauAuthToken() {
    this.tableauAuthToken = ''
    if (!this.worksheet) {
      setTimeout(() => this.loadTableauAuthToken(), 400)
      return
    }
    const svc = new TableauAuthTokensService(this.rootStore)
    const token = await svc.getAuthToken()
    this.tableauAuthToken = token
  }

  public setTableauVizContainer(div, viz) {
    this.tableauViz = viz
    this.tableauVizDiv = div
  }

  @action
  public refreshTableauData(fromExtractRefresh: boolean = false) {
    if (!this.tableauViz) return
    if (this.refreshLastCalled && fromExtractRefresh) {
      const now = new Date()
      const secondsAgo = (now.getTime() - this.refreshLastCalled.getTime()) / 1000
      console.log(secondsAgo)
      if (secondsAgo < 12) {
        console.log('wait longer before refreshing tableau')
        return
      }
    }
    this.refreshLastCalled = new Date()
    setTimeout(async () => await this.tableauViz.refreshDataAsync(), 7000)
  }
}
