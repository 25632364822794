import React from 'react'
import './DetailsArea.scss'
import { TrainingItemsStore } from '../../store/TrainingItemsStore'
import { inject, observer } from 'mobx-react'
import { Grid, OutlinedInput, Typography, TextField } from '@material-ui/core'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import { LabelsStore } from 'src/app/labels/store/LabelsStore'
import CategoryPicker from 'src/app/shared/category-picker/CategoryPicker'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { IApplicableParticipantDTO } from '../../dto/IApplicableParticipantDTO'

interface Props {
  trainingItemsStore?: TrainingItemsStore
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
}
const DetailsArea: React.FC<Props> = ({ trainingItemsStore, localizationStore, labelsStore }) => {
  const { trainingItemEditVM: vm } = trainingItemsStore
  const { training_items: strings } = localizationStore.lzStrings
  if (!vm) return null

  const renderDetailFields = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6} className='details-item'>
          <Typography variant='h5' className='input-label'>
            {strings.name}
            <span className='required'>*</span>
          </Typography>
          <OutlinedInput
            required
            fullWidth
            labelWidth={0}
            autoComplete='off'
            value={vm.name}
            onChange={(e) => vm.setName(e.target.value)}
            error={!vm.nameValid}
          />
        </Grid>
        <Grid item xs={6} className='details-item'>
          <Typography variant='h5' className='input-label'>
            {strings.description}
          </Typography>
          <OutlinedInput
            fullWidth
            labelWidth={0}
            autoComplete='off'
            value={vm.description}
            onChange={(e) => vm.setDescription(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          {renderTagsSection()}
        </Grid>
      </Grid>
    )
  }

  const renderTagsSection = () => {
    return (
      <Grid container spacing={2}>
        {/* <Grid item xs={12}>
          <Typography variant='h5' className='input-label'>
            {strings.tags}
            <span className='required'>*</span>
          </Typography>
        </Grid> */}
        {/* <form> */}
        <Grid item xs={6} className='details-item'>
          <Typography variant='h5' className='input-label'>
            {labelsStore.getLabelByLanguageAndFor('categories')}
          </Typography>
          <CategoryPicker
            mode='multiSelect'
            error={!vm.categoriesValid}
            onApply={(val) => vm.setCategories(val)}
            onChipDelete={(id) => vm.removeCategory(id)}
            vm={vm.categoryPickerVM}
            fullWidth={true}
          />
        </Grid>
        <Grid item xs={6} className='details-item'>
          <Typography variant='h5' className='input-label'>
            {strings.roles_groups}
          </Typography>
          <Autocomplete
            className='role-groups-input'
            value={vm.selectedRolesAndGroups.slice()}
            multiple
            filterSelectedOptions
            options={vm.availableRolesAndGroups.slice()}
            getOptionLabel={(e) => `${e.type.charAt(0).toUpperCase() + e.type.slice(1)}: ${e.name}`}
            onChange={(e, arr: IApplicableParticipantDTO[]) => vm.setSelectedRolesAndGroups(arr)}
            disableClearable={true}
            renderInput={(params) => (
              <TextField
                className={'text-field-input'}
                {...params}
                variant='outlined'
                fullWidth
                margin='none'
              />
            )}
          />
        </Grid>
        {/* </form> */}
      </Grid>
    )
  }

  return (
    <Grid container direction='row' id='detailsArea'>
      <Grid item xs={7} className='left-panel'>
        {renderDetailFields()}
      </Grid>
      {/* <Grid item xs={7} className='right-panel'>
        <img className='training-items-details-svg' src={TrainingItemsDetailsSvg} />
      </Grid> */}
    </Grid>
  )
}

export default inject(
  'trainingItemsStore',
  'localizationStore',
  'labelsStore'
)(observer(DetailsArea))
