import React, { FC } from 'react'
import { inject, observer, useLocalStore } from 'mobx-react'
import {
  AppBar,
  Button,
  Card,
  CssBaseline,
  Grid,
  Link,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { CreateSurveyScreenVM } from '../../view-models/create-dialog/CreateSurveyScreenVM'
import rootStore from '../../../stores/RootStore'
import isIE11 from '../../../../utils/isIE11'
import clsx from 'clsx'
import { LabelsStore } from '../../../labels/store/LabelsStore'
import startFromScratch from '../../../../assets/img/startFromScratch.png'
import startFromTemplate from '../../../../assets/img/startFromTemplate.png'
import './CreateSurveyScreen.scss'

export interface Props {
  labelsStore?: LabelsStore
}

const CreateSurveyScreen: FC<Props> = ({ labelsStore }) => {
  const localStore = useLocalStore(() => ({
    vm: new CreateSurveyScreenVM(rootStore),
  }))
  const { pulseCampaign: lz } = rootStore.localizationStore.lzStrings
  const vm = localStore.vm

  const renderToolbar = () => {
    return (
      <div id='DashboardToolbar'>
        <CssBaseline />
        <AppBar
          className={clsx('dashboard-appbar', {
            'no-transition': isIE11,
          })}
          elevation={0}
        >
          <Toolbar className='dashboard-toolbar'>
            <Link onClick={() => vm.navigateBack()} underline='always'>
              <Typography className='dashboard-title spaced'>
                {labelsStore.getLabelByLanguageAndFor('pulses')}
              </Typography>
            </Link>
            <Typography className='dashboard-title spaced'>{' > '}</Typography>
            <Typography className='dashboard-title spaced'>{'Create'}</Typography>
            <div className='dashboard-toolbar-right'></div>
          </Toolbar>
        </AppBar>
      </div>
    )
  }

  const renderCampaignDialogActionButtons = () => {
    return (
      <div className={'actions-container'}>
        <Grid container direction='row' justifyContent='center' alignItems='center'>
          <Grid item className={'create-campaign-grid-item'} xs={6}>
            <Card
              className={'create-campaign-btn-card'}
              onClick={(e) => {
                vm.handleStartFromTemplate()
              }}
            >
              <div className='create-campaign-btn-card-container'>
                <div className={'create-campaign-btn-card-frame'}>
                  <img
                    className={'create-campaign-btn-img'}
                    src={startFromTemplate}
                    alt={'Start from Template'}
                  />
                </div>
                <div className={'create-campaign-btn-card-text'}>
                  <Typography variant='body1' className={'create-campaign-card-label-main'}>
                    {lz.start_from_template}
                  </Typography>
                  <Typography variant='body2' className={'create-campaign-card-label-sub'}>
                    {`Choose from a library of system ${labelsStore.getLabelByLanguageAndFor(
                      'pulses'
                    )}`}
                  </Typography>
                </div>
              </div>
            </Card>
          </Grid>
          <Grid item className={'create-campaign-grid-item'} xs={6}>
            <Card
              className={'create-campaign-btn-card'}
              onClick={(e) => {
                vm.handleStartFromScratch()
              }}
            >
              <div className='create-campaign-btn-card-container'>
                <div className={'create-campaign-btn-card-frame'}>
                  <img
                    className={'create-campaign-btn-img'}
                    src={startFromScratch}
                    alt={'Start from Scratch'}
                  />
                </div>
                <div className={'create-campaign-btn-card-text'}>
                  <Typography variant='body1' className={'create-campaign-card-label-main'}>
                    {lz.start_from_scratch}
                  </Typography>
                  <Typography variant='body2' className={'create-campaign-card-label-sub'}>
                    {lz.create_your_own}
                  </Typography>
                </div>
              </div>
            </Card>
          </Grid>
        </Grid>
      </div>
    )
  }

  return (
    <div id='CreateSurveyScreen'>
      {renderToolbar()}
      {renderCampaignDialogActionButtons()}
    </div>
  )
}

export default inject('labelsStore')(observer(CreateSurveyScreen))
