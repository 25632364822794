import { observable, computed, action, reaction } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { Worksheet } from '../../aggregate/Worksheet'
import { ChartType } from '../../types/ChartType'
import { Survey } from '../../../surveys/aggregate/Survey'
import SurveyType from '../../../survey-types/store/aggregate/SurveyType'
import { ParticipantsSelectVM } from '../../../participants-select/view-models/ParticipantsSelectVM'
import { IParticipant } from '../../../participants-select/interfaces/IParticipant'
import { TableauAuthTokensService } from '../../../tableauAuthTokens/service/TableauAuthTokensService'
import { TableauRestService } from '../../../tableauRest/service/TableauRestService'
import { ITableauWorkbook } from '../../../tableauRest/interfaces/ITableauWorkbook'
import { ITableauDataSourceDTO } from '../../../tableauRest/interfaces/ITableauDataSourceDTO'
import { ParseService } from '../../../services/ParseService'

export class WorksheetEditVM {
  private rootStore: RootStore
  private tableauViz: tableau.Viz
  private tableauVizDiv: any
  private lastTableauUrl: string = ''
  private tableauUrlInterval: any
  private tabSvc: TableauRestService

  constructor(rootStore: RootStore, worksheet: Worksheet) {
    this.rootStore = rootStore
    this.worksheet = worksheet
    this.lz = this.rootStore.localizationStore.lzStrings.visualizations
    worksheet.setLoggedInUser(rootStore.appStore.currentUserId)
    if (this.worksheet.isNew && this.canEditTableau) this.enterNewTableauWorkbookMode()
    this.tabSvc = new TableauRestService(this.rootStore)
    this.loadTableauAuthToken()
    this.loadTableauWorkbooks()
    this.loadTableauDataSources()
    this.setDefaultSettings()
    setTimeout(() => this.refreshDimensions(), 700)
    this.permissionsSelectVM = new ParticipantsSelectVM(
      this.rootStore,
      false,
      false,
      true,
      true,
      false
    )
    this.loadVMData(worksheet)

    if (this) {
      reaction(
        () => this.infoString,
        () => {
          this.isDirty = true
        }
      )
    }
  }

  private async loadVMData(worksheet: Worksheet) {
    if (this.rootStore.audienceMembersStore)
      await this.rootStore.audienceMembersStore.loadAudienceMembers()
    setTimeout(() => {
      this.permissionsSelectVM.setParticipants(worksheet.visibleTo as IParticipant[])
      this.permissionsSelectVM.setVisible()
    }, 500)
  }

  @observable public isSaving: boolean = false
  @observable public saveTried: boolean = false
  @observable public newtableauWorkbookMode: boolean = false
  @observable public worksheet: Worksheet = null
  @observable public width: number = 0
  @observable public height: number = 0
  @observable public editMode: boolean = false
  @observable public tableauVizLoaded: boolean = false
  @observable public tableauAuthToken: string = null
  @observable public tableauWorkbooks: Array<ITableauWorkbook> = []
  @observable public tableauDataSource: string = ''
  @observable public tableauDataSources: Array<ITableauDataSourceDTO> = []
  @observable public currentTabIndex: number = 0
  @observable public permissionsSelectVM: ParticipantsSelectVM = null
  @observable public isDirty: boolean = false
  @observable public isSnackbarOpen: boolean = false
  @observable public snackbarMessage: string = ''
  @observable public lz = undefined

  @computed
  public get availableSurveyTypes() {
    const types = this.rootStore.surveyTypesStore.surveyTypes.filter((e) => !e.isDeleted)
    const anyType = SurveyType.create('')
    anyType.name = '(Any)'
    anyType.objectId = '(Any)'
    types.push(anyType)
    return types.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0))
  }

  @computed
  public get surveyTypeId(): string {
    if (!this.worksheet.surveyTypeId) return '(Any)'
    return this.worksheet.surveyTypeId
  }

  @action
  public setCurrentTab(idx) {
    this.currentTabIndex = idx
  }

  @action
  private setDefaultSettings() {
    this.setDefaultOwner()
    this.setDefaultForLoggedInUser()
  }

  @action
  private setDefaultOwner() {
    if (this.worksheet.owner) return
    if (!this.worksheet.owner) this.setWorksheetOwner(this.rootStore.appStore.currentUserId)
  }

  @action
  private setDefaultForLoggedInUser() {
    if (!this.canAccessScopedAnalysis) return
    if (this.worksheet.forLoggedInUser) return
    this.toggleForLoggedInUser()
  }

  @action
  public setSurveyType(id) {
    if (id === '(Any)') id = null
    this.worksheet.setSurveyTypeId(id)
  }

  @computed
  public get isNew(): boolean {
    return this.worksheet.isNew
  }

  @action
  public async loadTableauWorkbooks() {
    if (!this.rootStore.userStore.user) {
      setTimeout(() => this.loadTableauWorkbooks(), 400)
      return
    }
    const workbooks = await this.tabSvc.getWorkbooks()
    this.tableauWorkbooks = workbooks.sort((a, b) =>
      a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0
    )
    if (this.worksheet.tableauWorkbook) await this.loadTableauWorkbookViews()
  }

  @action
  public async loadTableauDataSources() {
    if (!this.rootStore.userStore.user) {
      setTimeout(() => this.loadTableauDataSources(), 400)
      return
    }
    const dataSources = await this.tabSvc.getTableauDataSources()
    this.tableauDataSources = dataSources.sort((a, b) =>
      a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0
    )
  }

  @computed
  public get isOwnerValid() {
    if (!this.saveTried) return true
    if (!this.ownerId) return false
    if (this.ownerId.trim() === '') return false
    return true
  }

  @computed
  public get ownerId() {
    return this.worksheet.owner
  }

  @computed
  public get worksheetOwner() {
    let foundUser = this.rootStore.usersStore.getUserByUserId(this.worksheet.owner)
    if (!foundUser) return { name: '' }
    return { name: foundUser.name }
  }

  @action
  public setWorksheetOwner(val) {
    this.worksheet.setOwner(val)
  }

  @computed
  public get usersOrdered() {
    if (!this.rootStore.usersStore.orgUsers.length) return []
    const users = []
    this.rootStore.usersStore.orgUsers.forEach((e) => {
      if (!e.name) return
      if (e.name === '') return
      users.push({ name: e.name, id: e.userId })
    })
    return users.sort((a, b) => {
      const nameA = a.name ? a.name.toLowerCase() : ''
      const nameB = b.name ? b.name.toLowerCase() : ''
      if (nameA < nameB) return -1
      if (nameA > nameB) return 1
    })
  }

  @computed
  public get currentUser() {
    return this.rootStore.userStore.user
  }

  @computed
  public get availableDataSources(): Array<ITableauDataSourceDTO> {
    if (this.canAccessScopedAnalysis) {
      const sources = []
      this.tableauDataSources.forEach((e) => {
        if (e.name.toLowerCase().includes('scoped')) sources.push(e)
      })
      return sources
    }
    if (this.canAccessAllAnalysis) return this.tableauDataSources
    return []
  }

  @computed
  public get availableTableauWorkbooks(): Array<ITableauWorkbook> {
    if (!this.currentUser) return []
    if (this.canAccessScopedAnalysis) {
      const workbooks = []
      this.tableauWorkbooks.forEach((e) => {
        if (e.owner.name === this.currentUser.email) workbooks.push(e)
      })
      return workbooks
    }
    if (this.canAccessAllAnalysis) return this.tableauWorkbooks
    return []
  }

  @computed
  public get canEditTableau() {
    return this.rootStore.userStore.user.tableauLicenseType === 'SiteAdministratorExplorer'    
  }

  @computed
  public get canAccessAllAnalysis(): boolean {
    return Boolean(this.rootStore.appStore.canAccessAllAnalysis)
  }

  @computed
  public get canAccessScopedAnalysis(): boolean {
    return Boolean(this.rootStore.appStore.canAccessScopedAnalysis)
  }

  @computed
  public get tableauWorkbookName(): string {
    if (!this.selectedTableauWorkbook) return ''
    return this.selectedTableauWorkbook.contentUrl
  }

  @computed
  public get tableauWorksheet(): string {
    if (this.newtableauWorkbookMode && this.tableauDataSource) return 'chosen'
    return this.worksheet.tableauWorksheet
  }

  @computed
  public get forSurveys(): boolean {
    return this.worksheet.forSurveys
  }

  @computed
  public get forPulseCampaigns(): boolean {
    return this.worksheet.forPulseCampaigns
  }

  @computed
  public get defaultPulseCampaignTemplate(): boolean {
    return this.worksheet.defaultPulseCampaignTemplate
  }

  @action
  public toggleForSurveys() {
    this.worksheet.toggleForSurveys()
    this.loadTableauAuthToken()
  }


  @action
  public toggleForPulseCampaigns() {
    this.worksheet.toggleForPulseCampaigns()
    this.loadTableauAuthToken()
  }

  @action
  public toggleDefaultPulseCampaignsTemplate() {
    this.worksheet.toggleDefaultPulseCampaignTemplate()
    this.loadTableauAuthToken()
  }

  @computed
  public get forLoggedInUser() {
    return this.worksheet.forLoggedInUser
  }

  @action
  public toggleForLoggedInUser() {
    this.worksheet.toggleForLoggedInUser()
    this.loadTableauAuthToken()
  }

  @computed
  public get showTabs(): boolean {
    return this.worksheet.showTabs
  }

  @action
  public toggleShowTabs() {
    this.worksheet.toggleShowTabs()
    this.loadTableauAuthToken()
  }

  @computed
  public get surveyId(): string {
    return this.worksheet.surveyId
  }

  @computed
  public get availableSurveys(): Survey[] {
    return this.rootStore.surveysStore.currentOrgSurveys
      .filter((e) => e.name)
      .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0))
  }

  @action
  public setTableauWorkbook(val) {
    this.worksheet.setTableauWorkbook(val)
    this.tableauAuthToken = undefined
    this.loadTableauWorkbookViews()
  }

  @action
  private async loadTableauWorkbookViews() {
    const workbookUrl = this.worksheet.tableauWorkbook
    const loadedWorkbook = await this.tabSvc.getWorkbook(workbookUrl)
    const idx = this.tableauWorkbooks.findIndex(
      (e) => e.contentUrl === this.worksheet.tableauWorkbook
    )
    this.tableauWorkbooks[idx] = loadedWorkbook
    this.setTableauWorksheet(this.selectedTableauWorkbook.viewsList[0].contentUrl)
  }

  @computed
  public get selectedTableauWorkbookViews() {
    if (!this.selectedTableauWorkbook) return []
    if (!this.selectedTableauWorkbook.viewsList) return []
    return this.selectedTableauWorkbook.viewsList
  }

  @action
  public setTableauDataSource(val: string) {
    this.isDirty = true
    this.tableauDataSource = val
    this.loadTableauAuthToken()
  }

  @action
  public enterNewTableauWorkbookMode() {
    this.newtableauWorkbookMode = true
    this.editMode = false
    if (this.tableauAuthToken) this.loadTableauAuthToken()
  }

  @action
  public exitNewTableauWorkbook() {
    this.newtableauWorkbookMode = false
    this.loadTableauWorkbooks()
    if (this.tableauAuthToken) this.loadTableauAuthToken()
  }

  @action
  public setSurvey(id) {
    this.worksheet.setSurvey(id)
    this.loadTableauAuthToken()
  }

  @computed
  public get selectedSurvey() {
    const foundSurvey = this.availableSurveys.find((e) => e.objectId === this.surveyId)
    if (!foundSurvey) return { name: '' }
    return foundSurvey
  }

  @computed
  public get selectedTableauWorkbook(): ITableauWorkbook {
    if (!this.worksheet.tableauWorkbook) return
    return this.tableauWorkbooks.find((e) => e.contentUrl === this.worksheet.tableauWorkbook)
  }

  @action
  public setTableauWorksheet(val) {
    const oldVal = this.worksheet.tableauWorksheet
    this.worksheet.setTableauWorksheet(val)
    if (oldVal !== val) this.loadTableauAuthToken()
  }

  @computed
  public get chartType(): ChartType {
    return this.worksheet.chartTypeId
  }

  @computed
  public get name(): string {
    if (!this.worksheet.name) return ''
    return this.worksheet.name
  }

  @action
  public setName(val) {
    this.worksheet.setName(val)
  }

  @computed
  public get selectedChartTypeText(): string {
    return this.worksheet.chartTypeId
  }

  @action
  public async delete() {
    const doDelete = window.confirm(`${this.lz.are_you_sure}`)
    if (!doDelete) return
    this.openSnackbar(`${this.lz.info}: ${this.lz.deleting_viz}`)
    const pSvc = new ParseService()
    const res = await pSvc.deleteWorksheet(
      this.worksheet.objectId,
      this.rootStore.appStore.currentOrgId
    )
    if (res) this.openSnackbar(`${this.lz.success}: ${this.lz.deleted_viz}`)
    else this.openSnackbar(`${this.lz.error}: ${this.lz.unable_to_delete_viz}`)
    setTimeout(() => this.rootStore.appStore.router.push('dashboard/worksheets'), 1500)
  }

  publish() {
    throw new Error('Method not implemented.')
  }

  @computed
  public get canRevertAll(): boolean {
    return !Boolean(this.worksheet.forLoggedInUser)
  }

  @action
  public async refresh() {
    this.openSnackbar(`${this.lz.info}: ${this.lz.refreshing_viz}`)
    await this.tableauViz.dispose()
    this.loadTableauAuthToken()
    await this.tableauViz.revertAllAsync()
    await this.tableauViz.refreshDataAsync()
  }

  @computed
  public get isNameValid() {
    if (!this.saveTried) return true
    if (!this.name || this.name.trim() === '') return false
    return true
  }

  loadTestData() {
    throw new Error('Method not implemented.')
  }

  @action
  public setChartType(val: ChartType): void {
    this.worksheet.setChartType(val)
  }

  @action
  public async save() {
    this.saveTried = true
    if (!this.isWorkbookValid) return ''
    let worksheet = this.worksheet.serialize()
    worksheet.visibleTo = this.permissionsSelectVM.participantsToDTO()
    this.isSaving = true
    this.openSnackbar(`${this.lz.info}: ${this.lz.saving_viz}`)
    const pSvc = new ParseService()
    const res = await pSvc.saveWorksheet(this.rootStore.appStore.currentOrgId, worksheet)
    if (res) this.openSnackbar(`${this.lz.success}: ${this.lz.saved_viz}`)
    else this.openSnackbar(`${this.lz.error}: ${this.lz.unable_to_save_viz}`)
    setTimeout(() => {
      this.isSaving = false
      this.isDirty = false
      this.rootStore.appStore.router.push('dashboard/worksheets')
    }, 1500)
  }

  public exportToCsv() {
    this.tableauViz.showExportCrossTabDialog(null)
  }

  public exportToPdf() {
    this.tableauViz.showExportPDFDialog()
  }

  public exportToImage() {
    this.tableauViz.showExportImageDialog()
  }

  @action
  public refreshDimensions() {
    const div = document.getElementById('TableauReportContainer')
    if (!div) {
      setTimeout(() => this.refreshDimensions(), 200)
      return
    }
    const rect = div.getBoundingClientRect()
    this.width = rect.width
    this.height = rect.height - 100
    if (this.editMode || this.newtableauWorkbookMode) {
      div.style.height = 'calc(100vh - 220px)'
    } else {
      div.style.height = 'calc(100vh - 115px)'
    }
  }

  @action
  private async listenToVizUrl() {
    if (!this.tableauViz) return
    try {
      const url = await this.tableauViz.getCurrentUrlAsync()
      const changed = this.lastTableauUrl !== '' && url.indexOf(this.lastTableauUrl) === -1
      if (changed) {
        console.log('CHANGED: ' + url)
        console.log('CHANGED: ' + this.lastTableauUrl)
      }
      this.lastTableauUrl = url
      const isStillAuthoring = url.includes('views//')
      if (changed && this.newtableauWorkbookMode && !isStillAuthoring) {
        this.exitNewTableauWorkbook()
        //https://tableau-qa.rippleworx.com/t/RippleDevTeam/views/asdfasdf/Sheet1?%3Aembed=yes&%3Aembed=y&%3Acomments=no&%3Atoolbar=no&%3Arefresh=yes&%3AshowVizHome=n&%3Atabs=n&%3Adevice=desktop&%3AapiID=host0#navType=1&navSrc=Parse&1
        const workbook = url.split('/views/')[1].split('/')[0]
        const worksheetName = url.split(workbook)[1].split('/')[1].split('?')[0]
        const worksheetContentUrl = workbook + '/sheets/' + worksheetName
        this.worksheet.setTableauWorkbook(workbook)
        this.setTableauWorksheet(worksheetContentUrl)
        this.loadTableauWorkbooks()
        this.lastTableauUrl = ''
        if (this.worksheet.name === '') this.setName(workbook + ': ' + worksheetName)
      }
      // const iframe = document.querySelectorAll('iframe')[0]
      // console.log(iframe.src)
    } catch (e) {
      console.log(e)
    }
  }

  public enterTableauEditMode(): void {
    this.editMode = true
    this.refreshDimensions()
    this.tableauViz.getCurrentUrlAsync().then((current_url) => {
      const edit_url = current_url.split('?')[0].replace('/views', '/authoring')
      const edit_options: tableau.VizCreateOptions = {
        hideTabs: true,
        hideToolbar: true,
        width: this.width.toString() + 'px',
        height: this.height.toString() + 'px',
        onFirstInteractive: () => {
          var iframe = document.querySelectorAll('iframe')[0]
          iframe.onload = () => {
            this.editMode = false
            this.refreshDimensions()
            this.loadTableauWorkbooks()
          }
        },
      }
      this.loadViz(edit_url, edit_options)
    })
  }

  private loadViz(url, options: tableau.VizCreateOptions) {
    if (this.tableauViz) this.tableauViz.dispose()
    this.tableauViz = new tableau.Viz(this.tableauVizDiv, url, options)
  }

  @action
  public setTableauVizContainer(div, viz) {
    this.tableauViz = viz
    this.tableauVizDiv = div
    this.lastTableauUrl = ''
    if (!this.tableauViz) return
    setTimeout(() => this.checkLoggedinUserParameter(), 3000)
    setTimeout(() => this.refreshDimensions(), 2500)
    setTimeout(() => (this.tableauVizLoaded = true), 2500)
    if (this.tableauUrlInterval) clearInterval(this.tableauUrlInterval)
    setTimeout(() => {
      this.tableauUrlInterval = setInterval(() => this.listenToVizUrl(), 500)
    }, 5000)
  }

  private async checkLoggedinUserParameter() {
    if (!this.worksheet.forLoggedInUser) return
    if (!this.tableauViz) {
      setTimeout(() => this.checkLoggedinUserParameter(), 1000)
      return
    }
    await this.tableauViz
      .getWorkbook()
      .changeParameterValueAsync('ViewingUserId', this.rootStore.appStore.currentUserId)
    console.log('Parameter set: ' + this.rootStore.appStore.currentUserId)
  }

  @computed
  public get tableauViewUrl(): string {
    if (this.newtableauWorkbookMode && this.tableauDataSource) {
      const url =
        this.worksheet.tableauServerUrl +
        't/' +
        this.worksheet.tableauSiteName +
        '/authoringNewWorkbook/' +
        '1e5g13p72$fmpu-1e-ed-qj-b7kf77/' +
        this.tableauDataSource +
        '/' +
        '?:embed=yes&:comments=no&:toolbar=no&:refresh=yes'
      return url
    }
    return this.worksheet.tableauViewUrl
  }

  @computed
  public get tableauQuery(): string {
    return this.worksheet.tableauQuery
  }

  @action
  public async loadTableauAuthToken() {
    this.tableauAuthToken = ''
    const svc = new TableauAuthTokensService(this.rootStore)
    const token = await svc.getAuthToken()
    this.tableauAuthToken = token
  }

  @computed
  public get isWorkbookValid() {
    if (!this.saveTried) return true
    if (!this.tableauVizLoaded) return false
    if (!this.isNameValid) return false
    if (!this.isOwnerValid) return false
    return true
  }

  @computed
  public get isVizValid() {
    if (!this.saveTried) return true
    if (this.tableauVizLoaded) return true
    return false
  }

  @computed
  public get infoString() {
    if (!this.worksheet) return ''
    const participants = this.permissionsSelectVM
      ? this.permissionsSelectVM.participants.length
      : ''
    return this.worksheet.name + this.worksheet.chartTypeId + participants
  }

  @action
  public openSnackbar(msg) {
    this.snackbarMessage = msg
    this.isSnackbarOpen = true
  }

  @action
  public closeSnackbar() {
    this.isSnackbarOpen = false
  }
}
