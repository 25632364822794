import { computed, observable, action, reaction } from 'mobx'
import { AGGridRowVM } from '../../shared/ag-grid/AGGridRowVM'
import { RootStore } from 'src/app/stores/RootStore'
import PrivilegeSet from '../aggregate/PrivilegeSet'
import { PrivilegeSetsWidgetVM } from './PrivilegeSetsWidgetVM'

export class PrivilegeSetRowVM extends AGGridRowVM<PrivilegeSet>{
 
  constructor(rootStore: RootStore, privilegeSet: PrivilegeSet, listVM: PrivilegeSetsWidgetVM) {
    super(rootStore, privilegeSet,listVM)
  }

  @computed
  public get name(): string {
    return this.aggregate.name
  }

  @computed
  public get description(): string {
    return this.aggregate.description
  }

  @computed
  public get objectId(): string {
    return this.aggregate.objectId
  }

  openPrivilegeSet() {
    this.rootStore.privilegeSetsStore.setSelectedPrivilegeSet(this.objectId)
    this.rootStore.privilegeSetsStore.showDrawer = true
  }
}
