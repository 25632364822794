import React from 'react'
import { inject, observer } from 'mobx-react'
import { PulseQuestionsStore } from '../../store/PulseQuestionsStore'
import { TextField, Typography } from '@material-ui/core'
import './PulseQuestionTitle.scss'

interface Props {
  pulseQuestionsStore?: PulseQuestionsStore
}

const PulseQuestionTitle: React.FC<Props> = ({ pulseQuestionsStore }) => {
  const vm = pulseQuestionsStore.editVM
  if (!vm) return null

  const renderQuestionTitle = () => {
    return (
      <div className='question-title'>
        <Typography className='question-title-header'>Question</Typography>
        <TextField
          autoFocus
          error={!vm.titleValid}
          id='outlined-name'
          value={vm.title}
          onChange={(e) => {
            vm.setTitle(e.target.value)
          }}
          fullWidth
          margin='none'
          variant='outlined'
        />
      </div>
    )
  }

  return (
    <div id='PulseQuestionTitle'>
    {renderQuestionTitle()}
    </div>
  )
}

export default inject('pulseQuestionsStore')(observer(PulseQuestionTitle))
