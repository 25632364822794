import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../localization/LocalizationStore'
import { NotificationsStore } from '../store/NotificationsStore'
import { CardHeader, Typography } from '@material-ui/core'
import Searchbar from '../../shared/Searchbar'
import { NotificationsWidgetVM } from '../store/view-models/NotificationsWidgetVM'
import './Header.scss'

interface HeaderProps {
  notificationsStore?: NotificationsStore
  localizationStore?: LocalizationStore
  vm: NotificationsWidgetVM
}

const Header: React.FC<HeaderProps> = ({ localizationStore, notificationsStore, vm }) => {
  if (!vm.shouldRender) return

  return (
    <CardHeader
      id='NotificationTemplatesHeader'
      action={
        <>
          <div id='ManageNotificationsSearch'>
            <Searchbar
              focused={vm.typedFilterText.length > 0}
              className='search'
              searchValue={vm.typedFilterText}
              onSearchChange={(e) => vm.setQuickFilter(e.target.value)}
            />
          </div>
        </>
      }
      title={<Typography className='template-title'>{localizationStore.lzStrings.notificationsWidget.notifications}</Typography>}
    />
  )
}

export default inject('localizationStore', 'notificationsStore')(observer(Header))
