import { IUserAuthOrgInfoResult } from '../interfaces/IUserAuthInfoResult'
import { computed } from 'mobx'

export class SignInMethodRowVM {
  public option: IUserAuthOrgInfoResult

  constructor(option: IUserAuthOrgInfoResult) {
    this.option = option
  }

  @computed
  public get name(): string {
    if (this.option.oidcPromptText) return this.option.oidcPromptText
    if (this.option.requireOIDCAuth || this.option.oidcConnectUrl) {
      return 'Sign in to ' + this.option.name + ' with ' + this.option.oidcProvider
    }
    return 'Sign in to other tenant with password'
  }

  @computed
  public get id(): string {
    return this.option.id
  }
}
