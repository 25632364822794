import React from 'react'
import { observer, inject } from 'mobx-react'
import { UserSurveysStore } from '../../store/UserSurveysStore'
import rootStore from '../../../stores/RootStore'
import LocalizationStore from '../../../localization/LocalizationStore'
import {
  AppBar,
  Grid,
  LinearProgress,
  Link,
  Toolbar,
  Button,
  Fade,
  Typography,
  CardContent,
  Card,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import './ScreenHeader.scss'
import { UserSurveyTakeVM } from '../../view-models/UserSurveyTakeVM'
import { AppStore } from '../../../stores/AppStore'
import { LabelsStore } from '../../../labels/store/LabelsStore'

interface Props {
  userSurveysStore?: UserSurveysStore
  localizationStore?: LocalizationStore
  takeVM: UserSurveyTakeVM
  appStore?: AppStore
  labelsStore?: LabelsStore
}

const ScreenHeader: React.FC<Props> = ({
  takeVM,
  userSurveysStore,
  localizationStore,
  appStore,
  labelsStore,
}) => {
  const store = userSurveysStore
  const strings = localizationStore.lzStrings.takeSurvey

  if (!appStore.isOnline) {
    return (
      <Card
        id='UserSurveyTakeWidget'
        style={{ marginTop: 100 }}
        elevation={1}
        key={takeVM ? takeVM.key : 'tempkey'}
      >
        <div id='UserSurveyWidgetContent'>
          <CardContent>
            <Fade in timeout={1500}>
              <div className='survey-not-found-container'>
                <div style={{ textAlign: 'left' }}>
                  <Typography variant='body1' className='survey-not-found-typography'>
                    Application offline. Please try again later.
                  </Typography>
                </div>
              </div>
            </Fade>
          </CardContent>
        </div>
      </Card>
    )
  }

  if (!store.takeVM && !store.anonymousSurveyNotFound) return null

  let surveyText = (
    <Skeleton
      variant='text'
      width='60%'
      height='30px'
      style={store.anonymousSurveyNotFound ? { display: 'none' } : null}
    />
  )

  let progressBar = (
    <Skeleton
      variant='text'
      width='100%'
      height='30px'
      style={store.anonymousSurveyNotFound ? { display: 'none' } : null}
    />
  )

  if (takeVM && takeVM.name) {
    surveyText = (
      <span className='survey-text'>
        {labelsStore.getLabelByLanguageAndFor('pulses')} &gt;{' '}
        {`${strings.take_} ${labelsStore.getLabelByLanguageAndFor('pulse')}`}
      </span>
    )
  }

  if (takeVM && takeVM.percentComplete) {
    progressBar = <LinearProgress variant='determinate' value={takeVM.percentComplete} />
  }

  return (
    <AppBar id='ScreenHeader' position='sticky' elevation={0}>
      <Toolbar>
        <Grid container>
          <Grid item xs={12} md={7} className='survey-and-link'>
            {surveyText}
          </Grid>
          <Grid item xs={12} md={5}>
            {progressBar}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default inject(
  'userSurveysStore',
  'localizationStore',
  'appStore',
  'labelsStore'
)(observer(ScreenHeader))
