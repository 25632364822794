import React from 'react'
import { inject, observer } from 'mobx-react'
import { PulseCampaignsStore } from '../../store/PulseCampaignsStore'
import LocalizationStore from '../../../localization/LocalizationStore'
import MuiConfirmPrompt from '../../../shared/MuiConfirmPrompt'
import { LabelsStore } from '../../../labels/store/LabelsStore'

interface Props {
  pulseCampaignsStore?: PulseCampaignsStore
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
}

const ConfirmSaveDialog: React.FC<Props> = ({
  pulseCampaignsStore,
  localizationStore,
  labelsStore,
}) => {
  const vm = pulseCampaignsStore.viewModels.editVM
  if (!vm) return null
  const { pulseCampaign: lz } = localizationStore.lzStrings

  if (!vm.confirmSaveDialogOpen) return
  if (vm.campaignStatus === 'initiated') {
    return (
      <MuiConfirmPrompt
        confirmMessage={lz.are_you_sure_update}
        confirmMessageSecondary={`${labelsStore.getLabelByLanguageAndFor('pulses')} ${
          lz.will_be_sent
        }`}
        onClose={() => vm.toggleConfirmSave()}
        open={vm.confirmSaveDialogOpen}
        onConfirm={() => vm.save()}
        title={`${lz.update} ${labelsStore.getLabelByLanguageAndFor('pulse')} ${lz.campaign}`}
      />
    )
  }
  if (vm.doInitiate) {
    return (
      <MuiConfirmPrompt
        confirmMessage={lz.are_you_sure}
        confirmMessageSecondary={`${labelsStore.getLabelByLanguageAndFor('pulses')} ${
          lz.will_be_sent
        }`}
        onClose={() => vm.toggleConfirmSave()}
        open={vm.confirmSaveDialogOpen}
        onConfirm={() => vm.save()}
        title={`${lz.initiate} ${labelsStore.getLabelByLanguageAndFor('pulse')} ${lz.campaign}`}
      />
    )
  } else {
    if (vm.templatesVM.confirmSwitchTemplate) {
      return (
        <MuiConfirmPrompt
          confirmMessage={lz.preview_template}
          onClose={() => {
            vm.toggleConfirmSave()
            vm.templatesVM.reset()
          }}
          open={vm.confirmSaveDialogOpen}
          onConfirm={() => vm.templatesVM.proceedSelectTemplate()}
          title={lz.are_you_sure_template_preview}
        />
      )
    } else {
      return (
        <MuiConfirmPrompt
          confirmMessage={lz.are_you_sure_draft}
          onClose={() => vm.toggleConfirmSave()}
          open={vm.confirmSaveDialogOpen}
          onConfirm={() => vm.save()}
          title={lz.save_campaign_draft}
        />
      )
    }
  }
}

export default inject(
  'pulseCampaignsStore',
  'localizationStore',
  'labelsStore'
)(observer(ConfirmSaveDialog))
