import React from 'react'
import rootStore from '../../stores/RootStore'
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import TopToolbarImport from './TopToolbarImport'
import './SurveyTemplatesImport.scss'
import { SurveyTemplatesImportVM } from '../view-models/SurveyTemplatesImportVM'
import { inject, observer, useLocalStore } from 'mobx-react'
import VirtualList from 'react-tiny-virtual-list'

const SurveyTemplatesImport: React.FC = () => {
  const localStore = useLocalStore(() => ({
    vm: new SurveyTemplatesImportVM(rootStore),
  }))
  const vm = localStore.vm

  const renderSpinner = () => {
    if (!vm.importLoading) return
    return (
      <div className='spinner-container'>
        <CircularProgress />
      </div>
    )
  }

  return (
    <div id='SurveyTemplatesImport'>
      <div>
        <TopToolbarImport vm={vm} />
        <div className='page-wrapper'>
          <div className='page-left'>
            <div className='import-field-wrapper'>
              <Card className='ui-card'>
                <CardContent>
                  <div className='primary-text'>Survey IDs</div>
                  <TextField
                    id='survey-templates-import'
                    multiline
                    minRows={15}
                    onChange={(e) => vm.setSurveyTemplateIds(e.target.value)}
                    value={vm.surveyTemplateIds}
                    variant='outlined'
                    placeholder='Enter Survey IDs, one per line'
                    className='survey-ids-field'
                    fullWidth
                  />
                  <Button
                    disabled={vm.surveyTemplateIds === ''}
                    variant='contained'
                    className='import-btn'
                    fullWidth
                    onClick={() => vm.importSurvey()}
                  >
                    Import
                  </Button>
                </CardContent>
              </Card>
            </div>
          </div>
          <div className='page-right'>
            <div className='import-field-wrapper right'>
              <Card className='ui-card'>
                <CardContent>
                  <div className='primary-text'>Import Status</div>
                  <Grid container className='import-list'>
                    {renderSpinner()}
                    <VirtualList
                      should-force-update={vm.newTemplates}
                      height='100%'
                      width='100%'
                      itemCount={vm.newTemplates.length}
                      itemSize={40}
                      renderItem={({ index }) => {
                        const row = vm.newTemplates[index]
                        return (
                          <Grid item key={index} className='import-text'>
                            <Typography className='em'>{`${row}`}</Typography>
                            <Typography>{` has successfully been imported`}</Typography>
                          </Grid>
                        )
                      }}
                    />
                    <Typography className='error-text'>{vm.errorMessage}</Typography>
                  </Grid>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default inject()(observer(SurveyTemplatesImport))
