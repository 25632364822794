import React from 'react'
import { IndexRoute, Route } from 'react-router'
import Layout from '../../layout/Layout'
import Visualizations from '../../user-worksheets/views/Visualizations'
import VisualizationReportViewer from 'src/app/user-worksheets/views/VisualizationReportViewer'

import { authenticate } from '../../auth/routes/AuthenticatedRoute'

export default function getUserWorksheetsRoutes() {
  return (
    <Route key={'userWorksheets'} component={authenticate(Layout)} path='/'>
      <IndexRoute
        onEnter={(nextState, replace) => {
          replace('/userWorksheets')
        }}
      />
      <Route key={'userWorksheetsGrid'} path='userWorksheets' component={Visualizations} />
      <Route key={'userWorksheetsReport'} path='userWorksheets/report/:id' component={VisualizationReportViewer} />
    </Route>
  )
}
