import { observable, computed, action, reaction, IReactionDisposer } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { ICMSItemDeleteRequest } from '../interfaces/ICMSItemDeleteRequest'
import { ICMSFileEditRequest } from '../../cms-files/interfaces/ICMSEditFileRequest'
import { ICMSItemEditRequest } from '../interfaces/ICMSItemEditRequest'
import { ICMSItemUploadRequest } from '../interfaces/ICMSItemUploadRequest'
import { CMSFilesUploadService } from 'src/app/cms-files/services/CMSFilesUploadService'
import { CMSItemsUploadService } from '../service/CMSItemsUploadService'
import { CMSItemsDeleteService } from '../service/CMSItemsDeleteService'
import { CMSItemsManageVM } from './CMSItemsManageVM'
import { CMSItemVM } from './CMSItemVM'
import { CMSItemExternalVideoPreviewVM } from './CMSItemExternalVideoPreviewVM'

export class CMSItemsPreviewVM {
  private rootStore: RootStore
  private reactions: IReactionDisposer[] = []
  private manageVM: CMSItemsManageVM

  constructor(rootStore: RootStore, manageVM: CMSItemsManageVM) {
    this.rootStore = rootStore
    this.lz = this.rootStore.localizationStore.lzStrings.contentManagement
    this.manageVM = manageVM
  }

  @observable public lz = undefined
  @observable public item: CMSItemVM = null
  @observable public itemClean: CMSItemVM = null
  @observable public uploadFile: ICMSItemUploadRequest = null
  @observable public uploadFileClean: ICMSItemUploadRequest = null
  @observable public isOpen: boolean = false
  @observable public isSaving: boolean = false
  @observable public isLoading: boolean = false
  @observable public confirmDialogOpen: boolean = false
  @observable public doReset: boolean = false
  @observable public doClose: boolean = false
  @observable public isSnackbarOpen: boolean = false
  @observable public snackbarMessage: string = ''
  @observable public isConfimDeleteDialogOpen: boolean = false

  @observable public saveTried: boolean = false
  @observable public isDirty: boolean = false
  @observable public isExpanded: boolean = true

  @observable public doneTried: boolean = false
  @observable public isReadOnly: boolean = true
  @observable public title: string = ''
  @observable public description: string = ''
  @observable public tags: string[] = []
  @observable public tenants: string[] = []

  @observable public extVideoUrl: string = ''
  @observable public isExternalVideo: boolean = false
  @observable public isPrivate: boolean = false
  @observable public extVideoPreviewVM: CMSItemExternalVideoPreviewVM = null

  @computed
  public get file(): File {
    if (!this.uploadFile) return undefined
    return this.uploadFile.file
  }

  @computed
  public get id(): number {
    if (!this.uploadFile) return undefined
    return this.uploadFile.id
  }

  @computed
  public get canManage() {
    return this.manageVM.canManage
  }

  @computed
  public get canManageTags() {
    return this.manageVM.canManageTags
  }

  @computed
  public get hasTags(): boolean {
    if (!this.tags) return false
    return true
  }

  @computed
  public get isLoaded(): boolean {
    return this.manageVM.isLoaded
  }

  @computed
  public get isValid() {
    if (!this.isTitleValid) return false
    if (!this.isDescriptionValid) return false
    if (!this.isExternalVideo && !this.isFileValid) return false
    if (this.isExternalVideo && !this.isExtVideoUrlValid) return false
    return true
  }

  @computed
  public get isTitleValid() {
    if (!this.title && this.doneTried) return false
    if (this.title.trim() === '' && this.doneTried) return false
    return true
  }

  @computed
  public get isDescriptionValid() {
    if (!this.description && this.description !== '' && this.doneTried) return false
    return true
  }

  @computed
  public get isExtVideoUrlValid() {
    if (!this.isExternalVideo) return true
    if (!this.doneTried) return true
    const trimmed = this.extVideoUrl.toLowerCase().trim()
    if (trimmed.includes('vimeo.com/') && this.doneTried) return true
    if (trimmed.includes('youtube.com/') && this.doneTried) return true
    if (trimmed.includes('youtu.be/') && this.doneTried) return true
    return false
  }

  @computed
  public get isTagsValid() {
    if (!this.tags && this.doneTried) return false
    return true
  }

  @computed
  public get isTenantsValid() {
    if (!this.tenants && this.doneTried) return false
    return true
  }

  @computed
  public get isFileValid() {
    if (!this.file && this.doneTried) return false
    return true
  }

  @computed
  public get tagOptions() {
    return this.manageVM.tagOptions
  }

  @action
  public loadReactions() {
    this.reactions.forEach((dispose: IReactionDisposer) => dispose())

    this.reactions.push(
      reaction(
        () => JSON.stringify(this.title),
        () => {
          const newVal = this.uploadFile ? this.uploadFile.title : false
          const oldVal = this.title
          this.deepEqual(newVal, oldVal)
        }
      )
    )

    this.reactions.push(
      reaction(
        () => JSON.stringify(this.description),
        () => {
          const newVal = this.uploadFile ? this.uploadFile.description : false
          const oldVal = this.description
          this.deepEqual(newVal, oldVal)
        }
      )
    )

    this.reactions.push(
      reaction(
        () => JSON.stringify(this.isPrivate),
        () => {
          const newVal = this.uploadFile ? this.uploadFile.isPrivate : false
          const oldVal = this.isPrivate
          this.deepEqual(newVal, oldVal)
        }
      )
    )

    this.reactions.push(
      reaction(
        () => JSON.stringify(this.tags),
        () => {
          const newVals = this.uploadFile ? this.uploadFile.tags.slice() : undefined
          const oldVals = this.tags ? this.tags.slice() : undefined
          this.deepEqual(newVals, oldVals)
        }
      )
    )

    this.reactions.push(
      reaction(
        () => JSON.stringify(this.isExternalVideo),
        () => {
          const newVal = this.uploadFile ? Boolean(this.uploadFile.extVideoUrl) : false
          const oldVal = this.isExternalVideo
          this.deepEqual(newVal, oldVal)
        }
      )
    )

    this.reactions.push(
      reaction(
        () => JSON.stringify(this.extVideoUrl),
        () => {
          const newVal = this.uploadFile ? this.uploadFile.extVideoUrl : ''
          const oldVal = this.isExternalVideo
          this.deepEqual(newVal, oldVal)
        }
      )
    )
  }

  @action
  public setItem(item: CMSItemVM) {
    this.item = item
    this.itemClean = item
  }

  @action
  public setUpload(upload: ICMSItemUploadRequest, keepFields: boolean = false) {
    this.uploadFile = upload
    if (!upload) {
      this.uploadFileClean = upload
      if (!keepFields) {
        this.setTitle('')
        this.setDescription('')
      }
    } else {
      let opts = undefined
      if (upload.file && upload.file.type) {
        opts = { type: upload.file.type }
      }
      this.uploadFileClean = {
        title: upload.title,
        description: upload.description,
        file: new File([upload.file], upload.file.name, opts),
        organizationId: this.rootStore.appStore.currentOrgId,
        isPrivate: upload.isPrivate,
        privateTo: upload.privateTo ? upload.privateTo : undefined,
        tags: upload.tags ? upload.tags : [],
        extVideoUrl: upload.extVideoUrl ? upload.extVideoUrl : '',
      }

      this.setTitle(upload.title)
      this.setDescription(upload.description)

      if (upload.extVideoUrl) {
        this.setExtVideo(true)
        this.setExtVideoUrl(upload.extVideoUrl)
      } else {
        this.setExtVideo(false)
        this.setExtVideoUrl('')
      }
      this.setPrivate(upload.isPrivate)
      this.setTagValues(upload.tags)
    }

    if (!keepFields) this.loadReactions()
  }

  @action
  public setTitle = (title: string) => {
    this.title = title
  }

  @action
  public setDescription = (description: string) => {
    this.description = description
  }

  @action
  public setExtVideo = (isExternalVideo: boolean) => {
    this.isExternalVideo = isExternalVideo

    if (this.isExternalVideo) {
      this.extVideoPreviewVM = new CMSItemExternalVideoPreviewVM('')
    } else {
      this.extVideoPreviewVM = undefined
      this.setExtVideoUrl('')
    }
  }

  @action
  public toggleExtVideoEnabled = () => {
    this.isExternalVideo = !this.isExternalVideo
    if (this.isExternalVideo) {
      this.extVideoPreviewVM = new CMSItemExternalVideoPreviewVM('')
      this.setUpload(undefined, true)
    } else {
      this.extVideoPreviewVM = undefined
      this.setExtVideoUrl('')
    }
  }

  @action
  public setExtVideoUrl = (extVideoUrl: string) => {
    this.extVideoUrl = extVideoUrl
    if (this.extVideoPreviewVM) this.extVideoPreviewVM.setUrl(this.extVideoUrl)
  }

  @action
  public togglePrivateEnabled = () => {
    this.isPrivate = !this.isPrivate
  }

  @action
  public setPrivate = (isPrivate: boolean) => {
    this.isPrivate = isPrivate
  }

  @action
  public setOpen(isOpen: boolean) {
    this.isOpen = isOpen
  }

  @action
  public setIsDirty() {
    this.isDirty = true
  }

  @action
  public reset() {
    this.confirmDialogOpen = false
    this.setTitle(this.uploadFileClean ? this.uploadFileClean.title : '')
    this.setDescription(this.uploadFileClean ? this.uploadFileClean.description : '')
    this.setUpload(this.uploadFileClean ? this.uploadFileClean : undefined)
    this.tags = this.uploadFileClean && this.uploadFileClean.tags ? this.uploadFileClean.tags : []
    this.tenants = []
    this.doneTried = false
    this.doReset = false
    this.saveTried = false
    this.isExternalVideo =
      this.uploadFileClean && this.uploadFileClean.extVideoUrl
        ? Boolean(this.uploadFileClean.extVideoUrl)
        : false
    this.extVideoUrl = this.isExternalVideo ? this.uploadFileClean.extVideoUrl : ''
    this.extVideoPreviewVM = this.isExternalVideo
      ? new CMSItemExternalVideoPreviewVM('')
      : undefined
    this.isDirty = false
  }

  @action
  public clear() {
    this.setUpload(undefined)
    this.tags = []
    this.tenants = []
    this.doneTried = false
    this.isExternalVideo = false
    this.extVideoUrl = ''
    this.extVideoPreviewVM = undefined
    this.isDirty = false
  }

  @action
  public async handleDelete(id: number, cb?: Function) {
    const req = {
      organizationId: this.rootStore.appStore.currentOrgId,
      id: id,
    } as ICMSItemDeleteRequest
    const deleteSvc = new CMSItemsDeleteService(this.rootStore)
    await deleteSvc.deleteItem(req).then(async (res) => {
      this.manageVM.refreshData()
      this.hideDeleteConfirmDialog()
      this.closePreviewDialog()
      if (cb) cb()
    })
  }

  @action
  public async handleEdit(cb?: Function) {
    const reqCMSFile = {
      alternativeText: this.itemClean.altText,
      caption: this.description,
      name: this.title,
      id: this.itemClean.fileId,
      file: this.file,
    } as ICMSFileEditRequest

    const cmsFileSvc = new CMSFilesUploadService(this.rootStore)
    await cmsFileSvc.editFile(reqCMSFile).then(async (res) => {
      this.itemClean.item.Description = this.description
      this.itemClean.item.Title = this.title

      const reqCMSItem = {
        item: this.itemClean.item,
        id: this.itemClean.itemId,
        organizationId: this.rootStore.appStore.currentOrgId,
        isPrivate: this.isPrivate,
        privateTo: this.isPrivate ? this.rootStore.appStore.currentUserId : undefined,
        tags: this.tags,
      } as ICMSItemEditRequest

      const cmsItemsSvc = new CMSItemsUploadService(this.rootStore)
      await cmsItemsSvc.editItem(reqCMSItem).then(async (res) => {
        {
          this.isSaving = false
          this.enableReadOnly()
          this.manageVM.refreshData()
          if (cb) cb()
        }
      })
    })
  }

  @action
  public toggleShowPreviewDialog = () => {
    this.isOpen = !this.isOpen
  }

  @action
  public openPreviewDialog = () => {
    this.isOpen = true
  }

  @action
  public closePreviewDialog = () => {
    this.confirmDialogOpen = false
    this.isOpen = false
    this.clear()
    this.isReadOnly = true
    this.doClose = false
  }

  @action
  public toggleConfirmDialog() {
    this.confirmDialogOpen = !this.confirmDialogOpen
  }

  @action
  public hideConfirmDialog() {
    this.confirmDialogOpen = false
    this.doClose = false
    this.doReset = false
  }

  @action
  public toggleReadOnly() {
    this.isReadOnly = !this.isReadOnly
  }

  @action
  public enableReadOnly() {
    this.isReadOnly = true
  }

  @action
  public disableReadOnly() {
    this.isReadOnly = false
  }

  @action
  public openSnackbar(msg) {
    this.snackbarMessage = msg
    this.isSnackbarOpen = true
  }

  @action
  public closeSnackbar() {
    this.isSnackbarOpen = false
  }

  @action
  public markSaveTried() {
    this.saveTried = true
  }

  @action
  public setTagValues(vals: string[]) {
    if (!vals) this.tags = []
    this.tags = vals
  }

  @action
  public setTenantValues(vals: string[]) {
    if (!vals) this.tenants = []
    this.tenants = vals
  }

  @action
  public showDeleteConfirmDialog() {
    this.isConfimDeleteDialogOpen = true
  }

  @action
  public hideDeleteConfirmDialog() {
    this.isConfimDeleteDialogOpen = false
  }

  @action
  public deepEqual(object1, object2) {
    if (this.isSaving) return
    const keys1 = Object.keys(object1)
    const keys2 = Object.keys(object2)
    if (keys1.length !== keys2.length) {
      this.isDirty = true
    }
    for (const key of keys1) {
      const val1 = object1[key]
      const val2 = object2[key]
      // added to avoid false flag on empty mobx arrays
      if (JSON.stringify(val1) === '[]' && JSON.stringify(val2) === '[]') return
      if (val1 !== val2) {
        this.isDirty = true
      }
      const areObjects = this.isObject(val1) && this.isObject(val2)
      if (areObjects && !this.isDirty) this.deepEqual(val1, val2)
    }
    if (!this.isObject(object1) && !this.isObject(object2)) {
      if (object1 !== object2) {
        this.isDirty = true
      }
    }
  }

  @action
  public disposeReactions() {
    if (!this.reactions) return
    this.reactions.forEach((dispose: IReactionDisposer) => dispose())
  }

  @action
  public isObject(object) {
    return object != null && typeof object === 'object'
  }

  public toDTO() {
    return {}
  }
}
