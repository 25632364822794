import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { EventsStore } from '../../store/EventsStore'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Grid, InputLabel, TextField, Select, MenuItem, Button } from '@material-ui/core'
import { EventTypesStore } from '../../../event-types/store/EventTypesStore'
import { IEventTypeModel } from '../../../event-types/store/model/EventType'
import EventType from '../../../event-types/store/aggregate/EventType'
import { IEventTypeDTO } from '../../../event-types/store/dtos/IEventTypeDTO'

interface ICalendarEventDrawerType {
  localizationStore?: LocalizationStore
  eventsStore?: EventsStore
  eventTypesStore?: EventTypesStore
}

const CalendarEventDrawerType: React.FC<ICalendarEventDrawerType> = ({
  localizationStore,
  eventsStore,
  eventTypesStore,
}) => {
  const { lzStrings } = localizationStore
  const { editVM: vm } = eventsStore

  const renderNewTypeFields = () => {
    if (!vm.addNewType || !vm.canEditType) return null
    return (
      <>
        <Grid item md={12} sm={12}>
          <InputLabel required>{lzStrings.calendarEventDrawer.name}</InputLabel>
          <TextField
            className='event-type2-input'
            error={!vm.eventTypeValid}
            value={vm.newEventType.name}
            onChange={(e) => vm.setNewEventTypeName(e.target.value)}
            fullWidth
            variant='outlined'
            id='name'
          />
        </Grid>

        <Grid container className='descriptionContainer'>
          <Grid item md={12} sm={12}>
            <InputLabel>{lzStrings.calendarEventDrawer.description}</InputLabel>
            <TextField
              className='event-type2-input'
              multiline
              minRows={5}
              margin='none'
              value={vm.newEventType.description}
              onChange={(e) => vm.setNewEventTypeDescription(e.target.value)}
              fullWidth
              variant='outlined'
              id='description'
            />
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          id='save-resource-button'
          className='calendar-event-drawer-resources-grid button'
        >
          <Button id='cancel-button' variant='text' onClick={(e) => vm.toggleAddNewEventType()}>
            {lzStrings.calendarEventDrawer.cancel}
          </Button>
          <Button id='save-type-button' variant='contained' onClick={(e) => vm.saveNewEventType()}>
            {lzStrings.calendarEventDrawer.save_type}
          </Button>
        </Grid>
      </>
    )
  }

  const renderTypeField = () => {
    if (vm.addNewType) return null
    return (
      <Grid item xs={12} className='event-type-input'>
        <InputLabel htmlFor='eventTypesSearch'>
          {lzStrings.calendarEventDrawer.event_type}
        </InputLabel>
        <Autocomplete
          className='eventTypesSearchBar'
          filterSelectedOptions
          options={vm.eventTypes}
          getOptionLabel={(option) => option.name}
          filterOptions={(options, params) => {
            if (vm.canEditType) {
              options.push({
                objectId: '',
                organizationId: '',
                name: 'Add New...',
                description: '',
                resourceId: '',
                isDeleted: false,
              })
            }

            return options
          }}
          id='eventTypesSearch'
          value={vm.selectedEventTypeOption}
          onChange={(e, value: IEventTypeDTO) => vm.handleEventTypeChange(value as EventType)}
          renderInput={(params) => (
            <TextField {...params} margin='none' variant='outlined' fullWidth />
          )}
        />
      </Grid>
    )
  }

  return (
    <>
      {renderTypeField()}
      {renderNewTypeFields()}
    </>
  )
}

export default inject(
  'localizationStore',
  'eventsStore',
  'eventTypesStore'
)(observer(CalendarEventDrawerType))
