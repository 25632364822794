import React from 'react'
import { inject, observer } from 'mobx-react'
import CloseIcon from '@material-ui/icons/Close'
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Button,
  Typography,
} from '@material-ui/core'
import { ContactsStore } from '../../contacts/store/ContactsStore'
import { ClientsStore } from '../store/ClientsStore'
import { LabelsStore } from '../../labels/store/LabelsStore'
import LocalizationStore from '../../localization/LocalizationStore'

interface ClientContactListProps {
  contactsStore?: ContactsStore
  clientsStore?: ClientsStore
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
}

const ClientContactList: React.FC<ClientContactListProps> = ({
  contactsStore,
  clientsStore,
  localizationStore,
  labelsStore,
}) => {
  const { lzStrings } = localizationStore
  const { clientEditVM: vm } = clientsStore

  const renderTableBody = () => {
    if (!vm) return
    return vm.contacts.map((contact, index) => {
      return (
        <TableRow hover key={contact.objectId + 'contact'}>
          <TableCell className='name-cell'>{contact.name}</TableCell>
          <TableCell className='email-cell'>
            {contact.email ? contact.email : '—'}
            <CloseIcon
              className='delete-icon'
              onClick={() => {
                vm.removeContact(index)
              }}
            />
          </TableCell>
        </TableRow>
      )
    })
  }

  return (
    <div id='ClientContactList'>
      <h6>
        {clientsStore.clientEditVM.contacts.length === 0 ? 'No ' : ''}
        {lzStrings.clientManagementWidget.contacts}
      </h6>
      {vm.contacts.length > 0 && (
        <Table stickyHeader size='small' className='temp-table'>
          <TableHead>
            <TableRow key='eventTypes-header'>
              <TableCell>Name</TableCell>
              <TableCell>Email Address</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderTableBody()}</TableBody>
        </Table>
      )}
      {clientsStore.clientEditVM.objectId ? (
        <Button
          className='add-contact-button'
          variant='outlined'
          onClick={() => contactsStore.addNewContact(vm.client)}
        >
          Add
        </Button>
      ) : (
        <Typography className='new-client-save'>Save new client before adding contacts.</Typography>
      )}
    </div>
  )
}
export default inject(
  'contactsStore',
  'clientsStore',
  'localizationStore',
  'labelsStore'
)(observer(ClientContactList))
