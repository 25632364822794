import React from 'react'
import { inject, observer } from 'mobx-react'
import Button from '@material-ui/core/Button'
import { Grid, Typography } from '@material-ui/core'
import './AnnouncementEditTabPanel.scss'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import { AnnouncementsStore } from '../store/AnnouncementsStore'
import { OrganizationsStore } from '../../organizations/store/OrganizationsStore'
import FileUploadModal from '../../upload/views/FileUploadModal'
import { FileTypeEnum } from '../../upload/enum/FileTypeEnum'
import CMSItemsPickerDialog from '../../cms-items/views/dialogs/CMSItemsPickerDialog'
import { CMSItemsTypeEnum } from '../../cms-items/enum/CMSItemsTypeEnum'
import { Attachment } from '../../attachments/aggregate/Attachment'
import { AttachmentVM } from '../../attachments/view-models/AttachmentVM'
import { CMSItemAttachmentVM } from '../../attachments/view-models/CMSItemAttachmentVM'
import { OldAttachmentVM } from '../../attachments/view-models/OldAttachmentVM'
import CMSThumbnail from '../../thumbnail/views/CMSThumbnail'
import OldThumbnail from '../../thumbnail/views/OldThumbnail'

interface Props {
  localizationStore?: LocalizationStore
  announcementsStore?: AnnouncementsStore
  organizationsStore?: OrganizationsStore
}

const AnnouncementAttachments: React.FC<Props> = ({
  localizationStore,
  announcementsStore,
  organizationsStore,
}) => {
  const { tasksManagementWidgetDrawer: strings } = localizationStore.lzStrings
  const { editVM: vm } = announcementsStore
  if (!vm) return null

  const renderAttachmentThumbnail = (item: AttachmentVM, index: number) => {
    if (item.isCMSItem)
      return (
        <CMSThumbnail
          key={`announce-cms-attach-thumb-${index}`}
          vm={item as CMSItemAttachmentVM}
          index={index}
          onClose={() => vm.removeAttachmentByIndex(index)}
        />
      )
    return (
      <OldThumbnail
        key={`announce-old-attach-thumb-${index}`}
        vm={item as OldAttachmentVM}
        index={index}
        onClose={() => vm.removeAttachmentByIndex(index)}
      />
    )
  }

  const renderAttachments = () => {
    if (!vm.attachments) return null
    return (
      <form>
        <Grid container className='attachments-list'>
          {vm.attachments.map((doc, index) => {
            return renderAttachmentThumbnail(doc, index)
          })}
        </Grid>
      </form>
    )
  }

  const renderUploadModal = () => {
    if (organizationsStore.currentOrganization?.hasCMSPicker) return renderCMSUploadModal()
    return renderStandardUploadModal()
  }

  const renderCMSUploadModal = () => {
    return (
      <CMSItemsPickerDialog
        allowMultiple={true}
        fileTypes={CMSItemsTypeEnum.DEFAULT}
        addAfterSave={(arr: Attachment[]) => {
          const attachments = arr.map((a) => {
            const attachment = new Attachment()
            attachment.cmsItemId = a.cmsItemId
            return attachment
          })
          vm.addAttachments(attachments)
        }}
        toggleShowModal={() => vm.toggleShowUploadModal()}
        isOpen={vm.showUploadModal}
      />
    )
  }

  const renderStandardUploadModal = () => {
    return (
      <FileUploadModal
        allowMultiple={true}
        fileTypes={FileTypeEnum.DEFAULT}
        addAfterSave={(a: Attachment[]) => vm.addAttachments(a)}
        toggleShowModal={() => vm.toggleShowUploadModal()}
        isOpen={vm.showUploadModal}
      />
    )
  }

  return (
    <div id='attachmentsSection'>
      {renderUploadModal()}
      <Grid item xs={12} className='attachments-title'>
        <Typography variant='h5' className='input-label'>
          {strings.attachments}
        </Typography>
        <Button variant='text' onClick={() => vm.toggleShowUploadModal()}>
          {strings.add_attachments}
        </Button>
      </Grid>
      {renderAttachments()}
    </div>
  )
}

export default inject(
  'announcementsStore',
  'localizationStore',
  'organizationsStore'
)(observer(AnnouncementAttachments))
