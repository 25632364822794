import React, { FC } from 'react'
import { inject, observer } from 'mobx-react'
import { makeStyles } from '@material-ui/core/styles'
import { ImageList, ImageListItem, ImageListItemBar } from '@material-ui/core'
import ImageIcon from '@material-ui/icons/Image'
import VideocamIcon from '@material-ui/icons/Videocam'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import { MediaUploadPickerVM } from '../../view-models/media-upload-picker/MediaUploadPickerVM'
import { MediaItemsStore } from '../../../media-items/store/MediaItemsStore'
import MediaItemDrawer from '../../../media-items/views/Drawer/MediaItemDrawer'
interface MediaGridProps {
  mediaItemsStore?: MediaItemsStore
  mediaUploadPickerVM: MediaUploadPickerVM
}
const MediaGrid: FC<MediaGridProps> = ({ mediaItemsStore, mediaUploadPickerVM }) => {
  const classes = useStyles({})
  const vm = mediaUploadPickerVM
  if (!vm) return null

  const renderAddMediaTile = () => {
    return (
      <ImageListItem className='add-media-tile' key={0} cols={1} onClick={() => vm.openDrawer()}>
        <AddIcon />
      </ImageListItem>
    )
  }

  const renderVideoThumbnail = (item) => {
    if (item.type !== 'video') return null
    if (!item.videoThumbnail) return <VideocamIcon style={{ width: '100%', height: '100%' }} />
    return <img src={item.videoThumbnail} className={classes.video} />
  }

  return (
    <div className='media-grid' id='MediaGrid'>
      <ImageList rowHeight={160} className={classes.gridList} cols={3} gap={10}>
        {vm.mediaItems.map((item) => {
          const isSelected = item.objectId === vm.selectedMediaItemId
          return (
            <ImageListItem
              onMouseOver={() => item.showEdit()}
              onMouseOut={() => item.hideEdit()}
              key={item.objectId}
              className={isSelected ? classes.gridItemSelected : classes.gridItem}
              cols={1}
              onClick={() => vm.selectMediaItem(item.objectId)}
            >
              {renderVideoThumbnail(item)}
              {item.type != 'video' && <img src={item.fileUrl} className={classes.image} />}
              <ImageListItemBar
                position='top'
                className={classes.tilebar}
                title={item.name}
                actionIcon={
                  item.showEditIcon ? (
                    <EditIcon
                      className='edit-button'
                      onClick={() => vm.openDrawer(item.objectId)}
                    />
                  ) : item.type === 'image' ? (
                    <ImageIcon />
                  ) : (
                    <VideocamIcon />
                  )
                }
              />
            </ImageListItem>
          )
        })}
        {renderAddMediaTile()}
      </ImageList>
      <MediaItemDrawer />
    </div>
  )
}
const useStyles = makeStyles({
  gridList: {
    width: '100%',
    height: 'fit-content',
  },
  hidden: {
    display: 'none',
  },
  gridItem: {
    position: 'relative',
    cursor: 'pointer',
    '&:hover': {
      border: '2px solid #05d1cf',
    },
  },
  image: {
    position: 'absolute',
    zIndex: 1,
  },
  video: {
    position: 'absolute',
    zIndex: 1,
    height: '100%',
  },
  gridItemSelected: {
    position: 'relative',
    cursor: 'pointer',
    border: '2px solid #05d1cf',
  },
  tilebar: {
    zIndex: 3,
    color: 'lightGrey',
    paddingRight: 10,
  },
})
export default inject('mediaItemsStore')(observer(MediaGrid))
