import { observable, action, computed } from 'mobx'
import { Option } from './Option'
import { Media } from './Media'
import { QuestionType } from '../types/QuestionTypes'
import { RatingDisplayType } from 'src/app/surveys/types/RatingDisplayType'
import { serializable, serialize, deserialize, list, object } from 'serializr'
import { IQuestionDTO } from '../dtos/IQuestionDTO'
import { IAggregate } from '../../shared/data/IAggregate'

export class Question implements IQuestionDTO, IAggregate {
  @serializable @observable public objectId: string = ''
  @serializable @observable public organizationId: string = ''
  @serializable @observable public title: string = ''
  @serializable @observable public type: QuestionType
  @serializable(object(Media)) @observable public media: Media = new Media()
  @serializable(list(object(Option))) @observable public options: Option[] = []
  @serializable @observable public categoryId: string = ''
  @serializable @observable public surveyTypeId: string = ''
  @serializable @observable public isDeleted: boolean = false
  @serializable @observable public required: boolean = true
  @serializable @observable public ratingDisplay: RatingDisplayType = 'horizontal'
  @serializable @observable public decimalsAllowed: number = 0
  @serializable @observable public minimumValueAllowed: number = 0
  @serializable @observable public maximumValueAllowed: number = 0
  @serializable @observable public numberStep: number = 0
  @serializable @observable public minimumRequired: boolean = false
  @serializable @observable public maximumRequired: boolean = false
  @serializable @observable public numberStepRequired: boolean = false
  @serializable @observable public defaultValueNumeric: number = 0
  @serializable @observable public defaultValueRequired: boolean = false
  @serializable @observable public pulseCategoryId?: string = ''
  @serializable @observable public pulseQuestionId?: string = ''
  @serializable @observable public isDropdownMultiSelect: boolean = false
  @serializable @observable public placeholder: string = 'Please Select'

  public isOnServer: boolean = false

  @action
  public setDecimalsAllowed(val: number) {
    if (!val) val = 0
    this.decimalsAllowed = val
  }

  @action
  public toggleMinimumRequired() {
    this.minimumRequired = !this.minimumRequired
  }

  @action
  public toggleMaximumRequired() {
    this.maximumRequired = !this.maximumRequired
  }

  @action
  public toggleNumberStepRequired() {
    this.numberStepRequired = !this.numberStepRequired
  }

  @action
  public toggleDropdownMultiSelect() {
    this.isDropdownMultiSelect = !this.isDropdownMultiSelect
  }

  @action
  public setPlaceholder(placeholder: string) {
    this.placeholder = placeholder
  }

  @action
  public setMinimumValueAllowed(val: string) {
    this.minimumValueAllowed = Number(val)
  }

  @action
  public setMaximumValueAllowed(val: string) {
    this.maximumValueAllowed = Number(val)
  }

  @action
  public setNumberStep(val: any) {
    this.numberStep = val
  }

  public markIsNotOnServer() {
    this.isOnServer = false
  }

  public markIsOnServer() {
    this.isOnServer = true
  }

  public serialize(): IQuestionDTO {
    return serialize(this)
  }

  public clone(): Question {
    return deserialize(Question, this.serialize())
  }

  public makeCopy(): Question {
    const q = this.clone()
    q.objectId = undefined
    return q
  }

}
