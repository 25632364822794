import React, { FC } from 'react'
import rootStore from '../../../../stores/RootStore'
import {
  Toolbar,
  Typography,
  Button,
  Tooltip,
  CssBaseline,
  AppBar,
} from '@material-ui/core'
import DoneIcon from '@material-ui/icons/Check'
import '../../../../css/dashboard-toolbar.scss'
import { inject, observer } from 'mobx-react'
import { UserDashboardsStore } from '../../UserDashboardsStore'
import EditIcon from '@material-ui/icons/Edit'
import isIE11 from '../../../../../utils/isIE11'
import clsx from 'clsx'

export interface Props {
  children?: React.ReactElement
  userDashboardsStore?: UserDashboardsStore
}


const WorksheetsToolbar: FC<Props> = ({ userDashboardsStore }) => {
  const vm = userDashboardsStore.viewModels.userDashboardVM

  const renderWorksheetsButtons = () => {
    return (
      <>
        <Tooltip title={rootStore.localizationStore.lzStrings.dashboard.manage_dashboard_button_tooltip} placement='bottom'>
          <span>
            <Button
              disabled={Boolean(!vm.userDashboard)}
              className='dashboard-action btn-icon btn-icon-white'
              variant='outlined'
              onClick={() => vm.editDashboard()}
            >
              <EditIcon /> {rootStore.localizationStore.lzStrings.dashboard.manage_dashboard_button_text}
            </Button>
          </span>
        </Tooltip>
        {renderAddButton()}
      </>
    )
  }

  const renderAddButton = () => {
    if (!rootStore.appStore.canAssignWork) return null
    return (
      <Button
        disabled={Boolean(!vm.userDashboard)}
        variant='contained'
        onClick={() => vm.addWorksheet()}
        className='dashboard-action'
      >
        {rootStore.localizationStore.lzStrings.analysisDashboard.create_new_visualization}
      </Button>
    )
  }

  return (
    <>
      <div id='DashboardToolbar'>
        <CssBaseline />
        <AppBar
          className={clsx('dashboard-appbar', {
            'no-transition': isIE11,
          })}
          elevation={0}
        >
          <Toolbar className='dashboard-toolbar'>
            <Typography className='dashboard-title'>
              {rootStore.localizationStore.lzStrings.dashboard.manage_analysis}
            </Typography>
            <div className='dashboard-toolbar-right'>
              {renderWorksheetsButtons()}
              <Tooltip title={rootStore.localizationStore.lzStrings.analysisDashboard.done_managing_tooltip} placement='bottom'>
                <span>
                  <Button
                    disabled={Boolean(!vm.userDashboard)}
                    className='dashboard-action btn-icon'
                    variant='contained'
                    onClick={() => rootStore.appStore.router.push('/userWorksheets')}
                  >
                    <DoneIcon /> {rootStore.localizationStore.lzStrings.analysisDashboard.done_managing}
                  </Button>
                </span>
              </Tooltip>
            </div>
          </Toolbar>
        </AppBar>
        <Toolbar />
      </div>

    </>
  )
}

export default inject('userDashboardsStore')(observer(WorksheetsToolbar))
