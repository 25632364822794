import Parse from 'parse'
import { INotificationTemplateDTO } from '../notifications/store/model/INotificationTemplateDTO'
import { ISurveyUpdateResult } from '../surveys/interfaces/ISurveyUpdateResult'
import { UserSurvey } from '../user-surveys/aggregate/UserSurvey'

export class ParseService {
  //*** DO NOT ADD TO THIS FILE: USE MODULAR SERVICE CLASSES ****/
  //*** MARK ****/

  async getSurveyNotificationTemplates(surveyId: string, organizationId: string) {
    return await Parse.Cloud.run('getSurveyNotificationTemplates', {
      surveyId,
      organizationId,
    })
  }

  async createUserSurveyForPaperSurvey(
    orgId: string,
    surveyId: string,
    userId: string,
    survey: any,
    surveyOwnerUser: any,
    inputByUserId: string
  ) {
    const userSurvey = await Parse.Cloud.run('createUserSurveyForPaperSurvey', {
      orgId,
      surveyId,
      userId,
      survey,
      surveyOwnerUser,
      inputByUserId,
    })
    return userSurvey
  }

  async saveUserAvatar(orgId, url, userId) {
    await Parse.Cloud.run('saveUserAvatar', { orgId, url, userId })
  }

  async uploadMedia(file: File, name: string, orgId: string) {
    //Generate filename and save Parse.File object which utilizes Cloudinary storage via an adapter
    const fileName: string = new Date().getTime().toString()
    const newParseFile: Parse.File = new Parse.File(fileName, file, file.type)
    newParseFile.setTags({ organizationId: orgId })
    const parseFile = await newParseFile.save()
    const type = file.type

    //Use the save data to update the Parse media collections with the Cloudinary url, orgId, etc and via the Parse cloud function which accounts for data sharding
    const databaseObject = await Parse.Cloud.run('uploadMedia', {
      parseFile,
      name,
      type,
      orgId,
    })

    return databaseObject
  }

  async uploadCategoryFile(file) {
    const fileName = file.name
    const newFile = new Parse.File(fileName, file, 'text/csv')
    await newFile.save()
    return newFile
  }

  async processCategoriesFile(orgId, parseFile) {
    const data = await Parse.Cloud.run('importCategoriesFromCsv', { orgId, parseFile })
    return data
  }

  async getPrivileges(privileges) {
    const data = await Parse.Cloud.run('getPrivileges', privileges)

    return data
  }

  async refreshUserInfo(userId) {
    return (dispatch) => {
      Parse.Cloud.run('refreshUserInfo', { userId }).then((data) => {
        return data
      })
    }
  }

  async saveTaskTemplate(orgId: string, taskTemplate: any) {
    const data = await Parse.Cloud.run('saveTaskTemplate', { orgId, taskTemplate })
    return data
  }

  async deleteTaskTemplate(orgId: string, taskTemplateId: string) {
    const data = await Parse.Cloud.run('deleteTaskTemplate', { orgId, taskTemplateId })
    return data
  }

  async saveCategory(orgId: string, category: any) {
    const data = await Parse.Cloud.run('saveCategory', { orgId, category })
    return data
  }

  async deleteCategory(orgId: string, categoryId: any) {
    const data = await Parse.Cloud.run('deleteCategory', { orgId, categoryId })
    return data
  }

  async getAnonymousUserSurvey(token: string): Promise<any> {
    const query = new Parse.Query('userSurveys')
    query.equalTo('anonymousToken', token)
    const survey: Parse.Object = await query.first()
    if (!survey) return null
    return survey.toJSON()
  }

  async saveSurvey(orgId: string, survey: any, doPublish: boolean): Promise<ISurveyUpdateResult> {
    const data = await Parse.Cloud.run('saveSurvey', { orgId, survey, doPublish })
    return data
  }

  async publishSurvey(orgId: string, surveyId: string, userId: string) {
    const data = await Parse.Cloud.run('publishSurvey', {
      orgId,
      surveyId,
      userId,
    })
    return data
  }

  async deleteSurvey(orgId: string, surveyId: any) {
    const data = await Parse.Cloud.run('deleteSurvey', { orgId, surveyId })
    return data
  }

  async getOrganizationUsers(orgId: string) {
    const data = await Parse.Cloud.run('getOrgUsers', { orgId })
    return data
  }

  async getOrganizationLabels(organizationId: string) {
    const data = await Parse.Cloud.run('getOrgLabels', { organizationId })
    return data
  }

  async saveOrgLabel(orgId: string, value: string, language: string, defaultValue: string) {
    const data = await Parse.Cloud.run('saveOrgLabel', { orgId, value, language, defaultValue })
    return data
  }

  async updateOrganizationSettings(orgId, orgObj) {
    await Parse.Cloud.run('updateOrganizationSettings', { orgId, orgObj })
  }

  async deleteOrgUser(orgId, userId, isLastAdminCheckNeeded: boolean, adminRoleId: string) {
    await Parse.Cloud.run('deleteOrgUser', {
      orgId,
      userId,
      isLastAdminCheckNeeded,
      adminRoleId,
    })
  }

  async archiveOrgUser(orgId, userId, isLastAdminCheckNeeded: boolean, adminRoleId: string) {
    await Parse.Cloud.run('archiveOrgUser', {
      orgId,
      userId,
      isLastAdminCheckNeeded,
      adminRoleId,
    })
  }

  async archiveUserForAllOrgs(userId) {
    await Parse.Cloud.run('archiveUserForAllOrgs', {
      userId,
    })
  }

  async unArchiveOrgUser(orgId, userId, isLastAdminCheckNeeded: boolean, adminRoleId: string) {
    await Parse.Cloud.run('unArchiveOrgUser', {
      orgId,
      userId,
      isLastAdminCheckNeeded,
      adminRoleId,
    })
  }

  async processUsersFile(orgId, parseFile) {
    await Parse.Cloud.run('importUsersFromCsv', { orgId, parseFile })
  }

  async updateOrgGeneralDetails(orgId: string, data: Object): Promise<Parse.Object> {
    const result = await Parse.Cloud.run('updateOrgGeneralDetails', { orgId, data })
    return result
  }

  async acceptConsent(
    orgId: string,
    userId: string,
    consentId: string,
    versionNumber: number,
    deleteMyData: boolean = false
  ): Promise<Parse.Object> {
    const result = await Parse.Cloud.run('acceptConsent', { orgId, userId, deleteMyData })
    return result
  }

  async updateConsent(
    orgId: string,
    userId: string,
    text: Object,
    attachments
  ): Promise<Parse.Object> {
    const result = await Parse.Cloud.run('updateConsent', { orgId, userId, text, attachments })
    return result
  }

  async saveCalendarTimes(orgId, times) {
    await Parse.Cloud.run('saveCalendarTimes', { orgId, times })
  }

  async saveEventType(orgId, type) {
    await Parse.Cloud.run('saveEventType', { orgId, type })
  }

  async deleteEventType(orgId, typeId) {
    await Parse.Cloud.run('deleteEventType', { orgId, typeId })
  }

  async saveResource(orgId, resource) {
    await Parse.Cloud.run('saveResource', { orgId, resource })
  }

  async deleteResource(orgId, resourceId) {
    await Parse.Cloud.run('deleteResource', { orgId, resourceId })
  }

  async saveDashboard(orgId, dashboard) {
    dashboard.visitedAt = null
    dashboard.isFavorite = null
    const savedDashboardId = await Parse.Cloud.run('saveDashboard', { orgId, dashboard })
    return savedDashboardId
  }

  async saveDashboardFavorite(orgId, userId, dashboardId, isFavorite) {
    await Parse.Cloud.run('saveDashboardFavorite', { orgId, userId, dashboardId, isFavorite })
  }

  async deleteDashboard(orgId, dashboardId) {
    await Parse.Cloud.run('deleteDashboard', { orgId, dashboardId })
  }

  async saveVisitedTimeStamp(orgId, userId, dashboardId) {
    await Parse.Cloud.run('saveVisitedTimeStamp', { orgId, userId, dashboardId })
  }

  async ensureUserDashboard(orgId, userId) {
    await Parse.Cloud.run('ensureUserDashboard', { orgId, userId })
  }

  async saveDashboardShare(orgId, dashboardId, sharedWith) {
    await Parse.Cloud.run('saveDashboardShare', { orgId, dashboardId, sharedWith })
  }

  async saveUserSurveyResponse(
    surveyId,
    userSurveyId,
    orgId,
    userId,
    response,
    isLoggedIn,
    anonymousToken = null
  ) {
    const data = await Parse.Cloud.run('saveSurveyResponseV2', {
      surveyId,
      userSurveyId,
      orgId,
      userId,
      response,
      application: 'web',
      isLoggedIn,
      anonymousToken,
    })
    return data
  }

  async saveNotificationTemplate(
    orgId,
    channel,
    notificationTypeId,
    language,
    subject,
    body,
    isDefault
  ): Promise<INotificationTemplateDTO> {
    const data = await Parse.Cloud.run('saveNotificationTemplate', {
      orgId,
      channel,
      notificationTypeId,
      language,
      subject,
      body,
      isDefault,
    })
    return data
  }
  
  async saveDefaultNotificationTemplate(
    orgId,
    channel,
    notificationTypeId,
    language,
    subject,
    body,
    isDefault
  ): Promise<INotificationTemplateDTO> {
    const data = await Parse.Cloud.run('saveDefaultNotificationTemplate', {
      orgId,
      channel,
      notificationTypeId,
      language,
      subject,
      body,
      isDefault,
    })
    return data
  }

  async getDefaultNotificationTemplate(orgId, channel, notificationTypeId, language) {
    const result = await Parse.Cloud.run('getDefaultNotificationTemplate', {
      orgId,
      channel,
      notificationTypeId,
      language,
    })
    return result
  }

  async deleteNotificationTemplate(orgId, objectId) {
    await Parse.Cloud.run('deleteNotificationTemplate', { orgId, objectId })
  }

  async saveSurveyType(orgId, data) {
    const result = await Parse.Cloud.run('saveSurveyType', {
      orgId,
      data,
    })
    return result
  }

  async saveSurveyTag(orgId, data) {
    const result = await Parse.Cloud.run('saveSurveyTag', {
      orgId,
      data,
    })
    return result
  }

  async deleteSurveyType(orgId, objectId) {
    const result = await Parse.Cloud.run('deleteSurveyType', {
      orgId,
      objectId,
    })
    return result
  }

  async deleteSurveyTag(orgId, objectId) {
    const result = await Parse.Cloud.run('deleteSurveyTag', {
      orgId,
      objectId,
    })
    return result
  }

  async markUserTaskAsCompleted(orgId, userTaskId) {
    const result = await Parse.Cloud.run('markUserTaskAsCompleted', { orgId, userTaskId })
    return result
  }

  async markUserTaskAsIncomplete(orgId, userTaskId) {
    const result = await Parse.Cloud.run('markUserTaskAsIncomplete', { orgId, userTaskId })
    return result
  }

  async savePrivilegeSet(orgId, privilegeSetObj) {
    const data = await Parse.Cloud.run('savePrivilegeSet', { orgId, privilegeSetObj })
    return data
  }

  async deletePrivilegeSet(orgId, privilegeSetObjectId) {
    const data = await Parse.Cloud.run('deletePrivilegeSet', { orgId, privilegeSetObjectId })
    return data
  }

  async saveWorksheet(orgId, worksheet: any): Promise<string> {
    const id = await Parse.Cloud.run('saveWorksheet', { orgId, worksheet })
    return id
  }

  async saveClient(orgId, client: any) {
    const data = await Parse.Cloud.run('saveClient', { client, orgId })
    return data
  }

  async deleteClient(orgId, objectId) {
    const data = await Parse.Cloud.run('deleteClient', { orgId, objectId })
    return data
  }

  async saveContact(orgId, contact: any) {
    const data = await Parse.Cloud.run('saveContact', { contact, orgId })
    return data
  }

  async deleteContact(orgId, objectId) {
    const data = await Parse.Cloud.run('deleteContact', { orgId, objectId })
    return data
  }

  async copySurvey(orgId, surveyId, userId) {
    const data = await Parse.Cloud.run('copySurvey', { orgId, surveyId, userId })
    return data
  }

  async sendUserSurveyReminders(orgId, surveyId, invitations, sendAll, skipInvitations?) {
    await Parse.Cloud.run('sendUserSurveyReminders', {
      orgId,
      surveyId,
      invitations,
      sendAll,
      skipInvitations,
    })
  }

  async cancelSurveyInvitations(orgId, surveyId, invitations, sendAll, skipInvitations?) {
    await Parse.Cloud.run('cancelSurveyInvitations', {
      orgId,
      surveyId,
      invitations,
      sendAll,
      skipInvitations,
    })
  }

  async sendSurveyInvitations(orgId, surveyId, invitations, sendAll, skipInvitations?) {
    await Parse.Cloud.run('sendSurveyInvitationsV2', {
      orgId,
      surveyId,
      invitations,
      sendAll,
      skipInvitations,
    })
  }

  async saveSurveyAutomation(orgId, surveyAutomation: any): Promise<string> {
    const id = await Parse.Cloud.run('saveSurveyAutomation', { orgId, surveyAutomation })
    return id
  }

  async deleteWorksheet(worksheetId: string, orgId: string) {
    const result = await Parse.Cloud.run('deleteWorksheet', { worksheetId, orgId })
    return result
  }

  async deleteSurveyAutomation(surveyAutomationId: string, orgId: string) {
    const result = await Parse.Cloud.run('deleteSurveyAutomation', { surveyAutomationId, orgId })
    return result
  }

  async saveNewEvent(event, orgId) {
    const result = await Parse.Cloud.run('processNewEvent', { event, orgId })
    return result
  }

  async updateEvent(event, orgId) {
    const result = await Parse.Cloud.run('processChangedEvent', { event, orgId })
    return result
  }

  async saveWeightProfile(orgId, weightProfile) {
    const result = await Parse.Cloud.run('saveWeightProfile', { orgId, weightProfile })
    return result
  }

  async copyWeightProfileToRoles(orgId, weightProfileId, roleIds) {
    const result = await Parse.Cloud.run('copyWeightProfileToRoles', {
      orgId,
      weightProfileId,
      roleIds,
    })
    return result
  }

  async deleteWeightProfile(weightProfileId: string, orgId: string) {
    const result = await Parse.Cloud.run('deleteWeightProfile', { weightProfileId, orgId })
    return result
  }

  async processWeightProfilesFile(orgId, parseFile) {
    const data = await Parse.Cloud.run('importWeightProfilesFromCsv', { orgId, parseFile })
    return data
  }

  async processQuestionsFile(orgId, parseFile, forSurvey = null) {
    const data = await Parse.Cloud.run('importQuestionsFromCsv', { orgId, parseFile, forSurvey })
    return data
  }

  async generateSurveyLink(orgId, userSurveyId) {
    const link = await Parse.Cloud.run('generateSurveyLink', { orgId, userSurveyId })
    return link
  }
}
