import { computed } from 'mobx'
import { IEventTotal } from '../interfaces/IEventTotal'

export class SurveyEventSummaryRowVM {
  private eventTotal: IEventTotal
  constructor(eventTotal: IEventTotal) {
    this.eventTotal = eventTotal
  }

  @computed
  public get event() {
    return this.eventTotal.event
  }

  @computed
  public get total() {
    return this.eventTotal.total
  }

  @computed
  public get objectId() {
    return this.eventTotal.event
  }
}
