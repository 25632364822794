import { action, observable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { CMSItemAttachmentVM } from '../view-models/CMSItemAttachmentVM'
import { Attachment } from '../aggregate/Attachment'
import { isNumeric } from '../../shared/isNumeric'
import { ICMSItemDTO } from '../../cms-items/interfaces/ICMSItemDTO'
import { CMSItemsFindService } from '../../cms-items/service/CMSItemsFindService'

export class AttachmentsStore {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @observable public attachments: CMSItemAttachmentVM[] = []
  @observable public cmsItems: ICMSItemDTO[] = []

  private getCMSItemId(attachment: Attachment) {
    if (isNumeric(attachment.objectId)) return Number(attachment.objectId)
    else return attachment.cmsItemId
  }

  public async loadCMSItem(cmsItemId: number): Promise<ICMSItemDTO> {
    if (!cmsItemId) return

    const svc = new CMSItemsFindService(this.rootStore)
    const found = await svc.getItem(cmsItemId)
    if (found) this.addCMSItem(found)

    return found
  }

  public getCMSItem(cmsItemId: number): ICMSItemDTO {
    const found: ICMSItemDTO = this.cmsItems.find((e) => Number(e.id) === cmsItemId)

    return found
  }

  public getAttachment(attachment: Attachment): CMSItemAttachmentVM {
    const cmsItemId = this.getCMSItemId(attachment)

    let found: CMSItemAttachmentVM = this.getAttachmentById(cmsItemId)
    if (!found) found = this.attachments.find((e) => Number(e.objectId) === cmsItemId)

    return found
  }

  public getAttachmentById(cmsItemId: number): CMSItemAttachmentVM {
    let found: CMSItemAttachmentVM = this.attachments.find((e) => e.cmsItemId === cmsItemId)
    if (!found) found = this.attachments.find((e) => Number(e.objectId) === cmsItemId)

    return found
  }

  @action
  public loadAttachment(attachment: Attachment) {
    const cmsItemId = this.getCMSItemId(attachment)

    const found: CMSItemAttachmentVM = this.getAttachmentById(cmsItemId)
    if (found) return found

    const vm = new CMSItemAttachmentVM(this.rootStore, attachment)
    vm.loadCMSItem().then((cmsItem) => {
      if (cmsItem) this.addCMSItem(cmsItem)
    })

    this.addAttachment(vm)
    return vm
  }

  @action
  public addCMSItem(cmsItem: ICMSItemDTO) {
    const index = this.getCMSItemIndex(cmsItem.id)
    this.cmsItems.push(cmsItem)
  }

  @action
  public addAttachment(attachment: CMSItemAttachmentVM) {
    this.attachments.push(attachment)
  }

  private getAttachmentIndex(cmsItemId: number): number {
    return this.attachments.findIndex((e) => e.cmsItemId === cmsItemId)
  }

  private getCMSItemIndex(cmsItemId: number): number {
    return this.cmsItems.findIndex((e) => e.id === cmsItemId)
  }

  public clearData() {
    this.attachments.splice(0, this.attachments.length)
    this.cmsItems.splice(0, this.cmsItems.length)
  }
}
