import { action, computed, observable } from 'mobx'
import { IRoleDTO } from 'src/app/roles/dtos/IRoleDTO'
import { RootStore } from 'src/app/stores/RootStore'
import WeightProfileExportVM from './WeightProfileExportVM'

export class ExportRoleVM {
  private rootStore: RootStore
  private role: IRoleDTO
  private exportVM: WeightProfileExportVM

  constructor(rootStore: RootStore, role: IRoleDTO, exportVM: WeightProfileExportVM) {
    this.rootStore = rootStore
    this.role = role
    this.exportVM = exportVM
  }

  @observable isSelected: boolean = false

  @computed
  public get objectId(): string {
    return this.role.objectId
  }

  @computed
  public get name(): string {
    return this.role.name
  }

  @computed
  public get height() {
    return 20
  }

  @action
  public toggleIsSelected() {
    this.isSelected = !this.isSelected
    if (!this.isSelected) this.exportVM.allSelected = false
  }
}
