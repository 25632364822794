import React from 'react'
import { observer, inject } from 'mobx-react'
import { SurveysStore } from '../../../surveys/store/SurveysStore'
import './InvitationsLinkDialog.scss'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import MuiConfirmPrompt from '../../../shared/MuiConfirmPrompt'

interface Props {
  surveysStore?: SurveysStore
  localizationStore?: LocalizationStore
}

const RemindDialog: React.FC<Props> = ({ surveysStore, localizationStore }) => {
  const { editVM } = surveysStore.viewModels
  if (!editVM) return null
  const vm = editVM.surveyParticipantsList
  if (!vm.showRemindDialog) return null
  const lz = localizationStore.lzStrings.surveyInvitationsList

  const renderMessage = () => {
    if (vm.newSelectedRow) return lz.remind_single
    if (vm.allSelected) {
      return (
        <>
          <span>{lz.remind_all}</span>
        </>
      )
    } else {
      return (
        <>
          <span>{lz.remind_multiple}</span>
        </>
      )
    }
  }

  const renderSecondaryMessage = () => {
    if (vm.newSelectedRow) return null
    if (vm.allSelected) {
      return <>{lz.remind_all_users_selected}</>
    } else {
      return <>{`(${vm.newSelectedRows.length}) ${lz.users_selected}`}</>
    }
  }

  const confirm = () => {
    if (vm.newSelectedRow) vm.remindInvite()
    else vm.remindSurveyInvitations()
  }

  return (
    <MuiConfirmPrompt
      confirmMessage={renderMessage()}
      confirmMessageSecondary={renderSecondaryMessage()}
      onClose={() => vm.toggleRemindDialog()}
      open={vm.showRemindDialog}
      onConfirm={() => confirm()}
      title={lz.send_invitation_reminder}
    />
  )
}

export default inject('surveysStore', 'localizationStore')(observer(RemindDialog))
