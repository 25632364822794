import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { ListItem, ListItemText } from '@material-ui/core'
import { RoleVM } from 'src/app/weight-profiles/view-models/RoleVM'
import { EditScreenVM } from 'src/app/weight-profiles/view-models/EditScreenVM'

interface Props {
  role: RoleVM
  style: any
  editVM: EditScreenVM
}

const RoleRow: FC<Props> = ({ role, style, editVM }) => {
  return (
    <ListItem
      key={`rolerow-${role.objectId}`}
      button
      style={style}
      onClick={(e) => {
        e.stopPropagation()
        editVM.selectRole(role.objectId)
      }}
      selected={role.isSelected}
    >
      <ListItemText primary={role.name} />
    </ListItem>
  )
}

export default observer(RoleRow)
