import React, { FC, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import {
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  DialogTitle,
  InputBase,
  CircularProgress,
} from '@material-ui/core'
import { OrganizationUsersStore } from '../../store/OrganizationUsersStore'

interface Props {
  organizationUsersStore?: OrganizationUsersStore
}

const UserBecomeDialog: FC<Props> = ({ organizationUsersStore }) => {
  const { becomeDialogVM: vm } = organizationUsersStore.editVM

  const renderMessage = () => {
    if (vm.isProcessing) return
    return <div>Are you sure you wish to log in as this user?</div>
  }

  const renderSpinner = () => {
    if (!vm.isProcessing) return
    return <CircularProgress />
  }

  const renderInput = () => {
    return (
      <InputBase
        value={vm.sessionToken}
        onChange={(e) => vm.setSessionToken(e.target.value)}
        placeholder='Enter Session Token'
      />
    )
  }

  const renderActions = () => {
    if (vm.isProcessing) return
    return (
      <DialogActions>
        <Button onClick={() => vm.cancel()}>NO</Button>
        <Button variant='contained' onClick={() => vm.ok()}>
          YES
        </Button>
      </DialogActions>
    )
  }

  return (
    <Dialog onClose={() => vm.hide()} open={vm.shown}>
      <DialogTitle>Log in as User</DialogTitle>
      <DialogContent>
        {renderSpinner()}
        {/* {renderInput()} */}
        {renderMessage()}
      </DialogContent>
      {renderActions()}
    </Dialog>
  )
}

export default inject('organizationUsersStore')(observer(UserBecomeDialog))
