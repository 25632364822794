import { UserTask } from '../aggregates/UserTask'
import { computed, action } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import moment from 'moment'
import { PendingUserTasksWidgetVM } from './PendingUserTasksWidgetVM'
import { AGGridRowVM } from '../../shared/ag-grid/AGGridRowVM'

class PendingUserTaskWidgetRowVM extends AGGridRowVM<UserTask> {
  constructor(rootStore: RootStore, userTask: UserTask, widgetVM: PendingUserTasksWidgetVM) {
    super(rootStore, userTask, widgetVM)
  }

  @computed
  public get objectId(): string {
    return this.aggregate.objectId
  }

  @computed
  public get discipline() {
    return this.rootStore.categoriesStore.getCategory(this.aggregate.categoryId)
  }

  @computed
  public get disciplineName() {
    if (!this.discipline) return ''
    return this.discipline.name
  }

  @computed
  public get name(): string {
    return this.aggregate.name
  }

  @computed
  public get dueDate() {
    return this.aggregate.dueDate
  }

  @computed
  public get assignedByUser() {
    return this.rootStore.audienceMembersStore.getUser(this.aggregate.assignedByUserId)
  }

  @computed
  public get assignedByUserId() {
    if (!this.assignedByUser) return ''
    return this.assignedByUser.objectId
  }

  @computed
  public get assignedByUserName() {
    if (!this.assignedByUser) return ''
    return this.assignedByUser.name
  }

  @computed
  public get assignedByUserIconURL() {
    return this.assignedByUser.iconURL
  }

  @computed
  public get isOverdue(): boolean {
    const today = moment()
    const dueDate = moment.unix(this.dueDate)
    return today.isAfter(dueDate, 'days')
  }

  @computed
  public get data() {
    return {
      ...this.aggregate,
      name: this.name,
      discipline: this.discipline,
      dueDate: this.dueDate,
      assignedByUser: this.assignedByUser,
      isOverdue: this.isOverdue,
    }
  }

  @action
  public openUserTask() {
    this.rootStore.userTasksStore.loadEditVM(this.objectId)
  }
}

export default PendingUserTaskWidgetRowVM
