import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { TasksStore } from '../../store/TasksStore'
import {
  Grid,
  InputLabel,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
  Tooltip,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import './Drawer.scss'
import { OrganizationsSAStore } from 'src/app/organizations-sa/store/OrganizationsSAStore'
import { Survey } from '../../../surveys/aggregate/Survey'

interface DrawerFeedbackDetailsProps {
  localizationStore?: LocalizationStore
  tasksStore?: TasksStore
}

const DrawerFeedbackDetails: React.FC<DrawerFeedbackDetailsProps> = ({
  localizationStore,
  tasksStore,
}) => {
  const { lzStrings } = localizationStore
  const { taskEditVM: VM } = tasksStore
  if (!VM.allowFeedback) return null

  const renderTemplateSelect = () => {
    return (
      <Autocomplete
        className='feedback-autocomplete'
        options={VM.availableSurveyTemplates}
        getOptionLabel={(e) => e.name}
        value={VM.selectedSurveyTemplate}
        onChange={(e, val: Survey) => VM.setSelectedSurveyTemplate(val && val.objectId)}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            placeholder='Select Template'
            variant='outlined'
            className='feedback-input'
          />
        )}
      />
    )
  }

  const renderAutoPublish = () => {
    if (!VM.showAutoPublish) return null
    return (
      <Tooltip
        title={`On feedback request: Publish survey immediately.`}
        placement='bottom-start'
        enterDelay={500}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={VM.autoPublishFeedback}
              onChange={() => VM.toggleAutoPublishFeedback()}
            />
          }
          label={
            <Typography variant='body1'>
              {lzStrings.tasksManagementWidgetDrawer.auto_publish}
            </Typography>
          }
        />
      </Tooltip>
    )
  }

  return (
    <section id='feedbackDetails'>
      <h6>{lzStrings.tasksManagementWidgetDrawer.feedback_survey}</h6>
      <form>
        <Grid container spacing={3} style={{ height: '69px' }}>
          <Grid item xs={8}>
            {renderTemplateSelect()}
          </Grid>
          <Grid item xs={4}>
            {renderAutoPublish()}
          </Grid>
        </Grid>
      </form>
    </section>
  )
}

export default inject('localizationStore', 'tasksStore')(observer(DrawerFeedbackDetails))
