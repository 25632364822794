import { computed, action } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { AGGridRowVM } from '../../../shared/ag-grid/AGGridRowVM'
import { CalendarManagementWidgetVM } from './CalendarManagementWidgetVM'
import Location from '../../../locations/aggregate/Location'

export class LocationRowVM extends AGGridRowVM<Location> {
  constructor(rootStore: RootStore, location: Location, widgetVM: CalendarManagementWidgetVM) {
    super(rootStore, location, widgetVM)
  }

  public type: string = 'location'

  @computed
  public get objectId() {
    return this.aggregate.objectId
  }

  @computed
  public get name() {
    return this.aggregate.name
  }

  @computed
  public get description() {
    return ''
  }

  editAggregate() {
    this.rootStore.locationsStore.setSelectedLocationId(this.objectId)
    this.rootStore.locationsStore.showDrawer = true
  }
}
