import React from 'react'
import PlacesAutocomplete, { geocodeByAddress, getLatLng, geocodeByPlaceId } from 'react-places-autocomplete'
import classnames from 'classnames'
import _ from 'lodash'
import {
  TextField
} from '@material-ui/core'
import './PlacesSearchBarV2.scss'

export default class PlacesSearchBarV2 extends React.Component<any, any> {

  constructor(props) {
    super(props)
    this.state = {
      address: this.props.defaultText ? this.props.defaultText : '',
      errorMessage: '',
      latitude: null,
      longitude: null,
      isGeocoding: false,
      isValid: true
    }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(val) {
    this.setState({
      address: val,
      latitude: null,
      longitude: null,
      errorMessage: '',
      isValid: val
    })
  }

  hasNumber(str) {
    return /\d/.test(str);
  }

  lookupPlace(address, placeId) {

    const map = new google.maps.Map(document.getElementById('searchbarmap'), {})
    var request = {
      placeId: placeId,
      fields: ['formatted_address', 'name', 'geometry'],
    }

    const service: any = new google.maps.places.PlacesService(map)
    service.getDetails(request, (place, status) => {
      if (status == google.maps.places.PlacesServiceStatus.OK) {

        place.selectedText = address

        if (place.name === 'United States') {
          place.name = ''
          place.formatted_address = address
        }

        if (this.hasNumber(address) && !this.hasNumber(place.formatted_address)) {
          place.name = ''
          place.formatted_address = address
        }

        this.props.placeSelected(place)
      }
    })

  }

  handleSelect = (selected, placeId) => {

    this.setState({
      isGeocoding: true,
      address: selected
    })

    this.lookupPlace(selected, placeId)

    geocodeByAddress(selected)
      .then(res => getLatLng(res[0]))
      .then(({ lat, lng }) => {
        this.setState({
          latitude: lat,
          longitude: lng,
          isGeocoding: false,
        })
        this.props.latLngChange(lat, lng)
      })
      .catch(error => {
        this.setState({ isGeocoding: false })
        console.log('error', error)
      })
  }

  handleCloseClick = () => {
    if (this.state.address) {
      this.setState({
        address: '',
        latitude: null,
        longitude: null
      })
      // this.props.latLngChange(null, null)
      // this.props.placeSelected(null)
    }
    else {
      this.props.onCancel()
    }
  }

  handleSaveClick = () => {
    if (this.state.address && this.state.address !== '') {
      const address = this.state.address
      this.setState({ isValid: true, address: '' })
      this.props.onSave(address)
    }
    else {
      this.setState({ isValid: false })
    }
  }


  handleError = (status, clearSuggestions) => {
    console.log('Error from Google Maps API', status)
    this.setState({ errorMessage: status }, () => {
      clearSuggestions()
    })
  }

  render() {
    const {
      address,
      errorMessage,
    }: any = this.state

    return (
      <div id='PlacesSearchBarV2'>
        <PlacesAutocomplete
          onChange={this.handleChange}
          value={address}
          onSelect={this.handleSelect}
          onError={this.handleError}
          shouldFetchSuggestions={address.length > 2}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps }) => {
            const setCursorPosAtEnd = (e) => {
              if (e.target) e.target.setSelectionRange(e.target.value.length, e.target.value.length)
            }
            return (
              <div className='search-bar-container'>
                <div className='search-input-container'>
                  <TextField
                    {...getInputProps({
                      placeholder: this.props.placeholder,
                      className: 'form-control input',
                    })}
                    error={!this.state.isValid}
                    variant='outlined'
                    onFocus={setCursorPosAtEnd}
                  />

                  {/* <input
                    {...getInputProps({
                      placeholder: this.props.placeholder,
                      className: + !this.state.isValid ? 'form-control input has-error' : 'form-control input',
                    })}
                    autoFocus
                    onFocus={setCursorPosAtEnd}
                  /> */}
                  <div className='clear-button fa fa-times' onClick={this.handleCloseClick} />
                  <div className='save-button fa fa-check' onClick={this.handleSaveClick} />
                </div>

                {suggestions.length > 0 && (
                  <div className='autocomplete-container'>
                    {suggestions.map(suggestion => {
                      const className = classnames('suggestion-item', {
                        'suggestion-item--active': suggestion.active,
                      })

                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, { className })}
                        >
                          <strong>
                            {suggestion.formattedSuggestion.mainText}
                          </strong>{' '}
                          <small>
                            {suggestion.formattedSuggestion.secondaryText}
                          </small>
                        </div>
                      )
                    })}
                  </div>
                )}

              </div>
            )
          }}
        </PlacesAutocomplete>

        {errorMessage.length > 0 && (
          <div className="Demo__error-message">{this.state.errorMessage}</div>
        )}

        <div id='searchbarmap'></div>

      </div>
    )
  }
}

