import React from 'react'
import { observer, inject } from 'mobx-react'
import { CardHeader } from '@material-ui/core'
import LocalizationStore from '../../../../localization/LocalizationStore'
import Searchbar from '../../../../shared/Searchbar'
import { TrainingPlansStore } from '../../../store/TrainingPlansStore'
import Title from './Title'

interface Props {
  trainingPlansStore?: TrainingPlansStore
  localizationStore?: LocalizationStore
}

const Header: React.FC<Props> = ({ trainingPlansStore, localizationStore }) => {
  const { manageTrainingsWidgetVM: vm } = trainingPlansStore
  if (!vm) return null

  return (
    <CardHeader
      id='Header'
      title={<Title />}
      action={
        <>
          <div className='search'>
            <Searchbar
              focused={vm.keywordFilter.length > 0}
              searchValue={vm.keywordFilter}
              onSearchChange={(e) => vm.setKeywordFilter(e.target.value)}
            />
          </div>
        </>
      }
    />
  )
}

export default inject('trainingPlansStore', 'localizationStore')(observer(Header))
