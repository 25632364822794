import { ColDef, ColumnApi, GridApi, GridReadyEvent } from 'ag-grid-community'
import { action, computed, observable } from 'mobx'
import { HistoryRowVM } from 'src/app/surveys/view-models/HistoryRowVM'
import { UserSurveyEventsService } from 'src/app/userSurveyEvents/service/UserSurveyEventsService'
import { AnnouncementsService } from '../../announcements/service/AnnouncementsService'
import { UserAnnouncementEventService } from '../services/UserAnnouncementEventService'
import { UserAnnouncementEventRowVM } from './UserAnnouncementEventRowVM'

export class UserAnnouncementEventsVM {
  public gridApi: GridApi
  private columnApi: ColumnApi
  private organizationId: string
  private announcementId: string
  private userId: string
  private lastName: string
  private firstName: string

  constructor(
    organizationId: string,
    announcementId: string,
    userId: string,
    firstName: string,
    lastName: string
  ) {
    this.organizationId = organizationId
    this.announcementId = announcementId
    this.userId = userId
    this.lastName = lastName
    this.firstName = firstName
  }

  @observable public isLoadingFirstPage: boolean = false

  @computed
  public get getName() {
    return `${this.lastName}, ${this.firstName}`
  }

  @action
  public onGridReady(e: GridReadyEvent) {
    this.gridApi = e.api
    this.columnApi = e.columnApi

    this.gridApi.setDatasource({
      rowCount: null,
      getRows: (params) => {
        const svc = new UserAnnouncementEventService()
        if (params.startRow === 0) {
          this.isLoadingFirstPage = true
        }
        svc
          .getUserAnnouncementEvents({
            organizationId: this.organizationId,
            userId: this.userId,
            announcementId: this.announcementId,
            skip: params.startRow,
            limit: params.endRow - params.startRow,
          })
          .then((result) => {
            const records = result.userAnnouncementEvents.map(
              (e) => new UserAnnouncementEventRowVM(e)
            )
            const lastRow =
              records.length < params.endRow - params.startRow
                ? params.startRow + records.length
                : undefined
            params.successCallback(records, lastRow)
            this.isLoadingFirstPage = false
            this.updateGridData()
          })
      },
    })
    this.updateGridData()
  }

  private updateGridData() {
    if (!this.gridApi) return
    this.gridApi.sizeColumnsToFit()
    //this.resizeColumns()
  }

  @computed
  public get fields() {
    return [
      { name: 'Type', field: 'eventType' },
      { name: 'Source', field: 'eventSource' },
      { name: 'Date', field: 'eventDateTime' },
      { name: 'Additional Info', field: 'additionalInfo' },
    ]
  }

  @computed
  public get columnDefs(): ColDef[] {
    return this.fields.map((e) => {
      let def: ColDef = {
        headerName: e.name,
        sortable: true,
        field: e.field,
        editable: false,
        resizable: true,
        headerTooltip: e.name,
      }
      if (e.field === 'eventSource') {
        def.width = 30
      } else if (e.field === 'eventDateTime') {
        def.width = 70
      } else if (e.field === 'eventType') {
        def.width = 50
      }
      return def
    })
  }

  @action
  public getRowNodeId(row: HistoryRowVM) {
    return row.objectId
  }
}
