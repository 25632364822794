import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { observer } from 'mobx-react'

interface Props {
  providerUuid: string
  height: number
  width: number
  onLoad?: Function
  onError?: Function
  style?: CSSProperties
}

const VimeoImage: React.FC<Props> = ({ providerUuid, height, width, onLoad, onError, style }) => {
  const [thumbnail, setThumbnail] = useState(undefined)
  const mounted = useRef(false)

  async function loadVimeoImageData(providerUid: string): Promise<string> {
    const url = `https://vimeo.com/api/oembed.json?url=https://vimeo.com/${providerUid}&width=${width}&height=${height}`
    const response = await axios.get(url)
    if (!response || !response.data) return ''

    if (response.data.thumbnail_url) {
      return response.data.thumbnail_url
    }
  }

  useEffect(() => {
    mounted.current = true

    async function prepare() {
      try {
        const fetchData = async () => {
          loadVimeoImageData(providerUuid).then((url) => {
            if (mounted.current) {
              setThumbnail(url)
            }
          })
        }
        await fetchData()
      } catch (e) {
        console.error(e)
      }
    }
    prepare()

    return () => {
      mounted.current = false
    }
  }, [])

  const handleLoadError = () => {
    if (onError) onError()
  }

  const handleLoad = () => {
    if (onLoad) onLoad()
  }

  return (
    <img
      style={{ height: height, width: width, backgroundSize: 'cover', ...style }}
      src={thumbnail}
      onLoad={() => handleLoad()}
      onError={() => handleLoadError()}
      alt={'Preview'}
    />
  )
}

export default observer(VimeoImage)
