import { RowClickedEvent } from 'ag-grid-community'
import { action, computed, observable } from 'mobx'
import moment from 'moment'
import { RootStore } from '../../../../stores/RootStore'
import { PulseCampaign } from '../../../aggregate/PulseCampaign'
import { IPulseCampaignFindRequest } from '../../../interfaces/IPulseCampaignFindRequest'
import { DraftPulseCampaignRowVM } from './DraftPulseCampaignRowVM'
import { DraftPulseCampaignsDataStore } from './DraftPulseCampaignsDataStore'

export class DraftPulseCampaignsVM {
  private rootStore: RootStore
  public dataStore: DraftPulseCampaignsDataStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.lz = this.rootStore.localizationStore.lzStrings.pulseCampaignDashboard
    this.labelsStore = this.rootStore.labelsStore
    this.dataStore = new DraftPulseCampaignsDataStore(this.rootStore, {
      organizationId: this.rootStore.appStore.currentOrgId,
      status: 'draft',
      isTemplate: false,
      isDeleted: false,
      mode: 'questions',
    } as IPulseCampaignFindRequest)
    this.loadStore()
  }

  @observable public lz = undefined
  @observable public labelsStore = undefined
  @observable public drafts: PulseCampaign[] = []

  public getRowNodeId(row: DraftPulseCampaignRowVM): string {
    return row.objectId
  }

  public get shouldRender(): boolean {
    if (!this.dataStore.listRecordsLoaded) return false
    return true
  }

  @computed
  public get rows(): DraftPulseCampaignRowVM[] {
    return this.dataStore.rows
      .map((draft) => new DraftPulseCampaignRowVM(this.rootStore, draft))
      .sort((a, b) => (new Date(a.createdAt).getTime() < new Date(b.createdAt).getTime() ? 1 : -1))
  }

  public rowClicked(e: RowClickedEvent) {}

  @computed
  public get hasRows(): boolean {
    return this.dataStore.hasListRecords
  }

  @computed
  public get isSystemAdmin(): boolean {
    return this.rootStore.appStore.isSystemAdmin
  }

  @computed
  protected get totalRecords() {
    return this.dataStore.totalRecords
  }

  @action
  public loadStore() {
    this.dataStore.loadListRecords()
  }

  @action
  public createPulseCampaign() {
    this.rootStore.pulseCampaignsStore.viewModels.createCampaignDialogVM.openCreateCampaignDialog()
  }
}
