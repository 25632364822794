import { action, observable } from 'mobx'
import { serializable, serialize, list, object, deserialize, primitive } from 'serializr'
import { Option } from '../aggregate/Option'
import { IQuestionDTO } from '../dtos/IQuestionDTO'
import { QuestionType } from '../types/QuestionType'
import { RatingDisplayType } from '../types/RatingDisplayType'
import HashId from '../../shared/HashId'

export class Question implements IQuestionDTO {
  static create(q) {
    const question = new Question()
    question.type = q.type
    question.rank = q.rank
    question.id = q.id
    question.title = q.title
    // question.originalIndex = q.originalIndex
    question.ratingDisplay = q.ratingDisplay

    if (q.options) {
      for (let opt of q.options) question.options.push(Option.create(opt))
    }

    question.isDropdownMultiSelect = q.isDropdownMultiSelect ? q.isDropdownMultiSelect : false
    question.placeholder = q.placeholder ? q.placeholder : ''

    return question
  }

  public static createFromEmojiType() {
    const question = new Question()
    question.id = HashId.generate()
    question.type = 'emoji'
    question.title = 'How is it going?'
    question.addOption()
    question.options[0].setText('Good')
    question.options[0].setEmoji('happy-4')
    question.addOption()
    question.options[1].setText('Well')
    question.options[1].setEmoji('smile')
    question.addOption()
    question.options[2].setText('Ok')
    question.options[2].setEmoji('emoticons')
    question.addOption()
    question.options[3].setText('Bad')
    question.options[3].setEmoji('unhappy')
    return question
  }

  public static createFromRatingType() {
    const question = new Question()
    question.type = 'rating'
    question.id = HashId.generate()
    question.addOption()
    question.options[0].setText('Very Poor')
    question.options[0].setRank(1)
    question.addOption()
    question.options[1].setText('Below Average')
    question.options[1].setRank(2)
    question.addOption()
    question.options[2].setText('Average')
    question.options[2].setRank(3)
    question.addOption()
    question.options[3].setText('Above Average')
    question.options[3].setRank(4)
    question.addOption()
    question.options[4].setText('Excellent')
    question.options[4].setRank(5)
    return question
  }

  public static createFromStarRatingType() {
    const question = new Question()
    question.type = 'starRating'
    question.id = HashId.generate()
    question.addOption()
    question.options[0].setText('Very Poor')
    question.options[0].setRank(1)
    question.addOption()
    question.options[1].setText('Below Average')
    question.options[1].setRank(2)
    question.addOption()
    question.options[2].setText('Average')
    question.options[2].setRank(3)
    question.addOption()
    question.options[3].setText('Above Average')
    question.options[3].setRank(4)
    question.addOption()
    question.options[4].setText('Excellent')
    question.options[4].setRank(5)
    return question
  }

  public static createFromRadioType() {
    const question = new Question()
    question.type = 'radio'
    question.id = HashId.generate()
    question.addOption()
    question.options[0].setText('A')
    question.addOption()
    question.options[1].setText('B')
    question.addOption()
    question.options[2].setText('C')
    question.addOption()
    question.options[3].setText('D')
    return question
  }

  public static createFromDropdownType() {
    const question = new Question()
    question.type = 'dropdown'
    question.id = HashId.generate()
    question.addOption()
    question.options[0].setText('Strongly Disagree')
    question.addOption()
    question.options[1].setText('Disagree')
    question.addOption()
    question.options[2].setText('Neutral')
    question.addOption()
    question.options[3].setText('Agree')
    question.addOption()
    question.options[4].setText('Strongly Agree')
    return question
  }

  public static createFromCheckType() {
    const question = new Question()
    question.type = 'check'
    question.id = HashId.generate()
    question.addOption()
    question.options[0].setText('Option one')
    question.addOption()
    question.options[1].setText('Option two')
    question.addOption()
    question.options[2].setText('Option 3')
    question.addOption()
    question.options[3].setText('Option 4')
    return question
  }

  public static createFromYesNoType() {
    const question = new Question()
    question.id = HashId.generate()
    question.type = 'yesNo'
    question.addOption()
    question.options[0].setText('Yes')
    question.addOption()
    question.options[1].setText('No')
    return question
  }

  public static createFromTextType() {
    const question = new Question()
    question.id = HashId.generate()
    question.type = 'text'
    question.addOption()
    question.options[0].text = ''
    return question
  }

  @serializable @observable public id: string = ''
  @serializable @observable public rank: number = 0
  @serializable @observable public title: string = ''
  @serializable @observable public type: QuestionType = 'emoji'
  @serializable @observable public ratingDisplay: RatingDisplayType = 'vertical'
  @serializable(list(object(Option))) @observable public options: Option[] = []
  @serializable(list(primitive())) @observable public correctOptionIds: string[] = []
  @serializable @observable public isDropdownMultiSelect: boolean = false
  @serializable @observable public placeholder: string = 'Please Select'

  @action
  public addOption() {
    this.options.push(new Option())
  }

  @action
  public addCorrectAnswer(id: string) {
    this.correctOptionIds.push(id)
  }

  @action
  public setRatingDisplay(val) {
    this.ratingDisplay = val
  }

  @action
  public setTitle(val: string) {
    this.title = val
  }

  @action
  public moveOption(index, newIndex) {
    const optionObj = this.options.splice(index, 1)
    this.options.splice(newIndex, 0, optionObj[0])
  }

  @action
  public changeQuestionType(val: QuestionType) {
    if (this.type === 'emoji' && val !== 'emoji')
      this.options.forEach((option) => {
        option.emoji = ''
      })
    if (this.type === 'text' && val !== 'text') {
      this.options[0].text = 'Good'
      this.addOption()
      this.options[1].text = 'Well'
      this.addOption()
      this.options[2].text = 'Ok'
      this.addOption()
      this.options[3].text = 'Bad'
    }
    if (this.type === 'text' && val === 'check') {
      this.options = []
      this.addDefaultCheckOptions()
    }
    if (this.type === 'text' && val === 'yesNo') {
      this.options = []
      this.addDefaultYesNoOptions()
    }
    if (this.type === 'yesNo' && val !== 'yesNo') {
      this.addOption()
      this.addOption()
    }
    if (val === 'emoji') {
      this.addDefaultEmojiIcons()
    }
    if (val === 'text') {
      this.options = []
      this.addDefaultTextOptions()
    }
    if (val === 'yesNo') {
      this.options = []
      while (this.options.length < 2) {
        this.addOption()
      }
      this.options[0].text = 'Yes'
      this.options[1].text = 'No'
    }
    if (val === 'rating') {
      this.options = []
      this.addDefaultRatingOptions()
    }
    if (val === 'starRating') {
      this.options = []
      this.addDefaultStarRatingOptions()
    }

    this.type = val
  }

  addDefaultCheckOptions() {
    this.addOption()
    this.options[0].text = 'Option one'
    // this.options[0].check = null
    this.addOption()
    this.options[1].text = 'Option two'
    // this.options[1].check = null
    this.addOption()
    this.options[2].text = 'Option three'
    // this.options[2].check = null
    this.addOption()
    this.options[3].text = 'Option four'
    // this.options[3].check = null
  }

  @action
  private addDefaultYesNoOptions() {
    this.addOption()
    this.options[0].text = 'Yes'
    this.addOption()
    this.options[1].text = 'No'
  }

  @action
  private addDefaultRatingOptions() {
    this.addOption()
    this.options[0].text = 'Very Poor'
    this.options[0].rank = 1
    this.addOption()
    this.options[1].text = 'Below Average'
    this.options[1].rank = 2
    this.addOption()
    this.options[2].text = 'Average'
    this.options[2].rank = 3
    this.addOption()
    this.options[3].text = 'Above Average'
    this.options[3].rank = 4
    this.addOption()
    this.options[4].text = 'Excellent'
    this.options[4].rank = 5
    this.options.push()
  }

  @action
  private addDefaultStarRatingOptions() {
    this.addOption()
    this.options[0].text = 'Very Poor'
    this.options[0].rank = 1
    this.addOption()
    this.options[1].text = 'Below Average'
    this.options[1].rank = 2
    this.addOption()
    this.options[2].text = 'Average'
    this.options[2].rank = 3
    this.addOption()
    this.options[3].text = 'Above Average'
    this.options[3].rank = 4
    this.addOption()
    this.options[4].text = 'Excellent'
    this.options[4].rank = 5
    this.options.push()
  }

  @action
  private addDefaultEmojiIcons() {
    this.options[0].emoji = 'happy-4'
    this.options[1].emoji = 'smile'
    this.options[2].emoji = 'emoticons'
    this.options[3].emoji = 'unhappy'
    this.options.push()
  }

  @action
  private addDefaultTextOptions() {
    this.addOption()
    this.options[0].text = ''
  }

  @action
  public setRank(val: number) {
    this.rank = val
  }

  @action
  public moveOptionUp(idx: number) {
    const newIdx = idx - 1
    const optionObj = this.options.splice(idx, 1)
    this.options.splice(newIdx, 0, optionObj[0])
  }

  @action
  public moveOptionDown(idx: number) {
    const newIdx = idx + 1
    const optionObj = this.options.splice(idx, 1)
    this.options.splice(newIdx, 0, optionObj[0])
  }

  @action
  public deleteOption(idx: number) {
    this.options.splice(idx, 1)
  }

  public clone(): Question {
    return deserialize(Question, this.toDTO())
  }

  public toDTO(): IQuestionDTO {
    return serialize(this)
  }
}
