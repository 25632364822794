import React, { FC, CSSProperties } from 'react'
import MuiAvatar from '@material-ui/core/Avatar'
import env from '../../env' 
import BlockIcon from '@material-ui/icons/Block';
import { UserStore } from '../user/store/UserStore'
import User from '../user/aggregate/User'
import styles from './Avatar.scss'
import { Tooltip } from '@material-ui/core'
import { useIsMounted } from './useIsMounted'

export interface AvatarUserMinimum {
  name: string
  iconURL: string
  objectId: string
}

export interface AvatarProps {
  onClick?: (e) => void
  showOnlyFirstInitial?: boolean
  size?: number | string
  fontSize?: number
  style?: CSSProperties
  user?: User | AvatarUserMinimum
  userStore?: UserStore
  isArchived?: boolean
  isCache?: boolean
}

const Avatar: FC<AvatarProps> = ({
  onClick,
  showOnlyFirstInitial,
  fontSize,
  size,
  style,
  user,
  userStore,
  isArchived = false,
  isCache = true
}) => {
  const _user = user
  if (!_user) return null

  const name = typeof _user.name === 'string' ? _user.name.trim() : ''

  const avatarStyle = {
    fontWeight: 500,
    fontSize: fontSize || 16,
    height: size || 40,
    width: size || 40,
    cursor: 'pointer',
    ...style,
  }

  const isMounted = useIsMounted()

  const buildAvatarUrl = (userId: string = 'U', firstName: string = 'U', lastName: string = 'N') => {
    const cache = isCache ? '' : `&t=${Date.now()}`
    return `${env.var.REACT_APP_CMS_API_URL}/api/avatar/${userId}?firstName=${firstName}&lastName=${lastName}${cache}`
  }

  const names = name ? name.split(' ') : `${_user['firstName']} ${_user['lastName']}`
  const firstName = names[0] ? names[0] : 'U'
  const lastName = names[names.length - 1] ? names[names.length - 1] : 'N'
  const userId = user.objectId ? user.objectId : 'U'
  let url = buildAvatarUrl(userId, firstName, lastName)

  if (isArchived)
    return (
      <Tooltip title={`User (${_user.name}) is archived`}>
        <MuiAvatar alt={name} style={{ ...avatarStyle, color: styles.archivedColor, backgroundColor: styles.archivedBackgroundColor }} onClick={onClick}>
          <BlockIcon fontSize={'small'} style={{ width: '80%', margin: '0.5rem' }} />
        </MuiAvatar>
      </Tooltip>
    )
  return <MuiAvatar alt={name} src={url} style={avatarStyle} onClick={onClick} />
}

export default Avatar
