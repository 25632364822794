export default class HashId {
  static ALPHABET = '23456789abdegjkmnpqrvwxyz'
  static ALPHABET_LENGTH = HashId.ALPHABET.length
  static ID_LENGTH = 8
  static UNIQUE_RETRIES = 9999

  static generate() {
    var rtn = ''
    for (var i = 0; i < this.ID_LENGTH; i++) {
      rtn += this.ALPHABET.charAt(Math.floor(Math.random() * this.ALPHABET_LENGTH))
    }
    return rtn
  }

  static generateUnique(previous) {
    previous = previous || []
    var retries = 0
    var id

    // Try to generate a unique ID,
    // i.e. one that isn't in the previous.
    while (!id && retries < this.UNIQUE_RETRIES) {
      id = HashId.generate()
      if (previous.indexOf(id) !== -1) {
        id = null
        retries++
      }
    }
    return id
  }
}
