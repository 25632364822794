import { action, observable } from 'mobx'
import { serializable, serialize } from 'serializr'
import { MomentTimezone } from 'moment'
import moment from 'moment'
import { AlarmModeType } from '../type/AlarmModeType'
import { PeriodType } from '../type/PeriodType'

export class Alarm {
  static create(al: any) {
    const alarm = new Alarm()
    alarm.mode = al.mode
    alarm.units = al.units
    alarm.period = al.period
    if (al.date) {
      alarm.date = al.date
      alarm.time = al.time
      alarm.timeZone = al.timeZone
    }

    return alarm
  }
  @serializable @observable mode: AlarmModeType = 'period'
  @serializable @observable units: number = 0
  @serializable @observable period: PeriodType = 'minute'
  @serializable @observable date: string = moment(
    moment()
      .minute(Math.ceil(moment().minute() / 15) * 15)
      .second(0)
      .add(1, 'days')
  ).toISOString()
  @serializable @observable time: Date
  @serializable @observable timeZone: string = moment.tz.guess()

  @action setMode(mode) {
    this.mode = mode
  }

  @action
  public setUnits(val: number) {
    this.units = val
  }

  @action
  public setPeriod(per: PeriodType) {
    this.period = per
  }

  @action
  public setDate(date: Date) {
    this.date = date.toISOString()
  }

  @action
  public setTime(time: Date) {
    this.time = time
  }

  @action
  public setTimeZone(timeZone) {
    this.timeZone = timeZone.value
  }

  @action
  public toDTO() {
    return serialize(this)
  }
}
