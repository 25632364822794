import React from 'react'

//MobX Stores
import { inject, observer } from 'mobx-react'
import { UserTasksStore } from '../../store/UserTasksStore'

//MaterialUI Components
import { Grid } from '@material-ui/core'

//Components
import WidgetTimelineHeader from './WidgetTimelineHeader'
import WidgetTimelineTodaySection from './WidgetTimelineTodaySection'
import WidgetTimelineUpcomingTasksSection from './WidgetTimelineUpcomingTasksSection'

interface IWidgetTimeline {
  userTasksStore?: UserTasksStore
}

const WidgetTimeline: React.FC<IWidgetTimeline> = ({ userTasksStore }) => {
  const { userTasksTimelineVM: VM } = userTasksStore

  if (!VM) return null

  return (
    <Grid container spacing={0}>
      <WidgetTimelineHeader />
      <WidgetTimelineTodaySection />
      <WidgetTimelineUpcomingTasksSection />
    </Grid>
  )
}
export default inject('userTasksStore')(observer(WidgetTimeline))
