import { action, computed, observable, when } from 'mobx'
import { IOrganizationsFindRequest } from '../../../organizations/interfaces/IOrganizationsFindRequest'
import { IPulseCampaignsSAFindRequest } from '../../../pulse-campaigns/interfaces/IPulseCampaignsSAFindRequest'
import { IPulseCampaignTemplateGlobalRequest } from '../../../pulse-campaigns/interfaces/IPulseCampaignTemplateGlobalRequest'
import { RootStore } from '../../../stores/RootStore'
import { PulseSet } from '../../aggregate/PulseSet'
import { PulseSetCampaignTemplatesDataStore } from './PulseSetCampaignTemplatesDataStore'
import { PulseSetOrganizationsDataStore } from './PulseSetOrganizationsDataStore'

export class PulseSetRowVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, pulseSet: PulseSet) {
    this.rootStore = rootStore
    this.pulseSet = pulseSet
    this.organizationsDataStore = new PulseSetOrganizationsDataStore(this.rootStore, {
      organizationIds: this.pulseSet.subscribedOrganizationIds.slice(),
    } as IOrganizationsFindRequest)
    this.templatesDataStore = new PulseSetCampaignTemplatesDataStore(this.rootStore, {
      pulseCampaignIds: this.pulseSet.pulseCampaignTemplateIds.slice(),
    } as IPulseCampaignsSAFindRequest)
  }

  @observable public pulseSet: PulseSet = null
  @observable public isExpanded: boolean = false
  @observable public organizationsDataStore: PulseSetOrganizationsDataStore = null
  @observable public templatesDataStore: PulseSetCampaignTemplatesDataStore = null

  @computed
  public get name(): string {
    return this.pulseSet.name
  }

  @computed
  public get objectId(): string {
    return this.pulseSet.objectId
  }

  @computed
  public get pulseSetOrganizations() {
    return this.organizationsDataStore.rows
  }

  @computed
  public get pulseSetTemplates() {
    return this.templatesDataStore.rows
  }

  @computed
  public get isLoaded() {
    if (!this.organizationsDataStore.isLoaded) return false
    if (!this.templatesDataStore.isLoaded) return false
    return true
  }

  @action
  public toggleIsExpanded() {
    this.isExpanded = !this.isExpanded
    if (this.isExpanded) return this.loadDataStores()
  }

  @action
  private loadDataStores() {
    this.organizationsDataStore.loadListRecords()
    this.templatesDataStore.loadListRecords()
  }

  @action
  public editPulseSet() {
    return this.rootStore.pulseSetsStore.viewModels.loadPulseSetEditVM(this.objectId)
  }

  @action
  public createNewCampaignTemplate() {
    this.rootStore.appStore.router.push(`/pulse/v1/fromtemplate/new`)
  }

  @action
  public editCampaignTemplate(objectId: string) {
    this.rootStore.appStore.router.push(`/pulse/v1/fromtemplate/edit/${objectId}`)
  }
}
