import React from 'react'
import { observer } from 'mobx-react'
import rootStore from '../../../stores/RootStore'
import TopToolbar from './TopToolbar'
import './WorksheetEditScreen.scss'
import TabsBar from './TabsBar'
import { CircularProgress } from '@material-ui/core'
import WorksheetsSnackbar from './WorksheetsSnackbar'

@observer
export default class WorksheetEditScreen extends React.Component<any, any> {
  constructor(props) {
    super(props)
    rootStore.worksheetsStore.loadWorksheetEditVM(props.params.id)
  }

  routerWillLeave(nextLocation) {
    if (!rootStore.worksheetsStore.editVM) return
    if (rootStore.worksheetsStore.editVM.isDirty) {
      return 'You have unsaved information, are you sure you want to leave this page?'
    }
  }

  componentDidMount() {
    this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave.bind(this))
  }

  componentWillUnmount() {
    rootStore.worksheetsStore.loadWorksheetEditVM('empty')
  }

  renderSpinner() {
    const vm = rootStore.worksheetsStore.editVM
    if (!vm) return
    if (!vm.isSaving) return
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  render() {
    return (
      <div id='WorksheetEditScreen'>
        {this.renderSpinner()}
        <TopToolbar />
        <TabsBar />
        <WorksheetsSnackbar vm={rootStore.worksheetsStore.editVM} />
      </div>
    )
  }
}
