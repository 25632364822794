import React from 'react'
import { observer, inject } from 'mobx-react'
import LocalizationStore from '../../localization/LocalizationStore'
import { AppBar, Grid, LinearProgress, Toolbar } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import './CompletedSurveyResponseHeader.scss'
import { UserSurveyTakeVM } from '../../user-surveys/view-models/UserSurveyTakeVM'

interface Props {
  localizationStore?: LocalizationStore
  takeVM: UserSurveyTakeVM
}

const CompletedSurveyResponseHeader: React.FC<Props> = ({ takeVM, localizationStore }) => {
  const lzStrings = localizationStore.lzStrings

  const renderSurveyText = () => {
    if (!takeVM) {
      return <Skeleton variant='text' width='60%' height='30px' />
    }
    return (
      <span className='survey-text'>
        {lzStrings.completedSurveysWidget.completed_survey}
      </span>
    )
  }

  const renderProgressBar = () => {
    if (!takeVM || !takeVM.percentComplete) {
      return <Skeleton variant='text' width='100%' height='30px' />
    }
    return <LinearProgress variant='determinate' value={takeVM.percentComplete} />
  }

  return (
    <AppBar id='ScreenHeader' position='sticky' elevation={0}>
      <Toolbar>
        <Grid container>
          <Grid item xs={12} md={7} className='survey-and-link'>
            {renderSurveyText()}
          </Grid>
          <Grid item xs={12} md={5}>
            {renderProgressBar()}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default inject('localizationStore')(observer(CompletedSurveyResponseHeader))
