import { RootStore } from '../../stores/RootStore'
import { Event } from '../aggregate/Event'
import { UserSurvey } from '../../user-surveys/aggregate/UserSurvey'
import { UserTask } from '../../user-tasks/aggregates/UserTask'
import { ParticipantVM } from 'src/app/participants-select/view-models/ParticipantVM'
import { UserEventPillVM } from '../view-models/UserEventPillVM'
import { RoleEventPillVM } from '../view-models/RoleEventPillVM'
import { GroupEventPillVM } from '../view-models/GroupEventPillVM'
import { UserTaskPillVM } from '../view-models/UserTaskPillVM'
import { UserSurveyPillVM } from '../view-models/UserSurveyPillVM'
import { IEventPillVM } from '../intefaces/IEventPillVM'
import { UserEvent } from '../user-events/aggregate/UserEvent'
import { EventsScheduleService } from './EventsScheduleService'
import { IUserEventDTO } from '../user-events/dtos/IUserEventDTO'
import { deserialize } from 'serializr'
import { observable } from 'mobx'
import { TrainingPlan } from '../../training-plans/aggregates/TrainingPlan'
import { UserTrainingPlan } from '../../training-plans/user-training-plans/aggregate/UserTrainingPlan'
import { UserTrainingPlanPillVM } from '../view-models/UserTrainingPlanPillVM'
import { TrainingItemPillVM } from '../view-models/TrainingItemPillVM'
export class CalendarItemsFindService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public findEventsForRole(roleId: string): Event[] {
    return this.rootStore.eventsStore.currentOrgEvents.filter((ev) => {
      const thisRoleInvited =
        ev.participants.find((e) => e.id === roleId && e.type === 'role') !== undefined
      return thisRoleInvited
    })
  }

  public findEventsForGroup(groupId: string): Event[] {
    return this.rootStore.eventsStore.currentOrgEvents.filter((ev) => {
      const invited =
        ev.participants.find((e) => e.id === groupId && e.type === 'group') !== undefined
      return invited
    })
  }

  public findSurveysForLoggedInUser(): UserSurvey[] {
    return this.rootStore.userSurveysStore.currentOrgUserSurveys.filter(
      (e) => e.userId === this.rootStore.appStore.currentUserId
    )
  }

  public findTrainingsForLoggedInUser(): UserTrainingPlan[] {
    return this.rootStore.userTrainingPlansStore.orgUserTrainingPlans.filter(
      (e) => e.userId === this.rootStore.appStore.currentUserId
    )
  }

  public findSurveysForUser(userId: string): UserSurvey[] {
    return this.rootStore.userSurveysStore.currentOrgUserSurveys.filter(
      (e) => e.userId === userId && e.publishedByUserId === this.rootStore.appStore.currentUserId
    )
  }

  public findTrainingPlansForUser(userId: string): UserTrainingPlan[] {
    return this.rootStore.userTrainingPlansStore.orgUserTrainingPlans.filter(
      (e) => e.userId === userId && e.assignedByUserId === this.rootStore.appStore.currentUserId
    )
  }

  public findTasksForUser(userId: string): UserTask[] {
    return this.rootStore.userTasksStore.currentOrgTasks.filter(
      (e) => e.userId === userId && e.assignedByUserId === this.rootStore.appStore.currentUserId
    )
  }

  public getCalendarPills(
    forLoggedInUser: boolean,
    events: boolean,
    surveys: boolean,
    tasks: boolean,
    trainingPlans: boolean,
    participants: ParticipantVM[],
    fromDate: Date,
    thruDate: Date
  ): IEventPillVM[] {
    const allEvents: IEventPillVM[] = []
    if (events && forLoggedInUser) {
      const loggedInUserEvents = this.rootStore.userEventsStore
        .getUserEventsForLoggedInUser()
        .map((e) => new UserEventPillVM(this.rootStore, e))
      loggedInUserEvents.forEach((e) => {
        const moreEvents = this.getMissingSeriesUserEvents(e.userEvent, fromDate, thruDate).map(
          (ee) => new UserEventPillVM(this.rootStore, ee)
        )
        moreEvents.forEach((ee) => allEvents.push(ee))
      })
      loggedInUserEvents.forEach((e) => allEvents.push(e))
    }
    if (surveys && forLoggedInUser) {
      const userSurveys = this.findSurveysForLoggedInUser().map(
        (e) => new UserSurveyPillVM(this.rootStore, e)
      )
      userSurveys.forEach((e) => allEvents.push(e))
    }
    if (trainingPlans && forLoggedInUser) {
      const userTrainingPlansForLoggedInUser = this.findTrainingsForLoggedInUser()
      const userTrainingPlans = userTrainingPlansForLoggedInUser.map(
        (e) => new UserTrainingPlanPillVM(this.rootStore, e)
      )
      userTrainingPlans.forEach((e) => allEvents.push(e))
      const userTrainingItems = []
      userTrainingPlansForLoggedInUser.map((e) => {
        const items = e.trainingPlanItems.map(
          (item) => new TrainingItemPillVM(this.rootStore, item, e.objectId)
        )
        items.forEach((e) => userTrainingItems.push(e))
      })
      userTrainingItems.forEach((e) => allEvents.push(e))
    }
    if (tasks && forLoggedInUser) {
      const userTasks = this.rootStore.userTasksStore.currentOrgTasks
        .filter((e) => e.userId === this.rootStore.appStore.currentUserId)
        .map((e) => new UserTaskPillVM(this.rootStore, e))
      userTasks.forEach((e) => allEvents.push(e))
    }
    participants.forEach((cal) => {
      if (cal.type === 'user' && events) {
        const thisUserEvents = this.rootStore.userEventsStore
          .getUserEventsForUser(cal.id)
          .map((e) => new UserEventPillVM(this.rootStore, e))
        thisUserEvents.forEach((e) => {
          const moreEvents = this.getMissingSeriesUserEvents(e.userEvent, fromDate, thruDate).map(
            (ee) => new UserEventPillVM(this.rootStore, ee)
          )
          moreEvents.forEach((ee) => allEvents.push(ee))
        })
        thisUserEvents.forEach((e) => allEvents.push(e))
      }
      allEvents.filter((e) => {
        if (!e.event.eventId) return false
        return true
      })
      if (cal.type === 'role' && events) {
        const thisRoleEvents = this.findEventsForRole(cal.id).map(
          (e) => new RoleEventPillVM(this.rootStore, cal.id, e)
        )
        thisRoleEvents.forEach((e) => {
          const moreEvents = this.getMissingSeriesUserEvents(e.event, fromDate, thruDate).map(
            (ee) => new RoleEventPillVM(this.rootStore, cal.id, ee)
          )
          moreEvents.forEach((ee) => allEvents.push(ee))
        })
        thisRoleEvents.forEach((e) => allEvents.push(e))
      }
      if (cal.type === 'group' && events) {
        const thisGroupEvents = this.findEventsForGroup(cal.id).map(
          (e) => new GroupEventPillVM(this.rootStore, cal.id, e)
        )
        thisGroupEvents.forEach((e) => {
          const moreEvents = this.getMissingSeriesUserEvents(e.event, fromDate, thruDate).map(
            (ee) => new GroupEventPillVM(this.rootStore, cal.id, ee)
          )
          moreEvents.forEach((ee) => allEvents.push(ee))
        })
        thisGroupEvents.forEach((e) => allEvents.push(e))
      }
      if (tasks) {
        const otherCalendarTasks = this.findTasksForUser(cal.id).map(
          (e) => new UserTaskPillVM(this.rootStore, e, cal.id)
        )
        otherCalendarTasks.forEach((e) => allEvents.push(e))
      }
      if (surveys) {
        const otherCalendarSurveys = this.findSurveysForUser(cal.id).map(
          (e) => new UserSurveyPillVM(this.rootStore, e, cal.id)
        )
        otherCalendarSurveys.forEach((e) => allEvents.push(e))
      }
      if (trainingPlans) {
        const otherCalendarTrainingPlans = this.findTrainingPlansForUser(cal.id).map(
          (e) => new UserTrainingPlanPillVM(this.rootStore, e, cal.id)
        )
        otherCalendarTrainingPlans.forEach((e) => allEvents.push(e))
      }
    })
    return allEvents.filter((e) => {
      if (!e.event) return true
      if (e.event.isDeleted) return false
      return true
    })
  }

  private getMissingSeriesUserEvents(
    userEvent: UserEvent | Event,
    fromDate: Date,
    thruDate: Date
  ): UserEvent[] {
    if (!userEvent.schedule) return []
    if (!userEvent.schedule.enabled) return []
    const svc = new EventsScheduleService(this.rootStore)
    const dtos = svc.getMissingSeriesUserEvents(userEvent, fromDate, thruDate)
    if (!dtos) return []
    return dtos.map((e) => deserialize(UserEvent, e))
  }
}
