import { observable, action, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import PulseCategory from '../aggregate/PulseCategory'
import { PulseCategoriesStore } from '../store/PulseCategoriesStore'
import { PulseCategoryEditVM } from '../view-models/PulseCategoryEditVM'

export class PulseCategoriesViewModelsService {
  private rootStore: RootStore
  private pulseCategoriesStore: PulseCategoriesStore

  constructor(rootStore: RootStore, pulseCategoriesStore: PulseCategoriesStore) {
    this.rootStore = rootStore
    this.pulseCategoriesStore = pulseCategoriesStore
  }

  @observable public editVM: PulseCategoryEditVM = null

  public async loadPulseCategoryEditVM(objectId: string) {
    if (objectId === 'empty') return this.editVM = null
    const pulseCategory = await this.pulseCategoriesStore.getFullRecord(objectId, true)
    this.editVM = new PulseCategoryEditVM(this.rootStore, pulseCategory.clone())
  }

}
