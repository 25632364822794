import { observable, action, computed } from 'mobx'
import formatPhoneNumber from '../../../utils/formatPhoneNumber'
import rootStore from '../../stores/RootStore'

export default class User {
  static create(orgId) {
    const user = new User()
    user.organizationId = orgId
    return user
  }

  @observable objectId: string = ''
  @observable organizationId: string = ''
  @observable userId: string = ''
  @observable privilegeSets: Array<string> = []
  @observable privilegeSetsFromGroupsAndRoles: Array<any> = []
  @observable superiors: Array<any> = []
  @observable subordinates: Array<any> = []
  @observable isDisabled: boolean
  @observable disabled: boolean
  @observable name: string = ''
  @observable sortName: string = ''
  @observable firstName: string = ''
  @observable lastName: string = ''
  @observable nickname: string = ''
  @observable email: string = ''
  @observable birthDate: string = ''
  @observable roleStartDate: string = ''
  @observable phone: string = ''
  @observable iconURL: string = ''
  @observable title: string = ''
  @observable type: string = ''
  @observable employeeId: string = ''
  @observable jobNumber: string = ''
  @observable roles: Array<any> = []
  @observable groups: Array<any> = []
  @observable receiveEmails: boolean = null
  @observable receivePushNotifications: boolean = null
  @observable receiveTextMessages: boolean = null
  @observable isProcessing: boolean = false
  @observable primaryRoleId: string = ''
  @observable primaryGroupId: string = ''
  @observable allAudienceUsers: string[] = []
  @observable allSubordinates: string[] = []
  @observable additionalAudienceMembers: any = []
  @observable languagePreference: string = ''
  @observable bypassOIDCAuth: boolean = false
  @observable isDirectSubordinate: boolean = false
  isOnServer: boolean = false
  isDeleted: boolean = false

  serialize() {
    return {}
  }

  markIsOnServer() {
    this.isOnServer = true
  }

  markIsNotOnServer() {
    this.isOnServer = false
  }

  @action
  markIsNotProcessing() {
    this.isProcessing = false
  }

  markIsProcessing() {
    this.isProcessing = true
  }

  @action
  processRoles(roles) {
    const roleIds = Object.values(roles)
    return roleIds
  }

  @action
  processGroups(groups) {
    const groupIds = Object.values(groups)
    return groupIds
  }

  @computed
  public get isAdmin(): boolean {
    const foundRole = rootStore.rolesStore.getRoleByName('Administrator')
    return Boolean(this.roles.find((role) => role === foundRole.objectId))
  }

  updateUserInfoFromServer(fromObject) {
    this.name = fromObject.name
    this.firstName = fromObject.firstName
    this.lastName = fromObject.lastName
    this.sortName = fromObject.sortName
    this.email = fromObject.publicEmail
    this.languagePreference = fromObject.languagePreference
    this.nickname = fromObject.nickname
    this.birthDate =
      fromObject.birthDate &&
      fromObject.birthDate !== 'null' &&
      fromObject.birthDate !== 'undefined'
        ? fromObject.birthDate
        : ''
    this.phone = formatPhoneNumber(fromObject.phone)
    this.iconURL = fromObject.iconURL
    this.receiveEmails = fromObject.receiveEmails
    this.receivePushNotifications = fromObject.receivePushNotifications
    this.receiveTextMessages = fromObject.receiveTextMessages
  }

  private setPrivilegeSets(user: User) {
    const privilegeSets = []
    const privilegeSetsFromGroupsAndRoles = []

    if (user.privilegeSets && user.privilegeSets.length) privilegeSets.push(...user.privilegeSets)

    if (this.groups && this.groups.length) {
      this.groups.forEach((groupId) => {
        const group =
          rootStore.groupsStore.groups && rootStore.groupsStore.groups.find
            ? rootStore.groupsStore.groups.find((group) => group.objectId === groupId)
            : null

        if (group && group.privilegeSets && group.privilegeSets.length) {
          privilegeSets.push(...group.privilegeSets)

          group.privilegeSets.forEach((privilegeSetObjectId) => {
            privilegeSetsFromGroupsAndRoles.push({
              privilegeSetObjectId,
              originType: 'group',
              originName: group.name,
            })
          })
        }
      })
    }

    if (this.roles && this.roles.length) {
      this.roles.forEach((roleId) => {
        const role =
          rootStore.rolesStore.roles && rootStore.rolesStore.roles.find
            ? rootStore.rolesStore.roles.find((group) => group.objectId === roleId)
            : null

        if (role && role.privilegeSets && role.privilegeSets.length) {
          privilegeSets.push(...role.privilegeSets)

          role.privilegeSets.forEach((privilegeSetObjectId) => {
            privilegeSetsFromGroupsAndRoles.push({
              privilegeSetObjectId,
              originType: 'role',
              originName: role.name,
            })
          })
        }
      })
    }

    this.privilegeSets = Array.from(new Set(privilegeSets))
    this.privilegeSetsFromGroupsAndRoles = privilegeSetsFromGroupsAndRoles
  }

  updateFromServer(fromObject: any) {
    if (!fromObject.User) fromObject.User = {}
    this.objectId = fromObject.objectId
    this.name = fromObject.name
    this.firstName = fromObject.firstName
    this.lastName = fromObject.lastName
    this.sortName = fromObject.sortName
    this.userId = fromObject.userId
    this.disabled = fromObject.disabled
    this.isDisabled = fromObject.isDisabled
    this.allAudienceUsers = fromObject.allAudienceUsers
    this.allSubordinates = fromObject.allSubordinates
    this.additionalAudienceMembers = fromObject.additionalAudienceMembers
    this.title = fromObject.title
    this.superiors = fromObject.superiors ? fromObject.superiors : []
    this.subordinates = fromObject.subordinates ? fromObject.subordinates : []
    this.isOnServer = true
    this.employeeId = fromObject.employeeId
    this.bypassOIDCAuth = fromObject.bypassOIDCAuth
    this.jobNumber = fromObject.jobNumber
    this.primaryRoleId = fromObject.primaryRoleId
    this.primaryGroupId = fromObject.primaryGroupId
    this.roles = fromObject.roles ? this.processRoles(fromObject.roles) : []
    this.groups = fromObject.groups ? this.processGroups(fromObject.groups) : []
    this.setPrivilegeSets(fromObject)
    this.roleStartDate =
      fromObject.roleStartDate &&
      fromObject.roleStartDate !== 'null' &&
      fromObject.roleStartDate !== 'undefined'
        ? fromObject.roleStartDate
        : ''
  }

  public toDTO() {
    return {
      objectId: this.objectId ? this.objectId : null,
      userId: this.objectId ? this.userId : null,
      name: this.name,
      firstName: this.firstName,
      lastName: this.lastName,
      sortName: this.sortName,
      nickname: this.nickname,
      email: this.email,
      primaryRoleId: this.primaryRoleId,
      iconURL: this.iconURL,
      roles: [...this.roles],
      groups: [...this.groups],
      birthDate: this.birthDate,
      roleStartDate: this.roleStartDate,
      phone: this.phone,
      title: this.title,
      privilegeSets: [...this.privilegeSets],
      superiors: [...this.superiors],
      subordinates: [...this.subordinates],
      receiveEmails: this.receiveEmails,
      receivePushNotifications: this.receivePushNotifications,
      receiveTextMessages: this.receiveTextMessages,
      disabled: false,
      isDisabled: this.isDisabled,
    }
  }
}
