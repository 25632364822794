import React, { useRef, useEffect } from 'react'
import clsx from 'clsx'
import { inject, observer } from 'mobx-react'
import { AppStore } from '../stores/AppStore'
import { OrganizationsStore } from '../organizations/store/OrganizationsStore'
import { UsersStore } from '../users/store/UsersStore'
import { DashboardsStore } from '../dashboards/store/DashboardsStore'
import LocalizationStore from '../localization/LocalizationStore'
import AvatarContainer from '../user/views/avatar-menu/AvatarContainer'
import AppBar from '@material-ui/core/AppBar'
import Drawer from '@material-ui/core/Drawer'
import CssBaseline from '@material-ui/core/CssBaseline'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Fade from '@material-ui/core/Fade'
import Tooltip from '@material-ui/core/Tooltip'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import DashboardIcon from '@material-ui/icons/DashboardRounded'
import CalendarIcon from '@material-ui/icons/CalendarTodayRounded'
import SkillsIcon from '@material-ui/icons/BarChartRounded'
import SurveysIcon from '@material-ui/icons/LiveHelpOutlined'
import OrgAdminIcon from '@material-ui/icons/SettingsRounded'
import SysAdminIcon from '@material-ui/icons/VpnKeyRounded'
import TasksIcon from '@material-ui/icons/Assignment'
import FlowsIcon from '@material-ui/icons/Input'
import TimelineIcon from '@material-ui/icons/Timeline'
import defaultLogo from '../../assets/img/logos/ripple-bluewhite.png'
import ChartWidgetDrawer from '../worksheets/views/viz-widget/drawer/ChartWidgetDrawer'
import { ConsentsStore } from '../consents/store/ConsentsStore'
import './Navigation.scss'
import { Typography } from '@material-ui/core'
import GoalsIcon from '@material-ui/icons/TrackChanges'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { AnnouncementsStore } from '../announcements/store/AnnouncementsStore'
import AnnouncementsWidget from '../announcements/views/AnnouncementsWidget'
import AnnouncementsSVG from '../../assets/img/campaign-24px.svg'
import { UserStore } from '../user/store/UserStore'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'
import isIE11 from '../../utils/isIE11'
import DateFnsUtils from '@date-io/date-fns'
import format from 'date-fns/format'
import deLocale from 'date-fns/locale/de'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import NotificationsWidget from '../notifications-widget/views/NotificationsWidget'
import OrganizationUserEditDrawer from '../organization-users/views/drawer/OrganizationUserEditDrawer'
import fullLogo from '../../assets/img/logos/ripple-text-only-white.png'
import OrganizationUserPasswordEditDrawer from '../organization-users/views/password-drawer/OrganizationUserPasswordEditDrawer'
import SurveyStreak from '../survey-streak/views/SurveyStreak'
import { OrganizationUsersStore } from '../organization-users/store/OrganizationUsersStore'
import Streak0 from '../../assets/img/streak/Fill-0.png'
import Streak1 from '../../assets/img/streak/Fill-1.png'
import Streak2 from '../../assets/img/streak/Fill-2.png'
import Streak3 from '../../assets/img/streak/Fill-3.png'
import { LabelsStore } from '../labels/store/LabelsStore'
import UserInfoPrompt from '../user-info-prompt/views/UserInfoPrompt'
import UserAdminIcon from '@material-ui/icons/SupervisorAccount'

interface NavigationProps {
  appStore?: AppStore
  organizationsStore?: OrganizationsStore
  localizationStore?: LocalizationStore
  usersStore?: UsersStore
  userStore?: UserStore
  dashboardsStore?: DashboardsStore
  children: any
  router: any
  consentsStore?: ConsentsStore
  announcementsStore?: AnnouncementsStore
  organizationUsersStore?: OrganizationUsersStore
  labelsStore?: LabelsStore
}

const Navigation: React.FC<NavigationProps> = ({
  appStore,
  organizationsStore,
  usersStore,
  userStore,
  children,
  router,
  consentsStore,
  announcementsStore,
  localizationStore,
  organizationUsersStore,
  labelsStore,
}) => {
  useEffect(() => {
    if (consentsStore.shouldRedirectToConsent) router.push('/consent')
  }, [consentsStore.shouldRedirectToConsent, router])

  const { announcementsWidgetVM } = announcementsStore
  const { surveyStreakVM } = organizationUsersStore
  const { notificationsWidgetVM: vm } = appStore

  // const toggleUserDrawer = (drawer) => (event: React.KeyboardEvent | React.MouseEvent) => {
  //   if (
  //     event.type === 'keydown' &&
  //     ((event as React.KeyboardEvent).key === 'Tab' ||
  //       (event as React.KeyboardEvent).key === 'Shift')
  //   )
  //     return

  //   if (drawer === 'addUser') usersStore.showAddDrawer = !usersStore.showAddDrawer

  //   if (drawer === 'editUser') {
  //     usersStore.editDrawerShown = !usersStore.editDrawerShown
  //   }
  // }

  const isDrawerOpen = appStore.isDrawerOpen

  const navigate = (name) => {
    switch (name) {
      case 'tasks':
        router.push('/dashboard/tasks')
        break
      case 'dashboard':
        router.push('/dashboard/userDashboard')
        break
      case 'analysis':
        router.push('/userWorksheets')
        break
      case 'pulse':
        router.push('/pulse/v1/campaigns')
        break
      case 'tenantAdmin':
        router.push('/dashboard/tenantAdmin/org')
        break
      case 'surveys':
        router.push('/dashboard/surveys')
        break
      case 'system':
        router.push('/dashboard/systemAdmin')
        break
      case 'userAdmin':
        router.push('/dashboard/tenantAdmin/users')
        break
      case 'impact':
        if (organizationsStore.currentOrganization.isGoalsEnabled) {
          router.push('/dashboard/impact/goals')
        } else {
          router.push('/dashboard/impact/trainings')
        }
        break
      default:
        router.push(`/${name}`)
    }
  }

  const buttons = [
    { name: 'dashboard', enabled: true, label: 'Dashboard' },
    {
      name: 'calendar',
      label: 'Calendar',
      enabled: organizationsStore.currentOrganization
        ? organizationsStore.currentOrganization.isCalendarEnabled
        : false,
    },
    { name: 'analysis', enabled: true, label: 'Analysis' },
    {
      name: 'pulse',
      label: 'Campaigns',
      enabled: organizationsStore.currentOrganization
        ? organizationsStore.currentOrganization.isPulseCampaignsEnabled &&
          appStore.canManagePulseCampaigns
        : false,
    },
    {
      name: 'surveys',
      label: labelsStore.getLabelByLanguageAndFor('pulses'),
      enabled: organizationsStore.currentOrganization
        ? organizationsStore.currentOrganization.isSurveysEnabled
        : false,
    },
    {
      name: 'tasks',
      label: 'Tasks',
      enabled: organizationsStore.currentOrganization
        ? organizationsStore.currentOrganization.isTasksEnabled
        : false,
    },
    {
      name: 'impact',
      label: 'Impact',
      enabled: organizationsStore.currentOrganization
        ? organizationsStore.currentOrganization.isGoalsEnabled ||
          organizationsStore.currentOrganization.isTrainingsEnabled
        : false,
    },
    {
      name: 'pademo',
      label: 'Flows',
      enabled: false,
    },
    {
      name: 'userAdmin',
      label: 'Manage Users',
      enabled: Boolean(appStore.isOrgAdmin) || Boolean(appStore.canManageUsers),
    },
    { name: 'tenantAdmin', label: 'Tenant Admin', enabled: Boolean(appStore.isOrgAdmin) },
    { name: 'system', label: 'System', enabled: Boolean(appStore.isSystemAdmin) },
  ]

  const renderBadge = (name) => {
    if (name === 'calendar' && appStore.calendarNotifications > 0)
      return <div className='left-nav-badge'>{appStore.calendarNotifications}</div>
    if (name === 'tasks' && appStore.taskNotifications > 0)
      return <div className='left-nav-badge'>{appStore.taskNotifications}</div>
    if (name === 'surveys' && appStore.surveyNotifications > 0)
      return <div className='left-nav-badge'>{appStore.surveyNotifications}</div>
    if (name === 'impact' && appStore.impactNotifications > 0)
      return <div className='left-nav-badge'>{appStore.impactNotifications}</div>
  }

  const renderButtons = () => {
    const path = router.location.pathname
    const buttonElements = []

    buttons.forEach((button, index) => {
      const { name, enabled } = button
      const useTooltips = true

      if (enabled) {
        let icon
        let isActive = Boolean(path.startsWith(`/${name}`))

        if (
          name === 'dashboard' &&
          (path.startsWith('/dashboard/tenantAdmin/users') ||
            path.startsWith('/dashboard/tenantAdmin') ||
            path.startsWith('/dashboard/systemAdmin') ||
            path.startsWith('dasboard/userDashboard') ||
            path.startsWith('/dashboard/tasks') ||
            path.startsWith('/dashboard/surveys') ||
            path.startsWith('/dashboard/impact') ||
            path.startsWith('/dashboard/worksheets'))
        ) {
          isActive = false
        }

        if (name === 'userAdmin' && path.startsWith('/dashboard/tenantAdmin/users')) {
          isActive = true
        }

        if (
          name === 'tenantAdmin' &&
          path.startsWith('/dashboard/tenantAdmin') &&
          !path.includes('/users')
        ) {
          isActive = true
        }

        if (name === 'system' && path.startsWith('/dashboard/systemAdmin')) {
          isActive = true
        }

        if (
          name === 'surveys' &&
          (path.startsWith('/dashboard/surveys') ||
            path.startsWith('/usersurveys/') ||
            path.startsWith('/surveyresponses/'))
        ) {
          isActive = true
        }

        if (name === 'analysis' && path.startsWith('/userWorksheets')) {
          isActive = true
        }

        if (name === 'pulse' && path.startsWith('/pulse')) {
          isActive = true
        }

        if (name === 'tasks' && path.startsWith('/dashboard/tasks')) {
          isActive = true
        }

        if (name === 'impact' && path.startsWith('/dashboard/impact')) {
          isActive = true
        }

        if (name === 'pademo' && path.startsWith('/pademo')) {
          isActive = true
        }

        switch (name) {
          case 'dashboard':
            icon = <DashboardIcon className='nav-button-icon' />
            break
          case 'calendar':
            icon = <CalendarIcon className='nav-button-icon' />
            break
          case 'analysis':
            icon = <SkillsIcon className='nav-button-icon' />
            break
          case 'surveys':
            icon = <SurveysIcon className='nav-button-icon sln-nav-button-surveys' />
            break
          case 'tenantAdmin':
            icon = <OrgAdminIcon className='nav-button-icon' />
            break
          case 'system':
            icon = <SysAdminIcon className='nav-button-icon' />
            break
          case 'tasks':
            icon = <TasksIcon className='nav-button-icon' />
            break
          case 'impact':
            icon = <GoalsIcon className='nav-button-icon' />
            break
          case 'pademo':
            icon = <FlowsIcon className='nav-button-icon' />
            break
          case 'pulse':
            icon = <TimelineIcon className='nav-button-icon' />
            break
          case 'userAdmin':
            icon = <UserAdminIcon className='nav-button-icon' />
            break
          default:
            icon = null
        }

        const key = name + '-' + index

        const el = (
          <ListItem
            button
            key={key}
            className={
              isActive
                ? 'nav-button active-button nav-button-' + name
                : 'nav-button nav-button-' + name
            }
            onClick={() => navigate(name)}
            disabled={consentsStore.shouldRedirectToConsent}
          >
            <ListItemIcon
              className={clsx({
                'active-icon': isActive,
              })}
            >
              {icon}
              {renderBadge(name)}
            </ListItemIcon>
            <ListItemText primary={button.label} />
          </ListItem>
        )

        if (!useTooltips) {
          buttonElements.push(el)
        } else {
          const elWithTooltip = (
            <Tooltip
              key={name + '-' + index}
              title={(isDrawerOpen ? '' : button.label) && button.label}
              placement='right'
            >
              {el}
            </Tooltip>
          )

          buttonElements.push(elWithTooltip)
        }
      }
    })
    return buttonElements
  }

  const renderLogo = () => {
    if (!organizationsStore.currentOrganization) return null
    const { logoUrl, name } = organizationsStore.currentOrganization
    return (
      <span onClick={() => navigate('dashboard')}>
        <div
          className={clsx('company-logo', {
            isDrawerOpen,
            'no-transition': isIE11,
          })}
        >
          <img
            src={Boolean(logoUrl) ? logoUrl : defaultLogo}
            alt={`${name} Logo`}
            className={'logo-img'}
          />
        </div>
      </span>
    )
  }

  const appbarRef = useRef()

  const renderAppOffline = () => {
    if (appStore.isOnline) return null
    return (
      <Tooltip title='App offline. Please try again later.'>
        <ErrorOutlineIcon />
      </Tooltip>
    )
  }

  const renderUserAnnouncementsCount = () => {
    if (!announcementsWidgetVM || announcementsWidgetVM.userAnnouncementsCount === 0) return null
    return <div className='top-nav-badge'>{announcementsWidgetVM.userAnnouncementsCount}</div>
  }

  const renderNotificationCount = () => {
    if (!vm || vm.notificationCount === 0) return null
    return <div className='top-nav-badge'>{vm.notificationCount}</div>
  }

  const renderEnvironmentLabel = () => {
    if (appStore.environmentLabel === '') return null
    return (
      <div style={{ paddingRight: 10 }}>
        <Typography>{appStore.environmentLabel}</Typography>
      </div>
    )
  }

  const renderSurveyStreakPillIcon = () => {
    const streak = surveyStreakVM.surveyStreak
    if (streak >= 30) return <img className='drop-icon' src={Streak3} alt='' />
    if (streak >= 20) return <img className='drop-icon' src={Streak2} alt='' />
    if (streak >= 10) return <img className='drop-icon2' src={Streak1} alt='' />
    return <img className='drop-icon2' src={Streak0} alt='' />
  }

  const renderSurveyStreakPill = () => {
    if (!surveyStreakVM) return null
    if (!surveyStreakVM.showSurveyStreak) return null
    return (
      <>
        <div className='dropdown' style={{ float: 'right' }}>
          <IconButton className='dropbtn'>
            {renderSurveyStreakPillIcon()}
            <Typography className='h4'>{surveyStreakVM.surveyStreak}</Typography>
          </IconButton>
          <div
            className={
              surveyStreakVM.noStreak ? 'dropdown-content zero' : 'dropdown-content non-zero'
            }
          >
            <SurveyStreak />
          </div>
        </div>
      </>
    )
  }

  const renderAnnouncementsButton = () => {
    if (!announcementsWidgetVM) return null
    if (!announcementsWidgetVM.shouldRender) return null
    return (
      <>
        <Tooltip
          title={labelsStore.getLabelByLanguageAndFor('announcements')}
          placement='bottom'
          enterDelay={500}
        >
          <IconButton
            className='app-bar-icon'
            onClick={(e) => {
              announcementsWidgetVM.setAnchorEl(e.currentTarget)
              announcementsWidgetVM.toggleAnnouncementsWidgetOpen()
            }}
          >
            <img src={AnnouncementsSVG} alt='' />

            {renderUserAnnouncementsCount()}
          </IconButton>
        </Tooltip>
      </>
    )
  }

  const appbarRight = (
    <div className={'app-bar-right'}>
      {renderSurveyStreakPill()}
      {renderAnnouncementsButton()}
      <Tooltip title='Notifications' placement='bottom' enterDelay={500}>
        <IconButton
          className='app-bar-icon'
          onClick={(e) => {
            vm.setAnchorEl(e.currentTarget)
            vm.toggleNotificationsWidgetOpen()
          }}
        >
          <NotificationsNoneIcon />
          {renderNotificationCount()}
        </IconButton>
      </Tooltip>

      {renderEnvironmentLabel()}
      {renderAppOffline()}
      <AvatarContainer />
    </div>
  )

  const renderAppbar = () => {
    return (
      <AppBar
        id='Appbar'
        ref={appbarRef}
        position='fixed'
        className={clsx('app-bar', {
          'app-bar-shift': isDrawerOpen,
          'no-transition': isIE11,
        })}
        elevation={2}
      >
        <Toolbar id='NavToolbar'>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={() => appStore.toggleDrawer()}
            edge='start'
            className={isDrawerOpen ? 'hide' : 'nav-button-icon'}
          >
            <MenuIcon />
          </IconButton>
          {renderLogo()}
          {appbarRight}
        </Toolbar>
      </AppBar>
    )
  }

  const renderDrawer = () => {
    return (
      <Drawer
        variant='permanent'
        className={clsx('drawer', {
          'drawer-open': isDrawerOpen,
          'drawer-close': !isDrawerOpen,
          'no-transition': isIE11,
        })}
        classes={{
          paper: clsx({
            'drawer-open': isDrawerOpen,
            'drawer-close': !isDrawerOpen,
            'no-transition': isIE11,
          }),
        }}
        open={isDrawerOpen}
      >
        <div className='toolbar'>
          <IconButton onClick={() => appStore.toggleDrawer()}>
            <ChevronLeftIcon className='nav-button-icon' />
          </IconButton>
        </div>
        <List>{renderButtons()}</List>
        <div className={'ripple-logo-container'}>
          <div className={'ripple-logo-position'}>
            <div className={'ripple-logo'}>
              <img src={'assets/img/logos/ripple-drop.png'} height='30' alt='' />
              <Fade in={isDrawerOpen}>
                <img className={'ripple-logo-text'} src={fullLogo} height='30' alt='' />
              </Fade>
            </div>
          </div>
        </div>
      </Drawer>
    )
  }

  const renderUserDrawer = () => {
    return <OrganizationUserEditDrawer />
  }

  const renderUserPasswordDrawer = () => {
    return <OrganizationUserPasswordEditDrawer />
  }

  class LocalizedUtils extends DateFnsUtils {
    getDatePickerHeaderText(date) {
      return format(date, 'd MMM yyyy', { locale: this.locale })
    }
  }

  const getLocale = () => {
    if (!userStore.loaded) {
      if (localizationStore.browserLocale === 'de') return deLocale
    }
    if (userStore.user && userStore.user.culture === 'de') return deLocale
    return undefined
  }

  return (
    <div id='Navigation'>
      <UserInfoPrompt />
      <MuiPickersUtilsProvider utils={LocalizedUtils} locale={getLocale()}>
        <CssBaseline />
        {renderAppbar()}
        {renderDrawer()}
        <main className='content'>
          <div className='toolbar' />

          {children}
          {renderUserDrawer()}
          {renderUserPasswordDrawer()}

          <ChartWidgetDrawer />
          <AnnouncementsWidget />
        </main>
      </MuiPickersUtilsProvider>
      <NotificationsWidget />
    </div>
  )
}

export default inject(
  'appStore',
  'organizationsStore',
  'organizationUsersStore',
  'usersStore',
  'consentsStore',
  'announcementsStore',
  'userStore',
  'localizationStore',
  'labelsStore'
)(observer(Navigation))
