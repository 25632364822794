import { action, computed, observable } from "mobx"
import { ParticipantsSelectVM } from "src/app/participants-select/view-models/ParticipantsSelectVM"
import { RootStore } from "src/app/stores/RootStore"

export class UserSelectModalVM {
    @observable public userSelectVM: ParticipantsSelectVM = null
    private rootStore: RootStore
    @observable public userSelectModalOpen: boolean = false
    @observable public user = null
    
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.userSelectVM = new ParticipantsSelectVM(
            this.rootStore,
            false,
            false,
            false,
            false,
            false,
            false,
            false
            )
    }

    @computed
    public get userValid() {
        return true
    }
    
    @action
    public toggleUserSelectOpen() {
        console.log('toggle me')
        this.userSelectModalOpen = !this.userSelectModalOpen
        if (this.userSelectModalOpen) {
            this.userSelectVM.setVisible()
        }
        if (this.user && this.userSelectModalOpen) {
            this.userSelectVM.setParticipants([this.user])
        }
    }

    @action
    public setUser() {
        if (this.userSelectVM.participants.length === 0) {
            this.user = null
        } else {
            this.user = this.userSelectVM.participants[0].toDTO()
        }
        this.toggleUserSelectOpen()
    }
}