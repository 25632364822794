import { RootStore } from '../../stores/RootStore'
import { DataStore } from '../../shared/data/DataStore'
import { IClientDTO } from '../../clients/dtos/IClientDTO'
import { Client } from '../../clients/aggregate/Client'

export class ClientsListDataStore extends DataStore<Client, IClientDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, Client, 'clients', ['objectId', 'name'])
    this.paged = true
    this.recordsPerPage = 100
    this.sortColumnName = 'name'
    this.filterColumns = ['name']
  }

  public get clients() {
    return this.records
  }
}
``