import React, { FC } from 'react'
import { inject, observer } from 'mobx-react'
import { CardContent, Typography } from '@material-ui/core'
import { WidgetRowVM } from '../../view-models/widget/WidgetRowVM'
import { AgGridReact } from 'ag-grid-react'
import AGGridUserCell from '../../../shared/ag-grid/AGGridUserCell'
import AGGridWidgetPagingRow from '../../../shared/ag-grid/AGGridPagingRow'
import { ArchivedTabVM } from './../../view-models/widget/ArchivedTabVM'
import ActionsCell from './../my-audience-widget/ActionsCell'
import WidgetHeader from './WidgetHeader'
import './OrganizationUsersWidget.scss'
import './Table.scss'
import SpinnerOverlay from '../../../shared/SpinnerOverlay'

interface Props {
  vm: ArchivedTabVM
}

const ActiveTab: FC<Props> = ({ vm }) => {
  const renderNameCell = (props) => {
    const row: WidgetRowVM = props.data
    if (!row) return null
    return <AGGridUserCell userId={row.userId} name={row.name} sortName={row.sortName} iconURL={row.iconURL} />
  }

  const renderGrid = () => {
    if (!vm.shouldRenderGrid) return
    return (
      <div
        className={vm.hasRows ? 'ag-theme-alpine' : 'ag-theme-alpine ag-grid-no-rows'}
        style={{ height: '100%', width: '100%', display: vm.shown ? undefined : 'none' }}
      >
        <AgGridReact
          gridOptions={{
            ...vm.getGridOptions(),
            frameworkComponents: {
              nameCell: renderNameCell,
              actionsCell: ActionsCell,
            },
          }}
        />
      </div>
    )
  }

  const renderSpinner = () => {
    if (vm.shown && vm.shouldRender) return
    return <SpinnerOverlay />
  }

  const renderPagingRow = () => {
    if (!vm.shown) return
    return <AGGridWidgetPagingRow vm={vm} />
  }

  const renderHeader = () => {
    if (!vm.shown) return
    return (
      <WidgetHeader localizationStore={vm.lzStore} orgUsersVM={vm.orgUsersVM} vm={vm} />
    )
  }

  const renderNoRowsContent = () => {
    if (vm.hasRows) return
    return (
      <Typography component='div' className='no-rows'>
        {vm.noRowsToShowLabel}
      </Typography>
    )
  }

  return (
    <>
      {renderHeader()}
      <CardContent className='no-padding' style={{ display: vm.shown ? undefined : 'none' }}>
        {renderSpinner()}
        {renderGrid()}
        {renderNoRowsContent()}
        {renderPagingRow()}
      </CardContent>
    </>
  )
}

export default inject('organizationUsersStore')(observer(ActiveTab))
