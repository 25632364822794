import React, { FC } from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { observer, inject } from 'mobx-react'
import './SurveyOptions.scss'
import { SurveysStore } from 'src/app/surveys/store/SurveysStore'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import NotificationTemplatesEditor from './NotificationTemplatesEditor'

interface Props {
  surveysStore?: SurveysStore
  localizationStore?: LocalizationStore
}

const ReminderNotificationTemplates: FC<Props> = ({ surveysStore, localizationStore }) => {
  const surveyEditVM = surveysStore.viewModels.editVM
  const vm = surveyEditVM.surveyOptionsVM
  if (!surveyEditVM) return null
  if (!vm) return null
  const s = localizationStore.lzStrings
  return (
    <div className='survey-published-notification-templates'>
      <div className='primary-text'>Reminder Templates</div>
      <div className='section-content'>
        <div>
          <Tabs
            orientation='horizontal'
            variant='standard'
            value={vm.reminderTemplatesTabIndex}
            onChange={(e, index) => {
              vm.setReminderTemplatesTabIndex(index)
            }}
            className='survey-published-notification-type-tabs'
          >
            <Tab className='survey-published-notification-type-tab' label='emails' {...a11yProps(0)} />
            <Tab className='survey-published-notification-type-tab' label='text messages' {...a11yProps(1)} />
            <Tab className='survey-published-notification-type-tab' label='push notifications' {...a11yProps(2)} />
          </Tabs>
        </div>
        <div>
          <NotificationTemplatesEditor id='reminder-notification-templates-editor' />
        </div>
      </div>
    </div>
  )
}

const TabPanel = (props) => {
  const { children, value, index, ...rest } = props
  return (
    <div
      id={`survey-reminder-notification-tabpanel-${index}`}
      aria-labelledby={`survey-reminder-notification-tabpanel-${index}`}
      hidden={value !== index}
      {...rest}
      className='options-v2-tab-panel'
    >
      {children}
    </div>
  )
}

const a11yProps = (index) => {
  return {
    id: `survey-reminder-notification-tab-${index}`,
    'aria-controls': `survey-reminder-notification-tab-${index}`,
  }
}

export default inject('surveysStore', 'localizationStore')(observer(ReminderNotificationTemplates))
