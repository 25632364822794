import Parse from 'parse'
import { observable } from 'mobx'
import { DataStore } from '../../../../shared/data/DataStore'
import { PulseCampaign } from '../../../aggregate/PulseCampaign'
import { IPulseCampaignDTO } from '../../../dto/IPulseCampaignDTO'
import { nameOf } from '../../../../shared/nameOf'
import { FKUser } from '../../../../organization-users/aggregate/FKUser'
import { RootStore } from '../../../../stores/RootStore'
import { IPulseCampaignFindRequest } from '../../../interfaces/IPulseCampaignFindRequest'
import { PulseCampaignsService } from '../../../service/PulseCampaignsService'
import { IPulseCampaignSurveyDTO } from '../../../dto/IPulseCampaignSurveyDTO'

export class AllCampaignsDataStore extends DataStore<PulseCampaign, IPulseCampaignDTO> {
  private request: IPulseCampaignFindRequest

  constructor(rootStore: RootStore, req: IPulseCampaignFindRequest) {
    super(rootStore, PulseCampaign, 'pulseCampaigns', [
      nameOf<IPulseCampaignDTO, string>((e) => e.name),
      nameOf<IPulseCampaignDTO, string>((e) => e.startDate),
      nameOf<IPulseCampaignDTO, string>((e) => e.endDate),
      nameOf<IPulseCampaignDTO, string>((e) => e.status),
      nameOf<IPulseCampaignDTO, IPulseCampaignSurveyDTO[]>((e) => e.surveys),
      nameOf<IPulseCampaignDTO, string>((e) => e.ownerUserId),
      nameOf<IPulseCampaignDTO, FKUser>((e) => e.fk_owner),
      nameOf<IPulseCampaignDTO, any>((e) => e.stats),
      nameOf<IPulseCampaignDTO, string>((e) => e.pulseCampaignTypeId),
      nameOf<IPulseCampaignDTO, boolean>((e) => e.anonymizeResults),
    ])
    this.request = req
    this.paged = true
    this.listenToListRecordsViaApiUpdates = true
    this.recordsPerPage = 100
    this.sortColumnName = 'startDate'
    this.sortDirection = 'asc'
  }

  @observable public isLoaded: boolean = false
  @observable public sortColumnName: string = 'startDate'
  @observable public sortDirection: 'asc' | 'desc' = 'asc'

  public onRecordUpdated(obj: PulseCampaign) {
    return this.loadListRecords()
  }

  public onListRecordsLoaded() {
    this.isLoaded = true
  }

  public setRequest(request: IPulseCampaignFindRequest) {
    this.request = request
  }

  public getRequest(): IPulseCampaignFindRequest {
    return this.request
  }

  protected getListRecordsApiCall() {
    return async () => {
      const req: IPulseCampaignFindRequest = {
        ...this.request,
        listColumns: this.listColumns,
        sortColumnName: this.sortColumnName,
        sortDirection: this.sortDirection,
      }
      const svc = new PulseCampaignsService()
      const result = await svc.findPulseCampaigns(req)
      this.totalRecords = result.count
      return result.pulseCampaigns
    }
  }

  public get rows() {
    return this.records
  }
}
