import React from 'react'
import { ManageSurveysWidgetRowVM } from '../../view-models/manage-surveys-widget/ManageSurveysWidgetRowVM'
import { Tooltip } from '@material-ui/core'
import UpdateRoundedIcon from '@material-ui/icons/UpdateRounded'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import { IconButton } from '@material-ui/core'
import './ActionsCell.scss'

const ActionsCell = (props) => {
  const data: ManageSurveysWidgetRowVM = props.data
  if (!data) return <div></div>

  return (
    <div className='manage-surveys-widget-actions-cell'>
      <Tooltip
        title={`${data.lz.send_reminders_to_those} ${data.labels
          .getLabelByLanguageAndFor('pulse')
          .toLowerCase()}`}
        placement='bottom-start'
        enterDelay={500}
      >
        <IconButton style={{ padding: '3px' }} onClick={() => data.toggleSendRemindersDialog()}>
          <UpdateRoundedIcon id='survey-reminders' />
        </IconButton>
      </Tooltip>

      <Tooltip
        title={`${data.lz.close_the} ${data.labels
          .getLabelByLanguageAndFor('pulse')
          .toLowerCase()}`}
        placement='bottom-start'
        enterDelay={500}
      >
        <IconButton
          style={{ padding: '3px' }}
          color='secondary'
          onClick={() => data.toggleCloseSurveyDialog()}
        >
          <HighlightOffRoundedIcon />
        </IconButton>
      </Tooltip>
    </div>
  )
}

export default ActionsCell
