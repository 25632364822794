import { RootStore } from '../../stores/RootStore'
import { observable, computed, action } from 'mobx'
import { CalendarVM } from './CalendarVM'
import { Task } from '../aggregate/Task'
import { AudienceMember } from '../../audience-members/aggregate/AudienceMember'
import moment from 'moment'

export class TaskPopupVM {
  private rootStore: RootStore
  private calendarVM: CalendarVM

  constructor(rootStore: RootStore, calendarVM: CalendarVM, task: Task, userTask?: Task) {
    this.rootStore = rootStore
    this.task = task
    this.userTask = userTask
    this.calendarVM = calendarVM
    setTimeout(() => (this.anchorEl = document.getElementsByClassName('selectedTask')[0]), 20)
  }

  @observable private task: Task = null
  @observable private userTask: Task = null
  @observable public anchorEl: any = null

  @computed
  public get name(): string {
    return this.task.name
  }

  @computed
  public get dueDate(): any {
    const s = this.rootStore.localizationStore.lzStrings.taskPopup
    return moment.unix(this.task.dueDate).format(s.due_date_format)
  }

  @computed
  public get createdAt(): Date {
    return this.task.createdAt
  }

  @computed
  public get participants(): Array<AudienceMember> {
    return this.task.assignedToAudienceMembers.map((member) =>
      this.rootStore.audienceMembersStore.getAudienceMember(member.objectId)
    )
  }

  @computed
  public get allowDiscipline(): boolean {
    if (!this.rootStore.organizationsStore.currentOrganization) return false
    return this.rootStore.organizationsStore.currentOrganization.tasksAllowDiscipline
  }

  @computed
  public get assignedBy(): string {
    if (this.userTask.assignedByUserId === this.rootStore.appStore.currentUserId) return 'Me'
    const assignedBy = this.rootStore.audienceMembersStore.getUser(this.task.assignedByUserId)
    if (!assignedBy) return ''
    return assignedBy.name
  }

  @computed
  public get category(): string {
    const category = this.rootStore.categoriesStore.getCategory(this.task.categoryId)
    if (!category) return ''
    return category.name
  }

  @computed
  public get userOwned(): boolean {
    return this.task.assignedByUserId === this.rootStore.appStore.currentUserId
  }

  @action
  public navigateToUserTask() {
    this.close()
    setTimeout(() => {
      this.rootStore.userTasksStore.loadEditVM(this.userTask.objectId)
    }, 750)
  }

  @action
  public navigateToTask() {
    this.close()
    this.rootStore.tasksStore.openTask(this.task.objectId)

    setTimeout(() => {
      this.rootStore.tasksStore.openDrawer()
    }, 750)
  }

  @action
  public close() {
    this.anchorEl = null
  }
}
