import { RootStore } from '../../../stores/RootStore'
import { OrganizationUser } from '../../aggregate/OrganizationUser'
import { IOrganizationUserDTO } from '../../dtos/IOrganizationUserDTO'
import { computed, observable } from 'mobx'
import { IOrganizationUsersFindRequest } from '../../interfaces/IOrganizationUsersFindRequest'
import { DataStore } from '../../../shared/data/DataStore'
import { OrganizationUsersService } from '../../service/OrganizationUsersService'

export class OrganizationUsersListDataStore extends DataStore<
  OrganizationUser,
  IOrganizationUserDTO
> {
  private request: IOrganizationUsersFindRequest

  constructor(
    rootStore: RootStore,
    req: IOrganizationUsersFindRequest,
    showHidden?: boolean,
    sortField?,
    sortOrder?
  ) {
    super(rootStore, OrganizationUser, 'organizationUsers', [
      'objectId',
      'organizationId',
      'roles',
      'groups',
      'title',
      'primaryGroupId',
      'primaryRoleId',
      'fk_User',
      'isArchived',
      'archivedDate',
      'fk_roles',
      'fk_groups',
      'userId',
      'welcomeEmailStatus',
      'resetEmailStatus',
      'roleStartDate',
      'hasConsented',
      'jobNumber',
      'employeeId',
      'isHiddenInOrg',
      'fk_privilegeSets',
      'resolvedPrivileges',
      'isArchived',
      'createdAt',
      'updatedAt',
    ])
    this.request = req
    this.paged = true
    this.recordsPerPage = 100
    this.listenToListRecordsViaApiUpdates = true
    this.showHidden = showHidden
    this.sortColumnName = sortField
    this.sortDirection = sortOrder
    this.filterColumns = [
      'fk_User.lastName',
      'fk_User.firstName',
      'fk_roles.name',
      'fk_groups.name',
      'fk_User.email',
      'fk_User.name',
      'fk_User.username',
    ]
  }

  @observable public showArchived: boolean = false
  @observable public showHidden: boolean = false

  public setRequest(request: IOrganizationUsersFindRequest) {
    this.request = request
  }

  public getRequest(): IOrganizationUsersFindRequest {
    return this.request
  }

  private getskipMultiplier() {
    if (this.pageNumber === 0) return 1
    return this.pageNumber
  }

  protected getListRecordsApiCall() {
    return async () => {
      const req: IOrganizationUsersFindRequest = {
        ...this.request,
        listColumns: this.listColumns,
        sortColumnName: this.sortColumnName,
        sortDirection: this.sortDirection,
        skip: this.recordsPerPage * (this.getskipMultiplier() - 1),
        limit: this.recordsPerPage,
        terms: this.filter,
        showHidden: this.showHidden,
        showArchived: this.showArchived,
      }
      const svc = new OrganizationUsersService(this.rootStore)
      const result = await svc.findOrganizationUsers(req)
      this.totalRecords = result.count
      return result.organizationUsers
    }
  }

  @computed
  public get organizationUsers() {
    return this.records
  }
}
