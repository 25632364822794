import { CircularProgress } from '@material-ui/core'
import React, { FC, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { UserDashboardsStore } from '../../UserDashboardsStore'
import UserDashboardLayout from './UserDashboardLayout'
import ShareDashboardDrawer from '../../../views/Drawers/ShareDashboardDrawer'
import FavoriteSnackbar from '../../../views/Snackbars/FavoriteSnackbar'
import SharedDashboardsDrawer from '../Drawers/SharedDashboardsDrawer'
import SharedWithSnackbar from '../../../views/Snackbars/SharedWithSnackbar'
import UserDashboardHeader from '../Toolbars/UserDashboardHeader'
import WorksheetsToolbar from '../Toolbars/WorksheetsToolbar'
import TasksToolbar from '../Toolbars/TasksToolbar'
import SurveysToolbar from '../Toolbars/SurveysToolbar'
import ImpactToolbar from '../Toolbars/ImpactToolbar'
import TenantAdminToolbar from '../Toolbars/TenantAdminToolbar'

interface Props {
  userDashboardsStore?: UserDashboardsStore
}

const UserDashboard: FC<Props> = ({ userDashboardsStore }) => {
  const vmSvc = userDashboardsStore.viewModels
  const vm = vmSvc.userDashboardVM

  useEffect(() => {
    setTimeout(() => vmSvc.getDashboard(), 1000)
  }, [vmSvc.locationProp])

  const renderSpinner = () => {
    return (
      <CircularProgress color='secondary' style={{ position: 'fixed', top: '50%', left: '50%' }} />
    )
  }

  const renderToolbars = () => {
    if (!vmSvc.typeFromRoute) return null
    if (vmSvc.typeFromRoute === 'custom') return <UserDashboardHeader />
    if (vmSvc.typeFromRoute === 'analysis') return <WorksheetsToolbar />
    if (vmSvc.typeFromRoute === 'tasks') return <TasksToolbar />
    if (vmSvc.typeFromRoute === 'surveys') return <SurveysToolbar />
    if (vmSvc.typeFromRoute.includes('impact')) return <ImpactToolbar />
    if (vmSvc.typeFromRoute.includes('tenantAdmin')) return <TenantAdminToolbar />
  }

  const renderUserDashboard = () => {
    if (!vm.userDashboard) return renderSpinner()
    return (
      <>
        <FavoriteSnackbar />
        <SharedWithSnackbar />
        <ShareDashboardDrawer />
        <SharedDashboardsDrawer />
        <UserDashboardLayout />
      </>
    )
  }

  return (
    <>
      {renderToolbars()}
      {renderUserDashboard()}
    </>
  )
}

export default inject('userDashboardsStore')(observer(UserDashboard))
