import { action, observable } from 'mobx'
import { ITenantLinkDTO } from '../dtos/ITenantLinkDTO'
import { serializable, serialize, deserialize, object } from 'serializr'
import uuid from 'uuid/v4'
import { Attachment } from '../../upload/aggregate/Attachment'

export class TenantLink implements ITenantLinkDTO {
  static create() {
    const link = new TenantLink()
    return link
  }

  @serializable @observable public id: string = ''
  @serializable @observable public name: string = ''
  @serializable(object(Attachment)) @observable public iconName: Attachment = null
  @serializable @observable public url: string = ''
  @serializable @observable public isEmbedded: boolean = false

  public setName(name: string) {
    this.name = name
  }

  public setIconName(iconName: Attachment) {
    this.iconName = iconName
  }

  public clearIconName() {
    this.iconName = null
  }

  public setUrl(url: string) {
    this.url = url
  }

  public toggleIsEmbeded() {
    this.isEmbedded = !this.isEmbedded
  }

  public serialize(): ITenantLinkDTO {
    return serialize(this)
  }

  public clone(): ITenantLinkDTO {
    return deserialize(TenantLink, this.serialize())
  }
}
