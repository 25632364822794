import React from 'react'
import { inject, observer } from 'mobx-react'
import { WeightProfilesStore } from '../../../store/WeightProfilesStore'
import {
  Grid,
  Button,
  MenuItem,
  Collapse,
  ListItem,
  Checkbox,
  CircularProgress,
  Tooltip,
} from '@material-ui/core'
import './CategoriesScreenHeader.scss'
import MuiConfirmDeleteDialog from '../../../../shared/MuiConfirmDeleteDialog'
import DeleteIcon from '@material-ui/icons/Delete'
import MuiConfirmDialog from '../../../../shared/MuiConfirmDialog'
import Searchbar from '../../../../shared/Searchbar'

interface Props {
  weightProfilesStore?: WeightProfilesStore
}

const CategoriesScreenHeader: React.FC<Props> = ({ weightProfilesStore }) => {
  const { editVM } = weightProfilesStore
  if (!editVM) return null
  if (!editVM.selectedRole) return null
  const weightProfileVM = editVM.selectedRole.weightProfileVM

  const renderDeleteDialog = () => {
    if (!editVM.showDeleteDialog) return null
    return (
      <MuiConfirmDeleteDialog
        onClose={() => editVM.toggleDeleteDialog()}
        open={editVM.showDeleteDialog}
        itemName={editVM.selectedRoleName}
        itemType={'Weight Profile'}
        onConfirm={() => editVM.deleteWeightProfile()}
      />
    )
  }

  const renderRevertDialog = () => {
    if (!editVM.showRevertDialog) return null
    return (
      <MuiConfirmDialog
        open={editVM.showRevertDialog}
        onClose={() => editVM.toggleRevertDialog()}
        onConfirm={() => editVM.revertWeightProfile()}
        confirmMessage={'Are you sure you would like undo any changes made to this weight profile?'}
      />
    )
  }

  const renderCopyDialog = () => {
    if (!editVM.showCopyDialog) return null
    return (
      <MuiConfirmDialog
        open={editVM.showCopyDialog}
        onClose={() => editVM.toggleCopyDialog()}
        onConfirm={() => editVM.copyWeightProfileToRoles()}
        confirmMessage={
          'Are you sure you would like to copy this weight profile to the selected roles?'
        }
      />
    )
  }

  const renderButtons = () => {
    return (
      <>
        <Grid item>
          <Button disabled={!weightProfileVM.isDirty} onClick={() => editVM.toggleRevertDialog()}>
            Revert
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant='outlined'
            className='btn-outlined-white'
            onClick={() => editVM.toggleShowCopyOptions()}
            disabled={weightProfileVM.isNew || weightProfileVM.isDirty}
          >
            Copy Weights To...
          </Button>
          {renderCopyOptions()}
        </Grid>
        <Grid item>
          <Button
            disabled={
              weightProfileVM.isNew ? weightProfileVM.isProcessing : !weightProfileVM.isDirty
            }
            variant='contained'
            className='btn-outlined-white'
            onClick={() => editVM.saveWeightProfile()}
          >
            {weightProfileVM.isProcessing ? (
              <CircularProgress size={20} />
            ) : weightProfileVM.isNew ? (
              'Save'
            ) : (
              'Update'
            )}
          </Button>
        </Grid>
        <Grid item>
          {!weightProfileVM.isNew && (
            <React.Fragment>
              <Tooltip title='Delete Weight Profile' placement='bottom'>
                <Button
                  disabled={weightProfileVM.isProcessing}
                  variant='outlined'
                  className='btn-icon btn-icon-white btn-outlined-white'
                  onClick={() => editVM.toggleDeleteDialog()}
                  size='small'
                >
                  <DeleteIcon />
                </Button>
              </Tooltip>
            </React.Fragment>
          )}
        </Grid>
      </>
    )
  }

  const renderCopyOptions = () => {
    return (
      <Collapse
        className='options-collapse'
        in={editVM.showCopyOptions}
        timeout='auto'
        unmountOnExit
      >
        <ListItem className='search'>
          <Searchbar
            focused={editVM.optionsKeywordFilter.length > 0}
            searchValue={editVM.optionsKeywordFilter}
            onSearchChange={e => editVM.setOptionsKeywordFilter(e.target.value)}
          />
        </ListItem>
        {editVM.copyOptionsPagedData.displayRows.map((e, index) => (
          <ListItem key={index} value={e.objectId}>
            <Checkbox
              onChange={() => editVM.toggleRoleToCopySelect(e.objectId)}
              edge='start'
              checked={editVM.selectedCopyOptions.includes(e.objectId)}
              disableRipple
            />
            {e.name}
          </ListItem>
        ))}
        <Button onClick={() => editVM.toggleShowCopyOptions()}>Cancel</Button>
        <Button
          disabled={!editVM.selectedCopyOptions.length}
          variant='contained'
          className='btn-outlined-white'
          onClick={() => editVM.toggleCopyDialog()}
        >
          Copy
        </Button>
      </Collapse>
    )
  }

  return (
    <div id='CategoriesScreenHeader'>
      {renderDeleteDialog()}
      {renderRevertDialog()}
      {renderCopyDialog()}
      <Grid container className='header' spacing={2}>
        <div className='header-buttons'>{renderButtons()}</div>
      </Grid>
    </div>
  )
}

export default inject('weightProfilesStore')(observer(CategoriesScreenHeader))
