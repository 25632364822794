import React from 'react'
import Icon from '@material-ui/core/Icon'

export default function FontAwesome(props: any) {
  return (
    <div className={props.className}>
      <Icon className={props.icon} />
    </div>
  )
}
