import React from 'react'
import { Route, Redirect } from 'react-router'
import { authenticate } from '../../auth/routes/AuthenticatedRoute'
import Layout from '../../layout/Layout'
import UserConsentScreenWrapper from '../views/UserConsentScreenWrapper'

const getConsentRoutes = () => (
  <Route key='consent-root' component={authenticate(Layout)} path='/'>
    <Route key='consent-main' path='consent' component={UserConsentScreenWrapper} />
    <Redirect from='consent/*' to='consent' />
  </Route>
)

export default getConsentRoutes
