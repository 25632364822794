import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { EventsStore } from '../../store/EventsStore'
import MuiDrawerToolbar from '../../../shared/Mui-Drawer/MuiDrawerToolbar'

interface ICalendarEventDrawerButtonControls {
  localizationStore?: LocalizationStore
  eventsStore?: EventsStore
}

const CalendarEventDrawerButtonControls: React.FC<ICalendarEventDrawerButtonControls> = ({
  localizationStore,
  eventsStore,
}) => {
  const { lzStrings } = localizationStore
  const { editVM: vm } = eventsStore
  if (vm.isParticipantsTabOpen) return null

  return (
    <MuiDrawerToolbar
      disabled={vm.saveProcessing || vm.addNewResource || vm.addNewType}
      handleCancelClick={() => eventsStore.calendarVM.closeCalendarEventDrawer()}
      handleSubmitClick={() => vm.trySave()}
      onConfirmReset={() => vm.handleReset()}
      moreMenuProps={{
        hidden: vm.isNewEvent,
        isEditMode: true,
        itemType: lzStrings.eventEdit.event,
        itemName: vm.title,
        onConfirmDelete: () => vm.loadNotificationsVM(() => vm.handleDelete()),
        onConfirmCopy: () => vm.copyEvent()
      }}
    />
  )
}

export default inject(
  'localizationStore',
  'eventsStore'
)(observer(CalendarEventDrawerButtonControls))
