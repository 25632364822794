import { RootStore } from '../../stores/RootStore'
import { observable, computed, action } from 'mobx'
import { CalendarVM } from './CalendarVM'
import moment from 'moment'
import { UserTask } from '../../user-tasks/aggregates/UserTask'
import { Task } from '../../tasks/aggregates/Task'
import { IEventPillVM } from '../intefaces/IEventPillVM'

export class UserTaskPillVM implements IEventPillVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, userTask: UserTask, id?: string) {
    this.rootStore = rootStore
    this.userTask = userTask
    this.id = id
  }

  @observable public userTask: UserTask = null
  @observable public allDay: boolean = true
  @observable public id: string = ''

  @computed
  public get task(): Task {
    return this.rootStore.tasksStore.getTask(this.userTask.taskId)
  }

  @computed
  public get title(): string {
    if (!this.task) return ''
    return this.task.name
  }

  @computed
  public get localStartDate(): Date {
    if (!this.task) return null
    return moment.unix(this.task.dueDate).toDate()
  }

  @computed
  public get localEndDate(): Date {
    if (!this.task) return null
    return moment.unix(this.task.dueDate).toDate()
  }

  @computed
  public get objectId(): string {
    return this.userTask.objectId
  }

  @computed
  public get calendarIndex(): number {
    const idx = this.calendarVM.selectedOtherCalendars.findIndex((e) => e.id === this.id)
    if (idx === -1) return 0
    return idx + 1
  }

  @computed
  public get calendarVM(): CalendarVM {
    return this.rootStore.eventsStore.calendarVM
  }
}
