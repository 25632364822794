import React from 'react'
import clsx from 'clsx'
import { inject, observer } from 'mobx-react'
import { ButtonBase } from '@material-ui/core'
import { CategoriesStore } from '../../../../categories/CategoriesStore'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { LabelsStore } from '../../../../labels/store/LabelsStore'

interface WidgetTitleProps {
  localizationStore?: LocalizationStore
  categoriesStore?: CategoriesStore
  labelsStore?: LabelsStore
}

const WidgetTitle: React.FC<WidgetTitleProps> = ({
  localizationStore,
  categoriesStore,
  labelsStore,
}) => {
  const { lzStrings } = localizationStore
  const { listWidgetVM: vm } = categoriesStore

  if (!vm) return

  return (
    <div className='widget-title-tabs'>
      <span>
        {localizationStore.language === 'English'
          ? `${localizationStore.lzStrings.categoriesManagementWidget.manage}
          ${labelsStore.getLabelByLanguageAndFor('categories')}`
          : `${labelsStore.getLabelByLanguageAndFor('categories')}
          ${localizationStore.lzStrings.categoriesManagementWidget.manage}`}
      </span>
      <div className='tab-btns'>
        <ButtonBase
          onClick={() => vm.setTabIndex(0)}
          className={clsx('tab-button', {
            active: vm.tabIndex === 0,
          })}
        >
          List
        </ButtonBase>
        <ButtonBase
          onClick={() => vm.setTabIndex(1)}
          className={clsx('tab-button', {
            active: vm.tabIndex === 1,
          })}
        >
          Tree View
        </ButtonBase>
      </div>
    </div>
  )
}

export default inject('localizationStore', 'categoriesStore', 'labelsStore')(observer(WidgetTitle))
