import Parse from 'parse'
import { observable, action, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { TaskComment } from '../aggregates/TaskComment'
import { ITaskCommentDTO } from '../dtos/ITaskCommentDTO'
import { DataStore } from '../../shared/data/DataStore'

export class TaskCommentsStore extends DataStore<TaskComment, ITaskCommentDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, TaskComment, 'taskComments', [
      'organizationId',
      'userId',
      'taskId',
      'text',
      'attachment',
      'datePosted',
    ])
  }

  @observable public loaded: boolean = false

  @computed
  public get taskComments(): TaskComment[] {
    return this.records
  }

  @action
  public onListRecordsLoaded() {
    this.loaded = true
  }

  private getTaskComment(objectId: string): TaskComment {
    return this.taskComments.find(e => e.objectId === objectId)
  }

  private getTaskCommentIndex(objectId): number {
    return this.taskComments.findIndex(e => e.objectId === objectId)
  }

  // @action
  // public closeOtherCommentEdits(comments: TaskCommentEditVM[]) {
  //   comments.forEach((comment) => (comment.isInEditMode = false))
  // }

  @action
  private deleteTaskCommentFromStore(objectId: string): void {
    const index = this.getTaskCommentIndex(objectId)
    this.taskComments.splice(index, 1)
  }
}
