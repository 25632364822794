import { Grid, InputLabel, MenuItem, Select } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { GoalsCatalogStore } from '../../store/GoalsCatalogStore'
import GoalRow from './GoalRow'
import './GoalsCatalogWidget.scss'
import VirtualList from 'react-tiny-virtual-list'

interface Props {
  goalsCatalogStore?: GoalsCatalogStore
}

const Content: React.FC<Props> = ({ goalsCatalogStore }) => {
  const { goalsCatalogWidgetVM: vm } = goalsCatalogStore
  if (!vm) return null

  const renderFilterMenu = () => {
    return (
      <Grid container direction='row' justifyContent='flex-end' className='filter-row'>
        <Grid item className='filter'>
          <InputLabel>Sort By:</InputLabel>
          <Select value={vm.sortType} onChange={(e) => vm.setSortType(e.target.value)}>
            <MenuItem value={'Date'}>Date</MenuItem>
            <MenuItem value={'Name'}>Name</MenuItem>
          </Select>
        </Grid>
      </Grid>
    )
  }

  const renderVirtualList = () => {
    return (
      <VirtualList
        height='100%'
        itemCount={vm.rows.length}
        itemSize={90}
        renderItem={({ index, style }) => (
          <GoalRow goal={vm.rows[index]} key={index} style={style} />
        )}
      />
    )
  }

  return (
    <>
      {/* {renderFilterMenu()} */}
      <div id='GoalCatalogContent'>{renderVirtualList()}</div>
    </>
  )
}

export default inject('goalsCatalogStore')(observer(Content))
