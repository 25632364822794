import { observable, action, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Question } from '../aggregates/Question'
import { QuestionEditVM } from '../view-models/QuestionEditVM'
import { IQuestionDTO } from '../dtos/IQuestionDTO'
import { DataStore } from '../../shared/data/DataStore'
import { nameOf } from '../../shared/nameOf'
import { Media } from '../aggregates/Media'
import { Option } from '../aggregates/Option'

export class QuestionsStore extends DataStore<Question, IQuestionDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, Question, 'questions', []) // List columns not used
  }

  @observable public editVM: QuestionEditVM = null
  @observable public nameUnique: boolean = true
  @observable public isSnackbarOpen: boolean
  @observable public snackbarMessage: string = ''
  @observable public loaded: boolean = false
  @observable public updated: number = 0

  protected getFullColumns() {
    return [
      nameOf<Question, string>((e) => e.objectId),
      nameOf<Question, string>((e) => e.organizationId),
      nameOf<Question, string>((e) => e.title),
      nameOf<Question, string>((e) => e.type),
      nameOf<Question, Media>((e) => e.media),
      nameOf<Question, Option[]>((e) => e.options),
      nameOf<Question, string>((e) => e.categoryId),
      nameOf<Question, string>((e) => e.surveyTypeId),
      nameOf<Question, boolean>((e) => e.required),
      nameOf<Question, string>((e) => e.ratingDisplay),
      nameOf<Question, number>((e) => e.decimalsAllowed),
      nameOf<Question, number>((e) => e.minimumValueAllowed),
      nameOf<Question, number>((e) => e.maximumValueAllowed),
      nameOf<Question, number>((e) => e.numberStep),
      nameOf<Question, boolean>((e) => e.minimumRequired),
      nameOf<Question, boolean>((e) => e.numberStepRequired),
      nameOf<Question, boolean>((e) => e.defaultValueRequired),
      nameOf<Question, boolean>((e) => e.defaultValueRequired),
      nameOf<Question, boolean>((e) => e.isDropdownMultiSelect),
      nameOf<Question, string>((e) => e.placeholder),
    ]
  }

  public onListRecordsLoaded() {
    this.loaded = true
  }

  public async loadEditVM(objectId?: string, makeCopy?: boolean) {
    this.editVM = new QuestionEditVM(this.rootStore, objectId)
    if (!objectId) return this.editVM
    const question = await this.getFullRecord(objectId)
    if (!question) throw 'Question not found'
    if (!makeCopy) this.editVM.loadData(question.clone())
    if (makeCopy) this.editVM.loadData(question.makeCopy(), true)
    return this.editVM
  }

  public forceUpdate() {
    this.updated = new Date().getTime()
  }
}
