import { RootStore } from '../../stores/RootStore'
import Parse from 'parse'
import { observable, action, computed } from 'mobx'
import { persist } from 'mobx-persist'
import CalendarSetting from './aggregate/CalendarSetting'
import CalendarSettingsEditForm from './forms/CalendarSettingsEditForm'
import { CalendarManagementWidgetVM } from '../../events/view-models/CalendarManagementWidget/CalendarManagementWidgetVM'

export class CalendarSettingsStore {
  constructor(rootStore: RootStore | null = null) {
    if (rootStore) this.rootStore = rootStore
    this.editForm = new CalendarSettingsEditForm(this.rootStore)
    this.widgetVM = new CalendarManagementWidgetVM(this.rootStore)
  }

  private rootStore: RootStore

  private query: Parse.Query
  @persist('object', CalendarSetting)
  @observable
  calendarSetting: CalendarSetting = new CalendarSetting()
  @observable public editForm: CalendarSettingsEditForm = null
  @observable public widgetVM: CalendarManagementWidgetVM = null
  
  @action
  public async loadCalendarSettings() {
    this.query = new Parse.Query('calendarSettings')
    this.query.equalTo('organizationId', this.rootStore.appStore.currentOrgId)
    const response = await this.query.first()
    if (response) {
      this.calendarSetting = CalendarSetting.create(response.toJSON())
      this.editForm = new CalendarSettingsEditForm(this.rootStore, this.calendarSetting)
    }
  }

  @computed
  public get currentCalendarSetting() {
    return this.calendarSetting
  }

  public setRootStore(root) {
    this.rootStore = root
  }

  public clearData() {
    this.calendarSetting = null
  }
}
