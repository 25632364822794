import React from 'react'
import { inject, observer } from 'mobx-react'
import CloseIcon from '@material-ui/icons/Close'
import {
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  DialogActions,
  Button,
  CircularProgress,
} from '@material-ui/core'
import './CustomRecordModal.scss'
import ErrorRow from '../import/ErrorRow'
import { CustomRecordModalVM } from '../../view-models/import-records/CustomRecordModalVM'
import StringInput from 'src/app/shared/data/inputs/StringInput'
import { Field } from '../../aggregate/Field'
import NumberInput from 'src/app/shared/data/inputs/NumberInput'
import BooleanInput from 'src/app/shared/data/inputs/BooleanInput'
import UserInput from 'src/app/shared/data/inputs/UserInput'
import DateInput from 'src/app/shared/data/inputs/DateInput'
import UserSelectModal from './UserSelectModal'
import { UserSelectModalVM } from '../../view-models/import-records/UserSelectModalVM'

interface Props {
  vm: CustomRecordModalVM
}

const CustomRecordModal: React.FC<Props> = ({ vm }) => {
  const renderErrors = () => {
    if (!vm.hasError) return null

    return (
      <>
      {vm.errorRows.map((e, idx) => (
        <ErrorRow
          key={'er' + idx}
          row={e}
        />
      ))}
      </>
    )
  }

  const renderDialogButtons = () => {
    if (vm.importProcessing) {
      return (
        <Grid container className='dialog-actions' alignItems='center' justifyContent='center'>
          <Grid item>
            <CircularProgress color='primary' />
          </Grid>
        </Grid>
      )
    }
    return (
      <Grid container className='dialog-actions' alignItems='center' justifyContent='flex-end'>
        <Grid item>
          <Button
            variant='text'
            size='large'
            disabled={vm.importProcessing}
            onClick={() => vm.toggleShown()}
            className='cancelButton'
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            size='large'
            onClick={() => vm.save()}
            disabled={vm.importProcessing || vm.hasErrors}
            className='saveButton'
          >
            Save
          </Button>
        </Grid>
      </Grid>
    )
  }

  const renderInputField = (field: Field) => {
    const commonProps = {
      disabled: vm.importProcessing || (!vm.isNew && field.isCustomRecordKey),
      displayName: field.displayName,
      required: CustomRecordModalVM.isFieldRequired(field),
      id: field.fieldName,
      error: vm.fieldErrors[field.fieldName],
      value: vm.values[field.fieldName],
      onChange: (val) => vm.setValue(field.type, field.fieldName, val),
    }
    switch(field.type) {
        case 'String':
            return (
                <StringInput {...commonProps}/>
            )
        case 'User':
            return (
                <UserSelectModal 
                  {...commonProps} 
                  vm={new UserSelectModalVM(vm.rootStore)}
                />
            )
        case 'Boolean':
            return (
                <BooleanInput {...commonProps}/>
            )
        case 'Number':
            return (
                <NumberInput {...commonProps}/>
            )
        case 'Date':
            return (
                 <DateInput {...commonProps} /> 
            )
        default:
            throw new Error(`Type: ${field.type} is not known.`)
    }
  }

  const renderInputFields = () => {
      return (
          <section>
          <Grid container className='dialog-body dialog-content' alignItems='flex-start'>
            <>
            {vm.customCollection.fields.map((field, idx) => (
                <Grid item className='each-input' key={idx}>
                    {renderInputField(field)}
                </Grid>
            ))}
            </>
          </Grid>
          </section>
      )
  }

  return (
    <div id='CustomRecordModal'>
      <Dialog onClose={() => vm.toggleShown()} open={vm.shown} fullWidth>
        <Grid
          className='dialog-title'
          container
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item>
            <DialogTitle>{vm.getTitle}</DialogTitle>
          </Grid>
          <Grid item>
            <IconButton onClick={() => vm.toggleShown()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        {renderInputFields()}
        <Grid className={'errors'}>
          {renderErrors()}
        </Grid>
        <DialogActions>
          {renderDialogButtons()}
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default inject('usersStore', 'rootStore')(observer(CustomRecordModal))
