import { observable } from 'mobx'
import { serializable, serialize, deserialize } from 'serializr'
import { IUserWorksheetDTO } from '../dto/IUserWorksheetDTO'
import { IAggregate } from '../../shared/data/IAggregate'

export class UserWorksheet implements IUserWorksheetDTO, IAggregate {
  public static create(userId: string, orgId: string, worksheetId: string) {
    const sht = new UserWorksheet()
    sht.userId = userId
    sht.worksheetId = worksheetId
    sht.organizationId = orgId
    return sht
  }

  @serializable @observable public objectId: string = ''
  @serializable @observable public organizationId: string = ''
  @serializable @observable public isBookmark: boolean = false
  @serializable @observable public sortOrder: number = 0
  @serializable @observable public worksheetId: string = ''
  @serializable @observable public userId: string = ''

  public isDeleted: boolean = false
  public isOnServer: boolean = false

  public markIsNotOnServer() {
    this.isOnServer = false
  }

  public markIsOnServer() {
    this.isOnServer = true
  }

  public updateFromServer(fromObject: any) {
    if (!fromObject.User) fromObject.User = {}
    this.organizationId = fromObject.organizationId
    this.isBookmark = fromObject.isBookmark
    this.sortOrder = fromObject.sortOrder
    this.worksheetId = fromObject.worksheetId
    this.userId = fromObject.userId
    this.isOnServer = true
  }

  public serialize(): IUserWorksheetDTO {
    return serialize(this)
  }

  public clone(): UserWorksheet {
    return deserialize(UserWorksheet, this.serialize())
  }
}
