import React from 'react'
import { ListItem, Grid, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'
import './UserImportRow.scss'
import { UserImportRowVM } from '../../view-models/import/UserImportRowVM'
import CheckIcon from '@material-ui/icons/Check'
import WarningIcon from '@material-ui/icons/Warning'
import { UserCheck } from 'react-feather'
import { UserX } from 'react-feather'
import { V4MAPPED } from 'dns'
import { UserImportVM } from '../../view-models/import/UserImportVM'
import ArchivedLabel from '../../../shared/ArchivedLabel'

interface Props {
  row: UserImportRowVM
  style: any
  vm: UserImportVM
}

const UserImportRow: React.FC<Props> = ({ row, style, vm }) => {
  const renderName = () => {
    return (
      <Grid container item>
        <strong>Name</strong>: {row.userName}
      </Grid>
    )
  }

  const renderUserEmail = () => {
    if (!row.userEmail) return null
    return (
      <Grid container item>
        <strong>Email</strong>: {row.userEmail}
      </Grid>
    )
  }

  const renderUserUsername = () => {
    if (!row.userUsername) return null
    return (
      <Grid container item>
        <strong>Username</strong>: {row.userUsername}
      </Grid>
    )
  }

  const renderArchived = () => {
    if (!row.archived) return null
    if (vm.acceptedChangesComplete || vm.acceptingChanges) return <ArchivedLabel />
  }

  const renderUserFound = () => {
    if (row.userErrorMessage) return
    return (
      <Grid container item className='user-found'>
        <strong>
          User {vm.acceptedChangesComplete || vm.acceptingChanges ? 'Saved' : 'Found'}
        </strong>
        :
        <div className='user-icon'>
          {row.isNewUser ? <UserX color='red' /> : <UserCheck color='green' />}
        </div>
        {renderArchived()}
      </Grid>
    )
  }

  const renderUserErrorMessage = () => {
    if (!row.userErrorMessage) return
    return (
      <Grid container item className='row-error-text'>
        <strong>Error</strong>: {row.userErrorMessage}
      </Grid>
    )
  }

  const renderOrgUserErrorMessage = () => {
    if (!row.orgUserErrorMessage) return
    return (
      <Grid container item className='row-error-text'>
        <strong>Error</strong>: {row.orgUserErrorMessage}
      </Grid>
    )
  }

  const renderAddedRoles = () => {
    if (!row.addedRoles) return
    return (
      <Grid container item>
        <strong>Added Roles</strong>: {row.addedRoles}
      </Grid>
    )
  }

  const renderAddedGroups = () => {
    if (!row.addedGroups) return
    return (
      <Grid container item>
        <strong>Added Groups</strong>: {row.addedGroups}
      </Grid>
    )
  }

  const renderUpdatedSuperiors = () => {
    if (!row.updatedSuperiors) return
    return (
      <Grid container item>
        <strong>Updated Superiors</strong>: {row.updatedSuperiors}
      </Grid>
    )
  }

  const renderAddedSuperiors = () => {
    if (!row.addedSuperiors) return
    return (
      <Grid container item>
        <strong>Added Superiors</strong>: {row.addedSuperiors}
      </Grid>
    )
  }

  const renderAddedPrimaryRole = () => {
    if (!row.addedPrimaryRoleName) return
    return (
      <Grid container item>
        <strong>Added Primary Role</strong>: {row.addedPrimaryRoleName}
      </Grid>
    )
  }

  const renderAddedPrimaryGroup = () => {
    if (!row.addedPrimaryGroupName) return
    return (
      <Grid container item>
        <strong>Added Primary Group</strong>: {row.addedPrimaryGroupName}
      </Grid>
    )
  }

  const renderUpdatedPrimaryRole = () => {
    if (!row.updatedPrimaryRoleName) return
    return (
      <Grid container item>
        <strong>Updated Primary Role</strong>: {row.updatedPrimaryRoleName}
      </Grid>
    )
  }

  const renderUpdatedPrimaryGroup = () => {
    if (!row.updatedPrimaryGroupName) return
    return (
      <Grid container item>
        <strong>Updated Primary Group</strong>: {row.updatedPrimaryGroupName}
      </Grid>
    )
  }

  const renderUpdatedUserPhoneNumber = () => {
    if (!row.updatedPhoneNumber) return
    return (
      <Grid container item>
        <strong>Updated Phone</strong>: {row.updatedPhoneNumber}
      </Grid>
    )
  }

  const renderAddedUserPhoneNumber = () => {
    if (!row.addedPhoneNumber) return
    return (
      <Grid container item>
        <strong>Added Phone</strong>: {row.addedPhoneNumber}
      </Grid>
    )
  }

  const renderUpdatedEmployeeId = () => {
    if (!row.updatedEmployeeId) return
    return (
      <Grid container item>
        <strong>Updated Employee Id</strong>: {row.updatedEmployeeId}
      </Grid>
    )
  }

  const renderAddedEmployeeId = () => {
    if (!row.addedEmployeeId) return
    return (
      <Grid container item>
        <strong>Added Employee Id</strong>: {row.addedEmployeeId}
      </Grid>
    )
  }

  const renderUpdatedJobNumber = () => {
    if (!row.updatedJobNumber) return
    return (
      <Grid container item>
        <strong>Updated Job Number</strong>: {row.updatedJobNumber}
      </Grid>
    )
  }

  const renderAddedJobNumber = () => {
    if (!row.addedJobNumber) return
    return (
      <Grid container item>
        <strong>Added Job Number</strong>: {row.addedJobNumber}
      </Grid>
    )
  }

  const renderUpdatedBirthdate = () => {
    if (!row.updatedBirthdate) return
    return (
      <Grid container item>
        <strong>Updated Birthdate</strong>: {row.updatedBirthdate}
      </Grid>
    )
  }

  const renderAddedBirthdate = () => {
    if (!row.addedBirthdate) return
    return (
      <Grid container item>
        <strong>Added Birthdate</strong>: {row.addedBirthdate}
      </Grid>
    )
  }

  const renderUpdatedTitle = () => {
    if (!row.updatedTitle) return
    return (
      <Grid container item>
        <strong>Updated Title</strong>: {row.updatedTitle}
      </Grid>
    )
  }

  const renderAddedTitle = () => {
    if (!row.addedTitle) return
    return (
      <Grid container item>
        <strong>Added Title</strong>: {row.addedTitle}
      </Grid>
    )
  }

  const renderUpdatedReceiveTextMessages = () => {
    if (!row.updatedReceiveTextMessages) return
    return (
      <Grid container item>
        <strong>Receive Texts</strong>: {row.updatedReceiveTextMessages}
      </Grid>
    )
  }

  const renderAddedReceiveTextMessages = () => {
    if (!row.addedReceiveTextMessages) return
    return (
      <Grid container item>
        <strong>Receive Texts</strong>: {row.addedReceiveTextMessages}
      </Grid>
    )
  }

  const renderUpdatedReceivePushNotifications = () => {
    if (!row.updatedReceivePushNotifications) return
    return (
      <Grid container item>
        <strong>Receive Push Notifications</strong>: {row.updatedReceivePushNotifications}
      </Grid>
    )
  }

  const renderAddedReceivePushNotifications = () => {
    if (!row.addedReceivePushNotifications) return
    return (
      <Grid container item>
        <strong>Receive Push Notifications</strong>: {row.addedReceivePushNotifications}
      </Grid>
    )
  }

  const renderUpdatedReceiveEmails = () => {
    if (!row.updatedReceiveEmails) return
    return (
      <Grid container item>
        <strong>Receive Emails</strong>: {row.updatedReceiveEmails}
      </Grid>
    )
  }

  const renderAddedReceiveEmails = () => {
    if (!row.addedReceiveEmails) return
    return (
      <Grid container item>
        <strong>Receive Emails</strong>: {row.addedReceiveEmails}
      </Grid>
    )
  }

  const renderSuccessfulRow = () => {
    if (row.hasError) return
    return (
      <>
        {renderUserFound()}
        {renderUserEmail()}
        {renderUserUsername()}
        {renderName()}
        {renderAddedTitle()}
        {renderUpdatedTitle()}
        {renderAddedUserPhoneNumber()}
        {renderUpdatedUserPhoneNumber()}
        {renderAddedEmployeeId()}
        {renderUpdatedEmployeeId()}
        {renderAddedJobNumber()}
        {renderUpdatedJobNumber()}
        {renderAddedBirthdate()}
        {renderUpdatedBirthdate()}
        {renderAddedPrimaryGroup()}
        {renderUpdatedPrimaryGroup()}
        {renderAddedRoles()}
        {renderAddedPrimaryRole()}
        {renderUpdatedPrimaryRole()}
        {renderAddedGroups()}
        {renderAddedSuperiors()}
        {renderUpdatedSuperiors()}
        {renderAddedReceiveTextMessages()}
        {renderUpdatedReceiveTextMessages()}
        {renderAddedReceiveEmails()}
        {renderUpdatedReceiveEmails()}
        {renderAddedReceivePushNotifications()}
        {renderUpdatedReceivePushNotifications()}
      </>
    )
  }

  return (
    <Grid container style={style} id='UserImportRow'>
      <Grid container item>
        <strong>Row: {row.rowIndex}</strong>
      </Grid>
      {renderUserErrorMessage()}
      {renderOrgUserErrorMessage()}
      {renderSuccessfulRow()}
    </Grid>
  )
}

export default observer(UserImportRow)
