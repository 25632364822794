import React from 'react'
import { UserTrainingPlansStore } from '../../training-plans/user-training-plans/store/UserTrainingPlansStore'
import { inject, observer } from 'mobx-react'
import { Grid, Hidden, Typography } from '@material-ui/core'
import { QuizResultOptionVM } from '../view-models/QuizResultOptionVM'
import CheckCircleRounded from '@material-ui/icons/CheckCircleRounded'
import CancelRounded from '@material-ui/icons/CancelRounded'
import './QuizResultOption.scss'

interface Props {
  vm: QuizResultOptionVM
  userTrainingPlansStore?: UserTrainingPlansStore
}

const QuizResultOption: React.FC<Props> = ({ vm, userTrainingPlansStore }) => {
  if (!vm) return null

  const renderOptionIcon = () => {
    let icon = <CheckCircleRounded style={{ visibility: 'hidden'}} />
    if (vm.isChecked) {
      if (vm.isCorrect) icon = <CheckCircleRounded className={'correct-icon'} />
      if (!vm.isCorrect) icon = <CancelRounded className={'incorrect-icon'} />
    }
    if (!vm.isChecked && vm.isCorrectAnswer) icon = <CheckCircleRounded className={'correct-icon'} />
    return (
      <Grid item xs={1}>
        {icon}
      </Grid>
    )
  }

  const renderOptionText = () => {
    let style = 'quiz-option-text'
    if (vm.isChecked) style = 'quiz-option-text is-checked'
    return (
      <Grid item xs={10} className={style}>
        <Typography className={vm.isChecked ? 'text-is-checked option-text' : 'option-text'}>{vm.text}</Typography>
      </Grid>
    )
  }

  const renderResponsePercent = () => {
    let percent = <Typography className={'quiz-option-percent-text'}>{vm.percent}</Typography>
    if (vm.questionType === 'text') percent = null
    return (
      <Grid item xs={1} className={'quiz-option-percent'}>
        {percent}
      </Grid>
    )
  }

  const renderOption = () => {
    let style = 'quiz-option'
    if (vm.questionType === 'text') style = ''
    return (
      <Grid container item xs={12} className={style}>
        {renderOptionIcon()}
        {renderOptionText()}
        {renderResponsePercent()}
      </Grid>
    )
  }



  return (
    <div id='QuizResultOption'>
      {renderOption()}
    </div>
  )
}

export default inject('userTrainingPlansStore')(observer(QuizResultOption))
