import { action, observable, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Role } from '../aggregate/Role'
import PrivilegeSetRowVM from './PrivilegeSetRowVM'
import _ from 'lodash'

export default class RoleBasicInfoVM {
  rootStore: RootStore

  constructor(rootStore: RootStore, role?: Role) {
    this.rootStore = rootStore
    if (role) this.loadData(role)
    if (!role) this.role = Role.create(this.rootStore.appStore.currentOrgId)
    setTimeout(() => this.loadPrivilegeSets(), 100)
  }

  private loadData(role: Role) {
    this.role = role
    this.name = role.name
    this.objectId = role.objectId
    if (role.privilegeSets) this.selectedPrivilegeSets = role.privilegeSets
    this.description = role.description
    this.defaultDashboardId = role.defaultDashboardId
    if (role.forceDashboardRoute) this.forceDashboardRoute = role.forceDashboardRoute
  }

  private loadPrivilegeSets() {
    this.rootStore.privilegeSetsStore.currentOrgPrivilegeSets.forEach((privilege) => {
      const newRow = new PrivilegeSetRowVM(this.rootStore, this, privilege)

      this.privilegeSetRows.push(newRow)
    })
  }

  private role: Role
  @observable public privilegeSetRows: Array<PrivilegeSetRowVM> = []
  @observable public name: string = ''
  @observable public objectId: string = ''
  @observable public description: string = ''
  @observable public selectedPrivilegeSets: Array<any> = []
  @observable public defaultDashboardId: string = ''
  @observable public forceDashboardRoute: boolean = false

  @computed
  public get nameHasPeriod(): boolean {
    return this.name.includes('.')
  }

  @action
  public setName(value) {
    this.name = value
  }

  @action
  public setDescription(val) {
    this.description = val
  }

  @computed
  public get disabledCauseAdmin(): boolean {
    return Boolean(this.role.name.trim().toLowerCase() === 'administrator')
  }

  @computed
  public get availableDashboards() {
    return this.rootStore.dashboardsStore.currentOrgDashboards.filter((e) =>
      e.sharedWith.find((e) => e.id === this.objectId)
    )
  }

  @computed
  public get defaultDashboard() {
    let foundDash = this.rootStore.dashboardsStore.getDashboard(this.defaultDashboardId)
    if (!foundDash) return { name: '' }
    return foundDash
  }

  @action
  public toggleForceDashboardRoute() {
    this.forceDashboardRoute = !this.forceDashboardRoute
  }

  @action
  public setDefaultDashboard(val) {
    this.defaultDashboardId = val ? val.objectId : null
  }

  @computed
  public get isAdministratorRole() {
    return (
      this.name.toLowerCase() === 'administrator' ||
      this.name.toLowerCase() === 'role: administrator'
    )
  }

  @computed
  public get privilegeSetIds() {
    const privilegeSetIds = []

    this.privilegeSetRows.forEach((set) => {
      if (set.isSelected) privilegeSetIds.push(set.objectId)
    })

    return privilegeSetIds
  }

  @computed
  public get isValid() {
    if (!this.nameValid) return false
    if (!this.nameUnique) return false
    return true
  }

  @computed
  public get nameValid() {
    return this.name && this.name.trim() !== ''
  }

  @computed
  public get nameUnique() {
    const foundRole = this.rootStore.rolesStore.getRoleByName(this.name)
    if (foundRole && foundRole.objectId !== this.rootStore.rolesStore.selectedRoleId) return false
    return true
  }
}
