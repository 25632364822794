import { action, observable, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Attachment } from '../../tasks/aggregates/Attachment'
import { ContentItemType } from '../types/ContentItemType'
import { FileAttachmentVM } from './FileAttachmentVM'
import { ICMSItemDTO } from '../../cms-items/interfaces/ICMSItemDTO'
import { CMSItemsFindService } from '../../cms-items/service/CMSItemsFindService'
import { isNumeric } from '../../shared/isNumeric'
import { FileOpenService } from '../../upload/services/FileOpenService'

export class CMSFileAttachmentVM extends FileAttachmentVM {
  private svc: CMSItemsFindService
  @observable private cmsItem: ICMSItemDTO

  constructor(rootStore: RootStore, contentItemType: ContentItemType, attachment: Attachment) {
    super(rootStore, contentItemType, attachment)

    this.svc = new CMSItemsFindService(rootStore)
    const cmsItemId = isNumeric(attachment.objectId)
      ? Number(attachment.objectId)
      : attachment.cmsItemId

    const found = rootStore.cmsItemAttachmentStore.getCMSItem(cmsItemId)
    if (found) this.cmsItem = found
  }

  @observable public isLoading: boolean = false

  @action
  public async loadCMSItem() {
    if (this.isLoaded) return

    this.isLoading = true

    if (isNumeric(this.attachment.objectId))
      this.setCMSItem(await this.svc.getItem(Number(this.attachment.objectId)))
    else this.setCMSItem(await this.svc.getItem(this.attachment.cmsItemId))

    this.isLoading = false

    return this.cmsItem
  }

  @action
  public setCMSItem(cmsItem: ICMSItemDTO) {
    this.cmsItem = cmsItem
  }

  @computed
  public get cmsItemId(): number {
    return this.attachment.cmsItemId
  }

  @computed
  public get objectId(): string {
    return this.attachment.objectId
  }

  @computed
  public get isLoaded() {
    if (!this.cmsItem) return false
    return true
  }

  @computed
  public get type(): ContentItemType {
    return this.contentItemType
  }

  @computed
  public get fileType(): string {
    return 'file'
  }

  @computed
  public get isCMSItem(): boolean {
    if (isNumeric(this.attachment.objectId)) return true
    return Boolean(this.attachment.cmsItemId)
  }

  @computed
  public get thumbnailUrl(): string {
    if (!this.cmsItem) return undefined
    if (this.isMuxVideo) return this.cmsItem.Optimized_Video.playback_id
    if (this.cmsItem.External_Video) return this.cmsItem.External_Video.thumbnailUrl

    let url = ''
    const file = this.cmsItem.File
    if (file && file.formats) {
      url = file.formats.small?.url
      if (!url) url = file.formats.thumbnail?.url
      if (!url) url = file.url
    } else url = file.url

    if (!url) return ''
    return url
  }

  @computed
  public get url(): string {
    if (!this.cmsItem) return undefined
    if (this.cmsItem.External_Video) return this.cmsItem.External_Video.url

    let url = ''
    const file = this.cmsItem.File
    if (file && file.formats) {
      url = file.formats.large?.url
      if (!url) url = file.formats.medium?.url
      if (!url) url = file.formats.small?.url
      if (!url) url = file.formats.thumbnail?.url
      if (!url) url = file.url
    } else url = file.url

    if (!url) return ''
    return url
  }

  @computed
  public get isVideo() {
    return this.fileType === 'video'
  }

  @computed
  public get isImage() {
    return this.fileType === 'image'
  }

  @computed
  public get isDocument() {
    return !this.isImage && !this.isVideo
  }

  @computed
  public get fileFormat(): string {
    if (!this.cmsItem) return null
    return this.cmsItem.Type
  }

  @computed
  public get fileUrl(): string {
    if (!this.cmsItem || !this.cmsItem.File) return undefined
    return this.cmsItem.File.url
  }

  @computed
  public get fileExtension(): string {
    if (!this.cmsItem) return ''
    if (!this.cmsItem.File) return '' 
    const index = this.cmsItem.File.url.lastIndexOf('.')
    if (index < 0) return ''
    return this.fileUrl.substring(index, this.fileUrl.length)
  }

  @computed
  public get fileName(): string {
    if (!this.cmsItem) return undefined
    if (this.cmsItem.Title.length > 50) {
      return this.cmsItem.Title.substring(0, 50) + '..' + this.fileExtension
    }
    return this.cmsItem.Title
  }

  @computed
  public get shorterFileName(): string {
    if (!this.cmsItem) return undefined
    if (this.cmsItem.Title.length > 30) {
      return this.cmsItem.Title.substring(0, 30) + '..' + this.fileExtension
    }
    return this.cmsItem.Title
  }

  @computed
  public get hasYouTubeURL(): boolean {
    if (!this.cmsItem) return false
    const url = this.url.toLowerCase()
    return url.includes('youtube') || url.includes('youtu.be')
  }

  @computed
  public get hasVimeoURL(): boolean {
    if (!this.cmsItem) return false
    const url = this.url.toLowerCase()
    return url.includes('vimeo')
  }

  @action
  public async openAttachment() {
    if (!this.cmsItem) return null
    const svc = new FileOpenService(this.rootStore)
    await svc.openFile(this.toAttachment())
  }

  @action
  public toAttachment() {
    const attachment = new Attachment()
    if (this.cmsItem) {
      attachment.url = this.url
    }
    return attachment
  }

  @computed
  public get isMuxVideo(): boolean {
    if (!this.cmsItem) return false
    return Boolean(this.cmsItem?.Optimized_Video?.id)
  }
}
