import { action, observable, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Role } from '../aggregate/Role'
import _ from 'lodash'
import RoleBasicInfoVM from './RoleBasicInfoVM'
import RoleMembersVM from './RoleMembersVM'
import { IParticipant } from '../../participants-select/interfaces/IParticipant'
import { ParticipantsSelectVM } from '../../participants-select/view-models/ParticipantsSelectVM'
import { RolesUpdateService } from '../service/RolesUpdateService'
import { RolesDuplicateService } from '../service/RolesDuplicateService'
import { IRolesDuplicateRequest } from '../interfaces/IRolesDuplicateRequest'

export default class RoleEditVM {
  private rootStore: RootStore
  private svc: RolesUpdateService
  private existingUsers: string[] = []
  private role: Role

  constructor(rootStore: RootStore, role?: Role) {
    this.rootStore = rootStore
    if (role) this.loadData(role)
    if (!role) this.role = Role.create(this.rootStore.appStore.currentOrgId)
    this.basicInfo = new RoleBasicInfoVM(this.rootStore, role)
    this.svc = new RolesUpdateService(this.rootStore)
    this.participantsSelectVM = new ParticipantsSelectVM(
      this.rootStore,
      false,
      false,
      false,
      false,
      false,
      true,
      false
    )
  }

  private loadData(role: Role) {
    this.role = role
    this.name = role.name
    this.objectId = role.objectId
    this.description = role.description
  }

  public participantsSelectVM: ParticipantsSelectVM
  @observable public basicInfo: RoleBasicInfoVM = null
  @observable public roleMembers: RoleMembersVM = null
  @observable public managerCategories: Array<any> = []
  @observable public name: string = ''
  @observable public objectId: string = ''
  @observable public description: string = ''
  @observable public saveTried: boolean = false
  @observable public showDrawer: boolean = false
  @observable public selectedPrivilegeSets: Array<any> = []
  @observable public tab: number = 0
  @observable public isProcessing: boolean = false
  @observable public isMembersLoaded: boolean = false
  @observable public isLoading: boolean = false
  @observable public tabValue: number = 0
  @observable public isParticipantsTabOpen: boolean = false
  @observable public assignedToAudienceMembers: IParticipant[] = []
  @observable public isDuplicate: boolean = false

  private async loadExistingUsers() {
    if (this.isNew) return
    this.isLoading = true
    this.isMembersLoaded = true
    this.participantsSelectVM.addParticipant(this.role.objectId, this.role.name, 'role')
    await this.participantsSelectVM.explodeParticipant(this.role.objectId)
    this.participantsSelectVM.setVisible()
    this.existingUsers = this.participantsSelectVM.participants.map((e) => e.id)
    this.isLoading = false
  }

  @computed
  public get roleNameErrorMessage(): string {
    if (this.basicInfo.nameHasPeriod) return 'Role name cannot include periods.'
    if (
      this.saveTried &&
      !this.objectId &&
      (this.rootStore.rolesStore.editVM.basicInfo.name.toLowerCase().trim() === 'administrator' ||
        this.rootStore.rolesStore.editVM.basicInfo.name.toLowerCase().trim() ===
          'role: administrator' ||
        this.rootStore.rolesStore.editVM.basicInfo.name.toLowerCase().trim() === 'admin')
    ) {
      return this.rootStore.localizationStore.lzStrings.roleEdit.error_cant_make_administrator_role
    }
    if (this.saveTried && this.isDuplicate) return 'A Role with this name already exists.'
    return ''
  }

  @computed
  public get saveDisabled(): boolean {
    if (this.isProcessing) return true
    if (this.basicInfo.nameHasPeriod) return true
    return false
  }

  @action
  public setTabValue(value) {
    if (!this.isMembersLoaded && value === 1) this.loadExistingUsers()
    this.tabValue = value
  }

  @action
  public toggleParticipantSelectOpen() {
    this.isParticipantsTabOpen = !this.isParticipantsTabOpen
  }

  @action
  public markSaveTried() {
    this.saveTried = true
  }

  @action
  public toggleRolesDrawer() {
    this.showDrawer = !this.showDrawer
  }

  @computed public get isNameValid() {
    if (this.basicInfo.nameHasPeriod) return false
    if (this.roleNameErrorMessage) return false
    if (!this.saveTried) return true
    return this.basicInfo.name && this.basicInfo.name.trim() !== ''
  }

  @computed
  public get isValid() {
    if (!this.isNameValid) return false
    return true
  }

  @computed
  public get isEditMode() {
    return Boolean(this.objectId)
  }

  @computed
  public get moreMenuProps() {
    const strings = this.rootStore.localizationStore.lzStrings
    const props = {
      isEditMode: this.isEditMode,
      itemType: strings.moreMenu.obj_type.role,
      itemName: this.name,
      onConfirmDelete: () => this.delete(),
    }
    if (this.basicInfo.disabledCauseAdmin) delete props.onConfirmDelete
    return props
  }

  @computed
  public get currentRoleMembers(): any[] {
    if (!this.objectId) return []
    let members = []
    this.rootStore.usersStore.currentOrganizationUsers.forEach((user) => {
      if (user.roles.includes(this.objectId)) {
        members.push({ id: user.userId, type: 'user', iconURL: user.iconURL, name: user.name })
      }
    })
    return members
  }

  @computed
  public get isNew(): boolean {
    return !Boolean(this.role.objectId)
  }

  public getMembersToRemove(): string[] {
    const finalUsers = this.participantsSelectVM.participants.map((e) => e.id)
    return this.existingUsers.filter((e) => !finalUsers.includes(e))
  }

  private getDuplicateRoleRequest() {
    const req: IRolesDuplicateRequest = {
      orgId: this.rootStore.appStore.currentOrgId,
      objectId: this.objectId ? this.objectId : '',
      roleName: this.basicInfo.name.trim(),
    }
    return req
  }

  private async checkForDuplicateRole() {
    const dupeSvc = new RolesDuplicateService()
    const dupeCheck = await dupeSvc.checkForDuplicateRole(this.getDuplicateRoleRequest())
    if (!dupeCheck.success) this.isDuplicate = true
  }

  @action
  public async save() {
    this.saveTried = true
    this.isDuplicate = false
    await this.checkForDuplicateRole()
    if (!this.isValid) return
    try {
      this.isProcessing = true
      const newRole = {
        objectId: this.objectId ? this.objectId : '',
        name: this.basicInfo.name.trim(),
        description: this.basicInfo.description,
        privilegeSets: this.basicInfo.privilegeSetIds,
        defaultDashboardId: this.basicInfo.defaultDashboardId,
        forceDashboardRoute: this.basicInfo.forceDashboardRoute,
        isDeleted: false,
        organizationId: this.rootStore.appStore.currentOrgId
      }
      const savedRole = await this.svc.saveRole(this.rootStore.appStore.currentOrgId, newRole)
      const userIdsToRemove = this.getMembersToRemove()
      const userIdsToAdd = this.participantsSelectVM.participants
        .filter((e) => !this.existingUsers.includes(e.id))
        .map((e) => e.id)
      await this.svc.updateRoleMembers(
        this.rootStore.appStore.currentOrgId,
        savedRole.objectId,
        userIdsToAdd,
        userIdsToRemove
      )
      this.isProcessing = false
      this.toggleRolesDrawer()
    } catch (e) {
      console.error(e)
    }
  }

  public async refreshEvents() {
    await this.svc.refreshEvents(this.objectId)
  }

  @action
  public async delete() {
    try {
      this.toggleRolesDrawer()
      await this.svc.deleteRole(this.objectId)
    } catch (e) {
      console.error(e)
    }
  }
}
