import {  computed } from 'mobx'
import { AGGridRowVM } from '../../../shared/ag-grid/AGGridRowVM'
import { RootStore } from '../../../stores/RootStore'
import TaskTemplate from '../aggregate/TaskTemplate'
import { TaskTypesWidgetVM } from './TaskTypesWidgetVM'

export class TaskTypesWidgetRowVM extends AGGridRowVM<TaskTemplate> {

  constructor(rootStore: RootStore, taskType: TaskTemplate, listVM: TaskTypesWidgetVM) {
    super(rootStore, taskType, listVM)
  }

  @computed
  public get name(): string {
    return this.aggregate.name
  }

  @computed
  public get objectId(): string {
    return this.aggregate.objectId
  }


  openTaskType() {
    this.rootStore.taskTemplatesStore.setSelectedTaskTemplate(this.objectId)
    this.rootStore.taskTemplatesStore.showDrawer = true
  }
}
