import React from 'react'
import { inject, observer } from 'mobx-react'
import rootStore from '../../../stores/RootStore'
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Input,
  DialogActions,
  CircularProgress,
  OutlinedInput,
  InputLabel,
  Typography,
  Select,
  MenuItem,
} from '@material-ui/core'
import { Editor } from '@tinymce/tinymce-react'
import { PulseCampaignsStore } from '../../store/PulseCampaignsStore'
import DialogHeader from './DialogHeader'
import AddPhotoIcon from '@material-ui/icons/AddPhotoAlternateOutlined'
import DescriptionIcon from '@material-ui/icons/DescriptionRounded';
import FileUploadModal from '../../../upload/views/FileUploadModal'
import { FileTypeEnum } from '../../../upload/enum/FileTypeEnum'
import { Attachment } from '../../../upload/aggregate/Attachment'
import EmailTemplatePreview from '../../../email-templates/views/previewer/EmailTemplatePreview'
import EmailTemplatesPreviewToggle from 'src/app/email-templates/views/previewer/EmailTemplatesPreviewToggle'
import UserSurveyTakeWidget from '../../../user-surveys/views/take/UserSurveyTakeWidget'
import { EmailTemplate } from '../../../email-templates/aggregate/EmailTemplate'
import CMSItemsPickerDialog from '../../../cms-items/views/dialogs/CMSItemsPickerDialog'
import { CMSItemsTypeEnum } from '../../../cms-items/enum/CMSItemsTypeEnum'
import { OrganizationsStore } from '../../../organizations/store/OrganizationsStore'
import './IntroductionScreenDesignerDialog.scss'

interface Props {
  pulseCampaignsStore?: PulseCampaignsStore
  organizationsStore?: OrganizationsStore
}

const useStyles = makeStyles((theme: Theme) => ({
  backDrop: {
    backdropFilter: 'blur(1px)',
    backgroundColor: 'rgba(0,0,30,0.9)',
  },
  input: {
    textAlign: 'center',
    '&::placeholder': {
      textAlign: 'center',
    },
  },
}))

const IntroductionScreenDesigner: React.FC<Props> = ({ pulseCampaignsStore, organizationsStore }) => {
  const classes = useStyles()

  const vm = pulseCampaignsStore.viewModels.editVM
  if (!vm) return null
  if (!vm.currentSurvey) return null

  const { pulseCampaign: strings } = rootStore.localizationStore.lzStrings

  const handleClose = () => {
    vm.applyTried = false
    vm.closeIntroScreenDialog()
    if (vm.currentSurvey.originalSurveyIntroduction !== vm.currentSurvey.surveyIntroduction)
      setSurveyIntroduction(vm.currentSurvey.originalSurveyIntroduction)
    
    vm.previewTakeVM = null
    vm.previewVM.setPreviewTabIndex(0)
    vm.resetSelectedOptionValue()
    vm.resetTemplates()
  }

  const handleReset = () => {
    vm.applyTried = false
    vm.resetTemplates()
    vm.previewVM.resetTemplateEditor()
    setSurveyIntroduction(vm.currentSurvey.originalSurveyIntroduction)
  }

  const handleApply = () => {
    vm.applyTried = true
    if (isInvalid()) return
    if (vm.currentSurvey.originalSurveyIntroduction !== vm.currentSurvey.surveyIntroduction)
      setOriginalSurveyIntroduction(vm.currentSurvey.surveyIntroduction)
    const publishedTemplates = vm.templates.map(t => EmailTemplate.create(t))
    const reminderTemplates = vm.reminderTemplates.map(t => EmailTemplate.create(t))

    handleClose()
    vm.resetTemplates()
    setPublishedTemplates(publishedTemplates)
    setReminderTemplates(reminderTemplates)
  }

  const setPublishedTemplates = (publishedTemplates: EmailTemplate[]) => {
    if (!publishedTemplates) return
    vm.currentSurvey.pulseCampaignSurvey.publishedTemplates = publishedTemplates.map(t => EmailTemplate.create(t)).slice()
  }

  const setReminderTemplates = (reminderTemplates: EmailTemplate[]) => {
    if (!reminderTemplates) return
    vm.currentSurvey.pulseCampaignSurvey.reminderTemplates = reminderTemplates.map(t => EmailTemplate.create(t)).slice()
  }

  const setOriginalSurveyIntroduction = (val: string) => {
    let emailTemplate = vm.previewVM.emailTemplate
    const intro = vm.replaceMarkupAndKeywordsForPreview(emailTemplate, val)

    vm.currentSurvey.setOriginalSurveyIntroduction(val)

    vm.setBody(intro)

    const htmlPreview = vm.replaceKeywordsForPreview(vm.body, vm.previewVM.emailTemplate)
    vm.previewVM.setPreviewHtml(htmlPreview)
  }

  const setSurveyIntroduction = (val: string) => {
    let emailTemplate = vm.previewVM.emailTemplate
    const intro = vm.replaceMarkupAndKeywordsForPreview(emailTemplate, val)

    vm.currentSurvey.setSurveyIntroduction(val)

    if (vm.previewTakeVM) vm.previewTakeVM.setSurveyIntroduction(val)

    vm.setBody(intro)

    const htmlPreview = vm.replaceKeywordsForPreview(vm.body, vm.previewVM.emailTemplate)
    vm.previewVM.setPreviewHtml(htmlPreview)
  }

  const renderDialogTitle = () => {
    return (
      <div className='intro-text intro-header'>
        <DialogHeader
          pulseCampaignsStore={pulseCampaignsStore}
          title={strings.intro_screen_designer}
          onClick={handleClose}
        />
      </div>
    )
  }

  const isInvalid = () => {
    if (!vm.currentSurvey.surveyIntroductionValid && vm.applyTried) return true
    return false
  }

  const renderBodyText = () => {
    if (!vm.notificationTemplate) return null
    return (
      <Grid item xs={12} className='editor-section'>
        <div className={isInvalid() ? 'body-invalid' : null}>
          <Editor
            value={vm.currentSurvey.surveyIntroduction}
            init={vm.tinyMCEInit}
            onEditorChange={setSurveyIntroduction}
          />
        </div>
      </Grid>
    )
  }

  const renderThumbnail = () => {
    if (!vm.currentSurvey.surveyIntroAttachment) return
    const attachment = vm.currentSurvey.surveyIntroAttachment
    if (attachment.type === 'image') return <img src={attachment.url} className='intro-image' />
    if (attachment.type === 'video') return <img src={attachment.thumbnail} className='intro-image' />
    if (attachment.type !== 'image' || 'video') return <DescriptionIcon className='intro-image' />
  }

  const renderAttachImage = () => {
    if (vm.currentSurvey && vm.currentSurvey.surveyIntroAttachment) {
      return (
        <Grid onClick={() => vm.toggleShowUploadModal()} className='intro-attached-image'>
          {renderThumbnail()}
        </Grid>
      )
    } else {
      return (
        <IconButton onClick={() => vm.toggleShowUploadModal()} className='intro-attach-image'>
          <Grid item xs={12}>
            <AddPhotoIcon className='add-photo-icon' />
          </Grid>
        </IconButton>
      )
    }
  }

  const renderTab = () => {
    if (!vm.previewTakeVM) return renderSpinner()
    if (vm.previewVM.previewTabIndex === 0) return <EmailTemplatePreview vm={vm.previewVM} />
    if (vm.previewVM.previewTabIndex === 1) return <UserSurveyTakeWidget takeVM={vm.previewTakeVM} />
    if (vm.previewVM.previewTabIndex === 2) return <UserSurveyTakeWidget takeVM={vm.previewTakeVM} />
    return
  }

  const renderEmailTemplatePreview = () => {
    if (!vm.previewVM) return renderSpinner()
    if (vm.previewVM.isLoading) return renderSpinner()

    const previewClass = vm.previewVM.previewTabIndex < 2 ? 'preview-desktop': 'preview-mobile'
    return (
      <div className='intro-preview-root'>
        <div id='IntroductionTakePreview' className={previewClass}>
          {renderTab()}
        </div>
      </div>
    )
  }

  const renderEmailTemplatePreviewTabs = () => {
    if (!vm.previewVM) return
    return <EmailTemplatesPreviewToggle vm={vm.previewVM} />
  }

  const handleSubjectChange = (event) => {
    vm.notificationTemplate.handleSubjectChange(event.target.value)
  }

  const renderTemplateSelect = () => {
    if (!vm.selectOptions) return null
    return (
      <div className='intro-section'>
        <div className='primary-text'>{strings.language}</div>
        <Select
          disabled={vm.isReadOnly}
          id='notification-template-editor-select'
          className={'template-select'}
          value={vm.selectedOptionValue}
          onFocus={(e) => (vm.isDirty = true)}
          onChange={(e) => vm.setSelectedOptionValue(e.target.value)}
          variant='outlined'
        >
          {vm.selectOptions.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </div>
    )
  }

  const renderSubjectInput = () => {
    if (!vm.notificationTemplate) return null
    return (
      <div className='intro-section'>
        <InputLabel required htmlFor='intro-subject'>
          {strings.email_subject}
        </InputLabel>
        <OutlinedInput
          onFocus={(e) => (vm.isDirty = true)}
          disabled={vm.isReadOnly}
          id={`${vm.objectId}-subject`}
          fullWidth
          labelWidth={0}
          autoComplete='off'
          value={vm.notificationTemplate.subject}
          onChange={handleSubjectChange}
        />
      </div>
    )
  }

  const renderBodyInput = () => {
    if (!vm.currentTemplate) return null
    return (
      <div className='intro-section'>
        <InputLabel required htmlFor='intro-body'>
          {strings.body}
        </InputLabel>
        {renderBodyText()}
      </div>
    )
  }

  const renderBody = () => {
    return (
      <Grid item xs={12} className='body'>
        <Grid container spacing={2} direction='row'>
          <Grid item xs={5} className='intro-left'>
            <Typography component='h6' className='intro-editor-label'>
              {strings.introduction_info}
            </Typography>
            {renderTemplateSelect()}
            {renderSubjectInput()}
            {renderBodyInput()}
          </Grid>
          <Grid item xs={7} className='intro-right'>
            {renderEmailTemplatePreviewTabs()}
            {renderEmailTemplatePreview()}
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderActionButtons = () => {
    return (
      <Grid item xs={12} className='intro-buttons'>
        <Grid item>
          <Button variant='outlined' onClick={handleReset}>
            {strings.reset}
          </Button>
          <Button disabled={isInvalid()} variant='contained' onClick={handleApply}>
            {strings.apply}
          </Button>
        </Grid>
      </Grid>
    )
  }

  const renderDialogContent = () => {
    return (
      <DialogContent>
        <Grid
          id='IntroductionScreenDesignerContent'
          container
          direction='column'
          justifyContent='center'
          alignItems='stretch'
        >
          {renderBody()}
        </Grid>
      </DialogContent>
    )
  }

  const renderDialogActions = () => {
    return (
      <DialogActions>
        <Grid
          id='IntroductionScreenDesignerActions'
          container
          direction='column'
          justifyContent='center'
          alignItems='stretch'
        >
          {renderActionButtons()}
        </Grid>
      </DialogActions>
    )
  }

  const renderUploadModal = () => {
    if (organizationsStore.currentOrganization?.hasCMSPicker) return renderCMSUploadModal()
    return renderStandardUploadModal()
  }

  const renderCMSUploadModal = () => {
    return (
      <CMSItemsPickerDialog
        allowMultiple={false}
        fileTypes={CMSItemsTypeEnum.DEFAULT}
        addAfterSave={(a: Attachment[]) => vm.addIntroImage(a[0])}
        toggleShowModal={() => vm.toggleShowUploadModal()}
        isOpen={vm.showUploadModal}
      />
    )
  }

  const renderStandardUploadModal = () => {
    return (
      <FileUploadModal
        allowMultiple={false}
        fileTypes={FileTypeEnum.DEFAULT}
        addAfterSave={(a: Attachment[]) => vm.addIntroImage(a[0])}
        toggleShowModal={() => vm.toggleShowUploadModal()}
        isOpen={vm.showUploadModal}
      />
    )
  }

  const renderSpinner = () => {
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  return (
    <Dialog
      id='IntroductionScreenDesignerDialog'
      open={vm.isIntroScreenDesignerOpen}
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
      onClose={handleClose}
      fullWidth
    >
      {renderUploadModal()}
      <div className={'dialog-container'}>{renderDialogTitle()}</div>
      {renderDialogContent()}
      {renderDialogActions()}
    </Dialog>
  )
}

export default inject('pulseCampaignsStore', 'organizationsStore')(observer(IntroductionScreenDesigner))
