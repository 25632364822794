import React, { FC } from 'react'
import rootStore from '../../../../stores/RootStore'
import {
  Toolbar,
  Typography,
  Button,
  ButtonBase,
  MenuItem,
  Menu,
  Tooltip,
  CssBaseline,
  AppBar,
} from '@material-ui/core'
import '../../../../css/dashboard-toolbar.scss'
import './ImpactDashboard.scss'
import { inject, observer } from 'mobx-react'
import { UserDashboardsStore } from '../../UserDashboardsStore'
import GoalsIcon from '@material-ui/icons/TrackChanges'
import TrainingItemIcon from '@material-ui/icons/School'
import TrainingPlanIcon from '@material-ui/icons/Map'
import EditIcon from '@material-ui/icons/Edit'
import isIE11 from '../../../../../utils/isIE11'
import clsx from 'clsx'

export interface Props {
  children?: React.ReactElement
  userDashboardsStore?: UserDashboardsStore
}


const ImpactToolbar: FC<Props> = ({ userDashboardsStore }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const vm = userDashboardsStore.viewModels.userDashboardVM

  const handleCreateGoal = () => {
    rootStore.appStore.router.push('/goals/edit/new')
  }

  const handleCreateTrainingPlan = () => {
    rootStore.appStore.router.push('/trainingplans/edit/new')
  }

  const handleCreateTrainingItem = () => {
    rootStore.appStore.router.push('/trainingitems/edit/new')
  }

  const renderGoalMenuOption = () => {
    if (!rootStore.organizationsStore.currentOrganization) return null
    if (!rootStore.organizationsStore.currentOrganization.isGoalsEnabled) return null
    if (!rootStore.appStore.canCreateGoals) return null
    return (
      <MenuItem
        onClick={() => {
          handleCreateGoal()
          setAnchorEl(null)
        }}
      >
        <GoalsIcon className='icon goals' />
        {rootStore.localizationStore.lzStrings.goals.goal}
      </MenuItem>
    )
  }

  const renderTrainingPlansMenuOption = () => {
    if (!rootStore.organizationsStore.currentOrganization) return null
    if (!rootStore.organizationsStore.currentOrganization.isTrainingsEnabled) return null
    if (!rootStore.appStore.canCreateTrainings) return null
    return (
      <MenuItem
        onClick={() => {
          handleCreateTrainingPlan()
          setAnchorEl(null)
        }}
      >
        <TrainingPlanIcon className='icon plans' />
        {rootStore.localizationStore.lzStrings.goals.training_plan}
      </MenuItem>
    )
  }
  const renderTrainingItemsMenuOption = () => {
    if (!rootStore.organizationsStore.currentOrganization) return null
    if (!rootStore.organizationsStore.currentOrganization.isTrainingsEnabled) return null
    if (!rootStore.appStore.canCreateTrainings) return null
    return (
      <MenuItem
        onClick={() => {
          handleCreateTrainingItem()
          setAnchorEl(null)
        }}
      >
        <TrainingItemIcon className='icon items' />
        {rootStore.localizationStore.lzStrings.goals.training_item}
      </MenuItem>
    )
  }

  const renderMenu = () => {
    return (
      <Menu
        className='goal-create-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {renderGoalMenuOption()}
        {renderTrainingItemsMenuOption()}
        {renderTrainingPlansMenuOption()}
      </Menu>
    )
  }

  const renderGoalsDashboard = () => {
    if (!rootStore.organizationsStore.currentOrganization) return null
    if (!rootStore.organizationsStore.currentOrganization.isGoalsEnabled) return null
    return (
      <ButtonBase
        className={vm.impactTab === 0 ? 'tab-button active' : 'tab-button'}
        onClick={() => vm.setSelectedImpactRoute(0)}
      >
        Goals
      </ButtonBase>
    )
  }

  const renderTrainingsDashboard = () => {
    if (!rootStore.organizationsStore.currentOrganization) return null
    if (!rootStore.organizationsStore.currentOrganization.isTrainingsEnabled) return null
    return (
      <ButtonBase
        className={vm.impactTab === 1 ? 'tab-button active' : 'tab-button'}
        onClick={() => vm.setSelectedImpactRoute(1)}
      >
        Trainings
      </ButtonBase>
    )
  }

  const renderCatalogDashboard = () => {
    return (
      <ButtonBase
        className={vm.impactTab === 2 ? 'tab-button active' : 'tab-button'}
        onClick={() => vm.setSelectedImpactRoute(2)}
      >
        Catalog
      </ButtonBase>
    )
  }

  const renderTabs = () => {
    return (
      <>
        {renderGoalsDashboard()}
        {renderTrainingsDashboard()}
        {renderCatalogDashboard()}
      </>
    )
  }

  const renderManageButton = () => {
    return (
      <Tooltip title={rootStore.localizationStore.lzStrings.dashboard.manage_dashboard_button_tooltip} placement='bottom'>
        <span>
          <Button
            disabled={Boolean(!vm.userDashboard)}
            className='dashboard-action btn-icon btn-icon-white'
            variant='outlined'
            onClick={() => vm.editDashboard()}
          >
            <EditIcon /> {rootStore.localizationStore.lzStrings.dashboard.manage_dashboard_button_text}
          </Button>
        </span>
      </Tooltip>
    )
  }

  const renderAddButton = () => {
    if (!rootStore.appStore.canCreateTrainings && !rootStore.appStore.canCreateGoals) return null
    return (
      <Button
        disabled={Boolean(!vm.userDashboard)}
        className='dashboard-action'
        variant='contained'
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        {rootStore.localizationStore.lzStrings.goals.create}...
      </Button>
    )
  }

  return (
    <>
      <div id='DashboardToolbar'>
        <CssBaseline />
        <AppBar
          className={clsx('dashboard-appbar', {
            'no-transition': isIE11,
          })}
          elevation={0}
        >
          <Toolbar className='dashboard-toolbar'>
            <Typography className='dashboard-title'>
              {rootStore.localizationStore.lzStrings.dashboard.goals}
            </Typography>
            <div className='tab-btns'>
              {renderTabs()}
            </div>
            <div className='dashboard-toolbar-right'>
              {renderManageButton()}
              {renderAddButton()}
              {renderMenu()}
            </div>
          </Toolbar>
        </AppBar>
        <Toolbar />
      </div>
    </>
  )
}

export default inject('userDashboardsStore')(observer(ImpactToolbar))
