import React from 'react'
import GoogleMapReact from 'google-map-react'

export interface ICoordinates {
  lat: number
  lng: number
}

interface IMap {
  center?: ICoordinates
  zoom?: number
}

const defaultMapProps: IMap = {
  center: { lat: 33.749, lng: -84.388 },
  zoom: 8,
}

const Map: React.FC<IMap> = ({ center, zoom, children }) => {
  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: 'AIzaSyBHM6URvtGJ9kRNLx8ws4Y-G-aM7GlLgBc' }}
      center={center}
      zoom={zoom}
      // layerTypes={['TrafficLayer', 'TransitLayer']} //Can show traffic and public transit routes if we want
    >
      {children}
    </GoogleMapReact>
  )
}

Map.defaultProps = defaultMapProps

export default Map
