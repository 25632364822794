import React, { FC } from 'react'
import { inject, observer } from 'mobx-react'
import Avatar from '../../../shared/Avatar'
import AvatarMenu from './AvatarMenu'
import { AppStore } from '../../../stores/AppStore'
import './AvatarMenu.scss'
import { UserStore } from '../../store/UserStore'
import HelpDialog from '../../../help/HelpDialog'
import { CircularProgress } from '@material-ui/core'

interface Props {
  appStore?: AppStore
  userStore?: UserStore
}

const AvatarContainer: FC<Props> = ({ appStore, userStore }) => {
  const vm = userStore.avatarMenuVM
  if (!vm) return null

  const renderFullyLoaded = () => {
    if (!appStore.isFullyLoaded) return
    return <div id='fully-loaded'></div>
  }

  const renderSpinner = () => {
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  const renderAvatar = () => {
    if (vm.isRefreshing) return renderSpinner()
    return <Avatar user={vm.user} onClick={(e) => vm.open(e.currentTarget)} isCache={false} />
  }

  return (
    <div className={vm.isOpen ? 'avatar-container open' : 'avatar-container'}>
      {renderAvatar()}
      {renderFullyLoaded()}
      <AvatarMenu />
      <HelpDialog />
    </div>
  )
}

export default inject('appStore', 'userStore')(observer(AvatarContainer))
