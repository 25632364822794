import Parse from 'parse'
import { RootStore } from '../../stores/RootStore'

export class TableauRestService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public async getPreviewImage(
    userName: string,
    workbookName: string,
    viewName: string,
    imageExt?: string
  ) {
    const orgId = this.rootStore.appStore.currentOrgId
    return await Parse.Cloud.run('getTableauPreviewImage', {
      orgId,
      userName,
      workbookName,
      viewName,
      imageExt,
    })
  }

  public async getWorkbooks() {
    const orgId = this.rootStore.appStore.currentOrgId
    return await Parse.Cloud.run('getTableauWorkbooks', { orgId })
  }

  public async getWorkbook(workbookName: string) {
    const orgId = this.rootStore.appStore.currentOrgId
    return await Parse.Cloud.run('getTableauWorkbook', { orgId, workbookName })
  }

  public async getTableauDataSources() {
    const orgId = this.rootStore.appStore.currentOrgId
    return await Parse.Cloud.run('getTableauDataSources', { orgId })
  }
}
