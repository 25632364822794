import { CircularProgress } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import React from 'react'
import SpinnerOverlay from '../../shared/SpinnerOverlay'
import { DashboardsStore } from '../DashboardsStore'
import DashboardEditLayout from './DashboardEditLayout'
import WidgetsDrawer from './Drawers/WidgetsDrawer'
import DashboardEditToolbar from './Toolbars/DashboardEditToolbar'

interface Props {
  dashboardsStore?: DashboardsStore
}

const DashboardEditContent: React.FC<Props> = ({ dashboardsStore }) => {
  const vm = dashboardsStore.editVM
  if (!vm) return null


  const renderSpinner = () => {
    if (!vm.isProcessing) return null
    return (
      <SpinnerOverlay />
    )
  }

  const renderHeader = () => {
    return <DashboardEditToolbar />
  }

  const renderDashboard = () => {
    return (
      <>
        <WidgetsDrawer />
        <DashboardEditLayout />
      </>
    )
  }

  return (
    <>
      {renderSpinner()}
      {renderHeader()}
      {renderDashboard()}
    </>
  )
}

export default inject('dashboardsStore')(observer(DashboardEditContent))
