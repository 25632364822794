import React, { FC, CSSProperties } from 'react'
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress'

export interface SpinnerOverlayProps {
  style?: CSSProperties
  spinnerProps?: CircularProgressProps
}

const SpinnerOverlay: FC<SpinnerOverlayProps> = ({ style, spinnerProps }) => {
  return (
    <div
      style={{
        position: 'absolute',
        height: 'calc(100vh - 64px)',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(248, 245, 245, 0.6)',
        zIndex: 1500,
        overflowY: 'hidden',
        ...style,
      }}
    >
      <CircularProgress color='primary' size={90} variant='indeterminate' {...spinnerProps} />
    </div>
  )
}

export default SpinnerOverlay
