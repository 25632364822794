import { RootStore } from '../../../stores/RootStore'
import { computed, action } from 'mobx'
import { MediaItemsStore } from '../../store/MediaItemsStore'
import { MediaItemRowVM } from './MediaItemRowVM'
import { ColDef, GridOptions, RowClickedEvent } from 'ag-grid-community'
import { AGGridVM } from '../../../shared/ag-grid/AGGridVM'
import { nameOf } from '../../../shared/nameOf'

export class MediaItemListWidgetVM extends AGGridVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    super()
    if (rootStore) this.rootStore = rootStore
  }

  @action
  public getRowNodeId(row: MediaItemRowVM): string {
    return row.objectId
  }

  public get shouldRender(): boolean {
    if (!this.rootStore.categoriesStore.loaded) return false
    return true
  }

  public getGridOptions(): GridOptions {
    return {
      ...this.baseGridOptions,
      rowData: this.rows,
      onRowClicked: (e) => this.rowClicked(e),
      getRowNodeId: (e) => this.getRowNodeId(e),
      columnDefs: this.ensureTooltipFields(this.columnDefs),
    }
  }

  public rowClicked(e: RowClickedEvent) {
    const row: MediaItemRowVM = e.data
    if (!row) return
    row.openMedia()
  }

  @computed
  public get columnDefs(): ColDef[] {
    const s = this.rootStore.localizationStore.lzStrings
    return [
      {
        headerName: s.mediaItemDrawer.name,
        field: nameOf<MediaItemRowVM, string>((e) => e.name),
        sort: 'asc',
      },
      {
        headerName: s.organizationWidgetDrawer.logo_preview,
        field: nameOf<MediaItemRowVM, string>((e) => e.preview),
        cellRenderer: 'previewCell',
        cellClass: 'media-items-widget-preview-cell',
      },
      {
        headerName: s.questionsCatalogWidget.type,
        field: nameOf<MediaItemRowVM, string>((e) => e.type),
      },
      {
        headerName: s.mediaItemDrawer.created,
        field: nameOf<MediaItemRowVM, string>((e) => e.createdDate),
      },
      { headerName: s.mediaItemDrawer.size, field: nameOf<MediaItemRowVM, string>((e) => e.size) },
    ]
  }

  @computed
  public get rows(): MediaItemRowVM[] {
    return this.mediaItems
  }

  @computed
  private get mediaItemsStore(): MediaItemsStore {
    return this.rootStore.mediaItemsStore
  }

  @computed
  private get mediaItems(): MediaItemRowVM[] {
    return this.mediaItemsStore.currentOrgMediaItems.map((itm) => new MediaItemRowVM(this.rootStore, itm, this))
  }
}
