import { computed, action, observable } from 'mobx'
import { RootStore } from 'src/app/stores/RootStore'
import { IOptionDTO } from '../dtos/IOptionDTO'
import { IQuestionImportRowDTO } from '../dtos/IQuestionImportRowDTO'
import { ResponseType } from '../types/ResponseType'

export class ImportOptionVM {
  private rootStore: RootStore
  private option: IOptionDTO

  constructor(rootStore: RootStore, option: IOptionDTO) {
    this.option = option
    this.rootStore = rootStore
  }

  @computed
  public get text(): string {
    return this.option.text
  }

  @computed
  public get value(): number {
    return this.option.value
  }

  @computed
  public get responseType(): string {
    if (!this.option.responseType) return 'neutral'
    if (this.option.responseType === 'very positive') return 'excellent'
    if (this.option.responseType === 'positive') return 'good'
    if (this.option.responseType === 'neutral') return 'average'
    if (this.option.responseType === 'negative') return 'below average'
    if (this.option.responseType === 'very negative') return 'poor'
    return 'neutral'
  }

  @computed
  public get emoji(): string {
    if (!this.option.emoji) return '-'
    return this.option.emoji
  }

}
