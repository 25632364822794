import { RootStore } from '../../stores/RootStore'
import Parse from 'parse'
import { IGoalDTO } from '../dto/IGoalDTO'
import { ISourceResult } from '../interfaces/ISourceResult'
import { IValueFactResult } from '../interfaces/IValueFactResult'

export class GoalsService {
  public async saveGoal(goal: IGoalDTO) {
    return await Parse.Cloud.run('createNewGoal', { goal })
  }
  public async editGoal(goal: IGoalDTO) {
    return await Parse.Cloud.run('editGoal', { goal })
  }

  public async getSources(orgId) {
    const data: ISourceResult = await Parse.Cloud.run('getSources', { orgId: orgId })
    if (!data) return []
    if (!data.success) return []
    return data.sources
  }

  public async getValueFacts(goal: IGoalDTO) {
    const data: IValueFactResult = await Parse.Cloud.run('getValueFacts', { goal })
    if (!data) return []
    if (!data.success) return []
    return data.valueFacts
  }

  public async deleteGoal(id: string) {
    await Parse.Cloud.run('deleteGoal', { id })
  }

  public async saveCatalogGoal(goal: IGoalDTO) {
    return await Parse.Cloud.run('saveCatalogGoal', { goal })
  }
}
