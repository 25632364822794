import React from 'react'
import clsx from 'clsx'
import { inject, observer } from 'mobx-react'
import { ButtonBase } from '@material-ui/core'
import LocalizationStore from '../../../localization/LocalizationStore'
import { ManageSurveysWidgetVM } from '../../view-models/manage-surveys-widget/ManageSurveysWidgetVM'
import { LabelsStore } from '../../../labels/store/LabelsStore'

interface WidgetTitleProps {
  localizationStore?: LocalizationStore
  vm: ManageSurveysWidgetVM
  labelsStore?: LabelsStore
}

const WidgetTitle: React.FC<WidgetTitleProps> = ({ localizationStore, vm, labelsStore }) => {
  const { lzStrings } = localizationStore

  return (
    <div className='widget-title-tabs'>
      <span>
        {`${lzStrings.manageSurveysWidget.manage} ${labelsStore.getLabelByLanguageAndFor(
          'pulses'
        )}`}
      </span>
      <div className='tab-btns'>
        <ButtonBase
          onClick={() => vm.setTabIndex(0)}
          className={clsx('tab-button', {
            active: vm.tabIndex === 0,
          })}
        >
          Open
        </ButtonBase>
        <ButtonBase
          onClick={() => vm.setTabIndex(1)}
          className={clsx('tab-button', {
            active: vm.tabIndex === 1,
          })}
        >
          Closed
        </ButtonBase>
        <ButtonBase
          onClick={() => vm.setTabIndex(2)}
          className={clsx('tab-button', {
            active: vm.tabIndex === 2,
          })}
        >
          Drafts
        </ButtonBase>
      </div>
    </div>
  )
}

export default inject('localizationStore', 'labelsStore')(observer(WidgetTitle))
