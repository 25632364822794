import { RootStore } from '../../stores/RootStore'
import { Event } from '../aggregate/Event'
import { observable, computed, action } from 'mobx'
import { CalendarVM } from './CalendarVM'
import { IEventPillVM } from '../intefaces/IEventPillVM'
import { UserEvent } from '../user-events/aggregate/UserEvent'
import moment, { ISO_8601 } from 'moment'

export class GroupEventPillVM implements IEventPillVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, groupId: string, event: Event) {
    this.rootStore = rootStore
    this.event = event
    this.groupId = groupId
  }

  @observable public event: Event

  public groupId: string = ''

  @computed
  public get title(): string {
    return this.event.title
  }

  @computed
  public get localStartDate(): Date {
    return this.event.localStartDate
  }

  @computed
  public get localEndDate(): Date {
    return this.event.localEndDate
  }

  @computed
  public get allDay(): boolean {
    return this.event.allDay
  }

  @computed
  public get objectId(): string {
    return this.event.objectId
  }

  @computed
  public get groupName(): string {
    const foundGroup = this.rootStore.groupsStore.getGroup(this.groupId)
    if (!foundGroup) return ''
    return foundGroup.name
  }

  @computed
  public get calendarVM(): CalendarVM {
    return this.rootStore.eventsStore.calendarVM
  }

  @computed
  public get calendarIndex(): number {
    const idx = this.calendarVM.selectedOtherCalendars.findIndex((e) => e.id === this.groupId)
    if (idx === -1) return 0
    return idx + 1
  }

  @action
  public edit() {
    this.rootStore.eventsStore.lazyLoadEventEditVM(this.event.objectId)
    this.rootStore.eventsStore.calendarVM.isEventsDrawerOpen = true
  }
}
