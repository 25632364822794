import {
  Button,
  Card,
  Grid,
  IconButton,
  Paper,
  Popover,
  Slide,
  Typography,
  Chip,
} from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import React, { useState } from 'react'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { TrainingPlansStore } from '../../../../training-plans/store/TrainingPlansStore'
import LaunchIcon from '@material-ui/icons/Launch'
import './Drawer.scss'
import Searchbar from '../../../../shared/Searchbar'
import { TrainingItemCardVM } from '../../../../training-plans/view-models/TrainingItemCardVM'
import LinkIcon from '@material-ui/icons/Link'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import PermMediaIcon from '@material-ui/icons/PermMedia'
import FilterListIcon from '@material-ui/icons/FilterList'
import DropdownTreeSelect from 'react-dropdown-tree-select'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ExerciseIcon from '@material-ui/icons/FitnessCenterRounded'

interface Props {
  trainingPlansStore?: TrainingPlansStore
  localizationStore?: LocalizationStore
}

const Drawer: React.FC<Props> = ({ trainingPlansStore, localizationStore }) => {
  const [categoryAnchorEl, setCategoryAnchorEl] = useState(null)
  const { training_plans: strings } = localizationStore.lzStrings
  const { trainingPlanEditVM: vm } = trainingPlansStore

  const renderHeader = () => {
    return (
      <Grid item xs={12} className='builder-drawer-header'>
        <Typography variant='h5'>{strings.available_training_items}</Typography>
      </Grid>
    )
  }

  const renderToolbar = () => {
    return (
      <Grid item xs={12} className='builder-drawer-toolbar'>
        <Button
          disabled={vm.editIsDisabled}
          variant='text'
          onClick={() => vm.createNewTrainingItem()}
        >
          {strings.new_training_item.toUpperCase()}
          <LaunchIcon />
        </Button>
      </Grid>
    )
  }

  const renderIcon = (type) => {
    if (!type) return null
    if (type === 'link')
      return (
        <span className='content-popup-menu-item-icon'>
          <div className='item-icon-container'>
            <LinkIcon />
          </div>
          <Typography component='span' variant='h6'>
            {type}
          </Typography>
        </span>
      )
    if (type === 'file')
      return (
        <span className='content-popup-menu-item-icon'>
          <div className='item-icon-container'>
            <AttachFileIcon />
          </div>
          <Typography component='span' variant='h6'>
            {type}
          </Typography>
        </span>
      )
    if (type === 'media')
      return (
        <span className='content-popup-menu-item-icon'>
          <div className='item-icon-container'>
            <PermMediaIcon />
          </div>
          <Typography component='span' variant='h6'>
            {type}
          </Typography>
        </span>
      )
    if (type === 'exercise')
      return (
        <span className='content-popup-menu-item-icon'>
          <div className='item-icon-container'>
            <ExerciseIcon />
          </div>
          <Typography component='span' variant='h6'>
            {type}
          </Typography>
        </span>
      )
  }

  const renderContentItemsMenu = (trainingItem: TrainingItemCardVM) => {
    if (!trainingItem.anchorEl) return null
    return (
      <Popover
        open={true}
        anchorEl={trainingItem.anchorEl}
        onClose={() => trainingItem.setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className='content-items-popup'
      >
        <Card elevation={5} className='popup-body'>
          {trainingItem.contentItems.map((item, index) => {
            return (
              <div className='content-popup-menu-item' key={index}>
                <div className='item-details'>
                  <Typography component='span' variant='h6'>
                    {index + 1}.
                  </Typography>
                  <Typography component='span' variant='h6'>
                    {item.title}
                  </Typography>
                </div>
                <div className='media-type-icon'>
                  <Typography
                    component='span'
                    variant='body2'
                    className='content-popup-menu-item-icon-container'
                  >
                    {renderIcon(item.type.toLowerCase())}
                  </Typography>
                </div>
              </div>
            )
          })}
        </Card>
      </Popover>
    )
  }

  const renderBody = () => {
    return (
      <>
        <Grid item xs={12} className='builder-drawer-body-filters'>
          {renderCategoryFilterButton()}
          <Searchbar
            focused={vm.trainingItemsFilter.length > 0}
            className='search'
            searchValue={vm.trainingItemsFilter}
            onSearchChange={(e) => vm.setFilter(e.target.value)}
          />
        </Grid>
        <div className='available-training-items'>
          {vm.trainingItems.map((trainingItem, index) => {
            return (
              <Grid item key={index}>
                <Paper elevation={0} className={'training-item-card'}>
                  <Grid container alignContent='center' justifyContent='space-between'>
                    <Grid
                      xs={10}
                      onClick={() => {
                        if (vm.editIsDisabled) return
                        trainingItem.onSelect()
                      }}
                      item
                    >
                      <Typography variant='h5' className='title'>
                        {trainingItem.title}
                      </Typography>
                      <Typography variant='body2' className='subtitle'>
                        {trainingItem.subtitle}
                      </Typography>
                      {trainingItem.categoryNames.map((cat, index) => (
                        <Chip key={index} label={cat} />
                      ))}
                    </Grid>
                    <Grid item xs={2} className='preview'>
                      <IconButton onClick={(e) => trainingItem.setAnchorEl(e.target)}>
                        <ArrowDropDownIcon fontSize='large' />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Paper>
                {renderContentItemsMenu(trainingItem)}
              </Grid>
            )
          })}
        </div>
      </>
    )
  }

  const renderCategoryFilterButton = () => {
    return (
      <>
        <Button
          variant='outlined'
          startIcon={<FilterListIcon />}
          onClick={(e) => setCategoryAnchorEl(categoryAnchorEl ? null : e.currentTarget)}
        >
          {vm.drawerCategoryFilterVM.categoryLabel} (
          {vm.drawerCategoryFilterVM.selectAllChecked
            ? 'All'
            : vm.drawerCategoryFilterVM.selectedCategoryIds.length}
          )
        </Button>
        <Popover
          open={Boolean(categoryAnchorEl)}
          onClose={() => setCategoryAnchorEl(null)}
          anchorEl={categoryAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          className='question-category-popper'
        >
          {renderCategoryFilterMenu()}
        </Popover>
      </>
    )
  }

  const renderCategoryFilterMenu = () => {
    return (
      <div className='question-catalog-category-filter-menu'>
        <DropdownTreeSelect
          data={vm.drawerCategoryFilterVM.data}
          onChange={(selected, allSelected) => {
            if (!vm.itemModalOpen) vm.drawerCategoryFilterVM.handleChange(selected)
          }}
          onNodeToggle={(node) => vm.drawerCategoryFilterVM.handleNodeToggle(node)}
          texts={{
            placeholder: 'Search',
          }}
          mode='multiSelect'
          showDropdown='always'
          disabled={vm.itemModalOpen}
          keepTreeOnSearch
          keepOpenOnSelect
          keepChildrenOnSearch
        />
      </div>
    )
  }

  return (
    <Slide direction={'left'} in={trainingPlansStore.trainingPlanEditVM.builderDrawerOpen}>
      <Paper id='PlanBuilderDrawer' square elevation={6}>
        <Grid container className='drawer-body'>
          {renderHeader()}
          {renderToolbar()}
          {renderBody()}
          {renderCategoryFilterMenu()}
        </Grid>
      </Paper>
    </Slide>
  )
}

export default inject('trainingPlansStore', 'localizationStore')(observer(Drawer))
