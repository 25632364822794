import React from 'react'
import { inject, observer } from 'mobx-react'
import { Avatar, Button, Paper } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { PulseCampaignEditVM } from '../../view-models/PulseCampaignEditVM'
import './AddSurveyCompletedContentButton.scss'
import LocalizationStore from '../../../localization/LocalizationStore'

interface Props {
  vm: PulseCampaignEditVM
  localizationStore?: LocalizationStore
}

const AddSurveyCompletedContentButton: React.FC<Props> = ({ vm, localizationStore }) => {
  if (!vm) return null
  const { pulseCampaign: lz } = localizationStore.lzStrings

  const outroClass =
    !vm.currentSurvey.surveyIntroductionValid && vm.publishTried
      ? 'add-outro-screen-create-btn intro-error'
      : 'add-outro-screen-create-btn'

  return (
    <div id='AddSurveyCompletedContentButton' className={'add-outro-screen-group-paper'}>
      <Paper elevation={0} onClick={() => vm.openCompletedContentScreenDialog()}>
        <Button className={outroClass} color='primary' fullWidth>
          <Avatar className={'add-outro-screen-avatar'}>
            <AddIcon fontSize='small' />
          </Avatar>
          <p className={'add-outro-screen-label'}>Add Completed Content</p>
        </Button>
      </Paper>
    </div>
  )
}

export default inject(
  'pulseCampaignsStore',
  'localizationStore'
)(observer(AddSurveyCompletedContentButton))
