import { inject, observer } from 'mobx-react'
import React from 'react'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { TrainingPlansStore } from '../../../store/TrainingPlansStore'
import TrainingPlanRow from './TrainingPlanRow'

interface Props {
  trainingPlansStore?: TrainingPlansStore
  localizationStore?: LocalizationStore
}

const Content: React.FC<Props> = ({ trainingPlansStore, localizationStore }) => {
  const { manageTrainingsWidgetVM: vm } = trainingPlansStore
  if (!vm) return null

  const renderPlanRows = () => {
    return vm.rows.map((plan, index) => <TrainingPlanRow plan={plan} key={index} />)
  }

  return <div id='ManageTrainingContent'>{renderPlanRows()}</div>
}

export default inject('trainingPlansStore', 'localizationStore')(observer(Content))
