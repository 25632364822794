import { RootStore } from '../../../stores/RootStore'
import { DataStore } from '../../../shared/data/DataStore'
import { IUserSurveyDTO } from '../../dtos/IUserSurveyDTO'
import { UserSurvey } from '../../aggregate/UserSurvey'
import { UserSurveysService } from '../../service/UserSurveysService'

export class AlwaysAvailableUserSurveysDataStore extends DataStore<UserSurvey, IUserSurveyDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, UserSurvey, 'userSurveys', [])
    this.paged = true
    this.recordsPerPage = 100
  }

  protected getListRecordsApiCall() {
    return async () => {
      const svc = new UserSurveysService(this.rootStore)
      const result = await svc.getAlwaysAvailableSurveys()
      this.totalRecords = result.count
      return result.userSurveys
    }
  }

  public get rows() {
    return this.records
  }
}
