import React from 'react'
import { inject, observer } from 'mobx-react'
import { Card, Fade, Popover, Link } from '@material-ui/core'
import TrainingItemPopupToolbar from './TrainingItemPopupToolbar'
import TrainingItemPopupBody from './TrainingItemPopupBody'
import './TrainingItemPopup.scss'
import { EventsStore } from '../../../store/EventsStore'

interface Props {
  eventsStore?: EventsStore
}

const TrainingItemPopup: React.FC<Props> = ({ eventsStore }) => {
  const { trainingItemPopupVM: vm } = eventsStore
  if (!vm) return null
  if (!vm.anchorEl) return null

  return (
    <Popover
      open={true}
      anchorEl={vm.anchorEl}
      onClose={() => vm.close()}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      className='calendarEventModal'
    >
      <Card elevation={5} className='trainingPopupCard'>
        <TrainingItemPopupToolbar />
        <TrainingItemPopupBody />
        <Link onClick={() => vm.navigateToTrainingItem()} className='training-link'>
          Open Training Item
        </Link>
      </Card>
    </Popover>
  )
}

export default inject('eventsStore')(observer(TrainingItemPopup))
