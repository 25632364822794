import React, { FC } from 'react'
import { inject } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid } from '@material-ui/core'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import './MuiConfirmDeleteDialog.scss'

export interface ConfirmDeleteDialogProps {
  localizationStore?: LocalizationStore
  confirmationMessage: string
  onClose: (e) => void
  onConfirm: (e) => void
  open: boolean
  additionalDetails?: string
}

const MuiConfirmDeleteDialog: FC<ConfirmDeleteDialogProps> = ({
  localizationStore,
  confirmationMessage,
  onClose,
  onConfirm,
  open,
  additionalDetails,
}) => {
  const strings = localizationStore.lzStrings.moreMenu

  const renderAdditionalDetails = () => {
    if (!additionalDetails) return null
    return <p className='additional-details-text'>{additionalDetails}</p>
  }

  return (
    <div>
      <Dialog onClose={onClose} open={open} id='MuiConfirmDeleteDialog'>
        <DialogTitle className='dialog-title'>{strings.confirm_delete}</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item md={1} sm={1}>
              <WarningRoundedIcon className='warning-icon' />
            </Grid>
            <Grid item md={11} sm={11} className='confirm-message'>
              <p>{confirmationMessage}</p>
              {renderAdditionalDetails()}
              <p className='cannot-undo-text'>{strings.cannot_be_undone}</p>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' className='btn-cancel' onClick={onClose}>
            {strings.cancel}
          </Button>
          <Button variant='contained' className='btn-remove' onClick={onConfirm}>
            {strings.yes_delete}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default inject('localizationStore')(MuiConfirmDeleteDialog)
