import { RootStore } from '../../stores/RootStore'
import { observable, computed, action } from 'mobx'
import { UserEvent } from '../../events/user-events/aggregate/UserEvent'
import { RowType } from '../types/RowType'
import { INotificationRow } from '../interfaces/INotificationRow'
import moment from 'moment'

export class EventRowVM implements INotificationRow {
  private rootStore: RootStore

  constructor(rootStore: RootStore, userEvent: UserEvent) {
    this.rootStore = rootStore
    this.userEvent = userEvent
  }

  @observable public userEvent: UserEvent = null
  @observable public type: RowType = 'userEvent'
  @observable public buttonText: string = 'VIEW EVENT'

  @computed
  public get id(): string {
    return this.userEvent.objectId
  }

  @computed
  public get title(): string {
    return this.userEvent.title
  }

  @computed
  public get createdSeconds(): number {
    const now = new Date()
    const createdAt = new Date(this.userEvent.startDate)
    return (now.getTime() - createdAt.getTime()) / 1000
  }

  @computed
  public get createdAt(): string {
    return moment(this.userEvent.startDate).fromNow()
  }

  @computed
  public get dueDate(): string {
    return null
  }

  @computed
  public get hasDuedate(): boolean {
    if (!this.dueDate) return false
    return true
  }

  @action
  public onAction() {
    this.rootStore.appStore.router.push(
      `/calendar/v2/${this.userEvent.eventId}/${this.userEvent.organizationId}`
    )
  }
}
