import { RootStore } from '../../../stores/RootStore'
import Parse from 'parse'
import { observable, runInAction, action, computed } from 'mobx'
import { deserialize } from 'serializr'
import { UserTrainingPlan } from '../aggregate/UserTrainingPlan'
import { UserTrainingPlanTakeVM } from '../view-models/UserTrainingPlanTakeVM'
import { MyTrainingPlansWidgetVM } from '../view-models/widgets/MyTrainingPlansWidgetVM'
import { UserTrainingPlansUpdateService } from '../service/UserTrainingPlansUpdateService'
import { IUserTrainingPlanDTO } from '../dto/IUserTrainingPlanDTO'
import { DataStore } from '../../../shared/data/DataStore'

export class UserTrainingPlansStore extends DataStore<UserTrainingPlan, IUserTrainingPlanDTO> {
  private svc: UserTrainingPlansUpdateService

  constructor(rootStore: RootStore) {
    super(rootStore, UserTrainingPlan, 'userTrainingPlans', [
      'userId',
      'title',
      'description',
      'trainingPlanId',
      'requireQuiz',
      'requireCertificate',
      'assignedByUserId',
      'trainingPlanItems',
      'dueDate',
      'activeDate',
      'organizationId',
      'activeTrainingItem',
      'quiz',
      'certificate',
      'isComplete',
      'completedDate',
      'version',
      'isRead',
      'readDateTime',
      'mustCompleteInOrder',
      'trainer',
      'completion',
      'isArchived',
      'dueDateVariesByTrainingItem',
      'showQuizResults',
      'associatedCatalogId',
      'trainingPlanFlowId',
      'isReadonly',
    ])
  }

  @observable public userTrainingPlanTakeVM: UserTrainingPlanTakeVM = null
  @observable public myTrainingPlansWidgetVM: MyTrainingPlansWidgetVM = null
  @observable public loaded: boolean = false

  @computed
  public get orgUserTrainingPlans(): UserTrainingPlan[] {
    return this.records
  }

  @action
  public onListRecordsLoaded() {
    this.myTrainingPlansWidgetVM = new MyTrainingPlansWidgetVM(this.rootStore)
    this.svc = new UserTrainingPlansUpdateService()
    this.loaded = true
  }

  @computed
  public get canCreateTrainingPlans(): boolean {
    return this.rootStore.appStore.canCreateGoals
  }

  @action
  public async markUserTrainingPlanAsRead(id: string) {
    await this.svc.markUserTrainingPlanAsRead(id, this.rootStore.appStore.currentOrgId)
  }

  public getUserTrainingPlan(objectId): UserTrainingPlan {
    const trainingPlan: UserTrainingPlan = this.orgUserTrainingPlans.find(
      (userTrainingPlan) => userTrainingPlan.objectId === objectId
    )

    if (!trainingPlan) {
      this.getFullRecord(objectId, false)
    }
    return trainingPlan
  }

  public getAllByPlanId(id: string, includeArchived?: boolean): UserTrainingPlan[] {
    const plans = this.orgUserTrainingPlans.filter((plan) => plan.trainingPlanId === id)
    if (includeArchived) return plans
    return plans.filter((plan) => !plan.isArchived)

  }

  public deleteUserTrainingPlan(objectId) {
    this.orgUserTrainingPlans.splice(this.getUserTrainingPlanIndex(objectId), 1)
  }

  private getUserTrainingPlanIndex(objectId): number {
    return this.orgUserTrainingPlans.findIndex((e) => e.objectId === objectId)
  }

  @action
  public clearEditVM() {
    this.userTrainingPlanTakeVM = null
  }

  @action
  public openUserTrainingPlan(
    id: string,
    itemId: string = '',
    quizId: string = '',
    certId: string = '',
    attempts: number = 0
  ) {
    console.log('try again ' + attempts)
    if (attempts > 14) {
      this.rootStore.appStore.router.push('/userTrainingPlans/404')
      return
    }
    const userTrainingPlan = this.getUserTrainingPlan(id)
    if (!userTrainingPlan || userTrainingPlan === undefined) {
      attempts = attempts + 1

      setTimeout(() => this.openUserTrainingPlan(id, itemId, quizId, certId, attempts), 1000)
    } else {
      this.userTrainingPlanTakeVM = new UserTrainingPlanTakeVM(
        this.rootStore,
        userTrainingPlan.clone(),
        userTrainingPlan.isReadonly ? userTrainingPlan.isReadonly : false,
        itemId,
        quizId,
        certId
      )
    }
  }

  @action
  public openUserTrainingPlanToView(id: string, attempts: number = 0) {
    if (attempts > 10) console.log('Error finding training plan to open')
    const userTrainingPlan = this.getUserTrainingPlan(id)
    if (!userTrainingPlan || userTrainingPlan === undefined) {
      setTimeout(() => this.openUserTrainingPlanToView(id, attempts++), 500)
    } else {
      this.userTrainingPlanTakeVM = new UserTrainingPlanTakeVM(
        this.rootStore,
        userTrainingPlan.clone(),
        true
      )
    }
  }

  @action
  public goBackToDashboard() {
    this.rootStore.appStore.router.push('/dashboard/UserDashboard/')
  }
}
