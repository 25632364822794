import React from 'react'
import { inject, observer } from 'mobx-react'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import { PulseCampaignsStore } from '../../store/PulseCampaignsStore'
import LocalizationStore from '../../../localization/LocalizationStore'
import './PulseCampaignEditOptions.scss'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import DeleteIcon from '@material-ui/icons/Delete'
import MuiConfirmDeleteDialog from '../../../shared/MuiConfirmDeleteDialog'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import MuiConfirmPrompt from '../../../shared/MuiConfirmPrompt'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'

interface Props {
  pulseCampaignsStore?: PulseCampaignsStore
  localizationStore?: LocalizationStore
}

const PulseCampaignEditOptions: React.FC<Props> = ({ pulseCampaignsStore, localizationStore }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { pulseCampaign: lz } = localizationStore.lzStrings
  const vm = pulseCampaignsStore.viewModels.editVM
  if (!vm) return null

  const renderConfirmDelete = () => {
    return (
      <MuiConfirmDeleteDialog
        itemType={vm.isTemplate ? 'pulse campaign template' : 'pulse campaign'}
        itemName={vm.name}
        open={vm.showDeleteCampaignDialog}
        onConfirm={() => vm.delete()}
        onClose={() => vm.toggleDeleteCampaignDialog()}
        additionalDetails={vm.isTemplate ? lz.delete_details : ''}
      />
    )
  }

  const renderConfirmClose = () => {
    return (
      <MuiConfirmPrompt
        confirmMessage={lz.close_confirm_message}
        confirmMessageSecondary={lz.close_confirm_message_second}
        title={lz.close_pulse_campaign}
        open={vm.showCloseCampaignDialog}
        onConfirm={() => vm.close()}
        onClose={() => vm.toggleCloseCampaignDialog()}
        icon={<WarningRoundedIcon className='warning-icon' />}
      />
    )
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (value: string) => {
    setAnchorEl(null)
    if (value === 'delete') vm.toggleDeleteCampaignDialog()
    if (value === 'copy-campaign') vm.copyPulseCampaign()
    if (value === 'close-campaign') vm.toggleCloseCampaignDialog()
  }

  const renderDeleteOption = () => {
    if (vm.isNewPulseCampaign) return null
    return (
      <MenuItem
        disabled={vm.isTemplateAndDisabled || vm.isLoading}
        onClick={() => handleClose('delete')}
      >
        <DeleteIcon className='icon' />
        {`${lz.delete} ${vm.isTemplate ? lz.template : lz.campaign}`}
      </MenuItem>
    )
  }

  const renderCopyButton = () => {
    if (!vm.showCopyPulseCampaignButton) return null
    return (
      <MenuItem disabled={vm.isLoading} onClick={() => handleClose('copy-campaign')}>
        <FileCopyIcon className='icon' />
        {`${lz.copy} ${vm.isTemplate ? lz.template : lz.campaign}`}
      </MenuItem>
    )
  }

  const renderCloseButton = () => {
    if (!vm.showClosePulseCampaignButton) return null
    return (
      <MenuItem disabled={vm.isLoading} onClick={() => handleClose('close-campaign')}>
        <HighlightOffRoundedIcon className='icon' />
        {`Close ${lz.campaign}`}
      </MenuItem>
    )
  }

  const renderMenu = () => {
    if (!vm.shouldShowTopMenu) return
    return (
      <div id='CampaignEditOptions'>
        <IconButton aria-controls='simple-menu' aria-haspopup='true' onClick={handleClick}>
          <MoreHorizIcon />
        </IconButton>
        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          style={{ marginTop: 40 }}
        >
          {renderCopyButton()}
          {renderCloseButton()}
          {renderDeleteOption()}
        </Menu>
      </div>
    )
  }

  return (
    <>
      {renderMenu()}
      {renderConfirmDelete()}
      {renderConfirmClose()}
    </>
  )
}

export default inject(
  'pulseCampaignsStore',
  'localizationStore'
)(observer(PulseCampaignEditOptions))
