import React from 'react'
import { OrganizationsSAStore } from '../../store/OrganizationsSAStore'
import { inject, observer } from 'mobx-react'
import { Grid, FormControlLabel, Checkbox, InputLabel, Switch } from '@material-ui/core'
import './OrganizationEditDrawer.scss'
import LocalizationStore from '../../../localization/LocalizationStore'

interface Props {
  organizationsSAStore?: OrganizationsSAStore
  localizationStore?: LocalizationStore
}

const OrganizationAnalysis: React.FC<Props> = ({ organizationsSAStore, localizationStore }) => {
  const { editVM: vm } = organizationsSAStore
  if (!vm || vm.isNew) return null

  const renderAnalysis = () => {
    return (
      <div id='userAnalysis' className='details-paper'>
        <div style={{ marginTop: '10px', color: 'black' }}>
          <h6>Sentiment Analysis</h6>
          <Switch
            checked={vm.organization.analyzeResponse}
            onChange={() => vm.toggleAnalyzeResponse()}
            size='medium'
          />
        </div>
      </div>
    )
  }

  return (
    <div id='OrganizationLanguages'>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          {renderAnalysis()}
        </Grid>
      </Grid>
    </div>
  )
}

export default inject('organizationsSAStore')(observer(OrganizationAnalysis))
