import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { TasksStore } from '../../store/TasksStore'
import { Grid, InputLabel, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { IOption } from '../../types/Types'

interface DrawerTaskDetailsTaskTypeProps {
  localizationStore?: LocalizationStore
  tasksStore?: TasksStore
}

const DrawerTaskDetailsTaskType: React.FC<DrawerTaskDetailsTaskTypeProps> = ({
  localizationStore,
  tasksStore,
}) => {
  const { lzStrings } = localizationStore
  const { taskEditVM: VM } = tasksStore

  const handleTaskTypeChange = (event: object, option: IOption) => {
    if (option) {
      VM.setTaskTypeId(option.objectId)
    } else {
      VM.setTaskTypeId(null)
    }
  }

  return (
    <Grid item xs={6}>
      <InputLabel htmlFor='taskType'>{lzStrings.tasksManagementWidgetDrawer.task_type}</InputLabel>
      <Autocomplete
        id='taskType'
        options={VM.taskTypeOptions}
        value={VM.selectedTaskTypeOption}
        getOptionLabel={(option: IOption) => option.label}
        onChange={handleTaskTypeChange}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              className: 'autocompleteInput',
            }}
            variant='outlined'
            fullWidth
            required
            // error={VM.disciplineError}
          />
        )}
      />
    </Grid>
  )
}

export default inject('localizationStore', 'tasksStore')(observer(DrawerTaskDetailsTaskType))
