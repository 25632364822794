import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import { AppBar, Button, CssBaseline, Link, Toolbar, Typography } from '@material-ui/core'
import clsx from 'clsx'
import isIE11 from 'src/utils/isIE11'
import SurveyTypeEditVM from '../../store/view-models/SurveyTypeEditVM'
import DeleteIcon from '@material-ui/icons/Delete'

interface Props {
  vm: SurveyTypeEditVM
  localizationStore?: LocalizationStore
}

const SAEditTopToolbar: React.FC<Props> = ({ vm, localizationStore }) => {
  if (!vm) return null
  const lz = localizationStore.lzStrings.pulseCampaign

  const renderSaveButton = () => {
    return (
      <Button
        disabled={vm.isSaving}
        variant='contained'
        onClick={() => vm.saveSurveyType()}
        className='dashboard-action'
      >
        {lz.save}
      </Button>
    )
  }
  const renderDeleteButton = () => {
    if (vm.isNew) return null
    return (
      <Button  disabled={vm.isSaving} className='delete-btn' variant='contained' onClick={() => vm.toggleDeleteDialog()}>
        <DeleteIcon />
        {'Delete'}
      </Button>
    )
  }

  const renderBreadcrumbs = () => {
    return (
      <>
        <Link onClick={() => vm.navigateBackToMainScreen()} underline='always'>
          <Typography className='dashboard-title spaced back'>{`Manage Survey Types`}</Typography>
        </Link>
        <Typography className='dashboard-title spaced'>{' > '}</Typography>
        <Typography className='dashboard-title spaced'>{'Edit'}</Typography>
      </>
    )
  }

  return (
    <div id='DashboardToolbar'>
      <CssBaseline />
      <AppBar
        className={clsx(
          'dashboard-appbar',
          {
            'no-transition': isIE11,
          },
          vm.screenType === 'systemManage' ? 'strong' : ''
        )}
        elevation={0}
      >
        <Toolbar className='dashboard-toolbar'>
          {renderBreadcrumbs()}
          <div className='dashboard-toolbar-right'>
            {renderDeleteButton()}
            {renderSaveButton()}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default inject('localizationStore')(observer(SAEditTopToolbar))
