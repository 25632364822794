import { computed, action, observable } from 'mobx'
import { IMediaDTO } from '../dtos/IMediaDTO'
import { MediaItemVM } from './MediaItemVM'
import { CMSItemsFindService } from '../../cms-items/service/CMSItemsFindService'
import { isNumeric } from '../../shared/isNumeric'
import { ICMSItemDTO } from '../../cms-items/interfaces/ICMSItemDTO'
import { MediaItemType } from '../../upload/types/MediaItemType'

export class CMSMediaItemVM extends MediaItemVM {
  private svc: CMSItemsFindService
  @observable private cmsItem: ICMSItemDTO

  constructor(rootStore, incomingMediaItem?: IMediaDTO) {
    super(rootStore, incomingMediaItem)

    this.svc = new CMSItemsFindService(rootStore)
    const cmsItemId = isNumeric(incomingMediaItem.objectId)
      ? Number(incomingMediaItem.objectId)
      : incomingMediaItem.cmsItemId

    const found = rootStore.cmsItemAttachmentStore.getCMSItem(cmsItemId)
    if (found) this.cmsItem = found
  }

  @action
  public async loadCMSItem() {
    if (this.isLoaded) return

    if (isNumeric(this.objectId)) this.setCMSItem(await this.svc.getItem(Number(this.objectId)))
    else this.setCMSItem(await this.svc.getItem(this.cmsItemId))
    return this.cmsItem
  }

  @action
  public setCMSItem(cmsItem: ICMSItemDTO) {
    this.cmsItem = cmsItem
  }

  @computed
  public get isCMSItem(): boolean {
    if (isNumeric(this.objectId)) return true
    return Boolean(this.cmsItemId)
  }

  @computed
  public get isLoaded() {
    if (!this.cmsItem) return false
    return true
  }

  @action
  public setWatchPercentage(value) {
    this.mediaWatchPercentage = value
  }

  @computed
  public get type(): MediaItemType {
    if (!this.cmsItem) return null
    return this.cmsItem.Type as MediaItemType
  }

  @computed
  public get displayName() {
    if (this.cmsItem === undefined) return ''
    return this.cmsItem.Title
  }

  @computed get uploadNameValid() {
    if (Boolean(this.uploadName)) return true
    else return false
  }

  @computed
  public get saveEnabled() {
    if (Boolean(this.uploadBase64) && this.uploadNameValid) return true
    else return false
  }

  @computed
  public get isMuxVideo(): boolean {
    if (!this.cmsItem) return false
    return Boolean(this.cmsItem?.Optimized_Video?.id)
  }

  @computed
  public get hasYouTubeURL(): boolean {
    if (!this.cmsItem) return false
    const url = this.url.toLowerCase()
    return url.includes('youtube') || url.includes('youtu.be')
  }

  @computed
  public get hasVimeoURL(): boolean {
    if (!this.cmsItem) return false
    const url = this.url.toLowerCase()
    return url.includes('vimeo')
  }

  @computed
  public get youTubeId(): string {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
    const match = this.url.match(regExp)
    return match && match[2].length === 11 ? match[2] : null
  }

  @computed
  public get vimeoId(): string {
    let regExp =
      /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/
    if (this.url.includes('player.vimeo.com')) {
      regExp =
        /(http|https)?:\/\/(www\.)?player.vimeo.com\/(?:video\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/
    }
    const match = this.url.match(regExp)
    return match && match.length ? match[4] : ''
  }

  @computed
  public get thumbnailUrl(): string {
    if (!this.cmsItem) return undefined
    if (this.isMuxVideo) return this.cmsItem.Optimized_Video.playback_id
    if (this.cmsItem.External_Video) return this.cmsItem.External_Video.thumbnailUrl

    let url = ''
    const file = this.cmsItem.File
    if (file && file.formats) {
      url = file.formats.small?.url
      if (!url) url = file.formats.thumbnail?.url
      if (!url) url = file.url
    } else url = file.url

    if (!url) return ''
    return url
  }

  @computed
  public get url(): string {
    if (!this.cmsItem) return undefined
    if (this.cmsItem.External_Video) return this.cmsItem.External_Video.url

    let url = ''
    const file = this.cmsItem.File
    if (file && file.formats) {
      url = file.formats.large?.url
      if (!url) url = file.formats.medium?.url
      if (!url) url = file.formats.small?.url
      if (!url) url = file.formats.thumbnail?.url
      if (!url) url = file.url
    } else url = file.url

    if (!url) return ''
    return url
  }

  public toDTO(): IMediaDTO {
    return {
      objectId: this.objectId,
      type: this.type,
      name: this.name,
      path: this.path,
      mediaWatchPercentage: this.mediaWatchPercentage,
    }
  }
}
