import { observable, action } from 'mobx'
import { Role } from './Role'
import { Group } from './Group'
import { IUserInfoOrganizationResult } from '../interfaces/IUserInfoResult'

export default class Organization {
  public static FromResult(orgResult: IUserInfoOrganizationResult): Organization {
    const org = new Organization()
    org.objectId = orgResult.organizationId
    org.name = orgResult.name
    org.theme = ''
    if (orgResult.theme && orgResult.theme !== '') {
      org.theme = orgResult.theme
    }
    org.hasConsented = orgResult.hasConsented
    org.oidcClientId = orgResult.oidcClientId
    org.oidcConnectUrl = orgResult.oidcConnectUrl
    org.oidcProvider = orgResult.oidcProvider
    org.requireOIDCAuth = orgResult.requireOIDCAuth
    org.bypassOIDCAuth = orgResult.bypassOIDCAuth
    org.orgUserId = orgResult.orgUserId
    org.isOrgAdmin = orgResult.isOrgAdmin
    org.isConsentRequiredForLogin = orgResult.isConsentRequiredForLogin
    org.roles = orgResult.roles && orgResult.roles.map((e) => Role.create(e))
    org.groups = orgResult.groups && orgResult.groups.map((e) => Group.create(e))
    org.subordinates = orgResult.subordinates
    org.privileges = orgResult.privileges
    org.mobileAppId = orgResult.mobileAppId
    org.disableOldAudience = orgResult.disableOldAudience
    return org
  }

  @observable objectId: string = ''
  @observable name: string = ''
  @observable public hasConsented: boolean = false
  @observable public privileges: string[] = []
  @observable roles: Array<Role> = []
  @observable groups: Array<Group> = []
  @observable orgUserId: string = ''
  @observable isConsentRequiredForLogin: boolean = false
  @observable theme: string = ''
  @observable isDeleted: boolean = false
  @observable mobileAppId: string = ''
  @observable requireOIDCAuth: boolean = false
  @observable oidcConnectUrl: string = ''
  @observable oidcClientId: string = ''
  @observable oidcProvider: string = ''
  @observable bypassOIDCAuth: boolean = false
  @observable public subordinates: string[] = []
  @observable isOrgAdmin: boolean = false
  isOnServer: boolean = false
  @observable public disableOldAudience: boolean = false

  @action
  public markIsNotOnServer() {
    this.isOnServer = false
  }

  public updateFromServer(orgUser) {
    // this.roles = []
    // if (orgUser.roles)
    //   Object.keys(orgUser.roles).forEach((roleId) => {
    //     const foundRole = rootStore.rolesStore.currentOrgRoles.find(
    //       (role) => role.objectId === roleId
    //     )
    //     if (foundRole)
    //       this.roles.push(
    //         Role.create({
    //           id: roleId,
    //           type: 'role',
    //           name: foundRole && foundRole.name ? foundRole.name : undefined,
    //           privilegeSetIds:
    //             foundRole && foundRole.privilegeSets ? foundRole.privilegeSets : undefined,
    //         })
    //       )
    //   })
    // this.groups = []
    // if (orgUser.groups)
    //   Object.keys(orgUser.groups).forEach((groupId) => {
    //     const foundGroup = rootStore.groupsStore.currentOrgGroups.find(
    //       (group) => group.objectId === groupId
    //     )
    //     if (foundGroup)
    //       this.groups.push(
    //         Group.create({
    //           id: groupId,
    //           type: 'group',
    //           name: foundGroup && foundGroup.name ? foundGroup.name : undefined,
    //           privilegeSetIds:
    //             foundGroup && foundGroup.privilegeSets ? foundGroup.privilegeSets : undefined,
    //         })
    //       )
    //   })
    // const allPrivilegeSets = []
    // if (Array.isArray(orgUser.privilegeSets)) allPrivilegeSets.push(...orgUser.privilegeSets)
    // this.roles.forEach((role) => {
    //   if (role.privilegeSetIds) allPrivilegeSets.push(...role.privilegeSetIds)
    // })
    // this.groups.forEach((group) => {
    //   if (group.privilegeSetIds) allPrivilegeSets.push(...group.privilegeSetIds)
    // })
    // this.privilegeSets = Array.from(new Set(allPrivilegeSets))
  }
}
