import { RootStore } from '../../stores/RootStore'
import { action, observable } from 'mobx'
import { SurveyTemplatesService } from '../services/SurveyTemplatesService'
import { ISurveyTemplatesImportRequest } from '../interfaces/ISurveyTemplatesImportRequest'
import { ISurveyTemplatesImportResult } from '../interfaces/ISurveyTemplatesImportResult'
import { ISurveyTemplateDTO } from '../dtos/ISurveyTemplateDTO'

export class SurveyTemplatesImportVM {
  private rootStore: RootStore
  private svc: SurveyTemplatesService

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.svc = new SurveyTemplatesService(this.rootStore)
  }

  @observable public surveyTemplateIds: string = ''
  @observable public newTemplates = []
  @observable public importLoading = false
  @observable public errorMessage: string

  @action
  public setSurveyTemplateIds(val: string) {
    this.surveyTemplateIds = val
  }

  @action
  public setErrorMessage(msg) {
    if (!msg) return
    this.errorMessage = msg
  }

  @action
  public async importSurvey() {
    this.newTemplates = []
    this.importLoading = true
    let sanitizedStr = this.surveyTemplateIds.split(`\n`)
    let importerUserId = this.rootStore.appStore.currentUserId
    const req: ISurveyTemplatesImportRequest = {
      objectIds: sanitizedStr,
      userId: importerUserId,
      organizationId: this.rootStore.appStore.currentOrgId
    }
    const result: ISurveyTemplatesImportResult = await this.svc.importSurveyTemplate(req)
    if (result.success) {
      result.surveyTemplates.forEach((e: ISurveyTemplateDTO) => {
        this.newTemplates.push(e.name)
      })
    }
    else {
      this.setErrorMessage(result.errorMessage)
    }
    this.importLoading = false
    this.setSurveyTemplateIds('')
  }

  @action
  public navigateToSurveyTemplatesScreen() {
    this.rootStore.appStore.router.push(`/systemAdmin/manage/surveyTemplates`)
  }
}
