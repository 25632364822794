import React from 'react'
import { RootStore } from "../../../../stores/RootStore"
import { inject, observer, useLocalStore } from 'mobx-react'
import { PulseCampaignEditVM } from '../../../view-models/PulseCampaignEditVM'
import './CampaignSurveyTemplateSelect.scss'
import SurveyTemplatesSelect from '../../../../survey-templates/views/manageV2/template-select/SurveyTemplatesSelect'


interface Props {
  rootStore?: RootStore
  editVM: PulseCampaignEditVM
}

const CampaignSurveyTemplateSelect: React.FC<Props> = ({ rootStore, editVM }) => {

  return (
    <div id='CampaignSurveyTemplateSelect'>
      <SurveyTemplatesSelect editVM={editVM}/>
    </div>
  )
}

export default inject('rootStore')(observer(CampaignSurveyTemplateSelect))
