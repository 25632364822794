import React, { FC } from 'react'
import { inject, observer } from 'mobx-react'
import { Typography } from '@material-ui/core'
import { PasswordStrengthMeterVM } from './PasswordStrengthMeterVM'
import './PasswordStrengthMeter.scss'

interface Props {
  vm: PasswordStrengthMeterVM
  isVisible?: boolean
  showScore?: boolean
}

const PasswordStrengthMeter: FC<Props> = ({ vm, isVisible = true, showScore = false }) => {
  const calculateStrengthScore = () => {
    return (vm.newPasswordStrengthScore / 5) * 100
  }

  const renderPasswordStrengthScore = () => {
    if (!isVisible) return
    if (!showScore) return
    const score = calculateStrengthScore()
    return (
      <Typography className='password-strength-text'>{`Password Strength: ${
        score > 0 && score < 50 ? '<' : ''
      } ${score}%`}</Typography>
    )
  }

  const renderPasswordStrengthMeter = () => {
    if (!isVisible) return
    return (
      <div>
        <div className='password-strength-meter' />
        <style>
          {`.password-strength-meter::before {
                    content: "";
                    background-color: ${
                      ['red', 'orange', '#03a2cc', '#03a2cc', 'green'][
                        vm.newPasswordStrengthScore - 1
                      ] || ''
                    };
                    height: 100%;
                    width: ${calculateStrengthScore()}%;
                    display: block;
                    border-radius: 3px;
                    transition: width 0.2s;
                }`}
        </style>
      </div>
    )
  }

  if (!isVisible) return
  return (
    <div id='PasswordStrengthMeter'>
      {renderPasswordStrengthScore()}
      {renderPasswordStrengthMeter()}
    </div>
  )
}

export default inject(
  'organizationUsersStore',
  'localizationStore'
)(observer(PasswordStrengthMeter))
