import React from 'react'
import { inject, observer } from 'mobx-react'
import Dropzone from 'react-dropzone'
import CloseIcon from '@material-ui/icons/Close'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  DialogActions,
  Button,
  CircularProgress,
  LinearProgress,
} from '@material-ui/core'
import VirtualList from 'react-tiny-virtual-list'
import ErrorRow from './ErrorRow'
import { ContactsStore } from '../store/ContactsStore'
import ContactImportRow from './ContactImportRow'
import LocalizationStore from 'src/app/localization/LocalizationStore'

interface Props {
  contactsStore?: ContactsStore
  localizationStore?: LocalizationStore
}

const ContactsImportModal: React.FC<Props> = ({ contactsStore, localizationStore }) => {
  const { contactListWidgetVM } = contactsStore
  if (!contactListWidgetVM) return null
  const { lzStrings } = localizationStore

  const { importVM: vm } = contactListWidgetVM
  if (!vm) return null

  const renderContactImportVirtualList = () => {
    if (vm.contactRows.length === 0) return null
    return (
      <VirtualList
        className='contact-import-list'
        height={400}
        width='100%'
        itemCount={vm.contactRows.length}
        itemSize={(index) => vm.contactRows[index].height}
        renderItem={({ style, index }) => {
          const row = vm.contactRows[index]
          return <ContactImportRow key={'usr' + row.key} row={row} style={style} />
        }}
      />
    )
  }

  const renderErrors = () => {
    if (!vm.hasErrors) return null
    return vm.errorRows.map((e, idx) => <ErrorRow key={'er' + idx} row={e} />)
  }

  const renderDropZone = () => {
    if (!vm.showDropZone) return null
    return (
      <Grid container className='dialog-content'>
        <DialogContent>
          {!vm.file ? (
            <Dropzone
              onDrop={(file) => vm.handleFileDrop(file)}
              accept='.csv,text/csv'
              multiple={false}
              minSize={0}
              maxSize={5242880}
            >
              {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                <section>
                  <div className='dropzone' {...getRootProps()}>
                    <input {...getInputProps()} />

                    {!isDragActive && (
                      <div className='iconContainer'>
                        <NoteAddIcon className='noteAddIcon' />
                        <p className='dropzoneText'>
                          {lzStrings.contact_import.drag_and_drop}<span className='dropLink'>{lzStrings.contact_import.browse}</span>{lzStrings.contact_import.your_file}
                        </p>
                      </div>
                    )}

                    {isDragActive && !isDragReject && (
                      <div className='iconContainer'>
                        <ThumbUpIcon className='thumbUpIcon' />
                        <p className='dropzoneText'>{lzStrings.contact_import.looks_good}</p>
                      </div>
                    )}

                    {isDragActive && isDragReject && (
                      <div className='iconContainer'>
                        <ThumbDownIcon className='thumbDownIcon' />
                        <p className='dropzoneText'>{lzStrings.contact_import.not_good}</p>
                      </div>
                    )}
                  </div>
                </section>
              )}
            </Dropzone>
          ) : (
              <Dropzone
                onDrop={(file) => vm.handleFileDrop(file)}
                accept='.csv,text/csv'
                multiple={false}
                minSize={0}
                maxSize={5242880}
              >
                {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                  <section>
                    <div className='dropzone' {...getRootProps()}>
                      <input {...getInputProps()} />

                      {!isDragActive && (
                        <div className='iconContainer'>
                          <ThumbUpIcon className='thumbUpIcon' />
                          <p className='dropzoneText'>{vm.file.name}</p>
                          <p className='dropzoneText'>{lzStrings.contact_import.looks_good}</p>
                        </div>
                      )}

                      {isDragActive && !isDragReject && (
                        <div className='iconContainer'>
                          <ThumbUpIcon className='thumbUpIcon' />
                          <p className='dropzoneText'>{lzStrings.contact_import.looks_good}</p>
                        </div>
                      )}

                      {isDragActive && isDragReject && (
                        <div className='iconContainer'>
                          <ThumbDownIcon className='thumbDownIcon' />
                          <p className='dropzoneText'>{lzStrings.contact_import.not_good}</p>
                        </div>
                      )}
                    </div>
                  </section>
                )}
              </Dropzone>
            )}
          <DialogContentText className='dialogText'> {lzStrings.contact_import.acceptable_formats}: csv </DialogContentText>
        </DialogContent>
      </Grid>
    )
  }

  const renderDropZoneNotProcessing = () => {
    if (!vm.showSpinner) return null
    return (
      <Grid container className='dialog-content'>
        <DialogContent>
          <div className='dropzone'>
            <div className='iconContainer'>
              <CircularProgress className='progressCircle' />
              <p className='dropzoneText'>{lzStrings.contact_import.processing}</p>
            </div>
          </div>
        </DialogContent>
      </Grid>
    )
  }

  const renderLinearProgress = () => {
    if (!vm.importProcessing) return null
    if (!vm.importProgress) return null
    return <LinearProgress variant='determinate' value={vm.importProgress} />
  }

  return (
    <div id='ContactsImportModal'>
      <Dialog onClose={() => contactListWidgetVM.toggleImportModal()} open={contactListWidgetVM.showImportModal} fullWidth>
        <Grid
          className='dialog-title'
          container
          justifyContent='space-between'
          alignItems='center'
          style={{ paddingRight: '10px' }}
        >
          <Grid item>
            <DialogTitle>{lzStrings.contact_import.file_upload}</DialogTitle>
          </Grid>
          <Grid item>
            <IconButton onClick={() => contactListWidgetVM.toggleImportModal()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        {renderLinearProgress()}
        <Grid className={vm.contactRows.length > 0 ? 'dialog-body' : ''}>
          {renderErrors()}
          {renderContactImportVirtualList()}
          {renderDropZone()}
          {renderDropZoneNotProcessing()}
        </Grid>
        <DialogActions>
          <Grid container className='dialog-actions' alignItems='center' justifyContent='space-between'>
            <Grid item>
              <Button
                variant='outlined'
                size='large'
                onClick={() => vm.downloadTemplate()}
                className='downloadButton'
              >
                {lzStrings.importModal.downloadTemplate}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant='text'
                size='large'
                onClick={() => contactListWidgetVM.toggleImportModal()}
                disabled={vm.importProcessing}
                className='cancelButton'
              >
                {lzStrings.contact_import.cancel}
              </Button>
              <Button
                variant='contained'
                size='large'
                onClick={() => vm.validate()}
                disabled={!vm.validateEnabled}
                className='saveButton'
              >
                {lzStrings.contact_import.validate}
              </Button>
              <Button
                variant='contained'
                size='large'
                onClick={() => vm.acceptChanges()}
                disabled={!vm.allowAcceptChanges}
                className='saveButton'
              >
                {lzStrings.contact_import.accept_changes}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default inject('contactsStore', 'localizationStore')(observer(ContactsImportModal))
