import { observable } from 'mobx'
import { ResponseType } from '../types/ResponseType'
import { IOptionDTO } from '../dtos/IOptionDTO'
import { serializable, serialize, deserialize, list, object } from 'serializr'

export class Option implements IOptionDTO {
  @serializable @observable public id: string = ''
  @serializable @observable public text: string = ''
  @serializable @observable public emoji: string = ''
  @serializable @observable public rank: number = 0
  @serializable @observable public value: number = 0
  @serializable @observable public isPositive: boolean = null
  @serializable @observable public isDeleted: boolean = false
  @serializable @observable public weight: number = 0
  @serializable @observable public responseType: ResponseType = 'neutral'
}
