import React from 'react'
import { inject, observer } from 'mobx-react'
import {
  Grid,
  TextField,
  Typography,
  Button,
  Select,
  MenuItem,
  Checkbox,
} from '@material-ui/core'
import { CustomCollectionsStore } from '../../store/CustomCollectionStore'
import './FieldsTab.scss'
import { FieldEditVM } from '../../view-models/edit/FieldEditVM'
import CustomCollectionImportModal from '../import/CustomCollectionImportModal'
import ErrorRow from '../import/ErrorRow'
import { ErrorRowVM } from '../../view-models/import/ErrorRowVM'
import InfoTooltip from 'src/app/shared/InfoTooltip'

interface Props {
  customCollectionsStore?: CustomCollectionsStore
}

const FieldsTab: React.FC<Props> = ({ customCollectionsStore }) => {
  const { editVM: vm } = customCollectionsStore
  if (!vm) return null

  const renderTitleBar = () => {
    return (
      <Grid container className='title-bar'>
        <Grid item xs={2} className='flex-align'>
          <Typography>Name</Typography>
          <InfoTooltip message="The display name of this field wherever this data is used."/>
        </Grid>
        <Grid item xs={2} className='flex-align'>
          <Typography>Data Type</Typography>
          <InfoTooltip message="The type of data that will be required for this field"/>
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'center' }} className='flex-align'>
          <Typography>Key Field</Typography>
          <InfoTooltip message="If checked, this will be a required field. The combination of all key fields for each row must be unique. These fields can only be set during creation of a custom collection"/>
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'center' }} className='flex-align'>
          Value Fact Date
          <InfoTooltip message="This field indicates the date the record was captured. There can only be one. A value fact date field can only be set during creation of a custom collection"/>
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'center' }} className='flex-align'>
          Facet
          <InfoTooltip message="This is a single piece of data used for features like goals"/>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    )
  }

  const debounce = (fn, delay) => {
    let timer
    return () => {
      clearTimeout(timer)
      timer = setTimeout(function () {
        timer = null
        fn.apply(this, arguments)
      }, delay)
    }
  }

  const renderField = (field: FieldEditVM, id) => {
    return (
      <Grid container className='field-row' key={'fldrow' + id}>
        <Grid item xs={2}>
          <TextField
            disabled={vm.isNew ? false : !field.isNew}
            variant='outlined'
            value={field.displayName}
            fullWidth
            onChange={(e) => field.setDisplayName(e.target.value)}
            error={!field.displayName}
          />
        </Grid>
        <Grid item xs={2}>
          <Select
            disabled={vm.isNew ? false : !field.isNew}
            variant='outlined'
            value={field.type}
            fullWidth
            onChange={(e) => field.setType(e.target.value)}
          >
            <MenuItem value={'String'}>String</MenuItem>
            <MenuItem value={'Boolean'}>Boolean</MenuItem>
            <MenuItem value={'Number'}>Number</MenuItem>
            <MenuItem value={'Date'}>Date</MenuItem>
            <MenuItem value={'User'}>User</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'center' }}>
          <Checkbox
            disabled={!vm.isNew}
            checked={field.isCustomRecordKey}
            onChange={() => field.toggleIsCustomRecordKey()}
          />
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'center' }}>
          <Checkbox 
            disabled={!vm.isNew || field.type !== 'Date'}
            checked={field.isValueFactDate}
            onChange={() => field.toggleIsValueFactDate()} 
          />
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'center' }}>
          <Checkbox 
            disabled={!field.isNew || field.type !== 'Number'}
            checked={field.isFacet} 
            onChange={() => field.toggleIsFacet()} 
          />
        </Grid>
        {field.isNew && (
          <Grid item xs={2}>
            &nbsp;&nbsp;&nbsp;
            <div className='fa fa-times remove-icon' onClick={() => field.delete()} />
          </Grid>
        )}
      </Grid>
    )
  }

  const renderFields = () => {
    return vm.fields.map((field, index) => renderField(field, index))
  }

  const renderAddField = () => {
    return (
      <Grid container alignContent='center' justifyContent='flex-start'>
        <Grid item className='fields-actions'>
          <Button variant='outlined' onClick={() => vm.addField()}>
            Add Field
          </Button>
        </Grid>
        {renderImportButton()}
      </Grid>

    )
  }

  const renderImportButton = () => {
    if (!vm.isNew) return null
    return (
      <Grid item>
        <Button variant='outlined' onClick={() => vm.openImportModal()}>
          Import Field Definitions
        </Button>
      </Grid>
    )
  }

  const renderError = () => {
    let errors = null
    if (vm.invalidReasons.length) {
      errors = vm.invalidReasons
      vm.unexpectedErrors = []
    }
    else if (vm.unexpectedErrors.length) errors = vm.unexpectedErrors
    
    if (!errors) return null
    return (
      <>
      {errors.map((e, idx) => (
        <ErrorRow
          key={'er' + idx}
          row={new ErrorRowVM(null, e, 0, true)}
        />
      ))}
      </>
    )
  }

  const renderWarning = () => {
    if (vm.hasTimeSeriesFields) return null

    return (
      <ErrorRow 
        key='missingTimeSeries'
        row={new ErrorRowVM(
          null,
          `A 'User' field AND a 'Value Fact Date' field are required
            for data to be loaded into RippleWorx Time Series Metric tables`,
          0,
          false
          )} 
      />
    )
  }

  return (
    <div id='FieldsTab'>
      {renderTitleBar()}
      {renderFields()}
      {renderError()}
      {renderWarning()}
      {renderAddField()}
      <CustomCollectionImportModal vm={vm.importModalVM} />
    </div>
  )
}

export default inject('customCollectionsStore')(observer(FieldsTab))
