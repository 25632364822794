import React from 'react'
import { inject, observer } from 'mobx-react'
import { SurveysStore } from '../../surveys/store/SurveysStore'
import './SurveyParticipantsList.scss'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import { AgGridReact } from 'ag-grid-react'
import { Typography, CircularProgress } from '@material-ui/core'

interface Props {
  surveysStore?: SurveysStore
  localizationStore?: LocalizationStore
}

const SurveyParticipantsList: React.FC<Props> = ({ surveysStore, localizationStore }) => {
  const { editVM } = surveysStore.viewModels
  if (!editVM) return null
  const vm = editVM.surveyParticipantsList
  if (!vm) return
  const s = localizationStore.lzStrings

  const renderGrid = () => {
    return (
      <div
        className={vm.hasRows ? 'ag-theme-alpine' : 'ag-theme-alpine ag-grid-no-invite-rows'}
        style={{ height: '100%', width: '100%' }}
      >
        <AgGridReact
          key={'svylist_' + editVM.objectId}
          gridOptions={{
            ...vm.getGridOptions(),
          }}
        />
      </div>
    )
  }

  const renderNoRowsContent = () => {
    if (vm.hasRows) return
    if (vm.loadingFirstPage) return
    return (
      <Typography component='div' className='no-rows'>
        No Rows To Show
      </Typography>
    )
  }

  const renderLoading = () => {
    if (!vm.loadingFirstPage) return
    return (
      <Typography component='div' className='no-rows'>
        Loading...
        <CircularProgress className='progressCircle' />
      </Typography>
    )
  }

  return (
    <div id='SurveyParticipantsList' className='surveyParticipantsList'>
      {renderGrid()}
      {renderNoRowsContent()}
      {renderLoading()}
    </div>
  )
}

export default inject('surveysStore', 'localizationStore')(observer(SurveyParticipantsList))
