import React from 'react'
import { observer } from 'mobx-react'
import rootStore from '../../../../stores/RootStore'
import UserTrainingPlanTakeWidget from './UserTrainingPlanTakeWidget'
import { Grid } from '@material-ui/core'
import './UserTrainingPlanTakeScreen.scss'
import UserTrainingPlanCompleted from './UserTrainingPlanCompleted'

class UserTrainingPlanTakeScreen extends React.Component<any, any> {
  constructor(props) {
    super(props)

    const { location, params } = props
    if (
      location &&
      typeof location.pathname === 'string' &&
      location.pathname.toLowerCase().includes('view')
    ) {
      rootStore.userTrainingPlansStore.clearEditVM()
      rootStore.userTrainingPlansStore.openUserTrainingPlanToView(params.id)
    } else if (
      location &&
      typeof location.pathname === 'string' &&
      location.pathname.toLowerCase().includes('take') &&
      !params.trainingItemId &&
      !params.quizId &&
      !params.certId
    ) {
      rootStore.userTrainingPlansStore.clearEditVM()
      rootStore.userTrainingPlansStore.openUserTrainingPlan(params.id)
    } else if (
      location &&
      typeof location.pathname === 'string' &&
      location.pathname.toLowerCase().includes('take') &&
      location.pathname.toLowerCase().includes('foritem') &&
      params.trainingItemId
    ) {
      rootStore.userTrainingPlansStore.clearEditVM()
      rootStore.userTrainingPlansStore.openUserTrainingPlan(params.id, params.trainingItemId)
    } else if (
      location &&
      typeof location.pathname === 'string' &&
      location.pathname.toLowerCase().includes('take') &&
      location.pathname.toLowerCase().includes('forquiz') &&
      params.quizId
    ) {
      rootStore.userTrainingPlansStore.clearEditVM()
      rootStore.userTrainingPlansStore.openUserTrainingPlan(params.id, '', params.quizId)
    } else if (
      location &&
      typeof location.pathname === 'string' &&
      location.pathname.toLowerCase().includes('take') &&
      location.pathname.toLowerCase().includes('forcert') &&
      params.certId
    ) {
      rootStore.userTrainingPlansStore.clearEditVM()
      rootStore.userTrainingPlansStore.openUserTrainingPlan(params.id, '', '', params.certId)
    }
  }

  componentDidUpdate(props) {
    if (props.params.id) {
      if (
        rootStore.userTrainingPlansStore.userTrainingPlanTakeVM &&
        props.params.id !==
          rootStore.userTrainingPlansStore.userTrainingPlanTakeVM.userTrainingPlan.objectId
      )
        setTimeout(
          () => rootStore.userTrainingPlansStore.openUserTrainingPlan(props.params.id),
          1000
        )
    }
  }

  routerWillLeave(nextLocation) {
    if (!rootStore.userTrainingPlansStore.userTrainingPlanTakeVM) return
    const takeVM = rootStore.userTrainingPlansStore.userTrainingPlanTakeVM
    if (takeVM.planIsComplete) return
    takeVM.save()
  }

  componentDidMount() {
    this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave.bind(this))
  }

  render() {
    return (
      <Grid container id='UserTrainingPlanTakeScreen'>
        <UserTrainingPlanTakeWidget
          takeVM={rootStore.userTrainingPlansStore.userTrainingPlanTakeVM}
        />
        <UserTrainingPlanCompleted />
      </Grid>
    )
  }
}

export default observer(UserTrainingPlanTakeScreen)
