import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../localization/LocalizationStore'
import { SurveyTemplateFoldersPickerVM } from '../view-models/SurveyTemplateFoldersPickerVM'
import TreeView from '@material-ui/lab/TreeView'
import TreeItem from '@material-ui/lab/TreeItem'
import FolderIcon from '@material-ui/icons/Folder'
import FolderOpenIcon from '@material-ui/icons/FolderOpen'
import PlusSquare from '@material-ui/icons/AddBoxOutlined'
import MinusSquare from '@material-ui/icons/IndeterminateCheckBoxOutlined'
import CloseSquare from '@material-ui/icons/CloseOutlined'

import { Button, IconButton, TextField, Tooltip, Checkbox } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import './SurveyTemplateFoldersPicker.scss'
import SurveyTemplateFolder from './SurveyTemplateFolder'
import SurveyTemplateFolderEdit from './SurveyTemplateFolderEdit'
import MuiConfirmDeleteDialog from '../../shared/MuiConfirmDeleteDialog'
import MDSpinner from 'react-md-spinner'

interface Props {
  localizationStore?: LocalizationStore
  vm: SurveyTemplateFoldersPickerVM
}

const SurveyTemplateFoldersPicker: React.FC<Props> = ({ vm }) => {
  const renderAddNewFolderBtn = () => {
    if (vm.manageVM.screenType === 'tenantManage') {
      if (vm.isSystemToggleMode) return null
    }
    if (!vm.showFolderActions) return null
    if (vm.folderEditVM?.objectId === '' && vm.folderEditVM?.parentFolderId === '') return null
    return (
      <div className={'folder-header'}>
        <React.Fragment>
          <Tooltip title={'Add New Folder'} placement='bottom'>
            <IconButton className='widget-cta' size='small' onClick={() => vm.editFolder('new')}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </React.Fragment>
      </div>
    )
  }

  const renderRoots = () => {
    const treeItems = []
    vm.data.forEach((e, i) => {
      treeItems.push(<SurveyTemplateFolder key={i} nodes={e} vm={vm} />)
    })
    return treeItems
  }

  const renderFolderEdit = () => {
    if (!vm.showFolderActions) return null
    if (vm.folderEditVM?.objectId !== '') return null
    if (vm.folderEditVM?.parentFolderId) return null
    return <SurveyTemplateFolderEdit vm={vm.folderEditVM} />
  }

  const renderConfirmDelete = () => {
    if (!vm.showDeleteDialog) return null
    return (
      <MuiConfirmDeleteDialog
        onClose={() => vm.toggleDeleteDialog()}
        open={vm.showDeleteDialog}
        itemName={vm.selectedFolder.name}
        itemType={'Folder'}
        onConfirm={() => vm.deleteFolder()}
        additionalDetails={'Note: This action is permanent.'}
      />
    )
  }

  const renderTree = () => {
    if (!vm.isLoaded) return null
    return (
      <TreeView
        // className={classes.root}
        defaultExpandIcon={<PlusSquare />}
        defaultCollapseIcon={<MinusSquare />}
        defaultEndIcon={<FolderOpenIcon />}
        defaultSelected={['no-folder']}
        expanded={vm.defaultExpanded}
      >
        {renderRoots()}
      </TreeView>
    )
  }

  const renderSpinner = () => {
    if (vm.isLoaded) return null
    return (
      <div className='folder-spinner'>
        <MDSpinner size={40} singleColor='#05d1cf' />
      </div>
    )
  }

  return (
    <div id='SurveyTemplateFoldersPicker'>
      {renderConfirmDelete()}
      {renderFolderEdit()}
      {renderAddNewFolderBtn()}
      <div className='picker-modal-content'>
        {renderSpinner()}
        {renderTree()}
        </div>
    </div>
  )
}

export default inject('localizationStore')(observer(SurveyTemplateFoldersPicker))
