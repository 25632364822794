import { action, observable } from 'mobx'
import { Alarm } from './Alarm'
import { serializable, list, object } from 'serializr'
import { RootStore } from '../../stores/RootStore'

export class Alarms {
  static create(al: any) {
    const alarms = new Alarms()
    alarms.enabled = al.enabled
    alarms.selectedAlarms = al.selectedAlarms.map((e) => Alarm.create(e))
    alarms.sendReminders15BeforeDueDate = al.sendReminders15BeforeDueDate
    alarms.sendRemindersOnDueDate = al.sendRemindersOnDueDate
    alarms.sendRemindersCustomPeriod = al.sendRemindersCustomPeriod
    alarms.sendRemindersSpecificDate = al.sendRemindersSpecificDate
    return alarms
  }

  @serializable @observable enabled: boolean = false
  @serializable(list(object(Alarm))) @observable selectedAlarms: Array<Alarm> = []
  @serializable @observable sendRemindersOnDueDate: boolean = false
  @serializable @observable sendReminders15BeforeDueDate: boolean = false
  @serializable @observable sendRemindersCustomPeriod: boolean = false
  @serializable @observable sendRemindersSpecificDate: boolean = false

  @action
  public toggleSendReminders() {
    this.enabled = !this.enabled
    if (!this.enabled) this.clearAllAlarms()
  }

  @action
  public setDefaultReminders() {
    this.toggleSendRemindersOnDueDate()
    this.toggleSendReminders15BeforeDueDate()
  }

  @action
  public toggleSendRemindersOnDueDate() {
    this.sendRemindersOnDueDate = !this.sendRemindersOnDueDate
    if (this.enabled && this.sendRemindersOnDueDate) {
      this.addAlarm({ mode: 'dueDate' })
    }
    if (this.enabled && !this.sendRemindersOnDueDate) {
      this.deleteAlarm('dueDate')
    }
  }

  @action
  public toggleSendReminders15BeforeDueDate() {
    this.sendReminders15BeforeDueDate = !this.sendReminders15BeforeDueDate
    if (this.enabled && this.sendReminders15BeforeDueDate) {
      this.addAlarm({ mode: '15min' })
    }
    if (this.enabled && !this.sendReminders15BeforeDueDate) {
      this.deleteAlarm('15min')
    }
  }

  @action
  public toggleSendRemindersCustomPeriod() {
    this.sendRemindersCustomPeriod = !this.sendRemindersCustomPeriod
    if (this.enabled && this.sendRemindersCustomPeriod) {
      this.addAlarm({ mode: 'period', units: 1, period: 'hour' })
    }
    if (this.enabled && !this.sendRemindersCustomPeriod) {
      this.deleteAlarm('period')
    }
  }

  @action
  public toggleSendRemindersSpecificDate() {
    this.sendRemindersSpecificDate = !this.sendRemindersSpecificDate
    if (this.enabled && this.sendRemindersSpecificDate) {
      this.addAlarm({ mode: 'specific' })
    }
    if (this.enabled && !this.sendRemindersSpecificDate) {
      this.deleteAlarm('specific')
    }
  }

  @action
  public addAlarm(obj) {
    this.selectedAlarms.push(Alarm.create(obj))
  }

  @action
  public deleteAlarm(mode) {
    const index = this.selectedAlarms.findIndex((e) => e.mode === mode)
    this.selectedAlarms.splice(index, 1)
  }

  @action
  public clearAllAlarms() {
    this.selectedAlarms = []
  }

  @action
  public resetAlarms() {
    this.selectedAlarms = []
    this.sendReminders15BeforeDueDate = false
    this.sendRemindersCustomPeriod = false
    this.sendRemindersOnDueDate = false
    this.sendRemindersSpecificDate = false
  }
}
