import React from 'react'
import CalendarEventDrawerTitle from './CalendarEventDrawerTitle'
import CalendarEventDrawerTimes from './CalendarEventDrawerTimes'
import CalendarEventDrawerRepeat from './CalendarEventDrawerRepeat'
import CalendarEventDrawerType from './CalendarEventDrawerType'
import CalendarEventDrawerInvitees from './CalendarEventDrawerInvitees'
import CalendarEventDrawerAttachments from './CalendarEventDrawerAttachments'
import CalendarEventDrawerNotifications from './CalendarEventDrawerNotifications'

const CalendarEventDrawerDetailsTab = () => (
  <>
    <form>
      <CalendarEventDrawerTitle />
      <CalendarEventDrawerTimes />
      {/* <CalendarEventDrawerRepeat /> */}
      <CalendarEventDrawerType />
      {/* <CalendarEventDrawerNotifications /> */}
    </form>
    <CalendarEventDrawerAttachments />
    <CalendarEventDrawerInvitees />
  </>
)

export default CalendarEventDrawerDetailsTab
