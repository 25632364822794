import React, { FC } from 'react'
import {
  Typography,
  Button,
  Tooltip,
  Grid,
  TextField,
  CssBaseline,
  AppBar,
  Toolbar,
} from '@material-ui/core'
import '../../../css/dashboard-toolbar.scss'
import { inject, observer } from 'mobx-react'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import DoneIcon from '@material-ui/icons/Check'
import CancelIcon from '@material-ui/icons/Close'
import { DashboardsStore } from '../../DashboardsStore'
import isIE11 from '../../../../utils/isIE11'
import clsx from 'clsx'
import MuiConfirmDeleteDialog from '../../../shared/MuiConfirmDeleteDialog'
import MuiDialogMessage from '../../../shared/MuiDialogMessage'

export interface Props {
  children?: React.ReactElement
  localizationStore?: LocalizationStore
  dashboardsStore?: DashboardsStore
}

const DashboardEditToolbar: FC<Props> = ({ localizationStore, dashboardsStore }) => {
  const s = localizationStore.lzStrings
  const vm = dashboardsStore.editVM
  if (!vm) return null

  const renderConfirmDelete = () => {
    return (
      <MuiConfirmDeleteDialog
        onClose={() => vm.toggleShowConfirmDelete()}
        open={vm.showConfirmDelete}
        itemName={vm.name}
        itemType={s.moreMenu.obj_type.dashboard}
        onConfirm={() => vm.delete()}
      />
    )
  }

  const renderCantDelete = () => {
    return (
      <MuiDialogMessage
        onClose={() => vm.toggleShowCantDelete()}
        open={vm.showCantDelete}
        message={'You can not delete your last remaining dashboard.'}
      />
    )
  }

  const renderCustomToolbar = () => {
    if (vm.type !== 'custom') return null
    return (
      <Grid className='dashboard-header edit-mode' container justifyContent='space-between'>
        <Grid item>
          <TextField
            disabled={Boolean(!vm.dashboard)}
            value={vm.name}
            onChange={(e) => vm.setName(e.target.value)}
            placeholder='Enter Dashboard Name'
            className='dashboard-title'
            variant='outlined'
            autoFocus
          />
        </Grid>
        <Grid item className='dashboard-action'>
          {renderEditMessage()}
          {vm.objectId && (
            <Button
              disabled={Boolean(!vm.dashboard)}
              variant='outlined'
              className='btn-icon btn-outlined-red'
              aria-label='delete dashboard'
              onClick={() => vm.toggleShowConfirmDelete()}
            >
              Delete Dashboard
            </Button>
          )}
          <Button
            variant='outlined'
            className='btn-outlined-white'
            onClick={() => vm.goBack()}
          >
            Cancel
          </Button>
          <Button
            variant='outlined'
            className={vm.nameValid ? 'btn-save btn-icon' : 'btn-icon'}
            disabled={!vm.nameValid || Boolean(!vm.dashboard)}
            onClick={() => vm.save()}
          >
            Save Changes
          </Button>
          <Button variant='contained' onClick={() => vm.toggleShowWidgetDrawer()}>
            Add Widget
          </Button>
        </Grid>
      </Grid>
    )
  }

  const renderTypeToolbar = () => {
    if (vm.type === 'custom') return null
    return (
      <div className='dashboard-toolbar-right'>
        {renderEditMessage()}
        {renderButtons()}
      </div>
    )
  }

  const renderEditMessage = () => {
    return (
      <Typography className='edit-memo' component='span'>
        {s.dashboard.you_are_now_in_edit_mode}
      </Typography>
    )
  }

  const renderButtons = () => {
    return (
      <>
        <Tooltip title='Done' placement='bottom'>
          <>
            <Button
              disabled={Boolean(!vm.dashboard)}
              variant='outlined'
              className='btn-icon btn-icon-white'
              onClick={() => vm.save()}
              size='small'
            >
              <DoneIcon /> Done
            </Button>
          </>
        </Tooltip>
        <Tooltip title='Cancel' placement='bottom'>
          <>
            <Button
              disabled={Boolean(!vm.dashboard)}
              variant='outlined'
              className='btn-icon btn-icon-white'
              onClick={() => vm.goBack()}
              size='small'
            >
              <CancelIcon /> Cancel
            </Button>
          </>
        </Tooltip>
      </>
    )
  }

  return (
    <div id='DashboardToolbar'>
      {renderConfirmDelete()}
      {renderCantDelete()}
      <CssBaseline />
      <AppBar
        id='AppBar'
        className={clsx('dashboard-appbar', {
          'no-transition': isIE11,
        })}
        elevation={0}
      >
        <Toolbar
          className={clsx('dashboard-toolbar edit-mode')}
        >
          {renderCustomToolbar()}
          {renderTypeToolbar()}
        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  )
}

export default inject('localizationStore', 'dashboardsStore')(observer(DashboardEditToolbar))
