import { observable, computed, action } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { MediaItemVM } from '../../user-surveys/view-models/MediaItemVM'
import { QuestionType } from '../../user-surveys/types/QuestionType'
import PulseQuestion from '../../pulse-questions/aggregate/PulseQuestion'
import { FKPulseCategory } from '../../pulse-questions/aggregate/FKPulseCategory'
import isMobile from '../../../utils/isMobile'
import { PulseQuestionOption } from '../../pulse-questions/aggregate/PulseQuestionOption'
import { PulseQuestionOptionVM } from './questions/PulseQuestionOptionVM'
import { PulseQuestionEditVM } from './PulseQuestionEditVM'
import { isNumeric } from '../../shared/isNumeric'
import { CMSMediaItemVM } from '../../user-surveys/view-models/CMSMediaItemVM'
import { OldMediaItemVM } from '../../user-surveys/view-models/OldMediaItemVM'

export class PulseQuestionPreviewVM {
  private rootStore: RootStore

  constructor(rootStore, question: PulseQuestion) {
    this.rootStore = rootStore
    this.question = question
    this.loadData(question)
  }

  private loadData(question: PulseQuestion) {
    this.type = question.type as QuestionType
    this.required = question.required
    this.ratingDisplay = question.ratingDisplay
    if (isNumeric(question.media.objectId) || question.media.cmsItemId) {
      this.media = new CMSMediaItemVM(this.rootStore, question.media)
    } else
      this.media = new OldMediaItemVM(
        this.rootStore,
        question.media,
        question.youTubeURL,
        question.vimeoURL
      )

    if (this.media.isCMSItem) {
      const media = this.media as CMSMediaItemVM
      const cmsItemId = isNumeric(question.media.objectId)
        ? Number(media.objectId)
        : media.cmsItemId
      const found = this.rootStore.cmsItemAttachmentStore.getCMSItem(cmsItemId)
      if (!found) {
        this.rootStore.cmsItemAttachmentStore.loadCMSItem(cmsItemId).then((item) => {
          media.setCMSItem(item)
          this.rootStore.cmsItemAttachmentStore.addCMSItem(item)
        })
      } else {
        media.setCMSItem(found)
      }
    }

    if (
      !this.media.path ||
      this.media.type === 'image' ||
      this.question.media.watchPercentageRequirement === 0
    )
      question.options.forEach((opt: PulseQuestionOption, index: number) => {
        this.options.push(
          new PulseQuestionOptionVM(
            this.rootStore,
            opt,
            index,
            new PulseQuestionEditVM(this.rootStore, question, true)
          )
        )
      })

    this.isDropdownMultiSelect = question.isDropdownMultiSelect
    this.placeholder = question.placeholder
  }

  @observable question: PulseQuestion
  @observable type: QuestionType = null
  @observable index: number = 0
  @observable options: Array<PulseQuestionOptionVM> = []
  @observable media: MediaItemVM = null
  @observable required: boolean = false
  @observable saveTried: boolean = false
  @observable playedSeconds: number = 0
  @observable mediaTimeLeft: number = 0
  @observable ratingDisplay: string = ''
  @observable isDirty: boolean = false
  displayedIndex: number = 0
  @observable oldIndex: number = 0
  @observable newIndex: number = 0
  @observable hover: number = 0
  @observable public isExpanded: boolean = true
  @observable isDropdownMultiSelect: boolean = false
  @observable placeholder: string = 'Please Select'

  @computed
  public get html(): string {
    return this.question.html
  }

  @action
  public setIsDirty() {
    this.isDirty = true
  }

  @action
  public setHover(newHover: number) {
    this.hover = newHover
  }

  @action
  public setNewRank(value) {
    this.newIndex = value
  }

  @computed
  public get isForResponseDisplay(): boolean {
    return false
  }

  @action
  public setValue(val) {
    if (!val) return
    this.options.forEach((e) => (e.isChecked = false))
    this.options.filter((e) => e.value === val)[0].isChecked = true
  }

  @action
  public toggleIsExpanded(isExpanded: boolean) {
    this.isExpanded = isExpanded
  }

  @computed
  public get isShown(): boolean {
    return true
  }

  @computed
  public get value(): number {
    if (this.type === 'rating' || this.type === 'starRating') {
      const currentSelectedOption = this.options.find((e) => e.isChecked === true)
      if (currentSelectedOption) return currentSelectedOption.value
      return 0
    }
    return null
  }

  @computed
  public get ratingDisplayVal(): string {
    return this.question.ratingDisplay
  }

  public setDisplayedIndex(val: number) {
    this.displayedIndex = val
  }

  @computed
  public get validatedNumberResponse(): boolean {
    if (!this.isDirty) return false
    if (
      Number(this.options[0].responseNumber) >= Number(this.minimumValueAllowed) &&
      Number(this.options[0].responseNumber) <= Number(this.maximumValueAllowed)
    )
      return true
    return false
  }

  @computed
  public get hasAnswer(): boolean {
    if (this.type === 'infoText') return true
    if (this.type === 'order') return true
    if (this.type === 'number') return this.validatedNumberResponse
    if (this.type !== 'text') {
      return this.options.filter((e) => e.isChecked).length !== 0
    } else {
      return this.options[0].text !== ''
    }
  }

  @computed
  public get textAnswer(): string {
    if (this.hasAnswer) {
      return this.options[0].text
    } else {
      return ''
    }
  }

  @computed
  public get blurred(): boolean {
    return false
  }

  @computed
  public get ratingText(): string {
    const index = this.hover - 1 > -1 ? this.hover - 1 : this.value - 1
    if (index < 0) return `${isMobile ? 'Tap' : 'Click'} to Select`
    if (this.type === 'rating' || this.type === 'starRating') {
      const currentSelectedOption = this.options[index].text
      return currentSelectedOption ? currentSelectedOption : ''
    }
    return null
  }

  @computed
  public get objectId(): string {
    return this.question.objectId
  }

  @computed
  public get title(): string {
    return this.question.title
  }

  @computed
  public get originalPulseQuestionId(): string {
    return this.question.originalPulseQuestionId
  }

  @computed
  public get fk_grandCategoryId(): FKPulseCategory {
    if (!this.question) return
    if (!this.question.fk_grandCategoryId) return
    return this.question.fk_grandCategoryId
  }

  @computed
  public get maximumValueAllowed(): string {
    return Number(this.question.maximumValueAllowed) === 0
      ? String(10 ** 22 - 1)
      : String(this.question.maximumValueAllowed)
  }

  @computed
  public get minimumValueAllowed(): string {
    return String(this.question.minimumValueAllowed)
  }

  @computed
  public get numberStep(): string {
    if (Number(this.question.numberStep) === 0) return '1'
    return String(this.question.numberStep)
  }

  @computed
  public get hasMedia(): boolean {
    return this.media && this.media.hasMedia
  }

  @computed
  public get isValid() {
    if (!this.saveTried) return true
    return true
  }

  public toDTO() {
    return {
      objectId: this.question.objectId,
      title: this.title,
      type: this.type,
      options: this.options.map((e) => e.toDTO()),
      media: this.media.toDTO(),
      required: this.required,
      html: this.html,
      ratingDisplay: this.ratingDisplay,
    }
  }
}
