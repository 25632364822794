import React from 'react'
import { Route } from 'react-router'
import { authenticate } from '../auth/routes/AuthenticatedRoute'
import Layout from '../layout/Layout'
import StyleGuideScreen from './StyleGuideScreen'

export default function getStyleGuideRoutes() {
  return (
    <Route key={'sg0'} component={authenticate(Layout)} path='styleguide'>
      <Route key={'sg1'} path='guide' component={StyleGuideScreen} />
    </Route>
  )
}
