import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../../localization/LocalizationStore'
import {
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  OutlinedInput,
  Tooltip,
  Typography,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import './WelcomeBannerEdit.scss'
import { WelcomeBannerEditVM } from '../../../view-models/WelcomeBannerEditVM'
import { Editor } from '@tinymce/tinymce-react'
import { PLUGINS, TOOLBAR } from '../../../../config/TinyMCEconfig'
import FileUploadModal from '../../../../upload/views/FileUploadModal'
import { FileTypeEnum } from '../../../../upload/enum/FileTypeEnum'
import { Attachment } from '../../../../upload/aggregate/Attachment'
import { Attachment as AttachmentItem } from '../../../../attachments/aggregate/Attachment'
import WelcomeBannerEditorToggle from './WelcomeBannerEditorToggle'
import WelcomeBannerHTMLEditor from './WelcomeBannerHTMLEditor'
import CMSItemsPickerDialog from '../../../../cms-items/views/dialogs/CMSItemsPickerDialog'
import { CMSItemsTypeEnum } from '../../../../cms-items/enum/CMSItemsTypeEnum'
import { OrganizationsStore } from '../../../../organizations/store/OrganizationsStore'
import { AttachmentVM } from '../../../../attachments/view-models/AttachmentVM'
import CMSThumbnail from '../../../../thumbnail/views/CMSThumbnail'
import OldThumbnail from '../../../../thumbnail/views/OldThumbnail'
import { CMSItemAttachmentVM } from '../../../../attachments/view-models/CMSItemAttachmentVM'
import { OldAttachmentVM } from '../../../../attachments/view-models/OldAttachmentVM'

interface Props {
  vm: WelcomeBannerEditVM
  localizationStore?: LocalizationStore
  organizationsStore?: OrganizationsStore
}

const WelcomeBannerEdit: React.FC<Props> = ({ localizationStore, vm, organizationsStore }) => {
  const { lzStrings } = localizationStore
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  if (!vm) return null

  const handleClose = (value: string) => {
    setAnchorEl(null)
    if (value === 'delete') vm.removeWelcomeBanner()
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const renderDeleteOption = () => {
    if (vm.isNew) return null
    return (
      <MenuItem onClick={() => handleClose('delete')}>
        <DeleteIcon className='icon' />
        {`Remove Welcome Banner`}
      </MenuItem>
    )
  }

  const renderMenu = () => {
    if (vm.isNew) return
    return (
      <>
        <IconButton aria-controls='simple-menu' aria-haspopup='true' onClick={handleClick}>
          <MoreHorizIcon />
        </IconButton>
        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          style={{ marginTop: 40 }}
        >
          {renderDeleteOption()}
        </Menu>
      </>
    )
  }

  const renderTitle = () => {
    return (
      <Grid item xs={12} className='section'>
        <Typography className='input-label'>
          {'Title'}
          <span className='required'>*</span>
        </Typography>
        <OutlinedInput
          error={!vm.titleValid}
          fullWidth
          labelWidth={0}
          autoComplete='off'
          value={vm.title}
          placeholder={'Title'}
          onChange={(e) => vm.setTitle(e.target.value)}
        />
      </Grid>
    )
  }

  const renderEditorToggle = () => {
    return <WelcomeBannerEditorToggle vm={vm} />
  }

  const renderWYSIWYGEditor = () => {
    if (vm.editorTabIndex !== 0) return null
    return (
      <Editor
        value={vm.body}
        init={{
          branding: false,
          height: 250,
          resize: true,
          menubar: false,
          plugins: PLUGINS,
          toolbar: TOOLBAR,
          powerpaste_block_drop: true,
          placeholder: 'Add some content...',
          browser_spellcheck: true,
          contextmenu: false,
          smart_paste: false,
          paste_data_images: false,
          powerpaste_allow_local_images: false,
          image_file_types: '',
          invalid_elements: 'img,svg',
        }}
        onEditorChange={(e) => vm.setBody(e)}
      />
    )
  }

  const renderHTMLEditor = () => {
    if (vm.editorTabIndex !== 1) return null
    return <WelcomeBannerHTMLEditor vm={vm} />
  }

  const renderBody = () => {
    return (
      <Grid item xs={12} className='section'>
        <Typography className='input-label'>
          {'Body'}
          <span className='required'>*</span>
        </Typography>
        <div className={!vm.bodyValid ? 'body-invalid editor-body' : 'editor-body'}>
          {renderHTMLEditor()}
          {renderWYSIWYGEditor()}
        </div>
      </Grid>
    )
  }

  const renderUploadModal = () => {
    if (organizationsStore.currentOrganization?.hasCMSPicker) return renderCMSUploadModal()
    return renderStandardUploadModal()
  }

  const renderCMSUploadModal = () => {
    return (
      <CMSItemsPickerDialog
        allowMultiple={true}
        fileTypes={CMSItemsTypeEnum.IMAGES_VIDEOS}
        addAfterSave={(arr: AttachmentItem[]) => {
          const attachments = arr.map((a) => {
            const attachment = new Attachment()
            attachment.cmsItemId = a.cmsItemId
            return attachment
          })
          vm.addAttachments(attachments)
        }}
        toggleShowModal={() => vm.toggleShowUploadModal()}
        isOpen={vm.showUploadModal}
      />
    )
  }

  const renderStandardUploadModal = () => {
    return (
      <FileUploadModal
        allowMultiple={true}
        fileTypes={FileTypeEnum.IMAGES_VIDEOS}
        addAfterSave={(a: Attachment[]) => vm.addAttachments(a)}
        toggleShowModal={() => vm.toggleShowUploadModal()}
        isOpen={vm.showUploadModal}
      />
    )
  }

  const renderAttachmentThumbnail = (item: AttachmentVM, index: number) => {
    if (item.isCMSItem)
      return (
        <CMSThumbnail
          vm={item as CMSItemAttachmentVM}
          index={index}
          onClose={() => vm.removeAttachmentByIndex(index)}
        />
      )
    return (
      <OldThumbnail
        vm={item as OldAttachmentVM}
        index={index}
        onClose={() => vm.removeAttachmentByIndex(index)}
      />
    )
  }

  const renderAttachments = () => {
    if (!vm.attachments) return null
    return (
      <form>
        <Grid container className='attachments-list'>
          {vm.attachments.map((doc, index) => {
            return renderAttachmentThumbnail(doc, index)
          })}
        </Grid>
      </form>
    )
  }

  const renderAddAttachments = () => {
    return (
      <Grid item xs={12} className='section'>
        <div className={'attachment-header'}>
          <Typography className='input-label'>{'Attachments'}</Typography>
          <Button variant='text' onClick={() => vm.toggleShowUploadModal()}>
            {'Add Attachments'}
          </Button>
        </div>
        {renderAttachments()}
      </Grid>
    )
  }

  const renderButtons = () => {
    return (
      <div className={'section btn-row'}>
        <Button onClick={() => vm.cancel()}>Cancel</Button>
        <Button onClick={() => vm.apply()} variant='contained'>
          Apply
        </Button>
      </div>
    )
  }

  return (
    <div id={'WelcomeBannerEdit'}>
      <div className={'main-content'}>
        {renderUploadModal()}
        <div className={'banner-edit-header'}>
          <h6 className={'banner-edit-title'}>{'Welcome Banner Edit'}</h6>
          {renderMenu()}
        </div>
        {renderTitle()}
        {renderEditorToggle()}
        {renderBody()}
        {renderAddAttachments()}
      </div>
      {renderButtons()}
    </div>
  )
}

export default inject('localizationStore', 'organizationsStore')(observer(WelcomeBannerEdit))
