import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { EventsStore } from '../../store/EventsStore'
import { Grid, IconButton, Paper } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'

interface ICalendarEventDrawerAddParticipantCard {
  localizationStore?: LocalizationStore
  eventsStore?: EventsStore
}

const CalendarEventDrawerAddParticipantCard: React.FC<ICalendarEventDrawerAddParticipantCard> = ({
  localizationStore,
  eventsStore,
}) => {
  const { lzStrings } = localizationStore
  const vm = eventsStore.editVM
  const hasParticipants = vm.participantsSelectVM.participants.length > 0
  return (
    <Grid item>
      <Paper
        elevation={2}
        onClick={() => vm.openParticipantsTab()}
        className='add-participant-card'
      >
        <Grid container spacing={1} alignItems='center'>
          <Grid item>
            <IconButton>{hasParticipants ? <EditIcon /> : <AddIcon />}</IconButton>
          </Grid>
          <Grid item>
            <p className={vm.participantsValid ? 'add-participant' : 'add-participant-error'}>
              {hasParticipants
                ? 'Manage Participants'
                : lzStrings.calendarEventDrawer.add_participant}
            </p>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default inject(
  'localizationStore',
  'eventsStore'
)(observer(CalendarEventDrawerAddParticipantCard))
