import axios from 'axios'
import Parse from 'parse'
import env from '../../../env'
import { IAPIResponse } from '../../shared/IAPIResponse'
import rootStore from '../../stores/RootStore'
import { IUserPasswordResetRequest } from '../interfaces/IUserPasswordResetRequest'
import { IUserAuthInfoRequest } from '../interfaces/IUserAuthInfoRequest'
import { IUserAuthInfoResult } from '../interfaces/IUserAuthInfoResult'
import { LoginVM } from '../view-models/LoginVM'
import { IUserWelcomePasswordRequest } from '../interfaces/IUserWelcomePasswordRequest'
import { IOIDCLoginResult } from '../interfaces/IOIDCLoginResult'
import { IOrganizationUsersBulkRequest } from '../../organization-users/interfaces/IOrganizationUsersBulkRequest'
import { IOIDCLoginRequest } from '../interfaces/IOIDCLoginRequest'

export class AuthenticationService {
  rootStore: any
  public async tryLogin(email, password, VM?: LoginVM): Promise<IAPIResponse> {
    try {
      let response = { success: false }
      await Parse.User.logIn(email.toLowerCase(), password)
        .then((user) => {
          return user.fetch()
        })
        .then(async (user) => {
          const expiresAt = user.get('passwordExpiresAt')
          if (expiresAt && expiresAt <= new Date().toDateString()) {
            // Password is expired, make call to create token, get link and reroute to reset page
            const result = await this.getPasswordExpiredLink(Parse.User.current().id, email)
            rootStore.appStore.router.push(result.link)
          } else {
            //Password is not expired, proceed with login
            rootStore.appStore.setCurrentUser(Parse.User.current().id)
            response = { success: true }
          }
        })
      return response
    } catch (error) {
      return { success: false, errorMessage: error.message }
    }
  }

  public async getPasswordExpiredLink(userId, email: string) {
    const result = await Parse.Cloud.run('getPasswordExpiredLink', {
      userId,
      email: email.toLowerCase(),
    })
    return result
  }

  public async sendPasswordReset(email: string) {
    const result = await Parse.Cloud.run('sendPasswordReset', { email: email.toLowerCase() })
    return result
  }

  public async sendPasswordResetAsAdmin(email: string) {
    const result = await Parse.Cloud.run('resetPassword', { email: email.toLowerCase() })
    return result
  }

  public async unlockAccountAsAdmin(userId: string) {
    const result = await Parse.Cloud.run('unlockAccount', { userId })
    return result
  }

  private getApiUrl(): string {
    return env.var.REACT_APP_API_URL
  }

  public async getUserAuthInfo(req: IUserAuthInfoRequest): Promise<IUserAuthInfoResult> {
    const url = this.getApiUrl() + '/auth/userAuthInfo'
    const response = await axios.post(url, req)
    return response.data ? response.data.data : null
  }

  public async getDomainSSO(domain) {
    if (process.env.NODE_ENV === 'test') return { success: true }
    const url = this.getApiUrl() + `/auth/getDomainSSO?domain=${domain}`
    const response = await axios.get(url)
    return response.data ? response.data.data : undefined
  }

  public async oidcLogin(request: IOIDCLoginRequest): Promise<IOIDCLoginResult> {
    const url = this.getApiUrl() + '/auth/oidcLogin'
    const response = await axios.post(url, request)
    return response.data ? response.data.data : { success: false }
  }

  public async passwordResetViaToken(req: IUserPasswordResetRequest) {
    const result = await Parse.Cloud.run('userPasswordResetViaToken', req)
    return result
  }

  public async welcomePasswordSet(req: IUserWelcomePasswordRequest) {
    const result = await Parse.Cloud.run('userWelcomePasswordSet', req)
    return result
  }

  public async sendWelcomeEmail(req: IOrganizationUsersBulkRequest) {
    const result = await Parse.Cloud.run('sendWelcomeEmail', { req })
    return result
  }

  public async expirePassword(req: IOrganizationUsersBulkRequest) {
    const result = await Parse.Cloud.run('expirePassword', { req })
    return result
  }
}
