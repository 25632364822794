import { RootStore } from '../../stores/RootStore'
import Parse from 'parse'
import { observable, action, computed, runInAction } from 'mobx'
import { find, findIndex } from 'lodash'
import User from '../aggregate/User'
import { UserImportVM } from '../../organization-users/view-models/import/UserImportVM'
import { UsersService } from '../service/UsersService'
import { OrganizationUsersWidgetVM } from '../../organization-users/view-models/widget/OrganizationUsersWidgetVM'

export class UsersStore {
  constructor(rootStore: RootStore | null = null) {
    if (rootStore) this.rootStore = rootStore
  }

  private rootStore: RootStore

  @observable public selectedUserId: string
  @observable public orgUsers: Array<User> = []
  private query1: Parse.Query
  private orgUsersQuery: Parse.Query
  private subscription1: Parse.LiveQuerySubscription

  @observable public importVM: UserImportVM = null
  @observable public showAddDrawer: boolean = false
  @observable public editDrawerShown: boolean = false
  @observable public showUsersImport: boolean = false
  @observable public orgUsersTableWidgetVM: OrganizationUsersWidgetVM = null
  @observable public loaded: boolean = false

  @action
  public async loadUsers(userId = null) {
    const qry = new Parse.Query('_User')
    if (!userId) {
      const userIds = this.currentOrganizationUsers.map((e) => e.userId)
      qry.containedIn('objectId', userIds)
    } else {
      qry.equalTo('objectId', userId)
    }
    qry.limit(2000)
    let response = await qry.find()
    if (response) {
      response.forEach((userObj) => {
        const user = userObj.toJSON()
        if (user && user.objectId === this.rootStore.appStore.currentUserId)
          this.rootStore.userStore.user.updateFromServer(user)
        const foundOrgUser = this.currentOrganizationUsers.find((e) => e.userId === user.objectId)
        if (foundOrgUser) foundOrgUser.updateUserInfoFromServer(user)
      })
    }
    if (!this.orgUsersTableWidgetVM) {
      this.orgUsersTableWidgetVM = new OrganizationUsersWidgetVM(this.rootStore)
    }
    this.loaded = true
  }

  @computed
  public get currentOrganizationUsers(): Array<User> {
    return this.orgUsers.filter(
      (e) =>
        e.organizationId === this.rootStore.appStore.currentOrgId && !e.disabled && !e.isProcessing
    )
  }

  @action
  private updateOrgUserFromServer(obj: Parse.Object) {
    const serverObj = obj.toJSON()
    let user = this.getUser(serverObj.objectId)

    if (!user) {
      user = User.create(serverObj.organizationId)
      this.orgUsers.push(user)
    }
    user.updateFromServer(serverObj)

    if (serverObj.isDeleted) this.deleteUser(serverObj.objectId)
  }

  public getUser(objectId): User {
    return this.orgUsers.find((e) => e.objectId === objectId)
  }

  public getUserByUserId(userId): User {
    return this.orgUsers.find((e) => e.userId === userId)
  }

  public getUserByUserName(userName): User {
    return this.orgUsers.find((e) => e.name === userName)
  }

  @action
  public addUser(user: User) {
    this.orgUsers.push(user)
  }

  public deleteUser(objectId) {
    this.orgUsers.splice(this.getUserIndex(objectId), 1)
  }

  private getUserIndex(objectId): number {
    return this.orgUsers.findIndex((e) => e.objectId === objectId)
  }

  private deleteUserNotOnServer() {
    while (true) {
      const itemNotOnServer = find(this.currentOrganizationUsers, ['isOnServer', false])
      if (itemNotOnServer) this.deleteUser(itemNotOnServer.objectId)
      else break
    }
  }

  @action
  public setAddNewUser() {
    this.selectedUserId = null
  }

  @action
  public async editNonOrgAdmin() {
    const svc = new UsersService(this.rootStore)
    const result = await svc.getOrgUser()
    this.updateOrgUserFromServer(result)
    // this.editVM = new UserEditVM(this.rootStore, this.orgUsers[0])
    this.showEditDrawer()
  }

  @action
  public setSelectedUser(id) {
    this.selectedUserId = id
    this.showEditDrawer()
  }

  @action
  public setImportUsers() {
    this.selectedUserId = null
    this.showUsersImport = true
    this.importVM = new UserImportVM(this.rootStore)
  }

  @action
  public clearForms() {
    this.selectedUserId = null
  }

  @action
  private async handleLiveQuery(orgUser) {
    const orgUserObj = orgUser.toJSON()
    this.updateOrgUserFromServer(orgUser)
    try {
      await this.loadUsers(orgUserObj.userId)
    } catch (error) {
      const errorObject = JSON.parse(JSON.stringify(error))

      if (
        errorObject.code === 209 &&
        String(errorObject.message).includes('Invalid session token')
      ) {
        this.rootStore.appStore.logout('UsersStore: Logging out due to invalid session token')
      } else {
        throw error
      }
    }
  }

  @action
  public showEditDrawer() {
    this.editDrawerShown = true
  }

  async listenToServer1() {
    if (this.subscription1) this.subscription1.unsubscribe()
    const sub1 = await this.query1.subscribe()
    sub1.on('create', (orgUser) => this.handleLiveQuery(orgUser))
    sub1.on('update', (orgUser) => this.handleLiveQuery(orgUser))
    sub1.on('delete', (orgUser) => this.deleteUser(orgUser.id))
    this.subscription1 = sub1
  }

  public clearData() {
    this.orgUsers = []
  }
}
