import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { Checkbox, FormControlLabel, InputAdornment, ListItem, ListItemText, OutlinedInput, Paper, Tooltip, Typography } from '@material-ui/core'
import { CategoryVM } from '../../../view-models/CategoryVM'
import { EditScreenVM } from '../../../view-models/EditScreenVM'

interface Props {
  cat: CategoryVM
  index: number
  style: any
  editVM: EditScreenVM
}

const CategoryRow: FC<Props> = ({ cat, index, style, editVM }) => {

  const handleCheckAll = (cat?) => {
    if (!cat) {
      editVM.selectedRole.checkAll()
    } else {
      cat.checkAll()
    }
  }

  const renderCategory = () => {
    return (
      <>
        <div className='cat-layout' key={index} style={style}>
          <div>
            {index === 0 && renderCheckAll()}
            <Paper
              square
              key={index}
              className={
                cat.isSelected
                  ? cat.childrenSelectedValid
                    ? 'cat-picker-card selected'
                    : 'cat-picker-card selected cat-card-error'
                  : cat.childrenSelectedValid
                    ? 'cat-picker-card'
                    : 'cat-picker-card cat-card-error'
              }
            >
              <div className='cat-container'>
                <div
                  className={
                    cat.isSelected && cat.isChecked ? 'checkbox checkbox-selected' : 'checkbox'
                  }
                >
                  <Checkbox
                    checked={cat.isChecked}
                    onChange={() => cat.handleCatCheck()}
                    color='primary'
                  />
                </div>
                <div className='w-7 cat-click' onClick={cat.hasChildren ? (() => cat.handleCatClick()) : undefined}>
                  <div id='cat-name'>
                    <Typography>{cat.name}</Typography>
                  </div>
                  {cat.hasChildren ? (
                    <Typography id='cat-subs'>{`( ${cat.children.length} sub-skills )`}</Typography>
                  ) : null}
                </div>
                <div className='w-4'>
                  <OutlinedInput
                    id='category-weight'
                    type='number'
                    disabled={!cat.isChecked}
                    value={cat.weight}
                    onChange={e => cat.handleWeightChange(e.target.value)}
                    endAdornment={<InputAdornment position='end'>%</InputAdornment>}
                    inputProps={{
                      step: 10,
                    }}
                  />
                </div>
              </div>
            </Paper>
          </div>

          {renderSubCategories(cat)}
        </div>
      </>
    )
  }

  const renderSubCategories = cat => {
    let cols = []

    const getChildren = cat => {
      if (!cat.hasChildren) return
      let rows = []
      cat.children.forEach((child, index) => {
        rows.push(subCategories(child, index))
        getChildren(child)
      })
      cols.unshift(column(cat, rows))
    }

    getChildren(cat)

    return cols
  }

  const renderCheckAll = (cat?) => {
    return (
      <Paper square className='check-card'>
        <div className='checkbox'>
          <FormControlLabel
            control={
              <Checkbox
                checked={cat ? cat.allChildrenChecked : editVM.selectedRole.allChildrenChecked}
                onChange={() => handleCheckAll(cat)}
                color='primary'
              />
            }
            label='Check All'
          />
        </div>
      </Paper>
    )
  }

  const column = (cat, rows) => {
    if (!cat.isSelected) return null
    return (
      <div key={cat.objectId}>
        {!cat.childrenWeightValid && renderTotalWeightValidation()}
        {!cat.childrenSelectedValid && renderInvalidChildCheck()}
        {renderCheckAll(cat)}
        {rows}
      </div>
    )
  }

  const subCategories = (cat, index) => {
    return (
      <Paper
        square
        key={cat.objectId}
        className={
          cat.isSelected
            ? cat.childrenSelectedValid
              ? 'cat-picker-card selected'
              : 'cat-picker-card selected cat-card-error'
            : cat.childrenSelectedValid
              ? 'cat-picker-card'
              : 'cat-picker-card cat-card-error'
        }
      >
        <div className='subcat-container' key={index}>
          <div
            className={cat.isSelected && cat.isChecked ? 'checkbox checkbox-selected' : 'checkbox'}
          >
            <Checkbox
              checked={cat.isChecked}
              onChange={() => cat.handleCatCheck()}
              color='primary'
            />
          </div>
          <div className='cat-click w-7' onClick={() => cat.handleCatClick()}>
          <Tooltip id='tooltip-unset' title={cat.showNameTooltip ? cat.name : ''} placement='top'>
            <div id='cat-name'>
              <Typography>{cat.name}</Typography>
            </div>
            </Tooltip>
            {cat.hasChildren ? (
              <Typography>{`( ${cat.children.length} sub-skills )`}</Typography>
            ) : null}
            <Tooltip id='tooltip-unset' title={cat.description ? cat.description : ''} placement='bottom'>
              <div id='cat-desc'>
                <Typography
                  variant='caption'
                  style={{ display: cat.description ? 'contents' : 'none' }}
                >
                  {cat.description}
                </Typography>
              </div>
            </Tooltip>
          </div>
          <div className='w-4'>
            <OutlinedInput
              id='category-weight'
              type='number'
              disabled={!cat.isChecked}
              value={cat.weight}
              onChange={e => cat.handleWeightChange(e.target.value)}
              endAdornment={<InputAdornment position='end'>%</InputAdornment>}
              inputProps={{
                step: 10,
              }}
            />
          </div>
        </div>
      </Paper>
    )
  }

  const renderInvalidChildCheck = () => {
    return (
      <div className='error-div'>
        <span className='error'>At least one sub-skill must be selected.</span>
      </div>
    )
  }

  const renderTotalWeightValidation = () => {
    return (
      <div>
        <span className='error'>Total must equal 100%</span>
      </div>
    )
  }

  return (
    <>
      {renderCategory()}
    </>
  )
}

export default observer(CategoryRow)
