export enum FileTypeEnum {
  VIDEOS = 'video/*',
  IMAGES = 'image/*',
  AUDIO = 'audio/*',
  IMAGES_VIDEOS = 'video/*, image/*',
  IMAGES_VIDEOS_AUDIO = 'video/*, image/*, audio/*',
  FILES = '.pdf, .doc, .docx, .xls, .xlsx, .csv, .zip, .bmp, .txt, .odt, .rtf',
  PDF = '.pdf',        
  DEFAULT = '.pdf, .doc, .docx, .xls, .xlsx, .csv, .zip, .bmp, .txt, .odt, .rtf, .mp4, .mov, .wmv, .jpg, .jpeg, .png, .gif, .svg, .heic'
}
