import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../localization/LocalizationStore'
import { ClientsStore } from '../store/ClientsStore'
import { LabelsStore } from '../../labels/store/LabelsStore'
import MuiDrawerToolbar from '../../shared/Mui-Drawer/MuiDrawerToolbar'

interface DrawerButtonControlsProps {
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
  clientsStore?: ClientsStore
}

const DrawerButtonControls: React.FC<DrawerButtonControlsProps> = ({
  localizationStore,
  clientsStore,
  labelsStore,
}) => {
  const { lzStrings } = localizationStore
  const { clientEditVM: vm } = clientsStore

  if (!vm) return null
  return (
    <div>
      <MuiDrawerToolbar
        handleCancelClick={() => {
          clientsStore.closeWidgetDrawer()
        }}
        handleSubmitClick={() => vm.saveClient()}
        showReset={Boolean(vm.objectId)}
        onConfirmReset={() => clientsStore.reloadEditVM(vm.client)}
        moreMenuProps={{
          hidden: !vm.objectId,
          isEditMode: true,
          itemType: lzStrings.moreMenu.obj_type.client,
          itemName: vm.name,
          onConfirmDelete: () => vm.deleteClient(),
        }}
      />
    </div>
  )
}

export default inject(
  'localizationStore',
  'clientsStore',
  'labelsStore'
)(observer(DrawerButtonControls))
