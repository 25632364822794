import React from 'react'
import { observer, inject } from 'mobx-react'
import { CardContent, Badge } from '@material-ui/core'
import { TasksStore } from '../../store/TasksStore'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import './TasksManagementWidget.scss'
import Tooltip from '@material-ui/core/Tooltip'
import Avatar from '../../../shared/Avatar'
import WidgetUserTaskBadge from './WidgetUserTaskBadge'
import { AudienceMembersStore } from 'src/app/audience-members/store/AudienceMembersStore'
import { TasksManagementWidgetRowVM } from '../../view-models/TasksManagementWidgetRowVM'
import AGGridWidgetPagingRow from '../../../shared/ag-grid/AGGridPagingRow'

interface WidgetContentProps {
  tasksStore?: TasksStore
  localizationStore?: LocalizationStore
  audienceMembersStore?: AudienceMembersStore
}

const WidgetContent: React.FC<WidgetContentProps> = ({
  localizationStore,
  tasksStore,
  audienceMembersStore,
}) => {
  const { taskManagementWidgetVM: vm } = tasksStore
  const s = localizationStore.lzStrings

  const renderGrid = () => {
    const ParticipantsCell = observer((props) => {
      const task: TasksManagementWidgetRowVM = props.data
      return (
        <div>
          {task.assignedToAudienceMembers.map((snapshot, index) => {
            const user = audienceMembersStore.getUser(snapshot.userId)

            if (!user) return

            return (
              <Tooltip
                key={snapshot.userTaskId}
                title={`${user.name}: ${s.tasksManagementWidget.task} ${
                  snapshot.completed
                    ? s.tasksManagementWidget.completed
                    : s.tasksManagementWidget.incomplete
                }`}
                placement='bottom'
              >
                <Badge
                  badgeContent={<WidgetUserTaskBadge isCompleted={snapshot.completed} />}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <Avatar
                    key={`${snapshot.userTaskId}-${user.objectId}`}
                    user={user}
                    size={20}
                    style={{ fontSize: 12, float: 'left', marginLeft: -2 }}
                    showOnlyFirstInitial
                  />
                </Badge>
              </Tooltip>
            )
          })}
        </div>
      )
    })

    return (
      <div className='ag-theme-alpine' style={{ height: '100%', width: '100%' }}>
        <AgGridReact
          gridOptions={{
            ...vm.getGridOptions(),
            frameworkComponents: {
              participantsCell: ParticipantsCell,
            },
          }}
        />
      </div>
    )
  }

  return (
    <CardContent className='cardContent'>
      {renderGrid()}
      <AGGridWidgetPagingRow vm={vm} />
    </CardContent>
  )
}

export default inject(
  'tasksStore',
  'localizationStore',
  'audienceMembersStore'
)(observer(WidgetContent))
