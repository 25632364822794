import Parse from 'parse'
import { RootStore } from '../../stores/RootStore'
import { PulseSet } from '../aggregate/PulseSet'
import { IPulseSetDTO } from '../dtos/IPulseSetDTO'
import { IPulseSetDeleteRequest } from '../interfaces/IPulseSetDeleteRequest'

export class PulseSetsService {
  rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }
  
  public async savePulseSet(pulseSet: IPulseSetDTO) {
    const data = await Parse.Cloud.run('savePulseSet', { pulseSet })
    return data
  }

  public async deletePulseSet(request: IPulseSetDeleteRequest) {
    const data = await Parse.Cloud.run('deletePulseSet', { request })
    return data
  }
}
