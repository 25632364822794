import React from 'react'
import { Route } from 'react-router'
import Layout from '../../layout/Layout'
import { authenticate } from '../../auth/routes/AuthenticatedRoute'
import SurveyResultsScreen from '../views/SurveyResultsScreen'

export default function getSurveyResultsRoutes() {
  return (
    <Route key={'srt0'} path='surveyresults' component={authenticate(Layout)}>
      <Route key={'srt1'} path='view/:surveyId/:userSurveyId' component={SurveyResultsScreen} />
      <Route key={'srt1'} path='view/:anonymousToken' component={SurveyResultsScreen} />
    </Route>
  )
}
