import { AGGridRowVM } from 'src/app/shared/ag-grid/AGGridRowVM'
import { AnnouncementParticipant } from '../../aggregate/AnnouncementParticipant'
import { RootStore } from '../../../stores/RootStore'
import { computed } from 'mobx'
import moment from 'moment'
import { EventType } from 'src/app/notifications/types/EventType'
import { AnnouncementParticipantsListVM } from './AnnouncementParticipantsListVM'
import { UserAnnouncementEventsVM } from 'src/app/userAnnouncementEvents/view-models/UserAnnouncementEventsVM'

export class AnnouncementParticipantsRowVM extends AGGridRowVM<AnnouncementParticipant> {
  private listVM: AnnouncementParticipantsListVM
  constructor(
    rootStore: RootStore,
    participant: AnnouncementParticipant,
    listVM: AnnouncementParticipantsListVM
  ) {
    super(rootStore, participant, listVM)
    this.listVM = listVM
  }

  private blankValue = '-'

  private formatDate(isoString: string) {
    if (!isoString) return this.blankValue
    return moment(isoString).format('lll')
  }

  @computed
  public get name(): string {
    return `${this.aggregate.lastName}, ${this.aggregate.firstName}`
  }

  @computed
  public get objectId(): string {
    return this.aggregate.objectId
  }

  @computed
  public get viewedDate(): string {
    return this.formatDate(this.aggregate.viewedDate)
  }

  @computed
  public get likedDate(): string {
    return this.formatDate(this.aggregate.likedDate)
  }

  @computed
  public get unlikedDate(): string {
    return this.formatDate(this.aggregate.unlikedDate)
  }

  @computed
  public get invitationCreated(): string {
    return this.formatDate(this.aggregate.invitationCreated)
  }

  @computed
  public get invitationUpdated(): string {
    return this.formatDate(this.aggregate.invitationUpdated)
  }

  @computed
  public get createdEmailStatus(): string {
    let status: EventType
    let date: string
    if (this.aggregate.createdEmailBounceDate) {
      status = 'bounce'
      date = this.aggregate.createdEmailBounceDate
    } else if (this.aggregate.createdEmailDropDate) {
      status = 'dropped'
      date = this.aggregate.createdEmailDropDate
    } else if (this.aggregate.createdEmailClickDate) {
      status = 'click'
      date = this.aggregate.createdEmailClickDate
    } else if (this.aggregate.createdEmailOpenDate) {
      status = 'open'
      date = this.aggregate.createdEmailOpenDate
    } else if (this.aggregate.createdEmailDeliverDate) {
      status = 'delivered'
      date = this.aggregate.createdEmailDeliverDate
    } else if (this.aggregate.createdEmailCreatedDate) {
      status = 'created'
      date = this.aggregate.createdEmailCreatedDate
    } else if (this.aggregate.createdEmailProcessedDate) {
      status = 'processed'
      date = this.aggregate.createdEmailProcessedDate
    } else if (this.aggregate.createdEmailErrorDate) {
      status = 'error'
      date = this.aggregate.createdEmailErrorDate
    } else {
      return this.blankValue
    }

    return `${status}: ${this.formatDate(date)}`
  }

  @computed
  public get updatedEmailStatus(): string {
    let status: EventType
    let date: string
    if (this.aggregate.updatedEmailBounceDate) {
      status = 'bounce'
      date = this.aggregate.updatedEmailBounceDate
    } else if (this.aggregate.updatedEmailDropDate) {
      status = 'dropped'
      date = this.aggregate.updatedEmailDropDate
    } else if (this.aggregate.updatedEmailClickDate) {
      status = 'click'
      date = this.aggregate.updatedEmailClickDate
    } else if (this.aggregate.updatedEmailOpenDate) {
      status = 'open'
      date = this.aggregate.updatedEmailOpenDate
    } else if (this.aggregate.updatedEmailDeliverDate) {
      status = 'delivered'
      date = this.aggregate.updatedEmailDeliverDate
    } else if (this.aggregate.updatedEmailCreatedDate) {
      status = 'created'
      date = this.aggregate.updatedEmailCreatedDate
    } else if (this.aggregate.updatedEmailProcessedDate) {
      status = 'processed'
      date = this.aggregate.updatedEmailProcessedDate
    } else {
      return this.blankValue
    }

    return `${status}: ${this.formatDate(date)}`
  }

  public click() {
    const eventsVM = new UserAnnouncementEventsVM(
      this.aggregate.organizationId,
      this.aggregate.announcementId,
      this.aggregate.userId,
      this.aggregate.firstName,
      this.aggregate.lastName
    )
    this.listVM.eventsVM = eventsVM
    this.listVM.toggleShowEventDialog()
  }
}
