import React from 'react'
import { observer } from 'mobx-react'
import { Typography, Grid, LinearProgress } from '@material-ui/core'
import './SurveyResultsScreen.scss'
import { SurveyResultsRowVM } from '../view-models/SurveyResultsRowVM'
import { QuestionVM } from '../view-models/QuestionVM'
import CheckIcon from '@material-ui/icons/Check'
import { SurveyResultsVM } from '../view-models/SurveyResultsVM'
import rootStore from '../../stores/RootStore'
import isMobile from 'src/utils/isMobile'

interface Props {
  index?: number
  result: SurveyResultsRowVM
  question: QuestionVM
  resultsVM: SurveyResultsVM
}

const CurrentQuestionOptionResultRow: React.FC<Props> = ({
  index,
  result,
  question,
  resultsVM,
}) => {
  if (!resultsVM) return null
  if (!result) return null
  const lz = rootStore.localizationStore.lzStrings.surveyResults
  if (!question) return null

  const renderEmoji = () => {
    if (question.type !== 'emoji') return null
    return (
      <img
        className='emoji-container'
        src={'../../../assets/emoticons/png/' + result.emoji + '.png'}
        alt=''
      />
    )
  }

  const renderProgressBar = () => {
    if (question.type === 'text') return null
    if (question.type === 'number') return null
    if (question.type === 'order') return null
    return (
      <Grid className='progress-bar-container'>
        <LinearProgress variant='determinate' value={result.progressPercent} />
      </Grid>
    )
  }

  const renderYesNoText = () => {
    if (question.type !== 'yesNo') return null
    return <Typography className='yesNo'>{result.shorterOptionText}</Typography>
  }

  const renderOptionText = () => {
    if (question.type === 'text') return null
    if (question.type === 'number') return null
    if (question.type === 'order') return null
    if (question.type === 'yesNo') return null
    if (question.type === 'emoji') {
      return (
        <Grid className='result-option-container'>
          <Typography className='result-option'></Typography>
        </Grid>
      )
    }
    return (
      <Grid className='result-option-container'>
        <Typography className='result-option'>{result.shorterOptionText}</Typography>
      </Grid>
    )
  }

  const renderPercent = () => {
    if (question.type === 'text') return null
    if (question.type === 'number') return null
    if (question.type === 'order') return null
    return (
      <>
        {renderIsChecked()}
        <Typography className='my-result-percent'>{result.percent}</Typography>
      </>
    )
  }

  const renderOrder = () => {
    if (question.type !== 'order') return null
    return (
      <>
        <div className='option-container-item'>
          <div className='my-result-order'>
            <div className='order-icon-container'>
              <div className='order-icon'>{index + 1}</div>
            </div>
            <Typography className='order-text'>{result.optionText}</Typography>
          </div>
        </div>
      </>
    )
  }

  const renderIsChecked = () => {
    if (!result.isChecked) return null
    return <CheckIcon className='checks-icon' />
  }

  const renderTypographyAnswer = () => {
    if (question.type !== 'text') return null
    return (
      <Grid className='my-text-container'>
        <Typography className='my-answer-text'>{lz.your_answer}:</Typography>
        <Typography className='my-result-text'>{result.optionText}</Typography>
      </Grid>
    )
  }

  const renderNumberAnswer = () => {
    if (question.type !== 'number') return null
    return (
      <Grid className='my-text-container'>
        <Typography className='my-answer-number'>
          {lz.your_answer}: {result.myNumberAnswer}
        </Typography>

        <Typography className='my-result-number'>
          {lz.average_answer}: {result.numberAverage}
        </Typography>
      </Grid>
    )
  }

  const renderOption = () => {
    return (
      <Grid container alignItems='flex-start' direction='column'>
        <Grid item className={isMobile ? 'option-container-mobile' : 'option-container'}>
          {renderYesNoText()}
          {renderEmoji()}
          {renderProgressBar()}
          {renderPercent()}
          {renderOrder()}
          {renderTypographyAnswer()}
          {renderNumberAnswer()}
        </Grid>
        {renderOptionText()}
      </Grid>
    )
  }

  return <>{renderOption()}</>
}

export default observer(CurrentQuestionOptionResultRow)
