import React from 'react'
import { inject, observer } from 'mobx-react'
import { RolesStore } from '../store/RolesStore'
import ParticipantsSelect from '../../participants-select/views/ParticipantsSelect'
import './RoleEditDrawer.scss'

interface Props {
  rolesStore?: RolesStore
}

const RolesMemberList: React.FC<Props> = ({ rolesStore }) => {
  const { editVM: vm } = rolesStore

  return (
    <div id='RoleMemberList'>
      <ParticipantsSelect participantsSelectVM={vm.participantsSelectVM} />
    </div>
  )
}

export default inject('rolesStore')(observer(RolesMemberList))
