import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { Button, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { SortableElement, SortableContainer } from 'react-sortable-hoc'
import { PulseQuestionEditVM } from '../../view-models/PulseQuestionEditVM'
import PulseQuestionOptionRow from './PulseQuestionOptionRow'
import { PulseQuestionOptionVM } from '../../view-models/PulseQuestionOptionVM'
import './PulseQuestionOptions.scss'

const SortableItem: any = SortableElement(({ value }) => (
  <div style={{ zIndex: 99999999 }} className='pulse-option-draggable'>
    {value}
  </div>
))

const SortableList: any = SortableContainer(({ items }) => {
  return (
    <div>
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} value={value} />
      ))}
    </div>
  )
})
interface Props {
  vm: PulseQuestionEditVM
}

const PulseQuestionOptions: React.FC<Props> = ({ vm }) => {
  if (!vm) return null

  const onSortEnd = ({ oldIndex, newIndex }, e) => {
    if (oldIndex === newIndex) return
    vm.moveOption(oldIndex, newIndex)
  }

  const renderOptions = () => {
    const options = []
    vm.options.forEach((e: PulseQuestionOptionVM, idx) => (
      options.push(<PulseQuestionOptionRow vm={e} key={'opt' + idx} />)
    ))
    return <SortableList distance={3} items={options} onSortEnd={onSortEnd} useDragHandle={true} />
  }

  const renderAddOption = () => {
    if (vm.questionType === 'text') return null
    if (!vm.isSystemAdmin) return null
    return (
      <Button
        className='add-option-button'
        fullWidth
        onClick={() => vm.addOption()}
      >
        <AddIcon />
      </Button>
    )
  }

  return (
    <div id='PulseQuestionOptions'>
      <Typography className='question-options-header'>Options</Typography>
      {renderOptions()}
      {renderAddOption()}
    </div>
  )
}

export default inject()(observer(PulseQuestionOptions))
