import React from 'react'
import { inject, observer } from 'mobx-react'
import { Box, AppBar, Toolbar, Tabs, Tab } from '@material-ui/core'
import { WorksheetsStore } from '../../store/WorksheetsStore'
import WorkbookTabPanel from './WorkbookTabPanel'
import OptionsTabPanel from './OptionsTabPanel'
import PermissionsTabPanel from './PermissionsTabPanel'
import AssignmentIcon from '@material-ui/icons/Assignment'
import TuneIcon from '@material-ui/icons/Tune'
import PersonPinIcon from '@material-ui/icons/PersonPin'
import './TabsBar.scss'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div id='TabPanel' hidden={value !== index} {...other}>
      <Box component='div'>{children}</Box>
    </div>
  )
}

interface Props {
  worksheetsStore?: WorksheetsStore
}

const TabsBar: React.FC<Props> = ({ worksheetsStore }) => {
  const { editVM } = worksheetsStore
  if (!editVM) return null

  const handleChange = (e: any, newValue: number) => {
    editVM.setCurrentTab(newValue)
  }

  return (
    <>
      <div id='TabsBar'>
        <AppBar position='static' elevation={0}>
          <Toolbar>
            <div>
              <Tabs
                value={editVM.currentTabIndex}
                onChange={handleChange}
                indicatorColor={'primary'}
                textColor='primary'
                variant='fullWidth'
              >
                <Tab
                  icon={<AssignmentIcon />}
                  label='Workbook'
                  value={0}
                  className={editVM.isVizValid ? '' : 'error'}
                />
                <Tab
                  icon={<TuneIcon />}
                  label='Options'
                  value={1}
                  className={editVM.isOwnerValid ? '' : 'error'}
                />
                <Tab icon={<PersonPinIcon />} label='Permissions' value={2} />
              </Tabs>
            </div>
          </Toolbar>
        </AppBar>

        <TabPanel value={editVM.currentTabIndex} index={0}>
          <WorkbookTabPanel />
        </TabPanel>
        <TabPanel value={editVM.currentTabIndex} index={1}>
          <OptionsTabPanel />
        </TabPanel>
        <TabPanel value={editVM.currentTabIndex} index={2}>
          <PermissionsTabPanel />
        </TabPanel>
      </div>
    </>
  )
}

export default inject('worksheetsStore')(observer(TabsBar))
