import { DataStore } from '../../shared/data/DataStore'
import { RootStore } from '../../stores/RootStore'
import { SurveyTemplate } from '../aggregates/SurveyTemplate'
import { ISurveyTemplateDTO } from '../dtos/ISurveyTemplateDTO'
import { SurveyTemplatesService } from '../services/SurveyTemplatesService'
import { ISurveyTemplatesFindRequest } from '../interfaces/ISurveyTemplatesFindRequest'
import { observable } from 'mobx'

export class SurveyTemplatesTableDataStore extends DataStore<SurveyTemplate, ISurveyTemplateDTO> {
  private request: ISurveyTemplatesFindRequest

  constructor(rootStore: RootStore, request: ISurveyTemplatesFindRequest) {
    super(rootStore, SurveyTemplate, 'surveyTemplates', [
      'organizationId',
      'name',
      'questionsCount',
      'publishedByUserId',
      'createdAt',
      'updatedAt',
      'publishedByUserName',
      'owner',
      'fk_owner',
      'updatedByUserId',
      'createdByUserId',
      'folderId',
      'subscribedOrganizationIds'
    ])
    this.request = request
    this.paged = true
    this.recordsPerPage = 100
    this.sortColumnName = 'name'
    this.pageNumber = 1
    this.listenToListRecordsViaApiUpdates = true
  }

  @observable public isLoaded: boolean = false

  public onListRecordsLoaded() {
    this.isLoaded = true
  }

  public setRequest(request: ISurveyTemplatesFindRequest) {
    this.request = request
  }

  public getRequest(): ISurveyTemplatesFindRequest {
    return this.request
  }

  protected getListRecordsApiCall() {
    this.isLoaded = false
    return async () => {
      const req: ISurveyTemplatesFindRequest = {
        ...this.request,
        // userId: this.rootStore.appStore.currentUserId,
        // organizationId: 'DEFAULT',
        // folderId: null,
        terms: this.filter,
        skip: this.recordsPerPage * (this.pageNumber - 1),
        limit: this.recordsPerPage,
        sortColumnName: this.sortColumnName,
        sortDirection: this.sortDirection,
        listColumns: this.listColumns,
      }
      const svc = new SurveyTemplatesService(this.rootStore)
      const result = await svc.getSurveyTemplates(req)
      this.totalRecords = result.count
      return result.surveyTemplates
    }
  }

  public get surveyTemplates() {
    return this.records
  }
}
