import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { ParticipantsSelectVM } from '../view-models/ParticipantsSelectVM'
import { AgGridReact } from 'ag-grid-react'
import Searchbar from '../../shared/Searchbar'
import ClientRow from './ClientRow'
import { ClientRowVM } from '../view-models/ClientRowVM'

interface Props {
  participantsSelectVM: ParticipantsSelectVM
}

const ClientsList: FC<Props> = ({ participantsSelectVM: vm }) => {
  if (!vm.clientsAGGridVM) return

  const renderNameCell = (props) => {
    const row: ClientRowVM = props.data
    if (!row) return null
    return <ClientRow row={row} />
  }

  const renderGrid = () => {
    return (
      <div
        className='ag-theme-alpine'
        style={{
          height: '100%',
          width: '100%',
          display: vm.clientsAGGridVM.shown ? undefined : 'none',
        }}
      >
        <AgGridReact
          gridOptions={{
            ...vm.clientsAGGridVM.getGridOptions(),
            frameworkComponents: {
              nameCell: renderNameCell,
            },
          }}
        />
      </div>
    )
  }

  const renderSearchbox = () => {
    if (!vm.clientsAGGridVM.shown) return
    return (
      <Searchbar
        focused={vm.clientsAGGridVM.hasFilter}
        className='search'
        searchValue={vm.clientsAGGridVM.typedFilterText}
        onSearchChange={(e) => vm.clientsAGGridVM.setQuickFilter(e.target.value)}
      />
    )
  }

  return (
    <div
      id='ParticipantsSelect_ClientsList'
      style={{
        display: vm.clientsAGGridVM.shown ? undefined : 'none',
      }}
    >
      {renderSearchbox()}
      {renderGrid()}
    </div>
  )
}

export default observer(ClientsList)
