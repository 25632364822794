import React from 'react'
import { inject, observer } from 'mobx-react'
import { TabEnums } from '../../../types/TabEnums'
import ParticipantsSelect from '../../../../participants-select/views/ParticipantsSelect'
import { GoalsStore } from '../../../store/GoalsStore'
import goalsParticipantsSvg from '../../../../../assets/img/goals/goals-participants.svg'
import './ParticipantsTabPanel.scss'

interface Props {
  goalsStore?: GoalsStore
}

const ParticipantsTabPanel: React.FC<Props> = ({ goalsStore }) => {
  const { goalEditVM: vm } = goalsStore
  if (!vm) return null
  if (vm.currentTab !== TabEnums.PARTICIPANTS) return null

  return (
    <div id='ParticipantsTabPanel'>
      <ParticipantsSelect participantsSelectVM={vm.participantsSelectVM} />
      <img className='participants-image' src={goalsParticipantsSvg} />
    </div>
  )
}

export default inject('goalsStore')(observer(ParticipantsTabPanel))
