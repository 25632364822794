import { RootStore } from '../../stores/RootStore'
import { observable, computed, action } from 'mobx'
import { Worksheet } from '../../worksheets/aggregate/Worksheet'
import { UserWorksheet } from '../aggregate/UserWorksheet'
import { TableauAuthTokensService } from '../../tableauAuthTokens/service/TableauAuthTokensService'
import { TableauRestService } from '../../tableauRest/service/TableauRestService'
import { UserWorksheetsService } from '../service/UserWorksheetsService'
import { IUserWorksheetBookmarkRequest } from '../interfaces/IUserWorksheetBookmarkRequest'

export class ContentItemVM {
  private rootStore: RootStore
  private svc: TableauRestService

  private tableauViz: tableau.Viz

  constructor(rootStore: RootStore, worksheet: Worksheet, userWorksheet: UserWorksheet) {
    this.rootStore = rootStore
    this.svc = new TableauRestService(this.rootStore)
    this.worksheet = worksheet
    this.userWorksheet = userWorksheet
    if (this.worksheet && this.worksheet.forLoggedInUser)
      this.worksheet.setLoggedInUser(rootStore.appStore.currentUserId)
    if (this.worksheet && !this.worksheet.forSurveys) this.worksheet.surveyId = ''
    if (this.userWorksheet) this.isBookmark = this.userWorksheet.isBookmark
    if (this.userWorksheet) this.sortOrder = this.userWorksheet.sortOrder
    this.loadPreviewImage()
  }

  @observable public worksheet: Worksheet
  @observable public userWorksheet: UserWorksheet
  @observable private authToken: string = ''
  @observable public thumbNail: string = undefined
  @observable public loading: boolean = true
  @observable public isLoaded: boolean = true
  @observable public scriptLoaded: boolean = false
  @observable public highlightedData: string = ''
  @observable private isBookmark: boolean = false
  @observable private sortOrder: number = 0

  @action
  public setTableauVizContainer(div, viz) {
    this.isLoaded = true
    this.tableauViz = viz
    this.captureMarks()
  }

  @action
  public setScriptLoaded(scriptLoaded: boolean) {
    this.scriptLoaded = scriptLoaded
  }

  private captureMarks() {
    this.tableauViz.addEventListener(
      tableau.TableauEventName.MARKS_SELECTION,
      (marks: tableau.MarksEvent) => {
        marks.getMarksAsync().then((marks: tableau.Mark[]) => {
          const pairs = marks[0].getPairs()
          this.highlightedData = JSON.stringify(pairs)
        })
      }
    )
  }

  @computed
  public get canRevertAll(): boolean {
    if (!this.worksheet) return false
    return !Boolean(this.worksheet.forLoggedInUser)
  }

  @action
  public async revertAll(): Promise<void> {
    if (!this.tableauViz) return
    await this.tableauViz.revertAllAsync()
  }

  @computed
  public get id(): string {
    if (!this.worksheet) return ''
    return this.worksheet.objectId
  }

  @computed
  public get isBookmarked(): boolean {
    return this.isBookmark
  }

  @computed
  public get sortNumber(): number {
    return this.sortOrder
  }

  @computed
  public get name(): string {
    if (!this.worksheet) return 'N/A'
    return this.worksheet.name
  }

  @computed
  public get tableauWorksheetDisplayName(): string {
    if (!this.worksheet) return 'N/A'
    return this.worksheet.tableauWorksheetDisplayName
  }

  @computed
  public get tableauWorkbook(): string {
    if (!this.worksheet) return 'N/A'
    return this.worksheet.tableauWorkbook
  }

  @computed
  public get updatedAt(): Date {
    if (!this.worksheet) return undefined
    return this.worksheet.updatedAt
  }

  @computed
  public get userWorksheetsService(): UserWorksheetsService {
    return this.rootStore.userWorksheetsStore.svc
  }

  @computed
  public get userEmail(): string {
    if (!this.rootStore.userStore.user) return null
    if (!this.rootStore.userStore.user.email) return null
    return this.rootStore.userStore.user.email
  }

  @action
  public async loadPreviewImage() {
    if (!this.userEmail) {
      setTimeout(() => this.loadPreviewImage(), 400)
      return
    }
    this.thumbNail = await this.svc.getPreviewImage(
      this.userEmail,
      this.worksheet.tableauWorkbook,
      this.worksheet.tableauWorksheet,
      '.png'
    )
  }

  @action
  public handleImageLoad() {
    this.loading = false
  }

  @action
  public setWorksheet(worksheet: Worksheet) {
    this.worksheet = worksheet
  }

  @action
  public async setBookmark(isBookmark: boolean) {
    const req: IUserWorksheetBookmarkRequest = {
      userId: this.rootStore.appStore.currentUserId,
      organizationId: this.rootStore.userStore.currentOrganization.objectId,
      worksheetId: this.worksheet.objectId,
      isBookmark: isBookmark,
    }
    this.isBookmark = isBookmark
    const result = await this.userWorksheetsService.bookmarkUserWorksheet(req)
    if (result.success) this.isBookmark = result.userWorksheet.isBookmark
    if (!result.success) this.isBookmark = !isBookmark
  }

  @action
  public setSortOrder(sortOrder: number) {
    this.sortOrder = sortOrder
  }

  @action
  public async loadTableauAuthToken() {
    if (!this.worksheet) return
    if (!this.worksheet.tableauSiteName || this.worksheet.tableauSiteName === '') return
    this.authToken = ''
    const svc = new TableauAuthTokensService(this.rootStore)
    const token = await svc.getAuthToken()
    this.authToken = token
  }

  @action
  public update(worksheet: Worksheet, userWorksheet: UserWorksheet) {
    this.worksheet = worksheet
    this.userWorksheet = userWorksheet
    if (this.worksheet && this.worksheet.forLoggedInUser)
      this.worksheet.setLoggedInUser(this.rootStore.appStore.currentUserId)
    if (this.worksheet && !this.worksheet.forSurveys) this.worksheet.surveyId = ''
    if (this.userWorksheet) this.isBookmark = this.userWorksheet.isBookmark
    if (this.userWorksheet) this.sortOrder = this.userWorksheet.sortOrder
    return this
  }

  @computed
  public get getTableauAuthToken(): string {
    return this.authToken
  }

  @computed
  public get hasPermission() {
    if (this.rootStore.appStore.isOrgAdmin) return true
    if (!this.id) return false
    if (this.id === '') return false
    if (!this.rootStore.userStore.currentOrganization) return false
    if (!this.worksheet) return false
    if (this.worksheet.owner === this.rootStore.appStore.currentUserId) return true
    if (!this.worksheet.visibleTo) return false
    let isVisible = false
    const foundUser = this.worksheet.visibleTo.find(
      (e) => e.id === this.rootStore.appStore.currentUserId
    )
    if (foundUser) isVisible = true
    const roles = this.worksheet.visibleTo.filter((e) => e.type === 'role')
    if (roles.length) {
      roles.forEach((role) => {
        const foundRole = this.rootStore.userStore.currentOrganization.roles.find(
          (e) => e.id === role.id
        )
        if (foundRole) isVisible = true
      })
    }
    const groups = this.worksheet.visibleTo.filter((e) => e.type === 'group')
    if (groups.length) {
      groups.forEach((group) => {
        const foundRole = this.rootStore.userStore.currentOrganization.groups.find(
          (e) => e.id === group.id
        )
        if (foundRole) isVisible = true
      })
    }
    return isVisible
  }
}
