import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { MediaItemsStore } from '../../store/MediaItemsStore'
import {
  Drawer,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Grid,
  InputLabel,
  OutlinedInput,
  Button,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'
import SpinnerOverlay from '../../../shared/SpinnerOverlay'
import MediaItemsFileUploader from './MediaItemFileUploader'
import MuiConfirmDeleteDialog from '../../../shared/MuiConfirmDeleteDialog'
import './MediaItemDrawer.scss'
import MuiDrawerToolbar from '../../../shared/Mui-Drawer/MuiDrawerToolbar'
import { Attachment } from '../../../upload/aggregate/Attachment'
import { FileTypeEnum } from '../../../upload/enum/FileTypeEnum'
import FileUploadModal from '../../../upload/views/FileUploadModal'
import CMSItemsPickerDialog from '../../../cms-items/views/dialogs/CMSItemsPickerDialog'
import { CMSItemsTypeEnum } from '../../../cms-items/enum/CMSItemsTypeEnum'
import { OrganizationsStore } from '../../../organizations/store/OrganizationsStore'

interface MediaItemsDrawerProps {
  localizationStore?: LocalizationStore
  mediaItemsStore?: MediaItemsStore
  organizationsStore?: OrganizationsStore
}

const MediaItemDrawer: React.FC<MediaItemsDrawerProps> = ({
  localizationStore,
  mediaItemsStore,
  organizationsStore,
}) => {
  const { lzStrings } = localizationStore
  const { mediaItemDrawerVM: vm } = mediaItemsStore
  if (!vm) return null

  const renderChangeMediaButton = () => {
    if (!vm.fileUrl) return null
    if (organizationsStore.currentOrganization?.hasCMSPicker) return
    return (
      <Button variant='contained' onClick={() => vm.showEditWidget()}>
        {lzStrings.mediaItemDrawer.change_media}
      </Button>
    )
  }

  const renderMediaUploadModal = () => {
    if (organizationsStore.currentOrganization?.hasCMSPicker) return renderCMSUploadModal()
    return renderStandardUploadModal()
  }

  const renderCMSUploadModal = () => {
    return (
      <CMSItemsPickerDialog
        allowMultiple={false}
        fileTypes={CMSItemsTypeEnum.IMAGES_VIDEOS}
        addAfterSave={(a: Attachment[]) => {
          vm.addMedia(a[0])
        }}
        toggleShowModal={() => vm.toggleMediaUploadModal()}
        isOpen={vm.showMediaUploadModal}
      />
    )
  }

  const renderStandardUploadModal = () => {
    return (
      <FileUploadModal
        allowMultiple={false}
        fileTypes={FileTypeEnum.IMAGES_VIDEOS}
        addAfterSave={(a: Attachment[]) => vm.addMedia(a[0])}
        toggleShowModal={() => vm.toggleMediaUploadModal()}
        isOpen={vm.showMediaUploadModal}
      />
    )
  }

  return (
    <Drawer
      anchor='right'
      open={mediaItemsStore.isMediaItemDrawerOpen}
      onClose={() => mediaItemsStore.closeDrawer()}
      id='MediaItemDrawer'
    >
      {renderMediaUploadModal()}
      {vm?.isProcessing && <SpinnerOverlay />}
      <Card elevation={0} id='MediaItemDrawer'>
        <CardHeader
          action={
            <IconButton onClick={() => mediaItemsStore.closeDrawer()}>
              <CloseIcon />
            </IconButton>
          }
          title={
            vm.objectId
              ? lzStrings.mediaItemDrawer.edit_media_item
              : lzStrings.mediaItemDrawer.add_media_item
          }
        />
        <MuiDrawerToolbar
          handleCancelClick={() => {
            mediaItemsStore.closeDrawer()
          }}
          handleSubmitClick={() => vm.saveMediaItem()}
          showReset={Boolean(vm.objectId)}
          onConfirmReset={() => mediaItemsStore.openDrawer(vm.objectId)}
          moreMenuProps={{
            hidden: !vm.objectId,
            isEditMode: true,
            itemType: lzStrings.moreMenu.obj_type.media_item,
            itemName: vm.name,
            onConfirmDelete: () => vm.deleteMediaItem(),
          }}
        />
        <CardContent>
          <section>
            <h6>{lzStrings.mediaItemDrawer.media_item_details}</h6>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputLabel htmlFor='mediaItemName' required>
                    {lzStrings.mediaItemDrawer.name}
                  </InputLabel>
                  <OutlinedInput
                    id='mediaItemName'
                    fullWidth
                    labelWidth={0}
                    value={vm.mediaItem.name}
                    onChange={(e) => vm.setName(e.target.value)}
                    error={!vm.nameValid}
                    required
                  />
                </Grid>
                {(vm.objectId || (!vm.objectId && Boolean(vm?.file))) && (
                  <>
                    <Grid item xs={12}>
                      <span className='media-item-detail-header'>
                        {lzStrings.mediaItemDrawer.created}
                      </span>
                      <span className='media-item-detail-field'>{vm?.formattedDate}</span>
                    </Grid>
                    <Grid item xs={12}>
                      <span className='media-item-detail-header'>
                        {lzStrings.mediaItemDrawer.size}
                      </span>
                      <span className='media-item-detail-field'>{vm?.formattedFileSize}</span>
                    </Grid>
                  </>
                )}
                {vm.objectId ? (
                  <Grid item xs={12}>
                    {vm?.type === 'image' && <img src={vm.fileUrl} className={'preview-image'} />}
                    {vm?.type === 'video' && (
                      <video className={'preview-video'} controls>
                        <source src={vm?.fileUrl} type='video/mp4'></source>
                      </video>
                    )}
                  </Grid>
                ) : (
                  <MediaItemsFileUploader />
                )}
                <div className='media-button'>{renderChangeMediaButton()}</div>
              </Grid>
            </form>
          </section>
        </CardContent>
      </Card>
      <MuiConfirmDeleteDialog
        onClose={() => vm?.toggleDeleteDialog()}
        open={vm?.showDeleteDialog || false}
        itemType={'Media'}
        itemName={vm?.name}
        onConfirm={() => vm?.deleteMediaItem()}
      />
    </Drawer>
  )
}

export default inject('localizationStore', 'mediaItemsStore', 'organizationsStore')(observer(MediaItemDrawer))
