import React from 'react'
import { inject, observer, useLocalStore } from 'mobx-react'
import { Button, Grid, Tooltip, Typography } from '@material-ui/core'
import './CampaignTemplatesList.scss'
import { PulseCampaignTemplatesListVM } from '../../../view-models/dashboard/templates/PulseCampaignTemplatesListVM'
import { CampaignTemplateRowVM } from '../../../view-models/dashboard/templates/CampaignTemplateRowVM'
import TemplateRowActions from './TemplateRowActions'
import LocalizationStore from '../../../../localization/LocalizationStore'

interface Props {
  vm: PulseCampaignTemplatesListVM
  localizationStore?: LocalizationStore
}

const CampaignTemplatesList: React.FC<Props> = ({ vm, localizationStore }) => {
  if (!vm) return null
  const lz = localizationStore.lzStrings.pulseCampaignDashboard

  const renderPulseCampaignTemplates = () => {
    const templates = []
    vm.pulseCampaigns.forEach((p, i) => {
      if (vm.templateLayout === 0) return templates.push(renderCampaignTemplateRow(p, i))
      templates.push(renderCampaignTemplateGrid(p, i))
    })
    if (vm.templateLayout === 0) return (
      <Grid container className={'templates-container'}>
        {templates}
      </Grid>
    )
    return (
      <Grid container spacing={3} className={'templates-container'}>
        {templates}
      </Grid>
    )
  }

  const renderCampaignTemplateRow = (template: CampaignTemplateRowVM, index: number) => {
    return (
      <Grid item xs={12} key={index + vm.templateLayout}>
        <div className={'campaign-template-row'}>
          <div className='template-preview' />
          <div className={'template-details'}>
            <div className={'template-name'}>{template.name}</div>
            <div className={'template-actions'}><TemplateRowActions vm={template} /></div>
          </div>
        </div>
      </Grid>
    )
  }

  const renderCampaignTemplateGrid = (template: CampaignTemplateRowVM, index: number) => {
    return (
      <Grid item xs={3} key={index + vm.templateLayout} >
        <div className={'campaign-template-grid'}>
          <div className='template-preview' />
          <div className={'template-details'}>
            <div className={'template-name'}>{template.name}</div>
            <div className={'template-actions'}>{renderUseTemplate(template)}</div>
          </div>
        </div>
      </Grid>
    )
  }

  const renderUseTemplate = (vm: CampaignTemplateRowVM) => {
    return (
      <div className={'use-template'} onClick={(() => vm.copyTemplate())}>
          <div className='arrow'>
            <div className='line'></div>
            <div className='point-right'></div>
          </div>
        <div className={'use-template-text'}>
          {lz.use_template}
        </div>
      </div>
    )
  }


  return (
    <div id='CampaignTemplatesList'>
      {renderPulseCampaignTemplates()}
    </div>
  )
}

export default inject('localizationStore')(observer(CampaignTemplatesList))
