import React from 'react'
import { inject, observer, useLocalStore } from "mobx-react"
import { AnnouncementParticipantsListVM } from "../../view-models/analytics/AnnouncementParticipantsListVM"
import { AgGridReact } from 'ag-grid-react'
import { CircularProgress } from '@material-ui/core'
import './AnnouncementParticipantsList.scss'
import UserAnnouncementEventsDialog from 'src/app/userAnnouncementEvents/views/UserAnnouncementEventsDialog'

interface Props {
    tableVM: AnnouncementParticipantsListVM,
    announcementId: string
}
const AnnouncementParticipantsList: React.FC<Props> = ({tableVM, announcementId}) => {
      const vm = tableVM
      if (!vm) return

      const renderSpinner = () => {
        if (vm.shouldRender || !vm.loadingFirstPage) return
        return (
          <div className={'spinner-container'}>
            <CircularProgress className='spinner' />
          </div>
        )
      }

    const renderGrid = () => {
        if (!vm.shouldRender) return
        return (
            <div className={vm.hasRows ? 'ag-theme-alpine' : 'ag-theme-alpine ag-grid-no-rows'}>
            <AgGridReact
                gridOptions={{
                ...vm.getGridOptions(),
                }}
            />
            </div>
        )
    }

    const renderNoParticipants = () => {
      if (vm.loadingDataStore || vm.loadingFirstPage || vm.dataStore.totalRecords !== 0 ) return
      return (
        <div className='no-participants-container'>
          No Participants Found
        </div>
      )

    }

    return (
        <>
        {renderSpinner()}
        <UserAnnouncementEventsDialog listVM={vm}/>
        {renderGrid()}
        {renderNoParticipants()}
        </>
    )
}

export default inject()(observer(AnnouncementParticipantsList))