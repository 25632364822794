import { action, computed, observable } from 'mobx'
import uuid from 'uuid/v4'
import { Attachment } from '../../../upload/aggregate/Attachment'
import { CloudinaryResult } from '../../../upload/aggregate/CloudinaryResult'
import { FileUploadService } from '../../../upload/services/FileUploadService'
import { NotificationTemplate } from '../aggregates/NotificationTemplate'
import { RootStore } from '../../../stores/RootStore'
import { NotificationsStore } from '../NotificationsStore'
import { LabelsStore } from '../../../labels/store/LabelsStore'
import { ParseService } from '../../../services/ParseService'
import { INotificationTemplateDTO } from '../model/INotificationTemplateDTO'

export class EditVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.labelsStore = this.rootStore.labelsStore
  }

  @computed
  private get notificationsStore(): NotificationsStore {
    return this.rootStore.notificationsStore
  }

  @observable cleanTemplate: NotificationTemplate = null
  @observable objectId: string = ''
  @observable isDefault: boolean = false
  @observable channel: string = ''
  @observable language: string = ''
  @observable notificationTypeId: string = ''
  @observable subject: string = ''
  @observable body: string = ''
  @observable editorState = ''
  @observable isFormDialogOpen: boolean = false
  @observable isFormDrawerOpen: boolean = false
  @observable isSaving: boolean = false
  @observable saveSuccessful: boolean = false
  @observable isDirty: boolean = false
  @observable resetDialog: boolean = false
  @observable public labelsStore: LabelsStore = null
  @observable editorTabIndex: number = 0
  @observable organizationId: string = ''
  @observable confirmDialogOpen: boolean = false
  @observable doClose: boolean = false
  @observable doReset: boolean = false
  @observable isSystemEdit: boolean = false
  @observable showLinkDialog: boolean = false
  @observable public showMediaModal: boolean = false
  @observable public editorRef: any = null

  @computed
  public get currentTemplate(): string {
    const orgId = this.isSystemEdit ? 'DEFAULT' : this.rootStore.appStore.currentOrgId
    let template =
      this.rootStore.emailTemplatesStore.getCurrentOrgEmailTemplateByNotificationTypeId(
        orgId,
        this.notificationTypeId
      )
  if (!template)
      template = this.rootStore.emailTemplatesStore.getCurrentOrgEmailTemplateByNotificationTypeId(
        orgId,
        'DEFAULT'
      )
  if (!template)
      template = this.rootStore.emailTemplatesStore.getCurrentOrgEmailTemplateByNotificationTypeId(
        'DEFAULT',
        'DEFAULT'
      )
    if (template) return template.html
    return undefined
  }

  @computed
  public get currentHTML(): string {
    const template = this.currentTemplate
    if (!template) return ''
    const html = template.replaceAll('{{{body}}}', this.body)
    return this.replaceMarkupForPreview(html)
  }

  @action
  public replaceMarkupForPreview(body: string) {
    if (!body) return ''

    return body
      .replaceAll('<!DOCTYPE html>', '')
      .replaceAll('<html', '<div')
      .replaceAll('</html', '</div')
      .replaceAll('<body', '<div')
      .replaceAll('</body', '</div')
      .replaceAll('<head', '<div')
      .replaceAll('</head', '</div')
      .replaceAll(/\{\w*Link\}/g, 'javascript:void(0)')
  }

  @action
  public setBody(value: string) {
    this.body = value
    if (this.cleanTemplate.body !== this.body) this.isDirty = true
    else this.isDirty = false
  }

  @action
  public setEditorTabIndex(value: number) {
    this.editorTabIndex = value
  }

  @action
  public setEditorRef(editorRef: any) {
    this.editorRef = editorRef
  }

  private get beautifyOpts() {
    return {
      'brace_style': 'expand',
      'indent_scripts': 'separate',
      'wrap-attributes': 'force-expand-multiline',
      'wrap_line_length': '350',
      // 'extra_liners': ['head', 'body', '/html', 'p', '/p', 'a'],
      'wrap-attributes-indent-size': 1,
      'indent-inner-html': false,
      "indent_size": "1",
      "indent_char": " ",
      "jslint_happy": true,
    }
  }

  @action populate(object: NotificationTemplate, isSystemEdit: boolean = false) {
    this.isDirty = false
    this.cleanTemplate = object
    this.objectId = object.objectId
    this.subject = object.subject
    this.body = object.body
    if(this.isEmail) {
      this.cleanTemplate.body = this.body
    }
    this.editorState = object.body
    this.channel = object.channel
    this.language = object.language
    this.isDefault = object.isDefault
    this.organizationId = object.organizationId
    this.notificationTypeId = object.notificationTypeId
    this.isSystemEdit = isSystemEdit
    // mitigates default wysiwyg editor removing certain html tags by default
    setTimeout(() => {
      this.isDirty = false
    }, 250)
  }

  @action getNotificationTemplate() {
    const template = this.notificationsStore.notificationTemplates.filter(
      (e) => e.objectId === this.objectId
    )

    this.populate(template[0], this.isSystemEdit)
  }

  @action setField(key: string, value: string | boolean) {
    this[key] = value
    this.isDirty = true
  }

  @action
  public async getDefaultNotification() {
    const svc = new ParseService()
    const data = await svc.getDefaultNotificationTemplate(
      this.rootStore.appStore.currentOrgId,
      this.channel,
      this.notificationTypeId,
      this.language
    )
    if (data && this.isDefault === false) {
      await svc.deleteNotificationTemplate(this.rootStore.appStore.currentOrgId, this.objectId)
      setTimeout(() => this.notificationsStore.loadListRecords(), 1000)
    }
    this.populate(data, this.isSystemEdit)
  }

  @computed get isSubjectValid() {
    return Boolean(this.subject)
  }

  @computed get isBodyValid() {
    return Boolean(this.body)
  }

  @computed get saveEnabled() {
    if (!this.isSubjectValid || !this.isBodyValid) return false
    if (this.isSaving) return false
    if (this.isDirty) return true
    return false
  }

  @computed get isEmail() {
    return this.channel === 'EMAIL'
  }

  @action openEditor() {
    if (this.isEmail) this.isFormDialogOpen = true
    else this.isFormDrawerOpen = true
  }

  @action closeEditor() {
    if (this.isEmail) this.isFormDialogOpen = false
    else this.isFormDrawerOpen = false

    this.doClose = false
    this.isDirty = false
    this.editorTabIndex = 0
  }

  @action toggleResetDialog() {
    this.resetDialog = !this.resetDialog
  }

  @action
  public handleReset = () => {
    this.getNotificationTemplate()
    // mitigates default wysiwyg editor removing certain html tags by default
    setTimeout(() => {
      this.isDirty = false
    }, 250)
  }

  public async saveNotification() {
    this.isSaving = true
    const { channel, notificationTypeId, language, subject, isDefault, body } = this
    // const typeId = this.revertTextBackToSurveyBeforeSaving(notificationTypeId)
    // const sub = this.revertTextBackToSurveyBeforeSaving(subject)
    // const bod = this.revertTextBackToSurveyBeforeSaving(body)
    const pSvc = new ParseService()
    let result: INotificationTemplateDTO
    if (this.isSystemEdit)
      result = await pSvc.saveDefaultNotificationTemplate(
        this.rootStore.appStore.currentOrgId,
        channel,
        notificationTypeId,
        language,
        subject,
        body,
        isDefault
      )
    else
      result = await pSvc.saveNotificationTemplate(
        this.rootStore.appStore.currentOrgId,
        channel,
        notificationTypeId,
        language,
        subject,
        body,
        isDefault
      )
    if (result) {
      this.notificationsStore.loadListRecords()
      this.saveSuccessful = true
      setTimeout(() => {
        this.isFormDrawerOpen = false
        this.populate(result as NotificationTemplate, this.isSystemEdit)
        this.isSaving = false
      }, 1000)
    }
  }

  @action
  public toggleConfirmDialog() {
    this.confirmDialogOpen = !this.confirmDialogOpen
  }

  @action
  public cancelConfirmDialog() {
    this.confirmDialogOpen = false
    this.doClose = false
    this.doReset = false
  }

  @action
  public toggleShowLinkDialog() {
    this.showLinkDialog = !this.showLinkDialog
  }

  @action
  public addMediaToInfoTextFromComputer(attachment: Attachment) {
    let content = ''
    if (attachment.type === 'video') {
      const url = attachment.url.toLowerCase()
      if (url.includes('vimeo')) {
        let regExp = /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/
        if (attachment.url.includes('player.vimeo.com')) {
          regExp = /(http|https)?:\/\/(www\.)?player.vimeo.com\/(?:video\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/
        }
        const match = attachment.url.match(regExp)
        const uuid = match && match.length ? match[4] : ''
        content = `<iframe height=220 width=320 src=${`https://player.vimeo.com/video/${uuid}`} allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullscreen />`
      } else if (url.includes('youtube') || url.includes('youtu.be')) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
        const match = attachment.url.match(regExp)
        const uuid = match && match[2].length === 11 ? match[2] : ''
        content = `<iframe height=220 width=320 src=${`https://www.youtube.com/embed/${uuid}`} allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullscreen />`
      } else {
        content = `<video height=150 width=250 alt=${attachment.fileName} poster=${attachment.thumbnail} src=${attachment.url} controls='controls' />`
      }
    }
    if (attachment.type === 'image') {
      content = `<img alt=${attachment.fileName} src=${attachment.url} height=150 width=150 />`
    }
    return setTimeout(() => {
      this.editorRef?.current?.editor?.execCommand('mceInsertContent', false, content)
    }, 1000)
  }

  @action
  public toggleMediaUploadModal() {
    this.showMediaModal = !this.showMediaModal
  }

  public async uploadToCloudinary(blobInfo, success, failure, progress) {
    try {
      const blob = blobInfo.blob()
      var file = new File([blob], `tiny-${uuid()}`)
      const uploadSvc = new FileUploadService()
      const res: CloudinaryResult[] = await uploadSvc.uploadMediaItemsToCloudinary([file])
      if (res) return success(res[0].secure_url)
    } catch (e) {
      if (e) {
        return failure(e.toString())
      }
    }
  }
}
