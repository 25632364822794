import Parse from 'parse'
import { RootStore } from '../../../stores/RootStore'
import { DataStore } from '../../../shared/data/DataStore'
import { OrganizationUser } from '../../aggregate/OrganizationUser'
import { IOrganizationUserDTO } from '../../dtos/IOrganizationUserDTO'
import { computed, observable } from 'mobx'

export class OrganizationUsersWidgetDataStore extends DataStore<
  OrganizationUser,
  IOrganizationUserDTO
> {
  constructor(rootStore: RootStore, onlyAddArchived: boolean, showHidden: boolean) {
    super(rootStore, OrganizationUser, 'organizationUsers', [
      'objectId',
      'organizationId',
      'roles',
      'groups',
      'title',
      'primaryGroupId',
      'primaryRoleId',
      'fk_User',
      'isArchived',
      'isHiddenInOrg',
      'archivedDate',
      'userId',
    ])
    this.paged = true
    this.recordsPerPage = 100
    this.sortColumnName = 'fk_User.lastName'
    this.onlyAddArchived = onlyAddArchived
    this.showHidden = showHidden
    this.filterColumns = [
      'fk_User.lastName',
      'fk_User.firstName',
      'fk_roles.name',
      'fk_groups.name',
      'fk_User.email',
      'fk_User.name',
    ]
  }

  @observable public onlyAddArchived: boolean = false
  @observable public showHidden: boolean = false
  protected getBaseQuery() {
    let query = new Parse.Query(this.className)
    query.equalTo('organizationId', this.rootStore.appStore.currentOrgId)
    query.equalTo('disabled', false)
    query.equalTo('isArchived', this.onlyAddArchived)
    if (!this.showHidden) query.equalTo('isHiddenInOrg', false)
    return query
  }

  @computed
  public get organizationUsers() {
    return this.records
  }
}
