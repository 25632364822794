import React, { FC } from 'react'
import rootStore from '../../stores/RootStore'
import {
  AppBar,
  Toolbar,
  Typography,
  CssBaseline,
  useScrollTrigger,
  Button,
} from '@material-ui/core'
import '../../css/dashboard-toolbar.scss'
import { observer } from 'mobx-react'
import clsx from 'clsx'
import isIE11 from '../../../utils/isIE11'

export interface Props {
  children?: React.ReactElement
}

function ElevationScroll(props: Props) {
  const { children } = props
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  })

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  })
}

const DashboardToolbar: FC<Props> = (props) => {
  const handleAddTask = () => {
    rootStore.tasksStore.createNewTask()
    rootStore.tasksStore.openDrawer()
  }

  return (
    <div id='DashboardToolbar'>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar
          className={clsx('dashboard-appbar', {
            'no-transition': isIE11,
          })}
        >
          <Toolbar className='dashboard-toolbar'>
            <Typography className='dashboard-title'>
              {rootStore.localizationStore.lzStrings.dashboard.tasks}
            </Typography>
            {rootStore.appStore.canAssignWork ? (
              <Button
                variant='contained'
                onClick={() => handleAddTask()}
                className='dashboard-action'
              >
                {rootStore.localizationStore.lzStrings.tasksManagementWidget.create_new_task}
              </Button>
            ) : null}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar />
    </div>
  )
}

export default observer(DashboardToolbar)
