import { computed, observable, action } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Category } from '../aggregate/Category'
import { ListWidgetVM } from './ListWidgetVM'

export class CategoryTreeVM {
  private rootStore: RootStore
  public category: Category
  private parentVM: ListWidgetVM

  constructor(rootStore: RootStore, parentVM: ListWidgetVM) {
    this.rootStore = rootStore
    this.parentVM = parentVM
    setTimeout(() => this.buildTreeData(), 500)
  }

  @observable public showTreeItemDescriptions: boolean = true
  @observable public expandTreeItems: boolean = false
  @observable public treeData: any[] = []

  @computed
  public get keywordFilter(): string {
    if (!this.parentVM.typedFilter) return null
    return this.parentVM.typedFilter
  }

  @action
  public handleNodeToggle(node: any) {
    node.isExpanded = !node.isExpanded
  }

  @action
  public openCategory(id, e) {
    const clickedItemClassName = e.target.className
    if (
      clickedItemClassName === 'rst__expandButton' ||
      clickedItemClassName === 'rst__collapseButton'
    )
      return
    this.rootStore.categoriesStore.widgetForm.setField('objectId', id)
    this.rootStore.categoriesStore.widgetForm.initEditForm(
      this.rootStore.categoriesStore.categories,
      id
    )
    this.rootStore.categoriesStore.widgetForm.openDrawer()
  }

  @action
  public toggleAllTreeItemDescriptions() {
    this.parentVM.setQuickFilter('')
    setTimeout(() => {
      this.showTreeItemDescriptions = !this.showTreeItemDescriptions
      this.buildTreeData()
    }, 250)
  }

  @action
  public toggleAllTreeItemsExpanded() {
    this.parentVM.setQuickFilter('')
    setTimeout(() => {
      this.expandTreeItems = !this.expandTreeItems
      this.buildTreeData()
    }, 250)
  }

  @computed
  public get currentTreeData(): any {
    let roots = []
    let nodes = []
    let expandCats = this.expandTreeItems ? true : false
    const arry = this.rootStore.categoriesStore.currentOrgCategories
    for (let i = 0; i < arry.length; ++i) {
      let item = arry[i],
        p = item.parentCategoryId,
        target = !p ? roots : nodes[p] || (nodes[p] = [])
      target.push({
        key: item.objectId,
        title: item.name,
        id: item.objectId,
        subtitle: item.description,
        parentId: item.parentCategoryId,
        value: item.objectId,
      })
    }

    let findChildren = function (parent, depth) {
      if (depth === 4) depth = 1
      if (depth === 5) depth = 2
      if (depth === 6) depth = 3
      parent.depth = depth
      parent.expanded = expandCats
      if (nodes[parent.key]) {
        parent.children = nodes[parent.key]
        for (let i = 0, len = parent.children.length; i < len; ++i) {
          findChildren(parent.children[i], depth + 1)
        }
      }
    }
    for (let i = 0; i < roots.length; ++i) {
      findChildren(roots[i], 1)
    }
    return roots
  }

  @action
  public buildTreeData() {
    const data = []
    for (let i = 0; i < this.currentTreeData.length; i++) {
      data.push({
        id: this.currentTreeData[i].id,
        title: this.currentTreeData[i].title,
        subtitle: this.currentTreeData[i].subtitle,
        value: this.currentTreeData[i].id,
        depth: this.currentTreeData[i].depth,
        children: this.currentTreeData[i].children,
        expanded: this.currentTreeData[i].expanded,
      })
    }

    this.treeData = data
  }

  @action
  public onChange(val: any) {
    this.treeData = val
  }
}
