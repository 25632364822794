import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'
import './WeightProfileImportRow.scss'
import { ImportCategoryVM } from 'src/app/weight-profiles/view-models/ImportCategoryVM'
import { WeightProfileImportRowVM } from 'src/app/weight-profiles/view-models/WeightProfileImportRowVM'

interface Props {
  category: ImportCategoryVM
  row: WeightProfileImportRowVM
}

const ImportCategory: React.FC<Props> = ({ category, row }) => {
  const renderText = () => {
    return (
      <Typography className={row.textStyle}>
        Name: <strong>{category.categoryName}</strong>
      </Typography>
    )
  }

  const renderEnabled = () => {
    return <Typography className={row.textStyle}>Enabled: {category.isChecked}</Typography>
  }

  const renderValue = () => {
    return <Typography className={row.textStyle}>Value: {category.value}</Typography>
  }

  return (
    <Grid container id='ImportCategory'>
      {renderText()}
      {renderEnabled()}
      {renderValue()}
    </Grid>
  )
}

export default observer(ImportCategory)
