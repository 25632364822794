import React from 'react'
import { observer, inject } from 'mobx-react'
import { CardHeader, IconButton, Tooltip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { TaskTemplatesStore } from '../store/TaskTemplatesStore'
import LocalizationStore from 'src/app/localization/LocalizationStore'

interface WidgetHeaderProps {
  taskTemplatesStore?: TaskTemplatesStore
  localizationStore?: LocalizationStore
}

const WidgetHeader: React.FC<WidgetHeaderProps> = ({
  taskTemplatesStore,
  localizationStore,
}) => {
  const vm = taskTemplatesStore.taskTypesWidgetVM
  const s = localizationStore.lzStrings
  if (!vm) return

  return (
    <CardHeader
          className='card-header'
          action={
            <>
              <React.Fragment>
                <Tooltip
                  title={s.taskTemplates.add_new_task_type}
                  placement='bottom'
                >
                  <IconButton className='widget-cta' size='small' onClick={() => vm.addNewTaskType()}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </React.Fragment>
            </>
          }
          title={s.taskTemplates.manage_task_types}
        />
  )
}

export default inject('taskTemplatesStore', 'localizationStore')(observer(WidgetHeader))
