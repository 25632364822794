import { RootStore } from '../../../stores/RootStore'
import { observable, action, computed } from 'mobx'
import { SystemAdminService } from '../../service/SystemAdminService'
import { ISystemAdminUser } from '../../dto/ISystemAdminUser'

export class SystemAdminNewVM {
  private rootStore: RootStore
   
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @observable public systemAdmins: Array<ISystemAdminUser> = []
  @observable public email: string = ''
  @observable saveTried: boolean = false
  @observable  public isOpen: boolean = false

  @action
  public setEmail(val: string) {
    this.email = val
  }
  
  @action
  public toggleDrawer() {
    this.isOpen = !this.isOpen
    this.email = ''
  }

  @action
  public async save() {
    const svc = new SystemAdminService()
    const response = await svc.save(this.email)
    if(response !== 'success') alert('Unable to add System Admin') 
    this.rootStore.systemAdminsStore.loadSystemAdmins()
    this.toggleDrawer()
  }

  @computed
  public get emailNotValid() {
    if (!this.saveTried) return true
    let pattern = new RegExp(/[^\s@]+@[^\s@]+\.[^\s@]+/i)
    return pattern.test(this.email)
  }
}
