import axios from 'axios'
import * as Sentry from '@sentry/browser'
import env from '../../../env'
import { RootStore } from '../../stores/RootStore'
import { ICMSItemDeleteRequest } from '../interfaces/ICMSItemDeleteRequest'
import { CMSAuthService } from 'src/app/cms-auth/services/CMSAuthService'

export class CMSItemsDeleteService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  private getCMSApiUrl(): string {
    return env.var.REACT_APP_CMS_API_URL
  }

  public async deleteItem(req: ICMSItemDeleteRequest): Promise<boolean> {
    try {
      const jwt = await CMSAuthService.getJWT()
      if (!jwt) return false

      await axios.delete(`${this.getCMSApiUrl()}/api/items/softDelete/${req.id}`, {
        headers: { Authorization: 'Bearer ' + jwt },
      })

      return true
    } catch (e) {
      console.error(e)
      Sentry.captureException({
        message: 'Unable to delete cms item',
        exception: e,
        userId: this.rootStore.appStore.currentUserId,
        orgId: this.rootStore.appStore.currentOrgId,
      })
    }
  }
}