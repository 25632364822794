import React from 'react'
import {
  InputLabel,
  OutlinedInput,
} from '@material-ui/core'

const StringInput = props => {
  const renderError = () => {
    if (!props.error) return null
    return  (
      <div className='input-error'>{props.error}</div>
    )
  }
  return (
    <div className={props.className}>
      <InputLabel htmlFor={props.id} required={props.required}>
          {props.displayName}
      </InputLabel>
      <OutlinedInput
          id={props.id}
          fullWidth
          labelWidth={0}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
          error={props.error ? true : false}
          required={props.required}
      />
      {renderError()}
    </div>  
  )
}

export default StringInput
