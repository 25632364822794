import React, { useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import rootStore from '../../stores/RootStore'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import ButtonBase from '@material-ui/core/ButtonBase'
import './UserConsentScreen.scss'
import AttachFileIcon from '@material-ui/icons/AttachFile'

const UserConsentScreen = () => {
  const consentsStore = rootStore.consentsStore
  const appStore = rootStore.appStore
  const vm = consentsStore.userConsentScreenVM

  useEffect(() => {
    if (!consentsStore.shouldRedirectToConsent) {
      appStore.router.push('/dashboard/userDashboard')
    }
  }, [consentsStore.shouldRedirectToConsent, appStore])

  if (!vm) return

  const renderAttachments = () => {
    if (vm.attachments.length === 0) return
    return (
      <div style={{ borderTop: '1px solid #e3e2e2', marginTop: '5px' }}>
        <Typography>Attachments:</Typography>
        <Grid container className='consent-attachment-container'>
          {vm.attachments.map((e, idx) => {
            return (
              <ButtonBase
                key={idx}
                onClick={() =>
                  vm.openAttachment(e)
                }
                className='link-pill'
              >
                <Grid item className='link-pill-text'>
                  <Typography>
                    <AttachFileIcon className='link-pill-icon' />
                    {e.shorterFileName}
                  </Typography>
                </Grid>
              </ButtonBase>
            )
          })}
        </Grid>
      </div>
    )
  }

  return (
    <div id='UserConsentScreen'>
      <Grid item xs={12} sm={10} md={6}>
        <Card className='consent-card'>
          <CardHeader
            className='consent-card-header'
            title='Consent Required'
            titleTypographyProps={{ align: 'center' }}
          />
          <CardContent className='consent-card-content'>
            <div dangerouslySetInnerHTML={{ __html: vm.text }}></div>
            {renderAttachments()}
          </CardContent>

          <CardActions className='consent-card-actions'>
            <Button variant='outlined' onClick={() => vm.decline()}>
              Decline
            </Button>
            <Button variant='contained' onClick={() => vm.accept()}>
              Accept
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </div>
  )
}

export default inject('consentsStore')(observer(UserConsentScreen))
