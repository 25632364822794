import React from 'react'
import clsx from 'clsx'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { CalendarSettingsStore } from '../../../calendar-settings/store/CalendarSettingsStore'
import { Button, ButtonBase } from '@material-ui/core'
import { LabelsStore } from 'src/app/labels/store/LabelsStore'

interface WidgetTitleProps {
  localizationStore?: LocalizationStore
  calendarSettingsStore?: CalendarSettingsStore
  labelsStore?: LabelsStore
}

const WidgetTitle: React.FC<WidgetTitleProps> = ({
  localizationStore,
  calendarSettingsStore,
  labelsStore,
}) => {
  const { lzStrings } = localizationStore
  const { widgetVM: vm } = calendarSettingsStore

  if (!vm) return

  const handleEventTypesClick = () => {
    vm.setTabIndex(0)
  }

  const handleLocationsClick = () => {
    vm.setTabIndex(1)
  }

  const handleResourcesClick = () => {
    vm.setTabIndex(2)
  }

  const handleStartTimesClick = () => {
    vm.setTabIndex(3)
  }

  return (
    <div className='widget-title-tabs'>
      <span>{lzStrings.calendarSettings.manage_calendar_settings}</span>
      <div className='tab-btns'>
        <ButtonBase
          onClick={handleEventTypesClick}
          className={clsx('tab-button', {
            active: vm.tabIndex === 0,
          })}
        >
          {lzStrings.settings.eventTypes}
        </ButtonBase>
        <ButtonBase
          onClick={handleLocationsClick}
          className={clsx('tab-button', {
            active: vm.tabIndex === 1,
          })}
        >
          {lzStrings.settings.locations}
        </ButtonBase>
        <ButtonBase
          onClick={handleResourcesClick}
          className={clsx('tab-button', {
            active: vm.tabIndex === 2,
          })}
        >
          {lzStrings.settings.resources}
        </ButtonBase>
        <ButtonBase
          onClick={handleStartTimesClick}
          className={clsx('tab-button', {
            active: vm.tabIndex === 3,
          })}
        >
          {labelsStore.getLabelByLanguageAndFor('working hours')}
        </ButtonBase>
      </div>
    </div>
  )
}

export default inject(
  'localizationStore',
  'calendarSettingsStore',
  'labelsStore'
)(observer(WidgetTitle))
