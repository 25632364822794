import React, { FC, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import {
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  DialogTitle,
  InputBase,
  CircularProgress,
} from '@material-ui/core'
import { OrganizationUsersStore } from '../../store/OrganizationUsersStore'
import { makeStyles } from '@material-ui/core/styles'
import LocalizationStore from '../../../localization/LocalizationStore'

const useStyles = makeStyles({
  title: {
    backgroundColor: '#f0eff4',
  },
  content: {
    margin: '0px 50px',
    fontSize: '20px',
    fontWeight: 'bold',
    marginTop: '10px',
    padding: '5px',
    backgroundColor: '#f0eff4',
    display: 'flex',
    justifyContent: 'center',
  },
  message: {
    color: '#e5918c',
    display: 'flex',
    justifyContent: 'center',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '110px',
    minWidth: '415px',
  },
})

interface Props {
  organizationUsersStore?: OrganizationUsersStore
  localizationStore?: LocalizationStore
}

const UserOneTimeUsePasscodeDialog: FC<Props> = ({ organizationUsersStore, localizationStore }) => {
  const { userOneTimeUsePasscodeDialogVM: vm } = organizationUsersStore.editVM
  const lz = localizationStore.lzStrings.passcode
  const classes = useStyles({})

  const renderMessage = () => {
    if (vm.isProcessing) return
    if (!vm.userOneTimeUsePasscode) return
    return (
      <>
        <div className={classes.message}>{lz.passcode_is_active}</div>
        <div className={classes.message}>{lz.it_will_be_deactivated}</div>
      </>
    )
  }

  const renderErrorMessage = () => {
    if (vm.isProcessing) return
    if (vm.userOneTimeUsePasscode) return
    return <div className={classes.message}>{vm.errorMessage}</div>
  }

  const renderSpinner = () => {
    if (!vm.isProcessing) return
    return (
      <div className={classes.spinner}>
        <CircularProgress />
      </div>
    )
  }

  const renderActions = () => {
    if (vm.isProcessing) return
    return (
      <DialogActions>
        <Button onClick={() => vm.cancel()}>{lz.close}</Button>
      </DialogActions>
    )
  }

  const renderPasscode = () => {
    if (vm.isProcessing) return
    if (!vm.userOneTimeUsePasscode) return
    return <div className={classes.content}>{vm.userOneTimeUsePasscode}</div>
  }

  return (
    <Dialog onClose={() => vm.hide()} open={vm.shown}>
      <DialogTitle className={classes.title}>{lz.user_one_time}</DialogTitle>
      <DialogContent>
        {renderSpinner()}
        {renderErrorMessage()}
        {renderMessage()}
        {renderPasscode()}
      </DialogContent>
      {renderActions()}
    </Dialog>
  )
}

export default inject(
  'organizationUsersStore',
  'localizationStore'
)(observer(UserOneTimeUsePasscodeDialog))
