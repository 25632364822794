import { computed, reaction } from 'mobx'
import { AGGridRowVM } from '../../../shared/ag-grid/AGGridRowVM'
import { RootStore } from '../../../stores/RootStore'
import { OrganizationUser } from '../../aggregate/OrganizationUser'
import { OrganizationUsersWidgetVM } from './OrganizationUsersWidgetVM'

export class WidgetRowVM extends AGGridRowVM<OrganizationUser> {
  constructor(rootStore: RootStore, user: OrganizationUser, listVM: OrganizationUsersWidgetVM) {
    super(rootStore, user, listVM)
    reaction(
      () => user.title,
      () => this.updateRow()
    )
  }

  @computed
  public get objectId(): string {
    return this.aggregate.objectId
  }

  @computed
  public get userId(): string {
    if (!this.aggregate.fk_User) return ''
    return this.aggregate.fk_User.objectId
  }

  @computed
  public get email(): string {
    if (!this.aggregate.fk_User) return ''
    return this.aggregate.fk_User.email
  }

  @computed
  public get iconURL(): string {
    if (!this.aggregate.fk_User) return ''
    return this.aggregate.fk_User.iconURL
  }

  @computed
  public get name(): string {
    if (!this.aggregate.fk_User) return ''
    return this.aggregate.fk_User.name
  }

  @computed get isHidden(): boolean {
    return this.aggregate.isHiddenInOrg
  }

  @computed
  public get sortName(): string {
    if (!this.aggregate.fk_User) return ''
    return this.aggregate.fk_User.lastName + ', ' + this.aggregate.fk_User.firstName
  }

  @computed
  public get title(): string {
    return this.aggregate.title ? this.aggregate.title : '--'
  }

  @computed
  public get primaryGroupName(): string {
    if (!this.aggregate.primaryGroupId) return ''
    const group = this.rootStore.groupsStore.getGroup(this.aggregate.primaryGroupId)
    if (!group) return ''
    return group.name
  }

  @computed
  public get groupNames(): string {
    const groupNames = this.aggregate.groupsAsArray
      .map((e) => this.rootStore.groupsStore.getGroup(e))
      .filter((e) => e)
      .filter((e) => e.objectId !== this.aggregate.primaryGroupId)
      .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0))
      .map((e) => e.name)
    if (this.primaryGroupName) groupNames.unshift(this.primaryGroupName)
    const result = groupNames.join(', ')
    if (result === '') return '--'
    return result
  }

  @computed
  public get primaryRoleName(): string {
    if (!this.aggregate.primaryRoleId) return ''
    const role = this.rootStore.rolesStore.getRole(this.aggregate.primaryRoleId)
    if (!role) return ''
    return role.name
  }

  @computed
  public get roleNames(): string {
    const roleNames = this.aggregate.rolesAsArray
      .map((e) => this.rootStore.rolesStore.getRole(e))
      .filter((e) => e)
      .filter((e) => e.objectId !== this.aggregate.primaryRoleId)
      .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0))
      .map((e) => e.name)
    if (this.primaryRoleName) roleNames.unshift(this.primaryRoleName)
    const result = roleNames.join(', ')
    if (result === '') return '--'
    return result
  }

  public click() {
    this.rootStore.organizationUsersStore.loadEditVM(this.objectId)
  }
}
