import { observable } from 'mobx'
import { serializable } from 'serializr'

export class Days {
  static create(d) {
    const days = new Days()
    days.sunday = d.sunday
    days.monday = d.monday
    days.tuesday = d.tuesday
    days.wednesday = d.wednesday
    days.thursday = d.thursday
    days.friday = d.friday
    days.saturday = d.saturday
    
    return days
  }

  @serializable @observable sunday: boolean = false
  @serializable @observable monday: boolean = false
  @serializable @observable tuesday: boolean = false
  @serializable @observable wednesday: boolean = false
  @serializable @observable thursday: boolean = false
  @serializable @observable friday: boolean = false
  @serializable @observable saturday: boolean = false

}
