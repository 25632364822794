import React from 'react'
import { inject, observer } from 'mobx-react'
import { OldAttachmentVM } from '../../attachments/view-models/OldAttachmentVM'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import 'swiper/swiper.scss'
import 'swiper/modules/effect-fade/effect-fade.scss'
import 'swiper/modules/lazy/lazy.scss'
import 'swiper/modules/navigation/navigation.scss'
import 'swiper/modules/pagination/pagination.scss'
import './AnnouncementsWidget.scss'

interface Props {
  vm: OldAttachmentVM
}

const OldItemAttachment: React.FC<Props> = ({ vm }) => {
  if (!vm) return

  const renderImage = (item: OldAttachmentVM) => {
    if (item.isCMSItem) return
    if (!item.isImage) return
    if (item.hasVimeoURL) return
    if (item.hasYouTubeURL) return
    return <img src={`${item.url}`} className='swiper-lazy' onClick={() => item.toggleLightBox()} />
  }

  const renderYouTubeVideo = (item: OldAttachmentVM) => {
    if (item.isCMSItem) return
    if (!item.isVideo) return
    if (!item.hasYouTubeURL) return

    const url = `https://www.youtube-nocookie.com/embed/${item.youTubeId}?modestbranding=1&showinfo=0&controls=1&rel=0`
    return (
      <div className={'media-preview youtube iframewrapper'}>
        <iframe
          className='swiper-lazy'
          width={'100%'}
          height={'auto'}
          src={url}
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        />
      </div>
    )
  }

  const renderVimeoVideo = (item: OldAttachmentVM) => {
    if (item.isCMSItem) return
    if (!item.hasVimeoURL) return
    const url = `https://player.vimeo.com/video/${item.vimeoId}?controls=1`
    return (
      <div className={'media-preview vimeo iframewrapper'}>
        <iframe
          className='swiper-lazy'
          src={url}
          width={'100%'}
          height={'auto'}
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        />
      </div>
    )
  }

  const renderVideo = (item: OldAttachmentVM) => {
    if (item.isCMSItem) return
    if (item.isImage) return
    return (
      <video className='swiper-lazy' controls width='100%' src={`${item.url}`}>
        <source type='video/mp4' src={`${item.url}`} />
      </video>
    )
  }

  const renderLightBox = (item: OldAttachmentVM) => {
    if (item.isCMSItem) return
    if (!item.isImage) return
    if (item.hasVimeoURL) return
    if (item.hasYouTubeURL) return
    if (!item.showLightBox) return
    const lightBoxStyles = { overlay: { zIndex: 10000 } }
    return (
      <Lightbox
        mainSrc={item.url}
        reactModalStyle={lightBoxStyles}
        onCloseRequest={() => item.toggleLightBox()}
      ></Lightbox>
    )
  }

  return (
    <>
      {renderImage(vm)}
      {renderYouTubeVideo(vm)}
      {renderVimeoVideo(vm)}
      {renderVideo(vm)}
      {renderLightBox(vm)}
    </>
  )
}

export default inject()(observer(OldItemAttachment))
