import React from 'react'
import { inject, observer } from 'mobx-react'
import { NotificationsStore } from '../store/NotificationsStore'
import { AgGridReact } from 'ag-grid-react/lib/agGridReact'
import AGGridWidgetPagingRow from '../../shared/ag-grid/AGGridPagingRow'
import { CircularProgress } from '@material-ui/core'
import { NotificationsWidgetVM } from '../store/view-models/NotificationsWidgetVM'
import './NotificationTemplatesTable.scss'

interface Props {
  notificationsStore?: NotificationsStore
  vm: NotificationsWidgetVM
}

const NotificationTemplatesTable: React.FC<Props> = ({ notificationsStore, vm }) => {

  if (!vm.shouldRender) return

  const renderSpinner = () => {
    if (vm && vm.shouldRender) return
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  const renderGrid = () => {
    if (!vm.shouldRender) return
    return (
      <div className='ag-theme-alpine' style={{ height: '100%', width: '100%' }}>
        <AgGridReact
          gridOptions={{
            ...vm.getGridOptions(),
          }}
        />
      </div>
    )
  }

  const renderGridPaging = () => {
    if (!vm.shouldRender) return
    return <AGGridWidgetPagingRow vm={vm} />
  }

  return (
    <>
      {renderSpinner()}
      {renderGrid()}
      {renderGridPaging()}
    </>
  )
}

export default inject('notificationsStore')(observer(NotificationTemplatesTable))
