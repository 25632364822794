import React from 'react'
import { observer } from 'mobx-react'
import rootStore from '../../stores/RootStore'
import Welcome from './Welcome'

@observer
export default class WelcomeScreen extends React.Component<any, any> {
  constructor(props) {
    super(props)

    if (props.params.orgId) {
      rootStore.authStore.loadWelcomeVM(props.params.orgId)
    }
  }

  render() {
    return (
      <div id='WelcomeScreen'>
        <Welcome />
      </div>
    )
  }
}
