import React from 'react'
import { inject, observer } from 'mobx-react'
import TasksIcon from '@material-ui/icons/AssignmentOutlined'
import SurveysIcon from '@material-ui/icons/LiveHelpOutlined'
import TrainingsIcon from '../../../shared/icons/TrainingsIcon'
import EventIcon from '@material-ui/icons/Event'
import { IBigCalendarEvent } from '../../intefaces/IBigCalendarEvent'
import './EventPill.scss'
import { UserEventPillVM } from '../../view-models/UserEventPillVM'

interface Props {
  event: IBigCalendarEvent
}

const EventPill: React.FC<Props> = ({ event }) => {
  const pillVM = event.event

  const renderTaskIcon = () => {
    if (!event.event.task) return null
    if (!event.event.userTask) return null
    return <TasksIcon className='icon-task' />
  }

  const renderSurveyIcon = () => {
    if (!event.event.userSurvey) return null
    return <SurveysIcon className='icon-survey' />
  }

  const renderTrainingPlansIcon = () => {
    if (!event.event.userTrainingPlan) return null
    return (
      // <div className='icon-trainings'>
      <TrainingsIcon />
      // </div>
    )
  }

  const renderTrainingItemsIcon = () => {
    if (!event.event.trainingItem) return null
    return (
      // <div className='icon-trainings'>
      <TrainingsIcon />
      // </div>
    )
  }

  const renderEventIcon = () => {
    if (!event.event.event && !event.event.groupId && !event.event.roleId) return null
    return <EventIcon className='icon-event' />
  }

  const renderUnreadIndicator = () => {
    if (pillVM.isRead) return null
    if (event.event.groupId) return null
    if (event.event.roleId) return null
    if (!event.event.event && !event.event.groupId && !event.event.roleId) return null
    return <div className='unread-indicator'></div>
  }
  let className = `calendar-pill EventPill-${pillVM.calendarIndex}`
  if (pillVM.isSelectedEvent) className = `${className} selectedEvent`
  return (
    <div
      onClick={() => {
        if (pillVM instanceof UserEventPillVM) {
          pillVM.loadPopup(pillVM)
        }
      }}
      className={className}
      key={pillVM.title}
      id={pillVM.userEvent ? pillVM.userEvent.eventId : pillVM.objectId + pillVM.calendarIndex}
    >
      {renderUnreadIndicator()}
      <div className='pill-icon'>
        {renderTaskIcon()}
        {renderSurveyIcon()}
        {renderEventIcon()}
        {renderTrainingPlansIcon()}
        {renderTrainingItemsIcon()}
      </div>
      <div className={pillVM.userNotGoing ? 'not-going' : ''}>{pillVM.title}</div>
    </div>
  )
}

export default inject('userEventsStore')(observer(EventPill))
