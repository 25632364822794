import React from 'react'
import { observer } from 'mobx-react'
import { Checkbox } from '@material-ui/core'
import { CMSItemVM } from '../../view-models/CMSItemVM'
import './CheckboxCell.scss'

const CheckboxCell = (props) => {
  const node = props.node.data
  if (!node) return

  const vm: CMSItemVM = node.item
  if (!vm) return

  return (
    <div id='CheckboxCell'>
      <Checkbox
        checked={vm ? vm.isChecked : false}
        color='primary'
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />
    </div>
  )
}

export default observer(CheckboxCell)
