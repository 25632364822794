import { RootStore } from '../../stores/RootStore'
import { observable, computed, action } from 'mobx'
import { CalendarVM } from './CalendarVM'
import { TrainingPlan } from '../aggregate/TrainingPlan'
import moment from 'moment'

export class TrainingPlanPopupVM {
  private rootStore: RootStore
  private calendarVM: CalendarVM

  constructor(rootStore: RootStore, calendarVM: CalendarVM, userTrainingPlan: TrainingPlan) {
    this.rootStore = rootStore
    this.userTrainingPlan = userTrainingPlan
    this.calendarVM = calendarVM
    setTimeout(
      () => (this.anchorEl = document.getElementsByClassName('selectedTrainingPlan')[0]),
      20
    )
  }

  @observable private userTrainingPlan: TrainingPlan = null
  @observable public anchorEl: any = null

  @computed
  public get title(): string {
    return this.userTrainingPlan.title
  }

  @computed
  public get from(): string {
    if (this.userTrainingPlan.assignedByUserId === this.rootStore.appStore.currentUserId)
      return 'Me'
    const assignedBy = this.rootStore.audienceMembersStore.getUser(
      this.userTrainingPlan.assignedByUserId
    )
    if (!assignedBy) return ''
    return assignedBy.name
  }

  @computed
  public get dueDate(): string {
    const s = this.rootStore.localizationStore.lzStrings.surveys
    if (this.userTrainingPlan.dueDate === null) return null
    return moment(this.userTrainingPlan.dueDate).format(s.due_date_format)
  }

  @computed
  public get activeDate(): string {
    const s = this.rootStore.localizationStore.lzStrings.surveys
    if (this.userTrainingPlan.activeDate === null) return null
    return moment(this.userTrainingPlan.activeDate).format(s.due_date_format)
  }

  @computed
  public get category(): string {
    return this.userTrainingPlan.categoryId
  }

  @computed
  public get trainingPlanLink(): string {
    const url = '/userTrainingPlans/take/' + this.userTrainingPlan.objectId
    return url
  }

  @action
  public navigateToTrainingPlan() {
    this.rootStore.appStore.router.push(this.trainingPlanLink)
    this.close()
  }

  @action
  public close() {
    this.anchorEl = null
  }
}
