import React from 'react'
import { inject, observer } from 'mobx-react'
import { PulseCampaignsStore } from '../../store/PulseCampaignsStore'
import ParticipantsSelect from '../../../participants-select/views/ParticipantsSelect'

interface Props {
  pulseCampaignsStore?: PulseCampaignsStore
}

const AudienceStep: React.FC<Props> = ({ pulseCampaignsStore }) => {
  const { editVM } = pulseCampaignsStore.viewModels
  if (!editVM) return null
  return <ParticipantsSelect participantsSelectVM={editVM.participantsSelectVM} />
}

export default inject('pulseCampaignsStore')(observer(AudienceStep))
