import { RootStore } from '../../stores/RootStore'
import { observable, computed, action } from 'mobx'
import { NotificationTemplate } from '../aggregate/NotificationTemplate'
import { LabelsStore } from '../../labels/store/LabelsStore'

export class NotificationTemplateVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, template: NotificationTemplate) {
    this.rootStore = rootStore
    this.template = template
    this.labelsStore = this.rootStore.labelsStore
    if (template) this.loadData()
  }

  @observable public labelsStore: LabelsStore = null
  @observable public template: NotificationTemplate = null
  @observable public body: string = ''
  @observable public subject: string = ''

  @action
  private loadData() {
    this.subject = this.template.subject
    this.body = this.template.body
  }

  @action
  public handleSubjectChange(val: string) {
    this.subject = val
  }

  @action
  public handleFieldSelect(val: string, editorRef: any) {
    let contentToInsert = val
    if (val === '{surveyLink}' && this.channel.toLowerCase() === 'email') {
      if (this.language === 'en') {
        contentToInsert = `<a href="{surveyLink}">Take ${this.labelsStore.getLabelByLanguageAndFor(
          'pulse'
        )}</a>`
      } else if (this.language === 'de') {
        contentToInsert =
          '<a href="{surveyLink}" target="_self">Nehmen Sie an einer Umfrage teil</a>'
      }
    }
    editorRef?.current?.editor?.insertContent(contentToInsert)
  }

  @action
  public setBody(body: string) {
    this.body = body
  }

  @computed
  public get channel(): string {
    return this.template.channel
  }

  @computed
  public get language() {
    return this.template.language
  }

  @computed
  public get notificationTypeId() {
    return this.template.notificationTypeId
  }

  @action
  public toDTO() {
    return {
      channel: this.channel,
      body: this.body,
      language: this.language,
      notificationTypeId: this.notificationTypeId,
      organizationId: this.rootStore.appStore.currentOrgId,
      subject: this.subject,
    }
  }
}
