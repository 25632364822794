import { Button, Checkbox, CircularProgress, FormControlLabel, Grid, InputLabel, Switch, TextField, Tooltip, Typography } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import React from 'react'
import {debounce} from 'lodash'
import { Divide } from 'react-feather'
import { LabelsStore } from 'src/app/labels/store/LabelsStore'
import { OrganizationsSAStore } from '../../store/OrganizationsSAStore'
import './OrganizationEditDrawer.scss'
import OrganizationFeatures from './OrganizationFeatures'
import OrganizationModules from './OrganizationModules'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import LockIcon from '@material-ui/icons/LockRounded'
import RefreshIcon from '@material-ui/icons/Refresh'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlankRounded'
import CheckBoxIcon from '@material-ui/icons/CheckBoxRounded'
import { IFederatedGroupDTO } from 'src/app/federatedImports/dtos/IFederatedGroupDTO'
import InfoTooltip from 'src/app/shared/InfoTooltip'

interface Props {
  organizationsSAStore?: OrganizationsSAStore
  labelsStore?: LabelsStore
}

const OrganizationAuthTab: React.FC<Props> = ({ organizationsSAStore, labelsStore }) => {
  const { editVM: vm } = organizationsSAStore
  if (!vm) return null


  const renderTestStatus = () => {
    if (vm.isTestingUserFederation) {
      return (
        <CircularProgress className='progressCircle'/>
      )
    } else if (vm.userFederationTestStatus === 'success') {
      return (<CheckIcon className='test-success'/>)
    } else if (vm.userFederationTestStatus === 'fail') {
      return (<CloseIcon className='test-error'/>)
    } else {
      return (<LockIcon className='test-notrun'/>)
    }
    // not run yet
    return null
  }

  const renderTestButton = () => {
    return (
      <Button
        variant='outlined'
        onClick={() => vm.testUserFederationSettings()}
        disabled={vm.isTestingUserFederation || !vm.userFederationSettingsValid || !vm.userFederationEnabled}
      >
        <div className='test-text'>Test</div> 
        <div className='test-status'>{renderTestStatus()}</div>
      </Button>
    )
  }

  const renderSyncStatus = () => {
    if (vm.isStartingFederatedUserSync) {
      return (
        <CircularProgress className='progressCircle'/>
      )
    } else if (vm.federatedUserSyncStatus === 'success') {
      return (<CheckIcon className='test-success'/>)
    } else if (vm.federatedUserSyncStatus === 'fail') {
      return (<CloseIcon className='test-error'/>)
    } else {
      return (<RefreshIcon className='test-notrun'/>)
    }
  }

  const renderSyncNowButton = () => {
    if (!vm.organization.objectId) return
    return (
      <Button
        variant='outlined'
        onClick={() => vm.startFederatedUserSync()}
        disabled={vm.federatedUserSyncDisabled}
      >
        <div className={'test-text ' + (vm.federatedUserSyncDisabled ? 'test-notrun': '')}> Diff Sync </div>
        <div className='test-status'>{renderSyncStatus()}</div>
      </Button>
    )
  }

  const renderFullSyncButton = () => {
    if (!vm.organization.objectId) return
    return (
      <Button
        variant='outlined'
        onClick={() => vm.startFederatedUserSync(true)}
        disabled={vm.federatedUserSyncDisabled}
      >
        <div className={'test-text ' + (vm.federatedUserSyncDisabled ? 'test-notrun': '')}> Full Sync </div>
        <div className='test-status'>{renderSyncStatus()}</div>
      </Button>
    )
  }

  const debouncedGet = debounce((terms) => vm.fetchGroups(terms), 500)

  const renderUserSyncSettings = () => {
    return (
      <div className='subsection'>
      <h6>User Federation Settings</h6>
        <FormControlLabel
          key='enableusersync'
          htmlFor='enableusersync'
          onClick={() => vm.toggleUserFederationEnabled()}
          control={<Checkbox color='primary' checked={vm.userFederationEnabled} />}
          label={'Enabled'}
        />
        {renderTestButton()}   
        {renderSyncNowButton()} 
        {renderFullSyncButton()}
        <div className='input-wrapper'>
          <InputLabel className='details-info-label'>Adapter</InputLabel>
        </div>
        <TextField
          className='details-info-input'
          disabled={true}
          value={"AzureGraphFederationAdapter"}
          fullWidth
          variant='outlined'
        />
        <div className='input-wrapper'>
          <InputLabel className='details-info-label'>Tenant ID</InputLabel>
          <InputLabel className='details-info-label auth-req'>*</InputLabel>
        </div>
        <TextField
          className='details-info-input'
          onChange={(e) => vm.setTenantId(e.target.value)}
          fullWidth
          variant='outlined'
          value={vm.tenantId}
          disabled={!vm.userFederationEnabled}
        />
        <div className='input-wrapper'>
          <InputLabel className='details-info-label'>Client ID</InputLabel>
          <InputLabel className='details-info-label auth-req'>*</InputLabel>
        </div>
        <TextField
          className='details-info-input'
          onChange={(e) => vm.setClientId(e.target.value)}
          fullWidth
          variant='outlined'
          value={vm.clientId}
          disabled={!vm.userFederationEnabled}
        />
        <div className='input-wrapper'>
          <InputLabel className='details-info-label'>Client Secret</InputLabel>
          <InputLabel className='details-info-label auth-req'>*</InputLabel>
        </div>
        <TextField
          className='details-info-input'
          onChange={(e) => vm.setClientSecret(e.target.value)}
          fullWidth
          variant='outlined'
          value={vm.clientSecret}
          disabled={!vm.userFederationEnabled}
        />
        <div className='input-wrapper'>
          <InputLabel className='details-info-label'>Custom Auth Domain</InputLabel>
          <InputLabel className='details-info-label auth-req'>&nbsp;</InputLabel>
        </div>
        <TextField
          className='details-info-input'
          onChange={(e) => vm.setCustomAuthDomain(e.target.value)}
          fullWidth
          variant='outlined'
          value={vm.customAuthDomain}
          disabled={!vm.userFederationEnabled}
        />
        <div className='input-wrapper'>
        <InputLabel htmlFor='content-ext-video' className={'form-group-label'}>
          Allowed Groups
        </InputLabel>
        <InfoTooltip message={"Specify which Azure Groups will be imported into RippleWorx. By Default, all Groups will be imported"}/>
        </div>
        <Autocomplete
          className={'toolbar-filter'}
          key={'group-names'}
          size='small'
          limitTags={4}
          multiple
          disableCloseOnSelect
          value={vm.groupNames}
          loading={vm.fetchingGroups}
          onOpen={() => {console.log('open'); vm.fetchGroups('')}}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                checkedIcon={<CheckBoxIcon fontSize='small' />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </React.Fragment>
          )}
          onChange={(e, val:IFederatedGroupDTO[]) => {
            if (val === null) {
              vm.setAllowedGroups([])
            } else {
              vm.setAllowedGroups(val)
            }
          }}
          options={vm.allowedGroupOptions}
          getOptionLabel={(option) => option.name}
          getOptionSelected={(option, value) => option.externalId === value.externalId}
          renderInput={(params) => (
            <TextField
              label=''
              onChange={(e) => debouncedGet(e.target.value)}
              name='toolbar-tag-text'
              type='search'
              variant='outlined'
              placeholder={'Search Groups'}
              {...params}
            />
          )}
        />
        <div className='input-wrapper'>
          <InputLabel htmlFor='content-ext-video' className={'form-group-label'}>
            GroupBy Fields 
          </InputLabel>
          <InfoTooltip message={"Specify which fields from the Azure User Properties that will be made into Groups in RippleWorx"}/>
        </div>

        <Autocomplete
          className={'toolbar-filter'}
          key={'group-by-fields'}
          size='small'
          limitTags={4}
          multiple
          disableCloseOnSelect
          value={vm.groupByFields}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                checkedIcon={<CheckBoxIcon fontSize='small' />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </React.Fragment>
          )}
          onChange={(e, val) => {
            if (val === null) {
              vm.setGroupByFields([])
            } else {
              vm.setGroupByFields(val)
            }
          }}
          options={vm.groupByFieldOptions}
          getOptionLabel={(option) => option}
          getOptionSelected={(option, value) => option === value}
          renderInput={(params) => (
            <TextField
              label=''
              name='toolbar-tag-text'
              type='search'
              variant='outlined'
              placeholder={''}
              {...params}
            />
          )}
        />
        <div className='input-wrapper'>
          <InputLabel className={'form-group-label'}>
            Prefer Email Over User Principal Name  
          </InputLabel>
          <InfoTooltip message={"Use the 'mail' field of the External User as opposed to the User Principal Name"}/>
        </div>
        <Switch
          checked={vm.preferEmailOverUPN}
          onChange={() => vm.togglePreferEmailOverUPN()}
          size='medium'
        />
      </div>

    )
  }

  const renderOIDCSettings = () => {
    return (
      <div className='subsection'>
        <h6>OIDC Settings</h6>
        <FormControlLabel
          key='enableoidcauth'
          htmlFor='enableoidcauth'
          onClick={() => vm.toggleEnableOIDCAuth()}
          control={<Checkbox color='primary' checked={vm.enableOIDCAuth} />}
          label={'Enabled'}
        />
        <div className='input-wrapper'>
          <InputLabel className='details-info-label'>Provider Name</InputLabel>
          <InputLabel className='details-info-label auth-req'>*</InputLabel>
        </div>
        <TextField
          className='details-info-input'
          value={vm.oidcProvider}
          error={!vm.isOidcProviderValid}
          disabled={!vm.OIDCAuthIsEnabled}
          onChange={(e) => vm.setOIDCProvider(e.target.value)}
          fullWidth
          variant='outlined'
        />
        {!vm.isOidcProviderValid && (
          <Typography className='auth-error'>This field is required</Typography>
        )}
        <div className='input-wrapper'>
          <InputLabel className='details-info-label'>Connect URL</InputLabel>
          <InputLabel className='details-info-label auth-req'>*</InputLabel>
        </div>
        <TextField
          className='details-info-input'
          value={vm.oidcConnectUrl}
          error={!vm.isOidcConnectUrlValid}
          disabled={!vm.OIDCAuthIsEnabled}
          onChange={(e) => vm.setOIDCConnectUrl(e.target.value)}
          fullWidth
          variant='outlined'
        />
        {!vm.isOidcConnectUrlValid && (
          <Typography className='auth-error'>A valid URL beginning with 'http' or 'https' is required</Typography>
        )}
        <div className='input-wrapper'>
          <InputLabel className='details-info-label'>Client ID</InputLabel>
          <InputLabel className='details-info-label auth-req'>*</InputLabel>
        </div>
        <TextField
          className='details-info-input'
          value={vm.oidcClientId}
          error={!vm.isOidcClientIdValid}
          disabled={!vm.OIDCAuthIsEnabled}
          onChange={(e) => vm.setOIDCClientId(e.target.value)}
          fullWidth
          variant='outlined'
        />
        {!vm.isOidcClientIdValid && (
          <Typography className='auth-error'>This field is required</Typography>
        )}
        <InputLabel className='details-info-label'>Prompt Text</InputLabel>
        <TextField
          className='details-info-input'
          value={vm.oidcPromptText}
          disabled={!vm.OIDCAuthIsEnabled}
          onChange={(e) => vm.setOICDPromptText(e.target.value)}
          fullWidth
          variant='outlined'
        />
      </div>
    )
  }

  return (
    <div id='OrgSettingsTab'>
      <Grid container>
        <Grid item xs={12}>
          <div className='details-paper'>
            {renderUserSyncSettings()}
            {renderOIDCSettings()}
            <InputLabel className='details-info-label'>Custom Domain</InputLabel>
            <TextField
              className='details-info-input'
              value={vm.accessDomain}
              onChange={(e) => vm.setAccessDomain(e.target.value)}
              disabled={!vm.OIDCAuthIsEnabled}
              fullWidth
              variant='outlined'
            />
            <FormControlLabel
              key='requireoidcauth'
              htmlFor='requireoidcauth'
              className='details-required'
              disabled={!vm.OIDCAuthIsEnabled}
              onClick={() => vm.toggleRequireOIDCAuth()}
              control={<Checkbox color='primary' checked={vm.requireOIDCAuth} />}
              label={'Force User to Use OIDC'}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
export default inject('organizationsSAStore', 'labelsStore')(observer(OrganizationAuthTab))
