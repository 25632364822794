import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { CircularProgress, Dialog, Grid } from '@material-ui/core'
import LocalizationStore from '../../localization/LocalizationStore'
import { NotificationsStore } from '../../notifications/store/NotificationsStore'
import DrawerNotificationInfo from './../widgets/DrawerNotificationInfo'
import DrawerTemplateEditor from './../widgets/DrawerTemplateEditor'
import DrawerDefaultButton from './../widgets/DrawerDefaultButton'
import NotificationTemplatesTopToolbar from './NotificationTemplatesTopToolbar'
import NotificationTemplatePreview from './NotificationTemplatePreview'
import { NotificationsWidgetVM } from '../store/view-models/NotificationsWidgetVM'
import './NotificationTemplatesDialog.scss'
import './../widgets/Drawer.scss'

interface Props {
  notificationsStore?: NotificationsStore
  localizationStore?: LocalizationStore
  vm: NotificationsWidgetVM
}

const NotificationTemplatesDialog: React.FC<Props> = ({
  notificationsStore,
  localizationStore,
  vm,
}) => {
  const { editVM: widgetForm } = notificationsStore
  if (!widgetForm) return

  const renderSpinner = () => {
    if (notificationsStore.loaded && !widgetForm.isSaving) return
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  const renderFormContent = () => {
    return (
      <div className='grid-item-content'>
        <DrawerNotificationInfo />
        <DrawerTemplateEditor />
        <DrawerDefaultButton />
      </div>
    )
  }

  const renderPreviewer = () => {
    return (
      <div className='grid-item-previewer'>
        <NotificationTemplatePreview />
      </div>
    )
  }

  return (
    <Dialog
      fullScreen
      open={widgetForm.isFormDialogOpen}
      onClose={() => widgetForm.closeEditor()}
      aria-labelledby='notification-template-dialog-title'
      aria-describedby='notification-template-dialog-description'
      disableEnforceFocus={true}
      disableAutoFocus={true}
    >
      <div id='NotificationTemplateDialogAppBar'>
        <NotificationTemplatesTopToolbar vm={vm} />
      </div>

      <Grid
        className='notification-template-dialog-grid'
        container
        spacing={0}
        direction='row'
        alignItems='stretch'
      >
        {renderSpinner()}
        <Grid item xs={7} sm={7} lg={7} xl={7} className='notification-template-dialog-grid-item'>
          {renderFormContent()}
        </Grid>
        <Grid item xs={5} sm={5} lg={5} xl={5} className='notification-template-dialog-grid-item'>
          {renderPreviewer()}
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default inject(
  'notificationsStore',
  'localizationStore'
)(observer(NotificationTemplatesDialog))
