import React from 'react'
import { inject, observer } from 'mobx-react'
import { EventsStore } from '../../store/EventsStore'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded'
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded'
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded'
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import './Calendar.scss'
import MDSpinner from 'react-md-spinner'

interface Props {
  eventsStore?: EventsStore
}

const CalendarTopToolbar: React.FC<Props> = ({ eventsStore }) => {
  const { calendarVM: vm } = eventsStore
  if (!vm) return null

  const renderSpinner = () => {
    if (!vm.loading) return
    return <MDSpinner size={30} singleColor='#049b98' />
  }

  return (
    <div id='CalendarTopToolbar'>
      <AppBar position='relative' elevation={0}>
        <Toolbar variant='dense'>
          <Box className='start-content'>
            <Button variant='outlined' className='mr-2' onMouseUp={() => vm.goToday()}>
              Today
            </Button>
            <IconButton aria-label='Back' onMouseUp={() => vm.goPrevious()}>
              <ChevronLeftRoundedIcon />
            </IconButton>
            <IconButton className='mr-2' aria-label='Next' onMouseUp={() => vm.goNext()}>
              <ChevronRightRoundedIcon />
            </IconButton>
            <Typography className='month-name'>{vm.displayedTimeFrame}</Typography>
          </Box>

          <Box className='end-content'>
            {renderSpinner()}
            <Button
              onClick={() => vm.openExportModal()}
              variant='outlined'
              startIcon={<GetAppRoundedIcon />}
            >
              Export
            </Button>
            <Button
              variant='outlined'
              onClick={(e) => vm.openViewMenu(e.currentTarget)}
              endIcon={<ExpandMoreRoundedIcon />}
            >
              {vm.viewModeName}
            </Button>
            <Button variant='contained' onClick={() => vm.createNewEvent()}>
              Add Event
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Menu
        id='MoreMenu'
        className='add-new-menu'
        elevation={2}
        anchorEl={vm.viewMenuAnchorEl}
        keepMounted
        open={vm.viewMenuShown}
        onClose={() => vm.closeViewMenu()}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={() => vm.setViewMode('month')}>
          <ListItemText primary='Month' />
        </MenuItem>
        <MenuItem onClick={() => vm.setViewMode('week')}>
          <ListItemText primary='Week' />
        </MenuItem>
        <MenuItem onClick={() => vm.setViewMode('work_week')}>
          <ListItemText primary='Work Week' />
        </MenuItem>
        <MenuItem onClick={() => vm.setViewMode('day')}>
          <ListItemText primary='Day' />
        </MenuItem>
        <MenuItem onClick={() => vm.setViewMode('agenda')}>
          <ListItemText primary='Agenda' />
        </MenuItem>
      </Menu>
    </div>
  )
}

export default inject('eventsStore')(observer(CalendarTopToolbar))
