import React from 'react'
import { inject, observer } from 'mobx-react'
import { TaskTemplatesStore } from '../store/TaskTemplatesStore'
import DeleteIcon from '@material-ui/icons/Delete'

import {
  Button,
  Grid,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  Paper,
  Table,
  CardContent,
} from '@material-ui/core'

interface Props {
  taskTemplatesStore?: TaskTemplatesStore
}

const TaskTypeParticipants: React.FC<Props> = ({ taskTemplatesStore }) => {
  const { taskTypeDrawerVM } = taskTemplatesStore

  const getRoleOptions = (part) => {
    const menuItems = []
    part.roleOptions.forEach((role, idx) => {
      menuItems.push(
        <MenuItem key={idx} value={role.value}>{role.label}</MenuItem>
      )
    })
    return menuItems
  }

  const renderParticipants = () => {
    const rows = []

    taskTypeDrawerVM.participantForms.forEach((part, idx) => {
      rows.push(
        <Grid container spacing={1} key={idx + '-row'} className='participantSelect'>
          <Grid item md={2}>
            <TextField
              type="number"
              value={part.quantity}
              className={part.quantityValid ? '' : 'invalid-input'}
              onChange={(e) => { part.quantity = Number(e.target.value) }}
            />
          </Grid>
          <Grid item md={9}>
            <Select
              value={part.roleId}
              onChange={e => part.setSelectedRole(e.target.value)}
              variant='outlined'
            >
              {getRoleOptions(part)}
            </Select>
          </Grid>
          <Grid item md={1}>
            <Button
              variant='outlined'
              className='btn-icon btn-icon-white btn-remove'
              onClick={() => taskTypeDrawerVM.removeParticipant(idx)}
              size='small'
            >
              <DeleteIcon />
            </Button>
          </Grid>
        </Grid>
      )
    })
    return rows
  }

  return (
    <div id='TaskTypeParticipants'>
      {renderParticipants()}
      <Button
        variant="contained"
        onClick={() => taskTypeDrawerVM.addParticipant()}
      >
        Add
      </Button>
    </div>
  )
}

export default inject('taskTemplatesStore')(observer(TaskTypeParticipants))
