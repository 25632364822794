import React from 'react'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import clsx from 'clsx'

const WidgetUserTaskBadge = ({ isCompleted }) => {
  return (
    <div className={clsx('userTaskBadge', isCompleted ? 'completedBadge' : 'incompleteBadge')}>
      {isCompleted ? <CheckIcon fontSize='inherit' /> : <CloseIcon fontSize='inherit' />}
    </div>
  )
}

export default WidgetUserTaskBadge
