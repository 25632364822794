import Parse, { Attributes, Query, Object } from 'parse'
import { RootStore } from '../../../stores/RootStore'
import { DataStore } from '../../../shared/data/DataStore'
import { SurveyResponse } from '../../../survey-responses/aggregate/SurveyResponse'
import { ISurveyResponseDTO } from '../../../survey-responses/dtos/ISurveyResponseDTO'
import { QuerySelector } from 'ag-grid-community'

export class CompletedSurveyResponsesDataStore extends DataStore<
  SurveyResponse,
  ISurveyResponseDTO
> {
  constructor(rootStore: RootStore) {
    super(rootStore, SurveyResponse, 'surveyResponses', [
      'isAnonymous',
      'objectId',
      'organizationId',
      'name',
      'respondedAt',
      'publishedByUserId',
      'publishedByUserName',
      'questionsCount',
      'userSurveyId',
      'surveyId',
      'categoryIds',
      'showResults',
      'anonymousToken',
    ])
    this.paged = true
    this.recordsPerPage = 100
    this.sortDirection = 'desc'
    this.sortColumnName = 'respondedAt'
  }

  protected getBaseQuery() {
    const queryAnonymousAndShownResults = new Parse.Query(this.className)
    queryAnonymousAndShownResults.equalTo('anonymizeResults', true)
    queryAnonymousAndShownResults.equalTo('showResults', true)

    const includeNonAnonymousResponses = new Parse.Query(this.className)
    includeNonAnonymousResponses.equalTo('anonymizeResults', false)

    const query = Parse.Query.or(queryAnonymousAndShownResults, includeNonAnonymousResponses)
    query.equalTo('organizationId', this.rootStore.appStore.currentOrgId)
    query.equalTo('userId', this.rootStore.appStore.currentUserId)
    query.notEqualTo('isDeleted', true)

    return query
  }

  public get rows() {
    return this.records
  }
}
