import Parse from 'parse'
import { IAPIResponse } from '../../shared/IAPIResponse'

export class UserInfoPromptService {
  public async markAsRead(id: string, orgId: string) {
    await Parse.Cloud.run('markUserInfoPromptAsRead', { id, orgId })
  }

  public async saveEmailAndPhone(
    userId: string,
    emailAddress: string,
    phoneNumber: string
  ): Promise<IAPIResponse> {
    return await Parse.Cloud.run('saveUserEmailAndPhoneNumber', {
      userId,
      emailAddress,
      phoneNumber,
    })
  }
}
