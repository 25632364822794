import React from 'react'
import { inject, observer } from 'mobx-react'
import { SurveysStore } from '../../../store/SurveysStore'
import { SurveyEditTabsEnum } from '../../../types/SurveyEditTabsEnum'
import ParticipantsSelect from '../../../../participants-select/views/ParticipantsSelect'

interface Props {
  surveysStore?: SurveysStore
}

const FeedbackTabPanel: React.FC<Props> = ({ surveysStore }) => {
  const { editVM } = surveysStore.viewModels
  if (!editVM) return null
  // if (editVM.currentTabIndex !== SurveyEditTabsEnum.FEEDBACK) return null

  return <ParticipantsSelect participantsSelectVM={editVM.feedbackParticipantsSelectVM} />
}

export default inject('surveysStore')(observer(FeedbackTabPanel))
