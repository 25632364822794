import { observable, computed, action, reaction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Role } from '../../roles/aggregate/Role'
import { WeightProfileVM } from './WeightProfileVM'
import { EditScreenVM } from './EditScreenVM'
import { CategoryVM } from './CategoryVM'
import { WeightProfile } from '../aggregate/WeightProfile'

export class RoleVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, editVM: EditScreenVM, role: Role) {
    this.rootStore = rootStore
    this.editVM = editVM
    this.role = role
  }

  @observable public role: Role = null
  @observable public editVM: EditScreenVM = null
  @observable public weightProfileVM: WeightProfileVM = null
  @observable public allChildrenChecked: boolean = false
  @observable public isSelected: boolean = false

  @computed
  public get categories(): Array<CategoryVM> {
    return this.editVM.categories
  }

  @computed
  public get loadingWeightProfile(): boolean {
    return Boolean(!this.weightProfileVM)
  }

  @action
  public lazyLoadWeightProfileVM(id: string, attempts: number = 0) {
    if (attempts === 10) {
      setTimeout(() => this.lazyLoadWeightProfileVM('new'))
      return
    }
    if (!this.rootStore.userStore.currentOrganization && process.env.NODE_ENV !== 'test') {
      setTimeout(() => this.lazyLoadWeightProfileVM(id, ++attempts), 500)
      return
    }
    if (id === 'empty') this.weightProfileVM = null
    else if (id === 'new')
      this.weightProfileVM = new WeightProfileVM(
        this.rootStore,
        this,
        WeightProfile.create(this.rootStore.appStore.currentOrgId)
      )
    else {
      const foundItem = this.rootStore.weightProfilesStore.getWeightProfileByRoleId(id)
      if (!foundItem) setTimeout(() => this.lazyLoadWeightProfileVM(id, ++attempts), 500)
      else this.weightProfileVM = new WeightProfileVM(this.rootStore, this, foundItem.clone())
    }
  }

  @action
  public toggleIsSelected() {
    this.isSelected = !this.isSelected
    if (this.isSelected) return this.lazyLoadWeightProfileVM(this.objectId)
    this.lazyLoadWeightProfileVM('empty')
  }

  @action
  public loadWeightProfileVM(attempts: number = 0) {
    if (attempts === 10) {
      return
    }
    const foundWeightProfile = this.rootStore.weightProfilesStore.getWeightProfileByRoleId(
      this.objectId
    )
    if (!foundWeightProfile) {
      setTimeout(() => this.loadWeightProfileVM(attempts + 1), 500)
      this.weightProfileVM = new WeightProfileVM(
        this.rootStore,
        this,
        WeightProfile.create(this.rootStore.appStore.currentOrgId)
      )
    } else {
      this.weightProfileVM = new WeightProfileVM(this.rootStore, this, foundWeightProfile.clone())
    }
  }

  @computed
  public get objectId() {
    return this.role.objectId
  }

  @computed
  public get name() {
    return this.role.name
  }

  @computed
  public get checkedCategories(): Array<CategoryVM> {
    return this.categories.filter((category) => category.isChecked)
  }

  @computed
  public get computedDefaultWeight() {
    const defaultWeight = Math.floor(100 / this.checkedCategories.length)
    return defaultWeight
  }

  @action
  public checkAll() {
    this.weightProfileVM.isDirty = true
    if (this.allChildrenChecked) {
      this.categories.forEach((cat) => {
        cat.weightProfileCategory.isChecked = false
        cat.weightProfileCategory.setValue(0)
      })
      this.toggleAllChildrenChecked()
    } else {
      this.categories.forEach((cat) => {
        cat.weightProfileCategory.isChecked = true
      })
      this.toggleAllChildrenChecked()
    }
    this.categories.forEach((category) => {
      if (category.isChecked) category.weightProfileCategory.setValue(this.computedDefaultWeight)
    })
  }

  @action
  public toggleAllChildrenChecked() {
    this.allChildrenChecked = !this.allChildrenChecked
  }

  @action
  public revertWeightProfile() {
    if (this.weightProfileVM.isNew)
      this.weightProfileVM = new WeightProfileVM(
        this.rootStore,
        this,
        WeightProfile.create(this.rootStore.appStore.currentOrgId)
      )
    else this.lazyLoadWeightProfileVM(this.objectId)
  }

  @action
  public saveWeightProfile() {
    this.weightProfileVM.save()
  }

  @action
  public deleteWeightProfile() {
    this.weightProfileVM.delete()
  }
}
