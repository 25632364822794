import { ColDef, ColumnApi, GridApi, GridReadyEvent } from 'ag-grid-community'
import { action, computed, observable } from 'mobx'
import { UserSurveyEventsService } from '../service/UserSurveyEventsService'
import { SurveyEventSummaryRowVM } from './SurveyEventSummaryRowVM'

export class SurveyEventSummaryVM {
  private orgId: string
  private surveyId: string

  public gridApi: GridApi
  private columnApi: ColumnApi
  @observable public eventType: 'published-email' | 'reminder-email' = 'published-email'
  @observable public loading: boolean = false

  constructor(orgId: string, surveyId: string) {
    this.orgId = orgId
    this.surveyId = surveyId
  }

  @computed
  public get fields() {
    return [
      { name: 'Event', field: 'event' },
      { name: 'Total', field: 'total' },
    ]
  }

  @action
  public onGridReady(e: GridReadyEvent) {
    this.gridApi = e.api
    this.columnApi = e.columnApi

    this.gridApi.setDatasource({
      rowCount: null,
      getRows: (params) => {
        const svc = new UserSurveyEventsService()
        this.loading = true
        svc
          .getSurveySendSummary({
            organizationId: this.orgId,
            eventType: this.eventType,
            surveyId: this.surveyId,
          })
          .then((result) => {
            const records = result.eventTotals.map((e) => new SurveyEventSummaryRowVM(e))
            const lastRow =
              records.length < params.endRow - params.startRow
                ? params.startRow + records.length
                : undefined
            params.successCallback(records, lastRow)
            this.loading = false
            this.updateGridData()
          })
      },
    })
    this.updateGridData()
  }

  public refreshList() {
    if (!this.gridApi) return
    this.gridApi.refreshInfiniteCache()
    this.gridApi.purgeInfiniteCache()
  }

  private updateGridData() {
    if (!this.gridApi) return
    this.gridApi.sizeColumnsToFit()
  }

  @computed
  public get getEventType() {
    return this.eventType
  }

  @action
  public handleEventChange(val: 'published-email' | 'reminder-email') {
    this.eventType = val
    this.refreshList()
  }

  @computed
  public get columnDefs(): ColDef[] {
    return this.fields.map((e) => {
      let def: ColDef = {
        headerName: e.name,
        sortable: false,
        field: e.field,
        editable: false,
        resizable: true,
        headerTooltip: e.name,
      }
      return def
    })
  }

  @action
  public getRowNodeId(row: SurveyEventSummaryRowVM) {
    return row.objectId
  }
}
