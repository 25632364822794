import { observable } from 'mobx'
import { serializable } from 'serializr'

export class Zone {
  static create(zoneObj) {
    const zone = new Zone()
    zone.objectId = zoneObj.objectId
    zone.location = zoneObj.location
    return zone
  }
  @serializable @observable public objectId: string = ''
  @serializable @observable public location: string = ''
}
