import { Grid, Tooltip, Typography } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { GoalsStore } from '../../store/GoalsStore'
import { GoalsCatalogWidgetRowVM } from '../../view-models/goal-catalog-widget/GoalsCatalogWidgetRowVM'
import ImageIcon from '@material-ui/icons/Image'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import './GoalsCatalogWidget.scss'
import GroupAddRoundedIcon from '@material-ui/icons/GroupAddRounded'

interface Props {
  goalsStore?: GoalsStore
  goal: GoalsCatalogWidgetRowVM
  key: number
  style: any
}

const GoalRow: React.FC<Props> = ({ goalsStore, goal, key, style }) => {
  const renderThumbnail = () => {
    if (goal.thumbnail) return <img src={goal.thumbnail} className='thumbnail' />
    if (!goal.thumbnail)
      return (
        <div className='default-thumbnail'>
          <ImageIcon />
        </div>
      )
  }

  const renderDatasourceAndFacet = () => {
    if (goal.dataSourceAndFacetString === '') return null
    return (
      <Typography variant='body1' className='source-pill'>
        {goal.dataSourceAndFacetString}
      </Typography>
    )
  }

  return (
    <Grid item xs={12} key={key} id='GoalRow' style={style}>
      {renderThumbnail()}
      <div style={{ width: '100%' }}>
        <Typography variant='h5' className='goal-title'>
          {goal.name}
          <span className='icons'>
            <Tooltip title={`Edit Catalog Goal`} placement='bottom' enterDelay={100}>
              <EditIcon className='edit' onClick={() => goal.editCatalogGoal()} />
            </Tooltip>
            <Tooltip title={`Assign this goal to new users`} placement='bottom' enterDelay={100}>
              <GroupAddRoundedIcon
                className='assign'
                fontSize='medium'
                onClick={() => goal.assignCatalogGoal()}
              />
            </Tooltip>
          </span>
        </Typography>
        <Typography variant='body2' className='goal-description'>
          {goal.description || '(No description given)'}
        </Typography>
        {renderDatasourceAndFacet()}
      </div>
    </Grid>
  )
}

export default inject('goalsStore')(observer(GoalRow))
