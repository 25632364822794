import React from 'react'
import { observer, inject } from 'mobx-react'
import { CardHeader, Tooltip, IconButton } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import LocalizationStore from '../../../../localization/LocalizationStore'
import Searchbar from '../../../../shared/Searchbar'
import { UserTrainingPlansStore } from '../../store/UserTrainingPlansStore'
import Title from './Title'

interface Props {
  userTrainingPlansStore?: UserTrainingPlansStore
  localizationStore?: LocalizationStore
}

const Header: React.FC<Props> = ({ userTrainingPlansStore, localizationStore }) => {
  const { myTrainingPlansWidgetVM: vm } = userTrainingPlansStore
  if (!vm) return null
  const { training_plans: strings } = localizationStore.lzStrings

  const renderAddButton = () => {
    if (!userTrainingPlansStore.canCreateTrainingPlans) return null
    return (
      <React.Fragment>
        <Tooltip title={strings.create_training_plan} placement='bottom'>
          <IconButton
            className='widget-cta'
            size='small'
            onClick={() => vm.createNewTrainingPlan()}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    )
  }

  return (
    <CardHeader
      id='Header'
      title={<Title />}
      action={
        <>
          <div className='search'>
            <Searchbar
              focused={vm.keywordFilter.length > 0}
              searchValue={vm.keywordFilter}
              onSearchChange={(e) => vm.setKeywordFilter(e.target.value)}
            />
          </div>
          {/* {renderAddButton()} */}
        </>
      }
    />
  )
}

export default inject('userTrainingPlansStore', 'localizationStore')(observer(Header))
