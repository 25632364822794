import React from 'react'
import { observer, inject } from 'mobx-react'
import { CardHeader } from '@material-ui/core'
import './Header.scss'
import { WorksheetsStore } from '../../store/WorksheetsStore'

interface Props {
  worksheetsStore?: WorksheetsStore
}

const Header: React.FC<Props> = ({ worksheetsStore }) => {
  const { listWidgetVM } = worksheetsStore
  if (!listWidgetVM) return null

  return <CardHeader id='Header' title={'Visualizations'}></CardHeader>
}

export default inject('worksheetsStore')(observer(Header))
