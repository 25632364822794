import React from 'react'
import { CardContent, Grid, Typography, Link } from '@material-ui/core'
import CreateIcon from '@material-ui/icons/Create'
import PersonIcon from '@material-ui/icons/Person'
import LinkIcon from '@material-ui/icons/Link'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import moment from 'moment'
import { observer, inject } from 'mobx-react'
import { EventsStore } from '../../../store/EventsStore'

interface IUserSurveyPopupBody {
  eventsStore?: EventsStore
}

const UserSurveyPopupBody: React.FC<IUserSurveyPopupBody> = ({ eventsStore }) => {
  const { surveyPopupVM: vm } = eventsStore

  const renderDueDate = () => {
    if (vm.dueDate === null) return null
    return (
      <Grid item xs={12}>
        <Typography variant='h5' className='popup-due'>
          Due: {vm.dueDate}
        </Typography>
      </Grid>
    )
  }

  if (!vm) return null
  return (
    <CardContent>
      <Grid item xs={12} className='user-survey-popup-title-grid'>
        <Typography variant='h6'>{vm.name}</Typography>
      </Grid>
      {renderDueDate()}
      <Grid
        item
        xs={12}
        container
        spacing={0}
        alignItems='flex-start'
        className='user-survey-popup-from-grid'
      >
        <Grid item xs={1} container justifyContent='center'>
          <PersonIcon className='icon' />
        </Grid>
        <Grid item>From: {vm.from}</Grid>
      </Grid>
      <Grid
        item
        xs={12}
        container
        spacing={0}
        alignItems='flex-start'
        className='user-survey-popup-created-at-grid'
      >
        <Grid item xs={1} container justifyContent='center'>
          <CreateIcon className='icon' />
        </Grid>
        <Grid item>Created: {vm.createdAt}</Grid>
      </Grid>
      <Grid
        item
        xs={12}
        container
        spacing={0}
        alignItems='flex-start'
        className='user-survey-popup-questions-grid'
      >
        <Grid item xs={1} container justifyContent='center'>
          <HelpOutlineIcon className='icon' />
        </Grid>
        <Grid item>{vm.questions}</Grid>
      </Grid>
    </CardContent>
  )
}

export default inject('eventsStore')(observer(UserSurveyPopupBody))
