import React from 'react'
import { inject, observer } from 'mobx-react'
import { Checkbox, CircularProgress, Grid, IconButton, Typography } from '@material-ui/core'
import ChevronDownIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import rootStore from '../../../stores/RootStore'
import { ChildCategoryRowVM } from '../../view-models/tree/ChildCategoryRowVM'
import { ParentCategoryRowVM } from '../../view-models/tree/ParentCategoryRowVM'
import ChildCategoryRow from './ChildCategoryRow'
import './CategoryRow.scss'

interface Props {
  vm: ParentCategoryRowVM
}

const ParentCategoryRow: React.FC<Props> = ({ vm }) => {
  if (!vm) return null
  const { pulseCampaign: lz } = rootStore.localizationStore.lzStrings

  const renderExpandIcon = () => {
    let icon = <ChevronRightIcon className={'expand-icon'} />
    if (vm.isExpanded) icon = <ChevronDownIcon className={'expand-icon'} />
    return (
      <IconButton
        disableRipple
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          vm.toggleIsExpanded(!vm.isExpanded)
        }}
        className={'icon-container'}
      >
        {icon}
      </IconButton>
    )
  }

  const renderBuildCard = () => {
    if (vm.childPulseQuestionCount === 0) return
    if (vm.childPulseSelectedQuestionCount === 0) return
    return (
      <Grid className={`parent-card-build border-cat-${vm.index}`} item xs={12}>
        <div id={'ParentCardTitle'}>
          <Typography className='parent-header'>{`${vm.name} (${vm.childPulseSelectedQuestionCount})`}</Typography>
        </div>
      </Grid>
    )
  }

  const handleClick = (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    event.preventDefault()
    vm.areAllQuestionsSelected
      ? vm.unselectRecursiveCategoryQuestions()
      : vm.selectRecursiveCategoryQuestions()
  }

  const renderFocusCard = () => {
    if (vm.childPulseQuestionCount === 0) return
    return (
      <Grid
        className={`parent-card-focus border-cat-${vm.index}`}
        item
        xs={12}
        onClick={() => vm.toggleIsExpanded(!vm.isExpanded)}
      >
        <div id={'ParentCardTitle'}>
          {renderExpandIcon()}
          <Typography className='parent-header'>{`${vm.name}`}</Typography>
          <Typography component='span' className='category-header-element-questions-number'>
            {vm.childPulseQuestionCount}
          </Typography>
        </div>
        {vm.childPulseQuestionCount > 0 ? (
          <div
            className='parent-category-header-element-selection-btn'
            onClick={handleClick}
          >
            <Checkbox
              checked={vm.areAllQuestionsSelected}
              onClick={handleClick}
              className='sub-category-header-element-check'
            />
            <Typography className='sub-category-header-element-text'>{`${vm.areAllQuestionsSelected ? lz.unselect : lz.select}  ${lz.all} (${vm.childPulseQuestionCount})`}</Typography>
          </div>
        ) : undefined}
      </Grid>
    )
  }

  const renderBuildChildCategories = () => {
    if (!vm.isLoaded) return renderSpinner()
    if (vm.childPulseQuestionCount === 0) return
    if (vm.childPulseSelectedQuestionCount === 0) return
    const categories = []
    vm.childPulseCategoryRows.forEach((row: ChildCategoryRowVM, i) => {
      categories.push(<ChildCategoryRow key={i} vm={row} />)
    })
    return (
      <div>
        {categories}
      </div>
    )
  }

  const renderFocusChildCategories = () => {
    if (!vm.isExpanded) return null
    if (!vm.isLoaded) return renderSpinner()
    if (vm.childPulseQuestionCount === 0) return
    const categories = []
    vm.childPulseCategoryRows.forEach((row: ChildCategoryRowVM, i) => {
      categories.push(<ChildCategoryRow key={i} vm={row} />)
    })
    if (categories.length === 0) return renderNoChildCategories()
    return (
      <div>
        {categories}
      </div>
    )
  }

  const renderSpinner = () => {
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  const renderNoChildCategories = () => {
    if (vm.childPulseCategoryRows.length) return null
    return (
      <div className={'no-child-container'}>
        <span>{lz.no_child_categories_to_show}</span>
      </div>
    )
  }

  return (
    <>
      <div className={`parent-container ${vm.isBuild ? '' : `border-cat-${vm.index}`}`}>
        {vm.isBuild ? renderBuildCard() : renderFocusCard()}
        {vm.isBuild ? renderBuildChildCategories() : renderFocusChildCategories()}
      </div>
    </>
  )
}

export default inject()(observer(ParentCategoryRow))
