import React, { FC } from 'react'
import { observer } from 'mobx-react'
import {
  Modal,
  Paper,
  Toolbar,
  Typography,
  IconButton,
  Checkbox,
  Grid,
  FormControlLabel,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import UsersList from './UsersList'
import { GroupedRowVM } from '../view-models/GroupedRowVM'

interface Props {
  groupedRowVM: GroupedRowVM
}

const UsersListModal: FC<Props> = ({ groupedRowVM }) => {
  const vm = groupedRowVM

  const renderCheckBox = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={vm.isParticipantSelected}
            onClick={(e) => {
              e.stopPropagation()
              vm.toggleParticipant()
            }}
          />
        }
        label='Select All'
      />
    )
  }

  const renderToolBar = () => {
    return (
      <Toolbar className='modal-toolbar'>
        <div className='left-toolbar'>
          <Typography variant='body1' className='audience-member-name'>
            {vm.name}
          </Typography>
        </div>
        <IconButton
          onClick={(e) => {
            e.stopPropagation()
            vm.closeUsersListModal()
          }}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
    )
  }

  return (
    <Modal
      disableBackdropClick={true}
      onBackdropClick={(e) => vm.closeUsersListModal()}
      onClose={() => vm.closeUsersListModal()}
      open={vm.isUsersListModalOpen}
      id='UsersListModal'
    >
      <Paper className='modal-list-container'>
        <Grid container direction='column'>
          <Grid item>{renderToolBar()}</Grid>
          <Grid item onClick={(e) => e.stopPropagation()} className='checkbox-and-searchbar-row'>
            {renderCheckBox()}
          </Grid>
        </Grid>
        <UsersList usersAGGridVM={vm.usersAGGridVM} />
      </Paper>
    </Modal>
  )
}

export default observer(UsersListModal)
