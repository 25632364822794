import { action, observable } from 'mobx'
import { IAggregate } from '../../shared/data/IAggregate'
import { serializable, serialize, list, primitive } from 'serializr'
import { ISurveyTemplateFolderDTO } from '../dtos/ISurveyTemplateFolderDTO'

export default class SurveyTemplateFolder implements ISurveyTemplateFolderDTO, IAggregate {
  static create(orgId) {
    const surveyTemplateFolder = new SurveyTemplateFolder()
    surveyTemplateFolder.organizationId = orgId
    return surveyTemplateFolder
  }

  @serializable @observable objectId: string = ''
  @serializable @observable organizationId: string = ''
  @serializable @observable name: string = ''
  @serializable @observable parentFolderId: string = ''
  @serializable @observable isDeleted: boolean = false
  @serializable(list(primitive())) @observable public subscribedOrganizationIds: string[] = []

  isOnServer: boolean = false

  @action
  setName(name: string) {
    this.name = name
  }

  @action
  markIsNotOnServer() {
    this.isOnServer = false
  }

  @action
  markIsOnServer() {
    this.isOnServer = true
  }

  @action
  public addOrg(id) {
    this.subscribedOrganizationIds.push(id)
  }

  @action
  public removeOrg(id) {
    this.subscribedOrganizationIds = this.subscribedOrganizationIds.filter((e) => e !== id)
  }

  public serialize(): ISurveyTemplateFolderDTO {
    return serialize(this)
  }
}
