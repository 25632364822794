import React from 'react'
import { IndexRoute, Route } from 'react-router'
import Layout from '../../layout/Layout'
import { authenticate } from '../../auth/routes/AuthenticatedRoute'
import PulseCategoriesManageWrapper from '../../pulse-categories/views/PulseCategoriesManageWrapper'
import PulseSetsManageWrapper from '../../pulse-sets/views/manage/PulseSetsManageWrapper'
import EmailTemplatesManageWrapper from '../../email-templates/views/EmailTemplatesManageWrapper'
import PulseQuestionEditScreen from '../../pulse-questions/views/edit/PulseQuestionEditScreen'
import SurveyTemplatesImportWrapper from '../../survey-templates/views/SurveyTemplatesImportWrapper'
import PulseCampaignTypesManageWrapper from 'src/app/pulse-campaign-types/views/PulseCampaignTypesManageWrapper'
import PulseCampaignTypesNewWrapper from 'src/app/pulse-campaign-types/views/PulseCampaignTypesNewWrapper'
import PulseCampaignTypesEditWrapper from 'src/app/pulse-campaign-types/views/PulseCampaignTypesEditWrapper'
import SurveyTypesSAManageWrapper from '../../survey-types/views/system/SurveyTypesSAManageWrapper'
import SurveyTypesSAEditWrapper from '../../survey-types/views/system/SurveyTypesSAEditWrapper'
import SurveyTemplatesPermissionsWrapper from '../../survey-templates/views/manageV2/permissions/SurveyTemplatesPermissionsWrapper'
import SystemSurveyTemplatesManageWrapper from '../../survey-templates/views/manageV2/system/SystemSurveyTemplatesManageWrapper'

export default function getSystemAdminRoutes() {
  return (
    <Route key={'systemAdmin0'} component={authenticate(Layout)} path='systemAdmin'>
      <IndexRoute onEnter={(nextState, replace) => replace('')} />
      <Route
        key={'systemAdmin4'}
        path='manage/pulseCampaignTypes'
        component={PulseCampaignTypesManageWrapper}
      />
      <Route
        key={'systemAdmin5'}
        path='manage/pulseCampaignTypes/new'
        component={PulseCampaignTypesNewWrapper}
      />
      <Route
        key={'pct1'}
        path='manage/pulseCampaignTypes/:objectId'
        component={PulseCampaignTypesEditWrapper}
      />
      <Route
        key={'systemAdmin1'}
        path='manage/pulseCategories'
        component={PulseCategoriesManageWrapper}
      />
      <Route key={'systemAdmin2'} path='manage/pulseSets' component={PulseSetsManageWrapper} />
      <Route key={'pt1'} path='manage/surveyTemplates' component={SystemSurveyTemplatesManageWrapper} />
      <Route
        key={'pt2'}
        path='manage/surveyTemplates/import'
        component={SurveyTemplatesImportWrapper}
      />
      <Route
        key={'systemAdmin3'}
        path='manage/emailTemplates'
        component={EmailTemplatesManageWrapper}
      />
      <Route
        key={'pq1'}
        path='pulseQuestion/edit/:id/:childCategoryId'
        component={PulseQuestionEditScreen}
      />
       <Route
        key={'systemAdmin6'}
        path='manage/surveyTypes'
        component={SurveyTypesSAManageWrapper}
      />
       <Route
        key={'systemAdmin8'}
        path='manage/surveyTemplates/permissions'
        component={SurveyTemplatesPermissionsWrapper}
      />
    </Route>
  )
}
