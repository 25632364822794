import React from 'react'
import { Grid, Switch, Typography } from '@material-ui/core'
import { PulseCampaignEditVM } from '../../view-models/PulseCampaignEditVM'
import { inject, observer } from 'mobx-react'
import './PulseCampaignDetailsColRight.scss'

interface Props {
  editVM: PulseCampaignEditVM
}

const PulseCampaignDetailsColRight: React.FC<Props> = ({ editVM }) => {
  const renderCampaignStatus = () => {
    return (
      <Typography className='campaign-status'>
        {/* {!editVM.isLoading ? editVM.campaignStatusText : `${lz.loading}...`} */}
        {editVM.campaignStatusText}
      </Typography>
    )
  }

  const renderAnonymizeResultsToggle = () => {
    return (
      <Grid item>
        <div className='input-div owner-input'>
          <Switch
            color='primary'
            checked={editVM.pulseCampaign.anonymizeResults}
            onChange={() => editVM.toggleAnonymizeResults()}
          />
        </div>
      </Grid>
    )
  }

  return (
    <div id='PulseCampaignDetailsColRight'>
      <Grid
        container
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-start'
        className={'details-grid'}
      >
        <Grid item className='details-grid-header'>
          <Grid
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='flex-start'
            className={'details-grid-field-wrapper'}
          >
            <Typography component='span' className='details-grid-header-title'>
              {/* {lz.pulse_campaign + ' ' + lz.type} */}
              Pulse Campaign Status
            </Typography>
          </Grid>
        </Grid>
        <Grid item className='details-grid-header'>
          <Grid
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='flex-start'
            className={'details-grid-field-wrapper'}
          >
            {renderCampaignStatus()}
          </Grid>
        </Grid>
        <Grid item className='details-grid-header'>
          <Grid
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='flex-start'
            className={'details-grid-field-wrapper'}
          >
            <Typography component='span' className='details-grid-header-title'>
              Anonymize Responses
            </Typography>
          </Grid>
        </Grid>
        <Grid item className='details-grid-header'>
          <Grid
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='flex-start'
            className={'details-grid-field-wrapper'}
          >
            {renderAnonymizeResultsToggle()}
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default inject('pulseCampaignsStore')(observer(PulseCampaignDetailsColRight))
