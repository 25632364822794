import React from 'react'
import { observer } from 'mobx-react'
import rootStore from '../../../stores/RootStore'
import { Grid } from '@material-ui/core'
import RolesSideBar from './RolesSideBar/RolesSideBar'
import CategoriesScreen from './CategoriesScreen/CategoriesScreen'
import SaveSnackbar from './SaveSnackbar'
import CopySnackbar from './CopySnackbar'
import ImportModal from './ImportModal'
import './WeightProfileEditScreen.scss'

@observer
export default class WeightProfileEditScreen extends React.Component<any, any> {
  render() {
    return (
      <div id='WeightProfileEditScreen'>
        <ImportModal />
        <SaveSnackbar />
        <CopySnackbar />


        <div className='grid-layout'>
          <div className='roles-sidebar'>
            <RolesSideBar />
          </div>
          <div className='categories-screen'>
            <CategoriesScreen />
          </div>
        </div>
      </div>
    )
  }
}
