import React, { FC } from 'react'
import { observer, inject } from 'mobx-react'
import './SurveyOptions.scss'
import { SurveysStore } from 'src/app/surveys/store/SurveysStore'
import {
  Checkbox,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { ReminderVM } from 'src/app/surveys/view-models/ReminderVM'
import DeleteIcon from '@material-ui/icons/Close'
import AddIcon from '@material-ui/icons/Add'
import { KeyboardDatePicker } from '@material-ui/pickers'
import TimePicker from '../../../../shared/time-picker/TimePicker'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { LabelsStore } from '../../../../labels/store/LabelsStore'

interface Props {
  surveysStore?: SurveysStore
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
}

const SendAutomaticReminders: FC<Props> = ({ surveysStore, localizationStore, labelsStore }) => {
  const surveyEditVM = surveysStore.viewModels.editVM
  const vm = surveyEditVM.surveyOptionsVM
  if (!surveyEditVM) return null
  if (!vm) return null
  const s = localizationStore.lzStrings

  const renderDeleteIcon = (mode) => {
    if (mode === 'specific' && surveyEditVM.specificReminders.length <= 1) return null
    if (mode === 'period' && surveyEditVM.periodReminders.length <= 1) return null
    return (
      <IconButton
        disabled={!surveyEditVM.sendReminders}
        onClick={() => surveyEditVM.deleteReminder(mode)}
      >
        <DeleteIcon />
      </IconButton>
    )
  }

  const renderCustomPeriod = () => {
    if (!surveyEditVM.sendReminders) return null

    const renderCustomPeriodRow = (reminder: ReminderVM, index: number) => {
      return (
        <div className='reminder-row' key={'remind-' + index}>
          <div className='reminder-input-row'>
            {reminder.period === 'minute' ? (
              <FormControl variant='outlined'>
                <Select
                  value={reminder.value}
                  onChange={(e) => {
                    reminder.setValue(e.target.value)
                  }}
                  className='reminder-value-select'
                  disabled={!surveyEditVM.dueAfterEnabled}
                >
                  <MenuItem value={15}>15</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={45}>45</MenuItem>
                </Select>
              </FormControl>
            ) : (
              <TextField
                value={reminder.value}
                onChange={(e) => reminder.setValue(e.target.value as string)}
                variant='outlined'
                autoFocus
                onFocus={(e) => e.target.select()}
                disabled={!surveyEditVM.dueAfterEnabled}
              />
            )}
            <FormControl variant='outlined'>
              <Select
                value={reminder.period}
                onChange={(e) => {
                  reminder.setPeriod(e.target.value)
                }}
                className='reminder-period-select'
                disabled={!surveyEditVM.dueAfterEnabled}
              >
                <MenuItem value={'hour'}>Hours</MenuItem>
                <MenuItem value={'minute'}>Minutes</MenuItem>
                <MenuItem value={'day'}>Days</MenuItem>
                <MenuItem value={'week'}>Weeks</MenuItem>
                <MenuItem value={'month'}>Months</MenuItem>
              </Select>
            </FormControl>
            <Typography component='span'>{`Before the ${labelsStore.getLabelByLanguageAndFor(
              'pulse'
            )} is Due`}</Typography>
            {renderDeleteIcon(reminder.mode)}
            <IconButton
              onClick={() => surveyEditVM.addReminder({ mode: 'period', units: 1, period: 'hour' })}
            >
              <AddIcon />
            </IconButton>
          </div>
        </div>
      )
    }

    return (
      <>
        {surveyEditVM.periodReminders.map((reminder, index) =>
          renderCustomPeriodRow(reminder, index)
        )}
      </>
    )
  }

  const renderCheckboxes = () => {
    return (
      <>
        <Grid className='reminder-checkbox-container'>
          <FormControlLabel
            control={
              <Checkbox
                checked={surveyEditVM.sendRemindersOnDueDate}
                onChange={() => surveyEditVM.toggleSendRemindersOnDueDate()}
                disabled={!surveyEditVM.dueAfterEnabled}
                size='small'
              />
            }
            label={' On Due Date (' + surveyEditVM.reminderDueDateText + ')'}
            disabled={!surveyEditVM.dueAfterEnabled}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={surveyEditVM.sendReminders15BeforeDueDate}
                onChange={() => surveyEditVM.toggleSendReminders15BeforeDueDate()}
                disabled={!surveyEditVM.dueAfterEnabled}
                size='small'
              />
            }
            label={`15 minutes before the ${labelsStore.getLabelByLanguageAndFor('pulse')} is due`}
            disabled={!surveyEditVM.dueAfterEnabled}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={surveyEditVM.sendRemindersCustomPeriod}
                onChange={() => surveyEditVM.toggleSendRemindersCustomPeriod()}
                disabled={!surveyEditVM.dueAfterEnabled}
                size='small'
              />
            }
            label='Custom Period(s)'
            disabled={!surveyEditVM.dueAfterEnabled}
          />
        </Grid>
      </>
    )
  }

  const renderSpecificReminderDateTimeHeader = () => {
    if (!surveyEditVM.sendReminders) return null
    if (!surveyEditVM.dueAfterEnabled) {
      return (
        <>
          <div className='primary-text textfield-label'>At Exact Date/Time</div>
        </>
      )
    }
    return (
      <>
        <FormControlLabel
          control={
            <Checkbox
              checked={surveyEditVM.sendRemindersSpecificDate}
              onChange={() => surveyEditVM.toggleSendRemindersSpecificDate()}
              disabled={!surveyEditVM.sendReminders}
              size='small'
            />
          }
          label='At Exact Date/Time'
          disabled={!surveyEditVM.sendReminders}
        />
      </>
    )
  }

  const renderSpecificReminderDateTime = () => {
    if (!surveyEditVM.sendReminders) return null

    const renderSpecificReminderRow = (reminder: ReminderVM, index: number) => {
      return (
        <div className='specific-date-time-reminder-content' key={'specific-' + reminder.uuid}>
          <div>
            <KeyboardDatePicker
              disablePast={surveyEditVM.isNewSurvey ? true : false}
              inputVariant='outlined'
              format='MM/dd/yyyy'
              margin='none'
              disableToolbar
              className='deliver-on-specific-date-picker'
              autoOk
              error={false}
              helperText={null}
              id='date-picker-inline6'
              value={reminder.sendReminderDate}
              onChange={(e) => reminder.setDate(e)}
            />
          </div>
          <div>
            <TimePicker
              error={false}
              value={reminder.sendReminderTime}
              key={String(surveyEditVM.vmCreatedAt)}
              onChange={(hr, min) => reminder.setTime(hr, min)}
            />
          </div>
          <div>
            <Autocomplete
              options={surveyEditVM.timeZones}
              getOptionLabel={(e) => e.label}
              value={reminder.timeZone}
              onChange={(e, val) => reminder.setTimeZone(val)}
              renderInput={(params) => (
                <TextField {...params} placeholder='Select Time Zone' variant='outlined' />
              )}
              className='time-zone-autocomplete'
            />
          </div>
          {renderDeleteIcon(reminder.mode)}
          <IconButton
            disabled={!surveyEditVM.sendReminders}
            onClick={() => surveyEditVM.addReminder({ mode: 'specific' })}
          >
            <AddIcon />
          </IconButton>
        </div>
      )
    }

    return (
      <>
        {renderSpecificReminderDateTimeHeader()}
        {surveyEditVM.specificReminders.map((reminder, index) =>
          renderSpecificReminderRow(reminder, index)
        )}
      </>
    )
  }

  const renderNoDueDateSet = () => {
    if (surveyEditVM.dueAfterEnabled) return null
    if (!surveyEditVM.sendReminders) return null
    return (
      <>
        <Grid item xs={8} className='reminder-container'>
          {renderSpecificReminderDateTime()}
          <div className='secondary-text reminder-nodue-text'>
            Set a Due Date in the 'Publishing Options' tab to Enable the Following:
          </div>
          {renderCheckboxes()}
          {renderCustomPeriod()}
        </Grid>
      </>
    )
  }

  const renderDueDateSet = () => {
    if (!surveyEditVM.survey.dueAfterEnabled) return null
    if (!surveyEditVM.sendReminders) return null
    return (
      <>
        <Grid item xs={8} className='reminder-container'>
          {renderCheckboxes()}
          {renderCustomPeriod()}
          {renderSpecificReminderDateTime()}
        </Grid>
      </>
    )
  }

  return (
    <div className='section survey-published-notification-templates'>
      <div className='primary-text textfield-label'>Send Automatic Reminders</div>
      <div className='section-content'>
        <FormControlLabel
          control={
            <Switch
              checked={surveyEditVM.sendReminders}
              onChange={() => surveyEditVM.toggleSendReminders()}
            />
          }
          label={surveyEditVM.sendReminders ? 'On' : 'Off'}
        />
        {renderDueDateSet()}
        {renderNoDueDateSet()}
      </div>
    </div>
  )
}

export default inject(
  'surveysStore',
  'localizationStore',
  'labelsStore'
)(observer(SendAutomaticReminders))
