import { Card } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { TrainingPlansStore } from '../../../../training-plans/store/TrainingPlansStore'
import Content from './Content'
import Header from './Header'
import './TrainerWidget.scss'

interface Props {
  trainingPlansStore?: TrainingPlansStore
}

const TrainerWidget: React.FC<Props> = ({ trainingPlansStore }) => {
  const { trainerWidgetVM: vm } = trainingPlansStore
  if (!vm) return null
  return (
    <Card id='TrainerWidget' elevation={0}>
      <Header />
      <Content />
    </Card>
  )
}

export default inject('trainingPlansStore')(observer(TrainerWidget))
