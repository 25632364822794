import * as Sentry from '@sentry/browser'
import env from '../../env'
import rootStore from '../stores/RootStore'

class SentryConfig {
  private enabled: boolean = false
  private environment: string = ''

  constructor() {
    this.environment = env.var.REACT_APP_SENTRY_ENVIRONMENT
    this.enabled =
      env.var.REACT_APP_SENTRY_ENABLED === '1' && env.var.REACT_APP_SENTRY_ENVIRONMENT !== 'LOCAL'
  }

  private setSentryScope() {
    console.log('setting scope')
    if (
      !rootStore.userStore ||
      !rootStore.userStore.user ||
      !rootStore.userStore.user.objectId ||
      !rootStore.appStore ||
      !rootStore.appStore.currentOrgId
    ) {
      setTimeout(() => this.setSentryScope(), 1000)
      return
    }
    Sentry.configureScope((scope) => {
      const user = {
        id: rootStore.userStore.user.objectId,
      }
      scope.setUser(user)
      scope.setTag('organizationId', rootStore.appStore.currentOrgId)
    })
  }

  private initSentry() {
    Sentry.init({
      dsn: 'https://c7c5033c393a421a96c11343ca79e9af@sentry.io/1831234',
      environment: this.environment,
      enabled: true,
      debug: true,
      // integrations: function(integrations) {
      //   return integrations.filter(function(integration) {
      //     return integration.name !== 'Breadcrumbs'
      //   })
      // },
    })
  }

  public init() {
    if (process.env.NODE_ENV === 'test') return
    if (this.enabled) this.initSentry()
    // this.setSentryScope()
  }
}

const cfg = new SentryConfig()
export default cfg
