import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { EventsStore } from '../../store/EventsStore'
import { CardHeader, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

interface DrawerHeaderProps {
  localizationStore?: LocalizationStore
  eventsStore?: EventsStore
}

const DrawerHeader: React.FC<DrawerHeaderProps> = ({ localizationStore, eventsStore }) => {
  const { lzStrings } = localizationStore
  const { editVM: VM } = eventsStore

  return (
    <CardHeader
      action={
        <IconButton onClick={eventsStore.calendarVM.closeCalendarEventDrawer}>
          <CloseIcon />
        </IconButton>
      }
      title={
        Boolean(VM.objectId)
          ? lzStrings.calendarEventDrawer.edit_event
          : lzStrings.calendarEventDrawer.create_new_event
      }
    />
  )
}

export default inject('localizationStore', 'eventsStore')(observer(DrawerHeader))
