import React from 'react'
import clsx from 'clsx'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { TasksStore } from '../../store/TasksStore'
import { ButtonBase } from '@material-ui/core'
import './TasksManagementWidget.scss'

interface WidgetTitleProps {
  localizationStore?: LocalizationStore
  tasksStore?: TasksStore
}

const WidgetTitle: React.FC<WidgetTitleProps> = ({ localizationStore, tasksStore }) => {
  const { lzStrings } = localizationStore
  const { taskManagementWidgetVM: vm } = tasksStore
  if (!vm) return null

  const renderTabs = () => {
    return (
      <div className='widget-title-tabs'>
        <div className='tab-btns'>
          <ButtonBase
            onClick={() => vm.setTabIndex(0)}
            className={clsx('tab-button', {
              active: vm.tabIndex === 0,
            })}
          >
            {lzStrings.tasksManagementWidget.incomplete}
          </ButtonBase>
          <ButtonBase
            onClick={() => vm.setTabIndex(1)}
            className={clsx('tab-button', {
              active: vm.tabIndex === 1,
            })}
          >
            {lzStrings.tasksManagementWidget.completed}
          </ButtonBase>
          <ButtonBase
            onClick={() => vm.setTabIndex(2)}
            className={clsx('tab-button', {
              active: vm.tabIndex === 2,
            })}
          >
            {lzStrings.tasksManagementWidget.all}
          </ButtonBase>
        </div>
      </div>
    )
  }

  return (
    <div className={'widget-title'}>
      <div className='title-tabs'>
        <span>{lzStrings.tasksManagementWidget.manage_tasks}</span>
        {renderTabs()}
      </div>
      <div></div>
    </div>
  )
}

export default inject('localizationStore', 'tasksStore')(observer(WidgetTitle))
