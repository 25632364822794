import React from 'react'
import { inject, observer } from 'mobx-react'
import { Button, Card, Dialog, DialogTitle, Grid, IconButton, Typography } from '@material-ui/core'
import LocalizationStore from '../../../localization/LocalizationStore'
import { PulseCampaignSurveyEditVM } from '../../view-models/PulseCampaignSurveyEditVM'
import UserSurveyTakeWidget from '../../../user-surveys/views/take/UserSurveyTakeWidget'
import CloseIcon from '@material-ui/icons/Close'
import EmailTemplatesPreviewToggle from '../../../email-templates/views/previewer/EmailTemplatesPreviewToggle'
import EmailTemplatePreview from '../../../email-templates/views/previewer/EmailTemplatePreview'
import { PulseCampaignEditVM } from '../../view-models/PulseCampaignEditVM'

interface Props {
  vm: PulseCampaignSurveyEditVM
  localizationStore?: LocalizationStore
  evm?: PulseCampaignEditVM
}

const PulseCampaignSurveyPreviewModal: React.FC<Props> = ({ vm, localizationStore, evm }) => {
  const renderHeader = () => {
    return (
      <DialogTitle className='header-text'>
        <Grid className='details-title'>
          <Grid>{'Pulse Preview'}</Grid>
          <EmailTemplatesPreviewToggle vm={vm.campaignEditVM.previewVM} />
          <IconButton size='small' className='margin-right' onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
    )
  }

  const renderTab = () => {
    if (vm.campaignEditVM.previewVM.previewTabIndex === 0)
      return <EmailTemplatePreview vm={vm.campaignEditVM.previewVM} />
    if (vm.campaignEditVM.previewVM.previewTabIndex === 1 && evm)
      return <UserSurveyTakeWidget takeVM={evm.previewTakeVM} />
    if (vm.campaignEditVM.previewVM.previewTabIndex === 1)
      return <UserSurveyTakeWidget takeVM={vm.previewTakeVM} />
    if (vm.campaignEditVM.previewVM.previewTabIndex === 2 && evm)
      return <UserSurveyTakeWidget takeVM={evm.previewTakeVM} />
    if (vm.campaignEditVM.previewVM.previewTabIndex === 2)
      return <UserSurveyTakeWidget takeVM={vm.previewTakeVM} />
    return
  }

  const renderPreview = () => {
    const previewClass =
      vm.campaignEditVM.previewVM.previewTabIndex < 2 ? 'preview-desktop' : 'preview-mobile'
    return (
      <div className='intro-preview-root'>
        <div id='IntroductionTakePreview' className={previewClass}>
          {renderTab()}
        </div>
      </div>
    )
  }

  const handleClose = () => {
    vm.campaignEditVM.previewVM.setPreviewTabIndex(0)
    vm.toggleSurveyPreview()
  }

  const renderModal = () => {
    return (
      <Dialog
        onClose={handleClose}
        open={vm.showSurveyPreview}
        id='CampaignDetails'
        maxWidth={'lg'}
      >
        {renderHeader()}
        {renderPreview()}
      </Dialog>
    )
  }

  return <div id='SurveyPreviewModal'>{renderModal()}</div>
}

export default inject('localizationStore')(observer(PulseCampaignSurveyPreviewModal))
