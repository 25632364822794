import { action, observable } from 'mobx'
import { serializable, serialize } from 'serializr'
import { IAggregate } from '../../shared/data/IAggregate'
import { IPulseSurveyScheduleDTO } from '../dto/IPulseSurveyScheduleDTO'

export class PulseSurveySchedule implements IPulseSurveyScheduleDTO, IAggregate {
  public static create(orgId: string) {
    const pulse = new PulseSurveySchedule()
    pulse.organizationId = orgId
    return pulse
  }

  @serializable @observable public objectId: string = ''
  @serializable @observable public surveyId: string = ''
  @serializable @observable public pulseCampaignSurveyId: string = ''
  @serializable @observable public pulseCampaignId: string = ''
  @serializable @observable public pulseCampaignSurveyName: string = undefined
  @serializable @observable public pulseCampaignName: string = undefined
  @serializable @observable public organizationId: string = ''
  @serializable @observable public status: string = 'not processed'
  @serializable @observable public effectiveDateTime: string = ''
  @serializable @observable public isDeleted: boolean = false

  public isOnServer: boolean = false

  @action
  public markIsNotOnServer() {
    this.isOnServer = false
  }

  @action
  public markIsOnServer() {
    this.isOnServer = true
  }

  public serialize(): IPulseSurveyScheduleDTO {
    try {
      const dto = serialize(this)
      return dto
    } catch (e) {
      console.error(e)
      console.log(this)
    }
  }
}
