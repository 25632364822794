import React from 'react'

//MobX Stores
import { observer, inject } from 'mobx-react'
import { QuestionsStore } from '../store/QuestionsStore'

//MaterialUI Components
import { TextField } from '@material-ui/core'

//Styles'
import './DrawerWeightMenu.scss'

interface DrawerWeightMenuProps {
  questionsStore?: QuestionsStore
  idx: number
  option: any
}

const DrawerWeightMenu: React.FC<DrawerWeightMenuProps> = ({ questionsStore, idx, option }) => {
  const { editVM: QuestionCatalogWidgetForm } = questionsStore

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  return (
    <div id='WeightMenu'>
      <TextField
        InputProps={{ inputProps: { min: 0 } }}
        className={option.weightValid ? 'weight-input' : 'error'}
        type='number'
        value={option.weight}
        onChange={(e) => option.setWeight(e.target.value)}
      />
    </div>
  )
}

export default inject('questionsStore')(observer(DrawerWeightMenu))
