import { RootStore } from '../../stores/RootStore'
import env from '../../../env'
import axios from 'axios'
import { ISurveyResultFindResult } from '../interfaces/ISurveyResultFindResult'

export class SurveyResultsService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public async findByToken(token: string): Promise<ISurveyResultFindResult> {
    const url = env.var.REACT_APP_API_URL + '/surveyResults/find?token=' + token
    const response = await axios.get(url)
    if (!response) return null
    if (!response.data) return null
    if (!response.data.data) return null
    if (!response.data.data.success) return null
    return response.data.data
  }
}
