import React from 'react'
import WidgetHeader from './WidgetHeader'
import WidgetContent from './WidgetContent'
import ClientDrawer from './ClientDrawer'
import { Card } from '@material-ui/core'
import './ClientManagementWidget.scss'
import { observer } from 'mobx-react'

const ClientManagementWidget: React.FC = () => {
  return (
    <>
      <Card id='ClientManagementWidget' elevation={0}>
        <WidgetHeader />
        <WidgetContent />
      </Card>
      <ClientDrawer />
    </>
  )
}

export default observer(ClientManagementWidget)
