import { action, computed, observable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { AuthenticationService } from '../services/AuthenticationService'

export class ForgotPasswordVM {
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  private rootStore: RootStore
  @observable username: string = ''
  @observable fetching: boolean = false
  @observable resetPasswordSuccess: boolean = false
  @observable resetPasswordFailed: boolean = false
  @observable resetUnknownError: boolean = false

  @action setUsername = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.username = event.target.value
  }

  @action setForgotPasswordUsername(value) {
    this.username = value
  }

  @computed get usernameError(): boolean {
    return false
  }

  @computed get loginButtonEnabled(): boolean {
    return Boolean(this.username)
  }

  @action
  public async goToLogin() {
    await this.rootStore.appStore.logout()
    this.rootStore.appStore.router.push('/auth/login')
  }

  @action handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault()
    try {
      this.fetching = true
      const authSvc = new AuthenticationService()
      const result = await authSvc.sendPasswordReset(this.username)

      if (result === 'SUCCESS') {
        this.resetPasswordSuccess = true
        this.resetPasswordFailed = false
        this.resetUnknownError = false
        this.fetching = false

        this.goToLogin()
      } else if (result === 'UNKNOWN ERROR') {
        this.resetUnknownError = true
        this.resetPasswordSuccess = false
        this.resetPasswordFailed = false
        this.fetching = false
      }
    } catch (error) {
      console.log(error)
    }
  }
}
