import React, { FC } from 'react'
import clsx from 'clsx'
import { inject, observer } from 'mobx-react'
import { AppBar, Button, CssBaseline, Grid, Toolbar, Tooltip, Typography } from '@material-ui/core'
import BookmarkIcon from '@material-ui/icons/Bookmark'
import EditRoundedIcon from '@material-ui/icons/EditRounded'
import ToggleButton from '@material-ui/lab/ToggleButton'
import rootStore from '../../stores/RootStore'
import { WorksheetsStore } from '../../worksheets/store/WorksheetsStore'
import { ContentVM } from '../view-models/ContentVM'
import Searchbar from 'src/app/shared/Searchbar'
import isIE11 from '../../../utils/isIE11'
import '../../css/dashboard-toolbar.scss'
import './Visualizations.scss'
import { OrganizationUsersStore } from '../../organization-users/store/OrganizationUsersStore'

interface Props {
  worksheetsStore?: WorksheetsStore
  vm: ContentVM
}

const VisualizationsTopToolbar: FC<Props> = ({ worksheetsStore, vm }) => {
  if (!vm) return null
  const listVM = rootStore.userWorksheetsStore.contentVM

  const renderSearchbar = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Searchbar
            containerStyle={{
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              color: 'black',
              height: '34px',
              borderRadius: '4px',
            }}
            focused={vm.typedFilterText.length > 0}
            className='search'
            searchValue={vm.typedFilterText}
            onSearchChange={(e) => {
              vm.setQuickFilter(e.target.value)
            }}
          />
        </Grid>
      </Grid>
    )
  }

  const renderManageVisualizations = () => {
    if (!listVM.canManageVis) return
    return (
      <Button
        variant='outlined'
        className='dashboard-action btn-icon btn-icon-white end-action'
        onClick={() => rootStore.appStore.router.push('/dashboard/worksheets')}
        startIcon={<EditRoundedIcon />}
      >
        {rootStore.localizationStore.lzStrings.visualizations.manage_visualizations}
      </Button>
    )
  }

  const renderToggleFilter = () => {
    return (
      <div id='ToggleFilter'>
        <Tooltip
          title={rootStore.localizationStore.lzStrings.visualizations.bookmarks_filter}
          placement='bottom'
        >
          <span>
            <ToggleButton
              className={'toggle-filter'}
              value='check'
              selected={vm.bookmarkFilterOn}
              onChange={() => {
                vm.setBookmarkFilter(!vm.bookmarkFilterOn)
              }}
            >
              <BookmarkIcon />
            </ToggleButton>
          </span>
        </Tooltip>
      </div>
    )
  }

  return (
    <div id='DashboardToolbar' className={'dashboardToolbar-header'}>
      <CssBaseline />
      <AppBar
        className={clsx('dashboard-appbar', {
          'no-transition': isIE11,
        })}
        elevation={0}
      >
        <Toolbar className={'dashboard-toolbar'}>
          <Typography className='dashboard-title'>
            {rootStore.localizationStore.lzStrings.visualizations.analysis}
          </Typography>
          <div className='dashboard-toolbar-right end-content'>
            {renderSearchbar()}
            {vm.isBookmarkEnabled ? renderToggleFilter() : undefined}
            {vm.canManage ? (
              <Tooltip
                title={
                  rootStore.localizationStore.lzStrings.visualizations.manage_visualizations_tooltip
                }
                placement='bottom'
              >
                <span>{renderManageVisualizations()}</span>
              </Tooltip>
            ) : undefined}
            <div
              hidden={!vm.isShowAllEnabled || (vm.contentItemVMs && vm.contentItemVMs.length < 1)}
            >
              <Button
                className='mr-2'
                variant='contained'
                onMouseUp={() => {
                  vm.setItemsVisible(
                    !vm.isShowingAll ? listVM.currentUserWorksheets.length : vm.minItemsVisible
                  )
                  vm.setShowAll(!vm.isShowingAll)
                }}
              >
                {vm.isShowingAll
                  ? rootStore.localizationStore.lzStrings.visualizations.show_less
                  : rootStore.localizationStore.lzStrings.visualizations.show_all}
              </Button>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default inject('worksheetsStore')(observer(VisualizationsTopToolbar))
