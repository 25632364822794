import React from 'react'
import { Typography, Grid } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { UserTrainingPlansStore } from '../../store/UserTrainingPlansStore'
import TimelineRow from './TimelineRow'
import './Timeline.scss'

interface Props {
  userTrainingPlansStore?: UserTrainingPlansStore
  localizationStore?: LocalizationStore
}

const Timeline: React.FC<Props> = ({ userTrainingPlansStore, localizationStore }) => {
  const { myTrainingPlansWidgetVM: vm } = userTrainingPlansStore
  const { training_plans: strings } = localizationStore.lzStrings
  if (!vm) return null
  if (!vm.showTimeline) return
  if (vm.tabIndex === 1) return

  const renderTimelineRows = () => {
    if (vm.subtabIndex === 1) return
    return (
      <>
        {vm.timelineRows.map((row, index) => {
          return <TimelineRow key={`timeline-active-row-${index}`} row={row} index={index} />
        })}
      </>
    )
  }

  const renderUpcomingHeader = () => {
    if (vm.subtabIndex === 1) return
    return (
      <>
        <Grid item xs={12}>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            className='upcoming-header'
          >
            <Typography className='header-title'>{`Upcoming(${vm.upcomingCount})`}</Typography>
            <Typography className='header-title'>{vm.upcomingDueIn}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={1}>
          <Grid container direction='column' className='marginContainer'></Grid>
        </Grid>
        <Grid item xs={11}>
          <Grid container direction='column' className='margin'></Grid>
        </Grid>
      </>
    )
  }

  const renderUpcomingRows = () => {
    if (vm.subtabIndex === 1) return
    return (
      <>
        {vm.upcomingRows.map((row, index) => {
          return <TimelineRow key={`timeline-upcoming-row-${index}`} row={row} index={index} />
        })}
      </>
    )
  }

  return (
    <Grid container wrap='wrap'>
      {renderTimelineRows()}
      {renderUpcomingHeader()}
      {renderUpcomingRows()}
    </Grid>
  )
}

export default inject('userTrainingPlansStore', 'localizationStore')(observer(Timeline))
