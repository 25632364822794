import React from 'react'
import { inject, observer } from 'mobx-react'
import { NotificationsStore } from '../../notifications/store/NotificationsStore'
import LocalizationStore from '../../localization/LocalizationStore'
import { Grid, InputLabel } from '@material-ui/core'

interface DrawerNotificationInfoProps {
  localizationStore?: LocalizationStore
  notificationsStore?: NotificationsStore
}

const DrawerNotificationInfo: React.FC<DrawerNotificationInfoProps> = ({
  localizationStore,
  notificationsStore,
}) => {
  const { lzStrings } = localizationStore
  const { editVM: widgetForm } = notificationsStore

  return (
    <section>
      <h6>{lzStrings.notificationsWidgetDrawer.notification_details}</h6>
      <Grid container direction='row' justifyContent='space-between' className='drawer-info'>
        <Grid item xs={6}>
          <InputLabel>{lzStrings.notificationsWidgetDrawer.notification_type}</InputLabel>
          <p>{widgetForm.notificationTypeId}</p>
        </Grid>
        <Grid item xs={3}>
          <InputLabel>{lzStrings.notificationsWidgetDrawer.notification_channel}</InputLabel>
          <p>{widgetForm.channel}</p>
        </Grid>
        <Grid item xs={3}>
          <InputLabel>{lzStrings.notificationsWidgetDrawer.notification_language}</InputLabel>
          <p>{widgetForm.language}</p>
        </Grid>
      </Grid>
    </section>
  )
}

export default inject('localizationStore', 'notificationsStore')(observer(DrawerNotificationInfo))
