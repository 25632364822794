import { DataStore } from '../../shared/data/DataStore'
import { ILocationDTO } from '../dtos/ILocationDTO'
import Location from '../aggregate/Location'
import { RootStore } from '../..//stores/RootStore'
import { PagedDataVM } from '../../shared/view-models/PagedDataVM'
import { action, computed, observable } from 'mobx'
import LocationEditVM from '../view-models/LocationEditVM'

export class LocationsStore extends DataStore<Location, ILocationDTO> {
  pagedData: PagedDataVM

  constructor(rootStore: RootStore) {
    super(rootStore, Location, 'locations', ['organizationId', 'name', 'lng', 'lat'])
    this.pagedData = new PagedDataVM(
      this.rootStore,
      this.locations,
      ['name', 'publishedByUserName'],
      '',
      'CalendarManagementWidget'
    )
  }

  @observable public selectedLocationId: string
  @observable public editForm: LocationEditVM = null
  @observable public showDrawer: boolean = false
  @observable public showDeleteDialog: boolean = false
  @observable public loaded: boolean = false

  @computed
  public get locations() {
    return this.records
  }

  @action
  public onListRecordsLoaded() {
    this.loaded = true
  }

  @computed
  public get currentOrgLocations() {
    return this.locations
  }

  public setSelectedLocationId(id) {
    this.selectedLocationId = id
    this.editForm = new LocationEditVM(this.rootStore, this.getLocation(id))
  }

  @action
  public setAddNewLocation() {
    this.selectedLocationId = null
    this.editForm = new LocationEditVM(this.rootStore)
  }

  public getLocation(objectId): Location {
    return this.locations.find((l) => l.objectId === objectId)
  }

  public getLocationByName(name): Location {
    return this.locations.find((l) => l.name === name)
  }

  private getLocationIndex(objectId): number {
    return this.locations.findIndex((l) => l.objectId === objectId)
  }

  public deleteLocation(objectId) {
    this.locations.splice(this.getLocationIndex(objectId), 1)
  }

  public clearForms() {
    this.selectedLocationId = ''
    this.editForm = null
  }
}
