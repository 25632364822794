import React from 'react'
import { Dialog, DialogTitle, IconButton, Grid, DialogContent, Button } from '@material-ui/core'
import { EventsStore } from '../../../store/EventsStore'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { observer, inject } from 'mobx-react'
import CloseIcon from '@material-ui/icons/Close'

interface Props {
  eventsStore?: EventsStore
  localizationStore?: LocalizationStore
}

const SeriesGoingPrompt: React.FC<Props> = ({ eventsStore }) => {
  const { userEventPopupVM: vm } = eventsStore
  if (!vm) return null
  
  return (
    <Dialog onClose={() => vm.hideSeriesGoingPrompt()} open={vm.seriesGoingPromptShown} fullWidth>
      <Grid
        id='modalHeader'
        className='dialog-title'
        container
        spacing={3}
        justifyContent='space-between'
        alignItems='center'
      >
        <Grid item>
          <DialogTitle>RSVP to Event Series</DialogTitle>
        </Grid>
        <Grid item>
          <IconButton onClick={() => vm.hideSeriesGoingPrompt()}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <DialogContent id='modalBody' style={{ textAlign: 'center', padding: '50px' }}>
        <Button variant='outlined' onClick={() => vm.setGoingStatusOneTime()}>
          This Event
        </Button>
        <Button variant='outlined' onClick={() => vm.setGoingStatusFuture()}>
          This and folowing events
        </Button>
        {/* <Button variant='outlined' onClick={() => vm.setGoingStatusModifier('all')}>
          All e
        </Button> */}
      </DialogContent>
    </Dialog>
  )
}

export default inject('eventsStore')(observer(SeriesGoingPrompt))
