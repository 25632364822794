import { action, computed, observable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import PulseCategory from '../aggregate/PulseCategory'
import { IPulseCategoryDTO } from '../dtos/IPulseCategoryDTO'
import { IPulseCategoriesFindRequest } from '../interfaces/IPulseCategoriesFindRequest'
import { PulseCategoriesDataStore } from './PulseCategoriesDataStore'

export class PulseCategorySelectVM {
  private rootStore: RootStore
  private dataStore: PulseCategoriesDataStore

  constructor(
    rootStore: RootStore,
    grandCategoryId: string,
    parentCategoryId: string,
    selectedCategoryId: string
  ) {
    this.rootStore = rootStore
    this.grandCategoryId = grandCategoryId
    this.parentCategoryId = parentCategoryId
    this.dataStore = new PulseCategoriesDataStore(this.rootStore, {
      grandCategoryId: this.grandCategoryId,
      parentCategoryId: this.parentCategoryId,
    } as IPulseCategoriesFindRequest)
    this.loadSelectedCategory(selectedCategoryId)
    this.refreshStore()
  }

  @observable public grandCategoryId: string = ''
  @observable public parentCategoryId: string = ''
  @observable public selectedCategoryId: string = ''

  @computed
  public get categories(): PulseCategory[] {
    return this.dataStore.rows
  }

  @action
  public refreshStore() {
    this.dataStore.isLoaded = false
    this.dataStore.setRequest({
      grandCategoryId: this.grandCategoryId,
      parentCategoryId: this.parentCategoryId,
    })
    this.dataStore.loadListRecords()
  }

  @action
  public loadSelectedCategory(selectedCategoryId: string, attempts?: number) {
    if (attempts === 14) return
    if (!this.dataStore.isLoaded)
      return setTimeout(() => this.loadSelectedCategory(selectedCategoryId, ++attempts), 500)
    this.setSelectedCategoryId(selectedCategoryId)
  }

  @action
  public setSelectedCategoryId(value) {
    this.selectedCategoryId = value
  }

  @action
  public setGrandCategoryId(value) {
    this.grandCategoryId = value
  }

  @action
  public setParentCategoryId(value) {
    this.parentCategoryId = value
  }
}
