import { observable } from 'mobx'
import { persist } from 'mobx-persist'
import { CloudinaryResult } from './CloudinaryResult'
import { ParseFileResult } from './ParseFileResult'
import uuid from 'uuid/v4'
import { FileUploadService } from '../services/FileUploadService'
import { MediaItemType } from '../types/MediaItemType'
import { serializable } from 'serializr'
import { IParseFileSaveResult } from '../interfaces/IParseFileSaveResult'
import { CMSItemVM } from '../../cms-items/view-models/CMSItemVM'

export class Attachment {
  static createFromCloudinaryResult(e: CloudinaryResult) {
    const attachment = new Attachment()
    attachment.objectId = e.asset_id
    attachment.fileName = e.original_filename
    attachment.thumbnail = e.thumbnail
    attachment.url = e.secure_url
    attachment.format = e.format
    attachment.type = e.resource_type
    attachment.bytes = e.bytes
    return attachment
  }

  static createFromParseFile(e: IParseFileSaveResult) {
    const attachment = new Attachment()
    attachment.objectId = uuid()
    attachment.fileName = e.fileName
    attachment.url = e.attachment._url
    // attachment.format = e.format
    attachment.type = e.type as MediaItemType
    return attachment
  }

  @serializable @observable objectId: string = ''
  @serializable @observable fileName: string = ''
  @serializable @observable thumbnail: string = ''
  @serializable @observable url: string = ''
  @serializable @observable format: string = ''
  @serializable @observable bytes: number = 0
  @serializable @observable type: MediaItemType = null
  @serializable @observable cmsItemId: number = undefined
  @serializable @observable unsignedUrl: string = undefined
}
