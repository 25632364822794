import React from 'react'
import { inject, observer } from 'mobx-react'
import { UserTasksStore } from '../../store/UserTasksStore'
import './PendingUserTasksWidget.scss'
import { AgGridReact } from 'ag-grid-react/lib/agGridReact'
import PendingUserTaskWidgetRowVM from '../../view-models/PendingUserTaskWidgetRowVM'
import AGGridWidgetPagingRow from '../../../shared/ag-grid/AGGridPagingRow'
import AGGridUserCell from '../../../shared/ag-grid/AGGridUserCell'

interface IWidgetPendingUserTasksTable {
  userTasksStore?: UserTasksStore
}

const WidgetPendingUserTasksTable: React.FC<IWidgetPendingUserTasksTable> = ({
  userTasksStore,
}) => {
  const vm = userTasksStore.pendingUserTasksWidgetVM
  if (!vm) return null
  if (!vm.shouldRender) return null

  const renderOwnerCell = (props) => {
    const row: PendingUserTaskWidgetRowVM = props.data
    if (!row.assignedByUser) return null
    return <AGGridUserCell userId={row.assignedByUserId} name={row.assignedByUserName} iconURL={row.assignedByUserIconURL} />
  }

  const renderGrid = () => {
    return (
      <div className='ag-theme-alpine' style={{ height: 'calc(100% - 42px)', width: '100%' }}>
        <AgGridReact
          gridOptions={{
            ...vm.getGridOptions(),
            frameworkComponents: {
              creatorCell: renderOwnerCell,
            },
          }}
        />
      </div>
    )
  }

  return (
    <>
      {renderGrid()}
      <AGGridWidgetPagingRow vm={vm} />
    </>
  )
}

export default inject('userTasksStore')(observer(WidgetPendingUserTasksTable))
