import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { EventsStore } from '../../store/EventsStore'
import { Box, CardContent, Tab, Tabs, Typography } from '@material-ui/core'
import CalendarEventDrawerDetailsTab from './CalendarEventDrawerDetailsTab'
import CalendarEventDrawerLocationsTab from './CalendarEventDrawerLocationsTab'
import CalendarEventDrawerAlarmsTab from './CalendarEventDrawerAlarmsTab'
import clsx from 'clsx'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`create-events-tab-panel-${index}`}
      aria-labelledby={`create-events-tab-panel-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </Typography>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `create-events-tab-panel-${index}`,
  }
}

interface ICalendarEventDrawerBody {
  localizationStore?: LocalizationStore
  eventsStore?: EventsStore
}

const CalendarEventDrawerBody: React.FC<ICalendarEventDrawerBody> = ({
  localizationStore,
  eventsStore,
}) => {
  const { lzStrings } = localizationStore
  const { editVM: vm } = eventsStore

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    vm.setTabIndex(newValue)
  }

  return (
    <>
      <Tabs value={vm.tabIndex} onChange={handleChange} variant='fullWidth'>
        <Tab
          label={lzStrings.calendarEventDrawer.details_and_invitees}
          {...a11yProps(0)}
          className={clsx('details-and-invitees-tab', {
            'tab-error': !vm.detailsTabValid,
          })}
        />
        <Tab
          label={lzStrings.calendarEventDrawer.location_and_resources}
          {...a11yProps(1)}
          className='locations-and-resources-tab'
        />
        <Tab
          label={lzStrings.calendarEventDrawer.alarms_and_notes}
          {...a11yProps(2)}
          className='alarms-and-notes-tab'
        />
      </Tabs>
      <CardContent className='calendar-event-drawer-body-card-content'>
        <TabPanel value={vm.tabIndex} index={0}>
          <CalendarEventDrawerDetailsTab />
        </TabPanel>
        <TabPanel value={vm.tabIndex} index={1}>
          <CalendarEventDrawerLocationsTab />
        </TabPanel>
        <TabPanel value={vm.tabIndex} index={2}>
          <CalendarEventDrawerAlarmsTab />
        </TabPanel>
      </CardContent>
    </>
  )
}

export default inject('localizationStore', 'eventsStore')(observer(CalendarEventDrawerBody))
