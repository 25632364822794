import React from 'react'
import clsx from 'clsx'
import { inject, observer } from 'mobx-react'
import { ButtonBase, Tooltip, Typography } from '@material-ui/core'
import LocalizationStore from '../../localization/LocalizationStore'
import { LabelsStore } from '../../labels/store/LabelsStore'
import { NotificationsStore } from '../store/NotificationsStore'
import './NotificationTemplatesEditorToggle.scss'

interface Props {
  notificationsStore?: NotificationsStore
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
}

const NotificationTemplatesEditorToggle: React.FC<Props> = ({ notificationsStore, localizationStore, labelsStore }) => {
  const { notificationsWidget: lz } = localizationStore.lzStrings
  const { editVM: vm } = notificationsStore
  if (!vm) return

  const renderWYSIWYGEditor = () => {
    return (
      <Tooltip title={lz.text_editor_tooltip} placement='top'>
        <span>
          <ButtonBase
            disabled={vm.isSaving}
            onClick={() => vm.setEditorTabIndex(0)}
            className={clsx('tab-button', {
              active: vm.editorTabIndex === 0,
            })}
          >
            <Typography className='tab-button-label'>{lz.text}</Typography>
          </ButtonBase>
        </span>
      </Tooltip>
    )
  }

  const renderHTMLEditor = () => {
    return (
      <Tooltip title={lz.html_editor_tooltip} placement='top'>
        <span>
          <ButtonBase
            disabled={vm.isSaving}
            onClick={() => vm.setEditorTabIndex(1)}
            className={clsx('tab-button', {
              active: vm.editorTabIndex === 1,
            })}
          >
            <Typography className='tab-button-label'>{lz.html}</Typography>
          </ButtonBase>
        </span>
      </Tooltip>
    )
  }

  return (
    <div id='NotificationTemplateEditorToggle'>
      <div className='tab-btns'>
        {renderWYSIWYGEditor()}
        {renderHTMLEditor()}
      </div>
    </div>
  )
}

export default inject('notificationsStore', 'localizationStore', 'labelsStore')(observer(NotificationTemplatesEditorToggle))
