import { action, observable, computed } from 'mobx'
import Parse from 'parse'
import { IWeightProfileDTO } from '../dtos/IWeightProfileDTO'
import { serializable, serialize, deserialize, list, object } from 'serializr'
import { Category } from './Category'
import { IAggregate } from '../../shared/data/IAggregate'

export class WeightProfile implements IWeightProfileDTO, IAggregate {
  static create(orgId: string, roleId: string = '') {
    const profile = new WeightProfile()
    profile.organizationId = orgId
    profile.roleId = roleId
    return profile
  }

  @serializable @observable objectId: string = ''
  @serializable @observable roleId: string = ''
  @serializable @observable organizationId: string = ''
  @serializable @observable isDeleted: boolean = false
  @serializable(list(object(Category))) @observable categories: Array<Category> = []

  isOnServer: boolean = false

  @computed
  public get isNew() {
    return Boolean(this.objectId === '')
  }

  @action
  public setRoleId(id) {
    this.roleId = id
  }

  @action
  public markIsNotOnServer() {
    this.isOnServer = false
  }

  @action
  public markIsOnServer() {
    this.isOnServer = true
  }

  public serialize(): IWeightProfileDTO {
    return serialize(this)
  }

  public clone(): WeightProfile {
    return deserialize(WeightProfile, this.serialize())
  }
}
