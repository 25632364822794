import React from 'react'
import { inject, observer, useLocalStore } from 'mobx-react'
import rootStore from '../../stores/RootStore'
import { Card, Grid, Typography } from '@material-ui/core'
import { PulseQuestionVM } from '../../pulse-questions/view-models/PulseQuestionVM'

interface Props {
  vm: PulseQuestionVM
}

const PulseQuestion: React.FC<Props> = ({ vm }) => {
  if (!vm) return null

  const renderCard = () => {
    return (
      <Card
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          vm.editQuestion()
        }}
        className={'question-card'}
      >
        <div className={'question-header'}>
          <Typography className='question-type'>{vm.questionTypeTitle}</Typography>
          <Typography className='question-pulse-sets'>{vm.pulseSetNames}</Typography>
        </div>
        <Typography className='question-title'>{vm.title}</Typography>
        {renderContainsFollowUps()}
      </Card>
    )
  }

  const renderContainsFollowUps = () => {
    if (!vm.hasFollowUp) return null
    return (
      <div className={'prompts'}>{'This question contains follow ups.'}</div>
    )
  }

  return (
    <>
      {renderCard()}
    </>
  )
}

export default inject()(observer(PulseQuestion))
