import React from 'react'
import { inject, observer, useLocalStore } from 'mobx-react'
import { Button, Card, Dialog, DialogTitle, Grid, IconButton, Typography } from '@material-ui/core'
import './CampaignDetails.scss'
import CloseIcon from '@material-ui/icons/Close'
import GoalsIcon from '@material-ui/icons/TrackChanges'
import EditIcon from '@material-ui/icons/Edit'
import FileCopyIcon from '@material-ui/icons/FileCopyRounded'
import SkillsIcon from '@material-ui/icons/BarChartRounded'
import { CampaignsListVM } from '../../../view-models/dashboard/all-campaigns/CampaignsListVM'
import PolarityScale from '../../../../shared/polarity-scale/PolarityScale'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import SurveyParticipantsList from '../campaign-pulse-participants/SurveyParticipantsList'
import LinkDialog from '../campaign-pulse-participants/Dialogs/LinkDialog'
import RemindDialog from '../campaign-pulse-participants/Dialogs/RemindDialog'
import CancelDialog from '../campaign-pulse-participants/Dialogs/CancelDialog'
import SendDialog from '../campaign-pulse-participants/Dialogs/SendDialog'
import HistoryDialog from '../campaign-pulse-participants/Dialogs/HistoryDialog'
import CampaignRowTimeline from '../all-campaigns/CampaignRowTimeline'
import LocalizationStore from '../../../../localization/LocalizationStore'
import EndButtons from './EndButtons'
import CloseSurveyDialog from '../campaign-pulse-participants/Dialogs/CloseSurveyDialog'
import { PulseCampaignsStore } from 'src/app/pulse-campaigns/store/PulseCampaignsStore'
import { PulseCampaignEditVM } from 'src/app/pulse-campaigns/view-models/PulseCampaignEditVM'
import rootStore from '../../../../stores/RootStore'

interface Props {
  vm: CampaignsListVM
  localizationStore?: LocalizationStore
  pulseCampaignsStore?: PulseCampaignsStore
}

const CampaignDetails: React.FC<Props> = ({ vm, localizationStore, pulseCampaignsStore }) => {
  const detailsVM = vm.detailsVM
  if (!vm) return null
  if (!detailsVM) return null
  if (!detailsVM.campaign) {
    detailsVM.handleNoCampaign()
    return null
  }
  const lz = localizationStore.lzStrings.pulseCampaignDashboard

  // const pcVM = new PulseCampaignEditVM(rootStore, detailsVM.campaign, false)

  const renderSummary = () => {
    return (
      <Grid className='details-summary'>
        <Grid className='summary-header'>
          <GoalsIcon className='pulse-icon' />
          <Typography className='pulse-name'>{detailsVM.name}</Typography>
        </Grid>
        <Grid className='summary-info-bar'>
          <Grid item className='summary-status' xs={2}>
            <Typography className='status-header'>{lz.status}</Typography>
            <div className='status-text'>
              <div className='status-indicator'></div>
              <Typography className='status'>{detailsVM.status}</Typography>
            </div>
          </Grid>
          <Grid item className='summary-effective' xs={3}>
            <Typography className='effective-header'>{lz.effective_date}</Typography>
            <Typography className='effective-text'>{detailsVM.effectiveDate}</Typography>
          </Grid>
          <Grid item className='summary-responses' xs={3}>
            <Typography className='responses-header'>{lz.responded}</Typography>
            <Typography className={'responses-text'}>{detailsVM.responsesText}</Typography>
          </Grid>
          <Grid item className='summary-type' xs={3}>
            <Typography className='responses-header'>{lz.type}</Typography>
            <Typography className={'responses-text'}>{detailsVM.type}</Typography>
          </Grid>
          <Grid item className='summary-owner' xs={3}>
            <Typography className='responses-header'>{lz.owner}</Typography>
            <Typography className={'responses-text'}>{detailsVM.owner}</Typography>
          </Grid>
          <Grid item className='summary-polarity' xs={4}>
            <Typography className='polarity-header'>{lz.avg_polarity_score}</Typography>
            <PolarityScale
              polarity={detailsVM.polarityScore}
              showScale={true}
              fontSize={13}
              arrowColor={true}
            />
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderCampaignTimeline = () => {
    return <CampaignRowTimeline vm={detailsVM.campaignRow} />
  }

  const renderParticipantList = () => {
    return <SurveyParticipantsList campaignDetailsVM={detailsVM} />
  }

  const renderParticipants = () => {
    return (
      <Grid className='participants-cont'>
        <span className='participants-header'>
          <Grid item xs={12}>
            <Grid container>
              <Grid item className='text-cont' xs={11}>
                <Grid container>
                  <div className='participant-icon-cont'>
                    <PeopleAltIcon className='participant-icon' />
                  </div>
                  {`${lz.participants} - `}
                  <span className='active-survey-name'>{detailsVM.activeSurveyName}</span>
                </Grid>
              </Grid>
              <Grid item className='end-buttons' xs={1}>
                <EndButtons detailsVM={detailsVM} />
              </Grid>
            </Grid>
          </Grid>
        </span>
        <div className='participant-list'>{renderParticipantList()}</div>
      </Grid>
    )
  }

  const renderActions = () => {
    return (
      <Grid className='actions-cont'>
        <Button variant='outlined' onClick={(e) => detailsVM.copyPulseCampaign()}>
          <FileCopyIcon className='actions-icon' />
          {lz.duplicate}
        </Button>
        <Button variant='outlined' onClick={(e) => detailsVM.editPulseCampaign()}>
          <EditIcon className='actions-icon' />
          {lz.edit}
        </Button>
        <Button variant='contained' onClick={(e) => detailsVM.editPulseAnalysis()}>
          <SkillsIcon className='actions-icon2' />
          {lz.view_analysis}
        </Button>
      </Grid>
    )
  }

  const renderCard = () => {
    return (
      <Dialog open={vm.detailsOpen} id='CampaignDetails' maxWidth={'lg'}>
        <Grid className='dialog-cont'>
          <LinkDialog campaignDetailsVM={detailsVM} />
          <CancelDialog campaignDetailsVM={detailsVM} />
          <CloseSurveyDialog campaignDetailsVM={detailsVM} />
          <RemindDialog campaignDetailsVM={detailsVM} />
          <SendDialog campaignDetailsVM={detailsVM} />
          <HistoryDialog campaignDetailsVM={detailsVM} />
        </Grid>
        <Card className='campaign-details'>
          <DialogTitle className='header-text'>
            <Grid className='details-title'>
              <Grid>{lz.campaign_details}</Grid>
              <IconButton
                size='small'
                className='margin-right'
                onClick={() => vm.closeDetailsModal()}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </DialogTitle>
          <Grid className='campaign-content'>{renderSummary()}</Grid>
          <Grid className='campaign-timeline'>{renderCampaignTimeline()}</Grid>
          <Grid className='campaign-participants'>{renderParticipants()}</Grid>
          <Grid className='campaign-actions'>{renderActions()}</Grid>
        </Card>
      </Dialog>
    )
  }

  return <div id='DetailsCard'>{renderCard()}</div>
}

export default inject('localizationStore', 'pulseCampaignsStore')(observer(CampaignDetails))
