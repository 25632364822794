import React, { FC, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { IconButton, Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import LocalizationStore from '../localization/LocalizationStore'
import DeleteIcon from '@material-ui/icons/Delete'
import ArchiveIcon from '@material-ui/icons/Archive'
import UnarchiveIcon from '@material-ui/icons/Unarchive'
import FileCopyIcon from '@material-ui/icons/FileCopyRounded'
import SendEmailIcon from '@material-ui/icons/SendRounded'
import PasswordIcon from '@material-ui/icons/LockRounded'
import LockOpen from '@material-ui/icons/LockOpen'
import rootStore from '../stores/RootStore'
import MuiConfirmDeleteDialog from './MuiConfirmDeleteDialog'
import MuiConfirmDialog from './MuiConfirmDialog'

export interface MoreMenuProps {
  localizationStore?: LocalizationStore
  onConfirmDelete?: (e) => void
  onConfirmCopy?: () => void
  onConfirmReset?: () => void
  onConfirmArchive?: () => void
  onConfirmSendPasswordReset?: () => void
  onConfirmSetNewPassword?: () => void
  onConfirmArchiveAll?: () => void
  onUnlockAccount?: () => void
  onConfirmSendWelcomeEmail?: () => void
  onConfirmExpirePassword?: () => void
  itemType?: string
  itemName?: string
  archiveButtonLabel?: string
  renderAdditionalMenuItems?: (close: () => void) => any
}

const MoreMenu: FC<MoreMenuProps> = ({
  localizationStore,
  onConfirmDelete,
  itemType,
  itemName,
  onConfirmCopy,
  onConfirmReset,
  onConfirmArchive,
  onConfirmSendPasswordReset,
  onConfirmSetNewPassword,
  onConfirmArchiveAll,
  onUnlockAccount,
  onConfirmSendWelcomeEmail,
  onConfirmExpirePassword,
  archiveButtonLabel,
  renderAdditionalMenuItems: renderAdditionalMenuItems,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false)
  const [isConfirmArchiveOpen, setIsConfirmArchiveOpen] = useState(false)
  const [isConfirmArchiveAllOpen, setIsConfirmArchiveAllOpen] = useState(false)
  const [isConfirmSendWelcomeEmailOpen, setConfirmSendWelcomeEmailOpen] = useState(false)
  const [isConfirmExpirePasswordOpen, setConfirmExpirePasswordOpen] = useState(false)
  const { lzStrings } = localizationStore

  const menuItems = []

  const close = () => {
    setAnchorEl(null)
  }

  const deleteMenuItem = (
    <MenuItem
      key='delete-menu-item'
      onClick={() => {
        setAnchorEl(null)
        setIsConfirmDeleteOpen(true)
      }}
    >
      <ListItemIcon className='red'>
        <DeleteIcon />
      </ListItemIcon>
      <ListItemText primary={lzStrings.buttonLabels.delete + ' ' + itemType} />
    </MenuItem>
  )

  const archiveMenuItem = (
    <MenuItem
      key='archive-menu-item'
      onClick={() => {
        setAnchorEl(null)
        setIsConfirmArchiveOpen(true)
      }}
    >
      <ListItemIcon className={archiveButtonLabel === 'Archive' ? 'blue' : 'green'}>
        {archiveButtonLabel === 'Archive' ? <ArchiveIcon /> : <UnarchiveIcon />}
      </ListItemIcon>
      <ListItemText primary={archiveButtonLabel + ' ' + itemType} />
    </MenuItem>
  )

  const archiveAllMenuItem = (
    <MenuItem
      key='archive-all-menu-item'
      onClick={() => {
        setAnchorEl(null)
        setIsConfirmArchiveAllOpen(true)
      }}
    >
      <ListItemIcon className={'blue'}>
        <ArchiveIcon />
      </ListItemIcon>
      <ListItemText primary={archiveButtonLabel + ' ' + itemType} />
    </MenuItem>
  )

  const copyMenuItem = (
    <MenuItem
      key='copy-menu-item'
      onClick={() => {
        setAnchorEl(null)
        onConfirmCopy()
      }}
    >
      <ListItemIcon className='green'>
        <FileCopyIcon />
      </ListItemIcon>
      <ListItemText primary={lzStrings.buttonLabels.copy + ' ' + itemType} />
    </MenuItem>
  )

  const sendPasswordResetMenuItem = (
    <MenuItem
      key='send-password-reset-menu-item'
      onClick={() => {
        setAnchorEl(null)
        onConfirmSendPasswordReset()
      }}
    >
      <ListItemIcon className='green'>
        <SendEmailIcon />
      </ListItemIcon>
      <ListItemText primary={lzStrings.buttonLabels.send_password_reset + ' to ' + itemType} />
    </MenuItem>
  )

  const unlockAccountMenuItem = (
    <MenuItem
      key='unlock-account-menu-item'
      onClick={() => {
        setAnchorEl(null)
        onUnlockAccount()
      }}
    >
      <LockOpen />
      <ListItemIcon className='blue'></ListItemIcon>
      <ListItemText primary={lzStrings.buttonLabels.unlock_account} />
    </MenuItem>
  )

  const setNewPasswordMenuItem = (
    <MenuItem
      key='set-password-menu-item'
      onClick={() => {
        setAnchorEl(null)
        onConfirmSetNewPassword()
      }}
    >
      <ListItemIcon className='blue'>
        <PasswordIcon />
      </ListItemIcon>
      <ListItemText primary={lzStrings.buttonLabels.set_new_password + ' for ' + itemType} />
    </MenuItem>
  )

  const setSendWelcomeEmailMenuItem = (
    <MenuItem
      key='send-welcome-email-menu-item'
      onClick={() => {
        setAnchorEl(null)
        setConfirmSendWelcomeEmailOpen(true)
      }}
    >
      <ListItemIcon className='green'>
        <SendEmailIcon />
      </ListItemIcon>
      <ListItemText primary={'Send welcome email to ' + itemType} />
    </MenuItem>
  )

  const expirePasswordMenuItem = (
    <MenuItem
      key='expire-password-menu-item'
      onClick={() => {
        setAnchorEl(null)
        setConfirmExpirePasswordOpen(true)
      }}
    >
      <ListItemIcon className='red'>
        <PasswordIcon />
      </ListItemIcon>
      <ListItemText primary={'Expire Password'} />
    </MenuItem>
  )

  const isSystemAdmin = () => {
    if (!rootStore) return false
    if (!rootStore.userStore) return false
    if (!rootStore.userStore.user) return false
    return rootStore.appStore.isSystemAdmin
  }

  if (isSystemAdmin() && onConfirmSetNewPassword) menuItems.push(setNewPasswordMenuItem)
  if (onConfirmSendPasswordReset) menuItems.push(sendPasswordResetMenuItem)
  if (isSystemAdmin() && onConfirmSetNewPassword) menuItems.push(expirePasswordMenuItem)
  if (onConfirmCopy) menuItems.push(copyMenuItem)
  if (onConfirmArchiveAll) menuItems.push(archiveAllMenuItem)
  else if (onConfirmArchive) menuItems.push(archiveMenuItem)

  if (onConfirmDelete) menuItems.push(deleteMenuItem)

  if (renderAdditionalMenuItems) {
    const additionalMenuItems = renderAdditionalMenuItems(close)
    if (additionalMenuItems) additionalMenuItems.forEach((e) => menuItems.push(e))
  }
  if (isSystemAdmin() && onUnlockAccount) menuItems.push(unlockAccountMenuItem)
  if (isSystemAdmin() && onConfirmSendWelcomeEmail) menuItems.push(setSendWelcomeEmailMenuItem)

  return (
    <div>
      <MuiConfirmDeleteDialog
        onClose={() => setIsConfirmDeleteOpen(false)}
        open={isConfirmDeleteOpen}
        itemName={itemName}
        itemType={itemType}
        onConfirm={(e) => {
          onConfirmDelete(e)
          setIsConfirmDeleteOpen(false)
          setAnchorEl(null)
        }}
      />

      <MuiConfirmDialog
        onClose={() => setIsConfirmArchiveOpen(false)}
        open={isConfirmArchiveOpen}
        confirmMessage={`Are you sure you want to ${
          archiveButtonLabel === 'Archive' ? 'archive' : 'restore (unarchive)'
        } this user?`}
        onConfirm={() => {
          onConfirmArchive()
          setIsConfirmArchiveOpen(false)
          setAnchorEl(null)
        }}
      />

      <MuiConfirmDialog
        onClose={() => {
          setIsConfirmArchiveAllOpen(false)
          setIsConfirmArchiveOpen(true)
        }}
        open={isConfirmArchiveAllOpen}
        confirmMessage={`Are you sure you want to archive this user for all organizations?`}
        onConfirm={() => {
          onConfirmArchiveAll()
          setIsConfirmArchiveAllOpen(false)
          setAnchorEl(null)
        }}
      />

      <MuiConfirmDialog
        onClose={() => setConfirmSendWelcomeEmailOpen(false)}
        open={isConfirmSendWelcomeEmailOpen}
        confirmMessage={`Are you sure you want to send a Welcome Email to this user?`}
        onConfirm={() => {
          onConfirmSendWelcomeEmail()
          setConfirmSendWelcomeEmailOpen(false)
          setAnchorEl(null)
        }}
      />

      <MuiConfirmDialog
        onClose={() => setConfirmExpirePasswordOpen(false)}
        open={isConfirmExpirePasswordOpen}
        confirmMessage={`Are you sure you want to expire the password of this user?`}
        onConfirm={() => {
          onConfirmExpirePassword()
          setConfirmExpirePasswordOpen(false)
          setAnchorEl(null)
        }}
      />

      <IconButton
        id='MoreButton'
        onClick={(e) => setAnchorEl(e.currentTarget)}
        className={Boolean(anchorEl) ? 'focused' : ''}
      >
        <MoreHorizIcon />
      </IconButton>

      <Menu
        id='MoreMenu'
        elevation={2}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {menuItems}
      </Menu>
    </div>
  )
}

export default inject('localizationStore')(observer(MoreMenu))
