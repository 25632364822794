import { RootStore } from '../../../stores/RootStore'
import { action, computed, observable } from 'mobx'
import { MediaLibraryItemVM } from '../../../media-items/view-models/media-library/MediaLibraryItemVM'
import { MediaItemsService } from '../../service/MediaItemsService'
import { ICloudinaryResult } from '../../dtos/ICloudinaryResult'
import { MediaItem } from '../../aggregate/MediaItem'
import { MediaItemDrawerVM } from '../drawer/MediaItemDrawerVMv2'
import { Attachment } from '../../../upload/aggregate/Attachment'

declare global {
  interface Window {
    cloudinary: any
  }
}

export class MediaUploadPickerVM {
  private rootStore: RootStore

  constructor(
    rootStore: RootStore,
    handleMediaSelection: Function,
    addAfterSave: boolean = false,
    allowVideos: boolean = true,
    allowImages: boolean = true
  ) {
    this.rootStore = rootStore
    this.handleMediaSelection = handleMediaSelection
    this.allowVideos = allowVideos
    this.allowImages = allowImages
    this.addAfterSave = addAfterSave
  }

  @observable public showMediaUploadPicker: boolean
  @observable public showMediaDrawer: boolean
  @observable public allowVideos: boolean = true
  @observable public allowImages: boolean = true
  @observable public showVideos: boolean = true
  @observable public showImages: boolean = true
  @observable public searchValue: string = ''
  @observable public selectedMediaItemId: string
  @observable public handleMediaSelection: Function = null
  @observable public showUploadButton: boolean = false
  @observable public mediaDrawerVM: MediaItemDrawerVM = null
  @observable public addAfterSave: boolean = false
  @observable public showUploadModal: boolean = false

  @computed
  public get mediaItems(): MediaLibraryItemVM[] {
    let mediaItems = this.rootStore.mediaItemsStore.currentOrgMediaItems.map(
      (itm) => new MediaLibraryItemVM(itm)
    )
    if (!this.showImages || !this.allowImages)
      mediaItems = mediaItems.filter((itm) => itm.type !== 'image')
    if (!this.showVideos || !this.allowVideos)
      mediaItems = mediaItems.filter((itm) => itm.type !== 'video')
    if (Boolean(this.searchValue))
      mediaItems = mediaItems.filter(
        (item) =>
          typeof item.name === 'string' &&
          item.name.toLowerCase().includes(this.searchValue.toLowerCase())
      )

    return mediaItems
  }

  @action
  public setSearchValue(value: string) {
    this.searchValue = value
  }

  @action
  public toggleShowMediaUploadPicker() {
    this.showMediaUploadPicker = !this.showMediaUploadPicker
    if (!this.showMediaUploadPicker) {
      this.selectMediaItem('')
      this.closeDrawer()
    }
  }

  @action
  public toggleShowVideos() {
    this.showVideos = !this.showVideos
  }

  @action
  public toggleShowImages() {
    this.showImages = !this.showImages
  }

  @action
  public generateSignature = async (callback: Function, paramsToSign: object): Promise<void> => {
    const svc = new MediaItemsService()
    const signature = await svc.getCloudinarySignature(paramsToSign)
    callback(signature)
  }

  // @action
  // public showUploadWidget() {
  //   this.showUploadButton = false
  //   window.cloudinary.openUploadWidget(
  //     {
  //       cloudName: 'rippleworx',
  //       sources: ['local', 'image_search', 'camera', 'dropbox', 'google_drive'],
  //       clientAllowedFormats: ['jpg', 'png', 'gif', 'jpeg', 'svg', 'mp4', 'mov'],
  //       maxFiles: 1,
  //       multiple: false,
  //       showPoweredBy: false,
  //       apiKey: '914141713215552',
  //       uploadSignature: this.generateSignature,
  //     },
  //     (error, result) => {
  //       this.showUploadButton = true

  //       if (!error && result && result.event === 'success') {
  //         this.uploadMediaItem(result.info)
  //       }
  //     }
  //   )
  // }

  @action
  public openDrawer(id?: string) {
    let mediaItem: MediaItem
    if (id) {
      mediaItem = this.rootStore.mediaItemsStore.getMediaItem(id)
      this.mediaDrawerVM = new MediaItemDrawerVM(
        mediaItem.clone(),
        this.rootStore,
        this,
        this.addAfterSave
      )
    } else {
      this.mediaDrawerVM = new MediaItemDrawerVM(
        MediaItem.create(this.rootStore.appStore.currentOrgId),
        this.rootStore,
        this,
        this.addAfterSave
      )
    }
    this.showMediaDrawer = true
  }

  @action
  public toggleFileUploadModal() {
    this.showUploadModal = !this.showUploadModal
  }

  @action
  public closeDrawer() {
    this.mediaDrawerVM = null
  }

  @action
  public selectMediaItem(objectId) {
    this.selectedMediaItemId = objectId
  }

  @action
  public addMedia(attempts: number = 0) {
    if (attempts === 8) return
    const media = this.rootStore.mediaItemsStore.getMediaItem(this.selectedMediaItemId)
    if (!media) setTimeout(() => this.addMedia(++attempts), 500)
    else {
      this.handleMediaSelection(media)
      this.toggleShowMediaUploadPicker()
    }
  }

  @action
  public uploadMediaItem(attachment: Attachment) {
    const mediaItem = MediaItem.createFromAttachment(
      attachment,
      this.rootStore.appStore.currentOrgId
    )
    const svc = new MediaItemsService()
    const savedItem = svc.saveMediaItem(mediaItem)
    return savedItem
  }
}
