import React from 'react'
import { observer, inject } from 'mobx-react'
import { WorksheetsStore } from '../../worksheets/store/WorksheetsStore'
import { UserWorksheetsStore } from 'src/app/user-worksheets/store/UserWorksheetsStore'
import { ContentVM } from '../view-models/ContentVM'
import Content from './Content'
import './VisualizationsGrid.scss'

interface Props {
  worksheetsStore?: WorksheetsStore
  userWorksheetsStore?: UserWorksheetsStore
  vm: ContentVM
}

const VisualizationsGrid: React.FC<Props> = ({ worksheetsStore, userWorksheetsStore, vm }) => {
  if (!vm) return null

  return (
    <div id='VisualizationsGrid'>
      <Content worksheetsStore={worksheetsStore} userWorksheetsStore={userWorksheetsStore} contentVM={vm} />
    </div>
  )
}

export default inject('worksheetsStore')(observer(VisualizationsGrid))
