import { action, computed, observable } from 'mobx'
import Parse from 'parse'
import moment from 'moment'
import { ErrorRowVM } from '../import/ErrorRowVM'
import { RootStore } from 'src/app/stores/RootStore'
import { CustomCollectionsService } from '../../service/CustomCollectionsService'
import { CustomCollection } from '../../aggregate/CustomCollection'
import { ICustomRecordsImportResult } from '../../interfaces/ICustomRecordsImportResult'
import { CustomCollectionEditVM } from '../edit/CustomCollectionEditVM'

export class CustomRecordsImportModalVM {
  private rootStore: RootStore
  private svc: CustomCollectionsService
  private customCollection: CustomCollection
  private editVM: CustomCollectionEditVM
  public MAX_FILE_SIZE: number = 20971520

  constructor(
    rootStore: RootStore,
    editVM: CustomCollectionEditVM,
    customCollection: CustomCollection
  ) {
    this.rootStore = rootStore
    this.editVM = editVM
    this.svc = new CustomCollectionsService(this.rootStore)
    this.customCollection = customCollection
  }

  @observable public file: File = null
  @observable public importProcessing: boolean = false
  @observable public shown: boolean = false
  @observable public result: ICustomRecordsImportResult = null
  @observable public processingMsg = ''


  public handleFileDrop(file) {
    if (!file[0] || file[0].size > this.MAX_FILE_SIZE) {
      this.setResult({
        success: false,
        errorMessages: ['File is too big. Maximum size is 20MB']
      })
    } else {
      this.result = null
    }
    this.file = file[0]
    return
  }

  @computed
  public get hasError(): boolean {
    if (!this.result) {
      return false
    }
    return !this.result.success
  }

  @computed
  public get hasFile(): boolean {
    return this.file !== null
  }

  @computed
  public get validateEnabled() {
    if (this.importProcessing) return false
    if (this.file) return true
    return false
  }

  @action
  public clearImport() {
    this.result = null
    this.file = null
  }

  @action
  public toggleShown() {
    this.shown = !this.shown
    this.clearImport()
  }

  @computed
  public get errorRows(): ErrorRowVM[] {
    return this.result.errorMessages.map((e, idx) => new ErrorRowVM(this.rootStore, e, idx))
  }

  @computed
  public get processedRowsCount(): number {
    if (!this.result) return 0
    // return a processedCount
    return 0
  }

  @action
  public setResult(result) {
    this.result = result
    if (!this.result) return
    if (!this.result.success) this.importProcessing = false
  }

  @action
  public getTitle() {
    if (this.importProcessing) {
      return 'Processing...'
    } else {
      return 'Data Import'
    }
  }

  @action
  public async import() {
    const s = this.rootStore.localizationStore.lzStrings.importModal
    this.importProcessing = true
    this.processingMsg = 'Importing data...'
    this.setResult(null)
    const batchId = moment().format(s.batch_format)
    const fileName = batchId + '.csv'
    let result = {
      success: true,
      errorMessages: [],
      warningMessages: [],
    }
    const orgId = this.rootStore.appStore.currentOrgId
    const parseFile = await new Parse.File(fileName, this.file, 'text/csv')
    parseFile.setTags({ organizationId: orgId })
    await parseFile.save()
    this.processingMsg = 'validating records and saving...'
    try {
      result = await this.svc.importCustomRecordsFromCsv(
        this.rootStore.appStore.currentOrgId,
        this.customCollection.objectId,
        parseFile,
        false,
        batchId
      )
    } catch (e) {
      console.log(e)
      result.success = false
      result.errorMessages.push('Unexpected Error Occurred. Please try again')
    }

    if (result.success) {
      this.processingMsg = 'refreshing records...'
      await this.editVM.gridApi.refreshInfiniteCache()
      this.toggleShown()
      this.clearImport()
    } else {
      this.setResult(result)
      this.editVM.updateHandler()
    }
    this.importProcessing = false
  }

  @computed
  public get showDropZone(): boolean {
    if (this.processedRowsCount && !this.importProcessing && this.errorRows.length > 0) {
      return true
    }
    if (this.processedRowsCount) return false
    if (this.importProcessing) return false
    return true
  }

  @computed
  public get showSpinner(): boolean {
    if (this.processedRowsCount) return false
    if (this.importProcessing) return true
    return false
  }
}
