import React from 'react'
import { inject, observer } from 'mobx-react'
import Typography from '@material-ui/core/Typography'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import PermMediaIcon from '@material-ui/icons/PermMedia'
import FileCopyIcon from '@material-ui/icons/FileCopyRounded'
import DeleteIcon from '@material-ui/icons/Delete'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import Tooltip from '@material-ui/core/Tooltip'
import './QuestionsList.scss'
import { QuestionVM } from '../view-models/QuestionVM'
import { QuizVM } from '../view-models/QuizVM'
import Icon from './Icon'

interface Props {
  index: number
  question: QuestionVM
  quiz: QuizVM
}

const QuestionCard: React.FC<Props> = ({ index, question, quiz }) => {
  const renderEmojiOptions = (question: QuestionVM, questionIndex) => {
    if (question.type !== 'emoji') return
    const emojis = question.options.map((opt, idx) => {
      return (
        <div
          className='emoji-options'
          key={'qcopt' + idx}
          id={'question-' + questionIndex + '-option-' + idx}
        >
          <ListItemIcon>
            <img
              src={'../../../assets/emoticons/png/' + opt.emoji + '.png'}
              style={{ width: 10, height: 10 }}
              alt=''
            />
          </ListItemIcon>
          <Typography component='span'>{opt.text}</Typography>
        </div>
      )
    })
    return emojis
  }

  const renderNormalOptions = (question: QuestionVM, questionIndex) => {
    if (question.type === 'emoji' || question.type === 'text') return
    const options = question.options.map((opt, idx) => {
      return (
        <div
          className='options'
          key={'qcopt' + idx}
          id={'question-' + questionIndex + '-option-' + idx}
        >
          <span className={opt.icon}></span>
          <Typography>{opt.text}</Typography>
        </div>
      )
    })
    return options
  }

  const renderQuestionIndex = () => {
    return <>Q{index + 1}</>
  }

  return (
    <div
      key={'qcd1' + index}
      id={'question-' + index}
      className={index === quiz.currentQuestionIndex ? 'question-card active' : 'question-card'}
      onMouseDown={() => quiz.setCurrentQuestion(index)}
    >
      <div className={index === quiz.currentQuestionIndex ? 'header active-header' : 'header'}>
        <div className='question-card-header-left'>
          <ListItemIcon className={question.icon.className}>
            <Icon icon={question.icon.iconType} />
          </ListItemIcon>
          <Typography className='card-index'>{renderQuestionIndex()}</Typography>
        </div>
        <div className='question-card-header-icons'>
          <ListItemIcon
            className={'copy-icon'}
            onClick={() => {
              if (quiz.isDisabled) return
              quiz.copyQuestion(question.id)
            }}
          >
            <Tooltip title={'Copy'}>
              <FileCopyIcon />
            </Tooltip>
          </ListItemIcon>
          <ListItemIcon
            className={'delete-icon'}
            onClick={() => {
              if (quiz.isDisabled) return
              quiz.deleteQuestionByCard(index)
            }}
          >
            <Tooltip title={'Delete'}>
              <DeleteIcon />
            </Tooltip>
          </ListItemIcon>
          <ListItemIcon className='drag-icon'>
            <Tooltip title={'Drag'}>
              <DragIndicatorIcon />
            </Tooltip>
          </ListItemIcon>
        </div>
      </div>
      <div className={question.titleError ? 'title-container error' : 'title-container'}>
        <Typography className='title'>{question.title}</Typography>
      </div>
      <div className={question.optionsError ? 'error' : ''}>
        {renderEmojiOptions(question, index)}
        {renderNormalOptions(question, index)}
      </div>
    </div>
  )
}

export default inject('surveysStore', 'localizationStore')(observer(QuestionCard))
