import { observable, action } from 'mobx'
import { IAggregate } from 'src/app/shared/data/IAggregate'
import { IPrivilegeSetDTO } from '../dtos/IPrivilegeSetDTO'
import { serializable, serialize, list, primitive } from 'serializr'

export default class PrivilegeSet implements IPrivilegeSetDTO, IAggregate{
  static create(orgId) {
    const privilegeSet = new PrivilegeSet()
    privilegeSet.organizationId = orgId
    return privilegeSet
  }

  @serializable @observable objectId: string = ''
  @serializable @observable organizationId: string = ''
  @serializable @observable description: string = ''
  @serializable(list(primitive())) @observable privileges: Array<string> = []
  @serializable @observable name: string = ''
  @serializable @observable isProcessing: boolean = false
  @serializable @observable isDeleted: boolean = false
  public isOnServer: boolean = false

  markIsNotOnServer() {
    this.isOnServer = false
  }

  markIsOnServer() {
    this.isOnServer = true
  }

  @action
  markIsNotProcessing() {
    this.isProcessing = false
  }

  markIsProcessing() {
    this.isProcessing = true
  }

  public serialize(): IPrivilegeSetDTO {
    return serialize(this)
  }
}
