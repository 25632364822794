import axios from 'axios'
import { RootStore } from '../../stores/RootStore'
import * as Sentry from '@sentry/browser'
import env from '../../../env'
import { ICMSItemTag } from '../interfaces/ICMSItemTag'
import { CMSAuthService } from '../../cms-auth/services/CMSAuthService'

export class CMSItemTagsFindService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  private getCMSApiUrl(): string {
    return env.var.REACT_APP_CMS_API_URL
  }

  public async getTags(): Promise<ICMSItemTag[]> {
    try {
      const url = this.getCMSApiUrl() + '/api/item-tags?sort=Tag_Name:ASC'
      const jwt = await CMSAuthService.getJWT()
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt}`,
          Accept: '*/*',
        },
      }
      const response = await axios.get(url, config)
      if (!response.data) return []
      if (!response.data.data) return []
      return response.data.data.map((e) => {
        return {
          id: e.id,
          Tag_Name: e.Tag_Name,
          createdAt: e.createdAt,
          updatedAt: e.updatedAt,
        }
      })
    } catch (e) {
      console.error(e)
      Sentry.captureException({
        message: 'Unable to get cms tags',
        exception: e,
        userId: this.rootStore.appStore.currentUserId,
        orgId: this.rootStore.appStore.currentOrgId,
      })
    }
  }

  public async getTagIds(tags?: string[]): Promise<number[]> {
    if (!tags || tags.length < 1) return []

    const sort = `?sort=Tag_Name:ASC`
    const tagFilter =
      tags && tags.length
        ? tags.reduce(
            (acc, value, index) =>
              acc + `&filters[$and][0][$or][${index}][Tag_Name][$eq]=${value}`,
            ''
          )
        : ''

    try {
      const url = this.getCMSApiUrl() + `/api/item-tags${sort}${tagFilter}`
      const jwt = await CMSAuthService.getJWT()
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt}`,
          Accept: '*/*',
        },
      }

      const response = await axios.get(url, config)

      if (!response.data) return []
      if (!response.data.data) return []
      const tagIds = []
      response.data.data.forEach((e) => {
        tagIds.push(e.id)
      })
      return tagIds
    } catch (e) {
      console.error(e)
      Sentry.captureException({
        message: 'Unable to get cms tags ids',
        exception: e,
        userId: this.rootStore.appStore.currentUserId,
        orgId: this.rootStore.appStore.currentOrgId,
      })
    }
  }

}
