import axios from 'axios'
import { RootStore } from '../../stores/RootStore'
import * as Sentry from '@sentry/browser'
import env from '../../../env' 
import { CMSAuthService } from '../../cms-auth/services/CMSAuthService'
import { ICMSAvatarUploadRequest } from '../interfaces/ICMSAvatarUploadRequest'
import { ICMSAvatarUpload } from '../interfaces/ICMSAvatarUpload'

export class CMSAvatarUploadService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  private getCMSApiUrl(): string {
    return env.var.REACT_APP_CMS_API_URL
  }

  public async uploadAvatar(
    req: ICMSAvatarUploadRequest,
    cbAfterUpload?: Function
  ): Promise<any> {
    try {
      const jwt = await CMSAuthService.getJWT()
      if (!jwt) return false
      let response = undefined
      if (req.customFile) {
        const formData = new FormData()
        formData.append('files', req.customFile)

        response = await axios.post(`${this.getCMSApiUrl()}/api/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${jwt}`,
            Accept: '*/*',
          },
          transformRequest: (data) => {
            return data
          },
        })
      }

      let avatarPayload = {
        User_Id: req.userId,
      } as ICMSAvatarUpload

      if (req.customFile) {
        Object.assign(avatarPayload, {
            Custom_File: [response.data[0].id],
        })
      }

      response = await axios.post(
        `${this.getCMSApiUrl()}/api/avatars/upload`,
        { data: avatarPayload },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwt}`,
            Accept: '*/*',
          },
        }
      )

      if (cbAfterUpload) cbAfterUpload()

      return response
    } catch (e) {
      console.error(e)
      Sentry.captureException({
        message: 'Unable to upload cms avatar',
        exception: e,
        userId: this.rootStore.appStore.currentUserId,
        orgId: this.rootStore.appStore.currentOrgId,
      })
    }
  }
}
