import Parse from 'parse'
import { IPulseCampaignListRequest } from '../interfaces/IPulseCampaignListRequest'
import { IPulseCampaignListResponse } from '../interfaces/IPulseCampaignListResponse'
import { IPulseCampaignSaveRequest } from '../interfaces/IPulseCampaignSaveRequest'
import { IPulseCampaignSaveResponse } from '../interfaces/IPulseCampaignSaveResponse'
import { IPulseCampaignDeleteRequest } from '../interfaces/IPulseCampaignDeleteRequest'
import { IPulseCampaignDeleteResponse } from '../interfaces/IPulseCampaignDeleteResponse'
import { IPulseCampaignAnalysisRequest } from '../interfaces/IPulseCampaignAnalysisRequest'
import { IPulseCampaignAnalysisResult } from '../interfaces/IPulseCampaignAnalysisResult'
import { IPulseCampaignTemplateGlobalRequest } from '../interfaces/IPulseCampaignTemplateGlobalRequest'
import { IPulseCampaignFindResult } from '../interfaces/IPulseCampaignFindResult'
import { IPulseCampaignFindRequest } from '../interfaces/IPulseCampaignFindRequest'
import { IPulseCampaignsSAFindRequest } from '../interfaces/IPulseCampaignsSAFindRequest'
import { ICampaignTemplateFindRequest } from '../interfaces/ICampaignTemplateFindRequest'
import { ISurveyDTO } from '../../surveys/dtos/ISurveyDTO'
import { IPulseCampaignDTO } from '../dto/IPulseCampaignDTO'
import { IPulseCampaignSurveyDTO } from '../dto/IPulseCampaignSurveyDTO'
import { IPulseCampaignCloseRequest } from '../interfaces/IPulseCampaignCloseRequest'
import { IPulseCampaignCloseResponse } from '../interfaces/IPulseCampaignCloseResponse'
import { IPulseCampaignSurveyCloseRequest } from '../interfaces/IPulseCampaignSurveyCloseRequest'
import { IPulseCampaignSurveyCloseResponse } from '../interfaces/IPulseCampaignSurveyCloseResponse'
import { ISurveyTemplateDTO } from '../../survey-templates/dtos/ISurveyTemplateDTO'
import { IOrganizationUserDTO } from '../../organization-users/dtos/IOrganizationUserDTO'

export class PulseCampaignsService {
  public async getPulseCampaigns(
    request: IPulseCampaignListRequest
  ): Promise<IPulseCampaignListResponse> {
    return await Parse.Cloud.run('getPulseCampaignList', { request })
  }

  public async savePulseCampaign(
    request: IPulseCampaignSaveRequest
  ): Promise<IPulseCampaignSaveResponse> {
    return await Parse.Cloud.run('savePulseCampaign', { request })
  }

  public async deletePulseCampaign(
    request: IPulseCampaignDeleteRequest
  ): Promise<IPulseCampaignDeleteResponse> {
    return await Parse.Cloud.run('deletePulseCampaign', { request })
  }

  public async deletePulseCampaignTemplate(
    request: IPulseCampaignDeleteRequest
  ): Promise<IPulseCampaignDeleteResponse> {
    return await Parse.Cloud.run('deletePulseCampaignTemplate', { request })
  }

  public async closePulseCampaign(
    request: IPulseCampaignCloseRequest
  ): Promise<IPulseCampaignCloseResponse> {
    return await Parse.Cloud.run('closePulseCampaign', { request })
  }

  public async closePulseCampaignSurvey(
    request: IPulseCampaignSurveyCloseRequest
  ): Promise<IPulseCampaignSurveyCloseResponse> {
    return await Parse.Cloud.run('closePulseCampaignSurvey', { request })
  }

  public async savePulseCampaignWorksheet(
    request: IPulseCampaignAnalysisRequest
  ): Promise<IPulseCampaignAnalysisResult> {
    return await Parse.Cloud.run('savePulseCampaignWorksheet', { request })
  }

  public async findGlobalTemplates(
    request: IPulseCampaignTemplateGlobalRequest
  ): Promise<IPulseCampaignFindResult> {
    return await Parse.Cloud.run('getGlobalPulseCampaignTemplates', { request })
  }

  public async findPulseCampaigns(
    request: IPulseCampaignFindRequest
  ): Promise<IPulseCampaignFindResult> {
    return await Parse.Cloud.run('getPulseCampaigns', { request })
  }

  public async findPulseCampaignsSA(
    request: IPulseCampaignsSAFindRequest
  ): Promise<IPulseCampaignFindResult> {
    return await Parse.Cloud.run('findPulseCampaignsSA', { request })
  }

  public async getCampaignTemplates(
    request: ICampaignTemplateFindRequest
  ): Promise<IPulseCampaignFindResult> {
    return await Parse.Cloud.run('getCampaignTemplates', { request })
  }

  public async getConvertedCampaignSurvey(
    pulseCampaign: IPulseCampaignDTO,
    pulseSurvey: IPulseCampaignSurveyDTO
  ): Promise<ISurveyDTO> {
    return await Parse.Cloud.run('getConvertedCampaignSurvey', { pulseCampaign, pulseSurvey })
  }

  public async getConvertedSurveyFromTemplate(
    pulseCampaign: IPulseCampaignDTO,
    surveyTemplate: ISurveyTemplateDTO
  ): Promise<ISurveyDTO> {
    return await Parse.Cloud.run('getConvertedSurveyFromTemplate', {
      pulseCampaign,
      surveyTemplate,
    })
  }

  public async refreshPulseCampaignSurveyInDW(
    orgId: string,
    pulseCampaignId: string,
    surveyId: string
  ): Promise<ISurveyDTO> {
    return await Parse.Cloud.run('refreshPulseCampaignSurveyInDW', {
      orgId,
      pulseCampaignId,
      surveyId,
    })
  }

  public async sendSurveyNow(
    orgId: string,
    pulseCampaignId: string,
    pulseCampaignSurveyId: string
  ): Promise<ISurveyDTO> {
    return await Parse.Cloud.run('sendPulseCampaignSurveyNow', {
      orgId,
      pulseCampaignId,
      pulseCampaignSurveyId,
    })
  }
  
  public async getAvailablePulseCampaignOwners(organizationId): Promise<IOrganizationUserDTO[]> {
    return await Parse.Cloud.run('getAvailablePulseCampaignOwners', { organizationId })
  }
}
