import { observable, action } from 'mobx'
import { serializable, serialize, deserialize, date, list, object, primitive } from 'serializr'
import { IParticipant } from 'src/app/participants-select/interfaces/IParticipant'
import { Attachment } from '../../attachments/aggregate/Attachment'
import { IAnnouncementDTO } from '../dtos/IAnnouncementDTO'
import { Participant } from './Participant'
import { FKCreatedByUser } from './FKCreatedByUser'
import { Schedule } from './Schedule'
import { FKOwnerUser } from './FKOwnerUser'
import { AnnouncementStatusType } from '../types/AnnouncementStatusType'

export class Announcement implements IAnnouncementDTO {
  public static create(userId: string, orgId: string): Announcement {
    const announcement = new Announcement()
    announcement.createdByUserId = userId
    announcement.ownerUserId = userId
    announcement.organizationId = orgId
    return announcement
  }

  @serializable @observable public body: string = ''
  @serializable @observable public title: string = ''
  @serializable @observable public objectId: string = ''
  @serializable @observable public isDeleted: boolean = false
  @serializable @observable public organizationId: string = ''
  @serializable @observable public createdByUserId: string = ''
  @serializable(date()) @observable public createdAt: Date = undefined
  @serializable(date()) @observable public updatedAt: Date = undefined
  @serializable(list(object(Participant))) @observable public participants: Participant[] = []
  @serializable(list(object(Attachment))) @observable public attachments: Attachment[] = []
  @serializable @observable public likes: number = 0
  @serializable @observable public views: number = 0
  @serializable(object(FKCreatedByUser)) public fk_createdByUser: FKCreatedByUser = undefined
  @serializable @observable public numberOfParticipants: number = 0
  @serializable(object(FKCreatedByUser)) public fk_updatedByUser: FKCreatedByUser = undefined
  @serializable @observable public updatedByUserId: string = ''
  @serializable @observable public status: AnnouncementStatusType
  @serializable(object(Schedule)) @observable public schedule: Schedule = new Schedule()
  @serializable @observable public ownerUserId: string = ''
  @serializable(object(FKOwnerUser)) public fk_ownerUser: FKOwnerUser = undefined
  @serializable @observable public showAnnouncementOwner: boolean = true

  isOnServer: boolean = false

  @action
  public setTitle(val: string) {
    this.title = val
  }

  @action
  public setBody(val: string) {
    this.body = val
  }

  @action
  public addParticipants(participants: IParticipant[]) {
    this.participants = participants as Participant[]
  }

  @action
  public addAttachment(attachment: Attachment) {
    this.attachments.push(attachment)
  }

  @action
  public clearAttachments() {
    this.attachments = []
  }

  @action
  public removeAttachmentByIndex(index: number) {
    if (index < 0) return
    this.attachments.splice(index, 1)
  }

  @action
  public removeAttachmentByObjectId(objectId: string) {
    this.attachments = this.attachments.filter((e) => e.objectId !== objectId)
  }

  @action
  public setDeliveryDateTime(val: Date) {
    this.schedule.setDeliveryDateTime(val)
  }

  @action
  public setDeliveryTimeZone(val) {
    this.schedule.setDeliveryTimeZone(val)
  }

  @action
  public toggleScheduleEnabled() {
    this.schedule.toggleIsEnabled()
  }

  @action
  public toggleShowAnnouncementOwner() {
    this.showAnnouncementOwner = !this.showAnnouncementOwner
  }

  @action
  public setOwnerId(val) {
    this.ownerUserId = val
  }

  @action
  public setStatus(status: AnnouncementStatusType) {
    this.status = status
  }

  @action
  public markIsOnServer() {
    this.isOnServer = true
  }

  @action
  public markIsNotOnServer() {
    this.isOnServer = true
  }

  public serialize() {
    const obj = serialize(this)
    return obj
  }

  public clone(): Announcement {
    return deserialize(Announcement, this.serialize())
  }
}
