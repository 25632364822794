import { RootStore } from '../RootStore'
import Parse from 'parse'
import { observable, action, computed } from 'mobx'
import Privilege from './aggregate/Privilege'
import { DataStore } from '../../shared/data/DataStore'
import { IPrivilegeDTO } from './dtos/IPrivilegeDTO'
import { deserialize } from 'serializr'

export class PrivilegesStore extends DataStore<Privilege, IPrivilegeDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, Privilege, 'privileges', ['name', 'shortDescription', 'longDescription'])
  }

  @observable public loaded: boolean = false

  protected getBaseQuery() {
    const query = new Parse.Query(this.className)
    return query
  }
  
  @computed
  public get privileges(): Privilege[] {
    return this.records
  }

  @action
  public onListRecordsLoaded() {
    this.loaded = true
  }

  @computed
  public get currentPrivileges(): Array<Privilege> {
    return this.privileges
  }

  public getPrivilege(objectId): Privilege {
    return this.privileges.find(e => e.objectId === objectId)
  }

  @action
  public addPrivilege(privilege: Privilege) {
    this.privileges.push(privilege)
  }

  public deletePrivilege(objectId) {
    this.privileges.splice(this.getPrivilegeIndex(objectId), 1)
  }

  private getPrivilegeIndex(objectId): number {
    return this.privileges.findIndex(e => e.objectId === objectId)
  }

}
