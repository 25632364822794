import { observable, computed, action, reaction } from 'mobx'
import moment, { Moment } from 'moment'

export class TimePickerVM {
  private onChange: Function

  constructor(onChange: Function, selectedTime: Date, beginningOfDay: Date, endOfDay: Date) {
    if (onChange) this.onChange = onChange
    this.displayFormat = 'h:mm A'
    this.selectedTime = moment(selectedTime)
    this.inputText = this.selectedTime.format(this.displayFormat)
  }

  @observable public menuOpen: boolean = false
  @observable public inputTextWasChanged: boolean = false
  @observable public isInputTextValid: boolean = true
  @observable public inputText: string = null
  @observable public selectedTime: Moment = null
  @observable public error: boolean = false
  @observable public displayFormat: string

  @computed
  public get hour(): number {
    return this.selectedTime.hour()
  }

  @computed
  public get minute(): number {
    return this.selectedTime.minute()
  }

  @computed
  public get timeOptionsToDisplay(): Array<Moment> {
    const options = []
    const roundedUp = Math.ceil(moment().minute() / 15) * 15
    for (let minutes = 0; minutes < 24 * 60; minutes += 15) {
      options.push(moment().minute(roundedUp).second(0).add(60, 'minutes').add(minutes, 'minutes'))
    }
    return options
  }

  @action
  public handleInputFocus() {
    this.openMenu()
  }

  @action
  public openMenu() {
    this.menuOpen = true
  }

  @action
  public closeMenu() {
    this.menuOpen = false
  }

  @action
  public setInputText(text) {
    if (!this.inputTextWasChanged) this.inputTextWasChanged = true
    if (!this.isInputTextValid) this.isInputTextValid = true
    this.inputText = text
  }

  @action
  public setSelectedTime(m: Moment) {
    if (m.isSame(this.selectedTime)) return
    this.selectedTime = m
    this.setInputText(m.format(this.displayFormat))
    this.closeMenu()
    if (this.onChange) this.onChange(this.hour, this.minute)
  }

  @action
  public setIsInputTextValid(isValid: boolean) {
    this.isInputTextValid = isValid
  }

  @action
  public handleOnBlur = (e) => {
    this.closeMenu()
    this.verifyInputText()
  }

  @action
  public handleEnterKeyDown = (e) => {
    if (e && e.target && e.target.blur) e.target.blur()
    this.closeMenu()
    this.verifyInputText()
  }

  @action
  public handleTabKeyDown = (e) => {
    if (e && e.target && e.target.blur) e.target.blur()
    this.closeMenu()
    this.verifyInputText()
  }

  @action
  public handleClickArrowLeft() {
    this.setSelectedTime(this.selectedTime.clone().subtract(15, 'minutes'))
  }

  @action
  public handleClickArrowRight() {
    this.setSelectedTime(this.selectedTime.clone().add(15, 'minutes'))
  }

  @action
  public verifyInputText() {
    if (this.inputTextWasChanged) {
      this.inputTextWasChanged = false
      const allowedCharacters = 'ap:0123456789'
      const cleanedInput = this.inputText
        .toLowerCase()
        .split('')
        .filter((char) => allowedCharacters.includes(char))
        .join('')

      let allowedFormats
      if (cleanedInput.includes('a') || cleanedInput.includes('p')) {
        allowedFormats = ['hmmA', 'hhmmA']
      } else {
        allowedFormats = ['hmm', 'hhmm']
      }
      for (let i = 0; i < allowedFormats.length; i++) {
        const format = allowedFormats[i]
        const m = moment(cleanedInput, format)
        if (m.isValid()) {
          this.setSelectedTime(m)
          return
        }
      }
      this.setIsInputTextValid(false)
      return
    }
  }
}
