import { observable } from 'mobx'
import { deserialize, serializable, serialize, date } from 'serializr'
import uuid from 'uuid/v4'
import { IExerciseSetDTO } from '../dto/IExerciseSetDTO'

export class ExerciseSet implements IExerciseSetDTO {
  public static create(): ExerciseSet {
    const content = new ExerciseSet()
    content.id = uuid()
    return content
  }

  @serializable @observable public id: string = ''
  @serializable @observable public reps: number = 0
  @serializable @observable public weight: number = 0
  @serializable @observable public time: number = 0
  @serializable @observable public percent: number = 0
  @serializable @observable public index: number = 0
  @serializable @observable public isComplete: boolean = false
  @serializable @observable public completedAt: string = ''

  public serialize(): IExerciseSetDTO {
    const obj = serialize(this)
    return obj
  }

  public clone(): ExerciseSet {
    return deserialize(ExerciseSet, this.serialize())
  }
}
