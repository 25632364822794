import React from 'react'
import { ListItem, Grid, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'
import { ErrorRowVM } from '../../view-models/import/ErrorRowVM'
import ErrorIcon from '@material-ui/icons/ErrorOutlineRounded'
import WarningIcon from '@material-ui/icons/Warning'
import { V4MAPPED } from 'dns'
interface Props {
  row: ErrorRowVM
}

const ErrorRow: React.FC<Props> = ({ row }) => {
  const renderErrorMessage = () => {
    return (
      <Typography 
        className={row.isError ? 'result-error' : 'result-warning'}
        variant='h6'
      >
        {row.message}
      </Typography>
    )
  }

  const renderIcon = () => {
    if(row.isError) {
      return (
        <ErrorIcon className='result-error'/>
      )
    } else {
      return (
        <WarningIcon className='result-warning'/>
      )
    }
  }

  return (
    <ListItem id='UserImportRow'>
      <Grid container direction='row'>
        <Grid item className='error-icon'>
          {renderIcon()}
        </Grid>
        <Grid item>{renderErrorMessage()}</Grid>
      </Grid>
    </ListItem>
  )
}

export default observer(ErrorRow)
