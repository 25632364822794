import React from 'react'
import { GoalsStore } from '../../../store/GoalsStore'
import {
  Button,
  AppBar,
  Toolbar,
  Typography,
  TextField,
  LinearProgress,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core'
import BackIcon from '@material-ui/icons/KeyboardBackspace'
import { AppStore } from '../../../../stores/AppStore'
import { inject, observer } from 'mobx-react'
import ReplayIcon from '@material-ui/icons/Replay'
import LocalizationStore from '../../../../localization/LocalizationStore'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import DeleteIcon from '@material-ui/icons/Delete'
import './TopToolbar.scss'

interface Props {
  localizationStore?: LocalizationStore
  goalsStore?: GoalsStore
  appStore?: AppStore
}

const TopToolBar: React.FC<Props> = ({ appStore, goalsStore, localizationStore }) => {
  const { goalEditVM: vm } = goalsStore
  const { goal_create: strings } = localizationStore.lzStrings
  if (!vm) return null

  const renderBackButton = () => {
    return (
      <Button
        className='back-surveys-btn'
        variant='text'
        aria-label='back'
        onClick={() => {
          if (appStore.router.location.pathname.startsWith('/goals/from-catalog/')) {
            appStore.router.push('/dashboard/impact/catalog')
          } else {
            appStore.router.push('/dashboard/impact/goals')
          }
        }}
      >
        <BackIcon className='back-icon' />
        {strings.back_to_goals}
      </Button>
    )
  }

  const renderGoalName = () => {
    return (
      <TextField
        error={!vm.nameValid}
        value={vm.name}
        onChange={(e) => vm.setName(e.target.value)}
        placeholder={vm.objectId ? strings.edit_goal : strings.create_goal}
        variant='standard'
      />
    )
  }

  const saveButton = () => {
    if (vm.currentTab < 2) return null
    return (
      <Button disabled={!vm.saveValid} onClick={() => vm.continue()} variant='contained'>
        {vm.objectId ? strings.update_goal : strings.create_goal}
      </Button>
    )
  }

  const continueButton = () => {
    if (vm.currentTab === 2) return null
    return (
      <Button onClick={() => vm.continue()} variant='contained'>
        {strings.continue}
      </Button>
    )
  }

  const renderRightToolBar = () => {
    return (
      <>
        {renderMenu()}
        {saveButton()}
        {continueButton()}
      </>
    )
  }

  const renderRefreshOption = () => {
    if (!vm.objectId) return null
    return (
      <MenuItem className='refresh-item' onClick={() => goalsStore.editGoal(vm.objectId)}>
        <ReplayIcon />
        <Typography variant='h6'>Refresh</Typography>
      </MenuItem>
    )
  }

  const renderDeleteOption = () => {
    if (!vm.objectId) return null
    return (
      <MenuItem className='delete-item' onClick={() => vm.delete()}>
        <DeleteIcon />
        <Typography variant='h6'>Delete</Typography>
      </MenuItem>
    )
  }

  const renderMenu = () => {
    if (!vm.objectId) return null
    return (
      <>
        <IconButton
          className='toolbar-menu'
          onClick={(e) => vm.setToolbarAnchorEl(e.currentTarget)}
        >
          <MoreHorizIcon />
        </IconButton>
        <Menu
          anchorEl={vm.toolbarAnchorEl}
          keepMounted
          open={Boolean(vm.toolbarAnchorEl)}
          onClose={() => vm.setToolbarAnchorEl(null)}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          style={{ marginTop: 40 }}
        >
          {renderDeleteOption()}
          {renderRefreshOption()}
        </Menu>
      </>
    )
  }

  return (
    <AppBar position='static' color='default' id='TopToolbar'>
      <Toolbar className='toolbar'>
        <div slot='start' className='start'>
          {renderBackButton()}
          <Typography className='title' variant='h5'>
            {vm.objectId ? strings.edit_goal : strings.create_goal}
          </Typography>
          {renderGoalName()}
        </div>
        <div slot='end' className='end-buttons'>
          {renderRightToolBar()}
        </div>
      </Toolbar>
    </AppBar>
  )
}
export default inject('goalsStore', 'appStore', 'localizationStore')(observer(TopToolBar))
