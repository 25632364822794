import { computed } from 'mobx'
import { INLPResponseDTO } from '../../nlpResponses/dtos/INLPResponseDTO'
import moment from 'moment'

export class SentimentAnalysisRowVM {
  private nlpResponse: INLPResponseDTO
  constructor(record: INLPResponseDTO) {
    this.nlpResponse = record
  }
  @computed
  public get objectId() {
    return this.nlpResponse.objectId
  }

  @computed
  public get type() {
    return this.nlpResponse.type
  }

  @computed
  public get isPositiveSentiment() {
    const sentiment = this.nlpResponse.sentiment.document
    return 'positive' === sentiment.label.toLowerCase()
  }

  @computed
  public get sentiment() {
    const docSentiment = this.nlpResponse.sentiment.document
    return `${docSentiment.label}: (${docSentiment.score})`
  }

  @computed
  public get classifications() {
    const classifications = this.nlpResponse.classifications
    if (classifications && classifications.length > 0) {
      return classifications
        .map((c) => {
          return `${c.class_name} (${c.confidence})`
        })
        .join(', ')
    }

    return '<No Classifications Found>'
  }

  @computed
  public get date() {
    const date = moment(this.nlpResponse.createdAt)
    return date.format('LLL')
  }
}
