import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { TasksStore } from '../../store/TasksStore'
import { Grid, InputLabel, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { IOption } from '../../types/Types'

interface DrawerTaskDetailsPartnerChannelProps {
  localizationStore?: LocalizationStore
  tasksStore?: TasksStore
}

const DrawerTaskDetailsPartnerChannel: React.FC<DrawerTaskDetailsPartnerChannelProps> = ({
  localizationStore,
  tasksStore,
}) => {
  const { lzStrings } = localizationStore
  const { taskEditVM: VM } = tasksStore
  if (!VM.allowPartnerChannel) return null

  const handlePartnerChannelChange = (event: object, option: IOption) => {
    if (option) {
      VM.setTaskPartnerChannel(option.objectId)
    } else {
      VM.setTaskPartnerChannel(null)
    }
  }

  return (
    <Grid item xs={6}>
      <InputLabel htmlFor='taskClient'>
        {lzStrings.tasksManagementWidgetDrawer.task_partner}
      </InputLabel>
      <Autocomplete
        id='taskClient'
        options={VM.channelPartnerOptions}
        value={VM.selectedChannelPartnerOption}
        getOptionLabel={(option: IOption) => option.label}
        onChange={handlePartnerChannelChange}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              className: 'autocompleteInput',
            }}
            variant='outlined'
            fullWidth
            // error={VM.disciplineError}
          />
        )}
      />
    </Grid>
  )
}

export default inject('localizationStore', 'tasksStore')(observer(DrawerTaskDetailsPartnerChannel))
