import React, { useEffect, useRef } from 'react'
import { ListItem, Grid, Typography, Paper } from '@material-ui/core'
import { observer } from 'mobx-react'
import CheckIcon from '@material-ui/icons/Check'
import WarningIcon from '@material-ui/icons/Warning'
import './WeightProfileImportRow.scss'
import { WeightProfileImportRowVM } from '../../view-models/WeightProfileImportRowVM'
import WeightProfileImportCategory from './WeightProfileImportCategory'

interface Props {
  row: WeightProfileImportRowVM
  style: any
  index: number
}

const WeightProfileImportRow: React.FC<Props> = ({ row, style, index }) => {

  const weightProfileRef = useRef(null);

  useEffect(() => {
    if (weightProfileRef.current) {
      row.setHeight(weightProfileRef.current.offsetHeight, index)
    }
  }, [weightProfileRef]);

  const renderRole = () => {
    return (
      <Grid container item>
        <Typography className={row.textStyle}><strong>Role:</strong> {row.role}</Typography>
      </Grid>
    )
  }

  const renderCategories = () => {
    return (
      <Grid container item>
        <Typography className={row.textStyle}><strong>Categories:</strong></Typography>
        {row.categories.map((o, index) => {
          return <WeightProfileImportCategory key={index} category={o} row={row} />
        })}
      </Grid>
    )
  }

  const renderWeightProfileErrorMessage = () => {
    if (!row.weightProfileErroMessage) return
    return (
      <Grid container item className='row-error-text'>
        <strong>Error: </strong> {row.weightProfileErroMessage}
      </Grid>
    )
  }

  const renderSuccessfulRow = () => {
    if (row.hasError) return
    return (
      <>
        {renderRole()}
        {renderCategories()}
      </>
    )
  }

  return (
    <div style={style}>
      <Paper
        elevation={1}
        ref={weightProfileRef}
        id='WeightProfileImportRow'>
        <Grid
          container
          item>
        </Grid>
        {renderWeightProfileErrorMessage()}
        {renderSuccessfulRow()}
      </Paper>
    </div>

  )
}

export default observer(WeightProfileImportRow)
