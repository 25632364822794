import { action, computed, observable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import PulseCategory from '../aggregate/PulseCategory'
import { PulseCategoriesService } from '../services/PulseCategoriesService'
import { PulseCategorySelectVM } from './PulseCategorySelectVM'

export class PulseCategoryEditVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, pulseCategory: PulseCategory) {
    this.rootStore = rootStore
    this.pulseCategory = pulseCategory
    this.loadSelectVMs()
  }

  @observable public pulseCategory: PulseCategory = null
  @observable public grandCategorySelectVM: PulseCategorySelectVM = null
  @observable public parentCategorySelectVM: PulseCategorySelectVM = null
  @observable public saveTried: boolean = false
  @observable public showConfirmDelete: boolean = false

  @computed
  public get isNew(): boolean {
    return Boolean(!this.objectId)
  }

  @computed
  public get objectId(): string {
    return this.pulseCategory.objectId
  }

  @computed
  public get isGrand(): boolean {
    return Boolean(!this.pulseCategory.grandCategoryId)
  }

  @computed
  public get isParent(): boolean {
    return Boolean(!this.pulseCategory.parentCategoryId)
  }

  @computed
  public get name(): string {
    return this.pulseCategory.name
  }

  public get isValid(): boolean {
    if (!this.parentCategoryValid) return false
    if (!this.categoryNameValid) return false
    return true
  }

  public loadSelectVMs() {
    this.grandCategorySelectVM = new PulseCategorySelectVM(
      this.rootStore,
      undefined,
      undefined,
      this.pulseCategory.grandCategoryId
    )
    this.parentCategorySelectVM = new PulseCategorySelectVM(
      this.rootStore,
      this.pulseCategory.grandCategoryId,
      undefined,
      this.pulseCategory.parentCategoryId
    )
  }

  @action
  public closeEditModal() {
    this.rootStore.pulseCategoriesStore.viewModels.loadPulseCategoryEditVM('empty')
  }

  @action
  public async save() {
    this.saveTried = true
    if (!this.isValid) return 
    const svc = new PulseCategoriesService(this.rootStore)
    await svc.savePulseCategory(this.pulseCategory.serialize())
    this.closeEditModal()
  }

  @action
  public async delete() {
    const svc = new PulseCategoriesService(this.rootStore)
    await svc.deletePulseCategory(this.objectId)
    this.toggleConfirmDelete()
    this.closeEditModal()
  }

  @action
  public toggleConfirmDelete() {
    this.showConfirmDelete = !this.showConfirmDelete
  }

  public get parentCategoryValid(): boolean {
    if (!this.saveTried) return true
    if (this.isParent) return true
    if (this.isGrand) return true
    if (!this.parentCategorySelectVM.selectedCategoryId || this.parentCategorySelectVM.selectedCategoryId === '') return false
    return true
  }

  public get categoryNameValid(): boolean {
    if (!this.saveTried) return true
    if (!this.pulseCategory.name || this.pulseCategory.name === '') return false
    return true
  }

  public setGrandCategoryId(categoryId) {
    if (this.pulseCategory.grandCategoryId === categoryId) return
    this.pulseCategory.grandCategoryId = categoryId
    this.grandCategorySelectVM.setSelectedCategoryId(categoryId)
    this.parentCategorySelectVM.setSelectedCategoryId('')
    this.parentCategorySelectVM.setGrandCategoryId(this.pulseCategory.grandCategoryId)
    this.parentCategorySelectVM.refreshStore()
  }

  public setParentCategoryId(categoryId) {
    if (this.pulseCategory.parentCategoryId === categoryId) return
    this.pulseCategory.parentCategoryId = categoryId
    this.parentCategorySelectVM.setSelectedCategoryId(categoryId)
  }

  public setCategoryName(name: string) {
    this.pulseCategory.setName(name)
  }
}
