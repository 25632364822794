import React from 'react'
import { observer, inject } from 'mobx-react'
import { SurveysStore } from '../../../store/SurveysStore'
import './WeightMenu.scss'
import { TextField } from '@material-ui/core'

interface WeightMenuProps {
  surveysStore?: SurveysStore
  idx: number
  option: any
}

const WeightMenu: React.FC<WeightMenuProps> = ({ surveysStore, idx, option }) => {
  const { editVM } = surveysStore.viewModels
  if (!editVM) return null

  return (
    <div id='WeightMenu'>
      <TextField
        InputProps={{ inputProps: { min: 0 } }}
        disabled={editVM.isReadOnly}
        className={option.weightValid ? 'weight-input' : 'error'}
        type='number'
        value={option.weight}
        onChange={(e) => option.setWeight(e.target.value)}
      />
    </div>
  )
}

export default inject('surveysStore')(observer(WeightMenu))
