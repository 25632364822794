import { observable, computed, action } from 'mobx'
import moment from 'moment'
import { RootStore } from '../../stores/RootStore'
import { Alarm } from '../aggregate/Alarm'
import { IReminderDTO } from '../dtos/IReminderDTO'
import { AlarmModeType } from '../types/AlarmModeType'
import { PeriodType } from '../types/PeriodType'
import { TimeZoneType } from '../types/TimeZoneType'
import { SurveyEditVM } from './SurveyEditVM'
import _ from 'lodash'
import { DateUtils } from '../../shared/data/DateUtils'
import uuid from 'uuid/v4'

export class ReminderVM {
  private reminder: Alarm

  constructor(reminder: Alarm) {
    this.reminder = reminder
    this.sendReminderTime = DateUtils.getBrowserDate(this.reminder.date, this.reminder.timeZone)
    this.sendReminderDate = DateUtils.getBrowserDate(this.reminder.date, this.reminder.timeZone)
    this.uuid = uuid()
  }

  @observable public sendReminderTime: Date
  @observable public sendReminderDate: Date
  @observable public uuid

  @computed
  public get mode() {
    return this.reminder.mode
  }

  @computed
  public get value(): number {
    return this.reminder.units
  }

  @computed
  public get period(): PeriodType {
    return this.reminder.period
  }

  @computed
  public get date() {
    return this.reminder.date
  }

  @computed
  public get timeZone(): TimeZoneType {
    return this.timeZones.find((e) => e.value === this.reminder.timeZone)
  }

  @action
  setMode(mode: AlarmModeType) {
    this.reminder.setMode(mode)
  }

  @action
  setValue(val: unknown) {
    this.reminder.setUnits(Number(val))
  }

  @action
  setPeriod(val: unknown) {
    this.reminder.setPeriod(val as PeriodType)
    if (val === 'minute') {
      this.setValue(15)
    } else {
      this.setValue(1)
    }
  }

  @action
  public setDate(val: any) {
    this.sendReminderDate = val
    const timeZonedDate = DateUtils.getISOStringFromBrowserInput(
      this.sendReminderTime.toISOString(),
      val.toISOString(),
      this.timeZone.value
    )
    this.reminder.setDate(new Date(timeZonedDate))
  }

  @action
  public setTime(hour: number, minute: number) {
    const newDate = moment(this.reminder.date).hour(hour).minute(minute)
    this.sendReminderTime = newDate.toDate()
    const timeZonedDate = DateUtils.getISOStringFromBrowserInput(
      newDate.toISOString(),
      this.sendReminderDate.toISOString(),
      this.reminder.timeZone
    )
    this.reminder.setDate(new Date(timeZonedDate))
  }

  @action
  public setTimeZone(timeZone: any) {
    if (!timeZone) return
    const timeZonedDate = DateUtils.getISOStringFromBrowserInput(
      this.sendReminderTime.toISOString(),
      this.sendReminderDate.toISOString(),
      timeZone.value
    )
    this.reminder.setDate(new Date(timeZonedDate))
    this.reminder.setTimeZone(timeZone)
  }

  @computed
  public get timeZones(): Array<TimeZoneType> {
    const timeZones = moment.tz.names()
    const offsetTmz = []

    for (const i in timeZones) {
      const tz = moment.tz(timeZones[i]).format('Z').replace(':00', '').replace(':30', '.5')
      let x = tz === '0' ? 0 : parseInt(tz).toFixed(2)

      const timeZone: TimeZoneType = {
        label: `(GMT${moment.tz(timeZones[i]).format('Z')}) ${timeZones[i]}`,
        value: `${timeZones[i]}`,
        time: `${x}`,
      }
      offsetTmz.push(timeZone)
    }

    return _.sortBy(offsetTmz, [
      function (el) {
        return -el.time
      },
    ])
  }

  public toDTO(): IReminderDTO {
    return this.reminder.toDTO()
  }
}
