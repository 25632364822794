import { observable, computed, action } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { MediaItemType } from '../../upload/types/MediaItemType'

export class MediaItemForm {
  private rootStore: RootStore

  constructor(rootStore, media = null) {
    this.rootStore = rootStore

    if (media) {
      this.objectId = media.objectId
      this.type = media.type
      this.name = media.name
      this.path = media.path
    }
  }

  @observable objectId: string = ''
  @observable type: MediaItemType = null
  @observable name: string = ''
  @observable path: string = ''
  @observable uploadName: string = ''
  @observable uploadBase64: string = ''
  @observable file: File = null
  @observable uploadPending: Boolean = false
  @observable mediaWatchPercentage: number = 0

  @action
  public handleUploadNameChange(value) {
    this.uploadName = value
  }

  @action
  public handleUploadBase64Change(value) {
    this.uploadBase64 = value
  }

  @action
  public handleFileChange(value) {
    this.file = value
  }

  @action
  public setUploadPending(value) {
    this.uploadPending = value
  }

  @action
  public setWatchPercentage(value) {
    this.mediaWatchPercentage = value
  }

  @action
  public clearForm() {
    this.uploadName = ''
    this.uploadBase64 = ''
    this.file = null
  }

  @computed
  public get displayName() {
    if (this.objectId === '') return ''
    return this.type + 's/' + this.name
  }

  @computed get uploadNameValid() {
    if (Boolean(this.uploadName)) return true
    else return false
  }

  @computed
  public get saveEnabled() {
    if (Boolean(this.uploadBase64) && this.uploadNameValid) return true
    else return false
  }

  public toDTO() {
    return {
      objectId: this.objectId,
      type: this.type,
      name: this.name,
      path: this.path,
    }
  }
}
