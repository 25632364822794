import React from 'react'
import { inject, observer } from 'mobx-react'
import { WeightProfilesStore } from '../../../store/WeightProfilesStore'
import './CategoriesScreenBody.scss'
import {
  Paper,
  Typography,
  Checkbox,
  InputAdornment,
  OutlinedInput,
  FormControlLabel,
  Tooltip,
  Grid,
} from '@material-ui/core'
import VirtualList from 'react-tiny-virtual-list'
import CategoryRow from './CategoryRow'

interface Props {
  weightProfilesStore?: WeightProfilesStore
}

const CategoriesScreenBody: React.FC<Props> = ({ weightProfilesStore }) => {
  const { editVM } = weightProfilesStore
  if (!editVM) return null
  if (!editVM.selectedRole) return null
  const weightProfileVM = editVM.selectedRole.weightProfileVM
  if (!weightProfileVM) return null

  const renderTotalWeightValidation = () => {
    return (
      <div>
        <span className='error'>Total must equal 100%</span>
      </div>
    )
  }

  const renderCategoriesFlatlist = () => {
    return (
      <Grid container style={{ width: '100vw' }}>
        <VirtualList
          should-force-update={editVM.selected}
          height={800}
          width={'100%'}
          itemCount={editVM.categories.length}
          itemSize={index => editVM.rowGetter(index)}
          overscanCount={20}
          renderItem={({ index, style }) => (
            <CategoryRow cat={editVM.categories[index]} key={index} index={index} style={style} editVM={editVM} />
          )}
        />
      </Grid>

    )
  }

  return (
    <div id='CategoriesScreenBody'>
      {!weightProfileVM.parentCategoryWeightsValid && renderTotalWeightValidation()}
      {renderCategoriesFlatlist()}
    </div>
  )
}

export default inject('weightProfilesStore')(observer(CategoriesScreenBody))
