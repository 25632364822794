import Parse from 'parse'
import { observable, action, computed, reaction, runInAction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { SystemAdminService } from '../service/SystemAdminService'
import { ISystemAdminUser } from '../dto/ISystemAdminUser'
import { SystemAdminsListWidgetVM } from '../view-models/list-widget/SystemAdminsListWidgetVM'
import { SystemAdminNewVM } from '../view-models/add-system-admin/SystemAdminNewVM'
import { SystemAdminScreenVM } from '../view-models/SystemAdminScreenVM'

export class SystemAdminsStore {
  private rootStore: RootStore = null

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.listWidgetVM = new SystemAdminsListWidgetVM(rootStore)
    this.newVM = new SystemAdminNewVM(rootStore)
    this.screenVM = new SystemAdminScreenVM(this.rootStore)
  }

  @observable public systemAdmins: Array<ISystemAdminUser> = []
  @observable public listWidgetVM: SystemAdminsListWidgetVM = null
  @observable public newVM: SystemAdminNewVM = null
  @observable public isLoading: boolean = true
  @observable public screenVM: SystemAdminScreenVM = null

  @action
  public async loadSystemAdmins() {
    this.isLoading = true
    const svc = new SystemAdminService()
    this.systemAdmins = await svc.getSystemAdmins()
    setTimeout(() => (this.isLoading = false), 100)
  }

  public getSystemAdmin(objectId) {
    return this.systemAdmins.find((e) => e.objectId === objectId)
  }

  public clearData() {
    this.systemAdmins = []
  }
}
