import React from 'react'
import { AgGridReact } from 'ag-grid-react'
import { inject, observer } from 'mobx-react'
import { SurveyTemplatesSelectTableVM } from '../../../view-models/template-select/SurveyTemplatesSelectTableVM'
import { IconButton, Tooltip } from '@material-ui/core'
import Edit from '@material-ui/icons/Edit'
import PreviewIcon from '@material-ui/icons/RemoveRedEye'
import { SurveyTemplateSelectRowVM } from '../../../view-models/template-select/SurveyTemplateSelectRowVM'

interface Props {
  vm: SurveyTemplatesSelectTableVM
}

const SurveyTemplatesTableSelect: React.FC<Props> = ({ vm }) => {
  if (!vm) return

  const renderPreviewBtn = (row: SurveyTemplateSelectRowVM) => {
    return (
      <Tooltip arrow title={`Preview Template`} placeholder='bottom' enterDelay={500}>
        <span>
          <IconButton
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              vm.selectPreview(row)
            }}
          >
            <PreviewIcon />
          </IconButton>
        </span>
      </Tooltip>
    )
  }

  const renderEditLinkBtn = (row: SurveyTemplateSelectRowVM) => {
    if (!vm.canManageTemplates) return
    return (
      <Tooltip arrow title={`Edit Template`} placeholder='bottom' enterDelay={500}>
        <span>
          <IconButton
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              // vm.selectedRow = row
              row.navigateToEditPage()
            }}
          >
            <Edit />
          </IconButton>
        </span>
      </Tooltip>
    )
  }

  const actionsCell = (props) => {
    const row: SurveyTemplateSelectRowVM = props.data
    if (!row) return <div></div>
    return (
      <div>
        {renderEditLinkBtn(row)}
        {renderPreviewBtn(row)}
      </div>
    )
  }

  const renderGrid = () => {
    return (
      <div className={vm.hasRows ? 'ag-theme-alpine' : 'ag-theme-alpine ag-grid-no-rows'}>
        <AgGridReact
          gridOptions={{
            ...vm.getGridOptions(),
            frameworkComponents: {
              actionsCell: actionsCell,
            },
          }}
        />
      </div>
    )
  }

  const renderNoTemplates = () => {
    if (!vm.isLoaded) return
    if (vm.hasRows) return
    return <div className='no-templates-container'>No templates found</div>
  }

  const renderTable = () => {
    if (!vm.shouldRender) return
    return (
      <div id='SurveyTemplatesTableSelect'>
        {renderGrid()}
        {renderNoTemplates()}
      </div>
    )
  }

  return <>{renderTable()}</>
}

export default inject()(observer(SurveyTemplatesTableSelect))
