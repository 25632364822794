import { action, computed, observable } from 'mobx'
import { PulseQuestionEditVM } from './../PulseQuestionEditVM'
import { RootStore } from '../../../stores/RootStore'
import { PulseQuestionOption } from '../../../pulse-questions/aggregate/PulseQuestionOption'
import { ResponseType } from '../../../pulse-questions/types/ResponseType'
import { PulseQuestionType } from '../../../pulse-questions/types/PulseQuestionType'
import { IPulseQuestionOptionDTO } from 'src/app/pulse-questions/dtos/IPulseQuestionOptionDTO'
import FollowUpQuestion from '../../../pulse-questions/aggregate/FollowUpQuestion'
import PulseQuestion from '../../../pulse-questions/aggregate/PulseQuestion'

export class PulseQuestionOptionVM {
  private rootStore: RootStore

  constructor(
    rootStore: RootStore,
    pulseQuestionOption: PulseQuestionOption,
    index: number,
    parentVM: PulseQuestionEditVM
  ) {
    this.rootStore = rootStore
    this.pulseQuestionOption = pulseQuestionOption
    this.index = index
    this.parentVM = parentVM
    this.isReadOnly = parentVM.isReadOnly ? parentVM.isReadOnly : false
    this.loadFollowUpVM()
  }

  @observable public pulseQuestionOption: PulseQuestionOption = null
  @observable public pulseQuestionEditVM: PulseQuestionEditVM = null
  @observable public parentVM: PulseQuestionEditVM = null
  @observable public index: number = 0
  @observable public isChecked: boolean = false
  @observable public responseNumber: number = 0
  @observable public isReadOnly: boolean = false
  @observable public followUpQuestionVM: PulseQuestionEditVM = null

  @action
  private loadFollowUpVM() {
    if (!this.hasFollowUp) return
    this.followUpQuestionVM = new PulseQuestionEditVM(this.rootStore, this.followUpQuestion as PulseQuestion, true, true) 
  }

  @computed
  public get questionType(): PulseQuestionType {
    return this.parentVM.questionType
  }

  @computed
  public get responseTypeOptions() {
    let options = [
      { value: 'very positive' },
      { value: 'positive' },
      { value: 'neutral' },
      { value: 'negative' },
      { value: 'very negative' },
    ]
    return options
  }

  @computed
  public get responseType(): ResponseType {
    return this.pulseQuestionOption.responseType
  }

  @computed
  public get isFollowUp(): boolean {
    return this.parentVM.isFollowUp
  }

  @computed
  public get hasFollowUp(): boolean {
    return this.pulseQuestionOption.hasFollowUp
  }

  @computed
  public get text(): string {
    return this.pulseQuestionOption.text
  }

  @computed
  public get value(): number {
    return this.pulseQuestionOption.value
  }

  @computed
  public get subText(): string {
    return this.pulseQuestionOption.subText
  }

  @computed
  public get emoji(): string {
    return this.pulseQuestionOption.emoji
  }

  @computed
  public get rank(): number {
    return this.pulseQuestionOption.rank
  }

  @computed
  public get isValid(): boolean {
    if (!this.parentVM.applyTried) return true
    if (!this.textValid) return false
    return true
  }

  @computed
  public get textValid(): boolean {
    if (!this.parentVM.applyTried) return true
    if (!this.text || this.text === '') return false
    return true
  }

  @action
  public setNumber(val: any) {
    if (!val) this.responseNumber = 0
    let decimals = 0
    const decimalIndex = String(val).lastIndexOf('.')
    if (decimalIndex > 0) {
      decimals = String(val).length - decimalIndex - 1
    }
    this.responseNumber = Number(val)
  }

  @action
  public increaseResponse() {
    const num = this.responseNumber + this.numberStep
    this.setNumber(num)
  }

  @action
  public decreaseResponse() {
    const num = this.responseNumber - this.numberStep
    this.setNumber(num)
  }

  @action
  public setValue(val) {
    this.pulseQuestionOption.setValue(val)
  }

  @action
  public setSubText(val) {
    this.pulseQuestionOption.setSubText(val)
  }

  @action
  public setEmoji(emoji: string) {
    this.pulseQuestionOption.setEmoji(emoji)
  }

  @action
  public setResponseType(responseType) {
    this.pulseQuestionOption.setResponseType(responseType)
  }

  @action
  public setText(text: string) {
    this.pulseQuestionOption.setText(text)
  }

  @action
  public setRank(val: number) {
    this.pulseQuestionOption.setRank(val)
  }

  @action
  public deleteOption() {
    this.parentVM.deleteOption(this.index)
  }

  @action
  public setIsReadOnly(isReadOnly: boolean) {
    this.isReadOnly = isReadOnly
  }

  @computed
  public get followUpQuestion(): FollowUpQuestion {
    return this.pulseQuestionOption.followUpQuestion
  }

  @action
  public toggleHasFollowUpQuestion() {
    this.pulseQuestionOption.toggleHasFollowUp()
    if (this.hasFollowUp) return this.loadFollowUpVM()
    this.followUpQuestionVM = null
  }
  
  @computed
  public get maximumValueAllowed(): number {
    return Number(this.parentVM.maximumValueAllowed)
  }

  @computed
  public get minimumValueAllowed(): number {
    return Number(this.parentVM.minimumValueAllowed)
  }

  @computed
  public get numberStep(): number {
    return Number(this.parentVM.numberStep)
  }

  @action
  public toggleChecked() {
    this.isChecked = !this.isChecked
  }

  @action
  public setChecked(val: boolean) {
    this.isChecked = val
  }

  public toDTO(): IPulseQuestionOptionDTO {
    return this.pulseQuestionOption.toDTO()
  }
}
