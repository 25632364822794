import React from 'react'
import { inject, observer, useLocalStore } from 'mobx-react'
import rootStore from '../../stores/RootStore'
import { Button, ButtonBase, Card, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, MenuItem, Select, Switch, TextField, Tooltip, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import './PulseCategoryEditModal.scss'
import { PulseCategoriesStore } from '../store/PulseCategoriesStore'
import PulseCategory from '../aggregate/PulseCategory'
import MuiConfirmDeleteDialog from '../../shared/MuiConfirmDeleteDialog'

interface Props {
  pulseCategoriesStore?: PulseCategoriesStore
}

const PulseCategoryEditModal: React.FC<Props> = ({ pulseCategoriesStore }) => {
  const vm = pulseCategoriesStore.viewModels.editVM
  if (!vm) return null

  const renderDialogTitle = () => {
    return (
      <div className={'dialog-header'}>
        <div className='dialog-left'>
          <Typography className={'dialog-title'}>Category Details</Typography>
        </div>
        <div className='dialog-right'>
          {renderDeleteButton()}
          <Button variant='contained' onClick={() => vm.save()}>
            Save
          </Button>
          <IconButton onClick={() => vm.closeEditModal()}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    )
  }

  const renderDeleteButton = () => {
    if (vm.isNew) return null
    return (
      <Button variant='text' className={'delete-btn'} onClick={() => vm.toggleConfirmDelete()}>
        Delete
      </Button>
    )
  }

  const renderGrandParentCategory = () => {
    if (vm.isGrand) return null
    const selectVM = vm.grandCategorySelectVM
    return (
      <div className='category'>
        <Typography className='category-header'>KMO</Typography>
        <Select
          className={'category-select'}
          value={selectVM.selectedCategoryId}
          onChange={(e) => { vm.setGrandCategoryId(e.target.value) }}
          variant='outlined'
        >
          {selectVM.categories.map((category: PulseCategory) => (
            <MenuItem key={category.objectId} value={category.objectId}>
              {category.name}
            </MenuItem>
          ))}
        </Select>
      </div>
    )
  }

  const renderParentCategorySelect = () => {
    if (vm.isParent) return null
    if (vm.isGrand) return null
    const selectVM = vm.parentCategorySelectVM
    return (
      <div className='category'>
        <Typography className='category-header'>Category</Typography>
        <Select
          error={!vm.parentCategoryValid}
          className={'category-select'}
          value={selectVM.selectedCategoryId}
          onChange={(e) => { vm.setParentCategoryId(e.target.value) }}
          variant='outlined'
        >
          {selectVM.categories.map((category: PulseCategory) => (
            <MenuItem key={category.objectId} value={category.objectId}>
              {category.name}
            </MenuItem>
          ))}
        </Select>
      </div>
    )
  }

  const renderChildCategory = () => {
    if (vm.isParent) return null
    return (
      <div className='category'>
        <Typography className='category-header'>Subcategory</Typography>
        <TextField
          error={!vm.categoryNameValid}
          autoFocus
          id='outlined-name'
          className={'category-select'}
          value={vm.name}
          onChange={(e) => vm.setCategoryName(e.target.value)}
          margin='none'
          fullWidth
          variant='outlined'
        />
      </div >
    )
  }

  const renderParentEdit = () => {
    if (vm.isGrand) return null
    if (!vm.isParent) return null
    return (
      <div className='category'>
        <Typography className='category-header'>Category</Typography>
        <TextField
          error={!vm.categoryNameValid}
          autoFocus
          id='outlined-name'
          className={'category-select'}
          value={vm.name}
          onChange={(e) => vm.setCategoryName(e.target.value)}
          margin='none'
          fullWidth
          variant='outlined'
        />
      </div >
    )
  }

  const renderGrandEdit = () => {
    if (!vm.isGrand) return null
    return (
      <div className='category'>
        <Typography className='category-header'>KMO</Typography>
        <TextField
          error={!vm.categoryNameValid}
          autoFocus
          id='outlined-name'
          className={'category-select'}
          value={vm.name}
          onChange={(e) => vm.setCategoryName(e.target.value)}
          margin='none'
          fullWidth
          variant='outlined'
        />
      </div >
    )
  }

  const renderConfirmDelete = () => {
    return (
      <MuiConfirmDeleteDialog
        itemType='pulse category'
        itemName={vm.name}
        open={vm.showConfirmDelete}
        onConfirm={() => vm.delete()}
        onClose={() => vm.toggleConfirmDelete()}
        additionalDetails={'Note: Doing so will also delete all associated sub-categories and questions.'}
      />
    )
  }

  const renderMainDetails = () => {
    return (
      <>
        {renderConfirmDelete()}
        {renderGrandParentCategory()}
        {renderParentCategorySelect()}
        {renderGrandEdit()}
        {renderParentEdit()}
        {renderChildCategory()}
      </>
    )
  }

  return (
    <Dialog
      id='PulseCategoryEditModal'
      open={true}
      onClose={() => vm.closeEditModal()}
      fullWidth
    >
      <div className={'dialog-container'}>
        {renderDialogTitle()}
      </div>
      <div className={'dialog-content'}>
        {renderMainDetails()}
      </div>
    </Dialog>
  )
}

export default inject('pulseCategoriesStore')(observer(PulseCategoryEditModal))
