import React, { FC } from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { observer, inject } from 'mobx-react'
import './SurveyOptions.scss'
import { SurveysStore } from '../../../../surveys/store/SurveysStore'
import LocalizationStore from '../../../../localization/LocalizationStore'
import GeneralTab from './GeneralTab'
import PublishingOptionsTab from './PublishingOptionsTab'
import EmailOnResponse from './EmailOnResponse'
import PublishedNotificationTemplates from './PublishedNotificationTemplates'
import ReminderNotificationTemplates from './ReminderNotificationTemplates'
import SendAutomaticReminders from './SendAutomaticReminders'
import Advanced from './Advanced'
import TinyMCEMediaPicker from '../TinyMCEMediaPicker/TinyMCEMediaPicker'
import { LabelsStore } from '../../../../labels/store/LabelsStore'

interface SurveyOptionsProps {
  surveysStore?: SurveysStore
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
}

const SurveyOptions: FC<SurveyOptionsProps> = ({
  surveysStore,
  localizationStore,
  labelsStore,
}) => {
  const surveyEditVM = surveysStore.viewModels.editVM
  const vm = surveyEditVM.surveyOptionsVM
  if (!surveyEditVM) return null
  if (!vm) return null
  const s = localizationStore.lzStrings

  return (
    <div id='SurveyOptions'>
      <TinyMCEMediaPicker vm={vm.notificationTemplatesEditorVM.mediaUploadPickerVM} />
      <div className='left'>
        <Tabs
          orientation='vertical'
          variant='standard'
          value={vm.tabIndex}
          onChange={(e, tabIndex) => vm.setTabIndex(tabIndex)}
          className='survey-options-tabs'
        >
          <CustomTab
            primaryText='General'
            secondaryText={
              'Question Flow, Anonymize Results, ' +
              labelsStore.getLabelByLanguageAndFor('pulse') +
              ' Owner'
            }
          />
          <CustomTab
            primaryText='Publishing Options'
            secondaryText={
              'When to Publish, Recurring ' +
              labelsStore.getLabelByLanguageAndFor('pulses') +
              ', Due Date'
            }
          />
          <CustomTab
            primaryText='Notifications'
            secondaryText={
              'Email on Response Submitted, ' +
              labelsStore.getLabelByLanguageAndFor('pulse') +
              ' Published Templates'
            }
            error={surveyEditVM.steppersVM.notifError}
          />
          <CustomTab
            primaryText='Reminders'
            secondaryText='Send Automatic Reminders, Reminder Templates'
            error={surveyEditVM.steppersVM.reminderError}
          />
          <CustomTab primaryText='Advanced' secondaryText={vm.advancedTabText} />
        </Tabs>
      </div>
      <div className='right'>
        <TabPanel value={vm.tabIndex} index={0}>
          <GeneralTab />
        </TabPanel>
        <TabPanel value={vm.tabIndex} index={1}>
          <PublishingOptionsTab />
        </TabPanel>
        <TabPanel value={vm.tabIndex} index={2}>
          <EmailOnResponse />
          <PublishedNotificationTemplates />
        </TabPanel>
        <TabPanel value={vm.tabIndex} index={3}>
          <SendAutomaticReminders />
          <ReminderNotificationTemplates />
        </TabPanel>
        <TabPanel value={vm.tabIndex} index={4}>
          <Advanced />
        </TabPanel>
      </div>
    </div>
  )
}

const TabPanel = (props) => {
  const { children, value, index, ...rest } = props
  return (
    <div hidden={value !== index} {...rest} className='options-v2-tab-panel'>
      {children}
    </div>
  )
}

interface CustomTabProps {
  primaryText: string
  secondaryText: string
  error?: boolean
}

const CustomTab: FC<CustomTabProps> = observer(
  ({ primaryText, secondaryText, error = false, ...rest }) => {
    return (
      <Tab
        className={'survey-options-tab'}
        label={
          <div className='custom-tab-label'>
            <div className={'tab-primary-text ' + (error ? 'invalid' : '')}>{primaryText}</div>
            <div className='tab-secondary-text'>{secondaryText}</div>
          </div>
        }
        {...rest}
      />
    )
  }
)

export default inject('surveysStore', 'localizationStore', 'labelsStore')(observer(SurveyOptions))
