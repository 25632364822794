import React from 'react'

//MobX Stores
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { CategoriesStore } from 'src/app/categories/CategoriesStore'

//MaterialUI Components
import { CardHeader, IconButton } from '@material-ui/core'

//Material Icons
import CloseIcon from '@material-ui/icons/Close'
import { LabelsStore } from '../../../../labels/store/LabelsStore'

//Components Props Definition
interface DrawerHeaderProps {
  localizationStore?: LocalizationStore
  categoriesStore?: CategoriesStore
  labelsStore?: LabelsStore
}

//Component
const DrawerHeader: React.FC<DrawerHeaderProps> = ({
  localizationStore,
  categoriesStore,
  labelsStore,
}) => {
  const { lzStrings } = localizationStore
  const { widgetForm } = categoriesStore

  const handleClose = () => {
    widgetForm.closeDrawer()
  }

  const renderEnglishLabel = () => {
    if (widgetForm.addingNewCategory) {
      return `${
        lzStrings.categoriesManagementWidgetDrawer.add_new
      } ${labelsStore.getLabelByLanguageAndFor('category')}`
    } else {
      return `${
        lzStrings.categoriesManagementWidgetDrawer.edit
      } ${labelsStore.getLabelByLanguageAndFor('category')}`
    }
  }

  const renderGermanLabel = () => {
    if (widgetForm.addingNewCategory) {
      return `Neue  ${labelsStore.getLabelByLanguageAndFor('category')} Hinzufügen`
    } else {
      return `${labelsStore.getLabelByLanguageAndFor('category')} ${
        lzStrings.categoriesManagementWidgetDrawer.edit
      } `
    }
  }

  return (
    <CardHeader
      action={
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      }
      title={localizationStore.language === 'English' ? renderEnglishLabel() : renderGermanLabel()}
    />
  )
}

export default inject('localizationStore', 'categoriesStore', 'labelsStore')(observer(DrawerHeader))
