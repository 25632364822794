import React from 'react'
import { Grid } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import './UserImportRow.scss'
import { UserCheck } from 'react-feather'
import { UserX } from 'react-feather'
import ArchivedLabel from '../../../shared/ArchivedLabel'
import { UserImportVM } from '../../view-models/importV2/UserImportVM'
import { UserImportRowVM } from '../../view-models/importV2/UserImportRowVM'
import LocalizationStore from '../../../localization/LocalizationStore'

interface Props {
  row: UserImportRowVM
  style: any
  vm: UserImportVM
  localizationStore?: LocalizationStore
}

const UserImportRow: React.FC<Props> = ({ row, style, vm, localizationStore }) => {
  const lz = localizationStore.lzStrings.usersImport

  const renderName = () => {
    return (
      <Grid container item>
        <strong>{lz.name}</strong>: {row.userName}
      </Grid>
    )
  }

  const renderUserEmail = () => {
    if (!row.userEmail) return null
    return (
      <Grid container item>
        <strong>{lz.email}</strong>: {row.userEmail}
      </Grid>
    )
  }

  const renderUserUsername = () => {
    if (!row.userUsername) return null
    return (
      <Grid container item>
        <strong>{lz.username}</strong>: {row.userUsername}
      </Grid>
    )
  }

  const renderArchived = () => {
    if (!row.archived) return null
    if (vm.acceptedChangesComplete || vm.acceptingChanges) return <ArchivedLabel />
  }

  const renderUserFound = () => {
    if (row.userErrorMessage) return
    return (
      <Grid container item className='user-found'>
        <strong>
          {lz.user} {vm.acceptedChangesComplete || vm.acceptingChanges ? 'Saved' : 'Found'}
        </strong>
        :
        <div className='user-icon'>
          {row.isNewUser ? <UserX color='red' /> : <UserCheck color='green' />}
        </div>
        {renderArchived()}
      </Grid>
    )
  }

  const renderUserErrorMessage = () => {
    if (!row.userErrorMessage) return
    return (
      <Grid container item className='row-error-text'>
        <strong>{lz.error}</strong>: {row.userErrorMessage}
      </Grid>
    )
  }

  const renderOrgUserErrorMessage = () => {
    if (!row.orgUserErrorMessage) return
    return (
      <Grid container item className='row-error-text'>
        <strong>{lz.error}</strong>: {row.orgUserErrorMessage}
      </Grid>
    )
  }

  const renderAddedRoles = () => {
    if (!row.addedRoles) return
    return (
      <Grid container item>
        <strong>{lz.added_roles}</strong>: {row.addedRoles}
      </Grid>
    )
  }

  const renderRemovedRoles = () => {
    if (!row.removedRoles) return
    return (
      <Grid container item>
        <strong>Removed Roles</strong>: {row.removedRoles}
      </Grid>
    )
  }

  const renderAddedGroups = () => {
    if (!row.addedGroups) return
    return (
      <Grid container item>
        <strong>{lz.added_groups}</strong>: {row.addedGroups}
      </Grid>
    )
  }

  const renderRemovedGroups = () => {
    if (!row.removedGroups) return
    return (
      <Grid container item>
        <strong>Removed Groups</strong>: {row.removedGroups}
      </Grid>
    )
  }

  const renderAddedPrivilegeSets = () => {
    if (!row.addedPrivilegeSets) return
    return (
      <Grid container item>
        <strong>Added Privilege Sets</strong>: {row.addedPrivilegeSets}
      </Grid>
    )
  }

  const renderAddedToPrivilegeSets = () => {
    if (!row.addedToPrivilegeSets) return
    return (
      <Grid container item>
        <strong>Added To Privilege Sets</strong>: {row.addedToPrivilegeSets}
      </Grid>
    )
  }

  const renderRemovedPrivilegeSets = () => {
    if (!row.removedPrivilegeSets) return
    return (
      <Grid container item>
        <strong>Removed Privilege Sets</strong>: {row.removedPrivilegeSets}
      </Grid>
    )
  }

  const renderUpdatedSuperiors = () => {
    if (!row.updatedSuperiors) return
    return (
      <Grid container item>
        <strong>{lz.updated_superiors}</strong>: {row.updatedSuperiors}
      </Grid>
    )
  }

  const renderAddedSuperiors = () => {
    if (!row.addedSuperiors) return
    return (
      <Grid container item>
        <strong>{lz.added_superiors}</strong>: {row.addedSuperiors}
      </Grid>
    )
  }

  const renderAddedPrimaryRole = () => {
    if (!row.addedPrimaryRoleName) return
    return (
      <Grid container item>
        <strong>{lz.added_primary_role}</strong>: {row.addedPrimaryRoleName}
      </Grid>
    )
  }

  const renderAddedPrimaryGroup = () => {
    if (!row.addedPrimaryGroupName) return
    return (
      <Grid container item>
        <strong>{lz.added_primary_group}</strong>: {row.addedPrimaryGroupName}
      </Grid>
    )
  }

  const renderUpdatedPrimaryRole = () => {
    if (!row.updatedPrimaryRoleName) return
    return (
      <Grid container item>
        <strong>{lz.updated_primary_role}</strong>: {row.updatedPrimaryRoleName}
      </Grid>
    )
  }

  const renderUpdatedPrimaryGroup = () => {
    if (!row.updatedPrimaryGroupName) return
    return (
      <Grid container item>
        <strong>{lz.updated_primary_group}</strong>: {row.updatedPrimaryGroupName}
      </Grid>
    )
  }

  const renderUpdatedUserPhoneNumber = () => {
    if (!row.updatedPhoneNumber) return
    return (
      <Grid container item>
        <strong>{lz.updated_phone}</strong>: {row.updatedPhoneNumber}
      </Grid>
    )
  }

  const renderAddedUserPhoneNumber = () => {
    if (!row.addedPhoneNumber) return
    return (
      <Grid container item>
        <strong>{lz.added_phone}</strong>: {row.addedPhoneNumber}
      </Grid>
    )
  }

  const renderUpdatedEmployeeId = () => {
    if (!row.updatedEmployeeId) return
    return (
      <Grid container item>
        <strong>{lz.updated_employee_id}</strong>: {row.updatedEmployeeId}
      </Grid>
    )
  }

  const renderAddedEmployeeId = () => {
    if (!row.addedEmployeeId) return
    return (
      <Grid container item>
        <strong>{lz.added_employee_id}</strong>: {row.addedEmployeeId}
      </Grid>
    )
  }

  const renderUpdatedJobNumber = () => {
    if (!row.updatedJobNumber) return
    return (
      <Grid container item>
        <strong>{lz.updated_job_number}</strong>: {row.updatedJobNumber}
      </Grid>
    )
  }

  const renderAddedJobNumber = () => {
    if (!row.addedJobNumber) return
    return (
      <Grid container item>
        <strong>{lz.added_job_number}</strong>: {row.addedJobNumber}
      </Grid>
    )
  }

  const renderUpdatedBirthdate = () => {
    if (!row.updatedBirthdate) return
    return (
      <Grid container item>
        <strong>{lz.updated_birthday}</strong>: {row.updatedBirthdate}
      </Grid>
    )
  }

  const renderAddedBirthdate = () => {
    if (!row.addedBirthdate) return
    return (
      <Grid container item>
        <strong>{lz.added_birthday}</strong>: {row.addedBirthdate}
      </Grid>
    )
  }

  const renderUpdatedTitle = () => {
    if (!row.updatedTitle) return
    return (
      <Grid container item>
        <strong>{lz.updated_title}</strong>: {row.updatedTitle}
      </Grid>
    )
  }

  const renderAddedTitle = () => {
    if (!row.addedTitle) return
    return (
      <Grid container item>
        <strong>{lz.added_title}</strong>: {row.addedTitle}
      </Grid>
    )
  }

  const renderUpdatedReceiveTextMessages = () => {
    if (!row.updatedReceiveTextMessages) return
    return (
      <Grid container item>
        <strong>{lz.receive_texts}</strong>: {row.updatedReceiveTextMessages}
      </Grid>
    )
  }

  const renderAddedReceiveTextMessages = () => {
    if (!row.addedReceiveTextMessages) return
    return (
      <Grid container item>
        <strong>{lz.receive_texts}</strong>: {row.addedReceiveTextMessages}
      </Grid>
    )
  }

  const renderUpdatedReceivePushNotifications = () => {
    if (!row.updatedReceivePushNotifications) return
    return (
      <Grid container item>
        <strong>{lz.receive_push}</strong>: {row.updatedReceivePushNotifications}
      </Grid>
    )
  }

  const renderAddedReceivePushNotifications = () => {
    if (!row.addedReceivePushNotifications) return
    return (
      <Grid container item>
        <strong>{lz.receive_push}</strong>: {row.addedReceivePushNotifications}
      </Grid>
    )
  }

  const renderUpdatedReceiveEmails = () => {
    if (!row.updatedReceiveEmails) return
    return (
      <Grid container item>
        <strong>{lz.receive_emails}</strong>: {row.updatedReceiveEmails}
      </Grid>
    )
  }

  const renderAddedReceiveEmails = () => {
    if (!row.addedReceiveEmails) return
    return (
      <Grid container item>
        <strong>{lz.receive_emails}</strong>: {row.addedReceiveEmails}
      </Grid>
    )
  }

  const renderSuccessfulRow = () => {
    if (row.hasError) return
    return (
      <>
        {renderUserFound()}
        {renderUserEmail()}
        {renderUserUsername()}
        {renderName()}
        {renderAddedTitle()}
        {renderUpdatedTitle()}
        {renderAddedUserPhoneNumber()}
        {renderUpdatedUserPhoneNumber()}
        {renderAddedEmployeeId()}
        {renderUpdatedEmployeeId()}
        {renderAddedJobNumber()}
        {renderUpdatedJobNumber()}
        {renderAddedBirthdate()}
        {renderUpdatedBirthdate()}
        {renderAddedPrimaryGroup()}
        {renderUpdatedPrimaryGroup()}
        {renderAddedRoles()}
        {renderAddedPrimaryRole()}
        {renderUpdatedPrimaryRole()}
        {renderAddedGroups()}
        {renderAddedSuperiors()}
        {renderUpdatedSuperiors()}
        {renderAddedReceiveTextMessages()}
        {renderUpdatedReceiveTextMessages()}
        {renderAddedReceiveEmails()}
        {renderUpdatedReceiveEmails()}
        {renderAddedReceivePushNotifications()}
        {renderUpdatedReceivePushNotifications()}
        {renderRemovedRoles()}
        {renderRemovedGroups()}
        {renderRemovedPrivilegeSets()}
        {renderAddedPrivilegeSets()}
        {renderAddedToPrivilegeSets()}
      </>
    )
  }

  return (
    <Grid container style={style} id='UserImportRow'>
      <Grid container item>
        <strong>
          {lz.row} {row.rowIndex}
        </strong>
      </Grid>
      {renderUserErrorMessage()}
      {renderOrgUserErrorMessage()}
      {renderSuccessfulRow()}
    </Grid>
  )
}

export default inject('localizationStore')(observer(UserImportRow))
