import React from 'react'
import { inject, observer } from 'mobx-react'
import { AppBar, Button, CssBaseline, Toolbar, Tooltip, Typography } from '@material-ui/core'
import clsx from 'clsx'
import isIE11 from '../../../utils/isIE11'
import LocalizationStore from '../../localization/LocalizationStore'
import { EmailTemplatesStore } from '../store/EmailTemplatesStore'
import { EmailTemplatesManageVM } from '../view-models/EmailTemplatesManageVM'
import { EmailTemplatesTableVM } from '../view-models/EmailTemplatesTableVM'
import '../../css/dashboard-toolbar.scss'
import './EmailTemplatesTopToolbar.scss'

interface Props {
  emailTemplatesStore?: EmailTemplatesStore
  localizationStore?: LocalizationStore
  vm: EmailTemplatesManageVM
  tableVM: EmailTemplatesTableVM
}

const EmailTemplatesTopToolbar: React.FC<Props> = ({
  localizationStore,
  vm,
  tableVM,
}) => {
  const { emailTemplateManagement: lz } = localizationStore.lzStrings

  const renderCreateNewButton = () => {
    if (!vm.canManage) return
    if (!tableVM.isSystemTable) return
    return (
      <div className='dashboard-toolbar-right end-content'>
        {vm.canManage ? (
          <Tooltip title={lz.create_email_template_info} placement='bottom'>
            <span>
              <Button
                variant='outlined'
                onClick={() => vm.createNewEmailTemplate()}
                className='dashboard-action btn-icon btn-icon-white end-action'
              >
                {`${lz.create} ${lz.new} ${lz.email_template}`}
              </Button>
            </span>
          </Tooltip>
        ) : undefined}
      </div>
    )
  }

  const renderPageTitle = () => {
    let title = `${lz.manage} ${lz.email_templates}`
    if (tableVM.isSystemTable) title = `${lz.manage} ${lz.system} ${lz.email_templates}`
    return <Typography className='dashboard-title'>{title}</Typography>
  }

  return (
    <div id='DashboardToolbar' className={'dashboardToolbar-header'}>
      <CssBaseline />
      <AppBar
        className={clsx('dashboard-appbar', {
          'no-transition': isIE11,
        }, tableVM.isSystemTable ? 'strong' : undefined)}
        elevation={0}
      >
        <Toolbar className='dashboard-toolbar'>
          {renderPageTitle()}
        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  )
}

export default inject()(observer(EmailTemplatesTopToolbar))
