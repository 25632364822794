import React from 'react'
import { observer, inject } from 'mobx-react'
import { Grid, IconButton, DialogTitle } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { TrainingItemsStore } from '../../store/TrainingItemsStore'
import LocalizationStore from '../../../localization/LocalizationStore'
import './EditDetailsDialogHeader.scss'

interface Props {
  trainingItemsStore?: TrainingItemsStore
  localizationStore?: LocalizationStore
  title: string
  onClick: Function
}

const EmailTemplateDialogHeader: React.FC<Props> = ({ trainingItemsStore, localizationStore, title, onClick }) => {
  return (
    <div id='EmailTemplateDialogHeader'>
      <Grid id='emailTemplatesDialogHeader' container alignItems='center'>
        <Grid item xs={11}>
          <DialogTitle className={'dialog-header-title'} disableTypography>
            {title}
          </DialogTitle>
        </Grid>
        <Grid item xs={1}>
          <IconButton className={'dialog-header-close'} onClick={() => onClick()}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  )
}

export default inject('trainingItemsStore', 'localizationStore')(observer(EmailTemplateDialogHeader))
