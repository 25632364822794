import React, { useRef } from 'react'
import { observer } from 'mobx-react'
import './CampaignRowTimeline.scss'
import { CampaignRowVM } from '../../../view-models/dashboard/all-campaigns/CampaignRowVM'
import TimelineSurvey from './TimelineSurvey'


interface Props {
  vm: CampaignRowVM
}

const CampaignRowTimeline: React.FC<Props> = ({ vm }) => {
  const scrollRef = useRef(null)

  if (!vm) return null
  if (!vm.isExpanded) return null

 
  const scroll = (scrollOffset) => {
    scrollRef.current.scrollLeft += scrollOffset;
  }

  const renderCampaignTimeline = () => {
    let className = `campaign-row-timeline border-status-${vm.status}`
    if (vm.isDetailsModal) className = 'campaign-row-timeline'
    return (
      <div ref={scrollRef} className={className}>
        {renderTimelineSurveys()}
      </div>
    )
  }

  const renderTimelineScrollButtons = () => {
    let className = `scroll-buttons border-status-${vm.status}`
    if (vm.isDetailsModal) className = 'scroll-buttons'
    return (
      <div className={className}>
        <div onClick={() => scroll(-300)} className={'left-button'}>
          <div className='arrow'>
            <div className='point-left'></div>
            <div className='line'></div>
          </div>
        </div>
        <div onClick={() => scroll(300)} className={'right-button'}>
          <div className='arrow'>
            <div className='line'></div>
            <div className='point-right'></div>
          </div>
        </div>
      </div>
    )
  }

  const renderTimelineSurveys = () => {
    const surveys = []
    vm.surveys.forEach((s, i) => {
      surveys.push(<TimelineSurvey key={i} vm={s} />)
    })
    return <div className='timeline-surveys'>{surveys}</div>
  }

  return (
    <div id='CampaignRowTimeline'>
      {renderTimelineScrollButtons()}
      {renderCampaignTimeline()}
    </div>
  )
}

export default observer(CampaignRowTimeline)
