import { observable } from 'mobx'
import { serializable } from 'serializr'
import { IParticipantDTO } from '../dtos/IParticipantDTO'
import { ParticipantType } from '../types/ParticipantType'

export class Participant implements IParticipantDTO {
  static create(fromPart: any) {
    const part = new Participant()
    part.id = fromPart.id
    part.type = fromPart.type
    part.isArchived = fromPart.isArchived
    part.name =
      fromPart.name &&
      fromPart.name.replace('User: ', '').replace('Role: ', '').replace('Group: ', '')
    return part
  }

  @serializable @observable id: string = ''
  @serializable @observable type: ParticipantType
  @serializable @observable name: string = ''
  @serializable @observable isArchived?: boolean = false
}
