import React from 'react'
import { inject, observer } from 'mobx-react'
import { PulseQuestionsStore } from '../../store/PulseQuestionsStore'
import { Button, FormControlLabel, InputAdornment, MenuItem, Radio, RadioGroup, Select, Switch, TextField, Typography } from '@material-ui/core'
import Icon from '../../../surveys/views/edit/builder/Icon'
import { PulseQuestionType } from '../../types/PulseQuestionType'
import './PulseQuestionTypeRequired.scss'

interface Props {
  pulseQuestionsStore?: PulseQuestionsStore
}

const PulseQuestionTypeRequired: React.FC<Props> = ({ pulseQuestionsStore }) => {
  const vm = pulseQuestionsStore.editVM
  if (!vm) return null

  const renderQuestionTypes = () => {
    return vm.questionTypes.map((type, index) => {
      let icon = ''
      let color = ''
      let label = ''
      if (type === 'emoji') {
        icon = 'far fa-smile'
        color = 'brown'
        label = 'Emoji'
      }
      if (type === 'check') {
        icon = 'far fa-check-square'
        color = 'blue'
        label = 'Multi-select'
      }
      if (type === 'radio') {
        icon = 'far fa-dot-circle'
        color = 'green'
        label = 'Multiple Choice'
      }
      if (type === 'rating') {
        icon = 'fas fa-list-ol'
        color = 'orange'
        label = 'Rating'
      }
      if (type === 'starRating') {
        icon = 'fas fa-star'
        color = 'yellow'
        label = 'Star Rating'
      }
      if (type === 'text') {
        icon = 'fas fa-font'
        color = 'red'
        label = 'Short Answer'
      }
      if (type === 'yesNo') {
        icon = 'far fa-thumbs-up'
        color = 'purple'
        label = 'Yes or No'
      }
      if (type === 'infoText') {
        icon = 'fas fa-info-circle'
        color = 'blue'
        label = 'Info Text'
      }
      if (type === 'number') {
        icon = 'fas fa-calculator'
        color = 'orange'
        label = 'Number'
      }
      if (type === 'order') {
        icon = 'fas fa-sort-amount-up'
        color = 'pink'
        label = 'Order'
      }
      if (type === 'dropdown') {
        icon = 'fas fa-caret-square-down'
        color = 'green'
        label = 'Dropdown'
      }

      return (
        <MenuItem key={index} value={type} className={`type-menu-item ${color}`}
          onClick={e => {
            vm.updateQuestionByType(type as PulseQuestionType)
          }
          }>
          <Icon icon={icon} className='icon' />
          {label}
        </MenuItem>
      )
    })
  }

  const renderQuestionType = () => {
    return (
      <div className='question-type'>
        <div className={'question-type-left'}>
          <Typography className='question-type-header'>Question Type</Typography>
          <Select
            className='type-select'
            variant='outlined'
            disabled={!vm.isSystemAdmin}
            value={vm.questionType}
            onChange={(e) => vm.changeQuestionType(e.target.value)}
          >
            {renderQuestionTypes()}
          </Select>
        </div>
        <div className={'question-type-right'}>
          <Typography className='question-type-required-header'>Required</Typography>
          <Switch checked={vm.required} onChange={() => vm.toggleRequred()} />
        </div>
      </div>
    )
  }

  const renderRatingDisplay = () => {
    if (vm.questionType !== 'rating') return null
    return (
      <div className={'rating-display'}>
        <Typography className={'rating-display-header'}>Rating Display</Typography>
        <RadioGroup value={vm.ratingDisplay} onChange={(e) => vm.setRatingDisplay(e.target.value)}>
          <FormControlLabel value='horizontal' control={<Radio />} label='Horizontal' />
          <FormControlLabel value='vertical' control={<Radio />} label='Vertical' />
        </RadioGroup>
      </div>
    )
  }

  

  return (
    <div id='PulseQuestionTypeRequired'>
      {renderQuestionType()}
      {renderRatingDisplay()}
    </div>
  )
}

export default inject('pulseQuestionsStore')(observer(PulseQuestionTypeRequired))
