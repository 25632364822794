import React from 'react'
import { Card, IconButton, Typography } from '@material-ui/core'
import './ManageSurveysWidget.scss'
import { observer, inject, useLocalStore, Observer } from 'mobx-react'
import { ManageSurveysWidgetVM } from '../../view-models/manage-surveys-widget/ManageSurveysWidgetVM'
import { RootStore } from '../../../stores/RootStore'
import SurveysTab from './SurveysTab'
import FilterPopup from './FilterPopup'
import RemindDialog from './Dialogs/RemindDialog'
import CloseDialog from './Dialogs/CloseDialog'
import CloseIcon from '@material-ui/icons/Close'
import Snackbar from '@material-ui/core/Snackbar'

interface Props {
  rootStore?: RootStore
}

const ManageSurveysWidget: React.FC<Props> = ({ rootStore }) => {
  const localStore = useLocalStore(() => ({
    vm: new ManageSurveysWidgetVM(rootStore),
  }))
  const vm = localStore.vm
  if (!vm.hasSurveyPrivilege) return null

  const renderSnackbar = () => {
    if (!vm.isSnackbarOpen) return null
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={vm.isSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => vm.closeSnackbar()}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<Typography style={{ color: 'black' }}>{vm.snackbarMessage}</Typography>}
        action={[
          <Observer key={'1'}>
            {() => (
              <IconButton key='close' aria-label='close' onClick={() => vm.closeSnackbar()}>
                <CloseIcon />
              </IconButton>
            )}
          </Observer>,
        ]}
      />
    )
  }

  return (
    <Card elevation={0} id='ManageSurveysWidget'>
      <FilterPopup widgetVM={vm} />
      <RemindDialog widgetVM={vm} />
      <CloseDialog widgetVM={vm} />
      {renderSnackbar()}
      <SurveysTab vm={vm.openSurveysTab} />
      <SurveysTab vm={vm.closedSurveysTab} />
      <SurveysTab vm={vm.draftSurveysTab} />
    </Card>
  )
}

export default inject('rootStore')(observer(ManageSurveysWidget))
