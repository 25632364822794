import React, { FC, useRef, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { SurveysStore } from '../../../store/SurveysStore'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded'
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDownRounded'
import TuneRoundedIcon from '@material-ui/icons/TuneRounded'
import DropDownRoundedIcon from '@material-ui/icons/ArrowDropDownCircleRounded'
import Icon from '@material-ui/core/Icon'
import Checkbox from '@material-ui/core/Checkbox'
import { QuestionRowVM } from '../../../view-models/edit/questions-catalog/QuestionRowVM'
import './QuestionRow.scss'
import { Tooltip } from '@material-ui/core'

interface Props {
  surveysStore?: SurveysStore
  question?: QuestionRowVM
  style?: any
  index: number
}

const CatalogQuestion: FC<Props> = ({ surveysStore, question, index, style }) => {

  const renderOptionIcon = () => {
    if (question.questionType === 'text' || question.questionType === 'number') return null
    if (question.questionType === 'radio') return <RadioButtonCheckedIcon className='option-icon' />
    if (question.questionType === 'check') return <CheckBoxRoundedIcon className='option-icon' />
    if (question.questionType === 'dropdown') return <DropDownRoundedIcon className='option-icon' />
  }

  const renderRatingOptions = () => {
    if (question.questionType !== 'rating' && question.questionType !== 'starRating') return
    return (
      <div className='option'>
        <TuneRoundedIcon className='option-icon' />
        <Tooltip
          title={question.options.map((e, index) => (
            <div key={`rating-option-${e.rank}-${e.text}-${index}`}>
              {e.rank}: {e.text}
            </div>
          ))}
          placement='bottom-start'
        >
          <div className='rating-options'>{question.ratingOptionsText}</div>
        </Tooltip>
      </div>
    )
  }

  const renderOrderOptions = () => {
    if (question.questionType !== 'order') return
    return (
      <div className='option'>
        <Icon className='fas fa-sort-amount-up icon-color-pink' />
        <Tooltip
          title={question.options.map((e) => (
            <div>{e.text}</div>
          ))}
          placement='bottom-start'
        >
          <div className='rating-options'>{question.orderOptionsText}</div>
        </Tooltip>
      </div>
    )
  }

  const renderEmojiIcon = (icon) => {
    if (!icon) return <div className='option-icon'>--</div>
    return (
      <img
        className='option-icon'
        src={'../../../../../assets/emoticons/png/' + icon + '.png'}
        alt=''
        style={{ width: 20, height: 20 }}
      />
    )
  }

  const renderYesNoOption = () => {
    if (question.questionType !== 'yesNo') return
    return (
      <div className='option'>
        <ThumbsUpDownIcon className='option-icon' /> Yes / No
      </div>
    )
  }

  const renderNormalOptions = () => {
    if (question.questionType === 'emoji') return
    if (question.questionType === 'yesNo') return
    if (question.questionType === 'rating') return
    if (question.questionType === 'starRating') return
    if (question.questionType === 'order') return
    return question.options.map((option, index) => (
      <div className='option' key={`${option.id}-${index}`}>
        {renderOptionIcon()}
        <Typography>{option.text}</Typography>
      </div>
    ))
  }

  const renderEmojiOptions = () => {
    return question.options.map((e, index) => (
      <div className='option' key={`${e.id}-${index}`}>
        {renderEmojiIcon(e.emoji)}
        <Typography>{e.text}</Typography>
      </div>
    ))
  }

  const renderEmojiSection = () => {
    if (question.questionType !== 'emoji') return
    return (
      <Tooltip
        key={`emoji-tooltip-${question.objectId}`}
        title={question.options.map((e, index) => (
          <div key={`emoji-icon-${e.id}-${index}`}>
            {renderEmojiIcon(e.emoji)}
            <div
              key={`emoji-text-${e.id}-${index}`}
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                marginLeft: '4px',
                marginTop: '-4px',
              }}
            >
              {e.text}
            </div>
          </div>
        ))}
        placement='bottom-start'
      >
        <div key={`emoji-opt-${question.objectId}`}>{renderEmojiOptions()}</div>
      </Tooltip>
    )
  }

  const renderQuestionIcon = () => {
    const type = question.questionType
    if (type === 'emoji') return <Icon className='far fa-smile icon-color-brown' />
    if (type === 'check') return <Icon className='far fa-check-square icon-color-blue' />
    if (type === 'radio') return <Icon className='far fa-dot-circle icon-color-green' />
    if (type === 'rating') return <Icon className='fas fa-list-ol icon-color-orange' />
    if (type === 'starRating') return <Icon className='fas fa-star icon-color-yellow' />
    if (type === 'text') return <Icon className='fas fa-font icon-color-red' />
    if (type === 'yesNo') return <Icon className='far fa-thumbs-up icon-color-purple' />
    if (type === 'number') return <Icon className='fas fa-calculator icon-color-orange' />
    if (type === 'order') return <Icon className='fas fa-sort-amount-up icon-color-pink' />
    if (type === 'dropdown') return <Icon className='fas fa-caret-square-down icon-color-green' />
    return null
  }

  const renderQuestionTitle = () => {
    let title = question.title
    if (question.questionType === 'dropdown') {
      if (question.isDropdownMultiSelect)
        title += ' (multiple)'
      else
        title += ' (single)'
    }
    return (
      <Typography variant='h5' className='title'>
        {title}
      </Typography>
    )
  }

  const isSelected = question.isSelected

  return (
    <Paper
      key={index}
      id='QuestionRow'
      className={
        isSelected
          ? 'sln-catalog-question-' + (index + 1) + ' selected'
          : 'sln-catalog-question-' + (index + 1)
      }
      onClick={() => question.toggle()}
    >
      <div className='question-card-header'>
        <Checkbox
          checked={isSelected}
          name={question.title}
          color='primary'
          className='question-card-header-checkbox'
        />
        <Tooltip title={question.title} placement='bottom-start'>
          {renderQuestionTitle()}
        </Tooltip>
        {renderQuestionIcon()}
      </div>
      <div className='question-card-content'>
        <Tooltip title={question.categoryLabel} placement='bottom-start'>
          <Typography className='category'>{question.categoryLabel}</Typography>
        </Tooltip>
        {renderEmojiSection()}
        {renderRatingOptions()}
        {renderNormalOptions()}
        {renderYesNoOption()}
        {renderOrderOptions()}
      </div>
    </Paper>
  )
}

export default inject('surveysStore')(observer(CatalogQuestion))
