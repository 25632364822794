import React from 'react'
import { inject, observer, useLocalStore } from 'mobx-react'
import rootStore from '../../stores/RootStore'
import { Button, CircularProgress, Grid, IconButton, Typography } from '@material-ui/core'
import { ParentCategoryRowVM } from '../view-models/ParentCategoryRowVM'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ChevronDownIcon from '@material-ui/icons/ExpandMore'
import { ChildCategoryRowVM } from '../view-models/ChildCategoryRowVM'
import { PulseQuestionVM } from '../../pulse-questions/view-models/PulseQuestionVM'
import PulseQuestion from './PulseQuestion'
import EditIcon from '@material-ui/icons/Edit'


interface Props {
  vm: ChildCategoryRowVM
}

const ChildCategoryRow: React.FC<Props> = ({ vm }) => {
  if (!vm) return null

  const renderExpandIcon = () => {
    let icon = <ChevronRightIcon className={'expand-icon'} />
    if (vm.isExpanded) icon = <ChevronDownIcon className={'expand-icon'} />
    return (
      <IconButton
        disableRipple
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          vm.toggleIsExpanded()
        }
        }
        className={'icon-container'}
      >
        {icon}
      </IconButton>
    )
  }

  const renderCard = () => {
    return (
      <Grid onClick={() => vm.toggleIsExpanded()} container className={'child-container'}>
        <Grid className={`child-sub-container border-cat-${vm.index}`} item xs={12}>
          <Grid className={'child-card'} item xs={12}>
            <div className={'child-card-title'}>
              {renderExpandIcon()}
              <Typography className='child-header'>{vm.name}</Typography>
            </div>
            <div>
              <IconButton
                size='small'
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  vm.editPulseCategory()
                }
                }
              >
                <EditIcon />
              </IconButton>
            </div>
          </Grid>
          {renderQuestions()}
        </Grid>
      </Grid>
    )
  }

  const renderQuestions = () => {
    if (!vm.isExpanded) return null
    if (!vm.isLoaded) return renderSpinner()
    const questions = []
    vm.pulseQuestionRows.forEach((q: PulseQuestionVM, i: number) => {
      questions.push(<PulseQuestion key={i} vm={q} />)
    })
    return (
      <>
        <Grid item className={'questions-container'}>
          {questions}
        </Grid>
        <div className={'no-child-container'}>
          {renderNoQuestions()}
          <Button 
          className={'add-question-button'} 
          variant='contained' 
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            vm.createNewPulseQuestion()
          }}>
            Add Question
          </Button>
        </div>
      </>
    )
  }

  const renderSpinner = () => {
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  const renderNoQuestions = () => {
    if (vm.pulseQuestionRows.length) return null
    return <span>No Questions To Show</span>
  }

  return (
    <>
      {renderCard()}
    </>
  )
}

export default inject()(observer(ChildCategoryRow))
