import React from 'react'
import { inject, observer } from 'mobx-react'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardActionArea,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Typography,
  Tooltip,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import BookmarkIcon from '@material-ui/icons/Bookmark'
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder'
import DragIndicatorRoundedIcon from '@material-ui/icons/DragIndicatorRounded'
import EditRoundedIcon from '@material-ui/icons/EditRounded'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import rootStore from '../../stores/RootStore'
import { WorksheetsStore } from '../../worksheets/store/WorksheetsStore'
import { UserWorksheetsStore } from 'src/app/user-worksheets/store/UserWorksheetsStore'
import { ContentVM } from '../view-models/ContentVM'
import { ContentItemVM } from '../view-models/ContentItemVM'
import { Worksheet } from 'src/app/worksheets/aggregate/Worksheet'
import { UserWorksheet } from '../aggregate/UserWorksheet'
import emptyResults from '../../../assets/img/empty-state/empty-state-results.png'
import emptySearch from '../../../assets/img/empty-state/empty-state-search.png'
import '../../css/dashboard-toolbar.scss'
import './Content.scss'

interface Props {
  worksheetsStore?: WorksheetsStore
  userWorksheetsStore?: UserWorksheetsStore
  contentVM?: ContentVM
}

const Content: React.FC<Props> = ({ worksheetsStore, userWorksheetsStore, contentVM }) => {
  const [render, setRender] = React.useState(false)

  const vm = contentVM
  if (!vm) return null

  const listVM = vm.currentWorksheets
  if (!listVM) return null
  if (!vm.shouldRender) return null

  const isInSearch = (val: string) => {
    if (vm && vm.typedFilterText.trim() === '') return true
    return val.toLowerCase().match(new RegExp(vm.typedFilterText.trim(), 'i'))
  }

  const isInBookmarkFilter = (val: boolean) => {
    if (vm && !vm.bookmarkFilterOn) return true
    return val
  }

  const options =
    vm.currentUserWorksheets && vm.currentUserWorksheets.length > 0
      ? vm.currentUserWorksheets.filter((w) => {
          const worksheet = worksheetsStore.getWorksheet(w.worksheetId)
          if (!worksheet) return false
          return (
            vm.hasPermission(worksheet) &&
            worksheet.chartTypeId === vm.chartTypeIdFilter &&
            worksheet.tableauWorkbook !== '' &&
            worksheet.tableauSiteName !== '' &&
            !worksheet.isDeleted &&
            !worksheet.forSurveys &&
            userWorksheetsStore.hasUserWorksheet(worksheet.objectId) &&
            isInBookmarkFilter(w.isBookmark) &&
            isInSearch(worksheet.name)
          )
        })
      : []

  const imageLoaded = (contentItemVM: ContentItemVM) => {
    contentItemVM.handleImageLoad()
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const moved = move(vm.currentUserWorksheets, oldIndex, newIndex) as UserWorksheet[]
    moved.forEach((m, index) => (m.sortOrder = index))
    vm.setSortOrder(moved)
    setRender(!render)
  }

  const move = (arr, old_index, new_index) => {
    while (old_index < 0) {
      old_index += arr.length
    }
    while (new_index < 0) {
      new_index += arr.length
    }
    if (new_index >= arr.length) {
      let k = new_index - arr.length
      while (k-- + 1) {
        arr.push(undefined)
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
    return arr
  }

  const SortableDragIndicator: any = SortableHandle(({}) => (
    <CardHeader
      className={'viz-card-header'}
      avatar={
        <Tooltip
          title={
            vm.typedFilterText.trim() !== ''
              ? rootStore.localizationStore.lzStrings.visualizations.drag_details
              : ''
          }
          placement='bottom-start'
        >
          <span>
            <div id='viz-card-header-drag'>
              <IconButton
                className={
                  vm.typedFilterText.trim() !== ''
                    ? 'viz-drag-handle-disabled'
                    : 'viz-drag-handle-enabled'
                }
                aria-label='drag-handle'
                disabled={true}
              >
                <DragIndicatorRoundedIcon />
              </IconButton>
            </div>
          </span>
        </Tooltip>
      }
    />
  ))

  const SortableItem: any = SortableElement(({ value }) => (
    <div
      id='viz-draggable'
      style={{ zIndex: !vm.isSortingEnabled ? 1 : 1100 }}
      className='viz-draggable-item'
    >
      <div className='viz-item'>{value}</div>
    </div>
  ))

  const SortableList: any = SortableContainer(({ items }) => {
    return (
      <Grid
        container
        className={'viz-sortable-grid'}
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        alignContent='center'
        spacing={3}
      >
        {items
          //.slice(0, vm.itemsVisible)
          .map((value, index) => (
            <SortableItem
              disabled={!vm.isSortingEnabled || vm.typedFilterText.trim() !== ''}
              key={`item-${index}`}
              index={index}
              value={value}
            />
          ))}
      </Grid>
    )
  })

  const renderSortableList = () => {
    return options && options.length > 0 ? (
      <SortableList
        pressDelay={80}
        axis='xy'
        useDragHandle
        items={options
          .sort((a, b) => a.sortOrder - b.sortOrder)
          .map((row, index) => {
            const worksheet = worksheetsStore.getWorksheet(row.worksheetId)
            const existItemVM = vm.getContentItemById(worksheet.objectId)
            const contentItemVM = !existItemVM
              ? new ContentItemVM(rootStore, worksheet, row)
              : existItemVM.update(worksheet, row)
            if (!existItemVM) contentItemVM.setWorksheet(worksheet)
            vm.updateContentItem(contentItemVM)
            return (
              <Grid
                item
                xs={3}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                key={`viz-grid-item-${row.worksheetId}`}
                className={'viz-grid'}
              >
                {renderCard(index, contentItemVM, worksheet)}
              </Grid>
            )
          })}
        onSortEnd={onSortEnd}
      />
    ) : (
      renderEmptyState()
    )
  }

  const renderButton = () => {
    if (!vm.canManageVis) return
    return (
      <>
        <Typography className='viz-empty-text'>
          {rootStore.localizationStore.lzStrings.visualizations.start_now}
        </Typography>
        <Tooltip
          title={rootStore.localizationStore.lzStrings.visualizations.manage_visualizations_tooltip}
          placement='bottom'
        >
          <span>
            <Button
              variant='outlined'
              className='viz-empty-action-btn'
              onClick={() => rootStore.appStore.router.push('/dashboard/worksheets')}
              startIcon={<EditRoundedIcon />}
            >
              {rootStore.localizationStore.lzStrings.visualizations.manage_visualizations}
            </Button>
          </span>
        </Tooltip>
      </>
    )
  }

  const renderEmptyState = () => {
    return (
      <div className={'viz-empty-container'}>
        <div className={'viz-empty-avatar'}>
          <img
            className={'viz-empty-img'}
            alt='Empty'
            src={vm.typedFilterText.trim() === '' ? emptyResults : emptySearch}
          />
        </div>
        <Typography className='viz-empty-header'>
          {rootStore.localizationStore.lzStrings.visualizations.no_visualizations}
        </Typography>
        {vm.typedFilterText.trim() === '' ? (
          vm.canManage ? (
            <div id='VizEmptyText'>
              <Typography className='viz-empty-text'>
                {
                  rootStore.localizationStore.lzStrings.visualizations
                    .no_visualizations_admin_details
                }
              </Typography>
              {renderButton()}
            </div>
          ) : (
            <div id='VizEmptyText'>
              <Typography className='viz-empty-text'>
                {
                  rootStore.localizationStore.lzStrings.visualizations
                    .no_visualizations_regular_details
                }
              </Typography>
              <Typography className='viz-empty-text'>
                {rootStore.localizationStore.lzStrings.visualizations.contact_admin}
              </Typography>
            </div>
          )
        ) : (
          <div id='VizEmptyText'>
            <Typography className='viz-empty-text'>
              {rootStore.localizationStore.lzStrings.visualizations.no_visualizations_search}
            </Typography>
          </div>
        )}
      </div>
    )
  }

  const renderCard = (index: number, contentItemVM: ContentItemVM, row: Worksheet) => {
    return (
      <Card className={'viz-card'}>
        <SortableDragIndicator />
        <CardActionArea>
          <CardContent
            onClick={(e) => {
              e.stopPropagation()
              contentItemVM.loadTableauAuthToken()
              vm.setSelectedItemVM(contentItemVM)
              vm.setOpen(true)
              rootStore.appStore.router.push(`/userWorksheets/report/${vm.selectedItemVM.id}`)
            }}
          >
            <Grid container className={'viz-card-content'}>
              <Grid item xs={12} className={'viz-card-media'} id={`${index}-images`}>
                <div>
                  <Box className={'media-box'} display='flex' alignItems='center'>
                    <div id='viz-card-container'>
                      <div id='navi'>
                        <Skeleton
                          className={contentItemVM.loading ? 'skeleton' : 'media-off'}
                          animation={contentItemVM.loading ? 'wave' : false}
                          height={vm.mediaHeight}
                          width='100%'
                        />
                      </div>
                      <div id='infoi'>
                        <div className={'skeleton-chart-container'}>
                          <Skeleton
                            className={contentItemVM.loading ? 'skeleton-chart-bar' : 'media-off'}
                            animation={contentItemVM.loading ? 'wave' : false}
                            variant='rect'
                            width='20px'
                            height='5%'
                          />
                          <Skeleton
                            className={contentItemVM.loading ? 'skeleton-chart-bar' : 'media-off'}
                            animation={contentItemVM.loading ? 'wave' : false}
                            variant='rect'
                            width='20px'
                            height='15%'
                          />
                          <Skeleton
                            className={contentItemVM.loading ? 'skeleton-chart-bar' : 'media-off'}
                            animation={contentItemVM.loading ? 'wave' : false}
                            variant='rect'
                            width='20px'
                            height='35%'
                          />
                          <Skeleton
                            className={contentItemVM.loading ? 'skeleton-chart-bar' : 'media-off'}
                            animation={contentItemVM.loading ? 'wave' : false}
                            variant='rect'
                            width='20px'
                            height='55%'
                          />
                          <Skeleton
                            className={contentItemVM.loading ? 'skeleton-chart-bar' : 'media-off'}
                            animation={contentItemVM.loading ? 'wave' : false}
                            variant='rect'
                            width='20px'
                            height='75%'
                          />
                          <Skeleton
                            className={contentItemVM.loading ? 'skeleton-chart-bar' : 'media-off'}
                            animation={contentItemVM.loading ? 'wave' : false}
                            variant='rect'
                            width='20px'
                            height='95%'
                          />
                        </div>
                      </div>
                    </div>

                    <img
                      id={`img-${contentItemVM.name}-${contentItemVM.id}`}
                      className={contentItemVM.loading ? 'media-off' : 'media'}
                      src={
                        contentItemVM.thumbNail
                          ? `data:image/jpeg;charset=utf-8;base64,${contentItemVM.thumbNail}`
                          : ''
                      }
                      alt={''}
                      onLoad={() => imageLoaded(contentItemVM)}
                    />
                  </Box>
                </div>
              </Grid>
            </Grid>
          </CardContent>
          <Divider variant='fullWidth' />
        </CardActionArea>
        {vm.isBookmarkEnabled ? (
          <CardActions disableSpacing>
            <Tooltip
              title={rootStore.localizationStore.lzStrings.visualizations.bookmark}
              placement='bottom-start'
            >
              <span>
                <IconButton
                  className={'checked-container'}
                  edge='start'
                  color='inherit'
                  onClick={(e) => {
                    e.stopPropagation()
                    contentItemVM.setBookmark(!contentItemVM.isBookmarked)
                  }}
                  aria-label='close'
                >
                  {contentItemVM && contentItemVM.isBookmarked ? (
                    <BookmarkIcon className='checked-icon' />
                  ) : (
                    <BookmarkBorderIcon />
                  )}
                </IconButton>
              </span>
            </Tooltip>
            <Typography className={'viz-card-body-text-bold'} noWrap gutterBottom>
              {contentItemVM.name}
            </Typography>
          </CardActions>
        ) : (
          <div className='card-action-container'>
            <CardActions
              disableSpacing
              onClick={(e) => {
                e.stopPropagation()
                contentItemVM.loadTableauAuthToken()
                vm.setSelectedItemVM(contentItemVM)
                vm.setOpen(true)
                rootStore.appStore.router.push(`/userWorksheets/report/${vm.selectedItemVM.id}`)
              }}
            >
              <Typography className={'viz-card-body-text-bold'} noWrap gutterBottom>
                {contentItemVM.name}
              </Typography>
            </CardActions>
          </div>
        )}
      </Card>
    )
  }

  const renderGrid = () => {
    return <div id='SortableGrid'>{renderSortableList()}</div>
  }

  return <>{renderGrid()}</>
}

export default inject('worksheetsStore')(observer(Content))
