import Parse from 'parse'
import { observable, computed, action, reaction, IReactionDisposer } from 'mobx'
import rootStore, { RootStore } from '../../stores/RootStore'
import { DataStore } from '../../shared/data/DataStore'
import { IEmailTemplateDTO } from '../dtos/IEmailTemplateDTO'
import { EmailTemplateVM } from '../view-models/EmailTemplateVM'
import { EmailTemplate } from '../aggregate/EmailTemplate'
import { EmailTemplatesService } from '../service/EmailTemplatesService'
import { IEmailTemplatesListRequest } from '../interfaces/IEmailTemplatesListRequest'
import { EmailTemplatesViewModelsService } from '../service/EmailTemplatesViewModelsService'
import { IEmailTemplatesUpdateRequest } from '../interfaces/IEmailTemplatesUpdateRequest'
import { IEmailTemplatesDeleteRequest } from '../interfaces/IEmailTemplatesDeleteRequest'

export class EmailTemplatesStore extends DataStore<EmailTemplate, IEmailTemplateDTO> {
  private request: IEmailTemplatesListRequest
  private svc: EmailTemplatesService

  @observable public viewModels: EmailTemplatesViewModelsService = undefined

  constructor(rootStore: RootStore, request: IEmailTemplatesListRequest) {
    super(rootStore, EmailTemplate, 'emailTemplates', [
      'objectId',
      'organizationId',
      'sgTemplateId',
      'sgTemplateVersionId',
      'sgTemplateTestVersionId',
      'name',
      'html',
      'test_data',
      'isDefault',
      'isDeleted',
      'notificationTypeId',
      'thumbnailUrl',
      'subject',
      'parentId',
    ])
    this.request = request
    this.paged = true
    this.recordsPerPage = 100
    this.pageNumber = 1
    this.sortDirection = 'asc'
    this.sortColumnName = 'name'
    this.viewModels = new EmailTemplatesViewModelsService(rootStore, this)
    this.listenToListRecordsViaApiUpdates = false
    this.svc = new EmailTemplatesService()
  }

  @observable public loaded: boolean = false

  @computed
  public get emailTemplates(): EmailTemplate[] {
    return this.records
  }

  @computed
  public get emailTemplateVMs(): EmailTemplateVM[] {
    return this.records.map((r) => new EmailTemplateVM(this.rootStore, r))
  }

  protected getBaseQuery() {
    const queryMain = new Parse.Query(this.className)
    queryMain.equalTo('organizationId', rootStore.appStore.currentOrgId)
    queryMain.notEqualTo('isDeleted', true)

    const queryDefault = new Parse.Query(this.className)
    queryDefault.equalTo('organizationId', 'DEFAULT')
    queryDefault.notEqualTo('isDeleted', true)
    return Parse.Query.or(queryMain, queryDefault)
  }

  public setRequest(request: IEmailTemplatesListRequest) {
    this.request = request
  }

  public getRequest(): IEmailTemplatesListRequest {
    return this.request
  }

  protected getListRecordsApiCall() {
    return async () => {
      const req: IEmailTemplatesListRequest = {
        ...this.request,
        userId: rootStore.appStore.currentUserId,
        organizationId: rootStore.appStore.currentOrgId,
        terms: this.filter,
        skip: this.recordsPerPage * (this.pageNumber - 1),
        limit: this.recordsPerPage,
        sortColumnName: this.sortColumnName,
        sortDirection: this.sortDirection,
        listColumns: this.listColumns,
        isDeleted: false,
      }
      const result = await this.svc.getEmailTemplatesList(req, rootStore.appStore.currentOrgId)
      this.totalRecords = result.count
      this.listRecordsQuery = this.getBaseQuery()
      this.listRecordsQuery.select(...this.listColumns)
      this.records = this.records.slice()

      return result.emailTemplates
    }
  }

  public onListRecordsLoaded() {
    this.loaded = true
  }

  @computed
  public get allEmailTemplates(): EmailTemplate[] {
    return this.records.filter((e) => e.organizationId === this.rootStore.appStore.currentOrgId || e.organizationId === 'DEFAULT')
  }

  @computed
  public get sysEmailTemplates(): EmailTemplate[] {
    const defaultTemplates: EmailTemplate[] = []
    for (const result of this.allEmailTemplates) {
      if (result.isDefault && result.organizationId === 'DEFAULT') defaultTemplates.push(result)
    }
    return defaultTemplates
  }

  @computed
  public get orgEmailTemplates(): EmailTemplate[] {
    const orgTemplates: EmailTemplate[] = []
    const defaultTemplates: EmailTemplate[] = []
    for (const result of this.allEmailTemplates) {
      if (result.notificationTypeId === 'DEFAULT' && result.organizationId === 'DEFAULT') {
        defaultTemplates.push(result)
        continue
      }
      if (result.isDefault && result.organizationId === 'DEFAULT') defaultTemplates.push(result)
      else orgTemplates.push(result)
    }

    for (const orgTemplate of orgTemplates) {
      let found = defaultTemplates.find(
        defT => defT.notificationTypeId === orgTemplate.notificationTypeId
      )
      if (!found) defaultTemplates.push(orgTemplate)
    }

    const activeOrgEmailTemplates = defaultTemplates.map(t => {
      const foundTemplates = orgTemplates.filter(orgT => orgT.parentId === t.objectId)
      let template = foundTemplates.find(orgT => orgT.parentId === t.objectId)
      if (!template) template = t
      return template
    })
    return activeOrgEmailTemplates
  }

    public getCurrentOrgEmailTemplateByNotificationTypeId(
      orgId: string,
      notificationTypeId: string
    ): IEmailTemplateDTO {
      return this.allEmailTemplates.find(
        (t) => t.notificationTypeId === notificationTypeId && t.organizationId === orgId
      )
    }

  public getEmailTemplate(objectId: string): EmailTemplate {
    return this.getRecord(objectId)
  }

  public async getSystemDefaultEmailTemplate(): Promise<EmailTemplate> {
    const query = new Parse.Query(this.className)
    query.equalTo('organizationId', 'DEFAULT')
    query.equalTo('notificationTypeId', 'DEFAULT')
    query.notEqualTo('isDefault', true)
    query.notEqualTo('isDeleted', false)
    const result = await query.first()
    const rec = this.getRecord(result.id)
    return rec
  }

  public initOrgEmailTemplate(id: string) {
    const emailTemplate = this.emailTemplates.filter(
      (emailTemplate) => emailTemplate.objectId === id
    )[0]
    if (!emailTemplate) return
    this.viewModels.previewVM.loadData(emailTemplate, false)
    this.viewModels.previewVM.isOpen = true
  }

  public initSysEmailTemplate(id: string) {
    const emailTemplate = this.emailTemplates.filter(
      (emailTemplate) => emailTemplate.objectId === id
    )[0]
    if (!emailTemplate) return
    this.viewModels.previewVM.loadData(emailTemplate, true)
    this.viewModels.previewVM.isOpen = true
  }

  public async revertEmailTemplate(id: string) {  
    this.viewModels.manageVM.isLoading = true

    const defaultTemplate = await this.svc.getSystemDefaultEmailTemplateVersion(
      { template_id: '', version_id: '' },
      this.rootStore.appStore.currentOrgId
    )
    const emailTemplate = this.emailTemplates.filter(
      (emailTemplate) => emailTemplate.objectId === id
    )[0]
    if (!emailTemplate) return
    const req = {
      objectId: emailTemplate.objectId,
      template_id: emailTemplate.sgTemplateId,
      version_id: emailTemplate.sgTemplateVersionId,
      name: emailTemplate.name,
      html_content: defaultTemplate.html_content,
      generate_plain_content: true,
      subject: emailTemplate.subject,
      test_data: emailTemplate.test_data,
      notificationTypeId: emailTemplate.notificationTypeId,
    } as IEmailTemplatesUpdateRequest
    const resp = await this.svc.revertEmailTemplate(req, this.rootStore.appStore.currentOrgId)
    this.viewModels.manageVM.isLoading = false
  }

  public async deleteEmailTemplate(id: string) {
    this.viewModels.manageVM.isLoading = true

    const emailTemplate = this.emailTemplates.filter(
      (emailTemplate) => emailTemplate.objectId === id
    )[0]
    if (!emailTemplate) return
    const req = {
      objectId: emailTemplate.objectId,
      template_id: emailTemplate.sgTemplateId,
    } as IEmailTemplatesDeleteRequest
    const resp = await this.svc.deleteEmailTemplate(req, this.rootStore.appStore.currentOrgId)
    this.viewModels.manageVM.isLoading = false
  }

  public onRecordUpdated(obj: EmailTemplate) {
    return this.loadListRecords()
  }

  public async refreshEmailTemplates() {
    await this.loadListRecords()
  }

  public async updateEmailTemplate(obj: Parse.Object) {
    const serverObj = obj.toJSON()
    const template = this.getEmailTemplate(obj.id)
    if (template) template.updateFromServer(serverObj)
  }

  protected getFullColumns() {
    return [
      'objectId',
      'organizationId',
      'sgTemplateId',
      'sgTemplateVersionId',
      'sgTemplateTestVersionId',
      'name',
      'html',
      'test_data',
      'isDefault',
      'isDeleted',
      'notificationTypeId',
      'thumbnailUrl',
      'subject',
      'parentId',
    ]
  }
}
