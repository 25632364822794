import React from 'react'
import { inject, observer } from 'mobx-react'
import { PulseCampaignsStore } from '../../store/PulseCampaignsStore'
import { Card, Grid, TextField, Typography } from '@material-ui/core'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import './PulseSurveyCard.scss'

interface Props {
  pulseCampaignsStore?: PulseCampaignsStore
  index: number
  survey: any
  error: boolean
  disabled: boolean
  hideDragandDrop?: boolean
}

const PulseSurveyCard: React.FC<Props> = ({
  pulseCampaignsStore,
  index,
  survey,
  error,
  disabled,
  hideDragandDrop,
}) => {
  const { editVM } = pulseCampaignsStore.viewModels
  if (!editVM) return null

  const cardClass = () => {
    if (hideDragandDrop && !error) return ''
    if (hideDragandDrop && error) return 'pu-error'
    if (error && !disabled) return 'pu-error pu-grab'
    if (error) return 'pu-error'
    if (!disabled) return 'pu-grab'
  }

  const renderCard = () => {
    const renderDragIndicator = () => {
      if (!hideDragandDrop) {
        return (
          <>
            <DragIndicatorIcon className={disabled ? 'drag-icon disabled' : 'drag-icon'} />
          </>
        )
      }
      return null
    }
    return (
      <Grid container>
        <Card id='PulseSurveyCard' className={cardClass()}>
          {renderDragIndicator()}
          <div className={disabled ? 'pulse-name disabled' : 'pulse-name'}>
            <TextField
              required
              fullWidth
              autoComplete='off'
              disabled={disabled}
              value={survey.name}
              placeholder='(Untitled Pulse)'
              onChange={(e) => editVM.setSurveyName(index, e.target.value)}
              error={error}
              className=''
              variant='standard'
              InputProps={{
                disableUnderline: true,
              }}
            />
          </div>
        </Card>
      </Grid>
    )
  }

  return <>{renderCard()}</>
}

export default inject('pulseCampaignsStore')(observer(PulseSurveyCard))
