import React, { FC } from 'react'
import { observer, inject } from 'mobx-react'
import './SurveyOptions.scss'
import { SurveysStore } from '../../../../surveys/store/SurveysStore'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { Switch, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { LabelsStore } from '../../../../labels/store/LabelsStore'
import { Worksheet } from '../../../../worksheets/aggregate/Worksheet'

interface Props {
  surveysStore?: SurveysStore
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
}

const Advanced: FC<Props> = ({ surveysStore, localizationStore, labelsStore }) => {
  const surveyEditVM = surveysStore.viewModels.editVM
  const vm = surveyEditVM.surveyOptionsVM
  if (!surveyEditVM) return null
  if (!vm) return null
  const s = localizationStore.lzStrings.surveys

  const renderWorksheet = () => {
    if (surveyEditVM.isSurveyTemplateEdit && surveyEditVM.survey.organizationId === 'DEFAULT') return null
    return (
      <>
        <div className='primary-text'>{s.worksheet}</div>
        <div className='section-content'>
          <Autocomplete
            disabled={surveyEditVM.isReadOnly}
            options={surveyEditVM.worksheets}
            getOptionLabel={(worksheet) => worksheet.name}
            value={surveyEditVM.worksheet || { name: '' }}
            onChange={(e, worksheet: Worksheet) =>
              surveyEditVM.setWorksheet(worksheet && worksheet.objectId ? worksheet.objectId : '')
            }
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder='Select Worksheet'
                variant='outlined'
                className='publisher-select'
                style={{ width: 200 }}
              />
            )}
          />
        </div>
      </>
    )
  }
  return (
    <>
      <div className='section'>
        <div className='primary-text'>{`${labelsStore.getLabelByLanguageAndFor('pulse')} ${
          s.links_never_expire
        }`}</div>
        <div className='section-content'>
          <Switch
            color='primary'
            disabled={surveyEditVM.isTemplateDisabled || surveyEditVM.isReadOnly}
            checked={surveyEditVM.linksNeverExpire}
            onChange={() => surveyEditVM.toggleLinksNeverExpire()}
          />
        </div>
      </div>
      <div>{renderWorksheet()}</div>
    </>
  )
}

export default inject('surveysStore', 'localizationStore', 'labelsStore')(observer(Advanced))
