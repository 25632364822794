import { action, computed, observable } from 'mobx'
import { IPulseCategoryDTO } from '../../pulse-categories/dtos/IPulseCategoryDTO'
import { RootStore } from '../../stores/RootStore'
import PulseCategory from '../aggregate/PulseCategory'
import { IPulseCategoriesFindRequest } from '../interfaces/IPulseCategoriesFindRequest'
import { PulseCategoriesService } from '../services/PulseCategoriesService'
import { ChildCategoryRowVM } from './ChildCategoryRowVM'
import { PulseCategoriesDataStore } from './PulseCategoriesDataStore'
import { PulseCategoriesManageVM } from './PulseCategoriesManageVM'
import { PulseCategoryEditVM } from './PulseCategoryEditVM'


export class ParentCategoryRowVM {
  private rootStore: RootStore
  private dataStore: PulseCategoriesDataStore

  constructor(rootStore: RootStore, manageVM: PulseCategoriesManageVM, category: IPulseCategoryDTO,) {
    this.rootStore = rootStore
    this.category = category
    this.manageVM = manageVM
    this.dataStore = new PulseCategoriesDataStore(this.rootStore, { grandCategoryId: this.grandParentPulseCategoryId, parentCategoryId: this.objectId } as IPulseCategoriesFindRequest)
  }

  @observable public category: IPulseCategoryDTO = null
  @observable public isExpanded: boolean = false
  @observable public childPulseCategories: IPulseCategoryDTO[] = []
  @observable public manageVM: PulseCategoriesManageVM
  @observable public index: number = 0

  @computed
  public get isLoaded(): boolean {
    return this.dataStore.isLoaded
  }

  @computed
  public get name(): string {
    return this.category.name
  }

  @computed
  public get objectId(): string {
    return this.category.objectId
  }

  @computed
  public get grandParentPulseCategoryId(): string {
    return this.category.grandCategoryId
  }

  @action
  public toggleIsExpanded() {
    this.isExpanded = !this.isExpanded
    if (this.isExpanded) this.getChildPulseCategories()
  }

  @action
  public async getChildPulseCategories() {
    this.dataStore.loadListRecords()
  }

  @computed
  public get childPulseCategoryRows(): ChildCategoryRowVM[] {
    return this.dataStore.rows.map((c) => {
      const child = new ChildCategoryRowVM(this.rootStore, this.manageVM, c)
      child.setIndex(this.index)
      return child
    })
  }

  @action
  public setIndex(index: number) {
    this.index = index
  }

  @action
  public createNewPulseCategory() {
    this.rootStore.pulseCategoriesStore.viewModels.editVM = new PulseCategoryEditVM(this.rootStore, PulseCategory.create(this.grandParentPulseCategoryId, this.objectId))
  }

  @action
  public editPulseCategory() {
    this.rootStore.pulseCategoriesStore.viewModels.loadPulseCategoryEditVM(this.objectId)
  }

}
