import { action, computed, observable, reaction } from 'mobx'
import { PasswordStrengthMeterVM } from '../../shared/password-strength/PasswordStrengthMeterVM'
import { RootStore } from '../../stores/RootStore'
import { IUserPasswordResetRequest } from '../interfaces/IUserPasswordResetRequest'
import { AuthenticationService } from '../services/AuthenticationService'

export class PasswordResetVM {
  constructor(rootStore: RootStore, token: string) {
    this.rootStore = rootStore
    this.token = token
    this.meterVM = new PasswordStrengthMeterVM(rootStore.localizationStore)
    const url = window.location.href
    if (url.includes('expired')) {
      this.isExpiredPasswordReset = true
    }

    reaction(
      () => this.newPassword,
      () => {
        this.meterVM.setPassword(this.newPassword)
      }
    )
  }

  private rootStore: RootStore
  @observable public newPassword: string = ''
  @observable public confirmNewPassword: string = ''
  @observable public meterVM: PasswordStrengthMeterVM = null
  @observable public saveTried: boolean = false
  @observable public isProcessing: boolean = false
  @observable public showNewPassword: boolean = false
  @observable public showConfirmNewPassword: boolean = false
  @observable public token: string
  @observable public errorMessage: boolean = false
  @observable isExpiredPasswordReset: boolean = false

  public get newPasswordValid() {
    if (!this.saveTried) return true
    if (!this.newPassword || this.newPassword.trim() === '') return false
    if (!this.isNewPasswordStrong) return false
    return true
  }

  public get confirmNewPasswordValid() {
    if (!this.saveTried) return true
    if (!this.confirmNewPassword || this.confirmNewPassword.trim() === '') return false
    return true
  }

  public get newPasswordsMatch() {
    if (!this.saveTried) return true
    if (this.newPassword !== this.confirmNewPassword) return false
    return true
  }

  @computed
  public get isNewPasswordStrong(): boolean {
    if (!this.saveTried) return true
    if (!this.newPassword || this.newPassword.trim() === '') return true
    if (this.meterVM.newPasswordStrengthScore < 5) return false
    return true
  }

  @action
  public goToLogin() {
    if (!this.rootStore.appStore.router) {
      window.location.href = '/'
      return
    }
    this.rootStore.appStore.router.push('/auth/login')
  }

  @action
  public markSaveTried() {
    this.saveTried = true
  }

  @computed
  public get isValid() {
    if (!this.saveTried) return true
    if (!this.newPasswordValid) return false
    if (!this.confirmNewPasswordValid) return false
    return true
  }

  @action handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault()
    this.markSaveTried()
    if (!this.isValid) return
    if (!this.newPasswordsMatch) return
    if (!this.isNewPasswordStrong) return
    this.errorMessage = false
    this.isProcessing = true
    try {
      const req: IUserPasswordResetRequest = {
        token: this.token,
        password: this.newPassword,
      }
      const authSvc = new AuthenticationService()
      const result = await authSvc.passwordResetViaToken(req)
      if (result.success === true) {
        this.goToLogin()
      } else if (result.success === false) {
        this.errorMessage = true
        this.isProcessing = false
      }
    } catch (error) {
      console.log(error)
    }
  }

  @action
  public setPasswordMeterShown(isPasswordMeterShown: boolean) {
    if (this.newPassword) return
    this.meterVM.setPasswordMeterShown(isPasswordMeterShown)
  }

  @action
  public toggleShowNewPassword() {
    this.showNewPassword = !this.showNewPassword
  }

  @action
  public toggleShowConfirmNewPassword() {
    this.showConfirmNewPassword = !this.showConfirmNewPassword
  }
}
