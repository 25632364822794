import React from 'react'
import SurveyResultsArea from './SurveyResultsArea'

export default class SurveyResultsScreen extends React.Component<any, any> {
  render() {
    return (
      <SurveyResultsArea
        userSurveyId={this.props.params.userSurveyId}
        surveyId={this.props.params.surveyId}
        anonymousToken={this.props.params.anonymousToken}
      />
    )
  }
}
