import React from 'react'
import { useLocalStore } from 'mobx-react'
import rootStore from '../../stores/RootStore'
import WidgetHeader from './WidgetHeader'
import WidgetContent from './WidgetContent'
import { Card } from '@material-ui/core'
import NotificationsWidgetDrawer from './Drawer'
import { NotificationsWidgetVM } from '../store/view-models/NotificationsWidgetVM'
import './NotificationsWidget.scss'

const NotificationsWidget: React.FC = () => {

  const vm = new NotificationsWidgetVM(rootStore)
  vm.isSystemOnly = false
  vm.channels = ['PUSH', 'TEXT']
  const localStore = useLocalStore(() => ({
    vm: vm,
  }))

  return (
    <>
      <Card elevation={0} id='NotificationsWidget'>
        <WidgetHeader vm={localStore.vm} />
        <WidgetContent vm={localStore.vm} />
      </Card>
      <NotificationsWidgetDrawer vm={localStore.vm} />
    </>
  )
}

export default NotificationsWidget
