import React from 'react'
import WidgetHeader from './components/WidgetHeader'
import WidgetContent from './components/WidgetContent'
import ContactDrawer from './components/ContactDrawer'
import { Card } from '@material-ui/core'
import './ContactListWidget.scss'

const ContactListWidget: React.FC = () => {
  return (
    <>
      <Card id='ContactListWidget' elevation={0}>
        <WidgetHeader />
        <WidgetContent />
      </Card>
      <ContactDrawer />
    </>
  )
}

export default ContactListWidget
