import React from 'react'
import { observer } from 'mobx-react'
import { inject } from 'mobx-react'
import { SurveysStore } from '../../../store/SurveysStore'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { Card, IconButton } from '@material-ui/core'
import './AdditionalResponseNotificationEditor.scss'
import Avatar from '../../../../shared/Avatar'
import styles from '../../../../shared/Avatar.scss'
import CloseIcon from '@material-ui/icons/Close'
import AddIcon from '@material-ui/icons/Add'
import { LabelsStore } from '../../../../labels/store/LabelsStore'
import AdditionalResponseNotificationParticipantSelect from './AdditionalResponseNotificationParticipantSelect'
import { AdditionalResponseNotificationParticipantVM } from '../../../view-models/AdditionalResponseNotificationParticipantVM'

interface Props {
  localizationStore?: LocalizationStore
  surveysStore?: SurveysStore
  labelsStore?: LabelsStore
}

const AdditionalResponseNotificationEditor: React.FC<Props> = ({
  surveysStore,
  localizationStore,
  labelsStore,
}) => {
  const { editVM } = surveysStore.viewModels
  const additionalResponseNotificationVM = editVM.additionalResponseNotificationEditorVM
  if (!additionalResponseNotificationVM) return null
  const s = localizationStore.lzStrings.surveys

  const handleSelectPeopleClick = () => {
    additionalResponseNotificationVM.toggleShowParticipantSelect()
  }

  const renderSpecificUsers = () => {
    return (
      <>
        <div className='reno-participants-container'>
          <Card
            className='reno-participant-card select-people'
            onClick={() => handleSelectPeopleClick()}
          >
            <div className='reno-participant-card-left'>
              <div className='fake-avatar-gray'>
                <AddIcon className='reno-add-participant-button-icon' />
              </div>
            </div>
            <div className='reno-participant-card-right'>
              <div>
                <div className='name'>Select User(s)...</div>
              </div>
            </div>
          </Card>
          {additionalResponseNotificationVM.selectedParticipants.map(
            (participant: AdditionalResponseNotificationParticipantVM, index) => {
              return (
                <div className='reno-participant-row'>
                  <Card className='reno-participant-card' key={participant.name}>
                    <div className='reno-participant-card-left'>
                      <Avatar
                        user={{
                          name: participant.name,
                          iconURL: participant.iconURL,
                          objectId: participant.objectId,
                        }}
                        size={35}
                        style={{ marginLeft: styles.marginLeft, fontSize: styles.fontSize }}
                        isArchived={participant.isArchived}
                      />
                    </div>
                    <div className='reno-participant-card-right'>
                      <div>
                        <span
                          style={
                            participant.isArchived ? { fontStyle: styles.archivedFontStyle } : {}
                          }
                        >
                          {participant.name}
                        </span>
                        <div className='type'>{participant.type}</div>
                      </div>
                      <div>
                        <IconButton
                          onClick={() =>
                            additionalResponseNotificationVM.removeParticipantById(participant.id)
                          }
                          size='small'
                        >
                          <CloseIcon className='reno-participant-card-close-icon' />
                        </IconButton>
                      </div>
                    </div>
                  </Card>
                </div>
              )
            }
          )}
        </div>
      </>
    )
  }

  return (
    <div id='ResponseNotificationEditor'>
      <AdditionalResponseNotificationParticipantSelect />
      {renderSpecificUsers()}
    </div>
  )
}

export default inject(
  'surveysStore',
  'localizationStore',
  'labelsStore'
)(observer(AdditionalResponseNotificationEditor))
