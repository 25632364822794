import React from 'react'
import { Route, IndexRoute } from 'react-router'
import Layout from '../../layout/Layout'
import { authenticate } from '../../auth/routes/AuthenticatedRoute'
import CompletedSurveyResponseScreen from '../../survey-responses/views/CompletedSurveyResponseScreen'
import PADemoScreen from '../views/PADemoScreen'

export default function getPADemoRoutes() {
  return (
    <Route key={'sr0'} path='pademo' component={authenticate(Layout)}>
      <IndexRoute onEnter={(nextState, replace) => replace('/pademo/index')} />
      <Route key={'sr1'} path='index' component={PADemoScreen} />
    </Route>
  )
}
