import { RootStore } from '../../stores/RootStore'
import { CompletedUserTasksWidgetVM } from './CompletedUserTasksWidgetVM'
import { UserTask } from '../aggregates/UserTask'
import { computed, action } from 'mobx'
import { Survey } from '../../surveys/aggregate/Survey'
import { Task } from '../../tasks/aggregates/Task'
import { Category } from '../../categories/aggregate/Category'
import moment from 'moment'
import { AudienceMember } from '../../audience-members/aggregate/AudienceMember'
import { AGGridRowVM } from '../../shared/ag-grid/AGGridRowVM'

export class CompletedUserTaskRowVM extends AGGridRowVM<UserTask> {
  constructor(rootStore: RootStore, listVM: CompletedUserTasksWidgetVM, userTask: UserTask) {
    super(rootStore, userTask, listVM)
  }

  @computed
  public get hasSurvey(): boolean {
    return Boolean(this.aggregate.surveyId)
  }

  @computed
  public get survey(): Survey {
    return this.rootStore.surveysStore.getSurvey(this.aggregate.surveyId)
  }

  @computed
  public get objectId(): string {
    return this.aggregate.objectId
  }

  @computed
  public get taskName(): string {
    if (!this.parentTask) return ''
    return this.parentTask.name
  }

  @computed
  public get disciplineName(): string {
    if (!this.discipline) return ''
    return this.discipline.name
  }

  @computed
  public get dueDateFormatted(): string {
    const s = this.rootStore.localizationStore.lzStrings.completedUserTasksWidget
    if (!this.parentTask) return ''
    return moment.unix(this.parentTask.dueDate).format(s.due_date_format)
  }

  @computed
  public get assignedByUserId(): string {
    if (!this.assignedByUser) return ''
    return this.assignedByUser.objectId
  }

  @computed
  public get assignedByUserName(): string {
    if (!this.assignedByUser) return ''
    return this.assignedByUser.name
  }

  @computed
  public get assignedByUserIconUrl(): string {
    if (!this.assignedByUser) return ''
    return this.assignedByUser.iconURL
  }

  @computed
  public get hasTask(): boolean {
    return this.parentTask !== undefined
  }

  @computed
  private get parentTask(): Task {
    return this.rootStore.tasksStore.tasks.find((e) => e.objectId === this.aggregate.taskId)
  }

  @computed
  public get assignedByUser(): AudienceMember {
    if (!this.parentTask) return null
    return this.rootStore.audienceMembersStore.getUser(this.parentTask.assignedByUserId)
  }

  @computed
  private get discipline(): Category {
    if (!this.parentTask) return null
    return this.rootStore.categoriesStore.getCategory(this.parentTask.categoryId)
  }

  @computed
  public get feedbackSurveyStatus(): string {
    if (!this.survey) return 'Not Sent'
    if (!this.survey.published) return 'Not Sent'
    if (!this.survey.lastResponseReceived) return 'Pending Response'
    if (this.survey.lastResponseReceived) return 'Complete'
    return ''
  }

  @action
  public openUserTask() {
    this.rootStore.userTasksStore.loadEditVM(this.objectId)
  }
}
