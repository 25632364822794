import { RootStore } from '../../../stores/RootStore'
import { action, observable } from 'mobx'
import { OrganizationUserEditVM } from './OrganizationUserEditVM'
import * as Sentry from '@sentry/browser'
import { OrganizationUsersService } from '../../service/OrganizationUsersService'

export class UserBecomeDialogVM {
  private rootStore: RootStore
  private editVM: OrganizationUserEditVM

  constructor(rootStore: RootStore, editVM: OrganizationUserEditVM) {
    this.rootStore = rootStore
    this.editVM = editVM
  }

  @observable public shown: boolean = false
  @observable public isProcessing: boolean = false
  @observable public sessionToken: string = ''

  @action
  public show() {
    this.shown = true
  }

  @action
  public hide() {
    this.shown = false
  }

  @action
  public async ok() {
    this.isProcessing = true
    const svc = new OrganizationUsersService(this.rootStore)
    try {
      await svc.impersonateUser(this.editVM.userId)
      this.hide()
    } catch (e) {
      alert('An unknown has error occurred.')
      this.isProcessing = false
      Sentry.captureException(e)
    }
  }

  @action
  public cancel() {
    this.hide()
  }

  @action
  public setSessionToken(val) {
    this.sessionToken = val
  }
}
