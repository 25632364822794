import { computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Media } from '../../surveys/aggregate/Media'
import { MediaItemVM } from './MediaItemVM'

export class OldMediaItemVM extends MediaItemVM {
  private youTubeUrl: string
  private vimeoUrl: string

  constructor(rootStore: RootStore, media: Media, youTubeUrl?: string, vimeoUrl?: string) {
    super(rootStore, media)
    this.youTubeUrl = youTubeUrl
    this.vimeoUrl = vimeoUrl
  }

  @computed
  public get isLoaded() {
    return true
  }

  @computed
  public get type(): string {
    if (!this.media) return undefined
    return this.media.type
  }

  @computed
  public get path(): string {
    if (!this.media) return undefined
    return this.media.path
  }

  @computed
  public get hasMedia(): boolean {
    if (!this.media) return undefined
    return this.media.path !== ''
  }

  @computed
  public get hasYouTubeURL(): boolean {
    if (this.youTubeUrl) return true
    if (!this.path) return false
    const url = this.path.toLowerCase()
    return url.includes('youtube') || url.includes('youtu.be')
  }

  @computed
  public get hasVimeoURL(): boolean {
    if (this.vimeoUrl) return true
    if (!this.path) return false
    const url = this.path.toLowerCase()
    return url.includes('vimeo')
  }

  @computed
  public get isImage(): boolean {
    if (!this.media) return undefined
    return this.media.type === 'image'
  }

  @computed
  public get isVideo(): boolean {
    if (!this.media) return undefined
    return this.media.type === 'video'
  }

  @computed
  public get mediaClassName(): string {
    if (!this.media) return undefined
    if (this.isVideo) return 'video-slider'
    if (this.isImage) return 'image-slider'
    return ''
  }

  @computed
  public get isCMSItem(): boolean {
    return false
  }

  public toDTO() {
    return {
      type: this.type,
      path: this.path,
    }
  }
}
