import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { TasksStore } from '../../store/TasksStore'
import { Grid, InputLabel, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { IOption } from '../../types/Types'

interface DrawerTaskDetailsNameProps {
  localizationStore?: LocalizationStore
  tasksStore?: TasksStore
}

const DrawerTaskDetailsName: React.FC<DrawerTaskDetailsNameProps> = ({
  localizationStore,
  tasksStore,
}) => {
  const { lzStrings } = localizationStore
  const { taskEditVM: VM } = tasksStore
  if (!VM.allowDiscipline) return null

  const handleDisciplineChange = (event: object, option: IOption) => {
    if (option) {
      VM.setTaskDisciplineId(option.objectId)
    } else {
      VM.setTaskDisciplineId(null)
    }
  }

  return (
    <Grid item xs={6}>
      <InputLabel htmlFor='taskDiscipline' required>
        {lzStrings.tasksManagementWidgetDrawer.task_discipline}
      </InputLabel>
      <Autocomplete
        id='taskDiscipline'
        options={VM.disciplineOptions}
        value={VM.selectedDisciplineOption}
        getOptionLabel={(option: IOption) => option.label}
        onChange={handleDisciplineChange}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{ ...params.InputProps, className: 'autocompleteInput' }}
            variant='outlined'
            fullWidth
            required={VM.disciplineRequired}
            error={VM.disciplineError}
          />
        )}
      />
    </Grid>
  )
}

export default inject('localizationStore', 'tasksStore')(observer(DrawerTaskDetailsName))
