import { action, computed, observable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { PulseCategoriesDataStore } from '../../pulse-categories/view-models/PulseCategoriesDataStore'
import { PulseCampaignEditVM } from './PulseCampaignEditVM'
import { PulseCategoriesManageVM } from './PulseCategoriesManageVM'
import { PulseCampaign } from '../aggregate/PulseCampaign'
import PulseCategory from '../../pulse-categories/aggregate/PulseCategory'
import PulseQuestion from '../..//pulse-questions/aggregate/PulseQuestion'
import { IPulseCategoriesFindRequest } from 'src/app/pulse-categories/interfaces/IPulseCategoriesFindRequest'
import { GrandCategoryTabVM } from './tree/GrandCategoryTabVM'

export class PulseCampaignSummaryVM {
  private rootStore: RootStore
  private editVM: PulseCampaignEditVM
  private manageVM: PulseCategoriesManageVM
  private dataStore: PulseCategoriesDataStore

  constructor(
    rootStore: RootStore,
    editVM: PulseCampaignEditVM,
    manageVM: PulseCategoriesManageVM
  ) {
    this.rootStore = rootStore
    this.pulseCampaign = editVM.pulseCampaign
    this.editVM = editVM
    this.manageVM = manageVM
    this.dataStore = new PulseCategoriesDataStore(this.rootStore, {
    } as IPulseCategoriesFindRequest)
    this.loadDataStore()
  }

  @observable public pulseCampaign: PulseCampaign = null
  @observable public page: number = 1
  @observable public PER_PAGE: number = 5

  @computed
  public get categoriesVM() {
    return this.manageVM
  }

  @action
  private loadDataStore() {
    this.dataStore.loadListRecords()
  }

  @computed
  public get isLoaded(): boolean {
    return this.dataStore.isLoaded
  }

  @computed
  public get categories(): PulseCategory[] {
    return this.dataStore.rows
  }

  @computed
  public get objectId(): string {
    return this.pulseCampaign.objectId
  }

  @computed
  public get name(): string {
    return this.pulseCampaign.name
  }

  @computed
  public get questionsSelectedCount(): number {
    return this.pulseCampaign.questions.length
  }

  @computed
  public get grandParentCategories(): PulseCategory[] {
    return this.rootStore.pulseCategoriesStore.viewModels.editVM.grandCategorySelectVM.categories
  }

  @computed
  public get questions(): PulseQuestion[] {
    return this.pulseCampaign.questions.filter(q => this.manageVM.selectedGrandCategoryTabs.includes(q.fk_grandCategoryId.objectId))
  }

  @computed
  public get grandCategoryTabs(): GrandCategoryTabVM[] {
    return this.manageVM.grandCategoryTabs
  }

  public grandParentCategoryCount(
    grandParentCategoryId: string,
  ): number {
    const gpVM = this.manageVM.categoryVMs.find((g) => grandParentCategoryId === g.objectId)
    return gpVM ? gpVM.parentQuestionSelectedCount : 0
  }

  @action
  public setPage(page: number) {
    this.page = page
  }

  @action
  public toggleTabIndex(index: number) {
    this.manageVM.toggleTabIndex(index)
  }

  @action
  public setPaginationPerPage(PER_PAGE: number) {
    this.PER_PAGE = PER_PAGE
  }
}
