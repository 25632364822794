import React, { FC } from 'react'
import { inject, observer } from 'mobx-react'
import Grid from '@material-ui/core/Grid'
import { IconButton, InputAdornment, InputLabel, TextField, Tooltip } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { Typography } from '@material-ui/core'
import { OrganizationUsersStore } from '../../store/OrganizationUsersStore'
import LocalizationStore from '../../../localization/LocalizationStore'
import PasswordStrengthMeter from '../../../shared/password-strength/PasswordStrengthMeter'
import PasswordStrengthChecklist from '../../../shared/password-strength/PasswordStrengthChecklist'
import './PasswordTab.scss'

interface Props {
  organizationUsersStore?: OrganizationUsersStore
  localizationStore?: LocalizationStore
}

const PasswordTab: FC<Props> = ({ organizationUsersStore, localizationStore }) => {
  const { editPasswordVM: vm } = organizationUsersStore
  const { profile: lz } = localizationStore.lzStrings

  const renderCurrentPassword = () => {
    return (
      <Grid item md={12} sm={12}>
        <InputLabel required={true} htmlFor='CurrentPassword'>
          {vm.editingSelf ? lz.current_password : lz.system_admin_password}
        </InputLabel>
        <TextField
          fullWidth
          error={!vm.passwordValid || vm.existingPasswordsDontMatch}
          id='CurrentPassword'
          variant='outlined'
          disabled={vm.isProcessing}
          type={vm.showPassword ? 'text' : 'password'}
          autoComplete={vm.showNewPassword ? 'off' : 'current-password'}
          value={vm.password}
          onChange={(e) => (vm.password = e.target.value)}
          InputProps={{
            autoComplete: vm.showNewPassword ? 'off' : 'current-password',
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  edge='end'
                  aria-label='toggle password visibility'
                  onClick={() => vm.toggleShowPassword()}
                  onMouseDown={(e) => e.preventDefault()}
                >
                  {!vm.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {vm.existingPasswordsDontMatch ? (
          <Typography className='error-text'>{lz.not_current_message}</Typography>
        ) : null}
      </Grid>
    )
  }

  const renderNewPassword = () => {
    return (
      <Grid item md={12} sm={12}>
        <InputLabel required={true} htmlFor='NewPassword'>
          {lz.new_password}
        </InputLabel>
        <TextField
          fullWidth
          error={!vm.newPasswordValid || !vm.newPasswordsMatch}
          id='NewPassword'
          variant='outlined'
          disabled={vm.isProcessing}
          type={vm.showNewPassword ? 'text' : 'password'}
          autoComplete={vm.showNewPassword ? 'off' : 'new-password'}
          value={vm.newPassword}
          onChange={(e) => (vm.newPassword = e.target.value)}
          onFocus={() => vm.setPasswordMeterShown(true)}
          onBlur={() => vm.setPasswordMeterShown(false)}
          InputProps={{
            type: vm.showNewPassword ? 'text' : 'password',
            autoComplete: vm.showNewPassword ? 'off' : 'new-password',
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  edge='end'
                  aria-label='toggle password visibility'
                  onClick={() => vm.toggleShowNewPassword()}
                  onMouseDown={(e) => e.preventDefault()}
                >
                  {!vm.showNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <PasswordStrengthMeter vm={vm.meterVM} isVisible />
        {vm.newPasswordsMatch ? null : (
          <Typography className='error-text'>{lz.no_match_message}</Typography>
        )}
        {!vm.saveTried || (vm.saveTried && vm.meterVM.isNewPasswordStrong) ? null : (
          <Typography className='error-text'>{lz.strength_message}</Typography>
        )}
      </Grid>
    )
  }

  const renderConfirmPassword = () => {
    return (
      <Grid item md={12} sm={12}>
        <InputLabel required={true} htmlFor='ConfirmedPassword'>
          {lz.confirm_new_password}
        </InputLabel>
        <TextField
          fullWidth
          error={!vm.confirmNewPasswordValid || !vm.newPasswordsMatch}
          id='ConfirmedPassword'
          variant='outlined'
          disabled={vm.isProcessing}
          type={vm.showConfirmNewPassword ? 'text' : 'password'}
          autoComplete={vm.showNewPassword ? 'off' : 'confirm-new-password'}
          value={vm.confirmNewPassword}
          onChange={(e) => (vm.confirmNewPassword = e.target.value)}
          InputProps={{
            autoComplete: vm.showNewPassword ? 'off' : 'confirm-new-password',
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  edge='end'
                  aria-label='toggle password visibility'
                  onClick={() => vm.toggleShowConfirmNewPassword()}
                  onMouseDown={(e) => e.preventDefault()}
                >
                  {!vm.showConfirmNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {vm.newPasswordsMatch ? null : (
          <Typography className='error-text'>{lz.no_match_message}</Typography>
        )}
      </Grid>
    )
  }

  const renderPasswordChecklist = () => {
    return (
      <Grid item md={12} sm={12} className='policy-grid'>
        <InputLabel>
          {lz.password_requirements}
        </InputLabel>
        <PasswordStrengthChecklist vm={vm.meterVM} isVisible />
      </Grid>
    )
  }

  const renderBasicInfo = () => {
    return (
      <div id='userBasicInfo'>
        <h6>{lz.change_password}</h6>
        <Grid container spacing={1} direction='row'>
          <Grid item md={12} sm={12}>
            {renderCurrentPassword()}
          </Grid>
          <Grid item md={8} sm={8}>
            <Grid container spacing={1}>
              {renderNewPassword()}
              {renderConfirmPassword()}
            </Grid>
          </Grid>
          <Grid item md={4} sm={4}>
            {renderPasswordChecklist()}
          </Grid>
        </Grid>
      </div>
    )
  }

  return (
    <div id='PasswordTab'>
      <form autoComplete='off' noValidate>
        {renderBasicInfo()}
      </form>
    </div>
  )
}

export default inject('organizationUsersStore', 'localizationStore')(observer(PasswordTab))
