import moment from 'moment'
import { observable, action, computed } from 'mobx'
import { MediaItem } from '../../aggregate/MediaItem'
import { RootStore } from '../../../stores/RootStore'
import { IMediaItemDTO } from '../../dtos/IMediaItemDTO'
import { MediaItemsService } from '../../service/MediaItemsService'
import fileSizer from '../../../shared/FileSizer'
import { ICloudinaryResult } from '../../dtos/ICloudinaryResult'
import { MediaItemType } from '../../../upload/types/MediaItemType'
import { Attachment } from '../../../upload/aggregate/Attachment'

declare global {
  interface Window {
    cloudinary: any
  }
}

export class MediaItemDrawerVM {
  private mediaItemsService: MediaItemsService

  constructor(mediaItem?: MediaItem, private rootStore?: RootStore) {
    this.mediaItemsService = new MediaItemsService()
    if (mediaItem) this.mediaItem = mediaItem
    if (!mediaItem.objectId) setTimeout(() => this.toggleMediaUploadModal(), 500)
  }

  @observable public file: Attachment
  @observable public isDirty: boolean
  @observable public isEdit: boolean = false
  @observable public isProcessing: boolean
  @observable public saveRequested: boolean
  @observable public saveSuccessful: boolean
  @observable public showDeleteDialog: boolean
  @observable public mediaItem: MediaItem = null
  @observable public showUploadError: boolean = false
  @observable public widget: any = null
  @observable public showMediaUploadModal: boolean = false

  @computed
  public get showUploadButton(): boolean {
    if (this.widget && this.widget.isShowing()) return false
    return true
  }

  @computed
  public get objectId(): string {
    return this.mediaItem.objectId
  }

  @computed
  public get createdAt(): Date {
    return this.mediaItem.createdAt
  }

  @computed
  public get fileSize(): number {
    return this.mediaItem.size
  }

  @computed
  public get name(): string {
    return this.mediaItem.name
  }

  @computed
  public get type(): MediaItemType {
    return this.mediaItem.type
  }

  @computed
  public get fileUrl(): string {
    return this.mediaItem.fileUrl
  }

  @computed
  public get formattedDate() {
    const s = this.rootStore.localizationStore.lzStrings.mediaItemDrawer
    if (!this.createdAt) return '-'
    let formattedDate = moment(this.createdAt).format(s.created_date_format)
    return formattedDate
  }

  @computed
  public get formattedFileSize() {
    return fileSizer(this.fileSize)
  }

  @computed
  public get nameValid(): boolean {
    if (!this.mediaItem.name && this.saveRequested) return false
    return true
  }

  @computed
  public get saveValid(): boolean {
    if (!this.nameValid || !this.mediaItem) return false
    return true
  }

  @action
  public setName(name: string) {
    this.mediaItem.setName(name)
  }

  @action
  public showEditWidget() {
    this.isEdit = true
    this.toggleMediaUploadModal()
  }

  @action
  public toggleMediaUploadModal() {
    this.showMediaUploadModal = !this.showMediaUploadModal
  }

  @action
  public addMedia(attachment: Attachment) {
    const mediaItem = MediaItem.createFromAttachment(attachment, this.rootStore.appStore.currentOrgId)
    if (this.objectId) return this.changeMediaItem(mediaItem, attachment, this.objectId, this.name)
    return this.createMediaItem(mediaItem, attachment)
  }

  @action
  public changeMediaItem(mediaItem: MediaItem, file: Attachment, objectId, name) {
    this.mediaItem = mediaItem
    this.mediaItem.setObjectId(objectId)
    this.mediaItem.setName(name)
    this.file = file
    this.isDirty = true
  }

  @action
  public createMediaItem(mediaItem, file: Attachment) {
    this.mediaItem = mediaItem
    this.file = file
    this.isDirty = true
  }

  @action
  public async saveMediaItem() {
    this.saveRequested = true
    if (!this.saveValid) return
    const dto: IMediaItemDTO = this.mediaItem.serialize()
    await this.mediaItemsService.saveMediaItem(dto)
    this.saveSuccessful = true
    this.saveRequested = false
    setTimeout(() => this.rootStore.mediaItemsStore.closeDrawer(), 500)
  }

  @action
  public toggleDeleteDialog() {
    this.showDeleteDialog = !this.showDeleteDialog
  }

  @action
  public async deleteMediaItem() {
    let isLoading: boolean = true
    setTimeout(() => (this.isProcessing = isLoading), 750)
    this.showDeleteDialog = false
    setTimeout(() => this.rootStore.mediaItemsStore.closeDrawer(), 500)
    await this.mediaItemsService.deleteMediaItem(this.objectId, this.mediaItem.organizationId)
    // Check Survey for Questions containing deleted media item
    if (this.rootStore.surveysStore && this.rootStore.surveysStore.viewModels.editVM) {
      let currentSurveyQuestion = this.rootStore.surveysStore.viewModels.editVM.currentQuestion
      // if (currentSurveyQuestion && currentSurveyQuestion.selectedMediaItemId === this.objectId) {
      //   currentSurveyQuestion.removeMedia()
      // }
    }
    isLoading = false
    this.isProcessing = false
  }
}
