import { action, computed, IReactionDisposer, observable } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { TableauAuthTokensService } from '../../../tableauAuthTokens/service/TableauAuthTokensService'
import { Worksheet } from '../../../worksheets/aggregate/Worksheet'
import { PulseCampaign } from '../../aggregate/PulseCampaign'
import { IPulseCampaignAnalysisRequest } from '../../interfaces/IPulseCampaignAnalysisRequest'
import { PulseCampaignsService } from '../../service/PulseCampaignsService'

export class CampaignAnalysisEditVM {
  private rootStore: RootStore
  private tableauViz: tableau.Viz
  private tableauVizDiv: any

  constructor(rootStore: RootStore, pulseCampaign: PulseCampaign) {
    this.rootStore = rootStore
    this.pulseCampaign = pulseCampaign
    this.loadTableauAuthToken()
  }

  @observable public pulseCampaign: PulseCampaign
  @observable public tableauAuthToken: string = null
  @observable public snackbarMessage: string = ''
  @observable public isSnackbarOpen: boolean = false
  @observable public isSaving: boolean = false

  @computed
  public get pulseCampaignName(): string {
    return this.pulseCampaign.name
  }

  @computed
  public get worksheetId(): string {
    return this.pulseCampaign.worksheetId
  }

  @computed
  public get worksheet(): Worksheet {
    if (!this.pulseCampaign.worksheetId) return null
    const ws = this.rootStore.worksheetsStore.getWorksheet(this.pulseCampaign.worksheetId)
    if (!ws) return null
    const clonedWS = ws.clone()
    clonedWS.setPulseCampaign(this.pulseCampaign.objectId)
    clonedWS.setLoggedInUser(this.rootStore.appStore.currentUserId)
    return clonedWS
  }

  @computed
  public get showTabs(): boolean {
    if (!this.worksheet) return false
    return this.worksheet.showTabs
  }

  @computed
  public get tableauViewUrl(): string {
    if (!this.worksheet) return ''
    return this.worksheet.tableauViewUrl
  }

  @computed
  public get tableauQuery(): string {
    if (!this.worksheet) return ''
    return this.worksheet.tableauQuery
  }

  @computed
  public get availableWorksheets(): Worksheet[] {
    return this.rootStore.worksheetsStore.currentOrgWorksheets
      .filter((e) => e.forPulseCampaigns)
      .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
  }

  @action
  public setWorksheetId(worksheetId: string) {
    this.pulseCampaign.setWorksheetId(worksheetId)
    this.loadTableauAuthToken()
  }

  @action
  public async loadTableauAuthToken() {
    this.tableauAuthToken = ''
    if (!this.worksheet) {
      setTimeout(() => this.loadTableauAuthToken(), 400)
      return
    }
    const svc = new TableauAuthTokensService(this.rootStore)
    const token = await svc.getAuthToken()
    this.tableauAuthToken = token
  }

  public setTableauVizContainer(div, viz) {
    this.tableauViz = viz
    this.tableauVizDiv = div
  }

  @action
  public openSnackbar(msg) {
    this.snackbarMessage = msg
    this.isSnackbarOpen = true
  }

  @action
  public closeSnackbar() {
    this.isSnackbarOpen = false
  }

  @action
  public async save() {
    this.isSaving = true
    const svc = new PulseCampaignsService()
    try {
      const req: IPulseCampaignAnalysisRequest = {
        userId: this.rootStore.appStore.currentUserId,
        pulseCampaignId: this.pulseCampaign.objectId,
        organizationId: this.pulseCampaign.organizationId,
        worksheetId: this.worksheetId
      }
      const result = await svc.savePulseCampaignWorksheet(req)
      if (result && result.success) {
        this.openSnackbar(`Success! Your worksheet has been saved.`)
      } else {
        this.openSnackbar(`Error! ${result.errorMessage}`)
      }
      this.isSaving = false
    } catch (e) {
      console.error(e)
    }
  }
}
