import { RootStore } from '../../stores/RootStore'
import { DataStore } from '../../shared/data/DataStore'
import { Contact } from '../../contacts/aggregate/Contact'
import { IContactDTO } from '../../contacts/dtos/IContactDTO'
import { ClientRowVM } from './ClientRowVM'

export class ContactsListDataStore extends DataStore<Contact, IContactDTO> {
  private forClientRow: ClientRowVM

  constructor(rootStore: RootStore, forClientRow: ClientRowVM) {
    super(rootStore, Contact, 'contacts', ['objectId', 'name'])
    this.paged = true
    this.recordsPerPage = 100
    this.sortColumnName = 'name'
    this.forClientRow = forClientRow
  }

  public get contacts() {
    return this.records
  }
}
