import React, { FC } from 'react'
import { observer, inject } from 'mobx-react'
import Paper from '@material-ui/core/Paper'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Slide from '@material-ui/core/Slide'
import './Drawer.scss'
import { QuizVM } from '../../view-models/QuizVM'
import QuestionForm from './QuestionForm'

interface Props {
  quiz: QuizVM
}

const Drawer: FC<Props> = ({ quiz }) => {
  const { currentQuestion: vm } = quiz
  if (!vm) return null

  const renderQuestionForm = () => {
    return <QuestionForm quiz={quiz} />
  }

  const renderDrawerTitle = () => {
    return `Q${vm.index + 1} Editor`
  }

  return (
    <Slide in={quiz.drawerOpen} direction={'left'}>
      <Paper id='Drawer' square elevation={6}>
        <AppBar color='default' position='sticky' elevation={0}>
          <Toolbar>
            <Typography variant='h5' style={{ flexGrow: 1 }}>
              {renderDrawerTitle()}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className='content-container'>{renderQuestionForm()}</div>
      </Paper>
    </Slide>
  )
}

export default inject('surveysStore', 'questionsStore', 'mediaItemsStore')(observer(Drawer))
