import { observable } from 'mobx'
import { serializable, serialize, deserialize } from 'serializr'
import { IApplicableParticipantDTO } from '../dto/IApplicableParticipantDTO'
import { AudienceMemberType } from 'src/app/audience-members/types/AudienceMemberType'

export class ApplicableParticipant implements IApplicableParticipantDTO {
  public static create(id: string, name: string, type: any): ApplicableParticipant {
    const applicableParticipant = new ApplicableParticipant()
    applicableParticipant.id = id
    applicableParticipant.type = type
    applicableParticipant.name = name
    return applicableParticipant
  }

  @serializable @observable public id: string = ''
  @serializable @observable public type: AudienceMemberType = null
  @serializable @observable public name: string = ''

  public serialize() {
    const obj = serialize(this)
    return obj
  }

  public clone(): ApplicableParticipant {
    return deserialize(ApplicableParticipant, this.serialize())
  }
}
