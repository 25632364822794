import Parse from 'parse'

export class SystemAdminService {
  public async getSystemAdmins() {
    const result = await Parse.Cloud.run('getSystemAdmins')
    return Array.isArray(result) ? result : []
  }

  public async save(email: string): Promise<string> {
    const response = await Parse.Cloud.run('addSystemAdmin', {email})
    return response
  }

  public async removeSystemAdmin(id: string): Promise<string> {
    const response = await Parse.Cloud.run('removeSystemAdmin', {id})
    return response
  }

}
