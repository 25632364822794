import { observable } from 'mobx'
import { ParticipantsSelectVM } from '../../participants-select/view-models/ParticipantsSelectVM'
import { RootStore } from '../../stores/RootStore'
import Dashboard from '../aggregate/Dashboard'
import RGLItem from '../aggregate/RGLItem'
import { DashboardsUpdateService } from '../service/DashboardsUpdateService'
import { DashboardType } from '../types/DashboardType'
import { WidgetContainerVM } from './WidgetContainerVM'
import WidgetDrawerVM from './WidgetDrawerVM'

export default class DashboardEditVM {
  rootStore: RootStore
  @observable dashboard: Dashboard = null

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.widgetDrawerVM = new WidgetDrawerVM(this.rootStore, this)
  }

  @observable public showWidgetDrawer: boolean = false
  @observable public showSharedWithDrawer: boolean = false
  @observable public showFavoriteSnackbar: boolean = false
  @observable public showSharedSnackbar: boolean = false
  @observable public showConfirmDelete: boolean = false
  @observable public showCantDelete: boolean = false
  @observable public isProcessing: boolean = false
  @observable public widgetDrawerVM: WidgetDrawerVM = null
  @observable public sharedWithVM: ParticipantsSelectVM = null
  @observable public isSaving: boolean = false

  public loadDashboard(dashboard: Dashboard) {
    this.dashboard = dashboard
    this.setIsNotProcessing()
  }

  public clearDashboard() {
    this.dashboard = null
  }

  public setIsProcessing() {
    this.isProcessing = true
  }

  public setIsNotProcessing() {
    this.isProcessing = false
  }

  public get name(): string {
    if (!this.dashboard) return ''
    return this.dashboard.name
  }

  public get objectId(): string {
    if (!this.dashboard) return ''
    return this.dashboard.objectId
  }

  public get nameValid() {
    if (!this.name || this.name.trim() === '') return false
    return true
  }

  public get type(): DashboardType {
    const type = this.rootStore.userDashboardsStore.viewModels.typeFromRoute
    if (!type) return 'custom'
    return type
  }

  public get layout(): RGLItem[] {
    if (!this.dashboard) return []
    return this.dashboard.lgLayout
  }

  public get widgets(): WidgetContainerVM[] {
    return this.layout.map(
      (e) => new WidgetContainerVM(this.rootStore, e, this.dashboard.objectId, this)
    )
  }

  public get isLastDashboard(): boolean {
    const customDashboards = this.rootStore.userDashboardsStore.currentOrgUserDashboards.filter(
      (e) => e.type === 'custom'
    )
    const usersDashboards = customDashboards.filter(
      (e) => e.ownerUserId === this.rootStore.appStore.currentUserId
    )
    return Boolean(usersDashboards.length === 1)
  }

  public setName(val: string) {
    this.dashboard.setName(val)
  }

  public setLayoutChange(layout: RGLItem[]) {
    if (!this.dashboard) return
    const newLayout = []
    layout.forEach((widget) => {
      let existingWidget = this.dashboard.lgLayout.find((e) => e.i === widget.i)
      if (existingWidget) {
        newLayout.push({
          i: existingWidget.i,
          x: widget.x,
          y: widget.y,
          h: widget.h,
          w: widget.w,
          type: existingWidget.type,
          worksheetId: existingWidget.worksheetId,
        })
      }
    })
    this.dashboard.setLayout(newLayout)
  }

  public removeWidget(index: string) {
    const newLayout = this.dashboard.lgLayout.filter((widget) => widget.i !== index)
    this.dashboard.setLayout(newLayout)
  }

  public async delete() {
    const svc = new DashboardsUpdateService()
    await svc.deleteDashboard(this.objectId)
    this.toggleShowConfirmDelete()
    this.goBack()
  }

  public async save() {
    if (!this.nameValid) return
    this.setIsProcessing()
    const svc = new DashboardsUpdateService()
    const result = await svc.updateDashboard(this.dashboard.serialize())
    if (!result) this.goBack()
    if (!result.success) this.goBack()
    this.setIsNotProcessing()
    return this.goToUserDashboard(result.dashboard.objectId)
  }

  public goToUserDashboard(dashboardId) {
    const vm = this.rootStore.userDashboardsStore.viewModels
    return vm.getUserDashboardByDashboardId(dashboardId)
  }

  public goBack() {
    const vm = this.rootStore.userDashboardsStore.viewModels
    return vm.getDefaultDashboardByType(this.type)
  }

  public toggleShowWidgetDrawer() {
    this.showWidgetDrawer = !this.showWidgetDrawer
  }

  public toggleShowFavoriteSnackbar() {
    this.showFavoriteSnackbar = !this.showFavoriteSnackbar
  }

  public toggleShowSharedSnackbar() {
    this.showSharedSnackbar = !this.showSharedSnackbar
  }

  public toggleShowConfirmDelete() {
    if (this.isLastDashboard) return this.toggleShowCantDelete()
    this.showConfirmDelete = !this.showConfirmDelete
  }

  public toggleShowCantDelete() {
    this.showCantDelete = !this.showCantDelete
  }

  public addWidget(widgetType) {
    let foundWidget = this.widgetDrawerVM.allWidgets.find((e) => e.type === widgetType)
    if (foundWidget) {
      if (this.layout.length > 0) {
        const highestI = this.layout.sort((a, b) => parseInt(b.i) - parseInt(a.i))
        const count = parseInt(highestI[0].i)
        const newCount = count + 1
        foundWidget.i = newCount.toString()
      } else {
        foundWidget.i = '0'
      }
      let newLgLayout = JSON.parse(JSON.stringify(this.layout))
      const newWidget = this.getNewWidgetPosition(foundWidget)
      newLgLayout.push(newWidget)
      this.dashboard.setLayout(newLgLayout)
      setTimeout(() => this.scrollToAddedWidget(newWidget.type), 500)
    }
  }

  private get widgetsGroupedByRow() {
    if (!this.layout) return []
    const sortedRows = this.layout.sort((a, b) => a.y - b.y)
    function groupBy(arr, prop) {
      const map = new Map(Array.from(arr, (obj) => [obj[prop], []]))
      arr.forEach((obj) => map.get(obj[prop]).push(obj))
      return Array.from(map.values())
    }
    return groupBy(sortedRows, 'y')
  }

  private getNewWidgetPosition(newWidget) {
    newWidget.x = 0
    newWidget.y = 0

    let onNewRow = true

    for (let row of this.widgetsGroupedByRow) {
      let rowWidth = 0

      let takenX = []

      row.forEach((widget) => {
        rowWidth = rowWidth + widget.w
        takenX.push(widget.x)
        for (let i = 1; i < widget.w; i++) {
          takenX.push(widget.x + i)
        }
      })

      if (rowWidth + newWidget.w <= 4) {
        let willXFit = true
        let willYFit = true

        for (let i = 0; i <= 3; i++) {
          if (!takenX.includes(i)) {
            if (newWidget.w > 1) {
              for (let j = 1; j < newWidget.w; j++) {
                if (takenX.includes(j + i)) willXFit = false
              }
            }

            if (willXFit) {
              newWidget.x = i
              break
            }
          }
        }

        if (willXFit) {
          let nextRow = this.widgetsGroupedByRow[row[0].y + 1]
          if (nextRow) {
            let foundWidget = nextRow.find((widget) => widget.x === newWidget.x)
            if (foundWidget) willYFit = false
          }
        }

        if (willXFit && willYFit) {
          onNewRow = false
          newWidget.y = row[0].y
          break
        }
      }
    }

    if (onNewRow) {
      newWidget.x = 0
      newWidget.y = this.widgetsGroupedByRow.length + 50
    }

    return newWidget
  }

  public scrollToAddedWidget(type) {
    if (type === 'ManageMediaItemsWidget') type = 'MediaItemsListWidget'
    if (type === 'ManageSurveys') type = 'ManageSurveysWidget'
    if (type === 'CategoriesManagementWidget') type = 'CategoriesWidget'
    if (type === 'PendingUserSurveys') type = 'PendingUserSurveysWidget'
    if (type === 'TasksManageMentWidget') type = 'TasksManagementWidget'
    if (type === 'WorksheetWidget') type = 'ChartWidget'

    const widget = document.getElementById(type)
    if (widget) widget.scrollIntoView()
  }
}
