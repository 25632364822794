import React from 'react'
import { inject, observer, useLocalStore } from 'mobx-react'
import rootStore from '../../stores/RootStore'
import { Button, CircularProgress, Grid, IconButton, Typography } from '@material-ui/core'
import { ParentCategoryRowVM } from '../view-models/ParentCategoryRowVM'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ChevronDownIcon from '@material-ui/icons/ExpandMore'
import { ChildCategoryRowVM } from '../view-models/ChildCategoryRowVM'
import ChildCategoryRow from './ChildCategoryRow'
import EditIcon from '@material-ui/icons/Edit'

interface Props {
  vm: ParentCategoryRowVM
}

const ParentCategoryRow: React.FC<Props> = ({ vm }) => {
  if (!vm) return null

  const renderExpandIcon = () => {
    let icon = <ChevronRightIcon className={'expand-icon'} />
    if (vm.isExpanded) icon = <ChevronDownIcon className={'expand-icon'} />
    return (
      <IconButton
        disableRipple
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          vm.toggleIsExpanded()
        }
        }
        className={'icon-container'}
      >
        {icon}
      </IconButton >
    )
  }

  const renderCard = () => {
    return (
      <Grid onClick={() => vm.toggleIsExpanded()} className={`parent-card border-cat-${vm.index}`} item xs={12}>
        <div className={'parent-card-title'}>
          {renderExpandIcon()}
          <Typography className='parent-header'>{vm.name}</Typography>
        </div>
        <div>
          <IconButton
            size='small'
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              vm.editPulseCategory()
            }
            }
          >
            <EditIcon />
          </IconButton>
        </div>
      </Grid>
    )
  }

  const renderChildCategories = () => {
    if (!vm.isExpanded) return null
    if (!vm.isLoaded) return renderSpinner()
    const categories = []
    vm.childPulseCategoryRows.forEach((row: ChildCategoryRowVM, i) => {
      categories.push(<ChildCategoryRow key={i} vm={row} />)
    })
    return (
      <div>
        {categories}
        <div className={'no-child-container'}>
          {renderNoChildCategories()}
          <Button className={'add-category-button'} variant='contained' onClick={() => vm.createNewPulseCategory()}>
            Add Category
          </Button>
        </div>
      </div>
    )
  }

  const renderSpinner = () => {
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  const renderNoChildCategories = () => {
    if (vm.childPulseCategoryRows.length) return null
    return <span> No Child Categories To Show </span>
  }

  return (
    <>
      {renderCard()}
      {renderChildCategories()}

    </>
  )
}

export default inject()(observer(ParentCategoryRow))
