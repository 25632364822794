import React from 'react'
import { inject, observer } from 'mobx-react'
import { Button, Card, Dialog, DialogTitle, Grid, IconButton, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import './VimeoPreviewModal.scss'
import { PulseQuestionsStore } from '../../store/PulseQuestionsStore'


interface Props {
  pulseQuestionsStore?: PulseQuestionsStore
}

const VimeoPreviewModal: React.FC<Props> = ({ pulseQuestionsStore }) => {
  const vm = pulseQuestionsStore.vimeoPreviewVM 
  if (!vm) return null

  const renderHeader = () => {
    return (
      <DialogTitle className='header-text'>
        <Grid className='details-title'>
          <Grid>{'Vimeo Preview'}</Grid>
          <IconButton
            size='small'
            className='margin-right'
            onClick={() => vm.closeVimeoPreviewVM()}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
    )
  }

  const renderIframe = () => {
    return (
      <div>
        <iframe
          width="853"
          height="480"
          src={vm.embedUrl}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    )
  }

  const renderModal = () => {
    return (
      <Dialog id='VimeoPreviewModal' onClose={() => vm.closeVimeoPreviewVM()} open={true} maxWidth={'lg'}>
        {renderHeader()}
        {renderIframe()}
      </Dialog>
    )
  }

  return <div >{renderModal()}</div>
}

export default inject('pulseQuestionsStore')(observer(VimeoPreviewModal))
