import { observable } from 'mobx'
import { ParticipantsSelectVM } from '../../participants-select/view-models/ParticipantsSelectVM'
import { RootStore } from '../../stores/RootStore'
import Dashboard from '../aggregate/Dashboard'
import { DashboardsUpdateService } from '../service/DashboardsUpdateService'

export default class DashboardEditVM {
  rootStore: RootStore
  dashboard: Dashboard

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.sharedWithVM = new ParticipantsSelectVM(
      this.rootStore,
      false,
      false,
      true,
      true,
      false,
      true,
      false
    )
    this.sharedWithVM.setExcludeCurrentUser()
  }

  @observable public sharedWithVM: ParticipantsSelectVM = null
  @observable public isOpen: boolean = false
  @observable public isProcessing: boolean = false

  public openDrawer() {
    this.isOpen = true
  }

  public closeDrawer() {
    this.isOpen = false
  }

  public async loadDashboard(objectId: string) {
    this.isProcessing = true
    this.sharedWithVM.setParticipants([])
    this.openDrawer()
    const dashboard = await this.rootStore.dashboardsStore.getFullRecord(objectId)
    this.dashboard = dashboard
    this.sharedWithVM.setParticipants(dashboard.sharedWith)
    this.sharedWithVM.setVisible()
    this.isProcessing = false
  }

  public async saveSharedWith() {
    this.isProcessing = true
    const svc = new DashboardsUpdateService()
    this.dashboard.setSharedWith(this.sharedWithVM.participantsToDTO())
    await svc.updateDashboard(this.dashboard.serialize())
    this.closeDrawer()
    this.isProcessing = false
    if (this.dashboard.sharedWith.length) return this.rootStore.userDashboardsStore.viewModels.userDashboardVM.loadSharedSnackbar(true)
    return this.rootStore.userDashboardsStore.viewModels.userDashboardVM.loadSharedSnackbar(false)
  }
}
