import Parse from 'parse'
import { RootStore } from '../../stores/RootStore'
import { ISurveyTypeDTO } from '../store/dtos/ISurveyTypeDTO'
import { ISurveyTypesFindRequest } from '../interfaces/ISurveyTypesFindRequest'

export class SurveyTypesService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public async saveSurveyType(data: ISurveyTypeDTO) {
    const result = await Parse.Cloud.run('saveSurveyType', { data, orgId: data.organizationId })
    return result
  }

  public async deleteSurveyType(objectId: string) {
    const result = await Parse.Cloud.run('deleteSurveyType', { objectId, orgId: this.rootStore.appStore.currentOrgId })
    return result
  }

  public async getSurveyTypes(request: ISurveyTypesFindRequest) {
    const result = await Parse.Cloud.run('getSurveyTypes', { request })
    return result
  }
}
