import React from 'react'
import WidgetHeader from './WidgetHeader'
import WidgetContent from './WidgetContent'
import Card from '@material-ui/core/Card'
import './SurveyTagsWidget.scss'
import SurveyTagsDrawer from './SurveyTagsDrawer'
import rootStore from '../../../stores/RootStore'

const SurveyTagsWidget: React.FC = () => {
  if (!rootStore.organizationsStore.currentOrganization.allowSurveyTags) return null
  return (
    <>
      <Card id='SurveyTagsWidget' elevation={0}>
        <WidgetHeader />
        <WidgetContent />
      </Card>
      <SurveyTagsDrawer />
    </>
  )
}

export default SurveyTagsWidget
