import { action, observable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { MediaItem } from 'src/app/media-items/aggregate/MediaItem'
import { ContentItemType } from '../types/ContentItemType'

export abstract class MediaItemAttachmentVM {
  protected rootStore: RootStore
  public contentItemType: ContentItemType
  public media: MediaItem

  constructor(rootStore: RootStore, contentItemType: ContentItemType, media: MediaItem) {
    this.rootStore = rootStore
    this.contentItemType = contentItemType
    this.media = media
  }

  @observable public showLightBox: boolean = false

  public abstract get isImage(): boolean
  public abstract get isVideo(): boolean
  public abstract get type(): ContentItemType

  public abstract get fileType(): string
  public abstract get thumbnail(): string
  public abstract get url(): string
  public abstract get fileFormat(): string
  public abstract get fileName(): string
  public abstract get shorterFileName(): string

  public abstract get isCMSItem(): boolean
  public abstract openAttachment(): void

  @action
  public toggleLightBox() {
    this.showLightBox = !this.showLightBox
  }
}
