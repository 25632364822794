import { observable } from 'mobx'
import { serializable } from 'serializr'
import { IAnonymousParticipantDTO } from '../dtos/IAnonymousParticipantDTO'

export class AnonymousParticipant implements IAnonymousParticipantDTO {
  static create(fromPart: any) {
    const part = new AnonymousParticipant()
    part.email = fromPart.email
    part.token = fromPart.token
    part.firstName = fromPart.firstName
    part.lastName = fromPart.lastName
    part.language = fromPart.language
    return part
  }

  @serializable @observable public id: string = ''
  @serializable @observable public email: string = ''
  @serializable @observable public token: string = ''
  @serializable @observable public firstName: string = ''
  @serializable @observable public lastName: string = ''
  @serializable @observable public language: string = ''
}
