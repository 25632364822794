import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { OrganizationsStore } from '../../store/OrganizationsStore'
import MuiDrawerToolbar from '../../../shared/Mui-Drawer/MuiDrawerToolbar'

interface DrawerButtonControlsProps {
  localizationStore?: LocalizationStore
  organizationsStore?: OrganizationsStore
}

const DrawerButtonControls: React.FC<DrawerButtonControlsProps> = ({
  localizationStore,
  organizationsStore,
}) => {
  const { lzStrings } = localizationStore
  const { editVM: vm } = organizationsStore

  return (
    <div>
      <MuiDrawerToolbar
        disabled={vm.saveDisabled}
        handleCancelClick={() => {
          organizationsStore.closeDrawer()
        }}
        handleSubmitClick={() => vm.save()}
        showReset={Boolean(vm.objectId)}
        onConfirmReset={() => organizationsStore.loadEditVM(true)}
        moreMenuProps={{
          hidden: true,
          isEditMode: true,
          itemType: lzStrings.organizationWidgetDrawer.organization,
          itemName: vm.name,
          onConfirmDelete: () => console.log('under construction'),
        }}
      />
    </div>
  )
}

export default inject('localizationStore', 'organizationsStore')(observer(DrawerButtonControls))
