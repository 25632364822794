import { RootStore } from '../../stores/RootStore'
import Parse from 'parse'

export class TaskCommentsService {
  private rootStore: RootStore

  public async saveTaskComment(orgId, comment) {
    await Parse.Cloud.run('saveTaskComment', {
      orgId,
      comment,
    })
  }
}
