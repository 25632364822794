import React from 'react'
import { InputBase, AppBar, Toolbar, LinearProgress, Typography, Grid, TextField } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import {
  Button,
  CircularProgress,
} from '@material-ui/core'
import Check from '@material-ui/icons/Check'
import ErrorSharp from '@material-ui/icons/ErrorSharp'
import './TopToolbar.scss'
import { CustomCollectionsStore } from '../../store/CustomCollectionStore'
import RefreshIcon from '@material-ui/icons/Refresh'
import BackIcon from '@material-ui/icons/KeyboardBackspace'
import MuiConfirmDeleteDialog from 'src/app/shared/MuiConfirmDeleteDialog'
import MuiConfirmDialog from 'src/app/shared/MuiConfirmDialog'
import { AppStore } from 'src/app/stores/AppStore'
import InfoTooltip from 'src/app/shared/InfoTooltip'

interface Props {
  customCollectionsStore?: CustomCollectionsStore
  appStore?: AppStore
}

const TopToolbar: React.FC<Props> = ({ customCollectionsStore, appStore }) => {
  const { editVM } = customCollectionsStore
  if (!editVM) return null

  const renderDeleteButton = () => {
    if (editVM.isNew) return
    return (
      <Button
        variant='contained'
        className='btn-remove'
        onClick={() => editVM.toggleDeleteDialog()}
        disabled={editVM.isProcessing}
      >
        Delete Collection
      </Button>
    )
  }

  const renderRefreshButton = () => {
    return (
      <Button 
        variant='outlined'
        onClick={() => editVM.toggleResetDialog()}
        disabled={editVM.isProcessing}
      >
        <RefreshIcon />
      </Button>
    )
  }

  const renderDwImportButton = () => {
    if (editVM.isDwImporting) {
      return (
        <Button
          variant='outlined'
          disabled={true}
        >
          <CircularProgress className='progressCircle'/>
        </Button>
      )
    }
    return (
      <Button
        variant='outlined'
        onClick={() => editVM.dwImport()}
        disabled={editVM.isDwImporting}
      >
        DW Import
      </Button>
    )
  }

  const renderErrorInfo = () => {
    if (!editVM.errorMessage) return null
    return (
      <InfoTooltip message={editVM.errorMessage}/>
    )
  }

  const renderStatusButton = () => {
    if (!editVM.isAIModel) return
    return (
      <Grid item>
        <Button
          variant='outlined'
          onClick={() => editVM.getStatus()}
          disabled={false}
        >
          AI Status: {editVM.AIStatus}
          {renderErrorInfo()}
        </Button>
      </Grid>
    )
  }

  const renderActionButtons = () => {
    return (
      <Grid container alignItems='center' justifyContent='flex-end'>
        <Grid item onClick={() => window.open('https://rippleworx.atlassian.net/servicedesk/customer/portal/1/article/725712960?src=-542590559')}>
        <InfoTooltip message='Go to Documentation Page'/>
        </Grid>
        {renderStatusButton()}
        <Grid item>
          {!editVM.isNew && renderDwImportButton()}
        </Grid>
        <Grid item>
          {renderDeleteButton()}
        </Grid>
        <Grid item>
          {!editVM.isNew && renderRefreshButton()}
        </Grid>
        <Grid item>
          {editVM.isNew && editVM.pendingImportFile && renderPendingFileMessage()}
        </Grid>
        <Grid item>
          <Button disabled={editVM.isProcessing} variant='outlined' onClick={() => editVM.save()}>
            Save
          </Button>
        </Grid>
      </Grid>
    )
  }

  const renderPendingFileMessage = () => {
    return (
      <Typography>
        Click Save to import records from:&nbsp;&nbsp;
        <span className='file-name'>{editVM.pendingImportFile.name}</span>
      </Typography>
    )
  }

  const renderSaveProgress = () => {
    if (!editVM.isProcessing) return
    return <LinearProgress variant='determinate' value={editVM.saveProgress} />
  }

  const renderCustomCollectionName = () => {
    return (
      <TextField
        value={editVM.name}
        onChange={(e) => editVM.setName(e.target.value)}
        style={{ width: '100%' }}
        placeholder={'Collection Name'}
        className={editVM.isNameValid ? 'toolbar-input' : 'toolbar-input error'}
        disabled={editVM.isProcessing}
        error={!editVM.isNameValid}
        variant='standard'
      />
    )
  }

  const renderValidName = () => {
    if (editVM.checkingName) {
      return (
        <CircularProgress className='progressCircle'/>
      )
    } else if (editVM.isNameValid) {
      return (
        <div className='valid-indicator valid'>
          <Check/>
        </div>
      )
    } else if (editVM.name && !editVM.isNameValid) {
      return (
        <Grid container alignItems='center'>
          <Grid item>
          <div className='valid-indicator invalid'>
          Already Exists!
        </div>
          </Grid>
        </Grid>

      )
    }
  }

  const renderBackButton = () => {
    return (
      <Button
        className='back-tenant-admin-btn'
        variant='text'
        aria-label='back'
        onClick={() => {
          appStore.router.push('/dashboard/tenantAdmin/config')
        }}
      >
        <BackIcon />
        Back to Tenant Admin
      </Button>
    )
  }

  return (
    <AppBar position='static' color='default' id='MainForm' elevation={2}>
      <MuiConfirmDeleteDialog
        onClose={() => editVM.toggleDeleteDialog()}
        open={editVM.showDeleteDialog}
        itemType={'Custom Collection'}
        itemName={editVM.name}
        onConfirm={() => editVM.delete()}
      />
      <MuiConfirmDialog
        confirmMessage={
          'Are you sure you want to refresh? Any unsaved changes will be lost.'
        }
        onConfirm={() => editVM.refresh()}
        open={editVM.showResetDialog}
        onClose={() => editVM.toggleResetDialog()}
      />

      <Toolbar className='tabs-toolbar'>
        <div className='start-buttons'>
          {renderBackButton()}
          <form noValidate autoComplete='off'>
            {renderCustomCollectionName()}
            {renderValidName()}
          </form>
        </div>
        <div slot='end' className='end-buttons'>
          {renderActionButtons()}
        </div>
      </Toolbar>
      {renderSaveProgress()}
    </AppBar>
  )
}

export default inject('customCollectionsStore', 'appStore')(observer(TopToolbar))
