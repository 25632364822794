import axios from 'axios'
import * as Sentry from '@sentry/browser'
import env from '../../../env'
import { RootStore } from '../../stores/RootStore'
import { IPaginationParams } from '../interfaces/IPaginationParams'
import { ISortParams } from '../interfaces/ISortParams'
import { ICMSItemDTO } from '../interfaces/ICMSItemDTO'
import { ICMSItemsListRequest } from '../interfaces/ICMSItemsListRequest'
import { CMSAuthService } from '../../cms-auth/services/CMSAuthService'

export class CMSItemsFindService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  private DEFAULT_SORT: ISortParams = {
    field: 'createdAt',
    dir: 'desc',
  }

  public DEFAULT_PAGINATION: IPaginationParams = {
    page: 1,
    pageSize: 50,
    start: 0,
    limit: 50,
  }

  private getCMSApiUrl(): string {
    return env.var.REACT_APP_CMS_API_URL
  }

  public async getItem(id: number): Promise<ICMSItemDTO> {
    if (!id) return
    try {
      const jwt = await CMSAuthService.getJWT()
      if (!jwt) return

      const url =
        this.getCMSApiUrl() +
        `/api/items/${id}?populate[0]=File&populate[1]=Unoptimized_Video&populate[2]=Optimized_Video&populate[3]=Item_Tags`
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt}`,
          Accept: '*/*',
        },
      }
      const response = await axios.get(url, config)
      if (!response.data) return
      if (!response.data.data) return
      return response.data.data
    } catch (e) {
      console.error(e)
      Sentry.captureException({
        message: 'Unable to get cms item',
        exception: e,
        userId: this.rootStore.appStore.currentUserId,
        orgId: this.rootStore.appStore.currentOrgId,
      })
    }
  }

  public async getItems(req: ICMSItemsListRequest): Promise<ICMSItemDTO[]> {
    const searchReq = {
      userId: req.userId,
      organizationId: req.organizationId,
      sort: req.sort ? req.sort : this.DEFAULT_SORT,
      pagination: req.pagination,
      search: req.search ? req.search : undefined,
      filter: req.filter ? req.filter : undefined,
      tags: req.tags ? req.tags : undefined,
      access: req.access ? req.access : undefined,
    } as ICMSItemsListRequest
    try {
      const jwt = await CMSAuthService.getJWT()
      if (!jwt) return []
      const url = this.getCMSApiUrl() + '/api/items/filterSort'
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt}`,
          Accept: '*/*',
        },
      }
      const response = await axios.post(url, { data: JSON.stringify(searchReq) }, config)
      if (!response.data) return []
      if (!response.data.items) return []
      return response.data.items
    } catch (e) {
      console.error(e)
      Sentry.captureException({
        message: 'Unable to get cms items',
        exception: e,
        userId: this.rootStore.appStore.currentUserId,
        orgId: this.rootStore.appStore.currentOrgId,
      })
    }
  }
}
