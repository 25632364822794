import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { CMSMediaItemAttachmentVM } from '../../../../training-items/view-models/CMSMediaItemAttachmentVM'
import MuxVideo from '../../../../shared/MuxVideo'
import { CircularProgress } from '@material-ui/core'
import BrokenImageRoundedIcon from '@material-ui/icons/BrokenImageRounded'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import 'swiper/swiper.scss'
import 'swiper/modules/effect-fade/effect-fade.scss'
import 'swiper/modules/lazy/lazy.scss'
import 'swiper/modules/navigation/navigation.scss'
import 'swiper/modules/pagination/pagination.scss'
import './ContentItem.scss'
import { useIsMounted } from '../../../../shared/useIsMounted'
import { isNumeric } from '../../../../shared/isNumeric'

interface Props {
  vm: CMSMediaItemAttachmentVM
}

const CMSMediaItemAttachment: React.FC<Props> = ({ vm }) => {
  const isMounted = useIsMounted()
  const [attempts, setAttempts] = React.useState<number>(0)
  const [cmsItemId, setCMSItemId] = React.useState<number>(vm ? vm.cmsItemId : 0)

  const handleLoad = () => {
    if (!isMounted) return
    if (vm.isLoading) return
    vm.loadCMSItem().then(() => {
      setAttempts(attempts + 1)
    })
  }

  useEffect(() => {
    handleLoad()
  }, [])

  const renderSpinner = () => {
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  const renderBrokenMedia = () => {
    return (
      <div className='broken-media-container'>
        <BrokenImageRoundedIcon className={'broken-media'} />
      </div>
    )
  }

  if (!vm) return

  if (!vm.isLoaded) {
    if (attempts < 20) {
      handleLoad()
      return renderSpinner()
    }
    if (vm.cmsItemId !== cmsItemId) {
      setCMSItemId(vm.cmsItemId)
      setAttempts(0)
    }
    return renderBrokenMedia()
  }

  const renderCMSImage = (item: CMSMediaItemAttachmentVM) => {
    if (!item.isCMSItem) return
    if (!item.isImage) return
    if (item.hasVimeoURL) return
    if (item.hasYouTubeURL) return
    return (
      <div
        className='media-container'
        style={{ backgroundImage: `url("${item.url}")` }}
        onClick={() => item.toggleLightBox()}
      />
    )
  }

  const renderYouTubeVideo = (item: CMSMediaItemAttachmentVM) => {
    if (!item.isCMSItem) return
    if (!item.hasYouTubeURL) return
    return (
      <div className={'media-preview youtube iframewrapper'}>
        <iframe
          className='swiper-lazy'
          width={'100%'}
          height={'auto'}
          src={item.embedUrl}
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        />
      </div>
    )
  }

  const renderVimeoVideo = (item: CMSMediaItemAttachmentVM) => {
    if (!item.isCMSItem) return
    if (!item.hasVimeoURL) return
    return (
      <div className={'media-preview vimeo iframewrapper'}>
        <iframe
          className='swiper-lazy'
          width={'100%'}
          height={'auto'}
          src={item.embedUrl}
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        />
      </div>
    )
  }

  const renderCMSVideo = (item: CMSMediaItemAttachmentVM) => {
    if (!item.isVideo) return
    if (!item.isCMSItem) return
    if (item.isMuxVideo) return
    if (item.hasVimeoURL) return
    if (item.hasYouTubeURL) return

    return (
      <video
        id='contentVideo'
        // ref={(e) => item.loadMediaWatchSeconds(e)}
        // onTimeUpdate={(e) => item.setMediaWatchSeconds(e.currentTarget.currentTime)}
        controls
        key={item.media.objectId}
        className='media-container'
        src={`${item.url}`}
        preload='metadata'
      >
        <source src={item.url} type='video/mp4' />
      </video>
    )
  }

  const renderMuxVideo = (item: CMSMediaItemAttachmentVM) => {
    if (!item.isVideo) return
    if (!item.isMuxVideo) return

    const cmsItemId = isNumeric(item.objectId) ? Number(item.objectId) : item.cmsItemId

    return (
      <div className={'mux-player-container'}>
        <MuxVideo
          cmsItemId={`${cmsItemId}`}
          playbackUrl={item.url}
          playbackId={item.thumbnailUrl}
          videoTitle={item.fileName}
        />
      </div>
    )
  }

  const renderLightBox = (item: CMSMediaItemAttachmentVM) => {
    if (!item.isCMSItem) return
    if (!item.isImage) return
    if (item.hasVimeoURL) return
    if (item.hasYouTubeURL) return
    if (!item.showLightBox) return
    const lightBoxStyles = { overlay: { zIndex: 10000 } }
    return (
      <Lightbox
        mainSrc={item.url}
        reactModalStyle={lightBoxStyles}
        onCloseRequest={() => item.toggleLightBox()}
      ></Lightbox>
    )
  }

  return (
    <>
      {renderCMSImage(vm)}
      {renderYouTubeVideo(vm)}
      {renderVimeoVideo(vm)}
      {renderCMSVideo(vm)}
      {renderMuxVideo(vm)}
      {renderLightBox(vm)}
    </>
  )
}

export default inject()(observer(CMSMediaItemAttachment))
