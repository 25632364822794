import { computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { IContactImportRowDTO } from '../dtos/IContactImportRowDTO'

export class ContactImportRowVM {
  private rootStore: RootStore
  private row: IContactImportRowDTO

  constructor(rootStore: RootStore, row: IContactImportRowDTO) {
    this.row = row
    const val = this.row.result.updated.clientId
    this.rootStore = rootStore
  }

  @computed
  public get height(): number {
    let height = 105
    height = height + 26 * this.rowsCount
    return height
  }

  @computed
  public get rowsCount(): number {
    let rows = 1
    if (this.hasError) return 0
    if (this.updatedName) rows++
    if (this.updatedTitle) rows++
    if (this.addedTitle) rows++
    if (this.updatedTitle) rows++
    if (this.addedClient) rows++
    if (this.updatedClient) rows++
    if (this.updatedEmail) rows++
    return rows
  }

  @computed
  public get hasError() {
    return !this.row.result.success
  }

  @computed
  public get rowIndex(): string {
    return this.row.rowIndex.toString()
  }

  @computed
  public get isNewContact(): boolean {
    return this.row.result.isNew
  }

  @computed
  public get key() {
    return 'cont' + this.row.rowIndex
  }

  @computed
  public get contactErrorMessage(): string {
    if (!this.row.result) return ''
    if (!this.row.result.errorMessage) return ''
    return this.row.result.errorMessage
  }

  @computed
  public get name(): string {
    if (!this.row.result) return ''
    if (!this.row.result.contact) return this.row['Name']
    return this.row.result.contact.name
  }

  @computed
  public get email(): string {
    if (!this.row.result) return ''
    if (!this.row.result.contact) return ''
    return this.row.result.contact.email
  }

  @computed
  public get title(): string {
    if (!this.row.result) return ''
    if (!this.row.result.contact) return ''
    return this.row.result.contact.title
  }

  @computed
  public get clientName(): string {
    if (!this.row.result) return ''
    if (!this.row.result.contact) return ''
    if (!this.row.result.contact.clientId) return this.row['Client']
    const foundClient = this.rootStore.clientsStore.getClient(this.row.result.contact.clientId)
    if (foundClient) return foundClient.name
    return ''
  }

  @computed
  public get updatedName() {
    const val = this.row.result.updated.name
    if (!val) return ''
    return val
  }

  @computed
  public get updatedEmail() {
    const val = this.row.result.updated.email
    if (!val) return ''
    return val
  }

  @computed
  public get updatedTitle() {
    const val = this.row.result.updated.title
    if (!val) return ''
    return val
  }

  @computed
  public get addedTitle() {
    const val = this.row.result.added.title
    if (!val) return ''
    return val
  }

  @computed
  public get addedClient() {
    const clientId = this.row.result.added.clientId
    if (!clientId) return ''
    const client = this.rootStore.clientsStore.getClient(clientId)
    if (!client) return ''
    return client.name
  }

  @computed
  public get updatedClient() {
    const clientId = this.row.result.updated.clientId
    if (!clientId) return ''
    const client = this.rootStore.clientsStore.getClient(clientId)
    if (!client) return ''
    return client.name
  }
}
