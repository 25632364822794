import React from 'react'
import { inject, observer, useLocalStore } from 'mobx-react'
import Dropzone from 'react-dropzone'
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
  Theme,
  DialogActions,
  InputLabel,
  TextField,
  CircularProgress,
  Box,
  DialogTitle,
  DialogContentText,
  IconButton,
  Typography,
  Switch,
  withStyles,
  Tooltip,
} from '@material-ui/core'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import CloseIcon from '@material-ui/icons/Close'
import DescriptionIcon from '@material-ui/icons/Description'
import ImageIcon from '@material-ui/icons/ImageRounded'
import VideoIcon from '@material-ui/icons/VideocamRounded'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlankRounded'
import CheckBoxIcon from '@material-ui/icons/CheckBoxRounded'
import Autocomplete from '@material-ui/lab/Autocomplete'
import MuxPlayer from '@mux/mux-player-react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import env from '../../../../env'
import { RootStore } from '../../../stores/RootStore'
import LocalizationStore from '../../../localization/LocalizationStore'
import ContentPickerDialogHeader from './CMSItemsPickerDialogHeader'
import { FileTypeEnum } from '../../../upload/enum/FileTypeEnum'
import { IParseFileSaveResult } from '../../../upload/interfaces/IParseFileSaveResult'
import { ICMSItemUploadRequest } from '../../interfaces/ICMSItemUploadRequest'
import { FileUploadModalVM } from '../../../upload/view-models/FileUploadModalVM'
import { CMSItemVM } from '../../view-models/CMSItemVM'
import { CMSItemsPreviewVM } from '../../view-models/CMSItemsPreviewVM'
import { blue, teal } from '@material-ui/core/colors'
import './CMSItemsDialog.scss'

interface Props {
  vm: CMSItemsPreviewVM
  localizationStore?: LocalizationStore
  rootStore?: RootStore
  isOpen: boolean
  fileTypes?: FileTypeEnum
  addAfterSave?: Function
  toggleShowModal?: Function
}

const useStyles = makeStyles((theme: Theme) => ({
  backDrop: {
    backdropFilter: 'blur(1px)',
    backgroundColor: 'rgba(0,0,30,0.9)',
  },
  input: {
    textAlign: 'center',
    '&::placeholder': {
      textAlign: 'center',
    },
  },
}))

const CMSItemsPreviewDialog: React.FC<Props> = ({
  vm,
  localizationStore,
  rootStore,
  isOpen,
  fileTypes = FileTypeEnum.DEFAULT,
  addAfterSave,
  toggleShowModal,
}) => {
  const classes = useStyles()
  const { contentManagement: lz } = localizationStore.lzStrings

  const localStore = useLocalStore(() => ({
    vm: new FileUploadModalVM(rootStore, false, addAfterSave, toggleShowModal, fileTypes),
  }))
  const fileUploadVM = localStore.vm

  if (!vm) return null

  const item = vm.item
  if (!item) return null

  const handleClose = () => {
    if (vm.isReadOnly) {
      vm.doneTried = false
      vm.closePreviewDialog()
      handleReset()
      return
    }

    vm.doClose = true
    vm.toggleConfirmDialog()
  }

  const handleCancel = () => {
    if (!vm.isReadOnly) {
      handleReset()
      vm.toggleReadOnly()
      return
    }
  }

  const handleDelete = () => {
    vm.showDeleteConfirmDialog()
  }

  const handleReset = () => {
    if (vm.isReadOnly) {
      vm.reset()
      fileUploadVM.reset()
      return
    }

    vm.doReset = true
    vm.toggleConfirmDialog()
  }

  const handleDone = () => {
    if (vm.isReadOnly) return vm.toggleReadOnly()

    vm.doneTried = true
    if (!vm.isValid) return
    vm.isSaving = true
    vm.handleEdit()
  }

  const handleImageLoadError = (item: CMSItemVM) => {
    item.handleImageLoadError()
  }

  const handleImageLoad = (item: CMSItemVM) => {
    item.handleImageLoad()
  }

  const handleTitleChange = (event: React.ChangeEvent<{ value: string }>) => {
    vm.title = event.target.value || ''
  }

  const handleDescriptionChange = (event: React.ChangeEvent<{ value: string }>) => {
    vm.description = event.target.value || ''
  }

  const handleExtVideoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    vm.toggleExtVideoEnabled()
    fileUploadVM.reset()
  }

  const handleExtVideoUrlChange = (event: React.ChangeEvent<{ value: string }>) => {
    vm.setExtVideoUrl(event.target.value || '')
  }

  const handlePrivateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    vm.togglePrivateEnabled()
  }

  const renderDialogTitle = () => {
    return (
      <div className='intro-text intro-header'>
        <ContentPickerDialogHeader
          title={'Content Item Details'}
          onClose={handleClose}
          onDelete={handleDelete}
        />
      </div>
    )
  }

  const renderContentInfo = () => {
    return (
      <div className='content-upload-dialog-form'>
        <form className={'dialog-form'}>
          <Grid container spacing={2} direction='column' className='settings-group'>
            {renderExtVideo()}
            {renderTitle()}
            {renderDescription()}
            {renderPrivateSwitch()}
            {renderTags()}
          </Grid>
        </form>
      </div>
    )
  }

  const renderTitle = () => {
    return (
      <Grid item sm={12}>
        <InputLabel required htmlFor='content-name' className={'form-group-label'}>
          Title
        </InputLabel>
        <Tooltip
          title={vm.isReadOnly ? `Click 'Edit' to modify this field` : ''}
          placement='bottom'
        >
          <span>
            <TextField
              error={!vm.isTitleValid}
              value={vm.title}
              onChange={handleTitleChange}
              fullWidth
              variant='outlined'
              className='content-name'
              inputProps={{ readOnly: vm.isReadOnly }}
              disabled={vm.isReadOnly || vm.isLoading || vm.isSaving}
              autoComplete='off'
            />
          </span>
        </Tooltip>
      </Grid>
    )
  }

  const renderDescription = () => {
    return (
      <Grid item sm={12}>
        <InputLabel htmlFor='content-description' className={'form-group-label'}>
          {lz.description}
        </InputLabel>
        <Tooltip
          title={vm.isReadOnly ? `Click 'Edit' to modify this field` : ''}
          placement='bottom'
        >
          <span>
            <TextField
              multiline
              error={!vm.isDescriptionValid}
              value={vm.description}
              minRows={3}
              onChange={handleDescriptionChange}
              fullWidth
              variant='outlined'
              className='content-description'
              inputProps={{ readOnly: vm.isReadOnly, maxLength: 255 }}
              disabled={vm.isReadOnly || vm.isLoading || vm.isSaving}
              autoComplete='off'
              helperText={lz.max_chars_helper}
            />
          </span>
        </Tooltip>
      </Grid>
    )
  }

  const renderPrivateSwitch = () => {
    return (
      <Grid item sm={12}>
        <InputLabel htmlFor='content-ext-video' className={'form-group-label'}>
          Private Upload
        </InputLabel>
        <Tooltip
          title={vm.isReadOnly ? `Click 'Edit' to modify this field` : ''}
          placement='bottom'
        >
          <span>
            <Switch
              checked={vm.isPrivate}
              onChange={handlePrivateChange}
              name='preview-private-switch'
              inputProps={{ readOnly: vm.isReadOnly }}
              disabled={vm.isReadOnly || vm.isLoading || vm.isSaving}
            />
          </span>
        </Tooltip>
      </Grid>
    )
  }

  const renderExtVideo = () => {
    return (
      <Grid item>
        <InputLabel htmlFor='content-ext-video' className={'form-group-label'}>
          Type
        </InputLabel>
        {renderExtVideoSwitch()}
      </Grid>
    )
  }

  const ExtVideoSwitch = withStyles({
    switchBase: {
      color: teal[300],
      '&$checked': {
        color: teal[500],
      },
      '&$checked + $track': {
        backgroundColor: blue[500],
      },
    },
    checked: {},
    track: {},
  })(Switch)

  const renderExtVideoSwitch = () => {
    return (
      <Grid item sm={12}>
        <Grid container spacing={1} direction='row'>
          <Grid item className={'switch-label-group'}>
            <Typography className={'switch-label'}>File</Typography>
          </Grid>
          <Grid item>
            <Tooltip
              title={`The content type (i.e File or External Video URL) cannot be changed once it has been uploaded to the content management system.`}
              placement='bottom'
            >
              <span>
                <ExtVideoSwitch
                  checked={vm.isExternalVideo}
                  onChange={handleExtVideoChange}
                  name='upload-ext-video-switch'
                  inputProps={{ readOnly: false }}
                  disabled={true}
                />
              </span>
            </Tooltip>
          </Grid>
          <Grid item className={'switch-label-group'}>
            <Typography className={'switch-label'}>Video URL (Vimeo/YouTube)</Typography>
          </Grid>
          {renderExtVideoUrl()}
        </Grid>
      </Grid>
    )
  }

  const renderExtVideoUrl = () => {
    if (!vm.isExternalVideo) return
    return (
      <Grid item sm={12}>
        <Tooltip
          title={vm.isReadOnly ? `Click 'Edit' to modify this field` : ''}
          placement='bottom'
        >
          <span>
            <TextField
              error={!vm.isExtVideoUrlValid}
              value={vm.extVideoUrl}
              onChange={handleExtVideoUrlChange}
              fullWidth
              variant='outlined'
              className='content-ext-video-url'
              inputProps={{ readOnly: vm.isReadOnly }}
              disabled={vm.isReadOnly || vm.isLoading || vm.isSaving}
              autoComplete='off'
              placeholder='eg. https://vimeo.com/1234'
            />
          </span>
        </Tooltip>
        <DialogContentText className={'file-details'}>
          <span className={'file-details-title-sub'}>Supported URLs:</span> YouTube and Vimeo
        </DialogContentText>
      </Grid>
    )
  }

  const renderTags = () => {
    if (!vm.canManageTags) return

    return (
      <Grid item sm={12}>
        <InputLabel htmlFor='content-ext-video' className={'form-group-label'}>
          Tags
        </InputLabel>
        <Tooltip
          title={vm.isReadOnly ? `Click 'Edit' to modify this field` : ''}
          placement='bottom'
        >
          <span>
            <Autocomplete
              className={'toolbar-filter'}
              key={'content-preview-tags'}
              size='small'
              limitTags={5}
              multiple
              disableCloseOnSelect
              disabled={vm.isReadOnly || vm.isLoading || vm.isSaving}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                    checkedIcon={<CheckBoxIcon fontSize='small' />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </React.Fragment>
              )}
              onChange={(e, val) => {
                if (val === null) {
                  vm.setTagValues([])
                } else {
                  vm.setTagValues(val)
                }
              }}
              value={vm.hasTags ? [...vm.tags] : []}
              options={vm.tagOptions}
              getOptionLabel={(option) => option}
              getOptionSelected={(option, value) => {
                return option === value
              }}
              renderInput={(params) => (
                <TextField
                  label=''
                  key={'content-preview-tags-text'}
                  type='search'
                  variant='outlined'
                  placeholder={''}
                  inputProps={{ readOnly: vm.isReadOnly }}
                  disabled={vm.isReadOnly || vm.isLoading || vm.isSaving}
                  {...params}
                />
              )}
            />
          </span>
        </Tooltip>
      </Grid>
    )
  }

  const renderPreview = () => {
    if (!item) return
    if (item.type === 'image') {
      return (
        <img
          className={item.loading ? 'media-off' : 'media-preview image'}
          id={`preview-img-${item.title}-${item.itemId}`}
          key={`preview-img-${item.title}-${item.itemId}`}
          src={item.thumbnail}
          alt={item.altText}
          onLoad={() => handleImageLoad(item)}
          onClick={() => item.toggleLightBox()}
        />
      )
    } else if (item.type === 'video') {
      if (item.hasOptimizedVideo) {
        return (
          <MuxPlayer
            streamType='on-demand'
            playbackId={item.thumbnail}
            metadata={{
              video_id: item.url,
              video_title: vm.title,
              viewer_user_id: rootStore.appStore.currentUserId,
            }}
            className={'mux-player'}
          />
        )
      } else {
        if (item.hasExternalVideo) {
          const extVideo = item.item.External_Video
          if (extVideo.provider === 'youtube') return renderYouTubeVideo(extVideo.providerUid)
          if (extVideo.provider === 'vimeo') return renderVimeoVideo(extVideo.providerUid)
        }
        return (
          <video
            className={item.loading ? 'media-off' : 'media-preview image'}
            id={`preview-video-${item.title}-${item.itemId}`}
            key={`preview-video-${item.title}-${item.itemId}`}
            src={`${item.thumbnail}`}
            preload='metadata'
            controls
            onLoadedData={() => handleImageLoad(item)}
            onError={() => {
              handleImageLoadError(item)
            }}
          />
        )
      }
    }
    handleImageLoad(item)
    return <DescriptionIcon className={'media-preview file'} />
  }

  const renderYouTubeVideo = (uuid: string) => {
    const url = `https://www.youtube-nocookie.com/embed/${uuid}?modestbranding=1&showinfo=0&controls=1&rel=0`
    return (
      <div
        className={item.loading ? 'media-off' : 'media-preview iframewrapper'}
        onClick={(evt) => {
          evt.stopPropagation()
          evt.preventDefault()
        }}
      >
        <iframe
          width={'auto'}
          height={'auto'}
          src={url}
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          onLoad={() => handleImageLoad(item)}
          onError={() => {
            handleImageLoadError(item)
          }}
          allowFullScreen
        />
      </div>
    )
  }

  const renderVimeoVideo = (providerUid: string) => {
    let uuid = providerUid
    if (uuid.includes('/')) {
      const regExp = /\/([^\/]+)\/?$/
      const match = uuid.match(regExp)
      if (match && match.length) uuid = match[1]
    }
    const url = `https://player.vimeo.com/video/${uuid}?controls=1`
    return (
      <div
        className={item.loading ? 'media-off' : 'media-preview iframewrapper'}
        onClick={(evt) => {
          evt.stopPropagation()
          evt.preventDefault()
        }}
      >
        <iframe
          src={url}
          width={'auto'}
          height={'auto'}
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          onLoad={() => handleImageLoad(item)}
          onError={() => {
            handleImageLoadError(item)
          }}
          allowFullScreen
        />
      </div>
    )
  }

  const renderIcon = (item: CMSItemVM) => {
    if (!item) return <DescriptionIcon className={'media-preview file'} />
    if (item.type === 'image') return <ImageIcon className={'media-preview file'} />
    if (item.type === 'video') return <VideoIcon className={'media-preview file'} />
    return <DescriptionIcon className={'media-preview file'} />
  }

  const renderPreviewType = (item: CMSItemVM) => {
    if (!item) return
    if (!item.loading) return
    return <div className='content-card-container-type'>{renderIcon(item)}</div>
  }

  const renderFile = () => {
    return (
      <Grid item xs={12} key={0} className='attachment-container'>
        {renderPreviewType(item)}
        {renderPreview()}
        {renderLightBox()}
      </Grid>
    )
  }

  const renderFiles = () => {
    return (
      <Grid container className='filesContainer'>
        {renderFile()}
      </Grid>
    )
  }

  const renderFileTooBig = (file: File, index: number) => {
    return (
      <Grid item xs={12} key={index} className={'errorContainer'}>
        <div className={'errorText'}>{`${file.name} ${lz.exceeds_max_size}.`}</div>
      </Grid>
    )
  }

  const renderFilesTooBig = () => {
    if (!fileUploadVM.filesTooBig) return null
    return (
      <Grid container className='errorContainer'>
        {fileUploadVM.filesTooBig.map((file, index) => {
          return renderFileTooBig(file, index)
        })}
      </Grid>
    )
  }

  const renderDefault = () => {
    if (fileUploadVM.uploadProcessing) return
    if (fileUploadVM.files.length > 0) return

    if (vm.item !== null) return
    return (
      <Grid container className='dialog-content-grid'>
        <DialogContent className='dialog-content'>
          <Dropzone
            accept={fileUploadVM.fileTypes}
            noDragEventsBubbling={true}
            onDrop={(files) => {
              //TODO handle update file preview
              fileUploadVM.handleFilesDrop(files)
              if (fileUploadVM.files && fileUploadVM.files.length > 0) {
                const fileVM = fileUploadVM.files[0]
                const upload = {
                  title: fileVM.name,
                  description: vm.description,
                  file: fileVM.file,
                  organizationId: rootStore.appStore.currentOrgId,
                } as ICMSItemUploadRequest
                vm.setUpload(upload)
              }
            }}
            multiple={fileUploadVM.allowMultiple}
            minSize={0}
          >
            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
              <>
                <div className='dropzone' {...getRootProps()}>
                  <input {...getInputProps()} />

                  {!isDragActive && (
                    <div className='iconContainer'>
                      <NoteAddIcon className='noteAddIcon' />
                      <p className='dropzoneText'>{lz.drag_and_drop_or_browse}</p>
                    </div>
                  )}

                  {isDragActive && !isDragReject && (
                    <div className='iconContainer'>
                      <ThumbUpIcon className='thumbUpIcon' />
                      <p className='dropzoneText'>{lz.file_good_to_go}</p>
                    </div>
                  )}

                  {isDragActive && isDragReject && (
                    <div className='iconContainer'>
                      <ThumbDownIcon className='thumbDownIcon' />
                      <p className='dropzoneText'>{lz.sorry_file_not_accepted}</p>
                    </div>
                  )}
                </div>
              </>
            )}
          </Dropzone>
          <div className='dialogText'>
            <DialogContentText className={'file-details'}>
              <span className={'file-details-title'}>{lz.accepted_file_types}</span>{' '}
              {fileUploadVM.acceptedFileTypes}
            </DialogContentText>
            <DialogContentText className={'file-details'}>
              <span className={'file-details-title'}>{lz.max_file_size}</span>{' '}
              {fileUploadVM.fileSizePrompt}
            </DialogContentText>
          </div>
        </DialogContent>
      </Grid>
    )
  }

  const renderProgress = () => {
    const useCloudinary = env.var.REACT_APP_MEDIA_UPLOAD_SERVICE === 'cloudinary'
    return (
      <div className='dropzone'>
        <div className='iconContainer'>
          <Typography variant='body1' className='dropzoneText'>
            {fileUploadVM.uploadProcessingText}
          </Typography>
          <Typography variant='caption' className='dropzoneText'>
            {fileUploadVM.uploadProcessingSubText}
          </Typography>
          {useCloudinary && (
            <Box position='relative' display='inline-flex'>
              <CircularProgress
                className={'uploadProgress'}
                variant='determinate'
                value={fileUploadVM.uploadProcessingProgress}
              />
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position='absolute'
                display='flex'
                alignItems='center'
                justifyContent='center'
              >
                <Typography variant='caption' component='div' color='textSecondary'>{`${Math.round(
                  fileUploadVM.uploadProcessingProgress
                )}%`}</Typography>
              </Box>
            </Box>
          )}
        </div>
      </div>
    )
  }

  const renderProcessing = () => {
    if (!fileUploadVM.uploadProcessing) return
    return (
      <Grid container className='dialog-content'>
        <DialogContent>
          {renderProgress()}
          <div className='dialogText'>
            <DialogContentText>
              {`${lz.accepted_file_types} ${fileUploadVM.acceptedFileTypes}`}
            </DialogContentText>
            <DialogContentText>{`${lz.max_file_size} ${fileUploadVM.fileSizePrompt}`}</DialogContentText>
          </div>
        </DialogContent>
      </Grid>
    )
  }

  const renderDropzone = () => {
    if (fileUploadVM.showErrors) return null
    return (
      <>
        {renderDefault()}
        {renderProcessing()}
        {renderFiles()}
        {renderFilesTooBig()}
      </>
    )
  }

  const renderError = (error: IParseFileSaveResult, index) => {
    return (
      <Grid item xs={12} key={index} className={'errorContainer'}>
        <div>{error.fileName}</div>
        <div className={'errorText'}>{error.error}</div>
      </Grid>
    )
  }

  const renderErrors = () => {
    if (!fileUploadVM.showErrors) return null
    return (
      <>
        <Grid
          className='dialog-title'
          container
          justifyContent='space-between'
          alignItems='center'
          style={{ paddingRight: '10px' }}
        >
          <Grid item>
            <DialogTitle>{lz.file_upload_error}</DialogTitle>
          </Grid>
          <Grid item>
            <IconButton onClick={() => fileUploadVM.toggleModal()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContentText className='errorDialog'>
          {`${lz.error_uploading_message}:`}
        </DialogContentText>
        <Grid container className='errorContainer'>
          {fileUploadVM.errors.map((error, index) => {
            return renderError(error, index)
          })}
        </Grid>
        <DialogActions>
          <Grid
            container
            className='dialog-actions'
            alignItems='center'
            justifyContent='space-between'
          >
            <Grid item>
              <Button
                variant='contained'
                size='large'
                onClick={() => fileUploadVM.toggleModal()}
                className='saveButton'
              >
                {lz.ok}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </>
    )
  }

  const renderBody = () => {
    return (
      <Grid item xs={12} className='body'>
        <Grid container spacing={2} direction='row'>
          <Grid item xs={12} className='content-info'>
            {renderDropzone()}
            {renderErrors()}
            {renderContentInfo()}
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderResetAction = () => {
    if (vm.isReadOnly) return
    return (
      <Button
        disabled={vm.isReadOnly || vm.isLoading || vm.isSaving}
        variant='outlined'
        onClick={(e) => handleReset()}
      >
        {lz.reset}
      </Button>
    )
  }

  const renderCancelAction = () => {
    if (vm.isReadOnly) return
    return (
      <Button
        disabled={vm.isLoading || vm.isSaving}
        variant='outlined'
        onClick={(e) => handleCancel()}
      >
        {lz.cancel}
      </Button>
    )
  }

  const renderApplyAction = () => {
    if (vm.isReadOnly) {
      return (
        <Button
          disabled={!vm.isValid || vm.isLoading || vm.isSaving}
          variant='contained'
          onClick={handleDone}
        >
          {lz.edit}
        </Button>
      )
    }

    return (
      <Button
        disabled={!vm.isDirty || !vm.isValid || vm.isLoading || vm.isSaving}
        variant='contained'
        onClick={handleDone}
      >
        {lz.update}
      </Button>
    )
  }

  const renderActionButtons = () => {
    return (
      <Grid item xs={12} className='dialog-action-buttons'>
        <Grid item>{renderResetAction()}</Grid>
        <Grid item>
          {renderCancelAction()}
          {renderApplyAction()}
        </Grid>
      </Grid>
    )
  }

  const renderDialogContent = () => {
    return (
      <DialogContent>
        <Grid
          className='content-upload-dialog-content'
          container
          direction='column'
          justifyContent='center'
          alignItems='stretch'
        >
          {renderBody()}
        </Grid>
      </DialogContent>
    )
  }

  const renderDialogActions = () => {
    return (
      <DialogActions>
        <Grid
          className='content-upload-dialog-actions'
          container
          direction='column'
          justifyContent='center'
          alignItems='stretch'
        >
          {renderActionButtons()}
        </Grid>
      </DialogActions>
    )
  }

  const renderSpinner = () => {
    if (!vm.isLoading && !vm.isSaving) return
    return (
      <div className={'spinner-container'}>
        <CircularProgress className='spinner' />
      </div>
    )
  }

  const renderLightBox = () => {
    if (!item) return
    if (!item.thumbnail) return
    if (item.type !== 'image') return
    if (!item.showLightBox) return
    const lightBoxStyles = { overlay: { zIndex: 10000 } }
    return (
      <Lightbox
        mainSrc={item.thumbnail}
        reactModalStyle={lightBoxStyles}
        onCloseRequest={() => item.toggleLightBox()}
      ></Lightbox>
    )
  }

  return (
    <Dialog
      id='ContentPreviewDialog'
      open={isOpen}
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
      onClose={handleClose}
      fullWidth
    >
      <div className={'dialog-container'}>{renderDialogTitle()}</div>
      {renderDialogContent()}
      {renderDialogActions()}
      {renderSpinner()}
    </Dialog>
  )
}

export default inject('localizationStore')(observer(CMSItemsPreviewDialog))
