import React from 'react'
import { ListItem, Grid, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'
import CheckIcon from '@material-ui/icons/Check'
import WarningIcon from '@material-ui/icons/Warning'
import './CategoryImportRow.scss'
import { CategoryImportRowVM } from '../../view-models/CategoryImportRowVM'

interface Props {
  row: CategoryImportRowVM
  style: any
}

const CategoryImportRow: React.FC<Props> = ({ row, style }) => {

  const renderName = () => {
    return (
      <Grid container item>
        <strong>Name:</strong> <Typography className={'import-row-text'}>{row.name}</Typography>
      </Grid>
    )
  }

  const renderDescription = () => {
    return (
      <Grid container item>
        <strong>Description:</strong> <Typography className={'import-row-text'}>{row.description}</Typography>
      </Grid>
    )
  }

  const renderPath = () => {
    return (
      <Grid container item>
        <strong>Path:</strong> <Typography className={'import-row-text'}>{row.path}</Typography>
      </Grid>
    )
  }

  // const renderAvailableForSurveys = () => {
  //   return (
  //     <Grid container item>
  //       <strong>Available for Surveys: </strong> {row.availableForSurveys}
  //     </Grid>
  //   )
  // }

  // const renderAvailableForTasks = () => {
  //   return (
  //     <Grid container item>
  //       <strong>Available for Tasks: </strong> {row.availableForTasks}
  //     </Grid>
  //   )
  // }

  // const renderAvailableForWork = () => {
  //   return (
  //     <Grid container item>
  //       <strong>Available for Work: </strong> {row.availableForWork}
  //     </Grid>
  //   )
  // }

  // const renderAvailableForSkills = () => {
  //   return (
  //     <Grid container item>
  //       <strong>Available for Skills: </strong> {row.availableForSkills}
  //     </Grid>
  //   )
  // }

  const renderContactErrorMessage = () => {
    if (!row.contactErrorMessage) return
    return (
      <Grid container item className='row-error-text'>
        <strong>Error: </strong> {row.contactErrorMessage}
      </Grid>
    )
  }

  const renderSuccessfulRow = () => {
    if (row.hasError) return
    return (
      <>
        {renderName()}
        {renderPath()}
        {renderDescription()}
        {/* {renderAvailableForSurveys()}
        {renderAvailableForTasks()}
        {renderAvailableForWork()}
        {renderAvailableForSkills()} */}
      </>
    )
  }

  return (
    <Grid container style={style} id='CategoryImportRow'>
      <Grid container item>
        <strong>Row: {row.rowIndex}</strong>
      </Grid>
      {renderContactErrorMessage()}
      {renderSuccessfulRow()}
    </Grid>
  )
}

export default observer(CategoryImportRow)
