import React from 'react'
import { ParticipantsSelectVM } from '../view-models/ParticipantsSelectVM'
import { observer, inject } from 'mobx-react'
import { LabelsStore } from '../../labels/store/LabelsStore'
import GroupedRow from './GroupedRow'

interface Props {
  participantsSelectVM: ParticipantsSelectVM
  labelsStore?: LabelsStore
}

const FromEventTab: React.FC<Props> = ({ participantsSelectVM, labelsStore }) => {
  const vm = participantsSelectVM
  if (vm.currentTab !== 5) return null

  return (
    <div id='FromEventTab'>
      <GroupedRow row={vm.eventParticipantGroups[0]} />
      <GroupedRow row={vm.eventParticipantGroups[1]} />
      <GroupedRow row={vm.eventParticipantGroups[2]} />
      <GroupedRow row={vm.eventParticipantGroups[3]} />
    </div>
  )
}

export default inject('labelsStore')(observer(FromEventTab))
