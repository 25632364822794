import { RootStore } from 'src/app/stores/RootStore'
import { action, computed } from 'mobx'
import PulseCampaignType from '../aggregate/PulseCampaignType'

export class PulseCampaignTypesManageVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @computed
  public get pulseCampaignTypes(): PulseCampaignType[] {
    return this.rootStore.pulseCampaignTypesStore.pulseCampaignTypes.map((p) => p)
  }

  @computed
  public get isLoaded(): boolean {
    return this.rootStore.pulseCampaignTypesStore.isLoaded
  }

  @action
  public navigateBackToMainScreen() {
    this.rootStore.appStore.router.push('/systemAdmin/manage/pulseCampaignTypes')
  }

  @action
  public navigateToNewPulseCampaignType() {
    this.rootStore.appStore.router.push('/systemAdmin/manage/pulseCampaignTypes/new')
  }

  @action
  public editPulseCampaignType(objectId: string) {
    return this.rootStore.appStore.router.push(`/systemAdmin/manage/pulseCampaignTypes/${objectId}`)
  }
}
