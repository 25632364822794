import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { EventsStore } from '../../store/EventsStore'
import ParticipantsToolbar from './CalendarEventParticipantTabToolbar'
import ParticipantsSelect from '../../../participants-select/views/ParticipantsSelect'

interface ICalendarEventDrawerParticipantsTab {
  localizationStore?: LocalizationStore
  eventsStore?: EventsStore
}

const CalendarEventDrawerParticipantsTab: React.FC<ICalendarEventDrawerParticipantsTab> = ({
  localizationStore,
  eventsStore,
}) => {
  const { editVM: vm } = eventsStore
  return (
    <>
      <ParticipantsToolbar />
      <div className='calendar-event-drawer-participants-select-container'>
        <div>
          <ParticipantsSelect participantsSelectVM={vm.participantsSelectVM} />
        </div>
      </div>
    </>
  )
}

export default inject(
  'localizationStore',
  'eventsStore'
)(observer(CalendarEventDrawerParticipantsTab))
