import { Dialog, DialogContent, DialogTitle, DialogActions, Button, CircularProgress } from '@material-ui/core'
import { AgGridReact } from 'ag-grid-react'
import { inject, observer } from 'mobx-react'
import React from 'react'
import LocalizationStore from '../../localization/LocalizationStore'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import './UserAnnouncementEventsDialog.scss'
import { AnnouncementParticipantsListVM } from 'src/app/announcements/view-models/analytics/AnnouncementParticipantsListVM'


interface Props {
    listVM: AnnouncementParticipantsListVM
    localizationStore?: LocalizationStore
}

const UserAnnouncementEventsDialog: React.FC<Props> = ({ listVM, localizationStore }) => {
  const eventsVM = listVM.eventsVM
  const { lzStrings } = localizationStore
  if (!eventsVM) return

  const renderSpinner = () => {
    if (!eventsVM.isLoadingFirstPage) return
    return (
    <div className='loading'>
      <CircularProgress size={'40px'} />
    </div>
    )
  }
  return (
    <Dialog
      className='userAnnouncementEvents'
      maxWidth={'lg'}
      onClose={() => listVM.toggleShowEventDialog()}
      open={listVM.showEventDialog}
    >
      <DialogTitle>Participant Events: {eventsVM.getName}</DialogTitle>
      <DialogContent>
        <div className='ag-theme-alpine'>
          <AgGridReact
            gridOptions={{ suppressCellSelection: true, rowModelType: 'infinite' }}
            rowSelection={'multiple'}
            suppressRowClickSelection={true}
            suppressClickEdit={false}
            columnDefs={eventsVM.columnDefs}
            onGridReady={(e) => eventsVM.onGridReady(e)}
            getRowNodeId={(e) => eventsVM.getRowNodeId(e)}
            editType=''
          />
          {renderSpinner()}
        </div>
        
      </DialogContent>
      <DialogActions>
        <Button variant='contained' size='large' onClick={() => listVM.toggleShowEventDialog()}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default inject('localizationStore')(observer(UserAnnouncementEventsDialog))
