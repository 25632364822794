import React from 'react'
import {
  InputLabel,
} from '@material-ui/core'
import moment from 'moment'
import { DatePicker } from '@material-ui/pickers'

const DateInput = props => {
  return (
    <div className={props.className}>
      <InputLabel htmlFor={props.id} required={props.required}>
          {props.displayName}
      </InputLabel>
      <DatePicker
          disabled={props.disabled}
          required
          id={props.id}
          variant='inline'
          inputVariant='outlined'
          margin='none'
          fullWidth
          autoOk
          helperText={null}
          value={props.value}
          error={false}
          onChange={(date: Date) => {
            const beginOfDay = moment(date).startOf('day')
            props.onChange(beginOfDay.toDate())
          }}
          format='MMM dd, yyyy'
        />
    </div>  
  )
}

export default DateInput
