import { computed, observable } from 'mobx'
import PulseCategory from '../../pulse-categories/aggregate/PulseCategory'
import { IPulseCategoryDTO } from '../../pulse-categories/dtos/IPulseCategoryDTO'
import { DataStore } from '../../shared/data/DataStore'
import { RootStore } from '../../stores/RootStore'
import PulseQuestion from '../aggregate/PulseQuestion'
import { IPulseQuestionDTO } from '../dtos/IPulseQuestionDTO'
import { IPulseQuestionsFindRequest } from '../interfaces/IPulseQuestionsFindRequest'
import { PulseQuestionsService } from '../services/PulseQuestionsService'

export class PulseQuestionsDataStore extends DataStore<PulseQuestion, IPulseQuestionDTO> {
  private request: IPulseQuestionsFindRequest

  constructor(rootStore: RootStore, request: IPulseQuestionsFindRequest) {
    super(rootStore, PulseQuestion, 'pulseQuestions', [
      'objectId',
      'title',
      'type',
      'pulseSets',
      'pulseCategoryId',
      'options',
      'ratingDisplay',
      'media',
      'isDefault',
      'required',
      'fk_pulseCategoryId',
      'fk_parentCategoryId',
      'fk_grandCategoryId',
      'youTubeURL',
      'vimeoURL',
      'originalPulseQuestionId',
      'showMediaFirst',
      'isDropdownMultiSelect',
      'placeholder'
    ])
    this.request = request
    this.paged = true
  }

  @observable public isLoaded: boolean = false

  public onListRecordsLoaded() {
    this.isLoaded = true
  }

  public setRequest(request: IPulseQuestionsFindRequest) {
    this.request = request
  }

  public getRequest(): IPulseQuestionsFindRequest {
    return this.request
  }

  protected getListRecordsApiCall() {
    return async () => {
      const req: IPulseQuestionsFindRequest = {
        ...this.request,
        listColumns: this.listColumns,
      }
      const svc = new PulseQuestionsService(this.rootStore)
      const result = await svc.getPulseQuestions(req)
      this.totalRecords = result.count
      return result.pulseQuestions
    }
  }

  public get rows() {
    return this.records
  }

}
