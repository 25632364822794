import {
  ColDef,
  ColumnApi,
  GridApi,
  GridOptions,
  GridReadyEvent,
  PaginationChangedEvent,
  RowClickedEvent,
} from 'ag-grid-community'
import { action, computed, observable, reaction } from 'mobx'
import { AGGridTooltip } from './AGGridTooltip'

export abstract class AGGridVM {
  protected quickFilterTO: NodeJS.Timer

  protected abstract get columnDefs()
  public abstract get rows(): any[]
  protected abstract rowClicked(e: RowClickedEvent)

  public gridApi: GridApi
  public columnApi: ColumnApi
  public sizeColumnsToFit: boolean = true
  public serverSideLoaded: boolean = false
  protected usePrettyTooltip: boolean = false
  @observable public paginationFrom: number
  @observable public paginationTo: number
  @observable public paginationRowCount: number
  @observable public typedFilterText: string = ''
  protected dynamicRowHeight: boolean = false
  protected listMode: boolean = false
  protected rowHeight: number = 34

  public get shouldRender() {
    return true
  }

  protected get baseGridOptions(): GridOptions {
    const opts: GridOptions = {
      defaultColDef: this.defaultColDef,
      onGridReady: (e) => this.onGridReady(e),
      onPaginationChanged: (e) => this.updatePagination(e),
      isExternalFilterPresent: () => this.isExternalFilterPresent(), //TODO: Refactor to a boring protected boolean that would get set in an implementing class's constructor
      doesExternalFilterPass: (node) => this.doesExternalFilterPass(node),
      onFirstDataRendered: (params) => this.onFirstDataRendered(params),
      pagination: true,
      paginationAutoPageSize: true,
      headerHeight: 34,
      icons: {
        sortAscending: `<i class='fa fa-caret-down' />`,
        sortDescending: `<i class='fa fa-caret-up' />`,
      },
      suppressAnimationFrame: true,
      suppressPaginationPanel: true,
      suppressDragLeaveHidesColumns: true,
      suppressChangeDetection: true,
      //suppressClickEdit: true,
      suppressCellSelection: true,
      immutableData: true,
      rowModelType: this.serverSideLoaded ? 'infinite' : 'clientSide',
      enableBrowserTooltips: this.usePrettyTooltip ? false : true,
      // tooltipShowDelay: 1000,
      // tooltipMouseTrack: true,
      components: {
        agGridTooltip: AGGridTooltip,
      },
    }
    if (!this.dynamicRowHeight) opts.rowHeight = this.rowHeight
    if (this.dynamicRowHeight) opts.getRowHeight = (e) => this.getRowHeight(e)
    if (this.listMode) opts.isFullWidthCell = () => true
    return opts
  }

  @action
  public setQuickFilter(val: string) {
    this.typedFilterText = val
    if (this.quickFilterTO) clearTimeout(this.quickFilterTO)
    this.quickFilterTO = setTimeout(() => this.gridApi.setQuickFilter(this.typedFilterText), 1000)
  }

  public reload() {
    this.onGridReadied()
    const model = this.columnApi.getColumnState()
    this.columnApi.applyColumnState({
      state: model,
    })
  }

  @computed
  public get hasFilter(): boolean {
    return Boolean(this.typedFilterText)
  }

  protected get defaultColDef(): ColDef {
    const def: ColDef = {
      sortable: true,
      resizable: true,
    }
    if (this.usePrettyTooltip) def.tooltipComponent = 'agGridTooltip'
    return def
  }

  protected getRowHeight(e) {
    return 34
  }

  protected get totalRecords() {
    return undefined
  }

  public goToPreviousPage() {
    this.gridApi.paginationGoToPreviousPage()
  }

  public goToNextPage() {
    this.gridApi.paginationGoToNextPage()
  }

  @action
  public updatePagination(e?: PaginationChangedEvent) {
    if (!this.gridApi) return
    let pageSize = this.gridApi.paginationGetPageSize()
    let currentPage = this.gridApi.paginationGetCurrentPage()
    this.paginationRowCount = this.totalRecords
      ? this.totalRecords
      : this.gridApi.getDisplayedRowCount()
    this.paginationFrom = currentPage * pageSize + 1
    this.paginationTo = this.paginationFrom + pageSize - 1
    if (this.paginationTo > this.paginationRowCount) this.paginationTo = this.paginationRowCount
    if (this.paginationRowCount === 0) {
      this.paginationTo = 0
      this.paginationFrom = 0
    }
  }

  @computed
  public get isPaginationBackDisabled(): boolean {
    if (!this.paginationFrom || this.paginationFrom === 1) return true
    return false
  }

  @computed
  public get isPaginationForwardDisabled(): boolean {
    if (!this.paginationTo || this.paginationTo === this.paginationRowCount) return true
    return false
  }

  protected onGridReadied() {}

  protected ensureTooltipFields(arr: ColDef[]) {
    return arr.map((e: ColDef) => {
      if (e.tooltipField === 'null') {
        e.tooltipField = null
        return e
      }
      if (!e.tooltipField) {
        e.tooltipField = e.field
        return e
      }
      return e
    })
  }

  private onFirstDataRendered(params) {
    return
    params.api.sizeColumnsToFit()
    setTimeout(() => {
      const colIds = params.columnApi.getAllColumns().map((c) => c.colId)
      params.columnApi.autoSizeColumns(colIds)
    }, 500)
  }

  public onGridReady(e: GridReadyEvent) {
    this.gridApi = e.api
    this.columnApi = e.columnApi
    if (this.sizeColumnsToFit) this.gridApi.sizeColumnsToFit()
    this.updatePagination()
    this.setReaction()
    this.onGridReadied()
  }

  private setReaction() {
    if (this.serverSideLoaded) return
    reaction(
      () => this.rows,
      () => this.refreshClientSideRows(),
      { delay: 100 }
    )
  }

  private refreshClientSideRows() {
    if (!this.gridApi) return
    this.gridApi.setRowData(this.rows)
    // if (this.gridApi.getDisplayedRowCount() === this.rows.length) return
    // const rowsToRemove = []
    // this.gridApi.forEachNode((e) => {
    //   const foundVM = this.rows.find((r) => r.rowNodeId === e.data.rowNodeId)
    //   if (!foundVM) rowsToRemove.push(e.data)
    // })
    // this.gridApi.applyTransaction({ remove: rowsToRemove })
  }

  protected isExternalFilterPresent(): boolean {
    return false
  }

  protected doesExternalFilterPass(node) {
    return true
  }

  protected dateComparator = (valA, valB, nodeA, nodeB, isInverted) => {
    const dateA = new Date(valA).getTime()
    const dateB = new Date(valB).getTime()
    if (dateA === dateB) return 0
    else if (dateA > dateB) return 1
    return -1
  }
}
