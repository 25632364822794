import React from 'react'
import { InputLabel, TextField } from '@material-ui/core'

export default class StyledInputs extends React.Component<any, any> {
  render() {
    return (
      <div>
        <h5>Textfield w/Floating Label</h5>
        <div>
          <form>
            <InputLabel>Label</InputLabel>
            <TextField
              id='outlined-name'
              margin='dense'
              variant='outlined'
              placeholder={'Placeholder'}
            />
          </form>
        </div>

        <h5>Required Field</h5>
        <div>
          <form>
            <InputLabel required>Label</InputLabel>
            <TextField
              id='outlined-name'
              margin='dense'
              variant='outlined'
              placeholder={'Placeholder'}
            />
          </form>
        </div>
      </div>
    )
  }
}
