import { RootStore } from '../../../stores/RootStore'
import { computed, action } from 'mobx'
import { Goal } from '../../aggregate/Goal'
import { GoalsCatalogWidgetVM } from './GoalCatalogWidgetVM'
import { GoalsService } from '../../service/GoalsService'
import { IValueFactResponse } from '../../dto/IValueFactResponse'

export class GoalsCatalogWidgetRowVM {
  private goal: Goal
  private rootStore: RootStore
  private catalogWidgetVM: GoalsCatalogWidgetVM

  constructor(rootStore: RootStore, catalogWidgetVM: GoalsCatalogWidgetVM, goal: Goal) {
    this.rootStore = rootStore
    this.goal = goal
    this.catalogWidgetVM = catalogWidgetVM
  }

  @computed
  public get objectId(): string {
    return this.goal.objectId
  }

  @computed
  public get dataSource(): any {
    if (!this.catalogWidgetVM) return null
    return this.catalogWidgetVM.dataSources.filter((ds) => ds.id === this.goal.dataSourceId)[0]
  }

  @computed
  public get facet(): any {
    return this.dataSource.facets.filter((facet) => facet.id === this.goal.facetId)[0]
  }

  @computed
  public get dataSourceAndFacetString(): string {
    if (!this.dataSource || !this.facet) return ''
    return `${this.dataSource.name} - ${this.facet.field}`
  }

  @computed
  public get thumbnail(): string {
    return this.goal.thumbnail
  }

  @computed
  public get name(): string {
    return this.goal.name
  }

  @computed
  public get description(): string {
    return this.goal.description
  }

  @action
  public editCatalogGoal() {
    this.rootStore.appStore.router.push(`/goals/from-catalog/edit/${this.objectId}`)
  }

  @action
  public assignCatalogGoal() {
    this.rootStore.appStore.router.push(`/goals/from-catalog/assign/${this.objectId}`)
  }
}
