import React from 'react'
import { inject, observer } from 'mobx-react'
import Dropzone from 'react-dropzone'
import CloseIcon from '@material-ui/icons/Close'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  DialogActions,
  Button,
  CircularProgress,
} from '@material-ui/core'
import './CustomRecordsImportModal.scss'
import ErrorRow from '../import/ErrorRow'
import { CustomRecordsImportModalVM } from '../../view-models/import-records/CustomRecordsImportModalVM'

interface Props {
  vm: CustomRecordsImportModalVM
}

const CustomRecordsImportModal: React.FC<Props> = ({ vm }) => {
  const renderErrors = () => {
    if (!vm.hasError) return null

    return (
      <>
      {vm.errorRows.map((e, idx) => (
        <ErrorRow
          key={'er' + idx}
          row={e}
        />
      ))}
      </>
    )
  }

  const renderDropZoneFile = () => {
    return (
      <Dropzone
        onDrop={(file) => vm.handleFileDrop(file)}
        accept='.csv,text/csv'
        multiple={false}
        minSize={0}
        maxSize={vm.MAX_FILE_SIZE}
      >
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
          <section>
            <div className='dropzone' {...getRootProps()}>
              <input {...getInputProps()} />
              {!vm.hasError && !vm.hasFile && !isDragActive && (
                <div className='iconContainer'>
                  <NoteAddIcon className='noteAddIcon' />
                  <p className='dropzoneText'>
                    Drag and drop or <span className='dropLink'>browse</span> your file
                  </p>
                </div>
              )}
              {!vm.hasError && vm.hasFile && (
                <div className='iconContainer'>
                  <ThumbUpIcon className='thumbUpIcon' />
                  <p className='dropzoneText'>This file looks good to go!</p>
                </div>
              )}
              {!vm.hasError && isDragReject && (
                <div className='iconContainer'>
                  <ThumbDownIcon className='thumbDownIcon' />
                  <p className='dropzoneText'>Sorry, this file type isn't accepted</p>
                </div>
              )}
              {vm.hasError && (
                <div className='iconContainer'>
                  <ThumbDownIcon className='thumbDownIcon' />
                  <p className='dropzoneText'>
                    There are some errors with this file. Correct errors and try again.
                  </p>
                  <p className='dropzoneText'>
                    Drag and drop or <span className='dropLink'>browse</span> your updated file
                  </p>
                </div>
              )}
            </div>
          </section>
        )}
      </Dropzone>
    )
  }

  const renderDropZone = () => {
    if (!vm.showDropZone) return null
    return (
      <Grid container className='dialog-content'>
        <DialogContent>
          <DialogContentText className='dialogText'> Upload a spreadsheet to import data from. Duplicates will be ignored.</DialogContentText>
          <DialogContentText className='dialogText'> Acceptable formats: csv </DialogContentText>
          {renderDropZoneFile()}
        </DialogContent>
      </Grid>
    )
  }

  const renderDropZoneNotProcessing = () => {
    if (!vm.showSpinner) return null
    return (
      <Grid container className='dialog-content'>
        <DialogContent>
          <div className='dropzone'>
            <div className='iconContainer'>
              <CircularProgress className='progressCircle' />
              <p className='dropzoneText'>{vm.processingMsg}</p>
            </div>
          </div>
        </DialogContent>
      </Grid>
    )
  }

  const renderDialogButtons = () => {
    if (vm.importProcessing) return null

    return (
      <Grid container className='dialog-actions' alignItems='center' justifyContent='flex-end'>
        <Grid item>
          <Button
            variant='text'
            size='large'
            onClick={() => vm.toggleShown()}
            disabled={vm.importProcessing}
            className='cancelButton'
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            size='large'
            onClick={() => vm.import()}
            disabled={!vm.validateEnabled}
            className='saveButton'
          >
            Import
          </Button>
        </Grid>
      </Grid>
    )
  }

  return (
    <div id='CustomRecordsImportModal'>
      <Dialog onClose={() => vm.toggleShown()} open={vm.shown} fullWidth>
        <Grid
          className='dialog-title'
          container
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item>
            <DialogTitle>{vm.getTitle()}</DialogTitle>
          </Grid>
          <Grid item>
            <IconButton onClick={() => vm.toggleShown()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid className={vm.processedRowsCount ? 'dialog-body' : ''}>
          {renderDropZone()}
          {renderDropZoneNotProcessing()}
        </Grid>
        <Grid className={'errors'}>
          {renderErrors()}
        </Grid>
        <DialogActions>
          {renderDialogButtons()}
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default inject('usersStore')(observer(CustomRecordsImportModal))
