import { computed, action, observable } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { Survey } from '../../aggregate/Survey'
import moment from 'moment'
import SurveyType from '../../../survey-types/store/aggregate/SurveyType'
import { AGGridRowVM } from '../../../shared/ag-grid/AGGridRowVM'
import { SurveysTabVM } from './SurveysTabVM'
import SurveyTag from '../../../survey-tags/aggregate/SurveyTag'

export class ManageSurveysWidgetRowVM extends AGGridRowVM<Survey> {
  constructor(rootStore: RootStore, tabVM: SurveysTabVM, survey: Survey) {
    super(rootStore, survey, tabVM)
    this.tabVM = tabVM
    this.lz = this.rootStore.localizationStore.lzStrings.surveys
    this.labels = this.rootStore.labelsStore
  }

  private tabVM: SurveysTabVM
  public lz = null
  public labels = null

  @computed
  public get mainCategoryId(): string {
    return this.aggregate.mainCategoryId
  }

  @computed
  public get participants() {
    return this.aggregate.participants
  }

  @computed
  public get surveyType(): SurveyType | null {
    if (!this.aggregate.surveyTypeId) return null
    return this.rootStore.surveyTypesStore.surveyTypes.find(
      (e) => e.objectId === this.aggregate.surveyTypeId
    )
  }

  @computed
  public get surveyTypeName(): string {
    if (this.surveyType && this.surveyType.name) return this.surveyType.name
    return '-'
  }

  @computed
  public get surveyTag(): SurveyTag | null {
    if (this.aggregate.surveyTagIds.length === 0) return null
    return this.rootStore.surveyTagsStore.surveyTags.find(
      (e) => e.objectId === this.aggregate.surveyTagIds[0]
    )
  }

  @computed
  public get surveyTagName(): string {
    if (this.surveyTag && this.surveyTag.name) return this.surveyTag.name
    return '-'
  }

  @computed
  public get objectId() {
    return this.aggregate.objectId
  }

  @computed
  public get name() {
    return this.aggregate.name
  }

  @computed
  public get schedule(): string {
    if (!this.aggregate.schedule) return '--'
    return this.aggregate.schedule.repeat
  }

  @computed
  public get ownerUserId() {
    if (!this.aggregate.fk_publishedByUserId) return ''
    return this.aggregate.fk_publishedByUserId.objectId
  }

  @computed
  public get ownerName() {
    if (!this.aggregate.fk_publishedByUserId) return '(Unknown)'
    return this.aggregate.fk_publishedByUserId.name
  }

  @computed
  public get ownerIconURL() {
    if (!this.aggregate.fk_publishedByUserId) return ''
    return this.aggregate.fk_publishedByUserId.iconURL
  }

  @computed
  public get published(): boolean {
    return this.aggregate.published
  }

  @computed
  public get isClosed(): boolean {
    return this.aggregate.isClosed
  }

  @computed
  public get publishedDateTime() {
    if (!this.aggregate.published) return '0000'
    return this.aggregate.publishedDateTime
  }

  @computed
  public get publishedDateFormated() {
    if (!this.aggregate.published) return '(Draft)'
    const s = this.rootStore.localizationStore.lzStrings.manageSurveysWidget
    const formattedDate = moment(this.aggregate.publishedDateTime).format(s.published_date_format)
    return formattedDate
  }

  @computed
  public get completed() {
    const participants = this.aggregate.numberOfParticipants || 0
    const responses = this.aggregate.uniqueUserResponses ? this.aggregate.uniqueUserResponses : 0
    return `${responses}/${participants}`
  }

  @computed
  public get questions() {
    return this.aggregate.questions.length
  }

  @action
  public openSurvey() {
    this.rootStore.appStore.router.push('/surveys/editv2/' + this.objectId)
  }

  @action
  public toggleCloseSurveyDialog() {
    if (!this.tabVM.widgetVM.closeSurveyDialogOpen) {
      this.tabVM.widgetVM.setSelectedSurveyRow(this.aggregate.objectId)
    }
    this.tabVM.widgetVM.closeSurveyDialogOpen = !this.tabVM.widgetVM.closeSurveyDialogOpen
  }

  @action
  public toggleSendRemindersDialog() {
    if (!this.tabVM.widgetVM.sendRemindersDialogOpen) {
      this.tabVM.widgetVM.setSelectedSurveyRow(this.aggregate.objectId)
    }
    this.tabVM.widgetVM.toggleSendRemindersDialog()
  }
}
