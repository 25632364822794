import React from 'react'
import { Route } from 'react-router'
import Layout from '../../layout/Layout'
import { authenticate } from '../../auth/routes/AuthenticatedRoute'
import CustomCollectionEditScreen from '../views/edit/CustomCollectionEditScreen'

export default function getCustomCollectionsRoutes() {
  return (
    <Route key='cc0' component={authenticate(Layout)} path='/customCollections'>
      <Route path='edit/:id' component={CustomCollectionEditScreen} />
    </Route>
  )
}
