import { observable, computed, action } from 'mobx'
import { TrainingPlan } from '../../../aggregates/TrainingPlan'
import { RootStore } from '../../../../stores/RootStore'
import { UserTrainingPlan } from '../../../user-training-plans/aggregate/UserTrainingPlan'
import moment from 'moment'
import { AudienceMember } from '../../../aggregates/AudienceMember'
import { UserTrainingPlanRowVM } from './UserTrainingPlanRowVM'

export class TrainerWidgetRowVM {
  private rootStore: RootStore
  public trainingPlan: TrainingPlan

  constructor(trainingPlan: TrainingPlan, rootStore: RootStore) {
    this.rootStore = rootStore
    this.trainingPlan = trainingPlan
  }

  @observable public isExpanded: boolean = false

  @computed
  public get userTrainingPlans(): UserTrainingPlan[] {
    return this.rootStore.userTrainingPlansStore.orgUserTrainingPlans.filter(
      (plan) => plan.trainingPlanId === this.trainingPlan.objectId
    )
  }

  @computed
  public get title(): string {
    return this.trainingPlan.title
  }

  @computed
  public get completedPlanUserIds(): string[] {
    const ids = []
    this.userTrainingPlans.filter((plan) => {
      if (!plan.trainingPlanItems.some((item) => !item.isComplete)) ids.push(plan.userId)
    })
    return ids
  }

  @computed
  public get incompletePlanUserIds(): string[] {
    const ids = []
    this.userTrainingPlans.filter((plan) => {
      if (plan.trainingPlanItems.some((item) => !item.isComplete)) ids.push(plan.userId)
    })
    return ids
  }

  @computed
  public get categories(): string {
    const catNames = []
    this.trainingPlan.categories.forEach((cat) => {
      const category = this.rootStore.categoriesStore.getCategory(cat)
      if (!category) return
      catNames.push(category.name)
    })
    return catNames.join(', ')
  }

  @computed
  public get assignedUsers(): AudienceMember[] {
    return this.trainingPlan.assignedToAudienceMembers
  }

  @computed
  public get dueDate(): string {
    const s = this.rootStore.localizationStore.lzStrings.training_plans
    return moment(this.trainingPlan.dueDate).format(s.due_date_format2)
  }

  @computed
  public get userTrainingPlanRows(): UserTrainingPlanRowVM[] {
    return this.userTrainingPlans.map(
      (plan) => new UserTrainingPlanRowVM(this.rootStore, this, plan)
    )
  }

  @action
  public toggleIsExpanded() {
    this.isExpanded = !this.isExpanded
  }
}
export default TrainerWidgetRowVM
