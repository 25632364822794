import { action, observable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Attachment } from '../aggregate/Attachment'

export abstract class AttachmentVM {
  protected rootStore: RootStore
  @observable protected attachment: Attachment
  @observable public showLightBox: boolean = false

  constructor(rootStore: RootStore, attachment: Attachment, idx: number = 0) {
    this.rootStore = rootStore
    this.attachment = attachment
    this.index = idx
  }

  public index: number = 0

  public abstract get isImage(): boolean
  public abstract get isVideo(): boolean
  public abstract get fileName(): string
  public abstract get shorterFileName(): string
  public abstract get url(): string
  public abstract get thumbnailUrl(): string
  public abstract get isLoaded(): boolean

  public abstract get isCMSItem(): boolean
  public abstract openAttachment(): Promise<any>
  
  @action
  public toggleLightBox() {
    this.showLightBox = !this.showLightBox
  }

}
