import React from 'react'
import { inject, observer } from 'mobx-react'
import './TemplateRowActions.scss'
import { IconButton, Tooltip, Typography } from '@material-ui/core'
import SkillsIcon from '@material-ui/icons/BarChartRounded'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import EditIcon from '@material-ui/icons/Edit'
import { CampaignTemplateRowVM } from '../../../view-models/dashboard/templates/CampaignTemplateRowVM'
import LocalizationStore from '../../../../localization/LocalizationStore'

interface Props {
  vm: CampaignTemplateRowVM
  localizationStore?: LocalizationStore
}

const TemplateRowActions: React.FC<Props> = ({ vm, localizationStore }) => {
  if (!vm) return null
  const lz = localizationStore.lzStrings.pulseCampaignDashboard

  // const renderEditCampaignAnalysis = () => {
  //   if (!vm.hasVizPermission) return null
  //   return (
  //     <Tooltip
  //       title={lz.view_analysis}
  //       placement='bottom-start'
  //       enterDelay={500}
  //     >
  //       <IconButton
  //         style={{ padding: '3px', backgroundColor: 'transparent' }}
  //         onClick={(e) => {
  //           e.stopPropagation()
  //           e.preventDefault()
  //           vm.editPulseAnalysis()
  //         }}
  //       >
  //         <SkillsIcon className='action-icon' />
  //       </IconButton>
  //     </Tooltip>
  //   )
  // }

  const renderDuplicateCampaign = () => {
    return (
      <Tooltip
        title={lz.duplicate}
        placement='bottom-start'
        enterDelay={500}
      >
        <IconButton
          style={{ padding: '3px', backgroundColor: 'transparent' }}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            vm.copyTemplate()
          }}
        >
          <FileCopyIcon className='action-icon' />
        </IconButton>
      </Tooltip>
    )
  }

  const renderEditCampaign = () => {
    if (!vm.canManage) return null
    return (
      <Tooltip
        title={lz.edit}
        placement='bottom-start'
        enterDelay={500}
      >
        <IconButton
          style={{ padding: '3px', backgroundColor: 'transparent' }}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            vm.editTemplate()
          }}
        >
          <EditIcon className='action-icon' />
        </IconButton>
      </Tooltip >
    )
  }

  return (
    <div id='TemplateRowActions'>
      {/* {renderEditCampaignAnalysis()} */}
      {renderDuplicateCampaign()}
      {renderEditCampaign()}
    </div>
  )
}

export default inject('localizationStore')(observer(TemplateRowActions))
