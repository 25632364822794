import React, { FC } from 'react'
import { observer, inject } from 'mobx-react'
import './SurveyOptions.scss'
import { SurveysStore } from 'src/app/surveys/store/SurveysStore'
import { FormControl, Select, MenuItem } from '@material-ui/core'
import LocalizationStore from 'src/app/localization/LocalizationStore'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { LabelsStore } from '../../../../labels/store/LabelsStore'

interface Props {
  surveysStore?: SurveysStore
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
}

const RecurringOptions: FC<Props> = ({ surveysStore, localizationStore, labelsStore }) => {
  const surveyEditVM = surveysStore.viewModels.editVM
  const vm = surveyEditVM.surveyOptionsVM
  if (!surveyEditVM) return null
  if (!vm) return null
  if (vm.publishMode === 'immediately') return null
  const s = localizationStore.lzStrings.surveys
  return (
    <div className='section recurring-options-content'>
      <div className='primary-text'>{s.recurring_options}</div>
      <div className='section-content'>
        <ToggleButtonGroup
          value={surveyEditVM.repeatMode === 'no repeat'}
          exclusive
          onChange={() => {
            if (surveyEditVM.repeatMode === 'no repeat') {
              surveyEditVM.setRepeatMode('daily')
            } else {
              surveyEditVM.setRepeatMode('no repeat')
            }
          }}
        >
          <ToggleButton value={true}>{`${s.send} ${labelsStore.getLabelByLanguageAndFor('pulse')} ${
            s.once
          }`}</ToggleButton>
          <ToggleButton value={false}>{s.send_repeatedly}</ToggleButton>
        </ToggleButtonGroup>

        {surveyEditVM.repeatMode === 'no repeat' ? null : (
          <div className='repeat-mode-container'>
            <FormControl variant='outlined' className='repeat-mode-select'>
              <Select
                value={surveyEditVM.repeatMode}
                onChange={(e) => {
                  surveyEditVM.setRepeatMode(e.target.value as string)
                }}
              >
                <MenuItem value={'daily'}>Daily</MenuItem>
                <MenuItem value={'weekly'}>Weekly</MenuItem>
                <MenuItem value={'monthly'}>Monthly</MenuItem>
                <MenuItem value={'quarterly'}>Quarterly</MenuItem>
                <MenuItem value={'yearly'}>Yearly</MenuItem>
              </Select>
            </FormControl>
            {surveyEditVM.repeatMode === 'weekly' && (
              <div className='weekly-container'>
                <ToggleButtonGroup
                  value={surveyEditVM.selectedWeekdays}
                  className='weekday-button-group'
                >
                  {surveyEditVM.weekdays.map((e, index) => (
                    <ToggleButton
                      key={'wd' + index}
                      value={index}
                      onClick={() => e.toggleChecked()}
                    >
                      {e.subString}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default inject(
  'surveysStore',
  'localizationStore',
  'labelsStore'
)(observer(RecurringOptions))
