import * as React from 'react'

//MobX Stores
import { inject, observer } from 'mobx-react'
import { OptionForm } from '../forms/OptionsForm'

//MaterialUI Components
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import { TextField, InputAdornment, Button } from '@material-ui/core'

//Components
import EmojiMenu from './DrawerEmojiMenu'
import OptionMenu from './DrawerOptionMenu'
import ResponseTypeMenu from './DrawerResponseTypeMenu'
import WeightMenu from './DrawerWeightMenu'
import { SortableHandle } from 'react-sortable-hoc'

//Styles
import './OptionsList.scss'

interface DrawerOptionRowProps {
  option: OptionForm
  idx: number
}

const DragHandle = SortableHandle(() => (
  <Button className='drag-handle'>
    <DragIndicatorIcon />
  </Button>
))

const DrawerOptionRow: React.FC<DrawerOptionRowProps> = ({ option, idx }) => {
  const handleChange = e => {
    option.handleTextChange(e.target.value)
  }

  return (
    <TextField
      key={'opttxt' + idx}
      id={'opttxt' + idx}
      value={option.text || ''}
      onChange={handleChange}
      margin='none'
      fullWidth
      variant='outlined'
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <ResponseTypeMenu idx={idx} />
            <EmojiMenu idx={idx} />
            <WeightMenu idx={idx} option={option} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position='end'>
            <OptionMenu idx={idx} />
            <DragHandle />
          </InputAdornment>
        ),
      }}
    />
  )
}

export default inject('questionsStore')(observer(DrawerOptionRow))
