import { observable, action } from 'mobx'
import { serializable } from 'serializr'
import { IFieldDTO } from '../dto/IFieldDTO'
import { FieldType } from '../types/FieldType'
import { UserIdentifierType } from '../types/UserIdentifierType'

export class Field implements IFieldDTO {
  public id: number
  @serializable @observable public type: FieldType = 'String'
  @serializable @observable public displayName: string = ''
  @serializable @observable public fieldName: string = ''
  @serializable @observable public isCustomRecordKey: boolean = false
  @serializable @observable public isFacet: boolean = false
  @serializable @observable public isValueFactDate: boolean = false
  @serializable @observable public isDeleted: boolean = false
  @serializable @observable public isNew: boolean = false

  @action
  public setName(val) {
    this.displayName = val
  }

  @action
  public isUserIdentifier() {
    return this.type === 'User'
  }

  @action
  public toggleIsRecordKey() {
    this.isCustomRecordKey = !this.isCustomRecordKey
  }

  @action
  public toggleIsValueFactDate() {
    this.isValueFactDate = !this.isValueFactDate
  }

  @action
  public toggleIsFacet() {
    this.isFacet = !this.isFacet
  }

  @action
  public setFieldName(val: any) {
    this.fieldName = val
  }

  @action
  public setType(val: any) {
    this.type = val
  }

  @action
  public delete() {
    this.isDeleted = true
  }
}
