import React from 'react'
import { inject, observer } from 'mobx-react'
import {
  Box,
  Card,
  CardActions,
  CardActionArea,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  Tooltip,
  Checkbox,
  Chip,
  Avatar,
  Popover,
  List,
  ListItem,
  ListSubheader,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import DescriptionIcon from '@material-ui/icons/Description'
import ImageIcon from '@material-ui/icons/ImageRounded'
import VideoIcon from '@material-ui/icons/VideocamRounded'
import PublicIcon from '@material-ui/icons/PublicRounded'
import PrivateIcon from '@material-ui/icons/VpnLockRounded'
import TagIcon from '@material-ui/icons/LocalOfferRounded'
import AvatarGroup from '@material-ui/lab/AvatarGroup'
import LocalizationStore from '../../localization/LocalizationStore'
import { CMSItemVM } from '../view-models/CMSItemVM'
import ContentOptionMenu from './CMSItemsOptionMenu'
import { ICMSItemTag } from '../../cms-item-tags/interfaces/ICMSItemTag'
import { IExternalVideo } from '../interfaces/IExternalVideo'
import { CMSItemsPickerVM } from '../view-models/CMSItemsPickerVM'
import VimeoImage from '../../shared/VimeoImage'
import YouTubeImage from '../../shared/YouTubeImage'
import './CMSItemsCard.scss'

interface Props {
  vm: CMSItemsPickerVM
  item: CMSItemVM
  localizationStore?: LocalizationStore
}

const MAX_TAGS_DISPLAY = 2

const CMSItemsCard: React.FC<Props> = ({ vm, item, localizationStore }) => {
  const { contentManagement: lz } = localizationStore.lzStrings

  if (!item) return

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const popoverOpen = Boolean(anchorEl)

  const handleImageLoadError = (item: CMSItemVM) => {
    item.handleImageLoadError()
  }

  const handleImageLoad = (item: CMSItemVM) => {
    item.handleImageLoad()
  }

  const handleCheckedChange = (item: CMSItemVM) => {
    item.toggleChecked()
    vm.manageVM.toggleItem(item)
  }

  const renderCheckIndicator = (item: CMSItemVM) => {
    return (
      <CardHeader
        className={'content-card-header'}
        avatar={
          <Tooltip title={''} placement='bottom-start'>
            <span>
              <div className='content-card-header-sel'>
                <Checkbox
                  checked={item.isChecked}
                  color='primary'
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              </div>
            </span>
          </Tooltip>
        }
      />
    )
  }

  const renderPreviewIcon = (item: CMSItemVM) => {
    if (!item) return <DescriptionIcon className={'media-preview file'} />
    if (item.type === 'image') return <ImageIcon className={'media-preview file'} />
    if (item.type === 'video') return <VideoIcon className={'media-preview file'} />
    return <DescriptionIcon className={'media-preview file'} />
  }

  const renderType = (item: CMSItemVM) => {
    if (!item) return
    return <div className='content-icon-type'>{renderTypeIcon(item)}</div>
  }

  const renderTypeIcon = (item: CMSItemVM) => {
    if (!item) return <DescriptionIcon className={'content-icon-type file'} />
    if (item.type === 'image') return <ImageIcon className={'content-icon-type file'} />
    if (item.type === 'video') return <VideoIcon className={'content-icon-type file'} />
    return <DescriptionIcon className={'content-icon-type file'} />
  }

  const renderAccessIcon = (item: CMSItemVM) => {
    if (item.isPrivate) return <PrivateIcon className={'content-icon-type file'} />
    return <PublicIcon className={'content-icon-type file'} />
  }

  const renderAccess = (item: CMSItemVM) => {
    return (
      <div>
        <Tooltip
          title={item.isPrivate ? 'Private Access' : 'Global Tenant Access'}
          placement='bottom-start'
        >
          <span>{renderAccessIcon(item)}</span>
        </Tooltip>
      </div>
    )
  }

  const renderPopover = (item: CMSItemVM) => {
    if (item.tags.length <= MAX_TAGS_DISPLAY) return
    return (
      <Popover
        style={{ pointerEvents: 'none' }}
        id='tag-popover'
        className='tag-popover'
        PaperProps={{
          style: { minWidth: 150, maxWidth: 250, padding: '4px' },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={anchorEl}
        open={popoverOpen}
        disableAutoFocus
        disableEnforceFocus
        disableRestoreFocus
        onMouseLeave={handlePopoverClose}
        onClose={handlePopoverClose}
      >
        <div>
          <List
            subheader={
              <ListSubheader className='tag-popover-header'>
                <ListItemIcon>
                  <TagIcon className={'tag-popover-header-icon'} />
                </ListItemIcon>
                <ListItemText className={'tag-popover-header-text'} primary={'Tags'} />
              </ListSubheader>
            }
            component='nav'
            aria-label='tag popover list'
          >
            {item.tags.map((tag: ICMSItemTag) => {
              return (
                <ListItem key={`popup-tag-${tag.id}`} button>
                  <ListItemText primary={tag.Tag_Name} />
                </ListItem>
              )
            })}
          </List>
        </div>
      </Popover>
    )
  }

  const renderTags = (item: CMSItemVM) => {
    if (!item.hasTags) return
    if (!vm.manageVM.canManageTags) return
    return (
      <div className='content-icon-tags'>
        <AvatarGroup
          aria-owns={'tag-popover'}
          aria-haspopup='true'
          max={MAX_TAGS_DISPLAY}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          {item.tags.map((tag: ICMSItemTag) => renderTag(tag))}
        </AvatarGroup>
        {renderPopover(item)}
      </div>
    )
  }

  const renderTag = (tag: ICMSItemTag) => {
    if (!tag) return
    return (
      <Avatar key={`card-tag-${tag.id}`}>
        <Chip
          className={'content-type-chip'}
          variant={'outlined'}
          size={'small'}
          key={`content-type-chip-${tag.id}`}
          label={tag.Tag_Name}
        />
      </Avatar>
    )
  }

  const renderSkeleton = (item: CMSItemVM) => {
    return (
      <div id='content-card-container'>
        <div id='content-skeleton'>
          <Skeleton
            className={item.loading ? 'skeleton' : 'media-off'}
            animation={item.loading ? 'wave' : false}
            height={vm.manageVM.mediaHeight}
            width='100%'
          />
        </div>
      </div>
    )
  }

  const renderPreview = (item: CMSItemVM) => {
    if (!item.thumbnail) return

    if (item.type === 'image') {
      return (
        <img
          className={item.loading ? 'media-off' : 'media-preview image'}
          key={`card-img-${item.title}-${item.itemId}`}
          src={item.thumbnail}
          alt={item.altText}
          onLoad={() => handleImageLoad(item)}
        />
      )
    } else if (item.type === 'video') {
      if (item.hasOptimizedVideo) {
        return (
          <img
            className={item.loading ? 'media-off' : 'media-preview image'}
            key={`card-video-${item.title}-${item.itemId}`}
            src={`https://image.mux.com/${item.thumbnail}/thumbnail.png`}
            alt={item.altText}
            onLoad={() => {
              handleImageLoad(item)
            }}
            onError={() => {
              handleImageLoadError(item)
            }}
          />
        )
      } else {
        if (item.hasExternalVideo) {
          const extVideo: IExternalVideo = item.item.External_Video
          if (extVideo.provider === 'youtube') return renderYouTubeVideo(extVideo.providerUid)
          if (extVideo.provider === 'vimeo') return renderVimeoVideo(extVideo.providerUid)
        }
        return (
          <video
            className={item.loading ? 'media-off' : 'media-preview image'}
            key={`card-video-${item.title}-${item.itemId}-alt`}
            src={`${item.thumbnail}`}
            preload='metadata'
            onLoadedData={() => {
              handleImageLoad(item)
            }}
            onError={() => {
              handleImageLoadError(item)
            }}
          />
        )
      }
    }

    handleImageLoad(item)
    return <DescriptionIcon className={'media-preview file'} />
  }

  const renderYouTubeVideo = (providerUid: string) => {
    return (
      <YouTubeImage
        providerUuid={providerUid}
        height={160}
        width={278}
        style={{ position: 'absolute' }}
        onLoad={() => handleImageLoad(item)}
        onError={() => handleImageLoadError(item)}
      />
    )
  }

  const renderVimeoVideo = (providerUid: string) => {
    let uuid = providerUid
    if (uuid.includes('/')) {
      const regExp = /\/([^\/]+)\/?$/
      const match = uuid.match(regExp)
      if (match && match.length) uuid = match[1]
    }
    return (
      <VimeoImage
        providerUuid={uuid}
        height={160}
        width={278}
        style={{ position: 'absolute' }}
        onLoad={() => handleImageLoad(item)}
        onError={() => handleImageLoadError(item)}
      />
    )
  }

  const renderMenu = (item: CMSItemVM) => {
    return (
      <ContentOptionMenu
        key={`menu-${item.title}-${item.itemId}`}
        vm={item}
        previewVM={vm.previewVM}
      />
    )
  }

  return (
    <div
      key={`content-${item.itemId}`}
      id='content-sel'
      style={{ zIndex: 1 }}
      className='content-sel-item'
    >
      <div className='content-item'>
        <Grid
          item
          xs={3}
          sm={3}
          md={3}
          lg={3}
          xl={3}
          key={`media-grid-item-${item.itemId}`}
          className={'content-grid'}
        >
          <Card className={'content-card'}>
            <CardActionArea disableRipple>
              <CardContent
                onClick={(evt) => {
                  evt.stopPropagation()
                  evt.preventDefault()
                  handleCheckedChange(item)
                  vm.manageVM.setSelectedItemVM(item)
                }}
              >
                <Grid container className={'content-card-content'}>
                  <Grid item xs={12} className={'content-card-media'}>
                    <div>
                      <Box className={'media-box'} display='flex' alignItems='center'>
                        {renderSkeleton(item)}
                        <div className='content-card-container-type'>{renderPreviewIcon(item)}</div>
                        <div id='content-container-overlay'>
                          <div className={'skeleton-chart-container'}>
                            {renderCheckIndicator(item)}
                          </div>
                        </div>
                        {renderPreview(item)}
                      </Box>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider variant='fullWidth' />
            </CardActionArea>
            <div className='card-action-container'>
              <CardActions
                className='card-actions'
                disableSpacing
                onClick={(evt) => {
                  evt.stopPropagation()
                  evt.preventDefault()
                  handleCheckedChange(item)
                  vm.manageVM.setSelectedItemVM(item)
                }}
              >
                <div className='content-info'>
                  <Typography className={'content-card-body-text-bold'} noWrap>
                    {item.title}
                  </Typography>
                  <div className='content-card-info-row'>
                    <div className='content-card-info-row-left'>{renderType(item)}</div>
                    <div className='content-card-info-row-right'>{renderMenu(item)}</div>
                  </div>
                  <div className='content-card-info-row'>
                    <div className='content-card-info-row-left'>{renderTags(item)}</div>
                    <div className='content-card-info-row-right-access'>{renderAccess(item)}</div>
                  </div>
                </div>
              </CardActions>
            </div>
          </Card>
        </Grid>
      </div>
    </div>
  )
}

export default inject('localizationStore')(observer(CMSItemsCard))
