import React, { useRef, useState } from 'react'
import {
  TextField,
  Switch,
  InputLabel,
  FormControlLabel,
  Tooltip,
  Select,
  MenuItem,
  Checkbox,
  Grid,
  ListItemText,
  Typography,
  Button,
} from '@material-ui/core'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Slider from '@material-ui/core/Slider'
import { observer } from 'mobx-react'
import { inject } from 'mobx-react'
import { SurveysStore } from '../../../store/SurveysStore'
import './QuestionForm.scss'
import OptionsList from './OptionsList'
import MediaSelect from '../media/MediaSelect'
import PropTypes from 'prop-types'
import { QuestionsStore } from '../../../../questions/store/QuestionsStore'
import LocalizationStore from '../../../../localization/LocalizationStore'
import CategoryPicker from '../../../../shared/category-picker/CategoryPicker'
import { LabelsStore } from '../../../../labels/store/LabelsStore'
import { Editor } from '@tinymce/tinymce-react'
import { TOOLBAR, PLUGINS } from '../../../../config/TinyMCEconfig'
import Icon from '../builder/Icon'
import StepIndicators from '../../../../shared/StepIndicators'
import OptionsHeader from './OptionsHeader'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import { QuestionType } from '../../../../surveys/types/QuestionType'
import FileUploadModal from '../../../../upload/views/FileUploadModal'
import { FileTypeEnum } from '../../../../upload/enum/FileTypeEnum'
import { Attachment } from '../../../../attachments/aggregate/Attachment'
import PulseCategoryPicker from '../../../../shared/pulse-category-picker/PulseCategoryPicker'
import CMSItemsPickerDialog from '../../../../cms-items/views/dialogs/CMSItemsPickerDialog'
import { CMSItemsTypeEnum } from '../../../../cms-items/enum/CMSItemsTypeEnum'
import { OrganizationsStore } from '../../../../organizations/store/OrganizationsStore'

interface QuestionFormProps {
  surveysStore?: SurveysStore
  questionsStore?: QuestionsStore
  localizationStore?: LocalizationStore
  labelsStore?: LabelsStore
  organizationsStore?: OrganizationsStore
}

const QuestionForm: React.FC<QuestionFormProps> = ({
  surveysStore,
  questionsStore,
  localizationStore,
  labelsStore,
  organizationsStore,
}) => {
  const editorRef: any = useRef()
  const inputRef: any = useRef()

  const { editVM } = surveysStore.viewModels
  if (!editVM) return null

  const { lzStrings } = localizationStore

  if (!editVM.currentQuestion) return null
  const currentQuestionVM = editVM.currentQuestion

  const [update, setUpdate] = useState(false)
  React.useEffect(() => {
    if (update && inputRef && inputRef.current) {
      inputRef.current.focus()
      setUpdate(false)
    }
  }, [update])

  function tooltip(props) {
    const { children, value, open } = props

    const popperRef = React.useRef(null)
    React.useEffect(() => {
      if (popperRef.current) {
        popperRef.current.update()
      }
    })

    return (
      <Tooltip
        PopperProps={{
          popperRef,
        }}
        open={open}
        enterTouchDelay={0}
        placement='bottom'
        title={value}
      >
        {children}
      </Tooltip>
    )
  }

  tooltip.propTypes = {
    children: PropTypes.element.isRequired,
    value: PropTypes.number.isRequired,
    open: PropTypes.bool.isRequired,
  }

  const renderRatingDisplay = () => {
    if (editVM.currentQuestion.type !== 'rating') return null

    return (
      <div>
        <InputLabel>{lzStrings.surveyEditQuestionForm.rating}</InputLabel>
        <RadioGroup
          value={editVM.currentQuestion.ratingDisplay}
          onChange={(e) => editVM.currentQuestion.setRatingDisplay(e.target.value)}
        >
          <FormControlLabel
            value='horizontal'
            control={<Radio disabled={editVM.isReadOnly} />}
            label='Horizontal'
          />
          <FormControlLabel
            value='vertical'
            control={<Radio disabled={editVM.isReadOnly} />}
            label='Vertical'
          />
        </RadioGroup>
      </div>
    )
  }

  const onFocus = () => {
    setUpdate(true)
  }

  const renderMediaPercentage = () => {
    if (editVM.currentQuestion.media.type !== 'video') return null

    return (
      <div style={{ marginTop: '10px' }}>
        <InputLabel>{lzStrings.surveyEditQuestionForm.watch_percentage}</InputLabel>
        <Slider
          disabled={editVM.isReadOnly}
          value={editVM.currentQuestion.media.mediaWatchPercentage}
          ValueLabelComponent={tooltip}
          onChange={(e, value) => editVM.currentQuestion.setWatchPercentage(value)}
        />
      </div>
    )
  }

  const renderQuestionTypes = () => {
    return editVM.questionTypes.map((type, index) => {
      let icon = ''
      let color = ''
      let label = ''
      if (type === 'emoji') {
        icon = 'far fa-smile'
        color = 'brown'
        label = 'Emoji'
      }
      if (type === 'check') {
        icon = 'far fa-check-square'
        color = 'blue'
        label = 'Multi-select'
      }
      if (type === 'radio') {
        icon = 'far fa-dot-circle'
        color = 'green'
        label = 'Multiple Choice'
      }
      if (type === 'rating') {
        icon = 'fas fa-list-ol'
        color = 'orange'
        label = 'Rating'
      }
      if (type === 'starRating') {
        icon = 'fas fa-star'
        color = 'yellow'
        label = 'Star Rating'
      }
      if (type === 'text') {
        icon = 'fas fa-font'
        color = 'red'
        label = 'Short Answer'
      }
      if (type === 'yesNo') {
        icon = 'far fa-thumbs-up'
        color = 'purple'
        label = 'Yes or No'
      }
      if (type === 'infoText') {
        icon = 'fas fa-info-circle'
        color = 'blue'
        label = 'Info Text'
      }
      if (type === 'number') {
        icon = 'fas fa-calculator'
        color = 'orange'
        label = 'Number'
      }
      if (type === 'order') {
        icon = 'fas fa-sort-amount-up'
        color = 'pink'
        label = 'Order'
      }
      if (type === 'dropdown') {
        icon = 'fas fa-caret-square-down'
        color = 'green'
        label = 'Dropdown'
      }

      return (
        <MenuItem
          key={index}
          value={type}
          className={`type-menu-item ${color}`}
          onClick={(e) => {
            editVM.updateCurrentQuestionByType(type as QuestionType)
          }}
        >
          <Icon icon={icon} className='icon' />
          {label}
        </MenuItem>
      )
    })
  }

  const renderNextQuestionOptions = () => {
    return editVM.currentQuestion.options[0].nextQuestions.map((e, index) => {
      return (
        <MenuItem key={index} value={e.id}>
          <ListItemText primary={`Q${e.rank}: ${e.title}`} />
        </MenuItem>
      )
    })
  }

  const renderTextQuestionNextQuestionMenu = () => {
    if (!editVM.canSetOptionlessBranching) return null
    return (
      <>
        <InputLabel>Branching</InputLabel>
        <Select
          disabled={editVM.isReadOnly}
          className='type-select'
          variant='outlined'
          value={editVM.currentQuestion.options[0].optionlessBranch}
          onChange={(e) => editVM.currentQuestion.options[0].setOptionlessBranching(e.target.value)}
        >
          <MenuItem value={'Default: Next Question'}>
            <ListItemText primary={'Default: Next Question'} />
          </MenuItem>
          {renderNextQuestionOptions()}
          <MenuItem value={'Go To End'}>
            <ListItemText primary={'Go To End'} />
          </MenuItem>
        </Select>
      </>
    )
  }

  // const renderAnalyzeResponseSwitch = () => {
  //   if (!editVM.analyzeResponsesAvailable) return null
  //   if (editVM.currentQuestion.type !== 'text') return null
  //   return (
  //     <div style={{ marginTop: '10px' }}>
  //       <InputLabel>{lzStrings.surveyEditQuestionForm.analyze_response}</InputLabel>
  //       <Switch
  //         disabled={editVM.isReadOnly}
  //         checked={editVM.currentQuestion.analyzeResponse}
  //         onChange={() => editVM.currentQuestion.toggleAnalyzeResponse()}
  //         size='medium'
  //       />
  //     </div>
  //   )
  // }

  // const renderAnalyzeResponseTypes = () => {
  //   if (editVM.currentQuestion.type === 'infoText') return null
  //   if (!editVM.currentQuestion.analyzeResponse) return null
  //   return (
  //     <>
  //       <div style={{ marginTop: '10px' }}>
  //         <InputLabel className='response-range'>Analysis Options</InputLabel>
  //         <FormControlLabel
  //           className='sentiment-check'
  //           control={<Checkbox checked={true} disabled={true} />}
  //           label={'Sentiment'}
  //         />
  //         <FormControlLabel
  //           className='keyword-check'
  //           control={
  //             <Checkbox
  //               checked={editVM.currentQuestion.responseKeywords}
  //               onChange={() => editVM.currentQuestion.toggleResponseKeywords()}
  //             />
  //           }
  //           label={'Keywords'}
  //         />
  //         <FormControlLabel
  //           control={
  //             <Checkbox
  //               checked={editVM.currentQuestion.responseConcepts}
  //               onChange={() => editVM.currentQuestion.toggleResponseConcepts()}
  //             />
  //           }
  //           label={'Concepts'}
  //         />
  //         <FormControlLabel
  //           control={
  //             <Checkbox
  //               checked={editVM.currentQuestion.responseEntities}
  //               onChange={() => editVM.currentQuestion.toggleResponseEntities()}
  //             />
  //           }
  //           label={'Entities'}
  //         />
  //         <FormControlLabel
  //           control={
  //             <Checkbox
  //               checked={editVM.currentQuestion.responseEmotions}
  //               onChange={() => editVM.currentQuestion.toggleResponseEmotions()}
  //             />
  //           }
  //           label={'Emotions'}
  //         />
  //       </div>
  //       <div>
  //         <InputLabel className='response-range'>Sentiment Range</InputLabel>
  //         <span className='response-fields'>
  //           <TextField
  //             style={{ marginLeft: '10px' }}
  //             className='response-field'
  //             type='number'
  //             variant='outlined'
  //             onChange={(e) => editVM.currentQuestion.setResponseRangeMin(Number(e.target.value))}
  //             value={editVM.currentQuestion.responseRangeMin}
  //           />{' '}
  //           -{' '}
  //           <TextField
  //             style={{ marginLeft: '10px' }}
  //             className='response-field'
  //             type='number'
  //             variant='outlined'
  //             onChange={(e) => editVM.currentQuestion.setResponseRangeMax(Number(e.target.value))}
  //             value={editVM.currentQuestion.responseRangeMax}
  //           />
  //         </span>
  //       </div>
  //     </>
  //   )
  // }

  const handleApplyCategoryId = (categoryId) => editVM.currentQuestion.setCategory(categoryId)

  const handleApplyPulseCategoryId = (pulseCategoryId) =>
    editVM.currentQuestion.setPulseCategory(pulseCategoryId)

  const renderNormalTitleRow = () => {
    if (currentQuestionVM.type === 'infoText') return null
    return (
      <div id='question-label'>
        <InputLabel>{lzStrings.surveyEditQuestionForm.question}</InputLabel>
        <TextField
          autoFocus
          onFocus={onFocus}
          inputRef={inputRef}
          disabled={editVM.isReadOnly}
          error={!currentQuestionVM.titleValid}
          id='outlined-name'
          value={editVM.currentQuestion.title}
          onChange={(e) => {
            currentQuestionVM.setTitle(e.target.value)
            questionsStore.nameUnique = true
          }}
          margin='none'
          fullWidth
          variant='outlined'
          placeholder={"What's your question?"}
        />
      </div>
    )
  }

  const renderInfoTextTitleRow = () => {
    if (editVM.currentQuestion.type !== 'infoText') return
    return (
      <>
        <InputLabel>Info Text</InputLabel>
        <Editor
          value={editVM.currentQuestion.html}
          init={{
            height: 500,
            resize: false,
            menubar: false,
            plugins: `${PLUGINS} image media`,
            toolbar: `customInsertButton ${TOOLBAR}`,
            contextmenu: false,
            extended_valid_elements:
              'video[poster|src|controls|preload|width|height|data-setup],source[src|type]',
            images_reuse_filename: true,
            images_upload_handler: (blobInfo, success, failure, progress) =>
              currentQuestionVM.uploadToCloudinary(blobInfo, success, failure, progress),
            placeholder:
              'Add some info text to give participants context for the upcoming questions...',
            setup: (editor) => {
              editor.on('init', (e) => {
                currentQuestionVM.setEditorRef(editorRef)
              })
              editor.ui.registry.addMenuButton('customInsertButton', {
                text: 'Upload Media',
                tooltip:
                  'Upload an image or video from the rippleworx media library or your computer.',
                icon: 'upload',
                fetch: function (callback) {
                  let items: any = []
                  if (organizationsStore.currentOrganization?.hasCMSPicker) {
                    items = [
                      {
                        type: 'menuitem',
                        icon: 'gallery',
                        text: 'Content Management System',
                        onAction: () => {
                          currentQuestionVM.toggleMediaUploadPicker()
                        },
                      },
                    ]
                  } else {
                    items = [
                      {
                        type: 'menuitem',
                        icon: 'gallery',
                        text: 'RippleWorx Media Library',
                        onAction: () => {
                          currentQuestionVM.toggleMediaUploadPicker()
                        },
                      },
                      {
                        type: 'menuitem',
                        icon: 'browse',
                        text: 'My Computer',
                        onAction: () => {
                          currentQuestionVM.toggleMediaUploadModal()
                        },
                      },
                    ]
                  }
                  callback(items)
                },
              })
            },
          }}
          outputFormat='html'
          onEditorChange={(e) => {
            editVM.currentQuestion.setHTML(e)
            if (editorRef && editorRef.current) {
              editVM.currentQuestion.setTitle(
                editorRef.current?.editor?.contentWindow?.document?.body?.innerText
              )
            }
          }}
          ref={editorRef}
        />
      </>
    )
  }

  const renderTypeRow = () => {
    return (
      <>
        <InputLabel>{lzStrings.surveyEditQuestionForm.type}</InputLabel>
        <Select
          disabled={editVM.isReadOnly}
          className='type-select'
          variant='outlined'
          value={editVM.currentQuestion.type}
          onChange={(e) => editVM.currentQuestion.changeQuestionType(e.target.value)}
        >
          {renderQuestionTypes()}
        </Select>
      </>
    )
  }

  const renderCategoryRow = () => {
    if (editVM.isSurveyTemplateEdit && editVM.survey.organizationId === 'DEFAULT') return null
    if (editVM.currentQuestion.type === 'infoText') return null
    return (
      <div className='question-category-picker-row'>
        <InputLabel>{labelsStore.getLabelByLanguageAndFor('category')}</InputLabel>
        <CategoryPicker
          disabled={editVM.isReadOnly}
          onApply={handleApplyCategoryId}
          vm={editVM.questionCategoryPickerVM}
          currentQuestionVM={editVM.currentQuestion}
        />
      </div>
    )
  }

  const renderPulseCategoryRow = () => {
    if (editVM.currentQuestion.type === 'infoText') return null
    return (
      <div className='question-category-picker-row'>
        <InputLabel>{`${labelsStore.getLabelByLanguageAndFor(
          'pulse'
        )} ${labelsStore.getLabelByLanguageAndFor('category')}`}</InputLabel>
        <PulseCategoryPicker
          value={editVM.currentQuestion.pulseCategoryId}
          disabled={editVM.isReadOnly}
          onApply={handleApplyPulseCategoryId}
          currentQuestionVM={editVM.currentQuestion}
        />
      </div>
    )
  }

  const renderBranchMsg = () => {
    if (!editVM.currentQuestion.showBranchMsg) return null
    return (
      <Typography variant='h6' className='branch-error'>
        <WarningRoundedIcon />
        {lzStrings.surveyEditQuestionForm.branch_error}
      </Typography>
    )
  }

  const renderOptionsRow = () => {
    return (
      <>
        {editVM.currentQuestion.canHaveOptions ? (
          <InputLabel>{lzStrings.surveyEditQuestionForm.choices}</InputLabel>
        ) : null}
        {editVM.currentQuestion.canHaveOptions ? (
          <OptionsHeader surveysStore={surveysStore} />
        ) : null}
        {editVM.currentQuestion.canHaveOptions ? <OptionsList surveysStore={surveysStore} /> : null}
      </>
    )
  }

  const renderMediaRow = () => {
    if (editVM.currentQuestion.type === 'infoText') return null
    return (
      <>
        <MediaSelect />
        {renderMediaPercentage()}
      </>
    )
  }

  const renderRequiredRow = () => {
    if (editVM.currentQuestion.type === 'infoText') return null
    return (
      <>
        <InputLabel>{lzStrings.surveyEditQuestionForm.response_required}</InputLabel>
        <Switch
          disabled={editVM.isReadOnly}
          checked={editVM.currentQuestion.required}
          onChange={() => editVM.currentQuestion.toggleRequired()}
          size='medium'
        />
      </>
    )
  }

  const renderMinInput = () => {
    if (!editVM.currentQuestion.minimumRequired) return null
    return (
      <Grid item xs={6}>
        <InputLabel>Minimum Value</InputLabel>
        <TextField
          error={!currentQuestionVM.minMaxValid}
          disabled={editVM.isReadOnly}
          value={currentQuestionVM.minimumValueAllowed}
          onFocus={(event) => {
            event.target.select()
          }}
          onChange={(e) => {
            currentQuestionVM.setMinimumValueAllowed(e.target.value)
          }}
          InputProps={{
            endAdornment: (
              <StepIndicators
                onIncreaseStep={() => currentQuestionVM.increaseMinimumValueAllowed()}
                onDecreaseStep={() => currentQuestionVM.decreaseMinimumValueAllowed()}
              />
            ),
          }}
          margin='none'
          fullWidth
          variant='outlined'
          placeholder={'Minimum value allowed...'}
        />
      </Grid>
    )
  }

  const renderMaxInput = () => {
    if (!editVM.currentQuestion.maximumRequired) return null
    let hover = true

    return (
      <Grid item xs={6}>
        <InputLabel>Maximum Value</InputLabel>
        <TextField
          error={!currentQuestionVM.minMaxValid}
          onFocus={(event) => {
            event.target.select()
          }}
          disabled={editVM.isReadOnly}
          value={currentQuestionVM.maximumValueAllowed}
          onChange={(e) => {
            currentQuestionVM.setMaximumValueAllowed(e.target.value)
          }}
          InputProps={{
            endAdornment: (
              <StepIndicators
                onIncreaseStep={() => currentQuestionVM.increaseMaximumValueAllowed()}
                onDecreaseStep={() => currentQuestionVM.decreaseMaximumValueAllowed()}
              />
            ),
          }}
          margin='none'
          fullWidth
          variant='outlined'
          placeholder={'Maximum value allowed...'}
        />
      </Grid>
    )
  }

  const renderDefaultValueInput = () => {
    if (!editVM.currentQuestion.defaultValueRequired) return null
    return (
      <Grid item xs={6}>
        <InputLabel>Default Value</InputLabel>
        <TextField
          onFocus={(event) => {
            event.target.select()
          }}
          disabled={editVM.isReadOnly}
          value={currentQuestionVM.defaultValueNumeric}
          onChange={(e) => {
            currentQuestionVM.setDefaultValueNumeric(e.target.value)
          }}
          InputProps={{
            endAdornment: (
              <StepIndicators
                onIncreaseStep={() => currentQuestionVM.increaseDefaultValueNumeric()}
                onDecreaseStep={() => currentQuestionVM.decreaseDefaultValueNumeric()}
              />
            ),
          }}
          margin='none'
          fullWidth
          variant='outlined'
          placeholder={'Default Value...'}
        />
      </Grid>
    )
  }

  const renderStepInput = () => {
    if (!editVM.currentQuestion.numberStepRequired) return null
    return (
      <Grid item xs={6}>
        <InputLabel>Step</InputLabel>
        <TextField
          onFocus={(event) => {
            event.target.select()
          }}
          disabled={editVM.isReadOnly}
          value={editVM.currentQuestion.numberStep}
          onChange={(e) => {
            currentQuestionVM.setNumberStep(e.target.value)
          }}
          InputProps={{
            endAdornment: (
              <StepIndicators
                onIncreaseStep={() => currentQuestionVM.increaseNumberStep()}
                onDecreaseStep={() => currentQuestionVM.decreaseNumberStep()}
              />
            ),
          }}
          margin='none'
          fullWidth
          variant='outlined'
          placeholder={'Number Step...'}
        />
      </Grid>
    )
  }

  const renderNumberInputs = () => {
    if (editVM.currentQuestion.type !== 'number') return null
    return (
      <Grid container spacing={2} style={{ marginTop: '10px' }}>
        <Grid item xs={5}>
          <InputLabel>{lzStrings.surveyEditQuestionForm.decimals}</InputLabel>
          <TextField
            disabled={editVM.isReadOnly}
            onFocus={(event) => {
              event.target.select()
            }}
            error={editVM.currentQuestion.decimalError}
            InputProps={{
              endAdornment: (
                <StepIndicators
                  onIncreaseStep={() => currentQuestionVM.increaseDecimalsAllowed()}
                  onDecreaseStep={() => currentQuestionVM.decreaseDecimalsAllowed()}
                />
              ),
              inputProps: { min: 0, max: 6 },
            }}
            value={editVM.currentQuestion.decimalsAllowed}
            onChange={(e) => {
              currentQuestionVM.setDecimalsAllowed(parseFloat(e.target.value))
            }}
            margin='none'
            fullWidth
            variant='outlined'
            placeholder={'Number of decimal allowed...'}
          />
        </Grid>
        <Grid item xs={7}>
          <InputLabel>Options</InputLabel>

          <FormControlLabel
            control={
              <Checkbox
                checked={editVM.currentQuestion.minimumRequired}
                onChange={() => editVM.currentQuestion.toggleMinimumRequired()}
              />
            }
            label={'Minimum'}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={editVM.currentQuestion.maximumRequired}
                onChange={() => editVM.currentQuestion.toggleMaximumRequired()}
              />
            }
            label={'Maximum'}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={editVM.currentQuestion.numberStepRequired}
                onChange={() => editVM.currentQuestion.toggleNumberStepRequired()}
              />
            }
            label={'Step'}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={editVM.currentQuestion.defaultValueRequired}
                onChange={() => editVM.currentQuestion.toggleDefaultValueRequired()}
              />
            }
            label={'Default Value'}
          />
        </Grid>
        <Grid container className='numeric-inputs' spacing={1}>
          {renderMinInput()}
          {renderMaxInput()}
          {renderDefaultValueInput()}
          {renderStepInput()}
        </Grid>
      </Grid>
    )
  }

  const renderMediaUploadModal = () => {
    if (organizationsStore.currentOrganization?.hasCMSPicker) return renderCMSUploadModal()
    return renderStandardUploadModal()
  }

  const renderCMSUploadModal = () => {
    return (
      <CMSItemsPickerDialog
        key={`file-${currentQuestionVM.key}`}
        allowMultiple={false}
        fileTypes={CMSItemsTypeEnum.IMAGES_VIDEOS}
        addAfterSave={(a: Attachment[]) => currentQuestionVM.addMediaToInfoTextFromComputer(a[0])}
        toggleShowModal={() => currentQuestionVM.toggleMediaUploadModal()}
        isOpen={currentQuestionVM.showMediaModal}
      />
    )
  }

  const renderStandardUploadModal = () => {
    return (
      <FileUploadModal
        key={`file-${currentQuestionVM.key}`}
        allowMultiple={false}
        fileTypes={FileTypeEnum.IMAGES_VIDEOS}
        addAfterSave={(a: Attachment[]) => currentQuestionVM.addMediaToInfoTextFromComputer(a[0])}
        toggleShowModal={() => currentQuestionVM.toggleMediaUploadModal()}
        isOpen={currentQuestionVM.showMediaModal}
      />
    )
  }

  const renderDropdownPlaceholder = () => {
    if (currentQuestionVM.type !== 'dropdown') return null
    return (
      <div className='dropdown-label'>
        <InputLabel>{lzStrings.questionsCatalogWidgetDrawer.dropdown_placeholder}</InputLabel>
        <TextField
          error={!currentQuestionVM.placeholderValid}
          id='outlined-placeholder'
          value={currentQuestionVM.placeholder}
          onChange={(e) => {
            currentQuestionVM.setPlaceholder(e.target.value)
          }}
          fullWidth
          margin='none'
          variant='outlined'
        />
      </div>
    )
  }

  const renderDropdownMultiSelectToggle = () => {
    if (currentQuestionVM.type !== 'dropdown') return null
    return (
      <div className={'dropdown-toggle'}>
        <InputLabel>{lzStrings.questionsCatalogWidgetDrawer.dropdown_multiselect}</InputLabel>
        <Switch
          checked={currentQuestionVM.isDropdownMultiSelect}
          onChange={() => currentQuestionVM.toggleDropdownMultiSelect()}
        />
      </div>
    )
  }

  const renderDropdownInfo = () => {
    if (currentQuestionVM.type !== 'dropdown') return null
    return (
      <div className={'dropdown-info'}>
        {renderDropdownPlaceholder()}
        {renderDropdownMultiSelectToggle()}
      </div>
    )
  }

  return (
    <form key={`file-${currentQuestionVM.key}`} noValidate autoComplete='off' id='QuestionForm'>
      {renderMediaUploadModal()}
      {renderNormalTitleRow()}
      {renderTypeRow()}
      {renderInfoTextTitleRow()}
      {renderCategoryRow()}
      {renderPulseCategoryRow()}
      {renderTextQuestionNextQuestionMenu()}
      {renderBranchMsg()}
      {renderOptionsRow()}
      {renderMediaRow()}
      {renderNumberInputs()}
      {renderRequiredRow()}
      {/* {renderAnalyzeResponseSwitch()}
      {renderAnalyzeResponseTypes()} */}
      {renderRatingDisplay()}
      {renderDropdownInfo()}
    </form>
  )
}

export default inject(
  'surveysStore',
  'questionsStore',
  'localizationStore',
  'labelsStore',
  'organizationsStore'
)(observer(QuestionForm))
