import React from 'react'
import { inject, observer } from 'mobx-react'
import { Avatar, Button, IconButton, InputAdornment, Switch, TextField, Typography } from '@material-ui/core'
import './PulseQuestionMedia.scss'
import { PulseQuestionEditVM } from '../../view-models/PulseQuestionEditVM'
import clsx from 'clsx'
import { OrganizationsStore } from '../../../organizations/store/OrganizationsStore'
import DescriptionIcon from '@material-ui/icons/Description'
import VideoIcon from '@material-ui/icons/VideocamRounded'
import { MediaItemVM } from '../../../surveys/view-models/MediaItemVM'

interface Props {
  vm: PulseQuestionEditVM
  organizationsStore?: OrganizationsStore
}

const PulseQuestionMedia: React.FC<Props> = ({ vm, organizationsStore }) => {
  if (!vm) return null
  if (vm.isFollowUp && !vm.isExpanded) return null

  const renderMediaTabs = () => {
    const renderExternalVideoBtns = () => {
      if (organizationsStore.currentOrganization?.hasCMSPicker) return
      return (
        <div>
          <Typography
            className={clsx('media-tab', {
              active: vm.mediaTabIndex === 1,
            })}
            onClick={() => vm.setMediaTabIndex(1)}
          >
            YouTube Url
          </Typography>
        </div>
      )
    }

    return (
      <div className={'media-tabs'}>
        <Typography className={clsx('media-tab', {
          active: vm.mediaTabIndex === 0,
        })}
          onClick={() => vm.setMediaTabIndex(0)}>
          Image / Video
        </Typography>
        {renderExternalVideoBtns()}
      </div>
    )
  }

  const renderPreview = () => {
    const media = vm.media
    if (!media) return <div />
    if (!media.isCMSItem && !media.path) return <div />

    const renderThumbnail = () => {
      if (media.type === 'image') return <img src={media.url} />
      if (media.type === 'video') {
        if (media.url) {
          if (media.url.includes('vimeo')) return <VideoIcon />
          if (media.url.includes('youtube') || media.url.includes('youtu.be')) return <VideoIcon />
          if (media.url.startsWith('http')) {
            return (
              <video>
                <source src={media.url} type='video/mp4' />
              </video>
            )
          }
          return <VideoIcon />
        }
        else return <VideoIcon />
      }
      return <DescriptionIcon />
    }

    const docStyle = (media: MediaItemVM) => {
      if (media.type === 'image') return 'thumbnail-container'
      if (media.type === 'video') {
        if (!media.url) return 'file-container'
        if (media.url.includes('vimeo')) return 'file-container'
        if (media.url.includes('youtube') || media.url.includes('youtu.be')) return 'file-container'
        if (media.url.startsWith('http')) return 'thumbnail-container'
      }
      return 'file-container'
    }

    return (
      <div
        className={docStyle(media)}
      >
        {renderThumbnail()}
      </div>
    )
  }

  const renderYouTubeURL = () => {
    if (vm.mediaTabIndex !== 1) return null

    const button =
      vm.youTubeURL ? (
        <Button
          className='browse-button'
          variant='outlined'
          onClick={() => vm.openYouTubePreview()}
        >
          Preview
        </Button>
      ) : (
        null
      )

    return (
      <div className='media-select'>
        <TextField
          autoFocus
          id='outlined-name'
          value={vm.youTubeURL}
          onChange={(e) => {
            vm.setYouTubeURL(e.target.value)
          }}
          fullWidth
          margin='none'
          variant='outlined'
          disabled={!vm.isSystemAdmin}
          InputProps={{
            endAdornment: <InputAdornment position='end'>{button}</InputAdornment>,
          }}
        />
      </div>
    )
  }

  const renderVimeoURL = () => {
    if (vm.mediaTabIndex !== 2) return null

    const button =
      vm.vimeoURL ? (
        <Button
          className='browse-button'
          variant='outlined'
          onClick={() => vm.openVimeoPreview()}
        >
          Preview
        </Button>
      ) : (
        null
      )

    return (
      <div className='media-select'>
        <TextField
          autoFocus
          id='outlined-name'
          value={vm.vimeoURL}
          onChange={(e) => {
            vm.setVimeoURL(e.target.value)
          }}
          fullWidth
          margin='none'
          variant='outlined'
          disabled={!vm.isSystemAdmin}
          InputProps={{
            endAdornment: <InputAdornment position='end'>{button}</InputAdornment>,
          }}
        />
      </div>
    )
  }

  const renderMediaPicker = () => {
    if (vm.mediaTabIndex !== 0) return null
    const button =
      vm.pulseQuestion && vm.pulseQuestion.media && (vm.pulseQuestion.media.objectId || vm.pulseQuestion.media.cmsItemId) ? (
        <Button
          className='browse-button'
          variant='outlined'
          onClick={() => vm.pulseQuestion.removeMedia()}
          disabled={!vm.isSystemAdmin}
        >
          Remove
        </Button>
      ) : (
        <Button
          className='browse-button'
          variant='outlined'
          onClick={() => vm.showMediaPicker()}
          disabled={!vm.isSystemAdmin}
        >
          Browse
        </Button>
      )

    return (
      <div className='media-select'>
        <TextField
          onFocus={() => vm.showMediaPicker()}
          variant='outlined'
          type='text'
          placeholder='Add Media'
          margin='none'
          fullWidth
          disabled={!vm.isSystemAdmin}
          value={vm.media.displayName}
          InputProps={{
            startAdornment: <InputAdornment position='start'>{renderPreview()}</InputAdornment>,
            endAdornment: <InputAdornment position='end'>{button}</InputAdornment>,
          }}
        />
      </div>
    )
  }

  const renderMediaFirstToggle = () => {
    return (
      <div className={'media-first-toggle'}>
        <Switch disabled={!vm.hasMedia} checked={vm.showMediaFirst} onChange={() => vm.toggleShowMediaFirst()} />
        <Typography className='media-first-header'>Show Media First?</Typography>
      </div>
    )
  }

  return (
    <div id='PulseQuestionMedia'>
      <Typography className={'media-display-header'}>Media</Typography>
      {renderMediaTabs()}
      <div className={'media-select-row'}>
        {renderMediaPicker()}
        {renderYouTubeURL()}
        {renderVimeoURL()}
        {renderMediaFirstToggle()}
      </div>
    </div>
  )
}

export default inject('pulseQuestionsStore', 'organizationsStore')(observer(PulseQuestionMedia))
