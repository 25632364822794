declare global {
  interface Window {
    env: any
  }
}

type EnvType = {
  REACT_APP_API_URL: string
  REACT_APP_SENTRY_ENVIRONMENT: string
  REACT_APP_SENTRY_ENABLED: string
  REACT_APP_ENVIRONMENT: string
  REACT_APP_TABLEAU_SERVER_URL: string
  REACT_APP_USE_SESSION_STORAGE: string
  REACT_APP_MEDIA_UPLOAD_SERVICE: string
  REACT_APP_CMS_API_URL: string
}

export class EnvClass {
  private win: any = {}

  public setWindow(window) {
    this.win = window
  }

  public get var(): EnvType {
    // console.log('WINDOW.ENV: ' + JSON.stringify(this.win.env))
    if (!this.win.env && process.env.NODE_ENV === 'production') {
      if (!document) return
      const script = document.getElementById('env-vars').innerText
      const cleanScript = script.replace('window.env = ', '').replace('//ENV_VARS//', '')
      console.log(cleanScript)
      if (cleanScript) this.win.env = JSON.parse(cleanScript)
    }
    return { ...process.env, ...this.win.env }
  }
}

const cls = new EnvClass()
export default cls
