import { observable, action } from 'mobx'
import { IAggregate } from 'src/app/shared/data/IAggregate'
import { serializable, serialize, list, object, deserialize, date} from 'serializr'
import { IUserDashboardDTO } from '../dtos/IUserDashboardDTO'
import { DashboardType } from '../../types/DashboardType'
import Dashboard from '../../aggregate/Dashboard'
import RGLItem from '../../aggregate/RGLItem'
import moment from 'moment'
import { ParseDate } from '../../../shared/ParseDate'

export default class UserDashboard extends Dashboard implements IUserDashboardDTO, IAggregate  {
  static create(orgId: string) {
    const dashboard = new UserDashboard()
    dashboard.organizationId = orgId
    return dashboard
  }

  @serializable @observable objectId: string = ''
  @serializable @observable ownerUserId: string = ''
  @serializable @observable organizationId: string = ''
  @serializable @observable dashboardId: string = ''
  @serializable @observable type: DashboardType = 'custom'
  @serializable @observable name: string = ''
  @serializable @observable isFavorite: boolean = false
  @serializable @observable userId: string = ''
  @serializable(list(object(RGLItem))) @observable lgLayout: Array<RGLItem>
  @serializable(object(ParseDate)) @observable public lastVisitedDateTime: ParseDate = null
  @serializable @observable isDeleted: boolean = false
  isOnServer: boolean = false

  @action
  public toggleIsFavorite() {
    this.isFavorite = !this.isFavorite
  }

  markIsNotOnServer() {
    this.isOnServer = false
  }

  markIsOnServer() {
    this.isOnServer = true
  }

  public clone(): UserDashboard {
    return deserialize(UserDashboard, this.serialize())
  }

  public serialize(): IUserDashboardDTO {
    const obj = serialize(this)
    return obj
  }
}


