import { RootStore } from '../../../../stores/RootStore'
import { observable, computed, action } from 'mobx'
import { MyTrainingPlansWidgetRowVM } from './MyTrainingPlansWidgetRowVM'
import { MyTrainingsTimelinePlanRowVM } from './MyTrainingsTimelinePlanRowVM'
import LocalizationStore from '../../../../localization/LocalizationStore'
import moment from 'moment'

export class MyTrainingPlansWidgetVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @observable public keywordFilter: string = ''
  @observable public deletingOrgId: string = ''
  @observable public tabIndex: number = 0
  @observable public subtabIndex: number = 0
  @observable public showTimeline: boolean = true
  @observable public sortType: string = 'Date'

  @computed public get activeRows(): MyTrainingPlansWidgetRowVM[] {
    return this.rootStore.userTrainingPlansStore.orgUserTrainingPlans
      .filter(
        (userTrainingPlan) => userTrainingPlan.userId === this.rootStore.appStore.currentUserId
      )
      .map((e) => new MyTrainingPlansWidgetRowVM(this.rootStore, this, e))
      .filter(
        (row) =>
          (row.isActive && !row.isArchived && !row.isComplete) ||
          (row.isPastDue && !row.isArchived && !row.isComplete)
      )
      .filter((row) => row.title.toLowerCase().includes(this.keywordFilter.toLowerCase()))
      .sort((a, b) => {
        if (this.sortType === 'Name') return a.title < b.title ? -1 : a.title > b.title ? 1 : 0
        if (this.sortType === 'Date') return a.dueDate < b.dueDate ? -1 : 0
      })
  }

  @computed public get completedRows(): MyTrainingPlansWidgetRowVM[] {
    return this.rootStore.userTrainingPlansStore.orgUserTrainingPlans
      .filter(
        (userTrainingPlan) => userTrainingPlan.userId === this.rootStore.appStore.currentUserId
      )
      .filter((row) => row.title.toLowerCase().includes(this.keywordFilter.toLowerCase()))
      .map((e) => new MyTrainingPlansWidgetRowVM(this.rootStore, this, e))
      .filter((row) => !row.isArchived && row.isComplete)
      .sort((a, b) => {
        if (this.sortType === 'Name') return a.title < b.title ? -1 : 0
        if (this.sortType === 'Date') return a.dueDate > b.dueDate ? -1 : 0
      })
  }

  @computed public get archivedRows(): MyTrainingPlansWidgetRowVM[] {
    return this.rootStore.userTrainingPlansStore.orgUserTrainingPlans
      .filter(
        (userTrainingPlan) => userTrainingPlan.userId === this.rootStore.appStore.currentUserId
      )
      .filter((row) => row.title.toLowerCase().includes(this.keywordFilter.toLowerCase()))
      .map((e) => new MyTrainingPlansWidgetRowVM(this.rootStore, this, e))
      .filter((row) => row.isArchived)
      .sort((a, b) => {
        if (this.sortType === 'Name') return a.title < b.title ? -1 : 0
        if (this.sortType === 'Date') return a.dueDate > b.dueDate ? -1 : 0
      })
  }

  @computed
  public get displayRows(): MyTrainingPlansWidgetRowVM[] {
    if (this.tabIndex === 0) return this.activeRows
    if (this.tabIndex === 1) return this.completedRows
  }

  @computed
  public get timelineRows(): MyTrainingsTimelinePlanRowVM[] {
    return this.rootStore.userTrainingPlansStore.orgUserTrainingPlans
      .filter(
        (userTrainingPlan) => userTrainingPlan.userId === this.rootStore.appStore.currentUserId
      )
      .map((e) => new MyTrainingsTimelinePlanRowVM(this.rootStore, this, e))
      .filter(
        (row) =>
          (row.isActive && !row.isArchived && !row.isComplete) ||
          (row.isPastDue && !row.isArchived && !row.isComplete)
      )
      .filter((row) => row.title.toLowerCase().includes(this.keywordFilter.toLowerCase()))
      .sort((a, b) => {
        if (this.sortType === 'Name') return a.title < b.title ? -1 : a.title > b.title ? 1 : 0
        if (this.sortType === 'Date') return a.dueDate < b.dueDate ? -1 : 0
      })
  }

  @computed
  public get upcomingRows(): MyTrainingsTimelinePlanRowVM[] {
    return this.rootStore.userTrainingPlansStore.orgUserTrainingPlans
      .filter(
        (userTrainingPlan) => userTrainingPlan.userId === this.rootStore.appStore.currentUserId
      )
      .map((e) => new MyTrainingsTimelinePlanRowVM(this.rootStore, this, e))
      .filter((row) => !row.isActive && !row.isArchived && !row.isComplete && !row.isPastDue)
      .filter((row) => row.title.toLowerCase().includes(this.keywordFilter.toLowerCase()))
      .sort((a, b) => (a.dueDate < b.dueDate ? 0 : -1))
  }

  @computed
  public get upcomingDueIn(): string {
    const s = this.rootStore.localizationStore.lzStrings.training_plans
    if (!this.upcomingRows[0]) return `${s.next_due_in} 0 ${s.days}`
    const nextDueDate = this.upcomingRows[0].dueDate
    const formattedDate = moment(nextDueDate)
    const today = moment()
    const timeTilDue = formattedDate.diff(today, 'minutes')
    const numDays = Math.floor(timeTilDue / 1440)
    return `${s.next_due_in} ${numDays} ${s.days}`
  }

  @computed
  public get upcomingCount(): number {
    return this.upcomingRows.length
  }

  @action setSortType(type) {
    this.sortType = type
  }

  @action
  public setTabIndex(num: number) {
    if (this.showTimeline) this.toggleShowTimeline()
    this.tabIndex = num
  }

  @action
  public toggleShowTimeline() {
    this.showTimeline = !this.showTimeline
  }

  @action setKeywordFilter(val) {
    this.keywordFilter = val
  }

  @action createNewTrainingPlan() {
    this.rootStore.appStore.router.push('/trainingPlans/edit/new')
  }
}
export default MyTrainingPlansWidgetVM
