import Parse from 'parse'
import { action, observable, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Organization } from '../aggregate/Organization'
import { OrganizationsService } from '../service/OrganizationsService'
import { MediaItemsService } from '../../media-items/service/MediaItemsService'
import defaultLogo from '../../../assets/img/logos/RippleWorx-BlueWhiteGrey.png'
import { Attachment } from '../../upload/aggregate/Attachment'
import { OrganizationEditVM } from './OrganizationEditVM'
import { TenantLinkEditVM } from './TenantLinkEditVM'
import { TenantLink } from '../aggregate/TenantLink'
import { TenantLinkRowVM } from './TenantLinkRowVM'
import { WelcomeBannerEditVM } from './WelcomeBannerEditVM'
import { WelcomeBanner } from '../aggregate/WelcomeBanner'
import { WelcomeBannerAttachmentVM } from './WelcomeBannerAttachmentVM'
import { isNumeric } from '../../shared/isNumeric'
import { OldAttachmentVM } from '../../attachments/view-models/OldAttachmentVM'
import { AttachmentVM } from '../../attachments/view-models/AttachmentVM'

export class OrganizationWelcomeBannerVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, editVM: OrganizationEditVM) {
    this.rootStore = rootStore
    this.editVM = editVM
  }

  @observable public editVM: OrganizationEditVM = null
  @observable public welcomeBannerEditVM: WelcomeBannerEditVM = null

  @computed
  public get welcomeBanner(): WelcomeBanner {
    return this.editVM.organization.welcomeBanner
  }

  @computed
  public get attachments(): AttachmentVM[] {
    return this.welcomeBanner.attachments.map((e, idx) => {
      if (isNumeric(e.objectId) || e.cmsItemId) {
        return this.rootStore.cmsItemAttachmentStore.loadAttachment(e)
      }
      return new OldAttachmentVM(this.rootStore, e, idx)
    })
  }

  @computed
  public get swiperAttachments(): AttachmentVM[] {
    return this.attachments.filter(
      (doc) => doc.isImage || doc.isVideo || !doc.isLoaded
    ) as AttachmentVM[]
  }

  @computed
  public get fileAttachments(): AttachmentVM[] {
    return this.attachments.filter((doc) => !doc.isImage && !doc.isVideo) as AttachmentVM[]
  }

  @computed
  public get currentTabIndex(): number {
    return this.editVM.currentTabIndex
  }

  @computed
  public get isNew(): boolean {
    if (!this.editVM.organization.welcomeBanner.body) return true
    return false
  }

  @action
  public addWelcomeBannerToOrg(welcomeBanner: WelcomeBanner) {
    this.editVM.organization.addWelcomeBanner(welcomeBanner)
  }

  @action
  public removeWelcomeBannerFromOrg() {
    this.editVM.organization.removeWelcomeBanner()
  }

  @action
  public loadWelcomeBannerEditVM() {
    return (this.welcomeBannerEditVM = new WelcomeBannerEditVM(
      this.rootStore,
      this,
      this.welcomeBanner.clone()
    ))
  }

  @action
  public clearWelcomeBannerEditVM() {
    this.welcomeBannerEditVM = null
  }
}
