import { observable } from 'mobx'
import { DataStore } from '../../../shared/data/DataStore'
import { nameOf } from '../../../shared/nameOf'
import { RootStore } from '../../../stores/RootStore'
import { PulseCampaign } from '../../aggregate/PulseCampaign'
import { IPulseCampaignDTO } from '../../dto/IPulseCampaignDTO'
import { IPulseCampaignSurveyDTO } from '../../dto/IPulseCampaignSurveyDTO'
import { ICampaignTemplateFindRequest } from '../../interfaces/ICampaignTemplateFindRequest'
import { PulseCampaignsService } from '../../service/PulseCampaignsService'
import { IPulseCampaignFindResult } from '../../interfaces/IPulseCampaignFindResult'

export class PulseCampaignTemplatesListDataStore extends DataStore<PulseCampaign, IPulseCampaignDTO> {
  private request: ICampaignTemplateFindRequest

  constructor(rootStore: RootStore, request: ICampaignTemplateFindRequest) {
    super(rootStore, PulseCampaign, 'pulseCampaigns', [
      nameOf<IPulseCampaignDTO, string>((e) => e.name),
      nameOf<IPulseCampaignDTO, IPulseCampaignSurveyDTO[]>((e) => e.surveys),
    ])
    this.request = request
    this.paged = true
    this.recordsPerPage = 100
    this.sortColumnName = 'name'
    this.sortDirection = 'desc'
    this.listenToListRecordsViaApiUpdates = true
  }

  @observable public isLoaded: boolean = false

  public onListRecordsLoaded() {
    this.isLoaded = true
  }

  protected getListRecordsApiCall() {
    return async () => {
      const req: ICampaignTemplateFindRequest = {
        ...this.request,
        listColumns: this.listColumns,
      }
      const svc = new PulseCampaignsService()
      const result: IPulseCampaignFindResult = await svc.getCampaignTemplates(req)
      this.totalRecords = result.count
      return result.pulseCampaigns
    }
  }

  public get templates() {
    return this.records
  }
}
