import Parse from 'parse'
import { ITrainingItemDTO } from '../dto/ITrainingItemDTO'
import { ICatalogItemsFindRequest } from '../interfaces/ICatalogItemsFindRequest'
import { ICatalogItemsFindResult } from '../interfaces/ICatalogItemsFindResult'

export class TrainingItemsService {
  public async saveTrainingItem(trainingItem: ITrainingItemDTO) {
    return await Parse.Cloud.run('saveTrainingItem', { trainingItem })
  }

  public async deleteTrainingItem(id: string, orgId: string) {
    await Parse.Cloud.run('deleteTrainingItem', { id, orgId })
  }

  public async getCatalogTrainingItemsForUser(
    request: ICatalogItemsFindRequest
  ): Promise<ICatalogItemsFindResult> {
    return await Parse.Cloud.run('getCatalogItemsForUser', { request })
  }
}
