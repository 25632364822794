import { observable, action, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import Parse from 'parse'
import User from '../aggregate/User'
import Organization from '../aggregate/Organization'
import { AuthenticationService } from '../../auth/services/AuthenticationService'
import { IUserAuthInfoResult } from '../../auth/interfaces/IUserAuthInfoResult'
import { UserService } from '../service/UserService'
import { IUserDTO } from '../../users/dto/IUserDTO'
import { OrganizationSelectService } from '../service/OrganizationSelectService'
import { IUserInfoOrganizationResult } from '../interfaces/IUserInfoResult'
import { AvatarMenuVM } from '../view-models/AvatarMenuVM'
import { UserInfoPromptVM } from '../../user-info-prompt/view-models/UserInfoPromptVM'

export class UserStore {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.avatarMenuVM = new AvatarMenuVM(this.rootStore)
    this.userInfoPromptVM = new UserInfoPromptVM(this.rootStore)
  }

  @observable public selectedTaskTemplateId: string
  @observable public user: User = new User()
  @observable public loaded: boolean = false
  @observable public organizationResults: IUserInfoOrganizationResult[]
  public parseUserInfo: any
  @observable public avatarMenuVM: AvatarMenuVM = null
  @observable public userInfoPromptVM: UserInfoPromptVM = null

  @action
  public async loadUser() {
    try {
      const user = await Parse.User.currentAsync()
      if (!user) {
        await this.rootStore.appStore.logout('UserStore: Parse current user not found')
        return
      }
      this.parseUserInfo = user.toJSON()
      this.updateUserFromServer(user.toJSON() as any as IUserDTO)
      const svc = new UserService(this.rootStore)
      const result = await svc.getUserInfo()
      if (!result.success) throw result.errorMessage
      this.rootStore.appStore.setAppOnline()
      this.updateUserFromServer(result.user)
      await this.selectOrganization(result.organizations)
      this.processSystemAdmin(result.isSystemAdmin)
      this.loaded = true
    } catch (error) {
      console.log('error in UserStore')
      console.error(error)
      const errorObject = JSON.parse(JSON.stringify(error))
      if (
        String(errorObject.message).includes(
          'XMLHttpRequest failed: "Unable to connect to the Parse API"'
        )
      ) {
        this.rootStore.appStore.setAppOffline()
      }
      if (String(errorObject).toLowerCase().includes('invalid session token')) {
        console.log('Yes tis caught')
        await this.rootStore.appStore.logout('UserStore: Invalid session token')
      } else {
        throw error
      }
    }
  }

  @action
  public checkTheme() {
    if (this.organizationResults.length === 0) return
    if (!this.currentOrganization) return
    this.rootStore.appStore.setTheme(this.currentOrganization.theme)
  }

  private async processSystemAdmin(isAdmin: boolean) {
    if (!isAdmin) return
    this.user.setSystemAdmin(true)
  }

  private updateUserFromServer(obj: IUserDTO) {
    if (!this.user) this.user = User.create()
    this.user.updateFromServer(obj)
  }

  @computed
  public get currentOrgUserId() {
    if (!this.currentOrganization) return undefined
    return this.currentOrganization.orgUserId
  }

  @computed
  public get userPrivileges(): string[] {
    if (!this.currentOrganization) return []
    if (!this.currentOrganization.privileges) return []
    return this.currentOrganization.privileges
  }

  @computed
  public get currentOrganization(): Organization {
    if (!this.organizationResults) return undefined
    const orgResult = this.organizationResults.find(
      (e) => e.organizationId === this.rootStore.appStore.currentOrgId
    )
    if (!orgResult) return undefined
    return Organization.FromResult(orgResult)
  }

  private async selectOrganization(orgResults: IUserInfoOrganizationResult[]) {
    this.organizationResults = orgResults
    const svc = new OrganizationSelectService(this.rootStore)
    const selectedOrgId = svc.selectOrganization(this.organizationResults)
    if (!selectedOrgId) return
    const orgResult = this.organizationResults.find((e) => e.organizationId === selectedOrgId)
    if (!orgResult.allSettingsLoaded) {
      const userSvc = new UserService(this.rootStore)
      const updatedResult = await userSvc.getUserInfo(selectedOrgId)
      const idx = this.organizationResults.findIndex((e) => e.organizationId === selectedOrgId)
      this.organizationResults[idx] = updatedResult.organizations[0]
    }
    this.checkTheme()
  }

  // public async listenToUserOnServer() {
  //   if (this.userSubscription) this.userSubscription.unsubscribe()
  //   this.userQuery = new Parse.Query(Parse.User)
  //   this.userQuery.equalTo('objectId', this.rootStore.appStore.currentUserId)
  //   // const response = await this.userQuery.first()
  //   const sub = await this.userQuery.subscribe()
  //   sub.on('update', (e) => this.updateUserFromServer(e.toJSON() as any as IUserDTO))
  //   this.userSubscription = sub
  // }

  // public async listenToOrganizationsOnServer() {
  //   if (this.organizationsSubscription) return
  //   // if (this.organizationsSubscription) this.organizationsSubscription.unsubscribe()
  //   const sub = await this.organizationsQuery.subscribe()
  //   sub.on('create', (e) => this.updateOrganizationFromServer(e))
  //   sub.on('update', (e) => this.updateOrganizationFromServer(e))
  //   sub.on('delete', (e) => this.deleteOrganzation(e.id))
  //   sub.on('open', (e) => this.rootStore.appStore.setAppOnline())
  //   sub.on('close', (e) =>
  //     this.rootStore.appStore.setAppOffline('user livequery connection closed')
  //   )
  //   this.organizationsSubscription = sub
  // }

  public clearData() {
    this.user = null
    this.organizationResults = []
  }

  public switchToOrgIfPresent(orgId: string) {
    const foundOrg = this.organizationResults.find((e) => e.organizationId === orgId)
    if (!foundOrg) {
      this.rootStore.appStore.router.push('/dashboard/tasks')
      return
    }
    this.rootStore.appStore.setCurrentOrg(orgId)
    setTimeout(() => window.location.reload(), 200)
  }
}
