import React from 'react'
import { inject, observer } from 'mobx-react'
import {
  Typography,
  IconButton,
  Grid,
  Paper,
  Popover,
  Card,
  Avatar,
  TextField,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import { TrainingPlansStore } from '../../../../training-plans/store/TrainingPlansStore'
import { TrainingPlanItemVM } from '../../../../training-plans/view-models/TrainingPlanItemVM'
import './TrainingItemCard.scss'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import LinkIcon from '@material-ui/icons/Link'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import PermMediaIcon from '@material-ui/icons/PermMedia'
import ExerciseIcon from '@material-ui/icons/FitnessCenterRounded'
import { ContentItem } from '../../../../training-items/aggregate/ContentItem'
import rootStore from '../../../../stores/RootStore'
import { TrainingItemsStore } from '../../../../training-items/store/TrainingItemsStore'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { TrainingItemEditVM } from '../../../../training-items/view-models/TrainingItemEditVM'
import EditIcon from '@material-ui/icons/Edit'

interface Props {
  trainingPlansStore?: TrainingPlansStore
  trainingItemsStore?: TrainingItemsStore
  localizationStore?: LocalizationStore
  trainingItem: TrainingPlanItemVM
  index: number
}

const ContentCard: React.FC<Props> = ({
  trainingPlansStore,
  trainingItemsStore,
  localizationStore,
  trainingItem,
  index,
}) => {
  const { trainingPlanEditVM: vm } = trainingPlansStore
  if (!vm) return null

  const handleDetails = (
    trainingItem: TrainingPlanItemVM,
    contentItem: ContentItem,
    itemIndex: number
  ) => {
    const type = contentItem.type.toLowerCase()
    const item = trainingItemsStore.getTrainingItem(trainingItem.id)
    const foundItem = vm.trainingPlan.trainingPlanItems.find(
      (t) => t.trainingItemId === item.objectId
    )
    if (foundItem) item.contentItems = foundItem.contentItems

    if (item && type === 'exercise') {
      const editVM = new TrainingItemEditVM(rootStore, item.clone())
      const dialogVM = trainingItemsStore.exerciseDetailsDialogVM
      dialogVM.contentItem = editVM
      dialogVM.index = itemIndex
      dialogVM.openDialog()
    }
    trainingItem.setAnchorEl(null)
  }

  const renderIcon = (item: ContentItem) => {
    const type = item.type.toLowerCase()
    if (!type) return null
    if (type === 'link')
      return (
        <span className='content-popup-menu-item-icon'>
          <div className='item-icon-container'>
            <LinkIcon />
          </div>
          <Typography component='span' variant='h6'>
            {type}
          </Typography>
        </span>
      )
    if (type === 'file')
      return (
        <span className='content-popup-menu-item-icon'>
          <div className='item-icon-container'>
            <AttachFileIcon />
          </div>
          <Typography component='span' variant='h6'>
            {type}
          </Typography>
        </span>
      )
    if (type === 'media')
      return (
        <span className='content-popup-menu-item-icon'>
          <div className='item-icon-container'>
            <PermMediaIcon />
          </div>
          <Typography component='span' variant='h6'>
            {type}
          </Typography>
        </span>
      )
    if (type === 'exercise')
      return (
        <div className='content-popup-menu-item-icon'>
          <div className='item-icon-container'>
            <ExerciseIcon />
          </div>
          <Typography component='span' variant='h6'>
            {type}
          </Typography>
        </div>
      )
  }

  const renderEditIcon = (item: ContentItem) => {
    const type = item.type.toLowerCase()
    if (!type) return null
    if (type !== 'exercise') return
    return (
      <div className='content-popup-menu-edit'>
        <div className='content-popup-menu-edit-icon'>
          <div className='edit-icon-container'>
            <IconButton onClick={() => handleDetails(trainingItem, item, index)}>
              <Avatar className='edit-icon-btn'>
                <EditIcon />
              </Avatar>
            </IconButton>
          </div>
        </div>
      </div>
    )
  }

  const renderContentItemsMenu = (trainingItem) => {
    if (!trainingItem.anchorEl) return null
    return (
      <Popover
        open={true}
        anchorEl={trainingItem.anchorEl}
        onClose={() => trainingItem.setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className='content-items-popup'
      >
        <Card elevation={5} className='popup-body'>
          <Grid item xs={12}>
            <Typography className='block-name'>{'Block Name'}</Typography>
            <TextField
              margin='none'
              fullWidth
              variant='outlined'
              placeholder='Block Name...'
              autoComplete='off'
              value={trainingItem.block}
              type={'text'}
              onChange={(e) => trainingItem.setBlock(e.target.value)}
            />
          </Grid>
          {trainingItem.contentItems.map((item, index) => {
            return (
              <div
                className='content-popup-menu'
                key={index}
                onClick={() => handleDetails(trainingItem, item, index)}
              >
                <div
                  className='content-popup-menu-item'
                  onClick={() => handleDetails(trainingItem, item, index)}
                >
                  <div className='item-details'>
                    <Typography component='span' variant='h6'>
                      {index + 1}.
                    </Typography>
                    <Typography component='span' variant='h6'>
                      {item.title}
                    </Typography>
                  </div>
                  <div className='media-type-icon'>
                    <Typography
                      component='span'
                      variant='body2'
                      className='content-popup-menu-item-icon-container'
                    >
                      {renderIcon(item)}
                    </Typography>
                  </div>
                </div>
                {renderEditIcon(item)}
              </div>
            )
          })}
        </Card>
      </Popover>
    )
  }

  return (
    <Paper
      elevation={1}
      key={index}
      id='TrainingItemCard'
      className='card'
      style={vm.editIsDisabled ? { cursor: 'default' } : {}}
    >
      <div className='training-item-container'>
        <div className='training-item-number'>
          <div className='drag-indicator'>
            <DragIndicatorIcon />
            <Typography variant='h5'>{index + 1}</Typography>
          </div>
        </div>
        <div className='training-item-body'>
          <Grid container>
            <Grid item xs={9}>
              <Typography variant='h5' className={trainingItem.name ? '' : 'title-placeholder'}>
                {trainingItem.name ? trainingItem.name : '(Untitled)'}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant='body1'>
                {trainingItem.block ? trainingItem.blockString : null}
                <span className='block'>{trainingItem.subtitle}</span>
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={4} className='preview'>
            <Typography
              variant='h5'
              className={'preview-menu'}
              onClick={(e) => trainingItem.setAnchorEl(e.target)}
            >
              {trainingItem.itemLabel}
            </Typography>
            <IconButton
              className={'preview-button'}
              size={'small'}
              onClick={(e) => trainingItem.setAnchorEl(e.target)}
            >
              <ArrowDropDownIcon fontSize='large' />
            </IconButton>
          </Grid>
          {renderContentItemsMenu(trainingItem)}
          <IconButton
            disabled={vm.editIsDisabled}
            onClick={() => vm.removeTrainingItem(trainingItem.id)}
          >
            <CloseIcon className='close-icon' />
          </IconButton>
        </div>
      </div>
    </Paper>
  )
}

export default inject('trainingPlansStore', 'trainingItemsStore')(observer(ContentCard))
