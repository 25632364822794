import React from 'react'
import { inject, observer } from 'mobx-react'
import CloseIcon from '@material-ui/icons/Close'
import { UserTasksStore } from '../../store/UserTasksStore'
import { UserStore } from '../../../user/store/UserStore'
import LocalizationStore from '../../../localization/LocalizationStore'
import { Avatar, Button, Grid, TextField, Typography } from '@material-ui/core'
import DescriptionIcon from '@material-ui/icons/Description'
import VideoIcon from '@material-ui/icons/VideocamRounded'
import FileUploadModal from '../../../upload/views/FileUploadModal'
import { FileTypeEnum } from '../../../upload/enum/FileTypeEnum'
import { Attachment } from '../../../upload/aggregate/Attachment'
import CMSItemsPickerDialog from '../../../cms-items/views/dialogs/CMSItemsPickerDialog'
import { CMSItemsTypeEnum } from '../../../cms-items/enum/CMSItemsTypeEnum'
import { OrganizationsStore } from '../../../organizations/store/OrganizationsStore'

interface IDrawerCommentInput {
  userTasksStore?: UserTasksStore
  userStore?: UserStore
  localizationStore?: LocalizationStore
  organizationsStore?: OrganizationsStore
}

const DrawerCommentInput: React.FC<IDrawerCommentInput> = ({
  userStore,
  userTasksStore,
  localizationStore,
  organizationsStore,
}) => {
  const { lzStrings } = localizationStore
  const { editVM: vm } = userTasksStore

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    vm.setCommentText(event.target.value)
  }

  const handleAddComment = (event: React.MouseEvent<HTMLButtonElement>): void => {
    vm.addComment()
  }

  const renderAttachment = () => {
    if (!vm.commentAttachment || !vm.commentAttachmentVM) return null

    const doc = vm.commentAttachmentVM
    const renderThumbnail = () => {
      if (doc.isImage) {
        return (
          <div
            className='img-container'
            style={{ backgroundImage: `url("${doc.url}")` }}
          />
        )
      }
      if (doc.isVideo) {
        if (doc.url) {
          if (doc.url.includes('vimeo')) return <VideoIcon />
          if (doc.url.includes('youtube') || doc.url.includes('youtu.be')) return <VideoIcon />
          if (doc.url.startsWith('http')) {
            return (
              <div className='img-container'>
                <video>
                  <source src={doc.url} type='video/mp4' />
                </video>
              </div>
            )
          }
          else return <VideoIcon />
        }
        else return <VideoIcon />
      }
      return <DescriptionIcon />
    }

    return (
      <Grid item xs={6} className='input-attachment-row'>
        <span className='attachment-body'>
          {renderThumbnail()}
          <Typography
            variant='body1'
            style={{ cursor: 'pointer' }}
            onClick={() => doc.openAttachment()}
          >
            {doc.shorterFileName}
          </Typography>
        </span>
        <span className='delete'>
          <CloseIcon onClick={() => vm.deleteAttachmentFromComment()} />
        </span>
      </Grid>
    )
  }

  const renderAddAttachmentButton = () => {
    if (vm.commentAttachmentVM) return null
    return (
      <Button variant='outlined' onClick={() => {vm.toggleCommentUploadModal()}}>
        {lzStrings.tasksManagementWidgetDrawer.add_attachment}
      </Button>
    )
  }

  const renderCommentUploadModal = () => {
    if (organizationsStore.currentOrganization?.hasCMSPicker) return renderCMSUploadModal()
    return renderStandardUploadModal()
  }

  const renderCMSUploadModal = () => {
    return (
      <CMSItemsPickerDialog
        allowMultiple={false}
        fileTypes={CMSItemsTypeEnum.DEFAULT}
        addAfterSave={(a: Attachment[]) => {
          const attachment = new Attachment()
          attachment.cmsItemId = a[0].cmsItemId
          vm.addAttachmentToTaskComment(attachment)
        }}
        toggleShowModal={() => vm.toggleCommentUploadModal()}
        isOpen={vm.showCommentUploadModal}
      />
    )
  }

  const renderStandardUploadModal = () => {
    return (
      <FileUploadModal
        allowMultiple={false}
        fileTypes={FileTypeEnum.DEFAULT}
        addAfterSave={(a: Attachment[]) => vm.addAttachmentToTaskComment(a[0])}
        toggleShowModal={() => vm.toggleCommentUploadModal()}
        isOpen={vm.showCommentUploadModal}
      />
    )
  }

  return (
    <Grid container alignItems='center' justifyContent='center' id='TaskCommentInput'>
      {renderCommentUploadModal()}
      <Grid item xs='auto'>
        <Avatar
          className='comment-avatar'
          alt={`${userStore.user.name}'s Avatar`}
          src={userStore.user.iconURL}
        />
      </Grid>
      <Grid item xs={11}>
        <TextField
          style={{ marginTop: '10px' }}
          variant='outlined'
          fullWidth
          multiline
          placeholder={lzStrings.pendingUserTasksWidgetDrawer.comment_placeholder}
          minRows={1}
          maxRows={4}
          onChange={handleCommentChange}
          value={vm.commentText}
        />
      </Grid>
      {/* <Grid item xs={12} container spacing={1} className='attachment'>
        
      </Grid> */}
      <Grid container item xs={12} spacing={1} justifyContent='flex-end' className='add-comment-btn'>
        {renderAttachment()}
        <Grid item xs='auto'>
          {renderAddAttachmentButton()}
          <Button onClick={handleAddComment} disabled={vm.commentSaveDisabled} variant='outlined'>
            {lzStrings.pendingUserTasksWidgetDrawer.add_comment}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default inject(
  'userStore',
  'userTasksStore',
  'localizationStore',
  'organizationsStore'
)(observer(DrawerCommentInput))
