import React, { useLayoutEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { Card, CardContent } from '@material-ui/core'
import LocalizationStore from '../../../localization/LocalizationStore'
import MuiDrawerHeader from '../../../shared/Mui-Drawer/MuiDrawerHeader'
import MuiDrawerToolbar from '../../../shared/Mui-Drawer/MuiDrawerToolbar'
import SpinnerOverlay from '../../../shared/SpinnerOverlay'
import './OrganizationUserPasswordEditDrawer.scss'
import Drawer from '@material-ui/core/Drawer'
import { OrganizationUsersStore } from '../../store/OrganizationUsersStore'
import PasswordTab from './PasswordTab'

interface Props {
  organizationUsersStore?: OrganizationUsersStore
  localizationStore?: LocalizationStore
}

const OrganizationUserEditDrawer: React.FC<Props> = ({
  organizationUsersStore,
  localizationStore,
}) => {
  const s = localizationStore.lzStrings
  const vm = organizationUsersStore.editPasswordVM

  useLayoutEffect(() => {
    if (!vm) return
    vm.setVirtualListParent('tabBody')
  }, [])

  if (!vm) return null

  const handleCancelClick = (e) => {
    e.preventDefault()
    vm.hideDrawer()
  }

  const renderHeaderAndTabs = () => {
    return (
      <>
        <MuiDrawerToolbar
          disabled={vm.isProcessing}
          handleCancelClick={(e) => handleCancelClick(e)}
          handleSubmitClick={(e) => {
            e.preventDefault()
            vm.saveNewPassword()
          }}
          onConfirmReset={() => organizationUsersStore.reloadPasswordEditVM()}
        />
      </>
    )
  }

  const renderSpinner = () => {
    if (!vm.isProcessing) return
    return <SpinnerOverlay />
  }

  return (
    <Drawer variant='temporary' anchor='right' open={vm.isOpen} onClose={() => vm.toggleDrawer()}>
      <div id='OrganizationUserEditDrawer'>
        {renderSpinner()}
        <Card elevation={0}>
          <MuiDrawerHeader title={'Edit User Password'} toggleDrawer={() => vm.toggleDrawer()} />
          {renderHeaderAndTabs()}
          <CardContent id='tabBody'>
            <PasswordTab />
          </CardContent>
        </Card>
      </div>
    </Drawer>
  )
}

export default inject(
  'organizationUsersStore',
  'localizationStore'
)(observer(OrganizationUserEditDrawer))
