import { action, computed, observable } from 'mobx'
import { IPulseCategoryDTO } from '../dtos/IPulseCategoryDTO'
import { RootStore } from '../../stores/RootStore'
import { PulseCategoriesManageVM } from './PulseCategoriesManageVM'


export class GrandCategoryTabVM {
  private rootStore: RootStore
  private manageVM: PulseCategoriesManageVM

  constructor(rootStore: RootStore, manageVM: PulseCategoriesManageVM, category: IPulseCategoryDTO, index: number) {
    this.rootStore = rootStore
    this.category = category
    this.manageVM = manageVM
    this.index = index
  }

  @observable public category: IPulseCategoryDTO = null
  @observable public index: number = 0
  @observable public isSelected: boolean = true

  @action
  public toggleIsSelected() {
    this.isSelected = !this.isSelected
  }

  @computed
  public get name(): string {
    return this.category.name
  }

  @computed
  public get objectId(): string {
    return this.category.objectId
  }

}
