import { computed, reaction } from 'mobx'
import { RootStore } from '../stores/RootStore'
import moment from 'moment'
import 'moment/locale/de'
import traverse from 'traverse'
import { LanguagePreferenceEnum } from './types'
import ILZstrings from './ILZstrings'

export default class LocalizationStore {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    setTimeout(() => moment.locale(this.locale), 200)
    reaction(
      () => this.locale,
      () => moment.locale(this.locale)
    )
  }

  @computed
  public get en(): ILZstrings {
    return require('./en.json')
  }

  @computed
  public get de() {
    return require('./de.json')
  }

  @computed
  public get pseudo() {
    return require('./pseudo.json')
  }

  @computed
  public get language(): LanguagePreferenceEnum {
    let defaultLang: LanguagePreferenceEnum = 'English'
    // Use browser locale until user loads, then use user language preference
    if (this.browserLocale && this.browserLocale !== 'en') {
      if (this.browserLocale === 'de') {
        defaultLang = 'German'
      }
    }
    if (!this.rootStore) return defaultLang
    if (!this.rootStore.userStore) return defaultLang
    if (!this.rootStore.userStore.user) return defaultLang
    if (!this.rootStore.userStore.user.languagePreference) return defaultLang
    return this.rootStore.userStore.user.languagePreference
  }

  @computed
  public get locale(): string {
    if (!this.language) return 'en'
    if (this.language === 'English') return 'en'
    if (this.language === 'Pseudo') return 'en'
    if (this.language === 'German') return 'de'
  }

  @computed
  public get browserLocale(): string {
    let locale = 'en'
    if (navigator.languages !== undefined) locale = navigator.languages[0]
    else locale = navigator.language
    locale = locale.toLowerCase().substring(0, 2)
    return locale
  }

  @computed
  public get lzStrings(): ILZstrings {
    if (!this.rootStore) return this.en
    if (this.language === 'English') return this.en
    const fallbackStrings = this.en
    const desiredStrings = this.language === 'German' ? this.de : this.pseudo
    const traverseFallback = traverse(fallbackStrings)
    const traverseDesired = traverse(desiredStrings)
    return traverseFallback.map(function (node) {
      if (this.isLeaf) {
        const foundValueToOverwriteWith = traverseDesired.get(this.path)
        if (foundValueToOverwriteWith) this.update(foundValueToOverwriteWith)
      }
    })
  }
}

const svc = new LocalizationStore(null)
