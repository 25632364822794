import { RootStore } from '../../../stores/RootStore'
import { DataStore } from '../../../shared/data/DataStore'
import { IUserSurveyDTO } from '../../dtos/IUserSurveyDTO'
import { UserSurvey } from '../../aggregate/UserSurvey'
import { UserSurveysService } from '../../service/UserSurveysService'
import { IUserSurveysListRequest } from '../../interfaces/IUserSurveysListRequest'

export class UserSurveysListStore extends DataStore<UserSurvey, IUserSurveyDTO> {
  private request: IUserSurveysListRequest

  constructor(rootStore: RootStore, request: IUserSurveysListRequest) {
    super(rootStore, UserSurvey, 'userSurveys', [
      'objectId',
      'name',
      'publishedByUserId',
      'publishedByUserName',
      'organizationId',
      'dueDate',
      'publishedDateTime',
      'participants',
      'isDeleted',
      'isActive',
      'questionsCount',
      'alwaysAvailable',
      'isWelcomeEmail',
    ])
    this.request = request
    this.paged = true
    this.recordsPerPage = 100
    this.sortDirection = 'asc'
    this.sortColumnName = 'dueDate'
  }

  public setRequest(request: IUserSurveysListRequest) {
    this.request = request
  }

  public getRequest(): IUserSurveysListRequest {
    return this.request
  }

  protected getListRecordsApiCall() {
    return async () => {
      const req: IUserSurveysListRequest = {
        ...this.request,
        userId: this.rootStore.appStore.currentUserId,
        organizationId: this.rootStore.appStore.currentOrgId,
        terms: this.filter,
        skip: this.recordsPerPage * (this.pageNumber - 1),
        limit: this.recordsPerPage,
        sortColumnName: this.sortColumnName,
        sortDirection: this.sortDirection,
        listColumns: this.listColumns,
      }
      const svc = new UserSurveysService(this.rootStore)
      const result = await svc.getUserSurveysList(req)
      this.totalRecords = result.count
      return result.userSurveys
    }
  }

  public get rows() {
    return this.records
  }
}
