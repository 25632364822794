import React from 'react'
import '../ContactListWidget.scss'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { ContactsStore } from '../../store/ContactsStore'
import { ClientsStore } from '../../../clients/store/ClientsStore'
import { OutlinedInput, InputLabel, MenuItem, TextField } from '@material-ui/core'
import { LabelsStore } from '../../../labels/store/LabelsStore'
import Autocomplete from '@material-ui/lab/Autocomplete'

interface ContactInfoProps {
  localizationStore?: LocalizationStore
  contactsStore?: ContactsStore
  clientsStore?: ClientsStore
  labelsStore?: LabelsStore
}

const ContactInfo: React.FC<ContactInfoProps> = ({
  localizationStore,
  contactsStore,
  clientsStore,
  labelsStore,
}) => {
  const { lzStrings } = localizationStore
  const { contactEditVM: vm } = contactsStore

  if (!vm) return null

  const renderDuplicateEmailError = () => {
    if (!vm.emailDuplicate) return null
    return (
      <div className={'email-error'}>This email is being used by another contact.</div>
    )
  }
  return (
    <section id='ContactInfo'>
      <h6>{lzStrings.contactListWidget.contact_details}</h6>
      <form autoComplete='off' noValidate>
        <InputLabel required htmlFor='name'>
          {lzStrings.contactListWidget.name}
        </InputLabel>
        <OutlinedInput
          id='name'
          fullWidth
          labelWidth={0}
          autoComplete='off'
          value={vm.name ? vm.name : ''}
          onChange={e => vm.setName(e.target.value)}
          error={vm.nameNotValid}
        />
        <InputLabel required htmlFor='email'>{lzStrings.contactListWidget.email}</InputLabel>
        <OutlinedInput
          id='email'
          required
          fullWidth
          labelWidth={0}
          autoComplete='off'
          value={vm.email}
          onChange={e => vm.setEmail(e.target.value)}
          error={!vm.emailNotValid}
        />
        {renderDuplicateEmailError()}
        <InputLabel htmlFor='title'>{lzStrings.contactListWidget.title}</InputLabel>
        <OutlinedInput
          id='title'
          fullWidth
          labelWidth={0}
          autoComplete='off'
          value={vm.title}
          onChange={e => vm.setTitle(e.target.value)}
        />
        <InputLabel htmlFor='client'>{`${labelsStore.getLabelByLanguageAndFor(
          'client'
        )} Name`}</InputLabel>
        <Autocomplete
          className='client-autocomplete'
          options={vm.clientOptions}
          getOptionLabel={e => e.name}
          value={vm.clientObj}
          onChange={(e, val) => vm.setClient(val)}
          renderInput={params => (
            <TextField
              {...params}
              placeholder={`Select ${labelsStore.getLabelByLanguageAndFor('client')}`}
              variant='outlined'
              className={'client-select'}
            />
          )}
        />
      </form>
    </section>
  )
}

export default inject(
  'localizationStore',
  'contactsStore',
  'clientsStore',
  'labelsStore'
)(observer(ContactInfo))
