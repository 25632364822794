import React, { useRef } from 'react'
import { inject, observer, Observer } from 'mobx-react'
import LocalizationStore from '../../../localization/LocalizationStore'
import { OrganizationsStore } from '../../store/OrganizationsStore'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import FileUploadModal from '../../../upload/views/FileUploadModal'
import { Attachment } from '../../../upload/aggregate/Attachment'
import { FileTypeEnum } from '../../../upload/enum/FileTypeEnum'
import CMSItemsPickerDialog from '../../../cms-items/views/dialogs/CMSItemsPickerDialog'
import { CMSItemsTypeEnum } from '../../../cms-items/enum/CMSItemsTypeEnum'

const useStyles = makeStyles({
  dropzone: {
    width: '100%',
    height: '100px',
    padding: '1em 0',
    backgroundColor: '#F4F4F7',
    marginBottom: '1em',
    border: '2px dashed #D7D7E2',
    borderRadius: '4px',
    cursor: 'pointer',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noteAddIcon: {
    fontSize: '5em',
    color: '#05D1CF',
    marginBottom: '15px',
  },
  dropzoneText: {
    color: '#91919A',
  },
  dropLink: {
    color: '#05D1CF',
    textDecoration: 'underline #05D1CF',
  },
  thumbUpIcon: {
    fontSize: '5em',
    color: '#6ACA9A',
    marginBottom: '15px',
  },
  thumbDownIcon: {
    fontSize: '5em',
    color: '#E85C5C',
    marginBottom: '15px',
  },
  dropzoneImage: {
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  buttonDiv: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '12px',
    color: '#009F9E',
  },
  revertButton: {
    fontSize: 'inherit',
    color: 'inherit',
    '&:hover': {
      backgroundColor: '#009F9E0D',
    },
    '&:focused': {
      backgroundColor: '#009F9E33',
    },
  },
  imageButton: {
    fontSize: 'inherit',
    borderColor: '#CECED5',
    color: 'inherit',
    '&:hover': {
      backgroundColor: '#009F9E0D',
    },
    '&:focused': {
      backgroundColor: '#009F9E33',
    },
  },
})

interface DrawerLogoDropzoneProps {
  localizationStore?: LocalizationStore
  organizationsStore?: OrganizationsStore
}

const DrawerLogoDropzone: React.FC<DrawerLogoDropzoneProps> = ({
  localizationStore,
  organizationsStore,
}) => {
  const classes = useStyles({})
  const { lzStrings } = localizationStore
  const { editVM } = organizationsStore

  const openButton = () => {
    editVM.toggleLogoUploadModal()
  }

  const handleRevertLogo = () => {
    editVM.revertLogoToDefault()
  }

  const renderLogoUploadModal = () => {
    if (organizationsStore.currentOrganization?.hasCMSPicker) return renderCMSUploadModal()
    return renderStandardUploadModal()
  }

  const renderCMSUploadModal = () => {
    return (
      <CMSItemsPickerDialog
        allowMultiple={false}
        fileTypes={CMSItemsTypeEnum.IMAGES}
        addAfterSave={(a: Attachment[]) => editVM.setLogo(a[0])}
        toggleShowModal={() => editVM.toggleLogoUploadModal()}
        isOpen={editVM.showLogoUploadModal}
      />
    )
  }

  const renderStandardUploadModal = () => {
    return (
      <FileUploadModal
      allowMultiple={false}
      fileTypes={FileTypeEnum.IMAGES}
      addAfterSave={(a: Attachment[]) => editVM.setLogo(a[0])}
      toggleShowModal={() => editVM.toggleLogoUploadModal()}
      isOpen={editVM.showLogoUploadModal}
    />
    )
  }

  return (
    <>
    {renderLogoUploadModal()}
      <div className={classes.buttonDiv}>
        <Button className={classes.revertButton} onClick={handleRevertLogo}>
          {lzStrings.organizationWidgetDrawer.logo_button_revert}
        </Button>
        <Button className={classes.imageButton} onClick={openButton} variant='outlined'>
          {lzStrings.organizationWidgetDrawer.logo_choose_image}
        </Button>
      </div>
    </>
  )
}

export default inject('localizationStore', 'organizationsStore')(observer(DrawerLogoDropzone))
