import { computed, action } from 'mobx'
import { LabelsStore } from '../../labels/store/LabelsStore'
import { RootStore } from '../../stores/RootStore'

export class SystemAdminScreenVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @computed
  public get labelsStore(): LabelsStore {
    return this.rootStore.labelsStore
  }

  @computed
  public get lz() {
    return this.rootStore.localizationStore.lzStrings.pulseCampaign
  }

  @computed
  public get sLz() {
    return this.rootStore.localizationStore.lzStrings.surveys
  }

  @computed
  public get lzn() {
    return this.rootStore.localizationStore.lzStrings.emailTemplateManagement
  }

  @action
  public navigateToManageCategories() {
    this.rootStore.appStore.router.push('/systemAdmin/manage/pulseCategories')
  }

  @action
  public navigateToManagePulseSets() {
    this.rootStore.appStore.router.push('/systemAdmin/manage/pulseSets')
  }

  @action
  public navigateToManageSystemEmailTemplates() {
    this.rootStore.appStore.router.push('/systemAdmin/manage/emailTemplates')
  }

  @action
  public navigateToManagePulseCampaignTypes() {
    this.rootStore.appStore.router.push('/systemAdmin/manage/pulseCampaignTypes')
  }

  @action
  public navigateToManageSurveyTemplates() {
    this.rootStore.appStore.router.push('/systemAdmin/manage/surveyTemplates')
  }
  
  public navigateToManageSurveyTypes() {
    this.rootStore.appStore.router.push('/systemAdmin/manage/surveyTypes')
  }
}
