import { computed, action, observable } from 'mobx'
import { RootStore } from '../../../stores/RootStore'
import { IUserImportRowDTO } from '../../dtos/IUserImportRowDTO'

export class UserImportRowVM {
  private rootStore: RootStore
  private row: IUserImportRowDTO

  constructor(rootStore: RootStore, row: IUserImportRowDTO) {
    this.row = row
    this.rootStore = rootStore
  }

  @computed
  public get height(): number {
    let height = 109
    height = height + 26 * this.rowsCount
    return height
  }

  @computed
  public get rowsCount(): number {
    let rows = 1
    if (this.hasError) return 0
    if (this.addedRoles) rows++
    if (this.addedGroups) rows++
    if (this.updatedPrimaryGroupName) rows++
    if (this.updatedPrimaryRoleName) rows++
    if (this.addedPrimaryGroupName) rows++
    if (this.addedPrimaryRoleName) rows++
    if (this.addedSuperiors) rows++
    if (this.updatedSuperiors) rows++
    if (this.addedPhoneNumber) rows++
    if (this.updatedPhoneNumber) rows++
    if (this.updatedEmployeeId) rows++
    if (this.addedEmployeeId) rows++
    if (this.updatedJobNumber) rows++
    if (this.addedJobNumber) rows++
    if (this.updatedBirthdate) rows++
    if (this.addedBirthdate) rows++
    if (this.updatedTitle) rows++
    if (this.addedTitle) rows++
    if (this.updatedReceiveEmails) rows++
    if (this.addedReceiveEmails) rows++
    if (this.updatedReceiveTextMessages) rows++
    if (this.addedReceiveTextMessages) rows++
    if (this.updatedReceivePushNotifications) rows++
    if (this.addedReceivePushNotifications) rows++
    if (this.updatedArchive) rows++
    if (this.addedArchive) rows++
    if (this.removedRoles) rows++
    if (this.removedGroups) rows++
    if (this.addedPrivilegeSets) rows++
    if (this.addedToPrivilegeSets) rows++
    if (this.removedPrivilegeSets) rows++
    return rows
  }

  @computed
  public get hasError() {
    return !this.row.result.success
  }

  @computed
  public get rowIndex(): string {
    return this.row.rowIndex.toString()
  }

  @computed
  public get isNewUser(): boolean {
    return this.row.result.isNew
  }

  @computed
  public get key() {
    return 'usr' + this.row.rowIndex
  }

  @computed
  public get userErrorMessage(): string {
    if (!this.row.result) return ''
    if (!this.row.result.errorMessage) return ''
    return this.row.result.errorMessage
  }

  @computed
  public get orgUserErrorMessage(): string {
    if (!this.row.result) return ''
    if (!this.row.result.orgUserSaveResult.errorMessage) return ''
    return this.row.result.orgUserSaveResult.errorMessage
  }

  @computed
  public get userName(): string {
    if (!this.row.result) return ''
    if (!this.row.result.user) return this.row['First Name'] + ' ' + this.row['Last Name']
    return this.row.result.user.name
  }

  @computed
  public get userEmail(): string {
    if (!this.row.result) return ''
    if (!this.row.result.user) return this.row['Email Address']
    return this.row.result.user.email
  }

  @computed
  public get userUsername(): string {
    if (!this.row.result) return ''
    if (!this.row.result.user) return this.row['Username']
    return this.row.result.user.username
  }

  @computed
  public get addedRoles(): string {
    if (!this.row.result) return ''
    if (!this.row.result.orgUserSaveResult.added) return ''
    if (!this.row.result.orgUserSaveResult.added.roles) return ''
    const roleIds = Object.keys(this.row.result.orgUserSaveResult.added.roles)
    const roleNames = roleIds.map((e) => {
      const foundRole = this.rootStore.rolesStore.getRole(e)
      if (foundRole) return foundRole.name
      return e
    })
    return roleNames.join(', ')
  }

  @computed
  public get addedGroups(): string {
    if (!this.row.result) return ''
    if (!this.row.result.orgUserSaveResult.added) return ''
    if (!this.row.result.orgUserSaveResult.added.groups) return ''
    const groupIds = Object.keys(this.row.result.orgUserSaveResult.added.groups)
    const groupNames = groupIds.map((e) => {
      const foundGroup = this.rootStore.groupsStore.getGroup(e)
      if (foundGroup) return foundGroup.name
      return e
    })
    return groupNames.join(', ')
  }

  @computed
  public get addedSuperiors(): string {
    if (!this.row.result) return ''
    if (!this.row.result.orgUserSaveResult.added) return ''
    if (!this.row.result.orgUserSaveResult.added.superiors) return ''
    const userIds = Object.values(this.row.result.orgUserSaveResult.added.superiors)
    const userNames = userIds.map((e) => {
      const foundUser = this.rootStore.usersStore.getUserByUserId(e)
      if (foundUser) return foundUser.name
      return e
    })
    return userNames.join(', ')
  }

  @computed
  public get updatedSuperiors(): string {
    if (!this.row.result) return ''
    if (!this.row.result.orgUserSaveResult.updated) return ''
    if (!this.row.result.orgUserSaveResult.updated.superiors) return ''
    const userIds = Object.values(this.row.result.orgUserSaveResult.updated.superiors)
    const userNames = userIds.map((e) => {
      const foundUser = this.rootStore.usersStore.getUserByUserId(e)
      if (foundUser) return foundUser.name
      return e
    })
    return userNames.join(', ')
  }

  @computed
  public get addedPrimaryGroupName() {
    const groupId = this.row.result.orgUserSaveResult.added.primaryGroupId
    if (!groupId) return ''
    const group = this.rootStore.groupsStore.getGroup(groupId)
    if (!group) return ''
    return group.name
  }

  @computed
  public get addedPrimaryRoleName() {
    const roleId = this.row.result.orgUserSaveResult.added.primaryRoleId
    if (!roleId) return ''
    const role = this.rootStore.rolesStore.getRole(roleId)
    if (!role) return ''
    return role.name
  }

  @computed
  public get updatedPrimaryGroupName() {
    const groupId = this.row.result.orgUserSaveResult.updated.primaryGroupId
    if (!groupId) return ''
    const group = this.rootStore.groupsStore.getGroup(groupId)
    if (!group) return ''
    return group.name
  }

  @computed
  public get updatedPrimaryRoleName() {
    const roleId = this.row.result.orgUserSaveResult.updated.primaryRoleId
    if (!roleId) return ''
    const role = this.rootStore.rolesStore.getRole(roleId)
    if (!role) return ''
    return role.name
  }

  @computed
  public get updatedPhoneNumber() {
    const val = this.row.result.updated.phone
    if (!val) return ''
    return val
  }

  @computed
  public get addedPhoneNumber() {
    if (this.hasError) return ''
    const val = this.row.result.added.phone
    if (!val) return ''
    return val
  }

  @computed
  public get updatedEmployeeId() {
    const val = this.row.result.orgUserSaveResult.updated.employeeId
    if (!val) return ''
    return val
  }

  @computed
  public get addedEmployeeId() {
    const val = this.row.result.orgUserSaveResult.added.employeeId
    if (!val) return ''
    return val
  }

  @computed
  public get updatedJobNumber() {
    const val = this.row.result.orgUserSaveResult.updated.jobNumber
    if (!val) return ''
    return val
  }

  @computed
  public get addedJobNumber() {
    const val = this.row.result.orgUserSaveResult.added.jobNumber
    if (!val) return ''
    return val
  }

  @computed
  public get updatedBirthdate() {
    const val = this.row.result.updated.birthDate
    if (!val) return ''
    return val
  }

  @computed
  public get addedBirthdate() {
    const val = this.row.result.added.birthDate
    if (!val) return ''
    return val
  }

  @computed
  public get updatedTitle() {
    const val = this.row.result.orgUserSaveResult.updated.title
    if (!val) return ''
    return val
  }

  @computed
  public get addedTitle() {
    const val = this.row.result.orgUserSaveResult.added.title
    if (!val) return ''
    return val
  }

  @computed
  public get updatedReceiveTextMessages() {
    if (!this.row.result.updated.hasOwnProperty('receiveTextMessages')) return false
    const val = this.row.result.updated.receiveTextMessages
    if (!val) return 'No'
    return 'Yes'
  }

  @computed
  public get addedReceiveTextMessages() {
    if (!this.row.result.added.hasOwnProperty('receiveTextMessages')) return false
    const val = this.row.result.added.receiveTextMessages
    if (!val) return 'No'
    return 'Yes'
  }

  @computed
  public get updatedReceivePushNotifications() {
    if (!this.row.result.updated.hasOwnProperty('receivePushNotifications')) return false
    const val = this.row.result.updated.receivePushNotifications
    if (!val) return 'No'
    return 'Yes'
  }

  @computed
  public get addedReceivePushNotifications() {
    if (!this.row.result.added.hasOwnProperty('receivePushNotifications')) return false
    const val = this.row.result.added.receivePushNotifications
    if (!val) return 'No'
    return 'Yes'
  }

  @computed
  public get updatedReceiveEmails() {
    if (!this.row.result.updated.hasOwnProperty('receiveEmails')) return false
    const val = this.row.result.updated.receiveEmails
    if (!val) return 'No'
    return 'Yes'
  }

  @computed
  public get addedReceiveEmails() {
    if (!this.row.result.added.hasOwnProperty('receiveEmails')) return false
    const val = this.row.result.added.receiveEmails
    if (!val) return 'No'
    return 'Yes'
  }

  @computed
  public get updatedArchive() {
    if (!this.row.result.updated.hasOwnProperty('archived')) return false
    const val = this.row.result.updated.archived
    if (!val) return 'No'
    return 'Yes'
  }

  @computed
  public get addedArchive() {
    if (!this.row.result.added.hasOwnProperty('archived')) return false
    const val = this.row.result.added.archived
    if (!val) return 'No'
    return 'Yes'
  }

  @computed
  public get archived(): boolean {
    if (!this.row.result) return false
    return this.row['Archived'] ? this.computeArchived(this.row['Archived'].toLowerCase()) : false
  }

  private computeArchived(value: string): boolean {
    if (!value) return false
    if (value === 'x') return true
    if (value === 'yes') return true
    if (value === 'true') return true
    return false
  }

  @computed
  public get removedRoles(): string {
    if (!this.row.result) return ''
    if (!this.row.result.orgUserSaveResult.deleted) return ''
    if (!this.row.result.orgUserSaveResult.deleted.roles) return ''
    const roleIds = this.row.result.orgUserSaveResult.deleted.roles
    const roleNames = Object.keys(roleIds).map((e) => {
      const foundRole = this.rootStore.rolesStore.getRole(e)
      if (foundRole) return foundRole.name
      return e
    })
    return roleNames.join(', ')
  }

  @computed
  public get removedGroups(): string {
    if (!this.row.result) return ''
    if (!this.row.result.orgUserSaveResult.deleted) return ''
    if (!this.row.result.orgUserSaveResult.deleted.groups) return ''
    const groupIds = this.row.result.orgUserSaveResult.deleted.groups
    const groupNames = Object.keys(groupIds).map((e) => {
      const foundGroup = this.rootStore.groupsStore.getGroup(e)
      if (foundGroup) return foundGroup.name
      return e
    })
    return groupNames.join(', ')
  }

  @computed
  public get removedPrivilegeSets(): string {
    if (!this.row.result) return ''
    if (!this.row.result.orgUserSaveResult.deleted) return ''
    if (!this.row.result.orgUserSaveResult.deleted.privilegeSets) return ''
    const setIds = this.row.result.orgUserSaveResult.deleted.privilegeSets
    const setNames = setIds.map((e) => {
      const foundSet = this.rootStore.privilegeSetsStore.getPrivilegeSet(e)
      if (foundSet) return foundSet.name
      return e
    })
    return setNames.join(', ')
  }

  @computed
  public get addedToPrivilegeSets(): string {
    if (!this.row.result) return ''
    if (!this.row.result.orgUserSaveResult.updated) return ''
    if (!this.row.result.orgUserSaveResult.updated.privilegeSets) return ''
    const setIds = this.row.result.orgUserSaveResult.updated.privilegeSets
    let setNames = []
    for (var key in setIds) {
      const foundSet = this.rootStore.privilegeSetsStore.getPrivilegeSet(setIds[key])
      if (foundSet) setNames.push(foundSet.name)
    }
    return setNames.join(', ')
  }

  @computed
  public get addedPrivilegeSets(): string {
    if (!this.row.result) return ''
    if (!this.row.result.orgUserSaveResult.added) return ''
    if (!this.row.result.orgUserSaveResult.added.privilegeSets) return ''
    const setIds = this.row.result.orgUserSaveResult.added.privilegeSets
    let setNames = []
    for (var key in setIds) {
      const foundSet = this.rootStore.privilegeSetsStore.getPrivilegeSet(setIds[key])
      if (foundSet) setNames.push(foundSet.name)
    }
    return setNames.join(', ')
  }
}
