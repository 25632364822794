import React from 'react'
import { observer, inject } from 'mobx-react'
import { CardHeader, Tooltip, IconButton } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import LocalizationStore from '../../../localization/LocalizationStore'
import { MediaItemsStore } from '../../store/MediaItemsStore'
import { OrganizationsStore } from '../../../organizations/store/OrganizationsStore'
import './Header.scss'

interface Props {
  localizationStore?: LocalizationStore
  mediaItemsStore?: MediaItemsStore
  organizationsStore?: OrganizationsStore
}

const Header: React.FC<Props> = ({ localizationStore, mediaItemsStore, organizationsStore }) => {
  const renderAction = () => {
    if (organizationsStore.currentOrganization?.hasCMSPicker) return
    return (
      <Tooltip
        title={localizationStore.lzStrings.mediaItemsListWidget.add_new_media_item}
        placement='bottom'
      >
        <IconButton
          className='widget-cta'
          size='small'
          onClick={() => mediaItemsStore.openDrawer()}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
    )
  }

  return (
    <CardHeader
      id='Header'
      title={'Manage Media Items'}
      action={
        <>
          <div id='MediaItemsAdd' className='action-btn'>
            {renderAction()}
          </div>
        </>
      }
    />
  )
}

export default inject('localizationStore', 'mediaItemsStore', 'organizationsStore')(observer(Header))
