import { action, observable, computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import Location from '../aggregate/Location'
import { LocationsService } from '../service/LocationsService'

export default class LocationEditVM {
  rootStore: RootStore

  constructor(rootStore: RootStore, location: Location = null) {
    this.rootStore = rootStore
    if (location) this.loadData(location)
  }

  private loadData(location: Location) {
    this.objectId = location.objectId
    this.name = location.name
    this.lat = location.lat
    this.lng = location.lng
  }

  @observable objectId: string = ''
  @observable name: string = ''
  @observable lat: number = null
  @observable lng: number = null
  @observable editAddress: boolean = false

  @observable saveTried: boolean = false

  @action
  public markSaveTried() {
    this.saveTried = true
  }

  @action
  public toggleEditAddress() {
    this.editAddress = !this.editAddress
  }

  @action
  public handlePlaceCancel() {
    if (this.objectId) {
      this.rootStore.locationsStore.setSelectedLocationId(this.objectId)
    } else {
      this.lat = null
      this.lng = null
    }
  }

  @action
  public handlePlaceSelection(place) {
    if (!this.name) {
      this.name = place.selectedText
    }
  }

  @action
  public handleEditCoordinates() {
    this.lat = null
    this.lng = null
    this.toggleEditAddress()
  }

  @computed
  public get isEditMode() {
    return Boolean(this.objectId)
  }

  @computed
  public get errorHelperText() {
    if (!this.nameValid) return 'Please enter a name for this location.'
    if (!this.nameUnique) return 'This name is being used by another location.'
  }

  @computed
  public get nameValid() {
    if (!this.saveTried) return true
    if (!this.name || this.name.trim() === '') return false
    return true
  }

  @computed
  public get nameUnique() {
    if (!this.saveTried) return true
    const foundLocation = this.rootStore.locationsStore.getLocationByName(this.name)
    if (foundLocation && foundLocation.objectId !== this.objectId) return false
    return true
  }

  @computed
  public get isValid() {
    if (!this.saveTried) return true
    if (!this.nameValid) return false
    if (!this.nameUnique) return false
    return true
  }

  @action
  public async save() {
    this.markSaveTried()
    if (!this.isValid) return false
    this.rootStore.locationsStore.showDrawer = false

    try {
      const newLocation = {
        objectId: this.objectId ? this.objectId : null,
        name: this.name,
        lat: this.lat,
        lng: this.lng,
      }

      await new LocationsService().saveLocation(this.rootStore.appStore.currentOrgId, newLocation)
      this.rootStore.locationsStore.clearForms()
    } catch (e) {
      console.error(e)
    }
  }

  @action
  public async delete() {
    try {
      this.rootStore.locationsStore.showDeleteDialog = false
      this.rootStore.locationsStore.showDrawer = false

      await new LocationsService().deleteLocation(
        this.rootStore.appStore.currentOrgId,
        this.objectId
      )
    } catch (e) {
      console.error(e)
    }
  }
}
