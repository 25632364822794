import { observable } from 'mobx'
import { IAggregate } from 'src/app/shared/data/IAggregate'
import { ISurveyTemplateDTO } from '../dtos/ISurveyTemplateDTO'
import { serializable, serialize, deserialize, date, list, primitive, object } from 'serializr'
import { Survey } from '../../surveys/aggregate/Survey'
import { FKUser } from '../../organization-users/aggregate/FKUser'

export class SurveyTemplate extends Survey implements ISurveyTemplateDTO, IAggregate {
  @serializable @observable objectId: string = ''
  @serializable @observable organizationId: string = ''
  @serializable @observable createdByUserId: string = ''
  @serializable(date()) @observable updatedAt: Date = null
  @serializable @observable updatedByUserId?: string = ''
  @serializable @observable isDeleted: boolean = false
  @serializable @observable owner: string = ''
  @serializable @observable folderId: string = ''
  @serializable(object(FKUser)) public fk_owner: FKUser = undefined
  @serializable(object(FKUser)) public fk_updatedByUser: FKUser = undefined
  @serializable(object(FKUser)) public fk_createdByUser: FKUser = undefined
  @serializable(list(primitive())) @observable public subscribedOrganizationIds: string[] = []

  public isOnServer: boolean = false

  markIsNotOnServer(): void {
    this.isOnServer = false
  }

  markIsOnServer(): void {
    this.isOnServer = true
  }

  public serialize(): ISurveyTemplateDTO {
    return serialize(this)
  }

  public clone(): SurveyTemplate {
    return deserialize(SurveyTemplate, this.serialize())
  }
}
