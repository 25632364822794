import { RootStore } from '../../../stores/RootStore'
import { computed, action, observable } from 'mobx'
import { Field } from '../../aggregate/Field'
import { FieldType } from '../../types/FieldType'
import { UserIdentifierType } from '../../types/UserIdentifierType'

export class FieldEditVM {
  private rootStore: RootStore
  private field: Field

  constructor(rootStore: RootStore, field: Field) {
    this.rootStore = rootStore
    this.field = field
  }

  @computed
  public get isNew(): boolean {
    return this.field.isNew
  }

  @computed
  public get fieldName(): string {
    return this.field.fieldName
  }

  @computed
  public get displayName(): string {
    return this.field.displayName
  }

  @computed
  public get type(): FieldType {
    return this.field.type
  }

  @computed
  public get isCustomRecordKey(): boolean {
    return this.field.isCustomRecordKey
  }

  @computed
  public get isUserIdentifier(): boolean {
    return this.field.isUserIdentifier()
  }

  @computed
  public get isFacet(): boolean {
    return this.field.isFacet
  }

  @computed
  public get isValueFactDate(): boolean {
    return this.field.isValueFactDate
  }

  @action
  public setFieldName(val) {
    console.log('settingFieldName')
    this.field.setFieldName(val)
  }

  @action
  public setName(val) {
    this.field.setName(val)
  }

  @action
  public setDisplayName(val) {
    this.field.displayName = val
  }

  @action
  public setType(val) {
    if (val !== 'Date') {
      this.field.isValueFactDate = false
    }

    if (val !== 'Number') {
      this.field.isFacet = false
    }
    this.field.setType(val)
    console.log(val)
  }

  @action
  public toggleIsCustomRecordKey() {
    this.field.toggleIsRecordKey()
  }

  @action
  public toggleIsFacet() {
    this.field.toggleIsFacet()
  }

  @action
  public toggleIsValueFactDate() {
    this.field.toggleIsValueFactDate()
  }

  @action
  public delete() {
    this.field.delete()
  }
}
