import { observable, action, computed } from 'mobx'
import { serializable, date, serialize, deserialize } from 'serializr'
import { ICloudinaryResult } from '../dtos/ICloudinaryResult'
import moment from 'moment'
import { IMediaItemDTO } from '../dtos/IMediaItemDTO'
import { IAggregate } from '../../shared/data/IAggregate'
import { Attachment } from '../../upload/aggregate/Attachment'
import { MediaItemType } from '../../upload/types/MediaItemType'

export class MediaItem implements IMediaItemDTO, IAggregate {
  static create(orgId: string) {
    const item = new MediaItem()
    item.organizationId = orgId
    return item
  }

  @serializable @observable public objectId: string = ''
  @serializable @observable public organizationId: string = ''
  @serializable @observable public name: string = ''
  @serializable @observable public type: MediaItemType = null
  @serializable(date()) @observable public createdAt: Date = new Date()
  @serializable @observable public size: number = 0
  @serializable @observable public fileUrl: string = ''
  @serializable @observable public videoThumbnail?: string = ''
  @serializable @observable public isDeleted: boolean = false
  public isOnServer: boolean = false
  @serializable @observable public cmsItemId: number = undefined

  static createFromAttachment(a: Attachment, orgId: string): MediaItem {
    const mediaItem = new MediaItem()
    mediaItem.videoThumbnail = a.type === 'video' ? a.thumbnail : null
    mediaItem.fileUrl = a.url
    mediaItem.type = a.type
    mediaItem.size = a.bytes
    mediaItem.name = a.fileName
    mediaItem.organizationId = orgId
    return mediaItem
  }

  static createFromCloudinaryResult(doc: ICloudinaryResult, orgId): MediaItem {
    const mediaItem = new MediaItem()
    mediaItem.videoThumbnail = doc.resource_type === 'video' ? doc.thumbnail_url : null
    mediaItem.fileUrl = doc.secure_url
    mediaItem.type = doc.resource_type
    mediaItem.size = doc.bytes
    mediaItem.name = doc.original_filename ? doc.original_filename : 'upload: ' + doc.created_at
    mediaItem.organizationId = orgId
    return mediaItem
  }
  @action
  public setOrganizationId(id: string) {
    this.organizationId = id
  }
  @action
  public setName(val: string) {
    this.name = val
  }

  @action
  public setObjectId(id: string) {
    this.objectId = id
  }

  @action
  public markIsOnServer() {
    this.isOnServer = true
  }
  @action
  public markIsNotOnServer() {
    this.isOnServer = false
  }

  public clone(): MediaItem {
    return deserialize(MediaItem, this.serialize())
  }

  @action
  public serialize() {
    const obj = serialize(this)
    obj.createdAt = moment(this.createdAt).toISOString()
    return obj
  }
}
