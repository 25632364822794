import React from 'react'
import { inject, observer } from 'mobx-react'
import LocalizationStore from '../../localization/LocalizationStore'
import { QuestionsStore } from '../store/QuestionsStore'
import { AppBar, Toolbar, Button } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import MuiConfirmDeleteDialog from '../../shared/MuiConfirmDeleteDialog'
import MuiDrawerToolbar from '../../shared/Mui-Drawer/MuiDrawerToolbar'

interface Props {
  localizationStore?: LocalizationStore
  questionsStore?: QuestionsStore
}

const DrawerButtonControls: React.FC<Props> = ({ localizationStore, questionsStore }) => {
  const { lzStrings } = localizationStore
  const { editVM: vm } = questionsStore
  return (
    <div>
      <MuiDrawerToolbar
        handleCancelClick={() => vm.closeModal()}
        handleSubmitClick={() => vm.saveQuestion()}
        showReset={Boolean(vm.objectId)}
        onConfirmReset={() => questionsStore.loadEditVM(vm.objectId)}
        disabled={vm.saveButtonDisabled}
        moreMenuProps={{
          hidden: !vm.objectId,
          isEditMode: true,
          itemType: lzStrings.moreMenu.obj_type.question,
          itemName: vm.title,
          onConfirmDelete: () => vm.deleteQuestion(),
          onConfirmCopy: () => vm.copyQuestion(),
        }}
      />
    </div>
  )
}

export default inject('localizationStore', 'questionsStore')(observer(DrawerButtonControls))
