import { observable } from 'mobx'
import { serializable } from 'serializr'

export class AudienceMember {
  static create() {
    const audienceMember = new AudienceMember()
    return audienceMember
  }

  static createFromParticipantSelect(participant) {
    const audienceMember = new AudienceMember()
    audienceMember.id = participant.id
    audienceMember.name = participant.name
    audienceMember.type = participant.type
    audienceMember.iconURL = participant.iconURL
    audienceMember.objectId = participant.id
    return audienceMember
  }

  @serializable @observable public id: string = ''
  @serializable @observable public name: string = ''
  @serializable @observable public type: any = null
  @serializable @observable public iconURL: string = ''
  @serializable @observable public objectId: string = ''
}
